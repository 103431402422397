import { Button, Popover } from 'antd';
import {
  EquipmentDatas,
  LineProductGraph,
} from '@photocarbone/nooco-equipment-components';
import { Form, Modal } from 'react-bootstrap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EquipmentSheet from '../../../../../projects/LifeCycle/EquipmentSheet';
import Loader from '@material-ui/core/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import React, { useContext, useEffect, useState } from 'react';
import SelectUi from '@mui/material/Select';
import TextField from '@material-ui/core/TextField';

import caracs from '../../../../../../assets/images/exploitation/caracs.png';

import './equipmentChoice.scss';
import { NewActionDTO } from '../../../../../models/ExploitationDTO';
import { StateDTO } from '../../../../../models/GeneralDTO';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import { findParentNode } from '../../../../../shared/utils';
import { useSelector } from 'react-redux';
import API from '../../../../../services/API';
import AppContext from '../../../../../AppContext';
import NoocoCheckbox from '../../../../../shared/NoocoCheckbox';
import { useTranslation } from 'react-i18next';

interface EquipmentChoiceState {
  updateExploitationValue: Function;
  newAction: NewActionDTO;
  createAction: Function;
  resetAddAction: Function;
  allGroups: {}[];
  currentProject: {};
  fetchSheets: Function;
  sheets: {}[];
  loadGraph: boolean;
  loadInfosSheet: boolean;
  getCaracs: Function;
  getFullMd: Function;
  filters: {};
  saveFiltersBrand: Function;
}

export const checkCaracsValues = (newAction: NewActionDTO) => {
  // Check if caracs values are not empty

  if (
    (newAction?.renewalEquipment?.caracs?.caracs?.length > 0 &&
      newAction?.renewalEquipment?.caracs?.caracs?.length !==
        newAction?.renewalEquipment?.Caracs?.length &&
      newAction?.equipment.MasterDataEquipmentId !==
        newAction?.renewalEquipment?.MasterDataEquipmentId) ||
    (newAction?.renewalEquipment?.Caracs?.length &&
      newAction?.renewalEquipment?.Caracs?.find(
        (el) => !el.value || el.value === ''
      ))
  ) {
    return true;
  }

  return false;
};

const AntTabs = withStyles({
  root: {
    textAlign: 'center',
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#22C994',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: ['Roboto'].join(','),
    '&:hover': {
      color: '#22C994',
      opacity: 1,
    },
    '&$selected': {
      color: '#22C994',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#22C994',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const EquipmentChoice = ({
  updateExploitationValue,
  newAction,
  createAction,
  resetAddAction,
  allGroups,
  currentProject,
  fetchSheets,
  sheets,
  loadGraph,
  loadInfosSheet,
  getCaracs,
  getFullMd,
  filters,
  saveFiltersBrand,
}: EquipmentChoiceState) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const ctx = useContext(AppContext);
  const apiNooco = new API();

  const {
    currentIndicator,
    searchValue,
    zones,
    graphData,
    currentAllotment,
    filteredLifeCycle,
    graphView,
    sheets: projectSheets,
    currentMd,
  } = useSelector((state: StateDTO) => state.projects);
  const { sheets: exploitationSheets, scope } = useSelector(
    (state: StateDTO) => state.exploitation
  );

  let lotsToRemove = [
    'Transports et engins',
    'Déchets',
    'Eau',
    'Energie',
    'Energie simplifiée',
    'Terre',
    'Fluides',
  ];

  useEffect(() => {
    let node = allGroups.flatAllotments.find(
      (el) => el.id === newAction.renewalEquipment?.AllotmentNodeId
    );

    getFullMd(node);
  }, []);

  const onLineChartEquipmentChangeCallback = (equipment) => {
    updateExploitationValue('newAction', {
      ...newAction,
      renewalEquipment: { ...newAction.renewalEquipment, SheetId: equipment },
    });
  };

  // display of carac popover
  const renderCaracPopOver = () => {
    const currentSheet = sheets?.find(
      (sheet) =>
        sheet.ProjectSheet && sheet.ProjectSheet.id === newAction.equipment.id
    );

    const handleChangeCarac = (carac, value) => {
      // Need to be refactor as soon as possible
      const newCaracs = newAction.renewalEquipment.Caracs.map((el) => {
        if (el.id === carac.id) {
          return {
            ...el,
            value: value,
          };
        } else {
          return el;
        }
      });

      updateExploitationValue('newAction', {
        ...newAction,
        renewalEquipment: { ...newAction.renewalEquipment, Caracs: newCaracs },
      });
    };

    return (
      <div>
        {newAction.renewalEquipment?.Caracs?.map((carac) => {
          let caracListOptions = carac.caracList;

          const discreteCarac = newAction.renewalEquipment.Caracs?.find(
            (el) => el.id === newAction.renewalEquipment.caracs.caracCombos?.id
          );

          const secondDiscreteDepandingOnValue =
            newAction.renewalEquipment.caracs.caracCombos.valueList?.find(
              (el) => el.value === discreteCarac?.value && carac.name === el.name
            );

          if (discreteCarac?.value && secondDiscreteDepandingOnValue) {
            caracListOptions = secondDiscreteDepandingOnValue?.valueList;
          }

          return (
            <div className="exploitation-carac" key={carac.id}>
              <p>{carac.name} :</p>
              {!carac.type ? (
                <TextField
                  defaultValue={carac.value}
                  onChange={(e) => handleChangeCarac(carac, e.target.value)}
                  style={{
                    width: '50%',
                    marginLeft: '10px',
                    padding: '7px 6px',
                    fontSize: '1rem',
                  }}
                  variant="outlined"
                  className="input-boarding researchBar"
                />
              ) : (
                <SelectUi
                  defaultValue={carac.value}
                  variant="outlined"
                  className="input-boarding researchBar"
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  onChange={(e) => handleChangeCarac(carac, e.target.value)}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  style={{ width: '50%', marginLeft: '10px' }}
                  disabled={
                    newAction.renewalEquipment.caracs.caracCombos
                      ? newAction.renewalEquipment.caracs.caracCombos.valueList.find(
                          (el) => el.id === carac.id
                        ) &&
                        carac.id !== discreteCarac?.id &&
                        !discreteCarac?.value
                      : false
                  }
                >
                  {caracListOptions?.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </SelectUi>
              )}

              <p style={{ marginLeft: '10px' }}>{carac.unit}</p>
            </div>
          );
        })}
      </div>
    );
  };

  let nodesAvailables = [];

  allGroups.flatAllotments
    .filter((node) => !node.isObsolete && node.MasterDataEquipmentId)
    .map((node) => {
      if (
        !lotsToRemove.includes(
          findParentNode(node, allGroups.flatAllotments, [], [])[0].name
        )
      ) {
        nodesAvailables = [...nodesAvailables, node];
      }
    });

  return (
    <>
      <div className="equipment-choice-wrapper">
        <div className="equipment-choice-header">
          <div>
            <p>
              {t('APP.EQUIPMENTCHOICE.INDEX.PH_9489C2', {
                defaultValue: 'Nouvelle quantité',
              })}
            </p>
            <TextField
              defaultValue={newAction?.renewedQuantity}
              onChange={(e) =>
                updateExploitationValue('newAction', {
                  ...newAction,
                  newQuantity: e.target.value,
                })
              }
              type="number"
              style={{ width: '80%' }}
              placeholder={t('APP.EQUIPMENTCHOICE.INDEX.PH_650863', {
                defaultValue: 'Quantité',
              })}
              variant="outlined"
              className="input-boarding researchBar"
            />
          </div>
          <div className="md-choice">
            <p>
              {t('APP.EQUIPMENTCHOICE.INDEX.PH_4C0A62', {
                defaultValue: 'Rechercher un équipement',
              })}
            </p>
            <Autocomplete
              value={newAction.renewalEquipment.MasterDataEquipment}
              key={newAction.renewalEquipment.MasterDataEquipment}
              id="checkboxes-tags-demo"
              options={allGroups.type.filter(
                (el) =>
                  el.name &&
                  !el.isObsolete &&
                  nodesAvailables.find(
                    (node) =>
                      node.MasterDataEquipmentId === el.id && !node.isObsolete
                  ) &&
                  (!el.Child || el.Child?.length === 0)
              )}
              noOptionsText={'Aucun résultat trouvé'}
              onChange={(event, newValue) => {
                if (newValue) {
                  let currentNode = allGroups.flatAllotments.find(
                    (node) =>
                      node.MasterDataEquipmentId === newValue.id && !node.isObsolete
                  );

                  updateExploitationValue('newAction', {
                    ...newAction,
                    renewalEquipment: {
                      AllotmentNodes: [currentNode],
                      MasterDataEquipment: newValue,
                      MasterDataEquipmentId: newValue?.id,
                      SheetId: null,
                    },
                  });

                  getFullMd(currentNode);
                }
              }}
              size={'small'}
              getOptionLabel={(option) => option.name || option.equipment || ''}
              renderInput={(params) => (
                <TextField
                  style={{ borderRadius: '10px', width: '100%' }}
                  {...params}
                  placeholder={t('APP.EQUIPMENTCHOICE.INDEX.PH_8FAAF1', {
                    defaultValue: 'Ajouter un équipement',
                  })}
                  variant="outlined"
                  className="researchBar"
                />
              )}
            />
          </div>
          <Popover
            placement="top"
            title={t('APP.EQUIPMENTCHOICE.INDEX.PH_65BBD0', {
              defaultValue: 'Caractéristiques',
            })}
            content={renderCaracPopOver}
            trigger="click"
          >
            <img
              src={caracs}
              style={{
                marginLeft: '10px',
                marginTop: '19px',
                display: 'block',
                filter: checkCaracsValues(newAction)
                  ? 'invert(21%) sepia(52%) saturate(5667%) hue-rotate(354deg) brightness(88%) contrast(133%)'
                  : undefined,
              }}
              alt=""
            />
          </Popover>
          {/* <Button style={{ marginLeft: 'auto', marginTop: '10px', marginRight:'2em' }} onClick={() => setShow(true)}  shape="round">Aucun produit bas carbone ne correspond au besoin</Button> */}
        </div>
        <div className="equipment-choice-core">
          {loadGraph && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '45vh',
                width: '99%',
              }}
            >
              <Loader
                variant="indeterminate"
                disableShrink
                style={{ color: '#73b7ec' }}
                size={60}
                thickness={4}
              />
            </div>
          )}

          {exploitationSheets && !loadGraph && (
            <>
              <div className="graph">
                <LineProductGraph
                  onEquipmentChange={onLineChartEquipmentChangeCallback}
                  onLockedEquipmentChange={onLineChartEquipmentChangeCallback}
                  filters={{
                    filters,
                  }}
                  scope={scope}
                  currentIndicator={currentIndicator}
                  currentProject={currentProject}
                  allGroups={allGroups.type}
                  ctx={ctx}
                  updateFormValue={updateExploitationValue}
                  selectedEquipment={newAction.renewalEquipment.SheetId}
                  lockedSelectedEquipment={newAction.renewalEquipment.SheetId}
                  data={exploitationSheets}
                  projectDdv={currentProject.ddv}
                  isUsageComputed={currentProject.isUsageComputed}
                  masterDataEquipment={
                    newAction.renewalEquipment.MasterDataEquipment
                  }
                  exploitation={true}
                />
              </div>
              {/* <EquipmentSheet
              sheets={sheets}
              // updateNewInies={updateNewInies}
              updateFormValue={updateExploitationValue}
              onEquipmentSelect={onLineChartEquipmentChangeCallback}
              equipment={newAction.renewalEquipment.SheetId}
              lockedSelectedEquipment={newAction.renewalEquipment.SheetId}
              project={currentProject}
              // decMds={decMds}
              // fetchProjectById={fetchProjectById}
              setSelectedEquipment={onLineChartEquipmentChangeCallback}
              loadingGraph={loadInfosSheet}
              exploitation={newAction}
              createAction={createAction}
              /> */}
              <EquipmentDatas
                currentMd={currentMd}
                // updateNewInies={updateNewInies}
                allGroups={allGroups}
                updateFormValue={updateExploitationValue}
                onEquipmentSelect={onLineChartEquipmentChangeCallback}
                equipment={newAction.renewalEquipment.SheetId}
                lockedSelectedEquipment={newAction.renewalEquipment.SheetId}
                currentProject={currentProject}
                currentIndicator={currentIndicator}
                searchValue={searchValue}
                parsedZones={zones}
                graphData={graphData}
                currentAllotment={currentAllotment}
                filteredLifeCycle={filteredLifeCycle}
                graphView={graphView}
                AppContext={AppContext}
                flatAllotments={allGroups.flatAllotments}
                Api={apiNooco}
                AntTabs={AntTabs}
                AntTab={AntTab}
                projectSheets={sheets}
                sheets={sheets}
                exploitationSheets={exploitationSheets}
                saveFiltersBrand={saveFiltersBrand}
                filters={filters}
                NoocoCheckbox={NoocoCheckbox}
                // decMds={decMds}
                // fetchProjectById={fetchProjectById}
                setSelectedEquipment={onLineChartEquipmentChangeCallback}
                loadingGraph={loadInfosSheet}
                exploitation={newAction}
                createAction={createAction}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EquipmentChoice;
