export const addEquipsFromJson = (
  zone,
  equipmentsArray,
  zoneId,
  fluidsArray,
  zoneName,
  buildingName,
  equipId,
  isPlot = false
) => {
  const generalData = {
    zoneId: String(zoneId),
    zoneName: zoneName,
    buildingName: buildingName,
    isPlot: isPlot,
  };

  const componentList = zone.jsonContributors.find(
    (list) => list.type === 'ContributeurComposant'
  ).c;
  const energyList = zone.jsonContributors.find(
    (list) => list.type === 'ContributeurEnergie'
  ).c;
  const waterList = zone.jsonContributors.find(
    (list) => list.type === 'ContributeurEau'
  ).c;
  const buildingSiteList = zone.jsonContributors.find(
    (list) => list.type === 'ContributeurChantier'
  ).c;

  if (componentList && componentList.length > 0) {
    addEquipments(equipmentsArray, componentList, equipId, generalData);
  }

  if (energyList && energyList.length > 0) {
    addEnergyEquips(equipmentsArray, energyList, equipId, generalData);
  }

  if (waterList && waterList.length > 0) {
    addWaterEquips(equipmentsArray, waterList, equipId, generalData);
  }

  if (buildingSiteList && buildingSiteList.length > 0) {
    addChantierEquips(equipmentsArray, buildingSiteList, equipId, generalData);
  }

  return [{}, fluidsArray, equipmentsArray];
};

export const addEquipsFromRSEE = (
  zone,
  equipsArray,
  zoneId,
  fluidsArray,
  zoneName,
  buildingName,
  equipId,
  isEpcm,
  isPlot = false
) => {
  for (let [key, value] of Object.entries(zone.contributeur)) {
    if (isEpcm) key = getEpcmProperKey(value);

    if (key === 'energie') continue;

    let isChantier = key === 'chantier';

    addEquipmentPropertiesRecursive(
      value,
      equipsArray,
      zoneId,
      fluidsArray,
      zoneName,
      buildingName,
      equipId,
      isChantier,
      key,
      isEpcm,
      null,
      isPlot
    );
  }

  const [linkedFluids, updatedFluidsArray] = linkFluids(equipsArray, fluidsArray);

  return [linkedFluids, updatedFluidsArray];
};

const getEpcmProperKey = (object) => {
  switch (object._attributes.ref) {
    case '2':
      return 'energie';
    case '3':
      return 'eau';
    case '4':
      return 'chantier';
    default:
      return 'composant';
  }
};

function hasProperty(obj, propName) {
  return Object.prototype.hasOwnProperty.call(obj, propName);
}

function addEquipmentPropertiesRecursive(
  object,
  result,
  zoneId,
  fluidsArray,
  zoneName,
  buildingName,
  equipId,
  isChantier,
  re2020LotKey,
  isEpcm,
  epcmLot,
  isPlot
) {
  let key;

  if (hasProperty(object, 'donnees_composant')) {
    key = 'donnees_composant';
  } else if (hasProperty(object, 'donnees_service')) {
    key = 'donnees_service';
  } else if (isEpcm && object.sous_lot) {
    epcmLot = object._attributes.ref;
  }

  if (key) {
    try {
      const donneesComposant = Array.isArray(object[key])
        ? object[key]
        : [object[key]];

      for (let donnee of donneesComposant) {
        if (!donnee) continue;

        const re2020Lot = extractRe2020Lot(
          isPlot,
          isEpcm,
          epcmLot,
          object,
          re2020LotKey,
          donnee
        );

        if (
          isEpcm
            ? epcmLot == null && object._attributes?.ref == '14'
            : object._attributes?.ref == '8.7'
        )
          fluidsArray.push({
            ...donnee,
            zoneId,
            zoneName,
            buildingName,
            id: equipId.id,
            re2020Lot,
            isChantier,
          });
        else
          result.push({
            ...donnee,
            zoneId,
            zoneName,
            buildingName,
            id: equipId.id,
            re2020Lot,
            isChantier,
            isPlot,
          });

        equipId.id += 1;
      }
    } catch (error) {
      console.log(
        `Error trying to exctract equipment properties from xml : ${error}`
      );
    }
  }

  for (let i = 0; i < Object.keys(object).length; i++) {
    if (typeof object[Object.keys(object)[i]] == 'object')
      addEquipmentPropertiesRecursive(
        object[Object.keys(object)[i]],
        result,
        zoneId,
        fluidsArray,
        zoneName,
        buildingName,
        equipId,
        isChantier,
        re2020LotKey,
        isEpcm,
        epcmLot,
        isPlot
      );
  }
}

const extractRe2020Lot = (isPlot, isEpcm, epcmLot, object, re2020LotKey, donnee) => {
  let re2020Lot = null;

  if (!isPlot) {
    if (isEpcm) {
      if (epcmLot) {
        re2020Lot = epcmLot + '.' + object._attributes.ref;
      } else {
        re2020Lot =
          object._attributes.ref + '.' + (object._attributes.ref != '13' ? '0' : '');
      }
    } else {
      re2020Lot = object._attributes.ref;
    }

    if (re2020LotKey === 'eau' || re2020LotKey === 'energie')
      re2020Lot = `${re2020LotKey};${
        isEpcm
          ? re2020LotKey === 'eau'
            ? donnee.eau_type._text
            : donnee.energie_usage._text
          : re2020Lot
      }`;
  }

  return re2020Lot;
};

const linkFluids = (equipsArray, fluidsArray) => {
  let linkedFluids = {};
  const forfaitsToAddFluidsTo = ['8.0', '8.1'];
  const typeDonneeForfait = '6';

  if (fluidsArray.length !== 0) {
    const forfaits = equipsArray.filter(
      (equip) =>
        forfaitsToAddFluidsTo.includes(equip.re2020Lot) &&
        equip.type_donnees._text === typeDonneeForfait
    );

    if (forfaits.length !== 0) {
      for (const forfait of forfaits) {
        const matchingFluids = fluidsArray.filter(
          (fluid) => fluid.zoneId === forfait.zoneId
        );

        if (matchingFluids.length !== 0) {
          const maxQuantityFluid = matchingFluids.reduce((prev, current) =>
            Number(prev.quantite._text) > Number(current.quantite._text)
              ? prev
              : current
          );
          const linkedFluidKey = String(
            maxQuantityFluid.zoneId + '-' + maxQuantityFluid.id
          );
          const fluidsIdsToRemove = matchingFluids.map((fluid) => fluid.id);

          linkedFluids[linkedFluidKey] = {
            equip: forfait,
            fluid: maxQuantityFluid,
          };
          fluidsArray = fluidsArray.filter(
            (fluid) => !fluidsIdsToRemove.includes(fluid.id)
          );
        }
      }
    }
  }

  return [linkedFluids, fluidsArray];
};

function fetchEquipmentsArray(componentsList, typeList) {
  componentsList = Array.isArray(componentsList) ? componentsList : [componentsList];

  while (!typeList.includes(componentsList[0].type)) {
    componentsList = componentsList.flatMap((component) => component.c);

    if (!componentsList[0] || !componentsList[0].type) {
      return [];
    }
  }

  if (!typeList.includes(componentsList[0].type)) {
    return [];
  }

  return componentsList;
}

const addEquipments = (equipmentsArray, componentsList, equipId, generalData) => {
  const importedEquipmentsArray = fetchEquipmentsArray(componentsList, [
    'Composant',
  ]);

  for (let importEquipment of importedEquipmentsArray) {
    try {
      if (!importEquipment.quantite_obj.valeur) continue;

      const newEquipment = {
        id_base: { _text: String(importEquipment.idBase) },
        id_fiche: { _text: String(importEquipment.idFicheBase) },
        nom: { _text: String(importEquipment.nom) },
        quantite: { _text: String(importEquipment.quantite_obj.valeur) },
        dve: { _text: String(importEquipment.dve) },
        id: equipId.id,
        isChantier: false,
        ...generalData,
      };

      if (importEquipment.tag) {
        newEquipment.re2020Lot = String(importEquipment.tag.replace('LOT_', ''));
      }

      equipmentsArray.push(newEquipment);
      equipId.id += 1;
    } catch (error) {
      console.error('Error trying to add equipment ', importEquipment);
      console.error('Error is ', error);
    }
  }
};

const addChantierEquips = (
  equipmentsArray,
  importedEquips,
  equipId,
  generalData
) => {
  const importedBuildingSiteArray = fetchEquipmentsArray(importedEquips, [
    'PosteEau',
    'PosteTransport',
    'PosteEnergie',
    'PosteDechet',
  ]);
  for (let importEquip of importedBuildingSiteArray) {
    try {
      if (!importEquip.quantite_obj.valeur) continue;

      const newEquipment = {
        id_base: { _text: String(importEquip.idBase) },
        id_fiche: { _text: String(importEquip.idFicheBase) },
        nom: { _text: String(importEquip.nom) },
        quantite: { _text: String(importEquip.quantite_obj.valeur) },
        re2020Lot: `eau;${importEquip.typePoste}`,
        id: equipId.id,
        isChantier: true,
        ...generalData,
      };

      equipmentsArray.push(newEquipment);
      equipId.id += 1;
    } catch (error) {
      console.error('Error trying to add energy ', importEquip);
      console.error('Error is ', error);
    }
  }
};

const addWaterEquips = (equipmentsArray, waterList, equipId, generalData) => {
  const importedWaterArray = fetchEquipmentsArray(waterList, ['PosteEau']);
  for (let importWater of importedWaterArray) {
    try {
      if (!importWater.quantite_obj.valeur) continue;

      const newEquipment = {
        id_base: { _text: String(importWater.idBase) },
        id_fiche: { _text: String(importWater.idFicheBase) },
        nom: { _text: String(importWater.nom) },
        quantite: { _text: String(importWater.quantite_obj.valeur) },
        re2020Lot: `eau;${importWater.typePoste}`,
        id: equipId.id,
        isChantier: false,
        ...generalData,
      };

      equipmentsArray.push(newEquipment);
      equipId.id += 1;
    } catch (error) {
      console.error('Error trying to add energy ', importWater);
      console.error('Error is ', error);
    }
  }
};

const addEnergyEquips = (equipmentsArray, energyList, equipId, generalData) => {
  const importedEnergyArray = fetchEquipmentsArray(energyList, ['PosteEnergie']);
  for (let importEnergy of importedEnergyArray) {
    try {
      if (!importEnergy.quantite_obj.valeur) continue;

      const newEquipment = {
        id_base: { _text: String(importEnergy.idBase) },
        id_fiche: { _text: String(importEnergy.idFicheBase) },
        nom: { _text: String(importEnergy.nom) },
        quantite: { _text: String(importEnergy.quantite_obj.valeur) },
        re2020Lot: `energie;${importEnergy.typeEnergie}`,
        id: equipId.id,
        isChantier: false,
        ...generalData,
      };

      equipmentsArray.push(newEquipment);
      equipId.id += 1;
    } catch (error) {
      console.error('Error trying to add energy ', importEnergy);
      console.error('Error is ', error);
    }
  }
};

export const extractForfaits = (equipmentsArray) => {
  const lotNames = ['8.1', '8.0'];
  const typeDonnees = ['6'];
  const matchingNameEpcm = 'Lot forfaitaire';
  const matchingNameRe2020 = 'Sous-lot forfaitaire';
  const forfaitsIdInies = [8323, 8319, 8314, 27577, 27571, 26718, 30327];

  const forfaits = equipmentsArray.filter((equip) => {
    return (
      lotNames.includes(equip.re2020Lot) &&
      (typeDonnees.includes(equip.type_donnees._text) ||
        equip.nom._text.toLowerCase().includes(matchingNameEpcm.toLowerCase()) ||
        equip.nom._text.toLowerCase().includes(matchingNameRe2020.toLowerCase()) ||
        forfaitsIdInies.includes(Number(equip.id_fiche._text)))
    );
  });

  return forfaits;
};
