import 'moment/locale/fr';
import {
  CREATE_ACTION,
  CREATE_NEW_EQUIPMENT,
  FETCH_BOARDING,
  FETCH_LIST_ACTIONS,
  FETCH_SHEETS,
  GET_FULL_MD,
  INCREMENT_OFFSET,
  LOAD_BOARDING,
  LOAD_LIST_ACTIONS,
  LOAD_NEW_ACTION,
  LOAD_NEW_EQUIPMENT,
  LOAD_SHEETS,
  RESET_ADD_ACTION,
  RESET_EXPLOITATION,
  RESET_NEW_EQUIPMENT,
  SEARCH_LIST_ACTIONS,
  UPDATE_EXPLOITATION_VALUE,
} from '../actions/exploitation';
import { LOAD_ALL_GROUPS } from '../actions/projects';
import dayjs from 'dayjs';

import {
  renderDetailsAction,
  returnNameAction,
} from '../app/exploitation/ListActions';
import _ from 'lodash';
import formatNumber from '../app/services/mathematics/formatNumber';

// TODO_TRAD: fr
const initialState = {
  boardingListDatas: [],
  scope: { x: { min: 0, max: 'auto' }, y: { min: 0, max: 'auto' } },
  newEquipment: {
    MasterDataEquipment: null,
    quantity: null,
    caracs: [],
  },
  newEquipmentQuantity: null,
  newEquipmentState: null,
  displayScreen: 'list',
  addAction: null,
  controlChoice: null,
  offset: 0,
  newAction: {},
  listActionDatas: [],
  filteredListActionsDatas: null,
  listActionsInput: '',
  actionOffset: 0,
  loadGraph: false,
  loadInfosSheet: false,
  isBoardingFull: false,
  loadingBoarding: false,
  isActionsFull: false,
  loadingActions: false,
  eventTypes: null,
  selectedRowKeys: [],
  openAlert: false,
  garbageLines: [
    {
      id: 0,
      MasterDataEquipmentId: null,
      quantity: null,
      treatment: null,
      actionType: 'garbage',
    },
  ],

  boardingActiveTab: 'Equipement / matériau',
  actionActiveTab: 'Projet',
  reportActiveTab: 'Période',
  transportLines: [
    {
      id: 0,
      MasterDataEquipmentId: null,
      quantity: null,
      type: null,
      carburant: null,
      actionType: 'transport',
    },
  ],
  actionsModelsGraphData: null,
  simplifiedEnergyLines: [
    {
      id: 0,
      AllotmentNodeId: null,
      quantity: null,
      caracs: [],
      unit: 'kWh',
      actionType: 'energy',
    },
  ],
};

function exploitationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_EXPLOITATION:
      return {
        ...state,
        boardingListDatas: [],
        newEquipment: {
          MasterDataEquipment: null,
          quantity: null,
          caracs: [],
        },
        newEquipmentQuantity: null,
        newEquipmentState: null,
        displayScreen: 'list',
        addAction: null,
        controlChoice: null,
        offset: 0,
        newAction: {},
        listActionDatas: [],
        filteredListActionsDatas: null,
        listActionsInput: '',
        actionOffset: 0,
        loadGraph: false,
        loadInfosSheet: false,
        isBoardingFull: false,
        loadingBoarding: false,
        isActionsFull: false,
        loadingActions: false,
        selectedRowKeys: [],
        openAlert: false,
        garbageLines: [
          {
            id: 0,
            MasterDataEquipmentId: null,
            quantity: null,
            treatment: null,
            actionType: 'garbage',
          },
        ],
        boardingActiveTab: 'Equipement / matériau',
        reportActiveTab: 'Période',
        transportLines: [
          {
            id: 0,
            MasterDataEquipmentId: null,
            quantity: null,
            type: null,
            carburant: null,
            actionType: 'transport',
          },
        ],
        actionsModelsGraphData: null,
        selectedActionKeys: [],
      };
    case LOAD_ALL_GROUPS:
      return {
        ...state,
        eventTypes: action.response.data.eventTypes,
      };
    case UPDATE_EXPLOITATION_VALUE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case FETCH_BOARDING:
      return {
        ...state,
        loadingBoarding: true,
      };
    case LOAD_BOARDING:
      return {
        ...state,
        boardingListDatas: [
          ...new Set([...state.boardingListDatas, ...action.data]),
        ],
        isBoardingFull: action.data.length < 20,
        offset: state.offset + 20,
        loadingBoarding: false,
      };
    case RESET_ADD_ACTION:
      return {
        ...state,
        newAction: {},
        controlChoice: null,
        addAction: null,
        transportChoice: null,
        sheets: null,
      };
    case CREATE_NEW_EQUIPMENT:
      return {
        ...state,
        loadingBoarding: true,
      };
    case LOAD_NEW_EQUIPMENT:
      return {
        ...state,
        boardingListDatas: [...state.boardingListDatas, action.data.newEquipment],
        newEquipment: {
          MasterDataEquipment: null,
          quantity: null,
          state: null,
          caracs: [],
        },
        newEquipmentQuantity: null,
        newEquipmentState: null,
        loadingBoarding: false,
      };
    case LOAD_LIST_ACTIONS: {
      let datas = action.data.map((record) => {
        let eventType = null;

        if (state.eventTypes) {
          eventType = state.eventTypes.find((el) => el.id === record.EventTypeId);
        }

        return {
          ...record,
          date: dayjs(record.createdAt).format('L'),
          actionType: returnNameAction(eventType.name, action.t),
          quantity: `${record.Target?.quantity} ${record.Target?.Unit?.name}`,
          detail: Array.isArray(
            renderDetailsAction(eventType.name, record, action.t).props.children
          )
            ? renderDetailsAction(
                eventType.name,
                record,
                action.t
              ).props.children?.join()
            : renderDetailsAction(eventType.name, record, action.t).props.children,
          impact: `${formatNumber(record.carbonImpact)} kg éq. CO₂`,
        };
      });

      return {
        ...state,
        listActionDatas: [...state.listActionDatas, ...datas],
        isActionsFull: action.data.length < 20,
        actionOffset: state.actionOffset + 20,
        loadingActions: false,
      };
    }
    case GET_FULL_MD:
      return {
        ...state,
        loadGraph: true,
      };
    case LOAD_SHEETS:
      return {
        ...state,
        loadGraph: false,
        sheets: action.sheets,
      };
    case FETCH_LIST_ACTIONS:
      return {
        ...state,
        loadingActions: true,
      };
    case CREATE_ACTION:
      return {
        ...state,
        loadingActions: true,
      };
    case LOAD_NEW_ACTION: {
      let datas = action.list.map((record) => {
        let eventType = null;

        if (state.eventTypes) {
          eventType = state.eventTypes.find((el) => el.id === record.EventTypeId);
        }

        return {
          ...record,
          date: dayjs(record.createdAt).format('L'),
          actionType: returnNameAction(eventType.name, action.t),
          quantity: `${record.Target?.quantity} ${record.Target?.Unit?.name}`,
          detail: Array.isArray(
            renderDetailsAction(eventType.name, record, action.t).props.children
          )
            ? renderDetailsAction(
                eventType.name,
                record,
                action.t
              ).props.children?.join()
            : renderDetailsAction(eventType.name, record, action.t).props.children,
          impact: `${formatNumber(record.carbonImpact)} kg éq. CO₂`,
        };
      });

      return {
        ...state,
        listActionDatas: datas,
        newAction: {},
        controlChoice: null,
        addAction: null,
        transportChoice: null,
        sheets: null,
        loadingActions: false,
        displayScreen: 'list',
        garbageLines: [
          {
            id: 0,
            MasterDataEquipmentId: null,
            quantity: null,
            treatment: null,
            actionType: 'garbage',
          },
        ],
      };
    }

    case SEARCH_LIST_ACTIONS: {
      let fullDatas = _.cloneDeep(state.listActionDatas);

      if (state.listActionsInput.length > 0) {
        let results = [];

        for (let i = 0; i < fullDatas.length; i++) {
          for (const [key, value] of Object.entries(fullDatas[i])) {
            if (
              value &&
              ((typeof value === 'number' &&
                (formatNumber(value).includes(state.listActionsInput) ||
                  value.toString().includes(state.listActionsInput))) ||
                (typeof value === 'string' &&
                  value
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .includes(
                      state.listActionsInput
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase()
                    )))
            ) {
              if (!results.find((el) => el.id === fullDatas[i].id)) {
                results = [...results, fullDatas[i]];
              }
            }
          }
        }

        return {
          ...state,
          filteredListActionsDatas: results,
        };
      } else {
        return {
          ...state,
          filteredListActionsDatas: null,
        };
      }
    }
    case INCREMENT_OFFSET:
      return {
        ...state,
        offset: state.offset + 20,
      };
    case RESET_NEW_EQUIPMENT:
      return {
        ...state,
        newEquipment: {
          MasterDataEquipment: null,
          quantity: null,
          caracs: [],
        },
        newEquipmentQuantity: null,
        newEquipmentState: null,
      };
    default:
      return state;
  }
}

export default exploitationReducer;
