/* eslint-disable function-call-argument-newline */
import './help.scss';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { updateFormValue } from '../../actions/projects';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Faq from './Faq';
import FeaturePage from './FeaturePage';
import Memo from './Memo';
import React, { useEffect, useState } from 'react';
import Release from './Release';

const Help = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    dispatch(updateFormValue('isMenuOpen', false));
  }, []);

  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [
    {
      name: 'feature',
      label: t('APP.HELP.HELP.PH_D0E798', { defaultValue: 'Fonctionnalités' }),
      testId: 'featureTab',
      tooltip: t('APP.HELP.HELP.PH_E33195', {
        defaultValue:
          'Retrouver les descriptions des fonctionnalités disponible dans Nooco',
      }),
      index: 0,
      route: ['/help/feature', '/help'],
      component: FeaturePage,
    },
    {
      name: 'release',
      label: t('APP.HELP.HELP.PH_179C5B', { defaultValue: 'Notes de version' }),
      testId: 'releaseTab',
      tooltip: t('APP.HELP.HELP.PH_F2A7CA', {
        defaultValue:
          'Retrouver les descriptions des nouveautés de la plateforme à chaque mise à jour de celle-ci',
      }),
      index: 1,
      route: ['/help/release'],
      component: Release,
    },
    {
      name: 'faq',
      testId: 'faqTab',
      label: t('APP.HELP.HELP.PH_B7BD87', { defaultValue: 'FAQ' }),
      tooltip: t('APP.HELP.HELP.PH_07B8F4', {
        defaultValue: 'Foire Aux Questions',
      }),
      index: 2,
      route: ['/help/faq'],
      component: Faq,
    },
    {
      name: 'startupguide',
      testId: 'startUpGuideTab',
      label: t('APP.HELP.HELP.PH_2CE0FA', {
        defaultValue: 'Guide de démarrage',
      }),
      tooltip: t('APP.HELP.HELP.PH_C72813', {
        defaultValue: "Se familiariser à l'utilisation de Nooco",
      }),
      index: 3,
      route: ['/help/startupguide'],
      component: Memo,
    },
  ];

  const renderMenu = tabs.map((tab) => {
    const actualPathname = location.pathname;
    const route = tab.route[0];
    const isSelected = tab.route.includes(actualPathname);

    return (
      <Tooltip key={tab.name} title={tab.tooltip}>
        <div
          key={tab}
          data-testid={tab.testId}
          className={
            isSelected ? 'selected-tab-button button-text' : 'tab-button button-text'
          }
          onClick={() => history.push(route)}
        >
          {tab.label}
        </div>
      </Tooltip>
    );
  });

  const renderRoute = tabs.map((tab) => {
    return <Route key={tab.name} exact path={tab.route} component={tab.component} />;
  });

  const renderBody = () => {
    const component = tabs.find((tab) => tab.index === tabIndex);

    return component.component;
  };

  return (
    <div className={'background'}>
      <div className="main-container">
        <div className="tab-menu-container">{renderMenu}</div>
        <div className={'body-container'}>{renderRoute}</div>
      </div>
    </div>
  );
};

export default Help;
