export const OPEN_FILTER_GRAPH = 'OPEN_FILTER_GRAPH';
export const OPEN_BRAND = 'OPEN_BRAND';
export const OPEN_PLACE = 'OPEN_PLACE';
export const OPEN_CARAC = 'OPEN_CARAC';
export const OPEN_DISCRETE = 'OPEN_DISCRETE';
export const OPEN_SECOND_DISCRETE = 'OPEN_SECOND_DISCRETE';
export const SAVE_FILTERS_BRAND = 'SAVE_FILTERS_BRAND';
export const SAVE_SHEETS = 'SAVE_SHEETS';
export const SET_CURRENT_MD = 'SET_CURRENT_MD';
export const SET_LOADING_GRAPH = 'SET_LOADING_GRAPH';
export const SET_NEW_SHEETS = 'SET_NEW_SHEETS';
export const OPEN_FILTRABLE_LIST = 'OPEN_FILTRABLE_LIST';
export const RESET_FILTERS = 'RESET_FILTERS';

export const openFilterGraph = () => ({
  type: OPEN_FILTER_GRAPH,
});

export const openBrand = () => ({
  type: OPEN_BRAND,
});

export const openPlace = () => ({
  type: OPEN_PLACE,
});

export const openCarac = () => ({
  type: OPEN_CARAC,
});

export const openDiscrete = () => ({
  type: OPEN_DISCRETE,
});

export const openSecondDiscrete = () => ({
  type: OPEN_SECOND_DISCRETE,
});

export const saveFiltersBrand = (filters, open, open2) => ({
  type: SAVE_FILTERS_BRAND,
  filters,
  open,
  open2,
});

export const saveSheets = (sheets, md) => ({
  type: SAVE_SHEETS,
  sheets,
  md,
});

export const setCurrentMd = (md) => ({
  type: SET_CURRENT_MD,
  md,
});

export const setLoadingGraph = () => ({
  type: SET_LOADING_GRAPH,
});

export const setNewSheets = (sheets, md) => ({
  type: SET_NEW_SHEETS,
  sheets,
  md,
});

export const openFiltrableList = (name) => ({
  type: OPEN_FILTRABLE_LIST,
  name,
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
});
