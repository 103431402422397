import {
  ALLOTMENT_IDS,
  REFERENCE_TYPE_NAME,
  TYPE_EPCM,
} from '../globalVariable/typeProject';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const InputSousLotRe2020 = ({
  sousLotOptions,
  inputSousLot,
  changeValueEquipments,
  searchValue,
  allGroups,
  addEquipment,
  composantVideForm,
  currentEquipment,
  setIsEditing,
  disabled,
  currentMd,
  importScreen,
  handleEdit,
  line,
  currentProject,
}) => {
  let current = searchValue || currentEquipment;

  const { t } = useTranslation();

  const { nodeSearchValue, currentAllotment } = useSelector(
    (state) => state.projects
  );
  const { iniesAdvanceLink } = useSelector((state) => state.equipments);

  let referenceName =
    currentProject.templateType !== TYPE_EPCM
      ? REFERENCE_TYPE_NAME.RE2020
      : REFERENCE_TYPE_NAME.EPCM;

  let reference = allGroups.allotments.find(
    (el) => el.name.toLowerCase() === referenceName.toLowerCase()
  );

  const CustomPaper = (props) => {
    return <Paper style={{ width: '250px' }} {...props} />;
  };

  Array.prototype.sortedNodes = function () {
    const regex = /^\d+/;
    const nonNumericOptions = this.filter((option) => !regex.test(option.name)).sort(
      (a, b) => a.name.localeCompare(b.name)
    );
    const numericOptions = this.filter((option) => regex.test(option.name)).sort(
      (a, b) => {
        const numA = parseFloat(a.name);
        const numB = parseFloat(b.name);

        if (numA < numB) {
          return -1;
        }
        if (numA > numB) {
          return 1;
        }

        return 0;
      }
    );

    return [...numericOptions, ...nonNumericOptions];
  };

  let nodeReference =
    iniesAdvanceLink && currentAllotment.id === 2
      ? nodeSearchValue
      : currentAllotment.id === 2
      ? allGroups.flatAllotments.find(
          (el) => el.id === nodeSearchValue?.Re2020AllotmentNodeId
        )
      : allGroups.flatAllotments.find(
          (el) => el.id === nodeSearchValue?.EpcmAllotmentNodeId
        );

  return (
    <div style={{ padding: addEquipment ? '0 1em' : null }}>
      <Autocomplete
        id="checkboxes-tags-demo"
        freeSolo={importScreen ? true : false}
        openOnFocus
        key={!importScreen ? nodeSearchValue : null}
        defaultValue={
          importScreen
            ? null
            : currentEquipment
            ? currentEquipment.ProjectSheet.AllotmentNodes.find(
                (el) => el.AllotmentId === reference.id
              )
            : nodeReference
        }
        inputValue={inputSousLot}
        options={reference?.AllotmentNodes?.filter(
          (el) => el.isReSousLot
        ).sortedNodes()}
        PaperComponent={CustomPaper}
        disabled={disabled}
        onChange={(event, newValue) => {
          if (setIsEditing) {
            setIsEditing(true);
          }

          if (newValue) {
            importScreen
              ? handleEdit('re2020lot', line.id, null, newValue)
              : addEquipment || currentMd
              ? changeValueEquipments('re2020lot', newValue)
              : changeValueEquipments('composantVideForm', {
                  ...composantVideForm,
                  re2020lot: newValue,
                });
          } else if (!newValue && importScreen) {
            handleEdit('re2020lot', line.id, null, null);
          }
        }}
        size={'small'}
        onInputChange={(event, newInputValue) => {
          changeValueEquipments('inputSousLot', newInputValue);
        }}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
            style={
              importScreen
                ? { background: 'transparent', width: '55px', height: '20px' }
                : null
            }
            {...params}
            placeholder={
              importScreen
                ? '...'
                : t('APP.COMPOSANTVIDEFORM.INDEX.PH_EB451E', {
                    allotment:
                      currentAllotment.id === ALLOTMENT_IDS.EPCM
                        ? REFERENCE_TYPE_NAME.EPCM
                        : REFERENCE_TYPE_NAME.RE2020,
                    defaultValue: 'Rattachement {allotment}*',
                  })
            }
            variant="outlined"
            className={importScreen ? null : 'researchBar'}
          />
        )}
      />
    </div>
  );
};

export default InputSousLotRe2020;
