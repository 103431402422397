/**
 * Created by nanaa on 26/05/2021
 */

import { connect } from 'react-redux';

import { updateFormValue } from '../../actions/projects';

import { fetchImportDate } from '../../actions/import';
import { openImportModal } from '../../actions/equipments';

import ImportModal from '../../app/projects/EquipmentList/ImportModal';

const mapStateToProps = (state) => ({
  importModal: state.imports.importModal,
  zones: state.projects.parsedProject?.zones,
  currentProject: state.projects.currentProject,
  importLoading: state.imports.importLoading,
  isSuccess: state.projects.isSuccess,
  zoneEquipmentId: state.projects.zoneEquipmentId,
  equipmentBuildingSiteId: state.projects.equipmentBuildingSiteId,
  isReadyToImport: state.imports.isReadyToImport,
  importTabs: state.imports.importTabs,
  importDate: state.imports.importDate,
  workflowImportModal: state.imports.workflowImportModal,
});

const mapDispatchToProps = (dispatch) => ({
  openImportModal: () => {
    dispatch(openImportModal());
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  fetchImport: (id, needAnimation, creationImport) => {
    dispatch(fetchImportDate(id, needAnimation, creationImport));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportModal);
