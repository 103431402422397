import './simulator.scss';
import { SimulatorInformationDTO } from '../models/SimulatorDTO';
import { StateDTO } from '../models/GeneralDTO';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { fetchQuickSimulator, updateSimulator } from '../../actions/simulator';
import { updateFormValue } from '../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import React from 'react';
import SimulatorGraphic from './SimulatorGraphic/SimulatorGraphic';
import SimulatorInformation from './SimulatorInformation/SimulatorInformation';
import SimulatorVariants from './SimulatorVariants/SimulatorVariants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      fontSize: `${window.screen.availHeight < 900 ? '14px' : ''}`,
    },
    // input: {
    //   color: 'red'
    // }
  })
);

const Simulator = () => {
  const { information, firstVariant, secondVariant } = useSelector(
    (state: StateDTO) => state.simulator
  );
  const { licenceTypeAccess } = useSelector((state: StateDTO) => state.projects);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const {
    control,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: { errors, isValid },
    clearErrors,
  } = useForm<SimulatorInformationDTO>({
    mode: 'onChange',
    // TODO ? transform lifespan and area in string when comes from api
    defaultValues: { ...information },
  });

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchQuickSimulator());
    dispatch(updateSimulator('isSimulatorFetchData', true));
    dispatch(updateFormValue('isMenuOpen', false));
  }, []);

  useEffect(() => {
    reset(information);
  }, [information]);

  if (licenceTypeAccess === 'read') {
    history.push('/');

    return null;
  }

  return (
    <>
      <div className="simulatorWrapper">
        {/* <div style={{display: 'flex', flexWrap: 'nowrap'}}> */}
        <section className="simulatorSection information">
          <div className="simulatorSection--title">
            {t('APP.SIMULATOR.SIMULATOR.PH_C161DE', {
              defaultValue: 'Informations générales',
            })}
          </div>
          <div className="simulatorSection--main">
            <SimulatorInformation
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              clearErrors={clearErrors}
              classes={classes}
              handleSubmit={handleSubmit}
            />
          </div>
        </section>
        <section className="simulatorSection graphic">
          <div className="simulatorSection--title">
            {t('APP.SIMULATOR.SIMULATOR.PH_59C732', {
              defaultValue: 'Visualiser',
            })}
          </div>
          <div className="simulatorSection--main">
            <SimulatorGraphic
              isValid={isValid}
              errors={errors}
              handleSubmit={handleSubmit}
              getValues={getValues}
            />
          </div>
        </section>

        {/* </div> */}
        <section className="simulatorSection quantitative">
          {/* <section className="simulator__graphic"> */}
          <div className="simulatorSection--title">
            {t('APP.SIMULATOR.SIMULATOR.PH_914464', {
              defaultValue: 'Quantitatifs',
            })}
          </div>
          <div className="simulatorSection--main--variants">
            <div className="variantsColumn first">
              <div className="firstVariant">
                <SimulatorVariants
                  variantNumber="first"
                  variant={firstVariant}
                  classes={classes}
                />
              </div>
            </div>
            <div className="variantsColumn">
              <div className="secondVariant">
                <SimulatorVariants
                  variantNumber="second"
                  variant={secondVariant}
                  classes={classes}
                />
              </div>
            </div>
          </div>
          {/* </section> */}
        </section>
      </div>
    </>
  );
};

export default Simulator;
