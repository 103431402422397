export enum CREATION_STEPS {
  GLOBAL_MONITORING = 'GLOBAL_MONITORING',
  ECOCONCEPTION = 'ECOCONCEPTION',
  NEW_CONSTRUCTION = 'NEW_CONSTRUCTION',
  RENOVATION_WORK = 'RENOVATION_WORK',
  RE2020 = 'RE2020',
  EPCM = 'EPCM',
  FREE_RENOVATION = 'FREE_RENOVATION',
  EXPLOITATION = 'EXPLOITATION',
  AEROPORT = 'AEROPORT',
  DATACENTER = 'DATACENTER',
  INFRASTRUCTURE = 'INFRASTRUCTURE',
  PROTOTYPE = 'PROTOTYPE',
  BBCA = 'BBCA',
  WORK_AND_INDUSTRY = 'WORK_AND_INDUSTRY',
  BUILDING = 'BUILDING',
}

export enum CREATION_STEPS_TRAD_KEYS {
  GLOBAL_MONITORING = 'APP.CREATIONPROJECT.INDEX.GLOBAL_MONITORING',
  ECOCONCEPTION = 'APP.CREATIONPROJECT.INDEX.PH_059790',
  NEW_CONSTRUCTION = 'APP.CREATIONPROJECT.INDEX.PH_14E10F',
  RENOVATION_WORK = 'APP.CREATIONPROJECT.INDEX.PH_FCFFC8',
  RE2020 = 'APP.CREATIONPROJECT.INDEX.PH_27883E',
  EPCM = 'APP.CREATIONPROJECT.INDEX.PH_370AE6',
  FREE_RENOVATION = 'APP.CREATIONPROJECT.INDEX.FREE_RENOVATION',
  EXPLOITATION = 'APP.CREATIONPROJECT.INDEX.PH_2A2E17',
  AEROPORT = 'APP.CREATIONPROJECT.INDEX.PH_EBFDD5',
  DATACENTER = 'APP.CREATIONPROJECT.INDEX.PH_7512E4',
  INFRASTRUCTURE = 'APP.CREATIONPROJECT.INDEX.PH_2BD3BA',
  PROTOTYPE = 'APP.CREATIONPROJECT.INDEX.PH_78BA05',
  BBCA = 'APP.CREATIONPROJECT.INDEX.BBCA_RENOVATION',
  WORK_AND_INDUSTRY = 'APP.CREATIONPROJECT.INDEX.CONSTRUCTION_AND_INDUSTRY',
  BUILDING = 'APP.CREATIONPROJECT.INDEX.PH_EE0821',
}
