import './accordionLicences.scss';
import { CompanyDTO } from '../../../models/ProjectDTO';
import { StateDTO } from '../../../models/GeneralDTO';
import { fetchCompanies } from '../../../../actions/projects';
import { updateRightsValue } from '../../../../actions/rights';
import { useDispatch, useSelector } from 'react-redux';
import { useEmployeesList } from '../../../../hooks/useQuery/rights/crudEmployees';
import { withStyles } from '@material-ui/core/styles';
import API from '../../../services/API';
import AccordionRawEmployee from './AccordionRawEmployee/AccordionRawEmployee';
import AccordionRawSummary from './AccordionRawSummary/AccordionRawSummary';
import AccordionRawSummarySub from './AccordionRawSummarySub/AccordionRawSummarySub';
import MuiAccordion from '@material-ui/core/Accordion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type AccordionByCompaniesProps = {
  columnSize: {
    expandIcon: string;
    licence: string;
    users: string;
    mails: string;
    company: string;
    admin: string;
    delete: string;
  };
};

const Accordion = withStyles({
  root: {
    marginBottom: 8,
    '&:first-child': {
      marginTop: 8,
      marginBottom: 8,
    },
    transform: 'none!important',
    minHeight: 80,
    boxShadow: 'none',
    '&:not(:last-child)': {},
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginBottom: 8,
      marginTop: 0,
      '&:first-child': {
        marginTop: 8,
        marginBottom: 8,
      },
      minHeight: 80,
    },
  },
  expanded: {},
})(MuiAccordion);

export type requestEmployeesList = {
  groupBy: 'company' | 'licence';
  companyId?: number | null;
  licenceType?: 'read' | 'light' | 'pro' | null;
  limit?: number;
  offset?: number;
  search?: string;
};

const AccordionByCompanies = ({ columnSize }: AccordionByCompaniesProps) => {
  // let api = new API(true);
  // const dispatch= useDispatch();
  const [expandedSummary, setExpandedSummary] = useState('');
  const { t } = useTranslation();
  // const [selectedCompany, setSelectedCompany] = useState<CompanyDTO | null>(null);
  const accordionLevel = 0;
  const color = [' #E4EFFF', '#DDF3FA', '#DDFAF5', '#E4F7FF', '#E4FFF9', '#E8FFE4'];

  const { allCompanies: companies, parentCompanies } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { inputSearch, selectedParentCompany, parentCompaniesFiltered } =
    useSelector((state: StateDTO) => state.rights);

  useEffect(() => {
    // console.log('passage dans le useEffect)');

    let companiesFiltered;

    const parentCompaniesIsAdmin = parentCompanies.filter(
      (parentCompany) => parentCompany.isAdmin === true
    );

    if (inputSearch !== '' && inputSearch !== null) {
      companiesFiltered = parentCompaniesIsAdmin.filter((obj) =>
        obj.name.toLowerCase().includes(inputSearch.toLowerCase())
      );

      // companiesFiltered = companiesFiltered.sort((a, b) => a.name > b.name ? 1 : -1);
      dispatch(updateRightsValue('parentCompaniesFiltered', companiesFiltered));
    } else {
      dispatch(updateRightsValue('parentCompaniesFiltered', parentCompaniesIsAdmin));
    }
  }, [inputSearch]);

  // useEffect(() => {
  //   const parentCompaniesIsAdmin = parentCompanies.filter((parentCompany) => parentCompany.isAdmin === true);

  //   dispatch(updateRightsValue('parentCompaniesFiltered',
  //     parentCompaniesIsAdmin ));
  //   dispatch(updateRightsValue('selectedParentCompany',
  //     parentCompaniesIsAdmin[0]));
  // },
  // []);

  const {
    data: employeesListParent,
    isLoading,
    isError,
  } = useEmployeesList(selectedParentCompany);

  // console.log('refetch');
  // console.log('employeesListParent', employeesListParent);

  const dispatch = useDispatch();

  const handleChangeExpandedSummary = (company) => (event, newExpanded) => {
    // console.log('company',
    //   company);
    // console.log('newExpanded',
    //   newExpanded); // true/false
    // setSelectedCompany(company);
    dispatch(updateRightsValue('selectedParentCompany', company));

    setExpandedSummary(newExpanded ? `panelSummary${company.id}` : '');
  };

  return (
    <div className="accordionLicenceWrapper">
      {parentCompaniesFiltered.map((parentCompany) => {
        return (
          <Accordion
            key={parentCompany.id}
            square
            expanded={expandedSummary === `panelSummary${parentCompany.id}`}
            onChange={handleChangeExpandedSummary(parentCompany)}
          >
            <AccordionRawSummary
              columnSize={columnSize}
              expandedSummary={expandedSummary === `panelSummary${parentCompany.id}`}
              company={parentCompany}
              color={color[accordionLevel]}
              // employeesCount={employeesList?.data?.count}
            />
            {employeesListParent?.data.count > 0 &&
              expandedSummary === `panelSummary${parentCompany.id}` &&
              !isLoading &&
              employeesListParent?.data.rows?.map((employee) => {
                //
                return (
                  <AccordionRawEmployee
                    key={employee.id}
                    columnSize={columnSize}
                    employee={employee}
                    company={parentCompany}
                  />
                );
              })}
            {expandedSummary === `panelSummary${parentCompany.id}` &&
            !isLoading &&
            employeesListParent?.data.count === 0 &&
            companies.filter((company) => company.ParentNodeId === parentCompany.id)
              .length === 0 ? (
              // <AccordionDetails>
              <div
                style={{
                  width: '100%',
                  fontStyle: 'italic',
                  padding: '6px 6rem 0px 6rem',
                }}
              >
                {t('APP.ACCORDION.ACCORDIONBYCOMPANIES.PH_87AEB2', {
                  defaultValue: 'Aucun utilisateur',
                })}
              </div>
            ) : (
              // </AccordionDetails>
              <div style={{ minHeight: '8px' }}></div>
            )}
            {expandedSummary === `panelSummary${parentCompany.id}` && isLoading && (
              <div style={{ minHeight: '0px' }}>
                <div className="spinner-wrapper-licenses">
                  <div className="donut" />
                </div>
              </div>
            )}

            {expandedSummary === `panelSummary${parentCompany.id}` &&
              companies
                .filter((company) => company.ParentNodeId === parentCompany.id)
                .map((company) => {
                  return (
                    <AccordionRawSummarySub
                      key={company.id}
                      columnSize={columnSize}
                      color={color}
                      company={company}
                      parentAccordionLevel={accordionLevel}
                    />
                  );
                })}
          </Accordion>
        );
      })}
    </div>
  );
};

export default AccordionByCompanies;
