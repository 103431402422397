import {
  AFTER_DELETE_ROWS,
  CHANGE_IMPORT_DATAS,
  CLOSE_VALID_ALERT,
  CONFIRM_DELETE_IMPORT,
  DELETE_ROWS_IMPORT,
  DUPLICATE_LINES,
  FETCH_IMPORT_DATAS,
  FETCH_MORE_IMPORT_DATAS,
  FILTER_IMPORT_LIST,
  GET_STATS_IMPORT,
  LOAD_IMPORT_DATAS,
  LOAD_IMPORT_DATE,
  LOAD_SEARCH_DATAS,
  OPEN_MORE_OPTIONS,
  OPEN_VALID_ALERT,
  REFRESH_DATA_AFTER_IMPORT,
  RESEARCH_SOURCE_NAME,
  RESET_DATA_AFTER_FILTER_CHANGED,
  RESET_IMPORT_STATES,
  RETRACT_IMPORT,
  SEND_IMPORT_EDIT,
  SET_DATA_ERROR,
  SET_LOADING_IMPORT,
  UPDATE_IMPORT_LINE,
  VERIFY_ROWS_EQUIPMENT,
} from '../actions/import';
import { OPEN_IMPORT_MODAL } from '../actions/equipments';
import { UPDATE_FORM_VALUE } from '../actions/projects';

import _ from 'lodash';

import { TYPE_MAPPINGREF } from '../app/globalVariable/typeProject';
import {
  checkStatusLine,
  createSetElems,
  filterImportDatas,
  handleAllotmentNodes,
  sortById,
} from '../app/shared/utils';
import { getFlattenImportRows } from '../app/projects/Import/ImportFunction';
import { klona } from 'klona';

const getAllAllotmentNodesRelatedToLineMasterData = (
  line,
  allGroups,
  currentAllotment,
  currentProject
) => {
  let currentMd = allGroups.type.find(
    (el) => el.id === line.AllotmentNode?.MasterDataEquipmentId
  );
  let allAllotmentNodesRelatedToLineMasterData = [];

  if (line.AllotmentNode) {
    allAllotmentNodesRelatedToLineMasterData = handleAllotmentNodes(
      currentMd,
      currentAllotment,
      allGroups.flatAllotments,
      currentProject
    )?.filter((allotmentNode) => !allotmentNode.isObsolete);
  }

  return { allAllotmentNodesRelatedToLineMasterData, currentMd };
};

export const assignAllotmentNodesToLines = (
  lines,
  allGroups,
  currentAllotment,
  currentProject
) => {
  lines.forEach((line) => {
    const { allAllotmentNodesRelatedToLineMasterData, currentMd } =
      getAllAllotmentNodesRelatedToLineMasterData(
        line,
        allGroups,
        currentAllotment,
        currentProject
      );

    line.currentMd = currentMd;
    line.allAllotmentNodesRelatedToLineMasterData =
      allAllotmentNodesRelatedToLineMasterData;
  });

  return lines;
};

export const assignAiMatchesSorted = (lines, nodesAvailableForProjectType) => {
  lines.forEach((line) => {
    let datas =
      line.ProjectImportElemMatches?.map((match) => {
        let node = nodesAvailableForProjectType.find(
          (el) => el.id === match.AllotmentNodeId
        );

        return { ...match, ...node };
      }) || [];

    datas = [
      ...datas,
      ...nodesAvailableForProjectType.filter(
        (el) => !datas.find((match) => match.id === el.id)
      ),
    ];

    line.OptionSortedByAI = datas.filter(
      (elem, index, self) => index === self.findIndex((t) => t.name === elem.name)
    );
  });
};

const initialState = {
  importDate: [],
  importDetails: {},
  importDatas: [],
  editImport: [],
  badLines: undefined,
  goodLines: undefined,
  error: false,
  isImportLoading: true,
  dataError: [],
  isRetracted: false,
  isLoad: false,
  isLoadingPatchLine: false,
  moreOptionLines: [],
  openValid: false,
  filterOn: [],
  isLoadMoreImports: false,
  isFullLoad: false,
  importPepFdes: [],
  waitLines: undefined,
  notProcessed: undefined,
  totalProcessed: undefined,
  executionId: null,
  stepsOfImport: [
    {
      id: 1,
      name: "Début de l'import",
      isActive: false,
      isComplete: true,
    },
    {
      id: 2,
      name: 'Matchings',
      isActive: false,
      isNext: false,
      isComplete: true,
    },
    {
      id: 3,
      name: "Fin de l'import",
      isActive: true,
      isNext: false,
      isComplete: false,
    },
  ],
  currentImport: null,
  isSearchingData: false,
  searchSourceValue: '',
  errorImport: '',
  isReadyToImport: false,
  importTabs: null,
  failedTab: [],
  onDelete: false,
  scrollPositionImport: 0,
  openBreadcrumb: false,
  selectedAllotmentNode: null,
  needAnimation: false,
  redirectionImport: false,
  loadingSwitch: [],
  importStep: 0,
  workflowImportModal: false,
  multipleActionOpen: false,
  linesSelected: [],
  exceptLinesFromMultipleActions: [],
  displayArchived: false,
  offset: 0,
};

function importReducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_FORM_VALUE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case OPEN_IMPORT_MODAL:
      return {
        ...state,
        importModal: !state.importModal,
      };
    case DELETE_ROWS_IMPORT:
      return {
        ...state,
        onDelete: true,
      };

    case LOAD_IMPORT_DATE:
      return {
        ...state,
        importDate: action.importDate.sort(function (a, b) {
          if (a.id > b.id) {
            return -1;
          }

          if (a.id < b.id) {
            return 1;
          }

          return 0;
        }),
        needAnimation: action.needAnimation,
        importModal: false,
        importLoading: false,
        isReadyToImport: false,
        importTabs: null,
        creationImport: action.creationImport ? true : false,
        failedTab: state.creationImportDatas
          ? state.creationImportDatas.failedTab
          : [],
        redirectionImport: action.creationImport ? true : false,
        creationImportDatas: null,
        currentImport: null,
        workflowImportModal: false,
      };
    case LOAD_IMPORT_DATAS: {
      const importDatasCopy = { ...state.importDatas };

      if (action.importDatas.ProjectImportElems) {
        if (action.offset > 0) {
          const dataIsAlreadyLoaded = action.importDatas.ProjectImportElems.some(
            (projectImportElem) =>
              importDatasCopy.ProjectImportElems.some(
                (importElem) => importElem.id === projectImportElem.id
              )
          );

          if (!dataIsAlreadyLoaded) {
            action.importDatas.ProjectImportElems = sortById([
              ...importDatasCopy.ProjectImportElems,
              ...action.importDatas.ProjectImportElems,
            ]);
          }
        }

        let wholeData = action.importDatas.ProjectImportElems;

        let linesSelected = null;

        if (state.allLinesChecked) {
          linesSelected = wholeData.map((el) => el.id);
        }

        let totalCount = 0;

        for (const value in action.importDatas.counts) {
          totalCount += action.importDatas.counts[value];
        }

        const isFullLoad =
          action.importDatas.ProjectImportElems.length === totalCount;

        wholeData =
          action.currentProject.templateType === TYPE_MAPPINGREF
            ? wholeData
            : createSetElems(wholeData);

        if (action.currentProject.templateType === TYPE_MAPPINGREF) {
          assignAiMatchesSorted(wholeData, action.nodesAvailableForProjectType);
        }
        assignAllotmentNodesToLines(
          wholeData,
          action.allGroups,
          action.currentAllotment,
          action.currentProject
        );

        return {
          ...state,
          importDetails: {
            ...state.importDetails,
            [action.importDatas.id]: action.importDatas,
          },
          importDatas: action.importDatas,
          isImportLoading: false,
          dataError: wholeData,
          editImport: klona(wholeData),
          isLoadMoreImports: false,
          goodLines: Number(action.importDatas.counts.userValidated),
          waitLines: Number(action.importDatas.counts.Validated),
          badLines: Number(action.importDatas.counts.notValidated),
          archivedLines: Number(action.importDatas.counts.isArchived),
          isFullLoad: isFullLoad,
          needAnimation: false,
          creationImport: false,
          importStep: 0,
          linesSelected: linesSelected || state.linesSelected,
          loadingProcess: false,
          offset: action.offset + 20,
        };
      } else {
        return {
          ...state,
          notProcessed: state.notProcessed ? state.notProcessed + 1 : 1,
          importDatas: 'loading',
        };
      }
    }
    case SET_LOADING_IMPORT:
      return {
        ...state,
        isImportLoading: true,
        error: false,
      };
    case SEND_IMPORT_EDIT:
      return {
        ...state,
        isImportLoading: true,
      };
    case CONFIRM_DELETE_IMPORT: {
      const newList = state.importDate.filter((date) => date.id !== action.importId);

      return {
        ...state,
        importDate: newList
          ? newList.sort(function (a, b) {
              if (a.id > b.id) {
                return -1;
              }

              if (a.id < b.id) {
                return 1;
              }

              return 0;
            })
          : [],
        offset: 0,
        importDatas: [],
        currentImport: newList.length > 0 ? newList[0].id : null,
        badLines: undefined,
        waitLines: undefined,
        goodLines: undefined,
        dataError: [],
      };
    }
    case SET_DATA_ERROR: {
      return {
        ...state,
        dataError: action.falseData,
      };
    }
    case AFTER_DELETE_ROWS: {
      const newImport = { ...state.importDatas };

      if (action.idsToDelete) {
        const rowsToKeep = newImport.ProjectImportElems.filter(
          (error) => !action.idsToDelete.includes(error.id)
        );

        newImport.ProjectImportElems = rowsToKeep;
        newImport.counts = action.importCounts;

        // Check with filters after load some new data what to display on screen

        let importRows =
          action.currentProject.templateType === TYPE_MAPPINGREF
            ? rowsToKeep
            : createSetElems(rowsToKeep);

        let index = state.dataError.findIndex(
          (row) =>
            action.idsToDelete.includes(row.id) ||
            (row.ProjectElemsDuplicate &&
              row.ProjectElemsDuplicate.find((rowDuplicate) =>
                action.idsToDelete.includes(rowDuplicate.id)
              ))
        );

        return {
          ...state,
          dataError: importRows,
          editImport: importRows,
          rowIndexToMeasure: index,
          importDatas: newImport,
          linesSelected: [],
          allLinesChecked: false,
          badLines: action.importCounts.notValidated,
          goodLines: action.importCounts.userValidated,
          waitLines: action.importCounts.Validated,
        };
      } else {
        newImport.ProjectImportElems = newImport.ProjectImportElems.filter(
          (el) => el.isValid !== false
        );

        return {
          ...state,
          dataError: newImport.ProjectImportElems,
          importDatas: newImport,
          badLines: 0,
        };
      }
    }
    case RETRACT_IMPORT: {
      return {
        ...state,
        isRetracted: !state.isRetracted,
      };
    }
    case CHANGE_IMPORT_DATAS: {
      return {
        ...state,
        editImport: action.editLines,
        dataError: action.editLines,
      };
    }
    case UPDATE_IMPORT_LINE: {
      let newImport = getFlattenImportRows(_.cloneDeep(state.dataError));
      let newLines = [];

      newImport = newImport.filter((el) => el.id !== 'duplicate');

      let importCopy = _.cloneDeep(state.importDatas);
      let result;

      action.line.map((currentRow) => {
        newLines =
          newLines.length === 0
            ? newImport.filter((line) => line.id !== action.line[0].id)
            : newLines.filter((line) => line.id !== currentRow.id);

        const oldLine = importCopy.ProjectImportElems.find(
          (el) => el.id === currentRow.id
        );

        if (
          currentRow.AllotmentNode &&
          oldLine?.AllotmentNodeId !== currentRow?.AllotmentNodeId
        ) {
          const { allAllotmentNodesRelatedToLineMasterData, currentMd } =
            getAllAllotmentNodesRelatedToLineMasterData(
              currentRow,
              action.allGroups,
              action.currentAllotment,
              action.currentProject
            );

          currentRow.currentMd = currentMd;
          currentRow.allAllotmentNodesRelatedToLineMasterData =
            allAllotmentNodesRelatedToLineMasterData;
        }

        newLines = [...newLines, currentRow];

        newLines = sortById(newLines);

        let allLines = importCopy.ProjectImportElems.filter(
          (line) => line.id !== currentRow.id
        );

        allLines = [...allLines, currentRow];

        importCopy = { ...importCopy, ProjectImportElems: sortById(allLines) };

        if (oldLine) {
          result = checkStatusLine(
            currentRow,
            oldLine,
            state.badLines,
            state.waitLines,
            state.goodLines,
            state.archivedLines
          );
        }

        if (state.isSearchingData) {
          newLines = [
            ...newLines.filter((el) => el.id !== currentRow.id),
            currentRow,
          ];
        }
      });

      if (action.count) {
        result = {
          ...result,
          valids: action.count.userValidated,
          waits: action.count.Validated,
          bads: action.count.notValidated,
          archivedLines: action.count.isArchived,
        };
      }

      importCopy.counts = {
        ...importCopy.counts,
        userValidated: result.valids,
        Validated: result.waits,
        notValidated: result.bads,
        isArchived: result.archivedLines,
      };

      let importRows =
        action.currentProject.templateType === TYPE_MAPPINGREF
          ? newLines
          : createSetElems(newLines);

      let idToFind = action.rowId || action.line[0].id;

      let index = state.dataError.findIndex(
        (row) =>
          row.id === idToFind ||
          (row.ProjectElemsDuplicate &&
            row.ProjectElemsDuplicate.find((el) => el.id === idToFind))
      );

      return {
        ...state,
        dataError: importRows,
        editImport: importRows,
        importDatas: importCopy,
        isImportLoading: false,
        isLoadingPatchLine: false,
        rowIndexToMeasure: index,
        moreOptionLines: [],
        openValid:
          action.line.length === 1 &&
          action.line[0]?.status === 'userValidated' &&
          !action.line[0]?.ProjectImportElemCaracs.find((el) => el.statusCode)
            ? 'one'
            : action.statusValidation
            ? action.statusValidation
            : false,
        goodLines: result.valids,
        badLines: result.bads,
        waitLines: result.waits,
        archivedLines: result.archivedLines,
        onDelete:
          (result.valids !== state.goodLines ||
            result.bads !== state.badLines ||
            result.waits !== state.waitLines) &&
          state.filterOn.length > 0
            ? true
            : false,
        loadingSwitch: [
          ...state.loadingSwitch.filter((el) => el !== action.line[0].id),
        ],
        isLoad: false,
      };
    }
    case FILTER_IMPORT_LIST: {
      return {
        ...state,
        dataError: action.resultFiltered,
        editImport: action.resultFiltered,
      };
    }
    case OPEN_VALID_ALERT: {
      return {
        ...state,
        openValid: true,
      };
    }
    case CLOSE_VALID_ALERT: {
      return {
        ...state,
        openValid: false,
        isMessageTeams: false,
      };
    }
    case FETCH_MORE_IMPORT_DATAS: {
      return {
        ...state,
        isLoadMoreImports: true,
      };
    }
    case GET_STATS_IMPORT: {
      return {
        ...state,
        goodLines: Number(action.good),
        badLines: Number(action.bad),
      };
    }
    case REFRESH_DATA_AFTER_IMPORT: {
      let flattenList = getFlattenImportRows(state.dataError);

      let goodLines =
        action.idsToDelete?.length > 1
          ? 0
          : state.importDatas.counts.userValidated - 1;
      let importRowsDisplayed = flattenList.filter(
        (row) => !action.idsToDelete.includes(row.id)
      );
      let importDatas = {
        ...state.importDatas,
        counts: { ...state.importDatas.counts, userValidated: goodLines },
        ProjectImportElems: importRowsDisplayed,
      };

      let importRows =
        action.currentProject.templateType === TYPE_MAPPINGREF
          ? importRowsDisplayed
          : createSetElems(importRowsDisplayed);

      return {
        ...state,
        importDatas: importDatas,
        goodLines: importDatas.counts.userValidated,
        dataError: importRows,
        editImport: importRows,
        importRowToScrollTo: action.scrollPosition,
        loadingProcess: false,
      };
    }
    case LOAD_SEARCH_DATAS: {
      assignAllotmentNodesToLines(
        action.data.ProjectImportElems,
        action.allGroups,
        action.currentAllotment,
        action.currentProject
      );

      let fullRowsDataLoaded = klona(state.importDatas.ProjectImportElems);

      action.data.ProjectImportElems.forEach((row) => {
        if (!fullRowsDataLoaded.find((obj) => obj.id === row.id)) {
          fullRowsDataLoaded = [...fullRowsDataLoaded, row];
        }
      });

      return {
        ...state,
        isSearchingData: true,
        dataError: createSetElems(action.data.ProjectImportElems),
        editImport: createSetElems(action.data.ProjectImportElems),
        importDatas: {
          ...state.importDatas,
          ProjectImportElems: fullRowsDataLoaded,
          counts: action.data.counts,
        },
        goodLines: Number(action.data.counts.userValidated),
        isImportLoading: false,
      };
    }
    case DUPLICATE_LINES: {
      const importCopy = { ...state.importDatas };
      let displayData = [...state.editImport];

      if (action.initialRow.Sheet?.type_fiche === 'bundle') {
        importCopy.ProjectImportElems = importCopy.ProjectImportElems.filter(
          (el) => el.id !== action.initialRow.id
        );
        displayData = displayData.filter((el) => el.id !== action.initialRow.id);
      }

      if (action.count) {
        importCopy.ProjectImportElems = [
          ...importCopy.ProjectImportElems,
          ...action.row,
        ];
      } else {
        importCopy.ProjectImportElems = [
          ...importCopy.ProjectImportElems,
          action.row,
        ];
      }

      // Need to filter on duplicate elems to rebuild the structure when the user is in searchingMode.

      for (let i = 0; i < displayData.length; i++) {
        if (displayData[i].id === 'duplicate') {
          displayData = [...displayData, ...displayData[i].ProjectElemsDuplicate];
        }
      }

      if (action.count) {
        displayData = [...displayData, ...action.row].filter(
          (el) => el.id !== 'duplicate'
        );
      } else {
        displayData = [...displayData, action.row].filter(
          (el) => el.id !== 'duplicate'
        );
      }

      let importRows =
        action.currentProject.templateType === TYPE_MAPPINGREF
          ? displayData
          : createSetElems(displayData);

      let index = state.dataError.findIndex(
        (row) =>
          row.id === action.initialRow.id ||
          (row.ProjectElemsDuplicate &&
            row.ProjectElemsDuplicate.find((el) => el.id === action.initialRow.id))
      );

      return {
        ...state,
        dataError: importRows,
        editImport: importRows,
        rowIndexToMeasure: index,
        importDatas: importCopy,
        waitLines: action.count
          ? action.count.Validated
          : action.row.statusCode === 'Validated'
          ? state.waitLines + 1
          : state.waitLines,
        goodLines: action.count
          ? action.count.userValidated
          : action.row.statusCode === 'userValidated'
          ? state.goodLines + 1
          : state.goodLines,
        badLines: action.count
          ? action.count.notValidated
          : !action.row.isValid
          ? state.badLines + 1
          : state.badLines,
      };
    }
    case RESET_IMPORT_STATES: {
      return {
        importDate: [],
        importDetails: {},
        importDatas: [],
        editImport: [],
        badLines: undefined,
        goodLines: undefined,
        error: false,
        isImportLoading: true,
        dataError: [],
        isRetracted: false,
        isLoad: false,
        isLoadingPatchLine: false,
        moreOptionLines: [],
        openValid: false,
        filterOn: [],
        isLoadMoreImports: false,
        isFullLoad: false,
        importPepFdes: [],
        waitLines: undefined,
        notProcessed: undefined,
        totalProcessed: undefined,
        executionId: null,
        stepsOfImport: [
          {
            id: 1,
            name: "Début de l'import",
            isActive: false,
            isComplete: true,
          },
          {
            id: 2,
            name: 'Matchings',
            isActive: false,
            isNext: false,
            isComplete: true,
          },
          {
            id: 3,
            name: "Fin de l'import",
            isActive: true,
            isNext: false,
            isComplete: false,
          },
        ],
        currentImport: null,
        isSearchingData: false,
        searchSourceValue: '',
        errorImport: '',
        isReadyToImport: false,
        importTabs: null,
        failedTab: [],
        onDelete: false,
        scrollPositionImport: 0,
        openBreadcrumb: false,
        selectedAllotmentNode: null,
        needAnimation: false,
        redirectionImport: false,
        loadingSwitch: [],
        importStep: 0,
        workflowImportModal: false,
        multipleActionOpen: false,
        linesSelected: [],
        exceptLinesFromMultipleActions: [],
        displayArchived: false,
        offset: 0,
      };
    }
    case RESEARCH_SOURCE_NAME: {
      return {
        ...state,
        isImportLoading: true,
      };
    }
    case VERIFY_ROWS_EQUIPMENT: {
      return {
        ...state,
        isLoad: true,
      };
    }
    case FETCH_IMPORT_DATAS: {
      return {
        ...state,
        isLoadMoreImports: true,
      };
    }
    case RESET_DATA_AFTER_FILTER_CHANGED: {
      return {
        ...state,
        dataError: [],
        offset: 0,
        allLinesChecked: false,
        linesSelected: [],
        exceptLinesFromMultipleActions: [],
      };
    }
    default:
      return state;
  }
}

export default importReducer;
