import { StateDTO } from '../../../app/models/GeneralDTO';
import { handleDeleteData } from '../bundle';
import { updateProject } from '../../../apiCallFunctions/project/project';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

export const useUpdateProject = () => {
  const { isBundleScreen, companyId, folderSheetId } = useSelector(
    (state: StateDTO) => state.bundle
  );
  const queryClient = useQueryClient();

  return useMutation(updateProject, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('projectsByFolder');
      queryClient.invalidateQueries('projectsBySearch');
      queryClient.invalidateQueries('foldersByCompany');

      if (isBundleScreen) {
        queryClient.setQueryData(
          ['getBundleListByFolder', companyId, folderSheetId],
          (oldData) => handleDeleteData(oldData, data)
        );
      }
    },
    onError: (error) => {
      console.log('error', error);
    },
  });
};
