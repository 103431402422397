import {
  CRUD_LICENSE,
  DELETE_COMPANY,
  FETCH_ACCESS_HEADER,
  FETCH_ADMIN_USERS,
  FETCH_COMPANIES_LIST,
  FETCH_EMPLOYEES_LIST,
  FETCH_ONE_COMPANY,
  PATCH_COMPANY,
  PATCH_USER,
  POST_COMPANY,
  POST_EMPLOYEE,
  updateRightsValue,
} from '../actions/rights';
import {
  dynamicHeightCompanyCard,
  findChildrenCompany,
  recursiveSeekAndUpdateObjectById,
} from '../app/shared/utils';
import API from '../app/services/API';

const apiUser = new API(true);
const api = new API();
let abortApi = false;

const rightsMiddleware = (store) => (next) => async (action) => {
  const { rights, projects } = store.getState();

  switch (action.type) {
    case FETCH_ACCESS_HEADER:
      if (!abortApi) {
        abortApi = true;
        apiUser
          .getAccessHeader()
          .then((res) =>
            store.dispatch(updateRightsValue('rightsHeaderData', res.data))
          );
        apiUser.getCategories().then((response) => {
          store.dispatch(updateRightsValue('categories', response.data));
          abortApi = false;
        });
      }

      next(action);
      break;
    case FETCH_ADMIN_USERS:
      apiUser
        .getAdminUsers(action.companyId)
        .then((res) =>
          store.dispatch(
            updateRightsValue(
              'companiesList',
              recursiveSeekAndUpdateObjectById(
                rights.companiesList,
                action.companyId,
                { users: res.data }
              )
            )
          )
        );
      next(action);
      break;
    case FETCH_COMPANIES_LIST:
      apiUser
        .getCompaniesList(0, rights.inputSearch, rights.parentNodeId)
        .then((res) => {
          const companiesByUsers = [];
          let mergedCompanies = [];

          if (rights.inputSearch) {
            apiUser.getEmployeesList({ ...rights }).then((employe) => {
              employe.data?.rows?.forEach((empl) => {
                empl.CompanyIds?.forEach((companyId) => {
                  const company = projects.allCompanies?.find(
                    (company) => company.id === companyId
                  );

                  if (
                    company &&
                    !companiesByUsers.some(
                      (uniqueCompany) => uniqueCompany.CompanyId === companyId
                    )
                  ) {
                    companiesByUsers.push(company);
                  }
                });
              });

              const existingCompanyIds = companiesByUsers?.map((e) => e.id);

              const uniqueCompanies = res.data?.filter(
                (company) => !existingCompanyIds.includes(company.id)
              );

              mergedCompanies = [...companiesByUsers, ...uniqueCompanies];

              store.dispatch(
                updateRightsValue('parentCompaniesFiltered', mergedCompanies)
              );
            });
          }

          if (rights.parentNodeId) {
            let newList = [];

            if (rights.companiesList) {
              newList = rights.companiesList.map((company) => {
                if (rights.parentNodeId === company.id) {
                  return {
                    ...company,
                    showChildren: true,
                    height:
                      company.height +
                      210 * [...company.children, ...res.data].length,
                    children: [
                      ...company.children,
                      ...res.data.map((el) => ({
                        ...el,
                        width: 90,
                        height: 210,
                        depth: company.depth + 2,
                        children: [],
                      })),
                    ],
                  };
                } else {
                  let copy = _.cloneDeep(company);

                  dynamicHeightCompanyCard(copy, res, rights.parentNodeId);
                  // if(company.children && company.children.length > 0) {
                  //   // company.children = findChildrenCompany(rights.parentNodeId,
                  //   //   company.children,

                  //   let heightToAdd = 0;

                  //   //   res);
                  //   company.children = company.children.map((el) => {

                  //     if(rights.parentNodeId === el.id) {
                  //       heightToAdd = el.height + (210*[...el.children, ...res.data].length );

                  //       return {...el, showChildren: true, height: heightToAdd, children: [...el.children, ...res.data.map((el) => ({...el, height: 210, width: 85, children: []}))]};
                  //     } else {
                  //       return el;
                  //     }
                  //   });

                  //   company.height = company.height + heightToAdd;
                  // }
                  console.log(company);

                  return copy;
                }
              });
            }

            store.dispatch(
              updateRightsValue(
                'companiesList',
                rights.companiesList
                  ? newList
                  : res.data.map((el) => ({
                      ...el,
                      width: 95,
                      height: 210,
                      children: [],
                    }))
              )
            );
          } else {
            store.dispatch(
              updateRightsValue(
                'companiesList',
                res.data.map((el) => ({
                  ...el,
                  depth: 1,
                  children: [],
                }))
              )
            );
          }

          store.dispatch(updateRightsValue('companiesLoading', false));
        });
      next(action);
      break;
    case FETCH_ONE_COMPANY:
      apiUser.getCompaniesList(0, action.name).then((res) => {
        store.dispatch(updateRightsValue('addUserSelectedCompany', res.data[0]));
      });
      next(action);
      break;
    case PATCH_COMPANY: {
      const company = action.company || rights.patchCompanyData;

      apiUser.patchCompany(company).then((res) => {
        if (!res.data) {
          return;
        }

        store.dispatch(
          updateRightsValue(
            'companiesList',
            recursiveSeekAndUpdateObjectById(
              rights.companiesList,
              res.data.id,
              res.data
            )
          )
        );
        store.dispatch(updateRightsValue('modalAccess', null));
      });
      next(action);
      break;
    }

    case POST_COMPANY:
      apiUser.postCompany(rights.newCompany).then((res) => {
        // store.dispatch(updateRightsValue('companiesList',
        //   [...rights.companiesList.map((el) => el.id === res.data.id ? {...el, ...res.data} : el )]));
        if (
          rights.newCompany.parentId &&
          rights.companiesList.find(
            (company) => company.id === rights.newCompany.parentId
          )
        ) {
          let newCompanies = rights.companiesList.map((company) =>
            company.id === rights.newCompany.parentId
              ? { ...company, type: 'organisation' }
              : company
          );

          store.dispatch(updateRightsValue('companiesList', newCompanies));
        }
        store.dispatch(updateRightsValue('modalAccess', null));
      });
      next(action);
      break;
    case DELETE_COMPANY:
      apiUser.deleteCompany(action.id).then((res) => {
        store.dispatch(
          updateRightsValue('companiesList', [
            ...rights.companiesList.filter((el) => el.id !== action.id),
          ])
        );
        store.dispatch(updateRightsValue('modalAccess', null));
      });
      next(action);
      break;
    case PATCH_USER:
      apiUser.patchUser(action.data).then((res) => {
        store.dispatch(
          updateRightsValue(
            'companiesList',
            recursiveSeekAndUpdateObjectById(
              rights.companiesList,
              action.data.CompanyId,
              { isAdmin: { isAdmin: action.data.isAdmin, id: action.data.id } }
            )
          )
        );
      });
      next(action);
      break;
    case CRUD_LICENSE:
      switch (action.data.method) {
        case 'add': {
          apiUser.postLicenses(action.data).then((res) => {
            store.dispatch(
              updateRightsValue(
                'companiesList',
                recursiveSeekAndUpdateObjectById(
                  rights.companiesList,
                  action.data.CompanyId,
                  { addCount: action.data.licenseType }
                )
              )
            );
          });
          break;
        }
        case 'delete': {
          apiUser.deleteLicenses(action.data).then((res) => {
            store.dispatch(
              updateRightsValue(
                'companiesList',
                recursiveSeekAndUpdateObjectById(
                  rights.companiesList,
                  action.data.CompanyId,
                  { removeCount: action.data.licenseType }
                )
              )
            );
          });
          break;
        }
      }

      next(action);
      break;
    default:
      next(action);
  }
};

export default rightsMiddleware;
