import { StateDTO } from '../../../app/models/GeneralDTO';
import { fetchFoldersByCompany } from '../../../apiCallFunctions/folder';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

export const useGetFoldersByCompany = (
  isExistChildrenCompanies,
  selectedCompanyId,
  onSuccess
) => {
  const isBundleScreen = useSelector(
    (state: StateDTO) => state.bundle.isBundleScreen
  );

  const isEnabled =
    isExistChildrenCompanies === false && typeof selectedCompanyId === 'number';

  return useQuery(
    ['foldersByCompany', [selectedCompanyId, isBundleScreen]],
    () => fetchFoldersByCompany(selectedCompanyId, isBundleScreen),
    {
      enabled: isEnabled,
      refetchOnWindowFocus: false,
      onSuccess,
      onError: (error) => {
        console.log('error in getFolders', error?.message);
      },
    }
  );
};
