export const ZONE_TYPES = {
  building: 'building',
  buildingSite: 'buildingSite',
  plot: 'plot',
  zone: 'zone',
};

export const MIDED_OPTIONS = {
  current: 'Mided actuel (date PC projet)',
  future: 'Mided futur ( date PC projetée)',
};
export type ThresholdBbcaType = 'STANDARD' | 'EFFICIENT' | 'EXCELLENT';

export const THREESHOLD_BBCA_OFFICE = {
  STANDARD: 0,
  EFFICIENT: 25,
  EXCELLENT: 50,
};

export const THREESHOLD_BBCA_COLLECTIVE_RESIDENTIAL = {
  STANDARD: 0,
  EFFICIENT: 35,
  EXCELLENT: 65,
};

export const MODELED_PRODUCT_ALLOTMENT_ID = 7600;

export enum LOTS_IDS {
  CVC = 95,
  PIF = 7030,
  ENERGIE_SIMPLIFIEE = 7327,
  PRODUITS_MODELISES = 7591,
  IRVE = 7407,
  DECHETS = 4236,
  FLUIDES = 275,
  OUVRAGE_ART = 6587,
  CFO = 222,
  SUPERSTRUCTURE = 68,
  MENUISERIE = 74,
  ENERGIE = 79,
  PLOMBERIE = 152,
  CLOISON_PLAFOND = 174,
  CFA = 181,
  COUVERTURE = 217,
  REVETEMENTS_PEINTURES = 293,
  VRD = 298,
  FACADES = 391,
  DEPLACEMENT_INT = 575,
  TERRE = 606,
  EAU = 813,
  RESEAUX = 5248,
  ECLAIRAGE_PUBLIC = 5253,
  SYSTEME_TRI_BAGAGES = 5281,
  TRANSPORTS_ENGINS = 5711,
  INFRASTRUCTURE = 208,
}

export enum TYPE_FICHE {
  DED = 'DED',
  PEP = 'PEP',
  FDES = 'FDES',
  BUNDLE = 'bundle',
  FORFAIT = 'FORFAIT',
  CUSTOM_USER = 'custom_user',
  FICHES_CONFIG = 'FC',
  REFURBISHED = 'REFURBISHED',
  DEC = 'DEC',
}

export const iniesTypeFiche = [TYPE_FICHE.DED, TYPE_FICHE.PEP, TYPE_FICHE.FDES];

export const DEC_LOTS_IDS = [
  LOTS_IDS.EAU,
  LOTS_IDS.ENERGIE,
  LOTS_IDS.FLUIDES,
  LOTS_IDS.ENERGIE_SIMPLIFIEE,
];

export const SOURCE_BUILDING_SITE_CALCULATOR = 'Calculette chantier';

export enum APP_ROUTES_PATH {
  PROJECTS = '/projects',
  RIGHTS = '/rights',
  PRODUCTS = '/produits',
  SIMULATOR = '/simulateur',
  HELP = '/help',
  LIBRARY = '/library',
}

export const APP_ROUTES_PATH_ARRAY = [
  APP_ROUTES_PATH.HELP,
  APP_ROUTES_PATH.LIBRARY,
  APP_ROUTES_PATH.PROJECTS,
  APP_ROUTES_PATH.PRODUCTS,
  APP_ROUTES_PATH.RIGHTS,
  APP_ROUTES_PATH.SIMULATOR,
];
