import './licence.scss';
import { StateDTO } from '../../models/GeneralDTO';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import AccordionByCompanies from './Accordion/AccordionByCompanies';
import AccordionByLicences from './Accordion/AccordionByLicences';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Licence = () => {
  const { sortBy } = useSelector((state: StateDTO) => state.rights);

  const columnSize = {
    expandIcon: '7%',
    licence: '18%',
    users: '18%',
    mails: '18%',
    company: '18%',
    admin: '14%',
    delete: '7%',
  };

  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3,
      }}
      className="licenceWrapper"
    >
      {/* Column Header sortBy Companies*/}
      {sortBy === 'Entreprises' && (
        <div className="headerLicence">
          {/* column expand */}
          <div
            style={{ width: columnSize.expandIcon }}
            className="titleColumnWrapper"
          ></div>

          {/* column companies */}
          <div style={{ width: columnSize.company }} className="titleColumnWrapper">
            <div className="columnTitle">
              {t('APP.LICENCE.LICENCE.PH_14B222', {
                defaultValue: 'Entreprises',
              })}
            </div>
            <div className="arrowWrapper">
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02219 1.41406L1.41406 6.1692"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 1.41406L10.5859 6.16919"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02222 5.77734L1.41409 1.02221"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 5.77742L10.5859 1.02222"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
            <div className="filterWrapper">
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="current"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0.782974 0.00891113H17.216C17.7629 0.00891113 18.1044 0.604893 17.8298 1.08034L12.6379 9.90623V15.2768C12.6379 15.6719 12.3209 15.9911 11.9281 15.9911H6.07092C5.67806 15.9911 5.3611 15.6719 5.3611 15.2768V9.90623L0.166903 1.08034C-0.105419 0.604893 0.236099 0.00891113 0.782974 0.00891113ZM6.95708 14.3839H11.0397V10.9018H6.95708V14.3839ZM6.74503 9.10266L6.95708 9.4732H11.0419L11.254 9.10266L15.6803 1.61605H2.31869L6.74503 9.10266Z" />
              </svg>
            </div>
          </div>

          {/* column users */}
          <div style={{ width: columnSize.users }} className="titleColumnWrapper">
            <div className="columnTitle">
              {t('APP.LICENCE.LICENCE.PH_086D1B', {
                defaultValue: 'Utilisateurs',
              })}
            </div>
            <div className="arrowWrapper">
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02219 1.41406L1.41406 6.1692"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 1.41406L10.5859 6.16919"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02222 5.77734L1.41409 1.02221"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 5.77742L10.5859 1.02222"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>

          {/* column Mails */}
          <div style={{ width: columnSize.mails }} className="titleColumnWrapper">
            <div className="columnTitle">
              {t('APP.LICENCE.LICENCE.PH_965A0D', { defaultValue: 'Mails' })}
            </div>
            <div className="arrowWrapper">
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02219 1.41406L1.41406 6.1692"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 1.41406L10.5859 6.16919"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02222 5.77734L1.41409 1.02221"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 5.77742L10.5859 1.02222"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>

          {/* column Licences */}
          <div style={{ width: columnSize.licence }} className="titleColumnWrapper">
            <div className="columnTitle">
              {t('APP.LICENCE.LICENCE.PH_4849C5', { defaultValue: 'Licences' })}
            </div>

            <div className="arrowWrapper">
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02219 1.41406L1.41406 6.1692"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 1.41406L10.5859 6.16919"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02222 5.77734L1.41409 1.02221"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 5.77742L10.5859 1.02222"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>

          {/* column admin */}
          <div style={{ width: columnSize.admin }} className="titleColumnWrapper">
            <div className="columnTitle">
              {t('APP.LICENCE.LICENCE.PH_397BBE', { defaultValue: 'Admin' })}
            </div>
            <div className="arrowWrapper">
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02219 1.41406L1.41406 6.1692"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 1.41406L10.5859 6.16919"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02222 5.77734L1.41409 1.02221"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 5.77742L10.5859 1.02222"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
            <div className="filterWrapper">
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="current"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0.782974 0.00891113H17.216C17.7629 0.00891113 18.1044 0.604893 17.8298 1.08034L12.6379 9.90623V15.2768C12.6379 15.6719 12.3209 15.9911 11.9281 15.9911H6.07092C5.67806 15.9911 5.3611 15.6719 5.3611 15.2768V9.90623L0.166903 1.08034C-0.105419 0.604893 0.236099 0.00891113 0.782974 0.00891113ZM6.95708 14.3839H11.0397V10.9018H6.95708V14.3839ZM6.74503 9.10266L6.95708 9.4732H11.0419L11.254 9.10266L15.6803 1.61605H2.31869L6.74503 9.10266Z" />
              </svg>
            </div>
          </div>

          {/* column delete */}
          <div
            style={{ width: columnSize.delete }}
            className="titleColumnWrapperRight"
          >
            <div className="columnTitle"></div>
            <div className="arrowWrapper">
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02219 1.41406L1.41406 6.1692"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 1.41406L10.5859 6.16919"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02222 5.77734L1.41409 1.02221"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 5.77742L10.5859 1.02222"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Column Header sortBy licences*/}
      {sortBy === 'Licences' && (
        <div className="headerLicence">
          {/* column expand */}
          <div
            style={{ width: columnSize.expandIcon }}
            className="titleColumnWrapper"
          ></div>

          {/* column Licences */}
          <div style={{ width: columnSize.licence }} className="titleColumnWrapper">
            <div className="columnTitle">
              {t('APP.LICENCE.LICENCE.PH_6D199A', { defaultValue: 'Licences' })}
            </div>

            <div className="arrowWrapper">
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02219 1.41406L1.41406 6.1692"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />

                  <path
                    d="M5.97778 1.41406L10.5859 6.16919"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02222 5.77734L1.41409 1.02221"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 5.77742L10.5859 1.02222"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>

          {/* column users */}
          <div style={{ width: columnSize.users }} className="titleColumnWrapper">
            <div className="columnTitle">
              {t('APP.LICENCE.LICENCE.PH_7984BF', {
                defaultValue: 'Utilisateurs',
              })}
            </div>
            <div className="arrowWrapper">
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02219 1.41406L1.41406 6.1692"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 1.41406L10.5859 6.16919"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02222 5.77734L1.41409 1.02221"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 5.77742L10.5859 1.02222"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
            <div className="filterWrapper">
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="current"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0.782974 0.00891113H17.216C17.7629 0.00891113 18.1044 0.604893 17.8298 1.08034L12.6379 9.90623V15.2768C12.6379 15.6719 12.3209 15.9911 11.9281 15.9911H6.07092C5.67806 15.9911 5.3611 15.6719 5.3611 15.2768V9.90623L0.166903 1.08034C-0.105419 0.604893 0.236099 0.00891113 0.782974 0.00891113ZM6.95708 14.3839H11.0397V10.9018H6.95708V14.3839ZM6.74503 9.10266L6.95708 9.4732H11.0419L11.254 9.10266L15.6803 1.61605H2.31869L6.74503 9.10266Z" />
              </svg>
            </div>
          </div>

          {/* column Mails */}
          <div style={{ width: columnSize.mails }} className="titleColumnWrapper">
            <div className="columnTitle">
              {t('APP.LICENCE.LICENCE.PH_5F7A07', { defaultValue: 'Mails' })}
            </div>
            <div className="arrowWrapper">
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02219 1.41406L1.41406 6.1692"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 1.41406L10.5859 6.16919"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02222 5.77734L1.41409 1.02221"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 5.77742L10.5859 1.02222"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>

          {/* column companies */}
          <div style={{ width: columnSize.company }} className="titleColumnWrapper">
            <div className="columnTitle">
              {t('APP.LICENCE.LICENCE.PH_930E17', {
                defaultValue: 'Entreprises',
              })}
            </div>
            <div className="arrowWrapper">
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02219 1.41406L1.41406 6.1692"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 1.41406L10.5859 6.16919"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02222 5.77734L1.41409 1.02221"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 5.77742L10.5859 1.02222"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
            <div className="filterWrapper">
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="current"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0.782974 0.00891113H17.216C17.7629 0.00891113 18.1044 0.604893 17.8298 1.08034L12.6379 9.90623V15.2768C12.6379 15.6719 12.3209 15.9911 11.9281 15.9911H6.07092C5.67806 15.9911 5.3611 15.6719 5.3611 15.2768V9.90623L0.166903 1.08034C-0.105419 0.604893 0.236099 0.00891113 0.782974 0.00891113ZM6.95708 14.3839H11.0397V10.9018H6.95708V14.3839ZM6.74503 9.10266L6.95708 9.4732H11.0419L11.254 9.10266L15.6803 1.61605H2.31869L6.74503 9.10266Z" />
              </svg>
            </div>
          </div>

          {/* column admin */}
          <div style={{ width: columnSize.admin }} className="titleColumnWrapper">
            <div className="columnTitle">
              {t('APP.LICENCE.LICENCE.PH_5FDE82', { defaultValue: 'Admin' })}
            </div>
            <div className="arrowWrapper">
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02219 1.41406L1.41406 6.1692"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 1.41406L10.5859 6.16919"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02222 5.77734L1.41409 1.02221"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 5.77742L10.5859 1.02222"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
            <div className="filterWrapper">
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="current"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0.782974 0.00891113H17.216C17.7629 0.00891113 18.1044 0.604893 17.8298 1.08034L12.6379 9.90623V15.2768C12.6379 15.6719 12.3209 15.9911 11.9281 15.9911H6.07092C5.67806 15.9911 5.3611 15.6719 5.3611 15.2768V9.90623L0.166903 1.08034C-0.105419 0.604893 0.236099 0.00891113 0.782974 0.00891113ZM6.95708 14.3839H11.0397V10.9018H6.95708V14.3839ZM6.74503 9.10266L6.95708 9.4732H11.0419L11.254 9.10266L15.6803 1.61605H2.31869L6.74503 9.10266Z" />
              </svg>
            </div>
          </div>

          {/* column delete */}
          <div
            style={{ width: columnSize.delete }}
            className="titleColumnWrapperRight"
          >
            <div className="columnTitle"></div>
            <div className="arrowWrapper">
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02219 1.41406L1.41406 6.1692"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 1.41406L10.5859 6.16919"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="arrowItem">
                <svg
                  width="12px"
                  height="8px"
                  viewBox="0 0 12 8"
                  fill="none"
                  stroke="current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.02222 5.77734L1.41409 1.02221"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.97778 5.77742L10.5859 1.02222"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Licences Item Scroll Container */}
      <div className="scrollContainer">
        {sortBy === 'Entreprises' && (
          <AccordionByCompanies columnSize={columnSize} />
        )}
        {sortBy === 'Licences' && <AccordionByLicences columnSize={columnSize} />}
      </div>
    </motion.div>
  );
};

export default Licence;
