import './creationProject.scss';
import { AnimatePresence, motion } from 'framer-motion';

import { Button } from '@photocarbone/nooco-ui';
import { Tooltip } from '@mui/material';
import { StateDTO } from '../../models/GeneralDTO';
import { updateFormValue } from '../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import ProjectForm from './ProjectForm';

import {
  TYPE_AIRPORT_ID,
  TYPE_BBCA_ID,
  TYPE_DATACENTER_ID,
  TYPE_EPCM_ID,
  TYPE_EXPLOITATION_ID,
  TYPE_GLOBAL_MONITORING_ID,
  TYPE_NOOCO_ID,
  TYPE_OUTDOOR_ID,
  TYPE_PROTOTYPE_ID,
  TYPE_RE2020_ID,
  TYPE_RENOVATION_ID,
} from '../../globalVariable/typeProject';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import StepBlock from './StepBlock';
import { useTranslation } from 'react-i18next';
import {
  CREATION_STEPS,
  CREATION_STEPS_TRAD_KEYS,
} from '../../globalVariable/creationProjectVariable';

const back = require('../../../assets/images/creation/back-arrow.png');

const CreationProject = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    creationProject,
    creationProjectForm,
    isMenuOpen,
    templateTypeAvailableIds,
    isSuperAdmin,
  } = useSelector((state: StateDTO) => state.projects);
  const { creationSteps } = useSelector(
    (state: StateDTO) => state.projects.creationProject
  );

  const isActivatedBuilding = [1, 2, 3, 4, 5, 12].some((number) =>
    templateTypeAvailableIds.includes(number)
  );
  const isActivatedAirport = templateTypeAvailableIds.includes(6);
  const isActivatedDatacenter = templateTypeAvailableIds.includes(7);
  const isActivatedPrototype = templateTypeAvailableIds.includes(11);
  const isActivatedOutdoor = templateTypeAvailableIds.includes(8);
  const isActivatedExploitation = templateTypeAvailableIds.includes(5);
  const isActivatedGlobalMonitoring = templateTypeAvailableIds.includes(13);
  const isActivatedSpecificBuilding =
    isActivatedAirport ||
    isActivatedDatacenter ||
    isActivatedPrototype ||
    isActivatedOutdoor;
  const isActivatedRenovation = [4, 12].some((number) =>
    templateTypeAvailableIds.includes(number)
  );
  const isActivatedNewBuilding = [1, 2, 3, 4, 5].some((number) =>
    templateTypeAvailableIds.includes(number)
  );

  const { t } = useTranslation();

  useEffect(() => {
    // setDisplayCreationModalInformation(true);
    dispatch(updateFormValue('isMenuOpen', false));
    dispatch(updateFormValue('currentProject', {}));

    return () => {
      dispatch(updateFormValue('creationProject', { creationSteps: [] }));
      dispatch(updateFormValue('creationProject', { creationSteps: [] }));
    };
  }, []);

  const returnStepOne = () => {
    // TODO_TRAD: fr;
    if (
      creationSteps.includes(
        t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.INFRASTRUCTURE])
      ) ||
      creationSteps.includes(
        t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.GLOBAL_MONITORING])
      )
    )
      return returnStepThree();

    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.5,
            x: { duration: 1 },
            default: { ease: 'linear' },
          }}
          className="creation-steps-choice"
        >
          {/* // TODO_TRAD: fr; */}
          {creationSteps.includes(
            t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.BUILDING])
          ) && (
            <>
              <StepBlock
                data={{
                  img: 'neuf',
                  creationStepName: 'Travaux neuf',
                  title: t('APP.CREATIONPROJECT.INDEX.PH_14E10F', {
                    defaultValue: 'Travaux neuf',
                  }),
                  isActive: isSuperAdmin ? true : isActivatedNewBuilding,
                  selectedTypeProjectId: TYPE_NOOCO_ID,
                }}
              />

              <StepBlock
                data={{
                  img: 'renovation',
                  creationStepName: 'Travaux rénovation',
                  title: t('APP.CREATIONPROJECT.INDEX.PH_FCFFC8', {
                    defaultValue: 'Travaux rénovation',
                  }),
                  isActive: isSuperAdmin ? true : isActivatedRenovation,
                  selectedTypeProjectId: TYPE_RENOVATION_ID,
                }}
              />

              <StepBlock
                data={{
                  img: 'exploitation',
                  creationStepName: 'Exploitation',
                  title: t('APP.CREATIONPROJECT.INDEX.PH_2A2E17', {
                    defaultValue: 'Exploitation',
                  }),
                  isActive: isSuperAdmin ? true : isActivatedExploitation,
                  selectedTypeProjectId: TYPE_EXPLOITATION_ID,
                }}
              />
            </>
          )}

          {/* TODO_TRAD : fr */}
          {creationSteps.includes(
            t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.WORK_AND_INDUSTRY])
          ) && (
            <>
              <StepBlock
                data={{
                  img: 'infra',
                  creationStepName: 'Infrastructure extérieure',
                  title: t('APP.CREATIONPROJECT.INDEX.PH_2BD3BA', {
                    defaultValue: 'Infrastructure extérieure',
                  }),
                  isActive: isSuperAdmin ? true : isActivatedOutdoor,
                  selectedTypeProjectId: TYPE_OUTDOOR_ID,
                }}
              />
              <StepBlock
                data={{
                  img: 'airport',
                  creationStepName: 'Aéroport',
                  title: t('APP.CREATIONPROJECT.INDEX.PH_EBFDD5', {
                    defaultValue: 'Aéroport',
                  }),
                  isActive: isSuperAdmin ? true : isActivatedAirport,
                  selectedTypeProjectId: TYPE_AIRPORT_ID,
                }}
              />

              <StepBlock
                data={{
                  img: 'data-center',
                  creationStepName: 'Datacenter',
                  title: t('APP.CREATIONPROJECT.INDEX.PH_7512E4', {
                    defaultValue: 'Datacenter',
                  }),
                  isActive: isSuperAdmin ? true : isActivatedDatacenter,
                  selectedTypeProjectId: TYPE_DATACENTER_ID,
                }}
              />

              <StepBlock
                data={{
                  img: 'prototype',
                  creationStepName: 'Prototype',
                  title: t('APP.CREATIONPROJECT.INDEX.PH_78BA05', {
                    defaultValue: 'Prototype',
                  }),
                  isActive: isSuperAdmin ? true : isActivatedPrototype,
                  selectedTypeProjectId: TYPE_PROTOTYPE_ID,
                }}
              />
            </>
          )}
        </motion.div>
      </AnimatePresence>
    );
  };

  const returnStepTwo = () => {
    // TODO_TRAD : fr
    if (
      creationSteps.includes(
        t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.NEW_CONSTRUCTION])
      ) ||
      creationSteps.includes(
        t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.RENOVATION_WORK])
      )
    ) {
      return (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              delay: 0.5,
              x: { duration: 1 },
              default: { ease: 'linear' },
            }}
            className="creation-steps-choice"
          >
            {creationSteps.includes(
              t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.NEW_CONSTRUCTION])
            ) && (
              <>
                <StepBlock
                  data={{
                    img: 'nooco',
                    creationStepName: 'Mode écoconception',
                    title: t('APP.CREATIONPROJECT.INDEX.PH_059790', {
                      defaultValue: 'Mode écoconception',
                    }),
                    isActive: true,
                    selectedTypeProjectId: TYPE_NOOCO_ID,
                  }}
                />

                <StepBlock
                  data={{
                    img: 're2020_2',
                    creationStepName: 'Mode RE2020',
                    title: t('APP.CREATIONPROJECT.INDEX.PH_27883E', {
                      defaultValue: 'Mode RE2020',
                    }),
                    isActive: true,
                    selectedTypeProjectId: TYPE_RE2020_ID,
                  }}
                />

                <StepBlock
                  data={{
                    img: 'epcm',
                    creationStepName: 'Mode E+C-',
                    title: t('APP.CREATIONPROJECT.INDEX.PH_370AE6', {
                      defaultValue: 'Mode E+C-',
                    }),
                    isActive: true,
                    selectedTypeProjectId: TYPE_EPCM_ID,
                  }}
                />
              </>
            )}
            {creationSteps.includes(
              t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.RENOVATION_WORK])
            ) && (
              <>
                <StepBlock
                  data={{
                    img: 'renovation',
                    creationStepName: 'Rénovation libre',
                    title: t('APP.CREATIONPROJECT.INDEX.FREE_RENOVATION', {
                      defaultValue: 'Rénovation libre',
                    }),
                    isActive: isSuperAdmin ? true : isActivatedRenovation,
                    selectedTypeProjectId: TYPE_RENOVATION_ID,
                  }}
                />
                <StepBlock
                  data={{
                    img: 'bbca',
                    creationStepName: 'BBCA Rénovation',
                    title: t('APP.CREATIONPROJECT.INDEX.BBCA_RENOVATION', {
                      defaultValue: 'BBCA Rénovation',
                    }),
                    isActive: isSuperAdmin ? true : isActivatedRenovation,
                    selectedTypeProjectId: TYPE_BBCA_ID,
                  }}
                />
              </>
            )}
          </motion.div>
        </AnimatePresence>
      );
    }

    return returnStepThree();
  };

  const returnStepThree = () => {
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.5,
            x: { duration: 1 },
            default: { ease: 'linear' },
          }}
        >
          <ProjectForm />
        </motion.div>
      </AnimatePresence>
    );
  };

  const returnDifferentSteps = () => {
    return (
      <>
        {creationSteps.length === 0 && (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                delay: 0.5,
                x: { duration: 1 },
                default: { ease: 'linear' },
              }}
              className="creation-steps-choice"
            >
              <StepBlock
                data={{
                  img: 'batiment',
                  creationStepName: 'Bâtiment',
                  title: t('APP.CREATIONPROJECT.INDEX.PH_EE0821', {
                    defaultValue: 'Bâtiment',
                  }),
                  isActive: isSuperAdmin ? true : isActivatedBuilding,
                  selectedTypeProjectId: TYPE_NOOCO_ID,
                }}
              />

              <StepBlock
                data={{
                  img: 'global_monitoring',
                  creationStepName: 'Global monitoring',
                  title: t('APP.CREATIONPROJECT.INDEX.GLOBAL_MONITORING', {
                    defaultValue: 'Global monitoring',
                  }),
                  isActive: isSuperAdmin ? true : isActivatedGlobalMonitoring,
                  selectedTypeProjectId: TYPE_GLOBAL_MONITORING_ID,
                }}
              />

              <StepBlock
                data={{
                  img: 'ouvrage',
                  creationStepName: CREATION_STEPS.WORK_AND_INDUSTRY,
                  title: t('APP.CREATIONPROJECT.INDEX.CONSTRUCTION_AND_INDUSTRY', {
                    defaultValue: 'Travaux publics & Industrie',
                  }),
                  isActive: isSuperAdmin ? true : isActivatedSpecificBuilding,
                  selectedTypeProjectId: TYPE_AIRPORT_ID,
                }}
              />
            </motion.div>
          </AnimatePresence>
        )}
        {creationSteps.length === 1 && returnStepOne()}
        {creationSteps.length === 2 && returnStepTwo()}
        {creationSteps.length === 3 && returnStepThree()}
      </>
    );
  };

  return (
    <div
      className="creation-project-wrapper"
      style={{ width: !isMenuOpen ? '96%' : undefined }}
    >
      <div className="creation-project-header">
        <img
          src={back}
          onClick={() =>
            creationSteps.length === 0
              ? history.push('/projects')
              : dispatch(
                  updateFormValue('creationProject', {
                    ...creationProject,
                    creationSteps: creationSteps.slice(0, creationSteps.length - 1),
                  })
                )
          }
        />

        <h3>
          {t('APP.CREATIONPROJECT.INDEX.PH_0BD58A', {
            defaultValue: 'Choisir un type de projet :',
          })}
        </h3>
        <div style={{ display: 'flex' }}>
          {creationSteps.map((step, index) => (
            <Button
              text={step}
              onClick={() => {
                const indexStep = creationSteps.indexOf(step);

                dispatch(
                  updateFormValue('creationProject', {
                    ...creationProject,
                    creationSteps: creationSteps.slice(0, indexStep + 1),
                  })
                );
              }}
              key={index}
              style={{
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                margin: '10px',
              }}
              className={
                creationSteps.includes(`${step}`) ? 'golden-button' : 'gray-button'
              }
            />
          ))}
        </div>
      </div>
      <div
        className="creation-steps"
        style={{ margin: creationProjectForm ? 0 : undefined }}
      >
        {returnDifferentSteps()}
      </div>
    </div>
  );
};

export default CreationProject;
