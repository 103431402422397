import { Select } from '@photocarbone/nooco-ui';
import { EquipmentFromListEquipment } from '../ListEquipmentsFunction';
import { Dispatch } from 'redux';
import { UseFormSetValue, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { handleAllotmentNodes } from '../../../../shared/utils';
import { useSelector } from 'react-redux';
import { stat } from 'fs';
import { StateDTO } from '../../../../models/GeneralDTO';
import { ALLOTMENT_IDS } from '../../../../globalVariable/typeProject';
import { SelectedSearchGroupOptionsLabel } from '../../../../shared/QuickAddBarEquipment/QuickAddBarEquipment';

interface LotSelectProps {
  tableEditMode: boolean;
  record: EquipmentFromListEquipment;
  updateFormData: Function;
  dispatch: Dispatch;
  isUserStartEditColumns: boolean;
  setValue: UseFormSetValue<any>;
  disabled: boolean;
  currentSearchGroup: SelectedSearchGroupOptionsLabel;
}

const LotSelect = ({
  tableEditMode,
  record,
  updateFormData,
  dispatch,
  isUserStartEditColumns,
  setValue,
  disabled,
  currentSearchGroup,
}: LotSelectProps) => {
  let dataRelatedToAllotmentNode = {
    parentNode: record.detailsNode.find((node) => node.level === 0),
    nodesLinkToSameMd: record.nodesLinkToSameMd,
  };
  const { currentAllotment, allGroups, currentProject } = useSelector(
    (state: StateDTO) => state.projects
  );

  const { getValues, watch } = useFormContext();

  const allotmentNodeField = watch(`ProjectSheets[${record.id}].AllotmentNode`);
  const sheetSelected = watch(`ProjectSheets[${record.id}].SheetId`);

  if (allotmentNodeField) {
    const nodesRelatedTo = handleAllotmentNodes(
      allGroups.type.find(
        (md) => md.id === allotmentNodeField.MasterDataEquipmentId
      ),
      allGroups.allotments.find((el) => el.id === ALLOTMENT_IDS.NOOCO),
      allGroups.flatAllotments,
      currentProject
    );

    dataRelatedToAllotmentNode = {
      parentNode: nodesRelatedTo?.find((parent) =>
        parent.details.find((child) => child.id === allotmentNodeField.id)
      ),
      nodesLinkToSameMd: nodesRelatedTo,
    };
  }

  const isIniesSelected =
    currentSearchGroup === SelectedSearchGroupOptionsLabel.INIES;

  return (
    <Select
      disabled={disabled}
      width="150px"
      keyValue="id"
      disabled={!tableEditMode}
      size="small"
      value={dataRelatedToAllotmentNode?.parentNode}
      keyItem="name"
      keyDisplay="name"
      style={{
        'background-color': `${dataRelatedToAllotmentNode?.parentNode?.color}`,
        width: '60px',
      }}
      displayMode="iconOnly"
      itemsArray={dataRelatedToAllotmentNode?.nodesLinkToSameMd}
      onChange={(value) => {
        let nodeNooco = dataRelatedToAllotmentNode.nodesLinkToSameMd
          ?.find((node) => node.name.toLowerCase() === value.toLowerCase())
          ?.details.find((node) => node.MasterDataEquipmentId);

        updateFormData(
          setValue,
          record,
          sheetSelected
            ? 'AllotmentNodeSheetAlreadySelected'
            : isIniesSelected
            ? 'AllotmentNodeSheet'
            : 'AllotmentNode',
          nodeNooco,
          dispatch,
          isUserStartEditColumns
        );
      }}
    />
  );
};

export default LotSelect;
