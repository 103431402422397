import {
  DRAG_AND_DROP_CONTRIBUTORS,
  FETCH_FAVORITES,
  FETCH_INITIAL_FAVORITES,
  TOGGLE_FAVORITES,
  loadFavorites,
  loadInitialFavorites,
  responseToggleFavorites,
  updateFavoritesValue,
} from '../actions/favorites';
import { FETCH_ALL_GROUPS, fetchProjectById } from '../actions/projects';
import API from '../app/services/API';
//TODO Necessaire?
// import _ from 'lodash';

const api = new API(true);
const apiProject = new API();

const favoritesMiddleware = (store) => (next) => async (action) => {
  const { projects, favorites } = store.getState();

  // console.log(releases);

  switch (action.type) {
    case FETCH_ALL_GROUPS:
      api.getUserFavoritesShort().then((response) => {
        store.dispatch(updateFavoritesValue('userFavoritesIds', response.data));
      });

      api.getCompanyFavoritesShort().then((response) => {
        store.dispatch(updateFavoritesValue('companyFavoritesIds', response.data));
      });

      next(action);
      break;
    case FETCH_INITIAL_FAVORITES:
      api.getUserFavorites().then((response) => {
        store.dispatch(loadInitialFavorites('user', response.data));
      });

      api.getCompanyFavorites().then((response) => {
        store.dispatch(loadInitialFavorites('company', response.data));
      });

      next(action);
      break;

    case TOGGLE_FAVORITES:
      if (!action.company) {
        if (!favorites.userFavoritesIds.includes(action.SheetId)) {
          api.putUserFavorites(action.SheetId).then((response) => {
            store.dispatch(responseToggleFavorites('user', response.data, 'PUT'));
            // store.dispatch(updateFavoritesValue('userFavoritesIds',
            //   [...favorites.userFavoritesIds, action.SheetId]));
            //   store.dispatch(updateFavoritesValue('userFavoritesSheets',
            //   [...favorites.userFavoritesSheets, response.data]));
          });
        } else {
          api.deleteUserFavorites(action.SheetId).then((response) => {
            store.dispatch(
              responseToggleFavorites('user', action.SheetId, 'DELETE')
            );
          });
        }
      } else {
        if (!favorites.companyFavoritesIds.includes(action.SheetId)) {
          api.putCompanyFavorites(action.SheetId).then((response) => {
            store.dispatch(responseToggleFavorites('company', response.data, 'PUT'));
          });
        } else {
          api.deleteCompanyFavorites(action.SheetId).then((response) => {
            store.dispatch(
              responseToggleFavorites('company', action.SheetId, 'DELETE')
            );
          });
        }
      }

      next(action);
      break;
    case DRAG_AND_DROP_CONTRIBUTORS:
      {
        let pickDefaultZone = projects.zones.find(
          (zone) => zone.localZoneId === 1 && zone.type === 'zone'
        );

        console.log(action, pickDefaultZone, 'action du DG');
        apiProject
          .postPepFdesDed({
            quantity: 1,
            SheetId: action.item.id,
            AllotmentNodes: [action.item.AllotmentNodes[0]],
            ProjectId: projects.currentProject.id,
            fluid: null,
            ZoneId: pickDefaultZone
              ? pickDefaultZone.id
              : projects.zones.find((zone) => zone.type === 'zone').id,
          })
          .then((response) => {
            store.dispatch(fetchProjectById(projects.currentProject.id));
          });
        store.dispatch(
          updateFavoritesValue(
            'favoritesSnackbar',
            'Votre équipement a été ajouté avec succès'
          )
        );
      }

      next(action);
      break;
    case FETCH_FAVORITES:
      {
        let page = (action.page - 1) * 5;

        if (action.scope === 'user') {
          let filterType =
            action.tabValue === 0 ? 'inies' : action.tabValue === 1 ? 'FC' : 'MACRO';

          api
            .getUserFavorites(filterType, page, favorites.inputSearchBar)
            .then((response) => {
              store.dispatch(loadFavorites('user', response.data));
            });
        } else {
          api
            .getCompanyFavorites(page, favorites.inputSearchBar)
            .then((response) => {
              store.dispatch(loadFavorites('company', response.data));
            });
        }
      }

      next(action);
      break;

    default:
      next(action);
  }
};

export default favoritesMiddleware;
