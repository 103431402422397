import { ZoneDTO } from '../models/ProjectDTO';

type structuredZoneForGraphType = {
  key: string;
  parentId: null | number;
  title: string;
  disabled?: boolean;
  value: string | number;
  children?: structuredZoneForGraphType[];
};

export type createStructuredZonesForDropdownDTO = {
  zones: ZoneDTO[];
  projectParam?: { visible: boolean; disabled: boolean };
  buildingParam?: { visible: boolean; disabled: boolean };
};
export const createStructuredZonesForDropdown = ({
  zones,
  projectParam = { visible: true, disabled: false },
  buildingParam = { visible: true, disabled: false },
}: createStructuredZonesForDropdownDTO) => {
  const projectZoneForDropdown = {
    key: 'project',
    parentId: null,
    title: 'Projet',
    disabled: projectParam.disabled,
    value: 'project',
  };

  const buildingZonesForDropdown = [
    ...zones
      .filter((zone) => zone.type === 'building')
      .map((buildingZone) => {
        return {
          key: buildingZone.id,
          parentId: 'project',
          title: buildingZone.name,
          value: buildingZone.id,
          disabled: buildingParam.disabled,
          children: zones
            .filter((zone) => zone.ParentId === buildingZone.id)
            .map((zone) => {
              return {
                key: zone.id,
                parentId: zone.ParentId,
                title: zone.name,
                value: zone.id,
              };
            }),
        };
      }),
  ];

  const plotZoneOrigin = zones.find((zone) => zone.type === 'plot');

  if (plotZoneOrigin) {
    buildingZonesForDropdown.unshift({
      key: plotZoneOrigin.id,
      parentId: 'project',
      title: plotZoneOrigin.name,
      value: plotZoneOrigin.id,
    });
  }

  let structuredZoneForGraph: structuredZoneForGraphType[] = [];

  if (projectParam.visible) {
    projectZoneForDropdown.children = buildingZonesForDropdown;
    structuredZoneForGraph = [projectZoneForDropdown];
  } else {
    structuredZoneForGraph = buildingZonesForDropdown;
  }

  //TODO add condition if building and projects needs to be hidden

  return structuredZoneForGraph;
};
