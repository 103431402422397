import { connect } from 'react-redux';

import {
  downloadRsee,
  downloadRseePdf,
  fetchAllGroups,
  fetchCompanies,
  fetchProjectById,
  loadUser,
  setNodeAdmins,
  setSuperAdmin,
  updateFormValue,
} from '../../actions/projects';

import { fetchImportDatas, fetchImportDate, sendImport } from '../../actions/import';

import { updateRightsValue } from '../../actions/rights';
import App from '../../app/App';

const mapStateToProps = (state) => ({
  isStoreLoading: state.projects.isLoading,
  companies: state.projects.companies,
  isCompanyAdmin: state.projects.isCompanyAdmin,
  allGroupsStatus: state.projects.allGroupsStatus,
  companiesStatus: state.projects.companiesStatus,
  connectedSocket: state.projects.connectedSocket,
  administrativeNodes: state.projects.administrativeNodes,
  noProvider: state.projects.noProvider,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCompanies: (isSuperAdmin) => {
    dispatch(fetchCompanies(isSuperAdmin));
  },

  setSuperAdmin: (isAdmin, isSuperAdmin) => {
    dispatch(setSuperAdmin(isAdmin, isSuperAdmin));
  },
  fetchAllGroups: () => {
    dispatch(fetchAllGroups());
  },
  loadUser: (user) => {
    dispatch(loadUser(user));
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  downloadRsee: (projectId) => {
    dispatch(downloadRsee(projectId));
  },
  downloadRseePdf: (projectId, data) => {
    dispatch(downloadRseePdf(projectId, data));
  },
  fetchImportDate: (id, needAnimation, creationImport) => {
    dispatch(fetchImportDate(id, needAnimation, creationImport));
  },
  fetchImportDatas: (projectId, importId, refresh, socketFetchData) => {
    dispatch(fetchImportDatas(projectId, importId, refresh, socketFetchData));
  },
  setNodeAdmins: (companyAdmins, orgaAdmins) => {
    dispatch(setNodeAdmins(companyAdmins, orgaAdmins));
  },
  fetchProjectById: (id, indicatorId, byPassForS3, importToLdc) => {
    dispatch(fetchProjectById(id, indicatorId, byPassForS3, importToLdc));
  },
  sendImport: () => {
    dispatch(sendImport());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
