import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

const LabelWithTooltip = ({ label, tooltip }) => (
  <div className="label-textfield" style={{ display: 'flex', alignItems: 'center' }}>
    <p style={{ fontFamily: 'inherit' }}>{label}</p>
    <Tooltip title={tooltip}>
      <InfoIcon style={{ height: '1.5rem', marginLeft: '10px' }} />
    </Tooltip>
  </div>
);

export default LabelWithTooltip;
