import React from 'react';
import { Dropdown, Input } from '@photocarbone/nooco-ui';
import NoocoCheckbox from '../../NoocoCheckbox';
import informationIcon from '../../../../assets/images/equipments/information.png';
import { isNumOrNull } from '../../utilsFunction';
import { Tooltip } from '@material-ui/core';
import { TREATMENT_MODE_VALUES_I18N_KEYS } from '../../../models/ProjectDTO';
import '../caracPopover.scss';
import { useSelector } from 'react-redux';
import { StateDTO } from '../../../models/GeneralDTO';
import { TEMPLATE_TYPE_NAME } from '../../../globalVariable/typeProject';
import { useTranslation } from 'react-i18next';
import { getTreatmentModeId } from '../../utils';

export type UnamortizedValuesDTO = {
  residualLifespan: string | null; //only positive number accepted. lifespan in year.
  isResidualLifespanUnknowChecked: Boolean;
  treatmentMode: null | TREATMENT_MODE_VALUES_I18N_KEYS;
};

export type UnamortizedCaracDisplayProps = {
  disabled: boolean;
  unamortizedValues: UnamortizedValuesDTO;
  setUnamortizedValues: (e: UnamortizedValuesDTO) => void;
};

const UnamortizedCaracDisplay = ({ setUnamortizedValues, unamortizedValues }) => {
  const { currentProject } = useSelector((state: StateDTO) => state.projects);
  const isGlobalMonitoringProject =
    currentProject.templateType === TEMPLATE_TYPE_NAME.GLOBAL_MONITORING;
  const { t } = useTranslation();
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column' }}
      className="carac-popover-wrapper"
    >
      <div style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
        {isGlobalMonitoringProject
          ? t('APP.SHARED.CARAC_POP_OVER.DEPOSIT', {
              defaultValue: 'Dépose :',
            })
          : t('APP.SHARED.CARAC_POP_OVER.NONE_AMORTIZED_PCE', {
              defaultValue: 'PCE non amorti :',
            })}
      </div>
      <div style={{ marginBottom: '1rem' }}>Durée de vie résiduelle</div>
      <div style={{ display: 'flex' }}>
        <div className="carac-row">
          <Input
            onChange={(value) => {
              setUnamortizedValues({
                ...unamortizedValues,
                residualLifespan: value,
              });
            }}
            disabled={unamortizedValues?.isResidualLifespanUnknowChecked}
            value={unamortizedValues?.residualLifespan}
            disabledDebouncing={true}
            error={!isNumOrNull(unamortizedValues?.residualLifespan)}
          />
          <NoocoCheckbox
            value={unamortizedValues?.isResidualLifespanUnknowChecked}
            onChange={(e) => {
              setUnamortizedValues({
                ...unamortizedValues,
                isResidualLifespanUnknowChecked: e.target.checked,
                residualLifespan: '',
              });
            }}
          />
          <div>Non connue</div>
          <Tooltip title="Si cette information n’est pas connue, on considère la moitié de la durée de vie estimée du produit">
            <img src={informationIcon} alt="" />
          </Tooltip>
        </div>
      </div>
      <div className="carac-row">
        <Dropdown
          onSelect={(e) => {
            setUnamortizedValues({
              ...unamortizedValues,
              treatmentMode: getTreatmentModeId(e),
              labelDisplay: t(e),
            });
          }}
          treeCheckable={false}
          MenuProps={{
            disableScrollLock: true,
          }}
          value={unamortizedValues?.labelDisplay}
          options={Object.values(TREATMENT_MODE_VALUES_I18N_KEYS).map((value) => ({
            title: t(value),
            key: value,
            value: value,
          }))}
          allowClear={false}
        />
      </div>
    </div>
  );
};

export default UnamortizedCaracDisplay;
