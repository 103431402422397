/**
 * Created by nanaa on 18/05/2021
 */

import { connect } from 'react-redux';
import LifeCycleTab from '../../app/projects/LifeCycle/LifeCycleTab';

import {
  resetFilters,
  saveFiltersBrand,
  saveSheets,
  setLoadingGraph,
  setNewSheets,
} from '../../actions/filtersgraph';

import {
  fetchProjectById,
  setDataset,
  updateActivatedSelects,
  updateCurrentFlag,
  updateFormValue,
} from '../../actions/projects';

import { changeValueEquipments, updateNewInies } from '../../actions/equipments';

const mapStateToProps = (state) => ({
  allGroups: state.projects.allGroups.type,
  flatAllotments: state.projects.allGroups.flatAllotments,
  filters: state.projects.filters,
  currentMd: state.projects.currentMd,
  sheets: state.projects.sheets,
  loadingGraph: state.projects.loadingGraph,
  sheetsDetails: state.projects.sheetsDetails,
  projectId: state.projects.projectId,
  currentIndicator: state.projects.currentIndicator,
  selectedLot: state.projects.selectedLot,
  selectedFonction: state.projects.selectedFonction,
  selectedCategory: state.projects.selectedCategory,
  selectedMaterial: state.projects.selectedMaterial,
  selectedType: state.projects.selectedType,
  project: state.projects.currentProject,
  currentFlag: state.projects.currentFlag,
  re2020SousLot: state.projects.allGroups.sousLot,
  parsedZones: state.projects.parsedProject.zones,
  searchValue: state.projects.searchValue,
  lockedEquipmentNeeded: state.equipments.lockedEquipmentNeeded,
  currentAllotment: state.projects.currentAllotment,
  structureGraph: state.projects.projectAllot?.structureGraph,
  graphData: state.projects.graphData,
  sheetToLifeCycle: state.projects.sheetToLifeCycle,
  filteredLifeCycle: state.projects.filteredLifeCycle,
  allotSteps: state.projects.allotSteps,
  patchModeLoading: state.projects.patchModeLoading,
  confirmReplaceEquipment: state.projects.confirmReplaceEquipment,
  replaceEquipment: state.projects.replaceEquipment,
  switchSelectedEquipment: state.projects.switchSelectedEquipment,
  graphView: state.projects.graphView,
  projectSheets: state.projects.sheets,
  exploitationSheets: state.exploitation.sheets,
  lists: state.projects.lists,
  loadGraph: state.exploitation.loadGraph,
  equipmentsAvailable: state.projects.equipmentsAvailable,
  scope: state.projects.scope,
  licenceTypeAccess: state.projects.licenceTypeAccess,
  copyData: state.projects.projectAllot.dataset,
  loadingWaitingGraph: state.projects.loadingWaitingGraph,
});

const mapDispatchToProps = (dispatch) => ({
  setDataset: (dataset) => {
    dispatch(setDataset(dataset));
  },
  saveSheets: (sheets, md) => {
    dispatch(saveSheets(sheets, md));
  },
  fetchProjectById: (id) => {
    dispatch(fetchProjectById(id));
  },
  setLoadingGraph: () => {
    dispatch(setLoadingGraph());
  },
  setNewSheets: (sheets, md) => {
    dispatch(setNewSheets(sheets, md));
  },
  resetFilters: () => {
    dispatch(resetFilters());
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  changeValueEquipments: (name, value) => {
    dispatch(changeValueEquipments(name, value));
  },
  updateCurrentFlag: (value) => {
    dispatch(updateCurrentFlag(value));
  },
  updateDisabledSelects: (id, value) => {
    dispatch(updateActivatedSelects(id, value));
  },
  updateNewInies: (sheet) => {
    dispatch(updateNewInies(sheet));
  },
  saveFiltersBrand: (filters, open, open2) => {
    dispatch(saveFiltersBrand(filters, open, open2));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LifeCycleTab);
