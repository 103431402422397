class Socket {
  constructor(user) {
    if (user) {
      const returnToken = () => {
        let accessTokenValue;

        if (user.tokens) {
          const { accessToken } = user?.tokens;

          accessTokenValue = accessToken;
        }

        return accessTokenValue;
      };

      let url = `${process.env.REACT_APP_WEBSOCKET}?AccessToken=${returnToken()}`;

      this.socket = new WebSocket(url);
    }
  }

  connect(
    fetchImportDatas,
    updateFormValue,
    downloadRsee,
    downloadRseePdf,
    connectedSocket,
    fetchProjectById,
    fetchImportDate
  ) {
    if (!connectedSocket) {
      updateFormValue('connectedSocket', true);
    }

    this.socket.addEventListener('open', (event) => {
      setInterval(() => {
        // Send a keep-alive message
        this.socket.send(JSON.stringify({ type: 'keep-alive' }));
      }, 540000);
    });

    this.socket.addEventListener('message', function (event) {
      const response = JSON.parse(event.data);
      const messages = response.message;

      if (messages.status === 'INIT_STARTED') {
        if (messages.type === 'creation') {
          fetchImportDate(messages.ProjectId, true, true);
        }
      }

      if (messages.status === 'INIT_ENDED') {
        updateFormValue('currentImport', messages.id);

        updateFormValue('importStep', 1);
      } else if (messages.status === 'DATA_CREATION_STARTED') {
        updateFormValue('importStep', 2);
      } else if (messages.status === 'DATA_CREATION_ENDED') {
        updateFormValue('importStep', 3);
      } else if (
        messages.status === 'DATA_VERIFICATION_ENDED' ||
        messages.status === 'DATA_UPDATE_ENDED'
      ) {
        fetchImportDatas(null, messages.id, null, true);
      } else if (messages.status === 'FAILED' || messages.status === 'TIMED_OUT') {
        updateFormValue(
          'Une erreur est survenue durant le chargement de votre import.'
        );
      }

      if (response.type === 'RSEE') {
        downloadRsee(response.projectId);
      }

      if (response.type === 'RSEEPDF') {
        downloadRseePdf(response.projectId, response.data);
      }

      // if(messages.status == 'DELETE_SELECTED_EQUIPMENTS') {
      //   fetchProjectById(messages.ProjectId,
      //     1);
      // }

      if (
        messages.status === 'VERSION_UPDATE_ENDED' ||
        messages.status === 'IMPORT_VALIDATE_ALL'
      ) {
        fetchProjectById(messages.ProjectId, 1);
      }
      if (messages.status === 'CONTRIBUTOR_CREATION_END') {
        fetchProjectById(messages.ProjectId, 1, null, true);
        setTimeout(() => {
          updateFormValue('isImportLoading', false);
          updateFormValue('creationImport', false);
          updateFormValue('importStep', 1);
        }, 1000);
      }

      if (messages.status === 'IMPORT_EN_MASSE_UPDATE_ENDED') {
        fetchImportDatas(null, Number(messages.ProjectImportId), 0, true);
      }
    });

    this.socket.addEventListener('close', () => {
      this.connect(
        fetchImportDatas,
        updateFormValue,
        downloadRsee,
        downloadRseePdf,
        connectedSocket,
        fetchProjectById,
        fetchImportDate
      );
    });
  }
}

export default Socket;
