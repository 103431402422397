/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import './Project.scss';
import './Projects.css';
import 'moment/locale/fr';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ModalMaterial from '@material-ui/core/Modal';

import { BreadCrumb } from './HomeProject/BreadCrumb/BreadCrumb';
import { Header } from './HomeProject/Header/Header';
import { ProjectsList } from './HomeProject/ProjectsList/ProjectsList';
import { useHistory } from 'react-router-dom';
import { SubMenu } from './HomeProject/SubMenu/SubMenu';
import { updateHelpNewReleaseNotification } from '../../actions/helps';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import AppContext from '../AppContext';
import InformationModal from './NotificationNewRelease/InformationModal.tsx';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import ReleaseModal from './NotificationNewRelease/ReleaseModal';
import Snackbar from '@material-ui/core/Snackbar';
import Spinner from '../shared/Spinner';
import dayjs from 'dayjs';
import useCase from './HomeProject/useCase/useCase';

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      background: 'white',
      border: '1px solid #D9DBE9',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      padding: theme.spacing(2, 2, 2, 2),
      // overflowY:"scroll",
      maxHeight: '700px',
      // maxWidth:"1000px",
      borderRadius: '12px',
      // maxHeight: '600px',
      // overflow: 'auto',
      // overflowX: 'hidden',
    },
    information: {
      background: 'white',
      border: '1px solid #D9DBE9',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      // padding: theme.spacing(4, 3, 4, 3),
      // overflowY:"scroll",
      // maxHeight:"700px",
      // maxWidth:"1000px",
      borderRadius: '12px',
      // maxHeight: '600px',
      // overflow: 'auto',
      // overflowX: 'hidden',
    },
  })
);

const Projects = ({
  updateFormValue,
  isLoadingProjects,
  buildingTypes,
  changeValueEquipments,
  isSuperAdmin,
  isInit,
}) => {
  document.title = 'Mes Projets';

  const dispatch = useDispatch();
  const { initBreadCrumb, selectHomeBreadCrumb } = useCase();
  const [error] = useState(null);
  const [snackMessage, setMessageSnack] = useState();

  const { newReleaseNotification } = useSelector((state) => state.help);
  const {
    lastReleaseId,
    lastReleaseIdLocal,
    isOpenModalNewRelease,
    isOpenModalInformation,
  } = newReleaseNotification;
  const { allCompanies } = useSelector((state) => state.projects);

  const classes = useStyles();

  //TODO WHAT IS DOING?
  useEffect(() => {
    const html = document.querySelector('html');

    html.style.overflow = 'hidden';

    changeValueEquipments('hideColumns', []);
    changeValueEquipments('groupBy', 'aucun');
    changeValueEquipments('filterBy', 'project');
  }, []);

  useEffect(() => {
    updateFormValue('currentIndicator', null);

    return () => {
      // updateFormValue('graphData', null);
      // updateFormValue('currentImport', null);
      updateFormValue('currentImport', null);
      updateFormValue('zoneEquipmentId', null);
    };
  }, []);

  const history = useHistory();
  const ctx = useContext(AppContext);

  //INIT BREADCRUMB, SUBMENU
  useEffect(() => {
    if (!isInit) {
      initBreadCrumb();
    }
  }, []);

  const onProjectClick = (project) => {
    const projectType = project?.ProjectType?.id || project.ProjectTypeId;
    const projectCompanyId = project?.Company?.id || project?.CompanyId;
    const companyOfProject = allCompanies.find(
      (company) => company.id === projectCompanyId
    );
    const isProjectAvailable = companyOfProject?.ProjectTypes?.includes(projectType);

    if (!isProjectAvailable && projectType !== 9 && !isSuperAdmin) {
      return;
    }

    history.push({
      pathname: `/projects/${project.id}`,
    });

    if (!project) {
      return;
    }

    const eventData = {
      eventCount: 1,
      eventType: 'click',
      eventContext: 'Projects',
      actorType: 'user',
      actorId: ctx.user.attributes.email,
      targetType: 'Project',
      targetAction: 'view',
      targetId: project.id,
      targetIdentity: project.name,
      targetParentId: companyOfProject?.id,
      targetParentIdentity: companyOfProject?.name,
      targetParentType: 'Company',
      metadata: {
        Project: {
          id: project.id,
          name: project.name,
        },
        Company: {
          id: companyOfProject?.id,
          name: companyOfProject?.name,
        },
        User: {
          email: ctx.user.attributes.email,
        },
      },
    };

    ctx.metrics(
      {
        data: eventData,
        streamName: ctx.config.kinesis.KINESIS_DATA_STREAM_NAME,
        partitionKey: 'FRONT',
      },
      'AWSKinesis'
    );
  };

  useEffect(() => {
    // fetchProjects();
    dispatch(
      updateHelpNewReleaseNotification(
        'lastReleaseIdLocal',
        localStorage?.getItem('lastReleaseIdLocal')
      )
    );
  }, []);

  useEffect(() => {
    if (
      lastReleaseId != lastReleaseIdLocal &&
      lastReleaseId !== undefined &&
      lastReleaseIdLocal !== undefined
    ) {
      dispatch(updateHelpNewReleaseNotification('isOpenModalNewRelease', true));
    }
  }, [lastReleaseIdLocal, lastReleaseId]);

  const handleCloseNewReleaseNotification = () => {
    dispatch(updateHelpNewReleaseNotification('isOpenModalNewRelease', false));
    dispatch(updateHelpNewReleaseNotification('isOpenModalInformation', true));
    dispatch(updateHelpNewReleaseNotification('lastReleaseIdLocal', lastReleaseId));
    localStorage.setItem('lastReleaseIdLocal', lastReleaseId);
  };

  const handleCloseInformationModal = () => {
    dispatch(updateHelpNewReleaseNotification('isOpenModalNewRelease', false));
    dispatch(updateHelpNewReleaseNotification('isOpenModalInformation', false));
    // localStorage.setItem('lastReleaseIdLocal', lastReleaseId);
  };

  if (isLoadingProjects && !buildingTypes) {
    return <Spinner />;
  }

  if (error) {
    return <div className="empty-content-text">{error}</div>;
  }

  return (
    // style height not  necessary? of fix?
    <div
      id="project-container"
      // style={{  height: (companies.length === 1 || localStorage.getItem('displayRefs') === 'false') ? '95vh' : '85vh'}}
    >
      <Snackbar
        onClose={() => setMessageSnack()}
        open={snackMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackMessage}
      >
        <Alert onClose={() => setMessageSnack()} severity="info">
          {snackMessage}
        </Alert>
      </Snackbar>
      <div className="projectHeader">
        <Header onClick={onProjectClick} home={true} />
      </div>
      <div className="projectNav">
        <BreadCrumb />
      </div>
      <div className="projectNavSub">
        <SubMenu />
      </div>
      <div className="projectsWrapper">
        <ProjectsList onClick={onProjectClick} />
      </div>

      {/* New Release */}
      <ModalMaterial
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpenModalNewRelease}
        onClose={handleCloseNewReleaseNotification}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        overflow="scroll"
      >
        <Fade in={isOpenModalNewRelease}>
          <div className={classes.paper}>
            <ReleaseModal
              handleCloseNewReleaseNotification={handleCloseNewReleaseNotification}
            />
          </div>
        </Fade>
      </ModalMaterial>
      <ModalMaterial
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpenModalInformation}
        onClose={handleCloseInformationModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpenModalInformation}>
          <div className={classes.information}>
            <InformationModal
              handleCloseInformationModal={handleCloseInformationModal}
            />
          </div>
        </Fade>
      </ModalMaterial>
    </div>
  );
};

Projects.propTypes = {
  fetchProjects: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired,
  lots: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
};

export default Projects;
