import React from 'react';
import { ModalNooco } from '@photocarbone/nooco-ui';
import { useTranslation } from 'react-i18next';

interface VerificationModalProps {
  allotmentNodes: Object[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  getRseePdf: Function;
  id: Number;
  name: String;
  isProjectEpcm: boolean;
  isXml: boolean;
  setShow: Function;
  generateRsee: Function;
}

const VerificationModal = ({
  allotmentNodes,
  isOpen,
  setIsOpen,
  getRseePdf,
  id,
  name,
  isProjectEpcm,
  isXml,
  setShow,
  generateRsee,
}: VerificationModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalNooco
      title={t('APP.DELIVRABLES_WIDGETS.MODAL.TITLE', {
        defaultValue: 'Verification',
      })}
      isOpen={isOpen}
      cancelFunction={() => setIsOpen(false)}
      validateFunction={() => {
        setIsOpen(false);
        if (isXml) {
          if (isProjectEpcm) return setShow(true);
          return generateRsee(id, name);
        } else {
          if (isProjectEpcm)
            return (window.location.href =
              'http://observatoire.batiment-energiecarbone.fr/authentification/connexion/');
          return getRseePdf(id);
        }
      }}
      closeOnOutsideClick={true}
      closeIcon={true}
    >
      {t('APP.DELIVRABLES_WIDGETS.MODAL.TEXT', {
        defaultValue:
          'Attention, vérifier l’unité des équipements suivants dans la liste des contributeurs :',
      })}
      <ul>
        {allotmentNodes.map((node) => (
          <li key={node.id}>{node.name}</li>
        ))}
      </ul>
    </ModalNooco>
  );
};

export default VerificationModal;
