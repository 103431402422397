/**
 * Created by nanaa on 18/05/2021
 */

import { connect } from 'react-redux';

import { changeValueEquipments } from '../../actions/equipments';
import { changeValueVariant } from '../../actions/variants';
import {
  downloadRsee,
  fetchProjectById,
  openIndicators,
  patchLotMode,
  setCurrentProject,
  setLoadingProjectDetails,
  setProjectId,
  updateFormValue,
} from '../../actions/projects';
import { fetchImportDatas, fetchImportDate } from '../../actions/import';
import { resetExploitation } from '../../actions/exploitation';

import ProjectOverview from '../../app/projects/ProjectOverview';

const mapStateToProps = (state) => ({
  allGroups: state.projects.allGroups,
  projectsDetails: state.projects.projectsDetails,
  currentProject: state.projects.currentProject,
  groups: state.projects.mds,
  rate: state.projects.rates,
  isLoadingProjectDetails: state.projects.isLoadingProjectDetails,
  projectId: state.projects.projectId,
  importDate: state.imports.importDate,
  buildingCategories: state.projects.buildingCategories,
  buildingTypes: state.projects.buildingTypes,
  currentIndicator: state.projects.currentIndicator,
  indicators: state.projects.indicators,
  decMds: state.projects.decMds,
  fluids: state.projects.fluids,
  zones: state.projects.zones,
  executionId: state.imports.executionId,
  isAdmin: state.projects.isAdmin,
  lotMode: state.projects.lotMode,
  isLoadingProject: state.equipments.isLoadingProject,
  failedTab: state.imports.failedTab,
  patchModeLoading: state.projects.patchModeLoading,
  socket: state.projects.socket,
  userCredential: state.projects.userCredential,
  user: state.projects.user,
  currentAllotment: state.projects.currentAllotment,
  needAnimation: state.imports.needAnimation,
  redirectionImport: state.imports.redirectionImport,
  isResponseProjectError: state.projects.isResponseProjectError,
  projectErrorMessage: state.projects.projectErrorMessage,
  licenceTypeAccess: state.projects.licenceTypeAccess,
  allFiltersFromTable: state.equipments.allFiltersFromTable,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjectById: (id, indicatorId) => {
    dispatch(fetchProjectById(id, indicatorId));
  },
  setCurrentProject: (project) => {
    dispatch(setCurrentProject(project));
  },
  setProjectId: (id) => {
    dispatch(setProjectId(id));
  },
  setLoadingProjectDetails: () => {
    dispatch(setLoadingProjectDetails());
  },
  fetchImportDate: (id, needAnimation, creationImport) => {
    dispatch(fetchImportDate(id, needAnimation, creationImport));
  },
  fetchImportDatas: (idProject, idImport) => {
    dispatch(fetchImportDatas(idProject, idImport));
  },
  openIndicators: (bool) => {
    dispatch(openIndicators(bool));
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  patchLotMode: (lotMode) => {
    dispatch(patchLotMode(lotMode));
  },
  changeValueEquipments: (name, value) => {
    dispatch(changeValueEquipments(name, value));
  },
  changeValueVariant: (name, value) => {
    dispatch(changeValueVariant(name, value));
  },

  downloadRsee: (projectId) => {
    dispatch(downloadRsee(projectId));
  },
  resetExploitation: () => {
    dispatch(resetExploitation());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOverview);
