import { Energy, EvacuatedEarthWork } from '../app/models/CalculatorDTO';
import { MasterDataEquipment, MasterDataType } from '../app/models/ProjectsDTO';

export const CALCULATOR_UPDATE = 'CALCULATOR_UPDATE';
export const UPDATE_WATER_CALCULATOR = 'UPDATE_WATER_CALCULATOR';
export const CALCULATOR_EXIT = 'CALCULATOR_EXIT';
export const CALCULATOR_RESET = 'CALCULATOR_RESET';
export const CALCULATOR_WATER_RESET = 'CALCULATOR_WATER_RESET';

export const SELECT_CALCULATOR_WORKSITE = 'SELECT_CALCULATOR_WORKSITE';
export const SELECT_CALCULATOR_WATER = 'SELECT_CALCULATOR_WATER';
export const UPDATE_CALCULATOR_DATA = 'UPDATE_CALCULATOR_DATA';
export const UPDATE_CALCULATOR_WATER_DETAILED = 'UPDATE_CALCULATOR_WATER_DETAILED';
export const PUT_BUILDING_CALCULATOR_DATA = 'PUT_BUILDING_CALCULATOR_DATA';
export const GET_BUILDING_CALCULATOR_DATA = 'GET_BUILDING_CALCULATOR_DATA';
export const GET_WATER_CALCULATOR_DETAILED = 'GET_WATER_CALCULATOR_DETAILED';
export const PUT_WATER_CALCULATOR_DETAILED = 'PUT_WATER_CALCULATOR_DETAILED';
export const RESET_WATER_CALCULATOR_DETAILED = 'RESET_WATER_CALCULATOR_DETAILED';
export const GET_GEAR_CARAC = 'GET_GEAR_CARAC';
export const GET_GEARS_CARACS = 'GET_GEARS_CARACS';

export type calculatorAction =
  | { type: 'CALCULATOR_UPDATE'; name: string; value: any }
  | { type: 'UPDATE_WATER_CALCULATOR'; name: string; value: any }
  | { type: 'GET_WATER_CALCULATOR_DETAILED'; projectId: number }
  | { type: 'GET_GEAR_CARAC'; masterData: MasterDataEquipment }
  | { type: 'GET_GEARS_CARACS'; masterDataGearArray: MasterDataEquipment[] }
  | {
      type: 'UPDATE_CALCULATOR_DATA';
      value: {
        energiesArray: Energy[];
        gazoleQuantity: string;
      };
      name: string;
    };

export function getGearsCaracs(masterDataGearArray: MasterDataEquipment[]) {
  return {
    type: 'GET_GEARS_CARACS',
    masterDataGearArray,
  };
}

export function getGearCarac(masterData: MasterDataEquipment) {
  return {
    type: 'GET_GEAR_CARAC',
    masterData,
  };
}

export function updateWaterCalculator(name: string, value: any) {
  return {
    type: 'UPDATE_WATER_CALCULATOR',
    name,
    value,
  };
}

export function calculatorUpdate(name: string, value: any) {
  return {
    type: 'CALCULATOR_UPDATE',
    name,
    value,
  };
}

export function getWaterCalculatorDetailed(projectId: number) {
  return {
    type: 'GET_WATER_CALCULATOR_DETAILED',
    projectId,
  };
}

export function putWaterCalculatorDetailed(id: number, data: any) {
  return {
    type: 'PUT_WATER_CALCULATOR_DETAILED',
    id,
    data,
  };
}

export function resetWaterCalculatorDetailed(id: number, data: any) {
  return {
    type: 'RESET_WATER_CALCULATOR_DETAILED',
    id,
    data,
  };
}

export function updateCalculatorWaterDetailed(name: string, value: any) {
  return {
    type: 'UPDATE_CALCULATOR_WATER_DETAILED',
    name,
    value,
  };
}

export function updateCalculatorData(
  name: string,
  value:
    | { energiesArray: Energy[]; gazoleQuantity: string }
    | { displayUnit: string }
    | {
        displayUnit: 'm3' | 'liter';
        drinkableWater: string;
        wastedWater: string;
      }
    | { evacuatedEarthWork: EvacuatedEarthWork[] }
) {
  return {
    type: 'UPDATE_CALCULATOR_DATA',
    name,
    value,
  };
}

//TODO add a type
export function putBuildingCalculatorData(id: number, data) {
  return {
    type: 'PUT_BUILDING_CALCULATOR_DATA',
    id,
    data,
  };
}

//TODO add a type
export function getBuildingCalculatorData(id: number) {
  return {
    type: 'GET_BUILDING_CALCULATOR_DATA',
    id,
  };
}

export function selectCalculatorWorksite() {
  return {
    type: 'SELECT_CALCULATOR_WORKSITE',
  };
}

export function selectCalculatorWater() {
  return {
    type: 'SELECT_CALCULATOR_WATER',
  };
}

export function calculatorExit() {
  return {
    type: 'CALCULATOR_EXIT',
  };
}

export function calculatorReset() {
  return {
    type: 'CALCULATOR_RESET',
  };
}

export function calculatorWaterReset() {
  return {
    type: 'CALCULATOR_WATER_RESET',
  };
}
