import { CompanyDTO } from '../../../../models/ProjectDTO';
import { StateDTO } from '../../../../models/GeneralDTO';
import { fetchCompanies } from '../../../../../actions/projects';
import { updateRightsValue } from '../../../../../actions/rights';
import { useDispatch, useSelector } from 'react-redux';
import { useEmployeesList } from '../../../../../hooks/useQuery/rights/crudEmployees';
import { withStyles } from '@material-ui/core/styles';
import AccordionRawEmployee from '../AccordionRawEmployee/AccordionRawEmployee';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import React, { useEffect, useState } from 'react';
import isOpenIcon from '../../../../../assets/images/rights/isOpen.png';

export type AccordionRawSummarySubProps = {
  columnSize: {
    expandIcon: string;
    licence: string;
    users: string;
    mails: string;
    company: string;
    admin: string;
    delete: string;
  };
  // expandedSummary: string,
  color: string[];
  company: CompanyDTO;
  parentAccordionLevel: number;
  // setExpandedSummary: (expandedSummary: string) => void,
  // expandedSubSummary: string,
  // setExpandedSubSummary: (expandedSubSummary: string) => void,
};

const AccordionSummarySub = withStyles({
  root: {
    margin: 0,
    padding: 0,
    // backgroundColor: '#yellow',
    minHeight: 80,
    '&$expanded': {
      minHeight: 80,
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionSub = withStyles({
  root: {
    // backgroundColor: '#yellow',

    marginBottom: 8,
    '&:first-child': {
      marginTop: 8,
    },
    transform: 'none!important',
    minHeight: 80,
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginTop: 0,
      '&:first-child': {
        marginTop: 8,
      },
      marginBottom: 0,
      minHeight: 80,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionRawSummarySub = ({
  columnSize,
  color,
  company,
  parentAccordionLevel,
}: AccordionRawSummarySubProps) => {
  const [expandedSubSummary, setExpandedSubSummary] = React.useState('');
  const [selectedCompany, setSelectedCompany] = useState<CompanyDTO | null>(null);
  const { allCompanies: companies } = useSelector(
    (state: StateDTO) => state.projects
  );
  const accordionLevel = parentAccordionLevel + 1;
  const dispatch = useDispatch();
  const {
    data: employeesList,
    isLoading,
    isError,
  } = useEmployeesList(selectedCompany);

  const handleChangeSubSummary = (company) => (event, newExpanded) => {
    setSelectedCompany(company);
    dispatch(updateRightsValue('selectedCompany', company));
    setExpandedSubSummary(newExpanded ? `panelSummary${company.id}` : '');
  };

  return (
    <AccordionSub
      square
      expanded={expandedSubSummary === `panelSummary${company.id}`}
      onChange={handleChangeSubSummary(company)}
    >
      <AccordionSummarySub
        aria-controls="panel1d-content"
        id="panel1d-header"
        style={{ backgroundColor: color[accordionLevel] }}
      >
        <div className="companyAccordionRaw">
          {/* column expand */}
          <div
            style={{ width: columnSize.expandIcon }}
            className="expandColumnWrapper"
          >
            <img
              className="expandIcon sub"
              src={isOpenIcon}
              style={{
                transform:
                  expandedSubSummary === `panelSummary${company.id}`
                    ? ''
                    : 'rotate(180deg)',
              }}
              alt=""
            />
          </div>

          {/* column companies */}
          <div style={{ width: columnSize.company }} className="titleColumnWrapper">
            {company.name}
          </div>

          {/* column users */}
          <div style={{ width: columnSize.users }} className="titleColumnWrapper">
            {/* {employeesList?.data?.count || company.EmployeesCount} */}
            {company.EmployeesCount}
          </div>
        </div>
      </AccordionSummarySub>
      {employeesList?.data.rows?.map((employee) => {
        return (
          <AccordionRawEmployee
            key={employee.id}
            columnSize={columnSize}
            licenceType={'pro'}
            employee={employee}
            company={company}
          />
        );
      })}
      {employeesList?.data.rows?.length === 0 &&
      companies.filter((childCompany) => childCompany.ParentNodeId === company.id)
        .length === 0 ? (
        <div style={{ width: '100%', fontStyle: 'italic', padding: '6px 6rem' }}>
          Aucun utilisateur
        </div>
      ) : (
        <div style={{ minHeight: '8px' }}></div>
      )}
      {isLoading && (
        <div style={{ minHeight: '0px' }}>
          <div className="spinner-wrapper-licenses">
            <div className="donut" />
          </div>
        </div>
      )}
      {companies
        .filter((childCompany) => childCompany.ParentNodeId === company.id)
        .map((company) => {
          return (
            <AccordionRawSummarySub
              key={company.id}
              columnSize={columnSize}
              color={color}
              company={company}
              parentAccordionLevel={accordionLevel}
            />
          );
        })}
    </AccordionSub>
  );
};

export default AccordionRawSummarySub;
