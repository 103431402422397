export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_LOTS = 'FETCH_LOTS';
export const LOAD_PROJECTS = 'LOAD_PROJECTS';
export const LOAD_COMPANIES = 'LOAD_COMPANIES';
export const LOAD_LOTS = 'LOAD_LOTS';
export const CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT';
export const UPDATE_FORM_VALUE = 'UPDATE_FORM_VALUE';
export const FETCH_FLUIDS_COMPATIBLE_SHEETS = 'FETCH_FLUIDS_COMPATIBLE_SHEETS';
export const UPDATE_CURRENT_FLAG = 'UPDATE_CURRENT_FLAG';
export const SET_SUPER_ADMIN = 'SET_SUPER_ADMIN';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const FETCH_ALL_GROUPS = 'FETCH_ALL_GROUPS';
export const FETCH_ALL_GROUPS_ERROR = 'FETCH_ALL_GROUPS_ERROR';
export const LOAD_ALL_GROUPS = 'LOAD_ALL_GROUPS';
export const FETCH_PROJECT_BY_ID = 'FETCH_PROJECT_BY_ID';
export const LOAD_PROJECT_BY_ID = 'LOAD_PROJECT_BY_ID';
export const FETCH_PROJECT_BY_ID_ERROR = 'FETCH_PROJECT_BY_ID_ERROR';
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';
export const SET_PROJECT_ID = 'SET_PROJECT_ID';
export const SET_LOADING_PROJECT_DETAILS = 'SET_LOADING_PROJECT_DETAILS';
export const OPEN_INDICATORS = 'OPEN_INDICATORS';
export const LOAD_USER = 'LOAD_USER';
export const SET_CURRENT_INDICATOR = 'SET_CURRENT_INDICATOR';
export const SEARCH_ADDRESS = 'SEARCH_ADDRESS';
export const LOAD_ADDRESS = 'LOAD_ADDRESS';
export const FETCH_INDICATORS_DATAS = 'FETCH_INDICATORS_DATAS';
export const LOAD_INDICATORS_DATAS = 'LOAD_INDICATORS_DATAS';
export const ADD_ZONES_SECTIONS = 'ADD_ZONES_SECTIONS';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_INPUT_VALUE = 'SET_INPUT_VALUE';
export const CHANGE_TYPE_OF_PROJECT = 'CHANGE_TYPE_OF_PROJECT';
export const MANAGEMENT_BUILDING_SECTIONS = 'MANAGEMENT_BUILDING_SECTIONS';
export const HANDLE_CONTROLLED_ZONES = 'HANDLE_CONTROLLED_ZONES';
export const UPDATE_ACTIVATED_SELECTS = 'UPDATE_ACTIVATED_SELECTS';
export const MODIFY_ACTIVATED_SELECTS = 'MODIFY_ACTIVATED_SELECTS';
export const PATCH_LOT_MODE = 'PATCH_LOT_MODE';
export const CHANGE_PROJECT_MODE = 'CHANGE_PROJECT_MODE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const DOWNLOAD_RSEE = 'DOWNLOAD_RSEE';
export const DOWNLOAD_PDF_RSEE = 'DOWNLOAD_PDF_RSEE';
export const SAVE_RSEE = 'SAVE_RSEE';
export const SAVE_PDF_RSEE = 'SAVE_PDF_RSEE';
export const LOAD_NEW_PROJECT = 'LOAD_NEW_PROJECT';
export const SET_BUILDING_SECTIONS = 'SET_BUILDING_SECTIONS';
export const RESET_BUILDING_SECTIONS = 'RESET_BUILDING_SECTIONS';
export const POST_NEW_PROJECT = 'POST_NEW_PROJECT';
export const HANDLE_RSET_MODAL = 'HANDLE_RSET_MODAL';
export const PATCH_ZONES = 'PATCH_ZONES';
export const SET_NODE_ADMINS = 'SET_NODE_ADMINS';
export const SET_PROJECT_MODAL_PAGE = 'SET_PROJECT_MODAL_PAGE';
export const FETCH_RSEE_EXPORT = 'FETCH_RSEE_EXPORT';
export const POST_RSEE_EXPORT = 'POST_RSEE_EXPORT';
export const POST_RSEE_EXPORT_SUCCESS = 'POST_RSEE_EXPORT_SUCCESS';
export const RESET_POST_RSEE_EXPORT_SUCCESS = 'RESET_POST_RSEE_EXPORT_SUCCESS';
export const MAPPING_EXISTANT_PROJECT = 'MAPPING_EXISTANT_PROJECT';
export const SET_FILE_UPLOAD_STATUS = 'SET_FILE_UPLOAD_STATUS';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const UPDATE_ARIANE_COMPANY_VALUES = 'UPDATE_ARIANE_COMPANY_VALUES';
export const UPDATE_ARIANE_FOLDER_VALUES = 'UPDATE_ARIANE_FOLDER_VALUES';
export const UPDATE_INIT_PROJECTS_LIST_MONITORING =
  'UPDATE_INIT_PROJECTS_LIST_MONITORING';
export const UPDATE_PROJECTS_LIST_MONITORING = 'UPDATE_PROJECTS_LIST_MONITORING';
export const FETCH_SHORT_BUNDLE = 'FETCH_SHORT_BUNDLE';
export const SET_DATASET = 'SET_DATASET';
export const GET_USER_METHOD_TO_LOGGED_IN = 'GET_USER_METHOD_TO_LOGGED_IN';

export const setDataset = (dataset) => ({
  type: SET_DATASET,
  dataset,
});

export const updateProjectsListMonitoring = (name, value) => ({
  type: UPDATE_PROJECTS_LIST_MONITORING,
  name,
  value,
});
export const setFileUploadStatus = (status) => ({
  type: SET_FILE_UPLOAD_STATUS,
  payload: status,
});

export const updateInitProjectsListMonitoring = (boolean) => ({
  type: UPDATE_INIT_PROJECTS_LIST_MONITORING,
  boolean,
});

//TODO type valuesElement =  {name, value}[]
export const updateArianeCompanyValues = (valuesElement) => ({
  type: UPDATE_ARIANE_COMPANY_VALUES,
  valuesElement,
});

//TODO type valuesElement =  {name, value}[]
export const updateArianeFolderValues = (valuesElement) => ({
  type: UPDATE_ARIANE_FOLDER_VALUES,
  valuesElement,
});

export const fetchProjects = (isSearching, companyId, typeProject) => ({
  type: FETCH_PROJECTS,
  isSearching,
  companyId,
  typeProject,
});

export const fetchCompanies = (isSuperAdmin) => ({
  type: FETCH_COMPANIES,
  isSuperAdmin,
});

export const fetchLots = () => ({
  type: FETCH_LOTS,
});

export const loadProjects = (projects, isSearching) => ({
  type: LOAD_PROJECTS,
  projects,
  isSearching,
});

export const loadCompanies = (companies, isSuperAdmin) => ({
  type: LOAD_COMPANIES,
  companies,
  isSuperAdmin,
});

export const loadLots = (lots) => ({
  type: LOAD_LOTS,
  lots,
});

export const createNewProject = (
  equipsBulkArray,
  linkedFluids,
  fcSheets,
  uuid,
  dataForm,
  pathId,
  idFicheFcFilenames
) => ({
  type: CREATE_NEW_PROJECT,
  equipsBulkArray,
  linkedFluids,
  fcSheets,
  uuid,
  dataForm,
  pathId,
  idFicheFcFilenames,
});

export const updateFormValue = (name, value) => ({
  type: UPDATE_FORM_VALUE,
  name,
  value,
});

export const updateCurrentFlag = (value) => ({
  type: UPDATE_CURRENT_FLAG,
  value,
});

export const setSuperAdmin = (isAdmin, isSuperAdmin) => ({
  type: SET_SUPER_ADMIN,
  isAdmin,
  isSuperAdmin,
});

export const toggleMenu = () => ({
  type: TOGGLE_MENU,
});

export const fetchAllGroups = () => ({
  type: FETCH_ALL_GROUPS,
});

export const fetchAllGroupsHandleError = (error) => ({
  type: FETCH_ALL_GROUPS_ERROR,
  error,
});

export const loadAllGroups = (response) => ({
  type: LOAD_ALL_GROUPS,
  response,
});

export const fetchProjectById = (id, indicatorId, byPassForS3, importToLdc) => ({
  type: FETCH_PROJECT_BY_ID,
  id,
  indicatorId,
  byPassForS3,
  importToLdc,
});

export const loadProjectById = (response, importToLdc) => ({
  type: LOAD_PROJECT_BY_ID,
  response,
  importToLdc,
});

export const fetchProjectByIdHandleError = (error) => ({
  type: FETCH_PROJECT_BY_ID_ERROR,
  error,
});

export const setCurrentProject = (project) => ({
  type: SET_CURRENT_PROJECT,
  project,
});

export const setProjectId = (id) => ({
  type: SET_PROJECT_ID,
  id,
});

export const setLoadingProjectDetails = () => ({
  type: SET_LOADING_PROJECT_DETAILS,
});

export const openIndicators = (bool) => ({
  type: OPEN_INDICATORS,
  bool,
});

export const loadUser = (user) => ({
  type: LOAD_USER,
  user,
});

export const setCurrentIndicator = (id) => ({
  type: SET_CURRENT_INDICATOR,
  id,
});

export const searchAddress = (search, countryCode) => ({
  type: SEARCH_ADDRESS,
  search,
  countryCode,
});

export const loadAddress = (address) => ({
  type: LOAD_ADDRESS,
  address,
});

export const fetchIndicatorsDatas = (id) => ({
  type: FETCH_INDICATORS_DATAS,
  id,
});

export const loadIndicatorsDatas = (data, id) => ({
  type: LOAD_INDICATORS_DATAS,
  data,
  id,
});

export const addZonesSections = (index, remove, t) => ({
  type: ADD_ZONES_SECTIONS,
  index,
  remove,
  t,
});

export const setSearchValue = (data) => ({
  type: SET_SEARCH_VALUE,
  data,
});

export const setInputValue = (data) => ({
  type: SET_INPUT_VALUE,
  data,
});

export const changeTypeOfProject = () => ({
  type: CHANGE_TYPE_OF_PROJECT,
});

export const managementBuildingSections = (index, remove, t) => ({
  type: MANAGEMENT_BUILDING_SECTIONS,
  index,
  remove,
  t,
});

export const handleControlledZones = (buildingIndex, zoneIndex, input, value) => ({
  type: HANDLE_CONTROLLED_ZONES,
  buildingIndex,
  zoneIndex,
  input,
  value,
});

export const updateActivatedSelects = (id, value) => ({
  type: UPDATE_ACTIVATED_SELECTS,
  id,
  value,
});

export const modifyActivatedSelects = (newSelects) => ({
  type: MODIFY_ACTIVATED_SELECTS,
  newSelects,
});

export const patchLotMode = (lotMode) => ({
  type: PATCH_LOT_MODE,
  lotMode,
});

export const changeProjectMode = (projectId) => ({
  type: CHANGE_PROJECT_MODE,
  projectId,
});

export const userLogout = () => ({
  type: USER_LOGOUT,
});

export const downloadRsee = (projectId) => ({
  type: DOWNLOAD_RSEE,
  projectId,
});

export const downloadRseePdf = (projectId, data) => ({
  type: DOWNLOAD_PDF_RSEE,
  projectId,
  data,
});

export const saveRsee = (xml, projectName) => ({
  type: SAVE_RSEE,
  xml,
  projectName,
});

export const savePdfRsee = (pdf, projectName) => ({
  type: SAVE_PDF_RSEE,
  pdf,
  projectName,
});

export const loadNewProject = (project) => ({
  type: LOAD_NEW_PROJECT,
  project,
});

export const resetBuildingSections = () => ({
  type: RESET_BUILDING_SECTIONS,
});

export const postNewProject = (data) => ({
  type: POST_NEW_PROJECT,
  data,
});

export const fetchFluidsCompatibleSheets = (idIniesArray) => ({
  type: FETCH_FLUIDS_COMPATIBLE_SHEETS,
  idIniesArray,
});

export const handleRsetModal = (
  open,
  equipsBulkArray,
  fluidsArray,
  linkedFluids
) => ({
  type: HANDLE_RSET_MODAL,
  open,
  equipsBulkArray,
  fluidsArray,
  linkedFluids,
});

export const patchZones = (datas, reason, settingsData) => ({
  type: PATCH_ZONES,
  datas,
  reason,
  settingsData,
});

export const setNodeAdmins = (companyAdmins, orgaAdmins) => ({
  type: SET_NODE_ADMINS,
  companyAdmins,
  orgaAdmins,
});

export const setProjectModalPage = (page) => ({
  type: SET_PROJECT_MODAL_PAGE,
  page,
});

export const fetchRseeExport = () => ({
  type: FETCH_RSEE_EXPORT,
});

export const postRseeExport = (data, shouldStartExportRsee = false) => ({
  type: POST_RSEE_EXPORT,
  data,
  shouldStartExportRsee,
});

export const postRseeExportSuccess = (data) => ({
  type: POST_RSEE_EXPORT_SUCCESS,
  data,
});

export const resetPostRseeExportSuccess = () => ({
  type: RESET_POST_RSEE_EXPORT_SUCCESS,
});

export const mappingExistantProject = () => ({
  type: MAPPING_EXISTANT_PROJECT,
});

export const deleteProject = () => ({
  type: DELETE_PROJECT,
});

export const getUserMethodToLoggedIn = () => ({
  type: GET_USER_METHOD_TO_LOGGED_IN,
});
