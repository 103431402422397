import { StateDTO } from '../../../app/models/GeneralDTO';
import { fetchProjectsList } from '../../../apiCallFunctions/project/project';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import API from '../../../app/services/API';

const api = new API();

export const useGetProjectsBySearch = (
  projectInput,
  projectTypeFilter,
  selectedCompanyId,
  onSuccess
) => {
  const { isSuperAdmin } = useSelector((state: StateDTO) => state.projects);
  const isEnabled = projectInput !== '';

  return useInfiniteQuery(
    ['projectsBySearch', projectInput, projectTypeFilter],
    (data) =>
      fetchProjectsList(
        projectInput,
        projectTypeFilter,
        selectedCompanyId,
        data,
        isSuperAdmin
      ),
    {
      enabled: isEnabled,
      refetchOnWindowFocus: false,
      onSuccess,
      onError: (error) => {
        console.log('error in getProjects', error?.message);
      },
      getNextPageParam: (lastPage, pages) => {
        // console.log('lastPage',
        //   lastPage);
        // console.log('pages',
        //   pages);

        const pageNumber = Math.ceil(lastPage.count / 10);

        if (pages.length < pageNumber) {
          return pages.length;
        } else {
          return undefined;
        }
      },
    }
  );
};
