import { Button } from '@photocarbone/nooco-ui';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../../../containers/BreadCrumb';
import HelpIcon from '@material-ui/icons/Help';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import ModalAsk from './modalAsk.js';
import { sendMessageSlack, sendMessageTrello } from '../../../../actions/import';

const BreadcrumbModal = ({
  updateFormValue,
  openBreadcrumb,
  groups,
  currentProject,
  zones,
  selectedAllotmentNode,
  handleEdit,
  verifyRowsEquipment,
  addEquipmentScreen,
  bundle,
  onChangeSimulator,
  handleChangeAllotmentNodes,
  variantNumber,
  onChangeListContributor,
}) => {
  const { mdActionOpen, currentLine, importDatas } = useSelector(
    (state) => state.imports
  );
  const { allGroups, currentBundleLine, modalAsk } = useSelector(
    (state) => state.projects
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSelectionBreadcrumb = (
    selectedLot,
    selectedFonction,
    selectedCategory,
    selectedMaterial,
    selectedType
  ) => {
    if (
      selectedLot &&
      selectedFonction &&
      selectedCategory &&
      selectedMaterial &&
      selectedType
    ) {
      updateFormValue(
        'selectedAllotmentNode',
        allGroups.flatAllotments.find((el) => el.id === Number(selectedType))
      );
    }
  };

  const resetStates = () => {
    updateFormValue('selectedAllotmentNode', null);
    updateFormValue('selectedLot', null);
    updateFormValue('selectedFonction', null);
    updateFormValue('selectedCategory', null);
    updateFormValue('selectedMaterial', null);
    updateFormValue('selectedType', null);
    updateFormValue('currentLine', null);
    updateFormValue('openBreadcrumb', false);
    updateFormValue('disabledBreadcrumbSelects', [false, true, true, true, true]);
  };

  const handleAllotmentNodeSelectedDependingOnScreen = () => {
    if (addEquipmentScreen) {
      updateFormValue('nodeSearchValue', selectedAllotmentNode);
      updateFormValue(
        'searchValue',
        allGroups.type.find(
          (md) => md.id === selectedAllotmentNode.MasterDataEquipmentId
        )
      );
      updateFormValue('openBreadcrumb', false);
      return;
    } else if (bundle) {
      handleChangeAllotmentNodes(selectedAllotmentNode, 'Nooco', currentBundleLine);
      updateFormValue('openBreadcrumb', false);
      return;
    } else if (onChangeSimulator) {
      onChangeSimulator(selectedAllotmentNode.id, variantNumber);
    } else if (onChangeListContributor) {
      onChangeListContributor(selectedAllotmentNode);
    } else if (!mdActionOpen) {
      handleEdit('AllotmentNode', currentLine.id, null, selectedAllotmentNode);

      setTimeout(() => {
        verifyRowsEquipment(currentLine.id);
        resetStates();
      }, 300);
    } else {
      updateFormValue('mdToLines', selectedAllotmentNode);
    }
    updateFormValue('openBreadcrumb', false);
  };

  return (
    <div>
      <Modal
        show={openBreadcrumb}
        onHide={() => updateFormValue('openBreadcrumb', false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('APP.LINE.BREADCRUMBMODAL.PH_C4A95D', {
              defaultValue: 'Recherche avancée',
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Breadcrumb
            onSelect={handleSelectionBreadcrumb}
            importBreadcrumb={true}
            groups={
              groups?.constructor !== Array
                ? []
                : zones?.find((el) => el.type === 'buildingSite')
                ? [
                    ...groups?.filter((el) => {
                      return (
                        el.lot !== 'Eau' &&
                        el.lot !== 'Energie' &&
                        !el.materiel.includes('DONNEE FORFAITAIRE')
                      );
                    }),
                  ]
                : [
                    ...groups?.filter((el) => {
                      return (
                        el.lot !== 'Eau' &&
                        el.lot !== 'Energie' &&
                        el.lot !== 'Terre' &&
                        !el.materiel.includes('DONNEE FORFAITAIRE')
                      );
                    }),
                  ]
            }
            currentProject={currentProject}
            isRe2020={currentProject?.isRe2020}
            addPage={true}
          />

          <Tooltip
            title={t('APP.LINE.BREADCRUMBMODAL.PH_E82655', {
              defaultValue:
                "Faire une demande d'ajout ou d'information à l'équipe Nooco",
            })}
          >
            <p
              onClick={() => {
                let isImportLinesStored =
                  importDatas?.ProjectImportElems?.length > 0;
                if (isImportLinesStored) updateFormValue('lineId', currentLine?.id);
                updateFormValue('modalAsk', true);
              }}
              style={{
                cursor: 'pointer',
                color: '#939da4',
                fontStyle: 'italic',
                display: 'inline-block',
              }}
            >
              <HelpIcon
                style={{
                  color: 'black',
                  marginLeft: '1em',
                  marginRight: '5px',
                }}
              />

              {t('APP.LINE.BREADCRUMBMODAL.PH_D166F6', {
                defaultValue: 'Equipement inexistant ? Demander une étude',
              })}
            </p>
          </Tooltip>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => resetStates()}
            className="close-button"
            text={t('APP.LINE.BREADCRUMBMODAL.PH_F762B9', {
              defaultValue: 'Fermer',
            })}
          />

          <Button
            disabled={!selectedAllotmentNode}
            className="purple-button"
            onClick={() => {
              handleAllotmentNodeSelectedDependingOnScreen();
            }}
            text={t('APP.LINE.BREADCRUMBMODAL.PH_E56BED', {
              defaultValue: 'Confirmer',
            })}
          />
        </Modal.Footer>
      </Modal>
      <ModalAsk
        modalAsk={modalAsk}
        updateFormValue={updateFormValue}
        sendMessageSlack={() => {
          dispatch(sendMessageSlack(currentLine?.id));
        }}
        sendMessageTrello={() => {
          dispatch(sendMessageTrello(currentLine?.id));
        }}
      />
    </div>
  );
};

export default BreadcrumbModal;
