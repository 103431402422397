import './modelingEquipment.scss';
import { StateDTO } from '../../../../models/GeneralDTO';
import { useSelector } from 'react-redux';
import Caracteristic from './Caracteristic/Caracteristic';
import CarbonEstimation from './CarbonEstimation/CarbonEstimation';
import CarbonWeight from './CarbonWeight/CarbonWeight';
import React from 'react';
import SelectModeling from './SelectModeling/SelectModeling';

const ModelingEquipment = ({ carbonWeightFormInstance }) => {
  const { addDisplay, modeling } = useSelector(
    (state: StateDTO) => state.equipments
  );
  const { isCarbonWeightSelected } = modeling;

  const stepToDisplay = () => {
    if (addDisplay?.includes('4') && isCarbonWeightSelected === false) {
      return <CarbonEstimation />;
    }

    if (addDisplay?.includes('4') && isCarbonWeightSelected === true) {
      return <CarbonWeight carbonWeightFormInstance={carbonWeightFormInstance} />;
    }

    if (addDisplay?.includes('3')) {
      return <SelectModeling carbonWeightFormInstance={carbonWeightFormInstance} />;
    }

    if (addDisplay?.includes('2')) {
      return <Caracteristic />;
    }
  };

  return <div className="modelingWrapper">{stepToDisplay()}</div>;
};

export default ModelingEquipment;
