import './projectForm.scss';
import { createNewProject, updateFormValue } from '../../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Alert from '@material-ui/lab/Alert';
import ArchitectureForm from './ArchitectureForm';
import GeneralForm from './GeneralForm';
import LabelForm from './LabelForm';
import Snackbar from '@material-ui/core/Snackbar';

import { Button } from '@photocarbone/nooco-ui';
import { StateDTO } from '../../../models/GeneralDTO';
import { isOnlyGeneralFormDisplayed } from '../CreationProjectFunction/creationProjectFunction';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import generateUUID from '../../../shared/generateUUID';
import { useTranslation } from 'react-i18next';
import {
  CREATION_STEPS,
  CREATION_STEPS_TRAD_KEYS,
} from '../../../globalVariable/creationProjectVariable';

const ProjectForm = () => {
  const [uuid, setUUID] = useState<string | any>(null);
  const [snackMessage, setMessageSnack] = useState<[string, string]>(['', 'info']);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    rsetmodalOpen,
    linkedFluids,
    fcSheetsFiles,
    equipsBulkArray,
    fluidsArray,
    redirectionId,
    idFicheFcFilenames,
  } = useSelector((state: StateDTO) => state.projects);
  const { arianeFolder, arianeCompany } = useSelector(
    (state: StateDTO) => state.projects.projectsListMonitoring
  );
  const { isExistChildrenCompanies, selectedCompanyId } = arianeCompany;
  const { selectedPathId } = arianeFolder;
  const { creationSteps, creationProjectLoading } = useSelector(
    (state: StateDTO) => state.projects.creationProject
  );

  const isChildrenCompanySelectedInNavigation =
    typeof selectedCompanyId === 'number' && !isExistChildrenCompanies;
  const isOnlyGeneralFormToDisplay = isOnlyGeneralFormDisplayed(creationSteps, t);

  const selectedPathIdToSend = () => {
    if (isChildrenCompanySelectedInNavigation) {
      return selectedPathId;
    }

    return null;
  };

  // Management data for form

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    setError,
    reset,
  } = useForm();

  useEffect(() => {
    if (redirectionId) {
      history.push(`/projects/${redirectionId}`);
      dispatch(updateFormValue('redirectionId', null));
      reset();
    }
  }, [redirectionId]);

  useEffect(() => {
    dispatch(updateFormValue('creationProjectForm', true));

    return () => {
      dispatch(updateFormValue('creationProjectForm', false));
      dispatch(updateFormValue('refUnit', null));
    };
  }, []);

  useEffect(() => {
    if (!uuid) setUUID(generateUUID());
  }, [uuid]);

  const handleRsetModalValidation = () => {
    if (Object.entries(linkedFluids).length < fluidsArray.length) {
      setMessageSnack([
        t('APP.PROJECTFORM.INDEX.PH_934DCB', {
          defaultValue:
            'Vos fluides ne sont pas correctement reliés aux équipements.',
        }),
        'warning',
      ]);

      return false;
    }

    const fcSheets = equipsBulkArray.filter((elem) => elem.donnees_configurateur);
    const fcCount = fcSheets.length;
    const mdCount = fcSheets.filter((sheet) => sheet.AllotmentNodeId).length;

    if (fcCount !== Object.keys(fcSheetsFiles).length) {
      setMessageSnack([
        t('APP.PROJECTFORM.INDEX.PH_C7C3D1', {
          defaultValue: "Vous n'avez pas importé toutes vos fiches configurées.",
        }),
        'warning',
      ]);

      return false;
    }

    if (mdCount !== fcCount) {
      setMessageSnack([
        t('APP.PROJECTFORM.INDEX.PH_55B9FB', {
          defaultValue:
            "Vous n'avez pas selectionné les équipements/matériaux pour toutes vos fiches configurées.",
        }),
        'warning',
      ]);

      return false;
    }

    return true;
  };

  return (
    <>
      <Snackbar
        onClose={() => setMessageSnack(['', 'info'])}
        open={snackMessage[0] !== ''}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackMessage[0]}
      >
        <Alert
          onClose={() => setMessageSnack(['', 'info'])}
          severity={snackMessage[1]}
        >
          {snackMessage[0]}
        </Alert>
      </Snackbar>
      {/* form */}
      <div
        className="projectform-wrapper"
        style={
          isOnlyGeneralFormToDisplay
            ? {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }
            : undefined
        }
      >
        {/* general and label form */}
        <div
          className="projectform-left"
          style={{
            display: rsetmodalOpen ? 'none' : undefined,
            width: isOnlyGeneralFormToDisplay ? '50%' : undefined,
          }}
        >
          {/* General Form */}
          <GeneralForm
            uuid={uuid}
            errors={errors}
            setValue={setValue}
            control={control}
            setError={setError}
          />
          {/* Label Form */}
          {!isOnlyGeneralFormToDisplay &&
            !creationSteps.includes(
              t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.GLOBAL_MONITORING])
            ) && <LabelForm />}
        </div>
        {/* Architecture Form */}
        {!isOnlyGeneralFormToDisplay && (
          <ArchitectureForm
            uuid={uuid}
            errors={errors}
            control={control}
            setValue={setValue}
            setMessageSnack={setMessageSnack}
          />
        )}
      </div>
      {/* footer button */}
      <Button
        onClick={() =>
          handleSubmit((data) => {
            if (rsetmodalOpen) {
              if (!handleRsetModalValidation()) return;
            }

            if (rsetmodalOpen && fcSheetsFiles) {
              for (const [key, value] of Object.entries(fcSheetsFiles)) {
                fcSheetsFiles[key] = value.name;
              }
            }

            dispatch(
              createNewProject(
                equipsBulkArray,
                linkedFluids,
                fcSheetsFiles,
                uuid,
                data,
                selectedPathIdToSend(),
                idFicheFcFilenames
              )
            );
          })()
        }
        className="purple-button"
        style={{ display: 'flex', marginLeft: 'auto', marginTop: '0.5em' }}
        loading={creationProjectLoading}
        text={t('APP.PROJECTFORM.INDEX.BUTTON.CONFIRM', {
          defaultValue: 'Confirmer',
        })}
      />
    </>
  );
};

export default ProjectForm;
