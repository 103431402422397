export const TYPE_EPCM = 'epcm';
export const TYPE_RE2020 = 're2020';
export const TYPE_NOOCO = 'nooco';
export const TYPE_EXPLOITATION = 'exploitation';
export const TYPE_AIRPORT = 'airport';
export const TYPE_DATACENTER = 'datacenter';
export const TYPE_OUTDOOR = 'outdoor';
export const TYPE_RENOVATION = 'renovation';
export const TYPE_MAPPINGREF = 'mappingRef';
export const TYPE_PROTOTYPE = 'prototype';
export const TYPE_BBCA = 'bbca';
export const TYPE_SIMULATOR = 'quickSim';
export const TYPE_GLOBAL_MONITORING = 'globalMonitoring';

export const TYPE_NOOCO_ID = 1;
export const TYPE_RE2020_ID = 2;
export const TYPE_EPCM_ID = 3;
export const TYPE_RENOVATION_ID = 4;
export const TYPE_EXPLOITATION_ID = 5;
export const TYPE_AIRPORT_ID = 6;
export const TYPE_DATACENTER_ID = 7;
export const TYPE_OUTDOOR_ID = 8;
export const TYPE_MAPPINGREF_ID = 9;
export const TYPE_QUICKSIM_ID = 10;
export const TYPE_PROTOTYPE_ID = 11;
export const TYPE_BBCA_ID = 12;
export const TYPE_GLOBAL_MONITORING_ID = 13;

export const TEMPLATE_TYPE_NAME = {
  EPCM: TYPE_EPCM,
  RE2020: TYPE_RE2020,
  NOOCO: TYPE_NOOCO,
  EXPLOITATION: TYPE_EXPLOITATION,
  AIRPORT: TYPE_AIRPORT,
  DATACENTER: TYPE_DATACENTER,
  OUTDOOR: TYPE_OUTDOOR,
  RENOVATION: TYPE_RENOVATION,
  MAPPINGREF: TYPE_MAPPINGREF,
  PROTOTYPE: TYPE_PROTOTYPE,
  BBCA: TYPE_BBCA,
  SIMULATOR: TYPE_SIMULATOR,
  GLOBAL_MONITORING: TYPE_GLOBAL_MONITORING,
};

export const TEMPLATE_TYPE_ID = {
  NOOCO: TYPE_NOOCO_ID,
  RE2020: TYPE_RE2020_ID,
  EPCM: TYPE_EPCM_ID,
  RENOVATION: TYPE_RENOVATION_ID,
  EXPLOITATION: TYPE_EXPLOITATION_ID,
  AIRPORT: TYPE_AIRPORT_ID,
  DATACENTER: TYPE_DATACENTER_ID,
  OUTDOOR: TYPE_OUTDOOR_ID,
  MAPPINGREF: TYPE_MAPPINGREF_ID,
  SIMULATOR: TYPE_QUICKSIM_ID,
  PROTOTYPE: TYPE_PROTOTYPE_ID,
  BBCA: TYPE_BBCA_ID,
  GLOBAL_MONITORING: TYPE_GLOBAL_MONITORING_ID,
};

export const REGULATORY_PROJECTS = [
  TEMPLATE_TYPE_NAME.EPCM,
  TEMPLATE_TYPE_NAME.RE2020,
  TEMPLATE_TYPE_NAME.BBCA,
];

export const BUILDING_TYPE_ID = {
  COLLECTIVE_RESIDENTIAL: 1,
  INDIVIDUAL_HOUSE: 2,
  HEALTHCARE_WITH_ACCOMMODATION: 3,
  RESTAURANT: 7,
  OFFICE: 8,
  COMMERCE: 9,
  CHILDCARE_CENTER: 10,
  PRIMARY_EDUCATION: 11,
  GYMNASIUM_OR_SPORTS_HALL: 12,
  HEALTH_FACILITY: 13,
  INDUSTRY_OR_WORKSHOP: 14,
  TRIBUNAL: 15,
  TERMINAL: 16,
  SECONDARY_EDUCATION: 17,
  YOUTH_HOSTEL: 18,
  UNIVERSITY_AND_RESEARCH: 19,
  HOSTEL: 20,
  UNIVERSITY_RESIDENCE: 38,
  DATACENTER: 39,
  AIRPORT: 40,
  OUTDOOR_INFRASTRUCTURE: 41,
  PARKING: 67,
  MUSEUM: 100,
};
//TODO TO COMPLETE BUILDING_TYPES
export const BUILDING_TYPE_OFFICE = 'Bureaux';
export const BUILDING_TYPE_SECONDARY_EDUCATION = 'Enseignement secondaire';
export const BUILDING_TYPE_PRIMARY_EDUCATION = 'Enseignement primaire';
export const BUILDING_TYPE_HOUSE_DETACHED = 'Maison individuelle ou accolée';
export const BUILDING_TYPE_MULTI_UNIT_BUILDING = 'Logement collectif';

export const ALLOTMENT_IDS = {
  NOOCO: 1,
  RE2020: 2,
  EPCM: 34,
};

export const PROJECT_TYPE_ALLOTMENT_ID = {
  EPCM: ALLOTMENT_IDS.EPCM,
  RE2020: ALLOTMENT_IDS.RE2020,
  NOOCO: ALLOTMENT_IDS.NOOCO,
  EXPLOITATION: ALLOTMENT_IDS.NOOCO,
  AIRPORT: ALLOTMENT_IDS.NOOCO,
  DATACENTER: ALLOTMENT_IDS.NOOCO,
  OUTDOOR: ALLOTMENT_IDS.NOOCO,
  RENOVATION: ALLOTMENT_IDS.NOOCO,
  MAPPINGREF: ALLOTMENT_IDS.NOOCO,
  PROTOTYPE: ALLOTMENT_IDS.NOOCO,
  BBCA: ALLOTMENT_IDS.EPCM,
  SIMULATOR: ALLOTMENT_IDS.NOOCO,
  GLOBAL_MONITORING: ALLOTMENT_IDS.NOOCO,
};

export const PROJECTS_TYPE_NAMES_FROM_TYPE_ALLOTMENT_EPCM = [
  TEMPLATE_TYPE_NAME.EPCM,
  TEMPLATE_TYPE_NAME.BBCA,
];

export const PROJECTS_TYPE_NAMES_TOTAL_IMPACT = [
  TEMPLATE_TYPE_NAME.NOOCO,
  TEMPLATE_TYPE_NAME.RE2020,
  TEMPLATE_TYPE_NAME.EPCM,
  TEMPLATE_TYPE_NAME.BBCA,
]

export const REFERENCE_TYPE_NAME = {
  RE2020: 'RE2020',
  EPCM: 'E+C-',
};

export const PROJECT_TYPE_TITLE = {
  RE2020: 'RE2020',
  BBCA: 'BBCA',
  EPCM: 'E+C-',
  NOOCO: 'Nooco',
};

export const returnBuildingTypeNameFromId = (id) => {
  switch (id) {
    case 8:
      return BUILDING_TYPE_OFFICE;
    case 17:
      return BUILDING_TYPE_SECONDARY_EDUCATION;
    case 11:
      return BUILDING_TYPE_PRIMARY_EDUCATION;
    case 2:
      return BUILDING_TYPE_HOUSE_DETACHED;
    case 1:
      return BUILDING_TYPE_MULTI_UNIT_BUILDING;
    default:
      return '';
  }
};

export const typeProjectForSimplifiedEnergy = [
  TYPE_AIRPORT,
  TYPE_DATACENTER,
  TYPE_OUTDOOR,
  TYPE_RENOVATION,
];

export const returnTemplateType = (name) => {
  switch (name) {
    case 'renovation':
      return 'Rénovation libre';
    case 'exploitation':
      return 'Travaux exploitation';
    case 'airport':
      return 'Aéroport';
    case 'datacenter':
      return 'Datacenter';
    case 'outdoor':
      return 'Infrastructure extérieure';
    case 'prototype':
      return 'Prototype';
  }
};

export const MODELED_PRODUCT_LOT_ID = 7591;
