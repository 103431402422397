import '../../Projects.css';
import '../LifeCycleTabGraphTooltip.scss';
import { ResponsiveLine, Serie, SliceTooltipProps } from '@nivo/line';
import { createProjectStructure } from '../../../shared/parsingDatas';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Spinner from '../../../shared/Spinner';
import { getUnitConversion, getFormattedWeightNumber } from '../../../services/mathematics/formatNumber';
import { createDataSet, createPeriodicDataSet } from './LifeCycleTemporalGraphDataFormatters';
import { useTranslation } from 'react-i18next';
import { PROJECTS_TYPE_NAMES_TOTAL_IMPACT, TEMPLATE_TYPE_ID } from '../../../globalVariable/typeProject.js';
import { AllotmentNodeDTO, ProjectDTO } from '../../../models/ProjectDTO';
import { StateDTO } from '../../../models/GeneralDTO';
import { CurrentAllotmentDTO, CurrentIndicatorDTO } from '../../../models/ProjectsDTO';
import { Lifespan } from '@photocarbone/nooco-shared-api-def';
import { TFunction } from 'i18next';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const NUMBER_OF_PLOT_TICKS_FOR_PERIOD_GRAPH = 100;

type LifeCycleTemporalGraphOptions = {
  bottomAxis: Object,
  xScale: Object,
  xFormat: string,
}

const SHARED_BOTTOM_AXIS_PROPS = {
  orient: 'bottom',
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legendOffset: 36,
  legendPosition: 'end',
};

const REGULAR_OPTIONS: LifeCycleTemporalGraphOptions = {
  bottomAxis: {
    ...SHARED_BOTTOM_AXIS_PROPS,
    legend: 'Années'
  },
  xScale: {
    type: 'linear',
  },
  xFormat: '',
};

const PERIOD_OPTIONS: LifeCycleTemporalGraphOptions = {
  bottomAxis: {
    ...SHARED_BOTTOM_AXIS_PROPS,
    format: '%d-%m-%y',
    legend: 'Date',
  },
  xScale: {
    type: 'time',
    format: '%d-%m-%Y',
    precision: 'day',
    useUTC: false,
  },
  xFormat: 'time:%Y-%m-%d',
};

type LifeCycleTemporalGraphProps = {
  onTab: boolean;
  equipments: any[];
  title: string;
  currentIndicator: CurrentIndicatorDTO;
  projectDdv: number;
  graphData: any;
  currentAllotment: CurrentAllotmentDTO;
  projectArea: number;
  refUnit: string;
  parsedZones: any[];
  isStacked: boolean;
  currentProject: ProjectDTO;
  zones: any[];
};

function getGraphProp(projectTypeId: number, key: string): any {
  const options = projectTypeId === TEMPLATE_TYPE_ID.GLOBAL_MONITORING ? PERIOD_OPTIONS : REGULAR_OPTIONS;

  return options[key];
}

function getProjectDatesArray(projectExploitContract: Lifespan): Date[] {
  const projectDates: Date[] = [];

  if (projectExploitContract && projectExploitContract.length !== 0 && projectExploitContract[0]?.value && projectExploitContract[1]?.value) {
    const startDate = new Date(projectExploitContract[0].value);
    const endDate = new Date(projectExploitContract[1].value);

    const interval = (endDate.getTime() - startDate.getTime()) / (NUMBER_OF_PLOT_TICKS_FOR_PERIOD_GRAPH - 1);

    for (let i = 0; i < NUMBER_OF_PLOT_TICKS_FOR_PERIOD_GRAPH; i++) {
      projectDates.push(new Date(startDate.getTime() + i * interval));
    }
  }

  return projectDates;
}

function getTooltipContent(t: TFunction<"translation", undefined>, sliceProps: SliceTooltipProps, isPeriod: boolean = false, isStacked: boolean = false) {
  const slice = sliceProps.slice;
  const style: CSSProperties = isStacked ? { 'padding-bottom': '8px' } : {};
  let total = slice.points.reduce((p: number, c) => p + (c.data.y as number), 0);
  const tooltipLabelKey = isPeriod ? 'xFormatted' : 'x';
  const tooltTipLabelTitle = isPeriod ? '' : `${t('APP.LIFECYCLE.LIFECYCLETEMPORALGRAPH.PH_667F9B', { defaultValue: 'Année:', })}`;
  const tooltipLabel = tooltTipLabelTitle + ' ' + slice.points[0].data[tooltipLabelKey] as string;

  const tooltip = (
    <div className="graphTooltip">
      <div className="temporalTotal" style={style}>
        <strong style={{ color: '#40C5AF' }}>
          {t('APP.LIFECYCLE.LIFECYCLETEMPORALGRAPH.PH_D0BE79', {
            defaultValue: 'Total:',
          })}{' '}
          {getFormattedWeightNumber(total)}
        </strong>
        <strong style={{ color: 'black' }}>{' - '}</strong>
        <span style={{ opacity: 0.7 }}>
          {tooltipLabel}
        </span>
      </div>
      {
        isStacked &&
        <div className="temporalList">
          {slice.points.map((point) => {
            return (
              <div
                key={point.id}
                style={{
                  color: point.serieColor,
                  padding: '3px 0',
                }}
              >
                <strong style={{}}>{point.serieId}</strong>
                <strong style={{ color: 'black', opacity: 0.7 }}>
                  {' - '}
                  {point.data.yFormatted}
                </strong>
              </div>
            );
          })}
        </div>
      }
    </div>
  );

  return tooltip;
}

const LifeCycleTemporalGraph: React.FC<LifeCycleTemporalGraphProps> = ({
  onTab,
  equipments,
  title,
  currentIndicator,
  projectDdv,
  graphData,
  currentAllotment,
  projectArea,
  refUnit,
  parsedZones,
  isStacked,
  currentProject,
  zones,
}) => {
  const [loading, setLoading] = useState(false);
  const [datas, setDatas]: [Serie[], Function] = useState([]);
  const [stackedState, setStackedState]: [boolean | undefined, Function] = useState(undefined);
  const [minY, setMinY]: [number | string, Function] = useState(0);
  const { t } = useTranslation();

  const { allGroups } = useSelector((state: StateDTO) => state.projects);
  const applyPeriodPlotOptions = currentProject.ProjectTypeId === TEMPLATE_TYPE_ID.GLOBAL_MONITORING;
  const allotmentNodesMap = new Map<number, AllotmentNodeDTO>(allGroups.flatAllotments.map((an) => [an.id, an]));
  let initialDatas = null;
  let childIdMap = new Map();

  for (let allotmentNode of currentAllotment.AllotmentNodes) {
    if (allotmentNode.ParentId === null) continue;

    if (!childIdMap.has(allotmentNode.ParentId)) {
      childIdMap.set(allotmentNode.ParentId, []);
    }

    childIdMap.get(allotmentNode.ParentId)?.push(allotmentNode.id);
  }

  const yAxisLegend = `${currentIndicator.shortName} (${getUnitConversion(PROJECTS_TYPE_NAMES_TOTAL_IMPACT.includes(currentProject.ProjectType.name) ? currentProject.Ic[1].IcProjet : currentProject.dynamicAcv)}/m²)`;

  useEffect(() => {
    if (equipments.length > 0) {
      setLoading(true);

      setStackedState(isStacked);
      initialDatas =
        graphData ||
        createProjectStructure(
          currentAllotment,
          currentProject.Sheets,
          zones,
          currentProject
        );

      if (initialDatas) {
        let newDataSet: { datas: Serie[], min: number | string } | null = null;

        if (applyPeriodPlotOptions) {
          const projectDates = getProjectDatesArray(currentProject.exploitContract);

          newDataSet = createPeriodicDataSet(
            initialDatas,
            projectArea,
            currentIndicator,
            currentAllotment,
            equipments,
            refUnit,
            childIdMap,
            isStacked,
            projectDates
          );
        } else {
          newDataSet = createDataSet(
            initialDatas,
            parsedZones,
            projectDdv,
            projectArea,
            currentIndicator,
            currentAllotment,
            equipments,
            refUnit,
            allotmentNodesMap,
            childIdMap,
            isStacked
          );
        }

        setMinY(newDataSet.min >= 0 ? 0 : 'auto');
        setDatas(newDataSet.datas);
        setLoading(false);
      }
    }
  }, [equipments, currentIndicator, isStacked, graphData]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (equipments.length === 0) {
    return (
      <div id="no-data-container">
        {t('APP.LIFECYCLE.LIFECYCLETEMPORALGRAPH.PH_E10D3A', {
          defaultValue: 'Pas de donnée',
        })}
      </div>
    );
  }

  return (
    <div
      id="stacked-vertical-bars-container"
      style={
        onTab
          ? { padding: '1em', height: '63.6vh', width: '95%' }
          : { height: 'auto' }
      }
    >
      {title ? <h4>{title}</h4> : null}
      {!loading && (
        <ResponsiveLine
          data={datas}
          margin={{ top: 60, right: 30, bottom: 140, left: 70 }}
          xFormat={getGraphProp(currentProject.ProjectTypeId, 'xFormat')}
          xScale={getGraphProp(currentProject.ProjectTypeId, 'xScale')}
          yScale={{
            type: 'linear',
            min: minY,
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          markers={[
            {
              axis: 'y',
              value: 0,
              lineStyle: { stroke: '#AAA', strokeWidth: 2 },
              legend: '',
              legendPosition: 'bottom-left',
            },
          ]}
          curve="linear"
          yFormat={(value) => getFormattedWeightNumber(value)}
          axisTop={null}
          sliceTooltip={(slice) => getTooltipContent(t, slice, applyPeriodPlotOptions, isStacked)}
          axisRight={null}
          axisBottom={getGraphProp(currentProject.ProjectTypeId, 'bottomAxis')}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: yAxisLegend,
            legendOffset: -60,
            legendPosition: 'end',
            format: (value) => getFormattedWeightNumber(value),
          }}
          colors={(d) => d.color}
          pointSize={0}
          pointBorderColor={{
            from: 'color',
            modifiers: [['darker', 0.3]],
          }}
          pointBorderWidth={2}
          pointLabelYOffset={-12}
          enableArea={stackedState}
          enableGridX={false}
          enableSlices="x"
          areaOpacity={0.7}
          useMesh={true}
        />
      )}
    </div>
  );
};

export default LifeCycleTemporalGraph;
