module.exports = {
  0: 1,
  1: 0.992,
  2: 0.984,
  3: 0.976,
  4: 0.969,
  5: 0.961,
  6: 0.953,
  7: 0.945,
  8: 0.937,
  9: 0.929,
  10: 0.921,
  11: 0.913,
  12: 0.905,
  13: 0.897,
  14: 0.889,
  15: 0.88,
  16: 0.872,
  17: 0.864,
  18: 0.856,
  19: 0.848,
  20: 0.84,
  21: 0.831,
  22: 0.823,
  23: 0.815,
  24: 0.806,
  25: 0.798,
  26: 0.79,
  27: 0.781,
  28: 0.773,
  29: 0.764,
  30: 0.756,
  31: 0.747,
  32: 0.739,
  33: 0.73,
  34: 0.721,
  35: 0.713,
  36: 0.704,
  37: 0.695,
  38: 0.686,
  39: 0.678,
  40: 0.669,
  41: 0.66,
  42: 0.651,
  43: 0.642,
  44: 0.633,
  45: 0.624,
  46: 0.615,
  47: 0.606,
  48: 0.597,
  49: 0.587,
  50: 0.578,
  51: 0.569,
  52: 0.559,
  53: 0.55,
  54: 0.541,
  55: 0.531,
  56: 0.521,
  57: 0.512,
  58: 0.502,
  59: 0.492,
  60: 0.483,
  61: 0.473,
  62: 0.463,
  63: 0.453,
  64: 0.443,
  65: 0.433,
  66: 0.423,
  67: 0.412,
  68: 0.402,
  69: 0.392,
  70: 0.381,
  71: 0.371,
  72: 0.36,
  73: 0.349,
  74: 0.339,
  75: 0.328,
  76: 0.317,
  77: 0.306,
  78: 0.295,
  79: 0.283,
  80: 0.272,
  81: 0.261,
  82: 0.249,
  83: 0.237,
  84: 0.226,
  85: 0.214,
  86: 0.202,
  87: 0.189,
  88: 0.177,
  89: 0.164,
  90: 0.151,
  91: 0.138,
  92: 0.125,
  93: 0.111,
  94: 0.097,
  95: 0.083,
  96: 0.068,
  97: 0.052,
  98: 0.036,
  99: 0.018,
  100: 0,
};
