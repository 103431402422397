import './breadCrumb.scss';
import { BreadCrumArrow, BreadCrumbElement } from './BreadCrumbElement';
import { StateDTO } from '../../../models/GeneralDTO';
import { Tooltip } from '@mui/material';
import { updateFormValue } from '../../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect } from 'react';
import folderOpenLogofrom from '../../../../assets/images/project/FolderOpen.png';
import useCase from '../useCase/useCase';

const CompanySvg = () => {
  return (
    <div style={{ marginRight: '12px', width: '22px' }}>
      <svg
        width="22"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.1755 13.7017V3.73682H8.71948V8.71924H3.73706V26.1577H13.7019V21.1753H16.1931V26.1577H26.158V13.7017H21.1755ZM8.71948 23.6665H6.22827V21.1753H8.71948V23.6665ZM8.71948 18.6841H6.22827V16.1929H8.71948V18.6841ZM8.71948 13.7017H6.22827V11.2104H8.71948V13.7017ZM13.7019 18.6841H11.2107V16.1929H13.7019V18.6841ZM13.7019 13.7017H11.2107V11.2104H13.7019V13.7017ZM13.7019 8.71924H11.2107V6.22803H13.7019V8.71924ZM18.6843 18.6841H16.1931V16.1929H18.6843V18.6841ZM18.6843 13.7017H16.1931V11.2104H18.6843V13.7017ZM18.6843 8.71924H16.1931V6.22803H18.6843V8.71924ZM23.6667 23.6665H21.1755V21.1753H23.6667V23.6665ZM23.6667 18.6841H21.1755V16.1929H23.6667V18.6841Z"
          fill="#789ADE"
        />
      </svg>
    </div>
  );
};

const FolderSvg = () => {
  return (
    <div style={{ marginRight: '12px', width: '22px' }}>
      <img src={folderOpenLogofrom} alt="" style={{ maxWidth: '22px' }} />
    </div>
  );
};

export const BreadCrumb = () => {
  const { arianeCompany, arianeFolder } = useSelector(
    (state: StateDTO) => state.projects.projectsListMonitoring
  );
  const { projectsListMonitoring } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { isBundleScreen } = useSelector((state: StateDTO) => state.bundle);
  const { isBreadcrumbCompanyVisible, selectedCompanies, isExistChildrenCompanies } =
    arianeCompany;
  const { isBreadcrumbFolderVisible, selectedPaths } = arianeFolder;
  const { selectCompanyOnBreadCrumb, selectHomeBreadCrumb, selectPathOnBreadCrumb } =
    useCase();
  const { t } = useTranslation();

  return (
    <div className="breadCrumbWrapper">
      {/* home button */}
      <BreadCrumbElement type={isBundleScreen ? 'home-bundle' : 'home'}>
        <div onClick={selectHomeBreadCrumb}>
          {t('APP.BREADCRUMB.BREADCRUMB.PH_0C490B', {
            defaultValue: 'Accueil',
          })}
        </div>
      </BreadCrumbElement>
      {/* companies button */}
      {isBreadcrumbCompanyVisible &&
        selectedCompanies?.map((company) => (
          <>
            <BreadCrumArrow key={uuidv4()} />
            <BreadCrumbElement type="company" key={uuidv4()} company={company}>
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                key={uuidv4()}
                onClick={() => {
                  selectCompanyOnBreadCrumb(company);
                }}
              >
                <CompanySvg />
                {company.name}
              </div>
            </BreadCrumbElement>
          </>
        ))}
      {/* select Companies button information */}
      {isExistChildrenCompanies && (
        <>
          <BreadCrumArrow key={uuidv4()} />
          <BreadCrumbElement type="companyChoice">
            <Tooltip
              title={t('APP.BREADCRUMB.CHOOSE_COMPANY_TOOLTIP', {
                defaultValue:
                  'Vous devez choisir une entreprise pour accéder aux projets',
              })}
            >
              <div style={{ display: 'flex', alignItems: 'center' }} key={uuidv4()}>
                <CompanySvg />
                {t('APP.BREADCRUMB.BREADCRUMB.PH_940FA5', {
                  defaultValue: "Choisir l'entreprise dans laquelle naviguer",
                })}
              </div>
            </Tooltip>
          </BreadCrumbElement>
        </>
      )}
      {/* folders button */}
      {isBreadcrumbFolderVisible && (
        <>
          {/* <BreadCrumArrow key={uuidv4()}/> */}
          {selectedPaths?.map((path) => (
            <>
              <BreadCrumArrow key={uuidv4()} />
              <BreadCrumbElement
                type={isBundleScreen ? 'folder-bundle' : 'folder'}
                key={uuidv4()}
                path={path}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  key={uuidv4()}
                  onClick={() => {
                    selectPathOnBreadCrumb(path);
                  }}
                >
                  <FolderSvg />
                  {path.name}
                </div>
              </BreadCrumbElement>
            </>
          ))}
        </>
      )}
    </div>
  );
};
