import { Tooltip } from '@material-ui/core';
import { importRowDTO } from '../../../models/ImportDTO';
import React from 'react';
import badPic from '../../../../assets/images/import/Red_Circle(small).svg.png';
import caracPic from '../../../../assets/images/import/specification.png';
import discreteError from '../../../../assets/images/import/conflict.png';
import mdIcon from '../../../../assets/images/import/md.png';
import quantityLogo from '../../../../assets/images/import/quantityLogo.png';

type DisplayLineErrorProps = {
  line: importRowDTO;
};

const DisplayLineError = ({ line }: DisplayLineErrorProps) => {
  return (
    <Tooltip
      title={
        <span style={{ display: 'block' }}>
          {line.ProjectImportElemCaracs?.find((el) => el.statusCode)
            ? line.ProjectImportElemCaracs?.find((el) => el.statusCode).status
            : line.status !== 'Validated'
            ? line.status
            : ''}
        </span>
      }
    >
      <div
        className="line-import"
        style={{ cursor: line.isArchived ? 'initial' : 'pointer' }}
      >
        <img src={badPic} alt="" />
        {line.status === 'Quantité invalide' ? (
          <img className="show-error" src={quantityLogo} />
        ) : line.statusCode === 'MD_CARACS_NOT_NUMERIC' ||
          line.statusCode === 'MD_UNIT_NOT_MATCHING' ||
          line.statusCode === 'MD_CARACS_WRONG_VALUE' ||
          line.statusCode === 'MD_CARACS_VALUE_NOT_IN_LIST' ||
          line.ProjectImportElemCaracs?.find((el) => el.statusCode) ? (
          <img
            className="show-error"
            style={{ height: '32px', top: '7px', left: '9px' }}
            src={caracPic}
          />
        ) : line.statusCode === 'MISSING_MD_ID' ||
          line.statusCode === 'MD_OBSOLETE' ? (
          <img
            className="show-error"
            style={{ height: '33px', top: '7px', left: '13px' }}
            src={mdIcon}
          />
        ) : (
          line.statusCode === 'MD_CARACS_COMBO_NOT_VALID' && (
            <img
              className="show-error"
              style={{ height: '31px', top: '7px', left: '8px' }}
              src={discreteError}
            />
          )
        )}
      </div>
    </Tooltip>
  );
};

export default DisplayLineError;
