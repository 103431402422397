import '../Project.scss';
import './styles.css';
import BackupIcon from '@material-ui/icons/Backup';
import { Button } from '@photocarbone/nooco-ui';
import React, { useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import readXlsxFile from 'read-excel-file';
import xlsxParser from 'xls-parser';

import { getAllZones } from '../../shared/utils';
import config from '../../../config';
import generateUUID from '../../shared/generateUUID';
import { uploadData } from 'aws-amplify/storage';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { basicConfigAmplify } from '../../../index.js';

const S3DropZone = ({ project, setImportError, updateFormValue }) => {
  const { t } = useTranslation();
  const onChange = async (file) => {
    console.log(file);

    const format = file.name
      .substring(file.name.lastIndexOf('.') + 1, file.name.length)
      .toLowerCase();

    const fileName = file.name.substring(0, file.name.lastIndexOf('.'));

    const uuid = generateUUID();

    const identityId = (await fetchAuthSession()).identityId;

    const allNames =
      project.templateType !== 'mappingRef'
        ? getAllZones(project).sort(function (a, b) {
            if (a.id < b.id) {
              return -1;
            }

            if (a.id > b.id) {
              return 1;
            }

            return 0;
          })
        : [];

    if (format === 'xls') {
      xlsxParser.onFileSelection(file).then((data) => {
        let rows = [];

        for (const [key] of Object.entries(data)) {
          let tab = {
            name: key,
            check: true,
            zoneId:
              project.templateType === 'mappingRef'
                ? null
                : allNames.find(
                    (el) =>
                      el.type === 'zone' &&
                      el.name === 'Zone 1' &&
                      el.buildingName === 'Bâtiment 1'
                  )
                ? allNames.find(
                    (el) =>
                      el.type === 'zone' &&
                      el.name === 'Zone 1' &&
                      el.buildingName === 'Bâtiment 1'
                  ).id
                : allNames.find((el) => el.type === 'zone').id,
            isBuildingSite: false,
            lots: [
              {
                id: 1200,
                label: 'Tous lots',
                color: '#ccc',
                name: 'Tous lots',
              },
            ],

            options: [
              {
                name: 'mappingRef',
                checked: true,
                sentence: 'Lignes reconnues depuis votre mapping de référence',
                tooltip:
                  'Le mapping de référence permet d’établir des correspondances entre vos références et les appellations Nooco. En cochant la case, les lignes avec des correspondances validées dans votre mapping de référence seront prévalidées dans l’import.',
              },
              {
                name: 'idInies',
                checked: true,
                sentence: 'Lignes pour lesquelles un ID Inies est reconnu',
                tooltip:
                  'Votre fichier peut contenir une colonne ID Inies, en plus des colonnes obligatoires Description, Quantité, Unité, avec les éventuels ID Inies des fiches de votre projet. En cochant la case, les lignes avec une fiche Inies seront prévalidées dans l’import.',
              },
              {
                name: 'IA',
                checked: false,
                sentence:
                  'Lignes pour lesquelles l’Intelligence Artificielle a une fiabilité évaluée à plus de 95%',
                tooltip:
                  'La performance de l’intelligence artificielle est évaluée mensuellement (à date de livraison de nos versions). Pour qu’une suggestion dispose d’une fiabilité > 95%, elle doit vérifier les conditions suivantes : Cette suggestion a été proposée plus de 30 fois sur des projets. Cette suggestion à été validée dans 95% des cas (hors validation automatisée) depuis la dernière version de l’IA',
              },
            ],

            customName: null,
          };

          rows = [...rows, tab];
        }

        updateFormValue('importTabs', {
          file: format,
          rows,
          uuid,
          identityId,
          fileName,
        });
        updateFormValue('importLoading', false);
      });
    } else {
      readXlsxFile(file, { getSheets: true }).then((rows) => {
        for (let i = 0; i < rows.length; i++) {
          rows[i] = {
            ...rows[i],
            check: true,
            zoneId:
              project.templateType === 'mappingRef'
                ? null
                : allNames.find(
                    (el) =>
                      el.type === 'zone' &&
                      el.name === 'Zone 1' &&
                      el.buildingName === 'Bâtiment 1'
                  )
                ? allNames.find(
                    (el) =>
                      el.type === 'zone' &&
                      el.name === 'Zone 1' &&
                      el.buildingName === 'Bâtiment 1'
                  ).id
                : allNames.find((el) => el.type === 'zone').id,
            isBuildingSite: false,
            lots: [
              {
                id: 1200,
                label: 'Tous lots',
                color: '#ccc',
                name: 'Tous lots',
              },
            ],
            options: [
              {
                name: 'mappingRef',
                checked: true,
                sentence: t('APP.SETTINGS.S3DROPZONE.PH_8E796B', {
                  defaultValue: 'Lignes reconnues depuis votre mapping de référence',
                }),
                tooltip: t('APP.SETTINGS.S3DROPZONE.PH_CC4339', {
                  defaultValue:
                    'Le mapping de référence permet d’établir des correspondances entre vos références et les appellations Nooco. En cochant la case, les lignes avec des correspondances validées dans votre mapping de référence seront prévalidées dans l’import.',
                }),
              },
              {
                name: 'idInies',
                checked: true,
                sentence: t('APP.SETTINGS.S3DROPZONE.PH_E5AA39', {
                  defaultValue: 'Lignes pour lesquelles un ID Inies est reconnu',
                }),
                tooltip: t('APP.SETTINGS.S3DROPZONE.PH_04C795', {
                  defaultValue:
                    'Votre fichier peut contenir une colonne ID Inies, en plus des colonnes obligatoires Description, Quantité, Unité, avec les éventuels ID Inies des fiches de votre projet. En cochant la case, les lignes avec une fiche Inies seront prévalidées dans l’import.',
                }),
              },
              {
                name: 'IA',
                checked: false,
                sentence: t('APP.SETTINGS.S3DROPZONE.PH_D3F878', {
                  defaultValue:
                    'Lignes pour lesquelles l’Intelligence Artificielle a une fiabilité évaluée à plus de 95%',
                }),
                tooltip: t('APP.SETTINGS.S3DROPZONE.PH_A723F1', {
                  defaultValue:
                    'La performance de l’intelligence artificielle est évaluée mensuellement (à date de livraison de nos versions). Pour qu’une suggestion dispose d’une fiabilité > 95%, elle doit vérifier les conditions suivantes : Cette suggestion a été proposée plus de 30 fois sur des projets. Cette suggestion à été validée dans 95% des cas (hors validation automatisée) depuis la dernière version de l’IA',
                }),
              },
            ],

            customName: null,
          };
        }

        // `rows` is an array of rows
        // each row being an array of cells.
        updateFormValue('importTabs', {
          file: format,
          rows,
          uuid,
          identityId,
          fileName,
        });
        updateFormValue('importLoading', false);
      });
    }

    if (['csv', 'xls', 'xlsx', 'xlsm'].includes(format)) {
      Amplify.configure({
        ...basicConfigAmplify,
        Storage: {
          S3: {
            bucket: config.import.BUCKET,
            region: config.cognito.REGION,
          },
        },
      });

      const response = await uploadData({
        key: `${uuid}`,
        data: file,
        options: {
          accessLevel: 'private',
          bucket: config.import.BUCKET,
        },
      });
    } else {
      setImportError(true);
    }
  };

  const fileInput = useRef(null);

  const onButtonClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  // ...rest of your code including `onChange` function

  return (
    <>
      <Tooltip
        title={t('APP.SETTINGS.S3DROPZONE.PH_EE77D0', {
          defaultValue:
            "Importer votre fichier d'équipements et/ou matériaux à ajouter au projet",
        })}
      >
        <Button
          additionalIcon={<BackupIcon />}
          className="contact-button"
          onClick={onButtonClick}
          text={t('APP.SETTINGS.S3DROPZONE.PH_63DC40', {
            defaultValue: 'Importer un fichier',
          })}
        />
      </Tooltip>
      <input
        ref={fileInput}
        id="file"
        className="upload-input"
        type="file"
        style={{ display: 'none' }} // Hide the input
        accept=".xlsm, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={(e) => onChange(e.target.files[0])}
      />
    </>
  );
};

export default S3DropZone;
