export enum NAVIGATION_TAB_LISTS {
  LIFE_CYCLE_TAB = 'LIFE_CYCLE_TAB',
  LIST_EQUIPMENTS = 'LIST_EQUIPMENTS',
  REPORT = 'REPORT',
  CLIMATE_INNOVATION = 'CLIMATE_INNOVATION',
  SETTINGS = 'SETTINGS',
  VARIANT = 'VARIANT',
  IMPORT = 'IMPORT',
  RSEE = 'RSEE',
  EXPLOIT_BOARDING = 'EXPLOIT_BOARDING',
  EXPLOIT_LIST_ACTIONS = 'EXPLOIT_LIST_ACTIONS',
  EXPLOIT_REPORT = 'EXPLOIT_REPORT',
}

export enum NAVIGATION_TABS_VALUE {
  LIFE_CYCLE_TAB = 0,
  LIST_EQUIPMENTS = 1,
  REPORT = 2,
  SETTINGS = 3,
  VARIANT = 4,
  RSEE = 5,
}

export enum NAVIGATION_TAB_LISTS_LABELS {
  LIFE_CYCLE_TAB = 'Analyse du cycle de vie',
  LIST_EQUIPMENTS = 'Liste des contributeurs',
  REPORT = 'Synthèse du projet',
  CLIMATE_INNOVATION = 'Innovation Climat',
  SETTINGS = 'Paramètres',
  VARIANT = 'Comparaison',
  IMPORT = 'Import en cours',
  RSEE = 'RSEE',
  EXPLOIT_BOARDING = 'Prise en charge',
  EXPLOIT_LIST_ACTIONS = 'Liste des actions',
  EXPLOIT_REPORT = 'Synthèse',
}

export enum NAVIGATION_TAB_LISTS_TRAD_REF {
  LIFE_CYCLE_TAB = 'APP.PROJECTS.PROJECTOVERVIEW.PH_634DEB',
  LIST_EQUIPMENTS = 'APP.PROJECTS.PROJECTOVERVIEW.PH_C01B2A',
  REPORT = 'APP.PROJECTS.PROJECTOVERVIEW.PH_34EFC5',
  CLIMATE_INNOVATION = 'Innovation Climat',
  SETTINGS = 'APP.PROJECTS.PROJECTOVERVIEW.PH_373EAF',
  VARIANT = 'APP.PROJECTS.PROJECTOVERVIEW.PH_43E69D',
  IMPORT = 'APP.PROJECTS.PROJECTOVERVIEW.PH_1F6D92',
  RSEE = 'RSEE',
  EXPLOIT_BOARDING = 'APP.PROJECTS.PROJECTOVERVIEW.PH_67CAD8',
  EXPLOIT_LIST_ACTIONS = 'APP.PROJECTS.PROJECTOVERVIEW.PH_8CE713',
  EXPLOIT_REPORT = 'APP.PROJECTS.PROJECTOVERVIEW.PH_322499',
}
