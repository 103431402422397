import React from 'react';

import { Checkbox, Row, Col, Popover } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { SearchGroup, Input } from '@photocarbone/nooco-ui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import API from '../../../../../services/API';
import { BundleDTO, BundleLineDTO } from '../../../../../models/BundleDTO';
import { findParentNode, handleAllotmentNodes } from '../../../../../shared/utils';
import { FormLabelElementWrapper } from '../../../../../projects/EquipmentList/AddEquipment/ModelingEquipment/CarbonWeight/CarbonWeight';
import { StateDTO } from '../../../../../models/GeneralDTO';
import { updateFormValue } from '../../../../../../actions/projects';
import caracs from '../../../../../../assets/images/import/specification.png';
import { ALLOTMENT_IDS } from '../../../../../globalVariable/typeProject';

export type RenderModelingPhaseProps = {
  bundleLines: BundleLineDTO[];
  line: BundleDTO;
  handleChangeModelingPhase: Function;
  searchBarOptionsFiltered: Object;
  handleOnChangeDropdown: Function;
  returnDefaultValueTypeEquipment: Function;
  certificationBundleChoice: Object;
  handleChangeAllotmentNodes: Function;
  renderCaracPopOver: Function;
  setIsAssignementToGraph: Function;
  isAssignementToGraph: boolean;
  setEquipHaveCaracs: Function;
};

const RenderModelingPhase = ({
  bundleLines,
  line,
  handleChangeModelingPhase,
  searchBarOptionsFiltered,
  handleOnChangeDropdown,
  returnDefaultValueTypeEquipment,
  certificationBundleChoice,
  handleChangeAllotmentNodes,
  renderCaracPopOver,
  setIsAssignementToGraph,
  isAssignementToGraph,
  setEquipHaveCaracs,
}: RenderModelingPhaseProps) => {
  const bModuleDetails = [
    {
      keyPhrase: 'APP.LIBRARY.MODELINGPHASE.B1',
      keyDefaultValue: 'B1',
      tooltipTitlePhrase: 'APP.LIBRARY.MODELINGPHASE.TOOTLIP_TITLE_B1',
      tooltipTitleDefaultValue:
        "Impacts liés à l'utilisation des composants dans le bâtiment (hors usage eau et énergie)",
      tooltipKey: 'B1',
    },
    {
      keyPhrase: 'APP.LIBRARY.MODELINGPHASE.B2',
      keyDefaultValue: 'B2',
      tooltipTitlePhrase: 'APP.LIBRARY.MODELINGPHASE.TOOTLIP_TITLE_B2',
      tooltipTitleDefaultValue: "Opérations d'entretien du bâtiment",
      tooltipKey: 'B2',
    },
    {
      keyPhrase: 'APP.LIBRARY.MODELINGPHASE.B3',
      keyDefaultValue: 'B3',
      tooltipTitlePhrase: 'APP.LIBRARY.MODELINGPHASE.TOOTLIP_TITLE_B3',
      tooltipTitleDefaultValue: 'Opérations de réparation du bâtiment',
      tooltipKey: 'B3',
    },
    {
      keyPhrase: 'APP.LIBRARY.MODELINGPHASE.B4',
      keyDefaultValue: 'B4',
      tooltipTitlePhrase: 'APP.LIBRARY.MODELINGPHASE.TOOTLIP_TITLE_B4',
      tooltipTitleDefaultValue:
        'Opérations de remplacement de composants du bâtiment',
      tooltipKey: 'B4',
    },
    {
      keyPhrase: 'APP.LIBRARY.MODELINGPHASE.B5',
      keyDefaultValue: 'B5',
      tooltipTitlePhrase: 'APP.LIBRARY.MODELINGPHASE.TOOTLIP_TITLE_B5',
      tooltipTitleDefaultValue: 'B5',
      tooltipKey: 'B5',
    },
    {
      keyPhrase: 'APP.LIBRARY.MODELINGPHASE.B6',
      keyDefaultValue: 'B6',
      tooltipTitlePhrase: 'APP.LIBRARY.MODELINGPHASE.TOOTLIP_TITLE_B6',
      tooltipTitleDefaultValue: "Impacts liés aux consommations d'énergie",
      tooltipKey: 'B6',
    },
    {
      keyPhrase: 'APP.LIBRARY.MODELINGPHASE.B7',
      keyDefaultValue: 'B7',
      tooltipTitlePhrase: 'APP.LIBRARY.MODELINGPHASE.TOOTLIP_TITLE_B7',
      tooltipTitleDefaultValue: "Impacts liés aux consommations et rejets d'eau",
      tooltipKey: 'B7',
    },
  ];
  const api = new API();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { allGroups, certifiedAllotments, listOfNodesSearchBar } = useSelector(
    (state: StateDTO) => state.projects
  );
  let result;
  let isModeledProductEquipment;
  let nodeWithMd;

  if (!bundleLines || bundleLines?.length === 0) return null;
  const handleOnChange = (e: CheckboxChangeEvent) =>
    setIsAssignementToGraph(e.target.checked);

  bundleLines?.map((bundleLine: BundleLineDTO) => {
    isModeledProductEquipment = bundleLine?.type_fiche === 'custom_user';
    if (!isModeledProductEquipment) {
      nodeWithMd = bundleLine.AllotmentNodes?.find(
        (node) => node.MasterDataEquipmentId
      );

      result = bundleLine.AllotmentNodes
        ? handleAllotmentNodes(
            allGroups.type.find((md) => md.id === nodeWithMd?.MasterDataEquipmentId),
            allGroups.allotments.find((allot) => allot.id === ALLOTMENT_IDS.NOOCO),
            allGroups.flatAllotments,
            null
          )
        : null;
    }
  });

  return (
    <div className="bundle-details-equipments modeling">
      {bundleLines?.map((bundleLine) => {
        return (
          <>
            <Row
              style={{
                display: 'flex',
                padding: '2rem',
                gap: '3rem',
                flexWrap: 'wrap',
              }}
            >
              <Col xs={24} lg={12}>
                <Row gutter={[16, 16]}>
                  {/* production */}
                  <Col className="formElementWrapper">
                    <FormLabelElementWrapper
                      labelPhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_B2CE1A"
                      labelDefaultValue={`Production(kg eq. CO₂/${line?.unit})`}
                      tooltipTitlePhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_81D113"
                      tooltipTitleDefaultValue={`Production`}
                      tooltipKey="Production"
                      t={t}
                      unit={line?.unit}
                    />
                    <Input
                      width={'200px'}
                      defaultValue={
                        bundleLine.modelingEquipmentCarbonWeightForm?.product
                      }
                      onChange={(value) => {
                        if (!value) return;
                        handleChangeModelingPhase({
                          bundleLine,
                          formName: 'product',
                          value,
                        });
                      }}
                    />
                  </Col>
                  {/* installation */}
                  <Col className="formElementWrapper">
                    <FormLabelElementWrapper
                      labelPhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_8EDDE4"
                      labelDefaultValue={`Installation(kg eq. CO₂/${line?.unit})`}
                      tooltipTitlePhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_FA72BF"
                      tooltipTitleDefaultValue={`Edification`}
                      tooltipKey="Edification"
                      t={t}
                      unit={line?.unit}
                    />
                    <Input
                      width={'200px'}
                      defaultValue={
                        bundleLine.modelingEquipmentCarbonWeightForm?.installation
                      }
                      onChange={(value) => {
                        if (!value) return;
                        handleChangeModelingPhase({
                          bundleLine,
                          formName: 'installation',
                          value,
                        });
                      }}
                    />
                  </Col>
                  <Col className="formElementWrapper">
                    <FormLabelElementWrapper
                      labelPhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_F0DDAF"
                      labelDefaultValue={`Fin de vie(kg eq. CO₂/${line?.unit})`}
                      tooltipTitlePhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_F34F13"
                      tooltipTitleDefaultValue={`Fin de vie`}
                      tooltipKey="Fin de vie"
                      t={t}
                      unit={line?.unit}
                    />
                    <Input
                      width={'200px'}
                      defaultValue={
                        bundleLine.modelingEquipmentCarbonWeightForm?.endOfLife
                      }
                      onChange={(value) => {
                        if (!value) return;
                        handleChangeModelingPhase({
                          bundleLine,
                          formName: 'endOfLife',
                          value,
                        });
                      }}
                    />
                  </Col>
                  {/* profit */}
                  <Col className="formElementWrapper">
                    <FormLabelElementWrapper
                      labelPhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_4348D5"
                      labelDefaultValue={`Bénéfices et charges(kg eq. CO₂/${line?.unit})`}
                      tooltipTitlePhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_8274F1"
                      tooltipTitleDefaultValue={`Module D`}
                      tooltipKey="Module D"
                      t={t}
                      unit={line?.unit}
                    />
                    <Input
                      width={'200px'}
                      defaultValue={
                        bundleLine.modelingEquipmentCarbonWeightForm?.profit
                      }
                      onChange={(value) => {
                        if (!value) return;
                        handleChangeModelingPhase({
                          bundleLine,
                          formName: 'profit',
                          value,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xs={24} lg={11}>
                {/* usage */}

                <div className="formElementWrapper">
                  <FormLabelElementWrapper
                    labelPhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_031F63"
                    labelDefaultValue={`Utilisation(kg eq. CO₂/${line?.unit})`}
                    tooltipTitlePhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_BC490D"
                    tooltipTitleDefaultValue={`Exploitation`}
                    tooltipKey="Exploitation"
                    t={t}
                    unit={line?.unit}
                  />
                </div>
                {/* endOfLife */}
                <Row gutter={[20, 20]}>
                  {bModuleDetails.map((bModuleDetail) => (
                    <Col className="formElementWrapper">
                      <FormLabelElementWrapper
                        labelPhraseKey={bModuleDetail.keyPhrase}
                        labelDefaultValue={bModuleDetail.keyDefaultValue.toUpperCase()}
                        tooltipTitlePhraseKey={bModuleDetail.tooltipTitlePhrase}
                        tooltipTitleDefaultValue={
                          bModuleDetail.tooltipTitleDefaultValue
                        }
                        tooltipKey={bModuleDetail.tooltipKey}
                        t={t}
                      />

                      <Input
                        width={'50px'}
                        defaultValue={
                          bundleLine.modelingEquipmentCarbonWeightForm[
                            bModuleDetail.keyDefaultValue.toUpperCase()
                          ]
                        }
                        onChange={(value) => {
                          if (!value) return;
                          handleChangeModelingPhase({
                            bundleLine,
                            formName: bModuleDetail.keyDefaultValue,
                            value,
                          });
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ padding: '2rem' }} align="middle">
              <Col>
                <Checkbox onChange={handleOnChange} checked={isAssignementToGraph}>
                  {t('APP.RENDER_MODELING_PHASE.CHECKBOX_LABEL', {
                    defaultValue: 'Affecter à un nuage de points',
                  })}
                </Checkbox>
              </Col>
              <Col>
                {isAssignementToGraph && (
                  <>
                    <SearchGroup
                      t={t}
                      searchBarOptionsFiltered={searchBarOptionsFiltered}
                      certifiedAllotments={certifiedAllotments}
                      isBundleScreen={true}
                      triggerBreadcrumb={() => {
                        dispatch(updateFormValue('openBreadcrumb', true));
                        dispatch(updateFormValue('currentBundleLine', bundleLine));
                      }}
                      findParentNode={findParentNode}
                      allAllotments={allGroups.flatAllotments}
                      allotmentsAvailable={listOfNodesSearchBar?.filter(
                        (el) => !el.isObsolete
                      )}
                      dropdownHeight="55px"
                      api={api}
                      dropdownProps={{
                        onSelect: () => {},
                        onChange: (value) => {
                          setEquipHaveCaracs(true);
                          handleOnChangeDropdown(value, bundleLine);
                        },
                        placeholder: t('APP.BUNDLEDETAILS.INDEX.PH_B43F0F', {
                          defaultValue: 'Rechercher...',
                        }),
                        treeCheckable: false,
                        value: !bundleLine.AllotmentNodes
                          ? null
                          : isModeledProductEquipment
                          ? bundleLine?.name
                          : bundleLine.Sheet?.name
                          ? bundleLine.Sheet.name
                          : bundleLine.AllotmentNodes
                          ? bundleLine.AllotmentNodes.find(
                              (node) => node.MasterDataEquipmentId
                            )?.name
                          : bundleLine?.name
                          ? bundleLine.name
                          : '',
                      }}
                      selectProps1={{
                        defaultValue: returnDefaultValueTypeEquipment(bundleLine),
                        value: bundleLine.searchBarType,
                        filterOptions: ['equipements'],
                        doNotDisplay: ['equipements'],
                        style: { display: 'none' },
                      }}
                      selectProps2={{
                        ListType: Object.entries(certificationBundleChoice)
                          .filter(([_, value]) => value)
                          .map(([key, _]) => key),
                        noocoOptions: result
                          ? result.filter((el) => !el.isObsolete)
                          : bundleLine.Sheet?.AllotmentNodes || [],
                        keyDisplay: 'name',
                        keyItem: 'id',
                        onChange: (selectedOptionValue, type) => {
                          const data = result?.find(
                            (el) => el.name === selectedOptionValue
                          );

                          let nodeSelected =
                            type === 'Nooco'
                              ? data.details.find((el) => el.MasterDataEquipmentId)
                              : selectedOptionValue;

                          handleChangeAllotmentNodes(nodeSelected, type, bundleLine);
                        },
                        value: bundleLine.AllotmentNodes,
                      }}
                      gridStyle={'300px 0px  55px'}
                    />
                    {bundleLine.AllotmentNodes &&
                      !bundleLine.Sheet?.type_fiche &&
                      bundleLine.caracs &&
                      bundleLine.caracs.caracs?.length > 0 && (
                        <>
                          <Popover
                            placement="top"
                            title={t('APP.BUNDLEDETAILS.INDEX.PH_BE774E', {
                              defaultValue: 'Caractéristiques',
                            })}
                            content={() => renderCaracPopOver(bundleLine)}
                            trigger="click"
                          >
                            <img
                              src={caracs}
                              style={{
                                width: '40px',
                                filter: bundleLine?.caracs?.caracs?.find(
                                  (car) => car && (!car.value || car.value === '')
                                )
                                  ? 'invert(21%) sepia(52%) saturate(5667%) hue-rotate(354deg) brightness(88%) contrast(133%)'
                                  : undefined,
                              }}
                              alt=""
                            />
                          </Popover>
                        </>
                      )}
                  </>
                )}
              </Col>
            </Row>
          </>
        );
      })}
    </div>
  );
};

export default RenderModelingPhase;
