import './climateInnovation.scss';
import {
  BbcaClimateInnovationBuildingFormDTO,
  ZoneDTO,
} from '../../models/ProjectDTO';
import { StateDTO } from '../../models/GeneralDTO';
import { ZONE_TYPES } from '../../globalVariable/generalDefinitions';
import { isNumOrNull } from '../../shared/utilsFunction';
import { isProjectSingleZoneOnly } from '../../shared/utils';
import { patchMonitoringBreadcrumbMultizone } from '../../../actions/monitoring';
import { updateFormValue } from '../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import BreadcrumbMultizone from '../../shared/BreadcrumbMultiZone/BreadcrumbMultizone';
import ClimateInnovationForm from './ClimateInnovationForm/ClimateInnovationForm';
import React, { useEffect } from 'react';
import { useGetProjectDataComps } from '../../../hooks/useQuery/projects/getProjectDataComps';
import { ProjectDataComps } from '@photocarbone/nooco-shared-api-def/src/project/projectDataComps';
import Spinner from '../../shared/Spinner';

const ClimateInnovation = () => {
  const { bbcaFormValuesfromSelectedBuilding, bbcaClimateInnovationFormValues } =
    useSelector((state: StateDTO) => state.projects);
  const { zones } = useSelector((state: StateDTO) => state.projects.parsedProject);
  const { zoneSelected } = useSelector(
    (state: StateDTO) => state.monitoring.breadcrumbMultizone
  );
  const isOnlyOneZoneProject = isProjectSingleZoneOnly(zones, true);
  const dispatch = useDispatch();

  const onSuccessGetProjectDataComps = (data: ProjectDataComps) => {
    const bbcaClimateInnovationFormValues: BbcaClimateInnovationBuildingFormDTO[] =
      [];
    data.buildings.forEach((projectDataCompBuilding) => {
      const formClimateInnovationEmpty = {
        zoneId: projectDataCompBuilding.ZoneId,
        savedParkingPlacesNumber:
          projectDataCompBuilding.climateInnovationData?.savedParkingPlacesNumber ||
          '',
        savedParkingPlacesUndergroundNumber:
          projectDataCompBuilding.climateInnovationData
            ?.savedParkingPlacesUndergroundNumber || '',
        savedArea: projectDataCompBuilding.climateInnovationData?.savedArea || '',
        potentialUseChange:
          projectDataCompBuilding.climateInnovationData?.potentialUseChange || null,
        potentialAdditionalArea:
          projectDataCompBuilding.climateInnovationData?.potentialAdditionalArea ||
          '',
        selectiveDeconstruction:
          projectDataCompBuilding.climateInnovationData?.selectiveDeconstruction ||
          false,
        massReusedConstructionProducts:
          projectDataCompBuilding.climateInnovationData
            ?.massReusedConstructionProducts || '',
      };

      bbcaClimateInnovationFormValues.push(formClimateInnovationEmpty);
      dispatch(
        updateFormValue(
          'bbcaClimateInnovationFormValues',
          bbcaClimateInnovationFormValues
        )
      );
    });
  };

  const { isLoading, data: projectDataComps } = useGetProjectDataComps({
    onSuccessCallback: onSuccessGetProjectDataComps,
  });

  useEffect(() => {
    if (!zoneSelected) {
      const newZoneSelected = zones.find(
        (zone) => zone.type === ZONE_TYPES.building
      );

      dispatch(patchMonitoringBreadcrumbMultizone('zoneSelected', newZoneSelected));
    }

    if (zoneSelected && bbcaClimateInnovationFormValues) {
      const bbcaFormValuesfromSelectedBuilding =
        bbcaClimateInnovationFormValues?.find(
          (bbcaValueElement) => bbcaValueElement.zoneId === zoneSelected?.id
        );

      dispatch(
        updateFormValue(
          'bbcaFormValuesfromSelectedBuilding',
          bbcaFormValuesfromSelectedBuilding
        )
      );
    }
  }, [zoneSelected, bbcaClimateInnovationFormValues]);

  const isClimateInnovationButtonZoneFilled = ({
    zone,
  }: {
    zone: ZoneDTO | null;
  }): boolean => {
    if (!zone || !bbcaClimateInnovationFormValues) {
      return false;
    }

    const formFromButtonZone = bbcaClimateInnovationFormValues?.find(
      (bbcaZone) => bbcaZone.zoneId === zone.id
    );

    if (!formFromButtonZone) {
      return false;
    }

    const isZoneFilled = Object.entries(formFromButtonZone).some(
      ([key, value]) => key !== 'zoneId' && value
    );

    return isZoneFilled;
  };

  const isClimateInnovationFormValid = ({
    bbcaFormValuesfromSelectedBuilding,
  }: {
    bbcaFormValuesfromSelectedBuilding?: BbcaClimateInnovationBuildingFormDTO;
  }): boolean => {
    if (!bbcaFormValuesfromSelectedBuilding) {
      return true;
    }

    const keyNameNeedsToBeNumber = [
      'savedParkingPlacesNumber',
      'savedParkingPlacesUndergroundNumber',
      'savedArea',
      'potentialAdditionalArea',
      'massReusedConstructionProducts',
    ];

    const isFormValid = keyNameNeedsToBeNumber.every((key) =>
      isNumOrNull(bbcaFormValuesfromSelectedBuilding[key])
    );

    return isFormValid;
  };

  const saveCurrentBbcaForm = () => {
    const updatebbcaClimateInnovationFormValues =
      bbcaClimateInnovationFormValues?.map((bbcaFormElement) => {
        if (bbcaFormElement.zoneId === zoneSelected?.id) {
          return bbcaFormValuesfromSelectedBuilding;
        }

        return bbcaFormElement;
      });

    dispatch(
      updateFormValue(
        'bbcaClimateInnovationFormValues',
        updatebbcaClimateInnovationFormValues
      )
    );
    return updatebbcaClimateInnovationFormValues;
  };

  const populateCurrentBbcaForm = (zone: ZoneDTO) => {
    const bbcaFormValuesfromSelectedBuilding = bbcaClimateInnovationFormValues?.find(
      (bbcaFormElement) => bbcaFormElement.zoneId === zone.id
    );

    dispatch(
      updateFormValue(
        'bbcaFormValuesfromSelectedBuilding',
        bbcaFormValuesfromSelectedBuilding
      )
    );
  };

  const onChangeZone = ({ zone }: { zone: ZoneDTO | null }) => {
    if (!zone) {
      return;
    }

    saveCurrentBbcaForm();
    populateCurrentBbcaForm(zone);
  };

  return (
    <div className={`climateInnovation-wrapper `}>
      {!isOnlyOneZoneProject && (
        <BreadcrumbMultizone
          isActiveScreenValid={() =>
            isClimateInnovationFormValid({ bbcaFormValuesfromSelectedBuilding })
          }
          isZoneFilled={(zone) => isClimateInnovationButtonZoneFilled({ zone })}
          onChangeZone={({ zone }) => onChangeZone({ zone })}
          isDisplayProject={false}
          isDisplayPlot={false}
          isDisplayZone={false}
          tooltip="" //TODO TOOLTIP A DEFINIR
        />
      )}
      <div
        className={`climateInnovation-content ${
          isOnlyOneZoneProject ? 'oneZoneProject' : ''
        }`}
      >
        {isLoading && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '30px',
            }}
          >
            <Spinner />
          </div>
        )}
        {zoneSelected &&
          bbcaFormValuesfromSelectedBuilding &&
          !isLoading &&
          projectDataComps && (
            <ClimateInnovationForm
              projectDataComps={projectDataComps}
              isClimateInnovationFormValid={isClimateInnovationFormValid}
              saveCurrentBbcaForm={saveCurrentBbcaForm}
            />
          )}
      </div>
    </div>
  );
};

export default ClimateInnovation;
