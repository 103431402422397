import '../Projects.css';
import { Dropdown } from '@photocarbone/nooco-ui';
import {
  EquipmentDatas,
  LineProductGraph,
} from '@photocarbone/nooco-equipment-components';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import { createProjectStructure } from '../../shared/parsingDatas';
import { createStructuredZonesForDropdown } from '../../shared/DropdownUiUtils';
import { useLocalStorage } from 'react-use';
import { useSelector } from 'react-redux';
import API from '../../services/API';
import AppContext from '../../AppContext';
import BarChartIcon from '@material-ui/icons/BarChart';
import Breadcrumb from '../../../containers/BreadCrumb';
import IconButton from '@mui/material/IconButton';
import ImpactSunburst from '../../../containers/ImpactSunburst';
import InfoIcon from '@material-ui/icons/Info';
import LifeCycleAnalysisGraph from '../../../containers/LifeCycleAnalysisGraph';
import LifeCycleTemporalGraph from '../../../containers/LifeCycleTemporalGraph';
import Loader from '@material-ui/core/CircularProgress';
import NavigationAllot from '../../../containers/NavigationAllot';
import NoocoCheckbox from '../../shared/NoocoCheckbox';
import React, { useContext, useEffect, useState } from 'react';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import SwitchEquipment from '../../../containers/SwitchEquipment';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import { returnIfProjectIsRegulatory } from '../../shared/utils';
import { getProjectPeriodInYears } from '../../shared/utilsFunction';

const AntTabs = withStyles({
  root: {
    textAlign: 'center',
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#22C994',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: ['Roboto'].join(','),
    '&:hover': {
      color: '#22C994',
      opacity: 1,
    },
    '&$selected': {
      color: '#22C994',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#22C994',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const addBuildingSiteIds = (selectedZoneId, parsedZones, projectArea) => {
  const isProjectSelected = selectedZoneId === 'project';
  const isPlotSelected = parsedZones.find(
    (zone) => zone.type === 'plot' && zone.id === selectedZoneId
  );
  let referenceArea = isProjectSelected || isPlotSelected ? projectArea : 0;
  let validZonesIds = isProjectSelected
    ? parsedZones.map((zone) => zone.id)
    : [selectedZoneId];

  validZonesIds = isPlotSelected
    ? validZonesIds.concat(
        parsedZones
          .filter(
            (zone) =>
              zone.type === 'buildingSite' && zone.ParentId === selectedZoneId
          )
          .map((buildingZone) => buildingZone.id)
      )
    : validZonesIds;

  if (isProjectSelected || isPlotSelected) {
    return { validZonesIds, referenceArea };
  }

  let selectedZone = parsedZones.find((zone) => zone.id === selectedZoneId);

  referenceArea = selectedZone.area;

  let childSelectedZonesIds = [selectedZoneId];
  let parentZoneIds = [selectedZoneId];

  while (childSelectedZonesIds.length > 0) {
    childSelectedZonesIds = parsedZones
      .filter((zone) => parentZoneIds.includes(zone.ParentId))
      .map((childZone) => childZone.id);
    parentZoneIds = childSelectedZonesIds;

    validZonesIds = validZonesIds.concat(childSelectedZonesIds);
  }

  return { validZonesIds: Array.from(new Set(validZonesIds)), referenceArea };
};

export const selectedZoneName = (selectedZoneId, parsedZones) => {
  if (selectedZoneId === 'project' || selectedZoneId === null) {
    return 'Projet';
  }

  return parsedZones.find((zone) => zone.id === selectedZoneId).name;
};

const LifeCycleTab = ({
  project,
  groups,
  filters,
  saveSheets,
  sheets,
  loadingGraph,
  currentMd,
  setLoadingGraph,
  currentIndicator,
  decMds,
  selectedLot,
  selectedFonction,
  selectedCategory,
  selectedMaterial,
  selectedType,
  updateFormValue,
  currentFlag,
  updateCurrentFlag,
  updateDisabledSelects,
  parsedZones,
  searchValue,
  lockedEquipmentNeeded,
  fetchProjectById,
  currentAllotment,
  structureGraph,
  graphData,
  sheetToLifeCycle,
  filteredLifeCycle,
  allotSteps,
  changeValueEquipments,
  patchModeLoading,
  confirmReplaceEquipment,
  replaceEquipment,
  switchSelectedEquipment,
  updateNewInies,
  graphView,
  projectSheets,
  exploitationSheets,
  saveFiltersBrand,
  lists,
  loadGraph,
  allGroups,
  equipmentsAvailable,
  scope,
  licenceTypeAccess,
  copyData,
  setDataset,
  loadingWaitingGraph,
}) => {
  const [selectedEquipment, setSelectedEquipment] = useState('');
  const [lockedSelectedEquipment, setLockedSelectedEquipment] = useState('');
  const { nodeSearchValue, mds } = useSelector((state) => state.projects);
  const { addDisplay } = useSelector((state) => state.equipments);
  const [selectedZoneIdForGraph, setSelectedZoneIdForGraph] = useState('project');
  const [structuredZonesForGraphDropdown, setStructuredZonesForGraphDropdown] =
    useState(createStructuredZonesForDropdown({ zones: parsedZones }));
  const [surfaceRef, setSurfaceRef] = useState(project.area);
  const [structureGraphFiltered, setStructureGraphFiltered] =
    useState(structureGraph);
  const [validZonesIdsState, setValidZonesIdsState] = useState([]);
  const { t } = useTranslation();

  const [
    selectedZoneIdForGraphFromLocalStorage,
    setSelectedZoneIdForGraphFromLocalStorage,
  ] = useLocalStorage(`selectedZonesIds-lifecycle-projectId-${project.id}`);

  useEffect(() => {
    if (selectedZoneIdForGraphFromLocalStorage) {
      setSelectedZoneIdForGraph(selectedZoneIdForGraphFromLocalStorage);
    }

    // if (!selectedZoneIdForGraphFromLocalStorage) {
    //   setSelectedZoneIdForGraph('project');
    // }
  }, []);

  useEffect(() => {
    setSelectedZoneIdForGraphFromLocalStorage(selectedZoneIdForGraph);
    updateFormValue('allotSteps', []);
    updateFormValue('searchValue', '');
    updateFormValue('sheetToLifeCycle', null);

    let { validZonesIds, referenceArea } = addBuildingSiteIds(
      selectedZoneIdForGraph,
      parsedZones,
      project.area
    );

    setValidZonesIdsState(validZonesIds);
    if (referenceArea !== 0) {
      setSurfaceRef(referenceArea);
    }

    if (currentAllotment) {
      const sheetsFiltered = project.Sheets.filter((sheet) =>
        validZonesIds.includes(sheet.ProjectSheet.ZoneId)
      );
      const zonesFiltered = parsedZones.filter((zone) =>
        validZonesIds.includes(zone.id)
      );

      const copyDataTest = createProjectStructure(
        currentAllotment,
        sheetsFiltered,
        zonesFiltered,
        project,
        false,
        referenceArea
      );

      setDataset(copyDataTest);
    }

    const filteredEquipmentsByZones = structureGraph?.filter((el) => {
      let filteredEquipments = validZonesIds.includes(el.sheets.ProjectSheet.ZoneId);

      if (filteredEquipments) {
        return filteredEquipments;
      }
    });

    setStructureGraphFiltered(filteredEquipmentsByZones);

    filterEquipments();
  }, [selectedZoneIdForGraph]);

  useEffect(() => {
    if (loadingGraph === true) {
      updateFormValue('loadingWaitingGraph', false);
    }
  }, [loadingGraph]);

  const { flatAllotments } = useSelector((state) => state.projects.allGroups);

  const handleOnChangeSelectedZonesForGraph = (zones) => {
    setSelectedZoneIdForGraph(zones);
  };

  const ctx = useContext(AppContext);
  const apiNooco = new API();

  const filterEquipments = () => {
    let filteredList = [];

    // Filtered sheets for LifeCycleAnalysisGraph
    if (sheetToLifeCycle) {
      updateFormValue('filteredLifeCycle', [...new Set(filteredList)]);

      return;
    }

    if (graphData) {
      structureGraphFiltered.forEach((el) => {
        graphData.children.forEach((first) => {
          if (first.step !== 0) {
            first.children.forEach((ele) => {
              for (const [key, value] of Object.entries(el)) {
                if (key !== 'res' && value === ele.id) {
                  filteredList = [...filteredList, el];
                }
              }
            });
          } else {
            for (const [key, value] of Object.entries(el)) {
              if (key !== 'res' && value === first.id) {
                filteredList = [...filteredList, el];
              }
            }
          }
        });
      });

      // filter based on zone
      if (currentAllotment.name === 'Re2020') {
        let selectedZones = [...parsedZones];

        let plot = parsedZones.find((z) => z.type === 'plot');

        if (plot !== undefined) plot = plot.id;

        for (let s of allotSteps) {
          if (s !== undefined) {
            if (!s.label) s.label = s.name;

            if (s.label === 'Parcelle') {
              selectedZones = selectedZones.filter(
                (z) => z.id === plot || z.ParentId === plot
              );
            }

            if (s.label?.includes('Chantier')) {
              let plotInSteps =
                allotSteps.find((as) => as.label === 'Parcelle') !== undefined;

              selectedZones = selectedZones.filter(
                (z) =>
                  z.type === 'buildingSite' &&
                  (plot === undefined ||
                    (plotInSteps && z.ParentId === plot) ||
                    (!plotInSteps && z.ParentId !== plot))
              );
            }

            if (
              ['Composant', 'Eau', 'Energie', 'Terre'].includes(s.label) &&
              allotSteps.find((as) => as.label === 'Chantier') === undefined
            ) {
              selectedZones = selectedZones.filter((z) => z.type !== 'buildingSite');
            }

            if (
              ['Composant', 'Eau', 'Energie', 'Terre'].includes(s.label) &&
              allotSteps.find((as) => as.label === 'Parcelle') === undefined
            ) {
              selectedZones = selectedZones.filter((z) => z.type !== 'plot');
            }

            // }
            // if (s.label?.includes('Chantier')) {
            //   let plotInSteps = allotSteps.find(as => as.label === 'Parcelle') !== undefined
            //   selectedZones = selectedZones.filter(z => z.type === 'buildingSite' && (plot === undefined || ((plotInSteps && z.ParentId === plot) || (!plotInSteps && z.ParentId !== plot))));

            // }
          }

          if (selectedZones.length > 0)
            filteredList = filteredList.filter(
              (l) =>
                selectedZones.find(
                  (sz) => sz.id === l.sheets.ProjectSheet.ZoneId
                ) !== undefined
            );
        }
      }

      filteredList = filteredList.map((fl) => fl.sheets);

      updateFormValue('filteredLifeCycle', [...new Set(filteredList)]);
    }
  };

  // Get new datas after a switch equipments
  // WAIT WE CAN HAVE CALCUL DATAS

  useEffect(() => {
    if (!patchModeLoading && confirmReplaceEquipment) {
      let newEquipment = project.Sheets.find(
        (el) => el.ProjectSheet.id === confirmReplaceEquipment.ProjectSheetId
      );

      let node = newEquipment?.ProjectSheet.AllotmentNodes.find(
        (el) => el.MasterDataEquipmentId
      );
      let md = groups.find((el) => el.id === node.MasterDataEquipmentId);

      updateFormValue('filteredLifeCycle', [newEquipment]);
      updateFormValue('searchValue', md);
      updateFormValue('nodeSearchValue', node);
      updateFormValue('confirmReplaceEquipment', null);
    }
  }, [patchModeLoading]);

  // clean datas when component unmount
  useEffect(() => {
    return () => {
      if (!allotSteps?.find((el) => el.step === 0)) {
        updateFormValue('allotSteps', []);
      }

      updateFormValue('graphData', null);
      updateFormValue('copyData', null);
      updateFormValue('clickLevelAllot', null);
    };
  }, []);

  useEffect(() => {
    if (graphData || searchValue) {
      filterEquipments();
    }
  }, [graphData, structureGraph, searchValue, allotSteps]);

  useEffect(() => {
    setLockedSelectedEquipment();
    if (nodeSearchValue && searchValue && (!addDisplay || addDisplay.length === 0)) {
      callSheets(searchValue);
    }
  }, [nodeSearchValue]);

  useEffect(() => {
    if (lockedEquipmentNeeded) {
      setSelectedEquipment(lockedEquipmentNeeded);
      changeValueEquipments('lockedEquipmentNeeded', null);
    }
  }, [lockedEquipmentNeeded]);

  const onLineChartEquipmentChangeCallback = (equipment) => {
    setSelectedEquipment(equipment);
  };
  const onLineChartLockedEquipmentChangeCallback = (equipment) => {
    setLockedSelectedEquipment(equipment);
    setSelectedEquipment(equipment);
  };

  const onEquipmentSheetEquipmentSelect = (equipment) => {
    setLockedSelectedEquipment(equipment);
  };

  const sunburstClickHandler = (flag, label, flagId) => {
    if (flag === 'fonction') {
      updateFormValue('selectedLot', label);
    } else if (flag === 'categorie') {
      updateFormValue('selectedFonction', label);
    } else if (flag === 'materiel') {
      updateFormValue('selectedCategory', label);
    } else if (flag === 'type') {
      updateFormValue('selectedMaterial', label);
    } else if (!flag) {
      updateFormValue('selectedType', label);
    }

    if (!flag) updateCurrentFlag('type');
    else updateCurrentFlag(flag);

    updateDisabledSelects(flagId, label);
  };

  const renderLeftGraph = () => {
    if (searchValue && sheets) {
      return (
        <>
          {!loadingGraph && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '200%',
                height: '45vh',
              }}
            >
              <Loader
                variant="indeterminate"
                disableShrink
                style={{ color: '#73b7ec' }}
                size={65}
                thickness={4}
              />
            </div>
          )}

          {loadingGraph && (
            <>
              {!graphData ? (
                <ImpactSunburst
                  sheets={project.Sheets}
                  currentFlag={currentFlag}
                  selectedLot={selectedLot}
                  selectedFonction={selectedFonction}
                  selectedCategory={selectedCategory}
                  selectedMaterial={selectedMaterial}
                  selectedType={selectedType}
                  isAcvDynamic={project.isAcvDynamic}
                  onClick={sunburstClickHandler}
                  lots={project.Lots}
                  unit={currentIndicator.unit}
                  projectArea={surfaceRef}
                  isRe2020={project.isRe2020}
                  copyData={copyData}
                  // filteredZones={filteredZones}
                  // surfaceRef={surfaceRef}
                  // selectedZoneIdsForGraph={selectedZoneIdsForGraph}
                />
              ) : (
                <></>
              )}

              <LineProductGraph
                onEquipmentChange={onLineChartEquipmentChangeCallback}
                onLockedEquipmentChange={onLineChartLockedEquipmentChangeCallback}
                filters={{
                  selectedLot,
                  selectedType,
                  selectedMaterial,
                  selectedCategory,
                  selectedFonction,
                  filters,
                }}
                updateFormValue={updateFormValue}
                selectedEquipment={selectedEquipment}
                lockedSelectedEquipment={lockedSelectedEquipment}
                data={sheets}
                projectDdv={project.ddv}
                isUsageComputed={project.isUsageComputed}
                masterDataEquipment={searchValue}
                currentIndicator={currentIndicator}
                isRe2020={project.isRe2020}
                switchSelectedEquipment={switchSelectedEquipment}
                currentProject={project}
                replaceEquipment={replaceEquipment}
                equipmentsAvailable={equipmentsAvailable}
                scope={scope}
                allGroups={allGroups}
                ctx={ctx}
                nodeSearchValue={nodeSearchValue}
              />
            </>
          )}
        </>
      );
    }

    if (project && currentAllotment) {
      return (
        <ImpactSunburst
          sheets={project.Sheets}
          currentFlag={currentFlag}
          selectedLot={selectedLot}
          selectedFonction={selectedFonction}
          selectedCategory={selectedCategory}
          selectedMaterial={selectedMaterial}
          selectedType={selectedType}
          isAcvDynamic={project.isAcvDynamic}
          onClick={sunburstClickHandler}
          lots={project.Lots}
          unit={currentIndicator.unit}
          projectArea={surfaceRef}
          isRe2020={project.isRe2020}
          selectedZoneName={selectedZoneName(selectedZoneIdForGraph, parsedZones)}
        />
      );
    }
  };

  const renderRightGraph = () => {
    if ((lockedSelectedEquipment || replaceEquipment) && searchValue) {
      return (
        <>
          <SwitchEquipment
            setLockedSelectedEquipment={setLockedSelectedEquipment}
            lockedSelectedEquipment={lockedSelectedEquipment}
          />
        </>
      );
    } else if ((selectedEquipment && searchValue) || (searchValue && sheets)) {
      return (
        <>
          {loadingGraph && (
            <EquipmentDatas
              AppContext={AppContext}
              allGroups={allGroups}
              filteredEquipments={sheetToLifeCycle ? [sheetToLifeCycle] : []}
              onEquipmentSelect={onEquipmentSheetEquipmentSelect}
              equipment={selectedEquipment}
              lockedSelectedEquipment={lockedSelectedEquipment}
              decMds={decMds}
              fetchProjectById={fetchProjectById}
              setSelectedEquipment={setSelectedEquipment}
              loadingGraph={loadingGraph}
              updateNewInies={updateNewInies}
              sheets={sheets}
              currentProject={project}
              currentMd={currentMd}
              searchValue={searchValue}
              parsedZones={parsedZones}
              graphData={graphData}
              currentAllotment={currentAllotment}
              filteredLifeCycle={filteredLifeCycle}
              graphView={graphView}
              currentIndicator={currentIndicator}
              updateFormValue={updateFormValue}
              projectSheets={projectSheets}
              exploitationSheets={exploitationSheets}
              saveFiltersBrand={saveFiltersBrand}
              filters={filters}
              lists={lists}
              loadGraph={loadGraph}
              AntTabs={AntTabs}
              AntTab={AntTab}
              NoocoCheckbox={NoocoCheckbox}
              Api={apiNooco}
              licenceTypeAccess={licenceTypeAccess}
              groups={mds}
              flatAllotments={flatAllotments}
              isRegulatoryProject={returnIfProjectIsRegulatory(project.templateType)}
            />
          )}
        </>
      );
    } else {
      const histogramGraphtitle = t('APP.LIFECYCLE.LIFECYCLETAB.PH_15CAB1', {
        value1: selectedZoneName(selectedZoneIdForGraph, parsedZones),
        value2: getProjectPeriodInYears(project.ddv, project.exploitContract),
        defaultValue: 'Analyse du cycle de vie - niveau {value1} - sur {value2} ans',
      });

      let temporalGraphTitle = t('APP.LIFECYCLE.LIFECYCLETAB.PH_D4CDBA', {
        value1: selectedZoneName(selectedZoneIdForGraph, parsedZones),
        value2: graphView === 'temporalStacked' ? ' en aires' : '',
        value3: getProjectPeriodInYears(project.ddv, project.exploitContract),
        defaultValue:
          'Analyse du cycle de vie - {value1} - temporelle{value2} sur {value3} ans',
      });

      return (
        <>
          <div style={{ position: 'absolute', right: '10px' }}>
            <Tooltip
              title={t('APP.LIFECYCLE.LIFECYCLETAB.PH_F90284', {
                defaultValue: "Visualiser l'analyse du cycle de vie par phases",
              })}
            >
              <IconButton
                onClick={() => updateFormValue('graphView', 'bar')}
                style={{ background: graphView === 'bar' ? '#0000000a' : null }}
              >
                <BarChartIcon />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={t('APP.LIFECYCLE.LIFECYCLETAB.PH_D51B4A', {
                defaultValue:
                  "Visualiser l'analyse du cycle de vie en fonction du temps sur la période d'étude du projet",
              })}
            >
              <IconButton
                onClick={() => updateFormValue('graphView', 'temporal')}
                style={{
                  background: graphView === 'temporal' ? '#0000000a' : null,
                }}
              >
                <ShowChartIcon />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={t('APP.LIFECYCLE.LIFECYCLETAB.PH_9031EE', {
                defaultValue:
                  "Visualiser l'analyse du cycle de vie par lot en fonction du temps sur la période d'étude du projet",
              })}
            >
              <IconButton
                onClick={() => updateFormValue('graphView', 'temporalStacked')}
                style={{
                  background: graphView === 'temporalStacked' ? '#0000000a' : null,
                }}
              >
                <StackedLineChartIcon />
              </IconButton>
            </Tooltip>
          </div>

          {['temporal', 'temporalStacked'].includes(graphView) && (
            <LifeCycleTemporalGraph
              equipments={
                sheetToLifeCycle
                  ? [sheetToLifeCycle]
                  : filteredLifeCycle
                  ? filteredLifeCycle
                  : []
              }
              title={
                <h4
                  style={{
                    fontSize: window.screen.availHeight < 810 ? '0.9rem' : null,
                    fontWeight: window.screen.availHeight < 950 ? 'bold' : null,
                  }}
                >
                  {temporalGraphTitle}
                </h4>
              }
              projectDdv={project.ddv}
              refUnit={project.refUnit}
              graphData={graphData}
              currentAllotment={currentAllotment}
              projectArea={surfaceRef}
              parsedZones={parsedZones}
              isStacked={graphView === 'temporalStacked'}
            />
          )}

          {graphView === 'bar' && (
            <LifeCycleAnalysisGraph
              equipments={
                sheetToLifeCycle
                  ? [sheetToLifeCycle]
                  : filteredLifeCycle
                  ? filteredLifeCycle
                  : []
              }
              title={
                <h4
                  style={{
                    fontSize: window.screen.availHeight < 810 ? '0.9rem' : null,
                    fontWeight: window.screen.availHeight < 950 ? 'bold' : null,
                  }}
                >
                  {histogramGraphtitle}
                </h4>
              }
              width={50}
              height={40}
              aspectRatio
              projectDdv={project.ddv}
              isUsageComputed={project.isUsageComputed}
              isFreeModeActivated={project.isFreeModeActivated}
              isAcvDynamic={project.isAcvDynamic}
              decMds={decMds}
            />
          )}
        </>
      );
    }
  };

  const callSheets = async (masterDataEquipment) => {
    setLoadingGraph();

    if (currentIndicator) {
      let fullData = (
        await ctx.api.getIndicatorsBySheet(
          nodeSearchValue.noocoAllotId || nodeSearchValue.id,
          currentIndicator && currentIndicator.id !== 26 ? currentIndicator.id : 1,
          project.id
        )
      ).data;

      let sheet = fullData[0];

      for (let i = 1; i < fullData.length; i++) {
        if (fullData[i].Caracs.length > sheet.Caracs.length) {
          sheet = fullData[i];
        }
      }

      let caracs = (
        await ctx.api.getEquipmentCaracListById(
          nodeSearchValue.noocoAllotId || nodeSearchValue.id
        )
      ).data.caracs;

      masterDataEquipment.Caracs = caracs;

      const isSecondDiscrete = masterDataEquipment.Caracs?.find(
        (carac) => carac.id === masterDataEquipment.secondDiscreteId
      );

      // Tricks to create caracs in case of second discrete to value 'aucun'
      if (isSecondDiscrete && sheets) {
        for (let i = 0; i < fullData.length; i++) {
          if (!fullData[i].Caracs.find((el) => el.name === isSecondDiscrete.name)) {
            fullData[i].Caracs = [
              ...fullData[i].Caracs,
              { name: isSecondDiscrete.name, SheetCarac: { value: ['Aucun'] } },
            ];
          }
        }
      }

      saveSheets(fullData, masterDataEquipment);
    } else {
      let fullData = (
        await ctx.api.getSheets(
          nodeSearchValue.noocoAllotId || nodeSearchValue.id,
          false
        )
      ).data;

      saveSheets(fullData, masterDataEquipment);
    }
  };

  // reset breadcrumb and sheetLifeCycle when coming from contributors list
  useEffect(() => {
    if (!searchValue && allotSteps?.length > 0 && sheetToLifeCycle) {
      updateFormValue('allotSteps', []);
      updateFormValue('sheetToLifeCycle', null);
    }
  }, [searchValue]);

  const onBreadCrumbSelectCallback = async (
    lot,
    fonction,
    category,
    material,
    type,
    flag
  ) => {
    updateFormValue('selectedLot', lot);
    updateFormValue('selectedFonction', fonction);
    updateFormValue('selectedCategory', category);
    updateFormValue('selectedMaterial', material);
    updateFormValue('selectedType', type);
    updateCurrentFlag(flag);
    setSelectedEquipment();
    setLockedSelectedEquipment();
  };

  return (
    <div className="tabpanel-container">
      <div
        className="wrapperBreadCrum"
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Breadcrumb
            groups={groups}
            onSelect={onBreadCrumbSelectCallback}
            searchBar
            currentProject={project}
            isRe2020={project.isRe2020}
            validZonesIds={validZonesIdsState}
          />
          <NavigationAllot
            setSelectedEquipment={setSelectedEquipment}
            selectedZoneName={selectedZoneName(selectedZoneIdForGraph, parsedZones)}
          />
        </div>
        <div style={{ marginRight: '3em' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: '4px' }}>
              {t('APP.LIFECYCLE.LIFECYCLETAB.LEVEL', {
                defaultValue: 'Niveau',
              })}
            </div>{' '}
            <div style={{ marginRight: '4px' }}>
              <Tooltip
                title={t('APP.LIFECYCLE.LIFECYCLETAB.PH_26898B', {
                  defaultValue:
                    "Filtrer les résultats à l'échelle du projet, d'un bâtiment ou d'une zone",
                })}
              >
                <InfoIcon />
              </Tooltip>
            </div>
            <Dropdown
              value={selectedZoneIdForGraph}
              options={structuredZonesForGraphDropdown}
              style={{
                borderRadius: '15px',
                width: '350px',
                maxHeight: '45px',
              }}
              treeCheckable={false}
              multiple={false}
              treeNodeFilterProp="title"
              onSelect={(e) => {}}
              onChange={(e) => {
                handleOnChangeSelectedZonesForGraph(e);
              }}
              treeDefaultExpandAll={true}
              allowClear={false}
              placeholder={t('APP.LIFECYCLE.LIFECYCLETAB.PH_6AD46D', {
                defaultValue: 'Sélectionner des zones',
              })}
            />
          </div>
        </div>
      </div>
      <div
        style={{ display: 'flex', flex: 1, justifyContent: 'space-around' }}
        key={searchValue}
      >
        {!loadingGraph && loadingWaitingGraph ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '45vh',
            }}
          >
            <Loader
              variant="indeterminate"
              disableShrink
              style={{ color: '#73b7ec' }}
              size={65}
              thickness={4}
            />
          </div>
        ) : (
          <>
            {renderLeftGraph()}
            {renderRightGraph()}
          </>
        )}
      </div>
    </div>
  );
};

export default LifeCycleTab;
