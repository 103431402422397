/**
 * Created by nanaa on 18/05/2021
 */

import { connect } from 'react-redux';
import LifeCycleTemporalGraph from '../../app/projects/LifeCycle/LifeCycleTemporalGraph/LifeCycleTemporalGraph';

const mapStateToProps = (state) => ({
  currentIndicator: state.projects.currentIndicator,
  projectArea: state.projects.currentProject.area,
  currentProject: state.projects.currentProject,
  zones: state.projects.zones,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LifeCycleTemporalGraph);
