import { Table } from 'antd';
//import 'antd/dist/antd.min.css';
import { Col, Form } from 'react-bootstrap';
import {
  closest,
  handleAllotmentNodes,
  findBreadcrumbChoices,
  findParentNode,
  findMdByAllotmentNodeId,
  checkIfNodeCanBeAddedInProject,
  checkIfForfaitBlocksEquipmentCreation,
  findAllotmentNodesForNewBundle,
  returnSearchBarOptionsFiltered,
  handleKeyPress,
} from '../../../shared/utils';
import { useVT } from 'virtualizedtableforantd4';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Alert from '@mui/material/Alert';
import AppContext from '../../../AppContext';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Breadcrumb from '../../../../containers/BreadCrumb';
import InfoIcon from '@material-ui/icons/Info';
import { Button, SearchGroup } from '@photocarbone/nooco-ui';
import Checkbox from '@material-ui/core/Checkbox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Loader from '@material-ui/core/CircularProgress';
import NoocoCheckbox from '../../../shared/NoocoCheckbox';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactFlow from 'reactflow';
import 'reactflow/dist/style.css';
import 'reactflow/dist/base.css';
import SearchBar from '../../../shared/SearchBar';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import area from '../../../../assets/images/equipments/area.png';
import buildingPic from '../../../../assets/images/synthese/Image1.png';
import buildingSite from '../../../../assets/images/equipments/buildingSite.png';
import edecPic from '../../../../assets/images/equipments/edec.svg';
import energyPic from '../../../../assets/images/equipments/energy.png';
import forfaitPic from '../../../../assets/images/equipments/forfait.png';
import modelingPic from '../../../../assets/images/equipments/modeling.png';
import plot from '../../../../assets/images/synthese/Image2.png';
import waterPic from '../../../../assets/images/equipments/water.png';
import xmlPic from '../../../../assets/images/equipments/xml.png';
import reemployPic from '../../../../assets/images/equipments/reemploy.png';
import ComposantVideForm from '../../../shared/ComposantVideForm/index';
import ReemployCheck from '../../../shared/ReemployCheck';
import InputSousLotRe2020 from '../../../../containers/InputSousLotRe2020';
import { useDebounce } from '../../../../hooks/utils/debounce';
import { useForm, FormProvider } from 'react-hook-form';
import { changeValueEquipments as changeValueEquipmentsAsAction } from '../../../../actions/equipments';
import './addEquipments.scss';
import {
  PROJECT_TYPE_TITLE,
  REFERENCE_TYPE_NAME,
  REGULATORY_PROJECTS,
  TEMPLATE_TYPE_NAME,
  TYPE_AIRPORT,
  TYPE_BBCA,
  TYPE_DATACENTER,
  TYPE_EPCM,
  TYPE_NOOCO,
  TYPE_OUTDOOR,
  TYPE_RE2020,
  TYPE_RENOVATION,
  typeProjectForSimplifiedEnergy,
} from '../../../globalVariable/typeProject';
import { calculatorUpdate } from '../../../../actions/calculator';
import { updateFavoritesValue } from '../../../../actions/favorites';
import FavoritesIcons from '../../../shared/FavoritesIcons';
import SimplifiedEnergy, {
  checkSimplifiedEnergyForm,
} from '../../../shared/SimplifiedEnergy';
import ModelingEquipment from './ModelingEquipment/ModelingEquipment';
import { useCreateEquipmentCustom } from '../../../../hooks/useQuery/equipments/createEquipmentCustom';
import informationPic from '../../../../assets/images/equipments/information.png';
import BreadcrumbModal from '../../../../containers/BreadcrumbModal';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { useTranslation } from 'react-i18next';
import { decimalNumberRegex } from '../../../shared/Regex/regex.js';
import { SEARCH_GROUP_OPTION_VALUES } from '../../../globalVariable/searchGroupType';
import {
  getSheetType,
  onChangeSelectedSearchGroupType,
} from '../../../shared/utilsFunction';
import {
  SelectedSearchGroupOptionsLabel,
  checkIdBundleCanBeAddedInProject,
  checkIfConfiguredSheetCanBeAdded,
} from '../../../shared/QuickAddBarEquipment/QuickAddBarEquipment.tsx';
import { useLocalStorage } from 'react-use';

const AddEquipment = ({
  currentProject,
  setSearchValue,
  setInputValue,
  inputValue,
  searchValue,
  updateFormValue,
  updateCurrentFlag,
  selectedType,
  selectedLot,
  selectedFonction,
  selectedCategory,
  selectedMaterial,
  addDisplay,
  changeValueEquipments,
  zones,
  iniesAdvanceLink,
  callback,
  elementsTree,
  currentDestination,
  fluids,
  groups,
  allGroups,
  setOpenSnackbar,
  breadcrumbChoice,
  forfaitList,
  errorIdInies,
  fetchIniesEquipments,
  datasForfait,
  loginEdec,
  edecUsername,
  edecPassword,
  edecChoice,
  edecFiles,
  displaySpinner,
  selectedRowKeys,
  downloadEdecFile,
  edecNames,
  filterEdec,
  isMenuOpen,
  filterData,
  parsed,
  urbanNetworks,
  fetchProjectById,
  currentIndicator,
  iniesNomenclature,
  askingIsDed,
  composantVideForm,
  sendRequestDed,
  currentAllotment,
  re2020lot,
  lineImport,
  commentEquipment,
  customName,
  idAsker,
  simplifiedEnergyLines,
}) => {
  const [displayBreadCrumb, setDisplayBreadCrumb] = useState(false);
  const [datas, setDatas] = useState([]);
  const [currentMd, setCurrentMd] = useState();
  const [refCaracs, setRefCaracs] = useState();
  const [refCaracsLength, setRefCaracsLenth] = useState(0);
  const [selectedQuantity, setSelectedQuantity] = useState(null);
  const [filteredSheets, setFilteredSheets] = useState([]);
  const [brands, setBrands] = useState(undefined);
  const [xmlFile, setXmlFile] = useState(undefined);
  const [userCaracs, setUserCaracs] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState(undefined);
  const [sheetsToShow, setSheetsToShow] = useState(undefined);
  const [selectedBrand, setSelectedBrand] = useState(undefined);

  const [selectedSheetObj, setSelectedSheetObj] = useState(undefined);
  const [selectedSubEquip, setSelectedSubEquip] = useState(undefined);

  const [fluidsData, setFluidsData] = useState([]);

  const [subEquipQuantity, setSubEquipQuantity] = useState(undefined);
  const [noCarac, setNoCarac] = useState(false);
  const [equipmentWaiting, setEquipmentWaiting] = useState(0);
  const [widthTable, setWidthTable] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [reEmploy, setReEmploy] = useState(false);
  const [urbanChoice, setUrbanChoice] = useState();
  const [urbanInput, setUrbanInput] = useState();
  const [isCurrentDestinationFilled, setIsCurrentDestinationFilled] =
    useState(false);
  const [
    isCurrentDestinationFilledForModeling,
    setIsCurrentDestinationFilledForModeling,
  ] = useState(false);
  const [currentDestinationForModeling, setCurrentDestinationForModeling] =
    useState(null);
  const [displayOverwritteFluidsForm, setDisplayOverwritteFluidsForm] =
    useState(false);
  const [isConfiguredSheetSelected, setIsConfiguredSheetSelected] = useState(false);

  const { t } = useTranslation();

  const { isOldBuildingSiteInProject, nbZoneBuildingSite } = useSelector(
    (state) => state.projects.calculator
  );
  const {
    nodeSearchValue,
    allotmentName,
    selectedIniesSheet,
    nodesAvailableForProjectType,
    bundleOptions,
    modeledProductOptions,
    configuredSheetOptions,
    certifiedAllotments,
    user,
  } = useSelector((state) => state.projects);
  const [selectedSearchGroupType, setSelectedSearchGroupType] = useLocalStorage(
    `selectedSearchGroupType-${user.userId}`,
    SelectedSearchGroupOptionsLabel.Equipements
  );
  const { addXmlFavorite, customXmlName } = useSelector((state) => state.favorites);
  const { templateType } = useSelector((state) => state.projects.currentProject);
  const { flatAllotments } = useSelector((state) => state.projects.allGroups);
  const datasinies = useSelector((state) => state.equipments.datasinies);
  const { modeling } = useSelector((state) => state.equipments);
  const { isCarbonWeightSelected, caracteristicForm, carbonWeightForm } = modeling;
  const caracteristicHookForm = useForm({
    defaultValues: caracteristicForm,
  });
  const carbonWeightFormInstance = useForm({
    defaultValues: carbonWeightForm,
  });
  const isRegulatoryProject = REGULATORY_PROJECTS.includes(
    currentProject.templateType
  );
  const searchGroupOptions = [
    SEARCH_GROUP_OPTION_VALUES.EQUIPEMENTS,
    SEARCH_GROUP_OPTION_VALUES.INIES,
    SEARCH_GROUP_OPTION_VALUES.BIBLIOTHEQUE,
  ];

  const searchBarOptionsFiltered = returnSearchBarOptionsFiltered({
    allAllotments: allGroups.flatAllotments,
    allotmentsAvailable: nodesAvailableForProjectType,
    iniesOptions: [],
    bundleOptions: bundleOptions.filter((bundle) =>
      checkIdBundleCanBeAddedInProject(bundle, currentProject, allGroups)
    ),
    modeledProductOptions,
    configuredSheetOptions: configuredSheetOptions?.filter((configuredSheet) =>
      checkIfConfiguredSheetCanBeAdded(configuredSheet, currentProject, allGroups)
    ),
    currentAllotment,
  });

  const dispatch = useDispatch();
  const ctx = useContext(AppContext);

  useEffect(() => {
    if (zones.filter((el) => el.type !== 'buildingSite').length === 2) {
      changeValueEquipments(
        'currentDestination',
        zones.find((el) => el.type === 'zone').id
      );
    }

    const isPlotInProject = zones.find((el) => el.type === 'plot') !== undefined;
    const isOnlyOneBuildingInProject =
      zones.filter((el) => el.type === 'building').length === 1;
    const isOnlyOneZoneInProject =
      zones.filter((el) => el.type === 'zone').length === 1;
    const isNoBuildingSiteInProject =
      zones.filter((el) => el.type === 'buildingSite').length === 0;

    setIsCurrentDestinationFilled(
      !isPlotInProject &&
        (!isOldBuildingSiteInProject || isNoBuildingSiteInProject) &&
        isOnlyOneBuildingInProject &&
        isOnlyOneZoneInProject
    );

    if (
      !isPlotInProject &&
      (!isOldBuildingSiteInProject || isNoBuildingSiteInProject) &&
      isOnlyOneBuildingInProject &&
      isOnlyOneZoneInProject
    ) {
      changeValueEquipments(
        'currentDestination',
        zones.find((el) => el.type === 'zone').id
      );
    }

    setIsCurrentDestinationFilledForModeling(
      !isPlotInProject && isOnlyOneBuildingInProject && isOnlyOneZoneInProject
    );

    if (!isPlotInProject && isOnlyOneBuildingInProject && isOnlyOneZoneInProject) {
      setCurrentDestinationForModeling(zones.find((el) => el.type === 'zone').id);
    }

    return () => {
      changeValueEquipments('addDisplay', null);
      updateFormValue('searchValue', null);
      updateFormValue('nodeSearchValue', null);
      updateFormValue('allotSteps', null);
    };
  }, []);

  useEffect(() => {
    if (zones.length - nbZoneBuildingSite === 2 && !isOldBuildingSiteInProject) {
      changeValueEquipments(
        'currentDestination',
        zones.find((el) => el.type === 'zone').id
      );
    }
  }, [nbZoneBuildingSite]);

  useEffect(() => {
    if (isOldBuildingSiteInProject === false) {
      let nbZoneBuildingSiteTemp = 0;
      zones.map((zone) => {
        if (zone.type === 'buildingSite') {
          nbZoneBuildingSiteTemp = +1;
        }
      });
      dispatch(calculatorUpdate('nbZoneBuildingSite', nbZoneBuildingSiteTemp));
    }
  }, []);

  const resetCommonValues = () => {
    changeValueEquipments('addDisplay', null);
    changeValueEquipments('isLoadingProject', true);
    changeValueEquipments('datasForfait', []);
    updateFormValue('selectedIniesSheet', null);
    updateFormValue('selectedLot', null);
    updateFormValue('selectedFonction', null);
    updateFormValue('selectedCategorie', null);
    updateFormValue('selectedMaterial', null);
    updateFormValue('selectedType', null);
    updateFormValue('nodeSearchValue', null);
    changeValueEquipments('displaySpinner', false);
    // changeValueEquipments('currentDestination', null);
    setSelectedQuantity();
    setSearchValue('');
    setInputValue('');
    setSubEquipQuantity(null);
    setSelectedSubEquip(null);
    setFluidsData([]);

    setSelectedSheet(null);
    setSheetsToShow(null);
    setSelectedSheetObj(null);
    setSelectedSheet(null);
    setSelectedBrand(null);
    changeValueEquipments('currentDestination', null);
  };

  const resetGenericValues = () => {
    updateFormValue('commentEquipment', '');
    updateFormValue('customName', null);
    changeValueEquipments('composantVideForm', {});
    changeValueEquipments('re2020lot', null);
    updateFormValue('lineImportId', null);
  };

  const resetAddEquipment = (sourceName) => {
    switch (sourceName) {
      case 'Modeling':
        resetCommonValues();
        changeValueEquipments('modeling', {
          isCarbonWeightSelected: null,
          caracteristicForm: {
            equipmentName: null,
            quantity: null,
            unit: null,
            lifeSpan: null,
            comment: null,
            labelQuote: null,
          },
          carbonWeightForm: {
            product: null,
            installation: null,
            usage: null,
            endOfLife: null,
            profit: null,
          },
          materialLines: [],
          totalWeight: null,
          manufacturedRate: 10,
          addMaterialLine: {
            masterdata: null,
            percent: null,
          },
        });

        break;

      case 'Forfait':
        resetCommonValues();
        break;

      case 'Energie':
        resetCommonValues();
        // changeValueEquipments('datasForfait', []);
        changeValueEquipments('simplifiedEnergyLines', [
          {
            id: 0,
            AllotmentNodeId: null,
            quantity: null,
            caracs: [],
            unit: 'kWh',
            actionType: 'energy',
          },
        ]);
        break;

      case 'selectedIniesSheet':
        resetCommonValues();
        resetGenericValues();
        setUrbanChoice();
        changeValueEquipments('iniesAdvanceLink', null);
        changeValueEquipments('datasinies', []);

        break;

      case 'generic':
        resetCommonValues();
        resetGenericValues();
        changeValueEquipments('selectedRowKeys', []);
        changeValueEquipments('edecChoice', null);
        setUrbanChoice();
        setXmlFile();
        break;

      default:
        console.log('Invalid source name.');
    }

    fetchProjectById(currentProject.id, currentIndicator.id);
  };

  const checkIfDedHasFluid = (sheet) => {
    const userDiscrete = userCaracs.find((c) => c.id === refCaracs.discreteId);
    const userSecondDiscrete = userCaracs.find(
      (c) => c.id === refCaracs.secondDiscreteId
    );

    if (
      (userDiscrete && !userDiscrete.value) ||
      (userSecondDiscrete && !userSecondDiscrete.value)
    ) {
      return false;
    }

    const dedSheets = filteredSheets.filter((sheet) => {
      if (userSecondDiscrete) {
        return (
          sheet.type_fiche === 'DED' &&
          sheet.Caracs.find(
            (el) =>
              el.name === userDiscrete.name &&
              el.SheetCarac.value.find((el) => el === userDiscrete.value)
          ) &&
          sheet.Caracs.find(
            (el) =>
              el.name === userSecondDiscrete.name &&
              el.SheetCarac.value.find((el) => el === userSecondDiscrete.value)
          )
        );
      } else if (userDiscrete && !userSecondDiscrete) {
        return (
          sheet.type_fiche === 'DED' &&
          sheet.Caracs.find(
            (el) =>
              el.name === userDiscrete.name &&
              el.SheetCarac.value.find((el) => el === userDiscrete.value)
          )
        );
      } else {
        return sheet.type_fiche === 'DED';
      }
    });

    for (const sheet of dedSheets) {
      const indices = sheet.Indices[0].SheetIndex;

      if (indices.B1) return sheet;
    }

    return false;
  };

  const [buildings, setBuildings] = useState([]);
  const [allEmpty, setAllEmpty] = useState(false);

  const addLine = (zoneSelected) => {
    let buildingsUpdated = buildings.map((building) => {
      if (building.id === zoneSelected.ParentId) {
        let zonesUpdated = building.Zones.map((zone) => {
          if (zone.id === zoneSelected.id) {
            return {
              ...zone,
              fluids: [
                ...zone.fluids,
                {
                  selectedFluid: null,
                  fluidQuantity: null,
                  key: Math.random().toString(36).substring(7),
                  ZoneId: zone.id,
                },
              ],
            };
          } else {
            return zone;
          }
        });

        return {
          ...building,
          Zones: zonesUpdated,
        };
      }

      return building;
    });

    setBuildings(buildingsUpdated);
    let fluids = buildingsUpdated.map((building) => {
      return building.Zones.map((zone) => {
        return zone.fluids;
      });
    });

    fluids = fluids.flat(2);

    setFluidsData(fluids);
  };

  const deleteLine = (zonesSelected, fluidToDelete) => {
    let buildingsUpdated = buildings.map((building) => {
      if (building.id === zonesSelected.ParentId) {
        let zonesUpdated = building.Zones.map((zone) => {
          if (zone.id === zonesSelected.id) {
            return {
              ...zone,
              fluids: zone.fluids.filter((fluid) => fluid.key !== fluidToDelete.key),
            };
          } else {
            return zone;
          }
        });

        return {
          ...building,
          Zones: zonesUpdated,
        };
      }

      return building;
    });

    setBuildings(buildingsUpdated);
    let fluids = buildingsUpdated.map((building) => {
      return building.Zones.map((zone) => {
        return zone.fluids;
      });
    });

    fluids = fluids.flat(2);

    setFluidsData(fluids);
  };

  const updateLine = (zonesSelected, fluid) => {
    let buildingsUpdated = buildings.map((building) => {
      if (building.id === zonesSelected.ParentId) {
        let zonesUpdated = building.Zones.map((zone) => {
          if (zone.id === zonesSelected.id) {
            return {
              ...zone,
              fluids: zone.fluids.map((f) => {
                if (f.key === fluid.key) {
                  return {
                    ...f,
                    selectedFluid: fluid.selectedFluid,
                    fluidQuantity: fluid.fluidQuantity,
                  };
                } else {
                  return f;
                }
              }),
            };
          } else {
            return zone;
          }
        });

        return {
          ...building,
          Zones: zonesUpdated,
        };
      }

      return building;
    });

    setBuildings(buildingsUpdated);
    let fluids = buildingsUpdated.map((building) => {
      return building.Zones.map((zone) => {
        return zone.fluids;
      });
    });

    fluids = fluids.flat(2);

    setFluidsData(fluids);
  };

  //this is not working because the choice comes after the useEffect.
  useEffect(() => {
    setBuildings(
      zones
        .filter((zone) => zone.type === 'building')
        .map((building) => {
          return {
            ...building,
            Zones: zones
              .filter(
                (zone) => zone.type === 'zone' && zone.ParentId === building.id
              )
              .map((zone) => {
                const isZoneInForfait = datasForfait.some(
                  (forfait) => forfait.ZoneId === zone.id
                );
                return {
                  ...zone,
                  fluids: isZoneInForfait
                    ? [
                        {
                          selectedFluid: null,
                          fluidQuantity: null,
                          key: Math.random().toString(36).substring(7),
                          ZoneId: zone.id,
                        },
                      ]
                    : [],
                };
              }),
          };
        })
    );
  }, []);

  const setNoFluid = (zone) => {
    let buildingsUpdated = buildings.map((building) => {
      if (building.id === zone.ParentId) {
        let zonesUpdated = building.Zones.map((z) => {
          if (z.id === zone.id) {
            return {
              ...z,
              fluids: [],
            };
          } else {
            return z;
          }
        });

        return {
          ...building,
          Zones: zonesUpdated,
        };
      }

      return building;
    });

    setBuildings(buildingsUpdated);
  };

  const renderFluidsModal = () => {
    let formControl = null;
    let refFluide = null;
    let flagTmp = false;

    if (
      (selectedSheetObj && selectedSheetObj.type_fiche === 'PEP') ||
      (iniesAdvanceLink && iniesAdvanceLink.SheetId.type_fiche === 'PEP')
    ) {
      flagTmp = true;
      const fluide = selectedSheetObj
        ? selectedSheetObj.Caracs.find((carac) =>
            carac.name.toLocaleLowerCase().includes('type fluide')
          )
        : iniesAdvanceLink.SheetId.Caracs.find((carac) =>
            carac.name.toLocaleLowerCase().includes('type fluide')
          );

      if (fluide) {
        refFluide = fluids.find((fluid) =>
          fluid.name.includes(fluide.SheetCarac.value[0])
        );
      }

      if (refFluide) {
        if (!selectedSubEquip) {
          setSelectedSubEquip(refFluide.id);
        }

        formControl = (
          <Form.Control custom as="select" readOnly>
            <option>{refFluide.name}</option>
          </Form.Control>
        );
      }
    }

    let updatedBuildings = buildings
      ?.map((building) => {
        let updatedZones = building.Zones.filter((zone) => {
          return datasForfait.some(
            (forfait) => forfait.ZoneId === zone.id && forfait.check === true
          );
        });
        return {
          ...building,
          Zones: updatedZones,
        };
      })
      .filter((building) => building.Zones.length > 0);

    return (
      <div>
        {updatedBuildings.length > 0 &&
          updatedBuildings.map((building) => {
            return (
              <div key={building.id}>
                {building.Zones.map((zone) => {
                  return (
                    <div key={zone.id}>
                      <h3 className="calculator__label">
                        {building.name} - {zone.name}
                      </h3>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                        }}
                      >
                        {zone.fluids.map((fluid) => {
                          return (
                            <div
                              key={fluid.key}
                              style={{
                                display: 'flex',
                                width: '100%',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                              }}
                            >
                              {((selectedSheetObj &&
                                !selectedSheetObj?.Indices[0].SheetIndex.B1) ||
                                !iniesAdvanceLink?.SheetId.Indices[0].SheetIndex
                                  .B1) &&
                                !checkIfDedHasFluid() && (
                                  <Form.Group
                                    key={selectedSheetObj}
                                    style={{
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      flex: 1,
                                      paddingLeft: 0,
                                    }}
                                    as={Col}
                                  >
                                    <Form.Label>
                                      {t('APP.ADDEQUIPMENT.INDEX.PH_80BD24', {
                                        defaultValue: 'Type de Fluide',
                                      })}
                                    </Form.Label>

                                    {formControl ? (
                                      formControl
                                    ) : (
                                      <Form.Control
                                        as="select"
                                        custom
                                        required
                                        defaultValue={t(
                                          'APP.ADDEQUIPMENT.INDEX.PH_8CB548',
                                          { defaultValue: 'Choisir une valeur' }
                                        )}
                                        onChange={(e) => {
                                          flagTmp === true
                                            ? setSelectedSubEquip(e.target.value)
                                            : updateLine(zone, {
                                                ...fluid,
                                                selectedFluid: e.target.value,
                                              });
                                        }}
                                      >
                                        <option key={'empty'} value={''}>
                                          {t('APP.ADDEQUIPMENT.INDEX.PH_050930', {
                                            defaultValue: 'Choisir une valeur',
                                          })}
                                        </option>
                                        {fluids.map((f) => (
                                          <option key={f.id} value={f.id}>
                                            {f.name}
                                          </option>
                                        ))}
                                      </Form.Control>
                                    )}
                                  </Form.Group>
                                )}

                              {(selectedSheetObj &&
                                selectedSheetObj?.Indices &&
                                selectedSheetObj?.Indices[0].SheetIndex.B1) ||
                              (iniesAdvanceLink &&
                                iniesAdvanceLink?.SheetId.Indices[0].SheetIndex
                                  .B1) ||
                              checkIfDedHasFluid() ? null : (
                                <>
                                  <Form.Group
                                    style={{
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      flex: 1,
                                      paddingLeft: 0,
                                    }}
                                    as={Col}
                                  >
                                    <Form.Label>
                                      {addDisplay.includes('Forfait')
                                        ? t('APP.ADDEQUIPMENT.INDEX.PH_23F38A', {
                                            defaultValue:
                                              'Masse de fluide totale (kg)',
                                          })
                                        : t('APP.ADDEQUIPMENT.INDEX.PH_255CD0', {
                                            defaultValue:
                                              'Masse de fluide par équipement (kg)',
                                          })}
                                    </Form.Label>
                                    <Form.Control
                                      autoComplete="off"
                                      autoCorrect="off"
                                      spellCheck="off"
                                      type="number"
                                      defaultValue={0}
                                      style={{ width: '30%' }}
                                      onChange={(e) => {
                                        flagTmp === true
                                          ? setSubEquipQuantity(e.target.value)
                                          : updateLine(zone, {
                                              ...fluid,
                                              fluidQuantity: e.target.value,
                                            });
                                      }}
                                      required
                                    />

                                    <Form.Control.Feedback type="invalid">
                                      {t('APP.ADDEQUIPMENT.INDEX.PH_3E7156', {
                                        defaultValue:
                                          'Veuillez renseigner une quantité.',
                                      })}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </>
                              )}
                              {(selectedSheetObj &&
                                selectedSheetObj?.Indices &&
                                selectedSheetObj?.Indices[0].SheetIndex.B1) ||
                              (iniesAdvanceLink &&
                                iniesAdvanceLink?.SheetId.Indices[0].SheetIndex
                                  .B1) ||
                              checkIfDedHasFluid() ? null : (
                                <div
                                  onClick={() => {
                                    deleteLine(zone, fluid);
                                  }}
                                >
                                  <HighlightOffIcon
                                    style={{ width: '40px', height: '40px' }}
                                    className="calculator__delElement"
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })}
                        {(selectedSheetObj &&
                          selectedSheetObj?.Indices &&
                          selectedSheetObj?.Indices[0].SheetIndex.B1) ||
                        (iniesAdvanceLink &&
                          iniesAdvanceLink?.SheetId.Indices[0].SheetIndex.B1) ||
                        checkIfDedHasFluid() ? null : (
                          <>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div
                                className="calculator__addElement"
                                onClick={() => addLine(zone)}
                              >
                                <ControlPointIcon />
                                {t('APP.ADDEQUIPMENT.INDEX.PH_05F8FF', {
                                  defaultValue:
                                    'Ajout un autre type de fluide au forfait',
                                })}
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <Form.Check
                                type="checkbox"
                                label={t('APP.ADDEQUIPMENT.INDEX.PH_19FEB4', {
                                  defaultValue:
                                    "Il n'y a pas de fluide frigorigène sur cette zone",
                                })}
                                checked={zone.fluids.length === 0}
                                onChange={(e) => setNoFluid(zone)}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    );
  };

  const renderOverwritteFluid = () => {
    let typeFluideCarac = checkIfDedHasFluid()
      ? checkIfDedHasFluid()?.Caracs.find((carac) =>
          carac.name.toLowerCase().includes('type fluide')
        )
      : null;

    let quantityFluideCarac = checkIfDedHasFluid()
      ? checkIfDedHasFluid()?.Caracs.find((carac) =>
          carac.name.toLowerCase().includes('charge')
        )
      : null;

    return (
      <>
        <Form.Group
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            paddingLeft: 0,
          }}
          as={Col}
        >
          <Form.Label>
            {t('APP.ADDEQUIPMENT.INDEX.PH_E31A2B', {
              defaultValue: 'Modifier le type du fluide frigorigène (facultatif)',
            })}
            <Tooltip
              title={
                <div>
                  <span>
                    {t('APP.ADDEQUIPMENT.INDEX.PH_B0DF5C', {
                      defaultValue:
                        "Pour le fluide frigorigène, l’impact est calculé en considérant des taux de fuite par défaut aux différentes étapes du cycle de vie de l'équipement :",
                    })}
                  </span>
                  <ul>
                    <li>
                      {t('APP.ADDEQUIPMENT.INDEX.PH_7EBD48', {
                        defaultValue:
                          '2% lors du remplissage en étape de fabrication',
                      })}
                    </li>
                    <li>
                      {t('APP.ADDEQUIPMENT.INDEX.PH_4B3857', {
                        defaultValue: "2% par an pendant l'utilisation",
                      })}
                    </li>
                    <li>
                      {t('APP.ADDEQUIPMENT.INDEX.PH_16C125', {
                        defaultValue: '10% en fin de vie de l’équipement',
                      })}
                    </li>
                  </ul>
                </div>
              }
            >
              <InfoIcon />
            </Tooltip>
          </Form.Label>
          <Form.Control
            as="select"
            custom
            required
            disabled={
              selectedSheetObj && selectedSheetObj?.type_fiche === 'PEP'
                ? true
                : false
            }
            defaultValue={
              typeFluideCarac
                ? fluids.find((fluid) =>
                    fluid.libelle.includes(typeFluideCarac.SheetCarac.value[0])
                  ).id
                : fluids.find((fluid) => fluid.libelle.includes('R410A')).id
            }
            onChange={(e) => {
              setSelectedSubEquip(e.target.value);
            }}
          >
            <option key={'empty'} value={''}>
              {t('APP.ADDEQUIPMENT.INDEX.PH_331932', {
                defaultValue: 'Choisir une valeur',
              })}
            </option>

            {!fluids
              ? null
              : fluids.map((fluid) => (
                  <option key={fluid.id} value={fluid.id}>
                    {fluid.libelle}
                  </option>
                ))}
          </Form.Control>
        </Form.Group>
        <Form.Group
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            paddingLeft: 0,
          }}
          as={Col}
        >
          <Form.Label style={{ marginBottom: '10px' }}>
            {t('APP.ADDEQUIPMENT.INDEX.PH_47C2BA', {
              defaultValue: "Saisir la charge de fluide initiale de l'appareil (kg)",
            })}
            ​
          </Form.Label>
          <Form.Control
            required
            autoComplete="off"
            autoCorrect="off"
            spellCheck="off"
            type="text"
            defaultValue={quantityFluideCarac?.SheetCarac?.value[0]}
            onChange={(e) => {
              setSubEquipQuantity(e.target.value);
            }}
          />
        </Form.Group>
      </>
    );
  };

  const handlePepFdesDed = (sheets) => {
    let brands = [];
    const length = sheets ? sheets.length : 0;

    for (let index = 0; index < length; index += 1) {
      //if brand was not found, add it to the array.
      if (brands.findIndex((brand) => brand?.id === sheets[index].BrandId) < 0) {
        brands.push(sheets[index].Brand);
      }
    }

    setBrands(brands);
  };

  const handleSubmit = async () => {
    let copyZones = zones.filter((el) => el.type !== 'buildingSite');

    if (selectedType !== '') {
      const response = await ctx.api.getEquipmentCaracListById(
        nodeSearchValue && nodeSearchValue.noocoAllotId
          ? nodeSearchValue.noocoAllotId
          : nodeSearchValue.id
      );

      setRefCaracs(response.data);

      const length = response.data.caracs?.length;
      let creationCaracsLength = 0;

      //count neededOnCreation caracs and neededonImplem
      for (let index = 0; index < length; index += 1) {
        if (
          response.data.caracs[index].neededOnCreation ||
          response.data.caracs[index].neededOnImplementation
        ) {
          creationCaracsLength += 1;
        }
      }

      if (
        creationCaracsLength === 0 &&
        response.data.sheets?.length === 0 &&
        copyZones.length === 2
      ) {
        handleCreateEquipment();

        return;
      } else if (
        creationCaracsLength === 0 &&
        response.data.sheets?.length === 0 &&
        copyZones.length > 2
      ) {
        setNoCarac(true);
        changeValueEquipments('addDisplay', [...addDisplay, '3']);

        return;
      } else if (addDisplay.includes('Connexion') && creationCaracsLength === 0) {
        if (copyZones.length === 2) {
          handleCreateEquipment();
        } else {
          setNoCarac(true);
          changeValueEquipments('addDisplay', [...addDisplay, '2', '3']);

          return;
        }
      }

      setFilteredSheets(response.data.sheets);
      setRefCaracsLenth(creationCaracsLength);
      handlePepFdesDed(response.data.sheets);

      // TODO_TRAD : fr
      if (addDisplay.includes('Energie') || addDisplay.includes('Eau')) {
        changeValueEquipments('addDisplay', ['1', addDisplay[1], '2']);
      } else if (addDisplay.includes('Rangement')) {
        if (addDisplay.includes('Connexion')) {
          changeValueEquipments('addDisplay', [...addDisplay, '2']);
        } else {
          changeValueEquipments('addDisplay', [
            '1',
            'Fiche configurée',
            'Rangement',
            '2',
          ]);
        }
      } else {
        changeValueEquipments('addDisplay', ['1', '2']);
      }
    }
  };

  /**
   * Callback used for searchbar component
   * @param value
   */
  const handleSearchValue = (value) => {
    updateFormValue('nodeSearchValue', value);
    setSearchValue(
      allGroups.type.find((el) => el.id === value.MasterDataEquipmentId)
    );
  };

  /**
   * Callback used for searchBar Component
   * @param value
   */
  const handleInputValue = (value) => {
    setInputValue(value);
  };

  const onBreadCrumbSelectCallback = async (
    lot,
    fonction,
    category,
    material,
    type,
    flag
  ) => {
    updateFormValue('selectedLot', lot);
    updateFormValue('selectedFonction', fonction);
    updateFormValue('selectedCategory', category);
    updateFormValue('selectedMaterial', material);
    updateFormValue('selectedType', type);

    const md = groups
      .filter((group) => {
        return group.referenceType?.includes(
          currentProject.templateType === TYPE_EPCM
            ? REFERENCE_TYPE_NAME.EPCM
            : REFERENCE_TYPE_NAME.RE2020
        );
      })
      .find(
        (el) =>
          el.id ===
          allGroups.flatAllotments.find((el) => el.id === Number(type))
            ?.MasterDataEquipmentId
      );

    setSearchValue({
      ...md,
      ...allGroups.flatAllotments.find((el) => el.id === Number(type)),
      unit: md?.unit,
    });
    updateFormValue(
      'nodeSearchValue',
      allGroups.flatAllotments.find((el) => el.id === Number(type))
    );

    updateCurrentFlag(flag);
  };

  const handleCaracsChange = (carac, value, loop) => {
    const updatedCaracs = [...userCaracs];
    const caracToUpdateIndex = userCaracs.findIndex(
      (elem) => elem.name === carac.name
    );

    if (caracToUpdateIndex === -1) {
      carac.value = value;
      updatedCaracs.push(carac);
    } else {
      if (!value) {
        updatedCaracs.splice(caracToUpdateIndex, 1);
      } else {
        updatedCaracs[caracToUpdateIndex].value = value;
      }
    }

    if (!loop) {
      setSelectedSheet(undefined);
      setUserCaracs(updatedCaracs);
    } else {
      return carac;
    }
  };

  /**
   * controls the list
   */
  useEffect(() => {
    if (!selectedBrand) {
      //reset selected sheet

      return;
    }
    if (
      selectedBrand &&
      ((searchValue.name?.includes('photovoltaïque') && currentProject.isRset) ||
        userCaracs.length === refCaracsLength)
    ) {
      const userCarac = userCaracs.find((c) => c.id === refCaracs.xAxisId);
      const userDiscrete = userCaracs.find((c) => c.id === refCaracs.discreteId);
      const userSecondDiscrete = userCaracs.find(
        (c) => c.id === refCaracs.secondDiscreteId
      );

      let brandSheets = filteredSheets.filter(
        (sheet) => sheet.BrandId === Number(selectedBrand)
      );
      let filterSheets = [];

      if (refCaracs?.discreteId) {
        brandSheets.forEach((sheet) => {
          const caracArrayToUse = sheet.Caracs || sheet.Carac;
          const carac = caracArrayToUse.find(
            (carac) => carac.id === refCaracs?.discreteId
          );

          if (carac) {
            if (carac.SheetCarac.value.find((el) => el === userDiscrete.value)) {
              filterSheets.push(sheet);
            }
          }
        });
      } else {
        filterSheets = brandSheets;
      }

      if (refCaracs?.secondDiscreteId && userSecondDiscrete?.value !== 'Aucun') {
        filterSheets = filterSheets.filter(
          (sheet) =>
            sheet.Caracs.find((carac) => carac.id == refCaracs.secondDiscreteId)
              ?.SheetCarac?.value[0] === userSecondDiscrete?.value
        );
      }

      let result;

      if (userCarac) {
        try {
          setSheetsToShow(closest(Number(userCarac.value), filterSheets, userCarac));
        } catch (e) {
          console.log(e);
        }

        return;
      }

      setSheetsToShow(brandSheets);
    }
  }, [selectedBrand, userCaracs]);

  useEffect(() => {
    if (addDisplay.includes('3')) {
      let elements = [];
      const isParcel = zones.find((el) => el.type === 'plot');
      const isBuildingSite = zones.find((el) => el.type === 'buildingSite');
      const nbOfBuilding = zones.filter((el) => el.type === 'building');

      if (isParcel) {
        elements = [
          {
            id: '1',
            type: 'input',
            data: {
              label: (
                <div
                  className={
                    currentDestination !== isParcel.id
                      ? 'zones-cursor'
                      : 'zones-cursor currentChoice'
                  }
                  onClick={() =>
                    searchValue.lot === 'Energie'
                      ? setOpenSnackbar(
                          t('APP.ADDEQUIPMENT.INDEX.PH_4524C2', {
                            defaultValue:
                              'Un équipement du lot Energie ne peut être ajouté dans la parcelle',
                          })
                        )
                      : searchValue.isConnectedToUrbanNetwork &&
                        !isParcel.IsConnectedToUrbanNetwork
                      ? setOpenSnackbar(
                          t('APP.ADDEQUIPMENT.INDEX.PH_4849BA', {
                            defaultValue:
                              "Impossible de sélectionner cette zone car elle n'est pas raccordée à un réseau de chaleur urbain dans votre projet",
                          })
                        )
                      : searchValue.lot === 'Terre'
                      ? setOpenSnackbar(
                          t('APP.ADDEQUIPMENT.INDEX.PH_585864', {
                            defaultValue:
                              'Un équipement du lot Terre ne peut être ajouté que dans le chantier',
                          })
                        )
                      : searchValue.lot === 'Transports et engins'
                      ? setOpenSnackbar(
                          t('APP.ADDEQUIPMENT.INDEX.PH_1A39A9', {
                            defaultValue:
                              'Un équipement du lot Transports et engins ne peut être ajouté que dans le chantier',
                          })
                        )
                      : changeValueEquipments('currentDestination', isParcel.id)
                  }
                >
                  <p>Parcelle</p>
                  <img src={plot} alt />
                </div>
              ),
            },
            position: { x: 250, y: 25 },
          },
        ];
        if (
          isBuildingSite &&
          isOldBuildingSiteInProject &&
          !addDisplay.includes('modeling')
        ) {
          const currentBuildingSite = zones.find(
            (el) => el.ParentId === isParcel.id && el.type === 'buildingSite'
          );

          elements = [
            ...elements,
            {
              id: '2',
              type: 'output',
              data: {
                label: (
                  <div
                    className={
                      currentDestination !== currentBuildingSite?.id
                        ? 'zones-cursor'
                        : 'zones-cursor currentChoice'
                    }
                    onClick={() => {
                      searchValue.isConnectedToUrbanNetwork &&
                      !isParcel.IsConnectedToUrbanNetwork
                        ? setOpenSnackbar(
                            t('APP.ADDEQUIPMENT.INDEX.PH_872B09', {
                              defaultValue:
                                "Impossible de sélectionner cette zone car elle n'est pas raccordée à un réseau de chaleur urbain dans votre projet",
                            })
                          )
                        : searchValue.name?.includes('photovoltaïque')
                        ? setOpenSnackbar(
                            t('APP.ADDEQUIPMENT.INDEX.PH_FD1849', {
                              defaultValue:
                                'Un panneau photovoltaïque ne peut pas être ajouté dans le chantier',
                            })
                          )
                        : changeValueEquipments(
                            'currentDestination',
                            currentBuildingSite.id
                          );
                    }}
                  >
                    <p>
                      {t('APP.ADDEQUIPMENT.INDEX.PH_09B109', {
                        defaultValue: 'Chantier',
                      })}
                    </p>
                    <img src={buildingSite} style={{ width: '35px' }} alt />
                  </div>
                ),
              },
              position: { x: 250, y: 200 },
            },
            { id: 'e1-2', source: '1', target: '2' },
          ];
        }
      }

      let x = 250;

      let length = 0;

      nbOfBuilding.map((el) => {
        x += length >= 2 ? 190 * length : 300;

        const childrens = zones.filter((zone) => zone.ParentId === el.id);

        length = childrens.length;

        elements = [
          ...elements,
          {
            id: `${el.id}`,
            type: 'input',
            data: {
              label: (
                <div style={{ cursor: 'not-allowed', padding: '10px' }}>
                  <p>{el.name}</p>
                  <img src={buildingPic} alt />
                </div>
              ),
            },
            position: { x: x, y: 25 },
          },
        ];

        let y = x;

        childrens.map((child, index) => {
          if (index === 0) {
            y -= 100;
          } else if (index === 1) {
            y += 200;
          } else {
            y += 200;
          }

          elements = [
            ...elements,
            {
              id: `${child.id}`,
              type: isBuildingSite ? 'default' : 'output',
              data: {
                label: (
                  <div
                    className={
                      currentDestination !== child.id
                        ? 'zones-cursor'
                        : 'zones-cursor currentChoice'
                    }
                    onClick={() => {
                      searchValue.isConnectedToUrbanNetwork &&
                      !child.IsConnectedToUrbanNetwork
                        ? setOpenSnackbar(
                            t('APP.ADDEQUIPMENT.INDEX.PH_6DA028', {
                              defaultValue:
                                "Impossible de sélectionner cette zone car elle n'est pas raccordée à un réseau de chaleur urbain dans votre projet",
                            })
                          )
                        : searchValue.lot !== 'Terre' &&
                          searchValue.lot !== 'Transports et engins'
                        ? changeValueEquipments('currentDestination', child.id)
                        : searchValue.lot === 'Transports et engins'
                        ? setOpenSnackbar(
                            t('APP.ADDEQUIPMENT.INDEX.PH_0C8975', {
                              defaultValue:
                                'Un équipement du lot Transports et engins ne peut être ajouté que dans le chantier',
                            })
                          )
                        : setOpenSnackbar(
                            t('APP.ADDEQUIPMENT.INDEX.PH_E23281', {
                              defaultValue:
                                'Un équipement du lot Terre ne peut être ajouté que dans le chantier',
                            })
                          );
                    }}
                  >
                    <p>{child.name}</p>
                    <img
                      src={area}
                      style={{ width: '35px', marginRight: '5px' }}
                      alt
                    />
                  </div>
                ),
              },
              position: {
                x: index == 0 && childrens.length === 1 ? x : y,
                y: 200,
              },
            },
            {
              id: `e${el.id}-${child.id}`,
              source: `${el.id}`,
              target: `${child.id}`,
            },
          ];

          if (
            isBuildingSite &&
            isOldBuildingSiteInProject &&
            !addDisplay.includes('modeling')
          ) {
            const currentBuildingSite = zones.find((el) => el.ParentId === child.id);

            elements = [
              ...elements,
              {
                id: `${currentBuildingSite?.id}`,
                type: 'output',
                data: {
                  label: (
                    <div
                      className={
                        currentDestination !== currentBuildingSite?.id
                          ? 'zones-cursor'
                          : 'zones-cursor currentChoice'
                      }
                      onClick={() =>
                        searchValue.isConnectedToUrbanNetwork &&
                        !currentBuildingSite?.IsConnectedToUrbanNetwork
                          ? setOpenSnackbar(
                              t('APP.ADDEQUIPMENT.INDEX.PH_029167', {
                                defaultValue:
                                  "Impossible de sélectionner cette zone car elle n'est pas raccordée à un réseau de chaleur urbain dans votre projet",
                              })
                            )
                          : searchValue.name?.includes('photovoltaïque')
                          ? setOpenSnackbar(
                              t('APP.ADDEQUIPMENT.INDEX.PH_D0BAD6', {
                                defaultValue:
                                  'Un panneau photovoltaïque ne peut pas être ajouté dans le chantier',
                              })
                            )
                          : changeValueEquipments(
                              'currentDestination',
                              currentBuildingSite?.id
                            )
                      }
                    >
                      <p>{currentBuildingSite?.name}</p>
                      <img src={buildingSite} style={{ width: '35px' }} alt />
                    </div>
                  ),
                },
                position: {
                  x: index == 0 && childrens.length === 1 ? x : y,
                  y: 350,
                },
              },
              {
                id: `e${child.id}-${currentBuildingSite?.id}`,
                source: `${child.id}`,
                target: `${currentBuildingSite?.id}`,
              },
            ];
          }
        });
      });

      changeValueEquipments('elementsTree', elements);
    }
  }, [addDisplay, currentDestination]);

  const { mutate: createEquipmentCustom } = useCreateEquipmentCustom();
  const onSuccessCreateEquipmentCustom = () => {
    resetAddEquipment('Modeling');
  };
  const onErrorCreateEquipmentCustom = () => {
    resetAddEquipment('Modeling');
  };
  const handleCreateEquipment = async (createAgain) => {
    let response = null;
    let promises = [];

    if (
      selectedSheetObj &&
      ['bundle', 'custom_user'].includes(selectedSheetObj?.type_fiche)
    ) {
      let data = {
        quantity: selectedQuantity,
        SheetId: selectedSheetObj.id,
        type_fiche: selectedSheetObj.type_fiche,
        comment: commentEquipment,
        ZoneId: currentDestination || zones.find((el) => el.type === 'zone').id,
        customName,
        type: selectedSheetObj.type_fiche === 'bundle' ? 'bundle' : 'SHEET_FROM_LIB',
      };
      ctx.api
        .createEquipment(data, currentProject.id)
        .then((res) => {
          resetAddEquipment('generic');
          return;
        })
        .catch(() => {
          return;
        });

      return;
    }

    if (addDisplay.includes('Forfait')) {
      datasForfait.map((ele) => {
        if (fluidsData && fluidsData.length > 0) {
          let fluidsPerZones = fluidsData.filter(
            (fluid) => fluid.ZoneId === ele.ZoneId
          );

          if (fluidsPerZones.length > 0) {
            let body = {
              AllotmentNodes: [
                allGroups.flatAllotments.find(
                  (el) => el.MasterDataEquipmentId === ele.id
                ),
              ],
              caracs: ele.caracs,
              ZoneId: ele.ZoneId,
              quantity: zones.find((el) => el.id === ele.ZoneId).area,
              fluid: fluidsPerZones,
              type: 'FORFAIT',
            };

            let promise = ctx.api.createEquipment(body, currentProject.id);

            promises = [...promises, promise];
          }
        } else {
          if (ele.check) {
            let body = {
              AllotmentNodes: [
                allGroups.flatAllotments.find(
                  (el) => el.MasterDataEquipmentId === ele.id
                ),
              ],
              caracs: ele.caracs,
              ZoneId: ele.ZoneId,
              quantity: zones.find((el) => el.id === ele.ZoneId).area,
              type: 'FORFAIT',
            };

            if (ele.hasFluid) {
              body = {
                ...body,
                fluid: {
                  selectedFluid: selectedSubEquip,
                  fluidQuantity: subEquipQuantity,
                },
              };
            }

            let promise = ctx.api.createEquipment(body, currentProject.id);

            promises = [...promises, promise];
          }
        }
      });

      Promise.all(promises).then((values) => {
        resetAddEquipment('Forfait');

        return;
      });

      return;
    }

    if (
      addDisplay.includes('Energie') &&
      typeProjectForSimplifiedEnergy.includes(currentProject.templateType)
    ) {
      let promise = ctx.api.createEquipment(
        simplifiedEnergyLines.map((energy) => ({
          ...energy,
          ZoneId: currentDestination,
          caracs: energy.caracs.caracs,
          id: null,
          AllotmentNodes: [energy.AllotmentNode],
          type: 'DEC',
        })),
        currentProject.id
      );

      promise.then((values) => {
        resetAddEquipment('Energie');

        return;
      });

      return;
    }

    const parseCarbonWeight = (carbonWeight) => {
      if (!carbonWeight) return 0;
      return carbonWeight
        ? parseFloat(carbonWeight.replace(',', '.'))
        : carbonWeight;
    };

    if (addDisplay.includes('modeling')) {
      let body = {
        ZoneId: currentDestinationForModeling || currentDestination, //number
        quantity: parseFloat(
          caracteristicHookForm.getValues().quantity.replace(',', '.')
        ), //number
        comment: caracteristicHookForm.getValues().comment, // string | null
        caracteristic: {
          equipmentName: caracteristicHookForm.getValues().equipmentName, //string
          labelQuote: caracteristicHookForm.getValues().labelQuote, //string
          lifeSpan: parseInt(caracteristicHookForm.getValues().lifeSpan), //number
          unit: caracteristicHookForm.getValues().unit, //string
        }, //caracteristicFormDTO
        isCarbonWeightSelected, //boolean
        customACV: {
          AProd: carbonWeightFormInstance.getValues().product
            ? parseFloat(
                carbonWeightFormInstance.getValues().product.replace(',', '.')
              )
            : carbonWeightFormInstance.getValues().product, //number
          AEdif: carbonWeightFormInstance.getValues().installation
            ? parseFloat(
                carbonWeightFormInstance.getValues().installation.replace(',', '.')
              )
            : carbonWeightFormInstance.getValues().installation, //number
          B1: parseCarbonWeight(carbonWeightFormInstance.getValues().B1),
          B2: parseCarbonWeight(carbonWeightFormInstance.getValues().B2),
          B3: parseCarbonWeight(carbonWeightFormInstance.getValues().B3),
          B4: parseCarbonWeight(carbonWeightFormInstance.getValues().B4),
          B5: parseCarbonWeight(carbonWeightFormInstance.getValues().B5),
          B6: parseCarbonWeight(carbonWeightFormInstance.getValues().B6),
          B7: parseCarbonWeight(carbonWeightFormInstance.getValues().B7),
          C: carbonWeightFormInstance.getValues().endOfLife
            ? parseFloat(
                carbonWeightFormInstance.getValues().endOfLife.replace(',', '.')
              )
            : carbonWeightFormInstance.getValues().endOfLife, //number
          D: carbonWeightFormInstance.getValues().profit
            ? parseFloat(
                carbonWeightFormInstance.getValues().profit.replace(',', '.')
              )
            : carbonWeightFormInstance.getValues().profit, //number
        }, // | null
        materialComposition: {
          manufacturedRate: modeling.manufacturedRate, //number
          totalWeight: modeling.totalWeight
            ? parseFloat(modeling.totalWeight.replace(',', '.'))
            : modeling.totalWeight, //number
          materialLines: modeling.materialLines, // {allotmentNode: AllotmentNodeDTO,      percent: number }[]
        }, // null
        type: 'custom_user',
      };
      createEquipmentCustom(
        {
          body: body,
          projectId: currentProject.id,
        },
        {
          onSuccess: onSuccessCreateEquipmentCustom,
          onError: onErrorCreateEquipmentCustom,
        }
      );
      return;
    }

    let changeAttachmentLot = composantVideForm.re2020lot
      ? composantVideForm.re2020lot
      : re2020lot
      ? re2020lot
      : searchValue.AllotmentNodes?.find((el) => el.AllotmentId === 2)
      ? searchValue.AllotmentNodes?.find((el) => el.AllotmentId === 2)
      : null;
    const sheetType = getSheetType(
      nodeSearchValue,
      allGroups.flatAllotments,
      reEmploy
    );

    let AllotmentNodes = changeAttachmentLot
      ? [nodeSearchValue, changeAttachmentLot]
      : [nodeSearchValue];
    let body = {
      id: nodeSearchValue
        ? nodeSearchValue.noocoAllotId || nodeSearchValue.id
        : null,
      comment: commentEquipment,
      customName,
      caracs: [],
      AllotmentNodes,
      ZoneId: searchValue
        ? searchValue.lot.includes('Transports')
          ? zones.find((el) => el.type === 'buildingSite')?.id
          : currentDestination
        : currentDestination,
      quantity: selectedQuantity ? selectedQuantity.replace(/,/g, '.') : null,
      type_fiche: sheetType,
      UrbanNetworkId: urbanChoice || null,
    };

    if (addDisplay.includes('Composant vide')) {
      body = {
        ...body,
        type_fiche: !reEmploy ? 'EMPTY' : 'EMPTY_REFURBISHED',
        quantity: composantVideForm.quantity,
        name: composantVideForm.nameDed,
        ddv: composantVideForm.ddv,
        unit: composantVideForm.unitDed,
        AllotmentNodes: composantVideForm.re2020lot
          ? [composantVideForm.re2020lot]
          : [],
        id_demandeur: idAsker,
        type: !reEmploy ? 'EMPTY' : 'EMPTY_REFURBISHED',
      };
    }

    const currentZone = currentDestination
      ? zones.find((el) => el.id === currentDestination)
      : zones[0];
    const [forfaitBlocksEquipmentCreation, errorMessage] =
      checkIfForfaitBlocksEquipmentCreation(
        currentZone.ProjectSheets,
        currentProject,
        currentZone,
        zones,
        nodeSearchValue,
        allGroups.flatAllotments,
        re2020lot
      );

    if (forfaitBlocksEquipmentCreation) {
      setSnackbarMessage(errorMessage);
      changeValueEquipments('displaySpinner', false);

      return;
    }

    if (selectedIniesSheet) {
      let defaultFluid = checkIfDedHasFluid()?.Caracs?.find(
        (carac) => carac.name.toLowerCase().includes('type fluide') || null
      );
      let fluide =
        fluids?.find((fluid) =>
          fluid?.libelle?.includes(defaultFluid?.SheetCarac?.value[0])
        ) || null;

      if (displayOverwritteFluidsForm) {
        fluide =
          currentProject.templateType !== TYPE_RE2020 &&
          currentProject.templateType !== TYPE_EPCM &&
          selectedIniesSheet.type_fiche === 'DED'
            ? {
                selectedFluid:
                  selectedSubEquip ||
                  fluids.find((fluid) => fluid.libelle.includes('R410A')).id,
                fluidQuantity: subEquipQuantity || 0,
              }
            : {};
      }

      response = await ctx.api.postPepFdesDed({
        quantity: selectedQuantity.replace(/,/g, '.'),
        SheetId: selectedIniesSheet.id,
        AllotmentNodes,
        ProjectId: currentProject.id,
        fluid: fluide,
        ZoneId: body.ZoneId,
        caracs: body.caracs,
        UrbanNetworkId: body.UrbanNetworkId,
        re2020lot: body.re2020lot,
        lineImportId: null,
        comment: body.comment,
        customName: body.customName,
      });

      setUserCaracs([]);
      setSelectedQuantity(null);

      // TODO_TRAD : fr
      if (!createAgain) {
        resetAddEquipment('selectedIniesSheet');
      } else {
        changeValueEquipments('composantVideForm', {});
        changeValueEquipments('re2020lot', null);
        changeValueEquipments('addDisplay', ['1', 'Choix fiche']);
      }

      return;
    }

    userCaracs.forEach((carac) => {
      const newCarac = {
        id: carac.id,
        name: carac.name,
        value: [carac.value],
      };

      body.caracs.push(newCarac);
    });

    // Panneaux
    if (searchValue.name?.includes('photovoltaïque') && currentProject.isRset) {
      refCaracs.caracs.forEach((carac) => {
        if (!carac.neededOnImplementation) return;

        const newCarac = {
          id: carac.id,
          name: carac.name,
          value: carac.value,
        };

        if (carac.name.startsWith('Prod'))
          newCarac.value = currentZone.OEefProdPvAnnuel.toString();
        if (carac.name.startsWith('Auto'))
          newCarac.value = currentZone.OEefProdPvAcAnnuel.toString();

        body.caracs.push(newCarac);
      });
    }

    if (searchValue.hasFluid && !xmlFile) {
      if (
        selectedSheetObj &&
        (selectedSheetObj.type_fiche === 'PEP' ||
          selectedSheetObj.type_fiche === 'DED' ||
          selectedSheetObj.type_fiche === 'FDES')
      ) {
        let defaultFluide = checkIfDedHasFluid()?.Caracs.find((carac) =>
          carac.name.toLowerCase().includes('type fluide')
        );

        let fluide = fluids.find((fluid) =>
          fluid.libelle.includes(defaultFluide.SheetCarac.value[0])
        );

        response = await ctx.api.postPepFdesDed({
          quantity: selectedQuantity.replace(/,/g, '.'),
          SheetId: selectedSheetObj.id,
          AllotmentNodes,
          ZoneId: currentProject.id,
          fluid:
            selectedSheetObj.type_fiche !== 'DED'
              ? {
                  selectedFluid: fluids.find((el) =>
                    el.libelle.includes(
                      selectedSheetObj?.Caracs.find((carac) =>
                        carac.name.toLocaleLowerCase().includes('type fluide')
                      )?.SheetCarac.value[0]
                    )
                  )?.id,
                  fluidQuantity: 0,
                }
              : {
                  selectedFluid: selectedSubEquip || fluide.id,
                  fluidQuantity: subEquipQuantity,
                },
          ZoneId: body.ZoneId,
          caracs: body.caracs,
          UrbanNetworkId: null,
          re2020lot: body.re2020lot,
          lineImportId: null,
          comment: body.comment,
          customName: body.customName,
        });
      } else {
        body.fluid =
          currentProject.templateType === TYPE_RE2020 ||
          currentProject.templateType === TYPE_EPCM
            ? {}
            : selectedSubEquip && subEquipQuantity
            ? {
                selectedFluid: selectedSubEquip,
                fluidQuantity: subEquipQuantity,
              }
            : checkIfDedHasFluid() && subEquipQuantity
            ? {
                selectedFluid: fluids.find((el) =>
                  el.libelle.includes(
                    checkIfDedHasFluid()?.Caracs.find((carac) =>
                      carac.name.toLocaleLowerCase().includes('type fluide')
                    )?.SheetCarac.value[0]
                  )
                )?.id,
                fluidQuantity: subEquipQuantity,
              }
            : null;
        response = await ctx.api.createEquipment(body, currentProject.id);
      }
    } else if (selectedSheetObj) {
      response = await ctx.api.postPepFdesDed({
        quantity: selectedQuantity.replace(/,/g, '.'),
        SheetId: selectedSheetObj.id,
        AllotmentNodes,
        ProjectId: currentProject.id,
        fluid: null,
        ZoneId: body.ZoneId,
        caracs: body.caracs,
        UrbanNetworkId: null,
        re2020lot: body.re2020lot,
        lineImportId: lineImport ? lineImport.id : null,
        comment: body.comment,
        customName: body.customName,
      });
    } else if (xmlFile) {
      body.addXmlFavorite = addXmlFavorite;

      if (addXmlFavorite && customXmlName) {
        body.customXmlName = customXmlName;
      }

      body.type_fiche = 'FC';

      response = await ctx.api.createEquipmentFromXml(
        body,
        xmlFile,
        currentProject.id
      );
    } else {
      response = await ctx.api.createEquipment(body, currentProject.id);
    }

    if (!createAgain) {
      resetAddEquipment('generic');
      // callback();
    } else {
      if (addDisplay.includes('Eau') || addDisplay.includes('Energie')) {
        changeValueEquipments('addDisplay', [addDisplay[0], addDisplay[1]]);
      } else {
        changeValueEquipments('addDisplay', ['1']);
      }
    }
  };

  // Management of the allotmentNodes Button

  const [isManualInput, setIsManualInput] = useState(true);

  const [inputChange, setInputChange] = useState('');

  const debouncedInputChange = useDebounce(inputChange, 700);

  useEffect(() => {
    if (isManualInput && debouncedInputChange.length >= 1) {
      fetchIniesEquipments(debouncedInputChange);
    }
  }, [debouncedInputChange, isManualInput]);

  useEffect(() => {
    if (searchValue) {
      const result = handleAllotmentNodes(
        searchValue,
        currentAllotment,
        allGroups.flatAllotments,
        currentProject
      );

      let isNodeInParentNodes = false;

      if (!result || result.length == 0) {
        return;
      }

      if (currentAllotment?.id === 1) {
        if (result.length > 1 && nodeSearchValue) {
          result.map((el) => {
            if (el.details.find((el) => el.id === nodeSearchValue.id)) {
              isNodeInParentNodes = true;
            }
          });

          if (!isNodeInParentNodes) {
            updateFormValue(
              'nodeSearchValue',
              result[0].details.find((el) => el.MasterDataEquipmentId)
            );
          }
        } else {
          if (result[0]) {
            updateFormValue(
              'nodeSearchValue',
              result[0].details.find((el) => el.MasterDataEquipmentId)
            );
          }
        }
      }

      setDatas(result.filter((el) => !el.isObsolete));
    } else if (datas && !searchValue) {
      setDatas([]);
    }
  }, [searchValue]);

  useEffect(() => {
    return () => {
      updateFormValue('allotSteps', []);
      updateFormValue('searchValue', null);
      updateFormValue('selectedLot', null);
      updateFormValue('selectedFonction', null);
      updateFormValue('selectedCategory', null);
      updateFormValue('selectedMaterial', null);
      updateFormValue('selectedType', null);
    };
  }, []);

  // handle breadcrumb on energy and water part

  useEffect(() => {
    if (
      (addDisplay.includes('Energie') || addDisplay.includes('Eau')) &&
      addDisplay.length === 2
    ) {
      updateFormValue('selectedLot', addDisplay[1]);

      let nodes = flatAllotments
        .filter((el) => !el.isObsolete)
        .map((node) => {
          if (node.MasterDataEquipmentId) {
            const md = findMdByAllotmentNodeId(
              node.id,
              flatAllotments,
              allGroups.type
            );

            if (
              md.referenceType.includes(
                currentProject.templateType === TYPE_EPCM
                  ? REFERENCE_TYPE_NAME.EPCM
                  : REFERENCE_TYPE_NAME.RE2020
              )
            ) {
              return node;
            }
          } else {
            return node;
          }
        })
        .filter((el) => el);

      let result = findBreadcrumbChoices(nodes, allGroups, addDisplay[1], zones);
      let names = [];

      for (const property in result) {
        result[property] = result[property]
          .map((node) => {
            if (names.includes(node.name)) {
              return;
            }
            const same = result[property].filter((el) => el.name === node.name);

            names = [...names, node.name];

            if (same.length > 1) {
              return same.reduce((acc, curr) => {
                const found = acc.name === curr.name ? acc : null;

                if (found) {
                  found.full = [...found.full, ...curr.full];

                  return found;
                }
                return curr;
              });
            }
            return node;
          })
          .filter((el) => el);
      }

      changeValueEquipments('breadcrumbChoice', result);
    }

    // Manage forfait display

    if (
      addDisplay.includes(
        t('APP.ADDEQUIPMENT.INDEX.PH_CB65A4', { defaultValue: 'Forfait' })
      ) &&
      addDisplay.length === 2
    ) {
      const copy = _.cloneDeep(groups);

      const forfait = copy?.filter((el) =>
        el.materiel.includes('DONNEE FORFAITAIRE')
      );

      let datas = [];

      forfait.forEach((el) => {
        const current = datas.find((ele) => ele.lot === el.lot);

        el.nbRef =
          projectTypeBBCAorEPCM.includes(currentProject.templateType) &&
          Number(el.name.slice(22, 23)) < 10
            ? Number(el.name.slice(22, 24))
            : projectTypeBBCAorEPCM.includes(currentProject.templateType) &&
              Number(el.name.slice(22, 23)) >= 10
            ? Number(el.name.slice(22, 24))
            : el.name.includes('Sous-lot')
            ? Number(el.name.slice(32, 36))
            : Number(el.name.slice(22, 25));

        if (!current) {
          datas = [
            ...datas,
            {
              lot: el.lot,
              forfait: [el],
              nbRef: el.nbRef,
            },
          ];
        } else {
          datas = datas.filter((ele) => ele.lot !== el.lot);

          current.forfait = [...current.forfait, el];

          datas = [...datas, current];
        }
      });

      changeValueEquipments('forfaitList', datas);
    }
  }, [addDisplay]);

  const prodConsoValidation = (carac, initialValue) => {
    if (carac.name === 'Autoconsommation annuelle : Eef_Prod_AC (kWh/m2/an)') {
      const productionPerYear =
        userCaracs?.find(
          (el) => el.name === 'Production annuelle : Eef_Prod (kWh/m2/an)'
        )?.value || 1;
      if (productionPerYear) {
        if (parseFloat(initialValue) > parseFloat(productionPerYear)) {
          return productionPerYear;
        }
      }
    }

    if (carac.name === 'Production annuelle : Eef_Prod (kWh/m2/an)') {
      const consoPerYear = userCaracs?.find(
        (el) => el.name === 'Autoconsommation annuelle : Eef_Prod_AC (kWh/m2/an)'
      );
      if (consoPerYear) {
        if (parseFloat(consoPerYear.value) > parseFloat(initialValue)) {
          handleCaracsChange(consoPerYear, initialValue);
        }
      }
    }

    return initialValue;
  };

  const handleChoiceForfait = async (check, forfait, zone) => {
    let already = datasForfait.find(
      (el) => el.id === forfait.id && el.ZoneId === zone.id
    );

    if (!already) {
      const newForfait = {
        id: forfait.id,
        hasFluid: forfait.hasFluid,
        ZoneId: zone.id,
        check,
        caracs: [
          {
            id: forfait.discreteId,
            name: t('APP.ADDEQUIPMENT.INDEX.PH_BA2EE1', {
              defaultValue: 'Typologie de bâtiment',
            }),
            value: [zone.BuildingType.name],
          },
        ],
      };

      changeValueEquipments('datasForfait', [...datasForfait, newForfait]);
    } else {
      let list = datasForfait.filter((el) => el.id !== already.id);

      already = { ...already, check };

      changeValueEquipments('datasForfait', [...list, already]);
    }
  };

  // reset spinner when screen display change
  useEffect(() => {
    changeValueEquipments('displaySpinner', false);
  }, [addDisplay]);

  const onChange = (value) => {
    if (value) {
      updateFormValue('nodeSearchValue', value);
      setSearchValue(
        allGroups.type.find((el) => el.id === value.MasterDataEquipmentId)
      );
      setInputValue(value.name);
    }
  };

  let locale = {
    emptyText: t('APP.ADDEQUIPMENT.INDEX.PH_8D273C', {
      defaultValue: 'Pas de donnée',
    }),
  };

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'Nom',
      sorter: (a, b) => {
        if (a.Nom < b.Nom) {
          return -1;
        }

        if (a.Nom > b.Nom) {
          return 1;
        }

        return 0;
      },
      key: 'Nom',
    },
    {
      title: t('APP.ADDEQUIPMENT.INDEX.PH_90663F', {
        defaultValue: 'Configurateur',
      }),
      dataIndex: 'ConfigurateurName',
      sorter: (a, b) => {
        if (a.ConfigurateurName < b.ConfigurateurName) {
          return -1;
        }

        if (a.ConfigurateurName > b.ConfigurateurName) {
          return 1;
        }

        return 0;
      },
      key: 'ConfigurateurName',
    },
    {
      title: t('APP.ADDEQUIPMENT.INDEX.PH_B2E448', { defaultValue: 'Date' }),
      dataIndex: 'Date',
      sorter: (a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }

        if (a.Date > b.Date) {
          return 1;
        }

        return 0;
      },
      key: 'Date',
    },
    {
      title: t('APP.ADDEQUIPMENT.INDEX.PH_EE3BF1', { defaultValue: 'Version' }),
      dataIndex: 'Version',
      key: 'Version',
    },
    {
      title: t('APP.ADDEQUIPMENT.INDEX.PH_150510', {
        defaultValue: 'Nom complet',
      }),
      dataIndex: 'File',
      key: 'File',
    },
  ];

  useEffect(() => {
    let widthTable = '';

    if (isMenuOpen) {
      widthTable = `${window.innerWidth - 350}px`;
    } else {
      widthTable = `${window.innerWidth - 207}px`;
    }

    window.addEventListener('resize', function () {
      if (isMenuOpen) {
        widthTable = `${window.innerWidth - 350}px`;
      } else {
        widthTable = `${window.innerWidth - 207}px`;
      }

      setWidthTable(widthTable);
    });

    setWidthTable(widthTable);
  }, [isMenuOpen]);

  useEffect(() => {
    let caracs = [];

    // TODO_TRAD
    if (addDisplay.length === 2 && refCaracs?.caracs) {
      refCaracs.caracs.forEach((carac) => {
        if (carac.defaultValue) {
          handleCaracsChange(carac, carac.defaultValue);
        } else if (
          carac.name === 'Production annuelle : Eef_Prod (kWh/m2/an)' ||
          carac.name === 'Autoconsommation annuelle : Eef_Prod_AC (kWh/m2/an)'
        ) {
          caracs = [...caracs, handleCaracsChange(carac, '1', true)];
        }
      });

      if (caracs.length > 0 && addDisplay[addDisplay.length - 1] === '2') {
        setUserCaracs(caracs);
      }
    }
  }, [addDisplay]);

  useEffect(() => {
    if (addDisplay.includes('Rangement') && addDisplay.length < 6 && edecChoice) {
      if (edecChoice.xml && !xmlFile) {
        setXmlFile(edecChoice.xml);
      } else {
        downloadEdecFile();
      }
    }
  }, [addDisplay, edecChoice]);

  useEffect(() => {
    if (edecFiles) {
      const scrollContainer = document.querySelector('.ant-table-body');

      if (scrollContainer) {
        if (window.screen.availHeight > 950) {
          scrollContainer.style.height = '545px';
        } else if (
          window.screen.availHeight > 810 &&
          window.screen.availHeight < 950
        ) {
          scrollContainer.style.height = '400px';
        } else {
          scrollContainer.style.height = '350px';
        }
      }
    }
  }, [filterEdec]);

  useEffect(() => {
    if (filterEdec?.length > 0) {
      let filtered = [];

      filterEdec.map((el) => {
        const datas = edecFiles.filter((file) => file.ConfigurateurName === el);

        filtered = [...filtered, ...datas];
      });

      changeValueEquipments('filterData', filtered);
    } else {
      changeValueEquipments('filterData', null);
    }
  }, [filterEdec]);

  const [vt] = useVT(
    () => ({
      scroll: {
        y:
          window.screen.availHeight < 950
            ? window.screen.availHeight - 650
            : window.screen.availHeight - 570,
      },
    }),
    []
  );

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    changeValueEquipments('edecChoice', selectedRows[1] || selectedRows[0]);

    changeValueEquipments(
      'selectedRowKeys',
      selectedRowKeys.length > 1 ? [selectedRowKeys[1]] : selectedRowKeys
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: 50,
    checkStrictly: false,
  };

  const modelingStepTitle = () => {
    if (addDisplay.includes('5') && isCarbonWeightSelected === false) {
      return t('APP.ADDEQUIPMENT.INDEX.PH_DA3B29', {
        defaultValue: 'Estimer le poids carbone du produit',
      });
    }

    if (addDisplay.includes('4') && isCarbonWeightSelected === false) {
      return t('APP.ADDEQUIPMENT.INDEX.PH_D0515D', {
        defaultValue: 'Estimer le poids carbone du produit',
      });
    }

    if (addDisplay.includes('4') && isCarbonWeightSelected === true) {
      return t('APP.ADDEQUIPMENT.INDEX.PH_B97B0F', {
        defaultValue: 'Saisir le poids carbone du produit',
      });
    }

    if (addDisplay.includes('3')) {
      return t('APP.ADDEQUIPMENT.INDEX.PH_B57DDE', {
        defaultValue: 'Choisissez votre type de modélisation',
      });
    }

    if (addDisplay.includes('2')) {
      return t('APP.ADDEQUIPMENT.INDEX.PH_975EBE', {
        defaultValue: 'Caractéristiques',
      });
    }
  };

  const modelingStepTitleTooltip = () => {
    if (addDisplay.includes('5') && isCarbonWeightSelected === false) {
      return t('APP.ADDEQUIPMENT.INDEX.PH_11AFB6', {
        defaultValue:
          'Le poids carbone du produit va être estimé à partir de sa composition matériaux ainsi qu’un indice de manufacture. Les phases d’installation, d’utilisation et de fin de vie ne sont pas calculées.',
      });
    }

    if (addDisplay.includes('4') && isCarbonWeightSelected === false) {
      return t('APP.ADDEQUIPMENT.INDEX.PH_3ACCE4', {
        defaultValue:
          'Le poids carbone du produit va être estimé à partir de sa composition matériaux ainsi qu’un indice de manufacture. Les phases d’installation, d’utilisation et de fin de vie ne sont pas calculées.',
      });
    }

    if (addDisplay.includes('4') && isCarbonWeightSelected === true) {
      return t('APP.ADDEQUIPMENT.INDEX.PH_DC13D1', {
        defaultValue:
          "Saisir le poids carbone par phase de l’ACV de l'équipement pour une unité de référence qui a été déterminée précédemment.",
      });
    }

    return false;
  };

  const isConfirmButtonModelingVisible = () => {
    if (addDisplay.includes('4') && currentDestinationForModeling !== null) {
      return true;
    }

    if (addDisplay.includes('5') && addDisplay.includes('modeling')) {
      return true;
    }
    return false;
  };

  const isConfirmModelingButtonCompleted = () => {
    const { totalWeight, manufacturedRate, materialLines } = modeling;

    if (zones.length > 2 && addDisplay.includes('5')) {
      return currentDestination !== null;
    }

    if (isStepFourModelingCompleted()) {
      return true;
    }

    return false;
  };

  const isStepTwoCaracModelingCompleted = () => {
    const {
      formState: { isValid },
    } = caracteristicHookForm;

    return isValid;
  };

  const isStepThreeChoiceModelingCompleted = () => {
    const { isCarbonWeightSelected } = modeling;

    return isCarbonWeightSelected !== null;
  };

  const isStepFourModelingCompleted = () => {
    const { totalWeight, materialLines } = modeling;

    if (isCarbonWeightSelected === true) {
      const {
        formState: { isValid },
      } = carbonWeightFormInstance;

      return isValid;
    }

    if (isCarbonWeightSelected === false) {
      let totalMaterialCompleted = 0;
      materialLines.forEach((line) => (totalMaterialCompleted += line.percent));
      const totalWeightToString =
        totalWeight && totalWeight !== '' && decimalNumberRegex.test(totalWeight)
          ? parseFloat(totalWeight.replace(',', '.'))
          : 0;
      const isMaterialCompleted = totalMaterialCompleted === 100;
      const istotalWeightCompleted = totalWeight && totalWeightToString > 0;
      const isModelingCompleted = isMaterialCompleted && istotalWeightCompleted;

      return isModelingCompleted;
    }
  };

  const handleOnChangeAddEquipmentSearchBar = (value) => {
    if (typeof value === 'number' && !isConfiguredSheetSelected) {
      let node = nodesAvailableForProjectType.find((node) => {
        return node.id === value;
      });
      updateFormValue('nodeSearchValue', node);
      updateFormValue(
        'searchValue',
        allGroups.type.find(
          (masterdata) => masterdata.id === node.MasterDataEquipmentId
        )
      );
    } else if (
      value === SEARCH_GROUP_OPTION_VALUES.EQUIPEMENTS ||
      value === SEARCH_GROUP_OPTION_VALUES.INIES ||
      value === SEARCH_GROUP_OPTION_VALUES.BIBLIOTHEQUE
    ) {
      updateFormValue('searchValue', null);
      updateFormValue('nodeSearchValue', null);
      updateFormValue('selectedIniesSheet', null);
      setSelectedSheetObj(null);
    } else {
      const typeFichesWithAllotmentNodes = ['bundle', 'custom_user'];
      const isTypeFicheIncludesInTypeFichesWithAllotmentNodes =
        typeFichesWithAllotmentNodes.includes(value.type_fiche);

      if (isConfiguredSheetSelected) {
        const configuredSheet = configuredSheetOptions.find(
          (configuredSheet) => configuredSheet.id === value
        );
        value = configuredSheet;
        value.key = value.id;
        value.value = value.id;
        value.title = value.name;
      }

      if (
        isTypeFicheIncludesInTypeFichesWithAllotmentNodes &&
        !value.AllotmentNodes
      ) {
        value = findAllotmentNodesForNewBundle(value);
      }
      let bundleCanBeAdded = 0;

      value.AllotmentNodes.map((node, index) => {
        if (
          !checkIfNodeCanBeAddedInProject(
            node,
            currentProject,
            allGroups.flatAllotments
          )
        ) {
          bundleCanBeAdded += 1;
        }
      });

      if (
        (bundleCanBeAdded === 0 &&
          (isTypeFicheIncludesInTypeFichesWithAllotmentNodes ||
            isConfiguredSheetSelected)) ||
        (bundleCanBeAdded < value.AllotmentNodes.length &&
          (!isTypeFicheIncludesInTypeFichesWithAllotmentNodes ||
            !isConfiguredSheetSelected))
      ) {
        let nodeMD = allGroups.type.find(
          (md) => md.id === value.AllotmentNodes[0]?.MasterDataEquipmentId
        );

        updateFormValue('nodeSearchValue', value.AllotmentNodes[0]);
        updateFormValue('searchValue', nodeMD);

        updateFormValue('selectedIniesSheet', value);
        setSelectedSheetObj(value);
        return;
      } else {
        updateFormValue('searchValue', null);
        updateFormValue('nodeSearchValue', null);
        updateFormValue('selectedIniesSheet', null);
        setSelectedSheetObj(null);
        setSnackbarMessage(
          t('APP.ADDEQUIPMENT.INDEX.PH_60726F', {
            defaultValue:
              "Le lot d’un ou plusieurs éléments de l’ensemble n’est pas disponible sur ce type de projet. Pour ajouter l'ensemble, aller dans ‘Ma bibliothèque’ afin de modifier le(s) lot(s) bloquants",
          })
        );
      }
    }
  };

  const handleChangeAllotmentNode = (value, type) => {
    if (type === 'Nooco') {
      let nodeSelected = datas
        .find((parentNode) => parentNode.name === value)
        .details.find((node) => node.MasterDataEquipmentId);
      updateFormValue('nodeSearchValue', nodeSelected);
    } else {
      changeValueEquipments(
        're2020lot',
        allGroups.flatAllotments.find(
          (node) => node.name === value && node.AllotmentId === currentAllotment.id
        )
      );
    }
  };

  let projectTypeBBCAorEPCM = [TYPE_BBCA, TYPE_EPCM];

  let certificationKeyNode =
    currentAllotment.id === 2
      ? 'Re2020AllotmentNodeId'
      : currentAllotment.id === 34
      ? 'EpcmAllotmentNodeId'
      : '';

  return (
    <div
      className="wrapper-add"
      style={{
        minHeight:
          window.screen.availHeight < 950
            ? window.screen.availHeight - 400
            : window.screen.availHeight - 350,
        padding: window.screen.availHeight < 950 ? '0.5em' : null,
      }}
    >
      <BreadcrumbModal addEquipmentScreen={true} />
      <Snackbar
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage(null)}
        open={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarMessage(null)} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* BreadCrumb */}
      <div
        className="adding-steps"
        style={{ marginBottom: window.screen.availHeight < 950 ? '1em' : null }}
      >
        {/* TODO_TRAD : fr */}
        {!addDisplay.includes('Energie') &&
          !addDisplay.includes('Eau') &&
          !addDisplay.includes('Forfait') &&
          !addDisplay.includes('modeling') &&
          !addDisplay.includes('Choix fiche') &&
          !addDisplay.includes('Fiche configurée') &&
          !addDisplay.includes('Composant vide') && (
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => {
                  changeValueEquipments('addDisplay', ['1']);
                }}
                style={{
                  marginRight: '10px',
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                }}
                className="golden-button"
                text={t('APP.ADDEQUIPMENT.INDEX.PH_688E0D', {
                  defaultValue: '1 - Contributeur',
                })}
              />

              {!noCarac && (
                <Button
                  onClick={() => {
                    if (searchValue && selectedQuantity) {
                      changeValueEquipments('addDisplay', ['1', '2']);
                      setNoCarac(false);
                    }
                  }}
                  style={{
                    marginRight: '10px',
                    fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                  }}
                  className={
                    addDisplay.includes('2') ? 'golden-button' : 'gray-button'
                  }
                  text={t('APP.ADDEQUIPMENT.INDEX.PH_DD4C50', {
                    defaultValue: '2 - Caractéristiques',
                  })}
                />
              )}

              {zones.length > 2 &&
                zones.length - nbZoneBuildingSite > 2 &&
                !isOldBuildingSiteInProject && (
                  <Button
                    onClick={() =>
                      currentDestination
                        ? changeValueEquipments('addDisplay', ['1', '2', '3'])
                        : null
                    }
                    style={{
                      marginRight: '10px',
                      fontSize:
                        window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                    }}
                    className={
                      addDisplay.includes('3') ? 'golden-button' : 'gray-button'
                    }
                    text={
                      noCarac
                        ? t('APP.ADDEQUIPMENT.INDEX.PH_9D8D66', {
                            defaultValue: '2 - Destination',
                          })
                        : t('APP.ADDEQUIPMENT.INDEX.PH_A5C91B', {
                            defaultValue: '3 - Destination',
                          })
                    }
                  />
                )}
            </div>
          )}

        {/* TODO_TRAD : fr */}
        {(addDisplay.includes('Energie') ||
          addDisplay.includes('Eau') ||
          addDisplay.includes('Forfait') ||
          addDisplay.includes('Choix fiche') ||
          addDisplay.includes('Composant vide')) && (
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => {
                if (
                  addDisplay.includes('Eau') ||
                  addDisplay.includes('Energie') ||
                  addDisplay.includes('Choix fiche')
                ) {
                  setSearchValue();
                  updateFormValue('selectedLot', null);
                  updateFormValue('selectedFonction', null);
                  updateFormValue('selectedCategorie', null);
                  updateFormValue('selectedMaterial', null);
                  updateFormValue('selectedType', null);
                  changeValueEquipments('iniesAdvanceLink', null);
                  updateFormValue('nodeSearchValue', null);
                  updateFormValue('searchValue', null);
                  setInputValue('');
                  setSelectedQuantity();
                }

                changeValueEquipments('addDisplay', ['1']);
              }}
              style={{
                marginRight: '10px',
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
              }}
              className="golden-button"
              text={t('APP.ADDEQUIPMENT.INDEX.PH_4A9F0B', {
                defaultValue: '1 - Contributeur',
              })}
            />

            <Button
              onClick={() =>
                searchValue && selectedQuantity
                  ? changeValueEquipments('addDisplay', ['1', `${addDisplay[1]}`])
                  : null
              }
              style={{
                marginRight: '10px',
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
              }}
              className={
                addDisplay.includes(`${addDisplay[1]}`)
                  ? 'golden-button'
                  : 'gray-button'
              }
              text={`2 - ${addDisplay[1]}`}
            />

            {/* TODO_TRAD : fr */}
            {((addDisplay.includes('Forfait') && addDisplay.length === 3) ||
              (!addDisplay.includes('Forfait') &&
                !addDisplay.includes('Choix fiche')) ||
              (addDisplay.includes('Choix fiche') && searchValue?.hasFluid)) &&
              !addDisplay.includes('Composant vide') &&
              !noCarac && (
                <Button
                  onClick={() =>
                    searchValue && selectedQuantity && refCaracs
                      ? changeValueEquipments('addDisplay', [
                          '1',
                          `${addDisplay[1]}`,
                          '2',
                        ])
                      : null
                  }
                  style={{
                    marginRight: '10px',
                    fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                  }}
                  className={
                    addDisplay.includes('2') ? 'golden-button' : 'gray-button'
                  }
                  text={t('APP.ADDEQUIPMENT.INDEX.PH_9A6D99', {
                    defaultValue: '3 - Caractéristiques',
                  })}
                />
              )}

            {!addDisplay.includes('Forfait') && zones.length > 2 && (
              <Button
                onClick={() =>
                  currentDestination
                    ? changeValueEquipments('addDisplay', [
                        '1',
                        `${addDisplay[1]}`,
                        '2',
                        '3',
                      ])
                    : null
                }
                style={{
                  marginRight: '10px',
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                }}
                className={
                  addDisplay.includes('3') ? 'golden-button' : 'gray-button'
                }
                text={t('APP.ADDEQUIPMENT.INDEX.PH_3BE431', {
                  index:
                    (addDisplay.includes('Choix fiche') && searchValue?.hasFluid) ||
                    ((addDisplay.includes('Energie') ||
                      addDisplay.includes('Eau')) &&
                      !noCarac)
                      ? 4
                      : 3,
                  defaultValue: '{index} - Destination',
                })}
              />
            )}
          </div>
        )}

        {/* Top navigation for xml file */}
        {addDisplay.includes('Fiche configurée') &&
          !addDisplay.includes('Connexion') && (
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => {
                  setSearchValue();
                  setXmlFile();
                  setSelectedQuantity();
                  changeValueEquipments('addDisplay', ['1']);
                }}
                style={{
                  marginRight: '10px',
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                }}
                className="golden-button"
                text={t('APP.ADDEQUIPMENT.INDEX.PH_C76C95', {
                  defaultValue: '1 - Contributeur',
                })}
              />

              <Button
                onClick={() =>
                  searchValue && selectedQuantity
                    ? changeValueEquipments('addDisplay', ['1', `${addDisplay[1]}`])
                    : null
                }
                style={{
                  marginRight: '10px',
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                }}
                className={
                  addDisplay.includes(`${addDisplay[1]}`)
                    ? 'golden-button'
                    : 'gray-button'
                }
                text={`2 - ${addDisplay[1]}`}
              />

              <Button
                onClick={() =>
                  searchValue && selectedQuantity
                    ? changeValueEquipments('addDisplay', [
                        '1',
                        `${addDisplay[1]}`,
                        'Rangement',
                      ])
                    : null
                }
                style={{
                  marginRight: '10px',
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                }}
                className={
                  addDisplay.includes('Rangement') ? 'golden-button' : 'gray-button'
                }
                text="3 - Rangement"
              />

              <Button
                onClick={() =>
                  searchValue && selectedQuantity
                    ? changeValueEquipments('addDisplay', [
                        '1',
                        `${addDisplay[1]}`,
                        'Rangement',
                        '2',
                      ])
                    : null
                }
                style={{
                  marginRight: '10px',
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                }}
                className={
                  addDisplay.includes('2') ? 'golden-button' : 'gray-button'
                }
                text={t('APP.ADDEQUIPMENT.INDEX.PH_247412', {
                  defaultValue: '4 - Caractéristiques',
                })}
              />

              {zones.length > 2 && (
                <Button
                  style={{
                    marginRight: '10px',
                    fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                  }}
                  className={
                    addDisplay.includes('3') ? 'golden-button' : 'gray-button'
                  }
                  text={t('APP.ADDEQUIPMENT.INDEX.PH_A4F73B', {
                    defaultValue: '5 - Destination',
                  })}
                />
              )}
            </div>
          )}
        {/* Top navigation for EDEC */}
        {addDisplay.includes('Fiche configurée') &&
          addDisplay.includes('Connexion') && (
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => {
                  setSearchValue();
                  setXmlFile();
                  setSelectedQuantity();
                  changeValueEquipments('addDisplay', ['1']);
                }}
                style={{
                  marginRight: '10px',
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                }}
                className="golden-button"
                text={t('APP.ADDEQUIPMENT.INDEX.PH_617A44', {
                  defaultValue: '1 - Contributeur',
                })}
              />

              <Button
                onClick={() =>
                  searchValue && selectedQuantity
                    ? changeValueEquipments('addDisplay', ['1', `${addDisplay[1]}`])
                    : null
                }
                style={{
                  marginRight: '10px',
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                }}
                className={
                  addDisplay.includes(`${addDisplay[1]}`)
                    ? 'golden-button'
                    : 'gray-button'
                }
                text={`2 - ${addDisplay[1]}`}
              />

              <Button
                onClick={() =>
                  searchValue && selectedQuantity
                    ? changeValueEquipments('addDisplay', [
                        '1',
                        `${addDisplay[1]}`,
                        'Connexion',
                      ])
                    : null
                }
                style={{
                  marginRight: '10px',
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                }}
                className={
                  addDisplay.includes(`Connexion`) ? 'golden-button' : 'gray-button'
                }
                text={t('APP.ADDEQUIPMENT.INDEX.PH_2E2847', {
                  defaultValue: '3 - Connexion',
                })}
              />

              <Button
                onClick={() =>
                  searchValue && selectedQuantity
                    ? changeValueEquipments('addDisplay', [
                        '1',
                        `${addDisplay[1]}`,
                        'Connexion',
                        'Sélection',
                      ])
                    : null
                }
                style={{
                  marginRight: '10px',
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                }}
                className={
                  addDisplay.includes(`Sélection`) ? 'golden-button' : 'gray-button'
                }
                text={t('APP.ADDEQUIPMENT.INDEX.PH_88195E', {
                  defaultValue: '4 - Sélection',
                })}
              />

              <Button
                onClick={() =>
                  searchValue && selectedQuantity
                    ? changeValueEquipments('addDisplay', [
                        '1',
                        `${addDisplay[1]}`,
                        'Connexion',
                        'Sélection',
                        'Rangement',
                      ])
                    : null
                }
                style={{
                  marginRight: '10px',
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                }}
                className={
                  addDisplay.includes('Rangement') ? 'golden-button' : 'gray-button'
                }
                text={t('APP.ADDEQUIPMENT.INDEX.PH_9A8B6E', {
                  defaultValue: '5 - Rangement',
                })}
              />

              <Button
                onClick={() =>
                  searchValue && selectedQuantity
                    ? changeValueEquipments('addDisplay', [
                        '1',
                        `${addDisplay[1]}`,
                        'Connexion',
                        'Sélection',
                        'Rangement',
                        '2',
                      ])
                    : null
                }
                style={{
                  marginRight: '10px',
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                }}
                className={
                  addDisplay.includes('2') ? 'golden-button' : 'gray-button'
                }
                text={t('APP.ADDEQUIPMENT.INDEX.PH_F37D4A', {
                  defaultValue: '6 - Caractéristiques',
                })}
              />

              {zones.length > 2 && (
                <Button
                  style={{
                    marginRight: '10px',
                    fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                  }}
                  className={
                    addDisplay.includes('3') ? 'golden-button' : 'gray-button'
                  }
                  text={t('APP.ADDEQUIPMENT.INDEX.PH_CECE2C', {
                    defaultValue: '7 - Destination',
                  })}
                />
              )}
            </div>
          )}
        {/* Top navigation for modeling Equipment */}
        {addDisplay.includes('modeling') && (
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => {
                changeValueEquipments('addDisplay', ['1']);
                // changeValueEquipments('currentDestination', null)
              }}
              style={{
                marginRight: '10px',
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
              }}
              className="golden-button"
              text={t('APP.ADDEQUIPMENT.INDEX.PH_9048F9', {
                defaultValue: '1 - Contributeur',
              })}
            />

            <Button
              onClick={() => {
                changeValueEquipments('addDisplay', ['1', '2', `modeling`]);
                // if (zones.length > 2) {
                //   changeValueEquipments('currentDestination', null)
                // }
              }}
              style={{
                marginRight: '10px',
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
              }}
              className={addDisplay.includes('2') ? 'golden-button' : 'gray-button'}
              text={t('APP.ADDEQUIPMENT.INDEX.PH_48025E', {
                defaultValue: '2 - Caractéristiques',
              })}
            />

            <Button
              onClick={() => {
                if (isStepTwoCaracModelingCompleted()) {
                  changeValueEquipments('addDisplay', ['1', '2', '3', `modeling`]);
                  //   if (zones.length > 2) {
                  //   changeValueEquipments('currentDestination', null)
                  // }
                }
              }}
              style={{
                marginRight: '10px',
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
              }}
              // condition du className
              className={addDisplay.includes('3') ? 'golden-button' : 'gray-button'}
              text={t('APP.ADDEQUIPMENT.INDEX.PH_F3D75B', {
                defaultValue: '3 - Choix de la modélisation',
              })}
            />

            <Button
              onClick={() => {
                if (
                  isStepTwoCaracModelingCompleted() &&
                  isStepThreeChoiceModelingCompleted()
                ) {
                  changeValueEquipments('addDisplay', [
                    '1',
                    '2',
                    '3',
                    '4',
                    `modeling`,
                  ]);
                  //   if (zones.length > 2) {
                  //   changeValueEquipments('currentDestination', null)
                  // }
                }
              }}
              style={{
                marginRight: '10px',
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
              }}
              // condition du className

              className={addDisplay.includes('4') ? 'golden-button' : 'gray-button'}
              text={t('APP.ADDEQUIPMENT.INDEX.PH_5E6580', {
                defaultValue: '4 - Modélisation',
              })}
            />

            {!isCurrentDestinationFilledForModeling && (
              <Button
                onClick={() => {
                  if (
                    isStepTwoCaracModelingCompleted() &&
                    isStepThreeChoiceModelingCompleted() &&
                    isStepFourModelingCompleted()
                  ) {
                    changeValueEquipments('addDisplay', [
                      '1',
                      '2',
                      '3',
                      '4',
                      '5',
                      `modeling`,
                    ]);
                    //   if (zones.length > 2) {
                    //   changeValueEquipments('currentDestination', null)
                    // }
                  }
                }}
                style={{
                  marginRight: '10px',
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                }}
                // condition du className

                className={
                  addDisplay.includes('5') ? 'golden-button' : 'gray-button'
                }
                text={t('APP.ADDEQUIPMENT.INDEX.PH_6C05C1', {
                  defaultValue: '5 - Destination',
                })}
              />
            )}
          </div>
        )}
      </div>
      {/* main home */}
      {addDisplay.length === 1 && addDisplay.includes('1') && (
        <>
          <div className="composant-part">
            <div className="composant-title">
              <h4>
                {t('APP.ADDEQUIPMENT.INDEX.PH_F2BA16', {
                  defaultValue: 'Ajout de composant',
                })}
              </h4>
            </div>
            <div className="composant-top">
              <div className="searchBar">
                <SearchGroup
                  t={t}
                  companyId={currentProject.CompanyId}
                  searchBarOptionsFiltered={searchBarOptionsFiltered}
                  certifiedAllotments={certifiedAllotments}
                  triggerBreadcrumb={() => {
                    updateFormValue('openBreadcrumb', true);
                  }}
                  projectTypeId={currentProject.ProjectTypeId}
                  bundleOptions={bundleOptions}
                  findParentNode={findParentNode}
                  allAllotments={allGroups.flatAllotments}
                  allotmentsAvailable={nodesAvailableForProjectType.filter(
                    (el) => !el.isObsolete
                  )}
                  api={ctx.api}
                  dropdownProps={{
                    autoFocus: true,
                    onSelect: () => {},
                    onChange: (value) => {
                      if (!value) {
                        updateFormValue('searchValue', null);
                        updateFormValue('nodeSearchValue', null);
                        updateFormValue('selectedIniesSheet', null);
                        setSelectedSheetObj(null);
                      } else {
                        handleOnChangeAddEquipmentSearchBar(value);
                      }
                    },
                    placeholder: 'Rechercher...',
                    treeCheckable: false,
                    value: selectedSheetObj?.processedLabel
                      ? selectedSheetObj.processedLabel
                      : selectedSheetObj?.name
                      ? selectedSheetObj.name
                      : nodeSearchValue
                      ? nodeSearchValue.name
                      : null,
                  }}
                  dropdownWidth="200px"
                  selectProps1={{
                    defaultValue: selectedSearchGroupType,
                    onChange: (value) => {
                      updateFormValue('searchValue', null);
                      updateFormValue('nodeSearchValue', null);
                      updateFormValue('selectedIniesSheet', null);
                      setSelectedSheetObj(null);
                      setIsConfiguredSheetSelected(
                        value?.type_fiche === 'FC' ? true : false
                      );
                      onChangeSelectedSearchGroupType(
                        setSelectedSearchGroupType,
                        SelectedSearchGroupOptionsLabel[value.label] ||
                          SelectedSearchGroupOptionsLabel[value.value] ||
                          SelectedSearchGroupOptionsLabel.Equipements
                      );
                    },
                    filterOptions: searchGroupOptions,
                  }}
                  selectProps2={{
                    ListType:
                      currentAllotment.id !== 1
                        ? [currentAllotment.name.toUpperCase()]
                        : ['Nooco'],
                    noocoOptions: datas.filter((el) => !el.isObsolete),
                    onChange: handleChangeAllotmentNode,
                    value:
                      nodeSearchValue && currentAllotment.id === 1
                        ? findParentNode(
                            nodeSearchValue,
                            allGroups.flatAllotments,
                            [],
                            []
                          )
                        : re2020lot
                        ? [re2020lot]
                        : nodeSearchValue
                        ? [
                            allGroups.flatAllotments.find(
                              (node) =>
                                node.id === nodeSearchValue[certificationKeyNode]
                            ),
                          ]
                        : [],
                  }}
                  selectWidth="100px"
                />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                className="composant-quantity"
                style={{ padding: '1em', width: '30%' }}
              >
                <p className="subtitle">
                  {t('APP.ADDEQUIPMENT.INDEX.PH_83E325', {
                    defaultValue: 'Quantité',
                  })}{' '}
                  {selectedSheetObj &&
                  ['bundle', 'FC'].includes(selectedSheetObj.type_fiche)
                    ? `(${selectedSheetObj.unit})`
                    : searchValue
                    ? `(${searchValue.unit})`
                    : null}
                </p>
                <Tooltip
                  title={
                    !searchValue
                      ? t('APP.ADDEQUIPMENT.INDEX.PH_9A5748', {
                          defaultValue:
                            "Choisir l'équipement/matériaux avant de saisir la quantité",
                        })
                      : t('APP.ADDEQUIPMENT.INDEX.PH_3DC0DA', {
                          defaultValue:
                            "Saisir la quantité d'équipements/matériaux dans l'unité demandée",
                        })
                  }
                >
                  <Form.Control
                    style={{ width: '100%' }}
                    type="number"
                    value={selectedQuantity}
                    disabled={!selectedSheetObj && !searchValue}
                    onChange={(e) => setSelectedQuantity(e.target.value)}
                  />
                </Tooltip>
              </div>
              <div style={{ padding: '1em', width: '30%' }}>
                <p className="subtitle">
                  {t('APP.ADDEQUIPMENT.INDEX.PH_584E03', {
                    defaultValue: 'Libellé source (facultatif)',
                  })}
                </p>
                <Form.Control
                  style={{ width: '100%' }}
                  disabled={!selectedSheetObj && !searchValue}
                  value={customName}
                  onChange={(e) => updateFormValue('customName', e.target.value)}
                />
              </div>
              <div style={{ padding: '1em', width: '30%' }}>
                <p className="subtitle">
                  {t('APP.ADDEQUIPMENT.INDEX.PH_D3B0E4', {
                    defaultValue: 'Commentaire (facultatif)',
                  })}
                </p>
                <Tooltip
                  title={t('APP.ADDEQUIPMENT.INDEX.PH_AB50CF', {
                    defaultValue: 'Ajouter un commentaire',
                  })}
                >
                  <Form.Control
                    style={{ width: '100%' }}
                    disabled={!selectedSheetObj && !searchValue}
                    value={commentEquipment}
                    onChange={(e) =>
                      updateFormValue('commentEquipment', e.target.value)
                    }
                  />
                </Tooltip>
              </div>
            </div>
          </div>

          {/* main home Add buttons */}
          <div className="composant-part advance-add">
            <div className="composant-title">
              <h4>
                {t('APP.ADDEQUIPMENT.INDEX.PH_510860', {
                  defaultValue: 'Autres ajouts',
                })}
              </h4>
            </div>
            <div
              className="all-contributors"
              style={{
                padding: window.screen.availHeight > 950 ? null : '1rem',
              }}
            >
              {(currentProject.Lots.find((el) => el.name === 'Energie') ||
                currentProject.Lots.find(
                  (el) => el.name === 'Energie simplifiée'
                )) &&
                currentProject.templateType !== TEMPLATE_TYPE_NAME.BBCA &&
                !currentProject.isRset && (
                  <Tooltip
                    title={t('APP.ADDEQUIPMENT.INDEX.PH_87ABE3', {
                      defaultValue: "Ajouter des consommations d'énergie",
                    })}
                  >
                    <div
                      style={{
                        height: window.screen.availHeight > 950 ? '100px' : null,
                      }}
                      className="contributor-add"
                      onClick={() =>
                        changeValueEquipments('addDisplay', ['1', 'Energie'])
                      }
                    >
                      <img
                        style={
                          window.screen.availHeight > 950
                            ? { width: '65px', height: '67px' }
                            : { width: '35px', height: '37px' }
                        }
                        src={energyPic}
                      />

                      <p>
                        {t('APP.ADDEQUIPMENT.INDEX.PH_F02496', {
                          defaultValue: 'Energie',
                        })}
                      </p>
                    </div>
                  </Tooltip>
                )}

              {/* && templateType !== TYPE_RE2020
              && isOldBuildingSiteInProject  */}
              {currentProject.Lots.find((el) => el.name === 'Eau') &&
                templateType !== TYPE_RE2020 && (
                  <Tooltip
                    title={t('APP.ADDEQUIPMENT.INDEX.PH_9B0449', {
                      defaultValue: "Ajouter des consommations d'eau",
                    })}
                  >
                    <div
                      style={{
                        height: window.screen.availHeight > 950 ? '100px' : null,
                      }}
                      className="contributor-add"
                    >
                      <img
                        style={
                          window.screen.availHeight > 950
                            ? { width: '63px', height: '67px' }
                            : { width: '33px', height: '37px' }
                        }
                        onClick={() =>
                          changeValueEquipments('addDisplay', ['1', 'Eau'])
                        }
                        src={waterPic}
                      />

                      <p>
                        {t('APP.ADDEQUIPMENT.INDEX.PH_134C0C', {
                          defaultValue: 'Eau',
                        })}
                      </p>
                    </div>
                  </Tooltip>
                )}

              {[TYPE_NOOCO, TYPE_EPCM, TYPE_RE2020, TYPE_BBCA].includes(
                currentProject.templateType
              ) && (
                <Tooltip
                  title={t('APP.ADDEQUIPMENT.INDEX.PH_3B396C', {
                    defaultValue: 'Ajouter un lot ou sous-lot forfaitaire',
                  })}
                >
                  <div
                    style={{
                      height: window.screen.availHeight > 950 ? '100px' : null,
                    }}
                    className="contributor-add"
                    onClick={() =>
                      changeValueEquipments('addDisplay', ['1', 'Forfait'])
                    }
                  >
                    <img
                      style={
                        window.screen.availHeight > 950
                          ? { width: '76px', height: '76px' }
                          : { width: '46px', height: '46px' }
                      }
                      src={forfaitPic}
                    />
                    <p>Forfait</p>
                  </div>
                </Tooltip>
              )}

              <Tooltip
                title={t('APP.ADDEQUIPMENT.INDEX.PH_33D824', {
                  defaultValue:
                    'Ajouter une fiche configurée manuellement ou via EDEC',
                })}
              >
                <div
                  className="contributor-add"
                  onClick={() =>
                    changeValueEquipments('addDisplay', ['1', 'Fiche configurée'])
                  }
                >
                  <img
                    style={{
                      width: window.screen.availHeight > 950 ? '60px' : '45px',
                      marginTop: '12px',
                    }}
                    src={xmlPic}
                  />
                  <div style={{ marginTop: '5px' }}>
                    <p style={{ textAlign: 'center' }}>Fiche configurée</p>
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                title={t('APP.ADDEQUIPMENT.INDEX.PH_FA47B2', {
                  defaultValue: 'Ajouter un composant vide',
                })}
              >
                <div
                  style={{
                    height: window.screen.availHeight > 950 ? '100px' : null,
                  }}
                  onClick={() => askingIsDed()}
                  className="contributor-add"
                >
                  <img
                    style={
                      window.screen.availHeight > 950
                        ? { width: '90px', height: '75px', marginTop: '10px' }
                        : { width: '60px', height: '38px', marginTop: '10px' }
                    }
                    onClick={() =>
                      changeValueEquipments('addDisplay', ['1', 'Composant vide'])
                    }
                    src={reemployPic}
                  />

                  <p>
                    {t('APP.ADDEQUIPMENT.INDEX.PH_1EE753', {
                      defaultValue: 'Composant vide',
                    })}
                  </p>
                </div>
              </Tooltip>
              {currentProject.templateType !== TYPE_EPCM &&
                currentProject.templateType !== TYPE_RE2020 && (
                  <Tooltip
                    title={t('APP.ADDEQUIPMENT.INDEX.PH_845A83', {
                      defaultValue: 'Modéliser un équipement sur mesure',
                    })}
                  >
                    <div
                      className="contributor-add"
                      style={{ justifyContent: 'flex-end' }}
                      onClick={() =>
                        changeValueEquipments('addDisplay', ['1', '2', 'modeling'])
                      }
                    >
                      <img
                        style={{
                          width: window.screen.availHeight > 950 ? '60px' : '45px',
                          marginBottom: '12px',
                        }}
                        src={modelingPic}
                      />

                      <div style={{ marginTop: '5px' }}>
                        <p style={{ textAlign: 'center' }}>
                          {t('APP.ADDEQUIPMENT.INDEX.PH_0CB382', {
                            defaultValue: 'Equipement modelisé',
                          })}
                        </p>
                      </div>
                    </div>
                  </Tooltip>
                )}
            </div>
          </div>
        </>
      )}

      {/* TODO_TRAD */}
      {((addDisplay.includes('2') && addDisplay.length === 2) ||
        (addDisplay.includes('2') &&
          (addDisplay.includes('Energie') ||
            addDisplay.includes('Eau') ||
            addDisplay.includes('Forfait') ||
            addDisplay.includes('Choix fiche')) &&
          addDisplay.length === 3) ||
        (addDisplay.includes('Rangement') && addDisplay.length === 4) ||
        (addDisplay.includes('Rangement') && addDisplay.length === 6)) && (
        <div>
          <div
            className="equipment-caracs-part"
            style={{
              minHeight: window.screen.availHeight > 950 ? '380px' : null,
            }}
          >
            {(refCaracsLength > 0 ||
              (searchValue?.hasFluid && !iniesAdvanceLink) ||
              (iniesAdvanceLink &&
                !iniesAdvanceLink.SheetId.Indices[0].SheetIndex.B1) ||
              datasForfait.find((el) => el.hasFluid && el.check)) && (
              <>
                <div className="composant-part caracsPart">
                  <Tooltip
                    title={
                      !addDisplay.includes('Fiche configurée')
                        ? t('APP.ADDEQUIPMENT.INDEX.PH_2C1DFD', {
                            defaultValue:
                              "Saisir les caractéristiques de l'équipement / matériau à ajouter",
                          })
                        : ''
                    }
                  >
                    <div
                      className="composant-title"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <h4>
                        {t('APP.ADDEQUIPMENT.INDEX.PH_53AE96', {
                          defaultValue: 'Caractéristiques',
                        })}
                      </h4>

                      {addDisplay.includes('Fiche configurée') && (
                        <Tooltip
                          title={t('APP.ADDEQUIPMENT.INDEX.PH_D01BDE', {
                            defaultValue:
                              'Les caractéristiques saisies sur la fiche configurée permettent de la placer sur le graphe de la famille d’équipements/matériaux correspondante mais n’impactent pas le calcul ACV.',
                          })}
                        >
                          <InfoIcon />
                        </Tooltip>
                      )}
                    </div>
                  </Tooltip>
                  <div className="carac-details">
                    {refCaracs?.caracs.length === 0 && (
                      <p style={{ width: '50%', margin: 'auto' }}>
                        {t('APP.ADDEQUIPMENT.INDEX.PH_7D1C35', {
                          defaultValue: 'Aucune caractéristique à renseigner',
                        })}
                      </p>
                    )}

                    {refCaracs?.caracs.map((carac) => {
                      if (
                        carac.neededOnCreation ||
                        (carac.neededOnImplementation &&
                          !(
                            searchValue.name?.includes('photovoltaïque') &&
                            currentProject.isRset
                          ))
                      ) {
                        let caracListOptions = carac.caracList;

                        if (
                          userCaracs?.find(
                            (el) => el.id === refCaracs.caracCombos?.id
                          )?.value &&
                          refCaracs.caracCombos.valueList.find(
                            (el) =>
                              el.value ===
                                userCaracs?.find(
                                  (el) => el.id === refCaracs.caracCombos.id
                                )?.value && carac.name === el.name
                          )
                        ) {
                          caracListOptions = refCaracs.caracCombos.valueList.find(
                            (el) =>
                              el.value ===
                              userCaracs?.find(
                                (el) => el.id === refCaracs.caracCombos.id
                              )?.value
                          )?.valueList;
                        }

                        if (carac.id === refCaracs.caracCombos.id) {
                          caracListOptions = refCaracs.caracCombos.valueList;
                        }

                        return (
                          <Form.Group controlId="formGridOverseer">
                            <Form.Label>
                              {carac.unit
                                ? `${carac.name} (${carac.unit})`
                                : `${carac.name} `}
                            </Form.Label>
                            {carac.caracList && (
                              <Form.Control
                                value={
                                  userCaracs?.find((el) => el.name === carac.name)
                                    ?.value ||
                                  (carac.name ===
                                    'Production annuelle : Eef_Prod (kWh/m2/an)' ||
                                  carac.name ===
                                    'Autoconsommation annuelle : Eef_Prod_AC (kWh/m2/an)'
                                    ? '1'
                                    : '')
                                }
                                disabled={
                                  refCaracs.caracCombos
                                    ? refCaracs.caracCombos.valueList.find(
                                        (el) => el.id === carac.id
                                      ) &&
                                      carac.id !== refCaracs.caracCombos.id &&
                                      !userCaracs?.find(
                                        (el) => el.id === refCaracs.caracCombos.id
                                      )?.value
                                    : false
                                }
                                required
                                as="select"
                                custom
                                onChange={(e) =>
                                  handleCaracsChange(carac, e.target.value)
                                }
                              >
                                <option key={'empty'} value={''}>
                                  {t('APP.ADDEQUIPMENT.INDEX.PH_33C6AF', {
                                    defaultValue: 'Choisir une valeur',
                                  })}
                                </option>
                                {caracListOptions
                                  .sort(
                                    (a, b) =>
                                      a.value.localeCompare(b.value) ||
                                      a.value - b.value
                                  )
                                  .map((carac) => (
                                    <option key={carac.id} value={carac.value}>
                                      {carac.value}
                                    </option>
                                  ))}
                              </Form.Control>
                            )}

                            {!carac.caracList && (
                              <Form.Control
                                required
                                autoComplete="off"
                                autoCorrect="off"
                                spellCheck="off"
                                onKeyPress={handleKeyPress}
                                type={
                                  carac.name ===
                                    'Production annuelle : Eef_Prod (kWh/m2/an)' ||
                                  carac.name ===
                                    'Autoconsommation annuelle : Eef_Prod_AC (kWh/m2/an)'
                                    ? 'number'
                                    : 'text'
                                }
                                defaultValue={
                                  carac.name ===
                                    'Production annuelle : Eef_Prod (kWh/m2/an)' ||
                                  carac.name ===
                                    'Autoconsommation annuelle : Eef_Prod_AC (kWh/m2/an)'
                                    ? '1'
                                    : carac.defaultValue
                                    ? carac.defaultValue
                                    : ''
                                }
                                value={
                                  userCaracs?.find((el) => el.name === carac.name)
                                    ?.value
                                }
                                onChange={(e) => {
                                  const finalValue = prodConsoValidation(
                                    carac,
                                    e.target.value
                                  );

                                  handleCaracsChange(carac, finalValue);
                                }}
                              />
                            )}

                            <Form.Control.Feedback type="invalid">
                              {t('APP.ADDEQUIPMENT.INDEX.PH_5E2277', {
                                defaultValue: 'Veuillez renseigner ce champ.',
                              })}
                            </Form.Control.Feedback>
                          </Form.Group>
                        );
                      } else return null;
                    })}

                    {datasForfait.find((el) => el.hasFluid) && renderFluidsModal()}
                    {searchValue.hasFluid &&
                      currentProject.templateType !== TYPE_RE2020 &&
                      currentProject.templateType !== TYPE_EPCM && (
                        <Tooltip
                          title={'Le fluide considéré par défaut est du R410A'}
                        >
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                cursor: 'pointer',
                                alignItems: 'center',
                              }}
                              onClick={() =>
                                (selectedIniesSheet &&
                                  selectedIniesSheet.type_fiche !== 'DED') ||
                                (selectedSheetObj &&
                                  selectedSheetObj?.type_fiche !== 'DED')
                                  ? null
                                  : setDisplayOverwritteFluidsForm(
                                      !displayOverwritteFluidsForm
                                    )
                              }
                            >
                              <NoocoCheckbox
                                disabled={
                                  (selectedIniesSheet &&
                                    selectedIniesSheet.type_fiche !== 'DED') ||
                                  (selectedSheetObj &&
                                    selectedSheetObj?.type_fiche !== 'DED')
                                }
                                checked={displayOverwritteFluidsForm}
                              />

                              <p style={{ fontStyle: 'italic', margin: '0' }}>
                                {t('APP.ADDEQUIPMENT.INDEX.PH_B542F0', {
                                  defaultValue:
                                    'Changer le type de fluide (facultatif)',
                                })}
                              </p>
                            </div>
                            {displayOverwritteFluidsForm && renderOverwritteFluid()}
                          </div>
                        </Tooltip>
                      )}
                  </div>
                </div>
              </>
            )}
            {!addDisplay.includes('Choix fiche') &&
              !addDisplay.includes('Eau') &&
              !addDisplay.includes('Energie') &&
              !addDisplay.includes('Connexion') &&
              !addDisplay.includes('Fiche configurée') &&
              !addDisplay.includes('Forfait') && (
                <div className="composant-part caracsPart">
                  <Tooltip
                    title={t('APP.ADDEQUIPMENT.INDEX.PH_3115DF', {
                      defaultValue:
                        'Rechercher un produit vérifié parmi les produits disponibles sur la base INIES',
                    })}
                  >
                    <div className="composant-title">
                      <h4>
                        {t('APP.ADDEQUIPMENT.INDEX.PH_A1ECC7', {
                          defaultValue:
                            "Recherche d'un produit vérifié (Facultatif)",
                        })}
                      </h4>
                    </div>
                  </Tooltip>
                  <div className="carac-details">
                    {!brands || brands.length === 0 ? null : (
                      <Form.Group controlId="formGridOverseer">
                        <Tooltip
                          title={t('APP.ADDEQUIPMENT.INDEX.PH_C37CB0', {
                            defaultValue: 'Choisir la marque du produit',
                          })}
                        >
                          <Form.Label className="subtitle">
                            {t('APP.ADDEQUIPMENT.INDEX.PH_1BD42B', {
                              defaultValue: 'Marque',
                            })}
                          </Form.Label>
                        </Tooltip>
                        <Form.Control
                          custom
                          disabled={
                            searchValue.name?.includes('photovoltaïque') &&
                            currentProject.isRset &&
                            userCaracs.find((el) => el.name.includes('Technologie'))
                              ? false
                              : refCaracsLength !== userCaracs.length
                          }
                          as="select"
                          onChange={(e) => {
                            setSheetsToShow(undefined);
                            setSelectedSheet(null);
                            setSelectedSheetObj(null);
                            setSelectedBrand(e.target.value);
                          }}
                        >
                          <option>
                            {t('APP.ADDEQUIPMENT.INDEX.PH_9F3EFC', {
                              defaultValue: 'Choisir une valeur',
                            })}
                          </option>

                          {!brands
                            ? null
                            : brands
                                .filter((el) => el)
                                .map((brand) => (
                                  <option key={brand.id} value={brand.id}>
                                    {brand.name}
                                  </option>
                                ))}
                        </Form.Control>
                      </Form.Group>
                    )}

                    <Tooltip
                      title={t('APP.ADDEQUIPMENT.INDEX.PH_3FFB2D', {
                        defaultValue: 'Sélectionner le produit à ajouter',
                      })}
                    >
                      <p className="subtitle">
                        {t('APP.ADDEQUIPMENT.INDEX.PH_900049', {
                          defaultValue: 'Liste des produits',
                        })}
                      </p>
                    </Tooltip>
                    {!selectedBrand && (
                      <p style={{ marginLeft: '10px' }}>
                        {t('APP.ADDEQUIPMENT.INDEX.PH_A54783', {
                          defaultValue: 'Aucune marque sélectionnée',
                        })}
                      </p>
                    )}

                    {((searchValue.name?.includes('photovoltaïque') &&
                      currentProject.isRset) ||
                      refCaracsLength === userCaracs.length) &&
                    sheetsToShow &&
                    selectedBrand ? (
                      <List
                        style={{
                          width: '100%',
                          maxHeight: '200px',
                          overflow: 'auto',
                          marginBottom: '20px',
                        }}
                      >
                        {sheetsToShow.length === 0 ? (
                          <p>
                            {t('APP.ADDEQUIPMENT.INDEX.PH_333133', {
                              defaultValue:
                                'Aucune fiche trouvée avec ces caractéristiques.',
                            })}
                          </p>
                        ) : (
                          sheetsToShow.map((sheet) => {
                            const labelId = `checkbox-list-label-${sheet.id}`;

                            return (
                              <ListItem key={sheet.id} role={undefined} dense button>
                                <>
                                  <ListItemIcon
                                    onClick={() => {
                                      setSelectedSheet(sheet.id);
                                      updateFormValue('objSheet', sheet);
                                      setSelectedSheetObj(sheet);
                                      setReEmploy(false);
                                    }}
                                  >
                                    <NoocoCheckbox
                                      on
                                      checked={sheet.id === selectedSheet}
                                      edge="start"
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                    />
                                  </ListItemIcon>
                                  <Tooltip title={sheet.libelle || sheet.name}>
                                    <ListItemText
                                      onClick={() => {
                                        setSelectedSheet(sheet.id);
                                        updateFormValue('objSheet', sheet);
                                        setSelectedSheetObj(sheet);
                                        setReEmploy(false);
                                      }}
                                      id={labelId}
                                      primary={sheet.libelle || sheet.name}
                                    />
                                  </Tooltip>
                                </>
                                <FavoritesIcons SheetId={sheet.id} />
                              </ListItem>
                            );
                          })
                        )}
                      </List>
                    ) : null}
                  </div>
                  <div></div>
                </div>
              )}
          </div>
          {/* TODO_TRAD */}
          {!addDisplay.includes('Forfait') &&
            !addDisplay.includes('Energie') &&
            !addDisplay.includes('Eau') &&
            searchValue.lot !== 'Terre' && (
              <ReemployCheck
                setSelectedSheet={setSelectedSheet}
                setSelectedSheetObj={setSelectedSheetObj}
                setReEmploy={setReEmploy}
                reEmploy={reEmploy}
              />
            )}
        </div>
      )}

      {/* Display screen Zone selection */}
      {((addDisplay.includes('3') && !addDisplay.includes('modeling')) ||
        addDisplay.includes('5')) && (
        <div className="composant-part">
          <div className="composant-title">
            <h4>
              {t('APP.ADDEQUIPMENT.INDEX.PH_48673E', {
                defaultValue: 'Quelle destination ?',
              })}
            </h4>
          </div>
          <div
            className="project-tree"
            style={{
              height:
                window.screen.availHeight < 950
                  ? window.screen.availHeight - 380
                  : window.screen.availHeight - 570,
              minWidth: '200px',
              margin: 'auto',
            }}
          >
            <ReactFlow
              preventScrolling={false}
              zoomOnScroll={true}
              fitView={true}
              nodes={elementsTree.filter((el) => el.hasOwnProperty('position'))}
              edges={elementsTree.filter((el) => !el.hasOwnProperty('position'))}
              nodesDraggable={false}
              nodesConnectable={false}
              proOptions={{ hideAttribution: true }}
            />
          </div>
        </div>
      )}
      {addDisplay[addDisplay.length - 1] === 'Energie' &&
        typeProjectForSimplifiedEnergy.includes(currentProject.templateType) && (
          <SimplifiedEnergy changeValue={changeValueEquipmentsAsAction} />
        )}
      {((addDisplay.includes('Energie') &&
        !typeProjectForSimplifiedEnergy.includes(currentProject.templateType)) ||
        addDisplay.includes('Eau')) &&
        addDisplay.length === 2 && (
          <>
            <div className="composant-part">
              <div className="composant-title">
                <h4>{addDisplay[1]}</h4>
              </div>
              <div className="energy-part">
                {breadcrumbChoice && (
                  <>
                    <div className="breadcrumb-part">
                      <Tooltip
                        title={t(
                          'APP.ADDEQUIPMENT.INDEX.PH_DE74F8',

                          {
                            text: addDisplay.includes('Energie') ? 'énergie' : 'eau',
                            defaultValue:
                              "Choisir la fonction liée aux consommations d'{text} à ajouter",
                          }
                        )}
                      >
                        <p className="subtitle">
                          {t('APP.ADDEQUIPMENT.INDEX.PH_3F1030', {
                            defaultValue: 'Fonction',
                          })}
                        </p>
                      </Tooltip>
                      <div className="choices">
                        {breadcrumbChoice.fonction.map((el) => (
                          <p
                            className={
                              el.name === selectedFonction
                                ? 'selected-choice'
                                : 'choice-active'
                            }
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSearchValue();

                              updateFormValue('selectedFonction', el.name);
                              updateFormValue('selectedCategory', undefined);
                              updateFormValue('selectedMaterial', undefined);
                              updateFormValue('selectedType', undefined);
                            }}
                            key={el}
                          >
                            {el.name}
                          </p>
                        ))}
                      </div>
                    </div>
                    <div className="breadcrumb-part">
                      <Tooltip
                        title={t(
                          'APP.ADDEQUIPMENT.INDEX.PH_ACF67C',

                          {
                            text: addDisplay.includes('Energie') ? 'énergie' : 'eau',
                            defaultValue:
                              "Choisir la catégorie liée aux consommations d'{text} à ajouter",
                          }
                        )}
                      >
                        <p className="subtitle">
                          {t('APP.ADDEQUIPMENT.INDEX.PH_1F1607', {
                            defaultValue: 'Catégorie',
                          })}
                        </p>
                      </Tooltip>
                      <div className="choices">
                        {breadcrumbChoice.categorie
                          .filter(
                            (elem, index, self) =>
                              index === self.findIndex((t) => t.name === elem.name)
                          )
                          .map((el) => (
                            <p
                              style={{
                                cursor: el.full.find(
                                  (ele) =>
                                    ele.lot === selectedLot &&
                                    ele.fonction === selectedFonction
                                )
                                  ? 'pointer'
                                  : 'not-allowed',
                              }}
                              className={
                                el.full.find(
                                  (ele) =>
                                    ele.lot === selectedLot &&
                                    ele.fonction === selectedFonction
                                ) && selectedCategory !== el.name
                                  ? 'choice-active'
                                  : selectedCategory === el.name
                                  ? 'selected-choice'
                                  : 'choice-inactive'
                              }
                              onClick={() => {
                                if (
                                  el.full.find(
                                    (ele) =>
                                      ele.lot === selectedLot &&
                                      ele.fonction === selectedFonction
                                  )
                                ) {
                                  setSearchValue();

                                  updateFormValue('selectedCategory', el.name);
                                  updateFormValue('selectedMaterial', undefined);
                                  updateFormValue('selectedType', undefined);
                                }
                              }}
                              key={el.name}
                            >
                              {el.name}
                            </p>
                          ))}
                      </div>
                    </div>
                    <div className="breadcrumb-part">
                      <Tooltip
                        title={t(
                          'APP.ADDEQUIPMENT.INDEX.PH_314E5F',

                          {
                            text: addDisplay.includes('Energie') ? 'énergie' : 'eau',
                            defaultValue:
                              "Choisir la matériel liée aux consommations d'{text} à ajouter",
                          }
                        )}
                      >
                        <p className="subtitle">
                          {t('APP.ADDEQUIPMENT.INDEX.PH_118ED5', {
                            defaultValue: 'Matériel',
                          })}
                        </p>
                      </Tooltip>
                      <div className="choices">
                        {breadcrumbChoice.materiel
                          .filter(
                            (elem, index, self) =>
                              index === self.findIndex((t) => t.name === elem.name)
                          )
                          .map((el) => (
                            <p
                              style={{
                                cursor: el.full.find(
                                  (ele) =>
                                    ele.lot === selectedLot &&
                                    ele.fonction === selectedFonction &&
                                    ele.categorie === selectedCategory
                                )
                                  ? 'pointer'
                                  : 'not-allowed',
                              }}
                              className={
                                el.full.find(
                                  (ele) =>
                                    ele.lot === selectedLot &&
                                    ele.fonction === selectedFonction &&
                                    ele.categorie === selectedCategory
                                ) && selectedMaterial !== el.name
                                  ? 'choice-active'
                                  : selectedMaterial === el.name
                                  ? 'selected-choice'
                                  : 'choice-inactive'
                              }
                              onClick={() => {
                                if (
                                  el.full.find(
                                    (ele) =>
                                      ele.lot === selectedLot &&
                                      ele.fonction === selectedFonction &&
                                      ele.categorie === selectedCategory
                                  )
                                ) {
                                  updateFormValue('selectedMaterial', el.name);
                                  let typeNode = breadcrumbChoice.type.find((node) =>
                                    node.full.find(
                                      (list) =>
                                        list.lot === selectedLot &&
                                        list.fonction === selectedFonction &&
                                        list.categorie === selectedCategory &&
                                        list.materiel === el.name
                                    )
                                  );

                                  updateFormValue('selectedType', typeNode.name);
                                  updateFormValue(
                                    'searchValue',
                                    allGroups.type.find(
                                      (ele) =>
                                        ele.id === typeNode.MasterDataEquipmentId
                                    )
                                  );
                                  updateFormValue('nodeSearchValue', typeNode);
                                }
                              }}
                              key={el.name}
                            >
                              {el.name}
                            </p>
                          ))}
                      </div>
                    </div>
                    {addDisplay.includes('Energie') &&
                      searchValue?.isConnectedToUrbanNetwork &&
                      zones.find((el) => el.IsConnectedToUrbanNetwork) && (
                        <div className="urban-network">
                          <p className="subtitle">
                            {t('APP.ADDEQUIPMENT.INDEX.PH_5E7E87', {
                              defaultValue: 'Réseau',
                            })}
                          </p>
                          <Autocomplete
                            id="checkboxes-tags-demo"
                            inputValue={urbanInput}
                            options={
                              !findParentNode(
                                nodeSearchValue,
                                allGroups.flatAllotments,
                                [],
                                []
                              )[0].details?.find(
                                (node) => node.name === 'Refroidissement'
                              )
                                ? [
                                    ...urbanNetworks.filter(
                                      (el) => el.type.trim() === 'C'
                                    ),
                                  ]
                                : [
                                    ...urbanNetworks.filter(
                                      (el) => el.type.trim() === 'F'
                                    ),
                                  ]
                            }
                            onChange={(event, newValue) => {
                              setUrbanChoice(newValue.id);
                            }}
                            size={'small'}
                            onInputChange={(event, newInputValue) => {
                              setUrbanInput(newInputValue);
                            }}
                            getOptionLabel={(option) => {
                              if (option.networkId.includes(urbanInput)) {
                                return option.networkId;
                              } else {
                                return `${option.name} - ${
                                  option.type.trim() === 'C'
                                    ? t('APP.ADDEQUIPMENT.INDEX.PH_CAA32E', {
                                        defaultValue: 'Chaud',
                                      })
                                    : t('APP.ADDEQUIPMENT.INDEX.PH_587A64', {
                                        defaultValue: 'Froid',
                                      })
                                }`;
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={t('APP.ADDEQUIPMENT.INDEX.PH_798FED', {
                                  defaultValue:
                                    'Rechercher un identifiant ou un nom de réseau',
                                })}
                                variant="outlined"
                                className="researchBar"
                              />
                            )}
                          />
                        </div>
                      )}
                  </>
                )}
              </div>
            </div>
            <div className="composant-part">
              <div className="composant-title">
                <h4>
                  {t('APP.ADDEQUIPMENT.INDEX.PH_A68F51', {
                    defaultValue: 'Informations',
                  })}
                </h4>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  className="composant-quantity"
                  style={{ padding: '1em', width: '30%' }}
                >
                  <p className="subtitle">
                    {t('APP.ADDEQUIPMENT.INDEX.PH_B0BABC', {
                      defaultValue: 'Quantité',
                    })}
                    {searchValue && searchValue.unit && ` (${searchValue.unit})`}
                  </p>

                  <Tooltip
                    title={
                      !searchValue
                        ? t(
                            'APP.ADDEQUIPMENT.INDEX.PH_DCAF2E',

                            {
                              text: addDisplay.includes('Energie')
                                ? 'énergie'
                                : 'eau',
                              defaultValue:
                                "Choisir le type d'{text} avant de saisir la quantité",
                            }
                          )
                        : t('APP.ADDEQUIPMENT.INDEX.PH_67E5B0', {
                            text: addDisplay.includes('Energie') ? 'énergie' : 'eau',
                            defaultValue:
                              "Saisir la quantité d'{text} dans l'unité demandée",
                          })
                    }
                  >
                    <Form.Control
                      style={{ width: '100%' }}
                      type="number"
                      value={selectedQuantity}
                      disabled={!searchValue}
                      onChange={(e) => setSelectedQuantity(e.target.value)}
                    />
                  </Tooltip>
                </div>
                <div style={{ padding: '1em', width: '30%' }}>
                  <p className="subtitle">
                    {t('APP.ADDEQUIPMENT.INDEX.PH_E28B0C', {
                      defaultValue: 'Libellé source (facultatif)',
                    })}
                  </p>
                  <Form.Control
                    style={{ width: '100%' }}
                    disabled={!searchValue}
                    value={customName}
                    onChange={(e) => updateFormValue('customName', e.target.value)}
                  />
                </div>
                <div style={{ padding: '1em', width: '30%' }}>
                  <p className="subtitle">
                    {t('APP.ADDEQUIPMENT.INDEX.PH_6E952F', {
                      defaultValue: 'Commentaire (facultatif)',
                    })}
                  </p>
                  <Tooltip
                    title={t('APP.ADDEQUIPMENT.INDEX.PH_C76BEC', {
                      defaultValue: 'Ajouter un commentaire',
                    })}
                    placement="top"
                  >
                    <Form.Control
                      style={{ width: '100%' }}
                      disabled={!searchValue}
                      value={commentEquipment}
                      onChange={(e) =>
                        updateFormValue('commentEquipment', e.target.value)
                      }
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          </>
        )}

      {addDisplay.includes('Forfait') && addDisplay.length === 2 && (
        <div className="composant-part">
          <Tooltip
            title={t('APP.ADDEQUIPMENT.INDEX.PH_E13340', {
              defaultValue: 'Cocher les forfaits à ajouter sur le projet',
            })}
          >
            <div className="composant-title">
              <h4>
                {t('APP.ADDEQUIPMENT.INDEX.PH_CB65A4', {
                  defaultValue: 'Forfait',
                })}
              </h4>
            </div>
          </Tooltip>
          <div className="forfait-wrapper">
            <div className="add-forfait">
              <p className="subtitle">
                {t(
                  'APP.ADDEQUIPMENT.INDEX.PH_4E597F',

                  {
                    check1: currentProject.templateType === TEMPLATE_TYPE_NAME.EPCM,
                    check2: [
                      TEMPLATE_TYPE_NAME.RE2020,
                      TEMPLATE_TYPE_NAME.NOOCO,
                    ].includes(currentProject.templateType),
                    value:
                      currentProject.templateType === TYPE_EPCM
                        ? PROJECT_TYPE_TITLE.EPCM
                        : currentProject.templateType,
                    defaultValue:
                      'Ajouter un forfait pour {check1, select, true {le(s) lot(s)} other {le(s) lot/sous-lot(s)}} {check2, select, true {RE2020} other {{value}}}',
                  }
                )}
              </p>
              <div className="forfait-choices">
                {zones
                  .filter((zone) => zone.type === 'zone')
                  .map((zone, i) => (
                    <div
                      key={zone.id}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {forfaitList
                        .sort((a, b) => a.nbRef - b.nbRef)
                        .map((el) => (
                          <div style={{ display: 'flex' }} key={el.lot}>
                            {el.forfait
                              .sort((a, b) => a.nbRef - b.nbRef)
                              .filter((f) =>
                                f.referenceType.includes(
                                  projectTypeBBCAorEPCM.includes(
                                    currentProject.templateType
                                  )
                                    ? REFERENCE_TYPE_NAME.EPCM
                                    : REFERENCE_TYPE_NAME.RE2020
                                )
                              )
                              .map((forfait) => {
                                return !forfait.list?.find(
                                  (el) => el === zone.BuildingType.name
                                ) ? (
                                  <Tooltip
                                    key={forfait.name}
                                    title={t(
                                      'APP.ADDEQUIPMENT.INDEX.PH_275EED',

                                      {
                                        name: zone.BuildingType.name,
                                        defaultValue:
                                          'Forfait indisponible pour la typologie de zone : {name}',
                                      }
                                    )}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {i === 0 ? (
                                        <p>
                                          {projectTypeBBCAorEPCM.includes(
                                            currentProject.templateType
                                          )
                                            ? el.nbRef
                                            : forfait.name.includes('Sous-lot')
                                            ? forfait.name.slice(32, 36)
                                            : forfait.name.slice(22, 25)}
                                        </p>
                                      ) : null}
                                      <span
                                        style={{
                                          cursor: !forfait.list?.find(
                                            (el) => el === zone.BuildingType.name
                                          )
                                            ? 'not-allowed'
                                            : null,
                                        }}
                                      >
                                        <NoocoCheckbox
                                          style={{
                                            padding: '5px',
                                            borderRadius: !forfait.list?.find(
                                              (el) => el === zone.BuildingType.name
                                            )
                                              ? '0'
                                              : null,
                                          }}
                                          disabled={
                                            !forfait.list?.find(
                                              (el) => el === zone.BuildingType.name
                                            )
                                          }
                                          labelStyle={{ color: 'black' }}
                                          iconStyle={{ fill: 'black' }}
                                          onChange={(e) => {
                                            handleChoiceForfait(
                                              e.target.checked,
                                              forfait,
                                              zone
                                            );
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </Tooltip>
                                ) : parsed.find(
                                    (el) =>
                                      el.MasterDataEquipmentId ===
                                        groups?.find(
                                          (el) => el.name === forfait.name
                                        ).id && el.ProjectSheet.ZoneId === zone.id
                                  ) ? (
                                  <Tooltip
                                    key={forfait.name}
                                    title={t('APP.ADDEQUIPMENT.INDEX.PH_E9F61A', {
                                      defaultValue:
                                        'Ce forfait est déjà présent sur cette zone',
                                    })}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {i === 0 ? (
                                        <p>
                                          {projectTypeBBCAorEPCM.includes(
                                            currentProject.templateType
                                          )
                                            ? el.nbRef
                                            : forfait.name.includes('Sous-lot')
                                            ? forfait.name.slice(32, 36)
                                            : forfait.name.slice(22, 25)}
                                        </p>
                                      ) : null}
                                      <Checkbox
                                        style={{
                                          padding: '5px',
                                          borderRadius: !forfait.list?.find(
                                            (el) => el === zone.BuildingType.name
                                          )
                                            ? '0'
                                            : null,
                                        }}
                                        disabled
                                        checked
                                        inputProps={{
                                          'aria-label': 'disabled checked checkbox',
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {i === 0 ? (
                                      <p>
                                        {projectTypeBBCAorEPCM.includes(
                                          currentProject.templateType
                                        )
                                          ? el.nbRef
                                          : forfait.name.includes('Sous-lot')
                                          ? forfait.name.slice(32, 36)
                                          : forfait.name.slice(22, 25)}
                                      </p>
                                    ) : null}
                                    <span
                                      style={{
                                        cursor: !forfait.list?.find(
                                          (el) => el === zone.BuildingType.name
                                        )
                                          ? 'not-allowed'
                                          : null,
                                      }}
                                    >
                                      <NoocoCheckbox
                                        id={`id${forfait.id}-${zone.id}`}
                                        style={{
                                          padding: '5px',
                                          borderRadius: !forfait.list?.find(
                                            (el) => el === zone.BuildingType.name
                                          )
                                            ? '0'
                                            : null,
                                        }}
                                        disabled={
                                          !forfait.list?.find(
                                            (el) => el === zone.BuildingType.name
                                          )
                                        }
                                        labelStyle={{ color: 'black' }}
                                        iconStyle={{ fill: 'black' }}
                                        onClick={(e) => {
                                          if (
                                            currentProject.templateType.includes(
                                              TYPE_RE2020
                                            ) ||
                                            currentProject.templateType.includes(
                                              TYPE_NOOCO
                                            )
                                          ) {
                                            const sousLot = allGroups.sousLot.find(
                                              (el) =>
                                                forfait.name.includes('Sous-lot')
                                                  ? el?.name.includes(
                                                      forfait.name.slice(32, 36)
                                                    )
                                                  : el.ReLot?.name.includes(
                                                      forfait.name.slice(22, 24)
                                                    )
                                            );

                                            if (
                                              !zone.ProjectSheets.find((el) =>
                                                forfait.name.includes('Sous-lot')
                                                  ? el.AllotmentNodes.find(
                                                      (el) => el.AllotmentId === 2
                                                    )?.name.includes(
                                                      sousLot.name.slice(0, 3)
                                                    )
                                                  : el.AllotmentNodes.find(
                                                      (el) => el.AllotmentId === 2
                                                    )?.name.includes(
                                                      sousLot?.name.slice(0, 2)
                                                    )
                                              )
                                            ) {
                                              handleChoiceForfait(
                                                e.target.checked,
                                                forfait,
                                                zone
                                              );
                                            } else {
                                              setSnackbarMessage(
                                                t(
                                                  'APP.ADDEQUIPMENT.INDEX.PH_E1E24F',

                                                  {
                                                    check1:
                                                      forfait.name.includes(
                                                        'Sous-lot'
                                                      ),
                                                    name1: sousLot.name,
                                                    name2: sousLot.ReLot.name,
                                                    defaultValue:
                                                      'Merci de supprimer les équipements / matériaux rattachés au { check1, select, true {sous-lot {name1}} other {lot {name2}}} afin de pouvoir ajouter ce forfait',
                                                  }
                                                )
                                              );

                                              const check = document.querySelector(
                                                `#id${forfait.id}-${zone.id}`
                                              );

                                              check.checked = false;
                                            }
                                          } else {
                                            const projectSheet =
                                              zone.ProjectSheets.find((el) => {
                                                return el.AllotmentNodes?.find(
                                                  (el) => el.AllotmentId === 34
                                                )?.name.includes(
                                                  forfait.name.slice(22, 24).trim()
                                                );
                                              });

                                            const potentialPoint =
                                              projectSheet?.AllotmentNodes?.find(
                                                (el) => el.AllotmentId === 34
                                              )?.name[
                                                projectSheet?.AllotmentNodes?.find(
                                                  (el) => el.AllotmentId === 34
                                                )?.name.indexOf(
                                                  forfait?.name.slice(22, 24).trim()
                                                ) - 1
                                              ];

                                            // Change that soon as possible.

                                            if (
                                              !projectSheet ||
                                              (projectSheet &&
                                                potentialPoint === '.')
                                            ) {
                                              handleChoiceForfait(
                                                e.target.checked,
                                                forfait,
                                                zone
                                              );
                                            } else {
                                              setSnackbarMessage(
                                                `Merci de supprimer les équipements / matériaux rattachés au lot ${forfait.name.slice(
                                                  22
                                                )} afin de pouvoir ajouter ce forfait`
                                              );

                                              t(
                                                'APP.ADDEQUIPMENT.INDEX.PH_388ABB',

                                                {
                                                  value: forfait.name.slice(22),
                                                  defaultValue:
                                                    'Merci de supprimer les équipements / matériaux rattachés au lot {value} afin de pouvoir ajouter ce forfait',
                                                }
                                              );

                                              const check = document.querySelector(
                                                `#id${forfait.id}-${zone.id}`
                                              );

                                              check.checked = false;
                                            }
                                          }
                                        }}
                                      />
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        ))}

                      <p
                        style={{
                          paddingTop: i === 0 ? '18px' : null,
                          marginLeft: '1rem',
                        }}
                        className="subtitle"
                      >
                        {`${zones.find((el) => el.id === zone.ParentId).name} - ${
                          zone.name
                        }`}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="forfait-details">
              {forfaitList.map((el) => (
                <div key={el.lot} className="lot-details">
                  <p className="subtitle" style={{ marginBottom: '1rem' }}>
                    {el.lot}
                  </p>
                  <ul>
                    {el.forfait
                      .sort((a, b) => a.nbRef - b.nbRef)
                      .filter((f) =>
                        f.referenceType.includes(
                          currentProject.templateType === TYPE_NOOCO
                            ? REFERENCE_TYPE_NAME.RE2020
                            : projectTypeBBCAorEPCM.includes(
                                currentProject.templateType
                              )
                            ? REFERENCE_TYPE_NAME.EPCM
                            : REFERENCE_TYPE_NAME.RE2020
                        )
                      )
                      .map((list) => (
                        <li
                          style={{
                            fontSize:
                              window.screen.availHeight > 950 ? null : '0.85rem',
                          }}
                          key={list.name}
                        >
                          {list.name.includes('Sous-lot')
                            ? list.name.slice(32)
                            : list.name.slice(22)}
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {addDisplay.includes('Fiche configurée') && addDisplay.length === 2 && (
        <div
          className="composant-part"
          style={{ height: `${window.screen.availHeight - 500}px` }}
        >
          <div className="composant-title">
            <h4>
              {t('APP.ADDEQUIPMENT.INDEX.PH_689688', {
                defaultValue: "Ajout d'une fiche configurée",
              })}
            </h4>
          </div>
          <p className="subtitle" style={{ padding: '1em' }}>
            {t('APP.ADDEQUIPMENT.INDEX.PH_87E9B0', {
              defaultValue: 'Choisir un fichier',
            })}
          </p>
          <div className="config-wrapper-choice">
            <div className="config-choice">
              <img
                onClick={() => {
                  const trigger = document.querySelector('label');

                  trigger.click();
                }}
                style={{ width: '60px', marginTop: '12px' }}
                src={xmlPic}
                alt
              />

              <label style={{ fontSize: '1rem', marginTop: '30px' }} htmlFor="file">
                {t('APP.ADDEQUIPMENT.INDEX.PH_5A25A4', {
                  defaultValue: 'Importer un xml',
                })}
              </label>
              <input
                type="file"
                name="file"
                id="file"
                accept="text/xml,application/xml,.xml"
                className="inputfile"
                key={xmlFile}
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    const validTypes = ['text/xml', 'application/xml'];
                    const extension = file.name.split('.').pop().toLowerCase();

                    if (validTypes.includes(file.type) || extension === 'xml') {
                      setSelectedSheet(undefined);
                      setXmlFile(file);
                    } else {
                      setSnackbarMessage(
                        t('APP.ADDEQUIPMENT.INDEX.FC.IMPORT.ERROR', {
                          defaultValue:
                            'Le type de fichier n’est pas correct. Merci de charger uniquement des fichiers de type XML (extension .xml)',
                        })
                      );
                      e.target.value = '';
                    }
                  }
                }}
              />
              {xmlFile && (
                <p className="xmlName">
                  {xmlFile.name}{' '}
                  <HighlightOffIcon
                    style={{ marginLeft: '10px' }}
                    onClick={() => setXmlFile()}
                  />
                </p>
              )}
            </div>
            <div
              className="config-choice edec"
              onClick={() => {
                if (edecFiles && edecUsername && edecPassword) {
                  changeValueEquipments('addDisplay', [
                    '1',
                    'Fiche configurée',
                    'Connexion',
                    'Sélection',
                  ]);
                } else {
                  changeValueEquipments('addDisplay', [
                    '1',
                    'Fiche configurée',
                    'Connexion',
                  ]);
                }
              }}
            >
              <img src={edecPic} alt />
              <p style={{ fontSize: '1rem', marginTop: '30px' }}>
                {t('APP.ADDEQUIPMENT.INDEX.PH_7F1ADE', {
                  defaultValue: 'Importer depuis EDEC',
                })}
              </p>
            </div>
          </div>
        </div>
      )}
      {((addDisplay.includes('Fiche configurée') &&
        !addDisplay.includes('Connexion') &&
        addDisplay.length === 3) ||
        (addDisplay.includes('Rangement') &&
          addDisplay.length === 5 &&
          addDisplay.includes('Connexion'))) && (
        <div className="composant-part">
          <div className="composant-title">
            <h4>
              {t('APP.ADDEQUIPMENT.INDEX.PH_1A6B8B', {
                defaultValue: 'Rangement de la fiche configurée',
              })}
            </h4>
          </div>
          <div className="searchBar">
            <SearchBar
              searchValue={searchValue}
              inputValue={inputValue}
              groups={[
                ...groups.filter((el) => {
                  return el.isConfiguredSheetImportAllowed;
                }),
              ]}
              handleSearchValue={handleSearchValue}
              handleInputValue={handleInputValue}
              currentProject={currentProject}
              onChange={onChange}
              classicBar={true}
            />
          </div>
          <div style={{ margin: '1em' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FavoritesIcons screen={'xmlScreen'} searchBarValue={inputValue} />
              <p style={{ marginLeft: '10px' }}>
                {t('APP.ADDEQUIPMENT.INDEX.PH_535906', {
                  defaultValue: 'Ajouter cette fiche dans vos favoris',
                })}
              </p>
            </div>
            {addXmlFavorite && (
              <div style={{ padding: '1em', width: '30%' }}>
                <p className="subtitle">
                  {t('APP.ADDEQUIPMENT.INDEX.PH_E47CAF', {
                    defaultValue: 'Renommer la fiche (facultatif)',
                  })}
                </p>
                <Form.Control
                  style={{ width: '100%' }}
                  disabled={!searchValue}
                  value={customXmlName}
                  onChange={(e) =>
                    dispatch(updateFavoritesValue('customXmlName', e.target.value))
                  }
                />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              className="composant-quantity"
              style={{ padding: '1em', width: '30%' }}
            >
              <p className="subtitle">
                Quantité{' '}
                {t('APP.ADDEQUIPMENT.INDEX.FC.QUANTITY', {
                  defaultValue: "(se référer à l'UF de votre fiche configurée)",
                })}
              </p>
              <Form.Control
                style={{ width: '100%' }}
                type="number"
                value={selectedQuantity}
                disabled={!searchValue}
                onChange={(e) => setSelectedQuantity(e.target.value)}
              />
            </div>
            <div style={{ padding: '1em', width: '30%' }}>
              <p className="subtitle">
                {t('APP.ADDEQUIPMENT.INDEX.PH_31F92D', {
                  defaultValue: 'Libellé source (facultatif)',
                })}
              </p>
              <Form.Control
                style={{ width: '100%' }}
                disabled={!searchValue}
                value={customName}
                onChange={(e) => updateFormValue('customName', e.target.value)}
              />
            </div>

            <div style={{ padding: '1em', width: '30%' }}>
              <p className="subtitle">
                {t('APP.ADDEQUIPMENT.INDEX.PH_F8EF7E', {
                  defaultValue: 'Commentaire (facultatif)',
                })}
              </p>
              <Tooltip
                title={t('APP.ADDEQUIPMENT.INDEX.PH_6F1893', {
                  defaultValue: 'Ajouter un commentaire',
                })}
                placement="top"
              >
                <Form.Control
                  style={{ width: '100%' }}
                  disabled={!searchValue}
                  onBlur={(e) => updateFormValue('commentEquipment', e.target.value)}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      )}

      {addDisplay.includes('Connexion') && addDisplay.length === 3 && (
        <div className="composant-part">
          <div className="composant-title">
            <h4>
              {t('APP.ADDEQUIPMENT.INDEX.PH_A95B53', {
                defaultValue: 'Connexion EDEC',
              })}
            </h4>
          </div>
          <div className="login-edec">
            <p style={{ color: '#2B3674', marginBottom: '10px' }}>
              {t('APP.ADDEQUIPMENT.INDEX.PH_F896AC', {
                defaultValue:
                  'Veuillez renseigner les informations de votre compte EDEC',
              })}
            </p>
            <p className="subtitle">
              {t('APP.ADDEQUIPMENT.INDEX.PH_9906A8', {
                defaultValue: 'Utilisateur ou email',
              })}
            </p>
            <Form.Control
              style={{ width: '30%' }}
              type="text"
              placeholder={t('APP.ADDEQUIPMENT.INDEX.PH_78025B', {
                defaultValue: 'Saisir votre identifiant',
              })}
              onChange={(e) => changeValueEquipments('edecUsername', e.target.value)}
            />
          </div>
          <div className="password-edec">
            <p className="subtitle">
              {t('APP.ADDEQUIPMENT.INDEX.PH_D9503E', {
                defaultValue: 'Mot de passe',
              })}
            </p>
            <Form.Control
              style={{ width: '30%' }}
              type="password"
              placeholder={t('APP.ADDEQUIPMENT.INDEX.PH_27CD53', {
                defaultValue: 'Saisir votre mot de passe',
              })}
              onChange={(e) => changeValueEquipments('edecPassword', e.target.value)}
            />

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://cloudedec.edibatec.org/index.php/apps/registration/"
              className="password-link"
            >
              {t('APP.ADDEQUIPMENT.INDEX.PH_7FE5EE', {
                defaultValue: 'Mot de passe oublié/Créer un compte',
              })}
            </a>
          </div>
        </div>
      )}
      {addDisplay.includes('Connexion') && edecFiles && addDisplay.length === 4 && (
        <div className="wrapper-table">
          <div className="config-names">
            {edecNames &&
              edecNames.map((el) => (
                <Button
                  style={{
                    fontSize: window.screen.availHeight > 950 ? '0.9rem' : null,
                  }}
                  onClick={() => {
                    if (!filterEdec.find((fil) => fil === el)) {
                      changeValueEquipments('filterEdec', [...filterEdec, el]);
                    } else {
                      changeValueEquipments('filterEdec', [
                        ...filterEdec.filter((fil) => fil !== el),
                      ]);
                    }
                  }}
                  className={
                    filterEdec?.includes(el) ? 'golden-button' : 'grey-button'
                  }
                  text={el}
                />
              ))}
          </div>
          <div>
            <Table
              locale={locale}
              components={vt}
              rowSelection={rowSelection}
              rowKey={(record) => record.key}
              columns={columns}
              dataSource={filterData || edecFiles}
              pagination={false}
              style={{ background: '#F4F7FC', width: widthTable }}
              showSorterTooltip={false}
              row
              scroll={{
                y:
                  window.screen.availHeight < 950
                    ? window.screen.availHeight - 650
                    : window.screen.availHeight - 570,
              }}
            />
          </div>
        </div>
      )}
      {addDisplay.includes('Composant vide') && addDisplay.length === 2 && (
        <>
          <ReemployCheck
            setSelectedSheet={setSelectedSheet}
            setSelectedSheetObj={setSelectedSheetObj}
            setReEmploy={setReEmploy}
            reEmploy={reEmploy}
          />
          <div className="composant-part" style={{ padding: '1em' }}>
            <ComposantVideForm
              composantVideForm={composantVideForm}
              reEmploy={reEmploy}
              addPart={true}
              changeValueEquipments={changeValueEquipments}
              iniesNomenclature={iniesNomenclature}
            />
          </div>
        </>
      )}
      {/* main modeling */}
      {addDisplay.includes('modeling') && !addDisplay.includes('5') && (
        <>
          <div className="composant-part">
            <div className="composant-title">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4>{modelingStepTitle()}</h4>
                {modelingStepTitleTooltip() && (
                  <Tooltip title={modelingStepTitleTooltip()}>
                    <div>
                      <img src={informationPic} alt="" />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
            <FormProvider {...caracteristicHookForm}>
              {/* <FormProvider {...testHookForm}> */}
              <ModelingEquipment
                carbonWeightFormInstance={carbonWeightFormInstance}
              />
              {/* </FormProvider> */}
            </FormProvider>
          </div>
        </>
      )}

      {/* footer buttons */}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {/* Close Button */}
        <Tooltip
          title={t('APP.ADDEQUIPMENT.INDEX.PH_A60BC5', {
            defaultValue: "Abandonner l'ajout de contributeurs",
          })}
        >
          <Button
            style={{
              fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
              marginRight: '10px',
            }}
            className="close-button"
            onClick={() => {
              changeValueEquipments('addDisplay', null);
              setSelectedQuantity();
              updateFormValue('selectedLot', null);
              updateFormValue('selectedFonction', null);
              updateFormValue('selectedCategorie', null);
              updateFormValue('selectedMaterial', null);
              updateFormValue('selectedType', null);
              updateFormValue('searchValue', null);
              updateFormValue('commentEquipment', '');
              updateFormValue('nodeSearchValue', null);

              setSearchValue('');
              setInputValue('');

              setSubEquipQuantity(null);
              setSelectedSubEquip(null);

              setSelectedSheet(null);
              setSheetsToShow(null);
              setSelectedSheetObj(null);
              setSelectedSheet(null);
              setSelectedBrand(null);
              changeValueEquipments('iniesAdvanceLink', null);
              changeValueEquipments('selectedRowKeys', []);
              changeValueEquipments('edecFiles', null);
              changeValueEquipments('datasForfait', []);
              changeValueEquipments('edecChoice', null);
              changeValueEquipments('forfaitList', []);
              changeValueEquipments('composantVideForm', {});
              changeValueEquipments('datasinies', []);

              setUrbanChoice();

              setXmlFile();

              if (equipmentWaiting > 0) {
                changeValueEquipments('isLoadingProject', true);
                fetchProjectById(currentProject.id, currentIndicator.id);

                // callback();
                setEquipmentWaiting(0);
              }
            }}
            text="Fermer"
          />
        </Tooltip>

        {/* Next Buttons */}
        {displaySpinner &&
          (addDisplay.includes('Connexion') ||
            (zones.length === 2 &&
              !addDisplay.includes('Energie') &&
              !addDisplay.includes('Eau') &&
              !addDisplay.includes('2') &&
              !addDisplay.includes('modeling'))) && (
            <div
              style={{
                width: '96px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Loader
                variant="indeterminate"
                disableShrink
                style={{ color: '#73b7ec' }}
                size={25}
                thickness={4}
              />
            </div>
          )}

        {addDisplay[addDisplay.length - 1] === 'Energie' &&
          typeProjectForSimplifiedEnergy.includes(currentProject.templateType) && (
            <Button
              onClick={() => {
                if (zones.filter((zone) => zone.type === 'zone').length > 2) {
                  changeValueEquipments('addDisplay', [...addDisplay, '3']);
                } else {
                  handleCreateEquipment();
                }
              }}
              className="purple-button"
              disabled={checkSimplifiedEnergyForm(simplifiedEnergyLines)}
              text={
                zones.length > 2
                  ? t('APP.ADDEQUIPMENT.INDEX.DISPLAY.ENERGIE.CONTINUE', {
                      defaultValue: 'Continuer',
                    })
                  : t('APP.ADDEQUIPMENT.INDEX.DISPLAY.ENERGIE.CONFIRM', {
                      defaultValue: 'Confirmer',
                    })
              }
            />
          )}

        {addDisplay?.includes('Connexion') &&
          !displaySpinner &&
          !addDisplay.includes('Rangement') && (
            <Tooltip
              title={t('APP.ADDEQUIPMENT.INDEX.PH_136173', {
                defaultValue: "Continuer l'ajout de contributeurs",
              })}
            >
              <Button
                style={{
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                }}
                disabled={
                  addDisplay.length === 3
                    ? !edecUsername || !edecPassword
                    : !edecChoice
                    ? true
                    : null
                }
                className="purple-button"
                onClick={() => {
                  if (addDisplay?.includes('Connexion') && addDisplay.length === 3) {
                    changeValueEquipments('displaySpinner', true);
                    loginEdec();
                  } else {
                    changeValueEquipments('addDisplay', [
                      ...addDisplay,
                      'Rangement',
                    ]);
                  }
                }}
                text={t('APP.ADDEQUIPMENT.INDEX.PH_EEA968', {
                  defaultValue: 'Continuer',
                })}
              />
            </Tooltip>
          )}

        {/* //TODO change condition */}
        {((addDisplay.includes('modeling') &&
          !addDisplay.includes('5') &&
          !addDisplay.includes('4')) ||
          (!isCurrentDestinationFilledForModeling &&
            addDisplay.includes('4') &&
            addDisplay.includes('modeling') &&
            !addDisplay.includes('5'))) && (
          <Tooltip
            title={t('APP.ADDEQUIPMENT.INDEX.PH_23E8FB', {
              defaultValue: "Continuer l'ajout de contributeurs",
            })}
          >
            <Button
              style={{
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                marginRight: '10px',
              }}
              disabled={
                (addDisplay.includes('2') && !isStepTwoCaracModelingCompleted()) ||
                (addDisplay.includes('3') &&
                  !isStepThreeChoiceModelingCompleted()) ||
                (addDisplay.includes('4') && !isStepFourModelingCompleted())
              }
              className="purple-button"
              onClick={() => {
                if (!addDisplay.includes('3')) {
                  changeValueEquipments('addDisplay', ['1', '2', '3', 'modeling']);
                } else if (!addDisplay.includes('4')) {
                  changeValueEquipments('addDisplay', [
                    '1',
                    '2',
                    '3',
                    '4',
                    'modeling',
                  ]);
                } else if (!addDisplay.includes('5')) {
                  changeValueEquipments('addDisplay', [
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    'modeling',
                  ]);
                }
              }}
              text={t('APP.ADDEQUIPMENT.INDEX.PH_3CD6CC', {
                defaultValue: 'Continuer',
              })}
            />
          </Tooltip>
        )}
        {selectedSheetObj && addDisplay.length === 1 && !displaySpinner && (
          <Button
            onClick={() => {
              if (addDisplay.length === 1 && selectedSheetObj) {
                if (searchValue?.hasFluid) {
                  changeValueEquipments('addDisplay', ['1', 'Choix fiche', '2']);
                } else {
                  if (
                    searchValue.lot === 'Energie' &&
                    currentProject.isRset &&
                    !zones.find((el) => el.type === 'buildingSite')
                  ) {
                    return setOpenSnackbar(
                      t('APP.ADDEQUIPMENT.INDEX.PH_EC87AC', {
                        defaultValue:
                          "Vous ne pouvez pas ajouter d'énergie ailleurs que dans le chantier si vous avez importé un RSET",
                      })
                    );
                  }
                  if (
                    searchValue.lot === 'Terre' &&
                    !zones.find((el) => el.type === 'buildingSite')
                  ) {
                    setOpenSnackbar(
                      t('APP.ADDEQUIPMENT.INDEX.PH_DCA4D8', {
                        defaultValue:
                          'Un équipement du lot Terre ne peut être ajouté que dans le chantier',
                      })
                    );

                    return;
                  }
                  if (
                    searchValue.categorie === 'Réseaux de chaleur' &&
                    !zones.find((el) => el.IsConnectedToUrbanNetwork)
                  ) {
                    setOpenSnackbar(
                      t('APP.ADDEQUIPMENT.INDEX.PH_24D882', {
                        defaultValue:
                          "Cet équipement ne peut pas être ajouté car aucune zone n'est reliée à un réseau de chaleur",
                      })
                    );

                    return;
                  }

                  if (zones.length === 2) {
                    changeValueEquipments('displaySpinner', true);
                    handleCreateEquipment();
                  } else {
                    changeValueEquipments('addDisplay', [
                      '1',
                      'Choix fiche',
                      '2',
                      '3',
                    ]);
                  }
                }
              } else if (zones.length === 2) {
                changeValueEquipments('displaySpinner', true);
                handleCreateEquipment();
              }
            }}
            className="purple-button"
            loading={displaySpinner}
            text={t('APP.ADDEQUIPMENT.INDEX.DISPLAY.PURPLE_BUTTON', {
              defaultValue: 'Confirmer',
            })}
            disabled={!selectedQuantity}
          />
        )}
        {/* Becareful, You enter into the darkness */}
        {(((!selectedSheetObj || (selectedSheetObj && addDisplay?.length > 1)) &&
          (addDisplay.includes('1') || addDisplay.includes('2')) &&
          addDisplay.length < 3 &&
          !addDisplay.includes('Forfait') &&
          !addDisplay.includes('Energie')) ||
          (addDisplay.includes('Energie') &&
            !typeProjectForSimplifiedEnergy.includes(currentProject.templateType) &&
            !addDisplay.includes('3')) ||
          (addDisplay.includes('Eau') && addDisplay.length === 3) ||
          (addDisplay.includes('Fiche configurée') &&
            !addDisplay.includes('Connexion') &&
            addDisplay.length < 5) ||
          (addDisplay.includes('Choix fiche') &&
            ((addDisplay.length < 3 && !addDisplay.includes('2')) ||
              (addDisplay.length < 4 && addDisplay.includes('2')))) ||
          (addDisplay.includes('Rangement') &&
            addDisplay.includes('Connexion') &&
            addDisplay.length < 7)) &&
          (!displaySpinner &&
          !(
            zones.length === 2 &&
            addDisplay.includes('1') &&
            addDisplay.includes('2') &&
            addDisplay.length === 2
          ) &&
          !addDisplay.includes('modeling') &&
          !addDisplay.includes('3') ? (
            <Tooltip
              title={t('APP.ADDEQUIPMENT.INDEX.PH_3B9A21', {
                defaultValue: "Continuer l'ajout de contributeurs",
              })}
            >
              <Button
                style={{
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                  marginRight: '10px',
                }}
                onClick={() => {
                  changeValueEquipments('displaySpinner', true);

                  if (
                    addDisplay.length === 1 ||
                    ((addDisplay.includes('Energie') ||
                      addDisplay.includes('Eau')) &&
                      addDisplay.length === 2) ||
                    (addDisplay.includes('Rangement') &&
                      (addDisplay.length === 3 || addDisplay.length === 5))
                  ) {
                    handleSubmit();
                  } else if (
                    addDisplay.includes('Fiche configurée') &&
                    !addDisplay.includes('Connexion')
                  ) {
                    if (addDisplay.length === 2) {
                      changeValueEquipments('addDisplay', [
                        '1',
                        'Fiche configurée',
                        'Rangement',
                      ]);
                    }

                    if (addDisplay.includes('2')) {
                      if (zones.length === 2) {
                        handleCreateEquipment();
                      } else {
                        changeValueEquipments('addDisplay', [
                          '1',
                          'Fiche configurée',
                          'Rangement',
                          '2',
                          '3',
                        ]);
                      }
                    }
                  } else if (
                    addDisplay.length === 2 &&
                    !addDisplay.includes('Composant vide')
                  ) {
                    changeValueEquipments('addDisplay', ['1', '2', '3']);
                  } else if (
                    ((addDisplay.includes('Energie') ||
                      addDisplay.includes('Eau')) &&
                      addDisplay.length === 3) ||
                    (addDisplay.includes('Connexion') && addDisplay.length === 6) ||
                    (addDisplay.includes('Composant vide') &&
                      addDisplay.length === 2)
                  ) {
                    if (addDisplay.includes('Composant vide') && !reEmploy) {
                      sendRequestDed();
                    }

                    if (zones.length === 2) {
                      handleCreateEquipment();
                    } else {
                      changeValueEquipments('addDisplay', [...addDisplay, '3']);
                    }
                  } else if (
                    selectedSheetObj &&
                    addDisplay.includes('Choix fiche') &&
                    zones.length > 2
                  ) {
                    changeValueEquipments('addDisplay', [
                      '1',
                      'Choix fiche',
                      '2',
                      '3',
                    ]);
                  } else {
                    changeValueEquipments('displaySpinner', true);

                    handleCreateEquipment();
                  }
                }}
                disabled={
                  refCaracsLength === userCaracs.length &&
                  addDisplay.includes('2') &&
                  addDisplay.length === 2
                    ? false
                    : searchValue?.hasFluid &&
                      addDisplay.includes('2') &&
                      !addDisplay.includes('Choix fiche') &&
                      !checkIfDedHasFluid() &&
                      (!selectedSubEquip || !subEquipQuantity) &&
                      !selectedSheetObj
                    ? true
                    : addDisplay.includes('2') &&
                      addDisplay.length === 2 &&
                      searchValue.name?.includes('photovoltaïque') &&
                      currentProject.isRset &&
                      userCaracs.length === 1
                    ? false
                    : addDisplay.includes('Composant vide')
                    ? reEmploy
                      ? Object.keys(composantVideForm).length < 4
                      : Object.keys(composantVideForm).length < 8
                    : addDisplay.includes('Fiche configurée') &&
                      addDisplay.length === 2
                    ? !xmlFile
                    : addDisplay.includes('Fiche configurée') &&
                      addDisplay.length === 4
                    ? refCaracsLength !== userCaracs.length
                    : (addDisplay.includes('2') && addDisplay.length < 3) ||
                      (addDisplay.includes('Energie') &&
                        addDisplay.includes('2') &&
                        refCaracsLength !== userCaracs.length)
                    ? refCaracsLength !== userCaracs.length
                    : !searchValue || !selectedQuantity
                }
                text={t('APP.ADDEQUIPMENT.INDEX.PH_985B44', {
                  defaultValue: 'Continuer',
                })}
                className="purple-button"
              />
            </Tooltip>
          ) : null)}

        {/* Confirm and continues Button Container */}
        {(addDisplay.includes('3') ||
          (zones.length - nbZoneBuildingSite === 2 &&
            addDisplay.length === 2 &&
            noCarac &&
            !isOldBuildingSiteInProject) ||
          addDisplay.includes('Forfait') ||
          (zones.length - nbZoneBuildingSite === 2 &&
            !isOldBuildingSiteInProject &&
            addDisplay.includes('1') &&
            addDisplay.includes('2') &&
            addDisplay.length === 2) ||
          (zones.length === 2 &&
            addDisplay.includes('1') &&
            addDisplay.includes('2') &&
            addDisplay.length === 2) ||
          (zones.length === 2 &&
            addDisplay.includes('2') &&
            (addDisplay.includes('Eau') || addDisplay.includes('Energie'))) ||
          (addDisplay.includes('3') && addDisplay.includes('Connexion'))) && (
          <>
            {/* Confirm and continues Button */}
            {/* {!addDisplay.includes('Forfait') && !addDisplay.includes('modeling') && !addDisplay.includes('Connexion') && !displaySpinner && (
            <Tooltip title="Confirmer l'ajout du contributeur et en ajouter un autre rapidement">
            <Button
            style={{
              fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem', marginRight: "10px",
              // background: 
              // (searchValue.name?.includes('photovolt') && userCaracs.find((el) => el.name.includes('Technologie')) && currentProject.isRset) ? 'black' :
              // (addDisplay.includes('Forfait') && !datasForfait.find((el) => el.check === true) ||
              // (addDisplay.includes('Energie') && addDisplay.includes('2') && (refCaracsLength !== userCaracs.length)) ||
              // (refCaracsLength !== userCaracs.length && zones.length === 2) ||
              // ((datasForfait.find((el) => el?.hasFluid)) && addDisplay.includes('2') && !addDisplay.includes('Choix fiche') && !checkIfDedHasFluid() && (!selectedSubEquip ||
              // !subEquipQuantity) && !selectedSheetObj)) ||
              // (zones.length - nbZoneBuildingSite === 2 && addDisplay.length === 2 && noCarac && !isOldBuildingSiteInProject) ||
              // (zones.length - nbZoneBuildingSite === 2 && !isOldBuildingSiteInProject && addDisplay.includes('1') && addDisplay.includes('2') && addDisplay.length === 2) && (refCaracsLength !== userCaracs.length ) ? '#C9C9C9' : 
              // isCurrentDestinationFilled ? 'black' : (!currentDestination && !addDisplay.includes('Forfait')) ? '#C9C9C9' : 'black'
             }}
            onClick={() => {
               handleCreateEquipment(true);
              setNoCarac(false);
              setEquipmentWaiting(equipmentWaiting + 1);
              changeValueEquipments('displaySpinner', true);
              }}
             // disabled={
            //   isCurrentDestinationFilled ? false :
            //   (!currentDestination && !addDisplay.includes('Forfait')) ||
            //   (addDisplay.includes('Forfait') && !datasForfait.find((el) => el.check === true) ||
            //   (addDisplay.includes('Energie') && addDisplay.includes('2') && (refCaracsLength !== userCaracs.length)) ||
            //   (refCaracsLength !== userCaracs.length && zones.length === 2) ||
            //   ((datasForfait.find((el) => el?.hasFluid)) && !addDisplay.includes('Choix fiche') && addDisplay.includes('2') && !checkIfDedHasFluid() && (!selectedSubEquip ||
            //   !subEquipQuantity) && !selectedSheetObj)) ||
            //   (zones.length - nbZoneBuildingSite === 2 && addDisplay.length === 2 && noCarac && !isOldBuildingSiteInProject) ||
            //   (zones.length - nbZoneBuildingSite === 2 && !isOldBuildingSiteInProject && addDisplay.includes('1') && addDisplay.includes('2') && addDisplay.length === 2) && (refCaracsLength !== userCaracs.length )
            // }
            disabled={
              (searchValue?.name?.includes('photovolt') && userCaracs.find((el) => el.name.includes('Technologie')) && currentProject.isRset) ? false :
                (addDisplay.includes('Forfait') && !datasForfait.find((el) => el.check === true) ||
                  (addDisplay.includes('Energie') && addDisplay.includes('2') && (refCaracsLength !== userCaracs.length)) ||
                  (refCaracsLength !== userCaracs.length && zones.length === 2) ||
                  ((datasForfait.find((el) => el?.hasFluid)) && addDisplay.includes('2') && !addDisplay.includes('Choix fiche') && !checkIfDedHasFluid() && (!selectedSubEquip ||
                    !subEquipQuantity) && !selectedSheetObj)) ||
                  (zones.length - nbZoneBuildingSite === 2 && addDisplay.length === 2 && noCarac && !isOldBuildingSiteInProject) ||
                  (zones.length - nbZoneBuildingSite === 2 && !isOldBuildingSiteInProject && addDisplay.includes('1') && addDisplay.includes('2') && addDisplay.length === 2) && (refCaracsLength !== userCaracs.length) ? true :
                  isCurrentDestinationFilled ? false : (!currentDestination && !addDisplay.includes('Forfait')) ? true : false
            }
            className="purple-button"
             text="Confirmer et ajouter autre"
            />
            </Tooltip>
            )} */}

            {/* Loader */}
            {displaySpinner && (
              <div
                style={{
                  width: '96px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Loader
                  variant="indeterminate"
                  disableShrink
                  style={{
                    color: '#73b7ec',
                    margin: 'auto',
                    display: 'inline-block',
                  }}
                  size={25}
                  thickness={4}
                />
              </div>
            )}

            {/* Confirm Button */}
            {!displaySpinner &&
              ((addDisplay.includes('3') && !addDisplay.includes('modeling')) ||
                (isCurrentDestinationFilled &&
                  !isOldBuildingSiteInProject &&
                  addDisplay.length === 2) ||
                (zones.length === 2 &&
                  addDisplay.includes('1') &&
                  addDisplay.includes('2') &&
                  addDisplay.length === 2) ||
                isConfirmButtonModelingVisible() ||
                addDisplay[addDisplay.length - 1] === 'Forfait' ||
                (addDisplay.includes('Forfait') && addDisplay.includes('2'))) && (
                <Button
                  style={{
                    fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    if (
                      datasForfait.find((el) => el?.hasFluid) &&
                      addDisplay.length === 2
                    ) {
                      changeValueEquipments('addDisplay', ['1', 'Forfait', '2']);
                    } else {
                      changeValueEquipments('displaySpinner', true);
                      handleCreateEquipment();
                    }
                  }}
                  disabled={
                    !searchValue?.name?.includes('photovolt') &&
                    userCaracs.find((el) => el.name.includes('Technologie')) &&
                    currentProject.isRset
                      ? false
                      : !isConfirmModelingButtonCompleted() &&
                        addDisplay.includes('modeling')
                      ? true
                      : (addDisplay.includes('Forfait') &&
                          !datasForfait.find((el) => el.check === true)) ||
                        (addDisplay.includes('Energie') &&
                          addDisplay.includes('2') &&
                          refCaracsLength !== userCaracs.length) ||
                        (refCaracsLength !== userCaracs.length &&
                          zones.length === 2 &&
                          !currentProject.isRset) ||
                        (datasForfait.find((el) => el?.hasFluid) &&
                          addDisplay.includes('2') &&
                          !addDisplay.includes('Choix fiche') &&
                          !checkIfDedHasFluid() &&
                          (fluidsData.length === 0 || fluidsData.length > 0
                            ? fluidsData.find((f) => f.selectedFluid === null) ||
                              fluidsData.find((f) => f.fluidQuantity === null)
                            : (!selectedSubEquip || !subEquipQuantity) &&
                              !selectedSheetObj)) ||
                        (zones.length - nbZoneBuildingSite === 2 &&
                          addDisplay.length === 2 &&
                          noCarac &&
                          !isOldBuildingSiteInProject) ||
                        (zones.length - nbZoneBuildingSite === 2 &&
                          !isOldBuildingSiteInProject &&
                          addDisplay.includes('1') &&
                          addDisplay.includes('2') &&
                          addDisplay.length === 2 &&
                          refCaracsLength !== userCaracs.length)
                      ? true
                      : isCurrentDestinationFilled
                      ? false
                      : !currentDestination && !addDisplay.includes('Forfait')
                      ? true
                      : false
                  }
                  className="purple-button"
                  text={t('APP.ADDEQUIPMENT.INDEX.PH_1644F3', {
                    defaultValue: 'Confirmer',
                  })}
                />
              )}
          </>
        )}
      </div>
    </div>
  );
};
export default AddEquipment;
