import { Category, Feature } from '../app/models/HelpDTO';

export const FEATURE_OPEN_MODAL = 'FEATURE_OPEN_MODAL';
export const FEATURE_CLOSE_MODAL = 'FEATURE_CLOSE_MODAL';
export const FEATURE_UPDATE = 'FEATURES_UPDATE';
export const NEW_FEATURE_UPDATE = 'NEW_FEATURE_UPDATE';
export const EDIT_FEATURE_UPDATE = 'EDIT_FEATURE_UPDATE';
export const FEATURE_SELECTED = 'FEATURE_SELECTED';
export const UPDATE_IS_NEW_FEATURE = 'UPDATE_IS_NEW_FEATURE';
export const FEATURE_CREATE_NEW = 'FEATURE_CREATE_NEW';
export const FEATURE_MODIFY_NEW = 'FEATURE_MODIFY_NEW';
export const FEATURE_MODIFY_NEW_TITLE = 'FEATURE_MODIFY_NEW_TITLE';
export const FEATURE_GET_ALL = 'FEATURE_GET_ALL';
export const FEATURE_MODIFY = 'FEATURE_MODIFY';
export const FEATURE_MODIFY_TITLE = 'FEATURE_MODIFY_TITLE';
export const FEATURE_EDIT_MODE_ON = 'FEATURE_EDIT_MODE_ON';
export const FEATURE_CREATE_MODE_ON = 'FEATURE_CREATE_MODE_ON';
export const FEATURE_EDIT_MODE_OFF = 'FEATURE_EDIT_MODE_OFF';
export const RELEASE_TOGGLE_NEW = 'RELEASE_TOGGLE_NEW';
export const RELEASE_NEW_EDIT = 'RELEASE_NEW_EDIT';
export const RELEASE_TOGGLE_VISIBLE = 'RELEASE_TOGGLE_VISIBLE';
export const RELEASE_VERIF = 'RELEASE_VERIF';
export const RELEASE_VALIDATE_TEST = 'RELEASE_VALIDATE_TEST';
export const RELEASE_CANCEL = 'RELEASE_CANCEL';
export const RELEASES_UPDATE = 'RELEASES_UPDATE';
export const RELEASES_EDIT = 'RELEASES_EDIT';
export const RELEASES_EDIT_NUMBER = 'RELEASES_EDIT_NUMBER';
export const RELEASE_EDIT_TEST = 'RELEASE_EDIT_TEST';
export const RELEASES_EDIT_ID = 'RELEASES_EDIT_ID';
export const RELEASE_EDIT_TEMP = 'RELEASE_EDIT_TEMP';
export const RELEASE_EDIT = 'RELEASE_EDIT';
export const RELEASE_CREATE_SUCCES = 'RELEASE_CREATE_SUCCES';
export const RELEASE_SETTINGS_ACCORDION = 'RELEASE_SETTINGS_ACCORDION';
export const RELEASE_SETTINGS_LOADER = 'RELEASE_SETTINGS_LOADER';
export const UPDATE_HELP_NEW_RELEASE_NOTIFICATION =
  'UPDATE_HELP_NEW_RELEASE_NOTIFICATION';
export const RELEASE_UPDATE = 'RELEASE_UPDATE';

// helpMiddleware
export const FETCH_RELEASES = 'FETCH_RELEASES';
export const FETCH_RELEASE = 'FETCH_RELEASE';
export const CREATE_NEW_RELEASE = 'CREATE_NEW_RELEASE';
export const PATCH_RELEASE = 'PATCH_RELEASE';
export const DELETE_RELEASE = 'DELETE_RELEASE';
export const CREATE_NEW_FEATURE = 'CREATE_NEW_FEATURE';
export const FETCH_FEATURES = 'FETCH_FEATURES';
export const FETCH_FEATURE = 'FETCH_FEATURE';
export const PATCH_FEATURE = 'PATCH_FEATURE';
export const DELETE_FEATURE = 'DELETE_FEATURE';

// export type HelpAction =
//   | { type: 'CREATE_NEW_FEATURE'; articleBody: string };

export const updateHelpNewReleaseNotification = (name: string, value: any) => ({
  type: UPDATE_HELP_NEW_RELEASE_NOTIFICATION,
  name,
  value,
});

export const releaseUpdate = (name: string, value: any) => ({
  type: RELEASE_UPDATE,
  name,
  value,
});

export const featureOpenModal = () => ({
  type: FEATURE_OPEN_MODAL,
});

export const featureCloseModal = () => ({
  type: FEATURE_CLOSE_MODAL,
});

export const featureCreateModeOn = () => ({
  type: FEATURE_CREATE_MODE_ON,
});

export const featureEditModeOn = () => ({
  type: FEATURE_EDIT_MODE_ON,
});

export const updateIsNewFeature = (value: boolean) => ({
  type: UPDATE_IS_NEW_FEATURE,
  value,
});

export const featureCreateNew = (category: Category) => ({
  type: FEATURE_CREATE_NEW,
  category,
});

export const featureSelected = (feature: Feature, category: Category) => ({
  type: FEATURE_SELECTED,
  category,
  feature,
});

/// TODO can we specify a propriete of NewFeature
export const newFeatureUpdate = (name: string, value) => ({
  type: NEW_FEATURE_UPDATE,
  name,
  value,
});

export const editFeatureUpdate = (name: string, value) => ({
  type: EDIT_FEATURE_UPDATE,
  name,
  value,
});

export const featureUpdate = (name: string, value) => ({
  type: FEATURE_UPDATE,
  name,
  value,
});

// Midleware
export function createNewFeature(articleBody: string) {
  return { type: 'CREATE_NEW_FEATURE', articleBody };
}

// export const createNewFeature = (articleBody: string) => ({

//   type: CREATE_NEW_FEATURE,
//   articleBody,

// });

export const releaseToggleNew = () => ({
  type: RELEASE_TOGGLE_NEW,
});

export const releaseNewEdit = (value, name) => ({
  type: RELEASE_NEW_EDIT,
  value,
  name,
});

export const releaseEditTemp = (value, name) => ({
  type: RELEASE_EDIT_TEMP,
  value,
  name,
});

export const releaseVerif = (value, name) => ({
  type: RELEASE_VERIF,
  value,
  name,
});

export const releaseValidateTest = () => ({
  type: RELEASE_VALIDATE_TEST,
});

export const releaseCancel = () => ({
  type: RELEASE_CANCEL,
});

export const fetchReleases = () => ({
  type: FETCH_RELEASES,
});

export const fetchRelease = (id, activeName, index) => ({
  type: FETCH_RELEASE,
  id,
  activeName,
  index,
});

export const fetchFeature = (id, index) => ({
  type: FETCH_FEATURE,
  id,
  index,
});

export const createNewRelease = () => ({
  type: CREATE_NEW_RELEASE,
});

export const releasesUpdate = (name, value) => ({
  type: RELEASES_UPDATE,
  name,
  value,
});

export const releaseCreateSucces = (value, message) => ({
  type: RELEASE_CREATE_SUCCES,
  value,
  message,
});

export const releaseSettingsAccordion = (panel) => ({
  type: RELEASE_SETTINGS_ACCORDION,
  panel,
});

export const releaseSettingsLoader = (loader) => ({
  type: RELEASE_SETTINGS_LOADER,
  loader,
});

export const releaseToggleVisible = () => ({
  type: RELEASE_TOGGLE_VISIBLE,
});

export const releasesEdit = () => ({
  type: RELEASES_EDIT,
});

export const releasesEditNumber = (value) => ({
  type: RELEASES_EDIT_NUMBER,
  value,
});

export const releasesEditId = (id) => ({
  type: RELEASES_EDIT_ID,
  id,
});

export const releaseEdit = (editRelease) => ({
  type: RELEASE_EDIT,
  editRelease,
});

export const releaseEditTest = () => ({
  type: RELEASE_EDIT_TEST,
});

export const patchRelease = () => ({
  type: PATCH_RELEASE,
});

export const patchFeature = () => ({
  type: PATCH_FEATURE,
});

export const deleteFeature = (id: number) => ({
  type: DELETE_FEATURE,
  id,
});

export const deleteRelease = (id: number) => ({
  type: DELETE_RELEASE,
  id,
});

export const fetchFeatures = () => ({
  type: FETCH_FEATURES,
});
