/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';

import { updateFormValue } from '../../actions/projects';

import BreadcrumbModal from '../../app/projects/Import/Line/BreadcrumbModal';
import { verifyRowsEquipment } from '../../actions/import';

const mapStateToProps = (state) => ({
  openBreadcrumb: state.imports.openBreadcrumb,
  zones: state.projects.parsedProject?.zones,
  currentProject: state.projects.currentProject,
  selectedAllotmentNode: state.projects.selectedAllotmentNode,
});

const mapDispatchToProps = (dispatch) => ({
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  verifyRowsEquipment: (lineId) => {
    dispatch(verifyRowsEquipment(lineId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbModal);
