import './projectCard.scss';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React from 'react';

type RoundElementProps = {
  type: 'delete' | 'edit' | 'duplicate' | 'open';
  onClick: Function;
  bundle?: boolean;
  style: CSSProperties;
};

export const TrashIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.38116 21.6519H18.6189L19.2941 7.36621H5.70593L6.38116 21.6519Z"
        fill="none"
      />
      <path
        d="M22.3214 5.35721H18.75V3.12507C18.75 2.14014 17.9492 1.33936 16.9643 1.33936H8.03571C7.05077 1.33936 6.24999 2.14014 6.24999 3.12507V5.35721H2.67856C2.1847 5.35721 1.78571 5.75621 1.78571 6.25007V7.14293C1.78571 7.2657 1.88615 7.36614 2.00892 7.36614H3.69419L4.38336 21.9588C4.42801 22.9102 5.21484 23.6608 6.16629 23.6608H18.8337C19.7879 23.6608 20.572 22.913 20.6166 21.9588L21.3058 7.36614H22.9911C23.1138 7.36614 23.2143 7.2657 23.2143 7.14293V6.25007C23.2143 5.75621 22.8153 5.35721 22.3214 5.35721ZM8.25892 3.34828H16.7411V5.35721H8.25892V3.34828ZM18.6189 21.6519H6.38113L5.70591 7.36614H19.2941L18.6189 21.6519Z"
        fill="current"
      />
    </svg>
  );
};

export const EditIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4504 6.25858L17.4052 4.21338L7.28518 14.3306L6.84991 16.8138L9.33038 16.3758L19.4504 6.25858Z"
        fill="none"
      />
      <path
        d="M22.7679 21.5402H2.23215C1.73829 21.5402 1.33929 21.9392 1.33929 22.4331V23.4376C1.33929 23.5603 1.43974 23.6608 1.56251 23.6608H23.4375C23.5603 23.6608 23.6607 23.5603 23.6607 23.4376V22.4331C23.6607 21.9392 23.2617 21.5402 22.7679 21.5402ZM5.40459 19.1965C5.46039 19.1965 5.51619 19.1909 5.572 19.1825L10.2651 18.3594C10.3209 18.3483 10.3739 18.3232 10.413 18.2813L22.2405 6.45375C22.2664 6.42794 22.2869 6.39728 22.3009 6.36353C22.3149 6.32977 22.3221 6.29359 22.3221 6.25705C22.3221 6.2205 22.3149 6.18432 22.3009 6.15057C22.2869 6.11681 22.2664 6.08615 22.2405 6.06034L17.6032 1.42027C17.5502 1.36726 17.4805 1.33936 17.4051 1.33936C17.3298 1.33936 17.2601 1.36726 17.207 1.42027L5.37947 13.2478C5.33762 13.2897 5.31251 13.3399 5.30135 13.3957L4.47825 18.0888C4.4511 18.2383 4.4608 18.3921 4.5065 18.537C4.5522 18.6819 4.63253 18.8134 4.74052 18.9203C4.92467 19.0988 5.15626 19.1965 5.40459 19.1965ZM7.28517 14.3304L17.4051 4.21324L19.4503 6.25844L9.33037 16.3756L6.8499 16.8137L7.28517 14.3304Z"
        fill="current"
      />
    </svg>
  );
};

export const DuplicateIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 10H9V13H6V15H9V18H11V15H14V13H11V10Z" fill="#A3AED0" />
      <path
        d="M4 22H16C17.103 22 18 21.103 18 20V8C18 6.897 17.103 6 16 6H4C2.897 6 2 6.897 2 8V20C2 21.103 2.897 22 4 22ZM4 8H16L16.002 20H4V8Z"
        fill="#A3AED0"
      />
      <path d="M20 2H8V4H20V16H22V4C22 2.897 21.103 2 20 2Z" fill="#A3AED0" />
    </svg>
  );
};

export const OpenIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9876 2.56885V4.28135H15.0615L6.6446 12.6983L7.85191 13.9056L16.2688 5.48866V8.5626H17.9813V2.56885M16.2688 16.2688H4.28135V4.28135H10.2751V2.56885H4.28135C3.82716 2.56885 3.39158 2.74927 3.07043 3.07043C2.74927 3.39158 2.56885 3.82716 2.56885 4.28135V16.2688C2.56885 16.723 2.74927 17.1586 3.07043 17.4798C3.39158 17.8009 3.82716 17.9813 4.28135 17.9813H16.2688C16.723 17.9813 17.1586 17.8009 17.4798 17.4798C17.8009 17.1586 17.9813 16.723 17.9813 16.2688V10.2751H16.2688V16.2688Z"
        fill="current"
      />
    </svg>
  );
};

export const RoundElement = ({
  type,
  onClick,
  bundle,
  variant,
  style,
}: RoundElementProps) => {
  return (
    <div
      className={
        !bundle
          ? `roundElement ${
              type === 'duplicate'
                ? 'duplicate'
                : type === 'delete'
                ? 'delete'
                : type === 'edit'
                ? 'edit'
                : type === 'open'
                ? 'open'
                : ''
            } ${variant ? 'variant' : ''}`
          : 'roundElement-bundle'
      }
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick(e);
      }}
      style={style}
    >
      {type === 'delete' && (
        <div className="iconWrapper">
          <TrashIcon />
        </div>
      )}
      {type === 'edit' && (
        <div style={style} className="iconWrapper">
          <EditIcon />
        </div>
      )}
      {type === 'duplicate' && (
        <div style={style} className="iconWrapper">
          <DuplicateIcon />
        </div>
      )}
      {type === 'open' && (
        <div style={style} className="iconWrapper">
          <OpenIcon />
        </div>
      )}
    </div>
  );
};
