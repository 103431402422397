import { Button } from '@photocarbone/nooco-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { StateDTO } from '../../../../../models/GeneralDTO';
import {
  calculatorUpdate,
  updateCalculatorData,
} from '../../../../../../actions/calculator';
import { v4 as uuidv4 } from 'uuid';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { useDispatch, useSelector } from 'react-redux';

import React from 'react';
import useCaseWorksite from './UseCaseWorksite';
import waste from '../../../../../../assets/images/equipments/calculator/waste.png';
import waterQuantity from '../../../../../../assets/images/equipments/calculator/waterQuantity.png';
import { useTranslation } from 'react-i18next';

const WorksiteWater = ({
  fieldsWaterForm,
  changeUnitOfWater,
  changeSourceOfEmptyRows,
}) => {
  const {
    getPreviousScreenActiveId,
    getNextScreenActiveId,
    calculateAreaZoneProrata,
    isDisplayEquipment,
    isEquipmentFromParentZone,
    isEquipmentEditable,
    onSubmitScreen: onSubmit,
    zoneSourceToDisplay,
  } = useCaseWorksite();
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useFormContext();
  const { fields, append, remove, replace } = fieldsWaterForm;
  const { zones } = useSelector((state: StateDTO) => state.projects.parsedProject);
  const { zoneSelected } = useSelector(
    (state: StateDTO) => state.monitoring.breadcrumbMultizone
  );
  const { worksiteCalculatorDetailedActive } = useSelector(
    (state: StateDTO) => state.projects.calculator
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onError = (errors) => {
    console.log('validation errors water', errors);
  };

  const onSubmitPrevious = (data) => {
    if (isValid) {
      changeSourceOfEmptyRows({
        zone: zoneSelected,
        sideBarActiveId: getPreviousScreenActiveId(),
      });
      dispatch(
        calculatorUpdate(
          'worksiteCalculatorDetailedActive',
          worksiteCalculatorDetailedActive - 1
        )
      );
    } else {
      onSubmit(data, 'previous');
    }
  };

  const onSubmitNext = (data) => {
    if (isValid) {
      changeSourceOfEmptyRows({
        zone: zoneSelected,
        sideBarActiveId: getNextScreenActiveId(),
      });
      dispatch(
        calculatorUpdate(
          'worksiteCalculatorDetailedActive',
          worksiteCalculatorDetailedActive + 1
        )
      );
    } else {
      onSubmit(data, 'next');
    }
  };

  const isSmallScreenMode = window.innerWidth < 1550;

  return (
    <>
      <form
        className={`calculatorFlexColumnStart calculatorCardWrapper ${
          isSmallScreenMode ? 'isSmallScreenMode' : ''
        }`}
        style={{ flexGrow: '1' }}
        action=""
      >
        {/* Toggle m3 / Liter */}
        <div
          className="calculatorFlexStart"
          style={{ marginBottom: '2rem', alignItems: 'center' }}
        >
          <h3 className="calculator__label" style={{ marginBottom: 0 }}>
            {' '}
            {t('APP.CALCULATORWORKSITECONTENT.WORKSITEWATER.PH_11F97F', {
              defaultValue: 'Unité de saisie',
            })}
          </h3>
          <div className="calculatorToggle__container">
            <div
              className="calculatorToggle__container--label"
              onClick={() => {
                changeUnitOfWater('m3');
              }}
            >
              m&#xB3;
            </div>
            <div
              className="calculatorToggle__container--label"
              onClick={() => {
                changeUnitOfWater('liter');
              }}
            >
              L
            </div>
            <div
              className={
                getValues('water.displayUnit') === 'm3'
                  ? 'calculatorToggle__container--m'
                  : 'calculatorToggle__container--l'
              }
            ></div>
          </div>
        </div>

        {/* header */}
        <div
          className="calculatorFlexStart"
          style={{
            marginBottom: '1rem',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          <h3
            className="calculator__label"
            style={{ width: isSmallScreenMode ? '320px' : '430px' }}
          >
            {t('APP.CALCULATORWORKSITECONTENT.WORKSITEWATER.PH_4D1A97', {
              defaultValue:
                'Consommation d’eau potable par le chantier de construction',
            })}
          </h3>
          <h3
            className="calculator__label"
            style={{ width: isSmallScreenMode ? '290px' : '430px' }}
          >
            {t('APP.CALCULATORWORKSITECONTENT.WORKSITEWATER.PH_FB8C1D', {
              defaultValue: 'Rejet d’eaux usées par le chantier de construction',
            })}
          </h3>
          <h3
            className="calculator__label"
            style={{
              width: isSmallScreenMode ? '100px' : '130px',
              textAlign: 'center',
            }}
          >
            {t('APP.CALCULATORWORKSITECONTENT.WORKSITEWATER.PH_110318', {
              defaultValue: 'Source',
            })}
          </h3>
          <div
            style={{
              display: 'flex',
              flexGrow: '1',
              justifyContent: 'flex-end',
            }}
          />
        </div>
        {fields.map((item, index) => {
          if (isEquipmentEditable(item)) {
            return (
              <div
                key={item.id}
                className="cardWrapper"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'nowrap',
                  justifyContent: 'flex-start',
                }}
              >
                {/* drinkableWater */}
                <div
                  className="calculatorFlexColumnStart"
                  style={{ width: isSmallScreenMode ? '320px' : '430px' }}
                >
                  <div
                    className="calculatorFlexStart"
                    style={{ alignItems: 'center' }}
                  >
                    <img
                      src={waterQuantity}
                      alt=""
                      style={{
                        display: 'block',
                        marginRight: '2rem',
                        width: '80px',
                        height: '60px',
                      }}
                    />
                    <div className="calculatorFlexColumnStart">
                      <h4 className="calculator__subLabel">
                        <span className="calculator__label--grow">Q</span>
                        chantier, eau potable{' '}
                        <span className="calculator__label--grow">
                          {getValues('water.displayUnit') === 'm3'
                            ? '(m\u00b3)'
                            : '(L)'}
                        </span>
                      </h4>
                      <Controller
                        control={control}
                        {...register(`water.zonesData.${index}.drinkableWater`)}
                        rules={{
                          validate: {
                            //v is = e.currentTarget.value
                            isNumber: (v) => {
                              if (!isNaN(Number(v?.replace(/,/, '.')))) {
                                return true;
                              } else {
                                return false;
                              }
                            },
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder={t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITEWATER.PH_DA8099',
                              { defaultValue: 'Saisir une quantité' }
                            )}
                            size={`${isSmallScreenMode ? 'small' : 'large'}`}
                            status={
                              errors?.water?.zonesData[index]?.drinkableWater
                                ? 'error'
                                : ''
                            }
                            // status="error"
                            style={{
                              width: isSmallScreenMode ? '140px' : '250px',
                            }}
                            // onBlur={handleSubmit(onBlur, onBlurError)}
                          />
                        )}
                      />
                      {errors?.water?.zonesData[index]?.drinkableWater && (
                        <div className="container__main--errorMessage">
                          {errors?.water?.zonesData[index]?.drinkableWater?.type ===
                          'isNumber'
                            ? t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEWATER.PH_5C700F',
                                { defaultValue: 'Vous devez entrer un nombre' }
                              )
                            : errors?.water?.zonesData[index]?.drinkableWater
                                ?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* wastedWater */}
                <div
                  className="calculatorFlexColumnStart"
                  style={{ width: isSmallScreenMode ? '290px' : '430px' }}
                >
                  <div
                    className="calculatorFlexStart"
                    style={{ alignItems: 'center' }}
                  >
                    <img
                      src={waste}
                      alt=""
                      style={{
                        display: 'block',
                        marginRight: '2rem',
                        width: '80px',
                        height: '60px',
                      }}
                    />
                    <div className="calculatorFlexColumnStart">
                      <h4 className="calculator__subLabel">
                        <span className="calculator__label--grow">Q</span>
                        {t('APP.CALCULATORWORKSITECONTENT.WORKSITEWATER.PH_8EF3B5', {
                          defaultValue: 'chantier, eaux usées',
                        })}
                        <span className="calculator__label--grow">
                          {getValues('water.displayUnit') === 'm3'
                            ? '(m\u00b3)'
                            : '(L)'}
                        </span>
                      </h4>
                      <Controller
                        control={control}
                        {...register(`water.zonesData.${index}.wastedWater`)}
                        rules={{
                          validate: {
                            //v is = e.currentTarget.value
                            isNumber: (v) => {
                              if (!isNaN(Number(v?.replace(/,/, '.')))) {
                                return true;
                              } else {
                                return false;
                              }
                            },
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder={t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITEWATER.PH_908551',
                              { defaultValue: 'Saisir une quantité' }
                            )}
                            status={
                              errors?.water?.zonesData[index]?.wastedWater
                                ? 'error'
                                : ''
                            }
                            // status={errors?.drinkableWater && isSubmitted ? "error" : "" }
                            size="large"
                            style={{
                              width: isSmallScreenMode ? '140px' : '250px',
                            }}
                            // onBlur={handleSubmit(onBlur, onBlurError)}
                          />
                        )}
                      />
                      {errors?.water?.zonesData[index]?.wastedWater && (
                        <div className="container__main--errorMessage">
                          {errors?.water?.zonesData[index]?.wastedWater?.type ===
                          'isNumber'
                            ? t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEWATER.PH_B369D3',
                                { defaultValue: 'Vous devez entrer un nombre' }
                              )
                            : errors?.water?.zonesData[index]?.wastedWater?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* source */}
                <div
                  className="calculatorFlexColumnStart"
                  style={{
                    width: isSmallScreenMode ? '100px' : '130px',
                    textAlign: 'center',
                  }}
                >
                  <div>{zoneSourceToDisplay(item.origin)}</div>
                </div>

                {/* Delete energy Type */}
                <div
                  style={{
                    display: 'flex',
                    flexGrow: '1',
                    justifyContent: 'flex-end',
                  }}
                  onClick={() => {
                    if (getValues('water.zonesData').length > 1) {
                      remove(index);
                    } else {
                      replace({
                        drinkableWater: '',
                        wastedWater: '',
                        origin: {
                          type: 'project',
                          sourceId: null,
                          uniqueId: uuidv4(),
                        },
                      });
                    }
                  }}
                >
                  <HighlightOffIcon
                    style={{
                      width: '40px',
                      height: '40px',
                      marginLeft: '1rem',
                    }}
                    className="calculator__delElement"
                  />
                </div>
              </div>
            );
          }

          if (isDisplayEquipment(item)) {
            let drinkableWater = getValues(
              `water.zonesData.${index}.drinkableWater`
            );
            let wastedWater = getValues(`water.zonesData.${index}.wastedWater`);

            if (isEquipmentFromParentZone(item)) {
              const quantityProrata = calculateAreaZoneProrata(item);

              drinkableWater =
                Math.round(drinkableWater * quantityProrata * 10000) / 10000;
              wastedWater =
                Math.round(wastedWater * quantityProrata * 10000) / 10000;
            }

            return (
              <div
                key={item.id}
                className="cardWrapper disabled"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'nowrap',
                  justifyContent: 'flex-start',
                }}
              >
                {/* drinkableWater */}
                <div
                  className="calculatorFlexColumnStart"
                  style={{ width: isSmallScreenMode ? '320px' : '430px' }}
                >
                  <div
                    className="calculatorFlexStart"
                    style={{ alignItems: 'center' }}
                  >
                    <img
                      src={waterQuantity}
                      alt=""
                      style={{
                        display: 'block',
                        marginRight: '2rem',
                        width: '80px',
                        height: '60px',
                      }}
                    />
                    <div className="calculatorFlexColumnStart">
                      <h4 className="calculator__subLabel">
                        <span className="calculator__label--grow">Q</span>
                        chantier, eau potable{' '}
                        <span className="calculator__label--grow">
                          {getValues('water.displayUnit') === 'm3'
                            ? '(m\u00b3)'
                            : '(L)'}
                        </span>
                      </h4>

                      <Input
                        value={drinkableWater}
                        placeholder={t(
                          'APP.CALCULATORWORKSITECONTENT.WORKSITEWATER.PH_4F46F8',
                          { defaultValue: 'Saisir une quantité' }
                        )}
                        size="large"
                        status={
                          errors?.water?.zonesData[index]?.drinkableWater
                            ? 'error'
                            : ''
                        }
                        // status="error"
                        disabled={true}
                        style={{ width: isSmallScreenMode ? '140px' : '250px' }}
                        // onBlur={handleSubmit(onBlur, onBlurError)}
                      />
                    </div>
                  </div>
                </div>

                {/* wastedWater */}
                <div
                  className="calculatorFlexColumnStart"
                  style={{ width: isSmallScreenMode ? '290px' : '430px' }}
                >
                  <div
                    className="calculatorFlexStart"
                    style={{ alignItems: 'center' }}
                  >
                    <img
                      src={waste}
                      alt=""
                      style={{
                        display: 'block',
                        marginRight: '2rem',
                        width: '80px',
                        height: '60px',
                      }}
                    />
                    <div className="calculatorFlexColumnStart">
                      <h4 className="calculator__subLabel">
                        <span className="calculator__label--grow">Q</span>
                        {t('APP.CALCULATORWORKSITECONTENT.WORKSITEWATER.PH_88E152', {
                          defaultValue: 'chantier, eaux usées',
                        })}{' '}
                        <span className="calculator__label--grow">
                          {getValues('water.displayUnit') === 'm3'
                            ? '(m\u00b3)'
                            : '(L)'}
                        </span>
                      </h4>

                      <Input
                        value={wastedWater}
                        placeholder={t(
                          'APP.CALCULATORWORKSITECONTENT.WORKSITEWATER.PH_68BE3D',
                          { defaultValue: 'Saisir une quantité' }
                        )}
                        status={
                          errors?.water?.zonesData[index]?.wastedWater ? 'error' : ''
                        }
                        // status={errors?.drinkableWater && isSubmitted ? "error" : "" }
                        size="large"
                        style={{ width: isSmallScreenMode ? '140px' : '250px' }}
                        disabled={true}
                        // onBlur={handleSubmit(onBlur, onBlurError)}
                      />
                    </div>
                  </div>
                </div>

                {/* source */}
                <div
                  className="calculatorFlexColumnStart"
                  style={{
                    width: isSmallScreenMode ? '100px' : '130px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#268F82',
                  }}
                >
                  <div>{zoneSourceToDisplay(item.origin)}</div>
                </div>

                {/* Delete energy Type */}
                <div
                  style={{
                    display: 'flex',
                    flexGrow: '1',
                    justifyContent: 'flex-end',
                  }}
                />
              </div>
            );
          }

          return null;
        })}

        {/* add button */}
        <div
          className="calculator__addElement"
          onClick={() =>
            append({
              drinkableWater: '',
              wastedWater: '',
              origin: {
                type: zoneSelected?.type || 'project',
                sourceId: zoneSelected?.id || null,
                uniqueId: uuidv4(),
              },
            })
          }
        >
          <ControlPointIcon />{' '}
          {t('APP.CALCULATORWORKSITECONTENT.WORKSITEWATER.PH_78CFDE', {
            defaultValue: 'Ajouter dans cette zone',
          })}
        </div>

        {/* next Button and Previus Button*/}
        <div
          className="calculatorFlexColumnStart"
          style={{
            flexGrow: 1,
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <div
            className="calculatorFlexStart"
            style={{ justifyContent: 'flex-end' }}
          >
            <Button
              style={{
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
              }}
              className="close-button"
              onClick={handleSubmit(onSubmitPrevious, onError)}
              disabled={getPreviousScreenActiveId() === null}
              text={t('APP.CALCULATORWORKSITECONTENT.WORKSITEWATER.PH_2A09E0', {
                defaultValue: 'Précédent',
              })}
            />

            <Button
              style={{
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                marginLeft: '5px',
              }}
              className="purple-button"
              onClick={handleSubmit(onSubmitNext, onError)}
              disabled={getNextScreenActiveId() === null}
              text={t('APP.CALCULATORWORKSITECONTENT.WORKSITEWATER.PH_0997A3', {
                defaultValue: 'Suivant',
              })}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default WorksiteWater;
