/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';

import {
  deleteAllErrors,
  deleteImport,
  fetchImportDatas,
  filterByArchived,
  filterImportList,
  researchSourceName,
  retractImport,
  sendImport,
  setLoadingImport,
} from '../../actions/import';

import { updateFormValue } from '../../actions/projects';

import Import from '../../app/projects/Import';

const mapStateToProps = (state) => ({
  importDate: state.imports.importDate,
  currentProject: state.projects.currentProject,
  importDatas: state.imports.importDatas,
  goodLines: state.imports.goodLines,
  badLines: state.imports.badLines,
  waitLines: state.imports.waitLines,
  error: state.imports.error,
  importDetails: state.imports.importDetails,
  isImportLoading: state.imports.isImportLoading,
  isRetracted: state.imports.isRetracted,
  editImport: state.imports.editImport,
  dataError: state.imports.dataError,
  isLoad: state.imports.isLoad,
  filterOn: state.imports.filterOn,
  notProcessed: state.imports.notProcessed,
  totalProcessed: state.imports.totalProcessed,
  executionId: state.imports.executionId,
  currentImport: state.imports.currentImport,
  isSearchingData: state.imports.isSearchingData,
  searchSourceValue: state.imports.searchSourceValue,
  importStep: state.imports.importStep,
  creationImport: state.imports.creationImport,
  zones: state.projects.parsedProject.zones,
  errorPatchLines: state.imports.errorPatchLines,
});

const mapDispatchToProps = (dispatch) => ({
  fetchImportDatas: (idProject, importId) => {
    dispatch(fetchImportDatas(idProject, importId));
  },
  setLoadingImport: () => {
    dispatch(setLoadingImport());
  },
  deleteImport: () => {
    dispatch(deleteImport());
  },
  sendImport: (unique) => {
    dispatch(sendImport(unique));
  },
  retractImport: () => {
    dispatch(retractImport());
  },
  deleteAllErrors: () => {
    dispatch(deleteAllErrors());
  },
  filterImportList: (lines, currentProject) => {
    dispatch(filterImportList(lines, currentProject));
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  researchSourceName: (isArchived) => {
    dispatch(researchSourceName(isArchived));
  },
  filterByArchived: () => {
    dispatch(filterByArchived());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Import);
