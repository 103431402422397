import './projectCard.scss';
import { ProjectDTO } from '../../../../models/ProjectDTO';
import { Tooltip } from '@mui/material';
import { formatDate } from '../../../../shared/utilsFunction';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { TEMPLATE_TYPE_NAME } from '../../../../globalVariable/typeProject';

const airportLogo = require('../../../../../assets/images/creation/airport.png');
const infrastructureExterieurLogo = require('../../../../../assets/images/creation/infra.png');
const dataCenterLogo = require('../../../../../assets/images/creation/data-center.png');
const prototypeLogo = require('../../../../../assets/images/creation/proto.png');
const exploitationLogo = require('../../../../../assets/images/creation/exploitation.png');
const noocoLogo = require('../../../../../assets/images/creation/nooco.png');
const renovationLogo = require('../../../../../assets/images/creation/renovationTest.png');
const bbcaLogo = require('../../../../../assets/images/creation/bbca.png');

const epcmLogo = require('../../../../../assets/images/creation/labelEpcm.png');
const re2020Logo = require('../../../../../assets/images/creation/labelRe2020.png');
const locationLogo = require('../../../../../assets/images/creation/location.png');
const areaLogo = require('../../../../../assets/images/creation/area.png');
const nameLogo = require('../../../../../assets/images/creation/name.png');
const companyLogo = require('../../../../../assets/images/creation/company.png');
const globalMonitoringLogo = require('../../../../../assets/images/creation/global_monitoring.png');

import dayjs from 'dayjs';
import { truncateName } from '../../../../shared/utils';

type MainCardProps = {
  project: ProjectDTO;
  cardHeader: boolean;
};

type RowElementProps = { logo: string; text: string; tooltip?: string };
const RowElement = ({ logo, text, tooltip }: RowElementProps) => {
  return (
    <Tooltip title={tooltip ? tooltip : ''}>
      <div className="rawElement">
        <img src={logo} alt="" />
        <div>{text}</div>
      </div>
    </Tooltip>
  );
};

function formatSurface(surface: number | null): string {
  if (surface === null) {
    return '0';
  }

  const formattedSurface = surface.toFixed(2);

  return formattedSurface;
}

export const MainCard = ({ project, cardHeader = false }: MainCardProps) => {
  const { t, i18n } = useTranslation();

  const selectLogo = () => {
    let logo = '';
    let tooltipLogo = '';
    let tooltipArea = t('APP.PROJECTCARD.MAINCARD.PH_409C0A', {
      defaultValue: 'Surface',
    });

    switch (project?.ProjectType?.name) {
      case TEMPLATE_TYPE_NAME.AIRPORT:
        logo = airportLogo;
        tooltipLogo = t('APP.PROJECTCARD.MAINCARD.PH_714DB5', {
          defaultValue: 'Aéroport',
        });
        break;
      case TEMPLATE_TYPE_NAME.OUTDOOR:
        logo = infrastructureExterieurLogo;
        tooltipLogo = t('APP.PROJECTCARD.MAINCARD.PH_4B30C0', {
          defaultValue: 'Infrastructure Extérieure',
        });

        break;
      case TEMPLATE_TYPE_NAME.DATACENTER:
        logo = dataCenterLogo;
        tooltipLogo = t('APP.PROJECTCARD.MAINCARD.PH_39A793', {
          defaultValue: 'Datacenter',
        });
        break;
      case TEMPLATE_TYPE_NAME.PROTOTYPE:
        logo = prototypeLogo;
        tooltipLogo = t('APP.PROJECTCARD.MAINCARD.PH_079873', {
          defaultValue: 'Prototype',
        });
        break;
      case TEMPLATE_TYPE_NAME.EXPLOITATION:
        logo = exploitationLogo;
        tooltipLogo = t('APP.PROJECTCARD.MAINCARD.PH_8957C0', {
          defaultValue: 'Exploitation',
        });
        break;
      case TEMPLATE_TYPE_NAME.NOOCO:
        logo = noocoLogo;
        tooltipLogo = t('APP.PROJECTCARD.MAINCARD.PH_228C59', {
          defaultValue: 'Ecoconception',
        });
        break;
      case TEMPLATE_TYPE_NAME.RENOVATION:
        logo = renovationLogo;
        tooltipLogo = t('APP.PROJECTCARD.MAINCARD.PH_6C1C1A', {
          defaultValue: 'Rénovation',
        });
        break;
      case TEMPLATE_TYPE_NAME.EPCM:
        logo = epcmLogo;
        tooltipLogo = 'E+C-';
        tooltipArea = t('APP.PROJECTCARD.MAINCARD.PH_90E868', {
          defaultValue: 'Surface de plancher',
        });
        break;
      case TEMPLATE_TYPE_NAME.BBCA:
        logo = bbcaLogo;
        tooltipLogo = 'Bbca';
        tooltipArea = 'Surface de plancher';
        break;
      case TEMPLATE_TYPE_NAME.RE2020:
        logo = re2020Logo;
        tooltipLogo = 'RE2020';
        tooltipArea = t('APP.PROJECTCARD.MAINCARD.PH_6D4486', {
          defaultValue:
            'Pour le résidentiel : Surface Habitable (SHab). Pour le tertiaire : Surface utile (SU)',
        });
        break;
      case TEMPLATE_TYPE_NAME.GLOBAL_MONITORING:
        logo = globalMonitoringLogo;
        tooltipArea = t('APP.PROJECTCARD.MAINCARD.GLOBAL_MONITORING_TOOLTIP', {
          defaultValue: 'Global monitoring',
        });
        break;
      default:
        // Définir un logo par défaut si aucun cas ne correspond
        logo = '';
        break;
    }

    setSelectedLogo(logo);
    setTooltipLogo(tooltipLogo);
    setTooltipArea(tooltipArea);
  };

  const displayPermitDate = () => {
    if (
      project?.permitDate !== 'Invalid date' &&
      formatDate(project?.permitDate) !== 'Invalid date' &&
      project?.permitDate !== null &&
      cardHeader === false &&
      [
        TEMPLATE_TYPE_NAME.EPCM,
        TEMPLATE_TYPE_NAME.RE2020,
        TEMPLATE_TYPE_NAME.BBCA,
      ].includes(project?.ProjectType?.name)
    ) {
      return true;
    }

    return false;
  };

  const displayArea = () => {
    const isProjectTypeNooco =
      project?.ProjectType?.name === TEMPLATE_TYPE_NAME.NOOCO;
    const refUnit = project.refUnit;

    if (isProjectTypeNooco || refUnit === 'm²') {
      return true;
    }

    return false;
  };

  const [selectedLogo, setSelectedLogo] = useState('');
  const [tooltipLogo, setTooltipLogo] = useState('');
  const [tooltipArea, setTooltipArea] = useState('Surface');

  useEffect(() => {
    selectLogo();
  }, [i18n.language]);

  if (!project) return;

  const renderInnerCardForProjectsList = (): JSX.Element => {
    return (
      <>
        <div className="mainCardColumn">
          <Tooltip
            title={t('APP.PROJECTCARD.MAINCARD.PH_DBC313', {
              defaultValue: 'Date de création du projet',
            })}
          >
            <div className="mainCardDate">
              {t('APP.PROJECTCARD.MAINCARD.PH_F8472C', {
                formatDate: dayjs(project?.createdAt).format('L'),
                defaultValue: 'Date - création : {formatDate}',
              })}
            </div>
          </Tooltip>
          <RowElement
            logo={nameLogo}
            tooltip={t('APP.PROJECTCARD.MAINCARD.PH_51D229', {
              defaultValue: 'Responsable',
            })}
            text={project?.inCharge}
          />

          <RowElement
            logo={companyLogo}
            tooltip={t('APP.PROJECTCARD.MAINCARD.PH_68EC2A', {
              defaultValue: 'Entreprise',
            })}
            text={project?.Company?.name}
          />
        </div>
        <div className="mainCardColumn">
          {displayPermitDate() ? (
            <Tooltip
              title={t('APP.PROJECTCARD.MAINCARD.PH_6A01F8', {
                defaultValue: 'Date de permis',
              })}
            >
              <div className="mainCardDate">
                {t('APP.PROJECTCARD.MAINCARD.PH_BA8D85', {
                  permitDate: formatDate(project?.permitDate),
                  defaultValue: 'Date - permis : {permitDate}',
                })}
              </div>
            </Tooltip>
          ) : (
            <div className="mainCardDate"></div>
          )}
          {displayArea() === true ? (
            <RowElement
              logo={areaLogo}
              tooltip={tooltipArea}
              text={`${formatSurface(project?.area)} m²`}
            />
          ) : null}
          <RowElement
            logo={locationLogo}
            tooltip={t('APP.PROJECTCARD.MAINCARD.PH_822B46', {
              defaultValue: 'Localisation',
            })}
            text={`${project?.Address?.name}, ${project?.Address?.postcode}`}
          />
        </div>
      </>
    );
  };

  const renderInnerCardForProjectHeader = (): JSX.Element => {
    return (
      <>
        <div
          style={{
            position: 'relative',
            width: '100%',
            color: '#2b3674',
            marginLeft: '10px',
            fontWeight: 700,
          }}
          className="project-title"
        >
          {truncateName(project.name, 40)}
        </div>

        <div className="mainCardColumn">
          {project.templateType !== TEMPLATE_TYPE_NAME.EXPLOITATION && (
            <RowElement
              logo={locationLogo}
              tooltip={t('APP.PROJECTCARD.MAINCARD.PH_822B46', {
                defaultValue: 'Localisation',
              })}
              text={`${project?.Address?.name}, ${project?.Address?.postcode}`}
            />
          )}
        </div>
        <div className="mainCardColumn">
          {displayPermitDate() ? (
            <Tooltip
              title={t('APP.PROJECTCARD.MAINCARD.PH_6A01F8', {
                defaultValue: 'Date de permis',
              })}
            >
              <div className="mainCardDate">
                {t('APP.PROJECTCARD.MAINCARD.PH_BA8D85', {
                  permitDate: formatDate(project?.permitDate),
                  defaultValue: 'Date - permis : {permitDate}',
                })}
              </div>
            </Tooltip>
          ) : (
            <div className="mainCardDate"></div>
          )}
          {displayArea() === true ? (
            <RowElement
              logo={areaLogo}
              tooltip={tooltipArea}
              text={`${formatSurface(project?.area)} m²`}
            />
          ) : null}
        </div>
      </>
    );
  };

  return (
    <div className={`mainCardWrapper ${cardHeader ? 'cardHeader' : ''}`}>
      <div className="mainCardLogo">
        <Tooltip title={tooltipLogo}>
          <img
            src={selectedLogo}
            alt=""
            style={{ width: selectedLogo === re2020Logo ? '103px' : '51px' }}
          />
        </Tooltip>
      </div>
      {!cardHeader
        ? renderInnerCardForProjectsList()
        : renderInnerCardForProjectHeader()}
    </div>
  );
};
