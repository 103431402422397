import './settings.scss';
import { Button } from '@photocarbone/nooco-ui';
import { Modal } from 'react-bootstrap';
import { StateDTO } from '../../models/GeneralDTO';
import {
  TEMPLATE_TYPE_NAME,
  TYPE_EXPLOITATION,
} from '../../globalVariable/typeProject';
import { changeValueEquipments } from '../../../actions/equipments';
import { fetchProjectById, updateFormValue } from '../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppContext from '../../AppContext';
import ArchitectureForm from './ArchitectureForm/ArchitectureForm';
import GeneralForm from './GeneralForm/GeneralForm';
import ParamsForm from './ParamsForm/ParamsForm';
import React, { useContext, useEffect, useState } from 'react';
import SnackBarElement from '../../shared/SnackBarElement';

import { calculatorReset, calculatorWaterReset } from '../../../actions/calculator';
import { updateMonitoring } from '../../../actions/monitoring';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getAllotmentId } from '../../shared/utils';
import { useSaveProjectDataComps } from '../../../hooks/useQuery/bbca/saveClimateInnovation';
import { useGetProjectDataComps } from '../../../hooks/useQuery/projects/getProjectDataComps';
import { ZONE_TYPES } from '../../globalVariable/generalDefinitions';
import { stringToNumber } from '../../shared/utilsFunction';

const Settings = ({
  id,
  infos,
  callback,
  allGroups,
  buildingTypes,
  editZones,
  currentIndicatorId,
  companies,
  projects,
  zones,
  licenceTypeAccess,
  settingsDatas,
}) => {
  const { currentProject } = useSelector((state: StateDTO) => state.projects);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm();
  // const { alertMessage, alertSeverity } = useSelector((state) => state.monitoring);

  //! editZone représente les zones qui ont été modifiés dans le composant ZoneSection. Quand on save les modifications d'un projet, on verifie si editZone est vide pour savoir si l'on doit faire un appel à patchZone en plus de patchProject
  //! newsInfo est un useState qui est une copie de currentProject. C'était l'ancien systeme qui permettait d'intérargir avec les différentes inputs de formulaire avant de les envoyer dans un patchProject. Mais du coup dans le patch on envoyait toutes les infos du projet. C'était plus un put qu'un patch.
  const ctx = useContext(AppContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //state
  const [buttonLoading, setButtonLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showCalculatorPreventModal, setShowCalculatorPreventModal] =
    useState(false);

  const updateSettingsDataWithDataComps = (projectDataComps) => {
    const buildingsFromProjectDataComps = projectDataComps?.buildings || [];
    if (settingsDatas) {
      let newSettingsDatas = [...settingsDatas];
      buildingsFromProjectDataComps.forEach((buildingFromProjectDataComp) => {
        const buildingIndex = newSettingsDatas.findIndex(
          (building) => building.id === buildingFromProjectDataComp.ZoneId
        );
        if (buildingIndex !== -1) {
          newSettingsDatas[buildingIndex].speculativeDevelopment =
            buildingFromProjectDataComp.speculativeDevelopment;
        }
        dispatch(updateFormValue('settingsData', newSettingsDatas));
      });
    }
  };

  const { data: projectDataComps } = useGetProjectDataComps({
    onSuccessCallback: updateSettingsDataWithDataComps,
  });

  useEffect(() => {
    return () => {
      dispatch(updateFormValue('isRsenv', null));
      dispatch(updateFormValue('rsetFile', null));
      dispatch(
        updateFormValue('buildingSections', [
          {
            flag: 0,
            name: 'Bâtiment 1',
            type: 'building',
            Zones: [
              {
                name: 'Zone 1',
                BuildingTypeId: 1,
                menInput: true,
                logementInput: true,
                comblesInput: false,
                area: null,
                type: 'zone',
                category: 'CE1',
              },
            ],
          },
        ])
      );
    };
  }, []);

  const deleteProject = async () => {
    const response = await ctx.api.deleteProject(id);

    dispatch(
      updateFormValue(
        'projects',
        projects.filter((project) => project.id !== id)
      )
    );

    if (response.status === 200) history.push('/projects');
  };

  const saveWithCalculatorPreventModal = async () => {
    onSubmit();
  };

  if (licenceTypeAccess !== 'pro') {
    history.push('/');

    return null;
  }

  const isEditZonesFilled = () => {
    if (
      editZones.zones.length > 0 ||
      editZones.hasOwnProperty('buildingSite') ||
      editZones.hasOwnProperty('plot') ||
      editZones.hasOwnProperty('buildingSiteDuration')
    ) {
      return true;
    }

    return false;
  };
  const { mutate: updateDataComps } = useSaveProjectDataComps();

  const onSubmit = async () => {
    const data = getValues();

    if (isEditZonesFilled() && showCalculatorPreventModal === false) {
      setShowCalculatorPreventModal(true);
      return;
    }

    setShowCalculatorPreventModal(false);
    setButtonLoading(true);
    let speculativeDevelopmentDatasFromUpdatedBuildingZones = [];
    if (editZones?.zones?.length > 0) {
      editZones.zones
        .filter((zone) => zone.type === ZONE_TYPES.building)
        .map((zone) => {
          speculativeDevelopmentDatasFromUpdatedBuildingZones.push({
            ZoneId: zone.id,
            speculativeDevelopment: zone.speculativeDevelopment,
          });
        });
    }

    //transform permitDate to iso before patch
    if (data?.permitDate) {
      data.permitDate = data.permitDate.toISOString();
    }
    if (data?.DatasComp?.paGazObtentionDate) {
      data.DatasComp.paGazObtentionDate =
        data.DatasComp.paGazObtentionDate.toISOString();
    }

    //transfor exploitContrat to call format:
    if (currentProject.templateType === TYPE_EXPLOITATION) {
      const newExploitContract = [
        {
          value: data?.exploitContract?.[0].toISOString(),
          inclusive: true,
        },
        {
          value: data?.exploitContract?.[1].toISOString(),
          inclusive: true,
        },
      ];

      data.exploitContract = newExploitContract;
    }

    // remove defaultsBuildingSiteDuration if isBuildingSite is false
    if (!data.isBuildingSite) {
      data.defaultBuildingSiteDuration = null;
    }

    let defaultAllotmentAccordingToProjectType = allGroups.allotments.find(
      (allotment) => allotment.id === getAllotmentId(currentProject.templateType)
    );

    if (
      !data.ProjectOption.showAlternativeResult &&
      data.ProjectOption.CurrentAllotmentId !==
        defaultAllotmentAccordingToProjectType.id
    ) {
      data.ProjectOption.CurrentAllotmentId =
        defaultAllotmentAccordingToProjectType.id;
    }

    // editZones if update buildingSite

    // remove parcelArea because is in editZones
    delete data.parcelArea;

    // add projectId
    data.id = currentProject.id;

    let patchzones = null;

    if (
      editZones.zones.length > 0 ||
      editZones.hasOwnProperty('buildingSite') ||
      editZones.hasOwnProperty('plot') ||
      editZones.hasOwnProperty('buildingSiteDuration')
    ) {
      let editZoneFormatted = { ...editZones };
      editZoneFormatted.zones.map((zone) => {
        zone.area = stringToNumber(zone.area);
        return zone;
      });

      patchzones = await ctx.api.patchZones(id, editZoneFormatted);
    }
    const response = await ctx.api.patchProject(data);

    if (
      response.status === 200 &&
      (((editZones.zones.length > 0 ||
        editZones.hasOwnProperty('buildingSite') ||
        editZones.hasOwnProperty('plot') ||
        editZones.hasOwnProperty('buildingSiteDuration')) &&
        patchzones.status === 200) ||
        !patchzones)
    ) {
      dispatch(changeValueEquipments('hideColumns', []));
      setAlert('Modifications réalisées avec succès', 'success');
      // alert('Modifications réalisées avec succès.');
    } else {
      setAlert('Erreur lors de la modification', 'error');
    }

    setButtonLoading(false);
    dispatch(calculatorReset());
    dispatch(calculatorWaterReset());
    dispatch(fetchProjectById(id));
    if (speculativeDevelopmentDatasFromUpdatedBuildingZones.length > 0) {
      speculativeDevelopmentDatasFromUpdatedBuildingZones.forEach(
        (speculativeDevelopmentData) => {
          const buildingIndex = projectDataComps?.buildings?.findIndex(
            (building) => building.ZoneId === speculativeDevelopmentData.ZoneId
          );
          if (buildingIndex !== -1) {
            projectDataComps.buildings[buildingIndex].speculativeDevelopment =
              speculativeDevelopmentData.speculativeDevelopment;
          }
        }
      );
    }
    updateDataComps({ projectId: id, body: projectDataComps });

    // callback(currentIndicatorId);
  };

  const setAlert = (message, severity) => {
    if (severity) dispatch(updateMonitoring('alertSeverity', severity));
    dispatch(updateMonitoring('alertMessage', message));
  };

  const onSubmitError = (errors) => {
    console.log('getValues', getValues());
    console.log('onSubmitError', errors);
  };

  return (
    <>
      <SnackBarElement />
      <div className="patch-project-wrapper">
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {t('APP.SETTINGS.SETTINGS.PH_760972', {
                defaultValue: 'Suppression du projet',
              })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t('APP.SETTINGS.SETTINGS.PH_FBB93B', {
              defaultValue:
                'Vous êtes sur le point de supprimer définitivement votre projet, voulez-vous continuer?',
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setShow(false)}
              className="close-button"
              text={t('APP.SETTINGS.SETTINGS.PH_CE31D7', {
                defaultValue: 'Annuler',
              })}
            />
            <Button
              className="delete-button"
              onClick={deleteProject}
              text={t('APP.SETTINGS.SETTINGS.PH_5D0229', {
                defaultValue: 'Supprimer',
              })}
            />
          </Modal.Footer>
        </Modal>
        <Modal
          show={showCalculatorPreventModal}
          onHide={() => setShowCalculatorPreventModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t('APP.SETTINGS.SETTINGS.PH_1AF1C3', {
                defaultValue: 'Modification des paramètres',
              })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t('APP.SETTINGS.SETTINGS.PH_DDF633', {
              defaultValue:
                'Suite aux modifications effectuées, la calculatrice chantier ne sera pas actualisée. Etes-vous sûr(e) de vouloir effectuer ces modifications?',
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setShowCalculatorPreventModal(false)}
              className="close-button"
              text={t('APP.SETTINGS.SETTINGS.PH_6E21E6', {
                defaultValue: 'Annuler',
              })}
            />
            <Button
              className="delete-button"
              onClick={saveWithCalculatorPreventModal}
              text={t('APP.SETTINGS.SETTINGS.PH_735F25', {
                defaultValue: 'Continuer',
              })}
            />
          </Modal.Footer>
        </Modal>

        <main className="patch-project-forms-wrapper">
          <div className="left-forms-wrapper">
            <GeneralForm
              watch={watch}
              errors={errors}
              control={control}
              setValue={setValue}
            />
            <ParamsForm
              control={control}
              setValue={setValue}
              getValues={getValues}
            />
          </div>
          <div className="right-forms-wrapper">
            <ArchitectureForm
              callback={callback}
              errors={errors}
              control={control}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              updateSettingsDataWithDataComps={updateSettingsDataWithDataComps}
              projectDataComps={projectDataComps}
            />
          </div>
        </main>
        {/* <ZoneSection isSettings={true} sheets={newInfos.Sheets}/> */}

        <div className="patch-project-footer">
          <Button
            text={t('APP.SETTINGS.SETTINGS.PH_E7EF1C', {
              defaultValue: 'Supprimer le projet',
            })}
            className="delete-button"
            onClick={() => setShow(true)}
          />
          <Button
            text={t('APP.SETTINGS.SETTINGS.PH_C07FA0', {
              defaultValue: 'Enregistrer les modifications',
            })}
            className="purple-button"
            loading={buttonLoading}
            disabled={buttonLoading}
            onClick={handleSubmit(onSubmit, onSubmitError)}
          />
        </div>
      </div>
    </>
  );
};

export default Settings;
