import {
  ALLOTMENT_IDS,
  REFERENCE_TYPE_NAME,
  TEMPLATE_TYPE_NAME,
  TYPE_EPCM,
  TYPE_RE2020,
} from '../../../globalVariable/typeProject';
import { Button, SearchGroup } from '@photocarbone/nooco-ui';
import { Col, Form } from 'react-bootstrap';
import { StateDTO } from '../../../models/GeneralDTO';
import {
  closest,
  findParentNode,
  handleAllotmentNodes,
  returnDefaultValueForSearchGroupSelectType,
  returnSearchBarOptionsFiltered,
} from '../../../shared/utils';
import { fetchProjectById } from '../../../../actions/projects';
import {
  returnListOfZonesForSelect,
  returnTypeFicheAfterChangeNode,
} from '../ListEquipments/ListEquipmentsFunction';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ButtonUi from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import InputSousLotRe2020 from '../../../../containers/InputSousLotRe2020';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Loader from '@material-ui/core/CircularProgress';
import NoocoCheckbox from '../../../shared/NoocoCheckbox';
import React, { useEffect, useState } from 'react';
import ReemployCheck from '../../../shared/ReemployCheck';
import SearchBar from '../../../shared/SearchBar';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { OptionType } from '../../../globalVariable/enumType';
import { useTranslation } from 'react-i18next';
import { returnTypeOfEquipment } from '../ListEquipments';

interface EditContributorProps {
  setCurrentEquipment: (currentEquipment: any) => void;
  currentEquipment: {};
  selectedSheetObj: {};
  selectedBrand: {};
  filteredSheets: {}[];
  currentDestination: number;
  groups: {}[];
  refCaracs: any;
  setSelectedBrand: Function;
  setSelectedSheetObj: Function;
  setReEmploy: Function;
  reEmploy: boolean;
  setIsEditMode: Function;
  updateFormValue: Function;
  changeValueEquipments: Function;
  ctx: any;
  setRefCaracs: Function;
  setFilteredSheets: Function;
  handlePepFdesDed: Function;
  brands: [];
  setCurrentDestination: Function;
}

export const handleDisabledConfirmButton = (
  isEditing: boolean,
  currentEquipment,
  displayOverwritteFluidsForm?: boolean,
  selectedSubEquip?: string | number,
  subEquipQuantity?: string | number
): boolean => {
  const noCaracValue = currentEquipment.caracs.find(
    (el) => !el.SheetCarac || el.SheetCarac?.value?.length === 0
  );

  const fluidsChangedWithoutAllValuesRequired = () => {
    if (displayOverwritteFluidsForm) {
      if (!selectedSubEquip || !subEquipQuantity) {
        return true;
      }
    }

    return false;
  };

  if (!isEditing || noCaracValue || fluidsChangedWithoutAllValuesRequired()) {
    return true;
  }

  return false;
};

const EditContributor = ({
  setCurrentEquipment,
  currentEquipment,
  selectedSheetObj,
  selectedBrand,
  filteredSheets,
  currentDestination,
  groups,
  refCaracs,
  setSelectedBrand,
  setSelectedSheetObj,
  setReEmploy,
  reEmploy,
  setIsEditMode,
  updateFormValue,
  changeValueEquipments,
  ctx,
  setRefCaracs,
  setFilteredSheets,
  handlePepFdesDed,
  brands,
  setCurrentDestination,
}: EditContributorProps) => {
  const {
    currentProject,
    allGroups,
    currentAllotment,
    fluids,
    zones,
    inputValue,
    nodesAvailableForProjectType,
    certifiedAllotments,
    currentIndicator,
  } = useSelector((state: StateDTO) => state.projects);
  const { re2020lot } = useSelector((state: StateDTO) => state.equipments);

  const [displayOverwritteFluidsForm, setDisplayOverwritteFluidsForm] =
    useState(false);
  const [urbanChoice, setUrbanChoice] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [datas, setDatas] = useState();
  const [isPatchLoading, setIsPatchLoading] = useState(false);
  const [selectedSubEquip, setSelectedSubEquip] = useState(
    fluids?.find((fluid) => fluid.libelle.includes('R410A'))?.id
  );
  const [subEquipQuantity, setSubEquipQuantity] = useState(undefined);
  const [sheetsToShow, setSheetsToShow] = useState(undefined);
  const [zonesName, setZonesName] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const searchBarOptionsFiltered = returnSearchBarOptionsFiltered({
    allAllotments: allGroups.flatAllotments,
    allotmentsAvailable: nodesAvailableForProjectType,
    iniesOptions: [],
    bundleOptions: [],
  });

  useEffect(() => {
    if (
      currentEquipment &&
      currentEquipment?.childEquip &&
      (!currentEquipment?.childEquip?.name.includes('R410A') ||
        currentEquipment?.childEquip?.ProjectSheet.quantity !== 0)
    ) {
      setDisplayOverwritteFluidsForm(true);

      if (currentEquipment?.childEquip?.ProjectSheet.quantity !== 0) {
        setSelectedSubEquip(currentEquipment?.childEquip?.id);
        setSubEquipQuantity(currentEquipment?.childEquip?.ProjectSheet.quantity);
      }
    }
  }, [currentEquipment]);

  useEffect(() => {
    const sheets = filteredSheets.filter(
      (el) => el.BrandId === Number(selectedBrand)
    );

    setSheetsToShow(sheets);
  }, [selectedBrand]);

  /**
   * controls the list
   */
  useEffect(() => {
    if (!selectedBrand) {
      //reset selected sheet

      return;
    }

    let userCaracs = currentEquipment.caracs;

    if (
      selectedBrand &&
      userCaracs.length ===
        refCaracs.caracs.filter((carac) => carac.neededOnCreation).length
    ) {
      const userCarac = userCaracs.find((c) => c.id === refCaracs.xAxisId);
      const userDiscrete = userCaracs.find((c) => c.id === refCaracs.discreteId);
      let brandSheets = filteredSheets.filter(
        (sheet) => sheet.BrandId === Number(selectedBrand)
      );
      let filterSheets = [];

      if (refCaracs.discreteId) {
        brandSheets.forEach((sheet) => {
          const carac = sheet.Caracs.find(
            (carac) => carac.id === refCaracs.discreteId
          );

          if (carac) {
            if (carac.SheetCarac.value.find((el) => el === userDiscrete?.value)) {
              filterSheets.push(sheet);
            }
          }
        });
      } else {
        filterSheets = brandSheets;
      }

      if (refCaracs.secondDiscreteId) {
        filterSheets = filterSheets.filter(
          (sheet) =>
            sheet.Caracs.find((carac) => carac.id == refCaracs.secondDiscreteId)
              .SheetCarac.value[0]
        );
      }

      if (userCarac) {
        // eslint-disable-next-line no-useless-catch
        try {
          setSheetsToShow(
            closest(userCarac.SheetCarac.value[0], filterSheets, userCarac)
          );
        } catch (e) {
          throw e;
        }

        return;
      }
      setSheetsToShow(brandSheets);
    }
  }, [selectedBrand, currentEquipment.caracs]);

  // Management of the MdLinks Button

  useEffect(() => {
    if (currentEquipment) {
      let result = handleAllotmentNodes(
        currentEquipment.MasterDataEquipment,
        currentAllotment,
        allGroups.flatAllotments,
        currentProject
      );

      result = result.filter((el) => !el.isObsolete);
      setDatas(result);
    }

    let all = returnListOfZonesForSelect(zones, []);

    setZonesName(
      currentEquipment?.materiel === 'Panneau'
        ? all.filter((el) => el.type !== 'buildingSite')
        : all
    );
  }, [currentEquipment]);

  const renderFluidsModal = () => {
    let refFluide = null;

    if (currentEquipment.childEquip) {
      refFluide = fluids.find((fluid) =>
        fluid.name.includes(currentEquipment.childEquip.name)
      );

      if (refFluide) {
        if (!selectedSubEquip) {
          setSelectedSubEquip(refFluide.id);
        }
      }
    }

    return (
      <div style={{ padding: '1em' }}>
        <Form.Group
          key={selectedSheetObj}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            paddingLeft: 0,
          }}
          as={Col}
        >
          <Form.Label>
            {t('APP.EDITCONTRIBUTOR.INDEX.PH_A11A39', {
              defaultValue: 'Type de Fluide',
            })}
          </Form.Label>

          {
            <Form.Control
              as="select"
              custom
              required
              defaultValue={
                refFluide
                  ? refFluide.id
                  : fluids.find((fluid) => fluid.libelle.includes('R410A'))?.id
              }
              onChange={(e) => {
                setSelectedSubEquip(e.target.value);
                setIsEditing(true);
              }}
            >
              <option key={'empty'} value={''}>
                {t('APP.EDITCONTRIBUTOR.INDEX.PH_0564F8', {
                  defaultValue: 'Choisir une valeur',
                })}
              </option>

              {fluids.map((fluid) => (
                <option key={fluid.id} value={fluid.id}>
                  {fluid.libelle}
                </option>
              ))}
            </Form.Control>
          }
        </Form.Group>

        <Form.Group
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            paddingLeft: 0,
          }}
          as={Col}
        >
          <Form.Label>
            {currentEquipment.type_fiche?.includes('FORFAIT')
              ? t('APP.EDITCONTRIBUTOR.INDEX.PH_2E1211', {
                  defaultValue: 'Masse de fluide totale (kg)',
                })
              : t('APP.EDITCONTRIBUTOR.INDEX.PH_E7ECB4', {
                  defaultValue: 'Masse de fluide par équipement (kg)',
                })}
          </Form.Label>
          <Form.Control
            autoComplete="off"
            autoCorrect="off"
            spellCheck="off"
            type="number"
            defaultValue={
              !currentEquipment.childEquip
                ? null
                : currentEquipment.childEquip?.ProjectSheet?.quantity
            }
            onChange={(e) => {
              setSubEquipQuantity(e.target.value);
              setIsEditing(true);
            }}
            required
          />

          <Form.Control.Feedback type="invalid">
            {t('APP.EDITCONTRIBUTOR.INDEX.PH_C5D673', {
              defaultValue: 'Veuillez renseigner une quantité.',
            })}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
    );
  };

  // Need to be redo
  const handleEditMd = async (newMd) => {
    setIsEditing(true);

    let newMdForAllot = allGroups.type.find(
      (md) => md.id === newMd.MasterDataEquipmentId
    );

    let isSameMd = newMdForAllot.id === currentEquipment?.MasterDataEquipment?.id;

    setCurrentEquipment({
      ...currentEquipment,
      MasterDataEquipment: newMdForAllot,
      MasterDataEquipmentId: newMdForAllot.id,
      AllotmentNodeId: newMd.id,
      name: newMd.name,
      AllotmentNode: newMd,
      type_fiche: returnTypeFicheAfterChangeNode(
        isSameMd,
        currentEquipment.type_fiche
      ),
      SheetId: isSameMd ? currentEquipment.SheetId : null,
      ProjectSheet: {
        ...currentEquipment.ProjectSheet,
        AllotmentNodes: [
          newMd,
          allGroups.flatAllotments.find(
            (node) => node.id === newMd.Re2020AllotmentNodeId
          ),
          allGroups.flatAllotments.find(
            (node) => node.id === newMd.EpcmAllotmentNodeId
          ),
        ],
      },
    });
    if (!isSameMd) {
      setSheetsToShow([]);
      setSelectedBrand();
      setSelectedSheetObj();
    }

    const response = await ctx.api
      .getEquipmentCaracListById(newMd.id)
      .then((response) => {
        setRefCaracs(response.data);
        setFilteredSheets(response.data.sheets);
        handlePepFdesDed(response.data.sheets);

        setCurrentEquipment({
          ...currentEquipment,
          MasterDataEquipment: newMdForAllot,
          MasterDataEquipmentId: newMdForAllot.id,
          unitMd: newMd.unit,
          AllotmentNodeId: newMd.id,
          name: newMd.name,
          AllotmentNode: newMd,
          caracs: response.data.caracs
            .filter((el) => el.neededOnCreation)
            .map((el) => {
              if (currentEquipment.caracs.find((carac) => carac.id === el.id)) {
                return currentEquipment.caracs.find((carac) => carac.id === el.id);
              } else {
                let initValue =
                  el.caracList && el.caracList[0]
                    ? el.caracList.sort((a, b) => a.value.localeCompare(b.value))[0]
                        .value
                    : null;

                let isCaracSecondDiscrete =
                  response.data.caracCombos?.valueList?.find(
                    (car) => car.id === el.id
                  );

                if (isCaracSecondDiscrete) {
                  initValue = isCaracSecondDiscrete.valueList[0].value;
                }

                return {
                  ...el,
                  SheetCarac: { value: initValue ? [initValue] : [] },
                };
              }
            }),
          type_fiche: returnTypeFicheAfterChangeNode(
            isSameMd,
            currentEquipment.type_fiche
          ),
          SheetId: isSameMd ? currentEquipment.SheetId : null,
          ProjectSheet: {
            ...currentEquipment.ProjectSheet,
            AllotmentNodes: [
              newMd,
              allGroups.flatAllotments.find(
                (node) => node.id === newMd.Re2020AllotmentNodeId
              ),
              allGroups.flatAllotments.find(
                (node) => node.id === newMd.EpcmAllotmentNodeId
              ),
            ],
          },
        });
      });
  };

  const handleEditModeCaracsChange = (e, carac) => {
    // find carac to modify
    let caracToUpdate = currentEquipment.caracs.find(
      (elem) => elem.name === carac.name
    );

    // // if caracUpdate is discrete check if childCarac can still had same value
    if (
      (!caracToUpdate &&
        refCaracs.caracCombos?.valueList[0] &&
        refCaracs.caracCombos?.valueList[0].id === carac.id) ||
      (caracToUpdate &&
        refCaracs.caracCombos &&
        caracToUpdate.id === refCaracs.caracCombos.id &&
        refCaracs.caracCombos.valueList[0].id)
    ) {
      currentEquipment.caracs = [
        ...currentEquipment.caracs.filter((el) => el.id !== refCaracs.id),
        {
          SheetCarac: {
            value: [
              refCaracs.caracCombos.valueList.find(
                (el) => el.value === e.target.value
              ).valueList[0].value,
            ],
            CaracId: refCaracs.id,
            SheetId: currentEquipment.ProjectSheet.SheetId,
          },
        },
      ];
      const findCarac = refCaracs.caracCombos.valueList.find(
        (el) => el.value === e.target.value
      );
      const updatedCarac = currentEquipment.caracs.find(
        (carac) => carac.id === findCarac.id
      );

      updatedCarac.SheetCarac.value = [
        refCaracs.caracCombos.valueList.find((el) => el.value === e.target.value)
          .valueList[0].value,
      ];
    }

    let newCurrentEquipment = {};
    // If carac doesn't exist in data, create it.
    if (!caracToUpdate) {
      caracToUpdate = {
        name: carac.name,
        SheetCarac: {
          value: [e.target.value],
        },
      };

      newCurrentEquipment = {
        ...currentEquipment,
        caracs: [...currentEquipment.caracs, caracToUpdate],
      };
    } else {
      // change value to input value
      if (!caracToUpdate?.SheetCarac) {
        caracToUpdate.SheetCarac = {
          CaracId: caracToUpdate.id,
          SheetId: currentEquipment.ProjectSheet.SheetId,
          ProjectSheetId: null,
          value: [e.target.value],
        };
      } else {
        caracToUpdate.SheetCarac.value =
          e.target.value !== '' ? [e.target.value] : [];
      }
      // replace carac by updated one
      newCurrentEquipment = {
        ...currentEquipment,
        caracs: currentEquipment.caracs.map((elem) => {
          if (elem.id === carac.id) {
            return caracToUpdate;
          } else {
            return elem;
          }
        }),
      };
    }

    // set (refresh) state
    setCurrentEquipment(newCurrentEquipment);
  };

  const handleEditEquipment = async (event, createSimilar) => {
    setIsPatchLoading(true);

    let allotmentNodes = re2020lot
      ? [
          ...currentEquipment.ProjectSheet.AllotmentNodes.filter(
            (node) => node.AllotmentId !== re2020lot.AllotmentId
          ),
          re2020lot,
        ]
      : currentEquipment.ProjectSheet.AllotmentNodes;

    if (selectedSheetObj) {
      allotmentNodes = [
        ...allotmentNodes.filter((node) => node.AllotmentId !== ALLOTMENT_IDS.NOOCO),
        selectedSheetObj.AllotmentNodes[0],
      ];
    }

    const body = {
      id: currentEquipment.mdId,
      AllotmentNodes: allotmentNodes,
      quantity: currentEquipment.quantity,
      caracs: [],
      ZoneId: currentDestination,
      SheetId: selectedSheetObj?.id || null,
      type_fiche:
        currentEquipment.type_fiche === 'FC'
          ? 'FC'
          : currentEquipment.type_fiche === 'REFURBISHED' && !reEmploy
          ? 'users'
          : reEmploy
          ? 'REFURBISHED'
          : selectedSheetObj
          ? selectedSheetObj.type_fiche
          : currentEquipment.type_fiche,
      ProjectSheetId: currentEquipment.ProjectSheet.id,
      UrbanNetworkId: urbanChoice || null,
      comment: currentEquipment.comment,
      customName: currentEquipment.customName,
      ddv: currentEquipment.ProjectSheet.ddv,
    };

    if (currentEquipment.type_fiche === 'REFURBISHED' && !reEmploy) {
      delete body.SheetId;
    }

    currentEquipment.caracs.forEach((carac) => {
      if (!carac) return;

      let value = null;

      if (!carac.SheetCarac) {
        value =
          refCaracs.caracCombos.id === carac.id
            ? [refCaracs.caracCombos.valueList[0]?.value]
            : null;
      }

      const newCarac = {
        name: carac.name,
        value: value || carac.SheetCarac?.value.flat(Infinity),
        unit: carac.unit,
      };

      body.caracs.push(newCarac);
    });

    if (
      ((currentEquipment.MasterDataEquipment?.hasFluid && !selectedSheetObj) ||
        (currentEquipment.MasterDataEquipment?.hasFluid &&
          currentEquipment.type_fiche === 'DED') ||
        (currentEquipment.MasterDataEquipment?.hasFluid &&
          currentEquipment.type_fiche === 'FORFAIT')) &&
      ![
        TEMPLATE_TYPE_NAME.EPCM,
        TEMPLATE_TYPE_NAME.RE2020,
        TEMPLATE_TYPE_NAME.BBCA,
      ].includes(currentProject.templateType)
    ) {
      body.fluid = {
        selectedFluid: selectedSubEquip || currentEquipment.childEquip?.id,
        fluidQuantity:
          subEquipQuantity ||
          currentEquipment.childEquip?.ProjectSheet.quantity ||
          1,
      };

      if (
        (currentEquipment.type_fiche === 'FORFAIT' ||
          currentEquipment.type_fiche === 'DED') &&
        !displayOverwritteFluidsForm
      ) {
        body.fluid = {
          selectedFluid: fluids.find((fluid) => fluid.libelle.includes('R410A'))?.id,
          fluidQuantity: 0,
        };
      }
    }

    const create = await ctx.api
      .patchEquipment(body, currentProject.id)
      .then((res) => {
        changeValueEquipments('isLoadingProject', true);
        setIsEditMode(false);
        changeValueEquipments('re2020lot', null);
        setSelectedSheetObj();
        setIsPatchLoading(false);

        // callback();
        dispatch(fetchProjectById(currentProject.id, currentIndicator.id));
      });
  };

  const prodConsoValidation = (carac, initialValue) => {
    const value = initialValue.target.value;
    let finalValue = initialValue.target.value;

    if (carac.name === 'Autoconsommation annuelle : Eef_Prod_AC (kWh/m2/an)') {
      const productionPerYear =
        currentEquipment.caracs?.find(
          (el) => el.name === 'Production annuelle : Eef_Prod (kWh/m2/an)'
        )?.SheetCarac.value[0] || 1;

      if (productionPerYear) {
        if (parseFloat(value) > parseFloat(productionPerYear)) {
          finalValue = productionPerYear;
        }
      }
    }

    if (carac.name === 'Production annuelle : Eef_Prod (kWh/m2/an)') {
      const consoPerYearVal = currentEquipment.caracs?.find(
        (el) => el.name === 'Autoconsommation annuelle : Eef_Prod_AC (kWh/m2/an)'
      ).SheetCarac.value[0];
      const consoPerYearRef = refCaracs.caracs?.find(
        (el) => el.name === 'Autoconsommation annuelle : Eef_Prod_AC (kWh/m2/an)'
      );

      if (consoPerYearRef) {
        if (parseFloat(consoPerYearVal) > parseFloat(value)) {
          handleEditModeCaracsChange({ target: { value: value } }, consoPerYearRef);
        }
      }
    }

    return { target: { value: finalValue } };
  };

  return (
    <div
      className="wrapper-add"
      style={{ minHeight: window.screen.availHeight - 350 }}
    >
      <div className="composant-part">
        <h4>
          {t('APP.EDITCONTRIBUTOR.INDEX.PH_FDA15E', {
            defaultValue: 'Modification équipement/matériau',
          })}
        </h4>
        <div className="edit-equipment-name">
          {currentEquipment.type_fiche !== 'users' &&
            currentEquipment.type_fiche.includes('EMPTY') &&
            currentEquipment.type_fiche !== 'FC' && (
              <img
                src={iniesPic}
                alt
                style={{
                  width: window.screen.availHeight < 950 ? '70px' : '50px',
                  marginBottom: '10px',
                  display: 'block',
                }}
              />
            )}
          <>
            {currentEquipment.type_fiche !== 'CONFIG' &&
              currentEquipment.type_fiche !== 'FORFAIT' &&
              currentEquipment.type_fiche !== 'DEC' && (
                <>
                  <SearchGroup
                    t={t}
                    searchBarOptionsFiltered={searchBarOptionsFiltered}
                    certifiedAllotments={certifiedAllotments}
                    triggerBreadcrumb={() => {
                      updateFormValue('openBreadcrumb', true);
                    }}
                    bundleOptions={[]}
                    findParentNode={findParentNode}
                    projectTypeId={currentProject.ProjectTypeId}
                    allAllotments={allGroups.flatAllotments}
                    allotmentsAvailable={nodesAvailableForProjectType.filter(
                      (el) => !el.isObsolete
                    )}
                    api={ctx.api}
                    dropdownProps={{
                      onSelect: () => {},
                      onChange: (value) => {
                        if (
                          [
                            OptionType.EQUIPMENTS,
                            OptionType.INIES,
                            OptionType.WATER,
                            OptionType.ENERGY,
                          ].includes(value)
                        ) {
                          return;
                        } else if (typeof value === 'number') {
                          let node = allGroups.flatAllotments.find(
                            (allotmentNode) => allotmentNode.id === value
                          );
                          handleEditMd(node);
                        } else {
                          setIsEditing(true);
                          setReEmploy(false);
                          setSelectedSheetObj(value);
                          if (currentAllotment.id !== ALLOTMENT_IDS.NOOCO) {
                            let key =
                              currentAllotment.id === ALLOTMENT_IDS.EPCM
                                ? 'EpcmAllotmentNodeId'
                                : 'Re2020AllotmentNodeId';
                            if (
                              value &&
                              value.AllotmentNodes &&
                              value.AllotmentNodes[0] &&
                              value.AllotmentNodes[0][key]
                            ) {
                              changeValueEquipments(
                                're2020lot',
                                allGroups.flatAllotments.find(
                                  (node) => node.id === value.AllotmentNodes[0][key]
                                )
                              );
                            }
                          }
                        }
                      },
                      placeholder: 'Rechercher...',
                      treeCheckable: false,
                      value:
                        selectedSheetObj?.name ||
                        currentEquipment.ProjectSheet.AllotmentNodes.find(
                          (node) => node.AllotmentId === ALLOTMENT_IDS.NOOCO
                        ).name,
                    }}
                    dropdownWidth="200px"
                    selectProps1={{
                      defaultValue: returnDefaultValueForSearchGroupSelectType(
                        currentEquipment,
                        allGroups
                      ),
                      onChange: (value) => {
                        updateFormValue('searchValue', null);
                        updateFormValue('nodeSearchValue', null);
                        updateFormValue('selectedIniesSheet', null);
                        setSelectedSheetObj(null);
                      },
                      filterOptions: ['equipements', 'inies', 'eau', 'energie'],
                    }}
                    selectProps2={{
                      ListType:
                        currentAllotment.id !== 1
                          ? [currentAllotment.name.toUpperCase()]
                          : ['Nooco'],
                      noocoOptions: datas?.filter((el) => !el.isObsolete) || [],
                      onChange: (newValue) => {
                        setIsEditing(true);
                        let node = allGroups.flatAllotments.find(
                          (allotmentNode) =>
                            allotmentNode.name === newValue &&
                            allotmentNode.AllotmentId === currentAllotment.id
                        );

                        if (currentAllotment.id === ALLOTMENT_IDS.NOOCO) {
                          node = datas
                            ?.find((el) => el.name === newValue)
                            ?.details.find(
                              (allotmentNode) => allotmentNode.MasterDataEquipmentId
                            );

                          handleEditMd(node);
                        } else {
                          changeValueEquipments('re2020lot', node);
                        }
                      },
                      value: re2020lot
                        ? [re2020lot]
                        : [
                            currentEquipment.ProjectSheet.AllotmentNodes.find(
                              (node) => node.AllotmentId === currentAllotment.id
                            ),
                          ],
                    }}
                    selectWidth="100px"
                  />
                </>
              )}

            {(currentEquipment.type_fiche === 'CONFIG' ||
              currentEquipment.type_fiche === 'FORFAIT' ||
              currentEquipment.type_fiche === 'DEC') && (
              <TextField
                id="outlined-basic"
                variant="outlined"
                style={{ width: '40%' }}
                value={
                  currentEquipment.type_fiche === 'users'
                    ? currentEquipment.MasterDataEquipment?.name
                    : currentEquipment.name
                }
                disabled
              />
            )}
          </>
        </div>
        <div className="equipment-details">
          <div className="quantity-ddv">
            <Form.Group controlId="formGridOverseer">
              <Form.Label key={currentEquipment.unitMd}>
                {t('APP.EDITCONTRIBUTOR.INDEX.PH_F31DF0', {
                  value: currentEquipment.MasterDataEquipment?.unit,
                  defaultValue: 'Quantité ({value})',
                })}
              </Form.Label>
              <Form.Control
                autoComplete="off"
                min="1"
                disabled={currentEquipment.type_fiche === 'FORFAIT'}
                value={currentEquipment.quantity}
                autoCorrect="off"
                spellCheck="off"
                type="number"
                size="lg"
                onChange={(e) => {
                  setCurrentEquipment({
                    ...currentEquipment,
                    quantity: e.target.value,
                  });
                  setIsEditing(true);
                }}
                required
              />

              <Form.Control.Feedback type="invalid">
                {t('APP.EDITCONTRIBUTOR.INDEX.PH_D42AF6', {
                  defaultValue: 'Veuillez renseigner une quantité.',
                })}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label style={{ marginBottom: '3px', cursor: 'initial' }}>
                {t('APP.EDITCONTRIBUTOR.INDEX.PH_DEB295', {
                  defaultValue: 'Durée de vie (an)',
                })}
                <Tooltip
                  title={t('APP.EDITCONTRIBUTOR.INDEX.PH_41BBA2', {
                    defaultValue:
                      'Vous pouvez choisir une durée de vie inférieure à la durée de vie de référence',
                  })}
                >
                  <InfoIcon />
                </Tooltip>
              </Form.Label>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Form.Control
                  autoComplete="off"
                  min="1"
                  disabled={currentEquipment.type_fiche === 'FORFAIT'}
                  defaultValue={
                    currentEquipment.ProjectSheet.modifiedDdv || currentEquipment.ddv
                  }
                  value={currentEquipment.ProjectSheet.ddv}
                  autoCorrect="off"
                  spellCheck="off"
                  type="number"
                  size="lg"
                  onChange={(e) => {
                    if (e.target.value <= currentEquipment.ddv) {
                      setCurrentEquipment({
                        ...currentEquipment,
                        ProjectSheet: {
                          ...currentEquipment.ProjectSheet,
                          ddv: e.target.value,
                        },
                      });
                      setIsEditing(true);
                    }
                  }}
                  required
                />

                <Tooltip
                  title={t('APP.EDITCONTRIBUTOR.INDEX.PH_6BC0ED', {
                    defaultValue: 'Restaurer la durée de vie de réference',
                  })}
                >
                  <Button
                    className="purple-button"
                    style={{
                      alignItems: 'center',
                      backgroundColor: '#f1f3f9',
                      color: '#a2a4aa',
                      fontWeight: 'bold',
                      fontStyle: 'italic',
                      borderWidth: '0px',
                      height: '100%',
                      fontSize: '0.8rem',
                    }}
                    onClick={() => {
                      setCurrentEquipment({
                        ...currentEquipment,
                        ProjectSheet: {
                          ...currentEquipment.ProjectSheet,
                          ddv: currentEquipment.ddv,
                        },
                      });
                      setIsEditing(true);
                    }}
                    text={`Référence\n${currentEquipment.ddv}`}
                  />
                </Tooltip>
              </div>
              <Form.Control.Feedback type="invalid">
                {t('APP.EDITCONTRIBUTOR.INDEX.PH_E1A7CC', {
                  defaultValue: 'Veuillez renseigner une durée de vie.',
                })}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          {zones?.length > 2 && (
            <div className="edit-destination">
              <Form.Group style={{ marginRight: '35px' }}>
                <Form.Label>
                  {t('APP.EDITCONTRIBUTOR.INDEX.PH_59AFA9', {
                    defaultValue: 'Destination',
                  })}
                </Form.Label>
                <Form.Control
                  required
                  as="select"
                  custom
                  onChange={(e) => {
                    setIsEditing(true);
                    setCurrentDestination(e.target.value);
                  }}
                  value={currentDestination}
                  style={{ width: '215px' }}
                >
                  {zonesName &&
                    zonesName
                      ?.sort(function (a, b) {
                        if (a.id < b.id) {
                          return -1;
                        }

                        if (a.id > b.id) {
                          return 1;
                        }

                        return 0;
                      })
                      .map((elem) => (
                        <option key={elem.id} value={elem.id}>
                          {elem.name}
                        </option>
                      ))}
                </Form.Control>
              </Form.Group>
            </div>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ padding: '1em', paddingTop: '0', width: '30%' }}>
            <p className="subtitle">Commentaire (facultatif)</p>
            <Tooltip
              title={t('APP.EDITCONTRIBUTOR.INDEX.PH_D32280', {
                defaultValue: 'Ajouter un commentaire',
              })}
              placement="top"
            >
              <Form.Control
                style={{ width: '100%' }}
                defaultValue={currentEquipment.comment}
                onChange={(e) => {
                  setCurrentEquipment({
                    ...currentEquipment,
                    comment: e.target.value,
                  });

                  setIsEditing(true);
                }}
              />
            </Tooltip>
          </div>
          <div style={{ padding: '1em', paddingTop: '0', width: '30%' }}>
            <p className="subtitle">
              {t('APP.EDITCONTRIBUTOR.INDEX.PH_606C13', {
                defaultValue: 'Libellé source (facultatif)',
              })}
            </p>
            <Form.Control
              style={{ width: '100%' }}
              defaultValue={
                currentEquipment.originalName || currentEquipment.customName
              }
              onChange={(e) => {
                setCurrentEquipment({
                  ...currentEquipment,
                  customName: e.target.value,
                });

                setIsEditing(true);
              }}
            />
          </div>
        </div>

        {currentEquipment.ProjectSheet.UrbanNetwork &&
          zones.find((el) => el.IsConnectedToUrbanNetwork) && (
            <div
              className="urban-network"
              style={{ padding: '1em', paddingTop: '0' }}
            >
              <p className="subtitle">
                {t('APP.EDITCONTRIBUTOR.INDEX.PH_9DD7A3', {
                  defaultValue: 'Réseau',
                })}
              </p>
              <Autocomplete
                id="checkboxes-tags-demo"
                options={urbanNetworks}
                defaultValue={currentEquipment.ProjectSheet.UrbanNetwork}
                onChange={(event, newValue) => {
                  setIsEditing(true);
                  setUrbanChoice(newValue.id);
                }}
                size={'small'}
                getOptionLabel={(option) =>
                  `${option.name} - ${option.type === 'C' ? 'Chaud' : 'Froid'}`
                }
                renderInput={(params) => (
                  <Tooltip
                    title={t('APP.EDITCONTRIBUTOR.INDEX.PH_65D6B7', {
                      defaultValue:
                        'Chercher sur tous les champs de la liste des contributeurs',
                    })}
                  >
                    <TextField
                      {...params}
                      placeholder={t('APP.EDITCONTRIBUTOR.INDEX.PH_57C90C', {
                        defaultValue:
                          'Rechercher un identifiant ou un nom de réseau',
                      })}
                      variant="outlined"
                      className="researchBar"
                    />
                  </Tooltip>
                )}
              />
            </div>
          )}
      </div>

      {/* Carac widget */}
      {!(currentEquipment.materiel === 'Panneau' && currentProject.isRset) && (
        <div
          style={
            refCaracs?.caracs?.length > 0 &&
            currentEquipment.categorie === 'Photovoltaïque'
              ? {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  flexDirection:
                    currentEquipment.type_fiche === 'users' ? 'column' : null,
                  flexWrap: 'wrap',
                }
              : null
          }
        >
          {refCaracs?.caracs.length > 0 &&
            !selectedSheetObj &&
            ((currentEquipment.type_fiche === 'users' &&
              refCaracs?.caracs.filter(
                (el) => el.neededOnCreation || el.neededOnImplementation
              ).length > 0) ||
              (currentEquipment.type_fiche === 'DED' &&
                currentEquipment.MasterDataEquipment?.hasFluid &&
                currentProject.templateType !== TYPE_RE2020 &&
                currentProject.templateType !== TYPE_EPCM) ||
              (currentEquipment.type_fiche === 'FORFAIT' &&
                currentEquipment?.MasterDataEquipment?.hasFluid) ||
              currentEquipment.type_fiche === 'FC' ||
              currentEquipment.categorie === 'Photovoltaïque') && (
              <div className="composant-part carac-wrapper">
                <h4>
                  {t('APP.EDITCONTRIBUTOR.INDEX.PH_2947DE', {
                    defaultValue: 'Caractéristiques',
                  })}
                </h4>
                <div className="carac-details">
                  {refCaracs.caracs.map((carac, index) => {
                    if (
                      (carac.name.includes('Autoconsommation') ||
                        carac.name.includes('Production')) &&
                      currentEquipment.MasterDataEquipment?.name.includes(
                        'Panneau'
                      ) &&
                      currentProject.isRset
                    ) {
                      return;
                    }

                    if (carac.neededOnCreation || carac.neededOnImplementation) {
                      let caracListOptions = carac.caracList;
                      if (
                        currentEquipment.caracs?.find(
                          (el) => el.id === refCaracs.caracCombos?.id
                        )?.SheetCarac?.value[0] &&
                        refCaracs.caracCombos?.valueList.find(
                          (el) =>
                            el.value ===
                              currentEquipment.caracs?.find(
                                (el) => el.id === refCaracs.caracCombos?.id
                              )?.SheetCarac?.value[0] && carac.name === el.name
                        )
                      ) {
                        caracListOptions = refCaracs.caracCombos?.valueList.find(
                          (el) =>
                            el.value ===
                              currentEquipment.caracs?.find(
                                (el) => el.id === refCaracs.caracCombos?.id
                              )?.SheetCarac.value[0] && carac.name === el.name
                        )?.valueList;
                      }

                      const value = currentEquipment.caracs.find(
                        (elemCarac) => elemCarac.name === carac.name
                      );

                      return (
                        <Form.Group key={index} controlId="formGridOverseer">
                          {(currentEquipment.type_fiche === 'users' ||
                            currentEquipment.type_fiche === 'FC' ||
                            carac.neededOnImplementation) && (
                            <Form.Label>
                              {carac.unit
                                ? `${carac.name} (${carac.unit})`
                                : `${carac.name} `}
                            </Form.Label>
                          )}
                          {(currentEquipment.type_fiche === 'users' ||
                            currentEquipment.type_fiche === 'FC' ||
                            carac.neededOnImplementation) &&
                            refCaracs.caracs[index].caracList && (
                              <Form.Control
                                key={currentEquipment.caracs}
                                custom
                                as="select"
                                defaultValue={
                                  caracListOptions.find(
                                    (el) => el.value === value?.SheetCarac?.value[0]
                                  )?.value ||
                                  caracListOptions.find((el) => el.value === 'Aucun')
                                    ?.value ||
                                  0
                                }
                                onChange={(e) => {
                                  setIsEditing(true);
                                  handleEditModeCaracsChange(e, carac);
                                }}
                              >
                                {(!value || !value.SheetCarac) && (
                                  <option key={0} value={0}>
                                    -
                                  </option>
                                )}

                                {caracListOptions
                                  .sort((a, b) => a.value - b.value)
                                  .map((carac) => (
                                    <option key={carac.id} value={carac.value}>
                                      {carac.value}
                                    </option>
                                  ))}
                              </Form.Control>
                            )}
                          {(currentEquipment.type_fiche === 'users' ||
                            currentEquipment.type_fiche === 'FC' ||
                            carac.neededOnImplementation) &&
                            !refCaracs.caracs[index].caracList && (
                              <Form.Control
                                required
                                autoComplete="off"
                                autoCorrect="off"
                                spellCheck="off"
                                type="number"
                                value={
                                  value?.SheetCarac &&
                                  value.SheetCarac.value.length > 0
                                    ? value.SheetCarac.value[0]
                                    : ''
                                }
                                onChange={(e) => {
                                  setIsEditing(true);
                                  const finalValue = prodConsoValidation(carac, e);
                                  handleEditModeCaracsChange(finalValue, carac);
                                }}
                              />
                            )}

                          <Form.Control.Feedback type="invalid">
                            {t('APP.EDITCONTRIBUTOR.INDEX.PH_1B4DFF', {
                              defaultValue: 'Veuillez renseigner ce champ.',
                            })}
                          </Form.Control.Feedback>
                        </Form.Group>
                      );
                    } else return null;
                  })}
                </div>

                {/* Fluid widget */}
                {(currentEquipment.type_fiche === 'FORFAIT' ||
                  currentEquipment.MasterDataEquipment?.hasFluid) &&
                  currentProject.templateType !== TYPE_RE2020 &&
                  currentProject.templateType !== TYPE_EPCM && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          cursor: 'pointer',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          if (
                            currentEquipment.type_fiche &&
                            currentEquipment.type_fiche !== 'DED' &&
                            currentEquipment.type_fiche !== 'users' &&
                            currentEquipment.type_fiche !== 'FORFAIT'
                          )
                            return;
                          else {
                            setDisplayOverwritteFluidsForm(
                              !displayOverwritteFluidsForm
                            );
                            setIsEditing(true);
                          }
                        }}
                      >
                        <NoocoCheckbox
                          disabled={
                            currentEquipment.type_fiche &&
                            currentEquipment.type_fiche !== 'DED' &&
                            currentEquipment.type_fiche !== 'users' &&
                            currentEquipment.type_fiche !== 'FORFAIT'
                          }
                          checked={displayOverwritteFluidsForm}
                        />

                        <p style={{ fontStyle: 'italic', margin: '0' }}>
                          {t('APP.EDITCONTRIBUTOR.INDEX.PH_368A65', {
                            defaultValue: 'Changer le type de fluide (facultatif)',
                          })}
                        </p>
                      </div>
                      {displayOverwritteFluidsForm && renderFluidsModal()}
                    </>
                  )}
              </div>
            )}

          {/* Reemploy widget */}
          {currentEquipment.type_fiche !== 'FORFAIT' &&
            currentEquipment.type_fiche !== 'PEP' &&
            currentEquipment.type_fiche !== 'FDES' &&
            currentEquipment.type_fiche !== 'DEC' &&
            currentEquipment.type_fiche !== 'DED' &&
            currentEquipment.MasterDataEquipment?.lot !== 'Eau' &&
            currentEquipment.MasterDataEquipment?.lot !== 'Energie' &&
            currentEquipment.MasterDataEquipment?.lot !== 'Terre' && (
              <ReemployCheck
                setSelectedSheetObj={setSelectedSheetObj}
                setIsEditing={setIsEditing}
                setReEmploy={setReEmploy}
                reEmploy={reEmploy}
              />
            )}
        </div>
      )}

      <div className="buttons-bottom">
        <Button
          style={{ marginRight: '10px' }}
          className="close-button"
          onClick={() => {
            setIsEditMode(false);
            setIsEditing(false);
            setUrbanChoice();
            updateFormValue('nodeSearchValue', null);
            updateFormValue('searchValue', null);
          }}
          text={t('APP.EDITCONTRIBUTOR.INDEX.PH_D42A3B', {
            defaultValue: 'Fermer',
          })}
        />

        {isPatchLoading && (
          <div
            style={{
              width: '96px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loader
              variant="indeterminate"
              disableShrink
              style={{ color: '#73b7ec', display: 'inline-block' }}
              size={25}
              thickness={4}
            />
          </div>
        )}

        {!isPatchLoading && (
          <Button
            className="purple-button"
            disabled={handleDisabledConfirmButton(
              isEditing,
              currentEquipment,
              displayOverwritteFluidsForm,
              selectedSubEquip,
              subEquipQuantity
            )}
            onClick={() => handleEditEquipment()}
            text={t('APP.EDITCONTRIBUTOR.INDEX.PH_FC68A3', {
              defaultValue: 'Confirmer',
            })}
          />
        )}
      </div>
    </div>
  );
};

export default EditContributor;
