import React from 'react';
import { useTranslation } from 'react-i18next';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import init from '../../../../../assets/images/import/init.png';
import validate from '../../../../../assets/images/import/validate.png';
import creation from '../../../../../assets/images/import/creation.png';
import IA from '../../../../../assets/images/import/ia.png';
import { useSelector } from 'react-redux';
import { StateDTO } from '../../../../models/GeneralDTO';
import Loader from '@material-ui/core/CircularProgress';

const LoadingBar = ({}) => {
  const { importStep, isImportLoading, creationImport } = useSelector(
    (state: StateDTO) => state.imports
  );
  const { t } = useTranslation();
  const steps = [
    t('APP.IMPORT.INDEX.PH_54DF33', {
      defaultValue: "Initialisation de l'import",
    }),
    t('APP.IMPORT.INDEX.PH_D4C299', { defaultValue: 'IA' }),
    t('APP.IMPORT.INDEX.PH_7CDEFD', { defaultValue: "Création de l'import" }),
    t('APP.IMPORT.INDEX.PH_7A458A', { defaultValue: 'Validation des données' }),
  ];

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient(90deg, rgba(126,255,224,1) 0%, rgba(112,192,173,1) 100%, rgba(0,212,255,1) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient(90deg, rgba(126,255,224,1) 0%, rgba(112,192,173,1) 100%, rgba(0,212,255,1) 100%);',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: (
        <img
          src={init}
          style={{ height: '30px', width: '30px' }}
          alt
          className="filter-white-png "
        />
      ),
      2: (
        <img
          src={IA}
          style={{ height: '30px', width: '30px' }}
          alt
          className="filter-white-png "
        />
      ),
      3: (
        <img
          src={creation}
          style={{ height: '30px', width: '30px' }}
          alt
          className="filter-white-png "
        />
      ),
      4: (
        <img
          src={validate}
          style={{ height: '30px', width: '30px' }}
          alt
          className="filter-white-png "
        />
      ),
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
        style={{}}
      >
        {importStep + 1 === props.icon ? (
          <Loader
            variant="indeterminate"
            disableShrink
            style={{ color: 'white' }}
            size={30}
            thickness={4}
          />
        ) : (
          icons[String(props.icon)]
        )}
      </ColorlibStepIconRoot>
    );
  }

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient(100deg, rgba(126,255,224,1) 0%, rgba(112,192,173,1) 100%, rgba(0,212,255,1) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient(100deg, rgba(126,255,224,1) 0%, rgba(112,192,173,1) 100%, rgba(0,212,255,1) 100%)',
    }),
  }));

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '5%',
      }}
    >
      <Stepper
        alternativeLabel
        activeStep={importStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step style={{ width: '300px' }} key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <p style={{ textAlign: 'center', width: '280px', marginTop: '2em' }}>
        {t('APP.IMPORT.INDEX.PH_E6180E', {
          defaultValue:
            'Import de votre fichier en cours : ceci peut prendre quelques dizaines de secondes',
        })}
      </p>
    </div>
  );
};

export default LoadingBar;
