/* eslint-disable no-unused-vars */
import { Dispatch } from 'redux';
import { MapDispatchToPropsParam, MapStateToPropsParam, connect } from 'react-redux';

import ProductsList from '../../app/products/ProductsList';

import {
  updateProductsValue,
  fetchProducts,
  filterListProducts,
} from '../../actions/products';

import { ProductDTO } from '../../models/ProjectDTO';

interface IProducts {
  brandsSelected: {}[];
  listProducts: ProductDTO[];
  viewMode: string;
  requests: string[];
  brandsList: {}[];
  brandsFilter: {}[];
  inputSearchBar: string;
  offsetList: number;
  productView: string;
  searchRequest: number;
  totalSheetCount: number;
}

interface IProjects {
  allGroups: {
    type: [];
  };
}

type State = {
  products: IProducts;
  projects: IProjects;
};

const mapStateToProps: MapStateToPropsParam<{}, {}, State> = (state: State) => ({
  brandsSelected: state.products.brandsSelected,
  listProducts: state.products.listProducts,
  viewMode: state.products.viewMode,
  requests: state.products.requests,
  brandsList: state.products.brandsList,
  brandsFilter: state.products.brandsFilter,

  inputSearchBar: state.products.inputSearchBar,
  offsetList: state.products.offsetList,
  productView: state.products.productView,
  searchRequest: state.products.searchRequest,
  allGroups: state.projects.allGroups.type,
  totalSheetCount: state.products.totalSheetCount,
});

const mapDispatchToProps: MapDispatchToPropsParam<{}, {}> = (
  dispatch: Dispatch
) => ({
  updateProductsValue: (name: string, value: string) => {
    dispatch(updateProductsValue(name, value));
  },
  fetchProducts: (concat: boolean) => {
    dispatch(fetchProducts(concat));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsList);
