import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Diapositive10 from './memo/part2/Diapositive10.png';
import Diapositive11 from './memo/part2/Diapositive11.png'; // requires a loader
import Diapositive12 from './memo/part2/Diapositive12.png';
import Diapositive13 from './memo/part2/Diapositive13.png';
import Diapositive14 from './memo/part2/Diapositive14.png';
import Diapositive15 from './memo/part2/Diapositive15.png';
import Diapositive16 from './memo/part2/Diapositive16.png';
import Diapositive17 from './memo/part2/Diapositive17.png';
import Diapositive18 from './memo/part2/Diapositive18.png';
import Diapositive19 from './memo/part2/Diapositive19.png';
import Diapositive2 from './memo/part1/Diapositive2.png';
import Diapositive20 from './memo/part3/Diapositive20.png';
import Diapositive21 from './memo/part3/Diapositive21.png';
import Diapositive22 from './memo/part3/Diapositive22.png';
import Diapositive23 from './memo/part3/Diapositive23.png';
import Diapositive24 from './memo/part3/Diapositive24.png';
import Diapositive25 from './memo/part3/Diapositive25.png';
import Diapositive26 from './memo/part3/Diapositive26.png';
import Diapositive27 from './memo/part3/Diapositive27.png';
import Diapositive28 from './memo/part4/Diapositive28.png';
import Diapositive29 from './memo/part4/Diapositive29.png';
import Diapositive3 from './memo/part1/Diapositive3.png';
import Diapositive30 from './memo/part4/Diapositive30.png';
import Diapositive31 from './memo/part4/Diapositive31.png';
import Diapositive32 from './memo/part4/Diapositive32.png';
import Diapositive33 from './memo/part4/Diapositive33.png';
import Diapositive34 from './memo/part5/Diapositive34.png';
import Diapositive35 from './memo/part5/Diapositive35.png';
import Diapositive4 from './memo/part1/Diapositive4.png';
import Diapositive5 from './memo/part1/Diapositive5.png';
import Diapositive6 from './memo/part2/Diapositive6.png';
import Diapositive7 from './memo/part2/Diapositive7.png';
import Diapositive8 from './memo/part2/Diapositive8.png';
import Diapositive9 from './memo/part2/Diapositive9.png';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, { useState } from 'react';

import './pdf.scss';
import { useTranslation } from 'react-i18next';

const Memo = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const { t } = useTranslation();

  // Data Prise en main
  const items = [
    {
      index: 1,
      label: t('APP.HELP.MEMO.PH_5922DD', {
        defaultValue: '01 - Créer un nouveau projet',
      }),
      file: [Diapositive2, Diapositive3, Diapositive4, Diapositive5],
    },
    {
      index: 2,
      label: t('APP.HELP.MEMO.PH_5E4247', {
        defaultValue: '02 - Importer mon projet dans Nooco',
      }),
      file: [
        Diapositive6,
        Diapositive7,
        Diapositive8,
        Diapositive9,
        Diapositive10,
        Diapositive11,
        Diapositive12,
        Diapositive13,
        Diapositive14,
        Diapositive15,
        Diapositive16,
        Diapositive17,
        Diapositive18,
        Diapositive19,
      ],
    },
    {
      index: 3,
      label: t('APP.HELP.MEMO.PH_725504', {
        defaultValue: '03 - Analyser et optimiser mon projet',
      }),
      file: [
        Diapositive20,
        Diapositive21,
        Diapositive22,
        Diapositive23,
        Diapositive24,
        Diapositive25,
        Diapositive26,
        Diapositive27,
      ],
    },
    {
      index: 4,
      label: t('APP.HELP.MEMO.PH_234B70', {
        defaultValue: '04 - Comparer des variantes',
      }),
      file: [
        Diapositive28,
        Diapositive29,
        Diapositive30,
        Diapositive31,
        Diapositive32,
        Diapositive33,
      ],
    },
    {
      index: 5,
      label: t('APP.HELP.MEMO.PH_3FD949', {
        defaultValue: '05 - Glossaire',
      }),
      file: [Diapositive34, Diapositive35],
    },
  ];

  const renderPdf = (item, index) => {
    return (
      <div className="slider-container">
        <Carousel showThumbs={false} className="carousel-wrapper">
          {item.file.map((file) => {
            return (
              <div key={index}>
                <img src={file} />
              </div>
            );
          })}
        </Carousel>
      </div>
    );
  };

  const renderTitles = () => {
    const list = items.map((item, index) => {
      return (
        <div
          key={index}
          className={'memo-item'}
          onClick={() => setSelectedItem(item)}
        >
          {item.label}
          <NavigateNextIcon />
        </div>
      );
    });

    return list;
  };

  const renderHeader = () => {
    return (
      <div className={'header-container'}>
        <div
          className="back-title"
          onClick={() => {
            setSelectedItem(null);
          }}
        >
          <div className="backToPrev">
            <NavigateBeforeIcon />
            {t('APP.HELP.MEMO.PH_51ACE2', { defaultValue: 'Sommaire' })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={'pdf-container'}>
      {selectedItem ? renderHeader(selectedItem) : null}
      {!selectedItem ? renderTitles() : renderPdf(selectedItem)}
    </div>
  );
};

export default Memo;
