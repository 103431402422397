//import 'antd/dist/antd.min.css';
import { Button, Form, Modal } from 'react-bootstrap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputSousLotRe2020 from '../../../containers/InputSousLotRe2020';
import TextField from '@material-ui/core/TextField';

import { TreeSelect } from 'antd';

import React, { useEffect, useState } from 'react';

import './composantVideForm.scss';
import { useTranslation } from 'react-i18next';
import {
  ALLOTMENT_IDS,
  REFERENCE_TYPE_NAME,
} from '../../globalVariable/typeProject';
import { useSelector } from 'react-redux';

const ComposantVideForm = ({
  changeValueEquipments,
  iniesNomenclature,
  addPart,
  reEmploy,
  composantVideForm,
}) => {
  useEffect(() => {
    changeValueEquipments('composantVideForm', {});
  }, [reEmploy]);

  const [validated, setValidated] = useState(false);
  const { currentAllotment } = useSelector((state) => state.projects);
  const { t } = useTranslation();

  return (
    <Form
      key={reEmploy}
      noValidate
      validated={validated}
      className="dedModal"
      style={{
        minHeight:
          reEmploy && addPart && window.screen.availHeight > 950 ? '45vh' : null,
      }}
    >
      {((!reEmploy && addPart) || !addPart) && (
        <>
          <h2>
            {t('APP.COMPOSANTVIDEFORM.INDEX.PH_B71817', {
              defaultValue: 'Argumentaire(s)/justification(s) de la demande',
            })}
          </h2>
          <Form.Group controlId="formGridOverseer">
            <Form.Label>
              {t('APP.COMPOSANTVIDEFORM.INDEX.PH_60C229', {
                defaultValue:
                  'Expliciter au maximum votre constat et votre demande *',
              })}
            </Form.Label>
            <Form.Control
              onChange={(e) => {
                changeValueEquipments('composantVideForm', {
                  ...composantVideForm,
                  explainDed: e.target.value,
                });
              }}
              required
              id="name"
            />
          </Form.Group>
          <Form.Group controlId="formGridOverseer">
            <Form.Label>
              {t('APP.COMPOSANTVIDEFORM.INDEX.PH_0AC624', {
                defaultValue:
                  'Intitulé de la donnée environnementale par défaut proposée *',
              })}
            </Form.Label>
            <Form.Control
              onChange={(e) => {
                changeValueEquipments('composantVideForm', {
                  ...composantVideForm,
                  nameDed: e.target.value,
                });
              }}
              required
              id="name"
            />
          </Form.Group>
          <h2>
            {t('APP.COMPOSANTVIDEFORM.INDEX.PH_B691DE', {
              defaultValue: 'Proposition détaillée',
            })}
          </h2>
          <Form.Group controlId="formGridOverseer">
            <Form.Label>
              {t('APP.COMPOSANTVIDEFORM.INDEX.PH_9D17C1', {
                defaultValue:
                  'Identification du produit de référence servant à construire la donnée environnementale par défaut et dimensionnement du produit *',
              })}
            </Form.Label>
            <Form.Control
              onChange={(e) => {
                changeValueEquipments('composantVideForm', {
                  ...composantVideForm,
                  productDed: e.target.value,
                });
              }}
              required
              id="name"
            />
          </Form.Group>

          <Form.Group controlId="formGridOverseer">
            <Form.Label>
              {t('APP.COMPOSANTVIDEFORM.INDEX.PH_335FC2', {
                defaultValue:
                  "Identification de l'échantillon/donnée source pour établir le calcul",
              })}
            </Form.Label>
            <Form.Control
              onChange={(e) =>
                changeValueEquipments('composantVideForm', {
                  ...composantVideForm,
                  calculDed: e.target.value,
                })
              }
              id="name"
            />
          </Form.Group>
        </>
      )}
      {reEmploy && addPart && (
        <>
          <Form.Group controlId="formGridOverseer">
            <Form.Label>
              {t('APP.COMPOSANTVIDEFORM.INDEX.PH_A2F6AB', {
                defaultValue: 'Intitulé du composant*',
              })}
            </Form.Label>
            <Form.Control
              onChange={(e) =>
                changeValueEquipments('composantVideForm', {
                  ...composantVideForm,
                  nameDed: e.target.value,
                })
              }
              required
              id="name"
            />
          </Form.Group>
          <Form.Group controlId="formGridOverseer" style={{ width: '25%' }}>
            <Form.Label>
              {t('APP.COMPOSANTVIDEFORM.INDEX.PH_EB451E', {
                allotment:
                  currentAllotment.id === ALLOTMENT_IDS.EPCM
                    ? REFERENCE_TYPE_NAME.EPCM
                    : REFERENCE_TYPE_NAME.RE2020,
                defaultValue: 'Rattachement {allotment}*',
              })}
            </Form.Label>
            <InputSousLotRe2020 />
          </Form.Group>
        </>
      )}

      <div style={{ display: 'flex' }}>
        {/* TODO_TRAD : fr */}
        <Form.Group
          className="inputs-composant-vide"
          controlId="formGridOverseer"
          style={{ marginRight: '2em', width: '25%' }}
        >
          <Form.Label>
            {t('APP.COMPOSANTVIDEFORM.INDEX.PH_74BB88', {
              defaultValue: 'Unité fonctionnelle*',
            })}
          </Form.Label>
          <Autocomplete
            id="checkboxes-tags-demo"
            options={[
              { name: 'dm²' },
              { name: 'm²' },
              { name: 'kg' },
              { name: 'm³' },
              { name: 'm' },
              { name: 'MJ' },
              { name: 'kWh' },
              { name: 'L' },
              { name: 'g' },
              { name: 'cm' },
              { name: 'ml' },
              { name: '%' },
              { name: 'unité' },
              { name: 'kg CO2 eq.' },
              { name: 'kg SO2 eq.' },
              { name: 'kg CFC-11 eq.' },
              { name: 'kg C2H4 eq.' },
              { name: 'mg' },
              { name: 'mm' },
              { name: 'g eq. SO2' },
              { name: 'g C2H4 eq.' },
              { name: 'g/m²' },
              { name: 'kg Sb eq.' },
              { name: 'm3/UF' },
              { name: 'kg/m²' },
              { name: 'kWhep' },
              { name: '-' },
              { name: "m³ d'air" },
              { name: 'kg C2H4 eq.' },
              { name: 'kg (PO4)3- eq.' },
              { name: 't' },
              { name: "m³ d'eau" },
              { name: 'person réserve' },
              { name: 'kg Sb eq.' },
              { name: 'Mole eq. H+' },
              { name: 'Mole eq. N' },
              { name: 'km' },
              { name: 'g.eq.CO2' },
              { name: 'g.eq.CFC-11' },
              { name: 'g.eq.P04 3-' },
              { name: 'g.eq.C2H4' },
              { name: 'g.eq.H+' },
              { name: 'dm³' },
              { name: 'year-1' },
              { name: 'kWc' },
              { name: 't.km' },
              { name: 'daN' },
              { name: 'm3/h' },
              { name: 'mm' },
              { name: 'kg/m3' },
              { name: 'kW' },
              { name: 'kWc' },
              { name: 'db' },
              { name: 'K/W' },
              { name: 'W/M²K' },
              { name: 'cm²' },
              { name: 'mm²' },
              { name: 'Wc' },
              { name: 'étage' },
              { name: 'm2.K/W' },
              { name: 'kg/m' },
            ]}
            onChange={(event, newValue) => {
              if (newValue)
                changeValueEquipments('composantVideForm', {
                  ...composantVideForm,
                  unitDed: newValue,
                });
            }}
            size={'small'}
            getOptionLabel={(option) => option.name}
            style={{ border: '1px solid #dee2e6' }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={
                  !addPart
                    ? {
                        paddingTop: '10px',
                        paddingBottom: '5px',
                        marginBottom: '0',
                        background: 'white',
                      }
                    : null
                }
              />
            )}
          />
        </Form.Group>
        <Form.Group
          controlId="formGridOverseer"
          style={{ marginRight: '2em', width: '25%' }}
        >
          <Form.Label>
            {t('APP.COMPOSANTVIDEFORM.INDEX.PH_EC1F35', {
              defaultValue: "Durée de vie de l'équipement*",
            })}
          </Form.Label>
          <Form.Control
            onChange={(e) =>
              changeValueEquipments('composantVideForm', {
                ...composantVideForm,
                ddv: e.target.value,
              })
            }
            required
            type="number"
            id="name"
          />
        </Form.Group>
        {addPart && (
          <>
            <Form.Group
              controlId="formGridOverseer"
              style={{ marginRight: '2em', width: '25%' }}
            >
              <Form.Label>
                {t('APP.COMPOSANTVIDEFORM.INDEX.PH_5C5A58', {
                  defaultValue: 'Quantité*',
                })}
              </Form.Label>
              <Form.Control
                onChange={(e) =>
                  changeValueEquipments('composantVideForm', {
                    ...composantVideForm,
                    quantity: e.target.value,
                  })
                }
                required
                type="number"
                id="name"
              />
            </Form.Group>
            {!reEmploy && addPart && (
              <Form.Group controlId="formGridOverseer" style={{ width: '25%' }}>
                <Form.Label>
                  {t('APP.COMPOSANTVIDEFORM.INDEX.PH_5808F2', {
                    allotment:
                      currentAllotment.id === ALLOTMENT_IDS.EPCM
                        ? REFERENCE_TYPE_NAME.EPCM
                        : REFERENCE_TYPE_NAME.RE2020,
                    defaultValue: 'Rattachement {allotment}*',
                  })}
                </Form.Label>
                <InputSousLotRe2020 />
              </Form.Group>
            )}
          </>
        )}
      </div>
      {((addPart && !reEmploy) || !addPart) && (
        <>
          <h2>
            {t('APP.COMPOSANTVIDEFORM.INDEX.PH_919460', {
              defaultValue: 'Rang de la donnée',
            })}
          </h2>

          <TreeSelect
            style={{ width: '100%', border: '1px solid #dee2e6' }}
            className="form-control"
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={iniesNomenclature}
            placeholder={t('APP.COMPOSANTVIDEFORM.INDEX.PH_655CC8', {
              defaultValue: 'Place dans la nomenclature INIES*',
            })}
            onChange={(value) => {
              changeValueEquipments('composantVideForm', {
                ...composantVideForm,
                nomenDed: value,
              });
            }}
          />
        </>
      )}
    </Form>
  );
};

export default ComposantVideForm;
