import { useTranslation } from 'react-i18next';
import {
  FETCH_FAVORITES,
  FETCH_INITIAL_FAVORITES,
  LOAD_FAVORITES,
  LOAD_INITIAL_FAVORITES,
  RESPONSE_TOGGLE_FAVORITES,
  UPDATE_FAVORITES_VALUE,
} from '../actions/favorites';
import { FavoritesDTO, FavoritesReducerDTO } from '../app/models/FavoritesDTO';

const initialState: FavoritesReducerDTO = {
  isOpen: false,
  tabValue: 0,
  userFavoritesIds: [],
  companyFavoritesIds: [],
  userFavoritesSheets: null,
  userLoadingSheets: false,
  companyFavoritesSheets: null,
  companyLoadingSheets: false,
  userSheetsCount: null,
  companySheetsCount: null,
  favoritesSnackbar: null,
  userPages: 1,
  companyPages: 1,
  inputSearchBar: '',
};

function favoritesReducer(state: FavoritesReducerDTO = initialState, action) {
  switch (action.type) {
    case UPDATE_FAVORITES_VALUE:
      return {
        ...state,
        [action.name]: action.value,
      };

    case FETCH_INITIAL_FAVORITES:
      return {
        ...state,
        userLoadingSheets: true,
        companyLoadingSheets: true,
      };
    case LOAD_INITIAL_FAVORITES:
      return {
        ...state,
        [`${action.key}FavoritesSheets`]: action.data?.rows,
        [`${action.key}LoadingSheets`]: false,
        [`${action.key}SheetsCount`]: action.data?.count,
      };
    case FETCH_FAVORITES:
      return {
        ...state,
        [`${action.scope}Pages`]: action.page,
        [`${action.scope}LoadingSheets`]: true,
        tabValue: action.scope === 'user' ? action.tabValue : state.tabValue,
      };
    case LOAD_FAVORITES:
      const userFavoritesIds = action.data?.rows.map(
        (userFavoriteSheet) => userFavoriteSheet.id
      );

      return {
        ...state,
        userFavoritesIds,
        [`${action.scope}FavoritesSheets`]: action.data?.rows,
        [`${action.scope}LoadingSheets`]: false,
        [`${action.scope}SheetsCount`]: action.data?.count,
      };
    case RESPONSE_TOGGLE_FAVORITES: {
      if (action.method === 'PUT') {
        let tabValue =
          action.scope === 'company'
            ? ['PEP', 'FDES', 'DED', 'DEC', 'FC']
            : state.tabValue === 0
            ? ['PEP', 'FDES', 'DED', 'DEC']
            : ['FC'];

        let sheets: any | null | FavoritesDTO[] = [];

        // Management of adding new favorites with good order
        if (
          state[`${action.scope}FavoritesSheets`] &&
          state[`${action.scope}Pages`] === 1
        ) {
          if (state[`${action.scope}FavoritesSheets`].length < 5) {
            sheets = [
              action.data,
              ...state[`${action.scope}FavoritesSheets`],
            ].filter((el) => tabValue.includes(el.type_fiche));
          } else {
            sheets = [
              action.data,
              ...state[`${action.scope}FavoritesSheets`].slice(0, 4),
            ].filter((el) => tabValue.includes(el.type_fiche));
          }
        } else if (
          state[`${action.scope}FavoritesSheets`] &&
          state[`${action.scope}FavoritesSheets`].length === 5
        ) {
          sheets = [...state[`${action.scope}FavoritesSheets`]];
        } else if (state[`${action.scope}FavoritesSheets`]) {
          sheets = [...state[`${action.scope}FavoritesSheets`]];
        } else {
          sheets = null;
        }

        return {
          ...state,
          [`${action.scope}FavoritesIds`]: [
            ...state[`${action.scope}FavoritesIds`],
            action.data.id,
          ],

          [`${action.scope}FavoritesSheets`]: sheets,
          [`${action.scope}SheetsCount`]: state[`${action.scope}SheetsCount`] + 1,
          // favoritesSnackbar: t(
          //   'APP.REDUCERS.FAVORITES.PH_5C69A4',

          //   {
          //     isUser: action.scope === 'user',
          //     defaultValue:
          //       'Votre équipement a bien été rajouté aux favoris {isUser, select, true {personnels} other {entreprise}}',
          //   }
          // ),
        };
      } else {
        return {
          ...state,
          [`${action.scope}FavoritesIds`]: [
            ...state[`${action.scope}FavoritesIds`].filter(
              (el) => el !== action.data
            ),
          ],

          // [`${action.scope}FavoritesSheets`]: state[`${action.scope}FavoritesSheets`] ? [...state[`${action.scope}FavoritesSheets`].filter((el) => el.id !== action.data)] : null,
          [`${action.scope}SheetsCount`]: state[`${action.scope}SheetsCount`] - 1,
          // favoritesSnackbar: t(
          //   'APP.REDUCERS.FAVORITES.PH_7570AB',

          //   {
          //     isUser: action.scope === 'user',
          //     defaultValue:
          //       'Votre équipement a bien été supprimé des favoris {isUser, select, true {personnels} other {entreprise}}',
          //   }
          // ),
        };
      }
    }

    default:
      return state;
  }
}

export default favoritesReducer;
