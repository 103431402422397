import {
  GET_BUILDING_CALCULATOR_DATA,
  GET_GEARS_CARACS,
  GET_GEAR_CARAC,
  GET_WATER_CALCULATOR_DETAILED,
  PUT_BUILDING_CALCULATOR_DATA,
  PUT_WATER_CALCULATOR_DETAILED,
  RESET_WATER_CALCULATOR_DETAILED,
  calculatorExit,
  calculatorReset,
  calculatorUpdate,
  calculatorWaterReset,
  updateCalculatorDetailed,
  updateWaterCalculator,
} from '../actions/calculator';
import { MasterDataEquipment } from '../app/models/ProjectsDTO';
import { Surface } from 'recharts';
import { WaterCalculatorDataStructure } from '../app/models/CalculatorDTO';
import { changeValueEquipments } from '../actions/equipments';
import { commaToDot } from '../app/shared/utilsFunction';
import { fetchProjectById } from '../actions/projects';
import API from '../app/services/API';
import _, { map } from 'lodash';
import { findAllAllotmentNodeChildrenFromFilteredParentAllotmentNodes } from '../app/shared/utils';
import { v4 as uuidv4 } from 'uuid';
import {
  BUILDING_TYPE_ID,
  typeProjectForSimplifiedEnergy,
} from '../app/globalVariable/typeProject';
import { getSimplifiedEnergyDefaultDataSelected } from '../app/projects/EquipmentList/Calculator/CalculatorWorksite/CalculatorWorksiteContent/calculatorWorksiteFunction';

const api = new API();

const isProjectIsOnlyIndividualHouse = (zones): boolean => {
  const zonesTypeZone = zones.filter((zone) => zone.type === 'zone');

  let isOnlyZoneIndividualHouse = true;
  zonesTypeZone.forEach((zone) => {
    if (zone?.BuildingType?.id !== BUILDING_TYPE_ID.INDIVIDUAL_HOUSE) {
      isOnlyZoneIndividualHouse = false;
    }
  });
  return isOnlyZoneIndividualHouse;
};

//TODO type store and action
const calculatorMiddleware = (store) => (next) => async (action) => {
  const { projects } = store.getState();
  const { waterCalculator } = store.getState().projects.waterCalculator;
  const { currentProject } = store.getState().projects;

  switch (action.type) {
    case PUT_BUILDING_CALCULATOR_DATA: {
      const {
        transportLines,
        gearLines,
        isTotalGazoilSelected,
        isInputByGearSelected,
        calculatorTotalGazoilGear,
        isInputByExcavatedSelected,
        calculatorTotalExcavatedGear,
        isWorksiteCalculatorDetailed,
        isWorksiteCalculatorSimplified,
      } = projects.calculator;
      const { zones } = projects;

      action.data.calculatorData.isWorksiteCalculatorDetailed =
        isWorksiteCalculatorDetailed;
      action.data.calculatorData.isWorksiteCalculatorSimplified =
        isWorksiteCalculatorSimplified;

      if (isTotalGazoilSelected) {
        calculatorTotalGazoilGear.map((gearline) => {
          gearline.quantity = commaToDot(gearline.quantity);
          if (gearline.quantity === '') {
            gearline.quantity = null;
          }
        });
        action.data.calculatorData.machines = {
          // separate: false,
          isTotalGazoilSelected: true,
          isInputByGearSelected: false,
          isInputByExcavatedSelected: false,
          machine: calculatorTotalGazoilGear.filter(
            (gearline) => gearline.quantity !== null
          ),
        };
      } else if (isInputByExcavatedSelected) {
        calculatorTotalExcavatedGear.map((gearline) => {
          gearline.quantity = commaToDot(gearline.quantity);
          if (gearline.quantity === '') {
            gearline.quantity = null;
          }
        });

        action.data.calculatorData.machines = {
          // separate: false,
          isTotalGazoilSelected: false,
          isInputByGearSelected: false,
          isInputByExcavatedSelected: true,
          machine: calculatorTotalExcavatedGear,
        };
      } else if (isInputByGearSelected) {
        const gearDataCleaned = gearLines.filter(
          (gearLine) =>
            gearLine.MasterDataEquipmentId !== null || gearLine.quantity !== null
        );

        gearDataCleaned.map((gearLine) => {
          gearLine.quantity = commaToDot(gearLine.quantity);
          if (gearLine.quantity === '') {
            gearLine.quantity = null;
          }
          gearLine.consumption = commaToDot(gearLine.consumption);
          if (gearLine.consumption === '') {
            gearLine.consumption = null;
          }
          gearLine.allotmentNode = projects.allGroups.flatAllotments.find(
            (allotment) =>
              allotment.MasterDataEquipmentId === gearLine.MasterDataEquipmentId
          );
        });

        action.data.calculatorData.machines = {
          // separate: true,
          isTotalGazoilSelected: false,
          isInputByGearSelected: true,
          isInputByExcavatedSelected: false,
          machine: gearDataCleaned,
        };
      } else {
        action.data.calculatorData.machines = {
          // separate: false,
          isTotalGazoilSelected: false,
          isInputByGearSelected: false,
          isInputByExcavatedSelected: false,
          machine: [],
        };
      }

      const transportsDataCleaned = transportLines.filter(
        (transportLine) =>
          transportLine.MasterDataEquipmentId !== null ||
          transportLine.quantity !== null
      );

      // console.log('data send to api in middleware', action.data.calculatorData)
      transportsDataCleaned.map((transportLine) => {
        transportLine.quantity = commaToDot(transportLine.quantity);
        if (transportLine.quantity === '') {
          transportLine.quantity = null;
        }
        transportLine.allotmentNode = projects.allGroups.flatAllotments.find(
          (allotment) =>
            allotment.MasterDataEquipmentId === transportLine.MasterDataEquipmentId
        );
      });

      action.data.calculatorData.transports = transportsDataCleaned;

      //TODO add a condition for not enter in this condition if is an individual house
      if (
        action.data.calculatorData.evacuatedEarthWorkIndividualHouse.length === 0 &&
        action.data.calculatorData.isWorksiteCalculatorSimplified === true &&
        !isProjectIsOnlyIndividualHouse(zones)
      ) {
        const energySimplifiedArray =
          action.data.calculatorData.energyCalculatorSimplified
            .energySimplifiedArray;
        const newWaterZonesData: {
          isDrinkableWater: boolean;
          isWasteWater: boolean;
          quantity: number;
          origin: {};
        }[] = [];
        energySimplifiedArray.forEach((energySimplified) => {
          const totalWater =
            (Number(energySimplified.NumberMonthsWithCraneSummer) +
              Number(energySimplified.NumberMonthsWithCraneWinter)) *
              60 +
            (Number(energySimplified.NumberMonthsWithoutCraneSummer) +
              Number(energySimplified.NumberMonthsWithoutCraneWinter)) *
              40;
          newWaterZonesData.push({
            isDrinkableWater: true,
            isWasteWater: false,
            quantity: totalWater / 2,
            origin: energySimplified.origin,
          });
          newWaterZonesData.push({
            isDrinkableWater: false,
            isWasteWater: true,
            quantity: totalWater / 2,
            origin: energySimplified.origin,
          });
        });
        action.data.calculatorData.water.zonesData = newWaterZonesData;
      }
      console.log('action.data.calculatorData', action.data.calculatorData);

      api
        .putBuildingCalculator(action.id, action.data)
        .then((response) => {
          // console.log(response)
          store.dispatch(
            fetchProjectById(
              projects.currentProject.id,
              projects.currentIndicator.id
            )
          );
          store.dispatch(changeValueEquipments('isLoadingProject', true));
          store.dispatch(calculatorUpdate('loadingCalculator', false));
          // store.dispatch(calculatorExit());
          store.dispatch(calculatorReset());
          store.dispatch(
            changeValueEquipments('simplifiedEnergyLines', [
              {
                id: 0,
                AllotmentNodeId: null,
                quantity: null,
                caracs: [],
                unit: 'kWh',
                actionType: 'energy',
                origin: {
                  type: 'project',
                  sourceId: null,
                  uniqueId: uuidv4(),
                },
              },
            ])
          );
        })
        .catch((e) => {
          // console.log(e)
          // store.dispatch(calculatorUpdate('loadingCalculator', false));
          // store.dispatch(calculatorExit());

          store.dispatch(
            fetchProjectById(
              projects.currentProject.id,
              projects.currentIndicator.id
            )
          );
          store.dispatch(calculatorReset());

          // store.dispatch(changeValueEquipments('isLoadingProject',
          //   true));
          // store.dispatch(calculatorUpdate('loadingCalculator',
          //   false));
          // store.dispatch(calculatorExit());
          // store.dispatch(calculatorReset());
        });

      next(action);
      break;
    }
    case PUT_WATER_CALCULATOR_DETAILED:
      // console.log(action.data);
      // action.data.calculatorData.specific.specificArray[0].caracs.push({name: 'Surface', value: ['15']});
      // console.log(action.data);
      // console.log('PUT_WATER_CALCULATOR_DETAILED')

      // console.log('éction', action)

      api
        .putWaterCalculatorDetailed(action.id, action.data)
        .then((response) => {
          // console.log(response)
          store.dispatch(
            fetchProjectById(
              projects.currentProject.id,
              projects.currentIndicator.id
            )
          );
          store.dispatch(changeValueEquipments('isLoadingProject', true));

          store.dispatch(calculatorExit());
          store.dispatch(calculatorWaterReset());
        })
        .catch((e) => {
          // console.log(e)
        });

      next(action);
      break;

    case RESET_WATER_CALCULATOR_DETAILED:
      // console.log('RESET_WATER_CALCULATOR_DETAILED')

      api
        .resetWaterCalculatorDetailed(action.id, action.data)
        .then((response) => {
          console.log(response);
          store.dispatch(
            fetchProjectById(
              projects.currentProject.id,
              projects.currentIndicator.id
            )
          );
          store.dispatch(changeValueEquipments('isLoadingProject', true));

          store.dispatch(calculatorExit());
          store.dispatch(calculatorWaterReset());
        })
        .catch((e) => {
          // console.log(e)
          // store.dispatch(calculatorUpdate('loadingCalculator', false));
          // store.dispatch(calculatorExit());
          // store.dispatch(fetchProjectById(projects.currentProject.id,
          //   projects.currentIndicator.id));
          // store.dispatch(changeValueEquipments('isLoadingProject',
          //   true));
          // store.dispatch(calculatorUpdate('loadingCalculator',
          //   false));
          // store.dispatch(calculatorExit());
          // store.dispatch(calculatorReset());
        });

      next(action);
      break;

    case GET_WATER_CALCULATOR_DETAILED:
      type Response = {
        drinkableWater: {
          id: number;
          BuildingTypeId: number;
          detailledWaterProperties: {}[];
          rainyWater: [];
          wastedWater: [];
        };
      };

      api
        .getWaterCalculatorDetailed(action.projectId)
        .then((response) => {
          // console.log('response getWater', response.data)
          // console.log('waterCalculator from prepare', waterCalculator)

          const dataFromBack = _.cloneDeep(response.data);
          const dataUpdate: WaterCalculatorDataStructure =
            _.cloneDeep(waterCalculator);

          const parsedDataFromBack: any = [];

          dataFromBack.drinkableWater.forEach((item) => {
            parsedDataFromBack.push(item);
          });
          dataFromBack.rainyWater.forEach((item) => {
            parsedDataFromBack.push(item);
          });
          dataFromBack.wastedWater.forEach((item) => {
            parsedDataFromBack.push(item);
          });

          // console.log('parsedDataFromBack', parsedDataFromBack)

          dataUpdate.buildings.map((building, buildingIndex) => {
            const buildingData = parsedDataFromBack.find(
              (item) => item.id === building.id
            );

            if (buildingData.detailledWaterProperties) {
              building.detailledWaterProperties.isCollectiveSanitation =
                buildingData.detailledWaterProperties.isCollectiveSanitation;
              building.detailledWaterProperties.isUnitNetwork =
                buildingData.detailledWaterProperties.isUnitNetwork;
              building.detailledWaterProperties.livingSurface =
                Number(
                  buildingData?.detailledWaterProperties?.livingSurface
                )?.toString() || '0';
              building.detailledWaterProperties.qPluviometrie =
                Number(
                  buildingData?.detailledWaterProperties?.qPluviometrie
                )?.toString() || '0';
              building.detailledWaterProperties.surfaceParking =
                Number(
                  buildingData?.detailledWaterProperties?.surfaceParking
                )?.toString() || '0';
              building.detailledWaterProperties.surfaceToiture =
                Number(
                  buildingData?.detailledWaterProperties?.surfaceToiture
                )?.toString() || '0';
            }

            building.zones.map((zone, zoneIndex) => {
              const zoneData = parsedDataFromBack.find(
                (item) => item.id === zone.id
              );

              if (zoneData.detailledWaterProperties) {
                dataUpdate.buildings[buildingIndex].zones[
                  zoneIndex
                ].detailledWaterProperties.distance =
                  Number(zoneData?.detailledWaterProperties?.distance)?.toString() ||
                  '0';
                dataUpdate.buildings[buildingIndex].zones[
                  zoneIndex
                ].detailledWaterProperties.nbRobinetEvier =
                  Number(
                    zoneData?.detailledWaterProperties?.nbRobinetEvier
                  )?.toString() || '0';
                dataUpdate.buildings[buildingIndex].zones[
                  zoneIndex
                ].detailledWaterProperties.nbRobinetLavabo =
                  Number(
                    zoneData?.detailledWaterProperties?.nbRobinetLavabo
                  )?.toString() || '0';
                dataUpdate.buildings[buildingIndex].zones[
                  zoneIndex
                ].detailledWaterProperties.nbDouche =
                  Number(zoneData?.detailledWaterProperties?.nbDouche)?.toString() ||
                  '0';
                dataUpdate.buildings[buildingIndex].zones[
                  zoneIndex
                ].detailledWaterProperties.nbChasseEau36 =
                  Number(
                    zoneData?.detailledWaterProperties?.nbChasseEau36
                  )?.toString() || '0';
                dataUpdate.buildings[buildingIndex].zones[
                  zoneIndex
                ].detailledWaterProperties.nbChasseEau24 =
                  Number(
                    zoneData?.detailledWaterProperties?.nbChasseEau24
                  )?.toString() || '0';
                dataUpdate.buildings[buildingIndex].zones[
                  zoneIndex
                ].detailledWaterProperties.nbToilettes =
                  Number(
                    zoneData?.detailledWaterProperties?.nbToilettes
                  )?.toString() || '0';
                dataUpdate.buildings[buildingIndex].zones[
                  zoneIndex
                ].detailledWaterProperties.urinoir =
                  Number(zoneData?.detailledWaterProperties?.urinoir)?.toString() ||
                  '0';
                // dataUpdate.buildings[buildingIndex].zones[zoneIndex].detailledWaterProperties.nbOccupants = zoneData?.detailledWaterProperties?.nbOccupant?.toString() || '0';

                dataUpdate.buildings[buildingIndex].zones[
                  zoneIndex
                ].detailledWaterProperties.distancePercentage = null;
                dataUpdate.buildings[buildingIndex].zones[
                  zoneIndex
                ].detailledWaterProperties.nbRobinetEvierPercentage = null;
                dataUpdate.buildings[buildingIndex].zones[
                  zoneIndex
                ].detailledWaterProperties.nbRobinetLavaboPercentage = null;
                dataUpdate.buildings[buildingIndex].zones[
                  zoneIndex
                ].detailledWaterProperties.nbDouchePercentage = null;
                dataUpdate.buildings[buildingIndex].zones[
                  zoneIndex
                ].detailledWaterProperties.nbChasseEau36Percentage = null;
                dataUpdate.buildings[buildingIndex].zones[
                  zoneIndex
                ].detailledWaterProperties.nbChasseEau24Percentage = null;
                dataUpdate.buildings[buildingIndex].zones[
                  zoneIndex
                ].detailledWaterProperties.nbToilettesPercentage = null;
                dataUpdate.buildings[buildingIndex].zones[
                  zoneIndex
                ].detailledWaterProperties.urinoirPercentage = null;
              }
            });
          });

          // console.log('dataUpdate', dataUpdate)
          // console.log('response.data', response.data)

          store.dispatch(updateWaterCalculator('calculatorDetailed', response.data));

          if (response.data.drinkableWater[0].detailledWaterProperties !== null) {
            store.dispatch(updateWaterCalculator('isWaterCalculatorDetailed', true));
          }

          store.dispatch(updateWaterCalculator('waterCalculator', dataUpdate));
          store.dispatch(updateWaterCalculator('isWaterCalculatorLoaded', true));
          store.dispatch(updateWaterCalculator('loadingWaterCalculator', false));
        })
        .catch((e) => {
          // console.log('error in MiddleWare CalculatorWater', e)
          // store.dispatch(updateWaterCalculator('loadingWaterCalculator',
          //   false));
          // store.dispatch(updateWaterCalculator('isWaterCalculatorLoaded',
          //   true));
        });

      next(action);
      break;

    case GET_BUILDING_CALCULATOR_DATA:
      api
        .getBuildingCalculator(action.id)
        .then((response) => {
          // response.data.calcBody = null;
          // response.data.caracComboForAllMachines = response.data[2]
          // response.data.caracComboForAllTransports = response.data[1]

          // traitment for gears and transports caracCombo
          const transportsAndGearsAllotmentNodes =
            findAllAllotmentNodeChildrenFromFilteredParentAllotmentNodes(
              projects.allGroups.flatAllotments,
              ['Transports et engins'],
              'keep'
            );

          let gearsEquipments = response.data.caracComboForAllMachines;
          gearsEquipments = gearsEquipments.filter((gearEquipment) => {
            gearEquipment.AllotmentNode = transportsAndGearsAllotmentNodes.find(
              (allotmentNode) =>
                allotmentNode.MasterDataEquipmentId === gearEquipment.id
            );

            if (gearEquipment?.AllotmentNode?.isObsolete === false) {
              return gearEquipment;
            }
          });
          gearsEquipments.sort((a, b) => (a.name > b.name ? 1 : -1));
          store.dispatch(calculatorUpdate('gearsEquipments', gearsEquipments));

          let transportEquipments = response.data.caracComboForAllTransports;
          transportEquipments = transportEquipments.filter((transportEquipment) => {
            transportEquipment.AllotmentNode = transportsAndGearsAllotmentNodes.find(
              (allotmentNode) =>
                allotmentNode.MasterDataEquipmentId === transportEquipment.id
            );

            if (transportEquipment?.AllotmentNode?.isObsolete === false) {
              return transportEquipment;
            }
          });
          transportEquipments.map((transportEquipment) => {
            transportEquipment.Caracs.map((Carac) => {
              const valueList = transportEquipment?.caracCombo?.valueList;

              if (Carac?.name === 'Motorisation') {
                Carac.comboValues = valueList;
              }

              return Carac;
              // transportEquipment.caracCombo.valueList.map((caracComboValueList) => {
              //   if (caracComboValueList.value)
              // });
            });
          });
          transportEquipments.sort((a, b) => (a.name > b.name ? 1 : -1));
          store.dispatch(
            calculatorUpdate('transportEquipments', transportEquipments)
          );
          // End traitment gears and transports caracCombo.

          const calcBody = response.data.calcBody;

          // gear traitment
          if (calcBody !== null) {
            const {
              isInputByExcavatedSelected,
              isInputByGearSelected,
              isTotalGazoilSelected,
              machine,
            } = calcBody.calculatorData.machines;
            const { isWorksiteCalculatorDetailed, isWorksiteCalculatorSimplified } =
              calcBody.calculatorData;
            if (isWorksiteCalculatorDetailed) {
              store.dispatch(calculatorUpdate('isWorksiteCalculatorDetailed', true));
            }
            if (isWorksiteCalculatorSimplified) {
              store.dispatch(
                calculatorUpdate('isWorksiteCalculatorSimplified', true)
              );
            }
            if (isInputByExcavatedSelected) {
              store.dispatch(calculatorUpdate('isInputByExcavatedSelected', true));
              store.dispatch(
                calculatorUpdate('calculatorTotalExcavatedGear', machine)
              );
            }
            if (isInputByGearSelected) {
              store.dispatch(calculatorUpdate('isInputByGearSelected', true));
              store.dispatch(calculatorUpdate('gearLines', machine));
            }
            if (isTotalGazoilSelected) {
              store.dispatch(calculatorUpdate('isTotalGazoilSelected', true));
              store.dispatch(calculatorUpdate('calculatorTotalGazoilGear', machine));
            }
          }

          // transport traitment
          const transports = calcBody?.calculatorData?.transports || [];
          const transportLines =
            transports?.map((transport, transportIndex) => {
              const transportLine = {
                id: transportIndex,
                MasterDataEquipmentId:
                  transport?.allotmentNode?.MasterDataEquipmentId ||
                  transport.MasterDataEquipmentId,
                quantity: transport.quantity,
                unit: transport.unit,
                caracs: transport.caracs,
                caracCombo: transports.find(
                  (transportEquipment) =>
                    transportEquipment.id ===
                      transport?.allotmentNode?.MasterDataEquipmentId ||
                    transport.MasterDataEquipmentId
                ).caracCombo,
                origin: transport.origin,
              };

              return transportLine;
            }) || [];
          if (transportLines.length === 0) {
            transportLines.push({
              id: 0,
              MasterDataEquipmentId: null,
              quantity: null,
              type: null,
              unit: 'km',
              carburant: null,
              caracs: [],
              origin: {
                type: 'project',
                sourceId: null,
                uniqueId: uuidv4(),
              },
            });
          }
          store.dispatch(calculatorUpdate('transportLines', transportLines));

          // energy traitment
          let energyArray = [
            {
              MasterDataEquipment: null,
              quantity: '',
              caracs: null,
              AllotmentNode: null,
              origin: {
                type: 'project',
                sourceId: null,
                uniqueId: uuidv4(),
              },
            },
          ];
          let energySimplifiedArray = [
            {
              NumberMonthsWithCraneSummer: '',
              NumberMonthsWithCraneWinter: '',
              NumberMonthsWithoutCraneSummer: '',
              NumberMonthsWithoutCraneWinter: '',
              origin: {
                type: 'project',
                sourceId: null,
                uniqueId: uuidv4(),
              },
              ...getSimplifiedEnergyDefaultDataSelected(currentProject.templateType),
            },
          ];
          // case of energySimplified in calculator detailed
          if (typeProjectForSimplifiedEnergy.includes(currentProject.templateType)) {
            calcBody?.calculatorData?.energy?.energiesArray.map(
              (simplifiedEnergyLine) => {
                simplifiedEnergyLine.caracs = simplifiedEnergyLine.caracsSave;
              }
            );
            if (calcBody?.calculatorData?.energy?.energiesArray.length === 0) {
              calcBody.calculatorData.energy.energiesArray.push({
                id: 0,
                AllotmentNodeId: null,
                quantity: null,
                caracs: null,
                unit: 'kWh',
                origin: {
                  type: 'project',
                  sourceId: null,
                  uniqueId: uuidv4(),
                },
              });
            }
            store.dispatch(
              changeValueEquipments(
                'simplifiedEnergyLines',
                calcBody?.calculatorData?.energy?.energiesArray || [
                  {
                    id: 0,
                    AllotmentNodeId: null,
                    quantity: null,
                    caracs: [],
                    unit: 'kWh',
                    origin: {
                      type: 'project',
                      sourceId: null,
                      uniqueId: uuidv4(),
                    },
                  },
                ]
              )
            );
          }
          // case of calculatorDetailed and calculatorSimplified
          if (calcBody) {
            energyArray =
              calcBody?.calculatorData?.energy?.energiesArray?.length > 0
                ? calcBody?.calculatorData?.energy?.energiesArray.map((energy) => {
                    energy.caracs = energy.caracsSave;
                    return energy;
                  })
                : energyArray;
            energySimplifiedArray =
              calcBody?.calculatorData?.energyCalculatorSimplified
                ?.energySimplifiedArray?.length > 0
                ? calcBody?.calculatorData?.energyCalculatorSimplified?.energySimplifiedArray.map(
                    (energy) => {
                      energy.caracs = energy.caracsSave;
                      return energy;
                    }
                  )
                : energySimplifiedArray;
          }

          // water traitment
          let waterData = {
            displayUnit: 'm3',
            zonesData: [
              {
                drinkableWater: '', //! need string
                wastedWater: '', //! need string
                origin: {
                  type: 'project',
                  sourceId: null,
                  uniqueId: uuidv4(),
                },
              },
            ],
          };
          if (calcBody) {
            let waterDataFromApi = calcBody?.calculatorData?.water;
            waterData.displayUnit = waterDataFromApi?.displayUnit || 'm3';
            const uniqueIds = new Set();
            waterDataFromApi.zonesData.forEach((zoneData) =>
              uniqueIds.add(zoneData.origin.uniqueId)
            );
            const uniqueIdsArray = Array.from(uniqueIds);
            waterData.zonesData = [];
            uniqueIdsArray.forEach((uniqueId) => {
              const zonesDataFromUniqueId = waterDataFromApi.zonesData.filter(
                (zoneData) => zoneData.origin.uniqueId === uniqueId
              );
              const newZoneData = {
                drinkableWater:
                  zonesDataFromUniqueId
                    .find((zoneData) => zoneData.isDrinkableWater)
                    ?.quantity?.toString() || '',
                wastedWater:
                  zonesDataFromUniqueId
                    .find((zoneData) => zoneData.isWasteWater)
                    ?.quantity?.toString() || '',
                origin: zonesDataFromUniqueId[0].origin,
              };
              waterData.zonesData.push(newZoneData);
            });
          }
          if (waterData.zonesData.length === 0) {
            waterData = {
              displayUnit: 'm3',
              zonesData: [
                {
                  drinkableWater: '', //! need string
                  wastedWater: '', //! need string
                  origin: {
                    type: 'project',
                    sourceId: null,
                    uniqueId: uuidv4(),
                  },
                },
              ],
            };
          }

          // importedEarthWork traitment
          let importedArray = [
            {
              quantity: '',
              distance: '',
              origin: {
                type: 'project',
                sourceId: null,
                uniqueId: uuidv4(),
              },
            },
          ];
          if (calcBody && calcBody.calculatorData.importedEarthWork.length > 0) {
            importedArray = calcBody?.calculatorData?.importedEarthWork;
          }

          // exportedEarthWork traitment
          let evacuatedArray = [
            {
              quantity: '',
              distance: '',
              AllotmentNode: null,
              origin: {
                type: 'project',
                sourceId: null,
                uniqueId: uuidv4(),
              },
            },
          ];
          if (calcBody && calcBody.calculatorData.evacuatedEarthWork.length > 0) {
            evacuatedArray = calcBody?.calculatorData?.evacuatedEarthWork;
          }

          let evacuatedEarthWorkIndividualHouse = [
            {
              area: '',
              distance: '',
              origin: {
                type: 'project',
                sourceId: null,
                uniqueId: uuidv4(),
              },
            },
          ];
          if (
            calcBody &&
            calcBody?.calculatorData?.evacuatedEarthWorkIndividualHouse?.length > 0
          ) {
            evacuatedEarthWorkIndividualHouse =
              calcBody?.calculatorData?.evacuatedEarthWorkIndividualHouse.map(
                (individualHouseElement) => {
                  individualHouseElement.area =
                    individualHouseElement.area.toString();
                  individualHouseElement.distance =
                    individualHouseElement.distance.toString();
                  return individualHouseElement;
                }
              );
          }

          // specific traitment
          let specificArray = [
            {
              MasterDataEquipment: null,
              quantity: '',
              caracs: null,
              origin: {
                type: 'project',
                sourceId: null,
                uniqueId: uuidv4(),
              },
            },
          ];
          if (
            calcBody &&
            calcBody.calculatorData.specific.specificArray.length > 0
          ) {
            specificArray = calcBody.calculatorData.specific.specificArray.map(
              (specific) => {
                specific.caracs = specific.caracsSave;
                return specific;
              }
            );
          }

          const calculatorData = {
            energy: {
              energiesArray: energyArray,
            },
            energyCalculatorSimplified: {
              energySimplifiedArray: energySimplifiedArray,
            },
            water: waterData,
            importedEarthWork: importedArray,
            evacuatedEarthWork: evacuatedArray,
            evacuatedEarthWorkIndividualHouse: evacuatedEarthWorkIndividualHouse,
            specific: {
              specificArray: specificArray,
            },
          };

          store.dispatch(calculatorUpdate('calculatorData', calculatorData));

          store.dispatch(calculatorUpdate('loadingCalculator', false));
          store.dispatch(calculatorUpdate('isCalculatorDetailedLoaded', true));
        })
        .catch((e) => {
          console.log(e);
        });

      next(action);
      break;

    case GET_GEAR_CARAC:
      // console.log('GET_GEAR_CARAC')

      api
        .getEquipmentCaracListById(action.masterData.id)
        .then((response) => {
          // console.log('GET_GEAR_CARAC', response.data)
        })
        .catch((e) => {
          // console.log(e)
        });

      next(action);
      break;
    case GET_GEARS_CARACS: {
      // console.log('GET_GEARS_CARACS')
      // console.log('gearArray', action.masterDataGearArray)

      let gearsMasterDataWithCarac: any = [];

      action.masterDataGearArray.map((masterDataGear) => {
        api
          .getEquipmentCaracListById(masterDataGear.id)
          .then((response) => {
            // console.log('GET_GEARS_CARACS', response.data)

            const masterDataWithCarac = masterDataGear;

            masterDataWithCarac.caracs = response.data.caracs;
            gearsMasterDataWithCarac.push(masterDataWithCarac);

            return masterDataWithCarac;
          })
          .catch((e) => {
            // console.log(e)
          });
      });

      // console.log('gearsMasterDataWithCarac', gearsMasterDataWithCarac)
      // store.dispatch(calculatorUpdate('gearsCaracData', gearsMasterDataWithCarac))
      // console.log('test',
      //   test);
      next(action);
      break;
    }

    default:
      next(action);
  }
};

export default calculatorMiddleware;
