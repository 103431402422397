import React from 'react';
import Info from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

export type IcMaxBbcaTooltipProps = {
  icKey: string | undefined;
  icToDisplayMax: number;
  currentIndicator: string | { unit: string };
  refUnitModeTotal: boolean;
};
export const IcMaxBbcaTooltip: React.FC<IcMaxBbcaTooltipProps> = ({
  icKey,
  icToDisplayMax,
  currentIndicator,
  refUnitModeTotal,
}) => {
  return (
    <div style={{ marginLeft: '8px', color: '#a4aed0' }}>
      {
        <>
          <Tooltip
            title={`${icKey} max: ${icToDisplayMax} ${
              typeof currentIndicator === 'string'
                ? currentIndicator
                : currentIndicator.unit
            } ${refUnitModeTotal ? '' : '/m²'}`}
          >
            <Info />
          </Tooltip>
        </>
      }
    </div>
  );
};
