import './graphicHeader.scss';
import { StateDTO } from '../../../models/GeneralDTO';
import { Tooltip } from '@material-ui/core';
import { updateSimulator } from '../../../../actions/simulator';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { useTranslation } from 'react-i18next';

const firstVariantLegendIcon = require('../../../../assets/images/simulator/firstVariantLegendIcon.png');
const secondVariantLegendIcon = require('../../../../assets/images/simulator/secondVariantLegendIcon.png');
const lineChartIcon = require('../../../../assets/images/simulator/lineChartIcon.png');
const radarChartIcon = require('../../../../assets/images/simulator/radarChartIcon.png');
const barChartIcon = require('../../../../assets/images/simulator/barChartIcon.png');

const GraphicHeader = () => {
  const graphicChoice = useSelector(
    (state: StateDTO) => state.simulator.graphicChoice
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="graphicHeaderWrapper">
      <div className="simulatorLegend">
        <div className="simulatorLegend__variant">
          <div className="SimulatorLegend__variant--legendIcon">
            <img src={firstVariantLegendIcon} alt="" />
          </div>
          <div className="simulatorLegend__variant--legendTitle">
            {t('APP.GRAPHICHEADER.GRAPHICHEADER.PH_9C3086', {
              defaultValue: 'Variante 1',
            })}
          </div>
        </div>
        <div className="simulatorLegend__variant">
          <div className="simulatorLegend__variant--legendIcon">
            <img src={secondVariantLegendIcon} alt="" />
          </div>
          <div className="simulatorLegend__variant--legendTitle">
            {t('APP.GRAPHICHEADER.GRAPHICHEADER.PH_F0817E', {
              defaultValue: 'Variante 2',
            })}
          </div>
        </div>
      </div>
      <div className="simulatorGraphicChoice ">
        <div
          className={`simulatorGraphicChoice--iconContainer ${
            graphicChoice === 'radar' ? 'selected' : ''
          }`}
        >
          <Tooltip
            title={t('APP.GRAPHICHEADER.GRAPHICHEADER.PH_1C228E', {
              defaultValue:
                'Comparer les valeurs des impacts des variantes en vue radar sur la durée de vie du système',
            })}
          >
            <img
              src={radarChartIcon}
              alt=""
              onClick={() => {
                // handleSubmit(onSubmit, onSubmitError)('radar');
                dispatch(updateSimulator('graphicChoice', 'radar'));
              }}
            />
          </Tooltip>
        </div>
        <div
          className={`simulatorGraphicChoice--iconContainer ${
            graphicChoice === 'bar' ? 'selected' : ''
          }`}
        >
          <Tooltip
            title={t('APP.GRAPHICHEADER.GRAPHICHEADER.PH_57ADD5', {
              defaultValue:
                'Comparer les valeurs des impacts des variantes en vue histogramme sur la durée de vie du système',
            })}
          >
            <img
              src={barChartIcon}
              alt=""
              onClick={() => {
                // handleSubmit(onSubmit, onSubmitError)('bar');
                dispatch(updateSimulator('graphicChoice', 'bar'));
              }}
            />
          </Tooltip>
        </div>
        <div
          className={`simulatorGraphicChoice--iconContainer ${
            graphicChoice === 'line' ? 'selected' : ''
          }`}
        >
          <Tooltip
            title={t('APP.GRAPHICHEADER.GRAPHICHEADER.PH_62D318', {
              defaultValue:
                'Comparer les évolutions dans le temps des impacts des variantes sur la durée de vie du système',
            })}
          >
            <img
              src={lineChartIcon}
              alt=""
              onClick={() => {
                // handleSubmit(onSubmit, onSubmitError)('line');
                dispatch(updateSimulator('graphicChoice', 'line'));
              }}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default GraphicHeader;
