import './impactForm.scss';
import { Controller } from 'react-hook-form';
import { StateDTO } from '../../../../../models/GeneralDTO';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import {
  CREATION_STEPS,
  CREATION_STEPS_TRAD_KEYS,
} from '../../../../../globalVariable/creationProjectVariable';

interface ImpactFormProps {
  errors: any;
  control: any;
  setValue: Function;
}

const ImpactForm = ({ errors, control, setValue }: ImpactFormProps) => {
  const { buildingSiteDurationRset, parcelAreaRset, rsetFile, isRsenv } =
    useSelector((state: StateDTO) => state.projects);
  const { creationSteps } = useSelector(
    (state: StateDTO) => state.projects.creationProject
  );

  const { t } = useTranslation();

  console.log('isRsenv', isRsenv, parcelAreaRset, buildingSiteDurationRset);

  useEffect(() => {
    if (parcelAreaRset) {
      setValue('parcelArea', parcelAreaRset);
    }

    if (buildingSiteDurationRset) {
      setValue('buildingSiteDuration', buildingSiteDurationRset);
    }
  }, [parcelAreaRset, buildingSiteDurationRset]);

  return (
    <div className="impact-form-wrapper">
      <h4 className="subtitle-form" style={{ marginLeft: '5%', marginTop: '2%' }}>
        {t('APP.IMPACTFORM.INDEX.PH_9AA8F2', {
          defaultValue: 'Impacts supplémentaires',
        })}
      </h4>
      <div className="impact-form-core">
        <div className="building-site-form">
          <p>{t('APP.IMPACTFORM.INDEX.PH_AE262B', { defaultValue: 'Chantier' })}</p>
          <div>
            <p>
              {t('APP.IMPACTFORM.INDEX.PH_D4023A', {
                defaultValue: 'Durée du chantier (mois)',
              })}
            </p>
            <Controller
              control={control}
              key={buildingSiteDurationRset}
              name="buildingSiteDuration"
              defaultValue={buildingSiteDurationRset ? buildingSiteDurationRset : 12}
              rules={{
                required: !rsetFile,
                pattern: /^[0-9]{1,10}([,.][0-9]{1,10})?$/,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  style={{ width: '100%' }}
                  defaultValue={
                    buildingSiteDurationRset ? buildingSiteDurationRset : 12
                  }
                  key={buildingSiteDurationRset}
                  onChange={(e) => setValue('buildingSiteDuration', e.target.value)}
                  placeholder={t('APP.IMPACTFORM.INDEX.PH_52770F', {
                    defaultValue: 'Durée du chantier',
                  })}
                  variant="outlined"
                  className={`input-boarding researchBar ${
                    errors.buildingSiteDuration ? 'error-border-form' : ''
                  }`}
                />
              )}
            />
          </div>
        </div>
        <div className="building-site-form">
          <p>{t('APP.IMPACTFORM.INDEX.PH_0FD9E2', { defaultValue: 'Parcelle' })}</p>
          <div>
            <p>
              {t('APP.IMPACTFORM.INDEX.PH_F99523', {
                defaultValue: 'Surface de parcelle (m²)',
              })}
            </p>
            <Controller
              control={control}
              name="parcelArea"
              key={parcelAreaRset}
              rules={{
                required: !creationSteps.includes(
                  t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.EPCM])
                ),
                pattern: /^[0-9]{1,10}([,.][0-9]{1,10})?$/,
              }}
              // rules={{required : true}}
              render={({ field }) => (
                <TextField
                  data-testid="plot-area"
                  onChange={(e) => setValue('parcelArea', e.target.value)}
                  key={parcelAreaRset}
                  defaultValue={parcelAreaRset || null}
                  {...field}
                  style={{ width: '100%' }}
                  placeholder={t('APP.IMPACTFORM.INDEX.PH_BDF554', {
                    defaultValue: 'Surface de la parcelle',
                  })}
                  variant="outlined"
                  className={`input-boarding researchBar ${
                    errors.parcelArea ? 'error' : ''
                  }`}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImpactForm;
