/* eslint-disable react/prop-types,react/jsx-filename-extension */
import './App.css';
import './App.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as Sentry from '@sentry/react';
import {
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
} from 'aws-amplify/auth';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import API from './services/API';
import AppContext from './AppContext';
import AppRoutes from './AppRoutes';
import React, { Component } from 'react';
import Sidebar from '../containers/Sidebar/index';
import Socket from '../app/services/WebSocket';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Spinner from './shared/Spinner';
import CatcherLoginRedirect from './user-pages/CatcherLoginRedirect';

const ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_URL;

Sentry.init({
  dsn: 'https://c217a42c012944d8a3a3650cd576edc5@o122392.ingest.sentry.io/4505945920438272',
  environment: process.env.REACT_APP_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
    // new Sentry.Replay(),
  ],
  // Performance Monitoring
  //  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // profilesSampleRate: 1.0, // Capture 100% of the profiles, reduce in production!
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// console.log(`ENV = ${process.env.REACT_APP_ENV}`);

class App extends Component {
  static contextType = AppContext;

  constructor() {
    super();
    this.state = {
      isLoaded: false,
      isAdmin: false,
      isSuperAdmin: false,
      isAuth: false,
      companyAdmins: [],
      orgaAdmins: [],
      // widthDevice: window.visualViewport.width,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (!prevState.isAuth && this.state.isAuth) {
      this.props.setSuperAdmin(this.state.isAdmin, this.state.isSuperAdmin);

      let user = await this.isUserAuthenticated();

      if (process.env.REACT_APP_ENV === 'prod') {
        window.$crisp.push([
          'set',
          'user:nickname',
          [`${user.attributes?.given_name} ${user.attributes?.family_name}`],
        ]);
        window.$crisp.push(['set', 'user:email', [`${user.attributes?.email}`]]);
      }

      if (user && !this.props.connectedSocket) {
        const socket = new Socket(user);

        socket.connect(
          this.props.fetchImportDatas,
          this.props.updateFormValue,
          this.props.downloadRsee,
          this.props.downloadRseePdf,
          null,
          this.props.fetchProjectById,
          this.props.fetchImportDate,
          this.props.changeValueEquipments
        );

        setInterval(async () => {
          const socket = new Socket(user);

          socket.connect(
            this.props.fetchImportDatas,
            this.props.updateFormValue,
            this.props.downloadRsee,
            this.props.downloadRseePdf,
            this.props.connectedSocket,
            this.props.fetchProjectById,
            this.props.fetchImportDate,
            this.props.changeValueEquipments
          );
        }, 540000);
      }

      this.props.fetchCompanies(this.state.isSuperAdmin);
      // this.props.fetchAllGroups();
    }

    if (this.props.companies !== prevProps.companies && this.state.user) {
      const employees = this.props.companies
        .filter((comp) => comp.companyType === 'builder')
        .map((c) => {
          if (!c.Employees.length) {
            return [{ CompanyId: c.id, CompanyName: c.name }];
          }

          return c.Employees.map((employee) => {
            return { ...employee, CompanyName: c.name };
          });
        })
        .flat();

      if (employees.length) {
        let companyAdmins;
        let orgaAdmins;

        if (this.state.isSuperAdmin) {
          companyAdmins = employees
            .filter(
              (employee, i, ar) =>
                ar.findIndex((e) => e.CompanyId === employee.CompanyId) === i
            )
            .sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
          orgaAdmins = this.props.administrativeNodes
            .map((node) => ({
              AdministrativeNodeId: node.id,
              AdministrativeNodeName: node.name,
            }))
            .sort((a, b) =>
              a.AdministrativeNodeName.localeCompare(b.AdministrativeNodeName)
            );
        } else {
          companyAdmins = employees.filter(
            (e) => e.UserEmail === this.state.user.attributes?.email && e.isAdmin
          );
          companyAdmins = companyAdmins
            .filter(
              (employee, i, ar) =>
                ar.findIndex((e) => e.CompanyId === employee.CompanyId) === i
            )
            .sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
          orgaAdmins = employees.filter(
            (e) => e.UserEmail === this.state.user.attributes?.email && e.isAdminOrga
          );
          orgaAdmins = orgaAdmins
            .filter(
              (employee, i, ar) =>
                ar.findIndex(
                  (e) => e.AdministrativeNodeId === employee.AdministrativeNodeId
                ) === i
            )
            .sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));

          if (orgaAdmins.length) {
            companyAdmins = [
              ...new Set(
                [...companyAdmins, orgaAdmins.map((o) => o.CompanyIds).flat()].flat()
              ),
            ];
            companyAdmins = companyAdmins
              .filter(
                (employee, i, ar) =>
                  ar.findIndex((e) => e.CompanyId === employee.CompanyId) === i
              )
              .sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
          }
        }

        this.setState({ companyAdmins, orgaAdmins });

        this.props.setNodeAdmins(companyAdmins, orgaAdmins);
      }
    }

    // console.log('mise a jour componentdidupdate dans app.js');
  }

  async componentDidMount() {
    const html = document.querySelector('html');

    // console.log('mise a jour componentdidupdate dans app.js');

    if (process.env.REACT_APP_ENV === 'prod') {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = 'c1251dc1-eb6b-441e-be92-335c756f882f';
      (function () {
        var d = document;
        var s = d.createElement('script');

        s.src = 'https://client.crisp.chat/l.js';
        s.async = 1;
        d.getElementsByTagName('head')[0].appendChild(s);
      })();

      document
        .querySelector('meta[name=viewport]')
        .setAttribute(
          'content',
          'width=device-width, initial-scale=' +
            0.5 +
            ', maximum-scale=1.0, user-scalable=0'
        );
    }

    // document.body.style.webkitTransform =  scale;    // Chrome, Opera, Safari
    // document.body.style.msTransform =   scale;       // IE 9
    // document.body.style.zoom = 0.8;

    if (window.screen.availWidth < 1600 || window.screen.availHeight < 950) {
      if (window.screen.availHeight < 700) {
        html.style.fontSize = '60%';
      } else {
        html.style.fontSize = '70%';
      }

      html.style.overflow = 'hidden';
    }

    let user = await this.isUserAuthenticated();

    if (user) {
      this.props.loadUser(user);
      Sentry.setUser({ email: user.attributes?.email });

      this.setState({ isAuth: true, isLoaded: true, user: user });

      if (user && !this.props.connectedSocket) {
        const socket = new Socket(user);
        socket.connect(
          this.props.fetchImportDatas,
          this.props.updateFormValue,
          this.props.downloadRsee,
          this.props.downloadRseePdf,
          null,
          this.props.fetchProjectById,
          this.props.fetchImportDate,
          this.props.changeValueEquipments
        );
      }
    } else {
      this.setState({ isAuth: false, isLoaded: true, user: null });
      if (location.pathname.match('/health')) {
        return;
      }

      this.props.history.push('/login');
      Sentry.setUser(null);
    }
  }

  async setAdmin(user) {
    this.setState({ isAdmin: user.isAdmin });
    this.setState({ isSuperAdmin: user.isSuperAdmin });
  }

  async isUserAuthenticated(firstConnection) {
    let user = null;

    console.log(this.props.noProvider, 'isUserAuthenticated');

    try {
      let [user, userSession, userAttributes] = await Promise.all([
        getCurrentUser(),
        fetchAuthSession({ forceRefresh: true }),
        fetchUserAttributes(),
      ]);

      let groups = userSession.tokens.accessToken.payload['cognito:groups'];

      if (user) {
        user.attributes = userAttributes;
        user = {
          ...user,
          ...userSession,
          isSuperAdmin: groups?.includes('SuperAdmin'),
          isAdmin: groups?.includes('Admin'),
        };
      }

      this.setAdmin(user);

      return user;
    } catch (e) {
      console.log(e);

      return null;
    }
  }

  renderTestEnv() {
    if (process.env.REACT_APP_ENV === 'testing')
      return (
        <div className="logoTest">
          TESTING
          <div className="glare"></div>
        </div>
      );
  }

  render() {
    let sidebarComponent = (
      <Sidebar
        isAdmin={this.state.isAdmin}
        isSuperAdmin={this.state.isSuperAdmin}
        orgaAdmins={this.state.orgaAdmins}
        companyAdmins={this.state.companyAdmins}
        setState={this.setState.bind(this)}
      />
    );

    const { location } = this.props;

    if (
      location.pathname.match('/login') ||
      location.pathname.match('/verify-mail') ||
      location.pathname.match('/register') ||
      location.pathname.match('/forgotPassword') ||
      location.pathname.match('/ConfirmCode')
    ) {
      sidebarComponent = '';
    }

    if (!this.state.isLoaded) {
      return null;
    }

    if (
      this.props.isCompanyAdmin === undefined ||
      (this.state.isAuth &&
        (this.props.allGroupsStatus !== 'ok' || this.props.companiesStatus !== 'ok'))
    ) {
      return <Spinner />;
    }

    // To avoid console log in Prod

    if (process.env.NODE_ENV === 'production') {
      console.log = () => {};
    }

    // console.log(window.$crisp);

    return (
      <DndProvider backend={HTML5Backend}>
        <AppContext.Provider
          value={{
            config: this.props.config,
            user: this.state.user,
            isFullPageLayout: true,
            isAuthenticated: this.state.isAuth,
            authInterval: undefined,
            api: new API(),
            selectedEquipment: null,
            metrics: this.props.metrics,
            isAdmin: this.state.isAdmin,
            isSuperAdmin: this.state.isSuperAdmin,
          }}
        >
          <div className="container-scroller">
            {this.renderTestEnv()}
            <div
              className={
                this.state.isAuth
                  ? 'container-fluid page-body-wrapper'
                  : 'container-fluid page-body-wrapper centered'
              }
            >
              {!this.props.isStoreLoading ? sidebarComponent : ''}
              <CatcherLoginRedirect />
              <AppRoutes
                orgaAdmins={this.state.orgaAdmins}
                isCompanyAdmin={this.props.isCompanyAdmin}
              />
            </div>
          </div>
        </AppContext.Provider>
      </DndProvider>
    );
  }
}

export default withRouter(App);
