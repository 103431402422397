import {
  LOAD_NEW_EQUIPMENT_FIRST_VARIANT_SIMULATOR,
  LOAD_NEW_EQUIPMENT_SECOND_VARIANT_SIMULATOR,
  SIMULATOR_DUPLICATE_VARIANT_REDUCER,
  SIMULATOR_VARIANT_RESET_REDUCER,
  SimulatorActionDTO,
  UPDATE_SIMULATOR,
  UPDATE_SIMULATOR_DATA_FOR_GRAPH,
  UPDATE_SIMULATOR_FIRST_VARIANT,
  UPDATE_SIMULATOR_INFORMATION,
  UPDATE_SIMULATOR_SECOND_VARIANT,
} from '../actions/simulator';
import { SimulatorDTO } from '../app/models/SimulatorDTO';

const initialState: SimulatorDTO = {
  isSimulatorFetchData: false,
  loadingInformation: true,
  loadingGraph: true,
  information: {
    lifespan: '',
    refUnit: 'm²',
    area: '',
    style: 'static',
  },
  graphicChoice: 'line',
  isInformationValid: false,
  currentIndicator: {
    id: undefined,
    unit: undefined,
  },
  currentAllotment: {
    id: undefined,
    name: undefined,
    AllotmentNodes: [],
  },
  dataForGraph: {
    dataGraph: null,
    filters: null,
    keys: null,
    currentIndicator: null,
    dataGraphLine: [],
  },
  parsedProject: {
    parsed: [],
    raw: [],
    zones: [],
  },
  needDatas: [],
  fakeCurrentProject: {
    Lots: [],
    refUnit: '',
  },
  sheetsGraph: null,
  equipmentClicked: null,
  firstVariant: {
    loadingVariant: true,
    addChoice: undefined,
    variantListDatas: [],
    newEquipment: {
      MasterDataEquipment: null,
      quantity: null,
      caracs: [],
    },
    selectedRowKeys: [],
    newEquipmentQuantity: null,
    data: null,
    parsedProject: null,
  },
  secondVariant: {
    loadingVariant: true,
    addChoice: undefined,
    variantListDatas: [],
    newEquipment: {
      MasterDataEquipment: null,
      quantity: null,
      caracs: [],
    },
    selectedRowKeys: [],
    newEquipmentQuantity: null,
    data: null,
    parsedProject: null,
  },
};

function simulatorReducer(
  state: SimulatorDTO = initialState,
  action: SimulatorActionDTO
) {
  switch (action.type) {
    case UPDATE_SIMULATOR:
      return {
        ...state,
        [action.name]: action.value,
      };

    case SIMULATOR_DUPLICATE_VARIANT_REDUCER:
      return {
        ...state,
        secondVariant: {
          ...state.secondVariant,
          variantListDatas: state.firstVariant.variantListDatas,
          newEquipment: {
            MasterDataEquipment: null,
            quantity: null,
            caracs: [],
          },
          newEquipmentQuantity: null,
          loadingVariant: false,
        },
      };

    case SIMULATOR_VARIANT_RESET_REDUCER: {
      if (action.variantNumber === 'first') {
        return {
          ...state,
          firstVariant: {
            ...state.firstVariant,
            variantListDatas: [],
            selectedRowKeys: [],
            newEquipment: {
              MasterDataEquipment: null,
              quantity: null,
              caracs: [],
            },
            newEquipmentQuantity: null,
            loadingVariant: false,
            parsedProject: null,
            addChoice: null,
          },
        };
      } else {
        return {
          ...state,
          secondVariant: {
            ...state.secondVariant,
            variantListDatas: [],
            selectedRowKeys: [],
            newEquipment: {
              MasterDataEquipment: null,
              quantity: null,
              caracs: [],
            },
            newEquipmentQuantity: null,
            loadingVariant: false,
            parsedProject: null,
            addChoice: null,
          },
        };
      }
    }

    case LOAD_NEW_EQUIPMENT_FIRST_VARIANT_SIMULATOR:
      return {
        ...state,
        firstVariant: {
          ...state.firstVariant,
          // variantListDatas: [...state.firstVariant.variantListDatas, action.data.newEquipment],
          newEquipment: {
            MasterDataEquipment: null,
            quantity: null,
            caracs: [],
          },
          selectedRowKeys: [],
          newEquipmentQuantity: null,
          addChoice: null,
          // loadingVariant: false,
        },
      };

    case LOAD_NEW_EQUIPMENT_SECOND_VARIANT_SIMULATOR:
      return {
        ...state,
        secondVariant: {
          ...state.secondVariant,
          // variantListDatas: [...state.secondVariant.variantListDatas, action.data.newEquipment],
          newEquipment: {
            MasterDataEquipment: null,
            quantity: null,
            caracs: [],
          },
          selectedRowKeys: [],
          newEquipmentQuantity: null,
          addChoice: null,
          // loadingVariant: false,
        },
      };

    case UPDATE_SIMULATOR_FIRST_VARIANT:
      return {
        ...state,
        firstVariant: {
          ...state.firstVariant,
          [action.name]: action.value,
        },
      };

    case UPDATE_SIMULATOR_SECOND_VARIANT:
      return {
        ...state,
        secondVariant: {
          ...state.secondVariant,
          [action.name]: action.value,
        },
      };

    case UPDATE_SIMULATOR_DATA_FOR_GRAPH:
      return {
        ...state,
        dataForGraph: {
          ...state.dataForGraph,
          [action.name]: action.value,
        },
      };

    case UPDATE_SIMULATOR_INFORMATION:
      return {
        ...state,
        information: action.form,
      };

    // case USER_LOGOUT:
    //   return {
    //     ...state,
    //     loadingInformation: false,
    //     loadingGraph: false
    //   };

    default:
      return state;
  }
}

export default simulatorReducer;
