import './report.scss';
import { Button } from '@photocarbone/nooco-ui';
import { DatePicker } from 'antd';
import { DualAxes } from '@ant-design/plots';
import { ResponsiveBar } from '@nivo/bar';
import { StateDTO } from '../../models/GeneralDTO';
import { Tooltip } from '@mui/material';
import { area } from 'd3-shape';
import {
  fetchActionsModelsGraph,
  fetchImpactBarGraph,
  updateExploitationValue,
} from '../../../actions/exploitation';
import {
  getActionsModelsGraph,
  getImpactGraph,
} from '../../../apiCallFunctions/exploitation';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/fr_FR';
import i18nInstance from '../../../i18n';
import { useTranslation } from 'react-i18next';

interface ReportProps {
  datas: [];
}

// TODO_TRAD : conditionnal
export const returnColorsForBarGraph = (eventName: string) => {
  switch (eventName) {
    case "Renouvellement d'équipement/matériau":
    case 'Renewal':
      return 'hsl(253, 65%, 49%)';
    case "Consommation d'énergie":
    case 'Energy':
      return 'hsl(48, 99%, 63%)';
    case 'Consommation d’eau':
    case 'Water':
      return 'hsl(194, 100%, 85%)';

    case 'Car':
    case 'Truck':
      return 'hsl(195, 100%, 39%)';
    case 'Contrôle et entretien':
    case 'Control':
    case 'Filter':
    case 'Fluid':
    case 'Other':
      return 'hsl(0, 1%, 69%)';
    case 'Gestion de déchets':
    case 'Garbage':
      return 'hsl(161, 82%, 30%)';
    case 'Transport':
      return 'hsl(1, 23%, 48%)';

    default:
      console.log('no match found');
  }
};

const { RangePicker } = DatePicker;

const Report = () => {
  const dispatch = useDispatch();
  const { currentProject } = useSelector((state: StateDTO) => state.projects);
  const { eventTypes, datesSelectedInGraph, reportActiveTab } = useSelector(
    (state: StateDTO) => state.exploitation
  );

  let queryOptions = {};

  if (reportActiveTab === 'Période') {
    queryOptions = {
      queryKey: [
        'impactBarGraph',
        currentProject?.id,
        datesSelectedInGraph,
        reportActiveTab,
      ],
      queryFn: () =>
        getImpactGraph(currentProject?.id, eventTypes, datesSelectedInGraph, t),
    };
  } else {
    queryOptions = {
      queryKey: [
        'actionsModelsGraphData',
        currentProject?.id,
        datesSelectedInGraph,
        reportActiveTab,
      ],
      queryFn: () =>
        getActionsModelsGraph(currentProject?.id, datesSelectedInGraph, t),
      refetchOnWindowFocus: false,
    };
  }

  const { data: dataGraph, isLoading, isError, isSuccess } = useQuery(queryOptions);

  let impactBarGraph = dataGraph;
  const [widthTable, setWidthTable] = useState('');
  const { isMenuOpen } = useSelector((state: StateDTO) => state.projects);
  const { t } = useTranslation();

  useEffect(() => {
    let widthTable = '';

    if (window.screen.availHeight < 950) {
      if (isMenuOpen) {
        widthTable = `${window.innerWidth - 285}px`;
      } else {
        widthTable = `${window.innerWidth - 120}px`;
      }

      window.addEventListener('resize', function () {
        if (isMenuOpen) {
          widthTable = `${window.innerWidth - 285}px`;
        } else {
          widthTable = `${window.innerWidth - 120}px`;
        }

        setWidthTable(widthTable);
      });

      setWidthTable(widthTable);
    } else {
      if (isMenuOpen) {
        widthTable = `${window.innerWidth - 315}px`;
      } else {
        widthTable = `${window.innerWidth - 165}px`;
      }

      window.addEventListener('resize', function () {
        if (isMenuOpen) {
          widthTable = `${window.innerWidth - 315}px`;
        } else {
          widthTable = `${window.innerWidth - 165}px`;
        }

        setWidthTable(widthTable);
      });

      setWidthTable(widthTable);
    }
  }, [isMenuOpen]);

  const reportChangeTab = (el: string) => {
    dispatch(updateExploitationValue('datesSelectedInGraph', null));
    dispatch(updateExploitationValue('reportActiveTab', el));
  };

  const returnExploitGraph = () => {
    let actions;
    let modelLines;

    if (reportActiveTab !== 'Période') {
      actions = datesSelectedInGraph
        ? dataGraph?.actionsData.filter((item) => {
            const year = dayjs(item.year, 'YYYY');

            return year.isBetween(
              datesSelectedInGraph[0],
              datesSelectedInGraph[1],
              null,
              '[]'
            );
          })
        : dataGraph?.actionsData;

      modelLines = datesSelectedInGraph
        ? dataGraph?.modelLines.filter((item) => {
            const year = dayjs(item.year, 'YYYY');

            return year.isBetween(
              datesSelectedInGraph[0],
              datesSelectedInGraph[1],
              null,
              '[]'
            );
          })
        : dataGraph?.modelLines;
    }

    const config = {
      data: [actions, modelLines],
      xField: 'year',
      yField: ['carbonImpact', 'carbonImpact'],
      yAxis: {
        carbonImpact: {
          min: 0,
          max: dataGraph?.maxValue,
        },
      },
      geometryOptions: [
        {
          geometry: 'column',
          isStack: true,
          seriesField: 'name',
          columnWidthRatio: 0.4,
          color: (datum, index) => {
            return returnColorsForBarGraph(datum.name);
          },
        },
        {
          geometry: 'line',
          seriesField: 'model',
          lineStyle: ({ name }) => {
            if (name === 'a') {
              return {
                lineDash: [1, 4],
                opacity: 1,
              };
            }

            return {
              opacity: 0.5,
            };
          },
        },
      ],
    };

    return reportActiveTab === 'Période' ? (
      <div className="bar-graph-actions" style={{ width: widthTable }}>
        {dataGraph && !isLoading && (
          <ResponsiveBar
            data={dataGraph.data}
            keys={dataGraph?.keys?.map((el) => el.key)}
            indexBy="month"
            margin={{ top: 50, right: 170, bottom: 50, left: 40 }}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={({ id, data }) => {
              return data[`${id}Color`];
            }}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            borderColor={{
              from: 'color',
              modifiers: [['darker', '1.4']],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              // legend: 'Mois',
              // legendPosition: 'middle',
              // legendOffset: 32
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              // legend: 'Impact',
              // legendPosition: 'middle',
              // legendOffset: -40
            }}
            enableLabel={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={function (e) {
              return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue;
            }}
          />
        )}
      </div>
    ) : (
      <div className="bar-graph-actions">
        {dataGraph && !isLoading && <DualAxes {...config} />}
      </div>
    );
  };

  const returnLegendGraph = () => {
    if (impactBarGraph) {
      return (
        <div className="wrapper-legends">
          {impactBarGraph.keys.map((el) => (
            <div key={el} className="legend">
              <p className="color-legend" style={{ background: el.color }}></p>
              {el.key.length > 25 ? (
                <Tooltip title={el.key}>
                  <p>{el.key.substring(0, 25)}...</p>
                </Tooltip>
              ) : (
                <p>{el.key}</p>
              )}
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="list-actions-wrapper report-wrapper ">
      <div className="report-header">
        <div className="buttons" style={{ display: 'flex', alignItems: 'center' }}>
          {[
            t('APP.REPORT.INDEX.PH_F3BFEB', { defaultValue: 'Période' }),
            t('APP.REPORT.INDEX.PH_E7A5CC', { defaultValue: 'Trajectoire' }),
          ].map((el) => (
            <Button
              key={el}
              style={{
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : undefined,
                marginRight: '1em',
              }}
              onClick={() => reportChangeTab(el)}
              className={reportActiveTab === el ? 'golden-button' : 'gray-button'}
              text={el}
            />
          ))}
          <div className="choice-periods">
            <span>
              {t('APP.REPORT.INDEX.PH_C76ADB', {
                defaultValue: 'Choix de la période :',
              })}
            </span>
            {reportActiveTab ===
            t('APP.REPORT.INDEX.PH_65996D', { defaultValue: 'Période' }) ? (
              <RangePicker
                onChange={(dates) =>
                  dispatch(updateExploitationValue('datesSelectedInGraph', dates))
                }
                picker={'month'}
                locale={locale}
                className={`permis-form`}
                format={'MM/YYYY'}
                disabledDate={(d) => {
                  return (
                    d.isBefore(currentProject.exploitContract[0].value) ||
                    d.isAfter(dayjs(currentProject.exploitContract[1].value))
                  );
                }}
                defaultValue={[
                  dayjs(currentProject.exploitContract[0].value),
                  dayjs(currentProject.exploitContract[1].value),
                ]}
              />
            ) : (
              <RangePicker
                key={reportActiveTab}
                onChange={(dates) =>
                  dispatch(updateExploitationValue('datesSelectedInGraph', dates))
                }
                picker={'year'}
                locale={locale}
                className={`permis-form`}
                format={'YYYY'}
                disabledDate={(d) => {
                  return (
                    d.isBefore(currentProject.exploitContract[0].value) ||
                    d.isAfter(
                      dayjs(currentProject.exploitContract[0].value)
                        .add(currentProject.ddv, 'years')
                        .format()
                    )
                  );
                }}
                defaultValue={[
                  dayjs(currentProject.exploitContract[0].value),
                  dayjs(currentProject.exploitContract[0].value).add(
                    currentProject.ddv,
                    'years'
                  ),
                ]}
              />
            )}
          </div>
        </div>
      </div>
      <div className="exploit-report-graph">
        {returnExploitGraph()}
        {reportActiveTab ===
          t('APP.REPORT.INDEX.PH_D49FE7', { defaultValue: 'Période' }) && (
          <div className="legend-exploitation">{returnLegendGraph()}</div>
        )}
      </div>
    </div>
  );
};

export default Report;
