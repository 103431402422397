import {
  CREATE_SIMULATOR_FIRST_VARIANT_NEW_EQUIPMENT,
  CREATE_SIMULATOR_SECOND_VARIANT_NEW_EQUIPMENT,
  DELETE_SIMULATOR_EQUIPMENT,
  FETCH_QUICK_SIMULATOR,
  FETCH_SHEETS_GRAPH,
  GET_CARAC_SIMULATOR_VARIANT,
  PATCH_QUICK_SIMULATOR_INFORMATION,
  SIMULATOR_DUPLICATE_VARIANT,
  SIMULATOR_FIRST_VARIANT_RESET,
  SIMULATOR_SECOND_VARIANT_RESET,
  fetchQuickSimulator,
  loadNewEquipmentFirstVariantSimulator,
  loadNewEquipmentSecondVariantSimulator,
  updateSimulator,
  updateSimulatorFirstVariant,
  updateSimulatorSecondVariant,
} from '../actions/simulator';
import { StateDTO } from '../app/models/GeneralDTO';
import { Store } from 'redux';
import { createProjectStructure } from '../app/shared/parsingDatas';
import { findParentNode } from '../app/shared/utils';
import { makeDataGraph } from '../app/shared/makeDataGraph';
import { parseProject } from '../functions/parseProject';
import { updateFormValue } from '../actions/projects';
import API from '../app/services/API';

const api = new API();
var _ = require('lodash');
const Coeff = require('../app/projects/LifeCycle/CoefAcv.js');

const simulatorMiddleware = (store: Store<StateDTO>) => (next) => async (action) => {
  const { simulator, projects } = store.getState();
  const { firstVariant, secondVariant, graphicChoice, information } = simulator;
  const { allGroups } = projects;

  const updateSimulatorData = (data) => {
    const deepCopyData = _.cloneDeep(data);

    const information = {
      lifespan: data[0].project.ddv.toString(),
      refUnit: data[0].project.refUnit,
      area: data[0].project.area.toString(),
      style: data[0].project.ProjectOption.isQuickSimStatic ? 'static' : 'dynamic',
    };

    store.dispatch(updateSimulator('information', information));
    store.dispatch(
      updateSimulatorFirstVariant(
        'data',
        deepCopyData.find((variant) => variant.project.name === 'Variante 1')
      )
    );
    store.dispatch(
      updateSimulatorSecondVariant(
        'data',
        deepCopyData.find((variant) => variant.project.name === 'Variante 2')
      )
    );
    store.dispatch(updateSimulator('loadingInformation', false));
  };

  const prepareNewEquipmentDataToSendApi = (variantNumber: 'first' | 'second') => {
    const variant = variantNumber === 'first' ? firstVariant : secondVariant;
    let typeFicheDecParentNode = ['eau', 'energie', 'fluides', 'energie simplifiée'];
    const type =
      variant?.newEquipment?.type_fiche === 'bundle'
        ? variant.newEquipment.type_fiche
        : ['custom_user', 'FC'].includes(variant?.newEquipment?.type_fiche)
        ? 'SHEET_FROM_LIB'
        : variant?.newEquipment?.type_fiche
        ? variant?.newEquipment?.type_fiche
        : typeFicheDecParentNode.includes(
            findParentNode(
              variant.newEquipment.AllotmentNode,
              allGroups.flatAllotments,
              [],
              []
            )[0].name.toLowerCase()
          )
        ? 'DEC'
        : 'users';

    const body = {
      id:
        variant.newEquipment?.iniesNode?.id ||
        variant.newEquipment?.AllotmentNode?.id,
      AllotmentNodes: variant?.newEquipment?.AllotmentNode
        ? [variant.newEquipment.AllotmentNode]
        : variant.newEquipment?.iniesNode
        ? [variant.newEquipment?.iniesNode]
        : ['custom_user', 'FC'].includes(variant.newEquipment?.type_fiche)
        ? variant.newEquipment?.AllotmentNodes
        : [],
      ZoneId: variant.data.project.Zones[0].id,
      caracs: [],
      quantity: variant.newEquipmentQuantity,
      fluid: variant.newEquipment.fluid,
      SheetId: variant.newEquipment?.SheetId,
      type_fiche: variant.newEquipment?.type_fiche,
      type,
    };

    variant.newEquipment.caracs?.caracs?.forEach((carac) => {
      const newCarac = {
        id: carac.id,
        name: carac.name,
        value: carac.value,
      };

      body.caracs.push(newCarac);
    });

    return body;
  };

  const createCurrentIndicator = (variant) => {
    const currentIndicator = {
      id: variant.project.ProjectOption.isQuickSimStatic ? 26 : 1,
      shortName: variant.project.ProjectOption.isQuickSimStatic ? 'EGES' : 'Ic',
      unit: variant.project.refUnit === 'total' ? 'kg éq. CO₂' : 'kg éq. CO₂/m²',
    };

    store.dispatch(updateSimulator('currentIndicator', currentIndicator));

    return currentIndicator;
  };

  const createCurrentAllotment = () => {
    const currentAllotment = projects?.allGroups?.allotments.find(
      (allotment) => allotment.id === 1
    );

    store.dispatch(updateSimulator('currentAllotment', currentAllotment));

    return currentAllotment;
  };

  const createParsedProject = (variant, currentIndicator) => {
    const parsedProject = parseProject(
      variant.project,
      projects.allGroups.type,
      projects.allGroups.sousLot,
      currentIndicator
    );

    store.dispatch(updateSimulator('parsedProject', parsedProject));

    if (variant.project.name === 'Variante 1') {
      store.dispatch(updateSimulatorFirstVariant('parsedProject', parsedProject));
    } else {
      store.dispatch(updateSimulatorSecondVariant('parsedProject', parsedProject));
    }

    return parsedProject;
  };

  const createFakeCurrentProject = (variant) => {
    const fakeCurrentProject = {
      Lots: projects?.allGroups?.projectTypes.find((type) => type.name === 'nooco')
        ?.AllotmentNodes,
      refUnit: variant.project.refUnit,
    };

    store.dispatch(updateSimulator('fakeCurrentProject', fakeCurrentProject));

    return fakeCurrentProject;
  };

  const createNeedDatas = (
    needDatas,
    variant,
    currentAllotment,
    parsedProject,
    parsedSheets
  ) => {
    needDatas = [
      ...needDatas,
      createProjectStructure(
        currentAllotment,
        parsedSheets,
        parsedProject.zones,
        variant.project,
        false
      ),
    ];

    needDatas.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });

    store.dispatch(updateSimulator('needDatas', needDatas));

    return needDatas;
  };

  const createVariantListData = (variant, parsedProject) => {
    const variantListDataWithoutBundleHeads =
      variant.project.Zones[0].ProjectSheets.filter(
        (projectSheet) =>
          projectSheet.MasterDataEquipment !== null &&
          projectSheet.Sheet.type_fiche !== 'bundle'
      );
    const variantListData = variantListDataWithoutBundleHeads.map((projectSheet) => {
      let mdFromAllGroups = projects.allGroups.type.find(
        (mds) => mds.id === projectSheet.MasterDataEquipment.id
      );

      return {
        id: projectSheet.MasterDataEquipment.id,
        MasterDataEquipmentId: projectSheet.MasterDataEquipment.id,
        quantity: projectSheet.quantity,
        MasterDataEquipment: mdFromAllGroups,
        unit: projectSheet?.Unit?.name || '',
        name: projectSheet.MasterDataEquipment.name,
        caracs: projectSheet.MasterDataEquipment.Caracs,
        key: projectSheet.id,
        completeName: projectSheet.Sheet.name,
        currentMd: mdFromAllGroups,
        parsedProjectZones: parsedProject.zones,
      };
    });

    if (variant.project.name === 'Variante 1') {
      store.dispatch(
        updateSimulatorFirstVariant('variantListDatas', variantListData)
      );
      store.dispatch(updateSimulatorFirstVariant('loadingVariant', false));
    } else {
      store.dispatch(
        updateSimulatorSecondVariant('variantListDatas', variantListData)
      );
      store.dispatch(updateSimulatorSecondVariant('loadingVariant', false));
    }

    return variantListData;
  };

  const createDataset = (
    variant,
    currentAllotment,
    needDatas,
    currentIndicator,
    parsedProject,
    projects
  ) => {
    let initialDatas = needDatas[0];

    const buildingSiteZones = []; //TODO or id of THE zone of the variant
    const nodeFamilies = initialDatas?.children?.map((gd) =>
      initialDatas?.children[0].children.length === 0
        ? { label: gd.label, color: gd.color, ids: [gd.id] }
        : getFamilyFromParentNode(
            gd.id,
            currentAllotment.AllotmentNodes,
            gd.label,
            gd.color
          )
    );

    let datas = [
      {
        ids: nodeFamilies.reduce((p, c) => p.concat(c.ids), []),
        // "id": variantNumber === 'firstVariant' ? "Variante 1" : "Variante 2",
        id: variant.project.name,
        color:
          variant.project.name === 'Variante 1'
            ? 'hsl(168, 25%, 70%)'
            : 'rgb(70, 221, 196)',
        data: [],
      },
    ];

    let processedDataSets = [];
    const equipments = parsedProject.parsed;

    for (let data of datas) {
      if (!processedDataSets.includes(data?.id)) {
        data.data = getLCAbyNode(
          equipments,
          currentIndicator.id,
          variant.project.ddv,
          buildingSiteZones,
          variant.project.area,
          variant.project.refUnit,
          projects
        );
      }
    }

    const newData = [...datas];

    newData.sort(
      (a, b) =>
        Math.abs(a.data[variant.project.ddv].y) -
        Math.abs(b.data[variant.project.ddv].y)
    );

    let min = 0;

    for (let i = 0; i < newData[0].data.length && min >= 0; i++) {
      let tot = 0;

      for (let d of newData) {
        tot += d.data[i].y;
      }

      min = tot;
    }

    return newData;
  };

  const updateSimulatorAfterFetchData = (response) => {
    const deepCopyResponse = _.cloneDeep(response);
    store.dispatch(updateSimulator('isSimulatorFetchData', false));

    if (!graphicChoice) {
      store.dispatch(updateSimulator('graphicChoice', 'bar'));
    }

    updateSimulatorData(response.data);

    let needDatas: any = [];
    let currentAllotment;
    let fakeCurrentProject = { Lots: [], refUnit: '' };
    let currentIndicator;
    let parsedProject;
    let parsedSheets;
    let dataGraphLine = [];

    deepCopyResponse.data.forEach((variant) => {
      const deepCopyVariant = _.cloneDeep(variant);

      currentIndicator = createCurrentIndicator(variant);

      if (projects?.allGroups?.allotments) {
        currentAllotment = createCurrentAllotment();
      }

      parsedProject = createParsedProject(variant, currentIndicator);

      parsedSheets = parsedProject.parsed;
      needDatas = createNeedDatas(
        needDatas,
        variant,
        currentAllotment,
        parsedProject,
        parsedSheets
      );

      if (projects?.allGroups?.projectTypes) {
        fakeCurrentProject = createFakeCurrentProject(variant);
      }

      createVariantListData(deepCopyVariant, parsedProject);

      dataGraphLine.push(
        ...createDataset(
          variant,
          currentAllotment,
          needDatas,
          currentIndicator,
          parsedProject,
          projects
        ).filter((dataGraphLineVariant) => {
          let year = new Date().getFullYear();

          dataGraphLineVariant.data.filter((data) => {
            data.x = year;
            year += 1;
            data.y = data.y;

            return data;
          });

          return dataGraphLineVariant;
        })
      );
    });

    const allDatas = makeDataGraph(
      needDatas,
      fakeCurrentProject,
      currentAllotment,
      1
    );

    store.dispatch(
      updateSimulator('dataForGraph', {
        ...allDatas,
        currentIndicator: currentIndicator,
        dataGraphLine: dataGraphLine,
      })
    );
    store.dispatch(updateSimulator('loadingGraph', false));
  };

  switch (action.type) {
    case PATCH_QUICK_SIMULATOR_INFORMATION: {
      const dataToSend = {
        updateInformation: [
          {
            ProjectId: firstVariant.data.project.id,
            ddv: action.form.lifespan,
            refUnit: action.form.refUnit,
            area: action.form.area,
            ProjectOption: {
              isQuickSimStatic: action.form.style === 'static' ? true : false,
            },
          },
          {
            ProjectId: secondVariant.data.project.id,
            ddv: action.form.lifespan,
            refUnit: action.form.refUnit,
            area: action.form.area,
            ProjectOption: {
              isQuickSimStatic: action.form.style === 'static' ? true : false,
            },
          },
        ],
      };

      store.dispatch(updateSimulator('loadingGraph', true));

      api
        .patchQuickSimulator(dataToSend)
        .then((response) => {
          updateSimulatorAfterFetchData(response);
        })
        .catch((error) => {
          console.log(error);
        });

      next(action);
      break;
    }

    case FETCH_QUICK_SIMULATOR: {
      if (simulator.isSimulatorFetchData) {
        next(action);
        break;
      }
      api
        .getQuickSimulator()
        .then((response) => {
          updateSimulatorAfterFetchData(response);
        })
        .catch((error) => {
          console.log(error);

          if (error?.response?.status === 404) {
            api
              .createQuickSimulator()
              .then((response) => {
                updateSimulatorAfterFetchData(response);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });

      next(action);
      break;
    }

    case GET_CARAC_SIMULATOR_VARIANT: {
      api
        .getCaracsByAllotmentNodes(action.AllotmentNodesIds)
        .then((response) => {
          response.data.caracs = response.data.caracs.filter(
            (el) => el.neededOnCreation || el.neededOnImplementation
          );

          if (action.variantNumber === 'first') {
            store.dispatch(
              updateSimulatorFirstVariant('newEquipment', {
                ...firstVariant.newEquipment,
                caracs: response.data,
              })
            );
          } else {
            store.dispatch(
              updateSimulatorSecondVariant('newEquipment', {
                ...secondVariant.newEquipment,
                caracs: response.data,
              })
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });

      next(action);
      break;
    }

    case DELETE_SIMULATOR_EQUIPMENT: {
      const variant =
        action.variantNumber === 'first' ? firstVariant : secondVariant;
      const projectSheetIds = [];

      variant.selectedRowKeys.map((selectedRowkey) => {
        projectSheetIds.push(
          variant?.data?.project?.Zones[0].ProjectSheets?.find(
            (sheet) => sheet.id === selectedRowkey
          ).id
        );
      });

      if (action.variantNumber === 'first') {
        store.dispatch(updateSimulatorFirstVariant('selectedRowKeys', []));
      } else {
        store.dispatch(updateSimulatorSecondVariant('selectedRowKeys', []));
      }

      api
        .deleteEquipments(variant.data.project.id, projectSheetIds)
        .then((response) => {
          store.dispatch(fetchQuickSimulator());
        })
        .catch((error) => {
          console.log('error delete simulator equipment', error);
        });

      next(action);
      break;
    }

    case SIMULATOR_FIRST_VARIANT_RESET: {
      api
        .resetQuickSimulatorVariant(firstVariant.data.project.id)
        .then((response) => {
          updateSimulatorAfterFetchData(response);
        })
        .catch((error) => {
          console.log('error reset variant 1 simulator', error);
        });

      next(action);
      break;
    }

    case SIMULATOR_DUPLICATE_VARIANT: {
      api
        .duplicateSimulatorFirstVariantToSecondVariant(
          firstVariant.data.project.id,
          secondVariant.data.project.id
        )
        .then((response) => {
          updateSimulatorAfterFetchData(response);
        })
        .catch((error) => {
          console.log(error);
        });

      next(action);
      break;
    }

    case SIMULATOR_SECOND_VARIANT_RESET: {
      api
        .resetQuickSimulatorVariant(secondVariant.data.project.id)
        .then((response) => {
          updateSimulatorAfterFetchData(response);
        })
        .catch((error) => {
          console.log('error reset variant 2 simulator', error);
        });

      next(action);
      break;
    }

    case CREATE_SIMULATOR_FIRST_VARIANT_NEW_EQUIPMENT: {
      const dataToSend = prepareNewEquipmentDataToSendApi('first');

      api
        .createEquipment(dataToSend, firstVariant.data.project.id)
        .then((response) => {
          store.dispatch(loadNewEquipmentFirstVariantSimulator());
          updateSimulatorAfterFetchData({ data: response.data.computation });
        })
        .catch((error) => {
          console.log(error);
        });

      next(action);
      break;
    }

    case CREATE_SIMULATOR_SECOND_VARIANT_NEW_EQUIPMENT: {
      const dataToSend = prepareNewEquipmentDataToSendApi('second');

      api
        .createEquipment(dataToSend, secondVariant.data.project.id)
        .then((response) => {
          store.dispatch(loadNewEquipmentSecondVariantSimulator());

          updateSimulatorAfterFetchData({ data: response.data.computation });
        })
        .catch((error) => {
          console.log(error);
        });

      next(action);
      break;
    }

    case FETCH_SHEETS_GRAPH: {
      const allotmentNodeId = action.allotmentNodeId;

      api
        .getIndicatorsBySheet(
          allotmentNodeId,
          action.currentIndicatorId,
          action.projectId
        )
        .then((req) => {
          store.dispatch(updateFormValue('sheets', req.data));
        })
        .catch((error) => {
          console.log(error);
        });

      next(action);
      break;
    }

    default:
      next(action);
  }
};

const getFamilyFromParentNode = (nodeId, nodes, label?, color?) => {
  let childs = nodes.filter((n) => n.ParentId === nodeId);
  let family = childs.length === 0 ? [nodeId] : [];

  for (let child of childs)
    family = family?.concat(getFamilyFromParentNode(child.id, nodes));

  return label && color ? { label: label, color: color, ids: family } : family;
};

const getLCAbyNode = (
  projectSheets,
  indexId,
  ddv,
  buildingSites,
  projectArea,
  refUnit,
  projects
) => {
  projectSheets.map((sheet) =>
    sheet.ddv ? (sheet.ddv = sheet.ddv) : (sheet.ddv = 1000)
  );

  let lcaTot = [];

  for (let i = 0; i <= ddv; i++) lcaTot.push({ x: `${i}`, y: 0 });

  // TODO promise array that for loop
  for (let ps of projectSheets) {
    let lcaSheet = 0;

    const fluidACV =
      ps.childEquip ||
      (ps.ProjectSheet.ProjectSheets && ps.ProjectSheet.ProjectSheets[0])
        ? ps.ACV[`${indexId === 26 ? 1 : indexId}`][
            indexId === 1 ? 'dynamic' : 'static'
          ]
        : null;
    const fluidValues =
      ps.childEquip ||
      (ps.ProjectSheet.ProjectSheets && ps.ProjectSheet.ProjectSheets[0])
        ? {
            end: fluidACV.base.endfluide ? fluidACV.base.endfluide : 0,
            b1: fluidACV.usage.b1fluide ? fluidACV.usage.b1fluide : 0,
            b4: fluidACV.usage.b4fluide ? fluidACV.usage.b4fluide : 0,
            ddv: ps.ddv,
            ZoneId: ps.ProjectSheet.ZoneId,
          }
        : null;
    let panPhotoCoef =
      ps.ProjectSheet.Caracs &&
      ps.ProjectSheet.Caracs.length > 0 &&
      ps.ProjectSheet.Caracs.find((c) => c.name.includes('autoconsommation')) !==
        undefined &&
      ps.ProjectSheet.Caracs.find((c) => c.name.includes('Production')) !== undefined
        ? Number(
            ps.ProjectSheet.Caracs.find((c) => c.name.includes('autoconsommation'))
              .SheetCarac.value[0]
          ) /
          Number(
            ps.ProjectSheet.Caracs.find((c) => c.name.includes('Production'))
              .SheetCarac.value[0]
          )
        : 1;

    const flatAllotments = projects.allGroups.flatAllotments;
    const lot = findParentNode(
      ps?.ProjectSheet?.AllotmentNodes?.find(
        (allotmentNode) => allotmentNode.AllotmentId === 1
      ),
      flatAllotments,
      [],
      []
    )[0].name;

    for (let lca of lcaTot) {
      const yearCoef = indexId === 1 ? Coeff[lca.x] : 1;

      lcaSheet +=
        getYearIc(
          ps,
          lca,
          ddv,
          buildingSites,
          indexId === 26 ? 1 : indexId,
          projectArea,
          lot
        ) *
        Number(ps.ProjectSheet.quantity) *
        yearCoef *
        panPhotoCoef;

      if (
        ps.childEquip ||
        (ps.ProjectSheet.ProjectSheets && ps.ProjectSheet.ProjectSheets[0])
      ) {
        lcaSheet +=
          getFluidYearIc(
            fluidValues,
            lca,
            ddv,

            buildingSites,
            indexId === 26 ? 1 : indexId
          ) * (refUnit === 'total' ? projectArea : 1);
      }

      lca.y += lcaSheet / (refUnit === 'total' ? 1 : projectArea);
    }
  }

  return lcaTot;
};

const getYearIc = (ps, lca, ddv, buildingSites, indexId, projectArea, lot) => {
  const year = Number(lca.x);
  let values = ps.Indices.find((i) => i.id === indexId);

  if (values !== undefined) values = values.SheetIndex;

  // Filtering buildingSite equipments outside of year 0
  if (buildingSites.includes(ps.ProjectSheet.ZoneId)) {
    if (year === 0) {
      return values
        ? getTOTAL(values) - ((ps.ddv - 1) / ps.ddv) * getB1To4(values)
        : (ps.acvResults.base.edifChantier / ps.ProjectSheet.quantity) * projectArea;
    } else {
      return 0;
    }
  }

  if (!values) return 0;

  if (['Eau', 'Energie', 'Energie simplifiée'].includes(lot) && year !== 0)
    return getTOTAL(values);

  const isForfait = ps.type_fiche === 'FORFAIT';

  if (year === 0) {
    return getAProd(values) + getAEdif(values);
  } else if (year === ddv) {
    return getB1To4(values, isForfait) / ps.ddv + getC(values);
  } else {
    if (year % ps.ddv === 0) {
      if (ddv - year < ps.ddv)
        return (
          getB1To4(values, isForfait) / ps.ddv +
          (ddv / ps.ddv - Math.floor(ddv / ps.ddv)) *
            (getAEdif(values) + getAProd(values) + getC(values))
        );

      return (
        getB1To4(values, isForfait) / ps.ddv +
        getAEdif(values) +
        getAProd(values) +
        getC(values)
      );
    }

    return getB1To4(values, isForfait) / ps.ddv;
  }
};

const getFluidYearIc = (fv, lca, ddv, buildingSites, indexId) => {
  const year = Number(lca.x);

  const usage = (fv.b1 + fv.b4) / ddv;
  const end = fv.end / (ddv / fv.ddv);

  if (buildingSites.includes(fv.ZoneId)) {
    if (year === 0) {
      return fv.b1 + fv.b4 + fv.end; // *ddvChantier;
    } else {
      return 0;
    }
  }

  if (year === 0) {
    return 0;
  } else if (year === ddv) {
    return usage + end;
  } else {
    if (year % fv.ddv === 0) {
      if (ddv - year < fv.ddv)
        return usage + (ddv / fv.ddv - Math.floor(ddv / fv.ddv)) * end;

      return usage + end;
    }

    return usage;
  }
};

const getB1To4 = (v, isForfait) => {
  if (isForfait) return v.B - v.B5 - v.B6 - v.B7;

  return v.B1 + v.B2 + v.B3 + v.B4;
};

const getAProd = (v) => {
  return v.AProd || v.A1 + v.A2 + v.A3;
};

const getAEdif = (v) => {
  return v.AEdif || v.A4 + v.A5;
};

const getB = (v) => {
  return v.B1 + v.B2 + v.B3 + v.B4 + v.B5 + v.B6 + v.B7 || v.B || v.BMaint + v.BOp;
};

const getC = (v) => {
  let c = v.C || v.C1 + v.C2 + v.C3 + v.C4;

  return c + v.D;
};

const getTOTAL = (v) => {
  return getAProd(v) + getAEdif(v) + getB(v) + getC(v) || v.TOTAL;
};

export default simulatorMiddleware;
