import './barGraphic.scss';
import { ResponsiveBar } from '@nivo/bar';
import { StateDTO } from '../../../models/GeneralDTO';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import GraphicHeader from '../GraphicHeader/GraphicHeader';
import React from 'react';
import formatNumber from '../../../services/mathematics/formatNumber';

const BarGraphic = ({ onSubmit, onSubmitError, handleSubmit, getValues }) => {
  // const barData = [
  //   {
  //     "year": "2022",
  //     "Variante 1": 147,
  //     "Variante 2": 144,
  //   },
  //   {
  //     "year": "2027",
  //     "Variante 1": 150,
  //     "Variante 2": 149,
  //   },
  // ];

  const { dataGraph, keys } = useSelector(
    (state: StateDTO) => state.simulator.dataForGraph
  );
  const { loadingGraph, information } = useSelector(
    (state: StateDTO) => state.simulator
  );

  return (
    <div className="barGraphicWrapper">
      {loadingGraph && (
        <div className="graphic-spinner-wrapper">
          <div className="donut" />
        </div>
      )}
      {!loadingGraph && (
        <>
          <GraphicHeader
            onSubmit={onSubmit}
            onSubmitError={onSubmitError}
            handleSubmit={handleSubmit}
            getValues={getValues}
          />
          <motion.div
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.5 }}
            transition={{
              // delay: 0.3,
              // x: { duration: 1 },
              opacity: { duration: 0.2 },
              default: { ease: 'linear' },
            }}
          >
            <div className="graphicContainer">
              <ResponsiveBar
                // width={window.screen.availHeight < 750 ? windowWidth - 500 : windowWidth -650}
                // height={window.screen.availHeight < 750 ? window.screen.availHeight - 350 : window.screen.availHeight - 400}
                layout="horizontal"
                // key={keys || []}
                margin={{ top: 50, right: 50, bottom: 50, left: 180 }}
                // margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
                // margin={{ top: 0, right: 0, bottom: 0, left: 0 }}

                data={dataGraph?.sort((a, b) => a.total - b.total) || []}
                indexBy={'name'}
                keys={keys || []}
                enableLabel={false}
                tooltip={({ id, value, color, label }) => {
                  const labelWithoutVariant = label
                    .replace('Variante 1 -', '')
                    .replace('Variante 2 -', '');

                  return (
                    <div
                      style={{
                        border: '1px solid #ccc',
                        padding: '9px 12px',
                        // alignItems: 'center',
                        justifyContent: 'center',
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div style={{ fontWeight: 'bold' }}>{labelWithoutVariant}</div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <div
                          style={{
                            width: '12px',
                            height: '12px',
                            backgroundColor: color,
                            paddingRight: '12px',
                            marginRight: '12px',
                          }}
                        ></div>
                        <div>
                          {`${id} : `} &nbsp;{' '}
                          <strong style={{ color: 'black' }}>
                            {' '}
                            {` ${formatNumber(value)}`}
                          </strong>{' '}
                          {` ${
                            information.refUnit === 'total'
                              ? 'kg éq. CO₂'
                              : 'kg éq. CO₂/m²'
                          }`}
                        </div>
                      </div>
                    </div>
                  );
                }}
                colors={['hsl(168, 25%, 70%)', 'rgb(70, 221, 196)']}
                borderColor={{ from: 'color', modifiers: [['darker', 2.6]] }}
                enableGridY={false}
                enableGridX={true}
                groupMode="grouped"
                axisTop={{
                  format: '~s',
                }}
                axisBottom={{
                  format: '~s',
                }}
                axisLeft={{
                  tickSize: 1,
                  tickPadding: 10,
                  tickRotation: 0,
                  legend: '',
                  format: (v) => {
                    return v.length > 27 ? (
                      <tspan>
                        {v.substring(0, 27) + '...'}
                        <title>{v}</title>
                      </tspan>
                    ) : (
                      v
                    );
                  },
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                padding={0.3}
                // legends={[
                //   {
                //     dataFrom: 'keys',
                //     anchor: 'bottom-right',
                //     direction: 'column',
                //     justify: false,
                //     translateX: 120,
                //     translateY: 0,
                //     itemsSpacing: 2,
                //     itemWidth: 100,
                //     itemHeight: 20,
                //     itemDirection: 'left-to-right',
                //     itemOpacity: 0.85,
                //     symbolSize: 20,
                //     effects: [
                //       {
                //         on: 'hover',
                //         style: {
                //           itemOpacity: 1
                //         }
                //       }
                //     ]
                //   }
                // ]}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.4]] }}
                isInteractive
                // barComponent={(props) => BarComponent(props, currentIndicator)}
                motionStiffness={170}
                motionDamping={26}

                // data={barData}
                // keys={[
                //   'Variante 1',
                //   'Variante 2',
                // ]}
                // indexBy="year"
                // margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
                // groupMode="grouped"
                // valueScale={{ type: 'linear' }}
                // indexScale={{ type: 'band', round: true }}
                // colors={["hsl(168, 25%, 70%)", "rgb(70, 221, 196)"]}
                // minValue={0}
                // maxValue={170}

                // borderColor={{
                //   from: 'color',
                //   modifiers: [
                //     [
                //       'darker',
                //       '1.2'
                //     ]
                //   ]
                // }}
                // theme={{
                //   axis: {
                //     domain: {
                //       line: {
                //         "stroke": "black",
                //         "strokeWidth": 2
                //       }
                //     },
                //   }}}
                // axisTop={null}
                // axisRight={null}
                // axisBottom={{
                //   tickSize: 5,
                //   tickPadding: 5,
                //   tickRotation: 0,
                // }}
                // padding={0.4}
                // axisLeft={{
                //   tickSize: 0,
                //   tickPadding: 5,
                //   tickRotation: 0,

                // }}
                // enableLabel={false}
                // labelSkipWidth={12}
                // labelSkipHeight={12}
                // labelTextColor={{
                //   from: 'color',
                //   modifiers: [
                //     [
                //       'darker',
                //       1.6
                //     ]
                //   ]
                // }}

                // role="application"
                // ariaLabel="Nivo bar chart demo"
                // barAriaLabel={function(e){

                //   return e.id+": "+e.formattedValue+" in country: "+e.indexValue;

                // }}
              />
            </div>
          </motion.div>
        </>
      )}
    </div>
  );
};

export default BarGraphic;
