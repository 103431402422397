import {
  CREATE_NEW_FEATURE,
  CREATE_NEW_RELEASE,
  DELETE_FEATURE,
  DELETE_RELEASE,
  FETCH_FEATURE,
  FETCH_FEATURES,
  FETCH_RELEASE,
  FETCH_RELEASES,
  PATCH_FEATURE,
  PATCH_RELEASE,
  featureCloseModal,
  featureUpdate,
  releaseCancel,
  releaseCreateSucces,
  releaseUpdate,
  releasesUpdate,
  updateHelpNewReleaseNotification,
} from '../actions/helps';
import API from '../app/services/API';
//TODO Necessaire?
// import _ from 'lodash';

const api = new API();

const helpMiddleware = (store) => (next) => async (action) => {
  const { help } = store.getState();
  const { feature, release } = help;
  const { newRelease, editRelease, editNumber, releases, selectedRelease } = release;
  const { newFeature, features, selectedFeature, selectedFeatureIndex } = feature;

  // console.log(releases);

  switch (action.type) {
    case FETCH_RELEASES:
      if (!releases) {
        api
          .fetchReleases()
          .then((res) => {
            // console.log(releases);

            store.dispatch(
              releasesUpdate('release', { ...release, releases: res.data.rows })
            );
            // console.log(releases);

            store.dispatch(
              updateHelpNewReleaseNotification('lastReleaseId', res.data.rows[0].id)
            );
            // console.log(releases);
            // store.dispatch(releaseUpdate('loadingRelease', false));

            // console.log(res.data.rows);

            // localStorage.setItem('lastReleaseIdLocal', res.data.rows[0].id);

            // console.log('lasReleaseId', res.data.rows[0].id);
            // console.log('lastReleaseIdLocal', localStorage.getItem('lastReleaseIdLocal'));

            if (res.data.rows[0].id != localStorage.getItem('lastReleaseIdLocal')) {
              // console.log('fetchlastRelease');

              api
                .fetchRelease(res.data.rows[0].id)
                .then((response) => {
                  // console.log(response.data);
                  // console.log(res.data);
                  res.data.rows[0].bodyId = response.data.bodyId;
                  res.data.rows[0].articleBody = response.data.articleBody;

                  // console.log(releases[action.index]);
                  // res.data.deliveryDate = new Date(res.data.deliveryDate);

                  // releases[action.index] = {...releases[action.index], ...res.data};
                  // console.log(releases);
                  // const releasesUpdate = [...releases]
                  store.dispatch(releaseUpdate('loadingRelease', false));
                  store.dispatch(releaseUpdate('releases', res.data.rows)); // console.log('fetch release response');
                  // console.log('fetch release response');
                })
                .catch((error) => {
                  console.log('error :', error);
                });
            }
          })
          .catch((error) => {
            console.log('error :', error);
          });
      } else {
        store.dispatch(releaseUpdate('loadingRelease', false));
      }

      next(action);
      break;

    case FETCH_RELEASE:
      // console.log(releases);
      // console.log('fetch release middleware');

      if (releases[action.index].articleBody) {
        releases[action.index].deliveryDate = new Date(
          releases[action.index].deliveryDate
        );

        store.dispatch(
          releasesUpdate('release', {
            ...release,
            selectedRelease: releases[action.index],
            loadingRelease: false,
            accordionPanel: action.activeName,
            // releases:[action.index: {articleBody: 'test'}]
          })
        );
      } else {
        api
          .fetchRelease(action.id)
          .then((res) => {
            console.log(res);
            console.log(releases[action.index]);
            // res.data.deliveryDate = new Date(res.data.deliveryDate);
            releases[action.index] = { ...releases[action.index], ...res.data };
            console.log(releases);
            // const releasesUpdate = [...releases]
            store.dispatch(
              releasesUpdate('release', {
                ...release,
                selectedRelease: res.data,
                loadingRelease: false,
                accordionPanel: action.activeName,
                // releases:[action.index: {articleBody: 'test'}]
              })
            );
            console.log('fetch release response');
          })
          .catch((error) => {
            console.log('error :', error);
          });
      }

      next(action);
      break;

    case CREATE_NEW_RELEASE:
      api
        .postRelease(newRelease)
        .then((res) => {
          store.dispatch(releaseCreateSucces(true, 'Note de version crée!'));
          store.dispatch(releaseCancel());

          store.dispatch(
            releasesUpdate('release', {
              ...release,
              releases: [res.data, ...release.releases],
              isOpenNew: false,
              loadingRelease: false,
            })
          );
          // store.dispatch(releasesUpdate('release', {...release, isOpenEdit: false}));
        })
        .catch((error) => {
          console.log('error :', error);
        });

      next(action);
      break;
    case FETCH_FEATURES:
      api
        .fetchFeatures()
        .then((res) => {
          // console.log(res.data.rows);
          store.dispatch(featureUpdate('loadingFeatures', false));
          store.dispatch(featureUpdate('features', res.data.rows));
        })
        .catch((error) => {
          console.log('error :', error);
        });

      next(action);
      break;

    case PATCH_RELEASE: {
      // console.log(editRelease);

      const {
        id,
        title,
        deliveryDate,
        isVisible,
        featureCount,
        newEquipmentCount,
        newMaterialCount,
        articleBody,
      } = editRelease;

      const [day, month, year] = deliveryDate.split('/');

      const dateFormat = new Date(year, month - 1, day);

      const editReleaseWithoutId = {
        title: title,
        deliveryDate: dateFormat,
        isVisible: isVisible,
        featureCount: featureCount,
        newEquipmentCount: newEquipmentCount,
        newMaterialCount: newMaterialCount,
        articleBody: articleBody,
      };

      api
        .patchRelease(id, editReleaseWithoutId)
        .then((res) => {
          editRelease.deliveryDate = dateFormat;

          // releases with update
          releases[editNumber] = editRelease;
          releases[editNumber].deliveryDate = dateFormat;

          store.dispatch(
            releasesUpdate('release', {
              ...release,
              loadingRelease: false,
              // selectedRelease: {},
              selectedReleaseArticleBody: '',
              accordionPanel: false,
              releases: [...releases],
              isDateValid: false,
              isTitleValidate: false,
              isFeatureValidate: false,
              isAddEquipentsValidate: false,
              isAddMaterialsValidate: false,
              isEdit: false,
              editNumber: '',
              editReleaseId: '',
              editArticleBody: '',
              // editRelease: {
              //   title: "",
              //   deliveryDate: "",
              //   featureCount: "",
              //   newEquipmentCount: "",
              //   newMaterialCount: "",
              //   articleBody: "",
              //   isVisible: true,
              // }
            })
          );
          store.dispatch(releaseCreateSucces(true, 'Note de version mise à jour'));
        })
        .catch((error) => {
          console.log('error :', error);
        });

      next(action);
      break;
    }

    case PATCH_FEATURE: {
      console.log(selectedFeature);

      api
        .patchFeature(selectedFeature)
        .then((res) => {
          console.log(res);
          features[selectedFeatureIndex] = selectedFeature;

          store.dispatch(featureUpdate('loading', false));
          store.dispatch(releaseCreateSucces(true, 'Fonctionnalité mise à jour'));
          // TODO NOT NECESSARY?
          // store.dispatch(featureUpdate('features', [selectedFeature, ...features]));
          store.dispatch(featureCloseModal());
        })
        .catch((error) => {
          console.log('error :', error);
        });

      next(action);
      break;
    }

    case DELETE_FEATURE: {
      // console.log(selectedFeature);
      // console.log(action.id);

      api
        .deleteFeature(action.id)
        .then((res) => {
          // console.log(res);
          // console.log(features);

          const featuresUpdate = features.filter(
            (feature) => feature.id !== action.id
          );

          // console.log(featuresUpdate);
          // features[selectedFeatureIndex] = selectedFeature;
          store.dispatch(featureUpdate('features', featuresUpdate));
          // store.dispatch(featureUpdate('loading', false));
          // store.dispatch(releaseCreateSucces(true, 'Fonctionnalité mise à jour'));
          // TODO NOT NECESSARY?
          // store.dispatch(featureUpdate('features', [selectedFeature, ...features]));
          // store.dispatch(featureCloseModal());
        })
        .catch((error) => {
          console.log('error :', error);
        });

      next(action);
      break;
    }

    case DELETE_RELEASE: {
      console.log(selectedRelease);
      console.log(action.id);

      api
        .deleteRelease(action.id)
        .then((res) => {
          console.log(res);
          console.log(releases);

          const releasesUpdate = releases.filter(
            (release) => release.id !== action.id
          );

          store.dispatch(releaseUpdate('releases', releasesUpdate));
        })
        .catch((error) => {
          console.log('error :', error);
        });

      next(action);
      break;
    }

    case CREATE_NEW_FEATURE: {
      // console.log('test');
      // console.log(newFeature);
      newFeature.articleBody = action.articleBody;
      console.log(newFeature);
      api
        .postFeature(newFeature)
        .then((res) => {
          console.log(res);
          console.log(res.data.id);
          store.dispatch(featureUpdate('loading', false));
          // store.dispatch(releaseCreateSucces(true, 'Nouvelle fonctionnalité crée!'));
          // store.dispatch(featureCloseModal());
          newFeature.id = res.data.id;
          store.dispatch(releaseCreateSucces(true, 'Fonctionnalité crée!'));

          store.dispatch(featureUpdate('features', [newFeature, ...features]));
          store.dispatch(featureCloseModal());
          console.log('passage ici');
          // store.dispatch(releasesUpdate('release', {...release, releases: [res.data, ...release.releases], isOpenNew: false, loadingRelease: false }));
          // store.dispatch(releasesUpdate('release', {...release, releases: [res.data, ...release.releases], isOpenNew: false, loadingRelease: false }));
          // store.dispatch(releasesUpdate('release', {...release, isOpenEdit: false}));
        })
        .catch((error) => {
          console.log('error :', error);
          // store.dispatch(releaseCreateSucces(true, 'Echec Création Nouvelle fonctionnalité'));
        });

      next(action);
      break;
    }

    case FETCH_FEATURE:
      console.log('fetch feature middleware');
      console.log(selectedFeature);
      console.log(action.id);
      console.log(action.index);

      if (features[action.index].articleBody) {
        // store.dispatch(releasesUpdate(
        //   'release',
        //   {...release,
        //     selectedRelease: releases[action.index],
        //     loadingRelease: false,
        //     accordionPanel: action.activeName,
        //     // releases:[action.index: {articleBody: 'test'}]
        //   }));
        console.log('déja downloadé');
        store.dispatch(featureUpdate('loadingFeature', false));
      } else {
        api
          .fetchFeature(action.id)
          .then((res) => {
            console.log(res);
            console.log(features[action.index]);
            features[action.index] = { ...features[action.index], ...res.data };
            selectedFeature.articleBody = res.data.articleBody;
            store.dispatch(featureUpdate('loadingFeature', false));
            console.log('fetch feature response');
            // console.log(releases);
            // const releasesUpdate = [...releases]
          })
          .catch((error) => {
            console.log('error :', error);
          });
      }

      next(action);
      break;

    default:
      next(action);
  }
};

export default helpMiddleware;
