import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

const ImportAbandonConfirm = ({ open, onConfirm, onCancel }) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm();
  };

  const handleClose = () => {
    onCancel();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {t('APP.IMPORTABANDONCONFIRM.IMPORTABANDONCONFIRM.PH_6A36A7', {
            defaultValue: 'Confirmer',
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('APP.IMPORTABANDONCONFIRM.IMPORTABANDONCONFIRM.PH_9D993A', {
              defaultValue:
                'Êtes-vous sûr(e) de vouloir abandonner ce fichier d’import ?',
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary">
            {t('APP.IMPORTABANDONCONFIRM.IMPORTABANDONCONFIRM.PH_662253', {
              defaultValue: 'Abandonner',
            })}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t('APP.IMPORTABANDONCONFIRM.IMPORTABANDONCONFIRM.PH_C24152', {
              defaultValue: 'Annuler',
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImportAbandonConfirm;
