import './simulatorGraphic.scss';
import { AnimatePresence } from 'framer-motion';
import { StateDTO } from '../../models/GeneralDTO';
import { Tooltip } from '@material-ui/core';
import {
  patchQuickSimulatorInformation,
  updateSimulator,
} from '../../../actions/simulator';
import { useDispatch, useSelector } from 'react-redux';
import BarGraphic from './BarGraphic/BarGraphic';
import LineGraphic from './LineGraphic/LineGraphic';
import RadarGraphic from './RadarGraphic/RadarGraphic';

import React from 'react';
import { useTranslation } from 'react-i18next';

const barChartIcon = require('../../../assets/images/simulator/barChartIcon.png');
const radarChartIcon = require('../../../assets/images/simulator/radarChartIcon.png');
const lineChartIcon = require('../../../assets/images/simulator/lineChartIcon.png');

const SimulatorGraphic = ({ isValid, errors, handleSubmit, getValues }) => {
  const { graphicChoice, loadingGraph } = useSelector(
    (state: StateDTO) => state.simulator
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSubmit = (data, graphicType) => {
    dispatch(updateSimulator('graphicChoice', graphicType));
    dispatch(patchQuickSimulatorInformation(data));
  };

  const onSubmitError = (data, graphType) => {
    dispatch(updateSimulator('graphicChoice', undefined));
  };

  return (
    <div className="simulatorGraphicWrapper">
      {!graphicChoice && (
        <AnimatePresence>
          {!loadingGraph && (
            <>
              <div className="graphicChoice">
                <div className="graphicChoice__title">
                  {t('APP.SIMULATORGRAPHIC.SIMULATORGRAPHIC.PH_B3A645', {
                    defaultValue:
                      'Cliquer sur le format souhaité pour simuler une synthèse',
                  })}
                </div>
                <div className="graphicChoice__icon">
                  <Tooltip
                    title={t('APP.SIMULATORGRAPHIC.SIMULATORGRAPHIC.PH_4714F9', {
                      defaultValue:
                        'Comparer les valeurs des impacts des variantes en vue radar sur la durée de vie du système',
                    })}
                  >
                    <img
                      src={radarChartIcon}
                      alt=""
                      onClick={() => {
                        handleSubmit(onSubmit, onSubmitError)('radar');
                      }}
                    />
                  </Tooltip>
                  <Tooltip
                    title={t('APP.SIMULATORGRAPHIC.SIMULATORGRAPHIC.PH_881E2E', {
                      defaultValue:
                        'Comparer les valeurs des impacts des variantes en vue histogramme sur la durée de vie du système',
                    })}
                  >
                    <img
                      src={barChartIcon}
                      alt=""
                      onClick={() => {
                        handleSubmit(onSubmit, onSubmitError)('bar');
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Comparer les évolutions dans le temps des impacts des variantes sur la durée de vie du système">
                    <img
                      src={lineChartIcon}
                      alt=""
                      onClick={() => {
                        handleSubmit(onSubmit, onSubmitError)('line');
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            </>
          )}
        </AnimatePresence>
      )}
      {graphicChoice === 'line' && (
        <AnimatePresence>
          <LineGraphic
            onSubmit={onSubmit}
            onSubmitError={onSubmitError}
            handleSubmit={handleSubmit}
            getValues={getValues}
          />
        </AnimatePresence>
      )}
      {graphicChoice === 'bar' && (
        <AnimatePresence>
          <BarGraphic
            onSubmit={onSubmit}
            onSubmitError={onSubmitError}
            handleSubmit={handleSubmit}
            getValues={getValues}
          />
        </AnimatePresence>
      )}
      {graphicChoice === 'radar' && (
        <AnimatePresence>
          <RadarGraphic
            onSubmit={onSubmit}
            onSubmitError={onSubmitError}
            handleSubmit={handleSubmit}
            getValues={getValues}
          />
        </AnimatePresence>
      )}
    </div>
  );
};

export default SimulatorGraphic;
