import { BundleDTO } from '../../../app/models/BundleDTO';
import { StateDTO } from '../../../app/models/GeneralDTO';
import {
  deleteBundle,
  getBundleListByFolder,
  patchBundle,
  postBundle,
  postBundleInProject,
  postBundleInProjectProps,
} from '../../../apiCallFunctions/bundle';
import { updateBundleValue } from '../../../actions/bundle';
import { updateFormValue } from '../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { TYPE_FICHE } from '../../../app/globalVariable/generalDefinitions';

const returnKeyDepandingOnState = (
  queryClient,
  folderSheetId,
  companyId,
  bundleSearchInput
) => {
  const folderQueryKey = ['getBundleListByFolder', companyId, folderSheetId];
  const searchQueryKey = ['getBundleListBySearch', folderSheetId, bundleSearchInput];
  const searchData = queryClient.getQueryData(searchQueryKey);

  return searchData ? searchQueryKey : folderQueryKey;
};

export const handleUpdateData = (oldData, newObjectData) => {
  const oldPages = oldData.pages;
  const newPages = oldPages.map((page) => {
    const newPageData = page.map((obj) => {
      if (obj.id === newObjectData.oldId) {
        if (!newObjectData.User) {
          newObjectData = { ...obj, ...newObjectData };
        }

        return newObjectData;
      } else {
        return obj;
      }
    });

    return newPageData;
  });

  return {
    pages: newPages,
    pageParams: [...oldData.pageParams],
  };
};

export const handleAddData = (oldData, newObjectData) => {
  const oldPages = oldData.pages;
  const newPages = [...oldPages];

  if (newPages.length > 0) {
    const firstPageData = newPages[0];

    if (newObjectData.type_fiche !== 'FC') {
      newPages[0] = [newObjectData, ...firstPageData];
    } else if (newObjectData.type_fiche === 'FC') {
      const formattedNewObjectData = {
        ...newObjectData,
        name: newObjectData.fileName,
      };

      newPages[0] = [formattedNewObjectData, ...firstPageData];
    }
  }

  return {
    pages: newPages,
    pageParams: [...oldData.pageParams],
  };
};

export const handleDeleteData = (oldData, bundleIdToDelete) => {
  const oldPages = oldData.pages;
  const newPages = oldPages.map((page) => {
    const newPageData = page.filter((obj) => obj.id !== Number(bundleIdToDelete));

    return newPageData;
  });

  return {
    pages: newPages,
    pageParams: [...oldData.pageParams],
  };
};

const updateDataFromFC = (item) => {
  if (item.type_fiche !== 'FC') {
    if (item[0]) return item[0];
    return item;
  }

  const newItem = { ...item };

  newItem.fileName = item.name;
  newItem.name = item?.ProjectSheet[0]?.originalName || '';

  return newItem;
};

export const useGetBundleByFolder = (
  companyId: number | null,
  folderSheetId: number | null
) => {
  return useInfiniteQuery(
    ['getBundleListByFolder', companyId, folderSheetId],
    (data) => getBundleListByFolder(companyId, folderSheetId, data, null),
    {
      enabled: folderSheetId || companyId ? true : false,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) => {
        const pageNumber = Math.ceil(lastPage.length / 20);

        if (lastPage.length < 20) return undefined;

        if (pageNumber === 1) {
          return pages.length;
        } else {
          return undefined;
        }
      },
      select: (data) => {
        return {
          pages: data.pages.map((page) =>
            page.map((item) => updateDataFromFC(item))
          ),
          pageParams: data.pageParams,
        };
      },
    }
  );
};

export const useGetBundleBySearch = (companyId, folderSheetId, searchInput) => {
  const isEnabled = searchInput !== '';

  return useInfiniteQuery(
    ['getBundleListBySearch', folderSheetId, searchInput],
    (data) => getBundleListByFolder(companyId, folderSheetId, data, searchInput),
    {
      enabled: isEnabled,
      refetchOnWindowFocus: false,
      onError: (error) => {
        console.log('error in getProjects', error?.message);
      },
      getNextPageParam: (lastPage, pages) => {
        const pageNumber = Math.ceil(lastPage.length / 20);

        if (lastPage.length < 20) return undefined;

        if (pageNumber === 1) {
          return pages.length;
        } else {
          return undefined;
        }
      },
      select: (data) => {
        return {
          pages: data.pages.map((page) =>
            page.map((item) => updateDataFromFC(item))
          ),
          pageParams: data.pageParams,
        };
      },
    }
  );
};

export const useCreateBundle = () => {
  const queryClient = useQueryClient();
  const { companyId, folderSheetId } = useSelector((state) => state.bundle);
  const { bundleOptions, modeledProductOptions, configuredSheetOptions } =
    useSelector((state: StateDTO) => state.projects);

  const dispatch = useDispatch();
  const mutation = useMutation((data: BundleDTO) => postBundle(data), {
    onSuccess: (data) => {
      const dataUpdated = { ...data };
      if (data.type_fiche === 'FC') {
        dataUpdated.fileName = data.name;
        dataUpdated.name = data?.ProjectSheet[0]?.originalName || '';
      }

      dispatch(updateBundleValue('newBundle', null));
      if (data.type_fiche === 'bundle') {
        dispatch(updateFormValue('bundleOptions', [...bundleOptions, data]));
      } else if (data.type_fiche === 'custom_user') {
        dispatch(
          updateFormValue('modeledProductOptions', [...modeledProductOptions, data])
        );
      } else if (data.type_fiche === 'FC') {
        dispatch(
          updateFormValue('configuredSheetOptions', [
            ...configuredSheetOptions,
            dataUpdated,
          ])
        );
      }
      queryClient.setQueryData(
        ['getBundleListByFolder', companyId, folderSheetId],
        (oldData) => handleAddData(oldData, dataUpdated[0] ?? dataUpdated)
      );
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  return mutation;
};

export type usePostBundleInProjectProps = {
  quantity: number;
  SheetId: number;
  type_fiche: 'bundle';
  comment?: string;
  ZoneId: number;
  customName?: string;
};
export const usePostBundleInProject = () => {
  const currentProjectId = useSelector(
    (state: StateDTO) => state.projects.currentProject
  ).id;

  return useMutation((body: usePostBundleInProjectProps) =>
    postBundleInProject({ body, currentProjectId })
  );
};

export const useDeleteBundle = () => {
  const queryClient = useQueryClient();
  const { companyId, folderSheetId, bundleSearchInput } = useSelector(
    (state) => state.bundle
  );
  const dispatch = useDispatch();

  const activeKey = returnKeyDepandingOnState(
    queryClient,
    folderSheetId,
    companyId,
    bundleSearchInput
  );

  const mutation = useMutation((data: BundleDTO) => deleteBundle(data), {
    onSuccess: (data) => {
      queryClient.setQueryData(activeKey, (oldData) =>
        handleDeleteData(oldData, data)
      );
      dispatch(updateBundleValue('openDeleteBundleModal', false));
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  return mutation;
};

const replaceOldIdWithNew = (
  data,
  bundleOptions,
  modeledProductOptions,
  configuredSheetOptions
) => {
  const keyToPatchInStore =
    data.type_fiche === TYPE_FICHE.FICHES_CONFIG
      ? 'configuredSheetOptions'
      : data.type_fiche === 'bundle'
      ? 'bundleOptions'
      : 'modeledProductOptions';
  let valueToStore =
    data.type_fiche === TYPE_FICHE.FICHES_CONFIG
      ? configuredSheetOptions
      : data.type_fiche === 'bundle'
      ? bundleOptions
      : modeledProductOptions;

  if (keyToPatchInStore === 'configuredSheetOptions') {
    data.title = data.ProjectSheet[0].originalName;
    data.name = data.ProjectSheet[0].originalName;
  }

  valueToStore = valueToStore.map((sheet) =>
    sheet.id === data.oldId ? data : sheet
  );
  return { keyToPatchInStore, valueToStore };
};

export const usePatchBundle = () => {
  const dispatch = useDispatch();
  const { editBundle, companyId, folderSheetId, bundleSearchInput } = useSelector(
    (state) => state.bundle
  );

  const { bundleOptions, modeledProductOptions, configuredSheetOptions } =
    useSelector((state: StateDTO) => state.projects);

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: BundleDTO) => patchBundle(data, folderSheetId),
    {
      onSuccess: (data) => {
        dispatch(
          updateBundleValue(
            'editBundle',
            editBundle.filter((bundle) => bundle.id !== Number(data.id))
          )
        );

        const activeKey = returnKeyDepandingOnState(
          queryClient,
          folderSheetId,
          companyId,
          bundleSearchInput
        );

        if (data.hasBeenMoved) {
          queryClient.setQueryData(activeKey, (oldData) =>
            handleDeleteData(oldData, data.id)
          );
        } else {
          queryClient.setQueryData(activeKey, (oldData) =>
            handleUpdateData(
              oldData,
              data.bundle
                ? { ...data.bundle, oldId: Number(data.id) }
                : { ...data, oldId: Number(data.id) }
            )
          );
        }

        const { keyToPatchInStore, valueToStore } = replaceOldIdWithNew(
          data.bundle
            ? { ...data.bundle, oldId: Number(data.id) }
            : { ...data, oldId: Number(data.id) },
          bundleOptions,
          modeledProductOptions,
          configuredSheetOptions
        );
        dispatch(updateFormValue(keyToPatchInStore, valueToStore));
      },
      onError: (error) => {
        console.log('error', error);
      },
    }
  );

  return mutation;
};
