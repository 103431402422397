import Tooltip from '@material-ui/core/Tooltip';

import React, { ComponentType } from 'react';

type WithConditionalTooltipType = (
  WrappedComponent: ComponentType,
  tooltipText: string
) => React.FC;
const WithConditionalTooltip: WithConditionalTooltipType = (
  WrappedComponent,
  tooltipText
) => {
  return function (props) {
    const shouldDisplayTooltip = tooltipText && tooltipText.length > 0;
    const content = <WrappedComponent {...props} />;

    return shouldDisplayTooltip ? (
      <Tooltip title={tooltipText}>
        <div>{content}</div>
      </Tooltip>
    ) : (
      content
    );
  };
};

export default WithConditionalTooltip;
