/* eslint-disable no-undef */
import { IndicatorDTO } from '../../../../models/ProjectDTO';
import { ProjectIcDTO } from './WidgetsDTO';
import { StateDTO } from '../../../models/GeneralDTO';
import { TEMPLATE_TYPE_NAME } from '../../../globalVariable/typeProject';
import { formatNumberP } from './ConstructWidgets';
import { useSelector } from 'react-redux';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import { ProjectDTO } from '../../../models/ProjectDTO';
import Info from '@material-ui/icons/Info';
import { IcMaxBbcaTooltip } from './widgetElements/WidgetsElements';

type WaterWidgetsProps = {
  projectIc: ProjectIcDTO;
  //   zones: zonesDTO[];
  currentIndicator: IndicatorDTO;
  openedPopooverId: string;
  seuilsOk: boolean;
  handlePopoverOpen: Function;
  handlePopoverClose: Function;
  renderPopoverBody: Function;
  currentProject: ProjectDTO;
};

const water = require('../../../../assets/images/synthese/Image6.png');

const WaterWidgets = ({
  currentIndicator,
  projectIc,
  currentProject,
}: WaterWidgetsProps): JSX.Element => {
  const { t } = useTranslation();
  let refUnitModeTotal = currentProject.refUnit === 'total';

  const icToDisplay =
    currentProject.templateType === TEMPLATE_TYPE_NAME.BBCA
      ? projectIc.Bbca.EgesEau
      : projectIc.IcEau;
  const icToDisplayMax =
    currentProject.templateType === TEMPLATE_TYPE_NAME.BBCA
      ? projectIc.Bbca.Max.EgesEauMax
      : projectIc.IcConstruction;

  return (
    <div className="report-water container-resizable-ok">
      <div className="report-water-header">
        <h3 className="dashboard-title">
          {t('APP.WIDGETS.WATERWIDGETS.WATER', { defaultValue: 'Eau' })}
        </h3>{' '}
        <Tooltip
          title={
            currentProject.templateType.includes(TEMPLATE_TYPE_NAME.EPCM)
              ? t('APP.WIDGETS.WATERWIDGETS.PH_3C643C', {
                  defaultValue: 'Impact de l’eau',
                })
              : t('APP.WIDGETS.WATERWIDGETS.PH_CAAEF3', {
                  defaultValue: 'Impact carbone eau : IcEau',
                })
          }
        >
          <img
            style={{
              width: window.screen.availHeight < 950 ? '40px' : undefined,
              height: window.screen.availHeight < 950 ? '40px' : undefined,
            }}
            src={water}
            alt=""
          />
        </Tooltip>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {formatNumberP(icToDisplay, false, currentProject, currentIndicator)}
          {[TEMPLATE_TYPE_NAME.BBCA].includes(currentProject.templateType) && (
            <IcMaxBbcaTooltip
              icKey={'EgesEau'}
              icToDisplayMax={icToDisplayMax}
              currentIndicator={currentIndicator}
              refUnitModeTotal={refUnitModeTotal}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WaterWidgets;
