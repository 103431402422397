import GeneralConception from './GeneralConception';
import GeneralInformation from './Generalnformation';
import Typology from './Typology';

function QuickSimulation() {
  return (
    <div
      style={{
        display: 'flex',
        columnGap: '20px',
      }}
    >
      <div>
        <GeneralInformation />
        <Typology />
      </div>
      <GeneralConception />
    </div>
  );
}

export default QuickSimulation;
