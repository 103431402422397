import './bundleList.scss';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { BundleDTO } from '../../models/BundleDTO';
import { StateDTO } from '../../models/GeneralDTO';
import { useDebounce } from '../../../hooks/utils/debounce';
import {
  useGetBundleByFolder,
  useGetBundleBySearch,
} from '../../../hooks/useQuery/bundle';
import { useIntersection } from 'react-use';
import { useSelector } from 'react-redux';
import BundleCard from './BundleCard';
import Loader from '@material-ui/core/CircularProgress';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const updateLineFromBundle = (
  currentBundle,
  currentBundleLine,
  keyToChange,
  value,
  subKey: null | string = null
) => {
  let newLine;

  if (subKey) {
    const updatedValue = { ...currentBundleLine[keyToChange], [subKey]: value };
    newLine = { ...currentBundleLine, [keyToChange]: updatedValue };
  } else {
    newLine = { ...currentBundleLine, [keyToChange]: value };
  }

  return {
    ...currentBundle,
    lines: currentBundle.lines.map((bd) =>
      bd.key === currentBundleLine.key ? newLine : bd
    ),
  };
};

type BundleListProps = {
  certificationBundleChoice: {
    Nooco: boolean;
    RE2020: boolean;
    EPCM: boolean;
  };
};

const BundleList = ({ certificationBundleChoice }: BundleListProps) => {
  const { t } = useTranslation();
  const { folderSheetId, newBundle, bundleSearchInput, companyId } = useSelector(
    (state: StateDTO) => state.bundle
  );
  const { projectsListMonitoring } = useSelector(
    (state: StateDTO) => state.projects
  );

  const [listBundle, setListBundle] = useState<BundleDTO[]>([]);
  const [listBundleBySearch, setListBundleBySearch] = useState<BundleDTO[]>([]);

  const debounceSearchInput = useDebounce(bundleSearchInput, 300);

  const {
    data: dataBySearch,
    isLoading: isLoadingBySearch,
    fetchNextPage: fetchNextPageBySearch,
    hasNextPage: hasNextPageBySearch,
  } = useGetBundleBySearch(companyId, folderSheetId, debounceSearchInput);

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useGetBundleByFolder(companyId, folderSheetId);

  const loaderRef = useRef(null);

  const intersection = useIntersection(loaderRef, {
    root: null,
    rootMargin: '600px',
  });
  const intersected = intersection && intersection.isIntersecting;

  useEffect(() => {
    if (intersected && hasNextPageBySearch) {
      fetchNextPageBySearch();
    }
    if (intersected && hasNextPage) {
      fetchNextPage();
    }
  }, [intersected]);

  useEffect(() => {
    let list: BundleDTO[] = [];

    if (dataBySearch && bundleSearchInput !== '') {
      if (newBundle && dataBySearch?.pages.flatMap((page) => page).length === 0) {
        list = [newBundle];
      } else if (newBundle) {
        list = [newBundle, ...dataBySearch?.pages.flatMap((page) => page)];
      } else {
        list = dataBySearch?.pages.flatMap((page) => page);
      }
    }

    setListBundleBySearch(list);
  }, [dataBySearch, newBundle]);

  useEffect(() => {
    let list: BundleDTO[] = [];

    const hasPages = data && data.pages.some((page) => page !== '');
    const pages =
      projectsListMonitoring.arianeCompany.selectedCompanyId && hasPages
        ? data.pages.flatMap((page) => page)
        : [];

    list = newBundle ? [newBundle, ...pages] : pages;

    setListBundle(list);
  }, [data, newBundle, projectsListMonitoring.arianeCompany.selectedCompanyId]);

  let listToMap =
    bundleSearchInput !== ''
      ? listBundleBySearch && listBundleBySearch.length > 0
        ? listBundleBySearch
        : []
      : listBundle;

  if (isLoadingBySearch || isLoading) {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Loader
          variant="indeterminate"
          disableShrink
          style={{ color: '#73b7ec' }}
          size={30}
          thickness={4}
        />
      </div>
    );
  }

  return (
    <div className="bundle-list" key={listBundle}>
      {listToMap.map((line, index) => (
        <BundleCard
          certificationBundleChoice={certificationBundleChoice}
          key={line.id || index}
          line={line}
        />
      ))}
      {listToMap.length === 0 &&
        projectsListMonitoring.arianeCompany.selectedCompanyId && (
          <div className="bundle-list__no-result">
            {t('APP.BUNDLE_LIST.NO_RESULT', { defaultValue: 'Aucun résultat' })}
          </div>
        )}
      {(isLoadingBySearch || isLoading) && (
        <div
          ref={loaderRef}
          style={{
            width: '100%',
            textAlign: 'center',
            height: isFetching || isLoadingBySearch || isLoading ? '100px' : '20px',
          }}
        >
          {isFetching ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Loader
                variant="indeterminate"
                disableShrink
                style={{ color: '#73b7ec' }}
                size={30}
                thickness={4}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
};

export default BundleList;
