import {
  ALLOTMENT_IDS,
  REFERENCE_TYPE_NAME,
  TYPE_BBCA,
  TYPE_EPCM,
  TYPE_GLOBAL_MONITORING,
  TYPE_NOOCO,
  TYPE_RE2020,
} from '../../../globalVariable/typeProject';
import { Checkbox, Popover, Select } from 'antd';
import { REFERENCE_SURFACE_TAG } from '../../../shared/utils';
import { Tooltip } from '@material-ui/core';
import { ZONE_TYPES } from '../../../globalVariable/generalDefinitions';
import { changeValueEquipments } from '../../../../actions/equipments';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import React, { useEffect, useState } from 'react';
import formatNumber from '../../../services/mathematics/formatNumber';
import { fetchCaracsByAllotmentNodeId } from '../../../../apiCallFunctions/equipment/equipment';
import {
  actionColumn,
  actionsColumn,
  arrowColumn,
  caracsColumn,
  categoryColumn,
  coeffColumn,
  commentColumn,
  customNameColumn,
  dataBaseColumn,
  dateColumn,
  destinationColumn,
  edificationColumn,
  endDateColumn,
  endImpactColumn,
  entretienColumn,
  functionColumn,
  impactByAreaColumn,
  impactByAreaRE2020Column,
  impactByUnitRe2020,
  impactRE2020Column,
  iniesColumn,
  lifeSpanColumn,
  lotCertifiedColumn,
  lotColumn,
  materialColumn,
  moduleDColumn,
  nameColumn,
  originalRowColumn,
  productionColumn,
  quantityColumn,
  re2020SheetColumn,
  sourceColumn,
  sousLotColumn,
  statusColumn,
  stockCColumn,
  totalAreaColumn,
  typeColumn,
  typeFicheColumn,
  unitColumn,
} from './allColumnsObject';
import { GroupByOptions } from '../EquipmentListFilter';

export const noticeThatUserEditInputs = (dispatch, isUserStartEditColumns) => {
  if (!isUserStartEditColumns) {
    dispatch(changeValueEquipments('isUserStartEditColumns', true));
  }
};

export const getColumnUnitString = (indicatorUnit, refUnit) => {
  return `(${indicatorUnit}${refUnit === 'm²' ? '/m²' : ' total'})`;
};

function calculateSum(record, keyName) {
  let sum = record[keyName] || 0;

  if (record.children) {
    for (let child of record.children) {
      sum += calculateSum(child, keyName);
    }
  }

  return sum;
}

export const multiLevelSorter = (a, b, keyName) => {
  const sumA = calculateSum(a, keyName);
  const sumB = calculateSum(b, keyName);

  return sumA - sumB;
};

const handleEditRow = (ProjectSheetsToPatch, row, key, value, initialId) => {
  let newRow = row ? { ...row, [key]: value } : { id: initialId, [key]: value };

  let projectSheetUpdated = ProjectSheetsToPatch.find((el) => el.id === initialId)
    ? ProjectSheetsToPatch.map((ps) => (ps.id === initialId ? newRow : ps))
    : [...ProjectSheetsToPatch, newRow];

  if (key === 'AllotmentNode') {
    fetchCaracsByAllotmentNodeId(value.id).then((caracs) => {
      console.log(caracs, '<==============');
    });
  }

  return projectSheetUpdated;
};

const sortChecks = (a, b, keyName) => {
  if (!a && !b) {
    return 0;
  } else if (!b) {
    return -1;
  } else if (!a) {
    return +1;
  }

  if (
    (a[keyName] === undefined || a[keyName] === null) &&
    (b[keyName] === undefined || b[keyName] === null)
  ) {
    return 0;
  } else if (b[keyName] === undefined || b[keyName] === null) {
    return -1;
  } else if (a[keyName] === undefined || a[keyName] === null) {
    return +1;
  }

  return null;
};

export const basicSorter = (a, b, keyName) => {
  const checks = sortChecks(a, b, keyName);

  if (checks !== null) {
    return checks;
  }

  if (a[keyName] < b[keyName]) {
    return -1;
  }

  if (a[keyName] > b[keyName]) {
    return 1;
  }

  return 0;
};

export const lotAndNameSorter = (a, b, keyName) => {
  const checks = sortChecks(a, b, keyName);

  if (checks !== null) {
    return checks;
  }

  if (!Number(a[keyName].slice(0, 3)) && Number(b[keyName].slice(0, 3))) {
    return 1;
  }

  if (Number(a[keyName].slice(0, 3)) && !Number(b[keyName].slice(0, 3))) {
    return -1;
  }

  if (!Number(a[keyName].slice(0, 3)) && !Number(b[keyName].slice(0, 3))) {
    if (a[keyName].toLowerCase() < b[keyName].toLowerCase()) {
      return -1;
    }

    if (a[keyName].toLowerCase() > b[keyName].toLowerCase()) {
      return 1;
    }

    return 0;
  }

  if (Number(a[keyName].slice(0, 3)) < Number(b[keyName].slice(0, 3))) {
    return -1;
  }

  if (Number(a[keyName].slice(0, 3)) > Number(b[keyName].slice(0, 3))) {
    return 1;
  }

  return 0;
};

export const getComplexFormatLineValue = (
  record,
  referenceSurfaceTag,
  columnKey
) => {
  const total = computeCellValue(record, referenceSurfaceTag, columnKey);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
      }}
    >
      <p
        style={{
          margin: 0,
          fontWeight: record.children ? 'bolder' : 'initial',
          fontSize:
            window.screen.availHeight > 600 && window.screen.availHeight <= 800
              ? '0.875rem'
              : undefined,
        }}
      >
        {total}
      </p>
    </div>
  );
};

export const getSimpleFormatLineValue = (record, referenceSurfaceTag, columnKey) => {
  const total = computeCellValue(record, referenceSurfaceTag, columnKey);

  return (
    <p style={{ fontWeight: record.children ? 'bolder' : 'initial' }}>{total}</p>
  );
};

const shouldGroupLineCellDataBeDisplayed = (record, referenceSurfaceTag) => {
  if (record.children) {
    if (
      referenceSurfaceTag === REFERENCE_SURFACE_TAG.project ||
      record.groupLevel === true
    ) {
      return true;
    } else if (referenceSurfaceTag === REFERENCE_SURFACE_TAG.building) {
      if (
        ![REFERENCE_SURFACE_TAG.building, REFERENCE_SURFACE_TAG.zone].includes(
          record.groupLevel
        )
      ) {
        return false;
      }
    } else {
      if (record.groupLevel === ZONE_TYPES.zone) {
        return true;
      }

      return false;
    }
  }

  return true;
};

const computeCellValue = (record, referenceSurfaceTag, columnKey) => {
  if (!shouldGroupLineCellDataBeDisplayed(record, referenceSurfaceTag)) {
    return null;
  }

  let cellTotal = 0;

  const sumValues = (record, columnKey) => {
    let total = 0;

    if (record.hasOwnProperty(columnKey)) {
      total += record[columnKey];
    }

    if (record.children) {
      record.children.forEach((child) => {
        total += sumValues(child, columnKey);
      });
    }

    return total;
  };

  try {
    cellTotal = sumValues(record, columnKey);
  } catch (error) {
    console.error('Error trying to compute group line value');
    console.error('Error is : ', error);
  }

  return formatNumber(cellTotal);
};

export const tooltipForEquipmentFromCalculator = ({ record, tableEditMode }) => {
  if (!tableEditMode) {
    return '';
  }

  if (record?.source === 'Calculette chantier') {
    return 'Suppression possible uniquement via la calculette chantier';
  }

  if (record?.source === 'Calculette eau') {
    return 'Suppression possible uniquement via la calculette eau';
  }

  return '';
};

const returnCertifiedImpactColumnDepandingOnRefUnit = (
  refUnit,
  t,
  getColumnSearchProps,
  listEquipementSortOrder,
  currentIndicator,
  referenceSurfaceTag,
  currentProject
) => {
  let column;

  if (refUnit === 'm²') {
    column = impactByAreaRE2020Column(
      t,
      getColumnSearchProps,
      listEquipementSortOrder,
      currentIndicator,
      referenceSurfaceTag,
      currentProject
    );
  } else {
    column = impactRE2020Column(
      t,
      getColumnSearchProps,
      listEquipementSortOrder,
      currentIndicator,
      referenceSurfaceTag,
      currentProject
    );
  }

  return column;
};

const returnNoocoImpactColumnDepandingOnRefUnit = (
  refUnit,
  t,
  getColumnSearchProps,
  listEquipementSortOrder,
  currentIndicator,
  referenceSurfaceTag
) => {
  let column;

  if (refUnit === 'm²') {
    column = impactByAreaColumn(
      t,
      getColumnSearchProps,
      listEquipementSortOrder,
      currentIndicator,
      referenceSurfaceTag
    );
  } else {
    column = totalAreaColumn(
      t,
      getColumnSearchProps,
      listEquipementSortOrder,
      referenceSurfaceTag,
      currentIndicator
    );
  }

  return column;
};

const baseSortColumns = (
  dispatch,
  listEquipementSortOrder,
  buildingSitesIds,
  modalLoadingDed,
  handleOpenModal,
  toLineProductGraph,
  getColumnSearchProps,
  currentProject,
  currentIndicator,
  tableEditMode,
  currentAllotment,
  allGroups,
  isUserStartEditColumns,
  referenceSurfaceTag,
  toggleCollapseAll,
  isAllCollapsed,
  t,
  searchBarOptions,
  bundleOptions,
  certifiedAllotments,
  nodesAvailableForProjectType,
  api,
  setValue,
  watch,
  getValues
) => {
  return [
    arrowColumn(t, getColumnSearchProps, toggleCollapseAll, isAllCollapsed),
    quantityColumn(
      t,
      getColumnSearchProps,
      tableEditMode,
      setValue,
      dispatch,
      isUserStartEditColumns,
      listEquipementSortOrder,
      currentProject,
      getValues
    ),
    unitColumn(buildingSitesIds, t, watch, allGroups.type, tableEditMode),
    typeFicheColumn(t, getColumnSearchProps, modalLoadingDed, handleOpenModal),
    nameColumn(
      t,
      getColumnSearchProps,
      tableEditMode,
      listEquipementSortOrder,
      bundleOptions,
      searchBarOptions,
      certifiedAllotments,
      allGroups,
      nodesAvailableForProjectType,
      api,
      currentAllotment,
      toLineProductGraph,
      setValue,
      dispatch,
      isUserStartEditColumns,
      getValues,
      currentProject
    ),
    currentAllotment.id === ALLOTMENT_IDS.NOOCO
      ? lotColumn(
          t,
          getColumnSearchProps,
          tableEditMode,
          setValue,
          dispatch,
          isUserStartEditColumns,
          listEquipementSortOrder,
          allGroups,
          watch,
          currentProject
        )
      : sousLotColumn(
          t,
          getColumnSearchProps,
          tableEditMode,
          setValue,
          dispatch,
          isUserStartEditColumns,
          listEquipementSortOrder,
          allGroups,
          currentAllotment,
          currentProject,
          watch
        ),
    caracsColumn(
      tableEditMode,
      getValues,
      setValue,
      dispatch,
      isUserStartEditColumns,
      currentProject,
      allGroups
    ),
    currentAllotment.id === ALLOTMENT_IDS.RE2020 ||
    currentAllotment.id === ALLOTMENT_IDS.EPCM
      ? re2020SheetColumn(t, listEquipementSortOrder, currentProject)
      : {
          className: 'wrapper-hide-column',
          width: 1,
          render: () => <div className="wrapper-hide-column" />,
        },
    lifeSpanColumn(t, getColumnSearchProps, listEquipementSortOrder),
    // impact
    (() => {
      let columnDefinition;

      if (currentAllotment.id === ALLOTMENT_IDS.NOOCO) {
        columnDefinition = returnNoocoImpactColumnDepandingOnRefUnit(
          currentProject.refUnit,
          t,
          getColumnSearchProps,
          listEquipementSortOrder,
          currentIndicator,
          referenceSurfaceTag
        );
      } else {
        columnDefinition = returnCertifiedImpactColumnDepandingOnRefUnit(
          currentProject.refUnit,
          t,
          getColumnSearchProps,
          listEquipementSortOrder,
          currentIndicator,
          referenceSurfaceTag,
          currentProject
        );
      }

      return columnDefinition;
    })(),
  ];
};

const importViewSortColumns = (
  dispatch,
  listEquipementSortOrder,
  buildingSitesIds,
  toLineProductGraph,
  getColumnSearchProps,
  currentProject,
  currentIndicator,
  tableEditMode,
  currentAllotment,
  allGroups,
  isUserStartEditColumns,
  referenceSurfaceTag,
  toggleCollapseAll,
  isAllCollapsed,
  t,
  searchBarOptions,
  bundleOptions,
  certifiedAllotments,
  nodesAvailableForProjectType,
  api,
  setValue,
  getValues,
  watch,
  modalLoadingDed,
  handleOpenModal
) => {
  return [
    arrowColumn(t, getColumnSearchProps, toggleCollapseAll, isAllCollapsed),
    currentProject.templateType === TYPE_GLOBAL_MONITORING
      ? dateColumn(
          t,
          currentProject,
          tableEditMode,
          setValue,
          dispatch,
          isUserStartEditColumns
        )
      : { width: 0.00001 },
    quantityColumn(
      t,
      getColumnSearchProps,
      tableEditMode,
      setValue,
      dispatch,
      isUserStartEditColumns,
      listEquipementSortOrder,
      currentProject,
      getValues
    ),
    unitColumn(buildingSitesIds, t, watch, allGroups.type, tableEditMode),
    customNameColumn(
      t,
      getColumnSearchProps,
      tableEditMode,
      setValue,
      dispatch,
      isUserStartEditColumns,
      currentProject,
      getValues
    ),
    typeFicheColumn(t, getColumnSearchProps, modalLoadingDed, handleOpenModal),
    nameColumn(
      t,
      getColumnSearchProps,
      tableEditMode,
      listEquipementSortOrder,
      bundleOptions,
      searchBarOptions,
      certifiedAllotments,
      allGroups,
      nodesAvailableForProjectType,
      api,
      currentAllotment,
      toLineProductGraph,
      setValue,
      dispatch,
      isUserStartEditColumns,
      getValues,
      currentProject
    ),
    currentAllotment.id === ALLOTMENT_IDS.NOOCO
      ? lotColumn(
          t,
          getColumnSearchProps,
          tableEditMode,
          setValue,
          dispatch,
          isUserStartEditColumns,
          listEquipementSortOrder,
          allGroups,
          watch,
          currentProject
        )
      : sousLotColumn(
          t,
          getColumnSearchProps,
          tableEditMode,
          setValue,
          dispatch,
          isUserStartEditColumns,
          listEquipementSortOrder,
          allGroups,
          currentAllotment,
          currentProject,
          watch
        ),
    caracsColumn(
      tableEditMode,
      getValues,
      setValue,
      dispatch,
      isUserStartEditColumns,
      currentProject,
      allGroups
    ),

    lifeSpanColumn(t, getColumnSearchProps, listEquipementSortOrder),

    // impact
    (() => {
      let columnDefinition;

      if (currentAllotment.id === ALLOTMENT_IDS.NOOCO) {
        columnDefinition = returnNoocoImpactColumnDepandingOnRefUnit(
          currentProject.refUnit,
          t,
          getColumnSearchProps,
          listEquipementSortOrder,
          currentIndicator,
          referenceSurfaceTag
        );
      } else {
        columnDefinition = returnCertifiedImpactColumnDepandingOnRefUnit(
          currentProject.refUnit,
          t,
          getColumnSearchProps,
          listEquipementSortOrder,
          currentIndicator,
          referenceSurfaceTag,
          currentProject
        );
      }

      return columnDefinition;
    })(),
    statusColumn(
      t,
      tableEditMode,
      setValue,
      dispatch,
      isUserStartEditColumns,
      getColumnSearchProps,
      watch,
      currentProject,
      getValues
    ),
  ];
};

export const returnCoreColumns = (
  dispatch,
  listEquipementSortOrder,
  buildingSitesIds,
  modalLoadingDed,
  handleOpenModal,
  toLineProductGraph,
  getColumnSearchProps,
  currentProject,
  currentIndicator,
  tableEditMode,
  currentAllotment,
  allGroups,
  isUserStartEditColumns,
  referenceSurfaceTag,
  toggleCollapseAll,
  isAllCollapsed,
  t,
  searchBarOptions,
  bundleOptions,
  certifiedAllotments,
  nodesAvailableForProjectType,
  api,
  setValue,
  groupBy,
  getValues,
  watch
) => {
  if (!currentAllotment) return [];

  if (groupBy !== GroupByOptions.IMPORT) {
    return baseSortColumns(
      dispatch,
      listEquipementSortOrder,
      buildingSitesIds,
      modalLoadingDed,
      handleOpenModal,
      toLineProductGraph,
      getColumnSearchProps,
      currentProject,
      currentIndicator,
      tableEditMode,
      currentAllotment,
      allGroups,
      isUserStartEditColumns,
      referenceSurfaceTag,
      toggleCollapseAll,
      isAllCollapsed,
      t,
      searchBarOptions,
      bundleOptions,
      certifiedAllotments,
      nodesAvailableForProjectType,
      api,
      setValue,
      watch,
      getValues
    );
  } else {
    return importViewSortColumns(
      dispatch,
      listEquipementSortOrder,
      buildingSitesIds,
      toLineProductGraph,
      getColumnSearchProps,
      currentProject,
      currentIndicator,
      tableEditMode,
      currentAllotment,
      allGroups,
      isUserStartEditColumns,
      referenceSurfaceTag,
      toggleCollapseAll,
      isAllCollapsed,
      t,
      searchBarOptions,
      bundleOptions,
      certifiedAllotments,
      nodesAvailableForProjectType,
      api,
      setValue,
      getValues,
      watch
    );
  }
};

export const returnFilterColumn = ({
  currentProject,
  licenceTypeAccess,
  updateNewInies,
  hideColumns,
  handleColumnsHide,
  deleteSelection,
  changeValueEquipments,
  visibleFilters,
  tableEditMode,
  isQuickAddBarListEquipmentsOpen,
  t,
}) => {
  return [
    {
      title: () => {
        return null;
      },
      filterIcon: () => {
        return (
          <Tooltip
            title={t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_47B7B7', {
              defaultValue: 'Afficher davantage de colonnes',
            })}
          >
            <AddCircleOutlineIcon
              onClick={() => changeValueEquipments('visibleFilters', ['addColumn'])}
            />
          </Tooltip>
        );
      },
      filterDropdown: () => {
        return (
          <div style={{ padding: 8, position: 'relative' }}>
            {ReturnPopoverFilterColumns(
              hideColumns,
              handleColumnsHide,
              currentProject,
              isQuickAddBarListEquipmentsOpen,
              t
            )}
          </div>
        );
      },
      onFilterDropdownOpenChange: (visible) =>
        !visible
          ? changeValueEquipments('visibleFilters', [])
          : changeValueEquipments('visibleFilters', ['addColumn']),
      filterDropdownOpen: visibleFilters.includes('addColumn'),
      fixed: 'right',
      width: 100,
      children: [
        commentColumn(),
        actionsColumn(
          t,
          tableEditMode,
          currentProject,
          licenceTypeAccess,
          deleteSelection,
          updateNewInies
        ),
      ],
    },
  ];
};

export const ReturnPopoverFilterColumns = (
  hideColumns,
  handleColumnsHide,
  currentProject,
  isQuickAddBarListEquipmentsOpen,
  t
) => {
  const [heightSizeForPopup, setHeightSizeForPopup] = useState(
    window.innerHeight - (isQuickAddBarListEquipmentsOpen ? 65 : 0)
  );

  useEffect(() => {
    setHeightSizeForPopup(
      window.innerHeight - (isQuickAddBarListEquipmentsOpen ? 65 : 0)
    );
  }, [isQuickAddBarListEquipmentsOpen]);

  const popupMaxHeightInVH = () => {
    if (heightSizeForPopup > 900) {
      return '50vh';
    } else if (heightSizeForPopup > 700) {
      return '45vh';
    } else if (heightSizeForPopup > 600) {
      return '35vh';
    }

    return '25vh';
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: popupMaxHeightInVH(),
        overflow: 'auto',
      }}
    >
      <p style={{ marginBottom: '0' }}>
        {t('APP.LISTEQUIPMENTS.TABLECOLUMNS.DISPLAY_FOLLOWING_COLUMNS', {
          defaultValue: 'Afficher les colonnes suivantes :',
        })}
      </p>
      {hideColumns
        .filter((el) => el.group === 'Nooco')
        .sort(function (a, b) {
          if (a.order < b.order) {
            return -1;
          }

          if (a.order > b.order) {
            return 1;
          }

          return 0;
        })
        .map((el, i) => (
          <div key={el.title}>
            {i === 0 && (
              <p className="select-title">
                {t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_2BEE0D', {
                  defaultValue: 'Rangement Nooco',
                })}
              </p>
            )}
            <Checkbox
              key={el.title}
              checked={el.check}
              onChange={(e) => handleColumnsHide(e, el.title)}
              style={{ margin: 0 }}
            >
              {el.title}
            </Checkbox>
          </div>
        ))}
      {hideColumns
        .filter((el) => el.group === 'carac')
        .sort(function (a, b) {
          if (a.order < b.order) {
            return -1;
          }

          if (a.order > b.order) {
            return 1;
          }

          return 0;
        })
        .map((el, i) => (
          <div key={el.title}>
            {i === 0 && (
              <p className="select-title">
                {t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_470AA7', {
                  defaultValue: 'Caractéristiques devis',
                })}
              </p>
            )}

            <Checkbox
              key={el.title}
              checked={el.check}
              onChange={(e) => handleColumnsHide(e, el.title)}
              style={{ margin: 0 }}
            >
              {el.title ||
                t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_2C67B4', {
                  defaultValue: 'Commentaire',
                })}
            </Checkbox>
          </div>
        ))}
      {hideColumns
        .filter((el) => el.group === 'calcul')
        .sort(function (a, b) {
          if (a.order < b.order) {
            return -1;
          }

          if (a.order > b.order) {
            return 1;
          }

          return 0;
        })
        .map((el, i) => {
          const title = currentProject.templateType.includes(TYPE_EPCM)
            ? el.title.replace(TYPE_EPCM, REFERENCE_TYPE_NAME.EPCM)
            : el.title;

          return (
            <div key={title}>
              {i === 0 && (
                <p className="select-title">
                  {t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_3D27A6', {
                    defaultValue: 'Analyse calcul réglementaire',
                  })}
                </p>
              )}

              <Checkbox
                key={title}
                checked={el.check}
                onChange={(e) => handleColumnsHide(e, el.title)}
                style={{ margin: 0 }}
              >
                {title}
              </Checkbox>
            </div>
          );
        })}
      {hideColumns
        .filter((el) => el.group === 'impact')
        .sort(function (a, b) {
          if (a.order < b.order) {
            return -1;
          }

          if (a.order > b.order) {
            return 1;
          }

          return 0;
        })
        .map((el, i) => {
          return (
            <div key={el.title}>
              {i === 0 && (
                <p className="select-title">
                  {t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_B04267', {
                    defaultValue: 'Emissions',
                  })}
                </p>
              )}
              <Checkbox
                key={el.title}
                checked={el.check}
                onChange={(e) => handleColumnsHide(e, el.title)}
                style={{ margin: 0 }}
              >
                {el.title}
              </Checkbox>
            </div>
          );
        })}
    </div>
  );
};

export const returnHideColumnInit = (
  dispatch,
  currentProject,
  currentIndicator,
  currentAllotment,
  getColumnSearchProps,
  tableEditMode,
  zonesName,
  referenceSurfaceTag,
  showBuildingSites = true,
  t,
  setValue,
  isUserStartEditColumns,
  listEquipementSortOrder,
  allGroups,
  groupBy,
  watch,
  getValues,
  modalLoadingDed,
  handleOpenModal
) => {
  if (!currentAllotment) return [];

  let hideInit = [
    functionColumn(t, getColumnSearchProps),
    categoryColumn(t, getColumnSearchProps),
    materialColumn(t, getColumnSearchProps),
    destinationColumn(
      t,
      getColumnSearchProps,
      tableEditMode,
      dispatch,
      isUserStartEditColumns,
      zonesName,
      showBuildingSites,
      setValue
    ),
    sourceColumn(t, getColumnSearchProps),
    coeffColumn(t, getColumnSearchProps),
    endDateColumn(t, getColumnSearchProps),
    // lot RE2020 & EPCM
    currentProject.templateType === TYPE_RE2020 ||
    currentProject.templateType === TYPE_EPCM ||
    currentProject.templateType === TYPE_BBCA
      ? lotCertifiedColumn(t, getColumnSearchProps, currentProject)
      : {},
    moduleDColumn(
      t,
      getColumnSearchProps,
      currentIndicator,
      currentProject,
      referenceSurfaceTag
    ),
    originalRowColumn(t, getColumnSearchProps),
    typeColumn(t, getColumnSearchProps),
    iniesColumn(
      t,
      getColumnSearchProps,
      tableEditMode,
      setValue,
      dispatch,
      isUserStartEditColumns
    ),
    currentProject.templateType === TYPE_RE2020 ||
    currentProject.templateType === TYPE_NOOCO
      ? stockCColumn(t, getColumnSearchProps, referenceSurfaceTag)
      : {},
    productionColumn(
      t,
      getColumnSearchProps,
      referenceSurfaceTag,
      currentIndicator,
      currentProject
    ),
    edificationColumn(
      t,
      getColumnSearchProps,
      referenceSurfaceTag,
      currentIndicator,
      currentProject
    ),
    entretienColumn(
      t,
      getColumnSearchProps,
      referenceSurfaceTag,
      currentIndicator,
      currentProject
    ),
    endImpactColumn(
      t,
      getColumnSearchProps,
      referenceSurfaceTag,
      currentIndicator,
      currentProject
    ),
    totalAreaColumn(
      t,
      getColumnSearchProps,
      listEquipementSortOrder,
      referenceSurfaceTag,
      currentIndicator,
    ),
    impactRE2020Column(
      t,
      getColumnSearchProps,
      listEquipementSortOrder,
      currentIndicator,
      referenceSurfaceTag,
      currentProject
    ),
    impactByUnitRe2020(
      t,
      getColumnSearchProps,
      listEquipementSortOrder,
      currentIndicator,
      referenceSurfaceTag,
      currentProject
    ),
  ];

  if (groupBy !== GroupByOptions.IMPORT) {
    hideInit.push(
      customNameColumn(
        t,
        getColumnSearchProps,
        tableEditMode,
        setValue,
        dispatch,
        isUserStartEditColumns,
        currentProject,
        getValues
      )
    );
    hideInit.push(
      statusColumn(
        t,
        tableEditMode,
        setValue,
        dispatch,
        isUserStartEditColumns,
        getColumnSearchProps,
        watch,
        currentProject,
        getValues
      )
    );
  }

  // impactRe2020 || globalImpactRe2020
  if (
    currentProject.templateType === TYPE_RE2020 ||
    currentProject.templateType === TYPE_EPCM ||
    currentProject.templateType === TYPE_BBCA
  ) {
    hideInit = [
      ...hideInit,
      returnCertifiedImpactColumnDepandingOnRefUnit(
        currentProject.refUnit,
        t,
        getColumnSearchProps,
        listEquipementSortOrder,
        currentIndicator,
        referenceSurfaceTag,
        currentProject
      ),
    ];

    if (currentProject.ProjectOption.showAlternativeResult) {
      hideInit = [
        ...hideInit,
        returnNoocoImpactColumnDepandingOnRefUnit(
          currentProject.refUnit,
          t,
          getColumnSearchProps,
          listEquipementSortOrder,
          currentIndicator,
          referenceSurfaceTag
        ),
      ];

      if (currentAllotment.id === ALLOTMENT_IDS.NOOCO) {
        hideInit = [
          ...hideInit,
          re2020SheetColumn(t, listEquipementSortOrder, currentProject),
          sousLotColumn(
            t,
            getColumnSearchProps,
            tableEditMode,
            setValue,
            dispatch,
            isUserStartEditColumns,
            listEquipementSortOrder,
            allGroups,
            currentAllotment,
            currentProject,
            watch
          ),

          returnCertifiedImpactColumnDepandingOnRefUnit(
            currentProject.refUnit,
            t,
            getColumnSearchProps,
            listEquipementSortOrder,
            currentIndicator,
            referenceSurfaceTag,
            currentProject
          ),
        ];
      } else {
        hideInit = [
          ...hideInit,
          lotColumn(
            t,
            getColumnSearchProps,
            tableEditMode,
            setValue,
            dispatch,
            isUserStartEditColumns,
            listEquipementSortOrder,
            allGroups,
            watch,
            currentProject
          ),
          returnNoocoImpactColumnDepandingOnRefUnit(
            currentProject.refUnit,
            t,
            getColumnSearchProps,
            listEquipementSortOrder,
            currentIndicator,
            referenceSurfaceTag
          ),
        ];
      }
    }
  } else {
    hideInit = [...hideInit, dataBaseColumn(t, getColumnSearchProps)];

    if (currentProject.ProjectOption.showAlternativeResult) {
      hideInit = [
        ...hideInit,
        lotCertifiedColumn(t, getColumnSearchProps, currentProject),
      ];

      if (currentAllotment.id === ALLOTMENT_IDS.NOOCO) {
        hideInit = [
          ...hideInit,
          re2020SheetColumn(t, listEquipementSortOrder, currentProject),
          impactByAreaRE2020Column(
            t,
            getColumnSearchProps,
            listEquipementSortOrder,
            currentIndicator,
            referenceSurfaceTag,
            currentProject
          ),
          sousLotColumn(
            t,
            getColumnSearchProps,
            tableEditMode,
            setValue,
            dispatch,
            isUserStartEditColumns,
            listEquipementSortOrder,
            allGroups,
            currentAllotment,
            currentProject,
            watch
          ),
          returnNoocoImpactColumnDepandingOnRefUnit(
            currentProject.refUnit,
            t,
            getColumnSearchProps,
            listEquipementSortOrder,
            currentIndicator,
            referenceSurfaceTag
          ),
        ];
      } else {
        hideInit = [
          ...hideInit,
          lotColumn(
            t,
            getColumnSearchProps,
            tableEditMode,
            setValue,
            dispatch,
            isUserStartEditColumns,
            listEquipementSortOrder,
            allGroups,
            watch,
            currentProject
          ),
          impactByAreaColumn(
            t,
            getColumnSearchProps,
            listEquipementSortOrder,
            currentIndicator,
            referenceSurfaceTag
          ),
          returnCertifiedImpactColumnDepandingOnRefUnit(
            currentProject.refUnit,
            t,
            getColumnSearchProps,
            listEquipementSortOrder,
            currentIndicator,
            referenceSurfaceTag,
            currentProject
          ),
          impactByAreaColumn(
            t,
            getColumnSearchProps,
            listEquipementSortOrder,
            currentIndicator,
            referenceSurfaceTag
          ),
        ];
      }
    }
  }
  return hideInit;
};
