import { ItemTypes } from '../../../models/DragConstants';
import { StateDTO } from '../../../models/GeneralDTO';
import { Tooltip } from '@material-ui/core';
import { toggleFavorites } from '../../../../actions/favorites';
import { useDispatch, useSelector } from 'react-redux';
import { useDrag } from 'react-dnd';
import StarIcon from '@material-ui/icons/Star';
import StarOrgaBorder from '../../../../assets/images/project/orgastarempty.png';
import StarOrgaEmpty from '../../../../assets/images/project/orgastar.png';

import CFA from '../../../../assets/images/products/listPics/cfa-removebg-preview.png';
import CFO from '../../../../assets/images/products/listPics/cfo-removebg-preview.png';
import CVC from '../../../../assets/images/products/listPics/cvc-removebg-preview.png';
import Cloison from '../../../../assets/images/products/listPics/Cloison-removebg-preview.png';
import Couv from '../../../../assets/images/products/listPics/couv-removebg-preview.png';
import Deplacement from '../../../../assets/images/products/listPics/deplacement-removebg-preview.png';
import Eau from '../../../../assets/images/products/listPics/eau-removebg-preview.png';
import EclairagesPublic from '../../../../assets/images/products/EclairagesPublic.png';
import Energy from '../../../../assets/images/products/listPics/energy-removebg-preview.png';
import Façades from '../../../../assets/images/products/listPics/façades-removebg-preview.png';
import Infra from '../../../../assets/images/products/listPics/infra-removebg-preview.png';
import Menuiserie from '../../../../assets/images/products/listPics/Menuiserie-removebg-preview.png';
import Plomberie from '../../../../assets/images/products/listPics/plomberie-removebg-preview.png';
import React from 'react';
import Réseaux from '../../../../assets/images/products/Réseaux.png';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Superstructure from '../../../../assets/images/products/listPics/superstructure-removebg-preview.png';
import SystemesTriBagage from '../../../../assets/images/products/SystemesTriBagage.png';
import Terre from '../../../../assets/images/products/listPics/terre-removebg-preview.png';
import VRD from '../../../../assets/images/products/listPics/vrd-removebg-preview.png';
import defaultPic from '../../../../assets/images/products/default.png';
import IRVE from '../../../../assets/images/products/IRVE.svg';
import PIF from '../../../../assets/images/products/PIF.svg';
import OuvrageArt from '../../../../assets/images/products/OuvrageArt.svg';
import Fluide from '../../../../assets/images/products/Fluide.svg';
import EnergieSimplifiee from '../../../../assets/images/products/EnergieSimplifiee.svg';

const FavoriteCard = ({ favorite, company }) => {
  const { allGroups, isCompanyAdmin, isAdminOrga } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { userFavoritesIds, companyFavoritesIds } = useSelector(
    (state: StateDTO) => state.favorites
  );
  const dispatch = useDispatch();

  const md = allGroups.type.find((ele) => ele.id === favorite.MasterDataEquipmentId);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.ProductCard,
    item: favorite,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        fontSize: 25,
        fontWeight: 'bold',
        cursor: 'move',
      }}
      className="product-item-card"
    >
      <div className="container-badge">
        <p
          className="badge"
          style={{
            backgroundColor:
              favorite?.type_fiche === 'FC'
                ? '#e8a838'
                : favorite?.type_fiche === 'DEC' ||
                  favorite?.type_fiche === 'FORFAIT'
                ? '#8e67ff'
                : favorite?.type_fiche === 'DED'
                ? '#363e63'
                : '#43ddc4',
            color: 'white',
          }}
        >
          {favorite?.type_fiche === 'FC' ? 'CONFIG' : favorite?.type_fiche}
        </p>
        {company ? (
          companyFavoritesIds?.includes(favorite.id) ? (
            <img
              style={{
                height: '20px',
                cursor: isAdminOrga || isCompanyAdmin ? 'pointer' : null,
                display: 'block',
              }}
              onClick={() =>
                isAdminOrga || isCompanyAdmin
                  ? dispatch(toggleFavorites(favorite.id, true))
                  : null
              }
              src={StarOrgaEmpty}
              alt=""
            />
          ) : (
            <img
              style={{
                height: '20px',
                cursor: isAdminOrga || isCompanyAdmin ? 'pointer' : null,
                display: 'block',
                filter:
                  'invert(0%) sepia(100%) saturate(0%) hue-rotate(269deg) brightness(40%) contrast(108%)',
              }}
              onClick={() =>
                isAdminOrga || isCompanyAdmin
                  ? dispatch(toggleFavorites(favorite.id, true))
                  : null
              }
              src={StarOrgaBorder}
              alt=""
            />
          )
        ) : !userFavoritesIds?.includes(favorite.id) ? (
          <StarBorderIcon
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch(toggleFavorites(favorite.id, null))}
          />
        ) : (
          <StarIcon
            style={{ color: '#92AAE7', cursor: 'pointer' }}
            onClick={() => dispatch(toggleFavorites(favorite.id, null))}
          />
        )}
      </div>
      <img
        className="img-card"
        src={
          md?.lot === 'Superstructure'
            ? Superstructure
            : md?.lot === 'CFA'
            ? CFA
            : md?.lot === 'Menuiseries ext.'
            ? Menuiserie
            : md?.lot === 'Plomberie'
            ? Plomberie
            : md?.lot === 'Cloison-Plafond'
            ? Cloison
            : md?.lot === 'Infrastructure'
            ? Infra
            : md?.lot === 'CVC'
            ? CVC
            : md?.lot === 'CFO'
            ? CFO
            : md?.lot === 'Façades'
            ? Façades
            : md?.lot === 'VRD-Aménagement'
            ? VRD
            : md?.lot === 'Déplacement int.'
            ? Deplacement
            : md?.lot === 'Terre'
            ? Terre
            : md?.lot === 'Couverture-étanchéité'
            ? Couv
            : md?.lot === 'Energie'
            ? Energy
            : md?.lot === 'Eau'
            ? Eau
            : md?.lot === 'Réseaux'
            ? Réseaux
            : md?.lot === 'Système tri-bagages'
            ? SystemesTriBagage
            : md?.lot === 'Éclairage public-SLT'
            ? EclairagesPublic
            : md?.lot === "Ouvrage d'art"
            ? OuvrageArt
            : md?.lot === "Poste d'Inspection Filtrage"
            ? PIF
            : md?.lot === 'IRVE'
            ? IRVE
            : md?.lot === 'Fluides'
            ? Fluide
            : md?.lot === 'Energie simplifiée'
            ? EnergieSimplifiee
            : defaultPic
        }
        alt=""
      />
      <div className="name-card">
        <Tooltip title={favorite?.name?.length > 40 ? favorite.name : ''}>
          <p>
            {favorite?.name?.length > 40
              ? `${favorite.name.slice(0, 40)}[...]`
              : favorite.name}
          </p>
        </Tooltip>
      </div>
    </div>
  );
};

export default FavoriteCard;
