import { connect } from 'react-redux';

import {
  addProjectToVariant,
  addVariantSelection,
  changeValueVariant,
  fetchProjectForGraph,
  removeAddedProject,
} from '../../actions/variants';

import CardVariant from '../../app/projects/Variant/CardVariant';

const mapStateToProps = (state) => ({
  searchVariant: state.variants.searchVariant,
  selectedVariants: state.variants.selectedVariants,
  variantsInDetails: state.variants.variantsInDetails,
  addedVariant: state.variants.addedVariant,
  currentProject: state.projects.currentProject,
  variants: state.variants.variants,
  currentIndicatorId: state.projects.currentIndicator.id,
  currentIndicator: state.projects.currentIndicator,
  currentAllotment: state.projects.currentAllotment,
});

const mapDispatchToProps = (dispatch) => ({
  addVariantSelection: (variant) => {
    dispatch(addVariantSelection(variant));
  },

  changeValueVariant: (name, value) => {
    dispatch(changeValueVariant(name, value));
  },
  addProjectToVariant: (project) => {
    dispatch(addProjectToVariant(project));
  },
  removeAddedProject: (project) => {
    dispatch(removeAddedProject(project));
  },
  fetchProjectForGraph: (project, isSelected) => {
    dispatch(fetchProjectForGraph(project, isSelected));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CardVariant);
