/* eslint-disable no-undef */
import { AllotmentNodeDTO, ZoneDTO } from '../../../models/ProjectDTO';
import { BuildingSheet, ProjectIcDTO, zonesDTO } from './WidgetsDTO';
import { IndicatorDTO, ProjectDTO } from '../../../../models/ProjectDTO';
import {
  TEMPLATE_TYPE_ID,
  TEMPLATE_TYPE_NAME,
} from '../../../globalVariable/typeProject';
import {
  gaugeEPCM,
  returnBuildingIcDepandingOnIndicator,
} from '../../../shared/utils';
import CustomBbcaThreshold from '../../../shared/CustomBbcaThreshold/CustomBbcaThreshold';
import CustomProgressBar from '../../../shared/CustomProgressBar';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import formatNumber from '../../../services/mathematics/formatNumber';
import { useTranslation } from 'react-i18next';
import lightIcon from '../../../../assets/images/import/light.png';
import { ProjectDataComps } from '@photocarbone/nooco-shared-api-def/src/project/projectDataComps';
import {
  ClimateInnovationDataScore,
  calculClimateInnovationScore,
  createClimateInnovationDataScore,
} from '../../ClimateInnovation/ClimateInnovationForm/ClimateInnovationForm';
import Spinner from '../../../shared/Spinner';
import { ZONE_TYPES } from '../../../globalVariable/generalDefinitions';
import { getBbcaScore } from './widgetsFunctions/widgetsFunctions';

const building = require('../../../../assets/images/synthese/Image1.png');

type BuildingWidgetsProps = {
  projectIc: ProjectIcDTO;
  zones: zonesDTO[];
  currentProject: ProjectDTO;
  currentIndicator: IndicatorDTO;
  currentAllotment: AllotmentNodeDTO;
  buildingSheet: BuildingSheet;
  projectDataComps: ProjectDataComps;
};

const BuildingWidgets = ({
  projectIc,
  zones,
  currentProject,
  currentIndicator,
  currentAllotment,
  buildingSheet,
  projectDataComps,
}: BuildingWidgetsProps): JSX.Element => {
  const buildingIc =
    returnBuildingIcDepandingOnIndicator(zones, buildingSheet, currentIndicator) ||
    projectIc;
  const buildingArea =
    zones?.find((zone) => zone?.id === buildingSheet?.buildingId)?.area || 1;
  const { t } = useTranslation();

  let refUnitModeTotal = currentProject.refUnit === 'total';

  let climateInnovationData: ClimateInnovationDataScore | undefined = undefined;
  let buildingZone: ZoneDTO | undefined = undefined;
  let climateInnovationScore: number | null = null;
  let bbcaScore: number | null = null;

  if (currentProject.templateType?.includes(TEMPLATE_TYPE_NAME.BBCA)) {
    buildingZone = zones.find((zone) => zone.id === buildingSheet?.buildingId);
    const selectedBuildingClimateInnovationData = projectDataComps?.buildings.find(
      (building) => building.ZoneId === buildingZone?.id
    );

    climateInnovationData = createClimateInnovationDataScore(
      selectedBuildingClimateInnovationData?.climateInnovationData
    );
    climateInnovationScore = calculClimateInnovationScore({
      climateInnovationData,
      zoneSelected: buildingZone,
    });
    bbcaScore = Number(buildingZone?.Ic[1]?.Bbca.BbcaScore.toFixed(2));
  }

  return (
    <div className="report-building container-resizable-ok">
      <div className="report-building-header">
        <h3 className="dashboard-title">{buildingSheet.name}</h3>
        <Tooltip
          title={t('APP.WIDGETS.BUILDINGWIDGET.PH_DE3119', {
            defaultValue: 'Impact carbone du bâtiment : IcBâtiment',
          })}
        >
          <img
            style={{
              width: window.screen.availHeight < 950 ? '40px' : undefined,
              height: window.screen.availHeight < 950 ? '40px' : undefined,
            }}
            src={building}
            alt=""
          />
        </Tooltip>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {currentProject.templateType?.includes(TEMPLATE_TYPE_NAME.BBCA) && (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                Score BBCA
              </div>
              <div
                style={{
                  marginLeft: '1rem',
                  marginRight: '1rem',
                  fontWeight: 'bold',
                  fontSize: '1.3rem',
                }}
              >
                {`${bbcaScore} point(s)`}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <CustomBbcaThreshold
                bbcaScore={bbcaScore}
                buildingTypeId={
                  buildingZone?.Zones?.find((zone) => zone.type === ZONE_TYPES.zone)
                    ?.BuildingTypeId
                }
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                marginTop: '8px',
              }}
            >
              <img
                src={lightIcon}
                alt=""
                style={{ width: '20px', height: '20px' }}
              />
              <div style={{ marginRight: '1rem' }}>
                {'Points innovation climat: '}
              </div>
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.3rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: '8px' }}>
                  {climateInnovationScore === null ? (
                    <Spinner size={'small'} />
                  ) : (
                    `${climateInnovationScore}`
                  )}
                </div>
                <div>{'point(s)'}</div>
              </div>
            </div>
          </>
        )}
        {!currentProject.templateType?.includes(TEMPLATE_TYPE_NAME.BBCA) && (
          <p className="value">
            <span className="valueNumbers">
              {refUnitModeTotal
                ? formatNumber(
                    currentAllotment.id === TEMPLATE_TYPE_ID.RE2020
                      ? buildingIc?.IcProjetRe2020 * buildingArea
                      : buildingIc?.IcProjet * buildingArea
                  )
                : formatNumber(
                    currentAllotment.id === TEMPLATE_TYPE_ID.RE2020
                      ? buildingIc?.IcProjetRe2020
                      : buildingIc?.IcProjet
                  )}
            </span>{' '}
            {currentIndicator.unit}
            {refUnitModeTotal ? '' : '/m²'}
          </p>
        )}
      </div>

      {currentProject.templateType.includes(TEMPLATE_TYPE_NAME.EPCM) && (
        <>
          <CustomProgressBar
            projectIc={buildingIc}
            valuePercentage={gaugeEPCM('IcProjet', buildingIc)}
          />
        </>
      )}
    </div>
  );
};

export default BuildingWidgets;
