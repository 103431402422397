import { Controller } from 'react-hook-form';
import { searchAddress } from '../../actions/projects';
import { updateFormValue } from '../../actions/projects';
import { useDebounce } from '../../hooks/utils/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import { Control } from 'react-hook-form';

interface AddressProps {
  settings: boolean;
  control: Control<any>;
  setValue: (name: string, value: any) => void;
  error: boolean;
  defaultValue?: string;
  disabled?: boolean;
  countryCode: string;
}

interface Location {
  city: string;
  postcode: string;
}

interface ProjectState {
  searchLocations: Location[];
  currentProject: { address: string };
  postCodeRset: string;
  isFileUploaded: boolean;
}

const LocationTextInputParams = {
  autocomplete: 'chrome-off',
};

const AddressTextInputParams = {
  style: { width: '100%' },
};

function Address({
  settings,
  control,
  setValue,
  error,
  defaultValue,
  disabled,
  countryCode,
}): React.FC<AddressProps> {
  const dispatch = useDispatch();
  const { searchLocations, currentProject, postCodeRset } =
    useSelector<ProjectState>((state) => state.projects);
  const [inputValue, setInputValue] = useState(currentProject.address);
  const name = useMemo(() => (settings ? 'Address' : 'location'), [settings]);
  const CustomTextInputParams = useMemo(
    () => (settings ? AddressTextInputParams : LocationTextInputParams),
    [settings]
  );
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = useDebounce(searchValue, 300);
  const { t } = useTranslation();

  useEffect(() => {
    if (postCodeRset) {
      dispatch(searchAddress(postCodeRset, countryCode));
    }
  }, [postCodeRset]);

  useEffect(() => {
    if (postCodeRset && searchLocations && searchLocations.length !== 0) {
      setValue(name, searchLocations[0] || undefined);
      setInputValue(searchLocations[0].Text);
    }
  }, [searchLocations]);

  useEffect(() => {
    if (debouncedValue.length > 2) {
      dispatch(searchAddress(debouncedValue, countryCode));
    }
  }, [debouncedValue, dispatch]);

  const handleField = (value: string) => {
    dispatch(updateFormValue(name, value));
    setSearchValue(value);
  };

  const returnOptionValue = (option: {
    city: string;
    citycode: string;
    Text: string;
  }) => {
    return option.Text || `${option.city}, ${option.citycode}`;
  };

  return (
    <div className="address-wrapper">
      <Controller
        disabled={disabled}
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          required: t('APP.SHARED.ADDRESS.PH_1FC2E9', {
            defaultValue: 'Veuillez renseigner une adresse (ville ou CP)',
          }),
        }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={searchLocations}
            className="addressBar"
            autoComplete="off"
            disableClearable
            key={inputValue}
            defaultValue={defaultValue || inputValue || null}
            onChange={(data, newValue: any) => {
              if (currentProject.Address?.id)
                newValue.id = currentProject.Address.id;
              setValue(name, newValue || undefined);
              dispatch(updateFormValue('searchLocations', []));
            }}
            size="small"
            getOptionLabel={(option) => `${returnOptionValue(option)}`}
            noOptionsText={t('APP.SHARED.ADDRESS.PH_A45083', {
              defaultValue: 'Aucune adresse trouvée',
            })}
            renderOption={(option) => returnOptionValue(option)}
            renderInput={(params) => (
              <TextField
                {...params}
                {...CustomTextInputParams}
                className={`researchBar ${error ? 'error-border-form' : ''}`}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'chrome-off',
                  'aria-label': 'description',
                  form: { autoComplete: 'off' },
                }}
                placeholder={t('APP.SHARED.ADDRESS.PH_9FA324', {
                  defaultValue: 'Ville ou code postal',
                })}
                variant="outlined"
                onChange={(e: HTMLInputElement) => handleField(e.target.value)}
              />
            )}
          />
        )}
      />
    </div>
  );
}

export default Address;
