import { StateDTO } from '../../app/models/GeneralDTO';
import { loadBoarding, updateExploitationValue } from '../../actions/exploitation';
import { returnColorsForBarGraph } from '../../app/exploitation/Report';
import { returnNameAction } from '../../app/exploitation/ListActions';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../app/services/API';
import moment from 'moment';

const api = new API();

export const getImpactGraph = async (
  id: number,
  eventTypes,
  datesSelectedInGraph,
  t
) => {
  const response = await api.fetchImpactBarGraph(id, datesSelectedInGraph);
  let formatData = [];
  let keys = [];

  response.data
    .sort((a, b) => {
      if (a.month < b.month) {
        return -1;
      }
    })
    .forEach((action) => {
      let data = formatData.find(
        (el) => el.month === moment(action.month).format('MMM YYYY')
      );

      let eventType = eventTypes.find((el) => el.id === action.EventTypeId);

      if (!keys.find((el) => el.key === returnNameAction(eventType.name, t))) {
        keys = [
          ...keys,
          {
            key: returnNameAction(eventType.name, t),
            color: returnColorsForBarGraph(eventType.name),
          },
        ];
      }

      if (!data) {
        formatData.push({
          month: moment(action.month).format('MMM YYYY'),
          [returnNameAction(eventType.name, t)]: action.carbonImpact,
          [`${returnNameAction(eventType.name, t)}Color`]: returnColorsForBarGraph(
            eventType.name
          ),
        });
      } else {
        formatData = formatData.map((el) => {
          if (el?.month === data.month) {
            return {
              ...el,
              [returnNameAction(eventType.name, t)]: action.carbonImpact,
              [`${returnNameAction(eventType.name, t)}Color`]:
                returnColorsForBarGraph(eventType.name),
            };
          } else {
            return el;
          }
        });
      }
    });

  return { data: formatData, keys: keys };
};
export const getActionsModelsGraph = async (id: number, datesSelectedInGraph, t) => {
  const res = await api.getGraphModelsAndActions(id, datesSelectedInGraph);
  let values: number[] = [];

  res.data.actionsData = res.data.actionsData
    .sort((a, b) => a.year < b.year)
    .map((data) => {
      values = [...values, data.carbonImpact];

      return {
        ...data,
        name: returnNameAction(data.EventType, t),
        year: moment(data.year).format('MMM YYYY'),
        color: returnColorsForBarGraph(data.EventType),
      };
    });
  res.data.modelLines = res.data.modelLines
    .sort((a, b) => a.year < b.year)
    .map((data) => {
      values = [...values, data.carbonImpact];

      return {
        ...data,
        model:
          data.model === 'initialState'
            ? 'Trajectoire de référence'
            : 'Trajectoire actualisée',
        year: moment(data.year).format('MMM YYYY'),
        color: returnColorsForBarGraph(data.EventType),
      };
    })
    .sort((a, b) => a.year - b.year);

  res.data.maxValue = Math.max(...values);

  return res.data;
};
export const getBoardingEquipment = async (
  id: number,
  offset: number,
  boardingActiveTab: string,
  allGroups: { type: {}[] },
  data
) => {
  return await api
    .fetchBoardingEquipment(
      id,
      data,
      boardingActiveTab !== 'Equipement / matériau' ? boardingActiveTab : null
    )
    .then((response) => {
      return response.data.map((el) => ({
        ...el,
        key: el.id,
        MasterDataEquipment: allGroups.type.find(
          (md) => md.id === el.MasterDataEquipmentId
        ),
      }));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const createBoardingEquipment = async (data) => {
  return await api
    .createBoardingEquipment(data.id, data.newEquipment)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
export const deleteBoardingEquipment = async (data) => {
  return await api
    .deleteBoardingEquipment(data.currentProjectId, data.ids)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const patchBoardingEquipment = async (data) => {
  return await api
    .patchBoardingEquipment(data.id, data.newEquipment)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
