import { ResponsiveRadar } from '@nivo/radar';
import { createProjectStructure } from '../../shared/parsingDatas';
import { filterGraphData, makeDataGraph } from '../../shared/makeDataGraph';
import { useUpdateEffect } from 'react-use';
import Loader from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from 'react';

import { ALLOTMENT_IDS } from '../../globalVariable/typeProject';
import { useTranslation } from 'react-i18next';
import formatNumber from '../../services/mathematics/formatNumber';

const MyRadar = ({
  selectedVariants,
  currentFilter,
  dataGraph,
  changeValueVariant,
  keysGraph,
  currentProject,
  selectedLotFilter,
  filterOptions,
  filteredGraph,
  allGroups,
  currentIndicator,
  copyData,
  currentAllotment,
  zones,
  mds,
  updateFormValue,
  realData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    changeValueVariant('selectedLotFilter', [
      {
        id: 1200,
        label:
          currentAllotment.id === ALLOTMENT_IDS.RE2020
            ? t('APP.VARIANT.RADAR.PH_093E6B', {
                defaultValue: "'Tous contributeurs'",
              })
            : t('APP.VARIANT.RADAR.PH_4B530A', { defaultValue: 'Tous lots' }),
        color: '#ccc',
        name: 'Tous lots',
      },
    ]);

    if (selectedVariants.length > 0) {
      let needDatas = [];

      selectedVariants.forEach((el) => {
        needDatas = [
          ...needDatas,
          createProjectStructure(
            currentAllotment,
            el.project.Sheets,
            el.zones,
            el.project,
            false
          ),
        ];
      });

      const allDatas = makeDataGraph(
        needDatas,
        currentProject,
        currentAllotment,
        currentFilter
      );

      changeValueVariant('realData', needDatas);

      changeValueVariant('dataGraph', allDatas.dataGraph);
      changeValueVariant('keysGraph', [...new Set(allDatas.keys)]);

      if (filterOptions.length === 0) {
        changeValueVariant('filterOptions', [
          ...filterOptions,
          ...selectedLotFilter,
          ...allDatas.filters,
        ]);
      }
    }

    return () => {
      changeValueVariant('dataGraph', []);
      changeValueVariant('keysGraph', []);
    };
  }, [selectedVariants, currentFilter]);

  useUpdateEffect(() => {
    filterGraphData(
      selectedLotFilter,
      changeValueVariant,
      dataGraph,
      currentFilter,
      realData
    );
  }, [selectedLotFilter]);

  if (isLoading) {
    return (
      <Loader
        variant="indeterminate"
        disableShrink
        style={{
          display: 'block',
          color: '#73b7ec',
          margin: 'auto',
          marginTop: '2em',
        }}
        size={70}
        thickness={4}
      />
    );
  }

  return (
    <ResponsiveRadar
      data={filteredGraph || dataGraph || []}
      keys={keysGraph}
      key={filteredGraph}
      indexBy={'name'}
      tooltipFormat={(value) => {
        return `${formatNumber(value)} ${currentIndicator.unit}`;
      }}
      margin={{ top: 70, right: 80, bottom: 60, left: 80 }}
      curve="linearClosed"
      borderWidth={2}
      borderColor={{ from: 'color' }}
      gridLevels={5}
      gridShape="circular"
      gridLabelOffset={36}
      enableDots={false}
      enableDotLabel={false}
      dotLabel="value"
      dotLabelYOffset={-12}
      colors={[
        '#67c0b5',
        '#8b5aff',
        '#f76d5f',
        '#D67E8E',
        '#D69954',
        '#5ED674',
        '#74B9D6',
        '#96FFE5',
        '#D68369',
        '#9E7ED6',
        '#D6D05E',
        '#68EDA6',
        '#CA8B84',
        '#6CCFFF',
        '#72E336',
        '#FFC400',
        '#D5BBFF',
        '#FFD5BB',
        '#FE80B1',
        '#CCCCCC',
        '#D15239',
        '#5689E5',
        '#8C8C8C',
        '#F7A19A',
        '#FFDAA2',
        '#C7A0CB',
        '#FDB94D',
        '#00B274',
        '#CE59C1',
        '#FFF685',
        '#AD9268',
      ]}
      fillOpacity={0.1}
      blendMode="multiply"
      animate={true}
      motionConfig="wobbly"
      isInteractive={true}
      legends={[
        {
          anchor: 'top-left',
          direction: 'column',
          translateX: -20,
          translateY: -40,
          itemWidth: 80,
          itemHeight: 20,
          itemTextColor: '#999',
          symbolSize: 12,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  );
};

export default MyRadar;
