import { IndicatorDTO } from '../../../../models/ProjectDTO';
import MyResponsivePie from '../ResponsivePie';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ZonesWidgetsProps = {
  currentIndicator: IndicatorDTO;
  data: {
    id: number | string;
    label: string;
    color: string;
    value: string;
    count: number;
  }[];
  isMenuOpen: boolean;
};

const ZonesWidgets = ({
  data,
  currentIndicator,
}: ZonesWidgetsProps): JSX.Element => {
  const { t } = useTranslation();
  const chartStyles = {
    '--chart-transform-origin': 'top left',
    '--chart-transition': 'transform 0.3s ease-in-out',
    '--chart-scale-small': 'scale(0.5)',
    '--chart-scale-medium': 'scale(0.75)',
    marginTop: data.filter((el) => el.id !== 'count').length > 7 ? '21%' : undefined,
    marginLeft: data.filter((el) => el.id !== 'count').length > 7 ? '6%' : undefined,
  };

  // console.log('data', data)

  return (
    <div
      className="report-zones-graph container-resizable-ok"
      style={{ overflow: 'auto' }}
    >
      <h3 className="dashboard-title">
        {t('APP.ZONESWIDGET.ZONE_DISTRIBUTION', {
          defaultValue: 'Répartition par zone',
        })}
      </h3>{' '}
      <div className="legends-custom" style={{ position: 'absolute' }}>
        {data
          .filter(
            (el: {
              id: number | string;
              label: string;
              color: string;
              value: string;
              count: number;
            }) => el.id !== 'count' && !el.label.includes('Chantier')
          )
          .map(
            (d: {
              id: number | string;
              label: string;
              color: string;
              value: string;
              count: number;
            }) => (
              <div
                key={d.id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  fontSize: '10px',
                  gap: '5px',
                  justifyContent: 'flex-start',
                  fontFamily: 'sans-serif',
                }}
              >
                <div
                  style={{
                    border: '0px solid',
                    borderRadius: '50%',
                    width: '10px',
                    height: '10px',
                    backgroundColor: d.color,
                  }}
                />

                {d.label}
              </div>
            )
          )}
      </div>
      {/* {console.log('data', data)}- */}
      {/* {console.log(currentIndicator)} */}
      {/* {console.log(
                'data',
                data.filter(
                  (el: {
                    id: number | string
                    label: string
                    color: string
                    value: string
                    count: number
                  }) =>
                    el.id !== 'count' &&
                    el.value !== '0.00' &&
                    !el.label.includes('Chantier')
                )
               )} */}
      <div
        className="graph-zones"
        style={{
          ...chartStyles,
          transformOrigin: 'var(--chart-transform-origin)',
          transition: 'var(--chart-transition)',
        }}
      >
        {data &&
          data.find(
            (el: {
              id: number | string;
              label: string;
              color: string;
              value: string;
              count: number;
            }) => el.id === 'count'
          ) &&
          data.find(
            (el: {
              id: number | string;
              label: string;
              color: string;
              value: string;
              count: number;
            }) => el.id === 'count'
          )?.count === 0 && (
            <p style={{ width: '100px', paddingTop: '27%', margin: 'auto' }}>
              Pas de données
            </p>
          )}

        {data &&
          data.find(
            (el: {
              id: number | string;
              label: string;
              color: string;
              value: string;
              count: number;
            }) => el.id === 'count'
          ) &&
          data.find(
            (el: {
              id: number | string;
              label: string;
              color: string;
              value: string;
              count: number;
            }) => el.id === 'count'
          )?.count !== 0 && (
            <div
              style={{
                width: '100%',
                height: '100%',
                minWidth: '50px',
                minHeight: '50px',
              }}
            >
              <MyResponsivePie
                currentIndicator={currentIndicator}
                data={data.filter(
                  (el: {
                    id: number | string;
                    label: string;
                    color: string;
                    value: string;
                    count: number;
                  }) =>
                    el.id !== 'count' &&
                    el.value !== '0.00' &&
                    !el.label.includes('Chantier')
                )}
                colors={(d: { data: { color: string } }) => {
                  return d.data.color;
                }}
                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                legends={undefined}
                style={{
                  transform: 'var(--chart-scale-medium)',
                  transformOrigin: 'var(--chart-transform-origin)',
                }}
                media={[
                  {
                    query: '(max-width: 767px)',
                    style: {
                      transform: 'var(--chart-scale-small)',
                    },
                  },
                  {
                    query: '(min-width: 768px) and (max-width: 991px)',
                    style: {
                      transform: 'var(--chart-scale-medium)',
                    },
                  },
                ]}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default ZonesWidgets;
