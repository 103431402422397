/* eslint-disable no-unused-vars */
import { Dispatch } from 'redux';
import { MapDispatchToPropsParam, MapStateToPropsParam, connect } from 'react-redux';

import CompaniesProducts from '../../app/products/CompaniesProducts';

import { updateProductsValue } from '../../actions/products';
import { BrandDTO } from '../../app/models/ProductDTO';

interface IProducts {
  productView: string;
  brandsList: {}[];
  brandsGroup: { group: string; children: { name: string }[] }[];
  brandsSelected: BrandDTO[];
}

type State = {
  products: IProducts;
};

const mapStateToProps: MapStateToPropsParam<{}, {}, State> = (state: State) => ({
  brandsList: state.products.brandsList,
  brandsGroup: state.products.brandsGroup,
  brandsSelected: state.products.brandsSelected,
});

const mapDispatchToProps: MapDispatchToPropsParam<{}, {}> = (
  dispatch: Dispatch
) => ({
  updateProductsValue: (name: string, value: string) => {
    dispatch(updateProductsValue(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesProducts);
