/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';

import { updateFormValue } from '../../actions/projects';

import { changeValueEquipments } from '../../actions/equipments';

import Projects from '../../app/projects/Projects';

const mapStateToProps = (state) => ({
  isLoadingProjects: state.projects.isLoadingProjects,
  buildingTypes: state.projects.buildingTypes,
  isSuperAdmin: state.projects.isSuperAdmin,
  isInit: state.projects.projectsListMonitoring.isInit,
});

const mapDispatchToProps = (dispatch) => ({
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  changeValueEquipments: (name, value) => {
    dispatch(changeValueEquipments(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
