import { connect } from 'react-redux';

import { changeValueVariant } from '../../actions/variants';
import { updateFormValue } from '../../actions/projects';

import Radar from '../../app/projects/Variant/Radar';

const mapStateToProps = (state) => ({
  selectedVariants: state.variants.selectedVariants,
  variantsInDetails: state.variants.variantsInDetails,
  currentFilter: state.variants.currentFilter,
  dataGraph: state.variants.dataGraph,
  keysGraph: state.variants.keysGraph,
  realData: state.variants.realData,
  currentProject: state.projects.currentProject,
  selectedLotFilter: state.variants.selectedLotFilter,
  filterOptions: state.variants.filterOptions,
  filteredGraph: state.variants.filteredGraph,
  allGroups: state.projects.allGroups,
  currentIndicator: state.projects.currentIndicator,
  copyData: state.projects.copyData,
  currentAllotment: state.projects.currentAllotment,
  zones: state.projects.parsedProject.zones,
  mds: state.projects.mds,
});

const mapDispatchToProps = (dispatch) => ({
  changeValueVariant: (name, value) => {
    dispatch(changeValueVariant(name, value));
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Radar);
