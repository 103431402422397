import React, { useEffect, useState } from 'react';
import API from '../services/API';
const api = new API();

const Health = () => {
  const [message, setMessage] = useState<string>();
  useEffect(() => {
    api.getHealth().then((response) => {
      setMessage(response.data?.message);
    });
  }, []);
  return <div>{message ? <h2>{message}</h2> : <h2>loading...</h2>}</div>;
};

export default Health;
