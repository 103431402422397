export const UPDATE_EXPLOITATION_VALUE = 'UPDATE_EXPLOITATION_VALUE';
export const FETCH_BOARDING = 'FETCH_BOARDING';
export const LOAD_BOARDING = 'LOAD_BOARDING';
export const GET_CARACS = 'GET_CARACS';
export const CREATE_NEW_EQUIPMENT = 'CREATE_NEW_EQUIPMENT';
export const LOAD_NEW_EQUIPMENT = 'LOAD_NEW_EQUIPMENT';
export const RESET_ADD_ACTION = 'RESET_ADD_ACTION';
export const CREATE_ACTION = 'CREATE_ACTION';
export const FETCH_LIST_ACTIONS = 'FETCH_LIST_ACTIONS';
export const LOAD_LIST_ACTIONS = 'LOAD_LIST_ACTIONS';
export const FETCH_SHEETS = 'FETCH_SHEETS';
export const LOAD_SHEETS = 'LOAD_SHEETS';
export const LOAD_NEW_ACTION = 'LOAD_NEW_ACTION';
export const GET_FULL_MD = 'GET_FULL_MD';
export const SEARCH_LIST_ACTIONS = 'SEARCH_LIST_ACTIONS';
export const CRUD_BOARDING = 'CRUD_BOARDING';
export const RESET_EXPLOITATION = 'RESET_EXPLOITATION';
export const FETCH_IMPACT_BARGRAPH = 'FETCH_IMPACT_BARGRAPH';
export const FETCH_ACTIONS_MODELS_GRAPH = 'FETCH_ACTIONS_MODELS_GRAPH';
export const GET_CARACS_COMBOS = 'GET_CARACS_COMBOS';
export const DELETE_ACTION = 'DELETE_ACTION';
export const PATCH_PROJECT = 'PATCH_PROJECT';
export const DOWNLOAD_BOARDING_XLSX = 'DOWNLOAD_BOARDING_XLSX';
export const INCREMENT_OFFSET = 'INCREMENT_OFFSET';
export const RESET_NEW_EQUIPMENT = 'RESET_NEW_EQUIPMENT';

export const updateExploitationValue = (name, value) => ({
  type: UPDATE_EXPLOITATION_VALUE,
  name,
  value,
});

export const getCaracs = (md, screen, index) => ({
  type: GET_CARACS,
  md,
  screen,
  index,
});

export const fetchBoarding = (offset, id) => ({
  type: FETCH_BOARDING,
  offset,
  id,
});

export const loadBoarding = (data) => ({
  type: LOAD_BOARDING,
  data,
});

export const createNewEquipment = () => ({
  type: CREATE_NEW_EQUIPMENT,
});

export const loadNewEquipment = (data) => ({
  type: LOAD_NEW_EQUIPMENT,
  data,
});

export const fetchListActions = (id, t) => ({
  type: FETCH_LIST_ACTIONS,
  id,
  t,
});

export const loadListActions = (data, t) => ({
  type: LOAD_LIST_ACTIONS,
  data,
  t,
});

export const resetAddAction = () => ({
  type: RESET_ADD_ACTION,
});

export const createAction = (
  t,
  garbage,
  refurbished,
  transport,
  simplifiedEnergy
) => ({
  type: CREATE_ACTION,
  t,
  garbage,
  refurbished,
  transport,
  simplifiedEnergy,
});

export const loadNewAction = (list, t) => ({
  type: LOAD_NEW_ACTION,
  list,
  t,
});

export const fetchSheets = (mdId) => ({
  type: FETCH_SHEETS,
  mdId,
});

export const loadSheets = (sheets) => ({
  type: LOAD_SHEETS,
  sheets,
});

export const getFullMd = (id) => ({
  type: GET_FULL_MD,
  id,
});

export const searchListActions = () => ({
  type: SEARCH_LIST_ACTIONS,
});

export const crudBoarding = (method) => ({
  type: CRUD_BOARDING,
  method,
});

export const resetExploitation = () => ({
  type: RESET_EXPLOITATION,
});

export const fetchImpactBarGraph = () => ({
  type: FETCH_IMPACT_BARGRAPH,
});

export const fetchActionsModelsGraph = () => ({
  type: FETCH_ACTIONS_MODELS_GRAPH,
});

export const getCaracsCombos = (masterData) => ({
  type: GET_CARACS_COMBOS,
  masterData,
});

export const deleteAction = () => ({
  type: DELETE_ACTION,
});

export const patchProject = (data) => ({
  type: PATCH_PROJECT,
  data,
});

export const downloadBoardingXlsx = () => ({
  type: DOWNLOAD_BOARDING_XLSX,
});
export const incrementOffset = () => ({
  type: INCREMENT_OFFSET,
});

export const resetNewEquipment = () => ({
  type: RESET_NEW_EQUIPMENT,
});
