/**
console.log(`User Pool ${process.env.REACT_APP_USER_POOL_ID}`);
console.log(`App Client ${process.env.REACT_APP_APP_CLIENT_ID}`);
console.log(process.env.REACT_APP_IDENTITY_POOL_ID);
console.log(process.env.REACT_APP_ENV)
**/

export const REGION = 'eu-west-1';

export default {
  s3: {
    REGION: 'YOUR_S3_UPLOADS_BUCKET_REGION',
    BUCKET: 'YOUR_S3_UPLOADS_BUCKET_NAME',
  },
  apiGateway: {
    REGION: REGION,
    URL: process.env.REACT_APP_BASE_URL,
  },
  cognito: {
    REGION: REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
  kinesis: {
    REGION: REGION,
    KINESIS_DATA_STREAM_NAME: process.env.REACT_APP_KINESIS_DATA_STREAM_NAME,
  },
  import: {
    BUCKET: process.env.REACT_APP_IMPORTFILES_BUCKET_NAME,
  },
  config: {
    BUCKET: process.env.REACT_APP_CONFIGSHEETS_BUCKET_NAME,
  },
  project: {
    BUCKET: process.env.REACT_APP_BUCKET_PROJECT,
  },
  location: {
    IndexName: 'nooco_location_no_storage',
  },
};
