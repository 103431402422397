import React, { useCallback } from 'react';
import WidgetItem from './WidgetItem';
import { useTranslation } from 'react-i18next';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import TimelineIcon from '@material-ui/icons/Timeline';
import { FontSizeOutlined } from '@ant-design/icons';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import WidgetPreview from './WidgetPreview';
import { StateDTO } from '../../../models/GeneralDTO';
import { useSelector } from 'react-redux';
import TableChartIcon from '@material-ui/icons/TableChart';
export interface Widget {
  key: ConstantKeysWidgets;
  id?: number;
  name: string;
  description: string;
  icon: React.ReactNode;
  text?: string;
}

export enum ConstantKeysWidgets {
  HISTOGRAM = 'histogram',
  ANALYTICS = 'analytics',
  DONUT = 'donut',
  TEXTAREA = 'textarea',
  TEXT = 'text',
  TABLE = 'table',
}

const widgetListData: (t) => Widget[] = (t) => {
  return [
    {
      key: ConstantKeysWidgets.HISTOGRAM,
      name: t('APP.PDF_BUIDLER.WIDGETS_LIST.DESCRIPTION.TITLE', {
        defaultValue: "Leviers d'optimisation",
      }),
      description: t('APP.PDF_BUIDLER.WIDGETS_LIST.DESCRIPTION.HISTOGRAMME', {
        defaultValue: 'Principaux contributeurs à l’impact du projet',
      }),
      icon: InsertChartIcon,
    },
    // {
    //   key: ConstantKeysWidgets.ANALYTICS,
    //   name: t('APP.PDF_BUIDLER.WIDGETS_LIST.ANALYTICS.TITLE', {
    //     defaultValue: 'Analytics',
    //   }),
    //   description: t('APP.PDF_BUIDLER.WIDGETS_LIST.DESCRIPTION.ANALYTICS', {
    //     defaultValue: 'Principaux contributeurs à l’impact du projet',
    //   }),
    //   icon: TimelineIcon,
    // },
    {
      key: ConstantKeysWidgets.DONUT,
      name: 'Donut',
      description: 'Diagramme circulaire des impacts répartis par lot',
      icon: DonutSmallIcon,
    },
    {
      key: ConstantKeysWidgets.TABLE,
      name: t('APP.PDF_BUIDLER.WIDGETS_LIST.PROJECT_PRESENTATION.TITLE', {
        defaultValue: 'Présentation projet',
      }),
      description: t(
        'APP.PDF_BUIDLER.WIDGETS_LIST.PROJECT_PRESENTATION.DESCRIPTION',
        {
          defaultValue: 'Présentation des impacts carbone du projet',
        }
      ),
      icon: TableChartIcon,
    },
    {
      key: ConstantKeysWidgets.TEXTAREA,
      name: t('APP.PDF_BUIDLER.WIDGETS_LIST.TEXTAREA.TITLE', {
        defaultValue: 'Texte libre',
      }),
      description: t('APP.PDF_BUIDLER.WIDGETS_LIST.TEXTAREA.DESCRIPTION', {
        defaultValue: 'Vignette de saisie libre de texte',
      }),
      icon: FontSizeOutlined,
    },
    {
      key: ConstantKeysWidgets.TEXT,
      name: t('APP.PDF_BUIDLER.WIDGETS_LIST.TEXT.TITLE', {
        defaultValue: 'Texte fixe',
      }),
      description: t('APP.PDF_BUIDLER.WIDGETS_LIST.TEXTAREA.DESCRIPTION', {
        defaultValue: 'Vignette de saisie libre de texte',
      }),
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ornare sapien non magna semper, sit amet pellentesque mauris dictum. Etiam facilisis nibh pretium dui ultricies tempor. Donec eu nisi in arcu convallis imperdiet. Praesent ut ante viverra, sagittis ante eget, feugiat ipsum. Vestibulum interdum nisl ut orci cursus sollicitudin ut in tortor. Duis ac nunc ac nibh posuere interdum in eget sem. Vivamus sagittis enim mi, vitae posuere lacus iaculis at. In eu vehicula justo. Nulla facilisi. Curabitur facilisis massa vel tincidunt egest',
      icon: FontSizeOutlined,
    },
  ];
};

function WidgetsList() {
  const { t } = useTranslation();

  const WidgetsListData = widgetListData(t);

  return (
    <>
      <WidgetPreview />
      <div className="border-section padding-section" data-testid="widgets-list">
        <div className="header-section">
          <p className="title-section">
            {t('APP.PDF_BUILDER.WIDGETS_LIST_TITLE', { defaultValue: 'Vignettes' })}
          </p>{' '}
          <p className="subtitle-section">
            {t('APP.PDF_BUILDER.DRAG_AND_DROP', {
              defaultValue: 'Glisser/Déposer les vignettes dans les sections',
            })}
          </p>
        </div>

        {WidgetsListData.map((widget) => (
          <WidgetItem widget={widget} key={widget.id} moveCard={() => {}} />
        ))}
      </div>
    </>
  );
}

export default WidgetsList;
