import { useTranslation } from 'react-i18next';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import impactLogo from '../../../../../assets/images/project/impact.png';
import referenceTrajectoryLogo from '../../../../../assets/images/project/referenceTrajectory.png';
import updatedTrajectoryLogo from '../../../../../assets/images/project/updatedTrajectory.png';

type ExploitationElementProps = {
  name: string;
  value: string;
  logoName: 'impact' | 'referenceTrajectory' | 'updatedTrajectory';
  cardHeader: boolean;
};

type LogoProps = {
  logoName: ExploitationElementProps['logoName'];
};
const Logo = ({ logoName }: LogoProps): JSX.Element => {
  const { t } = useTranslation();
  let logo;

  if (logoName === 'impact') {
    logo = impactLogo;
  } else if (logoName === 'referenceTrajectory') {
    logo = referenceTrajectoryLogo;
  } else if (logoName === 'updatedTrajectory') {
    logo = updatedTrajectoryLogo;
  }

  const tooltipTitle1 = t('APP.PROJECTCARD.EXPLOITATIONELEMENT.PH_EF27B2', {
    defaultValue:
      "L'impact de l'exploitation correspond à la somme des impacts des actions réalisées et modélisées dans la liste des actions durant la période du contrat d'exploitation",
  });

  const tooltipTitle2 = t('APP.PROJECTCARD.EXPLOITATIONELEMENT.PH_30C45C', {
    defaultValue:
      "La trajectoire de référence correspond à l’impact de référence projeté et simulé par rapport à la prise en charge, en fonction de la liste d'équipements / matériaux fournie et des consommations de référence",
  });

  const tooltipTitle3 = t('APP.PROJECTCARD.EXPLOITATIONELEMENT.PH_154349', {
    defaultValue:
      'La trajectoire actualisée correspond à l’actualisation de la trajectoire de référence en fonction des actions réalisées en liste des actions',
  });

  return (
    <div className="logoWrapper">
      <Tooltip
        title={
          logoName === 'impact'
            ? tooltipTitle1
            : logoName === 'referenceTrajectory'
            ? tooltipTitle2
            : tooltipTitle3
        }
      >
        <img src={logo} alt={logoName} />
      </Tooltip>
    </div>
  );
};

const ExploitationElement = ({
  name,
  value,
  logoName,
  cardHeader,
}: ExploitationElementProps): JSX.Element => {
  return (
    <div className={`elementWrapper ${cardHeader ? 'cardHeader' : ''}`}>
      {!cardHeader && <Logo logoName={logoName} />}
      <div
        className=""
        style={{
          display: 'flex',
          flexDirection: cardHeader ? 'row' : 'column',
          alignItems: 'center',
        }}
      >
        <div className={`title ${cardHeader ? 'cardHeader' : ''}`}>{name}</div>
        <div className={`value ${cardHeader ? 'cardHeader' : ''}`}>{value}</div>
      </div>
    </div>
  );
};

export default ExploitationElement;
