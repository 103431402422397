import './multipleActionsButton.scss';
import { Autocomplete } from '@material-ui/lab';
import { Button, SearchGroup, RealSelect, Badge } from '@photocarbone/nooco-ui';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Modal } from 'react-bootstrap';
import { Popover } from 'antd';
import { CurrentScreen, StateDTO } from '../../models/GeneralDTO';
import { changeValueEquipments, updateNewInies } from '../../../actions/equipments';
import { deleteRowsImport, patchLines } from '../../../actions/import';
import { motion } from 'framer-motion';
import { updateFormValue } from '../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/Update';
import { SEARCH_GROUP_OPTION_VALUES } from '../../globalVariable/searchGroupType';

import { ALLOTMENT_IDS } from '../../globalVariable/typeProject';
import {
  findParentNode,
  handleAllotmentNodes,
  returnSearchBarOptionsFiltered,
} from '../utils';
import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import mdAction from '../../../assets/images/mapping/mdAction.png';
import refurbishedIcon from '../../../assets/images/mapping/reemploy.svg';
import yellow from '../../../assets/images/mapping/yellow.png';
import { useTranslation } from 'react-i18next';
import BottomButton from './BottomButton';
import {
  useGetCaracsByAllotmentNodeId,
  usePatchLinesFromList,
} from '../../../hooks/useQuery/equipments';
import CaracPopover, { CaracValuesDTO } from '../CaracPopover/CaracPopover';
import { updateFormData } from '../../projects/EquipmentList/ListEquipments/allColumnsObject';
import { useFormContext } from 'react-hook-form';
import {
  getListScrollPosition,
  reformatDataForPatch,
  resetStatesAfterPatchEquipments,
} from '../../projects/EquipmentList/ListEquipments';
import { checkEmptyInput } from '../../projects/EquipmentList/ListEquipments/EditButtons';
import API from '../../services/API';
import { TYPE_FICHE } from '../../globalVariable/generalDefinitions';
import {
  checkIdBundleCanBeAddedInProject,
  getStatusAdjustableFluid,
} from '../QuickAddBarEquipment/QuickAddBarEquipment';
import { SelectedSearchGroupOptionsLabel } from '../QuickAddBarEquipment/QuickAddBarEquipment';
import { AllotmentNodeDTO } from '../../models/ProjectDTO';
import { MasterDataEquipmentDTO } from '../../models/SimulatorDTO';
import {
  findIfRowIdIsProjectSheetId,
  onChangeSelectedSearchGroupType,
  resetSelectedSearchGroupTypeIfNeeded,
  returnLotNoocoForSheetSelected,
  returnSearchGroupOptions,
} from '../utilsFunction';
import { useLocalStorage } from 'react-use';

enum Status {
  VALIDATED = 'validated',
  RELIABLE = 'reliable',
  TO_CHECK = 'toCheck',
}

const getStatusItems = (t) => [
  {
    value: Status.VALIDATED,
    optionRender: (
      <Badge
        type="success"
        text={t('APP.MULTIPLEACTIONSBUTTON.STATUS.VALIDATED_TEXT', {
          defaultValue: 'vérifié',
        })}
      />
    ),
    label: (
      <Badge
        type="success"
        text={t('APP.MULTIPLEACTIONSBUTTON.STATUS.VALIDATED_LABEL', {
          defaultValue: 'vérifié',
        })}
      />
    ),
  },
  {
    value: Status.RELIABLE,
    optionRender: (
      <Badge
        type="warning"
        text={t('APP.MULTIPLEACTIONSBUTTON.STATUS.RELIABLE_TEXT', {
          defaultValue: 'fiable',
        })}
      />
    ),
    label: (
      <Badge
        type="warning"
        text={t('APP.MULTIPLEACTIONSBUTTON.STATUS.RELIABLE_LABEL', {
          defaultValue: 'fiable',
        })}
      />
    ),
  },
  {
    value: Status.TO_CHECK,
    optionRender: (
      <Badge
        type="error"
        text={t('APP.MULTIPLEACTIONSBUTTON.STATUS.TO_CHECK_TEXT', {
          defaultValue: 'à vérifier',
        })}
      />
    ),
    label: (
      <Badge
        type="error"
        text={t('APP.MULTIPLEACTIONSBUTTON.STATUS.TO_CHECK_LABEL', {
          defaultValue: 'à vérifier',
        })}
      />
    ),
  },
];

const api = new API();

const MultipleActionsButton = ({
  linesSelected,
  listEquipment,
  deleteSelection,
  isMapping,
}) => {
  const dispatch = useDispatch();
  const {
    mdToLines,
    multipleActionOpen,
    mdActionOpen,
    allLinesChecked,
    multipleDeletionModal,
  } = useSelector((state: StateDTO) => state.imports);
  const {
    currentProject,
    licenceTypeAccess,
    allGroups,
    currentAllotment,
    bundleOptions,
    modeledProductOptions,
    configuredSheetOptions,
    nodesAvailableForProjectType,
    certifiedAllotments,
    listOfNodesSearchBar,
    confirmPatchRefurbished,
    user,
    parsedProject,
  } = useSelector((state: StateDTO) => state.projects);
  const {
    statusInMultipleActions,
    statusActionOpen,
    selectedRowKeys,
    caracValuesInContributorList,
  } = useSelector((state: StateDTO) => state.equipments);
  const [
    isBuildingSiteCalculatorEquipmentInSelection,
    setIsBuildingSiteCalculatorEquipmentInSelection,
  ] = useState(false);
  const [sheetsInSelection, setSheetsInSelection] = useState([]);
  const { t } = useTranslation();
  const usePatchEquipments = usePatchLinesFromList();
  const [AllotmendNodeId, setAllotmendNodeId] = useState<number | null>(null);
  const [caracValues, setCaracValues] = useState(null);
  const [sheetSelected, setSheetSelected] = useState(null);
  const [selectedSearchGroupType, setSelectedSearchGroupType] =
    useLocalStorage<SelectedSearchGroupOptionsLabel>(
      `selectedSearchGroupType-${user.userId}`,
      SelectedSearchGroupOptionsLabel.Equipements
    );

  let isIniesSelected =
    selectedSearchGroupType === SelectedSearchGroupOptionsLabel.INIES;

  let isTypeFicheSelectedDontNeedCarac =
    selectedSearchGroupType === SelectedSearchGroupOptionsLabel.Ensembles ||
    selectedSearchGroupType ===
      SelectedSearchGroupOptionsLabel['Produits modélisés'] ||
    selectedSearchGroupType ===
      SelectedSearchGroupOptionsLabel['Fiches Configurées'];

  let certificationKeyNode =
    currentAllotment?.id === 2
      ? 'Re2020AllotmentNodeId'
      : currentAllotment?.id === 34
      ? 'EpcmAllotmentNodeId'
      : '';

  const searchBarOptionsFiltered = returnSearchBarOptionsFiltered({
    allAllotments: allGroups.flatAllotments,
    allotmentsAvailable: isMapping
      ? listOfNodesSearchBar
      : nodesAvailableForProjectType,
    iniesOptions: [],
    bundleOptions: isMapping
      ? bundleOptions
      : bundleOptions?.filter((bundle) =>
          checkIdBundleCanBeAddedInProject(bundle, currentProject, allGroups)
        ),
    modeledProductOptions,
    configuredSheetOptions,
    currentAllotment,
  });

  const SEARCH_GROUP_OPTIONS = [
    SEARCH_GROUP_OPTION_VALUES.EQUIPEMENTS,
    SEARCH_GROUP_OPTION_VALUES.INIES,
    SEARCH_GROUP_OPTION_VALUES.BIBLIOTHEQUE,
  ];

  let formSetValue;
  let formReset;
  let formData;

  if (listEquipment) {
    const { setValue, getValues, reset } = useFormContext();

    formSetValue = setValue;
    formReset = reset;
    formData = getValues();
  }

  let isEmptyInput: Boolean = formData?.ProjectSheets
    ? checkEmptyInput(formData)
    : false;

  useEffect(() => {
    if (!isMapping) {
      if (
        (linesSelected.length > 0 || allLinesChecked) &&
        (!multipleActionOpen || listEquipment)
      ) {
        const sheetsInSelection = linesSelected
          .map((d) =>
            currentProject.Sheets.find(
              (el: { ProjectSheet: { id: number } }) => el.ProjectSheet.id === d
            )
          )
          .filter((el) => el !== undefined);

        setIsBuildingSiteCalculatorEquipmentInSelection(
          sheetsInSelection.find(
            (sheet) => sheet?.ProjectSheet?.source === 'buildingSiteCalculator'
          )
            ? true
            : false
        );
        setSheetsInSelection(sheetsInSelection);
        dispatch(updateFormValue('multipleActionOpen', true));
      } else if (
        linesSelected.length === 0 &&
        !allLinesChecked &&
        multipleActionOpen
      ) {
        dispatch(updateFormValue('multipleActionOpen', false));
        setSheetsInSelection([]);
        setIsBuildingSiteCalculatorEquipmentInSelection(false);
      }
    } else {
      if (linesSelected.length > 0 || allLinesChecked) {
        dispatch(updateFormValue('multipleActionOpen', true));
      } else {
        dispatch(updateFormValue('multipleActionOpen', false));
      }
    }
  }, [linesSelected, allLinesChecked]);

  useEffect(() => {
    if (mdActionOpen) {
      resetSelectedSearchGroupTypeIfNeeded(
        setSelectedSearchGroupType,
        selectedSearchGroupType,
        SEARCH_GROUP_OPTIONS
      );
    }
  }, [mdActionOpen]);

  const popoverMdContent = () => {
    let allotmentsAvailable = isMapping
      ? listOfNodesSearchBar
      : nodesAvailableForProjectType;

    let datas: any = [];

    if (mdToLines) {
      datas = handleAllotmentNodes(
        { id: mdToLines.MasterDataEquipmentId },
        currentAllotment,
        allGroups.flatAllotments,
        currentProject
      );

      datas = !datas ? [] : datas.filter((el) => !el.isObsolete);
    }

    if (isIniesSelected && sheetSelected) {
      datas = returnLotNoocoForSheetSelected(
        sheetSelected?.AllotmentNodes,
        currentProject?.Lots,
        allGroups.flatAllotments
      );
    }

    const resetFormData = () => {
      updateFormData(
        formSetValue,
        { id: 'multipleActions' },
        'mdToLines',
        null,
        dispatch,
        true
      );
      updateFormData(
        formSetValue,
        { id: 'multipleActions' },
        'SheetId',
        null,
        dispatch,
        true
      );
    };
    const setIniesDefaultCaracValues = ({ iniesSheet, newSearchValue }) => {
      if (
        !iniesSheet?.SheetCaracs ||
        !iniesSheet?.SheetCaracs?.length ||
        !iniesSheet?.id_inies ||
        !isIniesSelected
      ) {
        return;
      }

      let newCaracValues = {
        ...caracValues,
      };

      const selectedIniesSheet = iniesSheet.SheetCaracs;

      if (newSearchValue?.xAxisId) {
        newCaracValues.xAxis = selectedIniesSheet?.find(
          (sheet) => sheet.CaracId === newSearchValue.xAxisId
        )?.value[0];
      }

      if (newSearchValue?.discreteId) {
        newCaracValues.discrete = selectedIniesSheet?.find(
          (sheet) => sheet.CaracId === newSearchValue.discreteId
        )?.value[0];
      }
      if (newSearchValue?.secondDiscreteId) {
        newCaracValues.secondDiscrete =
          selectedIniesSheet?.find(
            (sheet) => sheet.CaracId === newSearchValue.secondDiscreteId
          )?.value[0] || 'Aucun';
      }
      return newCaracValues;
    };

    return (
      <div className="wrapper-md-action">
        <p>
          {t('APP.MULTIPLEACTIONSBUTTON.STATUS_ACTION', {
            defaultValue: 'Changer le statut des éléments sélectionnés',
          })}
        </p>
        <div style={{ marginBottom: '10px', display: 'flex' }}>
          <SearchGroup
            t={t}
            companyId={currentProject.CompanyId}
            searchBarOptionsFiltered={searchBarOptionsFiltered}
            triggerBreadcrumb={() => {
              dispatch(updateFormValue('openBreadcrumb', true));
            }}
            projectTypeId={currentProject.ProjectTypeId}
            certifiedAllotments={certifiedAllotments}
            api={api}
            bundleOptions={bundleOptions}
            findParentNode={findParentNode}
            allAllotments={allGroups.flatAllotments}
            allotmentsAvailable={
              isMapping
                ? allotmentsAvailable.sort((a, b) => a.name?.localeCompare(b.name))
                : nodesAvailableForProjectType.sort((a, b) =>
                    a.name.localeCompare(b.name)
                  )
            }
            dropdownProps={{
              onSelect: () => {},
              onChange: (value) => {
                if (typeof value === 'number') {
                  let node = allGroups.flatAllotments.find(
                    (node) => node.id === value
                  );

                  if (!isMapping) {
                    updateFormData(
                      formSetValue,
                      { id: 'multipleActions' },
                      'mdToLines',
                      node,
                      dispatch,
                      true
                    );
                  }
                  dispatch(updateFormValue('mdToLines', node));
                } else if (typeof value === 'object') {
                  // if (isIniesSelected) {
                  const iniesSheet = value;
                  setSheetSelected(iniesSheet);
                  let node = value.AllotmentNodes[0] || null;
                  const newSearchValue =
                    allGroups.type.find(
                      (masterdata) => masterdata.id === node?.MasterDataEquipmentId
                    ) || null;
                  if (isIniesSelected) {
                    setAllotmendNodeId(node?.id || null);
                  }

                  dispatch(
                    changeValueEquipments('caracValuesInContributorList', {
                      ...caracValuesInContributorList,
                      multipleActions: {
                        ...setIniesDefaultCaracValues({
                          iniesSheet,
                          newSearchValue,
                        }),
                      },
                    })
                  );

                  dispatch(updateFormValue('mdToLines', value.AllotmentNodes[0]));
                } else {
                  resetFormData();
                  dispatch(updateFormValue('mdToLines', null));
                }
              },
              onClear: () => {
                resetFormData();
                dispatch(updateFormValue('mdToLines', null));
                resetStatesAfterPatchEquipments(dispatch, formReset, true);
                setSheetSelected(null);
              },
              placeholder: 'Rechercher...',
              treeCheckable: false,
              value: sheetSelected
                ? sheetSelected.name
                : mdToLines
                ? mdToLines.name
                : null,
            }}
            dropdownWidth="200px"
            selectProps1={{
              defaultValue: selectedSearchGroupType,
              onChange: (value) => {
                onChangeSelectedSearchGroupType(
                  setSelectedSearchGroupType,
                  SelectedSearchGroupOptionsLabel[value.label] ||
                    SelectedSearchGroupOptionsLabel[value.value] ||
                    SelectedSearchGroupOptionsLabel.Equipements
                );
                resetFormData();
                resetStatesAfterPatchEquipments(dispatch, formReset, true);
                setSheetSelected(null);
              },
              filterOptions: SEARCH_GROUP_OPTIONS,
            }}
            selectProps2={{
              ListType:
                currentAllotment.id !== ALLOTMENT_IDS.NOOCO
                  ? [currentAllotment.name.toUpperCase()]
                  : ['Nooco'],
              noocoOptions: datas.filter((el) => !el.isObsolete),
              disabled: Boolean(currentAllotment.id !== ALLOTMENT_IDS.NOOCO),
              onChange: (data) => {
                if (!isIniesSelected) {
                  resetFormData();
                  resetStatesAfterPatchEquipments(dispatch, formReset, true);
                }
                let currentData = datas.find((node) => node.name === data);

                if (
                  currentProject.Lots.find((el) =>
                    currentData.details.find(
                      (ele) => ele.name.toLowerCase() === el.name.toLowerCase()
                    )
                  )
                ) {
                  dispatch(
                    updateFormValue(
                      'mdToLines',
                      currentData.details.find((el) => el.MasterDataEquipmentId)
                    )
                  );
                } else {
                  // setOpenSnackbar(
                  //   'Veuillez rajouter le lot dans votre projet'
                  // );
                }
              },
              value:
                mdToLines && currentAllotment.id === ALLOTMENT_IDS.NOOCO
                  ? findParentNode(mdToLines, allGroups.flatAllotments, [], [])
                  : mdToLines
                  ? [
                      allGroups.flatAllotments.find(
                        (node) => node.id === mdToLines[certificationKeyNode]
                      ),
                    ]
                  : [],
            }}
            selectWidth="100px"
          />
          {!isMapping && (
            <CaracPopover
              AllotmentNodeId={
                isTypeFicheSelectedDontNeedCarac
                  ? null
                  : isIniesSelected
                  ? AllotmendNodeId
                  : mdToLines?.id
              }
              caracValues={[]}
              setCaracValues={(caracChanged) => {
                updateFormData(
                  formSetValue,
                  { id: 'multipleActions' },
                  'caracs',
                  caracChanged,
                  dispatch,
                  true
                );
              }}
              unamortizedValues={null}
              setUnamortizedValues={() => {}}
              isUnamortizedChecked={false}
              isPhotovoltaicEquipmentSelected={false}
              isIniesSelected={isIniesSelected}
              isAdjustableFluid={getStatusAdjustableFluid(
                allGroups.type.find(
                  (md) => md.id === mdToLines?.MasterDataEquipmentId
                ),
                currentProject.templateType,
                null
              )}
              disabledContributorList={!mdToLines}
              row={{ id: 'multipleActions' }}
              screen={CurrentScreen.multipleActions}
            />
          )}
        </div>
        <BottomButton
          disabled={isMapping ? !mdToLines : !mdToLines || isEmptyInput}
          isEmptyInputInContributorList={isEmptyInput}
          onClose={() => dispatch(updateFormValue('mdActionOpen', false))}
          onConfirm={() =>
            listEquipment
              ? patchEquipmentsFromContributorList({
                  AllotmentNode: mdToLines,
                  SheetId: sheetSelected,
                })
              : dispatch(patchLines('allotmentNode'))
          }
        />
      </div>
    );
  };

  const renderConfirmationDeleteModal = () => {
    return (
      <Modal
        show={multipleDeletionModal}
        onHide={() => {
          dispatch(updateFormValue('multipleDeletionModal', false));
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_D17006', {
              defaultValue: 'Suppression',
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_1CD087', {
            defaultValue:
              "Tous les éléments sélectionnés sont sur le point d'être supprimés, voulez-vous confirmer ?",
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="close-button"
            onClick={() => {
              dispatch(updateFormValue('multipleDeletionModal', false));
            }}
            text={t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_8AF944', {
              defaultValue: 'Fermer',
            })}
          />

          {/* {!loadingApi && ( */}
          <Button
            className="purple-button"
            onClick={() => {
              dispatch(deleteRowsImport());
              dispatch(updateFormValue('isLoad', true));
            }}
            text={t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_44F680', {
              defaultValue: 'Confirmer',
            })}
          />

          {/* )} */}
          {/* {loadingApi && (
            <div style={{ width: '100px', display: 'flex' }}>
              <Loader variant="indeterminate"
                disableShrink
                style={{ color: "#73b7ec", margin: 'auto' }}
                size={30}
                thickness={4}/>
            </div>
            
          )} */}
        </Modal.Footer>
      </Modal>
    );
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleDelete = () => {
    if (formData.ProjectSheets) {
      let formatData = reformatDataForPatch(formData, allGroups);

      usePatchEquipments.mutate({ ProjectSheetsToPatch: formatData });

      resetStatesAfterPatchEquipments(dispatch, formReset);
    }

    deleteSelection(sheetsInSelection, dispatch);
    dispatch(changeValueEquipments('isLoadingProject', true));
    setTimeout(() => {
      dispatch(changeValueEquipments('selectedRowKeys', []));
    }, 200);
    setOpenDialog(false);
  };

  const patchEquipmentsFromContributorList = (objectWithKeysAndValuesToPatch) => {
    formData.ProjectSheets = formData.ProjectSheets ? formData.ProjectSheets : {};

    const multipleActionData = formData.ProjectSheets['multipleActions']
      ? {
          ...formData.ProjectSheets['multipleActions'],
          ...objectWithKeysAndValuesToPatch,
        }
      : objectWithKeysAndValuesToPatch;

    selectedRowKeys.forEach((rowId) => {
      const isRowIdAProjectSheetId = findIfRowIdIsProjectSheetId(
        rowId,
        parsedProject.raw
      );

      if (!isRowIdAProjectSheetId) return;

      const row = formData.ProjectSheets[rowId];
      if (row) {
        formData.ProjectSheets[rowId] = {
          ...formData.ProjectSheets[rowId],
          ...multipleActionData,
        };
      } else {
        formData.ProjectSheets[rowId] = { id: rowId, ...multipleActionData };
      }
    });

    delete formData.ProjectSheets['multipleActions'];

    let formatData = reformatDataForPatch(formData, allGroups);

    usePatchEquipments.mutate({ ProjectSheetsToPatch: formatData });

    getListScrollPosition(dispatch, changeValueEquipments);

    resetStatesAfterPatchEquipments(dispatch, formReset);
  };

  const renderCloseMultipleActionButtons = () => {
    return (
      <Tooltip
        title={t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_54EB36', {
          defaultValue:
            'Pour accéder aux actions multiples, sélectionner au moins 1 ligne avec la coche',
        })}
      >
        <div className="action-wrapper">
          <div className="action-icon">
            <img
              src={mdAction}
              style={{ filter: 'invert(75%)', width: '15px' }}
              alt=""
            />
          </div>
          <div className="action-icon">
            <CheckCircleIcon />
          </div>
          <div className="action-icon">
            <UpdateIcon />
          </div>
          <img
            src={refurbishedIcon}
            style={{
              width: '19px',
            }}
          />

          <div className="action-icon">
            <DeleteIcon />
          </div>
        </div>
      </Tooltip>
    );
  };

  const renderStatusPopOver = (t) => {
    const statusItems = getStatusItems(t);
    return (
      <div className="popOver-wrapper">
        <p style={{ color: '#687182' }}>
          {t('APP.MULTIPLEACTIONSBUTTON.CHANGE_STATUS', {
            defaultValue: 'Changer le statut',
          })}
        </p>
        <RealSelect
          onChange={(value) => {
            dispatch(changeValueEquipments('statusInMultipleActions', value.value));
          }}
          className={'transparent-select'}
          items={statusItems}
          dropdownStyle={{ width: '150px' }}
        />

        <BottomButton
          disabled={!statusInMultipleActions || isEmptyInput}
          isEmptyInputInContributorList={isEmptyInput}
          onClose={() => dispatch(changeValueEquipments('statusActionOpen', false))}
          onConfirm={() =>
            patchEquipmentsFromContributorList({
              status: statusInMultipleActions,
            })
          }
          isLoading={usePatchEquipments.isLoading}
        />
      </div>
    );
  };

  const renderOpenActionsForContributorList = (t) => {
    return (
      <div className="wrapper-multiple-actions">
        <div className="icon-action">
          <Popover trigger="click" content={popoverMdContent} visible={mdActionOpen}>
            <Tooltip
              title={
                licenceTypeAccess !== 'pro'
                  ? t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_DCAC6A', {
                      defaultValue:
                        'Fonctionnalité disponible avec une licence pro seulement',
                    })
                  : !!isBuildingSiteCalculatorEquipmentInSelection
                  ? t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_1F332A', {
                      defaultValue:
                        'Associer une famille d’équipements aux éléments sélectionnés',
                    })
                  : t('APP.MULTIPLEACTIONSBUTTON.INDEX.CALCULATOR_BLOCK_EQUIPMENT', {
                      defaultValue:
                        "La sélection contient un ou plusieurs contributeurs du chantier. Le changement d'équipement de ces derniers est possible uniquement via la calculette chantier",
                    })
              }
            >
              <img
                src={mdAction}
                style={{ filter: 'invert(100%)' }}
                onClick={() => dispatch(updateFormValue('mdActionOpen', true))}
                alt=""
              />
            </Tooltip>
          </Popover>
        </div>
        <div className="icon-action">
          <Popover
            visible={statusActionOpen}
            content={renderStatusPopOver(t)}
            trigger="click"
          >
            <Tooltip
              title={
                licenceTypeAccess !== 'pro'
                  ? t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_DCAC6A', {
                      defaultValue:
                        'Fonctionnalité disponible avec une licence pro seulement',
                    })
                  : !isBuildingSiteCalculatorEquipmentInSelection
                  ? t('APP.MULTIPLEACTIONSBUTTON.STATUS_ACTION', {
                      defaultValue: 'Changer le status des éléments sélectionnés',
                    })
                  : t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_22C3A5', {
                      defaultValue:
                        'La sélection contient un ou plusieurs contributeurs du chantier. La suppression de ces derniers est possible uniquement via la calculette chantier',
                    })
              }
            >
              <CheckCircleIcon
                onClick={() =>
                  dispatch(changeValueEquipments('statusActionOpen', true))
                }
                style={{
                  color: licenceTypeAccess !== 'pro' ? 'gray' : '',

                  cursor:
                    licenceTypeAccess !== 'pro' ||
                    isBuildingSiteCalculatorEquipmentInSelection
                      ? 'not-allowed'
                      : '',
                }}
              />
            </Tooltip>
          </Popover>
        </div>
        <div className="icon-action">
          <Tooltip
            title={
              licenceTypeAccess !== 'pro'
                ? t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_21E926', {
                    defaultValue:
                      'Fonctionnalité disponible avec une licence pro seulement',
                  })
                : t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_7457EA', {
                    defaultValue:
                      'Mettre à jour la donnée des éléments sélectionnés',
                  })
            }
          >
            <UpdateIcon
              style={{
                color: licenceTypeAccess !== 'pro' ? 'gray' : 'white',
              }}
              onClick={() => {
                if (licenceTypeAccess === 'pro') {
                  dispatch(updateNewInies());
                  dispatch(changeValueEquipments('isLoadingProject', true));
                }
              }}
            />
          </Tooltip>
        </div>
        <div className="icon-action">
          <Tooltip
            title={
              licenceTypeAccess !== 'pro'
                ? t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_21E926', {
                    defaultValue:
                      'Fonctionnalité disponible avec une licence pro seulement',
                  })
                : t('APP.MULTIPLEACTIONSBUTTON.REEMPLOY', {
                    defaultValue: "Passer l'équipement en réemploi",
                  })
            }
          >
            <img
              src={refurbishedIcon}
              onClick={() =>
                dispatch(updateFormValue('confirmPatchRefurbished', true))
              }
              style={{
                width: '19px',
                filter:
                  ' brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(116deg) brightness(109%) contrast(109%)',
              }}
            />
          </Tooltip>
        </div>
        <div className="icon-action">
          <Tooltip
            title={
              licenceTypeAccess !== 'pro'
                ? t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_DCAC6A', {
                    defaultValue:
                      'Fonctionnalité disponible avec une licence pro seulement',
                  })
                : !isBuildingSiteCalculatorEquipmentInSelection
                ? t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_00FCFD', {
                    defaultValue: 'Supprimer les éléments selectionnés',
                  })
                : t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_22C3A5', {
                    defaultValue:
                      'La sélection contient un ou plusieurs contributeurs du chantier. La suppression de ces derniers est possible uniquement via la calculette chantier',
                  })
            }
          >
            <DeleteIcon
              style={{
                width: '20px',
                color: licenceTypeAccess !== 'pro' ? 'gray' : '',
                cursor:
                  licenceTypeAccess !== 'pro' ||
                  isBuildingSiteCalculatorEquipmentInSelection
                    ? 'not-allowed'
                    : '',
              }}
              onClick={() => {
                if (
                  !isBuildingSiteCalculatorEquipmentInSelection &&
                  licenceTypeAccess === 'pro'
                ) {
                  setOpenDialog(true);
                }
              }}
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    dispatch(updateFormValue('confirmPatchRefurbished', false));
  };

  return (
    <>
      {/* Dialog for deletion confirmation */}

      <Dialog
        open={openDialog || confirmPatchRefurbished}
        onClose={() => handleCloseDialog()}
      >
        <DialogTitle>
          {confirmPatchRefurbished
            ? t('APP.MULTIPLEACTIONSBUTTON.CONFIRM_REFURBISHED', {
                defaultValue: 'Confirmer le passage en réemploi ? ',
              })
            : t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_F57E7E', {
                defaultValue: 'Confirmer la suppression ?',
              })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {confirmPatchRefurbished
              ? t('APP.MULTIPLEACTIONSBUTTON.REFURBISHED_MODAL', {
                  defaultValue:
                    'Etes-vous surs de vouloir passer en réemploi les éléments sélectionnés (impact carbone nul) ?',
                })
              : t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_951F96', {
                  defaultValue:
                    'Etes-vous sûr de vouloir supprimer les éléments sélectionnés ?',
                })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseDialog()}
            className="close-button"
            text={t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_833CB5', {
              defaultValue: 'Annuler',
            })}
          />

          <Button
            className="delete-button"
            onClick={() =>
              confirmPatchRefurbished
                ? patchEquipmentsFromContributorList({
                    type_fiche: TYPE_FICHE.REFURBISHED,
                  })
                : handleDelete()
            }
            text={
              confirmPatchRefurbished
                ? t('APP.MULTIPLEACTIONSBUTTON.CONFIRM_REFURBISHED', {
                    defaultValue: 'Confirmer le passage en réemploi ? ',
                  })
                : t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_27FAB6', {
                    defaultValue: 'Confirmer la suppression',
                  })
            }
          />
        </DialogActions>
      </Dialog>

      {renderConfirmationDeleteModal()}
      <div className={''}>
        {!multipleActionOpen && !listEquipment && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              default: { ease: 'linear' },
            }}
            className={linesSelected.length < 2 ? 'close-button' : 'save-button'}
            style={{
              width: linesSelected.length > 0 ? '136px' : '117px',
              cursor: linesSelected.length > 0 ? 'pointer' : 'not-allowed',
              borderRadius: '10px',
              textAlign: 'center',
              fontSize: '0.8rem',
              padding: '0.5rem',
            }}
            onClick={() =>
              linesSelected.length > 0
                ? dispatch(
                    updateFormValue('multipleActionOpen', !multipleActionOpen)
                  )
                : null
            }
          >
            <Tooltip
              title={t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_FC54A2', {
                defaultValue:
                  'Pour accéder aux actions multiples, sélectionner au moins 1 ligne avec la coche',
              })}
            >
              <div>
                <span>
                  {t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_8A0ABA', {
                    defaultValue: 'Actions multiples',
                  })}
                  {allLinesChecked
                    ? '(...)'
                    : linesSelected.length > 0 && `(${linesSelected.length})`}
                </span>
              </div>
            </Tooltip>
          </motion.div>
        )}

        {!multipleActionOpen && listEquipment && renderCloseMultipleActionButtons()}
        {multipleActionOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              default: { ease: 'linear' },
            }}
            className="save-button"
            style={{
              width: listEquipment ? undefined : '117px',
              padding: '0.5rem',
              borderRadius: '10px',
            }}
          >
            {listEquipment ? (
              renderOpenActionsForContributorList(t)
            ) : (
              <div className="wrapper-multiple-actions">
                <div className="icon-action">
                  <Popover
                    trigger="click"
                    content={popoverMdContent}
                    visible={mdActionOpen}
                  >
                    <Tooltip
                      title={t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_1F332A', {
                        defaultValue:
                          'Associer une famille d’équipements aux éléments sélectionnés',
                      })}
                    >
                      <img
                        src={mdAction}
                        onClick={() =>
                          dispatch(updateFormValue('mdActionOpen', true))
                        }
                        alt=""
                      />
                    </Tooltip>
                  </Popover>
                </div>
                <div className="icon-action">
                  <Tooltip
                    title={t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_8F17A4', {
                      defaultValue: 'Valider les éléments sélectionnés',
                    })}
                  >
                    <img
                      src={yellow}
                      alt=""
                      onClick={() => dispatch(patchLines('validate'))}
                    />
                  </Tooltip>
                </div>
                <div className="icon-action">
                  <Tooltip
                    title={t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_13C1B8', {
                      defaultValue: 'Supprimer les éléments selectionnés',
                    })}
                  >
                    <DeleteIcon
                      onClick={() =>
                        dispatch(updateFormValue('multipleDeletionModal', true))
                      }
                    />
                  </Tooltip>
                </div>
                <div
                  className="icon-action"
                  onClick={() =>
                    dispatch(updateFormValue('multipleActionOpen', false))
                  }
                >
                  <Tooltip
                    title={t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_320320', {
                      defaultValue:
                        'Fermer le panneau de gestion d’actions multiples',
                    })}
                  >
                    <CancelIcon />
                  </Tooltip>
                </div>
              </div>
            )}
          </motion.div>
        )}
      </div>
    </>
  );
};

export default MultipleActionsButton;
