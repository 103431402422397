import './line.scss';
// import 'antd/dist/antd.css';
import {
  ALLOTMENT_IDS,
  REGULATORY_PROJECTS,
} from '../../../globalVariable/typeProject';
import { Checkbox, Popover } from 'antd';
import { ItemTypes } from '../../../models/DragConstants';
import { ModalNooco, SearchGroup } from '@photocarbone/nooco-ui';
import { ProgressBar } from 'react-bootstrap';
import { ScissorOutlined } from '@ant-design/icons';
import { TYPE_EPCM, TYPE_RE2020 } from '../../../globalVariable/typeProject';
import { canImportRowBeAddedInProject } from '../ImportFunction';
import {
  findAllotmentNodesForNewBundle,
  findNodeByCurrentAllotment,
  findParentNode,
  handleKeyPress,
  handlePaste,
  returnDefaultValueForSearchGroupSelectType,
} from '../../../shared/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import API from '../../../services/API';
import AddIcon from '@material-ui/icons/Add';
import ArchiveIcon from '@material-ui/icons/Archive';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from '@material-ui/core/Button';
import Calculator from 'awesome-react-calculator';
import CaracsBlock from './CaracsBlock';
import DeleteIcon from '@material-ui/icons/Delete';
import DisplayLineError from './DisplayLineError';
import LineImportButton from './LineImportButton';
import React from 'react';
import ReemployCheck from '../../../shared/ReemployCheck';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@mui/material/Tooltip';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import calculatorPic from '../../../../assets/images/import/calculator.png';
import commentPic from '../../../../assets/images/import/comment.png';
import idee from '../../../../assets/images/import/idee.png';
import macro from '../../../../assets/images/bundle/macro.svg';
import okPic from '../../../../assets/images/import/Eo_circle_yellow_checkmark.svg';
import penPic from '../../../../assets/images/import/pen.svg';
import quantity from '../../../../assets/images/import/quantity.png';
import warningLogo from '../../../../assets/images/import/exclamation.png';
import { useTranslation } from 'react-i18next';
import goodPic from '../../../../assets/images/import/Eo_circle_green_checkmark.svg';
import { SEARCH_GROUP_OPTION_VALUES } from '../../../globalVariable/searchGroupType';
import { CurrentScreen } from '../../../models/GeneralDTO';

let waterOrEnergyNodes = ['energie', 'eau'];
let api = new API();

const LineComponent = ({
  groups,
  deleteRowsImport,
  certifiedAllotments,
  searchBarOptions,
  verifyRowsEquipment,
  currentProject,
  updateFormValue,
  allGroups,
  cutLines,
  currentAllotment,
  urbanNetworks,
  loadingSwitch,
  linesSelected,
  index,
  line,
  copyLine,
  handleCopyLine,
  handleLineCheckbox,
  handleEdit,
  setCalculatorsOpen,
  calculatorsOpen,
  allDatas,
  openAdvance = [],
  setOpenAdvance,
  loadingProcess,
  setSnackbarMessage,
  setLoadingProcess,
  setCopyLine,
  CustomPaper,
  lines,
}) => {
  const [openModal, setOpenModal] = React.useState(false);
  const { nodesAvailableForProjectType, bundleOptions } = useSelector(
    (state) => state.projects
  );
  const { allLinesChecked, exceptLinesFromMultipleActions } = useSelector(
    (state) => state.imports
  );

  const isRegulatoryProject = REGULATORY_PROJECTS.includes(
    currentProject.templateType
  );
  const searchGroupOptions = [
    SEARCH_GROUP_OPTION_VALUES.EQUIPEMENTS,
    SEARCH_GROUP_OPTION_VALUES.INIES,
    SEARCH_GROUP_OPTION_VALUES.ENERGIE,
    SEARCH_GROUP_OPTION_VALUES.EAU,
  ];
  if (!lines) {
    searchGroupOptions.push(SEARCH_GROUP_OPTION_VALUES.ENSEMBLES);
  }
  if (!isRegulatoryProject && !lines) {
    searchGroupOptions.push(SEARCH_GROUP_OPTION_VALUES.PRODUITS);
  }

  const { t } = useTranslation();

  const dispatch = useDispatch();

  let certificationKeyNode =
    currentAllotment.id === 2
      ? 'Re2020AllotmentNodeId'
      : currentAllotment.id === 34
      ? 'EpcmAllotmentNodeId'
      : '';

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.ProductCard,
      drop: (item) => {
        handleEdit('sheetPepFdes', line.id, null, item);
      },
      collect: (monitor) => {
        return {
          isOver: !!monitor.isOver(),
        };
      },
    }),
    []
  );

  let unitsAllowed = [
    'U',
    'u',
    'uté',
    'Uté',
    'Unité',
    'unité',
    'unités',
    'Unités',
    'unite',
    'Unites',
    'Unite',
    'unites',
  ];

  const managementEditFluids = (newValue, key) => {
    let newFluids = line.fluid
      ? { ...line.fluid, [key]: newValue }
      : { [key]: newValue };

    handleEdit('fluids', line.id, null, newFluids);
  };

  if (lines) {
    return (
      <>
        <Tooltip
          title={
            lines.ProjectElemsDuplicate.find((el) => el.isArchived)
              ? t('APP.LINE.LINECOMPONENT.PH_F152C1', {
                  defaultValue: 'Désarchiver cette ligne pour pouvoir la modifier',
                })
              : ''
          }
          followCursor
        >
          <div
            ref={drop}
            className={`import-line ${
              copyLine?.id === line.id ? 'line-selected' : ''
            } `}
            style={{
              paddingTop: !line.name ? '10px' : null,
              background: lines.ProjectElemsDuplicate.find((el) => el.isArchived)
                ? '#d5d4d4'
                : '',
              opacity: isOver ? 0.5 : 1,
            }}
          >
            <div className="line-left">
              <div style={{ minWidth: '45%' }}>
                <div className="container-top-left">
                  <div className="line-quantity">
                    <img src={quantity} alt="" style={{ marginRight: '5px' }} />
                    {(line.originalUnit?.toLowerCase() ===
                      line.unit?.toLowerCase() ||
                      (line.originalUnit === 'm2' && line.unit === 'm²') ||
                      (line.originalUnit === 'm3' && line.unit === 'm³') ||
                      (unitsAllowed.includes(line.originalUnit) &&
                        line.unit === 'unité')) && (
                      <>
                        <p
                          style={
                            line.statusCode === 'INVALID_QUANTITY'
                              ? { color: 'red' }
                              : null
                          }
                        >
                          Quantité {line.unit ? `(${line.unit})` : null} :
                        </p>
                        <TextField
                          disabled={line.isArchived}
                          error={line.statusCode === 'INVALID_QUANTITY'}
                          className="line-quantity-input"
                          defaultValue={line.quantity}
                          onBlur={(e) =>
                            e.target.value !== line.quantity
                              ? verifyRowsEquipment({
                                  id: line.id,
                                  quantity: e.target.value,
                                })
                              : null
                          }
                        />
                      </>
                    )}
                    {line.originalUnit?.toLowerCase() !== line.unit?.toLowerCase() &&
                      !(line.originalUnit === 'm2' && line.unit === 'm²') &&
                      !(line.originalUnit === 'm3' && line.unit === 'm³') &&
                      !(
                        unitsAllowed.includes(line.originalUnit) &&
                        line.unit === 'unité'
                      ) && (
                        <>
                          <p
                            style={
                              line.statusCode === 'MD_UNIT_NOT_MATCHING'
                                ? { color: 'red' }
                                : null
                            }
                          >
                            Quantité: {line.originalQuantity}{' '}
                            <span>{line.originalUnit}</span>{' '}
                            <ArrowForwardIcon style={{ width: '20px' }} />
                          </p>
                          <>
                            <TextField
                              disabled={line.isArchived}
                              error={line.statusCode === 'INVALID_QUANTITY'}
                              className="line-quantity-input"
                              defaultValue={line.quantity}
                              onBlur={(e) =>
                                e.target.value !== line.quantity
                                  ? verifyRowsEquipment({
                                      id: line.id,
                                      quantity: e.target.value,
                                    })
                                  : null
                              }
                            />

                            <p style={{ marginLeft: '10px' }}>{line.unit} </p>
                            <Tooltip
                              title={t('APP.LINE.LINECOMPONENT.PH_5F8A54', {
                                defaultValue:
                                  'Unité à vérifier : convertir la quantité au besoin',
                              })}
                            >
                              <img
                                style={{ marginLeft: '10px', width: '20px' }}
                                src={warningLogo}
                                alt=""
                              />
                            </Tooltip>
                            <Popover
                              content={() => (
                                <div style={{ width: '24rem', height: '18rem' }}>
                                  <Calculator />
                                </div>
                              )}
                              onVisibleChange={(visible) =>
                                setCalculatorsOpen(
                                  calculatorsOpen.includes(line.id)
                                    ? [
                                        ...calculatorsOpen.filter(
                                          (el) => el !== line.id
                                        ),
                                      ]
                                    : [...calculatorsOpen, line.id]
                                )
                              }
                              visible={calculatorsOpen.includes(line.id)}
                              trigger="click"
                            >
                              <img
                                style={{ marginLeft: '10px', width: '20px' }}
                                src={calculatorPic}
                                alt=""
                              />
                            </Popover>
                          </>
                        </>
                      )}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                    width: '100%',
                  }}
                >
                  <div style={{ marginRight: '0.5em' }}>{index + 1}.</div>
                  <SearchGroup
                    t={t}
                    gridStyle={'[first] 21% [line2] 70% [line3] 29%'}
                    triggerBreadcrumb={() => {
                      updateFormValue('currentLine', line);
                      updateFormValue('openBreadcrumb', true);
                    }}
                    bundleOptions={bundleOptions}
                    findParentNode={findParentNode}
                    projectTypeId={currentProject.ProjectTypeId}
                    searchBarOptionsFiltered={searchBarOptions}
                    allAllotments={allGroups.flatAllotments}
                    certifiedAllotments={certifiedAllotments}
                    allotmentsAvailable={nodesAvailableForProjectType?.filter(
                      (el) => !el.isObsolete
                    )}
                    dropdownHeight="70px"
                    api={api}
                    dropdownProps={{
                      onSelect: () => {},
                      onChange: (value) => {
                        if (
                          value === 'equipements' ||
                          value === 'ensembles' ||
                          value === 'energie' ||
                          value === 'eau'
                        ) {
                          handleEdit('AllotmentNode', line.id, null, null, true);
                        } else if (typeof value === 'number') {
                          handleEdit(
                            'AllotmentNode',
                            line.id,
                            null,
                            allGroups.flatAllotments.find((el) => el.id === value),
                            true
                          );
                        } else {
                          if (
                            value.type_fiche === 'bundle' &&
                            !value.AllotmentNodes
                          ) {
                            value = findAllotmentNodesForNewBundle(value);
                          }
                          handleEdit('sheetPepFdes', line.id, null, value);
                        }
                      },
                      placeholder: t('APP.LINE.LINECOMPONENT.PH_FCC62E', {
                        defaultValue: 'Rechercher...',
                      }),
                      treeCheckable: false,
                      value: line.Sheet?.processedLabel
                        ? line.Sheet?.processedLabel
                        : line.Sheet?.name
                        ? line.Sheet?.name
                        : line.AllotmentNode
                        ? line.AllotmentNode?.name
                        : '',
                    }}
                    selectProps1={{
                      defaultValue: returnDefaultValueForSearchGroupSelectType(
                        line,
                        allGroups
                      ),
                      onChange: (value) => {},
                      filterOptions: searchGroupOptions,
                      style: { width: '100%' },
                      disabled: Boolean(line.isArchived),
                    }}
                    selectProps2={{
                      ListType:
                        currentAllotment.id === 1
                          ? ['Nooco']
                          : [currentAllotment.name.toUpperCase()],
                      noocoOptions: allDatas
                        ? allDatas.filter((el) => !el.isObsolete)
                        : [],
                      keyDisplay: 'name',
                      keyItem: 'id',
                      disabled: Boolean(line.isArchived),
                      onChange: (selectedOptionValue, type) => {
                        let data;

                        if (currentAllotment.id === 1) {
                          data = allDatas.find(
                            (el) => el.name === selectedOptionValue
                          );
                        } else {
                          data = allGroups.flatAllotments.find(
                            (el) =>
                              el.name === selectedOptionValue &&
                              el.AllotmentId === currentAllotment.id
                          );
                        }

                        handleEdit(
                          currentAllotment.id === 1 ? 'AllotmentNode' : 're2020lot',
                          line.id,
                          null,
                          data.details
                            ? data.details.find((el) => el.MasterDataEquipmentId)
                            : data,
                          true
                        );
                      },
                      value:
                        currentAllotment.id !== 34 && currentAllotment.id !== 2
                          ? [line.AllotmentNode]
                          : line.AllotmentNodes?.find(
                              (node) => node.AllotmentId === currentAllotment.id
                            )
                          ? [
                              line.AllotmentNodes?.find(
                                (node) => node.AllotmentId === currentAllotment.id
                              ),
                            ]
                          : line.AllotmentNode
                          ? [
                              findNodeByCurrentAllotment(
                                allGroups.flatAllotments,
                                certificationKeyNode,
                                line.AllotmentNode
                              ),
                            ]
                          : [],
                    }}
                    selectWidth="100px"
                  />
                </div>

                <div className="bottom-left-container">
                  <Tooltip
                    title={t('APP.LINE.LINECOMPONENT.PH_B06B0E', {
                      defaultValue:
                        'Rechercher un équipement/matériau par la navigation dans le catalogue Nooco',
                    })}
                  >
                    <p
                      onClick={() => {
                        updateFormValue('openBreadcrumb', true);
                        updateFormValue('currentLine', line);
                      }}
                      style={{
                        cursor: 'pointer',
                        color: '#939da4',
                        fontSize: '0.78rem',
                        fontStyle: 'italic',
                        display: 'inline-block',
                      }}
                    >
                      {t('APP.LINE.LINECOMPONENT.PH_38279A', {
                        defaultValue: 'Recherche avancée',
                      })}
                      <SearchIcon style={{ color: '#939da4', fontSize: '1.1rem' }} />
                    </p>
                  </Tooltip>
                </div>
              </div>
              <div className="line-center-left">
                {line.ProjectImportElemMatches && (
                  <div className="line-center-left-left">
                    <div
                      className={
                        !lines.ProjectElemsDuplicate.find((el) => el.isArchived)
                          ? 'title-line'
                          : null
                      }
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <img
                        style={{ display: 'inline-block', width: '20px' }}
                        src={idee}
                      />

                      <p style={{ marginLeft: '0.5em' }} className="subtitle">
                        {t('APP.LINE.LINECOMPONENT.PH_9BC77A', {
                          defaultValue: "Autres suggestions de l'algorithme",
                        })}
                      </p>
                    </div>
                    {line.ProjectImportElemMatches.length === 0 && (
                      <p style={{ marginTop: '15px', marginLeft: '5px' }}>
                        {t('APP.LINE.LINECOMPONENT.PH_B09D3C', {
                          defaultValue: 'Aucune suggestion pertinente',
                        })}
                      </p>
                    )}

                    {line.ProjectImportElemMatches.sort(function (a, b) {
                      if (a.score > b.score) {
                        return -1;
                      }

                      if (a.score < b.score) {
                        return 1;
                      }

                      return 0;
                    }).map((match, index) => {
                      const suggestion = allGroups.flatAllotments.find(
                        (el) => el.id === match.AllotmentNodeId
                      );

                      if (
                        index < 5 &&
                        match.AllotmentNodeId !== line.AllotmentNode?.id &&
                        suggestion
                      ) {
                        return (
                          <div
                            key={match.id}
                            style={{
                              cursor: line.isArchived ? null : 'pointer',
                            }}
                            className={
                              !lines.ProjectElemsDuplicate.find(
                                (el) => el.isArchived
                              )
                                ? 'suggestion suggestion-hover'
                                : 'suggestion'
                            }
                            onClick={() =>
                              handleEdit(
                                'nameMd',
                                line.id,
                                null,
                                {
                                  id: match.MasterDataEquipmentId,
                                  name: suggestion.name,
                                },
                                true
                              )
                            }
                          >
                            <p style={{ marginLeft: '5px', maxWidth: '71%' }}>
                              {suggestion.name}
                            </p>
                            <Tooltip title={`${match.score}%`}>
                              <div style={{ width: '80px' }}>
                                <ProgressBar
                                  style={{ width: '100%' }}
                                  variant={
                                    match.score <= 25
                                      ? 'danger'
                                      : match.score <= 50
                                      ? 'warning'
                                      : match.score >= 50
                                      ? 'success'
                                      : null
                                  }
                                  now={match.score}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
                <CaracsBlock
                  line={line}
                  urbanNetworks={urbanNetworks}
                  currentProject={currentProject}
                  verifyRowsEquipment={verifyRowsEquipment}
                  allGroups={allGroups}
                  managementEditFluids={managementEditFluids}
                  CustomPaper={CustomPaper}
                />
              </div>
            </div>

            {canImportRowBeAddedInProject(line) && (
              <LineImportButton
                importRow={line}
                loadingProcess={loadingProcess}
                setLoadingProcess={setLoadingProcess}
                setSnackbarMessage={setSnackbarMessage}
                groups={groups}
              />
            )}
            {line.statusCode === 'Validated' &&
              line.ProjectImportElemCaracs &&
              !line.ProjectImportElemCaracs?.find((el) => el.statusCode) && (
                <Tooltip
                  title={
                    <span style={{ display: 'block' }}>
                      {t('APP.LINE.LINECOMPONENT.PH_048008', {
                        defaultValue: 'Ligne validée.',
                      })}
                      <br />
                      {t('APP.LINE.LINECOMPONENT.PH_51609F', {
                        defaultValue: 'Cliquer ici pour le charger',
                      })}
                    </span>
                  }
                >
                  <div
                    className="line-import line-import-ok"
                    style={
                      line.isValid && !line.isArchived ? { cursor: 'pointer' } : null
                    }
                    onClick={() =>
                      !line.isArchived
                        ? handleEdit('onValidation', line.id, null, null)
                        : null
                    }
                  >
                    <img src={okPic} alt="" />
                  </div>
                </Tooltip>
              )}
            {((line.statusCode !== 'Validated' &&
              line.statusCode !== 'userValidated') ||
              line.ProjectImportElemCaracs?.find((el) => el.statusCode)) && (
              <DisplayLineError line={line} />
            )}

            <div className="line-right">
              <div className="line-actions">
                <div className="actions-top">
                  <Tooltip
                    title={t('APP.LINE.LINECOMPONENT.PH_336461', {
                      defaultValue: 'Supprimer une ligne',
                    })}
                  >
                    <DeleteIcon
                      style={{
                        cursor: line.isArchived ? 'not-allowed' : 'pointer',
                        color: '#52626A',
                        display: 'inline-block',
                        marginLeft: 'auto',
                      }}
                      onClick={() => {
                        if (!line.isArchived) deleteRowsImport(line.id);
                      }}
                    />
                  </Tooltip>
                </div>
                <div className="actions-bottom">
                  <Tooltip
                    title={t('APP.LINE.LINECOMPONENT.PH_01D3AE', {
                      defaultValue: 'Copier cet équipement/matériau',
                    })}
                  >
                    <img
                      className="filter-svg"
                      onClick={() =>
                        !copyLine && !line.isArchived
                          ? handleCopyLine(line)
                          : setCopyLine()
                      }
                      style={{
                        width: '20px',
                        cursor: !line.isArchived ? 'pointer' : null,
                      }}
                      src={penPic}
                      alt=""
                    />
                  </Tooltip>
                  <Popover
                    content={() => (
                      <div>
                        <TextField
                          onBlur={(e) => {
                            handleEdit(
                              'comment',
                              line.id,
                              null,
                              e.target.value,
                              null
                            );
                          }}
                          variant="outlined"
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          defaultValue={line.comment}
                        />
                      </div>
                    )}
                    trigger="click"
                    title={t('APP.LINE.LINECOMPONENT.PH_EA691E', {
                      defaultValue: 'Ajouter/Modifier un commentaire',
                    })}
                  >
                    <Tooltip
                      title={
                        line.comment ||
                        t('APP.LINE.LINECOMPONENT.PH_F5E1AB', {
                          defaultValue: 'Affecter un commentaire à la ligne',
                        })
                      }
                    >
                      <img
                        className={line.comment ? 'filter-svg' : 'filter-svg-exist'}
                        style={{ width: '20px', cursor: 'pointer' }}
                        src={commentPic}
                        alt=""
                      />
                    </Tooltip>
                  </Popover>

                  <Tooltip
                    title={t('APP.LINE.LINECOMPONENT.PH_23A18B', {
                      defaultValue: 'Archiver cette ligne',
                    })}
                  >
                    {line.isArchived ? (
                      <UnarchiveIcon
                        onClick={() =>
                          verifyRowsEquipment({
                            id: line.id,
                            isArchived: !line.isArchived,
                          })
                        }
                        style={{ color: '#52626A' }}
                      />
                    ) : (
                      <ArchiveIcon
                        onClick={() =>
                          verifyRowsEquipment({
                            id: line.id,
                            isArchived: !line.isArchived,
                          })
                        }
                        style={{ color: '#52626A' }}
                      />
                    )}
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </Tooltip>
        {index === lines.ProjectElemsDuplicate.length - 1 && (
          <div
            style={{
              borderBottom: '1px solid #ccc',
              background: lines.ProjectElemsDuplicate.find((el) => el.isArchived)
                ? '#d5d4d4'
                : '',
            }}
          >
            <Button
              onClick={() => {
                cutLines(lines.ProjectElemsDuplicate[0]);
              }}
              startIcon={<AddIcon />}
              style={{
                marginLeft: '1em',
                marginBottom: '1em',
                fontSize: '0.75rem',
              }}
              disabled={lines.ProjectElemsDuplicate.find((el) => el.isArchived)}
              variant="contained"
            >
              {t('APP.LINE.LINECOMPONENT.PH_B1188A', {
                defaultValue: 'Ajouter une ligne',
              })}
            </Button>
          </div>
        )}
      </>
    );
  }

  return (
    <div ref={drop} className={`id id${line.id}`}>
      <ModalNooco
        title={t('APP.LINE.LINECOMPONENT.PH_86F915', {
          defaultValue: "Modification d'un ensemble",
        })}
        isOpen={openModal}
        cancelFunction={() => setOpenModal(false)}
        validateFunction={() => {
          setTimeout(() => {
            setOpenModal(false);
          }, [1000]);
          cutLines(line);
        }}
        closeOnOutsideClick={true}
        closeIcon={true}
      >
        <div>
          {t('APP.LINE.LINECOMPONENT.PH_4A337F', {
            defaultValue:
              'Vous êtes sur le point de modifier votre ensemble, vous allez créer un ensemble propre au projet, êtes-vous sûr de vouloir continuer ?',
          })}
        </div>
      </ModalNooco>
      <div
        className={`line-subcontainer ${
          copyLine?.id === line.id ? 'line-selected' : ''
        } ${line.isArchived ? 'line-archived' : ''}`}
        style={{
          marginBottom: '0.9em',
          cursor: copyLine ? 'copy' : null,
          opacity: isOver ? 0.5 : 1,
        }}
        onClick={() =>
          copyLine && copyLine.id !== line.id ? handleCopyLine(line) : null
        }
        key={line.id}
      >
        <Tooltip
          title={
            line.isArchived
              ? t('APP.LINE.LINECOMPONENT.PH_D559C1', {
                  defaultValue: 'Désarchiver cette ligne pour pouvoir la modifier',
                })
              : ''
          }
          followCursor
        >
          <div
            className="import-line"
            style={!line.name ? { paddingTop: '10px' } : null}
          >
            <div className="line-left">
              <div style={{ minWidth: '40%' }}>
                {(line.name || line.originalRow) && (
                  <Tooltip
                    title={t('APP.LINE.LINECOMPONENT.PH_F54FCE', {
                      defaultValue: 'Libellé devis',
                    })}
                    placement="right"
                  >
                    <div
                      className={!line.isArchived ? 'title-line' : null}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '15px',
                        padding: '10px',
                      }}
                    >
                      {!line.isArchived && (
                        <Checkbox
                          key={linesSelected}
                          style={{ marginRight: '1em' }}
                          checked={linesSelected.find((el) => el === line.id)}
                          onClick={(e) =>
                            handleLineCheckbox(
                              e.target.checked,
                              line.id,
                              linesSelected,
                              allLinesChecked,
                              exceptLinesFromMultipleActions,
                              dispatch
                            )
                          }
                        />
                      )}

                      <div style={{ display: 'inline-block' }}>
                        {line.name && (
                          <span
                            style={{
                              fontSize:
                                window.screen.availHeight > 810 &&
                                window.screen.availHeight < 950
                                  ? '1rem'
                                  : window.screen.availHeight < 810
                                  ? '0.9rem'
                                  : null,
                              fontWeight: 'bold',
                            }}
                          >
                            {line.name}
                          </span>
                        )}
                        {line.originalRow && (
                          <span className="excel">
                            {t('APP.LINE.LINECOMPONENT.PH_D33030', {
                              value: line.originalRow,
                              defaultValue: '(ligne fichier: {value})',
                            })}
                          </span>
                        )}
                      </div>
                    </div>
                  </Tooltip>
                )}
                <div className="container-top-left" style={{ marginBottom: '10px' }}>
                  <div className="line-quantity">
                    <img src={quantity} alt="" style={{ marginRight: '5px' }} />
                    {(line.originalUnit?.toLowerCase() ===
                      line.unit?.toLowerCase() ||
                      (line.originalUnit === 'm2' && line.unit === 'm²') ||
                      (line.originalUnit === 'm3' && line.unit === 'm³') ||
                      (unitsAllowed.includes(line.originalUnit) &&
                        line.unit === 'unité')) && (
                      <>
                        <p
                          style={
                            line.statusCode === 'INVALID_QUANTITY'
                              ? { color: 'red' }
                              : null
                          }
                        >
                          {t('APP.LINE.LINECOMPONENT.PH_81EE9E', {
                            value: line.unit,
                            lineUnitBoolean: Boolean(line.unit),
                            defaultValue:
                              'Quantité{lineUnitBoolean, select, true {({value})} other {}} :',
                          })}
                        </p>
                        <TextField
                          onPaste={handlePaste}
                          onKeyPress={handleKeyPress}
                          disabled={line.isArchived}
                          error={line.statusCode === 'INVALID_QUANTITY'}
                          className="line-quantity-input"
                          defaultValue={line.quantity}
                          onBlur={(e) =>
                            e.target.value !== line.quantity
                              ? verifyRowsEquipment({
                                  id: line.id,
                                  quantity: e.target.value,
                                })
                              : null
                          }
                        />
                      </>
                    )}
                    {line.originalUnit?.toLowerCase() !== line.unit?.toLowerCase() &&
                      !(line.originalUnit === 'm2' && line.unit === 'm²') &&
                      !(line.originalUnit === 'm3' && line.unit === 'm³') &&
                      !(
                        unitsAllowed.includes(line.originalUnit) &&
                        line.unit === 'unité'
                      ) && (
                        <>
                          <p
                            style={
                              line.statusCode === 'MD_UNIT_NOT_MATCHING'
                                ? { color: 'red' }
                                : null
                            }
                          >
                            {t('APP.LINE.LINECOMPONENT.PH_553602', {
                              value: line.originalQuantity,
                              defaultValue: 'Quantité: {value}',
                            })}
                            <span>{line.originalUnit}</span>{' '}
                            <ArrowForwardIcon style={{ width: '20px' }} />
                          </p>
                          <>
                            <TextField
                              onKeyPress={handleKeyPress}
                              onPaste={handlePaste}
                              disabled={line.isArchived}
                              error={line.statusCode === 'INVALID_QUANTITY'}
                              className="line-quantity-input"
                              defaultValue={line.quantity}
                              onBlur={(e) =>
                                e.target.value !== line.quantity
                                  ? verifyRowsEquipment({
                                      id: line.id,
                                      quantity: e.target.value,
                                    })
                                  : null
                              }
                            />
                            <p style={{ marginLeft: '10px' }}>{line.unit} </p>
                            <Tooltip
                              title={t('APP.LINE.LINECOMPONENT.PH_3D08F4', {
                                defaultValue:
                                  'Unité à vérifier : convertir la quantité au besoin',
                              })}
                            >
                              <img
                                style={{ marginLeft: '10px', width: '20px' }}
                                src={warningLogo}
                                alt=""
                              />
                            </Tooltip>
                            <Popover
                              content={() => (
                                <div style={{ width: '24rem', height: '18rem' }}>
                                  <Calculator />
                                </div>
                              )}
                              onVisibleChange={(visible) =>
                                setCalculatorsOpen(
                                  calculatorsOpen.includes(line.id)
                                    ? [
                                        ...calculatorsOpen.filter(
                                          (el) => el !== line.id
                                        ),
                                      ]
                                    : [...calculatorsOpen, line.id]
                                )
                              }
                              visibile={calculatorsOpen.includes(line.id)}
                              trigger="click"
                            >
                              <img
                                style={{ marginLeft: '10px', width: '20px' }}
                                src={calculatorPic}
                                alt=""
                              />
                            </Popover>
                          </>
                        </>
                      )}
                  </div>
                </div>
                <div key={line.AllotmentNodeId}>
                  <Tooltip
                    title={t('APP.LINE.LINECOMPONENT.PH_28E971', {
                      defaultValue: 'Rechercher un équipement',
                    })}
                  >
                    <SearchGroup
                      t={t}
                      gridStyle={'[first] 21% [line2] 70% [line3] 29%'}
                      triggerBreadcrumb={() => {
                        updateFormValue('currentLine', line);
                        updateFormValue('openBreadcrumb', true);
                      }}
                      searchBarOptionsFiltered={searchBarOptions}
                      findParentNode={findParentNode}
                      certifiedAllotments={certifiedAllotments}
                      projectTypeId={currentProject.ProjectTypeId}
                      allAllotments={allGroups.flatAllotments}
                      allotmentsAvailable={nodesAvailableForProjectType?.filter(
                        (el) => !el.isObsolete
                      )}
                      dropdownHeight="70px"
                      api={api}
                      dropdownProps={{
                        onSelect: () => {},
                        disabled: line.isArchived,
                        onChange: (value) => {
                          if (
                            value === SEARCH_GROUP_OPTION_VALUES.EQUIPEMENTS ||
                            value === SEARCH_GROUP_OPTION_VALUES.PRODUITS ||
                            value === SEARCH_GROUP_OPTION_VALUES.ENSEMBLES ||
                            value === SEARCH_GROUP_OPTION_VALUES.ENERGIE ||
                            value === SEARCH_GROUP_OPTION_VALUES.EAU
                          ) {
                            handleEdit('AllotmentNode', line.id, null, null, true);
                          } else if (typeof value === 'number') {
                            handleEdit(
                              'AllotmentNode',
                              line.id,
                              null,
                              allGroups.flatAllotments.find((el) => el.id === value),
                              true
                            );
                          } else {
                            handleEdit('sheetPepFdes', line.id, null, value);
                          }
                        },
                        placeholder: 'Rechercher...',
                        treeCheckable: false,
                        value: line.Sheet?.processedLabel
                          ? line.Sheet?.processedLabel
                          : line.Sheet?.name
                          ? line.Sheet?.name
                          : line.AllotmentNode
                          ? line.AllotmentNode?.name
                          : '',
                      }}
                      selectProps1={{
                        defaultValue: returnDefaultValueForSearchGroupSelectType(
                          line,
                          allGroups
                        ),
                        onChange: (value) => {},
                        filterOptions: searchGroupOptions,
                        disabled: Boolean(line.isArchived),
                      }}
                      selectProps2={{
                        ListType:
                          currentAllotment.id === 1
                            ? ['Nooco']
                            : [currentAllotment.name.toUpperCase()],
                        noocoOptions: allDatas
                          ? allDatas.filter((el) => !el.isObsolete)
                          : [],
                        keyDisplay: 'name',
                        keyItem: 'id',
                        onChange: (selectedOptionValue, type) => {
                          let data;

                          if (currentAllotment.id === ALLOTMENT_IDS.NOOCO) {
                            data = allDatas.find(
                              (el) => el.name === selectedOptionValue
                            );

                            data = data.details.find(
                              (el) => el.MasterDataEquipmentId
                            );

                            if (data?.id === line.AllotmentNode?.id) {
                              return;
                            }
                          } else {
                            data = allGroups.flatAllotments.find(
                              (el) =>
                                el.name === selectedOptionValue &&
                                el.AllotmentId === currentAllotment.id
                            );
                          }

                          handleEdit(
                            currentAllotment.id === 1
                              ? 'AllotmentNode'
                              : 're2020lot',
                            line.id,
                            null,
                            data,
                            true
                          );
                        },
                        disabled: Boolean(line.isArchived),

                        value:
                          currentAllotment.id !== 34 && currentAllotment.id !== 2
                            ? [line.AllotmentNode]
                            : line.AllotmentNodes?.find(
                                (node) => node.AllotmentId === currentAllotment.id
                              )
                            ? [
                                line.AllotmentNodes?.find(
                                  (node) => node.AllotmentId === currentAllotment.id
                                ),
                              ]
                            : line.AllotmentNode
                            ? [
                                findNodeByCurrentAllotment(
                                  allGroups.flatAllotments,
                                  certificationKeyNode,
                                  line.AllotmentNode
                                ),
                              ]
                            : [],
                      }}
                      selectWidth="100px"
                    />
                  </Tooltip>
                </div>

                <div className="bottom-left-container">
                  <Popover
                    onVisibleChange={(visible) =>
                      setOpenAdvance(
                        openAdvance.includes(line.id)
                          ? [...openAdvance.filter((el) => el !== line.id)]
                          : [...openAdvance, line.id]
                      )
                    }
                    title={t('APP.LINE.LINECOMPONENT.PH_463329', {
                      defaultValue: 'Autres options',
                    })}
                    placement="bottom"
                    content={() => (
                      <div>
                        <div className="advance-option">
                          <ReemployCheck
                            updateFormValue={updateFormValue}
                            loading={loadingSwitch}
                            handleEdit={handleEdit}
                            line={line}
                            verifyRowsEquipment={verifyRowsEquipment}
                            importScreen={true}
                          />
                        </div>
                      </div>
                    )}
                    visible={openAdvance.includes(line.id)}
                    trigger="click"
                  >
                    <Tooltip
                      title={t('APP.LINE.LINECOMPONENT.PH_48BF4C', {
                        defaultValue: 'Affecter du réemploi',
                      })}
                    >
                      <p
                        style={{
                          cursor: 'pointer',
                          color: '#939da4',
                          fontSize: '0.78rem',
                          fontStyle: 'italic',
                          display: 'inline-block',
                        }}
                        className="options-advance-popover"
                      >
                        {t('APP.LINE.LINECOMPONENT.PH_0E17DE', {
                          defaultValue: 'Autres options',
                        })}
                      </p>
                    </Tooltip>
                  </Popover>
                  {/* <Tooltip title="Rechercher un équipement/matériau par la navigation dans le catalogue Nooco">
                    <p onClick={() => {
                      updateFormValue('openBreadcrumb',
                        true);
                      updateFormValue('currentLine',
                        line);
                    }} style={{ cursor: 'pointer', color: '#939da4', fontSize: '0.78rem', fontStyle: 'italic', display: 'inline-block' }}>Recherche avancée <SearchIcon style={{ color:  '#939da4', fontSize: '1.1rem'}} />  </p>
                  </Tooltip> */}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginLeft: 'auto',
                      marginRight: '4em',
                    }}
                    onClick={() => {
                      if (line.Sheet?.type_fiche === 'bundle') {
                        setOpenModal(true);
                      } else {
                        cutLines(line);
                      }
                    }}
                  >
                    {line.Sheet?.type_fiche === 'bundle' ? (
                      <img
                        src={macro}
                        alt
                        style={{
                          width: '15px',
                          height: '15px',
                          marginRight: '3px',
                        }}
                      />
                    ) : (
                      <ScissorOutlined
                        style={{
                          fontSize: '1rem',
                          cursor: 'pointer',
                          color: '#939da4',
                          display: 'block',
                          marginRight: '3px',
                          'margin-top': '2px',
                        }}
                      />
                    )}

                    <p
                      style={{
                        cursor: 'pointer',
                        color: '#939da4',
                        fontSize: '0.78rem',
                        fontStyle: 'italic',
                        display: 'inline-block',
                      }}
                    >
                      {line.Sheet?.type_fiche === 'bundle'
                        ? t('APP.LINE.LINECOMPONENT.PH_DD53DC', {
                            defaultValue: "Modifier l'ensemble",
                          })
                        : t('APP.LINE.LINECOMPONENT.PH_2FFD38', {
                            defaultValue: 'Découper un ensemble',
                          })}
                    </p>
                  </div>
                </div>
              </div>
              <div className="line-center-left">
                {line.ProjectImportElemMatches && (
                  <div className="line-center-left-left">
                    <div
                      className={!line.isArchived ? 'title-line' : null}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <img
                        style={{ display: 'inline-block', width: '20px' }}
                        src={idee}
                      />

                      <p style={{ marginLeft: '0.5em' }} className="subtitle">
                        {t('APP.LINE.LINECOMPONENT.PH_7BC9E9', {
                          defaultValue: "Autres suggestions de l'algorithme",
                        })}
                      </p>
                    </div>
                    {line.Sheet?.type_fiche === 'bundle' ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '15px',
                        }}
                      >
                        <img
                          src={macro}
                          alt
                          style={{
                            width: '15px',
                            height: '15px',
                            marginRight: '3px',
                          }}
                        />

                        <p style={{ marginLeft: '5px' }}>
                          {t('APP.LINE.LINECOMPONENT.PH_CA7D01', {
                            defaultValue: "Ligne chargée à partir d'un ensemble",
                          })}
                        </p>
                      </div>
                    ) : line.ProjectImportElemMatches.length === 0 ? (
                      <p style={{ marginTop: '15px', marginLeft: '5px' }}>
                        {t('APP.LINE.LINECOMPONENT.PH_496EFB', {
                          defaultValue: 'Aucune suggestion pertinente',
                        })}
                      </p>
                    ) : (
                      line.ProjectImportElemMatches.sort(function (a, b) {
                        if (a.score > b.score) {
                          return -1;
                        }

                        if (a.score < b.score) {
                          return 1;
                        }

                        return 0;
                      }).map((match, index) => {
                        const suggestion = allGroups.flatAllotments.find(
                          (el) => el.id === match.AllotmentNodeId
                        );

                        if (
                          index < 5 &&
                          match.AllotmentNodeId !== line.AllotmentNode?.id &&
                          suggestion
                        ) {
                          return (
                            <div
                              key={match.id}
                              style={{
                                cursor: line.isArchived ? null : 'pointer',
                              }}
                              className={
                                !line.isArchived
                                  ? 'suggestion suggestion-hover'
                                  : 'suggestion'
                              }
                              onClick={() =>
                                !line.isArchived
                                  ? handleEdit(
                                      'AllotmentNode',
                                      line.id,
                                      null,
                                      {
                                        id: match.AllotmentNodeId,
                                        name: suggestion.name,
                                      },
                                      true
                                    )
                                  : null
                              }
                            >
                              <p style={{ marginLeft: '5px', maxWidth: '71%' }}>
                                {suggestion.name}
                              </p>
                              <Tooltip title={`${match.score}%`}>
                                <div style={{ width: '80px' }}>
                                  <ProgressBar
                                    style={{ width: '100%' }}
                                    variant={
                                      match.score <= 25
                                        ? 'danger'
                                        : match.score <= 50
                                        ? 'warning'
                                        : match.score >= 50
                                        ? 'success'
                                        : null
                                    }
                                    now={match.score}
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          );
                        }
                      })
                    )}
                  </div>
                )}
                <CaracsBlock
                  line={line}
                  urbanNetworks={urbanNetworks}
                  currentProject={currentProject}
                  verifyRowsEquipment={verifyRowsEquipment}
                  allGroups={allGroups}
                  managementEditFluids={managementEditFluids}
                />
              </div>
            </div>

            {canImportRowBeAddedInProject(line) && (
              <LineImportButton
                importRow={line}
                loadingProcess={loadingProcess}
                setLoadingProcess={setLoadingProcess}
                setSnackbarMessage={setSnackbarMessage}
                groups={groups}
              />
            )}
            {line.statusCode === 'Validated' &&
              line.ProjectImportElemCaracs &&
              !line.ProjectImportElemCaracs?.find((el) => el.statusCode) && (
                <Tooltip
                  title={
                    <span style={{ display: 'block' }}>
                      {t('APP.LINE.LINECOMPONENT.PH_048008', {
                        defaultValue: 'Ligne validée.',
                      })}
                      <br />
                      {t('APP.LINE.LINECOMPONENT.PH_51609F', {
                        defaultValue: 'Cliquer ici pour le charger',
                      })}
                    </span>
                  }
                >
                  <div
                    className="line-import line-import-ok"
                    style={
                      line.isValid && !line.isArchived ? { cursor: 'pointer' } : null
                    }
                    onClick={() =>
                      !line.isArchived
                        ? handleEdit('onValidation', line.id, null, null)
                        : null
                    }
                  >
                    <img src={okPic} alt="" />
                  </div>
                </Tooltip>
              )}
            {((line.statusCode !== 'Validated' &&
              line.statusCode !== 'userValidated') ||
              line.ProjectImportElemCaracs?.find((el) => el.statusCode)) && (
              <DisplayLineError line={line} />
            )}
            <div className="line-right">
              <div className="line-actions">
                <div className="actions-top">
                  <Tooltip
                    title={t('APP.LINE.LINECOMPONENT.PH_336461', {
                      defaultValue: 'Supprimer une ligne',
                    })}
                  >
                    <DeleteIcon
                      style={{
                        cursor: line.isArchived ? 'not-allowed' : 'pointer',
                        color: '#52626A',
                        display: 'inline-block',
                        marginLeft: 'auto',
                      }}
                      onClick={() => {
                        if (!line.isArchived) deleteRowsImport(line.id);
                      }}
                    />
                  </Tooltip>
                </div>
                <div className="actions-bottom">
                  <Tooltip
                    title={t('APP.LINE.LINECOMPONENT.PH_65F977', {
                      defaultValue: 'Copier cet équipement/matériau',
                    })}
                  >
                    <img
                      className="filter-svg"
                      onClick={() =>
                        !copyLine && !line.isArchived
                          ? handleCopyLine(line)
                          : setCopyLine()
                      }
                      style={{
                        width: '20px',
                        cursor: !line.isArchived ? 'pointer' : null,
                      }}
                      src={penPic}
                      alt=""
                    />
                  </Tooltip>
                  <Popover
                    content={() => (
                      <div>
                        <TextField
                          onBlur={(e) => {
                            handleEdit(
                              'comment',
                              line.id,
                              null,
                              e.target.value,
                              null
                            );
                          }}
                          variant="outlined"
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          defaultValue={line.comment}
                        />
                      </div>
                    )}
                    trigger="click"
                    title={t('APP.LINE.LINECOMPONENT.PH_269F2E', {
                      defaultValue: 'Ajouter/Modifier un commentaire',
                    })}
                  >
                    <Tooltip
                      title={
                        line.comment ||
                        t('APP.LINE.LINECOMPONENT.PH_56E0CE', {
                          defaultValue: 'Affecter un commentaire à la ligne',
                        })
                      }
                    >
                      <img
                        className={line.comment ? 'filter-svg' : 'filter-svg-exist'}
                        style={{
                          width: '20px',
                          cursor: !line.isArchived ? 'pointer' : null,
                        }}
                        src={commentPic}
                        alt=""
                      />
                    </Tooltip>
                  </Popover>
                  <Tooltip
                    title={
                      !line.isArchived
                        ? t('APP.LINE.LINECOMPONENT.PH_B56354', {
                            defaultValue: 'Archiver cette ligne',
                          })
                        : ''
                    }
                  >
                    {line.isArchived ? (
                      <UnarchiveIcon
                        onClick={() =>
                          verifyRowsEquipment({
                            id: line.id,
                            isArchived: !line.isArchived,
                          })
                        }
                        style={{ color: '#52626A' }}
                      />
                    ) : (
                      <ArchiveIcon
                        onClick={() =>
                          verifyRowsEquipment({
                            id: line.id,
                            isArchived: !line.isArchived,
                          })
                        }
                        style={{ color: '#52626A' }}
                      />
                    )}
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default LineComponent;
