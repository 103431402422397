import 'dayjs/locale/fr';
import { Controller } from 'react-hook-form';
import { DatePicker } from 'antd';
import { Select, Tooltip } from '@material-ui/core';
import { StateDTO } from '../../../models/GeneralDTO';
import {
  TYPE_AIRPORT,
  TYPE_DATACENTER,
  TYPE_EPCM,
  TYPE_EXPLOITATION,
  TYPE_GLOBAL_MONITORING,
  TYPE_NOOCO,
  TYPE_OUTDOOR,
  TYPE_PROTOTYPE,
  TYPE_RE2020,
  TYPE_RENOVATION,
} from '../../../globalVariable/typeProject';
import {
  isDisplayAmenagementDate,
  isDisplayDdv,
  isDisplayPermitDate,
  isDisplayProjectPhase,
  optionsPhase,
} from '../SettingsSharedFunction/SettingsSharedFunction';
import { updateFormValue } from '../../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import Address from '../../../shared/Address';
import HeaderForm from '../FormElement/HeaderForm';
import InfoIcon from '@material-ui/icons/Info';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import SelectUi from '@mui/material/Select';
import TextField from '@material-ui/core/TextField';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/fr_FR';
import { useTranslation } from 'react-i18next';
import { getAntdLocale } from '../../../shared/getAntdLocale';
import CountrySelector from '../../../shared/CountrySelector';

const dateFormat = 'L';

const { RangePicker } = DatePicker;

const GeneralForm = ({ control, errors, setValue, watch }) => {
  const { currentProject } = useSelector((state: StateDTO) => state.projects);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const countryCodeInput = watch('country');

  return (
    <div className="form-wrapper">
      <HeaderForm
        title={t('APP.GENERALFORM.GENERALFORM_HEADER', {
          defaultValue: 'Informations générales',
        })}
      />
      <div className="form-main-wrapper">
        {/* name */}
        <div className="block-form">
          <div className="label-textfield">
            {t('APP.GENERALFORM.GENERALFORM.PH_7F3B12', {
              defaultValue: 'Nom du projet',
            })}
          </div>
          <Controller
            name="name"
            control={control}
            defaultValue={currentProject.name}
            rules={{
              validate: (value) => {
                let forbiddenCharacters = [
                  '/',
                  '{',
                  '^',
                  '}',
                  '%',
                  '`',
                  ']',
                  '“',
                  '>',
                  '[',
                  '~',
                  '<',
                  '#',
                  '|',
                  `\\`,
                ];

                let errors = forbiddenCharacters.filter((char) =>
                  value.includes(char)
                );

                if (errors.length === 1) {
                  return t(
                    'APP.GENERALFORM.GENERALFORM.PH_8B61B0',

                    {
                      char: forbiddenCharacters.find((char) => value.includes(char)),
                      defaultValue:
                        'Caractère {char} interdit. Veuillez svp le supprimer ou le remplacer',
                    }
                  );
                } else if (errors.length > 1) {
                  return t(
                    'APP.GENERALFORM.GENERALFORM.PH_8D9FF9',

                    {
                      chars: errors.map((el) => `${el}`),
                      defaultValue:
                        'Les caractères suivants sont interdits : {chars}. Veuillez svp les supprimer ou les remplacer',
                    }
                  );
                }

                return true;
              },
              required: t('APP.GENERALFORM.GENERALFORM.PH_68B08C', {
                defaultValue: 'Veuillez renseigner un nom',
              }),
            }}
            render={({ field }) => (
              <TextField
                {...field}
                defaultValue={currentProject.name}
                placeholder={t('APP.GENERALFORM.GENERALFORM.PH_9F965E', {
                  defaultValue: 'Nom de projet',
                })}
                variant="outlined"
                className={`input-boarding researchBar ${
                  errors?.name ? 'error' : ''
                }`}
              />
            )}
          />

          <div className="error-message-form">{errors?.name?.message}</div>
        </div>
        {/* inCharge */}
        <div className="two-inputs-row">
          <div className="one-input">
            <div className="label-textfield">
              {t('APP.GENERALFORM.GENERALFORM.PH_FFB408', {
                defaultValue: 'Responsable',
              })}
            </div>

            <Controller
              name="inCharge"
              control={control}
              defaultValue={currentProject.inCharge}
              key="inCharge"
              rules={{
                required: t('APP.GENERALFORM.GENERALFORM.PH_CE64DF', {
                  defaultValue: 'Veuillez renseigner un responsable.',
                }),
              }}
              render={({ field }) => (
                <TextField
                  placeholder="Responsable"
                  variant="outlined"
                  className={`input-boarding-semi researchBar ${
                    errors?.inCharge ? 'error' : ''
                  }`}
                  {...field}
                />
              )}
            />
            <div className="error-message-form">{errors?.inCharge?.message}</div>
          </div>
        </div>
        {/* adress */}
        <div className="two-inputs-row">
          <div className="one-input">
            <p className="label-textfield">
              {t('APP.GENERALFORM.INDEX.COUNTRY', {
                defaultValue: 'Pays',
              })}
            </p>
            <CountrySelector
              name="country"
              defaultValue={currentProject.Address?.country || 'FRA'}
              setValue={setValue}
              language={localStorage.getItem('i18nLanguage') || 'fr-FR'}
              control={control}
            />
            <p className="error-message-form">{errors?.country?.message}</p>
          </div>

          {/* adress */}
          <div className="one-input">
            <p className="label-textfield">
              {t('APP.GENERALFORM.INDEX.PH_66F28B', {
                defaultValue: 'Ville ou code postal',
              })}
            </p>
            <Address
              error={errors?.location}
              control={control}
              defaultValue={currentProject.Address}
              setValue={setValue}
              countryCode={
                countryCodeInput?.code || currentProject.Address?.citycode
              }
              settings={true}
            />

            <p className="error-message-form">{errors?.location?.message}</p>
          </div>
        </div>

        {/* others input */}
        <div className="two-inputs-row">
          {/* permitDate */}
          {isDisplayPermitDate(
            currentProject.templateType,
            currentProject.ProjectOption.showAlternativeResult
          ) && (
            <div className="one-input">
              <p className="label-textfield">
                {t('APP.GENERALFORM.GENERALFORM.PH_1F5E31', {
                  defaultValue: 'Date de permis de construire',
                })}
              </p>
              <Controller
                name="permitDate"
                control={control}
                // key={permitDateRset}
                defaultValue={dayjs(currentProject.permitDate)}
                rules={{
                  required: t('APP.GENERALFORM.GENERALFORM.PH_D07694', {
                    defaultValue:
                      'Veuillez renseigner une date de permis de construire au format JJ/MM/AAAA.',
                  }),
                }}
                render={({ field }) => (
                  <>
                    <DatePicker
                      {...field}
                      className={`permis-form ${
                        errors?.permitDate ? 'error-border-form' : ''
                      }`}
                      format={dayjs.localeData().longDateFormat('L')}
                      locale={getAntdLocale()}
                    />
                  </>
                )}
              />

              {/* <p className="error-message-form">{errors?.permitDate?.type === 're2020' ? errors?.permitDate?.type.message : errors?.permitDate?.message}</p> */}
            </div>
          )}

          {/* amenagementDate */}
          {isDisplayAmenagementDate(
            currentProject.templateType,
            currentProject.ProjectOption.showAlternativeResult
          ) && (
            <div className="one-input">
              <p className="label-textfield">{"Date de permis d'aménager"}</p>
              <Controller
                name="DatasComp.paGazObtentionDate"
                control={control}
                // key={permitDateRset}
                defaultValue={dayjs(currentProject?.DatasComp?.paGazObtentionDate)}
                rules={{
                  required: t('APP.GENERALFORM.GENERALFORM.PH_C542EE', {
                    defaultValue:
                      'Veuillez renseigner une date de permis de construire au format JJ/MM/AAAA.',
                  }),
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    picker="year"
                    className={`permis-form ${
                      errors?.DatasComp?.paGazObtentionDate
                        ? 'error-border-form'
                        : ''
                    }`}
                    format={dayjs.localeData().longDateFormat('L')}
                  />
                )}
              />

              {/* <p className="error-message-form">{errors?.permitDate?.type === 're2020' ? errors?.permitDate?.type.message : errors?.permitDate?.message}</p> */}
            </div>
          )}

          {/* exploit Date */}
          {[TYPE_EXPLOITATION, TYPE_GLOBAL_MONITORING].includes(
            currentProject.templateType
          ) && (
            <div className="one-input">
              <p className="label-textfield">
                {t('APP.GENERALFORM.GENERALFORM.PH_19DADC', {
                  defaultValue: 'Période concernée',
                })}
              </p>
              <Controller
                name="exploitContract"
                control={control}
                defaultValue={[
                  dayjs(currentProject?.exploitContract[0]?.value),
                  dayjs(currentProject?.exploitContract[1]?.value),
                ]}
                rules={{
                  required: t('APP.GENERALFORM.GENERALFORM.PH_082AE4', {
                    defaultValue: 'Veuillez renseigner une période.',
                  }),
                }}
                render={({ field }) => (
                  <RangePicker
                    {...field}
                    className={`permis-form ${
                      errors?.exploitContract ? 'error' : ''
                    }`}
                    format={dayjs.localeData().longDateFormat('L')}
                    disabled={[true, false]}
                    locale={getAntdLocale()}
                  />
                )}
              />
              {/* <p className="error-message-form">{errors?.exploitContract?.message}</p> */}
            </div>
          )}

          {/* unit Reference */}
          <div className="one-input">
            <p className="label-textfield">
              {t('APP.GENERALFORM.GENERALFORM.PH_884A05', {
                defaultValue: "Choix de l'unité de référence",
              })}
              <Tooltip
                title={t('APP.GENERALFORM.GENERALFORM.PH_6A289E', {
                  defaultValue:
                    'Tous les calculs d’impact de votre projet seront exprimés selon l’unité de référence choisie : /m² de la surface de référence du projet ou bien en total kg éq. CO2.',
                })}
              >
                <InfoIcon style={{ marginLeft: '5px' }} />
              </Tooltip>
            </p>
            <Controller
              name="refUnit"
              control={control}
              defaultValue={currentProject.refUnit}
              rules={{
                required: t('APP.GENERALFORM.GENERALFORM.PH_AB3642', {
                  defaultValue: 'Veuillez renseigner une unitée de référence.',
                }),
              }}
              render={({ field }) => (
                <SelectUi
                  {...field}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  defaultValue={currentProject.refUnit}
                  style={{ width: '100%', height: '100%' }}
                  variant="outlined"
                  className={`input-boarding researchBar ${
                    errors?.refUnit ? 'error' : ''
                  }`}
                  onChange={(e) => {
                    if (e.target.value === 'Total') {
                      e.target.value = 'total';
                    }
                    setValue('refUnit', e.target.value);
                    dispatch(updateFormValue('refUnit', e.target.value));
                  }}
                >
                  {/* Generate options dynamically based on availableUnits array */}
                  {currentProject?.ProjectType?.availableUnits?.map(
                    (unit, index) => (
                      <MenuItem
                        key={index}
                        value={unit}
                        defaultValue={unit}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        {unit}
                      </MenuItem>
                    )
                  )}
                </SelectUi>
              )}
            />

            <p className="error-message-form">{errors?.refUnit?.message}</p>
          </div>
          {/* lifespan */}
          {isDisplayDdv(currentProject.templateType) && (
            <div className="one-input">
              <p className="label-textfield">
                {t(
                  'APP.GENERALFORM.GENERALFORM.PH_709A95',

                  {
                    isExploitation: currentProject.templateType === 'exploitation',
                    defaultValue:
                      'Durée de vie {isExploitation, select, true {du bâtiment} other {}}',
                  }
                )}
                {currentProject.templateType === 'exploitation' && (
                  <Tooltip
                    title={t('APP.GENERALFORM.GENERALFORM.PH_C81FE3', {
                      defaultValue: 'Saisir la durée de vie restante du bâtiment.',
                    })}
                  >
                    <InfoIcon style={{ marginLeft: '5px' }} />
                  </Tooltip>
                )}
              </p>

              <Controller
                name="ddv"
                control={control}
                defaultValue={currentProject.ddv}
                rules={{
                  required: t('APP.GENERALFORM.GENERALFORM.PH_0AECE9', {
                    defaultValue: 'Veuillez renseigner une période.',
                  }),
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder={t('APP.GENERALFORM.GENERALFORM.PH_74EDC2', {
                      defaultValue: 'Durée de vie du bâtiment',
                    })}
                    variant="outlined"
                    className={`input-boarding researchBar ${
                      errors?.ddv ? 'error-border-form error' : ''
                    }`}
                  />
                )}
              />

              {/* <p className="error-message-form">{errors?.ddv?.message}</p> */}
            </div>
          )}

          {/* projectPhase */}
          {isDisplayProjectPhase(
            currentProject.templateType,
            currentProject.ProjectOption.showAlternativeResult
          ) && (
            <div className="one-input">
              <p className="label-textfield">
                {t('APP.GENERALFORM.GENERALFORM.PH_891F7F', {
                  defaultValue: 'Phase',
                })}
              </p>
              <Controller
                name="PhaseId"
                control={control}
                rules={{
                  required: t('APP.GENERALFORM.GENERALFORM.PH_F242EF', {
                    defaultValue: 'Veuillez renseigner une phase.',
                  }),
                }}
                defaultValue={currentProject.PhaseId}
                render={({ field }) => (
                  <Select
                    {...field}
                    variant="outlined"
                    className={`input-boarding researchBar ${
                      errors?.PhaseId ? 'error' : ''
                    }`}
                  >
                    {optionsPhase(currentProject.templateType).map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <p className="error-message-form">{errors?.projectPhase?.message}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GeneralForm;
