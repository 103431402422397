/**
 * Created by nanaa on 26/05/2021
 */

export const FORMAT_SHEETS = 'FORMAT_SHEETS';
export const FILTER_EQUIPMENTS = 'FILTER_EQUIPMENTS';
export const HANDLE_SHOW_ADD_EQUIP_MODAL = 'HANDLE_SHOW_ADD_EQUIP_MODAL';
export const HANDLE_BREADCRUMB_INFOS = 'HANDLE_BREADCRUMB_INFOS';
export const HANDLE_HIDE_SHOW_ADD_EQUIP_MODAL = 'HANDLE_HIDE_SHOW_ADD_EQUIP_MODAL';
export const FETCH_EQUIPMENT_CARACS = 'FETCH_EQUIPMENT_CARACS';
export const FETCH_EQUIPMENT_CARACS_OK = 'FETCH_EQUIPMENT_CARACS_OK';
export const HANDLE_NEW_EQUIP_CARAC_CHANGE = 'HANDLE_NEW_EQUIP_CARAC_CHANGE';
export const HANDLE_QUANTITY_CHANGE = 'HANDLE_QUANTITY_CHANGE';
export const POST_NEW_EQUIPMENT = 'POST_NEW_EQUIPMENT';
export const SET_ID_ASKER = 'SET_ID_ASKER';
export const DISPLAY_DED_MODAL = 'DISPLAY_DED_MODAL';
export const CLOSE_DED_MODAL = 'CLOSE_DED_MODAL';
export const SET_LOADING_DED = 'SET_LOADING_DED';
export const SEND_REQUEST_DED = 'SEND_REQUEST_DED';
export const REQUEST_DED_SUCCESS = 'REQUEST_DED_SUCCESS';
export const HIDE_SUCCESS_MESSAGE = 'HIDE_SUCCESS_MESSAGE';
export const ASKING_IS_DED = 'ASKING_IS_DED';
export const SET_NOMENCLATURE_INIES = 'SET_NOMENCLATURE_INIES';
export const OPEN_IMPORT_MODAL = 'OPEN_IMPORT_MODAL';
export const CHANGE_VALUE_EQUIPMENTS = 'CHANGE_VALUE_EQUIPMENTS';
export const FETCH_INIES_EQUIPMENTS = 'FETCH_INIES_EQUIPMENTS';
export const LOGIN_EDEC = 'LOGIN_EDEC';
export const DOWNLOAD_EDEC_FILE = 'DOWNLOAD_EDEC_FILE';
export const UPDATE_NEW_INIES = 'UPDATE_NEW_INIES';
export const UPDATE_DATASINIES = 'UPDATE_DATASINIES';
export const CHANGE_MODELING_EQUIPMENTS = 'CHANGE_MODELING_EQUIPMENTS';
export const UPDATE_EQUIPMENTS_ADD_LINE = 'UPDATE_EQUIPMENTS_ADD_LINE';

export const updateEquipmentsAddLine = (name, value) => ({
  type: UPDATE_EQUIPMENTS_ADD_LINE,
  name,
  value,
});

export const formatSheets = (projectId) => {
  return {
    type: FORMAT_SHEETS,
    projectId,
  };
};

export const filterEquipments = (lots, masterDatas, projectId) => {
  return {
    type: FILTER_EQUIPMENTS,
    lots,
    masterDatas,
    projectId,
  };
};

export const handleShowAddEquipModal = () => {
  return {
    type: HANDLE_SHOW_ADD_EQUIP_MODAL,
  };
};

export const handleHideAddEquipModal = () => {
  return {
    type: HANDLE_HIDE_SHOW_ADD_EQUIP_MODAL,
  };
};

export const handleBreadCrumbInfos = (infos) => {
  return {
    type: HANDLE_BREADCRUMB_INFOS,
    infos,
  };
};

export const fetchEquipmentCaracList = (infos) => {
  return {
    type: FETCH_EQUIPMENT_CARACS,
    infos,
  };
};

export const handleFetchEquipmentCaracListSuccess = (response) => {
  return {
    type: FETCH_EQUIPMENT_CARACS_OK,
    response,
  };
};

export const handleNewEquipCaracChange = (caracId, newValue) => {
  return {
    type: HANDLE_NEW_EQUIP_CARAC_CHANGE,
    caracId,
    newValue,
  };
};

export const handleQuantityChange = (newValue) => {
  return {
    type: HANDLE_QUANTITY_CHANGE,
    newValue,
  };
};

export const postNewEquipment = (body, projectId) => {
  return {
    type: POST_NEW_EQUIPMENT,
    body,
    projectId,
  };
};

export const setIdAsker = (id, addEquipment) => {
  return {
    type: SET_ID_ASKER,
    id,
    addEquipment,
  };
};

export const displayDedModal = () => ({
  type: DISPLAY_DED_MODAL,
});

export const closeDedModal = () => ({
  type: CLOSE_DED_MODAL,
});

export const setLoadingDed = (bool) => ({
  type: SET_LOADING_DED,
  bool,
});

export const sendRequestDed = () => ({
  type: SEND_REQUEST_DED,
});

export const requestDedSuccess = () => ({
  type: REQUEST_DED_SUCCESS,
});

export const hideSuccessMessage = () => ({
  type: HIDE_SUCCESS_MESSAGE,
});

export const askingIsDed = (row) => ({
  type: ASKING_IS_DED,
  row,
});

export const setNomenclatureInies = (data) => ({
  type: SET_NOMENCLATURE_INIES,
  data,
});

export const openImportModal = () => ({
  type: OPEN_IMPORT_MODAL,
});

export const changeValueEquipments = (name, value) => ({
  type: CHANGE_VALUE_EQUIPMENTS,
  name,
  value,
});

export const fetchIniesEquipments = (value) => ({
  type: FETCH_INIES_EQUIPMENTS,
  value,
});

export const loginEdec = () => ({
  type: LOGIN_EDEC,
});

export const downloadEdecFile = () => ({
  type: DOWNLOAD_EDEC_FILE,
});

export const updateNewInies = (sheet) => ({
  type: UPDATE_NEW_INIES,
  sheet,
});

export const updateDatasinies = (payload) => ({
  type: 'UPDATE_DATASINIES',
  payload,
});

export const changeModelingEquipments = (name, value) => ({
  type: 'CHANGE_MODELING_EQUIPMENTS',
  name,
  value,
});
