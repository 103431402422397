import { Button } from '@photocarbone/nooco-ui';
import { GarbageDTO, NewActionDTO } from '../../../../models/ExploitationDTO';
import { StateDTO } from '../../../../models/GeneralDTO';
import { createAction } from '../../../../../actions/exploitation';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import D3E from '../../../../../assets/images/exploitation/garbage/D3E.png';
import DIB from '../../../../../assets/images/exploitation/garbage/DIB.png';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@mui/material/MenuItem';
import RangePickerCustom from '../../../../components/RangePicker';
import React, { useState } from 'react';
import SelectUi from '@mui/material/Select';
import TextField from '@material-ui/core/TextField';

import add from '../../../../../assets/images/exploitation/add.png';
import canette from '../../../../../assets/images/exploitation/garbage/canette.png';
import carton from '../../../../../assets/images/exploitation/garbage/carton.png';
import garbage from '../../../../../assets/images/exploitation/garbage.png';
import gobelet from '../../../../../assets/images/exploitation/garbage/gobelet.png';
import organique from '../../../../../assets/images/exploitation/garbage/organique.png';
import palette from '../../../../../assets/images/exploitation/garbage/palette.png';
import paper from '../../../../../assets/images/exploitation/garbage/paper.png';
import plastique from '../../../../../assets/images/exploitation/garbage/plastique.png';
import verre from '../../../../../assets/images/exploitation/garbage/verre.png';

import './garbage.scss';
import { AllotmentNodeDTO } from '../../../../models/ProjectDTO';
import { MasterDataEquipmentDTO } from '../../../../models/SimulatorDTO';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface GarbageState {
  newAction: NewActionDTO[];
  updateExploitationValue: Function;
  allGroups: {
    type: MasterDataEquipmentDTO[];
    flatAllotments: AllotmentNodeDTO[];
  };
  resetAddAction: Function;
}

const Garbage = ({
  newAction,
  updateExploitationValue,
  allGroups,
  resetAddAction,
}: GarbageState) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  let groups = [...allGroups.type];

  const { garbageLines } = useSelector((state: StateDTO) => state.exploitation);
  const { flatAllotments } = useSelector((state: StateDTO) => state.exploitation);

  const dispatch = useDispatch();

  const handleEditGarbage = (newValue: GarbageDTO): void => {
    let allot = allGroups.flatAllotments.find(
      (el) => el.MasterDataEquipmentId === newValue.MasterDataEquipmentId
    );

    const newLines = garbageLines.map((el: GarbageDTO) => {
      if (el.id === newValue.id) {
        return { ...newValue, AllotmentNode: allot };
      }

      return el;
    });

    updateExploitationValue('garbageLines', newLines);
  };

  const addNewGarbage = (
    defaultMasterDataEquipmentId: number | null,
    caracs: [] | undefined
  ): void => {
    let allot = allGroups.flatAllotments.find(
      (el) => el.MasterDataEquipmentId === defaultMasterDataEquipmentId
    );

    const newLines = [
      ...garbageLines,
      {
        id: Math.max(...garbageLines.map((el) => el.id)) + 1,
        MasterDataEquipmentId: defaultMasterDataEquipmentId,
        AllotmentNode: allot,
        caracs,
        quantity: null,
        unit: t('APP.GARBAGE.INDEX.PH_C46389', { defaultValue: 'tonnes/an' }),
        treatment: null,
        actionType: 'garbage',
      },
    ];

    updateExploitationValue('garbageLines', newLines);
  };

  return (
    <>
      <div className="garbage-wrapper">
        <div className="header-actions">
          <img src={garbage} alt="garbage" />
          <h4>{t('APP.GARBAGE.INDEX.PH_A82A65', { defaultValue: 'Déchets' })}</h4>
        </div>
        <div className="garbage-core">
          <div className="garbage-range">
            <RangePickerCustom
              label={t('APP.GARBAGE.INDEX.PH_9A7876', {
                defaultValue: 'Periode concernée',
              })}
            />
          </div>
          <div className="garbage-items">
            <div
              className="garbage-item"
              // TODO_TRAD : conditionnal "carton"
              onClick={() =>
                !garbageLines.find((el) => !el.MasterDataEquipmentId)
                  ? addNewGarbage(groups.find((el) => el.name === 'Carton')?.id)
                  : handleEditGarbage({
                      ...garbageLines.find((el) => !el.MasterDataEquipmentId),
                      MasterDataEquipmentId: groups.find(
                        (el) => el.name === 'Carton' && el.lot === 'Déchets'
                      )?.id,
                    })
              }
            >
              <img src={carton} alt="" />
              <p>{t('APP.GARBAGE.INDEX.PH_0F4FE8', { defaultValue: 'Carton' })}</p>
            </div>
            <div
              className="garbage-item"
              onClick={() =>
                !garbageLines.find((el) => !el.MasterDataEquipmentId)
                  ? addNewGarbage(groups.find((el) => el.name === 'Carton')?.id)
                  : handleEditGarbage({
                      ...garbageLines.find((el) => !el.MasterDataEquipmentId),
                      MasterDataEquipmentId: groups.find(
                        (el) => el.name === 'Carton' && el.lot === 'Déchets'
                      )?.id,
                    })
              }
            >
              <img src={gobelet} alt="" />
              <p>
                {t('APP.GARBAGE.INDEX.PH_55CEBE', {
                  defaultValue: 'Gobelet en carton',
                })}
              </p>
            </div>
            <div
              className="garbage-item"
              onClick={() =>
                !garbageLines.find((el) => !el.MasterDataEquipmentId)
                  ? addNewGarbage(groups.find((el) => el.name === 'Palette')?.id)
                  : handleEditGarbage({
                      ...garbageLines.find((el) => !el.MasterDataEquipmentId),
                      MasterDataEquipmentId: groups.find(
                        (el) => el.name === 'Palette'
                      )?.id,
                    })
              }
            >
              <img src={palette} alt="" />
              <p>{t('APP.GARBAGE.INDEX.PH_74E0DF', { defaultValue: 'Palette' })}</p>
            </div>
            <div
              className="garbage-item"
              onClick={() =>
                !garbageLines.find((el) => !el.MasterDataEquipmentId)
                  ? addNewGarbage(groups.find((el) => el.name === 'Carton')?.id)
                  : handleEditGarbage({
                      ...garbageLines.find((el) => !el.MasterDataEquipmentId),
                      MasterDataEquipmentId: groups.find(
                        (el) => el.name === 'Carton'
                      )?.id,
                    })
              }
            >
              <img src={paper} alt="" />
              <p>{t('APP.GARBAGE.INDEX.PH_90F440', { defaultValue: 'Papier' })}</p>
            </div>
            <div
              className="garbage-item"
              onClick={() =>
                !garbageLines.find((el) => !el.MasterDataEquipmentId)
                  ? addNewGarbage(
                      groups.find((el) => el.name === 'Plastique PET bouteille')?.id
                    )
                  : handleEditGarbage({
                      ...garbageLines.find((el) => !el.MasterDataEquipmentId),
                      MasterDataEquipmentId: groups.find(
                        (el) => el.name === 'Plastique PET bouteille'
                      )?.id,
                    })
              }
            >
              <img src={plastique} alt="" />
              <p>
                {t('APP.GARBAGE.INDEX.PH_26C3FA', {
                  defaultValue: 'Gobelet/Bouteille plastique PET',
                })}
              </p>
            </div>
            <div
              className="garbage-item"
              onClick={() =>
                !garbageLines.find((el) => !el.MasterDataEquipmentId)
                  ? addNewGarbage(groups.find((el) => el.name === 'Aluminium')?.id)
                  : handleEditGarbage({
                      ...garbageLines.find((el) => !el.MasterDataEquipmentId),
                      MasterDataEquipmentId: groups.find(
                        (el) => el.name === 'Aluminium'
                      )?.id,
                    })
              }
            >
              <img src={canette} alt="" />
              <p>
                {t('APP.GARBAGE.INDEX.PH_4B032A', {
                  defaultValue: 'Canette/Capsule alu',
                })}
              </p>
            </div>
            <div
              className="garbage-item"
              onClick={() =>
                !garbageLines.find((el) => !el.MasterDataEquipmentId)
                  ? addNewGarbage(
                      groups.find((el) => el.name === 'Déchet organique')?.id
                    )
                  : handleEditGarbage({
                      ...garbageLines.find((el) => !el.MasterDataEquipmentId),
                      MasterDataEquipmentId: groups.find(
                        (el) => el.name === 'Déchet organique'
                      )?.id,
                    })
              }
            >
              <img src={organique} alt="" />
              <p>
                {t('APP.GARBAGE.INDEX.PH_680FB0', {
                  defaultValue: 'Organique',
                })}
              </p>
            </div>
            <Tooltip
              title={t('APP.GARBAGE.INDEX.PH_34D18F', {
                defaultValue:
                  "Déchet d'Equipements Electriques et Electroniques (DEEE)",
              })}
            >
              <div
                className="garbage-item"
                onClick={() =>
                  !garbageLines.find((el) => !el.MasterDataEquipmentId)
                    ? addNewGarbage(
                        groups.find((el) =>
                          el.name?.includes(
                            "Déchet d'Equipements Electriques et Electroniques"
                          )
                        )?.id,
                        [{ name: 'Mode de traitement', value: ['non connu'] }]
                      )
                    : handleEditGarbage({
                        ...garbageLines.find((el) => !el.MasterDataEquipmentId),
                        MasterDataEquipmentId: groups.find((el) =>
                          el.name?.includes(
                            "Déchet d'Equipements Electriques et Electroniques"
                          )
                        )?.id,
                        caracs: [
                          { name: 'Mode de traitement', value: ['non connu'] },
                        ],
                      })
                }
              >
                <img src={D3E} alt="" />
                <p>D3E</p>
              </div>
            </Tooltip>
            <Tooltip
              title={t('APP.GARBAGE.INDEX.PH_F0DEF7', {
                defaultValue: 'Déchet Industriel Banal (DIB)',
              })}
            >
              <div
                className="garbage-item"
                onClick={() =>
                  !garbageLines.find((el) => !el.MasterDataEquipmentId)
                    ? addNewGarbage(
                        groups.find((el) =>
                          el.name?.includes('Déchet industriel banal')
                        )?.id,
                        [{ name: 'Mode de traitement', value: ['non connu'] }]
                      )
                    : handleEditGarbage({
                        ...garbageLines.find((el) => !el.MasterDataEquipmentId),
                        MasterDataEquipmentId: groups.find((el) =>
                          el.name?.includes('Déchet industriel banal')
                        )?.id,
                        caracs: [
                          { name: 'Mode de traitement', value: ['non connu'] },
                        ],
                      })
                }
              >
                <img src={DIB} alt="" />
                <p>{t('APP.GARBAGE.INDEX.PH_B54162', { defaultValue: 'DIB' })}</p>
              </div>
            </Tooltip>
            <div
              className="garbage-item"
              onClick={() =>
                !garbageLines.find((el) => !el.MasterDataEquipmentId)
                  ? addNewGarbage(groups.find((el) => el.name === 'Verre')?.id)
                  : handleEditGarbage({
                      ...garbageLines.find((el) => !el.MasterDataEquipmentId),
                      MasterDataEquipmentId: groups.find((el) => el.name === 'Verre')
                        ?.id,
                    })
              }
            >
              <img src={verre} alt="" />
              <p>Verre</p>
            </div>
          </div>
        </div>
        <div className="garbage-add">
          <div className="garbage-lines-header">
            <p style={{ width: '27%', paddingRight: '30px' }}>
              {t('APP.GARBAGE.INDEX.PH_95075D', {
                defaultValue: 'Type de déchets',
              })}
            </p>
            <p style={{ width: '18%' }}>
              {t('APP.GARBAGE.INDEX.PH_330F58', { defaultValue: 'Quantité' })}
            </p>
            <p style={{ width: '10%' }}>
              {t('APP.GARBAGE.INDEX.PH_A48A56', { defaultValue: 'Unité' })}
            </p>
            <p style={{ width: '17%' }}>
              {t('APP.GARBAGE.INDEX.PH_69B050', {
                defaultValue: 'Mode de traitement',
              })}
            </p>
            <p style={{ width: '3%' }}></p>
          </div>
          <div className="garbage-lines">
            {garbageLines.map((el, i) => (
              <div className="garbage-line" key={i}>
                <div style={{ position: 'absolute', left: '3px' }}>
                  {i === garbageLines.length - 1 && (
                    <img
                      onClick={() => addNewGarbage(null)}
                      style={{
                        width: '25px',
                        height: '25px',
                        marginTop: '10px',
                        marginRight: '20px',
                        cursor: 'pointer',
                      }}
                      src={add}
                    />
                  )}
                </div>
                <div style={{ width: '27%' }}>
                  <Autocomplete
                    value={groups.find((ele) => ele.id === el.MasterDataEquipmentId)}
                    key={el.MasterDataEquipmentId}
                    id="checkboxes-tags-demo"
                    options={groups.filter((el) => el.lot === 'Déchets')}
                    noOptionsText={t('APP.GARBAGE.INDEX.PH_B9CAEE', {
                      defaultValue: 'Aucun résultat trouvé',
                    })}
                    onChange={(event, newValue) => {
                      handleEditGarbage({
                        ...el,
                        MasterDataEquipmentId: newValue?.id,
                      });
                    }}
                    size={'small'}
                    getOptionLabel={(option) => option.name || ''}
                    renderInput={(params) => (
                      <TextField
                        style={{ borderRadius: '10px' }}
                        {...params}
                        placeholder={t('APP.GARBAGE.INDEX.PH_B9A236', {
                          defaultValue: 'Ajouter un déchet',
                        })}
                        variant="outlined"
                        className="researchBar"
                      />
                    )}
                  />
                </div>
                <div style={{ width: '18%' }}>
                  <TextField
                    value={el.quantity}
                    onChange={(e) =>
                      handleEditGarbage({
                        ...el,
                        quantity: Number(e.target.value),
                      })
                    }
                    type="number"
                    style={{ width: '100%' }}
                    placeholder={t('APP.GARBAGE.INDEX.PH_F6B57A', {
                      defaultValue: 'Quantité',
                    })}
                    variant="outlined"
                    className="input-boarding researchBar"
                  />
                </div>
                <div style={{ width: '10%' }}>
                  <p
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {t('APP.GARBAGE.INDEX.PH_C39FF5', {
                      defaultValue: 'tonnes',
                    })}
                  </p>
                </div>
                <div style={{ width: '17%' }}>
                  <SelectUi
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    onChange={(e) =>
                      handleEditGarbage({
                        ...el,
                        caracs: [
                          {
                            name: 'Mode de traitement',
                            value: [e.target.value],
                          },
                        ],
                      })
                    }
                    defaultValue={t('APP.GARBAGE.INDEX.PH_54BE90', {
                      defaultValue: 'Traitement',
                    })}
                    style={{ width: '100%', height: '40px' }}
                    disabled={
                      el.MasterDataEquipmentId ===
                        groups.find((el) =>
                          el.name?.includes(
                            "Déchet d'Equipements Electriques et Electroniques"
                          )
                        )?.id ||
                      el.MasterDataEquipmentId ===
                        groups.find((el) =>
                          el.name?.includes('Déchet industriel banal')
                        )?.id
                    }
                    placeholder="  -"
                    variant="outlined"
                    className="input-boarding researchBar"
                    value={
                      el.caracs && el.caracs[0].value ? el.caracs[0].value[0] : null
                    }
                  >
                    {
                      el.MasterDataEquipmentId ===
                        groups.find((el) => el.name === 'Déchet organique')?.id && (
                        <MenuItem value="compostage industriel">
                          {t('APP.GARBAGE.INDEX.PH_652CAC', {
                            defaultValue: 'Compostage industriel',
                          })}
                        </MenuItem>
                      )
                      // <MenuItem value={'méthanisation'}>Méthanisation</MenuItem>
                    }
                    {el.MasterDataEquipmentId ===
                      groups.find((el) => el.name === 'Déchet organique')?.id && (
                      <MenuItem value={'méthanisation'}>
                        {t('APP.GARBAGE.INDEX.PH_952078', {
                          defaultValue: 'Méthanisation',
                        })}
                      </MenuItem>
                    )}

                    <MenuItem
                      value={'incinération'}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {t('APP.GARBAGE.INDEX.PH_11C413', {
                        defaultValue: 'Incinération',
                      })}
                    </MenuItem>
                    <MenuItem
                      value={'non connu'}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {t('APP.GARBAGE.INDEX.PH_2D2A03', {
                        defaultValue: 'Non connu',
                      })}
                    </MenuItem>
                    {!(
                      el.MasterDataEquipmentId ===
                      groups.find((el) => el.name === 'Déchet organique')?.id
                    ) &&
                      !(
                        el.MasterDataEquipmentId ===
                        groups.find((el) => el.name === 'Palette')?.id
                      ) && (
                        <MenuItem
                          value={'recyclage'}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          {t('APP.GARBAGE.INDEX.PH_8816FB', {
                            defaultValue: 'Recyclage',
                          })}
                        </MenuItem>
                      )}

                    <MenuItem
                      value={'stockage'}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {t('APP.GARBAGE.INDEX.PH_015CFF', {
                        defaultValue: 'Stockage',
                      })}
                    </MenuItem>
                  </SelectUi>
                </div>
                <div
                  style={{
                    width: '2%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '10px',
                  }}
                >
                  <DeleteIcon
                    onClick={() =>
                      updateExploitationValue(
                        'garbageLines',
                        garbageLines.filter((ele) => ele.id !== el.id)
                      )
                    }
                    style={{
                      display: garbageLines.length > 1 ? 'block' : 'none',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', marginLeft: 'auto', width: '15%' }}>
        <Button
          className="close-button"
          style={{
            fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
            marginRight: '10px',
          }}
          onClic={() => resetAddAction()}
          text={t('APP.GARBAGE.INDEX.PH_DB6BCD', { defaultValue: 'Fermer' })}
        />

        <Button
          style={{
            fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
            marginRight: '10px',
          }}
          disabled={
            !newAction.date ||
            garbageLines.find(
              (el) => !el.quantity || !el.MasterDataEquipmentId || !el.caracs
            )
              ? true
              : false
          }
          loading={isLoading}
          onClick={() => {
            dispatch(createAction(t, true));
            setIsLoading(true);
          }}
          className="purple-button"
          text={t('APP.GARBAGE.INDEX.PH_20EB37', { defaultValue: 'Ajouter' })}
        />
      </div>
    </>
  );
};

export default Garbage;
