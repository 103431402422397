import React from 'react';

const Spinner = ({ size = '' }) => {
  if (size === 'small') {
    size = 20;
  } else if (size === 'medium') {
    size = 40;
  } else if (size === 'large') {
    size = 60;
  }

  return (
    <div>
      <div className="spinner-wrapper" style={{ width: size, height: size }}>
        <div className="donut" style={{ width: size, height: size }} />
      </div>
    </div>
  );
};

export default Spinner;
