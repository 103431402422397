export const SEARCH_GROUP_OPTION_VALUES = {
  EQUIPEMENTS: 'equipements',
  ENSEMBLES: 'ensembles',
  PRODUITS: 'produitsmodélisés',
  INIES: 'INIES',
  EAU: 'eau',
  ENERGIE: 'energie',
  FICHESCONFIG: 'fichesconfig',
  DECHETS: 'déchets',
  TRANSPORTS: 'transports',
  BIBLIOTHEQUE: 'bibliotheque',
};
