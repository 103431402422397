import {
  CREATE_ACTION,
  CREATE_NEW_EQUIPMENT,
  CRUD_BOARDING,
  DELETE_ACTION,
  DOWNLOAD_BOARDING_XLSX,
  FETCH_ACTIONS_MODELS_GRAPH,
  FETCH_BOARDING,
  FETCH_IMPACT_BARGRAPH,
  FETCH_LIST_ACTIONS,
  FETCH_SHEETS,
  GET_CARACS,
  GET_FULL_MD,
  PATCH_PROJECT,
  getCaracs,
  loadBoarding,
  loadListActions,
  loadNewAction,
  loadNewEquipment,
  loadSheets,
  updateExploitationValue,
} from '../actions/exploitation';
import { calculatorUpdate } from '../actions/calculator';
import { fetchProjectById, updateFormValue } from '../actions/projects';
// import { formatViewCard } from '../app/shared/utils';
import { returnColorsForBarGraph } from '../app/exploitation/Report';
import { returnNameAction } from '../app/exploitation/ListActions';
import API from '../app/services/API';
import formatNumber from '../app/services/mathematics/formatNumber';
import dayjs from 'dayjs';
import moment from 'moment';

const api = new API();

export const returnEventNameAction = (eventName) => {
  console.log(eventName);

  switch (eventName) {
    case 'Renouvellement':
      return 'Renewal';
    case 'Energie':
      return 'Energy';
    case 'Déchet':
      return 'Garbage';
    default:
      return eventName;
  }
};

const exploitationMiddleware = (store) => (next) => async (action) => {
  const { exploitation, projects } = store.getState();

  switch (action.type) {
    case GET_CARACS: {
      if (action.screen === 'calculator') {
        api
          .getEquipmentCaracListById(action.md.id)
          .then((response) => {
            const [mdSelected] = projects.allGroups.type.filter(
              (md) => md.id === action.md.id
            );

            console.log(mdSelected);

            console.log(action, 'action dans getCaracs calculator');

            const _ = require('lodash');

            const dataCopy = _.cloneDeep(response.data);

            console.log('dataCopy getCarac', dataCopy);
            // store.dispatch()
            // console.log('bien arrivé dans middleware calculator pour les carcs', 'action.md:',action.md, 'action.screen:', action.screen);
            console.log(
              'indexArray:',
              action.index,
              'caracs trouvées: ',
              response.data
            );
            // console.log('masterdata', action.md);
            response.data.caracs = response.data.caracs.filter(
              (el) => el.neededOnCreation || el.neededOnImplementation
            );
            response.data.caracs = response.data.caracs.sort(
              (a, b) =>
                (a.id === mdSelected?.secondDiscreteId) -
                  (b.id === mdSelected?.secondDiscreteId) ||
                (a.id === mdSelected?.discreteId) -
                  (b.id === mdSelected?.discreteId) ||
                (a.id === mdSelected?.xAxisId) - (b.id === mdSelected?.xAxisId)
            );

            console.log(response.data);

            store.dispatch(
              calculatorUpdate('resultOfSearchCarac', {
                index: action.index,
                caracs: response.data,
              })
            );
            store.dispatch(calculatorUpdate('isLoadingCaracs', false));
          })
          .catch((error) => {
            console.log(
              'error',
              'screen calculatorEnergy',
              'indexArray:',
              action.index,
              'masterDataId',
              action.md
            );

            console.log(error);
          });
      } else {
        let allotment = projects.allGroups.flatAllotments.find(
          (el) => el.MasterDataEquipmentId === action.md?.id
        );

        api
          .getEquipmentCaracListById(
            action.screen === 'consumer' || action.screen === 'addGeneric'
              ? allotment?.id
              : exploitation.newEquipment.AllotmentNode?.id
          )
          .then((response) => {
            if (action.screen === 'consumer') {
              store.dispatch(
                updateExploitationValue('newAction', {
                  ...exploitation.newAction,
                  MasterDataEquipmentId: action.md.id,
                  MasterDataEquipment: action.md,
                  caracs: response.data.caracs.filter(
                    (el) => el.neededOnCreation || el.neededOnImplementation
                  ),
                  AllotmentNodeId: allotment.id,
                  AllotmentNodes: [allotment],
                  AllotmentNode: allotment,
                })
              );
            } else if (action.screen === 'addGeneric') {
              const currentSheet = exploitation.sheets?.find(
                (sheet) =>
                  sheet.ProjectSheet &&
                  sheet.ProjectSheet.id === exploitation.newAction.equipment.id
              );

              console.group(
                currentSheet,
                'currentsheet',
                exploitation.sheets,
                exploitation.newAction
              );

              response.data.caracs = response.data.caracs
                .filter((el) => el.neededOnCreation || el.neededOnImplementation)
                .map((carac) => ({
                  ...carac,
                  value: currentSheet?.Caracs.find((el) => el.name === carac.name)
                    ?.SheetCarac?.value[0],
                }));
              response.data.sheets = [];

              store.dispatch(
                updateExploitationValue('newAction', {
                  ...exploitation.newAction,
                  renewalEquipment: {
                    ...exploitation.newAction.renewalEquipment,
                    Caracs: response.data.caracs,
                    caracs: response.data,
                  },
                })
              );
            } else {
              console.log(response);

              response.data.caracs = response.data.caracs.filter(
                (el) => el.neededOnCreation || el.neededOnImplementation
              );

              response.data.caracs = response.data.caracs.map((carac) => {
                return {
                  ...carac,
                  value: carac.defaultValue ? [carac.defaultValue] : carac.value,
                };
              });

              console.log('exploitation responsedata getCarac', response.data);

              store.dispatch(
                updateExploitationValue('newEquipment', {
                  ...exploitation.newEquipment,
                  caracs: response.data,
                })
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      next(action);
      break;
    }
    case FETCH_BOARDING:
      if (exploitation.isBoardingFull) return;
      api
        .fetchBoardingEquipment(
          action.id || projects.currentProject.id,
          exploitation.offset,
          exploitation.boardingActiveTab !== 'Equipement / matériau'
            ? exploitation.boardingActiveTab
            : null
        )
        .then((response) => {
          console.log(response.data);

          if (
            response.data.length > 0 &&
            !exploitation.boardingListDatas.find(
              (el) => el.id === response.data[response.data.length - 1].id
            )
          ) {
            store.dispatch(
              loadBoarding(
                response.data.map((el) => ({
                  ...el,
                  key: el.id,
                  MasterDataEquipment: projects.allGroups.type.find(
                    (md) => md.id === el.MasterDataEquipmentId
                  ),
                }))
              )
            );
          } else {
            store.dispatch(updateExploitationValue('isBoardingFull', true));
            store.dispatch(updateExploitationValue('loadingBoarding', false));
          }
        })
        .catch((error) => {
          console.log(error);
        });

      next(action);
      break;

    case CREATE_NEW_EQUIPMENT: {
      let data = {
        ...exploitation.newEquipment,
        quantity: exploitation.newEquipmentQuantity,
        state: exploitation.newEquipmentState,
        caracs: exploitation.newEquipment.caracs.caracs,
        type: 'users',
      };

      api
        .createBoardingEquipment(projects.currentProject.id, data)
        .then((response) => {
          response.data.newEquipment.MasterDataEquipment =
            projects.allGroups.type.find(
              (md) => md.id === response.data.newEquipment.MasterDataEquipmentId
            );
          response.data.newEquipment.key = response.data.newEquipment.id;

          store.dispatch(loadNewEquipment(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

      next(action);
      break;
    }
    case FETCH_LIST_ACTIONS:
      api
        .fetchListActions(
          action.id,
          exploitation.actionOffset,
          returnEventNameAction(exploitation.actionActiveTab)
        )
        .then((response) => {
          if (
            !exploitation.listActionDatas.find(
              (el) => el.id === response.data[response.data.length - 1]?.id
            )
          ) {
            store.dispatch(loadListActions(response.data, action.t));
          } else {
            store.dispatch(updateExploitationValue('isActionsFull', true));
            store.dispatch(updateExploitationValue('loadingActions', false));
          }
        })
        .catch((error) => {
          console.log(error);
        });

      next(action);
      break;
    case CREATE_ACTION: {
      let data = action.refurbished
        ? {
            ...exploitation.newAction,
            type: 'REFURBISHED',
            isRefurbished: true,
            equipment: {
              ...exploitation.newAction.equipment,
              AllotmentNodes: [exploitation.newAction.equipment.AllotmentNode],
            },
          }
        : exploitation.newAction;

      api
        .createAction(
          projects.currentProject.id,
          action.simplifiedEnergy
            ? exploitation.simplifiedEnergyLines.map((el) => ({
                ...el,
                type: 'users',
                AllotmentNodes: [el.AllotmentNode],
                caracs: el.caracs ? el.caracs.caracs : [],
                date: exploitation.newAction.date,
              }))
            : action.transport
            ? exploitation.transportLines.map((el) => ({
                ...el,
                type: 'users',
                AllotmentNodes: [el.AllotmentNode],
                caracs: el.caracs ? el.caracs.caracs : [],
                date: exploitation.newAction.date,
              }))
            : action.garbage
            ? exploitation.garbageLines.map((el) => ({
                ...el,
                type: 'users',
                AllotmentNodes: [el.AllotmentNode],
                date: exploitation.newAction.date,
              }))
            : data
        )
        .then((response) => {
          let actions = exploitation.listActionDatas.filter(
            (el) => !response.data.newAction.includes(el.id)
          );

          actions = [...actions, ...response.data.newAction];

          store.dispatch(loadNewAction([...actions], action.t));
          store.dispatch(
            updateFormValue('currentProject', {
              ...projects.currentProject,
              EmissionValues: [response.data.projectEmissions],
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });

      next(action);
      break;
    }
    case GET_FULL_MD:
      api
        .getFullMd(action.id.MasterDataEquipmentId)
        .then((res) => {
          store.dispatch(
            updateExploitationValue('newAction', {
              ...exploitation.newAction,
              renewalEquipment: {
                ...exploitation.newAction.renewalEquipment,
                MasterDataEquipment: res.data,
              },
            })
          );

          api
            .getIndicatorsBySheet(
              action.id.id,
              projects.currentIndicator.id,
              projects.currentProject.id
            )
            .then((response) => {
              store.dispatch(loadSheets(response.data));
              store.dispatch(getCaracs(res.data, 'addGeneric'));
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });

      next(action);
      break;
    case CRUD_BOARDING: {
      switch (action.method) {
        case 'delete': {
          api
            .deleteBoardingEquipment(
              projects.currentProject.id,
              exploitation.selectedRowKeys
            )
            .then((response) => {
              store.dispatch(
                updateExploitationValue(
                  'boardingListDatas',
                  exploitation.boardingListDatas.filter(
                    (el) => !exploitation.selectedRowKeys.includes(el.id)
                  )
                )
              );
              store.dispatch(
                updateFormValue('currentProject', {
                  ...projects.currentProject,
                  EmissionValues: [response.data.projectEmissions],
                })
              );
              store.dispatch(
                updateExploitationValue(
                  'listActionDatas',
                  exploitation.listActionDatas.filter(
                    (el) => !response.data.deletedEvents.includes(el.id)
                  )
                )
              );
            });

          break;
        }
        default:
          console.log('aucune action');
      }

      next(action);
      break;
    }
    // Replace by useQuery
    // case FETCH_IMPACT_BARGRAPH: {
    //   api.fetchImpactBarGraph(projects.currentProject.id).then((res) => {
    //     console.log(res);

    //     //format data for bar graph

    //     let formatData = [];
    //     let keys = [];

    //     res.data.sort((a,b) => {
    //       if(a.month < b.month) {
    //         return -1;
    //       }
    //     }).forEach((action) => {
    //       let data = formatData.find((el) => el.month === moment(action.month).format('MMM YYYY'));

    //       let eventType = exploitation.eventTypes.find((el) => el.id === action.EventTypeId);

    //       if(!keys.find((el) => el.key === returnNameAction(eventType.name))) {
    //         keys = [...keys, {key:returnNameAction(eventType.name), color: returnColorsForBarGraph(eventType.name)}];
    //       }

    //       if(!data) {
    //         formatData.push({month: moment(action.month).format('MMM YYYY'), [returnNameAction(eventType.name)] : action.carbonImpact, [`${returnNameAction(eventType.name)}Color`]: returnColorsForBarGraph(eventType.name) });
    //       } else {
    //         formatData = formatData.map((el) => {
    //           if(el?.month === data.month) {
    //             return {
    //               ...el,
    //               [returnNameAction(eventType.name)] : action.carbonImpact,
    //               [`${returnNameAction(eventType.name)}Color`]: returnColorsForBarGraph(eventType.name),
    //             };
    //           } else {
    //             return el;
    //           }
    //         });
    //       }
    //     });

    //     store.dispatch(updateExploitationValue('impactBarGraph',
    //       {data:formatData, keys: keys}));
    //   });
    //   next(action);
    //   break;
    // }
    // case FETCH_ACTIONS_MODELS_GRAPH: {
    //   api.getGraphModelsAndActions(projects.currentProject.id).then((res) => {
    //     let values = [];

    //     res.data.actionsData = res.data.actionsData.sort((a,b) => a.year < b.year).map((data) => {
    //       values = [...values, data.carbonImpact];

    //       return({
    //         ...data, name: returnNameAction(data.EventType), year: moment(data.year).format('MMM YYYY')
    //       }
    //       );
    //     });
    //     res.data.modelLines = res.data.modelLines.sort((a,b) => a.year < b.year).map((data) => {
    //       values = [...values, data.carbonImpact];

    //       return({
    //         ...data, model: data.model === 'initialState' ? 'Trajectoire de référence' : 'Trajectoire actualisée', year: moment(data.year).format('MMM YYYY')
    //       }
    //       );
    //     })
    //       .sort((a,b) => a.year - b.year);

    //     res.data.maxValue = Math.max(...values);
    //     store.dispatch(updateExploitationValue('actionsModelsGraphData',
    //       res.data));
    //   });
    //   next(action);
    //   break;
    // }

    case DELETE_ACTION: {
      api
        .deleteActionExploitation(
          projects.currentProject.id,
          exploitation.selectedActionKeys
        )
        .then((response) => {
          store.dispatch(
            updateExploitationValue(
              'listActionDatas',
              exploitation.listActionDatas.filter(
                (el) => !response.data.deletedEvents.includes(el.id)
              )
            )
          );
          store.dispatch(
            updateExploitationValue(
              'selectedActionKeys',
              exploitation.selectedActionKeys.filter(
                (id) => !response.data.deletedEvents.includes(id)
              )
            )
          );
          if (response.data.projectEmissions) {
            store.dispatch(
              updateFormValue('currentProject', {
                ...projects.currentProject,
                EmissionValues: [response.data.projectEmissions],
              })
            );
          }
        });

      next(action);
      break;
    }
    case PATCH_PROJECT: {
      api
        .patchProject({ ...action.data, id: projects.currentProject.id })
        .then((response) => {
          store.dispatch(fetchProjectById(projects.currentProject.id));
          setTimeout(() => {
            store.dispatch(
              updateExploitationValue('exploitationNotification', {
                text: 'Votre projet a été mis à jour avec succès',
                notificationType: 'success',
              })
            );
          }, 1000);
        })
        .catch((error) => {});
      next(action);
      break;
    }
    case DOWNLOAD_BOARDING_XLSX: {
      api
        .downloadBoardingXlsx(projects.currentProject.id)
        .then((response) => response.blob())
        .then((blob) => {
          console.log(blob, 'blob');

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');

          a.href = url;
          a.download = 'data.xlsx';
          console.log(a, 'censsé etre', window.URL);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {});
      next(action);
      break;
    }

    default:
      next(action);
  }
};

export default exploitationMiddleware;
