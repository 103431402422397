import './transportAndGear.scss';
import { Autocomplete } from '@mui/material';
import { Button } from '@photocarbone/nooco-ui';
import { StateDTO } from '../../../../../models/GeneralDTO';
import {
  calculatorUpdate,
  getGearCarac,
} from '../../../../../../actions/calculator';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@mui/material/MenuItem';
import SelectUi from '@mui/material/Select';
import InfoIcon from '@material-ui/icons/Info';
import { Select, Tooltip } from '@material-ui/core';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { isNumOrNull } from '../../../../../shared/utilsFunction';
import React from 'react';
import { TextField } from '@mui/material';
import add from '../../../../../../assets/images/exploitation/add.png';
import backIcon from '../../../../../../assets/images/creation/back-arrow.png';
import carburant from '../../../../../../assets/images/equipments/calculator/carburant.png';
import chariotElevateurElectrique from '../../../../../../assets/images/equipments/calculator/chariotElevateurElectrique.png';
import enginCompactage from '../../../../../../assets/images/equipments/calculator/enginCompactage.png';
import enginForage from '../../../../../../assets/images/equipments/calculator/enginForage.png';
import enginTransport from '../../../../../../assets/images/equipments/calculator/enginTransport.png';
import grueMobile from '../../../../../../assets/images/equipments/calculator/grueMobile.png';
import grueTour from '../../../../../../assets/images/equipments/calculator/grueTour.png';
import nacelleDiesel from '../../../../../../assets/images/equipments/calculator/nacelleDiesel.png';
import pelleteuse from '../../../../../../assets/images/equipments/calculator/pelleteuse.png';
import saisieEngin from '../../../../../../assets/images/equipments/calculator/saisieEngin.png';
import tractopelle from '../../../../../../assets/images/equipments/calculator/tractopelle.png';
import trancheuse from '../../../../../../assets/images/equipments/calculator/trancheuse.png';
import excavator from '../../../../../../assets/images/equipments/calculator/excavator.png';
import excavated from '../../../../../../assets/images/equipments/calculator/excavated.png';

import { handleKeyPress } from '../../../../../shared/utils';
import { useFormContext } from 'react-hook-form';
import useCaseWorksite from './UseCaseWorksite';
import { useTranslation } from 'react-i18next';

const WorksiteGear = ({ changeSourceOfEmptyRows: changeSourceOfEmptyRows }) => {
  const { handleSubmit } = useFormContext();
  const {
    isValidFormGear,
    isRegulationProject,
    isEquipmentEditable,
    isEquipmentFromParentZone,
    calculateAreaZoneProrata,
    isDisplayEquipment,
    onSubmitScreen,
    zoneSourceToDisplay,
  } = useCaseWorksite();
  const dispatch = useDispatch();
  const {
    isTotalGazoilSelected,
    isInputByGearSelected,
    isInputByExcavatedSelected,
    calculatorTotalGazoilGear,
    isGearErrorDisplay,
    calculatorTotalExcavatedGear,
    gearLines,
    worksiteCalculatorDetailedActive,
    gearsEquipments,
    isWorksiteCalculatorSimplified,
  } = useSelector((state: StateDTO) => state.projects.calculator);
  const { zoneSelected } = useSelector(
    (state: StateDTO) => state.monitoring.breadcrumbMultizone
  );
  const _ = require('lodash');
  const { zones } = useSelector((state: StateDTO) => state.projects.parsedProject);
  const { t } = useTranslation();

  let gearsEquipmentsFiltered: any[] = [];

  if (isRegulationProject && gearsEquipments && gearsEquipments?.length > 0) {
    gearsEquipmentsFiltered = gearsEquipments.filter((masterData) => {
      const motorizationCarac = masterData?.Caracs?.find(
        (carac) => carac.name === 'Motorisation'
      );

      if (motorizationCarac?.values?.includes('GNR')) {
        motorizationCarac.values = ['GNR'];
        //   motorizationCarac.value = motorizationCarac?.values;
        // }
        // masterData.Caracs.find((carac) => carac.name === 'Motorisation') = motorizationCarac;
        return masterData;
      }
    });
  } else {
    gearsEquipmentsFiltered = gearsEquipments;
  }

  gearsEquipmentsFiltered.map((equipements) => {
    equipements.Caracs.map((carac) => {
      if (carac.name === 'Motorisation' && carac.values.length === 1) {
        carac.value = carac.values;
      }
      return equipements;
    });
  });

  const onSubmit = (data) => {
    const _ = require('lodash');
    const gearLinesCopy = _.cloneDeep(gearLines);
    if (isValidFormGear()) {
      dispatch(calculatorUpdate('isGearErrorDisplay', false));
      changeSourceOfEmptyRows({
        zone: zoneSelected,
        sideBarActiveId: worksiteCalculatorDetailedActive + 1,
      });
      dispatch(
        calculatorUpdate(
          'worksiteCalculatorDetailedActive',
          worksiteCalculatorDetailedActive + 1
        )
      );
    } else {
      onSubmitScreen(data, 'next');
      dispatch(calculatorUpdate('isGearErrorDisplay', true));
    }
  };

  const onError = (errors) => {
    console.log('validation errors gear', errors);
  };

  const handleEditGear = (newValue: {}): void => {
    const newValueCopy = _.cloneDeep(newValue);
    const gearLinesCopy = _.cloneDeep(gearLines);

    const newLines = gearLinesCopy.map((gearLine: {}) => {
      if (gearLine?.id === newValueCopy?.id) {
        return newValueCopy;
      }

      return gearLine;
    });

    dispatch(calculatorUpdate('gearLines', newLines));
  };

  const addNewGear = (): void => {
    const newLines = [
      ...gearLines,
      {
        id: Math.max(...gearLines.map((el) => el.id)) + 1,
        MasterDataEquipmentId: null,
        quantity: null,
        type: null,
        motorisation: null,
        consumption: null,
        unit: null,
        unitConsumption: null,
        Caracs: [],
        origin: {
          type: zoneSelected?.type || 'project',
          sourceId: zoneSelected?.id || null,
          uniqueId: uuidv4(),
        },
      },
    ];

    dispatch(calculatorUpdate('gearLines', newLines));
  };

  const addNewGearCard = (defaultMasterDataEquipmentId: number): void => {
    const equipmentSelected = gearsEquipmentsFiltered.find(
      (equipment) => equipment.id === defaultMasterDataEquipmentId
    );

    if (gearLines.find((line) => !line.MasterDataEquipmentId)) {
      handleEditGear({
        ...gearLines.find((line) => !line.MasterDataEquipmentId),
        MasterDataEquipmentId: equipmentSelected?.id,
        Caracs: equipmentSelected.Caracs,
        consumption:
          equipmentSelected.Caracs.find((carac) => carac.name === 'Consommation')
            ?.defaultValue || null,
        origin: {
          type: zoneSelected?.type || 'project',
          sourceId: zoneSelected?.id || null,
          uniqueId: uuidv4(),
        },
      });
    } else {
      const newLines = [
        ...gearLines,
        {
          id: Math.max(...gearLines.map((line) => line.id)) + 1,
          MasterDataEquipmentId: equipmentSelected?.id,
          quantity: null,
          consumption:
            equipmentSelected.Caracs.find((carac) => carac.name === 'Consommation')
              ?.defaultValue || null,
          unit: equipmentSelected.unit,
          Caracs: equipmentSelected.Caracs,
          origin: {
            type: zoneSelected?.type || 'project',
            sourceId: zoneSelected?.id || null,
            uniqueId: uuidv4(),
          },

          // MasterDataEquipmentId: defaultMasterDataEquipmentId,

          // quantity: null,
          // unit: 'km',
        },
      ];

      dispatch(calculatorUpdate('gearLines', newLines));
    }
  };

  const ScreenChoiceEntryGearConsumption = (): JSX.Element => {
    return (
      <div className="gearCalculatorWrapper">
        <div className="gearHeader">
          <div className="gearHeaderLabel">
            {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_0527FF', {
              defaultValue: 'Choisir un type de saisie de consommation :',
            })}
          </div>
        </div>
        <div className="gear">
          <div className="iconWrapper">
            <div
              className={`gear-item-home ${
                isWorksiteCalculatorSimplified ? 'disabled' : ''
              }`}
              onClick={() => {
                if (!isWorksiteCalculatorSimplified) {
                  dispatch(calculatorUpdate('isTotalGazoilSelected', true));
                }
              }}
            >
              <img src={carburant} alt="" />
              <p>
                {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_5F7299', {
                  defaultValue: 'Saisie du gazole total',
                })}
              </p>
            </div>
            <div
              className={`gear-item-home ${
                isWorksiteCalculatorSimplified ? 'disabled' : ''
              }`}
              onClick={() => {
                if (!isWorksiteCalculatorSimplified) {
                  dispatch(calculatorUpdate('isInputByGearSelected', true));
                }
              }}
            >
              <img src={saisieEngin} alt="" />
              <p>
                {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_5DB259', {
                  defaultValue: 'Saisie par engin',
                })}
              </p>
            </div>
            {isWorksiteCalculatorSimplified && (
              <div
                className="gear-item-home"
                onClick={() =>
                  dispatch(calculatorUpdate('isInputByExcavatedSelected', true))
                }
              >
                <img src={excavator} alt="" />
                <p>{'Saisie par quantité de terres excavées'}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const RowInputByTotalGazole = (totalGazoilRow, index): JSX.Element => {
    if (isEquipmentEditable(totalGazoilRow)) {
      return (
        <div key={totalGazoilRow.origin.uniqueId} className="cardWrapper">
          {/* emptySourceRow */}
          <div className="sourceRow">
            {zoneSourceToDisplay(totalGazoilRow.origin)}
          </div>
          {/* labelRow */}
          <div className="labelRow">
            {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_E2363B', {
              defaultValue: 'Gazole pour engin de chantier (L)',
            })}
          </div>
          {/* quantityRow */}
          <div className="quantityRow">
            {/* logo */}
            <div className="gazoilLogoWrapper">
              <img src={carburant} alt="" />
            </div>
            {/* quantity */}
            <div className="gazoilInputWrapper">
              <TextField
                // value={totalGazoilRow.quantity}
                // key={totalGazoilRow.origin.uniqueId}
                defaultValue={totalGazoilRow.quantity}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  const newCalculatorTotalGazoilGear = [
                    ...calculatorTotalGazoilGear,
                  ];
                  if (e.target.value === '') {
                    newCalculatorTotalGazoilGear[index].quantity = null;
                    dispatch(
                      calculatorUpdate(
                        'calculatorTotalGazoilGear',
                        newCalculatorTotalGazoilGear
                      )
                    );
                    return;
                  }

                  newCalculatorTotalGazoilGear[index].quantity = Number(
                    e.target.value.replace(',', '.')
                  );
                  dispatch(
                    calculatorUpdate(
                      'calculatorTotalGazoilGear',
                      newCalculatorTotalGazoilGear
                    )
                  );
                }}
                error={!isNumOrNull(totalGazoilRow.quantity)}
                style={{ width: '100%' }}
                placeholder={t(
                  'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_89512B',
                  { defaultValue: 'Saisir une Quantité' }
                )}
                variant="outlined"
                className="textFieldV1 inputTextfield"
              />
            </div>
            {/* Delete energy Type */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              onClick={() => {
                let newCalculatorTotalGazoilGear = [...calculatorTotalGazoilGear];
                newCalculatorTotalGazoilGear.splice(index, 1);
                if (newCalculatorTotalGazoilGear.length === 0) {
                  newCalculatorTotalGazoilGear[0] = {
                    quantity: null,
                    origin: {
                      type: zoneSelected?.type || 'project',
                      sourceId: zoneSelected?.id || null,
                      uniqueId: uuidv4(),
                    },
                  };
                }
                dispatch(
                  calculatorUpdate(
                    'calculatorTotalGazoilGear',
                    newCalculatorTotalGazoilGear
                  )
                );
              }}
            >
              <HighlightOffIcon
                style={{ width: '40px', height: '40px', marginLeft: '1rem' }}
                className="calculator__delElement"
              />
            </div>
          </div>
        </div>
      );
    }

    if (isDisplayEquipment(totalGazoilRow)) {
      let quantity = totalGazoilRow.quantity;

      if (isEquipmentFromParentZone(totalGazoilRow)) {
        const quantityProrata = calculateAreaZoneProrata(totalGazoilRow);
        quantity = Math.round(quantity * quantityProrata * 10000) / 10000;
      }
      return (
        <div
          key={`${totalGazoilRow.origin.uniqueId}-${zoneSelected?.id}`}
          className="cardWrapper disabled"
        >
          {/* sourceRow */}
          <div className="sourceRow">
            {zoneSourceToDisplay(totalGazoilRow.origin)}
          </div>
          {/* labelRow */}
          <div className="labelRow">
            {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_76A277', {
              defaultValue: 'Gazole pour engin de chantier (L)',
            })}
          </div>
          {/* quantityRow */}
          <div className="quantityRow">
            {/* logo */}
            <div className="gazoilLogoWrapper">
              <img src={carburant} alt="" />
            </div>
            {/* quantity */}
            <div className="gazoilInputWrapper">
              <TextField
                // value={totalGazoilRow.quantity}
                // key={totalGazoilRow.origin.uniqueId}
                value={quantity}
                disabled={true}
                error={!isNumOrNull(totalGazoilRow.quantity)}
                style={{ width: '100%' }}
                placeholder={t(
                  'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_1B8860',
                  { defaultValue: 'Saisir une Quantité' }
                )}
                variant="outlined"
                className="textFieldV1 inputTextfield"
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const RowInputByExcaved = (totalExcavedRow, index): JSX.Element => {
    if (isEquipmentEditable(totalExcavedRow)) {
      return (
        <div className="cardWrapper">
          {/* emptySourceRow */}
          <div className="sourceRow">
            {zoneSourceToDisplay(totalExcavedRow.origin)}
          </div>
          {/* labelRow */}
          <div className="labelRow">
            {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_607F62', {
              defaultValue: 'Quantité de terres excavées (m3)',
            })}
          </div>
          {/* quantityRow */}
          <div className="quantityRow">
            {/* logo */}
            <div className="gazoilLogoWrapper">
              <img src={excavated} alt="" />
            </div>
            {/* quantity */}
            <div className="gazoilInputWrapper">
              <TextField
                defaultValue={totalExcavedRow.quantity}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  const newCalculatorExcavated = [...calculatorTotalExcavatedGear];
                  if (e.target.value === '') {
                    newCalculatorExcavated[index].quantity = null;
                    return;
                  }
                  newCalculatorExcavated[index].quantity = Number(
                    e.target.value.replace(',', '.')
                  );
                  dispatch(
                    calculatorUpdate(
                      'calculatorTotalExcavatedGear',
                      newCalculatorExcavated
                    )
                  );
                }}
                error={!isNumOrNull(totalExcavedRow.quantity)}
                style={{ width: '100%' }}
                placeholder={t(
                  'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_E34E6A',
                  { defaultValue: 'Saisir une Quantité' }
                )}
                variant="outlined"
                className="textFieldV1 inputTextfield"
              />
            </div>
            {/* Delete energy Type */}
            <div
              style={{ display: 'flex', justifyContent: 'flex-end' }}
              onClick={() => {
                let newCalculatorExcavated = _.cloneDeep(
                  calculatorTotalExcavatedGear
                );
                newCalculatorExcavated.splice(index, 1);
                if (newCalculatorExcavated.length === 0) {
                  newCalculatorExcavated[0] = {
                    quantity: null,
                    origin: {
                      type: zoneSelected?.type || 'project',
                      sourceId: zoneSelected?.id || null,
                      uniqueId: uuidv4(),
                    },
                  };
                }

                dispatch(
                  calculatorUpdate(
                    'calculatorTotalExcavatedGear',
                    newCalculatorExcavated
                  )
                );
              }}
            >
              <HighlightOffIcon
                style={{ width: '40px', height: '40px', marginLeft: '1rem' }}
                className="calculator__delElement"
              />
            </div>
          </div>
        </div>
      );
    }

    if (isDisplayEquipment(totalExcavedRow)) {
      let quantity = totalExcavedRow.quantity;

      if (isEquipmentFromParentZone(totalExcavedRow)) {
        const quantityProrata = calculateAreaZoneProrata(totalExcavedRow);
        quantity = Math.round(quantity * quantityProrata * 10000) / 10000;
      }
      return (
        <div className="cardWrapper disabled">
          {/* sourceRow */}
          <div className="sourceRow">
            {zoneSourceToDisplay(totalExcavedRow.origin)}
          </div>
          {/* labelRow */}
          <div className="labelRow">
            {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_CE7218', {
              defaultValue: 'Quantité de terres excavées (m3)',
            })}
          </div>
          {/* quantityRow */}
          <div className="quantityRow">
            {/* logo */}
            <div className="gazoilLogoWrapper">
              <img src={excavated} alt="" />
            </div>
            {/* quantity */}
            <div className="gazoilInputWrapper">
              <TextField
                value={quantity}
                disabled={true}
                error={!isNumOrNull(totalExcavedRow.quantity)}
                style={{ width: '100%' }}
                placeholder={t(
                  'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_DF6B7C',
                  { defaultValue: 'Saisir une Quantité' }
                )}
                variant="outlined"
                className="textFieldV1 inputTextfield"
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const AddRowTotalGazole = (): JSX.Element => {
    return (
      <div
        className="calculator__addElement"
        onClick={() => {
          const newCalculatorTotalGazoilGear = _.cloneDeep(
            calculatorTotalGazoilGear
          );
          const newLine = {
            quantity: null,
            origin: {
              type: zoneSelected?.type || 'project',
              sourceId: zoneSelected?.id || null,
              uniqueId: uuidv4(),
            },
          };
          dispatch(
            calculatorUpdate('calculatorTotalGazoilGear', [
              ...newCalculatorTotalGazoilGear,
              newLine,
            ])
          );
        }}
      >
        <ControlPointIcon />
        {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_4C1B11', {
          defaultValue: 'Ajouter dans cette zone',
        })}
      </div>
    );
  };
  const AddRowExcavated = (): JSX.Element => {
    return (
      <div
        className="calculator__addElement"
        onClick={() => {
          const newCalculatorTotalExcavated = _.cloneDeep(
            calculatorTotalExcavatedGear
          );
          const newLine = {
            quantity: null,
            origin: {
              type: zoneSelected?.type || 'project',
              sourceId: zoneSelected?.id || null,
              uniqueId: uuidv4(),
            },
          };
          dispatch(
            calculatorUpdate('calculatorTotalExcavatedGear', [
              ...newCalculatorTotalExcavated,
              newLine,
            ])
          );
        }}
      >
        <ControlPointIcon />
        {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_463F21', {
          defaultValue: 'Ajouter dans cette zone',
        })}
      </div>
    );
  };

  const BreadCrumb = (): JSX.Element => {
    const resetGearChoice = () => {
      if (isTotalGazoilSelected) {
        dispatch(calculatorUpdate('isTotalGazoilSelected', false));
        dispatch(
          calculatorUpdate('calculatorTotalGazoilGear', [
            {
              quantity: null,
              origin: {
                type: zoneSelected?.type || 'project',
                sourceId: zoneSelected?.id || null,
                uniqueId: uuidv4(),
              },
            },
          ])
        );
        dispatch(calculatorUpdate('isGearErrorDisplay', false));
      }

      if (isInputByGearSelected) {
        dispatch(calculatorUpdate('isInputByGearSelected', false));
        dispatch(
          calculatorUpdate('gearLines', [
            {
              id: 0,
              MasterDataEquipmentId: null,
              quantity: null,
              type: null,
              motorization: null,
              consumption: null,
              unitConsumption: null,
              unit: null,
              caracs: [],
              origin: {
                type: zoneSelected?.type || 'project',
                sourceId: zoneSelected?.id || null,
                uniqueId: uuidv4(),
              },
            },
          ])
        );
        dispatch(calculatorUpdate('isGearErrorDisplay', false));
      }

      if (isInputByExcavatedSelected) {
        dispatch(calculatorUpdate('isInputByExcavatedSelected', false));
      }
    };

    const titleButtonAccordingGearChoice = (): string => {
      if (isTotalGazoilSelected) {
        return t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_CEC187', {
          defaultValue: 'Saisie du gazole total',
        });
      } else if (isInputByGearSelected) {
        return t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_9A5660', {
          defaultValue: 'Saisie par engin',
        });
      } else if (isInputByExcavatedSelected) {
        return t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_31E1B4', {
          defaultValue: 'Saisie par quantité de terres excavées',
        });
      }
      return '';
    };

    return (
      <div className="gearHeader">
        <div className="backButton">
          <img src={backIcon} alt="" onClick={resetGearChoice} />
        </div>
        <div className="gearHeaderLabel">
          {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_D5456D', {
            defaultValue: 'Choisir un type de saisie de consommation :',
          })}
        </div>
        <Button
          style={{
            fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
            marginLeft: '10px',
          }}
          shape="round"
          className="golden-button"
          onClick={resetGearChoice}
          text={titleButtonAccordingGearChoice()}
        />
      </div>
    );
  };

  const Footer = (): JSX.Element => {
    return (
      <div
        className="calculatorFlexColumnStart"
        style={{
          flexGrow: 1,
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }}
      >
        <div className="calculatorFlexStart" style={{ justifyContent: 'flex-end' }}>
          <Button
            style={{
              fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
            }}
            className="purple-button"
            onClick={handleSubmit(onSubmit, onError)}
            text="Suivant"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {/* Screen Selection */}
      {!isTotalGazoilSelected &&
        !isInputByGearSelected &&
        !isInputByExcavatedSelected && <ScreenChoiceEntryGearConsumption />}
      {/* Screen TotalGazoleSelected */}
      {isTotalGazoilSelected && (
        <div className="gearCalculatorWrapper">
          <BreadCrumb />
          <div className="gear">
            <div className="totalGazoilInputWrapper">
              {calculatorTotalGazoilGear.map((totalGazoilRow, index) =>
                RowInputByTotalGazole(totalGazoilRow, index)
              )}
            </div>
            <AddRowTotalGazole />
          </div>
        </div>
      )}
      {/* ScreenConsumptionByGear */}
      {isInputByGearSelected && (
        <>
          <div className="gearCalculatorWrapper">
            <BreadCrumb />
            <div className="gear">
              <div className="iconWrapper" style={{ marginBottom: '0' }}>
                <div
                  className="transport-item"
                  onClick={() =>
                    addNewGearCard(
                      gearsEquipmentsFiltered?.find(
                        (el) => el.name === 'Nacelle diesel'
                      ).id
                    )
                  }
                >
                  <img src={nacelleDiesel} alt="" />
                  <p>
                    {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_DAFFF5', {
                      defaultValue: 'Nacelle Diesel',
                    })}
                  </p>
                </div>
                <div
                  className="transport-item"
                  onClick={() =>
                    addNewGearCard(
                      gearsEquipmentsFiltered.find((el) => el.name === 'Pelleteuse')
                        .id
                    )
                  }
                >
                  <img src={pelleteuse} alt="" />
                  <p>
                    {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_32F551', {
                      defaultValue: 'Pelleteuse',
                    })}
                  </p>
                </div>
                <div
                  className="transport-item"
                  onClick={() =>
                    addNewGearCard(
                      gearsEquipmentsFiltered.find((el) => el.name === 'Tractopelle')
                        .id
                    )
                  }
                >
                  <img src={tractopelle} alt="" />
                  <p>
                    {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_45E6E8', {
                      defaultValue: 'Tractopelle',
                    })}
                  </p>
                </div>
                <div
                  className="transport-item"
                  onClick={() =>
                    addNewGearCard(
                      gearsEquipmentsFiltered.find((el) => el.name === 'Trancheuse')
                        .id
                    )
                  }
                >
                  <img src={trancheuse} alt="" />
                  <p>
                    {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_537132', {
                      defaultValue: 'Trancheuse',
                    })}
                  </p>
                </div>
                <div
                  className="transport-item"
                  onClick={() =>
                    addNewGearCard(
                      gearsEquipmentsFiltered.find(
                        (el) => el.name === 'Engin de transport'
                      ).id
                    )
                  }
                >
                  <img src={enginTransport} alt="" />
                  <p>
                    {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_1CC228', {
                      defaultValue: 'Engin de transport',
                    })}
                  </p>
                </div>
              </div>
              <div className="iconWrapper" style={{ marginBottom: '0' }}>
                <div
                  className="transport-item"
                  onClick={() =>
                    addNewGearCard(
                      gearsEquipmentsFiltered.find((el) => el.name === 'Grue mobile')
                        .id
                    )
                  }
                >
                  <img src={grueMobile} alt="" />
                  <p>
                    {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_FA0E46', {
                      defaultValue: 'Grue mobile',
                    })}
                  </p>
                </div>
                <div
                  className="transport-item"
                  onClick={() =>
                    addNewGearCard(
                      gearsEquipmentsFiltered.find(
                        (el) => el.name === 'Engin de compactage'
                      ).id
                    )
                  }
                >
                  <img src={enginCompactage} alt="" />
                  <p>
                    {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_BD8AD5', {
                      defaultValue: 'Engin de compactage',
                    })}
                  </p>
                </div>
                <div
                  className="transport-item"
                  onClick={() =>
                    addNewGearCard(
                      gearsEquipmentsFiltered.find(
                        (el) => el.name === 'Engin de forage'
                      ).id
                    )
                  }
                >
                  <img src={enginForage} alt="" />
                  <p>Engin de forage</p>
                </div>

                {!isRegulationProject && (
                  <>
                    <div
                      className="transport-item"
                      onClick={() =>
                        addNewGearCard(
                          gearsEquipmentsFiltered.find(
                            (el) => el.name === 'Chariot élévateur électrique'
                          ).id
                        )
                      }
                    >
                      <img src={chariotElevateurElectrique} alt="" />
                      <p>
                        {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_FC088E', {
                          defaultValue: 'Chariot élévateur électrique',
                        })}
                      </p>
                    </div>
                    <div
                      className="transport-item"
                      onClick={() =>
                        addNewGearCard(
                          gearsEquipmentsFiltered.find(
                            (el) => el.name === 'Grue à tour'
                          ).id
                        )
                      }
                    >
                      <img src={grueTour} alt="" />
                      <p>
                        {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_9583A0', {
                          defaultValue: 'Grue à tour',
                        })}
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className="transport-add">
                <div className="transport-lines-header">
                  <p style={{ width: '3%' }}></p>
                  <p style={{ width: '22%', paddingRight: '30px' }}>
                    {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_F279F9', {
                      defaultValue: 'Type de véhicule',
                    })}
                  </p>
                  <p style={{ width: '15%' }}>Quantité</p>
                  <p style={{ width: '10%' }}>Unité</p>
                  <p
                    style={{
                      width: '15%',
                      paddingRight: '10px',
                      paddingLeft: '10px',
                    }}
                  >
                    {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_FC58A8', {
                      defaultValue: 'Motorisation',
                    })}
                  </p>
                  <p
                    style={{
                      width: '15%',
                      paddingRight: '10px',
                      paddingLeft: '10px',
                    }}
                  >
                    {t('APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_D48BFC', {
                      defaultValue: 'Consommation',
                    })}
                    <Tooltip
                      title={
                        <>
                          {t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_742F25',
                            {
                              defaultValue:
                                "Consommations moyennes d'engins à partir de fiches fabricants:",
                            }
                          )}
                          <br />
                          <br />
                          {t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_1B7E9D',
                            { defaultValue: '- Minipelle 5 t : 3.8 L/h' }
                          )}
                          <br />
                          {t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_9087C4',
                            { defaultValue: ' - Pelles sur pneus : 7.09 L/h' }
                          )}
                          <br />
                          {t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_ECA037',
                            {
                              defaultValue: '- Pelles sur chenilles : 23.64 L/h',
                            }
                          )}
                          <br />
                          {t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_CC6192',
                            { defaultValue: '- Chargeuse : 11.8 L/h' }
                          )}
                          <br />
                          {t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_8B17C0',
                            { defaultValue: '- Tombereau : 14.91 L/h' }
                          )}
                          <br />
                          {t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_84F989',
                            { defaultValue: '- Grue mobile : 7.52 L/h' }
                          )}
                          <br />
                          {t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_44EAEC',
                            { defaultValue: '- Foreuse : 18.54 L/h' }
                          )}
                        </>
                      }
                    >
                      <InfoIcon style={{ marginLeft: '5px' }} />
                    </Tooltip>
                  </p>
                  <p style={{ width: '8%' }}>{''} </p>
                  <p style={{ width: '12%' }}>Source</p>
                  <p style={{ width: '2%' }}>{''} </p>
                </div>
                <div className="transport-lines">
                  {gearLines.map((gearLine: any, lineIndex) => {
                    if (isEquipmentEditable(gearLine)) {
                      return (
                        <div
                          className="transport-line"
                          style={{ justifyContent: 'flex-start' }}
                          key={`line-${lineIndex}`}
                        >
                          {/* Logo Add new line */}
                          <div style={{ width: '3%' }}>
                            {lineIndex === gearLines.length - 1 && (
                              <img
                                onClick={() => addNewGear()}
                                style={{
                                  width: '25px',
                                  height: '25px',
                                  marginTop: '10px',
                                  marginRight: '20px',
                                  cursor: 'pointer',
                                }}
                                src={add}
                              />
                            )}
                          </div>
                          {/* Autocomplete Type Vehicule/gear */}
                          <div style={{ width: '22%' }}>
                            <Autocomplete
                              value={gearsEquipmentsFiltered.find(
                                (masterData) =>
                                  masterData.id === gearLine.MasterDataEquipmentId
                              )}
                              autoComplete={true}
                              key={gearLine.MasterDataEquipmentId}
                              id="checkboxes-tags-demo"
                              defaultValue={gearsEquipmentsFiltered.find(
                                (masterData) =>
                                  masterData.id === gearLine.MasterDataEquipmentId
                              )}
                              options={
                                gearsEquipmentsFiltered
                                // !isRegulationProject ?
                              }
                              noOptionsText={'Aucun résultat trouvé'}
                              style={{ paddingRight: '30px' }}
                              onChange={(event, newValue, reason) => {
                                if (newValue) {
                                  handleEditGear({
                                    ...gearLine,
                                    MasterDataEquipmentId: newValue?.id,
                                    Caracs: newValue.Caracs,
                                    unit: newValue.unit,
                                    consumption:
                                      newValue.Caracs.find(
                                        (carac) => carac.name === 'Consommation'
                                      )?.defaultValue || null,
                                  });
                                } else if (newValue === null) {
                                  const newGearLines = [...gearLines];

                                  newGearLines[lineIndex] = {
                                    id: gearLines[lineIndex].id,
                                    MasterDataEquipmentId: null,
                                    quantity: null,
                                    type: null,
                                    motorisation: null,
                                    consumption: null,
                                    unit: null,
                                    unitConsumption: null,
                                    Caracs: [],
                                    origin: {
                                      type: zoneSelected?.type || 'project',
                                      sourceId: zoneSelected?.id || null,
                                      uniqueId: uuidv4(),
                                    },
                                  };
                                  dispatch(
                                    calculatorUpdate('gearLines', newGearLines)
                                  );
                                  // dispatch(calculatorUpdate('isTransportErrorDisplay',
                                  //   false));
                                } else if (reason === 'clear') {
                                  const newGearLines = [...gearLines];

                                  newGearLines[lineIndex] = {
                                    id: gearLines[lineIndex].id,
                                    MasterDataEquipmentId: null,
                                    quantity: null,
                                    type: null,
                                    motorisation: null,
                                    consumption: null,
                                    unit: null,
                                    unitConsumption: null,
                                    Caracs: [],
                                    origin: {
                                      type: zoneSelected?.type || 'project',
                                      sourceId: zoneSelected?.id || null,
                                      uniqueId: uuidv4(),
                                    },
                                  };
                                  dispatch(
                                    calculatorUpdate('gearLines', newGearLines)
                                  );
                                }

                                // handleEditGear({...gearLine, MasterDataEquipmentId: newValue?.id, caracs,});
                              }}
                              size={'small'}
                              getOptionLabel={(option) => option.name || ''}
                              renderInput={(params) => (
                                <TextField
                                  // key={uuidv4()}
                                  style={{ borderRadius: '10px' }}
                                  defaultValue={gearsEquipmentsFiltered.find(
                                    (masterData) =>
                                      masterData.id ===
                                      gearLine.MasterDataEquipmentId
                                  )}
                                  {...params}
                                  placeholder={t(
                                    'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_13B61E',
                                    { defaultValue: 'Ajouter un transport' }
                                  )}
                                  variant="outlined"
                                  className="textFieldV1"
                                  error={
                                    isGearErrorDisplay &&
                                    !gearLine.MasterDataEquipmentId &&
                                    gearLine.quantity
                                  }
                                />
                              )}
                            />
                          </div>
                          {/* Quantity */}
                          <div style={{ width: '15%' }}>
                            <TextField
                              key={`${gearLine}-${lineIndex}`}
                              // value={gearLine.quantity}
                              value={gearLines[lineIndex].quantity}
                              onChange={(e) => {
                                // gearLine.quantity = Number(e.target.value);
                                // handleEditGear(gearLine);
                                handleEditGear({
                                  ...gearLine,
                                  quantity: e.target.value,
                                });
                              }}
                              // type="number"
                              style={{ width: '100%' }}
                              placeholder={t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_2BF582',
                                { defaultValue: 'Quantité' }
                              )}
                              variant="outlined"
                              className="textFieldV1"
                              error={
                                (isGearErrorDisplay &&
                                  !gearLine.quantity &&
                                  gearLine.MasterDataEquipmentId) ||
                                !isNumOrNull(gearLines[lineIndex].quantity)
                              }
                            />
                          </div>
                          {/* Unit */}
                          <div
                            style={{
                              width: '10%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            h
                          </div>
                          {/* Motorisation */}
                          {gearLine?.Caracs &&
                            gearLine?.Caracs.map((carac, caracIndex) => {
                              if (carac.name === 'Motorisation' && carac?.values) {
                                return (
                                  <div
                                    style={{
                                      width: '15%',
                                      paddingRight: '10px',
                                      paddingLeft: '10px',
                                    }}
                                    key={`${carac.name}-${caracIndex}`}
                                  >
                                    <SelectUi
                                      // key={uuidv4()}
                                      MenuProps={{
                                        disableScrollLock: true,
                                      }}
                                      // defaultValue={carac.value && carac.value[0]}
                                      defaultValue={
                                        carac?.value?.length === 1 ? carac.value : []
                                      }
                                      onChange={(e) => {
                                        const gearLineUpdate = gearLine;

                                        gearLineUpdate.Caracs[caracIndex].value = [
                                          e.target.value,
                                        ];
                                        handleEditGear(gearLineUpdate);
                                        // handleEditGear(
                                        // {...gearLine, caracs: [...gearLine.caracs.filter((car) => car.name !== carac.name), {...carac, value: [e.target.value]}]}
                                        // );
                                      }}
                                      style={{ width: '100%', height: '40px' }}
                                      placeholder="  -"
                                      variant="outlined"
                                      className="selectUiV1"
                                      error={
                                        isGearErrorDisplay &&
                                        !gearLine?.Caracs?.find(
                                          (carac) => carac.name === 'Motorisation'
                                        )?.value
                                      }
                                      // className="input-boarding researchBar"
                                      // error={true}
                                    >
                                      {carac.values.map((value, i) => (
                                        <MenuItem
                                          value={value}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                          }}
                                          // key={`motorisationGear${value} ${i}`}
                                        >
                                          {value}
                                        </MenuItem>
                                      ))}
                                    </SelectUi>
                                  </div>
                                );
                              }
                            })}
                          {gearLine.MasterDataEquipmentId === null && (
                            <div
                              style={{
                                width: '15%',
                                paddingRight: '10px',
                                paddingLeft: '10px',
                              }}
                            ></div>
                          )}

                          {/* Consumption */}
                          <div style={{ width: '15%' }}>
                            <TextField
                              key={`${gearLine}-${lineIndex}`}
                              // value={gearLine.quantity}
                              value={gearLines[lineIndex].consumption}
                              onChange={(e) => {
                                // gearLine.consumption = Number(e.target.value);
                                // handleEditGear(gearLine);
                                console.log('gearLine', gearLine);
                                handleEditGear({
                                  ...gearLine,
                                  consumption: e.target.value,
                                });
                              }}
                              // type="number"
                              style={{
                                width: '100%',
                                paddingRight: '10px',
                                paddingLeft: '10px',
                              }}
                              placeholder="Quantité"
                              variant="outlined"
                              className="textFieldV1"
                              error={
                                (isGearErrorDisplay &&
                                  !gearLine.consumption &&
                                  gearLine.MasterDataEquipmentId) ||
                                !isNumOrNull(gearLines[lineIndex].consumption)
                              }
                            />
                          </div>
                          {/* Unit Consumption */}
                          <div
                            style={{
                              width: '8%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {/* {console.log('unit', gearsEquipments?.find((equipment) => equipment.id === gearLine.MasterDataEquipmentId)?.xAxis?.unit)}
                            {console.log('gearLine', gearLine)}
                            {console.log('gerasEquipements', gearsEquipments)} */}
                            {
                              gearLine?.Caracs?.find(
                                (carac) => carac.name === 'Motorisation'
                              )?.value
                                ? gearsEquipments?.find(
                                    (equipment) =>
                                      equipment.id === gearLine.MasterDataEquipmentId
                                  )?.xAxis?.unit
                                : ''
                              // gearLine?.Caracs?.find((carac) => carac.name === 'Motorisation')?.value && gearLine?.Caracs?.find((carac) => carac.name === 'Motorisation')?.value[0] !== 'Electrique' ? 'L/h' :
                              //   gearLine?.Caracs?.find((carac) => carac.name === 'Motorisation')?.value && gearLine?.Caracs?.find((carac) => carac.name === 'Motorisation')?.value[0] === 'Electrique'  ? 'kW' :
                              //     ""
                            }
                          </div>
                          {/* source */}
                          <div
                            style={{
                              width: '12%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            className="sourceCell"
                          >
                            {zoneSourceToDisplay(gearLine.origin)}
                          </div>
                          {/* Delete Icon */}
                          <div
                            style={{
                              width: '2%',
                              display: 'flex',
                              justifyContent: 'center',
                              marginTop: '10px',
                            }}
                          >
                            <DeleteIcon
                              onClick={() =>
                                dispatch(
                                  calculatorUpdate(
                                    'gearLines',
                                    gearLines.filter(
                                      (line) => line.id !== gearLine.id
                                    )
                                  )
                                )
                              }
                              style={{
                                display: gearLines.length > 1 ? 'block' : 'none',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (isDisplayEquipment(gearLine)) {
                      let quantity = gearLine.quantity;

                      if (isEquipmentFromParentZone(gearLine)) {
                        const quantityProrata = calculateAreaZoneProrata(gearLine);
                        quantity =
                          Math.round(quantity * quantityProrata * 10000) / 10000;
                      }

                      return (
                        <div
                          className="transport-line disabled"
                          style={{ justifyContent: 'flex-start' }}
                          key={`line-${lineIndex}`}
                        >
                          {/* Logo Add new line */}
                          <div style={{ width: '3%' }}>
                            {lineIndex === gearLines.length - 1 && (
                              <img
                                onClick={() => addNewGear()}
                                style={{
                                  width: '25px',
                                  height: '25px',
                                  marginTop: '10px',
                                  marginRight: '20px',
                                  cursor: 'pointer',
                                }}
                                src={add}
                              />
                            )}
                          </div>
                          {/* Autocomplete Type Vehicule/gear */}
                          <div style={{ width: '22%' }}>
                            <Autocomplete
                              value={gearsEquipmentsFiltered.find(
                                (masterData) =>
                                  masterData.id === gearLine.MasterDataEquipmentId
                              )}
                              autoComplete={true}
                              key={gearLine.MasterDataEquipmentId}
                              id="checkboxes-tags-demo"
                              defaultValue={gearsEquipmentsFiltered.find(
                                (masterData) =>
                                  masterData.id === gearLine.MasterDataEquipmentId
                              )}
                              options={
                                gearsEquipmentsFiltered
                                // !isRegulationProject ?
                              }
                              disabled={true}
                              noOptionsText={t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_B4B268',
                                { defaultValue: 'Aucun résultat trouvé' }
                              )}
                              style={{ paddingRight: '30px' }}
                              size={'small'}
                              getOptionLabel={(option) => option.name || ''}
                              renderInput={(params) => (
                                <TextField
                                  // key={uuidv4()}
                                  style={{ borderRadius: '10px' }}
                                  defaultValue={gearsEquipmentsFiltered.find(
                                    (masterData) =>
                                      masterData.id ===
                                      gearLine.MasterDataEquipmentId
                                  )}
                                  {...params}
                                  placeholder={t(
                                    'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_2AFC8B',
                                    { defaultValue: 'Ajouter un transport' }
                                  )}
                                  variant="outlined"
                                  className="textFieldV1"
                                  disabled={true}
                                />
                              )}
                            />
                          </div>
                          {/* Quantity */}
                          <div style={{ width: '15%' }}>
                            <TextField
                              key={`${gearLine}-${lineIndex}`}
                              value={quantity}
                              style={{ width: '100%' }}
                              placeholder={t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEGEAR.PH_622B4E',
                                { defaultValue: 'Quantité' }
                              )}
                              variant="outlined"
                              className="textFieldV1"
                              disabled={true}
                            />
                          </div>
                          {/* Unit */}
                          <div
                            style={{
                              width: '10%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            h
                          </div>
                          {/* Motorisation */}
                          {gearLine?.Caracs &&
                            gearLine?.Caracs.map((carac, caracIndex) => {
                              if (carac.name === 'Motorisation' && carac?.values) {
                                return (
                                  <div
                                    style={{
                                      width: '15%',
                                      paddingRight: '10px',
                                      paddingLeft: '10px',
                                    }}
                                    key={`${carac.name}-${caracIndex}`}
                                  >
                                    <SelectUi
                                      // key={uuidv4()}
                                      MenuProps={{
                                        disableScrollLock: true,
                                      }}
                                      // defaultValue={carac.value && carac.value[0]}
                                      defaultValue={
                                        carac?.value?.length === 1 ? carac.value : []
                                      }
                                      disabled={true}
                                      style={{ width: '100%', height: '40px' }}
                                      placeholder="  -"
                                      variant="outlined"
                                      className="selectUiV1"
                                    >
                                      {carac.values.map((value, i) => (
                                        <MenuItem
                                          value={value}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                          }}
                                          // key={`motorisationGear${value} ${i}`}
                                        >
                                          {value}
                                        </MenuItem>
                                      ))}
                                    </SelectUi>
                                  </div>
                                );
                              }
                            })}
                          {gearLine.MasterDataEquipmentId === null && (
                            <div
                              style={{
                                width: '15%',
                                paddingRight: '10px',
                                paddingLeft: '10px',
                              }}
                            ></div>
                          )}

                          {/* Consumption */}
                          <div style={{ width: '15%' }}>
                            <TextField
                              key={`${gearLine}-${lineIndex}`}
                              // value={gearLine.quantity}
                              value={gearLines[lineIndex].consumption}
                              style={{
                                width: '100%',
                                paddingRight: '10px',
                                paddingLeft: '10px',
                              }}
                              placeholder="Quantité"
                              // variant="outlined"
                              className="textFieldV1"
                              disabled={true}
                            />
                          </div>
                          {/* Unit Consumption */}
                          <div
                            style={{
                              width: '8%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {gearLine?.Caracs?.find(
                              (carac) => carac.name === 'Motorisation'
                            )?.value
                              ? gearsEquipments?.find(
                                  (equipment) =>
                                    equipment.id === gearLine.MasterDataEquipmentId
                                )?.xAxis?.unit
                              : ''}
                          </div>
                          {/* source */}
                          <div
                            style={{
                              width: '12%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            className="sourceCell disabled"
                          >
                            {zoneSourceToDisplay(gearLine.origin)}
                          </div>
                          {/* Delete Icon */}
                          <div
                            style={{
                              width: '2%',
                              display: 'flex',
                              justifyContent: 'center',
                              marginTop: '10px',
                            }}
                          ></div>
                        </div>
                      );
                    }

                    return null;
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Screen ByExcavatedSelected */}
      {isInputByExcavatedSelected && (
        <div className="gearCalculatorWrapper">
          <BreadCrumb />
          <div className="gear">
            <div className="totalGazoilInputWrapper">
              {calculatorTotalExcavatedGear.map((totalExcavatedRow, index) => {
                return (
                  <div key={totalExcavatedRow.origin.uniqueId}>
                    {RowInputByExcaved(totalExcavatedRow, index)}
                  </div>
                );
              })}
            </div>
            <AddRowExcavated />
          </div>
        </div>
      )}
      {/* bottom buttons */}
      <Footer />
    </>
  );
};

export default WorksiteGear;
