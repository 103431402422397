/* eslint-disable no-unused-vars */
import { Dispatch } from 'redux';
import { MapDispatchToPropsParam, MapStateToPropsParam, connect } from 'react-redux';

import ProductsChoice from '../../app/products/ProductsChoice';

import { updateProductsValue, initDatasProducts } from '../../actions/products';

interface IProducts {
  productView: string;
}

interface IProjects {
  isMenuOpen: boolean;
}

type State = {
  products: IProducts;
  projects: IProjects;
};

const mapStateToProps: MapStateToPropsParam<{}, {}, State> = (state: State) => ({
  productView: state.products.productView,
  isMenuOpen: state.projects.isMenuOpen,
});

const mapDispatchToProps: MapDispatchToPropsParam<{}, {}> = (
  dispatch: Dispatch
) => ({
  updateProductsValue: (name: string, value: string) => {
    dispatch(updateProductsValue(name, value));
  },
  initDatasProducts: () => {
    dispatch(initDatasProducts());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsChoice);
