export const FETCH_IMPORT_DATE = 'FETCH_IMPORT_DATE';
export const LOAD_IMPORT_DATE = 'LOAD_IMPORT_DATE';
export const FETCH_IMPORT_DATAS = 'FETCH_IMPORT_DATAS';
export const LOAD_IMPORT_DATAS = 'LOAD_IMPORT_DATAS';
export const SET_LOADING_IMPORT = 'SET_LOADING_IMPORT';
export const SEND_IMPORT_EDIT = 'SEND_IMPORT_EDIT';
export const DELETE_IMPORT = 'DELETE_IMPORT';
export const CONFIRM_DELETE_IMPORT = 'CONFIRM_DELETE_IMPORT';
export const SEND_IMPORT = 'SEND_IMPORT';
export const DELETE_ROWS_IMPORT = 'DELETE_ROWS_IMPORT';
export const AFTER_DELETE_ROWS = 'AFTER_DELETE_ROWS';
export const SET_DATA_ERROR = 'SET_DATA_ERROR';
export const DELETE_ALL_ERRORS = 'DELETE_ALL_ERRORS';
export const CONFIRM_ALL_DELETE = 'CONFIRM_ALL_DELETE';
export const RETRACT_IMPORT = 'RETRACT_IMPORT';
export const CHANGE_IMPORT_DATAS = 'CHANGE_IMPORT_DATAS';
export const VERIFY_ROWS_EQUIPMENT = 'VERIFY_ROWS_EQUIPMENT';
export const UPDATE_IMPORT_LINE = 'UPDATE_IMPORT_LINE';
export const FILTER_IMPORT_LIST = 'FILTER_IMPORT_LIST';
export const OPEN_MORE_OPTIONS = 'OPEN_MORE_OPTIONS';
export const OPEN_VALID_ALERT = 'OPEN_VALID_ALERT';
export const CLOSE_VALID_ALERT = 'CLOSE_VALID_ALERT';
export const FETCH_MORE_IMPORT_DATAS = 'FETCH_MORE_IMPORT_DATAS';
export const SET_FILTER_IMPORT = 'SET_FILTER_IMPORT';
export const GET_STATS_IMPORT = 'GET_STATS_IMPORT';
export const SEND_MESSAGE_SLACK = 'SEND_MESSAGE_SLACK';
export const SEND_MESSAGE_TEAMS_FAQ = 'SEND_MESSAGE_TEAMS_FAQ';
export const SEND_MESSAGE_TRELLO = 'SEND_MESSAGE_TRELLO';
export const REFRESH_DATA_AFTER_IMPORT = 'REFRESH_DATA_AFTER_IMPORT';
export const FETCH_PEP_FDES = 'FETCH_PEP_FDES';
export const RESEARCH_SOURCE_NAME = 'RESEARCH_SOURCE_NAME';
export const LOAD_SEARCH_DATAS = 'LOAD_SEARCH_DATAS';
export const CUT_LINES = 'CUT_LINES';
export const DUPLICATE_LINES = 'DUPLICATE_LINES';
export const ADD_DUPLICATE_LINE = 'ADD_DUPLICATE_LINE';
export const FILTER_BY_ARCHIVED = 'FILTER_BY_ARCHIVED';
export const RESET_IMPORT_STATES = 'RESET_IMPORT_STATES';
export const PATCH_LINES = 'PATCH_LINES';
export const REFRESH_IA = 'REFRESH_IA';
export const RESET_DATA_AFTER_FILTER_CHANGED = 'RESET_DATA_AFTER_FILTER_CHANGED';

export const fetchImportDate = (id, needAnimation, creationImport) => ({
  type: FETCH_IMPORT_DATE,
  id,
  needAnimation,
  creationImport,
});

export const loadImportDate = (importDate, needAnimation, creationImport) => ({
  type: LOAD_IMPORT_DATE,
  importDate,
  needAnimation,
  creationImport,
});

export const fetchImportDatas = (
  idProject,
  importId,
  refresh,
  socketFetchData,
  forceOffset
) => ({
  type: FETCH_IMPORT_DATAS,
  idProject,
  importId,
  refresh,
  socketFetchData,
  forceOffset,
});

export const loadImportDatas = (
  importDatas,
  concat,
  refresh,
  currentProject,
  allGroups,
  currentAllotment,
  nodesAvailableForProjectType,
  offset
) => ({
  type: LOAD_IMPORT_DATAS,
  importDatas,
  concat,
  refresh,
  currentProject,
  allGroups,
  currentAllotment,
  nodesAvailableForProjectType,
  offset,
});

export const setLoadingImport = () => ({
  type: SET_LOADING_IMPORT,
});

export const sendImportEdit = (editionLines) => ({
  type: SEND_IMPORT_EDIT,
  editionLines,
});

export const deleteImport = () => ({
  type: DELETE_IMPORT,
});

export const confirmDeleteImport = (importId) => ({
  type: CONFIRM_DELETE_IMPORT,
  importId,
});

export const sendImport = (unique) => ({
  type: SEND_IMPORT,
  unique,
});

export const deleteRowsImport = (rowId) => ({
  type: DELETE_ROWS_IMPORT,
  rowId,
});

export const afterDeleteRows = (idsToDelete, currentProject, importCounts) => ({
  type: AFTER_DELETE_ROWS,
  idsToDelete,
  currentProject,
  importCounts,
});

export const setDataError = (falseData) => ({
  type: SET_DATA_ERROR,
  falseData,
});

export const deleteAllErrors = () => ({
  type: DELETE_ALL_ERRORS,
});

export const confirmAllDelete = () => ({
  type: CONFIRM_ALL_DELETE,
});

export const retractImport = () => ({
  type: RETRACT_IMPORT,
});

export const changeImportDatas = (editLines) => ({
  type: CHANGE_IMPORT_DATAS,
  editLines,
});

export const verifyRowsEquipment = (lineId, patchCarac) => ({
  type: VERIFY_ROWS_EQUIPMENT,
  lineId,
  patchCarac,
});

export const updateImportLine = (
  line,
  currentProject,
  statusValidation,
  count,
  currentAllotment,
  allGroups
) => ({
  type: UPDATE_IMPORT_LINE,
  line,
  currentProject,
  statusValidation,
  count,
  currentAllotment,
  allGroups,
});

export const filterImportList = (resultFiltered) => ({
  type: FILTER_IMPORT_LIST,
  resultFiltered,
});

export const openMoreOptions = (line) => ({
  type: OPEN_MORE_OPTIONS,
  line,
});

export const openValidAlert = () => ({
  type: OPEN_VALID_ALERT,
});

export const closeValidAlert = () => ({
  type: CLOSE_VALID_ALERT,
});

export const fetchMoreImportDatas = () => ({
  type: FETCH_MORE_IMPORT_DATAS,
});

export const setFilterImport = (filter) => ({
  type: SET_FILTER_IMPORT,
  filter,
});

export const getStatsImport = (good, bad) => ({
  type: GET_STATS_IMPORT,
  good,
  bad,
});

export const sendMessageSlack = (line) => ({
  type: SEND_MESSAGE_SLACK,
  line,
});

export const sendMessageTeamsFaq = (line) => ({
  type: SEND_MESSAGE_TEAMS_FAQ,
  line,
});

export const sendMessageTrello = (line) => ({
  type: SEND_MESSAGE_TRELLO,
  line,
});

export const refreshDataAfterImport = (
  scrollPosition,
  idsToDelete,
  currentProject
) => ({
  type: REFRESH_DATA_AFTER_IMPORT,
  scrollPosition,
  idsToDelete,
  currentProject,
});

export const fetchPepFdes = (mdId, caracs) => ({
  type: FETCH_PEP_FDES,
  mdId,
  caracs,
});

export const researchSourceName = (isArchived) => ({
  type: RESEARCH_SOURCE_NAME,
  isArchived,
});

export const loadSearchDatas = (
  data,
  allGroups,
  currentAllotment,
  currentProject
) => ({
  type: LOAD_SEARCH_DATAS,
  data,
  allGroups,
  currentAllotment,
  currentProject,
});

export const cutLines = (row) => ({
  type: CUT_LINES,
  row,
});

export const duplicateLines = (initialRow, row, currentProject, count) => ({
  type: DUPLICATE_LINES,
  initialRow,
  row,
  currentProject,
  count,
});

export const addDuplicateLine = (initialRow) => ({
  type: ADD_DUPLICATE_LINE,
  initialRow,
});

export const filterByArchived = () => ({
  type: FILTER_BY_ARCHIVED,
});

export const resetImportStates = () => ({
  type: RESET_IMPORT_STATES,
});

export const patchLines = (action) => ({
  type: PATCH_LINES,
  action,
});

export const refreshIa = () => ({
  type: REFRESH_IA,
});
export const resetDataAfterFilterChanged = () => ({
  type: RESET_DATA_AFTER_FILTER_CHANGED,
});
