import { MenuItem, Select, Tooltip } from '@mui/material';
import formatNumber from '../../../services/mathematics/formatNumber';
import React from 'react';
import i18n from 'i18next';
import {
  Input,
  SearchGroup,
  Select as SelectNooco,
  RealSelect,
  Badge,
  DatePicker,
  RangeDatePicker,
} from '@photocarbone/nooco-ui';
import {
  basicSorter,
  getColumnUnitString,
  getComplexFormatLineValue,
  getSimpleFormatLineValue,
  lotAndNameSorter,
  multiLevelSorter,
  noticeThatUserEditInputs,
  tooltipForEquipmentFromCalculator,
} from './tableColumns';
import {
  findParentNode,
  handleKeyPress,
  returnBadgeTypeFiche,
  returnIconButton,
} from '../../../shared/utils';
import {
  EquipmentFromListEquipment,
  listEquipmentDisplayUnity,
} from './ListEquipmentsFunction';
import bundlePic from '../../../../assets/images/bundle/macro.svg';
import Loader from '@material-ui/core/CircularProgress';
import loadDedIcon from '../../../../assets/images/dashboard/loadingDed.png';
import HelpIcon from '@material-ui/icons/Help';
import { notIniesTypeFiche } from '../../../shared/groupByEquipments';
import ConditionalTruncatedTextWithTooltip from '../../../shared/ConditionalTruncatedTextWithTooltip/ConditionalTruncatedTextWithTooltip';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {
  ALLOTMENT_IDS,
  REFERENCE_TYPE_NAME,
  REGULATORY_PROJECTS,
  TYPE_EPCM,
  TYPE_EXPLOITATION,
  TYPE_GLOBAL_MONITORING,
  TYPE_NOOCO,
  TYPE_RE2020,
} from '../../../globalVariable/typeProject';
import CaracPopover from '../../../shared/CaracPopover/CaracPopover';
import { changeValueEquipments } from '../../../../actions/equipments';
import dayjs from 'dayjs';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Popover } from 'antd';
import FavoritesIcons from '../../../shared/FavoritesIcons';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import UpdateIcon from '@material-ui/icons/Update';
import {
  AllotmentNodeDTO,
  TREATMENT_MODE_VALUES_I18N_KEYS,
  ProjectDTO,
} from '../../../models/ProjectDTO';
import {
  CurrentScreen,
  DISABLED_ALLOTMENT_NODE_ID,
} from '../../../models/GeneralDTO';
import { Carac } from '@photocarbone/nooco-shared-api-def';
import LotSelect from './LotSelect';
import CertifiedLotSelect from './CertifiedLotSelect';
import CommentComponent from '../../../shared/CommentComponent';
import warningLogo from '../../../../assets/images/import/exclamation.png';
import { updateFormValue } from '../../../../actions/projects';
import { SheetDTO } from '../../Report/Widgets/exportRsee/RseeDataValidation';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { checkIfEnergieInRset } from '../ListEquipments';
import {
  SOURCE_BUILDING_SITE_CALCULATOR,
  TYPE_FICHE,
} from '../../../globalVariable/generalDefinitions';
import {
  getStatusAdjustableFluid,
  returnFormatLifeSpan,
} from '../../../shared/QuickAddBarEquipment/QuickAddBarEquipment';
import { HandleFluidsChangeReason } from '../../../shared/FluidsForm';
import { Dispatch } from 'redux';
import {
  filterAllotmentNodesDepandingOnProjectType,
  returnSearchGroupOptions,
  returnUnitDepandingOnAllotmentNode,
} from '../../../shared/utilsFunction';
import { getAntdLocale } from '../../../shared/getAntdLocale';

enum Status {
  VALIDATED = 'validated',
  RELIABLE = 'reliable',
  TO_CHECK = 'toCheck',
}

let unitsAllowed = (t): string[] => {
  return [
    'u',
    'uté',
    t('APP.LISTEQUIPMENTS.ALL_COLUMNS.UNITE', { defaultValue: 'unité' }),
    t('APP.LISTEQUIPMENTS.ALL_COLUMNS.UNITES', { defaultValue: 'unités' }),
    t('APP.LISTEQUIPMENTS.ALL_COLUMNS.UNITE_WITHOUT_SPECIAL_CHARACTER', {
      defaultValue: 'unite',
    }),
    t('APP.LISTEQUIPMENTS.ALL_COLUMNS.UNITES_WITHOUT_SPECIAL_CHARACTER', {
      defaultValue: 'unites',
    }),
  ];
};

const isOriginalUnitDifferentFromCurrentUnit = (record, t, unitSelected) => {
  let currentUnit = unitSelected || record.ProjectSheet?.Unit?.name;
  let originalUnit = record.ProjectSheet?.originalUnit;
  let unitsAllowedValues = unitsAllowed(t);

  const valuesForM2 = ['m2', 'M2', 'm²', 'M²', 'm ²', 'M ²'];
  const valuesForM3 = ['m3', 'M3', 'm³', 'M³', 'm ³', 'M ³'];
  const valuesForMl = ['m', 'M', 'ml', 'ML'];
  return (
    originalUnit &&
    originalUnit?.toLowerCase() !== currentUnit?.toLowerCase() &&
    !(valuesForM2.includes(originalUnit) && valuesForM2.includes(currentUnit)) &&
    !(valuesForM3.includes(originalUnit) && valuesForM3.includes(currentUnit)) &&
    !(valuesForMl.includes(originalUnit) && valuesForMl.includes(currentUnit)) &&
    !(
      unitsAllowedValues.includes(originalUnit.toLowerCase()) &&
      currentUnit ===
        t('APP.LISTEQUIPMENTS.ALL_COLUMNS.UNITE', { defaultValue: 'unité' })
    )
  );
};

export const statusItems = [
  {
    value: Status.VALIDATED,
    optionRender: (
      <Badge
        type="success"
        text={i18n.t('APP.LISTEQUIPMENTS.STATUS.VALIDATED', {
          defaultValue: 'vérifié',
        })}
      />
    ),
    label: (
      <span
        className="circle-option-render"
        style={{ background: '#06AB1E' }}
      ></span>
    ),
  },
  {
    value: Status.RELIABLE,
    optionRender: (
      <Badge
        type="warning"
        text={i18n.t('APP.LISTEQUIPMENTS.STATUS.RELIABLE', {
          defaultValue: 'fiable',
        })}
      />
    ),
    label: (
      <span
        className="circle-option-render"
        style={{ background: '#FAE100' }}
      ></span>
    ),
  },
  {
    value: Status.TO_CHECK,
    optionRender: (
      <Badge
        type="error"
        text={i18n.t('APP.LISTEQUIPMENTS.STATUS.TO_CHECK', {
          defaultValue: 'à vérifier',
        })}
      />
    ),
    label: (
      <span
        className="circle-option-render"
        style={{ background: '#EA1710' }}
      ></span>
    ),
  },
];

const disabledConditonDependingOnAllotmentNode = (
  allotmentNode: AllotmentNodeDTO,
  row: EquipmentFromListEquipment,
  currentProject: ProjectDTO,
  t
) => {
  if (
    checkIfEnergieInRset(row, currentProject, () => {}, t) ||
    row.source === SOURCE_BUILDING_SITE_CALCULATOR
  ) {
    return true;
  }
  return allotmentNode?.id === DISABLED_ALLOTMENT_NODE_ID;
};

export const handleChangedInCaracPopOver = (
  caracChanged,
  setValue: Function,
  row,
  dispatch: Dispatch,
  isUserStartEditColumns: boolean
) => {
  let keyToChange = 'caracs';

  if (Object.keys(caracChanged).includes(HandleFluidsChangeReason.FluidQuantity)) {
    keyToChange = 'fluid';
  }

  updateFormData(
    setValue,
    row,
    keyToChange,
    caracChanged,
    dispatch,
    isUserStartEditColumns
  );
};

export const updateFormData = (
  setValue: Function,
  record: any,
  key: string,
  value: string | number | AllotmentNodeDTO | Carac | SheetDTO | null,
  dispatch: any,
  isUserStartEditColumns: boolean,
  allAllotmentNodes: AllotmentNodeDTO[] = [],
  currentProject: ProjectDTO
) => {
  let keysRelatedToAllotmentNode = [
    'AllotmentNodeSheetAlreadySelected',
    'AllotmentNodeSheet',
  ];
  const allotmentNodeKey = keysRelatedToAllotmentNode.includes(key)
    ? 'AllotmentNode'
    : key;
  setValue(`ProjectSheets[${record.id}][${allotmentNodeKey}]`, value);

  if (key === 'AllotmentNode') {
    setValue(`ProjectSheets[${record.id}].caracs`, []);
    setValue(`ProjectSheets[${record.id}].SheetId`, null);
    setValue(`ProjectSheets[${record.id}].fluid`, null);
  } else if (key === 'AllotmentNodeSheet') {
    setValue(`ProjectSheets[${record.id}].type_fiche`, record.type_fiche);
    setValue(`ProjectSheets[${record.id}].SheetId`, {
      id: record.ProjectSheet.SheetId,
      name: record.name,
    });
  } else if (key === 'SheetId') {
    if (!value) return;

    let nodesLinkedToSheetAvailable =
      filterAllotmentNodesDepandingOnProjectType(
        value?.AllotmentNodes,
        currentProject?.Lots,
        allAllotmentNodes
      ) || [];

    setValue(
      `ProjectSheets[${record.id}].AllotmentNode`,
      nodesLinkedToSheetAvailable ? nodesLinkedToSheetAvailable[0] : null
    );
    setValue(`ProjectSheets[${record.id}].caracs`, []);
    setValue(`ProjectSheets[${record.id}].fluid`, null);
  }
  noticeThatUserEditInputs(dispatch, isUserStartEditColumns);
};

export const quantityColumn = (
  t,
  getColumnSearchProps,
  tableEditMode,
  setValue,
  dispatch,
  isUserStartEditColumns,
  listEquipementSortOrder,
  currentProject,
  getValues
) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.QTY', { defaultValue: 'Qté' }),
    dataIndex: 'quantity',
    align: 'center',
    key: 'quantity',
    width: 80,
    editable: true,
    ...getColumnSearchProps('quantity', t),
    fixed: 'left',
    // className: "custom-global-class-row", //TODO TO REMOVE TEST OK className of the cell !!! aussi pour cell du
    render: (text, record) => {
      const quantityField = getValues(`ProjectSheets[${record.id}].quantity`);

      if (record.type_fiche === 'bundle') {
        return (
          <span className="custom-global-class-row" style={{ marginLeft: '10px' }}>
            {formatNumber(record?.quantity)}
          </span>
        );
      } else {
        if ((record.lot || record.type_fiche?.includes('EMPTY')) && tableEditMode) {
          return (
            <>
              <Tooltip
                title={
                  record.source === 'Calculette chantier'
                    ? t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_626E9B', {
                        defaultValue:
                          'Suppression possible uniquement via la calculette chantier',
                      })
                    : ''
                }
              >
                <span className="custom-global-class-row">
                  <Input
                    data-testid="quantity-input-contributor-list"
                    key={tableEditMode ? record.id : undefined}
                    style={{ width: '100%' }}
                    disabled={
                      record.source === 'Calculette chantier' ||
                      disabledConditonDependingOnAllotmentNode(
                        record.AllotmentNodeNooco,
                        record,
                        currentProject,
                        t
                      )
                    }
                    onKeyPress={handleKeyPress}
                    defaultValue={quantityField || record.quantity}
                    onChange={(value) => {
                      if (value != record.quantity) {
                        updateFormData(
                          setValue,
                          record,
                          'quantity',
                          value,
                          dispatch,
                          isUserStartEditColumns
                        );
                      }
                    }}
                  />
                </span>
              </Tooltip>
            </>
          );
        } else if (record.lot || record.type_fiche?.includes('EMPTY')) {
          return (
            <>
              <p
                className="custom-global-class-row"
                style={{
                  // display: 'inline',
                  whiteSpace: 'nowrap',
                  marginLeft: record.type_fiche === 'bundle' ? '10px' : undefined,
                  // display: "flex",
                  // alignItems: 'center',
                  // height: '100%',
                }}
              >
                {formatNumber(record.quantity)}
              </p>
            </>
          );
        }
      }
    },
    check: true,
    sorter: (a, b) => a.quantity - b.quantity,
    ...(listEquipementSortOrder &&
      JSON.parse(listEquipementSortOrder)?.quantity && {
        defaultSortOrder: JSON.parse(listEquipementSortOrder).quantity,
      }),
  };
};

export const unitColumn = (buildingSitesIds, t, watch, allMds, tableEditMode) => {
  return {
    title: '',
    dataIndex: 'mdUnit',
    align: 'center',
    key: 'mdUnit',
    width: 50,
    fixed: 'left',
    render: (text, record) => {
      const AllotmentNodeField = watch(`ProjectSheets[${record.id}].AllotmentNode`);
      let unitLinkedToAllotmentNode = undefined;
      if (AllotmentNodeField && tableEditMode) {
        unitLinkedToAllotmentNode = returnUnitDepandingOnAllotmentNode(
          allMds,
          AllotmentNodeField
        );
      }

      return (
        <div className="custom-global-class-row">
          {isOriginalUnitDifferentFromCurrentUnit(
            record,
            t,
            unitLinkedToAllotmentNode
          ) && (
            <Tooltip
              title={t(
                'APP.LISTEQUIPMENTS.ALL_COLUMNS_OBJECT.EXPLAIN_UNIT_WARNING',
                {
                  originalUnit: record.ProjectSheet.originalUnit,
                  unitInProject:
                    unitLinkedToAllotmentNode || record.ProjectSheet?.Unit?.name,
                  defaultValue:
                    "Unité à vérifier. Dans le devis, l’unité est en {originalUnit} et l'équipement sur le projet est en {unitInProject}. Convertir la quantité au besoin",
                }
              )}
            >
              <img
                style={{ marginLeft: '10px', width: '20px' }}
                src={warningLogo}
                alt=""
              />
            </Tooltip>
          )}
          {listEquipmentDisplayUnity(
            record,
            buildingSitesIds,
            unitLinkedToAllotmentNode
          )}{' '}
        </div>
      );
    },
    check: true,
  };
};

export const typeFicheColumn = (
  t,
  getColumnSearchProps,
  modalLoadingDed = [],
  handleOpenModal
) => {
  return {
    title: (
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.31862 0.833285L4.21862 5.89995C3.87696 6.44995 4.27696 7.16662 4.92696 7.16662H11.1186C11.7686 7.16662 12.1686 6.44995 11.827 5.89995L8.73529 0.833285C8.66162 0.711316 8.55771 0.610434 8.43362 0.540411C8.30952 0.470387 8.16945 0.433594 8.02696 0.433594C7.88447 0.433594 7.74439 0.470387 7.6203 0.540411C7.4962 0.610434 7.39229 0.711316 7.31862 0.833285Z"
          fill="#A3AED0"
        />
        <path
          d="M12.6104 16.3334C14.6814 16.3334 16.3604 14.6544 16.3604 12.5834C16.3604 10.5123 14.6814 8.83337 12.6104 8.83337C10.5393 8.83337 8.86035 10.5123 8.86035 12.5834C8.86035 14.6544 10.5393 16.3334 12.6104 16.3334Z"
          fill="#A3AED0"
        />
        <path
          d="M1.36068 15.9167H6.36068C6.81901 15.9167 7.19401 15.5417 7.19401 15.0833V10.0833C7.19401 9.625 6.81901 9.25 6.36068 9.25H1.36068C0.902344 9.25 0.527344 9.625 0.527344 10.0833V15.0833C0.527344 15.5417 0.902344 15.9167 1.36068 15.9167Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    dataIndex: 'type_fiche',
    key: 'type_fiche',
    fixed: 'left',
    group: 'calcul',
    width: 60,
    ...getColumnSearchProps('type_fiche', t),
    render: (text, record) => {
      if (record.type_fiche?.includes('Composant')) return;
      if (record.type_fiche === 'bundle') {
        return (
          <p className="custom-global-class-row">
            <img style={{ width: '27px' }} src={bundlePic} />
          </p>
        );
      }
      if (
        modalLoadingDed[0] === record.key &&
        record.id_inies === 25765 &&
        !record.ProjectSheet?.isDEDRequested
      ) {
        return (
          <div className="custom-global-class-row">
            <Loader
              variant="indeterminate"
              disableShrink
              style={{ color: '#73b7ec' }}
              size={25}
              thickness={4}
            />
          </div>
        );
      } else if (
        (record.id_inies === 25765 &&
          !record.ProjectSheet?.isDEDRequested &&
          record.type_fiche !== 'EMPTY' &&
          record.type_fiche !== 'custom_user') ||
        (record.Re2020Sheet &&
          record.Re2020Sheet.id_inies === 25765 &&
          !record.ProjectSheet?.isDEDRequested &&
          record.type_fiche !== 'EMPTY' &&
          record.type_fiche !== 'custom_user')
      ) {
        return (
          <Tooltip
            title={t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_D23A9D', {
              defaultValue: 'Demande de fiche DED',
            })}
          >
            {/* {modalLoadingDed && (
                <Loader variant="indeterminate"
                disableShrink
                style={{ color: "#73b7ec", marginLeft: '2em', marginTop: '1em' }}
                size={40}
                thickness={4} />
                )} */}
            <p
              className="custom-global-class-row"
              style={{ cursor: 'pointer' }}
              onClick={() => handleOpenModal(record)}
            >
              <HelpIcon />
            </p>
          </Tooltip>
        );
      } else if (
        record.ProjectSheet?.isDEDRequested ||
        (record.type_fiche === 'EMPTY' &&
          record.AllotmentNodeNooco?.id !== DISABLED_ALLOTMENT_NODE_ID)
      ) {
        return (
          <Tooltip
            title={t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_6FE2D0', {
              defaultValue: 'Demande de fiche DED en cours de traitement par INIES',
            })}
          >
            <img
              src={loadDedIcon}
              alt=""
              style={{ width: '24px', marginRight: '10px' }}
            />
          </Tooltip>
        );
      } else if (record.type_fiche) {
        return (
          <div className="custom-global-class-row">
            {' '}
            {returnBadgeTypeFiche(record.type_fiche, 'listEquipments')}
          </div>
        );
      }
    },
  };
};

export const nameColumn = (
  t,
  getColumnSearchProps,
  tableEditMode,
  listEquipementSortOrder,
  bundleOptions,
  searchBarOptions,
  certifiedAllotments,
  allGroups,
  nodesAvailableForProjectType,
  api,
  currentAllotment,
  toLineProductGraph,
  setValue,
  dispatch,
  isUserStartEditColumns,
  getValues,
  currentProject
) => {
  return {
    title: t('APP.TABLECOLUMNS.NAME', { defaultValue: 'Nom' }),
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: 300,
    fixed: 'left',
    check: true,
    ...getColumnSearchProps('name', t),
    render: (text, record) => {
      const AllotmentNodeField = getValues(
        `ProjectSheets[${record.id}].AllotmentNode`
      );
      const sheetField = getValues(`ProjectSheets[${record.id}].SheetId`);
      const searchGroupField = getValues(
        `ProjectSheets[${record.id}].searchGroupSelectType`
      );

      let depositName: string = record.ProjectSheet?.options?.treatmentMode
        ? `, ${t('APP.LISTEQUIPMENTS.TABLECOLUMNS.DEPOSIT_DETAILS', {
            details: t(
              TREATMENT_MODE_VALUES_I18N_KEYS[
                record.ProjectSheet.options.treatmentMode
              ]
            ),
            defaultValue: 'Dépose : {details}',
          })}`
        : '';

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            height: '100%',
          }}
          className="custom-global-class-row  "
        >
          {tableEditMode && !record.children ? (
            <SearchGroup
              t={t}
              gridStyle={'[first] 15% [line2] 85% [line3] 0'}
              companyId={currentProject.CompanyId}
              triggerBreadcrumb={() => {
                dispatch(updateFormValue('openBreadcrumb', true));
                dispatch(updateFormValue('currentLine', record));
              }}
              bundleOptions={bundleOptions}
              searchBarOptionsFiltered={searchBarOptions}
              findParentNode={findParentNode}
              projectTypeId={currentProject.ProjectTypeId}
              certifiedAllotments={certifiedAllotments}
              allAllotments={allGroups.flatAllotments}
              allotmentsAvailable={nodesAvailableForProjectType?.filter(
                (el) => !el.isObsolete
              )}
              dropdownHeight="70px"
              api={api}
              dropdownProps={{
                disabled: disabledConditonDependingOnAllotmentNode(
                  { id: ALLOTMENT_IDS.NOOCO } as AllotmentNodeDTO,
                  record,
                  currentProject,
                  t
                ),
                onSelect: () => {},
                defaultValue:
                  AllotmentNodeField === null
                    ? null
                    : sheetField
                    ? sheetField.name
                    : AllotmentNodeField
                    ? AllotmentNodeField.name
                    : notIniesTypeFiche.includes(record.type_fiche)
                    ? record.AllotmentNodeNooco?.name
                    : record.name,
                onChange: (value) => {
                  if (
                    value === 'equipements' ||
                    value === 'ensembles' ||
                    value === 'energie' ||
                    value === 'eau'
                  ) {
                    updateFormData(
                      setValue,
                      record,
                      'AllotmentNode',
                      null,
                      dispatch,
                      isUserStartEditColumns
                    );
                  } else if (typeof value === 'number') {
                    updateFormData(
                      setValue,
                      record,
                      'AllotmentNode',
                      allGroups.flatAllotments.find((el) => el.id === value),
                      dispatch,
                      isUserStartEditColumns
                    );
                  } else if (typeof value === 'object') {
                    updateFormData(
                      setValue,
                      record,
                      'SheetId',
                      value,
                      dispatch,
                      isUserStartEditColumns,
                      allGroups.flatAllotments,
                      currentProject
                    );
                  }
                },
                placeholder: 'Rechercher...',
                treeCheckable: false,
              }}
              selectProps1={{
                disabled: disabledConditonDependingOnAllotmentNode(
                  { id: ALLOTMENT_IDS.NOOCO } as AllotmentNodeDTO,
                  record,
                  currentProject,
                  t
                ),
                defaultValue: searchGroupField ?? record.searchGroupSelectType,
                onChange: (value) => {
                  updateFormData(
                    setValue,
                    record,
                    'searchGroupSelectType',
                    value.value,
                    dispatch,
                    isUserStartEditColumns
                  );
                },
                filterOptions: returnSearchGroupOptions(currentProject),
              }}
              selectProps2={{
                displayNone: true,
                ListType:
                  currentAllotment.id === 1
                    ? ['Nooco']
                    : [currentAllotment.name.toUpperCase()],
                noocoOptions: [],
                keyDisplay: 'name',
                keyItem: 'id',
                value: [],
              }}
              selectWidth="100px"
            />
          ) : (
            <>
              <div
                className={`${
                  !record.type_fiche?.includes('EMPTY') && !record.children
                    ? 'hoverLink'
                    : ''
                } `}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  margin: 0,
                  textAlign: 'left',
                  cursor:
                    !record.type_fiche?.includes('EMPTY') && !record.children
                      ? 'pointer'
                      : undefined,
                  fontWeight: record.children ? 'bolder' : 'initial',
                  fontSize:
                    window.screen.availHeight > 600 &&
                    window.screen.availHeight <= 800
                      ? '0.875rem'
                      : undefined,
                }}
                onClick={() =>
                  !record.type_fiche?.includes('EMPTY') && !record.children
                    ? toLineProductGraph(record)
                    : null
                }
              >
                <Tooltip
                  title={
                    record.children?.find((el) => el.isDisabled)
                      ? t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_631881', {
                          defaultValue:
                            'Des fiches INIES ont été modifiées et/ou supprimées dans ces équipements',
                        })
                      : ''
                  }
                >
                  <ErrorOutlineIcon
                    style={{
                      opacity:
                        record.children &&
                        record.children.find((el) => el.isDisabled)
                          ? 1
                          : 0,
                      color: '#f1bd00b3',
                      display: record.children ? 'block' : 'none',
                      marginRight: '10px',
                    }}
                  />
                </Tooltip>
                <span style={{ display: 'inline-grid' }}>
                  {ConditionalTruncatedTextWithTooltip({
                    text: `${record.name}${depositName}`,
                  })}
                </span>
              </div>

              {record.MasterDataEquipment?.hasFluid && (
                <p style={{ color: '#19d895', margin: 0, fontSize: '0.875rem' }}>
                  {t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_243745', {
                    defaultValue:
                      'Fluide frigorigène - DONNEE ENVIRONNEMENTALE CONVENTIONNELLE',
                  })}
                </p>
              )}
            </>
          )}
        </div>
      );
    },
    ...(listEquipementSortOrder &&
      JSON.parse(listEquipementSortOrder)?.name && {
        defaultSortOrder: JSON.parse(listEquipementSortOrder).name,
      }),
    sorter: (a, b) => lotAndNameSorter(a, b, 'name'),
  };
};

export const lotColumn = (
  t,
  getColumnSearchProps,
  tableEditMode,
  setValue,
  dispatch,
  isUserStartEditColumns,
  listEquipementSortOrder,
  allGroups,
  watch,
  currentProject
) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_61CDDB', {
      defaultValue: 'Lot',
    }),
    dataIndex: 'lot',
    align: 'center',
    key: 'lot',
    width: 100,
    editable: true,
    ...getColumnSearchProps('lot', t),
    check: false,
    group: 'Nooco',
    order: 1,
    render: (text, record) => {
      if (record.lot && record.type_fiche !== TYPE_FICHE.BUNDLE) {
        let currentNode = record.ProjectSheet.AllotmentNodes.find(
          (node) => node.AllotmentId === ALLOTMENT_IDS.NOOCO
        );

        if (tableEditMode && record.source !== 'Calculette chantier') {
          const AllotmentNodeField = watch(
            `ProjectSheets[${record.id}].AllotmentNode`
          );
          const SheetField = watch(`ProjectSheets[${record.id}].SheetId`);
          const searchGroupField = watch(
            `ProjectSheets[${record.id}].searchGroupSelectType`
          );

          let currentSearchGroup = searchGroupField ?? record.searchGroupSelectType;

          if (SheetField && SheetField.type_fiche === TYPE_FICHE.BUNDLE) return;

          return (
            <LotSelect
              disabled={disabledConditonDependingOnAllotmentNode(
                AllotmentNodeField || record.AllotmentNodeNooco,
                record,
                currentProject,
                t
              )}
              tableEditMode={tableEditMode}
              record={record}
              updateFormData={updateFormData}
              dispatch={dispatch}
              isUserStartEditColumns={isUserStartEditColumns}
              setValue={setValue}
              currentSearchGroup={currentSearchGroup}
            />
          );
        } else {
          let lotNode = findParentNode(
            currentNode,
            allGroups.flatAllotments,
            [],
            []
          )[0];

          return (
            <div className="custom-global-class-row">
              {returnIconButton(
                lotNode,
                tooltipForEquipmentFromCalculator({ tableEditMode, record })
              )}
            </div>
          );
        }
      }
    },
    ...(listEquipementSortOrder &&
      JSON.parse(listEquipementSortOrder)?.lot && {
        defaultSortOrder: JSON.parse(listEquipementSortOrder).lot,
      }),
    sorter: (a, b) => {
      try {
        if (a.lot.toLowerCase() < b.lot.toLowerCase()) {
          return -1;
        }

        if (a.lot.toLowerCase() > b.lot.toLowerCase()) {
          return 1;
        }
      } catch (error) {
        console.error(error);
      }

      return 0;
    },
  };
};

export const sousLotColumn = (
  t,
  getColumnSearchProps,
  tableEditMode,
  setValue,
  dispatch,
  isUserStartEditColumns,
  listEquipementSortOrder,
  allGroups,
  currentAllotment,
  currentProject,
  watch
) => {
  return {
    title: `${t('APP.LISTEQUIPMENTS.TABLECOLUMNS.SOUS_LOTS', {
      defaultValue: 'Sous-lot',
    })} ${
      currentProject.templateType === TYPE_NOOCO ||
      currentProject.templateType === TYPE_RE2020
        ? t('APP.LISTEQUIPMENTS.TABLECOLUMNS.RE2020_PROJECT_TYPE', {
            defaultValue: REFERENCE_TYPE_NAME.RE2020,
          })
        : currentProject.templateType === TYPE_EPCM
        ? t('APP.LISTEQUIPMENTS.TABLECOLUMNS.EPCM_PROJECT_TYPE', {
            defaultValue: REFERENCE_TYPE_NAME.EPCM,
          })
        : t('APP.LISTEQUIPMENTS.TABLECOLUMNS.CURRENT_PROJECT_TYPE', {
            defaultValue: currentProject.templateType,
          })
    }`,
    dataIndex: 're2020Lot',
    key: 're2020Lot',
    align: 'center',
    ...getColumnSearchProps('re2020Lot', t),
    width: 100,
    check: false,
    render: (text, record) => {
      if (record.ProjectSheet && record.type_fiche !== TYPE_FICHE.BUNDLE) {
        let currentNode = {
          ...record.ProjectSheet.AllotmentNodes.find(
            (node) => node.AllotmentId === currentAllotment.id
          ),
          color: record.ProjectSheet.AllotmentNodes.find(
            (node) => node.AllotmentId === ALLOTMENT_IDS.NOOCO
          )?.color,
        };

        if (tableEditMode && record.source !== 'Calculette chantier') {
          const AllotmentNodeField = watch(
            `ProjectSheets[${record.id}].AllotmentNode`
          );
          const SheetField = watch(`ProjectSheets[${record.id}].SheetId`);

          if (SheetField && SheetField.type_fiche === TYPE_FICHE.BUNDLE) return;

          return (
            <CertifiedLotSelect
              disabled={disabledConditonDependingOnAllotmentNode(
                AllotmentNodeField || record.AllotmentNodeNooco,
                record,
                currentProject,
                t
              )}
              currentNode={currentNode}
              tableEditMode={tableEditMode}
              record={record}
              updateFormData={updateFormData}
              dispatch={dispatch}
              isUserStartEditColumns={isUserStartEditColumns}
              setValue={setValue}
            />
          );
        } else {
          return (
            <div className="custom-global-class-row">
              {returnIconButton(
                {
                  ...currentNode,
                  color: record.ProjectSheet.AllotmentNodes.find(
                    (node) => node.AllotmentId === ALLOTMENT_IDS.NOOCO
                  )?.color,
                },
                tooltipForEquipmentFromCalculator({ tableEditMode, record })
              )}
            </div>
          );
        }
      }
    },
    ...(listEquipementSortOrder &&
      JSON.parse(listEquipementSortOrder)?.re2020Lot && {
        defaultSortOrder: JSON.parse(listEquipementSortOrder).re2020Lot,
      }),
    sorter: (a, b) => lotAndNameSorter(a, b, 're2020Lot'),
  };
};

export const re2020SheetColumn = (t, listEquipementSortOrder, currentProject) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_01CE17', {
      value:
        currentProject.templateType === TYPE_NOOCO ||
        currentProject.templateType === TYPE_RE2020
          ? REFERENCE_TYPE_NAME.RE2020
          : currentProject.templateType === TYPE_EPCM
          ? REFERENCE_TYPE_NAME.EPCM
          : currentProject.templateType,
      defaultValue: 'Correspondance fiche {value}',
    }),
    dataIndex: 'Re2020Sheet',
    key: 'Re2020Sheet',
    ...(listEquipementSortOrder &&
      JSON.parse(listEquipementSortOrder)?.Re2020Sheet && {
        defaultSortOrder: JSON.parse(listEquipementSortOrder).Re2020Sheet,
      }),
    sorter: (a, b) => {
      if (a.Re2020Sheet?.name < b.Re2020Sheet?.name) {
        return -1;
      }

      if (a.Re2020Sheet?.name > b.Re2020Sheet?.name) {
        return 1;
      }

      return 0;
    },
    width: 200,

    check: false,
    render: (text, record) => {
      let name;

      if (record.lot || record.type_fiche?.includes('EMPTY')) {
        if (record.Re2020Sheet && record.Re2020Sheet.name) {
          name = record.Re2020Sheet.name;
        } else
          name = t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_047EE2', {
            defaultValue: 'Pas de correspondance',
          });
        return (
          <div
            className="custom-global-class-row"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'start',
            }}
          >
            <p style={{ margin: 0 }} style={{ display: 'inline-grid' }}>
              {ConditionalTruncatedTextWithTooltip({ text: name })}
            </p>
          </div>
        );
      }
    },
  };
};

export const caracsColumn = (
  tableEditMode,
  getValues,
  setValue,
  dispatch,
  isUserStartEditColumns,
  currentProject,
  allGroups
) => {
  return {
    name: 'Caractéristiques',
    dataIndex: 'carac',
    key: 'carac',
    align: 'center',
    width: 50,
    fixed: 'left',
    check: true,
    render: (text, record) => {
      const formAllotmentNodeSelect = getValues(`ProjectSheets[${record.id}]`);

      let masterDataLinkedToNode = formAllotmentNodeSelect?.AllotmentNode
        ? allGroups.type.find(
            (md) =>
              md.id === formAllotmentNodeSelect.AllotmentNode.MasterDataEquipmentId
          )
        : record.MasterDataEquipment;

      const searchGroupField = getValues(
        `ProjectSheets[${record.id}].searchGroupSelectType`
      );

      let currentSearchGroup = searchGroupField ?? record.searchGroupSelectType;

      return (
        !record.children && (
          <p>
            <CaracPopover
              AllotmentNodeId={
                formAllotmentNodeSelect?.AllotmentNode?.id ||
                record.AllotmentNodeNooco?.id
              }
              ProjectSheetId={record.ProjectSheet?.id}
              caracValues={[]}
              setCaracValues={(caracChanged) => {
                handleChangedInCaracPopOver(
                  caracChanged,
                  setValue,
                  record,
                  dispatch,
                  isUserStartEditColumns
                );
              }}
              currentSearchGroup={currentSearchGroup}
              unamortizedValues={null}
              setUnamortizedValues={() => {}}
              isUnamortizedChecked={false}
              isPhotovoltaicEquipmentSelected={false}
              isIniesSelected={false}
              isAdjustableFluid={getStatusAdjustableFluid(
                masterDataLinkedToNode,
                currentProject.templateType,
                formAllotmentNodeSelect?.SheetId
              )}
              screen={CurrentScreen.listContributor}
              row={record}
              disabledContributorList={!tableEditMode}
            />
          </p>
        )
      );
    },
  };
};

export const lifeSpanColumn = (t, getColumnSearchProps, listEquipementSortOrder) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.DVE', {
      defaultValue: 'DVE',
    }),
    dataIndex: 'ddv',
    ...getColumnSearchProps('ddv', t),
    key: 'ddv',
    ...(listEquipementSortOrder &&
      JSON.parse(listEquipementSortOrder)?.ddv && {
        defaultSortOrder: JSON.parse(listEquipementSortOrder).ddv,
      }),
    sorter: (a, b) => basicSorter(a, b, 'ddv'),
    width: 75,
    render: (text, record) => (
      <div className="custom-global-class-row">
        {record.ProjectSheet?.modifiedDdv
          ? record.ProjectSheet.modifiedDdv
          : record.ddv}
      </div>
    ),
    align: 'center',
    check: true,
  };
};

export const impactByUnitRe2020 = (
  t,
  getColumnSearchProps,
  listEquipementSortOrder,
  currentIndicator,
  referenceSurfaceTag,
  currentProject
) => {
  const referenceTypeName =
    currentProject.templateType === TYPE_RE2020
      ? REFERENCE_TYPE_NAME.RE2020
      : currentProject.templateType === TYPE_EPCM
      ? REFERENCE_TYPE_NAME.EPCM
      : null;

  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.IMPACTBYUNIT.TITLE', {
      name: currentIndicator.shortName,
      unit: currentIndicator.unit,
      doesReferenceTypeExist: referenceTypeName !== null,
      referenceTypeName,
      defaultValue:
        '{name} {doesReferenceTypeExist, select, true {{referenceTypeName}} other {}} PAR UNITE ({unit})',
    }),
    dataIndex: 'impactByUnitRe2020',
    ...(listEquipementSortOrder &&
      JSON.parse(listEquipementSortOrder)?.impactByUnitRe2020 && {
        defaultSortOrder: JSON.parse(listEquipementSortOrder).impactByUnitRe2020,
      }),
    key: 'impactByUnitRe2020',
    render: (text, record) => (
      <div className="custom-global-class-row">
        {getComplexFormatLineValue(
          record,
          referenceSurfaceTag,
          'impactByUnitRe2020'
        )}
      </div>
    ),
    ...getColumnSearchProps('impactByUnitRe2020', t),
    sorter: (a, b) => multiLevelSorter(a, b, 'impactByUnitRe2020'),
    order: 15,
    align: 'center',
    group: 'impact',
    check: false,
    width: 110,
  };
};

export const impactByAreaColumn = (
  t,
  getColumnSearchProps,
  listEquipementSortOrder,
  currentIndicator,
  referenceSurfaceTag
) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_EC5F4F', {
      name: currentIndicator.shortName,
      unit: currentIndicator.unit,
      defaultValue: '{name} PAR M² ({unit}/m²)',
    }),
    dataIndex: 'impactByArea',
    ...(listEquipementSortOrder &&
      JSON.parse(listEquipementSortOrder)?.impactByArea && {
        defaultSortOrder: JSON.parse(listEquipementSortOrder).impactByArea,
      }),
    sorter: (a, b) => multiLevelSorter(a, b, 'impactByArea'),
    width: 170,
    key: 'impactByArea',
    align: 'center',
    ...getColumnSearchProps('impactByArea', t),
    render: (text, record) => {
      return (
        <div className="custom-global-class-row">
          {getComplexFormatLineValue(record, referenceSurfaceTag, 'impactByArea')}
        </div>
      );
    },
    check: false,
  };
};

export const totalAreaColumn = (
  t,
  getColumnSearchProps,
  listEquipementSortOrder,
  referenceSurfaceTag,
  currentIndicator
) => {
  return {
    title: `${currentIndicator.shortName} (${currentIndicator.unit})`,
    dataIndex: 'totalArea',
    group: 'impact',
    ...getColumnSearchProps('totalArea', t),
    order: 5,
    ...(listEquipementSortOrder &&
      JSON.parse(listEquipementSortOrder)?.totalArea && {
        defaultSortOrder: JSON.parse(listEquipementSortOrder).totalArea,
      }),
    sorter: (a, b) => multiLevelSorter(a, b, 'totalArea'),
    check: false,
    width: 170,
    render: (text, record) => {
      return (
        <div className="custom-global-class-row">
          {getSimpleFormatLineValue(record, referenceSurfaceTag, 'totalArea')}
        </div>
      );
    },
  };
};

export const impactByAreaRE2020Column = (
  t,
  getColumnSearchProps,
  listEquipementSortOrder,
  currentIndicator,
  referenceSurfaceTag,
  currentProject
) => {
  return {
    title: `${currentIndicator.shortName} ${t(
      'APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_670931',
      {
        name:
          currentProject.templateType === TYPE_NOOCO ||
          currentProject.templateType === TYPE_RE2020
            ? REFERENCE_TYPE_NAME.RE2020
            : REFERENCE_TYPE_NAME.EPCM,
        unit: currentIndicator.unit,
        defaultValue: '{name} PAR M² ({unit}/m²)',
      }
    )}`,
    dataIndex: 'impactRe2020',
    ...getColumnSearchProps('impactRe2020', t),
    width: 170,
    key: 'impactRe2020',
    ...(listEquipementSortOrder &&
      JSON.parse(listEquipementSortOrder)?.impactRe2020 && {
        defaultSortOrder: JSON.parse(listEquipementSortOrder).impactRe2020,
      }),
    sorter: (a, b) => multiLevelSorter(a, b, 'impactRe2020'),
    align: 'center',
    render: (text, record) => {
      return (
        <div className="custom-global-class-row">
          {getComplexFormatLineValue(record, referenceSurfaceTag, 'impactRe2020')}
        </div>
      );
    },
    check: false,
  };
};

export const impactRE2020Column = (
  t,
  getColumnSearchProps,
  listEquipementSortOrder,
  currentIndicator,
  referenceSurfaceTag,
  currentProject
) => {
  return {
    title: `${currentIndicator.shortName} ${
      currentProject.templateType === TYPE_EPCM
        ? REFERENCE_TYPE_NAME.EPCM
        : REFERENCE_TYPE_NAME.RE2020
    } (${currentIndicator.unit})`,
    dataIndex: 'globalImpactRe2020',
    ...getColumnSearchProps('globalImpactRe2020', t),
    key: 'globalImpactRe2020',
    render: (text, record) => {
      return (
        <div className="custom-global-class-row">
          {getSimpleFormatLineValue(
            record,
            referenceSurfaceTag,
            'globalImpactRe2020'
          )}
        </div>
      );
    },
    ...(listEquipementSortOrder &&
      JSON.parse(listEquipementSortOrder)?.globalImpactRe2020 && {
        defaultSortOrder: JSON.parse(listEquipementSortOrder).globalImpactRe2020,
      }),
    sorter: (a, b) => multiLevelSorter(a, b, 'globalImpactRe2020'),
    align: 'center',
    group: 'impact',
    order: 16,
    check: false,
    width: 170,
  };
};

export const functionColumn = (t, getColumnSearchProps) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_228E55', {
      defaultValue: 'Fonction',
    }),
    dataIndex: 'fonction',
    key: 'fonction',
    ...getColumnSearchProps('fonction', t),
    group: 'Nooco',
    order: 2,
    render: (text, record) => {
      return (
        <div className="custom-global-class-row alignLeft"> {record.fonction}</div>
      );
    },
    sorter: (a, b) => basicSorter(a, b, 'fonction'),
    align: 'center',
    width: 180,
    check: false,
  };
};

export const categoryColumn = (t, getColumnSearchProps) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_2D604C', {
      defaultValue: 'Catégorie',
    }),
    dataIndex: 'categorie',
    ...getColumnSearchProps('categorie', t),
    key: 'categorie',
    group: 'Nooco',
    order: 3,
    render: (text, record) => {
      return (
        <div className="custom-global-class-row alignLeft"> {record.categorie}</div>
      );
    },
    align: 'center',
    sorter: (a, b) => basicSorter(a, b, 'categorie'),
    width: 180,
    check: false,
  };
};

export const materialColumn = (t, getColumnSearchProps) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_C819EA', {
      defaultValue: 'Matériel',
    }),
    dataIndex: 'materiel',
    key: 'materiel',
    ...getColumnSearchProps('materiel', t),
    group: 'Nooco',
    sorter: (a, b) => basicSorter(a, b, 'materiel'),
    render: (text, record) => {
      return (
        <div className="custom-global-class-row alignLeft"> {record.materiel}</div>
      );
    },
    order: 4,
    align: 'center',
    width: 180,
    check: false,
  };
};

export const destinationColumn = (
  t,
  getColumnSearchProps,
  tableEditMode,
  dispatch,
  isUserStartEditColumns,
  zonesName,
  showBuildingSites,
  setValue
) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_7C1E7C', {
      defaultValue: 'Destination',
    }),
    dataIndex: 'destination',
    key: 'destination',
    ...getColumnSearchProps('destination', t),
    render: (text, record) => {
      if (tableEditMode && record.lot && record.source !== 'Calculette chantier') {
        let zonesItems = zonesName.filter(
          (zone) =>
            showBuildingSites || (!showBuildingSites && zone.type !== 'buildingSite')
        );
        return (
          <RealSelect
            defaultValue={record.destination}
            onChange={(value) => {
              updateFormData(
                setValue,
                record,
                'ZoneId',
                value?.value,
                dispatch,
                isUserStartEditColumns
              );
            }}
            className={'transparent-select'}
            items={zonesItems.map((zone) => ({
              ...zone,
              optionRender: zone.name,
              value: zone.id,
            }))}
            dropdownStyle={{ width: '150px' }}
          />
        );
      } else {
        return (
          <>
            <Tooltip
              title={
                record.source === 'Calculette chantier' && tableEditMode
                  ? t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_1F78C9', {
                      defaultValue:
                        'Suppression possible uniquement via la calculette chantier',
                    })
                  : ''
              }
            >
              <div
                className="custom-global-class-row alignLeft"
                style={{
                  cursor:
                    record.source === 'Calculette chantier' && tableEditMode
                      ? 'not-allowed'
                      : '',
                  justifyContent: 'flex-start!important',
                }}
              >
                {record.destination}
              </div>
            </Tooltip>
          </>
        );
      }
    },
    editable: true,
    sorter: (a, b) => basicSorter(a, b, 'destination'),
    align: 'center',
    group: 'carac',
    order: 3,
    width: 180,
    check: false,
  };
};

export const sourceColumn = (t, getColumnSearchProps) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_0EAC07', {
      defaultValue: 'Source',
    }),
    dataIndex: 'source',
    key: 'source',
    ...getColumnSearchProps('source', t),
    sorter: (a, b) => basicSorter(a, b, 'source'),
    render: (text, record) => {
      return (
        <div className="custom-global-class-row alignLeft"> {record.source}</div>
      );
    },
    align: 'center',
    group: 'carac',
    order: 4,
    width: 100,
    check: false,
  };
};

export const coeffColumn = (t, getColumnSearchProps) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_D0A40C', {
      defaultValue: 'Coefficient de renouvellement',
    }),
    dataIndex: 'coeff',
    ...getColumnSearchProps('coeff', t),
    key: 'coeff',
    sorter: (a, b) => basicSorter(a, b, 'coeff'),
    render: (text, record) => {
      return <div className="custom-global-class-row"> {record.coeff}</div>;
    },
    align: 'center',
    group: 'calcul',
    order: 5,
    width: 160,
    check: false,
  };
};

export const endDateColumn = (t, getColumnSearchProps) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_8889BB', {
      defaultValue: 'Date fin de validité',
    }),
    dataIndex: 'endDate',
    key: 'endDate',
    ...getColumnSearchProps('endDate', t),
    render: (text, record) =>
      record.expiration_date ? dayjs(record.expiration_date).format('L') : null,
    sorter: (a, b) => basicSorter(a, b, 'endDate'),
    align: 'center',
    group: 'calcul',
    order: 6,
    width: 160,
    check: false,
  };
};

export const lotCertifiedColumn = (t, getColumnSearchProps, currentProject) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.LOT', {
      value:
        currentProject.templateType === TYPE_EPCM
          ? REFERENCE_TYPE_NAME.EPCM
          : REFERENCE_TYPE_NAME.RE2020,
      defaultValue: 'Lot {value}',
    }),
    dataIndex: 'lotre2020',
    key: 'lotre2020',
    ...getColumnSearchProps('lotre2020', t),
    align: 'center',
    group: 'calcul',
    order: 1,
    render: (text, record) => {
      return <div className="custom-global-class-row"> {record.lotre2020}</div>;
    },
    check: false,
    width: 200,
    sorter: (a, b) => lotAndNameSorter(a, b, 'lotre2020'),
  };
};

export const moduleDColumn = (
  t,
  getColumnSearchProps,
  currentIndicator,
  currentProject,
  referenceSurfaceTag
) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_C5FC29', {
      value: getColumnUnitString(currentIndicator.unit, currentProject.refUnit),
      defaultValue: 'Module D {value}',
    }),
    dataIndex: 'moduleD',
    key: 'moduleD',
    ...getColumnSearchProps('moduleD', t),
    render: (text, record) =>
      getSimpleFormatLineValue(record, referenceSurfaceTag, 'moduleD'),
    sorter: (a, b) => a.moduleD - b.moduleD,
    align: 'center',
    group: 'impact',
    order: 6,
    check: false,
    width: 110,
  };
};

export const customNameColumn = (
  t,
  getColumnSearchProps,
  tableEditMode,
  setValue,
  dispatch,
  isUserStartEditColumns,
  currentProject,
  getValues
) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_EB116F', {
      defaultValue: 'Libellé source',
    }),
    dataIndex: 'originalName',
    key: 'originalName',
    ...getColumnSearchProps('originalName', t),
    editable: true,
    render: (text, record) => {
      if (tableEditMode && record.lot) {
        let defaultValue =
          getValues(`ProjectSheets[${record.id}].customName`) ||
          record.ProjectSheet?.customName ||
          record.ProjectSheet?.originalName ||
          null;

        return (
          <span className="custom-global-class-row">
            <Input
              style={{ width: '100%' }}
              defaultValue={defaultValue}
              disabledDebouncing={true}
              onChange={(value) => {
                if (
                  value === defaultValue ||
                  (defaultValue === null && value === '')
                ) {
                  return;
                }
                updateFormData(
                  setValue,
                  record,
                  'customName',
                  value,
                  dispatch,
                  isUserStartEditColumns
                );
              }}
            />
          </span>
        );
      }

      let customName =
        record.ProjectSheet?.customName || record.ProjectSheet?.originalName;

      return (
        <span className="custom-global-class-row" style={{ marginLeft: '10px' }}>
          {ConditionalTruncatedTextWithTooltip({ text: customName })}
        </span>
      );
    },
    sorter: (a, b) =>
      lotAndNameSorter(a.ProjectSheet, b.ProjectSheet, 'originalName'),
    align: 'center',
    group: 'carac',
    order: 2,
    check: currentProject.Sheets.find(
      (el) => el.ProjectSheet?.customName || el.ProjectSheet?.originalName
    )
      ? true
      : false,
    width: 200,
  };
};

export const originalRowColumn = (t, getColumnSearchProps) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_F62D16', {
      defaultValue: 'Ligne source',
    }),
    dataIndex: 'originalRow',
    key: 'originalRow',
    ...getColumnSearchProps('originalRow', t),
    sorter: (a, b) => a.originalRow - b.originalRow,
    render: (text, record) => {
      return (
        <div className="custom-global-class-row">
          {' '}
          {record.ProjectSheet?.originalRow}
        </div>
      );
    },
    align: 'center',
    group: 'carac',
    order: 1,
    check: false,
    width: 200,
  };
};

export const typeColumn = (t, getColumnSearchProps) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_82EB77', {
      defaultValue: 'Libellé Nooco',
    }),
    dataIndex: 'type',
    key: 'type',
    ...getColumnSearchProps('type', t),
    sorter: (a, b) => basicSorter(a, b, 'type'),
    render: (text, record) => {
      return (
        <div className="custom-global-class-row alignLeft">
          {' '}
          {ConditionalTruncatedTextWithTooltip({ text: record.type })}
        </div>
      );
    },
    align: 'center',
    group: 'Nooco',
    order: 6,
    check: false,
    width: 200,
  };
};

export const iniesColumn = (
  t,
  getColumnSearchProps,
  tableEditMode,
  setValue,
  dispatch,
  isUserStartEditColumns
) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.ID_INIES', {
      defaultValue: 'ID INIES',
    }),
    dataIndex: 'id_inies',
    key: 'id_inies',
    ...getColumnSearchProps('id_inies', t),
    sorter: (a, b) => basicSorter(a, b, 'id_inies'),
    render: (text, record) => {
      return record.lot && tableEditMode ? (
        <>
          <Input
            defaultValue={record.id_inies}
            onBlur={(e) => {
              if (e.target.value.length >= 4) {
                updateFormData(
                  setValue,
                  record,
                  'id_inies',
                  e.target.value,
                  dispatch,
                  isUserStartEditColumns
                );
                return;
              }
            }}
          />
        </>
      ) : (
        <div className="custom-global-class-row">
          {!record.type_fiche?.includes('EMPTY') && record.id_inies !== 25765 ? (
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://www.base-inies.fr/iniesV4/dist/consultation.html?id=${record.id_inies}`}
            >
              {record.id_inies}
            </a>
          ) : null}
        </div>
      );
    },
    group: 'calcul',
    order: 3,
    align: 'center',
    check: false,
    width: 110,
  };
};

export const stockCColumn = (t, getColumnSearchProps, referenceSurfaceTag) => {
  return {
    title: t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_680B08', {
      defaultValue: 'StockC (kg C/m²)',
    }),
    dataIndex: 'stockc',
    key: 'stockc',
    align: 'center',
    ...getColumnSearchProps('stockc', t),
    group: 'impact',
    order: 10,
    check: false,
    width: 110,
    sorter: (a, b) => multiLevelSorter(a, b, 'stockC'),
    render: (text, record) => {
      return (
        <div className="custom-global-class-row">
          {getSimpleFormatLineValue(record, referenceSurfaceTag, 'stockC')}{' '}
        </div>
      );
    },
  };
};

export const productionColumn = (
  t,
  getColumnSearchProps,
  referenceSurfaceTag,
  currentIndicator,
  currentProject
) => {
  return {
    title: `${currentIndicator.shortName} ${t(
      'APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_63DAA8',
      { defaultValue: 'Production (A1-A3)' }
    )} ${getColumnUnitString(currentIndicator.unit, currentProject.refUnit)}`,
    dataIndex: 'Production',
    key: 'production',
    align: 'center',
    group: 'impact',
    ...getColumnSearchProps('production', t),
    order: 11,
    check: false,
    width: 130,
    sorter: (a, b) => multiLevelSorter(a, b, 'production'),
    render: (text, record) => {
      return (
        <div className="custom-global-class-row">
          {getSimpleFormatLineValue(record, referenceSurfaceTag, 'production')}
        </div>
      );
    },
  };
};

export const edificationColumn = (
  t,
  getColumnSearchProps,
  referenceSurfaceTag,
  currentIndicator,
  currentProject
) => {
  return {
    title: `${currentIndicator.shortName} ${t(
      'APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_2C2EAA',
      { defaultValue: 'Edification (A4-A5)' }
    )} ${getColumnUnitString(currentIndicator.unit, currentProject.refUnit)}`,
    dataIndex: 'Edification',
    key: 'edification',
    ...getColumnSearchProps('edification', t),
    align: 'center',
    group: 'impact',
    order: 12,
    check: false,
    width: 130,
    sorter: (a, b) => multiLevelSorter(a, b, 'edification'),
    render: (text, record) => {
      return (
        <div className="custom-global-class-row">
          {' '}
          {getSimpleFormatLineValue(record, referenceSurfaceTag, 'edification')}
        </div>
      );
    },
  };
};

export const entretienColumn = (
  t,
  getColumnSearchProps,
  referenceSurfaceTag,
  currentIndicator,
  currentProject
) => {
  return {
    title: `${currentIndicator.shortName} ${t(
      'APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_C43E69',
      { defaultValue: 'Entretien et maintenance (B1-B5)' }
    )} ${getColumnUnitString(currentIndicator.unit, currentProject.refUnit)}`,
    dataIndex: 'Entretien',
    key: 'entretien',
    ...getColumnSearchProps('entretien', t),
    align: 'center',
    group: 'impact',
    order: 13,
    check: false,
    width: 130,
    sorter: (a, b) => multiLevelSorter(a, b, 'entretien'),
    render: (text, record) => {
      return (
        <div className="custom-global-class-row">
          {getSimpleFormatLineValue(record, referenceSurfaceTag, 'entretien')}
        </div>
      );
    },
  };
};

export const endImpactColumn = (
  t,
  getColumnSearchProps,
  referenceSurfaceTag,
  currentIndicator,
  currentProject
) => {
  return {
    title: `${currentIndicator.shortName} ${t(
      'APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_4D9C1E',
      { defaultValue: 'Fin de vie (C1-C4)' }
    )} ${getColumnUnitString(currentIndicator.unit, currentProject.refUnit)}`,
    dataIndex: 'Fin',
    key: 'fin',
    align: 'center',
    group: 'impact',
    ...getColumnSearchProps('fin', t),
    order: 14,
    check: false,
    width: 130,
    sorter: (a, b) => multiLevelSorter(a, b, 'fin'),
    render: (text, record) => {
      return (
        <div className="custom-global-class-row">
          {getSimpleFormatLineValue(record, referenceSurfaceTag, 'fin')}
        </div>
      );
    },
  };
};

export const arrowColumn = (t, isAllCollapsed, toggleCollapseAll) => {
  return {
    title: () => {
      return (
        <button
          className={`toggleCollapseAll ${
            isAllCollapsed ? 'isExpanded' : 'notExpanded'
          }`}
          onClick={() => toggleCollapseAll()}
        >
          <Tooltip
            title={
              isAllCollapsed
                ? t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_A68924', {
                    defaultValue: 'Tout replier',
                  })
                : t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_997C5D', {
                    defaultValue: 'Tout déplier',
                  })
            }
          >
            <KeyboardDoubleArrowDownIcon />
          </Tooltip>
        </button>
      );
    },
    dataIndex: 'collapseAll',
    align: 'center',
    key: 'collapseAll',
    width: 50,
    fixed: 'left',
    check: true,
  };
};

export const actionsColumn = (
  t,
  tableEditMode,
  currentProject,
  licenceTypeAccess,
  deleteSelection,
  updateNewInies
) => {
  return {
    title: null,
    dataIndex: 'actions',
    align: 'center',
    fixed: 'right',

    width: 47,
    key: 'actions',
    check: true,
    className: 'header-column-to-hide',
    render: (text, record) => {
      let sheet = currentProject.isRe2020 ? record.Re2020Sheet : record;

      if (tableEditMode) {
        return <div />;
      }

      if (record.lot) {
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <Popover
              color="#344750"
              content={() => {
                return (
                  <div
                    className="tooltip-homemade"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Tooltip
                      title={
                        record?.source === 'Calculette chantier'
                          ? t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_A7D1CD', {
                              defaultValue:
                                'Suppresion possible uniquement via la calculette chantier',
                            })
                          : record?.source === 'Calculette eau'
                          ? t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_060D2C', {
                              defaultValue:
                                'Suppresion possible uniquement via la calculette eau',
                            })
                          : licenceTypeAccess === 'pro'
                          ? t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_514F78', {
                              defaultValue: 'Supprimer un équipement',
                            })
                          : t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_7C8F52', {
                              defaultValue:
                                'Fonctionnalité disponible avec une licence pro seulement',
                            })
                      }
                    >
                      <DeleteIcon
                        onClick={() => {
                          if (
                            record?.source !== 'Calculette chantier' &&
                            record?.source !== 'Calculette eau' &&
                            licenceTypeAccess === 'pro'
                          ) {
                            deleteSelection([record]);
                          }
                        }}
                        style={{
                          cursor: 'pointer',
                          marginRight: '10px',
                          display: 'block',
                          fill:
                            record?.source === 'Calculette chantier' ||
                            record?.source === 'Calculette eau' ||
                            licenceTypeAccess !== 'pro'
                              ? 'gray'
                              : '',
                        }}
                      />
                    </Tooltip>
                    {record.type_fiche !== 'users' &&
                      record.type_fiche !== 'REFURBISHED' &&
                      record.type_fiche !== 'FORFAIT' && (
                        <FavoritesIcons
                          SheetId={record.ProjectSheet.SheetId}
                          screen={'list'}
                        />
                      )}
                  </div>
                );
              }}
            >
              <MoreHorizIcon />
            </Popover>
            <Tooltip
              title={
                licenceTypeAccess !== 'pro'
                  ? t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_D0972A', {
                      defaultValue:
                        'Fonctionnalité disponible avec une licence pro seulement',
                    })
                  : record.type_fiche === 'users' && record.Re2020Sheet.endDateEquiv
                  ? t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_917489', {
                      defaultValue:
                        "Correspondance RE2020 obsolète. Modifier l'équipement pour mettre à jour",
                    })
                  : sheet.isDisabled && !sheet.isProductDisabled
                  ? t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_F31B15', {
                      defaultValue:
                        'Cette fiche a été modifiée sur INIES cliquer ici pour l’actualiser',
                    })
                  : ''
              }
            >
              <UpdateIcon
                onClick={() => {
                  if (licenceTypeAccess !== 'pro') {
                    return null;
                  }

                  if (
                    (sheet.isDisabled && !sheet.isProductDisabled) ||
                    (record.type_fiche === 'users' &&
                      record.Re2020Sheet.endDateEquiv)
                  ) {
                    updateNewInies(record);
                    changeValueEquipments('isLoadingProject', true);
                  }
                }}
                style={{
                  marginLeft: '3px',
                  display:
                    record.type_fiche === 'users' && record.Re2020Sheet.endDateEquiv
                      ? 'inline-block'
                      : sheet.isProductDisabled
                      ? 'none'
                      : undefined,
                  opacity:
                    (sheet.isDisabled && !sheet.isProductDisabled) ||
                    (record.type_fiche === 'users' &&
                      record.Re2020Sheet.endDateEquiv)
                      ? 1
                      : 0,
                  cursor:
                    (sheet.isDisabled && !sheet.isProductDisabled) ||
                    (record.type_fiche === 'users' &&
                      record.Re2020Sheet.endDateEquiv &&
                      licenceTypeAccess === 'pro')
                      ? 'pointer'
                      : 'initial',
                }}
              />
            </Tooltip>
            <Tooltip
              title={
                sheet.isProductDisabled
                  ? t('APP.LISTEQUIPMENTS.TABLECOLUMNS.PH_455777', {
                      defaultValue: 'Cette fiche a été supprimée sur INIES',
                    })
                  : ''
              }
            >
              <ErrorOutlineIcon
                style={{
                  marginLeft: '3px',
                  opacity: sheet.isProductDisabled ? 1 : 0,
                  display:
                    sheet.isDisabled && !sheet.isProductDisabled
                      ? 'none'
                      : undefined,
                }}
              />
            </Tooltip>
          </div>
        );
      }
    },
  };
};

const CommentCell = ({ record }) => {
  const [currentComment, setCurrentComment] = React.useState(
    record?.ProjectSheet?.comment
  );
  return record.lot ? (
    <div>
      <CommentComponent
        currentComment={currentComment}
        setCurrentComment={setCurrentComment}
        currentScreen={CurrentScreen.listContributor}
        row={record}
      />
    </div>
  ) : null;
};

export const commentColumn = () => {
  return {
    title: null,
    dataIndex: 'comment',
    key: 'comment',
    render: (text, record) => <CommentCell record={record} />,
    align: 'center',
    group: 'carac',
    order: 4,
    width: 35,
    fixed: 'right',
    check: true,
    className: 'header-column-to-hide comment-wrapper',
  };
};

export const statusColumn = (
  t,
  tableEditMode,
  setValue,
  dispatch,
  isUserStartEditColumns,
  getColumnSearchProps,
  watch,
  currentProject,
  getValues
) => {
  return {
    title: `${t('APP.LISTEQUIPMENTS.TABLECOLUMNS.STATUS', {
      defaultValue: 'Statut',
    })}`,
    dataIndex: 'status',
    key: 'status',
    ...getColumnSearchProps('status', t),
    render: (text, record) => {
      const AllotmentNodeField = watch(`ProjectSheets[${record.id}].AllotmentNode`);
      const statusField = getValues(`ProjectSheets[${record.id}].status`);

      return record.lot ? (
        tableEditMode ? (
          <span className="custom-global-class-row">
            <RealSelect
              onChange={(value) => {
                updateFormData(
                  setValue,
                  record,
                  'status',
                  value.value,
                  dispatch,
                  isUserStartEditColumns
                );
              }}
              disabled={
                !tableEditMode ||
                disabledConditonDependingOnAllotmentNode(
                  AllotmentNodeField || record.AllotmentNodeNooco,
                  record,
                  currentProject,
                  t
                )
              }
              defaultValue={statusField || record.ProjectSheet.status}
              className={'transparent-select'}
              items={statusItems}
              dropdownStyle={{ width: '150px' }}
            />
          </span>
        ) : (
          <div className="custom-global-class-row">
            {
              statusItems.find((el) => el.value === record.ProjectSheet.status)
                ?.label
            }
          </div>
        )
      ) : null;
    },
    align: 'center',
    group: 'carac',
    order: '5',
    check: false,
    width: 100,
  };
};

const renderOneDate = (
  date: string,
  tableEditMode?: boolean,
  setValue?,
  record?: EquipmentFromListEquipment,
  dispatch?: Dispatch,
  isUserStartEditColumns?: boolean
) => {
  if (tableEditMode) {
    return (
      <DatePicker
        format={'DD/MM/YYYY'}
        onChange={(date) => {
          updateFormData(
            setValue,
            record,
            'lifespan',
            returnFormatLifeSpan(date),
            dispatch,
            isUserStartEditColumns
          );
        }}
        locale={getAntdLocale()}
        defaultValue={dayjs(date)}
      />
    );
  }
  return <p>{dayjs(date).format('L')}</p>;
};

const renderPeriodOfTime = (
  startDate: string,
  endDate: string,
  tableEditMode?: boolean,
  setValue?,
  record?: EquipmentFromListEquipment,
  dispatch?: Dispatch,
  isUserStartEditColumns?: boolean
) => {
  if (tableEditMode) {
    return (
      <RangeDatePicker
        format={'DD/MM/YYYY'}
        onChange={(dates) => {
          updateFormData(
            setValue,
            record,
            'lifespan',
            returnFormatLifeSpan(dates),
            dispatch,
            isUserStartEditColumns
          );
        }}
        locale={getAntdLocale()}
        defaultValue={[dayjs(startDate), dayjs(endDate)]}
      />
    );
  }
  return (
    <p>
      {dayjs(startDate).format('L')}
      <ArrowRightAltIcon style={{ marginBottom: '2px' }} />
      {dayjs(endDate).format('L')}
    </p>
  );
};

export const dateColumn = (
  t,
  currentProject: ProjectDTO,
  tableEditMode: boolean = false,
  setValue?: any,
  dispatch?: Dispatch,
  isUserStartEditColumns?: boolean
) => {
  return {
    title: t('APP.LISTACTIONS.INDEX.PH_1F8994', {
      defaultValue: 'Date',
    }),
    dataIndex: 'lifeSpan',
    key: 'lifeSpan',
    width: currentProject?.templateType === TYPE_GLOBAL_MONITORING ? 170 : 180,
    render: (value, record, index) => {
      let isGlobalMonitoring =
        currentProject?.templateType === TYPE_GLOBAL_MONITORING;
      let isExploitation = currentProject?.templateType === TYPE_EXPLOITATION;

      return isGlobalMonitoring && record.lifespan
        ? !record.lifespan[1] || !record.lifespan[1].value
          ? renderOneDate(
              record.lifespan[0].value,
              tableEditMode,
              setValue,
              record,
              dispatch,
              isUserStartEditColumns
            )
          : renderPeriodOfTime(
              record.lifespan[0].value,
              record.lifespan[1].value,
              tableEditMode,
              setValue,
              record,
              dispatch,
              isUserStartEditColumns
            )
        : isExploitation
        ? record.startDate === record.endDate
          ? renderOneDate(record.startDate)
          : renderPeriodOfTime(record.startDate, record.endDate)
        : null;
    },
  };
};

export const dataBaseColumn = (
  t,
  getColumnSearchProps,
  currentProject,
  referenceSurfaceTag,
  currentIndicator
) => {
  return {
    title: `${t('APP.LISTEQUIPMENTS.TABLECOLUMNS.DATABASE', {
      defaultValue: 'Base de donnée',
    })}`,
    dataIndex: 'database',
    key: 'database',
    ...getColumnSearchProps('database', t),
    group: 'calcul',
    order: 7,
    align: 'center',
    check: false,
    width: 110,
    render: (value, record) => <p style={{ marginRight: '30px' }}>{value}</p>,
  };
};
