/* eslint-disable no-unused-vars */

import {
  askingIsDed,
  changeValueEquipments,
  fetchIniesEquipments,
  updateNewInies,
} from '../../actions/equipments';
import {
  fetchProjectById,
  setSearchValue,
  updateFormValue,
} from '../../actions/projects';

import { connect } from 'react-redux';

import ListEquipments from '../../app/projects/EquipmentList/ListEquipments';

const mapStateToProps = (state, ownProps) => ({
  selectedRowKeys: state.equipments.selectedRowKeys,
  groupBy: state.equipments.groupBy,
  filterBy: state.equipments.filterBy,
  referenceSurfaceTag: state.equipments.referenceSurfaceTag,
  searchBarValue: state.equipments.searchBarValue,
  currentProject: state.projects.currentProject,
  zoneEquipmentId: state.projects.zoneEquipmentId,
  equipmentBuildingSiteId: state.projects.equipmentBuildingSiteId,
  zones: state.projects.parsedProject.zones,
  urbanNetworks: state.projects.parsedProject.urbanNetworks,
  importModal: state.projects.importModal,
  loadingDed: state.projects.loadingDed,
  fluids: state.projects.fluids,
  dataTable: state.equipments.dataTable,
  modalLoadingDed: state.projects.modalLoadingDed,
  searchText: state.equipments.searchText,
  filterLots: state.equipments.filterLots,
  hideColumns: state.equipments.hideColumns,
  columns: state.equipments.columns,
  isShown: state.equipments.isShown,
  lotMode: state.projects.lotMode,
  isMenuOpen: state.projects.isMenuOpen,
  currentIndicator: state.projects.currentIndicator,
  modalAdvanceInies: state.projects.modalAdvanceInies,
  isLoadingProject: state.equipments.isLoadingProject,
  iniesAdvanceLink: state.equipments.iniesAdvanceLink,
  allGroups: state.projects.allGroups,
  errorIdInies: state.equipments.errorIdInies,
  failedTab: state.imports.failedTab,
  scrollPosition: state.equipments.scrollPosition,
  addDisplay: state.equipments.addDisplay,
  inputValue: state.projects.inputValue,
  currentAllotment: state.projects.currentAllotment,
  projectAllot: state.projects.projectAllot,
  RE2020Lot: state.equipments.re2020lot,
  multipleActionOpen: state.imports.multipleActionOpen,
  isAdminOrga: state.projects.isAdminOrga,
  licenceTypeAccess: state.projects.licenceTypeAccess,
  isUserStartEditColumns: state.equipments.isUserStartEditColumns,
});

const mapDispatchToProps = (dispatch) => ({
  changeValueEquipments: (name, value) => {
    dispatch(changeValueEquipments(name, value));
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  askingIsDed: (row) => {
    dispatch(askingIsDed(row));
  },
  fetchIniesEquipments: (value) => {
    dispatch(fetchIniesEquipments(value));
  },
  setSearchValue: (data) => {
    dispatch(setSearchValue(data));
  },
  fetchProjectById: (id, indicatorId) => {
    dispatch(fetchProjectById(id, indicatorId));
  },
  updateNewInies: (sheet) => {
    dispatch(updateNewInies(sheet));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ListEquipments);
