import { dynamicCarbon } from './dynamic';

const sFunctions = require('./static');

/**
 * key is indice id.
 * arrayKey is static or dyamic. Can only be 0 or 1.
 * @type {{'1': [*, *]}}
 */

const indicesFunctions = {
  1: [sFunctions.staticCarbon, dynamicCarbon],
};

const calcElecProd = (lot, materiel, sheet) => {
  if (lot === 'CFO' && materiel === 'Panneau') {
    let efProdAc = 0;
    let efProd = 0;

    sheet.Caracs.forEach((carac) => {
      if (carac.name.includes('Eef_Prod_AC')) {
        efProdAc += Number(carac.SheetCarac.value[0]);
      } else if (carac.name.includes('Eef_Prod'))
        efProd += Number(carac.SheetCarac.value[0]);
    });

    if (efProd === 0 || efProdAc === 0) return 1;

    return efProdAc / efProd;
  }

  return 1;
};

const handleChantier = (res, usage, total) => {
  for (const [key] of Object.entries(usage)) {
    usage[key] = 0;
  }

  for (const [key] of Object.entries(res)) {
    res[key] = 0;
  }

  res.edifChantier = total;
};

const handleAcvResults = (projectMds, sheet, res, zoneArea, isChantier) => {
  const sheetMd = projectMds.find(
    (md) => md.id === sheet.ProjectSheet.MasterDataEquipmentId
  );
  const elecProdCoeff = calcElecProd(sheetMd.lot, sheetMd.materiel, sheet);
  let equipTotal = 0;

  const baseRes = res.baseACV;
  const usage = res.usageACV;

  for (const [key] of Object.entries(baseRes)) {
    baseRes[key] *= elecProdCoeff;
    baseRes[key] /= zoneArea;
    equipTotal += baseRes[key];
  }

  for (const [key] of Object.entries(usage)) {
    usage[key] *= elecProdCoeff;
    usage[key] /= zoneArea;
    equipTotal += usage[key];
  }

  // if its a chantier, add the total to chantier edif
  if (isChantier) {
    handleChantier(baseRes, usage, equipTotal);
    //baseRes.edifChantier = total;
  }

  return equipTotal * zoneArea;
};

/**
 * This functions gets the function from the pointer function array, and calls it.
 * The pointer function is supposed to calc the acv of every equip.
 * @param indiceId
 * @param project
 * @param decMds
 * @param zones
 * @returns {{}}
 */

const calcAcv = (indiceId, project, decMds, projectMds, zones) => {
  let equipsByZone = {};
  let acvFuncStatic, acvFuncDynamic;

  if (!indicesFunctions[indiceId]) {
    acvFuncStatic = indicesFunctions[1][0];
    acvFuncDynamic = indicesFunctions[1][1];
  } else {
    // get right function from parameters.
    acvFuncStatic = indicesFunctions[indiceId][0];
    acvFuncDynamic = indicesFunctions[indiceId][1];
  }

  let staticTotal = 0;
  let dynamicTotal = 0;

  // loop on sheets
  project.Sheets.forEach((sheet) => {
    try {
      const indicator = sheet.Indices.find((indice) => indice.id === indiceId);
      let sheetZone = zones.find((zone) => zone.id === sheet.ProjectSheet.ZoneId);
      let zoneArea = sheetZone.area;
      let projectDdv = project.ddv;
      let isChantier = sheetZone.type === 'buildingSite';

      sheet.isChantier = isChantier;

      // create map key in map
      if (!equipsByZone[sheetZone.id]) {
        equipsByZone[sheetZone.id] = {
          values: [],
          zoneArea,
        };
      }

      if (isChantier) {
        const parentZone = zones.find((zone) => zone.id === sheetZone.ParentId);

        projectDdv = 1;
        zoneArea = parentZone.area;
      }

      //Temp. Before, we used to calc only static or dynamic. Now we have to calc both.
      let staticRes, dynamicRes;

      //Call corresponding function to calculate acv
      // results is an object containing baseACV and usageACV.
      staticRes = acvFuncStatic(indicator, sheet, decMds, projectDdv, isChantier);
      dynamicRes = acvFuncDynamic(indicator, sheet, decMds, projectDdv, isChantier);
      sheet.staticAcvResults = staticRes;
      sheet.dynamicAcvResults = dynamicRes;
      sheet.acvResults = project.isAcvDynamic ? dynamicRes : staticRes;

      const staticAcv = handleAcvResults(
        projectMds,
        sheet,
        sheet.staticAcvResults,
        project.area,
        isChantier
      );
      const dynamicAcv = handleAcvResults(
        projectMds,
        sheet,
        sheet.dynamicAcvResults,
        project.area,
        isChantier
      );

      staticTotal += staticAcv;
      dynamicTotal += dynamicAcv;

      sheet.total = project.isAcvDynamic ? dynamicAcv : staticAcv;
    } catch (e) {
      console.log(e);
    }
  });

  //calc total divided by totalSurface
  return project.isAcvDynamic
    ? dynamicTotal / project.area
    : staticTotal / project.area;
};

export default calcAcv;
