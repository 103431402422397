import {
  BUILDING_TYPE_OFFICE,
  BUILDING_TYPE_HOUSE_DETACHED,
  BUILDING_TYPE_ID,
  BUILDING_TYPE_MULTI_UNIT_BUILDING,
  TEMPLATE_TYPE_NAME,
  TYPE_AIRPORT,
  TYPE_DATACENTER,
  TYPE_EPCM,
  TYPE_EXPLOITATION,
  TYPE_NOOCO,
  TYPE_OUTDOOR,
  TYPE_PROTOTYPE,
  TYPE_RE2020,
  TYPE_RENOVATION,
  returnBuildingTypeNameFromId,
} from '../../globalVariable/typeProject';

export const isBuildingTypeZoneEditable = (templateType: string): boolean => {
  const templateToInclude = [
    TYPE_AIRPORT,
    TYPE_DATACENTER,
    TYPE_PROTOTYPE,
    TYPE_OUTDOOR,
  ];

  if (templateToInclude.includes(templateType)) {
    return false;
  }

  return true;
};

export const isDisplayGroundExpansion = ({
  templateType,
  buildingTypeId,
  showAlternativeResult,
}: {
  templateType: string;
  buildingTypeId: number;
  showAlternativeResult?: number;
}): boolean => {
  if (templateType === TYPE_EPCM) {
    return true;
  }

  if (
    templateType === TYPE_RE2020 &&
    returnBuildingTypeNameFromId(buildingTypeId) === BUILDING_TYPE_OFFICE
  ) {
    return true;
  }

  if (
    templateType === TYPE_NOOCO &&
    showAlternativeResult &&
    returnBuildingTypeNameFromId(buildingTypeId) === BUILDING_TYPE_OFFICE
  ) {
    return true;
  }

  return false;
};

export const isDisplayLogementNumber = ({
  templateType,
  showAlternativeResult = false,
  buildingTypeId,
}: {
  templateType: string;
  showAlternativeResult?: boolean;
  buildingTypeId: number;
}): boolean => {
  if (templateType === TYPE_EPCM) {
    return true;
  }
  if (templateType === TYPE_NOOCO && showAlternativeResult) {
    const buildingTypesToInclude = [
      BUILDING_TYPE_HOUSE_DETACHED,
      BUILDING_TYPE_MULTI_UNIT_BUILDING,
    ];

    if (
      buildingTypesToInclude.includes(returnBuildingTypeNameFromId(buildingTypeId))
    ) {
      return true;
    }
  }

  const buildingTypeToInclude = [
    BUILDING_TYPE_MULTI_UNIT_BUILDING,
    BUILDING_TYPE_HOUSE_DETACHED,
  ];
  const buildingTypeName = returnBuildingTypeNameFromId(buildingTypeId);

  if (
    templateType === TYPE_RE2020 &&
    buildingTypeToInclude.includes(buildingTypeName)
  ) {
    return true;
  }

  return false;
};

export const isDisplayOccupantNumber = ({
  templateType,
  showAlternativeResult = false,
  buildingTypeId,
}: {
  templateType: string;
  showAlternativeResult?: boolean;
  buildingTypeId: number;
}): boolean => {
  if (
    (templateType === TYPE_RE2020 && buildingTypeId !== BUILDING_TYPE_ID.OFFICE) ||
    templateType === TYPE_EPCM
  ) {
    return true;
  }
  if (templateType === TYPE_NOOCO && showAlternativeResult) {
    return true;
  }

  return false;
};

export const isDisplayCombles = ({
  templateType,
  buildingTypeId,
  showAlternativeResult,
}: {
  templateType: string;
  buildingTypeId: number;
  showAlternativeResult?: boolean;
}): boolean => {
  // must be display if RE2020 or if show alternative result(double calcul). And only for individual house.
  if (
    (templateType === TYPE_RE2020 || showAlternativeResult) &&
    returnBuildingTypeNameFromId(buildingTypeId) === BUILDING_TYPE_HOUSE_DETACHED
  ) {
    return true;
  }

  return false;
};

export const isDisplayReferenceArea = ({
  templateType,
  refUnit,
}: {
  templateType: string;
  refUnit: string;
}): boolean => {
  const templateWhosDisplayConditionalRefUnit = [
    TYPE_EXPLOITATION,
    TYPE_RENOVATION,
    TYPE_AIRPORT,
    TYPE_OUTDOOR,
    TYPE_DATACENTER,
    TYPE_PROTOTYPE,
  ];

  if (
    templateWhosDisplayConditionalRefUnit.includes(templateType) &&
    refUnit === 'total'
  ) {
    return false;
  }

  return true;
};

export const buildingTypesFilteredAccordingToTemplateType = (
  templateType: string,
  buildingTypes
) => {
  if (
    [
      TEMPLATE_TYPE_NAME.AIRPORT,
      TEMPLATE_TYPE_NAME.OUTDOOR,
      TEMPLATE_TYPE_NAME.DATACENTER,
      TEMPLATE_TYPE_NAME.PROTOTYPE,
    ].includes(templateType)
  ) {
    return buildingTypes;
  }

  if (templateType === TEMPLATE_TYPE_NAME.BBCA) {
    const buildingTypeIdsToIncludeInBbcaProject = [
      BUILDING_TYPE_ID.OFFICE,
      BUILDING_TYPE_ID.COLLECTIVE_RESIDENTIAL,
    ];
    return buildingTypes.filter((buildingType) =>
      buildingTypeIdsToIncludeInBbcaProject.includes(buildingType.id)
    );
  }

  let buildingTypeIdsToIncludeInOthersProjectType = [
    BUILDING_TYPE_ID.COLLECTIVE_RESIDENTIAL,
    BUILDING_TYPE_ID.INDIVIDUAL_HOUSE,
    BUILDING_TYPE_ID.HEALTHCARE_WITH_ACCOMMODATION,
    BUILDING_TYPE_ID.RESTAURANT,
    BUILDING_TYPE_ID.OFFICE,
    BUILDING_TYPE_ID.COMMERCE,
    BUILDING_TYPE_ID.CHILDCARE_CENTER,
    BUILDING_TYPE_ID.PRIMARY_EDUCATION,
    BUILDING_TYPE_ID.GYMNASIUM_OR_SPORTS_HALL,
    BUILDING_TYPE_ID.HEALTH_FACILITY,
    BUILDING_TYPE_ID.INDUSTRY_OR_WORKSHOP,
    BUILDING_TYPE_ID.TRIBUNAL,
    BUILDING_TYPE_ID.TERMINAL,
    BUILDING_TYPE_ID.SECONDARY_EDUCATION,
    BUILDING_TYPE_ID.YOUTH_HOSTEL,
    BUILDING_TYPE_ID.UNIVERSITY_AND_RESEARCH,
    BUILDING_TYPE_ID.HOSTEL,
    BUILDING_TYPE_ID.UNIVERSITY_RESIDENCE,
    BUILDING_TYPE_ID.PARKING,
    BUILDING_TYPE_ID.MUSEUM,
  ];

  return buildingTypes.filter((buildingType) =>
    buildingTypeIdsToIncludeInOthersProjectType.includes(buildingType.id)
  );
};

export const isDisplayAddBuildingAndZoneButton = (templateType: string): boolean => {
  const templateTypeToExclude = [
    TYPE_AIRPORT,
    TYPE_EXPLOITATION,
    TYPE_OUTDOOR,
    TYPE_DATACENTER,
    TYPE_PROTOTYPE,
  ];

  if (templateTypeToExclude.includes(templateType)) {
    return false;
  }

  return true;
};

export const isDisplayspeculativeDevelopment = (templateType: string): boolean => {
  const templateTypeToInclude = [TEMPLATE_TYPE_NAME.BBCA];

  if (templateTypeToInclude.includes(templateType)) {
    return true;
  }

  return false;
};

export const isDisplayRenovationType = (templateType: string): boolean => {
  const templateTypeToInclude = [TEMPLATE_TYPE_NAME.BBCA];

  if (templateTypeToInclude.includes(templateType)) {
    return true;
  }

  return false;
};
