/* eslint-disable react/no-unescaped-entities */
import { Button, Form, Modal } from 'react-bootstrap';
import { TreeSelect } from 'antd';
import ComposantVideForm from '../../shared/ComposantVideForm/index';
import React, { useState } from 'react';
import '../../shared/ComposantVideForm/composantVideForm.scss';
import { useTranslation } from 'react-i18next';

const DedModal = ({
  showDedModal,
  closeDedModal,
  updateFormValue,
  sendRequestDed,
  explainDed,
  nameDed,
  productDed,
  unitDed,
  nomenDed,
  iniesNomenclature,
  changeValueEquipments,
  composantVideForm,
}) => {
  const [validated, setValidated] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = () => {
    setValidated(true);

    console.log(
      composantVideForm?.explainDed,
      nameDed,
      productDed,
      unitDed,
      nomenDed
    );

    if (
      composantVideForm?.explainDed &&
      composantVideForm?.nameDed &&
      composantVideForm?.productDed &&
      composantVideForm?.unitDed &&
      composantVideForm?.nomenDed
    ) {
      sendRequestDed();
    }
  };

  return (
    <div>
      <Modal
        show={showDedModal}
        onHide={closeDedModal}
        size="lg"
        style={{ maxWidth: '70%', marginLeft: '20%', overflowY: 'auto' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('APP.EQUIPMENTLIST.DEDMODAL.PH_5085D3', {
              defaultValue: 'Demande de fiche DED',
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ComposantVideForm
            composantVideForm={composantVideForm}
            changeValueEquipments={changeValueEquipments}
            iniesNomenclature={iniesNomenclature}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDedModal}>
            {t('APP.EQUIPMENTLIST.DEDMODAL.PH_FF0E3E', {
              defaultValue: 'Fermer',
            })}
          </Button>

          <Button variant="primary" onClick={handleSubmit}>
            {t('APP.EQUIPMENTLIST.DEDMODAL.PH_40A334', {
              defaultValue: 'Envoyer la demande',
            })}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DedModal;
