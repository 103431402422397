/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import { CircularProgress } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Loader from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import Snackbar from '@material-ui/core/Snackbar';
import Spinner from '../../shared/Spinner';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import './import.scss';
import { Checkbox } from 'antd';
import { Input as InputNooco } from '@photocarbone/nooco-ui';
import { REFERENCE_TYPE_NAME, TYPE_EPCM } from '../../globalVariable/typeProject';
import { filterImportDatas, findParentNode } from '../../shared/utils';
import { klona } from 'klona';
import { resetDataAfterFilterChanged } from '../../../actions/import';
import { updateFavoritesValue } from '../../../actions/favorites';
import { useDispatch, useSelector } from 'react-redux';
import HeaderImport from '../../shared/HeaderImport';
import IA from '../../../assets/images/import/ia.png';
import ImportList from './ImportList';
import NoocoCheckbox from '../../shared/NoocoCheckbox';
import { useTranslation } from 'react-i18next';
import creation from '../../../assets/images/import/creation.png';
import init from '../../../assets/images/import/init.png';
import validate from '../../../assets/images/import/validate.png';
import _ from 'lodash';

const Import = ({
  importDate,
  fetchImportDatas,
  badLines,
  goodLines,
  waitLines,
  error,
  importDatas,
  currentProject,
  allGroups,
  lots,
  setLoadingImport,
  isImportLoading,
  deleteImport,
  dataError,
  isLoad,
  filterOn,
  updateFormValue,
  currentImport,
  researchSourceName,
  isSearchingData,
  searchSourceValue,
  setTabValue,
  importStep,
  creationImport,
  zones,
}) => {
  const [snackbarMessage, setSnackbarMessage] = useState();
  const inputRef = useRef();
  const currentRowIndexDisplayed = useRef();

  const history = useHistory();
  const [groups, setGroups] = useState();

  const {
    displayArchived,
    archivedLines,
    loadingProcess,
    errorPatchLines,
    isLoadingPatchLine,
    allLinesChecked,
    linesSelected,
    exceptLinesFromMultipleActions,
  } = useSelector((state) => state.imports);
  const { favoritesSnackbar } = useSelector((state) => state.favorites);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      importDate.length > 0 &&
      currentImport &&
      importDatas?.id === currentImport
    ) {
      return;
    } else if (importDate.length > 0 && currentImport && !creationImport) {
      setLoadingImport();

      fetchImportDatas(currentProject.id, currentImport);
    } else if (importDate.length > 0) {
      setLoadingImport();

      updateFormValue('currentImport', importDate[0].id);

      if (!creationImport) {
        fetchImportDatas(currentProject.id, importDate[0].id);
      }
    } else if (importDate.length === 0 && !creationImport) {
      history.push(`/projects/${currentProject.id}`);
    }
  }, [importDate]);
  // Remove lots that are not in the project
  useEffect(() => {
    const lotNames = [];

    for (let index = 0; index < lots.length; index += 1) {
      lotNames.push(lots[index].name.toUpperCase());
    }

    let newGroups = allGroups.type.filter((elem) => {
      let nodeAvailable = allGroups.flatAllotments.filter(
        (el) => el.MasterDataEquipmentId === elem.id
      );

      let bool = false;

      nodeAvailable.map((node) => {
        let detailsNode = findParentNode(node, allGroups.flatAllotments, [], []);

        console.log();

        if (detailsNode.some((el) => lotNames.includes(el.name.toUpperCase()))) {
          bool = true;

          return;
        }
      });

      return bool;
    });

    newGroups = { type: newGroups };

    setGroups(newGroups);
  }, [lots, allGroups]);

  const steps = [
    t('APP.IMPORT.INDEX.PH_54DF33', {
      defaultValue: "Initialisation de l'import",
    }),
    t('APP.IMPORT.INDEX.PH_D4C299', { defaultValue: 'IA' }),
    t('APP.IMPORT.INDEX.PH_7CDEFD', { defaultValue: "Création de l'import" }),
    t('APP.IMPORT.INDEX.PH_7A458A', { defaultValue: 'Validation des données' }),
  ];

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient(90deg, rgba(126,255,224,1) 0%, rgba(112,192,173,1) 100%, rgba(0,212,255,1) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient(90deg, rgba(126,255,224,1) 0%, rgba(112,192,173,1) 100%, rgba(0,212,255,1) 100%);',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: (
        <img
          src={init}
          style={{ height: '30px', width: '30px' }}
          alt
          className="filter-white-png "
        />
      ),
      2: (
        <img
          src={IA}
          style={{ height: '30px', width: '30px' }}
          alt
          className="filter-white-png "
        />
      ),
      3: (
        <img
          src={creation}
          style={{ height: '30px', width: '30px' }}
          alt
          className="filter-white-png "
        />
      ),
      4: (
        <img
          src={validate}
          style={{ height: '30px', width: '30px' }}
          alt
          className="filter-white-png "
        />
      ),
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
        style={{}}
      >
        {importStep + 1 === props.icon ? (
          <Loader
            variant="indeterminate"
            disableShrink
            style={{ color: 'white' }}
            size={30}
            thickness={4}
          />
        ) : (
          icons[String(props.icon)]
        )}
      </ColorlibStepIconRoot>
    );
  }

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient(100deg, rgba(126,255,224,1) 0%, rgba(112,192,173,1) 100%, rgba(0,212,255,1) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient(100deg, rgba(126,255,224,1) 0%, rgba(112,192,173,1) 100%, rgba(0,212,255,1) 100%)',
    }),
  }));

  const handleAllCheckbox = (value) => {
    if (value) {
      updateFormValue(
        'linesSelected',
        displayArchived
          ? dataError.map((el) => el.id)
          : dataError.filter((el) => !el.isArchived).map((el) => el.id)
      );
      updateFormValue('allLinesChecked', true);
    } else {
      updateFormValue('linesSelected', []);
      updateFormValue('allLinesChecked', false);
    }

    updateFormValue('exceptLinesFromMultipleActions', []);
  };

  let listRows = useMemo(
    () => filterImportDatas(filterOn, displayArchived, _.cloneDeep(dataError)),
    [dataError, displayArchived]
  );

  const handleSearchInput = (value) => {
    updateFormValue('offset', 0);
    updateFormValue('isSearchingData', Boolean(value));

    updateFormValue('searchSourceValue', value);
    setTimeout(() => {
      dispatch(resetDataAfterFilterChanged());
    }, 200);
  };

  return (
    <div className="wrapper">
      {(importDate === undefined || importDatas === undefined) && <Spinner />}
      {importDate !== undefined && importDatas !== undefined && (
        <div className="container">
          <Snackbar
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => {
              updateFormValue('errorPatchLines', null);
              setSnackbarMessage();
            }}
            open={favoritesSnackbar || errorPatchLines || snackbarMessage}
          >
            <Alert
              onClose={() => {
                dispatch(updateFavoritesValue('favoritesSnackbar', null));
                updateFormValue('errorPatchLines', null);
                setSnackbarMessage();
              }}
              severity={
                favoritesSnackbar
                  ? 'success'
                  : errorPatchLines || snackbarMessage
                  ? 'error'
                  : ''
              }
            >
              {favoritesSnackbar || errorPatchLines || snackbarMessage || ''}
            </Alert>
          </Snackbar>
          <div className="import">
            <div style={{ width: '100%' }}>
              <HeaderImport
                currentRowIndexDisplayed={currentRowIndexDisplayed}
                setSnackbarMessage={setSnackbarMessage}
                groups={groups}
                displayArchived={displayArchived}
              />
            </div>
          </div>
          <div className="errors">
            <div className="header-import">
              <Checkbox
                disabled={displayArchived}
                checked={
                  allLinesChecked && exceptLinesFromMultipleActions.length === 0
                }
                indeterminate={
                  (!allLinesChecked && linesSelected.length > 0) ||
                  (allLinesChecked && exceptLinesFromMultipleActions?.length > 0)
                }
                onChange={(e) => handleAllCheckbox(e.target.checked)}
                style={{ marginRight: '1em' }}
              />
              <h2 className="errors-title">
                {t('APP.IMPORT.INDEX.PH_7D9449', {
                  defaultValue: " Equipements / Matériaux en cours d'import",
                })}{' '}
                <span style={{ fontSize: '1rem', textTransform: 'italic' }}>
                  ({goodLines + waitLines + badLines + archivedLines} lignes)
                </span>{' '}
                <CircularProgress
                  size={window.screen.availHeight < 800 ? 20 : 30}
                  style={{
                    marginLeft: '10px',
                    opacity: isLoadingPatchLine || loadingProcess ? 1 : 0,
                    position: 'absolute',
                    right: '65%',
                  }}
                />
                <InputNooco
                  id="standard-adornment-password"
                  placeholder={t('APP.IMPORT.INDEX.PH_385C25', {
                    defaultValue: 'Rechercher un libellé du devis',
                  })}
                  inputRef={inputRef}
                  defaultValue={searchSourceValue}
                  style={{ width: '45%', marginLeft: '2em' }}
                  onChange={(value) => handleSearchInput(value)}
                  startIcon={<SearchIcon />}
                  endIcon={
                    <IconButton>
                      {isSearchingData && (
                        <CloseIcon
                          onClick={() => {
                            handleSearchInput('');
                            inputRef.current.value = '';
                          }}
                        />
                      )}
                    </IconButton>
                  }
                />
              </h2>
            </div>
            {isImportLoading && !creationImport ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '15%',
                }}
              >
                <Loader
                  variant="indeterminate"
                  disableShrink
                  style={{ color: '#73b7ec', marginTop: '1em' }}
                  size={60}
                  thickness={4}
                />
              </div>
            ) : creationImport ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '5%',
                }}
              >
                <Stepper
                  alternativeLabel
                  activeStep={importStep}
                  connector={<ColorlibConnector />}
                >
                  {steps.map((label) => (
                    <Step style={{ width: '300px' }} key={label}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <p
                  style={{
                    textAlign: 'center',
                    width: '280px',
                    marginTop: '2em',
                  }}
                >
                  {t('APP.IMPORT.INDEX.PH_E6180E', {
                    defaultValue:
                      'Import de votre fichier en cours : ceci peut prendre quelques dizaines de secondes',
                  })}
                </p>
              </div>
            ) : importDatas.ProjectImportElems &&
              importDatas.ProjectImportElems.length > 0 &&
              importDate.length > 0 &&
              groups &&
              !isImportLoading ? (
              <div className="import-all-lines import-container-line">
                <ImportList
                  currentRowIndexDisplayed={currentRowIndexDisplayed}
                  setTabValue={setTabValue}
                  groups={groups}
                  displayArchived={displayArchived}
                  list={listRows}
                />
              </div>
            ) : !dataError.find((el) => el.isValid === false) &&
              !dataError.find((el) => el.isValid === true) &&
              !error &&
              !isImportLoading ? (
              <div className="errors-dashboard">
                <p className="no-error">
                  {t('APP.IMPORT.INDEX.PH_056D3B', {
                    defaultValue: "Vous n'avez aucun équipement.",
                  })}
                </p>
              </div>
            ) : null}

            {error && (
              <div className="errors-dashboard">
                <p className="no-error">
                  {t('APP.IMPORT.INDEX.PH_8ED4C0', {
                    defaultValue: 'Une erreur est survenue durant votre import.',
                  })}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const getListDataToDisplay = (importDataList, displayArchived) => {
  if (displayArchived) {
    return importDataList.sort(function (a, b) {
      if (a.originalRow < b.originalRow) {
        return -1;
      }
      if (a.originalRow > b.originalRow) {
        return 1;
      }

      return 0;
    });
  } else {
    return importDataList
      .filter((el) => !el.isArchived)
      .sort(function (a, b) {
        if (a.originalRow < b.originalRow) {
          return -1;
        }
        if (a.originalRow > b.originalRow) {
          return 1;
        }

        return 0;
      });
  }
};

Import.propTypes = {
  fetchImport: PropTypes.func.isRequired,
};

export default Import;
