import {
  THREESHOLD_BBCA_COLLECTIVE_RESIDENTIAL,
  THREESHOLD_BBCA_OFFICE,
  ThresholdBbcaType,
} from '../../../../globalVariable/generalDefinitions';
import { BUILDING_TYPE_ID } from '../../../../globalVariable/typeProject';

export type getAchievedBBCAThresholdsType = {
  bbcaScore: number;
  buildingTypeId: number;
};
export const getAchievedBBCAThresholds = ({
  bbcaScore,
  buildingTypeId,
}): ThresholdBbcaType[] => {
  const thresholds: ThresholdBbcaType[] = [];

  let thresholdsMap;

  if (buildingTypeId === BUILDING_TYPE_ID.COLLECTIVE_RESIDENTIAL) {
    thresholdsMap = THREESHOLD_BBCA_COLLECTIVE_RESIDENTIAL;
  } else if (buildingTypeId === BUILDING_TYPE_ID.OFFICE) {
    thresholdsMap = THREESHOLD_BBCA_OFFICE;
  } else {
    return thresholds;
  }

  if (bbcaScore >= thresholdsMap.STANDARD) {
    thresholds.push('STANDARD');
  }
  if (bbcaScore > thresholdsMap.EFFICIENT) {
    thresholds.push('EFFICIENT');
  }
  if (bbcaScore > thresholdsMap.EXCELLENT) {
    thresholds.push('EXCELLENT');
  }

  return thresholds;
};

export const getBBCAThreshold = (
  buildingTypeId: number | null | undefined,
  threshold: ThresholdBbcaType
): number => {
  if (buildingTypeId === BUILDING_TYPE_ID.COLLECTIVE_RESIDENTIAL) {
    return THREESHOLD_BBCA_COLLECTIVE_RESIDENTIAL[threshold];
  } else if (buildingTypeId === BUILDING_TYPE_ID.OFFICE) {
    return THREESHOLD_BBCA_OFFICE[threshold];
  }
  return 0;
};
