import { QueryClient, useQuery } from 'react-query';
import { getProjectsShort } from '../../../apiCallFunctions/project/project';

export const useGetProjectsShort = (refModal: boolean, CompanyId: number) => {
  //TODO ce serait mieux d'utiliser un objet à la place d'arguments multiples
  //TODO Je ne change pas mais je ne comprends pas pourquoi on a besoin de créer et renvoyer le queryClient?
  //TODO L'ideal est de retourner la response du useQuery complète: return useQuery(...) à moins que l'on veuille traiter les data ou limiter l'accès à certaines méthodes.
  const queryClient = new QueryClient();

  const { data, isLoading, isError } = useQuery(['projectsShort', refModal], () => {
    if (refModal) {
      return getProjectsShort({ CompanyId });
    }
  });

  return {
    queryClient,
    data,
    isLoading,
    isError,
  };
};
