/* eslint-disable no-unused-vars */
import { Dispatch } from 'redux';
import { MapDispatchToPropsParam, MapStateToPropsParam, connect } from 'react-redux';

import AddActions from '../../app/exploitation/ListActions/AddActions';

import { updateExploitationValue } from '../../actions/exploitation';

interface IExploitation {
  addAction: string;
}

// interface IProjects {
//   allGroups: {
//     type: [];
//   },
//   currentIndicator: {};

// }

type State = {
  exploitation: IExploitation;
  //   projects: IProjects,
};

const mapStateToProps: MapStateToPropsParam<{}, {}, State> = (state: State) => ({
  addAction: state.exploitation.addAction,
});

const mapDispatchToProps: MapDispatchToPropsParam<{}, {}> = (
  dispatch: Dispatch
) => ({
  updateExploitationValue: (name: string, value: string) => {
    dispatch(updateExploitationValue(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddActions);
