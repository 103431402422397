/* eslint-disable no-unused-vars */
import { Dispatch } from 'redux';
import { MapDispatchToPropsParam, MapStateToPropsParam, connect } from 'react-redux';

import { NewActionDTO } from '../../app/models/ExploitationDTO';

import Transport from '../../app/exploitation/ListActions/AddActions/Transport';

import {
  createAction,
  resetAddAction,
  updateExploitationValue,
} from '../../actions/exploitation';

interface IExploitation {
  transportChoice: string;
  newAction: NewActionDTO;
}

interface IProjects {
  allGroups: {
    type: [];
  };
}

type State = {
  exploitation: IExploitation;
  projects: IProjects;
};

const mapStateToProps: MapStateToPropsParam<{}, {}, State> = (state: State) => ({
  transportChoice: state.exploitation.transportChoice,
  newAction: state.exploitation.newAction,
});

const mapDispatchToProps: MapDispatchToPropsParam<{}, {}> = (
  dispatch: Dispatch
) => ({
  updateExploitationValue: (name: string, value: string) => {
    dispatch(updateExploitationValue(name, value));
  },
  resetAddAction: () => {
    dispatch(resetAddAction());
  },
  createAction: (
    t: any,
    garbage: boolean,
    refurbished: boolean,
    transport: boolean
  ) => {
    dispatch(createAction(t, garbage, refurbished, transport));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Transport);
