export enum ScreenType {
  IMPORT = 'import',
  MAPPING_REF = 'mapping',
}

export enum OptionType {
  WATER = 'eau',
  ENERGY = 'energy',
  EQUIPMENTS = 'equipements',
  INIES = 'inies',
}
