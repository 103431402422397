import { Autocomplete } from '@mui/material';
import { MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import { Popover } from 'antd';
import { TYPE_EPCM, TYPE_RE2020 } from '../../../globalVariable/typeProject';
import { handleKeyPress, handlePaste } from '../../../shared/utils';
import FluidsForm from '../../../shared/FluidsForm';
import React from 'react';
import caracPic from '../../../../assets/images/import/specification.png';

const CaracsBlock = ({
  line,
  urbanNetworks,
  currentProject,
  verifyRowsEquipment,
  allGroups,
  managementEditFluids,
  CustomPaper,
}) => {
  return (
    <div className="line-caracs">
      <div
        className={!line.isArchived ? 'title-line' : undefined}
        style={{ display: 'flex' }}
      >
        <img src={caracPic} alt="" style={{ width: '20px' }} />
        <p className="subtitle ">Caractéristiques</p>
      </div>
      {line.Sheet?.type_fiche === 'bundle' ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p style={{ marginTop: '15px', marginLeft: '5px' }}>
            Pour modifier les caractéristiques de l'ensemble <br /> Cliquer sur
            "modifier l'ensemble"{' '}
          </p>
        </div>
      ) : !line.AllotmentNode ? (
        <p style={{ marginTop: '15px', marginLeft: '5px' }}>
          En attente de sélection d’un équipement​
        </p>
      ) : line.ProjectImportElemCaracs &&
        line.ProjectImportElemCaracs.length === 0 &&
        line.AllotmentNode ? (
        <p style={{ marginTop: '15px', marginLeft: '5px' }}>
          Aucune caractéristique requise​
        </p>
      ) : line.MasterDataEquipment?.isConnectedToUrbanNetwork &&
        line.MasterDataEquipment?.name &&
        !(
          line.MasterDataEquipment?.name.includes('photovoltaïque') &&
          currentProject.isRset
        ) ? (
        <div style={{ display: 'flex' }}>
          <p>Réseau</p>
          <Autocomplete
            id="checkboxes-tags-demo"
            options={
              line.MasterDataEquipment?.fonction !== 'Refroidissement'
                ? [...urbanNetworks.filter((el) => el.type.trim() === 'C')]
                : [...urbanNetworks.filter((el) => el.type.trim() === 'F')]
            }
            freeSolo={true}
            defaultValue={
              line.urbanNetworkId
                ? urbanNetworks.find((el) => el.id === line.urbanNetworkId)
                : null
            }
            PaperComponent={CustomPaper}
            onChange={(event, newValue) => {
              handleEdit('urbanNetwork', line.id, null, newValue);
            }}
            size={'small'}
            getOptionLabel={(option) =>
              `${option.name} - ${option.type.trim() === 'C' ? 'Chaud' : 'Froid'}`
            }
            renderInput={(params) => (
              <TextField
                style={{ width: '200%', marginLeft: '0.5em' }}
                {...params}
                placeholder="Rechercher un identifiant ou un nom de réseau"
              />
            )}
          />
        </div>
      ) : (
        line.AllotmentNode &&
        line.ProjectImportElemCaracs?.length > 0 &&
        line.ProjectImportElemCaracs.map((carac) => {
          let caracOptions = carac.valueList || null;

          if (line.caracCombos && line.caracCombos.name === carac.name) {
            caracOptions = line.caracCombos.valueList;
          } else if (
            line.caracCombos &&
            line.ProjectImportElemCaracs.find(
              (el) => el.name === line.caracCombos.name
            ) &&
            line.caracCombos.valueList[0].name === carac.name &&
            line.ProjectImportElemCaracs.find(
              (el) => el.name === line.caracCombos.name
            ).value
          ) {
            caracOptions = line.caracCombos.valueList.find(
              (el) =>
                el.name === carac.name &&
                el.value ===
                  line.ProjectImportElemCaracs.find(
                    (el) => el.name === line.caracCombos.name
                  ).value
            )?.valueList;
          } else if (
            line.caracCombos &&
            line.ProjectImportElemCaracs.find(
              (el) => el.name === line.caracCombos.name
            ) &&
            line.caracCombos.valueList[0].name === carac.name &&
            !line.ProjectImportElemCaracs.find(
              (el) => el.name === line.caracCombos.name
            ).value
          ) {
            caracOptions = [];
          }

          if (
            caracOptions &&
            line.caracCombos?.name === carac?.name &&
            caracOptions[0]?.name
          ) {
            caracOptions = caracOptions.filter((el) => el.valueList?.length > 0);
          }

          return (
            <div key={carac.name} className="line-carac">
              <p
                style={
                  line.ProjectImportElemCaracs?.length > 2
                    ? { width: '68%' }
                    : undefined
                }
              >
                {carac.name.length > 26 ? (
                  <Tooltip title={carac.name}>
                    <p>{`${carac.name.substring(0, 26)}[...]`}</p>
                  </Tooltip>
                ) : (
                  carac.name
                )}{' '}
                <span
                  style={{
                    display: !carac.unit ? 'none' : 'inline-block',
                    minWidth: '20px',
                  }}
                >
                  ({carac.unit})
                </span>
              </p>

              {((carac.valueList && carac.valueList?.length > 0) ||
                caracOptions) && (
                <Select
                  required
                  dropdownStyle={{ width: '220px' }}
                  type="text"
                  as="select"
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  custom
                  disabled={
                    line.SheetId
                      ? true
                      : (line.caracCombos?.valueList[0]?.name === carac.name &&
                          !line.ProjectImportElemCaracs.find(
                            (el) => el.name === line.caracCombos.name
                          )?.value) ||
                        line.isArchived
                  }
                  defaultValue={carac.value}
                  key={carac.value}
                  className="line-select"
                  style={{
                    width: '38%',
                    maxWidth: '175px',
                    marginTop: '0.2em',
                    borderBottom:
                      (line.caracCombos?.valueList[0]?.name === carac.name &&
                        !line.ProjectImportElemCaracs.find(
                          (el) => el.name === line.caracCombos.name
                        )?.value) ||
                      caracOptions.length === 1
                        ? '1px solid #ccc'
                        : !carac.value || carac.statusCode
                        ? '2px solid red'
                        : '1px solid black',
                  }}
                  onChange={(e, value) => {
                    let patchSecondDiscrete = false;

                    if (carac.name === line.caracCombos.name) {
                      patchSecondDiscrete = true;
                    }

                    const newList = line.ProjectImportElemCaracs.map((car) => {
                      if (car.id === carac.id) {
                        return { ...carac, value: e.target.value };
                      } else if (
                        patchSecondDiscrete &&
                        car.name === line.caracCombos.valueList[0].name
                      ) {
                        patchSecondDiscrete = { ...car, value: null };

                        return { ...car, value: null };
                      } else {
                        return car;
                      }
                    });

                    const newLine = {
                      ...line,
                      ProjectImportElemCaracs: newList,
                    };

                    if (
                      patchSecondDiscrete &&
                      line.caracCombos.valueList.find(
                        (el) => el.value === e.target.value
                      ).valueList?.length > 1
                    ) {
                      verifyRowsEquipment(line.id, {
                        newCarac: patchSecondDiscrete,
                        newLine: newLine,
                      });
                    } else if (
                      patchSecondDiscrete &&
                      line.caracCombos.valueList.find(
                        (el) => el.value === e.target.value
                      ).valueList?.length === 1
                    ) {
                      verifyRowsEquipment(line.id, {
                        newCarac: {
                          ...patchSecondDiscrete,
                          value: line.caracCombos.valueList.find(
                            (el) => el.value === e.target.value
                          ).valueList[0].value,
                        },
                        newLine: newLine,
                      });
                    }

                    verifyRowsEquipment(line.id, {
                      newCarac: { ...carac, value: e.target.value },
                      newLine: newLine,
                      oldCarac: carac,
                    });
                  }}
                >
                  {caracOptions
                    .sort((a, b) => a.value - b.value)
                    .map((el) => (
                      <MenuItem key={el.value} value={el.value}>
                        {el.value}
                      </MenuItem>
                    ))}
                </Select>
              )}
              {(!carac.valueList || carac.valueList.length === 0) &&
                !caracOptions && (
                  <TextField
                    key={carac.value}
                    disabled={line.isArchived || line.SheetId}
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    className="line-quantity-input"
                    defaultValue={carac.value || null}
                    style={{
                      width: '38.5%',
                      borderBottom:
                        !carac.value || carac.statusCode
                          ? '2px solid red'
                          : '1px solid black',
                    }}
                    onBlur={(e) => {
                      if (e.target.value !== carac.value) {
                        let newCarac = { ...carac, value: e.target.value };

                        const newList = line.ProjectImportElemCaracs.map((car) => {
                          if (car.id === carac.id) {
                            return newCarac;
                          } else {
                            return car;
                          }
                        });

                        const newLine = {
                          ...line,
                          ProjectImportElemCaracs: newList,
                        };

                        setTimeout(() => {
                          verifyRowsEquipment(line.id, {
                            newCarac,
                            newLine: newLine,
                            oldCarac: carac,
                          });
                        }, 100);
                      }
                    }}
                  />
                )}
            </div>
          );
        })
      )}
      {currentProject.templateType !== TYPE_EPCM &&
        currentProject.templateType !== TYPE_RE2020 &&
        line.AllotmentNode &&
        allGroups.type.find(
          (md) => md.id === line.AllotmentNode.MasterDataEquipmentId
        )?.hasFluid && (
          <Popover
            content={() => (
              <FluidsForm fluid={line.fluid} onChange={managementEditFluids} />
            )}
            trigger="click"
          >
            <p
              style={{
                display: ' flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span className="fluid-form-access">
                Changer le type de fluide (facultatif)
              </span>
            </p>
          </Popover>
        )}
    </div>
  );
};

export default CaracsBlock;
