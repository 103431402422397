import {
  FormValuesCalculatorWorksite,
  OriginDTO,
  SimplifiedCalculatorEnergy,
  SpecificEquipment,
  WorksiteCalculatorNav,
} from '../../../../../models/CalculatorDTO';
import { StateDTO } from '../../../../../models/GeneralDTO';
import {
  calculatorUpdate,
  updateCalculatorData,
} from '../../../../../../actions/calculator';
import { changeValueEquipments } from '../../../../../../actions/equipments';
import {
  TEMPLATE_TYPE_NAME,
  typeProjectForSimplifiedEnergy,
} from '../../../../../globalVariable/typeProject';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import carriedAwayIcon from '../../../../../../assets/images/equipments/calculator/carriedAway.png';
import energyIcon from '../../../../../../assets/images/equipments/calculator/energy.png';
import evacuatedIcon from '../../../../../../assets/images/equipments/calculator/evacuated.png';
import gearIcon from '../../../../../../assets/images/equipments/calculator/gear.png';
import specificIcon from '../../../../../../assets/images/equipments/calculator/specific.png';
import transportIcon from '../../../../../../assets/images/equipments/calculator/transport.png';
import waterCalculatorIcon from '../../../../../../assets/images/equipments/water.png';
import formatNumber from '../../../../../services/mathematics/formatNumber';
import { commaToDot, isNum, isNumOrNull } from '../../../../../shared/utilsFunction';
import Item from 'antd/lib/list/Item';
import { TYPE_NOOCO, TYPE_RE2020 } from '../../../../../globalVariable/typeProject';
import { useFieldArray, useForm } from 'react-hook-form';
import { ZoneDTO } from '../../../../../models/ProjectDTO';
import { useTranslation } from 'react-i18next';

type ExtractFormValues<T extends string> = T extends 'water.zonesData'
  ? FormValuesCalculatorWorksite['water']['zonesData']
  : T extends 'water.displayUnit'
  ? FormValuesCalculatorWorksite['water']['displayUnit']
  : FormValuesCalculatorWorksite;

const useCaseWorksite = () => {
  const {
    worksiteCalculatorDetailedActive,
    transportLines,
    gearLines,
    isTotalGazoilSelected,
    isInputByGearSelected,
    isInputByExcavatedSelected,
    calculatorTotalGazoilGear,
    calculatorTotalExcavatedGear,
    isWorksiteCalculatorSimplified,
    isWorksiteCalculatorDetailed,
    worksiteCalculatorNav,
    calculatorData,
  } = useSelector((state: StateDTO) => state.projects.calculator);
  const { zoneSelected } = useSelector(
    (state: StateDTO) => state.monitoring.breadcrumbMultizone
  );
  const { simplifiedEnergyLines } = useSelector(
    (state: StateDTO) => state.equipments
  );
  const { zones } =
    useSelector((state: StateDTO) => state?.projects?.parsedProject) || [];
  const { currentProject } = useSelector((state: StateDTO) => state.projects);
  const { templateType } = useSelector(
    (state: StateDTO) => state.projects.currentProject
  );
  const isRegulationProject = [
    TEMPLATE_TYPE_NAME.RE2020,
    TEMPLATE_TYPE_NAME.EPCM,
    TEMPLATE_TYPE_NAME.BBCA,
  ].includes(templateType);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSimplifiedEnergySelected =
    typeProjectForSimplifiedEnergy.includes(currentProject.templateType) &&
    isWorksiteCalculatorDetailed;
  const {
    energy,
    specific,
    water,
    importedEarthWork,
    evacuatedEarthWork,
    energyCalculatorSimplified,
    evacuatedEarthWorkIndividualHouse,
  } = calculatorData;
  const energyInit = energy.energiesArray.map((item) => {
    item.quantity = item?.quantity?.toString();

    return item;
  });
  const energyCalculatorSimplifiedInit =
    energyCalculatorSimplified.energySimplifiedArray.map((item) => {
      item.NumberMonthsWithCraneSummer =
        item?.NumberMonthsWithCraneSummer?.toString() || '';
      item.NumberMonthsWithCraneWinter =
        item?.NumberMonthsWithCraneWinter?.toString() || '';
      item.NumberMonthsWithoutCraneSummer =
        item?.NumberMonthsWithoutCraneSummer?.toString() || '';
      item.NumberMonthsWithoutCraneWinter =
        item?.NumberMonthsWithoutCraneWinter?.toString() || '';

      return item;
    });
  const waterInit = {
    displayUnit: water.displayUnit,
    zonesData: water.zonesData,
  };
  const specificInit = specific.specificArray?.map((item) => {
    item.quantity = item?.quantity?.toString();

    item?.caracs?.caracs?.map((carac) => {
      carac.value = carac?.value?.toString();

      return carac;
    });

    return item;
  });
  const importedEarthWorkInit = importedEarthWork.map((item) => {
    item.quantity = item?.quantity?.toString();
    item.distance = item?.distance?.toString();

    return item;
  });
  const evacuatedEarthWorkInit = evacuatedEarthWork.map((item) => {
    item.quantity = item?.quantity?.toString();
    item.distance = item?.distance?.toString();

    return item;
  });

  const evacuatedEarthWorkIndividualHouseInit =
    evacuatedEarthWorkIndividualHouse.map((item) => {
      item.area = item?.area?.toString();
      item.distance = item?.distance?.toString();

      return item;
    });
  const calculatorFormInstance = useForm<FormValuesCalculatorWorksite>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      energy: {
        // gazoleQuantity: gazoleQuantityInit,
        energiesArray: energyInit,
        // caracs: [],
      },
      energyCalculatorSimplified: {
        energySimplifiedArray: energyCalculatorSimplifiedInit,
      },
      importedEarthWork: importedEarthWorkInit,
      evacuatedEarthWork: evacuatedEarthWorkInit,
      water: waterInit,
      evacuatedEarthWorkIndividualHouse: evacuatedEarthWorkIndividualHouseInit,
      specific: {
        specificArray: specificInit,
      },
    },
  });
  const {
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = calculatorFormInstance;

  const fieldsWaterForm = useFieldArray({
    control,
    name: 'water.zonesData',
  });
  const fieldsEnergyForm = useFieldArray({
    control,
    name: 'energy.energiesArray',
  });
  const fieldsEnergySimplifiedForm = useFieldArray({
    control,
    name: 'energyCalculatorSimplified.energySimplifiedArray',
  });
  const fieldsImportedForm = useFieldArray({
    control,
    name: 'importedEarthWork',
  });
  const fieldsEvacuatedForm = useFieldArray({
    control,
    name: 'evacuatedEarthWork',
  });
  const fieldsEvacuatedIndividualHouseForm = useFieldArray({
    control,
    name: 'evacuatedEarthWorkIndividualHouse',
  });

  const fieldsSpecificForm = useFieldArray({
    control,
    name: 'specific.specificArray',
  });

  const { update: updateEnergy } = fieldsEnergyForm;
  const { update: updateWater, replace: replaceWater } = fieldsWaterForm;
  const { update: updateEnergyCalculatorSimplified } = fieldsEnergySimplifiedForm;
  const { update: updateImported } = fieldsImportedForm;
  const { update: updateEvacuated } = fieldsEvacuatedForm;
  const { update: updateEvacuatedIndividualHouse } =
    fieldsEvacuatedIndividualHouseForm;
  const { update: updateSpecific } = fieldsSpecificForm;

  const form = watch();

  const worksiteCalculatorNavInit: WorksiteCalculatorNav[] = [
    // gear
    {
      id: 0,
      disabled: false,
      title: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_E026EC', {
        defaultValue: 'Calculette chantier détaillée - consommations des engins',
      }),
      sidebarTitle: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_1DFC39', {
        defaultValue: 'Engins',
      }),
      sidebarIcon: gearIcon,
      sidebarUnity: isTotalGazoilSelected ? 'L' : isInputByGearSelected ? 'h' : '',
      content: 'WorksiteGear',
      sidebarActive: 'gear',
      comment: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_3C358A', {
        defaultValue:
          'Saisie des engins de chantier par consommation au litre ou par types d’engins',
      }),
      total: null,
    },
    // transport
    {
      id: 1,
      disabled: false,
      title: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_FE1894', {
        defaultValue: 'Calculette chantier détaillée - consommations des transports',
      }),
      sidebarTitle: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_F9E15D', {
        defaultValue: 'Déplacements',
      }),
      sidebarIcon: transportIcon,
      sidebarUnity: 'km',
      content: 'WorksiteTransport',
      sidebarActive: 'transport',
      comment: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_55FC5F', {
        defaultValue:
          'Saisie des moyens de transports utilisés pour les déplacements sur ou entre les chantiers',
      }),
      total: null,
    },
    // energy
    {
      id: 2,
      disabled: false,
      title: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_2A2087', {
        defaultValue:
          "Calculette chantier détaillée - consommations d'énergie du chantier",
      }),
      sidebarTitle: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_F12D94', {
        defaultValue: 'Energie',
      }),
      sidebarIcon: energyIcon,
      sidebarUnity: 'kWh',
      content: 'WorksiteEnergy',
      sidebarActive: 'energy',
      comment: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_349BD9', {
        defaultValue: 'Saisie des consommations d’énergies de base vie du chantier',
      }),
      total: null,
    },
    // water
    {
      id: 3,
      disabled: false,
      title: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_F74775', {
        defaultValue:
          'Calculette chantier détaillée - consommations et rejets d’eau',
      }),
      sidebarTitle: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_1B8842', {
        defaultValue: 'Eau',
      }),
      sidebarIcon: waterCalculatorIcon,
      sidebarUnity: 'm3',
      content: 'WorksiteWater',
      sidebarActive: 'water',
      comment: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_013595', {
        defaultValue: "Saisie des consommations et rejets d'eau sur le chantier",
      }),
      total: null,
    },
    // carriedAway
    {
      id: 4,
      disabled: false,
      title: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_BB3684', {
        defaultValue:
          'Calculette chantier détaillée - terres importées pour le terrassement',
      }),
      sidebarTitle: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_AFAEF3', {
        defaultValue: 'Terres importées',
      }),
      sidebarIcon: carriedAwayIcon,
      sidebarUnity: 'tonnes.km',
      content: 'WorksiteCarriedAway',
      sidebarActive: 'carriedAway',
      comment: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_CE8EF8', {
        defaultValue:
          'Saisie des terres importées dans le cadre des opérations de terrassement',
      }),
      total: null,
    },
    // evacuated
    {
      id: 5,
      disabled: false,
      title: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_40C2AD', {
        defaultValue:
          'Calculette chantier détaillée - terres évacuées pour le terrassement',
      }),
      sidebarTitle: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_EE68F2', {
        defaultValue: 'Terres évacuées',
      }),
      sidebarIcon: evacuatedIcon,
      sidebarUnity: 'tonnes.km',
      content: 'WorksiteEvacuated',
      sidebarActive: 'evacuated',
      comment: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_8177C5', {
        defaultValue:
          'Saisie des terres évacuées dans le cadre des opérations de terrassement',
      }),
      total: null,
    },
    // specific
    {
      id: 6,
      disabled: false,
      title: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_2646E1', {
        defaultValue: 'Calculette chantier détaillée - composants spécifiques',
      }),
      sidebarTitle: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_E840B8', {
        defaultValue: 'Composants spécifiques',
      }),
      sidebarIcon: specificIcon,
      sidebarUnity: '',
      content: 'WorksiteSpecific',
      sidebarActive: 'specific',
      comment: t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_B2A6CF', {
        defaultValue:
          "Saisie des composants spécifiques pour la réalisation des ouvrages provisoires nécessaires au chantier ou pour protéger certaines parties de l'ouvrage pendant le chantier",
      }),
      total: null,
    },
  ];

  const changeSourceOfEmptyRows = ({
    zone = zoneSelected,
    sideBarActiveId = null,
  } = {}) => {
    const sidebarActive = sideBarActiveId
      ? worksiteCalculatorNav[sideBarActiveId]?.sidebarActive
      : worksiteCalculatorNav[worksiteCalculatorDetailedActive]?.sidebarActive;
    const calculatorFormDatas = getValues();
    if (sidebarActive === 'energy') {
      const { energy, energyCalculatorSimplified } = calculatorFormDatas;
      const { energiesArray } = energy;
      const { energySimplifiedArray } = energyCalculatorSimplified;

      if (!isSimplifiedEnergySelected) {
        energiesArray?.forEach((energy, index) => {
          if (energy.quantity === '') {
            const origin = {
              type: zone?.type || 'project',
              sourceId: zone?.id || null,
              uniqueId: uuidv4(),
            };
            energy.origin = origin;
            updateEnergy(index, {
              ...energy,
            });
          }
        });
        dispatch(updateCalculatorData('energy', { energiesArray: energiesArray }));
        energySimplifiedArray?.forEach((energy, index) => {
          if (
            energy.NumberMonthsWithCraneSummer === '' &&
            energy.NumberMonthsWithCraneWinter === '' &&
            energy.NumberMonthsWithoutCraneSummer === '' &&
            energy.NumberMonthsWithoutCraneWinter === ''
          ) {
            const origin = {
              type: zone?.type || 'project',
              sourceId: zone?.id || null,
              uniqueId: uuidv4(),
            };

            updateEnergyCalculatorSimplified(index, {
              ...energy,
              NumberMonthsWithCraneSummer: '',
              NumberMonthsWithCraneWinter: '',
              NumberMonthsWithoutCraneSummer: '',
              NumberMonthsWithoutCraneWinter: '',
              origin: origin,
            });
          }
        });
        dispatch(
          updateCalculatorData('energyCalculatorSimplified', {
            energySimplifiedArray: energySimplifiedArray,
          })
        );
      }

      // simplifiedEnergy in calculator detailed
      if (isSimplifiedEnergySelected) {
        simplifiedEnergyLines?.forEach((energyLine) => {
          if (energyLine.quantity === null) {
            const origin = {
              type: zone?.type || 'project',
              sourceId: zone?.id || null,
              uniqueId: uuidv4(),
            };

            energyLine.origin = origin;
          }
          dispatch(
            changeValueEquipments('simplifiedEnergyLines', simplifiedEnergyLines)
          );
        });
      }
    }

    if (sidebarActive === 'carriedAway') {
      const { importedEarthWork } = calculatorFormDatas;
      // const {carriedAwayArray} = importedEarthWork;

      importedEarthWork?.forEach((carriedAway, index) => {
        if (carriedAway.quantity === '') {
          const origin = {
            type: zone?.type || 'project',
            sourceId: zone?.id || null,
            uniqueId: uuidv4(),
          };

          updateImported(index, {
            masterDataId: undefined,
            AllotmentNode: undefined,
            quantity: '',
            distance: isProjectIsOnlyIndividualHouse() ? '30' : '',
            origin: origin,
          });
        }
      });
    }

    if (sidebarActive === 'evacuated') {
      const { evacuatedEarthWork, evacuatedEarthWorkIndividualHouse } =
        calculatorFormDatas;

      evacuatedEarthWork?.forEach((evacuatedEarthWork, index) => {
        if (evacuatedEarthWork.quantity === '') {
          const origin = {
            type: zone?.type || 'project',
            sourceId: zone?.id || null,
            uniqueId: uuidv4(),
          };

          updateEvacuated(index, {
            masterDataId: undefined,
            AllotmentNode: undefined,
            quantity: '',
            distance: '',
            origin: origin,
          });
        }
      });
      dispatch(updateCalculatorData('evacuatedEarthWork', evacuatedEarthWork));
      evacuatedEarthWorkIndividualHouse?.forEach((evacuatedEarthWork, index) => {
        if (evacuatedEarthWork.area === '') {
          const origin = {
            type: zone?.type || 'project',
            sourceId: zone?.id || null,
            uniqueId: uuidv4(),
          };

          updateEvacuatedIndividualHouse(index, {
            area: '',
            distance: '30',
            origin: origin,
          });
        }
      });
      dispatch(
        updateCalculatorData(
          'evacuatedEarthWorkIndividualHouse',
          evacuatedEarthWorkIndividualHouse
        )
      );
    }

    if (sidebarActive === 'specific') {
      const { specific } = calculatorFormDatas;
      const { specificArray } = specific;

      specificArray?.forEach((specificRow, index) => {
        if (specificRow.quantity === '') {
          const origin = {
            type: zone?.type || 'project',
            sourceId: zone?.id || null,
            uniqueId: uuidv4(),
          };

          updateSpecific(index, {
            // AllotmentNode: null,
            MasterDataEquipment: null,
            quantity: '',
            caracs: null,
            origin: origin,
          });
        }
      });
      dispatch(updateCalculatorData('specific', { specificArray: specificArray }));
    }

    if (sidebarActive === 'water') {
      const { water } = calculatorFormDatas;
      const { zonesData } = water;

      zonesData?.forEach((waterRow, index) => {
        if (waterRow.drinkableWater === '' && waterRow.wastedWater === '') {
          const origin = {
            type: zone?.type || 'project',
            sourceId: zone?.id || null,
            uniqueId: uuidv4(),
          };

          updateWater(index, {
            drinkableWater: '',
            wastedWater: '',
            origin: origin,
          });
        }
      });
      dispatch(updateCalculatorData('water', water));
    }

    if (sidebarActive === 'transport') {
      let newTransportLines = [];

      transportLines?.forEach((transportLine) => {
        if (transportLine.quantity === null) {
          transportLine.origin = {
            type: zone?.type || 'project',
            sourceId: zone?.id || null,
            uniqueId: uuidv4(),
          };
        }
        newTransportLines.push(transportLine);
      });
      dispatch(calculatorUpdate('transportLines', newTransportLines));
    }

    if (sidebarActive === 'gear') {
      if (
        isInputByGearSelected ||
        (!isInputByExcavatedSelected && !isTotalGazoilSelected)
      ) {
        let newGearLines = [];

        // console.log()
        gearLines?.forEach((gearLine) => {
          if (gearLine.quantity === null) {
            gearLine.origin = {
              type: zone?.type || 'project',
              sourceId: zone?.id || null,
              uniqueId: uuidv4(),
            };
          }
          newGearLines.push(gearLine);
        });

        dispatch(calculatorUpdate('gearLines', newGearLines));
      }

      if (
        isTotalGazoilSelected ||
        (!isInputByExcavatedSelected && !isInputByGearSelected)
      ) {
        let totalGazolLines = [];

        console.log('calculatorTotalGazoilGear', calculatorTotalGazoilGear);
        calculatorTotalGazoilGear?.forEach((totalGazoleLine) => {
          if (totalGazoleLine.quantity === null) {
            totalGazoleLine.origin = {
              type: zone?.type || 'project',
              sourceId: zone?.id || null,
              uniqueId: uuidv4(),
            };
          }
          totalGazolLines.push(totalGazoleLine);
        });

        dispatch(calculatorUpdate('calculatorTotalGazoilGear', totalGazolLines));
      }
      if (
        isInputByExcavatedSelected ||
        (!isInputByGearSelected && !isTotalGazoilSelected)
      ) {
        let totalExcavatedLines = [];

        // console.log()
        calculatorTotalExcavatedGear?.forEach((totalExcavatedLine) => {
          if (totalExcavatedLine.quantity === null) {
            totalExcavatedLine.origin = {
              type: zone?.type || 'project',
              sourceId: zone?.id || null,
              uniqueId: uuidv4(),
            };
          }
          totalExcavatedLines.push(totalExcavatedLine);
        });

        dispatch(
          calculatorUpdate('calculatorTotalExcavatedGear', totalExcavatedLines)
        );
      }
    }
  };

  const onSubmitScreen = (
    data: FormValuesCalculatorWorksite,
    changeScreen?: 'next' | 'previous'
  ) => {
    // console.log('data', data)
    dispatch(calculatorUpdate('resultOfSearchCarac', null));
    dispatch(calculatorUpdate('calculatorData', data));

    if (changeScreen === 'next') {
      dispatch(
        calculatorUpdate('worksiteCalculatorDetailedActive', getNextScreenActiveId())
      );
    }

    if (changeScreen === 'previous') {
      dispatch(
        calculatorUpdate(
          'worksiteCalculatorDetailedActive',
          getPreviousScreenActiveId()
        )
      );
    }
  };

  const isActiveScreenValid = () => {
    const calculatorFormDatas = getValues();
    // calculatorFormDatas.energyCalculatorSimplified.energySimplifiedArray?.sort((a, b) => {
    //   const aInZone = isEquipmentFromSameZone(a) ? 1 : 0;
    //   const bInZone = isEquipmentFromSameZone(b) ? 1 : 0;

    //   // Comparaison
    //   return bInZone - aInZone;
    // })

    // updateEnergyCalculatorSimplified(calculatorFormDatas.energyCalculatorSimplified.energySimplifiedArray)
    dispatch(calculatorUpdate('calculatorData', calculatorFormDatas));

    // handleSubmit()();
    const sidebarActive =
      worksiteCalculatorNavInit[worksiteCalculatorDetailedActive].sidebarActive;
    if (sidebarActive === 'water') {
      if (Object.keys(errors).length === 0) {
        return true;
      }
    }

    if (isValid && isValidFormTransport() && isValidFormGear()) {
      return true;
    } else {
      handleSubmit((data) => onSubmitScreen(data))();
    }

    return false;
  };

  const isValidFormTransport = () => {
    const transportLinesCleaned = transportLines.filter(
      (el) => el.MasterDataEquipmentId !== null
    );

    if (
      transportLinesCleaned.find(
        (line) =>
          !line.MasterDataEquipmentId ||
          !line.quantity ||
          (line.caracs.length > 0 && line.caracs.find((carac) => !carac.value))
      )
    ) {
      dispatch(calculatorUpdate('isTransportErrorDisplay', true));

      return false;
    }

    // if (transportLinesCleaned) {
    const isInputError = transportLinesCleaned.map((line) => {
      if (!isNumOrNull(line.quantity)) {
        // console.log("2eme resultazt faux");
        dispatch(calculatorUpdate('isTransportErrorDisplay', true));

        return false;
      }
    });

    if (isInputError.includes(false)) {
      // console.log("3eme resultazt faux");
      dispatch(calculatorUpdate('isTransportErrorDisplay', true));

      return false;
    }

    dispatch(calculatorUpdate('isTransportErrorDisplay', false));

    return true;
  };

  const isValidFormGear = () => {
    const _ = require('lodash');
    const gearLinesCopy = _.cloneDeep(gearLines);

    const gearLinesCleaned = gearLinesCopy.filter(
      (line) => line.MasterDataEquipmentId !== null
    );

    const gearLinesCleanedCopy = _.cloneDeep(gearLinesCleaned);

    if (
      gearLinesCleaned.find(
        (line) =>
          !line.MasterDataEquipmentId ||
          !line.consumption ||
          !line.quantity ||
          !line?.Caracs?.find((carac) => carac.name === 'Motorisation')?.value
      )
    ) {
      // console.log('returnfalse1');
      dispatch(calculatorUpdate('isGearErrorDisplay', true));

      return false;
    }

    if (isTotalGazoilSelected && !isNumOrNull(calculatorTotalGazoilGear?.quantity)) {
      // console.log('returnfalse2');
      dispatch(calculatorUpdate('isGearErrorDisplay', true));

      return false;
    }

    if (isInputByGearSelected) {
      const isGearError = gearLinesCleaned.map((line) => {
        if (!isNumOrNull(line.quantity)) {
          // console.log('returnfalse3');
          dispatch(calculatorUpdate('isGearErrorDisplay', true));

          return false;
        }
        if (!isNumOrNull(line.consumption)) {
          // console.log('returnfalse4');
          dispatch(calculatorUpdate('isGearErrorDisplay', true));

          return false;
        }
      });

      if (isGearError.includes(false)) {
        // console.log('returnfalse5');
        dispatch(calculatorUpdate('isGearErrorDisplay', true));

        return false;
      }
    }

    dispatch(calculatorUpdate('isGearErrorDisplay', false));

    return true;
  };

  const isEnergySimplifiedFilled = (
    equipement: SimplifiedCalculatorEnergy | undefined
  ): boolean => {
    let isFilled = false;

    if (!equipement) {
      return false;
    }

    if (equipement?.NumberMonthsWithCraneSummer !== '') {
      isFilled = true;
    }
    if (equipement?.NumberMonthsWithCraneWinter !== '') {
      isFilled = true;
    }
    if (equipement?.NumberMonthsWithoutCraneSummer !== '') {
      isFilled = true;
    }
    if (equipement?.NumberMonthsWithoutCraneWinter !== '') {
      isFilled = true;
    }

    return isFilled;
  };

  const isZoneFilled = (zone: ZoneDTO | null): boolean => {
    let isFilled = false;

    // gears
    if (isTotalGazoilSelected && isFilled === false) {
      const equipmentInZone = calculatorTotalGazoilGear?.find(
        (line) => line.origin.sourceId === zone?.id
      );
      if (
        typeof equipmentInZone?.quantity === 'number' &&
        equipmentInZone?.quantity > 0
      ) {
        isFilled = true;
      }
      const equipmentInProjectZone = calculatorTotalGazoilGear?.find(
        (line) => line.origin.sourceId === null
      );
      if (
        typeof equipmentInProjectZone?.quantity === 'number' &&
        equipmentInProjectZone?.quantity > 0 &&
        zone === null
      ) {
        isFilled = true;
      }
    }

    if (isInputByGearSelected && isFilled === false) {
      const equipmentInZone = gearLines?.find(
        (line) => line.origin.sourceId === zone?.id
      );
      if (typeof equipmentInZone?.quantity === 'string') {
        isFilled = true;
      }
      const equipmentInProjectZone = gearLines?.find(
        (line) => line.origin.sourceId === null
      );
      if (typeof equipmentInProjectZone?.quantity === 'string' && zone === null) {
        isFilled = true;
      }
    }

    if (isInputByExcavatedSelected && isFilled === false) {
      const equipmentInZone = calculatorTotalExcavatedGear?.find(
        (line) => line.origin.sourceId === zone?.id
      );
      if (
        typeof equipmentInZone?.quantity === 'number' &&
        equipmentInZone?.quantity > 0
      ) {
        isFilled = true;
      }
      const equipmentInProjectZone = calculatorTotalExcavatedGear?.find(
        (line) => line.origin.sourceId === null
      );
      if (
        typeof equipmentInProjectZone?.quantity === 'number' &&
        equipmentInProjectZone?.quantity > 0 &&
        zone === null
      ) {
        isFilled = true;
      }
    }

    // transports
    if (isFilled === false) {
      const equipmentInZone = transportLines?.find(
        (line) => line?.origin?.sourceId === zone?.id
      );
      if (
        typeof equipmentInZone?.quantity === 'string' &&
        equipmentInZone?.quantity !== ''
      ) {
        isFilled = true;
      }
      const equipementInProjectZone = transportLines?.find(
        (line) => line?.origin?.sourceId === null
      );
      if (
        typeof equipementInProjectZone?.quantity === 'string' &&
        equipementInProjectZone?.quantity !== '' &&
        zone === null
      ) {
        isFilled = true;
      }
    }

    // energy
    if (isFilled === false) {
      const equipmentInZone = energy.energiesArray?.find(
        (line) => line.origin.sourceId === zone?.id
      );
      if (
        typeof equipmentInZone?.quantity === 'string' &&
        equipmentInZone?.quantity !== ''
      ) {
        isFilled = true;
      }
      const equipementInProjectZone = energy.energiesArray?.find(
        (line) => line.origin.sourceId === null
      );
      if (
        typeof equipementInProjectZone?.quantity === 'string' &&
        equipementInProjectZone?.quantity !== '' &&
        zone === null
      ) {
        isFilled = true;
      }
    }

    // energy simplified
    if (isFilled === false) {
      const equipmentInZone = energyCalculatorSimplified.energySimplifiedArray?.find(
        (line) => line.origin.sourceId === zone?.id
      );

      if (isEnergySimplifiedFilled(equipmentInZone)) {
        isFilled = true;
      }
      const equipementInProjectZone =
        energyCalculatorSimplified.energySimplifiedArray?.find(
          (line) => line.origin.sourceId === null
        );
      if (isEnergySimplifiedFilled(equipementInProjectZone) && zone === null) {
        isFilled = true;
      }
    }

    // water
    if (isFilled === false) {
      const equipmentInZone = water.zonesData?.find(
        (line) => line.origin.sourceId === zone?.id
      );
      if (
        typeof equipmentInZone?.drinkableWater === 'string' &&
        equipmentInZone?.drinkableWater !== ''
      ) {
        isFilled = true;
      }
      if (
        typeof equipmentInZone?.wastedWater === 'string' &&
        equipmentInZone?.wastedWater !== ''
      ) {
        isFilled = true;
      }
      const equipementInProjectZone = water.zonesData?.find(
        (line) => line.origin.sourceId === null
      );
      if (
        typeof equipementInProjectZone?.drinkableWater === 'string' &&
        equipementInProjectZone?.drinkableWater !== '' &&
        zone === null
      ) {
        isFilled = true;
      }
      if (
        typeof equipementInProjectZone?.wastedWater === 'string' &&
        equipementInProjectZone?.wastedWater !== '' &&
        zone === null
      ) {
        isFilled = true;
      }
    }

    // carriedAway (imported)
    if (isFilled === false) {
      const equipmentInZone = importedEarthWork?.find(
        (line) => line.origin.sourceId === zone?.id
      );
      if (
        typeof equipmentInZone?.quantity === 'string' &&
        equipmentInZone?.quantity !== ''
      ) {
        isFilled = true;
      }
      const equipmentInProjectZone = importedEarthWork?.find(
        (line) => line.origin.sourceId === null
      );
      if (
        typeof equipmentInProjectZone?.quantity === 'string' &&
        equipmentInProjectZone?.quantity !== '' &&
        zone === null
      ) {
        isFilled = true;
      }
    }

    // evacuated
    if (isFilled === false) {
      const equipmentInZone = evacuatedEarthWork?.find(
        (line) => line.origin.sourceId === zone?.id
      );
      if (
        typeof equipmentInZone?.quantity === 'string' &&
        equipmentInZone?.quantity !== ''
      ) {
        isFilled = true;
      }
      const equipmentInProjectZone = evacuatedEarthWork?.find(
        (line) => line.origin.sourceId === null
      );
      if (
        typeof equipmentInProjectZone?.quantity === 'string' &&
        equipmentInProjectZone?.quantity !== '' &&
        zone === null
      ) {
        isFilled = true;
      }
    }

    // evacuated individual house
    if (isFilled === false) {
      const equipmentInZone = evacuatedEarthWorkIndividualHouse?.find(
        (line) => line.origin.sourceId === zone?.id
      );
      if (
        typeof equipmentInZone?.area === 'string' &&
        equipmentInZone?.area !== ''
      ) {
        isFilled = true;
      }
      const equipmentInProjectZone = evacuatedEarthWorkIndividualHouse?.find(
        (line) => line.origin.sourceId === null
      );
      if (
        typeof equipmentInProjectZone?.area === 'string' &&
        equipmentInProjectZone?.area !== '' &&
        zone === null
      ) {
        isFilled = true;
      }
    }

    // specific
    if (isFilled === false) {
      const equipmentInZone = specific.specificArray?.find(
        (line) => line?.origin?.sourceId === zone?.id
      );
      if (
        typeof equipmentInZone?.quantity === 'string' &&
        equipmentInZone?.quantity !== ''
      ) {
        isFilled = true;
      }
      const equipmentInProjectZone = specific.specificArray?.find(
        (line) => line?.origin?.sourceId === null
      );
      if (
        typeof equipmentInProjectZone?.quantity === 'string' &&
        equipmentInProjectZone?.quantity !== '' &&
        zone === null
      ) {
        isFilled = true;
      }
    }

    return isFilled;
  };

  const calculateAreaZoneProrata = (equipment: SpecificEquipment): number => {
    const typeOfSelectedZone =
      zoneSelected?.type === null ? 'project' : zoneSelected?.type;
    const equipmentSourceType = equipment?.origin?.type;
    if (equipmentSourceType === 'zone') {
      // because an equipment type 'zone' cant have children
      return 100;
    }

    const selectedAreaZone = zoneSelected?.area || 0;

    if (selectedAreaZone === 0) {
      return 0;
    }

    if (typeOfSelectedZone === 'zone') {
      if (equipmentSourceType === 'building') {
        const areaParentBuilding =
          zones?.find((zone) => zone.id === equipment.origin.sourceId)?.area || 1;

        return selectedAreaZone / areaParentBuilding;
      }

      if (equipmentSourceType === 'project') {
        const areaAllZones = zones
          ?.filter((zone) => zone.type === 'building')
          .map((zone) => zone.area)
          .reduce((a, b) => a + b, 0);

        return selectedAreaZone / areaAllZones;
      }
    }

    if (typeOfSelectedZone === 'building') {
      if (equipmentSourceType === 'project') {
        const areaAllBuildings = zones
          ?.filter((zone) => zone.type === 'building')
          .map((zone) => zone.area)
          .reduce((a, b) => a + b, 0);

        return selectedAreaZone / areaAllBuildings;
      }
    }

    return 100;
  };

  const isEquipmentFromParentZone = (equipment: SpecificEquipment): boolean => {
    const equipmentSourceType = equipment?.origin?.type;
    const zoneSelectedType = zoneSelected?.type || 'project';

    if (equipmentSourceType === 'project' && zoneSelectedType === 'zone') {
      return true;
    }

    if (equipmentSourceType === 'project' && zoneSelectedType === 'building') {
      return true;
    }

    if (equipmentSourceType === 'building' && zoneSelectedType === 'zone') {
      if (zoneSelected?.ParentId === equipment?.origin?.sourceId) {
        return true;
      }
    }

    return false;
  };

  const isDisplayEquipment = (equipment: SpecificEquipment): boolean => {
    const typeOfSelectedZone = zoneSelected === null ? 'project' : zoneSelected.type;

    if (typeOfSelectedZone === 'project') {
      return true;
    }

    if (typeOfSelectedZone === 'building') {
      return isDisplayBuilding(equipment);
    }

    if (typeOfSelectedZone === 'zone') {
      return isDisplayZone(equipment);
    }

    return false;
  };

  const isDisplayBuilding = (equipment: SpecificEquipment): boolean => {
    const typeOriginEquipment = equipment?.origin?.type;

    if (typeOriginEquipment === 'project') {
      return true;
    }

    if (typeOriginEquipment === 'building') {
      return equipment.origin.sourceId === zoneSelected?.id ? true : false;
    }

    if (typeOriginEquipment === 'zone') {
      return zoneSelected?.Zones?.find(
        (zone) => zone?.id === equipment.origin.sourceId
      )?.id
        ? true
        : false;
    }

    return false;
  };

  const isDisplayZone = (equipment: SpecificEquipment): boolean => {
    const typeOriginEquipment = equipment?.origin?.type;

    if (typeOriginEquipment === 'project') {
      return true;
    }

    if (typeOriginEquipment === 'building') {
      return zoneSelected?.ParentId === equipment.origin.sourceId ? true : false;
    }

    if (typeOriginEquipment === 'zone') {
      return isEquipmentFromSameZone(equipment);
    }

    return false;
  };

  const isEquipmentEditable = (equipment: SpecificEquipment): boolean => {
    // console.log('equipement', equipment)
    // const zoneSelectedId = zoneSelected?.id || null;

    // console.log('zoneSelectedId', zoneSelectedId)
    if (isEquipmentFromSameZone(equipment)) {
      return true;
    }

    return false;
  };

  const isEquipmentFromSameZone = (equipment: SpecificEquipment): boolean => {
    const sourceId = equipment?.origin?.sourceId || null;
    const zoneSelectedId = zoneSelected?.id || null;

    if (sourceId === zoneSelectedId) {
      return true;
    }

    return false;
  };

  const isDisplayBreadcrumbMultizone = (): boolean => {
    if (isPlotZoneInProject()) {
      return true;
    }

    if (isOnlyOneZoneTypeZone(zones)) {
      return false;
    }

    return true;
  };

  const isOnlyOneZoneTypeZone = (zones: ZoneDTO[]): boolean => {
    return zones.filter((zone) => zone.type === 'zone').length === 1 ? true : false;
  };

  const isCalculatorSimplifiedActivated = (): boolean => {
    if (isProjectHaveMixedZoneWithIndividualHouseZone()) {
      return false;
    }

    if (isProjectIsOnlyIndividualHouse() && !isPlotZoneInProject()) {
      return false;
    }
    return true;
  };

  const isProjectIsOnlyIndividualHouse = (): boolean => {
    const zonesTypeZone = zones.filter((zone) => zone.type === 'zone');

    let isOnlyZoneIndividualHouse = true;
    zonesTypeZone.forEach((zone) => {
      if (zone?.BuildingType?.name !== 'Maison individuelle ou accolée') {
        isOnlyZoneIndividualHouse = false;
      }
    });
    return isOnlyZoneIndividualHouse;
  };

  const isIndividualHouseWithUnder500m2Plot = () => {
    const plotZoneArea =
      zones.find((zone) => zone.type === 'plot')?.plotArea || null;
    const isIndividualHouseWithUnder500m2Plot =
      isProjectIsOnlyIndividualHouse() &&
      plotZoneArea !== null &&
      plotZoneArea <= 500;

    return isIndividualHouseWithUnder500m2Plot;
  };

  const isIndividualHouseWithOver500m2Plot = () => {
    const plotZoneArea =
      zones.find((zone) => zone.type === 'plot')?.plotArea || null;
    const isIndividualHouseWithOver500m2Plot =
      isProjectIsOnlyIndividualHouse() &&
      plotZoneArea !== null &&
      plotZoneArea > 500;

    return isIndividualHouseWithOver500m2Plot;
  };

  const isProjectHaveMixedZoneWithIndividualHouseZone = (): boolean => {
    const zonesTypeZone = zones.filter((zone) => zone.type === 'zone');
    const zonesTypeIndividualHouse = zones.filter(
      (zone) =>
        zone.type === 'zone' &&
        zone?.BuildingType?.name === 'Maison individuelle ou accolée'
    );

    if (zonesTypeIndividualHouse.length === 0) {
      return false;
    }

    if (zonesTypeZone.length === zonesTypeIndividualHouse.length) {
      return false;
    }

    return true;
  };

  const isPlotZoneInProject = (): boolean => {
    return Boolean(zones.find((zone) => zone.type === 'plot'));
  };

  const zoneSourceToDisplay = (origin: OriginDTO): string => {
    const { type, sourceId } = origin;

    if (type === 'project') {
      return 'Projet';
    }

    if (type === 'plot') {
      return 'Parcelle';
    }

    if (type === 'building') {
      return zones?.find((zone) => zone?.id === sourceId)?.name || '';
    }

    if (type === 'zone') {
      const zoneSource = zones?.find((zone) => zone?.id === sourceId);
      const zoneParent = zones?.find((zone) => zone?.id === zoneSource?.ParentId);
      return `${zoneParent?.name} - ${zoneSource?.name}`;
    }

    return '';
  };

  const changeUnitOfWater = (newUnit: 'liter' | 'm3') => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    const waterArray = getValues('water.zonesData');
    const actualUnit = getValues('water.displayUnit');

    const waterArrayToReturn = waterArray.map((item) => {
      const drinkableWater = Number(item.drinkableWater.replace(/,/, '.'));
      const wastedWater = Number(item.wastedWater.replace(/,/, '.'));

      if (actualUnit === 'liter' && newUnit === 'm3') {
        item.drinkableWater = (drinkableWater / 1000).toString();
        item.wastedWater = (wastedWater / 1000).toString();
      }

      if (actualUnit === 'm3' && newUnit === 'liter') {
        item.drinkableWater = (drinkableWater * 1000).toString();
        item.wastedWater = (wastedWater * 1000).toString();
      }

      return item;
    });

    setValue('water.displayUnit', newUnit);
    replaceWater(waterArrayToReturn);
  };

  const createWorksiteCalculatorNav = () => {
    let newWorksiteCalculatorNav: WorksiteCalculatorNav[] =
      worksiteCalculatorNavInit;

    if ([TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(templateType)) {
      newWorksiteCalculatorNav = newWorksiteCalculatorNav.filter(
        (sidebarElement) => sidebarElement.content !== 'WorksiteSpecific'
      );
    }

    if (isRegulationProject) {
      newWorksiteCalculatorNav = newWorksiteCalculatorNav.filter(
        (sidebarElement) => sidebarElement.content !== 'WorksiteTransport'
      );
    }

    if (isWorksiteCalculatorSimplified) {
      newWorksiteCalculatorNav = newWorksiteCalculatorNav.map((sidebarElement) => {
        switch (sidebarElement.content) {
          case 'WorksiteGear':
            sidebarElement.comment = isProjectIsOnlyIndividualHouse()
              ? t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_9F4043', {
                  defaultValue:
                    "En calcul simplifié, les consommations d'énergie sont considérées nulles",
                })
              : t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_BB39DC', {
                  defaultValue:
                    'La consommation de carburant est estimée à partir des quantités de terres excavées : 1L de carburant consommé par m³ de terre excavée',
                });
            sidebarElement.disabled = isProjectIsOnlyIndividualHouse()
              ? true
              : sidebarElement.disabled;
            break;
          case 'WorksiteTransport':
            sidebarElement.disabled = isProjectIsOnlyIndividualHouse()
              ? true
              : sidebarElement.disabled;
            break;
          case 'WorksiteEnergy':
            sidebarElement.comment = isProjectIsOnlyIndividualHouse()
              ? t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_7A3F02', {
                  defaultValue:
                    "En calcul simplifié, les consommations d'énergie sont considérées nulles",
                })
              : t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_576B38', {
                  defaultValue:
                    'La consommation d’eau est estimée à partir des quantités de terres excavées : 1L d’eau consommé par m³ de terre excavée',
                });
            sidebarElement.disabled = isProjectIsOnlyIndividualHouse()
              ? true
              : sidebarElement.disabled;
            break;
          case 'WorksiteWater':
            sidebarElement.comment = isProjectIsOnlyIndividualHouse()
              ? t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_326805', {
                  defaultValue:
                    'En calcul simplifié, les volumes d’eau potable consommée et d’eau usée rejetée sont tous deux fixés à 10 m³',
                })
              : t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_23EB72', {
                  defaultValue:
                    'En calcul simplifié, les volumes d’eau potable consommée et d’eau usée rejetée sont calculés à partir de l’utilisation des grues',
                });
            sidebarElement.disabled = true;
            break;
          case 'WorksiteSpecific':
            sidebarElement.comment = isProjectIsOnlyIndividualHouse()
              ? t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_A24579', {
                  defaultValue:
                    'En calcul simplifié, la part des composants spécifiques du chantier est négligée',
                })
              : t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_D1DA4C', {
                  defaultValue:
                    'En calcul simplifié, la part des composants spécifiques du chantier est négligée',
                });
            sidebarElement.disabled = true;
            break;
          case 'WorksiteCarriedAway':
            sidebarElement.comment = t(
              'APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_97AFB6',
              { defaultValue: 'Saisie des quantités de terres importées' }
            );
            break;
          case 'WorksiteEvacuated':
            sidebarElement.comment = isIndividualHouseWithOver500m2Plot()
              ? t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_9E9186', {
                  defaultValue:
                    'En calcul simplifié pour une maison individuelle avec une parcelle de surface > 500m², il est considéré que toutes les terres excavées sont réutilisées sur site',
                })
              : sidebarElement.comment;
            sidebarElement.disabled = isIndividualHouseWithOver500m2Plot()
              ? true
              : sidebarElement.disabled;
            break;
          default:
            break;
        }

        return sidebarElement;
      });

      newWorksiteCalculatorNav = updateNavItemTitleToSimplified(
        newWorksiteCalculatorNav
      );
    }

    newWorksiteCalculatorNav = regenerateIds(newWorksiteCalculatorNav);

    dispatch(calculatorUpdate('worksiteCalculatorNav', newWorksiteCalculatorNav));
    dispatch(
      calculatorUpdate(
        'worksiteCalculatorDetailedActive',
        getFirstScreenActiveId(newWorksiteCalculatorNav)
      )
    );
  };

  const getNextScreenActiveId = (): number | null => {
    const currentIndex = worksiteCalculatorNav.findIndex(
      (itemNav) => itemNav.id === worksiteCalculatorDetailedActive
    );

    const nextActiveItemId =
      worksiteCalculatorNav
        .slice(currentIndex + 1)
        .find((itemNav) => itemNav.disabled === false)?.id || null;

    return nextActiveItemId;
  };

  const getPreviousScreenActiveId = (): number | null => {
    const currentIndex = worksiteCalculatorNav.findIndex(
      (itemNav) => itemNav.id === worksiteCalculatorDetailedActive
    );

    const previousActiveItemId = worksiteCalculatorNav
      ?.slice(0, currentIndex)
      ?.reverse()
      ?.find((itemNav) => itemNav?.disabled === false)?.id;

    return previousActiveItemId != undefined ? previousActiveItemId : null;
  };

  const getFirstScreenActiveId = (
    newWorksiteCalculatorNav: WorksiteCalculatorNav[]
  ): number => {
    return (
      newWorksiteCalculatorNav?.find((itemNav) => itemNav?.disabled === false)?.id ||
      0
    );
  };

  const regenerateIds = <T extends { id: number }>(array: T[]): T[] => {
    const newArray = array.map((item, index) => {
      return { ...item, id: index };
    });

    return newArray;
  };

  const updateNavItemTitleToSimplified = (
    WorksiteCalculatorNav: WorksiteCalculatorNav[]
  ): WorksiteCalculatorNav[] => {
    return WorksiteCalculatorNav.map((navElement) => {
      return {
        ...navElement,
        title: navElement.title.replace(
          t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_5E5FA7', {
            defaultValue: 'détaillée',
          }),
          t('APP.CALCULATORWORKSITECONTENT.USECASEWORKSITE.PH_AEBB0A', {
            defaultValue: 'simplifiée',
          })
        ),
      };
    });
  };

  const calculSumEnergy = (): number => {
    if (isWorksiteCalculatorDetailed) {
      let energyData = typeProjectForSimplifiedEnergy.includes(
        currentProject.templateType
      )
        ? simplifiedEnergyLines?.map((el) => el.quantity)
        : form.energy.energiesArray.map((equipment) =>
            Number(equipment?.quantity?.replace(/,/, '.'))
          );
      const sumEnergy =
        energyData.reduce((accumulator, value) => {
          return accumulator + Number(value);
        }, 0) || 0;

      return sumEnergy;
    }

    if (isWorksiteCalculatorSimplified) {
      let totals = {
        totalNumberMonthsWithCraneSummer: 0,
        totalNumberMonthsWithCraneWinter: 0,
        totalNumberMonthsWithoutCraneSummer: 0,
        totalNumberMonthsWithoutCraneWinter: 0,
      };
      form.energyCalculatorSimplified.energySimplifiedArray.forEach((equipment) => {
        if (equipment) {
          totals.totalNumberMonthsWithCraneSummer += Number(
            equipment.NumberMonthsWithCraneSummer?.replace(/,/, '.') || 0
          );
          totals.totalNumberMonthsWithCraneWinter += Number(
            equipment.NumberMonthsWithCraneWinter?.replace(/,/, '.') || 0
          );
          totals.totalNumberMonthsWithoutCraneSummer += Number(
            equipment.NumberMonthsWithoutCraneSummer?.replace(/,/, '.') || 0
          );
          totals.totalNumberMonthsWithoutCraneWinter += Number(
            equipment.NumberMonthsWithoutCraneWinter?.replace(/,/, '.') || 0
          );
        }
      });

      return (
        totals.totalNumberMonthsWithCraneSummer * 10400 +
        totals.totalNumberMonthsWithCraneWinter * 19500 +
        totals.totalNumberMonthsWithoutCraneSummer * 5200 +
        totals.totalNumberMonthsWithoutCraneWinter * 10400
      );
    }
  };

  const calculSumWater = (): number => {
    if (isWorksiteCalculatorSimplified) {
      if (isProjectIsOnlyIndividualHouse()) {
        return 20;
      }

      const energySimplifiedArray: SimplifiedCalculatorEnergy[] = getValues(
        'energyCalculatorSimplified.energySimplifiedArray'
      );
      let nCrane = 0;
      let nWithoutCrane = 0;
      energySimplifiedArray.forEach((energyItem) => {
        nCrane =
          Number(energyItem.NumberMonthsWithCraneSummer) +
          Number(energyItem.NumberMonthsWithCraneWinter) +
          nCrane;
        nWithoutCrane =
          Number(energyItem.NumberMonthsWithoutCraneSummer) +
          Number(energyItem.NumberMonthsWithoutCraneWinter) +
          nWithoutCrane;
      });
      return nCrane * 60 + nWithoutCrane * 40;
    }

    return (
      form.water.zonesData.reduce((accumulator, object) => {
        return (
          accumulator +
          Number(object.drinkableWater?.replace(/,/, '.')) +
          Number(object.wastedWater?.replace(/,/, '.'))
        );
      }, 0) || 0
    );
  };

  const calculSumSpecific = (): number => {
    if (isWorksiteCalculatorSimplified) {
      return 0;
    }

    return form.specific.specificArray.filter((e) => e.MasterDataEquipment !== null)
      .length;
  };

  const calculSumTransport = (): number => {
    let sumTransport: any = 0;
    const transportTotal =
      transportLines.reduce((accumulator, object) => {
        return accumulator + Number(commaToDot(object?.quantity));
      }, 0) || 0;

    if (isNum(transportTotal)) {
      sumTransport = formatNumber(Number(transportTotal));
    }

    return sumTransport;
  };

  const calculateSumGear = (): number => {
    let sumGear: any = 0;

    if (isTotalGazoilSelected) {
      sumGear =
        calculatorTotalGazoilGear.reduce((accumulator, object) => {
          if (!object?.quantity) {
            return accumulator;
          } else {
            return accumulator + object.quantity;
          }
        }, 0) || 0;
    }
    if (isInputByExcavatedSelected) {
      sumGear =
        calculatorTotalExcavatedGear.reduce((accumulator, object) => {
          if (!object?.quantity) {
            return accumulator;
          } else {
            return (
              accumulator + Number(object?.quantity.toString().replace(/,/, '.'))
            );
          }
        }, 0) || 0;
    }
    if (isInputByGearSelected) {
      const gearTotal =
        gearLines.reduce((accumulator, object) => {
          return accumulator + Number(commaToDot(object?.quantity));
        }, 0) || 0;

      if (isNum(gearTotal)) {
        sumGear = formatNumber(Number(gearTotal));
      }
    }
    return sumGear;
  };

  const calculateSumImported = (): number => {
    const total =
      form.importedEarthWork
        .map(
          (el) =>
            Number(el?.quantity?.replace(/,/, '.')) *
            Number(el?.distance?.replace(/,/, '.'))
        )
        .reduce((sumImported, value) => {
          return sumImported + value;
        }, 0) || 0;
    return total;
  };

  const calculateSumEvacuated = (): number => {
    if (isWorksiteCalculatorSimplified && isProjectIsOnlyIndividualHouse()) {
      const totalArea =
        form.evacuatedEarthWorkIndividualHouse
          .map((el) => Number(el?.area?.replace(/,/, '.')))
          .reduce((sumEvacuated, value) => {
            return sumEvacuated + value;
          }, 0) || 0;

      return Math.round(totalArea * 1.12 * 10000) / 10000;
    }

    return (
      form.evacuatedEarthWork
        .map(
          (el) =>
            Number(el?.quantity?.replace(/,/, '.')) *
            (templateType === TYPE_RE2020 || templateType === TYPE_NOOCO
              ? Number(el?.distance?.replace(/,/, '.'))
              : 1)
        )
        .reduce((sumEvacuated, value) => {
          return sumEvacuated + value;
        }, 0) || 0
    );
  };

  return {
    changeSourceOfEmptyRows,
    isActiveScreenValid,
    isEquipmentEditable,
    isEquipmentFromParentZone,
    calculateAreaZoneProrata,
    isDisplayEquipment,
    changeUnitOfWater,
    createWorksiteCalculatorNav,
    calculSumEnergy,
    calculSumWater,
    calculSumSpecific,
    calculSumTransport,
    calculateSumGear,
    calculateSumImported,
    calculateSumEvacuated,
    isCalculatorSimplifiedActivated,
    isPlotZoneInProject,
    isIndividualHouseWithOver500m2Plot,
    isIndividualHouseWithUnder500m2Plot,
    isProjectIsOnlyIndividualHouse,
    getNextScreenActiveId,
    getPreviousScreenActiveId,
    isValidFormTransport,
    isValidFormGear,
    isRegulationProject,
    fieldsImportedForm,
    fieldsEvacuatedForm,
    fieldsEvacuatedIndividualHouseForm,
    fieldsSpecificForm,
    calculatorFormInstance,
    fieldsEnergyForm,
    fieldsEnergySimplifiedForm,
    fieldsWaterForm,
    onSubmitScreen,
    isZoneFilled,
    zoneSourceToDisplay,
    isDisplayBreadcrumbMultizone,
  };
};

export default useCaseWorksite;
