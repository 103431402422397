import '../Line/line.scss';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List,
} from 'react-virtualized';

import { Paper, Tooltip } from '@material-ui/core';
import {
  changeImportDatas,
  fetchImportDatas,
  verifyRowsEquipment,
} from '../../../../actions/import';
import {
  checkIfNodeCanBeAddedInProject,
  createSetElems,
  returnSearchBarOptionsFiltered,
} from '../../../shared/utils';
import { getFlattenImportRows } from '../ImportFunction';
import { updateFormValue } from '../../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import BreadcrumbModal from '../../../../containers/BreadcrumbModal';
import { useTranslation } from 'react-i18next';
import LineComponent from '../../../../containers/LineComponent';
import React, { useEffect, useRef, useState } from 'react';
import RowSkeleton from '../RowSkeleton';

const CustomPaper = (props) => {
  return <Paper style={{ width: '250px' }} {...props} />;
};

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 100,
});

export const returnCountOfImportRows = (
  actualCount,
  validatedRows,
  notValidatedRows,
  userValidatedRows,
  isArchivedRows,
  filterOn,
  displayArchived,
  isSearchingData,
  list
) => {
  let allBundles = list?.filter((importRow) => importRow.id === 'duplicate') || [];
  let countBundles = 0;

  if (allBundles.length > 0) {
    allBundles.map((bundle) => {
      countBundles += bundle.ProjectElemsDuplicate.length - 1;
    });
  }

  if (isSearchingData && actualCount !== undefined) {
    return actualCount - countBundles;
  } else if (filterOn?.length > 0 || displayArchived) {
    let count = 0;

    if (filterOn.includes('Validated')) {
      count += validatedRows + notValidatedRows;
    }
    if (filterOn.includes('userValidated')) {
      count += userValidatedRows;
    }
    if (displayArchived) {
      count += isArchivedRows;
    }

    return count - countBundles;
  } else {
    let count = displayArchived
      ? validatedRows + notValidatedRows + userValidatedRows + isArchivedRows
      : validatedRows + notValidatedRows + userValidatedRows;

    return count - countBundles;
  }
};

export const handleLineCheckbox = (
  value,
  id,
  linesSelected,
  allLinesChecked,
  exceptLinesFromMultipleActions,
  dispatch
) => {
  let selected = [...linesSelected];

  if (allLinesChecked && !value) {
    dispatch(
      updateFormValue('exceptLinesFromMultipleActions', [
        ...exceptLinesFromMultipleActions,
        id,
      ])
    );
  } else if (allLinesChecked && value) {
    if (exceptLinesFromMultipleActions.find((el) => el === id)) {
      dispatch(
        updateFormValue(
          'exceptLinesFromMultipleActions',
          exceptLinesFromMultipleActions.filter((el) => el !== id)
        )
      );
    }
  }
  if (value) {
    selected = [...selected, id];
  } else {
    selected = selected.filter((el) => el !== id);
  }

  dispatch(updateFormValue('linesSelected', selected));
};

const ImportList = ({ list, groups, currentRowIndexDisplayed, displayArchived }) => {
  const dispatch = useDispatch();

  const {
    allGroups,
    currentProject,
    nodesAvailableForProjectType,
    bundleOptions,
    modeledProductOptions,
    certifiedAllotments,
    fluids,
    currentAllotment,
  } = useSelector((state) => state.projects);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [openAdvance, setOpenAdvance] = useState([]);
  const [copyLine, setCopyLine] = useState();
  const [calculatorsOpen, setCalculatorsOpen] = useState([]);
  const { t } = useTranslation();

  const {
    isLoadMoreImports,
    editImport,
    filterOn,
    isSearchingData,
    isImportLoading,
    rowIndexToMeasure,
    importRowToScrollTo,
    currentImport,
    offset,
  } = useSelector((state) => state.imports);
  const { counts } = useSelector((state) => state.imports.importDatas);
  const searchBarOptions = returnSearchBarOptionsFiltered({
    allAllotments: allGroups.flatAllotments,
    allotmentsAvailable: nodesAvailableForProjectType,
    iniesOptions: [],
    bundleOptions,
    modeledProductOptions,
    currentAllotment,
  });

  let measureCallbacks = {};

  let listRef = useRef();

  let totalCount = returnCountOfImportRows(
    counts.actualCount,
    counts.Validated,
    counts.notValidated,
    counts.userValidated,
    counts.isArchived,
    filterOn,
    displayArchived,
    isSearchingData,
    list
  );

  useEffect(() => {
    if (cache && !isNaN(Number(rowIndexToMeasure))) {
      cache.clear(rowIndexToMeasure, 0);
      dispatch(updateFormValue('rowIndexToMeasure', null));
    }
  }, [rowIndexToMeasure]);

  useEffect(() => {
    if (importRowToScrollTo) {
      listRef.current?.scrollToPosition(importRowToScrollTo);
      dispatch(updateFormValue('importRowToScrollTo', null));
    }
  }, [currentProject]);

  useUpdateEffect(() => {
    if (!isLoadMoreImports) {
      setIsNextPageLoading(false);
    }
  }, [isLoadMoreImports]);

  // function for line component
  const handleEdit = (input, lineId, caracId, value, breadcrumb, e) => {
    const datas = getFlattenImportRows([...editImport]);

    let currentLine = datas.find((lines) => lines.id === lineId);

    dispatch(updateFormValue('isLoad', true));

    let editLines = datas.filter((lines) => lines.id !== lineId);

    switch (input) {
      case 'valueCarac':
        {
          let newCarac = currentLine.ProjectImportElemCaracs.find(
            (elem) => elem.name === caracId
          );
          let newListCarac = currentLine.ProjectImportElemCaracs.filter(
            (elem) => elem.name !== caracId
          );

          newCarac = { ...newCarac, value: value };
          newListCarac = [...newListCarac, newCarac];
          currentLine = {
            ...currentLine,
            Sheet: null,
            SheetId: null,
            ProjectImportElemCaracs: newListCarac.sort(function (a, b) {
              if (a.id < b.id) {
                return -1;
              }

              if (a.id > b.id) {
                return 1;
              }

              return 0;
            }),
          };

          editLines = [...editLines, currentLine];

          dispatch(changeImportDatas(createSetElems(editLines)));

          dispatch(updateFormValue('importPepFdes', []));

          if (breadcrumb) {
            dispatch(verifyRowsEquipment(lineId, { newCarac, currentLine }));
          }
        }
        break;

      case 'AllotmentNode': {
        if (value) {
          if (value && value.name) {
            currentLine.AllotmentNode = value;
            currentLine.SheetId = null;

            editLines = [...editLines, currentLine];

            dispatch(changeImportDatas(createSetElems(editLines)));

            dispatch(updateFormValue('isLoadingPatchLine', true));
            dispatch(
              verifyRowsEquipment({ id: currentLine.id, AllotmentNode: value })
            );
          }
        } else {
          if (!currentLine.AllotmentNode) {
            return;
          }
          currentLine.AllotmentNode = null;
          currentLine.AllotmentNodeId = null;
          currentLine.AllotmentNodes = [];

          editLines = [...editLines, currentLine];

          console.log(currentLine, 'currentLine :)');

          dispatch(changeImportDatas(createSetElems(editLines)));

          dispatch(
            verifyRowsEquipment({
              id: currentLine.id,
              AllotmentNode: null,
              AllotmentNodes: [],
              AllotmentNodeId: null,
            })
          );
        }

        break;
      }

      case 'quantity': {
        currentLine = { ...currentLine, quantity: value };

        editLines = [...editLines, currentLine];

        dispatch(changeImportDatas(createSetElems(editLines)));

        break;
      }
      case 'urbanNetwork': {
        currentLine = {
          ...currentLine,
          urbanNetworkId: value ? value.id : null,
        };

        editLines = [...editLines, currentLine];

        dispatch(changeImportDatas(createSetElems(editLines)));

        dispatch(verifyRowsEquipment(lineId));

        break;
      }
      case 're2020lot': {
        console.log(value, currentLine);

        currentLine = {
          ...currentLine,
          re2020lot: value,
          AllotmentNodes: value ? currentLine.AllotmentNodes : [],
        };

        editLines = [...editLines, currentLine];

        dispatch(changeImportDatas(createSetElems(editLines)));

        dispatch(
          verifyRowsEquipment({
            id: lineId,
            re2020lot: value,
            AllotmentNodes: value ? [value] : [],
          })
        );

        break;
      }
      case 'comment': {
        currentLine = { ...currentLine, comment: value };

        editLines = [...editLines, currentLine];

        dispatch(changeImportDatas(createSetElems(editLines)));

        dispatch(verifyRowsEquipment({ id: lineId, comment: value }));

        break;
      }

      case 'buildingSite': {
        let zone;

        if (value) {
          zone = allNames.find((el) => el.id === currentLine.ZoneId);

          zone = zone.Zones.find((el) => el.type === 'buildingSite');
        } else {
          zone = zones.find((el) => el.id === currentLine.ZoneId);

          zone = zones.find((el) => el.id === zone.ParentId);
        }

        currentLine = { ...currentLine, ZoneId: zone.id };

        editLines = [...editLines, currentLine];

        dispatch(changeImportDatas(createSetElems(editLines)));

        break;
      }

      case 'destination': {
        let zone = allNames.find((el) => el.id === value);

        if (equipmentBuildingSiteId) {
          zone = zone.Zones.find((el) => el.type === 'buildingSite');
        }

        currentLine = { ...currentLine, ZoneId: zone.id };

        editLines = [...editLines, currentLine];

        dispatch(changeImportDatas(createSetElems(editLines)));

        break;
      }

      case 'onValidation': {
        editLines = [...editLines, { ...currentLine, userValidated: true }];

        dispatch(changeImportDatas(createSetElems(editLines)));

        dispatch(verifyRowsEquipment({ id: lineId, userValidated: true }));

        break;
      }
      case 'isRefurbished': {
        currentLine.isRefurbished = !currentLine.isRefurbished;

        editLines = [...editLines, currentLine];

        dispatch(changeImportDatas(createSetElems(editLines)));

        dispatch(
          verifyRowsEquipment({
            id: currentLine.id,
            isRefurbished: currentLine.isRefurbished,
          })
        );

        break;
      }
      case 'sheetPepFdes': {
        let block = false;

        if (!value) {
          currentLine.SheetId = null;
        } else {
          const typeFichesWithAllotmentNodes = ['bundle', 'custom_user'];

          const isTypeFicheIncludesInTypeFichesWithAllotmentNodes =
            typeFichesWithAllotmentNodes.includes(value.type_fiche);

          if (isTypeFicheIncludesInTypeFichesWithAllotmentNodes) {
            value.AllotmentNodes?.map((node, index) => {
              if (
                !checkIfNodeCanBeAddedInProject(
                  node,
                  currentProject,
                  allGroups.flatAllotments
                )
              ) {
                setSnackbarMessage(
                  t('APP.IMPORTLIST.INDEX.PH_966334', {
                    defaultValue:
                      "Le lot d’un ou plusieurs éléments de l’ensemble n’est pas disponible sur ce type de projet. Pour ajouter l'ensemble, aller dans ‘Ma bibliothèque’ afin de modifier le(s) lot(s) bloquants",
                  })
                );
                block = true;

                return;
              }
            });
          }

          if (block) {
            return;
          }
          currentLine.SheetId = value.id;
          currentLine.Sheet = value;

          currentLine.AllotmentNode =
            isTypeFicheIncludesInTypeFichesWithAllotmentNodes
              ? null
              : value.AllotmentNodes.find((el) => el.AllotmentId === 1);
          currentLine.AllotmentNodeId =
            isTypeFicheIncludesInTypeFichesWithAllotmentNodes
              ? null
              : value.AllotmentNodes.find((el) => el.AllotmentId === 1).id;
        }

        editLines = [...editLines, currentLine];

        dispatch(changeImportDatas(createSetElems(editLines)));
        let bodyForPatch = {
          id: currentLine.id,
          SheetId: value ? value.id : null,
        };
        if (value?.type_fiche === 'custom_user') {
          bodyForPatch.AllotmentNode = value.AllotmentNodes.find(
            (el) => el.AllotmentId === 1
          );
        } else if (value) {
          // value.title = undefined;
          bodyForPatch.action = value?.type_fiche === 'bundle' ? 'BundleId' : null;
          bodyForPatch.Sheet = value ? value : null;
          bodyForPatch.AllotmentNode = currentLine.AllotmentNode;
          bodyForPatch.AllotmentNodeId = currentLine.AllotmentNodeId;
        }
        dispatch(verifyRowsEquipment(bodyForPatch));

        break;
      }
      case 'fluids': {
        currentLine.fluid = value;

        editLines = [...editLines, currentLine];

        if (!value.selectedFluid) {
          value.selectedFluid = fluids.find((fluid) =>
            fluid.libelle.includes('R410A')
          ).id;
        } else if (!value.fluidQuantity) {
          value.fluidQuantity = 1;
        }

        dispatch(verifyRowsEquipment({ id: currentLine.id, fluid: value }));
        dispatch(changeImportDatas(createSetElems(editLines)));

        break;
      }
    }
  };

  const handleCopyLine = (line) => {
    if (!copyLine) {
      setCopyLine(line);
    } else {
      dispatch(updateFormValue('isLoadingPatchLine', true));

      dispatch(
        verifyRowsEquipment({
          ...copyLine,
          id: line.id,
          originalRow: line.originalRow,
          originalQuantity: line.originalQuantity,
          originalUnit: line.originalUnit,
          quantity: line.quantity,
          copyLine: true,
        })
      );
      setCopyLine(null);
    }
  };

  const handleNewPageLoad = async () => {
    setIsNextPageLoading(true);

    if (offset > 0) {
      dispatch(fetchImportDatas(null, currentImport));
    }

    return;
  };

  function renderRow({ index, key, style, parent }) {
    const line = list[index];

    if (!list[index]) {
      currentRowIndexDisplayed.current = index;

      return (
        <CellMeasurer
          key={key}
          cache={cache}
          parent={parent}
          columnIndex={0}
          rowIndex={index}
        >
          <div style={style}>
            <RowSkeleton screen={'import'} />
          </div>
        </CellMeasurer>
      );
    }

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {({ measure, registerChild }) => (
          <div ref={registerChild} style={style} key={key}>
            <RowContent
              measure={measure}
              registerChild={registerChild}
              measureCallbacks={measureCallbacks}
              searchBarOptions={searchBarOptions}
              certifiedAllotments={certifiedAllotments}
              index={index}
              groups={groups}
              style={style}
              line={line}
              copyLine={copyLine}
              handleCopyLine={handleCopyLine}
              handleLineCheckbox={handleLineCheckbox}
              handleEdit={handleEdit}
              setCalculatorsOpen={setCalculatorsOpen}
              calculatorsOpen={calculatorsOpen}
              allDatas={line.allAllotmentNodesRelatedToLineMasterData}
              currentMd={line.currentMd}
              openAdvance={openAdvance}
              setOpenAdvance={setOpenAdvance}
              loadingProcess={loadingProcess}
              setSnackbarMessage={setSnackbarMessage}
              setLoadingProcess={setLoadingProcess}
              setCopyLine={setCopyLine}
              CustomPaper={CustomPaper}
            />
          </div>
        )}
      </CellMeasurer>
    );
  }

  function isRowLoaded({ index }) {
    return !!list[index] && !isLoadMoreImports && index < totalCount;
  }

  const loadMoreRows =
    isNextPageLoading || isImportLoading ? () => {} : handleNewPageLoad;

  return (
    <>
      <BreadcrumbModal handleEdit={handleEdit} />
      <div
        style={{
          width: '100%',
          height:
            window.screen.availHeight < 750
              ? ` ${window.screen.availHeight - 355}px`
              : window.screen.availHeight < 950
              ? ` ${window.screen.availHeight - 450}px`
              : '62vh',
        }}
      >
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          rowCount={totalCount}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width, height }) => (
                <List
                  onRowsRendered={onRowsRendered}
                  ref={listRef}
                  width={width}
                  height={height}
                  rowHeight={cache.rowHeight}
                  rowRenderer={renderRow}
                  rowCount={totalCount}
                  deferredMeasurementCache={cache}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </div>
    </>
  );
};

const RowContent = ({
  line,
  measure,
  registerChild,
  measureCallbacks = {},
  index,
  searchBarOptions,
  certifiedAllotments,
  groups,
  key,
  style,
  copyLine,
  handleCopyLine,
  handleLineCheckbox,
  handleEdit,
  setCalculatorsOpen,
  calculatorsOpen,
  openAdvance,
  setOpenAdvance,
  loadingProcess,
  setSnackbarMessage,
  setLoadingProcess,
  setCopyLine,
}) => {
  useEffect(() => {
    cache.clear(index);
  }, []);

  if (line.ProjectElemsDuplicate) {
    let lines;

    lines = line;

    return (
      <div
        className={`id id${line.id} line-subcontainer ${
          line.ProjectElemsDuplicate.find((el) => el.isArchived)
            ? 'line-archived'
            : ''
        }`}
      >
        <div className="duplicate-bundle">
          {(line.name || line.originalRow) && index === 0 && (
            <Tooltip title="Libellé devis" placement="top">
              <p
                className={
                  !lines.ProjectElemsDuplicate.find((el) => el.isArchived)
                    ? 'title-line'
                    : null
                }
              >
                <p style={{ display: 'inline-block' }}>
                  {line.originalName && (
                    <span
                      style={{
                        fontSize:
                          window.screen.availHeight > 810 &&
                          window.screen.availHeight < 950
                            ? '1rem'
                            : window.screen.availHeight < 810
                            ? '0.9rem'
                            : null,
                        fontWeight: 'bold',
                      }}
                    >
                      {line.originalName}{' '}
                    </span>
                  )}
                  {line.originalRow && (
                    <span className="excel">
                      (ligne fichier: {line.originalRow})
                    </span>
                  )}
                </p>
              </p>
            </Tooltip>
          )}
        </div>

        <div className="graphics-around-line">
          <div
            className="line-graphic"
            style={{ height: `${87 + lines.ProjectElemsDuplicate.length}%` }}
          >
            <div className="circle" style={{ bottom: `-5px`, left: '15px' }}></div>
          </div>
          <p className="creation-bundle-title">
            Création d'un ensemble : {line.originalName}
          </p>

          <div style={{ paddingLeft: '3em' }}>
            {lines.ProjectElemsDuplicate.sort((a, b) => b.id - a.id).map(
              (row, index) => {
                return (
                  <span key={`${Math.random()}+${row.id}+${index}`}>
                    <LineComponent
                      lines={lines}
                      searchBarOptions={searchBarOptions}
                      certifiedAllotments={certifiedAllotments}
                      index={index}
                      groups={groups}
                      key={key}
                      style={style}
                      line={row}
                      copyLine={copyLine}
                      handleCopyLine={handleCopyLine}
                      handleLineCheckbox={handleLineCheckbox}
                      handleEdit={handleEdit}
                      setCalculatorsOpen={setCalculatorsOpen}
                      calculatorsOpen={calculatorsOpen}
                      allDatas={row.allAllotmentNodesRelatedToLineMasterData}
                      currentMd={row.currentMd}
                      openAdvance={openAdvance}
                      setOpenAdvance={setOpenAdvance}
                      loadingProcess={loadingProcess}
                      setSnackbarMessage={setSnackbarMessage}
                      setLoadingProcess={setLoadingProcess}
                      setCopyLine={setCopyLine}
                      CustomPaper={CustomPaper}
                    />
                  </span>
                );
              }
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <LineComponent
      groups={groups}
      line={line}
      certifiedAllotments={certifiedAllotments}
      copyLine={copyLine}
      handleCopyLine={handleCopyLine}
      handleLineCheckbox={handleLineCheckbox}
      handleEdit={handleEdit}
      setCalculatorsOpen={setCalculatorsOpen}
      calculatorsOpen={calculatorsOpen}
      allDatas={line.allAllotmentNodesRelatedToLineMasterData}
      openAdvance={openAdvance}
      searchBarOptions={searchBarOptions}
      setOpenAdvance={setOpenAdvance}
      loadingProcess={loadingProcess}
      setSnackbarMessage={setSnackbarMessage}
      setLoadingProcess={setLoadingProcess}
      setCopyLine={setCopyLine}
      CustomPaper={CustomPaper}
    />
  );
};

export default ImportList;
