var colorsPalette = [
  '#D67E8E',
  '#D69954',
  '#5ED674',
  '#74B9D6',
  '#96FFE5',
  '#D68369',
  '#9E7ED6',
  '#D6D05E',
  '#68EDA6',
  '#CA8B84',
  '#6CCFFF',
  '#72E336',
  '#FFC400',
  '#D5BBFF',
  '#FFD5BB',
  '#FE80B1',
  '#CCCCCC',
  '#D15239',
  '#5689E5',
  '#8C8C8C',
  '#F7A19A',
  '#FFDAA2',
  '#C7A0CB',
  '#FDB94D',
  '#00B274',
  '#CE59C1',
  '#FFF685',
  '#AD9268',
];

const colorsPaletteLength = colorsPalette.length;

exports.getColorFromMD = (mdId) => {
  return colorsPalette[mdId % colorsPaletteLength];
};

exports.getColorFromString = (string) => {
  let hash = 0;
  let sLength = string.length;

  if (sLength === 0) return hash;

  for (let index = 0; index < sLength; index += 1) {
    hash = string.charCodeAt(index) + ((hash << string.length) - hash);

    hash = hash & hash;
  }

  hash =
    ((hash % colorsPaletteLength) + sLength + colorsPaletteLength) %
    colorsPaletteLength;

  return colorsPalette[hash];
};
