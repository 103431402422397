let totZonesCount = 0;

const jsonImport = (json, buildingTypes) => {
  if (!isJsonValid(json)) return null;

  const parcelArea = json.surfaceParcelle || null;
  const projectName = json.nom || null;
  const phaseId = json.phaseConcept || null;

  totZonesCount = 0;

  const [newBatCollection, ddvChantier] = extractZoneData(json.c, buildingTypes);

  return [newBatCollection, parcelArea, ddvChantier, projectName, phaseId];
};

const isJsonValid = (json) => {
  if (!json.type || !json.nom || !json.c) return false;

  return true;
};

const extractZoneData = (buildingsDatas, buildingTypes) => {
  let newBatCollection = [];
  let ddvChantier = null;

  for (const [buildingIndex, building] of buildingsDatas.entries()) {
    const newBat = {
      flag: buildingIndex,
      name: building.nom,
      type: 'building',
      nbParkingSpotsSurface: Number(building.nbrParkingPLUSurface),
      nbParkingSpotsUnderground: Number(building.nbrParkingPLUSouterrain),
      Zones: [],
    };

    if (!ddvChantier) {
      ddvChantier = building.dureeChantier || null;
    }

    for (const zone of building.c) {
      if (zone.type === 'Zone') {
        totZonesCount += 1;

        const buildingType = getBuildingTypeFromIniesId(
          zone.typologie,
          buildingTypes
        );

        const newZone = {
          id: totZonesCount,
          name: zone.nom,
          BuildingType: buildingType ? buildingType : null,
          BuildingTypeId: buildingType ? buildingType.id : null,
          iniesBuildingTypeId: zone.typologie || null,
          area: String(zone.sdp || ''),
          sdp: String(zone.sdp || null),
          srt: zone.suRT || null,
          nbLgt: zone.nbLogements,
          nbOccupants: zone.nbrOcupants,
          type: 'zone',
          jsonContributors: zone.c,
        };

        newBat.Zones.push(newZone);
      }
    }

    newBatCollection.push(newBat);
  }

  return [newBatCollection, ddvChantier];
};

const getBuildingTypeFromIniesId = (iniesId, buildingTypes) => {
  if (!iniesId) return null;

  const stringIniesId = String(iniesId);
  let matchingBuildingType = null;

  for (let buildingType of buildingTypes) {
    if (buildingType.iniesIdEpcm === stringIniesId) {
      matchingBuildingType = buildingType;
      break;
    } else {
      for (let childType of buildingType.Parent) {
        if (childType.iniesIdEpcm === stringIniesId) {
          matchingBuildingType = childType;
          break;
        }
      }
    }
  }

  return matchingBuildingType;
};

export default jsonImport;
