/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';

import {
  modifyActivatedSelects,
  setInputValue,
  setSearchValue,
  updateActivatedSelects,
  updateFormValue,
} from '../../actions/projects';

import Breadcrumb from '../../app/shared/Breadcrumb.jsx';

const mapStateToProps = (state) => ({
  searchValue: state.projects.searchValue,
  inputValue: state.projects.inputValue,
  selectedLot: state.projects.selectedLot,
  selectedFonction: state.projects.selectedFonction,
  selectedCategory: state.projects.selectedCategory,
  selectedMaterial: state.projects.selectedMaterial,
  selectedType: state.projects.selectedType,
  disabledBreadcrumbSelects: state.projects.disabledBreadcrumbSelects,
  projectAllot: state.projects.projectAllot,
  currentAllotment: state.projects.currentAllotment,
});

const mapDispatchToProps = (dispatch) => ({
  setSearchValue: (data) => {
    dispatch(setSearchValue(data));
  },
  setInputValue: (data) => {
    dispatch(setInputValue(data));
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  updateActivatedSelects: (id, value) => {
    dispatch(updateActivatedSelects(id, value));
  },
  modifyActivatedSelects: (newSelects) => {
    dispatch(modifyActivatedSelects(newSelects));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);
