import './refModal.scss';
import { Button, Form, Modal } from 'react-bootstrap';
import { StateDTO } from '../../../models/GeneralDTO';
import { fetchProjects, mappingExistantProject } from '../../../../actions/projects';
import { updateFormValue } from '../../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Loader from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import { getProjectsShort } from '../../../../apiCallFunctions/project/project';
import { useGetProjectsShort } from '../../../../hooks/useQuery/projects/getProjectsShort';
import { useQuery } from 'react-query';
import NoocoCheckbox from '../../../shared/NoocoCheckbox';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const RefModal = () => {
  const [loading, setLoading] = React.useState(false);
  const [loadingApi, setLoadingApi] = React.useState(false);
  const [step, setStep] = React.useState(0);

  const dispatch = useDispatch();
  const { refStates, projects, currentProject } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { refModal, refProject, refImport } = useSelector(
    (state: StateDTO) => state.projects.refStates
  );

  const { t } = useTranslation();
  const { data, isLoading, isError } = useGetProjectsShort(refModal);

  useEffect(() => {
    setLoadingApi(false);

    setLoading(false);
  }, [projects]);

  useEffect(() => {
    if (!refModal) {
      setLoading(false);
      setStep(0);
      setLoadingApi(false);
      dispatch(
        updateFormValue('refStates', {
          refModal: false,
          refProject: null,
          refLines: [],
          refImport: {
            caracs: true,
            sheets: true,
          },
        })
      );
    }
  }, [refModal]);

  const handleCreateMappingProject = () => {
    setLoadingApi(true);
    dispatch(mappingExistantProject());
  };

  const returnBodyPage = () => {
    if (step === 0) {
      return (
        <div className="wrapper">
          <p>
            {t('APP.REFMODAL.INDEX.PH_FAC88E', {
              defaultValue:
                "Vous pouvez choisir d'importer les mappings réalisés sur un projet existant afin de les utiliser comme références.",
            })}
          </p>
          <div>
            <p>
              {t('APP.REFMODAL.INDEX.PH_69B689', {
                defaultValue: 'Sélection du projet de référence',
              })}
            </p>
            <Autocomplete
              id="checkboxes-tags-demo"
              options={data || []}
              loading={isLoading}
              noOptionsText={t('APP.REFMODAL.INDEX.PH_01E7DE', {
                defaultValue: 'Aucun résultat trouvé',
              })}
              onChange={(event, newValue) => {
                dispatch(
                  updateFormValue('refStates', {
                    ...refStates,
                    refProject: newValue,
                  })
                );
              }}
              size={'small'}
              getOptionLabel={(option) => option.name || ''}
              renderInput={(params) => (
                <TextField
                  style={{ borderRadius: '10px' }}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  placeholder=""
                  variant="outlined"
                  className="researchBar"
                />
              )}
            />
          </div>
          <div>
            <p>
              {t('APP.REFMODAL.INDEX.PH_42F9BF', {
                defaultValue: 'Renommer le projet (facultatif)',
              })}
            </p>
            <Form.Control
              onChange={(e) =>
                dispatch(
                  updateFormValue('refStates', {
                    ...refStates,
                    refProjectRename: e.target.value,
                  })
                )
              }
              key={refProject?.name}
              defaultValue={refProject?.name}
              placeholder=""
              variant="outlined"
              className="researchBar"
            />
          </div>
          <p>
            {t('APP.REFMODAL.INDEX.PH_E60F9E', {
              defaultValue:
                'Les lignes avec un libellé source et un libellé devis seront importées comme mappings de référence.',
            })}
          </p>
        </div>
      );
    } else {
      return (
        <div className="wrapper">
          <p>{t('APP.REFMODAL.INDEX.PH_9F1FEF', { defaultValue: 'Importer :' })}</p>
          <div>
            <div className="import-choice-ref">
              <NoocoCheckbox
                defaultChecked={true}
                onChange={(e) =>
                  dispatch(
                    updateFormValue('refStates', {
                      ...refStates,
                      refImport: { ...refImport, caracs: e.target.checked },
                    })
                  )
                }
              />

              <p>
                {t('APP.REFMODAL.INDEX.PH_C878E5', {
                  defaultValue: 'Les caractéristiques',
                })}
              </p>
            </div>
            <div className="import-choice-ref">
              <NoocoCheckbox
                defaultChecked={true}
                onChange={(e) =>
                  dispatch(
                    updateFormValue('refStates', {
                      ...refStates,
                      refImport: { ...refImport, sheets: e.target.checked },
                    })
                  )
                }
              />

              <p>
                {t('APP.REFMODAL.INDEX.PH_08D153', {
                  defaultValue: 'Les produits certifiés associés',
                })}
              </p>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <Modal
        show={refModal}
        onHide={() => {
          dispatch(
            updateFormValue('refStates', {
              ...refStates,
              refModal: false,
              refProject: null,
            })
          );
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('APP.REFMODAL.INDEX.PH_79107B', {
              defaultValue: 'Utiliser un projet existant',
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{returnBodyPage()}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              dispatch(
                updateFormValue('refStates', {
                  ...refStates,
                  refModal: false,
                  refProject: null,
                })
              );
            }}
          >
            Fermer
          </Button>
          {!loadingApi && (
            <Button
              variant="secondary"
              disabled={!refProject}
              onClick={() =>
                step === 0 ? setStep(1) : handleCreateMappingProject()
              }
            >
              {t('APP.REFMODAL.INDEX.PH_C213B5', { defaultValue: 'Continuer' })}
            </Button>
          )}

          {loadingApi && (
            <div style={{ width: '100px', display: 'flex' }}>
              <Loader
                variant="indeterminate"
                disableShrink
                style={{ color: '#73b7ec', margin: 'auto' }}
                size={30}
                thickness={4}
              />
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RefModal;
