/* eslint-disable no-unused-vars */
import { Dispatch } from 'redux';
import { MapDispatchToPropsParam, MapStateToPropsParam, connect } from 'react-redux';

import { NewActionDTO } from '../../app/models/ExploitationDTO';
import Control from '../../app/exploitation/ListActions/AddActions/Control';

import {
  createAction,
  resetAddAction,
  updateExploitationValue,
} from '../../actions/exploitation';

interface IExploitation {
  controlChoice: string;
  newAction: NewActionDTO;
  boardingListDatas: {}[];
}

interface IProjects {
  allGroups: {
    type: [];
  };
  fluids: {}[];
}

type State = {
  exploitation: IExploitation;
  projects: IProjects;
};

const mapStateToProps: MapStateToPropsParam<{}, {}, State> = (state: State) => ({
  controlChoice: state.exploitation.controlChoice,
  newAction: state.exploitation.newAction,
  boardingListDatas: state.exploitation.boardingListDatas,
  fluids: state.projects.fluids,
});

const mapDispatchToProps: MapDispatchToPropsParam<{}, {}> = (
  dispatch: Dispatch
) => ({
  updateExploitationValue: (name: string, value: string) => {
    dispatch(updateExploitationValue(name, value));
  },
  resetAddAction: () => {
    dispatch(resetAddAction());
  },
  createAction: (t) => {
    dispatch(createAction(t));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Control);
