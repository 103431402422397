import './simulatorVariants.scss';
import { Popover } from 'antd';
import { Button, SearchGroup, Dropdown } from '@photocarbone/nooco-ui';
import { Modal } from 'antd';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import {
  EquipmentDatas,
  LineProductGraph,
} from '@photocarbone/nooco-equipment-components';
import { NewEquipmentDTO, VariantDTO } from '../../models/SimulatorDTO';
import { StateDTO } from '../../models/GeneralDTO';
import AppContext from '../../../app/AppContext';
import NoocoCheckbox from '../../shared/NoocoCheckbox';

import { Button as ButtonMaterial } from '@material-ui/core';
import {
  FETCH_PROJECT_BY_ID,
  fetchProjectById,
  updateFormValue,
} from '../../../actions/projects';
import { Modal as ModalBootstrap } from 'react-bootstrap';
import { Table } from 'antd';
import { autocompleteClasses } from '@mui/material';
import {
  createSimulatorFirstVariantNewEquipment,
  createSimulatorSecondVariantNewEquipment,
  deleteSimulatorEquipment,
  fetchSheetsGraph,
  getCaracSimulatorVariant,
  loadProjectBySimulator,
  simulatorDuplicateVariant,
  simulatorFirstVariantReset,
  simulatorSecondVariantReset,
  updateSimulator,
  updateSimulatorFirstVariant,
  updateSimulatorSecondVariant,
} from '../../../actions/simulator';
import {
  displayResultImpact,
  findChildrenNodes,
  findParentNode,
  handleAllotmentNodes,
  parentAllotmentNodesOfTypeProjectsUserRight,
  returnSearchBarOptionsFiltered,
} from '../../shared/utils';
import {
  isNumHigherZero,
  onChangeSelectedSearchGroupType,
  resultBestOptimization,
} from '../../shared/utilsFunction';
import {
  saveFiltersBrand,
  saveSheets,
  setLoadingGraph,
} from '../../../actions/filtersgraph';
import { truncate } from 'fs';
import { updateNewInies } from '../../../actions/equipments';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ButtonUi from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@mui/material/MenuItem';
import React, { useContext, useEffect, useState } from 'react';
import SelectUi from '@mui/material/Select';
import SwitchEquipment from '../../../containers/SwitchEquipment';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import API from '../../services/API';
import FluidsForm, {
  HandleFluidsChangeReason,
  NewValueType,
} from '../../shared/FluidsForm';
import { useGetCaracsByAllotmentNodeId } from '../../../hooks/useQuery/equipments';
import BreadcrumbModal from '../../../containers/BreadcrumbModal';
import { ALLOTMENT_IDS } from '../../globalVariable/typeProject';
import { useTranslation } from 'react-i18next';
import { SEARCH_GROUP_OPTION_VALUES } from '../../globalVariable/searchGroupType';
import { useLocalStorage } from 'react-use';
import { SelectedSearchGroupOptionsLabel } from '../../shared/QuickAddBarEquipment/QuickAddBarEquipment';

const AntTabs = withStyles({
  root: {
    textAlign: 'center',
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#22C994',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: ['Roboto'].join(','),
    '&:hover': {
      color: '#22C994',
      opacity: 1,
    },
    '&$selected': {
      color: '#22C994',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#22C994',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export type SimulatorVariantsProps = {
  variantNumber: 'first' | 'second';
  variant: {
    loadingVariant: boolean;
    variantListDatas: VariantDTO[];
    newEquipment: NewEquipmentDTO;
    selectedRowKeys: number[];
    newEquipmentQuantity: number | null;
    addChoice: 'energy' | 'equipment' | undefined;
  };
  classes: any;
};

const saveSimulatorIcon = require('../../../assets/images/simulator/saveSimulatorIcon.png');
const saveComponantButtonIcon = require('../../../assets/images/simulator/saveComponantButtonIcon.png');
const exportButtonIcon = require('../../../assets/images/simulator/exportButtonIcon.png');
const copyVariantIcon = require('../../../assets/images/simulator/copyVariantIcon.png');
const potentialIcon = require('../../../assets/images/simulator/potentialInformation.png');
// const resetVariantIcon = require('../../../assets/images/simulator/resetVariantIcon.png');
const graphIcon = require('../../../assets/images/simulator/graphIcon.png');

// Function to check input to create new equipment.
export const isUserSimulatorInputValid = ({
  newEquipment,
  newEquipmentQuantity,
}) => {
  if (!isNumHigherZero(newEquipmentQuantity)) {
    return false;
  }

  const typeFicheNeedsSheetId = ['bundle', 'inies'];
  if (
    typeFicheNeedsSheetId.includes(newEquipment?.source) ||
    newEquipment?.type_fiche === 'FC'
  ) {
    return newEquipment?.SheetId ? true : false;
  }

  const equipmentNeedAllotmentNode = !['bundle', 'custom_user'].includes(
    newEquipment?.type_fiche
  );

  if (!newEquipment.AllotmentNode && equipmentNeedAllotmentNode) {
    return false;
  }

  const caracs = newEquipment?.caracs?.caracs;
  const caracsWithoutValueOrInvalid = caracs?.some((carac) => {
    const hasNoValue =
      !carac.value ||
      carac.value.length !== 1 ||
      carac?.value[0] === '' ||
      carac?.value[0] === '0';
    // carac without caracList === input (select for carac with caracList)
    const needsNumHigherZeroCheck =
      !carac?.caracList &&
      carac?.value?.length === 1 &&
      !isNumHigherZero(carac?.value[0]);

    return hasNoValue || needsNumHigherZeroCheck;
  });

  if (caracsWithoutValueOrInvalid) {
    return false;
  }

  const equipmentHasFluid = newEquipment?.MasterDataEquipment?.hasFluid;
  const equipmentHasDefaultFluid =
    newEquipment?.fluid?.isDefaultFluid || !newEquipment?.fluid;
  const isValidFluid =
    !equipmentHasFluid ||
    equipmentHasDefaultFluid ||
    (equipmentHasFluid &&
      !equipmentHasDefaultFluid &&
      newEquipment?.fluid?.selectedFluid &&
      isNumHigherZero(newEquipment?.fluid?.fluidQuantity));

  if (!isValidFluid) {
    return false;
  }

  return true;
};

const SimulatorVariants = ({
  variantNumber,
  variant,
  classes,
}: SimulatorVariantsProps) => {
  const ctx = useContext(AppContext);
  const [openGraph, setOpenGraph] = useState(false);
  const [showModalDuplicate, setShowModalDuplicate] = useState(false);

  const dispatch = useDispatch();
  const {
    variantListDatas,
    newEquipment,
    selectedRowKeys,
    newEquipmentQuantity,
    loadingVariant,
    addChoice,
  } = variant;
  const {
    allGroups,
    filters,
    isLoadingProjectDetails,
    switchSelectedEquipment,
    currentProject,
    replaceEquipment,
    equipmentsAvailable,
    sheets,
    searchValue,
    licenceTypeAccess,
    templateTypeAvailableIds,
    bundleOptions,
    modeledProductOptions,
    configuredSheetOptions,
    listOfNodesSearchBar,
    scope,
    certifiedAllotments,
    user,
  } = useSelector((state: StateDTO) => state.projects);
  const { currentIndicator } = useSelector(
    (state: StateDTO) => state.simulator.dataForGraph
  );
  const {
    sheetsGraph,
    equipmentClicked,
    parsedProject,
    dataForGraph,
    currentAllotment,
    information,
    loadingGraph,
    needDatas,
  } = useSelector((state: StateDTO) => state.simulator);

  const searchBarOptionsFiltered = returnSearchBarOptionsFiltered({
    allAllotments: allGroups.flatAllotments,
    allotmentsAvailable: listOfNodesSearchBar,
    iniesOptions: [],
    bundleOptions,
    modeledProductOptions,
    configuredSheetOptions,
  });
  const { t } = useTranslation();

  const add = require('../../../assets/images/exploitation/add.png');
  const energyIcon = require('../../../assets/images/equipments/energy.png');
  const { projects } = useSelector((state: StateDTO) => state);
  const { variantNumberForModal } = useSelector(
    (state: StateDTO) => state.simulator
  );
  const graphicChoice = useSelector(
    (state: StateDTO) => state.simulator.graphicChoice
  );
  const caracsIcon = require('../../../assets/images/exploitation/caracs.png');
  // const {information} = useSelector((state: StateDTO) => state.simulator);
  const apiNooco = new API();

  // get Caracs everytime MD change
  const [selectedEquipment, setSelectedEquipment] = useState('');
  const [lockedSelectedEquipment, setLockedSelectedEquipment] = useState('');
  const [masterDataEquipmentOptions, setMasterDataEquipmentOptions] = useState([]);
  const [masterDataEnergyOptions, setMasterDataEnergyOptions] = useState([]);
  const [isConfiguredSheetSelected, setIsConfiguredSheetSelected] =
    useState<boolean>(false);
  const [selectedSearchGroupType, setSelectedSearchGroupType] =
    useLocalStorage<SelectedSearchGroupOptionsLabel>(
      `selectedSearchGroupType-${user.userId}`,
      SelectedSearchGroupOptionsLabel.Equipements
    );

  useEffect(() => {
    if (
      masterDataEquipmentOptions.length === 0 &&
      masterDataEnergyOptions.length === 0
    ) {
      const parentAllotments = parentAllotmentNodesOfTypeProjectsUserRight(
        templateTypeAvailableIds,
        allGroups.projectTypes,
        allGroups.flatAllotments
      );
      const parentAllotmentsEquipments = parentAllotments.filter(
        (node) =>
          node.name !== 'Energie' && node.name !== 'Déchets' && node.name !== 'Eau'
      );
      const parentAllotmentsEnergie = parentAllotments.filter(
        (node) => node.name === 'Energie'
      );

      const childrenAllotmentNodesEquipements = findChildrenNodes(
        parentAllotmentsEquipments,
        allGroups.flatAllotments
      );
      const childrenAllotmentNodesEnergie = findChildrenNodes(
        parentAllotmentsEnergie,
        allGroups.flatAllotments
      );

      const listEquipmentsMdIds = childrenAllotmentNodesEquipements[4].map(
        (node) => node.MasterDataEquipmentId
      );
      const listEnergiesMdIds = childrenAllotmentNodesEnergie[4].map(
        (node) => node.MasterDataEquipmentId
      );

      setMasterDataEquipmentOptions(
        allGroups.type
          .filter((md) => listEquipmentsMdIds.includes(md.id))
          .sort((a, b) => a.name - b.name)
      );
      setMasterDataEnergyOptions(
        allGroups.type
          .filter((md) => listEnergiesMdIds.includes(md.id))
          .sort((a, b) => a.name - b.name)
      );
    }
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      updateSimulatorVariant('selectedRowKeys', selectedRowKeys);
    },
    columnWidth: 10,
    checkStrictly: false,
  };

  useEffect(() => {
    if (newEquipment?.AllotmentNode?.id && !newEquipment?.SheetId) {
      dispatch(
        getCaracSimulatorVariant(variantNumber, [newEquipment.AllotmentNode.id])
      );
    }
  }, [newEquipment?.AllotmentNode?.id]);

  const handleSaveFilterBrand = (qsd, qsds, qsdd) => {
    dispatch(saveFiltersBrand(qsd, qsds, qsdd));
  };

  const handleUpdateFormValue = (name, value) => {
    dispatch(updateFormValue(name, value));
  };

  const handleFetchProjectById = (id, indicatorId) => {
    dispatch(fetchProjectById(id, indicatorId));
  };

  const handleUpdateNewInies = (sheet) => {
    dispatch(updateNewInies(sheet));
  };

  const resetNewEquipment = () => {
    const emptyNewEquipment = {
      MasterDataEquipment: null,
      quantity: null,
      caracs: [],
    };

    updateSimulatorVariant('newEquipment', emptyNewEquipment);
    updateSimulatorVariant('newEquipmentQuantity', null);
  };

  const onChangeDropdownAllotmentNode = (value) => {
    console.log('🚀 ~ onChangeDropdownAllotmentNode ~ value:', value);
    let md = null;
    let result = null;
    let newEquipmentUpdated;
    let variantNumberActive = variantNumberForModal || variantNumber;

    if (value?.id_inies) {
      const node = listOfNodesSearchBar.find(
        (node) => node.id === value.AllotmentNodes[0].id
      );

      newEquipmentUpdated = {
        quantity: null,
        caracs: [],
        SheetId: value.id,
        source: 'inies',
        type_fiche: value.type_fiche,
        unit: value?.AllotmentNodes[0]?.MasterDataEquipment?.unit || '',
        iniesNode: node,
        AllotmentNode: value.AllotmentNodes[0],
        AllotmentNodes: value.AllotmentNodes.map(
          (node) => findParentNode(node, allGroups.flatAllotments, [], [])[0]
        ),
      };
    } else if (value?.type_fiche === 'bundle') {
      newEquipmentUpdated = {
        quantity: null,
        caracs: [],
        SheetId: value.id,
        type_fiche: 'bundle',
        unit: value.unit,
      };
    } else if (value?.type_fiche === 'custom_user') {
      // so if it's a modeledProduct
      newEquipmentUpdated = {
        AllotmentNodes: value.AllotmentNodes,
        quantity: null,
        SheetId: value.id,
        type_fiche: 'custom_user',
        unit: value.unit,
        name: value?.name,
      };
    } else if (isConfiguredSheetSelected) {
      const configuredSheet = value;
      newEquipmentUpdated = {
        AllotmentNodes: configuredSheet.AllotmentNodes,
        quantity: null,
        SheetId: configuredSheet.id,
        type_fiche: 'FC',
        unit: configuredSheet?.unit,
        name: configuredSheet?.name,
      };
    } else if (typeof value === 'number') {
      const AllotmentNode = listOfNodesSearchBar.find((node) => node.id === value);
      md = allGroups.type.find(
        (md) => md.id === AllotmentNode.MasterDataEquipmentId
      );

      result = handleAllotmentNodes(
        md,
        currentAllotment,
        allGroups.flatAllotments,
        currentProject
      );

      newEquipmentUpdated = {
        quantity: null,
        caracs: [],
        AllotmentNode: { ...AllotmentNode, label: AllotmentNode.name },
        MasterDataEquipment: md,
        AllotmentNodes: result ? result.filter((el) => !el.isObsolete) : [],
      };
    } else {
      md =
        value.AllotmentNodes && value.AllotmentNodes[0]
          ? allGroups.type.find(
              (md) => md.id === value.AllotmentNodes[0].MasterDataEquipmentId
            )
          : null;

      newEquipmentUpdated = {
        quantity: null,
        caracs: [],
        SheetId: value?.id,
        AllotmentNode: value.AllotmentNodes[0],
        AllotmentNodes: value.AllotmentNodes.map(
          (node) => findParentNode(node, allGroups.flatAllotments, [], [])[0]
        ),
        MasterDataEquipment: md,
      };
    }

    updateSimulatorVariant('newEquipment', newEquipmentUpdated);

    if (variantNumberForModal) {
      dispatch(updateFormValue('openBreadcrumb', false));
      dispatch(updateSimulator('variantNumberForModal', null));
    }
  };

  const windowWidth = window.innerWidth;

  const renderFooter = () => {
    return (
      <>
        {!addChoice && (
          <div className="tableFooterChoice">
            <Button
              className="purple-button"
              onClick={() => {
                updateSimulatorVariant('addChoice', 'equipment');
              }}
              style={{
                marginRight: '15px',
                width: windowWidth < 1550 ? '180px' : '',
                fontSize: windowWidth < 1550 ? '11px' : '',
              }}
              text={t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_8ED44F', {
                defaultValue: 'Ajouter un équipement',
              })}
            />
          </div>
        )}
        {addChoice === 'equipment' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              maxWidth: '100%',
              // flexGrow: '1',
              // paddingRight: '1rem',
            }}
          >
            <div className="tableFooter">
              {/* <div className="tableFooter__addIconContainer"> */}
              {windowWidth > 1490 ? (
                <img className="tableFooter__addIconContainer" src={add} />
              ) : (
                <></>
              )}
              {/* </div> */}
              <div className="tableFooter__searchMasterData" key={newEquipment}>
                <SearchGroup
                  t={t}
                  companyId={currentProject.CompanyId}
                  searchBarOptionsFiltered={searchBarOptionsFiltered}
                  projectTypeId={currentProject.ProjectTypeId}
                  certifiedAllotments={certifiedAllotments}
                  findParentNode={findParentNode}
                  allAllotments={allGroups.flatAllotments}
                  allotmentsAvailable={listOfNodesSearchBar?.filter(
                    (el) => !el.isObsolete
                  )}
                  dropdownHeight="70px"
                  api={apiNooco}
                  dropdownProps={{
                    onSelect: () => {},
                    onChange: (value) => {
                      if (!value || typeof value === 'string') {
                        resetNewEquipment();
                      }
                      if (value && typeof value !== 'string') {
                        onChangeDropdownAllotmentNode(value);
                      }
                    },
                    placeholder: t(
                      'APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_DE524C',
                      { defaultValue: 'Rechercher...' }
                    ),
                    treeCheckable: false,
                  }}
                  dropdownWidth="180px"
                  selectProps1={{
                    defaultValue: selectedSearchGroupType,
                    onChange: (value) => {
                      setIsConfiguredSheetSelected(
                        value.value === 'fichesconfig' ? true : false
                      );
                      resetNewEquipment();
                      onChangeSelectedSearchGroupType(
                        setSelectedSearchGroupType,
                        SelectedSearchGroupOptionsLabel[value.label] ||
                          SelectedSearchGroupOptionsLabel[value.value] ||
                          SelectedSearchGroupOptionsLabel.Equipements
                      );
                    },
                    filterOptions: [
                      SEARCH_GROUP_OPTION_VALUES.EQUIPEMENTS,
                      SEARCH_GROUP_OPTION_VALUES.INIES,
                      SEARCH_GROUP_OPTION_VALUES.ENERGIE,
                      SEARCH_GROUP_OPTION_VALUES.EAU,
                      SEARCH_GROUP_OPTION_VALUES.BIBLIOTHEQUE,
                    ],
                  }}
                  selectProps2={{
                    ListType: ['Nooco'],
                    noocoOptions: newEquipment.AllotmentNodes || null,
                    keyDisplay: 'name',
                    keyItem: 'id',
                    onChange: (selectedOptionValue, type) => {
                      const newAllotmentNode = newEquipment.AllotmentNodes?.find(
                        (el) => el.name === selectedOptionValue
                      );
                      updateSimulatorVariant('newEquipment', {
                        ...newEquipment,
                        AllotmentNode: newAllotmentNode?.details.find(
                          (el) => el.MasterDataEquipmentId
                        ),
                      });
                    },
                    value: newEquipment.AllotmentNode
                      ? [newEquipment.AllotmentNode]
                      : null,
                  }}
                  // selectWidth="100px"
                  triggerBreadcrumb={() => {
                    dispatch(updateFormValue('openBreadcrumb', true));
                    dispatch(
                      updateSimulator('variantNumberForModal', variantNumber)
                    );
                  }}
                />
              </div>
              <div className="tableFooter__quantity">
                <TextField
                  value={newEquipmentQuantity === null ? '' : newEquipmentQuantity}
                  defaultValue=""
                  onChange={(e) => {
                    updateSimulatorVariant('newEquipmentQuantity', e.target.value);
                  }}
                  type="number"
                  placeholder={t(
                    'APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_C8F1DE',
                    { defaultValue: 'Quantité' }
                  )}
                  variant="outlined"
                  className="tableFooter__quantity-textField"
                />
              </div>
              <div className="tableFooter__unity">
                <p>
                  {newEquipment.MasterDataEquipment
                    ? newEquipment.MasterDataEquipment.unit
                    : newEquipment?.unit || ''}
                </p>
              </div>
              {newEquipment.caracs?.caracs?.length > 0 && (
                <div className="tableFooter__caracs">
                  <Popover
                    overlayClassName="custom-carac-popover-simulator"
                    defaultVisible={true}
                    placement="top"
                    title={t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_B6CFC0', {
                      defaultValue: 'Caractéristiques',
                    })}
                    content={renderCaracPopOver}
                    trigger="click"
                  >
                    <img
                      src={caracsIcon}
                      className={
                        newEquipment.caracs?.caracs.find(
                          (el) => !el.value || el.value.length !== 1
                        )
                          ? 'tableFooter__caracs--empty'
                          : 'tableFooter__caracs--full'
                      }
                      alt=""
                    />
                  </Popover>
                </div>
              )}
              <Button
                key={`${newEquipment} ${newEquipmentQuantity}`}
                onClick={() => {
                  updateSimulatorVariant('loadingVariant', true);
                  dispatch(updateSimulator('loadingGraph', true));
                  if (variantNumber === 'first') {
                    dispatch(createSimulatorFirstVariantNewEquipment());
                  } else {
                    dispatch(createSimulatorSecondVariantNewEquipment());
                  }
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  with: windowWidth < 1500 ? '70px' : '',
                  padding: windowWidth < 1500 ? '6px 12px' : '',
                  fontSize: windowWidth < 1500 ? '11px' : '',
                }}
                disabled={
                  !isUserSimulatorInputValid({
                    newEquipment,
                    newEquipmentQuantity,
                  })
                }
                className="purple-button"
                text={t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_10B450', {
                  defaultValue: 'Ajouter',
                })}
              />
              <IconButton
                onClick={() => {
                  updateSimulatorVariant('addChoice', undefined);
                  resetNewEquipment();
                }}
              >
                <ClearIcon />
              </IconButton>
            </div>
          </div>
        )}
      </>
    );
  };

  const calculTotalImpact = () => {
    const totalAcv = variant?.parsedProject?.zones[0].totalAcv[1];
    const isStatic = information.style === 'static';

    let totalImpact = isStatic ? totalAcv?.static : totalAcv?.dynamic;

    return displayResultImpact(totalImpact, information, 'kg éq. CO₂', 'onTab');
  };

  const calculBestOpti = () => {
    const ddv = Number(information.lifespan);
    const area = Number(information.area);

    return resultBestOptimization(
      variant.data.project.Zones[0].ProjectSheets,
      ddv,
      information.refUnit,
      area,
      true,
      currentIndicator
    );
  };

  const updateSimulatorVariant = (key, data) => {
    if (variantNumber === 'first') {
      dispatch(updateSimulatorFirstVariant(key, data));
    } else {
      dispatch(updateSimulatorSecondVariant(key, data));
    }
  };

  const handleFluidsChange = (
    newValue: NewValueType,
    reason: HandleFluidsChangeReason
  ) => {
    let fluid: NewEquipmentDTO['fluid'] = newEquipment.fluid || {
      isDefaultFluid: true,
      selectedFluid: null,
      fluidQuantity: null,
    };

    if (reason === HandleFluidsChangeReason.IsDefaultFluid && newValue === true) {
      fluid = undefined;
    } else {
      fluid = { ...fluid, [reason]: newValue };
    }

    updateSimulatorVariant('newEquipment', {
      ...newEquipment,
      fluid,
    });
  };

  const handleChangeCarac = (newValue, carac) => {
    const isDiscreteCarac = carac.id === newEquipment.caracs?.discreteId;
    let newCaracs = newEquipment.caracs.caracs.map((el) => {
      if (el.id === carac.id) {
        return {
          ...carac,
          value: [newValue],
        };
      } else if (
        isDiscreteCarac &&
        el.id === newEquipment.caracs?.secondDiscreteId
      ) {
        return {
          ...el,
          value: null,
        };
      } else {
        return el;
      }
    });
    updateSimulatorVariant('newEquipment', {
      ...newEquipment,
      caracs: { ...newEquipment.caracs, caracs: newCaracs },
    });
  };

  // display of carac popover
  const renderCaracPopOver = () => {
    // return (<div>en attente</div>);
    return (
      <div>
        {newEquipment.caracs.caracs
          .sort(
            (a, b) =>
              (a.id === newEquipment.MasterDataEquipment?.secondDiscreteId) -
                (b.id === newEquipment.MasterDataEquipment?.secondDiscreteId) ||
              (a.id === newEquipment.MasterDataEquipment?.discreteId) -
                (b.id === newEquipment.MasterDataEquipment?.discreteId) ||
              (a.id === newEquipment.MasterDataEquipment?.xAxisId) -
                (b.id === newEquipment.MasterDataEquipment?.xAxisId)
          )
          .map((carac) => {
            let caracListOptions = carac.caracList;

            if (
              newEquipment.caracs.caracs?.find(
                (el) => el.id === newEquipment.caracs.caracCombos?.id
              )?.value &&
              newEquipment.caracs.caracCombos.valueList?.find(
                (el) =>
                  el.value ===
                    newEquipment.caracs.caracs?.find(
                      (car) => car.id === newEquipment.caracs.caracCombos.id
                    )?.value[0] && carac.name === el.name
              )
            ) {
              caracListOptions = newEquipment.caracs.caracCombos.valueList.find(
                (el) =>
                  el.value ===
                  newEquipment.caracs.caracs?.find(
                    (el) => el.id === newEquipment.caracs.caracCombos.id
                  )?.value[0]
              )?.valueList;
            }

            return (
              <div className="exploitation-carac" key={carac.id}>
                <p>{carac.name} :</p>
                {!carac.type ? (
                  <TextField
                    style={{ width: '50%', marginLeft: '10px' }}
                    onChange={(e) => handleChangeCarac(e.target.value, carac)}
                  />
                ) : (
                  <>
                    <Dropdown
                      disabled={
                        newEquipment.caracs.caracCombos
                          ? newEquipment.caracs.caracCombos.valueList?.find(
                              (el) => el.id === carac.id
                            ) &&
                            carac.id !== newEquipment.caracs.caracCombos.id &&
                            !newEquipment.caracs.caracs?.find(
                              (el) => el.id === newEquipment.caracs.caracCombos.id
                            )?.value
                          : false
                      }
                      style={{ width: '100%', marginLeft: '10px' }}
                      onChange={(value) => {
                        handleChangeCarac(value, carac);
                      }}
                      onSelect={(value) => {
                        handleChangeCarac(value, carac);
                      }}
                      treeCheckable={false}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      // labelId="demo-simple-select-label"
                      // id="demo-simple-select"
                      value={
                        newEquipment.caracs.caracs?.find(
                          (el) => el.name === carac.name
                        )?.value
                      }
                      options={
                        caracListOptions
                          ?.sort((a, b) => a?.value - b?.value)
                          .map((el) => ({
                            ...el,
                            title: el.value,
                            value: el.value,
                            key: el.value,
                          })) || []
                      }
                    />
                  </>
                )}

                <p>{carac.unit}</p>
              </div>
            );
          })}
        {newEquipment.MasterDataEquipment?.hasFluid && (
          <FluidsForm onChange={handleFluidsChange} simulator={true} />
        )}
      </div>
    );
  };

  const handleOpenGraph = (record) => {
    dispatch(updateFormValue('isLoadingProjectDetails', true));

    const data = {
      data: {
        project: variant.data.project,
        mds: variant.data.mds,
        urbanNetworks: null,
        zones: null,
      },
    };

    dispatch(loadProjectBySimulator(data, variant));

    const currentMasterDataId = record.MasterDataEquipmentId;
    const currentProjectSheets = record.parsedProjectZones[0].ProjectSheets;
    const currentProjectSheet = currentProjectSheets.find(
      (projectSheet) =>
        projectSheet.AllotmentNodes.find(
          (allotmentNode) => allotmentNode?.AllotmentId === ALLOTMENT_IDS.NOOCO
        )?.MasterDataEquipmentId === currentMasterDataId
    );
    const currentAllotmentNode = currentProjectSheet.AllotmentNodes.find(
      (allotmentNode) => allotmentNode.AllotmentId === 1
    );
    record.AllotmentNode = currentAllotmentNode;

    apiNooco
      .getCaracsByAllotmentNodes(
        [currentAllotmentNode.id],
        currentProjectSheet.id,
        true
      )
      .then((response) => {
        dispatch(
          updateSimulator('equipmentClicked', {
            ...record,
            MasterDataEquipment: {
              ...record?.currentMd,
              Caracs: response.data.caracs,
            },
          })
        );

        dispatch(updateFormValue('nodeSearchValue', currentAllotmentNode));
        dispatch(
          fetchSheetsGraph(
            currentAllotmentNode.id,
            currentMasterDataId,
            currentIndicator.id,
            variant.data.project.id
          )
        );
        setOpenGraph(true);
        dispatch(updateFormValue('searchValue', record.MasterDataEquipment));
      });
  };

  const handleCloseGraph = () => {
    setOpenGraph(false);
    setLockedSelectedEquipment('');
    setSelectedEquipment('');
    dispatch(updateFormValue('isLoadingProjectDetails', false));
    dispatch(updateSimulator('equipmentClicked', null));
    dispatch(updateSimulator('sheetsGraph', null));
    dispatch(updateFormValue('sheets', []));
    dispatch(
      updateFormValue('scope', {
        x: { min: 0, max: 'auto' },
        y: { min: 0, max: 'auto' },
      })
    );
    dispatch(updateFormValue('currentProject', {}));
    dispatch(updateFormValue('isLoadingProjectDetails', true));
    dispatch(updateFormValue('zones', []));
    dispatch(updateFormValue('spinnerProjectModal', false));
    dispatch(updateFormValue('currentAllotment', null));
    dispatch(updateFormValue('currentIndicator', null));
    dispatch(updateFormValue('mds', null));
    dispatch(updateFormValue('lotMode', null));
    dispatch(updateFormValue('allotSteps', []));
    dispatch(updateFormValue('searchValue', ''));
    dispatch(updateFormValue('filters', []));

    // dispatch(updateFormValues('sdansprojectAllo/tructureGraph', null));
    // dispatch(updateFormValues('graphData',
    //   null));
  };

  const onLineChartEquipmentChangeCallback = (equipment) => {
    setSelectedEquipment(equipment);
  };

  const onLineChartLockedEquipmentChangeCallback = (equipment) => {
    setLockedSelectedEquipment(equipment);
    setSelectedEquipment(equipment);
  };

  const onEquipmentSheetEquipmentSelect = (equipment) => {
    setLockedSelectedEquipment(equipment);
  };

  const LoaderGraphSimulator = () => {
    return (
      <div className="graphSimulator-spinner-wrapper">
        <div className="donut" />
      </div>
    );
  };

  const renderEquipmentGraph = () => {
    return (
      <div className="modalWrapper">
        {(!equipmentClicked || sheets.length === 0 || isLoadingProjectDetails) && (
          <div className="modalLoader">
            <LoaderGraphSimulator />
          </div>
        )}

        {equipmentClicked && sheets.length !== 0 && !isLoadingProjectDetails && (
          <>
            <div className="modalLineProductGraph">
              <LineProductGraph
                onEquipmentChange={onLineChartEquipmentChangeCallback}
                onLockedEquipmentChange={onLineChartLockedEquipmentChangeCallback}
                filters={{
                  selectedLot: null,

                  selectedType: null,

                  selectedMaterial: null,

                  selectedCategory: null,

                  selectedFonction: null,

                  filters,
                }}
                updateFormValue={handleUpdateFormValue}
                selectedEquipment={selectedEquipment}
                lockedSelectedEquipment={lockedSelectedEquipment}
                data={sheets || []}
                projectDdv={variant.data.project.ddv}
                isUsageComputed={false} //TODO i think it's need only if not currentIndicator.id
                masterDataEquipment={equipmentClicked.MasterDataEquipment}
                currentIndicator={currentIndicator}
                isRe2020={false} //TODO not use in component
                switchSelectedEquipment={switchSelectedEquipment} //TODOto update? object?
                currentProject={currentProject}
                replaceEquipment={replaceEquipment} //TODO update?
                equipmentsAvailable={equipmentsAvailable} //TODO update? null at starting
                scope={projects.scope}
                allGroups={allGroups.type}
                simulator={true}
                // exploitation={false}
                ctx={ctx}
              />
            </div>
            <div className="modalEquipmentDatasGraph">
              {(lockedSelectedEquipment || replaceEquipment) && (
                <>
                  {/* <div>test</div> */}
                  <SwitchEquipment
                    setLockedSelectedEquipment={setLockedSelectedEquipment}
                    lockedSelectedEquipment={lockedSelectedEquipment}
                    isSimulator={true}
                    handleCloseGraph={handleCloseGraph}
                    setSelectedEquipment={setSelectedEquipment}
                  />
                </>
              )}
              {needDatas &&
                equipmentClicked &&
                !lockedSelectedEquipment &&
                !replaceEquipment && (
                  <EquipmentDatas
                    AppContext={AppContext} // i think is good
                    filteredEquipments={sheets.filter(
                      (sheet) => equipmentClicked.key === sheet.id
                    )} // i think it's ok. as sheetToLifeCycle
                    onEquipmentSelect={onEquipmentSheetEquipmentSelect} // function to set  lockedSelectedEquipment
                    lockedSelectedEquipment={lockedSelectedEquipment}
                    equipment={selectedEquipment} // i have change the type to string
                    currentProject={projects.currentProject} //ok
                    // currentProject={variant}
                    fetchProjectById={handleFetchProjectById} // ok
                    decMds={projects.decMds} // ok
                    setSelectedEquipment={setSelectedEquipment} //fonction to change useState of selectedEquipement
                    // selectedEquipment={null} // optional it can be null. Don't used in lib
                    loadingGraph={true} //TODO false at startup in reducer and true when graph loaded in lifecycle send true
                    loadGraph={false} // is not in reducer
                    updateNewInies={handleUpdateNewInies} // action
                    sheets={sheets} // ok
                    searchValue={equipmentClicked.MasterDataEquipment} //TODO as lifecycle so ok? is a string in reducer but it's an object. So  need to complete this value as autocomplete?
                    currentMd={equipmentClicked.MasterDataEquipment} //ok?
                    updateFormValue={handleUpdateFormValue}
                    parsedZones={equipmentClicked.parsedProjectZones} // ok
                    graphData={needDatas[variantNumber === 'first' ? 0 : 1]} // [0] if variante 1 or [1] if variante 2
                    currentAllotment={currentAllotment}
                    filteredLifeCycle={[]} // to verify ou projects.filteredLifeCycle || []
                    graphView={'bar'} // i think it's ok
                    exploitation={false} // i think it's not necessary vor Simulator
                    currentIndicator={currentIndicator || {}} //i have only id and unit in currentIndicator. Is it ok?
                    // projectSheets={variant.data.project.Zones[0].ProjectSheets.find((sheet) => sheet.SheetId === equipmentClicked.key).Sheet} // only one sheet? or without find?
                    projectSheets={sheets} // only one sheet? or without find?
                    // projectSheets={variant.data.project.Zones[0].ProjectSheets.filter((sheet) => sheet.SheetId === equipmentClicked.key)}
                    exploitationSheets={[]} // i think is not necessary for Simulator
                    saveFiltersBrand={handleSaveFilterBrand} //action
                    lists={projects.lists} // to verify ou projects.lists || []
                    // filters={projects.filters} //TODO
                    filters={
                      // filters: {...filters, MasterDataEquipmentId: equipmentClicked.MasterDataEquipmentId
                      // },
                      filters
                      // equipmentClicked
                    }
                    simulator={true}
                    AntTabs={AntTabs}
                    AntTab={AntTab} //TODO verify why styles not workings
                    NoocoCheckbox={NoocoCheckbox} // i think it s lok
                    Api={apiNooco}
                    licenceTypeAccess={licenceTypeAccess}
                  />
                )}
            </div>
          </>
        )}
      </div>
    );
  };

  const returnVariantColumns = () => {
    const baseColumns = [
      {
        title: t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_FFF715', {
          defaultValue: 'Contributeurs',
        }),
        dataIndex: 'completeName',
        key: 'completeName',
        width: 180,
      },
      {
        title: t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_EFB369', {
          defaultValue: 'Quantité',
        }),
        dataIndex: 'quantity',
        key: 'quantity',
        width: 40,
        // render:() => (<p>test</p>)
      },
      {
        // title: 'Unité',
        title: t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_BC41DD', {
          defaultValue: 'Unité',
        }),
        dataIndex: 'unit',
        key: 'unit',
        width: 30,
      },
      {
        title: () => (
          <div className="SimulatorTableUnity">
            <div>
              <IconButton
                disabled={selectedRowKeys.length === 0}
                style={{
                  opacity: selectedRowKeys.length > 0 ? 1 : 0,
                  marginLeft: '32px',
                }}
                aria-label="delete"
              >
                <Tooltip
                  title={t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_158ED3', {
                    defaultValue: 'Supprimer les éléments selectionnés',
                  })}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <DeleteIcon
                    onClick={() => {
                      updateSimulatorVariant('loadingVariant', true);

                      dispatch(updateSimulator('loadingGraph', true));
                      dispatch(deleteSimulatorEquipment(variantNumber));
                    }}
                  />
                </Tooltip>
              </IconButton>
            </div>
          </div>
        ),
        dataIndex: 'graph',
        key: 'graph',
        width: 40,
        render: (text, record) => (
          <div
            onClick={() => {
              handleOpenGraph(record);
            }}
          >
            <Tooltip
              title={t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_A5C58A', {
                defaultValue: "Accéder au graphe de l'équipement",
              })}
            >
              <img style={{ cursor: 'pointer' }} src={graphIcon} alt="" />
            </Tooltip>
          </div>
        ),
      },
    ];

    return baseColumns;
  };

  return (
    <div className="SimulatorVariantsWrapper">
      <BreadcrumbModal onChangeSimulator={onChangeDropdownAllotmentNode} />
      <Modal
        // title="Modal 1000px width"
        footer={null}
        centered
        visible={openGraph}
        onOk={() => {
          handleCloseGraph();
        }}
        onCancel={() => {
          handleCloseGraph();
        }}
        width={'auto'}
      >
        {renderEquipmentGraph()}
        {/* <div>graph</div> */}
      </Modal>
      <ModalBootstrap
        show={showModalDuplicate}
        onHide={() => setShowModalDuplicate(false)}
      >
        <ModalBootstrap.Header closeButton>
          <ModalBootstrap.Title>
            {t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_B85C6C', {
              defaultValue: 'Confirmer',
            })}
          </ModalBootstrap.Title>
        </ModalBootstrap.Header>
        <ModalBootstrap.Body>
          {t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_6FC661', {
            defaultValue:
              'Etes vous sur de vouloir dupliquer la variante 1 vers la variante 2 ? Les données de la variante 2 seront écrasées',
          })}
        </ModalBootstrap.Body>
        <ModalBootstrap.Footer>
          <Button
            onClick={() => setShowModalDuplicate(false)}
            className="close-button"
            text={t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_C09A83', {
              defaultValue: 'Annuler',
            })}
          />

          <Button
            onClick={() => {
              setShowModalDuplicate(false);
              dispatch(updateSimulatorSecondVariant('loadingVariant', true));
              dispatch(updateSimulator('loadingGraph', true));
              dispatch(simulatorDuplicateVariant());
            }}
            className="purple-button"
            text={t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_C9F3CF', {
              defaultValue: 'Dupliquer',
            })}
          />
        </ModalBootstrap.Footer>
      </ModalBootstrap>

      <div className="simulatorVariants">
        <header className="simulatorVariants__header">
          <div className="simulatorVariants__header--left">
            <div>
              {t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.HEADER', {
                variantNumber: variantNumber === 'first' ? '1' : '2',
                defaultValue: 'Variante {variantNumber}',
              })}
            </div>
            {variantNumber === 'first' && (
              <Tooltip
                title={t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_D1A935', {
                  defaultValue:
                    'Dupliquer les heontributeurs sur la variante 2. Attention : les contributeurs déjà saisis de la variante 2 seront écrasés',
                })}
                classes={{ tooltip: classes.tooltip }}
              >
                <img
                  src={copyVariantIcon}
                  alt=""
                  onClick={() => {
                    setShowModalDuplicate(true);
                    // dispatch(updateSimulatorSecondVariant('loadingVariant',
                    //   true));
                    // dispatch(updateSimulator('loadingGraph',
                    //   true));

                    // dispatch(simulatorDuplicateVariant());
                  }}
                />
              </Tooltip>
            )}
          </div>
        </header>
        <main className="simulatorVariants__tableContainer">
          <Table
            locale={{
              emptyText: t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_619479', {
                defaultValue: 'Pas de donnée',
              }),
            }}
            loading={loadingVariant}
            rowSelection={rowSelection}
            columns={returnVariantColumns()}
            dataSource={variantListDatas}
            pagination={false}
            showSorterTooltip={false}
            size={'small'}
            footer={renderFooter}
            scroll={{ x: undefined, y: 100 }}
          />
        </main>
        <footer className="simulatorVariants__footer">
          <div className="simulatorVariants__footer--total">
            <div>
              {t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_A8D40E', {
                defaultValue: 'Total impact : ',
              })}
            </div>
            <div
              style={{
                fontWeight: '400',
                fontSize: window.screen.availHeight < 900 ? '12px' : '15px',
                marginLeft: '1rem',
              }}
            >
              {!loadingGraph && calculTotalImpact()}
              {loadingGraph && (
                <div className="variantTotalImpact-spinner-wrapper">
                  <div className="donut" />
                </div>
              )}
            </div>
          </div>
          <div className="simulatorVariants__footer--optimisation">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                {t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_39596C', {
                  defaultValue: "Potentiel d'optimisation : ",
                })}
              </div>
              <div
                style={{
                  fontWeight: '400',
                  fontSize: window.screen.availHeight < 900 ? '12px' : '15px',
                  marginLeft: '1rem',
                }}
              >
                {!loadingGraph && calculBestOpti()}
                {loadingGraph && (
                  <div className="variantTotalImpact-spinner-wrapper">
                    <div className="donut" />
                  </div>
                )}
              </div>
            </div>
            <Tooltip
              title={t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_ED11EC', {
                defaultValue:
                  "Potentiel d'optimisation obtenu selon la méthode de calcul statique en sélectionnant les produits du marché les plus vertueux dans chaque famille d'équipement, en respectant les caractéristiques principales choisies",
              })}
              classes={{ tooltip: classes.tooltip }}
            >
              <img src={potentialIcon} alt="" />
            </Tooltip>
          </div>
        </footer>
      </div>

      <div className="SimulatorFooterVariant">
        <Tooltip
          title={t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_427B98', {
            defaultValue: 'Bientôt disponible',
          })}
          classes={{ tooltip: classes.tooltip }}
        >
          <div className="variantsButton">
            <img src={saveSimulatorIcon} alt="" />

            <div className="variantsButton__title">
              {t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_E625D8', {
                defaultValue: 'Exporter la simulation',
              })}
            </div>
          </div>
        </Tooltip>
        <Tooltip
          title={t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_427B98', {
            defaultValue: 'Bientôt disponible',
          })}
          classes={{ tooltip: classes.tooltip }}
        >
          <div className="variantsButton">
            <img src={saveComponantButtonIcon} alt="" />

            <div className="variantsButton__title">
              {t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_C3EFE6', {
                defaultValue: 'Sauvegarder macro-composant',
              })}
            </div>
          </div>
        </Tooltip>
        <Tooltip
          title={t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_B821BB', {
            defaultValue: 'Bientôt disponible',
          })}
          classes={{ tooltip: classes.tooltip }}
        >
          <div className="variantsButton">
            <img src={exportButtonIcon} alt="" />

            <div className="variantsButton__title">
              {t('APP.SIMULATORVARIANTS.SIMULATORVARIANTS.PH_8E8F39', {
                defaultValue: 'Exporter vers un projet',
              })}
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default SimulatorVariants;
