import {
  BodyInPepFdesDedInProject,
  BodyInPostEquipementDTO,
} from '../../hooks/useQuery/equipments';
import API from '../../app/services/API';

const api = new API();

export const createEquipmentCustom = async (data) => {
  const { body, projectId } = data;

  return await api
    .createEquipmentCustom(body, projectId)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export type createEquipmentProps = {
  currentProjectId: number;
  body: BodyInPostEquipementDTO;
};
export const createEquipment = async ({ body, currentProjectId }) => {
  return await api
    .createEquipment(body, currentProjectId)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

export type createPostPepFdesDedProps = {
  ProjectId: number;
  body: BodyInPepFdesDedInProject;
};
export const postPepFdesDed = async ({
  body,
  ProjectId,
}: createPostPepFdesDedProps) => {
  const data = {
    ...body,
    ProjectId: ProjectId,
  };

  return await api
    .postPepFdesDed(data)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export const fetchCaracsByAllotmentNodeId = async (
  allotmentNodeId: number,
  ProjectSheetId?: number,
  fetchSheets: boolean = true
) => {
  return await api
    .getCaracsByAllotmentNodes([allotmentNodeId], ProjectSheetId, fetchSheets)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};
export const patchEquipments = async (
  currentProjectId,
  ProjectSheetsToPatch,
  needToRecomputeProject = true
) => {
  return await api
    .patchEquipments(currentProjectId, {
      ProjectSheetsToPatch,
      ProjectSheetsToDelete: [],
      needToRecomputeProject,
    })
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};
