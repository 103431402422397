import { LIBELLE_KG_EQ_CO2, getUnitConversion } from '../mathematics/formatNumber';
import { PROJECTS_TYPE_NAMES_TOTAL_IMPACT } from '../../globalVariable/typeProject';
import { CurrentIndicatorDTO } from '../../models/ProjectsDTO';
import { ProjectDTO } from '../../models/ProjectDTO';

type LegendLabelProps = {
  currentIndicator: CurrentIndicatorDTO;
  currentProject: ProjectDTO;
  onTab?: boolean;
  unitOnly?: boolean;
  shouldDisplayShortName?: boolean;
};

export function getLegendLabel({
  currentIndicator,
  currentProject,
  onTab = false,
  unitOnly = false,
  shouldDisplayShortName = true,
}: LegendLabelProps) {
  let acvValue = PROJECTS_TYPE_NAMES_TOTAL_IMPACT.includes(
    currentProject.ProjectType.name
  )
    ? currentProject.Ic[1].IcProjet
    : currentProject.dynamicAcv;

  if (currentProject.refUnit === 'm²') {
    acvValue = acvValue / currentProject.area;
  }

  const partialUnitLabel =
    currentIndicator.unit === LIBELLE_KG_EQ_CO2
      ? getUnitConversion(acvValue)
      : currentIndicator.unit;

  const unitLabel = `${partialUnitLabel}${
    !onTab && currentProject.refUnit === 'm²' ? '/m²' : ''
  }`;

  if (unitOnly) {
    return unitLabel;
  }

  return `${
    shouldDisplayShortName ? currentIndicator.shortName : ''
  } (${unitLabel})`;
}
