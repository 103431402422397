/* eslint-disable no-unused-vars */
import { Dispatch } from 'redux';
import { MapDispatchToPropsParam, MapStateToPropsParam, connect } from 'react-redux';

import ProductsMenu from '../../app/products/ProductsMenu';

import { AllotmentNodeDTO } from '../../models/ProjectDTO';
import { fetchProducts, updateProductsValue } from '../../actions/products';

interface IProducts {
  productView: string;
  menuDatas: AllotmentNodeDTO[];
  requests: string[];
  listProducts: [];
  nodeId: string;
  searchRequest: boolean;
}

interface IProjects {
  allGroups: {
    allotments: [];
  };
}

type State = {
  products: IProducts;
  projects: IProjects;
};

const mapStateToProps: MapStateToPropsParam<{}, {}, State> = (state: State) => ({
  allotments: state.projects.allGroups.allotments,
  menuDatas: state.products.menuDatas,
  requests: state.products.requests,
  nodeId: state.products.nodeId,
  searchRequest: state.products.searchRequest,
});

const mapDispatchToProps: MapDispatchToPropsParam<{}, {}> = (
  dispatch: Dispatch
) => ({
  updateProductsValue: (name: string, value: string) => {
    dispatch(updateProductsValue(name, value));
  },
  fetchProducts: (concat: boolean) => {
    dispatch(fetchProducts(concat));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsMenu);
