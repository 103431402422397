import React from 'react';
import WidgetsList from './WidgetsList';
import WidgetsDropZone from './WidgetsDropZone';
import './pdfBuilder.scss';
import PdfPreview from './PdfPreview';

interface PdfBuilderProps {}

const PdfBuilder = ({}: PdfBuilderProps) => {
  return (
    <div className="pdf-builder-wrapper">
      <WidgetsList />
      <WidgetsDropZone />
      <PdfPreview />
    </div>
  );
};

export default PdfBuilder;
