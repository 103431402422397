import { CopyFilled } from '@ant-design/icons';
import { ResponsivePie } from '@nivo/pie';
import React, { useEffect, useState } from 'react';
import formatNumber from '../../services/mathematics/formatNumber';

const getZoomPercentage = () => {
  const basePixelRatio = 1;
  return Math.round((window.devicePixelRatio / basePixelRatio) * 100);
};

const getMarginByZoomPercentage = (zoomPercentage) => {
  if (zoomPercentage >= 80) {
    return { top: 0, right: 0, bottom: 0, left: 0 };
  } else if (zoomPercentage === 75) {
    return { top: 0, right: 0, bottom: 20, left: 0 };
  } else if (zoomPercentage === 67) {
    return { top: 0, right: 0, bottom: 55, left: 0 };
  } else {
    return { top: 40, right: 80, bottom: 80, left: 80 };
  }
};

const MyResponsivePie = ({
  data,
  colors,
  margin,
  legends,
  currentIndicator,
  isPercentage = false,
}) => {
  const [total, setTotal] = useState(0);
  const [zoomPercentage, setZoomPercentage] = useState(getZoomPercentage());

  useEffect(() => {
    let tempTotal = 0;

    data.map((bar) => {
      tempTotal += Number(bar.value);
    });

    setTotal(tempTotal);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setZoomPercentage(getZoomPercentage());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // console.log('data', data);

  return (
    <ResponsivePie
      data={data}
      isAnimationActive={false}
      valueFormat={(value) => {
        const percentage = ((value / total) * 100).toFixed(2);

        return `${formatNumber(value)} ${
          isPercentage ? ' %' : `${currentIndicator.unit} | ${percentage}%`
        }`;
      }}
      isInteractive={true}
      margin={getMarginByZoomPercentage(zoomPercentage)}
      // margin={{ top: 0, right: 0, bottom: 0, left: 0}}
      colors={colors}
      enableRadialLabels={false}
      radialLabel={(d) => {
        return (
          <React.Fragment style={{ color: 'black' }}>
            <tspan>{d.id}</tspan>
          </React.Fragment>
        );
      }}
      innerRadius={0.6}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      padAngle={0.5}
      cornerRadius={5}
      activeInnerRadiusOffset={18}
      activeOuterRadiusOffset={8}
      borderWidth={0}
      borderColor={{ from: 'color', modifiers: [['darker', 0.1]] }}
      arcLinkLabelsSkipAngle={40}
      arcLinkLabelsThickness={2}
      arcLabelsSkipAngle={10}
      legends={legends}
      enableSliceLabels={false}
      // sliceLabelsTextColor='white'
    />
  );
};

export default MyResponsivePie;
