import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  PROJECT_SIMULATOR_TABS,
  PROJECT_SIMULATOR_TOTAL_TABS,
  projectSimulatorNavigations,
} from './utils';

function NavigationTabs() {
  const { t } = useTranslation();
  const [tab, setTab] = useState(PROJECT_SIMULATOR_TABS.QUICK_SIMULATION);
  return (
    <ul className="project-simulator-navbar">
      {projectSimulatorNavigations.map((phase) => (
        <>
          <li
            key={phase.tab}
            className="project-simulator-navbar--item"
            style={{
              backgroundColor: tab === phase.tab ? 'white' : '#f0f2f9',
              cursor: phase.tab > 1 ? 'not-allowed' : 'pointer',
            }}
            onClick={() => phase.tab < 2 && setTab(phase.tab)}
          >
            <span
              className="project-simulator-navbar-item--tab--number"
              style={{
                color: tab === phase.tab ? 'white' : '#8D8D99',
                backgroundColor: tab === phase.tab ? '#8B5AFF' : '#E1E1E6',
              }}
            >
              {phase.tab}
            </span>{' '}
            <span
              className="project-simulator-navbar-item--tab--text"
              style={{
                color: tab === phase.tab ? 'black' : '#8D8D99',
              }}
            >
              {t(phase.translationKey, {
                defaultValue: phase.title,
              })}
            </span>
          </li>

          {phase.tab < PROJECT_SIMULATOR_TOTAL_TABS && (
            <div
              style={{
                alignSelf: 'center',
              }}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_39_528)">
                  <path
                    d="M7.91675 5L12.9167 10L7.91675 15"
                    stroke="#323238"
                    stroke-width="2"
                    stroke-linecap="square"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_39_528">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          )}
        </>
      ))}
    </ul>
  );
}

export default NavigationTabs;
