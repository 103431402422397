/* eslint-disable react/no-unescaped-entities */
import './importModal.scss';
import { Form, Modal } from 'react-bootstrap';
import { Button } from '@photocarbone/nooco-ui';
import { Tooltip } from '@material-ui/core';
import { animate } from 'framer-motion';
import { getAllZones } from '../../../shared/utils';
import AppContext from '../../../AppContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import InfoIcon from '@mui/icons-material/Info';
import Loader from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import NoocoCheckbox from '../../../shared/NoocoCheckbox';
import React, { useContext, useEffect, useState } from 'react';
import S3DropZone from '../../Settings/S3DropZone';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import askinfo from '../../../../assets/images/dashboard/askinfos.png';
import restrictions from '../../../../assets/images/dashboard/restrictions.png';

import {
  TYPE_MAPPINGREF,
  TYPE_NOOCO,
  TYPE_DATACENTER,
  TYPE_AIRPORT,
  TYPE_PROTOTYPE,
  TYPE_RENOVATION,
  TYPE_RE2020,
  TYPE_EPCM,
  TYPE_GLOBAL_MONITORING,
} from '../../../globalVariable/typeProject';

import { useTranslation } from 'react-i18next';
import { ZONE_TYPES } from '../../../globalVariable/generalDefinitions';
import { useLocalStorage } from 'react-use';
import { useSelector } from 'react-redux';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ImportModal = ({
  setSnackMessage,
  importModal,
  openImportModal,
  currentProject,
  updateFormValue,
  fetchImport,
  importLoading,
  isSuccess,
  setTemplateError,
  setImportError,
  zoneEquipmentId,
  equipmentBuildingSiteId,
  zones,
  isReadyToImport,
  importTabs,
  importDate,
  workflowImportModal,
  mappingRef = false,
}) => {
  const [zonesName, setZonesName] = useState([]);
  const [key, setKey] = useState(false);
  const [lotsOptions, setLotsOptions] = useState();
  const [allChecked, setAllChecked] = useState(true);

  const [isImportToContributorList] = useLocalStorage(
    `isImportToContributorList-${currentProject.id}`,
    false
  );
  const { t } = useTranslation();

  const [optionsModal, setOptionsModal] = useState([
    {
      name: 'mappingRef',
      checked: true,
      sentence: t('APP.IMPORTMODAL.INDEX.PH_96F5ED', {
        defaultValue: 'Lignes reconnues depuis votre mapping de référence',
      }),
      tooltip: t('APP.IMPORTMODAL.INDEX.PH_C427E1', {
        defaultValue:
          'Le mapping de référence permet d’établir des correspondances entre vos références et les appellations Nooco. En cochant la case, les lignes avec des correspondances validées dans votre mapping de référence seront prévalidées dans l’import.',
      }),
    },
    {
      name: 'idInies',
      checked: true,
      sentence: t('APP.IMPORTMODAL.INDEX.PH_E4E14F', {
        defaultValue: 'Lignes pour lesquelles un ID Inies est reconnu',
      }),
      tooltip: t('APP.IMPORTMODAL.INDEX.PH_9C75C2', {
        defaultValue:
          'Votre fichier peut contenir une colonne ID Inies, en plus des colonnes obligatoires Description, Quantité, Unité, avec les éventuels ID Inies des fiches de votre projet. En cochant la case, les lignes avec une fiche Inies seront prévalidées dans l’import.',
      }),
    },
    {
      name: 'IA',
      checked: false,
      sentence: t('APP.IMPORTMODAL.INDEX.PH_8BC525', {
        defaultValue:
          'Lignes pour lesquelles l’Intelligence Artificielle a une fiabilité évaluée à plus de 95%',
      }),
      tooltip: t('APP.IMPORTMODAL.INDEX.PH_557FFF', {
        defaultValue:
          'La performance de l’intelligence artificielle est évaluée mensuellement (à date de livraison de nos versions). Pour qu’une suggestion dispose d’une fiabilité > 95%, elle doit vérifier les conditions suivantes : Cette suggestion a été proposée plus de 30 fois sur des projets. Cette suggestion à été validée dans 95% des cas (hors validation automatisée) depuis la dernière version de l’IA',
      }),
    },
  ]);

  const ctx = useContext(AppContext);
  const allLots = useSelector((state) => state.projects.allGroups.flatAllotments);
  const filteredLots = allLots.filter((lot) =>
    currentProject.Lots.some((currentLot) => currentLot.id === lot.id)
  );
  const LOT_ENERGIE_ID = 79;

  useEffect(() => {
    let lots = [
      {
        id: 1200,
        label: t('APP.IMPORTMODAL.INDEX.ALL_LOTS', {
          defaultValue: 'Tous lots',
        }),
        color: '#ccc',
        name: t('APP.IMPORTMODAL.INDEX.ALL_LOTS', {
          defaultValue: 'Tous lots',
        }),
      },
      ...filteredLots,
    ];

    if (currentProject.isRset) {
      lots = lots.filter((lot) => lot.id !== LOT_ENERGIE_ID);
    }

    const templateTypeForTransportAndGears = [
      TYPE_NOOCO,
      TYPE_DATACENTER,
      TYPE_AIRPORT,
      TYPE_PROTOTYPE,
      TYPE_RENOVATION,
      TYPE_RE2020,
      TYPE_EPCM,
    ];

    // TODO_TRAD : fr
    if (templateTypeForTransportAndGears.includes(currentProject.templateType)) {
      lots = lots.filter((lot) => lot.name !== 'Transports et engins');
    }

    function compare(a, b) {
      if (a.name === 'Tous lots') {
        return -1;
      }
      if (b.name === 'Tous lots') {
        return 1;
      }

      return a.name.localeCompare(b.name);
    }

    lots.sort(compare);

    setLotsOptions(lots);
  }, [currentProject]);

  useEffect(() => {
    if (importModal) {
      const zone =
        allNames.length === 0
          ? null
          : allNames.find(
              (el) =>
                el.type === 'zone' &&
                el.name === 'Zone 1' &&
                el.buildingName === 'Bâtiment 1'
            )
          ? allNames.find(
              (el) =>
                el.type === 'zone' &&
                el.name === 'Zone 1' &&
                el.buildingName === 'Bâtiment 1'
            ).id
          : allNames.find((el) => el.type === 'zone').id;

      updateFormValue('zoneEquipmentId', zone);
    }

    return () => {
      updateFormValue('equipmentBuildingSiteId', false);
    };
  }, [importModal]);

  useEffect(() => {
    if (isReadyToImport) {
      let datas = [];

      importTabs.rows.forEach((el) => {
        if (el.check) {
          let data = {
            name: el.name,
            zoneId: el.areaProRata ? null : el.zoneId,
            lots: el.lots.map((el) => el.id).filter((el) => el !== 1200),
            options: el.options,
            customName: el.customName,
            areaProRata: el.areaProRata,
          };

          if (el.isBuildingSite && !el.areaProRata) {
            let zoneId = zones
              .find((el) => el.id === data.zoneId)
              .Zones.find((el) => el.type === 'buildingSite').id;

            data.zoneId = zoneId;
          }

          datas = [...datas, data];
        }
      });

      ctx.api
        .importFileToCSV(
          currentProject.id,
          `${importTabs.identityId}/${importTabs.uuid}`,
          importTabs.file,
          datas,
          importTabs.fileName,
          isImportToContributorList
        )
        .then((response) => {
          updateFormValue('creationImportDatas', response.data);
        })
        .catch((error) => {
          console.log(error);
          setTemplateError(true);
          updateFormValue('importLoading', false);
          updateFormValue('isReadyToImport', false);
          updateFormValue('workflowImportModal', false);

          // setSnackMessage(error);

          return;
        });

      updateFormValue('isReadyToImport', false);
    }
  }, [isReadyToImport]);

  const allNames =
    currentProject.Zones.length > 0 &&
    currentProject.templateType !== TYPE_MAPPINGREF
      ? getAllZones(currentProject).sort(function (a, b) {
          if (a.id < b.id) {
            return -1;
          }

          if (a.id > b.id) {
            return 1;
          }

          return 0;
        })
      : [];

  useEffect(() => {
    let all = [];

    if (zones) {
      zones
        .filter((el) => el.type !== 'building')
        .forEach((zone) => {
          let object = { ...zone };

          let bool = zone.ParentId;

          let i = 0;

          while (bool) {
            if (bool) {
              object.name = `${zones.find((el) => el.id === bool).name} ${
                zones.find((el) => el.id === bool).type === 'building' ? '- ' : ''
              }${
                object.type === 'buildingSite' && i === 0
                  ? `(${object.name})`
                  : object.name
              }`;

              if (
                zones.find((el) => el.id === bool).ParentId &&
                zones.find(
                  (el) => el.id === zones.find((el) => el.id === bool).ParentId
                ).type !== 'plot'
              ) {
                bool = zones.find((el) => el.id === bool).ParentId;
              } else {
                bool = false;
              }

              i++;
            }
          }

          all = [...all, object];
        });
    }

    setZonesName(all);
  }, []);

  useEffect(() => {
    const modal = document.querySelector('.modal-content');

    const inputs = document.querySelectorAll('.MuiAutocomplete-inputRoot');

    if (importTabs) {
      modal.style.width = '140%';

      inputs.forEach((el) => {
        el.style.margin = '0';
        // el.style.width = '400px';
      });
    }
  }, [importTabs]);

  const handleAllCheckbox = () => {
    let importTabsCopy = _.cloneDeep(importTabs);

    for (let i = 0; i < importTabsCopy.rows.length; i++) {
      importTabsCopy.rows[i].check = allChecked ? false : true;
    }

    updateFormValue('importTabs', importTabsCopy);

    console.log(importTabsCopy);
  };

  return (
    <div>
      <Modal show={importModal} onHide={openImportModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {workflowImportModal
              ? t('APP.IMPORTMODAL.INDEX.PH_1A675F', {
                  defaultValue: 'Validation automatique',
                })
              : t(
                  'APP.IMPORTMODAL.INDEX.PH_AC95D0',

                  {
                    isMapping: currentProject.type !== 'mappingRef',
                    defaultValue:
                      'Import en masse des {isMapping, select, true {équipements / matériaux} other {références}}',
                  }
                )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!importTabs && (
            <>
              <div className="import-files">
                <div className="import-file-inside">
                  <div className="import-file-text">
                    <div className="text-file">
                      <div className="icon">
                        <img src={restrictions} alt="" />
                      </div>
                      <div className="text">
                        <p>
                          {t(
                            'APP.IMPORTMODAL.DATA_NEEDED_FOR_IMPORT',

                            {
                              isProjectTypeWithSousLot:
                                currentProject.templateType === TYPE_EPCM ||
                                currentProject.templateType === TYPE_RE2020,
                              isGlobalMonitoringProject:
                                currentProject.templateType ===
                                TYPE_GLOBAL_MONITORING,
                              defaultValue:
                                'Votre fichier doit contenir un ou plusieurs onglets avec les colonnes : Description, Quantité, Unité. Facultatif: ID Inies, Commentaires{isGlobalMonitoringProject, select, true {, Date (au format “JJ/MM/AAAA” ou “JJ/MM/AAAA-JJ/MM/AAAA”)} other {}}{isProjectTypeWithSousLot, select, true {, Sous-lot} other {}}',
                            }
                          )}
                        </p>
                        <p style={{ marginTop: '1em' }}>
                          {t('APP.IMPORTMODAL.INDEX.PH_D90366', {
                            defaultValue: 'Formats acceptés : .xlsx ou .xls',
                          })}
                        </p>
                      </div>
                    </div>
                    <div className="text-file">
                      <div className="icon">
                        <img src={askinfo} alt="" />
                      </div>
                      <div className="text">
                        <p>
                          {t('APP.IMPORTMODAL.INDEX.PH_DF1D97', {
                            defaultValue:
                              'Les exports Quick Devis, Optima, et Revit respectent déjà ces prérequis !',
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="dropzone">
                    {!importLoading && (
                      <S3DropZone
                        setSnackMessage={setSnackMessage}
                        project={currentProject}
                        fetchImport={fetchImport}
                        setImportError={setImportError}
                        updateFormValue={updateFormValue}
                        setTemplateError={setTemplateError}
                        zoneEquipmentId={zoneEquipmentId}
                        equipmentBuildingSiteId={equipmentBuildingSiteId}
                        zones={zones}
                      ></S3DropZone>
                    )}

                    {importLoading && (
                      <Loader
                        variant="indeterminate"
                        disableShrink
                        style={{
                          color: '#73b7ec',
                          marginLeft: '2em',
                          marginTop: '1em',
                        }}
                        size={40}
                        thickness={4}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {importTabs && !workflowImportModal && (
            <>
              <div style={{ fontWeight: '500' }}>
                {t(
                  'APP.IMPORTMODAL.INDEX.PH_73BCA5',

                  {
                    check: allNames.length > 1,
                    defaultValue:
                      'Sélectionner les onglets à valider {check, select, true {et leurs destinations} other {}}',
                  }
                )}
              </div>
              <div
                onClick={() => {
                  setAllChecked(!allChecked);
                  handleAllCheckbox();
                }}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <NoocoCheckbox checked={allChecked} />
                {t(
                  'APP.IMPORTMODAL.INDEX.PH_991C5B',

                  {
                    allChecked,
                    defaultValue:
                      '{allChecked, select, true {Décocher} other {Cocher}} tous les onglets ',
                  }
                )}
              </div>
              <div style={{ margin: '1em 0' }}>
                {importTabs?.rows
                  ?.sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }

                    if (a.name > b.name) {
                      return 1;
                    }

                    return 0;
                  })
                  .map((el) => (
                    <div
                      key={el}
                      style={{
                        margin: '1em 0',
                        marginLeft: '2em',
                        display: 'flex',
                      }}
                    >
                      <div>
                        <div className="tab-name">
                          <NoocoCheckbox
                            className={`check-${el.name.replace(/\s/g, '')}`}
                            checked={el.check}
                            style={{ padding: '4px', paddingLeft: '0' }}
                            onChange={(e) => {
                              let importTabsCopy = _.cloneDeep(importTabs);

                              let newList = importTabsCopy.rows.filter(
                                (ele) => ele.name !== el.name
                              );
                              let current = importTabsCopy.rows.find(
                                (ele) => ele.name === el.name
                              );

                              current.check = e.target.checked;

                              importTabsCopy.rows = [...newList, current];

                              updateFormValue('importTabs', importTabsCopy);
                            }}
                          />
                          <p
                            onClick={() => {
                              const checkbox = document.querySelector(
                                `.check-${el.name.replace(/\s/g, '')} input`
                              );

                              checkbox.click();
                            }}
                            style={{
                              margin: 0,
                              cursor: 'pointer',
                              display: 'inline-block',
                            }}
                          >
                            {' '}
                            {el.name}
                          </p>
                          <div className="prorata-surface">
                            <NoocoCheckbox
                              style={{ padding: '4px', paddingLeft: '0' }}
                              onChange={(e) => {
                                let importTabsCopy = _.cloneDeep(importTabs);

                                let newList = importTabsCopy.rows.map((row) =>
                                  row.name === el.name
                                    ? { ...row, areaProRata: e.target.checked }
                                    : row
                                );

                                importTabsCopy.rows = newList;

                                updateFormValue('importTabs', importTabsCopy);
                              }}
                            />
                            <p>
                              {t(
                                'APP.IMPORTMODAL.SURFACES_PRORATA_FOR_ZONES_IN_IMPORT',
                                {
                                  defaultValue:
                                    'Répartir les quantités au prorata des surfaces de zones',
                                }
                              )}
                            </p>
                            <Tooltip
                              title={t('APP.IMPORT_MODAL.TOOLTIP_PRORATA', {
                                defaultValue:
                                  'En cochant cette case, toutes les quantités des équipements/matériaux seront automatiquement affectées à tous les bâtiments/zones au prorata de leurs surfaces respectives.',
                              })}
                            >
                              <InfoIcon style={{ color: 'grey' }} />
                            </Tooltip>
                          </div>
                        </div>
                        <div className="import-tabs">
                          {zonesName.length >= 2 &&
                            currentProject.templateType !== TYPE_MAPPINGREF && (
                              <Tooltip
                                title={
                                  el.areaProRata
                                    ? t(
                                        'APP.IMPORT_MODAL.TOOLTIP_DISABLED_DESTINATION_INPUT',
                                        {
                                          defaultValue:
                                            'Pas de choix de destination requis car les quantités sont automatiquement réparties au prorata des surfaces de zones',
                                        }
                                      )
                                    : ''
                                }
                              >
                                <FormControl
                                  variant="outlined"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <Form.Label>
                                    {t('APP.IMPORTMODAL.INDEX.PH_153B8C', {
                                      defaultValue: 'Destination',
                                    })}
                                  </Form.Label>
                                  <Select
                                    required
                                    className="textfield-select"
                                    custom
                                    disabled={el.areaProRata}
                                    onChange={(e) => {
                                      let importTabsCopy = { ...importTabs };

                                      let newList = importTabsCopy.rows.filter(
                                        (ele) => ele.name !== el.name
                                      );
                                      let current = importTabsCopy.rows.find(
                                        (ele) => ele.name === el.name
                                      );

                                      current.zoneId = e.target.value;

                                      importTabs.rows = [...newList, current];

                                      updateFormValue('importTabs', importTabs);
                                    }}
                                    defaultValue={el.zoneId}
                                    style={{ width: '215px' }}
                                  >
                                    {(currentProject.ProjectTypeId !== 8
                                      ? zonesName.filter(
                                          (elem) =>
                                            elem.type !== ZONE_TYPES.buildingSite
                                        )
                                      : zonesName
                                    )
                                      .sort(function (a, b) {
                                        if (a.id < b.id) {
                                          return -1;
                                        }
                                        if (a.id > b.id) {
                                          return 1;
                                        }
                                        return 0;
                                      })
                                      .map((elem) => (
                                        <MenuItem key={elem.id} value={elem.id}>
                                          {elem.name}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              </Tooltip>
                            )}

                          <Autocomplete
                            multiple
                            style={{ marginLeft: '1em' }}
                            id="checkboxes-tags-demo"
                            options={lotsOptions || []}
                            value={el.lots}
                            noOptionsText={t('APP.IMPORTMODAL.INDEX.PH_0FE057', {
                              defaultValue: 'Aucun résultat trouvé',
                            })}
                            disableCloseOnSelect
                            onChange={(event, newValue) => {
                              setKey(!key);

                              let importTabsCopy = _.cloneDeep(importTabs);

                              let allIds = null;

                              if (
                                newValue.find((el) => el.id === 1200) &&
                                el.lots.find((el) => el.id === 1200) &&
                                newValue.length === 1
                              ) {
                                allIds = [];
                              } else if (
                                el.lots.find((el) => el.id === 1200) &&
                                newValue.length > 1
                              ) {
                                allIds = newValue.filter((el) => el.id !== 1200);
                              } else if (
                                (!el.lots.find((el) => el.id === 1200) &&
                                  newValue.find((el) => el.id === 1200) &&
                                  newValue.length > 1) ||
                                newValue.length === 0
                              ) {
                                allIds = [
                                  {
                                    id: 1200,
                                    label: 'Tous lots',
                                    color: '#ccc',
                                    name: 'Tous lots',
                                  },
                                ];
                              }

                              let newList = importTabsCopy.rows.filter(
                                (ele) => ele.name !== el.name
                              );
                              let current = importTabsCopy.rows.find(
                                (ele) => ele.name === el.name
                              );

                              current.lots = allIds || newValue;

                              importTabs.rows = [...newList, current];

                              updateFormValue('importTabs', importTabs);
                            }}
                            size={'small'}
                            getOptionLabel={(option) => option.name}
                            renderTags={(value, getTagProps) => {
                              return value.map((option, index) => (
                                <Chip
                                  style={{
                                    background: `${option.color}`,
                                    fontWeight: '600',
                                    fontSize:
                                      window.screen.availHeight < 950
                                        ? '0.75rem'
                                        : '11px',
                                    color: 'white',
                                    borderRadius: '0.25rem',
                                    border: 'none',
                                    height: '27px',
                                  }}
                                  key={option.name}
                                  variant="outlined"
                                  label={option.name}
                                  {...getTagProps({ index })}
                                />
                              ));
                            }}
                            renderOption={(option, { selected }) => {
                              if (el.lots.find((el) => el.id === 1200)) {
                                selected = true;
                              }

                              return (
                                <React.Fragment>
                                  <NoocoCheckbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.name}
                                </React.Fragment>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Lots"
                                variant="outlined"
                                className="textfield-bar"
                              />
                            )}
                          />
                        </div>
                      </div>

                      {currentProject.templateType === 'mappingRef' &&
                        importTabs && (
                          <div
                            className="mappingRef-input"
                            style={{ paddingTop: '4px', marginLeft: '1em' }}
                          >
                            <p style={{ marginBottom: '0.50em' }}>
                              Renommer le fichier (facultatif)
                            </p>
                            <TextField
                              onChange={(e) => {
                                let importTabsCopy = _.cloneDeep(importTabs);

                                let newList = importTabsCopy.rows.filter(
                                  (ele) => ele.name !== el.name
                                );
                                let current = importTabsCopy.rows.find(
                                  (ele) => ele.name === el.name
                                );

                                current.customName = e.target.value;

                                importTabsCopy.rows = [...newList, current];

                                updateFormValue('importTabs', importTabsCopy);
                              }}
                              disabled={!el.check}
                              className="input-ref"
                              defaultValue={el.name}
                              variant="outlined"
                              style={{
                                height: '45px',
                                border: '1px solid #dee2e6',
                              }}
                            />
                          </div>
                        )}
                    </div>
                  ))}
              </div>
            </>
          )}

          {workflowImportModal && importTabs && (
            <div>
              <p>
                {t('APP.IMPORTMODAL.INDEX.PH_097370', {
                  defaultValue:
                    'Valider automatiquement les contributeurs suivants :',
                })}
              </p>
              <>
                {importTabs.rows[0].options?.map((el, i) => (
                  <Tooltip
                    key={i + 'el.name'}
                    title={
                      i < 2
                        ? ''
                        : t('APP.IMPORTMODAL.INDEX.PH_3A6187', {
                            defaultValue: 'Bientôt disponible',
                          })
                    }
                  >
                    <div className="import-option" key={(el, i)}>
                      <NoocoCheckbox
                        disabled={el.name === 'IA' ? true : false}
                        checked={el.checked}
                        onChange={() => {
                          let importTabsCopy = { ...importTabs };

                          importTabsCopy.rows.forEach((row) => {
                            row.options[i].checked = !row.options[i].checked;
                          });

                          updateFormValue('importTabs', importTabsCopy);
                        }}
                      />
                      <p style={{ color: i < 2 ? null : '#ccc' }}>{el.sentence}</p>
                      <Tooltip
                        title={
                          i < 2 ? (
                            el.tooltip
                          ) : (
                            <div>
                              <span style={{ display: 'block' }}>
                                {t('APP.IMPORTMODAL.INDEX.PH_C8B459', {
                                  defaultValue:
                                    "La performance de l’intelligence artificielle est évaluée mensuellement (à date de livraison de nos versions). Pour qu’une suggestion dispose d’une fiabilité {'>'} 95%, elle doit vérifier les conditions suivantes :",
                                })}
                              </span>
                              <ul>
                                <li>
                                  {t('APP.IMPORTMODAL.INDEX.PH_61070F', {
                                    defaultValue:
                                      'Cette suggestion a été proposée plus de 30 fois sur des projets',
                                  })}
                                </li>
                                <li>
                                  {t('APP.IMPORTMODAL.INDEX.PH_FF272A', {
                                    defaultValue:
                                      'Cette suggestion à été validée dans 95% des cas (hors validation automatisée) depuis la dernière version de l’IA',
                                  })}
                                </li>
                              </ul>
                            </div>
                          )
                        }
                        placement="right"
                      >
                        <InfoIcon />
                      </Tooltip>
                    </div>
                  </Tooltip>
                ))}
              </>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="close-button"
            onClick={() => {
              updateFormValue('importTabs', null);
              openImportModal();
            }}
            text={t('APP.IMPORTMODAL.INDEX.PH_812F7E', {
              defaultValue: 'Fermer',
            })}
          />

          {importTabs && !importLoading && (
            <Button
              className="purple-button"
              onClick={() => {
                if (!workflowImportModal && !mappingRef) {
                  updateFormValue('workflowImportModal', true);
                } else {
                  updateFormValue('importLoading', true);
                  updateFormValue('isReadyToImport', true);
                }
              }}
              text={t('APP.IMPORTMODAL.INDEX.PH_EDA96E', {
                defaultValue: 'Confirmer',
              })}
            />
          )}

          {importLoading && (
            <Loader
              variant="indeterminate"
              disableShrink
              style={{ color: '#73b7ec', marginLeft: '2em', marginTop: '1em' }}
              size={40}
              thickness={4}
            />
          )}
          {/* 
          <Button variant="primary" onClick={handleSubmit}>
              Envoyer la demande
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ImportModal;
