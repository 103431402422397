import {
  FETCH_PRODUCTS,
  INIT_DATAS_PRODUCTS,
  updateProductsValue,
} from '../actions/products';
import { formatViewCard, putCountOnNode } from '../app/shared/utils';
import API from '../app/services/API';
import _ from 'lodash';

const api = new API();
let apiAbort = false;

const productsMiddleware = (store) => (next) => async (action) => {
  const { projects, products } = store.getState();

  switch (action.type) {
    case FETCH_PRODUCTS:
      api.getCountByNodeId(products.nodeId).then((res) => {
        const menuDatas = _.cloneDeep(products.menuDatas);

        // console.log(res.data, menuDatas);
        // console.log(res.data.length);

        res.data.forEach((count) => {
          // console.log(count);

          // console.log(MenuDatas.length);

          for (let i = 0; i < menuDatas.length; i++) {
            // console.log(menuDatas[i]);
            // console.log('appel de la function putCountOnNode', '   ---i =', i, 'count.id=', count.id );
            putCountOnNode(menuDatas[i], count);
          }
        });

        // console.log(res, menuDatas, products.menuDatas);

        // console.log('appel update menu DATA', menuDatas);

        store.dispatch(updateProductsValue('menuDatas', menuDatas));
      });
      api
        .fetchProductsByNodeId(
          products.nodeId,
          products.inputSearchBar,
          products.brandsSelected,
          products.offsetList
        )
        .then((response) => {
          console.log(products.menuDatas);
          console.log('action', action);
          store.dispatch(
            updateProductsValue(
              'listProducts',
              action.concat && products.viewMode === 'card'
                ? [
                    ...products.listProducts,
                    ...formatViewCard(response.data.sheets, 'card'),
                  ]
                : action.concat
                ? [...formatViewCard(products.listProducts), ...response.data.sheets]
                : products.viewMode === 'card'
                ? formatViewCard(response.data.sheets, 'card')
                : response.data.sheets
            )
          );

          store.dispatch(
            updateProductsValue('totalSheetCount', response.data.totalSheetCount)
          );

          let brandFiltered = products.brandsList.filter((el) =>
            response.data.brands.includes(el.name)
          );

          store.dispatch(updateProductsValue('brandsFilter', brandFiltered));

          const copyData = [...products.requests];

          copyData.pop();

          store.dispatch(updateProductsValue('requests', copyData));
        })
        .catch((error) => {
          console.log(error);
        });

      next(action);
      break;

    case INIT_DATAS_PRODUCTS: {
      if (apiAbort) return;

      const response1 = api.fetchProductsByNodeId(null, null, null, 18);
      const response2 = api.fetchManufacturersSheet();

      apiAbort = true;

      Promise.all([response2, response1]).then((values) => {
        console.log('VALUES', values);

        apiAbort = false;

        store.dispatch(updateProductsValue('brandsList', values[0].data));
        store.dispatch(
          updateProductsValue(
            'listProducts',
            formatViewCard(values[1].data.sheets, 'card')
          )
        );
        store.dispatch(
          updateProductsValue('totalSheetCount', values[1].data.totalSheetCount)
        );
        store.dispatch(updateProductsValue('requests', []));
      });

      next(action);
      break;
    }

    default:
      next(action);
  }
};

export default productsMiddleware;
