import { Tooltip } from '@material-ui/core';
import { sendImport } from '../../../../actions/import';
import { updateFormValue } from '../../../../actions/projects';
import { useDispatch } from 'react-redux';
import React from 'react';
import goodPic from '../../../../assets/images/import/Eo_circle_green_checkmark.svg';

const LineImportButton = ({
  importRow,
  loadingProcess,
  groups,
  setSnackbarMessage,
  setLoadingProcess,
}) => {
  const dispatch = useDispatch();

  return (
    <Tooltip
      title={
        !importRow.isArchived ? (
          <span style={{ display: 'block' }}>
            Ligne validée. <br />
            Cliquer ici pour le charger
          </span>
        ) : (
          ''
        )
      }
    >
      <div
        className="line-import"
        style={{
          cursor: loadingProcess
            ? 'not-allowed'
            : importRow.isValid
            ? 'pointer'
            : undefined,
        }}
        onClick={() => {
          if (
            !groups.type?.find(
              (el) => el.id === importRow.AllotmentNode.MasterDataEquipmentId
            )
          ) {
            setSnackbarMessage('Veuillez rajouter le lot dans votre projet');
            dispatch(updateFormValue('isLoad', false));
          } else {
            if (!loadingProcess && !importRow.isArchived) {
              setLoadingProcess(true);

              dispatch(sendImport(importRow.id));
            }
          }
        }}
      >
        <img src={goodPic} alt="" />
      </div>
    </Tooltip>
  );
};

export default LineImportButton;
