import { StateDTO } from '../../../app/models/GeneralDTO';
import {
  createVariant,
  createVariantProps,
  deleteVariant,
  fetchVariants,
  patchVariant,
  patchVariantProps,
} from '../../../apiCallFunctions/variant';
import { loadVariants } from '../../../actions/variants';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const useGetVariants = () => {
  const {
    currentProject,
    mds,
    zones,
    allGroups,
    currentIndicator,
    currentAllotment,
  } = useSelector((state: StateDTO) => state.projects);
  const dispatch = useDispatch();

  return useQuery(
    ['variants', currentProject?.id, currentIndicator?.id],
    () =>
      fetchVariants({
        projectId: currentProject?.id,
        currentIndicatorId: currentIndicator.id,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        dispatch(
          loadVariants(
            [
              {
                name: currentProject.name,
                project: currentProject,
                id: currentProject.id,
                mds: mds,
                zones: zones,
              },
              ...data,
            ],
            allGroups,
            currentIndicator,
            currentAllotment
          )
        );
      },
      onError: (error) => {
        console.log('error in getVariants', error?.message);
      },
    }
  );
};

export const useCreateVariant = (
  onSuccessCallback?: (data: any) => void,
  onErrorCallback?: (error: any) => void
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation((data: createVariantProps) => createVariant(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries('variants');
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    },
    onError: (error) => {
      console.log('error', error);
      if (onErrorCallback) {
        onErrorCallback(error);
      }
    },
  });

  return mutation;
};

export const usePatchVariant = (
  onSuccessCallback?: (data: patchVariantProps) => void,
  onErrorCallback?: (error: any) => void
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(patchVariant, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('variants');
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    },
    onError: (error) => {
      console.log('error', error);
      if (onErrorCallback) {
        onErrorCallback(error);
      }
    },
  });

  return mutation;
};

export const useDeleteVariant = (
  onSuccessCallback?: (data: any) => void,
  onErrorCallback?: (error: any) => void
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteVariant, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('variants');
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    },
    onError: (error) => {
      console.log('error', error);
      if (onErrorCallback) {
        onErrorCallback(error);
      }
    },
  });

  return mutation;
};
