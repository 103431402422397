import './companyCard.scss';
import * as React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { CompanyDTO } from '../../models/ProjectDTO';
import { DatePicker } from 'antd';
import { Popover } from 'antd';
import { StateDTO } from '../../models/GeneralDTO';
import { TextField, Checkbox, Tooltip } from '@material-ui/core';
import {
  crudLicense,
  fetchAdminUsers,
  fetchCompaniesList,
  patchCompany,
  patchUser,
} from '../../../actions/rights';
import { updateRightsValue } from '../../../actions/rights.ts';
import { updateShowChildren } from '../../shared/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import ArrowDropDownCircleRoundedIcon from '@material-ui/icons/ArrowDropDownCircleRounded';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import adminPic from '../../../assets/images/access/adminPic.png';
import binPic from '../../../assets/images/access/bin.png';
import buildingPic from '../../../assets/images/access/building.png';
import contractPic from '../../../assets/images/access/contract.png';
import dayjs from 'dayjs';
import editPic from '../../../assets/images/access/edit.png';
import filesPic from '../../../assets/images/access/files.png';
import licensePic from '../../../assets/images/access/license.png';
import locale from 'antd/es/date-picker/locale/fr_FR';
import usersPic from '../../../assets/images/access/users.png';
import { useTranslation } from 'react-i18next';

interface CompanyCardProps {
  header: boolean;
  company: CompanyDTO;
}

const { RangePicker } = DatePicker;

export const returnProjectTypes = (t) => {
  return [
    {
      name: t('APP.SHARED.SIDEBAR.PH_05920A', { defaultValue: 'Travaux neuf' }),
      ids: [3, 1, 2, 4, 12],
    },
    {
      name: t('APP.SHARED.SIDEBAR.PH_F3C381', {
        defaultValue: 'Travaux exploitation',
      }),
      ids: [5],
    },
    {
      name: t('APP.SHARED.SIDEBAR.PH_D6B635', { defaultValue: 'Datacenter​' }),
      ids: [7],
    },
    {
      name: t('APP.SHARED.SIDEBAR.PH_CB1E66', { defaultValue: 'Aéroport' }),
      ids: [6],
    },
    {
      name: t('APP.SHARED.SIDEBAR.PH_763A59', {
        defaultValue: 'Infrastructure extérieure',
      }),
      ids: [8],
    },
    {
      name: t('APP.SHARED.SIDEBAR.PH_F89C6C', { defaultValue: 'Prototype' }),
      ids: [11],
    },
    {
      name: t('APP.CREATIONPROJECT.INDEX.GLOBAL_MONITORING', {
        defaultValue: 'Global monitoring',
      }),
      ids: [13],
    },
  ];
};

const CompanyCard = ({
  header = false,
  company,
  children = false,
}: CompanyCardProps) => {
  const dispatch = useDispatch();
  const { isSuperAdmin, companies, allCompanies } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { t } = useTranslation();

  const [projectTypes, setProjectTypes] = useState(returnProjectTypes(t));

  const { rightsHeaderData, companiesList, patchCompanyData, categories } =
    useSelector((state: StateDTO) => state.rights);
  const [selectedOptions, setSelectedOptions] = useState(
    projectTypes
      .filter((el) => company?.projectTypes?.find((id) => el.ids.includes(id)))
      .map((el) => el.name)
  );
  let proLicensesAvailable = 0;
  let readLicensesAvailable = 0;
  let lightLicensesAvailable = 0;
  let proLicensesUse = 0;
  let readLicensesUse = 0;
  let lightLicensesUse = 0;

  if (company) {
    if (company.freeLicenseCount) {
      company.freeLicenseCount?.forEach((el) => {
        switch (el.licenseType) {
          case 'pro':
            proLicensesAvailable = el.count;
            break;
          case 'read':
            readLicensesAvailable = el.count;
            break;
          case 'light':
            lightLicensesAvailable = el.count;
            break;
        }
      });
    }

    if (company.userCount) {
      company?.userCount?.forEach((el) => {
        switch (el.licenseType) {
          case 'pro':
            proLicensesUse = el.count;
            break;
          case 'read':
            readLicensesUse = el.count;
            break;
          case 'light':
            lightLicensesUse = el.count;
            break;
        }
      });
    }
  } else {
    if (!header) {
      return null;
    }
  }

  const renderUserPopUp = () => {
    return (
      <div key={company.userCount}>
        {company.userCount && company.userCount.length === 0 ? (
          <p>
            {t('APP.COMPANYCARD.INDEX.PH_BFF4F4', {
              defaultValue: 'Aucun utilisateur',
            })}
          </p>
        ) : (
          <>
            <div className="user-wrapper-licenses">
              {company.userCount &&
                company.userCount
                  .filter((el) => el.isAdmin)
                  .map((user) => (
                    <div key={user} className="user-license">
                      <div className="user-datas">
                        <p style={{ fontWeight: 'bold' }}>
                          {user.User.firstName} {user.User.lastName}
                        </p>
                        <a href="" className="">
                          {user.UserEmail}
                        </a>
                      </div>
                      <CloseIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          dispatch(
                            patchUser({
                              id: user.id,
                              isAdmin: false,
                              CompanyId: company.id,
                            })
                          )
                        }
                      />
                    </div>
                  ))}
            </div>

            <Autocomplete
              key={company.users}
              disabled={!company.users}
              id="checkboxes-tags-demo"
              options={company.users?.filter((el) => !el.isAdmin)}
              style={{ marginTop: '1em' }}
              noOptionsText={t('APP.COMPANYCARD.INDEX.PH_F1C41F', {
                defaultValue: 'Aucun résultat trouvé',
              })}
              onChange={(e, newValue) =>
                dispatch(
                  patchUser({
                    id: newValue.id,
                    isAdmin: true,
                    CompanyId: company.id,
                  })
                )
              }
              size={'small'}
              getOptionLabel={(option) => option && option.UserEmail}
              renderInput={(params) => (
                <TextField
                  style={{ borderRadius: '10px' }}
                  {...params}
                  placeholder={t('APP.COMPANYCARD.INDEX.PH_CAD4A8', {
                    defaultValue: 'Ajouter un utilisateur',
                  })}
                  variant="outlined"
                  className="researchBar"
                />
              )}
            />
          </>
        )}
      </div>
    );
  };

  const renderAdminPart = (count) => {
    return isSuperAdmin ? (
      <Popover
        trigger="click"
        title={t('APP.COMPANYCARD.INDEX.PH_CF6016', {
          defaultValue: 'Gérer les admins',
        })}
        onClick={() => dispatch(fetchAdminUsers(company.id))}
        content={renderUserPopUp}
      >
        <div className="item-access superadmin-license-admin">
          <div className="ellipse">
            <img src={adminPic} alt="" />
          </div>
          {/* <div className='subTitle'> */}
          <p>{t('APP.COMPANYCARD.INDEX.PH_DC5FB3', { defaultValue: 'Admin' })}</p>
          <div
            className="button-action"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {count}
          </div>
          {/* </div> */}
        </div>
      </Popover>
    ) : (
      <Popover
        trigger="click"
        title={t('APP.COMPANYCARD.INDEX.PH_EA16B7', {
          defaultValue: 'Gérer les admins',
        })}
        onClick={() => dispatch(fetchAdminUsers(company.id))}
        content={renderUserPopUp}
      >
        <div className="item-access">
          <div className="ellipse">
            <img src={adminPic} alt="" />
          </div>
          <div className="subTitle">
            <p>
              {count}{' '}
              {t('APP.COMPANYCARD.INDEX.PH_078BD3', {
                defaultValue: 'administrateurs',
              })}
            </p>
          </div>
        </div>
      </Popover>
    );
  };

  const renderChoiceContract = () => {
    return (
      <div>
        <p>
          {t('APP.COMPANYCARD.INDEX.PH_8CE2C3', {
            defaultValue: 'Début et fin de contrat :',
          })}
        </p>
        <RangePicker
          defaultValue={[
            dayjs(company.contract?.startDate),
            dayjs(company.contract?.endDate),
          ]}
          onChange={(e, dates) => {
            dispatch(
              updateRightsValue('patchCompanyData', {
                id: company.id,
                contract: {
                  startDate: dayjs(dates[0], 'L').format(),
                  endDate: dayjs(dates[1], 'L').format(),
                },
              })
            );
          }}
          className={`permis-form`}
          format={dayjs.localeData().longDateFormat('L')}
        />
      </div>
    );
  };

  const renderContractPart = () => {
    return (
      <Popover
        title={t('APP.COMPANYCARD.INDEX.PH_BFD2D0', {
          defaultValue: 'Gérer les dates de contrat',
        })}
        content={isSuperAdmin ? renderChoiceContract() : null}
        onOpenChange={(open) => (!open ? dispatch(patchCompany()) : null)}
        trigger={
          company?.id !== company?.payingCompany?.id ||
          !company.contract ||
          !isSuperAdmin
            ? ''
            : 'click'
        }
      >
        <div
          className={`item-access ${
            company?.id !== company?.payingCompany?.id || !company.contract
              ? 'disabled-block'
              : ''
          }`}
          style={{
            background: isSuperAdmin ? '#EFF1FA' : undefined,
            borderRadius: isSuperAdmin ? '10px' : null,
          }}
        >
          <div className="ellipse">
            <img src={contractPic} alt="" />
          </div>
          {header ? (
            <div className="subTitle">
              <p>Contrat</p>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p>{dayjs(company.contract?.startDate).format('L')}</p>
                <p>
                  <span style={{ marginLeft: '4px', marginRight: '4px' }}>-</span>
                </p>
                <p>{dayjs(company.contract?.endDate).format('L')}</p>
              </div>
            </div>
          ) : (
            <>
              <p>Contrat</p>
              <p>{dayjs(company.contract?.startDate).format('L')}</p>
              <p>{dayjs(company.contract?.endDate).format('L')}</p>
            </>
          )}

          {/* <div className='subTitle'>
                    <p>Contrat</p>
                    <div style={{display:'flex', justifyContent: 'center'}}>
                      <p>{moment(company.contract?.startDate).format('L')}</p>
                      <p><span style={{marginLeft: '4px', marginRight: '4px'}}>-</span></p>
                      <p>{moment(company.contract?.endDate).format('L')}</p>
                    </div>
                   </div> */}
          {/* <p>Contrat</p>
                   <p>{moment(company.contract?.startDate).format('L')}<br /><span style={{ marginLeft:"40%" }}></span><br />{moment(company.contract?.endDate).format('L')}</p> */}
        </div>
      </Popover>
    );
  };

  const renderCompaniesPart = (count: number = 0) => {
    return (
      <div className="item-access">
        <div className="ellipse">
          <img src={buildingPic} alt="" />
        </div>
        <div className="subTitle">
          <p>
            {count}{' '}
            {t('APP.COMPANYCARD.INDEX.PH_28893C', {
              defaultValue: 'entreprises',
            })}
          </p>
        </div>
      </div>
    );
  };

  const renderUserPart = (count: number = 0) => {
    let usersCount: number = 0;

    company?.userCount?.map((el) => {
      usersCount += el.count;
    });

    return (
      <div className="item-access">
        <div className="ellipse">
          <img src={usersPic} alt="" style={{ width: '40px' }} />
        </div>
        <div className="subTitle">
          <p>
            {count}{' '}
            {t('APP.COMPANYCARD.INDEX.PH_FE1222', {
              defaultValue: 'utilisateurs',
            })}
          </p>
        </div>
      </div>
    );
  };

  const renderManagementLicenses = () => {
    return (
      <div className="crud-licences">
        <p style={{ textAlign: 'center' }}>
          {t('APP.COMPANYCARD.INDEX.PH_2F4F17', {
            defaultValue: 'Ajouter/retirer des licences',
          })}
        </p>
        <div className="actions-part">
          {[
            {
              name: 'READ ONLY',
              variableName: 'read',
              backgroundColor: '#6EE8CB',
            },
            {
              name: 'LIGHT',
              variableName: 'light',
              backgroundColor: '#97F1E6',
            },
            { name: 'PRO', variableName: 'pro', backgroundColor: '#91E391' },
          ].map((type) => (
            <div className="crud-line" key={type.name}>
              <div
                className="license-count"
                style={{ background: type.backgroundColor }}
              >
                <p>{type.name}</p>
                <p>
                  {eval(`${type.variableName}LicensesUse`)}/
                  {eval(`${type.variableName}LicensesAvailable`) +
                    eval(`${type.variableName}LicensesUse`)}
                </p>
              </div>
              <div className="license-action">
                <p
                  className={`button-action ${
                    eval(`${type.variableName}LicensesAvailable`) +
                      eval(`${type.variableName}LicensesUse`) ===
                    0
                      ? 'button-disabled'
                      : ''
                  }`}
                  onClick={() =>
                    eval(`${type.variableName}LicensesAvailable`) +
                      eval(`${type.variableName}LicensesUse`) ===
                    0
                      ? null
                      : dispatch(
                          crudLicense({
                            method: 'delete',
                            licenseType: type.variableName,
                            CompanyId: company.id,
                          })
                        )
                  }
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  -
                </p>
                <p
                  className={`button-action`}
                  onClick={() =>
                    dispatch(
                      crudLicense({
                        method: 'add',
                        licenseType: type.variableName,
                        CompanyId: company.id,
                      })
                    )
                  }
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  +
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getOrgaRecursively = (orga) => {
    if (orga?.ParentNodeId) {
      const parent = allCompanies?.find((el) => el.id === orga?.ParentNodeId);
      return (
        <>
          {parent && (
            <>
              {getOrgaRecursively(parent)}
              {parent.ParentNodeId && '  >  '}
              <div>{parent?.name}</div>
            </>
          )}
        </>
      );
    }
  };

  const remainingLicences =
    readLicensesAvailable + lightLicensesAvailable + proLicensesAvailable;

  const renderLicensesPart = () => {
    return !isSuperAdmin && company.id === company.contract?.CompanyId ? (
      <div className="item-access">
        <div className="ellipse">
          <img src={licensePic} alt="" />
          <div className="container-dots">
            {readLicensesAvailable !== 0 && (
              <Tooltip
                title={t('APP.COMPANYCARD.INDEX.PH_35A3F9', {
                  defaultValue:
                    'Une licence read only permet exclusivement la consultation des projets de l’entreprise',
                })}
              >
                <span style={{ background: '#6EE8CB' }} className="dot">
                  <span>{readLicensesAvailable}</span>
                </span>
              </Tooltip>
            )}

            {lightLicensesAvailable ? (
              <Tooltip
                title={t('APP.COMPANYCARD.INDEX.PH_254933', {
                  defaultValue:
                    'Une licence light permet la consultation des projets, l’accès au catalogue Nooco et au simulateur rapide',
                })}
              >
                <span style={{ background: '#97F1E6' }} className="dot">
                  <span>{lightLicensesAvailable}</span>
                </span>
              </Tooltip>
            ) : (
              <></>
            )}

            <Tooltip
              title={t('APP.COMPANYCARD.INDEX.PH_C71F25', {
                defaultValue:
                  'Une licence pro donne à accès à toutes les fonctionnalités de la plateforme',
              })}
            >
              <span style={{ background: '#91E391' }} className="dot">
                <span>{proLicensesAvailable}</span>
              </span>
            </Tooltip>
          </div>
        </div>
        <div className="subTitle">
          <p>{`${remainingLicences} licence${
            remainingLicences > 1 ? 's' : ''
          } restante${remainingLicences > 1 ? 's' : ''}`}</p>
        </div>
      </div>
    ) : isSuperAdmin ? (
      <Popover
        title="Gérer les licences"
        trigger={
          company.id !== company.payingCompany.id || !company.contract ? '' : 'click'
        }
        content={renderManagementLicenses}
      >
        <div
          className={`superadmin-licenses-part ${
            company.id !== company.payingCompany.id || !company.contract
              ? 'disabled-block'
              : ''
          }`}
        >
          <div className="licenses-icon">
            <img src={licensePic} alt="" />
            <p>
              {t('APP.COMPANYCARD.INDEX.PH_49BBEE', {
                defaultValue: 'Licences',
              })}
            </p>
          </div>
          <div className="licenses-count">
            <div className="license-count" style={{ background: '#6EE8CB' }}>
              <p>READ ONLY</p>
              <p>
                {readLicensesUse}/{readLicensesAvailable + readLicensesUse}
              </p>
            </div>
            <div className="license-count" style={{ background: '#97F1E6' }}>
              <p>LIGHT</p>
              <p>
                {lightLicensesUse}/{lightLicensesAvailable + lightLicensesUse}
              </p>
            </div>
            <div className="license-count" style={{ background: '#91E391' }}>
              <p>PRO</p>
              <p>
                {proLicensesUse}/{proLicensesAvailable + proLicensesUse}
              </p>
            </div>
          </div>
        </div>
      </Popover>
    ) : null;
  };

  const renderProjectsPart = (count: number = 0) => {
    return (
      <div className="item-access">
        <div className="ellipse">
          <img src={filesPic} alt="" style={{ width: '30px' }} />
        </div>
        <div className="subTitle">
          <p>
            {count}{' '}
            {t('APP.COMPANYCARD.INDEX.PH_C5EF17', { defaultValue: 'projets' })}
          </p>
        </div>
      </div>
    );
  };

  const renderActionsButton = () => {
    return (
      !header && (
        <div
          className="access-action"
          onClick={() => {
            dispatch(
              updateRightsValue('patchCompanyData', {
                id: company.id,
                name: company.name,
                CompanyCategoryId: company.CompanyCategoryId,
              })
            );
          }}
        >
          <Tooltip
            title={t('APP.COMPANYCARD.INDEX.PH_E3019E', {
              defaultValue: 'Modifier le nom ou le type de l’entreprise',
            })}
          >
            <div
              className="ellipse-action"
              onClick={() => dispatch(updateRightsValue('modalAccess', 'editDatas'))}
            >
              <img src={editPic} alt="true" />
            </div>
          </Tooltip>
          {isSuperAdmin && (
            <Tooltip
              title={t('APP.COMPANYCARD.INDEX.PH_FE117A', {
                defaultValue: 'Supprimer l’entreprise',
              })}
            >
              <div
                className="ellipse-action"
                onClick={() =>
                  dispatch(updateRightsValue('modalAccess', 'deleteCompany'))
                }
              >
                <img src={binPic} alt="true" />
              </div>
            </Tooltip>
          )}
        </div>
      )
    );
  };

  return (
    <>
      <div
        className={`companyCard-wrapper ${
          children ? 'companyCard-wrapper-children' : ''
        } ${header ? 'topPanel' : ''}`}
        style={{
          // width: !header ? `${company.width}%` : undefined,
          height: !header ? '190px' : undefined,
          marginLeft: !header ? `${company.depth + 2}%` : undefined,
        }}
      >
        {company?.type !== 'company' && !header && (
          <ArrowDropDownCircleRoundedIcon
            className="arrow-orgas"
            onClick={() => {
              let copy = _.cloneDeep(companiesList);

              if (!company.showChildren && company.children?.length === 0) {
                // dispatch(updateRightsValue('inputSearch', null))
                dispatch(updateRightsValue('parentNodeId', company.id));
                dispatch(fetchCompaniesList());
              } else if (!company.showChildren && company.children?.length > 0) {
                dispatch(
                  updateRightsValue(
                    'companiesList',
                    updateShowChildren(copy, company.id, true)
                  )
                );
              } else {
                dispatch(
                  updateRightsValue(
                    'companiesList',
                    updateShowChildren(copy, company.id)
                  )
                );
              }
            }}
          />
        )}

        {isSuperAdmin && header ? (
          <div
            className="companyCard-left "
            style={{
              maxWidth: '100%',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '7%',
            }}
          >
            <h2 style={{ fontWeight: 'bold' }}>SuperAdmin</h2>
          </div>
        ) : (
          <div className="companyCard-left">
            <div className="companyCard-left-orga">
              {getOrgaRecursively(company)}
            </div>
            <h2
              className={
                company?.name.length > 41
                  ? 'verySmallTitle'
                  : company?.name.length > 41
                  ? 'smallTitle'
                  : ''
              }
            >
              {company?.name}
            </h2>
            <div style={{ marginLeft: '1em' }}>
              {company?.ParentNodeId && (
                <p>
                  {companies?.find((el) => el.id === company?.ParentNodeId)?.name}
                </p>
              )}

              <p>
                {
                  categories?.find((el) => el.id === company?.CompanyCategoryId)
                    ?.name
                }
              </p>
              <p>
                {t('APP.COMPANYCARD.INDEX.PH_E87618', {
                  defaultValue: 'sur Nooco depuis le',
                })}{' '}
                {dayjs(company?.createdAt).format('L')}
              </p>
            </div>

            <div
              className={`${
                isSuperAdmin
                  ? 'types-project-available'
                  : 'types-project-available disabled'
              }`}
              style={{ maxWidth: '100%', height: '45px', paddingRight: '10%' }}
            >
              <Autocomplete
                style={{ maxWidth: '100%', height: '45px' }}
                multiple
                id="tags-standard"
                className="addressBar"
                disabled={!isSuperAdmin}
                limitTags={2}
                options={projectTypes}
                getOptionLabel={(option) => option && option.name}
                defaultValue={projectTypes.filter((el) =>
                  company?.projectTypes?.find((id) => el.ids.includes(id))
                )}
                onChange={(e, newValue) => {
                  setSelectedOptions(newValue.map((el) => el.name)); // Store the selected options
                  dispatch(
                    patchCompany({
                      ...patchCompanyData,
                      id: company?.id,
                      projectTypes: [...newValue.map((el) => el.ids)].flat(2),
                    })
                  );
                }}
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      style={{
                        marginRight: 8,
                        color: selected ? '#c0b9f4' : 'inherit',
                      }}
                      checked={selected}
                    />

                    {option.name}
                  </>
                )}
                renderInput={(params) => (
                  <Tooltip title={selectedOptions.join(', ')}>
                    <TextField
                      style={{ maxWidth: '100%', height: '50px' }}
                      {...params}
                      variant="outlined"
                      className="researchBar input-boarding"
                    />
                  </Tooltip>
                )}
              />

              {/* <p className="badge-typeProject">Travaux</p>
              <p className="badge-typeProject">Exploitation</p> */}
            </div>
          </div>
        )}

        <div
          className="items-access"
          style={{ alignItems: !isSuperAdmin ? 'center' : 'center' }}
        >
          {/* Header part  */}
          {header &&
            rightsHeaderData &&
            (isSuperAdmin ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    minHeight: '150px',
                  }}
                >
                  {renderCompaniesPart(rightsHeaderData.companyCount)}
                  {renderUserPart(rightsHeaderData.userCount)}
                  {renderProjectsPart(rightsHeaderData.projectCount)}
                </div>
              </>
            ) : (
              <>
                {renderContractPart()}

                <div className="line-design"></div>

                {company.type !== 'company' &&
                  renderCompaniesPart(company.companyCount)}
                <div className="line-design"></div>
                {renderUserPart(company.userTotalCount)}
                <div className="line-design"></div>

                {renderLicensesPart()}
                <div className="line-design"></div>
                {renderProjectsPart(company.projectCount)}
              </>
            ))}

          {/* Line core part */}
          {!header &&
            (isSuperAdmin ? (
              <>
                <div className="subwrapperCardIsAdmin">
                  {renderContractPart()}
                  {renderLicensesPart()}
                  {renderAdminPart(company.adminCount)}
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                  }}
                >
                  {company.type !== 'company' &&
                    renderCompaniesPart(company.companyCount)}
                  {renderUserPart(company.userTotalCount)}
                  {renderProjectsPart(company.projectCount)}
                </div>
              </>
            ) : (
              <>
                {renderAdminPart(company.adminCount)}
                <div className="line-design"></div>
                {company.type !== 'company' &&
                  renderCompaniesPart(company.companyCount)}
                <div className="line-design"></div>
                {renderUserPart(company.userTotalCount)}
                <div className="line-design"></div>
                {renderLicensesPart()}
                <div className="line-design"></div>
                {renderProjectsPart(company.projectCount)}
              </>
            ))}
        </div>

        {renderActionsButton()}
      </div>
    </>
  );
};
export default CompanyCard;
