import './generalForm.scss';
import { Controller, useWatch } from 'react-hook-form';
import { DatePicker } from 'antd';
import { Select, Tooltip } from '@material-ui/core';
import { StateDTO } from '../../../../models/GeneralDTO';
import { buildingTypesMap } from '../../../../services/mathematics/indicators/buildingTypesMap';
import { updateFormValue } from '../../../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import Address from '../../../../shared/Address';
import CountrySelector from '../../../../shared/CountrySelector';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InfoIcon from '@material-ui/icons/Info';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import RsetImport from '../../../../../containers/RsetImport';
import SelectUi from '@mui/material/Select';

import { TYPE_EXPLOITATION } from '../../../../globalVariable/typeProject';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';

import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { getPhaseOptions } from '../../CreationProjectFunction/creationProjectFunction';
import { isDisplayPermitDate } from '../../../Settings/SettingsSharedFunction/SettingsSharedFunction';
import { returnProjectTypeFromProjectTypeTitle } from '../../../../shared/utils';
import { getAntdLocale } from '../../../../shared/getAntdLocale';
import useCase from '../../../HomeProject/useCase/useCase';
import {
  CREATION_STEPS,
  CREATION_STEPS_TRAD_KEYS,
} from '../../../../globalVariable/creationProjectVariable';

const { RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';

interface GeneralFormProps {
  control: any;
  setValue: Function;
  errors: any;
  uuid: string;
}

const GeneralForm = ({ control, setValue, errors, uuid }: GeneralFormProps) => {
  const [snackMessage, setMessageSnack] = useState<boolean | any>();
  const country = useWatch({ control, name: 'country' });

  const dispatch = useDispatch();
  const { creationSteps, selectedTypeProjectId } = useSelector(
    (state: StateDTO) => state.projects.creationProject
  );
  const {
    companies,
    user,
    projectNameRset,
    permitDateRset,
    rsetmodalOpen,
    refUnit,
    buildingSections,
    phaseIdRset,
    isSuperAdmin,
    allGroups,
    creationProject,
  } = useSelector((state: StateDTO) => state.projects);
  const { arianeCompany } = useSelector(
    (state: StateDTO) => state.projects.projectsListMonitoring
  );
  const { selectedCompanyId } = arianeCompany;
  const { selectHomeBreadCrumb } = useCase();

  const { t } = useTranslation();

  let filteredCompanies = isSuperAdmin
    ? companies
    : companies.filter((company) =>
        company.ProjectTypes.includes(selectedTypeProjectId)
      );

  const isCompanySelectedInNavigation = typeof selectedCompanyId === 'number';

  const getCompanyFromId = (companyId) => {
    return filteredCompanies.find((company) => company.id === companyId) || null;
  };

  useEffect(() => {
    if (projectNameRset) {
      setValue('name', projectNameRset);
    }
    if (permitDateRset) {
      setValue('permitDate', dayjs(permitDateRset, dateFormat));
    }
    if (phaseIdRset && phaseIdRset < optionsPhase.length) {
      setValue('projectPhase', optionsPhase[phaseIdRset - 1].value);
    }
    return () => {
      dispatch(updateFormValue('projectNameRset', ''));
    };
  }, [projectNameRset, permitDateRset, phaseIdRset]);

  const optionsPhase = getPhaseOptions({ creationSteps });

  const availableUnits =
    allGroups.projectTypes.find(
      (project) => project.id === creationProject.selectedTypeProjectId
    )?.availableUnits || [];

  useEffect(() => {
    if (availableUnits?.length === 1) {
      setValue('refUnit', availableUnits[0]);
      dispatch(updateFormValue('refUnit', availableUnits[0]));
    }
  }, []);

  return (
    <>
      <Snackbar
        onClose={() => setMessageSnack(false)}
        open={snackMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackMessage}
      >
        <Alert onClose={() => setMessageSnack(false)} severity="info">
          {snackMessage}
        </Alert>
      </Snackbar>
      <div
        className="general-form-wrapper"
        style={{ display: rsetmodalOpen ? 'none' : undefined }}
      >
        <div className="title-part">
          <h3>
            {t('APP.GENERALFORM.INDEX.PH_101B66', {
              defaultValue: 'Informations globales',
            })}
          </h3>
        </div>
        {/* RSET IMPORT */}
        <div className="architecture-core">
          {(creationSteps.includes(
            t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.RE2020])
          ) ||
            creationSteps.includes(
              t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.EPCM])
            )) && (
            <RsetImport
              creationSteps={creationSteps}
              alertCallback={setMessageSnack}
              projectId={undefined}
              renderCallback={undefined}
              uuid={uuid}
            />
          )}
        </div>
        <div className="general-form">
          {/* projectName */}
          <div className="block-form">
            <p className="label-textfield">
              {t('APP.GENERALFORM.INDEX.PROJECT_NAME_CREATION_LABEL', {
                defaultValue: 'Nom du projet',
              })}
            </p>{' '}
            <Controller
              name="name"
              control={control}
              defaultValue={null}
              rules={{
                validate: (value) => {
                  let forbiddenCharacters = [
                    '/',
                    '{',
                    '^',
                    '}',
                    '%',
                    '`',
                    ']',
                    '“',
                    '>',
                    '[',
                    '~',
                    '<',
                    '#',
                    '|',
                    `\\`,
                  ];

                  let errors = forbiddenCharacters.filter((char) =>
                    value.includes(char)
                  );

                  if (errors.length === 1) {
                    return t(
                      'APP.GENERALFORM.INDEX.PH_013EB1',

                      {
                        char: forbiddenCharacters.find((char) =>
                          value.includes(char)
                        ),
                        defaultValue:
                          'Caractère {char} interdit. Veuillez svp le supprimer ou le remplacer',
                      }
                    );
                  } else if (errors.length > 1) {
                    return t(
                      'APP.GENERALFORM.INDEX.PH_EB7F22',

                      {
                        chars: errors.map((el) => `${el} `),
                        defaultValue:
                          'Les caractères suivants sont interdits : {chars}. Veuillez svp les supprimer ou les remplacer',
                      }
                    );
                  }

                  dispatch(updateFormValue('projectNameRset', value));

                  return true;
                },
                required: t('APP.GENERALFORM.INDEX.PH_6CED8D', {
                  defaultValue: 'Veuillez renseigner un nom.',
                }),
              }}
              render={({ field }) => (
                <TextField
                  style={{ width: '100%' }}
                  {...field}
                  defaultValue={projectNameRset}
                  key={projectNameRset}
                  placeholder={t('APP.GENERALFORM.INDEX.PH_EEDCAB', {
                    defaultValue: 'Nom de projet',
                  })}
                  variant="outlined"
                  className={`input-boarding researchBar ${
                    errors?.name ? 'error' : ''
                  }`}
                />
              )}
            />
            <p className="error-message-form">{errors?.name?.message}</p>
          </div>
          {/* Company and responsable */}
          <div className="two-inputs-row">
            <div className="one-input">
              <>
                <p className="label-textfield">
                  {t('APP.GENERALFORM.INDEX.PH_451B75', {
                    defaultValue: 'Entreprise',
                  })}
                </p>
                {filteredCompanies.length === 1 ? (
                  <Controller
                    name="company"
                    control={control}
                    defaultValue={filteredCompanies[0]}
                    rules={{
                      required: t('APP.GENERALFORM.INDEX.PH_DD5F1C', {
                        defaultValue: 'Veuillez renseigner une entreprise.',
                      }),
                    }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        id="checkboxes-tags-demo"
                        options={filteredCompanies.sort(
                          (a, b) =>
                            a.name.localeCompare(b.name) -
                            b.name.localeCompare(a.name)
                        )}
                        value={filteredCompanies[0]}
                        disabled
                        noOptionsText={t('APP.GENERALFORM.INDEX.PH_48B4CE', {
                          defaultValue: 'Aucun résultat trouvé',
                        })}
                        // onChange={(event, newValue) => {

                        //   if (newValue)
                        //     handleField('company', newValue.id);

                        // }}
                        size={'small'}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t('APP.GENERALFORM.INDEX.PH_1817A2', {
                              defaultValue: 'Entreprises',
                            })}
                            variant="outlined"
                            className={`researchBar ${
                              errors?.company ? 'error-border-form' : ''
                            }`}
                          />
                        )}
                      />
                    )}
                  />
                ) : filteredCompanies.length > 1 ? (
                  <Controller
                    name="company"
                    control={control}
                    rules={{
                      required: t('APP.GENERALFORM.INDEX.PH_95F780', {
                        defaultValue: 'Veuillez renseigner une entreprise.',
                      }),
                    }}
                    defaultValue={
                      isCompanySelectedInNavigation
                        ? getCompanyFromId(selectedCompanyId)
                        : null
                    }
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        disabled={false}
                        onChange={(data, newValue) => {
                          console.log(data);
                          selectHomeBreadCrumb();

                          setValue('company', newValue ? newValue : undefined);
                        }}
                        id="checkboxes-tags-demo"
                        options={filteredCompanies.sort(
                          (a, b) =>
                            a.name.localeCompare(b.name) -
                            b.name.localeCompare(a.name)
                        )}
                        noOptionsText={t('APP.GENERALFORM.INDEX.PH_904D59', {
                          defaultValue: 'Aucun résultat trouvé',
                        })}
                        // onChange={(event, newValue) => {

                        //   if (newValue)
                        //     handleField('company', newValue.id);

                        // }}
                        size={'small'}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t('APP.GENERALFORM.INDEX.PH_4BB861', {
                              defaultValue: 'Entreprises',
                            })}
                            variant="outlined"
                            className={`researchBar ${
                              errors?.company ? 'error-border-form' : ''
                            }`}
                          />
                        )}
                      />
                    )}
                  />
                ) : null}

                <p className="error-message-form">{errors?.company?.message}</p>
              </>
            </div>

            <div className="one-input">
              <p className="label-textfield">
                {t('APP.GENERALFORM.INDEX.PH_6266F1', {
                  defaultValue: 'Responsable',
                })}
              </p>
              <Controller
                name="overseer"
                control={control}
                defaultValue={`${user.attributes.family_name.toUpperCase()} ${
                  user.attributes.given_name
                }`}
                rules={{
                  required: t('APP.GENERALFORM.INDEX.PH_CE78EE', {
                    defaultValue: 'Veuillez renseigner un responsable.',
                  }),
                }}
                render={({ field }) => (
                  <TextField
                    style={{ width: '100%' }}
                    placeholder={t('APP.GENERALFORM.INDEX.PH_97724D', {
                      defaultValue: 'Responsable',
                    })}
                    variant="outlined"
                    className={`input-boarding researchBar ${
                      errors?.overseer ? 'error' : ''
                    }`}
                    {...field}
                  />
                )}
              />
              <p className="error-message-form">{errors?.overseer?.message}</p>
            </div>
          </div>
          {/* adress */}
          <div className="two-inputs-row">
            <div className="one-input">
              <div className="block-form">
                <p className="label-textfield">
                  {t('APP.GENERALFORM.INDEX.COUNTRY', {
                    defaultValue: 'Pays',
                  })}
                </p>
                <CountrySelector
                  name="country"
                  defaultValue={'FRA'}
                  setValue={setValue}
                  language={localStorage.getItem('i18nLanguage') || 'fr-FR'}
                  control={control}
                />
                <p className="error-message-form">{errors?.country?.message}</p>
              </div>
            </div>
            <div className="one-input">
              {/* adress */}
              <div className="block-form">
                <p className="label-textfield">
                  {t('APP.GENERALFORM.INDEX.PH_66F28B', {
                    defaultValue: 'Ville ou code postal',
                  })}
                </p>
                <Address
                  disabled={!country}
                  error={errors?.location}
                  control={control}
                  setValue={setValue}
                  countryCode={country?.code}
                />

                <p className="error-message-form">{errors?.location?.message}</p>
              </div>
            </div>
          </div>
          {(creationSteps.includes(
            t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.RE2020])
          ) ||
            creationSteps.includes(
              t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.EPCM])
            )) && (
            <div className="two-inputs-row">
              <div className="one-input">
                <p className="label-textfield">
                  {t('APP.GENERALFORM.INDEX.PH_2F6080', {
                    defaultValue: 'Date de permis de construire',
                  })}
                </p>
                <Controller
                  name="permitDate"
                  control={control}
                  key={permitDateRset}
                  defaultValue={
                    permitDateRset ? dayjs(permitDateRset, dateFormat) : null
                  }
                  rules={{
                    required: t('APP.GENERALFORM.INDEX.PH_45D018', {
                      defaultValue:
                        'Veuillez renseigner une date de permis de construire au format JJ/MM/AAAA.',
                    }),
                    validate: (value) => {
                      if (
                        creationSteps.includes(
                          t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.RE2020])
                        )
                      ) {
                        let isFirstBuildingTypeValidation = false;
                        let isSecondBuildingTypeValidation = false;

                        buildingSections.forEach((bd) => {
                          bd.Zones.forEach((zone) => {
                            if (
                              zone.BuildingTypeId === buildingTypesMap.BUREAUX ||
                              zone.BuildingTypeId ===
                                buildingTypesMap.ENSEIGNEMENT_PRIMAIRE ||
                              zone.BuildingTypeId ===
                                buildingTypesMap.ENSEIGNEMENT_SECONDAIRE
                            ) {
                              isFirstBuildingTypeValidation = true;
                            } else if (
                              zone.BuildingTypeId === buildingTypesMap.MAISON ||
                              zone.BuildingTypeId === buildingTypesMap.LOGEMENT_COL
                            ) {
                              isSecondBuildingTypeValidation = true;
                            }
                          });
                        });

                        if (
                          value.isBefore(dayjs('01/07/2022').format(dateFormat)) &&
                          isFirstBuildingTypeValidation
                        ) {
                          return t('APP.GENERALFORM.INDEX.PH_81CE9A', {
                            defaultValue:
                              'La RE2020 ne s’applique pas aux projets de cette typologie ayant une date de permis de construire antérieure au 01/07/2022',
                          });
                        }

                        if (
                          value.isBefore('01/01/2022') &&
                          isSecondBuildingTypeValidation
                        ) {
                          return t('APP.GENERALFORM.INDEX.PH_449D09', {
                            defaultValue:
                              'La RE2020 ne s’applique pas aux projets de cette typologie ayant une date de permis de construire antérieure au 01/01/2022',
                          });
                        }
                      }

                      return true;
                    },
                  }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      className={`permis-form ${
                        errors?.permitDate ? 'error-border-form' : ''
                      }`}
                      format={dayjs.localeData().longDateFormat('L')}
                      locale={getAntdLocale()}
                    />
                  )}
                />
                <p className="error-message-form">
                  {errors?.permitDate?.type === 're2020'
                    ? errors?.permitDate?.type.message
                    : errors?.permitDate?.message}
                </p>
              </div>
              <div className="one-input">
                <p className="label-textfield">
                  {t('APP.GENERALFORM.INDEX.PH_20EE80', {
                    defaultValue: 'Phase',
                  })}
                </p>
                <Controller
                  name="projectPhase"
                  control={control}
                  rules={{
                    required: t('APP.GENERALFORM.INDEX.PH_BB2512', {
                      defaultValue: 'Veuillez renseigner une phase.',
                    }),
                  }}
                  key={phaseIdRset}
                  defaultValue={
                    phaseIdRset && phaseIdRset < optionsPhase.length
                      ? optionsPhase[phaseIdRset - 1].value
                      : null
                  }
                  render={({ field }) => (
                    <Select
                      data-testid="phase-dropdown"
                      {...field}
                      style={{ width: '100%' }}
                      variant="outlined"
                      className={`input-boarding researchBar ${
                        errors?.projectPhase ? 'error' : ''
                      }`}
                    >
                      {optionsPhase.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />

                <p className="error-message-form">{errors?.projectPhase?.message}</p>
              </div>
            </div>
          )}
          {(creationSteps.includes(
            t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.EXPLOITATION])
          ) ||
            creationSteps.includes(
              t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.GLOBAL_MONITORING])
            )) && (
            <div className="two-inputs-row">
              <div className="one-input">
                <p className="label-textfield">
                  {t('APP.GENERALFORM.INDEX.PH_DDAD9B', {
                    defaultValue: 'Période concernée',
                  })}
                </p>
                <Controller
                  data-testid="creationForm-exploitDates"
                  name="exploitDates"
                  control={control}
                  defaultValue={null}
                  rules={{
                    required: t('APP.GENERALFORM.INDEX.PH_7CEA2E', {
                      defaultValue: 'Veuillez renseigner une période.',
                    }),
                  }}
                  render={({ field }) => (
                    <RangePicker
                      disabledDate={null}
                      disabled={[false, false]}
                      {...field}
                      className={`permis-form ${
                        errors?.exploitDates ? 'error' : ''
                      }`}
                      format={dayjs.localeData().longDateFormat('L')}
                      locale={getAntdLocale()}
                    />
                  )}
                />
                <p className="error-message-form">{errors?.exploitDates?.message}</p>
              </div>

              {creationSteps.includes(
                t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.EXPLOITATION])
              ) && (
                <div className="one-input">
                  <p className="label-textfield">
                    {t('APP.GENERALFORM.INDEX.PH_99D671', {
                      defaultValue: 'Durée de vie du bâtiment',
                    })}
                    <Tooltip
                      title={t('APP.GENERALFORM.INDEX.PH_CC7CA3', {
                        defaultValue: 'Saisir la durée de vie restante du bâtiment.',
                      })}
                    >
                      <InfoIcon style={{ marginLeft: '5px' }} />
                    </Tooltip>
                  </p>

                  <Controller
                    name="ddv"
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: t('APP.GENERALFORM.INDEX.PH_0EE892', {
                        defaultValue: 'Veuillez renseigner une période.',
                      }),
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        style={{ width: '100%' }}
                        placeholder={t('APP.GENERALFORM.INDEX.PH_4AA73F', {
                          defaultValue: 'Durée de vie du bâtiment',
                        })}
                        variant="outlined"
                        className={`input-boarding researchBar ${
                          errors?.ddv ? 'error-border-form' : ''
                        }`}
                      />
                    )}
                  />

                  <p className="error-message-form">{errors?.ddv?.message}</p>
                </div>
              )}
            </div>
          )}

          <div className="two-inputs-row">
            <div className="one-input">
              <p className="label-textfield">
                {t('APP.GENERALFORM.INDEX.PH_FA62A9', {
                  defaultValue: "Choix de l'unité de référence",
                })}
                <Tooltip
                  title={t('APP.GENERALFORM.INDEX.PH_7EAE31', {
                    defaultValue:
                      'Tous les calculs d’impact de votre projet seront exprimés selon l’unité de référence choisie : /m² de la surface de référence du projet ou bien en total kg éq. CO2.',
                  })}
                >
                  <InfoIcon style={{ marginLeft: '5px' }} />
                </Tooltip>
              </p>
              <Controller
                name="refUnit"
                control={control}
                defaultValue={null}
                rules={{
                  required: t('APP.GENERALFORM.INDEX.PH_DD85F4', {
                    defaultValue: 'Veuillez renseigner une unité de référence.',
                  }),
                }}
                render={({ field }) => (
                  <SelectUi
                    {...field}
                    data-testid="refUnit-dropdown"
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    defaultValue={
                      availableUnits.length === 1 ? availableUnits[0] : null
                    }
                    style={{ width: '48%', height: '40px' }}
                    variant="outlined"
                    className={`input-boarding researchBar ${
                      errors?.refUnit ? 'error' : ''
                    }`}
                    onChange={(e) => {
                      if (e.target.value === 'Total') {
                        e.target.value = 'total';
                      }
                      setValue('refUnit', e.target.value);
                      dispatch(updateFormValue('refUnit', e.target.value));
                    }}
                  >
                    {/* Generate options dynamically based on availableUnits array */}
                    {availableUnits.map((unit, index) => (
                      <MenuItem
                        key={index}
                        value={unit}
                        defaultValue={unit}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        {unit}
                      </MenuItem>
                    ))}
                  </SelectUi>
                )}
              />

              <p className="error-message-form">{errors?.refUnit?.message}</p>
            </div>
            {refUnit === 'm²' &&
              creationSteps.includes(
                t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.WORK_AND_INDUSTRY])
              ) && (
                <div className="one-input">
                  <p className="label-textfield">
                    {t('APP.GENERALFORM.INDEX.PH_165DD9', {
                      defaultValue: 'Surface',
                    })}
                  </p>
                  <Controller
                    name="area"
                    control={control}
                    rules={{
                      required: t('APP.GENERALFORM.INDEX.PH_E25D0E', {
                        defaultValue: 'Veuillez renseigner une surface.',
                      }),
                    }}
                    render={({ field }) => (
                      <TextField
                        data-testid="creationForm-surface"
                        {...field}
                        style={{ width: '100%' }}
                        placeholder={t('APP.GENERALFORM.INDEX.PH_C6278D', {
                          defaultValue: 'Surface',
                        })}
                        variant="outlined"
                        className={`input-boarding researchBar ${
                          errors?.area ? 'error-border-form' : ''
                        }`}
                      />
                    )}
                  />
                  <p className="error-message-form">{errors?.area?.message}</p>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralForm;
