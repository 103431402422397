import './customThreshold.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { ProjectIcDTO, ScreenName } from '../../projects/Report/Widgets/WidgetsDTO';
import { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import React from 'react';
import formatNumber from '../../services/mathematics/formatNumber';
import { Dropdown } from '@photocarbone/nooco-ui';
import { useTranslation } from 'react-i18next';
import { MIDED_OPTIONS } from '../../globalVariable/generalDefinitions';

import validIcon from '../../../assets/images/synthese/threshold/C1.svg';
import invalidIcon from '../../../assets/images/synthese/threshold/C2.svg';

type BuildingEnergyWidgetsProps = {
  screen: ScreenName;
  modalData: any;
  buildingIc: ProjectIcDTO; // can be too projectIc if only one building
};

export function MiDedDropdown(selectedOption, setSelectedOption) {
  return (
    <div
      className={'dropdownMidedWrapper'}
      style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
    >
      <Dropdown
        options={Object.values(MIDED_OPTIONS).map((name) => ({
          label: name,
          value: name,
        }))}
        treeCheckable={false}
        style={{
          borderRadius: '15px',
          width: '120px',
        }}
        onChange={(value) => {
          setSelectedOption(value);
        }}
        onSelect={(value) => {
          setSelectedOption(value);
        }}
        allowClear={false}
        defaultValue={selectedOption}
      />
    </div>
  );
}

const CustomThreshold = ({
  screen,
  buildingIc,
  modalData,
  oldCalcul = false,
}: BuildingEnergyWidgetsProps) => {
  const [selectedModal, setSelectedModal] = useState('');
  const [isHovering, setIsHovering] = useState<string | undefined>('2020');

  let icMaxByYear;
  let icReference;
  let validatedThresholdYear = [];

  if (screen === 'energy') {
    icMaxByYear = buildingIc.IcEnergieMaxByYear;
    icReference = buildingIc?.IcEnergie;
    // icReference = 550;
  } else if (screen === 'construct') {
    icMaxByYear = oldCalcul
      ? modalData?.IcConstructionMaxByYearMidedFixed
      : modalData?.IcConstructionMaxByYear;
    icReference = buildingIc?.IcConstruction;
    // icReference = 550;
  }

  if (!icReference) {
    icReference = 0;
  }

  if (icMaxByYear && icMaxByYear['2022'] >= icReference) {
    validatedThresholdYear.push('2022');
  }

  if (icMaxByYear && icMaxByYear['2025'] >= icReference) {
    validatedThresholdYear.push('2025');
  }

  if (icMaxByYear && icMaxByYear['2028'] >= icReference) {
    validatedThresholdYear.push('2028');
  }

  if (icMaxByYear && icMaxByYear['2031'] >= icReference) {
    validatedThresholdYear.push('2031');
  }

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLDivElement>,
    yearSelected: '2022' | '2025' | '2028' | '2031'
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedModal(yearSelected);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedModal('');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const RenderPopoverBody = () => {
    if (selectedModal === '') {
      return null;
    }

    const modalDataArray = [];

    if (screen === 'energy') {
      modalDataArray.push(
        { IcEnergie: formatNumber(modalData?.IcEnergie) },
        {
          IcEnergieMax: formatNumber(modalData?.IcEnergieMaxByYear[selectedModal]),
        },
        {
          IcEnergieMaxMoyen: formatNumber(
            modalData?.EnergieMaxMoyenByYear[selectedModal]
          ),
        },
        { McGeo: formatNumber(modalData?.IcEnergieComp?.McGeo) },
        { McCombles: formatNumber(modalData?.IcEnergieComp?.McCombles) },
        { McsurfTot: formatNumber(modalData?.IcEnergieComp?.McsurfTot) },
        { McSurfMoy: formatNumber(modalData?.IcEnergieComp?.McSurfMoy) },
        { McCat: formatNumber(modalData?.IcEnergieComp?.McCat) }
      );
    }

    if (screen === 'construct') {
      const IcConstructionMaxByYear = oldCalcul
        ? modalData?.IcConstructionMaxByYearMidedFixed[selectedModal]
        : modalData?.IcConstructionMaxByYear[selectedModal];

      modalDataArray.push(
        { IcConstruction: formatNumber(modalData?.IcConstruction) },

        {
          IcConstructionMax: formatNumber(IcConstructionMaxByYear),
        },
        {
          IcConstructionMaxMoyen: formatNumber(
            modalData?.ConstructionMaxMoyenByYear[selectedModal]
          ),
        },
        { IcDed: formatNumber(modalData?.IcDed) },
        {
          Micombles: formatNumber(modalData?.IcConstructionMaxComp?.Micombles),
        },
        {
          Mided: formatNumber(
            oldCalcul
              ? modalData?.MidedByYear[`${selectedModal}Fixed`]
              : modalData?.MidedByYear[selectedModal]
          ),
        },
        { Migeo: formatNumber(modalData?.IcConstructionMaxComp?.Migeo) },
        { Miinfra: formatNumber(modalData?.IcConstructionMaxComp?.Miinfra) },
        { Mipv: formatNumber(modalData?.IcConstructionMaxComp?.Mipv) },
        { Misurf: formatNumber(modalData?.IcConstructionMaxComp?.Misurf) },
        { Mivrd: formatNumber(modalData?.IcConstructionMaxComp?.Mivrd) }
      );
    }

    return (
      <div className="popoverWrapper">
        {modalDataArray?.map((data, index) => {
          let keysToDisplate;
          let valueToDisplay;

          for (const [key, value] of Object.entries(data)) {
            keysToDisplate = key;
            valueToDisplay = value;
          }

          return (
            <div key={`popover-${index}`} className="popoverWrapper__index">
              {keysToDisplate} : <span>{valueToDisplay}</span>
            </div>
          );

          // })
        })}
      </div>
    );
  };

  return (
    <div className="thresholdWrapper">
      <div className="threshold__icons">
        <div
          onClick={(e) => {
            handlePopoverOpen(e, '2022');
          }}
          className={
            validatedThresholdYear.includes('2022')
              ? 'threshold__icons--valid'
              : 'threshold__icons--invalid'
          }
        >
          <img
            src={validatedThresholdYear.includes('2022') ? validIcon : invalidIcon}
            alt=""
            onMouseOver={() => {
              setIsHovering('2022');
            }}
            onMouseOut={() => {
              setIsHovering(undefined);
            }}
          />
          {isHovering === '2022' && (
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  delay: 0.5,
                  x: { duration: 1 },
                  opacity: { duration: 0.5 },
                  default: { ease: 'linear' },
                }}
                className={`threshold__hover ${
                  validatedThresholdYear.includes('2022') ? 'valid' : ''
                } `}
              >
                {t('APP.CUSTOMTHRESHOLD.INDEX.PH_5C968D', {
                  result: icMaxByYear && formatNumber(icMaxByYear['2022']),
                  defaultValue: 'Seuil max : {result} kg éq. CO₂/m²',
                })}
              </motion.div>
            </AnimatePresence>
          )}
        </div>

        <Popover
          disableScrollLock={true}
          // disableAutoFocus={true}
          // disableEnforceFocus={true}
          // disablePortal
          id={id}
          open={open && selectedModal === '2022'}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          {RenderPopoverBody()}
        </Popover>

        <div
          className={`threshold__icons--stroke ${
            validatedThresholdYear.includes('2025') ? 'valid' : ''
          }`}
        ></div>
        <div
          onClick={(e) => {
            handlePopoverOpen(e, '2025');
          }}
          className={
            validatedThresholdYear.includes('2025')
              ? 'threshold__icons--valid'
              : 'threshold__icons--invalid'
          }
        >
          <img
            src={validatedThresholdYear.includes('2025') ? validIcon : invalidIcon}
            alt=""
            onMouseOver={() => {
              setIsHovering('2025');
            }}
            onMouseOut={() => {
              setIsHovering(undefined);
            }}
          />
          {isHovering === '2025' && (
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  delay: 0.5,
                  x: { duration: 1 },
                  opacity: { duration: 0.5 },
                  default: { ease: 'linear' },
                }}
                className={`threshold__hover ${
                  validatedThresholdYear.includes('2025') ? 'valid' : ''
                } `}
              >
                {t('APP.CUSTOMTHRESHOLD.INDEX.PH_90AB9C', {
                  result: icMaxByYear && formatNumber(icMaxByYear['2025']),
                  defaultValue: 'Seuil max : {result}kg éq. CO₂/m²',
                })}
              </motion.div>
            </AnimatePresence>
          )}
        </div>
        <Popover
          disableScrollLock={true}
          id={id}
          open={open && selectedModal === '2025'}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          {RenderPopoverBody()}
        </Popover>
        <div
          className={`threshold__icons--stroke ${
            validatedThresholdYear.includes('2028') ? 'valid' : ''
          }`}
        ></div>
        <div
          onClick={(e) => {
            handlePopoverOpen(e, '2028');
          }}
          className={
            validatedThresholdYear.includes('2028')
              ? 'threshold__icons--valid'
              : 'threshold__icons--invalid'
          }
        >
          <img
            src={validatedThresholdYear.includes('2028') ? validIcon : invalidIcon}
            alt=""
            onMouseOver={() => {
              setIsHovering('2028');
            }}
            onMouseOut={() => {
              setIsHovering(undefined);
            }}
          />
          {isHovering === '2028' && (
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  delay: 0.5,
                  x: { duration: 1 },
                  opacity: { duration: 0.5 },
                  default: { ease: 'linear' },
                }}
                className={`threshold__hover ${
                  validatedThresholdYear.includes('2028') ? 'valid' : ''
                } `}
              >
                {t('APP.CUSTOMTHRESHOLD.INDEX.PH_AEEE36', {
                  result: icMaxByYear && formatNumber(icMaxByYear['2028']),
                  defaultValue: 'Seuil max : {result} kg éq. CO₂/m²',
                })}
              </motion.div>
            </AnimatePresence>
          )}
        </div>
        <Popover
          disableScrollLock={true}
          id={id}
          open={open && selectedModal === '2028'}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          {RenderPopoverBody()}
        </Popover>
        {screen !== 'energy' && (
          <>
            <div
              className={`threshold__icons--stroke ${
                validatedThresholdYear.includes('2031') ? 'valid' : ''
              }`}
            ></div>
            <div
              onClick={(e) => {
                handlePopoverOpen(e, '2031');
              }}
              className={
                validatedThresholdYear.includes('2031')
                  ? 'threshold__icons--valid'
                  : 'threshold__icons--invalid'
              }
            >
              <img
                src={
                  validatedThresholdYear.includes('2031') ? validIcon : invalidIcon
                }
                alt=""
                onMouseOver={() => {
                  setIsHovering('2031');
                }}
                onMouseOut={() => {
                  setIsHovering(undefined);
                }}
              />
              {isHovering === '2031' && (
                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      delay: 0.5,
                      x: { duration: 1 },
                      opacity: { duration: 0.5 },
                      default: { ease: 'linear' },
                    }}
                    className={`threshold__hover ${
                      validatedThresholdYear.includes('2031') ? 'valid' : ''
                    } `}
                  >
                    {t('APP.CUSTOMTHRESHOLD.INDEX.PH_BBE59E', {
                      result: icMaxByYear && formatNumber(icMaxByYear['2031']),
                      defaultValue: 'Seuil max : {result} kg éq. CO₂/m²',
                    })}
                  </motion.div>
                </AnimatePresence>
              )}
            </div>
            <Popover
              disableScrollLock={true}
              id={id}
              open={open && selectedModal === '2031'}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              {RenderPopoverBody()}
            </Popover>
          </>
        )}
      </div>
      <div className="threshold__dates">
        <div className="threshold__dates--year">2022</div>
        <div className="threshold__dates--year">2025</div>
        <div className="threshold__dates--year">2028</div>
        {screen !== 'energy' && <div className="threshold__dates--year">2031</div>}
      </div>
    </div>
  );
};

export default CustomThreshold;
