import { fetchProjectsByFolder } from '../../../apiCallFunctions/project/project';
import { useInfiniteQuery, useQuery } from 'react-query';

export const useGetProjectsByFolder = (
  isExistChildrenCompanies,
  selectedCompanyId,
  selectedPathId,
  projectTypeFilter,
  onSuccess
) => {
  return useInfiniteQuery(
    ['projectsByFolder', selectedCompanyId, selectedPathId, projectTypeFilter],
    (data) =>
      fetchProjectsByFolder(
        selectedCompanyId,
        selectedPathId,
        projectTypeFilter,
        data
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      enabled: !!selectedCompanyId,
      onError: (error) => {
        console.log('errors in getProjetcsByFolder', error?.message);
      },
      getNextPageParam: (lastPage, pages) => {
        const pageNumber = Math.ceil(lastPage.count / 10);

        if (pages.length < pageNumber) {
          return pages.length;
        } else {
          return undefined;
        }
      },
      retry: false,
    }
  );
};
