import React, { useState, useEffect, useMemo, useCallback } from 'react';
import countries from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import { Controller, Control } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

interface CountrySelectorProps {
  name: string;
  language?: string;
  control: Control<any>;
  onChange?: (country: { name: string; code: string } | null) => void;
  setValue: (name: string, value: string | Record<string, string>) => void;
  defaultValue?: string;
}

const CountrySelector: React.FC<CountrySelectorProps> = ({
  language = 'fr',
  name,
  control,
  onChange,
  setValue,
  defaultValue,
}) => {
  const [, setIsInitiated] = useState<boolean>(false);
  const [countryOptions, setCountryOptions] = useState<Record<string, string>>({});
  const [defaultCountryName, setDefaultCountryName] = useState<string>('');
  const countriesName = useMemo(
    () =>
      Object.keys(countryOptions).sort((a, b) =>
        a.localeCompare(b, language.split('-')[0])
      ),
    [countryOptions]
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (!countryOptions || !defaultValue) return;

    const countryName = countries.getName(defaultValue, language.split('-')[0]);

    if (!countryName || !countryOptions[countryName]) return;

    setIsInitiated((oldIsInitiated) => {
      if (!oldIsInitiated) {
        setDefaultCountryName(countryName);
        setValue(name, {
          name: countryName,
          code: countryOptions[countryName],
        });
        return !oldIsInitiated;
      }
      return oldIsInitiated;
    });
  }, [countryOptions]);

  const loadLocaleData = useCallback(async (lang: string) => {
    try {
      const localeData = await import(
        `i18n-iso-countries/langs/${language.split('-')[0]}.json`
      );
      countries.registerLocale(localeData.default);

      const namesToCodes: Record<string, string> = {};
      const names = countries.getNames(lang, { select: 'official' });

      Object.entries(names).forEach(([code, name]) => {
        namesToCodes[name] = countries.alpha2ToAlpha3(code) || '';
      });
      setCountryOptions(namesToCodes);
    } catch (error) {
      console.error('Could not load locale data', error);
    }
  }, []);

  useEffect(() => {
    loadLocaleData(language.split('-')[0]);
  }, [language, loadLocaleData]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: t('APP.GENERALFORM.COUNTRY.TITLE', {
          defaultValue: 'Veuillez renseigner le pays',
        }),
      }}
      render={({ field }) => (
        <>
          {defaultCountryName && (
            <Autocomplete
              {...field}
              disabled={false}
              id="checkboxes-tags-demo"
              defaultValue={defaultCountryName}
              options={countriesName}
              noOptionsText={t('APP.GENERALFORM.COUNTRY.NOT_FOUND', {
                defaultValue: 'Aucun résultat trouvé',
              })}
              onChange={(event, newValue) => {
                const country = newValue
                  ? {
                      name: newValue.toLowerCase(),
                      code: countryOptions[newValue],
                    }
                  : null;
                if (onChange) onChange(country);
                setValue(name, country);
              }}
              size={'small'}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('APP.GENERALFORM.COUNTRY.PLACEHOLDER', {
                    defaultValue: defaultCountryName,
                  })}
                  variant="outlined"
                  className={`researchBar`}
                />
              )}
            />
          )}
        </>
      )}
    />
  );
};

export default CountrySelector;
