import './productsMenu.scss';
//import 'antd/dist/antd.min.css';

import { AllotmentNodeDTO } from '../../models/ProjectDTO';
import { Menu, Tooltip } from 'antd';
import { useState } from 'react';
import Energie from '../../../assets/images/products/Energie.svg';
import Skeleton from '@material-ui/lab/Skeleton';
import Water from '../../../assets/images/products/watersmall.png';
import _ from 'lodash';

import React, { useEffect } from 'react';
import Couverture from '../../../assets/images/products/Couverture.svg';
import CFA from '../../../assets/images/products/CFA.svg';
import CFO from '../../../assets/images/products/CFO.svg';
import CloisonsPlafond from '../../../assets/images/products/CloisonsPlafond.svg';
import CVC from '../../../assets/images/products/CVC.svg';
import Déplacementintérieur from '../../../assets/images/products/DéplacementIntérieur.svg';
import Façades from '../../../assets/images/products/Façades.svg';
import Infrastructure from '../../../assets/images/products/Infrastructure.svg';
import Menuiserieextérieure from '../../../assets/images/products/Menuiserieexterieure.svg';
import Peinture from '../../../assets/images/products/Peinture.svg';
import Plomberie from '../../../assets/images/products/Plomberie.svg';
import Terre from '../../../assets/images/products/Terre.svg';
import VRD from '../../../assets/images/products/VRD.svg';
import Superstructure from '../../../assets/images/products/Superstructure.svg';
import Réseaux from '../../../assets/images/products/Réseaux.svg';
import EclairagesPublic from '../../../assets/images/products/EclairagePublic.svg';
import SystemesTriBagages from '../../../assets/images/products/SystemesTribagages.svg';
import SecuriteIncendie from '../../../assets/images/products/SecuriteIncendie.svg';
import TransportsEngins from '../../../assets/images/products/TransportsEngins.svg';
import IRVE from '../../../assets/images/products/IRVE.svg';
import PIF from '../../../assets/images/products/PIF.svg';
import OuvrageArt from '../../../assets/images/products/OuvrageArt.svg';
import Fluide from '../../../assets/images/products/Fluide.svg';
import EnergieSimplifiee from '../../../assets/images/products/EnergieSimplifiee.svg';
import defaultPic from '../../../assets/images/products/default.png';

type ProductsMenuProps = {
  updateProductsValue: Function;
  menuDatas: AllotmentNodeDTO[];
  allotments: { id: number; AllotmentNodes: AllotmentNodeDTO[] }[];
  fetchProducts: Function;
  requests: string[];
  nodeId: string;
  searchRequest: number;
};

const ProductsMenu = ({
  updateProductsValue,
  menuDatas,
  allotments,
  fetchProducts,
  requests,
  nodeId,
  searchRequest,
}: ProductsMenuProps): JSX.Element => {
  const [openMenus, setOpenMenus] = React.useState<number[]>([]);

  const handleRecursiveAllot = (
    allot: { id: number; AllotmentNodes: AllotmentNodeDTO[] },
    currentNode: AllotmentNodeDTO
  ) => {
    let children = allot.AllotmentNodes.filter(
      (node) => node.ParentId === currentNode.id && !node.isObsolete
    );

    currentNode.key = currentNode.id;
    currentNode.label = currentNode.name;
    currentNode.className = `class${currentNode.id}`;

    currentNode.children = children.filter((el) => el);

    if (children.length > 0) {
      children.forEach((child) => {
        handleRecursiveAllot(allot, child);
      });
    } else {
      delete currentNode.children;

      return;
    }
  };

  useEffect(() => {
    if (!menuDatas) {
      const allot: { id: number; AllotmentNodes: AllotmentNodeDTO[] } =
        allotments.find((el) => el.id === 1) || { id: 1, AllotmentNodes: [] };

      let allDatas: AllotmentNodeDTO[] = [];

      let baseNodes = allot.AllotmentNodes.filter(
        (el) => el.level === 0 && !el.isObsolete
      );

      baseNodes.forEach((el) => {
        console.log(baseNodes);

        handleRecursiveAllot(allot, el);

        el.icon = (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton
              variant="circle"
              width={20}
              height={20}
              style={{ marginRight: '10px' }}
            />
            <img
              style={{
                width: el.name === 'Eau' ? '22px' : '24px',
                height: '24px',
              }}
              src={
                el.name === 'Superstructure'
                  ? Superstructure
                  : el.name === 'CFA'
                  ? CFA
                  : el.name === 'Menuiseries ext.'
                  ? Menuiserieextérieure
                  : el.name === 'Plomberie'
                  ? Plomberie
                  : el.name === 'Cloison-Plafond'
                  ? CloisonsPlafond
                  : el.name === 'Infrastructure'
                  ? Infrastructure
                  : el.name === 'CVC'
                  ? CVC
                  : el.name === 'CFO'
                  ? CFO
                  : el.name === 'Façades'
                  ? Façades
                  : el.name === 'VRD-Aménagement'
                  ? VRD
                  : el.name === 'Revêtements-Peintures'
                  ? Peinture
                  : el.name === 'Déplacement int.'
                  ? Déplacementintérieur
                  : el.name === 'Terre'
                  ? Terre
                  : el.name === 'Couverture-étanchéité'
                  ? Couverture
                  : el.name === 'Energie'
                  ? Energie
                  : el.name === 'Eau'
                  ? Water
                  : el.name === 'Réseaux'
                  ? Réseaux
                  : el.name === 'Système tri-bagages'
                  ? SystemesTriBagages
                  : el.name === 'Éclairage public-SLT'
                  ? EclairagesPublic
                  : el.name === 'Sécurité Incendie'
                  ? SecuriteIncendie
                  : el.name === 'Transports et engins'
                  ? TransportsEngins
                  : el.name === "Ouvrage d'art"
                  ? OuvrageArt
                  : el.name === 'IRVE'
                  ? IRVE
                  : el.name === "Poste d'Inspection Filtrage"
                  ? PIF
                  : el.name === 'Fluides'
                  ? Fluide
                  : el.name === 'Energie simplifiée'
                  ? EnergieSimplifiee
                  : defaultPic
              }
              alt=""
            />
          </div>
        );

        allDatas = [...allDatas, el];
        allDatas.sort((a, b) => a.name.localeCompare(b.name));
      });

      console.log('appel update menu DATA', allDatas);
      updateProductsValue('menuDatas', allDatas);
    }
  }, []);

  const onClick = (event) => {
    updateProductsValue('offsetList', 0);

    if (event.key) {
      updateProductsValue('nodeId', event.key);

      updateProductsValue('requests', [...requests, event.key]);
      fetchProducts();
    } else {
      if (event.length > 0 && openMenus.length < event.length) {
        updateProductsValue('nodeId', event[event.length - 1]);

        updateProductsValue('requests', [...requests, event[event.length - 1]]);
        fetchProducts();
      }

      setOpenMenus(event);
    }
  };

  // useEffect(() => {

  //   const active = document.getElementById(`${nodeId}`);
  //   console.log(nodeId, active?.firstChild);

  //   if(active && active.firstChild) {

  //     active.firstChild.style.background = "#76e6cc50";

  //   }

  // }, [nodeId]);

  // const [isMenuDataLoaded, setIsMenuDataLoaded] = useState(false);

  // const handleMenuDataLoaded = () => {
  //   setIsMenuDataLoaded(true);
  // };

  const [menuDataItems, setMenuDataItems] = useState([]);

  useEffect(() => {
    if (menuDatas) {
      setMenuDataItems(
        menuDatas.map((menuData) => <Tooltip title={menuData.name}></Tooltip>)
      );
    }
  }, [menuDatas]);

  return (
    <div className="products-menu-wrapper">
      <Menu
        key={searchRequest}
        onOpenChange={onClick}
        style={{
          width: '100%',
        }}
        defaultSelectedKeys={['68']}
        mode="inline"
        items={menuDatas}
        onSelect={onClick}
      >
        {menuDataItems}
      </Menu>
    </div>
  );
};

export default ProductsMenu;
