import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import { ModalNooco } from '@photocarbone/nooco-ui';
import _ from 'lodash';
import MaterialIcon from 'material-icons-react';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import AppContext from '../../../AppContext';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuickSimulator, updateSimulator } from '../../../../actions/simulator';
import NoocoCheckbox from '../../../shared/NoocoCheckbox';
import { makeNamesForZone } from '../../../shared/utils';
import './switchEquipment.scss';

const SwitchEquipment = ({
  lockedSelectedEquipment,
  currentProject,
  updateFormValue,
  sheets,
  switchSelectedEquipment,
  replaceEquipment,
  currentIndicator,
  allGroups,
  zones,
  equipmentsAvailable,
  fetchProjectById,
  sheetsAvailable,
  selectedEquipments,
  displayedEquipments,
  setLockedSelectedEquipment,
  searchValue,
  isSimulator,
  handleCloseGraph,
  setSelectedEquipment,
}) => {
  const { nodeSearchValue } = useSelector((state) => state.projects);
  const ctx = useContext(AppContext);
  const dispatch = useDispatch();
  const [isSwitchEquipmentLoading, setIsSwitchEquipmentLoading] = useState(false);
  const { equipmentClicked } = useSelector((state) => state.simulator);
  const [openValidateChangeEquipementModal, setOpenValidateChangeEquipementModal] =
    useState(false);
  const { t } = useTranslation();

  const stream = async (data) => {
    await ctx.metrics(
      {
        data,
        streamName: ctx.config.kinesis.KINESIS_DATA_STREAM_NAME,
        partitionKey: 'FRONT',
      },
      'AWSKinesis'
    );
  };

  const streamSwitch = async (sheet) => {
    if (!sheet) {
      return;
    }

    if (sheet.type_fiche !== 'PEP') {
      // not a PEP sheet
      // not emitting since no manufacturer is present
      return;
    }

    const eventData = {
      eventCount: 1,
      eventType: 'click',
      eventContext: 'equipmentSwitch',
      actorType: 'user',
      actorId: ctx.user.attributes.email,
      targetType: 'Sheet',
      targetAction: 'switch',
      targetId: sheet.id,
      targetIdentity: sheet.name,
      targetParentId: sheet.CompanyId,
      targetParentIdentity: sheet.Company?.name,
      targetParentType: 'Company',
      metadata: {
        Project: {
          id: currentProject.id,
          name: currentProject.name,
        },
        Sheet: {
          id: sheet.id,
          name: sheet.name,
        },
        Company: {
          id: sheet.CompanyId,
          name: sheet.Company?.name,
        },
        User: {
          email: ctx.user.attributes.email,
        },
      },
    };

    await stream(eventData);
  };

  const switchEquipments = async () => {
    streamSwitch(switchSelectedEquipment);

    let selectedIds = [];
    let projectSheetsIds = [];

    if (Array.isArray(equipmentsAvailable)) {
      let checkedEquipment = equipmentsAvailable.filter((el) => el.checked);

      checkedEquipment.forEach((el) => {
        selectedIds = [...selectedIds, el.id];
        projectSheetsIds = [...projectSheetsIds, el.ProjectSheet.id];
      });
    }
    // dispatch(updateFormValue('isLoadingProjectDetails',
    //   true));

    await ctx.api
      .patchSwitchSheet(
        switchSelectedEquipment.id,
        selectedIds.length > 0 ? selectedIds : replaceEquipment.id,
        currentProject.id,
        projectSheetsIds.length > 0
          ? projectSheetsIds
          : replaceEquipment.ProjectSheet.id
      )
      .then(() => {
        const copy = { ...switchSelectedEquipment };

        console.log(replaceEquipment, 'REPLACE');

        copy.ProjectSheetId = replaceEquipment.ProjectSheet.id;
        if (!isSimulator) {
          updateFormValue('confirmReplaceEquipment', copy);
        } else {
          setLockedSelectedEquipment('');
          setSelectedEquipment('');
          updateFormValue('confirmReplaceEquipment', null);
        }
        updateFormValue('switchSelectedEquipment', null);
        updateFormValue('replaceEquipment', null);
        updateFormValue('equipmentsAvailable', null);
        updateFormValue('patchModeLoading', true);

        if (!isSimulator) {
          fetchProjectById(currentProject.id, currentIndicator.id);
        } else {
          handleCloseGraph();

          dispatch(fetchQuickSimulator());
        }
      });
  };

  useEffect(() => {
    if (lockedSelectedEquipment) {
      console.log(sheets, currentProject.Sheets, 'données');

      const selectedEquipment = sheets?.find(
        (el) => el.id === lockedSelectedEquipment.SheetId
      );

      if (!currentProject.Sheets?.find((el) => el.id === lockedSelectedEquipment)) {
        updateFormValue('switchSelectedEquipment', selectedEquipment);
      }

      if (!replaceEquipment) {
        if (!isSimulator) {
          const currentSheetToReplaceId = currentProject.Sheets.find(
            (sheet) =>
              sheet.ProjectSheet.AllotmentNodes.find(
                (node) => node.AllotmentId === 1
              )?.id === nodeSearchValue.id
          ).ProjectSheet.id;

          const sheetToReplace = sheets.find(
            (sheet) => sheet?.ProjectSheet?.id === currentSheetToReplaceId
          );

          updateFormValue('replaceEquipment', sheetToReplace);
          // updateFormValue('replaceEquipment',
          //   currentProject.Sheets.filter((el) => el.ProjectSheet.AllotmentNodes?.find((el) => el.id === nodeSearchValue.id ) ).length === 1 ? sheets.filter((el) => el.AllotmentNodes?.find((el) => el.id === nodeSearchValue.id ))[0] : null);
        } else if (isSimulator) {
          const currentSheetToReplaceId =
            equipmentClicked.AllotmentNode.PSAllot.ProjectSheetId;
          const sheetToReplace = sheets.find(
            (sheet) => sheet?.ProjectSheet?.id === currentSheetToReplaceId
          );

          updateFormValue('replaceEquipment', sheetToReplace);
        }
      }
    }
  }, [lockedSelectedEquipment]);

  useEffect(() => {
    let allDatas = [];

    let replace = replaceEquipment || switchSelectedEquipment;

    const equipments = currentProject.Sheets?.filter((el) =>
      el.ProjectSheet.AllotmentNodes.find((el) => el.id === nodeSearchValue.id)
    );

    if (equipments?.length > 1 && !equipmentsAvailable) {
      equipments.map((el) => {
        console.log('DANS LE MAP EQUIPMENTS');

        const currentZone = zones.find((zone) => zone.id === el.ProjectSheet.ZoneId);

        const name = makeNamesForZone(zones, currentZone, []);

        let newData = {
          id: el.ProjectSheet.id,
          groupedName: name,
          checked: replaceEquipment?.id === el.id ? true : false,
          ...el,
        };

        allDatas = [...allDatas, newData];
      });

      updateFormValue('equipmentsAvailable', allDatas);
    }
  }, [replaceEquipment, switchSelectedEquipment]);

  useEffect(() => {
    const sheetsAvailable = sheets.filter(
      (el) => el.type_fiche !== 'users' && el.type_fiche !== 'EXTRAPOLE'
    );

    updateFormValue(
      'sheetsAvailable',
      sheetsAvailable.sort((a, b) => (a.brand > b.brand ? 1 : -1))
    );

    return () => {
      updateFormValue('switchSelectedEquipment', null);
      updateFormValue('replaceEquipment', null);
      updateFormValue('equipmentsAvailable', null);
    };
  }, []);

  const handleReplaceEquipmentSubmit = () => {
    switchEquipments();
    // dispatch(updateFormValue('isLoadingProjectDetails',
    //   true));
    setIsSwitchEquipmentLoading(true);
    dispatch(updateSimulator('loadingGraph', true));
  };

  const handleEdit = (clickOption) => {
    if (Array.isArray(equipmentsAvailable)) {
      let copyEquipments = _.cloneDeep(equipmentsAvailable);

      let indexOption = copyEquipments.findIndex(
        (el) => el.ProjectSheet.id === clickOption.ProjectSheet.id
      );

      copyEquipments[indexOption] = {
        ...copyEquipments[indexOption],
        checked: !copyEquipments[indexOption].checked,
      };

      // change datas in card if one elem is checked

      if (copyEquipments.filter((el) => el.checked).length === 1) {
        updateFormValue(
          'replaceEquipment',
          copyEquipments.find((el) => el.checked)
        );
      } else if (copyEquipments.filter((el) => el.checked).length === 0) {
        updateFormValue('replaceEquipment', null);
      }

      const groupByZones = copyEquipments
        .filter((el) => el.checked)
        .reduce((group, equip) => {
          const { groupedName } = equip;

          group[groupedName] = group[groupedName] ?? [];
          group[groupedName].push(equip);

          return group;
        }, {});

      updateFormValue('displayedEquipments', groupByZones);
      updateFormValue('equipmentsAvailable', copyEquipments);
    }
  };

  const resetSwitch = () => {
    updateFormValue('displayedEquipments', null);
    updateFormValue('switchSelectedEquipment', null);
    updateFormValue('replaceEquipment', null);
    updateFormValue('equipmentsAvailable', null);
    setLockedSelectedEquipment();
  };

  useEffect(() => {
    if (equipmentsAvailable && replaceEquipment) {
      if (!displayedEquipments) {
        const copy = [...equipmentsAvailable];

        for (let i = 0; i < copy.length; i++) {
          if (copy[i].id === sheets[0].id) {
            copy[i].checked = true;
          } else {
            copy[i].checked = false;
          }
        }

        const groupByZones = copy
          .filter((el) => el.checked)
          .reduce((group, equip) => {
            const { groupedName } = equip;

            group[groupedName] = group[groupedName] ?? [];
            group[groupedName].push(equip);

            return group;
          }, {});

        updateFormValue('displayedEquipments', groupByZones);
      }

      const isChecked = equipmentsAvailable.find(
        (el) => el.id === replaceEquipment.id
      ).checked;

      if (!isChecked) {
        updateFormValue(
          'replaceEquipment',
          equipmentsAvailable.find(
            (el) => el.id !== replaceEquipment.id && el.checked
          )
        );
      }
    }
  }, [equipmentsAvailable]);

  return (
    <div className={isSimulator ? 'switch-wrapper simulator' : 'switch-wrapper'}>
      <div className="switch-equipment-wrapper">
        <div
          className="old-equipment equipment-card"
          style={{
            height: replaceEquipment ? '100%' : '30%',
            alignSelf: !replaceEquipment ? 'start' : null,
          }}
        >
          <div>
            <div className="header-equipment-card">
              <h3>
                {t('APP.SWITCHEQUIPMENT.INDEX.PH_6A8C02', {
                  defaultValue: 'Equipement à remplacer',
                })}
              </h3>

              {replaceEquipment &&
                replaceEquipment?.type_fiche !== 'users' &&
                replaceEquipment?.type_fiche !== 'EXTRAPOLE' && (
                  <div
                    style={{
                      borderRadius: '3.6338px',
                      color: 'white',
                      marginLeft: '1em',
                      padding: '0.05rem 0.4rem',
                      fontWeight: 'bold',
                      background:
                        replaceEquipment?.type_fiche === 'PEP' ||
                        replaceEquipment?.type_fiche === 'FDES'
                          ? '#83dfc5'
                          : '#363e63',
                      display: 'inline-block',
                    }}
                  >
                    {replaceEquipment?.type_fiche === 'REFURBISHED'
                      ? 'REEMPLOI'
                      : replaceEquipment?.type_fiche}
                  </div>
                )}
            </div>

            <div className="choice-equipment">
              <p>
                {t('APP.SWITCHEQUIPMENT.INDEX.PH_BC4F79', {
                  defaultValue: 'Sélectionner un équipement à remplacer',
                })}
              </p>
              <Autocomplete
                id="checkboxes-tags-demo"
                key={replaceEquipment || switchSelectedEquipment}
                value={replaceEquipment}
                disableCloseOnSelect
                options={equipmentsAvailable || []}
                groupBy={(option) => option.groupedName}
                renderOption={(option) => (
                  <div onClick={() => handleEdit(option)}>
                    <NoocoCheckbox checked={option.checked} />
                    {option.ProjectSheet?.customName ||
                      option.ProjectSheet?.originalName ||
                      option.libelle ||
                      option.name}
                  </div>
                )}
                onChange={(event, newValue) => {
                  const copy = _.cloneDeep(equipmentsAvailable);

                  if (!newValue && equipmentsAvailable.find((el) => el.checked)) {
                    for (let i = 0; i < copy.length; i++) {
                      copy[i].checked = false;
                    }

                    updateFormValue('equipmentsAvailable', copy);
                  }

                  updateFormValue('replaceEquipment', newValue || null);
                }}
                size={'small'}
                getOptionLabel={(option) =>
                  option.ProjectSheet?.customName ||
                  option.ProjectSheet?.originalName ||
                  option.libelle ||
                  option.name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="researchBar"
                  />
                )}
              />
            </div>
            {equipmentsAvailable?.filter((el) => el.checked).length >= 2 &&
              displayedEquipments && (
                <div>
                  <h4>
                    {t('APP.SWITCHEQUIPMENT.INDEX.PH_9B6A78', {
                      defaultValue: 'Equipements selectionnés',
                    })}
                  </h4>
                  <div className="list-equipments-checked">
                    {Object.keys(displayedEquipments).map(function (key) {
                      let value = displayedEquipments[key];

                      return value.map((el, i) => (
                        <>
                          {i === 0 && (
                            <p className="subtitle-switch">{el.groupedName} :</p>
                          )}
                          <p
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleEdit(el)}
                          >
                            {el.ProjectSheet.originalName || el.name}{' '}
                            <CloseIcon className="closeicon-switch" />
                          </p>
                        </>
                      ));
                    })}
                  </div>
                </div>
              )}
            {replaceEquipment &&
              (equipmentsAvailable?.filter((el) => el.checked).length < 2 ||
                !equipmentsAvailable) && (
                <>
                  <div className="main-info-equipment">
                    <p>
                      <span className="subtitle-switch">Nom :</span>{' '}
                      {replaceEquipment?.name}
                    </p>
                    {replaceEquipment?.ProjectSheet?.originalName && (
                      <p>
                        <span className="subtitle-switch">Libellé devis :</span>{' '}
                        {replaceEquipment?.ProjectSheet.customName ||
                          replaceEquipment?.ProjectSheet.originalName ||
                          replaceEquipment?.name}
                      </p>
                    )}
                    <p>
                      <span className="subtitle-switch">Quantité :</span>{' '}
                      {replaceEquipment?.ProjectSheet?.quantity}{' '}
                      {replaceEquipment?.MasterDataEquipment?.unit}
                    </p>
                    {zones.length > 2 && (
                      <p>
                        <span className="subtitle-switch">Destination :</span>{' '}
                        {zones.find(
                          (el) => el.id === replaceEquipment?.ProjectSheet?.ZoneId
                        )?.ParentId
                          ? zones.find(
                              (el) =>
                                el.id ===
                                zones.find(
                                  (el) =>
                                    el.id === replaceEquipment?.ProjectSheet?.ZoneId
                                )?.ParentId
                            ).name
                          : null}{' '}
                        {
                          zones.find(
                            (el) => el.id === replaceEquipment?.ProjectSheet?.ZoneId
                          )?.name
                        }
                      </p>
                    )}
                  </div>
                  <div className="carac-info-equipment">
                    <div className="main-caracs">
                      <h4>
                        {t('APP.SWITCHEQUIPMENT.INDEX.PH_0AC668', {
                          defaultValue: 'Caractéristiques principales',
                        })}
                      </h4>
                      {replaceEquipment?.Caracs?.filter(
                        (el) => el.neededOnCreation
                      ).map((carac) => (
                        <p key={carac.id}>
                          <span className="subtitle-switch">{carac.name} :</span>{' '}
                          {carac.SheetCarac.value[0]} {carac.unit}
                        </p>
                      ))}
                    </div>
                    {replaceEquipment?.Caracs?.filter(
                      (el) => !el.neededOnCreation && el.SheetCarac
                    ).length > 0 && (
                      <div>
                        <h4>
                          {t('APP.SWITCHEQUIPMENT.INDEX.PH_F2928E', {
                            defaultValue: 'Caractéristiques secondaires',
                          })}
                        </h4>
                        <div className="secondary-caracs">
                          {replaceEquipment?.Caracs?.filter(
                            (el) => !el.neededOnCreation && el.SheetCarac
                          ).map((carac) => (
                            <p key={carac.id}>
                              <span className="subtitle-switch">{carac.name} :</span>{' '}
                              {carac.SheetCarac?.value}
                            </p>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
          </div>
          {/* {replaceEquipment && (
            <div className="impact-equipment">
              <div className="infos-impact">
                <p>{currentIndicator.shortName}</p>
                <p style={{ fontWeight: '500' }}>{formatNumber(replaceEquipment?.acvResults?.total)}</p>
                <p>{currentIndicator.unit}/m²</p>
              </div>
            </div>
          )} */}
        </div>
        <div
          style={
            !switchSelectedEquipment || !replaceEquipment
              ? { alignSelf: 'start', marginTop: '4em' }
              : null
          }
        >
          <MaterialIcon icon="compare_arrows" size="large" />
        </div>

        <div
          className="new-equipment equipment-card"
          style={{
            height: switchSelectedEquipment ? '100%' : '30%',
            alignSelf: !switchSelectedEquipment ? 'start' : null,
          }}
        >
          <div>
            <div className="header-equipment-card">
              <h3>
                {t('APP.SWITCHEQUIPMENT.INDEX.PH_C21B50', {
                  defaultValue: 'Nouvel équipement',
                })}
              </h3>
              {switchSelectedEquipment &&
                switchSelectedEquipment?.type_fiche !== 'users' && (
                  <div
                    style={{
                      borderRadius: '3.6338px',
                      color: 'white',
                      marginLeft: '1em',
                      padding: '0.05rem 0.4rem',
                      fontWeight: 'bold',
                      background:
                        switchSelectedEquipment?.type_fiche === 'PEP' ||
                        switchSelectedEquipment?.type_fiche === 'FDES'
                          ? '#83dfc5'
                          : '#363e63',
                      display: 'inline-block',
                    }}
                  >
                    {switchSelectedEquipment?.type_fiche === 'REFURBISHED'
                      ? 'REEMPLOI'
                      : switchSelectedEquipment?.type_fiche}
                  </div>
                )}
            </div>

            <div className="choice-equipment">
              <p>
                {t('APP.SWITCHEQUIPMENT.INDEX.PH_7296D7', {
                  defaultValue: 'Sélectionner un nouvel équipement',
                })}
              </p>
              <Autocomplete
                id="checkboxes-tags-demo"
                options={sheetsAvailable || []}
                groupBy={(option) => option.brand}
                key={switchSelectedEquipment}
                value={switchSelectedEquipment}
                onChange={(event, newValue) => {
                  updateFormValue('switchSelectedEquipment', newValue || null);
                  // setIsEditing(true);
                  // setUrbanChoice(newValue.id);
                }}
                size={'small'}
                getOptionLabel={(option) => option.libelle || option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="researchBar"
                  />
                )}
              />
            </div>
            {switchSelectedEquipment && (
              <>
                <div className="main-info-equipment">
                  <p>
                    <span className="subtitle-switch">Nom :</span>{' '}
                    {switchSelectedEquipment?.name}
                  </p>
                  <p>
                    <span className="subtitle-switch">Marque :</span>{' '}
                    {switchSelectedEquipment?.brand}
                  </p>
                </div>
                <div className="carac-info-equipment">
                  <div className="main-caracs">
                    <h4>
                      {t('APP.SWITCHEQUIPMENT.INDEX.PH_C74737', {
                        defaultValue: 'Caractéristiques principales',
                      })}
                    </h4>
                    {switchSelectedEquipment?.Caracs.filter(
                      (el) => el.neededOnCreation
                    ).map((carac) => (
                      <p key={carac.id}>
                        <span className="subtitle-switch">{carac.name} :</span>{' '}
                        {carac.SheetCarac?.value} {carac.unit}
                      </p>
                    ))}
                  </div>
                  {switchSelectedEquipment?.Caracs.filter(
                    (el) =>
                      !el.neededOnCreation && el.SheetCarac && el.SheetCarac?.value
                  ).length > 0 && (
                    <div>
                      <h4>
                        {t('APP.SWITCHEQUIPMENT.INDEX.PH_1AE135', {
                          defaultValue: 'Caractéristiques secondaires',
                        })}
                      </h4>
                      <div className="secondary-caracs">
                        {switchSelectedEquipment?.Caracs.filter(
                          (el) =>
                            !el.neededOnCreation &&
                            el.SheetCarac &&
                            el.SheetCarac?.value
                        ).map((carac) => (
                          <p key={carac.id}>
                            <span className="subtitle-switch">{carac.name} :</span>{' '}
                            {carac.SheetCarac?.value} {carac.unit}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {/* {switchSelectedEquipment && (
            <div className="impact-equipment">
              <div className="infos-impact">
                <p>{currentIndicator.shortName}</p>
                <p style={{ fontWeight: '500' }}>32,3</p>
                <p>{currentIndicator.unit}/m²</p>
              </div>

            </div>
          )} */}
          <ModalNooco
            title={t('APP.SWITCHEQUIPMENT.INDEX.PH_D35ADF', {
              defaultValue: "Suppression de l'ensemble",
            })}
            isOpen={openValidateChangeEquipementModal}
            cancelFunction={() => setOpenValidateChangeEquipementModal(false)}
            validateFunction={() => {
              handleReplaceEquipmentSubmit();
            }}
            closeOnOutsideClick={false}
            closeIcon={false}
          >
            <div>
              {t('APP.SWITCHEQUIPMENT.INDEX.PH_8BA481', {
                defaultValue:
                  "Vous êtes sur le point de modifier plusieurs lignes d'équipements / matériaux de votre projet, voulez-vous continuer?",
              })}
            </div>
          </ModalNooco>
        </div>
      </div>
      <div className="buttons-action">
        <Button
          className="rounded-pill  btn-block"
          variant="success"
          size="sm"
          onClick={resetSwitch}
          disabled={isSwitchEquipmentLoading}
        >
          {t('APP.SWITCHEQUIPMENT.INDEX.PH_9960FE', {
            defaultValue: 'Annuler',
          })}
        </Button>
        <Button
          className="rounded-pill  btn-block"
          variant="success"
          size="sm"
          disabled={!replaceEquipment || isSwitchEquipmentLoading}
          onClick={() => {
            if (equipmentsAvailable?.filter((el) => el.checked).length >= 2) {
              setOpenValidateChangeEquipementModal(true);
            } else {
              handleReplaceEquipmentSubmit();
            }
          }}
        >
          {t('APP.SWITCHEQUIPMENT.INDEX.PH_C8BF3F', {
            defaultValue: 'Confirmer',
          }) + (isSwitchEquipmentLoading ? '...' : '')}
        </Button>
      </div>
    </div>
  );
};

export default SwitchEquipment;
