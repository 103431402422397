import './headerImport.scss';
import { Button } from '@photocarbone/nooco-ui';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { StateDTO } from '../../models/GeneralDTO';
import {
  deleteImport,
  fetchImportDatas,
  refreshIa,
  resetDataAfterFilterChanged,
  sendImport,
} from '../../../actions/import';
import { isNullishCoalesce } from 'typescript';
import { returnCountOfImportRows } from '../../projects/Import/ImportList';
import { updateFormValue } from '../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ImportAbandonConfirm from '../ImportAbandonConfirm/ImportAbandonConfirm.jsx';
import Loader from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import MultipleActionsButton from '../MultipleActionsButton';
import React, { useEffect, useRef, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@mui/material/TextField';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ia from '../../../assets/images/mapping/iaMap.png';
import valid from '../../../assets/images/mapping/ref3.png';
import yellow from '../../../assets/images/mapping/yellow.svg';
import { useTranslation } from 'react-i18next';

const offsetQueueRequest = 15;

const HeaderImport = ({
  mapping,
  groups,
  setSnackbarMessage,
  currentRowIndexDisplayed,
}) => {
  const [confirmationDelete, setConfirmationDelete] = useState(false);

  const { currentProject } = useSelector((state: StateDTO) => state.projects);
  const {
    importDate,
    currentImport,
    filterOn,
    linesSelected,
    importDatas,
    goodLines,
    waitLines,
    badLines,
    dataError,
    archivedLines,
    loadingProcess,
    displayArchived,
    isLoadMoreImports,
    isSearchingData,
    searchSourceValue,
    isLoad,
    isImportLoading,
  } = useSelector((state: StateDTO) => state.imports);
  const { counts } = useSelector((state) => state.imports.importDatas);

  const dispatch = useDispatch();

  // Onclick on other import get his datas
  const fetchOtherImportData = (idImport) => {
    dispatch(updateFormValue('offset', 0));
    dispatch(updateFormValue('filterOn', []));
    dispatch(updateFormValue('currentImport', idImport));
    dispatch(updateFormValue('isImportLoading', true));

    dispatch(fetchImportDatas(currentProject.id, idImport));
  };

  const returnFormatTabName = (file, string) => {
    if (string) {
      return `${file.fileName} - ${file.tabName} - ${file.date} à ${file.hour} par ${file.email}`;
    }

    return (
      <React.Fragment>
        <span
          key={goodLines || waitLines || badLines}
          style={{ fontWeight: 'bold' }}
        >
          {file.fileName} - {file.tabName}
        </span>{' '}
        - {file.date} à {file.hour} par {file.email} (
        {goodLines + waitLines + badLines})
      </React.Fragment>
    );
  };

  const handleConfirmImport = () => {
    // setDisabled(true);

    dispatch(updateFormValue('loadingProcess', true));
    dispatch(sendImport());
  };

  // gestion modale pour abadonner l'import

  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const currentPage = document.body.textContent.includes('Import en cours');
  const { t } = useTranslation();

  let totalCount = returnCountOfImportRows(
    counts?.actualCount,
    counts?.Validated,
    counts?.notValidated,
    counts?.userValidated,
    counts?.isArchived,
    filterOn,
    displayArchived,
    isSearchingData
  );

  // Fetch data
  useUpdateEffect(() => {
    if (
      !isLoadMoreImports &&
      !isImportLoading &&
      dataError.length === 0 &&
      totalCount !== 0
    ) {
      dispatch(fetchImportDatas(null, currentImport, null, null, 0));
    } else if (
      !isLoadMoreImports &&
      !isImportLoading &&
      currentRowIndexDisplayed.current > dataError.length + offsetQueueRequest &&
      dataError.length < totalCount
    ) {
      dispatch(fetchImportDatas(null, currentImport));
    }
  }, [dataError]);

  const handleFilterChanged = () => {
    dispatch(resetDataAfterFilterChanged());

    if (isSearchingData && totalCount === 0) {
      dispatch(fetchImportDatas(null, currentImport, null, null, 0));
    }
  };

  const handleDeleteImport = () => {
    setShowModal(true);
  };

  const handleConfirmAbandon = () => {
    dispatch(deleteImport());
    setShowModal(false);

    if (
      importDate.filter((importFile) => importFile.id !== importDatas.id).length ===
      0
    ) {
      const newUrl =
        window.location.pathname +
        window.location.search.replace(/page=5/, 'page=1');

      history.push(newUrl);
    }
  };

  const handleCancelAbandon = () => {
    setShowModal(false);
  };

  // fin gestion modale pour abadonner l'import

  const sortByDateAndAlphaIfDateEquals = (importData) => {
    // Sort by date and hour first
    importData.sort((a, b) => {
      const datea = new Date(a.date);
      const dateb = new Date(b.date);

      const dateComparison = dateb - datea;

      if (dateComparison === 0) {
        const hourComparison = b.hour.localeCompare(a.hour);

        return hourComparison !== 0
          ? hourComparison
          : a.tabName.localeCompare(b.tabName);
      }

      return dateComparison;
    });

    return importData;
  };

  let orderedImportDate = sortByDateAndAlphaIfDateEquals(importDate);

  // Adding button + tooltip on import load

  const LoadingButtonWithTooltip = ({ onClick, disabled, showSpinner }) => {
    const buttonContent = (
      <Button
        style={{ width: '100%', fontSize: '0.7rem', padding: '2px !important' }}
        onClick={onClick}
        disabled={disabled}
        className="purple-button"
        text={
          showSpinner ? (
            <CircularProgress size="1rem" />
          ) : (
            t('APP.HEADERIMPORT.INDEX.PH_E3678B', {
              defaultValue: 'Importer les lignes validées',
            })
          )
        }
      />
    );

    return (
      <Tooltip
        title={
          !importDatas?.ProjectImportElems?.some(
            (importElem) => importElem.statusCode === 'userValidated'
          )
            ? 'Veuillez valider des lignes ci-dessous pour les importer sur votre projet'
            : showSpinner
            ? t('APP.HEADERIMPORT.INDEX.PH_178A22', {
                defaultValue:
                  'Vos lignes sont en cours d’import, veuillez patienter...',
              })
            : t('APP.HEADERIMPORT.INDEX.PH_2BA2D6', {
                defaultValue: 'Importer toutes vos lignes validées',
              })
        }
        placement="top"
      >
        <span>{buttonContent}</span>
      </Tooltip>
    );
  };

  return (
    <>
      <Snackbar
        open={confirmationDelete}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert severity="error">
          <div>
            <p style={{ margin: 0 }}>
              {t('APP.HEADERIMPORT.INDEX.PH_F5BBA1', {
                defaultValue: 'Confirmez-vous vouloir supprimer cet import ?',
              })}
            </p>
            <div className="header-import-buttons">
              <Button
                className="close-button"
                onClick={() => setConfirmationDelete(false)}
                text={t('APP.HEADERIMPORT.INDEX.PH_E2E7D6', {
                  defaultValue: 'Annuler',
                })}
              />
              <Button
                className="purple-button"
                onClick={() => {
                  dispatch(deleteImport());
                  setConfirmationDelete(false);
                }}
                text={t('APP.HEADERIMPORT.INDEX.PH_5CC389', {
                  defaultValue: 'Confirmer',
                })}
              />
            </div>
          </div>
        </MuiAlert>
      </Snackbar>
      <div className="header-import-wrapper">
        <div className="import-choice">
          <p className="subtitle">
            {t('APP.HEADERIMPORT.INDEX.PH_31E195', {
              defaultValue: 'Fichier source',
            })}
          </p>
          <Autocomplete
            className="masterdata-autocomplete"
            options={orderedImportDate}
            key={currentImport}
            value={importDate.find((el) => el.id === currentImport)}
            onChange={(e, newValue) =>
              newValue ? fetchOtherImportData(newValue.id) : null
            }
            getOptionLabel={(option) => returnFormatTabName(option, true)}
            closeIcon={<span></span>}
            renderOption={(option) => {
              return returnFormatTabName(option, isNullishCoalesce);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                style={{ border: '1px solid #DADADA', borderRadius: '5px' }}
                className="researchBar"
              />
            )}
          />
        </div>
        <div className="header-imports-filters">
          <div className="filters-action-lines">
            <div
              className={`choice-filters ${
                filterOn.includes('userValidated') ? 'active-filter' : ''
              }`}
              onClick={() => {
                filterOn.includes('userValidated')
                  ? dispatch(
                      updateFormValue(
                        'filterOn',
                        filterOn.filter((filter) => filter !== 'userValidated')
                      )
                    )
                  : dispatch(
                      updateFormValue('filterOn', [...filterOn, 'userValidated'])
                    );
                handleFilterChanged();
              }}
            >
              <img src={valid} alt />
              <p>
                {t(
                  'APP.HEADERIMPORT.INDEX.PH_434247',

                  {
                    mapping,
                    goodLines: goodLines,
                    defaultValue:
                      'Afficher/masquer {mapping, select, true {les mappings validés} other {les lignes validées}} ({goodLines})',
                  }
                )}
              </p>
            </div>
            <div
              className={`choice-filters ${
                filterOn.includes('Validated') ? 'active-filter' : ''
              }`}
              onClick={() => {
                filterOn.includes('Validated')
                  ? dispatch(
                      updateFormValue(
                        'filterOn',
                        filterOn.filter(
                          (filter) => filter !== 'Validated' && filter !== 'error'
                        )
                      )
                    )
                  : dispatch(
                      updateFormValue('filterOn', [
                        ...filterOn,
                        'Validated',
                        'error',
                      ])
                    );
                handleFilterChanged();
              }}
            >
              <img src={yellow} alt />
              <p>
                {t(
                  'APP.HEADERIMPORT.INDEX.PH_72E742',

                  {
                    mapping,
                    result: waitLines + badLines,
                    defaultValue:
                      'Afficher uniquement {mapping, select, true {les mappings non validés} other {les lignes non validées}} ({result})',
                  }
                )}
              </p>
            </div>
          </div>
          <div>
            <div
              className={`choice-filters ${displayArchived ? 'active-filter' : ''}`}
              onClick={() => {
                // setDisplayArchived(!displayArchived);
                // need fast development before mep.
                dispatch(updateFormValue('displayArchived', !displayArchived));
                handleFilterChanged();
              }}
            >
              <VisibilityIcon className="img-visibility" />
              <p>
                {t('APP.HEADERIMPORT.INDEX.PH_5F7914', {
                  archivedLines,
                  defaultValue:
                    'Afficher/masquer les lignes archivées ({archivedLines})',
                })}
              </p>
            </div>
            <div className="choice-filters" onClick={() => dispatch(refreshIa())}>
              <img src={ia} alt />
              <>
                <Tooltip
                  title={t('APP.HEADERIMPORT.INDEX.PH_1015D0', {
                    defaultValue: 'Uniquement sur les lignes non validées',
                  })}
                >
                  <p>
                    {t('APP.HEADERIMPORT.INDEX.PH_FE3C18', {
                      defaultValue: 'Relancer la reconnaissance automatique',
                    })}
                  </p>
                </Tooltip>
              </>
            </div>
          </div>
        </div>

        <div style={{ width: '200px' }}>
          {!mapping && (
            <LoadingButtonWithTooltip
              onClick={() => {
                importDatas.ProjectImportElems.some(
                  (importElem) =>
                    importElem.statusCode === 'userValidated' &&
                    !groups.type.find(
                      (ele) =>
                        ele.id === importElem.AllotmentNode?.MasterDataEquipmentId
                    )
                )
                  ? setSnackbarMessage(
                      t('APP.HEADERIMPORT.INDEX.PH_66D40E', {
                        defaultValue:
                          'Veuillez rajouter le/les lot(s) nécéssaire dans votre projet',
                      })
                    )
                  : handleConfirmImport();
              }}
              disabled={importDatas?.counts?.userValidated === 0 || loadingProcess}
              showSpinner={loadingProcess}
            />
          )}

          <Tooltip
            title={t('APP.HEADERIMPORT.INDEX.PH_66BE96', {
              defaultValue: "Cliquer ici pour abandonner l'import de votre fichier",
            })}
          >
            <span>
              <Button
                style={{
                  width: '100%',
                  fontSize: '0.7rem',
                  padding: '2px !important',
                  marginTop: '0.5em',
                }}
                onClick={() => handleDeleteImport()}
                className="delete-button"
                text={t('APP.HEADERIMPORT.INDEX.PH_89C697', {
                  defaultValue: 'Abandonner',
                })}
              />
            </span>
          </Tooltip>
          <ImportAbandonConfirm
            open={showModal}
            onConfirm={handleConfirmAbandon}
            onCancel={handleCancelAbandon}
          />
        </div>

        <div style={{ width: '200px' }}>
          {isLoad ? (
            <Loader
              variant="indeterminate"
              disableShrink
              style={{ color: '#73b7ec', marginLeft: '3em' }}
              size={25}
              thickness={4}
            />
          ) : (
            <MultipleActionsButton
              isMapping={mapping}
              linesSelected={linesSelected}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderImport;
