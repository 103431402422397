import { v4 as uuidv4 } from 'uuid';

import {
  CHANGE_MODELING_EQUIPMENTS,
  CHANGE_VALUE_EQUIPMENTS,
  UPDATE_DATASINIES,
  UPDATE_EQUIPMENTS_ADD_LINE,
} from '../actions/equipments';

import { LOAD_PROJECT_BY_ID } from '../actions/projects';

const initialState = {
  showAddEquipModal: false,
  isAddEquipModalLoading: false,
  addEquipModalType: 'equip',
  projectCompatibleMasterDatas: {},
  breadCrumbInfos: {},
  isBreadcrumbValid: false,
  equipmentToAddCaracs: {},
  userCaracs: [],
  quantity: 0,
  importModal: false,
  selectedRowKeys: [],
  groupBy: 'import',
  filterBy: ['project'],
  referenceSurfaceTag: 'building',
  searchBarValue: '',
  dataTable: [],
  searchText: '',
  filterLots: [],
  columns: [],
  isShown: null,
  hideColumns: [],
  isLoadingProject: false,
  iniesAdvanceLink: null,
  errorIdInies: false,
  scrollPosition: null,
  elementsTree: [],
  addDisplay: null,
  currentDestination: null,
  datasinies: [],
  forfaitList: [],
  datasForfait: [],
  edecUsername: '',
  edecPassword: '',
  edecChoice: null,
  filterEdec: [],
  displaySpinner: false,
  composantVideForm: {},
  simplifiedEnergyLines: [
    {
      id: 0,
      AllotmentNodeId: null,
      quantity: null,
      caracs: [],
      unit: 'kWh',
      origin: {
        type: 'project',
        sourceId: null,
        uniqueId: uuidv4(),
      },
    },
  ],

  modeling: {
    isCarbonWeightSelected: null,
    caracteristicForm: {
      equipmentName: null,
      quantity: null,
      unit: null,
      lifeSpan: null,
      comment: null,
      labelQuote: null,
    },
    carbonWeightForm: {
      product: null,
      installation: null,
      usage: null,
      endOfLife: null,
      profit: null,
    },
    materialLines: [],
    totalWeight: null,
    manufacturedRate: 25,
    addMaterialLine: {
      allotmentNode: null,
      percent: null,
    },
  },
  allFiltersFromTable: null,
  ProjectSheetsToPatch: [],
  dataToPatchFromList: {
    ProjectSheetsToPatch: [],
  },
  visibleFilters: [],
  isUserStartEditColumns: false,
  caracValuesInContributorList: {},
};

const equipmentsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_DATASINIES:
      return {
        ...state,
        datasinies: action.payload,
      };

    case LOAD_PROJECT_BY_ID:
      return {
        ...state,
        isLoadingProject: false,
        tableEditMode: action.importToLdc || state.tableEditMode,
        isUserStartEditColumns: false,
      };
    case CHANGE_VALUE_EQUIPMENTS:
      return {
        ...state,
        [action.name]: action.value,
      };
    case UPDATE_EQUIPMENTS_ADD_LINE:
      return {
        ...state,
        modeling: {
          ...state.modeling,
          addMaterialLine: {
            ...state.modeling.addMaterialLine,
            [action.name]: action.value,
          },
        },
      };
    case CHANGE_MODELING_EQUIPMENTS:
      return {
        ...state,
        modeling: {
          ...state.modeling,
          [action.name]: action.value,
        },
      };
    default:
      return state;
  }
};

export default equipmentsReducer;
