import React, { RefCallback, useCallback, useRef, useState } from 'react';
import { ConstantKeysWidgets, Widget } from '../index';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from '../../../../models/DragConstants';
import './widgetItem.scss';
import { useDispatch, useSelector } from 'react-redux';
import { StateDTO } from '../../../../models/GeneralDTO';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { updateCheckValue } from '../../../../../actions/report';
import { klona } from 'klona';
import EditIcon from '@material-ui/icons/Edit';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import HelpIcon from '@material-ui/icons/Help';
interface WidgetItemProps {
  widget: Widget;
  index: number;
  moveCard?: Function;
  dragHover: { current: number } | null;
  isInDropzone: boolean;
  setOpenTextAreaModal: Function;
}

export const moveCardWrapper = (
  moveCard: Function,
  dragIndex: number,
  hoverIndex: number,
  widgetsDropped: Widget[],
  widget: Widget
) => {
  moveCard(dragIndex, hoverIndex, widgetsDropped, widget);
  widget.index = hoverIndex;
};

function WidgetItem({
  widget,
  index,
  moveCard,
  dragHover,
  isInDropzone = false,
  setOpenTextAreaModal,
}: WidgetItemProps) {
  const { widgetsInDropZone } = useSelector((state: StateDTO) => state.report);
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.Widgets,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (item && !item.id && dragHover) {
        dragHover.current = hoverIndex;
        return;
      }

      if (dragIndex === hoverIndex || (item && !item?.id)) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCardWrapper(moveCard, dragIndex, hoverIndex, widgetsInDropZone, item);
    },
  });
  const [{ opacity }, drag] = useDrag(
    () => ({
      type: ItemTypes.Widgets,
      item: { ...widget, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [widget, ItemTypes.Widgets]
  );
  drag(drop(ref));

  let IconComponent = widget.icon;

  const handleDeleteWidgetInDropZone = (index: number) => {
    let updatedWidgets = klona(widgetsInDropZone);
    updatedWidgets.splice(index, 1);
    dispatch(updateCheckValue('widgetsInDropZone', updatedWidgets));
  };

  return (
    <div
      className="widget-item"
      style={{ opacity }}
      ref={ref}
      data-handler-id={handlerId}
    >
      {isInDropzone && (
        <HighlightOffIcon
          className="close-widget-item"
          onClick={() => handleDeleteWidgetInDropZone(index)}
        />
      )}
      <div className="drag-indicator">
        <DragIndicatorIcon />
      </div>
      <div className="icon-wrapper">
        <IconComponent />
      </div>
      <div className="name-widget-wrapper">
        <p>{widget?.name}</p>
        <p style={{ fontWeight: '300' }}>{widget.description}</p>
      </div>
      <div className="action-available">
        {isInDropzone && widget.key === ConstantKeysWidgets.TEXTAREA && (
          <EditIcon onClick={() => setOpenTextAreaModal(widget)} />
        )}
        <HelpIcon
          onClick={() => dispatch(updateCheckValue('widgetTypePreview', widget.key))}
        />
      </div>
    </div>
  );
}

export default WidgetItem;
