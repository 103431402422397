import { FavoritesDTO } from '../app/models/FavoritesDTO';

export const UPDATE_FAVORITES_VALUE = 'UPDATE_FAVORITES_VALUE';
export const FETCH_INITIAL_FAVORITES = 'FETCH_INITIAL_FAVORITES';
export const LOAD_INITIAL_FAVORITES = 'LOAD_INITIAL_FAVORITES';
export const TOGGLE_FAVORITES = 'TOGGLE_FAVORITES';
export const DRAG_AND_DROP_CONTRIBUTORS = 'DRAG_AND_DROP_CONTRIBUTORS';
export const FETCH_FAVORITES = 'FETCH_FAVORITES';
export const LOAD_FAVORITES = 'LOAD_FAVORITES';
export const RESPONSE_TOGGLE_FAVORITES = 'RESPONSE_TOGGLE_FAVORITES';

export const updateFavoritesValue = (name: string, value: any) => ({
  type: UPDATE_FAVORITES_VALUE,
  name,
  value,
});

export const fetchInitialFavorites = () => ({
  type: FETCH_INITIAL_FAVORITES,
});

export const loadInitialFavorites = (
  key: string,
  data: { rows: {}[]; count: number }
) => ({
  type: LOAD_INITIAL_FAVORITES,
  key,
  data,
});

export const toggleFavorites = (SheetId: number, company: boolean | null) => ({
  type: TOGGLE_FAVORITES,
  SheetId,
  company,
});

export const dragAndDropContributors = (item: FavoritesDTO) => ({
  type: DRAG_AND_DROP_CONTRIBUTORS,
  item,
});

export const fetchFavorites = (scope: string, tabValue: number, page: number) => ({
  type: FETCH_FAVORITES,
  scope,
  tabValue,
  page,
});

export const loadFavorites = (scope: string, data: FavoritesDTO[]) => ({
  type: LOAD_FAVORITES,
  scope,
  data,
});

export const responseToggleFavorites = (
  scope: string,
  data: FavoritesDTO,
  method: string
) => ({
  type: RESPONSE_TOGGLE_FAVORITES,
  scope,
  data,
  method,
});
