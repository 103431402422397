import { Auth } from 'aws-amplify';
import { TYPE_MAPPINGREF } from '../globalVariable/typeProject';
import Cookies from 'js-cookie';
import axios from 'axios';
import dayjs from 'dayjs';
import i18nInstance from '../../i18n';
import moment from 'moment';
import { fetchAuthSession } from 'aws-amplify/auth';

let isAuthError = false;

function handleAuthError() {
  const { t } = useTranslation();

  if (!isAuthError) {
    // afficher l'alerte uniquement si elle n'a pas déjà été affichée
    isAuthError = true;
    window.location.assign('/login');
    alert(
      t('APP.SERVICES.API.PH_0E6CB7', {
        defaultValue: 'Votre session a expiré. Veuillez vous reconnecter.',
      })
    );
  }
}

class API {
  constructor(userManagement, adminApi) {
    this.requester = axios.create();

    const BASE_URL = userManagement
      ? process.env.REACT_APP_USER_MANAGEMENT
      : adminApi
      ? process.env.REACT_APP_ADMIN_URL
      : process.env.REACT_APP_BASE_URL;

    this.requester.defaults.baseURL = BASE_URL;

    const getLangIndex = localStorage.getItem('i18nLanguage') || 'fr';

    this.requester.interceptors.request.use(async (config) => {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};

      this.requester.interceptors.response.use(
        function (response) {
          // console.log('response',
          //   response);
          // if (response.status === 400) {
          //   alert('Votre session a expiré. Veuillez vous reconnecter.');
          //   window.location.href = '/login';
          // }

          return response;
        },
        function (error) {
          // console.log('error',
          //   error);
          // console.log(error === "No current user");
          if (
            error?.message === 'Refresh Token has been revoked' ||
            error === 'No current user'
          ) {
            // alert('Votre session a expiré. Veuillez vous reconnecter.');
            // window.location.assign('/login');

            handleAuthError();
            // Link.to('/login');
          }

          return Promise.reject(error);
        }
      );

      config.headers.authorization = idToken;
      config.headers.access = accessToken;
      config.headers['X-Frame-Options'] = 'DENY';
      config.headers['Cache-Control'] = 'no-cache';
      config.headers['X-Content-Type-Options'] = 'nosniff';
      config.headers['Accept-Language'] = getLangIndex;

      return config;
    });
  }

  async register(firstname, lastname, email, idCompany, password) {
    return this.requester
      .post('/register', {
        email,
        password,
        idCompany,
        lastname,
        firstname,
      })
      .then((response) => response.status)
      .catch((e) => e);
  }

  async authenticate(email, password, extended) {
    const response = await this.requester.post('/login', {
      email,
      password,
      extended,
    });

    return response;
  }

  async logout() {
    // console.log('logout');

    return this.requester
      .post('/logout')
      .then((response) => {
        // console.log('response');

        return response;
      })
      .catch((e) => {
        // console.log('e');

        return e;
      });
  }

  async isAuthenticated() {
    const response = await this.requester.get('/users/me');

    return response.status === 200;
  }

  async me() {
    const response = await this.requester.get('/me');

    return response.data;
  }

  async getAllCompanies() {
    const response = await this.requester.get('/companies');

    return response.data;
  }

  async getProjects(
    offset,
    projectInput,
    organisationFilters,
    companyFilters,
    companyId,
    typeProject
  ) {
    let urlCompany = '';
    let urlOrganisation = '';

    let dataCompany = companyFilters ? [...companyFilters] : [];
    let dataOrganisation = companyFilters ? [...companyFilters] : [];

    dataCompany.forEach((filter) => {
      urlCompany += `&companyId=${filter.id}`;
    });

    dataOrganisation.forEach((filter) => {
      urlOrganisation += `&organisationId=${filter.id}`;
    });

    if (companyId) {
      urlCompany = `&companyId=${companyId}`;
    }

    if (typeProject) {
      typeProject.map((type) => {
        urlCompany += `&ProjectTypeId=${type.id}`;
      });
    }

    let search = encodeURIComponent(projectInput);

    return await this.requester.get(
      `/projects?offset=${offset}&limit=10&search=${
        projectInput && !companyId ? search : ''
      }${urlCompany}${urlOrganisation}`,
      {}
    );
  }

  async getProjectsList(queryParams) {
    return await this.requester
      .get(`/projects?${queryParams}`)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        throw e;
      });
  }

  async getProjectRseeFile(id) {
    return await this.requester.get(`/project/${id}/rsee/file`, {});
  }

  // TODO catch
  async getProjectById(id, indicatorId, isRe2020 = null) {
    let config = {
      params: { isRe2020, indicatorId },
    };

    return this.requester.get(`/project/${id}`, config).then((response) => response);
  }

  async patchProject(body, isTemp = false) {
    if (isTemp) body.Address = null;

    if (body.permitDate) {
      console.log(body.permitDate, 'permitDate');
      body.permitDate =
        body.permitDate.length > 11
          ? body.permitDate
          : dayjs(body.permitDate, 'L').format();
    }

    return this.requester.patch('/project', body);
  }

  async deleteProject(id) {
    const body = { id };

    return this.requester
      .delete('/project', {
        data: body,
      })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  async createVariant(
    id,
    name,
    CompanyId,
    exploitData,
    isAddVariantSelected,
    keepLinkToCurrentProject
  ) {
    const body = {
      id,
      name,
      CompanyId,
      ...exploitData,
      isAddVariantSelected,
      keepLinkToCurrentProject,
    };

    if (isAddVariantSelected) {
      body.CompanyId = null;
      body.ProjectTypeId = null;
    }

    if (exploitData) {
      body.ProjectTypeId = 5;
    } else {
      body.ProjectTypeId = null;
    }

    return this.requester.post('/project/variant', {
      data: body,
    });
  }
  async getProjectEquipements(id) {
    return this.requester
      .get(`/project/${id}/equipment`, {})
      .then((response) => response);
  }

  async getCategories() {
    return this.requester.get(`/categories`, {}).then((response) => response);
  }

  async createProject(data) {
    let param = '';

    if (data.folderId) {
      param = `?folderId=${data.folderId}`;
    }

    const response = await this.requester.post(`/project${param}`, data, {});

    return response;
  }

  async createCompany(name, AdministrativeNodeId) {
    const data = {
      name,
      AdministrativeNodeId,
      companyType: 'builder',
    };

    return this.requester.post('/company', data, {});
  }

  async patchAdminOrga(email, action, AdministrativeNodeId) {
    const data = {
      email,
      action,
      AdministrativeNodeId,
    };

    return this.requester.patch('/administrativeNode/admin', data);
  }

  async createCompanyWithCategory(name, AdministrativeNodeId, companyCategory) {
    const data = {
      name,
      AdministrativeNodeId,
      companyType: 'builder',
      CategoryId: companyCategory,
    };

    return this.requester.post('/company', data, {});
  }

  // async deleteCompany(companyId) {
  //   return this.requester.delete(`/companies/${companyId}/delete`,
  //     {});
  // }

  async getEquipmentCaracList(lot, fonction, materiel, categorie, type) {
    const params = {
      lot,
      fonction,
      materiel,
      categorie,
      type,
    };

    return this.requester.get('/caracs', {
      params,
    });
  }

  async getEquipmentCaracListById(id) {
    const params = {
      AllotmentNodeId: id,
    };

    return this.requester.get('/caracs/allotmentnodes', { params });
  }

  async getEquipmentCaracListByMdId(id) {
    const params = {
      MDId: id,
    };

    return this.requester.get('/caracs', { params });
  }

  async getCaracById(id) {
    return this.requester.get('/carac/' + id, {});
  }

  async patchCaracById(data) {
    return this.requester.patch('/carac/' + data.id, {
      data: data,
    });
  }

  async deleteCarac(id) {
    return this.requester.delete('/carac/' + id, {
      data: { id },
    });
  }

  async createEquipmentCustom(data, id) {
    return this.requester
      .post(`/project/${id}/equipment/custom`, data)
      .then((response) => response)
      .catch((e) => {
        throw e;
      });
  }

  async createEquipmentFromXml(data, file, id) {
    let bodyFormData = {
      form: data,
      xml: typeof file === 'string' ? file : await file.text(),
      fileName: file.name,
    };

    return this.requester
      .post(`/project/${id}/fc`, bodyFormData, {})
      .then((response) => response)
      .catch((e) => e);
  }

  async createEquipment(data, id) {
    return this.requester
      .post(`/project/${id}/equipment`, data, {})
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  async patchEquipment(data, id) {
    return this.requester
      .patch(`/project/${id}/equipment`, data, {})
      .then((response) => response)
      .catch((e) => e);
  }

  /**
   *
   * @param projectId
   * @param idList
   * @returns {Promise<*>}
   */
  async deleteEquipments(projectId, idList) {
    console.log(projectId, idList);

    return this.requester
      .delete(`/project/${projectId}/equipment`, {
        data: idList,
      })
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async modifyEquipment(
    projectId,
    id,
    quantity,
    ZoneId,
    ProjectSheetId,
    caracs,
    isDEDRequested,
    id_demandeur
  ) {
    const data = {
      quantity,
      isDEDRequested: isDEDRequested || null,
      id_equipment: id,
      ZoneId,
      ProjectSheetId,
      caracs,
      id_demandeur,
    };

    return this.requester
      .patch(`/project/${projectId}/equipment`, data, {})
      .then((response) => response.data)
      .catch((e) => {
        return e;
      });
  }

  async getCompanies() {
    return this.requester
      .get('/companies', {})
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async getCompaniesByAdministrativeNode(id) {
    return this.requester
      .get(`/administrativeNode/${id}/companies`, {})
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async getCompany(id) {
    return this.requester
      .get(`/companies/${id}`, {})
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async getCompanyEmployees(id) {
    return this.requester
      .get(`/companies/${id}/employees`, {})
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async getCompanySheets(id, sheetType) {
    return this.requester
      .get(`/brand/${id}/sheets/${sheetType}`, {})
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async getCompanyInvitations(id) {
    return this.requester
      .get(`/companies/${id}/invitations`, {})
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async postCSV(projectId, csv) {
    const data = {
      projectId,
      csv,
    };

    return this.requester
      .post('/csvEquipment', data, {})
      .then(() => {})
      .catch(() => {});
  }

  async postInvitation(email, companyIds, isAdmin, giveLicense) {
    const data = {
      email,
      companyIds,
      isAdmin,
      giveLicense,
    };

    return this.requester.post('/companies/invitation', data, {});
  }

  async patchCompanyAdmin(employeeId) {
    return this.requester.patch(`/employee/${employeeId}/isAdmin`);
  }

  async patchWorkflowMode(administrativeNodeId) {
    return this.requester.patch(
      `/administrativeNode/${administrativeNodeId}/contract/workflowMode`
    );
  }

  async fetchRequests(administrativeNodeId) {
    return this.requester.get(
      `/administrativeNode/${administrativeNodeId}/requests`
    );
  }

  async getSheet(target, project, indicatorId, projectSheetId) {
    const optionalProjectSheetId = projectSheetId
      ? `&projectSheetId=${projectSheetId}`
      : '';

    if (target.SheetId) target = target.SheetId;

    return this.requester
      .get(
        `/sheet/${target}/project/${project}?indicatorId=${indicatorId}${optionalProjectSheetId}`,
        {}
      )
      .then((response) => {
        return response;
      });
  }

  async getProjectSheetCarac(id) {
    return this.requester.get(`/projectsheet/${id}`).then((response) => {
      return response;
    });
  }

  async getSheets(id) {
    return this.requester.get(`/sheets/${id}`, {}).then((response) => {
      return response;
    });
  }

  async patchSwitchSheet(
    selectedEquipment,
    lockedSelectedEquipment,
    id_project,
    ProjectSheetId
  ) {
    const data = {
      selectedEquipment,
      lockedSelectedEquipment,
      id_project,
      ProjectSheetId,
    };

    return this.requester.patch('/switchSheet', data, {}).then((response) => {
      return response;
    });
  }

  async getMD() {
    return this.requester.get('/md');
  }

  async modifyMD(id, data) {
    return this.requester.patch(`/md/elem/${id}`, data, {});
  }

  async deleteMD(id) {
    return this.requester.delete('/md', {
      data: { id },
    });
  }

  async getSources() {
    return this.requester.get('/sources');
  }

  // TODO use defaut values
  async createGroup(lot, fonction, categorie, materiel, type) {
    const data = {
      lot,
      fonction,
      categorie,
      materiel,
      type,
    };

    return this.requester.post('/md', data, {});
  }

  // /sheet/fiche wtf ??
  async getEquipSheet(masterDataId) {
    return this.requester.get(`/sheets/${masterDataId}`);
  }

  async modifySheet(body) {
    return this.requester.patch('/sheet', body);
  }

  async deleteSheet(body) {
    const { id } = body;

    return this.requester.delete('/sheet', {
      data: { id },
    });
  }

  async getImported() {
    return this.requester.get('/imported');
  }

  async patchImported(data) {
    return this.requester.patch('/imported', data, {}).then((response) => {
      return response;
    });
  }

  async getToReview() {
    return this.requester.get('/toreview');
  }

  async patchToReview(data) {
    return this.requester.patch('/toreview', data, {}).then((response) => {
      return response;
    });
  }

  async postSheet(data) {
    return this.requester
      .post('/sheet', data, {})
      .then((response) => {
        return response;
      })
      .catch((e) => {
        return e;
      });
  }

  async postPepFdesDed({
    quantity,
    SheetId,
    AllotmentNodes,
    ProjectId,
    fluid,
    ZoneId,
    caracs,
    UrbanNetworkId,
    re2020lot,
    lineImportId,
    comment,
    customName,
  }) {
    const data = {
      quantity,
      SheetId,
      fluid,
      AllotmentNodes,
      ZoneId,
      caracs,
      UrbanNetworkId,
      re2020lot,
      lineImportId,
      comment,
      customName,
    };

    return this.requester
      .post(`/project/${ProjectId}/equipment/sheet/pfd`, data, {})
      .then((response) => {
        return response;
      })
      .catch((e) => {
        return e;
      });
  }

  async patchFullData(data) {
    return this.requester.patch('/fullData', data, {}).then((response) => {
      return response;
    });
  }

  async archiveImported(data) {
    return this.requester.post('/archiveImported', data, {}).then((response) => {
      return response;
    });
  }

  async getInvitations() {
    return this.requester.get('/invitation');
  }

  async postInviteAction(token, accept) {
    const data = {
      token,
      accept,
    };

    return this.requester.post('/invitation/reponse', data, {}).then((response) => {
      return response;
    });
  }

  async postCaracValue(carac, sheetId) {
    const data = {
      CaracId: carac.id,
      value: carac.value,
    };

    return this.requester
      .post(`/caracvalue/${sheetId}`, data, {})
      .then((response) => {
        return response;
      });
  }

  async getCaracValue(id) {
    return this.requester.get(`/caracvalue/${id}`);
  }

  async createNewCarac(data, id) {
    return this.requester.post(`/carac/${id}`, data, {}).then((response) => {
      return response;
    });
  }

  async getImportForProject(id) {
    return this.requester
      .get(`/project/${id}/import`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  async getDataByImport(
    id,
    importId,
    searchValue,
    filterOn,
    displayArchived,
    offset
  ) {
    return this.requester
      .get(
        `/project/${id}/import/${importId}?offset=${offset}&limit=20&isArchived=${displayArchived}${
          searchValue ? `&search=${searchValue}` : ''
        }${filterOn.includes('userValidated') ? `&filter=userValidated` : ''}${
          filterOn.includes('error') ? `&filter=error` : ''
        }${filterOn.includes('Validated') ? `&filter=Validated` : ''}`
      )
      .then((response) => {
        return response;
      });
  }

  async patchErrorsImport(data, idProject, idImport, mappingRef) {
    data.isMappingRef = mappingRef;

    return this.requester
      .patch(`/project/${idProject}/import/${idImport}/elem/${data.id}`, data, {})
      .then((response) => {
        return response;
      });
  }

  async confirmImport(projectId, importId, lines) {
    return this.requester
      .post(
        `/project/${projectId}/import/${importId}/process`,
        lines.length > 0 ? lines : null,
        {}
      )
      .then((response) => {
        return response;
      });
  }
  async deleteImport(projectId, importId) {
    return this.requester
      .delete(`/project/${projectId}/import/${importId}`)
      .then((response) => {
        return response;
      });
  }

  async importFileToCSV(
    projectId,
    fileUUID,
    fileType,
    zoneId,
    fileName,
    isImportToContributorList
  ) {
    const data = {
      fileUUID: fileUUID,
      fileType: fileType,
      imports: zoneId,
      fileName,
      isImportToContributorList,
    };

    return this.requester
      .patch(`/project/${projectId}/import/toCSV`, data)
      .then((response) => {
        return response;
      });
  }

  async postProjectPDF(projectId) {
    return this.requester.post(`/project/${projectId}/pdf`).then((response) => {
      return response;
    });
  }

  async postProjectRsee(id) {
    return this.requester.get(`/project/${id}/rsee`).then((response) => {
      return response;
    });
  }

  async getRseePdf(projectId) {
    console.log('api getRseePdf');

    return this.requester.get(`/project/${projectId}/rseepdf`).then((response) => {
      return response;
    });
  }

  async getLots() {
    return this.requester.get('/lots');
  }

  async deleteErrorImport(
    queryParam,
    projectId,
    importId,
    searchSourceValue,
    filterOn,
    isArchived,
    exceptLinesFromMultipleActions
  ) {
    let url = '';

    if (typeof queryParam === 'boolean') {
      url = '&allLinesChecked=true';

      if (exceptLinesFromMultipleActions) {
        exceptLinesFromMultipleActions.map((id, i) => {
          url += `&exceptLine=${id}`;
        });
      }
    } else {
      queryParam.map((id, i) => {
        url += `&id=${id}`;
      });
    }

    return this.requester
      .delete(
        `/project/${projectId}/import/${importId}/elems?isArchived=${isArchived}&search=${searchSourceValue}${
          filterOn.includes('userValidated') ? `&filter=userValidated` : ''
        }${filterOn.includes('error') ? `&filter=error` : ''}${
          filterOn.includes('Validated') ? `&filter=Validated` : ''
        }${url}`
      )
      .then((response) => {
        return response;
      });
  }

  async putMdLink(ChildId, ParentId) {
    const data = {
      ChildId,
      ParentId,
    };

    return this.requester.post('/mdlink', data);
  }

  async deleteMdLink(ChildId) {
    const body = {
      ChildId,
    };

    return this.requester.delete('/mdlink', {
      data: body,
    });
  }

  async getMyCompaniesEmployees() {
    return this.requester.get('/companies/access');
  }

  async deleteInvite(id) {
    const body = {
      id,
    };

    return this.requester.delete('/companies/invitation', {
      data: body,
    });
  }

  async deleteEmployees(body) {
    return this.requester.delete('/companies/employees', {
      data: body,
    });
  }

  async patchEmployee(CompanyId, body) {
    return this.requester.patch(`/companies/${CompanyId}/employees`, body);
  }

  async deleteAdministrativeNodeEmployees(email, companyIds) {
    const body = {
      email,
      companyIds,
      delete: true,
    };

    return this.requester.patch(`/companies/${companyIds[0]}/employees`, body);
  }

  async fetchFluidsCompatibleSheets(idIniesArray) {
    return this.requester.get(`/sheets/fluidCompatible/`, {
      params: idIniesArray,
    });
  }

  async patchEmployeeLicense(employeeId, body) {
    return this.requester.patch(`/companies/employee/${employeeId}/license`, body);
  }

  async patchEmployeeAccess(employeeId, companyId) {
    return this.requester.patch(`/companies/employee/${employeeId}/access`, {
      companyId,
    });
  }

  async deleteRequest(requestId) {
    return this.requester.delete(`/requests/${requestId}/delete`);
  }

  async getAllGroups() {
    try {
      const response = this.requester.get('/groups');

      return response;
    } catch (e) {
      console.log(e);

      return e;
    }
  }

  async getIndicatorsDatas(projectId, indicatorId) {
    return this.requester.get(`/project/${projectId}/index/${indicatorId}`);
  }

  async getIndicatorsBySheet(allotmentNodeId, indicatorId, projectId) {
    return this.requester.get(
      `/sheets/${allotmentNodeId}/index/${indicatorId}?projectId=${projectId}`
    );
  }

  async verifyImportElem(line) {
    return this.requester.post(`/patchVerifyProjectImportElem`, line);
  }

  async getMoreImportDatas(
    idProject,
    importId,
    lastId,
    isValid,
    displayArchived = false
  ) {
    return this.requester.get(
      `/project/${idProject}/import/${importId}?limit=20&after_id=${lastId}&isArchived=${displayArchived}${
        isValid.includes('userValidated') ? `&filter=userValidated` : ''
      }${isValid.includes('error') ? `&filter=error` : ''}${
        isValid.includes('Validated') ? `&filter=Validated` : ''
      }`
    );
  }
  async patchZones(projectId, data) {
    data.zones = data.zones.filter((zone) => zone.id);
    data.zones.forEach((zone) => {
      delete zone.ProjectSheets;
      delete zone.Zones;

      if (zone.zone_c) {
        Object.keys(zone.zone_c).forEach((key) => {
          if (!key.includes('O_Cef')) {
            delete zone.zone_c[key];
          }
        });
      }
    });

    return this.requester.patch(`/project/${projectId}/zone`, data);
  }

  async fetchNomenclatureInies() {
    return this.requester.get(`/inies/nomenclature`);
  }

  async fetchVariantsById(id, indicatorId) {
    let config = {
      params: { indicatorId },
    };

    return this.requester.get(`/project/${id}/variant`, config);
  }

  async getAllLinesWithSearchValue(idProject, importId, search, isArchived) {
    let searchTerm = encodeURIComponent(search);

    return this.requester.get(
      `/project/${idProject}/import/${importId}?search=${searchTerm}&isArchived=${isArchived}`
    );
  }

  async postElemImport(idProject, importId, data) {
    return this.requester.post(
      `/project/${idProject}/import/${importId}/elem`,
      data
    );
  }

  async getSheetByIniesId(query, projectTypeId) {
    return this.requester.get(
      `/sheet/inies/?search=${query}${
        projectTypeId ? `&projectTypeId=${projectTypeId}` : ''
      }`
    );
  }

  async getEdecFiles(username, password) {
    let config = {
      headers: {
        ['EDEC-Username']: username,
        ['EDEC-Password']: password,
      },
    };

    return this.requester.get(`/edec/files`, config);
  }

  async downloadEdec(username, password, file) {
    let config = {
      headers: {
        ['EDEC-Username']: username,
        ['EDEC-Password']: password,
        ['EDEC-Fichier']: file,
      },
    };

    return this.requester.get(`/edec/download`, config);
  }

  async updateIniesSheet(data, projectId) {
    return this.requester.patch(`/project/${projectId}/equipment/version`, data);
  }

  async sendMessageTrello(card) {
    return this.requester.post(`trello/import`, card);
  }

  async postProjectZones(projectId, data) {
    return this.requester.post(`/project/${projectId}/zone`, data);
  }

  async deleteZones(projectId, data) {
    return this.requester.delete(`/project/${projectId}/zone/${data}`);
  }

  async patchImportCarac(projectId, importId, caracId, data, mappingRef) {
    console.log(data);

    if (mappingRef) {
      data.isMappingRef = mappingRef;
    }

    return this.requester.patch(
      `/project/${projectId}/import/${importId}/elem/carac/${caracId}`,
      data
    );
  }

  async fetchProductsByNodeId(nodeId, search, brand, offset) {
    let brandsUrl = '';

    if (brand) {
      brand.forEach((el) => {
        brandsUrl += `&brand=${el.name}`;
      });
    }

    return this.requester.get(
      `/sheets/node/${nodeId ? `${nodeId}` : 'null'}?search=${
        search || ''
      }&offset=${offset}${brandsUrl}`
    );
  }

  async fetchManufacturersSheet() {
    return this.requester.get(`/sheets/manufacturer/count`);
  }

  async getCountByNodeId(nodeId) {
    return this.requester.get(`/sheets/node/${!nodeId ? 'null' : nodeId}/count`);
  }

  async fetchBoardingEquipment(id, { pageParam = 0 }, lot) {
    console.log(pageParam, 'offset');

    return this.requester.get(
      `/exploitation/${id}/equipment?limit=20&offset=${pageParam * 20}${
        lot ? `&lot=${lot}` : ''
      }`
    );
  }

  async createBoardingEquipment(id, data) {
    return this.requester.post(`/exploitation/${id}/equipment`, data);
  }

  async deleteBoardingEquipment(id, data) {
    let url = '';

    data.forEach((el, i) => {
      url += `${i !== 0 ? '&' : '?'}id=${el}`;
    });

    return this.requester.delete(`/exploitation/${id}/equipment${url}`);
  }

  async fetchListActions(id, offset, eventType) {
    return this.requester.get(
      `/exploitation/${id}/action?limit=20&offset=${offset}${
        eventType !== 'Projet' ? `&eventType=${eventType}` : ''
      }`
    );
  }

  async createAction(id, data) {
    return this.requester.post(`/exploitation/${id}/action`, data);
  }

  async getFullMd(id) {
    return this.requester.get(`/masterdata/${id}`);
  }

  async getSearchShortData(projectId, param, search) {
    return this.requester.get(
      `/exploitation/${projectId}/equipment/short?${param}=${search}&limit=1000`
    );
  }

  async fetchReleases() {
    // console.log('fetchreleaseson');

    return this.requester.get(`/help/release?limit=10`).then((response) => response);
  }

  async postRelease(newRelease) {
    const releaseToPost = { ...newRelease };

    const { deliveryDate } = releaseToPost;

    const [day, month, year] = deliveryDate.split('/');

    const dateFormat = new Date(year, month - 1, day);

    releaseToPost.deliveryDate = dateFormat;

    releaseToPost.type = 'release';

    return this.requester
      .post(`/help/release`, releaseToPost)
      .then((response) => response);
  }

  async postFeature(newFeature) {
    // newFeature.type = "feature";
    console.log(newFeature);

    return this.requester
      .post(`/help/feature`, newFeature)
      .then((response) => response);
  }

  async fetchFeatures() {
    // console.log('fetchreleaseson');

    return this.requester.get(`/help/feature?limit=30`).then((response) => response);
  }

  async fetchRelease(id) {
    return this.requester.get(`/help/release/${id}`).then((response) => response);
  }

  async fetchFeature(id) {
    return this.requester.get(`/help/feature/${id}`).then((response) => response);
  }

  async patchRelease(id, release) {
    return this.requester
      .patch(`/help/release/${id}`, release)
      .then((response) => response);
  }

  async patchFeature(feature) {
    console.log(feature);
    console.log(feature.id);

    return this.requester
      .patch(`/help/feature/${feature.id}`, feature)
      .then((response) => response);
  }

  async deleteRelease(id) {
    return this.requester.delete(`/help/release/${id}`).then((response) => response);
  }

  async deleteFeature(id) {
    return this.requester.delete(`/help/feature/${id}`).then((response) => response);
  }

  async getRseeExport(id) {
    console.log(id);

    return this.requester
      .get(`/project/${id}/dataComps`)
      .then((response) => response);
  }

  async mappingProject(data, companyId) {
    return this.requester
      .post(`/company/${companyId}/mappingRef/project/${data.id}`, data)
      .then((response) => response);
  }

  async postRseeExport(id, data) {
    console.log(id, data);

    return this.requester
      .post(`/project/${id}/dataComps`, data)
      .then((response) => response);
  }

  async fetchImpactBarGraph(projectId, datesSelectedInGraph) {
    let queryParams = datesSelectedInGraph
      ? `?startDate=${datesSelectedInGraph[0]}&endDate=${datesSelectedInGraph[1]}`
      : '';

    return this.requester
      .get(`/exploitation/${projectId}/graph/impactbyaction${queryParams}`)
      .then((response) => response);
  }

  async putBuildingCalculator(id, data) {
    // console.log('api put',

    //   data);

    // return "test";

    return this.requester
      .put(`/project/${id}/calculator/buildingsite`, data)
      .then((response) => response);
  }

  async getBuildingCalculator(id) {
    return this.requester
      .get(`/project/${id}/calculator/buildingsite`)
      .then((response) => response);
  }

  async getWaterCalculatorDetailed(projectId) {
    return this.requester
      .get(`/project/${projectId}/calculator/water`)
      .then((response) => response);
  }

  async putWaterCalculatorDetailed(projectId, data) {
    return this.requester
      .put(`/project/${projectId}/calculator/water`, data)
      .then((response) => response);
  }

  async resetWaterCalculatorDetailed(projectId, data) {
    // console.log('RESETWATER API');

    return this.requester
      .put(`/project/${projectId}/calculator/water?typeOfCalculator=reset`, data)
      .then((response) => response);
  }

  async patchLinesImport(projectId, importId, data, mappingRef) {
    // data.isMappingRef = mappingRef;

    return this.requester
      .patch(`/project/${projectId}/import/${importId}/elems`, data)
      .then((response) => response);
  }

  async refreshIA(projectId, importId) {
    return this.requester
      .patch(`/project/${projectId}/import/${importId}/refresh`)
      .then((response) => response);
  }

  async getQuickSimulator() {
    return this.requester.get(`/project/quickSim`).then((response) => response);
  }

  async createQuickSimulator() {
    return this.requester.post(`/project/quickSim`).then((response) => response);
  }

  async patchQuickSimulator(data) {
    return this.requester
      .patch(`/project/quickSim`, data)
      .then((response) => response);
  }

  async resetQuickSimulatorVariant(id) {
    return this.requester
      .delete(`/project/quickSim/${id}/deleteAllEquips`)
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async duplicateSimulatorFirstVariantToSecondVariant(
    sourceVariantId,
    targetVariantId
  ) {
    return this.requester
      .post(`/project/quickSim/${sourceVariantId}/duplicateAllEquips`, {
        targetProjectId: targetVariantId,
      })
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async getSheetsByLibelleOrId(value) {
    return this.requester
      .get(`/sheet/inies?search=${value}`)
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async getUserFavoritesShort() {
    return this.requester
      .get(`/favorites/user/short`)
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async getCompanyFavoritesShort() {
    return this.requester
      .get(`/favorites/company/short`)
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async getUserFavorites(type_fiche = 'inies', offset, search) {
    return this.requester
      .get(
        `/favorites/user?offset=${offset || 0}${
          type_fiche ? `&type_fiche=${type_fiche}` : ''
        }${search ? `&search=${search}` : ''}`
      )
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async getCompanyFavorites(offset, search) {
    return this.requester
      .get(
        `/favorites/company?offset=${offset || 0}${
          search ? `&search=${search}` : ''
        }`
      )
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async putUserFavorites(SheetId) {
    return this.requester
      .put(`/favorites/user`, { SheetId: SheetId })
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async putCompanyFavorites(SheetId) {
    return this.requester
      .put(`/favorites/company`, { SheetId: SheetId })
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async deleteUserFavorites(sheetId) {
    return this.requester
      .delete(`/favorites/user/${sheetId}`)
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async deleteCompanyFavorites(sheetId) {
    return this.requester
      .delete(`/favorites/company/${sheetId}`)
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async getGraphModelsAndActions(projectId, datesSelectedInGraph) {
    let queryParams = datesSelectedInGraph
      ? `?startDate=${datesSelectedInGraph[0]}&endDate=${datesSelectedInGraph[1]}`
      : '';

    return this.requester
      .get(`/exploitation/${projectId}/graph/modelsandactions${queryParams}`)
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async getAccessHeader() {
    return this.requester
      .get(`/admin/companies/main`)
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async getCompaniesList(offset = 0, search = '', parentNodeId = null) {
    return this.requester
      .get(
        `/admin/companies?offset=${offset}&limit=20${
          search && !parentNodeId ? `&search=${search}` : ''
        }${parentNodeId ? `&parentId=${parentNodeId}` : ''}`
      )
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async getAdminUsers(companyId) {
    return this.requester
      .get(`/company/${companyId}/employees`)
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async postCompany(data) {
    return this.requester
      .post(`/company`, data)
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async patchCompany(data) {
    return this.requester
      .patch(`/company/${data?.id}`, data)
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async deleteCompany(companyId) {
    return this.requester
      .delete(`/company/${companyId}`)
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async patchUser(data) {
    return this.requester
      .patch(`/admin/employee/${data.id}/company/${data.CompanyId}`, data)
      .then((response) => response)
      .catch((e) => {
        throw e;
      });
  }

  async postLicenses(data) {
    return this.requester
      .post(`/company/${data.CompanyId}/employee?licenseType=${data.licenseType}`)
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async deleteLicenses(data) {
    return this.requester
      .delete(`/company/${data.CompanyId}/employee?licenseType=${data.licenseType}`)
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async getEmployeesList(request) {
    const { groupBy, companyId, licenceType, limit, offset, inputSearch } = request;

    const urlParams = [];

    if (groupBy) {
      urlParams.push(`groupBy=${groupBy}`);
    }

    const groupByParam =
      groupBy === 'company'
        ? companyId
          ? `companyId=${companyId}`
          : null
        : licenceType
        ? `licenseType=${licenceType}`
        : null;

    if (groupByParam) {
      urlParams.push(groupByParam);
    }

    if (limit) {
      urlParams.push(`limit=${limit}`);
    }

    if (offset) {
      urlParams.push(`offset=${offset}`);
    }

    if (inputSearch) {
      urlParams.push(`search=${inputSearch}`);
    }

    const url =
      urlParams.length !== 0
        ? `/admin/employees?${urlParams.join('&')}`
        : '/admin/employees';

    return this.requester
      .get(url)
      .then((response) => response)
      .catch((e) => {
        return e;
      });
  }

  async postEmployee(data) {
    return this.requester
      .put(`/admin/employee`, data)
      .then((response) => {
        // console.log('api response',
        //   response);

        return response;
      })
      .catch((e) => {
        // console.log('api error',
        //   e);
        throw e;

        // return e;
      });
  }

  async deleteUser(employee, companyIdToDelete) {
    return this.requester
      .delete(`/admin/employee/${employee.id}/company/${companyIdToDelete}`)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        throw e;
      });
  }

  async updateEmployee(employee, data) {
    return (
      this.requester
        .patch(
          `/admin/employee/${employee.id}/company/${employee.CompanyIds[0]}`,
          data
        )
        // .delete(`/admin/employee/${9558}/company/${614}`)
        .then((response) => {
          return JSON.stringify(response);
        })
        .catch((e) => {
          throw e;
        })
    );
  }
  async deleteActionExploitation(projectId, idToDelete) {
    let url = '';

    idToDelete.map((id, i) => {
      url += `${i === 0 ? '?' : '&'}id=${id}`;
    });

    return this.requester.delete(`/exploitation/${projectId}/action${url}`);
  }
  async getFoldersByCompany(companyId, isBundleScreen) {
    let bundleParams = '';

    if (isBundleScreen) {
      bundleParams = '?folderType=Sheet';
    }

    return this.requester
      .get(`/folder/company/${companyId}${bundleParams}`)
      .then((response) => response)
      .catch((e) => {
        throw e;
      });
  }
  async getProjectsByFolder(selectedCompanyId, folderId, projectTypeFilter, offset) {
    // const data = typeof folderId === 'number' ? null : {companyId: selectedCompanyId};
    let queryParams = ``;

    projectTypeFilter.forEach((projectType) => {
      queryParams += `&ProjectTypeId=${projectType.id}`;
    });

    return (
      this.requester
        // .get(`/folder/${folderId ? folderId : null}/project${queryParam}&offset=${offset}&projectTypeId=1&projectTypeId=5`)
        .get(
          `/folder/${
            folderId ? folderId : null
          }/project?companyId=${selectedCompanyId}&offset=${offset}${queryParams}`
        )
        .then((response) => response)
        .catch((error) => {
          throw error;
        })
    );
  }
  async createFolder(parentFolderId, companyId, folderName, isBundleScreen) {
    let bundleParams = '';

    if (isBundleScreen) {
      bundleParams = '?folderType=Sheet';
    }

    const data = {
      // body: {
      name: folderName,
      id: parentFolderId || null,
      // }
    };

    return this.requester
      .post(`/folder/company/${companyId}${bundleParams}`, data)
      .then((response) => response)
      .catch((e) => {
        throw e;
      });
  }

  // route optimized compared to pachProject
  async patchProjectOpti(projectId, body, isBundleScreen) {
    return this.requester
      .patch(
        `/project/${projectId}${isBundleScreen ? '?folderType=Sheet' : ''}`,
        body
      )
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  async patchFolder(data, isBundleScreen) {
    let bundleParams = '';

    if (isBundleScreen) {
      bundleParams = '?folderType=Sheet';
    }

    return this.requester
      .patch(`/folder/${data.id}${bundleParams}`, data.body)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  async deleteFolder(folderId, isBundleScreen) {
    let bundleParams = '';

    if (isBundleScreen) {
      bundleParams = '?folderType=Sheet';
    }

    return this.requester
      .delete(`/folder/${folderId}${bundleParams}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  // async deleteProject(projectId) {
  //   return this.requester
  //     .delete(`/project/${projectId}`)
  //     .then(response => response)
  //     .catch(error => { throw error; });
  // }

  async downloadBoardingXlsx(id) {
    return this.requester.get(`/exploitation/${id}/xlsx`);
  }

  async getProjectsShort(CompanyId) {
    return this.requester.get(
      `/projects/short${CompanyId ? `?CompanyId=${CompanyId}` : ''}`
    );
  }

  async getBundleListByFolder(companyId, folderSheetId, { pageParam = 0 }, search) {
    return this.requester.get(
      `/library/bundles/folderSheet/${folderSheetId}?limit=20&offset=${
        pageParam * 20
      }${companyId ? `&companyId=${companyId}` : ''}${
        search ? `&search=${search}` : ''
      }`
    );
  }

  async postBundle(data) {
    return this.requester.post(`/library/bundles`, data);
  }

  async deleteBundle(data) {
    return this.requester.delete(`/library/bundles${`/${data.id}`}`);
  }

  async patchBundle(data) {
    return this.requester.patch(`/library/bundles${`/${data.id}`}`, data);
  }

  async fetchShortBundle() {
    return this.requester.get(`/library/bundles/short`);
  }

  async postBundleInProject(currentProjectId, body) {
    return this.requester
      .post(`/project/${currentProjectId}/bundle`, body)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  async cutBundleLines(currentProjectId, importId, row) {
    return this.requester.patch(
      `/project/${currentProjectId}/import/${importId}/elem/${row.id}/bundle/split`
    );
  }

  async getCaracsByAllotmentNodes(
    AllotmentNodesIds,
    ProjectSheetId,
    fetchSheets = true
  ) {
    let queryParams = '';

    AllotmentNodesIds.map((id) => {
      queryParams += `&AllotmentNodeId=${id}`;
    });

    return this.requester.get(
      `/caracs/allotmentnodes?${queryParams}${
        ProjectSheetId ? `&ProjectSheetId=${ProjectSheetId}` : ''
      }${`&fetchSheets=${fetchSheets}`}`
    );
  }

  async patchEquipments(currentProjectId, body) {
    return this.requester.patch(`/project/${currentProjectId}/equipments`, body);
  }

  async getUserMethodToLoggedIn(email) {
    return this.requester.post(`/sso`, {
      email,
    });
  }

  async postUuidForVinciSSO(uuid) {
    return this.requester.patch(`/sso/sync`, {
      uuid,
    });
  }

  async getProjectDataComps(projectId) {
    return this.requester
      .get(`/project/${projectId}/dataComps`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  async postProjectDataComps(projectId, body) {
    return this.requester
      .post(`/project/${projectId}/dataComps`, body)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  async patchBoardingEquipment(id, data) {
    return this.requester.patch(`/exploitation/${id}/equipment`, data);
  }

  async getHealth() {
    return this.requester.get(`/health`);
  }

  async getPdf(widgetsToGenerate) {
    return this.requester.get(`/pdf`, widgetsToGenerate);
  }
}

export default API;
