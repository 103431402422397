/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';

import {
  addDuplicateLine,
  changeImportDatas,
  closeValidAlert,
  cutLines,
  deleteAllErrors,
  deleteRowsImport,
  fetchMoreImportDatas,
  fetchPepFdes,
  openMoreOptions,
  openValidAlert,
  sendImport,
  sendImportEdit,
  sendMessageSlack,
  sendMessageTrello,
  setDataError,
  verifyRowsEquipment,
} from '../../actions/import';

import { updateFormValue } from '../../actions/projects';

import { changeValueEquipments } from '../../actions/equipments';

import LineComponent from '../../app/projects/Import/Line/LineComponent';

const mapStateToProps = (state) => ({
  importDatas: state.imports.importDatas,
  currentProject: state.projects.currentProject,
  equipmentBuildingSiteId: state.projects.equipmentBuildingSiteId,
  zoneImportId: state.projects.zoneImportId,
  moreOptionLines: state.imports.moreOptionLines,
  openValid: state.imports.openValid,
  isLoadMoreImports: state.imports.isLoadMoreImports,
  zones: state.projects.parsedProject.zones,
  goodLines: state.imports.goodLines,
  badLines: state.imports.badLines,
  filterOn: state.imports.filterOn,
  modalAsk: state.projects.modalAsk,
  isMessageTeams: state.projects.isMessageTeams,
  isMessageTrello: state.projects.isMessageTrello,
  importPepFdes: state.imports.importPepFdes,
  waitLines: state.imports.waitLines,
  allGroups: state.projects.allGroups,
  isSearchingData: state.imports.isSearchingData,
  isImportLoading: state.imports.isImportLoading,
  scrollPositionImport: state.imports.scrollPositionImport,
  onDelete: state.imports.onDelete,
  isFullLoad: state.imports.isFullLoad,
  editImport: state.imports.editImport,
  selectedAllotmentNode: state.imports.selectedAllotmentNode,
  currentLine: state.imports.currentLine,
  projectAllot: state.projects.projectAllot,
  currentAllotment: state.projects.currentAllotment,
  urbanNetworks: state.projects.parsedProject.urbanNetworks,
  isLoad: state.imports.isLoad,
  loadingSwitch: state.imports.loadingSwitch,
  lineImport: state.imports.lineImport,
  linesSelected: state.imports.linesSelected,
});

const mapDispatchToProps = (dispatch) => ({
  sendImportEdit: (editionLines) => {
    dispatch(sendImportEdit(editionLines));
  },
  deleteRowsImport: (rowId) => {
    dispatch(deleteRowsImport(rowId));
  },
  setDataError: (falseData) => {
    dispatch(setDataError(falseData));
  },
  deleteAllErrors: () => {
    dispatch(deleteAllErrors());
  },
  changeImportDatas: (editLines) => {
    dispatch(changeImportDatas(editLines));
  },
  verifyRowsEquipment: (lineId, patchCarac) => {
    dispatch(verifyRowsEquipment(lineId, patchCarac));
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  sendImport: (unique) => {
    dispatch(sendImport(unique));
  },
  openMoreOptions: (line) => {
    dispatch(openMoreOptions(line));
  },
  openValidAlert: () => {
    dispatch(openValidAlert());
  },
  closeValidAlert: () => {
    dispatch(closeValidAlert());
  },
  fetchMoreImportDatas: () => {
    dispatch(fetchMoreImportDatas());
  },
  sendMessageSlack: () => {
    dispatch(sendMessageSlack());
  },
  sendMessageTrello: () => {
    dispatch(sendMessageTrello());
  },
  fetchPepFdes: (mdId, caracs) => {
    dispatch(fetchPepFdes(mdId, caracs));
  },
  cutLines: (row) => {
    dispatch(cutLines(row));
  },
  addDuplicateLine: (initialRow) => {
    dispatch(addDuplicateLine(initialRow));
  },
  changeValueEquipments: (name, value) => {
    dispatch(changeValueEquipments(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LineComponent);
