import { ProjectVerificationLevel } from '@photocarbone/nooco-shared-api-def';
import { Lifespan } from '@photocarbone/nooco-shared-api-def';

export type AllotmentNodeDTO = {
  count: {}[];
  isObsolete: boolean;
  className: string;
  icon: JSX.Element | null;
  id: number;
  key: number | null;
  name: string;
  label: string;
  level: number;
  isReSousLot: boolean;
  AllotmentId: number;
  ParentId: number | null;
  children: AllotmentNodeDTO[] | null;
  color: null | string;
  Re2020AllotmentNodeId: number | null;
  EpcmAllotmentNodeId: number | null;
  ReSousLotId: number | null;
  MasterDataEquipmentId: number | null;
};

export type ProjectTypeDTO = {
  id: number;
  name: string;
  defaultDdv: number;
  availableUnits: string[];
  AllotmentNodes: AllotmentNodeDTO[] | null;
  projectTypes: {
    name: string;
    AllotmentNodes: { id: number; name: string; color: string }[];
  }[];
};

//TODO move to globalVariables? to innovationClimateForm?
export const BbcaPotentialUseOptions = [
  {
    value: 1,
    label: "Principe d'évolution défini",
  },
  {
    value: 2,
    label: 'Dimensionnement ouvrage intègre cette évolution',
  },
  {
    value: 3,
    label: 'Dossier complet décrivant le process',
  },
];

export type BbcaClimateInnovationBuildingFormDTO = {
  zoneId: number; //zone of type 'building'
  savedParkingPlacesNumber: number | string | null;
  savedParkingPlacesUndergroundNumber: number | string | null;
  savedArea: number | string | null;
  potentialUseChange: { value: number; label: string } | null;
  potentialAdditionalArea: number | string | null;
  selectiveDeconstruction: boolean;
  massReusedConstructionProducts: number | string | null;
};

export enum TREATMENT_MODE_VALUES_I18N_KEYS {
  inFacility = 'APP.SHARED.CARAC_POP_OVER.PROCESSED_IN_FACILITY',
  inThirdPartyProject = 'APP.SHARED.CARAC_POP_OVER.REUSED_IN_THIRD_PARTY',
  onSite = 'APP.SHARED.CARAC_POP_OVER.REUSED_ON_SITE',
}
export type ProjectDTO = {
  VerificationData: ProjectVerificationLevel;
  AllotmentNodes: AllotmentNodeDTO[] | null;
  ProjectTypeId: number;
  isAcvDynamic: boolean;
  inCharge: string;
  ddv: number;
  phase: string;
  isUsageComputed: boolean;
  isFreeModeActivated: boolean;
  area: number;
  id: number;
  permitDate: string | null;
  dynamicAcv: number;
  name: string;
  ProjectType: {
    id: number;
    name: string;
    defaultDdv: number;
    availableUnits: string[];
  };
  ProjectOption: {
    ProjectId: number;
    CurrentAllotmentId: number;
    isQuickSimStatic: boolean;
    showAlternativeResult: boolean;
    whichTypeOfCalculator: any;
  };
  DatasComp: {
    paGazObtentionDate: string;
  };
  pathProject: {
    AncestorId: number;
    DescendantId: number;
    depth: number;
    name: string;
  }[];
  createdAt: string;
  staticAcv: number;
  isRe2020: boolean;
  staticRe2020Acv: number;
  dynamicRe2020Acv: number;
  isRset: boolean;
  CompanyId: number;
  comment: string | null;
  Company: {
    name: string;
    id: number;
  };
  isAddedToCompanyReport: boolean;
  Lots: { id: number; name: string; color: string }[];
  Zones:
    | {
        id: number;
        name: string;
        IsConnectedToUrbanNetwork: null | boolean;
        isTAC: boolean;
      }[]
    | any;
  Address: {
    name: string;
    postcode: string;
  };
  PhaseId: { label: string; value: number }[];
  EmissionValues?: {
    AEdif: number;
    AProd: number;
    Global1: number;
    Global2: number;
    TOTAL: number;
  }[];
  UrbanNetworks: [];
  Ic: any[];
  Sheets: [];
  referenceType: string[];
  templateType: string;
  refUnit: string;
  exploitContract: Lifespan;
};

export type ZoneDTO = {
  id: number;
  name: string;
  ProjectSheets: {
    id: number;
    source: string;
  }[];
  ParentId: number | null;
  nbOccupants: number | null;
  BuildingTypeId: number | null;
  IsConnectedToUrbanNetwork: null | boolean;
  isTAC: boolean;
  type: string;
  Zones?: ZoneDTO[];
  area: number;
  plotArea: number | undefined;
  BuildingType: {
    name: string;
  };
};

export type IndicatorDTO = {
  id: number;
  name: string;
  unit: string;
  shortName: string;
};

export type CompanyDTO = {
  id: number;
  name: string;
  companyType: string;
  logoUrl: string;
  address: string;
  salesContactId: number;
  CategoryId: number;
  AdministrativeNodeId: number;
  Contacts: [];
  Contracts: [];
  Employees: [];
  EmployeesCount: number;
  type: string;
  ProjectTypes: number[];
  isAdmin: boolean;
  CompanyCategoryId: number;
  userCount?: number;
  ParentNodeId: number | null;
  freeLicenseCount?: { licenseType: 'pro' | 'read' | 'light'; count: number }[];
};

export type StructuredCompanyDTO = {
  title: string; //companyName
  value: number; // companyId
  parentId: number | null;
  key: number; //
  children?: StructuredCompanyDTO[];
};
