/* eslint-disable no-unused-vars */
import { Dispatch } from 'redux';
import { MapDispatchToPropsParam, MapStateToPropsParam, connect } from 'react-redux';

import Boarding from '../../app/exploitation/Boarding';

import {
  createNewEquipment,
  fetchBoarding,
  getCaracs,
  updateExploitationValue,
} from '../../actions/exploitation';

import { NewEquipmentDTO } from '../../app/models/ExploitationDTO';
import { ProjectDTO } from '../../models/ProjectDTO';

interface IExploitation {
  newEquipment: NewEquipmentDTO;
  offset: number;
  boardingListDatas: {}[];
  isBoardingFull: boolean;
  loadingBoarding: boolean;
}

interface IProjects {
  allGroups: {
    type: [];
  };
  currentProject: ProjectDTO;
  zones: {}[];
}

type State = {
  // products: IProducts,
  projects: IProjects;
  exploitation: IExploitation;
};

const mapStateToProps: MapStateToPropsParam<{}, {}, State> = (state: State) => ({
  newEquipment: state.exploitation.newEquipment,
  allGroups: state.projects.allGroups,
  offset: state.exploitation.offset,
  boardingListDatas: state.exploitation.boardingListDatas,
  isBoardingFull: state.exploitation.isBoardingFull,
  loadingBoarding: state.exploitation.loadingBoarding,
  currentProject: state.projects.currentProject,
  zones: state.projects.zones,
});

const mapDispatchToProps: MapDispatchToPropsParam<{}, {}> = (
  dispatch: Dispatch
) => ({
  updateExploitationValue: (name: string, value: string) => {
    dispatch(updateExploitationValue(name, value));
  },
  getCaracs: (md: {}) => {
    dispatch(getCaracs(md));
  },
  fetchBoarding: (offset: number, id: number) => {
    dispatch(fetchBoarding(offset, id));
  },
  createNewEquipment: () => {
    dispatch(createNewEquipment());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Boarding);
