import { BundleDTO } from '../../app/models/BundleDTO';
import API from '../../app/services/API';

const api = new API();

export const getBundleListByFolder = async (
  companyId: number | null,
  folderSheetId: number | null,
  date: { pageParam: number | undefined },
  searchInput: string
) => {
  const response = await api.getBundleListByFolder(
    companyId,
    folderSheetId,
    date,
    searchInput
  );

  return response.data;
};

export const postBundle = async (data) => {
  const response = await api.postBundle(data);

  return response.data;
};

export const deleteBundle = async (data: BundleDTO) => {
  const response = await api.deleteBundle(data);

  return response.data;
};

export const patchBundle = async (data: BundleDTO) => {
  const response = await api.patchBundle(data);

  return response.data;
};

export type postBundleInProjectProps = {
  currentProjectId: number;
  body: {
    quantity: number;
    SheetId: number;
    type_fiche: 'bundle';
    comment?: string;
    ZoneId: number;
    customName?: string;
  };
};
export const postBundleInProject = async ({
  currentProjectId,
  body,
}: postBundleInProjectProps) => {
  return await api
    .postBundleInProject(currentProjectId, body)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};
