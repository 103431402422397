import { connect } from 'react-redux';
import NavigationAllot from '../../app/projects/LifeCycle/NavigationAllot/index';

import { updateFormValue } from '../../actions/projects';

const mapStateToProps = (state) => ({
  allotSteps: state.projects.allotSteps,
  searchValue: state.projects.searchValue,
  projectAllot: state.projects.projectAllot,
  currentAllotment: state.projects.currentAllotment,
  allGroups: state.projects.allGroups,
  mds: state.projects.mds,
  currentProject: state.projects.currentProject,
});

const mapDispatchToProps = (dispatch) => ({
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationAllot);
