import './impactForm.scss';
import { Controller } from 'react-hook-form';
import { StateDTO } from '../../../../models/GeneralDTO';
import { TYPE_EPCM, TYPE_RE2020 } from '../../../../globalVariable/typeProject';
import {
  isProjectSheetInBuildingSite,
  isProjectSheetInPlot,
  isProjectSheetInPlotZone,
  isRegularProject,
  isRegularProjectNorNooco,
} from '../../SettingsSharedFunction/SettingsSharedFunction';
import { parse } from 'path';
import { set } from 'js-cookie';
import { updateFormValue } from '../../../../../actions/projects';
import { updateMonitoring } from '../../../../../actions/monitoring';
import { useDispatch, useSelector } from 'react-redux';
import NoocoCheckbox from '../../../../shared/NoocoCheckbox';
import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const ImpactForm = ({ control, errors, setValue, getValues, watch }) => {
  const {
    buildingSiteDurationRset,
    parcelAreaRset,
    rsetFile,
    isRsenv,
    currentProject,
    parsedProject,
    editZones,
  } = useSelector((state: StateDTO) => state.projects);
  const dispatch = useDispatch();

  const [isBuildingSiteChecked, setIsBuildingSiteChecked] = useState(
    parsedProject?.zones?.find((el) => el.type === 'buildingSite') ? true : false
  );
  const [isPlotChecked, setIsPlotChecked] = useState(
    Boolean(
      currentProject.Zones.length > 0 && currentProject.Zones[0].type === 'plot'
    )
  );

  const { t } = useTranslation();
  // console.log('isRsenv',
  const test = watch('isBuildingSite');

  //   isRsenv);
  // console.log('rsetFile',
  //   rsetFile);
  // console.log('parcelAreaRset',
  //   parcelAreaRset);
  // console.log('buildingSiteDurationRset',
  //   buildingSiteDurationRset);

  useEffect(() => {
    if (parcelAreaRset) {
      setValue('parcelArea', parcelAreaRset);
    }

    if (buildingSiteDurationRset) {
      setValue('defaultBuildingSiteDuration', buildingSiteDurationRset);
    }
  }, [parcelAreaRset, buildingSiteDurationRset]);

  useEffect(() => {
    setValue(
      'isBuildingSite',
      parsedProject?.zones?.find((el) => el.type === 'buildingSite') ? true : false
    );
    setValue(
      'parcelArea',
      parcelAreaRset ||
        parsedProject.zones?.find((zone) => zone.type === 'plot')?.plotArea ||
        null
    );
  }, [currentProject, parsedProject]);

  useEffect(() => {
    setValue(
      'defaultBuildingSiteDuration',
      currentProject?.defaultBuildingSiteDuration || null
    );
    setValue(
      'isBuildingSite',
      parsedProject?.zones?.find((el) => el.type === 'buildingSite') ? true : false
    );
    setValue(
      'parcelArea',
      parcelAreaRset ||
        parsedProject.zones?.find((zone) => zone.type === 'plot')?.plotArea === 0
        ? '0'
        : parsedProject.zones?.find((zone) => zone.type === 'plot')?.plotArea || null
    );
    setValue(
      'isParcel',
      (currentProject.Zones.length > 0 && currentProject.Zones[0].type === 'plot') ||
        currentProject.isRset
        ? true
        : false
    );
  }, []);

  const setAlert = (message, severity) => {
    dispatch(updateMonitoring('alertSeverity', severity));
    dispatch(updateMonitoring('alertMessage', message));
  };

  return (
    <div className="impact-form-wrapper">
      <h4 className="subtitle-form" style={{ marginLeft: '5%', marginTop: '2%' }}>
        {t('APP.IMPACTFORM.IMPACTFORM.PH_46C546', {
          defaultValue: 'Impacts supplémentaires',
        })}
      </h4>
      <div className="impact-form-core">
        <div className="building-site-form">
          {!isRegularProjectNorNooco(currentProject.templateType) && (
            <Controller
              name="isBuildingSite"
              control={control}
              render={({ field }) => (
                <div style={{ marginTop: '19px' }}>
                  <NoocoCheckbox
                    {...field}
                    checked={isBuildingSiteChecked}
                    name="checkMode"
                    onChange={() => {}}
                    onClick={(e) => {
                      if (
                        !e.target.checked &&
                        isProjectSheetInBuildingSite(parsedProject?.zones)
                      ) {
                        setAlert(
                          t('APP.IMPACTFORM.IMPACTFORM.PH_DF6A4A', {
                            defaultValue:
                              "Vous devez d'abord supprimer les équipements associés",
                          }),
                          'error'
                        );
                        setValue('isBuildingSite', true);
                        setIsBuildingSiteChecked(true);

                        return;
                      }

                      dispatch(
                        updateFormValue('editZones', {
                          ...editZones,
                          buildingSite: e.target.checked,
                          buildingSiteDuration: getValues(
                            'defaultBuildingSiteDuration'
                          ),
                        })
                      );
                      setValue('isBuildingSite', e.target.checked);
                      setIsBuildingSiteChecked(e.target.checked);
                    }}
                  />
                </div>
              )}
            />
          )}

          <p style={{ marginTop: '19px' }}>
            {t('APP.IMPACTFORM.IMPACTFORM.CHANTIER_CHECKBOX', {
              defaultValue: 'Chantier',
            })}
          </p>
          <div>
            <p>
              {t('APP.IMPACTFORM.IMPACTFORM.PH_170EFE', {
                defaultValue: 'Durée du chantier (mois)',
              })}
            </p>
            <div>
              <Controller
                control={control}
                name="defaultBuildingSiteDuration"
                rules={{
                  required: watch('isBuildingSite') === true,
                  pattern: /^(?!0$)[0-9]{1,10}([,.][0-9]{1,10})?$/,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    style={{ width: '100%' }}
                    disabled={watch('isBuildingSite') === false}
                    onChange={(e) => {
                      setValue('defaultBuildingSiteDuration', e.currentTarget.value);
                      dispatch(
                        updateFormValue('editZones', {
                          ...editZones,
                          buildingSiteDuration: e.currentTarget.value,
                        })
                      );
                    }}
                    defaultValue={currentProject?.defaultBuildingSiteDuration}
                    placeholder={t('APP.IMPACTFORM.IMPACTFORM.PH_0C41AF', {
                      defaultValue: 'Durée du chantier',
                    })}
                    variant="outlined"
                    className={`input-boarding researchBar ${
                      errors.defaultBuildingSiteDuration
                        ? 'error-border-form error'
                        : ''
                    }`}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="building-site-form">
          {!isRegularProjectNorNooco(currentProject.templateType) && (
            <Controller
              name="isParcel"
              control={control}
              render={({ field }) => (
                <div style={{ marginTop: '19px' }}>
                  <NoocoCheckbox
                    {...field}
                    checked={isPlotChecked}
                    onChange={(e) => {
                      if (
                        !e.target.checked &&
                        isProjectSheetInPlotZone(parsedProject?.zones)
                      ) {
                        setAlert(
                          t('APP.IMPACTFORM.IMPACTFORM.PH_E87081', {
                            defaultValue:
                              "Vous devez d'abord supprimer les équipements associés",
                          }),
                          'error'
                        );
                        setValue('isParcel', true);
                        setIsPlotChecked(true);

                        return;
                      }

                      if (!e.target.checked) {
                        setValue('isParcel', false);
                        setIsPlotChecked(false);
                        dispatch(
                          updateFormValue('editZones', {
                            ...editZones,
                            plot: null,
                          })
                        );
                      }

                      if (e.target.checked) {
                        setValue('isParcel', true);
                        setIsPlotChecked(true);
                        dispatch(
                          updateFormValue('editZones', {
                            ...editZones,
                            plot: { area: getValues('parcelArea') },
                          })
                        );
                      }
                    }}
                    name="checkMode"
                  />
                </div>
              )}
            />
          )}

          <p style={{ marginTop: '19px' }}>
            {t('APP.IMPACTFORM.IMPACTFORM.PARCELLE_CHECKBOX', {
              defaultValue: 'Parcelle',
            })}
          </p>
          <div>
            <p>
              {t('APP.IMPACTFORM.IMPACTFORM.PH_796BBF', {
                defaultValue: 'Surface de parcelle (m²)',
              })}
            </p>
            <div>
              <Controller
                control={control}
                name="parcelArea"
                rules={{
                  required:
                    watch('isParcel') === true &&
                    currentProject.templateType !== TYPE_EPCM,
                  pattern: /^(0|[1-9][0-9]{0,9})([,.][0-9]{1,10})?$/,
                }}
                // rules={{required : true}}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={
                      watch('isParcel') === false &&
                      currentProject.templateType !== TYPE_RE2020 &&
                      currentProject.templateType !== TYPE_EPCM
                    }
                    defaultValue={
                      parcelAreaRset ||
                      parsedProject.zones?.find((zone) => zone.type === 'plot')
                        ?.plotArea === 0
                        ? '0'
                        : parsedProject.zones?.find((zone) => zone.type === 'plot')
                            ?.plotArea || null
                    }
                    onChange={(e) => {
                      const regex = /^(0|[1-9][0-9]{0,9})([,.][0-9]{1,10})?$/;

                      if (getValues('isParcel') === false) {
                        setValue('isParcel', true);

                        if (regex.test(e.target.value)) {
                          dispatch(
                            updateFormValue('editZones', {
                              ...editZones,
                              plot: {
                                area: Number(
                                  getValues('parcelArea').replace(',', '.')
                                ),
                              },
                            })
                          );
                        }
                      }
                      setValue('parcelArea', e.target.value);
                      if (regex.test(e.target.value)) {
                        dispatch(
                          updateFormValue('editZones', {
                            ...editZones,
                            plot: {
                              area: Number(e.target.value.replace(',', '.')),
                            },
                          })
                        );
                      }
                    }}
                    // onChange={(e) => console.log('test')}
                    // key={parcelAreaRset}
                    style={{ width: '100%' }}
                    placeholder={t('APP.IMPACTFORM.IMPACTFORM.PH_AB6B3D', {
                      defaultValue: 'Surface de la parcelle',
                    })}
                    variant="outlined"
                    className={`input-boarding researchBar ${
                      errors.parcelArea ? 'error' : ''
                    } ${
                      watch('isParcel') === false &&
                      currentProject.templateType !== TYPE_RE2020 &&
                      currentProject.templateType !== TYPE_EPCM
                        ? 'disabled'
                        : ''
                    }`}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImpactForm;
