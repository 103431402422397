import './caracsSVG.scss';
import { Tooltip } from '@material-ui/core';
import React from 'react';
import caracDisabled from '../../../assets/images/equipments/carac_disabled.svg';
import caracError from '../../../assets/images/equipments/carac_error.svg';
import caracIcon from '../../../assets/images/equipments/carac_normal.svg';
import { useTranslation } from 'react-i18next';
import { CaracValuesDTO } from '../CaracPopover/CaracPopover';

type CaracsSVGProps = {
  tableEditMode: boolean;
  doesEquipmentHaveCaracsInEditMode: boolean;
  doesEquipmentHaveCaracsInNormalMode: boolean;
  caracValuesInEditMode: CaracValuesDTO | undefined;
  state: 'filled' | 'error' | 'disabled';
  isLoading?: boolean;
  props?: React.HTMLProps<HTMLImageElement>;
};
const CaracsSVG = ({
  tableEditMode,
  doesEquipmentHaveCaracsInEditMode,
  doesEquipmentHaveCaracsInNormalMode,
  caracValuesInEditMode,
  state,
  isLoading = false,
  ...props
}: CaracsSVGProps) => {
  const { t } = useTranslation();
  let doesEquipmentHaveCaracs =
    tableEditMode && caracValuesInEditMode
      ? doesEquipmentHaveCaracsInEditMode
      : doesEquipmentHaveCaracsInNormalMode;

  if (isLoading)
    return (
      <div className="donutsWrapper">
        <div className="donut" />
      </div>
    );

  return (
    <div>
      {state === 'error' && (
        <img
          src={caracError}
          alt="carac_error"
          {...props}
          style={{ cursor: 'pointer', width: '40px' }}
        />
      )}
      {(state === 'disabled' || state === 'filled') && (
        <Tooltip
          title={
            doesEquipmentHaveCaracs
              ? ''
              : t('APP.SHARED.TOOLTIP.NO_CHARACTERISTICS', {
                  defaultValue: 'Pas de caractéristiques',
                })
          }
        >
          {doesEquipmentHaveCaracs || state === 'filled' ? (
            <img
              src={caracIcon}
              alt="carac_openable"
              {...props}
              style={{ cursor: 'pointer', width: '40px' }}
            />
          ) : (
            <img
              src={caracDisabled}
              alt="carac_disabled"
              style={{ cursor: 'not-allowed', width: '40px' }}
            />
          )}
        </Tooltip>
      )}
    </div>
  );
};

export default CaracsSVG;
