import './bundleCard.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { BundleLineDTO } from '../../../models/BundleDTO';
import { StateDTO } from '../../../models/GeneralDTO';
import { useDrag } from 'react-dnd';
import { useSelector } from 'react-redux';
import BundleCardHeader from './BundleCardHeader';
import BundleDetails from './BundleDetails';
import React, { useEffect, useState } from 'react';

type BundleCardProps = {
  style?: any;
  ref?: any;
  measure?: Function;
  line: BundleLineDTO;
  certificationBundleChoice: {
    Nooco: boolean;
    RE2020: boolean;
    EPCM: boolean;
  };
};

const BundleCard = ({ certificationBundleChoice, line }: BundleCardProps) => {
  const [expanded, setExpanded] = useState(false);
  const { editBundle, newBundle } = useSelector((state: StateDTO) => state.bundle);

  useEffect(() => {
    if (!line.id) {
      setExpanded(true);
    }
  }, [line]);

  useEffect(() => {
    if (!editBundle.find((el) => el.id === line.id) && line.id && expanded) {
      setExpanded(false);
    }
  }, [editBundle]);

  useEffect(() => {
    if (!newBundle) {
      setExpanded(false);
    }
  }, [newBundle]);

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'projectCard',
    item: { line },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult) {
        console.log(`you dropped ${item} into ${dropResult}`);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      // handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <div ref={drag}>
      {!expanded && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              delay: 0.5,
              x: { duration: 1 },
              default: { ease: 'linear' },
            }}
          >
            <BundleCardHeader line={line} setExpanded={setExpanded} />
          </motion.div>
        </AnimatePresence>
      )}

      {expanded && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              delay: 0.5,
              x: { duration: 1 },
              default: { ease: 'linear' },
            }}
          >
            <BundleCardHeader line={line} details={true} />
            <BundleDetails
              certificationBundleChoice={certificationBundleChoice}
              line={line}
              setExpanded={setExpanded}
            />
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};

export default BundleCard;
