import { CompanyDTO } from '../../../../models/ProjectDTO';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import React from 'react';
import isOpenIcon from '../../../../../assets/images/rights/isOpen.png';

export type AccordionRawSummaryProps = {
  columnSize: {
    expandIcon: string;
    licence: string;
    users: string;
    mails: string;
    company: string;
    admin: string;
    delete: string;
  };
  expandedSummary: boolean;
  color: string;
  // employeesCount: number | null,
  // setExpandedSummary: (expandedSummary: string) => void,
  // expandedSubSummary: boolean,
  company: CompanyDTO;
  // setExpandedSubSummary: (expandedSubSummary: string) => void,
};

const AccordionSummary = withStyles({
  root: {
    margin: 0,
    padding: 0,
    // backgroundColor: ' #E4EFFF',
    // backgroundColor: 'red',
    minHeight: 80,
    '&$expanded': {
      minHeight: 80,
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionRawSummary = ({
  columnSize,
  expandedSummary,
  company,
  color,
}: AccordionRawSummaryProps) => {
  console.log('refetch accordionRawSummary');

  return (
    <AccordionSummary
      aria-controls="panel1d-content"
      id="panel1d-header"
      style={{ backgroundColor: color }}
    >
      <div className="companyAccordionRaw">
        {/* column expand */}
        <div
          style={{ width: columnSize.expandIcon }}
          className="expandColumnWrapper"
        >
          <img
            className="expandIcon"
            src={isOpenIcon}
            style={{ transform: expandedSummary ? '' : 'rotate(180deg)' }}
            alt=""
          />
        </div>

        {/* column companies */}
        <div style={{ width: columnSize.company }} className="titleColumnWrapper">
          {company.name}
        </div>

        {/* column users */}
        <div style={{ width: columnSize.users }} className="titleColumnWrapper">
          {company.EmployeesCount}
        </div>
      </div>
    </AccordionSummary>
  );
};

export default AccordionRawSummary;
