import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import * as serviceWorker from './serviceWorker';
import { Amplify } from 'aws-amplify';
import { record } from 'aws-amplify/analytics/kinesis';

import { Authenticator } from '@aws-amplify/ui-react';
import { BrowserRouter } from 'react-router-dom';
import { CookieStorage } from 'aws-amplify/utils';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { createRoot } from 'react-dom/client';
import App from './containers/App/index';
import AppContext from './app/AppContext';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import config from './config';
import dayjs from 'dayjs';

import i18next from './i18n';
import isBetween from 'dayjs/plugin/isBetween'; // import plugin
import store from '../src/store';

const queryClient = new QueryClient();

export const basicConfigAmplify = {
  Auth: {
    Cognito: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolClientId: config.cognito.APP_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_SSO_DOMAIN,
          scopes: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
          redirectSignIn: [
            process.env.REACT_APP_URL_PLATFORM,
            'http://localhost:3000/',
          ],
          redirectSignOut: [
            process.env.REACT_APP_URL_PLATFORM,
            'http://localhost:3000/',
          ],
          responseType: 'code',
        },
      },
      passwordFormat: {
        minLength: 14,
        requireLowercase: true,
        requireNumbers: true,
        requireUppercase: true,
        requireSpecialCharacters: true,
      },
    },
  },
  Analytics: {
    Kinesis: {
      region: config.kinesis.REGION,
    },
    KinesisFirehose: {
      region: config.kinesis.REGION,
    },
  },
  Storage: {
    S3: {
      bucket: config.import.BUCKET,
      region: config.cognito.REGION,
    },
  },
};

// Use plugin and set locale
dayjs.extend(LocalizedFormat);

dayjs.extend(isBetween);

dayjs.locale(i18next?.language.split('-')[0]);

if (process.env.NODE_ENV === 'prod' && process.env.REACT_APP_ENV === 'prod')
  console.log = () => {};

// Storage.configure({
//   AWSS3: {
//     bucket: config.import.BUCKET,
//     region: config.cognito.REGION
//   }
// });

Amplify.configure(basicConfigAmplify);

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <Authenticator.Provider>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider value={{}}>
          <BrowserRouter>
            <I18nextProvider i18n={i18next} defaultNS={'translation'}>
              <App config={config} metrics={record} />
              <ReactQueryDevtools initialIsOpen={false} />
            </I18nextProvider>
          </BrowserRouter>
        </AppContext.Provider>
      </QueryClientProvider>
    </Provider>
  </Authenticator.Provider>
);

serviceWorker.unregister();
