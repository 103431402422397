import { CaracComboDTO, CaracDTO, GetCaracsDTO } from '../../models/EquipmentsDTO';
import { Dropdown, Input } from '@photocarbone/nooco-ui';
import { Popover } from 'antd';
import { Tooltip } from '@material-ui/core';
import { isNumHigherZero, isNumOrNull } from '../utilsFunction';
import { useGetCaracsByAllotmentNodeId } from '../../../hooks/useQuery/equipments';
import CaracsSVG from '../CaracSVG/CaracsSVG';
import NoocoCheckbox from '../NoocoCheckbox';
import FluidsForm, { HandleFluidsChangeReason, NewValueType } from '../FluidsForm';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import informationIcon from '../../../assets/images/equipments/information.png';
import UnamortizedCaracDisplay, {
  UnamortizedValuesDTO,
} from './UnamortizedCaracDisplay/UnamortizedCaracDisplay';
import './caracPopover.scss';
import { Carac } from '@photocarbone/nooco-shared-api-def';
import { CurrentScreen, StateDTO } from '../../models/GeneralDTO';
import { useDispatch, useSelector } from 'react-redux';
import { changeValueEquipments } from '../../../actions/equipments';
import { Dispatch } from 'redux';
import { UseFormGetValues, useFormContext } from 'react-hook-form';
import { EquipmentFromListEquipment } from '../../projects/EquipmentList/ListEquipments/ListEquipmentsFunction';
import { useEffectOnce } from 'react-use';
import { TYPE_FICHE } from '../../globalVariable/generalDefinitions';
import { handleKeyPress } from '../utils';
import { SelectedSearchGroupOptionsLabel } from '../QuickAddBarEquipment/QuickAddBarEquipment';

const screenWhoNeedsCaracsFormatInArray = [
  CurrentScreen.listContributor,
  CurrentScreen.multipleActions,
];

const handleFormatFluidsForPatch = (
  dispatch: Dispatch,
  caracValuesInContributorList: CaracValuesDTO,
  caracValues: CaracValuesDTO,
  row: EquipmentFromListEquipment,
  value: string,
  key: string,
  setCaracValues: Function
) => {
  dispatch(
    changeValueEquipments('caracValuesInContributorList', {
      ...caracValuesInContributorList,
      [row.id]: { ...caracValues, [key]: value },
    })
  );

  let getOtherValueFromFluid: {} =
    key === HandleFluidsChangeReason.FluidQuantity
      ? { selectedFluid: caracValues.selectedFluid || row.childEquip?.id }
      : {
          fluidQuantity:
            caracValues.fluidQuantity ||
            caracValues.fluidChargeQuantity ||
            row.childEquip?.ProjectSheet.quantity,
        };

  setCaracValues({ [key]: value, ...getOtherValueFromFluid });
};

const returnCaracFormatForLDCPatch = (
  fullCarac: Carac | CaracDTO,
  value: string,
  getValues: Function,
  row: EquipmentFromListEquipment,
  secondDiscrete?: Carac | CaracDTO | undefined,
  caracsFromGetCaracs?: GetCaracsDTO
) => {
  let formData = getValues(`ProjectSheets[${row.id}].caracs`);

  const resetSecondDiscreteIfNecessary = (
    formData,
    secondDiscrete: Carac | CaracDTO
  ) => {
    if (formData?.find((carac) => carac.id === secondDiscrete.id)) {
      return formData.map((carac) =>
        carac.id === secondDiscrete.id ? { ...carac, value: null } : carac
      );
    }

    return formData;
  };

  if (!formData && caracsFromGetCaracs.projectSheetCaracs) {
    let neededCaracs = caracsFromGetCaracs.caracs.filter(
      (carac) => carac.neededOnCreation
    );
    let allCaracsExceptEditOne = neededCaracs.filter(
      (carac) => carac.id !== fullCarac.id
    );
    let formatCaracs = allCaracsExceptEditOne.map((carac) => {
      let projectSheetCaracValue =
        caracsFromGetCaracs.projectSheetCaracs.caracs.find(
          (psCarac) => psCarac.CaracId === carac.id
        )?.value[0];
      return { id: carac.id, name: carac.name, value: projectSheetCaracValue };
    });
    return [...formatCaracs, { id: fullCarac.id, name: fullCarac.name, value }];
  } else if (!formData) {
    return [{ id: fullCarac.id, name: fullCarac.name, value }];
  } else if (!formData.find((carac) => carac.id === fullCarac.id)) {
    if (secondDiscrete) {
      formData = resetSecondDiscreteIfNecessary(formData, secondDiscrete);
    }
    return [...formData, { id: fullCarac.id, name: fullCarac.name, value }];
  } else {
    if (secondDiscrete) {
      formData = resetSecondDiscreteIfNecessary(formData, secondDiscrete);
    }
    return formData.map((carac) => {
      if (carac.id === fullCarac.id) {
        carac.value = value;
      }
      return carac;
    });
  }
};

type renderInputRowProps = {
  carac: Carac | CaracDTO | undefined;
  caracValues: CaracValuesDTO;
  setCaracValues: (e: CaracValuesDTO | any) => void;
  key: string;
  disabled?: boolean;
  screen: CurrentScreen;
  dispatch: Dispatch;
  getValues: Function;
  row: EquipmentFromListEquipment;
};
const renderInputRow = ({
  carac,
  setCaracValues,
  caracValues,
  key,
  disabled,
  screen,
  dispatch,
  getValues,
  row,
  caracValuesInContributorList,
  caracsFromGetCaracs,
}: renderInputRowProps) => {
  if (!carac) {
    return null;
  }

  return (
    <div className="carac-row">
      <div className="title">{carac.name}:</div>
      <Input
        onKeyPress={handleKeyPress}
        data-testid="xAxis-input-carac"
        onChange={(value) => {
          if (row) {
            dispatch(
              changeValueEquipments('caracValuesInContributorList', {
                ...caracValuesInContributorList,
                [row.id]: { ...caracValues, [key]: value },
              })
            );
          }
          setCaracValues(
            screenWhoNeedsCaracsFormatInArray.includes(screen)
              ? returnCaracFormatForLDCPatch(
                  carac,
                  value,
                  getValues,
                  row,
                  undefined,
                  caracsFromGetCaracs
                )
              : { ...caracValues, ...carac, [key]: value }
          );
        }}
        disabled={disabled}
        defaultValue={caracValues[key]}
        error={!isNumOrNull(caracValues[key])}
      />
      <div className="unit">{carac.unit}</div>
    </div>
  );
};

type renderDiscreteRowProps = {
  carac: CaracDTO | undefined;
  caracValues: CaracValuesDTO;
  setCaracValues: (e: CaracValuesDTO | any) => void;
  disabled?: boolean;
  screen: CurrentScreen;
  dispatch: Dispatch;
  getValues: Function;
  row: EquipmentFromListEquipment;
  secondDiscrete: CaracDTO | undefined;
  caracValuesInContributorList: any;
};
const renderDiscreteRow = ({
  carac,
  caracValues,
  setCaracValues,
  disabled,
  screen,
  dispatch,
  getValues,
  row,
  secondDiscrete,
  caracValuesInContributorList,
  caracsFromGetCaracs,
}: renderDiscreteRowProps) => {
  if (!carac) {
    return null;
  }

  return (
    <div className="carac-row">
      <div className="title">{carac.name}:</div>
      <Dropdown
        onSelect={(value) => {
          if (row) {
            dispatch(
              changeValueEquipments('caracValuesInContributorList', {
                ...caracValuesInContributorList,
                [row.id]: {
                  ...caracValues,
                  discrete: value,
                  secondDiscrete: null,
                },
              })
            );
          }
          setCaracValues(
            screenWhoNeedsCaracsFormatInArray.includes(screen)
              ? returnCaracFormatForLDCPatch(
                  carac,
                  value,
                  getValues,
                  row,
                  secondDiscrete,
                  caracsFromGetCaracs
                )
              : { ...caracValues, discrete: value, secondDiscrete: null }
          );
        }}
        dataTestId="dropdown-carac-discrete"
        // onSelect={(value) => {}}
        treeCheckable={false}
        disabled={disabled}
        defaultValue={caracValues.discrete}
        options={carac.caracList?.map((carac) => {
          carac.title = carac.value;
          carac.key = carac.value;

          return carac;
        })}
        // allowClear={false}
      />
    </div>
  );
};

type renderSecondDiscreteRowProps = {
  caracCombo: CaracComboDTO;
  setCaracValues: (e: CaracValuesDTO | any) => void;
  caracValues: CaracValuesDTO;
  disabled?: boolean;
  screen: CurrentScreen;
  dispatch: Dispatch;
  getValues: Function;
  row: EquipmentFromListEquipment;
  caracValuesInContributorList: any;
  caracsFromGetCaracs: GetCaracsDTO | undefined;
};
const renderSecondDiscreteRow = ({
  caracCombo,
  caracValues,
  setCaracValues,
  disabled,
  screen,
  dispatch,
  getValues,
  row,
  caracValuesInContributorList,
  caracsFromGetCaracs,
}: renderSecondDiscreteRowProps) => {
  return (
    <div className="carac-row">
      <div className="title">{caracCombo?.valueList[0]?.name}:</div>
      <Dropdown
        onSelect={(value) => {
          if (row) {
            dispatch(
              changeValueEquipments('caracValuesInContributorList', {
                ...caracValuesInContributorList,
                [row.id]: { ...caracValues, secondDiscrete: value },
              })
            );
          }
          setCaracValues(
            screenWhoNeedsCaracsFormatInArray.includes(screen)
              ? returnCaracFormatForLDCPatch(
                  caracCombo?.valueList[0],
                  value,
                  getValues,
                  row,
                  null,
                  caracsFromGetCaracs
                )
              : { ...caracValues, secondDiscrete: value }
          );
        }}
        disabled={caracValues.discrete === null || disabled}
        treeCheckable={false}
        MenuProps={{
          disableScrollLock: true,
        }}
        value={caracValues.secondDiscrete}
        options={caracCombo?.valueList
          ?.find((discrete) => discrete.value === caracValues.discrete)
          ?.valueList?.map((carac) => {
            carac.title = carac.value;
            carac.key = carac.value;

            return carac;
          })}
        allowClear={false}
        dataTestId="dropdown-carac-secondDiscrete"
      />
    </div>
  );
};

type renderCaracPopoverProps = {
  caracsFromGetCaracs: GetCaracsDTO | undefined;
  isPhotovoltaicEquipmentSelected: boolean;
  caracValues: CaracValuesDTO;
  setCaracValues: (e: CaracValuesDTO) => void;
  disabled?: boolean;
  isAdjustableFluid: boolean;
  isUnamortizedChecked?: boolean;
  unamortizedValues?: UnamortizedValuesDTO;
  setUnamortizedValues?: (e: UnamortizedValuesDTO) => void;
  screen: CurrentScreen;
  dispatch: Dispatch;
  getValues: Function;
  row: EquipmentFromListEquipment | undefined;
  caracValuesInContributorList: { [key: number | string]: CaracValuesDTO };
  tableEditMode: boolean;
};
const renderCaracPopover = ({
  caracsFromGetCaracs,
  isPhotovoltaicEquipmentSelected,
  caracValues,
  setCaracValues,
  disabled,
  isAdjustableFluid,
  isUnamortizedChecked,
  unamortizedValues,
  setUnamortizedValues,
  screen,
  dispatch,
  getValues,
  row,
  caracValuesInContributorList,
  tableEditMode,
}: renderCaracPopoverProps) => {
  const equipmentHasCarac =
    caracsFromGetCaracs?.xAxisId ||
    caracsFromGetCaracs?.discreteId ||
    caracsFromGetCaracs?.secondDiscreteId;
  const handleFluidChange = (
    value: NewValueType,
    reason: HandleFluidsChangeReason
  ) => {
    if (
      reason === HandleFluidsChangeReason.IsDefaultFluid &&
      !screenWhoNeedsCaracsFormatInArray.includes(screen)
    ) {
      setCaracValues({
        ...caracValues,
        isDefaultFluid: !value,
        fluidId: null,
        fluidChargeQuantity: null,
      });
    }
    if (
      reason === HandleFluidsChangeReason.SelectedFluid &&
      typeof value === 'number'
    ) {
      if (screenWhoNeedsCaracsFormatInArray.includes(screen)) {
        handleFormatFluidsForPatch(
          dispatch,
          caracValuesInContributorList,
          caracValues,
          row,
          value,
          'selectedFluid',
          setCaracValues
        );
      } else {
        setCaracValues({ ...caracValues, fluidId: value });
      }
    }

    if (
      reason === HandleFluidsChangeReason.FluidQuantity &&
      typeof value === 'string'
    ) {
      screenWhoNeedsCaracsFormatInArray.includes(screen)
        ? handleFormatFluidsForPatch(
            dispatch,
            caracValuesInContributorList,
            caracValues,
            row,
            value,
            'fluidQuantity',
            setCaracValues
          )
        : setCaracValues({ ...caracValues, fluidChargeQuantity: value });
    }
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
      {equipmentHasCarac && (
        <div>
          <div style={{ fontWeight: 'bold' }}>Caractéristiques:</div>
          {/* xAxisRender */}
          {caracsFromGetCaracs?.xAxisId &&
            renderInputRow({
              carac: caracsFromGetCaracs.caracs.find(
                (carac) => carac.id === caracsFromGetCaracs.xAxisId
              ),
              setCaracValues,
              key: 'xAxis',
              caracValues,
              disabled,
              screen,
              dispatch,
              getValues,
              row,
              caracValuesInContributorList,
              caracsFromGetCaracs,
            })}
          {/* discreteRender */}
          {caracsFromGetCaracs?.discreteId &&
            renderDiscreteRow({
              carac: caracsFromGetCaracs.caracs.find(
                (carac) => carac.id === caracsFromGetCaracs.discreteId
              ),
              setCaracValues,
              caracValues,
              disabled,
              screen,
              dispatch,
              getValues,
              row,
              secondDiscrete: caracsFromGetCaracs?.secondDiscreteId
                ? caracsFromGetCaracs.caracs.find(
                    (carac) => carac.id === caracsFromGetCaracs.secondDiscreteId
                  )
                : undefined,
              caracValuesInContributorList,
              caracsFromGetCaracs,
            })}
          {/* secondDiscreteRender */}
          {caracsFromGetCaracs?.secondDiscreteId &&
            renderSecondDiscreteRow({
              caracCombo: caracsFromGetCaracs.caracCombos,
              setCaracValues,
              caracValues,
              disabled,
              screen,
              dispatch,
              getValues,
              row,
              caracValuesInContributorList,
              caracsFromGetCaracs,
            })}
          {/* PhotovoltaicRender */}
          {isPhotovoltaicEquipmentSelected && (
            <>
              {renderInputRow({
                carac: caracsFromGetCaracs?.caracs.find((carac) =>
                  carac.name.includes('Production annuelle : Eef_Prod')
                ),
                setCaracValues,
                key: 'photovoltaicAnnualProduction',
                caracValues,
                screen,
              })}
              {renderInputRow({
                carac: caracsFromGetCaracs?.caracs.find((carac) =>
                  carac.name.includes('Autoconsommation annuelle : Eef_Prod_AC')
                ),
                setCaracValues,
                key: 'photovoltaicSelfConsumption',
                caracValues,
                screen,
              })}
            </>
          )}
          {/* fluid */}
          {isAdjustableFluid && (
            <FluidsForm
              isAddQuickBar={true}
              onChange={handleFluidChange}
              equipmentFromContributorList={row}
              disabledInReadMode={Boolean(
                !tableEditMode && screen === CurrentScreen.listContributor
              )}
            />
          )}
        </div>
      )}
      {equipmentHasCarac && isUnamortizedChecked && (
        <div
          className="test"
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            width: '2px',
            backgroundColor: '#CDCED9',
            margin: '0 4rem',
          }}
        >
          {''}
        </div>
      )}
      {isUnamortizedChecked && setUnamortizedValues && unamortizedValues && (
        <UnamortizedCaracDisplay
          setUnamortizedValues={setUnamortizedValues}
          unamortizedValues={unamortizedValues}
        />
      )}
    </div>
  );
};

export type getCaracStateProps = {
  caracsFromGetCaracs: GetCaracsDTO | undefined;
  caracValues: CaracValuesDTO;
  isPhotovoltaicEquipmentSelected: boolean;
  isUnamortizedChecked?: boolean;
  unamortizedValues?: UnamortizedValuesDTO;
  screen: CurrentScreen;
};
export const getCaracState = ({
  caracsFromGetCaracs,
  isPhotovoltaicEquipmentSelected,
  caracValues,
  isUnamortizedChecked = false,
  unamortizedValues,
  screen,
}: getCaracStateProps): 'disabled' | 'filled' | 'error' => {
  const isDiscreteOrXAxis = () => {
    return (
      caracsFromGetCaracs.discreteId ||
      caracsFromGetCaracs.secondDiscreteId ||
      caracsFromGetCaracs.xAxisId
    );
  };

  if (
    (caracsFromGetCaracs?.caracs?.length === 0 ||
      caracsFromGetCaracs === undefined ||
      !isDiscreteOrXAxis()) &&
    !isUnamortizedChecked
  ) {
    return 'disabled';
  }

  if (
    caracsFromGetCaracs?.xAxisId &&
    !isNumHigherZero(caracValues.xAxis) &&
    screen === CurrentScreen.multipleActions
  ) {
    return 'filled';
  }

  if (caracsFromGetCaracs?.xAxisId && !isNumHigherZero(caracValues.xAxis)) {
    return 'error';
  }

  if (caracsFromGetCaracs?.discreteId && !caracValues.discrete) {
    return 'error';
  }
  if (caracsFromGetCaracs?.secondDiscreteId && !caracValues.secondDiscrete) {
    return 'error';
  }
  // Photovoltaic
  if (
    isPhotovoltaicEquipmentSelected &&
    (!isNumHigherZero(caracValues.photovoltaicSelfConsumption) ||
      !isNumHigherZero(caracValues.photovoltaicAnnualProduction))
  ) {
    return 'error';
  }
  // fluid
  if (
    caracValues.hasFluid &&
    caracValues.isDefaultFluid === false &&
    (!caracValues.fluidId || !isNumHigherZero(caracValues.fluidChargeQuantity))
  ) {
    return 'error';
  }

  // unamortized
  if (
    isUnamortizedChecked &&
    ((!isNumHigherZero(unamortizedValues.residualLifespan) &&
      !unamortizedValues.isResidualLifespanUnknowChecked) ||
      !unamortizedValues.treatmentMode)
  ) {
    return 'error';
  }

  return 'filled';
};

export type CaracValuesDTO = {
  xAxis: string | null;
  discrete: string | null;
  secondDiscrete: string | null;
  photovoltaicSelfConsumption: string | null;
  photovoltaicAnnualProduction: string | null;
  hasFluid: boolean;
  isDefaultFluid: boolean;
  fluidId: number | null;
  fluidChargeQuantity: string | null;
};

export type CaracPopoverProps = {
  AllotmentNodeId: number | null;
  caracValues: CaracValuesDTO;
  setCaracValues: (e: CaracValuesDTO) => void;
  unamortizedValues?: UnamortizedValuesDTO;
  setUnamortizedValues?: (e: UnamortizedValuesDTO) => void;
  isUnamortizedChecked?: boolean;
  isPhotovoltaicEquipmentSelected: boolean;
  isIniesSelected: boolean;
  isAdjustableFluid: boolean;
  ProjectSheetId?: number | undefined;
  screen: CurrentScreen;
  row?: EquipmentFromListEquipment | undefined;
  disabledContributorList?: boolean;
  currentSearchGroup: SelectedSearchGroupOptionsLabel;
};

const initCaracValues = {
  xAxis: null,
  discrete: null,
  secondDiscrete: null,
  photovoltaicSelfConsumption: null,
  photovoltaicAnnualProduction: null,
  hasFluid: false,
  isDefaultFluid: true,
  fluidId: null,
  fluidChargeQuantity: null,
};

export const returnDefaultValueForCaracsInIniesSheet = (
  selectedIniesSheet,
  initCaracs,
  initCaracValues
) => {
  if (initCaracs?.xAxisId) {
    initCaracValues.xAxis = selectedIniesSheet?.find(
      (sheet) => sheet.CaracId === initCaracs.xAxisId
    ).value[0];
  }

  if (initCaracs?.discreteId) {
    initCaracValues.discrete = selectedIniesSheet?.find(
      (sheet) => sheet.CaracId === initCaracs.discreteId
    ).value[0];
  }
  if (initCaracs?.secondDiscreteId) {
    initCaracValues.secondDiscrete =
      selectedIniesSheet?.find(
        (sheet) => sheet.CaracId === initCaracs.secondDiscreteId
      ).value[0] || 'Aucun';
  }

  return initCaracValues;
};

let prevAllotmentNode = {};

const CaracPopover = ({
  AllotmentNodeId,
  caracValues,
  setCaracValues,
  isPhotovoltaicEquipmentSelected,
  isIniesSelected,
  isAdjustableFluid,
  unamortizedValues,
  setUnamortizedValues,
  isUnamortizedChecked,
  screen,
  ProjectSheetId = undefined,
  row,
  disabledContributorList,
  currentSearchGroup,
}: CaracPopoverProps) => {
  const [caracRenderOpen, setCaracRenderOpen] = useState<boolean>(false);
  const [caracIconState, setCaracIconState] = useState<
    'disabled' | 'filled' | 'error'
  >('disabled');

  const { getValues, watch } = useFormContext();
  const dispatch = useDispatch();
  const { tableEditMode, caracValuesInContributorList } = useSelector(
    (state: StateDTO) => state.equipments
  );
  const { allGroups } = useSelector((state: StateDTO) => state.projects);
  const typeFicheWithoutCaracs = [TYPE_FICHE.BUNDLE, TYPE_FICHE.FICHES_CONFIG];

  let caracValuesInEditMode: CaracValuesDTO | undefined =
    caracValuesInContributorList[row?.id];
  let allotmentNodeField = watch(`ProjectSheets[${row?.id}].AllotmentNode`);
  let sheetField = watch(`ProjectSheets[${row?.id}].SheetId`);

  if (sheetField) {
    isIniesSelected = true;
  }

  let isAllotmentFieldOnListContributorScreen =
    screenWhoNeedsCaracsFormatInArray.includes(screen) && allotmentNodeField;
  useEffect(() => {
    if (
      allotmentNodeField &&
      (!prevAllotmentNode[row.id] ||
        (prevAllotmentNode[row.id] &&
          prevAllotmentNode[row.id].id !== allotmentNodeField.id &&
          screen === CurrentScreen.listContributor)) &&
      !sheetField
    ) {
      dispatch(
        changeValueEquipments('caracValuesInContributorList', {
          ...caracValuesInContributorList,
          [row.id]: initCaracValues,
        })
      );
      let modifiedEquipment = allGroups.type.find(
        (md) => md.id === allotmentNodeField?.MasterDataEquipmentId
      );

      let doesModifiedEquipmentHaveCaracs = Boolean(
        modifiedEquipment?.discreteId || modifiedEquipment?.xAxisId
      );

      setCaracRenderOpen(doesModifiedEquipmentHaveCaracs);
    }
  }, [allotmentNodeField]);

  const {
    data: caracsFromGetCaracs,
    isLoading: isLoadingAllotmentNodeCaracs,
    isSuccess: isSuccessGetCaracs,
    remove,
  } = useGetCaracsByAllotmentNodeId({
    allotmentNodeId: isAllotmentFieldOnListContributorScreen
      ? allotmentNodeField.id
      : AllotmentNodeId,
    screen,
    caracRenderOpen: caracRenderOpen,
    ProjectSheetId,
    disabledCaracs:
      (sheetField && typeFicheWithoutCaracs.includes(sheetField.type_fiche)) ||
      currentSearchGroup === SelectedSearchGroupOptionsLabel.INIES,
    setCaracValues,
    getValues,
    row,
    currentSearchGroup,
  });

  useEffect(() => {
    if (
      Object.keys(caracValuesInContributorList).length === 0 &&
      screen === CurrentScreen.listContributor
    ) {
      remove();
    }
  }, [caracValuesInContributorList]);

  let caracDatas =
    screen === CurrentScreen.listContributor && sheetField
      ? returnDefaultValueForCaracsInIniesSheet(
          sheetField.SheetCaracs,
          caracsFromGetCaracs,
          initCaracValues
        )
      : screenWhoNeedsCaracsFormatInArray.includes(screen) && row
      ? caracValuesInContributorList[row.id] || initCaracValues
      : caracValues;

  function doesIniesEquipmentHaveCaracs() {
    const hasCaracs = Boolean(
      caracValuesInContributorList[row?.id]?.xAxis ||
        caracValuesInContributorList[row?.id]?.discrete ||
        caracValuesInContributorList[row?.id]?.secondDiscrete
    );
    return hasCaracs ? 'filled' : 'disabled';
  }
  const caracState = useMemo(
    () =>
      [TYPE_FICHE.BUNDLE, TYPE_FICHE.FICHES_CONFIG].includes(sheetField?.type_fiche)
        ? 'disabled'
        : isIniesSelected
        ? doesIniesEquipmentHaveCaracs()
        : getCaracState({
            caracsFromGetCaracs,
            caracValues: caracDatas,
            isPhotovoltaicEquipmentSelected,
            isUnamortizedChecked,
            unamortizedValues,
            screen,
          }),
    [
      caracsFromGetCaracs,
      isUnamortizedChecked,
      caracValues,
      isPhotovoltaicEquipmentSelected,
      unamortizedValues,
      sheetField,
    ]
  );

  useEffect(() => {
    setCaracIconState(caracState);
  }, [caracState]);

  useEffect(() => {
    if (
      getCaracState({
        caracsFromGetCaracs,
        caracValues: caracDatas,
        isPhotovoltaicEquipmentSelected,
        isUnamortizedChecked,
        unamortizedValues,
      }) === 'error' &&
      !isIniesSelected
    ) {
      if (screen === CurrentScreen.multipleActions && isSuccessGetCaracs) {
        setCaracRenderOpen(false);
      } else setCaracRenderOpen(isSuccessGetCaracs);
    }

    if (
      (isAllotmentFieldOnListContributorScreen &&
        isSuccessGetCaracs &&
        allotmentNodeField &&
        prevAllotmentNode?.id !== allotmentNodeField.id &&
        caracsFromGetCaracs.projectSheetCaracs.AllotmentNodeId !==
          allotmentNodeField.id) ||
      (screen === CurrentScreen.multipleActions && isSuccessGetCaracs)
    ) {
      // Put carac in formData when new AllotmentNode is selected, value of all caracs at null.
      let allCaracsWithDefaultValueAtNull = caracsFromGetCaracs?.caracs
        .filter((carac: Carac) => carac.neededOnCreation)
        .map((carac) => ({ id: carac?.id, name: carac.name, value: null }));
      prevAllotmentNode[row.id] = allotmentNodeField;

      setCaracValues(allCaracsWithDefaultValueAtNull);
    }
  }, [isSuccessGetCaracs]);

  useEffect(() => {
    if (isUnamortizedChecked) {
      setCaracRenderOpen(true);
    }
  }, [isUnamortizedChecked]);

  return (
    <>
      <Popover
        overlayClassName="carac-popover-wrapper"
        open={caracRenderOpen}
        placement="bottom"
        content={renderCaracPopover({
          caracsFromGetCaracs,
          isPhotovoltaicEquipmentSelected,
          caracValues: caracDatas,
          setCaracValues,
          disabled: isIniesSelected || disabledContributorList,
          isAdjustableFluid,
          isUnamortizedChecked,
          unamortizedValues,
          setUnamortizedValues,
          screen,
          dispatch,
          getValues,
          row,
          caracValuesInContributorList,
          tableEditMode,
        })}
        trigger={'click'}
        onOpenChange={(visible) => {
          if (
            !caracsFromGetCaracs?.xAxisId &&
            !caracsFromGetCaracs?.discreteId &&
            !caracsFromGetCaracs?.secondDiscreteId &&
            !isUnamortizedChecked &&
            !screen
          ) {
            setCaracRenderOpen(false);

            return;
          }

          if (visible) {
            setCaracRenderOpen(true);
          } else {
            setCaracRenderOpen(false);
          }
        }}
      >
        <CaracsSVG
          tableEditMode={tableEditMode}
          caracValuesInEditMode={caracValuesInEditMode}
          doesEquipmentHaveCaracsInNormalMode={Boolean(
            row?.MasterDataEquipment?.discreteId || row?.MasterDataEquipment?.xAxisId
          )}
          doesEquipmentHaveCaracsInEditMode={Boolean(
            caracValuesInEditMode?.discrete || caracValuesInEditMode?.xAxis
          )}
          style={{ width: '40px' }}
          state={caracIconState}
          isLoading={isLoadingAllotmentNodeCaracs}
        />
      </Popover>
    </>
  );
};

export default CaracPopover;
