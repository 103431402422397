/* eslint-disable react/no-unescaped-entities */
import { Modal } from 'react-bootstrap';
import {
  REFERENCE_TYPE_NAME,
  TYPE_EPCM,
  TYPE_RE2020,
} from '../globalVariable/typeProject';
import { getReferenceTypeNameFromProjectType } from '../shared/utils';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const IndicatorsModal = ({
  openIndicators,
  currentProject,
  isIndicatorsOpen,
  indicators,
  setCurrentIndicator,
  currentIndicator,
  fetchIndicatorsDatas,
  updateFormValue,
  changeValueEquipments,
}) => {
  const handleClose = () => {
    openIndicators(false);
  };

  const handleIndicatorChoice = (indicatorId) => {
    updateFormValue('patchModeLoading', true);
    fetchIndicatorsDatas(indicatorId);
    changeValueEquipments('hideColumns', []);
    changeValueEquipments('isLoadingProject', true);
    setCurrentIndicator(indicatorId);
    handleClose();
  };

  const { t } = useTranslation();

  useEffect(() => {
    const modal = document.querySelector('.modal-content');

    if (modal) {
      modal.style.width = '175%';
      modal.style.marginLeft = '-20%';
    }
  }, [isIndicatorsOpen]);

  if (currentProject.templateType === TYPE_EPCM) {
    indicators = indicators.filter((indicator) => indicator.id !== 14);
  }

  indicators = indicators.filter((indicator) => {
    return (
      !indicator.referenceType ||
      indicator.referenceType.filter((referenceTypeFromIndicator) => {
        if (
          currentProject.templateType !== TYPE_EPCM &&
          currentProject.templateType !== TYPE_RE2020
        ) {
          if (
            indicator.referenceType.includes(REFERENCE_TYPE_NAME.EPCM) &&
            indicator.referenceType.length === 1
          ) {
            return false;
          } else {
            return true;
          }
        }

        return getReferenceTypeNameFromProjectType(
          currentProject.templateType
        ).includes(referenceTypeFromIndicator);
      }).length > 0
    );
  });

  return (
    <div className="indicators-modal">
      <Modal show={isIndicatorsOpen} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('APP.PROJECTS.INDICATORSMODAL.PH_63F098', {
              defaultValue: 'Indicateurs environnementaux',
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="indicators-subtitle">
            {t('APP.PROJECTS.INDICATORSMODAL.PH_4B23B1', {
              defaultValue: 'Indicateurs décrivant les impacts environnementaux',
            })}
          </p>
          {indicators && (
            <ul className="list-indicators">
              {indicators.map((indicator, index) => {
                if (
                  index < 5 ||
                  (index >= 34 &&
                    currentProject.templateType?.includes(TYPE_RE2020)) ||
                  (indicator.id === 1 &&
                    !currentProject.templateType?.includes(TYPE_EPCM)) ||
                  indicator.id === 26 ||
                  (indicator.referenceType?.includes(REFERENCE_TYPE_NAME.EPCM) &&
                    (indicator.id === 28 || indicator.id === 29))
                ) {
                  return (
                    <div style={{ position: 'relative' }} key={indicator.id}>
                      {currentIndicator && currentIndicator.id === indicator.id && (
                        <ArrowBackIcon
                          style={{
                            position: 'absolute',
                            left: '-20px',
                            transform: 'rotate(180deg)',
                            width: '18px',
                          }}
                        />
                      )}
                      <li
                        key={indicator.name}
                        style={
                          currentIndicator && currentIndicator.id === indicator.id
                            ? { color: '#2196f3' }
                            : null
                        }
                        onClick={() => handleIndicatorChoice(indicator.id)}
                        className="indicator-selection"
                      >
                        {currentProject.templateType?.includes(TYPE_EPCM)
                          ? indicator.relativeName
                          : `${indicator.name} (${indicator.shortName})`}{' '}
                        -> {indicator.unit}
                      </li>
                    </div>
                  );
                }
              })}
            </ul>
          )}

          <p className="indicators-subtitle">
            {t('APP.PROJECTS.INDICATORSMODAL.PH_FE9EF0', {
              defaultValue: 'Indicateurs décrivant l’utilisation des ressources ​',
            })}
          </p>
          {indicators && (
            <ul className="list-indicators">
              {indicators.map((indicator, index) => {
                if (
                  index >= 5 &&
                  index < (currentProject.templateType === TYPE_EPCM ? 17 : 18)
                ) {
                  return (
                    <div style={{ position: 'relative' }} key={indicator.id}>
                      {currentIndicator && currentIndicator.id === indicator.id && (
                        <ArrowBackIcon
                          style={{
                            position: 'absolute',
                            left: '-15px',
                            transform: 'rotate(180deg)',
                            width: '15px',
                          }}
                        />
                      )}
                      <li
                        key={indicator.name}
                        style={
                          currentIndicator && currentIndicator.id === indicator.id
                            ? { color: '#2196f3' }
                            : null
                        }
                        onClick={() => handleIndicatorChoice(indicator.id)}
                        className="indicator-selection"
                      >
                        {indicator.name} ({indicator.shortName}) -> {indicator.unit}{' '}
                      </li>
                    </div>
                  );
                }
              })}
            </ul>
          )}

          <p className="indicators-subtitle">
            {t('APP.PROJECTS.INDICATORSMODAL.PH_03E053', {
              defaultValue: 'Indicateurs décrivant les catégories de déchets',
            })}
          </p>
          {indicators && (
            <ul className="list-indicators">
              {indicators.map((indicator, index) => {
                if (
                  index >= 18 &&
                  index < 21 &&
                  !indicator.name.includes('Composants destinés à la réutilisation')
                ) {
                  return (
                    <div style={{ position: 'relative' }} key={indicator.id}>
                      {currentIndicator && currentIndicator.id === indicator.id && (
                        <ArrowBackIcon
                          style={{
                            position: 'absolute',
                            left: '-15px',
                            transform: 'rotate(180deg)',
                            width: '15px',
                          }}
                        />
                      )}
                      <li
                        key={indicator.name}
                        style={
                          currentIndicator && currentIndicator.id === indicator.id
                            ? { color: '#2196f3' }
                            : null
                        }
                        onClick={() => handleIndicatorChoice(indicator.id)}
                        className="indicator-selection"
                      >
                        {indicator.name} ({indicator.shortName}) {'->'}
                        {indicator.unit}
                      </li>
                    </div>
                  );
                }
              })}
            </ul>
          )}

          <p className="indicators-subtitle">
            {t('APP.PROJECTS.INDICATORSMODAL.PH_5BE445', {
              defaultValue: 'Indicateurs décrivant les flux sortants du système',
            })}
          </p>
          {indicators && (
            <ul className="list-indicators">
              {indicators.map((indicator, index) => {
                if (
                  (index >= 21 &&
                    index < 26 &&
                    indicator.id !== 26 &&
                    !indicator.name.includes('Indicateur') &&
                    !indicator.name.includes('Epuisement des ressources')) ||
                  indicator.name.includes('Composants destinés à la réutilisation')
                ) {
                  return (
                    <div style={{ position: 'relative' }} key={indicator.id}>
                      {currentIndicator && currentIndicator.id === indicator.id && (
                        <ArrowBackIcon
                          style={{
                            position: 'absolute',
                            left: '-15px',
                            transform: 'rotate(180deg)',
                            width: '15px',
                          }}
                        />
                      )}
                      <li
                        key={indicator.name}
                        style={
                          currentIndicator && currentIndicator.id === indicator.id
                            ? { color: '#2196f3' }
                            : null
                        }
                        onClick={() => handleIndicatorChoice(indicator.id)}
                        className="indicator-selection"
                      >
                        {indicator.name} ({indicator.shortName}) {'->'}
                        {indicator.unit}
                      </li>
                    </div>
                  );
                }
              })}
            </ul>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default IndicatorsModal;
