import { withRouter } from 'react-router-dom';
import React from 'react';

const Rsee = ({ parsedProject, currentProject }) => {
  console.log(
    currentProject.Ic[1],
    Object.keys(currentProject.Ic[1]),
    'currentProject.Ic[1]'
  );

  const checkIfNumber = (value) => {
    return typeof value === 'number';
  };

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        maxHeight: '60vh',
        overflow: 'auto',
      }}
    >
      <div
        style={{
          marginTop: '10px',
          backgroundColor: '#e7e7e7',
          borderRadius: '5px',
        }}
      >
        <h3>Projet</h3>
        <div>surface : {currentProject.area} m2</div>

        {Object.keys(currentProject.Ic[1])
          .sort()
          .map((key, id) => {
            const lot = currentProject.Ic[1][key];

            if (!checkIfNumber(lot)) return;

            return (
              <div key={id} style={{ marginTop: '10px' }}>
                {key} : {lot}
              </div>
            );
          })}
      </div>
      {parsedProject.zones.map((zone) => {
        return (
          <div
            key={zone.id}
            style={{
              marginTop: '10px',
              backgroundColor: '#e7e7e7',
              borderRadius: '5px',
            }}
          >
            <h3>{zone.name}</h3>
            <div>surface : {zone.area} m2</div>

            {Object.keys(zone.Ic[1])
              .sort()
              .map((key, id) => {
                const lot = zone.Ic[1][key];

                if (!checkIfNumber(lot)) return;

                return (
                  <div key={id} style={{ marginTop: '10px' }}>
                    {key} : {lot}
                  </div>
                );
              })}

            <h4 style={{ marginTop: '10px', textDecoration: 'underline' }}>
              IcEnergieMax
            </h4>
            {zone.Ic[1].IcEnergieComp
              ? Object.keys(zone.Ic[1].IcEnergieComp)
                  .sort()
                  .map((key, id) => {
                    const lot = zone.Ic[1].IcEnergieComp[key];

                    if (!checkIfNumber(lot)) return;

                    return (
                      <div key={id} style={{ marginTop: '10px' }}>
                        {key} : {lot}
                      </div>
                    );
                  })
              : null}

            <h4 style={{ marginTop: '10px', textDecoration: 'underline' }}>
              IcConstruction max
            </h4>

            {zone.Ic[1].IcConstructionMaxComp
              ? Object.keys(zone.Ic[1].IcConstructionMaxComp)
                  .sort()
                  .map((key, id) => {
                    const lot = zone.Ic[1].IcConstructionMaxComp[key];

                    if (!checkIfNumber(lot)) return;

                    return (
                      <div key={id} style={{ marginTop: '10px' }}>
                        {key} : {lot}
                      </div>
                    );
                  })
              : null}
          </div>
        );
      })}
    </div>
  );
};

export default withRouter(Rsee);
