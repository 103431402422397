import {
  PROJECTS_TYPE_NAMES_FROM_TYPE_ALLOTMENT_EPCM,
  TEMPLATE_TYPE_NAME,
  TYPE_EPCM,
} from '../../../../../globalVariable/typeProject';

// TODO_TRAD : fr
export const getSimplifiedEnergyDefaultDataSelected = (templateType) => {
  return {
    AllotmentNode: !PROJECTS_TYPE_NAMES_FROM_TYPE_ALLOTMENT_EPCM.includes(
      templateType
    )
      ? {
          AllotmentId: 1,
          id: 7002,
          Re2020AllotmentNodeId: 245,
          EpcmAllotmentNodeId: 3953,
          ReSousLotId: null,
          MasterDataEquipmentId: 6548,
          name: "Consommation d'électricité pour les autres usages (RE2020)",
          level: 4,
          isObsolete: false,
          ParentId: 243,
          isReSousLot: false,
          color: '#ffd2b7',
          comment: null,
        }
      : {
          AllotmentId: 1,
          id: 4031,
          Re2020AllotmentNodeId: 139,
          EpcmAllotmentNodeId: 3936,
          ReSousLotId: null,
          MasterDataEquipmentId: 15457,
          name: "Consommation d'électricité pour les autres usages (E+C-)",
          level: 4,
          isObsolete: false,
          ParentId: 4030,
          isReSousLot: false,
          color: '#ffd2b7',
          comment: null,
        },
    MasterDataEquipment: !PROJECTS_TYPE_NAMES_FROM_TYPE_ALLOTMENT_EPCM.includes(
      templateType
    )
      ? {
          name: "Consommation d'électricité pour les autres usages (RE2020)",
          type: 'Pour les autres usages',
          materiel: "Mise à disposition d'électricité",
          categorie: 'Electricité',
          fonction: 'Auxiliaires ventilation',
          lot: 'Energie',
          id: 6548,
          isConfiguredSheetImportAllowed: null,
          hasFluid: null,
          unit: 'kWh/an',
          isConnectedToUrbanNetwork: false,
          referenceType: ['RE2020'],
          xAxisId: null,
          discreteId: 2402,
          secondDiscreteId: null,
          AllotmentNodes: [
            {
              AllotmentId: 1,
              id: 6993,
              Re2020AllotmentNodeId: 134,
              EpcmAllotmentNodeId: 3935,
              ReSousLotId: null,
              MasterDataEquipmentId: 6548,
              name: "Consommation d'électricité pour les autres usages (RE2020)",
              level: 4,
              isObsolete: false,
              ParentId: 132,
              isReSousLot: false,
              color: '#ffd2b7',
              comment: null,
              createdAt: '2022-03-17T17:12:18.029Z',
              updatedAt: '2023-05-04T17:55:01.592Z',
            },
            {
              AllotmentId: 1,
              id: 138,
              Re2020AllotmentNodeId: 139,
              EpcmAllotmentNodeId: 3936,
              ReSousLotId: null,
              MasterDataEquipmentId: 6548,
              name: "Consommation d'électricité pour les autres usages (RE2020)",
              level: 4,
              isObsolete: false,
              ParentId: 137,
              isReSousLot: false,
              color: '#ffd2b7',
              comment: null,
              createdAt: '2022-03-17T17:12:18.100Z',
              updatedAt: '2023-05-04T17:55:01.592Z',
            },
            {
              AllotmentId: 1,
              id: 7002,
              Re2020AllotmentNodeId: 245,
              EpcmAllotmentNodeId: 3953,
              ReSousLotId: null,
              MasterDataEquipmentId: 6548,
              name: "Consommation d'électricité pour les autres usages (RE2020)",
              level: 4,
              isObsolete: false,
              ParentId: 243,
              isReSousLot: false,
              color: '#ffd2b7',
              comment: null,
              createdAt: '2022-03-17T17:12:20.655Z',
              updatedAt: '2023-05-04T17:55:01.592Z',
            },
          ],
          Keywords: [],
        }
      : {
          name: "Consommation d'électricité pour les autres usages (E+C-)",
          type: 'Pour les autres usages',
          materiel: "Mise à disposition d'électricité",
          categorie: 'Electricité',
          fonction: 'Auxiliaires de ventilation',
          lot: 'Energie',
          id: 15457,
          isConfiguredSheetImportAllowed: null,
          hasFluid: null,
          unit: 'kWh/an',
          isConnectedToUrbanNetwork: null,
          referenceType: ['E+C-'],
          xAxisId: null,
          discreteId: 11775,
          secondDiscreteId: null,
          AllotmentNodes: [
            {
              AllotmentId: 1,
              id: 244,
              Re2020AllotmentNodeId: 245,
              EpcmAllotmentNodeId: 3953,
              ReSousLotId: null,
              MasterDataEquipmentId: 15457,
              name: "Consommation d'électricité pour les autres usages (E+C-)",
              level: 4,
              isObsolete: false,
              ParentId: 243,
              isReSousLot: false,
              color: '#ffd2b7',
              comment: null,
              createdAt: '2022-03-17T17:12:20.655Z',
              updatedAt: '2023-05-04T17:55:01.592Z',
            },
            {
              AllotmentId: 1,
              id: 133,
              Re2020AllotmentNodeId: 134,
              EpcmAllotmentNodeId: 3935,
              ReSousLotId: null,
              MasterDataEquipmentId: 15457,
              name: "Consommation d'électricité pour les autres usages (E+C-)",
              level: 4,
              isObsolete: false,
              ParentId: 132,
              isReSousLot: false,
              color: '#ffd2b7',
              comment: null,
              createdAt: '2022-03-17T17:12:18.029Z',
              updatedAt: '2023-05-04T17:55:01.592Z',
            },
            {
              AllotmentId: 1,
              id: 4031,
              Re2020AllotmentNodeId: 139,
              EpcmAllotmentNodeId: 3936,
              ReSousLotId: null,
              MasterDataEquipmentId: 15457,
              name: "Consommation d'électricité pour les autres usages (E+C-)",
              level: 4,
              isObsolete: false,
              ParentId: 4030,
              isReSousLot: false,
              color: '#ffd2b7',
              comment: null,
              createdAt: '2022-09-15T13:20:27.268Z',
              updatedAt: '2023-05-04T17:55:01.592Z',
            },
          ],
          Keywords: [],
        },
    caracs: !PROJECTS_TYPE_NAMES_FROM_TYPE_ALLOTMENT_EPCM.includes(templateType)
      ? {
          isConfiguredSheetImportAllowed: null,
          unit: 'kWh/an',
          xAxisId: null,
          discreteId: 2402,
          secondDiscreteId: null,
          caracs: [
            {
              MasterDataEquipmentId: 6548,
              id: 2402,
              min: null,
              max: null,
              defaultValue: null,
              type: 'list',
              neededOnCreation: true,
              neededOnImplementation: null,
              unit: null,
              name: "Champ d'application",
              isFiltrable: false,
              values: ['Résidentiel', 'Tertiaire'],
              createdAt: '2021-06-14T14:46:14.408Z',
              updatedAt: '2023-01-31T20:32:05.323Z',
              caracList: [
                {
                  value: 'Résidentiel',
                },
                {
                  value: 'Tertiaire',
                },
              ],
              value: 'Tertiaire',
            },
          ],
          id: 6548,
          sheets: [],
          caracCombos: {
            MasterDataEquipmentId: 6548,
            id: 2402,
            name: "Champ d'application",
            valueList: [
              {
                value: 'Résidentiel',
              },
              {
                value: 'Tertiaire',
              },
            ],
          },
        }
      : {
          isConfiguredSheetImportAllowed: null,
          unit: 'kWh/an',
          xAxisId: null,
          discreteId: 11775,
          secondDiscreteId: null,
          caracs: [
            {
              MasterDataEquipmentId: 15457,
              id: 11775,
              min: null,
              max: null,
              defaultValue: null,
              type: 'list',
              neededOnCreation: true,
              neededOnImplementation: null,
              unit: null,
              name: "Champ d'application",
              isFiltrable: false,
              values: ['Tertiaire', 'Résidentiel'],
              createdAt: '2022-09-13T08:55:47.418Z',
              updatedAt: '2023-01-31T20:31:48.158Z',
              caracList: [
                {
                  value: 'Résidentiel',
                },
                {
                  value: 'Tertiaire',
                },
              ],
              value: 'Tertiaire',
            },
          ],
          id: 15457,
          sheets: [],
          caracCombos: {
            MasterDataEquipmentId: 15457,
            id: 11775,
            name: "Champ d'application",
            valueList: [
              {
                value: 'Tertiaire',
              },
              {
                value: 'Résidentiel',
              },
            ],
          },
        },
  };
};
