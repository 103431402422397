import './rowSkeleton.scss';
import { ScreenType } from '../../../globalVariable/enumType';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';

type RowSkeletonProps = {
  screen?: ScreenType;
};

const RowSkeleton = ({ screen }: RowSkeletonProps) => (
  <div className="row-skeleton">
    <Skeleton variant="rounded" width={'25%'} height={30} />
    <Skeleton variant="rounded" width={'25%'} height={30} />
    <Skeleton variant="rounded" width={'15%'} height={85} />
    {screen === ScreenType.MAPPING_REF && (
      <Skeleton variant="rounded" width={'15%'} height={30} />
    )}
    <Skeleton variant="circular" width={60} height={60} />
  </div>
);

export default RowSkeleton;
