import { Alert } from '@material-ui/lab';
import { updateMonitoring } from '../../actions/monitoring';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

const SnackBarElement = () => {
  const { alertMessage, alertSeverity } = useSelector((state) => state.monitoring);
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(updateMonitoring('alertMessage', ''));
  };

  return (
    <Snackbar
      open={alertMessage}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={alertSeverity}>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarElement;
