import { FETCH_PROJECT_FOR_GRAPH, loadFullProject } from '../actions/variants';
import config from '../config';

import API from '../app/services/API';
import { basicConfigAmplify } from '../index.js';
import { Amplify } from 'aws-amplify';
const api = new API();

const reportMiddleware = (store) => (next) => (action) => {
  const { projects } = store.getState();
  const { id } = action;

  switch (action.type) {
    case FETCH_PROJECT_FOR_GRAPH:
      api
        .getProjectById(
          action?.project?.id || action.project.project.id,
          projects.currentIndicator.id,
          projects.currentProject.isRe2020
        )
        .then((response) => {
          if (response.status === 201) {
            Amplify.configure({
              ...basicConfigAmplify,
              Storage: {
                S3: {
                  bucket: config.project.BUCKET,
                  region: config.cognito.REGION,
                },
              },
            }).catch((err) => {
              console.log(err);
            });

            const response = downloadFileOnS3(
              `${projects.user.username}/${action.project.id}.json`
            );

            response.then((res) => {
              res.text().then((string) => {
                // handle the String data return String

                let format = JSON.parse(string);

                let response = {
                  data: {
                    project: format.project,
                    mds: format.mds,
                    urbanNetworks: format.urbanNetworks,
                    zones: format.zones,
                  },
                };

                store.dispatch(
                  loadFullProject(
                    response.data,
                    projects.allGroups,
                    action.isSelected,
                    projects.currentIndicator,
                    projects.currentProject
                  )
                );
              });
            });
          } else {
            store.dispatch(
              loadFullProject(
                response.data,
                projects.allGroups,
                action.isSelected,
                projects.currentIndicator,
                projects.currentProject
              )
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
      next(action);
      break;
    default:
      next(action);
  }
};

export default reportMiddleware;
