import { AllotmentNodeDTO } from '../../../models/ProjectDTO';
import { MasterDataEquipmentDTO } from '../../../models/SimulatorDTO';
import {
  getFlatContributorListTableData,
  sortFilterSousLot,
} from '../../../shared/utils';

export type EquipmentFromListEquipment = {
  id: number;
  detailsNode: any[];
  nodesLinkToSameMd: AllotmentNodeDTO[];
  type_fiche: string;
  source: string;
  lot: string;
  Unit?: {
    name: string;
  };
  ProjectSheet: {
    Unit?: {
      name: string;
    };
    ZoneId?: number;
  };
  MasterDataEquipment?: {
    unit: string;
  };
  children?: EquipmentFromListEquipment[];
};

export const listEquipmentDisplayUnity = (
  equipment: EquipmentFromListEquipment,
  buildingSitesIds: number[],
  unitLinkedToChosenAllotmentNode: string
): string => {
  if (unitLinkedToChosenAllotmentNode) {
    return unitLinkedToChosenAllotmentNode;
  }
  const equipmentZoneId = equipment.ProjectSheet?.ZoneId;

  if (equipment.type_fiche === 'bundle') {
    return equipment.Unit?.name;
  }
  if (
    equipment.source === 'Calculette chantier' ||
    (equipmentZoneId && buildingSitesIds.includes(equipmentZoneId))
  ) {
    let unitName =
      equipment.ProjectSheet.Unit?.name || equipment.MasterDataEquipment?.unit || '';

    if (unitName !== '') {
      unitName = unitName.split('/an')[0];
    }

    return unitName;
  }

  return (
    equipment.ProjectSheet?.Unit?.name || equipment.MasterDataEquipment?.unit || ''
  );
};

export const returnTypeFicheAfterChangeNode = (
  isSameMd: MasterDataEquipmentDTO | null,
  currentTypeFiche: string
) => {
  return currentTypeFiche === 'FC' ? 'FC' : isSameMd ? currentTypeFiche : 'users';
};

export const returnAllValuesFromColumn = (
  columnKey,
  flattenData,
  dataFromLocalStorage
) => {
  const rawValuesOfColumnFilter = [
    ...new Set(flattenData.map((data) => data[columnKey])),
  ];

  let columnFilterData = rawValuesOfColumnFilter
    .map((data) => ({
      name: String(data),
      value: data,
      checked:
        dataFromLocalStorage &&
        dataFromLocalStorage.values.includes(data.toLowerCase()),
    }))
    .filter((data) => data !== null);

  let sortedColumnFilterData;

  if (['re2020Lot', 'lotRe2020'].includes(columnKey)) {
    sortedColumnFilterData = sortFilterSousLot(columnFilterData);
  } else {
    sortedColumnFilterData = columnFilterData.sort((a, b) => {
      if (typeof a.value === 'string' && typeof b.value === 'number') {
        return -1;
      } else if (typeof a.value === 'number' && typeof b.value === 'string') {
        return 1;
      } else {
        return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
      }
    });
  }

  return sortedColumnFilterData;
};

export const initStateOfFilter = (
  columnsData,
  dataFromTable,
  datasFromLocalStorage
) => {
  const flattenData = getFlatContributorListTableData(dataFromTable);

  const newFilters = columnsData
    .filter((column) => column.key)
    .map((column) => {
      const allValuesFromColumn = returnAllValuesFromColumn(
        column.key,
        flattenData,
        datasFromLocalStorage
          ? datasFromLocalStorage.find((data) => data.name === column.key)
          : null
      );

      return { name: column.key, values: allValuesFromColumn };
    });

  return newFilters;
};

export const handleTableEdit = (editData, newData) => {
  let datas = [];

  let isAlreadyInEditData = editData.find((data) => data.id === newData.id);

  if (isAlreadyInEditData) {
    datas = editData.map((line) =>
      line.id === newData.id ? { ...line, ...newData } : line
    );
  } else {
    datas = [...editData, newData];
  }

  return datas;
};

export const returnListOfZonesForSelect = (zones, all) => {
  zones
    .filter((el) => el.type !== 'building')
    .forEach((zone) => {
      let object = { ...zone, label: zone.name, value: zone.id };
      let bool = zone.ParentId;
      let i = 0;

      while (bool) {
        if (bool) {
          object.name = `${zones.find((el) => el.id === bool).name} ${
            zones.find((el) => el.id === bool).type === 'building' ? '- ' : ''
          }${
            zone.type === 'buildingSite' && i === 0 ? `(${zone.name})` : object.name
          }`;
          object.label = object.name;

          if (
            zones.find((el) => el.id === bool).ParentId &&
            zones.find((el) => el.id === zones.find((el) => el.id === bool).ParentId)
              .type !== 'plot'
          ) {
            bool = zones.find((el) => el.id === bool).ParentId;
          } else {
            bool = false;
          }

          i++;
        }
      }

      all = [...all, object];

      return all;
    });

  return all;
};
