import './bundleDetails.scss';
import { AllotmentNodeDTO } from '../../../../models/ProjectDTO';
import { TextField, Tooltip } from '@mui/material';
import { BundleDTO, BundleLineDTO } from '../../../../models/BundleDTO';
import { Button, Dropdown, Input, SearchGroup } from '@photocarbone/nooco-ui';
import { Popover } from 'antd';
import { StateDTO } from '../../../../models/GeneralDTO';
import { Switch } from 'antd';
import {
  findMdFromAllotmentNode,
  findParentNode,
  handleAllotmentNodes,
  handleKeyPress,
  returnSearchBarOptionsFiltered,
} from '../../../../shared/utils';
import { updateBundleValue } from '../../../../../actions/bundle';
import { updateFormValue } from '../../../../../actions/projects';
import { updateLineFromBundle } from '../..';
import {
  useCreateBundle,
  usePatchBundle,
} from '../../../../../hooks/useQuery/bundle';
import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage, useUpdateEffect } from 'react-use';
import API from '../../../../services/API';
import BreadcrumbModal from '../../../../../containers/BreadcrumbModal';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import React, { useEffect, useState } from 'react';
import caracs from '../../../../../assets/images/import/specification.png';
import { useTranslation } from 'react-i18next';
import {
  commaToDot,
  isNum,
  isNumHigherZero,
  onChangeSelectedSearchGroupType,
} from '../../../../shared/utilsFunction';
import '../../../../../app/projects/EquipmentList/AddEquipment/ModelingEquipment/CarbonEstimation/carbonEstimation.scss';
import '../../../../../app/projects/EquipmentList/AddEquipment/ModelingEquipment/CarbonEstimation/MaterialTable/materialTable.scss';
import { getSheetType } from '../BundleCardHeader';
import { Amplify } from 'aws-amplify';
import { basicConfigAmplify } from '../../../../..';
import { downloadData } from 'aws-amplify/storage';
import config from '../../../../../config';
import convert from 'xml-js';
import RenderCarbonEstimation from './Renders/RenderCarbonEstimation';
import RenderModelingPhase from './Renders/RenderModelingPhase';
import RenderConfiguredSheet from './Renders/RenderConfiguredSheet';
import { SEARCH_GROUP_OPTION_VALUES } from '../../../../globalVariable/searchGroupType';
import {
  LOTS_IDS,
  MODELED_PRODUCT_ALLOTMENT_ID,
  TYPE_FICHE,
} from '../../../../globalVariable/generalDefinitions';
import {
  ALLOTMENT_IDS,
  MODELED_PRODUCT_LOT_ID,
} from '../../../../globalVariable/typeProject';
import { SelectedSearchGroupOptionsLabel } from '../../../../shared/QuickAddBarEquipment/QuickAddBarEquipment';

const add = require('../../../../../assets/images/exploitation/add.png');

const api = new API();

type BundleDetailsProps = {
  setExpanded: Function;
  line: BundleDTO;
  certificationBundleChoice: {
    Nooco: boolean;
    RE2020: boolean;
    EPCM: boolean;
  };
};

// TODO_TRAD
const bundleUnitOptions = [
  { value: 'Ensemble', title: 'Ensemble' },
  { value: 'Unité', title: 'Unité' },
  { value: 'kg', title: 'kg' },
  { value: 'm²', title: 'm²' },
  { value: 'm', title: 'm' },
  { value: 'm³', title: 'm³' },
  { value: 'ml', title: 'ml' },
  { value: 'km', title: 'km' },
  { value: 'mm', title: 'mm' },
  { value: 'cm', title: 'cm' },
  { value: 't', title: 't' },
  { value: 'h', title: 'h' },
];

export const getModelingUnitOption = (allGroups) => {
  const modeledProductAllotments = allGroups.flatAllotments.filter(
    (el) => el.ParentId === MODELED_PRODUCT_ALLOTMENT_ID
  );
  let modeledProductUnit: {
    value: string;
    unit: string;
    title: string;
    id: number;
  }[] = [];
  modeledProductAllotments.forEach((allotment) => {
    const md = findMdFromAllotmentNode(allGroups.type, allotment);
    if (md) {
      modeledProductUnit.push({
        value: md.unit,
        unit: md.unit,
        title: md.unit,
        id: md.id,
      });
    }
  });

  return modeledProductUnit;
};

export const reformatOptionToDropdown = (options) => {
  return options.reduce((uniqueObjects, node) => {
    if (!uniqueObjects.some((obj) => obj.name === node.name)) {
      uniqueObjects.push({
        ...node,
        title: node.name,
        value: node.name,
        key: node.name,
      });
    }

    return uniqueObjects;
  }, []);
};

let typeWaterOrEnergy = ['eau', 'energie'];

const BundleDetails = ({
  setExpanded,
  line,
  certificationBundleChoice,
}: BundleDetailsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [equipHaveCaracs, setEquipHaveCaracs] = useState(false);
  const { newBundle, folderSheetId, editBundle, companyId } = useSelector(
    (state: StateDTO) => state.bundle
  );
  const {
    allGroups,
    listOfNodesSearchBar,
    listOfNodesSearchBarWithConfiguredSheetAllowed,
    bundleOptions,
    modeledProductOptions,
    certifiedAllotments,
    user,
  } = useSelector((state: StateDTO) => state.projects);

  const {
    isBundleSheet,
    isConfiguredSheet,
    isModelingSheet,
    isModelingPhase,
    isModelingEstimate,
  } = getSheetType(line);

  const unitOptions = isModelingSheet
    ? getModelingUnitOption(allGroups)
    : bundleUnitOptions;
  const isNewBundle = !line.id ? true : false;

  const createBundleHook = useCreateBundle();
  const patchBundleHook = usePatchBundle();

  const editBundleLine = editBundle.find((bundle) => bundle.id === line.id);
  const allotmentNodeNooco =
    line?.AllotmentNodes &&
    line?.AllotmentNodes.find((node) => node.AllotmentId === ALLOTMENT_IDS.NOOCO);
  const findParentOfAllotmentNode =
    findParentNode(allotmentNodeNooco, allGroups.flatAllotments, [], [])[0].id ===
    MODELED_PRODUCT_LOT_ID;

  const [isCoeffChecked, setIsCoeffChecked] = useState(line?.security_coefficient);
  const [searchCaracs, setSearchCaracs] = useState<BundleLineDTO>();
  const [xmlFile, setXmlFile] = useState<undefined | File | { name: string }>(
    undefined
  );
  const [isAssignementToGraph, setIsAssignementToGraph] = useState(
    !findParentOfAllotmentNode
      ? editBundleLine?.AllotmentNodes && editBundleLine?.AllotmentNodes.length > 0
      : false
  );
  const [selectedSearchGroupType, setSelectedSearchGroupType] =
    useLocalStorage<SelectedSearchGroupOptionsLabel>(
      `selectedSearchGroupType-${user.userId}`,
      SelectedSearchGroupOptionsLabel.Equipements
    );

  const searchBarOptionsFiltered = returnSearchBarOptionsFiltered({
    allAllotments: allGroups.flatAllotments,
    allotmentsAvailable: isConfiguredSheet
      ? listOfNodesSearchBarWithConfiguredSheetAllowed
      : listOfNodesSearchBar,
    iniesOptions: [],
    bundleOptions,
    modeledProductOptions,
  });

  const getCaracs = async () => {
    let AllotmentNodeId = searchCaracs.AllotmentNodes.find(
      (node) => node.MasterDataEquipmentId
    )?.id;
    api.getEquipmentCaracListById(AllotmentNodeId).then((response) => {
      setEquipHaveCaracs(false);
      response.data.caracs = response.data.caracs.filter(
        (el) => el.neededOnCreation || el.neededOnImplementation
      );

      let bundleUpdated = updateLineFromBundle(
        !line.id && newBundle
          ? newBundle
          : editBundle.find((bundle) => bundle.id === line.id),
        searchCaracs,
        'caracs',
        response.data
      );

      if (!bundleUpdated.id) {
        dispatch(updateBundleValue('newBundle', bundleUpdated));
      } else {
        dispatch(
          updateBundleValue(
            'editBundle',
            editBundle.map((bundle) =>
              bundle.id === bundleUpdated.id ? bundleUpdated : bundle
            )
          )
        );
      }
    });
  };

  const setXmlFileFromS3 = async () => {
    Amplify.configure({
      ...basicConfigAmplify,
      Storage: {
        S3: {
          bucket: config.config.BUCKET,
          region: config.cognito.REGION,
        },
      },
    });
    const path = folderSheetId
      ? `${companyId}/${folderSheetId}/${line.id}.xml`
      : `${companyId}/${line.id}.xml`;
    const { body } = await downloadData({
      key: path,
    }).result;

    const fileName = line?.fileName;
    // Créer un Blob avec le contenu XML
    const blob = new Blob([body], { type: 'application/xml' });

    // Créer un File à partir du Blob
    const fileOptions = { type: blob.type };
    const newXmlFile = new File([blob], fileName, fileOptions);

    if (newXmlFile) {
      setXmlFile(newXmlFile);
      let editLine = editBundle.find((bundle) => bundle.id === line.id);
      handleChangeEditBundleLines(editLine, editLine.lines[0], fileName, 'fileName');
    } else {
      setXmlFile({ name: 'Aucun fichier XML trouvé' });
    }
  };

  useEffect(() => {
    if (!isNewBundle && isConfiguredSheet) {
      setXmlFileFromS3();
    }
  }, []);

  useUpdateEffect(() => {
    if (searchCaracs) {
      getCaracs();
    }
  }, [searchCaracs]);

  const renderCaracPopOver = (equipment) => {
    let caracsList =
      !line.id && newBundle
        ? equipment.caracs.caracs
        : equipment.caracs
        ? equipment.caracs.caracs
        : equipment.Caracs.map((carac) => ({
            ...carac,
            value: carac.SheetCarac.value[0],
            caracList: carac.caracList,
          }));

    let caracCombos =
      (!line.id && newBundle) || !Array.isArray(equipment.caracs.caracCombos)
        ? equipment.caracs.caracCombos
        : equipment.caracCombos
        ? equipment.caracCombos[0]
        : equipment.caracs.caracCombos;

    const handleChangeCarac = (value: string, currentCarac) => {
      let newCaracs = caracsList.map((carac) => {
        if (carac.id === currentCarac.id) {
          return {
            ...currentCarac,
            value,
          };
        }
        if (
          caracCombos?.valueList &&
          caracCombos.valueList[0] &&
          currentCarac.id === caracCombos.id &&
          carac.id === caracCombos.valueList[0].id
        ) {
          return {
            ...carac,
            value: null,
          };
        }

        return carac;
      });

      let bundle = editBundle.find((el) => el.id === line.id) || line;

      let newBundle = {
        ...bundle,
        lines: bundle.lines?.map((bundleLine) =>
          bundleLine.key === equipment.key
            ? {
                ...equipment,
                caracs: { ...equipment.caracs, caracs: newCaracs },
              }
            : bundleLine
        ),
      };

      if (!line.id) {
        dispatch(updateBundleValue('newBundle', newBundle));
      } else {
        dispatch(
          updateBundleValue(
            'editBundle',
            editBundle.map((bundle) =>
              bundle.id === newBundle.id ? newBundle : bundle
            )
          )
        );
      }
    };

    let currentMd = allGroups.type.find(
      (md) => md.id === caracCombos?.MasterDataEquipmentId
    );

    return (
      <div>
        {caracsList
          .sort(
            (a, b) =>
              (a.id === currentMd?.secondDiscreteId) -
                (b.id === currentMd?.secondDiscreteId) ||
              (a.id === currentMd?.discreteId) - (b.id === currentMd?.discreteId) ||
              (a.id === currentMd?.xAxisId) - (b.id === currentMd?.xAxisId)
          )
          .map((carac) => {
            let caracListOptions = carac.caracList;

            if (
              caracsList?.find((el) => el.id === caracCombos?.id)?.value &&
              caracCombos.valueList.find(
                (el) =>
                  el.value ===
                    caracsList?.find((car) => car.id === caracCombos.id)?.value &&
                  carac.name === el.name
              )
            ) {
              caracListOptions = caracCombos.valueList.find(
                (el) =>
                  el.value ===
                  caracsList?.find((el) => el.id === caracCombos.id)?.value
              )?.valueList;
            }

            let defaultCaracValue =
              !line.id && newBundle
                ? null
                : caracsList?.find((el) => el.name === carac.name)?.value;

            return (
              <div className="exploitation-carac" key={carac.id}>
                <p>{carac.name} :</p>
                {carac.type !== 'list' ? (
                  <TextField
                    style={{ width: '50%', marginLeft: '10px' }}
                    onKeyPress={handleKeyPress}
                    defaultValue={defaultCaracValue}
                    onChange={(e) => handleChangeCarac(e.target.value, carac)}
                  />
                ) : (
                  <Dropdown
                    // onChange={(e) => handleChangeCarac(e.target.value,
                    //   carac)}
                    defaultValue={{
                      title: defaultCaracValue,
                      value: defaultCaracValue,
                    }}
                    treeCheckable={false}
                    onChange={(value) => handleChangeCarac(value, carac)}
                    style={{ marginLeft: '10px', width: '150px' }}
                    onSelect={() => {}}
                    disabled={
                      caracCombos && caracCombos.valueList
                        ? caracCombos.valueList.find((el) => el.id === carac.id) &&
                          carac.id !== caracCombos.id &&
                          !caracsList?.find((el) => el.id === caracCombos.id)?.value
                        : false
                    }
                    key={caracsList}
                    defaultValue={defaultCaracValue}
                    variant="outlined"
                    className="input-boarding researchBar"
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    options={
                      caracListOptions?.map((el) => ({
                        ...el,
                        title: el.value,
                      })) || []
                    }
                  />
                )}

                <p>{carac.unit}</p>
              </div>
            );
          })}
      </div>
    );
  };

  const deleteLines = (key: number) => {
    if (isNewBundle) {
      dispatch(
        updateBundleValue('newBundle', {
          ...newBundle,
          lines: [...bundleLines.filter((el) => el.key !== key)],
        })
      );
    } else {
      let linesUpdated = bundleLines.filter((el) => el.key !== key);

      dispatch(
        updateBundleValue(
          'editBundle',
          editBundle.map((bundle) =>
            bundle.id === line.id ? { ...bundle, lines: linesUpdated } : bundle
          )
        )
      );
    }
  };

  const handleChangeEditBundle = (key: string, value: string) => {
    dispatch(
      updateBundleValue(
        'editBundle',
        editBundle.map((bundle) =>
          bundle.id === line.id ? { ...bundle, [key]: value } : bundle
        )
      )
    );
  };

  const handleChangeEditBundleLines = (
    line: BundleDTO,
    equipment: BundleLineDTO,
    newValue: AllotmentNodeDTO | string,
    key: string,
    subKey?: string
  ) => {
    if (typeof newValue === 'string' && newValue.includes(',')) {
      newValue = newValue.replace(',', '.');
    }
    let newLineEquipment = { ...equipment };
    if (subKey) {
      newLineEquipment[key] = { ...newLineEquipment[key], [subKey]: newValue };
    } else {
      newLineEquipment = { ...equipment, [key]: newValue };
    }

    if (key === 'AllotmentNodes') {
      newLineEquipment.Sheet = null;

      setSearchCaracs(newLineEquipment);
    } else if (key === 'Sheet') {
      newLineEquipment.AllotmentNodes =
        newValue.AllotmentNodes?.length === 1
          ? [
              ...newValue.AllotmentNodes,
              allGroups.flatAllotments.find(
                (node) =>
                  node.id === newValue.AllotmentNodes[0].Re2020AllotmentNodeId
              ),
              allGroups.flatAllotments.find(
                (node) => node.id === newValue.AllotmentNodes[0].EpcmAllotmentNodeId
              ),
            ]
          : newValue.AllotmentNodes;

      newLineEquipment.AllotmentNode = newValue.AllotmentNodes[0];
    }

    let newEditBundle = editBundle.find((bundle) => bundle.id === line.id);
    newEditBundle.lines = newEditBundle.lines.map((bd) =>
      bd.key === equipment.key ? newLineEquipment : bd
    );

    dispatch(
      updateBundleValue(
        'editBundle',
        editBundle.map((bundle) =>
          bundle.id === newEditBundle.id ? newEditBundle : bundle
        )
      )
    );
  };

  const isTypeFicheBundleValid = ({ mode }: { mode: 'edit' | 'create' }) => {
    const currentBundle =
      mode === 'edit'
        ? editBundle?.find((bundle) => bundle.id === line.id)
        : newBundle;

    if (
      (!currentBundle?.unit && !currentBundle?.Unit?.name) ||
      !currentBundle?.name ||
      !currentBundle?.lines
    ) {
      return false;
    }
    const bundleLines = currentBundle?.lines;
    return !Boolean(
      bundleLines?.find((line) => {
        if (line?.type_fiche === 'custom_user') {
          return !isNumHigherZero(line?.quantity);
        } else if (
          (line && line.Sheet?.type_fiche && !line.quantity) ||
          (!line.Sheet?.type_fiche &&
            !line?.AllotmentNodes?.find((el) => el && el?.MasterDataEquipmentId)) ||
          !line.quantity ||
          line.quantity === '' ||
          line?.caracs?.caracs
            ?.filter((carac) => carac.neededOnCreation)
            ?.find((carac) => carac && (!carac.value || carac.value === ''))
        ) {
          return line;
        }
      })
    );
  };

  const isTypeConfiguredSheetValid = ({ mode }: { mode: 'edit' | 'create' }) => {
    const currentBundle =
      mode === 'edit'
        ? editBundle?.find((bundle) => bundle.id === line.id)
        : newBundle;
    if (
      !currentBundle?.name ||
      !currentBundle?.lines ||
      currentBundle.lines[0].fileName === '' ||
      currentBundle.lines[0].caracs === null
    ) {
      return false;
    }
    const newBundleLines = currentBundle?.lines;
    return !Boolean(
      newBundleLines?.find((line) => {
        if (
          (line && !line?.AllotmentNodes) ||
          line?.caracs?.caracs
            ?.filter((carac) => carac.neededOnCreation)
            ?.find((carac) => carac && (!carac.value || carac.value === ''))
        ) {
          return line;
        }
      })
    );
  };

  const isTypeFicheModeledProductValid = ({ mode }: { mode: 'edit' | 'create' }) => {
    const checkTotalPercent = (lines): boolean => {
      const totalPercent = lines.reduce((acc, line) => acc + line.percent, 0);
      return totalPercent === 100;
    };

    const currentBundle =
      mode === 'edit'
        ? editBundle?.find((bundle) => bundle.id === line.id)
        : newBundle;

    if (
      currentBundle &&
      ((!currentBundle?.unit && !currentBundle?.Unit?.name) ||
        !currentBundle?.name ||
        !isNumHigherZero(currentBundle?.ddv) ||
        !currentBundle?.lines ||
        currentBundle.lines.find((line) => {
          if (isModelingPhase) {
            return (
              !isNum(line.modelingEquipmentCarbonWeightForm.endOfLife) ||
              !isNum(line.modelingEquipmentCarbonWeightForm.installation) ||
              !isNum(line.modelingEquipmentCarbonWeightForm.product) ||
              !isNum(line.modelingEquipmentCarbonWeightForm.profit)
            );
          } else {
            return (
              !isNumHigherZero(line.totalWeight) ||
              !checkTotalPercent(line.materialLines)
            );
          }
        }))
    ) {
      return false;
    }

    if (
      isAssignementToGraph &&
      !currentBundle?.lines?.find(
        (line) =>
          (line?.AllotmentNodes || line?.AllotmentNodes?.length > 0) &&
          (!line?.caracs?.caracs?.length > 0 ||
            !line?.caracs?.caracs.find((carac) => !carac?.value))
      )
    ) {
      return false;
    }

    return true;
  };

  const isEditOrCreateElementHasError = () => {
    const isEditMode = line.id ? true : false;
    switch (line?.type_fiche) {
      case 'bundle':
        return !isTypeFicheBundleValid({
          mode: isEditMode ? 'edit' : 'create',
        });
      case 'custom_user':
        return !isTypeFicheModeledProductValid({
          mode: isEditMode ? 'edit' : 'create',
        });
      case 'FC':
        return !isTypeConfiguredSheetValid({
          mode: isEditMode ? 'edit' : 'create',
        });
    }
  };

  let bundleLines =
    (isNewBundle && newBundle ? newBundle.lines : editBundleLine?.lines) || [];

  const handleOnChangeDropdown = (value, equipment) => {
    const isModeledProductEquipment =
      equipment?.searchBarType?.value === SEARCH_GROUP_OPTION_VALUES.PRODUITS;
    if (typeof value === 'number' && !isModeledProductEquipment) {
      let newValue = allGroups.flatAllotments
        ?.filter((el) => !el.isObsolete)
        .find((object) => object.id === value);

      if (!isNewBundle) {
        let editLine = editBundle.find((bundle) => bundle.id === line.id);
        handleChangeEditBundleLines(
          editLine,
          equipment,
          [
            newValue,
            allGroups.flatAllotments.find(
              (node) => node.id === newValue?.Re2020AllotmentNodeId
            ),
            allGroups.flatAllotments.find(
              (node) => node.id === newValue?.EpcmAllotmentNodeId
            ),
          ],
          'AllotmentNodes'
        );
      } else {
        let newLine = {
          ...bundleLines.find((bd) => bd.key === equipment.key),
          AllotmentNodes: [
            newValue,
            allGroups.flatAllotments.find(
              (node) => node.id === newValue?.Re2020AllotmentNodeId
            ),
            allGroups.flatAllotments.find(
              (node) => node.id === newValue?.EpcmAllotmentNodeId
            ),
          ],
        };

        if (newValue) setSearchCaracs(newLine);

        dispatch(
          updateBundleValue('newBundle', {
            ...newBundle,
            lines: bundleLines.map((bd) =>
              bd.key === equipment.key ? newLine : bd
            ),
          })
        );
      }
    } else if (typeof value === 'object') {
      if (!isNewBundle) {
        let editLine = editBundle.find((bundle) => bundle.id === line.id);

        handleChangeEditBundleLines(editLine, equipment, value, 'Sheet');
      } else {
        let noocoNodeByDefault = value.AllotmentNodes[0];
        let newLine = {
          ...bundleLines.find((bd) => bd.key === equipment.key),
          Sheet: value,
          AllotmentNode: noocoNodeByDefault,
          AllotmentNodes: [
            noocoNodeByDefault,
            allGroups.flatAllotments.find(
              (node) => node.id === noocoNodeByDefault.Re2020AllotmentNodeId
            ),
            allGroups.flatAllotments.find(
              (node) => node.id === noocoNodeByDefault.EpcmAllotmentNodeId
            ),
          ],
        };

        dispatch(
          updateBundleValue('newBundle', {
            ...newBundle,
            lines: bundleLines.map((bd) =>
              bd.key === equipment.key ? newLine : bd
            ),
          })
        );
      }
    } else if (isModeledProductEquipment) {
      const productEquipmentSelected = modeledProductOptions.find(
        (option) => option.id === value.id
      );
      if (!isNewBundle) {
        let editLine = editBundle.find((bundle) => bundle.id === line.id);
        equipment.unit = productEquipmentSelected?.unit || '';
        equipment.source = productEquipmentSelected?.source;
        equipment.type_fiche = productEquipmentSelected?.type_fiche;
        equipment.Unit = { name: productEquipmentSelected?.unit || '' };
        equipment.AllotmentNodes = productEquipmentSelected?.AllotmentNodes;
        handleChangeEditBundleLines(
          editLine,
          equipment,
          productEquipmentSelected?.id,
          'SheetId'
        );
      } else {
        const newBundleLine = bundleLines.find((bd) => bd.key === equipment.key);
        newBundleLine.unit = productEquipmentSelected?.unit || '';
        newBundleLine.Unit = { name: productEquipmentSelected?.unit || '' };
        let newLine = {
          ...newBundleLine,
          AllotmentNodes: productEquipmentSelected?.AllotmentNodes,
          type_fiche: productEquipmentSelected?.type_fiche,
          SheetId: productEquipmentSelected?.id,
        };

        dispatch(
          updateBundleValue('newBundle', {
            ...newBundle,
            lines: bundleLines.map((bd) =>
              bd.key === equipment.key ? newLine : bd
            ),
          })
        );
      }
    }
  };

  const handleChangeAllotmentNodes = (selectedOptionValue, type, equipment) => {
    let allotmentId = type === 'Nooco' ? 1 : type === 'RE2020' ? 2 : 34;

    let nodeSelected =
      type === 'Nooco'
        ? selectedOptionValue
        : allGroups.flatAllotments.find(
            (el) => el.name === selectedOptionValue && el.AllotmentId === allotmentId
          );

    let nodesUpdated = equipment.AllotmentNodes;

    if (!equipment.AllotmentNodes.find((node) => node.AllotmentId === allotmentId)) {
      nodesUpdated = [...equipment.AllotmentNodes, nodeSelected];
    } else {
      nodesUpdated = nodesUpdated.map((node) =>
        node.AllotmentId === allotmentId ? nodeSelected : node
      );
    }

    if (isNewBundle) {
      let newBundleUpdated = {
        ...newBundle,
        lines: bundleLines.map((line) =>
          line.key === equipment.key
            ? { ...equipment, AllotmentNodes: nodesUpdated }
            : line
        ),
      };

      dispatch(updateBundleValue('newBundle', newBundleUpdated));
    } else {
      let editLine = editBundle.find((bundle) => bundle.id === line.id);

      let newLine = {
        ...editLine,
        lines: [...bundleLines].map((equipmentLine) =>
          equipmentLine.key === equipment.key
            ? { ...equipment, AllotmentNodes: nodesUpdated }
            : equipmentLine
        ),
      };

      dispatch(
        updateBundleValue(
          'editBundle',
          editBundle.map((bundle) => (bundle.id === newLine.id ? newLine : bundle))
        )
      );
    }
  };

  const handleChangeCarbonEstimation = ({ bundleLine, value, formName }) => {
    isNewBundle
      ? dispatch(
          updateBundleValue(
            'newBundle',
            updateLineFromBundle(newBundle, bundleLine, formName, value)
          )
        )
      : handleChangeEditBundleLines(line, bundleLine, value, formName);
  };

  const returnDefaultValueTypeEquipment = (bundleLine) => {
    const defaultValueType = !bundleLine.id
      ? null
      : bundleLine.Sheet?.type_fiche === 'custom_user'
      ? SEARCH_GROUP_OPTION_VALUES.PRODUITS
      : bundleLine?.type_fiche === 'FC'
      ? 'equipements'
      : typeWaterOrEnergy.includes(
          findParentNode(
            bundleLine.AllotmentNodes.find((node) => node.MasterDataEquipmentId),
            allGroups.flatAllotments,
            [],
            []
          )[0].name.toLowerCase()
        )
      ? findParentNode(
          bundleLine.AllotmentNodes.find((node) => node.MasterDataEquipmentId),
          allGroups.flatAllotments,
          [],
          []
        )[0].name.toLowerCase()
      : findParentNode(
          bundleLine.AllotmentNodes.find((node) => node.MasterDataEquipmentId),
          allGroups.flatAllotments,
          [],
          []
        )[0].id === LOTS_IDS.ENERGIE_SIMPLIFIEE
      ? SelectedSearchGroupOptionsLabel.Energie
      : bundleLine.Sheet?.type_fiche !== 'users'
      ? 'INIES'
      : t('APP.BUNDLEDETAILS.INDEX.PH_F4E0AE', {
          defaultValue: 'equipements',
        });
    return defaultValueType;
  };

  const getCustomACV = (line) => {
    const equipmentForm = line.modelingEquipmentCarbonWeightForm;
    const customACV = {};

    const keys = {
      AProd: 'product',
      AEdif: 'installation',
      B: 'usage',
      B1: 'B1',
      B2: 'B2',
      B3: 'B3',
      B4: 'B4',
      B5: 'B5',
      B6: 'B6',
      B7: 'B7',
      C: 'endOfLife',
      D: 'profit',
    };

    Object.entries(keys).forEach(([key, value]) => {
      customACV[key] = Number(commaToDot(equipmentForm?.[value])) || 0;
    });

    return customACV;
  };

  const handleCreateOrPatchElement = async (line) => {
    if (isNewBundle) {
      let body = {
        CompanyId: companyId,
        folderSheetId: folderSheetId,
        comment: newBundle?.comment,
        lines: undefined,
      };

      if (isBundleSheet) {
        let lines = bundleLines?.map((line) => {
          let resultLine;
          if (line?.type_fiche === 'custom_user') {
            line.type = 'SHEET_FROM_LIB';
          }

          resultLine = {
            ...line,
            caracs: line.caracs ? line.caracs.caracs : null,
          };

          if (line?.Sheet?.id) {
            resultLine = { ...resultLine, SheetId: line?.Sheet?.id };
          }

          return resultLine;
        });

        body.name = newBundle?.name;
        body.unit = newBundle?.unit;
        body.security_coefficient = newBundle?.security_coefficient;
        body.lines = lines;
        body.typeOfSheet = newBundle?.type_fiche;
      }

      if (isConfiguredSheet) {
        body.typeOfSheet = newBundle?.type_fiche;
        body.name = newBundle?.name;
        body.unit = newBundle?.lines[0]?.caracs?.unit;
        body.fileName = newBundle?.lines[0]?.fileName;
        let lines = newBundle?.lines?.map((line) => {
          return {
            ...line,
            caracs: line.caracs ? line.caracs.caracs : null,
          };
        });
        body.form = lines[0];
        body.xml = await xmlFile?.text();
      }

      if (isModelingSheet) {
        let lines;
        if (isAssignementToGraph) {
          lines = newBundle?.lines?.map((line) => {
            return {
              ...body,
              AllotmentNodes: line.AllotmentNodes,
              caracs: line.caracs ? line.caracs.caracs : null,
            };
          });
        }

        if (lines) body = { ...lines[0] };
        body.typeOfSheet = 'modeledProduct';
        (body.type = newBundle?.type_fiche),
          (body.caracteristic = {
            equipmentName: newBundle?.name,
            labelQuote: '',
            lifeSpan: Number(commaToDot(newBundle?.ddv)),
            unit: newBundle?.unit,
          });
      }
      if (isModelingPhase) {
        body.isCarbonWeightSelected = true;
        body.materialComposition = {
          //TODO voir avec alex pour rendre cette clé optionnelle si pas de composition
          manufacturedRate: 25,
          totalWeight: null,
          materialLines: [],
        };
        body.customACV = { ...getCustomACV(newBundle?.lines[0]) };
      }

      if (isModelingEstimate) {
        let lines;
        if (isAssignementToGraph) {
          lines = newBundle?.lines?.map((line) => {
            return {
              ...body,
              AllotmentNodes: line.AllotmentNodes,
              caracs: line.caracs ? line.caracs.caracs : null,
            };
          });
        }

        if (lines) body = { ...lines[0] };
        body.isCarbonWeightSelected = false;
        body.customACV = {}; //TODO voir Alex pour la rendre optionnelle.
        body.materialComposition = {
          manufacturedRate: Number(commaToDot(newBundle?.lines[0].manufacturedRate)),
          totalWeight: Number(commaToDot(newBundle?.lines[0].totalWeight)),
          materialLines: newBundle?.lines[0].materialLines,
        };
      }
      createBundleHook.mutate(body);
    } else {
      let editLine = editBundle.find((bundle) => bundle.id === line.id);

      if (isBundleSheet) {
        editLine.lines = editLine?.lines?.map((line) => {
          if (line?.type_fiche === 'custom_user') {
            line.type = 'SHEET_FROM_LIB';
          }
          return {
            ...line,
            caracs: line.caracs ? line.caracs.caracs : null,
            SheetId: line?.Sheet?.id ? line?.Sheet.id : line?.SheetId,
          };
        });

        patchBundleHook.mutate({
          ...editLine,
          typeOfSheet: editLine?.type_fiche,
          unit: editLine.unit || editLine.Unit.name,
          folderSheetId: folderSheetId,
          CompanyId: companyId,
        });
      }

      if (isConfiguredSheet) {
        let body = {
          CompanyId: companyId,
          folderSheetId: folderSheetId,
          comment: newBundle?.comment,
          id: editLine.id,
        };
        body.typeOfSheet = editLine?.type_fiche;
        body.name = editLine?.name;
        body.unit = editLine?.lines[0]?.caracs?.unit;
        body.fileName = editLine?.lines[0]?.fileName;

        let lines = editLine?.lines?.map((line) => {
          return {
            ...line,
            caracs: line.caracs ? line.caracs.caracs : null,
          };
        });
        body.form = lines[0];
        body.xml = await xmlFile?.text();

        patchBundleHook.mutate(body);
      }

      if (isModelingPhase) {
        let lines;
        if (isAssignementToGraph) {
          lines = editLine?.lines?.map((line) => {
            return {
              AllotmentNodes: line.AllotmentNodes,
              caracs: line.caracs ? line.caracs.caracs : null,
            };
          });
        }

        let body = {
          CompanyId: companyId,
          id: editLine.id,
          folderSheetId: folderSheetId,
          typeOfSheet: 'modeledProduct',
          comment: editLine?.comment,
          type: 'custom_user',
          caracteristic: {
            equipmentName: editLine?.name,
            labelQuote: '',
            lifeSpan: Number(commaToDot(editLine?.ddv)),
            unit: editLine?.unit || editLine?.Unit?.name,
          },
          isCarbonWeightSelected: true,
          materialComposition: {
            manufacturedRate: 25,
            totalWeight: null,
            materialLines: [],
          },
          customACV: {
            ...getCustomACV(editLine?.lines[0]),
          },
        };
        if (isAssignementToGraph) {
          body = { ...body, ...lines[0] };
        }

        patchBundleHook.mutate(body);
      }

      if (isModelingEstimate) {
        let lines;
        if (isAssignementToGraph) {
          lines = editLine?.lines?.map((line) => {
            return {
              AllotmentNodes: line.AllotmentNodes,
              caracs: line.caracs ? line.caracs.caracs : null,
            };
          });
        }

        let body = {
          CompanyId: companyId,
          id: editLine.id,
          folderSheetId: folderSheetId,
          typeOfSheet: 'modeledProduct',
          comment: editLine?.comment,
          type: 'custom_user',
          isCarbonWeightSelected: false,
          materialComposition: {
            manufacturedRate: Number(
              commaToDot(editLine?.lines[0].manufacturedRate)
            ),
            totalWeight: Number(commaToDot(editLine?.lines[0].totalWeight)),
            materialLines: editLine?.lines[0].materialLines,
          },
          caracteristic: {
            equipmentName: editLine?.name,
            labelQuote: '',
            lifeSpan: Number(commaToDot(editLine?.ddv)),
            unit: editLine?.unit || editLine?.Unit?.name,
          },
          customACV: {},
        };
        if (isAssignementToGraph) {
          body = { ...body, ...lines[0] };
        }

        patchBundleHook.mutate(body);
      }
    }
  };

  const handleChangeModelingPhase = ({ bundleLine, formName, value }) => {
    isNewBundle
      ? dispatch(
          updateBundleValue(
            'newBundle',
            updateLineFromBundle(
              newBundle,
              bundleLine,
              'modelingEquipmentCarbonWeightForm',
              value,
              formName
            )
          )
        )
      : handleChangeEditBundleLines(
          line,
          bundleLine,
          value,
          'modelingEquipmentCarbonWeightForm',
          formName
        );
  };

  return (
    <div className="bundle-details-wrapper">
      {/* modal for searchGroup */}
      <BreadcrumbModal
        bundle={true}
        handleChangeAllotmentNodes={handleChangeAllotmentNodes}
      />
      {/* main detailed view display */}
      {isBundleSheet && (
        <div className="bundle-details-equipments">
          <div className="header-table">
            {/* searchGroup with Lots */}
            <p
              style={{
                display: 'grid',
                'grid-template-columns': '[first] 21%[line2] 51% [line3] 29%',
              }}
            >
              <span
                className="dark-blue-font"
                style={{ paddingLeft: '2em', display: 'inline-block' }}
              >
                {t('APP.BUNDLEDETAILS.INDEX.COMPOSITION', {
                  defaultValue: 'Composition',
                })}
              </span>
              <div></div>
              <div style={{ display: 'flex' }}>
                {certificationBundleChoice['Nooco'] && (
                  <span
                    className="dark-blue-font"
                    style={{
                      display: 'block',
                      textAlign: 'center',
                      width: '55px',
                    }}
                  >
                    {t('APP.HELP.FAQ.PH_71D837', {
                      defaultValue: 'Nooco',
                    })}
                  </span>
                )}

                {certificationBundleChoice['RE2020'] && (
                  <span
                    className="dark-blue-font"
                    style={{
                      display: 'block',
                      textAlign: 'center',
                      width: '55px',
                    }}
                  >
                    {t('APP.HELP.FAQ.PH_5BD2AB', {
                      defaultValue: 'RE2020',
                    })}
                  </span>
                )}

                {certificationBundleChoice['EPCM'] && (
                  <span
                    className="dark-blue-font"
                    style={{
                      display: 'block',
                      textAlign: 'center',
                      width: '55px',
                    }}
                  >
                    {t('APP.HELP.FAQ.PH_AEF1AA', {
                      defaultValue: 'E+C-',
                    })}
                  </span>
                )}
              </div>
            </p>
            {/* quantity */}
            <p className="dark-blue-font">
              {t('APP.BUNDLEDETAILS.INDEX.PH_9187FF', {
                defaultValue: 'Quantité par ensemble',
              })}
            </p>
            {/* unit */}
            <p className="dark-blue-font">
              {t('APP.BUNDLEDETAILS.INDEX.PH_CA62AE', {
                defaultValue: 'Unité',
              })}
            </p>
            {/* carac */}
            <p className="dark-blue-font">
              {t('APP.BUNDLEDETAILS.INDEX.PH_0132FA', {
                defaultValue: 'Carac',
              })}
              .
            </p>
            <p></p>
          </div>
          {bundleLines?.map((bundleLine: BundleLineDTO) => {
            const isModeledProductEquipment =
              bundleLine?.type_fiche === 'custom_user';
            let nodeWithMd;
            let result;

            if (!isModeledProductEquipment) {
              nodeWithMd = bundleLine.AllotmentNodes?.find(
                (node) => node.MasterDataEquipmentId
              );

              result = bundleLine.AllotmentNodes
                ? handleAllotmentNodes(
                    allGroups.type.find(
                      (md) => md.id === nodeWithMd?.MasterDataEquipmentId
                    ),
                    allGroups.allotments.find(
                      (allot) => allot.id === ALLOTMENT_IDS.NOOCO
                    ),
                    allGroups.flatAllotments,
                    null
                  )
                : null;
            }

            const unit = bundleLine?.unit
              ? bundleLine.unit
              : bundleLine.AllotmentNodes
              ? allGroups?.type?.find(
                  (node) =>
                    node.id ===
                    bundleLine.AllotmentNodes.find((nd) => nd?.MasterDataEquipmentId)
                      ?.MasterDataEquipmentId
                )?.unit
              : '';

            return (
              <div key={bundleLine} className="bundle-details-lines">
                <div className="search-bar-complex" style={{ paddingLeft: '2em' }}>
                  <SearchGroup
                    t={t}
                    searchBarOptionsFiltered={searchBarOptionsFiltered}
                    certifiedAllotments={certifiedAllotments}
                    isBundleScreen={true}
                    triggerBreadcrumb={() => {
                      dispatch(updateFormValue('openBreadcrumb', true));
                      dispatch(updateFormValue('currentBundleLine', bundleLine));
                    }}
                    findParentNode={findParentNode}
                    allAllotments={allGroups.flatAllotments}
                    allotmentsAvailable={listOfNodesSearchBar?.filter(
                      (el) => !el.isObsolete
                    )}
                    dropdownHeight="55px"
                    api={api}
                    dropdownProps={{
                      onSelect: () => {},
                      onChange: (value) => {
                        if (
                          bundleLine?.searchBarType &&
                          bundleLine?.searchBarType?.value ===
                            SEARCH_GROUP_OPTION_VALUES.EQUIPEMENTS
                        )
                          setEquipHaveCaracs(true);
                        handleOnChangeDropdown(value, bundleLine);
                      },
                      placeholder: t('APP.BUNDLEDETAILS.INDEX.PH_B43F0F', {
                        defaultValue: 'Rechercher...',
                      }),
                      treeCheckable: false,
                      value: !bundleLine.AllotmentNodes
                        ? null
                        : isModeledProductEquipment
                        ? bundleLine?.name
                        : bundleLine.Sheet?.name &&
                          bundleLine.Sheet?.type_fiche !== TYPE_FICHE.DEC
                        ? bundleLine.Sheet.name
                        : bundleLine.AllotmentNodes
                        ? bundleLine.AllotmentNodes.find(
                            (node) => node.MasterDataEquipmentId
                          )?.name
                        : bundleLine?.name
                        ? bundleLine.name
                        : '',
                    }}
                    selectProps1={{
                      value: bundleLine.id
                        ? returnDefaultValueTypeEquipment(bundleLine)
                        : bundleLine.searchBarType || selectedSearchGroupType,
                      onChange: (value) => {
                        const newEquipement = {
                          caracs: null,
                          AllotmentNodes: null,
                          unit: '',
                          quantity: '',
                          Unit: undefined,
                          type_fiche:
                            value.value === SEARCH_GROUP_OPTION_VALUES.PRODUITS
                              ? 'custom_user'
                              : '',
                          key: bundleLine.key,
                        };
                        if (!line.id) {
                          dispatch(
                            updateBundleValue('newBundle', {
                              ...newBundle,
                              lines: bundleLines.map((line) =>
                                line.key === newEquipement.key
                                  ? { ...newEquipement, searchBarType: value }
                                  : line
                              ),
                            })
                          );
                        } else {
                          handleChangeEditBundleLines(
                            line,
                            newEquipement,
                            value,
                            'searchBarType'
                          );
                        }
                        onChangeSelectedSearchGroupType(
                          setSelectedSearchGroupType,
                          SelectedSearchGroupOptionsLabel[value.label] ||
                            SelectedSearchGroupOptionsLabel[value.value] ||
                            SelectedSearchGroupOptionsLabel.Equipements
                        );
                      },
                      filterOptions: [
                        SEARCH_GROUP_OPTION_VALUES.EQUIPEMENTS,
                        SEARCH_GROUP_OPTION_VALUES.INIES,
                        SEARCH_GROUP_OPTION_VALUES.ENERGIE,
                        SEARCH_GROUP_OPTION_VALUES.EAU,
                        SEARCH_GROUP_OPTION_VALUES.PRODUITS,
                      ],
                    }}
                    selectProps2={{
                      ListType: Object.entries(certificationBundleChoice)
                        .filter(([key, value]) => value)
                        .map(([key, value]) => key),
                      noocoOptions: result
                        ? result.filter((el) => !el.isObsolete)
                        : bundleLine.Sheet?.AllotmentNodes || [],
                      keyDisplay: 'name',
                      keyItem: 'id',
                      onChange: (selectedOptionValue, type) => {
                        const data = result?.find(
                          (el) => el.name === selectedOptionValue
                        );

                        let nodeSelected =
                          type === 'Nooco'
                            ? data.details.find((el) => el.MasterDataEquipmentId)
                            : selectedOptionValue;
                        handleChangeAllotmentNodes(nodeSelected, type, bundleLine);
                      },
                      value: bundleLine.AllotmentNodes,
                    }}
                    selectWidth="100px"
                  />
                </div>
                <div className="quantity-input" style={{ width: '20%' }}>
                  <Input
                    style={{ width: '70px' }}
                    defaultValue={bundleLine.quantity}
                    onKeyPress={handleKeyPress}
                    disabledDebouncing={true}
                    value={bundleLine.quantity}
                    onChange={(value) =>
                      !line.id
                        ? dispatch(
                            updateBundleValue(
                              'newBundle',
                              updateLineFromBundle(
                                newBundle,
                                bundleLine,
                                'quantity',
                                value
                              )
                            )
                          )
                        : handleChangeEditBundleLines(
                            line,
                            bundleLine,
                            value,
                            'quantity'
                          )
                    }
                  />
                </div>
                <div style={{ width: '15%' }}>
                  <p>{unit}</p>
                </div>
                <div className="caracs" style={{ width: '20%' }}>
                  {bundleLine.AllotmentNodes &&
                    bundleLine.caracs &&
                    bundleLine.caracs.caracs?.length > 0 && (
                      <div>
                        <Popover
                          placement="top"
                          title={t('APP.BUNDLEDETAILS.INDEX.PH_BE774E', {
                            defaultValue: 'Caractéristiques',
                          })}
                          content={() => renderCaracPopOver(bundleLine)}
                          trigger="click"
                        >
                          <div>
                            <img
                              src={caracs}
                              style={{
                                marginLeft: '5px',
                                width: '27px',
                                filter: bundleLine?.caracs?.caracs?.find(
                                  (car) => car && (!car.value || car.value === '')
                                )
                                  ? 'invert(21%) sepia(52%) saturate(5667%) hue-rotate(354deg) brightness(88%) contrast(133%)'
                                  : undefined,
                              }}
                              alt=""
                            />
                          </div>
                        </Popover>
                      </div>
                    )}
                </div>
                <div style={{ width: '5%' }}>
                  <DeleteIcon
                    style={
                      bundleLines.length === 1
                        ? { cursor: 'not-allowed', color: 'grey' }
                        : { cursor: 'pointer' }
                    }
                    onClick={() =>
                      bundleLines.length > 1 ? deleteLines(bundleLine.key) : null
                    }
                  />
                </div>
              </div>
            );
          })}
          {/* add Element */}
          <div
            className="add-element"
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              width: '15rem',
              margin: '1em',
            }}
            onClick={() =>
              !line.id
                ? dispatch(
                    updateBundleValue('newBundle', {
                      ...newBundle,
                      lines: [
                        ...newBundle.lines,
                        {
                          key:
                            newBundle.lines.reduce(
                              (acc, obj) => {
                                return obj.key > acc.key ? obj : acc;
                              },
                              { key: -Infinity }
                            ).key + 1,
                          caracs: null,
                          quantity: null,
                          AllotmentNodes: null,
                          searchBarType: selectedSearchGroupType,
                        },
                      ],
                    })
                  )
                : dispatch(
                    updateBundleValue(
                      'editBundle',
                      editBundle.map((bundle) =>
                        bundle.id === line.id
                          ? {
                              ...bundle,
                              lines: [
                                ...bundle.lines,
                                {
                                  key:
                                    bundle.lines.reduce(
                                      (acc, obj) => {
                                        return obj.key > acc.key ? obj : acc;
                                      },
                                      { key: -Infinity }
                                    ).key + 1,
                                  caracs: null,
                                  quantity: null,
                                  AllotmentNodes: [],
                                  searchBarType: selectedSearchGroupType,
                                },
                              ],
                            }
                          : bundle
                      )
                    )
                  )
            }
          >
            <img style={{ width: '15px', height: '15px' }} src={add} />
            <p style={{ color: '#072BAC', marginLeft: '10px' }}>
              {t('APP.BUNDLEDETAILS.INDEX.ADD_ELEMENT', {
                defaultValue: 'Ajouter un élément',
              })}
            </p>{' '}
          </div>
        </div>
      )}
      {isConfiguredSheet && (
        <RenderConfiguredSheet
          bundleLines={bundleLines}
          line={line}
          xmlFile={xmlFile}
          setXmlFile={setXmlFile}
          handleChangeEditBundleLines={handleChangeEditBundleLines}
          isNewBundle={isNewBundle}
          handleOnChangeDropdown={handleOnChangeDropdown}
          searchBarOptionsFiltered={searchBarOptionsFiltered}
          returnDefaultValueTypeEquipment={returnDefaultValueTypeEquipment}
          certificationBundleChoice={certificationBundleChoice}
          handleChangeAllotmentNodes={handleChangeAllotmentNodes}
          renderCaracPopOver={renderCaracPopOver}
        />
      )}
      {isModelingPhase && (
        <RenderModelingPhase
          bundleLines={bundleLines}
          line={line}
          handleChangeModelingPhase={handleChangeModelingPhase}
          searchBarOptionsFiltered={searchBarOptionsFiltered}
          handleOnChangeDropdown={handleOnChangeDropdown}
          returnDefaultValueTypeEquipment={returnDefaultValueTypeEquipment}
          certificationBundleChoice={certificationBundleChoice}
          handleChangeAllotmentNodes={handleChangeAllotmentNodes}
          renderCaracPopOver={renderCaracPopOver}
          setIsAssignementToGraph={setIsAssignementToGraph}
          isAssignementToGraph={isAssignementToGraph}
          setEquipHaveCaracs={setEquipHaveCaracs}
        />
      )}
      {isModelingEstimate && (
        <RenderCarbonEstimation
          bundleLines={bundleLines}
          handleChangeCarbonEstimation={handleChangeCarbonEstimation}
          searchBarOptionsFiltered={searchBarOptionsFiltered}
          handleOnChangeDropdown={handleOnChangeDropdown}
          returnDefaultValueTypeEquipment={returnDefaultValueTypeEquipment}
          certificationBundleChoice={certificationBundleChoice}
          handleChangeAllotmentNodes={handleChangeAllotmentNodes}
          renderCaracPopOver={renderCaracPopOver}
          setIsAssignementToGraph={setIsAssignementToGraph}
          isAssignementToGraph={isAssignementToGraph}
          setEquipHaveCaracs={setEquipHaveCaracs}
        />
      )}

      {/* footer with validation button */}
      <div className="bundle-details-footer">
        {/* coefficient security */}
        {isBundleSheet && (
          <Tooltip
            title={t('APP.BUNDLEDETAILS.INDEX.PH_CAC7E5', {
              defaultValue:
                'Ajouter un coefficient de sécurité sur la valeur de l’impact',
            })}
          >
            <div className="bundle-security_coefficient center-element ">
              <Switch
                className="marginRightFooter"
                checked={isCoeffChecked}
                onChange={(e) => {
                  if (!isCoeffChecked === false) {
                    if (!line.id) {
                      dispatch(
                        updateBundleValue('newBundle', {
                          ...newBundle,
                          security_coefficient: null,
                        })
                      );
                    } else {
                      handleChangeEditBundle('security_coefficient', null);
                    }
                  }
                  setIsCoeffChecked(!isCoeffChecked);
                }}
              />
              <p className="dark-blue-font marginRightFooter">
                {t('APP.BUNDLEDETAILS.INDEX.PH_2E1B89', {
                  defaultValue: 'Ajouter un coefficient de sécurité',
                })}
                s
              </p>
              <Input
                defaultValue={line?.security_coefficient}
                onChange={(value) =>
                  value !== ''
                    ? !line.id
                      ? dispatch(
                          updateBundleValue('newBundle', {
                            ...newBundle,
                            security_coefficient: value,
                          })
                        )
                      : handleChangeEditBundle('security_coefficient', value)
                    : null
                }
                className="center-element"
                disabled={!isCoeffChecked}
                placeholder="10%"
                style={{ width: '55px', height: '50px' }}
              />
            </div>
          </Tooltip>
        )}

        {/* lifeSpan DDV */}
        {(isModelingSheet || isConfiguredSheet) && (
          <div className="bundle-unit center-element ">
            <p
              className="dark-blue-font marginRightFooter"
              style={{ minWidth: 'fit-content' }}
            >
              {t('APP.BUNDLEDETAILS.INDEX.DVE_AN', {
                defaultValue: 'DVE (an)',
              })}
            </p>{' '}
            <Tooltip
              title={
                'Durée de vie du produit modélisé qui sera utilisée dans les calculs sur les projets'
              }
            >
              {' '}
              <InfoIcon className="marginRightFooter" style={{ color: '#CDCED9' }} />
            </Tooltip>
            <Input
              style={{ width: '100%' }}
              defaultValue={line.ddv || 100}
              disabled={isConfiguredSheet}
              onChange={(value) =>
                !line.id
                  ? dispatch(
                      updateBundleValue('newBundle', {
                        ...newBundle,
                        ddv: value,
                      })
                    )
                  : dispatch(
                      updateBundleValue(
                        'editBundle',
                        editBundle.map((bundle) =>
                          bundle.id === line.id ? { ...bundle, ddv: value } : bundle
                        )
                      )
                    )
              }
            />
          </div>
        )}

        {/* unit */}
        <div className="bundle-unit center-element ">
          <p className="dark-blue-font marginRightFooter">
            {t('APP.BUNDLEDETAILS.INDEX.PH_CA62AE', { defaultValue: 'Unité' })}
          </p>
          {isConfiguredSheet ? (
            <Input
              key={line?.Unit?.name || line?.unit || line?.lines[0]?.caracs?.unit}
              defaultValue={
                line?.Unit?.name || line?.unit || line?.lines[0]?.caracs?.unit
              }
              disabled={true}
              style={{ width: '100px' }}
            />
          ) : (
            <>
              <Tooltip
                title={
                  <div>
                    <span style={{ color: 'white' }}>
                      {t(
                        'APP.BUNDLE.BUNDLELIST.BUNDLECARD.BUNDLEDETAILS.UNIT_TOOLTIP',
                        {
                          defaultValue:
                            'L’unité d’affichage de l’ensemble avec un choix parmi les options suivantes :',
                        }
                      )}
                    </span>
                    <ul>
                      <li>Ensemble</li>
                      <li>Unité</li>
                      <li>kg</li>
                      <li>m²</li>
                      <li>m</li>
                      <li>m³</li>
                      <li>ml</li>
                      <li>km</li>
                      <li>mm</li>
                      <li>cm</li>
                      <li>t</li>
                      <li>h</li>
                    </ul>
                  </div>
                }
              >
                {' '}
                <InfoIcon
                  className="marginRightFooter"
                  style={{ color: '#CDCED9' }}
                />
              </Tooltip>
              <Dropdown
                defaultValue={
                  isAssignementToGraph
                    ? bundleLines[0]?.caracs?.unit ?? bundleLines[0]?.unit
                    : line.Unit?.name || line?.unit
                }
                onSelect={() => {}}
                onClear={() => {}}
                treeCheckable={false}
                onChange={(value) =>
                  !line.id
                    ? dispatch(
                        updateBundleValue('newBundle', {
                          ...newBundle,
                          unit: value,
                        })
                      )
                    : handleChangeEditBundle('unit', value)
                }
                options={unitOptions}
                disabled={isAssignementToGraph}
                style={{ width: '100px' }}
              />
            </>
          )}
        </div>

        {/* buttons */}
        <div className="bundle-buttons center-element ">
          {/* cancel Button */}
          <Button
            className="close-button marginRightFooter"
            text={t('APP.BUNDLEDETAILS.INDEX.PH_A58AF0', {
              defaultValue: 'Annuler',
            })}
            onClick={() => {
              if (!line.id) {
                dispatch(updateBundleValue('newBundle', null));
              } else {
                dispatch(
                  updateBundleValue(
                    'editBundle',
                    editBundle.filter((bundle) => bundle.id !== line.id)
                  )
                );
              }
              setExpanded(false);
            }}
          />
          {/* save Button */}
          <Button
            className="purple-button"
            loading={createBundleHook.isLoading || patchBundleHook.isLoading}
            disabled={equipHaveCaracs || isEditOrCreateElementHasError()}
            text={t('APP.BUNDLEDETAILS.INDEX.PH_927BAC', {
              defaultValue: 'Enregistrer',
            })}
            onClick={() => {
              handleCreateOrPatchElement(line);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BundleDetails;
