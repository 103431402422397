import { use } from 'i18next';
import {
  TEMPLATE_TYPE_NAME,
  TYPE_BBCA,
  TYPE_EPCM,
} from '../../../globalVariable/typeProject';
import { useSelector } from 'react-redux';
import { StateDTO } from '../../../models/GeneralDTO';
import {
  CREATION_STEPS,
  CREATION_STEPS_TRAD_KEYS,
} from '../../../globalVariable/creationProjectVariable';

export const isOnlyGeneralFormDisplayed = (creationSteps: string[], t): boolean => {
  return creationSteps.includes(
    t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.WORK_AND_INDUSTRY])
  );
};

type GetPhaseOptionsProps = {
  creationSteps?: string[];
  projectTemplateType?: string;
};
export const getPhaseOptions = ({
  creationSteps,
  projectTemplateType,
}: GetPhaseOptionsProps): { label: string; value: number }[] => {
  if (!creationSteps && !projectTemplateType) {
    return [];
  }

  const isModeEC =
    creationSteps?.includes('Mode E+C-') || projectTemplateType === TYPE_EPCM;
  const isBBCARenovation =
    creationSteps?.includes('BBCA Rénovation') || projectTemplateType === TYPE_BBCA;

  const { phases } = useSelector((state: StateDTO) => state.projects.allGroups);

  if (isModeEC || isBBCARenovation) {
    return phases
      .filter((item) => [2, 34, 35, 36].includes(item.id))
      .map((item) => ({ label: item.name, value: item.id }));
  } else {
    return phases
      .filter((item) => [2, 3, 4, 5, 6, 8, 9].includes(item.id))
      .map((item) => ({ label: item.name, value: item.id }));
  }
};

export type isDisplaySelectZoneTypeProps = {
  zoneIndex: number;
  templateType: string;
};

export const isDisplaySelectZoneType = ({
  zoneIndex,
  templateType,
}: isDisplaySelectZoneTypeProps): boolean => {
  if (templateType === TEMPLATE_TYPE_NAME.BBCA && zoneIndex !== 0) {
    return false;
  }

  return true;
};
