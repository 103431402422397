/**
 * Created by nanaa on 27/05/2021
 */

import _ from 'lodash';
import axios from 'axios';

import {
  ASKING_IS_DED,
  DOWNLOAD_EDEC_FILE,
  FETCH_INIES_EQUIPMENTS,
  LOGIN_EDEC,
  SEND_REQUEST_DED,
  UPDATE_NEW_INIES,
  changeValueEquipments,
  displayDedModal,
  requestDedSuccess,
  setIdAsker,
  setLoadingDed,
  setNomenclatureInies,
  updateDatasinies,
} from '../actions/equipments';
import { fetchProjectById, updateFormValue } from '../actions/projects';

import { TYPE_EPCM, TYPE_NOOCO } from '../app/globalVariable/typeProject';
import API from '../app/services/API';

const api = new API();

const urlDemandeurs = process.env.REACT_APP_MLAB_DEMANDEURS;
const urlDemandes = process.env.REACT_APP_MLAB_DEMANDES;

const equipmentsMiddleware = (store) => (next) => (action) => {
  const { projects, equipments } = store.getState();

  switch (action.type) {
    // case FETCH_EQUIPMENT_CARACS:

    //   const infos = action.infos;

    //   api.getEquipmentCaracList(infos.lot, infos.fonction,
    //     infos.materiel, infos.categorie, infos.type).then((response) => {

    //     store.dispatch(handleFetchEquipmentCaracListSuccess(response));

    //   });
    //   next(action);
    //   break;
    // case POST_NEW_EQUIPMENT:
    //   console.log(action);

    //   api.createEquipment(action.body, action.projectId)
    //     .then((response) => {

    //       // response.data.sheet = { ...response.data.sheet, id_inies: 25765 };

    //       if(response.data.sheet.id_inies === 25765) {

    //         if(!projects.idAsker) {

    //           axios.post(`https://mdegd.dimn-cstb.fr/api/demandeurs/`, {
    //             entreprise: projects.currentProject.Company.name,
    //             mail: projects.user.attributes.email,
    //             nom: projects.user.attributes.family_name,
    //             prenom: projects.user.attributes.given_name,
    //             metier: "Any",
    //             telephone: '0123456789'
    //           })
    //             .then((response) => {

    //               store.dispatch(setIdAsker(response.data.id_demandeur));

    //             })
    //             .catch((error) => {

    //               console.log(error);

    //             });

    //         } else {

    //           store.dispatch(setLoadingDed(false));
    //           store.dispatch(displayDedModal());

    //         }

    //       } else {

    //         store.dispatch(setLoadingDed(false));

    //       }

    //     })
    //     .catch((error) => {

    //       console.log(error);

    //     });
    //   next(action);
    //   break;
    case ASKING_IS_DED:
      if (!projects.idAsker) {
        axios
          .post(urlDemandeurs, {
            entreprise: projects.currentProject.Company.name,
            mail: projects.user.attributes.email,
            nom: projects.user.attributes.family_name,
            prenom: projects.user.attributes.given_name,
            metier: 'Utilisateur Nooco',
            telephone: '0123456789',
          })
          .then((response) => {
            api.fetchNomenclatureInies().then((res) => {
              store.dispatch(setNomenclatureInies(res.data));
              store.dispatch(
                setIdAsker(response.data.id_demandeur, action.row ? true : false)
              );
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        api
          .fetchNomenclatureInies()
          .then((response) => {
            store.dispatch(setNomenclatureInies(response.data));

            store.dispatch(setLoadingDed(false));

            if (action.row) {
              store.dispatch(displayDedModal());
            }
          })
          .catch(() => {
            alert(
              'Base INIES indisponible pour le moment. Veuillez réessayer ultérieurement'
            );
          });
      }

      next(action);
      break;

    case SEND_REQUEST_DED:
      axios
        .post(urlDemandes, {
          content: {
            creationArgument: equipments.composantVideForm.explainDed,
            creationIdentification: equipments.composantVideForm.productDed,
            creationLabel: equipments.composantVideForm.nameDed,
            creationRank: {
              creationIsNewRank: false,
              creationRankId: equipments.composantVideForm.nomenDed
                ? equipments.composantVideForm.nomenDed
                : null,
            },
            creationSource: equipments.composantVideForm.calculDed,
            creationUf: `Unité fonctionnelle : 1 ${equipments.composantVideForm.unitDed?.name}, Durée de vie : ${equipments.composantVideForm.ddv}`,
          },
          id_demandeur: projects.idAsker,
          nature: 'Creation',
        })
        .then((response) => {
          if (projects.equipmentDedAsking) {
            api
              .modifyEquipment(
                projects.currentProject.id,
                projects.equipmentDedAsking.id,
                projects.equipmentDedAsking.quantity,
                projects.equipmentDedAsking.ProjectSheet.ZoneId,
                projects.equipmentDedAsking.ProjectSheet.id,
                projects.equipmentDedAsking.caracs,
                true,
                projects.idAsker
              )
              .then(() => {
                store.dispatch(requestDedSuccess());
                store.dispatch(
                  fetchProjectById(
                    projects.currentProject.id,
                    projects.currentIndicator.id
                  )
                );
                store.dispatch(changeValueEquipments('isLoadingProject', true));
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      next(action);
      break;

    case FETCH_INIES_EQUIPMENTS:
      api
        .getSheetByIniesId(action.value)
        .then((response) => {
          if (response.status === 202) {
            alert(response.data.message);
          }
          store.dispatch(
            updateDatasinies(Array.isArray(response.data) ? response.data : [])
          );
        })
        .catch(() => {
          // console.log(e);
        });

      next(action);
      break;
    case LOGIN_EDEC:
      api
        .getEdecFiles(equipments.edecUsername, equipments.edecPassword)
        .then((response) => {
          let configNames = [];

          for (let i = 0; i < response.data.length; i++) {
            response.data[i] = { ...response.data[i], key: i };

            if (
              !configNames.find((el) => el === response.data[i].ConfigurateurName)
            ) {
              configNames = [...configNames, response.data[i].ConfigurateurName];
            }
          }

          store.dispatch(changeValueEquipments('edecNames', configNames));

          store.dispatch(changeValueEquipments('edecFiles', response.data));
          store.dispatch(
            changeValueEquipments('addDisplay', [
              ...equipments.addDisplay,
              'Sélection',
            ])
          );
          store.dispatch(changeValueEquipments('displaySpinner', false));
        })
        .catch(() => {});

      next(action);
      break;
    case DOWNLOAD_EDEC_FILE:
      api
        .downloadEdec(
          equipments.edecUsername,
          equipments.edecPassword,
          equipments.edecChoice.File
        )
        .then((response) => {
          store.dispatch(
            changeValueEquipments('edecChoice', {
              ...equipments.edecChoice,
              xml: response.data,
            })
          );
        })
        .catch(() => {});

      next(action);
      break;
    case UPDATE_NEW_INIES: {
      let ids = [];

      projects.currentProject.Sheets.forEach((el) => {
        if (
          equipments.selectedRowKeys?.includes(el.ProjectSheet.id) &&
          ((el.isDisabled && !el.isProductDisabled) ||
            (el.type_fiche === 'users' && el.Re2020Sheet.endDateEquiv))
        ) {
          ids = [...ids, el.ProjectSheet.id];
        }
      });

      const data = {
        ProjectSheetIds: !action.sheet ? ids : [action.sheet.ProjectSheet.id],
      };

      console.log(data, ids);

      api
        .updateIniesSheet(data, projects.currentProject.id)
        .then((response) => {
          store.dispatch(
            fetchProjectById(
              projects.currentProject.id,
              projects.currentIndicator.id
            )
          );
        })
        .catch(() => {});

      next(action);
      break;
    }

    default:
      next(action);
  }
};

export default equipmentsMiddleware;
