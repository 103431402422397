import { TYPE_EPCM, TYPE_RE2020 } from '../../../../globalVariable/typeProject';
import { ZONE_TYPES } from '../../../../globalVariable/generalDefinitions';
import { getAllotmentId } from '../../../../shared/utils';
import { zonesDTO } from '../WidgetsDTO';
import { useTranslation } from 'react-i18next';
import { ProjectVerificationLevel } from '@photocarbone/nooco-shared-api-def';
import { AllotmentNodeDTO } from '../../../../models/ProjectDTO';

type LotsData = {
  lot: string;
  totalAcv: number;
};

export type SheetDTO = {
  id: number;
  name: string;
  type_fiche: string;
  AllotmentNodes: AllotmentNodeDTO[];
  Re2020Sheet: {
    id: number;
    ACV: {
      indice: {
        static: {
          total: number;
        };
      };
    };
  };
};

function checkZoneTypology(
  projectType: string,
  zones: zonesDTO[],
  tooltipText: string,
  isButtonUsable: boolean
) {
  const { t } = useTranslation();

  if (
    projectType === TYPE_RE2020 &&
    !zones.find((zone) => zone.BuildingType && zone.BuildingType.iniesId)
  ) {
    tooltipText = t('APP.WIDGETS.DELIVERABLESWIDGETS.PH_9CD6D8', {
      defaultValue:
        "Au moins une typologie de zone de votre projet n'est pas soumise à la réglementation RE2020",
    });
    isButtonUsable = false;
  }
  if (
    projectType === TYPE_EPCM &&
    zones.some(
      (zone) =>
        zone.BuildingType &&
        ['Datacenter', 'Aéroport', 'Infrastructure extérieure', 'Parking'].includes(
          zone.BuildingType.name
        )
    )
  ) {
    isButtonUsable = false;
    tooltipText = t('APP.WIDGETS.DELIVERABLESWIDGETS.PH_A21678', {
      defaultValue:
        "Au moins une typologie de zone de votre projet n'est pas soumise au label E+C-",
    });
  }

  return { tooltipText, isButtonUsable };
}

function checkLotsFilled(
  projectType: string,
  zones: zonesDTO[],
  sheets: SheetDTO[],
  rseeText: string | undefined,
  tooltipText: string,
  isButtonUsable: boolean
) {
  const { t } = useTranslation();

  const lotsInZones = getAllLotsPerZone(zones, projectType, sheets);
  const numberOfLots = getNumberOfLotsInTheProject(lotsInZones);
  const lot1IsPresent = isLot1PresentWithNonZeroAcv(lotsInZones);
  const numberOfLotsThreshold = projectType === TYPE_RE2020 ? 9 : 2;

  if (!lot1IsPresent) {
    tooltipText = t('APP.WIDGETS.DELIVERABLESWIDGETS.PH_E8C8E2', {
      rseeText,
      defaultValue:
        "Le lot 1 doit être modélisé et présenter des valeurs ACV non nulles dans chacune des zones afin d'exporter un {rseeText}",
    });
    isButtonUsable = false;
  } else if (numberOfLots < numberOfLotsThreshold) {
    tooltipText = t(
      'APP.WIDGETS.DELIVERABLESWIDGETS.DISABLED_RSEE_WHEN_LOT_IS_MISSING',
      {
        numberOfLotsThreshold,
        rseeText,
        numberOfLots: Number(numberOfLots),
        defaultValue:
          "Au moins {numberOfLotsThreshold} lots doivent être modélisés dans chacune des zones afin d'exporter un {rseeText}, votre projet n'en contient {numberOfLots, plural, =0 {aucun} one {un} other {que {numberOfLots} } }",
      }
    );
    isButtonUsable = false;
  }

  return { tooltipText, isButtonUsable };
}

function checkEquipmentsAndBuildingSites(
  zones: zonesDTO[],
  sheets: SheetDTO[],
  rseeText: string | undefined,
  tooltipText: string,
  isButtonUsable: boolean
) {
  const { t } = useTranslation();

  const zonesEquipmentsChecks = checkEquipmentsPresence(zones, sheets);

  if (
    zonesEquipmentsChecks &&
    Object.keys(zonesEquipmentsChecks).some((check) => !zonesEquipmentsChecks[check])
  ) {
    [isButtonUsable, tooltipText] =
      getChecksForEquipementsTypes(zonesEquipmentsChecks);
  } else if (!plotBuildingSiteHaveOnlyDec(zones, sheets)) {
    isButtonUsable = false;
    tooltipText = t('APP.WIDGETS.DELIVERABLESWIDGETS.PH_0EE837', {
      rseeText,
      defaultValue:
        'Export {rseeText} impossible car le chantier de la parcelle ne doit pas comporter de composants spécifiques',
    });
  } else if (projectHaveSoloFluid(zones, sheets)) {
    isButtonUsable = false;
    tooltipText = t('APP.WIDGETS.DELIVERABLESWIDGETS.PH_D9226C', {
      rseeText,
      defaultValue:
        'Export {rseeText} impossible car vous ne pouvez pas saisir de fluide frigorigène sans rattachement à un forfait ou à un équipement spécifique. Veuillez supprimer la charge de fluide additionnelle saisie',
    });
  } else if (projectHaveZeroDurationBuildingSites(zones)) {
    isButtonUsable = false;
    // TODO: translate
    tooltipText = `Export ${rseeText} impossible car votre chantier a une durée nulle`;
  }

  return { tooltipText, isButtonUsable };
}

function miscChecks(
  isExportEpcmDateValid: boolean | undefined,
  rseeText: string | undefined,
  tooltipText: string,
  isButtonUsable: boolean
) {
  const { t } = useTranslation();

  if (!isExportEpcmDateValid) {
    tooltipText = t('APP.WIDGETS.DELIVERABLESWIDGETS.PH_0694AD', {
      rseeText,
      defaultValue:
        'La date de permis ne vous permet pas de faire un export {rseeText}',
    });
  } else {
    tooltipText = t('APP.WIDGETS.DELIVERABLESWIDGETS.PH_932125', {
      rseeText,
      defaultValue:
        "Merci de renseigner tous les paramètres de votre projet dans l'onglet Paramètres pour pouvoir exporter un {rseeText}",
    });
  }
  isButtonUsable = false;

  return { tooltipText, isButtonUsable };
}

function checkVerificationData(
  verificationData: ProjectVerificationLevel,
  rseeText: string,
  tooltipText: string,
  isButtonUsable: boolean
) {
  const { t } = useTranslation();

  if (verificationData.level !== 100) {
    tooltipText = t(
      'APP.WIDGETS.DELIVERABLESWIDGETS.ERROR_MESSAGE_VERIFICATION_DATA_NOT_100',
      {
        rseeText,
        defaultValue:
          'Pour exporter un {rseeText}, il faut atteindre un niveau de vérification de 100% sur votre projet. Pour augmenter la vérification du projet, modifier les statuts en Liste des contributeurs',
      }
    );
    isButtonUsable = false;
  }

  return { tooltipText, isButtonUsable };
}

export function checkRseeDataForExport(
  sheets: SheetDTO[],
  verificationData: ProjectVerificationLevel,
  rseeText: string,
  zones?: zonesDTO[],
  validThresholds?: boolean,
  isExportEpcmDateValid?: boolean,
  projectType?: string
): [boolean, string] {
  let isButtonUsable = true;
  let tooltipText = '';

  if (projectType && zones) {
    ({ tooltipText, isButtonUsable } = checkZoneTypology(
      projectType,
      zones,
      tooltipText,
      isButtonUsable
    ));
    ({ tooltipText, isButtonUsable } = checkLotsFilled(
      projectType,
      zones,
      sheets,
      rseeText,
      tooltipText,
      isButtonUsable
    ));

    if (projectType === TYPE_RE2020 || projectType === TYPE_EPCM) {
      ({ tooltipText, isButtonUsable } = checkEquipmentsAndBuildingSites(
        zones,
        sheets,
        rseeText,
        tooltipText,
        isButtonUsable
      ));
    }
  }

  if (!validThresholds) {
    ({ tooltipText, isButtonUsable } = miscChecks(
      isExportEpcmDateValid,
      rseeText,
      tooltipText,
      isButtonUsable
    ));
  }

  ({ tooltipText, isButtonUsable } = checkVerificationData(
    verificationData,
    rseeText,
    tooltipText,
    isButtonUsable
  ));

  return [isButtonUsable, tooltipText];
}

function projectHaveZeroDurationBuildingSites(zones: zonesDTO[]): boolean {
  const buildingSites = zones.filter(
    (zone) => zone.type === ZONE_TYPES.buildingSite
  );

  return buildingSites.some((buildingSite) => buildingSite.ddv === 0);
}

function getAllLotsPerZone(
  zones: zonesDTO[],
  projectType: string,
  Sheets?: SheetDTO[]
): LotsData[][] {
  let lots: LotsData[][] = [];

  const allotmentId = getAllotmentId(projectType);
  const regularZones = zones.filter((zone) => zone.type === ZONE_TYPES.zone);

  regularZones.forEach((zone) => {
    let lotsForThisZone: LotsData[] = [];

    zone.ProjectSheets.forEach((projectSheet) => {
      lotsForThisZone = addLotToList(
        projectSheet,
        allotmentId,
        lotsForThisZone,
        Sheets
      );
    });

    lots.push(lotsForThisZone);
  });

  return lots;
}

const getProjectSheetTotalAcv = (projectSheet: any, Sheets?: SheetDTO[]): number => {
  let matchingSheetAcvTotal = 0;
  const certifiedSheet = Sheets
    ? Sheets.find((sheet) => sheet.id === projectSheet.SheetId)
    : undefined;
  if (
    certifiedSheet &&
    certifiedSheet.Re2020Sheet &&
    certifiedSheet.Re2020Sheet.ACV
  ) {
    matchingSheetAcvTotal = certifiedSheet
      ? certifiedSheet.Re2020Sheet.ACV['1']?.static?.total
      : 0;
  }

  return matchingSheetAcvTotal;
};

const addLotToList = (
  projectSheet: any,
  allotmentId: number,
  lots: LotsData[],
  Sheets?: SheetDTO[]
): LotsData[] => {
  try {
    const lot: string = projectSheet.AllotmentNodes.find(
      (an) => an.AllotmentId === allotmentId
    ).name.split('.')[0];

    const matchingSheetAcvTotal = getProjectSheetTotalAcv(projectSheet, Sheets);

    if (!isNaN(Number(lot))) {
      if (!lots.some((lotData) => lotData.lot === lot)) {
        const lotToAdd: LotsData = {
          lot,
          totalAcv: matchingSheetAcvTotal,
        };

        lots.push(lotToAdd);
      } else {
        const lotIndex = lots.findIndex((lotData) => lotData.lot === lot);

        lots[lotIndex].totalAcv += matchingSheetAcvTotal;
      }
    }
  } catch (error) {
    console.error(
      'Error trying to get lot from project sheet, concerned project sheet is ${projectSheet.id}, error is',
      error
    );
  }

  return lots;
};

const isLot1PresentWithNonZeroAcv = (lotsArray: LotsData[][]): boolean => {
  const lot1Array = lotsArray.map((lots) => lots.filter((lot) => lot.lot === '1'));

  return lot1Array.every((lot1) => lot1.length > 0 && lot1[0].totalAcv !== 0);
};

const getNumberOfLotsInTheProject = (lotsArray: LotsData[][]): number => {
  const nonZeroAcvLotsArray = lotsArray.map((lots) =>
    lots.filter((lot) => lot.totalAcv !== 0)
  );
  const numberOfLotsPerZone = nonZeroAcvLotsArray.map((lots) => lots.length);

  return Math.min(...numberOfLotsPerZone);
};

const projectHaveSoloFluid = (zones: zonesDTO[], sheets: SheetDTO[]): boolean => {
  const projectHaveSoloFluid = zones.some((zone) => {
    const zoneHaveSoloFluid = zone.ProjectSheets.some((projectSheet) => {
      const matchingSheet = sheets.find(
        (sheet) => sheet.id === Number(projectSheet.SheetId)
      );

      return matchingSheet
        ? matchingSheet.name.includes('Fluide frigorigène')
        : true;
    });

    return zoneHaveSoloFluid;
  });

  return projectHaveSoloFluid;
};

const plotBuildingSiteHaveOnlyDec = (
  zones: zonesDTO[],
  sheets: SheetDTO[]
): boolean => {
  const plotBuildingSite = zones.find(
    (zone) =>
      zone.type === ZONE_TYPES.buildingSite &&
      zones.find((parentZone) => zone.ParentId === parentZone.id)?.type ===
        ZONE_TYPES.plot
  );

  if (plotBuildingSite) {
    const plotBuildingSiteHasOnlyDecEquipments =
      plotBuildingSite.ProjectSheets.every((projectSheet) => {
        const certifiedSheet = sheets.find(
          (sheet) => sheet.id === Number(projectSheet.SheetId)
        );

        return certifiedSheet?.type_fiche === 'DEC';
      });

    return plotBuildingSiteHasOnlyDecEquipments;
  } else {
    return true;
  }
};

const checkEquipmentsPresence = (zones: zonesDTO[], sheets: SheetDTO[]): Object => {
  let buildingSitesHaveEquipments = doZonesHaveEquipementsAndNonZeroAcv(
    zones,
    ZONE_TYPES.buildingSite,
    sheets
  );
  let zonesHaveEquipements = doZonesHaveEquipementsAndNonZeroAcv(
    zones,
    ZONE_TYPES.zone,
    sheets,
    true,
    ['eau', 'energie']
  );
  let zonesHaveWaterEquipments = doZonesHaveEquipementsAndNonZeroAcv(
    zones,
    ZONE_TYPES.zone,
    sheets,
    false,
    ['eau']
  );
  let zonesHaveEnergyEquipments = doZonesHaveEquipementsAndNonZeroAcv(
    zones,
    ZONE_TYPES.zone,
    sheets,
    false,
    ['energie']
  );

  const zonesEquipmentsChecks = {
    Chantier: buildingSitesHaveEquipments,
    Composant: zonesHaveEquipements,
    Eau: zonesHaveWaterEquipments,
    Energie: zonesHaveEnergyEquipments,
  };

  return zonesEquipmentsChecks;
};

const doZonesHaveEquipementsAndNonZeroAcv = (
  zones: zonesDTO[],
  zoneType: string,
  sheets: SheetDTO[],
  isInverted: boolean = false,
  lots?: string[]
): boolean => {
  const zonesToCheck = zones.filter(
    (zone) =>
      zone.type === zoneType &&
      (zoneType === ZONE_TYPES.buildingSite
        ? zones.find((parentZone) => zone.ParentId === parentZone.id)?.type !==
          ZONE_TYPES.plot
        : true)
  );

  const zoneHaveEquipementsAndNonZeroAcv = zonesToCheck.every((zone) => {
    let totalAcv = 0;

    zone.ProjectSheets.forEach(
      (projectSheet) => (totalAcv += getProjectSheetTotalAcv(projectSheet, sheets))
    );

    return (
      zone.ProjectSheets.length > 0 &&
      (typeof lots !== 'undefined'
        ? isEquipmentTypeIncludedInZone(zone, lots, isInverted)
        : true) &&
      totalAcv !== 0
    );
  });

  return zoneHaveEquipementsAndNonZeroAcv;
};

const getChecksForEquipementsTypes = (
  zonesEquipmentsChecks: Object
): [boolean, string] => {
  const { t } = useTranslation();
  const numberOfFailedChecks = Object.values(zonesEquipmentsChecks).filter(
    (check) => !check
  ).length;

  let failedChecks = Object.keys(zonesEquipmentsChecks)
    .filter((check) => !zonesEquipmentsChecks[check])
    .join(', ');

  if (numberOfFailedChecks > 1) {
    failedChecks = failedChecks.replace(/,([^,]*)$/, ' et $1');
  }

  let tooltipText = t('APP.WIDGETS.DELIVERABLESWIDGETS.PH_D67407', {
    failedChecks,
    numberOfFailedChecks,
    defaultValue:
      "Export impossible car {numberOfFailedChecks, plural, one {le contributeur {failedChecks} n'a pas été saisi} other {les contributeurs {failedChecks} n'ont pas été saisis}} dans toutes les zones.",
  });

  return [false, tooltipText];
};

const isEquipmentTypeIncludedInZone = (
  zone: zonesDTO,
  lots: string[],
  isInverted: boolean = false
): boolean => {
  const masterDatas = zone.ProjectSheets.map((projectSheet) =>
    projectSheet.AllotmentNodes.filter(
      (an) => an.level === 4 && an.MasterDataEquipment
    )
  )
    .flat()
    .map((an) => an.MasterDataEquipment);

  const equipmentIsPresent = masterDatas.some((masterData) => {
    const lotIsIncluded = lots.includes(masterData.lot.toLowerCase());

    return isInverted ? !lotIsIncluded : lotIsIncluded;
  });

  return equipmentIsPresent;
};

export let testExports = {};

if (process.env.NODE_ENV === 'test') {
  testExports = {
    getAllLotsPerZone,
  };
}
