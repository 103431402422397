import { Button } from 'antd';
import { Button as ButtonFromNooco } from '@photocarbone/nooco-ui';
import { DatePicker } from 'antd';
import { Form, Modal } from 'react-bootstrap';
import { Select } from '@mui/material';
import AddIcon from '@material-ui/icons/Add';
import AppContext from '../../AppContext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loader from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import SnackBarElement from '../../shared/SnackBarElement';
import locale from 'antd/es/date-picker/locale/fr_FR';

import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

// import Swiper core and required modules
import Bar from '../../../containers/Bar';
import CardVariant from '../../../containers/CardVariant';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Chip from '@material-ui/core/Chip';
import NoocoCheckbox from '../../shared/NoocoCheckbox';
import Radar from '../../../containers/Radar';
import dayjs from 'dayjs';
import moment from 'moment';

import Slider from 'react-slick';

import graphleft from '../../../assets/images/variant/graphleft.png';
import graphright from '../../../assets/images/variant/graphright.png';

import tableau from '../../../assets/images/variant/tableau.png';

import './variant.scss';
import { fetchProjectById } from '../../../actions/projects';
import { updateMonitoring } from '../../../actions/monitoring';
import {
  useCreateVariant,
  useDeleteVariant,
  useGetVariants,
} from '../../../hooks/useQuery/variants';
import { useDispatch, useSelector } from 'react-redux';
import { useGetProjectsShort } from '../../../hooks/useQuery/projects/getProjectsShort';
import { useTranslation } from 'react-i18next';
import { ALLOTMENT_IDS } from '../../globalVariable/typeProject';

const { RangePicker } = DatePicker;

const dateFormat = 'L';

moment.locale('fr');

const Variant = ({
  typeGraph,
  changeValueVariant,
  variants,
  currentFilter,
  selectedLotFilter,
  filterOptions,
  limitDatas,
  updateFormValue,
  currentAllotment,
  dataGraph,
  currentProject,
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const { companies } = useSelector((state) => state.projects);
  // const { currentIndicator, companies } = projects;
  const dispatch = useDispatch();

  const radarLogo = require('../../../assets/images/variant/radar.png');
  const graph = require('../../../assets/images/variant/graph.png');
  const [variantModal, setVariantModal] = useState(false);
  const [variantName, setVariantName] = useState();
  const [isAddVariantSelected, setIsAddVariantSelected] = useState(true);
  const [copyCompany, setCopyCompany] = useState(currentProject.Company);
  const [exploitVariantDatas, setExploitVariantDatas] = useState(null);
  const [keepLinkToCurrentProject, setKeepLinkToCurrentProject] = useState(true);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    // centerMode: true,
    centerPadding: '2%',
    swipeToSlide: true,
    draggable: true,
    initialSlide: 0,
  };

  useEffect(() => {
    const slick = document.querySelector('.slick-list');
    const dots = document.querySelector('.slick-dots');

    if (slick) {
      slick.style.padding = '3rem 1rem';
    }

    if (dots && window.screen.availHeight < 750) {
      dots.style.marginBottom = '1rem';
    }
  }, [variants, typeGraph]);

  const { data } = useGetProjectsShort(true, currentProject.Company.id);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      changeValueVariant('typeGraph', null);
      changeValueVariant('currentFilter', 0);
      changeValueVariant('dataGraph', []);
      changeValueVariant('selectedLotFilter', []);
      changeValueVariant('filterOptions', []);
      changeValueVariant('filteredGraph', null);
      updateFormValue('copyData', null);
    };
  }, []);

  useGetVariants();

  const handleLots = (lot) => {
    let allIds = null;

    if (
      lot.find((lotItem) => lotItem.id === 1200) &&
      selectedLotFilter.find((selectedLotItem) => selectedLotItem.id === 1200) &&
      lot.length === 1
    ) {
      allIds = [];
    } else if (
      selectedLotFilter.find((selectedLotItem) => selectedLotItem.id === 1200) &&
      lot.length > 1
    ) {
      allIds = lot.filter((lotItem) => lotItem.id !== 1200);
    } else if (
      (!selectedLotFilter.find((selectedLotItem) => selectedLotItem.id === 1200) &&
        lot.find((lotItem) => lotItem.id === 1200) &&
        lot.length > 1) ||
      lot.length === 0
    ) {
      allIds = [
        {
          id: 1200,
          label:
            currentAllotment.id === ALLOTMENT_IDS.RE2020
              ? t('APP.VARIANT.INDEX.PH_4C6C25', {
                  defaultValue: 'Tous contributeurs',
                })
              : t('APP.VARIANT.INDEX.PH_1D2538', { defaultValue: 'Tous lots' }),
          color: '#ccc',
          name: 'Tous lots',
        },
      ];
    }

    changeValueVariant('selectedLotFilter', allIds || lot);
  };

  const choiceNameFilter = () => {
    if (currentAllotment.id !== 2 && currentFilter === 0) {
      return 'Lots';
    } else if (currentAllotment.id !== 2 && currentFilter === 1) {
      return 'Fonctions';
    } else if (currentAllotment.id === 2 && currentFilter === 0) {
      return 'Contributeurs';
    } else if (currentAllotment.id === 2 && currentFilter === 1) {
      return 'Sous-contributeurs';
    }
  };

  const setAlert = (message, severity) => {
    dispatch(updateMonitoring('alertSeverity', severity));
    dispatch(updateMonitoring('alertMessage', message));
  };

  const onSuccessCreateVariant = () => {
    setVariantModal(false);
    setAlert(
      t('APP.VARIANT.INDEX.PH_ECAB22', {
        defaultValue: 'Copie créée avec succes.',
      }),
      'success'
    );
  };
  const onErrorCreateVariant = () => {
    setVariantModal(false);
    setAlert(
      t('APP.VARIANT.INDEX.PH_02F0E4', {
        defaultValue: 'Erreur lors de la création de la copie.',
      }),
      'error'
    );
  };

  const createVariant = useCreateVariant(
    onSuccessCreateVariant,
    onErrorCreateVariant
  );
  const { isLoading: isLoadingCreateVariant } = createVariant;
  const handleCreateVariant = () => {
    let exploitData = exploitVariantDatas
      ? { ...exploitVariantDatas, ProjectTypeId: 5 }
      : null;

    createVariant.mutate({
      projectId: currentProject.id,
      variantName: variantName,
      companyId: copyCompany?.id,
      exploitData: exploitData,
      isAddVariantSelected: isAddVariantSelected,
      keepLinkToCurrentProject: keepLinkToCurrentProject,
    });
  };

  return (
    <div
      className="variant-screen"
      style={{ minHeight: window.screen.availHeight < 950 ? '71vh' : null }}
    >
      <SnackBarElement />
      {variants.length === 0 && (
        <Loader
          variant="indeterminate"
          disableShrink
          style={{
            display: 'block',
            color: '#73b7ec',
            margin: 'auto',
            marginTop: '7em',
          }}
          size={70}
          thickness={4}
        />
      )}

      {variants.length > 0 && (
        <>
          <div className="variants-part">
            {/* graph display */}
            {typeGraph && (
              <>
                <Tooltip
                  title={t('APP.VARIANT.INDEX.PH_99FD43', {
                    defaultValue: 'Retourner à la sélection de variantes',
                  })}
                >
                  <ArrowBackIcon
                    className="back-position"
                    onClick={() => changeValueVariant('typeGraph', null)}
                  />
                </Tooltip>
                {typeGraph === 'bar' && (
                  <div
                    style={{
                      marginLeft: '12%',
                      marginTop: '1.2%',
                      height: dataGraph?.length <= 5 ? '32px' : null,
                    }}
                  >
                    {dataGraph?.length > 5 && (
                      <Button
                        style={{ fontSize: '0.9rem', marginRight: '1em' }}
                        onClick={() => changeValueVariant('limitDatas', 5)}
                        shape="round"
                        className="button"
                      >
                        5 {choiceNameFilter()}
                      </Button>
                    )}

                    {dataGraph?.length > 10 && (
                      <Button
                        onClick={() => changeValueVariant('limitDatas', 10)}
                        style={{ fontSize: '0.9rem' }}
                        shape="round"
                        className="button"
                      >
                        10 {choiceNameFilter()}
                      </Button>
                    )}
                  </div>
                )}
                <div className="lot-filter-select">
                  <Autocomplete
                    key={filterOptions}
                    multiple
                    id="checkboxes-tags-demo"
                    value={selectedLotFilter}
                    options={filterOptions}
                    noOptionsText={t('APP.VARIANT.INDEX.PH_99D851', {
                      defaultValue: 'Aucun résultat trouvé',
                    })}
                    disableCloseOnSelect
                    onChange={(event, newValue) => {
                      if (limitDatas) {
                        changeValueVariant('limitDatas', null);
                      }

                      handleLots([...newValue]);
                    }}
                    size={'small'}
                    getOptionLabel={(option) => option.label}
                    renderTags={(value, getTagProps) => {
                      return value.map((option, index) => (
                        <Chip
                          style={{
                            background: `${option.color}`,
                            fontWeight: '600',
                            fontSize:
                              window.screen.availHeight < 950 ? '0.75rem' : '11px',
                            color: 'white',
                            borderRadius: '0.25rem',
                            border: 'none',
                            height: '27px',
                          }}
                          key={option.name}
                          variant="outlined"
                          label={option.label}
                          {...getTagProps({ index })}
                        />
                      ));
                    }}
                    renderOption={(option, { selected }) => {
                      if (selectedLotFilter.find((el) => el.id === 1200)) {
                        selected = true;
                      }

                      return (
                        <React.Fragment>
                          <NoocoCheckbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />

                          {option.label}
                        </React.Fragment>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Lots"
                        variant="outlined"
                        className="researchBar"
                      />
                    )}
                  />
                </div>
              </>
            )}

            {/* Main part of the screen when no graph is selected */}
            {!typeGraph && (
              <>
                <h3
                  className="variants-title"
                  style={{
                    fontSize: window.screen.availHeight < 700 ? '1.2rem' : null,
                  }}
                >
                  {t('APP.VARIANT.INDEX.PH_EF66D1', {
                    defaultValue: 'Sélectionnez les variantes à comparer',
                  })}
                </h3>
                {/* <p className="add-variant"><AddIcon style={{ marginRight: '10px' }} /> Ajouter une variante</p> */}
                <div
                  className="variants-caroussel"
                  style={{
                    marginTop: window.screen.availHeight < 750 ? '0.5em' : null,
                  }}
                >
                  <Slider {...settings}>
                    {variants
                      .filter((el) => el.isSelected)
                      .sort((a, b) => a.id - b.id)
                      .map((variant, index) => (
                        <div key={variant.id}>
                          <CardVariant
                            variant={variant}
                            isVariantEditable={index === 0 ? false : true}
                          />
                        </div>
                      ))}
                    {variants
                      .filter((el) => !el.isSelected)
                      .sort((a, b) => a.id - b.id)
                      .map((variant) => (
                        <div key={variant.id}>
                          <CardVariant variant={variant} isVariantEditable={true} />
                        </div>
                      ))}
                    {/* {addedVariant.map((variant) => (
                  <div key={variant.id}>
                  <CardVariant added={true} variant={variant} />
                  </div>
                  ))} */}
                    <div>
                      <CardVariant addCardVariant={true} projects={data} />
                    </div>
                  </Slider>
                </div>
              </>
            )}

            {/* Radar part */}
            {typeGraph === 'radar' && <Radar />}
            {/* Bar Graph part */}
            {typeGraph === 'bar' && <Bar />}
          </div>
          <div className="type-variants">
            <div className="variants-filters">
              <p
                className={
                  !typeGraph
                    ? 'variants-filters-header'
                    : 'variants-filters-header filter-active'
                }
                onClick={
                  typeGraph ? () => changeValueVariant('typeGraph', null) : null
                }
              >
                {typeGraph && (
                  <ArrowBackIcon
                    className="turn-back"
                    onClick={() => changeValueVariant('typeGraph', null)}
                  />
                )}
                {t('APP.VARIANT.INDEX.SELECTION_DE_VARIANTES', {
                  defaultValue: 'Sélection de variantes',
                })}
              </p>
              <div className="variants-add-wrapper">
                <ButtonFromNooco
                  additionalIcon={<AddIcon />}
                  className="purple-button"
                  text={t('APP.VARIANT.INDEX.PH_08C831', {
                    defaultValue: 'Créer une variante',
                  })}
                  onClick={() => {
                    setVariantName(`${currentProject.name} Variante`);
                    setVariantModal(true);
                  }}
                />
              </div>
              <div className="variants-filters-content">
                <p className="variants-filters-type">
                  {t('APP.VARIANT.INDEX.PH_A3E316', {
                    defaultValue: 'Type de graphique',
                  })}
                </p>

                <div
                  className={
                    typeGraph === 'radar'
                      ? 'variants-filters-choice backgroundFull'
                      : 'variants-filters-choice'
                  }
                  onClick={() => changeValueVariant('typeGraph', 'radar')}
                >
                  <div className="variants-filters-logo">
                    <img src={radarLogo} alt />
                  </div>
                  <p>
                    {t('APP.VARIANT.INDEX.PH_15A474', {
                      defaultValue: 'Radar',
                    })}
                  </p>
                </div>

                <div
                  className={
                    typeGraph === 'bar'
                      ? 'variants-filters-choice backgroundFull'
                      : 'variants-filters-choice'
                  }
                  onClick={() => changeValueVariant('typeGraph', 'bar')}
                >
                  <div className="variants-filters-logo">
                    <img src={graph} alt />
                  </div>
                  <p>
                    {t('APP.VARIANT.INDEX.PH_5954B6', {
                      defaultValue: 'Diagramme en bâtons',
                    })}
                  </p>
                </div>
                <div className="variants-filters-choice not-available-now">
                  <div className="variants-filters-logo">
                    <img src={graphleft} alt />
                    <img src={graphright} alt />
                  </div>
                  <p>
                    {t('APP.VARIANT.INDEX.PH_8F1872', {
                      defaultValue: 'Sapin',
                    })}
                    <br />
                    <span>
                      {t('APP.VARIANT.INDEX.PH_AADF21', {
                        defaultValue: '(bientôt disponible)',
                      })}
                    </span>
                  </p>
                </div>
                <div className="variants-filters-choice not-available-now">
                  <div className="variants-filters-logo">
                    <img src={tableau} alt />
                  </div>
                  <p>
                    {t('APP.VARIANT.INDEX.PH_6F8137', {
                      defaultValue: 'Tableau',
                    })}
                    <br />
                    <span>
                      {t('APP.VARIANT.INDEX.PH_CC7EAD', {
                        defaultValue: '(bientôt disponible)',
                      })}
                    </span>
                  </p>
                </div>
              </div>
              {(typeGraph === 'radar' || typeGraph === 'bar') && (
                <div className="variant-graph-filters">
                  <p
                    onClick={() => {
                      if (currentFilter !== 0) {
                        changeValueVariant('limitDatas', null);
                        changeValueVariant('dataGraph', null);
                        changeValueVariant('currentFilter', 0);
                      }
                    }}
                    className={
                      currentFilter === 0 ? 'active-filter choice' : 'choice'
                    }
                  >
                    {currentAllotment.id === 2
                      ? t('APP.VARIANT.INDEX.CONTRIBUTEURS', {
                          defaultValue: 'Contributeurs',
                        })
                      : t('APP.VARIANT.INDEX.LOTS', {
                          defaultValue: 'Lot',
                        })}{' '}
                  </p>
                  <p
                    onClick={() => {
                      if (currentFilter !== 1) {
                        changeValueVariant('limitDatas', null);
                        changeValueVariant('dataGraph', null);
                        changeValueVariant('currentFilter', 1);
                      }
                    }}
                    className={
                      currentFilter === 1 ? 'active-filter choice' : 'choice'
                    }
                  >
                    {currentAllotment.id === 2
                      ? t('APP.VARIANT.INDEX.SOUS_CONTRIBUTEURS', {
                          defaultValue: 'Sous-contributeurs',
                        })
                      : t('APP.VARIANT.INDEX.FUNCTION', {
                          defaultValue: 'Fonction',
                        })}
                  </p>
                </div>
              )}
            </div>
          </div>
          <Modal show={variantModal} onHide={() => setVariantModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {t('APP.VARIANT.INDEX.PH_176EE8', {
                  defaultValue: 'Créer une variante',
                })}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formGridOverseer">
                  {/* copany choice */}
                  <Form.Label>
                    {t('APP.VARIANT.INDEX.PH_1AAF28', {
                      defaultValue: 'Entreprise',
                    })}
                  </Form.Label>
                  <Autocomplete
                    id="checkboxes-tags-demo"
                    options={companies}
                    defaultValue={currentProject.Company}
                    onChange={(event, newValue) => {
                      setCopyCompany(newValue);
                      if (currentProject.Company.id === newValue.id) {
                        setIsAddVariantSelected(true);
                      } else {
                        setIsAddVariantSelected(false);
                      }
                    }}
                    size={'small'}
                    getOptionLabel={(option) => option.name}
                    style={{ width: '36%' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('APP.VARIANT.INDEX.PH_8BE3BF', {
                          defaultValue: 'Entreprise',
                        })}
                        variant="outlined"
                        style={{ width: '277%' }}
                        className="researchBar"
                      />
                    )}
                  />

                  {/* variant or copyProject name */}
                  <Form.Label>Nom</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setVariantName(e.target.value);
                    }}
                    placeholder={t('APP.VARIANT.INDEX.PH_931575', {
                      defaultValue: 'Nom',
                    })}
                    value={variantName}
                    required
                    id="name"
                  />
                  {/* check for create exploit project */}
                  <div style={{ position: 'relative' }}>
                    <p style={{ marginBottom: '15px' }}>
                      <NoocoCheckbox
                        checked={keepLinkToCurrentProject}
                        onChange={(e) => {
                          setKeepLinkToCurrentProject(e.target.checked);
                        }}
                      />

                      {t('APP.VARIANT.KEEP_LINK_TO_CURRENT_PROJECT', {
                        defaultValue: 'Conserver le lien avec le projet d’origine',
                      })}
                    </p>
                    <p
                      style={{
                        position: 'absolute',
                        fontSize: '11px',
                        fontStyle: 'italic',
                        top: '31px',
                        left: '10px',
                      }}
                    >
                      {t('APP.VARIANT.RECOMMANDATION_TO_KEEP_LINK_WITH_PROJECT', {
                        defaultValue:
                          'Option recommandée pour la comparaison de variantes',
                      })}
                    </p>
                  </div>
                  {/* check for create exploit project */}
                  <div style={{ position: 'relative' }}>
                    <p style={{ marginBottom: '30px' }}>
                      <NoocoCheckbox
                        checked={exploitVariantDatas}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setExploitVariantDatas({
                              dates: null,
                              ddv: null,
                              state: null,
                            });
                          } else {
                            setExploitVariantDatas(null);
                          }
                        }}
                      />

                      {t('APP.VARIANT.INDEX.PH_CF15E9', {
                        defaultValue:
                          'Créer un projet exploitation à partir de mon projet',
                      })}
                    </p>
                    <p
                      style={{
                        position: 'absolute',
                        fontSize: '11px',
                        fontStyle: 'italic',
                        top: '31px',
                        left: '10px',
                      }}
                    >
                      {t('APP.VARIANT.INDEX.PH_515894', {
                        defaultValue:
                          '(Les éléments du chantier et les lots forfaitaires sur votre projet ne seront pas copiés dans le nouveau projet exploitation.)',
                      })}
                    </p>
                  </div>

                  {/* options if exploit project to create */}
                  {exploitVariantDatas && (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Période concernée</Form.Label>
                        <RangePicker
                          onChange={(e, dates) => {
                            setExploitVariantDatas({
                              ...exploitVariantDatas,
                              dates:
                                dates.find((el) => el === '') === ''
                                  ? null
                                  : dates.map((el) =>
                                      dayjs(el, dateFormat).hour(12).format()
                                    ),
                            });
                            // dispatch(updateExploitationValue('newAction',
                            //   {...newAction, date: dates.find((el) => el === '') === '' ? null : dates.map((el) => moment(el,
                            //     dateFormat).hours(12)
                            //     .format() )}));
                          }}
                          className={`permis-form`}
                          format={dayjs.localeData().longDateFormat('L')}
                        />
                      </div>

                      <Form.Label style={{ marginTop: '10px' }}>
                        {t('APP.VARIANT.INDEX.PH_9604F3', {
                          defaultValue: 'Durée de vie du bâtiment',
                        })}
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          setExploitVariantDatas({
                            ...exploitVariantDatas,
                            ddv: e.target.value,
                          });
                        }}
                        placeholder={t('APP.VARIANT.INDEX.PH_3D1B2C', {
                          defaultValue: 'Durée de vie du bâtiment',
                        })}
                        value={exploitVariantDatas.ddv}
                        type="number"
                        required
                        id="name"
                      />

                      <Form.Label style={{ marginTop: '10px' }}>
                        {t('APP.VARIANT.INDEX.PH_BE39C2', {
                          defaultValue: 'Etat des équipements/matériaux',
                        })}
                      </Form.Label>
                      <Select
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        style={{
                          width: '100%',
                          border: '1px solid #dee2e6',
                          height: '46px',
                        }}
                        value={exploitVariantDatas.state}
                        renderValue={(value) => {
                          return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className={`etatCircle ${
                                  exploitVariantDatas.state === 'new'
                                    ? 'new'
                                    : exploitVariantDatas.state === 'good'
                                    ? 'green'
                                    : exploitVariantDatas.state === 'medium'
                                    ? 'yellow'
                                    : exploitVariantDatas.state === 'bad'
                                    ? 'red'
                                    : exploitVariantDatas.state === 'to replace'
                                    ? 'grey'
                                    : ''
                                }`}
                              ></div>
                              <p style={{ marginBottom: 0, marginLeft: '1em' }}>
                                {exploitVariantDatas.state === 'new'
                                  ? 'Neuf 100%'
                                  : exploitVariantDatas.state === 'good'
                                  ? 'Bon état 75%'
                                  : exploitVariantDatas.state === 'medium'
                                  ? 'Etat moyen 50%'
                                  : exploitVariantDatas.state === 'bad'
                                  ? 'Mauvais état 25%'
                                  : exploitVariantDatas.state === 'to replace'
                                  ? 'A remplacer 0%'
                                  : ''}
                              </p>
                            </div>
                          );
                        }}
                        onChange={(e) =>
                          setExploitVariantDatas({
                            ...exploitVariantDatas,
                            state: e.target.value,
                          })
                        }
                        placeholder="Etat"
                        variant="outlined"
                        className="input-boarding researchBar"
                      >
                        <MenuItem
                          value={'new'}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <div
                            style={{ marginRight: '15px' }}
                            className={`etatCircle new`}
                          ></div>
                          <p style={{ marginRight: '15px', marginBottom: 0 }}>
                            {t('APP.VARIANT.INDEX.PH_72B6D8', {
                              defaultValue: 'Neuf',
                            })}
                          </p>
                          <p style={{ marginBottom: 0 }}>100%</p>
                        </MenuItem>
                        <MenuItem
                          value={'good'}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <div
                            style={{ marginRight: '15px' }}
                            className={`etatCircle green`}
                          ></div>
                          <p style={{ marginRight: '15px', marginBottom: 0 }}>
                            {t('APP.VARIANT.INDEX.PH_F7AA46', {
                              defaultValue: 'Bon état',
                            })}
                          </p>
                          <p style={{ marginBottom: 0 }}>75%</p>
                        </MenuItem>
                        <MenuItem
                          value={'medium'}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <div
                            style={{ marginRight: '15px' }}
                            className={`etatCircle yellow`}
                          ></div>
                          <p style={{ marginRight: '15px', marginBottom: 0 }}>
                            {t('APP.VARIANT.INDEX.PH_F986BE', {
                              defaultValue: 'Etat moyen',
                            })}
                          </p>
                          <p style={{ marginBottom: 0 }}>50%</p>
                        </MenuItem>
                        <MenuItem value={'bad'}>
                          <div
                            style={{ marginRight: '15px' }}
                            className={`etatCircle red`}
                          ></div>
                          <p style={{ marginRight: '15px', marginBottom: 0 }}>
                            {t('APP.VARIANT.INDEX.PH_8266B0', {
                              defaultValue: 'Mauvais état',
                            })}
                          </p>
                          <p style={{ marginBottom: 0 }}>25%</p>
                        </MenuItem>
                        <MenuItem value={'to replace'}>
                          <div
                            style={{ marginRight: '15px' }}
                            className={`etatCircle grey`}
                          ></div>
                          <p style={{ marginRight: '15px', marginBottom: 0 }}>
                            {t('APP.VARIANT.INDEX.PH_2EA1F4', {
                              defaultValue: 'A remplacer',
                            })}
                          </p>
                          <p style={{ marginBottom: 0 }}>0%</p>
                        </MenuItem>
                      </Select>
                    </>
                  )}
                </Form.Group>
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <ButtonFromNooco
                onClick={() => setVariantModal(false)}
                text={t('APP.VARIANT.INDEX.PH_55F380', {
                  defaultValue: 'Fermer',
                })}
                className="close-button"
              />

              <ButtonFromNooco
                disabled={
                  !variantName ||
                  (exploitVariantDatas &&
                    (!exploitVariantDatas.state ||
                      !exploitVariantDatas.dates ||
                      !exploitVariantDatas.ddv))
                }
                onClick={handleCreateVariant}
                loading={isLoadingCreateVariant}
                text={t('APP.VARIANT.INDEX.PH_81D00D', {
                  defaultValue: 'Confirmer',
                })}
                className="purple-button"
              />
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Variant;
