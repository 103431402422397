import { calcBaseAcv } from './static';
import CoefR410 from '../../shared/CoefR410';
import Coeff from '../../shared/CoefAcv';

const calcGammab4 = (ddvEquip, fUtil) => {
  return CoefR410[ddvEquip] - Coeff[ddvEquip] + fUtil * CoefR410[2 * ddvEquip];
};

/**
 *
 * @param fluid fluid sheet
 * @param usageRes usageres object
 * @param res res object
 * @param a
 * @param PER
 * @param gammab4
 * @param parentDdv
 * @param indice indice of parent
 */
const calcDynamicFluid = (
  fluid,
  usageRes,
  res,
  a,
  PER,
  gammab4,
  parentDdv,
  indice
) => {
  const tfExploitation = 0.02;
  const tfProduction = 0.02;
  const tfEnd = 0.1;
  const isB1present = indice.B1 && indice.B1 > 0;

  if (!indice) return;

  if (indice.IndexId !== 1) {
    const b1 = isB1present ? indice.B1 : indice.TOTAL;

    usageRes.b1fluide = tfExploitation * fluid.ProjectSheet.quantity * b1 * PER;

    if (isB1present) {
      usageRes.b1fluide += usageRes.b1;
      usageRes.b1 = 0;
    }

    return;
  }

  // in dyamic, only take co2 indice.
  const co2IndiceId = 1;
  const fluideIndce = fluid.Indices.find(
    (indice) => indice.id === co2IndiceId
  ).SheetIndex;

  let coefSum = 0;

  for (let year = 1; year <= PER; year += 1) {
    coefSum += Number(CoefR410[year]);

    if (isB1present) coefSum -= Number(Coeff[year]);
  }

  if (isB1present) {
    usageRes.b1fluide = (indice.B1 / parentDdv) * coefSum;
    usageRes.b1fluide += usageRes.b1;
    usageRes.b1 = 0;
    usageRes.b4fluide =
      (tfProduction + tfEnd) * (indice.B1 / (parentDdv * tfExploitation)) * gammab4;
    res.endfluide =
      tfEnd *
      (indice.B1 / (parentDdv * tfExploitation)) *
      (CoefR410[PER] - Coeff[PER]);

    return;
  }

  usageRes.b1fluide =
    tfExploitation * fluid.ProjectSheet.quantity * fluideIndce.TOTAL * coefSum;
  usageRes.b4fluide =
    (tfProduction + tfEnd) *
    fluid.ProjectSheet.quantity *
    fluideIndce.TOTAL *
    gammab4;
  res.endfluide =
    tfEnd *
    fluid.ProjectSheet.quantity *
    fluideIndce.TOTAL *
    (CoefR410[PER] - Coeff[PER]);
};

const calcBaseDynamicUsageACV = (
  sheet,
  baseAcv,
  indices,
  projectDdv,
  isDec,
  baseACV,
  isChantier
) => {
  try {
    let usageRes = {
      b1: 0,
      b2: 0,
      b3: 0,
      b4: 0,
      b6: 0,
      b7: 0,
      moduleD: 0,
      edifChantier: 0,
    };
    let coefSum = 0;
    let sheetDdv = Number(sheet.ddv);
    const quantity = sheet.ProjectSheet.quantity;
    const r = Math.floor(projectDdv / (sheet.ddv ? sheet.ddv : 1));

    if (isDec) sheetDdv = 1;

    const fUtil = projectDdv / sheetDdv - r;

    for (let year = 1; year <= projectDdv; year += 1) {
      const coeff = Number(Coeff[year]);

      usageRes.b1 += (indices.SheetIndex.B1 / sheetDdv) * coeff * quantity;
      usageRes.b2 += (indices.SheetIndex.B2 / sheetDdv) * coeff * quantity;
      usageRes.b3 += (indices.SheetIndex.B3 / sheetDdv) * coeff * quantity;
      usageRes.b4 += (indices.SheetIndex.B4 / sheetDdv) * coeff * quantity;

      // For later use
      coefSum += Number(Coeff[year]);
    }

    if (isDec) {
      if (isChantier) {
        usageRes.edifChantier = indices.SheetIndex.TOTAL * quantity;
      } else {
        // isDec.lot !== Terre
        if (isDec.lot === 'Eau') {
          usageRes.b7 += indices.SheetIndex.TOTAL * coefSum * quantity;
        } else {
          // isDec.lot === Energy
          usageRes.b6 += indices.SheetIndex.TOTAL * coefSum * quantity;
        }
      }
    }

    // Calcul B4 and "ModuleD"
    if (sheet.ddv >= projectDdv) {
      usageRes.b4 += 0;
      usageRes.moduleD += indices.SheetIndex.D * Coeff[projectDdv] * quantity;
    } else {
      const length = r - 1;
      let b4UseSum = 0;

      for (let index = 1; index <= length; index += 1) {
        b4UseSum += Number(Coeff[sheetDdv * index]);
      }

      const futilCoef = fUtil * Coeff[sheetDdv * r];

      usageRes.moduleD += b4UseSum;
      usageRes.moduleD += futilCoef;
      usageRes.moduleD += Number(Coeff[projectDdv]);
      usageRes.moduleD *= indices.SheetIndex.D * quantity;
      b4UseSum += futilCoef;
      b4UseSum *= baseAcv.prod + baseAcv.end + baseAcv.edif;
      usageRes.b4 += b4UseSum;
    }

    if (sheet.childEquip && sheet.childEquip.name.includes('Fluide'))
      calcDynamicFluid(
        sheet.childEquip,
        usageRes,
        baseACV,
        r,
        projectDdv,
        calcGammab4(sheetDdv, fUtil),
        sheetDdv,
        indices.SheetIndex
      );

    return usageRes;
  } catch (e) {
    console.log(e);
  }
};

export const dynamicCarbon = (indice, sheet, decMds, projectDdv, isChantier) => {
  if (!indice) {
    return { baseACV: 0, usageACV: 0 };

    //throw new Error("No indice found.");
  }

  let usageACV = 0;
  let baseACV = calcBaseAcv(indice, sheet.ProjectSheet.quantity);

  const isDec = decMds.find(
    (decMd) => decMd.id === sheet.ProjectSheet.MasterDataEquipmentId
  );

  if (!isChantier || (isChantier && isDec)) {
    usageACV = calcBaseDynamicUsageACV(
      sheet,
      baseACV,
      indice,
      projectDdv,
      isDec,
      baseACV,
      isChantier
    );
    baseACV.end *= Coeff[projectDdv];
  }

  return { baseACV, usageACV };
};
