import { connect } from 'react-redux';

import { updateFormValue } from '../../actions/projects';

import ImpactSunburst from '../../app/projects/LifeCycle/ImpactSunburst';

const mapStateToProps = (state) => ({
  mds: state.projects.mds,
  allotments: state.projects.allGroups.allotments,
  currentAllotment: state.projects.currentAllotment,
  allGroups: state.projects.allGroups,
  initialStepGraph: state.projects.initialStepGraph,
  copyData: state.projects.projectAllot.dataset,
  currentProject: state.projects.currentProject,
  zones: state.projects.parsedProject.zones,
  allotSteps: state.projects.allotSteps,
  clickLevelAllot: state.projects.clickLevelAllot,
  structureGraph: state.projects.projectAllot.structureGraph,
  allotmentName: state.projects.allotmentName,
});

const mapDispatchToProps = (dispatch) => ({
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ImpactSunburst);
