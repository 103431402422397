import React from 'react';

import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { updateFormValue } from '../../../actions/projects.js';

import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ProjectErrorDialog = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);
  const history = useHistory();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(updateFormValue('isResponseProjectError', false));
    setOpen(false);
    history.push('/projects');
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Oups, petit problème !'}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('APP.PROJECTERRORDIALOG.PROJECTERRORDIALOG.PH_23E666', {
              defaultValue: 'Une erreur est survenue. Veuillez contacter le support',
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('APP.PROJECTERRORDIALOG.PROJECTERRORDIALOG.PH_4E3B1B', {
              defaultValue: 'Retour à la liste des Projets',
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProjectErrorDialog;
