import './navigationAllot.scss';
//import 'antd/dist/antd.min.css';
import { Breadcrumb, Menu } from 'antd';
import { handleMdButtons, linkInBreadcrumb } from '../../../shared/utils';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import _ from 'lodash';

const NavigationAllot = ({
  allotSteps,
  updateFormValue,
  searchValue,
  currentAllotment,
  projectAllot,
  mds,
  allGroups,
  setSelectedEquipment,
  currentProject,
  selectedZoneName,
}) => {
  const { nodeSearchValue } = useSelector((state) => state.projects);

  useEffect(() => {
    if (
      searchValue &&
      allotSteps?.find((step) => step.step === 0) &&
      nodeSearchValue
    ) {
      const copyAllot = [...allotSteps];

      updateFormValue(
        'allotSteps',
        linkInBreadcrumb(
          copyAllot,
          allGroups,
          nodeSearchValue,
          currentAllotment,
          allGroups.flatAllotments,
          currentProject
        )
      );
    }
  }, [searchValue]);

  const handleLinkClick = (link) => {
    projectAllot.structureGraph.forEach((item) => {
      // Check 2 links to get equipment
      const linkDatas = item.details.find((el) => el.id === link.id);

      const secondLink = item.details.find(
        (el) => el.id === link.details.find((el) => el.MasterDataEquipmentId).id
      );

      if (linkDatas && secondLink) {
        const steps = [...item.details];

        let result = linkInBreadcrumb(
          steps,
          allGroups,
          nodeSearchValue,
          currentAllotment,
          allGroups.flatAllotments,
          currentProject
        );

        updateFormValue('allotSteps', result.reverse());

        const md = mds.find(
          (el) =>
            el.id ===
            result.find((el) => el.MasterDataEquipmentId).MasterDataEquipmentId
        );

        updateFormValue(
          'nodeSearchValue',
          result.find((el) => el.MasterDataEquipmentId)
        );
        updateFormValue('searchValue', md);
        updateFormValue('sheetToLifeCycle', item.sheets);
      }
    });
  };

  useEffect(() => {
    if (searchValue && nodeSearchValue && allotSteps?.length === 0) {
      projectAllot.structureGraph.forEach((el) => {
        let currentNode = nodeSearchValue.id === el['0'];

        if (currentNode) {
          const copy = [...el.details];

          updateFormValue('sheetToLifeCycle', el.sheets);
          updateFormValue('allotSteps', copy.reverse());

          return;
        }
      });
    }
  }, [searchValue]);

  return (
    <div className="wrapper-breadcrumb">
      <Breadcrumb separator=">">
        <Breadcrumb.Item
          className="item-allot"
          onClick={() => {
            updateFormValue('searchValue', null);
            updateFormValue('clickLevelAllot', null);
            updateFormValue('allotSteps', []);
            updateFormValue('sheetToLifeCycle', null);
            updateFormValue('sheets', null);
            updateFormValue('selectedType', null);
            updateFormValue('nodeSearchValue', null);
          }}
        >
          {' '}
          {selectedZoneName ? selectedZoneName : 'Projet'}
        </Breadcrumb.Item>
        {allotSteps?.length === 0 && (
          <Breadcrumb.Item>
            <a href=""></a>
          </Breadcrumb.Item>
        )}
        {allotSteps?.map((el, i) =>
          el?.link ? (
            <Breadcrumb.Item
              onClick={() => {
                updateFormValue('searchValue', null);
                updateFormValue('sheetToLifeCycle', null);
                updateFormValue('clickLevelAllot', el);
              }}
              overlay={() => (
                <Menu>
                  {el?.link.map((link) => (
                    <Menu.Item
                      onClick={() => {
                        handleLinkClick(link);
                      }}
                      key={link.id}
                    >
                      {link?.name}
                    </Menu.Item>
                  ))}
                </Menu>
              )}
            >
              {el?.name}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item
              className="item-allot"
              onClick={() => {
                if (el.step !== 0) {
                  setSelectedEquipment('');
                  updateFormValue('sheets', null);
                  updateFormValue('selectedType', null);

                  updateFormValue('searchValue', null);
                  updateFormValue('sheetToLifeCycle', null);
                  delete el.link;

                  updateFormValue('clickLevelAllot', el);
                }
              }}
              key={el?.id}
            >
              {el?.label || el?.name}
            </Breadcrumb.Item>
          )
        )}

        {/* <Breadcrumb.Item overlay={menu}>
                  <a href="">General</a>
                 </Breadcrumb.Item> */}
      </Breadcrumb>
    </div>
  );
};

export default NavigationAllot;
