import '../Projects.css';
import './LifeCycleTabGraphTooltip.scss';
import { ResponsiveBar } from '@nivo/bar';
import { Tooltip } from '@material-ui/core';
import {
  PROJECTS_TYPE_NAMES_TOTAL_IMPACT,
  TYPE_NOOCO,
  TYPE_RE2020,
} from '../../globalVariable/typeProject';
import { displayResultImpact } from '../../shared/utils';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import Spinner from '../../shared/Spinner';
import { formatValueWithWeight } from '../../services/mathematics/formatNumber';
import { getLegendLabel } from '../../services/text/formatText';
import { useTranslation } from 'react-i18next';

const colors = {
  fabrication: '#F9BF8F',
  fabricationForfait: '#f1c6a2',
  installation: '#92CDDB',
  installationChantier: '#92DBC6',
  installationForfait: '#aceeff',
  installationChantierForfait: 'rgb(162,241,219)',
  end: '#D99594',
  endForfait: '#f6bcbb',
  endfluide: '#f08e6f',
  endFluideForfait: '#ee9a7f',
  b2: '#7a6d87',
  b1: '#cdb7e7',
  bforfait: '#c9a9e2',
  b1fluide: '#16cacb',
  b4fluide: '#57ebeb',
  b4: '#9888ac',
  b3: '#B2A1C7',
  b6: '#A3ACCD',
  b7: '#67C283',
  D: '#ABCF8F',
  DForfait: '#c8e8ac',
  b1EquipFluid: '#7a6d87',
};

const LifeCycleAnalysisGraph = ({
  onTab,
  equipments,
  title,
  currentIndicator,
  isDED,
  projectDdv,
  isRe2020,
  currentProject,
  selectedEquipment,
}) => {
  const { t } = useTranslation();
  // Used to render tooltip in bottom axis

  // TODO_TRAD : key fr
  const phaseMap = {
    Production: {
      time: 't = 0',
      modules: [
        {
          label: 'A1',
          description: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_22A859', {
            defaultValue: 'Extraction et production des matières utilisées',
          }),
        },
        {
          label: 'A2',
          description: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_E2DBEC', {
            defaultValue:
              "Transport des matières premières jusqu'au site de fabrication",
          }),
        },
        {
          label: 'A3',
          description: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_BEF61E', {
            defaultValue: 'Fabrication du composant',
          }),
        },
      ],
    },
    Edification: {
      time: 't = 0',
      modules: [
        {
          label: 'A4',
          description: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_7120A8', {
            defaultValue:
              "Transport des composants du site de fabrication jusqu'au chantier de construction",
          }),
        },
        {
          label: 'A5',
          description: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_48378A', {
            defaultValue:
              'Chantier de construction dont la mise en oeuvre des composants',
          }),
        },
      ],
    },
    'Entretien et maintenance (Exploitation)': {
      time: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_0B4489', {
        projectDdv,
        defaultValue: 't = 1 à t = {projectDdv}',
      }),
      modules: [
        {
          label: 'B1',
          description: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_90C3E9', {
            defaultValue:
              "Impacts liés à l'utilisation des composants dans le bâtiment (hors usage eau et énergie)",
          }),
        },
        {
          label: 'B2',
          description: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_A742C1', {
            defaultValue: "Opérations d'entretien du bâtiment",
          }),
        },
        {
          label: 'B3',
          description: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_15C034', {
            defaultValue: 'Opérations de réparation du bâtiment',
          }),
        },
        {
          label: 'B4',
          description: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_5EC3B6', {
            defaultValue: 'Opérations de remplacement de composants du bâtiment',
          }),
        },
      ],
    },
    'Opération (Exploitation)': {
      time: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_84CECA', {
        projectDdv,
        defaultValue: 't = 1 à t = {projectDdv}',
      }),
      modules: [
        {
          label: 'B6',
          description: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_BC2C91', {
            defaultValue: "Impacts liés aux consommations d'énergie",
          }),
        },
        {
          label: 'B7',
          description: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_EE007C', {
            defaultValue: "Impacts liés aux consommations et rejets d'eau",
          }),
        },
      ],
    },
    Exploitation: {
      time: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_E0D803', {
        projectDdv,
        defaultValue: 't = 1 à t = {projectDdv}',
      }),
      modules: [
        {
          label: 'B',
          description: 'Exploitation',
        },
      ],
    },
    'Fin de vie': {
      time: `t = ${projectDdv}`,
      modules: [
        {
          label: 'C1',
          description: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_4B5C20', {
            defaultValue:
              'Le chantier de déconstruction (dépose de composants avant démolition) ou de démolition du bâtiment',
          }),
        },
        {
          label: 'C2',
          description: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_C69AAE', {
            defaultValue:
              "Transport de déchets du chantier de déconstruction/démolition soit vers un lieu de valorisation soit vers un lieu d'élimination",
          }),
        },
        {
          label: 'C3',
          description: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_3F026A', {
            defaultValue:
              'Valorisation des déchets du chantier de déconstruction/démolition (réemploi, réutilisation, recyclage, valorisation énergétique)',
          }),
        },
        {
          label: 'C4',
          description: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_73A257', {
            defaultValue:
              'Elimination des déchets du chantier de  déconstruction/démolition',
          }),
        },
      ],
    },
  };

  if (
    currentProject.templateType.includes(TYPE_RE2020) ||
    currentProject.templateType.includes(TYPE_NOOCO)
  ) {
    phaseMap['Module D'] = {
      time: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_9B03B6', {
        projectDdv,
        defaultValue: 'A chaque remplacement et t={projectDdv}',
      }),
      modules: [
        {
          label: 'D',
          description: t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_24F2ED', {
            defaultValue:
              "Permet de comptabiliser les bénéfices et charges environnementaux liés à la valorisation des produits en fin de vie (recyclage, valorisation énergétique) ainsi qu'à l'export d'énergie",
          }),
        },
      ],
    };
  }

  const [loading, setLoading] = useState(true);
  const [datas, setDatas] = useState({});
  const [nbOfCharacters, setNbOfCharacters] = useState(20);

  const updateSize = () => {
    if (window.innerWidth < 1300) {
      setNbOfCharacters(6);
    } else if (window.innerWidth < 1500) {
      setNbOfCharacters(10);
    } else if (window.innerWidth < 1700) {
      setNbOfCharacters(14);
    } else {
      setNbOfCharacters(20);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 1300) {
      setNbOfCharacters(6);
    }

    window.onresize = updateSize;
  }, []);

  useEffect(() => {
    setLoading(true);
    if (equipments) {
      createDataset();
    }
  }, [equipments, currentIndicator]);

  const getIndices = (res, usage, indices) => {
    res.prod = indices.AProd || indices.A1 + indices.A2 + indices.A3;
    res.edif = indices.AEdif || indices.A4 + indices.A5;

    if (
      indices.B &&
      !indices.B1 &&
      !indices.B2 &&
      !indices.B3 &&
      !indices.B4 &&
      !indices.B5 &&
      !indices.B6 &&
      !indices.B7
    )
      usage.b = indices.B;
    else {
      usage.b1 = indices.B1;
      usage.b2 = indices.B2;
      usage.b3 = indices.B3;
      usage.b4 = indices.B4;
      usage.b5 = indices.B5;
      usage.b6 = indices.B6;
      usage.b7 = indices.B7;
    }

    res.end = indices.C || indices.C1 + indices.C2 + indices.C3 + indices.C4;
    usage.moduleD = indices.D;
  };

  const createDataset = () => {
    // init result objects

    const res = { prod: 0, edif: 0, end: 0, endfluide: 0, edifChantier: 0 };
    const forfaitRes = {
      prod: 0,
      edif: 0,
      end: 0,
      edifChantier: 0,
      moduleD: 0,
    };

    let usageRes = {
      bforfait: 0,
      b1: 0,
      b2: 0,
      b3: 0,
      b4: 0,
      b6: 0,
      b7: 0,
      moduleD: 0,
      b1fluide: 0,
      b4fluide: 0,
      b1EquipFluid: 0,
    };

    let total = 0;

    equipments.forEach((sheet) => {
      // if its a selected equip, simply show indices
      if (onTab) {
        const indices = sheet.Indices.find((indice) =>
          (indice.id === currentIndicator.id) === 26 ? 1 : currentIndicator.id
        );

        if (indices) {
          getIndices(res, usageRes, indices.SheetIndex);
        }
      } else {
        if (isRe2020 && sheet.Re2020Sheet) {
          sheet.Re2020Sheet.ProjectSheet = sheet.ProjectSheet;
          sheet = sheet.Re2020Sheet;
        }

        const baseRes = sheet.acvResults.base;
        const usage = sheet.acvResults.usage;

        for (const [key, value] of Object.entries(baseRes)) {
          if (
            sheet.type_fiche === 'FORFAIT' &&
            !key.toLocaleLowerCase().includes('fluide')
          )
            forfaitRes[key] += Number(value);
          else res[key] += Number(value);

          total += Number(value);
        }

        for (const [key, value] of Object.entries(usage)) {
          usageRes[key] += Number(value);
          total += Number(value);
        }
      }
    });

    //create dataset for each cycle
    const datasets = [
      {
        cycle: t('APP.LIFECYCLE.LIFECYCLETAB.PRODUCTION', {
          defaultValue: 'Production',
        }),
        'A1-A3': res.prod,
        'A1-A3Color': colors.fabrication,
        ['A1-A3 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.FORFAIT_A1A3', {
          defaultValue: 'Forfait',
        })]: forfaitRes.prod,
        ['A1-A3 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.FORFAIT_A1A3', {
          defaultValue: 'Forfait',
        }) +
        'Color']: colors.fabricationForfait,
      },
      {
        cycle: t('APP.LIFECYCLE.LIFECYCLETAB.EDIFICATION', {
          defaultValue: 'Edification',
        }),
        'A4-A5': res.edif,
        'A4-A5Color': colors.installation,
        ['A4-A5 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.CHANTIER_A4A5', {
          defaultValue: 'Chantier',
        })]: res.edifChantier,
        ['A4-A5 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.CHANTIER_A4A5', {
          defaultValue: 'Chantier',
        }) +
        'Color']: colors.installationChantier,
        ['A4-A5 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.FORFAIT_A4A5', {
          defaultValue: 'Forfait',
        })]: forfaitRes.edif,
        ['A4-A5 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.FORFAIT_A4A5', {
          defaultValue: 'Forfait',
        }) +
        'Color']: colors.installationForfait,
        ['A4-A5 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.FORFAIT_CHANTIER_A4A5', {
          defaultValue: 'ForfaitChantier',
        })]: forfaitRes.edifChantier,
        ['A4-A5 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.FORFAIT_CHANTIER_A4A5', {
          defaultValue: 'ForfaitChantier',
        }) +
        'Color']: colors.installationChantierForfait,
      },
      {
        cycle: t('APP.LIFECYCLE.LIFECYCLETAB.LIFETIME', {
          defaultValue: 'Fin de vie',
        }),
        'C1-C4': res.end,
        'C1-C4Color': colors.end,
        ['C1-C4 ' + t('FluideKey', { defaultValue: 'Fluide' })]: res.endfluide,
        ['C1-C4 ' + t('FluideKey', { defaultValue: 'Fluide' }) + 'Color']:
          colors.end,
        ['C1-C4 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.FORFAIT_C1C3', {
          defaultValue: 'Forfait',
        })]: forfaitRes.end,
        ['C1-C4 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.FORFAIT_C1C3', {
          defaultValue: 'Forfait',
        }) +
        'Color']: colors.endForfait,
        ['C1-C4 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.FORFAIT_C1C4', {
          defaultValue: 'ForfaitFluide',
        })]: forfaitRes.endfluide,
        ['C1-C4 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.FORFAIT_C1C4', {
          defaultValue: 'ForfaitFluide',
        }) +
        'Color']: colors.endFluideForfait,
      },
      // {
      //   cycle: t('APP.LIFECYCLE.LIFECYCLETAB.MODULE_D', { defaultValue: 'Module D' }),
      //   ['Module D ' + t('APP.LIFECYCLE.LIFECYCLETAB.MODULE_D_VALUE', { defaultValue: 'Module D' })]: usageRes.moduleD,
      //   ['Module D ' + t('APP.LIFECYCLE.LIFECYCLETAB.MODULE_D_VALUE', { defaultValue: 'Module D' }) + 'Color']: colors.D,
      // },
    ];

    if (
      currentProject.templateType.includes(TYPE_RE2020) ||
      currentProject.templateType.includes(TYPE_NOOCO)
    ) {
      datasets.push({
        cycle: t('APP.LIFECYCLE.LIFECYCLETAB.MODULE_D_SECOND', {
          defaultValue: 'Module D',
        }),
        'Module D': usageRes.moduleD,
        'Module DColor': colors.D,
        'Module D Forfait': forfaitRes.moduleD,
        'Module D ForfaitColor': colors.DForfait,
      });
    }

    // if no exploitation, or the B indice is set, only show one exploitation bar
    if (
      usageRes.b1 +
        usageRes.b2 +
        usageRes.b1fluide +
        usageRes.b3 +
        usageRes.b4 +
        usageRes.b4fluide +
        usageRes.b6 +
        usageRes.b7 +
        usageRes.bforfait ===
        0 ||
      usageRes.b
    ) {
      // Even if there is now exploitation, show a bar. So i just need to
      // put any value in it, which will always be 0

      let exploitationObject = {
        cycle: 'Exploitation',
        B: usageRes.b || 0,
        BColor: colors.b1,
      };

      datasets.splice(2, 0, exploitationObject);
    } else {
      const BFluideName =
        !usageRes.b && currentIndicator.id !== 26 ? 'B1 Fluide' : 'B1-B2 Fluide';

      let entretienObject = {
        cycle: t('APP.LIFECYCLE.LIFECYCLETAB.MAINTENANCE', {
          defaultValue: 'Entretien et maintenance (Exploitation)',
        }),
        'B Forfait': usageRes.bforfait,
        'B ForfaitColor': colors.bforfait,
        B1: usageRes.b1,
        B1Color: colors.b1,
        'B1 équipements fluides': usageRes.b1EquipFluid,
        'B1 équipements fluidesColor': colors.b1,
        [`${BFluideName}`]: usageRes.b1fluide,
        [`${BFluideName}Color`]: colors.b1,
        B2: usageRes.b2,
        B2Color: colors.b2,
        B3: usageRes.b3,
        B3Color: colors.b3,
        B4: usageRes.b4,
        B4Color: colors.b4,
        'B4 Fluide': usageRes.b4fluide,
        'B4 FluideColor': colors.b4,
      };

      let operationObject = {
        cycle: t('APP.LIFECYCLE.LIFECYCLETAB.OPERATION', {
          defaultValue: 'Opération (Exploitation)',
        }),
        'B6-Energie': usageRes.b6,
        'B6-EnergieColor': colors.b1,
        'B7-Eau': usageRes.b7,
        'B7-EauColor': colors.b3,
      };

      //push the exploitation object into
      datasets.splice(2, 0, operationObject);
      datasets.splice(2, 0, entretienObject);
    }

    setDatas(datasets);
    setLoading(false);
  };

  /**
   * Renders the bottom tick of the graph.
   * @param tick
   * @returns {JSX.Element}
   * @constructor
   */
  const CustomTick = (tick) => {
    // const theme = useTheme();

    let label = tick.value;
    const fullname = tick.value;

    if (
      tick.value.length > nbOfCharacters ||
      (isDED && tick.value.length > nbOfCharacters)
    ) {
      let nbofCaracs = isDED ? nbOfCharacters / 2 + 2 : nbOfCharacters;

      label = tick.value.substring(0, nbofCaracs) + '..';
    }

    return (
      <g transform={`translate(${tick.x},${tick.y + 22})`}>
        <CustomTooltip
          title={
            <React.Fragment>
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                  padding: '5px',
                }}
              >
                <div>{fullname}</div>
                <div style={{ fontWeight: 'normal' }}>
                  {phaseMap[tick.value]?.time}
                </div>
              </div>
              <div
                style={{
                  padding: '5px',
                  display: 'flex',
                  'flex-wrap': 'wrap',
                  gap: '5px',
                }}
              >
                {phaseMap[tick.value]?.modules.map((elem) => (
                  <div style={{ fontWeight: 'normal' }} key={elem.label}>
                    {elem.label}: {elem.description}
                  </div>
                ))}
              </div>
            </React.Fragment>
          }
        >
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              // ...theme.axis.ticks.text,
              fill: '#333',
              fontSize: 10,
            }}
          >
            {label}
          </text>
        </CustomTooltip>
      </g>
    );
  };

  if (equipments.length === 0) {
    return (
      <div id="no-data-container">
        {t('APP.LIFECYCLE.LIFECYCLEANALYSISGRAPH.PH_59830C', {
          defaultValue: 'Pas de donnée',
        })}
      </div>
    );
  }

  const responsiveBarKeys = [
    'A1-A3',
    [
      'A1-A3 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.FORFAIT_A1A3', {
          defaultValue: 'Forfait',
        }),
    ],
    'A4-A5',
    [
      'A4-A5 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.FORFAIT_A4A5', {
          defaultValue: 'Forfait',
        }),
    ],
    [
      'A4-A5 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.CHANTIER_A4A5', {
          defaultValue: 'Chantier',
        }),
    ],
    [
      'A4-A5 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.FORFAIT_CHANTIER_A4A5', {
          defaultValue: 'ForfaitChantier',
        }),
    ],
    'B',
    t('APP.LIFECYCLE.LIFECYCLETAB.RESPONSIVE_BAR_KEYS_FLUIDS_EQUIPMENTS_B1', {
      defaultValue: 'B1 équipements fluides',
    }),
    'B1',
    !datas?.B && currentIndicator.id !== 26
      ? t('APP.LIFECYCLE.LIFECYCLETAB.RESPONSIVE_BAR_KEYS_FLUID_B1', {
          defaultValue: 'B1 Fluide',
        })
      : t('APP.LIFECYCLE.LIFECYCLETAB.RESPONSIVE_BAR_KEYS_FLUID_B1_B2', {
          defaultValue: 'B1-B2 Fluide',
        }),
    t('APP.LIFECYCLE.LIFECYCLETAB.RESPONSIVE_BAR_KEYS_FLUID_B4', {
      defaultValue: 'B4 Fluide',
    }),
    t('APP.LIFECYCLE.LIFECYCLETAB.RESPONSIVE_BAR_KEYS_FORFAIT_B', {
      defaultValue: 'B Forfait',
    }),
    'B2',
    'B3',
    'B4',
    t('APP.LIFECYCLE.LIFECYCLETAB.RESPONSIVE_BAR_KEYS_ENERGY_B6', {
      defaultValue: 'B6-Energie',
    }),
    t('APP.LIFECYCLE.LIFECYCLETAB.RESPONSIVE_BAR_KEYS_WATER_B7', {
      defaultValue: 'B7-Eau',
    }),
    'C1-C4',
    [
      'C1-C4 ' +
        t('APP.LIFECYCLE.LIFECYCLETAB.FORFAIT_C1C3', {
          defaultValue: 'Forfait',
        }),
    ],
    ['C1-C4 ' + t('FluideKey', { defaultValue: 'Fluide' })],
  ];

  if (
    currentProject.templateType.includes(TYPE_RE2020) ||
    currentProject.templateType.includes(TYPE_NOOCO)
  ) {
    responsiveBarKeys.push('Module D', 'Module D Forfait');
  }

  return (
    <div
      id="stacked-vertical-bars-container"
      style={
        onTab
          ? { height: '63vh', width: '100%', padding: '1em' }
          : { height: 'auto' }
      }
    >
      {selectedEquipment && (
        <p
          style={{
            padding: '0.4em 1em',
            backgroundColor: '#d6faf1',
            'border-radius': '10px',
            fontSize: '0.9rem',
            fontWeight: '500',
          }}
        >
          {selectedEquipment[0].name}
        </p>
      )}
      {title ? <h4>{title}</h4> : null}
      {!loading && (
        <ResponsiveBar
          data={datas}
          animate={true}
          enableLabel={false}
          keys={responsiveBarKeys}
          indexBy="cycle"
          margin={{ top: 60, right: 0, bottom: 30, left: 70 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={({ id, data }) => data[`${id}Color`]}
          // borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            renderTick: CustomTick,
          }}
          axisLeft={{
            legend: getLegendLabel({ currentIndicator, currentProject, onTab }),
            legendOffset: -60,
            legendPosition: 'end',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: (value) =>
              formatValueWithWeight(value, currentIndicator, currentProject),
          }}
          // tooltipFormat={value =>
          //   `${formatNumber(value)} ${currentIndicator.unit}`
          // }
          yFormat=" >-.0f"
          // labelSkipWidth={12}
          // labelSkipHeight={12}
          // labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          // motionStiffness={90}
          // motionDamping={15}
          tooltip={(data) => {
            return (
              <div className={'graphTooltip'}>
                <div className="temporalTotal">
                  <strong
                    style={{
                      color: data.color,
                      padding: '3px 0',
                    }}
                  >
                    {data.id} - {data.indexValue}
                  </strong>
                  <strong style={{ color: 'black', opacity: 0.7 }}>
                    {` : `}
                    {`${displayResultImpact(
                      data.value,
                      currentProject,
                      currentIndicator.unit,
                      onTab
                    )}`}
                  </strong>
                </div>
              </div>
            );
          }}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: 'white',
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'white',
              rotation: -45,
              lineWidth: 3,
              spacing: 10,
            },
          ]}
          fill={[
            {
              match: {
                id:
                  !datas?.B && currentIndicator.id !== 26
                    ? 'B1 Fluide'
                    : 'B1-B2 Fluide',
              },
              id: 'dots',
            },
            {
              match: {
                id: 'B1 équipements fluides',
              },
              id: 'dots',
            },
            {
              match: {
                id: 'B4 Fluide',
              },
              id: 'dots',
            },
            {
              match: {
                id: 'C1-C4 Fluide',
              },
              id: 'dots',
            },
          ]}
        />
      )}
    </div>
  );
};

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(109,109,109,0.87)',
    minWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #4B4949FF',
    borderRadius: '0px',
  },
}))(Tooltip);

export default LifeCycleAnalysisGraph;
