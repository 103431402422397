import React, { useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import './companiesProducts.scss';
import { BrandDTO } from '../../models/ProductDTO';

interface CompaniesProductsProps {
  brandsList: BrandDTO[];
  brandsSelected: BrandDTO[];
  updateProductsValue: Function;
  brandsGroup: {
    group: string;
    children: {
      name: string;
      sheetCount: { count: number; type_fiche: string }[];
    }[];
  }[];
}

const CompaniesProducts = ({
  brandsList,
  updateProductsValue,
  brandsGroup,
  brandsSelected,
}: CompaniesProductsProps) => {
  console.log(brandsList.sort((a, b) => a.name.localeCompare(b.name)));

  useEffect(() => {
    let data = brandsList.reduce((r, e) => {
      // get first letter of name of current element
      let group = e.name[0];

      // if there is no property in accumulator with this letter create it
      if (!r[group]) r[group] = { group, children: [e] };
      // if there is push current element to children array for that letter
      else r[group].children.push(e);

      // return accumulator
      return r;
    }, {});

    updateProductsValue('brandsGroup', data);
  }, []);

  const handleBrandsClick = (brand: BrandDTO) => {
    if (brandsSelected && brandsSelected.find((el) => el.name === brand.name)) {
      updateProductsValue('brandsSelected', [
        ...brandsSelected.filter((el) => el.name !== brand.name),
      ]);
    } else {
      updateProductsValue(
        'brandsSelected',
        brandsSelected ? [...brandsSelected, brand] : [brand]
      );
    }
  };

  return (
    <div className="companies-products-wrapper">
      {brandsGroup &&
        Object.values(brandsGroup)
          .sort((a, b) => {
            if (!/\d/.test(a.group) && /\d/.test(b.group)) {
              console.log(a, b);

              return -1;
            }

            if (/\d/.test(a.group) && !/\d/.test(b.group)) {
              return 1;
            }

            return 0;
          })
          .map((group, index) => {
            return (
              <div className="company-group" key={group.group}>
                <h3>{group.group}</h3>
                {group.children.map((brand, index) => {
                  let type_fiches: string[] = [];

                  return (
                    <div
                      style={{
                        marginLeft: '0.9em',
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '0.2em',
                      }}
                      key={index}
                    >
                      {brand.sheetCount.map((count, i) => {
                        if (
                          count.type_fiche === 'PEP' ||
                          count.type_fiche === 'DED' ||
                          count.type_fiche === 'FDES'
                        ) {
                          let totalCount = 0;

                          if (
                            (count.type_fiche === 'PEP' ||
                              count.type_fiche === 'FDES') &&
                            !type_fiches.includes('PEP') &&
                            !type_fiches.includes('FDES')
                          ) {
                            let countType = brand.sheetCount.filter(
                              (el) =>
                                el.type_fiche === 'PEP' || el.type_fiche === 'FDES'
                            );

                            countType.forEach((el) => {
                              totalCount += el.count;
                            });
                          } else if (count.type_fiche === 'DED') {
                            totalCount = count.count;
                          } else {
                            return;
                          }

                          type_fiches = [...type_fiches, count.type_fiche];

                          return (
                            <Tooltip
                              title={
                                count.type_fiche === 'PEP' ||
                                count.type_fiche === 'FDES'
                                  ? `${brand.sheetCount
                                      .filter(
                                        (el) =>
                                          el.type_fiche === 'PEP' ||
                                          el.type_fiche === 'FDES'
                                      )
                                      .map((el) => `${el.count} ${el.type_fiche}`)}`
                                  : `${count.count} ${count.type_fiche}`
                              }
                            >
                              <div
                                className="circle-badge"
                                style={{
                                  background:
                                    count.type_fiche === 'PEP' ||
                                    count.type_fiche === 'FDES'
                                      ? '#43ddc4'
                                      : '#363e63',
                                }}
                                key={i}
                              >
                                <span>{totalCount}</span>
                              </div>
                            </Tooltip>
                          );
                        }
                      })}
                      <p
                        onClick={() => handleBrandsClick(brand)}
                        style={{
                          marginBottom: 0,
                          marginLeft: '0.35em',
                          width: '90%',
                        }}
                      >
                        {brand.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            );
          })}
    </div>
  );
};

export default CompaniesProducts;
