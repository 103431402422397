/**
 * Created by nanaa on 18/05/2021
 */

import { connect } from 'react-redux';

import ResponsiveBar from '../../app/projects/Report/ResponsiveBar';

import {
  setSearchValue,
  updateActivatedSelects,
  updateCurrentFlag,
  updateFormValue,
} from '../../actions/projects';

import { graphToFullScreen } from '../../actions/report';

const mapStateToProps = (state) => ({
  allGroups: state.projects.allGroups,
  groups: state.projects.mds,
  isMenuOpen: state.projects.isMenuOpen,
  structureGraph: state.projects.projectAllot.structureGraph,
  isRe2020: state.projects.currentProject.isRe2020,
  fullScreen: state.report.fullScreen,
  currentAllotment: state.projects.currentAllotment,
  currentProject: state.projects.currentProject,
  allotmentName: state.projects.allotmentName,
});

const mapDispatchToProps = (dispatch) => ({
  graphToFullScreen: () => {
    dispatch(graphToFullScreen());
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  setSearchValue: (data) => {
    dispatch(setSearchValue(data));
  },
  updateCurrentFlag: (value) => {
    dispatch(updateCurrentFlag(value));
  },
  updateActivatedSelects: (id, value) => {
    dispatch(updateActivatedSelects(id, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveBar);
