import { AllotmentNodeDTO } from '../../../../../models/ProjectDTO';
import { Button } from '@photocarbone/nooco-ui';
import { Input } from 'antd';

import { Controller, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { MasterDataEquipmentDTO } from '../../../../../models/SimulatorDTO';
import { Popover } from 'antd';
import { Select } from 'antd';
import { SpecificEquipment } from '../../../../../models/CalculatorDTO';
import { StateDTO } from '../../../../../models/GeneralDTO';
import {
  calculatorUpdate,
  updateCalculatorData,
} from '../../../../../../actions/calculator';
import {
  findAllAllotmentNodeChildrenFromFilteredParentAllotmentNodes,
  findAllChildrenNodes,
  findMdsFromFilteredParentAllotmentNodes,
  findParentNode,
} from '../../../../../shared/utils';
import { getCaracs } from '../../../../../../actions/exploitation';
import { insertAfter } from 'cheerio/lib/api/manipulation';
import { makeStyles } from '@material-ui/core/styles';
import { replace } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Item from 'antd/lib/list/Item';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import SelectUi from '@mui/material/Select';
import TextField from '@material-ui/core/TextField';
import caracError from '../../../../../../assets/images/equipments/calculator/carac.png';
import carac from '../../../../../../assets/images/import/specification.png';
import { v4 as uuidv4 } from 'uuid';
import useCaseWorksite from './UseCaseWorksite';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const WorksiteSpecific = ({ fieldsSpecificForm, changeSourceOfEmptyRows }) => {
  const {
    getPreviousScreenActiveId,
    getNextScreenActiveId,
    isEquipmentFromParentZone,
    isEquipmentEditable,
    isDisplayEquipment,
    calculateAreaZoneProrata,
    onSubmitScreen: onSubmit,
    zoneSourceToDisplay,
  } = useCaseWorksite();
  const {
    control,
    register,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useFormContext();
  const { fields, append, remove, update, insert } = fieldsSpecificForm;
  const handleClose = () => {
    calculatorUpdate('isLoadingCaracs', false);
  };
  const classes = useStyles();

  const {
    worksiteCalculatorDetailedActive,
    calculatorData,
    resultOfSearchCarac,
    isLoadingCaracs,
  } = useSelector((state: StateDTO) => state.projects.calculator);
  const { t } = useTranslation();
  // const { specific } = calculatorData;
  const { type, flatAllotments } = useSelector(
    (state: StateDTO) => state.projects.allGroups
  );
  const { Lots } = useSelector((state: StateDTO) => state.projects.currentProject);
  const { zones } = useSelector((state: StateDTO) => state.projects.parsedProject);
  const { isMenuOpen } = useSelector((state: StateDTO) => state.projects);
  const { zoneSelected } = useSelector(
    (state: StateDTO) => state.monitoring.breadcrumbMultizone
  );
  const isSmallScreenMode = window.innerWidth < 1550;

  const [filteredAllotmentNodes, setFilteredAllotmentNodes] = useState<
    AllotmentNodeDTO[]
  >([]);

  useEffect(() => {
    if (filteredAllotmentNodes.length === 0) {
      let equipments = findAllAllotmentNodeChildrenFromFilteredParentAllotmentNodes(
        flatAllotments,
        // allotments.find(
        //   (allotmentNodes) => allotmentNodes.id === 1
        // ).AllotmentNodes,

        ['Energie', 'Eau', 'Terre'],
        'remove'
      ).filter((item) => item.id !== 11800);
      const LotsArray = Lots.map((lot) => lot.name);

      equipments = findAllAllotmentNodeChildrenFromFilteredParentAllotmentNodes(
        flatAllotments,
        LotsArray,
        'keep'
      );

      const idsUniques = new Set();
      const uniqueFilteredAllotmentNodes = equipments.filter((objet) => {
        if (!idsUniques.has(objet.name)) {
          idsUniques.add(objet.name);

          return true;
        }

        return false;
      });

      setFilteredAllotmentNodes(uniqueFilteredAllotmentNodes);
    }
  }, [filteredAllotmentNodes]);

  const dispatch = useDispatch();
  const { Option } = Select;

  //* update formArray when resultOfSearchCaracs is modified
  useEffect(() => {
    if (resultOfSearchCarac) {
      const { index, caracs } = resultOfSearchCarac;

      // needs to set value to null because there is no carac.value in caracs receive from back.
      // can use spread operator for prevents?
      caracs?.caracs.forEach((carac) => {
        carac.value = null;
      });

      update(index, {
        AllotmentNode: watch(`specific.specificArray.${index}.AllotmentNode`),
        MasterDataEquipment: watch(
          `specific.specificArray.${index}.MasterDataEquipment`
        ),
        quantity: watch(`specific.specificArray.${index}.quantity`),
        caracs: caracs,
        origin: {
          type: zoneSelected?.type || 'project',
          sourceId: zoneSelected?.id || null,
          uniqueId: uuidv4(),
        },
      });

      dispatch(calculatorUpdate('resultOfSearchCarac', null));
    }

    dispatch(calculatorUpdate('resultOfSearchCarac', null));
  }, [resultOfSearchCarac]);

  const searchCarac = (index: number, md) => {
    dispatch(getCaracs(md, 'calculator', index));
  };

  const onError = (errors) => {
    // console.log('validation errors',
    //   errors);
  };
  const onSubmitPrevious = (data) => {
    if (isValid) {
      changeSourceOfEmptyRows({
        zone: zoneSelected,
        sideBarActiveId: getPreviousScreenActiveId(),
      });
      dispatch(
        calculatorUpdate(
          'worksiteCalculatorDetailedActive',
          worksiteCalculatorDetailedActive - 1
        )
      );
    } else {
      onSubmit(data, 'previous');
    }
  };
  const onSubmitNext = (data) => {
    if (isValid) {
      changeSourceOfEmptyRows({
        zone: zoneSelected,
        sideBarActiveId: getNextScreenActiveId(),
      });
      dispatch(
        calculatorUpdate(
          'worksiteCalculatorDetailedActive',
          worksiteCalculatorDetailedActive + 1
        )
      );
    } else {
      onSubmit(data, 'next');
    }
  };
  const onBlur = (data) => {
    // console.log("OnBlur specific data to send", data);
    // dispatch(updateCalculatorData('specific', data));
    dispatch(calculatorUpdate('calculatorDetailled', data));
  };
  const onBlurError = (errors) => {
    console.log('onBlurError specific validation errors', errors);
  };

  const renderCaracPopOver = (index, isDisabled = false) => {
    let caracs = fields[index].caracs;

    caracs.caracs = caracs?.caracs.map((carac) => {
      return {
        ...carac,
        value: carac.defaultValue ? carac.defaultValue : carac.value,
      };
    });

    let caracMainIndex;
    const caracMain = caracs.caracs?.find((carac, index) => {
      if (carac.id === caracs.xAxisId) {
        caracMainIndex = index;

        return carac;
      }
    });
    let discreteIndex;
    const discrete = caracs.caracs?.find((carac, index) => {
      if (carac.id === caracs.discreteId) {
        discreteIndex = index;

        return carac;
      }
    });
    let secondDiscreteIndex;
    const secondDiscrete = caracs.caracs?.find((carac, index) => {
      if (carac.id === caracs.secondDiscreteId) {
        secondDiscreteIndex = index;

        return carac;
      }
    });

    let secondDiscreteOption = [];

    if (discrete?.value) {
      // console.log("j'ai une discrete");
      // console.log(caracs.caracCombos.valueList.find((option) => option.value === discrete.value).valueList);
      secondDiscreteOption = caracs?.caracCombos?.valueList?.find(
        (option) => option?.value === discrete?.value
      )?.valueList;
    }

    let productionAnnuelle = caracs.caracs?.find(
      (carac) => carac.id === 10265 || carac.id === 6892
    );

    let autoconsommationAnnuel = caracs.caracs?.find(
      (carac) => carac.id === 10266 || carac.id === 6893
    );

    if (autoconsommationAnnuel?.value === null) {
      autoconsommationAnnuel.value = '';
      productionAnnuelle.value = '';
    }

    let autoConsommationAnnuelleIndex;
    let productionAnnuelleIndex;

    if (productionAnnuelle || autoconsommationAnnuel) {
      caracs.caracs?.find((carac, index) => {
        if (carac.id === 6892 || carac.id === 10265) {
          productionAnnuelleIndex = index;
        }

        if (carac.id === 6893 || carac.id === 10266) {
          autoConsommationAnnuelleIndex = index;
        }
      });
    }

    return (
      <div>
        {/* Caracs with input Texfield caracMain*/}
        {caracMain && (
          <>
            <div
              className="exploitation-carac"
              key={`containerCaracdefault${caracMain.id}`}
            >
              <p>{caracMain.name} :</p>
              <Controller
                control={control}
                {...register(
                  `specific.specificArray.${index}.caracs.caracs.${caracMainIndex}.value`
                )}
                rules={{
                  required: fields[index].MasterDataEquipment?.id
                    ? t('APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_3D607F', {
                        defaultValue: 'Champ obligatoire',
                      })
                    : false,
                  validate: {
                    // rules={{ validate: {
                    isNumber: (v) => {
                      if (fields[index].MasterDataEquipment === null) {
                        return true;
                      } else if (!isNaN(Number(v?.replace(/,/, '.')))) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    style={{ width: '50%', marginLeft: '10px' }}
                    onBlur={handleSubmit(onBlur, onBlurError)}
                    disabled={isDisabled}
                  />
                )}
              />

              <p>{caracMain.unit}</p>
            </div>
            {errors?.specific?.specificArray?.[index]?.caracs?.caracs?.[
              caracMainIndex
            ]?.value && (
              <div
                style={{ marginLeft: '0', marginBottom: '1rem' }}
                className="container__main--errorMessage"
              >
                {t('APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_300EE3', {
                  defaultValue: 'Nombre obligatoire',
                })}
              </div>
            )}
          </>
        )}

        {/* caracs type list with select  Discrete */}
        {discrete && (
          <>
            <div
              className="exploitation-carac"
              key={`containerCaraclist${discrete?.id}`}
            >
              <p>{discrete?.name} :</p>
              <Controller
                control={control}
                {...register(
                  `specific.specificArray.${index}.caracs.caracs.${discreteIndex}.value`
                )}
                rules={{
                  required: fields[index].MasterDataEquipment?.id
                    ? t('APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_2B0C8D', {
                        defaultValue: 'Champ obligatoire',
                      })
                    : false,
                  validate: {
                    // needs for remplace isNumber from l43
                    isNumber: (v) => {
                      return true;
                    },
                  },
                }}
                render={({ field }) => (
                  <SelectUi
                    {...field}
                    // error
                    key={`specific.specificArray.${index}.caracs.caracs.${discreteIndex}.value-${index}`}
                    variant="outlined"
                    className={
                      errors?.specific?.specificArray?.[index]?.caracs?.caracs?.[
                        discreteIndex
                      ]
                        ? 'input-boarding error researchBar'
                        : 'input-boarding researchBar'
                    }
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: '50%', marginLeft: '10px' }}
                    onClose={handleSubmit(onBlur, onError)}
                    onBlur={handleSubmit(onBlur, onError)}
                    disabled={isDisabled}
                  >
                    {discrete?.caracList?.map((option, index) => {
                      return (
                        <MenuItem
                          key={`${option}discreteItem${index}`}
                          value={option.value}
                        >
                          {option.value}
                        </MenuItem>
                      );
                    })}
                  </SelectUi>
                )}
              />
            </div>
          </>
        )}

        {/* caracs type list with select  Discrete */}
        {secondDiscrete && (
          <>
            <div
              className="exploitation-carac"
              key={`containerCaraclist${secondDiscrete.id}`}
            >
              <p>{secondDiscrete.name} :</p>
              <Controller
                control={control}
                {...register(
                  `specific.specificArray.${index}.caracs.caracs.${secondDiscreteIndex}.value`
                )}
                rules={{
                  required: fields[index].MasterDataEquipment?.id
                    ? t('APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_447262', {
                        defaultValue: 'Champ obligatoire',
                      })
                    : false,
                  validate: {
                    // needs for remplace isNumber from l43
                    isNumber: (v) => {
                      return true;
                    },
                  },
                }}
                render={({ field }) => (
                  <SelectUi
                    {...field}
                    // error
                    variant="outlined"
                    className={
                      errors?.specific?.specificArray?.[index]?.caracs?.caracs?.[
                        secondDiscreteIndex
                      ]
                        ? 'input-boarding error researchBar'
                        : 'input-boarding researchBar'
                    }
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: '50%', marginLeft: '10px' }}
                    value={secondDiscrete?.value}
                    disabled={discrete?.value === null || isDisabled}
                    onChange={(e) => {
                      setValue(
                        `specific.specificArray.${index}.caracs.caracs.${secondDiscreteIndex}.value`,
                        e.target.value
                      );
                    }}
                    onClose={handleSubmit(onBlur, onError)}
                    onBlur={handleSubmit(onBlur, onError)}
                    // value={carac.caracList.find((el) => el.name === carac?.value)}
                    // value={carac.caracList.find((el) => el.name === carac.name)}
                  >
                    {/* {secondDiscrete.caracList.map((option, index) => { */}
                    {secondDiscreteOption?.map((option, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={option?.value}
                          // onClose={handleSubmit(onBlur, onError)}
                          onBlur={handleSubmit(onBlur, onError)}
                        >
                          {option?.value}
                        </MenuItem>
                      );
                    })}
                  </SelectUi>
                )}
              />
            </div>
          </>
        )}

        {(productionAnnuelle?.id === 6892 || productionAnnuelle?.id === 10265) && (
          <>
            <div
              className="exploitation-carac"
              key={`containerproduction${productionAnnuelle?.id}`}
            >
              <p>{productionAnnuelle.name}:</p>
              <Controller
                control={control}
                {...register(
                  `specific.specificArray.${index}.caracs.caracs.${productionAnnuelleIndex}.value`
                )}
                rules={{
                  required: fields[index].MasterDataEquipment?.id
                    ? t('APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_67B9EB', {
                        defaultValue: 'Champ obligatoire',
                      })
                    : false,
                  validate: {
                    // rules={{ validate: {
                    isNumber: (v) => {
                      if (fields[index].MasterDataEquipment === null) {
                        return true;
                      } else if (!isNaN(Number(v?.replace(/,/, '.')))) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    style={{ width: '50%', marginLeft: '10px' }}
                    onBlur={handleSubmit(onBlur, onBlurError)}
                  />
                )}
              />
            </div>
            {errors?.specific?.specificArray?.[index]?.caracs?.caracs?.[
              productionAnnuelleIndex
            ]?.value && (
              <div
                style={{ marginLeft: '0', marginBottom: '1rem' }}
                className="container__main--errorMessage"
              >
                {t('APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_62EA78', {
                  defaultValue: 'Nombre obligatoire',
                })}
              </div>
            )}

            <div
              className="exploitation-carac"
              key={`containerproduction${autoconsommationAnnuel?.id}`}
            >
              <p>{autoconsommationAnnuel.name}:</p>
              <Controller
                control={control}
                {...register(
                  `specific.specificArray.${index}.caracs.caracs.${autoConsommationAnnuelleIndex}.value`
                )}
                rules={{
                  required: fields[index].MasterDataEquipment?.id
                    ? t('APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_98628A', {
                        defaultValue: 'Champ obligatoire',
                      })
                    : false,
                  validate: {
                    // rules={{ validate: {
                    isNumber: (v) => {
                      if (fields[index].MasterDataEquipment === null) {
                        return true;
                      } else if (!isNaN(Number(v?.replace(/,/, '.')))) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    style={{ width: '50%', marginLeft: '10px' }}
                    onBlur={handleSubmit(onBlur, onBlurError)}
                  />
                )}
              />
            </div>
            {errors?.specific?.specificArray?.[index]?.caracs?.caracs?.[
              autoConsommationAnnuelleIndex
            ]?.value && (
              <div
                style={{ marginLeft: '0', marginBottom: '1rem' }}
                className="container__main--errorMessage"
              >
                {t('APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_FDFE35', {
                  defaultValue: 'Nombre obligatoire',
                })}
              </div>
            )}
          </>
        )}

        {/* // productionAnnuelle.id === 6892 && (
                  {<>   
                     <div className="exploitation-carac" key={`containerproduction${productionAnnuelle.id}`}>
                        <p>{productionAnnuelle.name} :</p> 
                 {/* <Controller
                          control={control}
                          {...register(`specific.specificArray.${index}.caracs.caracs.${caracMainIndex}.value`)}
                          rules={{required: fields[index].MasterDataEquipment?.id ? 'Champ obligatoire' : false , validate: {
                            // rules={{ validate: {  
                            isNumber: v => {
                               if (fields[index].MasterDataEquipment === null ) {
                                 return true;
                                  
                              } else if (!isNaN(Number(v?.replace(/,/, '.')))) {
                                     return true;
                                  
                              } else {
                                     return false;
                                  
                              }
                                
                            },
                          } }} 
                          render={({ field }) => */}

        {/* 8 */}
        {/* }
                        /> */}
        {/* <p>{caracMain.unit}</p> */}
        {/* // </div> */}
        {/* {
                              
                            errors?.specific?.specificArray?.[index]?.caracs?.caracs?.[caracMainIndex]?.value && 
                              <div style={{marginLeft: '0', marginBottom: '1rem'}}  className="container__main--errorMessage">
                                Nombre obligatoire
                              </div>
                      } */}
        {/* //     </> */}
        {/* //   ) */}
        {/* // } */}
      </div>
    );
  };

  return (
    <>
      {/* title row */}
      <div className="calculatorFlexStart">
        <h3
          className="calculator__label"
          style={{ fontSize: '15px', maxWidth: '100%' }}
        >
          {t('APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_C30F42', {
            defaultValue:
              'Les composants spécifiques correspondent aux composants utilisés pour réaliser des ouvrages provisoires nécessaires au chantier ou pour protéger certaines parties de l’ouvrage pendant le chantier.',
          })}
        </h3>
      </div>

      {/* main Specific */}
      <div
        className="calculatorFlexColumnStart"
        style={{ marginBottom: '1.5rem', alignItems: 'flex-start' }}
      >
        <form className="calculatorScrollContainer">
          <Backdrop
            open={isLoadingCaracs}
            className={classes.backdrop}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <>
            {/* All rows of specificArray  */}
            {fields.map((item, index) => {
              // display editable energie raw
              if (isEquipmentEditable(item)) {
                return (
                  <div key={item.id} className="cardWrapper ">
                    {/* selectEquipment */}
                    <div className={`specificContainer `} style={{ width: '50%' }}>
                      <Controller
                        control={control}
                        {...register(
                          `specific.specificArray.${index}.AllotmentNode`
                        )}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            className="equipmentResearch"
                            key={`autocomplete-${item.id}`}
                            style={{ borderRadius: '30px' }}
                            placeholder={t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_34C05E',
                              { defaultValue: 'Choisir un equipement' }
                            )}
                            value={
                              watch(
                                `specific.specificArray.${index}.AllotmentNode`
                              ) || null
                            }
                            id="checkboxes-tags-demo"
                            options={filteredAllotmentNodes}
                            noOptionsText={t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_FD7A5A',
                              { defaultValue: 'Aucun résultat trouvé' }
                            )}
                            onBlur={handleSubmit(onBlur, onBlurError)}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                update(index, {
                                  AllotmentNode: flatAllotments.find(
                                    (Allotment) => Allotment.id === newValue.id
                                  ),
                                  MasterDataEquipment: type.find(
                                    (masterData) =>
                                      masterData.id ===
                                      newValue.MasterDataEquipmentId
                                  ),
                                  quantity: null,
                                  caracs: null,
                                  origin: {
                                    type: zoneSelected?.type || 'project',
                                    sourceId: zoneSelected?.id || null,
                                    uniqueId: uuidv4(),
                                  },
                                });
                                dispatch(calculatorUpdate('isLoadingCaracs', true));
                                searchCarac(index, newValue);
                              } else {
                                dispatch(
                                  calculatorUpdate('resultOfSearchCarac', null)
                                );
                                update(index, {
                                  AllotmentNode: null,
                                  MasterDataEquipment: null,
                                  quantity: null,
                                  caracs: null,
                                  origin: {
                                    type: zoneSelected?.type || 'project',
                                    sourceId: zoneSelected?.id || null,
                                    uniqueId: uuidv4(),
                                  },
                                });
                              }
                            }}
                            size={'small'}
                            getOptionLabel={(option) => option.name || ''}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                onBlur={handleSubmit(onBlur, onBlurError)}
                                placeholder={t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_728317',
                                  { defaultValue: 'Ajouter un équipement' }
                                )}
                                variant="outlined"
                                className="researchBar"
                              />
                            )}
                          />
                        )}
                      />
                    </div>

                    {/* equipement quantity input */}
                    <div className="calculatorFlexColumnStart">
                      <Controller
                        control={control}
                        {...register(`specific.specificArray.${index}.quantity`)}
                        rules={{
                          required: fields[index].MasterDataEquipment?.id
                            ? t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_7D460E',
                                { defaultValue: 'Champ obligatoire' }
                              )
                            : false,
                          validate: {
                            // rules={{ validate: {
                            isNumber: (v) => {
                              if (fields[index].MasterDataEquipment === null) {
                                return true;
                              } else if (!isNaN(Number(v?.replace(/,/, '.')))) {
                                // console.log('valifdate ? ');

                                return true;
                              } else {
                                return false;
                              }
                            },
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            value={field.value}
                            disabled={
                              watch('specific.specificArray')[index]
                                .MasterDataEquipment?.id
                                ? false
                                : true
                            }
                            status={
                              errors?.specific?.specificArray?.[index]?.quantity
                                ? 'error'
                                : ''
                            }
                            onBlur={handleSubmit(onBlur, onBlurError)}
                            size="large"
                            onChange={(e) => {
                              setValue(
                                `specific.specificArray.${index}.quantity`,
                                e.target.value
                              );
                              field.onChange(e.target.value);
                            }}
                            placeholder={t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_8790C1',
                              { defaultValue: 'Quantité' }
                            )}
                            style={{ width: '130px' }}
                          />
                        )}
                      />

                      {errors?.specific?.specificArray && (
                        <div className="container__main--errorMessageCenter">
                          {errors?.specific?.specificArray?.[index]?.quantity
                            ?.type === 'isNumber'
                            ? t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_E397F7',
                                { defaultValue: 'Nombre obligatoire' }
                              )
                            : errors?.specific?.specificArray?.[index]?.quantity
                                ?.message}
                        </div>
                      )}
                    </div>

                    <div className="calculatorScrollContainer--unity">
                      {item.MasterDataEquipment?.unit}
                    </div>

                    {/* logo caracs with modal */}
                    <div className="calculatorFlexColumnStart">
                      <div className="calculatorScrollContainer--caracs energyCaracs">
                        {item.caracs?.caracs?.length > 0 && (
                          <Controller
                            control={control}
                            {...register(`specific.specificArray.${index}.caracs`)}
                            render={({ field }) => (
                              <Popover
                                {...field}
                                defaultVisible={
                                  !fields[index].caracs?.caracs?.[0]?.value
                                }
                                placement="top"
                                key={`popover-${item}`}
                                title={t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_A31DAA',
                                  { defaultValue: 'Caractéristiques' }
                                )}
                                content={renderCaracPopOver(index)}
                                trigger="click"
                              >
                                <img
                                  src={caracError}
                                  style={{
                                    filter: errors?.specific?.specificArray?.[index]
                                      ?.caracs
                                      ? undefined
                                      : 'grayscale(100%)',
                                  }}
                                />
                              </Popover>
                            )}
                          />
                        )}
                      </div>
                      {/* {errors?.specific?.specificArray?.[index]?.caracs && (
                                <div className="container__main--errorMessageCenter">
                                  Caractéristiques obligatoires
                                </div>
                               )} */}
                    </div>

                    {/* source indicator */}
                    <div className="sourceIndicator">
                      {zoneSourceToDisplay(item.origin)}
                    </div>

                    {/* deleteButton */}
                    <div className="calculator__delContainer">
                      <HighlightOffIcon
                        style={{ width: '40px', height: '40px' }}
                        className="calculator__delElement"
                        onClick={() => {
                          if (watch('specific.specificArray').length > 1) {
                            remove(index);
                            dispatch(calculatorUpdate('resultOfSearchCarac', null));
                          } else {
                            dispatch(calculatorUpdate('resultOfSearchCarac', null));
                            remove(index);
                            insert(0, {
                              AllotmentNode: null,
                              MasterDataEquipment: null,
                              quantity: null,
                              caracs: null,
                              origin: {
                                type: zoneSelected?.type || 'project',
                                sourceId: zoneSelected?.id || null,
                                uniqueId: uuidv4(),
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                );
              }

              if (isDisplayEquipment(item)) {
                let quantity = getValues(`specific.specificArray.${index}.quantity`);
                if (isEquipmentFromParentZone(item)) {
                  const quantityProrata = calculateAreaZoneProrata(item);
                  quantity = Math.round(quantity * quantityProrata * 10000) / 10000;
                }
                return (
                  <div key={item.id} className="cardWrapper disabled">
                    {/* selectEquipment */}
                    <div
                      className={`specificContainer ${
                        isSmallScreenMode ? 'isSmallScreenMode' : ''
                      }`}
                    >
                      <Controller
                        control={control}
                        {...register(
                          `specific.specificArray.${index}.AllotmentNode`
                        )}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            className="equipmentResearch"
                            key={`autocomplete-${item.id}`}
                            style={{ borderRadius: '30px' }}
                            placeholder={t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_ACDFE0',
                              { defaultValue: 'Choisir un equipement' }
                            )}
                            value={
                              watch(
                                `specific.specificArray.${index}.AllotmentNode`
                              ) || null
                            }
                            id="checkboxes-tags-demo"
                            options={filteredAllotmentNodes}
                            noOptionsText={t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_818850',
                              { defaultValue: 'Aucun résultat trouvé' }
                            )}
                            size={'small'}
                            disabled={true}
                            getOptionLabel={(option) => option.name || ''}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                disabled={true}
                                placeholder={t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_953B72',
                                  { defaultValue: 'Ajouter un équipement' }
                                )}
                                variant="outlined"
                                className="researchBar disabled"
                              />
                            )}
                          />
                        )}
                      />
                    </div>

                    {/* equipement quantity input */}
                    <div className="calculatorFlexColumnStart">
                      <Input
                        value={quantity}
                        disabled={true}
                        status={
                          errors?.specific?.specificArray?.[index]?.quantity
                            ? 'error'
                            : ''
                        }
                        size="large"
                        placeholder={t(
                          'APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_BEAB2D',
                          { defaultValue: 'Quantité' }
                        )}
                        style={{ width: '130px' }}
                      />
                    </div>

                    {/* unit */}
                    <div className="calculatorScrollContainer--unity">
                      {item.MasterDataEquipment?.unit}
                    </div>

                    {/* logo caracs with modal */}
                    <div className="calculatorFlexColumnStart">
                      <div className="calculatorScrollContainer--caracs energyCaracs newImg">
                        {item.caracs?.caracs?.length > 0 && (
                          <Controller
                            control={control}
                            {...register(`specific.specificArray.${index}.caracs`)}
                            render={({ field }) => (
                              <Popover
                                {...field}
                                defaultVisible={
                                  !fields[index].caracs?.caracs?.[0]?.value
                                }
                                placement="top"
                                key={`popover-${item}`}
                                title={t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_0FE495',
                                  { defaultValue: 'Caractéristiques' }
                                )}
                                content={renderCaracPopOver(index, true)}
                                trigger="click"
                              >
                                <img
                                  src={carac}
                                  style={{ filter: 'grayscale(100%)' }}
                                />
                              </Popover>
                            )}
                          />
                        )}
                      </div>
                    </div>

                    {/* source indicator */}
                    <div className="sourceIndicator disabled">
                      {zoneSourceToDisplay(item.origin)}
                    </div>

                    {/* deleteButton */}
                    {/* <div className="calculator__delContainer">
                              <HighlightOffIcon
                                style={{ width: '40px', height: '40px' }}
                                className="calculator__delElement"
                                
                              />
                             </div> */}
                  </div>
                );
              }
            })}
          </>
        </form>
      </div>

      {/* add new equipment */}
      <div
        className="calculator__addElement"
        onClick={() => {
          // console.log('fields',
          //   fields);
          // console.log(fields[fields.length - 1]);
          append({
            MasterDataEquipment: null,
            quantity: '',
            caracs: null,
            origin: {
              type: zoneSelected?.type || 'project',
              sourceId: zoneSelected?.id || null,
              uniqueId: uuidv4(),
            },
          });
        }}
      >
        <ControlPointIcon />
        {t('APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_6E7DDD', {
          defaultValue: 'Ajouter un équipement',
        })}
      </div>

      {/* next and Previus Button*/}
      <div
        className="calculatorFlexColumnStart"
        style={{
          flexGrow: 1,
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }}
      >
        <div className="calculatorFlexStart" style={{ justifyContent: 'flex-end' }}>
          <Button
            style={{
              fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
            }}
            className="close-button"
            onClick={handleSubmit(onSubmitPrevious, onError)}
            disabled={getPreviousScreenActiveId() === null}
            text={t('APP.CALCULATORWORKSITECONTENT.WORKSITESPECIFIC.PH_C86015', {
              defaultValue: 'Précédent',
            })}
          />
        </div>
      </div>
    </>
  );
};

export default WorksiteSpecific;
