import { Controller } from 'react-hook-form';
import { StateDTO } from '../../../../../models/GeneralDTO';
import { Switch } from 'antd';
import { useSelector } from 'react-redux';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@mui/material/Typography';

import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const pinIcon = require('../../../../../../assets/images/equipments/calculator/pin.png');

const RainWater = ({ control, register, errors, setValue, watch }) => {
  const { waterCalculator: waterCalculatorData } = useSelector(
    (state: StateDTO) => state.projects.waterCalculator
  );

  const { t } = useTranslation();

  // const [testReseauUnit, settestReseauUnit] =useState(true);

  const form = watch();

  const handleChangeSwitch = (index) => {
    console.log('handleswith form', form);
    console.log('index', index);
    console.log(form);
    console.log(
      !form?.formData?.buildings[index]?.detailledWaterProperties?.isUnitNetwork
    );

    // if (form?.formData?.buildings[index]?.detailledWaterProperties?.isUnitNetwork) {
    console.log('normalement le toggle est desactivé');
    setValue(
      `formData.buildings[${index}].detailledWaterProperties.qPluviometrie`,
      null
    );
    setValue(
      `formData.buildings[${index}].detailledWaterProperties.surfaceParking`,
      null
    );
    setValue(
      `formData.buildings[${index}].detailledWaterProperties.surfaceToiture`,
      null
    );
    // }
    setValue(
      `formData.buildings[${index}].detailledWaterProperties.isUnitNetwork`,
      !form?.formData?.buildings[index]?.detailledWaterProperties?.isUnitNetwork
    );
    // calculWastedWater();
  };

  return (
    <div className="calculatorWaterWrapper">
      {waterCalculatorData.buildings.map((building, buildingIndex) => {
        return (
          <div key={`rainComponent-${building.id}`}>
            <div className="buildingTitle">{building.name}</div>
            <div className="itemFlex" style={{ marginBottom: '1.5rem' }}>
              <Tooltip
                title={
                  <React.Fragment>
                    <Typography
                      color="inherit"
                      fontFamily="inherit"
                      fontSize="inherit"
                      fontStyle="inherit"
                      // style={{fontSize: '18px'}}
                    >
                      {t('APP.CALCULATORWATERCONTENT.RAINWATER.PH_40FF6F', {
                        defaultValue:
                          'Pour la gestion des eaux pluviales, trois cas peuvent se présenter :',
                      })}
                    </Typography>
                    <Typography
                      color="inherit"
                      fontFamily="inherit"
                      fontSize="inherit"
                      fontStyle="inherit"
                      // style={{fontSize: '15px'}}
                    >
                      {t('APP.CALCULATORWATERCONTENT.RAINWATER.PH_EC5679', {
                        defaultValue:
                          '- Gestion des eaux pluviales à l’échelle de la parcelle du bâtiment par infiltration (puits perdus, noues, etc.) : la quantité d’eaux pluviales rejetées est nulle',
                      })}
                    </Typography>
                    <Typography
                      color="inherit"
                      fontFamily="inherit"
                      fontSize="inherit"
                      fontStyle="inherit"
                      // style={{fontSize: '15px'}}
                    >
                      {t('APP.CALCULATORWATERCONTENT.RAINWATER.PH_E032B2', {
                        defaultValue:
                          '- Collecte et traitement via un réseau séparatif, sauf cas spécifiques, les eaux de pluie (provenant notamment des toitures non accessibles) sont rendues au milieu naturel sans traitement : la quantité d’eaux pluviales rejetées est nulle',
                      })}
                    </Typography>
                    <Typography
                      color="inherit"
                      fontFamily="inherit"
                      fontSize="inherit"
                      fontStyle="inherit"
                      // style={{fontSize: '15px'}}
                    >
                      {t('APP.CALCULATORWATERCONTENT.RAINWATER.PH_FEEF92', {
                        defaultValue:
                          '- Collecte et traitement via un réseau unitaire : les eaux usées et les eaux pluviales sont acheminées ensemble vers une station d’épuration en assainissement collectif (AC). Il faut faire un calcul de la quantité d’eaux pluviales rejetées',
                      })}
                    </Typography>
                  </React.Fragment>
                }
              >
                <img
                  src={pinIcon}
                  style={{
                    width: '20px',
                    marginRight: '0.5rem',
                    display: 'block',
                  }}
                />
              </Tooltip>

              <div className="label" style={{ marginRight: '0.5rem' }}>
                {t('APP.CALCULATORWATERCONTENT.RAINWATER.PH_2A9B16', {
                  defaultValue: 'Collecte et traitement via un réseau unitaire',
                })}
              </div>

              <div>
                <Switch
                  checked={
                    form?.formData?.buildings[buildingIndex]
                      ?.detailledWaterProperties?.isUnitNetwork === true
                      ? true
                      : false
                  }
                  onChange={() => handleChangeSwitch(buildingIndex)}
                />
              </div>
            </div>
            {form?.formData?.buildings[buildingIndex]?.detailledWaterProperties
              ?.isUnitNetwork && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <div className="itemFlex" style={{ marginLeft: '0' }}>
                  <div className="label">
                    {t('APP.CALCULATORWATERCONTENT.RAINWATER.PH_9899E6', {
                      defaultValue:
                        'Pluviométrie moyenne annuelle du site (Qpluviométrie en mm d’eau)',
                    })}
                  </div>
                  <Tooltip
                    title={t('APP.CALCULATORWATERCONTENT.RAINWATER.PH_53D98D', {
                      defaultValue:
                        'Cliquez et ouvrez une nouvelle fenêtre avec la carte de la pluviométrie en France',
                    })}
                  >
                    <a
                      href="http://pluiesextremes.meteo.fr/france-metropole/Cartes-pluviometriques.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={pinIcon}
                        style={{
                          width: '20px',
                          marginLeft: '0.5rem',
                          display: 'block',
                          cursor: 'pointer',
                        }}
                        onClick={() => {}}
                      />
                    </a>
                  </Tooltip>
                </div>
                <Controller
                  control={control}
                  {...register(
                    `formData.buildings[${buildingIndex}].detailledWaterProperties.qPluviometrie`
                  )}
                  rules={{
                    validate: {
                      //v is = e.currentTarget.value
                      isNumber: (v) => {
                        console.log('v', v);
                        if (v === '' || v === null) {
                          return true;
                        } else if (!isNaN(Number(v?.replace(/,/, '.')))) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      // defaultValue={building.detailledWaterProperties.qPluviometrie}
                      placeholder={t(
                        'APP.CALCULATORWATERCONTENT.RAINWATER.PH_310DBD',
                        { defaultValue: 'Saisir une quantité' }
                      )}
                      style={{ marginBottom: '1rem' }}
                      ref={
                        register(
                          `formData.buildings[${buildingIndex}].detailledWaterProperties.qPluviometrie`
                        ).ref
                      }
                      variant="outlined"
                      // disabled={true}
                      error={
                        errors?.formData?.buildings[buildingIndex]
                          ?.detailledWaterProperties?.qPluviometrie
                          ? true
                          : false
                      }
                      className="textFieldV1 textFieldWith200"
                    />
                  )}
                />

                <div className="label" style={{ marginBottom: '0.5rem' }}>
                  {t('APP.CALCULATORWATERCONTENT.RAINWATER.PH_6DF3B8', {
                    defaultValue:
                      'Surfaces de toitures/couvertures (Stoitures en m²)',
                  })}
                </div>
                <Controller
                  control={control}
                  {...register(
                    `formData.buildings[${buildingIndex}].detailledWaterProperties.surfaceToiture`
                  )}
                  rules={{
                    validate: {
                      //v is = e.currentTarget.value
                      isNumber: (v) => {
                        console.log('v', v);
                        if (v === '' || v === null) {
                          return true;
                        } else if (!isNaN(Number(v?.replace(/,/, '.')))) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      // defaultValue={building.detailledWaterProperties.surfaceToiture}
                      placeholder={t(
                        'APP.CALCULATORWATERCONTENT.RAINWATER.PH_ABD3D3',
                        { defaultValue: 'Saisir une quantité' }
                      )}
                      ref={
                        register(
                          `formData.buildings[${buildingIndex}].detailledWaterProperties.surfaceToiture`
                        ).ref
                      }
                      style={{ marginBottom: '1rem' }}
                      variant="outlined"
                      // disabled={true}
                      error={
                        errors?.formData?.buildings[buildingIndex]
                          ?.detailledWaterProperties?.surfaceToiture
                          ? true
                          : false
                      }
                      className="textFieldV1 textFieldWith200"
                    />
                  )}
                />

                <div className="label" style={{ marginBottom: '0.5rem' }}>
                  {t('APP.CALCULATORWATERCONTENT.RAINWATER.PH_541BBA', {
                    defaultValue:
                      'Surfaces imperméabilisées de la parcelle occupées par des aires de stationnement non couvertes (Sparkings imperméablilisés non couverts en m²)',
                  })}
                </div>
                <Controller
                  control={control}
                  {...register(
                    `formData.buildings[${buildingIndex}].detailledWaterProperties.surfaceParking`
                  )}
                  rules={{
                    validate: {
                      //v is = e.currentTarget.value

                      isNumber: (v) => {
                        console.log('v', v);
                        if (v === '' || v === null) {
                          return true;
                        } else if (!isNaN(Number(v?.replace(/,/, '.')))) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      // defaultValue={building.detailledWaterProperties.surfaceParking}
                      placeholder={t(
                        'APP.CALCULATORWATERCONTENT.RAINWATER.PH_40D067',
                        { defaultValue: 'Saisir une quantité' }
                      )}
                      ref={
                        register(
                          `formData.buildings[${buildingIndex}].detailledWaterProperties.surfaceParking`
                        ).ref
                      }
                      style={{ marginBottom: '1.5rem' }}
                      variant="outlined"
                      // disabled={true}
                      error={
                        errors?.formData?.buildings[buildingIndex]
                          ?.detailledWaterProperties?.surfaceParking
                          ? true
                          : false
                      }
                      className="textFieldV1 textFieldWith200"
                    />
                  )}
                />
              </div>
            )}
            {!form?.formData?.buildings[buildingIndex]?.detailledWaterProperties
              ?.isUnitNetwork && (
              <>
                <div className="label">
                  {t('APP.CALCULATORWATERCONTENT.RAINWATER.PH_4851D3', {
                    defaultValue:
                      "Pour une collecte des eaux pluviales et un traitement via un réseau séparatif ou une gestion des eaux pluviales à l'echelle de la parcelle du bâtiment par infiltration :",
                  })}
                </div>
                <div className="special">
                  {t('APP.CALCULATORWATERCONTENT.RAINWATER.PH_869A5C', {
                    defaultValue: "Quantités d'eaux pluviales rejetées = 0 m³/an",
                  })}
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RainWater;
