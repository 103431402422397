import React from 'react';
import { BundleDTO, BundleLineDTO } from '../../../../../models/BundleDTO';
import { useTranslation } from 'react-i18next';
import {
  findParentNode,
  handleAllotmentNodes,
  handleKeyPress,
} from '../../../../../shared/utils';
import { useDispatch, useSelector } from 'react-redux';
import { StateDTO } from '../../../../../models/GeneralDTO';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { SearchGroup } from '@photocarbone/nooco-ui';
import { Popover } from 'antd';
import caracs from '../../../../../../assets/images/import/specification.png';
import xmlPic from '../../../../../../assets/images/equipments/xml.png';
import { updateBundleValue } from '../../../../../../actions/bundle';
import { updateMonitoring } from '../../../../../../actions/monitoring';
import API from '../../../../../services/API';
import { updateFormValue } from '../../../../../../actions/projects';

const api = new API();

export type RenderConfiguredSheetProps = {
  bundleLines: BundleLineDTO[];
  xmlFile: undefined | File | { name: string };
  setXmlFile: Function;
  line: BundleDTO;
  handleChangeEditBundleLines: Function;
  isNewBundle: boolean;
  handleOnChangeDropdown: Function;
  searchBarOptionsFiltered: any;
  returnDefaultValueTypeEquipment: Function;
  certificationBundleChoice: any;
  handleChangeAllotmentNodes: Function;
  renderCaracPopOver: Function;
};
const RenderConfiguredSheet = ({
  bundleLines,
  xmlFile,
  setXmlFile,
  line,
  handleChangeEditBundleLines,
  isNewBundle,
  handleOnChangeDropdown,
  searchBarOptionsFiltered,
  returnDefaultValueTypeEquipment,
  certificationBundleChoice,
  handleChangeAllotmentNodes,
  renderCaracPopOver,
}: RenderConfiguredSheetProps) => {
  const { allGroups, certifiedAllotments, listOfNodesSearchBar } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { newBundle, editBundle } = useSelector((state: StateDTO) => state.bundle);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="bundle-details-equipments">
      <div className="header-table-configured-sheet">
        {/* fileChoice */}
        <div>
          <span
            className="dark-blue-font"
            style={{ paddingLeft: '2em', display: 'inline-block' }}
          >
            {t('APP.BUNDLE.BUNDLELIST.BUNDLECARD.BUNDLEDETAILS.FILE_CHOICE', {
              defaultValue: 'Choix du fichier',
            })}
          </span>
        </div>
        {/* searchGroup with Lots */}
        <p
          style={{
            display: 'grid',
            'grid-template-columns': '[first] 21%[line2] 51% [line3] 29%',
          }}
        >
          <span
            className="dark-blue-font"
            style={{ paddingLeft: '2em', display: 'inline-block' }}
          >
            {t('APP.ARCHITECTUREFORM.INDEX.PH_C9BC2F', {
              defaultValue: 'Equipement/Matériau',
            })}
          </span>
          <div></div>
          <div style={{ display: 'flex' }}>
            {certificationBundleChoice['Nooco'] && (
              <span
                className="dark-blue-font"
                style={{ display: 'block', textAlign: 'center', width: '55px' }}
              >
                {t('APP.BUNDLE.BUNDLELIST.BUNDLECARD.BUNDLEDETAILS.LOT_NOOCO', {
                  defaultValue: ' Lot Nooco',
                })}
              </span>
            )}

            {certificationBundleChoice['RE2020'] && (
              <span
                className="dark-blue-font"
                style={{ display: 'block', textAlign: 'center', width: '55px' }}
              >
                {t('APP.HELP.FAQ.PH_5BD2AB', {
                  defaultValue: 'RE2020',
                })}
              </span>
            )}

            {certificationBundleChoice['EPCM'] && (
              <span
                className="dark-blue-font"
                style={{ display: 'block', textAlign: 'center', width: '55px' }}
              >
                {t('APP.HELP.FAQ.PH_AEF1AA', {
                  defaultValue: 'E+C-',
                })}
              </span>
            )}
          </div>
        </p>
        {/* carac */}
        <p className="dark-blue-font">
          {t('APP.BUNDLEDETAILS.INDEX.PH_0132FA', { defaultValue: 'Carac' })}.
        </p>
        <p></p>
      </div>
      {bundleLines?.map((bundleLine: BundleLineDTO) => {
        const isModeledProductEquipment = bundleLine?.type_fiche === 'custom_user';
        let nodeWithMd;
        let result;
        if (!isModeledProductEquipment) {
          nodeWithMd = bundleLine.AllotmentNodes?.find(
            (node) => node.MasterDataEquipmentId
          );

          result = bundleLine.AllotmentNodes
            ? handleAllotmentNodes(
                allGroups.type.find(
                  (md) => md.id === nodeWithMd?.MasterDataEquipmentId
                ),
                allGroups.allotments.find((allot) => allot.id === 1),
                allGroups.flatAllotments,
                null
              )
            : null;
        }

        return (
          <div key={bundleLine} className="configured-sheet-details-lines">
            {/* fileChoice */}
            <div className="config-choice" style={{ paddingLeft: '2em' }}>
              <img
                onClick={() => {
                  const trigger = document.querySelector(`.file-${line.id}`);

                  trigger.click();
                }}
                src={xmlPic}
                alt
              />
              {!xmlFile && !line?.fileName ? (
                <label
                  style={{ fontSize: '1rem' }}
                  className={`file-${line.id}`}
                  htmlFor={`file-${line.id}`}
                >
                  {t('APP.BUNDLE.BUNDLELIST.BUNDLECARD.BUNDLEDETAILS.XML_CHOICE', {
                    defaultValue: 'Choisir un fichier xml',
                  })}
                </label>
              ) : (
                <div
                  style={{ display: 'flex', gap: '12px', alignItems: 'flex-end' }}
                >
                  <label
                    className={`file-${line.id}`}
                    style={{ fontSize: '1rem' }}
                    htmlFor={`file-${line.id}`}
                  >
                    {xmlFile?.name || line[0]?.fileName}
                  </label>
                  <HighlightOffIcon
                    onClick={() => {
                      setXmlFile(undefined);

                      if (isNewBundle) {
                        const newBundleUpdated = { ...newBundle };
                        newBundleUpdated.lines[0].fileName = '';
                        dispatch(updateBundleValue('newBundle', newBundleUpdated));
                      } else {
                        let editLine = editBundle.find(
                          (bundle) => bundle.id === line.id
                        );
                        handleChangeEditBundleLines(
                          editLine,
                          bundleLine,
                          '',
                          'fileName'
                        );
                      }
                    }}
                  />
                </div>
              )}

              <input
                type="file"
                name={`file-${line.id}`}
                id={`file-${line.id}`}
                accept="text/xml,application/xml,.xml"
                className="inputfile"
                key={xmlFile}
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    const validTypes = ['text/xml', 'application/xml'];
                    const extension = file.name.split('.').pop().toLowerCase();

                    if (validTypes.includes(file.type) || extension === 'xml') {
                      setXmlFile(file);
                      if (isNewBundle) {
                        const newBundleUpdated = { ...newBundle };
                        newBundleUpdated.lines[0].fileName = file.name;
                        dispatch(updateBundleValue('newBundle', newBundleUpdated));
                      } else {
                        let editLine = editBundle.find(
                          (bundle) => bundle.id === line.id
                        );
                        handleChangeEditBundleLines(
                          editLine,
                          bundleLine,
                          file.name,
                          'fileName'
                        );
                      }
                    } else {
                      dispatch(
                        updateBundleValue('bundleNotification', {
                          notificationType: 'error',
                          text: t('APP.ADDEQUIPMENT.INDEX.FC.IMPORT.ERROR', {
                            defaultValue:
                              'Le type de fichier n’est pas correct. Merci de charger uniquement des fichiers de type XML (extension .xml)',
                          }),
                        })
                      );
                      e.target.value = '';
                    }
                  }
                }}
              />
            </div>
            {/* searchGroup with Lots */}
            <div className="search-bar-complex" style={{ paddingLeft: '2em' }}>
              <SearchGroup
                t={t}
                searchBarOptionsFiltered={searchBarOptionsFiltered}
                certifiedAllotments={certifiedAllotments}
                isBundleScreen={true}
                triggerBreadcrumb={() => {
                  dispatch(updateFormValue('openBreadcrumb', true));
                  dispatch(updateFormValue('currentBundleLine', bundleLine));
                }}
                findParentNode={findParentNode}
                allAllotments={allGroups.flatAllotments}
                allotmentsAvailable={listOfNodesSearchBar?.filter(
                  (el) => !el.isObsolete
                )}
                dropdownHeight="55px"
                api={api}
                dropdownProps={{
                  onSelect: () => {},
                  onChange: (value) => {
                    handleOnChangeDropdown(value, bundleLine);
                  },
                  placeholder: t('APP.BUNDLEDETAILS.INDEX.PH_B43F0F', {
                    defaultValue: 'Rechercher...',
                  }),
                  treeCheckable: false,
                  value: !bundleLine.AllotmentNodes
                    ? null
                    : isModeledProductEquipment
                    ? bundleLine?.name
                    : bundleLine.Sheet?.name
                    ? bundleLine.Sheet.name
                    : bundleLine.AllotmentNodes
                    ? bundleLine.AllotmentNodes.find(
                        (node) => node.MasterDataEquipmentId
                      )?.name
                    : bundleLine?.name
                    ? bundleLine.name
                    : '',
                }}
                selectProps1={{
                  defaultValue: returnDefaultValueTypeEquipment(bundleLine),
                  value: bundleLine.searchBarType,
                  filterOptions: ['equipements'],
                }}
                selectProps2={{
                  ListType: Object.entries(certificationBundleChoice)
                    .filter(([key, value]) => value)
                    .map(([key, value]) => key),
                  noocoOptions: result
                    ? result.filter((el) => !el.isObsolete)
                    : bundleLine.Sheet?.AllotmentNodes || [],
                  keyDisplay: 'name',
                  keyItem: 'id',
                  onChange: (selectedOptionValue, type) => {
                    const data = result?.find(
                      (el) => el.name === selectedOptionValue
                    );

                    let nodeSelected =
                      type === 'Nooco'
                        ? data.details.find((el) => el.MasterDataEquipmentId)
                        : selectedOptionValue;

                    handleChangeAllotmentNodes(nodeSelected, type, bundleLine);
                  },
                  value: bundleLine.AllotmentNodes,
                }}
                selectWidth="100px"
              />
            </div>
            <div className="caracs" style={{ width: '20%' }}>
              {bundleLine.AllotmentNodes &&
                !bundleLine.Sheet?.type_fiche &&
                bundleLine.caracs &&
                bundleLine.caracs.caracs?.length > 0 && (
                  <div>
                    <Popover
                      placement="top"
                      title={t('APP.BUNDLEDETAILS.INDEX.PH_BE774E', {
                        defaultValue: 'Caractéristiques',
                      })}
                      content={() => renderCaracPopOver(bundleLine)}
                      trigger="click"
                    >
                      <div>
                        <img
                          src={caracs}
                          style={{
                            marginLeft: '5px',
                            width: '27px',
                            filter: bundleLine?.caracs?.caracs?.find(
                              (car) => car && (!car.value || car.value === '')
                            )
                              ? 'invert(21%) sepia(52%) saturate(5667%) hue-rotate(354deg) brightness(88%) contrast(133%)'
                              : undefined,
                          }}
                          alt=""
                        />
                      </div>
                    </Popover>
                  </div>
                )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RenderConfiguredSheet;
