import { Button } from '@photocarbone/nooco-ui';
import { StateDTO } from '../../../../../models/GeneralDTO';
// import { Tooltip } from '@mui/material';
import {
  changeModelingEquipments,
  changeValueEquipments,
} from '../../../../../../actions/equipments';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

const SelectModeling = ({ carbonWeightFormInstance }) => {
  const dispatch = useDispatch();
  const { modeling } = useSelector((state: StateDTO) => state.equipments);
  const { reset } = carbonWeightFormInstance;
  const { t } = useTranslation();

  return (
    <div className="selectModelingWrapper">
      <div className="indication">
        {t('APP.SELECTMODELING.SELECTMODELING.PH_0A5A2D', {
          defaultValue:
            "Seul l'indicateur carbone de l'équipement sera à saisir ou estimer",
        })}
      </div>
      <div className="buttonsWrapper">
        <Tooltip
          title={t('APP.SELECTMODELING.SELECTMODELING.PH_1F3E6C', {
            defaultValue:
              'Saisir le poids carbone du produit à modéliser phase par phase.',
          })}
        >
          <div>
            <Button
              className="purple-button"
              style={{ margin: '2rem', padding: '10px 40px' }}
              text={t('APP.SELECTMODELING.SELECTMODELING.PH_F327F3', {
                defaultValue: 'Saisir le poids carbone du produit',
              })}
              onClick={() => {
                // dispatch(changeModelingEquipments('isCarbonWeightSelected',
                //   true));
                dispatch(
                  changeValueEquipments('addDisplay', [
                    '1',
                    '2',
                    '3',
                    '4',
                    'modeling',
                  ])
                );
                dispatch(
                  changeValueEquipments('modeling', {
                    ...modeling,
                    isCarbonWeightSelected: true,
                    materialLines: [],
                    totalWeight: null,
                    addMaterialLine: {
                      masterdata: null,
                      percent: null,
                    },
                  })
                );
              }}
            />
          </div>
        </Tooltip>
        <Tooltip
          title={t('APP.SELECTMODELING.SELECTMODELING.PH_CB3175', {
            defaultValue:
              'Le poids carbone du produit va être estimé à partir de sa composition matériaux ainsi qu’un indice de manufacture. Les phases d’installation, d’utilisation et de fin de vie ne sont pas calculées.',
          })}
        >
          <div>
            <Button
              className="purple-button"
              style={{ margin: '2rem', padding: '10px 40px' }}
              text={t('APP.SELECTMODELING.SELECTMODELING.PH_AB6FBA', {
                defaultValue: 'Estimer le poids carbone du produit',
              })}
              onClick={() => {
                reset();
                dispatch(
                  changeValueEquipments('modeling', {
                    ...modeling,
                    isCarbonWeightSelected: false,
                  })
                );
                dispatch(
                  changeValueEquipments('addDisplay', [
                    '1',
                    '2',
                    '3',
                    '4',
                    'modeling',
                  ])
                );
              }}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default SelectModeling;
