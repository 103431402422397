import './breadcrumbMultizone.scss';
import { StateDTO } from '../../models/GeneralDTO';
import { Tooltip } from 'antd';
import { ZoneDTO } from '../../models/ProjectDTO';
import { patchMonitoringBreadcrumbMultizone } from '../../../actions/monitoring';
import { useDispatch, useSelector } from 'react-redux';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import informationIcon from '../../../assets/images/equipments/information.png';

const SCROLL_AMOUNT = 400;

export type BreadcrumbMultizoneProps = {
  isActiveScreenValid: () => boolean;
  changeSourceOfEmptyRows?: ({ zone }: { zone: ZoneDTO | null }) => void;
  onChangeZone?: ({ zone }: { zone: ZoneDTO | null }) => void;
  isZoneFilled: (zone: ZoneDTO | null) => boolean;
  isDisplayProject?: boolean;
  isDisplayPlot?: boolean;
  isDisplayZone?: boolean;
  tooltip?: string | null;
};
const BreadcrumbMultizone = ({
  isActiveScreenValid,
  onChangeZone = () => {},
  changeSourceOfEmptyRows = () => {},
  isZoneFilled,
  isDisplayProject = true,
  isDisplayPlot = true,
  isDisplayZone = true,
  tooltip = null,
}: BreadcrumbMultizoneProps) => {
  const { zones } = useSelector((state: StateDTO) => state.projects.parsedProject);
  const { zoneSelected } = useSelector(
    (state: StateDTO) => state.monitoring.breadcrumbMultizone
  );
  const buildings = zones.filter((zone) => zone.type === 'building');
  const plot = zones.find((zone) => zone.type === 'plot') || null;
  type Zone = {
    zone: ZoneDTO | null;
  };
  const dispatch = useDispatch();
  const [canScrollLeft, setCanScrollLeft] = useState(true);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const scrollContainerRef = useRef(null);

  const checkScrollLimits = () => {
    const container = scrollContainerRef.current;

    if (container) {
      const maxScroll = container.scrollWidth - container.clientWidth;

      setCanScrollLeft(container.scrollLeft > 0);
      setCanScrollRight(container.scrollLeft < maxScroll);
    }
  };

  useEffect(() => {
    // Vérifiez les limites initiales du défilement lorsque le composant est monté
    checkScrollLimits();

    // Vous pouvez également ajouter un écouteur d'événement pour des vérifications continues
    const container = scrollContainerRef.current;

    if (container) {
      container.addEventListener('scroll', checkScrollLimits);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', checkScrollLimits);
      }
    };
  }, []);

  const handleHorizontalScroll = (direction) => {
    const container = scrollContainerRef.current;

    if (container) {
      let newScrollPosition;

      if (direction === 'left') {
        newScrollPosition = container.scrollLeft - SCROLL_AMOUNT;
      } else {
        newScrollPosition = container.scrollLeft + SCROLL_AMOUNT;
      }

      // Utilisez scrollTo pour un défilement animé
      container.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth',
      });

      setTimeout(checkScrollLimits, 300);
    }
  };

  const handleChangeSelectedZone = (zone) => {
    if (isActiveScreenValid()) {
      changeSourceOfEmptyRows({ zone });
      onChangeZone({ zone });
      dispatch(patchMonitoringBreadcrumbMultizone('zoneSelected', zone));
    }
  };

  // Project, Plot and Building Button
  const ButtonPrimary = ({ zone }: Zone): React.ReactElement => {
    const isFilled = isZoneFilled(zone);

    return (
      <div
        className="breadcrumbButton"
        onClick={() => handleChangeSelectedZone(zone)}
      >
        <div className={`buttonPrimary ${isFilled ? 'filled' : ''} `}>
          {zone?.name || 'Projet'}
        </div>
      </div>
    );
  };

  // zone Button
  const ButtonSecondary = ({ zone }: Zone): ReactElement => {
    const isFilled = isZoneFilled(zone);

    return (
      <div
        className={`breadcrumbButton`}
        onClick={() => handleChangeSelectedZone(zone)}
      >
        <div className={`buttonSecondary ${isFilled ? 'filled' : ''} `}>
          {zone?.name}
        </div>
      </div>
    );
  };

  const TabPrimary = ({ zone }: Zone): ReactElement => {
    return (
      <div className="tab">
        <div>{zone?.name || 'Projet'}</div>
        <div className="tabFooter"></div>
      </div>
    );
  };

  const TabSecondary = ({ zone }: Zone): ReactElement => {
    return (
      <div className="tabSecondary">
        <div>{zone?.name}</div>
        <div className="tabFooter"></div>
      </div>
    );
  };

  const ProjectButton = (): ReactElement => {
    if (zoneSelected === null) {
      return <TabPrimary zone={null} />;
    }

    return <ButtonPrimary zone={null} />;
  };

  const ParcelButton = (): ReactElement => {
    if (!plot) {
      return <></>;
    }

    if (zoneSelected?.id === plot?.id) {
      return <TabPrimary zone={plot} />;
    }

    return <ButtonPrimary zone={plot} />;
  };

  const BuildingButton = ({ zone }: Zone): ReactElement => {
    if (zoneSelected?.id === zone?.id) {
      return <TabPrimary zone={zone} />;
    }
    return <ButtonPrimary zone={zone} />;
  };

  const ZoneButton = ({ zone }: Zone): ReactElement => {
    if (zoneSelected?.id === zone?.id) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{ height: '1px', backgroundColor: '#A3AED0', width: '1rem' }}
          />
          <TabSecondary zone={zone} />
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ height: '1px', backgroundColor: '#A3AED0', width: '1rem' }} />
        <ButtonSecondary zone={zone} />
      </div>
    );
  };

  return (
    <div className="breadcrumb__wrapper">
      {/* title & logo  */}
      {tooltip && tooltip?.length > 0 && (
        <Tooltip title={tooltip}>
          <div className="iconWrapper">
            <img src={informationIcon} />
          </div>
        </Tooltip>
      )}
      <h2>Echelle de saisie :</h2>
      {/* multizone */}
      <div className="breadcrumb">
        {/* project Button */}
        {isDisplayProject && <ProjectButton />}
        {/* leftScrollIcon */}
        <div
          className={`arrowIcon ${canScrollLeft ? '' : 'disabled'}`}
          onClick={() => {
            if (canScrollLeft) {
              handleHorizontalScroll('left');
            }
          }}
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12.5" cy="12.5" r="12.5" fill="currentColor" />
            <path
              d="M14.69 7.21001C14.7827 7.30252 14.8562 7.41241 14.9064 7.53338C14.9566 7.65436 14.9824 7.78404 14.9824 7.91501C14.9824 8.04598 14.9566 8.17566 14.9064 8.29663C14.8562 8.4176 14.7827 8.52749 14.69 8.62001L10.81 12.5L14.69 16.38C14.877 16.567 14.982 16.8206 14.982 17.085C14.982 17.3494 14.877 17.603 14.69 17.79C14.503 17.977 14.2494 18.082 13.985 18.082C13.7206 18.082 13.467 17.977 13.28 17.79L8.68998 13.2C8.59727 13.1075 8.52373 12.9976 8.47355 12.8766C8.42336 12.7557 8.39753 12.626 8.39753 12.495C8.39753 12.364 8.42336 12.2344 8.47355 12.1134C8.52373 11.9924 8.59727 11.8825 8.68998 11.79L13.28 7.20001C13.66 6.82001 14.3 6.82001 14.69 7.21001Z"
              fill="white"
            />
          </svg>
        </div>
        {/* building and zone part */}
        <div className={`buildingsWrapper `} ref={scrollContainerRef}>
          {buildings.map((building) => {
            return (
              <React.Fragment key={building.id}>
                <BuildingButton zone={building} />
                {isDisplayZone &&
                  building?.Zones?.map((zone) => {
                    return <ZoneButton zone={zone} key={zone.id} />;
                  })}
              </React.Fragment>
            );
          })}
        </div>
        {/* rightScrollIcon */}
        <div
          className={`arrowIcon ${canScrollRight ? '' : 'disabled'}`}
          onClick={() => {
            if (canScrollRight) {
              handleHorizontalScroll('right');
            }
          }}
        >
          <svg
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12.5" cy="12.5" r="12.5" fill="currentColor" />
            <path
              d="M10.4355 7.21001C10.3428 7.30252 10.2693 7.41241 10.2191 7.53338C10.1689 7.65436 10.1431 7.78404 10.1431 7.91501C10.1431 8.04598 10.1689 8.17566 10.2191 8.29663C10.2693 8.4176 10.3428 8.52749 10.4355 8.62001L14.3155 12.5L10.4355 16.38C10.2485 16.567 10.1435 16.8206 10.1435 17.085C10.1435 17.3494 10.2485 17.603 10.4355 17.79C10.6225 17.977 10.8761 18.082 11.1405 18.082C11.4049 18.082 11.6585 17.977 11.8455 17.79L16.4355 13.2C16.5282 13.1075 16.6018 12.9976 16.6519 12.8766C16.7021 12.7557 16.728 12.626 16.728 12.495C16.728 12.364 16.7021 12.2344 16.6519 12.1134C16.6018 11.9924 16.5282 11.8825 16.4355 11.79L11.8455 7.20001C11.4655 6.82001 10.8255 6.82001 10.4355 7.21001Z"
              fill="white"
            />
          </svg>
        </div>
        {/* plot */}
        {isDisplayPlot && <ParcelButton />}
      </div>
    </div>
  );
};

export default BreadcrumbMultizone;
