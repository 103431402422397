import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { CurrentIndicatorDTO } from '../../../models/ProjectsDTO';
import { AllotmentNodeDTO, ProjectDTO } from '../../../models/ProjectDTO';
import {
  TEMPLATE_TYPE_ID,
  TEMPLATE_TYPE_NAME,
} from '../../../globalVariable/typeProject';
import i18nInstance from '../../../../i18n';
import { getColumnUnitString } from './tableColumns';
import { getFlatContributorListTableData } from '../../../shared/utils';
import { useTranslation } from 'react-i18next';

const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

type exportXlsxProps = {
  dataTable: [];
  groupBy: string | undefined;
  currentProject: ProjectDTO;
  currentIndicator: CurrentIndicatorDTO;
  t: Function;
  re2020Allotments: AllotmentNodeDTO[];
};

export const exportXlsx = ({
  dataTable,
  groupBy,
  currentProject,
  currentIndicator,
  t,
  re2020Allotments,
}: exportXlsxProps): void => {
  const getDataToExport = (sheet) => {
    const columnUnitString = getColumnUnitString(
      currentIndicator.unit,
      currentProject.refUnit
    );

    const getRe2020SousLotAndLot = (sheet) => {
      const re2020SousLotAllotmentIdRef =
        sheet.MasterDataEquipment?.AllotmentNodes[0].Re2020AllotmentNodeId || null;
      if (!re2020SousLotAllotmentIdRef)
        return { re2020SousLotAllotment: null, re2020LotAllotment: null };

      const re2020SousLotAllotment = re2020Allotments.find(
        (allotment) => allotment.id === re2020SousLotAllotmentIdRef
      );

      const re2020LotAllotment = re2020Allotments.find(
        (allotment) => allotment.id === re2020SousLotAllotment?.ParentId
      );

      return {
        re2020SousLotAllotment: re2020SousLotAllotment,
        re2020LotAllotment: re2020LotAllotment,
      };
    };

    if (
      ![TEMPLATE_TYPE_ID.RE2020, TEMPLATE_TYPE_ID.EPCM].includes(
        currentProject.ProjectTypeId
      )
    ) {
      const { re2020SousLotAllotment, re2020LotAllotment } =
        getRe2020SousLotAndLot(sheet);

      sheet.lotre2020 = re2020LotAllotment?.name || '';
      sheet.re2020Lot = re2020SousLotAllotment?.name || '';
    }

    const templateType =
      currentProject.templateType === TEMPLATE_TYPE_NAME.EPCM ? 'E+C-' : 'RE2020';

    return {
      [t('APP.EXPORTRSEE.EXPORTRSEE.PH_D00AF7', {
        defaultValue: 'Nom',
      })]: sheet.name,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_43B686', {
        defaultValue: 'Ligne source',
      })]: sheet.ProjectSheet?.originalRow,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_510033', {
        defaultValue: 'Libellé source',
      })]: sheet.ProjectSheet?.originalName || sheet.ProjectSheet?.customName,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_9B22DC', {
        defaultValue: 'Quantité',
      })]: sheet.quantity,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_80BEFC', { defaultValue: 'Unité' })]:
        sheet.MasterDataEquipment?.unit,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_0C4FBD', {
        defaultValue: 'Typefiche',
      })]: sheet.type_fiche !== 'users' ? sheet.type_fiche : 'Nooco',
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_1D2A95', {
        defaultValue: 'ID INIES',
      })]: sheet.id_inies,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_F87AAD', {
        templateType,
        defaultValue: 'Lot {templateType}',
      })]: sheet.lotre2020,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_54BAA7', {
        templateType,
        defaultValue: 'Sous-Lot {templateType}',
      })]: sheet.re2020Lot,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_624EEF', { defaultValue: 'Lot' })]:
        sheet.lot,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_956167', {
        defaultValue: 'Fonction',
      })]: sheet.fonction,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_6AFCC9', {
        defaultValue: 'Catégorie',
      })]: sheet.categorie,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_DE593F', {
        defaultValue: 'Materiel',
      })]: sheet.materiel,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_FD7AD1', {
        defaultValue: 'Libellé Nooco',
      })]: sheet.type,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_7EF085', {
        defaultValue: 'DuréeDeVie',
      })]: sheet.ddv,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_2146F2', {
        defaultValue: 'Destination',
      })]: sheet.destination,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_50162E', {
        defaultValue: 'Source',
      })]: sheet.source,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_2ED15A', {
        defaultValue: 'Commentaire',
      })]: sheet.comment,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_3237B7', {
        defaultValue: 'Coefficient de renouvellement',
      })]: sheet.coeff,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_CC50CC', {
        templateType,
        defaultValue: 'Correspondance fiche {templateType}',
      })]: sheet.Re2020Sheet ? sheet.Re2020Sheet.name : null,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_156F22', {
        value: currentIndicator.unit,
        defaultValue: 'Module D ({value}/m²)',
      })]: sheet.moduleD,
      //
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_548678', {
        defaultValue: 'StockC (kg C/m²)',
      })]: sheet.stockC,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_B373A1', {
        indicatorName: currentIndicator.shortName,
        indicatorUnit: currentIndicator.unit,
        defaultValue: '{indicatorName} PAR M² ({indicatorUnit}/m²)',
      })]: sheet.impactByArea,
      //
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_36FBBF', {
        indicatorName: currentIndicator.shortName,
        indicatorUnit: currentIndicator.unit,
        defaultValue: '{indicatorName} PAR UNITE ({indicatorUnit}/m²)',
      })]: sheet.impactByUnit,
      //
      [`${currentIndicator.shortName} (${currentIndicator.unit})`]: sheet.totalArea,
      //
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_083FBC', {
        templateType,
        indicatorName: currentIndicator.shortName,
        indicatorUnit: currentIndicator.unit,
        defaultValue: '{indicatorName} {templateType} PAR M² ({indicatorUnit}/m²)',
      })]: sheet.impactRe2020,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_45D521', {
        templateType,
        indicatorName: currentIndicator.shortName,
        indicatorUnit: currentIndicator.unit,
        defaultValue:
          '{indicatorName} {templateType} PAR UNITE ({indicatorUnit}/m²)',
      })]: sheet.impactByUnitRe2020,
      [`${currentIndicator.shortName} ${templateType} (${currentIndicator.unit})`]:
        sheet.globalImpactRe2020,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_8C06E3', {
        indicatorName: currentIndicator.shortName,
        columnUnitString,
        defaultValue: '{indicatorName} Production (A1-A3) {columnUnitString}',
      })]: sheet.production,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_A1AE46', {
        indicatorName: currentIndicator.shortName,
        columnUnitString,
        defaultValue: '{indicatorName} Edification (A4-A5) {columnUnitString}',
      })]: sheet.edification,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_BDF179', {
        indicatorName: currentIndicator.shortName,
        columnUnitString,
        defaultValue:
          '{indicatorName} Entretien et maintenance (B1-B5) {columnUnitString}',
      })]: sheet.entretien,
      [t('APP.LISTEQUIPMENTS.EXPORTXLSX.PH_8C1B30', {
        indicatorName: currentIndicator.shortName,
        columnUnitString,
        defaultValue: '{indicatorName} Fin de vie (C1-C4) {columnUnitString}',
      })]: sheet.fin,
    };
  };

  const flatDataTable = getFlatContributorListTableData(dataTable);
  let formatDatas = [];

  flatDataTable.forEach((sheet) => {
    const newObject = getDataToExport(sheet);

    formatDatas = [...formatDatas, newObject];
  });

  const ws = XLSX.utils.json_to_sheet(formatDatas);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, currentProject.name + fileExtension);
};
