import React from 'react';
import './conditionnalTruncatedTextWithTooltip.scss';
import { Tooltip } from 'antd';
interface Props {
  text: string;
  truncateNumber?: number;
}

const ConditionalTruncatedTextWithTooltip: React.FC<Props> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <Tooltip mouseEnterDelay={0.2} title={text} arrow>
      <span className="truncate-text">{text}</span>
    </Tooltip>
  );
};

export default ConditionalTruncatedTextWithTooltip;
