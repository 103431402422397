import './fluidsForm.scss';
import { Autocomplete } from '@material-ui/lab';
import { Dropdown } from '@photocarbone/nooco-ui';
import { StateDTO } from '../../models/GeneralDTO';
import { TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import NoocoCheckbox from '../NoocoCheckbox';
import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { EquipmentFromListEquipment } from '../../projects/EquipmentList/ListEquipments/ListEquipmentsFunction';

export enum HandleFluidsChangeReason {
  IsDefaultFluid = 'isDefaultFluid',
  SelectedFluid = 'selectedFluid',
  FluidQuantity = 'fluidQuantity',
}

export type NewValueType = boolean | number | string;

interface FluidsFormProps {
  onChange: (newValue: NewValueType, reason: HandleFluidsChangeReason) => void;
  simulator?: boolean;
  isAddQuickBar?: boolean;
  fluid?: {
    selectedFluid: number;
    fluidQuantity: string;
  };
  equipmentFromContributorList: EquipmentFromListEquipment;
  disabledInReadMode: boolean;
}

const FluidsForm = ({
  onChange,
  simulator,
  fluid,
  isAddQuickBar,
  equipmentFromContributorList,
  disabledInReadMode = false,
}: FluidsFormProps) => {
  const fluidData = equipmentFromContributorList?.childEquip;
  const { fluids } = useSelector((state: StateDTO) => state.projects);
  const [displayFluidChoice, setDisplayFluidChoice] = useState(Boolean(fluidData));
  const { t } = useTranslation();

  const debouncedOnChange = debounce(onChange, 300);

  return (
    <Tooltip
      title={
        simulator
          ? t('APP.FLUIDSFORM.INDEX.PH_91A2D8', {
              defaultValue: 'Le fluide considéré par défaut est du R410A',
            })
          : ''
      }
    >
      <div className="fluids-form-wrapper">
        {(isAddQuickBar || simulator) && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <h2
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                marginRight: '10px',
                marginBottom: '0',
              }}
            >{` ${t('APP.FLUIDSFORM.INDEX.PH_03BD0C', {
              defaultValue: 'Modifier le type du fluide (facultatif)',
            })}`}</h2>
            <NoocoCheckbox
              onChange={(e) => {
                if (simulator) {
                  debouncedOnChange(
                    !e.target.checked,
                    HandleFluidsChangeReason.IsDefaultFluid
                  );
                }
                if (!simulator) {
                  debouncedOnChange(
                    e.target.checked,
                    HandleFluidsChangeReason.IsDefaultFluid
                  );
                }

                setDisplayFluidChoice(e.target.checked);
              }}
              defaultChecked={Boolean(fluidData)}
              disabled={disabledInReadMode}
            />
          </div>
        )}
        {(((isAddQuickBar || simulator) && displayFluidChoice) ||
          (!isAddQuickBar && !simulator)) && (
          <div className="fluids-form-input">
            <div className="fluid-input">
              <div className="fluid-input-title" style={{}}>
                {t('APP.FLUIDSFORM.INDEX.PH_256A52', {
                  defaultValue: 'Choisir le type de fluide',
                })}{' '}
                <Tooltip
                  title={
                    <div>
                      <span>
                        {t('APP.FLUIDSFORM.INDEX.PH_A7A0A7', {
                          defaultValue:
                            "Pour le fluide frigorigène, l’impact est calculé en considérant des taux de fuite par défaut aux différentes étapes du cycle de vie de l'équipement :",
                        })}
                      </span>
                      <ul>
                        <li>
                          {t('APP.FLUIDSFORM.INDEX.PH_001CA8', {
                            defaultValue:
                              '2% lors du remplissage en étape de fabrication',
                          })}
                        </li>
                        <li>
                          {t('APP.FLUIDSFORM.INDEX.PH_CCC936', {
                            defaultValue: "2% par an pendant l'utilisation",
                          })}
                        </li>
                        <li>
                          {t('APP.FLUIDSFORM.INDEX.PH_BD2716', {
                            defaultValue: '10% en fin de vie de l’équipement',
                          })}
                        </li>
                      </ul>
                    </div>
                  }
                >
                  <InfoIcon />
                </Tooltip>
              </div>
            </div>

            <div style={{ marginBottom: '0.3em' }}>
              <Dropdown
                // style={{ width: '100%' }}
                disabled={disabledInReadMode}
                onSelect={(fluidId) => {
                  debouncedOnChange(fluidId, HandleFluidsChangeReason.SelectedFluid);
                }}
                // false because simulator hs if true and noocoUi and simulator to change.
                allowClear={false}
                MenuProps={{
                  disableScrollLock: true,
                }}
                treeCheckable={false}
                options={fluids.map((fl) => ({
                  title: fl.libelle,
                  label: fl.libelle,
                  value: fl.id,
                }))}
                defaultValue={fluidData ? fluidData.id : undefined}
                placeholder={t('APP.FLUIDSFORM.INDEX.PH_B67BE6', {
                  defaultValue: 'Choix du fluide',
                })}
              />
            </div>
            <div className="fluid-input">
              <p>
                {t('APP.FLUIDSFORM.INDEX.PH_ADA534', {
                  defaultValue: 'Charge de fluide (kg)',
                })}
              </p>
              <TextField
                disabled={disabledInReadMode}
                style={{ width: '100%' }}
                type="number"
                defaultValue={fluid ? fluid.fluidQuantity : null}
                placeholder=" -"
                variant="outlined"
                defaultValue={
                  fluidData ? fluidData.ProjectSheet.quantity : undefined
                }
                onChange={(event) =>
                  debouncedOnChange(
                    event.target.value,
                    HandleFluidsChangeReason.FluidQuantity
                  )
                }
                className={`input-boarding researchBar`}
              />
            </div>
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default FluidsForm;
