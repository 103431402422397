import {
  ADD_PROJECT_TO_VARIANT,
  ADD_VARIANT_SELECTION,
  CHANGE_VALUE_VARIANT,
  LOAD_FULL_PROJECT,
  LOAD_VARIANTS,
  REMOVE_ADDED_PROJECT,
} from '../actions/variants';
import { LOAD_PROJECT_BY_ID } from '../actions/projects';
import { parseProject } from '../functions/parseProject';
import { returnAllMdsInProject } from '../app/shared/utils';
import { useParams } from 'react-router-dom';

const initialState = {
  typeGraph: null,
  selectedVariants: [],
  variants: [],
  addedVariant: [],
  searchVariant: false,
  projectToVariant: null,
  variantsInDetails: {},
  currentFilter: 0,
  dataGraph: [],
  keysGraph: [],
  realData: [],
  variantsLoaded: false,
  filterOptions: [],
  filteredGraph: null,
  limitDatas: null,
};

function importReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_VALUE_VARIANT:
      return {
        ...state,
        [action.name]: action.value,
      };
    case LOAD_PROJECT_BY_ID: {
      return {
        ...state,
        variantsInDetails: {},
      };
    }
    case LOAD_VARIANTS: {
      action.data[0].isSelected = true;

      let defaultSelected = [action.data[0]];
      let variants = [action.data[0]];
      let variantDefault;

      action.data.forEach((variant, index) => {
        if (index === 1 && variant.project) {
          const parsedProject = parseProject(
            variant.project,
            action.allGroups.type,
            action.allGroups.sousLot,
            action.currentIndicator
          );

          let mdsInProject = returnAllMdsInProject(
            parsedProject.parsed,
            action.allGroups.type
          );

          const parsedSheets = parsedProject.parsed;

          variant.project.Sheets = parsedSheets;
          variant.isSelected = true;

          defaultSelected =
            index === 1
              ? [
                  ...defaultSelected,
                  {
                    name: variant.project.name,
                    id: variant.project.id,
                    project: variant.project,
                    mds: mdsInProject,
                    zones: parsedProject.zones,
                  },
                ]
              : [...defaultSelected];

          variants = [...variants, variant.project];

          variantDefault = variant;
        } else if (index > 1) {
          variants = [...variants, variant];
        }
      });

      let variantsDetails = {
        [action.data[0].id]: {
          id: action.data[0].id,
          name: action.data[0].project.name,
          zones: action.data[0].zones,
          mds: action.data[0].mds,
          project: action.data[0].project,
        },
      };

      if (variantDefault?.project.id) {
        variantsDetails = {
          ...variantsDetails,
          [variantDefault?.project.id]: {
            id: variantDefault?.project.id,
            project: variantDefault?.project,
            zones: variantDefault?.zones,
            mds: variantDefault?.mds,
            name: variantDefault?.project.name,
          },
        };
      }

      return {
        ...state,
        variants: variants,
        selectedVariants: defaultSelected,
        selectedLotFilter: [
          {
            id: 1200,
            label:
              action.currentAllotment.id === 2 ? 'Tous contributeurs' : 'Tous lots',
            color: '#ccc',
            name: 'all',
          },
        ],
        variantsInDetails: {
          ...state.variantsInDetails,
          ...variantsDetails,
        },
        // [action.name]: action.value,
      };
    }
    case ADD_PROJECT_TO_VARIANT: {
      if (state.projectToVariant) {
        const list = [...state.variants, { ...state.projectToVariant }];
        const added = [...state.addedVariant, { ...state.projectToVariant }];

        return {
          ...state,
          variants: list,
          addedVariant: added,
          // selectedVariants: selected,
          searchVariant: false,
          projectToVariant: null,
        };
      }

      return {
        ...state,
      };
    }
    case ADD_VARIANT_SELECTION: {
      if (state.variants.find((el) => el.id === action.variant.id)) {
        let selectedVariant = [];

        if (
          state.selectedVariants.find(
            (el) => el && el.project.id === action.variant.id
          )
        ) {
          selectedVariant = state.selectedVariants.filter(
            (el) => el.id !== action.variant.id
          );
        } else {
          selectedVariant = [
            ...state.selectedVariants,
            state.variantsInDetails[action.variant.id],
          ];
        }

        return {
          ...state,
          selectedVariants: selectedVariant,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case REMOVE_ADDED_PROJECT: {
      const list = state.addedVariant.filter((el) => el.id !== action.project.id);
      const variantList = state.variants.filter((el) => el.id !== action.project.id);

      const selecteds = state.selectedVariants.filter(
        (el) => el.id !== action.project.id
      );

      return {
        ...state,
        addedVariant: list,
        variants: variantList,
        selectedVariants: selecteds,
      };
    }
    case LOAD_FULL_PROJECT:
      {
        const queryParams = new URLSearchParams(window.location.search);

        const page = queryParams.get('page');

        console.log('LOADFULLPROJECT', action);

        if (
          state.variants.find((el) => el.id === action.currentProject.id) &&
          page == 4 &&
          action.project.project
        ) {
          let list = state.variants.filter(
            (el) => el.id !== action.project.project?.id
          );

          const parsedProject = parseProject(
            action.project.project,
            action.allGroups.type,
            action.allGroups.sousLot,
            action.currentIndicator
          );

          const mds = returnAllMdsInProject(
            parsedProject.parsed,
            action.allGroups.type
          );

          const parsedSheets = parsedProject.parsed;

          action.project.project.Sheets = parsedSheets;

          list = [...list, action.project.project];

          if (action.project)
            return {
              ...state,
              variantsInDetails: {
                ...state.variantsInDetails,
                [action.project.project.id]: {
                  id: action.project.project.id,
                  project: action.project.project,
                  mds: mds,
                  zones: parsedProject.zones,
                },
              },
              variants: list,
              // selectedVariants: action.isSelected ? selecteds : state.selectedVariants,
            };
        } else {
          return {
            ...state,
          };
        }
      }
      break;
    default:
      return state;
  }
}

export default importReducer;
