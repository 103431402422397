import '../../exploitation/ListActions/AddActions/Garbage/garbage.scss';
import '../../exploitation/ListActions/AddActions/Transport/transport.scss';
import './simplifiedEnergy.scss';
import { Button } from '@photocarbone/nooco-ui';
import { EquipmentsDTO } from '../../models/EquipmentsDTO';
import { StateDTO } from '../../models/GeneralDTO';
import { TextField } from '@mui/material';
import { changeValueEquipments } from '../../../actions/equipments';
import {
  createAction,
  updateExploitationValue,
} from '../../../actions/exploitation';
import { findParentNode, handleKeyPress } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import API from '../../services/API';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@mui/material/MenuItem';
import RangePickerCustom from '../../components/RangePicker';
import React from 'react';
import SelectUi from '@mui/material/Select';
import add from '../../../assets/images/exploitation/add.png';
import charbonPic from '../../../assets/images/simplifiedEnergy/charbon.png';
import elec from '../../../assets/images/equipments/energy.png';
import energySimplifiedPic from '../../../assets/images/simplifiedEnergy/energySimplified.png';
import fioulPic from '../../../assets/images/simplifiedEnergy/fioul.png';
import gazPic from '../../../assets/images/simplifiedEnergy/gaz.png';
import useCaseWorksite from '../../projects/EquipmentList/Calculator/CalculatorWorksite/CalculatorWorksiteContent/UseCaseWorksite';
import woodPic from '../../../assets/images/simplifiedEnergy/wood.png';
import { useTranslation } from 'react-i18next';

let api = new API();

export const checkSimplifiedEnergyForm = (lines, exploitation, newAction) => {
  if (
    lines.find(
      (el) =>
        !el.AllotmentNodeId ||
        !el.quantity ||
        (el.caracs &&
          el.caracs.caracs?.length > 0 &&
          el.caracs.caracs?.find((carac) => !carac.value))
    )
  ) {
    return true;
  }

  if (exploitation && !newAction.date) {
    return true;
  }

  return false;
};

interface SimplifiedEnergyProps {
  changeValue: Function;
  exploitation?: boolean;
  resetAddAction?: Function;
  calculator?: boolean;
  // isEquipmentEditable?: Function,
  // isEquipmentFromParentZone?: Function,
  // calculateAreaZoneProrata?: Function,
  // isDisplayEquipment?: Function,
}

const SimplifiedEnergy = ({
  changeValue,
  exploitation,
  calculator,
}: SimplifiedEnergyProps): JSX.Element => {
  const { allGroups } = useSelector((state: StateDTO) => state.projects);
  const { simplifiedEnergyLines: simplifiedEnergyLinesEquipment } = useSelector(
    (state: StateDTO) => state.equipments
  );
  const { simplifiedEnergyLines: simplifiedEnergyLinesExploitation } = useSelector(
    (state: StateDTO) => state.exploitation
  );
  const { newAction, loadingActions } = useSelector(
    (state: StateDTO) => state.exploitation
  );
  const { zoneSelected } = useSelector(
    (state: StateDTO) => state.projects.calculator
  );

  const simplifiedEnergyLines = exploitation
    ? simplifiedEnergyLinesExploitation
    : simplifiedEnergyLinesEquipment;
  // const { zones } = useSelector((state: StateDTO) => state.projects.parsedProject);
  const {
    isEquipmentEditable,
    isEquipmentFromParentZone,
    calculateAreaZoneProrata,
    isDisplayEquipment,
    zoneSourceToDisplay,
  } = useCaseWorksite();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  let allNodesEnergy = allGroups.flatAllotments
    .filter((node) => node.MasterDataEquipmentId && !node.isObsolete)
    .filter((node) => {
      let parentNode = findParentNode(node, allGroups.flatAllotments, [], [])[0];

      if (parentNode.name === 'Energie simplifiée') {
        return node;
      }
    });

  console.log('allNodesEnergy', allNodesEnergy);

  const callGetCaracs = (defaultAllotmentNodeId, caracs, line) => {
    let allot = allNodesEnergy.find((el) => el.id === defaultAllotmentNodeId);

    console.log('allot', allot);
    api
      .getEquipmentCaracListById(allot.id)
      .then((response) => {
        caracs = {
          ...response.data,
          caracs: response.data.caracs.map((el) => ({
            name: el.name,
            id: el.id,
          })),
        };

        if (line) {
          handleEditEnergy({
            ...line,
            AllotmentNode: allot,
            AllotmentNodeId: defaultAllotmentNodeId,
            caracs,
          });

          return;
        }

        if (
          defaultAllotmentNodeId &&
          simplifiedEnergyLines.find((el) => !el.AllotmentNodeId)
        ) {
          handleEditEnergy({
            ...simplifiedEnergyLines.find((el) => !el.AllotmentNodeId),
            AllotmentNode: allot,
            AllotmentNodeId: defaultAllotmentNodeId,
            caracs,
          });
        } else {
          const newLines = [
            ...simplifiedEnergyLines,
            {
              id: Math.max(...simplifiedEnergyLines.map((el) => el.id)) + 1,
              AllotmentNodeId: defaultAllotmentNodeId,
              AllotmentNode: allot,
              caracs,
              quantity: null,
              actionType: 'energy',
              origin: {
                type: zoneSelected?.type || 'project',
                sourceId: zoneSelected?.id || null,
                uniqueId: uuidv4(),
              },
            },
          ];

          dispatch(changeValue('simplifiedEnergyLines', newLines));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const addNewEnergy = (defaultAllotmentNodeId: number | null): void => {
    let caracs = [];

    if (defaultAllotmentNodeId) {
      // dispatch(getCaracsCombos());
      callGetCaracs(defaultAllotmentNodeId, caracs, null);
    } else {
      const newLines = [
        ...simplifiedEnergyLines,
        {
          id: Math.max(...simplifiedEnergyLines.map((el) => el.id)) + 1,
          MasterDataEquipmentId: null,
          AllotmentNode: null,
          caracs,
          quantity: null,
          unit: exploitation || calculator ? 'kWh' : 'kWh/an',
          actionType: 'energy',
          origin: {
            type: zoneSelected?.type || 'project',
            sourceId: zoneSelected?.id || null,
            uniqueId: uuidv4(),
          },
        },
      ];

      dispatch(changeValue('simplifiedEnergyLines', newLines));
    }
  };

  const handleEditEnergy = (newValue): void => {
    const newLines = simplifiedEnergyLines.map((energyLine) => {
      if (energyLine.id === newValue.id) {
        return newValue;
      }

      return energyLine;
    });

    dispatch(changeValue('simplifiedEnergyLines', newLines));
  };

  return (
    <>
      <div className={`transport-form ${calculator ? 'calculatorExtend' : ''}`}>
        {/* title */}
        {!calculator && (
          <div className="transport-header" style={{ marginBottom: '10px' }}>
            <img src={elec} alt="" />
            <h4>
              {t('APP.SIMPLIFIEDENERGY.INDEX.PH_8D9E3A', {
                defaultValue: 'Energie',
              })}
            </h4>
          </div>
        )}

        {exploitation && (
          <div style={{ width: '80%', margin: 'auto' }}>
            <div style={{ width: '30%' }}>
              <RangePickerCustom
                label={t('APP.SIMPLIFIEDENERGY.INDEX.PH_6BE651', {
                  defaultValue: 'Période concernée',
                })}
              />
            </div>
          </div>
        )}

        {/* card selection */}
        <div className="transport-infos-top">
          <div
            className="garbage-item"
            onClick={() =>
              addNewEnergy(
                allNodesEnergy.find((el) => el.name === 'Electricité')?.id
              )
            }
          >
            <img src={energySimplifiedPic} alt="" />
            <p>
              {t('APP.SIMPLIFIEDENERGY.INDEX.PH_9318C2', {
                defaultValue: 'Electricité',
              })}
            </p>
          </div>
          <div
            className="garbage-item"
            onClick={() =>
              addNewEnergy(allNodesEnergy.find((el) => el.name === 'Gaz')?.id)
            }
          >
            <img src={gazPic} alt="" />
            <p>
              {t('APP.SIMPLIFIEDENERGY.INDEX.PH_115205', {
                defaultValue: 'Gaz',
              })}
            </p>
          </div>
          <div
            className="garbage-item"
            onClick={() =>
              addNewEnergy(
                allNodesEnergy.find((el) => el.name === 'Fioul domestique')?.id
              )
            }
          >
            <img src={fioulPic} alt="" />
            <p>
              {t('APP.SIMPLIFIEDENERGY.INDEX.PH_57DAC5', {
                defaultValue: 'Fioul domestique',
              })}
            </p>
          </div>
          <div
            className="garbage-item"
            onClick={() =>
              addNewEnergy(allNodesEnergy.find((el) => el.name === 'Charbon')?.id)
            }
          >
            <img alt="" src={charbonPic} />
            <p>
              {t('APP.SIMPLIFIEDENERGY.INDEX.PH_28D303', {
                defaultValue: 'Charbon',
              })}
            </p>
          </div>
          <div
            className="garbage-item"
            onClick={() =>
              addNewEnergy(allNodesEnergy.find((el) => el.name === 'Bois')?.id)
            }
          >
            <img alt="" src={woodPic} />
            <p>
              {t('APP.SIMPLIFIEDENERGY.INDEX.PH_C7D975', {
                defaultValue: 'Bois',
              })}
            </p>
          </div>
        </div>
        {/* array of rows */}
        <div
          className="garbage-add"
          style={{ width: calculator ? '95%' : '80%', margin: 'auto' }}
        >
          {/* header */}
          <div className="garbage-lines-header">
            <div style={{ width: '4%' }}></div>
            <p style={{ width: '20%', paddingRight: '30px' }}>
              {t('APP.SIMPLIFIEDENERGY.INDEX.PH_856A76', {
                defaultValue: 'Energie',
              })}
            </p>
            <p style={{ width: '15%' }}>
              {calculator
                ? t('APP.SIMPLIFIEDENERGY.INDEX.PH_AC01EB', {
                    defaultValue: 'Consommation',
                  })
                : exploitation
                ? t('APP.SIMPLIFIEDENERGY.INDEX.PH_D70FAE', {
                    defaultValue: 'Consommation sur la période concernée',
                  })
                : t('APP.SIMPLIFIEDENERGY.INDEX.PH_6A1AD9', {
                    defaultValue: 'Consommation annuelle',
                  })}
            </p>
            <p style={{ width: '10%' }}>Unité</p>
            <p style={{ width: '15%' }}>Type</p>
            {calculator && (
              <p style={{ width: '20%' }}>
                {t('APP.SIMPLIFIEDENERGY.INDEX.PH_1FB143', {
                  defaultValue: 'Source',
                })}
              </p>
            )}
            <div style={{ width: '4%' }} />
          </div>
          {/* rows */}
          <div className="garbage-lines">
            {simplifiedEnergyLines.map((energyLine, i) => {
              const isEquipmentEditableResult = isEquipmentEditable(energyLine);
              const isDisplayEquipmentResult = isDisplayEquipment(energyLine);
              const isEquipmentFromParentZoneResult =
                isEquipmentFromParentZone(energyLine);
              let quantity = energyLine.quantity;

              if (energyLine?.quantity && isEquipmentFromParentZoneResult) {
                console.log('je passe ici?');

                const quantityProrata = calculateAreaZoneProrata(energyLine);

                quantity = Math.round(quantity * quantityProrata * 10000) / 10000;
              }
              console.log('quantity', quantity);

              const displayRow =
                !calculator ||
                (calculator &&
                  (isDisplayEquipmentResult || isEquipmentEditableResult));

              if (displayRow) {
                return (
                  <div
                    className={`garbage-line ${
                      calculator ? 'simplifiedEnergy' : ''
                    } ${isEquipmentEditableResult ? '' : 'disabledCalculator'}`}
                    key={i}
                  >
                    {/* add Energy Button */}
                    <div style={{ width: '4%' }}>
                      {i === simplifiedEnergyLines.length - 1 && (
                        <img
                          onClick={() => addNewEnergy(null)}
                          style={{
                            width: '25px',
                            height: '25px',
                            marginTop: '10px',
                            marginRight: '20px',
                            cursor: 'pointer',
                          }}
                          src={add}
                        />
                      )}
                    </div>
                    {/* select typeOf Energy */}
                    <div style={{ width: '20%' }}>
                      <Autocomplete
                        value={energyLine.AllotmentNode}
                        key={energyLine.AllotmentNodeId}
                        id="checkboxes-tags-demo"
                        options={allNodesEnergy || []}
                        noOptionsText={'Aucun résultat trouvé'}
                        disabled={calculator && !isEquipmentEditableResult}
                        onChange={(event, newValue) => {
                          let caracs = [];

                          if (newValue) {
                            callGetCaracs(newValue.id, [], energyLine);
                          } else {
                            handleEditEnergy({
                              ...energyLine,
                              AllotmentNodeId: null,
                              caracs,
                            });
                          }
                        }}
                        size={'small'}
                        getOptionLabel={(option) => option.name || ''}
                        renderInput={(params) => (
                          <TextField
                            style={{ borderRadius: '10px', padding: 0 }}
                            {...params}
                            placeholder={t('APP.SIMPLIFIEDENERGY.INDEX.PH_7E5420', {
                              defaultValue: "Ajouter de l'énergie",
                            })}
                            variant="outlined"
                            className="researchBar input-boarding input-simplified"
                          />
                        )}
                      />
                    </div>
                    {/* input consumption quantity */}
                    <div style={{ width: '15%' }}>
                      {calculator && !isEquipmentEditableResult ? (
                        <TextField
                          style={{ width: '100%' }}
                          value={quantity}
                          placeholder="  -"
                          variant="outlined"
                          className="input-boarding researchBar"
                          disabled={true}
                        />
                      ) : (
                        <TextField
                          onKeyPress={handleKeyPress}
                          onChange={(e) =>
                            handleEditEnergy({
                              ...energyLine,
                              quantity: Number(e.target.value),
                            })
                          }
                          style={{ width: '100%' }}
                          defaultValue={
                            calculator && energyLine.quantity
                              ? energyLine.quantity
                              : null
                          }
                          placeholder="  -"
                          variant="outlined"
                          className="input-boarding researchBar"
                        />
                      )}
                    </div>
                    {/* unit */}
                    <div
                      style={{
                        width: '10%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <p style={{ marginBottom: 0 }}>
                        {exploitation || calculator ? 'kWh' : 'kWh/an'}
                      </p>
                    </div>
                    {/* type carac select */}
                    {energyLine.caracs &&
                      energyLine.caracs.caracs &&
                      energyLine.caracs.caracs
                        .sort((a, b) => b.name.localeCompare(a.name))
                        .map((carac, i) => {
                          let caracListOptions =
                            energyLine.caracs.caracCombos.valueList;

                          return (
                            <div style={{ width: '15%' }} key={carac.name}>
                              <SelectUi
                                MenuProps={{
                                  disableScrollLock: true,
                                }}
                                defaultValue={carac.value && carac.value[0]}
                                onChange={(e) =>
                                  handleEditEnergy({
                                    ...energyLine,
                                    caracs: {
                                      ...energyLine.caracs,
                                      caracs: [
                                        ...energyLine.caracs.caracs.filter(
                                          (car) => car.name !== carac.name
                                        ),
                                        { ...carac, value: [e.target.value] },
                                      ],
                                    },
                                  })
                                }
                                disabled={
                                  caracListOptions.length === 0 ||
                                  (calculator && !isEquipmentEditableResult)
                                }
                                style={{ width: '100%', height: '40px' }}
                                placeholder="  -"
                                variant="outlined"
                                className="input-boarding researchBar"
                              >
                                {caracListOptions?.map((value, i) => (
                                  <MenuItem
                                    value={value.value}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                    key={i}
                                  >
                                    {value.value}
                                  </MenuItem>
                                ))}
                              </SelectUi>
                            </div>
                          );
                        })}
                    {/* no carac display */}
                    {(!energyLine.caracs?.caracs ||
                      energyLine.caracs?.caracs.length === 0) && (
                      <>
                        <div style={{ width: '15%' }}></div>
                      </>
                    )}

                    {/* source optionnal for multizone */}
                    {calculator && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '20%',
                        }}
                        className="sourceCell disabled"
                      >
                        {/* {energyLine?.origin?.type === 'project' ? 'Projet' : zones.find((zone) => zone.id === energyLine?.origin?.sourceId)?.name} */}
                        {zoneSourceToDisplay(energyLine.origin)}
                      </div>
                    )}

                    {/* delete row icon */}
                    <div
                      style={{
                        width: '4%',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <DeleteIcon
                        onClick={() => {
                          dispatch(
                            changeValue(
                              'simplifiedEnergyLines',
                              simplifiedEnergyLines.filter(
                                (ele) => ele.id !== energyLine.id
                              )
                            )
                          );
                        }}
                        style={{
                          display:
                            simplifiedEnergyLines.length > 1 ? 'block' : 'none',
                          cursor: 'pointer',
                          visibility:
                            calculator && !isEquipmentEditableResult
                              ? 'hidden'
                              : 'visible',
                        }}
                      />
                    </div>
                  </div>
                );
              }

              return null;
            })}
          </div>
        </div>
      </div>
      {exploitation && (
        <div style={{ display: 'flex', marginLeft: 'auto', width: '15%' }}>
          <Button
            style={{
              fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
              marginRight: '10px',
            }}
            text="Fermer"
            onClick={() => dispatch(updateExploitationValue('addAction', null))}
            className="close-button"
          />
          <Button
            style={{
              fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
            }}
            onClick={() => {
              dispatch(createAction(t, false, false, false, true));
            }}
            loading={loadingActions}
            disabled={checkSimplifiedEnergyForm(
              simplifiedEnergyLines,
              true,
              newAction
            )}
            text={t('APP.SIMPLIFIEDENERGY.INDEX.PH_4B7987', {
              defaultValue: 'Ajouter',
            })}
            className="purple-button"
          />
        </div>
      )}
    </>
  );
};

export default SimplifiedEnergy;
