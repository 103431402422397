import React, { useCallback, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '../../../models/DragConstants';
import { useDispatch, useSelector } from 'react-redux';
import { updateCheckValue } from '../../../../actions/report';
import { StateDTO } from '../../../models/GeneralDTO';
import { ConstantKeysWidgets, Widget } from '../WidgetsList';
import WidgetItem from '../WidgetsList/WidgetItem';
import { returnHighestIdOfArrayPlusOne } from '../../../shared/utils';
import { useTranslation } from 'react-i18next';
import { klona } from 'klona';
import ReplayIcon from '@material-ui/icons/Replay';
import { ModalNooco, TextAreaNooco } from '@photocarbone/nooco-ui';

export const callBackAfterWidgetIsDropped = (
  dragIndex,
  hoverIndex,
  updatedCards,
  widget
) => {
  let cardToMove = updatedCards[dragIndex];

  updatedCards.splice(dragIndex, 1);
  updatedCards.splice(hoverIndex, 0, cardToMove);
  return updatedCards;
};

function WidgetsDropZone() {
  const [openTextAreaModal, setOpenTextAreaModal] = React.useState<boolean | Widget>(
    false
  );
  const dragHover = useRef();
  const { widgetsInDropZone } = useSelector((state: StateDTO) => state.report);
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const moveCard = useCallback((dragIndex, hoverIndex, widgetsDropped) => {
    let updatedCards = klona(widgetsDropped);

    if (!widgetsDropped[dragIndex]) return;
    dispatch(
      updateCheckValue(
        'widgetsInDropZone',
        callBackAfterWidgetIsDropped(dragIndex, hoverIndex, updatedCards)
      )
    );
  }, []);

  const handleDropZone = (widget: Widget, widgetsAlreadyDropped: Widget[]) => {
    let updatedCards = klona(widgetsAlreadyDropped);
    if (!widget) return;

    if (!widget.id && widget.key === ConstantKeysWidgets.TEXTAREA) {
      setOpenTextAreaModal(widget);

      return;
    }
    if (dragHover?.current !== undefined && !widget.id) {
      let indexToPut = dragHover?.current === 0 ? 0 : dragHover?.current + 1;
      updatedCards.splice(indexToPut, 0, {
        id: returnHighestIdOfArrayPlusOne(updatedCards),
        ...widget,
      });
      dispatch(updateCheckValue('widgetsInDropZone', updatedCards));
      return;
    } else if (
      widget &&
      !widgetsInDropZone.find(
        (widgetInDropZone) => widgetInDropZone.id === widget.id
      )
    ) {
      dispatch(
        updateCheckValue('widgetsInDropZone', [
          ...widgetsInDropZone,
          { id: returnHighestIdOfArrayPlusOne(widgetsInDropZone), ...widget },
        ])
      );
    }
  };
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.Widgets,
    drop: (widget: Widget) => {
      handleDropZone(widget, widgetsInDropZone);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const { t } = useTranslation();
  const isActive = isOver && canDrop;

  let backgroundColor = 'transparent';

  if (isActive) {
    backgroundColor = '#d3d3d3';
  } else if (canDrop) {
    backgroundColor = '#e5e5e5';
  }

  const handleResetWidgetsInDropzone = () => {
    dispatch(updateCheckValue('widgetsInDropZone', []));
  };

  const handlePutTextAreaInDropzone = (openTextAreaModal) => {
    let valuesFromTextArea = inputRef?.current?.resizableTextArea.textArea.value;
    dispatch(
      updateCheckValue('widgetsInDropZone', [
        ...widgetsInDropZone,
        {
          id: returnHighestIdOfArrayPlusOne(widgetsInDropZone),
          ...openTextAreaModal,
          text: valuesFromTextArea,
        },
      ])
    );
    setOpenTextAreaModal(false);
    inputRef.current.resizableTextArea.textArea.value = '';
  };
  return (
    <>
      <ModalNooco
        title={t('APP.PDF_BUIDLER.WIDGETS_LIST.TEXTAREA.TITLE', {
          defaultValue: 'Texte libre',
        })}
        isOpen={openTextAreaModal}
        cancelFunction={() => setOpenTextAreaModal(false)}
        validateFunction={() => handlePutTextAreaInDropzone(openTextAreaModal)}
        closeOnOutsideClick={true}
        closeIcon={true}
      >
        <TextAreaNooco
          key={openTextAreaModal.id || Math.random()}
          defaultValue={openTextAreaModal?.text || ''}
          inputRef={inputRef}
        />
      </ModalNooco>
      <div
        ref={drop}
        className="border-section padding-section overflow-scroll"
        data-testid="widgets-dropzone"
        style={{ background: backgroundColor, transition: '0.5ms' }}
      >
        <div className="header-section">
          <div className="title-part">
            <p className="title-section">
              {t('APP.PDF_BUILDER.SECTIONS', { defaultValue: 'Sections' })}
            </p>
            <ReplayIcon
              style={{ cursor: 'pointer' }}
              onClick={() => handleResetWidgetsInDropzone()}
            />
          </div>{' '}
          <p className="subtitle-section">
            {t('APP.PDF_BUILDER.ORGANISE_SECTION', {
              defaultValue:
                'Organiser et disposer les vignettes et sections du pdf.',
            })}
          </p>
        </div>
        <div className="dropzone-wrapper">
          {widgetsInDropZone.length === 0 ? (
            <div
              style={{
                height: '400px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p className="empty-dropzone">
                {t('APP.PDF_BUILDER.DRAG_DROP_WIDGETS', {
                  defaultValue: 'Glissez et déposez les vignettes ici',
                })}
              </p>
            </div>
          ) : (
            widgetsInDropZone.map((widget, index) => (
              <WidgetItem
                widget={widget}
                index={index}
                key={widget?.id}
                moveCard={moveCard}
                dragHover={dragHover}
                isInDropzone={true}
                setOpenTextAreaModal={setOpenTextAreaModal}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
}

export default WidgetsDropZone;
