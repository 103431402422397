import { BundleReducerDTO } from '../app/models/BundleDTO';
import { GET_CARACS, UPDATE_BUNDLE_VALUE } from '../actions/bundle';

const initialState: BundleReducerDTO = {
  currentBundleTab: 0,
  folderSheetId: null,
  newBundle: null,
  editBundle: [],
  offset: 0,
  bundleSearchInput: '',
  isBundleScreen: false,
};

function bundleReducer(state: BundleReducerDTO = initialState, action) {
  switch (action.type) {
    case UPDATE_BUNDLE_VALUE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case GET_CARACS:
      return {
        ...state,
        bundleLines: action.newBundleLines,
      };
    default:
      return state;
  }
}

export default bundleReducer;
