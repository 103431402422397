import {
  INITIALIZE_ACCESS_LOADING,
  PATCH_MONITORING_BREADCRUMMB_MULTIZONE,
  SET_ACCESS_LOADING,
  SET_COMPANY_EMPLOYEES,
  SET_CURRENT_COMPANY,
  SET_DELETE_COMPANY_EMPLOYEE_MODAL,
  SET_MONITORING_ACTIVE_TAB,
  SET_NEW_USER_MODAL,
  UPDATE_MONITORING,
} from '../actions/monitoring';
import { FETCH_PROJECT_BY_ID } from '../actions/projects';

const initialState = {
  currentCompany: null,
  companyEmployees: [],
  monitoringActiveTab: 'users',
  isNewUserModalOpen: false,
  isDeleteCompanyEmployeeModalOpen: false,
  accessLoadings: [],
  alertMessage: '',
  alertSeverity: 'info',
  breadcrumbMultizone: {
    zoneSelected: null,
  },
};

const monitoringReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: action.company,
      };

    case SET_COMPANY_EMPLOYEES:
      return {
        ...state,
        companyEmployees: action.employees,
      };

    case PATCH_MONITORING_BREADCRUMMB_MULTIZONE:
      return {
        ...state,
        breadcrumbMultizone: {
          ...state.breadcrumbMultizone,
          [action.name]: action.value,
        },
      };

    case UPDATE_MONITORING:
      return {
        ...state,
        [action.name]: action.value,
      };

    case SET_MONITORING_ACTIVE_TAB:
      return {
        ...state,
        monitoringActiveTab: action.tabName,
      };

    case SET_NEW_USER_MODAL:
      return {
        ...state,
        isNewUserModalOpen: !state.isNewUserModalOpen,
      };

    case INITIALIZE_ACCESS_LOADING: {
      const initialAccessLoading = [];

      state.companyEmployees.forEach((employee) => {
        initialAccessLoading[employee.id] = false;
      });

      return {
        ...state,
        accessLoadings: initialAccessLoading,
      };
    }

    case SET_ACCESS_LOADING: {
      const loadings = [...state.accessLoadings];

      loadings[action.accessId] = !loadings[action.accessId];

      return {
        ...state,
        accessLoadings: loadings,
      };
    }

    case SET_DELETE_COMPANY_EMPLOYEE_MODAL:
      return {
        ...state,
        isDeleteCompanyEmployeeModalOpen: !state.isDeleteCompanyEmployeeModalOpen,
      };

    case FETCH_PROJECT_BY_ID: {
      return {
        ...state,
        breadcrumbMultizone: {
          ...state.breadcrumbMultizone,
          zoneSelected: null,
        },
      };
    }

    default:
      return state;
  }
};

export default monitoringReducer;
