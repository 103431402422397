import { connect } from 'react-redux';

import { changeValueEquipments } from '../../actions/equipments';
import { updateFormValue } from '../../actions/projects';
import InputSousLotRe2020 from '../../app/shared/InputSousLotRe2020';

const mapStateToProps = (state) => ({
  //   sousLotOptions: state.projects.sousLotOptions,
  inputSousLot: state.projects.inputSousLot,
  currentProject: state.projects.currentProject,
  searchValue: state.projects.searchValue,
  allGroups: state.projects.allGroups,
  composantVideForm: state.equipments.composantVideForm,
  lineImport: state.imports.lineImport,
});

const mapDispatchToProps = (dispatch) => ({
  changeValueEquipments: (name, value) => {
    dispatch(changeValueEquipments(name, value));
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InputSousLotRe2020);
