import {
  AllotmentNodeDTO,
  CompanyDTO,
  ProjectDTO,
  StructuredCompanyDTO,
} from '../models/ProjectDTO';
import { CurrentAllotmentDTO, MasterDataEquipment } from '../models/ProjectsDTO';
import dayjs from 'dayjs';
import { DEC_LOTS_IDS } from '../globalVariable/generalDefinitions';
import {
  REGULATORY_PROJECTS,
  TEMPLATE_TYPE_ID,
  TEMPLATE_TYPE_NAME,
} from '../globalVariable/typeProject';
import { findParentNode } from './utils';

import formatNumber from '../services/mathematics/formatNumber';
import {
  AllotmentNode,
  Lifespan,
  ProjectSheet,
} from '@photocarbone/nooco-shared-api-def';
import { SEARCH_GROUP_OPTION_VALUES } from '../globalVariable/searchGroupType';
import { SelectedSearchGroupOptionsLabel } from './QuickAddBarEquipment/QuickAddBarEquipment';

export type sheet = {
  quantity: number;
  Sheet: {
    id: number;
    name: string;
    ddv: number;
    optiDelta: null | number;
  };
};
export type currentIndicator = {
  id: number;
  unit: string;
};
export type sheets = sheet[];
export type projectArea = number | null;
export type projectDdv = number;
export type projectRefUnit = 'm²' | 'Total' | 'total';

export function getSheetType(
  allotmentNode: AllotmentNodeDTO,
  allAllotmentNodes: AllotmentNodeDTO[],
  isReEmploy: boolean = false
) {
  if (allotmentNode) {
    const parentAllotmentNode = findParentNode(
      allotmentNode,
      allAllotmentNodes,
      [],
      []
    );
    const lotId = parentAllotmentNode[0].id;

    if (DEC_LOTS_IDS.includes(lotId)) {
      return 'DEC';
    }
  }

  if (isReEmploy) {
    return 'REFURBISHED';
  }

  return 'users';
}

export const resultBestOptimization = (
  sheets: sheets,
  projectDdv: projectDdv,
  projectRefUnit: projectRefUnit,
  projectArea: projectArea,
  formatedResultWanted: boolean,
  currentIndicator: currentIndicator | null
) => {
  let optiSheetsTotal = 0;

  sheets.map((sheet) => {
    if (sheet.Sheet.optiDelta) {
      optiSheetsTotal += sheet.Sheet.optiDelta * sheet.quantity;
    }
  });

  const bestOptimization =
    projectRefUnit === 'm²' && projectArea
      ? (optiSheetsTotal * projectDdv) / 50 / projectArea
      : (optiSheetsTotal * projectDdv) / 50;

  if (formatedResultWanted && currentIndicator) {
    return formatResult(
      projectRefUnit,
      bestOptimization,
      projectArea,
      currentIndicator
    );
  } else {
    return bestOptimization;
  }
};

export const formatResult = (
  projectRefUnit: projectRefUnit,
  value: number,
  projectArea: projectArea,
  currentIndicator: currentIndicator
) => {
  return `${formatNumber(value)} ${currentIndicator.unit}`;
};

export const stringToNumber = (value: string | number | null | undefined) => {
  if (!value) {
    return null;
  }

  if (typeof value === 'string') {
    return Number(commaToDot(value));
  }

  return value;
};

/**
 * Convert comma to Dot. Return same type of the entry value.
 */
export const commaToDot = (
  value: string | number | null | undefined
): string | null | number | undefined => {
  if (!value) {
    return value;
  }

  if (!isNaN(Number(value))) {
    return value;
  }

  return value.toString().replace(/,/, '.');
};

/**
 * Verify if the value is a number and accept null and emptyString
 */
export const isNumOrNull = (value: string | number | null | undefined): boolean => {
  if (!value || value === '') {
    return true;
  }

  const isNum = !isNaN(Number(value.toString().replace(/,/, '.')));

  return isNum;
};

/**
 * Verify if the value is a number
 */
export const isNum = (value: string | number | null | undefined): boolean => {
  if (!value && value !== 0) {
    return false;
  }

  const isNum = !isNaN(Number(value.toString().replace(/,/, '.')));

  return isNum;
};

/**
 * Verify if the value is a number and higher than 0
 */
export const isNumHigherZero = (
  value: string | number | null | undefined
): boolean => {
  if (!value) {
    return false;
  }

  const stringCleaned = value.toString().replace(/,/, '.');

  const isNum = !isNaN(Number(stringCleaned));

  if (isNum) {
    return Number(stringCleaned) > 0;
  }

  return isNum;
};

export const findParentCompany = (
  allCompanys: CompanyDTO[],
  company: CompanyDTO
) => {
  console.log(allCompanys, company);
  if (company?.ParentNodeId) {
    const parent = allCompanys.find(
      (companies) => companies.id === company.ParentNodeId
    );

    return findParentCompany(allCompanys, parent);
  } else {
    return company;
  }
};

export const formatDate = (dateString: string | null): string => {
  if (!dateString) {
    return '';
  }
  const formattedDate = dayjs(dateString).format('L');
  return formattedDate;
};

export const structureCompanies = (
  companies: CompanyDTO[]
): StructuredCompanyDTO[] => {
  // Helper function to find child companies
  function findChildren(ParentNodeId: number): StructuredCompanyDTO[] {
    return companies
      .filter((company) => company.ParentNodeId === ParentNodeId)
      .map((company) => {
        const children = findChildren(company.id);
        return {
          title: company.name,
          value: company.id,
          parentId: company.ParentNodeId,
          key: company.id,
          children: children.length > 0 ? children : undefined,
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));
  }

  // Start with root companies (parentId === null)
  const result = companies
    .filter((company) => company.ParentNodeId === null)
    .map((company) => {
      const children = findChildren(company.id);
      return {
        title: company.name,
        value: company.id,
        parentId: company.ParentNodeId,
        key: company.id,
        children: children.length > 0 ? children : undefined,
      };
    })
    .sort((a, b) => a.title.localeCompare(b.title));
  return result;
};

type Item = {
  name: string;
  keep: boolean;
};
type FilterNodesChildrenByLevelsAndName = {
  AllotmentNodes: AllotmentNodeDTO[];
  Lot?: Item;
  Function?: Item;
  Category?: Item;
  Material?: Item;
  ChildrenLevel?: 'Function' | 'Category' | 'Material' | 'Label';
};
/**
 * This function allows filtering of AllotmentNodes by Name according to different levels. You can also choose the output level.
 *
 * @param {Object} param - The object containing the parameters of the function.
 * @param {AllotmentNodeDTO[]} param0.AllotmentNodes - AllotmentNodes to filter.
 * @param {Item} param0.Lot - The 'Lot' level object to filter. It contains the name and a boolean to know whether it should be kept or not.
 * @param {Item} param0.Function - The 'Function' level object to filter. It contains the name and a boolean to know whether it should be kept or not.
 * @param {Item} param0.Category - The 'Category' level object to filter. It contains the name and a boolean to know whether it should be kept or not.
 * @param {Item} param0.Material - The 'Material' level object to filter. It contains the name and a boolean to know whether it should be kept or not.
 * @param {string} param0.ChildrenLevel - The level of the AllotmentNodes returned by the function. By default, it's 'Label' (level === 4).
 *
 * @returns {AllotmentNodeDTO[]} - Returns AllotmentNodesFiltered of the selected ChildrenLevel.
 *
 * @example
 * // Example 1: The AllotmentNodes are filtered by Lot==="Lot1" and Function === 'Function'. The AllotmentNodes returned will be those of level Function, so level === 1.
 * filterNodesChildrenByLevelsAndName({
 *   AllotmentNodes: [...],
 *   Lot: {name: "Lot1", keep: true},
 *   Function: {name: "Function1", keep: true},
 *   ChildrenLevel: "Function"
 * });
 *
 * @example
 * // Example 2: Same as before, but in addition category === "Category1" and AllotmentNodes level "Category" that is level === 2.
 * filterNodesChildrenByLevelsAndName({
 *   AllotmentNodes: [...],
 *   Lot: {name: "Lot1", keep: true},
 *   Function: {name: "Function1", keep: true},
 *   Category: {name: "Category1", keep: true},
 *   ChildrenLevel: "Category"
 * });
 *
 * @example
 * // Example 3: The AllotmentNodes where the Lot !== "Lot1" and the level === "Label" that is level === 4.
 * filterNodesChildrenByLevelsAndName({
 *   AllotmentNodes: [...],
 *   Lot: {name: "Lot1", keep: false},
 *   ChildrenLevel: "Label"
 * });
 */
export const filterNodesChildrenByLevelsAndName = ({
  AllotmentNodes,
  Lot,
  Function,
  Category,
  Material,
  ChildrenLevel = 'Label',
}: FilterNodesChildrenByLevelsAndName): AllotmentNodeDTO[] => {
  const level = {
    Lot: 0,
    Function: 1,
    Category: 2,
    Material: 3,
    Label: 4,
  };

  type IdsArray = number[];
  let lotIds: IdsArray = [];
  let functionIds: IdsArray = [];
  let categoryIds: IdsArray = [];
  let materialIds: IdsArray = [];

  const findLotIds = (item: Item | undefined): IdsArray => {
    if (!item) {
      return AllotmentNodes.filter((node) => node.level === level.Lot).map(
        (node) => node.id
      );
    }
    const Nodes = AllotmentNodes.filter(
      (node) =>
        node.level === level.Lot &&
        (item.keep ? node.name === item.name : node.name !== item.name)
    );
    return Nodes.map((node) => node.id);
  };

  const findFunctionIds = (item: Item | undefined): IdsArray => {
    if (!item) {
      return AllotmentNodes.filter(
        (node) => node.level === level.Function && lotIds.includes(node.ParentId)
      ).map((node) => node.id);
    }
    const Nodes = AllotmentNodes.filter(
      (node) =>
        node.level === level.Function &&
        (item.keep ? node.name === item.name : node.name !== item.name) &&
        lotIds.includes(node.ParentId)
    );
    return Nodes.map((node) => node.id);
  };

  const findCategoryIds = (item: Item | undefined): IdsArray => {
    if (!item) {
      return AllotmentNodes.filter(
        (node) =>
          node.level === level.Category && functionIds.includes(node.ParentId)
      ).map((node) => node.id);
    }
    const Nodes = AllotmentNodes.filter(
      (node) =>
        node.level === level.Category &&
        (item.keep ? node.name === item.name : node.name !== item.name) &&
        functionIds.includes(node.ParentId)
    );
    return Nodes.map((node) => node.id);
  };

  const findMaterialIds = (item: Item | undefined): IdsArray => {
    if (!item) {
      return AllotmentNodes.filter(
        (node) =>
          node.level === level.Material && categoryIds.includes(node.ParentId)
      ).map((node) => node.id);
    }
    const Nodes = AllotmentNodes.filter(
      (node) =>
        node.level === level.Material &&
        (item.keep ? node.name === item.name : node.name !== item.name) &&
        categoryIds.includes(node.ParentId)
    );
    return Nodes.map((node) => node.id);
  };

  const selectArrayIdsToIncludes = (): IdsArray => {
    switch (ChildrenLevel) {
      case 'Function':
        return lotIds;
      case 'Category':
        return functionIds;
      case 'Material':
        return categoryIds;
      case 'Label':
        return materialIds;
      default:
        return [];
    }
  };

  const findAllotmentNodesChildren = (): AllotmentNodeDTO[] => {
    const IdsArrayToIncludes = selectArrayIdsToIncludes();
    return AllotmentNodes.filter(
      (node) =>
        node.level === level[ChildrenLevel] &&
        IdsArrayToIncludes.includes(node.ParentId)
    );
  };

  lotIds = findLotIds(Lot);
  if (ChildrenLevel === 'Function') {
    return findAllotmentNodesChildren();
  }
  functionIds = findFunctionIds(Function);
  if (ChildrenLevel === 'Category') {
    return findAllotmentNodesChildren();
  }
  categoryIds = findCategoryIds(Category);
  if (ChildrenLevel === 'Material') {
    return findAllotmentNodesChildren();
  }
  materialIds = findMaterialIds(Material);
  return findAllotmentNodesChildren();
};

export const returnLotNoocoForSheetSelected = (
  allotmentNodesFromSheet: AllotmentNode[],
  lotsAvailableInProject: AllotmentNode[],
  allAllotmentNodes: AllotmentNode[]
): AllotmentNode[] => {
  const lotFromAllNodesLinkToSheet = allotmentNodesFromSheet.map(
    (node) => findParentNode(node, allAllotmentNodes, [], [])[0]
  );

  const filterDepandingOnProjectType = lotFromAllNodesLinkToSheet.filter((lot) =>
    lotsAvailableInProject.find((lotAvailable) => lotAvailable.id === lot.id)
  );

  return filterDepandingOnProjectType;
};

export const filterAllotmentNodesDepandingOnProjectType = (
  allotmentNodesFromSheet: AllotmentNode[],
  lotsAvailableInProject: AllotmentNode[],
  allAllotmentNodes: AllotmentNode[]
) => {
  let allLotsAvailable = returnLotNoocoForSheetSelected(
    allotmentNodesFromSheet,
    lotsAvailableInProject,
    allAllotmentNodes
  );

  return allLotsAvailable.map((lot) =>
    lot.details.find((detail) => detail.MasterDataEquipmentId)
  );
};

export function shouldDisplayRoundStyleThresholds(
  currentAllotment: CurrentAllotmentDTO
) {
  const re2020AllotmentSelected = currentAllotment?.id === TEMPLATE_TYPE_ID.RE2020;

  return re2020AllotmentSelected;
}

export const onChangeSelectedSearchGroupType = (onChange, value) => {
  onChange(value);
};

export function getAllotmentNodeLotId(
  allotmentNodeMap: Map<number, AllotmentNodeDTO>,
  allotmentNodeId: number
) {
  const lotLevel = 0;
  let parentId: number | null = null;

  do {
    const allotmentNode = allotmentNodeMap.get(parentId || allotmentNodeId);

    if (allotmentNode !== undefined) {
      if (allotmentNode.level === lotLevel) {
        return allotmentNode.id;
      }

      parentId = allotmentNode.ParentId;
    }
  } while (parentId !== null);

  return null;
}

export function getProjectPeriodInYears(
  projectDdv: number,
  projectExploitContract: Lifespan
): number {
  if (
    projectExploitContract &&
    projectExploitContract.length !== 0 &&
    projectExploitContract[0]?.value &&
    projectExploitContract[1]?.value
  ) {
    const startDate = new Date(projectExploitContract[0].value);
    const endDate = new Date(projectExploitContract[1].value);

    return endDate.getFullYear() - startDate.getFullYear();
  } else {
    return projectDdv;
  }
}
export const returnUnitDepandingOnAllotmentNode = (
  allMds: MasterDataEquipment[],
  allotmentNode: AllotmentNodeDTO
): string | undefined => {
  return allMds.find((md) => md?.id === allotmentNode?.MasterDataEquipmentId)?.unit;
};

export const returnDisabledDepandingOnExploitContract = (d, currentProject) => {
  return (
    d.isBefore(currentProject.exploitContract[0].value) ||
    d.isAfter(currentProject.exploitContract[1].value)
  );
};

export const findIfRowIdIsProjectSheetId = (
  rowId: number,
  allProjectSheets: ProjectSheet[]
) => {
  let isRowInThisIteration = allProjectSheets.find((row) => row.id === rowId);

  return isRowInThisIteration;
};

export const returnSearchGroupOptions = (currentProject: ProjectDTO) => {
  let FULL_SEARCH_GROUP_OPTIONS = [
    SEARCH_GROUP_OPTION_VALUES.EQUIPEMENTS,
    SEARCH_GROUP_OPTION_VALUES.INIES,
    SEARCH_GROUP_OPTION_VALUES.ENERGIE,
    SEARCH_GROUP_OPTION_VALUES.EAU,
    SEARCH_GROUP_OPTION_VALUES.BIBLIOTHEQUE,
  ];

  const isGlobalMonitoringProject =
    currentProject.templateType === TEMPLATE_TYPE_NAME.GLOBAL_MONITORING;

  const isRegulatoryProject = REGULATORY_PROJECTS.includes(
    currentProject.templateType
  );

  const searchGroupOptionsWithoutEnergy = [
    SEARCH_GROUP_OPTION_VALUES.EQUIPEMENTS,
    SEARCH_GROUP_OPTION_VALUES.INIES,
    SEARCH_GROUP_OPTION_VALUES.EAU,
    SEARCH_GROUP_OPTION_VALUES.BIBLIOTHEQUE,
  ];
  const searchGroupOptionsForGlobalMonitoring = [
    SEARCH_GROUP_OPTION_VALUES.DECHETS,
    SEARCH_GROUP_OPTION_VALUES.TRANSPORTS,
  ];
  if (currentProject.isRset) {
    return searchGroupOptionsWithoutEnergy;
  }
  let searchGroupOptions = FULL_SEARCH_GROUP_OPTIONS;
  if (isGlobalMonitoringProject && !isRegulatoryProject) {
    searchGroupOptions.push(...searchGroupOptionsForGlobalMonitoring);
  } else if (!isRegulatoryProject) {
    searchGroupOptions.push(SEARCH_GROUP_OPTION_VALUES.TRANSPORTS);
  }

  return searchGroupOptions;
};

export const resetSelectedSearchGroupTypeIfNeeded = (
  setSelectedSearchGroupType: Function,
  currentSelectedSearchGroupType: SelectedSearchGroupOptionsLabel,
  searchGroupOptionsAvailable: SelectedSearchGroupOptionsLabel[]
) => {
  if (
    currentSelectedSearchGroupType &&
    !searchGroupOptionsAvailable.find(
      (searchGroup) =>
        searchGroup.toLowerCase() === currentSelectedSearchGroupType.toLowerCase()
    )
  ) {
    setSelectedSearchGroupType(SelectedSearchGroupOptionsLabel.Equipements);
  }
};
