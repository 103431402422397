import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';

const NoocoCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#19d895',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default NoocoCheckbox;
