import { connect } from 'react-redux';

import AddEquipment from '../../app/projects/EquipmentList/AddEquipment';

import {
  fetchProjectById,
  setInputValue,
  setSearchValue,
  updateCurrentFlag,
  updateFormValue,
} from '../../actions/projects';

import {
  askingIsDed,
  changeValueEquipments,
  downloadEdecFile,
  fetchIniesEquipments,
  loginEdec,
  sendRequestDed,
} from '../../actions/equipments';

const mapStateToProps = (state) => ({
  currentProject: state.projects.currentProject,
  searchValue: state.projects.searchValue,
  inputValue: state.projects.inputValue,
  selectedLot: state.projects.selectedLot,
  selectedFonction: state.projects.selectedFonction,
  selectedCategory: state.projects.selectedCategory,
  selectedMaterial: state.projects.selectedMaterial,
  selectedType: state.projects.selectedType,
  addDisplay: state.equipments.addDisplay,
  zoneEquipmentId: state.projects.zoneEquipmentId,
  equipmentBuildingSiteId: state.projects.equipmentBuildingSiteId,
  zones: state.projects.parsedProject.zones,
  parsed: state.projects.parsedProject.parsed,
  urbanNetworks: state.projects.parsedProject.urbanNetworks,
  iniesAdvanceLink: state.equipments.iniesAdvanceLink,
  elementsTree: state.equipments.elementsTree,
  currentDestination: state.equipments.currentDestination,
  fluids: state.projects.fluids,
  allGroups: state.projects.allGroups,
  breadcrumbChoice: state.equipments.breadcrumbChoice,
  forfaitList: state.equipments.forfaitList,
  errorIdInies: state.equipments.errorIdInies,
  datasForfait: state.equipments.datasForfait,
  edecUsername: state.equipments.edecUsername,
  edecPassword: state.equipments.edecPassword,
  edecFiles: state.equipments.edecFiles,
  edecChoice: state.equipments.edecChoice,
  displaySpinner: state.equipments.displaySpinner,
  selectedRowKeys: state.equipments.selectedRowKeys,
  edecNames: state.equipments.edecNames,
  filterEdec: state.equipments.filterEdec,
  filterData: state.equipments.filterData,
  currentIndicator: state.projects.currentIndicator,
  iniesNomenclature: state.projects.iniesNomenclature,
  composantVideForm: state.equipments.composantVideForm,
  re2020lot: state.equipments.re2020lot,
  currentAllotment: state.projects.currentAllotment,
  lineImport: state.imports.lineImport,
  commentEquipment: state.projects.commentEquipment,
  objSheet: state.projects.objSheet,
  customName: state.projects.customName,
  idAsker: state.projects.idAsker,
  simplifiedEnergyLines: state.equipments.simplifiedEnergyLines,
});

const mapDispatchToProps = (dispatch) => ({
  setSearchValue: (data) => {
    dispatch(setSearchValue(data));
  },
  setInputValue: (data) => {
    dispatch(setInputValue(data));
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  changeValueEquipments: (name, value) => {
    dispatch(changeValueEquipments(name, value));
  },
  updateCurrentFlag: (value) => {
    dispatch(updateCurrentFlag(value));
  },
  fetchIniesEquipments: (value) => {
    dispatch(fetchIniesEquipments(value));
  },
  loginEdec: () => {
    dispatch(loginEdec());
  },
  downloadEdecFile: () => {
    dispatch(downloadEdecFile());
  },
  fetchProjectById: (id, indicatorId) => {
    dispatch(fetchProjectById(id, indicatorId));
  },
  askingIsDed: (row) => {
    dispatch(askingIsDed(row));
  },
  sendRequestDed: (row) => {
    dispatch(sendRequestDed(row));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEquipment);
