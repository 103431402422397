import React from 'react';
import { useSelector } from 'react-redux';
import { StateDTO } from '../../models/GeneralDTO';
import { LOTS_IDS } from '../../globalVariable/generalDefinitions';
import { SEARCH_GROUP_OPTION_VALUES } from '../../globalVariable/searchGroupType';
import { SelectedSearchGroupOptionsLabel } from './QuickAddBarEquipment';
import { DatePicker, RangeDatePicker } from '@photocarbone/nooco-ui';
import { getAntdLocale } from '../getAntdLocale';
import dayjs from 'dayjs';
import { returnDisabledDepandingOnExploitContract } from '../utilsFunction';

interface DatePickerSelectorProps {
  selectedSearchGroupType: SelectedSearchGroupOptionsLabel;
  onChange: (date: string | string[]) => void;
}

export default function DatePickerSelector({
  selectedSearchGroupType,
  onChange,
}: DatePickerSelectorProps) {
  const { currentProject } = useSelector((state: StateDTO) => state.projects);
  let needRangePicker = [
    SelectedSearchGroupOptionsLabel['Déchets'],
    SelectedSearchGroupOptionsLabel['Transports'],
    SelectedSearchGroupOptionsLabel['Eau'],
    SelectedSearchGroupOptionsLabel['Energie'],
  ].includes(selectedSearchGroupType);

  let format = dayjs.localeData().longDateFormat('L');
  let locale = getAntdLocale();

  let commonProps = {
    disabledDate: (d) => returnDisabledDepandingOnExploitContract(d, currentProject),
    onChange,
    format,
    locale,
  };

  return !needRangePicker ? (
    <div>
      <DatePicker {...commonProps} />
    </div>
  ) : (
    <div>
      <RangeDatePicker {...commonProps} />
    </div>
  );
}
