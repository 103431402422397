import './lineGraphic.scss';
// import { Mesh } from '@nivo/voronoi';
import { ResponsiveLine } from '@nivo/line';
import { motion } from 'framer-motion';

import { StateDTO } from '../../../models/GeneralDTO';
import { useSelector } from 'react-redux';
import GraphicHeader from '../GraphicHeader/GraphicHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

// const Coeff = require('../../../../app/projects/LifeCycle/CoefAcv.js');

const LineGraphic = ({ onSubmit, onSubmitError, handleSubmit, getValues }) => {
  const { t } = useTranslation();

  const { loadingGraph, dataForGraph, information } = useSelector(
    (state: StateDTO) => state.simulator
  );

  const lineData = dataForGraph?.dataGraphLine;

  let ecartType;
  let firstTick;
  let lastTick;

  if (lineData[0]?.data[0]) {
    firstTick = lineData[0].data[0].x;
    lastTick = lineData[0].data[lineData[0].data.length - 1].x;
  }

  if (firstTick && lastTick) {
    ecartType = (lastTick - firstTick) / 6;
  }

  let MiddleTick = [];

  for (let i = 1; i < 6; i++) {
    MiddleTick.push(Math.round(firstTick + ecartType * i));
  }

  return (
    <div className="lineGraphicWrapper">
      {loadingGraph && (
        <div className="graphic-spinner-wrapper">
          <div className="donut" />
        </div>
      )}
      {!loadingGraph && (
        <>
          <GraphicHeader
            onSubmit={onSubmit}
            onSubmitError={onSubmitError}
            handleSubmit={handleSubmit}
            getValues={getValues}
          />
          <motion.div
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.5 }}
            transition={{
              // delay: 0.2,
              // x: { duration: 0.2 },
              opacity: { duration: 0.2 },
              default: { ease: 'linear' },
            }}
            className="graphicContainer"
          >
            <ResponsiveLine
              data={lineData}
              margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
              xScale={{
                type: 'point',
                // min: 2017,
                // max: 2057,
                // min: "auto",
                // max: "auto",
              }}
              yScale={{
                type: 'linear',
                // min: 140,
                // max: 165,
                min: 'auto',
                max: 'auto',
                stacked: false,
                reverse: false,
              }}
              yFormat=" >-.2f"
              curve="linear"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                // orient: 'bottom',
                tickSize: 0,
                tickPadding: 8,
                tickRotation: 0,
                tickValues: [firstTick, ...MiddleTick, lastTick],

                // legend: '',
                // legendOffset: -20,
                // legendPosition: 'middle'
              }}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: 'black',
                      strokeWidth: 2,
                    },
                  },
                },
              }}
              axisLeft={{
                // orient: 'left',
                tickSize: 0,
                tickPadding: 8,
                tickRotation: 0,
                // tickValues: [140, 145, 150, 155, 160, 165,],

                legend:
                  information.refUnit === 'm²' ? 'kg éq. CO₂/m²' : 'kg éq. CO₂',
                legendOffset: -50,
                legendPosition: 'middle',
              }}
              enableGridX={false}
              enablePoints={false}
              colors={{ datum: 'color' }}
              // pointSize={10}
              // pointColor={{ theme: 'background' }}
              // pointBorderWidth={2}
              // pointBorderColor={{ from: 'serieColor' }}
              // pointLabelYOffset={-12}
              enableSlices="x"
              sliceTooltip={({ slice }) => {
                const year = _.cloneDeep(slice?.points[0]?.data?.x);

                const sortedData = [slice?.points[1], slice?.points[0]];

                return (
                  <div
                    style={{
                      background: 'white',
                      padding: '9px 12px',
                      border: '1px solid #ccc',
                    }}
                  >
                    <div style={{ fontWeight: '700' }}>{`${t(
                      'APP.SIMULATOR_GRAPHIC.LINE_GRAPHIC.YEARS',
                      { defaultValue: 'Année :' }
                    )} ${year}`}</div>
                    {sortedData.map((point) => (
                      <div
                        key={point?.id}
                        style={{
                          color: 'black',
                          padding: '3px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <div
                          style={{
                            width: '12px',
                            height: '12px',
                            backgroundColor: point?.serieColor,
                            paddingRight: '12px',
                            marginRight: '12px',
                          }}
                        ></div>
                        <div>
                          {t('APP.SIMULATOR_GRAPHIC.VARIANT_LEDGEND', {
                            number: point?.serieId.split(' ')[1],
                            defaultValue: 'Variante {number} : ',
                          })}
                          &nbsp;
                        </div>
                        <div
                          style={{ fontWeight: '500' }}
                        >{`${point?.data?.yFormatted}`}</div>{' '}
                        &nbsp;{' '}
                        {information.refUnit === 'm²'
                          ? 'kg éq. CO₂/m²'
                          : 'kg éq. CO₂'}
                      </div>
                    ))}
                  </div>
                );
              }}
              useMesh={true}
            />
          </motion.div>
        </>
      )}
    </div>
  );
};

export default LineGraphic;
