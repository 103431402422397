import { useMutation, useQueryClient } from 'react-query';
import {
  PostProjectDataCompsProps,
  postProjectDataComps,
} from '../../../apiCallFunctions/project/project';

export const useSaveProjectDataComps = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: PostProjectDataCompsProps) => postProjectDataComps(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('projectDataComps');
      },
      onError: (error) => {
        console.log('error', error);
      },
    }
  );

  return mutation;
};
