/* eslint-disable no-undef */
import { IconButton, Popover } from '@material-ui/core';
import { IndicatorDTO, ProjectDTO } from '../../../../app/models/ProjectDTO';
import { ProjectIcDTO, zonesDTO } from './WidgetsDTO';
import { TEMPLATE_TYPE_NAME } from '../../../globalVariable/typeProject';
import {
  ZONE_TYPES,
  MIDED_OPTIONS,
} from '../../../globalVariable/generalDefinitions';
import { gaugeEPCM } from '../../../shared/utils';
import { shouldDisplayRoundStyleThresholds } from '../../../shared/utilsFunction';
import CustomProgressBar from '../../../shared/CustomProgressBar';
import CustomThreshold, { MiDedDropdown } from '../../../shared/CustomThreshold';
import InfoIcon from '@material-ui/icons/Info';
import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import formatNumber, {
  LIBELLE_KG_EQ_CO2,
  formatValueWithWeight,
  getUnitConversion,
} from '../../../services/mathematics/formatNumber';
import { useTranslation } from 'react-i18next';
import Info from '@material-ui/icons/Info';
import { IcMaxBbcaTooltip } from './widgetElements/WidgetsElements';
import { CurrentAllotmentDTO } from '../../../models/ProjectsDTO';

type ConstructWidgetsProps = {
  projectIc: ProjectIcDTO;
  zones: zonesDTO[];
  currentProject: ProjectDTO;
  currentIndicator: IndicatorDTO;
  currentAllotment: CurrentAllotmentDTO;
  openedPopooverId: string;
  seuilsOk: boolean;
  handlePopoverOpen: Function;
  handlePopoverClose: Function;
  renderPopoverBody: Function;
  icKey?: string;
  title?: string;
};

const construct = require('../../../../assets/images/synthese/Image4.png');

export const formatNumberP = (
  value: number,
  seuil: boolean,
  currentProject: ProjectDTO,
  currentIndicator: IndicatorDTO | string
) => {
  let refUnitModeTotal = currentProject.refUnit === 'total';

  if (!value && value !== 0)
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p>
          {' '}
          <span className="valueNumbers">0</span>{' '}
          {typeof currentIndicator === 'string'
            ? currentIndicator
            : currentIndicator.unit}{' '}
          {refUnitModeTotal ? '' : '/m²'}​
        </p>
      </div>
    );

  if (seuil) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p>
          Seuil max :{' '}
          <span className="valueNumbers">
            {formatNumber(refUnitModeTotal ? value * currentProject.area : value)}
          </span>{' '}
          {typeof currentIndicator === 'string'
            ? currentIndicator
            : currentIndicator.unit}{' '}
          {refUnitModeTotal ? '' : '/m²'}​
        </p>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p
          className={
            currentProject.templateType.includes(TEMPLATE_TYPE_NAME.EPCM)
              ? 'value'
              : 'valueRe2020'
          }
        >
          <span className="valueNumbers">
            {formatValueWithWeight(
              refUnitModeTotal ? value * currentProject.area : value,
              currentIndicator
            )}{' '}
          </span>
          {typeof currentIndicator === 'string'
            ? currentIndicator
            : currentIndicator.unit === LIBELLE_KG_EQ_CO2 ? getUnitConversion(refUnitModeTotal ? value * currentProject.area : value) : currentIndicator.unit}
          {refUnitModeTotal ? '' : '/m²'}
        </p>
      </div>
    );
  }
};

const ConstructWidgets = ({
  renderPopoverBody,
  currentIndicator,
  currentAllotment,
  projectIc,
  openedPopooverId,
  seuilsOk,
  handlePopoverOpen,
  handlePopoverClose,
  currentProject,
  zones,
  icKey,
  title,
}: ConstructWidgetsProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(MIDED_OPTIONS.current);
  let refUnitModeTotal = currentProject.refUnit === 'total';

  const buildingIc: ProjectIcDTO =
    zones?.find((zone) => zone?.type === ZONE_TYPES.building)?.Ic['1'] || projectIc;
  const modalData = zones?.find((zone) => zone?.type === ZONE_TYPES.building)
    ?.Zones[0].Ic['1'];

  const icToDisplay =
    currentProject.templateType === TEMPLATE_TYPE_NAME.BBCA
      ? buildingIc.Bbca[icKey]
      : projectIc.IcConstruction;
  const icToDisplayMax =
    currentProject.templateType === TEMPLATE_TYPE_NAME.BBCA
      ? buildingIc.Bbca.Max[`${icKey}Max`]
      : projectIc.IcConstruction;

  return (
    <div
      className={`report-construct ${
        currentProject.templateType.includes(TEMPLATE_TYPE_NAME.BBCA) ? 'bbca' : ''
      } container-resizable-ok`}
    >
      <div className="report-construct-header">
        <h3 className="dashboard-title">
          {title
            ? title
            : [TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(
                currentProject.templateType
              )
            ? t('APP.WIDGETS.CONSTRUCTWIDGETS.PH_E8DCD0', {
                defaultValue: 'Produits de Construction et Equipements',
              })
            : t('APP.WIDGETS.CONSTRUCTWIDGETS.PH_C13029', {
                defaultValue: 'Construction',
              })}
        </h3>
        <Tooltip
          title={
            currentProject.templateType.includes(TEMPLATE_TYPE_NAME.EPCM)
              ? t('APP.WIDGETS.CONSTRUCTWIDGETS.PH_075F47', {
                  defaultValue:
                    'EGESpce: Impact des produits de construction et équipements - bénéfices et charges (si positif)',
                })
              : t('APP.WIDGETS.CONSTRUCTWIDGETS.PH_84B54C', {
                  defaultValue: 'Impact carbone construction : IcConstruction',
                })
          }
        >
          <img
            style={{
              width: window.screen.availHeight < 950 ? '40px' : undefined,
              height: window.screen.availHeight < 950 ? '40px' : undefined,
            }}
            src={construct}
            alt=""
          />
        </Tooltip>
      </div>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexGrow: '1',
        }}
      >
        {![TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(
          currentProject.templateType
        ) && MiDedDropdown(selectedOption, setSelectedOption)}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {formatNumberP(icToDisplay, false, currentProject, currentIndicator)}
          {[TEMPLATE_TYPE_NAME.BBCA].includes(currentProject.templateType) && (
            <IcMaxBbcaTooltip
              icKey={icKey}
              icToDisplayMax={icToDisplayMax}
              currentIndicator={currentIndicator}
              refUnitModeTotal={refUnitModeTotal}
            />
          )}
        </div>
      </div>
      {shouldDisplayRoundStyleThresholds(currentAllotment) && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CustomThreshold
            screen="construct"
            buildingIc={buildingIc}
            modalData={modalData}
            oldCalcul={selectedOption === MIDED_OPTIONS.current ? true : false}
          />
        </div>
      )}
      {currentProject.templateType.includes('nothing') && (
        <>
          {(currentIndicator.id === 1 || currentIndicator.id === 26) && seuilsOk ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              {formatNumberP(
                projectIc.IcConstructionMax,
                true,
                currentProject,
                currentIndicator
              )}
              <IconButton
                size="small"
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  handlePopoverOpen(e, 'cons');
                }}
              >
                <InfoIcon />
              </IconButton>
              <Popover
                onClose={() => handlePopoverClose()}
                disableScrollLock={true}
                open={openedPopooverId === 'cons'}
                transformOrigin={{
                  horizontal: 'center',
                  vertical: 'bottom',
                }}
                anchorOrigin={{
                  horizontal: 'center',
                  vertical: 'top',
                }}
                anchorEl={anchorEl}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  {}
                  {renderPopoverBody()}
                </div>
              </Popover>
            </div>
          ) : (
            <p>
              {' '}
              {t('APP.WIDGETS.CONSTRUCTWIDGETS.PH_E3DB78', {
                defaultValue: 'Seuil max : Non applicable',
              })}
            </p>
          )}
        </>
      )}
      {currentProject.templateType.includes(TEMPLATE_TYPE_NAME.EPCM) && (
        <CustomProgressBar
          projectIc={projectIc}
          constructWidget={true}
          valuePercentage={gaugeEPCM('IcConstruction', projectIc)}
        />
      )}
    </div>
  );
};

export default ConstructWidgets;
