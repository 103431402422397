import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateDTO } from '../../../../models/GeneralDTO';
import { Tooltip } from '@mui/material';
import { Button } from '@photocarbone/nooco-ui';
import { LockFilled, UnlockFilled } from '@ant-design/icons';
import { changeValueEquipments } from '../../../../../actions/equipments';
import { updateFormValue } from '../../../../../actions/projects';
import { usePatchLinesFromList } from '../../../../../hooks/useQuery/equipments';
import { useTranslation } from 'react-i18next';
import { UseFormGetValues, useFormContext } from 'react-hook-form';
import { check } from 'prettier';
import { CaracDTO } from '../../../../models/EquipmentsDTO';
import { Carac } from '@photocarbone/nooco-shared-api-def';
import { TYPE_FICHE } from '../../../../globalVariable/generalDefinitions';

type EditButtonsProps = {
  handleSubmitForm: Function;
  isLoadingPatchEquipments: Boolean;
};

export const checkEmptyInput = (formData) => {
  let isEmpty = false;
  let keysToCheck = ['AllotmentNode', 'quantity'];
  Object.keys(formData.ProjectSheets).forEach((id) => {
    let data = formData.ProjectSheets[id];
    if (!data) return;
    if (data.SheetId && data?.SheetId.type_fiche === TYPE_FICHE.FICHES_CONFIG) {
      return;
    }
    Object.keys(data)?.forEach((key) => {
      if (!keysToCheck.includes(key)) return;
      if (data[key] === '' || data[key] === null || data[key] === undefined) {
        isEmpty = true;
      }
    });
  });
  return isEmpty;
};

const EditButtons = ({
  handleSubmitForm,
  isLoadingPatchEquipments,
}: EditButtonsProps) => {
  const { tableEditMode, isUserStartEditColumns, ProjectSheetsToPatch } =
    useSelector((state: StateDTO) => state.equipments);

  const { getValues } = useFormContext();
  const formData = getValues();

  let isEmptyInput: Boolean = formData?.ProjectSheets
    ? checkEmptyInput(formData)
    : false;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
      className="locked-wrapper"
    >
      <div
        className="subWrapperTest"
        style={{
          backgroundColor: '#F4F7FBd',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {
          // lockedMode
          !tableEditMode ? (
            <>
              <Tooltip
                title={t('APP.FOOTERTABLE.FOOTERTABLE.PH_0D197F', {
                  defaultValue: 'Passer en mode modification à la volée',
                })}
              >
                <div>
                  <Button
                    dataTestId="edit-button"
                    className="purple-button"
                    additionalIcon={<LockFilled />}
                    style={{
                      background: 'transparent',
                      color: '#A3AED0',
                      fontWeight: '700',
                    }}
                    text={t('APP.FOOTERTABLE.EDIT_BUTTONS.EDIT_MODE', {
                      defaultValue: 'Mode édition',
                    })}
                    onClick={() =>
                      dispatch(changeValueEquipments('tableEditMode', true))
                    }
                  ></Button>
                </div>
              </Tooltip>
            </>
          ) : (
            // Unlocked but no userInteraction
            // unlocked With userInteraction
            <>
              <Tooltip
                title={t('APP.FOOTERTABLE.FOOTERTABLE.PH_BFEFE3', {
                  defaultValue: 'Ignorer les modifications effectuées',
                })}
              >
                <div>
                  <Button
                    text={t('APP.FOOTERTABLE.FOOTERTABLE.PH_07DF95', {
                      defaultValue: 'Annuler',
                    })}
                    disabled={isLoadingPatchEquipments}
                    className="close-button"
                    style={{ fontWeight: '700', marginRight: '10px' }}
                    onClick={() => {
                      dispatch(changeValueEquipments('tableEditMode', false));
                      dispatch(changeValueEquipments('ProjectSheetsToPatch', []));
                      dispatch(changeValueEquipments('currentEdit', null));
                      dispatch(
                        changeValueEquipments('isUserStartEditColumns', false)
                      );
                    }}
                  />
                </div>
              </Tooltip>

              <Tooltip
                title={
                  isEmptyInput
                    ? t('APP.LIST_EQUIPMENTS.EDIT_BUTTONS.EMPTY_INPUT', {
                        defaultValue:
                          'Certaines de vos lignes sont vides, veuillez les remplir avant de sauvegarder',
                      })
                    : t('APP.FOOTERTABLE.FOOTERTABLE.PH_F1CE05', {
                        defaultValue: 'Enregistrer les modifications effectées',
                      })
                }
              >
                <div>
                  <Button
                    type="submit"
                    dataTestId="submit-button"
                    className="purple-button"
                    text={t('APP.FOOTERTABLE.FOOTERTABLE.PH_2F0394', {
                      defaultValue: 'Enregistrer',
                    })}
                    style={{ fontWeight: '700' }}
                    disabled={!isUserStartEditColumns || isEmptyInput}
                    additionalIcon={<UnlockFilled />}
                    loading={isLoadingPatchEquipments}
                    onClick={handleSubmitForm}
                  />
                </div>
              </Tooltip>
            </>
          )
        }
      </div>
    </div>
  );
};

export default EditButtons;
