import './mappingTable.scss';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List,
} from 'react-virtualized';
import { StateDTO } from '../../../../models/GeneralDTO';
import {
  changeImportDatas,
  fetchImportDatas,
  verifyRowsEquipment,
} from '../../../../../actions/import';
import { getListDataToDisplay } from '../../../Import';
import { loadAllGroups, updateFormValue } from '../../../../../actions/projects';
import { returnCountOfImportRows } from '../../../Import/ImportList';
import { useDispatch, useSelector } from 'react-redux';
import BreadcrumbModal from '../../../../../containers/BreadcrumbModal';
import InfoIcon from '@material-ui/icons/Info';
import Loader from '@material-ui/core/CircularProgress';
import MappingLine from '../MappingLine';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import RowSkeleton from '../../../Import/RowSkeleton';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import { returnSearchBarOptionsFiltered } from '../../../../shared/utils';

const MappingTable = ({ currentRowIndexDisplayed }) => {
  const [copyLine, setCopyLine] = useState<{} | null>();

  const dispatch = useDispatch();
  const {
    dataError,
    displayArchived,
    isSearchingData,
    isFullLoad,
    isImportLoading,
    importLoading,
    goodLines,
    badLines,
    waitLines,
    isLoad,
    editImport,
    filterOn,
    importDatas,
    currentImport,
  } = useSelector((state: StateDTO) => state.imports);
  const {
    allGroups,
    nodesAvailableForProjectType,
    bundleOptions,
    modeledProductOptions,
    configuredSheetOptions,
  } = useSelector((state: StateDTO) => state.projects);

  const { t } = useTranslation();

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 200,
    })
  );

  useEffect(() => {
    cache.current.clearAll();
  }, [dataError]);

  let totalCount = importDatas.counts
    ? returnCountOfImportRows(
        importDatas.counts.actualCount,
        importDatas.counts.Validated,
        importDatas.counts.notValidated,
        importDatas.counts.userValidated,
        importDatas.counts.isArchived,
        filterOn,
        displayArchived,
        isSearchingData
      )
    : 0;

  const _isRowLoaded = ({ index }) => {
    return getListDataToDisplay(dataError, displayArchived).length - 1 === index
      ? false
      : true;
  };

  const _loadMoreRows = () => {
    if (!isSearchingData && !isFullLoad && !isSearchingData) {
      dispatch(fetchImportDatas(null, currentImport));
    }
  };

  const searchBarOptions = returnSearchBarOptionsFiltered({
    allAllotments: allGroups.flatAllotments,
    allotmentsAvailable: nodesAvailableForProjectType,
    iniesOptions: [],
    bundleOptions,
    modeledProductOptions,
    configuredSheetOptions,
  });

  const handleEdit = (input, lineId, caracId, value, breadcrumb) => {
    const datas = [...editImport];
    let currentLine = datas.find((lines) => lines.id === lineId);

    if (!currentLine) {
      const duplicateLines = datas.filter((el) => el.id === 'duplicate');

      duplicateLines.forEach((el) => {
        if (el.ProjectElemsDuplicate.find((line) => line.id === lineId)) {
          currentLine = el.ProjectElemsDuplicate.find((line) => line.id === lineId);
        }
      });
    }

    let editLines = datas.filter((lines) => lines.id !== lineId);

    switch (input) {
      case 'valueCarac':
        {
          let newCarac = currentLine.ProjectImportElemCaracs.find(
            (elem) => elem.name === caracId
          );
          let newListCarac = currentLine.ProjectImportElemCaracs.filter(
            (elem) => elem.name !== caracId
          );

          newCarac = { ...newCarac, value: value };

          newListCarac = [...newListCarac, newCarac];

          currentLine = {
            ...currentLine,
            Sheet: null,
            SheetId: null,
            ProjectImportElemCaracs: newListCarac.sort(function (a, b) {
              if (a.id < b.id) {
                return -1;
              }

              if (a.id > b.id) {
                return 1;
              }

              return 0;
            }),
          };

          editLines = [...editLines, currentLine];

          dispatch(changeImportDatas(editLines));

          dispatch(updateFormValue('importPepFdes', []));

          if (breadcrumb) {
            dispatch(verifyRowsEquipment(lineId, { newCarac, currentLine }));
          }
        }
        break;

      case 'AllotmentNode': {
        if (value) {
          if (value && value.name) {
            currentLine.AllotmentNode = value;
            currentLine.SheetId = null;

            editLines = [...editLines, currentLine];

            dispatch(changeImportDatas(editLines));

            dispatch(updateFormValue('isLoad', true));
            dispatch(
              verifyRowsEquipment({ id: currentLine.id, AllotmentNode: value })
            );
          }
        } else {
          if (!currentLine.AllotmentNode) {
            return;
          }
          currentLine.AllotmentNode = null;
          editLines = [...editLines, currentLine];

          dispatch(changeImportDatas(editLines));

          dispatch(verifyRowsEquipment({ id: currentLine.id, AllotmentNode: null }));
        }

        break;
      }

      case 'quantity': {
        currentLine = { ...currentLine, quantity: value };

        editLines = [...editLines, currentLine];

        dispatch(changeImportDatas(editLines));

        break;
      }
      case 'urbanNetwork': {
        currentLine = {
          ...currentLine,
          urbanNetworkId: value ? value.id : null,
        };

        editLines = [...editLines, currentLine];

        dispatch(changeImportDatas(editLines));

        dispatch(verifyRowsEquipment(lineId));

        break;
      }
      case 're2020lot': {
        currentLine = {
          ...currentLine,
          re2020lot: value,
          AllotmentNodes: value ? currentLine.AllotmentNodes : [],
        };

        editLines = [...editLines, currentLine];

        dispatch(changeImportDatas(editLines));

        dispatch(
          verifyRowsEquipment({
            id: lineId,
            re2020lot: value,
            AllotmentNodes: value ? [value] : [],
          })
        );

        break;
      }
      case 'comment': {
        currentLine = { ...currentLine, comment: value };

        editLines = [...editLines, currentLine];

        dispatch(changeImportDatas(editLines));

        dispatch(verifyRowsEquipment({ id: lineId, comment: value }));

        break;
      }

      case 'onValidation': {
        currentLine = { ...currentLine, statusCode: 'userValidated' };

        editLines = [...editLines, currentLine];

        dispatch(changeImportDatas(editLines));

        dispatch(verifyRowsEquipment({ id: lineId, userValidated: true }));

        break;
      }
      case 'isRefurbished': {
        currentLine.isRefurbished = !currentLine.isRefurbished;

        editLines = [...editLines, currentLine];

        dispatch(changeImportDatas(editLines));

        dispatch(
          verifyRowsEquipment({
            id: currentLine.id,
            isRefurbished: currentLine.isRefurbished,
          })
        );

        break;
      }
      case 'sheetPepFdes': {
        if (!value) {
          currentLine.SheetId = null;
        } else {
          currentLine.SheetId = value.id;
          currentLine.Sheet = value;

          currentLine.AllotmentNode = value.AllotmentNodes.find(
            (el) => el.AllotmentId === 1
          );
          currentLine.AllotmentNodeId = value.AllotmentNodes.find(
            (el) => el.AllotmentId === 1
          ).id;
        }

        editLines = [...editLines, currentLine];
        dispatch(changeImportDatas(editLines));

        dispatch(
          verifyRowsEquipment({
            id: currentLine.id,
            SheetId: value ? value.id : null,
            AllotmentNode: currentLine.AllotmentNode,
            AllotmentNodeId: currentLine.MasterDataEquipmentId,
          })
        );

        break;
      }
    }
  };

  let listOfRows = useMemo(
    () => getListDataToDisplay(dataError, displayArchived),
    [dataError, displayArchived]
  );

  return (
    <>
      <BreadcrumbModal
        verifyRowsEquipment={verifyRowsEquipment}
        handleEdit={handleEdit}
        groups={allGroups.type.filter(
          (el) => el.name && !el.isObsolete && (!el.Child || el.Child?.length === 0)
        )}
      />

      <div className="wrapper-mapp-table">
        <div className="mapping-table-wrapper header-map-table">
          <p>
            {t('APP.MAPPINGTABLE.INDEX.PH_BB6485', {
              defaultValue: 'Libellé source',
            })}
          </p>
          <p>
            {t('APP.MAPPINGTABLE.INDEX.PH_E7BC3E', {
              defaultValue: 'Libellé cible',
            })}
          </p>
          <p>
            {t('APP.MAPPINGTABLE.INDEX.PH_8B9482', {
              defaultValue: 'Caractéristiques par défaut (facultatif)',
            })}
            <Tooltip
              title={t('APP.MAPPINGTABLE.INDEX.PH_DE08C5', {
                defaultValue:
                  'Les caractéristiques saisies seront automatiquement reconnues et prises par défaut sur les futurs mappings. Les caractéristiques non renseignées seront demandées à chaque import.',
              })}
            >
              <InfoIcon />
            </Tooltip>
          </p>
          <p></p>
        </div>

        {importLoading || isImportLoading ? (
          <div
            style={{
              height: '67vh',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loader
              variant="indeterminate"
              disableShrink
              style={{ color: '#73b7ec' }}
              size={60}
              thickness={4}
            />
          </div>
        ) : (
          <div style={{ height: '67vh', width: '100%' }}>
            {dataError && cache && cache.current && (
              <InfiniteLoader
                isRowLoaded={_isRowLoaded}
                loadMoreRows={_loadMoreRows}
                rowCount={totalCount}
              >
                {({ onRowsRendered, registerChild }) => (
                  <AutoSizer>
                    {({ width, height }) => (
                      <List
                        // onScroll={onScrollMethod}
                        width={width}
                        onRowsRendered={onRowsRendered}
                        ref={registerChild}
                        height={height}
                        deferredMeasurementCache={cache.current}
                        rowHeight={cache.current.rowHeight}
                        rowCount={totalCount}
                        rowRenderer={({ key, index, style, parent }) => {
                          if (!listOfRows[index]) {
                            currentRowIndexDisplayed.current = index;

                            return (
                              <CellMeasurer
                                key={key}
                                cache={cache.current}
                                parent={parent}
                                columnIndex={0}
                                rowIndex={index}
                              >
                                <div style={style}>
                                  <RowSkeleton screen={'mapping'} />
                                </div>
                              </CellMeasurer>
                            );
                          }

                          return (
                            <CellMeasurer
                              key={key}
                              cache={cache.current}
                              parent={parent}
                              columnIndex={0}
                              rowIndex={index}
                            >
                              <div style={style}>
                                <MappingLine
                                  handleEdit={handleEdit}
                                  cache={cache}
                                  line={listOfRows[index]}
                                  copyLine={copyLine}
                                  setCopyLine={setCopyLine}
                                  searchBarOptions={searchBarOptions}
                                />
                              </div>
                            </CellMeasurer>
                          );
                        }}
                      />
                    )}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default MappingTable;
