import {
  ProjectsShortRequest,
  ProjectsShortResponse,
} from '@photocarbone/nooco-shared-api-def/src/project/projectsShort';
import { ProjectDTO } from '../../app/models/ProjectDTO';

import API from '../../app/services/API';
import { AxiosResponse } from 'axios';
import { ProjectDataComps } from '@photocarbone/nooco-shared-api-def/src/project/projectDataComps';

const api = new API();

export const fetchProjectsList = async (
  projectInput,
  projectTypeFilter,
  selectedCompanyId,
  { pageParam = 0 },
  isSuperAdmin = false
) => {
  // console.log('projectInput',
  //   projectInput,
  //   'pageParam',
  //   pageParam);
  // console.log('projectTypeFilter',
  //   projectTypeFilter);

  let queryParams = `offset=${pageParam * 10}&limit=10&search=${encodeURIComponent(
    projectInput
  )}${selectedCompanyId && isSuperAdmin ? `&companyId=${selectedCompanyId}` : ''}`;

  projectTypeFilter.forEach((projectType) => {
    queryParams += `&ProjectTypeId=${projectType.id}`;
  });
  // filtersCompanyInput.forEach((company) => {
  //   queryParams += `&companyId=${company.id}`;
  // });

  return await api
    .getProjectsList(queryParams)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export type getProjectsByFolderResponse = {
  pages: {
    count: number;
    rows: ProjectDTO[];
  }[];
};
export const fetchProjectsByFolder = async (
  selectedCompanyId,
  folderId,
  projectTypeFilter,
  { pageParam = 0 }
) => {
  return await api
    .getProjectsByFolder(
      selectedCompanyId,
      folderId,
      projectTypeFilter,
      pageParam * 10
    )
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export const updateProject = async (data) => {
  let body = {
    ...data.body,
    id: data.projectId,
  };

  return await api
    .patchProject(body)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteProject = async (projectId) => {
  return await api
    .deleteProject(projectId)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export const getProjectsShort = async ({
  CompanyId,
}: ProjectsShortRequest): Promise<ProjectsShortResponse> => {
  return await api
    .getProjectsShort(CompanyId)
    .then((response: AxiosResponse<ProjectsShortResponse>) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getProjectDataComps = async (
  projectId: number
): Promise<ProjectDataComps> => {
  return await api
    .getProjectDataComps(projectId)
    .then((response: AxiosResponse<ProjectDataComps>) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export type PostProjectDataCompsProps = {
  projectId: number;
  body: ProjectDataComps;
};
export const postProjectDataComps = async ({
  projectId,
  body,
}: PostProjectDataCompsProps): Promise<ProjectDataComps> => {
  return await api
    .postProjectDataComps(projectId, body)
    .then((response: AxiosResponse<ProjectDataComps>) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

//TODO add patchProjectDataComps en plus de postProjectDataComps. PLus pratique et specifique?
