import './customBbcaThreshold.scss';
import React, { useState } from 'react';

import validIcon from '../../../assets/images/synthese/threshold/C1.svg';
import { ThresholdBbcaType } from '../../globalVariable/generalDefinitions';
import {
  getAchievedBBCAThresholds,
  getBBCAThreshold,
} from '../../projects/Report/Widgets/widgetsFunctions/widgetsFunctions.ts';
import { AnimatePresence, motion } from 'framer-motion';

type ThresholdProps = {
  title: string;
  isValid: boolean;
  threshold: number;
};
const Threshold = ({ title, isValid, threshold }: ThresholdProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  return (
    <div className="threshold-wrapper">
      <div className="threshold-title">{title}</div>

      <ThresholdIcon
        isValid={isValid}
        isHovering={isHovering}
        setIsHovering={setIsHovering}
        threshold={threshold}
      />
    </div>
  );
};

type ThresholdIconProps = {
  isValid: boolean;
  isHovering: boolean;
  setIsHovering: (boolean) => void;
  threshold: number;
};
const ThresholdIcon = ({
  isValid,
  isHovering,
  setIsHovering,
  threshold,
}: ThresholdIconProps) => {
  return (
    <div
      className={`threshold ${isValid ? 'valid' : ''} `}
      onMouseOver={() => {
        setIsHovering(true);
      }}
      onMouseOut={() => setIsHovering(false)}
    >
      <div className="threshold-icon">
        {isValid ? <img src={validIcon} alt="" /> : <div>X</div>}
      </div>
      {isHovering && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              opacity: { duration: 0.2 },
            }}
            className={`threshold-tooltip ${isValid ? 'valid' : ''} `}
          >
            {`${threshold} points`}
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};

const Connector = ({ isValid }: { isValid: boolean }) => {
  return (
    <div className="connector-wrapper">
      <div className="connector">
        <div className={`connector-line ${isValid ? 'valid' : ''} `} />
      </div>
    </div>
  );
};

export type CustomBbcaThresholdProps = {
  bbcaScore: number | null;
  buildingTypeId: number | null | undefined;
};
const CustomBbcaThreshold = ({
  bbcaScore,
  buildingTypeId,
}: CustomBbcaThresholdProps) => {
  const achievedThresholdBbca: ThresholdBbcaType[] = getAchievedBBCAThresholds({
    bbcaScore,
    buildingTypeId,
  });
  const isStandardAchieved = achievedThresholdBbca.includes('STANDARD');
  const isEfficientAchieved = achievedThresholdBbca.includes('EFFICIENT');
  const isExcellentAchieved = achievedThresholdBbca.includes('EXCELLENT');

  return (
    <div className="custom-bbca-threshold">
      <Threshold
        title="BBCA Standard"
        isValid={isStandardAchieved}
        threshold={getBBCAThreshold(buildingTypeId, 'STANDARD')}
      />
      <Connector isValid={isEfficientAchieved} />
      <Threshold
        title="BBCA Performant"
        isValid={isEfficientAchieved}
        threshold={getBBCAThreshold(buildingTypeId, 'EFFICIENT')}
      />
      <Connector isValid={isExcellentAchieved} />
      <Threshold
        title="BBCA Excellent"
        isValid={isExcellentAchieved}
        threshold={getBBCAThreshold(buildingTypeId, 'EXCELLENT')}
      />
    </div>
  );
};

export default CustomBbcaThreshold;
