import './Rights.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { Autocomplete } from '@material-ui/lab';
import { Button } from '@photocarbone/nooco-ui';
import { Spin } from 'antd';
import { DatePicker } from 'antd';
import { Modal } from 'react-bootstrap';
import { Select } from '@mui/material';
import { StateDTO } from '../models/GeneralDTO';
import { TextField, Tooltip } from '@material-ui/core';
import {
  deleteCompany,
  fetchAccessHeader,
  fetchCompaniesList,
  fetchOneCompany,
  patchCompany,
  postCompany,
  postEmployee,
  updateRightsValue,
} from '../../actions/rights';
import { divide, isNull } from 'lodash';
import {
  useAddEmployee,
  useDeleteEmployee,
} from '../../hooks/useQuery/rights/crudEmployees';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import API from '../services/API';
import AddIcon from '@material-ui/icons/Add';
import CompanyCard, { returnProjectTypes } from './CompanyCard';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Licence from './Licence/Licence';
import MenuItem from '@mui/material/MenuItem';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import NoocoCheckbox from '../shared/NoocoCheckbox';
import Radio from '@mui/material/Radio';
import React, { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import VirtualizedList from './VirtualizedList';
import debounce from 'lodash.debounce';
import locale from 'antd/es/date-picker/locale/fr_FR';
import dayjs from 'dayjs';
import { findParentCompany } from '../shared/utilsFunction';
import { useTranslation } from 'react-i18next';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const { RangePicker } = DatePicker;

let api = new API(true);

export type AddUserForm = {
  isValidated: boolean;
  error: { mail: boolean; company: boolean };
  mail: string;
  company: '' | { id: number; name: string };
  license: 'Pro' | 'Light' | 'Read';
};

const Rights = () => {
  const dispatch = useDispatch();
  const {
    rightsTabValue,
    rightsActiveType,
    modalAccess,
    companiesOffset,
    companiesList,
    inputSearch,
    parentNodeId,
    sortBy,
    patchCompanyData,
    newCompany,
    categories,
    selectedUser,
    snackbarMessage,
    isSnackbarOpen,
    snackbarSeverity,
    rightsHeaderData,
    selectedCompany,
    selectedParentCompany,
    addUserSelectedCompany,
  } = useSelector((state: StateDTO) => state.rights);
  const { companies, groups, organisations, isSuperAdmin, allCompanies } =
    useSelector((state: StateDTO) => state.projects);
  const [addUserForm, setAddUserForm] = useState<AddUserForm>({
    isValidated: false,
    error: { mail: true, company: true },
    mail: '',
    company: '',
    license: 'Pro',
  });

  const { t } = useTranslation();

  let projectTypes = returnProjectTypes(t);

  const licenceTypeReadCount =
    addUserSelectedCompany?.freeLicenseCount?.find((e) => e.licenseType === 'read')
      .count || 0;
  const licenceTypeLightCount =
    addUserSelectedCompany?.freeLicenseCount?.find((e) => e.licenseType === 'light')
      .count || 0;
  const [displayContract, setDisplayContract] = useState<boolean>(false);
  const deleteEmployee = useDeleteEmployee();
  const addEmployee = useAddEmployee();

  const handleChangeAddUserForm = (name, value) => {
    let isError = addUserForm.error;

    if (name === 'mail') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      isError.mail = !emailRegex.test(value);
    }

    if (name === 'company') {
      isError.company = value === '';
    }

    setAddUserForm({
      ...addUserForm,
      isValidated: true,
      error: isError,
      [name]: value,
    });
  };

  const isAddUserFormValid = (): boolean => {
    return Object.values(addUserForm.error).every(
      (valueError) => valueError === false
    );
  };

  const isNotCompanyChild =
    groups.length === 0 && organisations.length === 0 ? true : false;

  useEffect(() => {
    dispatch(fetchAccessHeader());
    // if (isNotCompanyChild) {
    //   dispatch(updateRightsValue('rightsTabValue',
    //     0));
    // }
  }, []);

  useEffect(() => {
    setAddUserForm({
      ...addUserForm,
      // isValidated: false,
      error: {
        ...addUserForm.error,
        company: false,
      },
      // mail: '',
      company: selectedCompany
        ? selectedCompany
        : selectedParentCompany
        ? selectedParentCompany
        : '',
      // license: 'Pro',
    });
    if (selectedCompany) {
      dispatch(
        fetchOneCompany(findParentCompany(allCompanies, selectedCompany)?.name)
      );
    } else if (selectedParentCompany) {
      dispatch(
        fetchOneCompany(findParentCompany(allCompanies, selectedParentCompany)?.name)
      );
    }
  }, [selectedCompany, selectedParentCompany]);

  const { data, isLoading, isError } = useQuery(
    ['items', companiesOffset],
    async () => {
      const response = await api.getCompaniesList(
        companiesOffset,
        inputSearch,
        parentNodeId
      );

      // console.log(response,
      //   'response ici ou non ?');

      const items = response.data;

      // Ajoutez les hauteurs des éléments à l'objet d'éléments.
      let itemsWithHeights = items.map((item, index) => {
        return {
          ...item,
          height: 210,
          width: 95,
          showChildren: false,
          depth: 1,
          children: [],
        };
      });

      itemsWithHeights = companiesList
        ? [...companiesList, ...itemsWithHeights]
        : itemsWithHeights;

      let uniqueItemsWithHeights = itemsWithHeights.filter((item, index, self) => {
        return index === self.findIndex((obj) => obj.id === item.id);
      });

      dispatch(updateRightsValue('companiesList', uniqueItemsWithHeights));

      return itemsWithHeights;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const debouncedChangeHandler = useCallback(
    debounce(() => {
      dispatch(fetchCompaniesList());
    }, 500),
    []
  );

  const addOrDeleteLicense = (count: number, license: {}) => {
    console.log('count', count);

    const newCompanyCopy = {
      ...newCompany,
      freeLicensesCount: newCompany.freeLicensesCount.map((el) =>
        el.name === license.name ? { ...license, count } : el
      ),
    };

    dispatch(updateRightsValue('newCompany', newCompanyCopy));
  };

  const isModalAccessOpen = (): boolean | undefined => {
    if (modalAccess) {
      return true;
    } else {
      return false;
    }
  };

  const checkModalInputs = () => {
    switch (modalAccess) {
      case 'newCompany':
        {
          if (
            newCompany &&
            (!newCompany.name ||
              !newCompany.type ||
              (!newCompany.contract && displayContract) ||
              !newCompany.projectTypes)
          ) {
            return true;
          }
        }
        break;
      default:
        return false;
    }
  };

  const renderTabContent = () => {
    switch (rightsTabValue) {
      case isNotCompanyChild ? 5 : 0: {
        if (isError) {
          return <div>Error!</div>;
        }

        if (isNotCompanyChild) {
          return <div />;
        }

        return (
          <AnimatePresence initial={true}>
            <motion.div
              style={{ height: '100%' }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.3,
              }}
            >
              {isNotCompanyChild ? (
                <div></div>
              ) : (
                companiesList && <VirtualizedList items={companiesList} />
              )}
            </motion.div>
          </AnimatePresence>
        );
      }
      case isNotCompanyChild ? 0 : 1: {
        return (
          <AnimatePresence initial={false}>
            <div
              className="licenceContainer"

              // style={{
              //   height: '100%',
              //   backgroundColor: 'white'
              // }}
            >
              <Licence />
            </div>
          </AnimatePresence>
        );
      }
    }
  };

  const handleChangeLicenceSortBy = (event) => {
    dispatch(updateRightsValue('sortBy', event.target.value));
  };

  const handleCloseMessage = () => {
    dispatch(updateRightsValue('isSnackbarOpen', false));
    // setIsOpenMessage(false);
  };

  return (
    <>
      <Modal
        show={isModalAccessOpen()}
        onHide={() => dispatch(updateRightsValue('modalAccess', null))}
      >
        {modalAccess === 'editDatas' && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                {t('APP.RIGHTS.RIGHTS.PH_713DDA', {
                  defaultValue: 'Modifier les informations',
                })}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="input-part">
                <p>Renommer</p>
                <TextField
                  style={{ width: '100%' }}
                  placeholder={t('APP.RIGHTS.RIGHTS.PH_08FFAD', {
                    defaultValue: 'Renommer',
                  })}
                  defaultValue={patchCompanyData.name}
                  variant="outlined"
                  className={`input-boarding researchBar`}
                  onChange={(e) =>
                    dispatch(
                      updateRightsValue('patchCompanyData', {
                        ...patchCompanyData,
                        name: e.target.value,
                      })
                    )
                  }
                />
              </div>
              <div className="input-part">
                <p>
                  {t('APP.RIGHTS.RIGHTS.PH_F6505F', {
                    defaultValue: "Modifier le type d'entreprise",
                  })}
                </p>
                <Select
                  style={{ width: '100%' }}
                  defaultValue={patchCompanyData.CompanyCategoryId}
                  variant="outlined"
                  className={`input-boarding researchBar`}
                  onChange={(e) =>
                    dispatch(
                      updateRightsValue('patchCompanyData', {
                        ...patchCompanyData,
                        CompanyCategoryId: e.target.value,
                      })
                    )
                  }
                >
                  {categories?.map((el) => (
                    <MenuItem value={el.id} key={el}>
                      {el.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Modal.Body>
          </>
        )}

        {modalAccess === 'deleteCompany' && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                {t('APP.RIGHTS.RIGHTS.PH_82A659', {
                  defaultValue: 'Supprimer',
                })}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="content-modal">
                <p>
                  {t('APP.RIGHTS.RIGHTS.PH_4FC6B1', {
                    defaultValue: 'Êtes-vous sûr de vouloir supprimer l’entité ?',
                  })}
                  ​​
                </p>
              </div>
            </Modal.Body>
          </>
        )}

        {modalAccess === 'newCompany' && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                {t('APP.RIGHTS.RIGHTS.PH_918780', {
                  defaultValue: 'Ajouter une entreprise',
                })}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="new-company-modal">
              <p>
                {t('APP.RIGHTS.RIGHTS.PH_3DD4A8', {
                  defaultValue: "Nom de l'entreprise",
                })}
              </p>
              <TextField
                style={{ width: '100%' }}
                placeholder="-"
                variant="outlined"
                className={`input-boarding researchBar`}
                onChange={(e) =>
                  dispatch(
                    updateRightsValue('newCompany', {
                      ...newCompany,
                      name: e.target.value,
                    })
                  )
                }
              />

              <p>
                {t('APP.RIGHTS.RIGHTS.PH_F50A36', {
                  defaultValue: 'Rattachement (facultatif)',
                })}
              </p>
              <Autocomplete
                id="tags-standard"
                className="addressBar"
                limitTags={2}
                options={allCompanies.sort((a, b) => -b.name.localeCompare(a.name))}
                getOptionLabel={(option) => option && option.name}
                onChange={(e, newValue) =>
                  dispatch(
                    updateRightsValue('newCompany', {
                      ...newCompany,
                      parentId: newValue?.id,
                    })
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={t('APP.RIGHTS.RIGHTS.PH_CBEF05', {
                      defaultValue: 'Choisir une entreprise de rattachement',
                    })}
                    className="researchBar input-boarding"
                  />
                )}
              />

              <p>
                {t('APP.RIGHTS.RIGHTS.PH_7E143E', {
                  defaultValue: "Type d'entreprise",
                })}
              </p>
              <Select
                defaultValue={'d'}
                style={{ width: '100%' }}
                variant="outlined"
                className={`input-boarding researchBar`}
                onChange={(e) =>
                  dispatch(
                    updateRightsValue('newCompany', {
                      ...newCompany,
                      type: e.target.value,
                    })
                  )
                }
              >
                <MenuItem disabled value={'d'}>
                  <span
                    style={{
                      fontSize: '0.75rem',
                      color: '#aeacac',
                      padding: '8px 6px',
                    }}
                  >
                    {t('APP.RIGHTS.RIGHTS.PH_E64674', {
                      defaultValue: 'Choisir un type d’entreprise',
                    })}
                  </span>
                </MenuItem>
                {categories
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((el) => (
                    <MenuItem value={el.id} key={el}>
                      {el.name}
                    </MenuItem>
                  ))}
              </Select>
              <p>
                {t('APP.RIGHTS.RIGHTS.PH_C0BC2A', {
                  defaultValue: 'Types de projets',
                })}
              </p>
              <Autocomplete
                multiple
                id="tags-standard"
                className="addressBar"
                limitTags={2}
                options={projectTypes}
                getOptionLabel={(option) => option && option.name}
                onChange={(e, newValue) =>
                  dispatch(
                    updateRightsValue('newCompany', {
                      ...newCompany,
                      projectTypes: [...newValue.map((el) => el.ids)].flat(2),
                    })
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={t('APP.RIGHTS.RIGHTS.PH_7860E6', {
                      defaultValue:
                        'Choisir des types de projet accessibles à l’entreprise',
                    })}
                    className="researchBar input-boarding"
                  />
                )}
              />

              <p>
                <NoocoCheckbox
                  checked={displayContract}
                  onChange={(e) => setDisplayContract(e.target.checked)}
                />

                {t('APP.RIGHTS.RIGHTS.PH_7376B3', {
                  defaultValue: "Affecter un contrat à l'entreprise",
                })}
              </p>
              {displayContract && (
                <>
                  <p>
                    {t('APP.RIGHTS.RIGHTS.PH_09E634', {
                      defaultValue: 'Dates de contrat',
                    })}
                  </p>
                  <RangePicker
                    onChange={(e, dates) => {
                      dispatch(
                        updateRightsValue('newCompany', {
                          ...newCompany,
                          contract: {
                            startDate: dayjs(dates[0], 'L').format(),
                            endDate: dayjs(dates[1], 'L').format(),
                          },
                        })
                      );
                    }}
                    className={`permis-form`}
                    format={dayjs.localeData().longDateFormat('L')}
                  />

                  <p>
                    {t('APP.RIGHTS.RIGHTS.PH_9FE4EB', {
                      defaultValue: 'Licences (facultatif)',
                    })}
                  </p>
                  <div style={{ width: '200px', margin: 'auto' }}>
                    {newCompany.freeLicensesCount.map((type) => (
                      <div className="crud-line" key={type.name}>
                        <div
                          className="license-count"
                          style={{ background: type.backgroundColor }}
                        >
                          <p>{type.name}</p>
                          <p>{type.count}</p>
                        </div>
                        <div className="license-action">
                          <p
                            className={`button-action ${
                              type.count === 0 ? 'button-disabled' : ''
                            }`}
                            onClick={() =>
                              type.count === 0
                                ? null
                                : addOrDeleteLicense(type.count - 1, type)
                            }
                          >
                            -
                          </p>
                          <p
                            className={`button-action`}
                            onClick={() => addOrDeleteLicense(type.count + 1, type)}
                          >
                            +
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </Modal.Body>
          </>
        )}

        {modalAccess === 'newUser' && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                {t('APP.RIGHTS.RIGHTS.PH_FF8ABB', {
                  defaultValue: 'Ajouter un utilisateur',
                })}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="new-company-modal">
              <p>
                {t('APP.RIGHTS.RIGHTS.PH_710C8A', {
                  defaultValue: 'Saisir le mail du nouvel utilisateur',
                })}
              </p>
              <TextField
                style={{ width: '100%' }}
                placeholder={t('APP.RIGHTS.RIGHTS.PH_E55363', {
                  defaultValue: 'Mail',
                })}
                variant="outlined"
                className={`input-boarding researchBar ${
                  addUserForm.error.mail === true && addUserForm.isValidated === true
                    ? 'error'
                    : ''
                }`}
                onChange={(e) =>
                  handleChangeAddUserForm('mail', e.currentTarget.value)
                }
              />

              <p>
                {t('APP.RIGHTS.RIGHTS.PH_139A21', {
                  defaultValue: 'Entreprise',
                })}
              </p>
              <Autocomplete
                id="tags-standard"
                className="addressBar"
                limitTags={2}
                defaultValue={
                  selectedCompany
                    ? selectedCompany
                    : selectedParentCompany
                    ? selectedParentCompany
                    : null
                }
                options={allCompanies}
                getOptionLabel={(option) => option && option.name}
                onChange={(e, newValue) => {
                  console.log(newValue);
                  if (newValue) {
                    handleChangeAddUserForm('company', newValue);
                    const parentCompany = findParentCompany(
                      allCompanies,
                      allCompanies.find((el) => el.id === newValue.id)
                    );
                    dispatch(fetchOneCompany(parentCompany.name));
                  } else {
                    handleChangeAddUserForm('company', '');
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={t('APP.RIGHTS.RIGHTS.PH_0339FC', {
                      defaultValue: 'Choisir une entreprise de rattachement',
                    })}
                    className={`researchBar input-boarding ${
                      addUserForm.error.company === true &&
                      addUserForm.isValidated === true
                        ? 'error'
                        : ''
                    }`}
                  />
                )}
              />

              <p>Licence</p>
              <div className="licenseChoiceWrapper">
                <div className="licenseRaw">
                  <Radio
                    checked={addUserForm.license === 'Pro'}
                    onChange={() => handleChangeAddUserForm('license', 'Pro')}
                    value="Pro"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'A' }}
                    sx={{
                      color: '#789ADE',
                      '&.Mui-checked': {
                        color: '#789ADE',
                      },
                    }}
                  />

                  <Tooltip
                    title={t('APP.RIGHTS.RIGHTS.PH_6DD04D', {
                      defaultValue:
                        'Une licence pro donne à accès à toutes les fonctionnalités de la plateforme',
                    })}
                  >
                    <div className="license pro">
                      {t('APP.RIGHTS.RIGHTS.PH_31BCC5', {
                        defaultValue: 'Pro',
                      })}
                    </div>
                  </Tooltip>
                </div>
                {licenceTypeReadCount > 0 && (
                  <div className="licenseRaw">
                    <Radio
                      checked={addUserForm.license === 'Light'}
                      onChange={() => handleChangeAddUserForm('license', 'Light')}
                      value="Light"
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'A' }}
                      sx={{
                        color: '#789ADE',
                        '&.Mui-checked': {
                          color: '#789ADE',
                        },
                      }}
                    />

                    <Tooltip
                      title={t('APP.RIGHTS.RIGHTS.PH_961580', {
                        defaultValue:
                          'Une licence light permet la consultation des projets, l’accès au catalogue Nooco et au simulateur rapide',
                      })}
                    >
                      <div className="license light">Light</div>
                    </Tooltip>
                  </div>
                )}

                {licenceTypeReadCount > 0 && (
                  <div className="licenseRaw">
                    <Radio
                      checked={addUserForm.license === 'Read'}
                      onChange={() => handleChangeAddUserForm('license', 'Read')}
                      value="Read"
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'A' }}
                      sx={{
                        color: '#789ADE',
                        '&.Mui-checked': {
                          color: '#789ADE',
                        },
                      }}
                    />

                    <Tooltip
                      title={t('APP.RIGHTS.RIGHTS.PH_D00864', {
                        defaultValue:
                          'Une licence read only permet exclusivement la consultation des projets de l’entreprise',
                      })}
                    >
                      <div className="license read">Read-only</div>
                    </Tooltip>
                  </div>
                )}
              </div>
              <p className="newUserInformation">
                {t('APP.RIGHTS.RIGHTS.PH_8A6CF0', {
                  defaultValue:
                    'Une fois la licence affectée, vous ne pouvez plus la déplacer pendant une durée de 1 mois',
                })}
              </p>
            </Modal.Body>
          </>
        )}

        {modalAccess === 'deleteEmployee' && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                {t('APP.RIGHTS.RIGHTS.PH_95243D', {
                  defaultValue: 'Supprimer',
                })}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="content-modal">
                <p>
                  {t('APP.RIGHTS.RIGHTS.PH_90A22C', {
                    defaultValue:
                      "Êtes-vous sûr de vouloir supprimer l'utilisateur ?",
                  })}
                  ​​
                </p>
              </div>
            </Modal.Body>
          </>
        )}

        <Modal.Footer>
          <Button
            style={{
              fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
            }}
            onClick={() => {
              dispatch(updateRightsValue("newCompany", {freeLicensesCount: newCompany?.freeLicensesCount}));
              dispatch(updateRightsValue("modalAccess", null))
              setAddUserForm({isValidated: false, error: { mail: true, company: true }, mail: "", company: "", license: "Pro"})
              setDisplayContract(false)
            }}
            className="close-button"
            text={t('APP.RIGHTS.RIGHTS.PH_85E754', { defaultValue: 'Annuler' })}
          />

          {modalAccess === 'newUser' && (
            <Button
              // disabled={checkModalInputs()}
              style={{
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
              }}
              disabled={!isAddUserFormValid()}
              loading={addEmployee.isLoading}
              onClick={() => {
                // dispatch(updateRightsValue('isModalLoading',
                //   true));
                addEmployee.mutate({
                  licenseType: addUserForm.license.toLowerCase(),
                  companyIds: [addUserForm.company.id],
                  email: addUserForm.mail,
                });
                // dispatch(postEmployee({
                //   licenseType: addUserForm.license.toLowerCase(),
                //   companyIds:[addUserForm.company.id],
                //   email:addUserForm.mail
                // }));

                // setTimeout(() => {
                //   setMessage("L'utilisateur à bien été ajouté");
                //   // handleClick(TransitionDown);
                //   setIsOpenMessage(true);
                // },
                // 1000);
              }}
              className="purple-button"
              text={t('APP.RIGHTS.RIGHTS.PH_DF7F89', {
                defaultValue: 'Ajouter',
              })}
            />
          )}

          {modalAccess === 'deleteEmployee' && (
            <Button
              // disabled={checkModalInputs()}
              style={{
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              loading={deleteEmployee.isLoading}
              disabled={deleteEmployee.isLoading}
              onClick={() => {
                deleteEmployee.mutate(selectedUser);
              }}
              // {deleteEmployee.isLoading ? <div style={{marginLeft: '1rem'}}>Supprimer</div> : 'Supprimer'}
              text={t('APP.RIGHTS.RIGHTS.PH_6BBAB2', {
                defaultValue: 'Supprimer',
              })}
              className="delete-button"
            />
          )}

          {modalAccess !== 'newUser' && modalAccess !== 'deleteEmployee' && (
            <Button
              disabled={checkModalInputs()}
              style={{
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
              }}
              onClick={() =>
                modalAccess === 'newCompany'
                  ? dispatch(postCompany())
                  : modalAccess !== 'editDatas'
                  ? dispatch(deleteCompany(patchCompanyData.id))
                  : dispatch(patchCompany())
              }
              className="purple-button"
              text={t('APP.RIGHTS.RIGHTS.PH_A25E0C', {
                defaultValue: 'Confirmer',
              })}
            />
          )}
        </Modal.Footer>
      </Modal>

      {/* <Stack sx={{ width: '100%' }} spacing={2}> */}
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseMessage}
          severity={snackbarSeverity}
          sx={{
            width: '100%',
            backgroundColor:
              snackbarSeverity === 'success' ? 'rgb(237, 247, 237)' : '',
            color: snackbarSeverity === 'success' ? 'gray' : '',
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {/* </Stack> */}

      <div className="rights-wrapper">
        {/* header */}
        <CompanyCard header={true} company={rightsHeaderData?.mainCompany} />

        {/* subHeader */}
        <div className="sub-header">
          {/* tabs */}
          <div className="tabs-rights">
            <Tabs
              value={rightsTabValue}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {!isNotCompanyChild && (
                <Tab
                  label="Entreprises"
                  onClick={() => dispatch(updateRightsValue('rightsTabValue', 0))}
                />
              )}

              <Tab
                label="Licences"
                onClick={() =>
                  dispatch(
                    updateRightsValue('rightsTabValue', isNotCompanyChild ? 0 : 1)
                  )
                }
              />

              <Tooltip
                title={t('APP.RIGHTS.RIGHTS.PH_65A3E8', {
                  defaultValue: 'Bientôt disponible',
                })}
              >
                <div>
                  <Tab
                    disabled
                    label={t('APP.RIGHTS.RIGHTS.PH_87BC77', {
                      defaultValue: 'Paramètres',
                    })}
                  />
                </div>
              </Tooltip>
            </Tabs>
          </div>

          {/* subTabs */}
          <div className="sub-tabs">
            {/* searchBar and  works/exploitations Buttons*/}
            <div className="sub-header-right">
              {/* searchBar */}
              <Input
                disableUnderline={true}
                className="rights-search"
                // onChange={() =>}
                onChange={(event) => {
                  if (parentNodeId) {
                    dispatch(updateRightsValue('parentNodeId', null));
                  }
                  dispatch(updateRightsValue('inputSearch', event.target.value));
                  debouncedChangeHandler();
                }}
                placeholder={t('APP.RIGHTS.RIGHTS.PH_787BA8', {
                  defaultValue: 'Rechercher',
                })}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />

              {/* works and exploitation Buttons */}
              {rightsTabValue === 0 && !isNotCompanyChild && (
                <div className="buttons-type-project">
                  {/* {['Travaux, Exploitation'].map((el) => (
                  <Button key={el} style={{ fontSize: window.screen.availHeight > 950 ? '0.9rem' : undefined, marginRight: '1em' }} shape="round" className={rightsActiveType === el ? "active-button" : undefined} disabled={el === 'Eau' || el === 'Déchets'} >{el}</Button>
                  ))} */}
                </div>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* sort by */}
              {((rightsTabValue === 1 && !isNotCompanyChild) ||
                (rightsTabValue === 0 && isNotCompanyChild)) && (
                <div className="licenceSortByWrapper">
                  <div className="licenceLabel">
                    {t('APP.RIGHTS.RIGHTS.PH_9CCFAC', {
                      defaultValue: 'Regrouper par:',
                    })}
                  </div>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={sortBy}
                    onChange={handleChangeLicenceSortBy}
                    className="licenceSelect"
                  >
                    <MenuItem value="Entreprises">
                      {t('APP.RIGHTS.RIGHTS.PH_D26248', {
                        defaultValue: 'Entreprises',
                      })}
                    </MenuItem>
                    {/* ONLY BY ENTREPRISES FOR THE MOMENT */}
                    {/* <MenuItem value={"Licences"}>Licences</MenuItem> */}
                  </Select>
                </div>
              )}

              {/* new Company Button */}
              {rightsTabValue === 0 && !isNotCompanyChild && isSuperAdmin && (
                <Button
                  onClick={() =>
                    dispatch(updateRightsValue('modalAccess', 'newCompany'))
                  }
                  style={{
                    fontSize: window.screen.availHeight > 950 ? '0.9rem' : undefined,
                    marginRight: '1em',
                    justifySelf: 'flex-end',
                  }}
                  className="purple-button"
                  additionalIcon={<AddIcon />}
                  text={t('APP.RIGHTS.RIGHTS.PH_FE2370', {
                    defaultValue: 'Nouvelle entreprise',
                  })}
                />
              )}

              {/* new User Button */}
              {((rightsTabValue === 1 && !isNotCompanyChild) ||
                (isNotCompanyChild && rightsTabValue === 0)) && (
                <Button
                  onClick={() =>
                    dispatch(updateRightsValue('modalAccess', 'newUser'))
                  }
                  style={{
                    fontSize: window.screen.availHeight > 950 ? '0.9rem' : undefined,
                    marginRight: '1em',
                    justifySelf: 'flex-end',
                  }}
                  className="purple-button"
                  additionalIcon={<AddIcon />}
                  text={t('APP.RIGHTS.RIGHTS.PH_CB9D8C', {
                    defaultValue: 'Nouvel Utilisateur',
                  })}
                />
              )}
            </div>
          </div>
        </div>

        {/* tab content */}
        <div className={`tab-content ${rightsTabValue === 1 ? 'licence' : ''}`}>
          {renderTabContent()}
        </div>
      </div>
    </>
  );
};
export default Rights;
