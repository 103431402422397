import './transportAndGear.scss';
import { Autocomplete } from '@mui/material';
import { Button } from '@photocarbone/nooco-ui';
import { StateDTO } from '../../../../../models/GeneralDTO';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import SelectUi from '@mui/material/Select';
import TextField from '@material-ui/core/TextField';
import { calculatorUpdate } from '../../../../../../actions/calculator';
import { isNumOrNull } from '../../../../../shared/utilsFunction';
import add from '../../../../../../assets/images/exploitation/add.png';
import bus from '../../../../../../assets/images/exploitation/transports/bus.png';
import metro from '../../../../../../assets/images/exploitation/transports/metro.png';
import plane from '../../../../../../assets/images/exploitation/transports/plane.png';
import train from '../../../../../../assets/images/exploitation/transports/train.png';
import transportIcon from '../../../../../../assets/images/exploitation/transportIcon.png';
import velo from '../../../../../../assets/images/exploitation/transports/velo.png';
import voiture from '../../../../../../assets/images/exploitation/transports/voiture.png';
import { useFormContext } from 'react-hook-form';
import useCaseWorksite from './UseCaseWorksite';
import { useTranslation } from 'react-i18next';

const WorksiteTransport = ({ changeSourceOfEmptyRows: changeSourceOfEmptyRows }) => {
  const _ = require('lodash');
  const { handleSubmit } = useFormContext();
  const {
    isValidFormTransport,
    calculateAreaZoneProrata,
    isDisplayEquipment,
    isEquipmentFromParentZone,
    isEquipmentEditable,
    onSubmitScreen,
    zoneSourceToDisplay,
  } = useCaseWorksite();

  const {
    transportLines,
    worksiteCalculatorDetailedActive,
    isTransportErrorDisplay,
    transportEquipments,
  } = useSelector((state: StateDTO) => state.projects.calculator);
  const { zoneSelected } = useSelector(
    (state: StateDTO) => state.monitoring.breadcrumbMultizone
  );
  const { zones } = useSelector((state: StateDTO) => state.projects.parsedProject);
  // const { allGroups } = useSelector((state: StateDTO) => state.projects);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  transportEquipments.map((equipement) => {
    equipement.Caracs.map((carac) => {
      if (carac.values.length === 1) {
        carac.value = carac.values;
      }

      return equipement;
    });
  });

  const onSubmit = (data) => {
    if (isValidFormTransport()) {
      dispatch(calculatorUpdate('isTransportErrorDisplay', false));
      changeSourceOfEmptyRows({
        zone: zoneSelected,
        sideBarActiveId: worksiteCalculatorDetailedActive + 1,
      });
      dispatch(
        calculatorUpdate(
          'worksiteCalculatorDetailedActive',
          worksiteCalculatorDetailedActive + 1
        )
      );
    } else {
      dispatch(calculatorUpdate('isTransportErrorDisplay', true));
      onSubmitScreen(data, 'next');
    }
  };

  const onError = (errors) => {
    console.log('validation errors transport', errors);
  };

  const onSubmitPrevious = (data) => {
    if (isValidFormTransport()) {
      onSubmitScreen(data, 'previous');
    } else {
      dispatch(calculatorUpdate('isTransportErrorDisplay', true));
    }
  };

  const handleEditTransport = (newValue: {}): void => {
    const newValueCopy = _.cloneDeep(newValue);
    const transportLinesCopy = _.cloneDeep(transportLines);

    const newLines = transportLinesCopy.map((transportLine: {}) => {
      if (transportLine?.id === newValueCopy?.id) {
        // 'Je suis dans la condition';

        return newValueCopy;
      }

      return transportLine;
    });

    // console.log('newLines',
    //   _.cloneDeep(newLines));
    dispatch(calculatorUpdate('transportLines', newLines));
  };

  const addNewTransport = (): void => {
    const test = _.cloneDeep(transportLines);

    const newLines = [
      ...test,
      {
        id: Math.max(...transportLines.map((el) => el.id)) + 1,
        MasterDataEquipmentId: null,
        quantity: null,
        type: null,
        unit: 'km',
        carburant: null,
        caracs: [],
        caracCombo: null,
        origin: {
          type: zoneSelected?.type || 'project',
          sourceId: zoneSelected?.id || null,
          uniqueId: uuidv4(),
        },
      },
    ];

    dispatch(calculatorUpdate('transportLines', newLines));
  };
  const addNewTransportCard = (defaultMasterDataEquipmentId: number): void => {
    const equipmentSelected = _.cloneDeep(
      transportEquipments.find(
        (equipment) => equipment.id === defaultMasterDataEquipmentId
      )
    );

    const transportLinesCopy = _.cloneDeep(transportLines);

    if (transportLinesCopy.find((line) => !line.MasterDataEquipmentId)) {
      handleEditTransport({
        // id: transportLinesCopy.find((line) => !line.MasterDataEquipmentId).id,
        // id: line.id,
        // id: 0,
        ...transportLines.find((line) => !line.MasterDataEquipmentId),
        MasterDataEquipmentId: equipmentSelected.id,
        // quantity: null,
        caracs: equipmentSelected?.Caracs,
        // type: null ,
        // carburant: null,
        // unit: 'km',
        caracCombo: equipmentSelected?.caracCombo || null,
        origin: {
          type: zoneSelected?.type || 'project',
          sourceId: zoneSelected?.id || null,
          uniqueId: uuidv4(),
        },
      });
    } else {
      const newLines = [
        ...transportLinesCopy,
        {
          id: Math.max(...transportLinesCopy.map((line) => line.id)) + 1,
          MasterDataEquipmentId: equipmentSelected?.id,
          type: null,
          carburant: null,
          unit: 'km',
          caracs: equipmentSelected?.Caracs || null,
          caracCombo: equipmentSelected?.caracCombo || null,
          origin: {
            type: zoneSelected?.type || 'project',
            sourceId: zoneSelected?.id || null,
            uniqueId: uuidv4(),
          },
        },
      ];

      console.log('newLines', newLines);

      dispatch(calculatorUpdate('transportLines', newLines));
    }
  };

  return (
    <>
      {/* transport wrapper */}
      <div className="transportCalculatorWrapper">
        <div className="transport">
          {/* cards wrapper */}
          <div className="iconWrapper">
            <div
              className="transport-item"
              onClick={() =>
                addNewTransportCard(
                  transportEquipments.find((el) => el.name === 'Véhicule routier').id
                )
              }
            >
              <img src={voiture} alt="" />
              <p>
                {t('APP.CALCULATORWORKSITECONTENT.WORKSITETRANSPORT.PH_2EA899', {
                  defaultValue: 'Véhicule routier',
                })}
              </p>
            </div>
            <div
              className="transport-item"
              onClick={() =>
                addNewTransportCard(
                  transportEquipments.find((el) => el.name === 'Métro / Tramway').id
                )
              }
            >
              <img src={metro} alt="" />
              <p>
                {t('APP.CALCULATORWORKSITECONTENT.WORKSITETRANSPORT.PH_4DDA70', {
                  defaultValue: 'Métro / Tramway',
                })}
              </p>
            </div>
            <div
              className="transport-item"
              onClick={() =>
                addNewTransportCard(
                  transportEquipments.find((el) => el.name === 'Vélo').id
                )
              }
            >
              <img src={velo} alt="" />
              <p>
                {t('APP.CALCULATORWORKSITECONTENT.WORKSITETRANSPORT.PH_C73C38', {
                  defaultValue: 'Vélo',
                })}
              </p>
            </div>
            <div
              className="transport-item"
              onClick={() =>
                addNewTransportCard(
                  transportEquipments.find((el) => el.name === 'Autobus / Autocar')
                    .id
                )
              }
            >
              <img src={bus} alt="" />
              <p>
                {t('APP.CALCULATORWORKSITECONTENT.WORKSITETRANSPORT.PH_402826', {
                  defaultValue: 'Autobus / Autocar',
                })}
              </p>
            </div>
            <div
              className="transport-item"
              onClick={() =>
                addNewTransportCard(
                  transportEquipments.find((el) => el.name === 'Train').id
                )
              }
            >
              <img src={train} alt="" />
              <p>
                {t('APP.CALCULATORWORKSITECONTENT.WORKSITETRANSPORT.PH_2E5262', {
                  defaultValue: 'Train',
                })}
              </p>
            </div>
            <div
              className="transport-item"
              onClick={() =>
                addNewTransportCard(
                  transportEquipments.find((el) => el.name === 'Avion').id
                )
              }
            >
              <img src={plane} alt="" />
              <p>
                {t('APP.CALCULATORWORKSITECONTENT.WORKSITETRANSPORT.PH_A12582', {
                  defaultValue: 'Avion',
                })}
              </p>
            </div>
          </div>
          {/* lines wrapper */}
          <div className="transport-add">
            <div className="transport-lines-header">
              <p style={{ width: '3%' }}></p>
              <p style={{ width: '18%', paddingRight: '30px' }}>Type de transport</p>
              <p style={{ width: '15%' }}>Quantité</p>
              <p style={{ width: '10%' }}>Unité</p>
              <p style={{ width: '17%' }}>Type</p>
              <p style={{ width: '17%' }}>Carburant</p>
              <p style={{ width: '12%' }}>Source</p>
              <p style={{ width: '2%' }}></p>
            </div>
            <div className="transport-lines">
              {transportLines.map((transportLine: any, lineIndex) => {
                if (isEquipmentEditable(transportLine)) {
                  return (
                    <div className="transport-line" key={`line-${lineIndex}`}>
                      {/* Logo Add new line */}
                      {/* <div style={{ position: 'absolute', left: '3px' }}> */}
                      <div style={{ width: '3%' }}>
                        {lineIndex === transportLines.length - 1 && (
                          <img
                            onClick={() => addNewTransport()}
                            style={{
                              width: '25px',
                              height: '25px',
                              marginTop: '10px',
                              marginRight: '20px',
                              cursor: 'pointer',
                            }}
                            src={add}
                          />
                        )}
                      </div>
                      {/* Autocomplete Type Vehicule/gear */}
                      <div style={{ width: '18%' }}>
                        <Autocomplete
                          value={transportEquipments.find(
                            (masterData) =>
                              masterData?.id === transportLine?.MasterDataEquipmentId
                          )}
                          // defaultValue={transportEquipments.find((masterData) => masterData.id === transportLine.MasterDataEquipmentId)}
                          key={transportLine.MasterDataEquipmentId}
                          // key={`autocomplete-${transportLines} ${lineIndex}`}

                          // key={transportLines}

                          // key={uuidv4()}

                          // id="checkboxes-tags-demo"
                          options={transportEquipments}
                          noOptionsText={t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITETRANSPORT.PH_8B89F5',
                            { defaultValue: 'Aucun résultat trouvé' }
                          )}
                          onChange={(event, newValue, reason) => {
                            // let caracs = [];

                            if (newValue) {
                              // caracs = returnCaracsForMd(newValue?.id);
                              handleEditTransport({
                                ...transportLine,
                                MasterDataEquipmentId: newValue?.id,
                                caracs: newValue.Caracs,
                                caracCombo: newValue.caracCombo,
                              });
                            } else if (newValue === null) {
                              const newTransportLines = [...transportLines];

                              newTransportLines[lineIndex] = {
                                id:
                                  Math.max(...transportLines.map((el) => el.id)) + 1,
                                MasterDataEquipmentId: null,
                                quantity: null,
                                type: null,
                                carburant: null,
                                unit: 'km',
                                caracs: [],
                                caracCombo: null,
                                origin: {
                                  type: zoneSelected?.type || 'project',
                                  sourceId: zoneSelected?.id || null,
                                  uniqueId: uuidv4(),
                                },
                              };
                              dispatch(
                                calculatorUpdate('transportLines', newTransportLines)
                              );
                            } else if (reason === 'clear') {
                              const newTransportLines = [...transportLines];

                              newTransportLines[lineIndex] = {
                                id:
                                  Math.max(...transportLines.map((el) => el.id)) + 1,
                                MasterDataEquipmentId: null,
                                quantity: null,
                                type: null,
                                carburant: null,
                                unit: 'km',
                                caracs: [],
                                caracCombo: null,
                                origin: {
                                  type: zoneSelected?.type || 'project',
                                  sourceId: zoneSelected?.id || null,
                                  uniqueId: uuidv4(),
                                },
                              };
                              dispatch(
                                calculatorUpdate('transportLines', newTransportLines)
                              );
                            }

                            // ;
                            // dispatch(calculatorUpdate('transportLines',
                            //   newTransportLines));
                            // dispatch(calculatorUpdate('isTransportErrorDisplay',
                            // false));
                            // }

                            // handleEditTransport({...transportLine, MasterDataEquipmentId: newValue?.id, caracs,});
                          }}
                          size={'small'}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              // key={transportLines}

                              // key={`textfield-${transportLines}-autocomplete`}
                              // key={`autocomplete-${transportLines} ${lineIndex}`}

                              // key={`autocomplete-${lineIndex}`}
                              // defaultValue={transportEquipments.find((masterData) => masterData.id === transportLine.MasterDataEquipmentId)}
                              style={{ borderRadius: '10px' }}
                              {...params}
                              placeholder={t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITETRANSPORT.PH_72B9CE',
                                { defaultValue: 'Ajouter un transport' }
                              )}
                              variant="outlined"
                              className="textFieldV1"
                              error={
                                isTransportErrorDisplay &&
                                !transportLine?.MasterDataEquipmentId &&
                                transportLine?.quantity
                              }
                            />
                          )}
                        />
                      </div>
                      {/* quantity textfield */}
                      <div
                        style={{ width: '15%' }}
                        // key={uuidv4()}
                      >
                        <TextField
                          key={`${transportLine}-${lineIndex}`}
                          // key={`textfield-${lineIndex}-autocomplete`}

                          // key={uuidv4()}

                          // value={transportLine.quantity}
                          value={transportLines[lineIndex].quantity}
                          onChange={(e) => {
                            // transportLine.quantity = Number(e.target.value);
                            // handleEditTransport(transportLine);
                            handleEditTransport({
                              ...transportLine,
                              quantity: e.target.value,
                            });
                          }}
                          // type="number"
                          style={{ width: '100%' }}
                          placeholder={t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITETRANSPORT.PH_E2A366',
                            { defaultValue: 'Quantité' }
                          )}
                          variant="outlined"
                          className="textFieldV1"
                          error={
                            (isTransportErrorDisplay &&
                              !transportLine.quantity &&
                              transportLine.MasterDataEquipmentId) ||
                            !isNumOrNull(transportLines[lineIndex].quantity)
                          }
                        />
                      </div>
                      {/* unit */}
                      <div
                        style={{
                          width: '10%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {/* <p 
                                 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                 > */}
                        km
                        {/* </p> */}
                      </div>
                      {/* type */}
                      {transportLine.caracs &&
                        transportLine.caracs?.find(
                          (carac) => carac.name === 'Type'
                        ) && (
                          <div
                            style={{ width: '17%' }}
                            // key={uuidv4()}
                          >
                            <SelectUi
                              // key={uuidv4()}

                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              defaultValue={
                                transportLine?.caracs?.find(
                                  (carac) => carac?.name === 'Type'
                                )?.value
                              }
                              onChange={(e) => {
                                const transportLineUpdate = transportLine;

                                transportLineUpdate.caracs.find(
                                  (carac) => carac.name === 'Type'
                                ).value = [e.target.value];
                                handleEditTransport(transportLineUpdate);
                              }}
                              // ref={transportLine}
                              // ref={[`nimp${lineIndex}`]}
                              // value={transportLine?.caracs?.find((carac) => carac?.name === "Type" )?.value || transportLine?.caracs?.find((carac) => carac?.name === "Type" )?.values[0]  || ""}
                              style={{ width: '100%', height: '40px' }}
                              placeholder="  -"
                              variant="outlined"
                              className="selectUiV1"
                              error={
                                isTransportErrorDisplay &&
                                // transportLine?.caracs?.find((carac) => carac?.name === "Type" )?.value[0]
                                // ||
                                transportLine?.caracs?.find(
                                  (carac) => carac?.name === 'Type'
                                ) &&
                                !transportLine?.caracs?.find(
                                  (carac) => carac?.name === 'Type'
                                )?.value
                              }
                              // className="input-boarding researchBar"
                            >
                              {transportLine.caracs
                                .find((carac) => carac.name === 'Type')
                                .values.map((value, i) => (
                                  <MenuItem
                                    value={value}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                    key={`${value}-${i}`}
                                  >
                                    {value}
                                  </MenuItem>
                                ))}
                            </SelectUi>
                          </div>
                        )}
                      {/* carburant */}
                      {transportLine?.caracs?.find(
                        (carac) => carac?.name === 'Motorisation'
                      ) && (
                        <div
                          style={{ width: '17%' }}
                          // key={uuidv4()}
                        >
                          <SelectUi
                            // key={uuidv4()}
                            // key={`${transportLine}-${lineIndex} item`}
                            disabled={
                              !transportLine?.caracs?.find(
                                (carac) => carac?.name === 'Type'
                              )?.value
                            }
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            defaultValue={
                              transportLine?.caracs?.find(
                                (carac) => carac?.name === 'Motorisation'
                              )?.value
                              // ""
                            }
                            onChange={(e) => {
                              const transportLineUpdate = _.cloneDeep(transportLine);

                              console.log(
                                'transportLineUpdate',
                                transportLineUpdate
                              );
                              console.log('e.target.value', e.target.value);

                              const test = [];

                              console.log(typeof e.target.value);
                              test.push(e.target.value);

                              transportLineUpdate.caracs.find(
                                (carac) => carac.name === 'Motorisation'
                              ).value = test;
                              console.log(
                                'transportLineUpdate',
                                transportLineUpdate
                              );
                              handleEditTransport(transportLineUpdate);
                            }}
                            style={{ width: '100%', height: '40px' }}
                            placeholder="  -"
                            variant="outlined"
                            className="selectUiV1"
                            error={
                              isTransportErrorDisplay &&
                              transportLine?.caracs?.find(
                                (carac) => carac?.name === 'Type'
                              )?.value &&
                              !transportLine?.caracs?.find(
                                (carac) => carac?.name === 'Motorisation'
                              )?.value
                            }
                          >
                            {transportLine?.caracCombo?.valueList
                              .find(
                                (listOfCaracCombo) =>
                                  listOfCaracCombo.value ===
                                  transportLine?.caracs?.find(
                                    (carac) => carac?.name === 'Type'
                                  )?.value?.[0]
                              )
                              ?.valueList?.map((value, i) => (
                                // transportLine.caracs.find((carac) => carac.name === "Motorisation" ).values.map((value) => (
                                // {console.log('value', value)}
                                <MenuItem
                                  value={value.value}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  key={`${value}-${i}`}
                                >
                                  {value.value}
                                </MenuItem>
                              ))}
                          </SelectUi>
                        </div>
                      )}
                      {/* optional empty columns for keep proportion of row */}
                      {transportLine.caracs && transportLine.caracs.length === 1 && (
                        <div style={{ width: '17%' }}></div>
                      )}
                      {(!transportLine.caracs ||
                        transportLine.caracs?.length === 0) && (
                        <>
                          <div style={{ width: '17%' }}></div>
                          <div style={{ width: '17%' }}></div>
                        </>
                      )}
                      {/* source */}
                      <div
                        style={{
                          width: '12%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        className="sourceCell"
                      >
                        {zoneSourceToDisplay(transportLine.origin)}
                      </div>
                      {/* delete row icon */}
                      <div
                        style={{
                          width: '2%',
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '10px',
                        }}
                      >
                        <DeleteIcon
                          onClick={() =>
                            dispatch(
                              calculatorUpdate(
                                'transportLines',
                                transportLines.filter(
                                  (line) => line.id !== transportLine.id
                                )
                              )
                            )
                          }
                          style={{
                            display: transportLines.length > 1 ? 'block' : 'none',
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                    </div>
                  );
                }

                if (isDisplayEquipment(transportLine)) {
                  let quantity = transportLine.quantity;

                  if (isEquipmentFromParentZone(transportLine)) {
                    const quantityProrata = calculateAreaZoneProrata(transportLine);
                    quantity =
                      Math.round(quantity * quantityProrata * 10000) / 10000;
                  }

                  return (
                    <div
                      className="transport-line disabled"
                      key={`line-${lineIndex}`}
                    >
                      {/* Logo Add new line */}
                      <div style={{ width: '3%' }}>
                        {lineIndex === transportLines.length - 1 && (
                          <img
                            onClick={() => addNewTransport()}
                            style={{
                              width: '25px',
                              height: '25px',
                              marginTop: '10px',
                              marginRight: '20px',
                              cursor: 'pointer',
                            }}
                            src={add}
                          />
                        )}
                      </div>
                      {/* Autocomplete Type Vehicule/gear */}
                      <div style={{ width: '18%' }}>
                        <Autocomplete
                          value={transportEquipments.find(
                            (masterData) =>
                              masterData?.id === transportLine?.MasterDataEquipmentId
                          )}
                          // defaultValue={transportEquipments.find((masterData) => masterData.id === transportLine.MasterDataEquipmentId)}
                          key={transportLine.MasterDataEquipmentId}
                          options={transportEquipments}
                          noOptionsText={t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITETRANSPORT.PH_21B91A',
                            { defaultValue: 'Aucun résultat trouvé' }
                          )}
                          size={'small'}
                          getOptionLabel={(option) => option.name}
                          disabled={true}
                          renderInput={(params) => (
                            <TextField
                              style={{ borderRadius: '10px' }}
                              {...params}
                              placeholder={t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITETRANSPORT.PH_B2477B',
                                { defaultValue: 'Ajouter un transport' }
                              )}
                              variant="outlined"
                              className="textFieldV1"
                              disabled={true}
                            />
                          )}
                        />
                      </div>
                      {/* quantity textfield */}
                      <div style={{ width: '15%' }}>
                        <TextField
                          key={`${transportLine}-${lineIndex}`}
                          disabled={true}
                          value={quantity}
                          style={{ width: '100%' }}
                          placeholder={t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITETRANSPORT.PH_B1EA45',
                            { defaultValue: 'Quantité' }
                          )}
                          variant="outlined"
                          className="textFieldV1"
                        />
                      </div>
                      {/* unit */}
                      <div
                        style={{
                          width: '10%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {/* <p 
                                 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                 > */}
                        km
                        {/* </p> */}
                      </div>
                      {/* type */}
                      {transportLine.caracs &&
                        transportLine.caracs?.find(
                          (carac) => carac.name === 'Type'
                        ) && (
                          <div
                            style={{ width: '17%' }}
                            // key={uuidv4()}
                          >
                            <SelectUi
                              // key={uuidv4()}

                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              defaultValue={
                                transportLine?.caracs?.find(
                                  (carac) => carac?.name === 'Type'
                                )?.value
                              }
                              disabled={true}
                              style={{ width: '100%', height: '40px' }}
                              placeholder="  -"
                              variant="outlined"
                              className="selectUiV1"
                            >
                              {transportLine.caracs
                                .find((carac) => carac.name === 'Type')
                                .values.map((value, i) => (
                                  <MenuItem
                                    value={value}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                    key={`${value}-${i}`}
                                  >
                                    {value}
                                  </MenuItem>
                                ))}
                            </SelectUi>
                          </div>
                        )}
                      {/* carburant */}
                      {transportLine?.caracs?.find(
                        (carac) => carac?.name === 'Motorisation'
                      ) && (
                        <div
                          style={{ width: '17%' }}
                          // key={uuidv4()}
                        >
                          <SelectUi
                            disabled={true}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            defaultValue={
                              transportLine?.caracs?.find(
                                (carac) => carac?.name === 'Motorisation'
                              )?.value
                            }
                            style={{ width: '100%', height: '40px' }}
                            placeholder="  -"
                            variant="outlined"
                            className="selectUiV1"
                          >
                            {transportLine?.caracCombo?.valueList
                              .find(
                                (listOfCaracCombo) =>
                                  listOfCaracCombo.value ===
                                  transportLine?.caracs?.find(
                                    (carac) => carac?.name === 'Type'
                                  )?.value?.[0]
                              )
                              ?.valueList?.map((value, i) => (
                                // transportLine.caracs.find((carac) => carac.name === "Motorisation" ).values.map((value) => (
                                // {console.log('value', value)}
                                <MenuItem
                                  value={value.value}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  key={`${value}-${i}`}
                                >
                                  {value.value}
                                </MenuItem>
                              ))}
                          </SelectUi>
                        </div>
                      )}

                      {/* optional empty columns for keep proportion of row */}
                      {transportLine.caracs && transportLine.caracs.length === 1 && (
                        <div style={{ width: '17%' }}></div>
                      )}

                      {(!transportLine.caracs ||
                        transportLine.caracs?.length === 0) && (
                        <>
                          <div style={{ width: '17%' }}></div>
                          <div style={{ width: '17%' }}></div>
                        </>
                      )}

                      {/* source */}
                      <div
                        style={{
                          width: '12%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        className="sourceCell disabled"
                      >
                        {zoneSourceToDisplay(transportLine.origin)}
                      </div>
                      {/* delete row icon */}
                      <div
                        style={{
                          width: '2%',
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '10px',
                        }}
                      >
                        {/* <DeleteIcon 
                                 style={{ display: transportLines.length > 1 ? 'block' : 'none', cursor: 'pointer' }} 
                                 /> */}
                      </div>
                    </div>
                  );
                }

                return null;
              })}
            </div>
          </div>
        </div>
      </div>
      {/* bottom buttons*/}
      <div
        className="calculatorFlexColumnStart"
        style={{
          flexGrow: 1,
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }}
      >
        <div className="calculatorFlexStart" style={{ justifyContent: 'flex-end' }}>
          <Button
            style={{
              fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
            }}
            className="close-button"
            onClick={handleSubmit(onSubmitPrevious, onError)}
            text={t('APP.CALCULATORWORKSITECONTENT.WORKSITETRANSPORT.PH_BCB97A', {
              defaultValue: 'Précédent',
            })}
          />

          <Button
            style={{
              fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
              marginLeft: '5px',
            }}
            className="purple-button"
            onClick={handleSubmit(onSubmit, onError)}
            text={t('APP.CALCULATORWORKSITECONTENT.WORKSITETRANSPORT.PH_4B5A5E', {
              defaultValue: 'Suivant',
            })}
          />
        </div>
      </div>
    </>
  );
};

export default WorksiteTransport;
