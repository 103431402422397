import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '@photocarbone/nooco-ui';
import { StateDTO } from '../../../../../models/GeneralDTO';
import { Tooltip } from '@material-ui/core';
import { decimalNumberRegex } from '../../../../../shared/Regex/regex.js';
import {
  findAllAllotmentNodeChildrenFromFilteredParentAllotmentNodes,
  findAllAllotmentNodeChildrenFromFilteredParentAllotmentNodesSelectedLevel,
  findParentNodeWithSelectLevel,
} from '../../../../../shared/utils';
import { timeEnd, timeLog } from 'console';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import Select from '@material-ui/core/Select';
import informationPic from '../../../../../../assets/images/equipments/information.png';
import { useTranslation } from 'react-i18next';
import { getModelingUnitOption } from '../../../../../bundle/BundleList/BundleCard/BundleDetails/index.tsx';

const Caracteristic = () => {
  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useFormContext();
  const { allGroups } = useSelector((state: StateDTO) => state.projects);

  const handleBlur = () => {};
  const { t } = useTranslation();

  const handleBlurError = (error) => {
    console.log('error', error);
  };

  return (
    <div className="caracteristicWrapper">
      <div className="formElementWrapper">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="caracteristicLabel">
            {t('APP.CARACTERISTIC.CARACTERISTIC.PH_51B292', {
              defaultValue: "Nom de l'équipement",
            })}
          </div>
          <Tooltip
            title={t('APP.CARACTERISTIC.CARACTERISTIC.PH_49C065', {
              defaultValue: "Saisir le nom de l'équipement à modéliser",
            })}
          >
            <img className="informationPic" src={informationPic} alt="" />
          </Tooltip>
        </div>
        <Controller
          name="equipmentName"
          control={control}
          rules={{
            required: t('APP.CARACTERISTIC.CARACTERISTIC.PH_6D50A9', {
              defaultValue: 'Veuillez renseigner un nom.',
            }),
          }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder={t('APP.CARACTERISTIC.CARACTERISTIC.PH_D4A690', {
                defaultValue: "Nom de l'équipement",
              })}
              errorText={
                dirtyFields?.equipmentName ? errors?.equipmentName?.message : ''
              }
              // key={'test'}
              width={'550px'}
              disabledDebouncing={true}
              error={errors?.equipmentName?.message ? true : false}
              onBlur={handleSubmit(
                () => handleBlur(),
                (e) => handleBlurError(e)
              )}
            />
          )}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <div className="formElementWrapper">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="caracteristicLabel">
              {t('APP.CARACTERISTIC.CARACTERISTIC.PH_25E9C4', {
                defaultValue: 'Quantité',
              })}
            </div>
            <Tooltip
              title={t('APP.CARACTERISTIC.CARACTERISTIC.PH_E918A3', {
                defaultValue:
                  "Saisir la quantité d'équipement dans l’unité sélectionnée",
              })}
            >
              <img className="informationPic" src={informationPic} alt="" />
            </Tooltip>
          </div>
          <Controller
            name="quantity"
            control={control}
            rules={{
              required: t('APP.CARACTERISTIC.CARACTERISTIC.PH_3B2D1A', {
                defaultValue: 'Veuillez renseigner une quantité',
              }),
              pattern: {
                value: /^[1-9]\d*([.,]\d+)?$/,
                message: t('APP.CARACTERISTIC.CARACTERISTIC.PH_9E926C', {
                  defaultValue: 'Veuillez renseigner une quantité',
                }),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                // type={"number"}
                placeholder={t('APP.CARACTERISTIC.CARACTERISTIC.PH_8AEF0D', {
                  defaultValue: 'Quantité',
                })}
                errorText={dirtyFields?.quantity ? errors?.quantity?.message : ''}
                // key={'test'}
                width={'230px'}
                defaultValue={field.value}
                value={null}
                error={errors?.quantity?.message ? true : false}
                onBlur={handleSubmit(
                  () => handleBlur(),
                  (e) => handleBlurError(e)
                )}
              />
            )}
          />
        </div>
        <div className="formElementWrapper">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="caracteristicLabel">
              {t('APP.CARACTERISTIC.CARACTERISTIC.PH_1B67D2', {
                defaultValue: 'Unité',
              })}
            </div>
            <Tooltip
              title={t('APP.CARACTERISTIC.CARACTERISTIC.PH_74BE48', {
                defaultValue: "Choisir l’unité de l'équipement à modéliser",
              })}
            >
              <img className="informationPic" src={informationPic} alt="" />
            </Tooltip>
          </div>
          <Controller
            name="unit"
            control={control}
            rules={{
              required: t('APP.CARACTERISTIC.CARACTERISTIC.PH_0F8812', {
                defaultValue: 'Veuillez selectionner une unité',
              }),
            }}
            render={({ field }) => (
              <FormControl
                // className={classes.formControl}
                variant="outlined"
              >
                <Select
                  className="selectUiV1"
                  style={{ width: '100px' }}
                  error={errors?.unit?.message ? true : false}
                  onClick={handleSubmit(
                    () => handleBlur(),
                    (e) => handleBlurError(e)
                  )}
                  {...field}
                >
                  <MenuItem value={''} aria-label="None"></MenuItem>
                  {getModelingUnitOption(allGroups).map((product) => {
                    return (
                      <MenuItem value={product.unit} key={product.id}>
                        {product.unit}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          />
        </div>
      </div>
      <div className="formElementWrapper">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="caracteristicLabel">
            {t('APP.CARACTERISTIC.CARACTERISTIC.PH_576464', {
              defaultValue: "Durée de vie de l'équipement (an)",
            })}
          </div>
          <Tooltip
            title={t('APP.CARACTERISTIC.CARACTERISTIC.PH_00E4AA', {
              defaultValue:
                "Saisir la durée de vie de l'équipement au bout de laquelle il devra être remplacé",
            })}
          >
            <img className="informationPic" src={informationPic} alt="" />
          </Tooltip>
        </div>
        <Controller
          name="lifeSpan"
          control={control}
          // {...register("lifeSpan",
          //   {
          //     valueAsNumber: true,
          //   })}
          rules={{
            required: t('APP.CARACTERISTIC.CARACTERISTIC.PH_50797D', {
              defaultValue: 'Veuillez renseigner une durée de vie',
            }),
            // pattern: {
            //   value: /^[1-9]\d*([.,]\d+)?$/,
            //   message: 'Veuillez renseigner une quantité',
          }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder={t('APP.CARACTERISTIC.CARACTERISTIC.PH_E7EB66', {
                defaultValue: "Durée de vie de l'équipement",
              })}
              type={'number'}
              errorText={dirtyFields?.lifeSpan ? errors?.lifeSpan?.message : ''}
              // key={'test'}

              width={'230px'}
              error={errors?.lifeSpan?.message ? true : false}
              onBlur={handleSubmit(
                () => handleBlur(),
                (e) => handleBlurError(e)
              )}
            />
          )}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <div className="formElementWrapper">
          <div className="caracteristicLabel">
            {t('APP.CARACTERISTIC.CARACTERISTIC.PH_3DD853', {
              defaultValue: 'Commentaire (facultatif)',
            })}
          </div>
          <Tooltip
            title={t('APP.CARACTERISTIC.CARACTERISTIC.PH_42E162', {
              defaultValue: 'Ajouter un commentaire',
            })}
            placement="top"
          >
            <Controller
              name="comment"
              control={control}
              // rules={{required: 'Veuillez renseigner un nom.'}}
              render={({ field }) => (
                <Input
                  {...field}
                  defaultValue={field.value}
                  value={null}
                  // type={"number"}
                  placeholder="Optionnel"
                  errorText={dirtyFields?.comment ? errors?.comment?.message : ''}
                  // key={'test'}
                  width={'280px'}
                  error={errors?.comment?.message ? true : false}
                  onBlur={handleSubmit(
                    () => handleBlur(),
                    (e) => handleBlurError(e)
                  )}
                />
              )}
            />
          </Tooltip>
        </div>
        <div className="formElementWrapper">
          <div className="caracteristicLabel">
            {t('APP.CARACTERISTIC.CARACTERISTIC.PH_90AED1', {
              defaultValue: 'Libellé devis (facultatif)',
            })}
          </div>
          <Controller
            name="labelQuote"
            control={control}
            // rules={{required: 'Veuillez selectionner une unité'}}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Optionnel"
                errorText={
                  dirtyFields?.labelQuote ? errors?.labelQuote?.message : ''
                }
                // key={'test'}
                width={'280px'}
                error={errors?.labelQuote?.message ? true : false}
                onBlur={handleSubmit(
                  () => handleBlur(),
                  (e) => handleBlurError(e)
                )}
              />
            )}
          />
        </div>
      </div>
      <div className="information">
        {t('APP.CARACTERISTIC.CARACTERISTIC.PH_3A1095', {
          defaultValue: 'Veuillez renseigner tous les champs.',
        })}
      </div>
      {/* <Button
                className='purple-button'
                onClick={handleSubmit(() => handleValmidate)}
                text="valider" 
                disabled={errors?.equipmentName || !isValid? true : false}
                
               /> */}
    </div>
  );
};

export default Caracteristic;
