import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

import './SearchBar.scss';

type SearchBarProps = {
  placeHolder?: string;
  style?: {};
  dataTestId?: string;
  productsList?: boolean;
  fetchProducts?: Function;
  inputSearchBar?: string;
  updateProductsValue?: Function;
  searchRequest?: number;
};

const SearchBar = (props: SearchBarProps) => {
  const {
    updateProductsValue,
    fetchProducts,
    dataTestId,
    searchRequest,
    productsList,
    placeHolder,
    inputSearchBar,
    style,
  } = props;

  const { t } = useTranslation();

  const handleSearch = (key: string, name: any) => {
    if (key === 'Enter') {
      if (fetchProducts) {
        if (updateProductsValue) {
          updateProductsValue('nodeId', 'null');
          updateProductsValue('offsetList', 0);
          updateProductsValue('requests', [name.value]);
          updateProductsValue('searchRequest', (searchRequest || 1) + 1);
        }

        fetchProducts(null, name.value, null);
      }
    }
  };

  return (
    <Input
      data-testid={dataTestId}
      disableUnderline={true}
      className="rights-search"
      value={inputSearchBar || null}
      // onChange={() =>}
      onKeyPress={(event) =>
        productsList ? handleSearch(event.key, event.target) : null
      }
      onChange={(event) => {
        if (updateProductsValue) {
          updateProductsValue('inputSearchBar', event.target.value);
        }
      }}
      style={style}
      placeholder={
        placeHolder ||
        t('APP.SEARCHBAR.SEARCHBAR.PH_E1127D', { defaultValue: 'Rechercher' })
      }
      startAdornment={
        <InputAdornment position="start">
          <IconButton
            onClick={() =>
              productsList ? handleSearch('Enter', { value: 1 }) : null
            }
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default SearchBar;
