const MIN_DIGITS_TO_APPLY_TON_FORMAT = 5;
export const LIBELLE_KG_EQ_CO2 = 'kg éq. CO₂';

export function formatValueWithWeight(
  value,
  currentIndicator,
  currentProject = null
) {
  let valueToUse = value;
  if (currentProject !== null) {
    valueToUse =
      currentProject.refUnit === 'm²' ? value / currentProject.area : value;
  }
  const formattedValue =
    currentIndicator.unit === LIBELLE_KG_EQ_CO2
      ? getFormattedWeightNumber(valueToUse)
      : formatNumber(valueToUse);

  return formattedValue;
}

const formatNumber = (number) => {
  if (number === undefined) return null;
  // eslint-disable-next-line use-isnan
  if (Number.isNaN(number)) return '0';

  const absoluteNumber = Math.abs(number);

  if (absoluteNumber < 1e-10) return '0';

  if (absoluteNumber > 1e6 || (absoluteNumber < 0.01 && number !== 0))
    return Number(number).toExponential(2);

  return number.toLocaleString('fr-FR', {
    maximumFractionDigits: 2,
  });
};

export const getFormattedWeightNumber = (number) => {
  let formattedNumber;
  const lengthNumber = number.toString().split('.')[0].length;

  if (lengthNumber >= MIN_DIGITS_TO_APPLY_TON_FORMAT) {
    formattedNumber = number / 1000;
  } else {
    formattedNumber = number;
  }

  return formattedNumber.toLocaleString('fr-FR', {
    maximumFractionDigits: 2,
  });
};

export const getUnitConversion = (number) => {
  return number?.toString().split('.')[0].length >= MIN_DIGITS_TO_APPLY_TON_FORMAT
    ? 't éq. CO₂'
    : LIBELLE_KG_EQ_CO2;
};

export default formatNumber;
