import React from 'react';

import './componentsProducts.scss';

import ProductsList from '../../../containers/ProductsList';
import ProductsMenu from '../../../containers/ProductsMenu';

const ComponentsProducts = () => {
  return (
    <div className="components-products-wrapper">
      <div className="components-products-left">
        <ProductsMenu />
      </div>
    </div>
  );
};

export default ComponentsProducts;
