import './materialTable.scss';
import { Button, Input, Select } from '@photocarbone/nooco-ui';
import { StateDTO } from '../../../../../../models/GeneralDTO';
import {
  changeModelingEquipments,
  updateEquipmentsAddLine,
} from '../../../../../../../actions/equipments';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
// import trashIcon from '../../../../../../../assets/images/equipments/trash.png';
import { ALLOTMENT_IDS } from '../../../../../../globalVariable/typeProject';
import { AllotmentNodeDTO } from '../../../../../../models/ProjectDTO';
import { decimalNumberRegex } from '../../../../../../shared/Regex/regex';
import { filterNodesChildrenByLevelsAndName } from '../../../../../../shared/utilsFunction';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const MaterialTable = ({ lines, updateLines }) => {
  const { allotments } = useSelector((state: StateDTO) => state.projects.allGroups);
  const { allGroups } = useSelector((state: StateDTO) => state.projects);
  const { addMaterialLine } = useSelector(
    (state: StateDTO) => state.equipments.modeling
  );
  const [editLineNumber, setEditLineNumber] = useState<null | number>(null);
  const [editPercentValue, setEditPercentValue] = useState<string | null>(null);
  const [editAllotmentNode, setEditAllotmentNode] =
    useState<AllotmentNodeDTO | null>(null);

  const [AllotmentMaterial, setAllotmentMaterial] = useState<AllotmentNodeDTO[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const AllotmentWithObsolet =
      allotments.find((allotmentList) => allotmentList.id === ALLOTMENT_IDS.NOOCO)
        ?.AllotmentNodes || [];

    if (AllotmentMaterial.length === 0) {
      setAllotmentMaterial(
        filterNodesChildrenByLevelsAndName({
          AllotmentNodes: AllotmentWithObsolet,
          Lot: {
            name: t('APP.MATERIALTABLE.MATERIALTABLE.MODELED_PRODUCT', {
              defaultValue: 'Produits modélisés',
            }),
            keep: true,
          },
          Function: {
            name: t('APP.MATERIALTABLE.MATERIALTABLE.PH_CF294E', {
              defaultValue: 'Matériaux',
            }),
            keep: true,
          },
          ChildrenLevel: 'Label',
        }).sort((a, b) => a.name.localeCompare(b.name))
      );
    }
  }, []);

  const dispatch = useDispatch();

  const isAddButtonValid = () => {
    const { allotmentNode, percent } = addMaterialLine;

    if (!isValidNumber()) {
      return false;
    }
    if (percent && percent > 100) {
      return false;
    }
    if (!percent || !allotmentNode) {
      return false;
    }

    return true;
  };

  const handleAddMaterial = () => {
    let addMaterialLineTemp = { ...addMaterialLine };

    addMaterialLineTemp.percent = parseFloat(
      addMaterialLineTemp.percent.replace(',', '.')
    );

    updateLines([...lines, addMaterialLineTemp]);
    dispatch(
      changeModelingEquipments('addMaterialLine', {
        allotmentNode: null,
        percent: '',
      })
    );
  };

  const handleResetAddMaterial = () => {
    dispatch(
      changeModelingEquipments('addMaterialLine', {
        allotmentNode: null,
        percent: '',
      })
    );
  };

  const handleDeleteMaterial = (materialIndexToRemove) => {
    const newMaterialLines = lines.filter(
      (line, index) => index !== materialIndexToRemove
    );

    updateLines(newMaterialLines);
  };

  const handleEditPercentOnBlur = (e, index) => {
    if (isValidNumberEdit() && editPercentValue) {
      editLinePercent(index);
    }
  };

  const handleEditPercentValidation = (e, index) => {
    if (e.key === 'Enter' && isValidNumberEdit() && editPercentValue) {
      editLinePercent(index);
    }
  };

  const handleEditAllotmentNode = (e, newValue) => {
    setEditAllotmentNode(newValue);

    const newMaterialLines = [...lines];

    if (editLineNumber === null) {
      return;
    }
    newMaterialLines[editLineNumber].allotmentNode = newValue;
    setEditLineNumber(null);
    setEditPercentValue(null);
    setEditAllotmentNode(null);
  };

  const editLinePercent = (index) => {
    const newMaterialLines = [...lines];

    if (editPercentValue === null) {
      return;
    }
    newMaterialLines[index].percent = parseFloat(editPercentValue.replace(',', '.'));
    updateLines(newMaterialLines);
    setEditLineNumber(null);
    setEditPercentValue(null);
    setEditAllotmentNode(null);
  };

  const isValidNumber = () => {
    const isValid = addMaterialLine.percent
      ? decimalNumberRegex.test(addMaterialLine.percent)
      : false;

    return isValid;
  };

  const isValidNumberEdit = () => {
    const isValid = editPercentValue
      ? decimalNumberRegex.test(editPercentValue)
      : false;

    return isValid;
  };

  return (
    <div className="materialTableWrapper">
      <div className="header">
        <div style={{ width: '45%', alignItems: 'center' }}>
          {t('APP.MATERIALTABLE.MATERIALTABLE.PH_6312E3', {
            defaultValue: 'Matériaux',
          })}
        </div>
        <div style={{ alignItems: 'center' }}>
          {t('APP.MATERIALTABLE.MATERIALTABLE.PH_0AB76B', {
            defaultValue: 'Quantité',
          })}
        </div>
      </div>
      <div className="materialLines">
        {lines.map((line, index) => (
          <div className="line" key={index}>
            {editLineNumber === index ? (
              <Autocomplete
                style={{
                  borderRadius: '30px',
                  width: '150px',
                  marginBottom: '0px!important',
                }}
                value={editAllotmentNode || undefined}
                options={AllotmentMaterial}
                onChange={handleEditAllotmentNode}
                getOptionLabel={(option) => option.name || ''}
                size={'small'}
                disableClearable={true}
                // disableDropDownIcon={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // onChange={(e, newValue) => { dispatch(updateEquipmentsAddLine('allotmentNode',
                    //   newValue)); }}
                    // placeholder="Ajouter un équipement"
                    size={'small'}
                    variant="outlined"
                    className="autocomplet"
                  />
                )}
              />
            ) : (
              <div style={{ width: '45%' }}>{line.allotmentNode.name}</div>
            )}
            {editLineNumber === index ? (
              <div onKeyDown={(e) => handleEditPercentValidation(e, index)}>
                <Input
                  defaultValue={editPercentValue}
                  width="80px"
                  // type="number"
                  size="small"
                  // min={1}
                  // max={100}
                  style={{ marginRight: '1rem', marginLeft: '1rem' }}
                  onChange={(value) => setEditPercentValue(value)}
                  onBlur={(e) => handleEditPercentOnBlur(e, index)}
                  onKeyPress={(e) => handleEditPercentValidation(e, index)}
                  error={!isValidNumberEdit() ? true : false}
                />
              </div>
            ) : (
              <div
                style={{ marginRight: '2rem', width: '20px' }}
              >{`${line.percent}`}</div>
            )}

            <div style={{ marginRight: '8rem' }}>%</div>
            <div
              onClick={() => {
                if (editPercentValue === null && editAllotmentNode === null) {
                  setEditPercentValue(lines[index].percent.toString());
                  setEditAllotmentNode(lines[index].allotmentNode);
                  setEditLineNumber(index);
                }
              }}
            >
              <IconButton aria-label="edit">
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.3281 13.5117H0.671875C0.343457 13.5117 0.078125 13.7771 0.078125 14.1055V14.7734C0.078125 14.8551 0.144922 14.9219 0.226562 14.9219H14.7734C14.8551 14.9219 14.9219 14.8551 14.9219 14.7734V14.1055C14.9219 13.7771 14.6565 13.5117 14.3281 13.5117ZM2.78154 11.9531C2.81865 11.9531 2.85576 11.9494 2.89287 11.9438L6.01377 11.3965C6.05088 11.3891 6.08613 11.3724 6.11211 11.3445L13.9774 3.4792C13.9946 3.46203 14.0083 3.44164 14.0176 3.4192C14.0269 3.39675 14.0317 3.37269 14.0317 3.34839C14.0317 3.32409 14.0269 3.30003 14.0176 3.27758C14.0083 3.25513 13.9946 3.23474 13.9774 3.21758L10.8937 0.131934C10.8584 0.0966797 10.812 0.078125 10.7619 0.078125C10.7118 0.078125 10.6654 0.0966797 10.6302 0.131934L2.76484 7.99727C2.73701 8.0251 2.72031 8.0585 2.71289 8.0956L2.16553 11.2165C2.14748 11.3159 2.15393 11.4182 2.18432 11.5145C2.21471 11.6109 2.26812 11.6984 2.33994 11.7694C2.4624 11.8882 2.61641 11.9531 2.78154 11.9531Z"
                    fill="#707070"
                  />
                </svg>
              </IconButton>
            </div>
            <div
              onClick={() => {
                setEditLineNumber(null);
                setEditPercentValue(null);
                setEditAllotmentNode(null);
                handleDeleteMaterial(index);
              }}
            >
              <IconButton aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
      <div className="addMaterialLine">
        <Autocomplete
          style={{
            borderRadius: '30px',
            width: '150px',
            marginBottom: '0px!important',
          }}
          value={addMaterialLine?.allotmentNode || null}
          options={AllotmentMaterial}
          onChange={(e, newValue) => {
            dispatch(updateEquipmentsAddLine('allotmentNode', newValue));
          }}
          getOptionLabel={(option) => option.name || ''}
          size={'small'}
          disableClearable={true}
          // disableDropDownIcon={true}
          renderInput={(params) => (
            <TextField
              {...params}
              // onChange={(e, newValue) => { dispatch(updateEquipmentsAddLine('allotmentNode',
              //   newValue)); }}
              // placeholder="Ajouter un équipement"
              size={'small'}
              variant="outlined"
              className="autocomplet"
            />
          )}
        />

        {/* </div> */}
        <Input
          defaultValue={addMaterialLine.percent}
          value={addMaterialLine.percent}
          disabledDebouncing={true}
          width="80px"
          // type="number"
          size="small"
          // min={1}
          // max={100}
          style={{ marginRight: '1rem', marginLeft: '1rem' }}
          onChange={(value) => {
            dispatch(updateEquipmentsAddLine('percent', value));
          }}
          error={addMaterialLine?.percent && !isValidNumber() ? true : false}
        />
        <div style={{ marginRight: '40px' }}>%</div>
        <Button
          className="save-button"
          text={t('APP.MATERIALTABLE.MATERIALTABLE.PH_A8E02A', {
            defaultValue: 'Ajouter',
          })}
          disabled={!isAddButtonValid()}
          onClick={handleAddMaterial}
        />
        <IconButton
          style={{ marginLeft: '10px', padding: '12px!important' }}
          onClick={handleResetAddMaterial}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default MaterialTable;
