/* eslint-disable no-unused-vars */
import { Dispatch } from 'redux';
import { MapDispatchToPropsParam, MapStateToPropsParam, connect } from 'react-redux';

import { NewActionDTO } from '../../app/models/ExploitationDTO';
import EquipmentChoice from '../../app/exploitation/ListActions/AddActions/Renewal/EquipmentChoice';

import {
  createAction,
  fetchSheets,
  getCaracs,
  getFullMd,
  resetAddAction,
  updateExploitationValue,
} from '../../actions/exploitation';
import { saveFiltersBrand } from '../../actions/filtersgraph';

interface IExploitation {
  controlChoice: string;
  newAction: NewActionDTO;
  sheets: {}[];
  loadGraph: boolean;
  loadInfosSheet: boolean;
}

interface IProjects {
  allGroups: {
    type: [];
  };
  currentProject: {};
  filters: {};
}

type State = {
  exploitation: IExploitation;
  projects: IProjects;
};

const mapStateToProps: MapStateToPropsParam<{}, {}, State> = (state: State) => ({
  newAction: state.exploitation.newAction,
  allGroups: state.projects.allGroups,
  currentProject: state.projects.currentProject,
  sheets: state.exploitation.sheets,
  loadGraph: state.exploitation.loadGraph,
  loadInfosSheet: state.exploitation.loadInfosSheet,
  filters: state.projects.filters,
});

const mapDispatchToProps: MapDispatchToPropsParam<{}, {}> = (
  dispatch: Dispatch
) => ({
  updateExploitationValue: (name: string, value: string) => {
    dispatch(updateExploitationValue(name, value));
  },
  resetAddAction: () => {
    dispatch(resetAddAction());
  },
  createAction: (t) => {
    dispatch(createAction(t));
  },
  fetchSheets: (mdId: number) => {
    dispatch(fetchSheets(mdId));
  },
  getCaracs: (md: {}, screen: string) => {
    dispatch(getCaracs(md, screen));
  },
  getFullMd: (id: number) => {
    dispatch(getFullMd(id));
  },
  saveFiltersBrand: (filters, open, open2) => {
    dispatch(saveFiltersBrand(filters, open, open2));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentChoice);
