/* eslint-disable no-undef */
import { AllotmentNodeDTO, ZoneDTO } from '../../../models/ProjectDTO';
import { IndicatorDTO, ProjectDTO } from '../../../../models/ProjectDTO';
import { ProjectIcDTO, zonesDTO } from './WidgetsDTO';
import {
  ALLOTMENT_IDS,
  TEMPLATE_TYPE_NAME,
} from '../../../globalVariable/typeProject';
import { gaugeEPCM } from '../../../shared/utils';
import CustomBbcaThreshold from '../../../shared/CustomBbcaThreshold/CustomBbcaThreshold';
import CustomProgressBar from '../../../shared/CustomProgressBar';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {
  LIBELLE_KG_EQ_CO2,
  formatValueWithWeight,
  getUnitConversion,
} from '../../ ../../../services/mathematics/formatNumber';
import { useTranslation } from 'react-i18next';
import lightIcon from '../../../../assets/images/import/light.png';
import { ProjectDataComps } from '@photocarbone/nooco-shared-api-def/src/project/projectDataComps';
import {
  ClimateInnovationDataScore,
  calculClimateInnovationScore,
  createClimateInnovationDataScore,
} from '../../ClimateInnovation/ClimateInnovationForm/ClimateInnovationForm';
import { ZONE_TYPES } from '../../../globalVariable/generalDefinitions';
import Spinner from '../../../shared/Spinner';
import { getBbcaScore } from './widgetsFunctions/widgetsFunctions';

const building = require('../../../../assets/images/synthese/Image1.png');
const buildingIcon = require('../../../../assets/images/synthese/Image3.png');
const surfaces = require('../../../../assets/images/synthese/Image2.png');

type ProjectWidgetsProps = {
  projectIc: ProjectIcDTO;
  zones: zonesDTO[];
  currentProject: ProjectDTO;
  currentIndicator: IndicatorDTO;
  currentAllotment: AllotmentNodeDTO;
  projectDataComps: ProjectDataComps;
  isLoadingDataComps: boolean;
};

const ProjectWidgets = ({
  zones,
  projectIc,
  currentProject,
  currentIndicator,
  currentAllotment,
  projectDataComps,
}: ProjectWidgetsProps): JSX.Element => {
  let refUnitModeTotal = currentProject.refUnit === 'total';
  const { t } = useTranslation();

  let climateInnovationData: ClimateInnovationDataScore | undefined = undefined;
  let buildingZone: ZoneDTO | undefined = undefined;
  let climateInnovationScore: number | null = null;
  let bbcaScore: number | null = null;
  if (currentProject.templateType.includes(TEMPLATE_TYPE_NAME.BBCA)) {
    climateInnovationData = createClimateInnovationDataScore(
      projectDataComps?.buildings[0]?.climateInnovationData
    );

    buildingZone = zones.find((zone) => zone.type === ZONE_TYPES.building);

    climateInnovationScore = calculClimateInnovationScore({
      climateInnovationData,
      zoneSelected: buildingZone,
    });
    bbcaScore = Number(buildingZone?.Ic[1]?.Bbca.BbcaScore.toFixed(2));
  }

  const icProjetValue = (projectImpact = null) => {
    return projectImpact !== null
      ? projectImpact
      : currentAllotment.id === ALLOTMENT_IDS.RE2020
      ? projectIc.IcProjetRe2020
      : projectIc.IcProjet;
  };

  return (
    <div className="report-project container-resizable-ok">
      <div className="report-project-header">
        <h3 className="dashboard-title">
          {currentProject.templateType.includes(TEMPLATE_TYPE_NAME.EPCM)
            ? 'Bâtiment'
            : currentProject.templateType.includes(TEMPLATE_TYPE_NAME.BBCA)
            ? 'Score BBCA'
            : 'Projet'}
        </h3>

        <Tooltip
          title={
            currentProject.templateType.includes(TEMPLATE_TYPE_NAME.EPCM)
              ? t('APP.WIDGETS.PROJECTWIDGETS.PH_62CFB3', {
                  defaultValue:
                    'EGES: Impact total du projet - bénéfices et charges (si positif)',
                })
              : t('APP.WIDGETS.PROJECTWIDGETS.PH_5CC3FE', {
                  defaultValue: 'Impact carbone du projet : IcProjet',
                })
          }
        >
          <img
            style={{
              width: window.screen.availHeight < 950 ? '40px' : undefined,
              height: window.screen.availHeight < 950 ? '40px' : undefined,
            }}
            src={buildingIcon}
            alt=""
          />
        </Tooltip>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {currentProject.templateType?.includes(TEMPLATE_TYPE_NAME.BBCA) && (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  marginLeft: '1rem',
                  marginRight: '1rem',
                  fontWeight: 'bold',
                  fontSize: '1.3rem',
                }}
              >
                {`${bbcaScore} point(s)`}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <CustomBbcaThreshold
                bbcaScore={bbcaScore}
                buildingTypeId={
                  buildingZone?.Zones?.find((zone) => zone.type === ZONE_TYPES.zone)
                    ?.BuildingTypeId
                }
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                marginTop: '8px',
              }}
            >
              <img
                src={lightIcon}
                alt=""
                style={{ width: '20px', height: '20px' }}
              />
              <div style={{ marginRight: '1rem' }}>
                {'Points innovation climat: '}
              </div>
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.3rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: '8px' }}>
                  {climateInnovationScore === null ? (
                    <Spinner size={'small'} />
                  ) : (
                    `${climateInnovationScore}`
                  )}
                </div>
                <div>{'point(s)'}</div>
              </div>
            </div>
          </>
        )}
        {![TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(
          currentProject.templateType
        ) && (
          <p className="value">
            <span className="valueNumbers">
              {formatValueWithWeight(icProjetValue(), currentIndicator)}
            </span>{' '}
            {currentIndicator.unit === LIBELLE_KG_EQ_CO2
              ? getUnitConversion(icProjetValue())
              : currentIndicator.unit}
            {refUnitModeTotal ? '' : '/m²'}
          </p>
        )}
        {currentProject.templateType.includes(TEMPLATE_TYPE_NAME.EPCM) && (
          <p className="value">
            <span className="valueNumbers">
              {formatValueWithWeight(icProjetValue(), currentIndicator)}
            </span>{' '}
            {currentIndicator.unit === LIBELLE_KG_EQ_CO2
              ? getUnitConversion(icProjetValue())
              : currentIndicator.unit}
            {refUnitModeTotal ? '' : '/m²'}
          </p>
        )}
      </div>
      {![TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(
        currentProject.templateType
      ) && (
        <div className="report-project-bottom">
          <div>
            <Tooltip title="Impact carbone du bâtiment : IcBâtiment">
              <img
                style={{
                  width: window.screen.availHeight < 950 ? '40px' : undefined,
                }}
                src={building}
                alt=""
              />
            </Tooltip>
            <p className="value">
              <span className="valueNumbers">
                {formatValueWithWeight(
                  icProjetValue(projectIc.IcBat),
                  currentIndicator
                )}
              </span>{' '}
              {currentIndicator.unit === LIBELLE_KG_EQ_CO2
                ? getUnitConversion(icProjetValue(projectIc?.IcBat))
                : currentIndicator.unit}
              {refUnitModeTotal ? '' : '/m²'}{' '}
            </p>
          </div>
          <div>
            <Tooltip
              title={t('APP.WIDGETS.PROJECTWIDGETS.PH_141466', {
                defaultValue: 'Impact carbone de la parcelle : IcParcelle',
              })}
            >
              <img
                style={{
                  width: window.screen.availHeight < 950 ? '40px' : undefined,
                }}
                src={surfaces}
                alt=""
              />
            </Tooltip>
            <p className="value">
              <span className="valueNumbers">
                {formatValueWithWeight(
                  icProjetValue(projectIc?.IcParcelle),
                  currentIndicator
                )}
              </span>{' '}
              {currentIndicator.unit === LIBELLE_KG_EQ_CO2
                ? getUnitConversion(icProjetValue(projectIc?.IcParcelle))
                : currentIndicator.unit}
              {refUnitModeTotal ? '' : '/m²'}
            </p>
          </div>
        </div>
      )}
      {currentProject.templateType.includes(TEMPLATE_TYPE_NAME.EPCM) && (
        <>
          <CustomProgressBar
            projectIc={projectIc}
            valuePercentage={gaugeEPCM('IcProjet', projectIc)}
          />
        </>
      )}
    </div>
  );
};

export default ProjectWidgets;
