/**
 * Base version of non usage phases calc
 * @param indice
 * @param quantity
 * @returns {{prod: *, edif: number, end: number}}
 */
const calcBaseAcv = (indice, quantity) => {
  let prod = 0;
  let edif = 0;
  let end = 0;

  //Calc production

  if (indice.SheetIndex.AProd) {
    prod = indice.SheetIndex.AProd;
  } else {
    prod = indice.SheetIndex.A1;
    prod = indice.SheetIndex.A2;
    prod = indice.SheetIndex.A3;
  }

  prod *= quantity;

  // Calc Edification
  if (indice.SheetIndex.AEdif) {
    edif = indice.SheetIndex.AEdif;
  } else {
    edif += indice.SheetIndex.A4;
    edif += indice.SheetIndex.A5;
  }

  edif *= quantity;

  // Calc Fin de Vie

  if (indice.SheetIndex.C) {
    end = indice.SheetIndex.C;
  } else {
    end += indice.SheetIndex.C1;
    end += indice.SheetIndex.C2;
    end += indice.SheetIndex.C3;
    end += indice.SheetIndex.C4;
  }

  end *= quantity;

  return { prod, edif, end };
};

/**
 *
 * @param fluid, the equipment
 * @param usageRes, res obj
 * @param PER, poject ddv
 * @param indice parent sheet indice
 * @param ddv rapport DdvProjet / ddvEquip (ex coefRenouvellement)

 */
const calcStaticFluid = (fluid, usageRes, PER, indice, ddv) => {
  if (!indice) return;

  if (indice.SheetIndex.B1 && indice.SheetIndex.B1 > 0) {
    usageRes.b1 = 0;
    usageRes.b1fluide = indice.SheetIndex.B1 * ddv;
  } else {
    const fluidIndice = fluid.Indices.find((indic) => indic.id === indice.id);

    usageRes.b1fluide =
      0.02 * fluid.ProjectSheet.quantity * fluidIndice.SheetIndex.TOTAL * PER;
  }
};

/**
 * Static version of carbon usage ACV calc.
 * @param sheet
 * @param baseAcv
 * @param rp
 * @param indices
 * @param projectDdv
 * @param decMds
 * @returns {{}}
 */
const calcBaseUsageAcv = (
  sheet,
  baseAcv,
  rp,
  indices,
  projectDdv,
  isDec,
  isChantier
) => {
  let usageRes = {};
  const quantity = sheet.ProjectSheet.quantity;

  if (!rp) rp = 1;

  let ddv = projectDdv ? Number(projectDdv) / Number(sheet.ddv) : 1;

  if (isDec) {
    if (isDec.lot === 'Eau') {
      usageRes.b7 = indices.SheetIndex.TOTAL * ddv * quantity;
    } else if (isDec.lot === 'Terre' && isChantier) {
      usageRes.edifChantier = indices.SheetIndex.TOTAL * ddv * quantity;
    } else {
      usageRes.b6 = indices.SheetIndex.TOTAL * ddv * quantity;
    }
  }

  usageRes.b1 = indices.SheetIndex.B1 * ddv * quantity;
  usageRes.b2 = indices.SheetIndex.B2 * ddv * quantity;
  usageRes.b3 = indices.SheetIndex.B3 * ddv * quantity;
  usageRes.b4 =
    (baseAcv.prod + baseAcv.edif + baseAcv.end) * (rp - 1) +
    indices.SheetIndex.B4 * quantity * ddv;
  usageRes.moduleD = indices.SheetIndex.D * rp * quantity;

  if (sheet.childEquip)
    calcStaticFluid(sheet.childEquip, usageRes, projectDdv, indices, ddv);

  return usageRes;
};

/**
 * Call basic calc acv func and usage function, and return both results objects.
 * @param indice
 * @param sheet
 * @param decMds
 * @param projectDdv
 * @returns {{usageACV: {}, baseACV: {prod: *, edif: number, end: number}}}
 */
const staticCarbon = (indice, sheet, decMds, projectDdv, isChantier) => {
  if (!indice) {
    return { baseACV: 0, usageACV: 0 };
    // throw new Error("No indice found.");
  }

  const isDec = decMds.find(
    (decMd) => decMd.id === sheet.ProjectSheet.MasterDataEquipmentId
  );

  sheet.isDec = isDec;

  const rp = sheet.ddv > projectDdv ? 1 : projectDdv / sheet.ddv;
  let baseACV = calcBaseAcv(indice, sheet.ProjectSheet.quantity);
  let usageACV = 0;

  if (!isChantier || (isChantier && isDec)) {
    usageACV = calcBaseUsageAcv(
      sheet,
      baseACV,
      rp,
      indice,
      projectDdv,
      isDec,
      isChantier
    );
  }
  //calcExportedEnergy(bas, usageACV, sheet);

  return { baseACV, usageACV };
};

exports.staticCarbon = staticCarbon;
exports.calcBaseAcv = calcBaseAcv;

/**
 * lOT CHANTIER / fonction EAU-energie-terre-composant
 *
 * Eau et energie et Terre => meme calcul que eau et energie sur un an et on met dans a4 a5 (DEC)
 *
 * Composant => Calcul normal et on vire les b et (usage)
 *
 */
