import { Redirect, Route, Switch } from 'react-router-dom';
import AppContext from './AppContext';
import Cookies from 'js-cookie';
import CreationProject from './projects/CreationProject';
import Help from './help/Help';

// import Invitation from "./invitation/invitation";
import BundleWrapper from './bundle/BundleWrapper';
import ProductsChoice from '../containers/ProductsChoice';
import ProjectOverview from '../containers/ProjectsOverview';
import Projects from '../containers/Projects';
import React, { Component, Suspense, lazy } from 'react';
import Rights from './rights/Rights.tsx';
import Rsee from '../containers/Rsee';
import Simulator from './simulator/Simulator';
import Spinner from './shared/Spinner';
import VerifyMail from './user-pages/VerifyMail.tsx';
import Health from './health/index.tsx';
import ProjectSimulator from './simulator/ProjectSimulator/ProjectSimulator';
const Login = lazy(() => import('./user-pages/Login'));
``;

export const isUserComeFromVinciSSOWithoutVerifyHisMail = (user) => {
  if (
    user &&
    user.attributes.email_verified === 'false' &&
    user.username.includes('Vinci')
  ) {
    return true;
  }

  return false;
};

class AppRoutes extends Component {
  constructor(props) {
    super(props);
  }

  static contextType = AppContext;

  render() {
    const { isAuthenticated, isSuperAdmin, isAdmin, user } = this.context;

    const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
      // console.log('isAuthenticated in private route',
      //   isAuthenticated);

      if (isUserComeFromVinciSSOWithoutVerifyHisMail(user)) {
        return <Redirect to="/verify-mail" />;
      } else if (isAuthenticated) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Route {...rest} render={(props) => <RouteComponent {...props} />} />;
      }

      // console.log('je vais être redirigé vers login');

      // console.log('redirect to login');
      Cookies.remove('token');
      Cookies.remove('access');

      return (window.location.href = '/login');
    };

    const AdminRoute = ({ component: RouteComponent, ...rest }) => {
      if (isAdmin) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Route {...rest} render={(props) => <RouteComponent {...props} />} />;
      }

      if (isUserComeFromVinciSSOWithoutVerifyHisMail(user)) {
        return <Redirect to="/verify-mail" />;
      }

      return <Redirect to="/projects" />;
    };

    const SuperAdminOrCompanyAdminRoute = ({
      component: RouteComponent,
      ...rest
    }) => {
      if (
        isSuperAdmin ||
        isAdmin ||
        this.props.isCompanyAdmin ||
        this.props.orgaAdmins.length
      ) {
        return <Route {...rest} render={(props) => <RouteComponent {...props} />} />;
      }

      // console.log('redirect to projects');

      if (isUserComeFromVinciSSOWithoutVerifyHisMail(user)) {
        return <Redirect to="/verify-mail" />;
      }

      return <Redirect to="/projects" />;
    };

    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/verify-mail" component={VerifyMail} />
          <Route path="/health" component={Health} />
          <PrivateRoute path="/products" component={ProductsChoice} />
          <PrivateRoute path="/simulator" component={Simulator} />
          <PrivateRoute path="/project-simulator" component={ProjectSimulator} />

          <PrivateRoute path="/projects" exact component={Projects} />
          <PrivateRoute path="/creation/project" exact component={CreationProject} />
          <PrivateRoute path="/library" exact component={BundleWrapper} />

          <PrivateRoute exact path="/projects/:id" component={ProjectOverview} />
          <AdminRoute path="/projects/:id/rsee" component={Rsee} />

          {/**
          <PrivateRoute path="/companies" exact component={Companies} />
          <PrivateRoute path="/companies/:id/employees" exact component={Employees} />
          <PrivateRoute path="/companies/:id/invitations" exact component={CompaniesInvitations} />
           **/}

          <PrivateRoute path="/help/:category?" component={Help} />

          {/* <SuperAdminOrCompanyAdminRoute path="/access" exact component={Access} />
          <SuperAdminOrCompanyAdminRoute path="/monitoring" exact component={Monitoring} /> */}
          <SuperAdminOrCompanyAdminRoute path="/rights" exact component={Rights} />

          {/* <PrivateRoute path="/invites" component={Invitation} /> */}

          <Redirect to="/projects" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
