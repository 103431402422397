import { Button } from '@photocarbone/nooco-ui';
import {
  CalculatorWorksiteProps,
  EvacuatedEarthWork,
  EvacuatedEarthWorkIndividualHouse,
  FormValuesCalculatorWorksite,
  ImportedLand,
  SimplifiedCalculatorEnergy,
  SpecificEquipment,
  WorksiteCalculatorNav,
} from '../../../../models/CalculatorDTO';
import { FormProvider } from 'react-hook-form';
import { StateDTO } from '../../../../models/GeneralDTO';
import {
  calculatorExit,
  calculatorReset,
  calculatorUpdate,
  getBuildingCalculatorData,
  putBuildingCalculatorData,
} from '../../../../../actions/calculator';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InfoIcon from '@material-ui/icons/Info';
import React, { useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import WorksiteCarriedAway from './CalculatorWorksiteContent/WorksiteCarriedAway';
import WorksiteEnergy from './CalculatorWorksiteContent/WorksiteEnergy';
import WorksiteEvacuated from './CalculatorWorksiteContent/WorksiteEvacuated';
import WorksiteGear from './CalculatorWorksiteContent/WorksiteGear';
import WorksiteSpecific from './CalculatorWorksiteContent/WorksiteSpecific';
import WorksiteTransport from './CalculatorWorksiteContent/WorksiteTransport';
import WorksiteWater from './CalculatorWorksiteContent/WorksiteWater';

import detailedCalcul from '../../../../../assets/images/equipments/calculator/calculator.png';
import epcmIcon from '../../../../../assets/images/creation/epcm.png';
import re2020 from '../../../../../assets/images/equipments/calculator/re2020.png';
import simplifiedCalcul from '../../../../../assets/images/equipments/calculator/simplified.png';

// import { CalculateTwoTone } from '@mui/icons-material';
import {
  PROJECTS_TYPE_NAMES_FROM_TYPE_ALLOTMENT_EPCM,
  TEMPLATE_TYPE_NAME,
  returnTemplateType,
  typeProjectForSimplifiedEnergy,
} from '../../../../globalVariable/typeProject';
import { changeValueEquipments } from '../../../../../actions/equipments';
import BreadcrumbMultizone from '../../../../shared/BreadcrumbMultiZone/BreadcrumbMultizone';
import WithConditionalTooltip from '../../../../../hoc/WithConditionalTooltip';
import _ from 'lodash';
import useCaseWorksite from './CalculatorWorksiteContent/UseCaseWorksite';
import { t } from 'i18next';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const CalculatorWorksite = ({ templateType }: CalculatorWorksiteProps) => {
  const handleClose = () => {
    calculatorUpdate('isLoadingCaracs', false);
  };
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    isWorksiteCalculatorDetailed,
    isWorksiteCalculatorSimplified,
    worksiteCalculatorDetailedActive,
    calculatorData,
    loadingCalculator,
    isCalculatorDetailedLoaded,
    worksiteCalculatorNav,
  } = useSelector((state: StateDTO) => state.projects.calculator);
  const { currentProject } = useSelector((state: StateDTO) => state.projects);
  const { simplifiedEnergyLines } = useSelector(
    (state: StateDTO) => state.equipments
  );
  const currentProjectId = useSelector(
    (state: StateDTO) => state.projects.currentProject.id
  );
  const flatAllotments = useSelector(
    (state: StateDTO) => state.projects.allGroups.flatAllotments
  );
  const isSmallScreenMode = window.innerWidth < 1550;

  // Fetch Calculator
  useEffect(() => {
    createWorksiteCalculatorNav();

    if (!isCalculatorDetailedLoaded) {
      dispatch(getBuildingCalculatorData(currentProjectId));
      dispatch(calculatorUpdate('loadingCalculator', true));
    }
  }, []);

  // createNavigation for CalculatorSidebar
  useEffect(() => {
    if (isWorksiteCalculatorDetailed || isWorksiteCalculatorSimplified) {
      createWorksiteCalculatorNav();
    }
  }, [isWorksiteCalculatorSimplified, isWorksiteCalculatorDetailed]);

  useEffect(() => {
    if (isProjectIsOnlyIndividualHouse()) {
      changeSourceOfEmptyRows();
    }
  }, [worksiteCalculatorDetailedActive]);

  // reset(for init) form from react-hook-form with data when calc isLoaded
  useEffect(() => {
    reset(calculatorData);

    if (isProjectIsOnlyIndividualHouse()) {
      changeSourceOfEmptyRows();
    }
  }, [isCalculatorDetailedLoaded]);

  const onSubmitApi = (data: FormValuesCalculatorWorksite) => {
    const dataToState = _.cloneDeep(data);

    dispatch(calculatorUpdate('calculatorData', dataToState));

    //* traitement data energy
    data.energy.energiesArray = data.energy.energiesArray.map((item) => {
      // item.quantity = Number(item?.quantity?.replace(/,/, '.'));
      item.quantity = item?.quantity?.replace(/,/, '.');
      if (item.quantity === '') {
        item.quantity = null;
      }

      item.masterDataId = item?.MasterDataEquipment?.id.toString();
      item.caracsSave = item.caracs;
      item.caracs = item?.caracs?.caracs?.map((carac) => {
        let caracFormated = {};

        caracFormated = {
          value: [carac.value],
          name: carac.name,
          id: carac.id,
        };

        return caracFormated;
      });

      return item;
    });
    data.energy.energiesArray = typeProjectForSimplifiedEnergy.includes(
      currentProject.templateType
    )
      ? simplifiedEnergyLines
          .map((energy) => ({
            ...energy,
            caracsSave: energy.caracs || null,
            caracs: energy?.caracs?.caracs,
          }))
          .filter((el) => el.AllotmentNodeId)
      : data.energy.energiesArray.filter((el) => el.MasterDataEquipment !== null);

    data.energyCalculatorSimplified.energySimplifiedArray =
      data.energyCalculatorSimplified.energySimplifiedArray.map((item) => {
        // item.quantity = Number(item?.quantity?.replace(/,/, '.'));

        item.masterDataId = item?.MasterDataEquipment?.id.toString();
        item.caracsSave = item.caracs;
        item.caracs = item?.caracs?.caracs?.map((carac) => {
          let caracFormated = {};

          caracFormated = {
            value: [carac.value],
            name: carac.name,
            id: carac.id,
          };

          return caracFormated;
        });

        return item;
      });

    //* traitement data water

    type ZoneData = {
      isDrinkableWater: boolean;
      isWasteWater: boolean;
      quantity: string;
      origin: {
        type: 'project' | 'building' | 'zone' | 'plot';
        sourceId: number | null;
        uniqueId: string;
      };
    };
    let zonesData: ZoneData[] = [];

    data.water.zonesData.forEach((item) => {
      item.drinkableWater = item?.drinkableWater?.replace(/,/, '.');
      if (item.drinkableWater === '') {
        item.drinkableWater = null;
      } else {
        item.drinkableWater = Number(item.drinkableWater);
      }
      item.wastedWater = item?.wastedWater?.replace(/,/, '.');
      if (item.wastedWater === '') {
        item.wastedWater = null;
      } else {
        item.wastedWater = Number(item.wastedWater);
      }

      zonesData.push({
        isDrinkableWater: true,
        isWasteWater: false,
        quantity: isWorksiteCalculatorSimplified ? 10 : item.drinkableWater,
        origin: item.origin,
      });
      zonesData.push({
        isDrinkableWater: false,
        isWasteWater: true,
        quantity: isWorksiteCalculatorSimplified ? 10 : item.wastedWater,
        origin: item.origin,
      });
    });

    data.water.zonesData = zonesData;

    //* traitement importedEarthWork
    data.importedEarthWork = data.importedEarthWork.map((item) => {
      item.quantity = item?.quantity?.replace(/,/, '.');
      if (item.quantity === '') {
        item.quantity = null;
      } else {
        item.quantity = Number(item.quantity);
      }
      item.distance = item?.distance?.replace(/,/, '.');
      if (item.distance === '') {
        item.distance = null;
      } else {
        item.distance = Number(item.distance);
      }

      return item;
    });
    data.importedEarthWork = data.importedEarthWork.filter(
      (el) => el.quantity !== null
    );

    //* traitement evacuatedEarthWorkIndividualHouse
    data.evacuatedEarthWorkIndividualHouse =
      data.evacuatedEarthWorkIndividualHouse.map((item) => {
        item.area = item?.area?.replace(/,/, '.');
        if (item.area === '') {
          item.area = null;
        } else {
          item.area = Number(item.area);
        }
        item.distance = item?.distance?.replace(/,/, '.');
        if (item.distance === '') {
          item.distance = null;
        } else {
          item.distance = Number(item.distance);
        }

        if (
          [TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(templateType)
        ) {
          item.distance = 1;
        }

        return item;
      });
    data.evacuatedEarthWorkIndividualHouse =
      data.evacuatedEarthWorkIndividualHouse.filter((el) => el.area !== null);

    //* traitement evacuatedEarthWork
    data.evacuatedEarthWork = data.evacuatedEarthWork.map((item) => {
      item.quantity = item?.quantity?.replace(/,/, '.');
      if (item.quantity === '') {
        item.quantity = null;
      } else {
        item.quantity = Number(item.quantity);
      }
      item.distance = item?.distance?.replace(/,/, '.');
      if (item.distance === '') {
        item.distance = null;
      } else {
        item.distance = Number(item.distance);
      }

      if (PROJECTS_TYPE_NAMES_FROM_TYPE_ALLOTMENT_EPCM.includes(templateType)) {
        item.distance = 1;
      }

      return item;
    });
    data.evacuatedEarthWork = data.evacuatedEarthWork.filter(
      (el) => el.quantity !== null
    );

    const evacuatedEarthWorkReadyToSend = data.evacuatedEarthWork.map((item) => {
      let itemToReturn: any = { ...item };

      // itemToReturn.allotmentNode = flatAllotments.find((allotmentNode) => allotmentNode.id == item.AllotmentNode.id);
      // itemToReturn.AllotmentNode = flatAllotments.find((allotmentNode) => allotmentNode.id == item?.AllotmentNode?.id) || null;

      return itemToReturn;
    });

    data.evacuatedEarthWork = evacuatedEarthWorkReadyToSend;

    //* traitement data specific

    const specificArrayDeep = _.cloneDeep(data.specific.specificArray);

    data.specific.specificArray = data?.specific?.specificArray?.map((item) => {
      item.caracsSave = { ...item.caracs };

      item.quantity = item?.quantity?.replace(/,/, '.');
      if (item.quantity === '') {
        item.quantity = null;
      } else {
        item.quantity = Number(item.quantity);
      }

      item?.caracs?.caracs.map((carac) => {
        carac.value = carac?.value?.replace(/,/, '.') || carac?.value;
        // if (carac.value === "") {
        //   carac.value = null;
        // } else {
        //   carac.value = Number(carac.value);
        // }
        // carac.id = carac?.id?.toString();

        return carac;
      });

      item.caracs = item?.caracs?.caracs?.map((carac) => {
        let caracFormated = {};

        caracFormated = {
          value: [carac.value],
          name: carac.name,
          id: carac.id,
        };

        return caracFormated;
      });

      return item;
    });
    data.specific.specificArray = data.specific.specificArray.filter(
      (el) => el.MasterDataEquipment !== null
    );

    dispatch(putBuildingCalculatorData(currentProjectId, { calculatorData: data }));

    dispatch(calculatorUpdate('loadingCalculator', true));
  };

  const componentToDisplay = (
    componentName: WorksiteCalculatorNav['content']
  ): JSX.Element => {
    switch (componentName) {
      case 'WorksiteTransport':
        return (
          <WorksiteTransport changeSourceOfEmptyRows={changeSourceOfEmptyRows} />
        );
      case 'WorksiteGear':
        return <WorksiteGear changeSourceOfEmptyRows={changeSourceOfEmptyRows} />;
      case 'WorksiteEnergy':
        return (
          <WorksiteEnergy
            fieldsEnergyForm={fieldsEnergyForm}
            fieldsEnergySimplifiedForm={fieldsEnergySimplifiedForm}
            changeSourceOfEmptyRows={changeSourceOfEmptyRows}
          />
        );
      case 'WorksiteWater':
        return (
          <WorksiteWater
            fieldsWaterForm={fieldsWaterForm}
            changeUnitOfWater={changeUnitOfWater}
            changeSourceOfEmptyRows={changeSourceOfEmptyRows}
          />
        );
      case 'WorksiteCarriedAway':
        return (
          <WorksiteCarriedAway
            fieldsImportedForm={fieldsImportedForm}
            changeSourceOfEmptyRows={changeSourceOfEmptyRows}
          />
        );
      case 'WorksiteEvacuated':
        return (
          <WorksiteEvacuated
            fieldsEvacuatedForm={fieldsEvacuatedForm}
            fieldsEvacuatedIndividualHouseForm={fieldsEvacuatedIndividualHouseForm}
            changeSourceOfEmptyRows={changeSourceOfEmptyRows}
          />
        );
      case 'WorksiteSpecific':
        return (
          <WorksiteSpecific
            fieldsSpecificForm={fieldsSpecificForm}
            changeSourceOfEmptyRows={changeSourceOfEmptyRows}
          />
        );
      default:
        return <>erreur 404</>;
    }
  };

  // Modals delete calculator
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // logic from useCaseWorksite
  const {
    changeSourceOfEmptyRows,
    isActiveScreenValid,
    changeUnitOfWater,
    createWorksiteCalculatorNav,
    calculSumEnergy,
    calculSumWater,
    calculSumSpecific,
    calculSumTransport,
    calculateSumGear,
    calculateSumImported,
    calculateSumEvacuated,
    isCalculatorSimplifiedActivated,
    isPlotZoneInProject,
    isProjectIsOnlyIndividualHouse,
    isValidFormTransport,
    isValidFormGear,
    isRegulationProject,
    fieldsImportedForm,
    fieldsEvacuatedForm,
    fieldsEvacuatedIndividualHouseForm,
    fieldsSpecificForm,
    calculatorFormInstance,
    fieldsEnergyForm,
    fieldsEnergySimplifiedForm,
    fieldsWaterForm,
    onSubmitScreen,
    isDisplayBreadcrumbMultizone,
    isZoneFilled,
  } = useCaseWorksite();
  const {
    reset,
    getValues,
    watch,
    handleSubmit,
    formState: { isValid },
  } = calculatorFormInstance;

  const form = watch();

  const templateTypeCanAccessSimplifiedCalculator = [
    TEMPLATE_TYPE_NAME.EPCM,
    TEMPLATE_TYPE_NAME.RE2020,
    TEMPLATE_TYPE_NAME.NOOCO,
    TEMPLATE_TYPE_NAME.BBCA,
  ];

  const calculatorSimplifiedTooltipText = () => {
    if (
      !templateTypeCanAccessSimplifiedCalculator.includes(
        currentProject.templateType
      )
    ) {
      return t(
        'APP.CALCULATORWORKSITE.CALCULATORWORKSITE.PH_A9E77A',

        {
          value: returnTemplateType(currentProject.templateType),
          defaultValue:
            "La calculatrice simplifiée n'est pas disponible pour les projets de type {value}",
        }
      );
    }

    if (isCalculatorSimplifiedActivated()) {
      return '';
    }

    if (!isPlotZoneInProject()) {
      return t('APP.CALCULATORWORKSITE.CALCULATORWORKSITE.PH_ACBFD9', {
        defaultValue:
          'La surface de parcelle étant nulle, le calcul simplifié pour une maison individuelle est indisponible',
      });
    }

    return t('APP.CALCULATORWORKSITE.CALCULATORWORKSITE.PH_303B0F', {
      defaultValue:
        'Pour activer la calculette simplifiée il faut uniquement des zones de type maison Individuelle',
    });
  };

  const CalculatorChoiceCard = (choice: 'detailled' | 'simplified'): JSX.Element => {
    const isCardActivated = (): boolean => {
      if (choice === 'detailled') {
        return true;
      }

      if (choice === 'simplified') {
        if (
          !templateTypeCanAccessSimplifiedCalculator.includes(
            currentProject.templateType
          )
        ) {
          return false;
        }
        if (isCalculatorSimplifiedActivated()) {
          return true;
        } else {
          return false;
        }
      }

      return true;
    };

    return (
      <div
        className={`calcContent__detailled ${isCardActivated() ? '' : 'disabled'} `}
        onClick={() => {
          if (choice === 'detailled') {
            dispatch(calculatorUpdate('isWorksiteCalculatorDetailed', true));
          }

          if (!isCalculatorSimplifiedActivated()) {
            return;
          }

          if (choice === 'simplified') {
            dispatch(calculatorUpdate('isWorksiteCalculatorSimplified', true));
          }
        }}
      >
        <img
          src={choice === 'detailled' ? detailedCalcul : simplifiedCalcul}
          alt=""
          className="calcContent__detailled--logo"
        />
        <p>{`${choice === 'detailled' ? 'Calcul détaillé' : 'Calcul simplifié'}`}</p>
        {isRegulationProject && (
          <img
            src={
              [TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(
                templateType
              )
                ? epcmIcon
                : re2020
            }
            alt=""
            className={
              [TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(
                templateType
              )
                ? 'calcContent__epcm'
                : 'calcContent__re2020'
            }
          />
        )}
      </div>
    );
  };

  const CalculatorSimplifiedCard = WithConditionalTooltip(
    () => CalculatorChoiceCard('simplified'),
    calculatorSimplifiedTooltipText()
  );

  return (
    <FormProvider {...calculatorFormInstance}>
      <div
        className={`calculator__subSubwrapper ${
          isDisplayBreadcrumbMultizone() ? '' : 'no-multi-zone'
        }`}
      >
        {/* BreadCrumb Multizone */}
        {(isWorksiteCalculatorDetailed || isWorksiteCalculatorSimplified) &&
          isDisplayBreadcrumbMultizone() && (
            <BreadcrumbMultizone
              isActiveScreenValid={isActiveScreenValid}
              changeSourceOfEmptyRows={changeSourceOfEmptyRows}
              isZoneFilled={isZoneFilled}
            />
          )}
        {/* Sidebar and Main (include Main Choice calculator simplified/detailled) */}
        <div className="calculator__subwrapperCopy">
          {/* SideBar */}
          {(isWorksiteCalculatorDetailed || isWorksiteCalculatorSimplified) && (
            <motion.nav
              className="calculator__sidebar"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                delay: 0.5,
                x: { duration: 1 },
                opacity: { duration: 0.5 },
                default: { ease: 'linear' },
              }}
            >
              {/* sidebar navigation */}
              {worksiteCalculatorNav.map((item) => {
                if (item.id !== null) {
                  return (
                    <Tooltip
                      style={{ transform: 'translate(20px, 2em)' }}
                      key={`item-${item.id}`}
                      title={item.comment}
                      placement="right"
                    >
                      <div
                        // className={item.id === worksiteCalculatorDetailedActive ? 'calculator__sidebar__item active' : 'calculator__sidebar__item' }
                        className={`calculator__sidebar__item ${
                          item.id === worksiteCalculatorDetailedActive
                            ? 'active'
                            : ''
                        } ${item.disabled === true ? 'disabled' : ''}`}
                        onClick={() => {
                          if (item.disabled === true) {
                            return;
                          }
                          // handleSubmit()();

                          // handleSubmit()(onSubmitScreenError);
                          if (
                            isValid &&
                            isValidFormTransport() &&
                            isValidFormGear()
                          ) {
                            changeSourceOfEmptyRows({
                              sideBarActiveId: item.id,
                            });
                            dispatch(
                              calculatorUpdate(
                                'worksiteCalculatorDetailedActive',
                                item.id
                              )
                            );
                          } else {
                            handleSubmit(onSubmitScreen)();
                          }
                        }}
                      >
                        <div className="calculator__sidebar__item--icon">
                          <img src={item.sidebarIcon} alt="" />
                        </div>
                        <div className="calculator__sidebar__item--result">
                          <h4 className="calculator__sidebar__item-result--title">
                            {item.sidebarTitle}
                          </h4>
                          <p>
                            {item.sidebarActive === 'energy' &&
                              `${calculSumEnergy()} ${item.sidebarUnity} `}
                            {item.sidebarActive === 'water' &&
                              `${calculSumWater()} ${
                                form.water.displayUnit === 'm3' ? 'm3' : 'L'
                              } `}
                            {item.sidebarActive === 'carriedAway' &&
                              `${calculateSumImported()} ${item.sidebarUnity} `}
                            {item.sidebarActive === 'evacuated' &&
                              `${calculateSumEvacuated()} ${
                                [
                                  TEMPLATE_TYPE_NAME.RE2020,
                                  TEMPLATE_TYPE_NAME.NOOCO,
                                  TEMPLATE_TYPE_NAME.OUTDOOR,
                                ].includes(templateType)
                                  ? item.sidebarUnity
                                  : 'kg'
                              } `}
                            {item.sidebarActive === 'specific' &&
                              `${calculSumSpecific()} ${item.sidebarUnity} `}
                            {item.sidebarActive === 'gear' &&
                              `${calculateSumGear()} ${item.sidebarUnity || 'L'} `}
                            {item.sidebarActive === 'transport' &&
                              `${calculSumTransport()} ${item.sidebarUnity} `}
                          </p>
                        </div>
                      </div>
                    </Tooltip>
                  );
                }
              })}

              {/* sidebar footer button */}
              <footer className="container__calcContent--footer">
                <div
                  className="calculator__footer__container"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    className="delete-button"
                    style={{ marginRight: '5px' }}
                    centeredIcon={<DeleteIcon />}
                    onClick={() => {
                      // dispatch(calculatorReset());
                      handleClickOpenDialog();
                    }}
                  />
                  <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {t('APP.CALCULATORWORKSITE.CALCULATORWORKSITE.PH_5CD2C2', {
                        defaultValue:
                          'Supprimer la calculatrice de chantier détaillé?',
                      })}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t('APP.CALCULATORWORKSITE.CALCULATORWORKSITE.PH_0BCA19', {
                          defaultValue:
                            'Attention: Tous les équipements et les valeurs de la calculatrice seront supprimés si vous continuez.',
                        })}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleCloseDialog}
                        className="close-button"
                        text={t(
                          'APP.CALCULATORWORKSITE.CALCULATORWORKSITE.PH_AE60B8',
                          { defaultValue: 'Annuler' }
                        )}
                      />

                      <Button
                        className="delete-button"
                        onClick={() => {
                          const data: FormValuesCalculatorWorksite = {
                            energy: {
                              energiesArray: [],
                            },
                            energyCalculatorSimplified: {
                              energySimplifiedArray: [],
                            },
                            water: {
                              displayUnit: 'm3',
                              zonesData: [],
                            },
                            importedEarthWork: [],
                            evacuatedEarthWorkIndividualHouse: [],
                            evacuatedEarthWork: [],
                            specific: {
                              specificArray: [],
                            },
                          };

                          dispatch(
                            calculatorUpdate('isWorksiteCalculatorDetailed', false)
                          );
                          dispatch(
                            calculatorUpdate('isWorksiteCalculatorSimplified', false)
                          );
                          dispatch(calculatorUpdate('isTotalGazoilSelected', false));
                          dispatch(calculatorUpdate('isInputByGearSelected', false));
                          dispatch(
                            calculatorUpdate('isInputByExcavatedSelected', false)
                          );
                          dispatch(calculatorUpdate('transportLines', []));

                          // dispatch(calculatorReset());
                          handleCloseDialog();
                          // dispatch(calculatorUpdate('loadingCalculator', true));
                          dispatch(changeValueEquipments('isLoadingProject', true));
                          dispatch(calculatorUpdate('loadingCalculator', true));
                          dispatch(
                            putBuildingCalculatorData(currentProjectId, {
                              calculatorData: data,
                            })
                          );
                          // console.log('currentProjectId', currentProjectId);
                          dispatch(calculatorReset());
                          // dispatch(calculatorUpdate('loadingCalculator', true));
                        }}
                        text={t(
                          'APP.CALCULATORWORKSITE.CALCULATORWORKSITE.PH_E831A1',
                          { defaultValue: 'Tout supprimer' }
                        )}
                      />
                    </DialogActions>
                  </Dialog>
                  <Button
                    className="close-button"
                    onClick={() => {
                      dispatch(calculatorReset());
                    }}
                    text={t('APP.CALCULATORWORKSITE.CALCULATORWORKSITE.PH_45102E', {
                      defaultValue: 'Fermer',
                    })}
                    style={{ marginRight: '5px' }}
                  />

                  <Button
                    className="save-button"
                    onClick={() => {
                      if (isValid && isValidFormTransport() && isValidFormGear()) {
                        const dataForm = getValues();

                        // dispatch(calculatorUpdate('isTransportErrorDisplay',
                        // false));

                        handleSubmit(onSubmitApi)(dataForm);
                      }
                    }}
                    text={t('APP.CALCULATORWORKSITE.CALCULATORWORKSITE.PH_991569', {
                      defaultValue: 'Enregistrer',
                    })}
                  />
                </div>
              </footer>
            </motion.nav>
          )}

          {/* main (include Main Choice calculator simplified/detailled) */}
          <div className="worksiteCalculatorChoice">
            <motion.main
              className={
                !isWorksiteCalculatorDetailed && !isWorksiteCalculatorSimplified
                  ? 'calculator__mainContainer'
                  : 'calculator__container'
              }
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                delay: 0.5,
                x: { duration: 1 },
                opacity: { duration: 0.2 },
                default: { ease: 'linear' },
              }}
            >
              {/* title header of screen */}
              <div className="container__header">
                {!isWorksiteCalculatorDetailed &&
                  !isWorksiteCalculatorSimplified && <h2>Calculette chantier</h2>}
                {(isWorksiteCalculatorDetailed ||
                  isWorksiteCalculatorSimplified) && (
                  <h2>
                    {worksiteCalculatorNav[worksiteCalculatorDetailedActive].title}
                  </h2>
                )}
              </div>

              {/* Screen: choice simplified or detailed calculator */}
              {!isWorksiteCalculatorDetailed &&
                !isWorksiteCalculatorSimplified &&
                !loadingCalculator && (
                  <>
                    <motion.div
                      // initial={{ opacity: 0, }}
                      // animate={{ opacity: 1 }}
                      // exit={{ opacity: 0 }}
                      // transition={{
                      //   delay: 0.5,
                      //   x: { duration: 1 },
                      //   opacity: { duration: 0.5},
                      //   default: { ease: "linear" }
                      // }}
                      className="container__calcContent"
                    >
                      <h3>
                        {t('APP.CALCULATORWORKSITE.CALCULATORWORKSITE.PH_2BD010', {
                          defaultValue:
                            'Choix du type de calcul de la contribution aux impacts du chantier de construction :',
                        })}
                      </h3>
                      <div className="container__calcContent--choice">
                        {CalculatorChoiceCard('detailled')}
                        <CalculatorSimplifiedCard />
                      </div>
                      <div className="container__calcContent--information">
                        {isRegulationProject && (
                          <>
                            <InfoIcon style={{ marginRight: '1rem' }} />
                            <p>
                              {t(
                                'APP.CALCULATORWORKSITE.CALCULATORWORKSITE.PH_53669E',

                                {
                                  value: [
                                    TEMPLATE_TYPE_NAME.RE2020,
                                    TEMPLATE_TYPE_NAME.NOOCO,
                                  ].includes(templateType)
                                    ? 'la RE2020'
                                    : 'E+C-',
                                  defaultValue:
                                    'Les deux calculettes permettent le calcul du chantier au sens de {value}',
                                }
                              )}
                            </p>
                          </>
                        )}
                      </div>
                    </motion.div>
                  </>
                )}

              {/* screen to display.  */}
              {(isWorksiteCalculatorDetailed ||
                isWorksiteCalculatorSimplified ||
                loadingCalculator) && (
                <>
                  {loadingCalculator && (
                    //  <Loader
                    //    variant="indeterminate"
                    //    disableShrink
                    //    style={{ display: 'block', color: "#73b7ec", margin: 'auto' }}
                    //    size={70}
                    //    thickness={4}
                    //  />
                    <Backdrop
                      open={loadingCalculator}
                      className={classes.backdrop}
                      onClick={handleClose}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  )}
                  {isCalculatorDetailedLoaded && (
                    <div
                      className={`container__main ${
                        isSmallScreenMode ? 'isSmallScreenMode' : ''
                      }`}
                    >
                      {componentToDisplay(
                        worksiteCalculatorNav[worksiteCalculatorDetailedActive]
                          .content
                      )}
                    </div>
                  )}
                </>
              )}
            </motion.main>
            {/* close button if choice calculator simplified or detailled not completed */}
            {!isWorksiteCalculatorDetailed && !isWorksiteCalculatorSimplified && (
              <footer className="container__calcContent--footerAlternative">
                <div className="calculator__footer__container">
                  <div></div>
                  <Button
                    className="close-button"
                    style={{
                      fontSize:
                        window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                    }}
                    onClick={() => {
                      dispatch(calculatorExit());
                    }}
                    text={t('APP.CALCULATORWORKSITE.CALCULATORWORKSITE.PH_3DE889', {
                      defaultValue: 'Fermer',
                    })}
                  />
                </div>
              </footer>
            )}
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default CalculatorWorksite;
