import './radarGraphic.scss';
import { InputLabel } from '@mui/material';
import { ResponsiveRadar } from '@nivo/radar';
import { StateDTO } from '../../../models/GeneralDTO';
import { divide } from 'lodash';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import GraphicHeader from '../GraphicHeader/GraphicHeader';
import React from 'react';
import formatNumber from '../../../services/mathematics/formatNumber';

const RadarGraphic = ({ onSubmit, onSubmitError, handleSubmit, getValues }) => {
  const { dataGraph, filters, keys, currentIndicator } = useSelector(
    (state: StateDTO) => state.simulator.dataForGraph
  );
  const { loadingGraph, information } = useSelector(
    (state: StateDTO) => state.simulator
  );

  return (
    <div className="radarGraphicWrapper">
      {loadingGraph && (
        <div className="graphic-spinner-wrapper">
          <div className="donut" />
        </div>
      )}

      {!loadingGraph && (
        <>
          <GraphicHeader
            onSubmit={onSubmit}
            onSubmitError={onSubmitError}
            handleSubmit={handleSubmit}
            getValues={getValues}
          />
          {/* sdf */}
          <motion.div
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.5 }}
            transition={{
              // delay: 0.2,
              // x: { duration: 0.2 },
              opacity: { duration: 0.2 },
              default: { ease: 'linear' },
            }}
          >
            <div className="graphicContainer">
              <ResponsiveRadar
                data={dataGraph || []}
                keys={keys || []}
                key={null}
                indexBy={'name'}
                tooltipFormat={(value) => {
                  return `${formatNumber(value)} ${currentIndicator?.unit}`;
                }}
                // margin={{ top: 70, right: 80, bottom: 60, left: 80 }}
                margin={{ top: 42, right: 50, bottom: 42, left: 50 }}
                // zIndex={10000}
                curve="linearClosed"
                borderWidth={2}
                borderColor={{ from: 'color' }}
                gridLevels={5}
                gridShape="circular"
                gridLabelOffset={36}
                enableDots={false}
                enableDotLabel={false}
                dotLabel="value"
                dotLabelYOffset={-12}
                colors={['hsl(168, 25%, 70%)', 'rgb(70, 221, 196)']}
                // colors={["#67c0b5", "#8b5aff", "#f76d5f", "#D67E8E", "#D69954", "#5ED674", "#74B9D6", "#96FFE5",
                //   "#D68369", "#9E7ED6", "#D6D05E", "#68EDA6", "#CA8B84", "#6CCFFF", "#72E336",
                //   "#FFC400", "#D5BBFF", "#FFD5BB", "#FE80B1", "#CCCCCC", "#D15239", "#5689E5",
                //   "#8C8C8C", "#F7A19A", "#FFDAA2", "#C7A0CB", "#FDB94D", "#00B274", "#CE59C1",
                //   "#FFF685", "#AD9268"]}
                fillOpacity={0.1}
                blendMode="multiply"
                animate={true}
                motionConfig="wobbly"
                isInteractive={true}
                sliceTooltip={({ data, index }) => {
                  const sortedData = [data[1], data[0]];

                  return (
                    <div
                      style={{
                        background: 'white',
                        padding: '9px 12px',
                        border: '1px solid #ccc',
                      }}
                    >
                      <div style={{ fontWeight: '700' }}>{index}</div>

                      {sortedData.map((variant) => (
                        <div
                          key={variant.id}
                          style={{
                            color: 'black',
                            padding: '3px 0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <div
                            style={{
                              width: '12px',
                              height: '12px',
                              backgroundColor: variant.color,
                              paddingRight: '12px',
                              marginRight: '12px',
                            }}
                          ></div>
                          <div>{`${variant.id} : `}&nbsp;</div>
                          <div style={{ fontWeight: '500' }}>{`${formatNumber(
                            variant.formattedValue
                          )}`}</div>{' '}
                          &nbsp;
                          {` ${
                            information.refUnit === 'total'
                              ? 'kg éq. CO₂'
                              : 'kg éq. CO₂/m²'
                          }`}
                        </div>
                      ))}
                    </div>
                  );
                }}
              />
            </div>
          </motion.div>
        </>
      )}
    </div>
  );
};

export default RadarGraphic;
