import './bundleWrapper.scss';
import { BreadCrumb } from '../../projects/HomeProject/BreadCrumb/BreadCrumb';
import { Checkbox } from '@material-ui/core';
import { ModalNooco, Notification } from '@photocarbone/nooco-ui';
import { Popover } from 'antd';
import { StateDTO } from '../../models/GeneralDTO';
import { SubMenu } from '../../projects/HomeProject/SubMenu/SubMenu';
import { updateBundleValue } from '../../../actions/bundle';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import BundleHeader from '../BundleHeader';
import BundleList from '../BundleList';
import React, { useEffect } from 'react';
import useCase from '../../projects/HomeProject/useCase/useCase';
import { useTranslation } from 'react-i18next';

const BundleWrapper = () => {
  const history = useHistory();
  const locationPath = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isInit } = useSelector(
    (state: StateDTO) => state.projects.projectsListMonitoring
  );
  const { editBundle, bundleNotification } = useSelector(
    (state: StateDTO) => state.bundle
  );
  const [locationToRedirect, setLocationToRedirect] = React.useState(null);
  const { selectHomeBreadCrumb, initBreadCrumb } = useCase();

  const [certificationBundleChoice, setCertificationBundleChoice, remove] =
    useLocalStorage('certificationBundleChoice', {
      Nooco: true,
      RE2020: false,
      EPCM: false,
    });

  const changeCertificationChoice = (newValue: boolean, key: string) => {
    let newCertificationChoice = {
      ...certificationBundleChoice,
      [key]: newValue,
    };
    let isOneKeyTrue = false;

    for (const key in newCertificationChoice) {
      if (newCertificationChoice[key]) {
        isOneKeyTrue = true;
      }
    }

    if (isOneKeyTrue) {
      setCertificationBundleChoice(newCertificationChoice);
    }
  };

  const renderReferentielChoice = () => {
    return (
      <div className="popover-referentiel-choice">
        <p>
          {t('APP.BUNDLEWRAPPER.INDEX.DROPDOWN', {
            defaultValue: 'Sélectionner les référentiels de choix des lots :',
          })}
        </p>{' '}
        <ul>
          <li>
            <Checkbox
              checked={certificationBundleChoice['Nooco']}
              onClick={(e) => changeCertificationChoice(e.target.checked, 'Nooco')}
              color="primary"
            />{' '}
            Nooco
          </li>
          <li>
            <Checkbox
              checked={certificationBundleChoice['RE2020']}
              onClick={(e) => changeCertificationChoice(e.target.checked, 'RE2020')}
              color="primary"
            />{' '}
            RE2020
          </li>
          <li>
            <Checkbox
              checked={certificationBundleChoice['EPCM']}
              onClick={(e) => changeCertificationChoice(e.target.checked, 'EPCM')}
              color="primary"
            />{' '}
            E+C-
          </li>
        </ul>
      </div>
    );
  };

  useEffect(() => {
    dispatch(updateBundleValue('isBundleScreen', true));

    if (!isInit) {
      initBreadCrumb();
    } else {
      selectHomeBreadCrumb({ needToReset: true });
    }

    return () => {
      dispatch(updateBundleValue('isBundleScreen', false));
      selectHomeBreadCrumb({ needToReset: true });
    };
  }, []);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (location.pathname === '/projects') {
        return;
      }

      if (editBundle.length > 0 && !locationToRedirect) {
        setLocationToRedirect(location.pathname);
        return false; // prevent navigation
      }
    });

    return () => {
      unblock();
    };
  }, [editBundle, locationToRedirect]);

  return (
    <>
      <ModalNooco
        title={t('APP.BUNDLEWRAPPER.INDEX.PH_B8F1F8', {
          defaultValue: 'Abandonner la saisie',
        })}
        isOpen={locationToRedirect}
        cancelFunction={() => setLocationToRedirect(null)}
        validateFunction={() => {
          console.log(history, 'history :D');
          dispatch(updateBundleValue('editBundle', []));
          setTimeout(() => {
            history.push(locationToRedirect);
          }, 300);
        }}
        closeOnOutsideClick={true}
        closeIcon={true}
      >
        <div>
          {t('APP.BUNDLEWRAPPER.INDEX.PH_051470', {
            defaultValue: 'Etes-vous sûr de vouloir abandonner la saisie ?',
          })}
        </div>
      </ModalNooco>
      <Notification
        onClose={() => dispatch(updateBundleValue('bundleNotification', null))}
        open={bundleNotification}
        notificationType={bundleNotification?.notificationType}
        text={bundleNotification?.text}
      />
      <div className="bundle-wrapper">
        <BundleHeader />
        <div className="breadcrumd-and-settings">
          <div>
            <BreadCrumb />
            <SubMenu />
          </div>
          <div className="referentiel-choice-wrapper">
            <Popover
              placement="bottom"
              content={renderReferentielChoice()}
              trigger="click"
            >
              <p>
                {t('APP.BUNDLEWRAPPER.INDEX.PH_C4F3BE', {
                  defaultValue: 'Paramètres avancés',
                })}
              </p>
            </Popover>
          </div>
        </div>
        <BundleList certificationBundleChoice={certificationBundleChoice} />
      </div>
    </>
  );
};

export default BundleWrapper;
