import {
  CREATE_NEW_PROJECT,
  DELETE_PROJECT,
  DOWNLOAD_PDF_RSEE,
  FETCH_ALL_GROUPS,
  DOWNLOAD_RSEE,
  FETCH_COMPANIES,
  FETCH_FLUIDS_COMPATIBLE_SHEETS,
  FETCH_INDICATORS_DATAS,
  FETCH_PROJECTS,
  FETCH_PROJECT_BY_ID,
  FETCH_RSEE_EXPORT,
  FETCH_SHORT_BUNDLE,
  GET_USER_METHOD_TO_LOGGED_IN,
  MAPPING_EXISTANT_PROJECT,
  PATCH_ZONES,
  POST_NEW_PROJECT,
  POST_RSEE_EXPORT,
  POST_RSEE_EXPORT_SUCCESS,
  SEARCH_ADDRESS,
  fetchAllGroups,
  fetchAllGroupsHandleError,
  fetchProjectById,
  fetchProjectByIdHandleError,
  handleRsetModal,
  loadAddress,
  loadAllGroups,
  loadCompanies,
  loadLots,
  loadNewProject,
  loadProjectById,
  loadProjects,
  postNewProject,
  savePdfRsee,
  saveRsee,
  updateFormValue,
} from '../actions/projects';
import {
  GENERATE_RSEE,
  GET_RSEE_PDF,
  GRAPH_TO_FULL_SCREEN,
} from '../actions/report';
import { POST_CREATE_COMPANY } from '../actions/access';
import {
  TYPE_AIRPORT,
  TYPE_BBCA,
  TYPE_DATACENTER,
  TYPE_EPCM,
  TYPE_EXPLOITATION,
  TYPE_NOOCO,
  TYPE_OUTDOOR,
  TYPE_PROTOTYPE,
  TYPE_RE2020,
} from '../app/globalVariable/typeProject';
import { addEquipsFromJson, addEquipsFromRSEE } from './functions/equipmentsImport';
import { buildingTypesMap } from '../app/services/mathematics/indicators/buildingTypesMap';
import { fetchImportDatas, fetchImportDate } from '../actions/import';
import {
  downloadFileOnS3,
  returnFormatProvider,
  returnProjectTypeFromProjectTypeTitle,
  returnProjectTypeTitleFromTemplateType,
} from '../app/shared/utils';
import { updateBundle } from 'typescript';
import { updateBundleValue } from '../actions/bundle';
import { updateExploitationValue } from '../actions/exploitation';
import API from '../app/services/API';
import axios from 'axios';
import config, { REGION } from '../config';
import moment from 'moment';
import useCase from '../app/projects/HomeProject/useCase/useCase';
import { downloadData } from 'aws-amplify/storage';
import { PROVIDERS } from '../app/globalVariable/authVariable';
import { signInWithRedirect } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from '@aws-amplify/auth';
import { basicConfigAmplify } from '../index';
import {
  LocationClient,
  SearchPlaceIndexForTextCommand,
} from '@aws-sdk/client-location';
import i18nInstance from '../i18n';

const api = new API();
const apiUserManagement = new API(true);

const projectsMiddleware = (store) => (next) => async (action) => {
  const { projects } = store.getState();
  const { id, indicatorId } = action;

  switch (action.type) {
    case FETCH_COMPANIES: {
      apiUserManagement.getAllCompanies().then((response) => {
        store.dispatch(loadCompanies(response, action.isSuperAdmin));
        api.fetchShortBundle().then((response) => {
          store.dispatch(
            updateFormValue(
              'bundleOptions',
              response.data
                .filter((bundle) => bundle.type_fiche === 'bundle')
                .sort((a, b) =>
                  a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                )
            )
          );
          store.dispatch(
            updateFormValue(
              'modeledProductOptions',
              response.data
                .filter((bundle) => bundle.type_fiche === 'custom_user')
                .sort((a, b) =>
                  a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                )
            )
          );
          store.dispatch(
            updateFormValue(
              'configuredSheetOptions',
              response.data
                .filter((bundle) => bundle.type_fiche === 'FC')
                .sort((a, b) => {
                  if (!a.name) return 1;
                  if (!b.name) return -1;
                  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                })
            )
          );
        });
        store.dispatch(fetchAllGroups());
      });
      next(action);
      break;
    }

    case FETCH_PROJECTS: {
      api
        .getProjects(
          action.isSearching || action.companyId ? 0 : projects.offsetProjects,
          projects.projectInput,
          projects.organisationFilters,
          projects.companyFilters,
          action.companyId || null,
          projects.searchTypeProject
        )
        .then((response) => {
          if (action.isSearching || action.companyId) {
            store.dispatch(updateFormValue('offsetProjects', 0));
          }

          store.dispatch(loadProjects(response.data, action.isSearching));
        });

      next(action);
      break;
    }

    case CREATE_NEW_PROJECT: {
      const projectType = projects.allGroups.projectTypes?.find(
        (project) => project.id === projects.creationProject?.selectedTypeProjectId
      ).name;
      const equipsBulkArray = [];
      let linkedFluids = {},
        equipmentsArray = [];
      let newBuildings = [...projects.buildingSections];
      let impacts = null;

      if (
        projectType === TYPE_RE2020 ||
        projectType === TYPE_EPCM ||
        projectType === TYPE_OUTDOOR
      ) {
        if (action.dataForm['parcelArea']) {
          impacts = {
            name: 'Parcelle',
            plotArea: action.dataForm['parcelArea'],
            BuildingTypeId: null,
            type: 'plot',
            Zones: [],
          };
        }

        if (projects.plotEquips) {
          impacts.contributeur = projects.plotEquips;
        }

        const buildingSite = {
          name: 'Chantier',
          BuildingTypeId: null,
          type: 'buildingSite',
          ddv: (
            projectType === TYPE_OUTDOOR
              ? 1
              : action.dataForm['buildingSiteDuration'] != null ||
                action.dataForm['buildingSiteDuration'] != ''
          )
            ? action.dataForm['buildingSiteDuration'] / 12.0
            : 1,
        };

        if (!projects.rsetmodalOpen) {
          // Deal with the parcell here
          if (projects.plotEquips) {
            const equipId = { id: 1 };

            let additionalLinkedFluids;

            [additionalLinkedFluids, equipmentsArray] = addEquipsFromRSEE(
              impacts,
              equipsBulkArray,
              1,
              equipmentsArray,
              impacts.name,
              '',
              equipId,
              projectType === TYPE_EPCM,
              true
            );
            linkedFluids = { ...linkedFluids, ...additionalLinkedFluids };
          }
          newBuildings.forEach((elem) => {
            const equipId = { id: 1 };

            const zones = elem.Zones.map((zone) => {
              const isNetwork = { ...buildingSite };
              let additionalLinkedFluids;

              isNetwork.IsConnectedToUrbanNetwork = zone.IsConnectedToUrbanNetwork;
              zone = { ...zone, Zones: [isNetwork] };

              if (zone.contributeur) {
                [additionalLinkedFluids, equipmentsArray] = addEquipsFromRSEE(
                  zone,
                  equipsBulkArray,
                  zone.id,
                  equipmentsArray,
                  zone.name,
                  elem.name,
                  equipId,
                  projectType === TYPE_EPCM
                );
                linkedFluids = { ...linkedFluids, ...additionalLinkedFluids };
              } else if (zone.jsonContributors) {
                // Elodie imports
                [additionalLinkedFluids, equipmentsArray] = addEquipsFromJson(
                  zone,
                  equipsBulkArray,
                  zone.id,
                  equipmentsArray,
                  zone.name,
                  elem.name,
                  equipId
                );

                linkedFluids = { ...linkedFluids, ...additionalLinkedFluids };
              }

              return zone;
            });

            elem.Zones = zones;
          });
        }
        if (impacts && impacts.name === 'Parcelle') {
          impacts = { ...impacts, Zones: [buildingSite] };
        }
      }

      const isModeInfrastructure =
        projectType === TYPE_OUTDOOR ||
        projectType === TYPE_AIRPORT ||
        projectType === TYPE_DATACENTER ||
        projectType === TYPE_PROTOTYPE;
      const isModeRe2020OrEpcm =
        projectType === TYPE_RE2020 || projectType === TYPE_EPCM;
      const isModeEcoOrExploitation =
        projectType === TYPE_NOOCO || projectType === TYPE_EXPLOITATION;
      const isModeBBCA = projectType === TYPE_BBCA;

      newBuildings.forEach((building) => {
        if (isModeBBCA) {
          // add default value
          if (!building.speculativeDevelopment) {
            building.speculativeDevelopment = false;
          }

          // add same buildingType Id as first zone for each building.
          const selectedBuildingTypeId = building.Zones[0].BuildingTypeId;
          building.Zones.map(
            (zone) => (zone.BuildingTypeId = selectedBuildingTypeId)
          );
        }

        building.Zones.forEach((zone) => {
          if (isModeInfrastructure) {
            const buildingTypeName =
              returnProjectTypeFromProjectTypeTitle(projectType);

            zone.area = action.dataForm['area'];
            if (
              buildingTypeName ===
              returnProjectTypeFromProjectTypeTitle(TYPE_PROTOTYPE)
            ) {
              zone.BuildingTypeId = 1;
            } else {
              zone.BuildingTypeId = projects.buildingTypes.find(
                (el) => el.name === buildingTypeName
              ).id;
            }
          }

          if (isModeRe2020OrEpcm) {
            if (typeof zone.logementInput === 'boolean') {
              zone.nbLgt = '1';
            }
            if (typeof zone.menInput === 'boolean') {
              zone.nbOccupants = '1';
            }
          }

          zone.area = zone.area || 1;

          if (isModeEcoOrExploitation) {
            const {
              LOGEMENT_COL,
              ENSEIGNEMENT_PRIMAIRE,
              ENSEIGNEMENT_SECONDAIRE,
              MAISON,
            } = buildingTypesMap;

            if (
              [
                LOGEMENT_COL,
                ENSEIGNEMENT_PRIMAIRE,
                ENSEIGNEMENT_SECONDAIRE,
                MAISON,
              ].includes(zone.BuildingTypeId)
            ) {
              zone.nbOccupants = '1';
            }

            if ([LOGEMENT_COL, MAISON].includes(zone.BuildingTypeId)) {
              zone.nbLgt = '1';
            }

            if (MAISON) {
              zone.sCombles = 0;
            }
          }

          if (
            projectType === TYPE_EXPLOITATION &&
            action.dataForm['refUnit'] === 'TOTAL'
          ) {
            zone.area = 1;
          }
        });
      });

      const copy = impacts
        ? [{ ...impacts, Zones: [...impacts.Zones, ...newBuildings] }]
        : [...newBuildings];

      let datasComp = !projects.datasComp ? null : projects.datasComp;

      const data = {
        CompanyId: action.dataForm['company'].id,
        SourceId: action.dataForm['source'],
        name: action.dataForm['name'],
        inCharge: action.dataForm['overseer'],
        address: action.dataForm['location'],
        ddv: action.dataForm['ddv'],
        PhaseId:
          projectType !== TYPE_RE2020 && projectType !== TYPE_EPCM
            ? 2
            : action.dataForm['projectPhase'],
        type: action.dataForm['typeProject'],
        permitDate:
          projectType !== TYPE_RE2020 && projectType !== TYPE_EPCM
            ? moment().format()
            : action.dataForm['permitDate'],
        buildingSections: copy,
        isRset: !!projects.rsetFile,
        datasComp: datasComp,
        equipsBulkArray:
          action.equipsBulkArray?.length != 0
            ? action.equipsBulkArray
            : equipsBulkArray,
        linkedFluids: action.linkedFluids
          ? { ...linkedFluids, ...action.linkedFluids }
          : linkedFluids,
        fcSheets: action.fcSheets,
        uuid: action.uuid,
        exploitDates: action.dataForm['exploitDates'],
        refUnit: action.dataForm['refUnit'],
        templateType: projectType,
        folderId: action.pathId,
        idFicheFcFilenames: action.idFicheFcFilenames,
      };

      if (
        !projects.rsetmodalOpen &&
        (equipmentsArray.length > 0 ||
          equipsBulkArray.filter((el) => el.donnees_configurateur).length > 0)
      ) {
        store.dispatch(
          handleRsetModal(true, equipsBulkArray, equipmentsArray, linkedFluids)
        );
      } else {
        store.dispatch(
          updateFormValue('creationProject', {
            ...projects.creationProject,
            creationProjectLoading: true,
          })
        );
        store.dispatch(postNewProject(data));
      }

      next(action);
      break;
    }

    case FETCH_FLUIDS_COMPATIBLE_SHEETS: {
      api.fetchFluidsCompatibleSheets(action.idIniesArray).then(async (res) => {
        store.dispatch(updateFormValue('fluidsCompatibleSheets', res.data));
      });

      next(action);
      break;
    }

    case POST_NEW_PROJECT: {
      api.createProject(action.data).then(async (res) => {
        store.dispatch(loadNewProject(res.data));
      });

      next(action);
      break;
    }

    case FETCH_ALL_GROUPS: {
      api
        .getAllGroups()
        .then((response) => {
          store.dispatch(loadAllGroups(response));
        })
        .catch((e) => {
          store.dispatch(fetchAllGroupsHandleError(e));
        });

      next(action);
      break;
    }

    case FETCH_PROJECT_BY_ID: {
      api
        .getProjectById(id, indicatorId || projects.currentIndicator.id)
        .then((response) => {
          if (response.status === 201 || action.byPassForS3) {
            Amplify.configure({
              ...basicConfigAmplify,
              Storage: {
                S3: {
                  bucket: config.project.BUCKET,
                  region: config.cognito.REGION,
                },
              },
            });

            const response = downloadFileOnS3(
              `${projects.user.username}/${id}.json`
            );

            response.then((res) => {
              res.text().then((string) => {
                // handle the String data return String

                let format = JSON.parse(string);

                let response = {
                  data: {
                    project: format.project,
                    urbanNetworks: format.urbanNetworks,
                  },
                };

                store.dispatch(loadProjectById(response, action.importToLdc));
              });
            });
          } else if (response.status === 200) {
            response.data.indicatorId = indicatorId;
            store.dispatch(loadProjectById(response, action.importToLdc));
          }
        })
        .catch((e) => {
          if (
            JSON.parse(JSON.stringify(e)).message ===
            'Request failed with status code 404'
          ) {
            store.dispatch(updateFormValue('isResponseProjectError', true));
            store.dispatch(
              updateFormValue(
                'projectErrorMessage',
                'Votre projet est inexistant ou a été supprimé'
              )
            );
          }
          if (JSON.parse(JSON.stringify(e)).message === 'Network Error') {
            store.dispatch(updateFormValue('isResponseProjectError', true));
            store.dispatch(
              updateFormValue(
                'projectErrorMessage',
                'Une erreur est survenue. Veuillez contacter le support'
              )
            );
          }

          store.dispatch(fetchProjectByIdHandleError(e));
        });

      next(action);
      break;
    }

    case SEARCH_ADDRESS: {
      const { credentials } = await fetchAuthSession();
      const awsLocationClient = new LocationClient({
        region: REGION,
        credentials,
      });

      const command = new SearchPlaceIndexForTextCommand({
        IndexName: config.location.IndexName,
        Text: action.search,
        MaxResults: 15,
        FilterCountries: action.countryCode ? [action.countryCode] : undefined,
        FilterCategories: ['PostalCodeType'],
        Language: localStorage.getItem('i18nLanguage') || 'fr',
      });

      try {
        const response = await awsLocationClient.send(command);
        store.dispatch(
          loadAddress(
            response.Results.map((result) => {
              result.Place.Text = `${result.Place.Municipality}, ${result.Place.PostalCode}`;
              return result.Place;
            })
          )
        );
      } catch (error) {
        console.error(error);
        store.dispatch(loadAddress([]));
      }
      next(action);
      break;
    }

    case FETCH_INDICATORS_DATAS: {
      store.dispatch(fetchProjectById(projects.currentProject.id, action.id));

      next(action);
      break;
    }

    case GENERATE_RSEE: {
      api.postProjectRsee(action.projectId);

      next(action);
      break;
    }

    case DOWNLOAD_PDF_RSEE: {
      if (action.data.error === true) {
        alert(action.data.message);
        store.dispatch(updateFormValue('loaderPdf', false));

        next(action);
        break;
      }

      let rsetKey = action.data.rset.rseeKey;
      let rsenvKey = action.data.rsenv.rseeKey;

      const promises = [];

      Amplify.configure({
        ...basicConfigAmplify,
        Storage: {
          S3: {
            bucket: config.config.BUCKET,
            region: config.cognito.REGION,
          },
        },
      });

      promises.push(downloadFileOnS3(rsetKey));

      promises.push(downloadFileOnS3(rsenvKey));

      const res = await Promise.all(promises);

      const rsetFile = await res[0].arrayBuffer();
      const rsenvFile = await res[1].arrayBuffer();

      const projectName = action.data.rsenv.projectName;

      res[0] = rsetFile;
      res[1] = rsenvFile;

      store.dispatch(savePdfRsee(res, projectName));

      next(action);
      break;
    }

    case GET_RSEE_PDF: {
      api.getRseePdf(action.projectId);

      next(action);
      break;
    }

    case DOWNLOAD_RSEE: {
      api.getProjectRseeFile(action.projectId).then(async (response) => {
        const promises = [];

        Amplify.configure({
          ...basicConfigAmplify,
          Storage: {
            S3: {
              bucket: config.config.BUCKET,
              region: config.cognito.REGION,
            },
          },
        });

        promises.push(downloadFileOnS3(response.data.rseeKey));

        for (const url of response.data.fcSheets) {
          promises.push(axios.get(url));
        }

        const res = await Promise.all(promises);
        const rseeFile = await res[0].text();
        const projectName = response.data.projectName;

        res[0] = rseeFile;

        store.dispatch(saveRsee(res, projectName));
      });

      next(action);
      break;
    }

    case PATCH_ZONES: {
      if (action.reason) {
        api.deleteZones(projects.currentProject.id, action.datas).then(() => {
          let buildingToKeep = action.settingsData.filter(
            (zone) => zone.Zones.length !== 0
          );
          store.dispatch(updateFormValue('settingsDatas', buildingToKeep));
          store.dispatch(
            updateFormValue('currentProject', {
              ...projects.currentProject,
              Zones: buildingToKeep,
            })
          );
          store.dispatch(updateFormValue('loadingDeleteBuilding', false));
          store.dispatch(updateFormValue('loadingDeleteZone', false));
        });
      } else {
        api
          .postProjectZones(projects.currentProject.id, action.datas)
          .then((response) => {
            store.dispatch(updateFormValue('zoneCreated', response.data));
            store.dispatch(updateFormValue('loadingCreationZone', false));
            store.dispatch(updateFormValue('loadingCreationBuilding', false));
          });
      }

      next(action);
      break;
    }

    case FETCH_RSEE_EXPORT: {
      const id = projects.currentProject.id;
      const zones = projects.zones.filter((e) => e.type === 'building');

      const dataFormated = {
        acvDesignOffice: {
          name: undefined,
          siret: '',
          adress: undefined,
          postal: '',
          city: undefined,
        },
        earthQuakeZone: undefined,
        buildings: [],
        rsenvVersion: '',
      };

      dataFormated.buildings = zones.map((building) => {
        return {
          ZoneId: building.id,
          name: building.name,
          type_structure_principale: null,
          elements_prefabriques: null,
          materiau_principal: null,
          materiau_remplissage_facade: null,
          mur_mode_isolation: null,
          mur_nature_isolant: null,
          mur_revetement_ext: null,
          type_fondation: null,
          type_plancher: null,
          plancher_mode_isolation: null,
          plancher_nature_isolant: null,
          plancher_nature_espace: null,
          type_toiture: null,
          toiture_mode_isolation: null,
          toiture_nature_isolant: null,
          toiture_vegetalisee: null,
          toiture_couverture: null,
          type_menuiserie: null,
          type_pm: null,
          vecteur_energie_principal_ch: null,
          vecteur_energie_principal_ecs: null,
          vecteur_energie_principal_fr: null,
          generateur_principal_ch: null,
          generateur_ch_liaison: null,
          emetteur_principal_ch: null,
          type_ventilation_principale: null,
          gestion_active: null,
          type_eclairage: null,
        };
      });

      api.getRseeExport(id).then((response) => {
        if (response.data.acvDesignOffice) {
          dataFormated.acvDesignOffice = response.data.acvDesignOffice;

          if (
            response.data.acvDesignOffice.siret === null ||
            response.data.acvDesignOffice.siret === undefined
          ) {
            dataFormated.acvDesignOffice.siret = '';
          }

          if (
            response.data.acvDesignOffice.postal === null ||
            response.data.acvDesignOffice.postal === undefined
          ) {
            dataFormated.acvDesignOffice.postal = '';
          }
        }

        if (response.data.earthQuakeZone) {
          dataFormated.earthQuakeZone = response.data.earthQuakeZone;
        }

        if (response.data.rsenvVersion) {
          dataFormated.rsenvVersion = response.data.rsenvVersion;
        }

        if (response.data.formData) {
          dataFormated.formData = response.data.formData;
        }

        dataFormated.digitalModel = response.data.digitalModel;
        dataFormated.projectOwnerSiret = response.data.projectOwnerSiret || null;
        dataFormated.projectOwnerType = response.data.projectOwnerType || null;

        if (response.data.buildings) {
          dataFormated.buildings = dataFormated.buildings.map((building) => {
            response.data.buildings.map((buildingFromGet) => {
              if (building.ZoneId === buildingFromGet.ZoneId) {
                building = buildingFromGet;
              }
            });

            return building;
          });
        }

        store.dispatch(updateFormValue('isLoadingGenerateRSEnv', false));
        store.dispatch(updateFormValue('generateRSEnv', dataFormated));
      });

      next(action);
      break;
    }

    case POST_RSEE_EXPORT: {
      const id = projects.currentProject.id;

      api
        .postRseeExport(id, action.data)
        .then(() => {
          if (action.shouldStartExportRsee) {
            store.dispatch({ type: POST_RSEE_EXPORT_SUCCESS });
          }
        })
        .catch((error) => {
          console.error('Error when posting the rsee export, error is : ', error);
        });

      next(action);
      break;
    }

    case MAPPING_EXISTANT_PROJECT: {
      let data = {
        id: projects.refStates.refProject.id,
        name: projects.refStates.refProject.name,
        rename: projects.refStates.refProjectRename,
        import: projects.refStates.refImport,
      };

      api.mappingProject(data, projects.currentProject.Company.id).then((result) => {
        store.dispatch(fetchImportDate(projects.currentProject.id));
        store.dispatch(
          fetchImportDatas(projects.currentProject.id, result.data, null, null, 0)
        );
        store.dispatch(updateFormValue('currentImport', result.data));
        store.dispatch(
          updateFormValue('refStates', {
            ...projects.refStates,
            refModal: false,
            refMessage: 'Ce projet a bien été ajouté comme référence',
          })
        );
      });

      next(action);
      break;
    }
    case DELETE_PROJECT: {
      api.deleteProject(projects.currentProject.id).then((result) => {
        store.dispatch(
          updateFormValue(
            'projects',
            projects.projects.filter(
              (project) => project.id !== projects.currentProject.id
            )
          )
        );
        store.dispatch(updateExploitationVaxlue('deletedStatus', true));
      });

      next(action);
      break;
    }
    case GET_USER_METHOD_TO_LOGGED_IN: {
      const handleSignInRedirectSSO = (resultApi) => {
        let provider = returnFormatProvider(resultApi);

        return resultApi === PROVIDERS.GOOGLE_PROVIDER
          ? signInWithRedirect(provider)
          : signInWithRedirect({ provider });
      };

      apiUserManagement
        .getUserMethodToLoggedIn(projects.userEmail)
        .then((result) => {
          let resultApi = result.data;

          if (resultApi) {
            handleSignInRedirectSSO(resultApi);
          } else {
            store.dispatch(updateFormValue('noProvider', true));
          }
        });

      next(action);
      break;
    }
    default:
      next(action);
  }
};
export default projectsMiddleware;
