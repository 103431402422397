/* eslint-disable no-unused-vars */
import './boarding.scss';
import { Button, Dropdown, SearchGroup } from '@photocarbone/nooco-ui';
import { NewEquipmentDTO } from '../../models/ExploitationDTO';
import { Popover, Slider } from 'antd';
import { StateDTO } from '../../models/GeneralDTO';
import { Table } from 'antd';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useVT } from 'virtualizedtableforantd4';
import API from '../../services/API';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ButtonUi from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import MenuItem from '@mui/material/MenuItem';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useEffect, useRef, useState } from 'react';
import SelectUi from '@mui/material/Select';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import XLSX from 'xlsx';
import caracs from '../../../assets/images/import/specification.png';
import exportExcel from '../../../assets/images/equipments/excel.png';

import { ProjectDTO } from '../../../models/ProjectDTO';
import { useTranslation } from 'react-i18next';
import {
  crudBoarding,
  fetchListActions,
  incrementOffset,
  resetNewEquipment,
} from '../../../actions/exploitation';

import { AllotmentNodeDTO } from '../../models/ProjectDTO';
import { MasterDataEquipmentDTO } from '../../models/SimulatorDTO';
import {
  findMdByAllotmentNodeId,
  findParentNode,
  handleAllotmentNodes,
  handleKeyPress,
  returnSearchBarOptionsFiltered,
} from '../../shared/utils';
import {
  useCreateBoardingEquipment,
  useDeleteBoardingEquipment,
  useGetBoardingEquipments,
  usePatchBoardingEquipment,
} from '../../../hooks/useQuery/exploitation';
import { useIntersection } from 'react-use';
import { useQueryClient } from 'react-query';
import formatNumber from '../../services/mathematics/formatNumber';

const add = require('../../../assets/images/exploitation/add.png');

enum BoardingTabs {
  EQUIPMENT = 'Equipement / matériau',
  ENERGY = 'Energie',
  WATER = 'Eau',
  WASTE = 'Déchets',
  TRANSPORT = 'Transport',
}

let api = new API();

interface BoardingProps {
  newEquipment: NewEquipmentDTO;
  updateExploitationValue: Function;
  allGroups: {
    type: MasterDataEquipmentDTO[];
    flatAllotments: AllotmentNodeDTO[];
  };
  getCaracs: Function;
  offset: number;
  createNewEquipment: Function;
  boardingListDatas: {}[];
  isBoardingFull: boolean;
  loadingBoarding: boolean;
  currentProject: ProjectDTO;
  zones: {}[];
}

type MenuItemsOption = {
  className: string;
  value: string;
  stateText: string;
  score: string;
};

function Alert(props: {}) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Boarding = ({
  newEquipment,
  updateExploitationValue,
  allGroups,
  getCaracs,
  offset,
  createNewEquipment,
  boardingListDatas,
  isBoardingFull,
  loadingBoarding,
  currentProject,
  zones,
}: BoardingProps) => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const createBoardingEquipment = useCreateBoardingEquipment(currentProject);
  const deleteBoardingEquipment = useDeleteBoardingEquipment(currentProject);
  const patchBoardingEquipment = usePatchBoardingEquipment();

  const { t } = useTranslation();

  const renderStateColor = (
    score: number | string | undefined,
    context: 'add' | 'edit'
  ) => {
    if (context === 'edit') {
      return score === 100
        ? 'new'
        : score === 0
        ? 'grey'
        : score < 50
        ? 'red'
        : score >= 50 && score < 75
        ? 'yellow'
        : 'green';
    } else {
      return score === 'new'
        ? 'new'
        : score === 'good'
        ? 'green'
        : score === 'medium'
        ? 'yellow'
        : score === 'bad'
        ? 'red'
        : score === 'to replace'
        ? 'grey'
        : '';
    }
  };

  const renderStateDropdown = (
    context: 'add' | 'edit',
    remainingWear?: number,
    rowBoardingEquipment: any
  ) => {
    const menuItemsOptions: MenuItemsOption[] = [
      {
        className: 'new',
        value: 'new',
        stateText: 'Neuf',
        score: '100%',
      },
      {
        className: 'green',
        value: 'good',
        stateText: 'Bon état',
        score: '75%',
      },
      {
        className: 'yellow',
        value: 'medium',
        stateText: 'Etat moyen',
        score: '50%',
      },
      {
        className: 'red',
        value: 'bad',
        stateText: 'Mauvais état',
        score: '25%',
      },
      {
        className: 'grey',
        value: 'to replace',
        stateText: 'A remplacer',
        score: '0%',
      },
    ];
    return (
      <SelectUi
        MenuProps={{
          disableScrollLock: true,
        }}
        style={{ width: '100%', height: '40px' }}
        value={context === 'add' ? newEquipmentState : remainingWear}
        renderValue={(value) => {
          return (
            <div
              className={`etatCircle ${renderStateColor(
                context === 'add' ? newEquipmentState : remainingWear,
                context
              )}`}
            ></div>
          );
        }}
        onChange={(e) =>
          context === 'add'
            ? updateExploitationValue('newEquipmentState', e.target.value)
            : patchBoardingEquipment.mutate({
                id: currentProject.id,
                newEquipment: {
                  id: rowBoardingEquipment.id,
                  state: e.target.value,
                },
              })
        }
        placeholder="Etat"
        variant="outlined"
        className="input-boarding researchBar"
      >
        {menuItemsOptions.map((menuItem) => {
          return (
            <MenuItem
              value={menuItem.value}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                style={{ marginRight: '15px' }}
                className={`etatCircle ${menuItem.className}`}
              ></div>{' '}
              <p style={{ marginRight: '15px', marginBottom: 0 }}>
                {menuItem.stateText}
              </p>{' '}
              <p style={{ marginBottom: 0 }}>{menuItem.score}</p>
            </MenuItem>
          );
        })}
      </SelectUi>
    );
  };

  let groups = [
    ...allGroups.type.filter(
      (el) => !el.isObsolete && (!el.Child || el.Child?.length === 0)
    ),
  ];

  const {
    selectedRowKeys,
    listActionDatas,
    loadingActions,
    openAlert,
    newEquipmentQuantity,
    newEquipmentState,
    boardingActiveTab,
  } = useSelector((state: StateDTO) => state.exploitation);
  const {
    bundleOptions,
    nodesAvailableForProjectType,
    certifiedAllotments,
    currentAllotment,
  } = useSelector((state: StateDTO) => state.projects);

  const searchBarOptionsFiltered = returnSearchBarOptionsFiltered({
    allAllotments: allGroups.flatAllotments,
    allotmentsAvailable: nodesAvailableForProjectType,
    iniesOptions: [],
    bundleOptions,
  });

  const [vt, set_components] = useVT(
    () => ({
      scroll: {
        y:
          window.screen.availHeight < 950
            ? window.screen.availHeight - 490
            : window.screen.availHeight - 500,
        x: 700,
      },
    }),
    []
  );

  let zoneIsConnectedToUrbanNetwork = zones.find(
    (el) => el.isConnectedToUrbanNetwork
  );

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useGetBoardingEquipments(
    currentProject.id,
    offset,
    boardingActiveTab,
    allGroups
  );

  useEffect(() => {
    if (!loadingActions && listActionDatas.length === 0) {
      dispatch(fetchListActions(id, t));
    }
  }, []);

  useEffect(() => {
    const options = {
      root: null, // Set the root element to the viewport for simplicity
      rootMargin: '0px',
      threshold: 0.5, // Define the threshold value for intersection
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (data?.pages.find((page) => page.length < 20)) {
      updateExploitationValue('isBoardingFull', true);
      observer.disconnect();

      return;
    }

    if (tableRef.current) {
      const tableBody = document.querySelector('.ant-table-tbody');
      const lastRow = tableBody.lastElementChild;

      if (lastRow && !isBoardingFull) {
        observer.observe(lastRow);
      }
    }

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, [offset, data]);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !isBoardingFull) {
        // The table has reached the intersection point, load more data
        fetchNextPage();
      }
    });
  };

  useEffect(() => {
    if (data?.pages.find((page) => page.length < 20)) {
      updateExploitationValue('isBoardingFull', true);
    }
  }, [data]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) =>
      updateExploitationValue('selectedRowKeys', selectedRowKeys),
    columnWidth: 50,
    checkStrictly: false,
  };

  // get Caracs everytime MD change
  useEffect(() => {
    if (newEquipment.AllotmentNode && !newEquipment.type_fiche) {
      getCaracs();
    }
  }, [newEquipment.AllotmentNode]);

  const handleChangeCarac = (newValue, carac) => {
    let newCaracs = newEquipment.caracs.caracs.map((el) => {
      if (el.id === carac.id) {
        return {
          ...carac,
          value: [newValue],
        };
      } else {
        return el;
      }
    });

    updateExploitationValue('newEquipment', {
      ...newEquipment,
      caracs: { ...newEquipment.caracs, caracs: newCaracs },
    });
  };

  // display of carac popover
  const renderCaracPopOver = () => {
    return (
      <div>
        {newEquipment.caracs.caracs
          .sort(
            (a, b) =>
              (a.id === newEquipment.MasterDataEquipment?.secondDiscreteId) -
                (b.id === newEquipment.MasterDataEquipment?.secondDiscreteId) ||
              (a.id === newEquipment.MasterDataEquipment?.discreteId) -
                (b.id === newEquipment.MasterDataEquipment?.discreteId) ||
              (a.id === newEquipment.MasterDataEquipment?.xAxisId) -
                (b.id === newEquipment.MasterDataEquipment?.xAxisId)
          )
          .map((carac) => {
            let caracListOptions = carac.caracList;

            if (
              newEquipment.caracs.caracs?.find(
                (el) => el.id === newEquipment.caracs.caracCombos?.id
              )?.value &&
              newEquipment.caracs.caracCombos.valueList.find(
                (el) =>
                  el.value ===
                    newEquipment.caracs.caracs?.find(
                      (car) => car.id === newEquipment.caracs.caracCombos.id
                    )?.value[0] && carac.name === el.name
              )
            ) {
              caracListOptions = newEquipment.caracs.caracCombos.valueList.find(
                (el) =>
                  el.value ===
                  newEquipment.caracs.caracs?.find(
                    (el) => el.id === newEquipment.caracs.caracCombos.id
                  )?.value[0]
              )?.valueList;
            }

            return (
              <div className="exploitation-carac" key={carac.id}>
                <p>{carac.name} :</p>
                {!carac.values || carac.type !== 'list' ? (
                  <TextField
                    style={{ width: '50%', marginLeft: '10px' }}
                    defaultValue={carac.defaultValue}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => handleChangeCarac(e.target.value, carac)}
                  />
                ) : (
                  <Dropdown
                    disabled={
                      newEquipment.caracs.caracCombos
                        ? newEquipment.caracs.caracCombos.valueList.find(
                            (el) => el.id === carac.id
                          ) &&
                          carac.id !== newEquipment.caracs.caracCombos.id &&
                          !newEquipment.caracs.caracs?.find(
                            (el) => el.id === newEquipment.caracs.caracCombos.id
                          )?.value
                        : false
                    }
                    treeCheckable={false}
                    onChange={(value) => handleChangeCarac(value, carac)}
                    style={{ marginLeft: '10px', width: '150px' }}
                    onSelect={() => {}}
                    variant="outlined"
                    className="input-boarding researchBar"
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    options={
                      caracListOptions?.map((el) => ({
                        ...el,
                        title: el.value,
                      })) || []
                    }
                  ></Dropdown>
                )}

                <p>{carac.unit}</p>
              </div>
            );
          })}
      </div>
    );
  };

  // Function to check input to create new equipment.
  const checkEquipmentValidation = () => {
    let basicCheck =
      !newEquipmentQuantity ||
      (!newEquipmentState && boardingActiveTab === BoardingTabs.EQUIPMENT);

    if (newEquipment.SheetId && !basicCheck) {
      return false;
    } else if (
      !newEquipment.MasterDataEquipment ||
      basicCheck ||
      !newEquipment.caracs?.caracs ||
      newEquipment.caracs?.caracs?.find((el) => el?.value?.length !== 1)
    ) {
      return true;
    }

    return false;
  };

  const renderFooter = () => {
    return (
      <div className="boarding-footer">
        <div>
          <img
            style={{ width: '25px', height: '25px', marginTop: '10px' }}
            src={add}
          />
        </div>
        <div style={{ width: '30%' }}>
          <SearchGroup
            t={t}
            key={boardingActiveTab}
            gridStyle={'[first] 20% [line2] 60% [line3] 5%'}
            searchBarOptionsFiltered={searchBarOptionsFiltered}
            certifiedAllotments={certifiedAllotments}
            triggerBreadcrumb={() => {
              // updateFormValue('openBreadcrumb', true);
            }}
            projectTypeId={currentProject.ProjectTypeId}
            bundleOptions={bundleOptions}
            findParentNode={findParentNode}
            allAllotments={allGroups.flatAllotments}
            allotmentsAvailable={nodesAvailableForProjectType.filter(
              (el) => !el.isObsolete
            )}
            api={api}
            dropdownProps={{
              onSelect: () => {},
              onChange: (value) => {
                if (typeof value === 'number') {
                  let nodeNooco = nodesAvailableForProjectType.find(
                    (node) => node.id === value
                  );
                  let currentMd = groups.find(
                    (md) => md.id === nodeNooco.MasterDataEquipmentId
                  );

                  updateExploitationValue('newEquipment', {
                    ...newEquipment,
                    AllotmentNode: nodeNooco,
                    MasterDataEquipment: currentMd,
                    nodesLinkToMasterData: handleAllotmentNodes(
                      currentMd,
                      currentAllotment,
                      allGroups.flatAllotments,
                      currentProject
                    ),
                  });
                } else if (value && value.type_fiche) {
                  updateExploitationValue('newEquipment', {
                    type_fiche: value.type_fiche,
                    SheetId: value.id,
                    AllotmentNode:
                      value.type_fiche !== 'bundle' ? value.AllotmentNodes[0] : null,
                    nodesLinkToMasterData: value.AllotmentNodes.map(
                      (node) =>
                        findParentNode(node, allGroups.flatAllotments, [], [])[0]
                    ),
                    MasterDataEquipment: null,
                  });
                } else {
                  updateExploitationValue('newEquipment', {
                    ...newEquipment,
                    AllotmentNode: null,
                    MasterDataEquipment: null,
                    SheetId: null,
                  });
                }
              },
              placeholder: 'Rechercher...',
              treeCheckable: false,
              value: null,
            }}
            dropdownWidth="200px"
            selectProps1={{
              onChange: (value) => {
                resetNewEquipment();
              },
              value:
                boardingActiveTab === BoardingTabs.EQUIPMENT
                  ? 'equipements'
                  : boardingActiveTab === BoardingTabs.TRANSPORT
                  ? 'transports'
                  : boardingActiveTab.toLowerCase(),
              filterOptions:
                boardingActiveTab === BoardingTabs.EQUIPMENT
                  ? ['equipements', 'inies']
                  : [boardingActiveTab.toLowerCase()],
            }}
            selectProps2={{
              ListType: ['Nooco'],
              noocoOptions: newEquipment?.nodesLinkToMasterData || [],
              onChange: (value) => {
                let nodeSelected = newEquipment.nodesLinkToMasterData
                  ?.find((parentNode) => parentNode.name === value)
                  .details.find((node) => node.MasterDataEquipmentId);

                updateExploitationValue('newEquipment', {
                  ...newEquipment,
                  AllotmentNode: nodeSelected,
                });
              },
              value: newEquipment.AllotmentNode
                ? findParentNode(
                    newEquipment.AllotmentNode,
                    allGroups.flatAllotments,
                    [],
                    []
                  )
                : [],
            }}
            selectWidth="100px"
          />
        </div>
        <div style={{ width: '5%' }}>
          <TextField
            value={newEquipmentQuantity}
            key={createBoardingEquipment.isSuccess}
            onChange={(e) =>
              updateExploitationValue('newEquipmentQuantity', e.target.value)
            }
            onKeyPress={handleKeyPress}
            style={{ width: '100%' }}
            placeholder="Quantité"
            variant="outlined"
            className="input-boarding researchBar"
          />
        </div>
        <div className="center-block" style={{ width: '4%' }}>
          <p style={{ textAlign: 'center' }}>
            {newEquipment.MasterDataEquipment
              ? newEquipment.MasterDataEquipment.unit
              : ''}
          </p>
        </div>
        {boardingActiveTab === BoardingTabs.EQUIPMENT && (
          <div style={{ width: '7%' }}>{renderStateDropdown('add')}</div>
        )}

        {newEquipment.caracs?.caracs?.length > 0 && (
          <div className="center-block" style={{ width: '4%' }}>
            <Popover
              defaultVisible={true}
              placement="top"
              title={'Caractéristiques'}
              content={renderCaracPopOver}
              trigger="click"
            >
              <div>
                <img
                  src={caracs}
                  style={{
                    marginBottom: '14px',
                    marginLeft: '10px',
                    width: '27px',
                    filter: newEquipment.caracs?.caracs.find(
                      (el) => !el.value || el.value.length !== 1
                    )
                      ? 'invert(21%) sepia(52%) saturate(5667%) hue-rotate(354deg) brightness(88%) contrast(133%)'
                      : undefined,
                  }}
                  alt=""
                />
              </div>
            </Popover>
          </div>
        )}
        <Button
          onClick={() => {
            createBoardingEquipment.mutate({
              id: currentProject.id,
              newEquipment: {
                type: returnTypeOfEquipmentAdded(),
                ZoneId: currentProject.Zones[0].Zones[0].id,
                SheetId: newEquipment.SheetId,
                AllotmentNodes:
                  newEquipment.type_fiche === 'bundle'
                    ? null
                    : [newEquipment.AllotmentNode],
                quantity: newEquipmentQuantity,
                state: newEquipmentState,
                caracs: newEquipment.caracs?.caracs,
              },
            });

            dispatch(resetNewEquipment());
          }}
          style={{ marginLeft: 'auto', alignSelf: 'center' }}
          disabled={checkEquipmentValidation()}
          className="purple-button"
          text="Ajouter"
        />
      </div>
    );
  };

  // Core Snackbar body for verification when delete equipment.
  const renderDeleteVerification = (): JSX.Element => (
    <div>
      <p style={{ color: 'white' }}>
        {t('APP.BOARDING.INDEX.DELETE_VERIFICATION.TITLE', {
          defaultValue:
            'Attention si vous supprimez ces équipements/matériaux, les actions les concernant seront également supprimées.',
        })}
      </p>
      <div style={{ display: 'inline-block', marginLeft: '72%' }}>
        <Button
          onClick={() => updateExploitationValue('openAlert', false)}
          className="close-button"
          text="Annuler"
        />
        <Button
          onClick={() => {
            updateExploitationValue('openAlert', false);

            deleteBoardingEquipment.mutate({
              currentProjectId: currentProject.id,
              ids: selectedRowKeys,
            });
          }}
          className="purple-button"
          text={t('APP.BOARDING.INDEX.DELETE_VERIFICATION.BUTTON.CONFIRM', {
            defaultValue: 'Confirmer',
          })}
        />
      </div>
    </div>
  );

  const renderDeleteIcon = (): JSX.Element => (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '0' }}>
      <IconButton
        style={{ opacity: selectedRowKeys.length > 0 ? 1 : 0, display: 'flex' }}
        aria-label="delete"
      >
        <Tooltip
          title={t('APP.BOARDING.INDEX.PH_94051B', {
            defaultValue: 'Supprimer les éléments selectionnés',
          })}
        >
          <DeleteIcon
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              if (
                listActionDatas.find((el) => selectedRowKeys.includes(el.targetId))
              ) {
                updateExploitationValue('openAlert', true);
              } else {
                deleteBoardingEquipment.mutate({
                  currentProjectId: currentProject.id,
                  ids: selectedRowKeys,
                });
              }
            }}
          />
        </Tooltip>
      </IconButton>
    </div>
  );

  // Method to change datas betweens tabs change

  const boardingChangeTab = (tab) => {
    queryClient.clear();
    updateExploitationValue('boardingActiveTab', tab);
    updateExploitationValue('offset', 0);
  };

  const returnBoardingColumns = () => {
    let baseColumns = [
      {
        title: boardingActiveTab,
        dataIndex: 'equipement',
        width: 380,
        key: 'equipement',
        render: (value, record) => {
          let sourceName = record.customName || record.originalName;

          return (
            <p>
              {record.Sheet?.name}{' '}
              {sourceName ? (
                <Tooltip title={sourceName}>
                  <LocalOfferOutlinedIcon style={{ marginLeft: '15px' }} />
                </Tooltip>
              ) : null}{' '}
            </p>
          );
        },
      },
      {
        title: t('APP.BOARDING.INDEX.PH_D8EA0C', { defaultValue: 'Quantité' }),
        dataIndex: 'quantity',
        key: 'quantity',
        width: 110,
      },
      {
        title:
          boardingActiveTab === BoardingTabs.TRANSPORT
            ? t('APP.BOARDING.TABS.UNIT_BY_YEAR', { defaultValue: 'Unité/an' })
            : t('APP.BOARDING.INDEX.PH_65D7E6', { defaultValue: 'Unité' }),
        dataIndex: 'unit',
        key: 'unit',
        width: 100,
        render: (value, record) => (
          <p>
            {
              findMdByAllotmentNodeId(
                record.AllotmentNodeId,
                allGroups.flatAllotments,
                allGroups.type
              ).unit
            }
          </p>
        ),
      },
      {
        title: () =>
          boardingActiveTab !== BoardingTabs.EQUIPMENT ? renderDeleteIcon() : null,
        dataIndex: 'etat',
        key: 'etat',
        width: 400,
      },
    ];

    if (boardingActiveTab === BoardingTabs.EQUIPMENT) {
      baseColumns = [
        ...baseColumns.filter((el) => el.key !== 'etat'),
        {
          title: 'Etat',
          dataIndex: 'etat',
          key: 'etat',
          width: 90,
          render: (text, record) =>
            record.MasterDataEquipment?.lot !== 'Energie' && (
              <Tooltip
                title={
                  record.remainingWear === 0
                    ? t('APP.BOARDING.INDEX.PH_895936', {
                        defaultValue: 'A remplacer',
                      })
                    : record.remainingWear === 100
                    ? t('APP.BOARDING.INDEX.PH_0A3DF1', {
                        defaultValue: 'Etat neuf',
                      })
                    : record.remainingWear < 50
                    ? t('APP.BOARDING.INDEX.PH_392A21', {
                        defaultValue: 'Mauvais état',
                      })
                    : record.remainingWear >= 50 && record.remainingWear < 75
                    ? t('APP.BOARDING.INDEX.PH_DD2CFE', {
                        defaultValue: 'Etat moyen',
                      })
                    : t('APP.BOARDING.INDEX.PH_D7F5C2', {
                        defaultValue: 'Bon état',
                      })
                }
              >
                {renderStateDropdown('edit', record.remainingWear, record)}
              </Tooltip>
            ),
        },
        {
          title: '',
          dataIndex: 'impact',
          key: 'impact',
          align: 'center',
          width: 100,
          render: (text, record) => {
            return (
              <span className="boarding-impact">
                {formatNumber(
                  record?.EmissionValues
                    ? record?.EmissionValues[0].Global1 / currentProject.area
                    : 0
                )}
                {t('APP.BOARDING.INDEX.PH_2E2631', {
                  defaultValue: 'kg CO2 éq./m²',
                })}
              </span>
            );
          },
        },
        {
          title: () => (
            <div className="boarding-title-renouvellement">
              <div>
                <div>
                  <p className="edge green"></p>
                  <p>
                    {t('APP.BOARDING.INDEX.PH_82868D', {
                      defaultValue: 'Reste à vivre',
                    })}
                  </p>
                </div>
                <div>
                  <p className="edge kaki"></p>
                  <p>
                    {t('APP.BOARDING.INDEX.PH_7324D6', {
                      defaultValue: 'Renouvellement',
                    })}
                  </p>
                </div>
              </div>

              {renderDeleteIcon()}
            </div>
          ),

          dataIndex: 'renouvellement',
          key: 'renouvellement',
          width: 450,
          render: (text, record) => {
            const ddv = record.modifiedDdv ? record.modifiedDdv : record.Sheet?.ddv;
            let marks = {};
            let year = (ddv * record?.remainingWear) / 100;

            if (!ddv) return;

            while (year < currentProject.ddv) {
              //projectDdv
              marks[year] = year;
              year += ddv;
            }

            return (
              <Slider
                disabled
                marks={marks}
                key={year}
                min={0}
                max={currentProject.ddv}
                defaultValue={
                  record?.remainingWear === 100
                    ? record.Sheet?.ddv
                    : (ddv * record?.remainingWear) / 100
                }
              />
            );
          },
        },
      ];
    }

    return baseColumns;
  };

  const downloadXlsx = async () => {
    api
      .downloadBoardingXlsx(currentProject.id) // Assuming the API call for PDF download is `downloadBoardingXlsx`
      .then((response) => {
        var b64 = response.data;

        var link = document.createElement('a');

        link.innerHTML = 'Download XLSX file';
        link.download = `Nooco-${currentProject.name}.xlsx`;
        link.href = 'data:application/octet-stream;base64,' + b64;
        // document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        // Handle any errors that occur during the download
        console.error(error);
      });
  };

  const returnTypeOfEquipmentAdded = () => {
    let parentNode = findParentNode(
      newEquipment.AllotmentNode,
      allGroups.flatAllotments,
      [],
      []
    )[0];

    if (
      boardingActiveTab === BoardingTabs.ENERGY ||
      boardingActiveTab === BoardingTabs.WATER ||
      parentNode.name === 'Fluides'
    ) {
      return 'DEC';
    }

    return newEquipment.type_fiche || 'users';
  };

  const [widthTable, setWidthTable] = useState('');
  const { isMenuOpen } = useSelector((state: StateDTO) => state.projects);

  useEffect(() => {
    let widthTable = '';

    if (window.screen.availHeight < 950) {
      if (isMenuOpen) {
        widthTable = `${window.innerWidth - 285}px`;
      } else {
        widthTable = `${window.innerWidth - 120}px`;
      }

      window.addEventListener('resize', function () {
        if (isMenuOpen) {
          widthTable = `${window.innerWidth - 285}px`;
        } else {
          widthTable = `${window.innerWidth - 120}px`;
        }

        setWidthTable(widthTable);
      });

      setWidthTable(widthTable);
    } else {
      if (isMenuOpen) {
        widthTable = `${window.innerWidth - 315}px`;
      } else {
        widthTable = `${window.innerWidth - 165}px`;
      }

      window.addEventListener('resize', function () {
        if (isMenuOpen) {
          widthTable = `${window.innerWidth - 315}px`;
        } else {
          widthTable = `${window.innerWidth - 165}px`;
        }

        setWidthTable(widthTable);
      });

      setWidthTable(widthTable);
    }
  }, [isMenuOpen]);

  const tabsAvailable = [
    t('APP.BOARDING.TABS.EQUIPMENTS', { defaultValue: BoardingTabs.EQUIPMENT }),
    t('APP.BOARDING.TABS.WATER', { defaultValue: BoardingTabs.ENERGY }),
    t('APP.BOARDING.TABS.GARBAGES', { defaultValue: BoardingTabs.WASTE }),
    t('APP.BOARDING.TABS.TRANSPORT', { defaultValue: BoardingTabs.TRANSPORT }),
  ];

  return (
    <div className="boarding-wrapper">
      <Snackbar
        open={openAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error">
          <div>{renderDeleteVerification()}</div>
        </Alert>
      </Snackbar>
      <div className="boarding-header">
        {tabsAvailable.map((el) => (
          <Button
            key={el}
            style={{
              fontSize: window.screen.availHeight > 950 ? '0.9rem' : undefined,
              marginRight: '1em',
            }}
            onClick={() => boardingChangeTab(el)}
            className={boardingActiveTab === el ? 'golden-button' : 'gray-button'}
            text={el}
          />
        ))}
        <div
          className="right-icon"
          onClick={() => {
            // dispatch(downloadBoardingXlsx())
            downloadXlsx();
          }}
        >
          <img
            style={{
              width: '25px',
            }}
            src={exportExcel}
          />
        </div>
      </div>
      <div className="boarding-table">
        <Table
          locale={{
            emptyText: 'Pas de donnée',
          }}
          ref={tableRef}
          components={{
            body: vt,
          }}
          loading={isFetching}
          rowSelection={rowSelection}
          columns={returnBoardingColumns()}
          dataSource={data?.pages.flatMap((page) => page)}
          pagination={false}
          showSorterTooltip={false}
          // style={{ width: '91vw', margin: 'auto', height: '100%' }}
          style={{ margin: 'auto', height: '100%', width: widthTable }}
          footer={renderFooter}
          scroll={{
            y:
              window.screen.availHeight < 750
                ? window.screen.availHeight - 430
                : window.screen.availHeight < 950
                ? window.screen.availHeight - 500
                : window.screen.availHeight - 540,
          }}
        />
      </div>
    </div>
  );
};

export default Boarding;
