import './accordionLicences.scss';
import { CompanyDTO } from '../../../models/ProjectDTO';
import { StateDTO } from '../../../models/GeneralDTO';
import { updateRightsValue } from '../../../../actions/rights';
import { useDispatch, useSelector } from 'react-redux';
import { useEmployeesByLicenceTypeList } from '../../../../hooks/useQuery/rights/crudEmployees';
import { withStyles } from '@material-ui/core/styles';
import AccordionRawEmployee from './AccordionRawEmployee/AccordionRawEmployee';
import AccordionRawSummary from './AccordionRawSummary/AccordionRawSummary';
import AccordionRawSummarySub from './AccordionRawSummarySub/AccordionRawSummarySub';
import MuiAccordion from '@material-ui/core/Accordion';
import React, { useEffect, useState } from 'react';

export type AccordionByCompaniesProps = {
  columnSize: {
    expandIcon: string;
    licence: string;
    users: string;
    mails: string;
    company: string;
    admin: string;
    delete: string;
  };
};

const Accordion = withStyles({
  root: {
    marginBottom: 8,
    '&:first-child': {
      marginTop: 8,
      marginBottom: 8,
    },
    transform: 'none!important',
    minHeight: 80,
    boxShadow: 'none',
    '&:not(:last-child)': {},
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginBottom: 8,
      marginTop: 0,
      '&:first-child': {
        marginTop: 8,
        marginBottom: 8,
      },
      minHeight: 80,
    },
  },
  expanded: {},
})(MuiAccordion);

export type requestEmployeesList = {
  groupBy: 'company' | 'licence';
  companyId?: number | null;
  licenceType?: 'read' | 'light' | 'pro' | null;
  limit?: number;
  offset?: number;
  search?: string;
};

const AccordionByLicences = ({ columnSize }: AccordionByCompaniesProps) => {
  // let api = new API(true);
  // const dispatch= useDispatch();
  const [expandedSummary, setExpandedSummary] = useState('');
  const [selectedCompany, setSelectedCompany] = useState<CompanyDTO | null>(null);
  // const accordionLevel = 0;
  // const color = [' #E4EFFF', '#DDF3FA', '#DDFAF5', '#E4F7FF', '#E4FFF9', '#E8FFE4'];

  const { allCompanies: companies } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { parentCompanies, inputSearch } = useSelector(
    (state: StateDTO) => state.rights
  );

  // useEffect(() => {
  //   let companiesFiltered = companies.filter(
  //     (company) => company.ParentNodeId === null);

  //   if (inputSearch !== "" && inputSearch !== null) {
  //     companiesFiltered = companiesFiltered.filter(obj => obj.name.toLowerCase().includes(inputSearch));
  //   }

  //   companiesFiltered = companiesFiltered.sort((a, b) => a.name > b.name ? 1 : -1);

  //   dispatch(updateRightsValue('parentCompanies',
  //     companiesFiltered ));
  // },
  // [inputSearch]);

  const {
    data: employeesByLicenceProList,
    isloading: isLoadingLicencePro,
    isError: isErrorLicenfePro,
  } = useEmployeesByLicenceTypeList('pro');
  const {
    data: employeesByLicenceReadList,
    isLoading: isLoadingLicenceRead,
    isError: isErrorLicenceRead,
  } = useEmployeesByLicenceTypeList('read');
  const {
    data: employeesByLicenceLightList,
    isLoading: isLoadingLicenceLight,
    isError: isErrorLicenceLight,
  } = useEmployeesByLicenceTypeList('light');

  const dispatch = useDispatch();

  const handleChangeExpandedSummary = (company) => (event, newExpanded) => {
    // console.log('company',
    //   company);
    // console.log('newExpanded',
    //   newExpanded); // true/false
    setSelectedCompany(company);
    dispatch(updateRightsValue('selectedCompany', company));

    setExpandedSummary(newExpanded ? `panelSummary${company.id}` : '');
  };

  return (
    <div className="accordionLicenceWrapper">
      {
        // parentCompanies.map((parentCompany) => {
        //   return (
        //     <Accordion
        //       key={parentCompany.id}
        //       square
        //       expanded={expandedSummary === `panelSummary${parentCompany.id}`}
        //       onChange={handleChangeExpandedSummary(parentCompany)}
        //     >
        //       <AccordionRawSummary
        //         columnSize={columnSize}
        //         expandedSummary={expandedSummary}
        //         company={parentCompany}
        //         color = {color[accordionLevel]}
        //         employeesCount={employeesList?.data?.count}
        //       />
        //       {
        //         employeesList?.data.rows?.map((employee) => {
        //           return (
        //             <>
        //               <AccordionRawEmployee key={employee.id} columnSize={columnSize} employee={employee} />
        //             </>
        //           );
        //         })
        //       }
        //       {
        //         employeesList?.data.rows?.length === 0 && companies.filter((company) => company.ParentNodeId === parentCompany.id).length === 0  ? (
        //           // <AccordionDetails>
        //           <div style={{width: '100%', fontStyle: 'italic', padding: '6px 6rem 0px 6rem', }} >Aucun utilisateur</div>
        //           // </AccordionDetails>
        //         ) :
        //           (
        //             <div style={{minHeight:'8px'}}></div>
        //           )
        //       }
        //       {
        //         isLoading && (
        //           <div style={{minHeight:'0px'}}>
        //             <div className="spinner-wrapper-licenses">
        //               <div className="donut" />
        //             </div>
        //           </div>
        //         )
        //       }
        //       {
        //         companies.filter((company) => company.ParentNodeId === parentCompany.id).map((company) => {
        //           return (
        //             <AccordionRawSummarySub
        //               key={company.id}
        //               columnSize={columnSize}
        //               color={color}
        //               company={company}
        //               parentAccordionLevel={accordionLevel}
        //             />
        //           );
        //         })
        //       }
        //     </Accordion>
        //   );
        // })
      }
    </div>
  );
};

export default AccordionByLicences;
