import { useQuery } from 'react-query';
import { getPdf } from '../../../apiCallFunctions/pdfBuilder';
import { StateDTO } from '../../../app/models/GeneralDTO';
import { useSelector } from 'react-redux';

export const useGetPdf = () => {
  const { generatePdfStarter, widgetsInDropZone } = useSelector(
    (state: StateDTO) => state.report
  );

  return useQuery(['getPdf'], () => getPdf(widgetsInDropZone), {
    enabled: generatePdfStarter,
  });
};
