import { deleteProject } from '../../../apiCallFunctions/project/project';
import { useMutation, useQueryClient } from 'react-query';

export const useDeleteProject = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteProject, {
    onSuccess: () => {
      queryClient.invalidateQueries('projectsByFolder');
      queryClient.invalidateQueries('projectsBySearch');
      queryClient.invalidateQueries('foldersByCompany');
    },
    onError: (error) => {
      console.log('error', error);
    },
  });
};
