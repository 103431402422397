import './helpFeature.scss';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useState } from 'react';

import { Category, HelpDTO } from '../../models/HelpDTO';
import { StateDTO } from '../../models/GeneralDTO';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  featureCloseModal,
  featureCreateModeOn,
  featureCreateNew,
  featureOpenModal,
  featureSelected,
  featureUpdate,
  fetchFeature,
  releaseCreateSucces,
  updateIsNewFeature,
} from '../../../actions/helps';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Alert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import Fade from '@material-ui/core/Fade';
import FeatureModal from './featureModal';
import Loader from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Snackbar from '@material-ui/core/Snackbar';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory, useLocation } from 'react-router-dom';
import { useEventCallback } from '@mui/material';
import { Autocomplete } from '@material-ui/lab';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      background: 'white',
      border: '1px solid #D9DBE9',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      padding: theme.spacing(4, 3, 4, 3),
      borderRadius: '12px',
    },
  })
);

const useGetUrlParams = () => {
  const { search } = useLocation();

  return React.useMemo(() => {
    const queryParams = new URLSearchParams(search);
    return queryParams.get('id');
  }, [search]);
};

const FeaturePage = () => {
  const [searchInput, setSearchInput] = useState('');

  const {
    isOpenModal,
    create,
    edit,
    features,
    loadingFeatures,
    isNewFeature2,
    localFeature,
  } = useSelector((state: HelpDTO) => state.help.feature);
  const { featureCategories, userNotification, messageNotification } = useSelector(
    (state: HelpDTO) => state.help
  );
  // TODO ad  DTO for isSuperAdmin
  const isSuperAdmin = useSelector((state: StateDTO) => state.projects.isSuperAdmin);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const idParams = useGetUrlParams();

  const handleRefs = useEventCallback((element: HTMLDivElement) => {
    if (element) {
      const id = element.dataset.id;
      if (idParams === id && !isOpenModal) element.click();
    }
  });
  const handleMarkAllFeaturesReading = () => {
    // console.log('ici', features);
    features.map((feature) => {
      // console.log(feature.id);
      handleSaveLocalStorage(feature.id);
    });
    dispatch(updateIsNewFeature(false));
  };

  const handleSaveLocalStorage = (id: number) => {
    // if id already includes do nothing
    if (!localFeature.includes(id)) {
      localFeature.push(id);
      dispatch(featureUpdate('localFeature', localFeature));
      localStorage.setItem('featuresConsulted', JSON.stringify(localFeature));
      // dispatch(updateIsNewFeature(false));
    }
  };
  const handleOpen = () => {
    dispatch(featureOpenModal());
  };
  const handleClose = () => {
    if (!create && !edit) {
      dispatch(featureCloseModal());
      // dispatch(featureEditModeOff());
    }
  };

  // Close Accordion
  const handleCloseMessage = () => {
    dispatch(releaseCreateSucces(false, ''));
  };

  return (
    <div className="feature-container">
      {/* //TODO SnackBar change to Nooco */}
      <Snackbar
        open={userNotification}
        autoHideDuration={6000}
        onClose={handleCloseMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseMessage} severity="success">
          {messageNotification}
        </Alert>
      </Snackbar>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpenModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpenModal}>
          <div className={classes.paper}>
            <FeatureModal />
          </div>
        </Fade>
      </Modal>

      <header className="feature-header">
        <div className="feature-allRead" onClick={handleMarkAllFeaturesReading}>
          {isNewFeature2 && (
            <>
              <DoneOutlinedIcon />{' '}
              <span>
                {t('APP.FEATUREPAGE.INDEX.PH_EEFC22', {
                  defaultValue: 'Tout marquer comme lu',
                })}
              </span>
            </>
          )}
        </div>

        <div className="feature-searchBar">
          <Tooltip
            title={t('APP.FEATUREPAGE.INDEX.PH_F9CA9A', {
              defaultValue:
                'Rechercher un élément dans le texte des réponses ainsi que sur celui des questions',
            })}
          >
            <TextField
              style={{ width: '350px' }}
              id="search-input"
              label={t('APP.FEATUREPAGE.INDEX.PH_D4DBE9', {
                defaultValue: 'Rechercher une fonctionnalité',
              })}
              variant="outlined"
              size="small"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
        </div>

        {/* // TODO Search Bar */}
        {/* <div className='feature-searchBar'>
                  <TextField 
                    id="outlined-basic"
                    label="Rechercher"
                    variant="outlined"
                    size="small"
                    // value={searchBar}
                    // onChange={(e) => {
                     //   setSearchBar(e.target.value);
                    //   filterQuestions();
                    
                    // }}
                  />
                 </div> */}
      </header>
      <main className="feature-main">
        {featureCategories.map((category: Category) => {
          return (
            <div className="feature-col" key={category.id}>
              <div className="feature">
                <div className="feature__category">&gt; {category.title}</div>

                {/* Create Feature Icon */}
                {isSuperAdmin && (
                  <div
                    className="feature__add"
                    onClick={() => {
                      dispatch(featureCreateModeOn());
                      handleOpen();
                      dispatch(featureCreateNew(category));
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </div>
                )}
                {/* Loader */}
                {loadingFeatures && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Loader
                      variant="indeterminate"
                      disableShrink
                      style={{ color: '#73b7ec' }}
                      size={25}
                      thickness={4}
                    />
                  </div>
                )}

                {features &&
                  features
                    .filter((feature) =>
                      feature.title.toLowerCase().includes(searchInput.toLowerCase())
                    )
                    .map((feature, index) => {
                      // display Feature only if isVisible or isSuperAdmin

                      if (
                        (feature.category == category.id && feature.isVisible) ||
                        (isSuperAdmin && feature.category == category.id)
                      ) {
                        return (
                          <div
                            ref={handleRefs}
                            data-id={feature.id}
                            className={
                              !localFeature.includes(feature.id)
                                ? 'feature__card unread'
                                : 'feature__card'
                            }
                            onClick={() => {
                              handleOpen();
                              handleSaveLocalStorage(feature.id);
                              dispatch(featureUpdate('loadingFeature', true));
                              dispatch(featureUpdate('selectedFeatureIndex', index));
                              dispatch(featureSelected(feature, category));
                              dispatch(fetchFeature(feature.id, index));
                              history.push(`/help/feature?id=${feature.id}`);
                            }}
                            key={`feature-id${feature.id}`}
                          >
                            {feature.title}
                            {!localFeature.includes(feature.id) && (
                              <div className="feature__card--badge" />
                            )}
                            {isSuperAdmin && (
                              <>
                                {feature.isVisible && (
                                  <div className="feature__card--visible">
                                    <VisibilityIcon
                                      style={{
                                        color: '#44DDC3',
                                        width: '16px',
                                      }}
                                    />
                                  </div>
                                )}
                                {!feature.isVisible && (
                                  <div className="feature__card--visible">
                                    <VisibilityOffIcon
                                      style={{
                                        color: '#CE4C33',
                                        width: '16px',
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        );
                      }
                    })}
              </div>
            </div>
          );
        })}
      </main>
    </div>
  );
};

export default FeaturePage;
