import './calculatorWater.scss';
import { Controller } from 'react-hook-form';
import { StateDTO } from '../../../../../models/GeneralDTO';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import formatNumber from '../../../../../services/mathematics/formatNumber';
import { useTranslation } from 'react-i18next';

const DrinkableWater = ({
  control,
  register,
  getValues,
  errors,
  setValue,
  watch,
  calculEquipmentPercentageByZone,
  percentageResultStructure,
}) => {
  const { waterCalculator: waterCalculatorData } = useSelector(
    (state: StateDTO) => state.projects.waterCalculator
  );

  const { t } = useTranslation();

  console.log(window.screen.availHeight);
  console.log(window.screen.availHeight);

  const zoneItemName = {
    nbRobinetEvier: t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_5025A0', {
      defaultValue: 'Robinet évier avec régulateur de débit​',
    }),
    nbRobinetLavabo: t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_247E67', {
      defaultValue: 'Robinet lavabo avec régulateur de débit​',
    }),
    nbDouche: t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_5FCB79', {
      defaultValue: 'Douche économe en eau ​​',
    }),
    nbChasseEau36: t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_F879D4', {
      defaultValue: 'Chasse d’eau double flux 3L / 6L ​',
    }),
    nbChasseEau24: t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_D6E3A2', {
      defaultValue: 'Chasse d’eau double flux 2L / 4L​',
    }),
    nbToilettes: t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_F620AB', {
      defaultValue: 'Toilettes sèches pour les maisons uniquement ​',
    }),
    distance: t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_6E3F23', {
      defaultValue: 'distance point de puisage ECS – point utilisation ​​',
    }),
    urinoir: t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_F60054', {
      defaultValue: 'Urinoir',
    }),
  };

  const tooltipData = {
    nbRobinetEvier: t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_3D8109', {
      defaultValue: 'Débit : 6 L/min ​',
    }),
    nbRobinetLavabo: t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_9D6564', {
      defaultValue: 'Débit : 5L/min​',
    }),
    nbDouche: t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_A90398', {
      defaultValue: 'Débit : 8 L/min​',
    }),
    nbChasseEau36: t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_582779', {
      defaultValue:
        '3L (petit débit) / 6L (grand débit) – petit débit utilisé dans 67% des cas​',
    }),
    nbChasseEau24: t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_3EA791', {
      defaultValue:
        '2L (petit débit) / 4L (grand débit) petit débit utilisé dans 67% des cas​',
    }),
    nbToilettes: t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_1AB7B0', {
      defaultValue: 'Pas de chasse d’eau ​',
    }),
    distance: t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_C9A17B', {
      defaultValue: 'inferieure à 8m​',
    }),
    urinoir: t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_D8945D', {
      defaultValue: 'Remplace la chasse d’eau à 67%​',
    }),
  };

  // console.log('zoneItemName',
  //   zoneItemName);
  console.log(window.innerHeight, window.innerWidth);
  console.log('waterCalculatorData', waterCalculatorData);

  // const onSubmit = (data) => {
  //   console.log('dataOnSubmit', data)
  // }

  // const onError = (errors) => {
  //   console.log('errors',)
  // }

  // const [test, setTest] = useState("left");
  const form = watch();

  console.log(form);
  // console.log(form.detailledWaterProperties.buildings[0].detailledWaterProperties.livingSurface);
  console.log('errors?', errors);
  console.log(watch);
  // console.log(errors?.detailledWaterProperties?.buildings[0]?.detailledWaterProperties?.livingSurface);

  return (
    <div className="calculatorWaterWrapper">
      {console.log('waterCalculatorData.buildings', waterCalculatorData)}
      {waterCalculatorData?.buildings?.map((building, buildingIndex) => {
        return (
          <div key={`drinkableComponent-${building.id}`}>
            <div className="buildingTitle">{building.name}</div>

            <div className="label" style={{ marginBottom: '0.5rem' }}>
              {t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_D792D9', {
                defaultValue:
                  'Surface de murs et toitures végétalisés (Svégétalisée en m²)',
              })}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginBottom: '1.5rem',
              }}
            >
              <Controller
                control={control}
                // ref={register}

                // ref={`formData.buildings[${buildingIndex}].detailledWaterProperties.livingSurface`}
                // key={`keyLivingSurface-${buildingIndex}`}
                name={`formData.buildings[${buildingIndex}].detailledWaterProperties.livingSurface`}
                {...register}
                rules={{
                  required: 'test',
                  validate: {
                    //v is = e.currentTarget.value
                    isNumber: (v) => {
                      console.log('v', v);
                      if (v === '' || v === null) {
                        return false;
                      } else if (!isNaN(Number(v?.replace(/,/, '.')))) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    key={`keyLivingSurface-${buildingIndex}`}
                    style={{ width: '20vw' }}
                    // ref={register}
                    // ref={`keyLivingSurface-${buildingIndex}`}
                    // defaultValue=""
                    ref={
                      register(
                        `formData.buildings[${buildingIndex}].detailledWaterProperties.livingSurface`
                      ).ref
                    }
                    placeholder={t(
                      'APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_47C1B8',
                      { defaultValue: 'Saisir une surface' }
                    )}
                    variant="outlined"
                    // disabled={true}
                    // error={errors?.formData?.buildings[buildingIndex]?.detailledWaterProperties?.livingSurface ? true : form?.formData?.buildings[buildingIndex]?.detailledWaterProperties?.livingSurface === "" || form?.formData?.buildings[buildingIndex]?.detailledWaterProperties?.livingSurface === null ? true : false}
                    error={
                      errors?.formData?.buildings[buildingIndex]
                        ?.detailledWaterProperties?.livingSurface
                        ? true
                        : false
                    }
                    className="textFieldV1"
                  />
                )}
              />

              {/* {
                        // console.log('errrors?', `errors?.buildings[${buildingIndex}].detailledWaterProperties?.livingSurface`);
                        // console.log('test');
                        // console.log(getValues())
                        console.log(errors)
                       }
                       {
                        console.log(getValues())
                       } */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginLeft: '12rem',
                }}
              >
                <div className="label">
                  {t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_4CD425', {
                    defaultValue: 'Assainissement :',
                  })}
                </div>
                <div className="calculatorToggle__container">
                  <div
                    className="calculatorToggle__container--label"
                    onClick={() => {
                      setValue(
                        `formData.buildings[${buildingIndex}].detailledWaterProperties.isCollectiveSanitation`,
                        true
                      );
                    }}
                  >
                    Collectif
                  </div>
                  <div
                    className="calculatorToggle__container--label"
                    onClick={() => {
                      console.log('click');
                      setValue(
                        `formData.buildings[${buildingIndex}].detailledWaterProperties.isCollectiveSanitation`,
                        false
                      );
                    }}
                  >
                    {t('APP.CALCULATORWATERCONTENT.DRINKABLEWATER.PH_9401B1', {
                      defaultValue: 'Non collectif',
                    })}
                  </div>
                  {/* {console.log(form)} */}
                  <div
                    className={
                      form?.formData?.buildings[buildingIndex]
                        ?.detailledWaterProperties.isCollectiveSanitation === true
                        ? 'calculatorToggle__container--m'
                        : 'calculatorToggle__container--l'
                    }
                  ></div>
                </div>
              </div>
            </div>
            {building?.zones?.map((zone, zoneIndex) => {
              return (
                <div
                  key={`drinkableComponent-${zone.id}`}
                  style={{ marginBottom: '1rem' }}
                >
                  <div className="buildingTitle--sub">
                    {`${building.name} - ${zone.name} - ${zone.type}`}
                  </div>
                  {/* <div className='itemFlex'> */}

                  {Object.entries(zone?.detailledWaterProperties)?.map(
                    ([name, value]) => {
                      // const isError = errors?.formData?.buildings[buildingIndex]?.zones[zoneIndex]?.detailledWaterProperties[name];
                      const isError =
                        errors?.formData?.buildings[buildingIndex]?.zones;
                      if (isError) {
                        const isError =
                          errors?.formData?.buildings[buildingIndex]?.zones[
                            zoneIndex
                          ];

                        console.log(
                          'isError',
                          isError,
                          'name',
                          name,
                          'value',
                          value,
                          'buildingIndex',
                          buildingIndex,
                          'zoneIndex',
                          zoneIndex
                        );
                      }

                      const namePercentage = `${name}Percentage`;
                      // console.log('namePercentage', namePercentage);

                      if (name !== 'nbOccupants' && value !== null) {
                        return (
                          <div
                            className="itemFlex"
                            key={`dirnkableComponent-${name}-${value}-${building.id}`}
                          >
                            <Tooltip title={tooltipData[name]}>
                              <div
                                className="label--right"
                                style={{ cursor: 'help' }}
                              >
                                {`${zoneItemName[name]} :`}
                              </div>
                            </Tooltip>
                            <Controller
                              control={control}
                              key={`key-${name}-${value}-${building.id}-${percentageResultStructure}`}
                              name={`formData.buildings[${buildingIndex}].zones[${zoneIndex}].detailledWaterProperties.${name}`}
                              {...register}
                              rules={{
                                validate: {
                                  isNumber: (v) => {
                                    if (v === '' || v === null) {
                                      return true;
                                    } else if (v === 0) {
                                      return true;
                                    } else if (
                                      !isNaN(Number(v?.replace(/,/, '.')))
                                    ) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  },
                                },
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  ref={
                                    register(
                                      `formData.buildings[${buildingIndex}].zones[${zoneIndex}].detailledWaterProperties.${name}`
                                    ).ref
                                  }
                                  key={`key-${name}-${value}-${building.id}-${percentageResultStructure}`}
                                  // defaultValue={value}
                                  placeholder=""
                                  variant="outlined"
                                  // disabled={true}
                                  error={
                                    isError
                                      ? errors?.formData?.buildings[buildingIndex]
                                          ?.zones[zoneIndex]
                                          ?.detailledWaterProperties[name]
                                        ? true
                                        : false
                                      : false
                                  }
                                  className="textFieldV1--small zoneItemTextfield"
                                  // onChange={handleSubmit(onSubmit, onError)}
                                  onBlur={() => {
                                    const actualZone = getValues(
                                      `formData.buildings[${buildingIndex}].zones[${zoneIndex}]`
                                    );
                                    calculEquipmentPercentageByZone(
                                      actualZone,
                                      buildingIndex,
                                      zoneIndex
                                    );
                                    console.log(
                                      'percentageResultStructure',
                                      percentageResultStructure
                                    );
                                    // console.log('onChange');
                                    //  const actualZone = getValues(`formData.buildings[${buildingIndex}].zones[${zoneIndex}]`);
                                    // console.log('actualZone', actualZone);
                                    // // console.log('zone', zone)
                                    // console.log('CALL RESULT OF CALCULPERCENTAGE')
                                    // console.log('result of calculEquipmentercentage', calculEquipmentPercentageByZone(actualZone, buildingIndex, zoneIndex));
                                  }}
                                />
                              )}
                            />
                            <div className="displayPercentage">
                              {`soit ${formatNumber(
                                (100 /
                                  percentageResultStructure?.buildings[buildingIndex]
                                    ?.zones[zoneIndex]?.detailledWaterProperties
                                    ?.totalPercentage) *
                                  percentageResultStructure?.buildings[buildingIndex]
                                    ?.zones[zoneIndex]?.detailledWaterProperties[
                                    namePercentage
                                  ]
                              )}%`}
                            </div>
                          </div>
                        );
                      }
                    }
                  )}
                  {/* </div> */}
                </div>
              );
            })}
          </div>
        );
      })}
      <div></div>
    </div>
  );
};

export default DrinkableWater;
