import { StateDTO } from '../../../app/models/GeneralDTO';
import { fetchAccessHeader, updateRightsValue } from '../../../actions/rights';
import { updateFormValue } from '../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import API from '../../../app/services/API';
import dayjs from 'dayjs';

const apiUser = new API(true);
const api = new API();

//******** CallAPIFunctions */
const patchEmployee = async (Datas) => {
  const { employee, data } = Datas;

  return await apiUser
    .updateEmployee(employee, data)
    .then((response) => {
      // console.log('api response',
      //   response);

      return response;
    })
    .catch((e) => {
      // console.log('api error',
      //   JSON.stringify(e));

      throw e;
    });
};

const getEmployees = async (selectedCompany) => {
  const request = {
    groupBy: 'company',
    companyId: selectedCompany?.id,
    licenceType: null,
    limit: 20,
    offset: 0,
  };

  return await apiUser
    .getEmployeesList(request)
    .then((response) => {
      // console.log('api response',
      //   response);

      return response;
    })
    .catch((e) => {
      // console.log('api error',
      //   JSON.stringify(e));

      throw e;
    });
};

//***************** Queries */

export const useEmployeesList = (selectedCompany) => {
  return useQuery(
    ['employees', selectedCompany],
    () => getEmployees(selectedCompany),
    {
      enabled: selectedCompany != null,
    }
  );
};

export type licenceType = 'pro' | 'read' | 'light' | null;

export const useEmployeesByLicenceTypeList = (licenceType) => {
  return useQuery(
    ['employeesByLicenceType', licenceType],
    async () => {
      const request = {
        groupBy: 'license',
        companyId: null,
        licenceType: licenceType,
        // limit: 20,
        // offset: 0,
      };

      const response = await apiUser.getEmployeesList(request);

      return response;
    },
    {
      enabled: licenceType != null,
    }
  );
};

export const useUpdateEmployee = (onSuccess, onError) => {
  return useMutation(patchEmployee, {
    onError,
    onSuccess,
  });
};

export const useDeleteEmployee = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { allCompanies, parentCompanies } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { companiesList, parentCompaniesFiltered, currentCompanyId } = useSelector(
    (state: StateDTO) => state.rights
  );

  const deleteEmployeeCall = async (employee) => {
    return await apiUser
      .deleteUser(employee, employee.currentCompanyId)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        throw e;
      });
  };

  const mutation = useMutation(deleteEmployeeCall, {
    onError: (error) => {
      console.log(error?.response);

      const response = error?.response;
      let message = "L'utilisateur n'a pas été supprimé";

      if (response?.status === 401) {
        message = "Vous n'avez pas les droits pour effectuer cette action";
      }
      if (response?.status === 403) {
        const date = dayjs(response.data);
        const dateFormatted = date.format('L');

        message = `La licence est bloquée pour une durée de 1 mois suite à son affectation. Elle sera à nouveau modifiable le ${dateFormatted} avec la date à laquelle le blocage s'arrête`;
      }

      dispatch(updateRightsValue('snackbarMessage', message));
      dispatch(updateRightsValue('snackbarSeverity', 'error'));
      dispatch(updateRightsValue('modalAccess', null));
      dispatch(updateRightsValue('isSnackbarOpen', 'true'));
      // console.log('error',
      //   error,
      //   variable,
      //   context);
    },
    onSuccess: (data, variable, context) => {
      const companyId = currentCompanyId;

      const allCompaniesNew = allCompanies.map((company) =>
        company.id === companyId
          ? { ...company, EmployeesCount: company.EmployeesCount - 1 }
          : company
      );

      const parentCompaniesNew = parentCompanies.map((company) =>
        company.id === companyId
          ? { ...company, EmployeesCount: company.EmployeesCount - 1 }
          : company
      );

      const parentCompaniesFilteredNew = parentCompaniesFiltered.map((company) =>
        company.id === companyId
          ? { ...company, EmployeesCount: company.EmployeesCount - 1 }
          : company
      );

      const companiesListNew =
        companiesList?.map((company) =>
          company.id === companyId
            ? { ...company, EmployeesCount: company.EmployeesCount - 1 }
            : company
        ) || [];

      queryClient.invalidateQueries('employees');
      dispatch(
        updateRightsValue('snackbarMessage', "L'utilisateur a bien été supprimé")
      );
      dispatch(updateRightsValue('snackbarSeverity', 'success'));
      dispatch(updateRightsValue('modalAccess', null));
      dispatch(updateRightsValue('isSnackbarOpen', 'true'));
      dispatch(fetchAccessHeader());
      dispatch(updateRightsValue('companiesList', companiesListNew));
      dispatch(
        updateRightsValue('parentCompaniesFiltered', parentCompaniesFilteredNew)
      );
      dispatch(updateFormValue('parentCompanies', parentCompaniesNew));
      dispatch(updateFormValue('allCompanies', allCompaniesNew));
    },
  });

  return mutation;
};

export type postEmployeeInformationDTO = {
  licenseType: 'pro' | 'read' | 'light' | string;
  companyIds: number[];
  email: string;
};

export const useAddEmployee = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const addEmployeeCall = async (data: postEmployeeInformationDTO) => {
    return await apiUser
      .postEmployee(data)
      .then((response) => {
        // console.log('api response',
        //   response);

        return response;
      })
      .catch((e) => {
        // console.log('api error',
        //   JSON.stringify(e));

        throw e;
      });
  };
  const { allCompanies, parentCompanies } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { companiesList, parentCompaniesFiltered } = useSelector(
    (state: StateDTO) => state.rights
  );

  const mutation = useMutation(addEmployeeCall, {
    onError: (error, variable, context) => {
      console.log('ajout utilisateur echec', error, variable, context);
      console.log(error?.response);

      const response = error?.response;

      let message = "Une erreur c'est produit";

      if (response?.data === 'No valid license available') {
        message = 'Aucune licence de ce type disponible';
      }
      if (response?.data === 'User already in company') {
        message = 'Cet utilisateur est déjà dans cette entreprise';
      }
      if (response?.status === 401) {
        message = "Vous n'avez pas les droits pour effectuer cette action";
      }

      //   variable,
      //   context);
      dispatch(updateRightsValue('snackbarMessage', message));
      dispatch(updateRightsValue('snackbarSeverity', 'error'));
      dispatch(updateRightsValue('modalAccess', null));
      dispatch(updateRightsValue('isSnackbarOpen', 'true'));
    },
    onSuccess: (data, variable, context) => {
      const companyId = variable.companyIds[0];

      const allCompaniesNew = allCompanies.map((company) =>
        company.id === companyId
          ? { ...company, EmployeesCount: company.EmployeesCount + 1 }
          : company
      );

      const parentCompaniesNew = parentCompanies.map((company) =>
        company.id === companyId
          ? { ...company, EmployeesCount: company.EmployeesCount + 1 }
          : company
      );

      const parentCompaniesFilteredNew = parentCompaniesFiltered.map((company) =>
        company.id === companyId
          ? { ...company, EmployeesCount: company.EmployeesCount + 1 }
          : company
      );

      const companiesListNew =
        companiesList?.map((company) =>
          company.id === companyId
            ? { ...company, EmployeesCount: company.EmployeesCount + 1 }
            : company
        ) || [];

      queryClient.invalidateQueries('employees');
      dispatch(
        updateRightsValue('snackbarMessage', "L'utilisateur a bien été ajouté")
      );
      dispatch(updateRightsValue('snackbarSeverity', 'success'));
      dispatch(updateRightsValue('modalAccess', null));
      dispatch(updateRightsValue('isSnackbarOpen', 'true'));
      dispatch(fetchAccessHeader());
      dispatch(updateRightsValue('companiesList', companiesListNew));
      dispatch(
        updateRightsValue('parentCompaniesFiltered', parentCompaniesFilteredNew)
      );
      dispatch(updateFormValue('parentCompanies', parentCompaniesNew));
      dispatch(updateFormValue('allCompanies', allCompaniesNew));
    },
  });

  return mutation;
};
