import './calculator.scss';
import { AnimatePresence } from 'framer-motion';
import { StateDTO } from '../../../models/GeneralDTO';
import {
  TYPE_EPCM,
  TYPE_NOOCO,
  TYPE_RE2020,
} from '../../../globalVariable/typeProject';
import { calculatorUpdate } from '../../../../actions/calculator';
import { useDispatch, useSelector } from 'react-redux';
import CalculatorWorksite from './CalculatorWorksite/CalculatorWorksite';
import React, { useEffect } from 'react';
import WaterCalculator from './CalculatorWater/CalculatorWater';

const Calculator = ({ groups }) => {
  // console.log(groups);
  const dispatch = useDispatch();

  const { isWorksiteCalculatorSelected } = useSelector(
    (state: StateDTO) => state.projects.calculator
  );
  const { isWaterCalculatorSelected } = useSelector(
    (state: StateDTO) => state.projects.waterCalculator
  );
  const zones = useSelector((state: StateDTO) => state.projects.parsedProject.zones);
  const { templateType } = useSelector(
    (state: StateDTO) => state.projects.currentProject
  );

  useEffect(() => {
    const zoneBuilding = zones.filter((zone) => zone.type === 'buildingSite');

    let buildingSiteCalculatorSheets = [];

    zoneBuilding.map((zonestypeBuilding) => {
      zonestypeBuilding.ProjectSheets.map((sheet) => {
        if (sheet.source === 'buildingSiteCalculator') {
          buildingSiteCalculatorSheets.push(sheet);
        }
      });
    });

    if (buildingSiteCalculatorSheets.length > 0) {
      //! change for dev calculator simplified
      // dispatch(calculatorUpdate('isWorksiteCalculatorDetailed',
      //   true));
      // dispatch(calculatorUpdate('isWorksiteCalculatorSimplified',
      //   true));
    }
  }, []);
  return (
    <div className="calculator__wrapper">
      <div className="calculator__subWrapper">
        {isWorksiteCalculatorSelected && (
          <AnimatePresence>
            <CalculatorWorksite templateType={templateType} />
          </AnimatePresence>
        )}
        {isWaterCalculatorSelected && (
          <AnimatePresence>
            <WaterCalculator
              groups={groups}
              templateType={templateType}
              TYPE_EPCM={TYPE_EPCM}
              TYPE_RE2020={TYPE_RE2020}
            />
          </AnimatePresence>
        )}
      </div>
    </div>
  );
};
export default Calculator;
