import './subMenu.scss';
import { QueryClient, useQueryClient } from 'react-query';
import { StateDTO } from '../../../models/GeneralDTO';
import { SubMenuElement } from './SubMenuElement';
import { TextField } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { getProjectsByFolderResponse } from '../../../../apiCallFunctions/project/project';
import { isBundle } from 'typescript';
import { updateBundleValue } from '../../../../actions/bundle';
import {
  updateFormValue,
  updateProjectsListMonitoring,
} from '../../../../actions/projects';
import { useCreateFolder } from '../../../../hooks/useQuery/folders/createFolder';
import { useDeleteFolder } from '../../../../hooks/useQuery/folders/deleteFolder';
import { useDeleteProject } from '../../../../hooks/useQuery/projects/deleteProject';
import { useDispatch, useSelector } from 'react-redux';
import { useGetFoldersByCompany } from '../../../../hooks/useQuery/folders/getFolders';
import { useGetProjectsByFolder } from '../../../../hooks/useQuery/projects/getProjectsByFolder';
import { useIsFetching } from 'react-query';
import { useIsMutating } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useUpdateFolder } from '../../../../hooks/useQuery/folders/patchFolder';
import Loader from '@material-ui/core/CircularProgress';
import React, { useState } from 'react';
import useCase from '../useCase/useCase';

const CompanySvg = () => {
  return (
    <div style={{ marginRight: '12px', width: '22px' }}>
      <svg
        width="22"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.1755 13.7017V3.73682H8.71948V8.71924H3.73706V26.1577H13.7019V21.1753H16.1931V26.1577H26.158V13.7017H21.1755ZM8.71948 23.6665H6.22827V21.1753H8.71948V23.6665ZM8.71948 18.6841H6.22827V16.1929H8.71948V18.6841ZM8.71948 13.7017H6.22827V11.2104H8.71948V13.7017ZM13.7019 18.6841H11.2107V16.1929H13.7019V18.6841ZM13.7019 13.7017H11.2107V11.2104H13.7019V13.7017ZM13.7019 8.71924H11.2107V6.22803H13.7019V8.71924ZM18.6843 18.6841H16.1931V16.1929H18.6843V18.6841ZM18.6843 13.7017H16.1931V11.2104H18.6843V13.7017ZM18.6843 8.71924H16.1931V6.22803H18.6843V8.71924ZM23.6667 23.6665H21.1755V21.1753H23.6667V23.6665ZM23.6667 18.6841H21.1755V16.1929H23.6667V18.6841Z"
          fill="#789ADE"
        />
      </svg>
    </div>
  );
};

const FolderSvg = () => {
  return (
    <div style={{ marginRight: '8px' }} className="svgWrapper">
      <svg
        width="22"
        height="22"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.1429 8.37136H16.3215L12.1322 4.36422C12.0789 4.31432 12.0088 4.28627 11.9358 4.28564H2.85721C2.22507 4.28564 1.71436 4.79636 1.71436 5.4285V26.5714C1.71436 27.2035 2.22507 27.7142 2.85721 27.7142H29.1429C29.7751 27.7142 30.2858 27.2035 30.2858 26.5714V9.51422C30.2858 8.88207 29.7751 8.37136 29.1429 8.37136ZM27.7144 25.1428H4.28578V6.85707H11.0179L15.2894 10.9428H27.7144V25.1428Z"
          fill="#A3AED0"
        />
        <path
          d="M11.0178 6.85718H4.28564V25.1429H27.7142V10.9429H15.2892L11.0178 6.85718Z"
          fill="#FAFAFA"
        />
      </svg>
    </div>
  );
};

export const SubMenu = () => {
  const { licenceTypeAccess, isSuperAdmin } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { arianeCompany, arianeFolder, isCreateFolder, inputCreateFolder } =
    useSelector((state: StateDTO) => state.projects.projectsListMonitoring);
  const { isBundleScreen } = useSelector((state: StateDTO) => state.bundle);
  const { isExistChildrenCompanies, childrenCompanies, selectedCompanyId } =
    arianeCompany;
  const { isExistChildrenPaths, childrenPaths, selectedPathId } = arianeFolder;
  const dispatch = useDispatch();
  const createFolder = useCreateFolder();
  const { initFolders, addFolder } = useCase();
  const isUserProAccess = licenceTypeAccess === 'pro';
  const { t } = useTranslation();

  const createFolderTooltip = () => {
    if (!isUserProAccess) {
      return 'Fonctionnalité disponible avec une licence pro';
    }
    if (isExistChildrenCompanies) {
      return 'Vous devez selectionner une entreprise pour créer un dossier';
    }

    return t('APP.SUBMENU.CREATE_NEW_FOLDER_TOOLTIP', {
      defaultValue:
        "Permet de créer un nouveau dossier au niveau actuel de l'arborescence",
    });
  };

  const createFolderClassName = () => {
    let createFolderClassName = 'createFolderButton';

    if (!isUserProAccess || isExistChildrenCompanies) {
      createFolderClassName = 'createFolderButton disabled';
    }

    return createFolderClassName;
  };

  const handleClicCreateFolder = () => {
    const canCreateFolder = isUserProAccess && !isExistChildrenCompanies;

    if (canCreateFolder) {
      dispatch(updateProjectsListMonitoring('isCreateFolder', true));
    }
  };

  const onSuccessGetFoldersByCompany = (foldersData) => {
    dispatch(updateProjectsListMonitoring('foldersData', foldersData));
    if (!selectedPathId) {
      initFolders(foldersData);
    } else {
      addFolder(foldersData, selectedPathId);
    }
  };

  const handleChangeInputFolder = (e) => {
    dispatch(updateProjectsListMonitoring('inputCreateFolder', e));
  };

  const handleCreateFolder = () => {
    if (inputCreateFolder !== '') {
      createFolder.mutate({
        parentFolderId: selectedPathId,
        companyId: selectedCompanyId,
        folderName: inputCreateFolder,
      });
    }

    dispatch(updateProjectsListMonitoring('isCreateFolder', false));
    dispatch(updateProjectsListMonitoring('inputCreateFolder', ''));
  };

  const { isLoading: isLoadingFolders, isFetching: isFetchingFolders } =
    useGetFoldersByCompany(
      isExistChildrenCompanies,
      selectedCompanyId,
      onSuccessGetFoldersByCompany
    );

  return (
    <div className="foldersWrapper">
      <div className="subMenuWrapper">
        {/* companies display */}
        {isExistChildrenCompanies &&
          childrenCompanies.map((company) => (
            <SubMenuElement key={company.id} company={company}>
              <>
                <CompanySvg />
                {company.name}
              </>
            </SubMenuElement>
          ))}
        {/* folders display */}
        {/* Loader */}
        {isLoadingFolders && !isExistChildrenCompanies && (
          <div>
            <Loader
              variant="indeterminate"
              disableShrink
              style={{ color: '#73b7ec' }}
              size={12}
              thickness={4}
            />
          </div>
        )}

        {/* display "aucun dossier" if no folder */}
        {!isExistChildrenCompanies &&
          childrenPaths.length === 0 &&
          !isLoadingFolders && (
            <SubMenuElement emptyPath={selectedPathId}>
              <>
                {/* <FolderSvg /> */}
                {t('APP.SUBMENU.SUBMENU.PH_3B5907', {
                  defaultValue: 'Aucun dossier',
                })}
              </>
            </SubMenuElement>
          )}

        {/* folders display */}
        {isExistChildrenPaths &&
          !isLoadingFolders &&
          childrenPaths.map((path) => (
            <SubMenuElement key={path.id} path={path}>
              <>
                <FolderSvg />
              </>
            </SubMenuElement>
          ))}
        {/* createFolder */}
        {isCreateFolder && (
          <div className="subMenuElement">
            <FolderSvg />
            <TextField
              autoFocus
              id="standard-basic"
              onChange={(e) => {
                handleChangeInputFolder(e.currentTarget.value);
              }}
              onBlur={handleCreateFolder}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleCreateFolder();
                }
              }}
            />
          </div>
        )}
      </div>
      {/* CREATE FOLDER */}
      {!isExistChildrenCompanies && (
        <Tooltip title={createFolderTooltip()}>
          <div className={createFolderClassName()} onClick={handleClicCreateFolder}>
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4684 6.6777H4.52521V22.8206H25.2082V10.2846H14.2393L10.4684 6.6777ZM15.7495 12.5768V15.2536H18.4137C18.543 15.2536 18.6502 15.3513 18.6502 15.4743V16.7985C18.6502 16.9183 18.543 17.0192 18.4137 17.0192H15.7495V19.696C15.7495 19.819 15.6518 19.9199 15.5288 19.9199H14.2046C14.0848 19.9199 13.9839 19.819 13.9839 19.696V17.0192H11.3197C11.1904 17.0192 11.0832 16.9183 11.0832 16.7985V15.4743C11.0832 15.3513 11.1904 15.2536 11.3197 15.2536H13.9839V12.5768C13.9839 12.4538 14.0848 12.3529 14.2046 12.3529H15.5288C15.6518 12.3529 15.7495 12.4538 15.7495 12.5768Z"
                fill="#FAFAFA"
              />
              <path
                d="M26.4694 8.01454H15.1504L11.4521 4.47699C11.405 4.43293 11.3431 4.40818 11.2787 4.40762H3.26399C2.70593 4.40762 2.25507 4.85849 2.25507 5.41655V24.0817C2.25507 24.6398 2.70593 25.0907 3.26399 25.0907H26.4694C27.0274 25.0907 27.4783 24.6398 27.4783 24.0817V9.02347C27.4783 8.46541 27.0274 8.01454 26.4694 8.01454ZM25.2082 22.8206H4.52516V6.67771H10.4684L14.2392 10.2846H25.2082V22.8206Z"
                fill="current"
              />
              <path
                d="M13.9839 12.5768V15.2536H11.3197C11.1904 15.2536 11.0832 15.3513 11.0832 15.4743V16.7985C11.0832 16.9183 11.1904 17.0192 11.3197 17.0192H13.9839V19.696C13.9839 19.819 14.0848 19.9199 14.2046 19.9199H15.5288C15.6517 19.9199 15.7495 19.819 15.7495 19.696V17.0192H18.4137C18.543 17.0192 18.6502 16.9183 18.6502 16.7985V15.4743C18.6502 15.3513 18.543 15.2536 18.4137 15.2536H15.7495V12.5768C15.7495 12.4538 15.6517 12.3529 15.5288 12.3529H14.2046C14.0848 12.3529 13.9839 12.4538 13.9839 12.5768Z"
                fill="current"
              />
            </svg>
          </div>
        </Tooltip>
      )}
    </div>
  );
};
