import { Dispatch } from 'redux';
import { MapDispatchToPropsParam, MapStateToPropsParam, connect } from 'react-redux';

import { updateCheckValue } from '../../actions/report';

import { WidgetsDTO } from '../../app/projects/Report/Widgets/WidgetsDTO';

import SelectionWidgets from '../../app/projects/Report/SelectionWidgets';

interface IReport {
  widgets: WidgetsDTO[];
  widgetsOpen: boolean;
}

type State = {
  report: IReport;
};

const mapStateToProps: MapStateToPropsParam<{}, {}, State> = (state: State) => ({
  widgets: state.report.widgets,
  widgetsOpen: state.report.widgetsOpen,
});

const mapDispatchToProps: MapDispatchToPropsParam<{}, {}> = (
  dispatch: Dispatch
) => ({
  updateCheckValue: (name: string, value: number) => {
    dispatch(updateCheckValue(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectionWidgets);
