/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import './Project.scss';
import { Auth } from '@aws-amplify/auth';
import { Button } from '@photocarbone/nooco-ui';
import {
  REFERENCE_TYPE_NAME,
  TEMPLATE_TYPE_NAME,
  TYPE_GLOBAL_MONITORING,
} from '../globalVariable/typeProject';
import { Tab, Tabs, makeStyles, withStyles } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import { useLocalStorage, useUnmount } from 'react-use';
import { withRouter } from 'react-router-dom';
import { zoomLevel } from 'zoom-level';
import Alert from '@material-ui/lab/Alert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ProjectErrorDialog from '../shared/ProjectErrorDialog/ProjectErrorDialog.jsx';
import ProjectNotFoundDialog from '../shared/ProjectNotFoundDialog/ProjectNotFoundDialog.jsx';

import AppContext from '../AppContext';
import Boarding from '../../containers/Boarding';
import ListActions from '../../containers/ListActions';
import ReportExploit from '../exploitation/Report';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import Import from '../../containers/Import';
import IndicatorsModal from '../../containers/IndicatorsModal';
import LifeCycleTab from '../../containers/LifeCycleTab';
import ListEquipments from '../../containers/ListEquipments';

import MenuItem from '@material-ui/core/MenuItem';
import ProjectCardHeader from './ProjectCard/ProjectCardHeader';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Report from '../../containers/Report';
import Rsee from '../../containers/Rsee';
import Select from '@material-ui/core/Select';
import Settings from '../../containers/Settings';
import Snackbar from '@material-ui/core/Snackbar';
import Spinner from '../shared/Spinner';
import Tooltip from '@material-ui/core/Tooltip';
import Variant from '../../containers/Variant';

import {
  NAVIGATION_TAB_LISTS,
  NAVIGATION_TAB_LISTS_LABELS,
  NAVIGATION_TAB_LISTS_TRAD_REF,
  NAVIGATION_TABS_VALUE,
} from '../globalVariable/navGlobalVariable.ts';
import {
  TYPE_BBCA,
  TYPE_EPCM,
  TYPE_EXPLOITATION,
  TYPE_MAPPINGREF,
  TYPE_NOOCO,
  TYPE_RE2020,
} from '../globalVariable/typeProject';
import { calculatorReset, calculatorWaterReset } from '../../actions/calculator';
import { resetImportStates } from '../../actions/import.js';
import { useDispatch } from 'react-redux';
import ClimateInnovation from './ClimateInnovation/ClimateInnovation.tsx';
import Mapping from './RefProject/Mapping';
import { useTranslation } from 'react-i18next';
import { SelectedSearchGroupOptionsLabel } from '../shared/QuickAddBarEquipment/QuickAddBarEquipment.tsx';
import {
  resetSelectedSearchGroupTypeIfNeeded,
  returnSearchGroupOptions,
} from '../shared/utilsFunction.ts';

const useStyles = makeStyles(() => ({
  indicator: {
    borderTop: ' 2px solid #2B6ED3',
    marginTop: '15px',
    paddingBottom: '10px',
    backgroundColor: 'transparent',
  },
}));

const AntTabs = withStyles({
  root: {
    minHeight: window.screen.availHeight < 750 ? 0 : null,
    height: window.screen.availHeight < 750 ? 30 : null,
  },
  indicator: {
    backgroundColor: '#2B6ED3',
  },
  flexContainer: {
    display: 'inline-flex',
    background: 'white',
    padding: '0 10px',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    minWidth: 72,
    paddingTop: 0,
    fontWeight: theme.typography.fontWeightRegular,
    paddingRight: theme.spacing(4),
    fontFamily: ['Roboto'].join(','),
    height: '60px',
    '&:hover': {
      color: '#1570EF',
      opacity: 1,
    },
    '&$selected': {
      color: '#1570EF',
      opacity: 1,
      fontWeight: 'bolder',
    },
    '&:focus': {
      color: '#1570EF',
      opacity: 1,
    },
  },
  selected: {},
  wrapper: {
    marginBottom: window.screen.availHeight < 750 ? '12px' : null,
  },
}))((props) => <Tab disableRipple {...props} />);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ProjectOverview = ({
  allGroups,
  fetchProjectById,
  currentProject,
  projectsDetails,
  setCurrentProject,
  groups,
  rate,
  isLoadingProjectDetails,
  changeProjectMode,
  setProjectId,
  projectId,
  setLoadingProjectDetails,
  loadProjectById,
  fetchImportDate,
  importDate,
  openIndicators,
  buildingTypes,
  currentIndicator,
  indicators,
  decMds,
  zones,
  updateFormValue,
  executionId,
  fetchImportDatas,
  isAdmin,
  lotMode,
  changeValueEquipments,
  changeValueVariant,
  failedTab,
  patchModeLoading,
  socket,
  userCredential,
  user,
  downloadRsee,
  currentAllotment,
  needAnimation,
  redirectionImport,
  resetExploitation,
  isResponseProjectError,
  projectErrorMessage,
  licenceTypeAccess,
}) => {
  const { id } = useParams();
  const pageQuerry = Number(useQuery().get('page'));
  const ctx = useContext(AppContext);
  const [tabValue, setTabValue] = useState(pageQuerry ? pageQuerry : 0);
  const [equipments, setEquipments] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorSnackOpen, setErrorSnakOpen] = useState(false);
  const { t } = useTranslation();
  const [tabList, setTabList] = useState([]);
  const tableEditModeRef = useRef();
  const [selectedSearchGroupType, setSelectedSearchGroupType] = useLocalStorage(
    `selectedSearchGroupType-${user.userId}`,
    SelectedSearchGroupOptionsLabel.Equipements
  );

  const dispatch = useDispatch();

  const refPopper = useRef(null);

  const handleClose = (event) => {
    if (refPopper.current && refPopper.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (currentProject?.errorIds?.length > 0) setErrorSnakOpen(true);

    if (currentProject?.templateType) {
      const newTabList = getTabList();

      setTabList(newTabList);
    }
  }, [currentProject, importDate]);

  useEffect(() => {
    if (
      tabValue !== NAVIGATION_TABS_VALUE.SETTINGS &&
      currentProject.templateType !== TYPE_EXPLOITATION
    ) {
      if (Number(id) === currentProject.id && currentProject.Sheets?.length === 0) {
        window.history.replaceState(
          null,
          null,
          `?page=${NAVIGATION_TABS_VALUE.LIST_EQUIPMENTS}`
        );
        setTabValue(NAVIGATION_TABS_VALUE.LIST_EQUIPMENTS);
      }
    }

    let searchGroupOptionsAvailable = returnSearchGroupOptions(currentProject);

    resetSelectedSearchGroupTypeIfNeeded(
      setSelectedSearchGroupType,
      selectedSearchGroupType,
      searchGroupOptionsAvailable
    );
  }, [currentProject]);

  const handleErrorClose = () => {
    setErrorSnakOpen(false);
  };

  const classes = useStyles();
  const rerenderCallback = useCallback(async (indicatorId = 1) => {
    fetchProjectById(id, currentIndicator ? currentIndicator.id : indicatorId);
    changeValueVariant('variants', []);
  }, []);

  useEffect(() => {
    updateFormValue('filterOn', []);
  }, [tabValue]);

  useEffect(() => {
    zoomLevel(); // 1;

    const body = document.querySelector('.project-overview-container');

    if (body && window.screen.availWidth <= 1600) {
      body.style.minHeight = '100vh';
    }

    window.addEventListener('resize', () => {
      zoomLevel(); // will return current browser's zoom level
    });

    return () => {
      updateFormValue('currentAllotment', null);

      updateFormValue('projectAllot', {});
      changeValueVariant('variants', []);
      changeValueVariant('variantsInDetails', {});
      changeValueVariant('selectedVariants', []);
      changeValueVariant('dataGraph', []);
      changeValueVariant('keysGraph', []);
      changeValueEquipments('searchBarValue', '');
      updateFormValue('searchValue', '');
      updateFormValue('inputValue', '');
      updateFormValue('selectedLot', null);
      updateFormValue('selectedFonction', null);
      updateFormValue('selectedCategorie', null);
      updateFormValue('selectedMaterial', null);
      updateFormValue('selectedType', null);
      updateFormValue('graphData', null);
      updateFormValue('copyData', null);
      updateFormValue('clickLevelAllot', null);
      updateFormValue('allotSteps', []);
      updateFormValue('filteredLifeCycle', null);
      updateFormValue('sheetToLifeCycle', null);
      updateFormValue('lotMode', null);
      updateFormValue('settingsDatas', null);
      updateFormValue('workflowImportModal', null);
      resetExploitation();
      updateFormValue('currentImport', null);
      updateFormValue('zoneEquipmentId', null);
      updateFormValue('nodeSearchValue', null);
      updateFormValue('currentProject', {});
      dispatch(calculatorReset());
      dispatch(calculatorWaterReset());
      dispatch(resetImportStates());
    };
  }, []);

  useUnmount(() => {
    changeValueEquipments('allFiltersFromTable', null);
    changeValueEquipments('filtersTableActive', null);
    changeValueEquipments('dataTable', []);
    changeValueEquipments('filteredData', null);
  }, []);

  const getTabList = () => {
    let enumTab = [
      NAVIGATION_TAB_LISTS.LIFE_CYCLE_TAB,
      NAVIGATION_TAB_LISTS.LIST_EQUIPMENTS,
      NAVIGATION_TAB_LISTS.REPORT,
      NAVIGATION_TAB_LISTS.SETTINGS,
      NAVIGATION_TAB_LISTS.VARIANT,
    ];

    if (currentProject.templateType === TEMPLATE_TYPE_NAME.BBCA) {
      const reportIndex = enumTab.indexOf(NAVIGATION_TAB_LISTS.REPORT);

      enumTab.splice(reportIndex + 1, 0, NAVIGATION_TAB_LISTS.CLIMATE_INNOVATION);
    }

    if (importDate.length > 0) {
      enumTab.push(NAVIGATION_TAB_LISTS.IMPORT);
    }

    if (isAdmin) {
      enumTab.push(NAVIGATION_TAB_LISTS.RSEE);
    }

    if (currentProject.templateType === TYPE_EXPLOITATION) {
      enumTab = [
        NAVIGATION_TAB_LISTS.EXPLOIT_BOARDING,
        NAVIGATION_TAB_LISTS.EXPLOIT_LIST_ACTIONS,
        NAVIGATION_TAB_LISTS.EXPLOIT_REPORT,
        NAVIGATION_TAB_LISTS.SETTINGS,
      ];
    }

    return enumTab;
  };

  const TabListComponentToDisplay = {
    [NAVIGATION_TAB_LISTS.LIFE_CYCLE_TAB]: (
      <LifeCycleTab
        groups={groups}
        equipments={equipments}
        rate={rate}
        list={groups}
        decMds={decMds}
      />
    ),
    [NAVIGATION_TAB_LISTS.LIST_EQUIPMENTS]: (
      <ListEquipments
        lots={currentProject?.Lots}
        allGroups={allGroups}
        callback={rerenderCallback}
        fetchImport={fetchImportDate}
        setTabValue={setTabValue}
        tabValue={tabValue}
        tableEditModeRef={tableEditModeRef}
      />
    ),
    [NAVIGATION_TAB_LISTS.REPORT]: (
      <Report
        setTabValue={setTabValue}
        project={currentProject}
        masterDatas={groups}
        rates={rate}
        currentIndicator={currentIndicator}
      />
    ),
    [NAVIGATION_TAB_LISTS.CLIMATE_INNOVATION]: <ClimateInnovation />,
    [NAVIGATION_TAB_LISTS.SETTINGS]: (
      <Settings
        infos={currentProject}
        id={currentProject.id}
        callback={rerenderCallback}
        fetchImport={fetchImportDate}
        allGroups={allGroups}
        buildingTypes={buildingTypes}
      />
    ),
    [NAVIGATION_TAB_LISTS.VARIANT]: <Variant />,
    [NAVIGATION_TAB_LISTS.IMPORT]: (
      <Import
        setTabValue={setTabValue}
        id={currentProject.id}
        allGroups={allGroups}
        lots={currentProject?.Lots}
        tabValue={tabValue}
      />
    ),
    [NAVIGATION_TAB_LISTS.RSEE]: <Rsee />,
    [NAVIGATION_TAB_LISTS.EXPLOIT_BOARDING]: <Boarding />,
    [NAVIGATION_TAB_LISTS.EXPLOIT_LIST_ACTIONS]: <ListActions />,
    [NAVIGATION_TAB_LISTS.EXPLOIT_REPORT]: <ReportExploit />,
  };

  const TabPanel = (props) => {
    const { value: tabIndex, index, ...other } = props;
    let tab = null;
    tab = TabListComponentToDisplay[tabList[tabIndex]];

    const tabWithGreyBackground = [
      NAVIGATION_TAB_LISTS.SETTINGS,
      NAVIGATION_TAB_LISTS.CLIMATE_INNOVATION,
    ];

    return (
      <div
        role="tabpanel"
        hidden={tabIndex !== index}
        id={`simple-tabpanel-${index}`}
        className="tabpanel-container"
        style={{
          backgroundColor: tabWithGreyBackground.includes(tabList[index])
            ? '#E9EDF6'
            : '',
        }} //temp for settings background
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabIndex === index && tab}
      </div>
    );
  };

  const handleIsClose = () => {
    setTimeout(() => {
      updateFormValue('failedTab', []);
    }, 1000);
  };

  useEffect(() => {
    updateFormValue('isMenuOpen', false);

    if (currentProject.type !== 'mappingRef') {
      if (id !== projectId || !projectId) {
        setProjectId(id);
      }

      if (projectId === id) {
        if (projectsDetails[projectId]) {
          setCurrentProject(projectsDetails[projectId]);
        } else {
          setLoadingProjectDetails();

          fetchProjectById(id, 1);
        }
      }
    }
  }, [projectId]);

  const handleChange = (event, newValue) => {
    // I think is for the importIndexTab. But normally its resolve in useEffect
    // if (!importDate.length > 0 && newValue === 5) newValue = 6;

    window.history.replaceState(null, null, `?page=${newValue}`);
    setTabValue(newValue);
  };
  useEffect(() => {
    fetchImportDate(id);
  }, []);

  useEffect(() => {
    if (currentProject && currentProject.name) {
      document.title = currentProject.name;
    }
  }, [currentProject]);

  if (
    isResponseProjectError &&
    projectErrorMessage &&
    projectErrorMessage === 'Votre projet est inexistant ou a été supprimé'
  ) {
    return <ProjectNotFoundDialog />;
  }
  if (
    isResponseProjectError &&
    projectErrorMessage &&
    projectErrorMessage === 'Une erreur est survenue. Veuillez contacter le support'
  ) {
    return <ProjectErrorDialog />;
  }
  if (isLoadingProjectDetails) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  return (
    <>
      <Snackbar
        open={failedTab.length > 0}
        onClose={handleIsClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {failedTab.length > 0 && (
          <Alert onClose={handleIsClose} severity="error">
            {
              (t('APP.PROJECTS.PROJECTOVERVIEW.PH_DA5A6B', {
                defaultValue: `
              Le format {failedTab, plural,
              one {de l'onglet : {failedTabList} n'est pas correct}
              other {des onglets : {failedTabList} ne sont pas corrects}}
            `,
              }),
              {
                failedTab: failedTab.length,
                failedTabList: failedTab.map((tab) => tab),
              })
            }
          </Alert>
        )}
      </Snackbar>
      {currentProject.templateType === 'mappingRef' &&
      licenceTypeAccess === 'pro' ? (
        <Mapping />
      ) : (
        currentProject.name && (
          <div
            className="project-overview-container"
            style={{
              width: '80%',
              padding: window.screen.availHeight < 700 ? '5px' : null,
            }}
          >
            <Snackbar
              open={errorSnackOpen}
              onClose={handleErrorClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert
                onClose={handleErrorClose}
                severity="warning"
                sx={{ width: '100%' }}
              >
                {t(
                  'APP.PROJECTS.PROJECTOVERVIEW.PH_1D78E2',

                  {
                    value: currentProject.errorIds?.join(', '),
                    defaultValue:
                      'Les fiches {value} n’ont pas pu être importées dans votre projet.',
                  }
                )}
              </Alert>
            </Snackbar>
            {/* <ProjectCard
          currentIndicator={currentIndicator}
          changeValueEquipments={changeValueEquipments}
          updateFormValue={updateFormValue}
          sources={sources}
          project={currentProject}
          onClick={() => {}}
          disabled
          /> */}
            <ProjectCardHeader />
            <div
              id="project-overview-tab-container"
              style={{ position: 'relative' }}
            >
              <div className="tabs-container">
                {currentProject.templateType !== TYPE_EXPLOITATION && (
                  <AntTabs
                    value={tabValue}
                    onChange={(event, index) => {
                      const isProAccess = licenceTypeAccess === 'pro';
                      const isNotProAccess = licenceTypeAccess !== 'pro';
                      const isParamsTab =
                        tabList[index] === NAVIGATION_TAB_LISTS.SETTINGS;
                      const isImportTab =
                        tabList[index] === NAVIGATION_TAB_LISTS.IMPORT &&
                        importDate.length > 0;

                      if (
                        isProAccess ||
                        (isNotProAccess && !isParamsTab) ||
                        (isNotProAccess && !isImportTab)
                      ) {
                        if (tableEditModeRef.current === true) {
                          changeValueEquipments(
                            'isOpenModalExitNavigationConfirmation',
                            index
                          );
                          return;
                        }
                        handleChange(event, index);
                      }
                    }}
                    TabIndicatorProps={{ className: classes.indicator }}
                  >
                    {tabList.map((tab, index) => {
                      if (
                        tab === NAVIGATION_TAB_LISTS.SETTINGS &&
                        licenceTypeAccess !== 'pro'
                      ) {
                        return (
                          <Tooltip
                            key={tab}
                            title={
                              licenceTypeAccess === 'pro'
                                ? ''
                                : t('APP.PROJECTS.PROJECTOVERVIEW.PH_CE975A', {
                                    defaultValue:
                                      'Fonctionnalité disponible avec une licence pro seulement',
                                  })
                            }
                          >
                            <>
                              <AntTab
                                style={{
                                  fontSize:
                                    window.screen.availHeight > 810 &&
                                    window.screen.availHeight < 950
                                      ? '1rem'
                                      : null,
                                  backgroundColor:
                                    licenceTypeAccess === 'pro' ? '' : 'lightGray',
                                  pointerEvents:
                                    licenceTypeAccess === 'pro'
                                      ? 'auto!important'
                                      : 'none!important',
                                }}
                                // label={NAVIGATION_TAB_LISTS_LABELS[tab]}
                                label={t(NAVIGATION_TAB_LISTS_TRAD_REF[tab], {
                                  defaultValue: NAVIGATION_TAB_LISTS_LABELS[tab],
                                })}
                                disabled={licenceTypeAccess !== 'pro'}
                              />
                            </>
                          </Tooltip>
                        );
                      } else if (
                        importDate.length > 0 &&
                        tab === NAVIGATION_TAB_LISTS.IMPORT
                      ) {
                        return (
                          <Tooltip
                            key={tab}
                            title={
                              licenceTypeAccess === 'pro'
                                ? ''
                                : t('APP.PROJECTS.PROJECTOVERVIEW.PH_CE975A', {
                                    defaultValue:
                                      'Fonctionnalité disponible avec une licence pro seulement',
                                  })
                            }
                          >
                            <>
                              <AntTab
                                className={needAnimation ? 'borderAnimated' : null}
                                onClick={() => {
                                  window.history.replaceState(
                                    null,
                                    null,
                                    `?page=${index}`
                                  );
                                  setTabValue(index);
                                }}
                                label={t(NAVIGATION_TAB_LISTS_TRAD_REF[tab], {
                                  defaultValue: NAVIGATION_TAB_LISTS_LABELS[tab],
                                })}
                                style={{
                                  fontSize:
                                    window.screen.availHeight > 810 &&
                                    window.screen.availHeight < 950
                                      ? '1rem'
                                      : null,
                                  backgroundColor:
                                    licenceTypeAccess === 'pro' ? '' : 'lightGray',
                                  pointerEvents:
                                    licenceTypeAccess === 'pro'
                                      ? 'auto'
                                      : 'none!important',
                                }}
                                disabled={licenceTypeAccess !== 'pro'}
                              />
                            </>
                          </Tooltip>
                        );
                      } else {
                        return (
                          <AntTab
                            key={tab}
                            style={{
                              fontSize:
                                window.screen.availHeight > 810 &&
                                window.screen.availHeight < 950
                                  ? '1rem'
                                  : null,
                            }}
                            label={t(NAVIGATION_TAB_LISTS_TRAD_REF[tab], {
                              defaultValue: NAVIGATION_TAB_LISTS_LABELS[tab],
                            })}
                          />
                        );
                      }
                    })}
                  </AntTabs>
                )}
                {currentProject.templateType === TYPE_EXPLOITATION && (
                  <AntTabs
                    value={tabValue}
                    onChange={handleChange}
                    TabIndicatorProps={{ className: classes.indicator }}
                  >
                    {tabList.map((tab, index) => {
                      return (
                        <AntTab
                          key={tab}
                          style={{
                            fontSize:
                              window.screen.availHeight > 810 &&
                              window.screen.availHeight < 950
                                ? '1rem'
                                : null,
                          }}
                          label={t(NAVIGATION_TAB_LISTS_TRAD_REF[tab], {
                            defaultValue: NAVIGATION_TAB_LISTS_LABELS[tab],
                          })}
                        />
                      );
                    })}
                  </AntTabs>
                )}
              </div>
              {indicators && (
                <div
                  className="indicators-button"
                  style={{ top: window.screen.availHeight < 750 ? '0' : null }}
                >
                  {[
                    TEMPLATE_TYPE_NAME.RE2020,
                    TEMPLATE_TYPE_NAME.EPCM,
                    TEMPLATE_TYPE_NAME.NOOCO,
                  ].includes(currentProject.templateType) &&
                    currentProject.ProjectOption.showAlternativeResult && (
                      <div ref={refPopper} className="lotMode">
                        <FormControl>
                          <Select
                            labelId="demo-simple-select-label"
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            IconComponent={ExpandMoreIcon}
                            id="demo-simple-select"
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSizeSmall MuiButton-sizeSmall"
                            style={{
                              fontSize:
                                window.screen.availHeight < 950 ? '0.9rem' : '12px',
                              textTransform: 'initial',
                              fontWeight: '500',
                              padding: '1.6px',
                              paddingLeft: '10px',
                              paddingTop: '2px',
                            }}
                            value={lotMode}
                            key={currentProject.id}
                            onChange={(e) => {
                              updateFormValue('lotMode', e.target.value);

                              const name = e.target.value.slice(12);

                              updateFormValue(
                                'currentAllotment',
                                allGroups.allotments.find((el) => el.name === name)
                              );

                              changeValueEquipments('iniesAdvanceLink', null);
                              updateFormValue('allotSteps', []);
                              changeValueEquipments('groupBy', 'aucun');

                              changeValueEquipments('isLoadingProject', true);
                              changeValueEquipments('hideColumns', []);

                              let copy = { ...currentProject };

                              copy.isRe2020 =
                                e.target.value === 'Référentiel RE2020';
                              copy.isFreeModeActivated =
                                e.target.value === 'Référentiel Nooco';
                              updateFormValue('patchModeLoading', true);
                              ctx.api
                                .patchProject({
                                  id: copy.id,
                                  ProjectOption: {
                                    CurrentAllotmentId: allGroups.allotments.find(
                                      (el) =>
                                        el.name.toLowerCase() === name.toLowerCase()
                                    ).id,
                                  },
                                })
                                .then(() => {
                                  fetchProjectById(
                                    projectId,
                                    currentIndicator?.id || 1
                                  );
                                });
                            }}
                          >
                            <MenuItem
                              value={`Référentiel ${
                                currentProject.templateType === TYPE_RE2020 ||
                                currentProject.templateType === TYPE_NOOCO
                                  ? 'RE2020'
                                  : 'E+C-'
                              }`}
                            >
                              {t('APP.PROJECTS.PROJECTOVERVIEW.PH_797C98', {
                                defaultValue: 'Référentiel',
                              })}{' '}
                              {currentProject.templateType === TYPE_RE2020 ||
                              currentProject.templateType === TYPE_NOOCO
                                ? REFERENCE_TYPE_NAME.RE2020
                                : REFERENCE_TYPE_NAME.EPCM}
                            </MenuItem>
                            <MenuItem value="Référentiel NOOCO">
                              {t('APP.PROJECTS.PROJECTOVERVIEW.PH_15283A', {
                                defaultValue: 'Référentiel Nooco',
                              })}
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {/* <Button variant="contained" style={{ marginRight: '10px' }} size="small" onClick={() => setOpen(!open)} endIcon={<ExpandMoreIcon />}>Analyse {lotMode}</Button>
                <Popper role={undefined} open={open} anchorEl={refPopper.current} transition disablePortal>
                {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
                >
                <Paper>
                <ClickAwayListener
                onClickAway={handleClose}
                >
                <MenuList id="split-button-menu">
                <MenuItem
                // key={option}
                // disabled={index === 2}
                // selected={index === selectedIndex}
                // onClick={(event) => handleMenuItemClick(event, index)}
                >
                Analyse RE2020
                </MenuItem>
                </MenuList>
                </ClickAwayListener>
                </Paper>
                </Grow>
                )}
                </Popper> */}
                      </div>
                    )}

                  <div>
                    {currentProject.templateType !== TYPE_EXPLOITATION && (
                      <Tooltip
                        title={t('APP.PROJECTS.PROJECTOVERVIEW.PH_F9E372', {
                          defaultValue:
                            "Naviguer entre les différents indicateurs de l'ACV",
                        })}
                      >
                        <div className="wrapperIndicator">
                          <Button
                            additionalIcon={<KeyboardArrowDownIcon />}
                            style={{
                              fontSize:
                                window.screen.availHeight < 950 ? '0.9rem' : '',
                            }}
                            className="close-button"
                            endIcon={<ExpandMoreIcon />}
                            onClick={() => openIndicators(true)}
                            text={
                              currentIndicator
                                ? `${
                                    currentProject?.templateType?.includes(
                                      TEMPLATE_TYPE_NAME.EPCM
                                    ) &&
                                    currentIndicator.relativeName.match(/\(.*\)/gm)
                                      ? currentIndicator.relativeName
                                          .match(/\(.*\)/gm)[0]
                                          .slice(1, -1)
                                      : currentIndicator.shortName
                                  } (${currentIndicator.unit})`
                                : t('APP.PROJECTS.PROJECTOVERVIEW.PH_9E2E58', {
                                    defaultValue: 'Indicateurs',
                                  })
                            }
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              )}
              {patchModeLoading && tabValue !== 1 && <Spinner />}
              <TabPanel
                style={{ 'background-color': '#FFFFFF' }}
                value={tabValue}
                index={1}
              />

              {!patchModeLoading && (
                <>
                  <TabPanel
                    style={{ 'background-color': '#FFFFFF' }}
                    value={tabValue}
                    index={0}
                  />

                  <TabPanel
                    style={{ 'background-color': '#FFFFFF' }}
                    value={tabValue}
                    index={2}
                  />
                  <TabPanel
                    style={{ 'background-color': '#FFFFFF' }}
                    value={tabValue}
                    index={3}
                  />
                  <TabPanel
                    style={{ 'background-color': '#FFFFFF' }}
                    value={tabValue}
                    index={4}
                  />
                  <TabPanel
                    style={{ 'background-color': '#FFFFFF' }}
                    value={tabValue}
                    index={5}
                  />
                  <TabPanel
                    style={{ 'background-color': '#FFFFFF' }}
                    value={tabValue}
                    index={6}
                  />
                  <TabPanel
                    style={{ 'background-color': '#FFFFFF' }}
                    value={tabValue}
                    index={7}
                  />
                </>
              )}
            </div>
            <IndicatorsModal />
          </div>
        )
      )}
    </>
  );
};

export default withRouter(ProjectOverview);
