import API from '../../app/services/API';

const api = new API();

type fetchVariantsProps = {
  projectId: number;
  currentIndicatorId: number;
};
export const fetchVariants = async ({
  projectId,
  currentIndicatorId,
}: fetchVariantsProps) => {
  return await api
    .fetchVariantsById(projectId, currentIndicatorId)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export type createVariantProps = {
  projectId: number;
  variantName: string;
  companyId: number;
  exploitData?: any;
  isAddVariantSelected: boolean;
  keepLinkToCurrentProject: boolean;
};
export const createVariant = async ({
  projectId,
  variantName,
  companyId,
  exploitData,
  isAddVariantSelected,
  keepLinkToCurrentProject,
}: createVariantProps) => {
  return await api
    .createVariant(
      projectId,
      variantName,
      companyId,
      exploitData,
      isAddVariantSelected,
      keepLinkToCurrentProject
    )
    .then((response) => response)
    .catch((e) => {
      throw e;
    });
};

export const deleteVariant = async (variantId: number) => {
  return await api
    .deleteProject(variantId)
    .then((response) => response)
    .catch((e) => {
      throw e;
    });
};

export type patchVariantProps = {
  variantId: number;
  body: any;
};
export const patchVariant = async (data: patchVariantProps) => {
  const { variantId, body } = data;

  body.id = variantId;

  return await api
    .patchProject(body)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};
