import './zonesForm.scss';
import { Button } from '@photocarbone/nooco-ui';
import { Select, Tooltip } from '@material-ui/core';
import { StateDTO } from '../../../../../models/GeneralDTO';
import {
  addZonesSections,
  handleControlledZones,
  managementBuildingSections,
  updateFormValue,
} from '../../../../../../actions/projects';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';

import { Controller } from 'react-hook-form';
import { buildingTypesMap } from '../../../../../services/mathematics/indicators/buildingTypesMap';
import {
  isDisplayGroundExpansion,
  isDisplayOccupantNumber,
} from '../../../../../shared/ZoneSection/ZoneSectionFunction';
import { isNum } from '../../../../../shared/utilsFunction';
import { returnProjectTypeFromProjectTypeTitle } from '../../../../../shared/utils';
import { BUILDING_TYPE_ID } from '../../../../../globalVariable/typeProject';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import NoocoCheckbox from '../../../../../shared/NoocoCheckbox';
import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import TreeSelect, { TreeNode } from 'antd/lib/tree-select';
import { useTranslation } from 'react-i18next';
import { returnProjectTypeTitleFromTemplateType } from '../../../../../shared/utils';
import LabelWithTooltip from '../../../../../shared/LabelWithToolTip';
import { TEMPLATE_TYPE_NAME } from '../../../../../globalVariable/typeProject';
import { isDisplaySelectZoneType } from '../../../CreationProjectFunction/creationProjectFunction';
import {
  CREATION_STEPS,
  CREATION_STEPS_TRAD_KEYS,
} from '../../../../../globalVariable/creationProjectVariable';

interface ZonesFormProps {
  errors: any;
  control: any;
  setValue: Function;
}

enum InputName {
  groundExpansion = 'groundExpansion',
}

const ZonesForm = ({ errors, control, setValue }: ZonesFormProps) => {
  const dispatch = useDispatch();
  const {
    buildingSections,
    rsetFile,
    isRsenv,
    buildingTypes,
    refUnit,
    allGroups,
    creationProject,
  } = useSelector((state: StateDTO) => state.projects);
  const { creationSteps } = useSelector(
    (state: StateDTO) => state.projects.creationProject
  );
  const { t } = useTranslation();

  // Set the default value in the form state
  useEffect(() => {
    buildingSections.forEach((building, buildingIndex) => {
      building.Zones.forEach((zone, zoneIndex) => {
        setValue(`building${buildingIndex}-zone${zoneIndex}-isTAC`, false);
        dispatch(handleControlledZones(buildingIndex, zoneIndex, `isTAC`, false));
        setValue(`building${buildingIndex}-zone${zoneIndex}-OEefProdPvAnnuel`, 1);
        dispatch(
          handleControlledZones(buildingIndex, zoneIndex, `OEefProdPvAnnuel`, 1)
        );

        setValue(`building${buildingIndex}-zone${zoneIndex}-OEefProdPvAcAnnuel`, 1);
        dispatch(
          handleControlledZones(buildingIndex, zoneIndex, `OEefProdPvAcAnnuel`, 1)
        );
      });
    });
  }, []);

  useEffect(() => {
    if (isRsenv) {
      buildingSections.forEach((building, buildingIndex) => {
        building.Zones.forEach((zone, zoneIndex) => {
          setValue(
            `building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsUnderground`,
            zone.nbParkingSpotsUnderground
          );
          setValue(
            `building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsUndergroundPlu`,
            zone.nbParkingSpotsUndergroundPlu
          );
          setValue(
            `building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsSurface`,
            zone.nbParkingSpotsSurface
          );
          setValue(
            `building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsSurfacePlu`,
            zone.nbParkingSpotsSurfacePlu
          );
          setValue(
            `building${buildingIndex}-zone${zoneIndex}-groundExpansion`,
            zone.groundExpansion
          );
        });
      });
    }
  }, [isRsenv]);

  const selectedProjectType = allGroups.projectTypes.find(
    (projectType) => projectType.id === creationProject.selectedTypeProjectId
  )?.name;

  let forbiddenBuildingTypes = [
    'Aéroport',
    'Infrastructure extérieure',
    'Datacenter',
    'Prototype',
  ];
  if (selectedProjectType === TEMPLATE_TYPE_NAME.BBCA) {
    forbiddenBuildingTypes = buildingTypes
      .filter(
        (buildingType) =>
          ![
            BUILDING_TYPE_ID.COLLECTIVE_RESIDENTIAL,
            BUILDING_TYPE_ID.OFFICE,
          ].includes(buildingType.id)
      )
      .map((buildingType) => buildingType.name);
  }

  const returnClassNameOverflow = () => {
    if (
      creationSteps.includes(
        t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.ECOCONCEPTION])
      ) ||
      creationSteps.includes(
        t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.GLOBAL_MONITORING])
      )
    ) {
      return 'overflow-without-impact';
    } else if (
      creationSteps.includes(t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.EPCM])) ||
      creationSteps.includes(t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.RE2020]))
    ) {
      return 'overflow-with-impact';
    }

    return '';
  };

  const isValid = (value: any) => {
    if (value === 0 || value === '0') {
      return true;
    }

    return isNum(value);
  };

  const checkConditionForDisplay = (
    inputName: string,
    zone: {
      name: string;
      BuildingTypeId: number | string;
      menInput: boolean;
      logementInput: boolean;
      comblesInput: boolean;
      area: string | number;
      type: string;
    }
  ) => {
    if (inputName === 'comblesInput') {
      if (
        zone.BuildingTypeId == 2 &&
        creationSteps.includes(t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.RE2020]))
      ) {
        return true;
      }
    } else if (inputName === 'surface') {
      if (
        creationSteps.includes(
          t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.NEW_CONSTRUCTION])
        )
      ) {
        return true;
      }

      if (refUnit === 'm²') {
        return true;
      }
    } else if (
      creationSteps.includes(t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.EPCM]))
    ) {
      return true;
    }

    if (inputName === 'occupants') {
      return isDisplayOccupantNumber({
        templateType: selectedProjectType || '',
      });
    } else if (inputName === 'logement') {
      if (
        creationSteps.includes(t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.RE2020])) &&
        (Number(zone.BuildingTypeId) === buildingTypesMap.ENSEIGNEMENT_PRIMAIRE ||
          Number(zone.BuildingTypeId) === buildingTypesMap.ENSEIGNEMENT_SECONDAIRE)
      ) {
        return;
      }
      return isDisplayOccupantNumber({
        templateType: selectedProjectType || '',
        showAlternativeResult: false,
        buildingTypeId: Number(zone.BuildingTypeId),
      });
    } else if (inputName === 'urbanNetwork') {
      if (
        creationSteps.includes(t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.EPCM])) ||
        creationSteps.includes(t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.BBCA])) ||
        (creationSteps.includes(
          t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.RE2020])
        ) &&
          Number(zone.BuildingTypeId) === buildingTypesMap.LOGEMENT_COL) ||
        Number(zone.BuildingTypeId) === buildingTypesMap.BUREAUX ||
        Number(zone.BuildingTypeId) === buildingTypesMap.ENSEIGNEMENT_PRIMAIRE ||
        Number(zone.BuildingTypeId) === buildingTypesMap.ENSEIGNEMENT_SECONDAIRE ||
        ((creationSteps.includes(
          t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.FREE_RENOVATION])
        ) ||
          creationSteps.includes(
            t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.ECOCONCEPTION])
          )) &&
          Number(zone.BuildingTypeId) !== 2)
      ) {
        return true;
      }
    } else if (inputName === InputName.groundExpansion) {
      return isDisplayGroundExpansion({
        templateType: selectedProjectType,
        buildingTypeId: Number(zone.BuildingTypeId),
      });
    }

    if (inputName === 'isTAC') {
      const templateTypes = [
        TEMPLATE_TYPE_NAME.RE2020,
        TEMPLATE_TYPE_NAME.EPCM,
        TEMPLATE_TYPE_NAME.BBCA,
        TEMPLATE_TYPE_NAME.EXPLOITATION,
        TEMPLATE_TYPE_NAME.RENOVATION,
        TEMPLATE_TYPE_NAME.NOOCO,
        TEMPLATE_TYPE_NAME.GLOBAL_MONITORING,
      ];

      const projectTypeTitles = templateTypes.map((templateType) =>
        returnProjectTypeTitleFromTemplateType(templateType)
      );

      if (
        projectTypeTitles.some((projectTypeTitle) =>
          creationSteps.includes(projectTypeTitle)
        )
      ) {
        return true;
      }
    }

    return false;
  };


  return (
    <div className={`zones-form-wrapper ${returnClassNameOverflow()}`}>
      {buildingSections.map((section, buildingIndex) => {
        return (
          <>
            <div key={buildingIndex} className="zone-form">
              {/* LeftSide BuildingName */}
              <div className="building-name">
                <Controller
                  name={`building${buildingIndex}-name`}
                  control={control}
                  rules={{ required: !rsetFile }}
                  defaultValue={section.name}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={errors[`building${buildingIndex}-name`]}
                      value={section.name}
                      id="standard-basic"
                      style={{
                        width: '55%',
                        marginTop: '3em',
                        marginLeft: '2em',
                        marginRight: '20%',
                      }}
                      disabled={rsetFile ? true : false}
                      onChange={(e) => {
                        setValue(`building${buildingIndex}-name`, e.target.value);
                        dispatch(
                          handleControlledZones(
                            buildingIndex,
                            null,
                            `nameBuilding`,
                            e.target.value
                          )
                        );
                      }}
                    />
                  )}
                />
                {creationSteps.includes(
                  t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.BBCA])
                ) && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '1em',
                    }}
                  >
                    <Controller
                      name={`building${buildingIndex}-speculativeDevelopment`}
                      control={control}
                      // rules={{ required: !rsetFile}}
                      defaultValue={false}
                      render={({ field }) => (
                        <NoocoCheckbox
                          {...field}
                          defaultValue={false}
                          value={section.speculativeDevelopment}
                          small
                          onChange={(e) => {
                            setValue(
                              `building${buildingIndex}-speculativeDevelopment`,
                              e.currentTarget.checked
                            );
                            dispatch(
                              handleControlledZones(
                                buildingIndex,
                                null,
                                `speculativeDevelopment`,
                                e.currentTarget.checked
                              )
                            );
                          }}
                        />
                      )}
                    />
                    <div
                      style={{
                        fontSize: '0.7rem',
                        fontWeight: '500',
                        color: '#444B59',
                      }}
                    >
                      Livré en blanc
                    </div>
                  </div>
                )}
              </div>
              {/* rightSide zones form */}
              <div style={{ width: '100%' }}>
                {section.Zones.map((zone, zoneIndex) => (
                  <>
                    <div className="zone-part-form" key={zoneIndex}>
                      {/* zone Name */}
                      <Controller
                        name={`building${buildingIndex}-zone${zoneIndex}-name`}
                        control={control}
                        rules={{ required: !rsetFile }}
                        key={rsetFile ? zone.name : null}
                        defaultValue={zone.name}
                        render={({ field }) => (
                          <TextField
                            disabled={rsetFile ? true : false}
                            {...field}
                            error={
                              errors[
                                `building${buildingIndex}-zone${zoneIndex}-name`
                              ]
                            }
                            style={{ width: '15%', marginBottom: '0.5em' }}
                            id="standard-basic"
                            value={zone.name}
                            onChange={(e) => {
                              setValue(
                                `building${buildingIndex}-zone${zoneIndex}-name`,
                                e.target.value
                              );

                              dispatch(
                                handleControlledZones(
                                  buildingIndex,
                                  zoneIndex,
                                  'nameZone',
                                  e.target.value
                                )
                              );
                            }}
                          />
                        )}
                      />

                      {/* zone firstRow */}
                      <div className="high-part-type-form">
                        {/* typology */}
                        {isDisplaySelectZoneType({
                          zoneIndex,
                          templateType: selectedProjectType,
                        }) && (
                          <div className="type-form">
                            <p>
                              {t('APP.ZONESFORM.INDEX.PH_6FF068', {
                                defaultValue: 'Typologie de zone',
                              })}
                            </p>
                            <Controller
                              control={control}
                              name={`building${buildingIndex}-zone${zoneIndex}-type`}
                              rules={{ required: rsetFile ? false : true }}
                              defaultValue={zone.BuildingTypeId}
                              render={({ field }) => (
                                <TreeSelect
                                  {...field}
                                  showSearch
                                  disabled={rsetFile ? true : false}
                                  className="inputOfZone"
                                  value={zone.BuildingTypeId}
                                  dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                  }}
                                  placeholder={t('APP.ZONESFORM.INDEX.PH_9C5700', {
                                    defaultValue: 'Typologie de zone',
                                  })}
                                  allowClear
                                  treeDefaultExpandAll
                                  style={{ width: '90%' }}
                                  filterTreeNode={(inputValue, treeNode) => {
                                    return treeNode?.title
                                      .toLowerCase()
                                      .includes(inputValue.toLowerCase());
                                  }}
                                  onChange={(e: any) => {
                                    setValue(
                                      `building${buildingIndex}-zone${zoneIndex}-type`,
                                      e
                                    );
                                    dispatch(
                                      handleControlledZones(
                                        buildingIndex,
                                        zoneIndex,
                                        `type`,
                                        e
                                      )
                                    );
                                  }}
                                >
                                  {buildingTypes
                                    .filter(
                                      (el) =>
                                        !forbiddenBuildingTypes.includes(el.name)
                                    )
                                    .sort(
                                      (a, b) =>
                                        a.name.localeCompare(b.name) -
                                        b.name.localeCompare(a.name)
                                    )
                                    .map(
                                      (type: {
                                        Parent: any[];
                                        id: number;
                                        name: any;
                                        iniesId: string;
                                        idIniesEpcm: string;
                                      }) => {
                                        if (
                                          type.Parent?.length > 0 &&
                                          creationSteps.includes(
                                            t(
                                              CREATION_STEPS_TRAD_KEYS[
                                                CREATION_STEPS.EPCM
                                              ]
                                            )
                                          )
                                        ) {
                                          return (
                                            <TreeNode
                                              disabled
                                              key={type.id}
                                              value={type.id}
                                              title={type.name}
                                            >
                                              {type.Parent.sort(
                                                (a, b) =>
                                                  a.name.localeCompare(b.name) -
                                                  b.name.localeCompare(a.name)
                                              ).map(
                                                (subType: {
                                                  id: number;
                                                  name: any;
                                                }) => {
                                                  return (
                                                    <TreeNode
                                                      key={subType.id}
                                                      value={subType.id}
                                                      title={subType.name}
                                                    />
                                                  );
                                                }
                                              )}
                                            </TreeNode>
                                          );
                                        } else if (
                                          creationSteps.includes(
                                            t(
                                              CREATION_STEPS_TRAD_KEYS[
                                                CREATION_STEPS.RE2020
                                              ]
                                            )
                                          )
                                        ) {
                                          if (type.iniesId) {
                                            return (
                                              <TreeNode
                                                key={type.id}
                                                value={type.id}
                                                title={type.name}
                                              />
                                            );
                                          }
                                        } else if (
                                          creationSteps.includes(
                                            t(
                                              CREATION_STEPS_TRAD_KEYS[
                                                CREATION_STEPS.EPCM
                                              ]
                                            )
                                          )
                                        ) {
                                          if (type.iniesIdEpcm) {
                                            return (
                                              <TreeNode
                                                key={type.id}
                                                value={type.id}
                                                title={type.name}
                                              />
                                            );
                                          }
                                        } else {
                                          return (
                                            <TreeNode
                                              key={type.id}
                                              value={type.id}
                                              title={type.name}
                                            />
                                          );
                                        }
                                      }
                                    )}
                                </TreeSelect>
                              )}
                            />
                          </div>
                        )}

                        {/* occupantsNumber */}
                        {checkConditionForDisplay('occupants', zone) && (
                          <div className="type-form-small">
                            <p>
                              {t('APP.ZONESFORM.INDEX.PH_2F4C7A', {
                                defaultValue: "Nombre d'occupants",
                              })}
                            </p>
                            <Controller
                              control={control}
                              name={`building${buildingIndex}-zone${zoneIndex}-menInput`}
                              defaultValue={
                                zone.nbOccupants
                                  ? zone.nbOccupants
                                  : (
                                      creationSteps.includes(
                                        t(
                                          CREATION_STEPS_TRAD_KEYS[
                                            CREATION_STEPS.RE2020
                                          ]
                                        )
                                      )
                                        ? rsetFile
                                        : isRsenv
                                    )
                                  ? zone.menInput
                                  : 1
                              }
                              rules={{
                                required: !(creationSteps.includes(
                                  t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.RE2020])
                                )
                                  ? rsetFile
                                  : isRsenv),
                                pattern: /^[0-9]{1,20}([,.][0-9]{1,20})?$/,
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  defaultValue={
                                    zone.nbOccupants
                                      ? zone.nbOccupants
                                      : (
                                          creationSteps.includes(
                                            t(
                                              CREATION_STEPS_TRAD_KEYS[
                                                CREATION_STEPS.RE2020
                                              ]
                                            )
                                          )
                                            ? rsetFile
                                            : isRsenv
                                        )
                                      ? zone.menInput
                                      : 1
                                  }
                                  value={
                                    zone.nbOccupants
                                      ? zone.nbOccupants
                                      : (
                                          creationSteps.includes(
                                            t(
                                              CREATION_STEPS_TRAD_KEYS[
                                                CREATION_STEPS.RE2020
                                              ]
                                            )
                                          )
                                            ? rsetFile
                                            : isRsenv
                                        )
                                      ? zone.menInput
                                      : 1
                                  }
                                  onChange={(e) => {
                                    setValue(
                                      `building${buildingIndex}-zone${zoneIndex}-menInput`,
                                      e.target.value
                                    );
                                    dispatch(
                                      handleControlledZones(
                                        buildingIndex,
                                        zoneIndex,
                                        `menInput`,
                                        e.target.value
                                      )
                                    );
                                  }}
                                  variant="outlined"
                                  className={`input-boarding researchBar ${
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-menInput`
                                    ]
                                      ? 'error'
                                      : ''
                                  }`}
                                />
                              )}
                            />
                          </div>
                        )}

                        {/* combles */}
                        {checkConditionForDisplay('comblesInput', zone) && (
                          <div className="type-form-small">
                            <p>
                              {t('APP.ZONESFORM.INDEX.SURFACE_COMBLES', {
                                defaultValue: 'Surface des combles (m²) :',
                              })}
                            </p>{' '}
                            <Tooltip
                              title={
                                rsetFile
                                  ? t('APP.ZONESFORM.INDEX.PH_A9E50E', {
                                      defaultValue:
                                        'Votre projet a été créé à partir d’un RSET et il est donc impossible de modifier les paramètres relatifs aux zones du projet.',
                                    })
                                  : ''
                              }
                            >
                              <Controller
                                control={control}
                                name={`building${buildingIndex}-zone${zoneIndex}-comblesInput`}
                                defaultValue={isRsenv ? section.comblesInput : 0}
                                rules={{
                                  required: !isRsenv,
                                  pattern: /^[0-9]{1,20}([,.][0-9]{1,20})?$/,
                                }}
                                render={({ field }) => (
                                  <TextField
                                    style={{ width: '100px' }}
                                    {...field}
                                    disabled={isRsenv}
                                    defaultValue={isRsenv ? section.comblesInput : 0}
                                    key={isRsenv}
                                    value={isRsenv ? zone.comblesInput : null}
                                    onChange={(e) => {
                                      setValue(
                                        `building${buildingIndex}-zone${zoneIndex}-comblesInput`,
                                        e.target.value
                                      );
                                      dispatch(
                                        handleControlledZones(
                                          buildingIndex,
                                          zoneIndex,
                                          `comblesInput`,
                                          e.target.value
                                        )
                                      );
                                    }}
                                    variant="outlined"
                                    className={`input-boarding researchBar ${
                                      isRsenv ? 'background-disabled' : null
                                    } ${
                                      errors[
                                        `building${buildingIndex}-zone${zoneIndex}-comblesInput`
                                      ]
                                        ? 'error-border-form'
                                        : ''
                                    }`}
                                  />
                                )}
                              />
                            </Tooltip>
                          </div>
                        )}

                        {/* category */}
                        {checkConditionForDisplay('category', zone) && (
                          <div className="type-form-small">
                            <p>
                              {t('APP.ZONESFORM.INDEX.PH_5818C8', {
                                defaultValue: 'Catégories',
                              })}
                            </p>
                            <Controller
                              control={control}
                              name={`building${buildingIndex}-zone${zoneIndex}-category`}
                              rules={{ required: !rsetFile }}
                              defaultValue={rsetFile ? zone.category : 'CE1'}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  error={
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-category`
                                    ]
                                  }
                                  onChange={(e) => {
                                    setValue(
                                      `building${buildingIndex}-zone${zoneIndex}-category`,
                                      e.target.value
                                    );
                                    dispatch(
                                      handleControlledZones(
                                        buildingIndex,
                                        zoneIndex,
                                        `category`,
                                        e.target.value
                                      )
                                    );
                                  }}
                                  value={zone.category || 'CE1'}
                                  key={rsetFile}
                                  style={{ width: '100px' }}
                                  disabled={rsetFile ? true : false}
                                  variant="outlined"
                                  className={`input-boarding researchBar ${
                                    rsetFile ? 'background-disabled' : null
                                  } ${
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-category`
                                    ]
                                      ? 'error-border-form'
                                      : ''
                                  }`}
                                >
                                  <MenuItem key={'CE1'} value={'CE1'}>
                                    {t('APP.ZONESFORM.INDEX.PH_1E3933', {
                                      defaultValue: 'CE1',
                                    })}
                                  </MenuItem>
                                  <MenuItem key={'CE2'} value={'CE2'}>
                                    {t('APP.ZONESFORM.INDEX.PH_55B1AE', {
                                      defaultValue: 'CE2',
                                    })}
                                  </MenuItem>
                                </Select>
                              )}
                            />
                          </div>
                        )}
                      </div>

                      {/* zone secondRow */}
                      <div className="high-part-type-form">
                        {/* reference area */}
                        {checkConditionForDisplay('surface', zone) && (
                          <div className="type-form">
                            <p>
                              {t('APP.ZONESFORM.INDEX.PH_CA06EE', {
                                defaultValue: 'Surface de référence',
                              })}
                              <Tooltip
                                title={
                                  creationSteps.includes(
                                    t(
                                      CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.RE2020]
                                    )
                                  )
                                    ? t('APP.ZONESFORM.INDEX.PH_816B24', {
                                        defaultValue:
                                          'Pour le résidentiel: Surface Habitable (SHab). Pour le tertiaire: Surface utile (SU)',
                                      })
                                    : creationSteps.includes(
                                        t(
                                          CREATION_STEPS_TRAD_KEYS[
                                            CREATION_STEPS.EPCM
                                          ]
                                        )
                                      )
                                    ? t('APP.ZONESFORM.INDEX.PH_F449F8', {
                                        defaultValue:
                                          'Surface de référence: Surface de Plancher',
                                      })
                                    : t('APP.ZONESFORM.INDEX.PH_5BBB7C', {
                                        defaultValue:
                                          'Si RE2020: Pour le résidentiel: Surface Habitable (SHab). Pour le tertiaire: Surface utile (SU). Si E+C-: Surface de référence: Surface de Plancher',
                                      })
                                }
                              >
                                <InfoIcon style={{ height: '1.5rem' }} />
                              </Tooltip>
                            </p>
                            <Controller
                              control={control}
                              key={rsetFile || isRsenv}
                              defaultValue={Number(zone.area)}
                              name={`building${buildingIndex}-zone${zoneIndex}-area`}
                              rules={{
                                required: rsetFile || refUnit === 'm²',
                                pattern: /^[0-9]{1,20}([,.][0-9]{1,20})?$/,
                              }}
                              render={({ field }) => (
                                <TextField
                                  data-testid="creationForm-refSurface"
                                  {...field}
                                  disabled={
                                    creationSteps.includes(
                                      t(
                                        CREATION_STEPS_TRAD_KEYS[
                                          CREATION_STEPS.RE2020
                                        ]
                                      )
                                    )
                                      ? rsetFile
                                      : isRsenv
                                  }
                                  style={{ width: '90%' }}
                                  value={zone.area || null}
                                  error={
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-area`
                                    ]
                                  }
                                  onChange={(e) => {
                                    setValue(
                                      `building${buildingIndex}-zone${zoneIndex}-area`,
                                      e.target.value
                                    );
                                    dispatch(
                                      handleControlledZones(
                                        buildingIndex,
                                        zoneIndex,
                                        `area`,
                                        e.target.value
                                      )
                                    );
                                  }}
                                  placeholder={t('APP.ZONESFORM.INDEX.PH_7AB2B2', {
                                    defaultValue: 'Surface de référence',
                                  })}
                                  key={
                                    creationSteps.includes(
                                      t(
                                        CREATION_STEPS_TRAD_KEYS[
                                          CREATION_STEPS.RE2020
                                        ]
                                      )
                                    )
                                      ? rsetFile
                                      : isRsenv
                                  }
                                  variant="outlined"
                                  className={`input-boarding researchBar ${
                                    (
                                      creationSteps.includes(
                                        t(
                                          CREATION_STEPS_TRAD_KEYS[
                                            CREATION_STEPS.RE2020
                                          ]
                                        )
                                      )
                                        ? rsetFile
                                        : isRsenv
                                    )
                                      ? 'background-disabled'
                                      : ''
                                  }  ${
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-area`
                                    ]
                                  } ? 'error' : ''}`}
                                />
                              )}
                            />
                          </div>
                        )}

                        {/* logement area */}
                        {checkConditionForDisplay('logement', zone) && (
                          <div className="type-form-small">
                            <p>
                              {t('APP.ZONESFORM.INDEX.PH_E13D8B', {
                                defaultValue: 'Nombre de logements',
                              })}
                            </p>
                            <Controller
                              control={control}
                              name={`building${buildingIndex}-zone${zoneIndex}-logementInput`}
                              rules={{
                                required: !rsetFile,
                                pattern: /^[0-9]{1,20}([,.][0-9]{1,20})?$/,
                              }}
                              defaultValue={1}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  disabled={rsetFile ? true : false}
                                  defaultValue={rsetFile ? zone.logementInput : 1}
                                  onChange={(e) => {
                                    setValue(
                                      `building${buildingIndex}-zone${zoneIndex}-logementInput`,
                                      e.target.value
                                    );
                                    dispatch(
                                      handleControlledZones(
                                        buildingIndex,
                                        zoneIndex,
                                        `logementInput`,
                                        e.target.value
                                      )
                                    );
                                  }}
                                  value={rsetFile ? zone.logementInput : null}
                                  key={rsetFile}
                                  variant="outlined"
                                  className={`input-boarding researchBar ${
                                    rsetFile ? 'background-disabled' : null
                                  } ${
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-logementInput`
                                    ]
                                      ? 'error-border-form'
                                      : ''
                                  }`}
                                />
                              )}
                            />
                          </div>
                        )}
                        {selectedProjectType === TEMPLATE_TYPE_NAME.RE2020 &&
                          checkConditionForDisplay(
                            InputName.groundExpansion,
                            zone
                          ) && (
                            <div className="type-form-small">
                              <p>
                                {t('APP.ZONESFORM.INDEX.PH_D54054', {
                                  defaultValue: 'Emprise au sol (m2)',
                                })}
                              </p>
                              <Controller
                                control={control}
                                defaultValue={0}
                                name={`building${buildingIndex}-zone${zoneIndex}-groundExpansion`}
                                rules={{ validate: isValid }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    defaultValue={0}
                                    value={isRsenv ? zone.groundExpansion : null}
                                    error={
                                      errors[
                                        `building${buildingIndex}-zone${zoneIndex}-groundExpansion`
                                      ]
                                    }
                                    onChange={(e) => {
                                      setValue(
                                        `building${buildingIndex}-zone${zoneIndex}-groundExpansion`,
                                        e.target.value
                                      );
                                      dispatch(
                                        handleControlledZones(
                                          buildingIndex,
                                          zoneIndex,
                                          `groundExpansion`,
                                          e.target.value
                                        )
                                      );
                                    }}
                                    placeholder=""
                                    variant="outlined"
                                    className={`input-boarding researchBar ${
                                      errors[
                                        `building${buildingIndex}-zone${zoneIndex}-groundExpansion`
                                      ]
                                        ? 'error'
                                        : ''
                                    }`}
                                  />
                                )}
                              />
                            </div>
                          )}

                        {/* parking surface PLU */}
                        {checkConditionForDisplay('parking', zone) && (
                          <div className="type-form-small">
                            <p>
                              {t('APP.ZONESFORM.INDEX.PH_4E97A2', {
                                defaultValue:
                                  'Nombre de places de parking en surface (PLU)',
                              })}
                            </p>
                            <Controller
                              control={control}
                              name={`building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsSurfacePlu`}
                              rules={{
                                required: !isRsenv,
                                pattern: /^[0-9]{1,20}([,.][0-9]{1,20})?$/,
                              }}
                              defaultValue={0}
                              name={`building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsSurfacePlu`}
                              rules={{ validate: isValid }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  value={
                                    isRsenv ? zone.nbParkingSpotsSurfacePlu : null
                                  }
                                  defaultValue={0}
                                  error={
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsSurfacePlu`
                                    ]
                                  }
                                  onChange={(e) => {
                                    setValue(
                                      `building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsSurfacePlu`,
                                      e.target.value
                                    );
                                    dispatch(
                                      handleControlledZones(
                                        buildingIndex,
                                        zoneIndex,
                                        `nbParkingSpotsSurfacePlu`,
                                        e.target.value
                                      )
                                    );
                                  }}
                                  disabled={isRsenv}
                                  style={{ width: '100px' }}
                                  key={isRsenv}
                                  variant="outlined"
                                  className={`input-boarding researchBar ${
                                    isRsenv ? 'background-disabled' : null
                                  } ${
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsSurfacePlu`
                                    ]
                                      ? 'error-border-form'
                                      : ''
                                  }`}
                                />
                              )}
                            />
                          </div>
                        )}
                      </div>

                      {/* zone thirdRow */}
                      <div className="high-part-type-form">
                        {/* emprise au sol area for EPCM project (grid need) */}
                        {selectedProjectType === TEMPLATE_TYPE_NAME.EPCM &&
                          checkConditionForDisplay(
                            InputName.groundExpansion,
                            zone
                          ) && (
                            <div className="type-form">
                              <p>
                                {t('APP.ZONESFORM.INDEX.PH_D54054', {
                                  defaultValue: 'Emprise au sol (m2)',
                                })}
                              </p>
                              <Controller
                                control={control}
                                defaultValue={0}
                                name={`building${buildingIndex}-zone${zoneIndex}-groundExpansion`}
                                rules={{ validate: isValid }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    defaultValue={0}
                                    style={{ width: '90%' }}
                                    value={isRsenv ? zone.groundExpansion : null}
                                    error={
                                      errors[
                                        `building${buildingIndex}-zone${zoneIndex}-groundExpansion`
                                      ]
                                    }
                                    onChange={(e) => {
                                      setValue(
                                        `building${buildingIndex}-zone${zoneIndex}-groundExpansion`,
                                        e.target.value
                                      );
                                      dispatch(
                                        handleControlledZones(
                                          buildingIndex,
                                          zoneIndex,
                                          `groundExpansion`,
                                          e.target.value
                                        )
                                      );
                                    }}
                                    placeholder=""
                                    variant="outlined"
                                    className={`input-boarding researchBar ${
                                      errors[
                                        `building${buildingIndex}-zone${zoneIndex}-groundExpansion`
                                      ]
                                        ? 'error'
                                        : ''
                                    }`}
                                  />
                                )}
                              />
                            </div>
                          )}

                        {/* thermique area */}
                        {checkConditionForDisplay('mgcSurf', zone) && (
                          <div className="type-form-small">
                            <p>
                              {t('APP.ZONESFORM.INDEX.PH_DE2AAA', {
                                defaultValue:
                                  'Surface thermique au sens de la RT(Srt)',
                              })}
                            </p>
                            <Controller
                              control={control}
                              key={rsetFile || isRsenv}
                              defaultValue={zone.srt}
                              name={`building${buildingIndex}-zone${zoneIndex}-srt`}
                              rules={{
                                required: rsetFile ? false : true,
                                pattern: /^[0-9]{1,20}([,.][0-9]{1,20})?$/,
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  disabled={
                                    creationSteps.includes(
                                      t(
                                        CREATION_STEPS_TRAD_KEYS[
                                          CREATION_STEPS.RE2020
                                        ]
                                      )
                                    )
                                      ? rsetFile
                                      : isRsenv
                                  }
                                  style={{ width: '90%' }}
                                  value={zone.srt}
                                  error={
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-srt`
                                    ]
                                  }
                                  onChange={(e) => {
                                    setValue(
                                      `building${buildingIndex}-zone${zoneIndex}-srt`,
                                      e.target.value
                                    );
                                    dispatch(
                                      handleControlledZones(
                                        buildingIndex,
                                        zoneIndex,
                                        `srt`,
                                        e.target.value
                                      )
                                    );
                                  }}
                                  key={
                                    creationSteps.includes(
                                      t(
                                        CREATION_STEPS_TRAD_KEYS[
                                          CREATION_STEPS.RE2020
                                        ]
                                      )
                                    )
                                      ? rsetFile
                                      : isRsenv
                                  }
                                  variant="outlined"
                                  className={`input-boarding researchBar ${
                                    (
                                      creationSteps.includes(
                                        t(
                                          CREATION_STEPS_TRAD_KEYS[
                                            CREATION_STEPS.RE2020
                                          ]
                                        )
                                      )
                                        ? rsetFile
                                        : isRsenv
                                    )
                                      ? 'background-disabled'
                                      : null
                                  } ${
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-srt`
                                    ]
                                      ? 'error'
                                      : ''
                                  }`}
                                />
                              )}
                            />
                          </div>
                        )}

                        {/* parking surface exluding PLU */}
                        {checkConditionForDisplay('parking', zone) && (
                          <div className="type-form-small">
                            <p>
                              {t('APP.ZONESFORM.INDEX.PH_3BDD09', {
                                defaultValue:
                                  'Nombre de places de parking en surface (hors PLU)',
                              })}
                            </p>
                            <Controller
                              control={control}
                              name={`building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsSurface`}
                              rules={{
                                required: !isRsenv,
                                pattern: /^[0-9]{1,20}([,.][0-9]{1,20})?$/,
                              }}
                              defaultValue={0}
                              rules={{ validate: isValid }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  value={isRsenv ? zone.nbParkingSpotsSurface : null}
                                  defaultValue={0}
                                  error={
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsSurface`
                                    ]
                                  }
                                  onChange={(e) => {
                                    setValue(
                                      `building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsSurface`,
                                      e.target.value
                                    );
                                    dispatch(
                                      handleControlledZones(
                                        buildingIndex,
                                        zoneIndex,
                                        `nbParkingSpotsSurface`,
                                        e.target.value
                                      )
                                    );
                                  }}
                                  style={{ width: '100px' }}
                                  variant="outlined"
                                  className={`input-boarding researchBar ${
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsSurface`
                                    ]
                                      ? 'error'
                                      : ''
                                  }`}
                                />
                              )}
                            />
                          </div>
                        )}
                      </div>

                      {/* zone fourthRow */}
                      <div className="high-part-type-form">
                        {/* placesNumberUndergroundExcludingPLU */}
                        {checkConditionForDisplay(
                          'nbParkingSpotsUndergroundPlu',
                          zone
                        ) && (
                          <div className="type-form">
                            <p>
                              {t('APP.ZONESFORM.INDEX.PH_1F50BA', {
                                defaultValue:
                                  'Nombre de places de parking en sous-sol(hors PLU)',
                              })}
                            </p>
                            <Controller
                              control={control}
                              defaultValue={'0'}
                              name={`building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsUndergroundPlu`}
                              rules={{
                                required: true,
                                pattern: /^(?:\d+([,.]\d*)?|[,.]\d+)$/,
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  style={{ width: '90%' }}
                                  defaultValue={0}
                                  value={
                                    isRsenv ? zone.nbParkingSpotsUnderground : null
                                  }
                                  error={
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsUnderground`
                                    ]
                                  }
                                  onChange={(e) => {
                                    setValue(
                                      `building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsUnderground`,
                                      e.target.value
                                    );

                                    dispatch(
                                      handleControlledZones(
                                        buildingIndex,
                                        zoneIndex,
                                        `nbParkingSpotsUnderground`,
                                        e.target.value
                                      )
                                    );
                                  }}
                                  placeholder=""
                                  variant="outlined"
                                  className={`input-boarding researchBar ${
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsUnderground`
                                    ]
                                      ? 'error'
                                      : ''
                                  }`}
                                />
                              )}
                            />
                          </div>
                        )}

                        {/* placesNumberUnderground PLU */}
                        {checkConditionForDisplay(
                          'nbParkingSpotsUndergroundPlu',
                          zone
                        ) && (
                          <div className="type-form">
                            <p>
                              {t('APP.ZONESFORM.INDEX.PARKING_PLACE_UNDERGROUND', {
                                defaultValue:
                                  'Nombre de place de parking en sous-sol(PLU)',
                              })}
                            </p>{' '}
                            <Controller
                              control={control}
                              name={`building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsUndergroundPlu`}
                              defaultValue={0}
                              rules={{ validate: isValid }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  style={{ width: '90%' }}
                                  defaultValue={0}
                                  value={
                                    isRsenv
                                      ? zone.nbParkingSpotsUndergroundPlu
                                      : null
                                  }
                                  error={
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsUndergroundPlu`
                                    ]
                                  }
                                  onChange={(e) => {
                                    setValue(
                                      `building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsUndergroundPlu`,
                                      e.target.value
                                    );
                                    dispatch(
                                      handleControlledZones(
                                        buildingIndex,
                                        zoneIndex,
                                        `nbParkingSpotsUndergroundPlu`,
                                        e.target.value
                                      )
                                    );
                                  }}
                                  placeholder=""
                                  variant="outlined"
                                  className={`input-boarding researchBar ${
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-nbParkingSpotsUndergroundPlu`
                                    ]
                                      ? 'error'
                                      : ''
                                  }`}
                                />
                              )}
                            />
                          </div>
                        )}
                      </div>

                      {/* zone fourthBisBisRow */}

                      {/* zone fourthBisRow */}
                      <div className="high-part-type-form">
                        {/* isTAC first params */}
                        {zone.isTAC != false && (
                          <div className="type-form">
                            <LabelWithTooltip
                              label={'Production annuelle : Eef_Prod(kWh/m2/an)'}
                              tooltip={
                                'Production électrique totale des installations. Taux d’autoconsommation (TAC) = Autoconsommation / Production'
                              }
                            />
                            <Controller
                              control={control}
                              name={`building${buildingIndex}-zone${zoneIndex}-OEefProdPvAnnuel`}
                              rules={{
                                required: true,
                                min: 1,
                              }}
                              defaultValue={1}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  onChange={(e) => {
                                    setValue(
                                      `building${buildingIndex}-zone${zoneIndex}-OEefProdPvAnnuel`,
                                      e.target.value
                                    );
                                    dispatch(
                                      handleControlledZones(
                                        buildingIndex,
                                        zoneIndex,
                                        `OEefProdPvAnnuel`,
                                        e.target.value
                                      )
                                    );
                                  }}
                                  value={zone.OEefProdPvAnnuel}
                                  defaultValue={rsetFile ? zone.OEefProdPvAnnuel : 1}
                                  disabled={rsetFile || !zone.isTAC ? true : false}
                                  placeholder=""
                                  variant="outlined"
                                  className={`input-boarding researchBar ${
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-OEefProdPvAnnuel`
                                    ]
                                      ? 'error'
                                      : ''
                                  }`}
                                />
                              )}
                            />
                          </div>
                        )}
                        {/* isTAC second params */}
                        {zone.isTAC != false && (
                          <div className="type-form">
                            <LabelWithTooltip
                              label={
                                'Autoconsommation annuelle : Eef_Prod_PV_AC(kWh/m2/an)'
                              }
                              tooltip={
                                "Part autoconsommée de l'électricité produite par les installations. Taux d’autoconsommation annuel (TAC) = Autoconsommation / Production"
                              }
                            />
                            <Controller
                              control={control}
                              name={`building${buildingIndex}-zone${zoneIndex}-OEefProdPvAcAnnuel`}
                              defaultValue={1}
                              rules={{
                                required: true,
                                min: 1,
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  onChange={(e) => {
                                    setValue(
                                      `building${buildingIndex}-zone${zoneIndex}-OEefProdPvAcAnnuel`,
                                      e.target.value
                                    );
                                    dispatch(
                                      handleControlledZones(
                                        buildingIndex,
                                        zoneIndex,
                                        `OEefProdPvAcAnnuel`,
                                        e.target.value
                                      )
                                    );
                                  }}
                                  value={zone.OEefProdPvAcAnnuel}
                                  defaultValue={
                                    rsetFile ? zone.OEefProdPvAcAnnuel : 1
                                  }
                                  disabled={rsetFile || !zone.isTAC ? true : false}
                                  variant="outlined"
                                  className={`input-boarding researchBar`}
                                />
                              )}
                            />
                          </div>
                        )}
                        {/* )} */}
                      </div>

                      {/* zone fifthRow */}
                      <div
                        style={{
                          marginRight: '3em',
                        }}
                        className="high-part-type-form"
                      >
                        {/* urbanNetwork checkbox */}
                        {checkConditionForDisplay('urbanNetwork', zone) && (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: checkConditionForDisplay('mgcSurf', zone)
                                ? '40%'
                                : '65%',
                            }}
                          >
                            <Controller
                              control={control}
                              name={`building${buildingIndex}-zone${zoneIndex}-IsConnectedToUrbanNetwork`}
                              defaultValue={zone.IsConnectedToUrbanNetwork}
                              render={({ field }) => (
                                <NoocoCheckbox
                                  {...field}
                                  disabled={rsetFile ? true : false}
                                  checked={zone.IsConnectedToUrbanNetwork}
                                  key={zone.IsConnectedToUrbanNetwork}
                                  onChange={(e: { target: { checked: any } }) => {
                                    setValue(
                                      `building${buildingIndex}-zone${zoneIndex}-IsConnectedToUrbanNetwork`,
                                      e.target.checked
                                    );
                                    dispatch(
                                      handleControlledZones(
                                        buildingIndex,
                                        zoneIndex,
                                        `IsConnectedToUrbanNetwork`,
                                        e.target.checked
                                      )
                                    );
                                  }}
                                />
                              )}
                            />
                            <p
                              style={{
                                margin: 0,
                                cursor: rsetFile ? 'not-allowed' : undefined,
                              }}
                            >
                              {t('APP.ZONESFORM.INDEX.PH_2E059F', {
                                defaultValue:
                                  'Raccordé à un réseau de chaleur urbain',
                              })}
                            </p>
                          </div>
                        )}

                        {/* isTAC checkbox */}
                        {checkConditionForDisplay('isTAC', zone) && (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: checkConditionForDisplay('mgcSurf', zone)
                                ? '40%'
                                : '65%',
                            }}
                          >
                            <Controller
                              control={control}
                              name={`building${buildingIndex}-zone${zoneIndex}-isTAC`}
                              render={({ field }) => (
                                <NoocoCheckbox
                                  {...field}
                                  checked={
                                    (rsetFile &&
                                      zone.OEefProdPvAcAnnuel != 0 &&
                                      zone.OEefProdPvAnnuel != 0) ||
                                    zone.isTAC === true
                                  }
                                  key={zone.isTAC}
                                  onChange={(e: { target: { checked: any } }) => {
                                    const isTAC = e.target.checked;
                                    setValue(
                                      `building${buildingIndex}-zone${zoneIndex}-isTAC`,
                                      isTAC
                                    );
                                    dispatch(
                                      handleControlledZones(
                                        buildingIndex,
                                        zoneIndex,
                                        `isTAC`,
                                        isTAC
                                      )
                                    );

                                    if (isTAC && section.Zones.length > 1) {
                                      alert(
                                        'Attention, les TAC saisis sur les zones d’un même bâtiment ne sont pas les mêmes'
                                      );
                                    }

                                    if (!isTAC) {
                                      setValue(
                                        `building${buildingIndex}-zone${zoneIndex}-OEefProdPvAnnuel`,
                                        1
                                      );
                                      dispatch(
                                        handleControlledZones(
                                          buildingIndex,
                                          zoneIndex,
                                          `OEefProdPvAnnuel`,
                                          1
                                        )
                                      );

                                      setValue(
                                        `building${buildingIndex}-zone${zoneIndex}-OEefProdPvAcAnnuel`,
                                        1
                                      );
                                      dispatch(
                                        handleControlledZones(
                                          buildingIndex,
                                          zoneIndex,
                                          `OEefProdPvAcAnnuel`,
                                          1
                                        )
                                      );
                                    }
                                  }}
                                  disabled={rsetFile ? true : false}
                                />
                              )}
                            />
                            <p
                              style={{
                                margin: 0,
                                cursor: rsetFile ? 'not-allowed' : undefined,
                              }}
                            >
                              {t('APP.ZONESFORM.INDEX.PHOTOVOLTAIC_PANELS', {
                                defaultValue: 'Présence de panneaux photovoltaïque',
                              })}{' '}
                            </p>
                          </div>
                        )}
                        {/* isSharedArea checkbox */}
                        {checkConditionForDisplay('isSharedArea', zone) && (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Controller
                              control={control}
                              name={`building${buildingIndex}-zone${zoneIndex}-isSharedArea`}
                              render={({ field }) => (
                                <NoocoCheckbox
                                  {...field}
                                  // disabled={rsetFile ? true : false}
                                  checked={zone?.isSharedArea}
                                  key={zone?.isSharedArea}
                                  defaultChecked={false}
                                  onChange={(e: { target: { checked: any } }) => {
                                    setValue(
                                      `building${buildingIndex}-zone${zoneIndex}-isSharedArea`,
                                      e.target.checked
                                    );
                                    dispatch(
                                      handleControlledZones(
                                        buildingIndex,
                                        zoneIndex,
                                        `isSharedArea`,
                                        e.target.checked
                                      )
                                    );
                                  }}
                                />
                              )}
                            />
                            <p style={{ margin: 0 }}>
                              {t('APP.ZONESFORM.INDEX.COMMON_ZONE', {
                                defaultValue: 'Zone commune',
                              })}
                            </p>{' '}
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '8px',
                              }}
                            >
                              <Tooltip
                                title={t('APP.ZONESFORM.TOOLTIP.ZONE_COMMUNES', {
                                  defaultValue:
                                    'Cocher si la zone correspond aux parties communes',
                                })}
                              >
                                <InfoIcon style={{ height: '1.5rem' }} />
                              </Tooltip>
                            </div>
                          </div>
                        )}
                      </div>

                      {/* zone fifthRow */}
                      <div className="high-part-type-form">
                        {/* renovation typologie */}
                        {creationSteps.includes(
                          t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.BBCA], {
                            defaultValue: 'BBCA Rénovation',
                          })
                        ) && (
                          <div className="type-form">
                            <p>
                              <p>
                                {t('APP.ZONESFORM.INDEX.TYPOLOGIE_RENOVATION', {
                                  defaultValue: 'Typologie de rénovation',
                                })}
                              </p>
                              <Tooltip
                                title={
                                  'Rénovation lourde = Opération immobilière impliquant l’arrêt total de l’activité de l’immeuble pendant l’exécution des travaux. Rénovation thermique = Opération impliquant un changement sur les produits de construction liés à l’enveloppe (lot 4 et/ou 6) et un changement sur les équipement influençant la performance thermique.'
                                }
                              >
                                <InfoIcon style={{ height: '1.5rem' }} />
                              </Tooltip>
                            </p>
                            <Controller
                              control={control}
                              name={`building${buildingIndex}-zone${zoneIndex}-RenovationTypeId`}
                              defaultValue={rsetFile ? zone?.RenovationTypeId : null}
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  data-testid="zoneTypology-dropdown"
                                  error={
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-RenovationTypeId`
                                    ]
                                  }
                                  onChange={(e) => {
                                    setValue(
                                      `building${buildingIndex}-zone${zoneIndex}-RenovationTypeId`,
                                      e.target.value
                                    );
                                    dispatch(
                                      handleControlledZones(
                                        buildingIndex,
                                        zoneIndex,
                                        `RenovationTypeId`,
                                        e.target.value
                                      )
                                    );
                                  }}
                                  value={zone?.RenovationTypeId || null}
                                  key={rsetFile}
                                  style={{ width: '230px' }}
                                  variant="outlined"
                                  className={`input-boarding researchBar ${
                                    rsetFile ? 'background-disabled' : null
                                  } ${
                                    errors[
                                      `building${buildingIndex}-zone${zoneIndex}-RenovationTypeId`
                                    ]
                                      ? 'error-border-form'
                                      : ''
                                  }`}
                                >
                                  {allGroups.renovationTypes.map(
                                    (renovationType) => {
                                      return (
                                        <MenuItem
                                          key={renovationType.id}
                                          value={renovationType.id}
                                        >
                                          {renovationType.name}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                  {/* <MenuItem
                                    key={'heavyRenovation'}
                                    value={'heavyRenovation'}
                                  >
                                    {'Rénovation lourde'}
                                  </MenuItem>
                                  <MenuItem
                                    key={'energyRenovation'}
                                    value={'energyRenovation'}
                                  >
                                    {'Rénovation énergétique'}
                                  </MenuItem> */}
                                </Select>
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {!rsetFile &&
                      !creationSteps.includes(
                        t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.EXPLOITATION])
                      ) && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            margin: '1em',
                          }}
                        >
                          {zoneIndex === section.Zones.length - 1 && (
                            <Button
                              onClick={() =>
                                dispatch(addZonesSections(buildingIndex, null, t))
                              }
                              className="save-button"
                              additionalIcon={<AddIcon />}
                              text={t('APP.ZONESFORM.INDEX.PH_7DE2F2', {
                                defaultValue: 'Ajouter une zone',
                              })}
                              style={{ margin: '0 0.5em' }}
                            />
                          )}
                          {zoneIndex === section.Zones.length - 1 &&
                            zoneIndex > 0 && (
                              <Button
                                onClick={() =>
                                  dispatch(addZonesSections(buildingIndex, 'remove'))
                                }
                                className="delete-button"
                                text={t('APP.ZONESFORM.INDEX.PH_34C636', {
                                  defaultValue: 'Supprimer une zone',
                                })}
                                style={{ margin: '0 0.5em' }}
                              />
                            )}
                        </div>
                      )}
                  </>
                ))}
              </div>
            </div>
            {/* add and delete buttons */}
            {!creationSteps.includes(
              t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.EXPLOITATION])
            ) &&
              !rsetFile && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '1em',
                  }}
                >
                  {buildingIndex === buildingSections.length - 1 && (
                    <Button
                      onClick={() =>
                        dispatch(managementBuildingSections(buildingIndex, null, t))
                      }
                      className="save-button"
                      text={t('APP.ZONESFORM.INDEX.PH_AF0F4F', {
                        defaultValue: 'Ajouter un bâtiment',
                      })}
                      style={{ margin: '0 0.5em' }}
                    />
                  )}

                  {buildingIndex === buildingSections.length - 1 &&
                    buildingIndex > 0 && (
                      <Button
                        onClick={() =>
                          dispatch(
                            managementBuildingSections(buildingIndex, 'remove')
                          )
                        }
                        className="delete-button"
                        variant="success"
                        text={t('APP.ZONESFORM.INDEX.PH_0F8FC4', {
                          defaultValue: 'Supprimer un bâtiment',
                        })}
                        style={{ margin: '0 0.5em' }}
                      />
                    )}
                </div>
              )}
          </>
        );
      })}
    </div>
  );
};

export default ZonesForm;
