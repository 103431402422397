import { Button, DatePicker } from '@photocarbone/nooco-ui';
import { NewActionDTO } from '../../../../models/ExploitationDTO';
import { StateDTO } from '../../../../models/GeneralDTO';
import { useSelector } from 'react-redux';
import AppContext from '../../../../AppContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import EquipmentChoice from '../../../../../containers/EquipmentChoice';
import React, { useContext, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import locale from 'antd/es/date-picker/locale/fr_FR';
import renouvellement from '../../../../../assets/images/exploitation/renouvellement.png';

import Tooltip from '@material-ui/core/Tooltip';

import './renewal.scss';
import { checkCaracsValues } from './EquipmentChoice';
import API from '../../../../services/API';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getAntdLocale } from '../../../../shared/getAntdLocale';

const dateFormat = 'L';

interface RenewalState {
  newAction: NewActionDTO;
  updateExploitationValue: Function;
  createAction: Function;
  resetAddAction: Function;
  loadInfosSheet: boolean;
  boardingListDatas: {}[];
}

const api = new API();

const Renewal = ({
  newAction,
  updateExploitationValue,
  createAction,
  resetAddAction,
  boardingListDatas,
  loadInfosSheet,
  allGroups,
}: RenewalState) => {
  const ctx = useContext(AppContext);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { currentProject } = useSelector((state: StateDTO) => state.projects);
  const { sheets } = useSelector((state: StateDTO) => state.exploitation);
  const { t } = useTranslation();

  const checkValidationForm = () => {
    if (!newAction.equipment || !newAction.renewedQuantity || !newAction.date) {
      return true;
    }
  };

  const fetchOptions = (searchValue) => {
    setLoading(true);

    ctx.api
      .getSearchShortData(currentProject.id, 'search', searchValue)
      .then((res) => {
        setOptions(res.data);
        setLoading(false);
      });
  };

  return (
    <>
      {newAction.renewalEquipment ? (
        <EquipmentChoice />
      ) : (
        <div className="renewal-form">
          <div className="header-actions">
            <img src={renouvellement} alt="" />
            <h4>
              {t('APP.RENEWAL.INDEX.PH_A737E2', {
                defaultValue: "Choix de l'équipement/matériau à renouveler",
              })}
            </h4>
          </div>
          <div className="renewal-core">
            <div className="renewal-equipment">
              <p>
                {t('APP.RENEWAL.INDEX.PH_E230D3', {
                  defaultValue: 'Rechercher un équipement',
                })}
              </p>
              <Autocomplete
                // value={newEquipment.MasterDataEquipment}
                id="checkboxes-tags-demo"
                options={options}
                loading={loading}
                noOptionsText={t('APP.RENEWAL.INDEX.PH_5AB8C1', {
                  defaultValue: 'Aucun résultat trouvé',
                })}
                onOpen={() => {
                  fetchOptions('');
                }}
                onChange={(event, newValue) => {
                  //   if (newValue)
                  updateExploitationValue('newAction', {
                    ...newAction,
                    equipment: newValue,
                    defaultQuantity: newValue?.quantity,
                  });
                }}
                size={'small'}
                renderOption={(option) => {
                  let sourceName = option.customName || option.originalName;

                  return (
                    <p style={{ margin: 0 }}>
                      {option.Sheet?.name
                        ? `${option.quantity} ${option.Unit?.name}${
                            option.quantity > 1 && option.Unit?.name === 'unité'
                              ? 's'
                              : ''
                          } - ${option.Sheet?.name} `
                        : ''}
                      {sourceName ? (
                        <span
                          style={{
                            fontStyle: 'italic',
                            color: '#645ad1',
                            marginLeft: '10px',
                            fontWeight: 'bold',
                          }}
                        >
                          ({sourceName})
                        </span>
                      ) : null}
                    </p>
                  );
                }}
                getOptionLabel={(option) =>
                  option.Sheet?.name
                    ? `${option.quantity} ${option.Unit?.name}${
                        option.quantity > 1 && option.Unit?.name === 'unité'
                          ? 's'
                          : ''
                      } - ${option.Sheet?.name}`
                    : ''
                }
                renderInput={(params) => (
                  <TextField
                    onChange={(e) => fetchOptions(e.target.value)}
                    style={{ borderRadius: '10px' }}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    placeholder={t('APP.RENEWAL.INDEX.PH_C554A1', {
                      defaultValue: 'Rechercher un équipement',
                    })}
                    variant="outlined"
                    className="researchBar"
                  />
                )}
              />
            </div>
            <div className="date-picker-renewal">
              <p>
                {t('APP.RENEWAL.INDEX.PH_908254', {
                  defaultValue: "Date de l'action",
                })}
              </p>

              <DatePicker
                disabled={!newAction.equipment ? true : false}
                disabledDate={(d) => {
                  return newAction.equipment.lifespan &&
                    newAction.equipment.lifespan[0].value
                    ? d.isBefore(newAction.equipment.lifespan[0].value) ||
                        d.isAfter(currentProject.exploitContract[1].value)
                    : d.isBefore(currentProject.exploitContract[0].value) ||
                        d.isAfter(currentProject.exploitContract[1].value);
                }}
                className={`permis-form`}
                format={dayjs.localeData().longDateFormat('L')}
                locale={getAntdLocale()}
                onChange={(date) => {
                  updateExploitationValue('newAction', {
                    ...newAction,
                    date: date === '' ? null : dayjs(date, dateFormat).format(),
                  });
                }}
              />
            </div>
            <div className="renewal-quantity-comment">
              <div>
                <p>
                  {t('APP.RENEWAL.INDEX.PH_584A32', {
                    defaultValue: 'Quantité remplacée',
                  })}
                </p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    value={newAction?.renewedQuantity}
                    onChange={(e) =>
                      updateExploitationValue('newAction', {
                        ...newAction,
                        renewedQuantity: e.target.value,
                      })
                    }
                    type="number"
                    style={{ width: '50%' }}
                    placeholder={t('APP.RENEWAL.INDEX.PH_CD4903', {
                      defaultValue: 'Quantité',
                    })}
                    variant="outlined"
                    className="input-boarding researchBar"
                  />

                  {newAction.equipment && (
                    <p style={{ margin: 0 }}>
                      /
                      {`${newAction?.defaultQuantity} ${
                        newAction.equipment.Unit.name
                      }${
                        newAction?.defaultQuantity > 1 &&
                        newAction.equipment.Unit.name === 'unité'
                          ? 's'
                          : ''
                      }`}
                    </p>
                  )}
                </div>
              </div>
              <div style={{ width: '50%' }}>
                <p style={{ color: 'rgba(102, 102, 102, 0.53)' }}>
                  {t('APP.RENEWAL.INDEX.PH_AC0775', {
                    defaultValue: 'Commentaire (facultatif)',
                  })}
                </p>
                <Tooltip
                  title={t('APP.RENEWAL.INDEX.PH_E20990', {
                    defaultValue: 'Ajouter un commentaire',
                  })}
                  placement="top"
                >
                  <TextField
                    onChange={(e) =>
                      updateExploitationValue('newAction', {
                        ...newAction,
                        comment: e.target.value,
                      })
                    }
                    type="text"
                    placeholder={t('APP.RENEWAL.INDEX.PH_5C194C', {
                      defaultValue: 'Commentaire',
                    })}
                    style={{ width: '100%' }}
                    variant="outlined"
                    className="input-boarding researchBar"
                  />
                </Tooltip>
              </div>
            </div>

            <div className="renewal-choice">
              <div
                onClick={
                  !checkValidationForm()
                    ? () => {
                        updateExploitationValue('newAction', {
                          ...newAction,
                          equipment: {
                            ...newAction.equipment,
                            AllomentNodes: [newAction.equipment.AllotmentNode],
                          },
                        });
                        createAction(t);
                      }
                    : null
                }
                className="choice"
                style={
                  checkValidationForm()
                    ? {
                        cursor: 'not-allowed',
                        background: 'white',
                        color: '#666666',
                      }
                    : undefined
                }
              >
                <p style={checkValidationForm() ? { color: '#666666' } : undefined}>
                  {t('APP.RENEWAL.INDEX.PH_B4FE36', {
                    defaultValue: 'Renouveler l’équipement par lui-même',
                  })}
                </p>
              </div>
              <div
                className="choice"
                onClick={() =>
                  !checkValidationForm()
                    ? updateExploitationValue('newAction', {
                        ...newAction,
                        renewalEquipment: {
                          AllotmentNodeId: newAction.equipment.AllotmentNode.id,
                          AllotmentNodes: [newAction.equipment.AllotmentNode],
                        },
                      })
                    : null
                }
                style={
                  checkValidationForm()
                    ? {
                        cursor: 'not-allowed',
                        background: 'white',
                        color: '#666666',
                      }
                    : undefined
                }
              >
                <p style={checkValidationForm() ? { color: '#666666' } : undefined}>
                  {t('APP.RENEWAL.INDEX.PH_F9CED5', {
                    defaultValue: 'Choisir un nouvel équipement',
                  })}
                </p>
              </div>
              <div
                onClick={() =>
                  !checkValidationForm() ? createAction(t, null, true) : null
                }
                className="choice"
                style={
                  checkValidationForm()
                    ? {
                        cursor: 'not-allowed',
                        background: 'white',
                        color: '#666666',
                      }
                    : undefined
                }
              >
                <p style={checkValidationForm() ? { color: '#666666' } : undefined}>
                  {t('APP.RENEWAL.INDEX.PH_FF66DF', {
                    defaultValue: 'Renouveler par un équipement issu du réemploi',
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          marginLeft: 'auto',
          width: newAction.renewalEquipment ? '35%' : '9%',
        }}
      >
        <Button
          style={{
            fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
            marginRight: '10px',
          }}
          onClick={() => resetAddAction()}
          className="close-button"
          text={t('APP.RENEWAL.INDEX.PH_8C05AE', { defaultValue: 'Fermer' })}
        />

        {newAction.renewalEquipment && (
          <>
            <Tooltip
              placement="top"
              title={t('APP.RENEWAL.INDEX.PH_94F5C2', {
                defaultValue:
                  "Remplacer l'équipement par un produit générique avec les caractéristiques renseignées",
              })}
            >
              <div>
                <Button
                  disabled={!newAction.date || checkCaracsValues(newAction)}
                  style={{
                    fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    updateExploitationValue('newAction', {
                      ...newAction,
                      equipment: {
                        ...newAction.equipment,
                        AllomentNodes: [newAction.equipment.AllotmentNode],
                      },
                      renewalEquipment: newAction.renewalEquipment.Caracs
                        ? {
                            ...newAction.renewalEquipment,
                            type: newAction.renewalEquipment?.SheetId?.type,
                            SheetId: null,
                            Sheet: null,
                            caracs: newAction.renewalEquipment.Caracs
                              ? newAction.renewalEquipment.Caracs
                              : sheets
                                  ?.find(
                                    (sheet) =>
                                      sheet.ProjectSheet &&
                                      sheet.ProjectSheet.id ===
                                        newAction.equipment.id
                                  )
                                  ?.Caracs.map((el) => ({
                                    ...el,
                                    value: el.SheetCarac.value[0],
                                  })),
                            Caracs: null,
                          }
                        : null,
                    });
                    createAction(t);
                  }}
                  text={t('APP.RENEWAL.INDEX.PH_5EA3AE', {
                    defaultValue: 'Continuer avec produit par défaut',
                  })}
                  className="purple-button"
                />
              </div>
            </Tooltip>
            <Tooltip
              placement="top"
              title={t('APP.RENEWAL.INDEX.PH_1D5BEE', {
                defaultValue:
                  "Remplacer l'équipement par le produit spécifique sélectionné sur le graphe",
              })}
            >
              <div>
                <Button
                  style={{
                    fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    updateExploitationValue('newAction', {
                      ...newAction,
                      equipment: {
                        ...newAction.equipment,
                        AllomentNodes: [newAction.equipment.AllotmentNode],
                      },
                      renewalEquipment: {
                        ...newAction.renewalEquipment,
                        type: newAction.renewalEquipment?.SheetId?.type,
                        SheetId: newAction.renewalEquipment?.SheetId.SheetId,
                        Caracs: null,
                        caracs: null,
                      },
                    });
                    createAction(t);
                  }}
                  disabled={!newAction.date || !newAction.renewalEquipment.SheetId}
                  text={t('APP.RENEWAL.INDEX.PH_5C3870', {
                    defaultValue: 'Continuer avec la sélection',
                  })}
                  className="purple-button"
                />
              </div>
            </Tooltip>
          </>
        )}
      </div>
    </>
  );
};

export default Renewal;
