/* eslint-disable no-undef */
import { IconButton, Popover } from '@material-ui/core';
import { IndicatorDTO, ProjectDTO } from '../../../../models/ProjectDTO';
import { ProjectIcDTO, zonesDTO } from './WidgetsDTO';
import { CurrentAllotmentDTO } from '../../../models/ProjectsDTO';
import { TEMPLATE_TYPE_NAME } from '../../../globalVariable/typeProject';
import { shouldDisplayRoundStyleThresholds } from '../../../shared/utilsFunction';
import { formatNumberP } from './ConstructWidgets';
import CustomThreshold from '../../../shared/CustomThreshold';
import InfoIcon from '@material-ui/icons/Info';
import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import Info from '@material-ui/icons/Info';
import { IcMaxBbcaTooltip } from './widgetElements/WidgetsElements';

type EnergyWidgetsProps = {
  projectIc: ProjectIcDTO;
  zones: zonesDTO[];
  currentIndicator: IndicatorDTO;
  currentAllotment: CurrentAllotmentDTO;
  openedPopooverId: string;
  seuilsOk: boolean;
  handlePopoverOpen: Function;
  handlePopoverClose: Function;
  renderPopoverBody: Function;
  currentProject: ProjectDTO;
};

const energy = require('../../../../assets/images/synthese/Image5.png');

const EnergyWidgets = ({
  renderPopoverBody,
  currentIndicator,
  projectIc,
  openedPopooverId,
  seuilsOk,
  handlePopoverOpen,
  handlePopoverClose,
  currentProject,
  currentAllotment,
  zones,
}: EnergyWidgetsProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  let refUnitModeTotal = currentProject.refUnit === 'total';

  const buildingIc: ProjectIcDTO =
    zones?.find((zone) => zone?.type === 'building')?.Ic['1'] || projectIc;

  const modalData = zones?.find((zone) => zone?.type === 'building')?.Zones[0].Ic[
    '1'
  ];
  const icToDisplay =
    currentProject.templateType === TEMPLATE_TYPE_NAME.BBCA
      ? buildingIc.Bbca.EgesEnergie
      : projectIc.IcEnergie;
  const icToDisplayMax =
    currentProject.templateType === TEMPLATE_TYPE_NAME.BBCA
      ? buildingIc.Bbca.Max.EgesEnergieMax
      : projectIc.IcConstruction;

  return (
    <div className="report-energy container-resizable-ok">
      <div className="report-energy-header">
        <h3 className="dashboard-title">
          {t('APP.WIDGETS.ENERGYWIDGETS.PH_1BB0A3', {
            defaultValue: 'Energie',
          })}
        </h3>

        <Tooltip
          title={
            currentProject.templateType.includes(TEMPLATE_TYPE_NAME.EPCM)
              ? t('APP.WIDGETS.ENERGYWIDGETS.PH_169C6F', {
                  defaultValue: "Impact de l'énergie",
                })
              : t('APP.WIDGETS.ENERGYWIDGETS.PH_BC1F8C', {
                  defaultValue: 'Impact carbone énergie : IcEnergie',
                })
          }
        >
          <img
            style={{
              width: window.screen.availHeight < 950 ? '40px' : undefined,
              height: window.screen.availHeight < 950 ? '40px' : undefined,
            }}
            src={energy}
            alt=""
          />
        </Tooltip>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {formatNumberP(icToDisplay, false, currentProject, currentIndicator)}
          {[TEMPLATE_TYPE_NAME.BBCA].includes(currentProject.templateType) && (
            <IcMaxBbcaTooltip
              icKey={'EgesEnergie'}
              icToDisplayMax={icToDisplayMax}
              currentIndicator={currentIndicator}
              refUnitModeTotal={refUnitModeTotal}
            />
          )}
        </div>
      </div>
      {shouldDisplayRoundStyleThresholds(currentAllotment) && (
        <div>
          <CustomThreshold
            modalData={modalData}
            screen="energy"
            buildingIc={buildingIc}
          />
        </div>
      )}
      {currentProject.templateType.includes('nothing') &&
        ((currentIndicator.id === 1 || currentIndicator.id === 26) && seuilsOk ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            {formatNumberP(
              projectIc.IcEnergieMax,
              true,
              currentProject,
              currentIndicator
            )}
            <IconButton
              size="small"
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                handlePopoverOpen(e, 'energy');
              }}
            >
              <InfoIcon />
            </IconButton>
            <Popover
              onClose={() => handlePopoverClose()}
              getContentAnchorEl={null}
              disableScrollLock={true}
              open={openedPopooverId === 'energy'}
              transformOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
              }}
              anchorOrigin={{
                horizontal: 'center',
                vertical: 'top',
              }}
              anchorEl={anchorEl}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {renderPopoverBody()}
              </div>
            </Popover>
          </div>
        ) : (
          <p>
            {t('APP.WIDGETS.ENERGYWIDGETS.PH_B0C7F4', {
              defaultValue: 'Seuil max : Non applicable',
            })}
          </p>
        ))}
    </div>
  );
};

export default EnergyWidgets;
