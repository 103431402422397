/* eslint-disable no-unused-vars */
import '../Projects.css';
import { ResponsiveBar } from '@nivo/bar';
import {
  ALLOTMENT_IDS,
  TEMPLATE_TYPE_NAME,
  PROJECTS_TYPE_NAMES_TOTAL_IMPACT,
} from '../../globalVariable/typeProject';
import {
  addBuildingSiteIds,
  changeSurfaceReference,
} from '../../../app/projects/LifeCycle/LifeCycleTab';
import { createFilterOptions } from '@material-ui/lab';
import { getColorFromMD, getColorFromString } from '../../services/colors/getColor';
import { set } from 'js-cookie';
import { setDataset, setSearchValue } from '../../../actions/projects';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { formatValueWithWeight } from '../../services/mathematics/formatNumber';
import { getLegendLabel } from '../../services/text/formatText';
import { useTranslation } from 'react-i18next';

const ResponsiveBarGraph = (dat) => {
  const [graphData, setGraphData] = useState({});
  const [keys, setKeys] = useState([]);
  const [total, setTotal] = useState(1);
  const {
    allGroups,
    isRe2020,
    selectedFilterLot,
    fullScreen,
    sortedBy,
    selectedZoneIdForGraph,
  } = dat;

  const parsedZones = useSelector((state) => state.projects.parsedProject.zones);
  const currentProject = useSelector((state) => state.projects.currentProject);
  const [surfaceRef, setSurfaceRef] = useState(0);
  const [isGraphEmpty, setIsGraphEmpty] = useState(true);
  const [axisRightTickValue, setAxisRightTickValue] = useState([0, 25, 50, 75, 100]);
  const { t } = useTranslation();

  useEffect(() => {
    setMasterDatas();

    let reformatDatas = [];
    let keys = [];

    const { validZonesIds, referenceArea } = addBuildingSiteIds(
      selectedZoneIdForGraph,
      parsedZones,
      currentProject.area
    );

    setSurfaceRef(referenceArea);

    if (dat.structureGraph) {
      dat.structureGraph.forEach((equipment) => {
        let sheet;

        if (validZonesIds.includes(equipment.sheets.ProjectSheet.ZoneId)) {
          sheet = equipment.sheets;
        }
        if (!sheet || sheet.length === 0) {
          return;
        }

        let match = sheet.ProjectSheet?.AllotmentNodes.find(
          (node) => node.AllotmentId === 1 && equipment['0'] === node.id
        );

        if (match) {
          if (dat.level !== 0 && dat.currentAllotment.id === 1) {
            match = equipment.details.find((ele) => ele.id === equipment[dat.level]);
          }

          if (
            (dat.currentAllotment.id === 2 || dat.currentAllotment.id === 34) &&
            typeof dat.level === 'number'
          ) {
            match =
              equipment.details[equipment.details.length - 1 - (dat.level + 1)];
          }

          const parent =
            dat.currentAllotment.id !== 1 && typeof dat.level === 'string'
              ? dat.currentAllotment.AllotmentNodes.find(
                  (node) => node.id === equipment.details[0].ParentId
                )
              : (dat.currentAllotment.id === 2 || dat.currentAllotment.id === 34) &&
                typeof dat.level !== 'string'
              ? equipment.details[equipment.details.length - dat.level - 1]
              : dat.currentAllotment.AllotmentNodes.find(
                  (node) => node.id === match.ParentId
                );

          keys = [...keys, match?.name];

          let exist = reformatDatas.find((el) => el.name === parent?.name);

          if (parent && match) {
            const result = computeResult(
              sheet,
              dat.project.refUnit,
              dat.currentProject.ddv,
              equipment,
              surfaceRef,
              sortedBy
            );

            if (exist) {
              reformatDatas = reformatDatas.filter((el) => el.name !== parent.name);

              exist = {
                ...exist,
                [match.name]: exist[match.name]
                  ? exist[match.name] + result
                  : result,
                [`${match.name}Color`]: getColorFromString(match.name),
                [`${match.name}Id`]: match.id.toString(),
              };

              reformatDatas = [...reformatDatas, exist];
            } else {
              let object = {
                name: parent.name,
                [match.name]: result,
                [`${match.name}Color`]: getColorFromString(match.name),
                [`${match.name}Id`]: match.id.toString(),
              };

              reformatDatas = [...reformatDatas, object];
            }
          }
        }
      });

      reformatDatas = compressData(
        reformatDatas.sort(function (a, b) {
          let aSum = 0;
          let bSum = 0;

          for (let i in a) {
            if (typeof a[i] === 'number') {
              aSum += a[i];
            }
          }

          for (let j in b) {
            if (typeof b[j] === 'number') {
              bSum += b[j];
            }
          }

          return bSum - aSum;
        }),
        'name',
        t
      );

      if (reformatDatas.length > 0) {
        setIsGraphEmpty(false);
      } else {
        setIsGraphEmpty(true);
      }

      let formatData = [];

      let tempTotal = 0;

      reformatDatas.map((bar) => {
        let formatObject = {};

        for (const [key, value] of Object.entries(bar)) {
          if (typeof value === 'number') {
            formatObject = { ...formatObject, [key]: value };
            tempTotal += Number(value);
          } else {
            formatObject = { ...formatObject, [key]: value };
          }
        }

        formatData = [...formatData, formatObject];
      });

      let conditionToKnowIfProjectIsStatic =
        dat.project.templateType === TEMPLATE_TYPE_NAME.EPCM ||
        dat.project.isAcvDynamic === false ||
        dat.project.isStaticCarbon;

      let total = [
        TEMPLATE_TYPE_NAME.BBCA,
        TEMPLATE_TYPE_NAME.GLOBAL_MONITORING,
      ].includes(dat.project.templateType)
        ? dat.project.staticAcv
        : conditionToKnowIfProjectIsStatic &&
          dat.currentAllotment.id === ALLOTMENT_IDS.RE2020
        ? dat.project.dynamicRe2020Acv
        : dat.currentAllotment.id === ALLOTMENT_IDS.RE2020
        ? dat.project.dynamicRe2020Acv
        : conditionToKnowIfProjectIsStatic
        ? dat.project.staticAcv
        : dat.project.dynamicAcv;

      const denominator = surfaceRef ? surfaceRef : 1;

      setTotal(dat.project.refUnit === 'total' ? total : total / denominator);

      let filteredData = [];

      dat.structureGraph.forEach((data) => {
        let parent = data.details[data.details.length - 1];

        if (selectedFilterLot.find((el) => el.label === parent.name)) {
          let current;

          if (dat.currentAllotment.id !== 1) {
            if (typeof dat.level === 'number') {
              const index = data.details.length - dat.level;

              parent = data.details[index - 1];
            } else {
              parent = data.details[1];
            }

            current = formatData.find((el) => el.name === parent.name);
          } else {
            current = formatData.find(
              (el) => el.name === data.details[Number(dat.level) + 1].name
            );
          }

          if (!current) {
            const otherColumn = formatData.find(
              (el) => el.name === t('APP.REPORT.OTHERS', { defaultValue: 'Autres' })
            );

            parent =
              dat.currentAllotment.id === 1
                ? data.details[Number(dat.level) + 1]
                : parent;

            if (otherColumn && otherColumn[data.details[0].name] && parent) {
              if (filteredData.find((el) => el?.name === parent?.name)) {
                const indexCurr = filteredData.findIndex(
                  (el) => el?.name === parent?.name
                );

                if (indexCurr) {
                  filteredData[indexCurr] = {
                    ...filteredData[indexCurr],
                    [data.details[0].name]: otherColumn[data.details[0].name],
                    [`${data.details[0].name}Color`]: getColorFromString(
                      data.details[0].name
                    ),
                    [`${data.details[0].name}Id`]: data.details[0].id.toString(),
                  };
                }

                return;
              } else {
                current = {
                  name: parent.name,
                  [data.details[0].name]: otherColumn[data.details[0].name],
                  [`${data.details[0].name}Color`]: getColorFromString(
                    data.details[0].name
                  ),
                  [`${data.details[0].name}Id`]: `${data.details[0].id.toString()}`,
                };
              }
            }
          }

          filteredData = [...filteredData, current];
        }
      });

      let validGraphData =
        selectedFilterLot.filter((el) => el.id !== 1200).length > 0
          ? compressData(
              [...new Set(filteredData)].sort(function (a, b) {
                let aSum = 0;
                let bSum = 0;

                for (let i in a) {
                  if (typeof a[i] === 'number') {
                    aSum += a[i];
                  }
                }

                for (let j in b) {
                  if (typeof b[j] === 'number') {
                    bSum += b[j];
                  }
                }

                return bSum - aSum;
              }),
              'name',
              t
            )
          : formatData;

      setGraphData(validGraphData);

      setKeys([...new Set(keys)]);
    }
  }, [
    dat.level,
    dat.sortedBy,
    selectedFilterLot,
    selectedZoneIdForGraph,
    surfaceRef,
  ]);

  const setMasterDatas = () => {
    const length = dat.dat.length;

    for (let index = 0; index < length; index += 1) {
      dat.dat[index].MasterDataEquipment = allGroups.type.find(
        (group) => group.id === dat.dat[index].ProjectSheet.MasterDataEquipmentId
      );
    }
  };

  useEffect(() => {
    return () => {
      setKeys([]);
      setGraphData([]);
    };
  }, []);

  useEffect(() => {
    setMasterDatas();
  }, [dat]);

  const renderToolTip = (info) => {
    const percentage = ((info.value / total) * 100).toFixed(2);

    return (
      <div
        style={{
          background: 'white',
          padding: '8px',
          border: '1px solid #A7AFB2',
          'border-radius': '4px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            zIndex: 100,
          }}
        >
          <span
            style={{
              display: 'block',
              width: '12px',
              height: '12px',
              background: `${info.color}`,
              'margin-right': '7px',
            }}
          ></span>
          <span>
            {info.id} :
            <strong style={{ marginLeft: '6px' }}>
              {formatValueWithWeight(info.value, dat.currentIndicator)}{' '}
              {getLegendLabel({
                currentIndicator: dat.currentIndicator,
                currentProject,
                unitOnly: true,
              })}
            </strong>{' '}
            | <strong>{percentage}%</strong>
          </span>
        </div>
        {/* {sheetsAllot.length > 0 && (
                  <span style={{ display:'block', marginLeft: '1.2em',  }}>Nombre de {type} {isCollective === sheetsAllot.length ? '' : isCollective ? `collective${isCollective > 1 ? 's' : ''}` : `individuelle${sheetsAllot.length > 1 ? 's' : ''}`} vérifiée{sheetsAllot.length > 1 ? 's' : ''} : <strong>{sheetsAllot.length}</strong></span>
                 )} */}
      </div>
    );
  };

  const handleRedirectToGraph = (data) => {
    dat.structureGraph.forEach((el) => {
      const current =
        el?.details?.find((el) => el.name === data.id) &&
        el?.details?.find((ele) => ele.name === data.indexValue)
          ? el
          : null;

      if (el.details.find((el) => el.name.includes('toiture'))) {
      }

      if (current) {
        dat.groups.forEach((md) => {
          let find = null;

          let allotSteps = [];

          find = allGroups.flatAllotments.find(
            (node) => el['0'] === node.id && node.MasterDataEquipmentId === md.id
          );

          if (find) {
            const copy = [...el.details];

            copy
              .sort((a, b) => b.step - a.step)
              .map((struct) => {
                allotSteps = [...allotSteps, struct];
              });

            dat.updateFormValue('sheetToLifeCycle', el.sheets);
            dat.updateFormValue('nodeSearchValue', find);
            dat.updateFormValue('allotSteps', allotSteps);
            dat.setSearchValue(md);
            dat.setTabValue(0);
          }
        });
      }
    });
  };

  if (graphData) {
    return (
      <>
        {isGraphEmpty ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: '1',
              fontSize: '30px',
              fontWeight: 'bold',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            {t('APP.REPORT.RESPONSIVEBAR.PH_A88560', {
              defaultValue: 'Pas de données',
            })}
          </div>
        ) : (
          <ResponsiveBar
            enableLabel={false}
            data={graphData}
            keys={keys}
            onClick={(e) =>
              dat.currentAllotment.id === 1 || typeof dat.level === 'string'
                ? handleRedirectToGraph(e)
                : null
            }
            onMouseEnter={(event) => {
              const hoverElements = document.querySelectorAll('g');

              hoverElements.forEach((el) => {
                el.style.cursor = 'pointer';
              });
            }}
            indexBy={'name'}
            tooltip={(input) => {
              return renderToolTip(input);
            }}
            margin={{ top: 30, right: 50, bottom: 50, left: 100 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            colors={({ id, data }) => {
              return data[`${id}Color`];
            }}
            axisTop={null}
            axisRight={{
              // tickValues:  [0, 0.25 * total, 0.5 * total, 0.75 * total, total],
              tickValues: axisRightTickValue,

              format: (v) => `${Math.round((v / total) * 100)}%`,
            }}
            axisBottom={{
              tickSize: 2,
              tickPadding: 10,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 32,
              format: (v) => {
                if (!v) return;

                const arrayOfWord = v.split(' ');

                let firstLine = '';
                let secondLine = '';
                let thirdLine = '';

                arrayOfWord.map((wd, index) => {
                  if (index < 2 && firstLine.length < 15) {
                    firstLine += wd + ' ';
                  } else if (index <= 4 && secondLine.length < 15) {
                    secondLine += wd + ' ';
                  } else {
                    thirdLine += wd + ' ';
                  }
                });

                if (!v) return;

                return (
                  <tspan>
                    <React.Fragment>
                      <tspan x="0" dy="-0.4em">
                        {firstLine}
                      </tspan>
                      <tspan x="0" dy="1.2em">
                        {secondLine}
                      </tspan>
                      <tspan x="0" dy="1.2em">
                        {thirdLine}
                      </tspan>
                    </React.Fragment>
                    <title>{v}</title>
                  </tspan>
                );
              },
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: dat.currentIndicator
                ? `${t('APP.REPORT.RESPONSIVEBAR.PH_F798C8', {
                    defaultValue: 'Impact environnemental',
                  })} ${getLegendLabel({
                    currentIndicator: dat.currentIndicator,
                    currentProject,
                    shouldDisplayShortName: false,
                  })}`
                : t('APP.REPORT.RESPONSIVEBAR.PH_9C52FC', {
                    defaultValue: 'Impact environnemental (kg eq. CO2)',
                  }),
              legendPosition: 'middle',
              legendOffset: -70,
              format: (value) => formatValueWithWeight(value, dat.currentIndicator),
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            motionStiffness={90}
            motionDamping={15}
          />
        )}
      </>
    );

    // if sortedBy === 1, display graph for optimisation potentiel
  } else {
    return <div>Load</div>;
  }
};

const compressData = (data, level, t) => {
  let newData = [];

  data = data.filter((el) => el);

  for (let i in data) {
    if (i == 9) {
      newData.push(data[i]);

      for (let k in newData[9]) {
        if (newData[9][k] === level) {
          newData[9][k] = t('APP.REPORT.OTHERS', { defaultValue: 'Autres' });
        }
      }
    } else if (i > 9) {
      for (const [key, value] of Object.entries(data[i])) {
        newData[9][key] = value;
      }

      newData[newData.length - 1][level] = t('APP.REPORT.OTHERS', {
        defaultValue: 'Autres',
      });
    } else {
      newData.push(data[i]);
    }
  }

  return newData;
};
const computeResult = (
  sheet,
  refUnit,
  projectDdv,
  equipment,
  surfaceRef,
  sortedBy
) => {
  surfaceRef = refUnit === 'total' ? 1 : surfaceRef;

  let result = equipment.res / surfaceRef;

  if (sortedBy === 'potentiel') {
    result =
      (sheet.optiDelta * sheet.ProjectSheet.quantity * projectDdv) / 50 / surfaceRef;
  }

  return result;
};

export let exportFunctionsForTests = {};

if (process.env.NODE_ENV === 'test') {
  exportFunctionsForTests = {
    computeResult,
  };
}

export default ResponsiveBarGraph;
