import { UPDATE_PRODUCTS_VALUE } from '../actions/products';

const initialState = {
  productView: 'components',
  menuDatas: null,
  brandsList: [],
  brandsSelected: [],
  requests: [],
  listProducts: [],
  viewMode: 'card',
  inputSearchBar: '',
  offsetList: 0,
  totalSheetCount: 0,
  searchRequest: 0,
};

function productsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_PRODUCTS_VALUE:
      return {
        ...state,
        [action.name]: action.value,
      };
    default:
      return state;
  }
}

export default productsReducer;
