import './labelForm.scss';
import { StateDTO } from '../../../../models/GeneralDTO';
import { useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CREATION_STEPS,
  CREATION_STEPS_TRAD_KEYS,
} from '../../../../globalVariable/creationProjectVariable';

const LabelForm = () => {
  const { rsetmodalOpen } = useSelector((state: StateDTO) => state.projects);
  const { creationSteps } = useSelector(
    (state: StateDTO) => state.projects.creationProject
  );

  const { t } = useTranslation();

  // TODO_TRAD
  let labelsAvailable = creationSteps.includes(
    t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.EXPLOITATION])
  )
    ? ['BBCA Exploitation']
    : creationSteps.includes(
        t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.RENOVATION_WORK])
      )
    ? ['BREEAM', 'Bâtiment biosourcé', 'BBCA Rénovation']
    : ['BREEAM', 'Bâtiment biosourcé', 'BBCA Neuf'];

  return (
    <div
      className="labelForm-wrapper soon-available"
      style={{ display: rsetmodalOpen ? 'none' : undefined }}
    >
      <div className="title-part">
        <h3>
          {t('APP.LABELFORM.INDEX.PH_48E012', {
            defaultValue: 'Labels (bientôt disponible)',
          })}
        </h3>
      </div>
      <div className="label-core">
        {labelsAvailable.map((label) => (
          <div key={label} className="choice-label">
            <FormControlLabel
              control={<Checkbox disabled />}
              label={
                <div
                  className="badge-label"
                  style={{ background: '#3FB155', color: 'white' }}
                >
                  {label}
                </div>
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LabelForm;
