import { Controller } from 'react-hook-form';
import { StateDTO } from '../../../models/GeneralDTO';
import { isDisplayDoubleCalcul } from '../SettingsSharedFunction/SettingsSharedFunction';
import { useSelector } from 'react-redux';
import HeaderForm from '../FormElement/HeaderForm';
import InfoIcon from '@material-ui/icons/Info';
import NoocoCheckbox from '../../../shared/NoocoCheckbox';
import React, { useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

const ParamsForm = ({ control, setValue }) => {
  const { currentProject } = useSelector((state: StateDTO) => state.projects);
  const { t } = useTranslation();

  useEffect(() => {
    // set default value only if value is undefined? for rerender?
    setValue('isAddedToCompanyReport', currentProject.isAddedToCompanyReport);
    setValue(
      'ProjectOption.showAlternativeResult',
      currentProject.ProjectOption.showAlternativeResult
    );
  }, []);

  return (
    <div className="form-wrapper">
      <HeaderForm
        title={t('APP.PARAMSFORM.PARAMSFORM.ADVANCED_SETTINGS', {
          defaultValue: 'Paramètres avancés',
        })}
      />
      <div className="form-main-wrapper">
        <div className="block-form">
          {/* double calcul */}
          {isDisplayDoubleCalcul(currentProject.templateType) && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Controller
                name="ProjectOption.showAlternativeResult"
                control={control}
                render={({ field }) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <NoocoCheckbox
                      {...field}
                      // checked={"isAddedToCompanyReport"}
                      defaultChecked={
                        currentProject.ProjectOption.showAlternativeResult
                      }
                      name="checkMode"
                    />

                    {/* <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}> */}
                    <div className="label-checkbox">
                      {t('APP.PARAMSFORM.PARAMSFORM.PH_ADD7FE', {
                        defaultValue:
                          'Afficher le double calcul Nooco/réglementaire',
                      })}
                    </div>
                    <Tooltip
                      title={t('APP.PARAMSFORM.PARAMSFORM.PH_6E6637', {
                        defaultValue:
                          "Affiche le calcul Nooco écoconception ainsi que le calcul réglementaire et rend disponible le changement de \"réferentiel\" afin de basculer d'un calcul à l'autre pour l'analyse du projet",
                      })}
                    >
                      <InfoIcon />
                    </Tooltip>

                    {/* </div> */}
                  </div>
                )}
              />
            </div>
          )}
          {/* project Reporting */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Controller
              name="isAddedToCompanyReport"
              control={control}
              render={({ field }) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <NoocoCheckbox
                    {...field}
                    // checked={"isAddedToCompanyReport"}
                    defaultChecked={currentProject.isAddedToCompanyReport}
                    name="checkMode"
                  />

                  {/* <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}> */}
                  <div className="label-checkbox">
                    {t('APP.PARAMSFORM.PARAMSFORM.PH_C2F16C', {
                      defaultValue: 'Ajouter ce projet au reporting entreprise',
                    })}
                  </div>
                  <Tooltip
                    title={t('APP.PARAMSFORM.PARAMSFORM.PH_80BB59', {
                      defaultValue:
                        "Comptabiliser ce projet dans le reporting consolidé des projets de l'entreprise (en attendant que les tableaux de reporting soient mis à votre disposition, vous pouvez déjà anticiper la prise en compte ou non de ce projet)",
                    })}
                  >
                    <InfoIcon />
                  </Tooltip>

                  {/* </div> */}
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParamsForm;
