import {
  TEMPLATE_TYPE_NAME,
  TYPE_AIRPORT,
  TYPE_BBCA,
  TYPE_DATACENTER,
  TYPE_EPCM,
  TYPE_EXPLOITATION,
  TYPE_NOOCO,
  TYPE_OUTDOOR,
  TYPE_PROTOTYPE,
  TYPE_RE2020,
  TYPE_RENOVATION,
} from '../../../globalVariable/typeProject';
import { ZoneDTO } from '../../../models/ProjectDTO';
import { useSelector } from 'react-redux';
import { StateDTO } from '../../../models/GeneralDTO';

export const isDisplayPermitDate = (
  templateType: string,
  showAlternativeResult: boolean = false
): boolean => {
  const templateToInclude = [TYPE_EPCM, TYPE_RE2020, TYPE_BBCA];

  if (templateToInclude.includes(templateType)) {
    return true;
  }

  if (templateType === TYPE_NOOCO && showAlternativeResult) {
    return true;
  }

  return false;
};

export const isDisplayAmenagementDate = (
  templateType: string,
  showAlternativeResult: boolean = false
): boolean => {
  if (templateType === TYPE_RE2020) {
    return true;
  }
  if (templateType === TYPE_NOOCO && showAlternativeResult) {
    return true;
  }

  return false;
};

export const isDisplayDdv = (templateType: string): boolean => {
  const templateToInclude = [
    TYPE_EXPLOITATION,
    TYPE_NOOCO,
    TYPE_RENOVATION,
    TYPE_AIRPORT,
    TYPE_DATACENTER,
    TYPE_OUTDOOR,
    TYPE_PROTOTYPE,
  ];

  if (templateToInclude.includes(templateType)) {
    return true;
  }

  return false;
};

export const isDisplayProjectPhase = (
  templateType: string,
  showAlternativeResult: boolean = false
): boolean => {
  if (templateType === TYPE_EPCM) {
    return true;
  }
  if (templateType === TYPE_RE2020) {
    return true;
  }
  if (templateType === TYPE_BBCA) {
    return true;
  }

  if (templateType === TYPE_NOOCO && showAlternativeResult) {
    return true;
  }

  return false;
};

export const isDisplayDoubleCalcul = (templateType: string): boolean => {
  const templateToInclude = [TYPE_EPCM, TYPE_RE2020, TYPE_NOOCO];

  if (templateToInclude.includes(templateType)) {
    return true;
  }

  return false;
};

// TODO_TRAD : ...
export const optionsPhase = (
  templateType: string
): { label: string; value: number }[] => {
  const allGroups = useSelector((state: StateDTO) => state.projects.allGroups);

  if ([TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(templateType)) {
    return [
      {
        label: allGroups.phases.find((phase) => phase.id === 2)?.name || '',
        value: 2,
      },
      {
        label: allGroups.phases.find((phase) => phase.id === 34)?.name || '',
        value: 34,
      },
      {
        label: allGroups.phases.find((phase) => phase.id === 35)?.name || '',
        value: 35,
      },
      {
        label: allGroups.phases.find((phase) => phase.id === 36)?.name || '',
        value: 36,
      },
    ];
  }
  if ([TEMPLATE_TYPE_NAME.RE2020, TEMPLATE_TYPE_NAME.NOOCO].includes(templateType)) {
    return [
      {
        label: allGroups.phases.find((phase) => phase.id === 2)?.name || '',
        value: 2,
      },
      {
        label: allGroups.phases.find((phase) => phase.id === 3)?.name || '',
        value: 3,
      },
      {
        label: allGroups.phases.find((phase) => phase.id === 4)?.name || '',
        value: 4,
      },
      {
        label: allGroups.phases.find((phase) => phase.id === 5)?.name || '',
        value: 5,
      },
      {
        label: allGroups.phases.find((phase) => phase.id === 6)?.name || '',
        value: 6,
      },
      {
        label: allGroups.phases.find((phase) => phase.id === 8)?.name || '',
        value: 8,
      },
      {
        label: allGroups.phases.find((phase) => phase.id === 9)?.name || '',
        value: 9,
      },
    ];
  }

  return [];
};

export const isRegularProject = (templateType: string): boolean => {
  const templateToInclude = [TYPE_EPCM, TYPE_RE2020, TYPE_NOOCO];

  if (templateToInclude.includes(templateType)) {
    return true;
  }

  return false;
};

export const isRegularProjectNorNooco = (templateType: string): boolean => {
  const templateToInclude = [TYPE_EPCM, TYPE_RE2020];

  if (templateToInclude.includes(templateType)) {
    return true;
  }

  return false;
};

export const isNeitherRegularNorExploitationProject = (
  templateType: string
): boolean => {
  if (isRegularProject(templateType) || isExploitationProject(templateType)) {
    return false;
  }

  return true;
};

export const isExploitationProject = (templateType: string): boolean => {
  const templateToInclude = [TYPE_EXPLOITATION];

  if (templateToInclude.includes(templateType)) {
    return true;
  }

  return false;
};

export const isProjectSheetInBuildingSite = (zones: ZoneDTO[]): boolean => {
  let isFoundProjectSheetInBuildingSiteZone = false;

  zones
    .filter((zone) => zone.type === 'buildingSite')
    .forEach((zone) => {
      if (
        zone.ProjectSheets.length > 0 &&
        isFoundProjectSheetInBuildingSiteZone === false
      ) {
        isFoundProjectSheetInBuildingSiteZone = true;
      }
    });

  return isFoundProjectSheetInBuildingSiteZone;
};

export const isProjectSheetInPlotZone = (zones: ZoneDTO[]): boolean => {
  console.log('zones', zones);

  const plotZoneId = zones.find((zone) => zone.type === 'plot')?.id;

  console.log(
    zones.filter((zone) => zone.type === 'plot' && zone.ParentId === plotZoneId)
  );

  let isFoundProjectSheetInPlotZone = false;

  zones
    .filter(
      (zone) =>
        (zone.type === 'buildingSite' && zone.ParentId === plotZoneId) ||
        zone.type === 'plot'
    )
    .forEach((zone) => {
      if (zone.ProjectSheets.length > 0 && isFoundProjectSheetInPlotZone === false) {
        isFoundProjectSheetInPlotZone = true;
      }
    });

  return isFoundProjectSheetInPlotZone;
};
