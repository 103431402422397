import i18nInstance from '../../i18n';

export const addNewBuilding = (zones, index, rsetBuilding, t) => {
  const newBuilding = {
    flag: index + 1,
    name: t('APP.SHARED.HANDLEPROJECTZONESCREATION.PH_5013F7', {
      building: zones.length + 1,
      defaultValue: 'Bâtiment {building}',
    }),
    type: 'building',
    Zones: [
      {
        name: 'Zone 1',
        BuildingTypeId: 1,
        menInput: true,
        logementInput: true,
        comblesInput: false,
        area: null,
        type: 'zone',
        category: 'CE1',
        isTAC: false,
      },
    ],
  };

  return [...zones, newBuilding];
};

export const addNewZone = (zones, index, t) => {
  const newZone = {
    name: t('APP.SHARED.HANDLEPROJECTZONESCREATION.PH_31E08D', {
      zone: zones[index].Zones.length + 1,
      defaultValue: 'Zone {zone}',
    }),
    BuildingTypeId: 1,
    menInput: true,
    logementInput: true,
    comblesInput: false,
    area: null,
    type: 'zone',
    category: 'CE1',
    isTAC: false,
  };

  zones[index] = { ...zones[index], Zones: [...zones[index].Zones, newZone] };

  return zones;
};
