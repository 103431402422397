const buildingTypesMap = {
  MAISON: 2,
  LOGEMENT_COL: 1,
  BUREAUX: 8,
  ENSEIGNEMENT_PRIMAIRE: 11,
  ENSEIGNEMENT_SECONDAIRE: 17,
  PARKING: 67,
};

const epcmBuildingTypesMap = {
  MAISON: 2,
  LOGEMENT_COL: 1,
  BUREAUX: 8,
  ENSEIGNEMENT_PRIMAIRE: 11,
  ENSEIGNEMENT_SECONDAIRE_JOUR: 24,
  ENSEIGNEMENT_SECONDAIRE_NUIT: 25,
  ETABLISSEMENT_PETITE_ENFANCE: 10,
};

module.exports = {
  buildingTypesMap,
  epcmBuildingTypesMap,
};
