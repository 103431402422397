import i18n from 'i18next';
import {
  REFERENCE_SURFACE_TAG,
  findLotFromAllotmentNode,
  findParentNode,
  getAllotmentId,
  handleAllotmentNodes,
  isBuildingMonoZoneOnly,
  isProjectCertified,
  makeNamesForZone,
  returnDefaultValueForSearchGroupSelectType,
  sortByIniesUpdate,
  sortFilterSousLot,
} from './utils';
import { ZONE_TYPES } from '../globalVariable/generalDefinitions';
import formatNumber from '../services/mathematics/formatNumber';

export const notIniesTypeFiche = [
  'EMPTY',
  'EMPTY_REFURBISHED',
  'bundle',
  'users',
  'FC',
  'DEC',
];
import { useTranslation } from 'react-i18next';

export const getReferenceSurface = (
  ZoneId,
  referenceSurfaceTag,
  zones,
  projectArea
) => {
  if (referenceSurfaceTag === REFERENCE_SURFACE_TAG.project) {
    return projectArea;
  }

  const equipmentZone = zones.find((zone) => ZoneId === zone.id);
  const equipmentParentZone = zones.find(
    (zone) => equipmentZone.ParentId === zone.id
  );

  if (
    equipmentZone.type === ZONE_TYPES.plot ||
    (equipmentParentZone && equipmentParentZone.type === ZONE_TYPES.plot)
  ) {
    return projectArea;
  }

  if (referenceSurfaceTag === REFERENCE_SURFACE_TAG.zone) {
    if (equipmentZone.type === ZONE_TYPES.zone) {
      return equipmentZone.area;
    } else if (equipmentZone.type === ZONE_TYPES.buildingSite) {
      const parentZone = zones.find((zone) => zone.id === equipmentZone.ParentId);

      return parentZone.area;
    }
  } else if (referenceSurfaceTag === REFERENCE_SURFACE_TAG.building) {
    if (equipmentZone.type === ZONE_TYPES.zone) {
      const parentBuilding = zones.find(
        (zone) => zone.id === equipmentZone.ParentId
      );

      return parentBuilding.area;
    } else if (equipmentZone.type === ZONE_TYPES.buildingSite) {
      const parentZone = zones.find((zone) => zone.id === equipmentZone.ParentId);
      const parentBuilding = zones.find((zone) => zone.id === parentZone.ParentId);

      return parentBuilding.area;
    }
  }

  return projectArea;
};

const calculateImpactByUnit = (currentSheet, referenceSurface) => {
  let total = currentSheet.acvResults?.total / referenceSurface;
  let totalRe2020 = currentSheet.Re2020Sheet
    ? currentSheet.Re2020Sheet.acvResults?.total / referenceSurface
    : 0;

  let emission = total / currentSheet.ProjectSheet.quantity;
  let emissionRe2020 = currentSheet.Re2020Sheet
    ? totalRe2020 / currentSheet.ProjectSheet.quantity
    : 0;

  let impactByUnit = emission * referenceSurface;
  let impactByUnitRe2020 = emissionRe2020 * referenceSurface;

  return { total, totalRe2020, impactByUnit, impactByUnitRe2020 };
};

const groupingOptionShowingGroupedBundles = {
  zones: true,
  import: true,
  lots: false,
  contrib: false,
  nomenclature: false,
};

const areBundlesShowedAsGroups = (groupingOption) => {
  return groupingOptionShowingGroupedBundles[groupingOption];
};

export const applyFiltersToZones = (zones, filterBy, allGroups, currentProject) => {
  if (filterBy.includes('project') || !currentProject.Sheets) return zones;

  const zonesFilterBy = filterBy.filter((el) =>
    [ZONE_TYPES.plot, ZONE_TYPES.buildingSite].includes(el)
  );
  const allotmentNodeFilterBy = filterBy.filter((el) =>
    ['eau', 'energie', 'zone'].includes(el)
  );

  let zonesToLoop = zones.map((zone) => {
    return { ...zone };
  });

  for (let zone of zonesToLoop) {
    if (zonesFilterBy.includes(zone.type)) {
      continue;
    }

    zone.ProjectSheets = [...zone.ProjectSheets].filter((projectSheet) => {
      const currentSheet = currentProject.Sheets.find(
        (el) => el.id === projectSheet.SheetId
      );

      if (currentSheet.type_fiche === 'bundle') {
        return false;
      }

      let detailsNode = getAllotmentNodeDetails(
        currentSheet,
        allGroups.flatAllotments
      );

      if (!detailsNode) {
        return false;
      }

      const componentCondition = filterBy.includes('zone')
        ? zone.type === ZONE_TYPES.zone &&
          !detailsNode.some((el) =>
            ['eau', 'energie'].includes(el.name.toLowerCase())
          )
        : false;
      const energyWaterCondition = detailsNode.some((el) =>
        allotmentNodeFilterBy
          .filter(
            (fb) => zone.type === ZONE_TYPES.zone && ['eau', 'energie'].includes(fb)
          )
          .includes(el.name.toLowerCase())
      );

      return componentCondition || energyWaterCondition;
    });
  }

  zonesToLoop = zonesToLoop.filter((zone) => zone.ProjectSheets.length > 0);

  return zonesToLoop;
};

const getAllotmentNodeDetails = (equipment, allAllotmentNodes) => {
  let nonCertifiedAllotmentNode = null;

  if (['EMPTY', 'EMPTY_REFURBISHED'].includes(equipment.type_fiche)) {
    const placeToSearch =
      equipment.AllotmentNodes.length > 0
        ? equipment.AllotmentNodes
        : equipment.ProjectSheet.AllotmentNodes.length > 0
        ? equipment.ProjectSheet.AllotmentNodes
        : null;

    if (placeToSearch) {
      nonCertifiedAllotmentNode = placeToSearch.find(
        (el) => el.MasterDataEquipmentId
      );
    }
  } else {
    nonCertifiedAllotmentNode = equipment.ProjectSheet.AllotmentNodes.find(
      (el) => el.MasterDataEquipmentId
    );
  }

  if (!nonCertifiedAllotmentNode) {
    return null;
  }

  let parentAllotmentNode = findParentNode(
    nonCertifiedAllotmentNode,
    allAllotmentNodes,
    [],
    []
  );

  let detailsNode = parentAllotmentNode[0].details;

  return detailsNode;
};

export const getGroupedEquipmentList = (
  zones,
  currentProject,
  filterBy,
  allGroups,
  allEquipmentRows,
  currentAllotmentNodes,
  groupingOption
) => {
  const filteredZones = applyFiltersToZones(
    zones,
    filterBy,
    allGroups,
    currentProject
  );

  const processedEquipmentRows = selectEquipmentRows(
    filteredZones,
    allEquipmentRows
  );

  const groupedEquipmentList = createAndFillGroupedEquipmentList(
    groupingOption,
    filteredZones,
    zones,
    currentProject,
    currentAllotmentNodes,
    processedEquipmentRows
  );

  return groupedEquipmentList;
};

const selectEquipmentRows = (filteredZones, allEquipmentRows) => {
  let processedEquipmentRows = [];

  for (let filteredZone of filteredZones) {
    for (let projectSheet of filteredZone.ProjectSheets) {
      const equipmentRow =
        allEquipmentRows &&
        allEquipmentRows.find((row) => row.key === projectSheet.id);

      if (!equipmentRow || equipmentRow.ProjectSheet.AllotmentNodes.length === 0) {
        continue;
      }

      processedEquipmentRows.push(equipmentRow);
    }
  }

  return processedEquipmentRows;
};

const removeEmptyGroups = (groupedEquipmentList) => {
  const cleanedGroupedEquipmentList = groupedEquipmentList.filter((group) => {
    group.children = group.children.filter(
      (child) => !child.children || (child.children && child.children.length > 0)
    );

    if (group.children) {
      return group.children.length > 0;
    }

    return false;
  });

  return cleanedGroupedEquipmentList;
};

const fetchBundlesInformations = (currentProject, groupingOption) => {
  const bundlesAreGrouped = areBundlesShowedAsGroups(groupingOption);

  const bundlesInformations = {
    bundlesAreGrouped: bundlesAreGrouped,
    bundles: [],
  };

  if (!bundlesAreGrouped) {
    return bundlesInformations;
  }

  if (currentProject.Sheets) {
    bundlesInformations.bundles = currentProject.Sheets.filter(
      (sheet) => sheet.type_fiche === 'bundle'
    );

    bundlesInformations.bundles.forEach((bundle) => {
      bundle.quantity = bundle.ProjectSheet.quantity;
    });

    bundlesInformations.bundles = bundlesInformations.bundles.map(
      ({ id, name, quantity, type_fiche }) => ({
        id,
        name,
        quantity,
        type_fiche,
      })
    );
  }

  return bundlesInformations;
};

const sortByOriginalRow = (groupedEquipmentList) => {
  return groupedEquipmentList.sort((a, b) => {
    let aOriginalRow = a.originalRow;
    let bOriginalRow = b.originalRow;

    if (a.children?.length > 0) {
      aOriginalRow = Math.min(...a.children.map((child) => child.originalRow));
    }

    if (b.children?.length > 0) {
      bOriginalRow = Math.min(...b.children.map((child) => child.originalRow));
    }

    if (aOriginalRow === undefined) return 1;

    if (bOriginalRow === undefined) return -1;

    return aOriginalRow - bOriginalRow;
  });
};

const createAndFillGroupedEquipmentList = (
  groupingOption,
  filteredZones,
  allZones,
  currentProject,
  currentAllotmentNodes,
  processedEquipmentRows
) => {
  let groupedEquipmentList = [];
  const projectTemplateType = currentProject.templateType;
  const bundlesInformations = fetchBundlesInformations(
    currentProject,
    groupingOption
  );

  switch (groupingOption) {
    case 'import': {
      groupedEquipmentList = getGroupByImportEquipmentList(
        filteredZones,
        processedEquipmentRows,
        bundlesInformations
      );

      break;
    }
    case 'zones': {
      groupedEquipmentList = getGroupByZonesEquipmentList(
        allZones,
        filteredZones,
        processedEquipmentRows,
        bundlesInformations
      );
      break;
    }
    case 'lots': {
      groupedEquipmentList = getGroupByLotsEquipmentList(
        filteredZones,
        currentAllotmentNodes,
        processedEquipmentRows,
        projectTemplateType
      );
      break;
    }
    case 'contrib': {
      groupedEquipmentList = getGroupByContributorEquipmentList(
        filteredZones,
        allZones,
        currentAllotmentNodes,
        processedEquipmentRows,
        projectTemplateType,
        bundlesInformations
      );
      break;
    }
    case 'nomenclature': {
      groupedEquipmentList = getGroupByNomenclatureEquipmentList(
        filteredZones,
        processedEquipmentRows
      );
      break;
    }
  }

  groupedEquipmentList = removeEmptyGroups(groupedEquipmentList);
  groupedEquipmentList = sortAllGroupLevels(groupedEquipmentList);

  if (groupingOption === 'import') {
    groupedEquipmentList.forEach((group) => {
      group.children = sortByOriginalRow(group.children);
    });
  }

  return groupedEquipmentList;
};

const sortAllGroupLevels = (groupedEquipmentList) => {
  groupedEquipmentList = sortFilterSousLot(groupedEquipmentList);

  for (let group of groupedEquipmentList) {
    if (group.children) {
      group.children = sortByIniesUpdate(sortFilterSousLot(group.children));
    }
  }

  return groupedEquipmentList;
};

const getGroupByNomenclatureEquipmentList = (
  filteredZones,
  processedEquipmentRows
) => {
  let equipmentsTreeObject = [];
  const defaultGroupName = 'Autre';

  for (let filteredZone of filteredZones) {
    for (let projectSheet of filteredZone.ProjectSheets) {
      const equipmentRow = processedEquipmentRows.find(
        (equipmentRow) => equipmentRow.ProjectSheet.id === projectSheet.id
      );

      if (!equipmentRow) {
        continue;
      }

      const nonCertifiedAllotmentNode =
        equipmentRow.ProjectSheet.AllotmentNodes.find(
          (allotmentNode) => allotmentNode.AllotmentId === 1
        );

      let nomenclatureName = nonCertifiedAllotmentNode
        ? nonCertifiedAllotmentNode.name
        : undefined;

      if (nomenclatureName === undefined) {
        nomenclatureName = defaultGroupName;
      }

      equipmentsTreeObject = addEquipmentRowToGroups({
        groupKeyByLevel: [nomenclatureName],
        groupNameByLevel: [nomenclatureName],
        equipmentsTreeObject: equipmentsTreeObject,
        equipmentRow: equipmentRow,
      });
    }
  }

  return equipmentsTreeObject;
};

export const getProcessEquipmentRows = (
  allProjectZones,
  project,
  referenceSurfaceTag,
  allotments,
  currentAllotment,
  currentIndicator,
  allGroups
) => {
  let processedEquipmentRows = [];

  for (let projectZone of allProjectZones) {
    let referenceSurfaceValue = getReferenceSurface(
      projectZone.id,
      referenceSurfaceTag,
      allProjectZones,
      project.area
    );

    let destinationName = makeNamesForZone(allProjectZones, projectZone, []);

    for (let projectSheet of projectZone.ProjectSheets) {
      const equipment = project.Sheets.find(
        (sheet) => sheet.ProjectSheet.id === projectSheet.id
      );

      if (equipment.ProjectSheet.AllotmentNodes.length === 0) {
        continue;
      }

      processedEquipmentRows.push(
        getProcessEquipmentRow(
          equipment,
          project,
          referenceSurfaceValue,
          allotments,
          destinationName,
          currentAllotment,
          currentIndicator,
          allGroups
        )
      );
    }
  }

  return processedEquipmentRows;
};

const getProcessEquipmentRow = (
  equipment,
  project,
  referenceSurfaceValue,
  allotments,
  destinationName,
  currentAllotment,
  currentIndicator,
  allGroups
) => {
  if (!currentAllotment) return;

  const { total, totalRe2020, impactByUnit, impactByUnitRe2020 } =
    calculateImpactByUnit(equipment, referenceSurfaceValue);
  const { sousLot, lotRe2020 } = getLotAndSousLotData(
    project.templateType,
    allotments,
    equipment.ProjectSheet.AllotmentNodes
  );

  let detailsNode = getAllotmentNodeDetails(equipment, allGroups.flatAllotments);

  const equipmentRow = getEquipmentObjectForEquipmentsList(
    equipment,
    project,
    destinationName,
    detailsNode,
    equipment.Re2020Sheet,
    currentAllotment,
    referenceSurfaceValue,
    totalRe2020,
    total,
    impactByUnitRe2020,
    impactByUnit,
    sousLot,
    lotRe2020,
    currentIndicator,
    allGroups
  );

  return equipmentRow;
};

const getGroupByImportEquipmentList = (
  filteredZones,
  processedEquipmentRows,
  bundlesInformations
) => {
  const defaultGroupName = 'Autre';
  let equipmentsTreeObject = [];

  for (let filteredZone of filteredZones) {
    for (let projectSheet of filteredZone.ProjectSheets) {
      const { equipmentRow, bundleData } = getEquipmentRowOrBundleObject(
        processedEquipmentRows,
        projectSheet,
        bundlesInformations
      );

      if (!equipmentRow) {
        continue;
      }

      let source = returnSource(equipmentRow);

      if (source === null || source === '') {
        source = defaultGroupName;
      }

      equipmentsTreeObject = addEquipmentRowToGroups({
        groupKeyByLevel: [source],
        groupNameByLevel: [source],
        equipmentsTreeObject: equipmentsTreeObject,
        equipmentRow: equipmentRow,
        bundleData: bundleData,
      });
    }
  }

  return equipmentsTreeObject;
};

const addBundleData = (bundleGroupObject, bundleData) => {
  bundleGroupObject.Unit = { name: 'Ensemble' };
  bundleGroupObject.quantity = bundleData.quantity;
  bundleGroupObject.type_fiche = 'bundle';

  return bundleGroupObject;
};

const addEquipmentRowToGroups = ({
  groupKeyByLevel,
  groupNameByLevel,
  groupTypeByLevel = [],
  equipmentsTreeObject,
  equipmentRow,
  bundleData = null,
}) => {
  let treeToActOn = equipmentsTreeObject;

  let { groupKeyList, groupNameList, groupTypeList } = getGroupKeyAndNameLists(
    groupKeyByLevel,
    groupNameByLevel,
    groupTypeByLevel,
    bundleData
  );

  for (let groupLevel = 0; groupLevel < groupKeyList.length; groupLevel++) {
    const isLastLevel = groupLevel === groupKeyList.length - 1;
    let matchingGroup = treeToActOn.find(
      (group) => group.key === groupKeyList[groupLevel]
    );

    if (!matchingGroup) {
      let groupObject = {
        key: groupKeyList[groupLevel],
        name: groupNameList[groupLevel],
        children: [],
      };

      if (groupTypeList.length > groupLevel) {
        groupObject.groupLevel = groupTypeList[groupLevel];
      }

      if (isLastLevel && bundleData !== null) {
        groupObject = addBundleData(groupObject, bundleData);
      }

      treeToActOn.push(groupObject);

      matchingGroup = treeToActOn.find(
        (group) => group.key === groupKeyList[groupLevel]
      );
    }

    if (isLastLevel) {
      matchingGroup.children.push(equipmentRow);

      break;
    }

    treeToActOn = matchingGroup.children;
  }

  return equipmentsTreeObject;
};

const getGroupByZonesEquipmentList = (
  allZones,
  filteredZones,
  processedEquipmentRows,
  bundlesInformations
) => {
  let equipmentsTreeObject = [];
  const plotAndPlotBuildingSiteZonesIds =
    getPlotAndPlotBuildingSiteZonesIds(allZones);
  const plotZoneId = plotAndPlotBuildingSiteZonesIds[0];
  const allBuildingsAreSingleZone = allZones
    .filter((zone) => zone.type === ZONE_TYPES.building)
    .every((building) => isBuildingMonoZoneOnly(building.id, allZones));

  for (let filteredZone of filteredZones) {
    const parentZone = allZones.find((zone) => zone.id === filteredZone.ParentId);
    const parentZoneId =
      filteredZone.type === ZONE_TYPES.buildingSite
        ? parentZone.ParentId
        : filteredZone.ParentId;
    const buildingZone = allZones.find(
      (zone) => zone.id === parentZoneId && zone.type === ZONE_TYPES.building
    );
    const zoneToUse =
      filteredZone.type === ZONE_TYPES.buildingSite ? parentZone : filteredZone;
    const zoneToUseName = makeNamesForZone(allZones, zoneToUse, []);

    for (let projectSheet of filteredZone.ProjectSheets) {
      const { equipmentRow, bundleData } = getEquipmentRowOrBundleObject(
        processedEquipmentRows,
        projectSheet,
        bundlesInformations
      );

      if (!equipmentRow) {
        continue;
      }

      if (
        plotAndPlotBuildingSiteZonesIds.includes(equipmentRow.ProjectSheet.ZoneId)
      ) {
        const plotZoneName = allZones.find((zone) => zone.id === plotZoneId).name;

        equipmentsTreeObject = addEquipmentRowToGroups({
          groupKeyByLevel: [`plot-${plotZoneId}`],
          groupNameByLevel: [plotZoneName],
          groupTypeByLevel: [REFERENCE_SURFACE_TAG.zone],
          equipmentsTreeObject: equipmentsTreeObject,
          equipmentRow: equipmentRow,
          bundleData: bundleData,
        });
      } else {
        let groupKeyByLevel = [buildingZone.id, zoneToUse.id];
        let groupNameByLevel = [buildingZone.name, zoneToUseName];
        let groupTypeByLevel = [
          REFERENCE_SURFACE_TAG.building,
          REFERENCE_SURFACE_TAG.zone,
        ];

        if (allBuildingsAreSingleZone) {
          groupKeyByLevel = [zoneToUse.id];
          groupNameByLevel = [zoneToUseName];
          groupTypeByLevel = [REFERENCE_SURFACE_TAG.zone];
        }

        equipmentsTreeObject = addEquipmentRowToGroups({
          groupKeyByLevel: groupKeyByLevel,
          groupNameByLevel: groupNameByLevel,
          groupTypeByLevel: groupTypeByLevel,
          equipmentsTreeObject: equipmentsTreeObject,
          equipmentRow: equipmentRow,
          bundleData: bundleData,
        });
      }
    }
  }

  return equipmentsTreeObject;
};

const getGroupKeyAndNameLists = (
  groupKeyList,
  groupNameList,
  groupTypeList,
  bundleData
) => {
  if (bundleData) {
    if (groupTypeList.length !== groupKeyList.length) {
      groupTypeList = groupKeyList.map(() => null);
    }
    groupKeyList.push(bundleData.id);
    groupNameList.push(bundleData.name);
    groupTypeList.push(true);
  }

  return { groupKeyList, groupNameList, groupTypeList };
};

const getEquipmentRowOrBundleObject = (
  processedEquipmentRows,
  projectSheet,
  bundlesInformations
) => {
  let equipmentData = {
    equipmentRow:
      processedEquipmentRows.find(
        (equipmentRow) => equipmentRow.ProjectSheet.id === projectSheet.id
      ) || null,
    bundleData: null,
  };

  if (!equipmentData.equipmentRow) {
    return equipmentData;
  }

  if (
    equipmentData.equipmentRow.ProjectSheet.BundleId !== null &&
    bundlesInformations.bundlesAreGrouped
  ) {
    const matchingBundle = bundlesInformations.bundles.find(
      (bundle) => bundle.id === equipmentData.equipmentRow.ProjectSheet.BundleId
    );

    equipmentData.bundleData = {
      id: equipmentData.equipmentRow.ProjectSheet.BundleId,
      name: matchingBundle?.name || 'Ensemble',
      quantity: matchingBundle?.quantity || 1,
    };
  }

  return equipmentData;
};

const getPlotAndPlotBuildingSiteZonesIds = (zones) => {
  const plotZone = zones.find((zone) => zone.type === ZONE_TYPES.plot);

  if (!plotZone) {
    return [];
  }

  let plotAndPlotBuildingSiteZonesIds = [plotZone.id];
  const plotBuildingSiteZone = zones.find(
    (zone) => zone.type === ZONE_TYPES.buildingSite && zone.ParentId === plotZone.id
  );

  if (plotBuildingSiteZone) {
    plotAndPlotBuildingSiteZonesIds.push(plotBuildingSiteZone.id);
  }

  return plotAndPlotBuildingSiteZonesIds;
};

const getGroupByContributorEquipmentList = (
  filteredZones,
  allZones,
  currentAllotmentNodes,
  processedEquipmentRows,
  bundlesInformations
) => {
  let equipmentsTreeObject = [];

  const lotLevel = 0;
  const sousLotLevel = 1;
  const currentAllotmentId = currentAllotmentNodes.id;
  const plotAndPlotBuildingSiteZonesIds =
    getPlotAndPlotBuildingSiteZonesIds(filteredZones);
  const plotZoneId = plotAndPlotBuildingSiteZonesIds[0];
  const otherBuildingSiteZonesIds = filteredZones
    .filter(
      (zone) =>
        !plotAndPlotBuildingSiteZonesIds.includes(zone.id) &&
        zone.type === ZONE_TYPES.buildingSite
    )
    .map((zone) => zone.id);

  for (let filteredZone of filteredZones) {
    for (let projectSheet of filteredZone.ProjectSheets) {
      const { equipmentRow } = getEquipmentRowOrBundleObject(
        processedEquipmentRows,
        projectSheet,
        bundlesInformations
      );

      if (!equipmentRow) {
        continue;
      }

      if (
        plotAndPlotBuildingSiteZonesIds.includes(equipmentRow.ProjectSheet.ZoneId)
      ) {
        const plotZoneName = allZones.find((zone) => zone.id === plotZoneId).name;

        equipmentsTreeObject = addEquipmentRowToGroups({
          groupKeyByLevel: [`plot-${plotZoneId}`],
          groupNameByLevel: [plotZoneName],
          equipmentsTreeObject: equipmentsTreeObject,
          equipmentRow: equipmentRow,
        });
      } else if (
        otherBuildingSiteZonesIds.includes(equipmentRow.ProjectSheet.ZoneId)
      ) {
        const zoneName = filteredZones.find(
          (zone) => zone.id === equipmentRow.ProjectSheet.ZoneId
        ).name;

        equipmentsTreeObject = addEquipmentRowToGroups({
          groupKeyByLevel: [zoneName],
          groupNameByLevel: [zoneName],
          equipmentsTreeObject: equipmentsTreeObject,
          equipmentRow: equipmentRow,
        });
      } else {
        const lot = findLotFromAllotmentNode(
          currentAllotmentNodes.AllotmentNodes,
          projectSheet.AllotmentNodes.find(
            (allotmentNode) => allotmentNode.AllotmentId === currentAllotmentId
          ),
          lotLevel
        );
        const sousLot = findLotFromAllotmentNode(
          currentAllotmentNodes.AllotmentNodes,
          projectSheet.AllotmentNodes.find(
            (allotmentNode) => allotmentNode.AllotmentId === currentAllotmentId
          ),
          sousLotLevel
        );

        equipmentsTreeObject = addEquipmentRowToGroups({
          groupKeyByLevel: [lot.name, sousLot.name],
          groupNameByLevel: [lot.name, sousLot.name],
          equipmentsTreeObject: equipmentsTreeObject,
          equipmentRow: equipmentRow,
        });
      }
    }
  }

  return equipmentsTreeObject;
};

const getGroupByLotsEquipmentList = (
  filteredZones,
  currentAllotmentNodes,
  processedEquipmentRows,
  projectTemplateType
) => {
  let equipmentsTreeObject = [];
  let lotsList = [];
  let sousLotsList = [];

  const currentAllotmentId = currentAllotmentNodes.id;
  const projectIsCertified = isProjectCertified(projectTemplateType);
  const lotLevel = projectIsCertified ? 1 : 0;
  const sousLotLevel = projectIsCertified ? 2 : 0;

  for (let filteredZone of filteredZones) {
    for (let projectSheet of filteredZone.ProjectSheets) {
      const equipmentRow = processedEquipmentRows.find(
        (equipmentRow) => equipmentRow.ProjectSheet.id === projectSheet.id
      );
      const AllotmentNode = projectSheet.AllotmentNodes.find(
        (allotmentNode) => allotmentNode.AllotmentId === currentAllotmentId
      );

      if (!equipmentRow || !AllotmentNode) {
        continue;
      }

      const lot = findLotFromAllotmentNode(
        currentAllotmentNodes.AllotmentNodes,
        AllotmentNode,
        lotLevel
      );
      const sousLot = findLotFromAllotmentNode(
        currentAllotmentNodes.AllotmentNodes,
        AllotmentNode,
        sousLotLevel
      );

      if (
        !(
          lotsList.map((existingLot) => existingLot.id).includes(lot.id) ||
          lot === null
        )
      ) {
        lotsList.push(lot);
      }
      if (
        !(
          sousLotsList
            .map((existingSousLot) => existingSousLot.key)
            .includes(sousLot.id) || sousLot === null
        )
      ) {
        const sousLotObject = {
          key: sousLot.id,
          name: sousLot.name,
          ParentId: sousLot.ParentId,
          children: [equipmentRow],
        };

        sousLotsList.push(sousLotObject);
      } else {
        const sousLotObject = sousLotsList.find(
          (existingSousLot) => existingSousLot.key === sousLot.id
        );

        sousLotObject.children.push(equipmentRow);
      }
    }
  }

  for (let lot of lotsList) {
    equipmentsTreeObject.push({
      key: lot.id,
      name: lot.name,
      children: [],
    });
  }

  for (let sousLot of sousLotsList) {
    let lotObject = equipmentsTreeObject.find((lot) => lot.key === sousLot.ParentId);

    if (lotObject) {
      lotObject.children.push(sousLot);
    } else {
      lotObject = equipmentsTreeObject.find((lot) => lot.key === sousLot.key);
      if (lotObject) {
        lotObject.children = sousLot.children;
      }
    }
  }

  return equipmentsTreeObject;
};

const addAllotmentNodeDataToEquipment = (equipment, allotmentNodesData) => {
  if (!allotmentNodesData) {
    return;
  }

  equipment.lot = allotmentNodesData.find((el) => el.level === 0)?.name;
  equipment.fonction = allotmentNodesData.find((el) => el.level === 1)?.name;
  equipment.materiel = allotmentNodesData.find((el) => el.level === 3)?.name;
  equipment.type = allotmentNodesData.find((el) => el.level === 4)?.name;
  equipment.categorie = allotmentNodesData.find((el) => el.level === 2)?.name;
};

const getLotAndSousLotData = (
  projectTemplateType,
  allotments,
  equipmentNonCertifiedAllotmentNodes
) => {
  const allotmentId = getAllotmentId(projectTemplateType);
  const idSousLot = equipmentNonCertifiedAllotmentNodes.find(
    (el) => el.AllotmentId === allotmentId
  );

  const allotmentNodes = allotments.find(
    (allotment) => allotment.id === allotmentId
  ).AllotmentNodes;

  let sousLot = allotmentNodes.find((el) => el.id === idSousLot?.id);
  let lotRe2020 = allotmentNodes.find((el) => el.id === sousLot?.ParentId);

  return { sousLot, lotRe2020 };
};

const getEquipmentObjectForEquipmentsList = (
  nonCertifiedSheet,
  currentProject,
  destinationName,
  detailsNode,
  certifiedSheet,
  currentAllotment,
  referenceSurfaceValue,
  totalRe2020,
  total,
  impactByUnitRe2020,
  impactByUnit,
  sousLot,
  lotRe2020,
  currentIndicator,
  allGroups
) => {
  const refUnit = currentProject.refUnit;
  const divisorImpact = refUnit === 'total' ? 1 : referenceSurfaceValue;
  const AllotmentNodeNooco = nonCertifiedSheet.ProjectSheet.AllotmentNodes.find(
    (node) => node.MasterDataEquipmentId
  );

  let nodesLinkToSameMd = handleAllotmentNodes(
    nonCertifiedSheet.ProjectSheet.MasterDataEquipment,
    currentAllotment,
    allGroups.flatAllotments,
    currentProject
  );

  let sourceName;

  if (nonCertifiedSheet.Sources?.length > 0) {
    sourceName = allGroups.sources.find(
      (source) => source.id === nonCertifiedSheet.Sources[0].SourceId
    )?.originalName;
  }

  let totalUsage = 0;

  if (nonCertifiedSheet.acvResults.usage) {
    const usage = nonCertifiedSheet.acvResults.usage;
    const usageFields = ['b1', 'b2', 'b3', 'b4', 'b1fluide', 'b4fluide'];
    totalUsage =
      usageFields.reduce((sum, field) => sum + (usage[field] || 0), 0) /
      divisorImpact;
  }

  let equipmentObjectForEquipmentList = {
    key: nonCertifiedSheet.ProjectSheet.id,
    id: nonCertifiedSheet.ProjectSheet.id,
    Unit: nonCertifiedSheet.Unit,
    Indices: nonCertifiedSheet.Indices,
    detailsNode,
    nodesLinkToSameMd,
    isRe2020: currentProject.isRe2020,
    acvResults: nonCertifiedSheet.acvResults,
    childEquip: nonCertifiedSheet.childEquip,
    MasterDataEquipment: nonCertifiedSheet.ProjectSheet.MasterDataEquipment,
    database: sourceName,
    status: nonCertifiedSheet.ProjectSheet.status,
    lifespan: nonCertifiedSheet.ProjectSheet.lifespan,
    coeff:
      nonCertifiedSheet.ddv >= currentProject.ddv || nonCertifiedSheet.isChantier
        ? 1
        : formatNumber(currentProject.ddv / nonCertifiedSheet.ddv),
    destination: destinationName,
    caracs: nonCertifiedSheet.Caracs,
    AllotmentNodeNooco: AllotmentNodeNooco,
    name: nonCertifiedSheet.name,
    comment: nonCertifiedSheet.ProjectSheet.comment,
    Re2020SheetName: certifiedSheet.name,
    type_fiche: `${nonCertifiedSheet.type_fiche}${
      nonCertifiedSheet.is_collective ? ' Collectif' : ''
    }`,
    searchGroupSelectType: returnDefaultValueForSearchGroupSelectType(
      {
        SheetId: Boolean(!notIniesTypeFiche.includes(nonCertifiedSheet.type_fiche)),
        AllotmentNode: AllotmentNodeNooco,
        type_fiche: nonCertifiedSheet.type_fiche,
      },
      allGroups
    ),
    config: nonCertifiedSheet.type_fiche === 'FC' ? 'Config' : null,
    id_inies:
      currentAllotment.id !== 1
        ? certifiedSheet.id_inies
        : nonCertifiedSheet.id_inies,
    ProjectSheet: nonCertifiedSheet.ProjectSheet,
    originalName: nonCertifiedSheet.ProjectSheet.originalName
      ? nonCertifiedSheet.ProjectSheet.originalName
      : '',
    quantity: nonCertifiedSheet.ProjectSheet.quantity,
    ddv: nonCertifiedSheet.ddv,
    impactByArea:
      nonCertifiedSheet.type_fiche === 'REFURBISHED'
        ? 0
        : nonCertifiedSheet.acvResults.total / referenceSurfaceValue,
    fluidImpact: nonCertifiedSheet.acvResults.totalFluid / referenceSurfaceValue,
    impactRe2020:
      nonCertifiedSheet.type_fiche === 'REFURBISHED'
        ? 0
        : certifiedSheet && certifiedSheet.acvResults
        ? certifiedSheet.acvResults.total / referenceSurfaceValue
        : null,
    globalImpactRe2020:
      nonCertifiedSheet.type_fiche === 'REFURBISHED'
        ? 0
        : certifiedSheet && certifiedSheet.acvResults
        ? Number(totalRe2020) * Number(referenceSurfaceValue)
        : null,
    impactByUnitRe2020: impactByUnitRe2020 / divisorImpact,
    Re2020Sheet: certifiedSheet,
    Keywords: nonCertifiedSheet.ProjectSheet.MasterDataEquipment?.Keywords,
    impactByUnit: impactByUnit / divisorImpact,
    re2020Lot: sousLot?.name,
    lotre2020: lotRe2020?.name,
    totalArea:
      nonCertifiedSheet.type_fiche === 'REFURBISHED'
        ? 0
        : Number(total) * Number(referenceSurfaceValue),
    endDate: nonCertifiedSheet.endDate,
    isDisabled: nonCertifiedSheet.isDisabled,
    isProductDisabled: nonCertifiedSheet.isProductDisabled,
    moduleD:
      currentIndicator?.id === 1
        ? nonCertifiedSheet.acvResults.usage.moduleD / divisorImpact
        : nonCertifiedSheet.acvResults.usage.moduleD / divisorImpact,
    source: returnSource(nonCertifiedSheet),
    expiration_date: nonCertifiedSheet.expiration_date,
    customName: nonCertifiedSheet.ProjectSheet.customName,
    originalRow: nonCertifiedSheet.ProjectSheet.originalRow,
    stockC: nonCertifiedSheet.ProjectSheet?.StockC / divisorImpact,
    production: nonCertifiedSheet.acvResults?.base.prod / divisorImpact,
    edification: nonCertifiedSheet.acvResults?.base.edif / divisorImpact,
    fin: nonCertifiedSheet.acvResults
      ? (nonCertifiedSheet.acvResults?.base.end +
          (nonCertifiedSheet.acvResults.base.endfluide || 0)) /
        divisorImpact
      : 0,
    entretien: totalUsage,
    is_collective: nonCertifiedSheet.is_collective,
  };

  addAllotmentNodeDataToEquipment(equipmentObjectForEquipmentList, detailsNode);

  addSearchablesFieldToEquipment(equipmentObjectForEquipmentList);

  return equipmentObjectForEquipmentList;
};

const addSearchablesFieldToEquipment = (equipment) => {
  equipment.searchables = [
    equipment.name,
    equipment.lot,
    equipment.fonction,
    equipment.materiel,
    equipment.type,
    equipment.categorie,
    equipment.destination,
    equipment.comment,
    equipment.Re2020SheetName,
    equipment.type_fiche,
    equipment.id_inies,
    equipment.config,
    equipment.originalName,
    equipment.re2020Lot,
    equipment.lotre2020,
    equipment.source,
    equipment.customName,
    equipment.originalRow,
    equipment.quantity,
    equipment.ddv,
  ];

  if (equipment.MasterDataEquipment && equipment.MasterDataEquipment.hasFluid) {
    equipment.searchables.push('fluide frigorigene');
  }
  if (equipment.Unit && equipment.Unit.name) {
    equipment.searchables.push(equipment.Unit.name);
  }
  if (equipment.Keywords && equipment.Keywords.length > 0) {
    equipment.searchables.push(...equipment.Keywords.map((keyword) => keyword.name));
  }

  equipment.searchables = equipment.searchables.filter(
    (tag) =>
      tag !== undefined &&
      tag !== null &&
      tag !== '' &&
      tag !== false &&
      tag !== true
  );
  equipment.searchables = equipment.searchables
    .map((tag) => {
      if (typeof tag === 'number') {
        const formattedNumber = formatNumber(tag).replace(/\s/g, '');

        return [formattedNumber, formattedNumber.replace(',', '.')];
      } else {
        return tag
          .toString()
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .split(' ');
      }
    })
    .flat();

  equipment.searchables = Array.from(new Set(equipment.searchables));
};

const equipmentSourcesMap = {
  manual: i18n.t('APP.GENERALFORM.EQUIPMENT.SOURCE.MANUAL', {
    defaultValue: 'Ajout simple',
  }),
  xml: 'RSEE/RSET',
  buildingSiteCalculator: i18n.t(
    'APP.GENERALFORM.EQUIPMENT.SOURCE.BUILDING_SITE_CALCULATOR',
    {
      defaultValue: 'Calculette chantier',
    }
  ),
  waterCalculator: i18n.t('APP.GENERALFORM.EQUIPMENT.SOURCE.WATER_CALCULATOR', {
    defaultValue: 'Calculette eau',
  }),
};

export const returnSource = (currentSheet) => {
  const sourceKey = Object.keys(equipmentSourcesMap).find(
    (key) => key === currentSheet.ProjectSheet.source
  );

  if (sourceKey) {
    return equipmentSourcesMap[sourceKey];
  }

  return currentSheet.ProjectSheet.source;
};
