import { PathDTO } from '../../app/models/ProjectsDTO';
import API from '../../app/services/API';

const api = new API();

export const fetchFoldersByCompany = async (companyId, isBundleScreen) => {
  return await api
    .getFoldersByCompany(companyId, isBundleScreen)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

type createFolderData = {
  parentFolderId: number;
  companyId: number;
  folderName: string;
};
export const createFolder = async (
  data: createFolderData,
  isBundleScreen: boolean
) => {
  return await api
    .createFolder(
      data.parentFolderId,
      data.companyId,
      data.folderName,
      isBundleScreen
    )
    .then((response) => response)
    .catch((e) => {
      throw e;
    });
};

export const updateFolder = async (
  data: { id: number; body: PathDTO },
  isBundleScreen: boolean
) => {
  return await api
    .patchFolder(data, isBundleScreen)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

export const deleteFolder = async (folderId, isBundleScreen: boolean) => {
  return await api
    .deleteFolder(folderId, isBundleScreen)
    .then((response) => response)
    .catch((e) => {
      throw e;
    });
};
