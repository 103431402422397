import './carbonEstimation.scss';
import { Input } from '@photocarbone/nooco-ui';
import { Tooltip } from '@material-ui/core';
import { decimalNumberRegex } from '../../../../../shared/Regex/regex.js';
import MaterialTable from './MaterialTable/MaterialTable';
import Radio from '@material-ui/core/Radio';
import informationPic from '../../../../../../assets/images/equipments/information.png';

import { StateDTO } from '../../../../../models/GeneralDTO';
import { changeModelingEquipments } from '../../../../../../actions/equipments';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const MaterialSynthese = ({ lines, isLibraryScreen = false }) => {
  const { t } = useTranslation();
  const calculTotalPercent = () => {
    let totalPercent = 0;

    lines.forEach((line) => {
      totalPercent += line.percent;
    });

    return totalPercent;
  };

  const totalMaterialCompletedStatus = ():
    | 'incomplete'
    | 'complete'
    | 'overflow' => {
    const totalPercent = calculTotalPercent();

    if (totalPercent > 100) return 'overflow';
    if (totalPercent === 100) return 'complete';

    return 'incomplete';
  };

  const totalPercent = calculTotalPercent();
  const completedStatus = totalMaterialCompletedStatus();

  return (
    <div className={`materialSynthese ${isLibraryScreen ? 'libraryScreen' : ''}`}>
      <div className="title">
        {t('APP.CARBONESTIMATION.CARBONESTIMATION.PH_7D8FDF', {
          defaultValue: 'Saisie des matériaux:',
        })}
      </div>
      <div className="materialToComplete">
        <span className={completedStatus}>
          {`
            ${
              completedStatus === 'overflow'
                ? t('APP.CARBONESTIMATION.CARBONESTIMATION.PH_EDB984', {
                    defaultValue: 'Trop de matériaux :',
                  })
                : ''
            }
            ${
              completedStatus === 'complete'
                ? t('APP.CARBONESTIMATION.CARBONESTIMATION.PH_48FFF1', {
                    defaultValue: 'Complet :',
                  })
                : ''
            }
            ${
              completedStatus === 'incomplete'
                ? t('APP.CARBONESTIMATION.CARBONESTIMATION.PH_7215F3', {
                    defaultValue: 'A compléter :',
                  })
                : ''
            }
            ${totalPercent}
            `}
        </span>
        <span style={{ color: 'rgba(102, 102, 102, 1)' }}>{` / `}</span>
        {`100%`}
      </div>
      <div className="slider">
        <div
          className={`completedPart ${completedStatus}`}
          style={{
            width: completedStatus === 'overflow' ? '100%' : `${totalPercent}%`,
          }}
        />

        {/* <div className="emptyPart" /> */}
      </div>
    </div>
  );
};

const percentToLabel = (percent: number) => {
  const { t } = useTranslation();

  const labels = {
    10: t('APP.CARBONESTIMATION.CARBONESTIMATION.FAIBLE', {
      defaultValue: 'Faible',
    }),
    25: t('APP.CARBONESTIMATION.CARBONESTIMATION.MOYEN', {
      defaultValue: 'Moyen',
    }),
    50: t('APP.CARBONESTIMATION.CARBONESTIMATION.ELEVE', {
      defaultValue: 'Elevé',
    }),
    75: t('APP.CARBONESTIMATION.CARBONESTIMATION.TRES_ELEVE', {
      defaultValue: 'Très élevé',
    }),
  };

  return labels[percent] || '';
};

const ExempleElement = ({
  percentValueElement,
  percentValueElementSelected,
  exempleDescription,
  handleCheckElement,
  isLibraryScreen,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: isLibraryScreen ? '110' : '190px',
        margin: '0 16px',
        borderRadius: '10px',
        marginTop: isLibraryScreen ? '1rem' : '',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '35px',
        }}
      >
        <Radio
          checked={percentValueElementSelected === percentValueElement}
          onChange={(newValue) => {
            if (isLibraryScreen) {
              handleCheckElement(parseInt(newValue.currentTarget.value));
            } else {
              handleCheckElement(newValue);
            }
          }}
          value={percentValueElement}
          color="default"
          name="radio-button-demo"
        />
        <div
          style={{
            color: 'color: #666666',
            fontSize: isLibraryScreen ? '15px' : '17px',
            fontWeight: '700',
            marginLeft: '8px',
            marginRight: '8px',
          }}
        >
          {percentToLabel(percentValueElement)}
        </div>
        <Tooltip title={exempleDescription}>
          <div style={{ marginBottom: '2px' }}>
            <img src={informationPic} alt="" />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export type CarbonEstimationProps =
  | {
      library: 'create' | 'edit';
    }
  | undefined;
export const CarbonEstimation = ({ library }) => {
  const { totalWeight, manufacturedRate, materialLines } = useSelector(
    (state: StateDTO) => state.equipments.modeling
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleCheckElement = (e) => {
    dispatch(
      changeModelingEquipments('manufacturedRate', parseInt(e.currentTarget.value))
    );
  };

  const updateMaterialLines = (newMaterialLines) => {
    dispatch(changeModelingEquipments('materialLines', newMaterialLines));
  };

  const onChangeTotalWeight = (e) => {
    dispatch(changeModelingEquipments('totalWeight', e));
  };

  return (
    <div className="carbonEstimationWrapper">
      <div className="main">
        <MaterialTable lines={materialLines} updateLines={updateMaterialLines} />
        <div className="rightPart">
          <MaterialTotalWeight
            t={t}
            totalWeight={totalWeight}
            onChange={onChangeTotalWeight}
          />
          <MaterialSynthese lines={materialLines} />
        </div>
      </div>
      <div className="footerCarbon">
        <ManufacturerIndexTitle t={t} />
        <ManufacturerIndexChoice
          t={t}
          handleCheckElement={handleCheckElement}
          percentValueElementSelected={manufacturedRate}
        />
      </div>
    </div>
  );
};

export default CarbonEstimation;

export const MaterialTotalWeight = ({ t, totalWeight, onChange }) => {
  const isValidNumberTotalWeight = () => {
    const regex = /^[1-9]\d*([.,]\d*)?$/;
    const isValid =
      totalWeight === null || totalWeight === ''
        ? true
        : totalWeight
        ? decimalNumberRegex.test(totalWeight)
        : false;

    return isValid;
  };

  return (
    <div className="formElementWrapper" style={{ marginLeft: '2rem' }}>
      <div className="caracteristicLabel">
        {t('APP.CARBONESTIMATION.CARBONESTIMATION.PH_2E40AD', {
          defaultValue: 'Masse totale du produit (kg)',
        })}
      </div>

      <Input
        // placeholder="Nom de l'équipement"
        errorText={
          isValidNumberTotalWeight()
            ? ''
            : t('APP.CARBONESTIMATION.CARBONESTIMATION.PH_BD1B14', {
                defaultValue: 'Veuillez renseigner une quantité',
              })
        }
        // type="number"
        defaultValue={totalWeight}
        width={'160px'}
        onChange={onChange}
        error={!isValidNumberTotalWeight()}
      />
    </div>
  );
};

export const ManufacturerIndexTitle = ({ t, isLibraryScreen = false }) => {
  return (
    <div
      className="title"
      style={{
        marginLeft: isLibraryScreen ? '2rem' : '4rem',
        marginTop: '1rem',
        fontSize: isLibraryScreen ? '15px' : '17px',
        color: isLibraryScreen ? '' : '#666666',
        fontWeight: '700',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div style={{ marginRight: '1rem' }}>
        {t('APP.CARBONESTIMATION.CARBONESTIMATION.PH_D4B1E8', {
          defaultValue: 'Indice de manufacture',
        })}
      </div>
      <Tooltip
        title={t('APP.CARBONESTIMATION.CARBONESTIMATION.PH_FEF08D', {
          defaultValue:
            "L’indice de manufacture permet de prendre en compte l’impact dû à la manufacture de l'équipement. Plus un équipement est manufacturé, plus l’impact de sa phase de fabrication est grand.",
        })}
      >
        <div>
          <img src={informationPic} alt="" />
        </div>
      </Tooltip>
    </div>
  );
};

const exemples = [
  {
    percentValueElement: 10,
    exempleKey: 'PH_6F850C',
    defaultValue: 'Exemples : Tube, plaque',
  },
  {
    percentValueElement: 25,
    exempleKey: 'PH_502DA5',
    defaultValue: 'Exemples : Câble électrique, interrupteur',
  },
  {
    percentValueElement: 50,
    exempleKey: 'PH_F0DA2E',
    defaultValue: 'Exemples : Alarme, chemin de câbles',
  },
  {
    percentValueElement: 75,
    exempleKey: 'PH_961441',
    defaultValue: 'Exemples : Moteur industriel, pompe à chaleur',
  },
];

export const ManufacturerIndexChoice = ({
  t,
  handleCheckElement,
  percentValueElementSelected,
  isLibraryScreen = false,
}) => {
  return (
    <div className="exempleWrapper">
      {exemples.map((exemple, index) => (
        <ExempleElement
          key={index}
          percentValueElement={exemple.percentValueElement}
          exempleDescription={t(
            `APP.CARBONESTIMATION.CARBONESTIMATION.${exemple.exempleKey}`,
            { defaultValue: exemple.defaultValue }
          )}
          handleCheckElement={handleCheckElement}
          percentValueElementSelected={percentValueElementSelected}
          isLibraryScreen={isLibraryScreen}
        />
      ))}
    </div>
  );
};
