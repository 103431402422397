import '../LifeCycle/NavigationAllot/navigationAllot.scss';
import './LifeCycleTabGraphTooltip.scss';
import { BoxLegendSvg } from '@nivo/legends';
import { ResponsiveSunburst } from '@nivo/sunburst';
import { SvgWrapper, ThemeProvider } from '@nivo/core';
import { seekNodeData } from '../../shared/parsingDatas';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import formatNumber, {
  LIBELLE_KG_EQ_CO2,
  getFormattedWeightNumber,
  getUnitConversion,
} from '../../services/mathematics/formatNumber';
import { useTranslation } from 'react-i18next';

let totalMetric = 0;
let metricUnit;

const ImpactSunburst = ({
  unit,
  projectArea,
  mds,
  allGroups,
  updateFormValue,
  currentProject,
  allotSteps,
  clickLevelAllot,
  copyData,
  selectedZoneName,
}) => {
  const [graphData, setGraphData] = useState(null);
  const [titleLabel, setTitleLabel] = useState('');
  const [reverseDepth, setReverseDepth] = useState(false);
  const { t } = useTranslation();

  const CenteredMetric = ({ nodes, centerX, centerY }) => {
    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '1.1vw',
          fontWeight: 600,
        }}
      >
        {metricUnit === LIBELLE_KG_EQ_CO2
          ? getFormattedWeightNumber(totalMetric)
          : formatNumber(totalMetric)}{' '}
        {metricUnit === LIBELLE_KG_EQ_CO2
          ? getUnitConversion(totalMetric)
          : metricUnit}
        {currentProject.refUnit === 'm²' ? '/m²' : ''}
      </text>
    );
  };

  const redirectToLineGraph = (data) => {
    mds.forEach((md) => {
      const allotment = allGroups.flatAllotments.find(
        (node) => data.id === node.id && node.MasterDataEquipmentId === md.id
      );

      if (allotment) {
        updateFormValue('nodeSearchValue', allotment);
        updateFormValue('searchValue', md);
      }
    });
  };

  const loopOnDepth = (parent) => {
    let total = 0;

    const result = parent.children.map((el) => {
      if (el.step !== undefined) {
        if (el.step !== 0) {
          delete el.res;
        } else {
          if (el.children.length === 0) {
            total += el.res;
          }

          for (let i = 0; i < el.children.length; i++) {
            total += Number(el.children[i].res);
          }
        }

        for (let i = 0; i < el.children.length; i++) {
          total += Number(el.children[i].res);

          delete el.children[i].children;
        }

        return el;
      }
    });

    totalMetric = currentProject.refUnit === 'total' ? total : total / projectArea;

    return result;
  };

  const splitGraphData = (data) => {
    let splitData;

    let copy = _.cloneDeep(copyData);

    if (!data) {
      splitData = loopOnDepth(copy);
    } else {
      if (data.step !== 0) {
        let parentDepth = seekNodeData(data, copy);

        splitData = loopOnDepth(parentDepth);
      } else {
        redirectToLineGraph(data);

        return;
      }
    }

    let dataset = {
      label: 'nooco',
      color: 'hsl(317, 70%, 50%)',
      children: splitData,
    };

    updateFormValue('graphData', dataset);

    if (dataset.children && dataset.children.length > 1)
      dataset.children.sort(
        (a, b) =>
          b.children.reduce((p, c) => p + c.res, 0) -
          a.children.reduce((p, c) => p + c.res, 0)
      );

    setGraphData(dataset);
  };

  // Sorry but miss time, function to find node on click of breadcrumb and display datas.
  useEffect(() => {
    if (clickLevelAllot && copyData) {
      updateFormValue('sheetToLifeCycle', null);

      let datas = [];
      let copy = _.cloneDeep(copyData);

      let data = {
        id: clickLevelAllot?.children
          ? clickLevelAllot.children[0].id
          : clickLevelAllot.id,
        uniqueId: clickLevelAllot?.children
          ? clickLevelAllot.children[0].uniqueId
          : clickLevelAllot.uniqueId,
      };

      const firstParent = seekNodeData(data, copy);

      let secondParent = null;

      if (firstParent?.ParentId) {
        data = { id: firstParent.ParentId, uniqueId: firstParent.uniqueId };

        secondParent =
          !clickLevelAllot?.children && clickLevelAllot
            ? firstParent
            : seekNodeData(data, copy);

        datas = loopOnDepth(secondParent);
      } else {
        datas = loopOnDepth(copy);
      }

      setTitleLabel(firstParent?.ParentId ? secondParent.label : null);

      let dataset = {
        label: 'nooco',
        color: 'hsl(317, 70%, 50%)',
        children: datas,
      };

      const copyAllots = [...allotSteps.filter((el) => el)];

      // delete link if exist
      for (let i = 0; i < copyAllots.length; i++) {
        delete copyAllots[i].link;
      }

      const indexToCut = copyAllots?.findIndex((el) => el.id === data.id);

      copyAllots.length =
        !copyAllots || copyAllots.length === 0
          ? []
          : !clickLevelAllot?.children
          ? indexToCut + 2
          : indexToCut + 1;
      updateFormValue('allotSteps', copyAllots);
      updateFormValue('graphData', dataset);

      setGraphData(dataset);

      updateFormValue('clickLevelAllot', null);
    }
  }, [clickLevelAllot]);

  useEffect(() => {
    if (graphData && copyData) {
      updateFormValue('sheetToLifeCycle', null);

      let datas = [];
      let copy = _.cloneDeep(copyData);

      let data = {
        id: graphData.children[0].ParentId,
        uniqueId: graphData.children[0].uniqueId,
      };

      const firstParent = seekNodeData(data, copy);

      let secondParent = null;

      if (firstParent?.ParentId) {
        data = { id: firstParent.ParentId, uniqueId: firstParent.uniqueId };

        secondParent = seekNodeData(data, copy);

        datas = loopOnDepth(secondParent);
      } else {
        datas = loopOnDepth(copy);
      }

      setTitleLabel(firstParent?.ParentId ? secondParent.label : null);

      let dataset = {
        label: 'nooco',
        color: 'hsl(317, 70%, 50%)',
        children: datas,
      };

      const copyAllots = [...allotSteps.filter((el) => el)];

      copyAllots.length =
        !copyAllots || copyAllots.length === 0 ? [] : copyAllots.length - 1;

      updateFormValue('allotSteps', copyAllots);
      updateFormValue('graphData', dataset);

      setGraphData(dataset);
    }
  }, [reverseDepth]);

  useEffect(() => {
    if (copyData && !clickLevelAllot && allotSteps?.length === 0) {
      splitGraphData();
    }
  }, [copyData, clickLevelAllot, allotSteps]);

  useEffect(() => {
    metricUnit = unit;
  }, []);

  if (!graphData) return null;

  const handleClick = (data) => {
    if (data.depth === 2) {
      updateFormValue('allotSteps', [
        ...allotSteps,
        graphData.children.find((el) => el.id === data.data.ParentId),
        data.data,
      ]);
    } else {
      updateFormValue('allotSteps', [...allotSteps, data?.data]);
    }

    setTitleLabel(data?.data.label);

    splitGraphData(data?.data);
  };

  const renderTitle = () => {
    const isParentId = graphData.children[0] && !graphData.children[0].ParentId;

    const content = isParentId
      ? `${selectedZoneName ?? 'Projet'}`
      : `${selectedZoneName ?? 'Projet'} - ${titleLabel}`;

    if (graphData.children[0] && !graphData.children[0]?.ParentId) {
      return (
        <h4
          style={{
            fontSize: window.screen.availHeight < 810 ? '0.9rem' : null,
            fontWeight: window.screen.availHeight < 950 ? 'bold' : null,
          }}
        >
          {t('APP.LIFECYCLE.IMPACTSUNBURST.LEVELSUNBURST', {
            defaultValue: 'Impact environnemental - niveau',
          })}{' '}
          {selectedZoneName
            ? selectedZoneName
            : t('APP.LIFECYCLE.IMPACTSUNBURST.PROJECT', {
                defaultValue: 'Projet',
              })}
        </h4>
      );
    }
    return (
      <h4
        style={{
          fontSize: window.screen.availHeight < 810 ? '0.9rem' : null,
          fontWeight: window.screen.availHeight < 950 ? 'bold' : null,
        }}
      >
        {t('APP.LIFECYCLE.IMPACTSUNBURST.PH_62EBF3', {
          defaultValue: 'Impact environnemental - niveau',
        })}
        {content}
      </h4>
    );
  };

  const CustomLegend = () => {
    const data = graphData.children
      .map((e) => {
        const regexp = /[0-9]+[.][0-9]*\s/gm;
        const found = e.label.match(regexp);
        const name = found && found[0] ? found[0] : e.label;

        return { id: name, label: name, color: e.color, value: 1 };
      })
      .sort((a, b) => {
        const diff = a.label.length - b.label.length;

        if (diff !== 0) return diff;

        const firstComp = a.label[0].localeCompare(b.label[0]);

        if (firstComp !== 0) return firstComp;

        const regexp = /[0-9]+/gm;
        const aFound = a.label.match(regexp);
        const bFound = b.label.match(regexp);

        if (!aFound || !bFound || aFound.length < 2 || bFound.length < 2) return 0;

        return Number(aFound[1]) - Number(bFound[1]);
      });

    return (
      <ThemeProvider>
        <SvgWrapper
          margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
          height={300}
          width={1000}
        >
          <BoxLegendSvg
            anchor="bottom-left"
            data={data}
            containerWidth={1000}
            containerHeight={300}
            height={200}
            width={1000}
            direction="column"
            itemWidth={1000}
            itemHeight={20}
            itemsSpacing={0}
            padding={0}
            symbolSize={12}
            symbolShape="circle"
          />
        </SvgWrapper>
      </ThemeProvider>
    );
  };

  return (
    <div
      key={clickLevelAllot}
      id="stacked-vertical-bars-container"
      style={{ height: 'auto', position: 'relative' }}
    >
      {graphData?.children[0]?.ParentId && (
        <ArrowBackIcon
          className="arrow-allot-steps"
          style={{
            position: 'absolute',
            top: '55px',
            left: '2px',
            cursor: 'pointer',
            zIndex: 3,
          }}
          onClick={() => {
            setReverseDepth(!reverseDepth);
          }}
        />
      )}

      {renderTitle()}
      <div style={{ position: 'absolute', bottom: 0, left: 5 }}>
        {CustomLegend()}
      </div>
      <ResponsiveSunburst
        data={graphData}
        key={titleLabel}
        id="label"
        value="res"
        cornerRadius={2}
        childColor={{ from: 'color', modifiers: [['opacity', 0.7]] }}
        borderWidth={1}
        margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
        onClick={handleClick}
        tooltip={(data) => {
          return (
            <div className="graphTooltip">
              <div className="temporalTotal">
                <strong style={{ color: data.color }}>{data.id}</strong>
                <strong style={{ color: 'black' }}>{' : '}</strong>
                <strong style={{ opacity: 0.7 }}>{data.formattedValue}</strong>
              </div>
            </div>
          );
        }}
        borderColor="white"
        motionConfig="gentle"
        colors={(d) => {
          return d.data.color;
        }}
        layers={['arcs', 'arcLabels', CenteredMetric]}
      />
    </div>
  );
};

export default ImpactSunburst;
