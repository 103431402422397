import './breadCrumb.scss';
import { CompanyDTO } from '../../../models/ProjectDTO';
import { ModalNooco } from '@photocarbone/nooco-ui';
import { PathDTO } from '../../../models/ProjectsDTO';
import { StateDTO } from '../../../models/GeneralDTO';
import { useDrop } from 'react-dnd';
import { usePatchBundle } from '../../../../hooks/useQuery/bundle';
import { useSelector } from 'react-redux';
import { useUpdateProject } from '../../../../hooks/useQuery/projects/patchProject';
import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type BreadCrumbElementProps = {
  children: JSX.Element | string;
  type:
    | 'home'
    | 'home-bundle'
    | 'company'
    | 'folder'
    | 'folder-bundle'
    | 'companyChoice';
  path?: PathDTO;
  company?: CompanyDTO;
};

export const BreadCrumArrow = () => {
  return (
    <div key={uuidv4()} style={{ margin: '8px', marginBottom: '16px' }}>
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_107_4092)">
          <path
            d="M6.58594 5.97781L1.8308 10.5859"
            stroke="#A3AED0"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M6.58594 6.02222L1.83081 1.4141"
            stroke="#A3AED0"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_107_4092">
            <rect
              width="12"
              height="8"
              fill="white"
              transform="matrix(0 -1 -1 0 8 12)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const BreadCrumbElement = ({
  children,
  type,
  path,
  company,
}: BreadCrumbElementProps) => {
  const { isExistChildrenCompanies, selectedCompanies, selectedCompanyId } =
    useSelector(
      (state: StateDTO) => state.projects.projectsListMonitoring.arianeCompany
    );
  const { isBundleScreen, folderSheetId } = useSelector(
    (state: StateDTO) => state.bundle
  );

  const [openDragErrorModal, setOpenDragErrorModal] = useState(false);
  const handleCloseDragModal = () => {
    setOpenDragErrorModal(false);
  };
  const elementCanDrop = () => {
    if (type === 'folder') {
      return true;
    }
    if (type === 'folder-bundle') {
      return true;
    }

    if (!isExistChildrenCompanies && type === 'company') {
      const lastIndexOfCOmpanies = selectedCompanies.length - 1;
      const lastCompany = selectedCompanies[lastIndexOfCOmpanies];
      const isCompanyIsLastCompany = lastCompany?.id === company?.id;

      // console.log(company);
      // console.log(selectedCompanies);

      return isCompanyIsLastCompany;
    }

    return false;
  };

  const updateBundleHook = usePatchBundle();
  const { mutate } = useUpdateProject();
  const { t } = useTranslation();
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'projectCard',
    canDrop: () => elementCanDrop(),
    drop: (item) => {
      // if path so it's a pathElement of breadcrumb
      // if company so it's a companyElement of breadcrumb.

      const pathIdOrigin = item?.project?.FolderId || folderSheetId || null;
      // if pathTarget === null it s a companyElement so rootFolder
      const pathIdTarget = path?.id || null;
      const isSameFolderTargetAndOrigin = pathIdOrigin === pathIdTarget;

      if (isSameFolderTargetAndOrigin) {
        return;
      }

      if (isBundleScreen) {
        updateBundleHook.mutate({
          CompanyId: company?.id || selectedCompanyId,
          id: item?.line?.id,
          folderSheetId: path?.id || null,
        });
      } else {
        if (
          item?.project?.Company?.id === company?.id ||
          item?.project?.CompanyId === company?.id
        ) {
          mutate({
            projectId: item.project.id,
            body: { FolderId: path?.id || null },
          });
        } else {
          setOpenDragErrorModal(true);
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  return (
    <>
      <ModalNooco
        title={t('APP.BREADCRUMB.BREADCRUMBELEMENT.PH_3D5D19', {
          defaultValue: 'Déplacement projet',
        })}
        isOpen={openDragErrorModal}
        cancelFunction={handleCloseDragModal}
        validateFunction={handleCloseDragModal}
        closeOnOutsideClick={false}
        closeIcon={true}
      >
        <div>
          {t('APP.BREADCRUMB.BREADCRUMBELEMENT.PH_9936B4', {
            defaultValue:
              "Il n'est pas possible de déplacer un projet dans une autre entreprise pour le moment",
          })}
        </div>
      </ModalNooco>
      <div
        ref={elementCanDrop() ? drop : null}
        key={uuidv4()}
        className={`breadCrumbElement ${type}`}
        style={{
          backgroundColor:
            canDrop && !isOver ? '#44DDC350' : isOver ? '#44DDC380' : '',
        }}
      >
        {children}
      </div>
    </>
  );
};
