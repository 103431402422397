import './helpRelease.scss';
import 'react-quill/dist/quill.snow.css';
import { AnimatePresence, motion } from 'framer-motion';
import { StateDTO } from '../../models/GeneralDTO';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Alert from '@material-ui/lab/Alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loader from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import NoocoCheckbox from '../../shared/NoocoCheckbox';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';

import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DeleteIcon from '@material-ui/icons/Delete';

// import { FirstVersion} from "./RichTextComposant";
import { Outlined } from '@ant-design/icons';
import {
  createNewRelease,
  fetchRelease,
  fetchReleases,
  patchRelease,
  releaseCancel,
  releaseCreateSucces,
  deleteRelease,
  releaseDisableAccordion,
  releaseEdit,
  releaseEditTemp,
  releaseEditTest,
  releaseNewEdit,
  releaseSettingsAccordion,
  releaseSettingsLoader,
  releaseToggleNew,
  releaseToggleVisible,
  releaseValidateTest,
  releaseVerif,
  releasesEdit,
  releasesEditId,
  releasesEditNumber,
  releasesUpdate,
  RELEASE_EDIT_TEST,
} from '../../../actions/helps';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import closeArrow from '../../../assets/images/help/closeArrow.svg';
import newEquipmentLogo from '../../../assets/images/help/newEquipment.svg';
import newFeatureLogo from '../../../assets/images/help/newFeature.svg';
import { getElementById } from 'domutils';
import { Assistant } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

// function Alert(props) {

//   return <MuiAlert elevation={6} variant="filled" {...props} />;

// }

// Temp variable for share QuillComponent with redux state
let articleBodyTemp = '';

// Toolbar ReactQuill configuration
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [
      {
        color: ['#1B2559', '#44DDC3', '#344750', '#71A1FD', '#9E77FD', '#CE4C33'],
      },
    ],
    [
      {
        background: [
          '#1B2559',
          '#44DDC3',
          '#344750',
          '#71A1FD',
          '#9E77FD',
          '#CE4C33',
        ],
      },
    ],
    [{ align: [] }],
    ['link', 'image'],
    ['clean'],
  ],
};

// Toolbar ReactQuill New Release
const EDITOR_MODULES = {
  toolbar: {
    container: '#toolbar',
  },
};

// Toolbar ReactQuill Edit Release
// const editModule = {
//   toolbar: {
//     container: "#toolbar2",
//   }
// };

// Component for New ReactQuill
function ReactQuillNew() {
  // const { articleBody } = useSelector((state: StateDTO) => state.help.release.editRelease);
  const [newQuillValue, setNewQuillValue] = useState('');

  return (
    <ReactQuill
      id="test"
      theme="snow"
      value={newQuillValue}
      onChange={(e) => {
        // console.log(e, i, c, d);
        // console.log('onchange')
        setNewQuillValue(e);
      }}
      modules={EDITOR_MODULES}
      readOnly={false}
      // onFocus={(e, i, s) => {
      //   console.log(e, i, s);
      // }}
      scrollingContainer=".parent-scroll"
      bounds="#scrolling-container"
      onChangeSelection={(range, source, quill) => {
        // const index = quill.getSelection().index;

        articleBodyTemp = quill?.getHTML();
      }}
    />
  );
}

// Component for Edit ReactQuill
function ReactQuillEdit() {
  // const { articleBody } = useSelector((state: StateDTO) => state.help.release.editRelease);
  // console.log(articleBodyTemp);
  // console.log(0);
  const [editQuillValue, setEditQuillValue] = useState(articleBodyTemp);

  // console.log(editQuillValue);
  console.log('1');
  return (
    <ReactQuill
      id="test2"
      theme="snow"
      value={editQuillValue || ''}
      onChange={(e) => {
        setEditQuillValue(e);
        // console.log('2');
      }}
      modules={modules}
      readOnly={false}
      scrollingContainer=".parent-scroll2"
      bounds="#scrolling-container2"
      onChangeSelection={(range, source, quill) => {
        // temp variable for each change.
        articleBodyTemp = quill.getHTML();
      }}
    />
  );
}

// Component for display ReactQuill
function ReactQuillDisplay() {
  const { articleBody } = useSelector(
    (state: StateDTO) => state.help.release.selectedRelease
  );
  // const [quillValue, setQuillValue] = useState(articleBody);

  return (
    <ReactQuill
      theme="snow"
      value={articleBody || ''}
      // onChange={(e) => {

      //   setQuillValue(e);

      // }}
      // modules={modules}
      readOnly={true}
      // onChangeSelection={(range, source, quill) => {
      //   // temp variable for each change.
      //   articleBodyTemp = quill.getHTML();

      // }}
    />
  );
}

const Release = () => {
  //TODO quill as composant?
  const {
    isOpenNew,
    isDateValid,
    isNewReleaseValidate,
    isTitleValidate,
    isFeatureValidate,
    isAddEquipentsValidate,
    isAddMaterialsValidate,
    accordionPanel,
    selectedRelease,
    loadingRelease,
    isVisible,
    isEdit,
    editRelease,
    selectedReleaseIdTemp,
  } = useSelector((state: StateDTO) => state.help.release);
  const {
    articleBody,
    newEquipmentCount,
    newMaterialCount,
    deliveryDate,
    title,
    featureCount,
  } = useSelector((state) => state.help.release.newRelease);
  const isSuperAdmin = useSelector((state) => state.projects.isSuperAdmin);
  const { userNotification, messageNotification } = useSelector(
    (state) => state.help
  );
  const dispatch = useDispatch();
  const editRef = useRef(null);

  const editIcone = require('../../../assets/images/help/bxs_edit.svg');

  const { releases } = useSelector((state) => state.help.release);
  const { t } = useTranslation();

  // Test then create new Release
  const handleValidateNewFeature = () => {
    dispatch(releaseValidateTest());
    dispatch(releaseNewEdit(articleBodyTemp, 'articleBody'));
    articleBodyTemp = '';

    if (
      isDateValid &&
      isTitleValidate &&
      isFeatureValidate &&
      isAddEquipentsValidate &&
      isAddMaterialsValidate
    ) {
      dispatch(releaseSettingsLoader(true));
      dispatch(createNewRelease());
      // dispatch(releaseToggleEdit());
      // dispatch(releaseCancel());
      // dispatch(releasesUpdate('isOpenEdit', false));
    }
  };

  // Close Accordion
  const handleClose = (event, reason) => {
    console.log(event, reason);

    if (reason === 'clickaway') {
      return;
    }

    dispatch(releaseCreateSucces(false, ''));
  };

  // Change status of accordion
  const handleChange = (id, event, isExpanded, index) => {
    const activeName = `panel${id}`;

    console.log(activeName, id, event, isExpanded);

    console.log('handle test 0');
    console.log(loadingRelease);
    dispatch(releaseCancel());
    dispatch(releaseSettingsAccordion(activeName));
    // dispatch(releaseSettingsAccordion(isExpanded ? panel : false));
    dispatch(releaseSettingsLoader(true));
    // dispatch(releasesUpdate('release', {...release, accordionPanel: (!isExpanded ? panel : false), loadingRelease: true, }));

    // dispatch(releasesUpdate('release', {...release, accordionPanel: ( false)}));
    // dispatch(releaseDisableAccordion(false));

    console.log('handle test 1');

    if (isExpanded === true) {
      console.log('handle test2');
      // dispatch(releaseSettingsAccordion(isExpanded ? panel : false));
      console.log('dans le handle', selectedReleaseIdTemp, index);
      dispatch(fetchRelease(id, activeName, index));
    } else {
      dispatch(releaseSettingsAccordion(false));
    }
  };

  // useEffect(() => {

  //   dispatch(fetchReleases());

  // }, []);

  return (
    <div className="release-container">
      {/* //TODO SnackBar change to Nooco */}
      <Snackbar
        open={userNotification}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="success">
          {messageNotification}
        </Alert>
      </Snackbar>
      {/* Add Release Button */}
      {isSuperAdmin && (
        <div
          className="release__add"
          onClick={() => {
            dispatch(releaseToggleNew());
            dispatch(releaseSettingsLoader(false));
          }}
        >
          <AddCircleOutlineIcon />
        </div>
      )}

      {/* New Release animation */}
      <AnimatePresence>
        {isOpenNew && (
          <motion.div
            className="release edit"
            initial={{ opacity: 0, y: -200 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -250 }}
          >
            <main className="release_main">
              {/* Title */}
              <form noValidate autoComplete="off">
                <TextField
                  id="outlined-basic"
                  error={!isTitleValidate && isNewReleaseValidate}
                  helperText={
                    !isTitleValidate && isNewReleaseValidate
                      ? t('APP.RELEASE.INDEX.PH_D80746', {
                          defaultValue: '1 caractère minimum',
                        })
                      : ''
                  }
                  label={t('APP.RELEASE.INDEX.PH_EC1385', {
                    defaultValue: 'Titre',
                  })}
                  variant="outlined"
                  value={title}
                  onChange={(e) => {
                    const isCorrectTitle = !(e.currentTarget.value.length < 1);

                    if (isCorrectTitle) {
                      dispatch(releaseVerif(true, 'isTitleValidate'));
                    } else {
                      dispatch(releaseVerif(false, 'isTitleValidate'));
                    }

                    dispatch(releaseNewEdit(e.currentTarget.value, 'title'));
                  }}
                />
              </form>

              {/* Date */}
              <form noValidate autoComplete="off">
                <TextField
                  id="outlined-basic"
                  label={t('APP.RELEASE.INDEX.PH_68339F', {
                    defaultValue: 'Date JJ/MM/AAAA',
                  })}
                  error={!isDateValid && isNewReleaseValidate}
                  helperText={
                    !isDateValid && isNewReleaseValidate
                      ? t('APP.RELEASE.INDEX.PH_79C2E2', {
                          defaultValue: 'format JJ/MM/AAAA',
                        })
                      : ''
                  }
                  variant="outlined"
                  value={deliveryDate}
                  onChange={(e) => {
                    const dateFR = e.currentTarget.value;

                    const regex = /^\d{2}\/\d{2}\/\d{4}$/;

                    const isCorrectDate = !(
                      e.currentTarget.value.match(regex) === null
                    );

                    console.log(dateFR);

                    if (isCorrectDate) {
                      dispatch(releaseVerif(true, 'isDateValid'));

                      dispatch(releaseNewEdit(dateFR, 'deliveryDate'));
                    } else {
                      dispatch(releaseVerif(false, 'isDateValid'));
                      dispatch(releaseNewEdit(dateFR, 'deliveryDate'));
                    }
                  }}
                />
              </form>

              {/* FeatureNumber */}
              <form noValidate autoComplete="off">
                <TextField
                  id="outlined-basic"
                  label={t('APP.RELEASE.INDEX.PH_00851D', {
                    defaultValue: 'Nb de fonctionnalités',
                  })}
                  variant="outlined"
                  helperText={
                    !isFeatureValidate && isNewReleaseValidate
                      ? t('APP.RELEASE.INDEX.PH_331C7A', {
                          defaultValue: 'Uniquement un nombre',
                        })
                      : ''
                  }
                  value={featureCount}
                  error={!isFeatureValidate && isNewReleaseValidate}
                  onChange={(e) => {
                    const isFeatureValidate = !(e.currentTarget.value.length < 1);
                    const isANumber = Number(e.currentTarget.value);

                    if (isFeatureValidate && isANumber) {
                      dispatch(releaseVerif(true, 'isFeatureValidate'));
                      dispatch(releaseNewEdit(isANumber, 'featureCount'));
                    } else {
                      dispatch(releaseVerif(false, 'isFeatureValidate'));
                      dispatch(
                        releaseNewEdit(e.currentTarget.value, 'featureCount')
                      );
                    }
                  }}
                />
              </form>

              {/* EquipmentNumber */}
              <form noValidate autoComplete="off">
                <TextField
                  id="outlined-basic"
                  label={t('APP.RELEASE.INDEX.PH_9C11D7', {
                    defaultValue: "Nb d'équipements ajoutés",
                  })}
                  error={!isAddEquipentsValidate && isNewReleaseValidate}
                  helperText={
                    !isAddEquipentsValidate && isNewReleaseValidate
                      ? t('APP.RELEASE.INDEX.PH_27BE71', {
                          defaultValue: 'Uniquement un nombre',
                        })
                      : ''
                  }
                  variant="outlined"
                  value={newEquipmentCount}
                  onChange={(e) => {
                    const isAddEquipentsValidate =
                      (e.currentTarget.value.length > 0 ||
                        Number(e.currentTarget.value) === 0) &&
                      e.currentTarget.value !== '';
                    const isANumber =
                      Number(e.currentTarget.value) ||
                      Number(e.currentTarget.value) === 0
                        ? true
                        : false;

                    console.log(Number(e.currentTarget.value) === 0);
                    console.log(Number(0));
                    console.log(isAddEquipentsValidate);
                    console.log(isANumber);

                    if (isAddEquipentsValidate && isANumber) {
                      dispatch(releaseVerif(true, 'isAddEquipentsValidate'));
                      dispatch(
                        releaseNewEdit(e.currentTarget.value, 'newEquipmentCount')
                      );
                    } else {
                      dispatch(releaseVerif(false, 'isAddEquipentsValidate'));
                      dispatch(
                        releaseNewEdit(e.currentTarget.value, 'newEquipmentCount')
                      );
                    }
                  }}
                />
              </form>

              {/* MaterialNumber */}
              <form noValidate autoComplete="off">
                <TextField
                  id="outlined-basic"
                  label={t('APP.RELEASE.INDEX.PH_A0CBCA', {
                    defaultValue: 'Nb de matériels ajoutés',
                  })}
                  error={!isAddMaterialsValidate && isNewReleaseValidate}
                  helperText={
                    !isAddMaterialsValidate && isNewReleaseValidate
                      ? t('APP.RELEASE.INDEX.PH_700671', {
                          defaultValue: 'Uniquement un nombre',
                        })
                      : ''
                  }
                  variant="outlined"
                  value={newMaterialCount}
                  onChange={(e) => {
                    const isAddMaterialsValidate =
                      (e.currentTarget.value.length > 0 ||
                        Number(e.currentTarget.value) === 0) &&
                      e.currentTarget.value !== '';
                    const isANumber =
                      Number(e.currentTarget.value) ||
                      Number(e.currentTarget.value) === 0
                        ? true
                        : false;

                    if (isAddMaterialsValidate && isANumber) {
                      dispatch(releaseVerif(true, 'isAddMaterialsValidate'));
                      dispatch(
                        releaseNewEdit(e.currentTarget.value, 'newMaterialCount')
                      );
                    } else {
                      dispatch(releaseVerif(false, 'isAddMaterialsValidate'));
                      dispatch(
                        releaseNewEdit(e.currentTarget.value, 'newMaterialCount')
                      );
                    }
                  }}
                />
              </form>
            </main>

            {/* Loading when wait for create new release */}
            {loadingRelease && isOpenNew && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Loader
                  variant="indeterminate"
                  disableShrink
                  style={{ color: '#73b7ec' }}
                  size={25}
                  thickness={4}
                />
              </div>
            )}
            <div id="toolbar" style={{ display: 'flex', alignItems: 'start' }}>
              <select className="ql-header" defaultValue="">
                <option value="1"></option>
                <option value="2"></option>
                <option value=""></option>
              </select>
              <button className="ql-bold"></button>
              <button className="ql-italic"></button>
              <button className="ql-underline"></button>
              <button className="ql-strike"></button>
              <button className="ql-blockquote"></button>
              <button className="ql-list" value="ordered" />
              <button className="ql-list" value="bullet" />
              <select className="ql-align" />
              <select className="ql-link" />
              <select className="ql-color" defaultValue="">
                <option value="#1B2559"></option>
                <option value="#44DDC3"></option>
                <option value="#344750"></option>
                <option value="#71A1FD"></option>
                <option value="#9E77FD"></option>
                <option value="#CE4C33"></option>
                <option value=""></option>
              </select>
              <select className="ql-background" defaultValue="">
                <option value="#1B2559"></option>
                <option value="#44DDC3"></option>
                <option value="#344750"></option>
                <option value="#71A1FD"></option>
                <option value="#9E77FD"></option>
                <option value="#CE4C33"></option>
                <option value=""></option>
              </select>
              <button className="ql-image"></button>
              <button className="ql-clean"></button>
            </div>
            <div className="parent-scroll">
              <div id="scrolling-container">
                <ReactQuillNew />
              </div>
            </div>

            {/* footer */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  marginRight: '1rem',
                  marginTop: '16px',
                }}
              >
                <NoocoCheckbox
                  checked={isVisible}
                  onChange={() => {
                    dispatch(releaseToggleVisible());
                  }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />

                <div>
                  {t('APP.RELEASE.INDEX.PH_4DCF2A', {
                    defaultValue: 'Coché = invisible',
                  })}
                </div>
              </div>
              <div className="featModal_footer">
                <button
                  className="featModal_footer--button"
                  type="button"
                  onClick={() => dispatch(releaseCancel())}
                >
                  {t('APP.RELEASE.INDEX.PH_175E60', {
                    defaultValue: 'Annuler',
                  })}
                </button>
                <button
                  className="featModal_footer--button"
                  type="button"
                  onClick={() => {
                    handleValidateNewFeature();
                    // handleClick();
                  }}
                >
                  {t('APP.RELEASE.INDEX.PH_136CAB', {
                    defaultValue: 'Valider',
                  })}
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Loader */}
      {releases === null && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loader
            variant="indeterminate"
            disableShrink
            style={{ color: '#73b7ec' }}
            size={25}
            thickness={4}
          />
        </div>
      )}

      {/* Releases mapping */}
      {releases?.map((release, index) => {
        // const dateEdit = {...release.deliveryDate};

        // console.log(release.deliveryDate);

        const date = new Date(release.deliveryDate);

        return (
          <>
            <div
              style={{ display: 'flex', position: 'relative' }}
              key={`edit-id${release.id}`}
            >
              {/* Icon edit */}
              {!isEdit &&
                isSuperAdmin &&
                accordionPanel === `panel${release.id}` && (
                  <>
                    <div
                      className="edit"
                      style={{ position: 'absolute', top: '0', zIndex: '10' }}
                    >
                      <img
                        src={editIcone}
                        alt="edition"
                        style={{ cursor: 'pointer', margin: '1rem' }}
                        onClick={() => {
                          dispatch(releaseSettingsLoader(true));
                          dispatch(releasesEdit());
                          dispatch(releasesEditNumber(index));
                          dispatch(releasesEditId(release.id));
                          // console.log(release);
                          dispatch(releaseEdit(release));
                          dispatch(releaseEditTest());
                          dispatch(
                            releaseEditTemp(
                              selectedRelease.articleBody,
                              'articleBody'
                            )
                          );
                          articleBodyTemp = selectedRelease.articleBody;
                          // setQuillValue(selectedRelease.articleBody);
                          // dispatch(releaseSettingsLoader(false));
                        }}
                      />

                      <DeleteIcon
                        style={{ cursor: 'pointer', color: '#CE4C33' }}
                        onClick={() => {
                          console.log(selectedRelease);
                          dispatch(deleteRelease(selectedRelease.id));
                        }}
                      />
                    </div>
                    {/* <div 
                  className='edit' 
                  style={{position: 'absolute', top: '0', right: '20px', zIndex:'10'}}
                  >
                  <img src={editIcone} alt="edition" style={{cursor: 'pointer', margin: '1rem'}} />
                  </div> */}
                  </>
                )}

              {/* Releases */}
              <div
                className="release"
                style={{ position: 'relative' }}
                key={`release-id${release.id}`}
              >
                {/* Icon edit */}
                {/* {
                !isEdit && (isSuperAdmin && accordionPanel) &&  (
                  <div className='edit' style={{position: 'absolute', top: '0', zIndex:'10'}}
                    onClick={() => {

                      
                      dispatch(releaseSettingsLoader(true));
                      dispatch(releasesEdit());
                      dispatch(releasesEditNumber(index));
                      dispatch(releasesEditId(release.id));
                      // console.log(release);
                      dispatch(releaseEdit(release));
                      dispatch(releaseEditTest());
                      dispatch(releaseEditTemp(selectedRelease.articleBody, 'articleBody'));
                      articleBodyTemp = selectedRelease.articleBody;
                      // setQuillValue(selectedRelease.articleBody);
                      // dispatch(releaseSettingsLoader(false));

                          
                    }}>
                    <img src={editIcone} alt="edition" style={{cursor: 'pointer', margin: '1rem'}} />

                  </div>
                )
              } */}
                <Accordion
                  style={{ boxShadow: 'none' }}
                  expanded={accordionPanel === `panel${release.id}`}
                  onChange={(event, isExpanded) => {
                    if (!isEdit) {
                      handleChange(release.id, event, isExpanded, index);
                    }
                  }}
                >
                  {/* Releases resume */}
                  <AccordionSummary
                    style={{ boxShadow: 'none' }}
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onChange={() => {
                      console.log('dans l accordeon', selectedReleaseIdTemp);
                      dispatch(fetchRelease(release.id));
                    }}
                  >
                    <main className="release_main">
                      {isSuperAdmin && (
                        <>
                          {release.isVisible && (
                            <div className="release_main--edit">
                              <VisibilityIcon style={{ color: '#44DDC3' }} />
                            </div>
                          )}
                          {!release.isVisible && (
                            <div className="release_main--edit">
                              <VisibilityOffIcon style={{ color: '#CE4C33' }} />
                            </div>
                          )}
                        </>
                      )}

                      <div className="release_main--title">
                        <div className="release_main--title-first">
                          {release.title}
                        </div>
                        <div className="release_main--title-date">
                          {/* TODO_TRAD : formatage ??? */}
                          {`Livrée le ${String(date.getDate()).padStart(2, '0')} ${(
                            '0' +
                            (date.getMonth() + 1)
                          ).slice(-2)} ${date.getFullYear()}`}
                        </div>
                      </div>
                      <div className="release_main--feature">
                        <img
                          src={newFeatureLogo}
                          alt=""
                          className="release_main--feature-logo"
                        />

                        {/* TODO_TRAD : plural ??? */}
                        <div>{`${release.featureCount} fonctionnalité${
                          release.featureCount > 1 ? 's' : ''
                        }`}</div>
                      </div>

                      <div className="release_main--equipment">
                        {Number(release.newMaterialCount) > 0 &&
                          Number(release.newEquipmentCount) > 0 && (
                            <img
                              src={newEquipmentLogo}
                              alt=""
                              className="release_main--equipment-logo"
                            />
                          )}

                        <div>{`${
                          Number(release.newMaterialCount) > 0
                            ? release.newMaterialCount
                            : ''
                        } ${
                          Number(release.newMaterialCount) > 1
                            ? 'nouveaux matériaux'
                            : Number(release.newMaterialCount) === 1
                            ? 'nouveau matériau'
                            : ''
                        }
                         ${
                           Number(release.newMaterialCount) > 0 &&
                           Number(release.newEquipmentCount) > 0
                             ? 'et'
                             : ''
                         }
                         ${
                           Number(release.newEquipmentCount) > 0
                             ? release.newEquipmentCount
                             : ''
                         } ${
                          Number(release.newEquipmentCount) > 1
                            ? 'équipements ajoutés'
                            : Number(release.newEquipmentCount) === 1
                            ? 'équipement ajouté'
                            : ''
                        }`}</div>
                      </div>

                      <div className="release_main--consult">
                        Consulter la note de version
                        <img
                          src={closeArrow}
                          alt=""
                          className={
                            accordionPanel === `panel${release.id}`
                              ? 'release_main--consult-open'
                              : 'release_main--consult-close'
                          }
                        />
                      </div>
                    </main>
                  </AccordionSummary>

                  {/* Releases detail */}
                  {!isEdit && (
                    <AccordionDetails style={{ minHeight: '150px' }}>
                      {loadingRelease && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            position: 'absolute',
                          }}
                        >
                          <Loader
                            variant="indeterminate"
                            disableShrink
                            style={{ color: '#73b7ec' }}
                            size={25}
                            thickness={4}
                          />
                        </div>
                      )}

                      {
                        // selectedRelease.articleBody &&
                        !loadingRelease && (
                          // <ReactQuill
                          //   theme="snow"
                          //   defaultValue=""
                          //   modules={modules}
                          //   value={selectedRelease.articleBody || ""}
                          //   readOnly={true}
                          // />
                          <ReactQuillDisplay />
                        )
                        // <div>d</div>
                      }
                    </AccordionDetails>
                  )}

                  {/* Releases Edit*/}

                  {/* <AnimatePresence> */}
                  {isEdit && (
                    <div className="release edit">
                      {!loadingRelease && (
                        <main className="release_main">
                          {/* Title */}
                          <form noValidate autoComplete="off">
                            <TextField
                              id="outlined-basic"
                              error={!isTitleValidate && isNewReleaseValidate}
                              helperText={
                                !isTitleValidate && isNewReleaseValidate
                                  ? '1 caractère minimum'
                                  : ''
                              }
                              label="Titre"
                              variant="outlined"
                              value={editRelease.title}
                              onChange={(e) => {
                                const isCorrectTitle = !(
                                  e.currentTarget.value.length < 1
                                );

                                if (isCorrectTitle) {
                                  dispatch(releaseVerif(true, 'isTitleValidate'));
                                } else {
                                  dispatch(releaseVerif(false, 'isTitleValidate'));
                                }

                                dispatch(
                                  releaseEditTemp(e.currentTarget.value, 'title')
                                );
                              }}
                            />
                          </form>

                          {/* Date */}
                          <form noValidate autoComplete="off">
                            <TextField
                              id="outlined-basic"
                              label="Date JJ/MM/AAAA"
                              error={!isDateValid && isNewReleaseValidate}
                              helperText={
                                !isDateValid && isNewReleaseValidate
                                  ? 'format JJ/MM/AAAA'
                                  : ''
                              }
                              variant="outlined"
                              value={editRelease.deliveryDate}
                              onChange={(e) => {
                                const dateFR = e.currentTarget.value;

                                const regex = /^\d{2}\/\d{2}\/\d{4}$/;

                                const isCorrectDate = !(
                                  e.currentTarget.value.match(regex) === null
                                );

                                if (isCorrectDate) {
                                  dispatch(releaseVerif(true, 'isDateValid'));

                                  dispatch(releaseEditTemp(dateFR, 'deliveryDate'));
                                } else {
                                  dispatch(releaseVerif(false, 'isDateValid'));
                                  dispatch(releaseEditTemp(dateFR, 'deliveryDate'));
                                }
                              }}
                            />
                          </form>

                          {/* FeatureNumber */}
                          <form noValidate autoComplete="off">
                            <TextField
                              id="outlined-basic"
                              label="Nb de fonctionnalités"
                              variant="outlined"
                              helperText={
                                !isFeatureValidate && isNewReleaseValidate
                                  ? 'Uniquement un nombre'
                                  : ''
                              }
                              value={editRelease.featureCount}
                              error={!isFeatureValidate && isNewReleaseValidate}
                              onChange={(e) => {
                                const isFeatureValidate = !(
                                  e.currentTarget.value.length < 1
                                );
                                const isANumber = Number(e.currentTarget.value);

                                if (isFeatureValidate && isANumber) {
                                  dispatch(releaseVerif(true, 'isFeatureValidate'));
                                  dispatch(
                                    releaseEditTemp(isANumber, 'featureCount')
                                  );
                                } else {
                                  dispatch(releaseVerif(false, 'isFeatureValidate'));
                                  dispatch(
                                    releaseEditTemp(
                                      e.currentTarget.value,
                                      'featureCount'
                                    )
                                  );
                                }
                              }}
                            />
                          </form>

                          {/* EquipmentNumber */}
                          <form noValidate autoComplete="off">
                            <TextField
                              id="outlined-basic"
                              label="Nb d'équipements ajoutés"
                              error={!isAddEquipentsValidate && isNewReleaseValidate}
                              helperText={
                                !isAddEquipentsValidate && isNewReleaseValidate
                                  ? 'Uniquement un nombre'
                                  : ''
                              }
                              variant="outlined"
                              value={editRelease.newEquipmentCount}
                              onChange={(e) => {
                                const isAddEquipentsValidate =
                                  (e.currentTarget.value.length > 0 ||
                                    Number(e.currentTarget.value) === 0) &&
                                  e.currentTarget.value !== '';
                                const isANumber =
                                  Number(e.currentTarget.value) ||
                                  Number(e.currentTarget.value) === 0
                                    ? true
                                    : false;

                                if (isAddEquipentsValidate && isANumber) {
                                  dispatch(
                                    releaseVerif(true, 'isAddEquipentsValidate')
                                  );
                                  dispatch(
                                    releaseEditTemp(
                                      e.currentTarget.value,
                                      'newEquipmentCount'
                                    )
                                  );
                                } else {
                                  dispatch(
                                    releaseVerif(false, 'isAddEquipentsValidate')
                                  );
                                  dispatch(
                                    releaseEditTemp(
                                      e.currentTarget.value,
                                      'newEquipmentCount'
                                    )
                                  );
                                }
                              }}
                            />
                          </form>

                          {/* MaterialNumber */}
                          <form noValidate autoComplete="off">
                            <TextField
                              id="outlined-basic"
                              label="Nb de matériels ajoutés"
                              error={!isAddMaterialsValidate && isNewReleaseValidate}
                              helperText={
                                !isAddMaterialsValidate && isNewReleaseValidate
                                  ? 'Uniquement un nombre'
                                  : ''
                              }
                              variant="outlined"
                              value={editRelease.newMaterialCount}
                              onChange={(e) => {
                                const isAddMaterialsValidate =
                                  (e.currentTarget.value.length > 0 ||
                                    Number(e.currentTarget.value) === 0) &&
                                  e.currentTarget.value !== '';
                                const isANumber =
                                  Number(e.currentTarget.value) ||
                                  Number(e.currentTarget.value) === 0
                                    ? true
                                    : false;

                                if (isAddMaterialsValidate && isANumber) {
                                  dispatch(
                                    releaseVerif(true, 'isAddMaterialsValidate')
                                  );
                                  dispatch(
                                    releaseEditTemp(
                                      e.currentTarget.value,
                                      'newMaterialCount'
                                    )
                                  );
                                } else {
                                  dispatch(
                                    releaseVerif(false, 'isAddMaterialsValidate')
                                  );
                                  dispatch(
                                    releaseEditTemp(
                                      e.currentTarget.value,
                                      'newMaterialCount'
                                    )
                                  );
                                }
                              }}
                            />
                          </form>
                        </main>
                      )}

                      {/* articleBody */}
                      {/* //TODO reactivate when articleBody ok */}

                      {loadingRelease && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            position: 'absolute',
                          }}
                        >
                          <Loader
                            variant="indeterminate"
                            disableShrink
                            style={{ color: '#73b7ec' }}
                            size={25}
                            thickness={4}
                          />
                        </div>
                      )}

                      {editRelease?.articleBody && (
                        // <ReactQuill
                        //   theme="snow"
                        //   modules={modules}
                        //   defaultValue=""
                        //   value={editRelease.articleBody}
                        //   onChange={(value) => {

                        //     dispatch(releaseEditTemp(value, 'articleBody'));

                        //   }}
                        //   readOnly={false}
                        // >
                        // </ReactQuill >
                        // <FirstVersion value={editRelease.articleBody}/>
                        // <div>
                        //   <div id="toolbar2" style={{display:'flex', alignItems: 'start'}}>
                        //     <select className="ql-header" defaultValue="">
                        //       <option value="1"></option>
                        //       <option value="2"></option>
                        //       <option value=""></option>
                        //     </select>
                        //     <button className="ql-bold"></button>
                        //     <button className="ql-italic"></button>
                        //     <button className="ql-underline"></button>
                        //     <button className="ql-strike"></button>
                        //     <button className="ql-blockquote"></button>
                        //     <button className="ql-list" value="ordered" />
                        //     <button className="ql-list" value="bullet" />
                        //     <select className="ql-align" />
                        //     <select className="ql-link" />
                        //     <select className="ql-color" defaultValue="">
                        //       <option value="#1B2559"></option>
                        //       <option value="#44DDC3"></option>
                        //       <option value="#344750"></option>
                        //       <option value="#71A1FD"></option>
                        //       <option value="#9E77FD"></option>
                        //       <option value="#CE4C33"></option>
                        //       <option value=""></option>
                        //     </select>
                        //     <select className="ql-background" defaultValue="">
                        //     <option value="#1B2559"></option>
                        //       <option value="#44DDC3"></option>
                        //       <option value="#344750"></option>
                        //       <option value="#71A1FD"></option>
                        //       <option value="#9E77FD"></option>
                        //       <option value="#CE4C33"></option>
                        //       <option value=""></option>
                        //     </select>
                        //     <button className="ql-image"></button>
                        //     <button className="ql-clean"></button>
                        //   </div>
                        <div className="parent-scroll2">
                          <div ref={editRef} id="scrolling-container2">
                            <ReactQuillEdit />
                          </div>
                        </div>
                        // </div>
                        //  <ReactQuillEdit />
                      )}

                      {/* footer */}
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <div
                          style={{
                            width: '50%',
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                            marginRight: '1rem',
                            marginTop: '16px',
                          }}
                        >
                          <NoocoCheckbox
                            checked={!editRelease.isVisible}
                            onChange={() => {
                              // dispatch(releaseToggleVisible());
                              dispatch(
                                releaseEditTemp(!editRelease.isVisible, 'isVisible')
                              );
                            }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          <div>Coché = invisible</div>
                        </div>
                        <div className="featModal_footer">
                          <button
                            className="featModal_footer--button"
                            type="button"
                            onClick={() => dispatch(releaseCancel())}
                          >
                            Annuler
                          </button>

                          <button
                            className="featModal_footer--button"
                            type="button"
                            onClick={
                              () => {
                                // console.log(articleBodyTemp);
                                // console.log(articleBodyTemp);

                                dispatch(
                                  releaseEditTemp(articleBodyTemp, 'articleBody')
                                );
                                dispatch(releaseValidateTest());

                                if (
                                  isDateValid &&
                                  isTitleValidate &&
                                  isFeatureValidate &&
                                  isAddEquipentsValidate &&
                                  isAddMaterialsValidate
                                ) {
                                  dispatch(releaseSettingsLoader(true));
                                  dispatch(patchRelease());
                                }
                              }
                              // inputRef.current.focus();
                            }
                          >
                            Valider
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* </AnimatePresence> */}
                </Accordion>
              </div>
            </div>

            {/* // EditRelease */}

            {/* // End EditRelease */}
          </>
        );
      })}
    </div>
  );
};

export default Release;
