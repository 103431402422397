import './quickAddBarEquipment.scss';
import {
  ALLOTMENT_IDS,
  REGULATORY_PROJECTS,
  TEMPLATE_TYPE_NAME,
} from '../../globalVariable/typeProject';
import {
  getSheetType,
  returnLotNoocoForSheetSelected,
  returnSearchGroupOptions,
} from '../../shared/utilsFunction';
import { AllotmentNodeDTO } from '../../models/ProjectDTO';
import { Dropdown, Input, SearchGroup, DatePicker } from '@photocarbone/nooco-ui';
import { MasterDataEquipmentDTO } from '../../models/SimulatorDTO';
import { CurrentScreen, StateDTO } from '../../models/GeneralDTO';
import { Tooltip } from '@material-ui/core';
import {
  checkIfForfaitBlocksEquipmentCreation,
  checkIfNodeCanBeAddedInProject,
  filterNodesByEnergyMatchingCurrentAllotment,
  findAllotmentNodesForNewBundle,
  findParentNode,
  getTreatmentModeId,
  getCertificationKeyNode,
  handleAllotmentNodes,
  isProjectSingleZoneOnly,
  returnSearchBarOptionsFiltered,
} from '../utils';
import { createStructuredZonesForDropdown } from '../DropdownUiUtils';
import { commaToDot, isNumHigherZero, isNumOrNull } from '../utilsFunction';
import { updateFormValue } from '../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetCaracsByAllotmentNodeId,
  usePostEquipmentInProject,
} from '../../../hooks/useQuery/equipments';
import { useLocalStorage } from 'react-use';
import API from '../../services/API';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Alert from '@mui/material/Alert';
import BreadcrumbModal from '../../../containers/BreadcrumbModal';
import CaracPopover, {
  CaracValuesDTO,
  getCaracState,
} from '../CaracPopover/CaracPopover';
import CloseIcon from '@mui/icons-material/Close';
import NoocoCheckbox from '../NoocoCheckbox';
import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import informationIcon from '../../../assets/images/equipments/information.png';
import { UnamortizedValuesDTO } from '../CaracPopover/UnamortizedCaracDisplay/UnamortizedCaracDisplay';
import { useTranslation } from 'react-i18next';
import { SEARCH_GROUP_OPTION_VALUES } from '../../globalVariable/searchGroupType';
import { getAntdLocale } from '../getAntdLocale';
import DatePickerSelector from './DatePickerSelector';
import dayjs from 'dayjs';

export const returnFormatLifeSpan = (lifeSpan, format = 'DD/MM/YYYY') => {
  if (typeof lifeSpan !== 'string') {
    return lifeSpan.map((date) => ({
      value: dayjs(date, format).format(),
      inclusive: true,
    }));
  } else {
    return [{ value: dayjs(lifeSpan, format).format(), inclusive: true }, null];
  }
};

export const getStatusAdjustableFluid = (
  masterdata,
  currentProjectTemplate,
  selectedSheet
): boolean => {
  if (!masterdata?.hasFluid) {
    return false;
  }

  if (REGULATORY_PROJECTS.includes(currentProjectTemplate)) {
    return false;
  }

  const sheetNotAdjustableFluid = ['PEP', 'FDES'];

  if (!sheetNotAdjustableFluid.includes(selectedSheet?.type_fiche)) {
    return true;
  }

  return false;
};

export enum SelectedSearchGroupOptionsLabel {
  Equipements = 'Equipements',
  INIES = 'INIES',
  Eau = 'Eau',
  Energie = 'Energie',
  'Déchets' = 'Déchets',
  'Transports' = 'Transports',
  Bibliotheque = 'Bibliotheque',
}

export const checkIdBundleCanBeAddedInProject = (
  bundle,
  currentProject,
  allGroups
) => {
  let bundleCanBeAdded = 0;

  bundle.AllotmentNodes.map((node) => {
    if (
      !checkIfNodeCanBeAddedInProject(node, currentProject, allGroups.flatAllotments)
    ) {
      bundleCanBeAdded += 1;
    }
  });

  return !Boolean(bundleCanBeAdded);
};

export const checkIfConfiguredSheetCanBeAdded = (
  configuredSheet,
  currentProject,
  allGroups
) => {
  let nodesCanBeAddedInProject = 0;

  configuredSheet.AllotmentNodes.map((node) => {
    if (
      checkIfNodeCanBeAddedInProject(node, currentProject, allGroups.flatAllotments)
    ) {
      nodesCanBeAddedInProject += 1;
    }
  });

  return Boolean(nodesCanBeAddedInProject);
};

type QuickAddBarEquipmentProps = {
  setIsQuickAddBarListEquipmentsOpen?: (arg: boolean) => void;
};
const QuickAddBarEquipment: React.FC<QuickAddBarEquipmentProps> = ({
  setIsQuickAddBarListEquipmentsOpen,
}) => {
  const apiNooco = new API();

  const dispatch = useDispatch();
  const {
    allGroups,
    nodesAvailableForProjectType,
    bundleOptions,
    modeledProductOptions,
    configuredSheetOptions,
    certifiedAllotments,
    currentProject,
    currentAllotment,
    user,
  } = useSelector((state: StateDTO) => state.projects);
  const { zones } = useSelector((state: StateDTO) => state.projects.parsedProject);
  const [searchValue, setSearchValue] = useState<MasterDataEquipmentDTO | null>(
    null
  );
  const [nodeSearchValue, setNodeSearchValue] = useState<AllotmentNodeDTO | null>(
    null
  );
  const [selectedSheetObj, setSelectedSheetObj] = useState(null);
  const [allAllotmentNodes, setAllAllotmentNodes] = useState([]);
  const [re2020lot, setRe2020lot] = useState<AllotmentNodeDTO | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [quantity, setQuantity] = useState<number | string | null>(null);
  const [lifeSpan, setLifeSpan] = useState<string | string[] | null>(null);
  const [selectedDestinationZoneId, setSelectedDestinationZoneId] = useLocalStorage(
    `selectedDestinationZoneId-${currentProject.id}`,
    zones.find((zone) => zone.type === 'zone')?.id || null
  );
  const [sheetSelected, setSheetSelected] = useState(null);

  const [selectedSearchGroupType, setSelectedSearchGroupType] =
    useLocalStorage<SelectedSearchGroupOptionsLabel>(
      `selectedSearchGroupType-${user.userId}`,
      SelectedSearchGroupOptionsLabel.Equipements
    );
  const [caracValues, setCaracValues] = useState<CaracValuesDTO>({
    xAxis: null,
    discrete: null,
    secondDiscrete: null,
    photovoltaicSelfConsumption: null,
    photovoltaicAnnualProduction: null,
    hasFluid: false,
    isDefaultFluid: true,
    fluidId: null,
    fluidChargeQuantity: null,
  });
  const [isUnamortizedChecked, setIsUnamortizedChecked] = useState<boolean>(false);
  const [unamortizedValues, setUnamortizedValues] = useState<UnamortizedValuesDTO>({
    residualLifespan: null, //only positive number accepted. lifespan in year.
    isResidualLifespanUnknowChecked: false,
    treatmentMode: null,
  });
  const [AllotmendNodeId, setAllotmendNodeId] = useState<number | null>(null);
  const [isPhotovoltaicEquipmentSelected, setIsPhotovoltaicEquipmentSelected] =
    useState<boolean>(false);

  const isBundleSelected = sheetSelected?.type_fiche === 'bundle';
  const isIniesSelected =
    selectedSearchGroupType === SelectedSearchGroupOptionsLabel.INIES;
  const isModeledProductSelected = sheetSelected?.type_fiche === 'custom_user';
  const isConfiguredSheetSelected = sheetSelected?.type_fiche === 'FC';

  const isGlobalMonitoringProject =
    currentProject.templateType === TEMPLATE_TYPE_NAME.GLOBAL_MONITORING;

  const globalMonitoringSearchGroupNeedDeposit = [
    SelectedSearchGroupOptionsLabel.Equipements,
    SelectedSearchGroupOptionsLabel.INIES,
    SelectedSearchGroupOptionsLabel.Bibliotheque,
  ];

  const conditionToDisplayAmortizedCheckboxGlobalMonitoring =
    globalMonitoringSearchGroupNeedDeposit.includes(selectedSearchGroupType) &&
    isGlobalMonitoringProject;
  const conditionToDisplayAmortizedCheckboxBBCA =
    selectedSearchGroupType === SelectedSearchGroupOptionsLabel.Equipements &&
    currentProject.templateType === TEMPLATE_TYPE_NAME.BBCA;

  const searchGroupOptions = returnSearchGroupOptions(currentProject);

  const { data: caracsFromGetCaracs } = useGetCaracsByAllotmentNodeId({
    allotmentNodeId: AllotmendNodeId,
  });
  const [certificationKeyNode, setCertificationKeyNode] = useState<string | null>(
    currentAllotment ? getCertificationKeyNode(currentAllotment.id) : null
  );
  const { t } = useTranslation();
  const [
    filteredNodesAvailableForProjectType,
    setFilteredNodesAvailableForProjectType,
  ] = useState(nodesAvailableForProjectType);
  const {
    mutate: mutatePostEquipmentInProject,
    isLoading: isLoadingPostEquipmentInProject,
    isSuccess: isSuccessPostEquipmentInProject,
  } = usePostEquipmentInProject();

  const setIniesDefaultCaracValues = ({ iniesSheet, newSearchValue }) => {
    if (
      !iniesSheet?.SheetCaracs ||
      !iniesSheet?.SheetCaracs?.length ||
      !iniesSheet?.id_inies ||
      !isIniesSelected
    ) {
      return;
    }

    let newCaracValues = {
      ...caracValues,
    };

    const selectedIniesSheet = iniesSheet.SheetCaracs;

    if (newSearchValue?.xAxisId) {
      newCaracValues.xAxis = selectedIniesSheet?.find(
        (sheet) => sheet.CaracId === newSearchValue.xAxisId
      )?.value[0];
    }

    if (newSearchValue?.discreteId) {
      newCaracValues.discrete = selectedIniesSheet?.find(
        (sheet) => sheet.CaracId === newSearchValue.discreteId
      )?.value[0];
    }
    if (newSearchValue?.secondDiscreteId) {
      newCaracValues.secondDiscrete =
        selectedIniesSheet?.find(
          (sheet) => sheet.CaracId === newSearchValue.secondDiscreteId
        )?.value[0] || 'Aucun';
    }

    setCaracValues(newCaracValues);
  };
  const resetUserInputs = () => {
    setSearchValue(null);
    setNodeSearchValue(null);
    setSelectedSheetObj(null);
    setRe2020lot(null);
    setQuantity('');
    setAllAllotmentNodes([]);
    setAllotmendNodeId(null);
    setCaracValues({
      xAxis: '',
      discrete: null,
      secondDiscrete: null,
      photovoltaicSelfConsumption: null,
      photovoltaicAnnualProduction: null,
      hasFluid: false,
      isDefaultFluid: true,
      fluidId: null,
      fluidChargeQuantity: null,
    });
    setIsUnamortizedChecked(false);
    setUnamortizedValues({
      residualLifespan: '',
      isResidualLifespanUnknowChecked: false,
      treatmentMode: null,
    });
  };
  const isQuantityValid = (): boolean => {
    return Boolean(quantity) && isNumHigherZero(quantity);
  };

  const returnCaracsFromUser = (): {
    name: string;
    value: string | number | [string];
  }[] => {
    let caracs: { name: string; value: string | number | [string] }[] = [];

    const caracsInfo = [
      {
        key: 'xAxisId',
        value: caracValues.xAxis,
      },
      {
        key: 'discreteId',
        value: caracValues.discrete,
      },
      {
        key: 'secondDiscreteId',
        value: caracValues.secondDiscrete,
      },
    ];

    caracsInfo.forEach((caracInfo) => {
      if (searchValue?.[caracInfo?.key] && caracInfo.value) {
        const carac = caracsFromGetCaracs?.caracs.find(
          (carac) => carac.id === searchValue[caracInfo.key]
        );

        caracs.push({
          id: carac.id,
          name: carac.name,
          value: [caracInfo.value],
        });
      }
    });

    if (isPhotovoltaicEquipmentSelected) {
      const photovoltaicSelfConsumptionCarac = caracsFromGetCaracs?.caracs.find(
        (carac) => carac.name.includes('Eef_Prod_AC')
      );
      const photovoltaicAnnualProductionCarac = caracsFromGetCaracs?.caracs.find(
        (carac) =>
          carac.name.includes('Eef_Prod') && !carac.name.includes('Eef_Prod_AC')
      );

      caracs.push({
        id: photovoltaicSelfConsumptionCarac.id,
        name: photovoltaicSelfConsumptionCarac.name,
        value: caracValues.photovoltaicSelfConsumption,
      });
      caracs.push({
        id: photovoltaicAnnualProductionCarac.id,
        name: photovoltaicAnnualProductionCarac.name,
        value: caracValues.photovoltaicAnnualProduction,
      });
    }

    return caracs;
  };

  const areEquipmentInputsValid = (): boolean => {
    if (
      !selectedDestinationZoneId ||
      !isQuantityValid() ||
      getCaracState({
        caracsFromGetCaracs,
        caracValues,
        isPhotovoltaicEquipmentSelected,
        isUnamortizedChecked,
        unamortizedValues,
      }) === 'error' ||
      (!lifeSpan && isGlobalMonitoringProject)
    ) {
      return false;
    }

    if (isPhotovoltaicEquipmentSelected) {
      if (
        !isNumHigherZero(caracValues.photovoltaicSelfConsumption) ||
        !isNumHigherZero(caracValues.photovoltaicAnnualProduction)
      ) {
        return false;
      }
    }

    if (isBundleSelected || isModeledProductSelected || isConfiguredSheetSelected) {
      return Boolean(selectedSheetObj);
    } else if (!searchValue || !nodeSearchValue || !selectedDestinationZoneId) {
      return false;
    }

    return true;
  };

  const searchBarOptionsFiltered = returnSearchBarOptionsFiltered({
    allAllotments: allGroups.flatAllotments,
    allotmentsAvailable: filteredNodesAvailableForProjectType,
    iniesOptions: [],
    bundleOptions: bundleOptions?.filter((bundle) =>
      checkIdBundleCanBeAddedInProject(bundle, currentProject, allGroups)
    ),
    modeledProductOptions,
    configuredSheetOptions: configuredSheetOptions?.filter((configuredSheet) =>
      checkIfConfiguredSheetCanBeAdded(configuredSheet, currentProject, allGroups)
    ),
    currentAllotment,
  });

  const selectedUnit = (): string | null => {
    if (isBundleSelected || isModeledProductSelected || isConfiguredSheetSelected) {
      return selectedSheetObj?.unit || selectedSheetObj.Unit?.name;
    }

    if (searchValue) {
      return searchValue?.unit || null;
    }

    return '';
  };

  useEffect(() => {
    if (isProjectSingleZoneOnly(zones)) {
      setSelectedDestinationZoneId(
        zones.find((zone) => zone.type === 'zone')?.id || null
      );
    }
  }, []);

  useEffect(() => {
    if (searchValue?.hasFluid) {
      setCaracValues((prevState) => ({ ...prevState, hasFluid: true }));
    }

    if (searchValue && !isIniesSelected) {
      const allotmentNodesOfSearchValue = handleAllotmentNodes(
        searchValue,
        currentAllotment,
        allGroups.flatAllotments,
        currentProject
      );

      let isNodeInParentNodes = false;

      if (!allotmentNodesOfSearchValue || allotmentNodesOfSearchValue.length == 0) {
        return;
      }

      const firstNode = allotmentNodesOfSearchValue[0].details.find(
        (el) => el.MasterDataEquipmentId && el.AllotmentId === ALLOTMENT_IDS.NOOCO
      );

      if (currentAllotment?.id === ALLOTMENT_IDS.NOOCO) {
        // If length > 1 need to select the first node of the first parent node
        if (allotmentNodesOfSearchValue.length > 1 && nodeSearchValue) {
          allotmentNodesOfSearchValue.map((allotmentNode) => {
            if (
              allotmentNode.details.find(
                (allotmentNodeByLevel) =>
                  allotmentNodeByLevel.id === nodeSearchValue.id
              )
            ) {
              isNodeInParentNodes = true;
            }
          });

          if (!isNodeInParentNodes) {
            setNodeSearchValue(firstNode);
          }
        } else {
          if (allotmentNodesOfSearchValue[0]) {
            setNodeSearchValue(firstNode);
          }
        }
      }

      setAllAllotmentNodes(
        allotmentNodesOfSearchValue.filter((el) => !el.isObsolete)
      );

      if (!isBundleSelected) {
        setAllotmendNodeId(firstNode?.id || null);
      }
    } else if (allAllotmentNodes && !searchValue) {
      setAllAllotmentNodes([]);
      setAllotmendNodeId(null);
    }
  }, [searchValue]);

  useEffect(() => {
    setIsPhotovoltaicEquipmentSelected(
      Boolean(
        caracsFromGetCaracs?.caracs?.find((carac) =>
          carac.name.includes('Eef_Prod_AC')
        )
      )
    );
  }, [caracsFromGetCaracs]);

  useEffect(() => {
    if (isSuccessPostEquipmentInProject) {
      resetUserInputs();
    }
  }, [isSuccessPostEquipmentInProject]);

  const handleOnChangeAddEquipmentSearchBar = (value) => {
    if (value?.type_fiche === 'custom_user') {
      setSelectedSheetObj(value);
    } else if (value?.type_fiche === 'bundle' || value?.type_fiche === 'FC') {
      if (!value.AllotmentNodes) {
        value = findAllotmentNodesForNewBundle(value);
      }

      setSelectedSheetObj(value);

      return;
    } else if (isIniesSelected) {
      const iniesSheet = value;

      setSelectedSheetObj(iniesSheet);
      let lotsAvailable = returnLotNoocoForSheetSelected(
        value.AllotmentNodes,
        currentProject.Lots,
        allGroups.flatAllotments
      );
      let node =
        lotsAvailable[0]?.details.find((node) => node.MasterDataEquipmentId) || null;
      const newSearchValue =
        allGroups.type.find(
          (masterdata) => masterdata.id === node?.MasterDataEquipmentId
        ) || null;

      setNodeSearchValue(node);
      setSearchValue(newSearchValue);
      setAllotmendNodeId(node?.id || null);
      setAllAllotmentNodes(lotsAvailable);
      setIniesDefaultCaracValues({ iniesSheet, newSearchValue });

      return;
    } else if (typeof value === 'number') {
      let node =
        nodesAvailableForProjectType.find((node) => node.id === value) || null;

      setNodeSearchValue(node);
      setSearchValue(
        allGroups.type.find(
          (masterdata) => masterdata.id === node?.MasterDataEquipmentId
        ) || null
      );

      return;
    } else {
      resetUserInputs();

      return;
    }
  };

  const handleChangeAllotmentNode = (value, type) => {
    if (type === 'Nooco') {
      let nodeSelected =
        allAllotmentNodes
          ?.find((parentNode) => parentNode.name === value)
          .details.find((node) => node.MasterDataEquipmentId) || null;

      setNodeSearchValue(nodeSelected || null);
      setAllotmendNodeId(nodeSelected?.id || null);
    } else {
      setRe2020lot(
        allGroups.flatAllotments.find(
          (node) => node.name === value && node.AllotmentId === currentAllotment.id
        ) || null
      );
    }
  };

  const getAllotmentNodesToPost = () => {
    return [nodeSearchValue, re2020lot ? re2020lot : null].filter(
      (el) => el !== null
    );
  };

  const handleCreateEquipment = async () => {
    // bundle && modeledProduct && configuredSheet
    if (
      selectedSheetObj &&
      (isBundleSelected || isModeledProductSelected || isConfiguredSheetSelected)
    ) {
      const body = {
        quantity: quantity,
        SheetId: selectedSheetObj.id,
        lifespan: lifeSpan ? returnFormatLifeSpan(lifeSpan) : null,
        type_fiche: isBundleSelected
          ? 'bundle'
          : isConfiguredSheetSelected
          ? 'FC'
          : 'custom_user',
        type: isBundleSelected ? 'bundle' : 'SHEET_FROM_LIB',
        ZoneId: selectedDestinationZoneId,
        returnProject: true,
      };
      if (isModeledProductSelected) {
        body['AllotmentNodes'] =
          modeledProductOptions?.find(
            (option) => option?.id === selectedSheetObj?.id
          )?.AllotmentNodes || [];
      }
      if (isConfiguredSheetSelected) {
        body['AllotmentNodes'] = selectedSheetObj?.AllotmentNodes;
      }

      mutatePostEquipmentInProject(body);
    }

    const currentZone = zones.find((zone) => zone.id === selectedDestinationZoneId);
    const [forfaitBlocksEquipmentCreation, errorMessage] =
      checkIfForfaitBlocksEquipmentCreation(
        currentZone.ProjectSheets,
        currentProject,
        currentZone,
        zones,
        nodeSearchValue,
        allGroups.flatAllotments,
        re2020lot
      );
    if (forfaitBlocksEquipmentCreation) {
      setSnackbarMessage(errorMessage);
      return;
    }

    // Inies
    if (selectedSheetObj && isIniesSelected) {
      const body = {
        id: nodeSearchValue.id,
        lifespan: lifeSpan ? returnFormatLifeSpan(lifeSpan) : null,
        quantity: quantity,
        type: 'INIES',
        SheetId: selectedSheetObj.id,
        AllotmentNodes: getAllotmentNodesToPost(),
        ZoneId: selectedDestinationZoneId,
        caracs: returnCaracsFromUser(),
        fluid:
          !caracValues.hasFluid || caracValues.isDefaultFluid
            ? null
            : {
                selectedFluid: caracValues.fluidId,
                fluidQuantity: caracValues.fluidChargeQuantity,
              },
        returnProject: true,
      };

      mutatePostEquipmentInProject(body);
    }

    // equipments including water and energy
    if (searchValue && nodeSearchValue && !isBundleSelected && !isIniesSelected) {
      const sheetType = getSheetType(nodeSearchValue, allGroups.flatAllotments);

      const body = {
        id: nodeSearchValue.id,
        caracs: returnCaracsFromUser(),
        AllotmentNodes: getAllotmentNodesToPost(),
        ZoneId: selectedDestinationZoneId,
        quantity: quantity,
        type_fiche: sheetType,
        lifespan: lifeSpan ? returnFormatLifeSpan(lifeSpan) : null,
        UrbanNetwokId: null,
        fluid:
          !caracValues.hasFluid || caracValues.isDefaultFluid
            ? null
            : {
                selectedFluid: caracValues.fluidId,
                fluidQuantity: caracValues.fluidChargeQuantity,
              },
        returnProject: true,
      };

      if (isUnamortizedChecked) {
        body['options'] = {
          residualDdv: Number(commaToDot(unamortizedValues.residualLifespan)),
          unknownResidualDdv: unamortizedValues.isResidualLifespanUnknowChecked,
          treatmentMode: unamortizedValues.treatmentMode,
        };
      }
      mutatePostEquipmentInProject(body);
    }
  };

  return (
    <div className="quick-addBar-equipment">
      <Snackbar
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage(null)}
        open={snackbarMessage !== null}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarMessage(null)} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {/* user inputs */}
      <div className="left-render">
        <div className="searchGroup-wrapper">
          {/* For advanced search in searchGroup */}
          <BreadcrumbModal addEquipmentScreen={true} />
          <SearchGroup
            t={t}
            companyId={currentProject.CompanyId}
            searchBarOptionsFiltered={searchBarOptionsFiltered}
            projectTypeId={currentProject.ProjectTypeId}
            certifiedAllotments={certifiedAllotments}
            selectProps1={{
              value: selectedSearchGroupType,
              onChange: (e) => {
                resetUserInputs();
                setSelectedSearchGroupType(
                  SelectedSearchGroupOptionsLabel[e.label] ||
                    SelectedSearchGroupOptionsLabel[e.value] ||
                    SelectedSearchGroupOptionsLabel.Equipements
                );
              },
              filterOptions: searchGroupOptions,
            }}
            dropdownProps={{
              autoFocus: true,
              onSelect: () => {}, //if not present errors from packageUI. SEE IN PACKAGE-UI WHY?
              onChange: (value) => {
                setSheetSelected(
                  searchBarOptionsFiltered['bibliotheque'].find(
                    (el) => el.id === value.id
                  )
                );
                if (!value) {
                  resetUserInputs();
                }
                if (value) {
                  setCaracValues({
                    xAxis: '',
                    discrete: null,
                    secondDiscrete: null,
                    photovoltaicSelfConsumption: null,
                    photovoltaicAnnualProduction: null,
                    hasFluid: false,
                    isDefaultFluid: true,
                    fluidId: null,
                    fluidChargeQuantity: null,
                  });
                  handleOnChangeAddEquipmentSearchBar(value);
                }
              },
              onClear: () => {},
              placeholder: 'Rechercher...',
              treeCheckable: false,
            }}
            selectProps2={{
              ListType:
                currentAllotment && currentAllotment?.id !== ALLOTMENT_IDS.NOOCO
                  ? [currentAllotment.name.toUpperCase()]
                  : ['Nooco'],
              noocoOptions: allAllotmentNodes,
              onChange: handleChangeAllotmentNode,
              value:
                nodeSearchValue && currentAllotment.id === ALLOTMENT_IDS.NOOCO
                  ? findParentNode(nodeSearchValue, allGroups.flatAllotments, [], [])
                  : re2020lot
                  ? [re2020lot]
                  : nodeSearchValue && certificationKeyNode
                  ? [
                      allGroups.flatAllotments.find(
                        (node) => node.id === nodeSearchValue[certificationKeyNode]
                      ),
                    ]
                  : [],
            }}
            triggerBreadcrumb={() => {
              dispatch(updateFormValue('openBreadcrumb', true));
            }}
            findParentNode={findParentNode}
            allAllotments={allGroups.flatAllotments}
            allotmentsAvailable={nodesAvailableForProjectType}
            api={apiNooco}
            gridStyle={'210px 385px  55px'}
          />
        </div>
        <div className="userInput-wrapper">
          {/* unamortized Wrapper */}
          {(conditionToDisplayAmortizedCheckboxBBCA ||
            conditionToDisplayAmortizedCheckboxGlobalMonitoring) && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <NoocoCheckbox
                checked={isUnamortizedChecked}
                onChange={(e) => {
                  if (e.target.checked) {
                    setIsUnamortizedChecked(e.target.checked);
                  }
                  if (!e.target.checked) {
                    setIsUnamortizedChecked(e.target.checked);
                    setUnamortizedValues({
                      residualLifespan: '',
                      isResidualLifespanUnknowChecked: false,
                      treatmentMode: null,
                    });
                  }
                }}
              />
              <div style={{ marginRight: '8px' }}>
                {!isGlobalMonitoringProject
                  ? t('APP.SHARED.QUICKADDBAREQUIPMENT.NONE_AMORTIZED', {
                      defaultValue: 'Non amorti',
                    })
                  : t('APP.SHARED.QUICKADDBAREQUIPMENT.DEPOSIT', {
                      defaultValue: 'Dépose',
                    })}
              </div>
              <Tooltip
                title={t('APP.SHARED.QUICKADDBAREQUIPMENT.PCE_TOOLTIP', {
                  defaultValue:
                    'Les PCE non amortis correspondent aux PCE déposés. A l’ajout d’un PCE non amorti, la ligne associée sera affichée en vert dans la Liste des contributeurs.',
                })}
              >
                <img
                  src={informationIcon}
                  style={{ width: '22px', height: '22px' }}
                  alt=""
                />
              </Tooltip>
            </div>
          )}
          {/* caracs */}
          <CaracPopover
            AllotmentNodeId={AllotmendNodeId}
            caracValues={caracValues}
            setCaracValues={setCaracValues}
            unamortizedValues={unamortizedValues}
            setUnamortizedValues={setUnamortizedValues}
            isUnamortizedChecked={isUnamortizedChecked}
            isPhotovoltaicEquipmentSelected={isPhotovoltaicEquipmentSelected}
            isIniesSelected={isIniesSelected}
            isAdjustableFluid={getStatusAdjustableFluid(
              searchValue,
              currentProject.templateType,
              selectedSheetObj
            )}
            screen={CurrentScreen.quickAddBar}
          />
          {/* quantity */}
          <Input
            type="text"
            placeholder={t('APP.SHARED.QUICKADDBAREQUIPMENT.QTY.PLACEHOLDER', {
              defaultValue: 'Qté',
            })}
            width="100px"
            defaultValue={quantity}
            disabledDebouncing={true}
            value={quantity}
            onChange={(value) => {
              setQuantity(value);
            }}
            error={!isNumOrNull(quantity)}
          />
          {/* unit */}
          <div style={{ width: '70px', textAlign: 'center' }}>
            <div>{selectedUnit()}</div>
          </div>
          {/* zone selection */}
          {!isProjectSingleZoneOnly(zones) && (
            <Dropdown
              value={selectedDestinationZoneId}
              options={createStructuredZonesForDropdown({
                zones,
                projectParam: { visible: false, disabled: true },
                buildingParam: { visible: true, disabled: true },
              })}
              style={{
                borderRadius: '15px',
                width: '170px',
              }}
              treeCheckable={false}
              multiple={false}
              treeNodeFilterProp="title"
              onSelect={() => {}}
              onChange={(e) => {
                setSelectedDestinationZoneId(e);
              }}
              treeDefaultExpandAll={true}
              allowClear={false}
              placeholder="Sélectionner des zones"
            />
          )}
          {isGlobalMonitoringProject && (
            <DatePickerSelector
              onChange={setLifeSpan}
              selectedSearchGroupType={selectedSearchGroupType}
            />
          )}
        </div>

        {/* validation part */}
      </div>
      {/* validation part */}
      <div className="validation-wrapper">
        <div className="valid-quick-addBar-equipment">
          {isLoadingPostEquipmentInProject && (
            <div className="donutsWrapper">
              <div className="donut" />
            </div>
          )}
          {areEquipmentInputsValid() && !isLoadingPostEquipmentInProject ? (
            <div>
              {' '}
              <Tooltip title="Ajouter sur le projet">
                <div className="valid-button">
                  {' '}
                  <AddCircleIcon
                    style={{ color: '#00e0c9', fontSize: '40px' }}
                    onClick={handleCreateEquipment}
                  />{' '}
                </div>
              </Tooltip>
            </div>
          ) : null}
          {!areEquipmentInputsValid() ? (
            <div>
              {' '}
              <Tooltip title="Remplir tous les champs manquants pour ajouter">
                <div className="valid-button">
                  {' '}
                  <AddCircleIcon style={{ color: '#B7B7B7', fontSize: '40px' }} />
                </div>
              </Tooltip>
            </div>
          ) : null}
        </div>

        {setIsQuickAddBarListEquipmentsOpen && (
          <div className="close-quick-addBar-equipment">
            <Tooltip title="Annuler/Fermer l’ajout rapide">
              <CloseIcon onClick={() => setIsQuickAddBarListEquipmentsOpen(false)} />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuickAddBarEquipment;
