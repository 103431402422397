import { Button } from '@photocarbone/nooco-ui';
import { StateDTO } from '../../../../models/GeneralDTO';
import {
  WaterCalculatorDataStructure,
  WorksiteCalculatorNav,
} from '../../../../models/CalculatorDTO';
import {
  calculatorExit,
  calculatorReset,
  calculatorUpdate,
  calculatorWaterReset,
  getWaterCalculatorDetailed,
  putBuildingCalculatorData,
  putWaterCalculatorDetailed,
  resetWaterCalculatorDetailed,
  updateWaterCalculator,
} from '../../../../../actions/calculator';
import { changeValueEquipments } from '../../../../../actions/equipments';
import { isNum, isNumHigherZero } from '../../../../shared/utilsFunction';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DrinkableWater from './CalculatorWaterContent/DrinkableWater';
import InfoIcon from '@material-ui/icons/Info';
import RainWater from './CalculatorWaterContent/RainWater';
import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import WasteWater from './CalculatorWaterContent/WasteWater';
import detailedCalcul from '../../../../../assets/images/equipments/calculator/calculator.png';
import drinkableIcon from '../../../../../assets/images/equipments/calculator/drinkable.png';
import epcmIcon from '../../../../../assets/images/creation/epcm.png';
import formatNumber from '../../../../services/mathematics/formatNumber';
import rainWaterIcon from '../../../../../assets/images/equipments/calculator/rainWater.png';
import re2020 from '../../../../../assets/images/equipments/calculator/re2020.png';
import simplifiedCalcul from '../../../../../assets/images/equipments/calculator/simplified.png';
import wasteIcon from '../../../../../assets/images/equipments/calculator/waste.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export type CalculatorWorksiteProps = {
  groups: [];
  templateType: string;
  TYPE_EPCM: string;
  TYPE_RE2020: string;
  // show: boolean,
};

const WaterCalculator = ({
  groups,
  templateType,
  TYPE_EPCM,
  TYPE_RE2020,
}: CalculatorWorksiteProps) => {
  const {
    isWaterCalculatorDetailed,
    isWaterCalculatorSimplified,
    loadingWaterCalculator,
    isWaterCalculatorLoaded,
    waterCalculatorDetailedActive,
    waterCalculator: waterCalculatorDataStructure,
  } = useSelector((state: StateDTO) => state.projects.waterCalculator);
  const currentProjectId = useSelector(
    (state: StateDTO) => state.projects.currentProject.id
  );
  const zones = useSelector((state: StateDTO) => state.projects.parsedProject.zones);
  const buildingTypes = useSelector(
    (state: StateDTO) => state.projects.buildingTypes
  );
  const dispatch = useDispatch();
  const [percentageResultStructure, setPercentageResultStructure] = useState<any>();
  const classes = useStyles();
  const _ = require('lodash');

  const [openDialog, setOpenDialog] = React.useState(false);

  const { t } = useTranslation();

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleResetWaterCalculator = () => {
    dispatch(changeValueEquipments('isLoadingProject', true));
    dispatch(calculatorUpdate('loadingCalculator', true));

    const dataReset = getValues().formData;

    // console.log('dataReset',
    //   dataReset);

    dataReset.buildings.map((building: any) => {
      building.detailledWaterProperties = {
        livingSurface: 0,
        isCollectiveSanitation: true,
        isUnitNetwork: false,
        qPluviometrie: 0,
        surfaceToiture: 0,
        surfaceParking: 0,
      };
      building.zones.map((zone: any) => {
        zone.detailledWaterProperties = {
          nbOccupants: zone.detailledWaterProperties.nbOccupants,
          nbRobinetEvier: 0,
          nbRobinetLavabo: 0,
          nbDouche: 0,
          nbChasseEau36: 0,
          nbChasseEau24: 0,
          nbToilettes: 0,
          distance: 0,
          urinoir: 0,
        };
        zone.calculatorDetailed = null;
      });
    });
    // console.log('getValues in trash',
    //   getValues());
    dispatch(resetWaterCalculatorDetailed(currentProjectId, dataReset));
    // console.log('currentProjectId', currentProjectId);
    dispatch(calculatorWaterReset());
  };

  const waterCalculatorNav: WorksiteCalculatorNav[] = [
    {
      id: 0,
      title: t('APP.CALCULATORWATER.CALCULATORWATER.PH_ED6A44', {
        defaultValue: 'Calculette Eau - Eau potable',
      }),
      sidebarTitle: t('APP.CALCULATORWATER.CALCULATORWATER.PH_B373A8', {
        defaultValue: 'Quantité d’eau potable consommée',
      }),
      sidebarIcon: drinkableIcon,
      sidebarUnity: 'm³/an',
      content: 'DrinkableWater',
      sidebarActive: 'drinkableWater',
      comment: t('APP.CALCULATORWATER.CALCULATORWATER.PH_29A7A2', {
        defaultValue:
          'Saisie de vos consommations en eau potable (usages internes et classiques de l’eau dans le bâtiment)',
      }),
      total: null,
    },
    {
      id: 1,
      title: t('APP.CALCULATORWATER.CALCULATORWATER.PH_1E583A', {
        defaultValue: 'Calculette Eau - Eaux usées',
      }),
      sidebarTitle: t('APP.CALCULATORWATER.CALCULATORWATER.PH_00E8B3', {
        defaultValue: 'Quantité d’eaux usées rejetées',
      }),
      sidebarIcon: wasteIcon,
      sidebarUnity: 'm³/an',
      content: 'WasteWater',
      sidebarActive: 'wasteWater',
      comment: t('APP.CALCULATORWATER.CALCULATORWATER.PH_A3F38D', {
        defaultValue:
          'La quantité d’eaux usées rejetées est directement calculée avec la quantité d’eau potable consommée',
      }),
      total: null,
    },
    {
      id: 2,
      title: t('APP.CALCULATORWATER.CALCULATORWATER.PH_F5DF23', {
        defaultValue: 'Calculette Eau - Eaux pluviales',
      }),
      sidebarTitle: t('APP.CALCULATORWATER.CALCULATORWATER.PH_466251', {
        defaultValue: 'Quantité d’eaux pluviales rejetées',
      }),
      sidebarIcon: rainWaterIcon,
      sidebarUnity: 'm³/an',
      content: 'RainWater',
      sidebarActive: 'rainWater',
      comment: t('APP.CALCULATORWATER.CALCULATORWATER.PH_4655BE', {
        defaultValue:
          'Saisie de la quantité d’eaux pluviales reçues sur les toitures, couvertures d’aires de stationnement et aires de stationnement imperméabilisées non couvertes',
      }),
      total: null,
    },
  ];

  type FormValues = {
    formData: typeof waterCalculatorDataStructure;
  };

  // console.log("waterCalculatorDataStructure before creation du form",
  //   waterCalculatorDataStructure);

  const {
    control,
    reset,
    getValues,
    setValue,
    register,
    setFocus,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'all',
    shouldFocusError: true,
    defaultValues: {
      formData: waterCalculatorDataStructure,
    },
  });

  const form = watch();

  // console.log('form apres refresh',
  //   form);
  // console.log('errors',
  //   errors);

  useEffect(() => {
    // console.log('Reset useEffect');
    reset({ formData: waterCalculatorDataStructure });
    // trigger();
    // handleSubmit(onSubmitReset);
    // calculWastedWater();
    // setFormResetDone(true);
  }, [isWaterCalculatorLoaded]);

  useEffect(() => {
    dispatch(
      updateWaterCalculator('calculatorWater', prepareWaterCalculatorDataStructure())
    );
  }, []);

  const onSubmitApi = (formData: FormValues) => {
    // dispatch(putBuildingcalculatorData(currentProjectId,
    //   {calculatorData: data}));
    // dispatch(calculatorUpdate('loadingCalculator',
    //   true));
    dispatch(updateWaterCalculator('loadingWaterCalculator', true));
    // console.log('Data send to API',
    //   {calculatorData: {...formData.formData}});
    dispatch(putWaterCalculatorDetailed(currentProjectId, { ...formData.formData }));
    //TODO remove detailledWater active page, choicedetailled?, ...
  };

  const onSubmitSidebar = (data, sidebarItem: any) => {
    if (sidebarItem.content !== 'WasteWater') {
      dispatch(
        updateWaterCalculator('waterCalculatorDetailedActive', sidebarItem.id)
      );
    }
  };

  const onSubmitNext = (data) => {
    dispatch(updateWaterCalculator('waterCalculatorDetailedActive', 2));
  };

  const onSubmitPrevious = (data) => {
    dispatch(updateWaterCalculator('waterCalculatorDetailedActive', 0));
  };

  const onSubmitError = (data) => {
    console.log('onSubmitError', data);
  };

  const calculWastedWater = (building) => {
    // console.log('calculWastedWater start');

    let total: number | null = null;

    if (
      isNumHigherZero(building.detailledWaterProperties.qPluviometrie) &&
      isNum(building.detailledWaterProperties.surfaceToiture) &&
      isNum(building.detailledWaterProperties.surfaceParking)
    ) {
      total =
        (Number(building.detailledWaterProperties.qPluviometrie) / 1000) *
        (Number(building.detailledWaterProperties.surfaceToiture) +
          Number(building.detailledWaterProperties.surfaceParking));
    } else {
      total = null;
    }

    return total;
  };

  const isRequieredFieldFilled =
    form.formData.buildings.filter(
      (building) =>
        building.detailledWaterProperties.livingSurface !== null &&
        building.detailledWaterProperties.livingSurface !== '' &&
        building.detailledWaterProperties.livingSurface !== null
    ).length === form.formData.buildings.length
      ? true
      : false;

  const arrayOfSumPluvialWaterByBuilding = form.formData.buildings.map(
    (building) => {
      return calculWastedWater(building);
    }
  );

  const typeIdEducation = [11, 17];
  const typeIdOffice = [8];
  const typeIdResidentiel = [1, 2];

  type correctionFactorType = {
    BuildingTypeId: number[];
    nbRobinetEvier: number | null;
    nbRobinetLavabo: number | null;
    nbDouche: number | null;
    nbChasseEau36: number | null;
    nbChasseEau24: number | null;
    nbToilettes: number | null;
    distance: number | null;
    urinoir: number | null;
  }[];

  const correctionFactor: correctionFactorType = [
    {
      BuildingTypeId: typeIdEducation,
      nbRobinetEvier: null,
      nbRobinetLavabo: 0.91,
      nbDouche: null,
      nbChasseEau36: 0.75,
      nbChasseEau24: 0.58,
      nbToilettes: null,
      distance: null,
      urinoir: 0.58,
    },
    {
      BuildingTypeId: typeIdOffice,
      nbRobinetEvier: null,
      nbRobinetLavabo: 0.86,
      nbDouche: null,
      nbChasseEau36: 0.77,
      nbChasseEau24: 0.62,
      nbToilettes: null,
      distance: 0.95,
      urinoir: 0.61,
    },
    {
      BuildingTypeId: typeIdResidentiel,
      nbRobinetEvier: 0.94,
      nbRobinetLavabo: 0.97,
      nbDouche: 0.8,
      nbChasseEau36: 0.96,
      nbChasseEau24: 0.93,
      nbToilettes: 0.87,
      distance: 0.98,
      urinoir: null,
    },
  ];

  const occupantWaterConsumption = {
    office: 5.59,
    residentiel: 48,
    education: 1.44,
  };

  const calculDrinkableWater = (building) => {
    let total: number = 0;

    building?.zones?.map((zone) => {
      const correctionFactorToUse =
        correctionFactor.find((correctionFactor) =>
          correctionFactor.BuildingTypeId.includes(zone.BuildingTypeId)
        ) || null;

      const occupantWaterConsuptionChoice = typeIdEducation.includes(
        zone.BuildingTypeId
      )
        ? occupantWaterConsumption.education
        : typeIdOffice.includes(zone.BuildingTypeId)
        ? occupantWaterConsumption.office
        : typeIdResidentiel.includes(zone.BuildingTypeId)
        ? occupantWaterConsumption.residentiel
        : null;

      // console.log('occupantWaterConsuptionChoice',
      //   occupantWaterConsuptionChoice);

      // let totalPercentage = 0;

      if (correctionFactorToUse?.nbRobinetEvier && occupantWaterConsuptionChoice) {
        total =
          total +
          zone?.detailledWaterProperties?.nbRobinetEvier *
            correctionFactorToUse?.nbRobinetEvier *
            zone.detailledWaterProperties.nbOccupants *
            occupantWaterConsuptionChoice;
        // totalPercentage += correctionFactorToUse?.nbRobinetEvier * zone?.detailledWaterProperties?.nbRobinetEvier;
      }
      if (correctionFactorToUse?.nbRobinetLavabo) {
        total =
          total +
          zone?.detailledWaterProperties?.nbRobinetLavabo *
            correctionFactorToUse?.nbRobinetLavabo *
            zone.detailledWaterProperties.nbOccupants *
            occupantWaterConsuptionChoice;
        // totalPercentage += correctionFactorToUse?.nbRobinetLavabo * zone?.detailledWaterProperties?.nbRobinetLavabo;
      }
      if (correctionFactorToUse?.nbDouche) {
        total =
          total +
          zone?.detailledWaterProperties?.nbDouche *
            correctionFactorToUse?.nbDouche *
            zone.detailledWaterProperties.nbOccupants *
            occupantWaterConsuptionChoice;
        // totalPercentage += correctionFactorToUse?.nbDouche * zone?.detailledWaterProperties?.nbDouche;
      }
      if (correctionFactorToUse?.nbChasseEau36) {
        total =
          total +
          zone?.detailledWaterProperties?.nbChasseEau36 *
            correctionFactorToUse?.nbChasseEau36 *
            zone.detailledWaterProperties.nbOccupants *
            occupantWaterConsuptionChoice;
        // totalPercentage += correctionFactorToUse?.nbChasseEau36 * zone?.detailledWaterProperties?.nbChasseEau36;
      }
      if (correctionFactorToUse?.nbChasseEau24) {
        total =
          total +
          zone?.detailledWaterProperties?.nbChasseEau24 *
            correctionFactorToUse?.nbChasseEau24 *
            zone.detailledWaterProperties.nbOccupants *
            occupantWaterConsuptionChoice;
        // totalPercentage += correctionFactorToUse?.nbChasseEau24 * zone?.detailledWaterProperties?.nbChasseEau24;
      }
      if (correctionFactorToUse?.nbToilettes) {
        total =
          total +
          zone?.detailledWaterProperties?.nbToilettes *
            correctionFactorToUse?.nbToilettes *
            zone.detailledWaterProperties.nbOccupants *
            occupantWaterConsuptionChoice;
        // totalPercentage += correctionFactorToUse?.nbToilettes * zone?.detailledWaterProperties?.nbToilettes;
      }
      if (correctionFactorToUse?.distance) {
        total =
          total +
          zone?.detailledWaterProperties?.distance *
            correctionFactorToUse?.distance *
            zone.detailledWaterProperties.nbOccupants *
            occupantWaterConsuptionChoice;
        // totalPercentage += correctionFactorToUse?.distance * zone?.detailledWaterProperties?.distance;
      }
      if (correctionFactorToUse?.urinoir) {
        total =
          total +
          zone?.detailledWaterProperties?.urinoir *
            correctionFactorToUse?.urinoir *
            zone.detailledWaterProperties.nbOccupants *
            occupantWaterConsuptionChoice;
        // totalPercentage += correctionFactorToUse?.urinoir * zone?.detailledWaterProperties?.urinoir;
      }

      // console.log('totalPercentage',
      //   totalPercentage);
      // setValue(
      //   `formData.buildings.${buildingIndex}.zones.${zoneIndex}.detailledWaterProperties.totalPercentage`,
      //   totalPercentage
      // );
    });

    let needWatering = 0;

    if (!isNaN(building.detailledWaterProperties.livingSurface)) {
      needWatering = building.detailledWaterProperties.livingSurface * 0.033 * 20;
      total = total + needWatering;
    }

    return total;
  };

  const calculEquipmentPercentageByZone = (zone, buildingIndex, zoneIndex) => {
    const correctionFactorToUse =
      correctionFactor.find((correctionFactor) =>
        correctionFactor.BuildingTypeId.includes(zone?.BuildingTypeId)
      ) || null;

    let totalPercentage = 0;
    let nbRobinetEvierPercentage = 0;
    let nbRobinetLavaboPercentage = 0;
    let nbDouchePercentage = 0;
    let nbChasseEau36Percentage = 0;
    let nbChasseEau24Percentage = 0;
    let nbToilettesPercentage = 0;
    let distancePercentage = 0;
    let urinoirPercentage = 0;

    if (correctionFactorToUse?.nbRobinetEvier) {
      totalPercentage +=
        correctionFactorToUse?.nbRobinetEvier *
        zone?.detailledWaterProperties?.nbRobinetEvier;
      nbRobinetEvierPercentage =
        correctionFactorToUse?.nbRobinetEvier *
        zone?.detailledWaterProperties?.nbRobinetEvier;
    }
    if (correctionFactorToUse?.nbRobinetLavabo) {
      totalPercentage +=
        correctionFactorToUse?.nbRobinetLavabo *
        zone?.detailledWaterProperties?.nbRobinetLavabo;
      nbRobinetLavaboPercentage =
        correctionFactorToUse?.nbRobinetLavabo *
        zone?.detailledWaterProperties?.nbRobinetLavabo;
    }
    if (correctionFactorToUse?.nbDouche) {
      totalPercentage +=
        correctionFactorToUse?.nbDouche * zone?.detailledWaterProperties?.nbDouche;
      nbDouchePercentage =
        correctionFactorToUse?.nbDouche * zone?.detailledWaterProperties?.nbDouche;
    }
    if (correctionFactorToUse?.nbChasseEau36) {
      totalPercentage +=
        correctionFactorToUse?.nbChasseEau36 *
        zone?.detailledWaterProperties?.nbChasseEau36;
      nbChasseEau36Percentage =
        correctionFactorToUse?.nbChasseEau36 *
        zone?.detailledWaterProperties?.nbChasseEau36;
    }
    if (correctionFactorToUse?.nbChasseEau24) {
      totalPercentage +=
        correctionFactorToUse?.nbChasseEau24 *
        zone?.detailledWaterProperties?.nbChasseEau24;
      nbChasseEau24Percentage =
        correctionFactorToUse?.nbChasseEau24 *
        zone?.detailledWaterProperties?.nbChasseEau24;
    }
    if (correctionFactorToUse?.nbToilettes) {
      totalPercentage +=
        correctionFactorToUse?.nbToilettes *
        zone?.detailledWaterProperties?.nbToilettes;
      nbToilettesPercentage =
        correctionFactorToUse?.nbToilettes *
        zone?.detailledWaterProperties?.nbToilettes;
    }
    if (correctionFactorToUse?.distance) {
      totalPercentage +=
        correctionFactorToUse?.distance * zone?.detailledWaterProperties?.distance;
      distancePercentage =
        correctionFactorToUse?.distance * zone?.detailledWaterProperties?.distance;
    }
    if (correctionFactorToUse?.urinoir) {
      totalPercentage +=
        correctionFactorToUse?.urinoir * zone?.detailledWaterProperties?.urinoir;
      urinoirPercentage =
        correctionFactorToUse?.urinoir * zone?.detailledWaterProperties?.urinoir;
    }
    percentageResultStructure.buildings[buildingIndex].zones[
      zoneIndex
    ].detailledWaterProperties.totalPercentage = totalPercentage;
    percentageResultStructure.buildings[buildingIndex].zones[
      zoneIndex
    ].detailledWaterProperties.nbRobinetEvierPercentage = nbRobinetEvierPercentage;
    percentageResultStructure.buildings[buildingIndex].zones[
      zoneIndex
    ].detailledWaterProperties.nbRobinetLavaboPercentage = nbRobinetLavaboPercentage;
    percentageResultStructure.buildings[buildingIndex].zones[
      zoneIndex
    ].detailledWaterProperties.nbDouchePercentage = nbDouchePercentage;
    percentageResultStructure.buildings[buildingIndex].zones[
      zoneIndex
    ].detailledWaterProperties.nbChasseEau36Percentage = nbChasseEau36Percentage;
    percentageResultStructure.buildings[buildingIndex].zones[
      zoneIndex
    ].detailledWaterProperties.nbChasseEau24Percentage = nbChasseEau24Percentage;
    percentageResultStructure.buildings[buildingIndex].zones[
      zoneIndex
    ].detailledWaterProperties.nbToilettesPercentage = nbToilettesPercentage;
    percentageResultStructure.buildings[buildingIndex].zones[
      zoneIndex
    ].detailledWaterProperties.distancePercentage = distancePercentage;
    percentageResultStructure.buildings[buildingIndex].zones[
      zoneIndex
    ].detailledWaterProperties.urinoirPercentage = urinoirPercentage;

    const newPercentageResultStructure = _.cloneDeep(percentageResultStructure);

    if (
      errors?.formData?.buildings[buildingIndex]?.zones &&
      errors?.formData?.buildings[buildingIndex]?.zones[zoneIndex]
    ) {
    } else {
      setPercentageResultStructure(newPercentageResultStructure);
      // }
    }
  };

  const arrayOfSumDrinkableWaterByBuilding = form.formData.buildings.map(
    (building) => {
      return calculDrinkableWater(building);
    }
  );

  const QeauxUsées =
    _.sum(arrayOfSumDrinkableWaterByBuilding) -
    _.sum(
      form.formData.buildings.map((building) => {
        let needWatering = 0;

        if (!isNaN(Number(building.detailledWaterProperties.livingSurface))) {
          needWatering =
            Number(building.detailledWaterProperties.livingSurface) * 0.033 * 20;
          needWatering = needWatering * 0.5;
        }

        return needWatering;
      })
    );

  const componentToDisplay = (componentName: string): JSX.Element => {
    switch (componentName) {
      case 'DrinkableWater':
        return (
          <DrinkableWater
            control={control}
            errors={errors}
            register={register}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
            calculEquipmentPercentageByZone={calculEquipmentPercentageByZone}
            percentageResultStructure={percentageResultStructure}
          />
        );
      case 'RainWater':
        return (
          <RainWater
            control={control}
            errors={errors}
            register={register}
            setValue={setValue}
            watch={watch}
          />
        );

      case 'WasteWater':
        return <WasteWater />;
      default:
        return (
          <>
            {t('APP.CALCULATORWATER.CALCULATORWATER.PH_21D984', {
              defaultValue: 'erreur 404',
            })}
          </>
        );
    }
  };

  const prepareWaterCalculatorDataStructure = () => {
    const buildingTypesRE2020 = [17, 11, 2, 8, 1];
    const officeAndTeachingBuildingTypes = [17, 11, 8];
    const residentialBuildingTypes = [2, 1];
    const nbRobinetEvier = residentialBuildingTypes;
    const nbRobinetLavabo = residentialBuildingTypes.concat(
      officeAndTeachingBuildingTypes
    );
    const nbDouche = residentialBuildingTypes;
    const nbChasseEau36 = residentialBuildingTypes.concat(
      officeAndTeachingBuildingTypes
    );
    const nbChasseEau24 = residentialBuildingTypes.concat(
      officeAndTeachingBuildingTypes
    );
    const nbToilettes = residentialBuildingTypes;
    const distance = residentialBuildingTypes;
    const urinoir = officeAndTeachingBuildingTypes;

    let buildingsId: number[] = [];

    zones.forEach((zone) => {
      if (zone.type === 'building') {
        waterCalculatorDataStructure.buildings.push({
          id: zone.id,
          name: zone.name,
          detailledWaterProperties: {
            livingSurface: '0',
            isCollectiveSanitation: true,
            isUnitNetwork: true,
            qPluviometrie: '0',
            surfaceToiture: '0',
            surfaceParking: '0',
          },
          zones: [],
        });
        buildingsId.push(zone.id);
      }
    });

    zones.forEach((zone) => {
      if (
        zone.ParentId &&
        zone.BuildingTypeId &&
        buildingsId.includes(zone?.ParentId) &&
        buildingTypesRE2020.includes(zone?.BuildingTypeId)
      ) {
        waterCalculatorDataStructure.buildings
          .find((building) => building.id === zone.ParentId)
          ?.zones.push({
            id: zone.id,
            name: zone.name,
            type:
              buildingTypes.find(
                (buildingType) => buildingType.id === zone.BuildingTypeId
              )?.name || '',
            BuildingTypeId: zone.BuildingTypeId,
            ParentId: zone.ParentId,
            detailledWaterProperties: {
              totalPercentage: null,
              nbOccupants: zone.nbOccupants === 0 ? 1 : zone.nbOccupants,
              nbRobinetEvier: nbRobinetEvier.includes(zone.BuildingTypeId)
                ? 0
                : null,
              nbRobinetEvierPercentage: null,
              nbRobinetLavabo: nbRobinetLavabo.includes(zone.BuildingTypeId)
                ? 0
                : null,
              nbRobinetLavaboPercentage: null,
              nbDouche: nbDouche.includes(zone.BuildingTypeId) ? 0 : null,
              nbDouchePercentage: null,
              nbChasseEau36: nbChasseEau36.includes(zone.BuildingTypeId) ? 0 : null,
              nbChasseEau36Percentage: null,
              nbChasseEau24: nbChasseEau24.includes(zone.BuildingTypeId) ? 0 : null,
              nbChasseEau24Percentage: null,
              nbToilettes: nbToilettes.includes(zone.BuildingTypeId) ? 0 : null,
              nbToilettesPercentage: null,
              distance: distance.includes(zone.BuildingTypeId) ? 0 : null,
              distancePercentage: null,
              urinoir: urinoir.includes(zone.BuildingTypeId) ? 0 : null,
              urinoirPercentage: null,
            },
            calculatorDetailed: null,
          });
      }
    });

    setPercentageResultStructure(waterCalculatorDataStructure);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ display: 'flex', width: '100%', height: '100%' }}>
        {isWaterCalculatorDetailed && (
          <motion.nav
            className="calculator__sidebarWater"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              delay: 0.5,
              x: { duration: 1 },
              opacity: { duration: 0.5 },
              default: { ease: 'linear' },
            }}
          >
            {/* SideBar */}
            {waterCalculatorNav.map((item) => {
              return (
                <Tooltip
                  style={{ transform: 'translate(20px, 2em)' }}
                  key={`item-${item.id}`}
                  title={item.comment}
                  placement="right"
                >
                  <div
                    // key={`item-${item.id}`}
                    className={
                      item.id === waterCalculatorDetailedActive
                        ? 'calculator__sidebar__item active'
                        : 'calculator__sidebar__item'
                    }
                    style={{
                      backgroundColor:
                        item.content === 'WasteWater'
                          ? 'rgba(201, 201, 201, 0.55)'
                          : '',
                      cursor: item.content === 'WasteWater' ? 'not-allowed' : '',
                    }}
                    onClick={() =>
                      handleSubmit(onSubmitSidebar, onSubmitError)(item)
                    }
                  >
                    <div className="calculator__sidebar__item--icon">
                      <img
                        // className='calculator__sidebar__item--icon'
                        src={item.sidebarIcon}
                        alt=""
                      />
                    </div>
                    <div className="calculator__sidebar__item--result">
                      <h4 className="calculator__sidebar__item-result--title">
                        {item.sidebarTitle}
                      </h4>
                      <p>
                        {item.sidebarActive === 'rainWater' &&
                          _.sum(arrayOfSumPluvialWaterByBuilding) !== 0 &&
                          `${formatNumber(
                            _.sum(arrayOfSumPluvialWaterByBuilding)
                          )} ${item.sidebarUnity}`}
                        {item.sidebarActive === 'rainWater' &&
                          _.sum(arrayOfSumPluvialWaterByBuilding) === 0 &&
                          `0 ${item.sidebarUnity}`}
                        {item.sidebarActive === 'drinkableWater' &&
                          !isNaN(_.sum(arrayOfSumDrinkableWaterByBuilding)) &&
                          `${formatNumber(
                            _.sum(arrayOfSumDrinkableWaterByBuilding)
                          )} ${item.sidebarUnity}`}
                        {item.sidebarActive === 'wasteWater' &&
                          !isNaN(QeauxUsées) &&
                          `${formatNumber(QeauxUsées)} ${item.sidebarUnity}`}
                      </p>
                    </div>
                  </div>
                </Tooltip>
              );
            })}
          </motion.nav>
        )}
        {/* main */}
        <div className="worksiteCalculatorChoiceWater">
          <motion.main
            className={
              !isWaterCalculatorDetailed && !isWaterCalculatorSimplified
                ? 'calculator__mainContainer'
                : 'calculator__container'
            }
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              delay: 0.5,
              x: { duration: 1 },
              opacity: { duration: 0.2 },
              default: { ease: 'linear' },
            }}
          >
            <div className="container__header">
              {!isWaterCalculatorDetailed && !isWaterCalculatorSimplified && (
                <h2>
                  {t('APP.CALCULATORWATER.CALCULATORWATER.PH_9A5CA7', {
                    defaultValue: 'Calculette eau',
                  })}
                </h2>
              )}

              {isWaterCalculatorDetailed && (
                <h2>{waterCalculatorNav[waterCalculatorDetailedActive].title}</h2>
              )}
            </div>

            {/* // Display if isDetailled or isSimplified active */}
            {isWaterCalculatorDetailed && (
              <>
                {loadingWaterCalculator && (
                  //  <Loader
                  //    variant="indeterminate"
                  //    disableShrink
                  //    style={{ display: 'block', color: "#73b7ec", margin: 'auto' }}
                  //    size={70}
                  //    thickness={4}
                  //  />
                  <Backdrop
                    open={loadingWaterCalculator}
                    className={classes.backdrop}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )}
                {isWaterCalculatorLoaded && (
                  <div className="container__main">
                    {componentToDisplay(
                      waterCalculatorNav[waterCalculatorDetailedActive].content
                    )}
                  </div>
                )}
              </>
            )}

            {/* // index of worksiteCalculator: choice simplified or detailed */}
            {loadingWaterCalculator && (
              //  <Loader
              //    variant="indeterminate"
              //    disableShrink
              //    style={{ display: 'block', color: "#73b7ec", margin: 'auto' }}
              //    size={70}
              //    thickness={4}
              //  />
              <Backdrop open={loadingWaterCalculator} className={classes.backdrop}>
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            {!isWaterCalculatorDetailed &&
              !isWaterCalculatorSimplified &&
              !loadingWaterCalculator && (
                <>
                  <motion.div
                    // initial={{ opacity: 0, }}
                    // animate={{ opacity: 1 }}
                    // exit={{ opacity: 0 }}
                    // transition={{
                    //   delay: 0.5,
                    //   x: { duration: 1 },
                    //   opacity: { duration: 0.5},
                    //   default: { ease: "linear" }
                    // }}
                    className="container__calcContent"
                  >
                    <h3>
                      {t('APP.CALCULATORWATER.CALCULATORWATER.PH_507540', {
                        defaultValue:
                          'Choix du type de calcul de la contribution aux impacts de l’eau consommé pendant la durée de vie du bâtiment :',
                      })}
                    </h3>
                    <div className="container__calcContent--choice">
                      <div className="wrapperChoiceWaterCalculator">
                        <div
                          className="calcContent__detailled"
                          onClick={() => {
                            dispatch(getWaterCalculatorDetailed(currentProjectId));
                            dispatch(
                              updateWaterCalculator('loadingWaterCalculator', true)
                            );
                            dispatch(
                              updateWaterCalculator(
                                'isWaterCalculatorDetailed',
                                true
                              )
                            );
                          }}
                        >
                          <img
                            src={detailedCalcul}
                            alt=""
                            className="calcContent__detailled--logo"
                          />

                          <h4>
                            {t('APP.CALCULATORWATER.CALCULATORWATER.PH_9DC304', {
                              defaultValue: 'Calculette eau',
                            })}
                          </h4>
                          <h5>
                            {t('APP.CALCULATORWATER.CALCULATORWATER.PH_8E9D1C', {
                              defaultValue: 'Calcul détaillé',
                            })}
                          </h5>
                          <img
                            src={templateType === TYPE_EPCM ? epcmIcon : re2020}
                            className={
                              templateType === TYPE_EPCM
                                ? 'calcContent__epcm'
                                : 'calcContent__re2020'
                            }
                          />
                        </div>
                        <div className="container__calcContent--information">
                          <InfoIcon style={{ marginRight: '1rem' }} />
                          <p>
                            {t('APP.CALCULATORWATER.CALCULATORWATER.PH_FC44FF', {
                              defaultValue:
                                'La calculette détaillée s’appuie sur la méthode de calcul RE2020 pour déterminer les quanités d’eaux du projet consommées lors de la durée de vie du bâtiment',
                            })}
                          </p>
                        </div>
                      </div>
                      <div className="wrapperChoiceWaterCalculator">
                        <Tooltip
                          title={t('APP.CALCULATORWATER.CALCULATORWATER.PH_D33182', {
                            defaultValue: 'Bientôt disponible',
                          })}
                        >
                          <div
                            className="calcContent__detailled"
                            style={{ backgroundColor: '#eee' }}
                          >
                            <img
                              src={simplifiedCalcul}
                              style={{ filter: 'grayscale(1)' }}
                              className="calcContent__detailled--logo"
                            />

                            <h4 style={{ color: 'gray' }}>
                              {t('APP.CALCULATORWATER.CALCULATORWATER.PH_D9DEF6', {
                                defaultValue: 'Saisie libre',
                              })}
                            </h4>
                            <h5 style={{ color: 'gray' }}>
                              {t('APP.CALCULATORWATER.CALCULATORWATER.PH_4B6097', {
                                defaultValue: 'Calcul simplifié',
                              })}
                            </h5>

                            {/* <img src={templateType === TYPE_EPCM ? epcmIcon : re2020} alt="" className={templateType === TYPE_EPCM ? 'calcContent__epcm' :'calcContent__re2020'} /> */}
                          </div>
                        </Tooltip>
                        <div className="container__calcContent--information">
                          <InfoIcon style={{ marginRight: '1rem' }} />
                          <p>
                            {t('APP.CALCULATORWATER.CALCULATORWATER.PH_0D1DD4', {
                              defaultValue:
                                'La calculette simplifiée demande directement à l’utilisateur de remplir les quantités d’eaux du projet consommées lors de la durée de vie du bâtiment',
                            })}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className='container__calcContent--information'>
                  <InfoIcon style={{marginRight: '1rem'}}/>
                  <p>
                  Les deux calculettes permettent le calcul du chantier au sens de {templateType === TYPE_RE2020 ?'la RE2020' : 'E+C-'}
                  </p>
                  </div> */}
                  </motion.div>
                </>
              )}
          </motion.main>
          {!isWaterCalculatorDetailed && !isWaterCalculatorSimplified && (
            <footer className="container__calcContent--footerAlternative">
              <div className="calculator__footer__container">
                <Button
                  className="close-button"
                  style={{
                    fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                  }}
                  onClick={() => {
                    dispatch(calculatorExit());
                  }}
                  text={t('APP.CALCULATORWATER.CALCULATORWATER.PH_7C7E0A', {
                    defaultValue: 'Fermer',
                  })}
                />
              </div>
            </footer>
          )}
        </div>
      </div>
      {isWaterCalculatorDetailed && !isWaterCalculatorSimplified && (
        <footer
          className="container__calcContent--footerAlternative"
          style={{ width: '100%' }}
        >
          <div
            className="calculator__footer__container"
            style={{ display: 'flex', justifyContent: 'space-around' }}
          >
            <Button
              className="close-button"
              style={{
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
              }}
              onClick={() => {
                dispatch(calculatorExit());
                dispatch(calculatorWaterReset());
              }}
              text={t('APP.CALCULATORWATER.CALCULATORWATER.PH_B91ADC', {
                defaultValue: 'Fermer',
              })}
            />
            {waterCalculatorDetailedActive === 2 && (
              <Button
                className="close-button"
                style={{
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                  //TODO to corrige with goot conditions
                }}
                disabled={
                  _.isEmpty(errors) === true && isRequieredFieldFilled === true
                    ? false
                    : true
                }
                onClick={handleSubmit(onSubmitPrevious, onSubmitError)}
                text={t('APP.CALCULATORWATER.CALCULATORWATER.PH_452587', {
                  defaultValue: 'Précédent',
                })}
              />
            )}

            {waterCalculatorDetailedActive === 0 && (
              <Button
                className="purple-button"
                style={{
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                }}
                disabled={
                  _.isEmpty(errors) === true && isRequieredFieldFilled === true
                    ? false
                    : true
                }
                // onClick={() => {
                //   dispatch(updateWaterCalculator('waterCalculatorDetailedActive',
                //     2));
                // }}
                onClick={handleSubmit(onSubmitNext, onSubmitError)}
                text={t('APP.CALCULATORWATER.CALCULATORWATER.PH_42080A', {
                  defaultValue: 'Suivant',
                })}
              />
            )}

            <Button
              className="purple-button"
              loading={loadingWaterCalculator}
              style={{
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
              }}
              disabled={
                (_.isEmpty(errors) === true && isRequieredFieldFilled === true) ||
                loadingWaterCalculator === true
                  ? false
                  : true
              }
              onClick={handleSubmit(onSubmitApi, onSubmitError)}
              text={t('APP.CALCULATORWATER.CALCULATORWATER.PH_50587E', {
                defaultValue: 'Enregistrer',
              })}
            />

            <Button
              className="delete-button"
              centeredIcon={<DeleteIcon />}
              onClick={() => {
                // dispatch(calculatorReset());
                handleClickOpenDialog();
              }}
            />
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t('APP.CALCULATORWATER.CALCULATORWATER.PH_2E75F6', {
                  defaultValue: 'Supprimer la calculatrice eau détaillée?',
                })}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t('APP.CALCULATORWATER.CALCULATORWATER.PH_C33BF7', {
                    defaultValue:
                      'Attention: Tous les équipements et les valeurs de la calculatrice détaillée seront supprimés si vous continuez.',
                  })}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseDialog}
                  text={t('APP.CALCULATORWATER.CALCULATORWATER.PH_4787A4', {
                    defaultValue: 'Annuler',
                  })}
                  className="close-button"
                />

                <Button
                  onClick={() => {
                    // dispatch(calculatorReset());
                    handleCloseDialog();
                    handleResetWaterCalculator();
                    // dispatch(calculatorUpdate('loadingCalculator', true));

                    // dispatch(calculatorUpdate('loadingCalculator', true));
                  }}
                  // autoFocus
                  text={t('APP.CALCULATORWATER.CALCULATORWATER.PH_932E92', {
                    defaultValue: 'Tout supprimer',
                  })}
                  className="delete-button"
                />
              </DialogActions>
            </Dialog>
          </div>
        </footer>
      )}
    </div>
  );
};

export default WaterCalculator;
