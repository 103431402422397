import {
  companiesListDTO,
  companiesOffsetDTO,
  modalAccessDTO,
  rightsActiveTypeDTO,
  rightsHeaderDataDTO,
  rightsNameActionDTO,
  rightsTabValueDTO,
  rightsValueActionListDTO,
} from '../app/models/RightsDTO';

export const UPDATE_RIGHTS_VALUE = 'UPDATE_RIGHTS_VALUE';
export const FETCH_ACCESS_HEADER = 'FETCH_ACCESS_HEADER';
export const FETCH_ADMIN_USERS = 'FETCH_ADMIN_USERS';
export const FETCH_COMPANIES_LIST = 'FETCH_COMPANIES_LIST';
export const PATCH_COMPANY = 'PATCH_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const PATCH_USER = 'PATCH_USER';
export const CRUD_LICENSE = 'CRUD_LICENSE';
export const POST_COMPANY = 'POST_COMPANY';
export const POST_EMPLOYEE = 'POST_EMPLOYEE';
export const FETCH_ONE_COMPANY = 'FETCH_ONE_COMPANY';

export type rightsValueActionDTO =
  | { type: 'UPDATE_RIGHTS_VALUE'; name: 'modalAccess'; value: modalAccessDTO }
  | {
      type: 'UPDATE_RIGHTS_VALUE';
      name: 'rightsTabValue';
      value: rightsTabValueDTO;
    }
  | {
      type: 'UPDATE_RIGHTS_VALUE';
      name: 'rightsActiveType';
      value: rightsActiveTypeDTO;
    }
  | {
      type: 'UPDATE_RIGHTS_VALUE';
      name: 'rightsHeaderData';
      value: rightsHeaderDataDTO;
    }
  | {
      type: 'UPDATE_RIGHTS_VALUE';
      name: 'companiesList';
      value: companiesListDTO;
    }
  | {
      type: 'UPDATE_RIGHTS_VALUE';
      name: 'companiesOffset';
      value: companiesOffsetDTO;
    }
  | {
      type: 'POST_EMPLOYEE';
      data: { companyIds: number[]; email: string; licenceType: string };
    }
  | { type: 'FETCH_ACCESS_HEADER' }
  | { type: 'FETCH_ONE_COMPANY'; name: string };

export const updateRightsValue = (name: string, value: string | {}) => ({
  type: UPDATE_RIGHTS_VALUE,
  name,
  value,
});
export const fetchOneCompany = (name: string) => ({
  type: FETCH_ONE_COMPANY,
  name,
});

export const fetchAccessHeader = () => ({
  type: FETCH_ACCESS_HEADER,
});

export const fetchAdminUsers = (companyId: number) => ({
  type: FETCH_ADMIN_USERS,
  companyId,
});
export const fetchCompaniesList = () => ({
  type: FETCH_COMPANIES_LIST,
});

export const patchCompany = (company) => ({
  type: PATCH_COMPANY,
  company,
});

export const deleteCompany = (id: number) => ({
  type: DELETE_COMPANY,
  id,
});

export const patchUser = (data: {}) => ({
  type: PATCH_USER,
  data,
});

export const crudLicense = (data) => ({
  type: CRUD_LICENSE,
  data,
});

export const postCompany = () => ({
  type: POST_COMPANY,
});

export const postEmployee = (data) => ({
  type: POST_EMPLOYEE,
  data,
});
