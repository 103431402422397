import { StateDTO } from '../../models/GeneralDTO';
import { Tooltip } from '@material-ui/core';
import { toggleFavorites, updateFavoritesValue } from '../../../actions/favorites';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import StarOrgaBorder from '../../../assets/images/project/orgastarempty.png';
import StarOrgaEmpty from '../../../assets/images/project/orgastar.png';
import { useTranslation } from 'react-i18next';

interface FavoritesIconsProps {
  SheetId: number;
  screen: string | null;
  searchBarValue?: string;
}

const FavoritesIcons = ({
  SheetId,
  screen = null,
  searchBarValue,
}: FavoritesIconsProps) => {
  const dispatch = useDispatch();
  const { companyFavoritesIds, userFavoritesIds, addXmlFavorite } = useSelector(
    (state: StateDTO) => state.favorites
  );
  const { isAdminOrga, isCompanyAdmin, licenceTypeAccess } = useSelector(
    (state: StateDTO) => state.projects
  );

  const { t } = useTranslation();

  return (
    <>
      <Tooltip
        title={
          licenceTypeAccess === 'pro'
            ? t('APP.FAVORITESICONS.INDEX.PH_1FE1F3', {
                defaultValue: 'Mettre en favoris',
              })
            : t('APP.FAVORITESICONS.INDEX.PH_CAFBB1', {
                defaultValue:
                  'Fonctionnalité disponible avec une licence pro seulement',
              })
        }
        style={{
          cursor: 'pointer',
          display: 'block',
          marginRight: screen === 'xmlScreen' ? '5px' : '10px',
          color:
            addXmlFavorite !== 'user' && !userFavoritesIds?.includes(SheetId)
              ? undefined
              : '#92AAE7',
        }}
      >
        {!userFavoritesIds?.includes(SheetId) && addXmlFavorite !== 'user' ? (
          <StarBorderIcon
            style={{
              color: licenceTypeAccess === 'pro' && !!searchBarValue ? '' : 'gray',
              cursor: !!searchBarValue ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (!!searchBarValue) {
                if (licenceTypeAccess === 'pro') {
                  screen === 'xmlScreen'
                    ? dispatch(updateFavoritesValue('addXmlFavorite', 'user'))
                    : dispatch(toggleFavorites(SheetId, null));
                }
              }
            }}
          />
        ) : (
          <StarIcon
            style={{
              fill:
                licenceTypeAccess === 'pro' && !!searchBarValue ? '#8ea9db' : 'gray',
              cursor: !!searchBarValue ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (!!searchBarValue) {
                screen === 'xmlScreen'
                  ? dispatch(updateFavoritesValue('addXmlFavorite', null))
                  : dispatch(toggleFavorites(SheetId, null));
              }
            }}
          />
        )}
      </Tooltip>
      {(isAdminOrga || isCompanyAdmin) && (
        <Tooltip
          title={
            licenceTypeAccess === 'pro'
              ? t('APP.FAVORITESICONS.INDEX.PH_A16E56', {
                  defaultValue: 'Mettre en favoris entreprise',
                })
              : t('APP.FAVORITESICONS.INDEX.PH_BBA59E', {
                  defaultValue:
                    'Fonctionnalité disponible avec une licence pro seulement',
                })
          }
        >
          {companyFavoritesIds?.includes(SheetId) || addXmlFavorite === 'company' ? (
            <img
              style={{
                height: '20px',
                cursor: !!searchBarValue ? 'pointer' : 'default',
                display: 'block',
                fill:
                  licenceTypeAccess === 'pro' && !!searchBarValue
                    ? '#8ea9db'
                    : 'gray',
              }}
              onClick={() => {
                if (licenceTypeAccess === 'pro' && !!searchBarValue) {
                  screen === 'xmlScreen'
                    ? dispatch(updateFavoritesValue('addXmlFavorite', null))
                    : dispatch(toggleFavorites(SheetId, true));
                }
              }}
              src={StarOrgaEmpty}
              alt=""
            />
          ) : (
            <img
              style={{
                height: '20px',
                cursor: !!searchBarValue ? 'pointer' : 'default',
                display: 'block',
                filter:
                  screen === 'list' && !!searchBarValue
                    ? 'invert(100%) sepia(100%) saturate(0%) hue-rotate(146deg) brightness(500%) contrast(101%)'
                    : 'invert(0%) sepia(100%) saturate(0%) hue-rotate(269deg) brightness(40%) contrast(108%)',
              }}
              onClick={() => {
                if (licenceTypeAccess === 'pro' && !!searchBarValue) {
                  screen === 'xmlScreen'
                    ? dispatch(updateFavoritesValue('addXmlFavorite', 'company'))
                    : dispatch(toggleFavorites(SheetId, true));
                }
              }}
              src={StarOrgaBorder}
              alt=""
            />
          )}
        </Tooltip>
      )}
    </>
  );
};

export default FavoritesIcons;
