import { Button } from '@photocarbone/nooco-ui';
import { Controller, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import {
  EvacuatedEarthWork,
  EvacuatedEarthWorkIndividualHouse,
} from '../../../../../models/CalculatorDTO';
import { Input, Select } from 'antd';
import { MasterDataEquipmentDTO } from '../../../../../models/SimulatorDTO';
import {
  REFERENCE_TYPE_NAME,
  TEMPLATE_TYPE_NAME,
} from '../../../../../globalVariable/typeProject';
import { StateDTO } from '../../../../../models/GeneralDTO';
import {
  calculatorUpdate,
  updateCalculatorData,
} from '../../../../../../actions/calculator';
import {
  findAllAllotmentNodeChildrenFromFilteredParentAllotmentNodes,
  findMdsFromFilteredParentAllotmentNodes,
} from '../../../../../shared/utils';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoIcon from '@material-ui/icons/Info';
import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import useCaseWorksite from './UseCaseWorksite';
import { useTranslation } from 'react-i18next';

type WorksiteEvacuatedProps = {
  fieldsEvacuatedForm: {
    fields: EvacuatedEarthWork[];
    append: Function;
    remove: Function;
    replace: Function;
    insert: Function;
  };
  fieldsEvacuatedIndividualHouseForm: {
    fields: EvacuatedEarthWorkIndividualHouse[];
    append: Function;
    remove: Function;
    replace: Function;
    insert: Function;
  };
  changeSourceOfEmptyRows: Function;
};

const WorksiteEvacuated = ({
  fieldsEvacuatedForm,
  fieldsEvacuatedIndividualHouseForm,
  changeSourceOfEmptyRows,
}: WorksiteEvacuatedProps) => {
  const { isWorksiteCalculatorSimplified, worksiteCalculatorDetailedActive } =
    useSelector((state: StateDTO) => state.projects.calculator);
  const { zoneSelected } = useSelector(
    (state: StateDTO) => state.monitoring.breadcrumbMultizone
  );
  const { templateType } = useSelector(
    (state: StateDTO) => state.projects.currentProject
  );
  const { zones } = useSelector((state: StateDTO) => state.projects.parsedProject);
  const { isMenuOpen } = useSelector((state: StateDTO) => state.projects);
  const {
    control,
    register,
    getValues,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useFormContext();
  const { fields, append, remove, replace, insert } = fieldsEvacuatedForm;
  const {
    fields: fieldsEvacuatedIndividualHouse,
    append: appendEvacuatedIndividualHouse,
    remove: removeEvacuatedIndividualHouse,
    replace: replaceEvacuatedIndividualHouse,
    insert: insertEvacuatedIndividualHouse,
  } = fieldsEvacuatedIndividualHouseForm;
  const isSmallScreenMode = window.innerWidth < 1550;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    onSubmitScreen: onSubmit,
    isEquipmentEditable,
    isEquipmentFromParentZone,
    calculateAreaZoneProrata,
    isDisplayEquipment,
    isProjectIsOnlyIndividualHouse,
    getPreviousScreenActiveId,
    getNextScreenActiveId,
    zoneSourceToDisplay,
  } = useCaseWorksite();

  const evacuatedEarthWork = watch('evacuatedEarthWork');
  const evacuatedEarthWorkIndividualHouse = watch(
    'evacuatedEarthWorkIndividualHouse'
  );

  // need for Select from antd
  const { Option } = Select;

  const { flatAllotments } = useSelector(
    (state: StateDTO) => state.projects.allGroups
  );
  const [filteredAllotments, setFilteredAllotments] = useState<
    MasterDataEquipmentDTO[]
  >([]);

  useEffect(() => {
    if (filteredAllotments.length === 0) {
      let AllotmentNodesEquipments =
        findAllAllotmentNodeChildrenFromFilteredParentAllotmentNodes(
          flatAllotments,
          ['Terre'],
          'keep'
        );

      AllotmentNodesEquipments = AllotmentNodesEquipments.filter((allotmentNode) =>
        allotmentNode.name.includes(
          [TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(templateType)
            ? REFERENCE_TYPE_NAME.EPCM
            : REFERENCE_TYPE_NAME.RE2020
        )
      );

      setFilteredAllotments(AllotmentNodesEquipments);
    }
  }, []);

  useEffect(() => {}, [isSmallScreenMode]);

  const onError = (errors) => {
    console.log('errors', errors);
  };

  const onSubmitPrevious = (data) => {
    if (isValid) {
      changeSourceOfEmptyRows({
        zone: zoneSelected,
        sideBarActiveId: getPreviousScreenActiveId(),
      });
      dispatch(
        calculatorUpdate(
          'worksiteCalculatorDetailedActive',
          worksiteCalculatorDetailedActive - 1
        )
      );
    } else {
      onSubmit(data, 'previous');
    }
  };

  const onSubmitNext = (data) => {
    if (isValid) {
      changeSourceOfEmptyRows({
        zone: zoneSelected,
        sideBarActiveId: getNextScreenActiveId(),
      });
      dispatch(
        calculatorUpdate(
          'worksiteCalculatorDetailedActive',
          worksiteCalculatorDetailedActive + 1
        )
      );
    } else {
      onSubmit(data, 'next');
    }
  };

  const onBlur = (data) => {
    // console.log("OnBlur Evacuated data to send",
    //   data);
    //change state of
    // dispatch(updateCalculatorData('evacuatedLand', data));
    dispatch(calculatorUpdate('calculatorData', data));
  };

  const onBlurError = (errors) => {
    console.log('onBlur evacuated validation errors', errors);
  };

  const isIndividualHouseCalculatorSimplified = () => {
    return isWorksiteCalculatorSimplified && isProjectIsOnlyIndividualHouse();
  };

  return (
    <>
      {/* titles */}
      <div
        className={`calculatorFlexStart ${
          isSmallScreenMode ? 'isSmallScreenMode' : ''
        }`}
        style={{ marginBottom: '0,5rem', padding: '0 10px' }}
      >
        {!isIndividualHouseCalculatorSimplified() && (
          <h3
            className="calculator__label "
            style={{
              width: isSmallScreenMode ? '300px' : '360px',
              maxWidth: isSmallScreenMode && isMenuOpen ? '250px' : '310px',
            }}
          >
            {t('APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_259729', {
              defaultValue: 'Type de traitement des terres évacuées',
            })}
          </h3>
        )}

        <h3
          className="calculator__label "
          style={{
            width: isSmallScreenMode ? '240px' : '350px',
            marginRight: isSmallScreenMode ? '8px' : '',
            maxWidth: isSmallScreenMode && isMenuOpen ? '200px' : '310px',
          }}
        >
          {t(
            'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_6C502D',

            {
              isSimplified: isIndividualHouseCalculatorSimplified(),
              templateType: ![
                TEMPLATE_TYPE_NAME.EPCM,
                TEMPLATE_TYPE_NAME.BBCA,
              ].includes(templateType),
              defaultValue:
                '{isSimplified, select, true {Surface de plancher du rez-de-chaussée (m²)} other {Quantités de terres évacuées du chantier {templateType, select, true {tonnes} other {kg}}}}',
            }
          )}
        </h3>
        <div
          style={{
            width: isSmallScreenMode ? '280px' : '350px',
            marginRight: isSmallScreenMode ? '8px' : '',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginBottom: '0.5rem',
            maxWidth: isSmallScreenMode && isMenuOpen ? '200px' : '310px',
          }}
        >
          {![TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(
            templateType
          ) && (
            <h3 className="calculator__label ">
              {t('APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_2116EE', {
                defaultValue: 'Distance chantier-lieu traitement des terres (km)',
              })}
            </h3>
          )}

          {isIndividualHouseCalculatorSimplified() && (
            <div style={{ marginBottom: '0.5rem', marginLeft: '0.5rem' }}>
              <Tooltip
                title={t(
                  'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_5466DA',
                  {
                    defaultValue:
                      "En calcul simplifié, la distance d'approvisionnement du chantier peut être prise par défaut à 30km",
                  }
                )}
              >
                <InfoIcon
                  style={{
                    marginLeft: '5px',
                    marginBottom: '0.5rem',
                    width: '18px',
                    height: '18px',
                  }}
                />
              </Tooltip>
            </div>
          )}
        </div>

        {/* source */}
        <h3
          className="calculator__label "
          style={{
            width: isSmallScreenMode ? '100px' : '200px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {t('APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_EC2CBC', {
            defaultValue: 'Source',
          })}
        </h3>
        <div style={{ flexGrow: '1', minWidth: '50px' }}></div>
      </div>

      {
        // If not calculatorSimplified individualHouse
        !isIndividualHouseCalculatorSimplified() ? (
          <form
            className="calculatorFlexColumnStart calculatorCardWrapper"
            style={{ flexGrow: '1' }}
            action=""
          >
            {/* container of array of evacuatedLand */}
            <div className="calculatorScrollContainer">
              {/* array of evacuatedLand start */}
              {fields.map((item, index) => {
                // display editable row
                if (isEquipmentEditable(item)) {
                  return (
                    <div
                      key={item.id}
                      className="calculatorFlexStart cardWrapper"
                      style={{ width: '100%' }}
                    >
                      {/* traitment */}
                      <div
                        style={{
                          width: isSmallScreenMode ? '300px' : '360px',
                          paddingRight: '1rem',
                          maxWidth:
                            isSmallScreenMode && isMenuOpen ? '250px' : '310px',
                        }}
                      >
                        <Controller
                          control={control}
                          {...register(
                            `evacuatedEarthWork.${index}.AllotmentNode.id`
                          )}
                          render={({ field }) => (
                            <Select
                              {...field}
                              style={{ maxWidth: '440px' }}
                              // type='number'
                              key={`autocomplete-${item.id}`}
                              showSearch
                              placeholder="Choisir un type de traitement"
                              status={
                                errors?.evacuatedEarthWork?.[index]?.AllotmentNode
                                  ?.message
                                  ? 'error'
                                  : ''
                              }
                              onBlur={handleSubmit(onBlur, onBlurError)}
                            >
                              {filteredAllotments.map((Allotment, index) => {
                                // console.log(Allotment);
                                return (
                                  <Option
                                    key={index}
                                    value={Allotment.id}
                                    style={{ maxWidth: '420px' }}
                                  >
                                    {Allotment.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </div>

                      <div>
                        {errors.evacuatedEarthWork && (
                          <div className="container__main--errorMessage">
                            {
                              errors?.evacuatedEarthWork?.[index]?.AllotmentNode
                                ?.message
                            }
                          </div>
                        )}
                      </div>

                      {/* quantity */}
                      <div
                        className="calculatorFlexColumnStart "
                        style={{
                          width: isSmallScreenMode ? '240px' : '350px',
                          marginRight: isSmallScreenMode ? '8px' : '',
                          maxWidth:
                            isSmallScreenMode && isMenuOpen ? '200px' : '310px',
                        }}
                      >
                        <div
                          className="calculatorFlexStart"
                          style={{
                            width: isSmallScreenMode ? '240px' : '350px',
                            marginRight: isSmallScreenMode ? '8px' : '',
                            maxWidth:
                              isSmallScreenMode && isMenuOpen ? '200px' : '310px',
                          }}
                        >
                          <div
                            className="calculator__subLabel"
                            style={{ marginBottom: 0, alignItems: 'center' }}
                          >
                            <span className="calculator__label--grow">Q</span>
                            {t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_95AD38',
                              {
                                value: index + 1,
                                defaultValue: 'terres évac,{value}',
                              }
                            )}
                          </div>
                          {/* <Input  placeholder="Saisir une quantité" style={{width: '190px', marginLeft: '1rem'}}  /> */}
                          <Controller
                            control={control}
                            {...register(`evacuatedEarthWork.${index}.quantity`)}
                            rules={{
                              required: evacuatedEarthWork[index].AllotmentNode?.id
                                ? t(
                                    'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_F413BB',
                                    { defaultValue: 'Champ obligatoire' }
                                  )
                                : false,
                              validate: {
                                // rules={{ validate: {
                                isNumber: (v) => {
                                  if (!isNaN(Number(v.replace(/,/, '.')))) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                },
                              },
                            }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                size="large"
                                placeholder={t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_69DF74',
                                  { defaultValue: 'Saisir une quantité' }
                                )}
                                style={{ width: '190px', marginLeft: '1rem' }}
                                status={
                                  errors?.evacuatedEarthWork?.[index]?.quantity
                                    ? 'error'
                                    : ''
                                }
                                // onBlur={handleSubmit(onBlur, onBlurError)}
                                disabled={
                                  watch('evacuatedEarthWork')[index].AllotmentNode
                                    ?.id
                                    ? false
                                    : true
                                }
                              />
                            )}
                          />
                        </div>
                        {errors.evacuatedEarthWork && (
                          <div
                            className="container__main--errorMessage"
                            style={{ marginLeft: '120px' }}
                          >
                            {errors?.evacuatedEarthWork?.[index]?.quantity?.type ===
                            'isNumber'
                              ? t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_A08F64',
                                  {
                                    defaultValue: 'Vous devez entrer un nombre',
                                  }
                                )
                              : errors?.evacuatedEarthWork?.[index]?.quantity
                                  ?.message}
                          </div>
                        )}
                      </div>

                      {/* distance */}
                      {![TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(
                        templateType
                      ) && (
                        <div className="calculatorFlexColumnStart">
                          <div
                            className="calculatorFlexStart"
                            style={{
                              width: isSmallScreenMode ? '280px' : '350px',
                              marginRight: isSmallScreenMode ? '8px' : '',
                              maxWidth:
                                isSmallScreenMode && isMenuOpen ? '200px' : '310px',
                            }}
                          >
                            <div
                              className="calculator__subLabel"
                              style={{ marginBottom: 0, alignItems: 'center' }}
                            >
                              <span className="calculator__label--grow">d</span>
                              {`chantier-trait${index + 1}`}
                            </div>
                            <Controller
                              control={control}
                              {...register(`evacuatedEarthWork.${index}.distance`)}
                              rules={{
                                required: evacuatedEarthWork[index].AllotmentNode?.id
                                  ? t(
                                      'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_D52284',
                                      { defaultValue: 'Champ obligatoire' }
                                    )
                                  : false,
                                validate: {
                                  // rules={{ validate: {
                                  isNumber: (v) => {
                                    if (!isNaN(Number(v.replace(/,/, '.')))) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  },
                                },
                              }}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  placeholder={t(
                                    'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_B65379',
                                    { defaultValue: 'Distance' }
                                  )}
                                  style={{ width: '190px', marginLeft: '1rem' }}
                                  status={
                                    errors?.evacuatedEarthWork?.[index]?.distance
                                      ? 'error'
                                      : ''
                                  }
                                  disabled={
                                    watch('evacuatedEarthWork')[index].AllotmentNode
                                      ?.id
                                      ? false
                                      : true
                                  }
                                  // onBlur={handleSubmit(onBlur, onBlurError)}
                                />
                              )}
                            />
                          </div>
                          {errors.evacuatedEarthWork && (
                            <div
                              className="container__main--errorMessage"
                              style={{ marginLeft: '120px' }}
                            >
                              {errors?.evacuatedEarthWork?.[index]?.distance
                                ?.type === 'isNumber'
                                ? t(
                                    'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_C651E8',
                                    {
                                      defaultValue: 'Vous devez entrer un nombre',
                                    }
                                  )
                                : errors?.evacuatedEarthWork?.[index]?.distance
                                    ?.message}
                            </div>
                          )}
                        </div>
                      )}

                      {/* source indicator */}
                      <div
                        style={{
                          width: isSmallScreenMode ? '100px' : '200px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {zoneSourceToDisplay(item.origin)}
                      </div>

                      {/* Delete energy Type */}
                      <div
                        style={{
                          display: 'flex',
                          flexGrow: '1',
                          justifyContent: 'flex-end',
                          minWidth: '50px',
                        }}
                        onClick={() => {
                          // console.log(watch('evacuatedEarthWork').length);
                          // console.log(watch('evacuatedEarthWork'));
                          if (!isEquipmentEditable(item)) {
                            return;
                          }

                          if (watch('evacuatedEarthWork').length > 1) {
                            remove(index);
                            dispatch(calculatorUpdate('resultOfSearchCarac', null));
                          } else {
                            // resetField('energy.energiesArray');
                            replace({
                              AllotmentNode: null,
                              // masterDataId: null,
                              quantity: '',
                              distance: '',
                              origin: {
                                type: zoneSelected?.type || 'project',
                                sourceId: zoneSelected?.id || null,
                                uniqueId: uuidv4(),
                              },
                            });
                            remove(index);
                            insert(0, {
                              AllotmentNode: null,
                              // masterDataId: null,
                              quantity: '',
                              distance: '',
                              origin: {
                                type: zoneSelected?.type || 'project',
                                sourceId: zoneSelected?.id || null,
                                uniqueId: uuidv4(),
                              },
                            });
                          }
                        }}
                      >
                        <HighlightOffIcon
                          style={{ width: '40px', height: '40px' }}
                          className="calculator__delElement"
                        />
                      </div>
                    </div>
                  );
                }

                // display not editable row
                if (isDisplayEquipment(item)) {
                  let quantity = getValues(`evacuatedEarthWork.${index}.quantity`);

                  if (isEquipmentFromParentZone(item)) {
                    const quantityProrata = calculateAreaZoneProrata(item);

                    quantity =
                      Math.round(quantity * quantityProrata * 10000) / 10000;
                  }

                  return (
                    <div
                      key={item.id}
                      className="calculatorFlexStart cardWrapper disabled"
                      style={{ width: '100%' }}
                    >
                      {/* traiment */}
                      <div
                        style={{
                          width: isSmallScreenMode ? '240px' : '360px',
                          paddingRight: '1rem',
                          maxWidth:
                            isSmallScreenMode && isMenuOpen ? '250px' : '360px',
                        }}
                      >
                        <Controller
                          control={control}
                          {...register(
                            `evacuatedEarthWork.${index}.AllotmentNode.id`
                          )}
                          render={({ field }) => (
                            <Select
                              {...field}
                              style={{ maxWidth: '440px' }}
                              // type='number'
                              key={`autocomplete-${item.id}`}
                              showSearch
                              // defaultValue={evacuatedEarthWork[index].AllotmentNode.id}
                              // defaultValue={"Traitement des déchets dangereux par incinération (RE2020)"}

                              placeholder={t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_AF4649',
                                {
                                  defaultValue: 'Choisir un type de traitement',
                                }
                              )}
                              status={
                                errors?.evacuatedEarthWork?.[index]?.AllotmentNode
                                  ?.message
                                  ? 'error'
                                  : ''
                              }
                              disabled={true}
                            >
                              {filteredAllotments.map((Allotment, index) => {
                                // console.log(Allotment);
                                return (
                                  <Option
                                    key={index}
                                    value={Allotment.id}
                                    style={{ maxWidth: '420px' }}
                                  >
                                    {Allotment.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </div>

                      {/* quantity */}
                      <div className="calculatorFlexColumnStart ">
                        <div
                          className="calculatorFlexStart"
                          style={{
                            width: isSmallScreenMode ? '240px' : '350px',
                            marginRight: isSmallScreenMode ? '8px' : '',
                            maxWidth:
                              isSmallScreenMode && isMenuOpen ? '200px' : '310px',
                          }}
                        >
                          <div
                            className="calculator__subLabel"
                            style={{ marginBottom: 0, alignItems: 'center' }}
                          >
                            <span className="calculator__label--grow">Q</span>
                            {`terres évac,${index + 1}`}
                          </div>

                          <Input
                            size="large"
                            placeholder={t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_EB4466',
                              { defaultValue: 'Saisir une quantité' }
                            )}
                            style={{ width: '190px', marginLeft: '1rem' }}
                            value={quantity}
                            disabled={true}
                          />
                        </div>
                      </div>

                      {/* distance */}
                      {![TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(
                        templateType
                      ) && (
                        <div className="calculatorFlexColumnStart">
                          <div
                            className="calculatorFlexStart"
                            style={{
                              width: isSmallScreenMode ? '280px' : '350px',
                              marginRight: isSmallScreenMode ? '8px' : '',
                              maxWidth:
                                isSmallScreenMode && isMenuOpen ? '200px' : '310px',
                            }}
                          >
                            <div
                              className="calculator__subLabel"
                              style={{ marginBottom: 0, alignItems: 'center' }}
                            >
                              <span className="calculator__label--grow">d</span>
                              {`chantier-trait${index + 1}`}
                            </div>
                            <Controller
                              control={control}
                              {...register(`evacuatedEarthWork.${index}.distance`)}
                              rules={{
                                required: evacuatedEarthWork[index].AllotmentNode?.id
                                  ? 'Champ obligatoire'
                                  : false,
                                validate: {
                                  // rules={{ validate: {
                                  isNumber: (v) => {
                                    if (!isNaN(Number(v.replace(/,/, '.')))) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  },
                                },
                              }}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  placeholder="Distance"
                                  style={{ width: '190px', marginLeft: '1rem' }}
                                  status={
                                    errors?.evacuatedEarthWork?.[index]?.distance
                                      ? 'error'
                                      : ''
                                  }
                                  disabled={true}
                                  // onBlur={handleSubmit(onBlur, onBlurError)}
                                />
                              )}
                            />
                          </div>
                          {errors.evacuatedEarthWork && (
                            <div
                              className="container__main--errorMessage"
                              style={{ marginLeft: '120px' }}
                            >
                              {errors?.evacuatedEarthWork?.[index]?.distance
                                ?.type === 'isNumber'
                                ? t(
                                    'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_9319B8',
                                    {
                                      defaultValue: 'Vous devez entrer un nombre',
                                    }
                                  )
                                : errors?.evacuatedEarthWork?.[index]?.distance
                                    ?.message}
                            </div>
                          )}
                        </div>
                      )}

                      {/* source indicator */}
                      <div
                        style={{
                          width: isSmallScreenMode ? '100px' : '200px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#268F82',
                        }}
                      >
                        {zoneSourceToDisplay(item.origin)}
                      </div>

                      {/* Delete energy Type */}
                      <div
                        style={{
                          display: 'flex',
                          flexGrow: '1',
                          justifyContent: 'flex-end',
                          minWidth: '50px',
                        }}
                      />
                    </div>
                  );
                }

                return null;
              })}
              {/* end array of evacuatedLand start */}
            </div>

            {/* add button */}
            <div
              className="calculator__addElement"
              onClick={() =>
                append({
                  masterDataId: undefined,
                  quantity: '',
                  distance: '',
                  AllotmentNode: null,
                  origin: {
                    type: zoneSelected?.type || 'project',
                    sourceId: zoneSelected?.id || null,
                    uniqueId: uuidv4(),
                  },
                })
              }
            >
              <ControlPointIcon />
              {t('APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_C5730D', {
                defaultValue: 'Ajouter une autre quantité de terres évacuées',
              })}
            </div>

            {/* next Button and Previus Button */}
            <div
              className="calculatorFlexColumnStart"
              style={{
                flexGrow: 1,
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <div
                className="calculatorFlexStart"
                style={{ justifyContent: 'flex-end' }}
              >
                <Button
                  style={{
                    fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                  }}
                  className="close-button"
                  onClick={handleSubmit(onSubmitPrevious, onError)}
                  disabled={getPreviousScreenActiveId() === null}
                  text="Précédent"
                />
                {![TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(
                  templateType
                ) && (
                  // <div
                  //   className='calculator__nextButton'

                  //   onClick={handleSubmit(onSubmit, onError)
                  //   }
                  // >
                  //   <p>Suivant</p>
                  //   <DoubleRightOutlined className=' calculator__nextButton--icon'/>
                  // </div>
                  <Button
                    style={{
                      fontSize:
                        window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                      marginLeft: '5px',
                    }}
                    className="purple-button"
                    onClick={handleSubmit(onSubmitNext, onError)}
                    disabled={getNextScreenActiveId() === null}
                    text="Suivant"
                  />
                )}
              </div>
            </div>
          </form>
        ) : (
          // If calculatorSimplified individualHouse
          <form
            className="calculatorFlexColumnStart calculatorCardWrapper"
            style={{ flexGrow: '1' }}
            action=""
          >
            {/* container of array of evacuatedLand */}
            <div className="calculatorScrollContainer">
              {/* array of evacuatedLand start */}
              {fieldsEvacuatedIndividualHouse.map((item, index) => {
                // display editable row
                if (isEquipmentEditable(item)) {
                  return (
                    <div
                      key={item.id}
                      className="calculatorFlexStart cardWrapper"
                      style={{ width: '100%' }}
                    >
                      {/* area */}
                      <div className="calculatorFlexColumnStart">
                        <div
                          className="calculatorFlexStart"
                          // style={{minWidth: '350px'}}
                          style={{
                            width: isSmallScreenMode ? '240px' : '350px',
                            marginRight: isSmallScreenMode ? '8px' : '',
                            maxWidth:
                              isSmallScreenMode && isMenuOpen ? '200px' : '310px',
                          }}
                        >
                          <div
                            className="calculator__subLabel"
                            style={{ marginBottom: 0, alignItems: 'center' }}
                          >
                            <span className="calculator__label--grow">S</span>
                            {t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_17A1FD',
                              { defaultValue: 'plancher RDC' }
                            )}
                          </div>
                          <Controller
                            control={control}
                            {...register(
                              `evacuatedEarthWorkIndividualHouse.${index}.area`
                            )}
                            rules={{
                              required: false,
                              validate: {
                                // rules={{ validate: {
                                isNumber: (v) => {
                                  if (!isNaN(Number(v.replace(/,/, '.')))) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                },
                              },
                            }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                size="large"
                                placeholder={t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_51CA3C',
                                  { defaultValue: 'Saisir une quantité' }
                                )}
                                style={{ width: '190px', marginLeft: '1rem' }}
                                status={
                                  errors?.evacuatedEarthWorkIndividualHouse?.[index]
                                    ?.area
                                    ? 'error'
                                    : ''
                                }
                              />
                            )}
                          />
                        </div>
                        {errors.evacuatedEarthWorkIndividualHouse && (
                          <div
                            className="container__main--errorMessage"
                            style={{ marginLeft: '120px' }}
                          >
                            {errors?.evacuatedEarthWorkIndividualHouse?.[index]?.area
                              ?.type === 'isNumber'
                              ? t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_A4F895',
                                  {
                                    defaultValue: 'Vous devez entrer un nombre',
                                  }
                                )
                              : errors?.evacuatedEarthWorkIndividualHouse?.[index]
                                  ?.area?.message}
                          </div>
                        )}
                      </div>

                      {/* distance */}
                      {![TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(
                        templateType
                      ) && (
                        <Tooltip
                          title={
                            isProjectIsOnlyIndividualHouse()
                              ? t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_69CDD5',
                                  {
                                    defaultValue:
                                      'En calcul simplifié, la distance d’approvisionnement du chantier peut être prise par défaut à 30 km',
                                  }
                                )
                              : ''
                          }
                        >
                          <div className="calculatorFlexColumnStart">
                            <div
                              className="calculatorFlexStart"
                              style={{
                                width: isSmallScreenMode ? '280px' : '350px',
                                marginRight: isSmallScreenMode ? '8px' : '',
                                maxWidth:
                                  isSmallScreenMode && isMenuOpen
                                    ? '200px'
                                    : '310px',
                              }}
                            >
                              <div
                                className="calculator__subLabel"
                                style={{
                                  marginBottom: 0,
                                  alignItems: 'center',
                                }}
                              >
                                <span className="calculator__label--grow">d</span>
                                {``}
                                {t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_EE885A',
                                  {
                                    value: index + 1,
                                    defaultValue: 'imp,{value}-chantier',
                                  }
                                )}
                              </div>
                              <Controller
                                control={control}
                                {...register(
                                  `evacuatedEarthWorkIndividualHouse.${index}.distance`
                                )}
                                rules={{
                                  required: false,
                                  validate: {
                                    // rules={{ validate: {
                                    isNumber: (v) => {
                                      if (!isNaN(Number(v.replace(/,/, '.')))) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    },
                                  },
                                }}
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    placeholder={t(
                                      'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_B20A96',
                                      { defaultValue: 'Distance' }
                                    )}
                                    style={{
                                      width: '190px',
                                      marginLeft: '1rem',
                                    }}
                                    status={
                                      errors?.evacuatedEarthWorkIndividualHouse?.[
                                        index
                                      ]?.distance
                                        ? 'error'
                                        : ''
                                    }
                                  />
                                )}
                              />
                            </div>
                            {errors.evacuatedEarthWorkIndividualHouse && (
                              <div
                                className="container__main--errorMessage"
                                style={{ marginLeft: '120px' }}
                              >
                                {errors?.evacuatedEarthWorkIndividualHouse?.[index]
                                  ?.distance?.type === 'isNumber'
                                  ? t(
                                      'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_78DB8F',
                                      {
                                        defaultValue: 'Vous devez entrer un nombre',
                                      }
                                    )
                                  : errors?.evacuatedEarthWorkIndividualHouse?.[
                                      index
                                    ]?.distance?.message}
                              </div>
                            )}
                          </div>
                        </Tooltip>
                      )}

                      {/* source indicator */}
                      <div
                        style={{
                          width: isSmallScreenMode ? '100px' : '200px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {zoneSourceToDisplay(item.origin)}
                      </div>

                      {/* Delete energy Type */}
                      <div
                        style={{
                          display: 'flex',
                          flexGrow: '1',
                          justifyContent: 'flex-end',
                          minWidth: '50px',
                        }}
                        onClick={() => {
                          // console.log(watch('evacuatedEarthWork').length);
                          // console.log(watch('evacuatedEarthWork'));
                          if (!isEquipmentEditable(item)) {
                            return;
                          }

                          if (evacuatedEarthWorkIndividualHouse.length > 1) {
                            removeEvacuatedIndividualHouse(index);
                          } else if (evacuatedEarthWorkIndividualHouse.length <= 1) {
                            replaceEvacuatedIndividualHouse({
                              area: '',
                              distance: '30',
                              origin: {
                                type: zoneSelected?.type || 'project',
                                sourceId: zoneSelected?.id || null,
                                uniqueId: uuidv4(),
                              },
                            });
                            removeEvacuatedIndividualHouse(index);
                            insertEvacuatedIndividualHouse(0, {
                              area: '',
                              distance: '30',
                              origin: {
                                type: zoneSelected?.type || 'project',
                                sourceId: zoneSelected?.id || null,
                                uniqueId: uuidv4(),
                              },
                            });
                          }

                          if (evacuatedEarthWork.length > 1) {
                            remove(index);
                            dispatch(calculatorUpdate('resultOfSearchCarac', null));
                          } else if (evacuatedEarthWork.length <= 1) {
                            // resetField('energy.energiesArray');
                            replace({
                              AllotmentNode: null,
                              // masterDataId: null,
                              quantity: '',
                              distance: '',
                              origin: {
                                type: zoneSelected?.type || 'project',
                                sourceId: zoneSelected?.id || null,
                                uniqueId: uuidv4(),
                              },
                            });
                            remove(index);
                            insert(0, {
                              AllotmentNode: null,
                              // masterDataId: null,
                              quantity: '',
                              distance: '',
                              origin: {
                                type: zoneSelected?.type || 'project',
                                sourceId: zoneSelected?.id || null,
                                uniqueId: uuidv4(),
                              },
                            });
                          }
                        }}
                      >
                        <HighlightOffIcon
                          style={{ width: '40px', height: '40px' }}
                          className="calculator__delElement"
                        />
                      </div>
                    </div>
                  );
                }

                // display not editable row
                if (isDisplayEquipment(item)) {
                  let area = getValues(
                    `evacuatedEarthWorkIndividualHouse.${index}.area`
                  );

                  if (isEquipmentFromParentZone(item)) {
                    const quantityProrata = calculateAreaZoneProrata(item);

                    area = Math.round(area * quantityProrata * 10000) / 10000;
                  }

                  return (
                    <div
                      key={item.id}
                      className="calculatorFlexStart cardWrapper disabled"
                      style={{ width: '100%' }}
                    >
                      {/* area */}
                      <div className="calculatorFlexColumnStart ">
                        <div
                          className="calculatorFlexStart"
                          style={{
                            width: isSmallScreenMode ? '240px' : '350px',
                            marginRight: isSmallScreenMode ? '8px' : '',
                            maxWidth:
                              isSmallScreenMode && isMenuOpen ? '200px' : '310px',
                          }}
                        >
                          <div
                            className="calculator__subLabel"
                            style={{ marginBottom: 0, alignItems: 'center' }}
                          >
                            <span className="calculator__label--grow">S</span>
                            {`plancher RDC`}
                          </div>

                          <Input
                            size="large"
                            placeholder={t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_FF3E4C',
                              { defaultValue: 'Saisir une quantité' }
                            )}
                            style={{ width: '190px', marginLeft: '1rem' }}
                            value={area}
                            disabled={true}
                          />
                        </div>
                      </div>

                      {/* distance */}
                      {![TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(
                        templateType
                      ) && (
                        <div className="calculatorFlexColumnStart">
                          <div
                            className="calculatorFlexStart"
                            style={{
                              width: isSmallScreenMode ? '260px' : '350px',
                              marginRight: isSmallScreenMode ? '8px' : '',
                              maxWidth:
                                isSmallScreenMode && isMenuOpen ? '200px' : '310px',
                            }}
                          >
                            <div
                              className="calculator__subLabel"
                              style={{ marginBottom: 0, alignItems: 'center' }}
                            >
                              <span className="calculator__label--grow">d</span>
                              {t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_AFDB5D',
                                {
                                  value: index + 1,
                                  defaultValue: 'chantier-trait${value}',
                                }
                              )}
                            </div>
                            <Controller
                              control={control}
                              {...register(
                                `evacuatedEarthWorkIndividualHouse.${index}.distance`
                              )}
                              rules={{
                                required: false,
                                validate: {
                                  // rules={{ validate: {
                                  isNumber: (v) => {
                                    if (!isNaN(Number(v.replace(/,/, '.')))) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  },
                                },
                              }}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  placeholder={t(
                                    'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_ABDD12',
                                    { defaultValue: 'Distance' }
                                  )}
                                  style={{ width: '190px', marginLeft: '1rem' }}
                                  status={
                                    errors?.evacuatedEarthWorkIndividualHouse?.[
                                      index
                                    ]?.distance
                                      ? 'error'
                                      : ''
                                  }
                                  disabled={true}
                                  // onBlur={handleSubmit(onBlur, onBlurError)}
                                />
                              )}
                            />
                          </div>
                          {errors.evacuatedEarthWork && (
                            <div
                              className="container__main--errorMessage"
                              style={{ marginLeft: '120px' }}
                            >
                              {errors?.evacuatedEarthWork?.[index]?.distance
                                ?.type === 'isNumber'
                                ? t(
                                    'APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_7520F3',
                                    {
                                      defaultValue: 'Vous devez entrer un nombre',
                                    }
                                  )
                                : errors?.evacuatedEarthWork?.[index]?.distance
                                    ?.message}
                            </div>
                          )}
                        </div>
                      )}

                      {/* source indicator */}
                      <div
                        style={{
                          width: isSmallScreenMode ? '100px' : '200px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#268F82',
                        }}
                      >
                        {zoneSourceToDisplay(item.origin)}
                      </div>

                      {/* Delete energy Type */}
                      <div
                        style={{
                          display: 'flex',
                          flexGrow: '1',
                          justifyContent: 'flex-end',
                          minWidth: '50px',
                        }}
                      />
                    </div>
                  );
                }

                return null;
              })}
              {/* end array of evacuatedLand start */}
            </div>

            {/* add button */}
            <div
              className="calculator__addElement"
              onClick={() =>
                appendEvacuatedIndividualHouse({
                  area: '',
                  distance: '30',
                  origin: {
                    type: zoneSelected?.type || 'project',
                    sourceId: zoneSelected?.id || null,
                    uniqueId: uuidv4(),
                  },
                })
              }
            >
              <ControlPointIcon />
              {t('APP.CALCULATORWORKSITECONTENT.WORKSITEEVACUATED.PH_A983C5', {
                defaultValue: 'Ajouter une autre quantité de terres évacuées',
              })}
            </div>

            {/* next Button and Previus Button */}
            <div
              className="calculatorFlexColumnStart"
              style={{
                flexGrow: 1,
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <div
                className="calculatorFlexStart"
                style={{ justifyContent: 'flex-end' }}
              >
                <Button
                  style={{
                    fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                  }}
                  className="close-button"
                  onClick={handleSubmit(onSubmitPrevious, onError)}
                  disabled={getPreviousScreenActiveId() === null}
                  text="Précédent"
                />
                {![TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(
                  templateType
                ) && (
                  <Button
                    style={{
                      fontSize:
                        window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                      marginLeft: '5px',
                    }}
                    className="purple-button"
                    onClick={handleSubmit(onSubmitNext, onError)}
                    disabled={getNextScreenActiveId() === null}
                    text="Suivant"
                  />
                )}
              </div>
            </div>
          </form>
        )
      }
    </>
  );
};

export default WorksiteEvacuated;
