import { AnimatePresence, motion } from 'framer-motion';
import { Button } from '@photocarbone/nooco-ui';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { WidgetsDTO } from '../Widgets/WidgetsDTO';
import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';
import arrowOpenIcon from '../../../../assets/images/project/arrowOpen.png';

import './selectionWidgets.scss';
// import { ProjectDTO } from "../../../models/ProjectDTO";
import { StateDTO } from '../../../models/GeneralDTO';
// import { getOriginalNode } from "typescript";
import { TEMPLATE_TYPE_NAME } from '../../../globalVariable/typeProject';
import { toggleCheckWidget } from '../../../../actions/report';
import { useDispatch, useSelector } from 'react-redux';
import AddchartIcon from '@mui/icons-material/Addchart';
import CloseIcon from '@mui/icons-material/Close';
import NoocoCheckbox from '../../../shared/NoocoCheckbox';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from 'react-i18next';

type SelectionWidgetsProps = {
  widgets: WidgetsDTO[];
  widgetsOpen: boolean;
  updateCheckValue: Function;
  isMultiBuilding: boolean;
  setOriginalLayout: Function;
  // getFromLS: Function,
  // zones: {
  //         id: number,
  //         type: string
  //     }[],
  //     setLayout: Function,
  //     originalLayout: [];
  setResetLocalStorage: Function;
  // currentProject: ProjectDTO
};

const SelectionWidgets = ({
  widgets,
  widgetsOpen,
  updateCheckValue,
  isMultiBuilding,
  setResetLocalStorage,
}: SelectionWidgetsProps): JSX.Element => {
  const dispatch = useDispatch();
  const currentProject = useSelector(
    (state: StateDTO) => state.projects.currentProject
  );
  const { templateType } = currentProject;
  const { t } = useTranslation();

  return (
    <>
      {
        !widgetsOpen && (
          <Tooltip
            title={t('APP.SELECTIONWIDGETS.INDEX.PH_8C10B3', {
              defaultValue: 'Selectionner vos modules',
            })}
          >
            <div
              className={`openButton ${
                templateType === 'nooco'
                  ? 'nooco'
                  : templateType === TEMPLATE_TYPE_NAME.EPCM
                  ? 'epcm'
                  : ''
              }`}
              onClick={() => updateCheckValue('widgetsOpen', !widgetsOpen)}
            >
              <img src={arrowOpenIcon} alt="" />
            </div>
            {/* <motion.div
           initial={{ opacity: 0, right: '0'}}
           animate={{ opacity: 1, right: '50%'}}
           // transition={{duration: 0.4}}
           exit={{ opacity: 0, right: '0',}} className="button-selection-widgets">
           <Button 
             style={{ 
               position: "absolute",
               left: '20px',
               // top: "50%",
               width: '70px', 
               height: '30px',
               // width: '16px',
               // transform: 'rotate(90deg) translateX(50%)',
               // transformOrigin: 'top right',
             }}                 
             onClick={() => updateCheckValue('widgetsOpen',
               !widgetsOpen)} 
             additionalIcon={<AddchartIcon />}
             centeredIcon={true}
             // text="Afficher les modules"
             className="purple-button" 
           />
         </motion.div> */}
          </Tooltip>
        )
        // </AnimatePresence>
      }

      <div
        className={`selection-widgets-container ${
          widgetsOpen ? 'open-widgets-container' : ''
        }`}
      >
        {/* <AnimatePresence> */}

        <AnimatePresence>
          {widgetsOpen && (
            <motion.div
              initial={{ opacity: 0, x: '50%' }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: '50%' }}
              transition={{ duration: 0.3 }}
              className="widgets-list"
              // style={{overflow: 'scroll'}}
            >
              <div className="list">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '10px 10px 20px 10px',
                  }}
                >
                  <CloseIcon
                    style={{ cursor: 'pointer', fontSize: '30px' }}
                    onClick={() => updateCheckValue('widgetsOpen', !widgetsOpen)}
                  />

                  <Tooltip
                    title={t('APP.SELECTIONWIDGETS.INDEX.PH_96D458', {
                      defaultValue: 'Réinitialiser les modules de la synthèse',
                    })}
                  >
                    <ReplayIcon
                      style={{
                        color: '#44DDC3',
                        cursor: 'pointer',
                        fontSize: '30px',
                      }}
                      onClick={() => {
                        console.log('reset au colick sur le bouton reset');

                        localStorage.removeItem(`rgl-${currentProject.id}`);
                        setResetLocalStorage(true);
                        setTimeout(() => {
                          setResetLocalStorage(true);
                        }, 100);
                      }}
                    />
                  </Tooltip>

                  {/* <Button 
                  className="delete-button"
                  style={{width: "auto"}}
                  text="Réinitialiser les modules de la synthèse"
                  onClick={() => {
                  console.log('reset au colick sur le bouton reset');
                  localStorage.removeItem(`rgl-${currentProject.id}`);
                  setResetLocalStorage(true);
                  setTimeout(() => { setResetLocalStorage(true); },
                  100);
                  }}
                  /> */}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h5>
                    {t('APP.SELECTIONWIDGETS.INDEX.PH_3DE0AB', {
                      defaultValue: 'Choix des modules :',
                    })}
                  </h5>
                </div>
                {!isMultiBuilding && (
                  <ul>
                    {widgets
                      .sort((a, b) => a.order - b.order)
                      .map((widget) => (
                        <li
                          className={widget.disabled ? 'widget-disabled' : ''}
                          onClick={() => {
                            dispatch(toggleCheckWidget(widget, currentProject.id));
                          }}
                          key={widget.order}
                        >
                          <NoocoCheckbox
                            disabled={widget.disabled}
                            checked={widget.disabled ? false : widget.checked}
                          />
                          {widget.name}{' '}
                          {widget.disabled ? '(Bientôt disponible)' : null}
                        </li>
                      ))}
                  </ul>
                )}

                {/* TODO_TRAD : fr */}
                {isMultiBuilding && (
                  <ul>
                    {widgets
                      .sort((a, b) => a.order - b.order)
                      .filter(
                        (widget) =>
                          widget.name != 'Eau' &&
                          widget.name != 'Energie' &&
                          widget.name != 'Construction' &&
                          widget.name != 'Produits de Construction et Equipements' &&
                          widget.name != 'Projet' &&
                          widget.name != 'Objectif'
                      )
                      .map((widget) => (
                        <li
                          className={widget.disabled ? 'widget-disabled' : ''}
                          onClick={() => {
                            dispatch(toggleCheckWidget(widget, currentProject.id));
                          }}
                          key={widget.order}
                        >
                          <NoocoCheckbox
                            disabled={widget.disabled}
                            checked={widget.disabled ? false : widget.checked}
                          />

                          {widget.name}
                          {widget.disabled
                            ? t('APP.SELECTIONWIDGETS.INDEX.PH_8BBADF', {
                                defaultValue: '(Bientôt disponible)',
                              })
                            : null}
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default SelectionWidgets;
