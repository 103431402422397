import './notification.scss';
import { HelpDTO } from '../../models/HelpDTO';
import { useSelector } from 'react-redux';
import React from 'react';
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next';

function ReactQuillDisplay(articleBody: string) {
  // const articleBody = useSelector((state: HelpDTO) => state.help.feature.selectedFeature?.articleBody);
  // const [quillValue, setQuillValue] = useState(articleBody);

  return (
    <ReactQuill
      theme="snow"
      value={articleBody}
      // onChange={(e) => {

      //   setQuillValue(e);

      // }}
      // modules={modules}
      readOnly={true}
      // onChangeSelection={(range, source, quill) => {
      //   // temp variable for each change.
      //   articleBodyTemp = quill.getHTML();

      // }}
    />
  );
}

const ReleaseModal = ({ handleCloseNewReleaseNotification }) => {
  const releases = useSelector((state: HelpDTO) => state.help.release.releases);
  const firstReleaseVisible = releases.find((release) => release.isVisible === true);
  const { t } = useTranslation();

  const dateLastRelease = new Date(firstReleaseVisible?.deliveryDate);

  // console.log(dateLastRelease.getDate());
  const day = dateLastRelease.getDate();
  // console.log(dateLastRelease.toDateString());
  const year = dateLastRelease.getFullYear();
  const month = dateLastRelease.getMonth() + 1;

  function formatNumber(monthOrDay) {
    return monthOrDay < 10 ? `0${monthOrDay}` : monthOrDay;
  }

  return (
    <div className="releaseNotification">
      <header className="header">
        <div className="header__title">
          {t('APP.NOTIFICATIONNEWRELEASE.RELEASEMODAL.PH_8C456A', {
            defaultValue: 'NOUVELLE VERSION NOOCO :',
          })}{' '}
          {`${formatNumber(day)}/${formatNumber(month)}/${year}`}
        </div>
        <div className="header__closeContainer">
          <div
            className="header__closeContainer--close"
            onClick={handleCloseNewReleaseNotification}
          >
            {'X'}
          </div>
        </div>
      </header>
      {!firstReleaseVisible?.articleBody && (
        <div className="loader">
          <div className="modal-spinner-wrapper">
            <div className="donut" />
          </div>
        </div>
      )}
      {firstReleaseVisible?.articleBody && (
        <div className="reactQuillWrapper">
          <ReactQuill
            theme="snow"
            value={firstReleaseVisible?.articleBody}
            // onChange={(e) => {

            //   setQuillValue(e);

            // }}
            // modules={modules}
            readOnly={true}
            // onChangeSelection={(range, source, quill) => {
            //   // temp variable for each change.
            //   articleBodyTemp = quill.getHTML();

            // }}
          />
        </div>
      )}
    </div>
  );
};

export default ReleaseModal;
