/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';

import {
  generateRsee,
  getRseePdf,
  graphToFullScreen,
  updateCheckValue,
} from '../../actions/report';

import { setDataset, updateFormValue } from '../../actions/projects';

import Report from '../../app/projects/Report/Report';

const mapStateToProps = (state) => ({
  synthese: state.report.synthese,
  A4: state.report.A4,
  format169: state.report.format169,
  format43: state.report.format43,
  variant: state.report.variant,
  pepfdes: state.report.pepfdes,
  rse: state.report.rse,
  fullScreen: state.report.fullScreen,
  parsedProject: state.projects.parsedProject,
  indicators: state.report.indicators,
  isMenuOpen: state.projects.isMenuOpen,
  currentProject: state.projects.currentProject,
  selectedFilterLot: state.report.selectedFilterLot,
  zones: state.projects.parsedProject.zones,
  downloadRsee: state.projects.downloadRsee,
  downloadRseePdf: state.projects.downloadRseePdf,
  projectAllot: state.projects.projectAllot,
  currentAllotment: state.projects.currentAllotment,
  widgets: state.report.widgets,
});

const mapDispatchToProps = (dispatch) => ({
  updateCheckValue: (name, value) => {
    dispatch(updateCheckValue(name, value));
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  setDataset: (dataset) => {
    dispatch(setDataset(dataset));
  },
  graphToFullScreen: () => {
    dispatch(graphToFullScreen());
  },
  generateRsee: (projectId, projectName) => {
    dispatch(generateRsee(projectId, projectName));
  },
  getRseePdf: (projectId) => {
    dispatch(getRseePdf(projectId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
