import { initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';
import i18next from 'i18next';
import resource_FR from './locales/fr-FR/translations.json';
import resource_GB from './locales/en-GB/translations.json';

const resources = {
  'en-GB': {
    translation: {
      ...resource_GB,
    },
  },
  'fr-FR': {
    translation: {
      ...resource_FR,
    },
  },
};

const arrayKeyLng = Object.keys(resources);

const storedLanguage = localStorage.getItem('i18nLanguage');

i18next
  .use(ICU)
  .use(initReactI18next)
  .init({
    resources,
    lng: storedLanguage || 'fr-FR',
    fallbackLng: 'fr-FR',
    supportedLngs: ['en-GB', 'fr-FR'],
    returnEmptyString: false,
    // debug:
    //   process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'testing' || process.env.REACT_APP_ENV === 'staging',
    interpolation: {
      prefix: '{',
      suffix: '}',
    },
  });

const i18nInstance = i18next;

export default i18nInstance;
