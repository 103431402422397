/* eslint-disable no-undef */
import { BuildingSheet, ProjectIcDTO, zonesDTO } from './WidgetsDTO';
import { IconButton, Popover } from '@material-ui/core';
import { IndicatorDTO, ProjectDTO } from '../../../../models/ProjectDTO';
import { TEMPLATE_TYPE_NAME } from '../../../globalVariable/typeProject';
import {
  gaugeEPCM,
  returnBuildingIcDepandingOnIndicator,
} from '../../../shared/utils';
import CustomProgressBar from '../../../shared/CustomProgressBar';
import { MIDED_OPTIONS } from '../../../globalVariable/generalDefinitions';
import { CurrentAllotmentDTO } from '../../../models/ProjectsDTO';
import { shouldDisplayRoundStyleThresholds } from '../../../shared/utilsFunction';
import CustomThreshold, { MiDedDropdown } from '../../../shared/CustomThreshold';
import InfoIcon from '@material-ui/icons/Info';
import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import formatNumber from '../../../services/mathematics/formatNumber';
import { useTranslation } from 'react-i18next';
import Info from '@material-ui/icons/Info';
import { IcMaxBbcaTooltip } from './widgetElements/WidgetsElements';

type ConstructWidgetsProps = {
  projectIc: ProjectIcDTO;
  zones: zonesDTO[];
  currentProject: ProjectDTO;
  currentIndicator: IndicatorDTO;
  currentAllotment: CurrentAllotmentDTO;
  openedPopooverId: string;
  seuilsOk: boolean;
  handlePopoverOpen: Function;
  handlePopoverClose: Function;
  renderPopoverBody: Function;
  buildingSheet: BuildingSheet;
  title?: string;
  icKey?: string;
};

const construct = require('../../../../assets/images/synthese/Image4.png');

export const formatNumberP = (
  value: number,
  seuil: boolean,
  currentProject: ProjectDTO,
  currentIndicator: IndicatorDTO | string,
  buildingArea: number | null = null
) => {
  let refUnitModeTotal = currentProject.refUnit === 'total';

  if (seuil) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p>
          Seuil max :{' '}
          <span className="valueNumbers">
            {formatNumber(refUnitModeTotal ? value * currentProject.area : value)}
          </span>{' '}
          {typeof currentIndicator === 'string'
            ? currentIndicator
            : currentIndicator.unit}{' '}
          {refUnitModeTotal ? '' : '/m²'}​
        </p>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p
          className={
            currentProject.templateType.includes(TEMPLATE_TYPE_NAME.EPCM)
              ? 'value'
              : 'valueRe2020'
          }
        >
          <span className="valueNumbers">
            {formatNumber(refUnitModeTotal ? value * currentProject.area : value)}{' '}
          </span>
          {typeof currentIndicator === 'string'
            ? currentIndicator
            : currentIndicator.unit}
          {refUnitModeTotal ? '' : '/m²'}
        </p>
      </div>
    );
  }
};

const BuildingConstructWidgets = ({
  renderPopoverBody,
  currentIndicator,
  projectIc,
  openedPopooverId,
  seuilsOk,
  handlePopoverOpen,
  handlePopoverClose,
  currentProject,
  currentAllotment,
  zones,
  buildingSheet,
  title,
  icKey,
}: ConstructWidgetsProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(MIDED_OPTIONS.current);
  let refUnitModeTotal = currentProject.refUnit === 'total';

  const buildingIc: ProjectIcDTO =
    returnBuildingIcDepandingOnIndicator(zones, buildingSheet, currentIndicator) ||
    projectIc;
  const buildingArea =
    zones?.find((zone) => zone?.id === buildingSheet?.buildingId)?.area || 1;
  const modalData = zones.find((zone) => zone?.id === buildingSheet.buildingId)
    ?.Zones[0].Ic['1'];

  const icToDisplay =
    currentProject.templateType === TEMPLATE_TYPE_NAME.BBCA
      ? buildingIc.Bbca[icKey]
      : buildingIc.IcConstruction;
  const icToDisplayMax =
    currentProject.templateType === TEMPLATE_TYPE_NAME.BBCA
      ? buildingIc.Bbca.Max[`${icKey}Max`]
      : buildingIc.IcConstruction;

  return (
    <div
      className={`report-construct ${
        currentProject.templateType.includes(TEMPLATE_TYPE_NAME.BBCA) ? 'bbca' : ''
      } container-resizable-ok`}
    >
      <div className="report-construct-header">
        <h3 className="dashboard-title">
          {title
            ? title
            : [TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(
                currentProject.templateType
              )
            ? t('APP.BUILDINGCONSTRUCTIONWIDGETS.CONSTRUCTION_PRODUCTS_EQUIPMENT', {
                defaultValue: 'Produits de Construction et Equipements',
              })
            : t('APP.BUILDINGCONSTRUCTIONWIDGETS.CONSTRUCTION', {
                defaultValue: 'Construction',
              })}
        </h3>

        <Tooltip
          title={
            currentProject.templateType.includes(TEMPLATE_TYPE_NAME.EPCM)
              ? t('APP.BUILDINGCONSTRUCTIONWIDGETS.EPCM_TOOLTIP', {
                  defaultValue:
                    'EGESpce: Impact des produits de construction et équipements - bénéfices et charges (si positif)',
                })
              : t('APP.BUILDINGCONSTRUCTIONWIDGETS.CARBON_IMPACT_CONSTRUCTION', {
                  defaultValue: 'Impact carbone construction : IcConstruction',
                })
          }
        >
          <img
            style={{
              width: window.screen.availHeight < 950 ? '40px' : undefined,
            }}
            src={construct}
            alt=""
          />
        </Tooltip>
      </div>
      {/* result */}
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: '1',
        }}
      >
        {![TEMPLATE_TYPE_NAME.EPCM, TEMPLATE_TYPE_NAME.BBCA].includes(
          currentProject.templateType
        ) && MiDedDropdown(selectedOption, setSelectedOption)}
        {formatNumberP(
          icToDisplay,
          false,
          currentProject,
          currentIndicator,
          buildingArea
        )}
        {[TEMPLATE_TYPE_NAME.BBCA].includes(currentProject.templateType) && (
          <IcMaxBbcaTooltip
            icKey={icKey}
            icToDisplayMax={icToDisplayMax}
            currentIndicator={currentIndicator}
            refUnitModeTotal={refUnitModeTotal}
          />
        )}
      </div>
      {/* threshold progresse bar */}
      {shouldDisplayRoundStyleThresholds(currentAllotment) && (
        <div>
          <CustomThreshold
            modalData={modalData}
            screen="construct"
            buildingIc={buildingIc}
            oldCalcul={selectedOption === MIDED_OPTIONS.current ? true : false}
          />
        </div>
      )}
      {/* ??? */}
      {currentProject.templateType.includes('nothing') && (
        <>
          {(currentIndicator.id === 1 || currentIndicator.id === 26) && seuilsOk ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              {formatNumberP(
                buildingIc.IcConstructionMax,
                true,
                currentProject,
                currentIndicator
              )}
              <IconButton
                size="small"
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  handlePopoverOpen(e, 'cons');
                }}
              >
                <InfoIcon />
              </IconButton>
              <Popover
                onClose={() => handlePopoverClose()}
                disableScrollLock={true}
                open={openedPopooverId === 'cons'}
                transformOrigin={{
                  horizontal: 'center',
                  vertical: 'bottom',
                }}
                anchorOrigin={{
                  horizontal: 'center',
                  vertical: 'top',
                }}
                anchorEl={anchorEl}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  {}
                  {renderPopoverBody()}
                </div>
              </Popover>
            </div>
          ) : (
            <p>Seuil max : Non applicable</p>
          )}
        </>
      )}
      {/* progress bar EPCM */}
      {currentProject.templateType.includes(TEMPLATE_TYPE_NAME.EPCM) && (
        <CustomProgressBar
          projectIc={buildingIc}
          constructWidget={true}
          valuePercentage={gaugeEPCM('IcConstruction', buildingIc)}
        />
      )}
    </div>
  );
};

export default BuildingConstructWidgets;
