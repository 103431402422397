import { connect } from 'react-redux';
import {
  featureUpdate,
  fetchFeatures,
  fetchReleases,
  releaseUpdate,
  updateIsNewFeature,
} from '../../actions/helps';
import {
  fetchProjects,
  updateFormValue,
  updateProjectsListMonitoring,
} from '../../actions/projects';
import Sidebar from '../../app/shared/Sidebar';

import { fetchQuickSimulator } from '../../actions/simulator';
import { toggleMenu, userLogout } from '../../actions/projects';
import { updateCheckValue } from '../../actions/report';

const mapStateToProps = (state) => ({
  isCompanyAdmin: state.projects.isCompanyAdmin,
  companyAdmins: state.projects.companyAdmins,
  orgaAdmins: state.projects.orgaAdmins,
  isMenuOpen: state.projects.isMenuOpen,
  features: state.help.feature.features,
  isNewFeature2: state.help.feature.isNewFeature2,
  localFeature: state.help.feature.localFeature,
  companies: state.projects.companies,
  templateTypeAvailable: state.projects.templateTypeAvailable,
  isDarkMode: state.report.isDarkMode,
  licenceTypeAccess: state.projects.licenceTypeAccess,
  allCompanies: state.projects.allCompanies,
  user: state.projects.user,
  tableEditMode: state.equipments.tableEditMode,
  isUserStartEditColumns: state.equipments.isUserStartEditColumns,
});

const mapDispatchToProps = (dispatch) => ({
  toggleMenu: () => {
    dispatch(toggleMenu());
  },
  userLogout: () => {
    dispatch(userLogout());
  },
  updateCheckValue: (name, value) => {
    dispatch(updateCheckValue(name, value));
  },
  fetchFeatures: () => {
    dispatch(fetchFeatures());
  },
  fetchReleases: () => {
    dispatch(fetchReleases());
  },
  releaseUpdate: (name, value) => {
    dispatch(releaseUpdate(name, value));
  },
  updateIsNewFeature: (value) => {
    dispatch(updateIsNewFeature(value));
  },
  featureUpdate: (name, value) => {
    dispatch(featureUpdate(name, value));
  },
  updateProjectsListMonitoring: (name, value) => {
    dispatch(updateProjectsListMonitoring(name, value));
  },
  // fetchQuickSimulator: () => {

  //   dispatch(fetchQuickSimulator());

  // },
  fetchProjects: (isSearching, companyId, templateTypeActive) => {
    dispatch(fetchProjects(isSearching, companyId, templateTypeActive));
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
