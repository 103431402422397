/* eslint-disable no-unused-vars */
// Import

import 'moment/locale/fr';
import { klona } from 'klona';
import API from '../app/services/API';
import moment from 'moment';

// Import actions
import {
  CUT_LINES,
  DELETE_ALL_ERRORS,
  DELETE_IMPORT,
  DELETE_ROWS_IMPORT,
  DOWNLOAD_RSEE,
  FETCH_IMPORT_DATAS,
  FETCH_IMPORT_DATE,
  FETCH_MORE_IMPORT_DATAS,
  FETCH_PEP_FDES,
  PATCH_LINES,
  REFRESH_IA,
  RESEARCH_SOURCE_NAME,
  SEND_IMPORT,
  SEND_IMPORT_EDIT,
  SEND_MESSAGE_SLACK,
  SEND_MESSAGE_TEAMS_FAQ,
  SEND_MESSAGE_TRELLO,
  VERIFY_ROWS_EQUIPMENT,
  afterDeleteRows,
  confirmDeleteImport,
  duplicateLines,
  fetchImportDatas,
  loadImportDatas,
  loadImportDate,
  loadSearchDatas,
  refreshDataAfterImport,
  updateImportLine,
} from '../actions/import';

import {
  canImportRowBeAddedInProject,
  getFlattenImportRows,
  returnAllRowWhoCanImportRowBeAddedInProject,
} from '../app/projects/Import/ImportFunction';
import { fetchProjectById, updateFormValue } from '../actions/projects';

let fetchDatasFlag = false;

const api = new API();

const importMiddleware = (store) => (next) => (action) => {
  const { projects, imports } = store.getState();

  switch (action.type) {
    case FETCH_IMPORT_DATE:
      {
        api.getImportForProject(action.id).then((response) => {
          let newDate = [];

          moment.locale('fr');

          if (response.status === 200) {
            const dateImport = response.data.map((date) => {
              const format = moment(date.createdAt).format('DD/MM/YYYY');

              const hour = moment(date.createdAt).format('LT');

              newDate = [
                ...newDate,
                {
                  id: date.id,
                  date: format,
                  hour,
                  email: date.UserEmail,
                  count: date.count,
                  tabName: date.tabName,
                  fileName: date.fileName,
                },
              ];
            });

            store.dispatch(
              loadImportDate(
                newDate,
                action.needAnimation ? true : false,
                action.creationImport
              )
            );
          } else {
            store.dispatch(loadImportDate([]));
          }
        });
      }

      next(action);
      break;
    case FETCH_IMPORT_DATAS:
      {
        if (
          action.socketFetchData &&
          action.importId !== imports.currentImport &&
          imports.currentImport
        ) {
          return;
        }

        if (fetchDatasFlag) {
          return;
        }

        let offset =
          action.forceOffset !== undefined ? action.forceOffset : imports.offset;

        fetchDatasFlag = true;

        api
          .getDataByImport(
            projects.currentProject.id,
            action.importId,
            imports.searchSourceValue !== '' ? imports.searchSourceValue : null,
            imports.filterOn,
            imports.displayArchived,
            offset
          )
          .then((response) => {
            for (let i = 0; i < response.data.ProjectImportElems?.length; i++) {
              response.data.ProjectImportElems[i].MasterDataEquipment =
                projects.allGroups.type.find(
                  (el) =>
                    el.id ===
                    response.data.ProjectImportElems[i].MasterDataEquipmentId
                );
            }
            store.dispatch(updateFormValue('isLoad', false));
            fetchDatasFlag = false;

            store.dispatch(
              loadImportDatas(
                response.data,
                null,
                action.refresh || null,
                projects.currentProject,
                projects.allGroups,
                projects.currentAllotment,
                projects.nodesAvailableForProjectType,
                offset
              )
            );
          });
      }

      next(action);
      break;
    case SEND_IMPORT_EDIT:
      {
        api
          .patchErrorsImport(
            imports.editImport,
            imports.importDatas.ProjectId,
            imports.importDatas.id
          )
          .then((response) => {
            store.dispatch(
              fetchImportDatas(imports.importDatas.ProjectId, imports.importDatas.id)
            );
          });
      }

      next(action);
      break;
    case DELETE_IMPORT:
      {
        api
          .deleteImport(imports.importDatas.ProjectId, imports.importDatas.id)
          .then((response) => {
            store.dispatch(confirmDeleteImport(imports.importDatas.id));
          });
      }

      next(action);
      break;
    case SEND_IMPORT:
      {
        let flattenList = getFlattenImportRows(imports.dataError);
        let validIds = action.unique
          ? [action.unique]
          : [
              ...flattenList
                .filter((importRow) => canImportRowBeAddedInProject(importRow))
                .map((importRow) => importRow.id),
            ];

        if (action.unique) {
          validIds = [action.unique];
        }

        api
          .confirmImport(
            imports.importDatas.ProjectId,
            imports.importDatas.id,
            action.unique ? validIds : []
          )
          .then((response) => {
            if (response.data.isDeleted) {
              api
                .deleteImport(imports.importDatas.ProjectId, imports.importDatas.id)
                .then((response) => {
                  store.dispatch(confirmDeleteImport(imports.importDatas.id));
                });
            } else {
              // store.dispatch(fetchImportDatas(imports.importDatas.ProjectId,
              //   imports.importDatas.id,
              //   validIds ? validIds : true));

              store.dispatch(
                refreshDataAfterImport(
                  document.querySelector('.ReactVirtualized__List').scrollTop,
                  validIds,
                  projects.currentProject
                )
              );
            }

            store.dispatch(fetchProjectById(projects.currentProject.id));
          });
      }

      next(action);
      break;
    case DELETE_ROWS_IMPORT:
      {
        let ids = [];

        console.log('action', action);
        if (action.rowId) {
          ids = [action.rowId];
        } else if (imports.allLinesChecked) {
          ids = true;
        } else {
          ids = imports.linesSelected;
        }

        api
          .deleteErrorImport(
            ids,
            imports.importDatas.ProjectId,
            imports.importDatas.id,
            imports.searchSourceValue,
            imports.filterOn,
            imports.displayArchived,
            imports.exceptLinesFromMultipleActions
          )
          .then((response) => {
            store.dispatch(updateFormValue('multipleDeletionModal', false));
            store.dispatch(updateFormValue('isLoad', false));
            store.dispatch(
              afterDeleteRows(
                response.data.deletedIds,
                projects.currentProject,
                response.data.importCounts
              )
            );
          });
      }

      next(action);
      break;
    case DELETE_ALL_ERRORS:
      {
        api
          .deleteErrorImport(
            'allErrors=true',
            imports.importDatas.id,
            imports.importDatas.ProjectId
          )
          .then((response) => {
            store.dispatch(afterDeleteRows(null, projects.currentProject));
          });
      }

      next(action);
      break;
    case VERIFY_ROWS_EQUIPMENT:
      {
        let displayData = imports.editImport;

        if (imports.isSearchingData) {
          // Need to filter on duplicate elems to rebuild the structure when the user is in searchingMode.

          displayData = klona(imports.editImport);
          for (let i = 0; i < displayData.length; i++) {
            if (displayData[i].id === 'duplicate') {
              displayData = [
                ...displayData,
                ...displayData[i].ProjectElemsDuplicate,
              ];
            }
          }
        }

        if (typeof action.lineId !== 'number' && !action.patchCarac) {
          api
            .patchLinesImport(
              imports.importDatas.ProjectId,
              imports.importDatas.id,
              action.lineId,
              projects.currentProject.templateType === 'mappingRef'
            )
            .then((response) => {
              let currLine = displayData.find(
                (elem) => elem.id === action.lineId.id
              );

              if (response.data === true) {
                currLine.status = 'userValidated';
                currLine.statusCode = 'userValidated';
              }

              store.dispatch(
                updateImportLine(
                  response.data === true ? [currLine] : response.data,
                  projects.currentProject,
                  null,
                  null,
                  projects.currentAllotment,
                  projects.allGroups
                )
              );
            });

          return;
        }

        if (action.patchCarac) {
          api
            .patchImportCarac(
              imports.importDatas.ProjectId,
              imports.importDatas.id,
              action.patchCarac.newCarac.id,
              action.patchCarac.newCarac,
              projects.currentProject.templateType === 'mappingRef'
            )
            .then((response) => {
              let errorCarac = null;
              let newLine = action.patchCarac.newLine;

              newLine.ProjectImportElemCaracs = newLine.ProjectImportElemCaracs.map(
                (carac) => {
                  if (carac.id === response.data.id) {
                    if (response.data.statusCode) {
                      errorCarac = true;
                    }

                    return response.data;
                  } else {
                    return carac;
                  }
                }
              );

              if (newLine.statusCode === 'userValidated' && errorCarac) {
                newLine.statusCode = 'Validated';
              }

              store.dispatch(
                updateImportLine([{ ...newLine }], projects.currentProject)
              );
            });

          return;
        }
      }

      next(action);
      break;
    case FETCH_MORE_IMPORT_DATAS:
      {
        // need change this with offset because it's buggy as fuck.
        const lastId =
          imports.importDatas.ProjectImportElems[
            imports.importDatas.ProjectImportElems.length - 1
          ]?.originalRow || 0;

        if (!imports.isLoadMoreImports) {
          api
            .getMoreImportDatas(
              imports.importDatas.ProjectId,
              imports.importDatas.id,
              lastId,
              imports.filterOn,
              imports.displayArchived
            )
            .then((response) => {
              for (let projectImportElem of response.data.ProjectImportElems) {
                projectImportElem.MasterDataEquipment = projects.allGroups.type.find(
                  (el) => el.id === projectImportElem.MasterDataEquipmentId
                );
              }

              store.dispatch(
                loadImportDatas(
                  response.data,
                  true,
                  null,
                  projects.currentProject,
                  projects.allGroups,
                  projects.currentAllotment,
                  projects.nodesAvailableForProjectType
                )
              );
            });
        }
      }

      next(action);
      break;
    case SEND_MESSAGE_SLACK:
      {
        store.dispatch(updateFormValue('isMessageTeams', true));

        let currentLine;
        if (imports.lineId) {
          currentLine = imports.importDatas.ProjectImportElems.find(
            (el) => el.id === imports?.lineId
          );
        } else {
          currentLine = null;
        }

        const webhookUrl =
          'https://hooks.slack.com/services/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';

        const ladate = new Date();

        const payload = {
          channel: 'nooco-import-request',
          text: `Demande d'informations sur le projet : ${projects.currentProject.name}`,
          attachments: [
            {
              fallback: "Demande d'informations sur le projet",
              color: '#0078D7',
              title: `Demande d'informations sur le projet : ${projects.currentProject.name}`,
              fields: [
                {
                  title: 'Libellé source',
                  value:
                    currentLine && currentLine.name
                      ? currentLine.name
                      : 'Pas de libellé source',
                  short: true,
                },
                {
                  title: 'Libellé de la MD',
                  value:
                    currentLine && currentLine.MasterDataEquipment
                      ? currentLine.MasterDataEquipment.name
                      : 'Pas de MD',
                  short: true,
                },
              ],
              text: projects.contentAsk,
              footer: `${imports.importDatas.UserEmail} - ${
                ladate.getDate() +
                '/' +
                (ladate.getMonth() + 1) +
                '/' +
                ladate.getFullYear()
              }, ${
                ladate.getHours() +
                ':' +
                ladate.getMinutes() +
                ':' +
                ladate.getSeconds()
              }`,
            },
          ],
        };

        try {
          fetch(webhookUrl, {
            method: 'POST',
            mode: 'no-cors',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((data) => console.log(data))
            .catch((error) => console.log(error));
        } catch (error) {
          console.error(error);
        }

        setTimeout(() => {
          store.dispatch(updateFormValue('openValid', true));
          store.dispatch(updateFormValue('modalAsk', false));
        }, 1000);
      }

      next(action);
      break;

    case SEND_MESSAGE_TEAMS_FAQ:
      {
        const { date, mail, question, firstName, lastName } = action.line;

        const webhookUrl =
          'https://hooks.slack.com/services/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';

        const payload = {
          channel: 'nooco-faq-help',
          text: `${firstName} ${lastName} à posé une nouvelle question`,
          attachments: [
            {
              fallback: `${firstName} ${lastName} à posé une nouvelle question`,
              color: '#44DDC3',
              title: `${firstName} ${lastName} à posé une nouvelle question`,
              title_link: 'https://example.com/path/to/question',
              fields: [
                {
                  title: 'Question:',
                  value: `${question}`,
                  short: false,
                },
                {
                  title: "Date d'envoi",
                  value: `${
                    date.getDate() +
                    '/' +
                    (date.getMonth() + 1) +
                    '/' +
                    date.getFullYear()
                  }, ${
                    date.getHours() +
                    ':' +
                    date.getMinutes() +
                    ':' +
                    date.getSeconds()
                  }`,
                  short: true,
                },
              ],
              mrkdwn_in: ['text'],
            },
          ],
        };

        try {
          fetch(webhookUrl, {
            method: 'POST',
            mode: 'no-cors',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((data) => console.log(data))
            .catch((error) => console.log(error));
        } catch (error) {
          console.error(error);
        }

        setTimeout(() => {
          store.dispatch(updateFormValue('openValid', true));
          store.dispatch(updateFormValue('modalAsk', false));
        }, 1000);
      }
      next(action);
      break;

    case SEND_MESSAGE_TRELLO:
      {
        try {
          store.dispatch(updateFormValue('isMessageTrello', true));

          let currentLine;
          if (imports.lineId) {
            currentLine = imports.importDatas.ProjectImportElems.find(
              (el) => el.id === imports?.lineId
            );
          } else {
            currentLine = null;
          }
          const date = new Date();

          const card = {
            name: `Projet : ${projects.currentProject.name}`,
            desc: `**${projects.user.attributes.email}**
*${date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()}, ${
              date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
            }*
      
---

>${projects.contentAsk}

**Libellé source**    ${
              currentLine && currentLine.name
                ? currentLine.name
                : 'Pas de libellé source'
            }
**Libellé de la MD**  ${
              currentLine && currentLine.MasterDataEquipment
                ? currentLine.MasterDataEquipment.name
                : 'Pas de MD'
            }
      `,
          };

          setTimeout(() => {
            store.dispatch(updateFormValue('openValid', true));
            store.dispatch(updateFormValue('modalAsk', false));
          }, 1000);

          api.sendMessageTrello(card);
        } catch (error) {
          console.error(error);
        }
      }
      next(action);
      break;

    case FETCH_PEP_FDES:
      {
        let filteredSheets = [];

        api.getSheetsByLibelleOrId(action.mdId).then((response) => {
          // // Get sheets who's got same value on carac to filter on pep/fdes/ded

          // action.caracs.forEach((carac) => {

          //   let currentCaracId = null;

          //   response.data.sheets.forEach((sheet) => {

          //     sheet.Caracs.forEach((sheetCarac) => {

          //       if(carac.name === sheetCarac.name && sheetCarac.type === 'list' && sheetCarac.SheetCarac.value[0] === carac.value) {

          //         filteredSheets = [...filteredSheets, sheet];

          //       } else if(carac.name === sheetCarac.name && !sheetCarac.type) {

          //         currentCaracId = sheetCarac.id;

          //       }

          //     });

          //   });

          //   if(currentCaracId) {

          //     const sheets = closest(carac.value, response.data.sheets, currentCaracId );

          //     filteredSheets = carac.value ? [...filteredSheets, ...sheets] : [...filteredSheets];

          //   }

          // });*

          if (response.status === 202) {
            console.log('response.data', response.data);

            const message = response.data.message;

            if (response.data.data)
              response.data = response.data.data.map((sheet) => {
                return {
                  ...sheet,
                  name: `${sheet.name} (${sheet.id_inies})`,
                };
              });

            alert(message);
          } else if (response.data) {
            response.data = response.data.map((sheet) => {
              return {
                ...sheet,
                name: `${sheet.name} (${sheet.id_inies})`,
              };
            });
          }

          store.dispatch(updateFormValue('importPepFdes', response.data || []));
        });
      }

      next(action);
      break;
    case RESEARCH_SOURCE_NAME:
      {
        console.log(action, ' action dans le research source name');
        api
          .getAllLinesWithSearchValue(
            imports.importDatas.ProjectId,
            imports.importDatas.id,
            imports.searchSourceValue,
            imports.displayArchived
          )
          .then((response) => {
            for (let i = 0; i < response.data.ProjectImportElems.length; i++) {
              response.data.ProjectImportElems[i].MasterDataEquipment =
                projects.allGroups.type.find(
                  (el) =>
                    el.id ===
                    response.data.ProjectImportElems[i].MasterDataEquipmentId
                );
            }

            store.dispatch(
              loadSearchDatas(
                response.data,
                projects.allGroups,
                projects.currentAllotment,
                projects.currentProject
              )
            );
          });
      }

      next(action);
      break;
    case CUT_LINES:
      {
        if (action.row?.Sheet?.type_fiche === 'bundle') {
          api
            .cutBundleLines(
              imports.importDatas.ProjectId,
              imports.importDatas.id,
              action.row
            )
            .then((response) => {
              console.log('response.data', response.data);
              store.dispatch(
                duplicateLines(
                  action.row,
                  response.data.ProjectImportElems,
                  projects.currentProject,
                  response.data.counts
                )
              );
            });
        } else {
          api
            .postElemImport(
              imports.importDatas.ProjectId,
              imports.importDatas.id,
              action.row
            )
            .then((response) => {
              store.dispatch(
                duplicateLines(action.row, ...response.data, projects.currentProject)
              );
            });
        }
      }

      next(action);
      break;
    case PATCH_LINES: {
      store.dispatch(updateFormValue('isLoad', true));

      let target = imports.allLinesChecked
        ? {
            userValidated: imports.filterOn.includes('userValidated'),
            notValidated: imports.filterOn.includes('error'),
            Validated: imports.filterOn.includes('Validated'),
            isArchived: imports.displayArchived,
            search: imports.isSearchingData ? imports.searchSourceValue : null,
            exceptLinesFromMultipleActions: imports.exceptLinesFromMultipleActions,
          }
        : null;

      switch (action.action) {
        case 'allotmentNode': {
          let data = {
            [target ? 'target' : 'ids']: target || imports.linesSelected,
            action: 'AllotmentNode',
            AllotmentNodeId: imports.mdToLines.id,
          };

          api
            .patchLinesImport(
              imports.importDatas.ProjectId,
              imports.importDatas.id,
              data
            )
            .then((response) => {
              response.data.resultUpdateMasterData =
                response.data.resultUpdateMasterData.map((el) => ({
                  ...el,
                  AllotmentNode: projects.allGroups.flatAllotments.find(
                    (node) => node.id === el.AllotmentNodeId
                  ),
                }));
              store.dispatch(
                updateImportLine(
                  response.data.resultUpdateMasterData,
                  projects.currentProject,
                  null,
                  null,
                  projects.currentAllotment,
                  projects.allGroups
                )
              );
            })
            .catch((error) => {
              if (error.response && error.response.status === 413) {
                store.dispatch(updateFormValue('isLoad', false));
                store.dispatch(
                  updateFormValue(
                    'errorPatchLines',
                    'Limite de 200 lignes atteinte, veuillez réduire le nombre de lignes à modifier'
                  )
                );
              }
            });
          break;
        }
        case 'validate': {
          let displayData = [...imports.editImport];

          let data = {
            [target ? 'target' : 'ids']: target || imports.linesSelected,
            action: 'validate',
          };

          api
            .patchLinesImport(
              imports.importDatas.ProjectId,
              imports.importDatas.id,
              data
            )
            .then((response) => {
              // store.dispatch(duplicateLines(action.row, ...response.data, projects.currentProject));

              let lines = displayData.filter((el) =>
                imports.linesSelected.includes(el.id)
              );
              let statusValidation = 'complete';

              let datas = [];

              lines.forEach((el) => {
                if (
                  el.statusCode === 'Validated' &&
                  !el.ProjectImportElemCaracs.find((ele) => ele.statusCode)
                ) {
                  datas = [
                    ...datas,
                    {
                      ...el,
                      status: 'userValidated',
                      statusCode: 'userValidated',
                    },
                  ];
                } else {
                  datas = [...datas, el];

                  statusValidation = 'incomplete';
                }
              });
              store.dispatch(
                updateImportLine(
                  datas,
                  projects.currentProject,
                  statusValidation,
                  response.data.count
                )
              );
            });
          break;
        }
      }

      next(action);
      break;
    }
    case REFRESH_IA:
      {
        api
          .refreshIA(imports.importDatas.ProjectId, imports.importDatas.id)
          .then((response) => {
            store.dispatch(updateFormValue('isImportLoading', true));
          });
      }

      next(action);
      break;

    default:
      next(action);
  }
};
export default importMiddleware;
