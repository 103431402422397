import {
  EDIT_FEATURE_UPDATE,
  FEATURE_CLOSE_MODAL,
  FEATURE_CREATE_MODE_ON,
  FEATURE_CREATE_NEW,
  FEATURE_EDIT_MODE_ON,
  FEATURE_GET_ALL,
  FEATURE_MODIFY,
  FEATURE_MODIFY_NEW,
  FEATURE_MODIFY_NEW_TITLE,
  FEATURE_MODIFY_TITLE,
  FEATURE_OPEN_MODAL,
  FEATURE_SELECTED,
  FEATURE_UPDATE,
  NEW_FEATURE_UPDATE,
  RELEASES_EDIT,
  RELEASES_EDIT_ID,
  RELEASES_EDIT_NUMBER,
  RELEASES_UPDATE,
  RELEASE_CANCEL,
  RELEASE_CREATE_SUCCES,
  RELEASE_EDIT,
  RELEASE_EDIT_TEMP,
  RELEASE_EDIT_TEST,
  RELEASE_NEW_EDIT,
  RELEASE_SETTINGS_ACCORDION,
  RELEASE_SETTINGS_LOADER,
  RELEASE_TOGGLE_NEW,
  RELEASE_TOGGLE_VISIBLE,
  RELEASE_UPDATE,
  RELEASE_VALIDATE_TEST,
  RELEASE_VERIF,
  UPDATE_HELP_NEW_RELEASE_NOTIFICATION,
  UPDATE_IS_NEW_FEATURE,
} from '../actions/helps';
import { HelpDTO } from '../app/models/HelpDTO';
import { StateDTO } from '../app/models/GeneralDTO';

// TODO_TRAD : pas possible dans un reducer
const initialState: HelpDTO['help'] = {
  userNotification: false,
  messageNotification: '',
  newReleaseNotification: {
    lastReleaseId: undefined,
    lastReleaseIdLocal: undefined,
    isOpenModalNewRelease: false,
    isOpenModalInformation: false,
  },
  featureCategories: [
    {
      id: 1,
      title: 'Création et paramétrage de projets',
      visible: true,
      order: 1,
    },
    {
      id: 2,
      title: 'Gestion entreprises',
      visible: true,
      order: 2,
    },
    {
      id: 3,
      title: 'Import de données',
      visible: true,
      order: 3,
    },
    {
      id: 4,
      title: 'Calculs',
      visible: true,
      order: 4,
    },
    {
      id: 5,
      title: 'Synthèse et livrables',
      visible: true,
      order: 5,
    },
  ],

  feature: {
    isNewFeature2: true,
    localFeature: [],
    loadingFeatures: true,
    loading: false,
    loadingFeature: false,
    isOpenModal: false,
    create: false,
    edit: false,
    features: [],
    newFeature: undefined,
    selectedFeature: undefined,
    selectedFeatureIndex: undefined,
    selectedCategory: undefined,
    isTitleValid: false,
    isArticleBodyValid: false,
    hasValidate: false,
  },
  release: {
    releases: null,
    loadingRelease: false,
    selectedRelease: {},
    selectedReleaseIdLocal: null,
    selectedReleaseArticleBody: '',
    accordionPanel: '',
    isOpenNew: false,
    isNewReleaseValidate: false,
    isDateValid: false,
    isTitleValidate: false,
    isFeatureValidate: false,
    isAddEquipentsValidate: false,
    isAddMaterialsValidate: false,
    newReleaseArticleBody: '',
    newRelease: {
      title: '',
      deliveryDate: '',
      featureCount: '',
      newEquipmentCount: '',
      newMaterialCount: '',
      articleBody: '',
      isVisible: true,
    },
    isEdit: false,
    editNumber: '',
    editReleaseId: '',
    editArticleBody: '',
    editRelease: {
      title: '',
      deliveryDate: '',
      featureCount: '',
      newEquipmentCount: '',
      newMaterialCount: '',
      articleBody: '',
      isVisible: '',
    },
  },
};

function helpReducer(state: HelpDTO['help'] = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_HELP_NEW_RELEASE_NOTIFICATION:
      return {
        ...state,
        newReleaseNotification: {
          ...state.newReleaseNotification,
          [action.name]: action.value,
        },
      };

    case FEATURE_OPEN_MODAL:
      return {
        ...state,
        feature: {
          ...state.feature,
          isOpenModal: true,
        },
      };
    case UPDATE_IS_NEW_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          isNewFeature2: action.value,
        },
      };

    case FEATURE_CLOSE_MODAL:
      return {
        ...state,
        feature: {
          ...state.feature,
          isOpenModal: false,
          create: false,
          edit: false,
          newFeature: undefined,
          selectedFeature: undefined,
          selectedFeatureIndex: undefined,
          selectedCategory: undefined,
          isTitleValid: false,
          isArticleBodyValid: false,
          hasValidate: false,
        },
      };

    case FEATURE_CREATE_MODE_ON: {
      return {
        ...state,
        feature: {
          ...state.feature,
          isTitleValid: true,
          edit: false,
          create: true,
        },
      };
    }

    case FEATURE_EDIT_MODE_ON: {
      return {
        ...state,
        feature: {
          ...state.feature,
          isTitleValid: true,
          isArticleBodyValid: true,
          edit: true,
          create: false,
        },
      };
    }

    case FEATURE_CREATE_NEW: {
      console.log(action.category);

      return {
        ...state,
        feature: {
          ...state.feature,
          newFeature: {
            title: '',
            deliveryDate: new Date(),
            isVisible: true,
            articleBody: '',
            category: action.category.id,
            unRead: true,
            type: 'feature',
          },
          // TO DELETE
          selectedCategory: action.category,
          isTitleValid: false,
          isArticleBodyValid: false,
          hasValidate: false,
        },
      };
    }

    case FEATURE_SELECTED: {
      console.log(action.category);
      console.log(action.feature);

      return {
        ...state,
        feature: {
          ...state.feature,
          selectedFeature: action.feature,
          selectedCategory: action.category,
        },
      };
    }

    case NEW_FEATURE_UPDATE:
      return {
        ...state,
        feature: {
          ...state.feature,
          newFeature: {
            ...state.feature.newFeature,
            [action.name]: action.value,
          },
        },
      };

    case EDIT_FEATURE_UPDATE:
      return {
        ...state,
        feature: {
          ...state.feature,
          selectedFeature: {
            ...state.feature.selectedFeature,
            [action.name]: action.value,
          },
        },
      };

    case FEATURE_UPDATE:
      return {
        ...state,
        feature: {
          ...state.feature,
          [action.name]: action.value,
        },
      };

    //TODO

    case FEATURE_GET_ALL:
      return {
        ...state,
        feature: {
          ...state.feature,
          features: action.features,
        },
      };

    case FEATURE_MODIFY_NEW:
      // console.log(action.value);
      // console.log(action.value.innerHTML);

      return {
        ...state,
        feature: {
          ...state.feature,
          newFeature: {
            ...state.feature.newFeature,
            body: action.value,
          },
        },
      };

    case FEATURE_MODIFY_NEW_TITLE: {
      let isTitleValidate = true;

      console.log(action.value);

      if (action.value.length < 4) {
        isTitleValidate = false;
      }

      return {
        ...state,
        feature: {
          ...state.feature,
          isTitleValid: isTitleValidate,
          newFeature: {
            ...state.feature.newFeature,
            title: action.value,
          },
        },
      };
    }

    case FEATURE_MODIFY:
      console.log(action.title);

      return {
        ...state,
        feature: {
          ...state.feature,
          selectedFeature: {
            ...state.feature.selectedFeature,
            body: action.value,
          },
        },
      };

    case FEATURE_MODIFY_TITLE: {
      let isTitleValidate = true;

      console.log(action.title);

      if (action.title.length < 4) {
        isTitleValidate = false;
      }

      return {
        ...state,
        feature: {
          ...state.feature,
          isTitleValid: isTitleValidate,
          selectedFeature: {
            ...state.feature.selectedFeature,
            title: action.title,
          },
        },
      };
    }

    //**  ------- RELEASES ------------------- */

    case RELEASE_UPDATE:
      return {
        ...state,
        release: {
          ...state.release,
          [action.name]: action.value,
        },
      };

    case RELEASE_TOGGLE_NEW:
      return {
        ...state,
        release: {
          ...state.release,
          isOpenNew: !state.release.isOpenNew,
          // selectedRelease: {},
          accordionPanel: false,
          // editRelease: {
          //   title: "",
          //   deliveryDate: "",
          //   featureCount: "",
          //   newEquipmentCount: "",
          //   newMaterialCount: "",
          //   articleBody: "",
          //   isVisible: "",
          // }
        },
      };

    case RELEASE_NEW_EDIT: {
      return {
        ...state,
        release: {
          ...state.release,
          newRelease: {
            ...state.release.newRelease,
            [action.name]: action.value,
          },
        },
      };
    }

    case RELEASE_EDIT_TEMP: {
      return {
        ...state,
        release: {
          ...state.release,

          editRelease: {
            ...state.release.editRelease,
            [action.name]: action.value,
          },
          loadingRelease: false,
        },
      };
    }

    case RELEASE_VERIF:
      return {
        ...state,
        release: {
          ...state.release,
          [action.name]: action.value,
        },
      };

    case RELEASE_VALIDATE_TEST:
      return {
        ...state,
        release: {
          ...state.release,
          isNewReleaseValidate: true,
        },
      };

    case RELEASE_CANCEL:
      return {
        ...state,
        release: {
          ...state.release,
          loadingRelease: false,
          selectedRelease: {},
          selectedReleaseIdLocal: null,
          selectedReleaseArticleBody: '',
          isOpenNew: false,
          accordionPanel: false,
          isNewReleaseValidate: false,
          isDateValid: false,
          isTitleValidate: false,
          isFeatureValidate: false,
          isAddEquipentsValidate: false,
          isAddMaterialsValidate: false,
          newReleaseArticleBody: '',
          newRelease: {
            title: '',
            deliveryDate: '',
            featureCount: '',
            newEquipmentCount: '',
            newMaterialCount: '',
            articleBody: '',
            isVisible: true,
          },
          isEdit: false,
          editNumber: '',
          editReleaseId: '',
          editArticleBody: '',
          editRelease: {
            title: '',
            deliveryDate: '',
            featureCount: '',
            newEquipmentCount: '',
            newMaterialCount: '',
            articleBody: '',
            isVisible: true,
          },
        },
      };

    case RELEASES_EDIT:
      return {
        ...state,
        release: {
          ...state.release,
          isEdit: !state.release.isEdit,
        },
      };

    case RELEASES_EDIT_NUMBER:
      return {
        ...state,
        release: {
          ...state.release,
          editNumber: action.value,
        },
      };

    case RELEASE_EDIT: {
      // console.log(action.editRelease);
      // console.log(action.editRelease.length());

      // let deb = 0;
      // let end = 10;
      // cancel reference to origin array
      let newReference = {};

      newReference = { ...action.editRelease };
      console.log(newReference);

      // console.log(newReference);
      // console.log(newReference === action.editRelease);

      let dateFromDate = newReference.deliveryDate;

      // dateFromDate = dateFromDate.toISOString();
      // console.log(dateFromDate);
      // console.log(typeof dateFromDate);

      if (typeof dateFromDate === 'object') {
        console.log(dateFromDate.getMonth());
        newReference.deliveryDate = `${dateFromDate
          .getDate()
          .toString()
          .padStart(2, 0)}/${
          dateFromDate.getMonth() + 1
        }/${dateFromDate.getFullYear()}`;
      } else if (typeof dateFromDate === 'string') {
        console.log(dateFromDate);

        const [year, month, day] = dateFromDate.slice(0, 10).split('-');

        newReference.deliveryDate = `${(Number(day) + 1)
          .toString()
          .padStart(2, '0')}/${month}/${year}`;
        // console.log(newReference);
      }

      return {
        ...state,
        release: {
          ...state.release,
          editRelease: { ...newReference },
        },
      };
    }
    case RELEASES_EDIT_ID:
      return {
        ...state,
        release: {
          ...state.release,
          editReleaseId: action.id,
        },
      };

    case RELEASES_UPDATE:
      return {
        ...state,
        [action.name]: action.value,
      };

    case RELEASE_CREATE_SUCCES: {
      return {
        ...state,
        userNotification: action.value,
        messageNotification: action.message,
      };
    }

    case RELEASE_SETTINGS_ACCORDION: {
      return {
        ...state,
        release: {
          ...state.release,
          accordionPanel: action.panel,
        },
      };
    }

    case RELEASE_SETTINGS_LOADER: {
      return {
        ...state,
        release: {
          ...state.release,
          loadingRelease: action.loader,
        },
      };
    }
    case RELEASE_TOGGLE_VISIBLE: {
      return {
        ...state,
        release: {
          ...state.release,
          newRelease: {
            ...state.release.newRelease,
            isVisible: !state.release.newRelease.isVisible,
          },
        },
      };
    }
    case RELEASE_EDIT_TEST: {
      const {
        title,
        deliveryDate,
        featureCount,
        newEquipmentCount,
        newMaterialCount,
        articleBody,
        isVisible,
      } = state.release.editRelease;

      const regex = /^\d{2}\/\d{2}\/\d{4}$/;

      console.log(
        title,
        deliveryDate,
        featureCount,
        newEquipmentCount,
        newMaterialCount,
        articleBody,
        isVisible
      );

      let isTitleValidate = !(title < 1);
      let isDateValid = !(deliveryDate.match(regex) === null);
      let isFeatureValidate = typeof featureCount === 'number';
      let isAddEquipmentsValidate = typeof newEquipmentCount === 'number';
      let AddMaterialsValidate = typeof newMaterialCount === 'number';

      console.log(
        isTitleValidate,
        isDateValid,
        isFeatureValidate,
        isAddEquipmentsValidate,
        AddMaterialsValidate
      );

      return {
        ...state,
        release: {
          ...state.release,
          isTitleValidate: isTitleValidate,
          isDateValid: isDateValid,
          isFeatureValidate: isFeatureValidate,
          isAddEquipentsValidate: isAddEquipmentsValidate,
          isAddMaterialsValidate: AddMaterialsValidate,
        },
      };
    }

    default:
      return state;
  }
}

export default helpReducer;
