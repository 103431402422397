export const FETCH_COMPANY_BY_ID = 'FETCH_COMPANY_BY_ID';
export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY';
export const FETCH_EMPLOYEES_BY_COMPANY_ID = 'FETCH_EMPLOYEES_BY_COMPANY_ID';
export const SET_COMPANY_EMPLOYEES = 'SET_COMPANY_EMPLOYEES';
export const PATCH_EMPLOYEE_ACCESS = 'PATCH_EMPLOYEE_ACCESS';
export const SET_MONITORING_ACTIVE_TAB = 'SET_MONITORING_ACTIVE_TAB';
export const SET_NEW_USER_MODAL = 'SET_NEW_USER_MODAL';
export const INITIALIZE_ACCESS_LOADING = 'INITIALIZE_ACCESS_LOADING';
export const SET_ACCESS_LOADING = 'SET_ACCESS_LOADING';
export const SEND_COMPANY_INVITATION = 'SEND_COMPANY_INVITATION';
export const SET_DELETE_COMPANY_EMPLOYEE_MODAL = 'SET_DELETE_COMPANY_EMPLOYEE_MODAL';
export const DELETE_COMPANY_EMPLOYEE = 'DELETE_COMPANY_EMPLOYEE';
export const PATCH_COMPANY_ADMIN = 'PATCH_COMPANY_ADMIN';
export const UPDATE_MONITORING = 'UPDATE_MONITORING';
export const PATCH_MONITORING_BREADCRUMMB_MULTIZONE =
  'PATCH_MONITORING_BREADCRUMMB_MULTIZONE';

export const updateMonitoring = (name, value) => ({
  type: UPDATE_MONITORING,
  name,
  value,
});

export const patchMonitoringBreadcrumbMultizone = (name, value) => ({
  type: PATCH_MONITORING_BREADCRUMMB_MULTIZONE,
  value,
  name,
});

export const fetchCompanyById = (id) => ({
  type: FETCH_COMPANY_BY_ID,
  id,
});

export const setCurrentCompany = (company) => ({
  type: SET_CURRENT_COMPANY,
  company,
});

export const fetchEmployeesByCompanyId = (id) => ({
  type: FETCH_EMPLOYEES_BY_COMPANY_ID,
  id,
});

export const setCompanyEmployees = (employees) => ({
  type: SET_COMPANY_EMPLOYEES,
  employees,
});

export const patchEmployeeAccess = (employeeId, companyId) => ({
  type: PATCH_EMPLOYEE_ACCESS,
  employeeId,
  companyId,
});

export const setMonitoringActiveTab = (tabName) => ({
  type: SET_MONITORING_ACTIVE_TAB,
  tabName,
});

export const setNewUserModal = () => ({
  type: SET_NEW_USER_MODAL,
});

export const initializeAccessLoading = () => ({
  type: INITIALIZE_ACCESS_LOADING,
});

export const setAccessLoading = (accessId) => ({
  type: SET_ACCESS_LOADING,
  accessId,
});

export const sendCompanyInvitation = (email, companyId, isAdmin, giveLicense) => ({
  type: SEND_COMPANY_INVITATION,
  email,
  companyId,
  isAdmin,
  giveLicense,
});

export const setDeleteCompanyEmployeeModal = () => ({
  type: SET_DELETE_COMPANY_EMPLOYEE_MODAL,
});

export const deleteCompanyEmployee = (email, companyId) => ({
  type: DELETE_COMPANY_EMPLOYEE,
  email,
  companyId,
});

export const patchCompanyAdmin = (employeeId) => ({
  type: PATCH_COMPANY_ADMIN,
  employeeId,
});
