import { getColorFromString } from '../services/colors/getColor';

export const recursiveGroupSimilarSheets = (graphData) => {
  graphData.children.map((high) => {
    let childrens = [];

    const graphDataWithSameLabel = [];
    const graphDataLeftOvers = [];

    graphData.children.forEach((el) => {
      if (el.label === high.label) {
        graphDataWithSameLabel.push(el);
      } else {
        graphDataLeftOvers.push(el);
      }
    });

    graphData.children = graphDataLeftOvers;

    for (let data of graphDataWithSameLabel) {
      if (Array.isArray(data.children)) {
        childrens = [...childrens, ...data.children];
      } else {
        childrens = [...childrens, data.children];
      }
    }

    let res = 0;
    let ids = [];

    childrens.forEach((el) => {
      if (el) {
        res += Number(el.res);

        if (Array.isArray(el.uniqueId)) {
          ids = [...ids, ...el.uniqueId];
        } else {
          ids = [...ids, el.uniqueId];
        }
      }
    });

    if (graphDataWithSameLabel[0].step === 0) {
      const uniqueRes = [...graphDataWithSameLabel.map((o) => o.res)];

      uniqueRes.forEach((el) => {
        res += Number(el);
      });

      graphDataWithSameLabel.forEach((el) => {
        if (Array.isArray(el.uniqueId)) {
          ids = [...ids, ...el.uniqueId];
        } else {
          ids = [...ids, el.uniqueId];
        }
      });
    }

    const unique = {
      id: graphDataWithSameLabel[0].id,
      uniqueId: ids,
      label: graphDataWithSameLabel[0].label,
      color: graphDataWithSameLabel[0].color,
      step: graphDataWithSameLabel[0].step,
      ParentId: graphDataWithSameLabel[0].ParentId,
      children: childrens.filter((el) => el),
      res: res,
    };

    graphData.children = [...graphData.children, unique];

    if (childrens.filter((el) => el).length > 0) {
      return recursiveGroupSimilarSheets(unique);
    }
  });
};

export const seekNodeData = (current, data) => {
  let currentNode;

  data.children.forEach((el) => {
    if (currentNode) {
      return;
    }

    if (el.id === Number(current.id)) {
      if (
        (typeof current.uniqueId === 'number' &&
          el?.uniqueId.toString().includes(current.uniqueId)) ||
        (Array.isArray(current.uniqueId) &&
          current.uniqueId.every((id) => el.uniqueId.includes(id))) ||
        (typeof current.uniqueId === 'number' &&
          Array.isArray(el?.uniqueId) &&
          el.uniqueId.includes(current.uniqueId))
      ) {
        currentNode = el;

        return currentNode;
      }
    } else {
      return (currentNode = seekNodeData(current, el));
    }
  });

  return currentNode;
};

// Match all md and make all structure for each md
export const recursiveMatchingAllot = (
  node,
  child,
  level,
  objectStructure,
  sheet,
  currentAllotment,
  zones,
  currentProject
) => {
  if (sheet.type_fiche === 'EMPTY' || sheet.type_fiche === 'EMPTY_REFURBISHED') {
    return;
  }

  // TODO TEAMRORO a investiguer et changer
  let uniqueId = node.id * Math.floor(Math.random() * 100000);

  let acvPerZone = [];

  if (level === 0) {
    let res =
      currentAllotment.id !== 1
        ? sheet?.Re2020Sheet?.acvResults?.total
        : sheet?.acvResults?.total;
    let certifiedSheet = currentAllotment.id !== 1 ? sheet?.Re2020Sheet : sheet;

    // TODO TEAMRORO on ne comprends pas comment on somme les total acv sur la zone
    if (currentAllotment.id !== 1) {
      acvPerZone = [
        ...acvPerZone,
        {
          zoneId: sheet.ProjectSheet.ZoneId,
          res: sheet.Re2020Sheet?.acvResults?.total,
        },
      ];

      certifiedSheet.ProjectSheet = sheet.ProjectSheet;
    }

    objectStructure.res = res;
    objectStructure.sheets = certifiedSheet; // selectedRefSheet
    objectStructure.sheetsIndicator =
      currentAllotment.id !== 1 ? sheet : sheet.Re2020Sheet; // alternativeRefSheet
  }

  if (level === 0 && currentAllotment.id !== 1) {
    let nodeNooco = sheet.ProjectSheet.AllotmentNodes.find(
      (el) => el.AllotmentId === 1
    );

    objectStructure['0'] = nodeNooco.id;
    objectStructure['1'] = node.id;

    child = {
      id: nodeNooco.id,
      uniqueId: uniqueId,
      label: nodeNooco.name,
      color: getColorFromString(nodeNooco.name),
      step: 0,
      ParentId: node.id,
      children: null,
      res:
        currentAllotment.id !== 1
          ? sheet.Re2020Sheet.acvResults.total
          : sheet.acvResults.total,
      ZoneId: acvPerZone,
      SheetId: sheet.id,
    };

    objectStructure.details = [
      {
        id: nodeNooco.id,
        name: nodeNooco.name,
        step: 0,
        uniqueId: uniqueId,
        res:
          currentAllotment.id !== 1
            ? sheet.Re2020Sheet.acvResults.total
            : sheet.acvResults.total,
        ParentId: node.id,
        MasterDataEquipmentId: nodeNooco.MasterDataEquipmentId,
      },
      {
        id: node.id,
        name: node.name,
        step: 1,
        uniqueId: uniqueId,
        res:
          currentAllotment.id !== 1
            ? sheet.Re2020Sheet.acvResults.total
            : sheet.acvResults.total,
        ParentId: node.ParentId,
      },
    ];
  } else if (currentAllotment.id === 1 || level > 0) {
    objectStructure.details =
      level === 0
        ? [
            {
              id: node.id,
              name: node.name,
              step: 0,
              uniqueId: uniqueId,
              res:
                currentAllotment.id !== 1
                  ? sheet.Re2020Sheet.acvResults.total
                  : sheet.acvResults.total,
              ParentId: node.ParentId,
              MasterDataEquipmentId: node.MasterDataEquipmentId,
            },
          ]
        : [
            ...objectStructure.details,
            {
              id: node.id,
              name: node.name,
              step: child.step + 1,
              children: [child],
              res:
                currentAllotment.id !== 1
                  ? sheet.Re2020Sheet.acvResults.total
                  : sheet.acvResults.total,
              uniqueId: child.uniqueId,
              ParentId: node.ParentId,
              MasterDataEquipmentId: node.MasterDataEquipmentId,
            },
          ];
    if (currentAllotment.id !== 1) {
      objectStructure[level + 1] = node.id;
    } else {
      objectStructure[level] = node.id;
    }
  }

  let color = currentProject.Lots?.find(
    (elem) => elem.name.toLocaleUpperCase() === node.name.toLocaleUpperCase()
  );

  let container = {
    ...node,
    id: node.id,
    uniqueId: level === 0 ? uniqueId : child.uniqueId,
    label: node.name,
    color: color?.color || getColorFromString(node.name),
    step: level === 0 && currentAllotment.id !== 1 ? 1 : level,
    ParentId: node.ParentId,
    children: child,
    res:
      currentAllotment.id !== 1
        ? sheet.Re2020Sheet.acvResults.total
        : sheet.acvResults.total,
    ZoneId: level === 0 ? acvPerZone : child.ZoneId,
    SheetId: sheet.id,
  };

  const step = currentAllotment?.AllotmentNodes?.find(
    (el) => el.id === node.ParentId
  );

  if (step) {
    level++;

    return recursiveMatchingAllot(
      step,
      container,
      level,
      objectStructure,
      sheet,
      currentAllotment,
      zones,
      currentProject
    );
  } else {
    // filter on zoneId for RE2020
    if (currentAllotment.id !== 1) {
      let containers = [];

      const data = child.ZoneId || container.ZoneId;

      data.forEach((zone) => {
        const currentZone = zones?.find((el) => el.id === zone.zoneId);

        if (currentZone.type === 'zone') {
          containers = [...containers, container];
        } else {
          let parent = null;

          const parentZone = zones?.find((el) => el.id === currentZone.ParentId);

          container.ParentId = currentZone.id;

          if (currentZone.type === 'buildingSite' && parentZone.type === 'plot') {
            objectStructure[objectStructure.details.length - 1] = parentZone.id;
            objectStructure[objectStructure.details.length] = currentZone.id;

            objectStructure.details = [
              ...objectStructure.details,
              {
                id: currentZone.id,
                name: currentZone.name,
                step: level,
                uniqueId: container.uniqueId,
                res:
                  currentAllotment.id !== 1
                    ? sheet.Re2020Sheet.acvResults.total
                    : sheet.acvResults.total,
                ParentId: parentZone.ParentId,
              },
              {
                id: parentZone.id,
                name: parentZone.name,
                step: level,
                uniqueId: container.uniqueId,
                res:
                  currentAllotment.id !== 1
                    ? sheet.Re2020Sheet.acvResults.total
                    : sheet.acvResults.total,
                ParentId: null,
              },
            ];

            let subContainer = {
              id: currentZone.id,
              label: currentZone.name,
              uniqueId: container.uniqueId,
              color: getColorFromString(currentZone.name),
              step: level + 1,
              ParentId: parentZone.id,
              children: container,
              res: objectStructure.res,
              ZoneId: level === 0 ? acvPerZone : child.ZoneId,
              SheetId: sheet.id,
            };

            parent = {
              id: parentZone.id,
              label: parentZone.name,
              uniqueId: container.uniqueId,
              color: getColorFromString(parentZone.name),
              step: level + 2,
              ParentId: null,
              children: subContainer,
              res: objectStructure.res,
              ZoneId: level === 0 ? acvPerZone : child.ZoneId,
              SheetId: sheet.id,
            };
          } else {
            objectStructure[objectStructure.details.length] = currentZone.id;

            objectStructure.details = [
              ...objectStructure.details,
              {
                id: currentZone.id,
                name: currentZone.name,
                step: level,
                uniqueId: container.uniqueId,
                res:
                  currentAllotment.id !== 1
                    ? sheet.Re2020Sheet.acvResults.total
                    : sheet.acvResults.total,
                ParentId: null,
              },
            ];

            // console.log('OBJECTSTRUCTURE', objectStructure);

            parent = {
              id: currentZone.id,
              uniqueId: container.uniqueId,
              label: currentZone.name,
              color: getColorFromString(currentZone.name),
              step: level + 1,
              ParentId: null,
              children: container,
              res: objectStructure.res,
              ZoneId: level === 0 ? acvPerZone : child.ZoneId,
              SheetId: sheet.id,
            };
          }

          containers = [...containers, parent];
        }
      });

      return { containers, objectStructure };
    } else {
      return { container, objectStructure };
    }
  }
};

// TODO TEAMRORO delete le mds et les calls associés
export const createProjectStructure = (
  currentAllotment,
  sheets,
  zones,
  currentProject,
  needStructure,
  referenceSurface = null
) => {
  let dataset = {
    id: currentProject.id,
    name: currentProject.name,
    area: referenceSurface || currentProject.area,
    label: 'nooco',
    color: 'hsl(317, 70%, 50%)',
    children: [],
  };

  let allStructure = [];
  let i = 0;
  let psIds = [];

  sheets.forEach((sheet) => {
    const currentAllotmentNode = sheet.ProjectSheet?.AllotmentNodes?.find(
      (ele) => ele.AllotmentId === currentAllotment.id
    );

    if (currentAllotmentNode && !psIds.includes(sheet.ProjectSheet.id)) {
      psIds = [...psIds, sheet.ProjectSheet.id];

      let objectStructure = {};

      const architect = recursiveMatchingAllot(
        currentAllotmentNode,
        [],
        i,
        objectStructure,
        sheet,
        currentAllotment,
        zones,
        currentProject
      );

      if (needStructure && architect) {
        allStructure = [...allStructure, architect.objectStructure];
      }

      if (architect?.containers) {
        dataset.children = [...dataset.children, ...architect.containers];
      } else if (architect) {
        dataset.children = [...dataset.children, architect.container];
      }
    }
  });

  recursiveGroupSimilarSheets(dataset);

  return needStructure ? { dataset, structureGraph: allStructure } : dataset;
};
