import React from 'react';
import logo from '../../assets/images/noocoLogo.png';
import './auth.scss';
import { useTranslation } from 'react-i18next';

const VerifyMail = () => {
  const { t } = useTranslation();
  return (
    <div style={{ width: '35%', margin: 'auto' }}>
      <div className="header-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="verify-mail-container">
        <h1>
          {t('APP.VERIFY_MAIL.TITLE', {
            defaultValue: 'Verifiez votre adresse email',
          })}
        </h1>
        <p>
          {t('APP.VERIFY_MAIL.STEP_TO_FOLLOW', {
            defaultValue:
              'Avant de pouvoir vous connecter, veuillez vérifier votre adresse électronique en cliquant sur le lien que nous avons envoyé à votre adresse mail.',
          })}
        </p>
      </div>
    </div>
  );
};

export default VerifyMail;
