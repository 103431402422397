import { Input, Select } from 'antd';
import { Button } from '@photocarbone/nooco-ui';
// import { Select } from 'antd';
import {
  Caracs,
  Energy,
  OriginDTO,
  SimplifiedCalculatorEnergy,
  SpecificEquipment,
} from '../../../../../models/CalculatorDTO';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DoubleRightOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { StateDTO } from '../../../../../models/GeneralDTO';
import {
  calculatorUpdate,
  getBuildingCalculatorData,
  putBuildingCalculatorData,
  updateCalculatorData,
} from '../../../../../../actions/calculator';
import { getCaracs } from '../../../../../../actions/exploitation';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import SelectUi from '@mui/material/Select';
import TextField from '@material-ui/core/TextField';
import caracError from '../../../../../../assets/images/equipments/calculator/carac.png';
import carac from '../../../../../../assets/images/import/specification.png';
import summerIcon from '../../../../../../assets/images/equipments/calculator/summer.png';
import winterIcon from '../../../../../../assets/images/equipments/calculator/winter.png';
import deleteIcon from '../../../../../../assets/images/equipments/calculator/deleteSimplified.png';
import craneIcon from '../../../../../../assets/images/equipments/calculator/crane.png';
import noCraneIcon from '../../../../../../assets/images/equipments/calculator/noCrane.png';
import {
  findAllAllotmentNodeChildrenFromFilteredParentAllotmentNodes,
  findMdFromAllotmentNode,
  findMdsFromFilteredParentAllotmentNodes,
} from '../../../../../shared/utils';
import { MasterDataEquipmentDTO } from '../../../../../models/SimulatorDTO';
import { AllotmentNodeDTO } from '../../../../../models/ProjectDTO';
import {
  REFERENCE_TYPE_NAME,
  typeProjectForSimplifiedEnergy,
  TEMPLATE_TYPE_NAME,
  PROJECTS_TYPE_NAMES_FROM_TYPE_ALLOTMENT_EPCM,
} from '../../../../../globalVariable/typeProject';
import SimplifiedEnergy from '../../../../../shared/SimplifiedEnergy';
import { changeValueEquipments } from '../../../../../../actions/equipments';
import useCaseWorksite from './UseCaseWorksite';
import { useFormContext } from 'react-hook-form';
import { getSimplifiedEnergyDefaultDataSelected } from './calculatorWorksiteFunction';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

type EnergyProps = {
  // onSubmit: (data: any, changeScreen?: 'next' | 'previous') => void,
  fieldsEnergyForm: {
    fields: SpecificEquipment[];
    append: Function;
    remove: Function;
    update: Function;
    replace: Function;
    insert: Function;
  };
  fieldsEnergySimplifiedForm: {
    fields: SimplifiedCalculatorEnergy[];
    append: Function;
    remove: Function;
    update: Function;
    replace: Function;
    insert: Function;
  };
  changeSourceOfEmptyRows: ({ zone }: { zone: ZoneDTO | null }) => void;
};

const WorksiteEnergy = ({
  fieldsEnergyForm,
  fieldsEnergySimplifiedForm,
  changeSourceOfEmptyRows,
}: EnergyProps) => {
  const {
    control,
    register,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useFormContext();
  const {
    getPreviousScreenActiveId,
    getNextScreenActiveId,
    calculateAreaZoneProrata,
    isDisplayEquipment,
    isEquipmentFromParentZone,
    isEquipmentEditable,
    onSubmitScreen: onSubmit,
    zoneSourceToDisplay,
  } = useCaseWorksite();

  const { fields, append, remove, update, replace, insert } = fieldsEnergyForm;
  const {
    fields: fieldsSimplified,
    append: appendSimplified,
    remove: removeSimplified,
    update: updateSimplified,
    replace: replaceSimplified,
    insert: insertSimplified,
  } = fieldsEnergySimplifiedForm;

  const dispatch = useDispatch();
  const {
    resultOfSearchCarac,
    isLoadingCaracs,
    worksiteCalculatorDetailedActive,
    isWorksiteCalculatorSimplified,
    isWorksiteCalculatorDetailed,
  } = useSelector((state: StateDTO) => state.projects.calculator);
  const { currentProject, isMenuOpen } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { zoneSelected } = useSelector(
    (state: StateDTO) => state.monitoring.breadcrumbMultizone
  );
  const { simplifiedEnergyLines } = useSelector(
    (state: StateDTO) => state.equipments
  );

  const energiesArray = watch('energy.energiesArray');
  const energySimplifiedArray = watch(
    'energyCalculatorSimplified.energySimplifiedArray'
  );
  const { templateType } = useSelector(
    (state: StateDTO) => state.projects.currentProject
  );
  const { t } = useTranslation();
  const { type, flatAllotments, allotments } = useSelector(
    (state: StateDTO) => state.projects.allGroups
  );
  const { zones } = useSelector((state: StateDTO) => state.projects.parsedProject);
  // const { zoneSelected, isWorksiteCalculatorSimplified, isWorksiteCalculatorDetailed } = useSelector((state: StateDTO) => state.projects.calculator);

  // const [filteredMd, setFilteredMd] = useState<MasterDataEquipmentDTO[]>([]);
  const [filteredAllotmentNodes, setFilteredAllotmentNodes] = useState<
    AllotmentNodeDTO[]
  >([]);
  const isSmallScreenMode = window.innerWidth < 1550;

  const [simplifiedCalculatorcolumnSize, setSimplifiedCalculatorcolumnSize] =
    useState({
      logo: 100,
      title: isSmallScreenMode ? 250 : isSmallScreenMode && isMenuOpen ? 150 : 350,
      season: isSmallScreenMode ? 180 : 230,
      total: isSmallScreenMode ? 60 : 95,
      delete: 50,
    });

  useEffect(() => {
    if (filteredAllotmentNodes.length === 0) {
      const AllotmentNodes =
        findAllAllotmentNodeChildrenFromFilteredParentAllotmentNodes(
          flatAllotments,
          ['Energie'],
          'keep'
        );

      const idsUniques = new Set();

      let uniqueFilteredAllotmentNodes = AllotmentNodes.filter((objet) => {
        if (!idsUniques.has(objet.name)) {
          idsUniques.add(objet.name);

          return true;
        }

        return false;
      });

      let filteredAllotmentNodesFinal: AllotmentNodeDTO[] = [];

      uniqueFilteredAllotmentNodes.forEach((AllotmentNode) => {
        const masterData = findMdFromAllotmentNode(type, AllotmentNode);

        if (
          zones.filter((zone) => zone.IsConnectedToUrbanNetwork === true).length > 0
        ) {
          if (
            masterData.referenceType.includes(
              PROJECTS_TYPE_NAMES_FROM_TYPE_ALLOTMENT_EPCM.includes(templateType)
                ? REFERENCE_TYPE_NAME.EPCM
                : REFERENCE_TYPE_NAME.RE2020
            )
          ) {
            filteredAllotmentNodesFinal.push(AllotmentNode);
          }
        } else {
          if (!PROJECTS_TYPE_NAMES_FROM_TYPE_ALLOTMENT_EPCM.includes(templateType)) {
            if (
              masterData.referenceType.includes(
                PROJECTS_TYPE_NAMES_FROM_TYPE_ALLOTMENT_EPCM.includes(templateType)
                  ? REFERENCE_TYPE_NAME.EPCM
                  : REFERENCE_TYPE_NAME.RE2020
              ) &&
              !masterData.referenceType.includes(REFERENCE_TYPE_NAME.EPCM) &&
              masterData.categorie !== 'Réseaux de chaleur' &&
              masterData.categorie !== 'Réseaux de froid'
            ) {
              filteredAllotmentNodesFinal.push(AllotmentNode);
            }
          } else if (
            PROJECTS_TYPE_NAMES_FROM_TYPE_ALLOTMENT_EPCM.includes(templateType)
          ) {
            if (
              masterData.referenceType.includes(REFERENCE_TYPE_NAME.EPCM) &&
              masterData.categorie !== 'Réseaux de chaleur' &&
              masterData.categorie !== 'Réseaux de froid'
            ) {
              filteredAllotmentNodesFinal.push(AllotmentNode);
            }
          }
        }
      });

      setFilteredAllotmentNodes(filteredAllotmentNodesFinal);
    }
  }, []);

  //* update formArray when resultOfSearchCaracs is modified
  useEffect(() => {
    if (resultOfSearchCarac) {
      const { index, caracs } = resultOfSearchCarac;

      caracs?.caracs.map((carac) => {
        carac.value = null;
      });

      if (isWorksiteCalculatorDetailed) {
        update(index, {
          AllotmentNode: watch(`energy.energiesArray.${index}.AllotmentNode`),
          MasterDataEquipment: watch(
            `energy.energiesArray.${index}.MasterDataEquipment`
          ),
          quantity: '',
          caracs: caracs,
          origin: {
            type: zoneSelected?.type || 'project',
            sourceId: zoneSelected?.id || null,
            uniqueId: uuidv4(),
          },
        });
      }

      if (isWorksiteCalculatorSimplified) {
        updateSimplified(index, {
          ...getValues(`energyCalculatorSimplified.energySimplifiedArray.${index}`),
          caracs: caracs,
          origin: {
            type: zoneSelected?.type || 'project',
            sourceId: zoneSelected?.id || null,
            uniqueId: uuidv4(),
          },
        });
      }

      dispatch(calculatorUpdate('resultOfSearchCarac', null));
    }

    dispatch(calculatorUpdate('resultOfSearchCarac', null));
  }, [resultOfSearchCarac]);

  const onSubmitPrevious = (data) => {
    if (isValid) {
      changeSourceOfEmptyRows({
        zone: zoneSelected,
        sideBarActiveId: getPreviousScreenActiveId(),
      });
      dispatch(
        calculatorUpdate(
          'worksiteCalculatorDetailedActive',
          worksiteCalculatorDetailedActive - 1
        )
      );
    } else {
      onSubmit(data, 'previous');
    }
  };

  const onSubmitNext = (data) => {
    if (isValid) {
      changeSourceOfEmptyRows({
        zone: zoneSelected,
        sideBarActiveId: getNextScreenActiveId(),
      });
      dispatch(
        calculatorUpdate(
          'worksiteCalculatorDetailedActive',
          worksiteCalculatorDetailedActive + 1
        )
      );
    } else {
      onSubmit(data, 'next');
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      setSimplifiedCalculatorcolumnSize({
        logo: 100,
        title: isSmallScreenMode ? 150 : 350,
        season: isSmallScreenMode ? 170 : 230,
        total: isSmallScreenMode ? 60 : 95,
        delete: 50,
      });
    } else if (!isMenuOpen) {
      setSimplifiedCalculatorcolumnSize({
        logo: 100,
        title: isSmallScreenMode ? 250 : 350,
        season: isSmallScreenMode ? 180 : 230,
        total: isSmallScreenMode ? 60 : 95,
        delete: 50,
      });
    }
  }, [isMenuOpen]);

  //* getCaracs from api
  const searchCarac = (index: number, md) => {
    dispatch(getCaracs(md, 'calculator', index));
  };

  const calculTotalMonthsWithCrane = (index: number): number | '' => {
    let total = 0;
    total =
      Number(energySimplifiedArray[index]?.NumberMonthsWithCraneSummer) +
      Number(energySimplifiedArray[index]?.NumberMonthsWithCraneWinter);
    return isNaN(total) ? '' : total;
  };

  const calculTotalMonthsWithoutCrane = (index: number): number | '' => {
    let total = 0;
    total =
      Number(energySimplifiedArray[index]?.NumberMonthsWithoutCraneSummer) +
      Number(energySimplifiedArray[index]?.NumberMonthsWithoutCraneWinter);
    return isNaN(total) ? '' : total;
  };

  const calculTotalMonths = (index: number): number | '' => {
    const totalMonths =
      calculTotalMonthsWithCrane(index) + calculTotalMonthsWithoutCrane(index);
    return isNaN(totalMonths) ? '' : totalMonths;
  };

  // const zoneSourceToDisplay = (origin: OriginDTO): string => {
  //   const {type, sourceId} = origin;

  //   if (type === 'project') {
  //     return 'Projet'
  //   }

  //   if (type === 'plot') {
  //     return 'Parcelle'
  //   }

  //   if (type === 'building') {
  //     return zones?.find(zone => zone?.id === sourceId)?.name || ''
  //   }

  //   if (type === 'zone') {
  //     const zoneSource = zones?.find(zone => zone?.id === sourceId)
  //     const zoneParent = zones?.find(zone => zone?.id === zoneSource?.ParentId)
  //     return `${zoneParent?.name} - ${zoneSource?.name}`
  //   }

  //   return ""

  // }

  // need for Select from antd
  const { Option } = Select;

  const onError = (errors) => {
    console.log('validation errors', errors);
  };

  const onBlur = (data) => {
    // console.log("on passe dans le onBlur")
    const getItem = getValues(`energyCalculatorSimplified.energySimplifiedArray`);
    updateSimplified({
      ...getItem,
    });
    const dataUpdate = getValues();
    onSubmit(dataUpdate);

    // dispatch(calculatorUpdate('calculatorData',
    //   data));
  };

  const onBlurError = (errors) => {
    console.log('onBlur validation errors', errors);
  };

  //* display of carac popover
  const renderCaracPopOver = (index, isDisabled = false) => {
    const caracs = isWorksiteCalculatorSimplified
      ? fieldsSimplified[index].caracs
      : fields[index].caracs;

    let caracMainIndex;
    const caracMain = caracs.caracs?.find((carac, index) => {
      if (carac.id === caracs.xAxisId) {
        caracMainIndex = index;

        return carac;
      }
    });
    let discreteIndex;
    const discrete = caracs.caracs?.find((carac, index) => {
      if (carac.id === caracs.discreteId) {
        discreteIndex = index;

        return carac;
      }
    });
    let secondDiscreteIndex;
    const secondDiscrete = caracs.caracs?.find((carac, index) => {
      if (carac.id === caracs.secondDiscreteId) {
        secondDiscreteIndex = index;

        return carac;
      }
    });
    let secondDiscreteOption = [];

    if (discrete?.value) {
      secondDiscreteOption = caracs?.caracCombos?.valueList?.find(
        (option) => option?.value === discrete?.value
      )?.valueList;
    }

    return (
      <div>
        {/* Caracs with input Texfield = caracMain */}
        {caracMain && isWorksiteCalculatorDetailed && (
          <>
            <div
              className="exploitation-carac"
              key={`containerCaracdefault${caracMain.id}`}
            >
              <p>{caracMain.name} :</p>
              <Controller
                control={control}
                {...register(
                  `energy.energiesArray.${index}.caracs.caracs.${caracMainIndex}.value`
                )}
                rules={{
                  required: fields[index].MasterDataEquipment?.id
                    ? t('APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_48C191', {
                        defaultValue: 'Champ obligatoire',
                      })
                    : false,
                  validate: {
                    isNumber: (v) => {
                      if (fields[index].MasterDataEquipment === null) {
                        return true;
                      } else if (!isNaN(Number(v?.replace(/,/, '.')))) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    key={`carac${caracMain.id}`}
                    style={{ width: '50%', marginLeft: '10px' }}
                    onBlur={handleSubmit(onBlur, onBlurError)}
                    disabled={isDisabled}
                  />
                )}
              />
              <p>{caracMain.unit}</p>
            </div>
            {errors?.energy?.energiesArray?.[index]?.caracs?.caracs?.[caracMainIndex]
              ?.value && (
              <div
                style={{ marginLeft: '0', marginBottom: '1rem' }}
                className="container__main--errorMessage"
              >
                {t('APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_F9F4C9', {
                  defaultValue: 'Nombre obligatoire',
                })}
              </div>
            )}
          </>
        )}
        {caracMain && isWorksiteCalculatorSimplified && (
          <>
            <div
              className="exploitation-carac"
              key={`containerCaracdefault${caracMain.id}`}
            >
              <p>{caracMain.name} :</p>
              <Controller
                control={control}
                {...register(
                  `energyCalculatorSimplified.energySimplifiedArray.${index}.caracs.caracs.${caracMainIndex}.value`
                )}
                rules={{
                  required: fieldsSimplified[index].MasterDataEquipment?.id
                    ? t('APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_48C191', {
                        defaultValue: 'Champ obligatoire',
                      })
                    : false,
                  validate: {
                    isNumber: (v) => {
                      if (fieldsSimplified[index].MasterDataEquipment === null) {
                        return true;
                      } else if (!isNaN(Number(v?.replace(/,/, '.')))) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    key={`carac${caracMain.id}`}
                    style={{ width: '50%', marginLeft: '10px' }}
                    onBlur={handleSubmit(onBlur, onBlurError)}
                    disabled={isDisabled}
                  />
                )}
              />
              <p>{caracMain.unit}</p>
            </div>
            {errors?.energyCalculatorSimplified?.energySimplifiedArray?.[index]
              ?.caracs?.caracs?.[caracMainIndex]?.value && (
              <div
                style={{ marginLeft: '0', marginBottom: '1rem' }}
                className="container__main--errorMessage"
              >
                {t('APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_F9F4C9', {
                  defaultValue: 'Nombre obligatoire',
                })}
              </div>
            )}
          </>
        )}

        {/* caracs type list with select discrete*/}
        {discrete && isWorksiteCalculatorDetailed && (
          <>
            <div
              className="exploitation-carac"
              key={`containerCaraclistfirstDiscrete${discrete?.id}`}
            >
              <p>{discrete?.name} :</p>
              <Controller
                control={control}
                {...register(
                  `energy.energiesArray.${index}.caracs.caracs.${discreteIndex}.value`
                )}
                rules={{
                  required: fields[index].MasterDataEquipment?.id
                    ? t('APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_48C191', {
                        defaultValue: 'Champ obligatoire',
                      })
                    : false,
                  validate: {
                    // needs for remplace isNumber from l43
                    isNumber: (v) => {
                      return true;
                    },
                  },
                }}
                render={({ field }) => (
                  <SelectUi
                    {...field}
                    // error
                    key={`energy.energiesArray.${index}.caracs.caracs.${discreteIndex}.value`}
                    variant="outlined"
                    className={
                      errors?.energy?.energiesArray?.[index]?.caracs?.caracs?.[
                        discreteIndex
                      ]
                        ? 'input-boarding error researchBar'
                        : 'input-boarding researchBar'
                    }
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: '50%', marginLeft: '10px' }}
                    // value={discrete?.value}
                    // onChange={(e) => {
                    //   setValue(`energy.energiesArray.${index}.caracs.caracs.${discreteIndex}.value`,
                    //     e.target.value);
                    // }}
                    disabled={isDisabled}
                    onClose={handleSubmit(onBlur, onError)}
                    onBlur={handleSubmit(onBlur, onError)}
                  >
                    {discrete?.caracList?.map((value, index) => {
                      return (
                        <MenuItem
                          key={`${value}discreteItem${index}`}
                          value={value.value}
                        >
                          {value.value}
                        </MenuItem>
                      );
                    })}
                  </SelectUi>
                )}
              />
            </div>
          </>
        )}
        {discrete && isWorksiteCalculatorSimplified && (
          <>
            <div
              className="exploitation-carac"
              key={`containerCaraclistfirstDiscrete${discrete?.id}`}
            >
              <p>{discrete?.name} :</p>
              <Controller
                control={control}
                {...register(
                  `energyCalculatorSimplified.energySimplifiedArray.${index}.caracs.caracs.${discreteIndex}.value`
                )}
                rules={{
                  required: fieldsSimplified[index].MasterDataEquipment?.id
                    ? t('APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_48C191', {
                        defaultValue: 'Champ obligatoire',
                      })
                    : false,
                  validate: {
                    // needs for remplace isNumber from l43
                    isNumber: (v) => {
                      return true;
                    },
                  },
                }}
                render={({ field }) => (
                  <SelectUi
                    {...field}
                    // error
                    key={`energyCalculatorSimplified.energySimplifiedArray.${index}.caracs.caracs.${discreteIndex}.value`}
                    variant="outlined"
                    className={
                      errors?.energyCalculatorSimplified?.energySimplifiedArray?.[
                        index
                      ]?.caracs?.caracs?.[discreteIndex]
                        ? 'input-boarding error researchBar'
                        : 'input-boarding researchBar'
                    }
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: '50%', marginLeft: '10px' }}
                    // value={discrete?.value}
                    // onChange={(e) => {
                    //   setValue(`energy.energiesArray.${index}.caracs.caracs.${discreteIndex}.value`,
                    //     e.target.value);
                    // }}
                    disabled={isDisabled}
                    onClose={handleSubmit(onBlur, onError)}
                    onBlur={handleSubmit(onBlur, onError)}
                  >
                    {discrete?.caracList?.map((value, index) => {
                      return (
                        <MenuItem
                          key={`${value}discreteItem${index}`}
                          value={value.value}
                        >
                          {value.value}
                        </MenuItem>
                      );
                    })}
                  </SelectUi>
                )}
              />
            </div>
          </>
        )}

        {/* caracs type list with select  Discrete */}
        {secondDiscrete && isWorksiteCalculatorDetailed && (
          <>
            <div
              className="exploitation-carac"
              key={`containerCaraclistsecondeDiscrete${secondDiscrete.id}`}
            >
              <p>{secondDiscrete.name} :</p>
              <Controller
                control={control}
                {...register(
                  `energy.energyArray.${index}.caracs.caracs.${secondDiscreteIndex}.value`
                )}
                rules={{
                  required: fields[index].MasterDataEquipment?.id
                    ? t('APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_48C191', {
                        defaultValue: 'Champ obligatoire',
                      })
                    : false,
                  validate: {
                    // needs for remplace isNumber from l43
                    isNumber: (v) => {
                      return true;
                    },
                  },
                }}
                render={({ field }) => (
                  <SelectUi
                    {...field}
                    // error
                    key={`energy.energyArray.${index}.caracs.caracs.${secondDiscreteIndex}.value`}
                    variant="outlined"
                    className={
                      errors?.energy?.energyArray?.[index]?.caracs?.caracs?.[
                        secondDiscreteIndex
                      ]
                        ? 'input-boarding error researchBar'
                        : 'input-boarding researchBar'
                    }
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: '50%', marginLeft: '10px' }}
                    value={secondDiscrete?.value}
                    disabled={discrete?.value === null || isDisabled}
                    onChange={(e) => {
                      setValue(
                        `energy.energyArray.${index}.caracs.caracs.${secondDiscreteIndex}.value`,
                        e.target.value
                      );
                    }}
                    onClose={handleSubmit(onBlur, onError)}
                    onBlur={handleSubmit(onBlur, onError)}
                    // value={carac.caracList.find((el) => el.name === carac?.value)}
                    // value={carac.caracList.find((el) => el.name === carac.name)}
                  >
                    {/* {secondDiscrete.caracList.map((option, index) => { */}
                    {secondDiscreteOption?.map((option, index) => {
                      return (
                        <MenuItem
                          key={`${option}secondDiscreteItems${index}`}
                          value={option?.value}
                          // onClose={handleSubmit(onBlur, onError)}
                          onBlur={handleSubmit(onBlur, onError)}
                        >
                          {option?.value}
                        </MenuItem>
                      );
                    })}
                  </SelectUi>
                )}
              />
            </div>
          </>
        )}
        {secondDiscrete && isWorksiteCalculatorSimplified && (
          <>
            <div
              className="exploitation-carac"
              key={`containerCaraclistsecondeDiscrete${secondDiscrete.id}`}
            >
              <p>{secondDiscrete.name} :</p>
              <Controller
                control={control}
                {...register(
                  `energyCalculatorSimplified.energySimplifiedArray.${index}.caracs.caracs.${secondDiscreteIndex}.value`
                )}
                rules={{
                  required: fields[index].MasterDataEquipment?.id
                    ? t('APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_48C191', {
                        defaultValue: 'Champ obligatoire',
                      })
                    : false,
                  validate: {
                    // needs for remplace isNumber from l43
                    isNumber: (v) => {
                      return true;
                    },
                  },
                }}
                render={({ field }) => (
                  <SelectUi
                    {...field}
                    // error
                    key={`energyCalculatorSimplified.energySimplifiedArray.${index}.caracs.caracs.${secondDiscreteIndex}.value`}
                    variant="outlined"
                    className={
                      errors?.energyCalculatorSimplified?.energySimplifiedArray?.[
                        index
                      ]?.caracs?.caracs?.[secondDiscreteIndex]
                        ? 'input-boarding error researchBar'
                        : 'input-boarding researchBar'
                    }
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: '50%', marginLeft: '10px' }}
                    value={secondDiscrete?.value}
                    disabled={discrete?.value === null || isDisabled}
                    onChange={(e) => {
                      setValue(
                        `energyCalculatorSimplified.energySimplifiedArray.${index}.caracs.caracs.${secondDiscreteIndex}.value`,
                        e.target.value
                      );
                    }}
                    onClose={handleSubmit(onBlur, onError)}
                    onBlur={handleSubmit(onBlur, onError)}
                    // value={carac.caracList.find((el) => el.name === carac?.value)}
                    // value={carac.caracList.find((el) => el.name === carac.name)}
                  >
                    {/* {secondDiscrete.caracList.map((option, index) => { */}
                    {secondDiscreteOption?.map((option, index) => {
                      return (
                        <MenuItem
                          key={`${option}secondDiscreteItems${index}`}
                          value={option?.value}
                          // onClose={handleSubmit(onBlur, onError)}
                          onBlur={handleSubmit(onBlur, onError)}
                        >
                          {option?.value}
                        </MenuItem>
                      );
                    })}
                  </SelectUi>
                )}
              />
            </div>
          </>
        )}
      </div>
    );
  };
  //* End of renderCaracPopover

  const handleClose = () => {
    calculatorUpdate('isLoadingCaracs', false);
  };
  const classes = useStyles();

  return (
    <>
      {
        // calculator Simplified Energy
        isWorksiteCalculatorSimplified ? (
          <form
            className={`calculatorFlexColumnStart `}
            style={{ flexGrow: '1' }}
            action=""
          >
            <div style={{ marginBottom: '0.5rem' }} style={{ fontSize: '15px' }}>
              {t('APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_2921B0', {
                defaultValue:
                  "Calcul de la consommation d'électricité du chantier (kwh)",
              })}
            </div>
            {/* array of energies */}
            <div>
              {fieldsSimplified.map((item, index: number) => {
                if (isEquipmentEditable(item)) {
                  return (
                    <div
                      key={item.id}
                      className={`calculatorSimplifiedEnergyContainer ${
                        isSmallScreenMode ? 'isSmallScreenMode' : ''
                      }`}
                    >
                      {/* header row */}
                      <div className="rowContainer">
                        <div
                          className="label"
                          style={{
                            width: `${
                              simplifiedCalculatorcolumnSize.logo +
                              simplifiedCalculatorcolumnSize.title
                            }px`,
                          }}
                        >
                          <p>
                            {t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_521A71',
                              {
                                value: zoneSourceToDisplay(item.origin),
                                defaultValue: 'Votre saisie dans : {value}',
                              }
                            )}
                          </p>
                        </div>
                        <div
                          className="seasonWrapper header"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.season}px`,
                          }}
                        >
                          <img src={summerIcon} alt="" />
                          <div className="titleWrapper">
                            <p>
                              {t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_D5C623',
                                { defaultValue: 'En été' }
                              )}
                            </p>
                            <p>
                              {t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_A60E48',
                                { defaultValue: '(Avril à Septembre)' }
                              )}
                            </p>
                          </div>
                        </div>
                        <div
                          className="seasonWrapper header"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.season}px`,
                          }}
                        >
                          <img src={winterIcon} alt="" />
                          <div className="titleWrapper">
                            <p>
                              {' '}
                              {t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_8F9E8E',
                                { defaultValue: 'En hiver' }
                              )}
                            </p>
                            <p>
                              {t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_F5028D',
                                { defaultValue: '(Octobre à Mars)' }
                              )}
                            </p>
                          </div>
                        </div>
                        <div
                          className="totalWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.total}px`,
                          }}
                        >
                          {t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_145B1D',
                            { defaultValue: 'total' }
                          )}
                        </div>
                        <div
                          className="deleteWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.delete}px`,
                          }}
                        />
                      </div>
                      {/* with Crane row */}
                      <div className="rowContainer">
                        <div
                          className="logoWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.logo}px`,
                          }}
                        >
                          <img src={craneIcon} alt="" />
                        </div>
                        <div
                          className="titleWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.title}px`,
                          }}
                        >
                          {t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_A23985',
                            {
                              defaultValue:
                                'Nombre de mois de chantier avec présence de grue',
                            }
                          )}
                        </div>
                        <div
                          className="seasonWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.season}px`,
                          }}
                        >
                          <Controller
                            control={control}
                            rules={{
                              required: false,
                              validate: {
                                // rules={{ validate: {
                                isNumber: (v) => {
                                  if (!isNaN(Number(v?.replace(/,/, '.')))) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                },
                              },
                            }}
                            {...register(
                              `energyCalculatorSimplified.energySimplifiedArray.${index}.NumberMonthsWithCraneSummer`
                            )}
                            render={({ field }) => (
                              <Input
                                {...field}
                                size="large"
                                placeholder={t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_451A2A',
                                  { defaultValue: "Nombre de mois d'été" }
                                )}
                                // style={{width: '290px'}}
                                status={
                                  errors?.energyCalculatorSimplified
                                    ?.energySimplifiedArray?.[index]
                                    ?.NumberMonthsWithCraneSummer
                                    ? 'error'
                                    : ''
                                }
                                // disabled={watch('energy.energiesArray')[index].MasterDataEquipment ? false : true}
                                // onBlur={handleSubmit(onBlur,
                                //   onBlurError)}
                                // onBlur={onBlurHandler}
                                onMouseOut={handleSubmit(onBlur, onError)}
                              />
                            )}
                          />
                        </div>
                        <div
                          className="seasonWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.season}px`,
                          }}
                        >
                          <Controller
                            control={control}
                            rules={{
                              required: false,
                              validate: {
                                // rules={{ validate: {
                                isNumber: (v) => {
                                  if (!isNaN(Number(v?.replace(/,/, '.')))) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                },
                              },
                            }}
                            {...register(
                              `energyCalculatorSimplified.energySimplifiedArray.${index}.NumberMonthsWithCraneWinter`
                            )}
                            render={({ field }) => (
                              <Input
                                {...field}
                                size="large"
                                placeholder={t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_C06D87',
                                  { defaultValue: "Nombre de mois d'hiver" }
                                )}
                                // style={{width: '290px'}}
                                status={
                                  errors?.energyCalculatorSimplified
                                    ?.energySimplifiedArray?.[index]
                                    ?.NumberMonthsWithCraneWinter
                                    ? 'error'
                                    : ''
                                }
                                // disabled={watch('energy.energiesArray')[index].MasterDataEquipment ? false : true}
                                // onBlur={handleSubmit(onBlur,
                                //   onBlurError)}
                                // onBlur={onBlurHandler}
                                onMouseOut={handleSubmit(onBlur, onError)}
                              />
                            )}
                          />
                        </div>
                        <div
                          className="totalWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.total}px`,
                          }}
                        >
                          <Input
                            size="large"
                            disabled={true}
                            value={calculTotalMonthsWithCrane(index)}
                          />
                        </div>
                        <div
                          className="deleteWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.delete}px`,
                          }}
                          onClick={() => {
                            if (
                              watch(
                                'energyCalculatorSimplified.energySimplifiedArray'
                              ).length > 1
                            ) {
                              removeSimplified(index);
                            } else {
                              removeSimplified(index);
                              insertSimplified(0, {
                                NumberMonthsWithCraneSummer: '',
                                NumberMonthsWithCraneWinter: '',
                                NumberMonthsWithoutCraneSummer: '',
                                NumberMonthsWithoutCraneWinter: '',
                                origin: {
                                  type: zoneSelected?.type || 'project',
                                  sourceId: zoneSelected?.id || null,
                                  uniqueId: uuidv4(),
                                },
                                ...getSimplifiedEnergyDefaultDataSelected(
                                  templateType
                                ),
                              });
                            }
                          }}
                        >
                          <HighlightOffIcon
                            style={{ width: '40px', height: '40px' }}
                            className="calculator__delElement"
                          />
                          {/* <img src={deleteIcon} alt="" /> */}
                        </div>
                      </div>
                      {/* without Crane row */}
                      <div className="rowContainer">
                        <div
                          className="logoWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.logo}px`,
                          }}
                        >
                          <img src={noCraneIcon} alt="" />
                        </div>
                        <div
                          className="titleWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.title}px`,
                          }}
                        >
                          {t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_B72F9E',
                            {
                              defaultValue:
                                'Nombre de mois de chantier sans présence de grue',
                            }
                          )}
                        </div>
                        <div
                          className="seasonWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.season}px`,
                          }}
                        >
                          <Controller
                            control={control}
                            rules={{
                              required: false,
                              validate: {
                                // rules={{ validate: {
                                isNumber: (v) => {
                                  if (!isNaN(Number(v?.replace(/,/, '.')))) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                },
                              },
                            }}
                            {...register(
                              `energyCalculatorSimplified.energySimplifiedArray.${index}.NumberMonthsWithoutCraneSummer`
                            )}
                            render={({ field }) => (
                              <Input
                                {...field}
                                size="large"
                                placeholder={t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_451A2A',
                                  { defaultValue: "Nombre de mois d'été" }
                                )}
                                // style={{width: '290px'}}
                                status={
                                  errors?.energyCalculatorSimplified
                                    ?.energySimplifiedArray?.[index]
                                    ?.NumberMonthsWithoutCraneSummer
                                    ? 'error'
                                    : ''
                                }
                                // disabled={watch('energy.energiesArray')[index].MasterDataEquipment ? false : true}
                                // onBlur={handleSubmit(onBlur,
                                //   onBlurError)}
                                // onBlur={onBlurHandler}
                                onMouseOut={handleSubmit(onBlur, onError)}
                              />
                            )}
                          />
                        </div>
                        <div
                          className="seasonWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.season}px`,
                          }}
                        >
                          <Controller
                            control={control}
                            rules={{
                              required: false,
                              validate: {
                                // rules={{ validate: {
                                isNumber: (v) => {
                                  if (!isNaN(Number(v?.replace(/,/, '.')))) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                },
                              },
                            }}
                            {...register(
                              `energyCalculatorSimplified.energySimplifiedArray.${index}.NumberMonthsWithoutCraneWinter`
                            )}
                            render={({ field }) => (
                              <Input
                                {...field}
                                size="large"
                                placeholder={t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_C06D87',
                                  { defaultValue: "Nombre de mois d'hiver" }
                                )}
                                // style={{width: '290px'}}
                                status={
                                  errors?.energyCalculatorSimplified
                                    ?.energySimplifiedArray?.[index]
                                    ?.NumberMonthsWithoutCraneWinter
                                    ? 'error'
                                    : ''
                                }
                                // disabled={watch('energy.energiesArray')[index].MasterDataEquipment ? false : true}
                                // onBlur={handleSubmit(onBlur,
                                //   onBlurError)}
                                // onBlur={onBlurHandler}
                                onMouseOut={handleSubmit(onBlur, onError)}
                              />
                            )}
                          />
                        </div>
                        <div
                          className="totalWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.total}px`,
                          }}
                        >
                          <Input
                            size="large"
                            disabled={true}
                            value={calculTotalMonthsWithoutCrane(index)}
                          />
                        </div>
                        <div
                          className="deleteWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.delete}px`,
                          }}
                          onClick={() => {
                            const getItem = getValues(
                              `energyCalculatorSimplified.energySimplifiedArray.${index}`
                            );
                            updateSimplified(index, {
                              ...getItem,
                              NumberMonthsWithoutCraneSummer: '',
                              NumberMonthsWithoutCraneWinter: '',
                            });
                          }}
                        >
                          {/* <img src={deleteIcon} alt="" /> */}
                        </div>
                      </div>
                      {/* total row */}
                      <div className="rowContainer">
                        {/* empty column */}
                        <div
                          className="logoWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.logo}px`,
                          }}
                        />

                        {/* Select energy Type */}
                        <div
                          className={`energySimplifiedResearch ${
                            isSmallScreenMode ? ' isSmallScreenMode' : ''
                          } `}
                          style={{
                            width: `${
                              simplifiedCalculatorcolumnSize.title +
                              simplifiedCalculatorcolumnSize.season +
                              32
                            }px`,
                            marginRight: '1rem',
                          }}
                        >
                          <Controller
                            control={control}
                            {...register(
                              `energyCalculatorSimplified.energySimplifiedArray.${index}.MasterDataEquipment`
                            )}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                className="equipmentResearch"
                                key={`autocomplete-${item.id}`}
                                style={{ borderRadius: '30px' }}
                                placeholder={t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_D9BB8B',
                                  { defaultValue: 'Choisir un equipement' }
                                )}
                                // value={filteredMd.find((md) => md.id) || null}
                                value={
                                  watch(
                                    `energyCalculatorSimplified.energySimplifiedArray.${index}.AllotmentNode`
                                  ) || null
                                }
                                id="checkboxes-tags-demo"
                                options={filteredAllotmentNodes}
                                getOptionLabel={(option) => option.name || ''}
                                // getOptionSelected={(option) => option.id}
                                noOptionsText={t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_BFCCE9',
                                  { defaultValue: 'Aucun résultat trouvé' }
                                )}
                                onBlur={handleSubmit(onBlur, onBlurError)}
                                onChange={(event, newValue: any) => {
                                  if (newValue) {
                                    const masterData = findMdFromAllotmentNode(
                                      type,
                                      newValue
                                    );
                                    updateSimplified(index, {
                                      ...getValues(
                                        `energyCalculatorSimplified.energySimplifiedArray.${index}`
                                      ),
                                      // masterDataId: newValue.MasterDataEquipement.id,
                                      AllotmentNode: newValue,
                                      MasterDataEquipment: masterData,
                                      caracs: null,
                                      origin: {
                                        type: zoneSelected?.type || 'project',
                                        sourceId: zoneSelected?.id || null,
                                        uniqueId: uuidv4(),
                                      },
                                    });

                                    dispatch(
                                      calculatorUpdate('isLoadingCaracs', true)
                                    );
                                    searchCarac(index, newValue);
                                  } else {
                                    dispatch(
                                      calculatorUpdate('resultOfSearchCarac', null)
                                    );
                                    updateSimplified(index, {
                                      ...getValues(
                                        `energyCalculatorSimplified.energySimplifiedArray.${index}`
                                      ),
                                      AllotmentNode: null,
                                      masterDataId: null,
                                      caracs: null,
                                      origin: {
                                        type: zoneSelected?.type || 'project',
                                        sourceId: zoneSelected?.id || null,
                                        uniqueId: uuidv4(),
                                      },
                                    });
                                  }
                                }}
                                size={'small'}
                                disabled={isEquipmentEditable(item) ? false : true}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onBlur={handleSubmit(onBlur, onBlurError)}
                                    placeholder={t(
                                      'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_17A450',
                                      {
                                        defaultValue: "Choisir un type d'énergie",
                                      }
                                    )}
                                    disabled={
                                      isEquipmentEditable(item) ? false : true
                                    }
                                    variant="outlined"
                                    className={`researchBar ${
                                      isEquipmentEditable(item) ? '' : 'disabled'
                                    }`}
                                    style={{ marginBottom: '0!important' }}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>

                        {/* Carac */}
                        <div
                          className="seasonWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.season}px`,
                            marginRight: '2rem',
                          }}
                        >
                          <div className="energyCaracs">
                            {/* TODO a reactiver */}
                            {item.caracs?.caracs?.length > 0 && (
                              <Controller
                                control={control}
                                {...register(
                                  `energyCalculatorSimplified.energySimplifiedArray.${index}.caracs`
                                )}
                                render={({ field }) => (
                                  <Popover
                                    {...field}
                                    defaultVisible={
                                      !fieldsSimplified[index].caracs?.caracs?.[0]
                                        ?.value
                                    }
                                    placement="top"
                                    title={t(
                                      'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_A41941',
                                      { defaultValue: 'Caractéristiques' }
                                    )}
                                    key={`popover-${item}`}
                                    content={renderCaracPopOver(index)}
                                    trigger="click"
                                  >
                                    <img
                                      src={caracError}
                                      style={{
                                        filter: errors?.energyCalculatorSimplified
                                          ?.energySimplifiedArray?.[index]?.caracs
                                          ? undefined
                                          : 'grayscale(100%)',
                                      }}
                                    />
                                  </Popover>
                                )}
                              />
                            )}
                          </div>
                        </div>

                        {/* total */}
                        <div
                          className="totalWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.total}px`,
                          }}
                        >
                          <Input
                            size="large"
                            disabled={true}
                            value={calculTotalMonths(index)}
                          />
                        </div>

                        {/* deleteIcon */}
                        <div
                          className="deleteWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.delete}px`,
                          }}
                        >
                          {/* <img src={deleteIcon} alt="" /> */}
                        </div>
                      </div>
                    </div>
                  );
                }

                if (isDisplayEquipment(item)) {
                  let NumberMonthsWithCraneSummer = item.NumberMonthsWithCraneSummer;
                  let NumberMonthsWithCraneWinter = item.NumberMonthsWithCraneWinter;
                  let NumberMonthsWithoutCraneSummer =
                    item.NumberMonthsWithoutCraneSummer;
                  let NumberMonthsWithoutCraneWinter =
                    item.NumberMonthsWithoutCraneWinter;

                  if (isEquipmentFromParentZone(item)) {
                    const quantityProrata = calculateAreaZoneProrata(item);
                    NumberMonthsWithCraneSummer =
                      Math.round(
                        NumberMonthsWithCraneSummer * quantityProrata * 10000
                      ) / 10000;
                    NumberMonthsWithCraneWinter =
                      Math.round(
                        NumberMonthsWithCraneWinter * quantityProrata * 10000
                      ) / 10000;
                    NumberMonthsWithoutCraneSummer =
                      Math.round(
                        NumberMonthsWithoutCraneSummer * quantityProrata * 10000
                      ) / 10000;
                    NumberMonthsWithoutCraneWinter =
                      Math.round(
                        NumberMonthsWithoutCraneWinter * quantityProrata * 10000
                      ) / 10000;
                  }

                  const NumberMonthsWithCrane =
                    NumberMonthsWithCraneSummer + NumberMonthsWithCraneWinter;
                  const NumberMonthsWithoutCrane =
                    NumberMonthsWithoutCraneSummer + NumberMonthsWithoutCraneWinter;
                  const TotalMonths =
                    NumberMonthsWithCrane + NumberMonthsWithoutCrane;

                  return (
                    <div
                      key={item.origin.uniqueId}
                      className={`calculatorSimplifiedEnergyContainer disabled`}
                    >
                      {/* header row */}
                      <div className="rowContainer">
                        <div
                          className="label"
                          style={{
                            width: `${
                              simplifiedCalculatorcolumnSize.logo +
                              simplifiedCalculatorcolumnSize.title
                            }px`,
                          }}
                        >
                          <p>
                            {t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_521A71',
                              {
                                value: zoneSourceToDisplay(item.origin),
                                defaultValue: 'Votre saisie dans : {value}',
                              }
                            )}
                          </p>
                        </div>
                        <div
                          className="seasonWrapper header"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.season}px`,
                          }}
                        >
                          <img src={summerIcon} alt="" />
                          <div className="titleWrapper">
                            <p>
                              {t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_D5C623',
                                { defaultValue: 'En été' }
                              )}
                            </p>
                            <p>
                              {t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_A60E48',
                                { defaultValue: '(Avril à Septembre)' }
                              )}
                            </p>
                          </div>
                        </div>
                        <div
                          className="seasonWrapper header"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.season}px`,
                          }}
                        >
                          <img src={winterIcon} alt="" />
                          <div className="titleWrapper">
                            <p>
                              {' '}
                              {t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_8F9E8E',
                                { defaultValue: 'En hiver' }
                              )}
                            </p>
                            <p>
                              {t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_F5028D',
                                { defaultValue: '(Octobre à Mars)' }
                              )}
                            </p>
                          </div>
                        </div>
                        <div
                          className="totalWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.total}px`,
                          }}
                        >
                          {t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_145B1D',
                            { defaultValue: 'total' }
                          )}
                        </div>
                        <div
                          className="deleteWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.delete}px`,
                          }}
                        />
                      </div>
                      {/* with Crane row */}
                      <div className="rowContainer">
                        <div
                          className="logoWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.logo}px`,
                          }}
                        >
                          <img src={craneIcon} alt="" />
                        </div>
                        <div
                          className="titleWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.title}px`,
                          }}
                        >
                          {t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_A23985',
                            {
                              defaultValue:
                                'Nombre de mois de chantier avec présence de grue',
                            }
                          )}
                        </div>
                        <div
                          className="seasonWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.season}px`,
                          }}
                        >
                          <Input
                            size="large"
                            placeholder={t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_451A2A',
                              { defaultValue: "Nombre de mois d'été" }
                            )}
                            disabled={true}
                            value={NumberMonthsWithCraneSummer}
                          />
                        </div>
                        <div
                          className="seasonWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.season}px`,
                          }}
                        >
                          <Input
                            size="large"
                            placeholder={t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_C06D87',
                              { defaultValue: "Nombre de mois d'hiver" }
                            )}
                            disabled={true}
                            value={NumberMonthsWithCraneWinter}
                          />
                        </div>
                        <div
                          className="totalWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.total}px`,
                          }}
                        >
                          <Input
                            size="large"
                            disabled={true}
                            value={NumberMonthsWithCrane}
                          />
                        </div>
                        {/* <div className='deleteWrapper' style={{width: `${simplifiedCalculatorcolumnSize.delete}px`}}>
                        <img src={deleteIcon} alt="" />
                      </div> */}
                      </div>
                      {/* without Crane row */}
                      <div className="rowContainer">
                        <div
                          className="logoWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.logo}px`,
                          }}
                        >
                          <img src={noCraneIcon} alt="" />
                        </div>
                        <div
                          className="titleWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.title}px`,
                          }}
                        >
                          {t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_B72F9E',
                            {
                              defaultValue:
                                'Nombre de mois de chantier sans présence de grue',
                            }
                          )}
                        </div>
                        <div
                          className="seasonWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.season}px`,
                          }}
                        >
                          <Input
                            size="large"
                            placeholder={t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_451A2A',
                              { defaultValue: "Nombre de mois d'été" }
                            )}
                            disabled={true}
                            value={NumberMonthsWithoutCraneSummer}
                          />
                        </div>
                        <div
                          className="seasonWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.season}px`,
                          }}
                        >
                          <Input
                            size="large"
                            placeholder={t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_C06D87',
                              { defaultValue: "Nombre de mois d'hiver" }
                            )}
                            disabled={true}
                            value={NumberMonthsWithoutCraneWinter}
                          />
                        </div>
                        <div
                          className="totalWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.total}px`,
                          }}
                        >
                          <Input
                            size="large"
                            disabled={true}
                            value={NumberMonthsWithoutCrane}
                          />
                        </div>
                        {/* <div className='deleteWrapper' style={{width: `${simplifiedCalculatorcolumnSize.delete}px`}}>
                        <img src={deleteIcon} alt="" />
                      </div> */}
                      </div>
                      {/* total row */}
                      <div className="rowContainer">
                        <div
                          className="logoWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.logo}px`,
                          }}
                        />
                        {/* Select energy Type */}
                        <div
                          className="energySimplifiedResearch"
                          style={{
                            width: `${
                              simplifiedCalculatorcolumnSize.title +
                              simplifiedCalculatorcolumnSize.season +
                              32
                            }px`,
                            marginRight: '2rem',
                          }}
                        >
                          <Autocomplete
                            className="equipmentResearch"
                            key={`autocomplete-${item.id}`}
                            style={{ borderRadius: '30px' }}
                            placeholder={t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_D9BB8B',
                              { defaultValue: 'Choisir un equipement' }
                            )}
                            // value={filteredMd.find((md) => md.id) || null}
                            value={
                              watch(
                                `energyCalculatorSimplified.energySimplifiedArray.${index}.AllotmentNode`
                              ) || null
                            }
                            id="checkboxes-tags-demo"
                            options={filteredAllotmentNodes}
                            getOptionLabel={(option) => option.name || ''}
                            // getOptionSelected={(option) => option.id}
                            noOptionsText={t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_BFCCE9',
                              { defaultValue: 'Aucun résultat trouvé' }
                            )}
                            size={'small'}
                            disabled={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_17A450',
                                  { defaultValue: "Choisir un type d'énergie" }
                                )}
                                disabled={true}
                                variant="outlined"
                                className={`researchBar disabled`}
                                style={{ marginBottom: '0!important' }}
                              />
                            )}
                          />
                        </div>

                        {/* Carac */}
                        <div
                          className="seasonWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.season}px`,
                            marginRight: '2rem',
                          }}
                        >
                          <div className="energyCaracs newImg">
                            {/* TODO a reactiver */}
                            {item.caracs?.caracs?.length > 0 && (
                              <Controller
                                control={control}
                                {...register(
                                  `energyCalculatorSimplified.energySimplifiedArray.${index}.caracs`
                                )}
                                render={({ field }) => (
                                  <Popover
                                    defaultVisible={false}
                                    placement="top"
                                    title={t(
                                      'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_A41941',
                                      { defaultValue: 'Caractéristiques' }
                                    )}
                                    key={`popover-${item}`}
                                    content={renderCaracPopOver(index, true)}
                                    trigger="click"
                                  >
                                    <img
                                      src={carac}
                                      style={{
                                        width: '33px',
                                        height: '35px',
                                        marginLeft: '10px',
                                        marginTop: '8px',
                                      }}
                                    />
                                  </Popover>
                                )}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          className="totalWrapper"
                          style={{
                            width: `${simplifiedCalculatorcolumnSize.total}px`,
                          }}
                        >
                          <Input size="large" disabled={true} value={TotalMonths} />
                        </div>
                      </div>
                    </div>
                  );
                }

                return null;
              })}
            </div>

            {/* Add energyCalculatorSimplified row */}
            <div
              className="calculator__addElement"
              // style={{maxWidth: '180px'}}
              onClick={() =>
                appendSimplified({
                  NumberMonthsWithCraneSummer: '',
                  NumberMonthsWithCraneWinter: '',
                  NumberMonthsWithoutCraneSummer: '',
                  NumberMonthsWithoutCraneWinter: '',
                  origin: {
                    type: zoneSelected?.type || 'project',
                    sourceId: zoneSelected?.id || null,
                    uniqueId: uuidv4(),
                  },
                  ...getSimplifiedEnergyDefaultDataSelected(templateType),
                })
              }
            >
              <ControlPointIcon style={{ paddingRight: '5px' }} />
              Ajouter un type d&apos;énergie
            </div>

            {/* prev and next button*/}
            <div
              className="calculatorFlexColumnStart"
              style={{ flexGrow: '1', justifyContent: 'flex-end' }}
            >
              <div
                className="calculatorFlexStart"
                style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
              >
                <div
                  className="calculatorFlexColumnStart"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Button
                      style={{
                        fontSize:
                          window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                      }}
                      className="close-button"
                      onClick={handleSubmit(onSubmitPrevious, onError)}
                      disabled={getPreviousScreenActiveId() === null}
                      text={t(
                        'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_8AEC06',
                        {
                          defaultValue: 'Précédent',
                        }
                      )}
                    />
                    <Button
                      style={{
                        fontSize:
                          window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                        marginLeft: '5px',
                      }}
                      className="purple-button"
                      onClick={handleSubmit(onSubmitNext, onError)}
                      disabled={getNextScreenActiveId() === null}
                      text={t(
                        'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_C988BE',
                        {
                          defaultValue: 'Suivant',
                        }
                      )}
                    />
                  </div>
                </div>

                {/* Validation Button */}
                {/* <div          
              className='calculator__nextButton'
              onClick={handleSubmit(onSubmit, onError)}
            >
              <p>Suivant</p>
              <DoubleRightOutlined className=' calculator__nextButton--icon'/>
            </div> */}
              </div>
            </div>
          </form>
        ) : // Calculator detailled Energy screen (not simplified)
        !typeProjectForSimplifiedEnergy.includes(currentProject.templateType) ? (
          <form
            className={`calculatorFlexColumnStart ${
              isSmallScreenMode ? 'isSmallScreenMode' : ''
            }`}
            style={{ flexGrow: '1' }}
            action=""
          >
            {/* Titles of sub array */}
            <div className="calculatorFlexStart">
              <h3 className="calculator__label energyResearch">Type d'énergie</h3>
              <div className="calculator__label energyCaracs" />
              <h3 className="calculator__label energyQuantity">
                {t('APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_E12D40', {
                  defaultValue: "Quantité d'énergie consommée (kWh)",
                })}
              </h3>
            </div>

            {/* Array of selected type of energy with quantity */}
            <div className="calculatorScrollContainer">
              <Backdrop
                open={isLoadingCaracs}
                className={classes.backdrop}
                onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              {fields.map((item, index: number) => {
                // display editable energie raw
                if (isEquipmentEditable(item)) {
                  return (
                    <div key={item.id} className="cardWrapper">
                      {/* Select energy Type */}
                      <div className="calculatorFlexColumnStart energyResearch">
                        <Controller
                          control={control}
                          {...register(
                            `energy.energiesArray.${index}.MasterDataEquipment`
                          )}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              className="equipmentResearch"
                              key={`autocomplete-${item.id}`}
                              style={{ borderRadius: '30px' }}
                              placeholder={t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_D9BB8B',
                                { defaultValue: 'Choisir un equipement' }
                              )}
                              // value={filteredMd.find((md) => md.id) || null}
                              value={
                                watch(
                                  `energy.energiesArray.${index}.AllotmentNode`
                                ) || null
                              }
                              id="checkboxes-tags-demo"
                              options={filteredAllotmentNodes}
                              getOptionLabel={(option) => option.name || ''}
                              // getOptionSelected={(option) => option.id}
                              noOptionsText={t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_BFCCE9',
                                { defaultValue: 'Aucun résultat trouvé' }
                              )}
                              onBlur={handleSubmit(onBlur, onBlurError)}
                              onChange={(event, newValue: any) => {
                                if (newValue) {
                                  const masterData = findMdFromAllotmentNode(
                                    type,
                                    newValue
                                  );
                                  update(index, {
                                    // masterDataId: newValue.MasterDataEquipement.id,
                                    AllotmentNode: newValue,
                                    MasterDataEquipment: masterData,
                                    quantity: '',
                                    caracs: null,
                                    origin: {
                                      type: zoneSelected?.type || 'project',
                                      sourceId: zoneSelected?.id || null,
                                      uniqueId: uuidv4(),
                                    },
                                  });

                                  dispatch(
                                    calculatorUpdate('isLoadingCaracs', true)
                                  );
                                  searchCarac(index, newValue);
                                } else {
                                  dispatch(
                                    calculatorUpdate('resultOfSearchCarac', null)
                                  );
                                  update(index, {
                                    masterDataId: null,
                                    quantity: '',
                                    caracs: null,
                                    origin: {
                                      type: zoneSelected?.type || 'project',
                                      sourceId: zoneSelected?.id || null,
                                      uniqueId: uuidv4(),
                                    },
                                  });
                                }
                              }}
                              size={'small'}
                              disabled={isEquipmentEditable(item) ? false : true}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onBlur={handleSubmit(onBlur, onBlurError)}
                                  placeholder={t(
                                    'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_17A450',
                                    {
                                      defaultValue: "Choisir un type d'énergie",
                                    }
                                  )}
                                  disabled={isEquipmentEditable(item) ? false : true}
                                  variant="outlined"
                                  className={`researchBar ${
                                    isEquipmentEditable(item) ? '' : 'disabled'
                                  }`}
                                />
                              )}
                            />
                          )}
                        />
                        <div>
                          {errors?.energy?.energiesArray && (
                            <div className="container__main--errorMessage">
                              {
                                errors?.energy?.energiesArray?.[index]
                                  ?.MasterDataEquipment?.message
                              }
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Carac */}
                      <div className="calculatorFlexColumnStart">
                        <div className="calculatorFlexColumnStart energyCaracs">
                          {/* TODO a reactiver */}
                          {item.caracs?.caracs?.length > 0 && (
                            <Controller
                              control={control}
                              {...register(`energy.energiesArray.${index}.caracs`)}
                              render={({ field }) => (
                                <Popover
                                  {...field}
                                  // defaultVisible={
                                  //   !fields[index].caracs?.caracs?.[0]?.value
                                  // }
                                  forceRender={
                                    !fields[index].caracs?.caracs?.[0]?.value
                                  }
                                  placement="top"
                                  title={t(
                                    'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_A41941',
                                    { defaultValue: 'Caractéristiques' }
                                  )}
                                  key={`popover-${item.caracs?.caracs}`}
                                  content={renderCaracPopOver(index)}
                                  trigger="click"
                                >
                                  <img
                                    src={caracError}
                                    style={{
                                      cursor: 'pointer',
                                      filter: errors?.energy?.energiesArray?.[index]
                                        ?.caracs
                                        ? undefined
                                        : 'grayscale(100%)',
                                    }}
                                  />
                                </Popover>
                              )}
                            />
                          )}
                        </div>
                        {/* {
                      
                      errors?.energy?.energiesArray?.[index]?.caracs && 
                        <div className="container__main--errorMessageCenter">
                          Obligatoires
                        </div>
                  } */}
                      </div>

                      {/* Input energyQuantity */}
                      <div className="calculatorFlexColumnStart energyQuantity">
                        <Controller
                          control={control}
                          rules={{
                            required: energiesArray[index].MasterDataEquipment
                              ? t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_48C191',
                                  {
                                    defaultValue: 'Champ obligatoire',
                                  }
                                )
                              : false,
                            validate: {
                              // rules={{ validate: {
                              isNumber: (v) => {
                                if (!isNaN(Number(v?.replace(/,/, '.')))) {
                                  return true;
                                } else {
                                  return false;
                                }
                              },
                            },
                          }}
                          {...register(`energy.energiesArray.${index}.quantity`)}
                          render={({ field }) => (
                            <Input
                              {...field}
                              size="large"
                              placeholder={t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_0C8FA5',
                                { defaultValue: 'Saisir une quantité' }
                              )}
                              // style={{width: '290px'}}
                              status={
                                errors?.energy?.energiesArray?.[index]?.quantity
                                  ? 'error'
                                  : ''
                              }
                              disabled={
                                watch('energy.energiesArray')[index]
                                  .MasterDataEquipment
                                  ? false
                                  : true
                              }
                              onBlur={handleSubmit(onBlur, onBlurError)}
                            />
                          )}
                        />
                        {errors?.energy?.energiesArray && (
                          <div className="container__main--errorMessage">
                            {errors?.energy?.energiesArray?.[index]?.quantity
                              ?.type === 'isNumber'
                              ? t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_5D5958',
                                  {
                                    defaultValue: 'Vous devez entrer un nombre',
                                  }
                                )
                              : errors?.energy?.energiesArray?.[index]?.quantity
                                  ?.message}
                          </div>
                        )}
                      </div>

                      {/* source indicator */}
                      <div className="sourceIndicator">
                        {zoneSourceToDisplay(item.origin)}
                      </div>

                      {/* Delete energy Type */}
                      <div className="calculatorFlexColumnStart energyDelete">
                        <div
                          onClick={() => {
                            // console.log(watch('energy.energiesArray').length);

                            if (watch('energy.energiesArray').length > 1) {
                              // remove(index)
                              // est bugué lors de la suppression d'éléments:
                              // lorsqu'on supprime quelconque élément qui apparait au dessus d'un élément possédant des caracs
                              // cela crash la page
                              const displayFields = [...fields];
                              displayFields.splice(index, 1);
                              remove();
                              replace(displayFields);
                              dispatch(
                                calculatorUpdate('resultOfSearchCarac', null)
                              );
                            } else {
                              // resetField('energy.energiesArray');
                              replace({
                                MasterDataEquipment: null,
                                quantity: '',
                                caracs: undefined,
                                origin: {
                                  type: zoneSelected?.type || 'project',
                                  sourceId: zoneSelected?.id || null,
                                  uniqueId: uuidv4(),
                                },
                              });
                              remove(index);
                              insert(0, {
                                MasterDataEquipment: null,
                                quantity: '',
                                caracs: undefined,
                                origin: {
                                  type: zoneSelected?.type || 'project',
                                  sourceId: zoneSelected?.id || null,
                                  uniqueId: uuidv4(),
                                },
                              });
                            }
                          }}
                        >
                          <HighlightOffIcon
                            style={{
                              width: '40px',
                              height: '40px',
                              margin: isSmallScreenMode ? '0 1rem 0 0' : '0 3rem',
                            }}
                            className="calculator__delElement"
                          />
                        </div>
                      </div>
                    </div>
                  );
                }

                //  Display not editable energie raw
                if (isDisplayEquipment(item)) {
                  let quantity = getValues(`energy.energiesArray.${index}.quantity`);

                  if (isEquipmentFromParentZone(item)) {
                    const quantityProrata = calculateAreaZoneProrata(item);
                    quantity =
                      Math.round(quantity * quantityProrata * 10000) / 10000;
                  }

                  return (
                    <div className="cardWrapper disabled" key={item.id}>
                      {/* Select energy Type */}
                      <div className="energyResearch">
                        <Controller
                          control={control}
                          {...register(
                            `energy.energiesArray.${index}.MasterDataEquipment`
                          )}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              className="equipmentResearch"
                              key={`autocomplete-${item.id}`}
                              style={{ borderRadius: '30px' }}
                              placeholder={t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_D9BB8B',
                                { defaultValue: 'Choisir un equipement' }
                              )}
                              // value={filteredMd.find((md) => md.id) || null}
                              value={
                                watch(
                                  `energy.energiesArray.${index}.AllotmentNode`
                                ) || null
                              }
                              id="checkboxes-tags-demo"
                              options={filteredAllotmentNodes}
                              getOptionLabel={(option) => option.name || ''}
                              // getOptionSelected={(option) => option.id}
                              noOptionsText={t(
                                'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_BFCCE9',
                                { defaultValue: 'Aucun résultat trouvé' }
                              )}
                              size={'small'}
                              disabled={true}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={t(
                                    'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_17A450',
                                    {
                                      defaultValue: "Choisir un type d'énergie",
                                    }
                                  )}
                                  disabled={true}
                                  variant="outlined"
                                  className={`researchBar disabled`}
                                />
                              )}
                            />
                          )}
                        />
                      </div>

                      {/* Carac */}
                      <div className="calculatorFlexColumnStart">
                        <div className="calculatorFlexColumnStart energyCaracs newImg">
                          {/* TODO a reactiver */}
                          {item.caracs?.caracs?.length > 0 && (
                            <Controller
                              control={control}
                              {...register(`energy.energiesArray.${index}.caracs`)}
                              render={({ field }) => (
                                <Popover
                                  {...field}
                                  defaultVisible={
                                    !fields[index].caracs?.caracs?.[0]?.value
                                  }
                                  placement="top"
                                  title={t(
                                    'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_A41941',
                                    { defaultValue: 'Caractéristiques' }
                                  )}
                                  key={`popover-${item}`}
                                  content={renderCaracPopOver(index, true)}
                                  trigger="click"
                                >
                                  <img
                                    src={carac}
                                    style={{ filter: 'grayscale(100%)' }}
                                  />
                                </Popover>
                              )}
                            />
                          )}
                        </div>
                      </div>

                      {/* Input energyQuantity */}
                      <div className="calculatorFlexColumnStart energyQuantity">
                        <Input
                          // {...field}
                          value={quantity}
                          size="large"
                          placeholder={t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_0C8FA5',
                            { defaultValue: 'Saisir une quantité' }
                          )}
                          // style={{width: '290px'}}
                          status={
                            errors?.energy?.energiesArray?.[index]?.quantity
                              ? 'error'
                              : ''
                          }
                          disabled={true}
                        />
                      </div>

                      {/* source indicator */}
                      <div className="sourceIndicator disabled">
                        {zoneSourceToDisplay(item.origin)}
                      </div>

                      {/* Delete energy Type */}
                      {/* <div className="calculatorFlexColumnStart energyDelete" >
                    <div 
                     
                    >
                      <HighlightOffIcon
                        style={{ width: '40px', height: '40px', margin: '0 3rem'}}
                        className="calculator__delElement" 
                      />
                    </div> 
                  </div> */}
                    </div>
                  );
                }

                return null;
              })}
            </div>
            {/* End Array of selected type of energy with quantity  */}

            {/* Add energy row */}
            <div
              className="calculator__addElement"
              // style={{maxWidth: '180px'}}
              onClick={() =>
                append({
                  MasterDataEquipment: null,
                  quantity: '',
                  caracs: null,
                  origin: {
                    type: zoneSelected?.type || 'project',
                    sourceId: zoneSelected?.id || null,
                    uniqueId: uuidv4(),
                  },
                })
              }
            >
              <ControlPointIcon style={{ paddingRight: '5px' }} />
              Ajouter un type d&apos;énergie
            </div>

            {/* Add prev and next button*/}
            <div
              className="calculatorFlexColumnStart"
              style={{ flexGrow: '1', justifyContent: 'flex-end' }}
            >
              <div
                className="calculatorFlexStart"
                style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
              >
                <div
                  className="calculatorFlexColumnStart"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Button
                      style={{
                        fontSize:
                          window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                      }}
                      className="close-button"
                      onClick={handleSubmit(onSubmitPrevious, onError)}
                      disabled={getPreviousScreenActiveId() === null}
                      text={t(
                        'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_8AEC06',
                        {
                          defaultValue: 'Précédent',
                        }
                      )}
                    />
                    <Button
                      style={{
                        fontSize:
                          window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                        marginLeft: '5px',
                      }}
                      className="purple-button"
                      onClick={handleSubmit(onSubmitNext, onError)}
                      disabled={getNextScreenActiveId() === null}
                      text={t(
                        'APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_C988BE',
                        {
                          defaultValue: 'Suivant',
                        }
                      )}
                    />
                  </div>
                </div>

                {/* Validation Button */}
                {/* <div          
              className='calculator__nextButton'
              onClick={handleSubmit(onSubmit, onError)}
            >
              <p>Suivant</p>
              <DoubleRightOutlined className=' calculator__nextButton--icon'/>
            </div> */}
              </div>
            </div>
          </form>
        ) : (
          // calculator detailled Simplified Energy screen
          <>
            <SimplifiedEnergy
              changeValue={changeValueEquipments}
              calculator={true}
              isEquipmentEditable={isEquipmentEditable}
              isEquipmentFromParentZone={isEquipmentFromParentZone}
              calculateAreaZoneProrata={calculateAreaZoneProrata}
              isDisplayEquipment={isDisplayEquipment}
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <Button
                style={{
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                }}
                className="close-button"
                onClick={handleSubmit(onSubmitPrevious, onError)}
                disabled={getPreviousScreenActiveId() === null}
                text={t('APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_8AEC06', {
                  defaultValue: 'Précédent',
                })}
              />
              <Button
                style={{
                  fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                  marginLeft: '5px',
                }}
                className="purple-button"
                onClick={handleSubmit(onSubmitNext, onError)}
                disabled={getNextScreenActiveId() === null}
                text={t('APP.CALCULATORWORKSITECONTENT.WORKSITEENERGY.PH_C988BE', {
                  defaultValue: 'Suivant',
                })}
              />
            </div>
          </>
        )
      }
    </>
  );
};

export default WorksiteEnergy;
