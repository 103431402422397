import {
  GRAPH_TO_FULL_SCREEN,
  TOGGLE_CHECK_WIDGET,
  UPDATE_CHECK_VALUE,
} from '../actions/report';
import { LOAD_PROJECT_BY_ID, SET_CURRENT_PROJECT } from '../actions/projects';

const initialState = {
  synthese: true,
  A4: true,
  format169: false,
  format43: false,
  variant: false,
  pepfdes: false,
  rse: false,
  fullScreen: false,
  indicators: {},
  selectedFilterLot: [{ id: 1200, label: 'Tous lots', color: '#ccc', name: 'all' }],
  widgets: [],
  widgetsOpen: false,
  isDarkMode: false,
  widgetsInDropZone: [],
  generatePdfStarter: false,
  widgetTypePreview: null,
};

function importReducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_CHECK_VALUE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case TOGGLE_CHECK_WIDGET: {
      // console.log(action.widget);
      // console.log(state);

      const widgets = state.widgets.map((widget) => {
        if (widget.name === action.widget.name) {
          widget.checked = !widget.checked;

          return widget;
        } else {
          return widget;
        }
      });

      console.log('maj localstorage et state in reducer', widgets);

      localStorage.setItem(
        `rgl-${action.currentProjectId}`,
        JSON.stringify({
          layout: widgets,
        })
      );

      return {
        ...state,
        widgets: widgets,
      };
    }
    case GRAPH_TO_FULL_SCREEN:
      return {
        ...state,
        fullScreen: !state.fullScreen,
      };

    case LOAD_PROJECT_BY_ID:
      return {
        ...state,
        indicators: {},
      };
    case SET_CURRENT_PROJECT:
      return {
        ...state,
        indicators: {},
      };
    default:
      return state;
  }
}

export default importReducer;
