import { Serie } from '@nivo/line';
import {
  getLCAbyNode,
  getPeriodicLCAbyNode,
} from './LifeCycleTemporalGraphDataCompute';
import { AllotmentNodeDTO } from '../../../models/ProjectDTO';
import {
  CurrentIndicatorDTO,
  CurrentAllotmentDTO,
} from '../../../models/ProjectsDTO';
import { ALLOTMENT_IDS } from '../../../globalVariable/typeProject.js';
import { LOTS_IDS, ZONE_TYPES } from '../../../globalVariable/generalDefinitions';
import { getAllotmentNodeLotId } from '../../../shared/utilsFunction';

type TemporalGraphInputData = {
  color: string;
  label: string;
  children: TemporalGraphInputChildrenData[];
};

type TemporalGraphInputChildrenData = {
  id: number;
  label: string;
  color: string;
  ParentId: number;
  step: number;
  children: TemporalGraphInputChildrenData[];
  uniqueId: number[];
};

type TemporalGraphDataCollectionItem = {
  id: string;
  serie: Serie | null;
  lotId?: number;
};

type Re2020TemporalGraphDataCollection = {
  plot: TemporalGraphDataCollectionItem;
  buildingSite: TemporalGraphDataCollectionItem;
  water: TemporalGraphDataCollectionItem;
  soil: TemporalGraphDataCollectionItem;
  energy: TemporalGraphDataCollectionItem;
  simpleEnergy: TemporalGraphDataCollectionItem;
};

const RE2020_TEMPORAL_GRAPH_DATA_COLLECTION: Re2020TemporalGraphDataCollection = {
  plot: {
    id: 'Parcelle',
    serie: null,
  },
  buildingSite: {
    id: 'Chantier',
    serie: null,
  },
  water: {
    id: 'Eau',
    serie: null,
    lotId: LOTS_IDS.EAU,
  },
  soil: {
    id: 'Terre',
    serie: null,
    lotId: LOTS_IDS.TERRE,
  },
  energy: {
    id: 'Energie',
    serie: null,
    lotId: LOTS_IDS.ENERGIE,
  },
  simpleEnergy: {
    id: 'Energie simplifiée',
    serie: null,
    lotId: LOTS_IDS.ENERGIE_SIMPLIFIEE,
  },
};

function getDataFamilies(
  initialDatas: TemporalGraphInputData,
  childIdsMap: Map<number, number[]>,
  isStacked: boolean
) {
  let datas: Serie[] = [];

  const nodeFamilies = initialDatas.children.map((gd) =>
    getFamilyFromParentNode(gd.id, gd.ParentId, childIdsMap, gd.label, gd.color)
  );

  if (isStacked) {
    datas = datas?.concat(
      nodeFamilies.map((family) => {
        return {
          ids: family.ids,
          id: family.label,
          color: family.color,
          data: [],
        };
      })
    );
  } else {
    datas = [
      {
        ids: nodeFamilies.reduce((p, c) => p.concat(c.ids), [] as number[]),
        id: 'Impact',
        color: '#000000',
        data: [],
      },
    ];
  }

  return datas;
}

function lotFilter(
  equipment: any,
  allotmentNodesMap: Map<number, AllotmentNodeDTO>,
  lotId: number
) {
  const allotmentNodeId = equipment.ProjectSheet.AllotmentNodes.find(
    (an: AllotmentNodeDTO) => an.AllotmentId === ALLOTMENT_IDS.NOOCO
  )?.id;

  return getAllotmentNodeLotId(allotmentNodesMap, allotmentNodeId) === lotId;
}

function plotFilter(equipment: any, plotsIds: number[]) {
  return plotsIds.includes(equipment.ProjectSheet.ZoneId);
}

function buildingSiteFilter(
  equipment: any,
  buildingSiteData: Serie,
  parsedZones: any[]
) {
  const zone = parsedZones.find((pz) => pz.id === equipment.ProjectSheet.ZoneId);

  return zone.name === buildingSiteData?.id;
}

function filterEquipments(
  equipments: any[],
  re2020Data: [string, TemporalGraphDataCollectionItem],
  parsedZones: any[],
  allotmentNodesMap: Map<number, AllotmentNodeDTO>
): { filteredEquipments: any[]; leftOverEquipments: any[] } {
  let filteredEquipments: any[] = [];
  let leftOverEquipments: any[] = [];
  let filterFunction: Function = () => {};

  switch (re2020Data[1].id) {
    case RE2020_TEMPORAL_GRAPH_DATA_COLLECTION.plot.id:
      const plotsIds = parsedZones
        .filter((parsedZone) => parsedZone.type === ZONE_TYPES.plot)
        .map((pz) => pz.id);
      const plotBuildingSite = parsedZones.find(
        (zone) =>
          plotsIds &&
          zone.ParentId === plotsIds[0] &&
          zone.type === ZONE_TYPES.buildingSite
      );

      if (plotBuildingSite !== undefined) plotsIds.push(plotBuildingSite.id);
      filterFunction = (equip: any) => plotFilter(equip, plotsIds);
      break;
    case RE2020_TEMPORAL_GRAPH_DATA_COLLECTION.buildingSite.id:
      filterFunction = (equip: any) =>
        buildingSiteFilter(equip, re2020Data[1].serie as Serie, parsedZones);
      break;
    default:
      if (re2020Data[1].lotId !== undefined) {
        filterFunction = (equip: any) =>
          lotFilter(equip, allotmentNodesMap, re2020Data[1].lotId as number);
      }
  }

  equipments.forEach((equip) => {
    if (filterFunction(equip)) {
      filteredEquipments.push(equip);
    } else {
      leftOverEquipments.push(equip);
    }
  });

  return { filteredEquipments, leftOverEquipments };
}

function applyFiltersForRe2020Data(
  allTempGraphDatas: Serie[],
  equipments: any[],
  parsedZones: any[],
  currentIndicator: CurrentIndicatorDTO,
  projectDdv: number,
  projectArea: number,
  refUnit: string,
  allotmentNodesMap: Map<number, AllotmentNodeDTO>,
  buildingSiteZonesIds: number[]
) {
  const re2020Data = Object.entries(RE2020_TEMPORAL_GRAPH_DATA_COLLECTION);
  let processedDataSets: string[] = [];

  for (let tempGraphData of allTempGraphDatas) {
    let matchingRe2020Datas: any[] = [];

    // Look for all 'Chantiers' of the project
    if (
      tempGraphData.id === RE2020_TEMPORAL_GRAPH_DATA_COLLECTION.buildingSite.id &&
      typeof tempGraphData.id === 'string'
    ) {
      matchingRe2020Datas = re2020Data.filter((re2020Id) =>
        (tempGraphData.id as string).includes(re2020Id[1].id)
      );
    } else {
      matchingRe2020Datas = re2020Data.filter(
        (re2020Id) => tempGraphData.id === re2020Id[1].id
      );
    }

    if (matchingRe2020Datas.length !== 0) {
      for (let matchingRe2020Data of matchingRe2020Datas) {
        matchingRe2020Data[1].tempGraphData = tempGraphData;

        const { filteredEquipments, leftOverEquipments } = filterEquipments(
          equipments,
          matchingRe2020Data,
          parsedZones,
          allotmentNodesMap
        );

        matchingRe2020Data[1].tempGraphData.data = getLCAbyNode(
          filteredEquipments,
          currentIndicator.id,
          projectDdv,
          buildingSiteZonesIds,
          projectArea,
          refUnit,
          allotmentNodesMap
        );

        equipments = leftOverEquipments;
        processedDataSets.push(matchingRe2020Data[1].id);
      }
    }
  }

  return { newEquipments: equipments, processedDataSets };
}

export function createPeriodicDataSet(
  initialDatas: TemporalGraphInputData,
  projectArea: number,
  currentIndicator: CurrentIndicatorDTO,
  currentAllotment: CurrentAllotmentDTO,
  equipments: any[],
  refUnit: string,
  childIdMap: Map<number, number[]>,
  isStacked: boolean,
  projectDates: Date[]
) {
  let temporalGraphDatas = getDataFamilies(initialDatas, childIdMap, isStacked);

  for (let tempGraphData of temporalGraphDatas) {
    tempGraphData.data = getPeriodicLCAbyNode(
      equipments.filter((e) =>
        tempGraphData.ids.includes(
          e.ProjectSheet.AllotmentNodes.find(
            (an: AllotmentNodeDTO) => an.AllotmentId === currentAllotment.id
          )?.id
        )
      ),
      currentIndicator.id,
      projectArea,
      refUnit,
      projectDates
    );
  }

  return getDataSet(temporalGraphDatas, projectDates.length - 1);
}

export function createDataSet(
  initialDatas: TemporalGraphInputData,
  parsedZones: any[],
  projectDdv: number,
  projectArea: number,
  currentIndicator: CurrentIndicatorDTO,
  currentAllotment: CurrentAllotmentDTO,
  equipments: any[],
  refUnit: string,
  allotmentNodesMap: Map<number, AllotmentNodeDTO>,
  childIdMap: Map<number, number[]>,
  isStacked: boolean
) {
  const buildingSiteZonesIds = parsedZones
    .filter((pz) => pz.type === ZONE_TYPES.buildingSite)
    .map((pz) => pz.id);

  let temporalGraphDatas = getDataFamilies(initialDatas, childIdMap, isStacked);
  let processedDataSets: any[] = [];

  if (currentAllotment.id === ALLOTMENT_IDS.RE2020) {
    const { newEquipments, processedDataSets: newProcessedDataSets } =
      applyFiltersForRe2020Data(
        temporalGraphDatas,
        equipments,
        parsedZones,
        currentIndicator,
        projectDdv,
        projectArea,
        refUnit,
        allotmentNodesMap,
        buildingSiteZonesIds
      );
    processedDataSets = processedDataSets.concat(newProcessedDataSets);
    equipments = newEquipments;
  }

  for (let tempGraphData of temporalGraphDatas) {
    if (!processedDataSets.includes(tempGraphData?.id)) {
      tempGraphData.data = getLCAbyNode(
        equipments.filter((e) =>
          tempGraphData.ids.includes(
            e.ProjectSheet.AllotmentNodes.find(
              (an: AllotmentNodeDTO) => an.AllotmentId === currentAllotment.id
            )?.id
          )
        ),
        currentIndicator.id,
        projectDdv,
        buildingSiteZonesIds,
        projectArea,
        refUnit,
        allotmentNodesMap
      );
    }
  }

  return getDataSet(temporalGraphDatas, projectDdv);
}

function getDataSet(temporalGraphDatas: Serie[], projectDdv: number) {
  const newData = [...temporalGraphDatas];

  newData.sort(
    (a, b) =>
      Math.abs(a.data[projectDdv].y as number) -
      Math.abs(b.data[projectDdv].y as number)
  );

  let min = 0;

  for (let i = 0; i < newData[0].data.length && min >= 0; i++) {
    let tot = 0;

    for (let d of newData) tot += d.data[i].y as number;

    min = tot;
  }

  return { datas: newData, min: min };
}

function getFamilyFromParentNode(
  allotmentNodeId: number,
  parentId: number,
  childIdsMap: Map<number, number[]>,
  label: string,
  color: string
) {
  let stack = [allotmentNodeId];
  let familyIds: number[] = [allotmentNodeId];
  if (parentId !== null) {
    familyIds.push(parentId);
  }

  while (stack.length > 0) {
    let currentNodeId = stack.pop();
    if (currentNodeId === undefined) break;

    familyIds.push(currentNodeId);

    let childsIds = childIdsMap.get(currentNodeId);
    if (childsIds !== undefined) {
      stack = stack.concat(childsIds);
    }
  }

  return { label: label, color: color, ids: familyIds };
}
