import './architectureForm.scss';
import { StateDTO } from '../../../../models/GeneralDTO';
import Button from '@material-ui/core/Button';
import BackupIcon from '@material-ui/icons/Backup';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector, useDispatch } from 'react-redux';
import ImpactForm from './ImpactForm';
import React, { useState, useEffect } from 'react';
import ZonesForm from './ZonesForm';
import {
  updateFormValue,
  fetchFluidsCompatibleSheets,
} from '../../../../../actions/projects';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
  findLotFromAllotmentNode,
  returnProjectTypeFromProjectTypeTitle,
} from '../../../../shared/utils';
import convert from 'xml-js';
import { basicConfigAmplify } from '../../../../..';

import _ from 'lodash';
import config from '../../../../../config';
import i18nInstance from '../../../../../i18n';
import { useTranslation } from 'react-i18next';
import { uploadData } from 'aws-amplify/storage';
import { Amplify } from 'aws-amplify';
import {
  CREATION_STEPS,
  CREATION_STEPS_TRAD_KEYS,
} from '../../../../globalVariable/creationProjectVariable';
interface ArchitectureFormProps {
  errors: any;
  control: any;
  setValue: Function;
  uuid: string;
  setMessageSnack: Function;
}

const reader = new FileReader();

const checkIfFcIsValid = (fc, t): Promise<[boolean, string]> => {
  let errorMessage = '';

  return new Promise((resolve, reject) => {
    reader.readAsText(fc);
    reader.onloadend = (event) => {
      if (event && event.target) {
        const readerData = event.target.result?.toString();

        if (readerData) {
          try {
            let fcJson = JSON.parse(
              convert.xml2json(readerData, { compact: true, spaces: 4 })
            );

            const indicatorsIds = fcJson.EPDC.Indicators.Indicator.map(
              (indicator) => indicator._attributes.IndicatorCode
            );
            const totalNumberOfIds = indicatorsIds.length;
            const totalNumberOfUniqueIds = Array.from(new Set(indicatorsIds)).length;

            if (totalNumberOfIds !== totalNumberOfUniqueIds) {
              errorMessage = t('APP.ARCHITECTUREFORM.INDEX.PH_788A5E', {
                defaultValue:
                  "La fiche configurée ne peut être importée, certains indicateurs sont présents en double. Veuillez vous rapprocher de l'auteur de cette fiche configurée",
              });
            }

            if (errorMessage === '') {
              resolve([true, '']);
            } else {
              resolve([false, errorMessage]);
            }
          } catch (error) {
            errorMessage = t('APP.ARCHITECTUREFORM.INDEX.PH_F9CA6E', {
              defaultValue:
                'La fiche configurée ne peut être importée, veuillez vous rapprocher du service support.',
            });
            resolve([false, errorMessage]);
          }
        }
      }
    };

    reader.onerror = (error) => {
      errorMessage = t('APP.ARCHITECTUREFORM.INDEX.PH_364485', {
        defaultValue:
          'La fiche configurée ne peut être importée, veuillez vous rapprocher du service support.',
      });
      resolve([false, errorMessage]);
    };
  });
};

const getAllowedAllotmentNodesForFCSheets = (
  allGroups: any,
  projectTypeId: number
) => {
  const projectType = allGroups.projectTypes.find(
    (projectType: any) => projectType.id === projectTypeId
  );

  if (!projectType) {
    return [];
  }

  const compatibleLotsIds = projectType.AllotmentNodes.map(
    (allotment: any) => allotment.id
  );
  const allowedMdIds = allGroups.type
    .filter((md: any) => md.isConfiguredSheetImportAllowed)
    .map((md: any) => md.id);
  let allowedAllotmentNodes = allGroups.flatAllotments.filter(
    (allotment: any) =>
      allowedMdIds.includes(allotment.MasterDataEquipmentId) &&
      allotment.isObsolete === false
  );

  allowedAllotmentNodes = allowedAllotmentNodes.filter((allotment: any) => {
    const lot = findLotFromAllotmentNode(allGroups.flatAllotments, allotment);

    return compatibleLotsIds.includes(lot.id);
  });

  allowedAllotmentNodes.forEach((allotment: any) => {
    const lot = findLotFromAllotmentNode(allGroups.flatAllotments, allotment);
    const optionName = lot.name + ' > ' + allotment.name;
    allotment.optionName = optionName;
  });

  return allowedAllotmentNodes;
};

const ArchitectureForm = ({
  errors,
  control,
  setValue,
  uuid,
  setMessageSnack,
}: ArchitectureFormProps) => {
  let dispatch = useDispatch();
  const [fluidsAndFcFilteredByZone, setFluidsAndFcFilteredByZone] =
    useState<any>(null);
  const {
    rsetmodalOpen,
    fcSheetsFiles,
    fluidsArray,
    equipsBulkArray,
    allGroups,
    linkedFluids,
    hasFcSheets,
    fluidsCompatibleSheets,
    idFicheFcFilenames,
    creationProject,
  } = useSelector((state: StateDTO) => state.projects);
  const { creationSteps } = useSelector(
    (state: StateDTO) => state.projects.creationProject
  );
  const allowedAllotmentNodes = getAllowedAllotmentNodesForFCSheets(
    allGroups,
    creationProject.selectedTypeProjectId
  );

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(updateFormValue('hasFcSheets', null));
      dispatch(updateFormValue('equipsBulkArray', []));
      dispatch(updateFormValue('plotEquips', null));
      dispatch(updateFormValue('fluidsArray', []));
      dispatch(updateFormValue('fluidsCompatibleSheets', []));
      dispatch(updateFormValue('fcSheetsFiles', {}));
      dispatch(updateFormValue('linkedFluids', null));
      dispatch(updateFormValue('rsetFile', null));
      dispatch(updateFormValue('rsetmodalOpen', false));
      dispatch(updateFormValue('isRsenv', null));
      dispatch(
        updateFormValue('buildingSections', [
          {
            flag: 0,
            name: 'Bâtiment 1',
            type: 'building',
            Zones: [
              {
                name: 'Zone 1',
                BuildingTypeId: 1,
                menInput: true,
                logementInput: true,
                comblesInput: false,
                area: null,
                type: 'zone',
                category: 'CE1',
              },
            ],
          },
        ])
      );
    };
  }, []);

  const fluidsAndEquipsDataPreprocessing = (
    fluidsArray,
    equipmentsArray,
    fluidsCompatibleSheets
  ) => {
    if (fluidsArray.length > 0 && fluidsCompatibleSheets.length === 0) {
      const idIniesArray = Array.from(
        new Set(equipmentsArray.map((fluid) => Number(fluid.id_fiche._text)))
      );
      dispatch(fetchFluidsCompatibleSheets(idIniesArray));
    }

    const localFcOrFluids: any = {};
    const fluidsAndFcArray = fluidsArray.concat(equipmentsArray);

    for (const fluidOrFc of fluidsAndFcArray) {
      if (!localFcOrFluids[fluidOrFc.zoneId]) {
        localFcOrFluids[fluidOrFc.zoneId] = {
          zoneId: fluidOrFc.zoneId,
          fluids: [],
          fcSheets: [],
          zoneName: `${fluidOrFc.buildingName} - ${fluidOrFc.zoneName}`,
        };
      }

      if (fluidOrFc.donnees_configurateur) {
        localFcOrFluids[fluidOrFc.zoneId].fcSheets.push(fluidOrFc);
      } else if (fluidsArray.indexOf(fluidOrFc) != -1) {
        localFcOrFluids[fluidOrFc.zoneId].fluids.push(fluidOrFc);
      }
    }

    const idIniesFluidsCompatibles = fluidsCompatibleSheets.map((sheet) =>
      String(sheet.id_inies)
    );

    return [localFcOrFluids, idIniesFluidsCompatibles];
  };

  const checkDisplayImpactForm = () => {
    // TODO_TRAD : fr
    if (
      creationSteps.includes(t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.RE2020])) ||
      creationSteps.includes(t(CREATION_STEPS_TRAD_KEYS[CREATION_STEPS.EPCM]))
    ) {
      return true;
    }

    return false;
  };

  const onChange = async (
    file: File | null,
    _sheetName: string,
    key: any,
    className: string,
    fluidsAndFcFilteredByZone: any = null
  ) => {
    let newMap: any = _.cloneDeep(fcSheetsFiles);

    const input = document.querySelector(`.${className}`);

    if (file) {
      let isValidFc, errorMessage;
      try {
        [isValidFc, errorMessage] = await checkIfFcIsValid(file, t);

        if (isValidFc) {
          newMap[key] = file;
          newMap = updateFcSheetsFiles(fluidsAndFcFilteredByZone, newMap);

          try {
            Amplify.configure({
              ...basicConfigAmplify,
              Storage: {
                S3: {
                  bucket: config.config.BUCKET,
                  region: config.cognito.REGION,
                },
              },
            });

            const response = await uploadData({
              key: `temp/${uuid}/${_sheetName}.xml`,
              data: file,
            });

            dispatch(
              updateFormValue('idFicheFcFilenames', {
                ...idFicheFcFilenames,
                [_sheetName]: file.name,
              })
            );

            setMessageSnack(['Fiche Sauvegardee', 'success']);
          } catch (e: any) {
            setMessageSnack([e.message, 'error']);
          }
        } else {
          setMessageSnack([errorMessage, 'error']);
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      input.value = '';
      delete newMap[key];
      const newIdFicheFcFilenames = { ...idFicheFcFilenames };
      delete newIdFicheFcFilenames[_sheetName];
      dispatch(updateFormValue('idFicheFcFilenames', newIdFicheFcFilenames));
    }

    dispatch(updateFormValue('fcSheetsFiles', newMap));
  };

  const updateFcSheetsFiles = (fluidsAndFcFilteredByZone, fcSheetsFiles) => {
    let fcSheets: any = Object.values(fluidsAndFcFilteredByZone)
      .map((fluidOrFc: any) => fluidOrFc.fcSheets)
      .flat();
    const filledFcSheets = fcSheets.filter(
      (fcSheet) => fcSheetsFiles[fcSheet.id + '-' + fcSheet.zoneId]
    );

    for (let filledFcSheet of filledFcSheets) {
      let fcIdProduitTag = filledFcSheet.id_fiche_configurateur
        ? filledFcSheet.id_fiche_configurateur._text
        : filledFcSheet.id_fiche._text;
      let fcSheetsToUpdate = fcSheets.filter(
        (fcSheet) =>
          (fcSheet.id_fiche_configurateur
            ? fcSheet.id_fiche_configurateur._text
            : fcSheet.id_fiche._text) === fcIdProduitTag
      );

      for (let fcSheet of fcSheetsToUpdate) {
        if (!fcSheetsFiles[fcSheet.id + '-' + fcSheet.zoneId]) {
          fcSheetsFiles[fcSheet.id + '-' + fcSheet.zoneId] =
            fcSheetsFiles[filledFcSheet.id + '-' + filledFcSheet.zoneId];
        }
      }
    }

    return fcSheetsFiles;
  };

  const updateFcSheetsMd = (fluidsAndFc) => {
    let fcSheets: any = Object.values(fluidsAndFc)
      .map((fluidOrFc: any) => fluidOrFc.fcSheets)
      .flat();
    const filledFcSheets = fcSheets.filter((fcSheet) => fcSheet.AllotmentNodeId);

    for (let filledFcSheet of filledFcSheets) {
      let fcIdProduitTag = filledFcSheet.id_fiche_configurateur
        ? filledFcSheet.id_fiche_configurateur._text
        : filledFcSheet.id_fiche._text;
      let fcSheetsToUpdate = fcSheets.filter(
        (fcSheet) =>
          !fcSheet.AllotmentNodeId &&
          (fcSheet.id_fiche_configurateur
            ? fcSheet.id_fiche_configurateur._text
            : fcSheet.id_fiche._text) === fcIdProduitTag
      );
      let equipToUpdate = equipsBulkArray.find(
        (equip) =>
          equip.id === filledFcSheet.id && equip.zoneId === filledFcSheet.zoneId
      );
      equipToUpdate.AllotmentNodeId = filledFcSheet.AllotmentNodeId;

      for (let fcSheet of fcSheetsToUpdate) {
        if (!fcSheet.AllotmentNodeId) {
          fcSheet.AllotmentNodeId = filledFcSheet.AllotmentNodeId;
          let equipToUpdate = equipsBulkArray.find(
            (equip) => equip.id === fcSheet.id && equip.zoneId === fcSheet.zoneId
          );
          equipToUpdate.AllotmentNodeId = filledFcSheet.AllotmentNodeId;
        }
      }
    }

    dispatch(updateFormValue('equipsBulkArray', equipsBulkArray));
    setFluidsAndFcFilteredByZone(JSON.parse(JSON.stringify(fluidsAndFc)));
  };

  // sorry but i dont have time to refactor this and i dont understand a word of it : don't worry bro! got your back :p
  if (rsetmodalOpen) {
    const resultArray = fluidsAndEquipsDataPreprocessing(
      fluidsArray,
      equipsBulkArray,
      fluidsCompatibleSheets
    );

    if (!fluidsAndFcFilteredByZone) {
      setFluidsAndFcFilteredByZone(resultArray[0]);
    }

    const idIniesFluidsCompatibles = resultArray[1];
    const fluidRegex = new RegExp(/R[0-9]+/g);

    if (fluidsAndFcFilteredByZone) {
      const fluids = Object.entries(fluidsAndFcFilteredByZone).map(([key]) => {
        const currentZone = fluidsAndFcFilteredByZone[key];
        const fcSheetsToAssign = currentZone.fcSheets?.map((fc: any) => {
          let fcIdProduitTag = fc.id_fiche_configurateur
            ? fc.id_fiche_configurateur._text
            : fc.id_fiche._text;

          return (
            <div className="rset-line rset-fc-line" key={fc}>
              <div key={fc} className="name">
                {fc.nom._text}
              </div>

              <div style={{ width: '35%' }}>
                <Autocomplete
                  getOptionLabel={(option: any) => option.optionName}
                  options={allowedAllotmentNodes}
                  value={
                    fc.AllotmentNodeId
                      ? allowedAllotmentNodes.find(
                          (equip: any) => equip.id === fc.AllotmentNodeId
                        )
                      : null
                  }
                  id="combo-box-demo"
                  key={fluidsAndFcFilteredByZone}
                  onChange={(_e: any, equip: any) => {
                    fc.AllotmentNodeId = equip?.id || null;
                    updateFcSheetsMd(fluidsAndFcFilteredByZone);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      className="researchBar input-boarding"
                      placeholder={t('APP.ARCHITECTUREFORM.INDEX.PH_C9BC2F', {
                        defaultValue: 'Equipement/matériau',
                      })}
                      variant="outlined"
                    />
                  )}
                />
              </div>

              <div key={fc.id + '-' + fc.zoneId} className="rset-import-file">
                {!fcSheetsFiles[fc.id + '-' + fc.zoneId] && (
                  <label
                    className="button-upload"
                    htmlFor={'file' + fc.id + '-' + fc.zoneId}
                  >
                    <Button
                      className="buttonRset"
                      startIcon={<BackupIcon />}
                      variant="contained"
                      component="span"
                    >
                      {t('APP.ARCHITECTUREFORM.INDEX.PH_AF1A80', {
                        defaultValue: 'Importer un fichier',
                      })}
                    </Button>
                  </label>
                )}

                <input
                  id={'file' + fc.id + '-' + fc.zoneId}
                  key={'file' + fc.id + '-' + fc.zoneId}
                  className={`upload-input upload-input${fc.id + '-' + fc.zoneId}`}
                  type="file"
                  accept=".xml"
                  onChange={(e) => {
                    onChange(
                      e.target.files[0],
                      fcIdProduitTag,
                      fc.id + '-' + fc.zoneId,
                      `upload-input${fc.id + '-' + fc.zoneId}`,
                      fluidsAndFcFilteredByZone
                    );
                  }}
                />

                {fcSheetsFiles[fc.id + '-' + fc.zoneId] && (
                  <div
                    key={fc.id + '-' + fc.zoneId}
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <p className="xmlName">
                      {fcSheetsFiles[fc.id + '-' + fc.zoneId]?.name}{' '}
                      <DeleteIcon
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() =>
                          onChange(
                            null,
                            fcIdProduitTag,
                            fc.id + '-' + fc.zoneId,
                            `upload-input${fc.id + '-' + fc.zoneId}`
                          )
                        }
                      />
                    </p>
                  </div>
                )}
              </div>
            </div>
          );
        });

        const fluidsToLink = currentZone.fluids?.map((fluid: any) => {
          const fluidName = fluid.nom._text.match(fluidRegex)[0];

          return (
            <div
              className="rset-line rset-fluid-line"
              style={{ justifyContent: 'initial' }}
              key={fluid}
            >
              <div className="name">
                {fluidName}, Qte = {fluid.quantite._text}kg
              </div>

              <div style={{ width: '35%' }}>
                <Autocomplete
                  getOptionLabel={(option) => option.nom._text}
                  options={equipsBulkArray.filter(
                    (equip: any) =>
                      equip.zoneId == fluid.zoneId &&
                      equip.nom &&
                      idIniesFluidsCompatibles.includes(equip.id_fiche._text)
                  )}
                  id="combo-box-demo"
                  fullWidth={true}
                  onChange={(_e, equip) => {
                    const newFluidMap = { ...linkedFluids };

                    newFluidMap[fluid.zoneId + '-' + fluid.id] = {
                      equip,
                      fluid,
                    };

                    dispatch(updateFormValue('linkedFluids', newFluidMap));
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="researchBar input-boarding"
                      {...params}
                      placeholder={t('APP.ARCHITECTUREFORM.INDEX.PH_099449', {
                        defaultValue: 'Equipement contenant du fluide',
                      })}
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
          );
        });

        return (
          <div
            className="rset-line-by-zone"
            key={currentZone.zoneId}
            style={{ display: 'flex', flexDirection: 'column', flex: '1' }}
          >
            <div className="zone-name">{currentZone.zoneName}</div>
            <div style={{ marginBottom: '1em' }}>{fluidsToLink}</div>
            {fcSheetsToAssign}
          </div>
        );
      });

      return (
        <div
          className="rsetModalForm"
          style={{
            display: rsetmodalOpen ? '' : 'none',
          }}
        >
          <div className="title-part">
            <h3 className="subtitle">
              {t('APP.ARCHITECTUREFORM.INDEX.PH_3C7A28', {
                defaultValue:
                  'RSEE - Chargement des fluides frigorigènes et/ou Fiches Configurées',
              })}
            </h3>
          </div>
          {fluids}
        </div>
      );
    }
  }
  return (
    <>
      <div className="projectform-right">
        <div className="title-part">
          <h3>
            {t('APP.ARCHITECTUREFORM.INDEX.PH_BD92FD', {
              defaultValue: 'Architecture du projet',
            })}
          </h3>
        </div>

        {checkDisplayImpactForm() && (
          <ImpactForm errors={errors} control={control} setValue={setValue} />
        )}

        <ZonesForm errors={errors} control={control} setValue={setValue} />
      </div>
    </>
  );
};

export default ArchitectureForm;
