import React from 'react';

import { Checkbox, Row, Col, Popover } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useTranslation } from 'react-i18next';
import { SearchGroup } from '@photocarbone/nooco-ui';
import { useDispatch, useSelector } from 'react-redux';

import API from '../../../../../services/API';
import { BundleLineDTO } from '../../../../../models/BundleDTO';
import caracs from '../../../../../../assets/images/import/specification.png';
import { findParentNode, handleAllotmentNodes } from '../../../../../shared/utils';
import MaterialTable from '../../../../../projects/EquipmentList/AddEquipment/ModelingEquipment/CarbonEstimation/MaterialTable/MaterialTable';
import {
  ManufacturerIndexChoice,
  ManufacturerIndexTitle,
  MaterialSynthese,
  MaterialTotalWeight,
} from '../../../../../projects/EquipmentList/AddEquipment/ModelingEquipment/CarbonEstimation/CarbonEstimation';
import { StateDTO } from '../../../../../models/GeneralDTO';
import { updateFormValue } from '../../../../../../actions/projects';
import { ALLOTMENT_IDS } from '../../../../../globalVariable/typeProject';

export type RenderCarbonEstimationProps = {
  bundleLines: BundleLineDTO[];
  handleChangeCarbonEstimation: Function;
  searchBarOptionsFiltered: Object;
  handleOnChangeDropdown: Function;
  returnDefaultValueTypeEquipment: Function;
  certificationBundleChoice: Object;
  handleChangeAllotmentNodes: Function;
  renderCaracPopOver: Function;
  setIsAssignementToGraph: Function;
  isAssignementToGraph: boolean;
  setEquipHaveCaracs: Function;
};
const RenderCarbonEstimation = ({
  bundleLines,
  handleChangeCarbonEstimation,
  searchBarOptionsFiltered,
  handleOnChangeDropdown,
  returnDefaultValueTypeEquipment,
  certificationBundleChoice,
  handleChangeAllotmentNodes,
  renderCaracPopOver,
  setIsAssignementToGraph,
  isAssignementToGraph,
  setEquipHaveCaracs,
}: RenderCarbonEstimationProps) => {
  const api = new API();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { allGroups, certifiedAllotments, listOfNodesSearchBar } = useSelector(
    (state: StateDTO) => state.projects
  );

  const handleOnChange = (e: CheckboxChangeEvent) =>
    setIsAssignementToGraph(e.target.checked);

  if (!bundleLines || bundleLines?.length === 0) return null;

  return (
    <>
      <div className="carbonEstimationWrapper">
        <div className="main fromLibrary">
          <MaterialTable
            lines={bundleLines[0]?.materialLines || []}
            updateLines={(newValue) => {
              handleChangeCarbonEstimation({
                bundleLine: bundleLines[0],
                value: newValue,
                formName: 'materialLines',
              });
            }}
          />
          <div className="rightPart">
            <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
              <MaterialTotalWeight
                t={t}
                totalWeight={bundleLines[0]?.totalWeight}
                onChange={(newValue) => {
                  handleChangeCarbonEstimation({
                    bundleLine: bundleLines[0],
                    value: newValue,
                    formName: 'totalWeight',
                  });
                }}
              />
              <MaterialSynthese
                lines={bundleLines[0]?.materialLines || []}
                isLibraryScreen={true}
              />
            </div>
            <div className="footerCarbon">
              <ManufacturerIndexTitle t={t} isLibraryScreen={true} />
              <ManufacturerIndexChoice
                t={t}
                handleCheckElement={(newValue) => {
                  handleChangeCarbonEstimation({
                    bundleLine: bundleLines[0],
                    value: newValue,
                    formName: 'manufacturedRate',
                  });
                }}
                percentValueElementSelected={bundleLines[0]?.manufacturedRate}
                isLibraryScreen={true}
              />
            </div>
          </div>
        </div>
      </div>
      <Row gutter={[16, 16]} style={{ padding: '2rem' }} align="middle">
        <Col>
          <Checkbox onChange={handleOnChange} checked={isAssignementToGraph}>
            {t('APP.RENDER_MODELING_PHASE.CHECKBOX_LABEL', {
              defaultValue: 'Affecter à un nuage de points',
            })}
          </Checkbox>
        </Col>
        <Col style={{ display: 'flex' }}>
          {isAssignementToGraph &&
            bundleLines?.map((bundleLine: BundleLineDTO) => {
              const isModeledProductEquipment =
                bundleLine?.type_fiche === 'custom_user';
              let nodeWithMd;
              let result;
              if (!isModeledProductEquipment) {
                nodeWithMd = bundleLine.AllotmentNodes?.find(
                  (node) => node.MasterDataEquipmentId
                );

                result = bundleLine.AllotmentNodes
                  ? handleAllotmentNodes(
                      allGroups.type.find(
                        (md) => md.id === nodeWithMd?.MasterDataEquipmentId
                      ),
                      allGroups.allotments.find(
                        (allot) => allot.id === ALLOTMENT_IDS.NOOCO
                      ),
                      allGroups.flatAllotments,
                      null
                    )
                  : null;
              }
              return (
                <>
                  <SearchGroup
                    t={t}
                    searchBarOptionsFiltered={searchBarOptionsFiltered}
                    certifiedAllotments={certifiedAllotments}
                    isBundleScreen={true}
                    triggerBreadcrumb={() => {
                      dispatch(updateFormValue('openBreadcrumb', true));
                      dispatch(updateFormValue('currentBundleLine', bundleLine));
                    }}
                    findParentNode={findParentNode}
                    allAllotments={allGroups.flatAllotments}
                    allotmentsAvailable={listOfNodesSearchBar?.filter(
                      (el) => !el.isObsolete
                    )}
                    dropdownHeight="55px"
                    api={api}
                    dropdownProps={{
                      onSelect: () => {},
                      onChange: (value) => {
                        setEquipHaveCaracs(true);
                        handleOnChangeDropdown(value, bundleLine);
                      },
                      placeholder: t('APP.BUNDLEDETAILS.INDEX.PH_B43F0F', {
                        defaultValue: 'Rechercher...',
                      }),
                      treeCheckable: false,
                      value: !bundleLine.AllotmentNodes
                        ? null
                        : isModeledProductEquipment
                        ? bundleLine?.name
                        : bundleLine.Sheet?.name
                        ? bundleLine.Sheet.name
                        : bundleLine.AllotmentNodes
                        ? bundleLine.AllotmentNodes.find(
                            (node) => node.MasterDataEquipmentId
                          )?.name
                        : bundleLine?.name
                        ? bundleLine.name
                        : '',
                    }}
                    selectProps1={{
                      defaultValue: returnDefaultValueTypeEquipment(bundleLine),
                      value: bundleLine.searchBarType,
                      filterOptions: ['equipements'],
                      doNotDisplay: ['equipements'],
                      style: { display: 'none' },
                    }}
                    selectProps2={{
                      ListType: Object.entries(certificationBundleChoice)
                        .filter(([_, value]) => value)
                        .map(([key, _]) => key),
                      noocoOptions: result
                        ? result.filter((el) => !el.isObsolete)
                        : bundleLine.Sheet?.AllotmentNodes || [],
                      keyDisplay: 'name',
                      keyItem: 'id',
                      onChange: (selectedOptionValue, type) => {
                        const data = result?.find(
                          (el) => el.name === selectedOptionValue
                        );

                        let nodeSelected =
                          type === 'Nooco'
                            ? data.details.find((el) => el.MasterDataEquipmentId)
                            : selectedOptionValue;

                        handleChangeAllotmentNodes(nodeSelected, type, bundleLine);
                      },
                      value: bundleLine.AllotmentNodes,
                    }}
                    gridStyle={'300px 0px  55px'}
                  />
                  {bundleLine.AllotmentNodes &&
                    !bundleLine.Sheet?.type_fiche &&
                    bundleLine.caracs &&
                    bundleLine.caracs.caracs?.length > 0 && (
                      <Popover
                        placement="top"
                        title={t('APP.BUNDLEDETAILS.INDEX.PH_BE774E', {
                          defaultValue: 'Caractéristiques',
                        })}
                        content={() => renderCaracPopOver(bundleLine)}
                        trigger="click"
                      >
                        <img
                          src={caracs}
                          style={{
                            width: '40px',
                            filter: bundleLine?.caracs?.caracs?.find(
                              (car) => car && (!car.value || car.value === '')
                            )
                              ? 'invert(21%) sepia(52%) saturate(5667%) hue-rotate(354deg) brightness(88%) contrast(133%)'
                              : undefined,
                          }}
                          alt=""
                        />
                      </Popover>
                    )}
                </>
              );
            })}
        </Col>
      </Row>
    </>
  );
};

export default RenderCarbonEstimation;
