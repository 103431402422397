import './stepBlock.scss';
import { StateDTO } from '../../../models/GeneralDTO';
import { updateFormValue } from '../../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

interface StepBlockState {
  data: {
    img: string;
    title: string;
    creationStepName: string;
    isActive: boolean;
    selectedTypeProjectId: number | null;
    disabled: boolean;
  };
}

const StepBlock = ({ data }: StepBlockState) => {
  const dispatch = useDispatch();
  const { creationProject } = useSelector((state: StateDTO) => state.projects);
  const { creationSteps } = useSelector(
    (state: StateDTO) => state.projects.creationProject
  );

  const { t } = useTranslation();

  const img = require(`../../../..//assets/images/creation/${data.img}.png`);

  return (
    <Tooltip
      title={`${
        data.disabled
          ? t('APP.STEPBLOCK.INDEX.SOON_AVAILABLE', {
              defaultValue: 'Bientôt disponible',
            })
          : data.isActive
          ? ''
          : t('APP.STEPBLOCK.INDEX.PH_B53716', {
              defaultValue:
                'Typologie de projet non inclue dans le paramétrage de votre entreprise',
            })
      }`}
    >
      <div
        className={`creation-choice ${
          data.isActive && !data.disabled ? '' : 'disabled'
        }`}
        style={{ position: 'relative' }}
        onClick={() => {
          if (data.isActive && !data.disabled) {
            dispatch(
              updateFormValue('creationProject', {
                ...creationProject,
                creationSteps: [...creationSteps, data?.title],
                selectedTypeProjectId: data.selectedTypeProjectId,
              })
            );
          }
        }}
      >
        {/* // TODO_TRAD : return true / false */}
        {data.creationStepName === 'Infrastructure extérieure' ||
          data.creationStepName === 'Exploitation' ||
          data.creationStepName === 'Datacenter' ||
          data.creationStepName === 'Prototype'}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={typeof img === 'string' ? img : img.default} />
        </div>
        <p>{data.title}</p>
      </div>
    </Tooltip>
  );
};

export default StepBlock;
