import './zonesection.scss';
import { Button } from '@photocarbone/nooco-ui';
import { Col, Form } from 'react-bootstrap';
import {
  REFERENCE_TYPE_NAME,
  TEMPLATE_TYPE_NAME,
  TYPE_RE2020,
} from '../../globalVariable/typeProject';
import { Select } from '@material-ui/core';
import { TreeSelect } from 'antd';
import { buildingTypesMap } from '../../services/mathematics/indicators/buildingTypesMap';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import ButtonMaterial from '@material-ui/core/Button';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MenuItem from '@mui/material/MenuItem';
import NoocoCheckbox from '../NoocoCheckbox';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import LabelWithTooltip from '../LabelWithToolTip';

import _, { set, update } from 'lodash';

import { CircularProgress } from '@material-ui/core';
import { TYPE_EPCM, TYPE_RENOVATION } from '../../globalVariable/typeProject';
import {
  addZonesSections,
  changeTypeOfProject,
  handleControlledZones,
  managementBuildingSections,
  patchZones,
  updateFormValue,
} from '../../../actions/projects';
import {
  buildingTypesFilteredAccordingToTemplateType,
  isBuildingTypeZoneEditable,
  isDisplayAddBuildingAndZoneButton,
  isDisplayCombles,
  isDisplayGroundExpansion,
  isDisplayLogementNumber,
  isDisplayOccupantNumber,
  isDisplayReferenceArea,
  isDisplayRenovationType,
  isDisplayspeculativeDevelopment,
} from './ZoneSectionFunction';
import { useTranslation } from 'react-i18next';
import { isDisplaySelectZoneType } from '../../projects/CreationProject/CreationProjectFunction/creationProjectFunction';
import { ZONE_TYPES } from '../../globalVariable/generalDefinitions';
import { Controller } from 'react-hook-form';
import { isNumHigherZero } from '../utilsFunction';

const { TreeNode } = TreeSelect;

const ZoneSection = ({
  isSettings,
  scrollRef,
  projectDataComps,
  updateSettingsDataWithDataComps,
  control,
  errors,
  setValue,
}) => {
  const {
    buildingTypes,
    typeOfProject,
    buildingSections,
    currentProject,
    editZones,
    buildingTypeStandard,
    rsetFile,
    zoneCreated,
    settingsDatas,
    isRsenv,
    zones,
    loadingCreationZone,
    loadingCreationBuilding,
    addBuildingIndex,
    addZoneIndex,
    zoneScrollPosition,
    loadingDeleteBuilding,
    loadingDeleteZone,
    refUnit,
    allGroups,
  } = useSelector((state) => state.projects);
  const { isRset } = currentProject;
  const { t } = useTranslation();

  const rseeImportMessage = t('APP.ZONESECTION.ZONESECTION.PH_0B9491', {
    defaultValue:
      'Votre projet a été créé à partir d’un RSEE et il est donc impossible de modifier les paramètres relatifs aux informations importées depuis ce fichier.',
  });
  const rsetImportMessage = t('APP.ZONESECTION.ZONESECTION.PH_4600AE', {
    defaultValue:
      'Votre projet a été créé à partir d’un RSET et il est donc impossible de modifier les paramètres relatifs aux zones du projet.',
  });

  //! settingsData représentent les zones dans le composant ZoneSection. C'est une copie de currentProject.Zones
  //! updateZone permet d'update settingsData
  //! editZone représente les zones qui ont été modifiés Quand on save les modifications d'un projet, on verifie si editZone est vide pour savoir si l'on doit faire un appel à patchZone en plus de patchProject

  const dispatch = useDispatch();

  const [checked, setChecked] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(
    t('APP.ZONESECTION.ZONESECTION.PH_DF768D', {
      defaultValue: "Vous devez d'abord supprimer les équipements associés",
    })
  );
  const referenceType =
    currentProject.templateType === TYPE_RE2020
      ? 'RE2020'
      : currentProject.templateType === TYPE_EPCM
      ? 'E+C-'
      : 'NOOCO';
  const hasCurrentProjectRset = currentProject.isRset;

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = zoneScrollPosition;
    }
  }, [zoneScrollPosition]);

  // Management of zones in creation project modal

  const handleZone = (input, value) => {
    dispatch(updateFormValue(input, value));
  };

  const handleChecked = (id, bool, isDisabled = false) => {
    const updateChecked = checked.length ? [...checked] : [];

    updateChecked[id] = {
      value: bool,
      isDisabled,
    };
    setChecked(updateChecked);

    return updateChecked[id];
  };

  /**
   * If the project uses masterdatas which are linked
   * to a urban network, the checkbox is disabled and
   * an alert is triggered
   */
  const handleUrbanNetwork = (zone) => {
    let isConnected = null;

    if (!zone.ProjectSheets) return isConnected;

    zone.ProjectSheets.forEach((el) => {
      if (el.MasterDataEquipment?.isConnectedToUrbanNetwork) {
        setShowSnackbar(true);

        isConnected = true;

        return;
      }
    });

    return isConnected;
  };

  const handleSnackClose = (event, reason) => {
    setShowSnackbar(false);
  };

  useEffect(() => {
    let initData = [];
    const copy = _.cloneDeep(currentProject);

    if (isSettings) {
      initData =
        copy.Zones[0]?.type === 'plot'
          ? [...copy.Zones[0].Zones.filter((elem) => elem?.type !== 'buildingSite')]
          : [...copy.Zones.filter((elem) => elem?.type !== 'buildingSite')];
      initData = initData.sort(function (a, b) {
        if (a.id < b.id) {
          return -1;
        }

        if (a.id > b.id) {
          return 1;
        }

        return 0;
      });

      dispatch(updateFormValue('settingsDatas', initData));
      updateSettingsDataWithDataComps(projectDataComps);
    }

    dispatch(updateFormValue('editZones', { zones: [] }));

    return () => {
      dispatch(updateFormValue('editZones', { zones: [] }));
    };
  }, [currentProject]);

  const updateZones = (buildingIndex, zoneIndex, inputName, value) => {
    let currentZone;
    let currentZones;

    if (typeof zoneIndex === 'number') {
      currentZone = settingsDatas[buildingIndex].Zones[zoneIndex];
    } else {
      currentZone = settingsDatas[buildingIndex];
    }

    const typeException = () => {
      if (currentProject.templateType === TEMPLATE_TYPE_NAME.BBCA) {
        currentZones = settingsDatas[buildingIndex].Zones.filter(
          (zone) => zone.type === ZONE_TYPES.zone
        );

        currentZones.forEach((zone) => {
          zone.BuildingTypeId = Number(value);
          zone.sCombles = 0;
          zone.nbOccupants = 0;
          zone.nbLgt = 0;
          zone.IsConnectedToUrbanNetwork = null;
        });
      } else {
        currentZone.BuildingTypeId = Number(value);
        currentZone.sCombles = 0;
        currentZone.nbOccupants = 0;
        currentZone.nbLgt = 0;
        currentZone.IsConnectedToUrbanNetwork = null;
      }
    };

    switch (inputName) {
      case 'surface':
        currentZone.area = Number(value);
        break;
      case 'type':
        typeException();
        break;
      case 'nameZone':
        currentZone.name = value;
        break;
      case 'srt':
        currentZone.srt = value;
        break;
      case 'nbParkingSpotsSurface':
        currentZone.nbParkingSpotsSurface = value;
        break;
      case 'nbParkingSpotsSurfacePlu':
        currentZone.nbParkingSpotsSurfacePlu = value;
        break;
      case 'nbParkingSpotsUndergroundPlu':
        currentZone.nbParkingSpotsUndergroundPlu = value;
        break;
      case 'nbParkingSpotsUnderground':
        currentZone.nbParkingSpotsUnderground = value;
        break;
      case 'nbLgt':
        currentZone.nbLgt = value;
        break;
      case 'sCombles':
        currentZone.sCombles = Number(value);
        break;
      case 'nbOccupants':
        currentZone.nbOccupants = value;
        break;
      case 'groundExpansion':
        currentZone.groundExpansion = value;
        break;
      case 'IsConnectedToUrbanNetwork':
        currentZone.IsConnectedToUrbanNetwork = value;
        break;
      case 'isSharedArea':
        currentZone.isSharedArea = value;
        break;
      case 'area':
        currentZone.area = value;
        break;
      case 'isTAC':
        currentZone.isTAC = value;
        break;
      case 'speculativeDevelopment':
        currentZone.speculativeDevelopment = value;
        break;
      case 'RenovationTypeId':
        currentZone.RenovationTypeId = value;
        break;

      default:
        currentZone[inputName] = value;
    }

    let newZones = [];

    const editZonesUpdate = (editZones) => {
      if (editZones.zones.find((el) => el.id === currentZone.id)) {
        newZones = [
          ...editZones.zones.filter((el) => el.id !== currentZone.id),
          currentZone,
        ];
      } else {
        newZones = [...editZones.zones, currentZone];
      }
    };

    const editAllZonesUpdate = (editZones, zone) => {
      if (editZones.zones.find((editZone) => editZone.id === zone.id)) {
        editZones = { ...editZones, ...zone };
        newZones = [...newZones, zone];
      } else {
        newZones = [...newZones, ...editZones.zones, zone];
      }
    };

    if (currentZones) {
      currentZones.forEach((zone) => {
        editAllZonesUpdate(editZones, zone);
      });
    } else {
      editZonesUpdate(editZones);
    }

    dispatch(updateFormValue('editZones', { ...editZones, zones: newZones }));
  };

  const handleCheckboxClick = (e, buildingIndex, zonesIndex, section) => {
    const bool = handleUrbanNetwork(section);

    if (!bool) {
      updateZones(
        buildingIndex,
        zonesIndex,
        `IsConnectedToUrbanNetwork`,
        e.target.checked
      );
    }
  };

  function zoneContainsEquipments(data, index) {
    const zones = data[index].Zones;
    const lastZone = zones[zones.length - 1];

    const hasId = lastZone.id;
    const hasProjectSheets = lastZone.ProjectSheets?.length > 0;
    const hasEquipments =
      lastZone.ProjectSheets &&
      lastZone.ProjectSheets.some(
        (projectSheetElement) => projectSheetElement.MasterDataEquipment
      );

    return hasId && hasProjectSheets && hasEquipments;
  }
  const handleZonesSettings = (buildingIndex, zoneIndex, reason, buildingId) => {
    let copyData = _.cloneDeep(settingsDatas);
    let idToRemove = null;

    dispatch(updateFormValue('addBuildingIndex', buildingIndex));
    dispatch(updateFormValue('addZoneIndex', zoneIndex));

    // setAddZoneActive(zoneId);

    if (zoneIndex || zoneIndex === 0) {
      if (reason === 'add') {
        let BuildingTypeId = 1;

        if (currentProject.templateType === TEMPLATE_TYPE_NAME.BBCA) {
          // add same buildingType as the first zone
          BuildingTypeId = zones
            .find((zone) => zone.id === buildingId)
            .Zones.find((zone) => zone.type === ZONE_TYPES.zone).BuildingTypeId;
        }

        let newZone = {
          name: `Zone ${zoneIndex + 2}`,
          area: 1,
          BuildingTypeId: BuildingTypeId,
          nbOccupants: 1,
          nbLgt: 1,
          ParentId: copyData[buildingIndex]?.id,
          sCombles: 1,
          localZoneId: copyData[buildingIndex].Zones.length + 1,
          IsConnectedToUrbanNetwork: false,
          isTAC: false,
          type: 'zone',
          category: 'CE1',
          isBuildingSite: zones.find((el) => el.type === 'buildingSite')
            ? true
            : false,
        };

        copyData[buildingIndex] = {
          ...copyData[buildingIndex],
          Zones: [...copyData[buildingIndex].Zones, newZone],
        };

        dispatch(
          updateFormValue('currentProject', {
            ...currentProject,
            area: currentProject.area + 1,
          })
        );
      } else {
        if (zoneContainsEquipments(copyData, buildingIndex)) {
          setSnackbarMessage(
            t('APP.ZONESECTION.ZONESECTION.PH_99764F', {
              defaultValue: "Vous devez d'abord supprimer les équipements associés",
            })
          );
          setShowSnackbar(true);

          return;
        }

        dispatch(
          updateFormValue('currentProject', {
            ...currentProject,
            area:
              currentProject.area -
              copyData[buildingIndex].Zones[copyData[buildingIndex].Zones.length - 1]
                .area,
          })
        );

        idToRemove =
          copyData[buildingIndex].Zones[copyData[buildingIndex].Zones.length - 1].id;
        copyData[buildingIndex].Zones.pop();
      }
    } else {
      if (reason === 'add') {
        let maxLocalZoneId = Math.max(
          ...settingsDatas.map((el) => Number(el.localZoneId))
        );

        const newBuilding = {
          ...copyData[0],
          id: null,
          name: `Bâtiment ${buildingIndex + 2}`,
          localZoneId: maxLocalZoneId + 1,
          area: 1,
          Zones: [
            {
              name: `Zone 1`,
              area: 1,
              isBuildingSite: zones.find((el) => el.type === 'buildingSite')
                ? true
                : false,
              BuildingTypeId: 1,
              nbOccupants: 1,
              nbLgt: 1,
              sCombles: 1,
              localZoneId: 1,
              IsConnectedToUrbanNetwork: false,
              isTAC: false,
              type: 'zone',
              category: 'CE1',
            },
          ],
        };

        copyData = [...copyData, newBuilding];

        dispatch(
          updateFormValue('currentProject', {
            ...currentProject,
            area: currentProject.area + 1,
          })
        );
      } else {
        let needVerification = false;

        copyData[buildingIndex].Zones.forEach((zone) => {
          if (
            zone.ProjectSheets &&
            zone.ProjectSheets.some(
              (projectSheetElement) => projectSheetElement.MasterDataEquipment
            ) &&
            zone.ProjectSheets.length > 0
          ) {
            setSnackbarMessage(
              t('APP.ZONESECTION.ZONESECTION.PH_9295BD', {
                defaultValue:
                  "Vous devez d'abord supprimer les équipements associés",
              })
            );
            setShowSnackbar(true);

            needVerification = true;

            return;
          }
        });

        if (needVerification) return;

        // if(copyData[buildingId].Zones[copyData[buildingId].Zones.length - 1].ProjectSheets.length > 0) {

        //   return;

        // }

        dispatch(
          updateFormValue('currentProject', {
            ...currentProject,
            area: currentProject.area - copyData[buildingIndex].area,
          })
        );

        idToRemove = copyData[buildingIndex].id;
        copyData.pop();
      }
    }

    if (reason === 'remove' && idToRemove) {
      if (zoneIndex === null) {
        dispatch(updateFormValue('loadingDeleteBuilding', true));
      } else {
        dispatch(updateFormValue('loadingDeleteZone', true));
      }
      dispatch(patchZones(idToRemove, 'remove', copyData));
      // dispatch(updateFormValue('settingsDatas',
      //   copyData));
    } else {
      if (zoneIndex || zoneIndex === 0) {
        dispatch(updateFormValue('loadingCreationZone', true));
      } else {
        dispatch(updateFormValue('loadingCreationBuilding', true));
      }

      dispatch(patchZones(copyData));
    }
  };

  useEffect(() => {
    if (zoneCreated) {
      let copyData = _.cloneDeep(settingsDatas);

      let newZone = zoneCreated.find((el) => el.type === 'zone');

      let newBuilding = zoneCreated.find((el) => el.type === 'building');

      if (!newBuilding) {
        copyData = copyData.map((building) => {
          if (building.id === newZone.ParentId) {
            return {
              ...building,
              Zones: [
                ...building.Zones.filter((el) => el.name !== newZone.name),
                newZone,
              ],
            };
          } else {
            return building;
          }
        });
      } else {
        newBuilding.Zones = [newZone];
        copyData = [...copyData, newBuilding];
      }

      dispatch(updateFormValue('settingsDatas', copyData));
      dispatch(updateFormValue('zoneCreated', null));
    }
  }, [zoneCreated]);

  // Define conditions for fields disabling or not
  // TODO

  const displayUrbanNetWorkCheck = (projectType, buildingType) => {
    if (projectType === TYPE_EPCM || projectType === TYPE_RE2020) {
      if (
        buildingType === buildingTypesMap.LOGEMENT_COL ||
        buildingType === buildingTypesMap.BUREAUX ||
        buildingType === buildingTypesMap.ENSEIGNEMENT_PRIMAIRE ||
        buildingType === buildingTypesMap.ENSEIGNEMENT_SECONDAIRE ||
        buildingType === buildingTypesMap.MAISON
      ) {
        return true;
      }
    } else if (projectType === 'NOOCO') {
      if (
        buildingType !== buildingTypesMap.MAISON &&
        buildingType !== buildingTypesMap.PARKING
      ) {
        return true;
      }
    }

    return false;
  };

  const [inputValue, setInputValue] = useState('');

  return (
    <>
      <div className="zoneSection-container ">
        {/* when user want a project without zones */}
        {/* It is Still used? because now it s in createProject Component? */}
        {typeOfProject === 'standard' && !isSettings && (
          <>
            <div className="standard-project">
              <p>
                {t('APP.ZONESECTION.ZONESECTION.PH_9E5F79', {
                  defaultValue: 'Bâtiment',
                })}
              </p>
              <Form.Row className="zonesContainer">
                <Tooltip
                  title={
                    (isSettings && rsetFile) || (isSettings !== true && rsetFile)
                      ? rsetImportMessage
                      : ''
                  }
                >
                  <Form.Group
                    className={`${
                      referenceType[0] === 'E+C-' ? 'columns-ec' : null
                    }`}
                    as={Col}
                    controlId="formGridState"
                  >
                    <Form.Label>
                      {t('APP.ZONESECTION.ZONESECTION.PH_7D013E', {
                        defaultValue: 'Typologie de zone',
                      })}
                    </Form.Label>
                    <TreeSelect
                      showSearch={false}
                      value={buildingTypeStandard}
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      placeholder={t('APP.ZONESECTION.ZONESECTION.PH_CDBB93', {
                        defaultValue: 'Typologie de zone',
                      })}
                      allowClear
                      treeDefaultExpandAll
                      onChange={(value) => {
                        handleZone(`buildingTypeStandard`, value);
                      }}
                    >
                      {buildingTypes.map((type) => {
                        if (
                          type.Parent?.length > 0 &&
                          currentProject.templateType.includes(TYPE_EPCM)
                        ) {
                          return (
                            <TreeNode
                              disabled
                              key={type.id}
                              value={type.id}
                              title={type.name}
                            >
                              {type.Parent.sort(
                                (a, b) =>
                                  a.name.localeCompare(b.name) -
                                  b.name.localeCompare(a.name)
                              ).map((subType: { id: number, name: any }) => {
                                return (
                                  <TreeNode
                                    key={subType.id}
                                    value={subType.id}
                                    title={subType.name}
                                  />
                                );
                              })}
                            </TreeNode>
                          );
                        } else if (
                          currentProject.templateType.includes(TYPE_RE2020)
                        ) {
                          if (type.iniesId) {
                            return (
                              <TreeNode
                                key={type.id}
                                value={type.id}
                                title={type.name}
                              />
                            );
                          }
                        } else if (currentProject.templateType.includes(TYPE_EPCM)) {
                          if (type.iniesIdEpcm) {
                            return (
                              <TreeNode
                                key={type.id}
                                value={type.id}
                                title={type.name}
                              />
                            );
                          }
                        } else {
                          return (
                            <TreeNode
                              key={type.id}
                              value={type.id}
                              title={type.name}
                            />
                          );
                        }
                      })}
                    </TreeSelect>
                  </Form.Group>
                </Tooltip>
                <Form.Group as={Col} controlId="exampleForm.ControlTextarea1">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Form.Label
                      defaultValue={1}
                      min={1}
                      step={0.1}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      Surface de référence (m²)
                    </Form.Label>
                    {(referenceType === 'RE2020' || referenceType === 'E+C-') && (
                      <Tooltip
                        title={
                          referenceType === 'RE2020'
                            ? t('APP.ZONESECTION.ZONESECTION.PH_9596D6', {
                                defaultValue:
                                  'Pour le résidentiel : Surface Habitable (SHab). Pour le tertiaire : Surface utile (SU)',
                              })
                            : referenceType === 'E+C-'
                            ? t('APP.ZONESECTION.ZONESECTION.PH_BFA178', {
                                defaultValue: 'Surface de plancher',
                              })
                            : ''
                        }
                      >
                        <InfoOutlinedIcon
                          style={{ marginBottom: '0.5rem', height: '1rem' }}
                        />
                      </Tooltip>
                    )}
                  </div>

                  <Tooltip
                    title={
                      (isSettings &&
                        (referenceType === 'RE2020' ? rsetFile : isRsenv)) ||
                      (isSettings !== true &&
                        (referenceType === 'RE2020' ? rsetFile : isRsenv))
                        ? rsetImportMessage
                        : ''
                    }
                  >
                    <Form.Control
                      type="number"
                      step=".0000001"
                      className="inputOfZone"
                      placeholder={t('APP.ZONESECTION.ZONESECTION.PH_8BFCC5', {
                        defaultValue: 'Surface',
                      })}
                      isInvalid={/^0+$/.test(inputValue)}
                      onChange={(e) => {
                        setInputValue(e.target.value);
                        handleZone(`standardLgt`, e.target.value);
                      }}
                      required
                    />
                  </Tooltip>

                  {/^0+$/.test(inputValue) && (
                    <Form.Control.Feedback type="invalid">
                      {t('APP.ZONESECTION.ZONESECTION.PH_E494C9', {
                        defaultValue:
                          "Veuillez indiquer une valeur d'au minimum 1m²",
                      })}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>
            </div>
            {referenceType.includes(REFERENCE_TYPE_NAME.EPCM) && (
              <div className="epcm-inputs">
                <div className="left">
                  <Form.Group
                    as={Col}
                    controlId="exampleForm.ControlTextarea1"
                    className="optionalGroup"
                  >
                    <Form.Label style={{ margin: 0 }}>
                      {t('APP.ZONESECTION.ZONESECTION.PH_FB4276', {
                        defaultValue: "Nombre d'occupants",
                      })}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      step=".01"
                      min="0"
                      defaultValue={1}
                      className="inputOfZone"
                      onChange={(e) => handleZone(`standardMen`, e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    controlId="exampleForm.ControlTextarea1"
                    className="optionalGroup"
                  >
                    <Form.Label style={{ margin: 0 }}>
                      {t('APP.ZONESECTION.ZONESECTION.PH_1A2AC4', {
                        defaultValue: 'Nombre de logements',
                      })}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      step="1"
                      min="1"
                      className="inputOfZone"
                      onChange={(e) => handleZone(`standardLgt`, e.target.value)}
                      required
                    />
                  </Form.Group>
                </div>
                <div className="right">
                  <Form.Group
                    as={Col}
                    controlId="exampleForm.ControlTextarea1"
                    className="optionalGroup"
                  >
                    <Form.Label style={{ margin: 0 }}>
                      {t('APP.ZONESECTION.ZONESECTION.PH_249369', {
                        defaultValue: 'Catégories :',
                      })}
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => handleZone(`category`, e.target.value)}
                      required
                      type="text"
                      as="select"
                      custom
                    >
                      <option label={'CE1'} value={'CE1'} />
                      <option label={'CE2'} value={'CE2'} />
                    </Form.Control>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    controlId="exampleForm.ControlTextarea1"
                    className="optionalGroup"
                  >
                    <Form.Label style={{ margin: 0 }}>
                      {t('APP.ZONESECTION.ZONESECTION.PH_A826B4', {
                        defaultValue:
                          'Nombre de places de parking en surface (PLU):',
                      })}
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        handleZone(`nbParkingSpotsSurface`, e.target.value)
                      }
                      type="number"
                      className="inputOfZone"
                      defaultValue={0}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    controlId="exampleForm.ControlTextarea1"
                    className="optionalGroup"
                  >
                    <Form.Label style={{ margin: 0 }}>
                      {t('APP.ZONESECTION.ZONESECTION.PH_3879C5', {
                        defaultValue:
                          'Nombre de places de parking en souterrain (PLU):',
                      })}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      onChange={(e) =>
                        handleZone(`nbParkingSpotsUnderground`, e.target.value)
                      }
                      className="inputOfZone"
                      defaultValue={0}
                      required
                    />
                  </Form.Group>
                </div>
              </div>
            )}

            <div className="input-optional">
              {displayUrbanNetWorkCheck(referenceType, buildingTypesMap) && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <NoocoCheckbox
                    defaultChecked={false}
                    onChange={(e) =>
                      handleZone(
                        `standardIsConnectedToUrbanNetwork`,
                        e.target.checked
                      )
                    }
                  />
                  {}
                  <p style={{ margin: 0 }}>
                    {t('APP.ZONESECTION.ZONESECTION.PH_573472', {
                      defaultValue: 'Raccordé à un réseau de chaleur urbain',
                    })}
                  </p>
                </div>
              )}
              {(referenceType.includes('RE2020') ||
                referenceType.includes('free')) && (
                <Form.Group
                  as={Col}
                  controlId="exampleForm.ControlTextarea1"
                  className="optionalGroup"
                >
                  <Form.Label style={{ margin: 0 }}>
                    {t('APP.ZONESECTION.ZONESECTION.PH_9CFE96', {
                      defaultValue: "Nombre d'occupants :",
                    })}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    step=".01"
                    min="0"
                    defaultValue={1}
                    className="inputOfZone"
                    onChange={(e) => handleZone(`standardMen`, e.target.value)}
                    required
                  />
                </Form.Group>
              )}
              {((buildingTypeStandard == '1' &&
                (referenceType.includes('RE2020') ||
                  referenceType.includes('free'))) ||
                (buildingTypeStandard == buildingTypesMap.MAISON &&
                  (referenceType.includes('RE2020') ||
                    referenceType.includes('free')))) && (
                <Form.Group
                  as={Col}
                  controlId="exampleForm.ControlTextarea1"
                  className="optionalGroup"
                >
                  <Form.Label style={{ margin: 0 }}>
                    {t('APP.ZONESECTION.ZONESECTION.PH_16A70D', {
                      defaultValue: 'Nombre de logements :',
                    })}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    step="1"
                    min="1"
                    className="inputOfZone"
                    onChange={(e) => handleZone(`standardLgt`, e.target.value)}
                    required
                  />
                </Form.Group>
              )}
              {(buildingTypeStandard == '2' ||
                buildingTypeStandard == buildingTypesMap.MAISON) &&
                (referenceType.includes('RE2020') ||
                  referenceType.includes('free')) && (
                  <Form.Group
                    as={Col}
                    controlId="exampleForm.ControlTextarea1"
                    className="optionalGroup"
                  >
                    <Tooltip
                      title={t('APP.ZONESECTION.ZONESECTION.PH_3D794E', {
                        defaultValue:
                          'Surface de plancher des combles aménagés dont la hauteur sous plafond est inférieure à 1,8 mètres',
                      })}
                    >
                      <Form.Label style={{ margin: 0, width: '55%' }}>
                        {t('APP.ZONESECTION.ZONESECTION.PH_8B5DCB', {
                          defaultValue: 'Surface des combles (m²) :',
                        })}
                      </Form.Label>
                    </Tooltip>
                    <Form.Control
                      className="inputOfZone"
                      type="number"
                      step=".0000001"
                      onChange={(e) => handleZone(`standardCombles`, e.target.value)}
                      required
                    />
                  </Form.Group>
                )}
            </div>
            <ButtonMaterial
              disabled={rsetFile}
              variant="contained"
              startIcon={<ControlPointIcon />}
              onClick={() => dispatch(changeTypeOfProject())}
            >
              {t('APP.ZONESECTION.ZONESECTION.PH_E10B65', {
                defaultValue: 'Ajouter un bâtiment ou une zone',
              })}
            </ButtonMaterial>
          </>
        )}

        {/* It is Still used? because now it s in createProject Component? */}
        {typeOfProject === 'projectWithZones' &&
          !isSettings &&
          buildingSections.map((building, buildingIndex) => (
            <div key={buildingIndex} className="buildingSection-container">
              {/* buildingName */}
              <TextField
                id="standard-basic"
                style={{ width: '20%' }}
                defaultValue={building.name}
                disabled={rsetFile}
                onChange={(e) => {
                  dispatch(
                    handleControlledZones(
                      buildingIndex,
                      null,
                      `nameBuilding`,
                      e.target.value
                    )
                  );
                }}
              />
              {/* zones */}
              {building.Zones.map((section, zonesIndex) => {
                {
                  /* zoneWrapper */
                }

                return (
                  <div key={zonesIndex} className="inner-zones">
                    {/* first row */}
                    <div className="zones-section">
                      {/* zoneName */}
                      <TextField
                        style={{ width: '15%' }}
                        id="standard-basic"
                        defaultValue={section.name}
                        disabled={rsetFile}
                        onChange={(e) => {
                          dispatch(
                            handleControlledZones(
                              buildingIndex,
                              zonesIndex,
                              `nameZone`,
                              e.target.value
                            )
                          );
                        }}
                      />
                      <Form.Row key={zonesIndex} className="zonesContainer">
                        {/* zoneTypes */}
                        <Tooltip
                          title={
                            (isSettings && rsetFile) ||
                            (isSettings !== true && rsetFile)
                              ? rsetImportMessage
                              : ''
                          }
                        >
                          <Form.Group as={Col} controlId="formGridState">
                            {zonesIndex === 0 && (
                              <Form.Label>
                                {t('APP.ZONESECTION.ZONESECTION.PH_EAB579', {
                                  defaultValue: 'Typologie de zone',
                                })}
                              </Form.Label>
                            )}

                            <TreeSelect
                              showSearch
                              disabled={rsetFile}
                              className="inputOfZone"
                              value={section.BuildingTypeId}
                              key={buildingSections}
                              dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                              }}
                              placeholder="Typologie de zone"
                              allowClear
                              treeDefaultExpandAll
                              onChange={(e) => {
                                dispatch(
                                  handleControlledZones(
                                    buildingIndex,
                                    zonesIndex,
                                    `type`,
                                    e
                                  )
                                );
                              }}
                            >
                              {buildingTypes.map((type) => {
                                if (
                                  type.Parent?.length > 0 &&
                                  currentProject.templateType.includes(TYPE_EPCM)
                                ) {
                                  return (
                                    <TreeNode
                                      disabled
                                      key={type.id}
                                      value={type.id}
                                      title={type.name}
                                    >
                                      {type.Parent.sort(
                                        (a, b) =>
                                          a.name.localeCompare(b.name) -
                                          b.name.localeCompare(a.name)
                                      ).map((subType: { id: number, name: any }) => {
                                        return (
                                          <TreeNode
                                            key={subType.id}
                                            value={subType.id}
                                            title={subType.name}
                                          />
                                        );
                                      })}
                                    </TreeNode>
                                  );
                                } else if (
                                  currentProject.templateType.includes(TYPE_RE2020)
                                ) {
                                  if (type.iniesId) {
                                    return (
                                      <TreeNode
                                        key={type.id}
                                        value={type.id}
                                        title={type.name}
                                      />
                                    );
                                  }
                                } else if (
                                  currentProject.templateType.includes(TYPE_EPCM)
                                ) {
                                  if (type.iniesIdEpcm) {
                                    return (
                                      <TreeNode
                                        key={type.id}
                                        value={type.id}
                                        title={type.name}
                                      />
                                    );
                                  }
                                } else {
                                  return (
                                    <TreeNode
                                      key={type.id}
                                      value={type.id}
                                      title={type.name}
                                    />
                                  );
                                }
                              })}
                            </TreeSelect>
                          </Form.Group>
                        </Tooltip>
                        <Form.Group
                          as={Col}
                          controlId="exampleForm.ControlTextarea1"
                        >
                          {zonesIndex === 0 && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Form.Label
                                defaultValue={1}
                                min={1}
                                step={0.1}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {t('APP.ZONESECTION.ZONESECTION.PH_F9F918', {
                                  defaultValue: 'Surface de référence (m²)',
                                })}
                              </Form.Label>
                              {(referenceType === 'RE2020' ||
                                referenceType === 'E+C-') && (
                                <Tooltip
                                  title={
                                    referenceType === 'RE2020'
                                      ? t('APP.ZONESECTION.ZONESECTION.PH_814AD6', {
                                          defaultValue:
                                            'Pour le résidentiel : Surface Habitable (SHab). Pour le tertiaire : Surface utile (SU)',
                                        })
                                      : referenceType === 'E+C-'
                                      ? t('APP.ZONESECTION.ZONESECTION.PH_BB053A', {
                                          defaultValue: 'Surface de plancher',
                                        })
                                      : ''
                                  }
                                >
                                  <InfoOutlinedIcon
                                    style={{
                                      marginBottom: '0.5rem',
                                      height: '1rem',
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          )}
                          {/* zoneArea */}
                          <Tooltip
                            title={
                              (isSettings &&
                                (referenceType === 'RE2020' ? rsetFile : isRsenv)) ||
                              (isSettings !== true &&
                                (referenceType === 'RE2020' ? rsetFile : isRsenv))
                                ? rsetImportMessage
                                : ''
                            }
                          >
                            <Form.Control
                              disabled={
                                referenceType === 'RE2020' ? rsetFile : isRsenv
                              }
                              type="number"
                              step=".0000001"
                              className="inputOfZone"
                              placeholder={t(
                                'APP.ZONESECTION.ZONESECTION.PH_C0AE81',
                                { defaultValue: 'Surface' }
                              )}
                              defaultValue={section.area}
                              key={buildingSections}
                              isInvalid={/^0+$/.test(inputValue)}
                              onChange={(e) => {
                                setInputValue(e.target.value);
                                dispatch(
                                  handleControlledZones(
                                    buildingIndex,
                                    zonesIndex,
                                    `surface`,
                                    e.target.value
                                  )
                                );
                              }}
                              required
                            />
                          </Tooltip>

                          {/^0+$/.test(inputValue) && (
                            <Form.Control.Feedback type="invalid">
                              {t('APP.ZONESECTION.ZONESECTION.PH_4AF9C8', {
                                defaultValue:
                                  "Veuillez indiquer une valeur d'au minimum 1m²",
                              })}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Form.Row>
                    </div>
                    {/* specific epcm input */}
                    {referenceType.includes(REFERENCE_TYPE_NAME.EPCM) && (
                      <div className="epcm-inputs">
                        <div className="left">
                          <Form.Group
                            as={Col}
                            controlId="exampleForm.ControlTextarea1"
                            className="optionalGroup"
                          >
                            <Form.Label style={{ margin: 0 }}>
                              {t('APP.ZONESECTION.ZONESECTION.PH_9B1364', {
                                defaultValue: "Nombre d'occupants",
                              })}
                            </Form.Label>
                            <Tooltip
                              title={
                                (isSettings && isRsenv) ||
                                (isSettings !== true && isRsenv)
                                  ? rseeImportMessage
                                  : ''
                              }
                            >
                              <Form.Control
                                type="number"
                                defaultValue={section.menInput}
                                // disabled={isRsenv}
                                key={buildingSections}
                                step=".01"
                                min="0"
                                className="inputOfZone"
                                onChange={(e) =>
                                  dispatch(
                                    handleControlledZones(
                                      buildingIndex,
                                      zonesIndex,
                                      `menInput`,
                                      e.target.value
                                    )
                                  )
                                }
                                required
                              />
                            </Tooltip>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            controlId="exampleForm.ControlTextarea1"
                            className="optionalGroup"
                          >
                            <Form.Label style={{ margin: 0 }}>
                              {t('APP.ZONESECTION.ZONESECTION.PH_1C8026', {
                                defaultValue: 'Nombre de logements',
                              })}
                            </Form.Label>
                            <Tooltip
                              title={
                                (isSettings && rsetFile) ||
                                (isSettings !== true && rsetFile)
                                  ? rsetImportMessage
                                  : ''
                              }
                            >
                              <Form.Control
                                type="number"
                                defaultValue={section.logementInput}
                                disabled={rsetFile}
                                key={buildingSections}
                                step="1"
                                min="1"
                                className="inputOfZone"
                                onChange={(e) =>
                                  dispatch(
                                    handleControlledZones(
                                      buildingIndex,
                                      zonesIndex,
                                      `logementInput`,
                                      e.target.value
                                    )
                                  )
                                }
                                required
                              />
                            </Tooltip>
                          </Form.Group>
                        </div>
                        <div className="right">
                          <Tooltip
                            title={
                              (isSettings && rsetFile) ||
                              (isSettings !== true && rsetFile)
                                ? rsetImportMessage
                                : ''
                            }
                          >
                            <Form.Group
                              as={Col}
                              controlId="exampleForm.ControlTextarea1"
                              className="optionalGroup"
                            >
                              <Form.Label style={{ margin: 0 }}>
                                {t('APP.ZONESECTION.ZONESECTION.PH_83D314', {
                                  defaultValue: 'Catégories :',
                                })}
                              </Form.Label>
                              <Form.Control
                                onChange={(e) =>
                                  dispatch(
                                    handleControlledZones(
                                      buildingIndex,
                                      zonesIndex,
                                      `category`,
                                      e.target.value
                                    )
                                  )
                                }
                                required
                                defaultValue={section.category}
                                disabled={rsetFile}
                                key={buildingSections}
                                type="text"
                                as="select"
                                custom
                              >
                                <option label={'CE1'} value={'CE1'} />
                                <option label={'CE2'} value={'CE2'} />
                              </Form.Control>
                            </Form.Group>
                          </Tooltip>
                          <Form.Group
                            as={Col}
                            controlId="exampleForm.ControlTextarea1"
                            className="optionalGroup"
                          >
                            <Form.Label style={{ margin: 0 }}>
                              {t('APP.ZONESECTION.ZONESECTION.PH_9F36C4', {
                                defaultValue:
                                  'Nombre de places de parking en surface (PLU):',
                              })}
                            </Form.Label>
                            <Tooltip
                              title={
                                (isSettings && isRsenv) ||
                                (isSettings !== true && isRsenv)
                                  ? rseeImportMessage
                                  : ''
                              }
                            >
                              <Form.Control
                                type="number"
                                onChange={(e) =>
                                  dispatch(
                                    handleControlledZones(
                                      buildingIndex,
                                      zonesIndex,
                                      `nbParkingSpotsSurface`,
                                      e.target.value
                                    )
                                  )
                                }
                                className="inputOfZone"
                                defaultValue={section.nbParkingSpotsSurface || 0}
                                // disabled={isRsenv}
                                key={buildingSections}
                                required
                              />
                            </Tooltip>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            controlId="exampleForm.ControlTextarea1"
                            className="optionalGroup"
                          >
                            <Form.Label style={{ margin: 0 }}>
                              {t('APP.ZONESECTION.ZONESECTION.PH_8ED736', {
                                defaultValue:
                                  'Nombre de places de parking en souterrain (PLU):',
                              })}
                            </Form.Label>
                            <Tooltip
                              title={
                                (isSettings && isRsenv) ||
                                (isSettings !== true && isRsenv)
                                  ? rseeImportMessage
                                  : ''
                              }
                            >
                              <Form.Control
                                type="number"
                                onChange={(e) =>
                                  dispatch(
                                    handleControlledZones(
                                      buildingIndex,
                                      zonesIndex,
                                      `nbParkingSpotsUnderground`,
                                      e.target.value
                                    )
                                  )
                                }
                                className="inputOfZone"
                                defaultValue={section.nbParkingSpotsUnderground || 0}
                                // disabled={isRsenv}
                                key={buildingSections}
                                required
                              />
                            </Tooltip>
                          </Form.Group>
                        </div>
                      </div>
                    )}
                    {/* optional input according projectType */}
                    <div className="input-optional">
                      {displayUrbanNetWorkCheck(referenceType, buildingTypesMap) && (
                        <Tooltip
                          title={
                            (isSettings && rsetFile) ||
                            (isSettings !== true && rsetFile)
                              ? rsetImportMessage
                              : ''
                          }
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            key={buildingSections}
                          >
                            <NoocoCheckbox
                              disabled={rsetFile}
                              checked={section.IsConnectedToUrbanNetwork || null}
                              onChange={(e) =>
                                dispatch(
                                  handleControlledZones(
                                    buildingIndex,
                                    zonesIndex,
                                    `IsConnectedToUrbanNetwork`,
                                    e.target.checked
                                  )
                                )
                              }
                            />

                            <p style={{ margin: 0 }}>
                              {t('APP.ZONESECTION.ZONESECTION.PH_61434C', {
                                defaultValue:
                                  'Raccordé à un réseau de chaleur urbain',
                              })}
                            </p>
                          </div>
                        </Tooltip>
                      )}
                      {(Number(section.BuildingTypeId) ===
                        buildingTypesMap.LOGEMENT_COL ||
                        section.menInput ||
                        section.BuildingTypeId == 2 ||
                        Number(section.BuildingTypeId) ===
                          buildingTypesMap.ENSEIGNEMENT_PRIMAIRE ||
                        Number(section.BuildingTypeId) ===
                          buildingTypesMap.BUREAUX ||
                        Number(section.BuildingTypeId) ===
                          buildingTypesMap.ENSEIGNEMENT_SECONDAIRE ||
                        Number(section.BuildingTypeId) ===
                          buildingTypesMap.LOGEMENT_COL) &&
                        !referenceType.includes('E+C-') && (
                          <Form.Group
                            as={Col}
                            controlId="exampleForm.ControlTextarea1"
                            className="optionalGroup"
                          >
                            <Form.Label style={{ margin: 0 }}>
                              {t('APP.ZONESECTION.ZONESECTION.PH_8F3BFA', {
                                defaultValue: "Nombre d'occupants :",
                              })}
                            </Form.Label>
                            <Tooltip
                              title={
                                (isSettings && rsetFile) ||
                                (isSettings !== true && rsetFile)
                                  ? rsetImportMessage
                                  : ''
                              }
                            >
                              <Form.Control
                                disabled={rsetFile}
                                defaultValue={section.menInput}
                                key={buildingSections}
                                type="number"
                                step=".01"
                                min="0"
                                className="inputOfZone"
                                onChange={(e) =>
                                  dispatch(
                                    handleControlledZones(
                                      buildingIndex,
                                      zonesIndex,
                                      `menInput`,
                                      e.target.value
                                    )
                                  )
                                }
                                required
                              />
                            </Tooltip>
                          </Form.Group>
                        )}
                      {(Number(section.BuildingTypeId) ===
                        buildingTypesMap.LOGEMENT_COL ||
                        Number(section.BuildingTypeId) ===
                          buildingTypesMap.ENSEIGNEMENT_PRIMAIRE ||
                        section.logementInput ||
                        section.BuildingTypeId == 2) &&
                        !referenceType.includes('E+C-') && (
                          <Form.Group
                            as={Col}
                            controlId="exampleForm.ControlTextarea1"
                            className="optionalGroup"
                          >
                            <Form.Label style={{ margin: 0 }}>
                              {t('APP.ZONESECTION.ZONESECTION.PH_315E98', {
                                defaultValue: 'Nombre de logements :',
                              })}
                            </Form.Label>
                            <Tooltip
                              title={
                                (isSettings && rsetFile) ||
                                (isSettings !== true && rsetFile)
                                  ? rsetImportMessage
                                  : ''
                              }
                            >
                              <Form.Control
                                disabled={rsetFile}
                                defaultValue={section.logementInput}
                                key={buildingSections}
                                type="number"
                                step="1"
                                min="1"
                                className="inputOfZone"
                                onChange={(e) =>
                                  dispatch(
                                    handleControlledZones(
                                      buildingIndex,
                                      zonesIndex,
                                      `logementInput`,
                                      e.target.value
                                    )
                                  )
                                }
                                required
                              />
                            </Tooltip>
                          </Form.Group>
                        )}
                      {(section.comblesInput || section.BuildingTypeId == 2) &&
                        !referenceType.includes('E+C-') && (
                          <Form.Group
                            as={Col}
                            controlId="exampleForm.ControlTextarea1"
                            className="optionalGroup"
                          >
                            <Form.Label style={{ margin: 0 }}>
                              {t('APP.ZONESECTION.ZONESECTION.PH_28A897', {
                                defaultValue: 'Surface des combles (m²) :',
                              })}
                            </Form.Label>
                            <Tooltip
                              title={
                                (isSettings && rsetFile) ||
                                (isSettings !== true && rsetFile)
                                  ? rsetImportMessage
                                  : ''
                              }
                            >
                              <Form.Control
                                disabled={rsetFile}
                                defaultValue={section.comblesInput}
                                key={buildingSections}
                                type="number"
                                step=".0000001"
                                className="inputOfZone"
                                onChange={(e) =>
                                  dispatch(
                                    handleControlledZones(
                                      buildingIndex,
                                      zonesIndex,
                                      `comblesInput`,
                                      e.target.value
                                    )
                                  )
                                }
                                required
                              />
                            </Tooltip>
                          </Form.Group>
                        )}
                    </div>
                    {/* footerZone Buttons add and delete */}
                    <div className="zones-buttons" style={{ display: 'flex' }}>
                      {zonesIndex === building.Zones.length - 1 && (
                        <Button
                          disabled={rsetFile}
                          additionalIcon={<ControlPointIcon />}
                          onClick={() => dispatch(addZonesSections(buildingIndex))}
                          text={t('APP.ZONESECTION.ZONESECTION.PH_44DF38', {
                            defaultValue: 'Ajouter une zone',
                          })}
                          className="purple-button"
                          style={{ marginRight: '10px' }}
                        />
                      )}

                      {zonesIndex === building.Zones.length - 1 && (
                        <Button
                          disabled={building.Zones.length === 1}
                          additionalIcon={<DeleteIcon />}
                          onClick={() =>
                            dispatch(addZonesSections(buildingIndex, 'remove'))
                          }
                          className="delete-button"
                          style={{ marginRight: '10px' }}
                          text={t('APP.ZONESECTION.ZONESECTION.PH_2BBC87', {
                            defaultValue: 'Supprimer une zone',
                          })}
                        />
                      )}
                    </div>
                  </div>
                );
              })}

              <div className="building-buttons">
                {buildingIndex === buildingSections.length - 1 && (
                  <ButtonMaterial
                    disabled={rsetFile}
                    className="purple-button"
                    additionalIcon={<ControlPointIcon />}
                    onClick={() =>
                      dispatch(managementBuildingSections(buildingIndex))
                    }
                    text={t('APP.ZONESECTION.ZONESECTION.PH_A3738D', {
                      defaultValue: 'Ajouter un bâtiment',
                    })}
                  />
                )}

                {buildingIndex === buildingSections.length - 1 && (
                  <ButtonMaterial
                    disabled={buildingSections.length === 1}
                    startIcon={<DeleteIcon />}
                    onClick={() =>
                      dispatch(managementBuildingSections(buildingIndex, 'remove'))
                    }
                    text={t('APP.ZONESECTION.ZONESECTION.PH_B9C0CC', {
                      defaultValue: 'Supprimer un bâtiment',
                    })}
                    className="delete-button"
                  />
                )}
              </div>
            </div>
          ))}

        {/* Zone section for Params screen */}
        {isSettings &&
          settingsDatas &&
          settingsDatas
            .sort(function (a, b) {
              if (a.localZoneId < b.localZoneId) {
                return -1;
              }

              if (a.localZoneId > b.localZoneId) {
                return 1;
              }

              return 0;
            })
            .map((building, buildingIndex) => (
              <>
                <div key={buildingIndex} className="zone-form-wrapper">
                  {/* buildings */}
                  <div className="left-part-buildings">
                    {/* buildingName */}
                    <TextField
                      id="standard-basic"
                      style={{
                        width: '55%',
                        marginTop: '3em',
                        marginLeft: '2em',
                        marginRight: '20%',
                      }}
                      defaultValue={building.name}
                      disabled={rsetFile || isRset}
                      onChange={(e) => {
                        dispatch(
                          handleControlledZones(
                            buildingIndex,
                            null,
                            `nameBuilding`,
                            e.target.value
                          )
                        );
                        updateZones(buildingIndex, null, 'nameZone', e.target.value);
                      }}
                    />
                    {/* speculativeDevelopment */}
                    {isDisplayspeculativeDevelopment(
                      currentProject.templateType
                    ) && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: '20px',
                        }}
                      >
                        <NoocoCheckbox
                          checked={building?.speculativeDevelopment || false}
                          small
                          onChange={(e) => {
                            dispatch(
                              handleControlledZones(
                                buildingIndex,
                                null,
                                `speculativeDevelopment`,
                                e.target.checked
                              )
                            );
                            updateZones(
                              buildingIndex,
                              null,
                              'speculativeDevelopment',
                              e.target.checked
                            );
                          }}
                        />
                        <div
                          style={{
                            fontSize: '0.7rem',
                            fontWeight: '500',
                            color: '#444B59',
                          }}
                        >
                          Livré en blanc
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="right-part-zones">
                    {/* zones */}
                    {building?.Zones?.sort(function (a, b) {
                      if (a.id < b.id) {
                        return -1;
                      }

                      if (a.id > b.id) {
                        return 1;
                      }

                      return 0;
                    })
                      .filter((el) => el.type !== 'buildingSite')
                      .map((section, zonesIndex) => (
                        <>
                          <div key={zonesIndex} className="zone-row-container">
                            {/* first row  zone Name*/}
                            <div
                              className="zone-row"
                              style={{ marginBottom: '8px' }}
                            >
                              <TextField
                                style={{ width: '15%' }}
                                id="standard-basic"
                                defaultValue={section.name}
                                disabled={hasCurrentProjectRset || isRset}
                                onChange={(e) => {
                                  updateZones(
                                    buildingIndex,
                                    zonesIndex,
                                    `nameZone`,
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            {/* second row zone type / occupant number / category / thermique Area srt */}
                            <div className="zone-row">
                              {/* zone type */}
                              {isDisplaySelectZoneType({
                                zoneIndex: zonesIndex,
                                templateType: currentProject.templateType,
                              }) && (
                                <div className="input-wrapper">
                                  <div className="label-textfield">
                                    {t('APP.ZONESECTION.ZONESECTION.PH_5FCE95', {
                                      defaultValue: 'Typologie de zone',
                                    })}
                                  </div>
                                  <div className="field-wrapper">
                                    <TreeSelect
                                      showSearch
                                      disabled={
                                        hasCurrentProjectRset ||
                                        isRset ||
                                        !isBuildingTypeZoneEditable(
                                          currentProject.templateType
                                        )
                                      }
                                      className="inputOfZone"
                                      value={section.BuildingTypeId}
                                      key={buildingSections}
                                      dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                      }}
                                      placeholder={t(
                                        'APP.ZONESECTION.ZONESECTION.PH_C769F8',
                                        { defaultValue: 'Typologie de zone' }
                                      )}
                                      treeDefaultExpandAll
                                      style={{ height: '44.25px!important' }}
                                      onChange={(e) => {
                                        updateZones(
                                          buildingIndex,
                                          zonesIndex,
                                          `type`,
                                          e
                                        );
                                      }}
                                    >
                                      {buildingTypesFilteredAccordingToTemplateType(
                                        currentProject.templateType,
                                        buildingTypes
                                      )
                                        .sort(
                                          (a, b) =>
                                            a.name.localeCompare(b.name) -
                                            b.name.localeCompare(a.name)
                                        )
                                        .map((type) => {
                                          if (
                                            type.Parent?.length > 0 &&
                                            currentProject.templateType.includes(
                                              TYPE_EPCM
                                            )
                                          ) {
                                            return (
                                              <TreeNode
                                                disabled
                                                key={type.id}
                                                value={type.id}
                                                title={type.name}
                                              >
                                                {type.Parent.sort(
                                                  (a, b) =>
                                                    a.name.localeCompare(b.name) -
                                                    b.name.localeCompare(a.name)
                                                ).map(
                                                  (subType: {
                                                    id: number,
                                                    name: any,
                                                  }) => {
                                                    return (
                                                      <TreeNode
                                                        key={subType.id}
                                                        value={subType.id}
                                                        title={subType.name}
                                                      />
                                                    );
                                                  }
                                                )}
                                              </TreeNode>
                                            );
                                          } else if (
                                            currentProject.templateType.includes(
                                              TYPE_RE2020
                                            )
                                          ) {
                                            if (type.iniesId) {
                                              return (
                                                <TreeNode
                                                  key={type.id}
                                                  value={type.id}
                                                  title={type.name}
                                                />
                                              );
                                            }
                                          } else if (
                                            currentProject.templateType.includes(
                                              TYPE_EPCM
                                            )
                                          ) {
                                            if (type.iniesIdEpcm) {
                                              return (
                                                <TreeNode
                                                  key={type.id}
                                                  value={type.id}
                                                  title={type.name}
                                                />
                                              );
                                            }
                                          } else {
                                            return (
                                              <TreeNode
                                                key={type.id}
                                                value={type.id}
                                                title={type.name}
                                              />
                                            );
                                          }
                                        })}
                                    </TreeSelect>
                                  </div>
                                </div>
                              )}
                              {/* occupant Number */}
                              {isDisplayOccupantNumber({
                                templateType: currentProject.templateType,
                                showAlternativeResult:
                                  currentProject.ProjectOption.showAlternativeResult,
                              }) && (
                                <div className="input-wrapper small">
                                  <div className="label-textfield">
                                    {t('APP.ZONESECTION.ZONESECTION.PH_EC6480', {
                                      defaultValue: "Nombre d'occupants",
                                    })}
                                  </div>
                                  <div className="field-wrapper">
                                    <TextField
                                      style={{ height: '44.25px' }}
                                      defaultValue={section.nbOccupants || 1}
                                      onChange={(e) => {
                                        updateZones(
                                          buildingIndex,
                                          zonesIndex,
                                          `nbOccupants`,
                                          e.target.value
                                        );
                                      }}
                                      className={`input-boarding researchBar ${
                                        hasCurrentProjectRset || isRset
                                          ? 'disabled'
                                          : ''
                                      }`}
                                      variant="outlined"
                                      disabled={hasCurrentProjectRset || isRset}
                                      required
                                    />
                                  </div>
                                </div>
                              )}
                              {/* combles */}
                              {isDisplayCombles({
                                templateType: currentProject.templateType,
                                buildingTypeId: section.BuildingTypeId,
                                showAlternativeResult:
                                  currentProject.ProjectOption.showAlternativeResult,
                              }) && (
                                <div className="input-wrapper small">
                                  <div className="label-textfield">
                                    {t('APP.ZONESECTION.ZONESECTION.PH_FC149E', {
                                      defaultValue: 'Surface des combles (m²)',
                                    })}
                                  </div>
                                  <div className="field-wrapper">
                                    <TextField
                                      style={{ height: '44.25px' }}
                                      defaultValue={section?.sCombles}
                                      onChange={(e) => {
                                        updateZones(
                                          buildingIndex,
                                          zonesIndex,
                                          `sCombles`,
                                          e.target.value
                                        );
                                      }}
                                      className={`input-boarding researchBar ${
                                        hasCurrentProjectRset || isRset
                                          ? 'disabled'
                                          : ''
                                      }`}
                                      variant="outlined"
                                      disabled={hasCurrentProjectRset || isRset}
                                      required
                                    />
                                  </div>
                                </div>
                              )}
                              {/* Category */}
                              {referenceType.includes(REFERENCE_TYPE_NAME.EPCM) && (
                                <div className="input-wrapper small">
                                  <div className="label-textfield">
                                    {t('APP.ZONESECTION.ZONESECTION.PH_5C18A6', {
                                      defaultValue: 'Catégories',
                                    })}
                                  </div>
                                  <div className="field-wrapper">
                                    <Select
                                      onChange={(e) =>
                                        updateZones(
                                          buildingIndex,
                                          zonesIndex,
                                          `category`,
                                          e.target.value
                                        )
                                      }
                                      defaultValue={section.category}
                                      key={rsetFile}
                                      style={{ height: '44.25px' }}
                                      disabled={rsetFile || isRset ? true : false}
                                      variant="outlined"
                                      className={`input-boarding researchBar ${
                                        hasCurrentProjectRset || isRset
                                          ? 'disabled'
                                          : ''
                                      }`}
                                    >
                                      <MenuItem key={'CE1'} value={'CE1'}>
                                        CE1
                                      </MenuItem>
                                      <MenuItem key={'CE2'} value={'CE2'}>
                                        CE2
                                      </MenuItem>
                                    </Select>
                                  </div>
                                </div>
                              )}
                              {/* Termique Area SRT */}
                              {referenceType.includes(REFERENCE_TYPE_NAME.EPCM) && (
                                <div className="input-wrapper small">
                                  <div className="label-textfield">
                                    {t('APP.ZONESECTION.ZONESECTION.PH_BA6B62', {
                                      defaultValue:
                                        'Surface thermique au sens de la RT(Srt)',
                                    })}
                                  </div>
                                  <div className="field-wrapper">
                                    <TextField
                                      style={{ height: '44.25px' }}
                                      defaultValue={section.srt}
                                      onChange={(e) => {
                                        updateZones(
                                          buildingIndex,
                                          zonesIndex,
                                          `srt`,
                                          e.target.value
                                        );
                                      }}
                                      className={`input-boarding researchBar ${
                                        hasCurrentProjectRset || isRset
                                          ? 'disabled'
                                          : ''
                                      }`}
                                      variant="outlined"
                                      disabled={hasCurrentProjectRset || isRset}
                                      required
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            {/* third row  referenceArea / logementNumber / emprise au sol / surface places hors PLU */}
                            <div className="zone-row">
                              {/* reference area */}
                              {isDisplayReferenceArea({
                                templateType: currentProject.templateType,
                                refUnit: refUnit || currentProject.refUnit,
                              }) && (
                                <div className="input-wrapper">
                                  <div className="label-textfield">
                                    {t('APP.ZONESECTION.ZONESECTION.PH_FAEFDB', {
                                      defaultValue: 'Surface de référence (m²)',
                                    })}
                                  </div>
                                  <div className="field-wrapper">
                                    <Controller
                                      name={`${buildingIndex}_${zonesIndex}_referenceArea`}
                                      control={control}
                                      defaultValue={section.area}
                                      rules={{
                                        validate: (value) => isNumHigherZero(value),
                                      }}
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          variant="outlined"
                                          disabled={
                                            (currentProject.templateType ===
                                              TYPE_RE2020 &&
                                              isRset) ||
                                            isRsenv
                                              ? true
                                              : false
                                          }
                                          // style={{ width: '90%' }}
                                          onChange={(e) => {
                                            setValue(
                                              `${buildingIndex}_${zonesIndex}_referenceArea`,
                                              e.target.value
                                            );
                                            setInputValue(e.target.value);
                                            updateZones(
                                              buildingIndex,
                                              zonesIndex,
                                              'area',
                                              e.target.value
                                            );
                                          }}
                                          placeholder="Surface de référence"
                                          className={`input-boarding researchBar ${
                                            currentProject.templateType ===
                                              TYPE_RE2020 || isRsenv
                                              ? 'disabled'
                                              : ''
                                          } ${
                                            errors[
                                              `${buildingIndex}_${zonesIndex}_referenceArea`
                                            ]
                                              ? 'error'
                                              : ''
                                          }`}
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                              )}
                              {/* logement Number */}
                              {isDisplayLogementNumber({
                                templateType: currentProject.templateType,
                                showAlternativeResult:
                                  currentProject.ProjectOption.showAlternativeResult,
                                buildingTypeId: section.BuildingTypeId,
                              }) && (
                                <div className="input-wrapper small">
                                  <div className="label-textfield">
                                    {t('APP.ZONESECTION.ZONESECTION.PH_3DAF10', {
                                      defaultValue: 'Nombre de logements',
                                    })}
                                  </div>
                                  <div className="field-wrapper">
                                    <TextField
                                      style={{ height: '44.25px' }}
                                      defaultValue={section.nbLgt || null}
                                      onChange={(e) =>
                                        updateZones(
                                          buildingIndex,
                                          zonesIndex,
                                          `nbLgt`,
                                          e.target.value
                                        )
                                      }
                                      className={`input-boarding researchBar ${
                                        isRset ? 'disabled' : ''
                                      }`}
                                      variant="outlined"
                                      disabled={hasCurrentProjectRset || isRset}
                                      required
                                    />
                                  </div>
                                </div>
                              )}
                              {/* emprise au sol*/}
                              {isDisplayGroundExpansion({
                                templateType: currentProject.templateType,
                                buildingTypeId: section.BuildingTypeId,
                                showAlternativeResult:
                                  currentProject.ProjectOption.showAlternativeResult,
                              }) && (
                                <div className="input-wrapper small">
                                  <div className="label-textfield">
                                    {t('APP.ZONESECTION.ZONESECTION.PH_05D228', {
                                      defaultValue: 'Emprise au sol (m²)',
                                    })}
                                  </div>
                                  <div className="field-wrapper">
                                    <TextField
                                      style={{ height: '44.25px' }}
                                      defaultValue={section.groundExpansion}
                                      onChange={(e) =>
                                        updateZones(
                                          buildingIndex,
                                          zonesIndex,
                                          `groundExpansion`,
                                          e.target.value
                                        )
                                      }
                                      className={`input-boarding researchBar `}
                                      variant="outlined"
                                    />
                                  </div>
                                </div>
                              )}
                              {/* Nombre de places en surface (hors PLU)*/}
                              {referenceType.includes(REFERENCE_TYPE_NAME.EPCM) && (
                                <div className="input-wrapper small">
                                  <div className="label-textfield">
                                    {t('APP.ZONESECTION.ZONESECTION.PH_9DCEF4', {
                                      defaultValue:
                                        'Nombre de places de parking en surface (hors PLU)',
                                    })}
                                  </div>
                                  <div className="field-wrapper">
                                    <TextField
                                      style={{ height: '44.25px' }}
                                      defaultValue={
                                        section.nbParkingSpotsSurface || null
                                      }
                                      onChange={(e) =>
                                        updateZones(
                                          buildingIndex,
                                          zonesIndex,
                                          `nbParkingSpotsSurface`,
                                          e.target.value
                                        )
                                      }
                                      className={`input-boarding researchBar`}
                                      variant="outlined"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            {/* fourth row  EPCM spotParking / undergroundPArking / parkingNumber */}
                            {referenceType.includes(REFERENCE_TYPE_NAME.EPCM) && (
                              <div className="zone-row">
                                {/* blankZone */}
                                <div className="input-wrapper"></div>
                                {/* spotParking PLU */}
                                <div className="input-wrapper small">
                                  <div className="label-textfield">
                                    {t('APP.ZONESECTION.ZONESECTION.PH_9E8D88', {
                                      defaultValue:
                                        'Nombre de places de parking en surface (PLU)',
                                    })}
                                  </div>
                                  <div className="field-wrapper">
                                    <TextField
                                      style={{ height: '44.25px' }}
                                      defaultValue={
                                        section.nbParkingSpotsSurfacePlu || null
                                      }
                                      onChange={(e) =>
                                        updateZones(
                                          buildingIndex,
                                          zonesIndex,
                                          `nbParkingSpotsSurfacePlu`,
                                          e.target.value
                                        )
                                      }
                                      className={`input-boarding researchBar`}
                                      variant="outlined"
                                    />
                                  </div>
                                </div>
                                {/* undergroundParking PLU */}
                                <div className="input-wrapper small">
                                  <div className="label-textfield">
                                    {t('APP.ZONESECTION.ZONESECTION.PH_64F2FA', {
                                      defaultValue:
                                        'Nombre de places de parking en sous-sol (PLU)',
                                    })}
                                  </div>
                                  <div className="field-wrapper">
                                    <TextField
                                      style={{ height: '44.25px' }}
                                      defaultValue={
                                        section.nbParkingSpotsUndergroundPlu || null
                                      }
                                      onChange={(e) =>
                                        updateZones(
                                          buildingIndex,
                                          zonesIndex,
                                          `nbParkingSpotsUndergroundPlu`,
                                          e.target.value
                                        )
                                      }
                                      className={`input-boarding researchBar`}
                                      variant="outlined"
                                    />
                                  </div>
                                </div>
                                {/* undergroundParking */}
                                <div className="input-wrapper small">
                                  <div className="label-textfield">
                                    {t('APP.ZONESECTION.ZONESECTION.PH_4B65C4', {
                                      defaultValue:
                                        'Nombre de places de parking en sous-sol (hors PLU)',
                                    })}
                                  </div>
                                  <div className="field-wrapper">
                                    <TextField
                                      style={{ height: '44.25px' }}
                                      defaultValue={
                                        section.nbParkingSpotsUnderground || null
                                      }
                                      onChange={(e) =>
                                        updateZones(
                                          buildingIndex,
                                          zonesIndex,
                                          `nbParkingSpotsUnderground`,
                                          e.target.value
                                        )
                                      }
                                      className={`input-boarding researchBar`}
                                      variant="outlined"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* fifth row  urbanNetwork and commonZone*/}
                            {/* isTAC first param */}
                            <div className="zone-row">
                              {section.isTAC != false &&
                                section.OEefProdPvAcAnnuel != 0 &&
                                section.OEefProdPvAnnuel != 0 && (
                                  <div className="input-wrapper small">
                                    <LabelWithTooltip
                                      label={
                                        'Production annuelle : Eef_Prod(kWh/m2/an)'
                                      }
                                      tooltip={
                                        'Production électrique totale des installations. Taux d’autoconsommation (TAC) = Autoconsommation / Production'
                                      }
                                    />
                                    <div className="field-wrapper">
                                      <TextField
                                        style={{ height: '44.25px' }}
                                        defaultValue={section.OEefProdPvAnnuel || 1}
                                        value={section.OEefProdPvAnnuel}
                                        onChange={(e) =>
                                          updateZones(
                                            buildingIndex,
                                            zonesIndex,
                                            `OEefProdPvAnnuel`,
                                            e.target.value
                                          )
                                        }
                                        className={`input-boarding researchBar ${
                                          isRset ? 'disabled' : ''
                                        }`}
                                        variant="outlined"
                                        disabled={hasCurrentProjectRset || isRset}
                                        required
                                      />
                                    </div>
                                  </div>
                                )}
                              {/* isTAC second param */}
                              {section.isTAC != false &&
                                section.OEefProdPvAcAnnuel != 0 &&
                                section.OEefProdPvAnnuel != 0 && (
                                  <div className="input-wrapper small">
                                    <LabelWithTooltip
                                      label={
                                        'Autoconsommation annuelle : Eef_Prod_AC(kWh/m2/an)'
                                      }
                                      tooltip={
                                        "Part autoconsommée de l'électricité produite par les installations. Taux d’autoconsommation annuel (TAC) = Autoconsommation / Production"
                                      }
                                    />
                                    <div className="field-wrapper">
                                      <TextField
                                        style={{ height: '44.25px' }}
                                        defaultValue={
                                          section.OEefProdPvAcAnnuel || 1
                                        }
                                        value={section.OEefProdPvAcAnnuel}
                                        onChange={(e) =>
                                          updateZones(
                                            buildingIndex,
                                            zonesIndex,
                                            `OEefProdPvAcAnnuel`,
                                            e.target.value
                                          )
                                        }
                                        className={`input-boarding researchBar ${
                                          isRset ? 'disabled' : ''
                                        }`}
                                        variant="outlined"
                                        disabled={hasCurrentProjectRset || isRset}
                                        required
                                      />
                                    </div>
                                  </div>
                                )}
                            </div>
                            <div className="zone-row">
                              {/* urbanNetwork */}
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <NoocoCheckbox
                                  disabled={hasCurrentProjectRset || isRset}
                                  defaultChecked={section.IsConnectedToUrbanNetwork}
                                  checked={section.IsConnectedToUrbanNetwork}
                                  onChange={(e) =>
                                    handleCheckboxClick(
                                      e,
                                      buildingIndex,
                                      zonesIndex,
                                      section
                                    )
                                  }
                                  className={`${isRset ? 'disabled' : ''}`}
                                />
                                <p style={{ margin: 0 }}>
                                  {t('APP.ZONESECTION.ZONESECTION.PH_0B754F', {
                                    defaultValue:
                                      'Raccordé à un réseau de chaleur urbain',
                                  })}
                                </p>
                              </div>
                              {/* isTAC */}
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <NoocoCheckbox
                                  disabled={hasCurrentProjectRset || isRset}
                                  defaultChecked={section.isTAC}
                                  checked={section.isTAC != false}
                                  onChange={(e) => {
                                    if (!e.target.checked) {
                                      if (
                                        window.confirm(
                                          'Si vous confirmez, les champs relatifs aux panneaux photovoltaïques seront remis à 1.'
                                        )
                                      ) {
                                        updateZones(
                                          buildingIndex,
                                          zonesIndex,
                                          `OEefProdPvAnnuel`,
                                          1
                                        );
                                        updateZones(
                                          buildingIndex,
                                          zonesIndex,
                                          `OEefProdPvAcAnnuel`,
                                          1
                                        );
                                        updateZones(
                                          buildingIndex,
                                          zonesIndex,
                                          `isTAC`,
                                          e.target.checked
                                        );
                                      }
                                    }
                                    updateZones(
                                      buildingIndex,
                                      zonesIndex,
                                      `isTAC`,
                                      e.target.checked
                                    );
                                  }}
                                  className={`${isRset ? 'disabled' : ''}`}
                                />
                                <p style={{ margin: 0 }}>
                                  {t('APP.ZONESECTION.ZONESECTION.PHOTOVOL', {
                                    defaultValue:
                                      'Présence de panneaux photovoltaïque',
                                  })}
                                </p>
                              </div>

                              {/* commonZone */}
                              {referenceType.includes(REFERENCE_TYPE_NAME.EPCM) && (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <NoocoCheckbox
                                    disabled={hasCurrentProjectRset}
                                    defaultChecked={section?.isSharedArea}
                                    checked={section?.isSharedArea}
                                    onChange={(e) =>
                                      updateZones(
                                        buildingIndex,
                                        zonesIndex,
                                        `isSharedArea`,
                                        e.target.checked
                                      )
                                    }
                                  />

                                  <p style={{ margin: 0 }}>
                                    {t('APP.ZONESECTION.ZONESECTION.PH_C97A8B', {
                                      defaultValue: 'Zone commune',
                                    })}
                                  </p>
                                </div>
                              )}
                              {/* Row RenovationType */}
                            </div>
                            {isDisplayRenovationType(
                              currentProject.templateType
                            ) && (
                              <div className="zone-row">
                                <div className="type-form">
                                  <p>
                                    Typologie de rénovation
                                    <Tooltip
                                      title={
                                        'Rénovation lourde = Opération immobilière impliquant l’arrêt total de l’activité de l’immeuble pendant l’exécution des travaux. Rénovation thermique = Opération impliquant un changement sur les produits de construction liés à l’enveloppe (lot 4 et/ou 6) et un changement sur les équipement influençant la performance thermique.'
                                      }
                                    >
                                      <InfoIcon style={{ height: '1.5rem' }} />
                                    </Tooltip>
                                  </p>
                                  <Select
                                    onChange={(e) => {
                                      updateZones(
                                        buildingIndex,
                                        zonesIndex,
                                        `RenovationTypeId`,
                                        e.target.value
                                      );
                                    }}
                                    value={section?.RenovationTypeId || null}
                                    key={rsetFile}
                                    style={{ width: '230px' }}
                                    variant="outlined"
                                    className={`input-boarding researchBar `}
                                  >
                                    {allGroups.renovationTypes.map(
                                      (renovationType) => {
                                        return (
                                          <MenuItem
                                            key={renovationType.id}
                                            value={renovationType.id}
                                          >
                                            {renovationType.name}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* footerZone Buttons add and delete */}
                          {isDisplayAddBuildingAndZoneButton(
                            currentProject.templateType
                          ) && (
                            <div
                              className="zones-buttons"
                              style={{ display: 'flex' }}
                            >
                              {zonesIndex === building.Zones.length - 1 && (
                                <Button
                                  disabled={hasCurrentProjectRset}
                                  additionalIcon={<ControlPointIcon />}
                                  onClick={() => {
                                    if (scrollRef.current) {
                                      dispatch(
                                        updateFormValue(
                                          'zoneScrollPosition',
                                          scrollRef.current.scrollTop
                                        )
                                      );
                                    }
                                    handleZonesSettings(
                                      buildingIndex,
                                      zonesIndex,
                                      'add',
                                      building.id
                                    );
                                  }}
                                  loading={
                                    loadingCreationZone &&
                                    buildingIndex === addBuildingIndex &&
                                    zonesIndex === addZoneIndex
                                  }
                                  text={t('APP.ZONESECTION.ZONESECTION.PH_D23981', {
                                    defaultValue: 'Ajouter une zone',
                                  })}
                                  className="save-button"
                                  style={{ marginRight: '10px' }}
                                />
                              )}
                              {zonesIndex === building.Zones.length - 1 && (
                                <Button
                                  disabled={
                                    hasCurrentProjectRset ||
                                    (settingsDatas.length === 1 &&
                                      building.Zones.length === 1)
                                  }
                                  additionalIcon={<DeleteIcon />}
                                  loading={
                                    loadingDeleteZone &&
                                    buildingIndex === addBuildingIndex &&
                                    zonesIndex === addZoneIndex
                                  }
                                  onClick={() => {
                                    if (scrollRef.current) {
                                      dispatch(
                                        updateFormValue(
                                          'zoneScrollPosition',
                                          scrollRef.current.scrollTop
                                        )
                                      );
                                    }
                                    handleZonesSettings(
                                      buildingIndex,
                                      zonesIndex,
                                      'remove',
                                      building.id
                                    );
                                  }}
                                  className="delete-button"
                                  text={t('APP.ZONESECTION.ZONESECTION.PH_C210E6', {
                                    defaultValue: 'Supprimer une zone',
                                  })}
                                  style={{ marginRight: '10px' }}
                                />
                              )}
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </div>
                {/* footer buttons add and delete building */}
                {isDisplayAddBuildingAndZoneButton(currentProject.templateType) && (
                  <div
                    className="zones-buttons"
                    style={{ display: 'flex' }}
                    onClick={(e) => e.preventDefault()}
                  >
                    {buildingIndex === settingsDatas.length - 1 && (
                      <Button
                        disabled={hasCurrentProjectRset || loadingCreationZone}
                        additionalIcon={<ControlPointIcon />}
                        onClick={() => {
                          if (scrollRef.current) {
                            dispatch(
                              updateFormValue(
                                'zoneScrollPosition',
                                scrollRef.current.scrollTop
                              )
                            );
                          }
                          handleZonesSettings(
                            buildingIndex,
                            null,
                            'add',
                            building.id
                          );
                        }}
                        loading={loadingCreationBuilding}
                        text={t('APP.ZONESECTION.ZONESECTION.PH_2F826C', {
                          defaultValue: 'Ajouter un bâtiment',
                        })}
                        className="save-button"
                        style={{ marginRight: '10px' }}
                      />
                    )}
                    {buildingIndex === settingsDatas.length - 1 && (
                      <Button
                        disabled={
                          hasCurrentProjectRset || settingsDatas.length === 1
                        }
                        additionalIcon={<DeleteIcon />}
                        onClick={() => {
                          if (scrollRef.current) {
                            dispatch(
                              updateFormValue(
                                'zoneScrollPosition',
                                scrollRef.current.scrollTop
                              )
                            );
                          }
                          handleZonesSettings(
                            buildingIndex,
                            null,
                            'remove',
                            building.id
                          );
                        }}
                        loading={loadingDeleteBuilding}
                        text={t('APP.ZONESECTION.ZONESECTION.PH_36F7A1', {
                          defaultValue: 'Supprimer un bâtiment',
                        })}
                        className="delete-button"
                        style={{ marginRight: '10px' }}
                      />
                    )}
                  </div>
                )}
              </>
            ))}
      </div>
      <Snackbar
        autoHideDuration={3000}
        open={showSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackbarMessage}
      >
        <Alert onClose={handleSnackClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ZoneSection;
