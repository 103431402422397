import { ResponsiveBar } from '@nivo/bar';
import { createProjectStructure } from '../../shared/parsingDatas';
import { filterGraphData, makeDataGraph } from '../../shared/makeDataGraph';
import { useUpdateEffect } from 'react-use';

import React, { useEffect, useState } from 'react';
import formatNumber from '../../services/mathematics/formatNumber';
import { useTranslation } from 'react-i18next';
import { ALLOTMENT_IDS } from '../../globalVariable/typeProject';

const Bar = ({
  selectedVariants,
  currentFilter,
  dataGraph,
  keysGraph,
  currentProject,
  selectedLotFilter,
  filterOptions,
  filteredGraph,
  allGroups,
  changeValueVariant,
  limitDatas,
  currentIndicator,
  currentAllotment,
  realData,
}) => {
  const [windowWidth, setWindowWidth] = useState();
  const [rerender, setRerender] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    changeValueVariant('selectedLotFilter', [
      {
        id: 1200,
        label:
          currentAllotment.id === ALLOTMENT_IDS.RE2020
            ? t('APP.VARIANT.BAR.PH_D98675', {
                defaultValue: 'Tous contributeurs',
              })
            : t('APP.VARIANT.BAR.PH_3F879E', { defaultValue: 'Tous lots' }),
        color: '#ccc',
        name: 'all',
      },
    ]);

    if (selectedVariants.length > 0) {
      let needDatas = [];

      selectedVariants.forEach((el) => {
        needDatas = [
          ...needDatas,
          createProjectStructure(
            currentAllotment,
            el.project.Sheets,
            el.zones,
            el.project,
            false
          ),
        ];
      });

      const allDatas = makeDataGraph(
        needDatas,
        currentProject,
        currentAllotment,
        currentFilter
      );

      changeValueVariant('realData', needDatas);
      changeValueVariant('dataGraph', allDatas.dataGraph);
      changeValueVariant('keysGraph', allDatas.keys);

      console.log(allDatas);

      if (filterOptions.length === 0) {
        changeValueVariant('filterOptions', [
          ...filterOptions,
          ...selectedLotFilter,
          ...allDatas.filters,
        ]);
      }
    }
  }, [selectedVariants, currentFilter]);

  useUpdateEffect(() => {
    filterGraphData(
      selectedLotFilter,
      changeValueVariant,
      dataGraph,
      currentFilter,
      realData
    );
  }, [selectedLotFilter]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setRerender(!rerender);
  }, [limitDatas, currentFilter]);

  useEffect(() => {
    if (limitDatas) {
      let limit = dataGraph.sort((a, b) => b.total - a.total).slice(0, limitDatas);

      changeValueVariant('filteredGraph', limit);
    }
  }, [limitDatas]);

  return (
    <ResponsiveBar
      width={window.screen.availHeight < 750 ? windowWidth - 200 : windowWidth - 380}
      height={
        window.screen.availHeight < 750
          ? window.screen.availHeight - 350
          : window.screen.availHeight - 400
      }
      layout="horizontal"
      key={rerender}
      margin={{ top: 100, right: 280, bottom: 0, left: 180 }}
      data={
        filteredGraph?.sort((a, b) => a.total - b.total) ||
        dataGraph?.sort((a, b) => a.total - b.total) ||
        []
      }
      indexBy={'name'}
      keys={keysGraph}
      enableLabel={false}
      tooltipFormat={(value) => {
        return `${formatNumber(value)} ${currentIndicator.unit}`;
      }}
      colors={[
        '#67c0b5',
        '#8b5aff',
        '#f76d5f',
        '#D67E8E',
        '#D69954',
        '#5ED674',
        '#74B9D6',
        '#96FFE5',
        '#D68369',
        '#9E7ED6',
        '#D6D05E',
        '#68EDA6',
        '#CA8B84',
        '#6CCFFF',
        '#72E336',
        '#FFC400',
        '#D5BBFF',
        '#FFD5BB',
        '#FE80B1',
        '#CCCCCC',
        '#D15239',
        '#5689E5',
        '#8C8C8C',
        '#F7A19A',
        '#FFDAA2',
        '#C7A0CB',
        '#FDB94D',
        '#00B274',
        '#CE59C1',
        '#FFF685',
        '#AD9268',
      ]}
      borderColor={{ from: 'color', modifiers: [['darker', 2.6]] }}
      enableGridY={false}
      enableGridX={true}
      groupMode="grouped"
      axisTop={{
        format: '~s',
      }}
      axisBottom={{
        format: '~s',
      }}
      axisLeft={{
        tickSize: 1,
        tickPadding: 10,
        tickRotation: 0,
        legend: '',
        format: (v) => {
          return v.length > 27 ? (
            <tspan>
              {v.substring(0, 27) + '...'}
              <title>{v}</title>
            </tspan>
          ) : (
            v
          );
        },
        legendPosition: 'middle',
        legendOffset: -40,
      }}
      padding={0.3}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'top-left',
          direction: 'column',
          justify: false,
          translateX: -2,
          translateY: -100,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.4]] }}
      isInteractive
      motionStiffness={170}
      motionDamping={26}
    />
  );
};

export default Bar;
