/* eslint-disable no-unused-vars */
import { Dispatch } from 'redux';
import { MapDispatchToPropsParam, MapStateToPropsParam, connect } from 'react-redux';

import ComponentsProducts from '../../app/products/ComponentsProducts';

import { updateProductsValue } from '../../actions/products';

interface IProducts {
  productView: string;
}

type State = {
  products: IProducts;
};

const mapStateToProps: MapStateToPropsParam<{}, {}, State> = (state: State) => ({
  //   productView: state.products.productView,
});
const mapDispatchToProps: MapDispatchToPropsParam<{}, {}> = (
  dispatch: Dispatch
) => ({
  updateProductsValue: (name: string, value: string) => {
    dispatch(updateProductsValue(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ComponentsProducts);
