import './scoreBar.scss';
import React from 'react';

export type ScoreBarProps = {
  scorePercentage: number;
  status?: 'error' | 'valid' | 'warning';
  width?: string;
};
const ScoreBar = ({
  scorePercentage,
  status = 'valid',
  width = '500px',
}: ScoreBarProps) => {
  return (
    <div className="scoreBarWrapper" style={{ width: width }}>
      <div
        className={`scoreBar ${status}`}
        style={{ width: `${scorePercentage}%` }}
      />
    </div>
  );
};

export default ScoreBar;
