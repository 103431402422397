import { ProjectIcDTO } from '../../projects/Report/Widgets/WidgetsDTO';
import { Tooltip } from '@material-ui/core';
import React from 'react';
import formatNumber from '../../services/mathematics/formatNumber';
import { useTranslation } from 'react-i18next';

const valid = require('../../../assets/images/synthese/valid.png');
const error = require('../../../assets/images/synthese/error.png');
const leaf = require('../../../assets/images/synthese/leaf.png');

const CustomProgressBar = (props: {
  valuePercentage: number;
  constructWidget?: boolean | undefined;
  projectIc: ProjectIcDTO;
}) => {
  const { valuePercentage: value, constructWidget, projectIc } = props;
  const fillerRelativePercentage = (100 / value) * 100;

  const { t } = useTranslation();

  // text
  const title1 = constructWidget
    ? t('APP.CUSTOMPROGRESSBAR.INDEX.PH_46EC32', {
        value: formatNumber(projectIc.Eges_pce_max1 || 0),
        defaultValue: 'EgesPCE, max1 = {value} kg éq. CO₂/m²',
      })
    : t('APP.CUSTOMPROGRESSBAR.INDEX.PH_FBA6D3', {
        value: formatNumber(projectIc.Eges_max1 || 0),
        defaultValue: 'EGESmax1 = {value} kg éq. CO₂/m²',
      });

  const title2 = constructWidget
    ? t('APP.CUSTOMPROGRESSBAR.INDEX.PH_CB6916', {
        value: formatNumber(projectIc.Eges_pce_max2 || 0),
        defaultValue: 'EgesPCE, max2 = {value} kg éq. CO₂/m²',
      })
    : t('APP.CUSTOMPROGRESSBAR.INDEX.PH_2B88F9', {
        value: formatNumber(projectIc.Eges_max2 || 0),
        defaultValue: 'EGESmax2 = {value} kg éq. CO₂/m²',
      });

  return (
    <div
      className="wrapper-progress-bar"
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={value}
      // style={{ marginTop: constructWidget ? '1.8em' : undefined}}
    >
      <div className="ec1">
        <div>
          <p>C1</p>
          <img alt="" src={leaf} />
        </div>
        <Tooltip title={title1}>
          <img src={value >= 45 ? valid : error} className=".standard-img" />
        </Tooltip>
      </div>
      <div className="ec2">
        <div>
          <p>C2</p>
          <img alt="" src={leaf} />
          <img alt="" src={leaf} />
        </div>
        <Tooltip title={title2}>
          <img src={value === 100 ? valid : error} className=".standard-img" />
        </Tooltip>
      </div>
      <div className="barContainer">
        <div className="filler" style={{ width: `${value}%` }}>
          <div
            className="fillerBackground"
            style={{ width: `${fillerRelativePercentage}%` }}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomProgressBar;
