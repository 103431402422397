import React from 'react';
import { ConstantKeysWidgets } from '..';
import { ModalNooco } from '@photocarbone/nooco-ui';
import { useDispatch, useSelector } from 'react-redux';
import { StateDTO } from '../../../../models/GeneralDTO';
import { updateCheckValue } from '../../../../../actions/report';
import histogramPreview from '../../../../../assets/images/pdfbuilder/histogram-preview.png';
import tablePreview from '../../../../../assets/images/pdfbuilder/table-preview.png';

const WidgetPreview = () => {
  const { widgetTypePreview } = useSelector((state: StateDTO) => state.report);
  const dispatch = useDispatch();
  const renderPreview = (widgetType) => {
    switch (widgetType) {
      case ConstantKeysWidgets.HISTOGRAM:
        return (
          <>
            <img
              style={{ width: '800px', marginBottom: '10px' }}
              src={histogramPreview}
              alt="histogram"
            />

            <p>
              Présentation des principaux contributeurs à l’impact sur le projet en
              fonction de l’unité du projet
            </p>
          </>
        );
      case ConstantKeysWidgets.TEXTAREA:
        return <div>Saisissez du texte à insérer dans votre PDF</div>;
      case ConstantKeysWidgets.TEXT:
        return (
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ornare
            sapien non magna semper, sit amet pellentesque mauris dictum. Etiam
            facilisis nibh pretium dui ultricies tempor. Donec eu nisi in arcu
            convallis imperdiet. Praesent ut ante viverra, sagittis ante eget,
            feugiat ipsum. Vestibulum interdum nisl ut orci cursus sollicitudin ut in
            tortor. Duis ac nunc ac nibh posuere interdum in eget sem. Vivamus
            sagittis enim mi, vitae posuere lacus iaculis at. In eu vehicula justo.
            Nulla facilisi. Curabitur facilisis massa vel tincidunt egestas. Interdum
            et malesuada fames ac ante ipsum primis in faucibus. Integer vel elit
            quis nulla sodales suscipit quis id leo. Phasellus a nisl varius, tempus
            lacus a, venenatis metus. Donec aliquam dolor quam, ac hendrerit massa
            aliquam sit amet.
          </div>
        );
      case ConstantKeysWidgets.TABLE:
        return (
          <>
            <img
              style={{ width: '800px', marginBottom: '10px' }}
              src={tablePreview}
              alt="histogram"
            />

            <p>
              Tableau récapitulatif du projet : les différentes zones et leur
              surfaces, l’impact carbone de chacune de ces zones et le seuil associé
              s’il existe.
            </p>
          </>
        );
      default:
        return <div></div>;
    }
  };

  return (
    <ModalNooco
      cancelFunction={() => dispatch(updateCheckValue('widgetTypePreview', null))}
      validateFunction={() => dispatch(updateCheckValue('widgetTypePreview', null))}
      isOpen={widgetTypePreview}
      width={1000}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {renderPreview(widgetTypePreview)}
      </div>
    </ModalNooco>
  );
};

export default WidgetPreview;
