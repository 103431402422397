export const UPDATE_CHECK_VALUE = 'UPDATE_CHECK_VALUE';
export const TOGGLE_CHECK_WIDGET = 'TOGGLE_CHECK_WIDGET';
export const GRAPH_TO_FULL_SCREEN = 'GRAPH_TO_FULL_SCREEN';
export const CALC_INDICATORS = 'CALC_INDICATORS';
export const GENERATE_RSEE = 'GENERATE_RSEE';
export const GET_RSEE_PDF = 'GET_RSEE_PDF';

export const updateCheckValue = (name, value) => ({
  type: UPDATE_CHECK_VALUE,
  name,
  value,
});

export const toggleCheckWidget = (widget, currentProjectId) => ({
  type: TOGGLE_CHECK_WIDGET,
  widget,
  currentProjectId,
});

export const graphToFullScreen = () => ({
  type: GRAPH_TO_FULL_SCREEN,
});

export const generateRsee = (projectId, projectName) => ({
  type: GENERATE_RSEE,
  projectId,
  projectName,
});

export const getRseePdf = (projectId) => ({
  type: GET_RSEE_PDF,
  projectId,
});
