type ProjectSimulatorNavigationType = {
  title: string;
  translationKey: string;
  tab: number;
};

export enum PROJECT_SIMULATOR_TABS {
  QUICK_SIMULATION = 1,
  ADVANCED_CONCEPTION = 2,
  SUMMARY = 3,
}

export const projectSimulatorNavigations: ProjectSimulatorNavigationType[] = [
  {
    title: 'Simulation rapide',
    translationKey: 'APP.PROJECT.SIMULATOR.QUICK_SIMULATION.TAB',
    tab: PROJECT_SIMULATOR_TABS.QUICK_SIMULATION,
  },
  {
    title: 'Conception avancée (facultatif)',
    translationKey: 'APP.PROJECT.SIMULATOR.ADVANCED_CONCEPTION.TAB',
    tab: PROJECT_SIMULATOR_TABS.ADVANCED_CONCEPTION,
  },
  {
    title: 'Synthèse',
    translationKey: 'APP.PROJECT.SIMULATOR.SUMMARY.TAB',
    tab: PROJECT_SIMULATOR_TABS.SUMMARY,
  },
];

export const PROJECT_SIMULATOR_TOTAL_TABS = projectSimulatorNavigations.length;

export const inputStyle = {
  padding: '8px 12px',
  fontSize: '10px',
  color: '#789ADE',
  fontWeight: '400',
};

export const selectStyle = {
  border: '1px solid #789ADE',
  padding: '8px 10px',
  fontSize: '10px',
  borderRadius: '6px',
  color: '#789ADE',
};
