import { Button } from '@photocarbone/nooco-ui';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateFormValue } from '../../../actions/projects';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';

type BottomButtonProps = {
  onConfirm: () => void;
  disabled: boolean;
  isLoading: boolean;
  onClose: Function;
  isEmptyInputInContributorList: Boolean;
};

export default function BottomButton({
  onConfirm,
  disabled,
  isLoading,
  onClose,
  isEmptyInputInContributorList,
}: BottomButtonProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <div className="bottom-button">
      <Button
        onClick={() => onClose()}
        className="close-button"
        text={t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_E59D7B', {
          defaultValue: 'Fermer',
        })}
      />
      <Tooltip
        title={
          isEmptyInputInContributorList
            ? t('APP.LIST_EQUIPMENTS.EDIT_BUTTONS.EMPTY_INPUT', {
                defaultValue:
                  'Certaines de vos lignes sont vides, veuillez les remplir avant de sauvegarder',
              })
            : ''
        }
      >
        <span>
          <Button
            onClick={() => {
              onConfirm();
              onClose();
            }}
            loading={isLoading}
            disabled={disabled}
            text={t('APP.MULTIPLEACTIONSBUTTON.INDEX.PH_401C80', {
              defaultValue: 'Confirmer',
            })}
            className="purple-button"
          />
        </span>
      </Tooltip>
    </div>
  );
}
