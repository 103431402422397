import React from 'react';
import { useTranslation } from 'react-i18next';
import './pdfPreview.scss';
import { useQuery } from 'react-query';
import { useGetPdf } from '../../../../hooks/useQuery/pdfBuilder';
import { updateCheckValue } from '../../../../actions/report';
import { useDispatch, useSelector } from 'react-redux';
import { StateDTO } from '../../../models/GeneralDTO';
import testPdf from './test.pdf'; // Import the PDF file
import ReplayIcon from '@material-ui/icons/Replay';

function PdfPreview() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { generatePdfStarter } = useSelector((state: StateDTO) => state.report);

  const { data, isLoading, isError, isSuccess } = useGetPdf();

  const renderPdfPreview = (data) => {
    return (
      <iframe
        src={testPdf}
        title="pdfPreview"
        style={{ width: '100%', height: '100%', backgroundColor: 'white' }}
      ></iframe>
    );
  };
  return (
    <div className="border-section pdfPreview-wrapper" style={{}}>
      {generatePdfStarter ? (
        <>
          <ReplayIcon
            style={{ cursor: 'pointer', top: '22px', right: '7px', color: 'white' }}
          />
          {renderPdfPreview(data)}
        </>
      ) : (
        <div className="button-preview">
          <p onClick={() => dispatch(updateCheckValue('generatePdfStarter', true))}>
            {t('APP.PDF_BUILDER.PREVIEW', { defaultValue: 'Générer une preview' })}
          </p>
        </div>
      )}
    </div>
  );
}

export default PdfPreview;
