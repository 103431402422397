import NavigationTabs from './NavigationTabs';
import QuickSimulation from './QuickSimulation/QuickSimulation';

import './projectSimulator.scss';

function ProjectSimulator() {
  return (
    <div className="project-simulator-container">
      <NavigationTabs />
      <QuickSimulation />
      {/* ADVANCED_CONCEPTION */}
      {/* SUMMARY */}
    </div>
  );
}

export default ProjectSimulator;
