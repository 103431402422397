import { useTranslation } from 'react-i18next';
import { TextField, Tooltip, MenuItem } from '@material-ui/core';

import SelectUi from '@mui/material/Select';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { inputStyle, selectStyle } from '../utils';

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#789ADE',
      borderRadius: '6px',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: '1.5px solid #789ADE',
      },
    },
    '& .MuiSelect-select': {
      padding: '8px',
      border: '1px solid #789ADE',
    },
  },
});

type FormValues = {
  usableArea: number;
  parcelArea: number;
  parkingSpaces: number;
  balcony: number;
};

function Typology() {
  const {
    control,
    formState: { errors },
    trigger,
  } = useForm<FormValues>({
    defaultValues: {
      parkingSpaces: 0,
      balcony: 0,
    },
  });
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className="form-block" style={{ marginTop: '12px', paddingBottom: '32px' }}>
      <h2 className="form-title">
        {t('APP.PROJECT.SIMULATOR.QUICK_SIMULATION.TYPOLOGY.TITLE', {
          defaultValue: 'Typologie',
        })}
      </h2>

      <hr className="line-title-separation" />

      <div
        style={{
          display: 'flex',
          paddingLeft: '24px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '16px',
          }}
        >
          <div>
            <label
              className="form-label"
              style={{
                display: 'flex',
              }}
              htmlFor="typology"
            >
              {t('APP.PROJECT.SIMULATOR.QUICK_SIMULATION.FORM.LABEL.TYPOLOGY', {
                defaultValue: 'Typologie',
              })}
            </label>
            <Tooltip title="D'autres choix seront bientôt disponible">
              <SelectUi
                color="primary"
                MenuProps={{
                  sx: {
                    '& .MuiMenuItem-root:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    },
                    '& .Mui-selected': {
                      backgroundColor: '#E1E1E6',
                    },
                  },
                }}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    borderStyle: 'none',
                  },
                  '.MuiOutlinedInput-input': selectStyle,
                }}
                name="typology"
                id="typology"
                disabled
                defaultValue="office"
                className={classes.root}
                variant="outlined"
                style={{
                  width: '50%',
                }}
              >
                {/* fake data just for the visual, it will be replaced don't pay attention */}
                <MenuItem value="office">Bureaux</MenuItem>
              </SelectUi>
            </Tooltip>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              columnGap: '40px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '20%',
              }}
            >
              <label className="form-label">
                {t('APP.PROJECT.SIMULATOR.QUICK_SIMULATION.FORM.LABEL.PARCEL_AREA', {
                  defaultValue: 'Surface parcelle (m²)',
                })}
              </label>
              <Controller
                control={control}
                name="parcelArea"
                rules={{
                  validate: (value) => !isNaN(value) && value > 0,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="250"
                    style={{ width: '85%' }}
                    variant="outlined"
                    className={classes.root}
                    onBlur={() => trigger('parcelArea')}
                    error={Boolean(errors.parcelArea)}
                    inputProps={{
                      style: inputStyle,
                    }}
                  />
                )}
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '20%',
              }}
            >
              <label className="form-label">
                {t('APP.PROJECT.SIMULATOR.QUICK_SIMULATION.FORM.USABLE_AREA', {
                  defaultValue: 'Surface utile (m²)',
                })}
              </label>
              <Controller
                control={control}
                name="usableArea"
                rules={{
                  validate: (value) => !isNaN(value) && value > 0,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="250"
                    style={{ width: '85%' }}
                    variant="outlined"
                    className={classes.root}
                    onBlur={() => trigger('usableArea')}
                    error={Boolean(errors.usableArea)}
                    inputProps={{
                      style: inputStyle,
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div style={{ display: 'flex', columnGap: '32px' }}>
            <div>
              <label className="form-label" htmlFor="parking">
                {t('APP.PROJECT.SIMULATOR.QUICK_SIMULATION.FORM.LABEL.PARKING', {
                  defaultValue: 'Stationnements',
                })}
              </label>
              <Tooltip title="D'autres choix seront bientôt disponible">
                <SelectUi
                  color="primary"
                  MenuProps={{
                    sx: {
                      '& .MuiMenuItem-root:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                      },
                      '& .Mui-selected': {
                        backgroundColor: '#E1E1E6',
                      },
                    },
                  }}
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' },
                    '.MuiOutlinedInput-input': selectStyle,
                  }}
                  name="parking"
                  id="parking"
                  disabled
                  defaultValue="outdoor"
                  className={classes.root}
                  variant="outlined"
                  style={{
                    width: '100%',
                  }}
                >
                  {/* fake data just for the visual, it will be replaced don't pay attention */}
                  <MenuItem value="outdoor">Extérieurs</MenuItem>
                </SelectUi>
              </Tooltip>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <label className="form-label">
                {t(
                  'APP.PROJECT.SIMULATOR.QUICK_SIMULATION.FORM.LABEL.PARKING_SPACES',
                  {
                    defaultValue: 'Nombre de places',
                  }
                )}
              </label>
              <Controller
                control={control}
                name="parkingSpaces"
                rules={{
                  validate: (value) => value !== '' && !isNaN(value) && value >= 0,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="-"
                    style={{ width: '40%' }}
                    variant="outlined"
                    className={classes.root}
                    onBlur={() => trigger('parkingSpaces')}
                    error={Boolean(errors.parkingSpaces)}
                    inputProps={{
                      style: {
                        ...inputStyle,
                        padding: '10px 12px',
                      },
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
            }}
          >
            <p
              style={{
                marginRight: '10px',
              }}
              className="typology-text-other-area"
            >
              {t('APP.PROJECT.SIMULATOR.QUICK_SIMULATION.TEXT.OTHER_AREA', {
                defaultValue: 'Autres surfaces',
              })}{' '}
              :
            </p>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <label className="typology-label-balcony">
                {t('APP.PROJECT.SIMULATOR.QUICK_SIMULATION.FORM.LABEL.BALCONY', {
                  defaultValue: 'Balcons (m²)',
                })}
              </label>
              <Controller
                control={control}
                name="balcony"
                rules={{
                  validate: (value) => value !== '' && !isNaN(value) && value >= 0,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    style={{ width: '50%' }}
                    variant="outlined"
                    className={classes.root}
                    onBlur={() => trigger('balcony')}
                    error={Boolean(errors.balcony)}
                    inputProps={{
                      style: inputStyle,
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Typology;
