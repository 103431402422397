import { StateDTO } from '../../../app/models/GeneralDTO';
import { createFolder } from '../../../apiCallFunctions/folder';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

export const useCreateFolder = () => {
  const { isBundleScreen } = useSelector((state: StateDTO) => state.bundle);
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => createFolder(data, isBundleScreen), {
    onSuccess: (data) => {
      // console.log('response',
      //   data);
      queryClient.invalidateQueries('foldersByCompany');
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  return mutation;
};
