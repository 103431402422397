import { StateDTO } from '../../../app/models/GeneralDTO';
import {
  createBoardingEquipment,
  deleteBoardingEquipment,
  getBoardingEquipment,
  patchBoardingEquipment,
} from '../../../apiCallFunctions/exploitation';
import { fetchProjectsList } from '../../../apiCallFunctions/project/project';
import { incrementOffset } from '../../../actions/exploitation';
import { updateFormValue } from '../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import API from '../../../app/services/API';

export const useGetBoardingEquipments = (
  id: number,
  offset: number,
  boardingActiveTab: string,
  allGroups: { type: {}[] }
) => {
  const dispatch = useDispatch();

  return useInfiniteQuery(
    ['boardingEquipment'],
    (data) => getBoardingEquipment(id, offset, boardingActiveTab, allGroups, data),
    {
      onSuccess: () => {
        dispatch(incrementOffset());
      },
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) => {
        const pageNumber = Math.ceil(lastPage.length / 20);

        if (pageNumber === 1) {
          return pages.length;
        } else {
          return undefined;
        }
      },
    }
  );
};

export const useCreateBoardingEquipment = (currentProject) => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const mutation = useMutation(createBoardingEquipment, {
    onSuccess: (data) => {
      // console.log('response',
      //   data);

      queryClient.invalidateQueries('boardingEquipment');
      dispatch(
        updateFormValue('currentProject', {
          ...currentProject,
          EmissionValues: [data.projectEmissions],
        })
      );
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  return mutation;
};

export const useDeleteBoardingEquipment = (currentProject) => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const mutation = useMutation(deleteBoardingEquipment, {
    onSuccess: (data) => {
      // console.log('response',
      //   data);
      dispatch(
        updateFormValue('currentProject', {
          ...currentProject,
          EmissionValues: [data.projectEmissions],
        })
      );
      queryClient.invalidateQueries('boardingEquipment');
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  return mutation;
};

export const usePatchBoardingEquipment = () => {
  const dispatch = useDispatch();
  const { currentProject } = useSelector((state: StateDTO) => state.projects);

  const queryClient = useQueryClient();
  const mutation = useMutation(patchBoardingEquipment, {
    onSuccess: (data) => {
      // console.log('response',
      //   data);

      queryClient.invalidateQueries('boardingEquipment');
      if (data?.projectEmissions) {
        dispatch(
          updateFormValue('currentProject', {
            ...currentProject,
            EmissionValues: [data.projectEmissions],
          })
        );
      }
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  return mutation;
};
