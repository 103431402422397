/* eslint-disable no-unused-vars */
import '../Projects.css';
import './report.scss';
import { Dropdown } from '@photocarbone/nooco-ui';
import { Modal } from 'react-bootstrap';
import AppContext from '../../AppContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BenefitsWidgets from './Widgets/BenefitsWidgets';
import BuildingConstructWidgets from './Widgets/BuildingConstructWidgets';
import BuildingEnergyWidgets from './Widgets/BuildingEnergyWidgets';
import BuildingWaterWidgets from './Widgets/BuildingWaterWidgets';
import BuildingWidgets from './Widgets/BuildingWidget';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import ConstructWidgets from './Widgets/ConstructWidgets';
import DeliverablesWidgets from './Widgets/DeliverablesWidgets';
import EnergyWidgets from './Widgets/EnergyWidgets';
import ExportRsee from './Widgets/exportRsee/ExportRsee';
import FormControl from '@material-ui/core/FormControl';
import GoalsWidgets from './Widgets/GoalsWidgets';
import HeightIcon from '@material-ui/icons/Height';
import InputLabel from '@material-ui/core/InputLabel';
import Loader from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import NoocoCheckbox from '../../shared/NoocoCheckbox';
import ProjectWidgets from './Widgets/ProjectWidgets';
import PropTypes from 'prop-types';
import RGL, { WidthProvider } from 'react-grid-layout';
import React, { useContext, useEffect, useState } from 'react';
import ResponsiveBar from '../../../containers/ResponsiveBar';
// import RsenvErrorsModal from './Widgets/exportRsee/RsenvErrorsModal';
import Select from '@material-ui/core/Select';
import SelectionWidgets from '../../../containers/SelectionWidgets';
import StockWidgets from './Widgets/StockWidgets';
import SunburstGraph from './SunburstGraph';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import UsageWidgets from './Widgets/UsageWidgets';
import WaterWidgets from './Widgets/WaterWidgets';
import ZonesWidgets from './Widgets/ZonesWidgets';

import {
  TEMPLATE_TYPE_NAME,
  TYPE_EPCM,
  TYPE_GLOBAL_MONITORING,
  TYPE_NOOCO,
  TYPE_RE2020,
} from '../../globalVariable/typeProject';
import { addBuildingSiteIds } from '../LifeCycle/LifeCycleTab';
import {
  buildingTypesMap,
  epcmBuildingTypesMap,
} from '../../services/mathematics/indicators/buildingTypesMap.js';
import { createProjectStructure } from '../../shared/parsingDatas';
// import { setDataset } from '../../../actions/projects';
import { createStructuredZonesForDropdown } from '../../shared/DropdownUiUtils.ts';
import { useLocalStorage } from 'react-use';
import _ from 'lodash';
import formatNumber from '../../services/mathematics/formatNumber';
import fullscreenIcon from '../../../assets/images/dashboard/expendDashboard.png';
import dayjs from 'dayjs';
import i18nInstance from '../../../i18n.js';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useGetProjectDataComps } from '../../../hooks/useQuery/projects/getProjectDataComps.ts';

export const getFromLocalStorage = (
  key,
  currentProjectId,
  templateType,
  zones,
  t
) => {
  let localStorage = {};

  if (global.localStorage) {
    try {
      localStorage =
        JSON.parse(global.localStorage.getItem(`rgl-${currentProjectId}`)) || {};
    } catch (e) {
      /*Ignore*/
    }
  }

  if (!localStorage[key] || localStorage[key].length === 0) {
    let baseWidget = [];

    if (templateType === TYPE_GLOBAL_MONITORING) {
      return [
        {
          name: 'Histogramme',
          checked: true,
          disabled: false,
          order: 102,
          isResizable: true,
        },
      ];
    }

    const isMultiZone =
      zones.find((zone) => zone.type === 'building').Zones.length > 1; //TODO this condition is not correct?
    const isMultiBuilding =
      zones.filter((zone) => zone.type === 'building').length > 1;
    const isParcelInProject = zones.find((zone) => zone.type === 'plot')
      ? true
      : false;

    // create individual Project or Building widget
    if (!isMultiBuilding) {
      baseWidget.push({
        name:
          templateType === TYPE_EPCM
            ? 'Bâtiment'
            : templateType === TEMPLATE_TYPE_NAME.BBCA
            ? 'Score BBCA'
            : 'Projet',
        id: currentProjectId,
        checked:
          templateType === TYPE_RE2020 ||
          templateType === TYPE_EPCM ||
          templateType === TEMPLATE_TYPE_NAME.BBCA,
        disabled: false,
        order: 1,
        isResizable: true,
        type: 'projet',
      });
    }

    let indexOrder = 2;

    zones.map((zone) => {
      if (zone.type === 'building') {
        // create wrapper building widget
        if (isMultiBuilding) {
          baseWidget.push({
            name: zone.name,
            checked: templateType === TYPE_NOOCO ? false : true,
            disabled: false,
            order: indexOrder,
            isResizable: true,
            type: 'building',
            buildingId: zone.id,
          });
        }
        // create construct, energy, water, objective widget.
        // Can be linked to wrapper building if isMultiBuilding
        baseWidget.push(
          {
            name:
              templateType !== TYPE_RE2020
                ? 'Produits de Construction et Equipements'
                : 'Construction',
            checked:
              templateType === TYPE_RE2020 ||
              templateType === TYPE_EPCM ||
              templateType === TEMPLATE_TYPE_NAME.BBCA,
            disabled: false,
            order: indexOrder + 1,
            isResizable: true,
            buildingIdLinkedTo: zone.id,
          },

          {
            name: 'Energie',
            checked:
              templateType === TYPE_RE2020 ||
              templateType === TYPE_EPCM ||
              templateType === TEMPLATE_TYPE_NAME.BBCA,
            disabled: false,
            order: indexOrder + 4, //TODO how to define id of order?
            isResizable: true,
            buildingIdLinkedTo: zone.id,
          },
          {
            name: 'Eau',
            checked:
              templateType === TYPE_RE2020 ||
              templateType === TYPE_EPCM ||
              templateType === TEMPLATE_TYPE_NAME.BBCA,
            disabled: false,
            order: indexOrder + 5,
            isResizable: true,
            buildingIdLinkedTo: zone.id,
          }
        );

        if (templateType.includes(TYPE_EPCM)) {
          baseWidget.push({
            name: 'Objectif',
            checked: true,
            disabled: false,
            order: indexOrder + 2,
            isResizable: true,
            buildingIdLinkedTo: zone.id,
          });
        }

        if (templateType.includes(TEMPLATE_TYPE_NAME.BBCA)) {
          baseWidget.push({
            name: 'Produits de Construction et Equipements non amortis',
            checked: true,
            disabled: false,
            order: indexOrder + 2,
            isResizable: true,
            buildingIdLinkedTo: zone.id,
          });
        }

        if (templateType.includes(TEMPLATE_TYPE_NAME.BBCA)) {
          baseWidget.push({
            name: 'Chantier',
            checked: true,
            disabled: false,
            order: indexOrder + 3,
            isResizable: true,
            buildingIdLinkedTo: zone.id,
          });
        }

        indexOrder = indexOrder + 5;
      }
    });

    // histo, deliverable, export, optimizaion, risks, variant,
    baseWidget.push(
      {
        name: 'Histogramme',
        checked: true,
        disabled: false,
        order: 102,
        isResizable: true,
      },
      // {
      //   name: 'Stockage de carbone biogénique',
      //   checked: templateType === TYPE_RE2020,
      //   disabled: false,
      //   order: 103,
      //   isResizable: true,
      // },

      {
        name: 'Livrables',
        checked: true,
        disabled: false,
        order: 105,
        isResizable: true,
      },
      {
        name: 'Export Bexp',
        checked: false,
        disabled: true,
        order: 110,
        isResizable: true,
      },
      {
        name: 'Optimisation des matériaux/équipements',
        checked: false,
        disabled: true,
        order: 111,
        isResizable: true,
      },
      {
        name: 'Principaux générateurs de risque',
        checked: false,
        disabled: true,
        order: 112,
        isResizable: true,
      },
      {
        name: 'Comparaison de variante',
        checked: false,
        disabled: true,
        order: 113,
        isResizable: true,
      },
      {
        name: 'Comparaison au forfait',
        checked: false,
        disabled: true,
        order: 114,
        isResizable: true,
      }
    );

    // if (templateType.includes(TYPE_RE2020)) {
    // zone and composant widget
    baseWidget.push(
      {
        name: t('APP.REPORT.ZONE_DISTRIBUTION', {
          defaultValue: 'Répartition par zone',
        }),
        checked: isMultiZone || isMultiBuilding || isParcelInProject ? true : false,
        disabled: false,
        order: 101,
        isResizable: true,
      },
      {
        name: t('APP.REPORT.DATA_USAGE_COMPONENTS', {
          defaultValue: 'Usage de données - Composants',
        }),
        checked: true,
        disabled: false,
        order: 104,
        isResizable: true,
      }
    );

    // stock biogenic widget
    if (!templateType.includes(TYPE_EPCM)) {
      baseWidget.push({
        name: 'Stockage de carbone biogénique',
        checked:
          templateType.includes(TYPE_RE2020) || templateType.includes(TYPE_EPCM)
            ? true
            : false,
        disabled: false,
        order: 103,
        isResizable: true,
      });
    }
    // }

    if (templateType.includes(TYPE_EPCM)) {
      baseWidget.push({
        name: 'Bénéfices et charges',
        checked: true,
        disabled: false,
        order: 106,
        isResizable: true,
      });
    }

    return baseWidget;
  } else {
    // updateCheckValue('widgets',  ls[key]);

    return localStorage[key];
  }
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Report = ({
  project,
  rates,
  updateCheckValue,
  masterDatas,
  currentIndicator,
  parsedProject,
  fullScreen,
  graphToFullScreen,
  isMenuOpen,
  currentProject,
  selectedFilterLot,
  setTabValue,
  zones,
  generateRsee,
  downloadRsee,
  downloadRseePdf,
  structureGraph,
  projectAllot,
  currentAllotment,
  widgets,
  getRseePdf,
  setDataset,
}) => {
  const ReactGridLayout = WidthProvider(RGL);
  const { t } = useTranslation();
  const [originalLayout, setOriginalLayout] = useState(
    getFromLocalStorage(
      'layout',
      currentProject.id,
      currentProject.templateType,
      zones,
      t
    )
  );

  const projectIc =
    currentProject.Ic[currentIndicator.id === 26 ? 1 : currentIndicator.id];
  const sheets = project.Sheets;
  const projectDdv = project.ddv;
  const [lvl, setLvl] = useState(
    currentAllotment.id === 2 ? 'last' : currentAllotment.id === 34 ? 'last' : 0
  );
  const [sortedBy, setSortedBy] = useState('impact');
  const [graphType, setGraphType] = useState(0);
  const [data, setData] = useState([]);
  const [secondData, setSecondData] = useState([]);
  const [seuilsOk, setSeuilsOk] = useState(true);
  const [downloadPdf, setDownloadPdf] = useState(false);
  const [freezeBar, setFreezeBar] = useState(false);
  const [openedPopooverId, setOpenedPopoverId] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState(null);
  const [layout, setLayout] = useState(originalLayout);
  const [show, setShow] = useState(false);
  const [resetLocalStorage, setResetLocalStorage] = useState(false);
  // const [selectedZoneIdsForGraph, setSelectedZoneIdsForGraph] = useState([]);
  const [selectedZoneIdForGraph, setSelectedZoneIdForGraph] = useState('project');
  const [structuredZonesForGraphDropdown, setStructuredZonesForGraphDropdown] =
    useState(createStructuredZonesForDropdown({ zones }));
  const [
    selectedZoneIdForGraphFromLocalStorage,
    setSelectedZoneIdForGraphFromLocalStorage,
  ] = useLocalStorage(`selectedZonesIds-report-projectId-${project.id}`);
  const [filteredAllots, setFilteredAllots] = useState(projectAllot.dataset);
  const { data: projectDataComps } = useGetProjectDataComps();

  const ctx = useContext(AppContext);
  const rseeText = project.isRset ? 'RSEE' : 'RSEnv';
  const isCertifiedMode =
    currentProject.templateType === TYPE_RE2020 ||
    currentProject.templateType === TYPE_EPCM;
  const [isExportEpcmDateValid, setIsExportEpcmDateValid] = useState(true);
  const handleOnChangeSelectedZonesForGraph = (zones) => {
    setSelectedZoneIdForGraph(zones);
  };

  useEffect(() => {
    if (selectedZoneIdForGraphFromLocalStorage) {
      setSelectedZoneIdForGraph(selectedZoneIdForGraphFromLocalStorage);
    }

    if (originalLayout) {
      updateCheckValue('widgets', originalLayout);
      setLayout(originalLayout);
    }
  }, []);

  useEffect(() => {
    setSelectedZoneIdForGraphFromLocalStorage(selectedZoneIdForGraph);
    updateCheckValue('selectedFilterLot', [
      { id: 1200, label: 'Tous lots', color: '#ccc', name: 'all' },
    ]);

    let { validZonesIds, referenceArea } = addBuildingSiteIds(
      selectedZoneIdForGraph,
      parsedProject.zones,
      currentProject.area
    );

    const sheetsFiltered = currentProject.Sheets.filter((sheet) =>
      validZonesIds.includes(sheet.ProjectSheet.ZoneId)
    );
    const zonesFiltered = parsedProject.zones.filter((zone) =>
      validZonesIds.includes(zone.id)
    );

    const copyDataTest = createProjectStructure(
      currentAllotment,
      sheetsFiltered,
      zonesFiltered,
      project,
      false,
      referenceArea
    );

    setDataset(copyDataTest);
  }, [selectedZoneIdForGraph]);

  useEffect(() => {
    if (resetLocalStorage) {
      setLayout(undefined);
      setOriginalLayout(
        getFromLocalStorage(
          'layout',
          currentProject.id,
          currentProject.templateType,
          zones,
          t
        )
      );

      if (originalLayout) {
        updateCheckValue('widgets', originalLayout);
      }

      setResetLocalStorage(false);
    }
  }, [resetLocalStorage]);

  useEffect(() => {
    if (currentAllotment.id === 2 && projectAllot.dataset) {
      const composant = projectAllot.dataset.children.find(
        (el) => el.label === 'Composant'
      );

      if (composant) {
        updateCheckValue('selectedFilterLot', [composant]);
      }
    } else {
      updateCheckValue('selectedFilterLot', [
        { id: 1200, label: 'Tous lots', color: '#ccc', name: 'all' },
      ]);
    }
  }, [currentAllotment, projectAllot.dataset]);

  useEffect(() => {
    setFreezeBar(true);

    if (!fullScreen) {
      setTimeout(
        () => {
          setFreezeBar(false);
        },
        currentProject.Sheets.length > 100 ? 400 : 300
      );
    } else {
      setTimeout(
        () => {
          setFreezeBar(false);
        },
        currentProject.Sheets.length > 100 ? 350 : 200
      );
    }
  }, [isMenuOpen]);

  useEffect(() => {
    checkIfSeuilsCorrect();

    var colorsPalette = [
      '#D67E8E',
      '#D69954',
      '#5ED674',
      '#74B9D6',
      '#96FFE5',
      '#D68369',
      '#9E7ED6',
      '#D6D05E',
      '#68EDA6',
      '#CA8B84',
      '#6CCFFF',
      '#72E336',
      '#FFC400',
      '#D5BBFF',
      '#FFD5BB',
      '#FE80B1',
      '#CCCCCC',
      '#D15239',
      '#5689E5',
      '#8C8C8C',
      '#F7A19A',
      '#FFDAA2',
      '#C7A0CB',
      '#FDB94D',
      '#00B274',
      '#CE59C1',
      '#FFF685',
      '#AD9268',
    ];

    if (parsedProject.zones) {
      let data = [];
      let count = 0;

      for (let i = 0; i < parsedProject.zones.length; i++) {
        const currentZone = parsedProject.zones[i];
        const value =
          currentZone.Ic[currentIndicator.id === 26 ? 1 : currentIndicator.id]
            .TotalZoneACV;
        const parentZone = parsedProject.zones.find(
          (el) => el.id === currentZone.ParentId
        );

        if (
          (!parentZone && currentZone.type !== 'plot') ||
          currentZone.type === 'building'
        ) {
          continue;
        }

        let subDat = {
          id:
            currentZone.type === 'zone' ||
            (currentZone.type === 'buildingSite' && parentZone.type == 'plot')
              ? `${parentZone?.name} - ${currentZone?.name}`
              : currentZone.type === 'buildingSite' && parentZone.type === 'zone'
              ? `${
                  parsedProject.zones.find((el) => el.id === parentZone.ParentId)
                    ?.name
                } ${parentZone?.name} (${currentZone?.name})`
              : `${currentZone?.name}`,
          label:
            currentZone.type === 'zone'
              ? `${parentZone?.name} - ${currentZone?.name}`
              : currentZone.type === 'buildingSite' && parentZone.type === 'zone'
              ? `${
                  parsedProject.zones.find((el) => el.id === parentZone.ParentId)
                    ?.name
                } - ${parentZone?.name} (${currentZone?.name})`
              : currentZone.type === 'buildingSite' && parentZone.type == 'plot'
              ? `${parentZone?.name} (${currentZone?.name})`
              : `${currentZone?.name}`,
          value: value > 1000 ? value?.toFixed(2) : value ? value : '',
          color: colorsPalette[i],
        };

        data = [...data, subDat];
        count += value;
      }

      data = [...data, { id: 'count', count: count }];

      setData(data);

      const secondData = [
        {
          id: 'DED',
          label: 'DED',
          value: projectIc.TotDedUdd * 100,
          color: '#363e63',
        },
        {
          id: 'PEP/FDES',
          label: 'PEP/FDES',
          value: projectIc.TotPepFdesUdd * 100,
          color: '#43ddc4',
        },
        {
          id: 'Config',
          label: t('APP.REPORT.REPORT.PH_B2E5B0', {
            defaultValue: 'Fiches configurées',
          }),
          value: projectIc.TotFcUdd * 100,
          color: '#E8A838',
        },
        {
          id: 'Calcul Nooco',
          label: t('APP.REPORT.REPORT.PH_F3B2FA', {
            defaultValue: 'Calcul Nooco',
          }),
          value: projectIc.TotNoocoUdd * 100,
          color: '#799fd8',
        },
        {
          id: 'DEC',
          label: 'DEC',
          value: projectIc.TotDecUdd * 100,
          color: '#8e67ff',
        },
      ];

      setSecondData(secondData);
    }
  }, [parsedProject.zones, project]);

  const handleChange = (event) => {
    setLvl(event.target.value);
  };
  const handleChangeSortedBy = (event) => {
    setSortedBy(event.target.value);
  };
  const handleChangeGraph = (event) => {
    setGraphType(event.target.value);
  };

  const renderGraph = () => {
    return (
      <ResponsiveBar
        setTabValue={setTabValue}
        selectedFilterLot={selectedFilterLot}
        area={project.area}
        currentIndicator={currentIndicator}
        masterDatas={masterDatas}
        dat={sheets}
        level={lvl}
        sortedBy={sortedBy}
        projectDdv={projectDdv}
        project={project}
        selectedZoneIdForGraph={selectedZoneIdForGraph}
      />
    );
  };

  const checkZoneValidityForThresholds = (zone, templateType, permitDate) => {
    let thresholdDate;

    if (
      zone.BuildingType.id === buildingTypesMap.MAISON ||
      zone.BuildingType.id === buildingTypesMap.LOGEMENT_COL
    ) {
      thresholdDate = '01/01/2022';
    } else if (
      zone.BuildingType.id === buildingTypesMap.BUREAUX ||
      zone.BuildingType.id === buildingTypesMap.ENSEIGNEMENT_PRIMAIRE ||
      (templateType === TYPE_RE2020
        ? zone.BuildingType.id === buildingTypesMap.ENSEIGNEMENT_SECONDAIRE
        : zone.BuildingType.id ===
            epcmBuildingTypesMap.ENSEIGNEMENT_SECONDAIRE_JOUR ||
          zone.BuildingType.id === epcmBuildingTypesMap.ENSEIGNEMENT_SECONDAIRE_NUIT)
    ) {
      thresholdDate = '01/07/2022';
    } else if (templateType === TYPE_EPCM) {
      return true;
    } else {
      return false;
    }

    if (templateType === TYPE_RE2020) {
      return (
        dayjs(permitDate).isSame(dayjs(thresholdDate), 'day') ||
        dayjs(permitDate).isAfter(dayjs(thresholdDate))
      );
    } else if (templateType === TYPE_EPCM) {
      setIsExportEpcmDateValid(
        dayjs(permitDate).isBefore(dayjs(thresholdDate).format('L'))
      );

      return dayjs(permitDate).isBefore(dayjs(thresholdDate).format('L'));
    }
  };

  const checkIfSeuilsCorrect = () => {
    if (
      currentProject.templateType !== TYPE_RE2020 &&
      currentProject.templateType !== TYPE_EPCM
    ) {
      return setSeuilsOk(false);
    }

    for (const zone of parsedProject.zones) {
      if (zone.type !== 'zone') continue;

      if (
        !checkZoneValidityForThresholds(
          zone,
          currentProject.templateType,
          currentProject.permitDate
        )
      ) {
        return setSeuilsOk(false);
      }
    }

    setSeuilsOk(true);
  };

  const generatePDF = async () => {
    const res = await ctx.api.postProjectPDF(project.id);

    // The Base64 string of a simple PDF file
    var b64 = res.data;

    var link = document.createElement('a');

    link.innerHTML = 'Download PDF file';
    link.download = `Nooco-${project.name}.pdf`;
    link.href = 'data:application/octet-stream;base64,' + b64;
    // document.body.appendChild(link);
    link.click();

    // wait until pfd is download
    setTimeout(() => {
      setDownloadPdf(false);
    }, 500);
  };

  const handleLots = (lot) => {
    let allIds = null;

    if (
      lot.find((el) => el.id === 1200) &&
      selectedFilterLot.find((el) => el.id === 1200) &&
      lot.length === 1
    ) {
      allIds = [];
    } else if (selectedFilterLot.find((el) => el.id === 1200) && lot.length > 1) {
      allIds = lot.filter((el) => el.id !== 1200);
    } else if (
      (!selectedFilterLot.find((el) => el.id === 1200) &&
        lot.find((el) => el.id === 1200) &&
        lot.length > 1) ||
      lot.length === 0
    ) {
      allIds = [
        {
          id: 1200,
          label:
            currentAllotment.id !== 2
              ? t('APP.REPORT.REPORT.PH_32C0CB', { defaultValue: 'Tous lots' })
              : t('APP.REPORT.REPORT.PH_2D163E', {
                  defaultValue: 'Tous contributeurs',
                }),
          color: '#ccc',
          name: 'all',
        },
      ];
    }

    updateCheckValue('selectedFilterLot', allIds || lot);
  };

  const handlePopoverOpen = (event, id) => {
    setOpenedPopoverId(id);
    setAnchorEl(event.target);
  };

  const handlePopoverClose = (event, id) => {
    setOpenedPopoverId(null);
    setAnchorEl(null);
  };

  const renderPopoverBody = () => {
    if (!openedPopooverId) return null;

    const key = openedPopooverId === 'cons' ? 'IcConstructionMax' : 'IcEnergie';

    return zones.map((zone) => {
      if (zone.type === 'zone') {
        const comp = zone.Ic[currentIndicator.id][key + 'Comp'];

        return (
          <div key={zone.name} style={{ padding: '10px' }}>
            <h6>{zone.name}</h6>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  fontSize: '2px',
                  flex: 1,
                  flexDirection: 'row',
                }}
              >
                <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{key} : </p>
                <p style={{ fontSize: '10px', marginLeft: '10px' }}>
                  {formatNumber(zone.Ic[currentIndicator.id][key])}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  fontSize: '2px',
                  flex: 1,
                  flexDirection: 'row',
                }}
              >
                <p style={{ fontSize: '10px', fontWeight: 'bold' }}>
                  {key === 'IcEnergie' ? 'IcEnergieMax' : 'IcConstruction'} :
                </p>
                <p style={{ fontSize: '10px' }}>
                  {formatNumber(
                    zone.Ic[currentIndicator.id][
                      key === 'IcEnergie' ? 'IcEnergieMax' : 'IcConstruction'
                    ]
                  )}
                </p>
                bonsoir
              </div>
              {key === 'IcConstructionMax' ? (
                <div
                  style={{
                    display: 'flex',
                    fontSize: '2px',
                    flex: 1,
                    flexDirection: 'row',
                  }}
                >
                  <p style={{ fontSize: '10px', fontWeight: 'bold' }}>IcDed : </p>
                  <p style={{ fontSize: '10px' }}>
                    {formatNumber(zone.Ic[currentIndicator.id]['IcDed'])}
                  </p>
                </div>
              ) : (
                ''
              )}

              {Object.keys(comp).map((keyName, i) => (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    fontSize: '2px',
                    flex: 1,
                    flexDirection: 'row',
                  }}
                >
                  <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{keyName} :</p>
                  <p style={{ fontSize: '10px' }}> {formatNumber(comp[keyName])} </p>
                </div>
              ))}
            </div>
          </div>
        );
      }
    });
  };

  const saveToLS = (key, widgetsValuesFromLib, widgets) => {
    if (global.localStorage && widgetsValuesFromLib.length > 0) {
      let tempWidgetsValuesFromLib = _.cloneDeep(widgetsValuesFromLib);

      const MergedWidgetsAndGridLayoutData = widgets.map((widget) => {
        const widgetMatchLayoutData = tempWidgetsValuesFromLib.find(
          (widgetFromLib) => widgetFromLib.i == widget.order
        );

        if (widgetMatchLayoutData) {
          return { ...widget, ...widgetMatchLayoutData };
        } else {
          return widget;
        }
      });

      global.localStorage.setItem(
        `rgl-${currentProject.id}`,
        JSON.stringify({
          [key]: MergedWidgetsAndGridLayoutData,
        })
      );

      updateCheckValue('widgets', MergedWidgetsAndGridLayoutData);
    }
  };

  const projectWidget = widgets?.find((widget) => widget.type === 'projet');
  const histogramWidget = widgets?.find((widget) => widget.name === 'Histogramme');
  const storageWidget = widgets?.find(
    (widget) => widget.name === 'Stockage de carbone biogénique'
  );
  const deliverableWidget = widgets?.find((widget) => widget.name === 'Livrables');

  const zoneDistributionWidget = widgets?.find(
    (widget) => widget.name === 'Répartition par zone'
  );
  const useDataComponantWidget = widgets?.find(
    (widget) => widget.name === 'Usage de données - Composants'
  );
  const benefitsAndChargesWidget = widgets?.find(
    (widget) => widget.name === 'Bénéfices et charges'
  );
  const buildingWidgets = widgets?.filter((widget) => widget.type === 'building');
  const constructionWidgets = widgets?.filter(
    (widget) =>
      widget.name === 'Construction' ||
      widget.name === 'Produits de Construction et Equipements'
  );
  const constructionNoneAmortizedWidgets = widgets?.filter(
    (widget) => widget.name === 'Produits de Construction et Equipements non amortis'
  );
  const constructionSiteWidget = widgets?.filter(
    (widget) => widget.name === 'Chantier'
  );
  const energyWidgets = widgets?.filter((widget) => widget.name === 'Energie');
  const objectiveWidgets = widgets?.filter((widget) => widget.name === 'Objectif');
  const waterWidgets = widgets?.filter((widget) => widget.name === 'Eau');
  const isMultiBuilding =
    zones.filter((zone) => zone.type === 'building').length > 1;

  let tooltipTitle = [
    t('APP.REPORT.REPORT.PH_AB734D', {
      defaultValue: 'Impact : Visualiser l’impact de vos produits',
    }),
    <br />,
    <br />,
    t('APP.REPORT.REPORT.PH_5BB9FC', {
      defaultValue:
        "Potentiel d’optimisation : Visualiser le potentiel d’optimisation de vos produits obtenu selon la méthode de calcul statique en sélectionnant les produits du marché les plus vertueux dans chaque famille d'équipement, en respectant les caractéristiques choisies",
    }),
  ];

  return (
    <>
      {/* <Modal show={show} onHide={() => setShow(false)} backdrop="static" centered >
        <RsenvErrorsModal setShow={setShow}/>
      </Modal> */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        centered
        className="exportRseeModal"
      >
        <ExportRsee setShow={setShow} />
      </Modal>
      {fullScreen && (
        <div className="fullscreenGraph">
          <div className="filterOnDashboard">
            <FormControl style={{ marginRight: '1rem', width: 150 }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ float: 'right', width: 600 }}
              >
                {t('APP.REPORT.REPORT.PH_72410E', {
                  defaultValue: 'Niveau de filtre',
                })}
              </InputLabel>
              {currentAllotment.id === 1 && (
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={lvl}
                  onChange={handleChange}
                >
                  <MenuItem value={3}>Lot</MenuItem>
                  <MenuItem value={2}>Fonction</MenuItem>
                  <MenuItem value={1}>Categorie</MenuItem>
                  <MenuItem value={0}>Materiel</MenuItem>
                </Select>
              )}

              {currentAllotment.id === 2 && (
                <>
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={lvl}
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>Contributeurs</MenuItem>
                    <MenuItem value={1}>Sous-contributeurs</MenuItem>
                    {/* {selectedFilterLot.length === 1 && selectedFilterLot.find((el) => el.label === "Composant") && (
                  <MenuItem value={'last'}>Sous-sous-contributeurs</MenuItem>
                  )} */}
                    <MenuItem value={'last'}>Sous-sous-contributeurs</MenuItem>-
                  </Select>
                </>
              )}

              {currentAllotment.id === 34 && (
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={lvl}
                  onChange={handleChange}
                >
                  <MenuItem value={0}>Contributeurs</MenuItem>
                  <MenuItem value={1}>Sous-contributeurs</MenuItem>
                  {selectedFilterLot.length === 1 && (
                    <MenuItem value={'last'}>Sous-sous-contributeurs</MenuItem>
                  )}
                </Select>
              )}
            </FormControl>
            <Tooltip title={tooltipTitle}>
              <FormControl style={{ width: 150 }}>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ float: 'right', width: 600 }}
                >
                  {t('APP.REPORT.REPORT.PH_F5303A', {
                    defaultValue: 'Trier par',
                  })}
                </InputLabel>
                {currentAllotment.id === 2 && (
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sortedBy}
                    onChange={handleChangeSortedBy}
                  >
                    <MenuItem value={'potentiel'}>
                      {t('APP.REPORT.REPORT.PH_1CDD04', {
                        defaultValue: "Potentiel d'optimisation",
                      })}
                    </MenuItem>
                    y<MenuItem value={'impact'}>Impact</MenuItem>
                  </Select>
                )}

                {currentAllotment.id === 1 && (
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sortedBy}
                    onChange={handleChangeSortedBy}
                  >
                    <MenuItem value={'potentiel'}>
                      {t('APP.REPORT.REPORT.PH_2B44D8', {
                        defaultValue: "Potentiel d'optimisation",
                      })}
                    </MenuItem>
                    y<MenuItem value={'impact'}>Impact</MenuItem>
                  </Select>
                )}

                {currentAllotment.id === 34 && (
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sortedBy}
                    onChange={handleChangeSortedBy}
                  >
                    <MenuItem value={'potentiel'}>
                      {t('APP.REPORT.REPORT.PH_79ABC5', {
                        defaultValue: "Potentiel d'optimisation",
                      })}
                    </MenuItem>
                    y<MenuItem value={'impact'}>Impact</MenuItem>
                  </Select>
                )}
              </FormControl>
            </Tooltip>
            <Autocomplete
              multiple
              style={{ marginLeft: '1em' }}
              id="checkboxes-tags-demo"
              value={selectedFilterLot}
              options={[
                {
                  id: 1200,
                  label:
                    currentAllotment.id !== 2 ? 'Tous lots' : 'Tous contributeurs',
                  color: '#ccc',
                  name: 'all',
                },
                ...projectAllot.dataset.children,
              ]}
              noOptionsText={t('APP.REPORT.REPORT.PH_044D41', {
                defaultValue: 'Aucun résultat trouvé',
              })}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                handleLots([...newValue]);
              }}
              size={'small'}
              getOptionLabel={(option) => option.label}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    style={{
                      background: `${option.color}`,
                      fontWeight: '600',
                      fontSize: window.screen.availHeight < 950 ? '0.75rem' : '11px',
                      color: 'white',
                      borderRadius: '0.25rem',
                      border: 'none',
                      height: '27px',
                    }}
                    key={option.name}
                    variant="outlined"
                    label={option.label}
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderOption={(option, { selected }) => {
                if (selectedFilterLot.find((el) => el.id === 1200)) {
                  selected = true;
                }

                return (
                  <React.Fragment>
                    <NoocoCheckbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />

                    {option.label}
                  </React.Fragment>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Lots"
                  variant="outlined"
                  className="researchBar"
                />
              )}
            />
            <div style={{ display: 'flex', alignItems: 'flexStart' }}>
              <Dropdown
                value={selectedZoneIdForGraph}
                options={structuredZonesForGraphDropdown}
                style={{
                  borderRadius: '15px',
                  width: '350px',
                  marginLeft: '1em',
                  maxHeight: '45px',
                  minHeight: '45px',
                }}
                treeCheckable={false}
                multiple={false}
                treeNodeFilterProp="title"
                onSelect={(e) => {
                  // console.log('onSelect',
                  //   e);
                }}
                onChange={(e) => {
                  handleOnChangeSelectedZonesForGraph(e);
                }}
                treeDefaultExpandAll={true}
                allowClear={false}
                placeholder={t('APP.REPORT.REPORT.PH_2C99D9', {
                  defaultValue: 'Sélectionner des zones',
                })}
              />
            </div>
          </div>

          {!freezeBar && (
            <div className="report-synthese-fullscreen" key={currentAllotment}>
              <CloseIcon
                style={{
                  position: 'absolute',
                  display: 'inline-block',
                  right: '80px',
                  cursor: 'pointer',
                  zIndex: '3',
                  width: '25px',
                  height: '25px',
                }}
                onClick={graphToFullScreen}
              />

              {renderGraph()}
            </div>
          )}

          {freezeBar && (
            <div
              style={{
                color: '#73b7ec',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '99.5%',
              }}
            >
              <Loader
                variant="indeterminate"
                disableShrink
                style={{ color: '#73b7ec', display: 'block' }}
                size={40}
                thickness={4}
              />
            </div>
          )}
        </div>
      )}

      {parsedProject.zones && parsedProject.zones.length > 0 && (
        <div
          className="report-container"
          style={
            fullScreen
              ? { opacity: 0, transition: '400ms' }
              : { transition: '400ms' }
          }
        >
          <>
            <SelectionWidgets
              isMultiBuilding={isMultiBuilding}
              setResetLocalStorage={setResetLocalStorage}
            />

            {widgets?.length > 0 && (
              <>
                <ReactGridLayout
                  className={'layout'}
                  cols={10}
                  // resizeHandle={<HeightIcon className="icon-resize" />}
                  layout={layout}
                  rowHeight={12}
                  isDraggable={false}
                  isResizable={false}
                  onLayoutChange={(newLayout) => {
                    saveToLS('layout', newLayout, [...widgets]);
                    setLayout(newLayout);
                  }}
                >
                  {/* if only one building */}
                  {projectWidget?.checked && !isMultiBuilding && (
                    <div
                      key={projectWidget.order.toString()}
                      data-grid={{
                        w: 3,
                        h: 10,
                        x: 0,
                        y: 0,
                        i: projectWidget.order.toString(),
                      }}
                      className="container-grid"
                    >
                      <ProjectWidgets
                        currentIndicator={currentIndicator}
                        currentProject={currentProject}
                        projectIc={projectIc}
                        zones={parsedProject.zones}
                        currentAllotment={currentAllotment}
                        projectDataComps={projectDataComps}
                      />
                    </div>
                  )}
                  {/* PCE BBCA */}
                  {!isMultiBuilding &&
                    constructionWidgets?.map((construction) => {
                      if (construction.checked)
                        return (
                          <div
                            key={construction.order.toString()}
                            data-grid={{
                              w: currentProject.templateType.includes(
                                TEMPLATE_TYPE_NAME.BBCA
                              )
                                ? 1.5
                                : 3,
                              h: 10,
                              x: 3,
                              y: 0,
                              i: construction.order.toString(),
                            }}
                            className="container-grid"
                          >
                            {/* //TODO FIND LES WIDGETS ENERGY ... DONT buildingIdLinkedTo est le même que le bâtimen
                             */}
                            <ConstructWidgets
                              renderPopoverBody={renderPopoverBody}
                              seuilsOk={seuilsOk}
                              handlePopoverClose={handlePopoverClose}
                              handlePopoverOpen={handlePopoverOpen}
                              openedPopooverId={openedPopooverId}
                              currentIndicator={currentIndicator}
                              currentAllotment={currentAllotment}
                              currentProject={currentProject}
                              projectIc={projectIc}
                              zones={parsedProject.zones}
                              icKey={'EgesPce'}
                            />
                          </div>
                        );
                    })}
                  {/* PCENA BBCA */}
                  {!isMultiBuilding &&
                    currentProject.templateType?.includes(TEMPLATE_TYPE_NAME.BBCA) &&
                    constructionNoneAmortizedWidgets?.map((construction) => {
                      if (construction.checked)
                        return (
                          <div
                            key={construction.order.toString()}
                            data-grid={{
                              w: 1.5,
                              h: 10,
                              x: 4.5,
                              y: 0,
                              i: construction.order.toString(),
                            }}
                            className="container-grid"
                          >
                            {/* //TODO FIND LES WIDGETS ENERGY ... DONT buildingIdLinkedTo est le même que le bâtimen
                             */}
                            <ConstructWidgets
                              renderPopoverBody={renderPopoverBody}
                              seuilsOk={seuilsOk}
                              handlePopoverClose={handlePopoverClose}
                              handlePopoverOpen={handlePopoverOpen}
                              openedPopooverId={openedPopooverId}
                              currentIndicator={currentIndicator}
                              currentProject={currentProject}
                              currentAllotment={currentAllotment}
                              projectIc={projectIc}
                              zones={parsedProject.zones}
                              title={
                                'Produits de Construction et Equipements non amortis'
                              }
                              icKey={'EgesPceNa'}
                            />
                          </div>
                        );
                    })}
                  {/* worksite BBCA*/}
                  {!isMultiBuilding &&
                    currentProject.templateType?.includes(TEMPLATE_TYPE_NAME.BBCA) &&
                    constructionSiteWidget?.map((construction) => {
                      if (construction.checked)
                        return (
                          <div
                            key={construction.order.toString()}
                            data-grid={{
                              w: 1.5,
                              h: 10,
                              x: 6,
                              y: 0,
                              i: construction.order.toString(),
                            }}
                            className="container-grid"
                          >
                            {/* //TODO FIND LES WIDGETS ENERGY ... DONT buildingIdLinkedTo est le même que le bâtimen
                             */}
                            <ConstructWidgets
                              renderPopoverBody={renderPopoverBody}
                              seuilsOk={seuilsOk}
                              handlePopoverClose={handlePopoverClose}
                              handlePopoverOpen={handlePopoverOpen}
                              openedPopooverId={openedPopooverId}
                              currentIndicator={currentIndicator}
                              currentProject={currentProject}
                              currentAllotment={currentAllotment}
                              projectIc={projectIc}
                              zones={parsedProject.zones}
                              title={'Chantier'}
                              icKey="EgesChantier"
                            />
                          </div>
                        );
                    })}
                  {!isMultiBuilding &&
                    currentProject.templateType?.includes(TYPE_EPCM) &&
                    objectiveWidgets?.map((objectiveWidget) => {
                      if (objectiveWidget.checked)
                        return (
                          <div
                            key={objectiveWidget.order.toString()}
                            data-grid={{
                              w: 1.5,
                              h: 10,
                              x: 6,
                              y: 0,
                              i: objectiveWidget.order.toString(),
                            }}
                            className="container-grid"
                          >
                            <GoalsWidgets projectIc={projectIc} />
                          </div>
                        );
                    })}
                  {/* Energy  */}
                  {!isMultiBuilding &&
                    energyWidgets?.map((energyWidget) => {
                      if (energyWidget.checked)
                        return (
                          <div
                            key={energyWidget.order.toString()}
                            data-grid={{
                              w:
                                currentProject.templateType?.includes(TYPE_EPCM) ||
                                currentProject.templateType?.includes(
                                  TEMPLATE_TYPE_NAME.BBCA
                                )
                                  ? 1.3
                                  : 2,
                              h: 10,
                              x:
                                currentProject.templateType?.includes(TYPE_EPCM) ||
                                currentProject.templateType?.includes(
                                  TEMPLATE_TYPE_NAME.BBCA
                                )
                                  ? 7.5
                                  : 6,
                              y: 0,
                              i: energyWidget.order.toString(),
                            }}
                            className="container-grid"
                          >
                            <EnergyWidgets
                              renderPopoverBody={renderPopoverBody}
                              seuilsOk={seuilsOk}
                              handlePopoverClose={handlePopoverClose}
                              handlePopoverOpen={handlePopoverOpen}
                              openedPopooverId={openedPopooverId}
                              currentIndicator={currentIndicator}
                              currentAllotment={currentAllotment}
                              currentProject={currentProject}
                              projectIc={projectIc}
                              zones={parsedProject.zones}
                            />
                          </div>
                        );
                    })}
                  {/* water */}
                  {!isMultiBuilding &&
                    waterWidgets?.map((waterWidget) => {
                      if (waterWidget.checked)
                        return (
                          <div
                            key={waterWidget.order.toString()}
                            data-grid={{
                              w:
                                currentProject.templateType?.includes(TYPE_EPCM) ||
                                currentProject.templateType?.includes(
                                  TEMPLATE_TYPE_NAME.BBCA
                                )
                                  ? 1.2
                                  : 2,
                              h: 10,
                              x:
                                currentProject.templateType?.includes(TYPE_EPCM) ||
                                currentProject.templateType?.includes(
                                  TEMPLATE_TYPE_NAME.BBCA
                                )
                                  ? 8.8
                                  : 8,
                              y: 0,
                              i: waterWidget.order.toString(),
                            }}
                            className="container-grid"
                          >
                            <WaterWidgets
                              projectIc={projectIc}
                              currentIndicator={currentIndicator}
                              currentProject={currentProject}
                            />
                          </div>
                        );
                    })}

                  {/* If multi building */}
                  {isMultiBuilding &&
                    buildingWidgets
                      ?.filter((building) => building.checked === true)
                      .map((building, index) => {
                        return (
                          <div
                            key={building.order.toString()}
                            data-grid={{
                              w: 10,
                              h: 10,
                              x: 0,
                              y: index,
                              i: building.order.toString(),
                              minW: 10,
                              minH: 10,
                            }}
                            className="container-grid"
                            style={{ display: 'flex' }}
                          >
                            {/* <div style={{display: 'flex', flexGrow: 1}}> */}
                            <BuildingWidgets
                              buildingSheet={building}
                              currentIndicator={currentIndicator}
                              currentProject={currentProject}
                              projectIc={projectIc}
                              zones={parsedProject.zones}
                              currentAllotment={currentAllotment}
                              projectDataComps={projectDataComps}
                            />
                            <BuildingConstructWidgets
                              buildingSheet={building}
                              renderPopoverBody={renderPopoverBody}
                              seuilsOk={seuilsOk}
                              handlePopoverClose={handlePopoverClose}
                              handlePopoverOpen={handlePopoverOpen}
                              openedPopooverId={openedPopooverId}
                              currentIndicator={currentIndicator}
                              currentAllotment={currentAllotment}
                              currentProject={currentProject}
                              projectIc={projectIc}
                              zones={parsedProject.zones}
                              icKey={'EgesPce'}
                              // style={{flexGrow: '3'}}
                            />
                            {
                              //TODO construct not amortized
                              currentProject.templateType?.includes(
                                TEMPLATE_TYPE_NAME.BBCA
                              ) && (
                                <BuildingConstructWidgets
                                  buildingSheet={building}
                                  renderPopoverBody={renderPopoverBody}
                                  seuilsOk={seuilsOk}
                                  handlePopoverClose={handlePopoverClose}
                                  handlePopoverOpen={handlePopoverOpen}
                                  openedPopooverId={openedPopooverId}
                                  currentIndicator={currentIndicator}
                                  currentAllotment={currentAllotment}
                                  currentProject={currentProject}
                                  projectIc={projectIc}
                                  zones={parsedProject.zones}
                                  title={
                                    'Produits de Construction et Equipements non amortis'
                                  }
                                  icKey={'EgesPceNa'}
                                  // style={{flexGrow: '3'}}
                                />
                              )
                            }
                            {
                              //TODO constructSite
                              currentProject.templateType?.includes(
                                TEMPLATE_TYPE_NAME.BBCA
                              ) && (
                                <BuildingConstructWidgets
                                  buildingSheet={building}
                                  renderPopoverBody={renderPopoverBody}
                                  seuilsOk={seuilsOk}
                                  handlePopoverClose={handlePopoverClose}
                                  handlePopoverOpen={handlePopoverOpen}
                                  openedPopooverId={openedPopooverId}
                                  currentIndicator={currentIndicator}
                                  currentAllotment={currentAllotment}
                                  currentProject={currentProject}
                                  projectIc={projectIc}
                                  zones={parsedProject.zones}
                                  title={'Chantier'}
                                  icKey="EgesChantier"
                                  // style={{flexGrow: '3'}}
                                />
                              )
                            }

                            {currentProject.templateType?.includes(TYPE_EPCM) && (
                              <GoalsWidgets
                                projectIc={projectIc}
                                buildingSheet={building}
                                zones={parsedProject.zones}
                              />
                            )}

                            <BuildingEnergyWidgets
                              buildingSheet={building}
                              zones={parsedProject.zones}
                              renderPopoverBody={renderPopoverBody}
                              seuilsOk={seuilsOk}
                              handlePopoverClose={handlePopoverClose}
                              handlePopoverOpen={handlePopoverOpen}
                              openedPopooverId={openedPopooverId}
                              currentAllotment={currentAllotment}
                              currentIndicator={currentIndicator}
                              currentProject={currentProject}
                              projectIc={projectIc}
                              // style={{flexGrow: '1'}}
                            />
                            <BuildingWaterWidgets
                              buildingSheet={building}
                              zones={parsedProject.zones}
                              projectIc={projectIc}
                              currentIndicator={currentIndicator}
                              currentProject={currentProject}
                              // style={{flexGrow: '1'}}
                            />
                            {/* </div> */}
                          </div>
                        );
                      })}
                  {zoneDistributionWidget?.checked && (
                    <div
                      key={zoneDistributionWidget.order.toString()}
                      data-grid={{
                        w: 3,
                        h: isCertifiedMode ? 16 : 13,
                        x: 0,
                        y: isCertifiedMode ? 5 : 21,
                        i: zoneDistributionWidget.order.toString(),
                      }}
                      className="container-grid"
                    >
                      <ZonesWidgets
                        isMenuOpen={isMenuOpen}
                        data={data}
                        currentIndicator={currentIndicator}
                      />
                    </div>
                  )}

                  {histogramWidget?.checked && (
                    <div
                      key={histogramWidget.order.toString()}
                      className="report-synthese-graph container-grid"
                      data-grid={{
                        w: isCertifiedMode ? 7 : 12,
                        h: isCertifiedMode ? 16 : 22,
                        x: isCertifiedMode ? 3 : 0,
                        y: 5,
                        i: histogramWidget.order.toString(),
                      }}
                    >
                      {!freezeBar && (
                        <>
                          <Tooltip
                            title={t('APP.REPORT.REPORT.PH_50C711', {
                              defaultValue: 'Mettre le graphique en plein écran',
                            })}
                          >
                            <img
                              src={fullscreenIcon}
                              style={{
                                position: 'absolute',
                                display: 'inline-block',
                                right: '50px',
                                cursor: 'pointer',
                                zIndex: '3',
                                width: '20px',
                              }}
                              onClick={graphToFullScreen}
                            />
                          </Tooltip>
                          {renderGraph()}
                        </>
                      )}

                      {freezeBar && (
                        <div
                          style={{
                            color: '#73b7ec',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '99.5%',
                          }}
                        >
                          <Loader
                            variant="indeterminate"
                            disableShrink
                            style={{ color: '#73b7ec', display: 'block' }}
                            size={40}
                            thickness={4}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {storageWidget?.checked && (
                    <div
                      key={storageWidget.order.toString()}
                      data-grid={{
                        w: 3,
                        h: 12,
                        x: 0,
                        y: 21,
                        i: storageWidget.order.toString(),
                      }}
                      className="container-grid"
                    >
                      <StockWidgets
                        currentProject={currentProject}
                        projectIc={projectIc}
                        zones={parsedProject.zones}
                      />
                    </div>
                  )}

                  {benefitsAndChargesWidget?.checked &&
                    currentProject.templateType?.includes(TYPE_EPCM) && (
                      <div
                        key={benefitsAndChargesWidget.order.toString()}
                        data-grid={{
                          w: 3,
                          h: 16,
                          x: 0,
                          y: 21,
                          i: benefitsAndChargesWidget.order.toString(),
                        }}
                        className="container-grid"
                      >
                        <BenefitsWidgets
                          projectIc={projectIc}
                          currentIndicator={currentIndicator}
                          buildingsZones={parsedProject.zones.filter(
                            (zone) => zone.type === 'building'
                          )}
                        />
                      </div>
                    )}

                  {useDataComponantWidget?.checked && (
                    <div
                      key={useDataComponantWidget.order.toString()}
                      data-grid={{
                        w: 4,
                        h: isCertifiedMode ? 12 : 13,
                        x: 3,
                        y: 21,
                        i: useDataComponantWidget.order.toString(),
                      }}
                      className="container-grid"
                    >
                      <UsageWidgets secondData={secondData} />
                    </div>
                  )}

                  {deliverableWidget?.checked && (
                    <div
                      key={deliverableWidget.order.toString()}
                      data-grid={{
                        w: 3,
                        h: isCertifiedMode ? 12 : 13,
                        x: 7,
                        y: 21,
                        i: deliverableWidget.order.toString(),
                      }}
                      className="container-grid"
                    >
                      <DeliverablesWidgets
                        currentProject={currentProject}
                        zones={parsedProject.zones}
                        seuilsOk={seuilsOk}
                        downloadPdf={downloadPdf}
                        setDownloadPdf={setDownloadPdf}
                        rseeText={rseeText}
                        generatePDF={generatePDF}
                        downloadRsee={downloadRsee}
                        downloadRseePdf={downloadRseePdf}
                        generateRsee={generateRsee}
                        setShow={setShow}
                        isExportEpcmDateValid={isExportEpcmDateValid}
                        getRseePdf={getRseePdf}
                      />
                    </div>
                  )}
                </ReactGridLayout>
              </>
            )}
          </>
        </div>
      )}
    </>
  );
};

Report.propTypes = {
  synthese: PropTypes.bool.isRequired,
  A4: PropTypes.bool.isRequired,
  format169: PropTypes.bool.isRequired,
  format43: PropTypes.bool.isRequired,
  variant: PropTypes.bool.isRequired,
  pepfdes: PropTypes.bool.isRequired,
  rse: PropTypes.bool.isRequired,
  updateCheckValue: PropTypes.func.isRequired,
};

export default Report;
