import { useTranslation } from 'react-i18next';
import { TextField, Tooltip, MenuItem } from '@material-ui/core';
import SelectUi from '@mui/material/Select';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { inputStyle, selectStyle } from '../utils';
import RectangleSVG from '../../../../assets/images/projectSimulator/RectangleSVG';

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#789ADE',
      borderRadius: '6px',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: '1.5px solid #789ADE',
      },
    },
    '& .MuiSelect-select': {
      padding: '8px',
      border: '1px solid #789ADE',
    },
  },
});

type FormValues = {
  rectangleLength: number;
  rectangleWidth: number;
  rectangleHeight: number;
  rectangleLevels: number;
};

function GeneralConception() {
  const {
    control,
    formState: { errors },
    trigger,
  } = useForm<FormValues>();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className="form-block">
      <h2 className="form-title">
        {t('APP.PROJECT.SIMULATOR.QUICK_SIMULATION.GENERAL.CONCEPTION.TITLE', {
          defaultValue: 'Conception générale',
        })}
      </h2>

      <hr className="line-title-separation" />

      <div
        style={{
          display: 'flex',
          paddingLeft: '24px',
          paddingRight: '48px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
          }}
        >
          <div>
            <label
              className="form-label"
              style={{
                display: 'flex',
              }}
              htmlFor="morphology"
            >
              {t('APP.PROJECT.SIMULATOR.QUICK_SIMULATION.FORM.LABEL.MORPHOLOGY', {
                defaultValue: 'Type de morphologie',
              })}
            </label>
            <Tooltip title="D'autres choix seront bientôt disponible">
              <SelectUi
                color="primary"
                MenuProps={{
                  sx: {
                    '& .MuiMenuItem-root:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    },
                    '& .Mui-selected': {
                      backgroundColor: '#E1E1E6',
                    },
                  },
                }}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    borderStyle: 'none',
                  },
                  '.MuiOutlinedInput-input': selectStyle,
                }}
                name="morphology"
                id="morphology"
                disabled
                defaultValue="rectangle"
                className={classes.root}
                variant="outlined"
                style={{
                  width: '80%',
                }}
              >
                {/* fake data just for the visual, it will be replaced don't pay attention */}
                <MenuItem value="rectangle">Rectangle</MenuItem>
              </SelectUi>
            </Tooltip>
          </div>

          <div
            style={{
              display: 'flex',
              columnGap: '20px',
            }}
          >
            {/* [ ] TODO: supprimer les strokes étranges apparaissant sur le svg */}
            {RectangleSVG()}

            <div
              style={{
                display: 'flex',
                rowGap: '10px',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <label className="form-label">
                  {t(
                    'APP.PROJECT.SIMULATOR.QUICK_SIMULATION.FORM.LABEL.RECTANGLE_LENGTH',
                    {
                      defaultValue: 'Longueur',
                    }
                  )}
                </label>
                <Controller
                  control={control}
                  name="rectangleLength"
                  rules={{
                    validate: (value) => !isNaN(value) && value > 0,
                  }}
                  render={({ field }) => (
                    <div
                      style={{
                        display: 'flex',
                        columnGap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        {...field}
                        placeholder="250"
                        variant="outlined"
                        className={classes.root}
                        onBlur={() => trigger('rectangleLength')}
                        error={Boolean(errors.rectangleLength)}
                        inputProps={{
                          style: inputStyle,
                        }}
                      />
                      <span
                        style={{
                          fontSize: '9px',
                          color: '#789ADE',
                        }}
                      >
                        m
                      </span>
                    </div>
                  )}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <label className="form-label">
                  {t(
                    'APP.PROJECT.SIMULATOR.QUICK_SIMULATION.FORM.LABEL.RECTANGLE_WIDTH',
                    {
                      defaultValue: 'Largeur',
                    }
                  )}
                </label>
                <Controller
                  control={control}
                  name="rectangleWidth"
                  rules={{
                    validate: (value) => !isNaN(value) && value > 0,
                  }}
                  render={({ field }) => (
                    <div
                      style={{
                        display: 'flex',
                        columnGap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        {...field}
                        placeholder="180"
                        variant="outlined"
                        className={classes.root}
                        onBlur={() => trigger('rectangleWidth')}
                        error={Boolean(errors.rectangleWidth)}
                        inputProps={{
                          style: inputStyle,
                        }}
                      />
                      <span
                        style={{
                          fontSize: '9px',
                          color: '#789ADE',
                        }}
                      >
                        m
                      </span>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '16px',
            }}
          >
            <label className="form-label">
              {t(
                'APP.PROJECT.SIMULATOR.QUICK_SIMULATION.FORM.LABEL.RECTANGLE_LEVELS',
                {
                  defaultValue: 'Nombre de niveaux',
                }
              )}
            </label>
            <Controller
              control={control}
              name="rectangleLevels"
              rules={{
                validate: (value) => !isNaN(value) && value > 0,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="5"
                  style={{ width: '30%' }}
                  variant="outlined"
                  className={classes.root}
                  onBlur={() => trigger('rectangleLevels')}
                  error={Boolean(errors.rectangleLevels)}
                  inputProps={{
                    style: inputStyle,
                  }}
                />
              )}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <label className="form-label">
              {t(
                'APP.PROJECT.SIMULATOR.QUICK_SIMULATION.FORM.LABEL.RECTANGLE_HEIGHT',
                {
                  defaultValue: 'Hauteur moyenne (m)',
                }
              )}
            </label>
            <Controller
              control={control}
              name="rectangleHeight"
              rules={{
                validate: (value) => !isNaN(value) && value > 0,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="5"
                  style={{ width: '30%' }}
                  variant="outlined"
                  className={classes.root}
                  onBlur={() => trigger('rectangleHeight')}
                  error={Boolean(errors.rectangleHeight)}
                  inputProps={{
                    style: inputStyle,
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralConception;
