import './favoriteOverlay.scss';
import { Pagination } from 'antd';
import { StateDTO } from '../../models/GeneralDTO';
import { Tooltip } from '@material-ui/core';
import {
  fetchFavorites,
  fetchInitialFavorites,
  updateFavoritesValue,
} from '../../../actions/favorites';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useDrag } from 'react-dnd';
import FavoriteCard from './FavoriteCard';
import InputAdornment from '@material-ui/core/InputAdornment';
import Loader from '@material-ui/core/CircularProgress';
import React, { useCallback, useEffect, useRef } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import debounce from 'lodash.debounce';
import { autoShowTooltip } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, dispatch, isOpen) {}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

const FavoriteOverlay = ({ screen }) => {
  const wrapperRef = useRef(null);
  const {
    isOpen,
    tabValue,
    userFavoritesSheets,
    companyFavoritesSheets,
    userSheetsCount,
    companySheetsCount,
    userLoadingSheets,
    companyLoadingSheets,
    userPages,
    companyPages,
    smallScreen,
  } = useSelector((state: StateDTO) => state.favorites);
  const { allGroups, sheets, licenceTypeAccess } = useSelector(
    (state: StateDTO) => state.projects
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (isOpen) {
          dispatch(updateFavoritesValue('isOpen', false));
        }

        // alert("You clicked outside of me!");
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, isOpen]);

  // useOutsideAlerter(wrapperRef,
  //   dispatch,
  //   isOpen);

  useEffect(() => {
    if (window.screen.availHeight <= 950) {
      dispatch(updateFavoritesValue('smallScreen', true));
    }
  }, []);

  useEffect(() => {
    if (isOpen && !userFavoritesSheets && !companyFavoritesSheets) {
      dispatch(fetchInitialFavorites());
    }

    if (!isOpen && userFavoritesSheets && companyFavoritesSheets) {
      dispatch(updateFavoritesValue('userFavoritesSheets', null));
      dispatch(updateFavoritesValue('companyFavoritesSheets', null));
      dispatch(updateFavoritesValue('tabValue', 0));
    }
  }, [isOpen]);

  const handleChangePage = (tabValue, nbPage, scope) => {
    dispatch(fetchFavorites(scope, tabValue, nbPage));
  };

  // Mangement of search request

  const changeHandler = () => {
    // fetchProjects(true);
    dispatch(fetchFavorites('user', tabValue, 1));
    dispatch(fetchFavorites('company', tabValue, 1));
  };

  const debouncedChangeHandler = useCallback(
    debounce(() => {
      changeHandler();
    }, 1000),
    []
  );

  return (
    <motion.div
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      variants={{
        open: { top: '-10px' },
        closed: { top: screen === 'products' ? '-65.2vh' : '-67.2vh' },
      }}
      exit={{ opacity: 0, top: '0' }}
      ref={wrapperRef}
      className={`favorite-overlay-wrapper ${
        smallScreen ? 'favorite-overlay-small' : ''
      }`}
    >
      <div className={`favorite-overlay-subwrapper`}>
        <div className="favorites-list-wrapper">
          <div className="user-list-wrapper">
            <div className="favorites-header">
              <div className="favorites-header-left">
                <h5>
                  {t('APP.FAVORITEOVERLAY.INDEX.PH_994283', {
                    defaultValue: 'Vos favoris :',
                  })}
                </h5>
                <div className="tabs">
                  <Tabs
                    style={{ padding: '0 10px 0 10px' }}
                    value={tabValue}
                    onChange={(e, value) => handleChangePage(value, 1, 'user')}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab
                      label={t('APP.FAVORITEOVERLAY.INDEX.PH_04239B', {
                        defaultValue: 'Fiches INIES',
                      })}
                    />
                    <Tab
                      label={t('APP.FAVORITEOVERLAY.INDEX.PH_F6EBC5', {
                        defaultValue: 'Fiches configurées',
                      })}
                    />
                    <Tooltip
                      title={t('APP.FAVORITEOVERLAY.INDEX.PH_5939C2', {
                        defaultValue: 'Bientôt disponible',
                      })}
                    >
                      <div>
                        <Tab disabled label="Macro-composants" />
                      </div>
                    </Tooltip>
                  </Tabs>
                </div>
              </div>
              <div className="pagination">
                <Pagination
                  current={userPages}
                  pageSize={5}
                  total={userSheetsCount}
                  onChange={(e) => handleChangePage(tabValue, e, 'user')}
                />
              </div>
              <div className="favorites-header-right">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ fontSize: '14px', color: '#92AAE7' }} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    dispatch(updateFavoritesValue('inputSearchBar', e.target.value));
                    debouncedChangeHandler();
                  }}
                  variant="outlined"
                  className="input-boarding researchBar"
                />
              </div>
            </div>
            <div
              className="content-tab"
              style={
                userLoadingSheets
                  ? {
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }
                  : {}
              }
            >
              <TabPanel value={tabValue} index={tabValue}>
                <div className="list-cards">
                  {userLoadingSheets ? (
                    <Loader
                      variant="indeterminate"
                      disableShrink
                      style={{ color: '#73b7ec' }}
                      size={25}
                      thickness={4}
                    />
                  ) : (
                    <>
                      {userSheetsCount === 0 && <p>Aucun favori enregistré</p>}
                      {userFavoritesSheets &&
                        userFavoritesSheets.map((el) => {
                          return (
                            <FavoriteCard key={el.id} favorite={el} company={null} />
                          );
                        })}
                    </>
                  )}
                </div>
              </TabPanel>
            </div>
          </div>

          <div className="favorites-company-list-wrapper">
            <div className="favorites-header">
              <div className="favorites-header-left">
                <h5 style={{ color: 'transparent' }}>
                  {t('APP.FAVORITEOVERLAY.INDEX.PH_B6BEEA', {
                    defaultValue: 'Vos favoris :',
                  })}
                </h5>
                <Tabs
                  style={{ padding: '0 10px 0 10px' }}
                  value={0}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab
                    label={t('APP.FAVORITEOVERLAY.INDEX.PH_8B8809', {
                      defaultValue: "Recommandés par l'entreprise",
                    })}
                  />
                  -
                </Tabs>
                <div className="pagination">
                  <Pagination
                    current={companyPages}
                    pageSize={5}
                    total={companySheetsCount}
                    onChange={(e) => handleChangePage(0, e, 'company')}
                  />
                </div>
              </div>
            </div>
            <div
              className="content-tab"
              style={
                companyLoadingSheets
                  ? {
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }
                  : {}
              }
            >
              <TabPanel value={0} index={0}>
                <div className="list-cards">
                  {companyLoadingSheets && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Loader
                        variant="indeterminate"
                        disableShrink
                        style={{ color: '#73b7ec' }}
                        size={25}
                        thickness={4}
                      />
                    </div>
                  )}

                  {!companyLoadingSheets && companySheetsCount === 0 && (
                    <p>Aucun favori entreprise enregistré</p>
                  )}

                  {!companyLoadingSheets &&
                    companyFavoritesSheets &&
                    companyFavoritesSheets.map((el) => {
                      return <FavoriteCard key={el} favorite={el} company={true} />;
                    })}
                </div>
              </TabPanel>
            </div>
          </div>
        </div>

        <div className="button-overlay">
          <div
            className={`button-content ${
              licenceTypeAccess !== 'pro' ? 'disabled' : ''
            }`}
            onClick={() => {
              if (licenceTypeAccess === 'pro') {
                dispatch(updateFavoritesValue('isOpen', !isOpen));
              }
            }}
          >
            {isOpen ? (
              <StarIcon
                style={{
                  color: licenceTypeAccess === 'pro' ? '#FFFFFF' : 'lightGray',
                }}
              />
            ) : (
              <StarBorderIcon
                style={{
                  color: licenceTypeAccess === 'pro' ? '#FFFFFF' : 'lightGray',
                }}
              />
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default FavoriteOverlay;
