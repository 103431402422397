import {
  AllotmentNodeDTO,
  BbcaClimateInnovationBuildingFormDTO,
  CompanyDTO,
  ProjectDTO,
  ProjectTypeDTO,
  StructuredCompanyDTO,
  ZoneDTO,
} from '../models/ProjectDTO';
import { CalculatorDTO, WaterCalculatorDTO } from './CalculatorDTO';
import { GarbageDTO, NewActionDTO } from './ExploitationDTO';
import { HelpDTO } from './HelpDTO';
// import { CalculatorDTO } from './CalculatorDTO';
import {
  AllGroupsDTO,
  CurrentAllotmentDTO,
  CurrentIndicatorDTO,
  GenerateRSEnvDTO,
  MasterDataType,
  ProjectsListMonitoring,
} from './ProjectsDTO';
import { MasterDataEquipment } from './ProjectsDTO';
// import { ProjectDTO } from "../../models/ProjectDTO";
import { BundleReducerDTO } from './BundleDTO';
import { EquipmentsDTO } from './EquipmentsDTO';
import { FavoritesReducerDTO } from './FavoritesDTO';
import { MasterDataEquipmentDTO, SimulatorDTO } from './SimulatorDTO';
import { MonitoringDTO } from './MonitoringDTO';
import { RightsDTO, newCompanyDTO } from './RightsDTO';
import { ConstantKeysWidgets, Widget } from '../projects/PDFBuilder/WidgetsList';

export type StateDTO = {
  projects: {
    currentProject: ProjectDTO;
    postRseeExportSuccess: boolean;
    projects: ProjectDTO[];
    projectsList: { count: number; rows: ProjectDTO[] }[];
    calculator: CalculatorDTO;
    waterCalculator: WaterCalculatorDTO;
    refUnit: string;
    allGroups: AllGroupsDTO;
    nodesAvailableForProjectType: AllotmentNodeDTO[];
    bundleOptions: {
      name: string;
      AllotmentNodes: AllotmentNodeDTO[];
      id: number;
      unit: string;
      type_fiche: string;
    }[];
    configuredSheetOptions: {
      id: number;
      name: string;
      type: string;
      unit: string;
      AllotmentNodes: AllotmentNodeDTO[];
    }[];
    modeledProductOptions: {
      id: number;
      name: string;
      type: string;
      unit: string;
      AllotmentNodes: AllotmentNodeDTO[];
    }[];
    certifiedAllotments: {
      RE2020: AllotmentNodeDTO[];
      EPCM: AllotmentNodeDTO[];
    };
    listOfNodesSearchBar: AllotmentNodeDTO[];
    listOfNodesSearchBarWithConfiguredSheetAllowed: AllotmentNodeDTO[];
    mds?: MasterDataType[];
    currentIndicator: CurrentIndicatorDTO;
    currentAllotment: CurrentAllotmentDTO;
    projectsListMonitoring: ProjectsListMonitoring;
    companies: CompanyDTO[];
    allCompanies: CompanyDTO[];
    structuredCompanies: StructuredCompanyDTO[];
    parentCompanies: CompanyDTO[];
    refStates: {
      refModal: boolean;
      refLines: {}[];
      refProject: {};
      refMessage: string;
    };
    creationProjectForm: boolean;
    creationProject: {
      creationSteps: string[];
      refUnit: string;
      creationProjectLoading: boolean;
      selectedTypeProjectId: number;
    };
    isLoadingGenerateRSEnv: boolean;
    generateRSEnv: GenerateRSEnvDTO;
    zones: {
      id: number;
      type: string;
    }[];
    buildingSections: {
      BuildingTypeId: any;
      name: string;
      speculativeDevelopment?: boolean;
      Zones: {
        IsConnectedToUrbanNetwork: boolean;
        name: string;
        BuildingTypeId: number | string;
        menInput: boolean;
        nbOccupants: number;
        logementInput: boolean;
        comblesInput: boolean;
        area: string | number;
        RenovationTypeId?: number;
        type: string;
      }[];
    }[];
    rsetFile: boolean;
    isRsenv: boolean;
    buildingTypes: { id: number; name: string }[];
    buildingTypeStandard: number | string;
    user: {
      attributes: {
        family_name: string;
        given_name: string;
        email_verified: boolean;
        email: string;
      };
    };
    isMenuOpen: boolean;
    buildingSiteDurationRset: number | string;
    projectNameRset: string;
    permitDateRset: string;
    phaseIdRset: number;
    postCodeRset: number | string;
    parcelAreaRset: number | string;
    rsetmodalOpen: boolean;
    fluidsArray: any[];
    equipsBulkArray: any[];
    parsedProject: {
      parsed: any[];
      raw: [];
      zones: ZoneDTO[];
      urbanNetworks: [];
    };
    isSuperAdmin: boolean;
    patchCompanyData: {};
    newCompany: newCompanyDTO;
    templateTypeAvailable: {
      id: number;
      name: string;
      queryParams: {
        id: number;
        name: string;
        defaultDdv: number;
        availableUnites: string[];
        AllotmentNodes: {
          id: number;
          name: string;
          color: string;
        }[];
      }[];
    }[];
    bbcaClimateInnovationFormValues: BbcaClimateInnovationBuildingFormDTO[] | null;
    bbcaFormValuesfromSelectedBuilding:
      | BbcaClimateInnovationBuildingFormDTO
      | undefined;
    templateTypeAvailableIds: number[];
    licenceTypeAccess: 'read' | 'light' | 'pro';
    refProjects: {
      id: number;
      ddv: number;
      name: string;
      Company: {
        id: number;
        name: string;
      };
    }[];
    projectInput: string;
    companyFilters: CompanyDTO[];
    fluids: { id: number; libelle: string }[];
    confirmPatchRefurbished: boolean;
  };
  access: {};
  equipments: EquipmentsDTO;
  imports: {
    importDate: {
      id: number;
    }[];
    isImportLoading: boolean;
    creationImport: boolean;
    importStep: number;
    dataError: {}[];
    currentImport: number;
    waitLines: number;
    badLines: number;
    goodLines: number;
    importDatas: {};
    isSearchingData: boolean;
    searchSourceValue: string;
    isLoadMoreImports: boolean;
  };
  help: HelpDTO[];
  monitoring: MonitoringDTO;
  report: {
    widgetsInDropZone: Widget[];
    generatePdfStarter: boolean;
    widgetTypePreview: ConstantKeysWidgets;
  };
  rights: RightsDTO;
  variants: {};
  products: {};
  groups: [];
  organisations: [];
  exploitation: {
    exploitationNotification: {
      notificationType: string;
      text: string;
    };
    deletedStatus: boolean;
    modalDeleteProject: boolean;
    selectedRowKeys: string[];
    listActionDatas: {
      sourceId: number;
    }[];
    newAction: NewActionDTO;
    loadingActions: boolean;
    openAlert: boolean;
    garbageLines: GarbageDTO[];
    newEquipmentQuantity: number;
    newEquipmentState: number;
    sheets: {}[];
    boardingActiveTab: string;
    reportActiveTab: string;
    transportLines: {}[];
    selectedActionKeys: number[];
    actionActiveTab: number;
  };
  simulator: SimulatorDTO;
  favorites: FavoritesReducerDTO;
  bundle: BundleReducerDTO;
};

export enum CurrentScreen {
  listContributor = 'listContributor',
  quickAddBar = 'quickAddBar',
  multipleActions = 'multipleActions',
}

export const DISABLED_ALLOTMENT_NODE_ID: number = 10274;
export const DISABLED_LOT_ALLOTMENT_NODE_ID: number = 10270;

export enum IndicatorsID {
  GWP_ID = 26,
  IC_ID = 1,
}
