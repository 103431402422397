import './mapping.scss';
import { Checkbox } from 'antd';
import { Input as InputNooco } from '@photocarbone/nooco-ui';
import { StateDTO } from '../../../models/GeneralDTO';
import {
  fetchImportDatas,
  fetchMoreImportDatas,
  researchSourceName,
  resetDataAfterFilterChanged,
  resetImportStates,
} from '../../../../actions/import';
import { styled } from '@mui/material/styles';
import { updateFormValue } from '../../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import HeaderImport from '../../../shared/HeaderImport';
import IconButton from '@material-ui/core/IconButton';
import ImportModal from '../../../../containers/ImportModal';
import InputAdornment from '@material-ui/core/InputAdornment';
import Loader from '@material-ui/core/CircularProgress';
import MappingTable from './MappingTable';
import React, { useEffect, useRef } from 'react';
import RefModal from '../RefModal';
import RefProjectCard from '../RefProjectCard';
import SearchIcon from '@material-ui/icons/Search';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import { Alert } from '@mui/material';
import { Snackbar } from '@material-ui/core';
import IA from '../../../../assets/images/import/ia.png';
import creation from '../../../../assets/images/import/creation.png';
import init from '../../../../assets/images/import/init.png';
import ref1 from '../../../../assets/images/mapping/ref1.png';
import ref2 from '../../../../assets/images/mapping/ref2.png';
import ref3 from '../../../../assets/images/mapping/ref3.png';
import ref4 from '../../../../assets/images/mapping/ref4.png';
import ref5 from '../../../../assets/images/mapping/ref5.png';
import validate from '../../../../assets/images/import/validate.png';
import { useTranslation } from 'react-i18next';

const Mapping = () => {
  const dispatch = useDispatch();
  const { currentProject, refStates, allGroups } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { refLines, refMessage } = useSelector(
    (state: StateDTO) => state.projects.refStates
  );
  const {
    importDate,
    isImportLoading,
    creationImport,
    importStep,
    dataError,
    currentImport,
    waitLines,
    badLines,
    goodLines,
    importDatas,
    isSearchingData,
    searchSourceValue,
    displayArchived,
    errorPatchLines,
    filterOn,
    isLoadMoreImports,
    allLinesChecked,
    linesSelected,
    exceptLinesFromMultipleActions,
  } = useSelector((state: StateDTO) => state.imports);
  let inputRef = useRef();
  const currentRowIndexDisplayed = useRef();

  const { t } = useTranslation();
  // fetchImport datas for mapping table

  useEffect(() => {
    if (!creationImport) {
      if (importDate.length > 0 && currentImport) {
        dispatch(fetchImportDatas(currentProject.id, currentImport));
      } else if (importDate.length > 0 && importDate[0]) {
        dispatch(updateFormValue('currentImport', importDate[0].id));

        dispatch(fetchImportDatas(currentProject.id, importDate[0].id));
      }
    }
  }, [importDate]);

  useEffect(() => {
    return () => {
      dispatch(
        updateFormValue('refStates', {
          refModal: false,
          refProject: null,
          refLines: [],
          refImport: {
            caracs: true,
            sheets: true,
          },
        })
      );
      // dispatch(resetImportStates());
    };
  }, []);

  // Data for progress bar

  const steps = [
    t('APP.MAPPING.INDEX.PH_A664CF', {
      defaultValue: "Initialisation de l'import",
    }),
    t('APP.MAPPING.INDEX.PH_DE4C23', { defaultValue: 'IA' }),
    t('APP.MAPPING.INDEX.PH_779723', { defaultValue: "Création de l'import" }),
    t('APP.MAPPING.INDEX.PH_E9E2E4', {
      defaultValue: 'Validation des données',
    }),
  ];

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient(90deg, rgba(126,255,224,1) 0%, rgba(112,192,173,1) 100%, rgba(0,212,255,1) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient(90deg, rgba(126,255,224,1) 0%, rgba(112,192,173,1) 100%, rgba(0,212,255,1) 100%);',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  function ColorlibStepIcon(props: {
    active: boolean;
    completed: boolean;
    className: string;
  }) {
    const { active, completed, className } = props;

    const icons = {
      1: (
        <img
          src={init}
          style={{ height: '30px', width: '30px' }}
          alt=""
          className="filter-white-png "
        />
      ),
      2: (
        <img
          src={IA}
          style={{ height: '30px', width: '30px' }}
          alt=""
          className="filter-white-png "
        />
      ),
      3: (
        <img
          src={creation}
          style={{ height: '30px', width: '30px' }}
          alt=""
          className="filter-white-png "
        />
      ),
      4: (
        <img
          src={validate}
          style={{ height: '30px', width: '30px' }}
          alt=""
          className="filter-white-png "
        />
      ),
    };

    console.log(props, className);

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
        style={{}}
      >
        {importStep + 1 === props.icon ? (
          <Loader
            variant="indeterminate"
            disableShrink
            style={{ color: 'white' }}
            size={30}
            thickness={4}
          />
        ) : (
          icons[String(props.icon)]
        )}
      </ColorlibStepIconRoot>
    );
  }

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient(100deg, rgba(126,255,224,1) 0%, rgba(112,192,173,1) 100%, rgba(0,212,255,1) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient(100deg, rgba(126,255,224,1) 0%, rgba(112,192,173,1) 100%, rgba(0,212,255,1) 100%)',
    }),
  }));

  const handleAllCheckbox = (value) => {
    if (value) {
      dispatch(
        updateFormValue(
          'linesSelected',
          displayArchived
            ? dataError.map((el) => el.id)
            : dataError.filter((el) => !el.isArchived).map((el) => el.id)
        )
      );
      dispatch(updateFormValue('allLinesChecked', true));
    } else {
      dispatch(updateFormValue('linesSelected', []));

      dispatch(updateFormValue('allLinesChecked', false));
    }

    dispatch(updateFormValue('exceptLinesFromMultipleActions', []));
  };

  const handleSearchInput = (value) => {
    dispatch(updateFormValue('isSearchingData', Boolean(value)));

    dispatch(updateFormValue('searchSourceValue', value));
    dispatch(resetDataAfterFilterChanged());
  };

  return (
    <>
      {/* Display Modals for buttons */}
      <ImportModal mappingRef={true} />
      <RefModal />
      <Snackbar
        open={refMessage || errorPatchLines}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        handleClose={() =>
          errorPatchLines
            ? dispatch(updateFormValue('errorPatchLines', null))
            : dispatch(
                updateFormValue('refStates', {
                  ...refStates,
                  refModal: false,
                  refMessage: null,
                })
              )
        }
        autoHideDuration={3000}
      >
        <Alert
          onClose={() =>
            errorPatchLines
              ? dispatch(updateFormValue('errorPatchLines', null))
              : dispatch(
                  updateFormValue('refStates', {
                    ...refStates,
                    refModal: false,
                    refMessage: null,
                  })
                )
          }
          severity={errorPatchLines ? 'error' : 'success'}
        >
          {refMessage || errorPatchLines}
        </Alert>
      </Snackbar>
      <div className="mapping-wrapper">
        <RefProjectCard />
        {refLines.length === 0 &&
        dataError.length === 0 &&
        importDate.length === 0 ? (
          <div className="infos-mapping">
            <div className="infos-prewrapper">
              <div className="info">
                <img src={ref3} alt />
                <p>
                  {t('APP.MAPPING.INDEX.PH_6E2253', {
                    defaultValue:
                      'Mappez une fois et réutilisez la reconnaissance du mapping de référence sur vos futurs projets',
                  })}
                </p>
              </div>
              <div className="info">
                <img src={ref1} alt />
                <p>
                  {t('APP.MAPPING.INDEX.PH_117229', {
                    defaultValue:
                      "Vous pouvez définir la famille produit cible, et de manière facultative préciser dans l'automatisation le choix de caractéristiques ou de références produits (PEP/FDES)",
                  })}
                </p>
              </div>
              <div className="info">
                <img src={ref4} alt />
                <p>
                  {t('APP.MAPPING.INDEX.PH_3F6531', {
                    defaultValue:
                      'Vos projets déjà existants peuvent être utilisés comme projets de référence',
                  })}
                </p>
              </div>
              <div className="info">
                <img src={ref2} alt />
                <p>
                  {t('APP.MAPPING.INDEX.PH_32132E', {
                    defaultValue:
                      'Vous pouvez charger avoir autant de fichiers et projets de référence que vous le souhaitez',
                  })}
                </p>
              </div>
              <div className="info">
                <img src={ref5} alt />
                <p>
                  {t('APP.MAPPING.INDEX.PH_8AF293', {
                    defaultValue:
                      'Attention : nous ne gérons pas les conflits (un même libellé mappé sur différentes notions dans des projets de référence différents)',
                  })}
                </p>
              </div>
              <div>
                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                  {t('APP.MAPPING.INDEX.PH_259582', {
                    defaultValue:
                      'Utilisez les boutons en haut à droite pour commencer à mapper',
                  })}
                </p>
              </div>
            </div>
          </div>
        ) : creationImport ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '5%',
            }}
          >
            <Stepper
              alternativeLabel
              activeStep={importStep}
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step style={{ width: '300px' }} key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <p style={{ textAlign: 'center', width: '280px', marginTop: '2em' }}>
              {t('APP.MAPPING.INDEX.PH_B1FE0B', {
                defaultValue:
                  'Import de votre fichier en cours : ceci peut prendre quelques dizaines de secondes',
              })}
            </p>
          </div>
        ) : (
          <div className="mapping-core">
            <HeaderImport
              currentRowIndexDisplayed={currentRowIndexDisplayed}
              mapping={true}
            />
            <div className="mapping-table-header">
              <Checkbox
                disabled={displayArchived}
                checked={
                  allLinesChecked && exceptLinesFromMultipleActions.length === 0
                }
                indeterminate={
                  (!allLinesChecked && linesSelected.length > 0) ||
                  (allLinesChecked && exceptLinesFromMultipleActions.length > 0)
                }
                onChange={(e) => handleAllCheckbox(e.target.checked)}
                style={{ marginRight: '1em' }}
              />

              <p className="subtitle">
                {t('APP.MAPPING.INDEX.PH_4C90A7', {
                  defaultValue: 'Mapping des références',
                })}
                <span className="nb-lines-valid">
                  {t('APP.MAPPING.INDEX.PH_657A30', {
                    result: goodLines,
                    defaultValue: '({result} lignes validées)',
                  })}
                </span>
              </p>
              <InputNooco
                id="standard-adornment-password"
                placeholder={t('APP.MAPPING.INDEX.PH_F86066', {
                  defaultValue: 'Rechercher un libellé du devis',
                })}
                onChange={(value) => handleSearchInput(value)}
                style={{ width: '35%', marginLeft: '2em' }}
                startIcon={<SearchIcon />}
                endIcon={
                  !isSearchingData ? null : (
                    <CloseIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleSearchInput('');
                        document.querySelector(
                          '.textfield .MuiInputBase-root input'
                        ).value = '';
                      }}
                    />
                  )
                }
              />
            </div>
            <MappingTable currentRowIndexDisplayed={currentRowIndexDisplayed} />
          </div>
        )}
      </div>
    </>
  );
};

export default Mapping;
