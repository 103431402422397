import dayjs from 'dayjs';
import enUS from 'antd/es/date-picker/locale/en_US';
import frFR from 'antd/es/date-picker/locale/fr_FR';

export const getAntdLocale = () => {
  const locale = dayjs.locale();

  switch (locale) {
    case 'fr':
      return frFR;
    case 'en':
      return enUS;
    // Add more cases for other locales if needed
    default:
      return enUS; // Default locale
  }
};
