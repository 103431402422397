import { StateDTO } from '../../../app/models/GeneralDTO';
import { updateFolder } from '../../../apiCallFunctions/folder';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

export const useUpdateFolder = () => {
  const { isBundleScreen } = useSelector((state: StateDTO) => state.bundle);
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => updateFolder(data, isBundleScreen), {
    onSuccess: () => {
      queryClient.invalidateQueries('foldersByCompany');
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  return mutation;
};
