import { useDispatch, useSelector } from 'react-redux';
import { StateDTO } from '../../../app/models/GeneralDTO';
import { getProjectDataComps } from '../../../apiCallFunctions/project/project';
import { useQuery } from 'react-query';
import { ProjectDataComps } from '@photocarbone/nooco-shared-api-def/src/project/projectDataComps';

export type useGetProjectDataCompsProps = {
  projectIdPersonalized?: number;
  onSuccessCallback?: (data) => void;
};
export const useGetProjectDataComps = ({
  projectIdPersonalized,
  onSuccessCallback,
}: useGetProjectDataCompsProps = {}) => {
  const { id: currentProjectId, templateType } = useSelector(
    (state: StateDTO) => state.projects.currentProject
  );
  const dispatch = useDispatch();

  const projectIdToUse = projectIdPersonalized || currentProjectId;

  return useQuery<ProjectDataComps>(
    ['projectDataComps', projectIdToUse],
    () => getProjectDataComps(projectIdToUse),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (onSuccessCallback) {
          onSuccessCallback(data);
        }
      },
      onError: (error) => {
        console.log('error getValues', error?.message);
      },
    }
  );
};
