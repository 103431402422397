import { AutoSizer, List } from 'react-virtualized';
import { StateDTO } from '../../models/GeneralDTO';
import { updateRightsValue } from '../../../actions/rights';
import { useDispatch, useSelector } from 'react-redux';
import CompanyCard from '../CompanyCard';
import React, { useEffect, useRef } from 'react';

function VirtualizedList({ items }) {
  const dispatch = useDispatch();
  const { companiesOffset, parentNodeId, companiesList, companiesLoading } =
    useSelector((state: StateDTO) => state.rights);
  const listRef = useRef();

  const reflectiveCompanyCardChildren = (el) => {
    return (
      <>
        <CompanyCard
          key={el.id}
          company={el}
          children={Boolean(el.children.length > 0)}
        />
        {el.children &&
          el.showChildren &&
          el.children.length > 0 &&
          el?.children.map((ele) => reflectiveCompanyCardChildren(ele))}
      </>
    );
  };

  const handleScroll = (event) => {
    if (
      event.target.clientHeight + event.target.scrollTop ===
      event.target.scrollHeight
    ) {
      dispatch(updateRightsValue('companiesOffset', companiesOffset + 20));
    }
  };

  return (
    <AutoSizer>
      {({ width, height }) => (
        <div
          ref={listRef}
          style={{ width, height, overflowY: 'scroll', overflowX: 'clip' }}
          onScroll={handleScroll}
        >
          {items && items.map((el) => reflectiveCompanyCardChildren(el))}
        </div>
      )}
    </AutoSizer>
  );
}

export default VirtualizedList;
