/* eslint-disable no-undef */
import '../report.scss';
import { BuildingSheet, ProjectIcDTO, zonesDTO } from './WidgetsDTO';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StateDTO } from '../../../models/GeneralDTO';
import { returnBuildingIcDepandingOnIndicator } from '../../../shared/utils';

const goalPic = require('../../../../assets/images/synthese/goal.png');
const leaf = require('../../../../assets/images/synthese/leaf.png');
const valid = require('../../../../assets/images/synthese/valid.png');
const error = require('../../../../assets/images/synthese/error.png');

interface GoalsWidgetsState {
  projectIc: ProjectIcDTO;
  zones: zonesDTO[];
  buildingSheet: BuildingSheet;
}

const GoalsWidgets = ({
  projectIc,
  zones,
  buildingSheet,
}: GoalsWidgetsState): JSX.Element => {
  const { currentIndicator } = useSelector((state: StateDTO) => state.projects);
  const { t } = useTranslation();

  const buildingIc =
    returnBuildingIcDepandingOnIndicator(zones, buildingSheet, currentIndicator) ||
    projectIc;

  return (
    <div className="report-energy container-resizable-ok">
      <div className="report-energy-header">
        <h3 className="dashboard-title">
          {t('APP.WIDGETS.GOALSWIDGETS.PH_D0D078', {
            defaultValue: 'Objectif',
          })}
        </h3>
        <Tooltip title="Objectif atteint du projet">
          <img
            style={{
              width: window.screen.availHeight < 950 ? '40px' : undefined,
              height: window.screen.availHeight < 950 ? '40px' : undefined,
            }}
            src={goalPic}
            alt=""
          />
        </Tooltip>
      </div>
      <div className="report-goal-core">
        <div className="container-stats">
          <div className="report-goal-stats">
            <p>{t('APP.WIDGETS.GOALSWIDGETS.PH_B52B40', { defaultValue: 'C1' })}</p>
            <img className="standard-img" alt="" src={leaf} />
          </div>
          <img
            className="standard-img"
            alt=""
            src={
              buildingIc.IcProjet < buildingIc.Eges_max1 &&
              buildingIc.IcConstruction < buildingIc.Eges_pce_max1
                ? valid
                : error
            }
          />
        </div>
        <div className="container-stats">
          <div className="report-goal-stats">
            <p>{t('APP.WIDGETS.GOALSWIDGETS.PH_FE58D0', { defaultValue: 'C2' })}</p>
            <img className="standard-img" alt="" src={leaf} />
            <img className="standard-img" alt="" src={leaf} />
          </div>
          <img
            className="standard-img"
            alt=""
            src={
              buildingIc.IcProjet < buildingIc.Eges_max2 &&
              buildingIc.IcConstruction < buildingIc.Eges_pce_max2
                ? valid
                : error
            }
          />
        </div>
      </div>
    </div>
  );
};

export default GoalsWidgets;
