import './notification.scss';
import { Button } from '@photocarbone/nooco-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

const InformationModal = ({ handleCloseInformationModal }) => {
  const { t } = useTranslation();

  return (
    <div className="releaseInformation" data-testid="newRelease-modal">
      <header>
        <div className="title">
          {t('APP.NOTIFICATIONNEWRELEASE.INFORMATIONMODAL.PH_EF11C2', {
            defaultValue: 'Nouvelle version',
          })}
        </div>
        <div
          className="releaseInformation--close"
					data-testid="newRelease-closeButton"
          onClick={handleCloseInformationModal}
        >
          X
        </div>
      </header>
      <main>
        {t('APP.NOTIFICATIONNEWRELEASE.INFORMATIONMODAL.PH_E24BD5', {
          defaultValue:
            "Retrouvez le contenu de cette note de version n'importe quand dans la rubrique Aide",
        })}
      </main>
      <footer>
        {/* <button
                  className='releaseInformation--close'
                  type='button'
                  onClick={handleCloseInformationModal}
                 >
                  fermer
                 </button> */}
        <Button
          onClick={handleCloseInformationModal}
          // style={{ marginLeft: 'auto', alignSelf: 'center' }}
          disabled={false}
          className="close-button"
          text={t('APP.NOTIFICATIONNEWRELEASE.INFORMATIONMODAL.PH_EA2C97', {
            defaultValue: 'Fermer',
          })}
        />

        {/* <Button
                  style={{ fontSize: window.screen.availHeight > 950 ? '0.9rem' : undefined }} 
                  shape="round" 
                  className="active-button" 
                 >
                  Projet
                 </Button> */}
      </footer>
    </div>
  );
};

export default InformationModal;
