import API from '../../app/services/API';

const api = new API();

export const getPdf = async (widgetsInDropZone) => {
  return api
    .getPdf(widgetsInDropZone)
    .then((response) => response)
    .catch((error) => error);
};
