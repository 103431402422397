/* eslint-disable no-undef */
import { IndicatorDTO, ProjectDTO } from '../../../../models/ProjectDTO';
import { ProjectIcDTO, zonesDTO } from './WidgetsDTO';
import { formatNumberP } from './ConstructWidgets';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

type StockWidgetsProps = {
  projectIc: ProjectIcDTO;
  zones: zonesDTO[];
  currentIndicator: IndicatorDTO;
  openedPopooverId: string;
  seuilsOk: boolean;
  handlePopoverOpen: Function;
  handlePopoverClose: Function;
  renderPopoverBody: Function;
  currentProject: ProjectDTO;
};

const stock = require('../../../../assets/images/synthese/Image7.png');
const building = require('../../../../assets/images/synthese/Image1.png');
const surfaces = require('../../../../assets/images/synthese/Image2.png');

const StockWidgets = ({
  projectIc,
  zones,
  currentProject,
}: StockWidgetsProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="report-stock container-resizable-ok">
      <div className="report-stock-header">
        <h3 className="dashboard-title">
          {t('APP.WIDGETS.STOCKWIDGETS.PH_F2390F', {
            defaultValue: 'Stockage de carbone biogénique',
          })}
        </h3>
        <Tooltip
          title={t('APP.WIDGETS.STOCKWIDGETS.PH_81BF08', {
            defaultValue: 'Stockage de carbone biogénique du projet : StockC projet',
          })}
        >
          <img
            style={{
              width: window.screen.availHeight < 950 ? '40px' : undefined,
              paddingTop: zones.find((el) => el.name === 'Parcelle')
                ? undefined
                : '9%',
            }}
            src={stock}
            alt=""
          />
        </Tooltip>
      </div>
      <div
        style={
          zones.find((el) => el.name === 'Parcelle')
            ? undefined
            : { paddingBottom: '4rem' }
        }
      >
        {formatNumberP(projectIc.StockC, false, currentProject, 'kg C')}
      </div>
      {zones.find((el) => el.name === 'Parcelle') && (
        <div className="report-stock-bottom">
          <div>
            <Tooltip
              title={t('APP.WIDGETS.STOCKWIDGETS.PH_7E2D1C', {
                defaultValue:
                  'Stockage de carbone biogénique du bâtiment : StockC bâtiment',
              })}
            >
              <img
                style={{
                  width: window.screen.availHeight < 950 ? '40px' : undefined,
                }}
                src={building}
                alt=""
              />
            </Tooltip>
            <p>
              {formatNumberP(projectIc.StockCBat, false, currentProject, 'kg C')} ​
            </p>
          </div>
          <div>
            <Tooltip
              title={t('APP.WIDGETS.STOCKWIDGETS.PH_26FD9C', {
                defaultValue:
                  'Stockage de carbone biogénique de la parcelle : StockC parcelle',
              })}
            >
              <img
                style={{
                  width: window.screen.availHeight < 950 ? '40px' : undefined,
                }}
                src={surfaces}
                alt=""
              />
            </Tooltip>
            <p>
              {formatNumberP(
                projectIc.StockCParcelle,
                false,
                currentProject,
                'kg C'
              )}
              ​​
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default StockWidgets;
