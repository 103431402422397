import { Button } from '@photocarbone/nooco-ui';
import { Form, Modal } from 'react-bootstrap';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import lightIcon from '../../../../assets/images/import/light.png';

const ModalAsk = ({
  modalAsk,
  updateFormValue,
  sendMessageSlack,
  sendMessageTrello,
}) => {
  const [validated] = useState(false);
  const { t } = useTranslation();

  const sendMessage = () => {
    sendMessageSlack();
    sendMessageTrello();
  };

  return (
    <div className="modalAsk">
      <Modal show={modalAsk} onHide={() => updateFormValue('modalAsk', false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('APP.LINE.MODALASK.PH_F6768D', {
              defaultValue: "Demande d'informations",
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group
              controlId="formGridOverseer"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Form.Label style={{ marginBottom: '1em' }}>
                {t('APP.LINE.MODALASK.PH_27B5A5', {
                  defaultValue: 'Comment pouvons-nous vous aider ?',
                })}
              </Form.Label>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={12}
                style={{ height: '200px' }}
                className="form-control"
                placeholder={t('APP.LINE.MODALASK.PH_4536C9', {
                  defaultValue: 'Décrivez nous votre besoin',
                })}
                onChange={(e) => updateFormValue('contentAsk', e.target.value)}
              />
            </Form.Group>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={lightIcon} alt="" style={{ width: '35px' }} />
              <p style={{ margin: '0 10px' }}>
                {t('APP.LINE.MODALASK.PH_C5B2D1', {
                  defaultValue:
                    'En moyenne les demandes sont traitées en moins de 48h',
                })}
              </p>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => updateFormValue('modalAsk', false)}
            text={t('APP.LINE.MODALASK.PH_495FCD', { defaultValue: 'Fermer' })}
            className="close-button"
          />

          <Button
            onClick={sendMessage}
            className="purple-button"
            text={t('APP.LINE.MODALASK.PH_2EB625', { defaultValue: 'Envoyer' })}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalAsk;
