import './helpFeature.scss';
import { HelpDTO } from '../../models/HelpDTO';
// import { attachEventProps } from '@aws-amplify/ui-react/lib-esm/react-component-lib/utils';
import {
  createNewFeature,
  deleteFeature,
  editFeatureUpdate,
  featureCloseModal,
  featureEditModeOn,
  featureModify,
  featureModifyNew,
  featureModifyTitle,
  featureUpdate,
  fetchFeatures,
  newFeatureUpdate,
  patchFeature,
} from '../../../actions/helps';
import { useDispatch, useSelector } from 'react-redux';
// import Input from '@material-ui/core/Input';
import DatePicker from 'react-date-picker';
// import {DatePicker} from 'antd';
import DeleteIcon from '@material-ui/icons/Delete';
import Loader from '@material-ui/core/CircularProgress';
import NoocoCheckbox from '../../shared/NoocoCheckbox';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import TextField from '@material-ui/core/TextField';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// import locale from 'antd/es/date-picker/locale/fr_FR';
// import dayjs from 'dayjs';
// import "react-datepicker/dist/react-datepicker.css";
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import { useTranslation } from 'react-i18next';

import editIcone from '../../../assets/images/help/bxs_edit.svg';

// Toolbar ReactQuill configuration
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [
      {
        color: ['#1B2559', '#44DDC3', '#344750', '#71A1FD', '#9E77FD', '#CE4C33'],
      },
    ],
    [
      {
        background: [
          '#1B2559',
          '#44DDC3',
          '#344750',
          '#71A1FD',
          '#9E77FD',
          '#CE4C33',
        ],
      },
    ],
    [{ align: [] }],
    ['link', 'image'],
    ['clean'],
  ],
};

// Toolbar ReactQuill New Feature
const CREATE_MODULES = {
  toolbar: {
    container: '#toolbar',
  },
};

// Toolbar ReactQuill Edit Feature
const EDIT_MODULES = {
  toolbar: {
    container: '#toolbar2',
  },
};

// const dateFormat = 'L';

// Temp variable for share QuillComponent with redux state
let articleBodyTemp: string = '';

// Component for New ReactQuill
function ReactQuillNew() {
  // const { articleBody } = useSelector((state: StateDTO) => state.help.release.editRelease);
  const [newQuillValue, setNewQuillValue] = useState('');

  const dispatch = useDispatch();

  return (
    <ReactQuill
      id="reactquillCreate"
      theme="snow"
      value={newQuillValue}
      onChange={(e) => {
        // console.log(e, i, c, d);
        // console.log('onchange')
        setNewQuillValue(e);
      }}
      // placeholder='Composez votre fonctionalité'
      modules={CREATE_MODULES}
      readOnly={false}
      // onFocus={(e, i, s) => {
      //   console.log(e, i, s);
      // }}
      preserveWhitespace={true}
      scrollingContainer=".parent-scroll"
      bounds="#scrolling-container"
      onChangeSelection={(range, source, quill) => {
        // const index = quill.getSelection().index;

        articleBodyTemp = quill?.getHTML();

        if (quill?.getHTML() === '<p><br></p>') {
          dispatch(featureUpdate('isArticleBodyValid', false));
          // console.log('pas bon');
        } else {
          // console.log('bon');
          // console.log(quill?.getHTML());
          dispatch(featureUpdate('isArticleBodyValid', true));
        }
      }}
    />
  );
}

// Component for Edit ReactQuill
function ReactQuillEdit() {
  // const { articleBody } = useSelector((state: StateDTO) => state.help.release.editRelease);

  const [newQuillValue, setNewQuillValue] = useState(articleBodyTemp);

  const dispatch = useDispatch();

  return (
    <ReactQuill
      id="reactquillEdit"
      theme="snow"
      value={newQuillValue}
      onChange={(e) => {
        // console.log(e, i, c, d);
        // console.log('onchange')
        setNewQuillValue(e);
      }}
      // placeholder='Composez votre fonctionalité'
      modules={EDIT_MODULES}
      readOnly={false}
      // onFocus={(e, i, s) => {
      //   console.log(e, i, s);
      // }}
      preserveWhitespace={true}
      scrollingContainer=".parent-scroll2"
      bounds="#scrolling-container2"
      onChangeSelection={(range, source, quill) => {
        // const index = quill.getSelection().index;

        articleBodyTemp = quill?.getHTML();

        if (quill?.getHTML() === '<p><br></p>') {
          dispatch(featureUpdate('isArticleBodyValid', false));
          // console.log('pas bon');
        } else {
          // console.log('bon');
          // console.log(quill?.getHTML());
          dispatch(featureUpdate('isArticleBodyValid', true));
        }
      }}
    />
  );
}

// Component for display ReactQuill
function ReactQuillDisplay() {
  const articleBody = useSelector(
    (state: HelpDTO) => state.help.feature.selectedFeature?.articleBody
  );
  // const [quillValue, setQuillValue] = useState(articleBody);

  return (
    <ReactQuill
      theme="snow"
      value={articleBody || ''}
      // onChange={(e) => {

      //   setQuillValue(e);

      // }}
      // modules={modules}
      readOnly={true}
      // onChangeSelection={(range, source, quill) => {
      //   // temp variable for each change.
      //   articleBodyTemp = quill.getHTML();

      // }}
    />
  );
}

const FeatureModal = () => {
  const {
    selectedFeature,
    selectedCategory,
    edit,
    create,
    newFeature,
    isTitleValid,
    hasValidate,
    isArticleBodyValid,
    loading,
    loadingFeature,
  } = useSelector((state: HelpDTO) => state.help.feature);
  // const { featureCategories: categories } = useSelector((state:HelpDTO) => state.help);
  const isSuperAdmin = useSelector((state) => state.projects.isSuperAdmin);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Cancel Create or edit button
  const handleCancel = (e) => {
    // console.log(e);
    // dispatch(featureEditModeOff());
    dispatch(featureCloseModal());
  };

  // Validate New Feature
  const handleValidateFeature = (e, featureType) => {
    dispatch(featureUpdate('hasValidate', true));

    if (isTitleValid && isArticleBodyValid && featureType === 'createFeature') {
      // console.log('create feature');
      // console.log(featureType);
      // console.log(e);
      console.log('createFeature');
      dispatch(featureUpdate('loading', true));
      dispatch(createNewFeature(articleBodyTemp));
    }

    if (isTitleValid && isArticleBodyValid && featureType === 'editFeature') {
      // console.log('create feature');
      // console.log(featureType);
      // console.log(e);
      console.log('editFeature');
      dispatch(featureUpdate('loading', true));
      dispatch(editFeatureUpdate('articleBody', articleBodyTemp));
      console.log('validation de la feature à faire');
      dispatch(patchFeature());
      // dispatch(featureUpdate('loading', true));
      // dispatch(createNewFeature(articleBodyTemp));
    }

    console.log('click');
  };

  return (
    <div className={edit ? 'featModal add' : create ? 'featModal add' : 'featModal'}>
      <div className="featModal_header">
        {/* <h1 className='featModal_header--title'>{selectedCategory && (selectedCategory.order)} &rsaquo; {(create && selectedCategory) &&(selectedCategory.title)}
                 </h1> */}
        <h1 className="featModal_header--title">
          {selectedCategory && selectedCategory.order} &rsaquo;
          {selectedCategory && selectedCategory.title} &rsaquo;
          {selectedFeature && !edit && selectedFeature.title}
        </h1>
        {/* //TODO icon when superadmin and display feature */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!create && !edit && isSuperAdmin && selectedFeature?.isVisible && (
            <div className="">
              <VisibilityIcon style={{ color: '#44DDC3' }} />
            </div>
          )}
          {!create && !edit && isSuperAdmin && !selectedFeature?.isVisible && (
            <div className="">
              <VisibilityOffIcon style={{ color: '#CE4C33' }} />
            </div>
          )}

          {!create && !edit && isSuperAdmin && (
            <img
              src={editIcone}
              alt="edition"
              onClick={() => {
                console.log('enter edit mode');
                articleBodyTemp = selectedFeature?.articleBody
                  ? selectedFeature.articleBody
                  : '';
                dispatch(featureEditModeOn());
                // dispatch(handleEnterEditMode());
              }}
              style={{ cursor: 'pointer', marginLeft: '1rem' }}
            />
          )}
          {!create && !edit && isSuperAdmin && (
            <div className="">
              <DeleteIcon
                style={{
                  color: '#CE4C33',
                  marginLeft: '16px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  // console.log(selectedFeature);

                  if (selectedFeature) {
                    dispatch(deleteFeature(selectedFeature.id));
                    dispatch(featureCloseModal());
                  }
                }}
              />
            </div>
          )}
        </div>

        {edit && (
          <form noValidate autoComplete="off" style={{ flexGrow: '1' }}>
            <TextField
              id="outlined-basic"
              label={t('APP.FEATUREPAGE.FEATUREMODAL.PH_C6D4FA', {
                defaultValue: 'Titre de la fonctionnalité',
              })}
              variant="outlined"
              helperText={
                !isTitleValid
                  ? t('APP.FEATUREPAGE.FEATUREMODAL.PH_3F0E04', {
                      defaultValue: "Entrez un titre d'au moins 4 caractères",
                    })
                  : ''
              }
              value={selectedFeature?.title}
              onChange={(e) => {
                console.log('change');

                if (e.currentTarget.value.length < 5) {
                  // console.log('titre trop court');
                  dispatch(featureUpdate('isTitleValid', false));
                } else {
                  dispatch(featureUpdate('isTitleValid', true));
                  // console.log('titre ok');
                }

                dispatch(editFeatureUpdate('title', e.currentTarget.value));
              }}
              error={!isTitleValid}
              style={{ width: '100%' }}
            />
          </form>
        )}

        {create && (
          // title Form
          <form
            noValidate
            autoComplete="off"
            style={{ flexGrow: '1', marginLeft: '1em' }}
          >
            <TextField
              id="outlined-basic"
              label={t('APP.FEATUREPAGE.FEATUREMODAL.PH_3DF74C', {
                defaultValue: 'Titre de la fonctionnalité',
              })}
              variant="outlined"
              helperText={
                !isTitleValid && hasValidate
                  ? t('APP.FEATUREPAGE.FEATUREMODAL.PH_0172A7', {
                      defaultValue: "Entrez un titre d'au moins 5 caractères",
                    })
                  : ''
              }
              value={newFeature?.title}
              onChange={(e) => {
                if (e.currentTarget.value.length < 5) {
                  // console.log('titre trop court');
                  dispatch(featureUpdate('isTitleValid', false));
                } else {
                  dispatch(featureUpdate('isTitleValid', true));
                  // console.log('titre ok');
                }

                dispatch(newFeatureUpdate('title', e.currentTarget.value));
              }}
              error={!isTitleValid && hasValidate}
              style={{ width: '100%' }}
            />
          </form>
        )}
        {!create && edit && isSuperAdmin && selectedFeature?.isVisible && (
          <div className="">
            <VisibilityIcon style={{ color: '#44DDC3', marginLeft: '1rem' }} />
          </div>
        )}
        {!create && edit && isSuperAdmin && !selectedFeature?.isVisible && (
          <div className="">
            <VisibilityOffIcon style={{ color: '#CE4C33', marginLeft: '1rem' }} />
          </div>
        )}
      </div>
      {selectedFeature && loadingFeature && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader
            variant="indeterminate"
            disableShrink
            style={{ color: '#73b7ec' }}
            size={25}
            thickness={4}
          />
        </div>
      )}

      {/* {(selectedFeature && !loadingFeature ) &&(
                <div className='featModal_main'>
                  {selectedFeature?.articleBody}
                  articleBody a afficher
                  {
                    selectedFeature.articleBody && (
                      <ReactQuillEdit />
                      
                    )
                  }
                </div>
               )} */}
      {selectedFeature?.articleBody && !edit && !loadingFeature && (
        <ReactQuillDisplay />
      )}

      {edit && selectedFeature?.articleBody !== undefined && !loadingFeature && (
        <>
          <div id="toolbar2" style={{ display: 'flex', alignItems: 'start' }}>
            <select className="ql-header" defaultValue="">
              <option value="1"></option>
              <option value="2"></option>
              <option value=""></option>
            </select>
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-strike"></button>
            <button className="ql-blockquote"></button>
            <button className="ql-list" value="ordered" />
            <button className="ql-list" value="bullet" />
            <select className="ql-align" />
            <select className="ql-link" />
            <select className="ql-color" defaultValue="">
              <option value="#1B2559"></option>
              <option value="#44DDC3"></option>
              <option value="#344750"></option>
              <option value="#71A1FD"></option>
              <option value="#9E77FD"></option>
              <option value="#CE4C33"></option>
              <option value=""></option>
            </select>
            <select className="ql-background" defaultValue="">
              <option value="#1B2559"></option>
              <option value="#44DDC3"></option>
              <option value="#344750"></option>
              <option value="#71A1FD"></option>
              <option value="#9E77FD"></option>
              <option value="#CE4C33"></option>
              <option value=""></option>
            </select>
            <button className="ql-image"></button>
            <button className="ql-clean"></button>
          </div>
          <div className="parent-scroll2">
            <div id="scrolling-container2">
              <ReactQuillEdit />
              {!isArticleBodyValid && (
                <div
                  style={{
                    marginLeft: '1rem',
                    fontStyle: 'italic',
                    color: 'red',
                  }}
                >
                  {t('APP.FEATUREPAGE.FEATUREMODAL.PH_A6C5F5', {
                    defaultValue:
                      'Vous ne pouvez pas enregistrer une fonctionnalité vide',
                  })}
                </div>
              )}

              {/* {ReactQuillEdit(selectedFeature.articleBody)}
               */}
              {loading && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Loader
                    variant="indeterminate"
                    disableShrink
                    style={{ color: '#73b7ec' }}
                    size={25}
                    thickness={4}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {create && (
        <>
          <div id="toolbar" style={{ display: 'flex', alignItems: 'start' }}>
            <select className="ql-header" defaultValue="">
              <option value="1"></option>
              <option value="2"></option>
              <option value=""></option>
            </select>
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-strike"></button>
            <button className="ql-blockquote"></button>
            <button className="ql-list" value="ordered" />
            <button className="ql-list" value="bullet" />
            <select className="ql-align" />
            <select className="ql-link" />
            <select className="ql-color" defaultValue="">
              <option value="#1B2559"></option>
              <option value="#44DDC3"></option>
              <option value="#344750"></option>
              <option value="#71A1FD"></option>
              <option value="#9E77FD"></option>
              <option value="#CE4C33"></option>
              <option value=""></option>
            </select>
            <select className="ql-background" defaultValue="">
              <option value="#1B2559"></option>
              <option value="#44DDC3"></option>
              <option value="#344750"></option>
              <option value="#71A1FD"></option>
              <option value="#9E77FD"></option>
              <option value="#CE4C33"></option>
              <option value=""></option>
            </select>
            <button className="ql-image"></button>
            <button className="ql-clean"></button>
          </div>
          <div className="parent-scroll">
            <div id="scrolling-container">
              <ReactQuillNew />
              {!isArticleBodyValid && hasValidate && (
                <div
                  style={{
                    marginLeft: '1rem',
                    fontStyle: 'italic',
                    color: 'red',
                  }}
                >
                  {t('APP.FEATUREPAGE.FEATUREMODAL.PH_0BEDA1', {
                    defaultValue:
                      'Vous ne pouvez pas enregistrer une fonctionnalité vide',
                  })}
                </div>
              )}

              {loading && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Loader
                    variant="indeterminate"
                    disableShrink
                    style={{ color: '#73b7ec' }}
                    size={25}
                    thickness={4}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {isSuperAdmin && (
        <div className="featModal_footer">
          {edit && (
            <>
              <div className="featModal_footer--deliveryDate">
                <div
                  style={{
                    marginRight: '0.5rem',
                    color: 'white',
                    fontWeight: '500',
                  }}
                >
                  {t('APP.FEATUREPAGE.FEATUREMODAL.PH_6628E6', {
                    defaultValue: "Date d'activation :",
                  })}
                </div>
                <div>
                  <DatePicker
                    className={'datePicker'}
                    clearIcon={null}
                    // locale={locale}
                    // format={dateFormat}
                    onChange={(e) => {
                      console.log(e);
                      console.log(typeof e);
                      // dispatch(featureUpdate('dateTemp2', e ));
                      dispatch(editFeatureUpdate('deliveryDate', e));
                    }}
                    value={new Date(selectedFeature?.deliveryDate)}
                  />
                </div>
              </div>
              {/* IsVisible */}
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {/* <div style={{display: 'flex!important', alignItems:'center!important', justifyContent: 'center!important'}}> */}
                <NoocoCheckbox
                  checked={!selectedFeature?.isVisible}
                  onChange={() => {
                    dispatch(
                      editFeatureUpdate('isVisible', !selectedFeature?.isVisible)
                    );
                  }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />

                <div>
                  {t('APP.FEATUREPAGE.FEATUREMODAL.PH_239CA7', {
                    defaultValue: 'Coché = invisible',
                  })}
                </div>
                {/* </div> */}
              </div>
              <button
                className="featModal_footer--button"
                type="button"
                onClick={handleCancel}
              >
                {t('APP.FEATUREPAGE.FEATUREMODAL.PH_8227F9', {
                  defaultValue: 'Annuler',
                })}
              </button>
              <button
                className="featModal_footer--button"
                type="button"
                onClick={(e) => {
                  handleValidateFeature(e, 'editFeature');
                }}
              >
                {t('APP.FEATUREPAGE.FEATUREMODAL.PH_56F6DB', {
                  defaultValue: 'Valider',
                })}
              </button>
            </>
          )}

          {create && (
            <>
              <div className="featModal_footer--deliveryDate">
                <div
                  style={{
                    marginRight: '0.5rem',
                    color: 'white',
                    fontWeight: '500',
                  }}
                >
                  {t('APP.FEATUREPAGE.FEATUREMODAL.PH_05BC55', {
                    defaultValue: "Date d'activation :",
                  })}
                </div>
                <div>
                  <DatePicker
                    className={'datePicker'}
                    clearIcon={null}
                    // locale={locale}
                    // format={dateFormat}
                    onChange={(e, date) => {
                      console.log(e, date);
                      console.log(typeof e);
                      // dispatch(featureUpdate('dateTemp2', e ));
                      dispatch(newFeatureUpdate('deliveryDate', e));
                    }}
                    value={newFeature?.deliveryDate}
                  />
                </div>
              </div>

              {/* IsVisible */}
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {/* <div style={{display: 'flex!important', alignItems:'center!important', justifyContent: 'center!important'}}> */}
                <NoocoCheckbox
                  checked={!newFeature?.isVisible}
                  onChange={() => {
                    dispatch(newFeatureUpdate('isVisible', !newFeature?.isVisible));
                  }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />

                <div>
                  {t('APP.FEATUREPAGE.FEATUREMODAL.PH_02A5E3', {
                    defaultValue: 'Coché = invisible',
                  })}
                </div>
                {/* </div> */}
              </div>
              <button
                className="featModal_footer--button"
                type="button"
                onClick={handleCancel}
              >
                {t('APP.FEATUREPAGE.FEATUREMODAL.PH_060E51', {
                  defaultValue: 'Annuler',
                })}
              </button>
              <button
                className="featModal_footer--button"
                type="button"
                onClick={(e) => {
                  if (!loading) {
                    handleValidateFeature(e, 'createFeature');
                  }
                }}
              >
                <div style={{ marginRight: '0.5rem' }}>Créer</div>
                {loading && (
                  <Loader
                    variant="indeterminate"
                    disableShrink
                    style={{ color: '#73b7ec' }}
                    size={15}
                    thickness={4}
                  />
                )}
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default FeatureModal;
