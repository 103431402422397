import {
  REFERENCE_TYPE_NAME,
  TYPE_AIRPORT,
  TYPE_DATACENTER,
  TYPE_EPCM,
  TYPE_NOOCO,
  TYPE_PROTOTYPE,
  TYPE_RE2020,
  TYPE_RENOVATION,
} from '../globalVariable/typeProject';
import { findParentNode } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const SearchBar = ({
  groups,
  searchValue,
  handleSearchValue,
  inputValue,
  handleInputValue,
  onChange,
  onBlur,
  currentProject,
  classicBar,
  editMode,
  projectAllot,
  updateFormValue,
  currentAllotment,
  originFrom,
  setSelectedQuantity,
  setRefCaracs,
  setRefCaracsLenth,
  setUserCaracs,
  currentEquipment,
  validZonesIds,
}) => {
  const [firstRender, setFirstRender] = useState(false);
  const { t } = useTranslation();
  const { listOfNodesSearchBar, allGroups, nodeSearchValue } = useSelector(
    (state) => state.projects
  );
  const dispatch = useDispatch();
  const sheetsToUse = validZonesIds
    ? currentProject.Sheets.filter((equipment) =>
        validZonesIds.includes(equipment.ProjectSheet.ZoneId)
      )
    : currentProject.Sheets;

  // TODO_TRAD : fr
  let newGroups = classicBar
    ? [
        ...groups.filter((el) => {
          return (
            el.lot !== 'Eau' &&
            el.lot !== 'Energie' &&
            !el.materiel.includes('DONNEE FORFAITAIRE')
          );
        }),
      ]
    : [
        ...groups.filter((masterData) =>
          sheetsToUse.some((equipment) =>
            equipment.ProjectSheet.AllotmentNodes?.find(
              (node) => node.MasterDataEquipmentId === masterData.id
            )
          )
        ),
        ...sheetsToUse.filter(
          (el) => el.type_fiche !== 'users' && !el.type_fiche.includes('EMPTY')
        ),
      ];

  newGroups = newGroups.filter((el) => {
    if (
      (currentProject.templateType === TYPE_EPCM &&
        !el.referenceType?.includes(REFERENCE_TYPE_NAME.EPCM)) ||
      (currentProject.templateType !== TYPE_EPCM &&
        el.referenceType &&
        el.referenceType?.length === 1 &&
        el.referenceType?.includes(REFERENCE_TYPE_NAME.EPCM))
    ) {
      return false;
    }

    return true;
  });

  const templateTypeForTransportAndGears = [
    TYPE_NOOCO,
    TYPE_DATACENTER,
    TYPE_PROTOTYPE,
    TYPE_AIRPORT,
    TYPE_RENOVATION,
    TYPE_RE2020,
    TYPE_EPCM,
  ];

  if (templateTypeForTransportAndGears.includes(currentProject.templateType)) {
    newGroups = newGroups.filter(
      (equipment) => equipment.lot !== 'Transports et engins'
    );
  }

  newGroups.sort(function compare(a, b) {
    if (a.name < b.name) return -1;

    if (a.name > b.name) return 1;

    return 0;
  });

  let oldGroups = newGroups;

  let listOfNodesSearchBarWithoutObsoleteExceptProductModeling = [];

  const customProducts = currentProject.Lots.find(
    (lot) => lot.name === 'Produits sur mesure' || lot.name === 'Produits modélisés'
  );

  listOfNodesSearchBar.forEach((nodeSearchBar) => {
    let isParentFromModeling = null;

    if (customProducts && !classicBar) {
      isParentFromModeling = findParentNode(
        nodeSearchBar,
        allGroups.flatAllotments,
        [],
        []
      )[0].details.find(
        (el) =>
          el.level === 0 &&
          ['Produits sur mesure', 'Produits modélisés'].includes(el.name)
      );
    }

    if (!nodeSearchBar.isObsolete || isParentFromModeling) {
      listOfNodesSearchBarWithoutObsoleteExceptProductModeling.push(nodeSearchBar);
    }
  });

  newGroups = listOfNodesSearchBarWithoutObsoleteExceptProductModeling.filter(
    (nodeSearchBar) => {
      let nodesDetails = findParentNode(
        nodeSearchBar,
        allGroups.flatAllotments,
        [],
        []
      );

      if (
        currentProject.Lots.find((lot) =>
          nodesDetails.find(
            (nodeDetail) => nodeDetail.name.toLowerCase() === lot.name.toLowerCase()
          )
        ) &&
        oldGroups.find(
          (oldGroup) => oldGroup.id === nodeSearchBar.MasterDataEquipmentId
        )
      ) {
        if (!classicBar) {
          let bool = false;

          sheetsToUse.map((sheet) => {
            if (
              sheet.ProjectSheet.AllotmentNodes?.find(
                (node) => node.id === nodeSearchBar.id
              )
            ) {
              bool = true;
            }
          });

          return bool;
        } else {
          return true;
        }
      }

      return false;
    }
  );

  newGroups = newGroups.filter(
    (elem, index, self) => index === self.findIndex((t) => t.name === elem.name)
  );

  return (
    <Autocomplete
      noOptionsText={t('APP.SHARED.SEARCHBAR.PH_624DFC', {
        defaultValue: 'Aucun résultat trouvé',
      })}
      value={editMode ? currentEquipment : nodeSearchValue || searchValue}
      onChange={(event, newValue) => {
        setFirstRender(false);

        let old = null;

        if (newValue?.type_fiche) {
          old = groups.find(
            (el) => el.id === newValue.ProjectSheet.MasterDataEquipmentId
          );
        }

        if (old) {
          handleSearchValue(old);
        } else {
          if (newValue) {
            handleSearchValue(newValue);
          }
        }

        if (originFrom === 'homeAddEquipment' && newValue) {
          console.log('Changement update Value');
          setSelectedQuantity('');
          setRefCaracs(null);
          setRefCaracsLenth(0);
          setUserCaracs([]);
        }

        if (onChange) {
          if (old) {
            onChange(old);
          } else {
            onChange(newValue);
          }
        }

        if (!editMode && !classicBar) {
          let allotSteps = [];

          let current = old || newValue;

          if (current) {
            projectAllot.structureGraph.forEach((el) => {
              let currentNode = current.id === el['0'];

              if (currentNode) {
                const copy = [...el.details].reverse();

                updateFormValue('sheetToLifeCycle', el.sheets);
                updateFormValue('allotSteps', copy);

                return;
              }
            });
          }
        }
      }}
      inputValue={inputValue}
      closeIcon={
        <CloseIcon
          onClick={() => {
            if (updateFormValue) {
              updateFormValue('sheetToLifeCycle', null);
              updateFormValue('allotSteps', []);
              updateFormValue('searchValue', null);
              updateFormValue('nodeSearchValue', null);
            }
          }}
          fontSize="small"
        />
      }
      getOptionLabel={(option) => {
        if (
          option.Keywords?.length > 0 &&
          option?.Keywords?.find((el) =>
            el?.name?.toLowerCase().includes(inputValue?.toLowerCase())
          )
        ) {
          const find = option.Keywords.find((el) =>
            el.name.toLowerCase().includes(inputValue.toLowerCase())
          );

          if (find) {
            const md = oldGroups.find((el) => el.id === find.MasterDataEquipmentId);

            setFirstRender(true);

            return editMode && searchValue?.type_fiche === 'PEP'
              ? option.name
              : editMode && !firstRender
              ? md?.name
              : !firstRender && md
              ? md?.name
              : option?.name;
          }
        } else if (!isNaN(Number(inputValue)) && option.id_inies && !editMode) {
          return option.id_inies.toString();
        } else {
          return option.name ? option.name : '';
        }
      }}
      renderOption={(option) => option.subName || option.name}
      onInputChange={(event, newInputValue) => {
        handleInputValue(newInputValue);
      }}
      id="combo-box-demo"
      options={[...newGroups]}
      style={{ display: 'flex', flex: '1', minWidth: '250px' }}
      renderInput={(params) => {
        return (
          <TextField
            autoFocus={editMode ? false : classicBar}
            onBlur={onBlur}
            autoComplete="new-password"
            {...params}
            label={t('APP.SHARED.SEARCHBAR.PH_C0D208', {
              defaultValue: 'Rechercher un equipement',
            })}
            variant="outlined"
          />
        );
      }}
    />
  );
};

export default SearchBar;
