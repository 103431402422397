import React from 'react';
import { useTranslation } from 'react-i18next';

const WasteWater = () => {
  const { t } = useTranslation();

  return (
    <div className="calculatorWaterWrapper">
      {t('APP.CALCULATORWATERCONTENT.WASTEWATER.PH_67A84B', {
        defaultValue: 'WasteWater',
      })}
    </div>
  );
};

export default WasteWater;
