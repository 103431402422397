import './simulatorInformation.scss';
import { Controller } from 'react-hook-form';
import { StateDTO } from '../../models/GeneralDTO';
import {
  patchQuickSimulatorInformation,
  updateSimulator,
} from '../../../actions/simulator';
import { useDebounce } from '../../../hooks/utils/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@material-ui/core/Radio';
import React from 'react';
import SelectUi from '@mui/material/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

const SimulatorInformation = ({
  control,
  setValue,
  getValues,
  errors,
  clearErrors,
  classes,
  handleSubmit,
}) => {
  const { loadingInformation } = useSelector((state: StateDTO) => state.simulator);
  const [lifeSpan, setLifeSpan] = useState('');
  const [area, setArea] = useState('');
  const debounceArea = useDebounce(area, 300);
  const debounceLifespan = useDebounce(lifeSpan, 300);
  const { t } = useTranslation();

  useEffect(() => {
    if (debounceLifespan) {
      handleSubmit(onSubmit(getValues()));
    }
  }, [debounceLifespan]);

  useEffect(() => {
    if (debounceArea) {
      handleSubmit(onSubmit(getValues()));
    }
  }, [debounceArea]);

  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(patchQuickSimulatorInformation(data));
  };
  const form = getValues();
  return (
    <div className="simulatorInformationWrapper">
      {loadingInformation && (
        <div className="information-spinner-wrapper">
          <div className="donut" />
        </div>
      )}

      {!loadingInformation && (
        <>
          <div className="label">
            <p>
              {t('APP.SIMULATORINFORMATION.SIMULATORINFORMATION.PH_C30B68', {
                defaultValue: 'Durée de vie du système',
              })}
            </p>
          </div>
          <div className="input">
            <Controller
              name="lifespan"
              control={control}
              // rules={{ required: true }}
              rules={{
                required: true,
                validate: {
                  isNumber: (v) => {
                    if (!isNaN(Number(v?.replace(/,/, '.')))) {
                      return true;
                    } else {
                      return false;
                    }
                  },
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder={t(
                    'APP.SIMULATORINFORMATION.SIMULATORINFORMATION.PH_666357',
                    { defaultValue: 'Durée de vie' }
                  )}
                  variant="outlined"
                  className={`simulatorInput researchBar ${
                    errors?.lifespan ? 'error-border-form' : ''
                  }`}
                  onChange={(e) => {
                    setLifeSpan(e.target.value);
                    setValue('lifespan', e.target.value);
                  }}
                />
              )}
            />
          </div>
          <div className="label">
            <p>
              {t('APP.SIMULATORINFORMATION.SIMULATORINFORMATION.PH_8F6260', {
                defaultValue: "Choix de l'unité de référence",
              })}
            </p>
            <div className="simulatorTooltip">
              <Tooltip
                title={t('APP.SIMULATORINFORMATION.SIMULATORINFORMATION.PH_4F0CD7', {
                  defaultValue:
                    'Tous les calculs d’impact de votre projet seront exprimés selon l’unité de référence choisie : /m² de la surface de référence du projet ou bien en total kg éq. CO2.',
                })}
                classes={{ tooltip: classes.tooltip }}
              >
                <InfoIcon />
              </Tooltip>
            </div>
          </div>
          <div className="input">
            <Controller
              name="refUnit"
              control={control}
              rules={{
                required: t(
                  'APP.SIMULATORINFORMATION.SIMULATORINFORMATION.PH_AB8004',
                  {
                    defaultValue: 'Veuillez renseigner une unitée de référence.',
                  }
                ),
              }}
              // defaultValue={"total"}
              render={({ field }) => (
                <SelectUi
                  {...field}
                  MenuProps={{ disableScrollLock: true }}
                  // defaultValue={"total"}
                  variant="outlined"
                  className={`simulatorInput researchBar`}
                  onChange={(e) => {
                    setValue('refUnit', e.target.value);

                    if (e.target.value === 'total') {
                      setValue('area', '');
                      clearErrors('area');
                    }

                    handleSubmit(onSubmit)();
                  }}
                >
                  <MenuItem
                    value="m²"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    m²
                  </MenuItem>
                  <MenuItem
                    value="total"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    Total
                  </MenuItem>
                </SelectUi>
              )}
            />
          </div>
          {form.refUnit === 'm²' && (
            <>
              <div className="label">
                <p>
                  {t('APP.SIMULATORINFORMATION.SIMULATORINFORMATION.PH_099711', {
                    defaultValue: 'Surface',
                  })}
                </p>
              </div>
              <div className="input">
                <Controller
                  name="area"
                  control={control}
                  // rules={{ required: true }}
                  rules={{
                    required: form.refUnit === 'm²' ? true : false,
                    validate: {
                      isNumber: (v) => {
                        if (!isNaN(Number(v?.replace(/,/, '.')))) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={form.refUnit === 'm²' ? false : true}
                      placeholder={t(
                        'APP.SIMULATORINFORMATION.SIMULATORINFORMATION.PH_C82573',
                        { defaultValue: 'Surface' }
                      )}
                      variant="outlined"
                      className={`simulatorInput researchBar ${
                        errors?.area ? 'error-border-form' : ''
                      } ${form.refUnit === 'total' ? 'disabled' : ''}`}
                      onChange={(e) => {
                        setArea(e.target.value);
                        setValue('area', e.target.value);
                      }}
                    />
                  )}
                />
              </div>
            </>
          )}
          <div className="radioGroup">
            <div className="radioGroup-raw">
              <Radio
                checked={form.style === 'static'}
                className="simulatorRadio"
                value="static"
                name="static"
                onClick={() => {
                  dispatch(updateSimulator('loadingGraph', true));
                  setValue('style', 'static');
                  handleSubmit(onSubmit)();
                }}
              />
              <p>
                {t('APP.SIMULATORINFORMATION.SIMULATORINFORMATION.PH_76E611', {
                  defaultValue: 'Statique',
                })}
              </p>
            </div>
            <div className="radioGroup-raw">
              <Radio
                checked={form.style === 'dynamic'}
                className="simulatorRadio"
                value="dynamic"
                name="dynamic"
                onClick={() => {
                  dispatch(updateSimulator('loadingGraph', true));

                  setValue('style', 'dynamic');
                  // dispatch(updateSimulatorInformation(getValues()));
                  handleSubmit(onSubmit)();
                }}
              />
              <p>
                {t('APP.SIMULATORINFORMATION.SIMULATORINFORMATION.PH_5C0F42', {
                  defaultValue: 'Dynamique',
                })}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SimulatorInformation;
