import './equipmentListFilter.scss';
import { AllGroupsDTO } from '../../../models/ProjectsDTO';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, Input } from '@photocarbone/nooco-ui';
import { Dropdown, MenuProps, Select } from 'antd';
import { ProjectDTO, ZoneDTO } from '../../../models/ProjectDTO';
import { StateDTO } from '../../../models/GeneralDTO';
import {
  TEMPLATE_TYPE_ID,
  TYPE_EPCM,
  TYPE_RE2020,
} from '../../../globalVariable/typeProject';
import { Tooltip } from '@material-ui/core';
import { applyFiltersToZones } from '../../../shared/groupByEquipments';
import {
  changeValueEquipments,
  openImportModal,
} from '../../../../actions/equipments';
import { exportXlsx } from '../ListEquipments/exportXlsx';
import { isProjectCertified, isProjectSingleZoneOnly } from '../../../shared/utils';
import {
  selectCalculatorWater,
  selectCalculatorWorksite,
} from '../../../../actions/calculator';
import { updateFormValue } from '../../../../actions/projects';
import { useDebounce } from '../../../../hooks/utils/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from 'react-use';
import AddIcon from '@mui/icons-material/Add';
import CalculateIcon from '@mui/icons-material/Calculate';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MultipleActionsButton from '../../../shared/MultipleActionsButton';
import QuickAddBarEquipment from '../../../shared/QuickAddBarEquipment/QuickAddBarEquipment';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import EditButtons from '../ListEquipments/EditButtons';
import { redirectToManualAdd } from '../../../../hooks/useQuery/equipments';

type FilterOptions = 'all' | 'zone' | 'buildingSite' | 'plot' | 'energie' | 'eau';
type ViewOptions =
  | 'buildingAndZone'
  | 'import'
  | 'lots'
  | 'contrib'
  | 'nomenclature';
type SurfaceRefOptions = 'building' | 'zone' | 'totalProject';

export enum GroupByOptions {
  IMPORT = 'import',
  ZONES = 'zones',
  LOTS = 'lots',
  CONTRIBUT = 'contrib',
  NOMENCLATURES = 'nomenclature',
}

type EquipmentListFilterProps = {
  zones: ZoneDTO[];
  allGroups: AllGroupsDTO;
  currentProject: ProjectDTO;
  deleteSelection: () => void;
  filterBy: FilterOptions[];
  setFilterBy: (filter: FilterOptions[]) => void;
  allEquipmentRows: any[];
  isQuickAddBarListEquipmentsOpen: boolean;
  setIsQuickAddBarListEquipmentsOpen: (
    isQuickAddBarListEquipmentsOpen: boolean
  ) => void;
  handleSubmitForm: Function;
  isLoadingPatchEquipments: Boolean;
  tableEditModeRef: React.RefObject<boolean>;
};

const EquipmentListFilter: React.FC<EquipmentListFilterProps> = ({
  zones,
  allGroups,
  currentProject,
  deleteSelection,
  filterBy,
  setFilterBy,
  allEquipmentRows,
  isQuickAddBarListEquipmentsOpen,
  setIsQuickAddBarListEquipmentsOpen,
  handleSubmitForm,
  isLoadingPatchEquipments,
  tableEditModeRef,
}) => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [zoneCount, setZoneCount] = useState<number>(0);
  const [plotCount, setPlotCount] = useState<number>(0);
  const [buildingSiteCount, setBuildingSiteCount] = useState<number>(0);
  const [energieCount, setEnergieCount] = useState<number>(0);
  const [eauCount, setEauCount] = useState<number>(0);
  const { t } = useTranslation();

  const {
    dataTable,
    selectedRowKeys,
    searchBarValue,
    referenceSurfaceTag,
    filtersTableActive,
    columns,
    hideColumns,
  } = useSelector((state: StateDTO) => state.equipments);

  const isTableModeEditRefTrue = tableEditModeRef.current === true;

  const { licenceTypeAccess, currentIndicator } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { importStep } = useSelector((state: StateDTO) => state.imports);
  const {
    isOldBuildingSiteInProject,
    isBuildingSiteActivatedInParams,
    isOldWaterLotInProject,
  } = useSelector((state: StateDTO) => state.projects.calculator);
  const [surfaceRef, setSurfaceRef] = useLocalStorage<SurfaceRefOptions>(
    `surfaceRef-${currentProject.id}`,
    'building'
  );
  const [groupBy, setGroupBy] = useLocalStorage(
    `groupBy-${currentProject.id}`,
    'import'
  );
  const [searchBar, setSearchBar, removeSearchBar] = useLocalStorage(
    `searchBar-${currentProject.id}`
  );
  const [searchBarValueState, setSearchBarValueState] = useState(searchBar || '');
  const dispatch = useDispatch();

  const debounceSearchInput = useDebounce(searchBarValueState, 200);

  const animationVariants = {
    hidden: { height: 0 },
    visible: { height: 'auto' },
  };

  useEffect(() => {
    if (importStep === 1) {
      setGroupBy(GroupByOptions.IMPORT);
    }
  }, [importStep]);

  useEffect(() => {
    dispatch(changeValueEquipments('searchBarValue', searchBarValueState));
    setSearchBar(searchBarValueState);

    if (searchBarValueState === undefined || searchBarValueState === '') {
      removeSearchBar();
    }
  }, [debounceSearchInput]);

  useEffect(() => {
    dispatch(changeValueEquipments('referenceSurfaceTag', surfaceRef));
  }, [surfaceRef]);

  useEffect(() => {
    if (groupBy) {
      dispatch(changeValueEquipments('groupBy', groupBy));
    }
  }, [groupBy]);

  useEffect(() => {
    setTotalCount(allEquipmentRows.length);
    setZoneCount(
      countEquipmentsInZoneArray(
        applyFiltersToZones(zones, ['zone'], allGroups, currentProject)
      )
    );
    setPlotCount(
      countEquipmentsInZoneArray(
        applyFiltersToZones(zones, ['plot'], allGroups, currentProject)
      )
    );
    setBuildingSiteCount(
      countEquipmentsInZoneArray(
        applyFiltersToZones(zones, ['buildingSite'], allGroups, currentProject)
      )
    );
    setEnergieCount(
      countEquipmentsInZoneArray(
        applyFiltersToZones(zones, ['energie'], allGroups, currentProject)
      )
    );
    setEauCount(
      countEquipmentsInZoneArray(
        applyFiltersToZones(zones, ['eau'], allGroups, currentProject)
      )
    );
  }, [zones, allEquipmentRows]);

  useEffect(() => {
    if (
      currentProject.refUnit === 'total' &&
      referenceSurfaceTag !== 'totalProject'
    ) {
      handleSurfaceRefChange('totalProject');
    }
  }, []);

  const updateAllFiltersFromTableOnReset = (dataIndex) => {
    let newData = filtersTableActive.map((col) => {
      if (col.name === dataIndex) {
        col.values = [];
      }

      return col;
    });

    changeValueEquipments('filtersTableActive', newData);

    if (filtersTableActive.some((col) => col.values.length > 0)) {
      localStorage.setItem(
        `stateOfFilter-${currentProject.id}`,
        JSON.stringify(newData)
      );
    } else {
      localStorage.removeItem(`stateOfFilter-${currentProject.id}`);
    }
  };

  const removeTag = (columnName: string | null = null) => {
    let data = [...filtersTableActive];

    data.map((col) => {
      if (columnName) {
        if (col.name === columnName) {
          col.values = [];
        }
      } else {
        col.values = [];
      }

      return col;
    });

    dispatch(changeValueEquipments('filtersTableActive', data));

    if (filtersTableActive.some((col) => col.values.length > 0)) {
      localStorage.setItem(
        `stateOfFilter-${currentProject.id}`,
        JSON.stringify(data)
      );
    } else {
      localStorage.removeItem(`stateOfFilter-${currentProject.id}`);
    }
  };

  function formatTags(tags: string[], maxLength: number): string {
    let result = '';
    let currentIndex = 0;

    while (currentIndex < tags.length) {
      const currentTag = tags[currentIndex]?.toString() || '';
      const separator = result.length > 0 ? ', ' : '';
      const newResult = result + separator + currentTag;

      if (newResult.length < maxLength) {
        result = newResult;
        currentIndex++;
      } else {
        const truncatedTag =
          currentTag.substring(0, maxLength - result.length) + '...' || '';

        result += separator + truncatedTag;
        break;
      }
    }

    if (currentIndex < tags.length - 1) {
      const numRemaining = tags.length - 1 - currentIndex;

      result += `et ${numRemaining} autre${numRemaining > 1 ? 's' : ''}`;
    }

    return result;
  }

  const handleViewChange = (view: ViewOptions) => {
    let finalView: any = view;

    if (view === 'buildingAndZone') {
      finalView = 'zones';
    }

    setGroupBy(finalView);
    dispatch(changeValueEquipments('groupBy', finalView));
  };

  const handleFilterChange = (filterOption: FilterOptions) => {
    // Reset filter
    if (filterOption === 'all') {
      setFilterBy(['project']);
    }
    // Delete filter
    else if (filterBy.includes(filterOption)) {
      const filters = filterBy.filter((item) => item !== filterOption);

      setFilterBy(filters.length ? filters : ['project']);
    }
    // Add filter
    else {
      setFilterBy([
        ...Object.values(filterBy).filter((filter) => filter !== 'project'),
        filterOption,
      ]);
    }
  };

  const handleSurfaceRefChange = (value: SurfaceRefOptions) => {
    dispatch(changeValueEquipments('referenceSurfaceTag', value));
    setSurfaceRef(value);
  };

  const countEquipmentsInZoneArray = (zones: any) => {
    return zones.reduce((acc: any, zone: any) => {
      acc += zone.ProjectSheets.length;

      return acc;
    }, 0);
  };

  const addEquipmentMenu: MenuProps['items'] = [
    {
      key: 'quick',
      onClick: () => {
        setIsQuickAddBarListEquipmentsOpen(true);
      },
      label: (
        <span>
          {t('APP.EQUIPMENTLISTFILTER.INDEX.QUICK_ADD', {
            defaultValue: 'Ajout rapide',
          })}
        </span>
      ),
    },
    {
      key: 'manual',
      label: (
        <span>
          {t('APP.EQUIPMENTLISTFILTER.INDEX.MANUAL_ADD', {
            defaultValue: 'Ajout manuel',
          })}
        </span>
      ),
      onClick: () => {
        if (isTableModeEditRefTrue) {
          dispatch(
            changeValueEquipments('isOpenModalExitNavigationConfirmation', 'manual')
          );
        } else {
          redirectToManualAdd(dispatch);
        }
        // e.preventDefault();
      },
    },
    {
      key: 'import',
      label: (
        <span>
          {t('APP.EQUIPMENTLISTFILTER.INDEX.IMPORT_FILE', {
            defaultValue: 'Importer un fichier',
          })}
        </span>
      ),
      onClick: () => {
        dispatch(openImportModal());
      },
    },
  ];

  const getCalculateWorksiteDisplayInformation = (): {
    tooltip: string;
    isDisabled: boolean;
  } => {
    if (licenceTypeAccess !== 'pro') {
      return {
        tooltip: t('APP.EQUIPMENTLISTFILTER.INDEX.PH_7CCD03', {
          defaultValue: 'Fonctionnalité disponible avec une licence pro seulement',
        }),
        isDisabled: true,
      };
    }

    if (!isBuildingSiteActivatedInParams) {
      return {
        tooltip: t('APP.EQUIPMENTLISTFILTER.INDEX.PH_F4550C', {
          defaultValue:
            'Pour utiliser la calculette chantier, veuillez d’abord ajouter une zone chantier dans les paramètres',
        }),
        isDisabled: true,
      };
    }

    if (isOldBuildingSiteInProject) {
      return {
        tooltip: t('APP.EQUIPMENTLISTFILTER.INDEX.PH_19BEDC', {
          defaultValue:
            "Des éléments du chantier ont été ajoutés en dehors de la calculatrice chantier. Pour utiliser la calculatrice, supprimer ces éléments manuellement depuis la liste des contributeurs. Ils sont identifiables à l'aide du champ source dans les colonnes cachées de la liste des contributeurs (valeur différente de 'calculette chantier').",
        }),
        isDisabled: true,
      };
    }

    return {
      tooltip: '',
      isDisabled: false,
    };
  };

  const getCalculateWaterDisplayInformation = (): {
    tooltip: string;
    isDisabled: boolean;
  } => {
    if (currentProject.templateType !== TYPE_RE2020) {
      return {
        tooltip: t('APP.EQUIPMENTLISTFILTER.INDEX.PH_E9D4D7', {
          defaultValue: 'Bientôt disponible',
        }),
        isDisabled: true,
      };
    }

    if (licenceTypeAccess !== 'pro') {
      return {
        tooltip: t('APP.EQUIPMENTLISTFILTER.INDEX.PH_3FC0DA', {
          defaultValue: 'Fonctionnalité disponible avec une licence pro seulement',
        }),
        isDisabled: true,
      };
    }

    // if (!isBuildingSiteActivatedInParams) {
    //   return {
    //     tooltip: "Pour utiliser la calculette eau, veuillez d’abord ajouter une zone chantier dans les paramètres",
    //     isDisabled: true
    //   };
    // }

    if (isOldWaterLotInProject) {
      return {
        tooltip: t('APP.EQUIPMENTLISTFILTER.INDEX.PH_A7DDF9', {
          defaultValue:
            "Pour utiliser la calculette eau, veuillez d’abord supprimer tous les contributeurs du lot 'EAU' hors consommation d'eau du chantier",
        }),
        isDisabled: true,
      };
    }

    return {
      tooltip: '',
      isDisabled: false,
    };
  };

  const {
    tooltip: tooltipForCalculatorWorksite,
    isDisabled: isDisabledCalculatorWorksite,
  } = getCalculateWorksiteDisplayInformation();
  const {
    tooltip: tooltipForCalculatorWater,
    isDisabled: isDisabledCalculatorWater,
  } = getCalculateWaterDisplayInformation();

  const calculateMenu: MenuProps['items'] = [
    {
      key: 'worksite',
      disabled: isDisabledCalculatorWorksite,
      label: (
        <Tooltip title={tooltipForCalculatorWorksite}>
          <span>Calculette chantier</span>
        </Tooltip>
      ),

      onClick: () => {
        if (!isDisabledCalculatorWorksite) {
          if (isTableModeEditRefTrue) {
            dispatch(
              changeValueEquipments(
                'isOpenModalExitNavigationConfirmation',
                'selectCalculatorWorksite'
              )
            );
          } else {
            dispatch(selectCalculatorWorksite());
          }
        }
      },
    },
    {
      key: 'water',
      disabled: isDisabledCalculatorWater,
      label: (
        <Tooltip title={tooltipForCalculatorWater}>
          <span>Calculette eau</span>
        </Tooltip>
      ),

      onClick: () => {
        if (isTableModeEditRefTrue) {
          dispatch(
            changeValueEquipments(
              'isOpenModalExitNavigationConfirmation',
              'selectCalculatorWater'
            )
          );
        } else {
          dispatch(selectCalculatorWater());
        }
      },
    },
  ];

  const optionsSurfaceRef = (): { label: string; value: string }[] => {
    if (currentProject.refUnit === 'total') {
      return [
        {
          label: t('APP.EQUIPMENTLISTFILTER.INDEX.PH_FD72F9', {
            defaultValue: 'Sréf Total projet',
          }),
          value: 'totalProject',
        },
      ];
    }

    return [
      {
        label: t('APP.EQUIPMENTLISTFILTER.INDEX.PH_385A15', {
          defaultValue: 'Sréf Bâtiments',
        }),
        value: 'building',
      },
      {
        label: t('APP.EQUIPMENTLISTFILTER.INDEX.PH_38A70E', {
          defaultValue: 'Sréf Zone',
        }),
        value: 'zone',
      },
      {
        label: t('APP.EQUIPMENTLISTFILTER.INDEX.PH_40D214', {
          defaultValue: 'Sréf Total projet',
        }),
        value: 'totalProject',
      },
    ];
  };

  const statusKeys = (t) => ({
    validated: t('APP.MULTIPLEACTIONSBUTTON.STATUS.VALIDATED_LABEL', {
      defaultValue: 'verifié',
    }),
    reliable: t('APP.MULTIPLEACTIONSBUTTON.STATUS.RELIABLE_LABEL', {
      defaultValue: 'fiable',
    }),
    toCheck: t('APP.MULTIPLEACTIONSBUTTON.STATUS.TO_CHECK_LABEL', {
      defaultValue: 'à vérifier',
    }),
  });

  const RenderTag = React.memo(({ name, values }) => {
    if (values.length === 0) {
      return;
    }

    const filters = [...values];
    const matchingColumn = columns.find((e) => e.dataIndex === name);
    const matchingHideColumn = hideColumns.find((e) => e.dataIndex === name);

    const columnName: string =
      (typeof matchingColumn?.title === 'string' && matchingColumn?.title) ||
      matchingHideColumn?.title ||
      matchingColumn?.dataIndex ||
      matchingHideColumn?.dataIndex;

    if (name === 're2020Lot') {
      filters.map((filter, index) => {
        filters[index] = filter ? filter.split(' ')[0] : filter;
      });
    } else if (name === 'status') {
      filters.map((filter, index) => {
        filters[index] = statusKeys(t)[filter];
      });
    }

    return (
      <div className="tableHeader_header3_tagList_tag">
        {`${columnName} : ${formatTags(filters, 20)}`}
        <button
          className="tableHeader_header3_tagList_tag_delete"
          onClick={() => removeTag(name)}
        >
          <CloseIcon />
        </button>
      </div>
    );
  });

  return (
    <div className="tableHeader">
      <div className="tableHeader_header1">
        <div className="tableHeader_header1_view">
          <label
            htmlFor="tableHeader_header1_view_container"
            className="tableHeader_header1_view_label"
          >
            {t('APP.EQUIPMENTLISTFILTER.INDEX.PH_06E85E', {
              defaultValue: 'Vue par :',
            })}
          </label>
          <div
            id="tableHeader_header1_view_container"
            className="tableHeader_header1_view_container"
          >
            <button
              onClick={() => handleViewChange('import')}
              className={`tableHeader_header1_view_container_button ${
                groupBy === GroupByOptions.IMPORT
                  ? 'tableHeader_header1_view_container_button--active'
                  : ''
              }`}
            >
              {t('APP.EQUIPMENTLISTFILTER.INDEX.IMPORT', {
                defaultValue: 'Import',
              })}
            </button>
            {!isProjectSingleZoneOnly(zones) && (
              <button
                onClick={() => handleViewChange('buildingAndZone')}
                className={`tableHeader_header1_view_container_button ${
                  groupBy === GroupByOptions.ZONES
                    ? 'tableHeader_header1_view_container_button--active'
                    : ''
                }`}
              >
                {t('APP.EQUIPMENTLISTFILTER.INDEX.PH_157830', {
                  defaultValue: 'Bâtiments/Zones',
                })}
              </button>
            )}

            <button
              onClick={() => handleViewChange('lots')}
              className={`tableHeader_header1_view_container_button ${
                groupBy === GroupByOptions.LOTS
                  ? 'tableHeader_header1_view_container_button--active'
                  : ''
              }`}
            >
              {t('APP.EQUIPMENTLISTFILTER.INDEX.PH_6167F5', {
                defaultValue: 'Lots',
              })}
            </button>
            {isProjectCertified(currentProject.templateType) && (
              <button
                onClick={() => handleViewChange('contrib')}
                className={`tableHeader_header1_view_container_button ${
                  groupBy === GroupByOptions.CONTRIBUT
                    ? 'tableHeader_header1_view_container_button--active'
                    : ''
                }`}
              >
                {t('APP.EQUIPMENTLISTFILTER.INDEX.PH_BBCD14', {
                  defaultValue: 'Contributeurs',
                })}
              </button>
            )}

            <button
              onClick={() => handleViewChange('nomenclature')}
              className={`tableHeader_header1_view_container_button ${
                groupBy === GroupByOptions.NOMENCLATURES
                  ? 'tableHeader_header1_view_container_button--active'
                  : ''
              }`}
            >
              {t('APP.EQUIPMENTLISTFILTER.INDEX.PH_02BA79', {
                defaultValue: 'Nomenclatures',
              })}
            </button>
          </div>
        </div>
        <div className="tableHeader_header1_action">
          {/* Save Button */}
          <Tooltip
            title={
              licenceTypeAccess === 'pro'
                ? t('APP.EQUIPMENTLISTFILTER.INDEX.PH_26A491', {
                    defaultValue: 'Téléchargement de la liste au format Excel',
                  })
                : t('APP.EQUIPMENTLISTFILTER.INDEX.PH_9D4C31', {
                    defaultValue:
                      'Fonctionnalité disponible avec une licence pro seulement',
                  })
            }
          >
            <div>
              <Button
                disabled={licenceTypeAccess !== 'pro'}
                className={`save-button tableHeader_header1_action_button tableHeader_header1_action_button--download`}
                centeredIcon={<DownloadIcon />}
                onClick={(e) => {
                  if (licenceTypeAccess === 'pro') {
                    exportXlsx({
                      dataTable,
                      groupBy,
                      currentProject,
                      currentIndicator,
                      t,
                      re2020Allotments:
                        allGroups.allotments.find(
                          (allotment) => allotment.id === TEMPLATE_TYPE_ID.RE2020
                        )?.AllotmentNodes || [],
                    });
                  }
                }}
              />
            </div>
          </Tooltip>

          {/* Calculate Button */}
          <Dropdown
            placement="bottom"
            trigger={['hover']}
            menu={{ items: calculateMenu }}
            overlayClassName={'customDropdown'}
          >
            <Tooltip
              title={t('APP.EQUIPMENTLISTFILTER.INDEX.PH_295945', {
                defaultValue: 'Accéder aux calculettes chantier et eau',
              })}
            >
              <button
                className={
                  'nooco-button purple-button tableHeader_header1_action_button tableHeader_header1_action_button--calculate'
                }
              >
                <CalculateIcon />
              </button>
            </Tooltip>
          </Dropdown>

          {/* Add Button */}
          <Dropdown
            placement="bottom"
            trigger={['hover']}
            menu={{ items: addEquipmentMenu }}
            overlayClassName={'customDropdown'}
          >
            <button
              className={
                'nooco-button purple-button tableHeader_header1_action_button tableHeader_header1_action_button--add'
              }
            >
              {t('APP.EQUIPMENTLISTFILTER.INDEX.PH_4BD4E5', {
                defaultValue: 'Ajouter',
              })}
              <AddIcon />
            </button>
          </Dropdown>
          <EditButtons
            handleSubmitForm={handleSubmitForm}
            isLoadingPatchEquipments={isLoadingPatchEquipments}
          />
        </div>
      </div>
      <AnimatePresence initial={false}>
        {isQuickAddBarListEquipmentsOpen && (
          <motion.div
            className="tableHeader_quickAdd"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={animationVariants}
            transition={{ duration: 0.2 }}
          >
            <QuickAddBarEquipment
              setIsQuickAddBarListEquipmentsOpen={setIsQuickAddBarListEquipmentsOpen}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="tableHeader_header2">
        <div className="tableHeader_header2_filter">
          <div className="tableHeader_header2_filter_wrapper">
            <div className="tableHeader_header2_filter_wrapper_container">
              <button
                onClick={() => handleFilterChange('all')}
                className={`tableHeader_header2_filter_wrapper_container_button ${
                  filterBy.includes('project')
                    ? 'tableHeader_header2_filter_wrapper_container_button--active'
                    : ''
                }`}
              >
                {t('APP.EQUIPMENTLISTFILTER.INDEX.PH_72748A', {
                  defaultValue: 'Tout',
                })}
                <span className="tableHeader_header2_filter_wrapper_container_button_count">
                  {totalCount}
                </span>
              </button>
            </div>
            <div className="tableHeader_header2_filter_wrapper_container">
              <button
                onClick={() => handleFilterChange('zone')}
                className={`tableHeader_header2_filter_wrapper_container_button ${
                  filterBy.includes('zone')
                    ? 'tableHeader_header2_filter_wrapper_container_button--active'
                    : ''
                }`}
              >
                {t('APP.EQUIPMENTLISTFILTER.INDEX.PH_367080', {
                  defaultValue: 'Composants',
                })}
                <span className="tableHeader_header2_filter_wrapper_container_button_count">
                  {zoneCount}
                </span>
              </button>
              {zones.some((zone: any) => zone.type === 'buildingSite') ? (
                <button
                  onClick={() => handleFilterChange('buildingSite')}
                  className={`tableHeader_header2_filter_wrapper_container_button ${
                    filterBy.includes('buildingSite')
                      ? 'tableHeader_header2_filter_wrapper_container_button--active'
                      : ''
                  }`}
                >
                  {t('APP.EQUIPMENTLISTFILTER.INDEX.PH_30A6FA', {
                    defaultValue: 'Chantier',
                  })}
                  <span className="tableHeader_header2_filter_wrapper_container_button_count">
                    {buildingSiteCount}
                  </span>
                </button>
              ) : (
                <></>
              )}
              <button
                onClick={() => handleFilterChange('energie')}
                className={`tableHeader_header2_filter_wrapper_container_button ${
                  filterBy.includes('energie')
                    ? 'tableHeader_header2_filter_wrapper_container_button--active'
                    : ''
                }`}
              >
                {t('APP.EQUIPMENTLISTFILTER.INDEX.PH_FB33AF', {
                  defaultValue: 'Energie',
                })}
                <span className="tableHeader_header2_filter_wrapper_container_button_count">
                  {energieCount}
                </span>
              </button>
              <button
                onClick={() => handleFilterChange('eau')}
                className={`tableHeader_header2_filter_wrapper_container_button ${
                  filterBy.includes('eau')
                    ? 'tableHeader_header2_filter_wrapper_container_button--active'
                    : ''
                }`}
              >
                {t('APP.EQUIPMENTLISTFILTER.INDEX.PH_5B1000', {
                  defaultValue: 'Eau',
                })}
                <span className="tableHeader_header2_filter_wrapper_container_button_count">
                  {eauCount}
                </span>
              </button>
              {zones.some(
                (zone: any) =>
                  zone.type === 'plot' && currentProject.templateType !== TYPE_EPCM
              ) ? (
                <button
                  onClick={() => handleFilterChange('plot')}
                  className={`tableHeader_header2_filter_wrapper_container_button ${
                    filterBy.includes('plot')
                      ? 'tableHeader_header2_filter_wrapper_container_button--active'
                      : ''
                  }`}
                >
                  {t('APP.EQUIPMENTLISTFILTER.INDEX.PH_C88050', {
                    defaultValue: 'Parcelle',
                  })}
                  <span className="tableHeader_header2_filter_wrapper_container_button_count">
                    {plotCount}
                  </span>
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="tableHeader_header2_search">
            <div
              className={`tableHeader_header2_search_wrapper ${
                searchBarValue && 'tableHeader_header2_search_wrapper--filled'
              }`}
            >
              <Input
                data-testid="contributor-list-search"
                defaultValue={searchBarValueState}
                onChange={(value) => {
                  setSearchBarValueState(value);
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    setSearchBarValueState(event.target.value);
                  }
                }}
                onBlur={(event) => {
                  setSearchBarValueState(event.target.value);
                }}
                type="text"
                placeholder={t('APP.EQUIPMENTLISTFILTER.INDEX.PH_FCD5A7', {
                  defaultValue: 'Rechercher',
                })}
                size={'small'}
                startIcon={<SearchIcon />}
                className={`tableHeader_header2_search_wrapper_input`}
                endIcon={
                  searchBarValue ? (
                    <button
                      className={'tableHeader_header2_search_wrapper_clear'}
                      onClick={() => {
                        setSearchBarValueState('');
                        document.querySelector(
                          '.tableHeader_header2_search_wrapper .textfield input'
                        ).value = '';
                      }}
                    >
                      <CloseIcon />
                    </button>
                  ) : null
                }
              />
            </div>
          </div>
          <div className="tableHeader_header2_surfaceRef">
            <Select
              suffixIcon={<KeyboardArrowDownIcon />}
              placeholder={'Sréf Bâtiments'}
              popupClassName={'customSelect'}
              defaultValue={surfaceRef}
              options={optionsSurfaceRef()}
              onSelect={(surfaceRefValue) => handleSurfaceRefChange(surfaceRefValue)}
            />

            <div className="tableHeader_header2_surfaceRef_info">
              <Tooltip
                title={t('APP.EQUIPMENTLISTFILTER.INDEX.PH_0A87CD', {
                  defaultValue:
                    'Sélectionner l’échelle de la surface de référence. Tous les résultats de la liste des contributeurs seront ramenés à la surface correspondante. Ce filtre ne s’applique pas aux éléments de la parcelle, qui sont toujours ramenés à la surface totale du projet',
                })}
              >
                <InfoIcon />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="tableHeader_header2_action">
          <MultipleActionsButton
            deleteSelection={deleteSelection}
            listEquipment={true}
            searchEquipOptions={null}
            linesSelected={selectedRowKeys}
          />
        </div>
        {filtersTableActive &&
        !filtersTableActive?.every(
          (column) => column.values && column.values.length === 0
        ) ? (
          <div className="tableHeader_header3">
            <p className="tableHeader_header3_text">Filtre : </p>
            <button
              className="tableHeader_header3_reset"
              onClick={() => removeTag()}
            >
              {t('APP.EQUIPMENTLISTFILTER.INDEX.PH_940C2E', {
                defaultValue: 'Réinitialiser',
              })}
            </button>
            <div className="tableHeader_header3_tagList">
              {filtersTableActive &&
                filtersTableActive.map((table) => {
                  return (
                    <RenderTag
                      key={table.name}
                      name={table.name}
                      values={table.values}
                    />
                  );
                })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EquipmentListFilter;
