import './projectsList.scss';
import { ProjectCard } from './ProjectCard/ProjectCard';
import { StateDTO } from '../../../models/GeneralDTO';
import { updateFormValue } from '../../../../actions/projects';
// import { useDebounce } from 'react-use';
import { ProjectDTO } from '../../../models/ProjectDTO';
import { getProjectsByFolderResponse } from '../../../../apiCallFunctions/project/project';
import { useDebounce } from '../../../../hooks/utils/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useGetProjectsByFolder } from '../../../../hooks/useQuery/projects/getProjectsByFolder';
import { useGetProjectsBySearch } from '../../../../hooks/useQuery/projects/getProjects';
import { useIntersection } from 'react-use';
import { useTranslation } from 'react-i18next';
import Loader from '@material-ui/core/CircularProgress';
import React, { useEffect, useRef, useState } from 'react';
import useCase from '../useCase/useCase';

type ProjectsListProps = {
  onClick: Function;
};

export const ProjectsList = ({ onClick }: ProjectsListProps) => {
  const { projectsList, projectInput, projects } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { selectedCompanyId, isExistChildrenCompanies } = useSelector(
    (state: StateDTO) => state.projects.projectsListMonitoring.arianeCompany
  );
  const { selectedPathId } = useSelector(
    (state: StateDTO) => state.projects.projectsListMonitoring.arianeFolder
  );
  const { projectTypeFilter, isInit } = useSelector(
    (state: StateDTO) => state.projects.projectsListMonitoring
  );
  const { creationProjectLoading } = useSelector(
    (state: StateDTO) => state.projects.creationProject
  );
  const { getProjectsBySearch, getProjectsByFolder } = useCase();
  let dispatch = useDispatch();
  const { t } = useTranslation();

  const onSuccess = (data) => {
    getProjectsBySearch(data);
  };
  const onSuccessGetProjectsByFolder = (data: getProjectsByFolderResponse) => {
    getProjectsByFolder(data);
  };

  const debounceProjectInput = useDebounce(projectInput, 300);
  const loaderRef = useRef(null);

  const intersection = useIntersection(loaderRef, {
    root: null,
    rootMargin: '600px',
  });
  const intersected = intersection && intersection.isIntersecting;

  useEffect(() => {
    if (intersected && hasNextPageBySearch) {
      fetchNextPageBySearch();
    }
    if (intersected && hasNextPageByFolder) {
      fetchNextPageByFolder();
    }
  }, [intersected]);

  const messageToDisplay = () => {
    const chooseCompany = t('APP.PROJECTSLIST.PROJECTSLIST.PH_F495EB', {
      defaultValue: 'Veuillez choisir votre entreprise',
    });

    const noProjects = t('APP.PROJECTSLIST.PROJECTSLIST.PH_9DB54F', {
      defaultValue: 'Aucun projet existant',
    });

    if (isExistChildrenCompanies) {
      return chooseCompany;
    }

    return noProjects;
  };

  const [flatProjectsBySearch, setFlatProjectsBySearch] = useState<ProjectDTO[]>([]);
  const [flatProjectsByFolder, setFlatPRojectsByFolder] = useState<ProjectDTO[]>([]);

  useEffect(() => {
    const newFlatProjects = projectsList.flatMap((item) => item.rows);

    setFlatProjectsBySearch(newFlatProjects);
  }, [projectsList, debounceProjectInput]);

  useEffect(() => {
    const newFlatProjects = projects
      .flatMap((item) => item?.rows)
      .filter((project) => project?.ProjectTypeId !== 9);

    setFlatPRojectsByFolder(newFlatProjects);
  }, [projects]);

  const {
    isLoading: isLoadingBySearch,
    fetchNextPage: fetchNextPageBySearch,
    hasNextPage: hasNextPageBySearch,
    isFetchingNextPage: isFetchingNextPageBySearch,
  } = useGetProjectsBySearch(
    debounceProjectInput,
    projectTypeFilter,
    selectedCompanyId,
    onSuccess
  );

  const {
    isLoading: isLoadingByFolder,
    fetchNextPage: fetchNextPageByFolder,
    hasNextPage: hasNextPageByFolder,
    isFetchingNextPage: isFetchingNextPageByFolder,
  } = useGetProjectsByFolder(
    isExistChildrenCompanies,
    selectedCompanyId,
    selectedPathId,
    projectTypeFilter,
    onSuccessGetProjectsByFolder
  );

  return (
    <div className="projectsListWrapper">
      {/* Loading */}
      {(isLoadingBySearch || isLoadingByFolder) && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Loader
            variant="indeterminate"
            disableShrink
            style={{ color: '#73b7ec' }}
            size={30}
            thickness={4}
          />
        </div>
      )}
      {/* display when projects or projectsList empty */}
      {flatProjectsBySearch.length === 0 &&
        flatProjectsByFolder.length === 0 &&
        !isLoadingByFolder &&
        !isLoadingBySearch && <div className="listEmpty">{messageToDisplay()}</div>}

      {/* Display projects by search */}
      {projectsList.length > 0 &&
        flatProjectsBySearch.map((project, index) => (
          <ProjectCard
            key={`${project.id}-${index}`}
            project={project}
            onClick={onClick}
          />
        ))}

      {/* Display projects by folder */}
      {projects.length > 0 &&
        projectInput === '' &&
        flatProjectsByFolder.map((project, index) => (
          <ProjectCard
            key={`${project.id}-${index}`}
            project={project}
            onClick={onClick}
          />
        ))}
      {/* Ref for fetchNextPage */}
      {(projectsList.length > 0 || projects.length > 0) && (
        <div
          ref={loaderRef}
          style={{ width: '100%', textAlign: 'center', minHeight: '100px' }}
        >
          {isFetchingNextPageBySearch || isFetchingNextPageByFolder ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Loader
                variant="indeterminate"
                disableShrink
                style={{ color: '#73b7ec' }}
                size={30}
                thickness={4}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
};
