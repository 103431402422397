import { klona } from 'klona';

let zones = [];

const toSref = (zoneArea, acv) => {
  for (const [key, value] of Object.entries(acv.base)) {
    acv.base[key] = value * zoneArea;
  }

  for (const [key, value] of Object.entries(acv.usage)) {
    acv.usage[key] = value * zoneArea;
  }
};

export const formatSheets = (
  projectSheets,
  mds,
  sousLot,
  isDynamic,
  projectArea,
  indicatorId
) => {
  if (!indicatorId) indicatorId = 1;

  let useStatic = false;

  if (indicatorId === 26) {
    useStatic = true;
    indicatorId = 1;
  }

  let newSheets = [];

  const parsingProjectSheet = (projectSheet) => {
    const masterDataEquipment = mds.find((md) => {
      const allotmentNode = projectSheet.AllotmentNodes.find(
        (node) => node.MasterDataEquipmentId
      );
      if (allotmentNode) {
        return md.id === allotmentNode.MasterDataEquipmentId;
      }
      return false;
    });
    let newSheet = {};

    let sheetZone = zones.find((zone) => zone.id === projectSheet.ZoneId);

    if (!sheetZone) return;

    if (sheetZone.type === 'buildingSite') {
      sheetZone = zones.find((zone) => zone.id === sheetZone.ParentId);
    }

    projectSheet.MasterDataEquipment = masterDataEquipment;

    if (masterDataEquipment) {
      const mdSousLot = sousLot.find(
        (lot) => lot.id === masterDataEquipment?.ReSousLotId
      );

      projectSheet.MasterDataEquipment.ReSousLot = mdSousLot ? mdSousLot.name : '1';
    }

    newSheet = projectSheet.Sheet;

    delete projectSheet.Sheet;
    newSheet.ProjectSheet = projectSheet;
    if (!newSheet.ACV) {
      return newSheets.push(newSheet);
    }
    newSheet.acvResults =
      useStatic || indicatorId !== 1
        ? newSheet.ACV[indicatorId].static
        : newSheet.ACV[indicatorId].dynamic;

    if (newSheet.acvResults?.usage?.b1 && masterDataEquipment.hasFluid) {
      newSheet.acvResults.usage.b1EquipFluid = newSheet.acvResults?.usage?.b1;

      delete newSheet.acvResults.usage.b1;
    }

    // TODO TEAMRORO Improve this part : just fetch the sheet itself if it's its own re2020 eq
    if (newSheet.ReEqs && newSheet.ReEqs[0]) {
      if (newSheet.ReEqs[0].Re2020Id === newSheet.id) {
        newSheet.ReEqs[0].Re2020 = klona(newSheet);
      }
      newSheet.ReEqs[0].Re2020.acvResults =
        useStatic || indicatorId !== 1
          ? newSheet.ReEqs[0].Re2020.ACV[indicatorId].static
          : newSheet.ReEqs[0].Re2020.ACV[indicatorId].dynamic;
      newSheet.Re2020Sheet = newSheet.ReEqs[0].Re2020;
      newSheet.Re2020Sheet.endDateEquiv = newSheet.ReEqs[0].endDate;
    }

    toSref(sheetZone.area, newSheet.acvResults);

    if (newSheet.Re2020Sheet) {
      toSref(sheetZone.area, newSheet.Re2020Sheet.acvResults);
    }

    // if sheet has children
    if (newSheet.ProjectSheet.ProjectSheets) {
      newSheet.ProjectSheet.ProjectSheets.forEach((childPs) => {
        childPs.MasterDataEquipment = mds.find(
          (md) => md.id === childPs.MasterDataEquipmentId
        );
        newSheet.childEquip = childPs.Sheet;
        newSheet.childEquip.ProjectSheet = childPs;

        delete newSheet.childEquip.ProjectSheet.Sheet;
      });
    }

    newSheet.Caracs = projectSheet.Caracs;
    delete newSheet.ProjectSheets;
    newSheets.push(newSheet);
  };

  projectSheets.forEach((projectSheet) => {
    if (projectSheet.type_fiche === 'bundle') {
      projectSheet.BundledProjectSheets.forEach((bundledProjectSheet) => {
        parsingProjectSheet(bundledProjectSheet);
      });
    } else {
      parsingProjectSheet(projectSheet);
    }
  });

  return newSheets;
};

export const parseProject = (project, mds, sousLot, indicator) => {
  if (!project) {
    throw new Error('No project');
  }

  zones = [];

  let projectSheets = getSubZonesRecursive(project);

  return {
    parsed: formatSheets(
      projectSheets,
      mds,
      sousLot,
      project.isAcvDynamic,
      project.area,
      indicator.id
    ),
    raw: projectSheets,
    zones,
  };
};

const getSubZonesRecursive = (zone) => {
  let currentZoneSheets = [];

  if (Object.prototype.hasOwnProperty.call(zone, 'ParentId')) {
    zones.push(zone);
  }

  if (zone.ProjectSheets) {
    let psFormats = [];

    zone.ProjectSheets.forEach((ps) => {
      if (ps.Sheet.BundledProjectSheets) {
        psFormats = [...psFormats, ps, ...ps.Sheet.BundledProjectSheets];
      } else {
        psFormats.push(ps);
      }
    });
    currentZoneSheets = psFormats;
  }

  if (zone.Zones && zone.Zones.length > 0) {
    zone.Zones.forEach((zone) => {
      currentZoneSheets = currentZoneSheets?.concat(getSubZonesRecursive(zone));
    });
  }

  return currentZoneSheets;
};
