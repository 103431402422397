import { Button } from '@photocarbone/nooco-ui';
import { NewActionDTO } from '../../../../models/ExploitationDTO';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import SelectUi from '@mui/material/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import eau from '../../../../../assets/images/equipments/water.png';
import elec from '../../../../../assets/images/equipments/energy.png';

// import '../../../../projects/EquipmentList/AddEquipment/addEquipments.scss';
import './energywater.scss';
import { StateDTO } from '../../../../models/GeneralDTO';
import { TYPE_EPCM, TYPE_RE2020 } from '../../../../globalVariable/typeProject';
import {
  filterNodesByProjectType,
  findBreadcrumbChoices,
  findMdByAllotmentNodeId,
  handleAllotmentNodes,
} from '../../../../shared/utils';
import { useSelector } from 'react-redux';
import RangePickerCustom from '../../../../components/RangePicker';
import { useTranslation } from 'react-i18next';

interface EnergyWaterState {
  consumer: string;
  allGroups: {
    type: [];
  };
  zones: [];
  updateExploitationValue: Function;
  breadcrumbChoice: string;
  selectedFonction: string;
  selectedCategory: string;
  selectedMaterial: string;
  selectedType: string;
  newAction: NewActionDTO;
  resetAddAction: Function;
  createAction: Function;
}

const EnergyWater = ({
  consumer,
  allGroups,
  zones,
  updateExploitationValue,
  breadcrumbChoice,
  selectedFonction,
  selectedCategory,
  selectedMaterial,
  selectedType,
  newAction,
  getCaracs,
  resetAddAction,
  createAction,
}: EnergyWaterState) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { flatAllotments } = useSelector(
    (state: StateDTO) => state.projects.allGroups
  );
  const { currentProject } = useSelector((state: StateDTO) => state.projects);
  const { t } = useTranslation();

  useEffect(() => {
    let nodes = filterNodesByProjectType(
      flatAllotments,
      TYPE_RE2020,
      allGroups.type
    );

    let result = findBreadcrumbChoices(nodes, allGroups, consumer, zones);

    let names = [];

    for (const property in result) {
      result[property] = result[property]
        .map((node) => {
          if (names.includes(node.name)) {
            return;
          }

          const same = result[property].filter((el) => el.name === node.name);

          names = [...names, node.name];

          if (same.length > 1) {
            return same.reduce((acc, curr) => {
              const found = acc.name === curr.name ? acc : null;

              if (found) {
                found.full = [...found.full, ...curr.full];

                return found;
              }

              return curr;
            });
          }

          return node;
        })
        .filter((el) => el);
    }

    updateExploitationValue('breadcrumbChoice', result);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (selectedType) {
      let node;
      const value = allGroups.type.find(
        (el) =>
          el.lot === consumer &&
          el.categorie === selectedCategory &&
          el.fonction === selectedFonction &&
          el.materiel === selectedMaterial &&
          el.type === selectedType
      );

      getCaracs(value, 'consumer');
    }
  }, [selectedType]);

  const checkValidation = () => {
    if (
      !newAction.date ||
      !newAction.MasterDataEquipment ||
      !newAction.quantity ||
      (newAction.caracs.length > 0 && newAction.caracs.find((el) => !el.value))
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <div className="consumers-form">
        <div className="consumers-title">
          <img src={consumer === 'Eau' ? eau : elec} alt="" />
          <h4>{consumer}</h4>
        </div>
        <div className="consumers-breadcrumb">
          {breadcrumbChoice && (
            <>
              <div className="breadcrumb-part">
                <Tooltip
                  title={t(
                    'APP.ENERGYWATER.INDEX.PH_921DFC',

                    {
                      consumer,
                      defaultValue:
                        "Choisir la fonction liée aux consommations d'{consumer} à ajouter",
                    }
                  )}
                >
                  <p className="subtitle">
                    {t('APP.ENERGYWATER.INDEX.PH_A62F95', {
                      defaultValue: 'Fonction',
                    })}
                  </p>
                </Tooltip>
                <div className="choices">
                  {breadcrumbChoice.fonction.map((el) => (
                    <p
                      className={
                        el.name === selectedFonction
                          ? 'selected-choice'
                          : 'choice-active'
                      }
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        updateExploitationValue('selectedFonction', el.name);
                        updateExploitationValue('selectedCategory', undefined);
                        updateExploitationValue('selectedMaterial', undefined);
                        updateExploitationValue('selectedType', undefined);
                        updateExploitationValue('newAction', {
                          ...newAction,
                          MasterDataEquipment: null,
                        });
                      }}
                      key={el}
                    >
                      {el.name}
                    </p>
                  ))}
                </div>
              </div>
              <div className="breadcrumb-part">
                <Tooltip
                  title={t(
                    'APP.ENERGYWATER.INDEX.PH_B3CA82',

                    {
                      consumer,
                      defaultValue:
                        "Choisir la catégorie liée aux consommations d'{consumer} à ajouter",
                    }
                  )}
                >
                  <p className="subtitle">
                    {t('APP.ENERGYWATER.INDEX.PH_BC63BD', {
                      defaultValue: 'Catégorie',
                    })}
                  </p>
                </Tooltip>
                <div className="choices">
                  {breadcrumbChoice.categorie
                    .filter(
                      (elem, index, self) =>
                        index === self.findIndex((t) => t.name === elem.name)
                    )
                    .map((el) => (
                      <p
                        style={{
                          cursor: el.full.find(
                            (ele) =>
                              ele.lot === consumer &&
                              ele.fonction === selectedFonction
                          )
                            ? 'pointer'
                            : 'not-allowed',
                        }}
                        className={
                          el.full.find(
                            (ele) =>
                              ele.lot === consumer &&
                              ele.fonction === selectedFonction
                          ) && selectedCategory !== el.name
                            ? 'choice-active'
                            : selectedCategory === el.name
                            ? 'selected-choice'
                            : 'choice-inactive'
                        }
                        onClick={() => {
                          if (
                            el.full.find(
                              (ele) =>
                                ele.lot === consumer &&
                                ele.fonction === selectedFonction
                            )
                          ) {
                            updateExploitationValue('selectedCategory', el.name);
                            updateExploitationValue('selectedMaterial', undefined);
                            updateExploitationValue('selectedType', undefined);
                          }
                        }}
                        key={el.name}
                      >
                        {el.name}
                      </p>
                    ))}
                </div>
              </div>
              <div className="breadcrumb-part">
                <Tooltip
                  title={t(
                    'APP.ENERGYWATER.INDEX.PH_DB74CA',

                    {
                      consumer,
                      defaultValue:
                        "Choisir la matériel liée aux consommations d'{consumer} à ajouter",
                    }
                  )}
                >
                  <p className="subtitle">
                    {t('APP.ENERGYWATER.INDEX.PH_626516', {
                      defaultValue: 'Matériel',
                    })}
                  </p>
                </Tooltip>
                <div className="choices">
                  {breadcrumbChoice.materiel
                    .filter(
                      (elem, index, self) =>
                        index === self.findIndex((t) => t.name === elem.name)
                    )
                    .map((el) => (
                      <p
                        style={{
                          cursor: el.full.find(
                            (ele) =>
                              ele.lot === consumer &&
                              ele.fonction === selectedFonction &&
                              ele.categorie === selectedCategory
                          )
                            ? 'pointer'
                            : 'not-allowed',
                        }}
                        className={
                          el.full.find(
                            (ele) =>
                              ele.lot === consumer &&
                              ele.fonction === selectedFonction &&
                              ele.categorie === selectedCategory
                          ) && selectedMaterial !== el.name
                            ? 'choice-active'
                            : selectedMaterial === el.name
                            ? 'selected-choice'
                            : 'choice-inactive'
                        }
                        onClick={() => {
                          if (
                            el.full.find(
                              (ele) =>
                                ele.lot === consumer &&
                                ele.fonction === selectedFonction &&
                                ele.categorie === selectedCategory
                            )
                          ) {
                            updateExploitationValue('selectedMaterial', el.name);

                            updateExploitationValue('selectedType', undefined);
                          }
                        }}
                        key={el.name}
                      >
                        {el.name}
                      </p>
                    ))}
                </div>
              </div>
              <div className="breadcrumb-part">
                <Tooltip
                  title={t(
                    'APP.ENERGYWATER.INDEX.PH_8692C7',

                    {
                      consumer,
                      defaultValue:
                        "Choisir le type liée aux consommations d'{consumer} à ajouter",
                    }
                  )}
                >
                  <p className="subtitle">
                    {t('APP.ENERGYWATER.INDEX.PH_CD409A', {
                      defaultValue: 'Type',
                    })}
                  </p>
                </Tooltip>
                <div className="choices">
                  {breadcrumbChoice.type
                    .filter(
                      (elem, index, self) =>
                        index === self.findIndex((t) => t.name === elem.name)
                    )
                    .map((el) => (
                      <p
                        style={{
                          cursor: el.full.find(
                            (ele) =>
                              ele.lot === consumer &&
                              ele.fonction === selectedFonction &&
                              ele.categorie === selectedCategory &&
                              ele.materiel === selectedMaterial
                          )
                            ? 'pointer'
                            : 'not-allowed',
                        }}
                        className={
                          el.full.find(
                            (ele) =>
                              ele.lot === consumer &&
                              ele.fonction === selectedFonction &&
                              ele.categorie === selectedCategory &&
                              ele.materiel === selectedMaterial
                          ) && selectedType !== el.name
                            ? 'choice-active'
                            : selectedType === el.name
                            ? 'selected-choice'
                            : 'choice-inactive'
                        }
                        onClick={() => {
                          if (
                            el.full.find(
                              (ele) =>
                                ele.lot === consumer &&
                                ele.fonction === selectedFonction &&
                                ele.categorie === selectedCategory &&
                                ele.materiel === selectedMaterial
                            )
                          ) {
                            updateExploitationValue('selectedType', el.name);
                            getCaracs(
                              allGroups.type.find(
                                (ele) => ele.id === el.MasterDataEquipmentId
                              ),
                              'consumer'
                            );
                          }
                        }}
                        key={el.name}
                      >
                        {el.name}
                      </p>
                    ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="consumer-form-wrapper">
        <div className="consumers-form" style={{ width: '60%' }}>
          <div className="consumers-title">
            <h4>
              {t('APP.ENERGYWATER.INDEX.PH_90AB05', {
                defaultValue: 'Informations',
              })}
            </h4>
          </div>
          <div className="consumers-infos">
            <div style={{ width: '20%' }}>
              {/* TODO_TRAD : check next line */}
              <p>
                {t(
                  'APP.ENERGYWATER.INDEX.PH_6E56E3',

                  {
                    equipment: !!newAction.MasterDataEquipment,
                    unit: newAction.MasterDataEquipment
                      ? newAction.MasterDataEquipment.unit.slice(
                          0,
                          newAction.MasterDataEquipment.unit.indexOf('/')
                        )
                      : '',
                    defaultValue:
                      'Quantité {equipment, select, true {({unit} sur la période concernée)} other {}}',
                  }
                )}
                {/* Quantité
                         {newAction.MasterDataEquipment
                          ? `(${newAction.MasterDataEquipment.unit.slice(
                              0,
                              newAction.MasterDataEquipment.unit.indexOf('/')
                            )} sur la période concernée)`
                          : ''} */}
              </p>
              <TextField
                onChange={(e) =>
                  updateExploitationValue('newAction', {
                    ...newAction,
                    quantity: e.target.value,
                  })
                }
                disabled={!newAction.MasterDataEquipment}
                type="number"
                style={{ width: '100%' }}
                placeholder="-"
                variant="outlined"
                className="input-boarding researchBar"
              />
            </div>
            <div style={{ width: '35%' }}>
              <RangePickerCustom label={'Periode concernée'} />
            </div>
            <div style={{ width: '30%' }}>
              <p style={{ color: 'rgba(102, 102, 102, 0.53)' }}>
                Commentaire (facultatif)
              </p>
              <Tooltip
                title={t('APP.ENERGYWATER.INDEX.PH_3B5917', {
                  defaultValue: 'Ajouter un commentaire',
                })}
              >
                <TextField
                  onChange={(e) =>
                    updateExploitationValue('newAction', {
                      ...newAction,
                      comment: e.target.value,
                    })
                  }
                  type="text"
                  style={{ width: '100%' }}
                  placeholder="-"
                  variant="outlined"
                  className="input-boarding researchBar"
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="consumers-form" style={{ width: '40%' }}>
          <div className="consumers-title">
            <h4>
              {t('APP.ENERGYWATER.INDEX.PH_FC7A8F', {
                defaultValue: 'Caractéristiques',
              })}
            </h4>
          </div>
          <div className="consumers-infos">
            {!newAction.MasterDataEquipment && (
              <p style={{ textAlign: 'center', margin: 'auto' }}>
                {t('APP.ENERGYWATER.INDEX.PH_DE552F', {
                  defaultValue: "En attende de sélection d'un équipement",
                })}
              </p>
            )}

            {newAction.MasterDataEquipment && newAction.caracs.length === 0 && (
              <p style={{ textAlign: 'center', margin: 'auto' }}>
                {t('APP.ENERGYWATER.INDEX.PH_09F537', {
                  defaultValue: 'Aucune caractéristique à renseigner',
                })}
              </p>
            )}

            {newAction.MasterDataEquipment &&
              newAction.caracs.length > 0 &&
              newAction.caracs.map((el) => (
                <div key={el.id} style={{ width: '100%' }}>
                  <p>{el.name}</p>
                  {el.type !== 'list' ? (
                    <TextField
                      type="number"
                      style={{ width: '100%' }}
                      placeholder="-"
                      variant="outlined"
                      className="input-boarding researchBar"
                    />
                  ) : (
                    <SelectUi
                      onChange={(e) => {
                        let newCaracs = newAction.caracs.map((carac) => {
                          if (el.id === carac.id) {
                            return {
                              ...carac,
                              value: [e.target.value],
                            };
                          }

                          return carac;
                        });

                        updateExploitationValue('newAction', {
                          ...newAction,
                          caracs: newCaracs,
                        });
                      }}
                      variant="outlined"
                      className="input-boarding researchBar"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                    >
                      {el.values.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </SelectUi>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', marginLeft: 'auto', width: '15%' }}>
        <Button
          style={{
            fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
            marginRight: '10px',
          }}
          onClick={() => resetAddAction()}
          className="close-button"
          text="Fermer"
        />
        <Button
          style={{
            fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
          }}
          onClick={() => {
            setIsLoading(true);
            createAction(t);
          }}
          disabled={checkValidation()}
          loading={isLoading}
          className="purple-button"
          text={t('APP.ENERGYWATER.INDEX.PH_3D6FB4', {
            defaultValue: 'Ajouter',
          })}
        />
      </div>
    </>
  );
};

export default EnergyWater;
