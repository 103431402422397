import './climateInnovationForm.scss';
import {
  BbcaClimateInnovationBuildingFormDTO,
  BbcaPotentialUseOptions,
  ZoneDTO,
} from '../../../models/ProjectDTO';
import { Button, Dropdown, Input } from '@photocarbone/nooco-ui';
import { StateDTO } from '../../../models/GeneralDTO';
import {
  commaToDot,
  isNumOrNull,
  stringToNumber,
} from '../../../shared/utilsFunction';
import { updateFormValue } from '../../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'antd/es/checkbox/Checkbox';
import React, { useEffect } from 'react';
import ScoreBar from '../../../shared/ScoreBar/ScoreBar';
import { useSaveProjectDataComps } from '../../../../hooks/useQuery/bbca/saveClimateInnovation';
import {
  ProjectDataComps,
  ClimateInnovationData,
} from '@photocarbone/nooco-shared-api-def/src/project/projectDataComps';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SCORE_MAX = 10;

const TitleCard = ({ title }: { title: string }) => {
  return <div className="titleCard">{title}</div>;
};

const Label = ({
  label,
  tooltip,
}: {
  label: { defaultValue: string; key: string };
  tooltip?: { defaultValue: string; key: string };
}) => {
  const { t } = useTranslation();
  return (
    <div className="label">
      {t(label.key, { defaultValue: label.defaultValue })}
      {tooltip && (
        <div style={{ color: '#a4aed0' }}>
          <Tooltip
            title={t(tooltip.key, {
              defaultValue: tooltip.defaultValue,
            })}
          >
            <InfoIcon />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const SubLabel = ({
  subLabel,
  style,
  tooltip,
}: {
  subLabel: { defaultValue: string; key: string };
  style?: React.CSSProperties;
  tooltip?: { defaultValue: string; key: string };
}) => {
  const { t } = useTranslation();
  return (
    <div className="subLabel" style={style}>
      {t(subLabel.key, { defaultValue: subLabel.defaultValue })}
      {tooltip && (
        <div style={{ color: '#a4aed0' }}>
          <Tooltip
            title={t(tooltip.key, {
              defaultValue: tooltip.defaultValue,
            })}
          >
            <InfoIcon />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

type InputRowProps = {
  keyName: string;
  rightLabel?: string;
};
const InputRow = ({ keyName, rightLabel }: InputRowProps) => {
  const { bbcaFormValuesfromSelectedBuilding } = useSelector(
    (state: StateDTO) => state.projects
  );
  const dispatch = useDispatch();

  const handleChangeValue = (value) => {
    const newBbcaFormValuesfromSelectedBuilding = {
      ...bbcaFormValuesfromSelectedBuilding,
    };

    newBbcaFormValuesfromSelectedBuilding[keyName] = value;
    dispatch(
      updateFormValue(
        'bbcaFormValuesfromSelectedBuilding',
        newBbcaFormValuesfromSelectedBuilding
      )
    );
  };

  if (!bbcaFormValuesfromSelectedBuilding) {
    return null;
  }

  return (
    <div className="inputRow">
      <Input
        disabledDebouncing={true}
        defaultValue={bbcaFormValuesfromSelectedBuilding[keyName]}
        value={bbcaFormValuesfromSelectedBuilding[keyName]}
        onChange={(value) => handleChangeValue(value)}
        error={!isNumOrNull(bbcaFormValuesfromSelectedBuilding[keyName])}
        width={'80%'}
      />
      {rightLabel && <div className="unitRow">{rightLabel}</div>}
    </div>
  );
};

const SelectRow = ({
  keyName,
  options,
}: {
  keyName: string;
  options: { value: number; label: string }[];
}) => {
  const { bbcaFormValuesfromSelectedBuilding } = useSelector(
    (state: StateDTO) => state.projects
  );
  const dispatch = useDispatch();

  const handleChangeValue = (value) => {
    const newBbcaFormValuesfromSelectedBuilding = {
      ...bbcaFormValuesfromSelectedBuilding,
    };

    newBbcaFormValuesfromSelectedBuilding[keyName] =
      options?.find((option) => option.value === value) || null;
    dispatch(
      updateFormValue(
        'bbcaFormValuesfromSelectedBuilding',
        newBbcaFormValuesfromSelectedBuilding
      )
    );
  };

  if (!bbcaFormValuesfromSelectedBuilding) {
    return null;
  }

  return (
    <div className="inputRow">
      <Dropdown
        defaultValue={bbcaFormValuesfromSelectedBuilding[keyName] || null}
        options={options}
        onSelect={() => {}}
        width={'80%'}
        style={{ width: '80%' }}
        onChange={(value) => handleChangeValue(value)}
        treeCheckable={false}
        allowClear={true}
        onClear={() => handleChangeValue(null)}
      />
    </div>
  );
};

const ClimateInnovationBuildingCard = () => {
  return (
    <div className="climateInnovationCard buildingLevel">
      <TitleCard title="Au niveau du bâtiment" />
      <div className="column">
        <div className="section">
          <Label
            label={{
              defaultValue: 'Mutualisation des parkings',
              key: 'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.SHARED_PARKING_LABEL',
            }}
            tooltip={{
              defaultValue:
                'Pour encourager la mutualisation des places de parking, des points sont attribués en fonction du nombre de places de parking que l’on a évité de construire.',
              key: 'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.SHARED_PARKING_TOOLTIP',
            }}
          />
          <SubLabel
            subLabel={{
              defaultValue: 'Nombre places surface évitées',
              key: 'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.SAVED_PARKING_SUB_LABEL',
            }}
          />
          <InputRow keyName="savedParkingPlacesNumber" rightLabel="places" />
          <div style={{ height: '36px' }} />
          <SubLabel
            subLabel={{
              defaultValue: 'Nombre places souterrain évitées',
              key: 'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.SAVED_UNDERGROUND_PARKING_SUB_LABEL',
            }}
          />
          <InputRow
            keyName="savedParkingPlacesUndergroundNumber"
            rightLabel="places"
          />
          <div style={{ height: '36px' }} />
          <Label
            label={{
              defaultValue: 'Mutualisation des autres espaces',
              key: 'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.SHARING_OTHER_SPACES_LABEL',
            }}
            tooltip={{
              defaultValue:
                "Un point est attribué pour 10 kg d'émissions évitées par m² de SDP du bâtiment.",
              key: 'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.SHARING_OTHER_SPACES_TOOLTIP',
            }}
          />
          <SubLabel
            subLabel={{
              defaultValue:
                'Surface complémentaire équivalente liée à la mutualisation',
              key: 'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.ADDITIONAL_SURFACE_LABEL',
            }}
          />
          <InputRow keyName="savedArea" rightLabel="m²" />
        </div>
        <div className="section">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Label
              label={{
                defaultValue: 'Potentiel de changement d’usage',
                key: 'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.POTENTIAL_USE_CHANGE_LABEL',
              }}
              tooltip={{
                defaultValue:
                  "La capacité de transformation du bâtiment permet par exemple de prolonger sa durée de vie et d'optimiser l'utilisation de la surface existante favorisant ainsi une adaptabilité maximale.",
                key: 'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.POTENTIAL_USE_CHANGE_TOOLTIP',
              }}
            />
            <div>
              {'/ '}
              <span style={{ color: '#7CDB8E' }}>{'3 points'}</span>{' '}
            </div>
          </div>
          <SelectRow
            keyName="potentialUseChange"
            options={BbcaPotentialUseOptions}
          />
          <div style={{ height: '36px' }} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Label
              label={{
                defaultValue: 'Potentiel d’extension',
                key: 'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.EXPANSION_POTENTIAL_LABEL',
              }}
              tooltip={{
                defaultValue:
                  "L’extension du bâtiment permet de prolonger la durée d’utilisation d’une structure existante plutôt que de déconstruire puis reconstruire. 1 point est attribué à chaque augmentation de la surface de 10% par l'extension, avec un maximum de 3 points.",
                key: 'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.EXPANSION_POTENTIAL_TOOLTIP',
              }}
            />
            <div>
              {`/ `}
              <span style={{ color: '#7CDB8E' }}>{`3 points`}</span>{' '}
            </div>
          </div>
          <SubLabel
            subLabel={{
              defaultValue: 'Surface complémentaire',
              key: 'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.ADDITIONAL_SURFACE_AREA_SUB_LABEL',
            }}
          />
          <InputRow keyName="potentialAdditionalArea" rightLabel="m²" />
        </div>
      </div>
    </div>
  );
};

const ClimateInnovationRenovationCard = () => {
  const { bbcaFormValuesfromSelectedBuilding } = useSelector(
    (state: StateDTO) => state.projects
  );
  const dispatch = useDispatch();
  const handleChangeValue = (e) => {
    const newBbcaFormValuesfromSelectedBuilding = {
      ...bbcaFormValuesfromSelectedBuilding,
    };

    newBbcaFormValuesfromSelectedBuilding['selectiveDeconstruction'] =
      e.target.checked;
    dispatch(
      updateFormValue(
        'bbcaFormValuesfromSelectedBuilding',
        newBbcaFormValuesfromSelectedBuilding
      )
    );
  };

  return (
    <div className="climateInnovationCard">
      <TitleCard title="Au niveau du chantier de rénovation" />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Checkbox
            defaultChecked={
              bbcaFormValuesfromSelectedBuilding?.selectiveDeconstruction
            }
            checked={bbcaFormValuesfromSelectedBuilding?.selectiveDeconstruction}
            onChange={handleChangeValue}
          />
          <SubLabel
            subLabel={{
              defaultValue: 'Déconstruction sélective',
              key: 'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.SELECTIVE_DECONSTRUCTION_LABEL',
            }}
            style={{ marginLeft: '8px' }}
            tooltip={{
              defaultValue:
                'La déconstruction sélective permet d’obtenir un taux de valorisation des déchets plus réaliste, favorisant ainsi la pérennité des filières de recyclage. Cette action est valorisée d’1 point.',
              key: 'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.SELECTIVE_DECONSTRUCTION_TOOLTIP',
            }}
          />
        </div>
        <div>
          {'/ '}
          <span style={{ color: '#7CDB8E' }}>{'1 point'}</span>{' '}
        </div>
      </div>
    </div>
  );
};

const ClimateInnovationPCECard = () => {
  return (
    <div className="climateInnovationCard">
      <TitleCard title="Au niveau des PCE" />
      <SubLabel
        subLabel={{
          defaultValue: 'Masse de produits de construction réemployés',
          key: 'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.MASS_REUSED_CONSTRUCTION_PRODUCTS_LABEL',
        }}
        tooltip={{
          defaultValue:
            'Pour valoriser le réemploi, 1 point est attribué pour 5 kg/m² SDP de matériau réemployé.',
          key: 'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.LEVEL_PCE_TOOLTIP',
        }}
      />
      <InputRow keyName="massReusedConstructionProducts" rightLabel="kg" />
    </div>
  );
};

const ClimateInnovationScoreCard = () => {
  const { t } = useTranslation();
  const { bbcaFormValuesfromSelectedBuilding } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { zoneSelected } = useSelector(
    (state: StateDTO) => state.monitoring.breadcrumbMultizone
  );

  const climateInnovationData: ClimateInnovationDataScore =
    createClimateInnovationDataScore(bbcaFormValuesfromSelectedBuilding);

  const score = calculClimateInnovationScore({
    climateInnovationData,
    zoneSelected,
  });
  const scorePercentage = Math.round((score / 10) * 100);
  const scoreFormatted =
    score !== null ? (Number.isInteger(score) ? score : score.toFixed(2)) : null;

  return (
    <div className="climateInnovationScoreCard">
      <div className="label">
        {'Points Innovation climat'}
        <div style={{ color: '#a4aed0' }}>
          <Tooltip
            title={t(
              'APP.PROJECTS.CLIMATE_INNOVATION.CLIMATE_INNOVATION_FORM.CLIMATE_INNOVATION_POINTS_TOOLTIP',
              {
                defaultValue:
                  'Des Points Innovation climat sont attribués à des actions spécifiques afin d’encourager certaines pratiques, un maximum de 10 points sont accessibles.',
              }
            )}
          >
            <InfoIcon />
          </Tooltip>
        </div>
      </div>
      <div className="score">
        <span className={score < SCORE_MAX ? 'orange' : 'valid'}>
          {scoreFormatted}
        </span>
        &nbsp;/&nbsp;
        <span className="valid">{`10 points`}</span>
      </div>
      <ScoreBar scorePercentage={scorePercentage} status={'valid'} width={'500px'} />
    </div>
  );
};

export const createClimateInnovationDataScore = (
  bbcaFormValuesfromSelectedBuilding:
    | BbcaClimateInnovationBuildingFormDTO
    | undefined
    | null
    | ClimateInnovationData
): ClimateInnovationDataScore => {
  const climateInnovationData: ClimateInnovationDataScore = {
    savedParkingPlacesNumber:
      bbcaFormValuesfromSelectedBuilding?.savedParkingPlacesNumber || null,
    savedParkingPlacesUndergroundNumber:
      bbcaFormValuesfromSelectedBuilding?.savedParkingPlacesUndergroundNumber ||
      null,
    savedArea: bbcaFormValuesfromSelectedBuilding?.savedArea || null,
    potentialUseChange:
      bbcaFormValuesfromSelectedBuilding?.potentialUseChange?.value,
    potentialAdditionalArea:
      bbcaFormValuesfromSelectedBuilding?.potentialAdditionalArea || null,
    selectiveDeconstruction:
      bbcaFormValuesfromSelectedBuilding?.selectiveDeconstruction,
    massReusedConstructionProducts:
      bbcaFormValuesfromSelectedBuilding?.massReusedConstructionProducts || null,
  };

  return climateInnovationData;
};

export type ClimateInnovationDataScore = {
  savedParkingPlacesNumber: string | number | null | undefined;
  savedParkingPlacesUndergroundNumber: string | number | null | undefined;
  savedArea: string | number | null | undefined;
  potentialUseChange: number | undefined;
  potentialAdditionalArea: string | number | null | undefined;
  selectiveDeconstruction: boolean | undefined | null;
  massReusedConstructionProducts: string | number | null | undefined;
};
export type CalculClimateInnovationScoreProps = {
  zoneSelected: ZoneDTO | null | undefined;
  climateInnovationData: ClimateInnovationDataScore | undefined;
};
export const calculClimateInnovationScore = ({
  climateInnovationData,
  zoneSelected,
}: CalculClimateInnovationScoreProps): number | null => {
  if (!climateInnovationData || !zoneSelected) {
    return null;
  }

  let score = 0;

  if (
    isNumOrNull(climateInnovationData?.savedParkingPlacesNumber) &&
    isNumOrNull(climateInnovationData?.savedParkingPlacesUndergroundNumber)
  ) {
    const scorePoolingOfParkingSpaces =
      (Number(commaToDot(climateInnovationData?.savedParkingPlacesNumber)) * 700 +
        Number(
          commaToDot(climateInnovationData?.savedParkingPlacesUndergroundNumber)
        ) *
          3000) /
      (10 * Number(zoneSelected?.area));

    score += scorePoolingOfParkingSpaces;
  }

  //TODO FORMULE A REVOIR score savedArea
  if (isNumOrNull(climateInnovationData?.savedArea)) {
    const scoreSharingOfOtherSpaces =
      (zoneSelected?.Ic[1].IcConstruction *
        Number(commaToDot(climateInnovationData.savedArea))) /
      (10 * Number(zoneSelected?.area));

    score += scoreSharingOfOtherSpaces;
  }

  // score potentialUseChange
  if (climateInnovationData?.potentialUseChange) {
    score += climateInnovationData?.potentialUseChange;
  }

  // score potentialAdditionalArea
  if (isNumOrNull(climateInnovationData?.potentialAdditionalArea)) {
    const scorePotentialAdditionalArea =
      (10 * Number(commaToDot(climateInnovationData?.potentialAdditionalArea))) /
      Number(zoneSelected?.area);

    score += scorePotentialAdditionalArea > 3 ? 3 : scorePotentialAdditionalArea;
  }

  // score déconstruction selective
  if (climateInnovationData?.selectiveDeconstruction) {
    score += 1;
  }

  // score masse de produits de construction réemploys
  if (isNumOrNull(climateInnovationData?.massReusedConstructionProducts)) {
    const scoreMassReusedConstructionProducts =
      Number(commaToDot(climateInnovationData?.massReusedConstructionProducts)) /
      Number(zoneSelected?.area * 5);

    score += scoreMassReusedConstructionProducts;
  }

  if (score > SCORE_MAX) {
    score = SCORE_MAX;
  }

  if (isNaN(score)) {
    return null;
  }
  const scoreFormatted = Number.isInteger(score) ? score : Number(score.toFixed(2));

  return scoreFormatted;
};

type ClimateInnovationFormProps = {
  projectDataComps: ProjectDataComps;
  saveCurrentBbcaForm: () =>
    | (BbcaClimateInnovationBuildingFormDTO | undefined)[]
    | undefined;
  isClimateInnovationFormValid: ({
    bbcaFormValuesfromSelectedBuilding,
  }: {
    bbcaFormValuesfromSelectedBuilding?: BbcaClimateInnovationBuildingFormDTO;
  }) => boolean;
};
const ClimateInnovationForm = ({
  projectDataComps,
  saveCurrentBbcaForm,
  isClimateInnovationFormValid,
}: ClimateInnovationFormProps) => {
  const {
    bbcaFormValuesfromSelectedBuilding,
    currentProject,
    parsedProject: { zones },
  } = useSelector((state: StateDTO) => state.projects);
  const dispatch = useDispatch();

  const resetClimateInnovationForm = () => {
    dispatch(updateFormValue('bbcaFormValuesfromSelectedBuilding', null));
    dispatch(updateFormValue('bbcaClimateInnovationFormValues', null));
  };

  const { isLoading, mutate: saveClimateInnovationMutate } =
    useSaveProjectDataComps();

  const handleSaveClimateInnovation = () => {
    const bbcaClimateInnovationFormValues = saveCurrentBbcaForm();
    const climateInnovationBody: ProjectDataComps | null = projectDataComps;
    projectDataComps.buildings.forEach((building) => {
      const bbcaClimateInnovationFormValuesfromSelectedBuilding =
        bbcaClimateInnovationFormValues?.find(
          (bbcaValueElement) => bbcaValueElement?.zoneId === building.ZoneId
        ) || null;

      if (bbcaClimateInnovationFormValuesfromSelectedBuilding) {
        const score = calculClimateInnovationScore({
          climateInnovationData: createClimateInnovationDataScore(
            bbcaClimateInnovationFormValuesfromSelectedBuilding
          ),
          zoneSelected: zones.find((zone) => zone.id === building.ZoneId),
        });
        const climateInnovationData: ClimateInnovationData = {
          massReusedConstructionProducts: stringToNumber(
            bbcaClimateInnovationFormValuesfromSelectedBuilding?.massReusedConstructionProducts
          ),
          potentialAdditionalArea: stringToNumber(
            bbcaClimateInnovationFormValuesfromSelectedBuilding?.potentialAdditionalArea
          ),
          potentialUseChange:
            bbcaClimateInnovationFormValuesfromSelectedBuilding?.potentialUseChange ||
            null,
          savedArea: stringToNumber(
            bbcaClimateInnovationFormValuesfromSelectedBuilding?.savedArea
          ),
          savedParkingPlacesNumber: stringToNumber(
            bbcaClimateInnovationFormValuesfromSelectedBuilding?.savedParkingPlacesNumber
          ),
          savedParkingPlacesUndergroundNumber: stringToNumber(
            bbcaClimateInnovationFormValuesfromSelectedBuilding?.savedParkingPlacesUndergroundNumber
          ),
          selectiveDeconstruction:
            bbcaClimateInnovationFormValuesfromSelectedBuilding?.selectiveDeconstruction ||
            false,
          climateInnovationPoints: score,
        };
        building.climateInnovationData = climateInnovationData;
      }
    });
    const data = {
      projectId: currentProject.id,
      body: climateInnovationBody,
    };
    saveClimateInnovationMutate(data);
  };

  return (
    <div className="climateInnovationForm-wrapper">
      {/* form */}
      <div className="climateInnovationCards-wrapper">
        <ClimateInnovationBuildingCard />
        <div className="climateInnovationCards-right-part">
          <ClimateInnovationRenovationCard />
          <ClimateInnovationPCECard />
        </div>
      </div>
      {/*  score && button footer */}
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          gap: '36px',
        }}
      >
        <ClimateInnovationScoreCard />
        <div style={{ display: 'flex', gap: '10px', marginLeft: '70px' }}>
          <Button
            className="close-button"
            onClick={() => resetClimateInnovationForm()}
            text="Annuler les changements"
          />
          <Button
            disabled={
              !isClimateInnovationFormValid({
                bbcaFormValuesfromSelectedBuilding,
              })
            }
            className="save-button"
            text="Sauvegarder"
            loading={isLoading}
            onClick={handleSaveClimateInnovation}
          />
        </div>
      </div>
    </div>
  );
};

export default ClimateInnovationForm;
