import './Modal.scss';
import './projectCard.scss';
import { ModalNooco } from '@photocarbone/nooco-ui';
import { DragPreviewImage, useDrag } from 'react-dnd';
import { FooterCard } from './FooterCard';
import { MainCard } from './MainCard';
import { ProjectDTO } from '../../../../models/ProjectDTO';
import { RoundElement } from './RoundElement';
import { TextField } from '@material-ui/core';
import { StateDTO } from '../../../../models/GeneralDTO';
import { useDeleteProject } from '../../../../../hooks/useQuery/projects/deleteProject';
import { useSelector } from 'react-redux';
import { useUpdateProject } from '../../../../../hooks/useQuery/projects/patchProject';
import React, { useState } from 'react';
import companyLogo from '../../../../../assets/images/creation/company.png';
import dragCard from '../../../../../assets/images/help/testDrag.svg';
import useCase from '../../useCase/useCase';
import { useTranslation } from 'react-i18next';
import { truncateName } from '../../../../shared/utils';

type ProjectCardWrapper = {
  project: ProjectDTO;
  onClick: Function;
};

export const ProjectCard = ({ project, onClick }: ProjectCardWrapper) => {
  const { projectsList, projects } = useSelector(
    (state: StateDTO) => state.projects
  );

  const { t } = useTranslation();

  const [isEditProjectCard, setIsEditProjectCard] = useState(false);
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'projectCard',
    item: { project },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  const [editInputProjectCard, setEditInputProjectCard] = useState('');
  const updateProject = useUpdateProject();
  const { getUrl } = useCase();

  const handleClickEditButton = (e) => {
    setEditInputProjectCard(project.name);
    setIsEditProjectCard(!isEditProjectCard);
  };

  const handleCanDrop = () => {
    if (isEditProjectCard) {
      return false;
    }
    return true;
  };

  const handleEditProjectCard = (e) => {
    let isRset: boolean | null = null;
    if (projectsList.length > 0 || projects[0].rows.length > 0) {
      const projectListTocheck =
        projectsList.length > 0 ? projectsList[0].rows : projects[0].rows;
      const projectData = projectListTocheck.find(
        (eachProjectData) => eachProjectData.id === project.id
      );
      if (projectData) {
        isRset = projectData.isRset;
      }
    }
    updateProject.mutate({
      projectId: project.id,
      body: {
        name: editInputProjectCard,
        isRset: isRset,
      },
    });
    setIsEditProjectCard(false);
    setEditInputProjectCard('');
  };
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDeleteProject = () => {
    deleteProject.mutate(project.id);
    handleCloseModal();
  };

  const deleteProject = useDeleteProject();

  return (
    <>
      <ModalNooco
        title={'Suppression du projet'}
        isOpen={openModal}
        cancelFunction={handleCloseModal}
        validateFunction={handleDeleteProject}
        closeOnOutsideClick={true}
        closeIcon={true}
      >
        <div>
          {t('APP.PROJECTCARD.PROJECTCARD.PH_40CC26', {
            defaultValue:
              'Vous êtes sur le point de supprimer définitivement votre projet, voulez-vous continuer?',
          })}
        </div>
      </ModalNooco>
      <DragPreviewImage connect={preview} src={dragCard} />
      <div
        className="projectCardWrapper"
        ref={handleCanDrop() ? drag : undefined}
        style={{
          opacity: isDragging ? '0.4' : '',
        }}
        onClick={() => {
          if (!isEditProjectCard) {
            onClick(project);
          }
        }}
      >
        <RoundElement type="delete" onClick={handleOpenModal} />
        <RoundElement type="edit" onClick={handleClickEditButton} />
        <div className="title">
          {!isEditProjectCard && <>{truncateName(project.name, 40)}</>}
          {isEditProjectCard && (
            <TextField
              style={{ width: '80%' }}
              autoFocus
              id="standard-basic"
              value={editInputProjectCard}
              onChange={(e) => {
                setEditInputProjectCard(e.currentTarget.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleEditProjectCard(e);
                }
              }}
            />
          )}
        </div>
        <div className="adress">
          {/* <div className='folder'>
            {`Répertoire: ${getUrl(project)}`}
          </div> */}
          <div>
            <img className="logo" src={companyLogo} alt="" />
          </div>
          <div className="company">
            {`${project.Company.name} :  ${getUrl(project)}`}
          </div>
        </div>
        <MainCard project={project} cardHeader={false} />
        <FooterCard project={project} />
      </div>
    </>
  );
};
