/* eslint-disable no-case-declarations */
import {
  ADD_ZONES_SECTIONS,
  CHANGE_TYPE_OF_PROJECT,
  DOWNLOAD_RSEE,
  FETCH_ALL_GROUPS_ERROR,
  GET_USER_METHOD_TO_LOGGED_IN,
  HANDLE_CONTROLLED_ZONES,
  HANDLE_RSET_MODAL,
  LOAD_ADDRESS,
  LOAD_ALL_GROUPS,
  LOAD_COMPANIES,
  LOAD_INDICATORS_DATAS,
  LOAD_LOTS,
  LOAD_NEW_PROJECT,
  LOAD_PROJECTS,
  LOAD_PROJECT_BY_ID,
  LOAD_USER,
  MANAGEMENT_BUILDING_SECTIONS,
  MODIFY_ACTIVATED_SELECTS,
  OPEN_INDICATORS,
  RESET_BUILDING_SECTIONS,
  POST_RSEE_EXPORT_SUCCESS,
  RESET_POST_RSEE_EXPORT_SUCCESS,
  POST_RSEE_EXPORT,
  SAVE_PDF_RSEE,
  SAVE_RSEE,
  SET_CURRENT_INDICATOR,
  SET_CURRENT_PROJECT,
  SET_DATASET,
  SET_FILE_UPLOAD_STATUS,
  SET_INPUT_VALUE,
  SET_LOADING_PROJECT_DETAILS,
  SET_NODE_ADMINS,
  SET_PROJECT_ID,
  SET_PROJECT_MODAL_PAGE,
  SET_SEARCH_VALUE,
  SET_SUPER_ADMIN,
  TOGGLE_MENU,
  UPDATE_ACTIVATED_SELECTS,
  UPDATE_ARIANE_COMPANY_VALUES,
  UPDATE_ARIANE_FOLDER_VALUES,
  UPDATE_CURRENT_FLAG,
  UPDATE_FORM_VALUE,
  UPDATE_INIT_PROJECTS_LIST_MONITORING,
  UPDATE_PROJECTS_LIST_MONITORING,
  USER_LOGOUT,
} from '../actions/projects';
import {
  ASKING_IS_DED,
  CLOSE_DED_MODAL,
  DISPLAY_DED_MODAL,
  FORMAT_SHEETS,
  HIDE_SUCCESS_MESSAGE,
  REQUEST_DED_SUCCESS,
  SET_ID_ASKER,
  SET_LOADING_DED,
  SET_NOMENCLATURE_INIES,
} from '../actions/equipments';
import { GENERATE_RSEE, GET_RSEE_PDF } from '../actions/report';
import { LOAD_NEW_EQUIPMENT } from '../actions/exploitation';
import {
  OPEN_BRAND,
  OPEN_CARAC,
  OPEN_DISCRETE,
  OPEN_FILTER_GRAPH,
  OPEN_FILTRABLE_LIST,
  OPEN_PLACE,
  OPEN_SECOND_DISCRETE,
  RESET_FILTERS,
  SAVE_FILTERS_BRAND,
  SAVE_SHEETS,
  SET_LOADING_GRAPH,
  SET_NEW_SHEETS,
} from '../actions/filtersgraph';
import {
  addNewBuilding,
  addNewZone,
} from '../app/shared/handleProjectZonesCreation';
import {
  findParentNode,
  recursiveNomenclatureInies,
  returnAllMdsInProject,
  sortFilterSousLot,
} from '../app/shared/utils';
import { parseProject } from '../functions/parseProject';
import calcAcv from '../app/services/mathematics/calcACV';

import {
  ALLOTMENT_IDS,
  REGULATORY_PROJECTS,
  TEMPLATE_TYPE_NAME,
  TYPE_BBCA,
  TYPE_EPCM,
  TYPE_EXPLOITATION,
  TYPE_GLOBAL_MONITORING,
  TYPE_NOOCO,
  TYPE_OUTDOOR,
  TYPE_RE2020,
} from '../app/globalVariable/typeProject';
import {
  CALCULATOR_EXIT,
  CALCULATOR_RESET,
  CALCULATOR_UPDATE,
  CALCULATOR_WATER_RESET,
  SELECT_CALCULATOR_WATER,
  SELECT_CALCULATOR_WORKSITE,
  UPDATE_CALCULATOR_DATA,
  UPDATE_CALCULATOR_WATER_DETAILED,
  UPDATE_WATER_CALCULATOR,
} from '../actions/calculator';
import { LOAD_PROJECT_BY_SIMULATOR } from '../actions/simulator';
import { createProjectStructure } from '../app/shared/parsingDatas';
import { saveAs } from 'file-saver';
import { structureCompanies } from '../app/shared/utilsFunction';
import { v4 as uuidv4 } from 'uuid';
import { LOTS_IDS } from '../app/globalVariable/generalDefinitions';

let JSZip = require('jszip');

let unitRefs = ['m²', 'total'];

const initialState = {
  isResponseProjectError: false,
  projectErrorMessage: '',
  projects: [],
  projectsList: [],
  projectsListMonitoring: {
    isInit: false,
    foldersData: [],
    isCreateFolder: false,
    inputCreateFolder: '',
    isMultipleCompanies: undefined,
    mappingProject: undefined,
    dropDownInput: undefined,
    projectTypeFilter: [],
    arianeCompany: {
      isBreadcrumbCompanyVisible: false,
      childrenCompanies: [],
      selectedCompanies: [],
    },
    arianeFolder: {
      isBreadcrumbFolderVisible: false,
      isExistChildrenPaths: true,
      childrenPaths: [],
      selectedPaths: [],
    },
  },
  companies: [],
  allCompanies: [],
  structuredCompanies: [],
  lots: [],
  currentProject: {},
  isLoadingGenerateRSEnv: true,
  generateRSEnv: {
    acvDesignOffice: {
      name: null,
      siret: '',
      adress: null,
      postal: '',
      city: null,
    },
    earthQuakeZone: null,
    buildings: [],
  },
  zones: [],
  buildingSections: [
    {
      flag: 0,
      name: 'Bâtiment 1',
      type: 'building',
      Zones: [
        {
          name: 'Zone 1',
          BuildingTypeId: 1,
          menInput: true,
          logementInput: true,
          comblesInput: false,
          category: 'CE1',
          area: null,
          type: 'zone',
        },
      ],
    },
  ],
  allGroups: undefined,
  fluidsCompatibleSheets: [],
  projectAllot: {},
  currentAllotment: null,
  currentIndicator: null,
  searchValue: '',
  nodeSearchValue: null,
  calculator: {
    isCalculatorActived: false,
    isOldBuildingSiteInProject: undefined,
    isOldWaterLotInProject: undefined,
    nbZoneBuildingSite: undefined,
    isBuildingSiteActivatedInParams: undefined,
    isWorksiteCalculatorSelected: false,
    isWorksiteCalculatorDetailed: false,
    loadingCalculator: false, // true if loading in progress
    isCalculatorDetailedLoaded: false, // true if calculatorData is loaded.
    isWorksiteCalculatorSimplified: false,
    worksiteCalculatorNav: [],
    worksiteCalculatorDetailedActive: 0, // index of componant to display cf CalculatorWorksite/componentToDisplay
    // isWorksiteFormIsValid: true,
    // isWorksiteFormToSubmit: false,
    zoneSelected: null,
    isLoadingCaracs: false,
    resultOfSearchCarac: null,
    isTransportErrorDisplay: false,
    transportLines: [
      {
        id: 0,
        MasterDataEquipmentId: null,
        quantity: null,
        type: null,
        carburant: null,
        caracs: [],
        caracCombo: null,
        origin: {
          type: 'project',
          sourceId: null,
          uniqueId: uuidv4(),
        },
      },
    ],
    transportEquipments: [],
    isGearErrorDisplay: false,
    isTotalGazoilSelected: false,
    isInputByGearSelected: false,
    isInputByExcavatedSelected: false,
    calculatorTotalGazoilGear: [
      {
        quantity: null,
        origin: {
          type: 'project',
          sourceId: null,
          uniqueId: uuidv4(),
        },
      },
    ],
    gearLines: [
      {
        id: 0,
        MasterDataEquipmentId: null,
        quantity: null,
        type: null,
        motorization: null,
        consumption: null,
        unit: null,
        Caracs: [],
        origin: {
          type: 'project',
          sourceId: null,
          uniqueId: uuidv4(),
        },
      },
    ],
    calculatorTotalExcavatedGear: [
      {
        quantity: null,
        origin: {
          type: 'project',
          sourceId: null,
          uniqueId: uuidv4(),
        },
      },
    ],
    // gearsCaracData: [],
    gearsEquipments: [],
    calculatorData: {
      energy: {
        energiesArray: [
          {
            MasterDataEquipment: null,
            quantity: '',
            caracs: null,
            origin: {
              type: 'project',
              sourceId: null,
              uniqueId: uuidv4(),
            },
          },
        ],
        // gazoleQuantity: "",
      },
      energyCalculatorSimplified: {
        energySimplifiedArray: [
          {
            NumberMonthsWithCraneSummer: null,
            NumberMonthsWithCraneWinter: null,
            NumberMonthsWithoutCraneSummer: null,
            NumberMonthsWithoutCraneWinter: null,
            origin: {
              type: 'project',
              sourceId: null,
              uniqueId: uuidv4(),
            },
            MasterDataEquipment: null,
            caracs: null,
            AllotmentNode: null,
          },
        ],
      },
      water: {
        displayUnit: 'm3',
        zonesData: [
          {
            drinkableWater: '',
            wastedWater: '',
            origin: {
              type: 'project',
              sourceId: null,
              uniqueId: uuidv4(),
            },
          },
        ],
      },
      importedEarthWork: [
        {
          quantity: '',
          distance: '',
          origin: {
            type: 'project',
            sourceId: null,
            uniqueId: uuidv4(),
          },
        },
      ],
      evacuatedEarthWork: [
        {
          masterDataId: undefined,
          quantity: '',
          distance: '',
          origin: {
            type: 'project',
            sourceId: null,
            uniqueId: uuidv4(),
          },
        },
      ],
      evacuatedEarthWorkIndividualHouse: [
        {
          area: '',
          distance: '',
          origin: {
            type: 'project',
            sourceId: null,
            uniqueId: uuidv4(),
          },
        },
      ],
      specific: {
        specificArray: [
          {
            MasterDataEquipment: null,
            quantity: '',
            caracs: null,
            origin: {
              type: 'project',
              sourceId: null,
              uniqueId: uuidv4(),
            },
          },
        ],
      },
    },
  },
  waterCalculator: {
    isWaterCalculatorSelected: false,
    isWaterCalculatorDetailed: false,
    isWaterCalculatorSimplified: false,
    loadingWaterCalculator: false,
    isWaterCalculatorLoaded: false,
    waterCalculatorDetailedActive: 0,
    // index of component to display cf CalculatorWorksite/componentToDisplay
    waterCalculator: {
      buildings: [],
    },
    calculatorDetailed: null,
  },

  // end calculator
  isLoading: true,
  isLoadingProjects: true,
  overseer: '',
  name: '',
  area: '',
  location: '',
  company: null,
  permitDate: '',
  typeProject: 'new',
  type: 'Logement collectif',
  lifeSpan: '50',
  newBuilding: true,
  selectedLotIds: [],
  isAdmin: false,
  isSuperAdmin: false,
  isCompanyAdmin: false,
  companyAdmins: [],
  orgaAdmins: [],
  filteredCompanies: [],
  administrativeNodes: [],
  projectModalPage: 1,
  isFilterOpen: false,
  loaderPdf: false,
  isBrandOpen: false,
  isPlaceOpen: false,
  isCaracOpen: false,
  isMenuOpen: true,
  isDiscreteOpen: false,
  isSecondDiscreteOpen: false,
  filters: [],
  // allGroups: undefined,
  allGroupsStatus: 'idle',
  sheets: [],
  sheetStatus: false,
  currentMd: null,
  loadingGraph: false,
  projectsDetails: [],
  exploitDates: [null, null],
  // currentProject: {},
  projectPhase: '',
  buildingSiteDuration: '',
  mds: null,
  rates: null,
  isLoadingProjectDetails: true,
  projectId: null,
  sheetsDetails: {},
  lists: [],
  isIndicatorsOpen: false,
  idAsker: null,
  showDedModal: false,
  loadingDed: false,
  explainDed: '',
  nameDed: '',
  productDed: '',
  unitDed: '',
  calculDed: '',
  nomenDed: '',
  sucessDed: false,
  buildingTypes: null,
  buildingCategories: null,
  buildingCategoryId: null,
  buildingTypeId: null,
  indicators: null,
  // currentIndicator: null,
  companiesStatus: 'idle',
  searchLocations: [],
  fluids: null,
  // searchValue: '',
  inputValue: '',
  selectedLot: null,
  selectedFonction: null,
  selectedCategory: null,
  selectedMaterial: null,
  selectedType: null,
  typeOfProject: 'standard',
  // buildingSections: [
  //   {
  //     flag: 0,
  //     name: 'Bâtiment 1',
  //     type: 'building',
  //     Zones: [
  //       {
  //         name: 'Zone 1',
  //         BuildingTypeId: 1,
  //         menInput: true,
  //         logementInput: true,
  //         comblesInput: false,
  //         area: null,
  //         type: 'zone',
  //       }
  //     ]
  //   }
  // ],
  organisations: [],
  parentCompanies: [],
  groups: [],
  parcel: false,
  buildingSite: false,
  buildingType: false,
  surfaceProject: '',
  zoneEquipmentId: null,
  equipmentBuildingSiteId: false,
  buildingTypeStandard: 1,
  standardIsConnectedToUrbanNetwork: false,
  parcelArea: '',
  equipmentDedAsking: null,
  currentFlag: 'lot',
  editZones: { zones: [] },
  iniesNomenclature: [],
  importModal: false,
  modalLoadingDed: [],
  disabledBreadcrumbSelects: [false, true, true, true, true],
  lotMode: null,
  referenceType: 'free',
  openPopperLot: false,
  modalAdvanceInies: false,
  patchModeLoading: false,
  companyFilters: [],
  organisationFilters: [],
  spinnerProjectModal: false,
  socket: null,
  downloadRsee: false,
  rsetFile: null,
  clickLevelAllot: null,
  allotSteps: [],
  // zones: [],
  // projectAllot: {},
  // currentAllotment: null,
  sheetToLifeCycle: null,
  equipmentsAvailable: null,
  scope: { x: { min: 0, max: 'auto' }, y: { min: 0, max: 'auto' } },
  plotEquips: null,
  equipsBulkArray: [],
  fluidsArray: [],
  linkedFluids: {},
  rsetmodalOpen: false,
  commentEquipment: '',
  graphView: 'bar',
  bbcaClimateInnovationFormValues: null,
  settingsDatas: null,
  offsetProjects: 0,
  isRsenv: false,
  hasFcSheets: false,
  refProjects: [],
  refStates: {
    refModal: false,
    refProject: null,
    refLines: [],
    refImport: {
      caracs: true,
      sheets: true,
    },
  },
  creationProject: {
    creationSteps: [],
    typeProject: null,
    creationProjectLoading: false,
    selectedTypeProjectId: 1,
  },
  hasFluidSheets: false,
  fcSheetsFiles: {},
  isFileUploaded: false,
  projectInput: '',
  selectedIniesSheet: null,
  idFicheFcFilenames: null,
};

function projectsReducer(state = initialState, action = {}) {
  let firstBuildingType;
  let isCompanyAdmin = false;
  let projectId;

  switch (action.type) {
    case SET_FILE_UPLOAD_STATUS:
      return {
        ...state,
        isFileUploaded: action.payload,
      };

    case POST_RSEE_EXPORT_SUCCESS: {
      return {
        ...state,
        postRseeExportSuccess: true,
      };
    }

    case RESET_POST_RSEE_EXPORT_SUCCESS: {
      return {
        ...state,
        postRseeExportSuccess: false,
      };
    }

    case POST_RSEE_EXPORT: {
      return {
        ...state,
        postRseeExportSuccess: false,
      };
    }

    case LOAD_PROJECTS: {
      let concat = [...state.projects];

      if (action.projects.rows) {
        action.projects.rows.forEach((el) => {
          el.Lots = state.allGroups.projectTypes.find(
            (type) => type.name === el.ProjectType?.name
          )?.AllotmentNodes;
          el.templateType =
            el.ProjectType?.name === 're2020'
              ? TYPE_RE2020
              : el.ProjectType?.name === 'epcm'
              ? TYPE_EPCM
              : el.ProjectType?.name;

          if (
            !concat.find((project) => project.id === el.id) &&
            el.ProjectType?.name !== 'mappingRef'
          ) {
            concat.push(el);
          }
        });
      }

      let refs = action.projects.rows.filter(
        (el) =>
          el.ProjectType?.name === 'mappingRef' &&
          !state.refProjects.find((ref) => ref.id === el.id)
      );

      return {
        ...state,
        isLoadingProjects: false,
        projects: action.isSearching
          ? action.projects?.rows.filter(
              (el) => el.ProjectType?.name !== 'mappingRef'
            )
          : concat,
        refProjects: action.isSearching
          ? action.projects.rows.filter(
              (el) => el.ProjectType?.name === 'mappingRef'
            )
          : [...state.refProjects, ...refs],
      };
    }
    case LOAD_NEW_PROJECT: {
      action.project.Lots = state.allGroups.projectTypes.find(
        (type) => type.name === action.project.ProjectType?.name
      )?.AllotmentNodes;
      action.project.templateType =
        action.project.ProjectType?.name === 're2020'
          ? TYPE_RE2020
          : action.project.ProjectType?.name === 'epcm'
          ? TYPE_EPCM
          : action.project.ProjectType?.name;

      const copy = [...state.projects, action.project];

      return {
        ...state,
        redirectionId: action.project.id,
      };
    }
    case SET_DATASET: {
      return {
        ...state,
        projectAllot: {
          ...state.projectAllot,
          dataset: action.dataset,
        },
      };
    }

    case LOAD_COMPANIES: {
      // let isAdminOrga = action.isSuperAdmin;
      let isAdminOrga = action.isSuperAdmin || false;
      let licenceTypeAccess = 'read';

      if (!action.isSuperAdmin) {
        licenceTypeAccess = action.companies.reduce((highest, company) => {
          if (company.Employee?.licenseType === 'pro') {
            return 'pro';
          } else if (
            company.Employee?.licenseType === 'light' &&
            highest !== 'pro'
          ) {
            return 'light';
          } else {
            return highest;
          }
        }, 'read');
      } else {
        licenceTypeAccess = 'pro';
      }

      for (const company of action.companies) {
        if (company.isAdmin) {
          isAdminOrga = true;
          break;
        }
      }

      const organisation = action.companies.filter(
        (orga) => orga.type === 'organisation'
      );
      const parentCompanies = action.companies
        .filter((company) => {
          // Si la company n'a pas de parent, on la garde
          if (company.ParentNodeId === null) {
            return true;
          }

          // On recherche le parent correspondant dans la liste des companies
          const parent = action.companies.find(
            (parentCompany) => parentCompany.id === company.ParentNodeId
          );

          // Si le parent n'existe pas, on garde la company
          if (!parent) {
            return true;
          }

          // Sinon, on ne garde pas la company
          return false;
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1));

      const group = action.companies.filter((orga) => orga.type === 'group');
      const struturedCompanies = structureCompanies(action.companies);

      return {
        ...state,
        administrativeNodes: action.companies.filter(
          (orga) => orga.type !== 'company'
        ),
        companies: action.companies.filter((orga) => orga.type === 'company'),
        allCompanies: action.companies.sort((a, b) => (a.name > b.name ? 1 : -1)),
        structuredCompanies: struturedCompanies,
        company: action.companies.filter((orga) => orga.type === 'company')[0]
          ? action.companies.filter((orga) => orga.type === 'company')[0].id
          : '',
        organisations: organisation ? organisation : null,
        parentCompanies: parentCompanies,
        groups: group ? group : null,
        isCompanyAdmin: isAdminOrga,
        filteredCompanies: action.companies,
        isLoading: false,
        companiesStatus: 'ok',
        licenceTypeAccess: licenceTypeAccess,
      };
    }

    case LOAD_LOTS:
      return {
        ...state,
        lots: action.lots,
      };
    case SET_NODE_ADMINS:
      return {
        ...state,
        companyAdmins: action.companyAdmins,
        orgaAdmins: action.orgaAdmins,
      };
    case UPDATE_FORM_VALUE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case UPDATE_CURRENT_FLAG:
      return {
        ...state,
        currentFlag: action.value,
      };

    case SET_SUPER_ADMIN:
      return {
        ...state,
        isSuperAdmin: action.isSuperAdmin,
        isAdmin: action.isAdmin,
      };

    case OPEN_FILTER_GRAPH:
      return {
        ...state,
        isFilterOpen: !state.isFilterOpen,
        isBrandOpen: false,
        isPlaceOpen: false,
        isCaracOpen: false,
        isDiscreteOpen: false,
        isSecondDiscreteOpen: false,
        // isMenuOpen: !state.isFilterOpen ? false : state.isMenuOpen,
      };
    case OPEN_BRAND:
      return {
        ...state,
        isBrandOpen: !state.isBrandOpen,
      };
    case OPEN_PLACE:
      return {
        ...state,
        isPlaceOpen: !state.isPlaceOpen,
      };
    case OPEN_CARAC:
      return {
        ...state,
        isCaracOpen: !state.isCaracOpen,
      };
    case OPEN_DISCRETE:
      return {
        ...state,
        isDiscreteOpen: !state.isDiscreteOpen,
      };
    case OPEN_SECOND_DISCRETE:
      return {
        ...state,
        isSecondDiscreteOpen: !state.isSecondDiscreteOpen,
      };
    case TOGGLE_MENU:
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen,
      };
    case SAVE_FILTERS_BRAND: {
      if (action.filters.filtrableList) {
        action.filters.filtrableList.forEach((filterList) => {
          filterList.values.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }

            if (a.name > b.name) {
              return 1;
            }

            return 0;
          });
        });
      }

      action.filters = {
        ...action.filters,
        brands: action.filters.brands
          ? action.filters.brands.sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }

              if (a.name > b.name) {
                return 1;
              }

              return 0;
            })
          : [],
        production_place: action.filters.production_place
          ? action.filters.production_place.sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }

              if (a.name > b.name) {
                return 1;
              }

              return 0;
            })
          : [],
        discrete: action.filters.discrete
          ? action.filters.discrete.sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }

              if (a.name > b.name) {
                return 1;
              }

              return 0;
            })
          : [],
        secondDiscrete: action.filters.secondDiscrete
          ? action.filters.secondDiscrete.sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }

              if (a.name > b.name) {
                return 1;
              }

              return 0;
            })
          : [],
        filtrable: action.filters.filtrable
          ? action.filters.filtrable.sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }

              if (a.name > b.name) {
                return 1;
              }

              return 0;
            })
          : [],
        filtrableList: action.filters.filtrableList
          ? action.filters.filtrableList.sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }

              if (a.name > b.name) {
                return 1;
              }

              return 0;
            })
          : [],
      };

      if (state.filters.length === 0) {
        return {
          ...state,
          filters: action.filters,
          isFilterOpen: action.open || action.open2 ? true : false,
          isCaracOpen: action.open || action.open2 ? true : false,
          isDiscreteOpen: action.open ? true : false,
          isSecondDiscreteOpen: action.open2 ? true : false,
        };
      } else {
        return {
          ...state,
          filters: action.filters,
        };
      }
    }
    case FETCH_ALL_GROUPS_ERROR:
      return {
        ...state,
      };

    case LOAD_ALL_GROUPS:
      const firstBuilding = action.response.data.types[0];
      const decMds = action.response.data.type.filter((md) => {
        return !!(
          md.lot.includes('Energie') ||
          md.lot.includes('Eau') ||
          md.lot.includes('Terre')
        );
      });
      const indicators = action.response.data.indices;

      if (indicators.some((i) => i.relativeName?.includes('(GWP)'))) {
        const index = indicators.findIndex((el) =>
          el.relativeName?.includes('(GWP)')
        );

        [indicators[0], indicators[index]] = [indicators[index], indicators[0]];
      }
      // Management for template type available in sidebar

      const returnTemplateName = (name) => {
        switch (name) {
          case 'exploitation':
            return 'Travaux exploitation';
          case 'airport':
            return 'Aéroport';
          case 'datacenter':
            return 'Datacenter';
          case 'outdoor':
            return 'Infrastructure extérieure';
          case 'prototype':
            return 'Prototype';
        }
      };

      let templateTypeAvailable = [];
      let templateTypeAvailableIds = [];

      function addOrUpdateTemplateType(
        typeProject,
        templateName,
        templateTypeAvailable
      ) {
        let regroupType = templateTypeAvailable.find(
          (el) => el.name === templateName
        );
        if (regroupType) {
          if (!regroupType.queryParams.find((el) => el.id === typeProject.id)) {
            regroupType.queryParams.push(typeProject);
          }
        } else {
          templateTypeAvailable.push({
            id: typeProject.id,
            name: templateName,
            queryParams: [typeProject],
          });
        }
      }

      state.companies.forEach((company) => {
        company.ProjectTypes.forEach((projectTypeId) => {
          let typeProject = action.response.data.projectTypes.find(
            (el) =>
              el.id === projectTypeId && el.name !== TEMPLATE_TYPE_NAME.SIMULATOR
          );

          if (!templateTypeAvailableIds.includes(projectTypeId)) {
            templateTypeAvailableIds.push(projectTypeId);
          }

          if (
            !typeProject ||
            templateTypeAvailable.find((el) => el.id === typeProject.id) ||
            typeProject.name === TEMPLATE_TYPE_NAME.MAPPINGREF
          ) {
            return;
          }

          const isNewConstructionType = [
            TEMPLATE_TYPE_NAME.RE2020,
            TEMPLATE_TYPE_NAME.EPCM,
            TEMPLATE_TYPE_NAME.NOOCO,
          ].includes(typeProject.name);
          const isRenovationType = [
            TEMPLATE_TYPE_NAME.RENOVATION,
            TEMPLATE_TYPE_NAME.BBCA,
          ].includes(typeProject.name);
          if (isNewConstructionType) {
            addOrUpdateTemplateType(
              typeProject,
              'Travaux neuf',
              templateTypeAvailable
            );
          } else if (isRenovationType) {
            addOrUpdateTemplateType(
              typeProject,
              'Travaux rénovation',
              templateTypeAvailable
            );
          } else {
            templateTypeAvailable.push({
              id: typeProject.id,
              name: returnTemplateName(typeProject.name),
              queryParams: [typeProject],
            });
          }
        });
      });

      templateTypeAvailable = templateTypeAvailable.sort((a, b) =>
        a.id < b.id ? -1 : 1
      );
      // GET LISTS OF ALLOTMENTS

      let listOfNodesSearchBar = action.response.data.allotments
        ?.find((el) => el.id === ALLOTMENT_IDS.NOOCO)
        .AllotmentNodes.filter(
          (el) =>
            el.MasterDataEquipmentId &&
            el.AllotmentId === ALLOTMENT_IDS.NOOCO &&
            !el.isObsolete &&
            !el.name.toLowerCase().includes('forfaitaire')
        )
        .sort((a, b) => a.name.localeCompare(b.name));

      // GET LIST_OF_NODE_SEARCHBAR FILTRED WITH IS_CONFIGURED_SHEET_IMPORT_ALLOWED
      const listOfNodesSearchBarWithConfiguredSheetAllowed = listOfNodesSearchBar
        .filter((node) => {
          const masterData = action.response.data.type.find(
            (md) => md.id === node?.MasterDataEquipmentId
          );
          if (masterData?.isConfiguredSheetImportAllowed) {
            return node;
          }
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      // GET ALL ALOTMENTS IN FLAT

      let flatAllotments = [];

      action.response.data.allotments.map((el) => {
        flatAllotments = [...flatAllotments, ...el.AllotmentNodes];
      });

      const flatAllotmentsWithMd = flatAllotments.filter(
        (el) => el.MasterDataEquipmentId
      );

      let RE2O2OALlotments = sortFilterSousLot(
        action.response.data.allotments
          .find((allotment) => allotment.id === ALLOTMENT_IDS.RE2020)
          .AllotmentNodes.filter(
            (node) =>
              node.isReSousLot && !node.name.toLowerCase().includes('forfait')
          )
      );

      let EPCMAllotments = sortFilterSousLot(
        action.response.data.allotments
          .find((allotment) => allotment.id === ALLOTMENT_IDS.EPCM)
          .AllotmentNodes.filter(
            (node) =>
              node.isReSousLot && !node.name.toLowerCase().includes('forfait')
          )
      );

      const certifiedAllotments = {
        RE2020: {
          equipements: RE2O2OALlotments.filter((obj) => /^\d/.test(obj.name)),
          eau: RE2O2OALlotments.filter(
            (obj) => !/^\d/.test(obj.name) && obj.name.includes('Eau')
          ),
          energie: RE2O2OALlotments.filter(
            (obj) => !/^\d/.test(obj.name) && !obj.name.includes('Eau')
          ),
        },
        EPCM: {
          equipements: EPCMAllotments.filter((obj) => /^\d/.test(obj.name)),
          eau: EPCMAllotments.filter(
            (obj) => !/^\d/.test(obj.name) && obj.name.includes('Eau')
          ),
          energie: EPCMAllotments.filter(
            (obj) => !/^\d/.test(obj.name) && !obj.name.includes('Eau')
          ),
        },
      };

      return {
        ...state,
        allGroups: {
          type: action.response.data.type,
          sousLot: action.response.data.sousLots,
          allotments: action.response.data.allotments,
          flatAllotments,
          flatAllotmentsWithMd,
          projectTypes: action.response.data.projectTypes,
          renovationTypes: action.response.data.renovationTypes,
          phases: action.response.data.phases,
          sources: action.response.data.sources,
        },
        certifiedAllotments,
        listOfNodesSearchBar,
        listOfNodesSearchBarWithConfiguredSheetAllowed,
        decMds: decMds,
        rates: action.response.data.rates,
        buildingTypes: action.response.data.types,
        buildingCategories: action.response.data.categories,
        BuildingCategoryId: firstBuilding.BuildingCategoryId,
        BuildingTypeId: firstBuilding.id,
        allGroupsStatus: 'ok',
        templateTypeAvailable,
        templateTypeAvailableIds,
        indicators,
        fluids: action.response.data.fluids,
      };
    // break;

    case SAVE_SHEETS:
      return {
        ...state,
        sheetsDetails: {
          ...state.sheetsDetails,
          [state.nodeSearchValue?.id + state.projectId]: action.sheets,
        },
        sheets: action.sheets,
        sheetStatus: true,
        searchValue: action.md,
        loadingGraph: true,
      };
    case SET_LOADING_GRAPH:
      return {
        ...state,
        loadingGraph: false,
      };
    case LOAD_PROJECT_BY_ID:
      let projectAllot;

      if (action.response.data.id) {
        action.response.data.templateType = 'mappingRef';

        let company = state.allCompanies.find(
          (el) => el.id === action.response.data.CompanyId
        );

        let allAvailableProjectTypes = state.allGroups.projectTypes.filter((type) =>
          company.ProjectTypes.includes(type.id)
        );

        let lotsAvailable = [];

        allAvailableProjectTypes.forEach((projectType) => {
          lotsAvailable = [
            ...lotsAvailable,
            ...projectType.AllotmentNodes.filter((node) =>
              lotsAvailable.find((el) => el.id === node.id) ? false : true
            ),
          ];
        });

        action.response.data.Lots = lotsAvailable;

        let nodesAvailableForProjectType = state.allGroups.flatAllotments.filter(
          (el) => {
            if (
              el.AllotmentId === ALLOTMENT_IDS.NOOCO &&
              el.MasterDataEquipmentId &&
              !el.isObsolete
            ) {
              let nodeParent = findParentNode(
                el,
                state.allGroups.flatAllotments,
                [],
                []
              )[0];

              if (lotsAvailable.find((lot) => lot.id === nodeParent.id)) {
                return true;
              }
            }
          }
        );

        return {
          ...state,
          currentProject: action.response.data,
          isLoadingProjectDetails: false,
          currentAllotment: state.allGroups.allotments.find((el) => el.id === 1),
          nodesAvailableForProjectType,
        };
      }

      projectId = action.response.data.project.id;
      action.response.data.project.templateType =
        action.response.data.project.ProjectType.name;
      action.response.data.project.Lots = state.allGroups.projectTypes.find(
        (type) => type.name === action.response.data.project.ProjectType?.name
      )?.AllotmentNodes;

      let indicator = !state.currentIndicator
        ? ![
            TEMPLATE_TYPE_NAME.EPCM,
            TEMPLATE_TYPE_NAME.GLOBAL_MONITORING,
            TEMPLATE_TYPE_NAME.BBCA,
            TEMPLATE_TYPE_NAME.EXPLOITATION,
          ].includes(action.response.data.project.templateType)
          ? state.indicators?.find((elem) => elem.id === 1)
          : state.indicators?.find((elem) => elem.id === 26)
        : state.currentIndicator
        ? state.currentIndicator
        : null;

      let parsedProject;

      action.response.data.project.Sheets = [];

      if (action.response.data.project.templateType !== TYPE_EXPLOITATION) {
        parsedProject = parseProject(
          action.response.data.project,
          state.allGroups.type,
          state.allGroups.sousLot,
          indicator
        );

        parsedProject.urbanNetworks =
          action.response.data.urbanNetworks || state.parsedProject?.urbanNetworks;

        action.response.data.mds = returnAllMdsInProject(
          parsedProject.parsed,
          state.allGroups.type
        );

        action.response.data.project.Sheets = parsedProject.parsed;

        let currentZone = action.response.data.project.Zones[0];

        let buildingSiteDuration = 12;

        if (currentZone?.Zones && currentZone?.Zones.length > 0) {
          while (currentZone.Zones && currentZone.Zones.length != 0) {
            if (currentZone.Zones[0].type == 'buildingSite') {
              if (currentZone.Zones[0].ddv != null)
                buildingSiteDuration = Math.floor(currentZone.Zones[0].ddv * 12);

              break;
            } else currentZone = currentZone.Zones[0];
          }
        }

        action.response.data.project.defaultBuildingSiteDuration =
          buildingSiteDuration;

        projectAllot = createProjectStructure(
          action.response.data.project.ProjectOption.CurrentAllotmentId
            ? state.allGroups.allotments.find(
                (el) =>
                  el.id ===
                  action.response.data.project.ProjectOption.CurrentAllotmentId
              )
            : action.response.data.project.templateType === TYPE_NOOCO
            ? state.allGroups.allotments.find((el) => el.id === 1)
            : action.response.data.project.templateType === TYPE_EPCM
            ? state.allGroups.allotments.find((el) => el.name === 'E+C-')
            : action.response.data.project.templateType === TYPE_RE2020
            ? state.allGroups.allotments.find((el) => el.id === 2)
            : state.allGroups.allotments.find((el) => el.id === 1),
          action.response.data.project.Sheets,
          parsedProject.zones,
          action.response.data.project,
          true
        );
      }

      let currentAllotment = action.response.data.project.ProjectOption
        .CurrentAllotmentId
        ? state.allGroups.allotments.find(
            (el) =>
              el.id === action.response.data.project.ProjectOption.CurrentAllotmentId
          )
        : action.response.data.project.templateType === TYPE_NOOCO
        ? state.allGroups.allotments.find((el) => el.id === 1)
        : action.response.data.project.templateType === TYPE_EPCM
        ? state.allGroups.allotments.find((el) => el.name === 'E+C-')
        : action.response.data.project.templateType === TYPE_RE2020
        ? state.allGroups.allotments.find((el) => el.id === 2)
        : state.allGroups.allotments.find((el) => el.id === 1);

      const allotmentName =
        currentAllotment.id === 2 ? 'Re2020AllotmentNodeId' : 'EpcmAllotmentNodeId';

      // Get all nodes filtered for project type

      let nodesAvailableForProjectType = state.listOfNodesSearchBar.filter(
        (nodeSearchBar) => {
          let nodesDetails = findParentNode(
            nodeSearchBar,
            state.allGroups.flatAllotments,
            [],
            []
          );
          if (
            action.response.data.project.Lots.find(
              (lot) => nodesDetails[0].id === lot.id
            ) &&
            (!nodeSearchBar.name.toLowerCase().includes('forfait') ||
              (nodeSearchBar.name.toLowerCase().includes('forfait') &&
                nodeSearchBar.name.toLowerCase().includes('appareillage')))
          ) {
            if (
              (!REGULATORY_PROJECTS.includes(
                action.response.data.project.templateType
              ) &&
                nodesDetails[0].id === LOTS_IDS.TRANSPORTS_ENGINS) ||
              !(nodesDetails[0].id === LOTS_IDS.TRANSPORTS_ENGINS)
            ) {
              return true;
            }
          }

          return false;
        }
      );
      // Calc acv for each sheet with default indicator (carbon)
      // this sets acvResults in each sheet of the project

      // let totalPCE = calcAcv(1, action.response.data.project, state.decMds, action.response.data.mds, parsedProject.zones);

      //action.response.data.project.detailedPce = totalPCE;

      return {
        ...state,
        // projectsDetails: {
        //   ...state.projectsDetails, [projectId]: {
        //     parsedProject,
        //     project: action.response.data
        //   }
        // },
        nodesAvailableForProjectType,
        projectAllot: projectAllot,
        zones:
          action.response.data.project.templateType !== TYPE_EXPLOITATION
            ? parsedProject.zones
            : [],
        sheetsDetails: {},
        spinnerProjectModal: false,
        searchValue: '',
        inputValue: '',
        typeProject: 'new',
        currentProject: action.response.data.project,
        currentAllotment: currentAllotment,
        allotmentName,
        lotMode:
          action.response.data.project.ProjectOption.showAlternativeResult &&
          action.response.data.project.ProjectOption.CurrentAllotmentId
            ? `Référentiel ${state.allGroups.allotments
                .find(
                  (el) =>
                    el.id ===
                    action.response.data.project.ProjectOption.CurrentAllotmentId
                )
                .name.toUpperCase()}`
            : action.response.data.project.templateType === TYPE_NOOCO
            ? 'Référentiel NOOCO'
            : action.response.data.project.templateType === TYPE_BBCA
            ? 'Référentiel BBCA'
            : action.response.data.project.templateType === TYPE_EPCM
            ? 'Référentiel E+C-'
            : action.response.data.project.templateType === TYPE_RE2020
            ? 'Référentiel RE2020'
            : 'Référentiel NOOCO',
        currentIndicator: indicator,
        mds: action.response.data.mds
          ? action.response.data.mds.sort((a, b) => a.name.localeCompare(b.name))
          : [],
        rates: action.response.data.rates,
        isLoadingProjectDetails: false,
        selectedLot: null,
        selectedFonction: null,
        selectedCategory: null,
        selectedMaterial: null,
        selectedType: null,
        currentFlag: 'lot',
        parsedProject: parsedProject,
        patchModeLoading: false,
      };
    case LOAD_PROJECT_BY_SIMULATOR:
      projectId = action.response.data.project.id;

      action.response.data.project.templateType = 'nooco';

      action.response.data.project.Lots = state.allGroups.projectTypes.find(
        (type) => type.name === action.response.data.project.ProjectType?.name
      )?.AllotmentNodes;

      let indicatorSimulator = state.currentIndicator
        ? state.currentIndicator
        : action.response.data.project.templateType !== TYPE_EPCM
        ? state.indicators?.find((elem) => elem.id === 1)
        : state.indicators?.find((elem) => elem.id === 26);

      let parsedProjectSimulator;

      action.response.data.project.Sheets = [];

      parsedProjectSimulator = action.variant.parsedProject;

      const parsedSheets = parsedProjectSimulator.parsed;

      action.response.data.project.Sheets = parsedSheets;

      let currentZone = action.response.data.project.Zones[0];

      let buildingSiteDuration = 12;

      while (currentZone.Zones && currentZone.Zones.length != 0) {
        if (currentZone.Zones[0].type == 'buildingSite') {
          if (currentZone.Zones[0].ddv != null)
            buildingSiteDuration = Math.floor(currentZone.Zones[0].ddv * 12);

          break;
        } else currentZone = currentZone.Zones[0];
      }
      // currentZone = currentZone.Zones[0];

      action.response.data.project.defaultBuildingSiteDuration =
        buildingSiteDuration;

      // Calc acv for each sheet with default indicator (carbon)
      // this sets acvResults in each sheet of the project

      // let totalPCE = calcAcv(1, action.response.data.project, state.decMds, action.response.data.mds, parsedProject.zones);

      //action.response.data.project.detailedPce = totalPCE;

      return {
        ...state,
        // projectsDetails: {
        //   ...state.projectsDetails, [projectId]: {
        //     parsedProject,
        //     project: action.response.data
        //   }
        // },
        zones: action.response.data.zones,
        sheetsDetails: {},
        spinnerProjectModal: false,
        searchValue: '',
        inputValue: '',
        typeProject: 'new',
        currentProject: action.response.data.project,
        currentAllotment: action.response.data.project.ProjectOption
          .CurrentAllotmentId
          ? state.allGroups.allotments.find(
              (el) =>
                el.id ===
                action.response.data.project.ProjectOption.CurrentAllotmentId
            )
          : action.response.data.project.templateType === 'nooco'
          ? state.allGroups.allotments.find((el) => el.id === 1)
          : action.response.data.project.templateType === TEMPLATE_TYPE_NAME.EPCM
          ? state.allGroups.allotments.find((el) => el.name === 'E+C-')
          : action.response.data.project.templateType === TEMPLATE_TYPE_NAME.RE2020
          ? state.allGroups.allotments.find((el) => el.id === 2)
          : state.allGroups.allotments.find((el) => el.id === 1),
        lotMode:
          action.response.data.project.ProjectOption.showAlternativeResult &&
          action.response.data.project.ProjectOption.CurrentAllotmentId
            ? `Référentiel ${state.allGroups.allotments
                .find(
                  (el) =>
                    el.id ===
                    action.response.data.project.ProjectOption.CurrentAllotmentId
                )
                .name.toUpperCase()}`
            : action.response.data.project.templateType === 'nooco'
            ? 'Référentiel NOOCO'
            : action.response.data.project.templateType === TYPE_EPCM
            ? 'Référentiel E+C-'
            : action.response.data.project.templateType === TYPE_RE2020
            ? 'Référentiel RE2020'
            : 'Référentiel NOOCO',
        currentIndicator: indicatorSimulator,
        mds: action.response.data.mds
          ? action.response.data.mds.sort((a, b) => a.name.localeCompare(b.name))
          : [],
        rates: action.response.data.rates,
        isLoadingProjectDetails: false,
        selectedLot: null,
        selectedFonction: null,
        selectedCategory: null,
        selectedMaterial: null,
        selectedType: null,
        currentFlag: 'lot',
        parsedProject: parsedProjectSimulator,
        patchModeLoading: false,
      };
    case FORMAT_SHEETS:
      // For each sheet of the projet, add the corresponding masterData of the project.

      const project = { ...state.projectsDetails[action.projectId] };

      project.project.Sheets.forEach((sheet) => {
        const masterData = project.mds.find(
          (group) => group.id === sheet.ProjectSheet.MasterDataEquipmentId
        );

        // flatten md into sheet
        for (const [key, value] of Object.entries(masterData)) {
          sheet[key] = value;
        }

        // add quantity
        sheet.quantity = sheet.ProjectSheet.quantity;

        // Calcul de la colone Emission unitaire

        let emissionUnitaire = 0;

        for (const [key, value] of Object.entries(sheet)) {
          if (
            key.startsWith('carbon') &&
            (project.project.isUsageComputed || !key.startsWith('carbon_b'))
          ) {
            emissionUnitaire += value;
          }
        }

        sheet.emissionunitaire = emissionUnitaire.toLocaleString('fr-FR', {
          maximumFractionDigits: 2,
        });

        const coeff = project.project.ddv / sheet.ddv;

        // Calcul de la colonne coeff. De renouvellement.
        sheet.coeff = coeff.toLocaleString('fr-FR', {
          maximumFractionDigits: 2,
        });

        if (sheet.ddv >= project.project.ddv) {
          sheet.coeff = 1;
        }

        // Calcul de la conlone Emissions totales
        sheet.total = (emissionUnitaire * coeff * sheet.quantity).toLocaleString(
          'fr-FR',
          { maximumFractionDigits: 2 }
        );

        sheet.quantity = sheet.quantity.toLocaleString('fr-FR', {
          maximumFractionDigits: 2,
        });
      });

      return {
        ...state,
        projectsDetails: {
          ...state.projectsDetails,
          [action.projectId]: project,
        },
      };
    case SET_CURRENT_PROJECT: {
      const indicators = action.project.project.indices;

      if (action.project.project.templateType.includes(TYPE_EPCM)) {
        indicator = indicators?.find((elem) => elem.id === 26);
      }

      if (indicators.some((i) => i.relativeName?.includes('(GWP)'))) {
        const index = indicators.findIndex((el) =>
          el.relativeName?.includes('(GWP)')
        );

        [indicators[0], indicators[index]] = [indicators[index], indicators[0]];
      }

      return {
        ...state,
        currentProject: action.project.project.project,
        parsedProject: action.project.parsedProject,
        indicators,
        mds: action.project.project.mds,
        rates: action.project.project.rates,
        currentAllotment: action.project.project.isFreeModeActivated
          ? state.allGroups.allotments.find((el) => el.id === 1)
          : state.allGroups.allotments.find(
              (el) => el.id === action.project.project.referenceType[0]
            ),
        lotMode:
          action.project.project.ProjectOption.showAlternativeResult &&
          action.project.project.ProjectOption.CurrentAllotmentId
            ? `Référentiel ${state.allGroups.allotments
                .find(
                  (el) =>
                    el.id === action.project.project.ProjectOption.CurrentAllotmentId
                )
                .name.toUpperCase()}`
            : action.project.project.templateType === 'nooco'
            ? 'Référentiel NOOCO'
            : action.project.project.templateType === TYPE_EPCM
            ? 'Référentiel E+C-'
            : action.project.project.templateType === TYPE_RE2020
            ? 'Référentiel RE2020'
            : 'Référentiel NOOCO',
        searchValue: '',
        inputValue: '',
        selectedLot: null,
        selectedFonction: null,
        selectedCategory: null,
        selectedMaterial: null,
        selectedType: null,
        disabledBreadcrumbSelects: [false, true, true, true, true],
        currentFlag: 'lot',
      };
    }
    case SET_PROJECT_ID:
      return {
        ...state,
        projectId: action.id,
      };
    case SET_LOADING_PROJECT_DETAILS:
      return {
        ...state,
        isLoadingProjectDetails: true,
      };
    case SET_NEW_SHEETS:
      return {
        ...state,
        sheets: action.sheets,
        currentMd: action.md,
        loadingGraph: true,
      };
    case OPEN_FILTRABLE_LIST: {
      const object = {
        name: action.name,
        checked: true,
      };

      let isAlreadyInArray = state.lists.find((list) => list.name === action.name);

      if (isAlreadyInArray) {
        isAlreadyInArray = {
          ...isAlreadyInArray,
          checked: !isAlreadyInArray.checked,
        };

        let newList = state.lists.filter((list) => list.name !== action.name);

        newList = [...newList, isAlreadyInArray];

        return {
          ...state,
          lists: newList,
        };
      } else {
        const stateCopy = [...state.lists, object];

        return {
          ...state,
          lists: stateCopy,
        };
      }
    }
    case RESET_FILTERS:
      return {
        ...state,
        isFilterOpen: false,
        filters: [],
      };
    case OPEN_INDICATORS:
      return {
        ...state,
        isIndicatorsOpen: action.bool,
      };
    case LOAD_USER:
      return {
        ...state,
        user: action.user,
      };
    case SET_ID_ASKER:
      return {
        ...state,
        idAsker: action.id,
        showDedModal: action.addEquipment,
        modalLoadingDed: [],
      };
    case DISPLAY_DED_MODAL:
      return {
        ...state,
        showDedModal: true,
        modalLoadingDed: [],
      };
    case CLOSE_DED_MODAL:
      return {
        ...state,
        showDedModal: false,
      };
    case SET_LOADING_DED:
      return {
        ...state,
        loadingDed: action.bool,
      };
    case REQUEST_DED_SUCCESS:
      return {
        ...state,
        successDed: true,
        showDedModal: false,
      };
    case HIDE_SUCCESS_MESSAGE:
      return {
        ...state,
        successDed: false,
      };
    case SET_CURRENT_INDICATOR: {
      const indicator = state.indicators.find((elem) => elem.id === action.id);

      return {
        ...state,
        currentIndicator: indicator,
      };
    }
    case LOAD_INDICATORS_DATAS: {
      const indicator = state.indicators.find((elem) => elem.id === action.id);

      let currentProject = { ...state.currentProject };

      currentProject.Sheets.forEach((sheet) => {
        const datas = action.data.find((elem) => elem.id === sheet.id);

        if (datas.Indices.length === 0) {
          sheet.Indices = [...sheet.Indices, []];
        } else {
          sheet.Indices = [...sheet.Indices, datas.Indices[0]];
        }
      });

      try {
        calcAcv(
          action.id,
          currentProject,
          state.decMds,
          state.mds,
          state.parsedProject.zones
        );
      } catch (e) {
        console.log(e);
      }

      return {
        ...state,
        currentIndicator: indicator,
        currentProject: currentProject,
      };
    }
    case LOAD_ADDRESS: {
      return {
        ...state,
        searchLocations: action.address,
      };
    }
    case ADD_ZONES_SECTIONS: {
      // let zonesCopy = [...state.zonesSections];

      let buildingSections = [...state.buildingSections];

      if (action.remove) {
        buildingSections[action.index].Zones.pop();
      } else {
        buildingSections = addNewZone(buildingSections, action.index, action.t);
      }

      return {
        ...state,
        buildingSections: buildingSections,
      };
    }
    case MANAGEMENT_BUILDING_SECTIONS: {
      let copy = [...state.buildingSections];
      if (action.remove) {
        copy.pop();
      } else {
        copy = addNewBuilding(copy, action.index, action.rsetBuilding, action.t);
      }

      return {
        ...state,
        buildingSections: copy,
      };
    }
    case SET_SEARCH_VALUE: {
      return {
        ...state,
        searchValue: action.data,
      };
    }
    case SET_INPUT_VALUE: {
      return {
        ...state,
        inputValue: action.data,
      };
    }
    case CHANGE_TYPE_OF_PROJECT: {
      return {
        ...state,
        typeOfProject: 'projectWithZones',
      };
    }
    case HANDLE_CONTROLLED_ZONES: {
      let currentBuilding = state.buildingSections.find(
        (elem) => elem.flag === action.buildingIndex
      );
      let filterList = state.buildingSections.filter(
        (elem) => elem.flag !== action.buildingIndex
      );

      if (action.input === 'nameBuilding') {
        currentBuilding = { ...currentBuilding, name: action.value };
        state.buildingSections.find(
          (elem) => elem.flag === action.buildingIndex
        ).name = action.value;
      }

      if (action.input === 'speculativeDevelopment') {
        currentBuilding = {
          ...currentBuilding,
          speculativeDevelopment: action.value,
        };
        state.buildingSections.find(
          (elem) => elem.flag === action.buildingIndex
        ).speculativeDevelopment = action.value;
      }

      if (action.input === 'RenovationTypeId') {
        currentBuilding.Zones[action.zoneIndex] = {
          ...currentBuilding.Zones[action.zoneIndex],
          RenovationTypeId: action.value,
        };
      }

      if (action.input === 'nameZone') {
        currentBuilding.Zones[action.zoneIndex] = {
          ...currentBuilding.Zones[action.zoneIndex],
          name: action.value,
        };
      }

      if (action.input === 'surface') {
        currentBuilding.Zones[action.zoneIndex] = {
          ...currentBuilding.Zones[action.zoneIndex],
          area: action.value,
        };
      }

      if (action.input === 'type') {
        currentBuilding.Zones[action.zoneIndex] = {
          ...currentBuilding.Zones[action.zoneIndex],
          BuildingTypeId: action.value,
        };

        if (
          action.value === '1' ||
          action.value === '2' ||
          action.value === '10' ||
          action.value === '11'
        ) {
          currentBuilding.Zones[action.zoneIndex] = {
            ...currentBuilding.Zones[action.zoneIndex],
            menInput: true,
            logementInput: false,
            comblesInput: false,
          };

          if (action.value === '1') {
            currentBuilding.Zones[action.zoneIndex] = {
              ...currentBuilding.Zones[action.zoneIndex],
              logementInput: true,
            };
          }

          if (action.value === '2') {
            currentBuilding.Zones[action.zoneIndex] = {
              ...currentBuilding.Zones[action.zoneIndex],
              comblesInput: true,
              logementInput: true,
            };
          }
        } else {
          currentBuilding.Zones[action.zoneIndex] = {
            ...currentBuilding.Zones[action.zoneIndex],
            menInput: false,
            logementInput: false,
            comblesInput: false,
          };
        }
      }

      if (action.input === 'menInput') {
        currentBuilding.Zones[action.zoneIndex] = {
          ...currentBuilding.Zones[action.zoneIndex],
          nbOccupants: action.value,
        };
      }

      if (action.input === 'comblesInput') {
        currentBuilding.Zones[action.zoneIndex] = {
          ...currentBuilding.Zones[action.zoneIndex],
          sCombles: action.value,
        };
      }

      if (action.input === 'logementInput') {
        currentBuilding.Zones[action.zoneIndex] = {
          ...currentBuilding.Zones[action.zoneIndex],
          nbLgt: action.value,
        };
      }

      if (action.input === 'IsConnectedToUrbanNetwork') {
        currentBuilding.Zones[action.zoneIndex] = {
          ...currentBuilding.Zones[action.zoneIndex],
          IsConnectedToUrbanNetwork: action.value,
        };
      }

      // need just this from begging... Uf...
      if (action.input && action.input !== 'type') {
        currentBuilding.Zones[action.zoneIndex] = {
          ...currentBuilding.Zones[action.zoneIndex],
          [action.input]: action.value,
        };
      }

      filterList = [...filterList, currentBuilding];

      return {
        ...state,
        buildingSections: filterList.sort(function (a, b) {
          if (a.flag < b.flag) {
            return -1;
          }

          if (a.flag > b.flag) {
            return 1;
          }

          return 0;
        }),
      };
    }
    case ASKING_IS_DED:
      return {
        ...state,
        equipmentDedAsking: action.row,
        modalLoadingDed: action.row ? [action.row.id] : [],
      };

    case SET_NOMENCLATURE_INIES: {
      // Get all parents
      let tree = action.data.filter(
        (el) => el.Tree_Level === 0 && el.Parent_Item_ID
      );

      // parse every nomenclature inies datas in good structure
      for (let i = 0; i < tree.length; i++) {
        tree[i] = recursiveNomenclatureInies(action.data, tree[i]);
      }

      return {
        ...state,
        iniesNomenclature: tree,
      };
    }
    case UPDATE_ACTIVATED_SELECTS:
      const cpy = [...state.disabledBreadcrumbSelects];

      cpy[(action.id += 1)] = false;

      if (action.value) {
        cpy[(action.id += 1)] = false;
      }

      for (let index = action.id; index < cpy.length; index += 1) {
        cpy[index] = true;
      }

      return {
        ...state,
        disabledBreadcrumbSelects: cpy,
      };
    case MODIFY_ACTIVATED_SELECTS:
      return {
        ...state,
        disabledBreadcrumbSelects: action.newSelects,
      };
    case GENERATE_RSEE:
      return {
        ...state,
        downloadRsee: true,
        rseeName: action.projectName,
      };
    case GET_RSEE_PDF:
      return {
        ...state,
        loaderPdf: true,
      };
    case DOWNLOAD_RSEE:
      return {
        ...state,
        downloadRsee: true,
      };
    case SAVE_RSEE:
      if (action.xml) {
        let zip = new JSZip();

        let rseeFile = action.xml.shift();

        zip.file(`${action.projectName}.xml`, rseeFile, {
          compression: 'DEFLATE',
        });

        for (let fc of action.xml) {
          const splittedUrl = fc.config.url.split('/');
          let fcName = '';

          for (let i = 4; i < splittedUrl.length; i++) {
            if (splittedUrl[i].includes('.xml')) {
              fcName += splittedUrl[i].split('.xml')[0];
              fcName += '.xml';
            } else {
              fcName += splittedUrl[i];

              if (i !== splittedUrl.length - 1) {
                fcName += '-';
              }
            }
          }

          zip.file('Fiches_configurees/' + decodeURIComponent(fcName), fc.data, {
            compression: 'DEFLATE',
          });
        }

        zip.generateAsync({ type: 'blob' }).then(function (content) {
          // see FileSaver.js
          saveAs(content, `${action.projectName}.zip`);
        });
      }

      return {
        ...state,
        downloadRsee: false,
      };

    case SAVE_PDF_RSEE:
      const pdfBlob = new Blob([action.pdf[0]], { type: 'application/pdf' });

      const pdfBlob2 = new Blob([action.pdf[1]], { type: 'application/pdf' });

      saveAs(pdfBlob, `${action.projectName}_Rset.pdf`);
      saveAs(pdfBlob2, `${action.projectName}_Rsenv.pdf`);

      return {
        ...state,
        loaderPdf: false,
      };

    case RESET_BUILDING_SECTIONS:
      return {
        ...state,
        buildingSections: [
          {
            flag: 0,
            name: 'Bâtiment 1',
            type: 'building',
            Zones: [
              {
                name: 'Zone 1',
                BuildingTypeId: 1,
                menInput: true,
                logementInput: true,
                comblesInput: false,
                area: null,
                type: 'zone',
                category: 'CE1',
              },
            ],
          },
        ],
      };

    case HANDLE_RSET_MODAL:
      return {
        ...state,
        rsetmodalOpen: action.open,
        fluidsArray: action.fluidsArray,
        equipsBulkArray: action.equipsBulkArray,
        linkedFluids: action.linkedFluids,
        spinnerProjectModal: false,
      };

    case SET_PROJECT_MODAL_PAGE:
      return {
        ...state,
        projectModalPage: action.page,
      };

    case LOAD_NEW_EQUIPMENT:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          EmissionValues: [action.data.projectEmissions],
        },
      };

    case CALCULATOR_UPDATE:
      return {
        ...state,
        calculator: {
          ...state.calculator,
          [action.name]: action.value,
        },
      };

    case UPDATE_WATER_CALCULATOR:
      return {
        ...state,
        waterCalculator: {
          ...state.waterCalculator,
          [action.name]: action.value,
        },
      };

    case UPDATE_CALCULATOR_DATA:
      return {
        ...state,
        calculator: {
          ...state.calculator,
          calculatorData: {
            ...state.calculator.calculatorData,
            // energies: action.energies,
            [action.name]: action.value,
            // ...state.calculator.calculatorData.energies,
            // [action.name]: action.value
          },
        },
      };

    case UPDATE_CALCULATOR_WATER_DETAILED:
      return {
        ...state,
        waterCalculator: {
          ...state.waterCalculator,
          calculatorDetailed: {
            ...state.waterCalculator.calculatorDetailed,
            // energies: action.energies,
            [action.name]: action.value,
            // ...state.calculator.calculatorData.energies,
            // [action.name]: action.value
          },
        },
      };

    case SELECT_CALCULATOR_WORKSITE:
      return {
        ...state,
        calculator: {
          ...state.calculator,
          isCalculatorActived: true,
          isWorksiteCalculatorSelected: true,
        },
        waterCalculator: {
          ...state.waterCalculator,
          isWaterCalculatorDetailed: false,
          isWaterCalculatorSelected: false,
        },
      };

    case SELECT_CALCULATOR_WATER:
      return {
        ...state,
        calculator: {
          ...state.calculator,
          isCalculatorActived: true,
          isWorksiteCalculatorSelected: false,
        },
        waterCalculator: {
          ...state.waterCalculator,
          isWaterCalculatorSelected: true,
          // isWaterCalculatorDetailed: true,
        },
      };

    case CALCULATOR_EXIT:
      return {
        ...state,
        calculator: {
          ...state.calculator,
          isCalculatorActived: false,
          isWorksiteCalculatorSelected: false,
        },
        waterCalculator: {
          ...state.waterCalculator,
          isWaterCalculatorSelected: false,
        },
      };

    case UPDATE_PROJECTS_LIST_MONITORING:
      return {
        ...state,
        projectsListMonitoring: {
          ...state.projectsListMonitoring,
          [action.name]: action.value,
        },
      };

    case UPDATE_INIT_PROJECTS_LIST_MONITORING:
      return {
        ...state,
        projectsListMonitoring: {
          ...state.projectsListMonitoring,
          isInit: action.boolean,
        },
      };

    case UPDATE_ARIANE_FOLDER_VALUES:
      let newArianeFolderState = {
        ...state.projectsListMonitoring.arianeFolder,
      };

      action.valuesElement.forEach((valueElement) => {
        const { name, value } = valueElement;

        newArianeFolderState[name] = value;
      });

      return {
        ...state,
        projectsListMonitoring: {
          ...state.projectsListMonitoring,
          arianeFolder: {
            ...newArianeFolderState,
          },
        },
      };

    case UPDATE_ARIANE_COMPANY_VALUES:
      let newArianeCompanyState = {
        ...state.projectsListMonitoring.arianeCompany,
      };

      action.valuesElement.forEach((valueElement) => {
        const { name, value } = valueElement;

        newArianeCompanyState[name] = value;
      });

      return {
        ...state,
        projectsListMonitoring: {
          ...state.projectsListMonitoring,
          arianeCompany: {
            ...newArianeCompanyState,
          },
        },
      };

    case CALCULATOR_RESET:
      return {
        ...state,
        calculator: {
          ...state.calculator,
          isCalculatorActived: false,
          isOldBuildingSiteInProject: undefined,
          isOldWaterLotInProject: undefined,
          // nbZoneBuildingSite: undefined,
          // isBuildingSiteActivatedInParams: undefined,
          isWorksiteCalculatorSelected: false,
          isWorksiteCalculatorDetailed: false,
          loadingCalculator: false, // true if loading in progress
          isCalculatorDetailedLoaded: false, // true if calculatorData is loaded.
          isWorksiteCalculatorSimplified: false,
          worksiteCalculatorNav: [],
          worksiteCalculatorDetailedActive: 0,
          isLoadingCaracs: false,
          resultOfSearchCarac: null,
          isTransportErrorDisplay: false,
          zoneSelected: null,
          transportLines: [
            {
              id: 0,
              MasterDataEquipmentId: null,
              quantity: null,
              type: null,
              carburant: null,
              caracs: [],
              caracCombo: null,
              origin: {
                type: 'project',
                sourceId: null,
                uniqueId: uuidv4(),
              },
            },
          ],
          transportEquipments: [],
          isTotalGazoilSelected: false,
          isInputByGearSelected: false,
          isInputByExcavatedSelected: false,
          isGearErrorDisplay: false,
          gearLines: [
            {
              id: 0,
              MasterDataEquipmentId: null,
              quantity: null,
              type: null,
              motorisation: null,
              consumption: null,
              unit: null,
              unitConsumption: null,
              Caracs: [],
              origin: {
                type: 'project',
                sourceId: null,
                uniqueId: uuidv4(),
              },
            },
          ],
          calculatorTotalGazoilGear: [
            {
              quantity: null,
              origin: {
                type: 'project',
                sourceId: null,
                uniqueId: uuidv4(),
              },
            },
          ],
          calculatorTotalExcavatedGear: [
            {
              quantity: null,
              origin: {
                type: 'project',
                sourceId: null,
                uniqueId: uuidv4(),
              },
            },
          ],
          // gearsCaracData: [],
          gearsEquipments: [],
          calculatorData: {
            energy: {
              energiesArray: [
                {
                  MasterDataEquipment: null,
                  quantity: '',
                  caracs: null,
                  origin: {
                    type: 'project',
                    sourceId: null,
                    uniqueId: uuidv4(),
                  },
                },
              ],
              // gazoleQuantity: "",
            },
            energyCalculatorSimplified: {
              energySimplifiedArray: [
                {
                  NumberMonthsWithCraneSummer: null,
                  NumberMonthsWithCraneWinter: null,
                  NumberMonthsWithoutCraneSummer: null,
                  NumberMonthsWithoutCraneWinter: null,
                  origin: {
                    type: 'project',
                    sourceId: null,
                    uniqueId: uuidv4(),
                  },
                  MasterDataEquipment: null,
                  caracs: null,
                  AllotmentNode: null,
                },
              ],
            },
            water: {
              displayUnit: 'm3',
              zonesData: [
                {
                  drinkableWater: '',
                  wastedWater: '',
                  origin: {
                    type: 'project',
                    sourceId: null,
                    uniqueId: uuidv4(),
                  },
                },
              ],
            },
            importedEarthWork: [
              {
                quantity: '',
                distance: '',
                origin: {
                  type: 'project',
                  sourceId: null,
                  uniqueId: uuidv4(),
                },
              },
            ],
            evacuatedEarthWork: [
              {
                masterDataId: undefined,
                AllotmentNode: undefined,
                quantity: '',
                distance: '',
                origin: {
                  type: 'project',
                  sourceId: null,
                  uniqueId: uuidv4(),
                },
              },
            ],
            evacuatedEarthWorkIndividualHouse: [
              {
                area: '',
                distance: '',
                origin: {
                  type: 'project',
                  sourceId: null,
                  uniqueId: uuidv4(),
                },
              },
            ],
            specific: {
              specificArray: [
                {
                  MasterDataEquipment: null,
                  quantity: '',
                  caracs: null,
                  origin: {
                    type: 'project',
                    sourceId: null,
                    uniqueId: uuidv4(),
                  },
                },
              ],
            },
          },
        },
      };

    case CALCULATOR_WATER_RESET:
      return {
        ...state,
        calculator: {
          ...state.calculator,
          isCalculatorActived: false,
          // isOldBuildingSiteInProject: undefined,
          // isOldWaterLotInProject: undefined,
          // nbZoneBuildingSite: undefined,
        },
        waterCalculator: {
          ...state.waterCalculator,
          isWaterCalculatorSelected: false,
          isWaterCalculatorDetailed: false,
          isWaterCalculatorSimplified: false,
          loadingWaterCalculator: false,
          isWaterCalculatorLoaded: false,
          waterCalculatorDetailedActive: 0,
          // index of component to display cf CalculatorWorksite/componentToDisplay
          waterCalculator: {
            ...state.waterCalculator.waterCalculator,
            buildings: [],
          },
          calculatorDetailed: null,
        },
      };

    case USER_LOGOUT:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        isLoadingProjects: false,
        isLoadingGenerateRSEnv: false,
      };
    case GET_USER_METHOD_TO_LOGGED_IN: {
      return {
        ...state,
        loginCheckProviderLoading: true,
      };
    }

    default:
      return state;
  }
}

export default projectsReducer;
