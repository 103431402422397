export const UPDATE_BUNDLE_VALUE = 'UPDATE_BUNDLE_VALUE';
export const GET_CARACS = 'GET_CARACS';
export const FETCH_SHORT_BUNDLE = 'FETCH_SHORT_BUNDLE';

export const updateBundleValue = (name: string, value: any) => {
  return {
    type: UPDATE_BUNDLE_VALUE,
    name,
    value,
  };
};

export const getCaracs = (newLine, newBundleLines) => {
  return {
    type: GET_CARACS,
    newLine,
    newBundleLines,
  };
};
