import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'react-router-redux';
import calculatorMiddleware from '../middlewares/calculator';
import equipmentsMiddleware from '../middlewares/equipments';
import exploitationMiddleware from '../middlewares/exploitation';
import favoritesMiddleware from '../middlewares/favorites';
import helpMiddleware from '../middlewares/help';
import importMiddleware from '../middlewares/import';
import productsMiddleware from '../middlewares/products';
import projectsMiddleware from '../middlewares/projects';
import reducer from '../reducers/rootReducer';
import rightsMiddleware from '../middlewares/rights';
import simulatorMiddleware from '../middlewares/simulator';
import variantsMiddleware from '../middlewares/variants';

const middleware = routerMiddleware(window.history);

const enhancers = composeWithDevTools({
  trace: true, // Enable trace feature
  traceLimit: 20,
})(
  applyMiddleware(
    middleware,
    equipmentsMiddleware,
    importMiddleware,
    projectsMiddleware,
    rightsMiddleware,
    variantsMiddleware,
    productsMiddleware,
    exploitationMiddleware,
    helpMiddleware,
    calculatorMiddleware,
    simulatorMiddleware,
    favoritesMiddleware
  )
);
const store = createStore(reducer, enhancers);

export default store;
