/* eslint-disable no-unused-vars */
import { Dispatch } from 'redux';
import { MapDispatchToPropsParam, MapStateToPropsParam, connect } from 'react-redux';

import ListActions from '../../app/exploitation/ListActions';

import {
  fetchBoarding,
  fetchListActions,
  searchListActions,
  updateExploitationValue,
} from '../../actions/exploitation';

interface IExploitation {
  displayScreen: string;
  listActionDatas: {}[];
  actionOffset: number;
  boardingListDatas: {}[];
  loadingActions: boolean;
  isActionsFull: boolean;
  eventTypes: {}[];
  listActionsInput: string;
  filteredListActionsDatas: null | {}[];
}

interface IProjects {
  allGroups: {
    type: [];
  };
  currentIndicator: {};
}

type State = {
  exploitation: IExploitation;
  projects: IProjects;
};

const mapStateToProps: MapStateToPropsParam<{}, {}, State> = (state: State) => ({
  currentIndicator: state.projects.currentIndicator,
  displayScreen: state.exploitation.displayScreen,
  listActionDatas: state.exploitation.listActionDatas,
  actionOffset: state.exploitation.actionOffset,
  boardingListDatas: state.exploitation.boardingListDatas,
  isActionsFull: state.exploitation.isActionsFull,
  loadingActions: state.exploitation.loadingActions,
  eventTypes: state.exploitation.eventTypes,
  filteredListActionsDatas: state.exploitation.filteredListActionsDatas,
  listActionsInput: state.exploitation.listActionsInput,
});

const mapDispatchToProps: MapDispatchToPropsParam<{}, {}> = (
  dispatch: Dispatch
) => ({
  updateExploitationValue: (name: string, value: string) => {
    dispatch(updateExploitationValue(name, value));
  },
  fetchListActions: (id: number, t: any) => {
    dispatch(fetchListActions(id, t));
  },
  fetchBoarding: (offset: number, id: number) => {
    dispatch(fetchBoarding(offset, id));
  },
  searchListActions: () => {
    dispatch(searchListActions());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ListActions);
