import { SimulatorInformationDTO } from '../app/models/SimulatorDTO';

export const UPDATE_SIMULATOR = 'UPDATE_SIMULATOR';
export const UPDATE_SIMULATOR_INFORMATION = 'UPDATE_SIMULATOR_INFORMATION';
export const UPDATE_SIMULATOR_DATA_FOR_GRAPH = 'UPDATE_SIMULATOR_DATA_FOR_GRAPH';

export const UPDATE_SIMULATOR_FIRST_VARIANT = 'UPDATE_SIMULATOR_FIRST_VARIANT';
export const UPDATE_SIMULATOR_SECOND_VARIANT = 'UPDATE_SIMULATOR_SECOND_VARIANT';
export const GET_CARAC_SIMULATOR_VARIANT = 'GET_CARAC_SIMULATOR_VARIANT';
export const SIMULATOR_FIRST_VARIANT_RESET = 'SIMULATOR_FIRST_VARIANT_RESET';
export const SIMULATOR_SECOND_VARIANT_RESET = 'SIMULATOR_SECOND_VARIANT_RESET';
export const SIMULATOR_VARIANT_RESET_REDUCER = 'SIMULATOR_VARIANT_RESET_REDUCER';
export const CREATE_SIMULATOR_FIRST_VARIANT_NEW_EQUIPMENT =
  'CREATE_SIMULATOR_FIRST_VARIANT_NEW_EQUIPMENT';
export const CREATE_SIMULATOR_SECOND_VARIANT_NEW_EQUIPMENT =
  'CREATE_SIMULATOR_SECOND_VARIANT_NEW_EQUIPMENT';
export const LOAD_NEW_EQUIPMENT_FIRST_VARIANT_SIMULATOR =
  'LOAD_NEW_EQUIPMENT_FIRST_VARIANT_SIMULATOR';
export const LOAD_NEW_EQUIPMENT_SECOND_VARIANT_SIMULATOR =
  'LOAD_NEW_EQUIPMENT_SECOND_VARIANT_SIMULATOR';
export const SIMULATOR_DUPLICATE_VARIANT = 'SIMULATOR_DUPLICATE_VARIANT';
export const SIMULATOR_DUPLICATE_VARIANT_REDUCER =
  'SIMULATOR_DUPLICATE_VARIANT_REDUCER';
export const FETCH_QUICK_SIMULATOR = 'FETCH_QUICK_SIMULATOR';
export const PATCH_QUICK_SIMULATOR_INFORMATION = 'PATCH_QUICK_SIMULATOR_INFORMATION';
export const FETCH_SHEETS_GRAPH = 'FETCH_SHEETS_GRAPH';
export const LOAD_PROJECT_BY_SIMULATOR = 'LOAD_PROJECT_BY_SIMULATOR';

export const DELETE_SIMULATOR_EQUIPMENT = 'DELETE_SIMULATOR_EQUIPMENT';

export type SimulatorActionDTO =
  | { type: 'UPDATE_SIMULATOR'; name: string; value: any }
  | { type: 'UPDATE_SIMULATOR_INFORMATION'; form: SimulatorInformationDTO }
  | { type: 'PATCH_QUICK_SIMULATOR_INFORMATION'; form: SimulatorInformationDTO }
  | { type: 'UPDATE_SIMULATOR_FIRST_VARIANT'; name: string; value: any }
  | { type: 'UPDATE_SIMULATOR_DATA_FOR_GRAPH'; name: string; value: any }
  | { type: 'UPDATE_SIMULATOR_SECOND_VARIANT'; name: string; value: any }
  | { type: ''; variantNumber: 'first' | 'second'; AllotmentNodesIds: number[] }
  | { type: 'CREATE_SIMULATOR_FIRST_VARIANT_NEW_EQUIPMENT' }
  | { type: 'CREATE_SIMULATOR_SECOND_VARIANT_NEW_EQUIPMENT' }
  | { type: 'SIMULATOR_FIRST_VARIANT_RESET' }
  | { type: 'SIMULATOR_SECOND_VARIANT_RESET' }
  | { type: 'SIMULATOR_DUPLICATE_VARIANT' }
  | { type: 'FETCH_QUICK_SIMULATOR' }
  | { type: 'SIMULATOR_DUPLICATE_VARIANT_REDUCER' }
  | {
      type: 'SIMULATOR_VARIANT_RESET_REDUCER';
      variantNumber: 'first' | 'second';
    }
  | { type: 'LOAD_NEW_EQUIPMENT_FIRST_VARIANT_SIMULATOR' }
  | { type: 'LOAD_NEW_EQUIPMENT_SECOND_VARIANT_SIMULATOR' }
  | { type: 'DELETE_SIMULATOR_EQUIPMENT'; variantNumber: 'first' | 'second' }
  | {
      type: 'LOAD_PROJECT_BY_SIMULATOR';
      data: {
        data: { project: any; mds: any; urbanNetworks: any; zones: any };
      };
      variant: any;
    }
  | {
      type: 'FETCH_SHEETS_GRAPH';
      mdId: number;
      currentIndicatorId: number;
      projectId: number | null;
    };

export function patchQuickSimulatorInformation(form: SimulatorInformationDTO) {
  return {
    type: 'PATCH_QUICK_SIMULATOR_INFORMATION',
    form,
  };
}

export const fetchQuickSimulator = () => ({
  type: FETCH_QUICK_SIMULATOR,
});

export const deleteSimulatorEquipment = (variantNumber: 'first' | 'second') => ({
  type: DELETE_SIMULATOR_EQUIPMENT,
  variantNumber,
});

export const loadProjectBySimulator = (
  response: {
    data: { project: any; mds: any; urbanNetworks: any; zones: any };
  },
  variant: any
) => ({
  type: LOAD_PROJECT_BY_SIMULATOR,
  response,
  variant,
});

export const simulatorDuplicateVariant = () => ({
  type: SIMULATOR_DUPLICATE_VARIANT,
});

export const simulatorDuplicateVariantReducer = () => ({
  type: SIMULATOR_DUPLICATE_VARIANT_REDUCER,
});

export const loadNewEquipmentFirstVariantSimulator = () => ({
  type: LOAD_NEW_EQUIPMENT_FIRST_VARIANT_SIMULATOR,
});

export const loadNewEquipmentSecondVariantSimulator = () => ({
  type: LOAD_NEW_EQUIPMENT_SECOND_VARIANT_SIMULATOR,
});

export const simulatorFirstVariantReset = () => ({
  type: SIMULATOR_FIRST_VARIANT_RESET,
});

export const simulatorSecondVariantReset = () => ({
  type: SIMULATOR_SECOND_VARIANT_RESET,
});

export const simulatorVariantResetReducer = (variantNumber: 'first' | 'second') => ({
  type: SIMULATOR_VARIANT_RESET_REDUCER,
  variantNumber,
});

export const createSimulatorFirstVariantNewEquipment = () => ({
  type: CREATE_SIMULATOR_FIRST_VARIANT_NEW_EQUIPMENT,
});

export const createSimulatorSecondVariantNewEquipment = () => ({
  type: CREATE_SIMULATOR_SECOND_VARIANT_NEW_EQUIPMENT,
});

export function getCaracSimulatorVariant(
  variantNumber: 'first' | 'second',
  AllotmentNodesIds: number[]
) {
  return {
    type: 'GET_CARAC_SIMULATOR_VARIANT',
    variantNumber,
    AllotmentNodesIds,
  };
}

export function updateSimulator(name: string, value: any) {
  return {
    type: 'UPDATE_SIMULATOR',
    name,
    value,
  };
}

export function updateSimulatorInformation(form: SimulatorInformationDTO) {
  return {
    type: 'UPDATE_SIMULATOR_INFORMATION',
    form,
  };
}

export function updateSimulatorFirstVariant(name: string, value: any) {
  return {
    type: 'UPDATE_SIMULATOR_FIRST_VARIANT',
    name,
    value,
  };
}

export function updateSimulatorSecondVariant(name: string, value: any) {
  return {
    type: 'UPDATE_SIMULATOR_SECOND_VARIANT',
    name,
    value,
  };
}

export function updateSimulatorDataForGraph(name: string, value: any) {
  return {
    type: 'UPDATE_SIMULATOR_DATA_FOR_GRAPH',
    name,
    value,
  };
}

export function fetchSheetsGraph(
  allotmentNodeId: number,
  mdId: number,
  currentIndicatorId: number,
  projectId: number | null
) {
  return {
    type: 'FETCH_SHEETS_GRAPH',
    allotmentNodeId,
    mdId,
    currentIndicatorId,
    projectId,
  };
}
