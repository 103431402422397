/**
 * Created by nanaa on 18/05/2021
 */

import { connect } from 'react-redux';
import LifeCycleAnalysisGraph from '../../app/projects/LifeCycle/LifeCycleAnalysisGraph';

const mapStateToProps = (state) => ({
  currentIndicator: state.projects.currentIndicator,
  projectArea: state.projects.currentProject.area,
  isRe2020: state.projects.currentProject.isRe2020,
  currentProject: state.projects.currentProject,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LifeCycleAnalysisGraph);
