import { Select } from '@photocarbone/nooco-ui';
import { UseFormSetValue, useFormContext } from 'react-hook-form';
import { Dispatch } from 'redux';
import { EquipmentFromListEquipment } from '../ListEquipmentsFunction';
import {
  filterSousLotByTypeOfNodes,
  findLotFromAllotmentNode,
  sortFilterSousLot,
} from '../../../../shared/utils';
import { OptionType } from '../../../../globalVariable/enumType';
import { ALLOTMENT_IDS } from '../../../../globalVariable/typeProject';
import { StateDTO } from '../../../../models/GeneralDTO';
import { useSelector } from 'react-redux';
import { AllotmentNodeDTO } from '../../../../models/ProjectDTO';

interface CertifiedLotSelectProps {
  tableEditMode: boolean;
  record: EquipmentFromListEquipment;
  updateFormData: Function;
  dispatch: Dispatch;
  isUserStartEditColumns: boolean;
  setValue: UseFormSetValue<any>;
  currentNode: AllotmentNodeDTO;
  disabled: boolean;
}

const CertifiedLotSelect = ({
  currentNode,
  tableEditMode,
  record,
  updateFormData,
  dispatch,
  isUserStartEditColumns,
  setValue,
  disabled,
}: CertifiedLotSelectProps) => {
  const { currentAllotment, allGroups } = useSelector(
    (state: StateDTO) => state.projects
  );

  const { watch } = useFormContext();

  const searchTypeSearchGroupField = watch(
    `ProjectSheets[${record.id}].searchGroupSelectType`
  );

  const allotmentIdToUse =
    currentAllotment.id === ALLOTMENT_IDS.NOOCO
      ? ALLOTMENT_IDS.RE2020
      : currentAllotment.id;

  let lotNode = findLotFromAllotmentNode(
    allGroups.flatAllotments,
    record.ProjectSheet.AllotmentNodes.find(
      (node) => node.AllotmentId === ALLOTMENT_IDS.NOOCO
    )
  );

  let typeOfNode = searchTypeSearchGroupField
    ? searchTypeSearchGroupField
    : ['energie', 'eau'].includes(lotNode.name.toLowerCase())
    ? lotNode.name.toLowerCase()
    : OptionType.EQUIPMENTS;

  return (
    <Select
      width="150px"
      keyValue="id"
      disabled={!tableEditMode || disabled}
      defaultValue={currentNode}
      size="small"
      keyItem="name"
      keyDisplay="name"
      style={{
        'background-color': `${currentNode.color}`,
        color: 'white',
        width: '60px',
      }}
      itemsArray={filterSousLotByTypeOfNodes(
        sortFilterSousLot(
          allGroups.allotments
            .find((allot) => allot.id === allotmentIdToUse)
            .AllotmentNodes.filter(
              (node) =>
                node.isReSousLot && !node.name.toLowerCase().includes('forfait')
            )
        ),
        typeOfNode
      )}
      onChange={(value) => {
        let nodeSelected = allGroups.flatAllotments.find(
          (node) =>
            node.name === value &&
            node.isReSousLot &&
            node.AllotmentId === allotmentIdToUse
        );

        updateFormData(
          setValue,
          record,
          'AllotmentNodeCertified',
          { ...nodeSelected, label: nodeSelected.name },
          dispatch,
          isUserStartEditColumns
        );
      }}
    />
  );
};

export default CertifiedLotSelect;
