//import 'antd/dist/antd.min.css';
import { Autocomplete } from '@material-ui/lab';
import { Button, Input, Popover } from 'antd';
import { ConsoleLogger } from '@aws-amplify/core';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { getColorFromString } from '../services/colors/getColor';
import { sendMessageTeamsFaq } from '../../actions/import';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 150,
    '&$expanded': {
      minHeight: 150,
      color: '#44DDC3',
    },
    'border-bottom': 0,
    position: 'initial',
  },
  expanded: {},
})(MuiAccordionSummary);

const Accordion = withStyles({
  root: {
    boxShadow: '0',
    border: 0,
  },
  expanded: {},
})(MuiAccordion);

const basequestionsWithoutTags = require('./questions.json');
const basequestions = basequestionsWithoutTags.map((question) => {
  const tag1 = question['TAG 1'] + ';' || '';
  const tag2 = question['TAG 2'] + ';' || '';
  const tag3 = question['TAG 3'] + ';' || '';
  const tag4 = question['TAG 4'] + ';' || '';
  question.TAG = tag1 + tag2 + tag3 + tag4;

  return question;
});

const { TextArea } = Input;

const Faq = () => {
  const [questions, setQuestions] = useState(basequestions);
  const [searchBar, setSearchBar] = useState('');
  const [filteredQuestions, setFilteredQuestions] = useState(basequestions);
  const [expanded, setExpanded] = useState(null);
  const [summary, setSummary] = useState([]);
  const [activeSummary, setActiveSummary] = useState(null);
  const [tags, setTags] = useState(null);
  const { t } = useTranslation();

  const [tagsList, setTagsList] = useState([
    {
      id: 1,
      name: t('APP.HELP.FAQ.PH_FF802A', { defaultValue: 'Base de données' }),
      color: '#E3B9B9',
    },
    {
      id: 3,
      name: t('APP.HELP.FAQ.PH_E302A8', { defaultValue: 'Calcul carbone' }),
      color: '#FFD384',
    },
    {
      id: 2,
      name: t('APP.HELP.FAQ.PH_1C8FED', { defaultValue: 'Certification' }),
      color: '#FDD0D0',
    },
    {
      id: 4,
      name: t('APP.HELP.FAQ.PH_76CE9D', { defaultValue: 'Chantier' }),
      color: '#FFEAB6',
    },
    {
      id: 5,
      name: t('APP.HELP.FAQ.PH_3036ED', { defaultValue: 'Eau' }),
      color: '#D0F0FD',
    },
    {
      id: 6,
      name: t('APP.HELP.FAQ.PH_7BF8FD', { defaultValue: 'Ecoconception' }),
      color: '#D1F7C4',
    },
    {
      id: 7,
      name: t('APP.HELP.FAQ.PH_CC58BA', { defaultValue: 'Energie' }),
      color: '#FDF0D0',
    },
    {
      id: 8,
      name: t('APP.HELP.FAQ.PH_AEF1AA', { defaultValue: 'E+C-' }),
      color: '#C2F5E9',
    },
    {
      id: 9,
      name: t('APP.HELP.FAQ.PH_0532B3', { defaultValue: 'Favoris' }),
      color: '#BBE1F7',
    },
    {
      id: 10,
      name: t('APP.HELP.FAQ.PH_0D6924', { defaultValue: 'Fichier de sortie' }),
      color: '#B9C8E3',
    },
    {
      id: 11,
      name: t('APP.HELP.FAQ.PH_44FC18', { defaultValue: "Fichier d'entrée" }),
      color: '#EDE2FE',
    },
    {
      id: 12,
      name: t('APP.HELP.FAQ.PH_CC3778', {
        defaultValue: 'Fluides frigorigènes',
      }),
      color: '#F8CBAD',
    },
    {
      id: 13,
      name: t('APP.HELP.FAQ.PH_F0F2A1', {
        defaultValue: 'Mapping de référence',
      }),
      color: '#FEE2D5',
    },
    {
      id: 14,
      name: t('APP.HELP.FAQ.PH_71D837', { defaultValue: 'Nooco' }),
      color: '#98DED6',
    },
    {
      id: 15,
      name: t('APP.HELP.FAQ.PH_1CCF83', { defaultValue: 'Optimisation' }),
      color: '#CEB9E3',
    },
    {
      id: 16,
      name: t('APP.HELP.FAQ.PH_98C538', { defaultValue: 'Projet' }),
      color: '#F7C4EF',
    },
    {
      id: 17,
      name: t('APP.HELP.FAQ.PH_5BD2AB', { defaultValue: 'RE2020' }),
      color: '#F5DEC2',
    },
  ]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [userQuestion, setUserQuestion] = useState('');
  const [userNotification, setUserNotification] = useState(false);

  const {
    email,
    given_name: firstName,
    family_name,
  } = useSelector((state) => state.projects.user.attributes);
  const dispatch = useDispatch();

  const makeSummary = (questions) => {
    const summary = [];
    const tagsArray = [];

    for (const [key, value] of Object.entries(questions)) {
      let sommaire = value?.Sommaire;
      let tags = value?.TAG;

      if (!sommaire) continue;

      tags = tags?.trim();
      tags = tags?.split(';');

      sommaire = sommaire.trim();

      const split = sommaire.split(';');

      for (let str of split) {
        const foundIndex = summary.findIndex((v) => v === str);

        if (str && foundIndex < 0) {
          summary.push(str);
        }
      }

      if (!tags) continue;

      for (let tag of tags) {
        const foundIndex = tagsArray.findIndex((v) => v.label === tag);

        if (tag && foundIndex < 0) {
          const newTag = {
            label: tag,
            color: getColorFromString(tag),
          };

          tagsArray.push(newTag);
        }
      }
    }

    setTags(tagsArray);
    summary.sort();
    setSummary(summary);
  };

  const makeActiveSummary = (e) => {
    if (e === activeSummary) {
      setActiveSummary(null);
    } else {
      setActiveSummary(e);
    }
  };

  useEffect(() => {
    //create questions

    makeSummary(basequestions);

    setQuestions(basequestions);
    setFilteredQuestions(basequestions);
  }, []);

  useEffect(() => {
    filterQuestions();
  }, [activeSummary, searchBar, tagsSelected]);

  const filterQuestions = () => {
    let questionsToFilter = questions;

    questionsToFilter = filterQuestionsBySummary(questionsToFilter);
    questionsToFilter = filterQuestionsBySearchBar(questionsToFilter);
    questionsToFilter = filterQuestionsByTag(questionsToFilter);

    setFilteredQuestions(questionsToFilter);
  };

  const filterQuestionsBySummary = (questionsToFilter) => {
    if (activeSummary) {
      const filteredQuestionsBySummary = questionsToFilter.filter((e) =>
        e.Sommaire?.includes(activeSummary)
      );

      return filteredQuestionsBySummary;

      // setFilteredQuestions(filteredQuestionsBySummary);
    }

    return questionsToFilter;
  };

  const filterQuestionsBySearchBar = (questionsToFilter) => {
    const normalizedText = searchBar.toLocaleLowerCase('fr-FR');

    const filtered = questionsToFilter.filter((q) => {
      let tags = [];

      if (q.TAG) tags = Array.isArray(q.TAG) ? q.TAG : [q.TAG];

      const normalizedTags = tags.map((tag) => tag.toLocaleLowerCase('fr-FR'));

      return (
        q.Réponses?.toLocaleLowerCase('fr-FR').includes(normalizedText) ||
        q.Questions?.toLocaleLowerCase('fr-FR').includes(normalizedText) ||
        normalizedTags.includes(normalizedText)
      );
    });

    // setFilteredQuestions(filtered);
    return filtered;
  };

  const filterQuestionsByTag = (questionsToFilter) => {
    if (tagsSelected.length === 0) {
      return questionsToFilter;
    }

    // tagsSelectedArray is an array with the names of the selected tags
    const tagsSelectedArray = tagsSelected.map((tag) => tag.name);

    const filteredQuestionsByTag = questionsToFilter.filter((question) => {
      // tagsQuestion is a string og tags separated by semicolons
      const tagsQuestion = question.TAG;

      // count the number of tag include in the question
      let includeQuestion = 0;

      tagsSelectedArray.forEach((tag) => {
        let questionToDisplay;

        if (tagsQuestion) {
          questionToDisplay = tagsQuestion.includes(tag);
        }

        if (questionToDisplay) {
          // le tag est inclus dans la question
          includeQuestion += 1;
        }
      });

      // includeQuestion is the number of tag include in the question who's match with tagsSelectedArray
      if (includeQuestion === tagsSelectedArray.length) {
        return question;
      }
    });

    return filteredQuestionsByTag;
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeTags = (event, newValue) => {
    setTagsSelected([]);
    setTagsSelected([...newValue]);
  };

  const renderQuestions = () => {
    if (!filteredQuestions) return null;

    const filteredUniqueQuestions = filteredQuestions.reduce((acc, current) => {
      const alreadyExists = acc.some((item) => item.Questions === current.Questions);
      if (!alreadyExists) {
        acc.push(current);
      }
      return acc;
    }, []);

    const list = filteredUniqueQuestions.map((question) => {
      if (!question.Questions) return null;

      const newText = question?.Réponses?.split('\n').map((str, index) => (
        <p key={`${question.ID}-text-${index}`} className={'accordion-text'}>
          {str}
        </p>
      ));

      return (
        <Accordion
          key={question.ID}
          style={{ boxShadow: 'none', borderBottom: '1px solid #E2E4EB' }}
          className={'accordion'}
          expanded={question.ID === expanded}
          onChange={handleChange(question.ID)}
        >
          <AccordionSummary
            className={'accordion-title'}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="">
              {question.Questions}
              <div className="">
                {tagsList.map((tag) => {
                  const tagsString = question.TAG;

                  if (question.TAG && tagsString.includes(tag.name)) {
                    return (
                      <Chip
                        key={tag.name}
                        className=""
                        style={{
                          background: tag.color,
                          fontWeight: '600',
                          fontSize: '11px',
                          color: 'white',
                          borderRadius: '0.25rem',
                          border: 'none',
                          height: '27px',
                          margin: '0.3em',
                        }}
                        label={tag.name}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={'accordion-text-container'}>{newText}</div>
          </AccordionDetails>
        </Accordion>
      );
    });

    return list;
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setUserNotification(false);
  };

  const handleSubmitUserQuestion = () => {
    dispatch(
      sendMessageTeamsFaq({
        mail: email,
        firstName: firstName,
        lastName: family_name,
        question: userQuestion,
        date: new Date(),
      })
    );
    setOpen(false);
    setUserQuestion('');
    setUserNotification(true);
  };

  const content = (
    <>
      <div style={{ width: '250px' }}>
        <TextArea
          rows={2}
          style={{ marginBottom: '1rem' }}
          bordered={false}
          placeholder={t('APP.HELP.FAQ.PH_63959F', {
            defaultValue: 'Votre question',
          })}
          value={userQuestion}
          onChange={(e) => setUserQuestion(e.target.value)}
        />

        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            shape="round"
            style={{ backgroundColor: '#6C33F1', color: 'white' }}
            onClick={() => {
              handleSubmitUserQuestion();
            }}
          >
            Envoyer
          </Button>
        </div>
      </div>
    </>
  );

  const title = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>
        {t('APP.HELP.FAQ.PH_EE7160', {
          defaultValue: 'Posez votre question à Nooco.',
        })}
      </div>
      <div>
        <IconButton>
          <CloseIcon onClick={() => setOpen(false)} />
        </IconButton>
      </div>
    </div>
  );

  if (!tags) return null;

  return (
    <div className="faq-container">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={userNotification}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {t('APP.HELP.FAQ.PH_9F80DF', {
            defaultValue: 'La demande a bien été envoyée',
          })}
        </Alert>
      </Snackbar>
      {/* summary display */}
      <div className="summary">
        <div className="summary__title">
          {t('APP.HELP.FAQ.PH_942405', { defaultValue: 'Sommaire' })}
        </div>
        {summary.map((e, i) => (
          <div
            className={
              activeSummary === e ? 'summary__content active' : 'summary__content'
            }
            key={i}
            onClick={() => {
              makeActiveSummary(e);
            }}
          >
            {e}
          </div>
        ))}
      </div>

      {/* Right part with searchBar, Tag & questions to display */}
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <div className="search-global">
          {/* search Bar*/}
          <div className={'search-container'}>
            <TextField
              // style={{width: '50%'}}v
              id="outlined-basic"
              label="Rechercher"
              variant="outlined"
              size="small"
              value={searchBar}
              onChange={(e) => {
                setSearchBar(e.target.value);
                filterQuestions();
              }}
            />
          </div>
          {/* search TAG */}
          <div className="search-tag">
            <Autocomplete
              id="checkboxes-tags-demo"
              limitTags={1}
              value={tagsSelected}
              multiple
              disableCloseOnSelect
              options={tagsList}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <div>
                  <Checkbox />
                  {option.name}
                </div>
              )}
              onChange={(event, newValue) => handleChangeTags(event, newValue)}
              size={'small'}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ width: '270px' }}
                  variant="outlined"
                  placeholder="Tags"
                  className="rights-search "
                />
              )}
            />
          </div>
          <Popover
            content={content}
            title={title}
            visible={open}
            placement="bottom"
            trigger="click"
            onVisibleChange={() => setOpen(!open)}
          >
            <div className="search-askNooco">
              <Tooltip
                title="Ma question n'est pas présente dans la FAQ, cliquer ici pour l'envoyer à Nooco."
                placement="top"
                arrow
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
          </Popover>
        </div>
        <div className="tags-selected">
          {tagsSelected.map((option) => (
            <>
              <Chip
                style={{
                  background: option.color,
                  fontWeight: '600',
                  fontSize: '11px',
                  color: 'white',
                  borderRadius: '0.25rem',
                  border: 'none',
                  height: '27px',
                  margin: '0.3em',
                }}
                key={option.name}
                variant="outlined"
                label={option.name}
                onDelete={(data) => {
                  handleChangeTags(data, [
                    ...tagsSelected.filter((el) => el.name !== option.name),
                  ]);
                }}
              />
            </>
          ))}
        </div>

        {/* Questions Display */}
        <div>{renderQuestions()}</div>
      </div>
    </div>
  );
};

export default Faq;

// -------------   archive old code   ------------------

// import { Autocomplete } from '@material-ui/lab';
// import { Typography } from '@material-ui/core';

// -----------------------------------------------------

{
  /* <Autocomplete
            multiple
            id="tags-outlined"
            options={tags}
            getOptionLabel={(option) => option.label}
            onChange={(e, newValue) => {
               console.log(e, newValue);
              filterQuestionsByTag(newValue);
            
            }}
            style={{width: '40%'}}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Tag"
              />
            )}
          /> */
} // -------------------------------------------------------
// const renderSummary = () => {
//   const sum = summary?.map((sum) => {
//     return (
//       <div style={{boxShadow: 'none',  borderBottom: '1px solid #E2E4EB' }}>
//       </div>
//     );
//   });
//   return (
//     <div>
//       <p>Sommaire</p>
//       {sum}
//     </div>
//   );
// };
// --------------------------------------------------------
// const filterQuestionsByTagex = (tags) => {
//   console.log(tags);
//   if (tags.length === 0) {
//     return setFilteredQuestions(basequestions);
//   }
//   const filtered = questions.filter((q) => {
//     for (let tag of tags) {
//       if (q.TAG?.includes(tag.label)) {
//         return true;
//       }
//     }
