import { connect } from 'react-redux';

import { updateFormValue, fetchProjectById } from '../../actions/projects';

import SwitchEquipment from '../../app/projects/LifeCycle/SwitchEquipment';

const mapStateToProps = (state) => ({
  currentProject: state.projects.currentProject,
  sheets: state.projects.sheets,
  switchSelectedEquipment: state.projects.switchSelectedEquipment,
  replaceEquipment: state.projects.replaceEquipment,
  currentIndicator: state.projects.currentIndicator,
  allGroups: state.projects.allGroups,
  zones: state.projects.parsedProject.zones,
  equipmentsAvailable: state.projects.equipmentsAvailable,
  sheetsAvailable: state.projects.sheetsAvailable,
  selectedEquipments: state.projects.selectedEquipments,
  displayedEquipments: state.projects.displayedEquipments,
  searchValue: state.projects.searchValue,
});

const mapDispatchToProps = (dispatch) => ({
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  fetchProjectById: (id, indicatorId) => {
    dispatch(fetchProjectById(id, indicatorId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SwitchEquipment);
