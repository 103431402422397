import { connect } from 'react-redux';

import Variant from '../../app/projects/Variant';

import { changeValueVariant } from '../../actions/variants';
import { fetchProjects, updateFormValue } from '../../actions/projects';

const mapStateToProps = (state) => ({
  typeGraph: state.variants.typeGraph,
  variants: state.variants.variants,
  currentProject: state.projects.currentProject,
  projects: state.projects.projects,
  addedVariant: state.variants.addedVariant,
  currentFilter: state.variants.currentFilter,
  currentIndicator: state.projects.currentIndicator,
  variantsLoaded: state.variants.variantsLoaded,
  selectedLotFilter: state.variants.selectedLotFilter,
  filterOptions: state.variants.filterOptions,
  limitDatas: state.variants.limitDatas,
  currentAllotment: state.projects.currentAllotment,
  dataGraph: state.variants.dataGraph,
});

const mapDispatchToProps = (dispatch) => ({
  changeValueVariant: (name, value) => {
    dispatch(changeValueVariant(name, value));
  },
  fetchProjects: (isSearching, companyId) => {
    dispatch(fetchProjects(isSearching, companyId));
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Variant);
