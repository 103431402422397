import { TYPE_FICHE, LOTS_IDS } from '../../../globalVariable/generalDefinitions';
import { Datum } from '@nivo/line';
import { ALLOTMENT_IDS } from '../../../globalVariable/typeProject.js';
import { getAllotmentNodeLotId } from '../../../shared/utilsFunction';
import { AllotmentNodeDTO } from '../../../models/ProjectDTO';

const Coeff = require('../CoefAcv.js');

type AcvValue = {
  A1: number,
  A2: number,
  A3: number,
  A4: number,
  A5: number,
  AProd: number,
  AEdif: number,
  B1: number,
  B2: number,
  B3: number,
  B4: number,
  B5: number,
  B6: number,
  B7: number,
  B: number,
  BMaint: number,
  BOp: number,
  C1: number,
  C2: number,
  C3: number,
  C4: number,
  C: number,
  D: number,
  TOTAL: number
};

function getB1To4(v: AcvValue, isForfait: boolean = false) {
  if (isForfait) return v.B - v.B5 - v.B6 - v.B7;

  return v.B1 + v.B2 + v.B3 + v.B4;
};

function getAProd(v: AcvValue) {
  return v.AProd || v.A1 + v.A2 + v.A3;
};

function getAEdif(v: AcvValue) {
  return v.AEdif || v.A4 + v.A5;
};

function getB(v: AcvValue) {
  return v.B1 + v.B2 + v.B3 + v.B4 + v.B5 + v.B6 + v.B7 || v.B || v.BMaint + v.BOp;
};

function getC(v: AcvValue) {
  let c = v.C || v.C1 + v.C2 + v.C3 + v.C4;

  return c + v.D;
};

function getTOTAL(v: AcvValue) {
  return getAProd(v) + getAEdif(v) + getB(v) + getC(v) || v.TOTAL;
};

function getDateIndex(inputDate: Date, dates: Date[]) {
  const inputDateTime = inputDate.getTime();

  if (inputDateTime <= dates[0].getTime()) {
    return 0;
  }

  if (inputDateTime >= dates[dates.length - 1].getTime()) {
    return dates.length - 1;
  }

  for (let i = 1; i < dates.length; i++) {
    if (inputDateTime <= dates[i].getTime()) {
      return i;
    }
  }

  return dates.length - 1;
}

export function addEquipmentToLca(
  ps: any,
  dates: Date[],
  lcaTot: Datum[],
  indexId: number,
) {
  const psStartDate = ps.ProjectSheet.lifespan[0].value;
  const psEndDate = ps.ProjectSheet.lifespan[1].value;

  const startDateIndex = getDateIndex(new Date(psStartDate), dates);
  let endDateIndex = -1;
  if (psEndDate !== null) {
    endDateIndex = getDateIndex(new Date(psEndDate), dates);
  }

  const maxIndex = endDateIndex === -1 ? dates.length - 1 : endDateIndex;
  for (let index = startDateIndex; index <= maxIndex; index++) {
    (lcaTot[index].y as number) += ps.ACV[`${indexId === 26 ? 1 : indexId}`].static.total;
  }
}

export function getYearIc(ps: any, lca: Datum, ddv: number, buildingSitesIds: number[], indexId: number, projectArea: number, lotId: number) {
  const year = Number(lca.x);
  let values = ps.Indices.find((index) => index.id === indexId);

  if (values !== undefined) {
    values = values.SheetIndex;
  }

  // Filtering buildingSite equipments outside of year 0
  if (buildingSitesIds.includes(ps.ProjectSheet.ZoneId)) {
    if (year === 0) {
      const result = values
        ? getTOTAL(values) - (ps.ddv ? ((ps.ddv - 1) / ps.ddv) * getB1To4(values) : 0)
        : (ps.acvResults.base.edifChantier / ps.ProjectSheet.quantity) * projectArea;

        return result;
    } else {
      return 0;
    }
  }

  if (!values) return 0;

  if ([LOTS_IDS.EAU, LOTS_IDS.ENERGIE, LOTS_IDS.ENERGIE_SIMPLIFIEE].includes(lotId) && year !== 0)
    return getTOTAL(values);

  const isForfait = ps.type_fiche === TYPE_FICHE.FORFAIT;

  if (year === 0) {
    return getAProd(values) + getAEdif(values);
  } else if (year === ddv) {
    return getB1To4(values, isForfait) / ps.ddv + getC(values);
  } else {
    if (year % ps.ddv === 0) {
      if (ddv - year < ps.ddv)
        return (
          getB1To4(values, isForfait) / ps.ddv +
          (ddv / ps.ddv - Math.floor(ddv / ps.ddv)) *
          (getAEdif(values) + getAProd(values) + getC(values))
        );

      return (
        getB1To4(values, isForfait) / ps.ddv +
        getAEdif(values) +
        getAProd(values) +
        getC(values)
      );
    }

    return getB1To4(values, isForfait) / ps.ddv;
  }
};

export function getFluidYearIc(fluidAcvValue: any, lca: Datum, ddv: number, buildingSitesIds: number[]) {
  // Fluid impact is annually deducted from the Sheet LCA at the project lifespan scope
  // It's relying on the backend computation of the fluid impact
  const year = Number(lca.x);

  const usage = fluidAcvValue.b1 / ddv;
  const renew = fluidAcvValue.b4 / Math.floor(ddv / fluidAcvValue.ddv);
  const end = fluidAcvValue.end;

  if (buildingSitesIds.includes(fluidAcvValue.ZoneId)) {
    if (year === 0) {
      return fluidAcvValue.b1 + fluidAcvValue.b4 + fluidAcvValue.end;
    }
    return 0;
  }

  switch (year) {
    case 0:
      return 0;
    case ddv:
      return usage + end;
    default:
      if (year % fluidAcvValue.ddv === 0) {
        return usage + renew;
      }

      return usage;
  }
};

export function getPeriodicLCAbyNode(
  projectSheets: any[],
  indexId: number,
  projectArea: number,
  refUnit: string,
  dates: Date[],
) {
  let lcaTot: Datum[] = [];

  for (let i = 0; i < dates.length; i++) {
    const dateString = `${dates[i].getDate()}-${dates[i].getMonth() + 1}-${dates[i].getFullYear()}`;
    lcaTot.push({ x: dateString, y: 0 });
  }

  for (let ps of projectSheets) {
    addEquipmentToLca(
      ps,
      dates,
      lcaTot,
      indexId
    );
  }

  const areaFactor = refUnit === 'total' ? 1 : projectArea;
  if (areaFactor !== 1) {
    for (let lca of lcaTot) {
      lca.y = (lca.y as number) / areaFactor;
    }
  }

  return lcaTot;
}

export function getLCAbyNode(
  projectSheets: any[],
  indexId: number,
  ddv: number,
  buildingSitesIds: number[],
  projectArea: number,
  refUnit: string,
  allotmentNodesMap: Map<number, AllotmentNodeDTO>
) {
  let lcaTot: Datum[] = [];

  for (let i = 0; i <= ddv; i++) {
    lcaTot.push({ x: `${i}`, y: 0 });
  }

  for (let ps of projectSheets) {
    let lcaSheet = 0;
    let fluidValues: any;

    if (ps.ProjectSheet.MasterDataEquipment.hasFluid) {
      const fluidACV =
        ps.ACV[`${indexId === 26 ? 1 : indexId}`][
        indexId === 1 ? 'dynamic' : 'static'
        ];
      fluidValues = {
        end: fluidACV.base.endfluide ? fluidACV.base.endfluide : 0,
        b1: fluidACV.usage.b1fluide ? fluidACV.usage.b1fluide : 0,
        b4: fluidACV.usage.b4fluide ? fluidACV.usage.b4fluide : 0,
        ddv: ps.ddv,
        ZoneId: ps.ProjectSheet.ZoneId,
      };
    }

    const allotmentNodeId = ps.ProjectSheet.AllotmentNodes.find(
      (allotmentNode: AllotmentNodeDTO) => allotmentNode.AllotmentId === ALLOTMENT_IDS.NOOCO
    )?.id;
    const lotId = getAllotmentNodeLotId(allotmentNodesMap, allotmentNodeId);

    if (lotId !== null) {
      for (let lca of lcaTot) {
        const yearCoeff = indexId === 1 ? Coeff[lca.x as string] : 1;
        const icValue = getYearIc(
          ps,
          lca,
          ddv,
          buildingSitesIds,
          indexId === 26 ? 1 : indexId,
          projectArea,
          lotId
        );
        const equipContribForThisYear = icValue * Number(ps.ProjectSheet.quantity) * yearCoeff;

        lcaSheet += equipContribForThisYear

        if (fluidValues) {
          const fluidContribForThisYear = getFluidYearIc(
            fluidValues,
            lca,
            ddv,
            buildingSitesIds
          );

          lcaSheet += fluidContribForThisYear;
        }

        (lca.y as number) += lcaSheet / (refUnit === 'total' ? 1 : projectArea);
      }
    }
  }

  return lcaTot;
};
