/* eslint-disable no-undef */
import { ALERT_EXPORT_ALLOTMENT_NODES } from './delivrablesWidgetsConfig';
import './deliverablesWidget.scss';
import { ProjectDTO } from '../../../../../models/ProjectDTO';
import { SheetDTO, checkRseeDataForExport } from '../exportRsee/RseeDataValidation';
import { StateDTO } from '../../../../models/GeneralDTO';
import { TYPE_EPCM, TYPE_RE2020 } from '../../../../globalVariable/typeProject';
import { useSelector } from 'react-redux';
import { zonesDTO } from '../WidgetsDTO';
import Link from '@material-ui/core/Link';
import Loader from '@material-ui/core/CircularProgress';
import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import { ProjectVerificationLevel } from '@photocarbone/nooco-shared-api-def';
import VerificationModal from './VerificationModal';
import { FullScreenModal } from '@photocarbone/nooco-ui';
import PdfBuilder from '../../../PdfBuilder';
import notesAdd from '../../../../../assets/images/pdfbuilder/note-add.png';

type DeliverablesWidgetsProps = {
  rseeText: string;
  seuilsOk: boolean;
  downloadPdf: boolean;
  setDownloadPdf: Function;
  generatePDF: Function;
  zones: zonesDTO[];
  downloadRsee: boolean;
  generateRsee: Function;
  currentProject: ProjectDTO;
  setShow: Function;
  isExportEpcmDateValid: boolean;
  getRseePdf: Function;
};

type rseeExportClicableIconProps = {
  downloadRseeXml: boolean;
  generateRsee: Function;
  licenceTypeAccess: string;
  seuilsOk: boolean;
  zones: zonesDTO[];
  isExportEpcmDateValid: boolean;
  rseeText: string;
  currentProject: ProjectDTO;
  setShow: Function;
  setIsVerificationModalOpen: Function;
  getAllotmentNodesWithUnitWarning: Object[];
  isProjectEpcm: boolean;
  setIsXml: Function;
};

const pdf = require('../../../../../assets/images/synthese/downloadPdf.png');
const xml = require('../../../../../assets/images/synthese/downloadXml.png');

const centerText = (rseeText: string, isProjectTypeRsenvCompatible: boolean) => {
  const { t } = useTranslation();

  return (
    <div className="report-rsee-text">
      <h3 className="dashboard-title">
        {t('APP.WIDGETS.DELIVERABLESWIDGETS.PH_0A4EDD', {
          defaultValue: 'Livrables',
        })}
      </h3>
      <p>
        {t('APP.WIDGETS.DELIVERABLESWIDGETS.PH_616623', {
          compatible: isProjectTypeRsenvCompatible,
          rseeText,
          defaultValue:
            'Générer la synthèse de mon projet {compatible, select, true {{rseeText}} other {} }',
        })}
      </p>
    </div>
  );
};

const loaderObject = () => {
  return (
    <Loader
      variant="indeterminate"
      disableShrink
      style={{ display: 'block', color: '#73b7ec', margin: 'auto' }}
      size={50}
      thickness={4}
    />
  );
};

const iconAndTextObject = (text: string, isUsable: boolean, icon) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <p
        style={{
          margin: '0',
          color: isUsable ? undefined : '#ccc',
        }}
      >
        {text}
      </p>
      <img
        src={icon}
        style={{
          filter: isUsable ? 'none' : 'grayscale(100%)',
          cursor: isUsable ? 'pointer' : 'initial',
        }}
      />
    </div>
  );
};

const pdfExportClicableIcon = (
  downloadPdf: boolean,
  setDownloadPdf: Function,
  generatePDF: Function,
  setOpenModal: Function
) => {
  const { t, i18n } = useTranslation();
  const { isSuperAdmin } = useSelector((state: StateDTO) => state.projects);

  const isEnglishLanguage = i18n.language === 'en-GB';
  const tooltipText = isEnglishLanguage
    ? t('APP.RIGHTS.RIGHTS.PH_65A3E8', {
        defaultValue: 'Bientôt disponible',
      })
    : t('APP.WIDGETS.DELIVERABLESWIDGETS.PH_699635', {
        defaultValue: 'Télécharger une synthèse du projet au format pdf',
      });

  return (
    <div
      className="icons-synthese"
      style={{
        cursor: isEnglishLanguage ? 'not-allowed' : '',
        height: downloadPdf ? '50px' : undefined,
        marginBottom: '4px',
        width: '50%',
      }}
    >
      {downloadPdf && loaderObject()}
      {!downloadPdf && (
        <div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Tooltip title={tooltipText}>
            <div
              onClick={
                !downloadPdf && !isEnglishLanguage
                  ? () => {
                      setDownloadPdf(true);
                      generatePDF();
                    }
                  : undefined
              }
            >
              {iconAndTextObject(
                t('APP.WIDGETS.DELIVERABLESWIDGETS.SYNTHESIS', {
                  defaultValue: 'Synthèse',
                }),
                true,
                pdf
              )}
            </div>
          </Tooltip>
          {isSuperAdmin && (
            <div onClick={() => setOpenModal(true)}>
              {iconAndTextObject('PDF Builder', true, pdf)}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const getAccessRightForButton = (
  licenceType: string,
  sheets: SheetDTO[],
  verificationData: ProjectVerificationLevel,
  rseeText: string,
  zones?: zonesDTO[],
  validThresholds?: boolean,
  isExportEpcmDateValid?: boolean,
  projectType?: string
): [boolean, string] => {
  let tooltipText = '';
  let isButtonUsable = true;
  const { t } = useTranslation();

  if (licenceType !== 'pro') {
    tooltipText = t('APP.WIDGETS.DELIVERABLESWIDGETS.PH_0DC7FE', {
      defaultValue: 'Fonctionnalité disponible avec une licence pro seulement',
    });
    isButtonUsable = false;
  } else {
    [isButtonUsable, tooltipText] = checkRseeDataForExport(
      sheets,
      verificationData,
      rseeText,
      zones,
      validThresholds,
      isExportEpcmDateValid,
      projectType
    );
  }

  return [isButtonUsable, tooltipText];
};

const rseeExportPdfClicableIcon = (
  loaderPdf,
  licenceTypeAccess,
  seuilsOk,
  zones,
  isExportEpcmDateValid,
  rseeText,
  currentProject,
  getRseePdf,
  setIsVerificationModalOpen,
  getAllotmentNodesWithUnitWarning,
  isProjectEpcm,
  setIsXml
) => {
  const { t } = useTranslation();

  const [isRseeExportable, tooltipText] = getAccessRightForButton(
    licenceTypeAccess,
    currentProject.Sheets,
    currentProject.VerificationData,
    rseeText,
    zones,
    seuilsOk,
    isExportEpcmDateValid,
    currentProject.templateType
  );

  if (isProjectEpcm) {
    return (
      <Link
        className="icons-synthese2"
        style={{
          cursor: isRseeExportable ? 'pointer' : 'initial',
          height: loaderPdf ? '50px' : undefined,
        }}
        onClick={() => {
          setIsXml(false);
          if (!isRseeExportable) return;
          if (getAllotmentNodesWithUnitWarning.length > 0) {
            setIsVerificationModalOpen(true);
          } else {
            window.location.href =
              'http://observatoire.batiment-energiecarbone.fr/authentification/connexion/';
          }
        }}
      >
        {loaderPdf && loaderObject()}
        {!loaderPdf && (
          <Tooltip
            title={t('APP.WIDGETS.DELIVERABLESWIDGETS.PH_20C0E8', {
              defaultValue:
                'Lien vers la plateforme qui vous permet de télécharger votre RSEE au format PDF',
            })}
          >
            {iconAndTextObject('', currentProject.isRset, pdf)}
          </Tooltip>
        )}
      </Link>
    );
  } else {
    return (
      <div
        onClick={
          !loaderPdf
            ? () => {
                setIsXml(false);
                if (getAllotmentNodesWithUnitWarning.length > 0) {
                  setIsVerificationModalOpen(true);
                } else {
                  if (isRseeExportable && currentProject.isRset) {
                    if (!isProjectEpcm) {
                      getRseePdf(currentProject.id);
                    }
                  }
                }
              }
            : undefined
        }
        className="icons-synthese2"
        style={{
          cursor: isRseeExportable ? 'pointer' : 'initial',
          height: loaderPdf ? '50px' : undefined,
        }}
      >
        {loaderPdf && loaderObject()}
        {!loaderPdf && (
          <Tooltip title={tooltipText}>
            {iconAndTextObject('', currentProject.isRset && isRseeExportable, pdf)}
          </Tooltip>
        )}
      </div>
    );
  }
};

const RseeExportClicableIcon = ({
  downloadRseeXml,
  generateRsee,
  licenceTypeAccess,
  seuilsOk,
  zones,
  isExportEpcmDateValid,
  rseeText,
  currentProject,
  setShow,
  setIsVerificationModalOpen,
  getAllotmentNodesWithUnitWarning,
  isProjectEpcm,
  setIsXml,
}: rseeExportClicableIconProps): JSX.Element => {
  const { i18n } = useTranslation();
  const isEnglishLanguage = i18n.language === 'en-GB';

  const [isRseeExportable, tooltipText] = getAccessRightForButton(
    licenceTypeAccess,
    currentProject.Sheets,
    currentProject.VerificationData,
    rseeText,
    zones,
    seuilsOk,
    isExportEpcmDateValid,
    currentProject.templateType
  );

  return (
    <div
      onClick={
        !downloadRseeXml
          ? () => {
              setIsXml(true);
              isRseeExportable && !isEnglishLanguage
                ? isProjectEpcm
                  ? getAllotmentNodesWithUnitWarning.length > 0
                    ? setIsVerificationModalOpen(true)
                    : setShow(true)
                  : getAllotmentNodesWithUnitWarning.length > 0
                  ? setIsVerificationModalOpen(true)
                  : generateRsee(currentProject.id, currentProject.name)
                : undefined;
            }
          : undefined
      }
      className="icons-synthese"
      style={{
        cursor: isEnglishLanguage
          ? 'not-allowed'
          : isRseeExportable
          ? 'pointer'
          : 'initial',
        height: downloadRseeXml ? '50px' : undefined,
      }}
    >
      {downloadRseeXml && loaderObject()}
      {!downloadRseeXml && (
        <Tooltip title={isEnglishLanguage ? 'Soon Available' : tooltipText}>
          {iconAndTextObject('', isRseeExportable, xml)}
        </Tooltip>
      )}
    </div>
  );
};

const DeliverablesWidgets = ({
  rseeText,
  seuilsOk,
  downloadPdf,
  setDownloadPdf,
  generatePDF,
  zones,
  downloadRsee,
  generateRsee,
  currentProject,
  setShow,
  isExportEpcmDateValid,
  getRseePdf,
}: DeliverablesWidgetsProps): JSX.Element => {
  const isProjectEpcm = currentProject.templateType === TYPE_EPCM;

  const { licenceTypeAccess } = useSelector((state: StateDTO) => state.projects);
  const { loaderPdf } = useSelector((state: StateDTO) => state.projects);
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const isProjectTypeRsenvCompatible =
    currentProject.templateType === TYPE_RE2020 ||
    currentProject.templateType === TYPE_EPCM;
  const [isXml, setIsXml] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const getAllotmentNodesWithUnitWarning = () => {
    let allotmentNodesList = [];

    currentProject?.Sheets.forEach((sheet) => {
      sheet?.AllotmentNodes.forEach((allotmentsNode) => {
        if (ALERT_EXPORT_ALLOTMENT_NODES.includes(allotmentsNode.id)) {
          const exists = allotmentNodesList.find(
            (allotmentNode) => allotmentNode.id === allotmentsNode.id
          );
          if (!exists) allotmentNodesList.push(allotmentsNode);
        }
      });
    });

    return allotmentNodesList;
  };

  return (
    <div className="report-rsee-download container-resizable-ok">
      <FullScreenModal
        title={
          <div style={{ display: 'flex', alignItems: 'center', padding: '0.1em' }}>
            <div className="icon-header-pdf-builder-modal">
              <img style={{ width: '22px' }} src={notesAdd} />
            </div>
            <p className="title-section">Téléchargement du PDF de la synthèse</p>
          </div>
        }
        bodyContent={<PdfBuilder />}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <VerificationModal
        allotmentNodes={getAllotmentNodesWithUnitWarning()}
        isOpen={isVerificationModalOpen}
        setIsOpen={setIsVerificationModalOpen}
        getRseePdf={getRseePdf}
        id={currentProject.id}
        name={currentProject.name}
        isProjectEpcm={isProjectEpcm}
        isXml={isXml}
        setShow={setShow}
        generateRsee={generateRsee}
      />
      {centerText(rseeText, isProjectTypeRsenvCompatible)}
      <div
        style={{
          display: 'flex',
          height: '60%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {pdfExportClicableIcon(
          downloadPdf,
          setDownloadPdf,
          generatePDF,
          setOpenModal
        )}
        {isProjectTypeRsenvCompatible && (
          <div
            style={{
              width: '50%',
              display: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p style={{ textAlign: 'center', marginBottom: '0px' }}>{rseeText}</p>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              {rseeExportPdfClicableIcon(
                loaderPdf,
                licenceTypeAccess,
                seuilsOk,
                zones,
                isExportEpcmDateValid,
                rseeText,
                currentProject,
                getRseePdf,
                setIsVerificationModalOpen,
                getAllotmentNodesWithUnitWarning(),
                isProjectEpcm,
                setIsXml
              )}
              {RseeExportClicableIcon({
                downloadRseeXml: downloadRsee,
                generateRsee,
                licenceTypeAccess,
                seuilsOk,
                zones,
                isExportEpcmDateValid,
                rseeText,
                currentProject,
                setShow,
                setIsVerificationModalOpen,
                getAllotmentNodesWithUnitWarning: getAllotmentNodesWithUnitWarning(),
                isProjectEpcm,
                setIsXml,
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeliverablesWidgets;
