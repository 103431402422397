import { combineReducers } from 'redux';

// import reducers
import bundle from './bundle';
import equipments from './equipments';
import exploitation from './exploitation';
import favorites from './favorites';
import help from './help';
import imports from './import';
import monitoring from './monitoring';
import products from './products';
import projects from './projects';
import report from './report';
import rights from './rights';
import simulator from './simulator';
import variants from './variants';

// combine reducers
const appReducer = combineReducers({
  equipments,
  imports,
  monitoring,
  projects,
  report,
  rights,
  variants,
  products,
  exploitation,
  help,
  simulator,
  favorites,
  bundle,
});

const rootReducer = (state, action) => {
  // console.log('user logout reducer');
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
