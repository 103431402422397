import { getColorFromString } from '../services/colors/getColor';

export const makeDataGraph = (
  needDatas,
  currentProject,
  currentAllotment,
  currentFilter
) => {
  // console.log('currentProject', currentProject);
  // console.log("currentAllotment",
  //   currentAllotment);
  // console.log("currentFilter",
  //   currentFilter);

  // console.log('ici');

  let dataGraph = [];
  let keys = [];
  let filters = [];

  // console.log('needDatas',
  //   needDatas);
  // console.log('dataGraph',
  //   dataGraph);

  needDatas.forEach((needData) => {
    keys = [...keys, needData.name];

    needData.children.forEach((children) => {
      let parentName = null;
      let parent = children;

      if (children.label === 'Parcelle' || children.label === 'Chantier') {
        parentName = `${children.label} - `;
      }

      let elemValueByRefUnit =
        currentProject.refUnit === 'total'
          ? children.res
          : children.res / needData.area;

      if (currentFilter === 1) {
        children.children.forEach((smallChildren) => {
          let itemValueByRefUnit =
            currentProject.refUnit === 'total'
              ? smallChildren.res
              : smallChildren.res / needData.area;

          let graphData = dataGraph.findIndex((dataGraphElement) =>
            parentName
              ? parentName + dataGraphElement.label ===
                  parentName + smallChildren.label &&
                dataGraphElement.ParentId === smallChildren.ParentId
              : dataGraphElement.label === smallChildren.label &&
                dataGraphElement.ParentId === smallChildren.ParentId
          );

          if (graphData !== -1) {
            dataGraph[graphData] = {
              ...dataGraph[graphData],
              [needData.name]: itemValueByRefUnit,
              total: dataGraph[graphData].total + elemValueByRefUnit,
            };
          } else {
            filters = [
              ...filters,
              {
                id: smallChildren.id,
                label: smallChildren.label,
                color:
                  currentAllotment.id === 1
                    ? currentProject.Lots.find((lot) => lot.label === parent.label)
                        ?.color
                    : getColorFromString(smallChildren.label),
                name: smallChildren.label,
              },
            ];

            let object = {
              id: smallChildren.id,
              key: smallChildren.id * Math.floor(Math.random() * 100000),
              label: smallChildren.label,
              name: parentName
                ? parentName + smallChildren.label
                : smallChildren.label,
              [needData.name]: itemValueByRefUnit,
              total: itemValueByRefUnit,
              ParentId: smallChildren.ParentId,
            };

            dataGraph = [...dataGraph, object];
          }
        });
      } else {
        let graphData = dataGraph.find(
          (dataGraphElement) => dataGraphElement.label === children.label
        );

        // console.log('graphData',
        //   graphData);
        if (graphData) {
          dataGraph = dataGraph.filter(
            (dataGraphElement) => dataGraphElement.label !== children.label
          );

          graphData = {
            ...graphData,
            [needData.name]: elemValueByRefUnit,
            total: graphData.total + elemValueByRefUnit,
          };

          dataGraph = [...dataGraph, graphData];
        } else {
          // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!',
          //   currentAllotment,
          //   currentProject,
          //   children,
          //   currentProject.Lots.find((lot) => lot.name === children.label)?.color
          // );
          filters = [
            ...filters,
            {
              id: children.id,
              label: children.label,
              color:
                currentAllotment.id === 1
                  ? currentProject.Lots.find((lot) => lot.name === children.label)
                      ?.color
                  : getColorFromString(children.label),
              name: children.label,
            },
          ];

          let object = {
            id: children.id,
            key: children.id * Math.floor(Math.random() * 100000),
            label: children.label,
            name: children.label,
            [needData.name]: elemValueByRefUnit,
            total: elemValueByRefUnit,
          };

          dataGraph = [...dataGraph, object];
        }
      }
    });
  });

  for (let i = 0; i < dataGraph.length; i++) {
    if (currentFilter === 1) {
      const sameLabels = dataGraph.filter((el) => el.label === dataGraph[i].label);

      if (sameLabels.length > 1) {
        const parent = currentAllotment.AllotmentNodes.find(
          (el) => el.id === dataGraph[i].ParentId
        );

        if (parent && !dataGraph[i].name.includes(parent.name)) {
          dataGraph[i] = {
            ...dataGraph[i],
            name: `${parent.name} - ${dataGraph[i].name}`,
          };
        }
      }
    }

    keys.forEach((proj) => {
      if (!dataGraph[i][proj]) {
        dataGraph[i] = { ...dataGraph[i], [proj]: 0 };
      }
    });
  }

  return { dataGraph, keys, filters };
};

export const filterGraphData = (
  selectedLotFilter,
  changeValueVariant,
  dataGraph,
  currentFilter,
  realData
) => {
  // console.log('ici');
  // console.log('selectedLotFilter',
  //   selectedLotFilter);
  // console.log('changeValueVariant',
  //   changeValueVariant);
  // console.log('dataGraph',
  //   dataGraph);
  // console.log('currentFilter',
  //   currentFilter);
  // console.log('realData',
  //   realData);

  let filterData = [];

  if (selectedLotFilter.find((lot) => lot.id === 1200)) {
    changeValueVariant('filteredGraph', null);
  } else {
    dataGraph.map((dataGraphElement) => {
      if (currentFilter === 0) {
        if (selectedLotFilter.find((lot) => lot.name === dataGraphElement.label)) {
          console.log('dataGraphElement', dataGraphElement);
          realData.forEach((realDataElement) => {
            realDataElement.children.forEach((realDataElementChildren) => {
              if (realDataElementChildren.id === dataGraphElement.id) {
                dataGraphElement.color = realDataElement.color;
              }
            });
          });
          filterData = [...filterData, dataGraphElement];
        }
      } else {
        realData.forEach((realDataElement) => {
          if (
            selectedLotFilter.find(
              (lot) =>
                lot.label ===
                realDataElement.children.find(
                  (realDataElementChildren) =>
                    realDataElementChildren.id === dataGraphElement.ParentId
                )?.label
            )
          ) {
            if (
              !filterData.find(
                (filterDataElement) =>
                  filterDataElement.label === dataGraphElement.label &&
                  dataGraphElement.ParentId === filterDataElement.ParentId
              )
            ) {
              filterData = [...filterData, dataGraphElement];
            }
          }
        });
      }
    });

    changeValueVariant('filteredGraph', filterData);
  }
};
