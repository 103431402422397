/* eslint-disable no-undef */

import { IndicatorDTO } from '../../../../models/ProjectDTO';
import { ProjectIcDTO } from './WidgetsDTO';
import { Select } from '@photocarbone/nooco-ui';
import { TestScheduler } from 'jest';
import { set } from 'js-cookie';
import { type } from 'os';
import InfoIcon from '@material-ui/icons/Info';
import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import formatNumber from '../../../services/mathematics/formatNumber';
import formula from '../../../../assets/images/project/formula_benefices_charges.jpg';
import { useTranslation } from 'react-i18next';

const benefits1 = require('../../../../assets/images/synthese/benefits1.png');
const benefits2 = require('../../../../assets/images/synthese/benefits2.png');
const benefits3 = require('../../../../assets/images/synthese/benefits3.png');
const plus = require('../../../../assets/images/synthese/+.png');

type ProjectWidgetsProps = {
  projectIc: ProjectIcDTO;
  currentIndicator: IndicatorDTO;
  buildingsZones: BuildingZone[];
};

export type BuildingZone = {
  name: string;
  id: number;
  Ic: { '1': ProjectIcDTO };
};
export type BuildingsZonesSelect = { name: string; id: number };

export type BenefitsResult = {
  BExp: number;
  BVal: number;
};

const BenefitsWidgets = ({
  projectIc,
  currentIndicator,
  buildingsZones,
}: ProjectWidgetsProps): JSX.Element => {
  const [selectedBuildingZone, setSelectedBuildingZone] =
    useState<BuildingsZonesSelect>({
      id: buildingsZones[0].id,
      name: buildingsZones[0].name,
    });
  const [benefitsResultToDisplay, setBenefitsResultToDisplay] =
    useState<BenefitsResult>({
      BExp: buildingsZones[0].Ic['1'].BExp,
      BVal: buildingsZones[0].Ic['1'].BVal,
    });

  const { t } = useTranslation();

  const selectOptions = buildingsZones.map((buildingZone) => {
    return {
      id: buildingZone.id,
      name: buildingZone.name,
    };
  });

  const handleSelectBuildingZone = (buildingZoneId) => {
    const selectedBuilding = buildingsZones.find(
      (buildingZone) => buildingZone.id === buildingZoneId
    );

    setSelectedBuildingZone({
      id: selectedBuilding?.id || 0,
      name: selectedBuilding?.name || '',
    });
    setBenefitsResultToDisplay({
      BExp: selectedBuilding?.Ic['1']?.BExp || 0,
      BVal: selectedBuilding?.Ic['1']?.BVal || 0,
    });
  };

  return (
    <div className="report-benefits container-resizable-ok">
      <div className="report-benefits-header">
        <div>
          <h3 className="dashboard-title">
            {t('APP.WIDGETS.BENEFITSWIDGETS.PH_BC5FEA', {
              defaultValue: 'Bénéfices et charges',
            })}
          </h3>
          {buildingsZones.length > 1 && (
            <Select
              value={selectedBuildingZone}
              onChange={(buildingZoneId) => handleSelectBuildingZone(buildingZoneId)}
              onSelect={(buildingZoneId) => handleSelectBuildingZone(buildingZoneId)}
              itemsArray={selectOptions}
              width="150px"
              keyValue="name"
              keyItem="id"
              keyDisplay="name"
            />
          )}
        </div>
        <Tooltip
          title={t('APP.WIDGETS.BENEFITSWIDGETS.PH_BE7858', {
            defaultValue:
              'Bénéfices et charges au-delà du cycle de vie du bâtiment: Bénéfice',
          })}
        >
          <img
            style={{
              width: window.screen.availHeight < 950 ? '40px' : undefined,
            }}
            src={benefits3}
            alt=""
          />
        </Tooltip>
      </div>
      <div className="benefits-global">
        {/* Minus carac */}
        {benefitsResultToDisplay.BExp + benefitsResultToDisplay.BVal < 0 && (
          <span style={{ color: '#4CAF50', fontSize: '2em', fontWeight: 'bold' }}>
            -
          </span>
        )}
        {/* Plus carac */}
        {benefitsResultToDisplay.BExp + benefitsResultToDisplay.BVal >= 0 && (
          <img style={{ width: '10px', marginRight: '10px' }} src={plus} alt="" />
        )}
        <span className="valueNumbers">
          {formatNumber(
            Math.abs(benefitsResultToDisplay.BExp + benefitsResultToDisplay.BVal / 3)
          )}
        </span>
        &nbsp;{currentIndicator.unit}/m²
        <Tooltip
          title={
            <>
              {' '}
              <img src={formula} alt="" style={{ maxWidth: '280px' }} />{' '}
            </>
          }
        >
          <InfoIcon />
        </Tooltip>
      </div>
      <div className="benefits-values">
        <p className="benefits-value">
          <div className="benefits-value--logo">
            <Tooltip
              title={t('APP.WIDGETS.BENEFITSWIDGETS.PH_5AF1D5', {
                defaultValue:
                  "Bénéfices et charges liés à l'export d'énergie: Bénéfice export",
              })}
            >
              <img
                style={{
                  // width: window.screen.availHeight < 950 ? '40px' : '50px',
                  height: window.screen.availHeight < 950 ? '40px' : '50px',
                }}
                src={benefits1}
                alt=""
              />
            </Tooltip>
          </div>
          <div className="benefits-value--result">
            {benefitsResultToDisplay.BExp < 0 && (
              <span
                style={{
                  color: '#4CAF50',
                  fontSize: '2em',
                  fontWeight: 'bold',
                }}
              >
                -
              </span>
            )}
            {benefitsResultToDisplay.BExp >= 0 && (
              <img
                style={{ width: '10px', marginRight: '0.5em' }}
                src={plus}
                alt=""
              />
            )}
            <span className="valueNumbers">
              {formatNumber(Math.abs(benefitsResultToDisplay.BExp))}
            </span>
            &nbsp;{currentIndicator.unit}/m²
          </div>
        </p>
        <p className="benefits-value">
          <div className="benefits-value--logo">
            <Tooltip
              title={t('APP.WIDGETS.BENEFITSWIDGETS.PH_E168F2', {
                defaultValue:
                  'Bénéfices et charges liés à la valorisation des produits de construction et équipements: Bénéfice valorisation',
              })}
            >
              <img
                style={{
                  // width: window.screen.availHeight < 950 ? '40px' : '50px',
                  height: window.screen.availHeight < 950 ? '40px' : '50px',
                }}
                src={benefits2}
                alt=""
              />
            </Tooltip>
          </div>
          <div className="benefits-value--result">
            {benefitsResultToDisplay.BVal < 0 && (
              <span
                style={{
                  color: '#4CAF50',
                  fontSize: '2em',
                  fontWeight: 'bold',
                }}
              >
                -
              </span>
            )}
            {benefitsResultToDisplay.BVal >= 0 && (
              <img
                style={{ width: '10px', marginRight: '0.5em' }}
                src={plus}
                alt=""
              />
            )}
            <span className="valueNumbers">
              {formatNumber(Math.abs(benefitsResultToDisplay.BVal))}
            </span>
            &nbsp;{currentIndicator.unit}/m²
          </div>
        </p>
      </div>
    </div>
  );
};

export default BenefitsWidgets;
