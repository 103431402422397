import { createEquipmentCustom } from '../../../apiCallFunctions/equipment/equipment';
import { useMutation } from 'react-query';

export const useCreateEquipmentCustom = () => {
  // const queryClient = useQueryClient();

  return useMutation(
    createEquipmentCustom
    //TODO later when getEquipments in useQuery is done, we can invalidate only the equipments of the current project
    // {
    //   onSuccess: () => {
    //     queryClient.invalidateQueries('equipments');
    //   }
    // }
  );
};
