import './control.scss';
import { Button } from '@photocarbone/nooco-ui';
import { DatePicker } from 'antd';
import { NewActionDTO } from '../../../../models/ExploitationDTO';
import { StateDTO } from '../../../../models/GeneralDTO';
import { useSelector } from 'react-redux';
import AppContext from '../../../../AppContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import React, { useContext } from 'react';
import SelectUi from '@mui/material/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import control from '../../../../../assets/images/exploitation/control.png';
import locale from 'antd/es/date-picker/locale/fr_FR';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getAntdLocale } from '../../../../shared/getAntdLocale';

const dateFormat = 'L';

interface ControlStateProps {
  controlChoice: string;
  updateExploitationValue: Function;
  newAction: NewActionDTO;
  createAction: Function;
  resetAddAction: Function;
  boardingListDatas: {}[];
}

const Control = ({
  controlChoice,
  updateExploitationValue,
  newAction,
  createAction,
  resetAddAction,
  boardingListDatas,
  fluids,
}: ControlStateProps) => {
  const ctx = useContext(AppContext);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const { allGroups, currentProject } = useSelector(
    (state: StateDTO) => state.projects
  );

  const resetStateOptions = () => {
    setOptions([]);
    setLoading(false);
  };

  const fetchOptions = (param, searchValue) => {
    setLoading(true);

    ctx.api.getSearchShortData(currentProject.id, param, searchValue).then((res) => {
      setOptions(res.data);
      setLoading(false);
    });
  };

  const checkValidation = () => {
    if (!controlChoice || !newAction.date) {
      return true;
    }

    if (
      controlChoice === 'fluid' &&
      (!newAction.fluid || !newAction.quantity || !newAction.equipment)
    ) {
      return true;
    }

    if (
      controlChoice === 'filter' &&
      (!newAction.nbFilters || !newAction.equipment)
    ) {
      return true;
    }

    if (controlChoice === 'other' && (!newAction.name || !newAction.equipment)) {
      return true;
    }

    return false;
  };

  return (
    <>
      <div className="control-form">
        <div className="control-header">
          <img src={control} alt="control" />
          <h4>
            {t('APP.CONTROL.INDEX.PH_14DC63', {
              defaultValue: 'Sélectionner le type',
            })}
          </h4>
        </div>
        <div
          className="date-picker-renewal"
          style={{
            width: '80%',
            margin: 'auto',
            marginTop: '0.5em',
            marginBottom: '0.5em',
          }}
        >
          <p style={{ margin: 0, color: 'rgb(102, 102, 102)' }}>
            {t('APP.CONTROL.INDEX.PH_2AB02D', {
              defaultValue: "Date de l'action",
            })}
          </p>
          <DatePicker
            disabledDate={(d) =>
              d.isBefore(currentProject.exploitContract[0].value) ||
              d.isAfter(currentProject.exploitContract[1].value)
            }
            className={`permis-form`}
            format={dayjs.localeData().longDateFormat('L')}
            locale={getAntdLocale()}
            onChange={(event, date) => {
              console.log(event, date);
              // //   if (newValue)
              updateExploitationValue('newAction', {
                ...newAction,
                date: date === '' ? null : dayjs(date, dateFormat).hour(12).format(),
              });
            }}
          />
        </div>
        <div className="control-choices">
          <div className="control-fluid">
            <div
              onClick={() => {
                updateExploitationValue('newAction', {
                  actionType: 'fluid',
                  fluid: null,
                  quantity: null,
                  equipment: null,
                  comment: null,
                  date: newAction.date || null,
                });
                updateExploitationValue('controlChoice', 'fluid');
                resetStateOptions();
              }}
              className={`control-choice ${
                controlChoice === 'fluid' ? 'active-choice' : ''
              }`}
            >
              <p>
                {t('APP.CONTROL.INDEX.PH_4B4B5A', {
                  defaultValue: 'Ajout de fluide frigorigène',
                })}
              </p>
            </div>
            {controlChoice === 'fluid' && (
              <>
                <div className="control-fluid-form">
                  <p>
                    {t('APP.CONTROL.INDEX.PH_A2F5E9', {
                      defaultValue: 'Type de fluide frigorigène',
                    })}
                  </p>
                  <SelectUi
                    onChange={(e) =>
                      updateExploitationValue('newAction', {
                        ...newAction,
                        fluid: e.target.value,
                      })
                    }
                    variant="outlined"
                    className="input-boarding researchBar"
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                  >
                    {fluids.map((fluid) => (
                      <MenuItem key={fluid.id} value={fluid.id}>
                        {fluid.libelle}
                      </MenuItem>
                    ))}
                  </SelectUi>
                </div>

                <div className="control-fluid-form">
                  <p>
                    {t('APP.CONTROL.INDEX.PH_0519F9', {
                      defaultValue: 'Quantité de fluide frigorigène',
                    })}
                  </p>
                  <TextField
                    onChange={(e) =>
                      updateExploitationValue('newAction', {
                        ...newAction,
                        quantity: e.target.value,
                      })
                    }
                    type="number"
                    style={{ width: '100%' }}
                    placeholder="-"
                    variant="outlined"
                    className="input-boarding researchBar"
                  />
                </div>
                <div className="control-fluid-form">
                  <p>
                    {t('APP.CONTROL.INDEX.PH_7289E5', {
                      defaultValue: 'Equipement concerné',
                    })}
                  </p>
                  <Autocomplete
                    id="checkboxes-tags-demo"
                    options={options}
                    onOpen={() => fetchOptions('category', 'fluid')}
                    loading={loading}
                    noOptionsText={'Aucun résultat trouvé'}
                    onChange={(event, newValue) => {
                      //   if (newValue)
                      updateExploitationValue('newAction', {
                        ...newAction,
                        equipment: {
                          ...newValue,
                          AllotmentNodes: [newValue.AllotmentNode],
                        },
                      });
                    }}
                    size={'small'}
                    getOptionLabel={(option) => option.Sheet.name || ''}
                    renderInput={(params) => (
                      <TextField
                        style={{ borderRadius: '10px' }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        placeholder=""
                        variant="outlined"
                        className="researchBar"
                      />
                    )}
                  />
                </div>
                <div className="control-fluid-form">
                  <p>
                    {t('APP.CONTROL.INDEX.PH_DC2B7B', {
                      defaultValue: 'Commentaire (facultatif)',
                    })}
                  </p>
                  <Tooltip
                    title={t('APP.CONTROL.INDEX.PH_4D7667', {
                      defaultValue: 'Ajouter un commentaire',
                    })}
                    placement="top"
                  >
                    <TextField
                      onChange={(e) =>
                        updateExploitationValue('newAction', {
                          ...newAction,
                          comment: e.target.value,
                        })
                      }
                      type="text"
                      style={{ width: '100%' }}
                      placeholder="-"
                      variant="outlined"
                      className="input-boarding researchBar"
                    />
                  </Tooltip>
                </div>
              </>
            )}
          </div>
          <div className="control-filter">
            <div
              onClick={() => {
                updateExploitationValue('newAction', {
                  actionType: 'filter',
                  nbFilters: null,
                  equipment: null,
                  comment: null,
                  date: newAction.date || null,
                });
                updateExploitationValue('controlChoice', 'filter');
                resetStateOptions();
              }}
              className={`control-choice ${
                controlChoice === 'filter' ? 'active-choice' : ''
              }`}
            >
              <p>
                {t('APP.CONTROL.INDEX.PH_6223B8', {
                  defaultValue: 'Remplacement filtre CTA',
                })}
              </p>
            </div>
            {controlChoice === 'filter' && (
              <>
                <div className="control-fluid-form">
                  <p>
                    {t('APP.CONTROL.FILTERS_REPLACED', {
                      defaultValue: 'Nombre de filtres remplacés',
                    })}
                  </p>{' '}
                  <TextField
                    onChange={(e) =>
                      updateExploitationValue('newAction', {
                        ...newAction,
                        nbFilters: e.target.value,
                      })
                    }
                    type="number"
                    style={{ width: '100%' }}
                    placeholder="  -"
                    variant="outlined"
                    className="input-boarding researchBar"
                  />
                </div>
                <div className="control-fluid-form">
                  <p>
                    {t('APP.CONTROL.INDEX.PH_68648E', {
                      defaultValue: 'CTA concernée',
                    })}
                  </p>
                  <Autocomplete
                    id="checkboxes-tags-demo"
                    options={options}
                    onOpen={() => fetchOptions('category', 'cta')}
                    loading={loading}
                    noOptionsText={t('APP.CONTROL.INDEX.PH_0517B1', {
                      defaultValue: 'Aucun résultat trouvé',
                    })}
                    onChange={(event, newValue) => {
                      updateExploitationValue('newAction', {
                        ...newAction,
                        equipment: {
                          ...newValue,
                          AllotmentNodes: [newValue.AllotmentNode],
                        },
                      });
                    }}
                    size={'small'}
                    getOptionLabel={(option) => option.Sheet.name || ''}
                    renderInput={(params) => (
                      <TextField
                        style={{ borderRadius: '10px' }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        placeholder=""
                        variant="outlined"
                        className="researchBar"
                      />
                    )}
                  />
                </div>
                <div className="control-fluid-form">
                  <p>
                    {t('APP.CONTROL.INDEX.PH_768FB3', {
                      defaultValue: 'Commentaire (facultatif)',
                    })}
                  </p>
                  <Tooltip
                    title={t('APP.CONTROL.INDEX.PH_ABCFAB', {
                      defaultValue: 'Ajouter un commentaire',
                    })}
                    placement="top"
                  >
                    <TextField
                      onChange={(e) =>
                        updateExploitationValue('newAction', {
                          ...newAction,
                          comment: e.target.value,
                        })
                      }
                      type="select"
                      style={{ width: '100%' }}
                      placeholder="-"
                      variant="outlined"
                      className="input-boarding researchBar"
                    />
                  </Tooltip>
                </div>
              </>
            )}
          </div>

          <div className="control-filter">
            <div
              onClick={() => {
                updateExploitationValue('newAction', {
                  actionType: 'other',
                  name: null,
                  equipment: null,
                  impact: null,
                  comment: null,
                  date: newAction.date || null,
                });
                updateExploitationValue('controlChoice', 'other');
                resetStateOptions();
              }}
              className={`control-choice ${
                controlChoice === 'other' ? 'active-choice' : ''
              }`}
            >
              <p>
                {t('APP.CONTROL.INDEX.PH_68EAD6', {
                  defaultValue: 'Autres opérations',
                })}
              </p>
            </div>
            {controlChoice === 'other' && (
              <>
                <div className="control-fluid-form">
                  <p>
                    {t('APP.CONTROL.INDEX.PH_86EB21', {
                      defaultValue: "Nom de l'opération réalisée",
                    })}
                  </p>
                  <TextField
                    onChange={(e) =>
                      updateExploitationValue('newAction', {
                        ...newAction,
                        name: e.target.value,
                      })
                    }
                    type="text"
                    style={{ width: '100%' }}
                    placeholder="  -"
                    variant="outlined"
                    className="input-boarding researchBar"
                  />
                </div>
                <div className="control-fluid-form">
                  <p>
                    {t('APP.CONTROL.INDEX.PH_EB36C5', {
                      defaultValue: 'Equipement concerné',
                    })}
                  </p>
                  <Autocomplete
                    id="checkboxes-tags-demo"
                    options={options}
                    loading={loading}
                    onOpen={() => fetchOptions('search', '')}
                    noOptionsText={'Aucun résultat trouvé'}
                    onChange={(event, newValue) => {
                      //   if (newValue)
                      updateExploitationValue('newAction', {
                        ...newAction,
                        equipment: {
                          ...newValue,
                          AllotmentNodes: [newValue.AllotmentNode],
                        },
                      });
                    }}
                    size={'small'}
                    getOptionLabel={(option) => option.Sheet.name || ''}
                    renderInput={(params) => (
                      <TextField
                        style={{ borderRadius: '10px' }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        placeholder=""
                        variant="outlined"
                        className="researchBar"
                      />
                    )}
                  />
                </div>
                <div className="control-fluid-form">
                  <p>
                    {t('APP.CONTROL.INDEX.PH_EB8CD2', {
                      defaultValue: 'Empreinte carbone',
                    })}
                    <span style={{ color: 'rgba(174, 174, 174, 1)' }}>
                      {t('APP.CONTROL.INDEX.PH_65DFCB', {
                        defaultValue: '(si connue)',
                      })}
                    </span>
                  </p>
                  <TextField
                    onChange={(e) =>
                      updateExploitationValue('newAction', {
                        ...newAction,
                        impact: e.target.value,
                      })
                    }
                    type="text"
                    style={{ width: '40%' }}
                    placeholder="  -"
                    variant="outlined"
                    className="input-boarding researchBar"
                  />

                  <span style={{ display: 'inline-block', marginTop: '10px' }}>
                    {t('APP.CONTROL.INDEX.PH_9FC459', {
                      defaultValue: 'kg éq. CO2',
                    })}
                  </span>
                </div>
                <div className="control-fluid-form">
                  <p>
                    {t('APP.CONTROL.INDEX.PH_2F9D38', {
                      defaultValue: 'Commentaire (facultatif)',
                    })}
                  </p>
                  <Tooltip
                    title={t('APP.CONTROL.INDEX.PH_1B73CE', {
                      defaultValue: 'Ajouter un commentaire',
                    })}
                    placement="top"
                  >
                    <TextField
                      onChange={(e) =>
                        updateExploitationValue('newAction', {
                          ...newAction,
                          comment: e.target.value,
                        })
                      }
                      type="text"
                      style={{ width: '100%' }}
                      placeholder="-"
                      variant="outlined"
                      className="input-boarding researchBar"
                    />
                  </Tooltip>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', marginLeft: 'auto', width: '15%' }}>
        <Button
          style={{
            fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
            marginRight: '10px',
          }}
          onClick={() => resetAddAction()}
          text={t('APP.CONTROL.INDEX.PH_249C2B', { defaultValue: 'Fermer' })}
          className="close-button"
        />

        <Button
          style={{
            fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
          }}
          loading={isLoading}
          onClick={() => {
            createAction(t);
            setIsLoading(true);
          }}
          disabled={checkValidation()}
          className="purple-button"
          text={t('APP.CONTROL.INDEX.PH_68947D', { defaultValue: 'Ajouter' })}
        />
      </div>
    </>
  );
};

export default Control;
