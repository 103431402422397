import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  TextField,
  Tooltip,
  MenuItem,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import SelectUi from '@mui/material/Select';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { inputStyle, selectStyle } from '../utils';

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#789ADE',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: '1.5px solid #789ADE',
      },
    },
    '& .MuiSelect-select': {
      padding: '8px',
      border: '1px solid #789ADE',
    },
  },
});

type FormValues = {
  lifespan: number;
};

function GeneralInformation() {
  const {
    control,
    formState: { errors },
    trigger,
  } = useForm<FormValues>();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className="form-block">
      <h2 className="form-title">
        {t('APP.PROJECT.SIMULATOR.QUICK_SIMULATION.GENERAL.INFORMATION.TITLE', {
          defaultValue: 'Informations générales',
        })}
      </h2>

      <hr className="line-title-separation" />

      <div
        style={{
          display: 'flex',
          paddingLeft: '24px',
          paddingRight: '100px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: '24px',
            rowGap: '8px',
          }}
        >
          <div>
            <label className="form-label" htmlFor="lifespan">
              {t('APP.PROJECT.SIMULATOR.QUICK_SIMULATION.FORM.LABEL.LIFESPAN', {
                defaultValue: 'Durée de vie',
              })}
            </label>

            <Controller
              control={control}
              name="lifespan"
              rules={{
                validate: (value) => !isNaN(value) && value > 0,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="-"
                  style={{ width: '75%' }}
                  variant="outlined"
                  className={classes.root}
                  onBlur={() => trigger('lifespan')}
                  error={Boolean(errors.lifespan)}
                  inputProps={{
                    style: inputStyle,
                  }}
                />
              )}
            />
          </div>
          <div>
            <label className="form-label" htmlFor="unit">
              {t('APP.PROJECT.SIMULATOR.QUICK_SIMULATION.FORM.LABEL.UNIT', {
                defaultValue: "Choix de l'unité de référence",
              })}
              <Tooltip
                title={t(
                  'APP.PROJECT.SIMULATOR.QUICK_SIMULATION.FORM.LABEL.UNIT_TOOLTIP',
                  {
                    defaultValue:
                      'Tous les calculs d’impact de votre projet seront exprimés selon l’unité de référence choisie : /m² de la surface de référence du projet ou bien en total kg éq. CO2.',
                  }
                )}
              >
                <InfoIcon
                  fontSize="small"
                  style={{
                    marginLeft: '3px',
                  }}
                  htmlColor="#A3AED0"
                />
              </Tooltip>
            </label>
            <SelectUi
              color="primary"
              MenuProps={{
                sx: {
                  '& .MuiMenuItem-root:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  },
                  '& .Mui-selected': {
                    backgroundColor: '#E1E1E6',
                  },
                },
              }}
              sx={{
                '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' },
                '.MuiOutlinedInput-input': selectStyle,
              }}
              name="unit"
              id="unit"
              defaultValue="m²"
              className={classes.root}
              variant="outlined"
              style={{ width: '75%' }}
            >
              {/* fake data just for the visual, it will be replaced don't pay attention */}
              <MenuItem value="m²">m²</MenuItem>
              <MenuItem value="total">total</MenuItem>
            </SelectUi>
          </div>
        </div>

        <div className="form-radio-control-project-type">
          <FormControl>
            <FormLabel>
              <p className="form-radio-control-label">
                {t(
                  'APP.PROJECT.SIMULATOR.QUICK.SIMULATION.RADIOGROUP.PROJECT_TYPE',
                  {
                    defaultValue: 'Type de projet',
                  }
                )}
              </p>
            </FormLabel>
            <RadioGroup defaultValue="new">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Radio color="primary" value="new" />
                <p className="MuiFormControlLabel-label">
                  {t(
                    'APP.PROJECT.SIMULATOR.QUICK.SIMULATION.RADIOGROUP.NEW.OPTION',
                    {
                      defaultValue: 'Neuf',
                    }
                  )}
                </p>
              </div>

              <Tooltip
                title={t(
                  'APP.PROJECT.SIMULATOR.QUICK.SIMULATION.RADIOGROUP.NOT.AVAILABLE.TOOLTIP',
                  {
                    defaultValue: 'Bientôt disponible',
                  }
                )}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ cursor: 'not-allowed' }}>
                    <Radio color="primary" disabled className={classes.root} />
                  </div>

                  <p className="MuiFormControlLabel-label">
                    {t(
                      'APP.PROJECT.SIMULATOR.QUICK.SIMULATION.RADIOGROUP.RENOVATION.OPTION',
                      {
                        defaultValue: 'Rénovation',
                      }
                    )}
                  </p>
                </div>
              </Tooltip>
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default GeneralInformation;
