import './listActions.scss';
import 'moment/locale/fr';
import { Button } from '@photocarbone/nooco-ui';
import { Select, Table } from 'antd';
import { useParams } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { useVT } from 'virtualizedtableforantd4';
import AddActions from '../../../containers/AddActions';
import AddIcon from '@material-ui/icons/Add';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import commentPic from '../../../assets/images/import/comment.png';
import formatNumber from '../../services/mathematics/formatNumber';
import refurbishedIcon from '../../../assets/images/equipments/refurbished.png';

import { StateDTO } from '../../models/GeneralDTO';
import { deleteAction } from '../../../actions/exploitation';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import i18nInstance from '../../../i18n';
import { useTransform } from 'framer-motion';
import { dateColumn } from '../../projects/EquipmentList/ListEquipments/allColumnsObject';

export const returnNameAction = (eventName: string, t: any): string => {
  switch (eventName) {
    case 'Renewal':
      return t('APP.LISTACTIONS.INDEX.PH_398F98', {
        defaultValue: "Renouvellement d'équipement/matériau",
      });
    case 'Energy':
      return t('APP.LISTACTIONS.INDEX.PH_902612', {
        defaultValue: "Consommation d'énergie",
      });
    case 'Water':
      return t('APP.LISTACTIONS.INDEX.PH_39F924', {
        defaultValue: 'Consommation d’eau',
      });
    case 'Car':
    case 'Truck':
      return t('APP.LISTACTIONS.INDEX.PH_AD1613', {
        defaultValue: 'Transport sur site',
      });
    case 'Control':
    case 'Filter':
    case 'Fluid':
    case 'Other':
      return t('APP.LISTACTIONS.INDEX.PH_1FD80E', {
        defaultValue: 'Contrôle et entretien',
      });
    case 'Garbage':
      return t('APP.LISTACTIONS.INDEX.PH_26D783', {
        defaultValue: 'Gestion de déchets',
      });
    default:
      return eventName;
  }
};

const { Option } = Select;

interface ListActionsProps {
  currentIndicator: {};
  displayScreen: string;
  updateExploitationValue: Function;
  listActionDatas: {}[];
  fetchListActions: Function;
  actionOffset: number;
  boardingListDatas: {}[];
  fetchBoarding: Function;
  loadingActions: boolean;
  isActionsFull: boolean;
  eventTypes: {}[];
  listActionsInput: string;
  filteredListActionsDatas: {}[];
  searchListActions: Function;
}

interface IRecord {
  Source: {};
  Target: {};
}

export const renderDetailsAction = (eventName: string, record: IRecord, t: any) => {
  const values = {
    Other: `${record.misc}, ${
      record.Source ? record.Source?.Sheet?.name : record.Target?.Sheet?.name
    }`,
    Fluid: t('APP.LISTACTIONS.INDEX.PH_971165', {
      v1: record.Source?.Sheet.name.slice(
        19,
        record.Source?.Sheet.name.indexOf('-')
      ),
      v2: record.Target?.Sheet.name,
      defaultValue: 'Ajout de fluide frigorigène {v1}, {v2}',
    }),
    Filter: t('APP.LISTACTIONS.INDEX.PH_3645C4', {
      v1: record.Target?.Sheet.name,
      defaultValue: 'Remplacement pièce, Filtre {v1}',
    }),
    default: record.Source ? record.Source?.Sheet?.name : record.Target?.Sheet?.name,
  };

  return <p style={{ marginBottom: 0 }}>{values[eventName] ?? values.default}</p>;
};

const ListActions = ({
  currentIndicator,
  displayScreen,
  updateExploitationValue,
  listActionDatas,
  fetchListActions,
  actionOffset,
  boardingListDatas,
  fetchBoarding,
  loadingActions,
  isActionsFull,
  eventTypes,
  listActionsInput,
  filteredListActionsDatas,
  searchListActions,
}: ListActionsProps) => {
  const [vt, set_components] = useVT(
    () => ({
      scroll: {
        y:
          window.screen.availHeight < 950
            ? window.screen.availHeight - 490
            : window.screen.availHeight - 500,
      },
    }),
    []
  );

  const dispatch = useDispatch();
  const { actionActiveTab, selectedActionKeys } = useSelector(
    (state: StateDTO) => state.exploitation
  );
  const { currentProject } = useSelector((state: StateDTO) => state.projects);

  const { id } = useParams();
  const { t } = useTranslation();

  // Management pagination for actions's list
  useEffect(() => {
    if (!loadingActions && listActionDatas.length === 0) {
      fetchListActions(id, t);
    }
    updateExploitationValue('offset', 0);
  }, []);

  const rowSelection = {
    selectedActionKeys,
    onChange: (selectedRowKeys) =>
      updateExploitationValue('selectedActionKeys', selectedRowKeys),
    columnWidth: 50,
    checkStrictly: false,
  };

  useEffect(() => {
    var tableContent = document.querySelector('.ant-table-body');

    let isScrollLeft = 0;

    const fetchingMoreDatas = (event) => {
      // checking whether a selector is well defined
      let maxScroll = event.target.scrollHeight - event.target.clientHeight;
      let currentScroll = event.target.scrollTop;

      if (isScrollLeft !== event.target.scrollLeft) {
        isScrollLeft = event.target.scrollLeft;

        return;
      }

      if (isActionsFull) {
        tableContent?.removeEventListener('scroll', fetchingMoreDatas, true);
      }

      if (currentScroll === maxScroll && !isActionsFull) {
        // load more data
        fetchListActions(id, t);
      }
    };

    if (tableContent && listActionDatas.length > 0) {
      tableContent.addEventListener('scroll', (event) => fetchingMoreDatas, true);
    }
  }, [isActionsFull, listActionDatas]);

  // trigger to filter on table when input change
  useUpdateEffect(() => {
    searchListActions();
  }, [listActionsInput]);

  const changeActiveTab = (tabValue) => {
    updateExploitationValue('actionActiveTab', tabValue);
    updateExploitationValue('actionOffset', 0);
    updateExploitationValue('listActionDatas', []);

    fetchListActions(id, t);
  };

  const arrayChoises = [
    t('APP.LISTACTIONS.INDEX.PH_EF4109', { defaultValue: 'Projet' }),
    t('APP.LISTACTIONS.INDEX.PH_032F72', { defaultValue: 'Renouvellement' }),
    t('APP.LISTACTIONS.INDEX.PH_CD5564', { defaultValue: 'Energie' }),
    t('APP.LISTACTIONS.INDEX.PH_44E2BE', { defaultValue: 'Transport' }),
    t('APP.LISTACTIONS.INDEX.PH_7B3889', { defaultValue: 'Déchet' }),
  ];

  return (
    <div className="list-actions-wrapper">
      {displayScreen === 'list' && (
        <>
          <div className="actions-header">
            <div style={{ display: 'flex', width: '80%' }}>
              <div className="actions-button" style={{ display: 'flex' }}>
                {arrayChoises.map((el) => (
                  <Button
                    key={el}
                    onClick={() => changeActiveTab(el)}
                    style={{
                      fontSize:
                        window.screen.availHeight > 950 ? '0.9rem' : undefined,
                      marginRight: '1em',
                    }}
                    shape="round"
                    className={
                      actionActiveTab === el ? 'golden-button' : 'gray-button'
                    }
                    text={el}
                  />
                ))}
              </div>
              <div className="search-bar-equipment" style={{}}>
                <Input
                  id="standard-adornment-password"
                  placeholder={t('APP.LISTACTIONS.INDEX.PH_51FB08', {
                    defaultValue: 'Recherche',
                  })}
                  value={listActionsInput}
                  onChange={(e) =>
                    updateExploitationValue('listActionsInput', e.target.value)
                  }
                  style={{ width: '100%' }}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton aria-label="toggle password visibility">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
            </div>

            <div className="groupby-exploitation" style={{ display: 'none' }}>
              <p>
                {t('APP.LISTACTIONS.INDEX.PH_4E64E0', {
                  defaultValue: 'Regrouper par ',
                })}
              </p>
              <Select style={{ fontSize: '0.9rem', width: 120 }}>
                <Option style={{ fontSize: '0.9rem' }} value="aucun">
                  Aucun
                </Option>
              </Select>
            </div>
          </div>

          <Table
            locale={{
              emptyText: t('APP.LISTACTIONS.INDEX.PH_B8C2E8', {
                defaultValue: 'Pas de donnée',
              }),
            }}
            components={{
              body: vt,
            }}
            rowSelection={rowSelection}
            columns={[
              dateColumn(t, currentProject),
              {
                title: t('APP.LISTACTIONS.INDEX.PH_B5C10F', {
                  defaultValue: "Type d'action effectuée",
                }),
                dataIndex: 'type',
                key: 'type',
                render(value, record, index) {
                  let event = '';

                  if (eventTypes) {
                    event = eventTypes.find((el) => el.id === record.EventTypeId);
                  }

                  const name = returnNameAction(event?.name, t);

                  return <p>{name}</p>;
                },
              },
              {
                title: t('APP.LISTACTIONS.INDEX.PH_D18DD7', {
                  defaultValue: 'Quantité',
                }),
                dataIndex: 'quantity',
                key: 'quantity',
                width: 110,
                render: (value, record, index) =>
                  record.Target ? (
                    <p>{`${record.Target?.quantity} ${record.Target?.Unit?.name}`}</p>
                  ) : (
                    <p>{`${record.Source?.quantity} ${record.Source?.Unit?.name}`}</p>
                  ),
              },
              {
                title: t('APP.LISTACTIONS.INDEX.PH_05C5A9', {
                  defaultValue: "Détail de l'action",
                }),
                dataIndex: 'detail',
                key: 'detail',
                render: (value, record, index) => {
                  let eventName = eventTypes?.find(
                    (el) => el.id === record.EventTypeId
                  )?.name;

                  return (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      {record.Source?.Sheet.type_fiche === 'REFURBISHED' &&
                        eventName.includes('Renewal') && (
                          <Tooltip title={'Composant réemploi'}>
                            <img
                              src={refurbishedIcon}
                              alt=""
                              style={{ width: '27px', marginRight: '10px' }}
                            />
                          </Tooltip>
                        )}

                      {renderDetailsAction(eventName, record, t)}
                    </span>
                  );
                },
              },
              {
                title: t('APP.LISTACTIONS.INDEX.PH_AB0CC1', {
                  defaultValue: 'EGES TOTAL',
                }),
                dataIndex: 'impact',
                key: 'impact',
                render: (text, record) => (
                  <p>{formatNumber(record.carbonImpact)} kg éq. CO₂</p>
                ),
              },
              {
                title: t('APP.LISTACTIONS.INDEX.PH_F65EC9', {
                  defaultValue: 'Fin de vie',
                }),
                dataIndex: 'end',
                key: 'end',
                align: 'center',
                render: (text, record) => {
                  let event = eventTypes.find((el) => el.id === record.EventTypeId);

                  if (event.name !== 'Other') {
                    return <p>{record.misc}</p>;
                  } else {
                    return null;
                  }
                },
              },
              {
                title: '',
                dataIndex: 'comment',
                key: 'comment',
                render: (text, record) =>
                  record?.comment ? (
                    <div>
                      <Tooltip title={record.comment}>
                        <img
                          className="filter-svg"
                          style={{ width: '20px' }}
                          src={commentPic}
                          alt=""
                        />
                      </Tooltip>
                    </div>
                  ) : null,
                width: 50,
              },
              {
                dataIndex: 'actions',
                title: () => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {selectedActionKeys?.length > 0 && (
                        <DeleteIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => dispatch(deleteAction())}
                        />
                      )}
                    </div>
                  );
                },
                align: 'center',
                width: 70,
                key: 'actions',
                fixed: 'right',
                render: (value, record) => <MoreHorizIcon />,
              },
            ]}
            dataSource={
              filteredListActionsDatas?.map((el) => ({ ...el, key: el.id })) ||
              listActionDatas?.map((el) => ({ ...el, key: el.id }))
            }
            pagination={false}
            showSorterTooltip={false}
            style={{ width: '97%', margin: 'auto', background: '#F4F7FC' }}
            scroll={{
              y:
                window.screen.availHeight < 750
                  ? window.screen.availHeight - 390
                  : window.screen.availHeight < 950
                  ? window.screen.availHeight - 460
                  : window.screen.availHeight - 510,
            }}
            loading={loadingActions}
          />
          <div className="bottom-part">
            <Button
              onClick={() => updateExploitationValue('displayScreen', 'add')}
              style={{ fontSize: '0.85rem', marginRight: '50px' }}
              additionalIcon={<AddIcon />}
              className="purple-button"
              text={t('APP.LISTACTIONS.INDEX.PH_8F2C99', {
                defaultValue: 'Ajouter une action',
              })}
            />
          </div>
        </>
      )}

      {displayScreen === 'add' && (
        <AddActions updateExploitationValue={updateExploitationValue} />
      )}
    </div>
  );
};

export default ListActions;
