/* eslint-disable no-unused-vars */
import { Dispatch } from 'redux';
import { MapDispatchToPropsParam, MapStateToPropsParam, connect } from 'react-redux';

import { NewActionDTO } from '../../app/models/ExploitationDTO';

import EnergyWater from '../../app/exploitation/ListActions/AddActions/EnergyWater';

import {
  createAction,
  getCaracs,
  resetAddAction,
  updateExploitationValue,
} from '../../actions/exploitation';

interface IExploitation {
  breadcrumbChoice: string;
  selectedLot: string;
  selectedFonction: string;
  selectedCategory: string;
  selectedMaterial: string;
  selectedType: string;
  mdConsumer: {};
  newAction: NewActionDTO;
}

interface IProjects {
  allGroups: {
    type: [];
  };
  zones: [];
}

type State = {
  exploitation: IExploitation;
  projects: IProjects;
};

const mapStateToProps: MapStateToPropsParam<{}, {}, State> = (state: State) => ({
  //   controlChoice: state.exploitation.controlChoice,
  allGroups: state.projects.allGroups,
  zones: state.projects.zones,
  breadcrumbChoice: state.exploitation.breadcrumbChoice,
  selectedLot: state.exploitation.selectedLot,
  selectedFonction: state.exploitation.selectedFonction,
  selectedCategory: state.exploitation.selectedCategory,
  selectedMaterial: state.exploitation.selectedMaterial,
  selectedType: state.exploitation.selectedType,
  mdConsumer: state.exploitation.mdConsumer,
  newAction: state.exploitation.newAction,
});

const mapDispatchToProps: MapDispatchToPropsParam<{}, {}> = (
  dispatch: Dispatch
) => ({
  updateExploitationValue: (name: string, value: string) => {
    dispatch(updateExploitationValue(name, value));
  },
  resetAddAction: () => {
    dispatch(resetAddAction());
  },
  getCaracs: (md, screen) => {
    dispatch(getCaracs(md, screen));
  },
  createAction: (t) => {
    dispatch(createAction(t));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EnergyWater);
