export const returnCountOfImportRows = (
  actualCount,
  validatedRows,
  notValidatedRows,
  userValidatedRows,
  isArchivedRows,
  filterOn,
  displayArchived,
  isSearchingData
) => {
  if (isSearchingData) {
    return actualCount;
  }
  if (filterOn?.length > 0) {
    let count = 0;

    if (filterOn.includes('Validated')) {
      count += validatedRows + notValidatedRows;
    }
    if (filterOn.includes('userValidated')) {
      count += userValidatedRows;
    }
    if (displayArchived) {
      count += isArchivedRows;
    }

    return count;
  } else {
    return displayArchived
      ? validatedRows + notValidatedRows + userValidatedRows + isArchivedRows
      : validatedRows + notValidatedRows + userValidatedRows;
  }
};

export const canImportRowBeAddedInProject = (importRow): boolean => {
  return importRow.AllotmentNodeId &&
    importRow.statusCode === 'userValidated' &&
    !importRow.ProjectImportElemCaracs?.find((el) => el.statusCode)
    ? true
    : false;
};

export const getFlattenImportRows = (importList) => {
  let linesDuplicate = [];

  importList.forEach((importRow) => {
    if (importRow.ProjectElemsDuplicate) {
      linesDuplicate = [...linesDuplicate, ...importRow.ProjectElemsDuplicate];
    } else {
      linesDuplicate = [...linesDuplicate, importRow];
    }
  });

  return linesDuplicate;
};
