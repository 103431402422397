import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '@photocarbone/nooco-ui';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import informationPic from '../../../../../../assets/images/equipments/information.png';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

export const phaseMap = (t) => {
  return {
    Production: {
      modules: [
        {
          label: 'A1',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_665A47', {
            defaultValue: 'Extraction et production des matières utilisées',
          }),
        },
        {
          label: 'A2',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_33B79E', {
            defaultValue:
              "Transport des matières premières jusqu'au site de fabrication",
          }),
        },
        {
          label: 'A3',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_FE8A32', {
            defaultValue: 'Fabrication du composant',
          }),
        },
      ],
    },
    Edification: {
      modules: [
        {
          label: 'A4',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_879CC7', {
            defaultValue:
              "Transport des composants du site de fabrication jusqu'au chantier de construction",
          }),
        },
        {
          label: 'A5',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_C89B54', {
            defaultValue:
              'Chantier de construction dont la mise en oeuvre des composants',
          }),
        },
      ],
    },
    // TODO_TRAD : key fr ??
    'Entretien et maintenance (Exploitation)': {
      modules: [
        {
          label: 'B1',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_F4728C', {
            defaultValue:
              "Impacts liés à l'utilisation des composants dans le bâtiment (hors usage eau et énergie)",
          }),
        },
        {
          label: 'B2',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_6F32C9', {
            defaultValue: "Opérations d'entretien du bâtiment",
          }),
        },
        {
          label: 'B3',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_12C50F', {
            defaultValue: 'Opérations de réparation du bâtiment',
          }),
        },
        {
          label: 'B4',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_E4C1B1', {
            defaultValue: 'Opérations de remplacement de composants du bâtiment',
          }),
        },
      ],
    },
    // TODO_TRAD : key fr ??
    'Opération (Exploitation)': {
      modules: [
        {
          label: 'B6',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_AAD135', {
            defaultValue: "Impacts liés aux consommations d'énergie",
          }),
        },
        {
          label: 'B7',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_87F87B', {
            defaultValue: "Impacts liés aux consommations et rejets d'eau",
          }),
        },
      ],
    },
    Exploitation: {
      modules: [
        {
          label: 'B1',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_B3C9A6', {
            defaultValue:
              "Impacts liés à l'utilisation des composants dans le bâtiment (hors usage eau et énergie)",
          }),
        },
        {
          label: 'B2',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_B3F137', {
            defaultValue: "Opérations d'entretien du bâtiment",
          }),
        },
        {
          label: 'B3',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_1BA161', {
            defaultValue: 'Opérations de réparation du bâtiment',
          }),
        },
        {
          label: 'B4',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_77354E', {
            defaultValue: 'Opérations de remplacement de composants du bâtiment',
          }),
        },
        {
          label: 'B6',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_AD19E7', {
            defaultValue: "Impacts liés aux consommations d'énergie",
          }),
        },
        {
          label: 'B7',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_D7FBA3', {
            defaultValue: "Impacts liés aux consommations et rejets d'eau",
          }),
        },
      ],
    },
    'Fin de vie': {
      modules: [
        {
          label: 'C1',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_30453B', {
            defaultValue:
              'Le chantier de déconstruction (dépose de composants avant démolition) ou de démolition du bâtiment',
          }),
        },
        {
          label: 'C2',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_27D9DB', {
            defaultValue:
              "Transport de déchets du chantier de déconstruction/démolition soit vers un lieu de valorisation soit vers un lieu d'élimination",
          }),
        },
        {
          label: 'C3',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_ACADE6', {
            defaultValue:
              'Valorisation des déchets du chantier de déconstruction/démolition (réemploi, réutilisation, recyclage, valorisation énergétique)',
          }),
        },
        {
          label: 'C4',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_0B7539', {
            defaultValue:
              'Elimination des déchets du chantier de  déconstruction/démolition',
          }),
        },
      ],
    },
    'Module D': {
      modules: [
        {
          label: 'D',
          description: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_2BF771', {
            defaultValue:
              "Permet de comptabiliser les bénéfices et charges environnementaux liés à la valorisation des produits en fin de vie (recyclage, valorisation énergétique) ainsi qu'à l'export d'énergie",
          }),
        },
      ],
    },
  };
};

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(109,109,109,0.87)',
    minWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #4B4949FF',
    borderRadius: '0px',
  },
}))(Tooltip);

export type FormLabelElementWrapperProps = {
  labelPhraseKey: string;
  labelDefaultValue: string;
  tooltipTitlePhraseKey: string;
  tooltipTitleDefaultValue: string;
  tooltipKey: string;
  unit: string;
  t: any;
};
export const FormLabelElementWrapper = ({
  labelPhraseKey,
  labelDefaultValue,
  tooltipTitlePhraseKey,
  tooltipTitleDefaultValue,
  tooltipKey,
  t,
  unit,
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div className="caracteristicLabel">
        {t(labelPhraseKey, {
          unit,
          defaultValue: labelDefaultValue,
        })}
      </div>
      <CustomTooltip
        title={
          <React.Fragment>
            <div
              style={{
                display: 'flex',
                height: '100%',
                width: '100%',
                justifyContent: 'space-between',
                padding: '5px',
              }}
            >
              <div>
                {t(tooltipTitlePhraseKey, {
                  defaultValue: tooltipTitleDefaultValue,
                })}
              </div>
            </div>
            <div
              style={{
                padding: '5px',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '5px',
              }}
            >
              {phaseMap(t)[tooltipKey]?.modules.map((elem) => (
                <div style={{ fontWeight: 'normal' }} key={elem.label}>
                  {elem.label}: {elem.description}
                </div>
              ))}
            </div>
          </React.Fragment>
        }
      >
        <img className="informationPic" src={informationPic} alt="" />
      </CustomTooltip>
    </div>
  );
};

const CarbonWeight = ({ carbonWeightFormInstance }) => {
  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = carbonWeightFormInstance;
  const { getValues } = useFormContext();
  const { t } = useTranslation();

  const unit = getValues('unit');

  const handleBlur = () => {};

  const handleBlurError = (error) => {
    console.log('error', error);
  };

  const bModuleDetails = [
    {
      keyPhrase: 'APP.LIBRARY.MODELINGPHASE.B1',
      keyDefaultValue: 'B1',
      tooltipTitlePhrase: 'APP.LIBRARY.MODELINGPHASE.TOOTLIP_TITLE_B1',
      tooltipTitleDefaultValue:
        "Impacts liés à l'utilisation des composants dans le bâtiment (hors usage eau et énergie)",
      tooltipKey: 'B1',
    },
    {
      keyPhrase: 'APP.LIBRARY.MODELINGPHASE.B2',
      keyDefaultValue: 'B2',
      tooltipTitlePhrase: 'APP.LIBRARY.MODELINGPHASE.TOOTLIP_TITLE_B2',
      tooltipTitleDefaultValue: "Opérations d'entretien du bâtiment",
      tooltipKey: 'B2',
    },
    {
      keyPhrase: 'APP.LIBRARY.MODELINGPHASE.B3',
      keyDefaultValue: 'B3',
      tooltipTitlePhrase: 'APP.LIBRARY.MODELINGPHASE.TOOTLIP_TITLE_B3',
      tooltipTitleDefaultValue: 'Opérations de réparation du bâtiment',
      tooltipKey: 'B3',
    },
    {
      keyPhrase: 'APP.LIBRARY.MODELINGPHASE.B4',
      keyDefaultValue: 'B4',
      tooltipTitlePhrase: 'APP.LIBRARY.MODELINGPHASE.TOOTLIP_TITLE_B4',
      tooltipTitleDefaultValue:
        'Opérations de remplacement de composants du bâtiment',
      tooltipKey: 'B4',
    },
    {
      keyPhrase: 'APP.LIBRARY.MODELINGPHASE.B5',
      keyDefaultValue: 'B5',
      tooltipTitlePhrase: 'APP.LIBRARY.MODELINGPHASE.TOOTLIP_TITLE_B5',
      tooltipTitleDefaultValue: 'B5',
      tooltipKey: 'B5',
    },
    {
      keyPhrase: 'APP.LIBRARY.MODELINGPHASE.B6',
      keyDefaultValue: 'B6',
      tooltipTitlePhrase: 'APP.LIBRARY.MODELINGPHASE.TOOTLIP_TITLE_B6',
      tooltipTitleDefaultValue: "Impacts liés aux consommations d'énergie",
      tooltipKey: 'B6',
    },
    {
      keyPhrase: 'APP.LIBRARY.MODELINGPHASE.B7',
      keyDefaultValue: 'B7',
      tooltipTitlePhrase: 'APP.LIBRARY.MODELINGPHASE.TOOTLIP_TITLE_B7',
      tooltipTitleDefaultValue: "Impacts liés aux consommations et rejets d'eau",
      tooltipKey: 'B7',
    },
  ];

  return (
    <div>
      <Row style={{ display: 'flex', padding: 30 }}>
        {/* production */}
        <Col xs={24} lg={12}>
          <Row>
            <Col>
              <div className="formElementWrapper">
                <FormLabelElementWrapper
                  labelPhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_B2CE1A"
                  tooltipTitlePhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_81D113"
                  tooltipKey="Production"
                  t={t}
                  unit={unit}
                />
                <Controller
                  name="product"
                  control={control}
                  rules={{
                    required: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_739883', {
                      defaultValue: 'Veuillez renseigner une quantité',
                    }),
                    pattern: {
                      value: /^-?(0|[1-9]\d*)([.,]\d+)?$/,
                      message: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_E08A42', {
                        defaultValue: 'Veuillez renseigner une quantité',
                      }),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      errorText={
                        dirtyFields?.product ? errors?.product?.message : ''
                      }
                      width={'300px'}
                      disabledDebouncing={true}
                      error={errors?.product?.message ? true : false}
                      onBlur={handleSubmit(
                        () => handleBlur(),
                        (e) => handleBlurError(e)
                      )}
                    />
                  )}
                />
              </div>
            </Col>
            <Col>
              {/* installation */}
              <div className="formElementWrapper">
                <FormLabelElementWrapper
                  labelPhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_8EDDE4"
                  tooltipTitlePhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_FA72BF"
                  tooltipKey="Edification"
                  t={t}
                  unit={unit}
                />
                <Controller
                  name="installation"
                  control={control}
                  rules={{
                    required: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_B6B560', {
                      defaultValue: 'Veuillez renseigner une quantité',
                    }),
                    pattern: {
                      value: /^-?(0|[1-9]\d*)([.,]\d+)?$/,
                      message: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_5C3DA2', {
                        defaultValue: 'Veuillez renseigner une quantité',
                      }),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      disabledDebouncing={true}
                      errorText={
                        dirtyFields?.installation
                          ? errors?.installation?.message
                          : ''
                      }
                      width={'300px'}
                      error={errors?.installation?.message ? true : false}
                      onBlur={handleSubmit(
                        () => handleBlur(),
                        (e) => handleBlurError(e)
                      )}
                    />
                  )}
                />
              </div>
            </Col>
            <Col>
              <div className="formElementWrapper">
                <FormLabelElementWrapper
                  labelPhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_F0DDAF"
                  tooltipTitlePhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_F34F13"
                  tooltipKey="Fin de vie"
                  t={t}
                  unit={unit}
                />
                <Controller
                  name="endOfLife"
                  control={control}
                  rules={{
                    required: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_E0BEBB', {
                      defaultValue: 'Veuillez renseigner une quantité',
                    }),
                    pattern: {
                      value: /^-?(0|[1-9]\d*)([.,]\d+)?$/,
                      message: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_8DAF8C', {
                        defaultValue: 'Veuillez renseigner une quantité',
                      }),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      disabledDebouncing={true}
                      errorText={
                        dirtyFields?.endOfLife ? errors?.endOfLife?.message : ''
                      }
                      width={'300px'}
                      error={errors?.endOfLife?.message ? true : false}
                      onBlur={handleSubmit(
                        () => handleBlur(),
                        (e) => handleBlurError(e)
                      )}
                    />
                  )}
                />
              </div>
            </Col>
            <Col>
              {/* profit */}
              <div className="formElementWrapper">
                <FormLabelElementWrapper
                  labelPhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_4348D5"
                  tooltipTitlePhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_8274F1"
                  tooltipKey="Module D"
                  t={t}
                  unit={unit}
                />
                <Controller
                  name="profit"
                  control={control}
                  rules={{
                    required: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_91C522', {
                      defaultValue: 'Veuillez renseigner une quantité',
                    }),
                    pattern: {
                      value: /^-?(0|[1-9]\d*)([.,]\d+)?$/,
                      message: t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_518179', {
                        defaultValue: 'Veuillez renseigner une quantité',
                      }),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      disabledDebouncing={true}
                      errorText={dirtyFields?.profit ? errors?.profit?.message : ''}
                      width={'300px'}
                      error={errors?.profit?.message ? true : false}
                      onBlur={handleSubmit(
                        () => handleBlur(),
                        (e) => handleBlurError(e)
                      )}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={24} lg={12}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <FormLabelElementWrapper
                labelPhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_031F63"
                tooltipTitlePhraseKey="APP.CARBONWEIGHT.CARBONWEIGHT.PH_BC490D"
                tooltipKey="Exploitation"
                t={t}
                unit={unit}
              />
            </Col>

            {bModuleDetails.map((bModuleDetail) => (
              <Col>
                <FormLabelElementWrapper
                  labelPhraseKey={bModuleDetail.keyPhrase}
                  labelDefaultValue={bModuleDetail.keyDefaultValue.toUpperCase()}
                  tooltipTitlePhraseKey={bModuleDetail.tooltipTitlePhrase}
                  tooltipTitleDefaultValue={bModuleDetail.tooltipTitleDefaultValue}
                  tooltipKey={bModuleDetail.tooltipKey}
                  t={t}
                  unit={unit}
                />
                <Controller
                  name={bModuleDetail.keyDefaultValue}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      disabledDebouncing={true}
                      errorText={
                        dirtyFields[bModuleDetail.keyDefaultValue]
                          ? errors[bModuleDetail.keyDefaultValue]?.message
                          : ''
                      }
                      width={'100px'}
                      error={
                        errors[bModuleDetail.keyDefaultValue]?.message ? true : false
                      }
                      onBlur={handleSubmit(
                        () => handleBlur(),
                        (e) => handleBlurError(e)
                      )}
                    />
                  )}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <div className="information">
        {t('APP.CARBONWEIGHT.CARBONWEIGHT.PH_52E881', {
          defaultValue: 'Veuillez renseigner tous les champs.',
        })}
      </div>
    </div>
  );
};

export default CarbonWeight;
