// import { FETCH_ACCESS_HEADER } from "../actions/access";
import {
  FETCH_COMPANIES_LIST,
  UPDATE_RIGHTS_VALUE,
  rightsValueActionDTO,
} from '../actions/rights';
import { RightsDTO } from '../app/models/RightsDTO';
import { CompanyDTO } from '../app/models/ProjectDTO';

const initialState: RightsDTO = {
  rightsTabValue: 0,
  rightsActiveType: 'Travaux',
  rightsHeaderData: null,
  companiesList: null,
  companiesOffset: 0,
  modalAccess: null,
  isLoadingAddUser: false,
  inputSearch: null,
  parentNodeId: null,
  patchCompanyDatas: {},
  newCompany: {
    freeLicensesCount: [
      {
        name: 'READ ONLY',
        licenseType: 'read',
        backgroundColor: '#6EE8CB',
        count: 0,
      },
      {
        name: 'LIGHT',
        licenseType: 'light',
        backgroundColor: '#97F1E6',
        count: 0,
      },
      { name: 'PRO', licenseType: 'pro', backgroundColor: '#91E391', count: 0 },
    ],
  },
  selectedUser: null,
  selectedCompany: null,
  selectedParentCompany: null,
  sortBy: 'Entreprises',
  snackbarMessage: '',
  isSnackbarOpen: false,
  snackbarSeverity: undefined,
  parentCompaniesFiltered: [],
  addUserSelectedCompany: null,
};

const rightsReducer = (
  state: RightsDTO = initialState,
  action: rightsValueActionDTO
) => {
  switch (action.type) {
    case UPDATE_RIGHTS_VALUE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case FETCH_COMPANIES_LIST:
      return {
        ...state,
        companiesLoading: true,
      };
    // case FETCH_ACCESS_HEADER:

    //   return {
    //     ...state,
    //     initLoading: true,
    //   };
    default:
      return state;
  }
};

export default rightsReducer;
