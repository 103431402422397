export const CHANGE_VALUE_VARIANT = 'CHANGE_VALUE_VARIANT';
export const ADD_VARIANT_SELECTION = 'ADD_VARIANT_SELECTION';
export const LOAD_VARIANTS = 'LOAD_VARIANTS';
export const REMOVE_ADDED_PROJECT = 'REMOVE_ADDED_PROJECT';
export const FETCH_PROJECT_FOR_GRAPH = 'FETCH_PROJECT_FOR_GRAPH';
export const ADD_PROJECT_TO_VARIANT = 'ADD_PROJECT_TO_VARIANT';
export const LOAD_FULL_PROJECT = 'LOAD_FULL_PROJECT';

export const changeValueVariant = (name, value) => ({
  type: CHANGE_VALUE_VARIANT,
  name,
  value,
});

export const addVariantSelection = (variant) => ({
  type: ADD_VARIANT_SELECTION,
  variant,
});

export const loadVariants = (
  data,
  allGroups,
  currentIndicator,
  currentAllotment
) => ({
  type: LOAD_VARIANTS,
  data,
  allGroups,
  currentIndicator,
  currentAllotment,
});

export const addProjectToVariant = (project) => ({
  type: ADD_PROJECT_TO_VARIANT,
  project,
});

export const removeAddedProject = (project) => ({
  type: REMOVE_ADDED_PROJECT,
  project,
});

export const fetchProjectForGraph = (project, isSelected) => ({
  type: FETCH_PROJECT_FOR_GRAPH,
  project,
  isSelected,
});

export const loadFullProject = (
  project,
  allGroups,
  isSelected,
  currentIndicator,
  currentProject
) => ({
  type: LOAD_FULL_PROJECT,
  project,
  allGroups,
  isSelected,
  currentIndicator,
  currentProject,
});
