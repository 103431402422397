import './mappingLine.scss';
import '../../../Import/Line/line.scss';
import { MenuItem, Select } from '@material-ui/core';
import { Popover, Checkbox } from 'antd';
import { StateDTO } from '../../../../models/GeneralDTO';
import {
  deleteRowsImport,
  fetchPepFdes,
  verifyRowsEquipment,
} from '../../../../../actions/import';
import {
  findParentNode,
  handleKeyPress,
  returnDefaultValueForSearchGroupSelectType,
  returnSearchBarOptionsFiltered,
} from '../../../../shared/utils';
import { updateFormValue } from '../../../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import ArchiveIcon from '@material-ui/icons/Archive';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import NoocoCheckbox from '../../../../shared/NoocoCheckbox';
import React, { useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import TextField from '@mui/material/TextField';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import commentPic from '../../../../../assets/images/import/comment.png';
import link from '../../../../../assets/images/import/link.png';
import mdIcon from '../../../../../assets/images/import/md.png';
import penPic from '../../../../../assets/images/import/pen.svg';
import red from '../../../../../assets/images/import/Red_Circle(small).svg.png';
import valid from '../../../../../assets/images/import/Eo_circle_green_checkmark.svg';
import yellow from '../../../../../assets/images/mapping/yellow.png';
import { useTranslation } from 'react-i18next';

import { handleLineCheckbox } from '../../../Import/ImportList';
import { SearchGroup } from '@photocarbone/nooco-ui';
import API from '../../../../services/API';
import { ALLOTMENT_IDS } from '../../../../globalVariable/typeProject';
import { SEARCH_GROUP_OPTION_VALUES } from '../../../../globalVariable/searchGroupType';

let api = new API();

const MappingLine = ({
  line = {},
  style,
  key,
  copyLine,
  setCopyLine,
  cache,
  handleEdit,
  searchBarOptions,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const {
    allGroups,
    currentProject,
    nodesAvailableForProjectType,
    certifiedAllotments,
    bundleOptions,
    currentAllotment,
    configuredSheetOptions,
  } = useSelector((state: StateDTO) => state.projects);
  const {
    importPepFdes,
    linesSelected,
    allLinesChecked,
    exceptLinesFromMultipleActions,
  } = useSelector((state: StateDTO) => state.imports);

  const handleCopyLine = (line) => {
    if (!copyLine) {
      setCopyLine(line);
    } else {
      dispatch(updateFormValue('isLoad', true));

      dispatch(
        verifyRowsEquipment({
          ...copyLine,
          id: line.id,
          originalRow: line.originalRow,
          quantity: line.quantity,
          copyLine: true,
        })
      );
      setCopyLine(null);
    }
  };

  const renderCaracsPart = (line) => {
    return (
      line.Sheet?.type_fiche !== 'bundle' &&
      line.ProjectImportElemCaracs?.map((carac) => {
        let caracOptions = carac.valueList || null;

        if (line.caracCombos && line.caracCombos.name === carac.name) {
          caracOptions = line.caracCombos.valueList;
        } else if (
          line.caracCombos &&
          line.ProjectImportElemCaracs.find(
            (el) => el.name === line.caracCombos.name
          ) &&
          line.caracCombos.valueList[0].name === carac.name &&
          line.ProjectImportElemCaracs.find(
            (el) => el.name === line.caracCombos.name
          ).value
        ) {
          caracOptions = line.caracCombos.valueList.find(
            (el) =>
              el.name === carac.name &&
              el.value ===
                line.ProjectImportElemCaracs.find(
                  (el) => el.name === line.caracCombos.name
                ).value
          )?.valueList;

          if (
            line.caracCombos.valueList.find(
              (el) =>
                el.name === carac.name &&
                el.value ===
                  line.ProjectImportElemCaracs.find(
                    (el) => el.name === line.caracCombos.name
                  ).value
            )?.valueList.length === 1 &&
            !carac.value
          ) {
            const options = line.caracCombos.valueList.find(
              (el) =>
                el.name === carac.name &&
                el.value ===
                  line.ProjectImportElemCaracs.find(
                    (el) => el.name === line.caracCombos.name
                  ).value
            )?.valueList;

            const newList = line.ProjectImportElemCaracs.map((car) => {
              if (car.id === carac.id) {
                return { ...carac, value: options[0].value };
              } else {
                return car;
              }
            });

            const newLine = { ...line, ProjectImportElemCaracs: newList };

            dispatch(
              verifyRowsEquipment(line.id, {
                newCarac: { ...carac, value: options[0].value },
                newLine: newLine,
                oldCarac: carac,
              })
            );
          }
        } else if (
          line.caracCombos &&
          line.ProjectImportElemCaracs.find(
            (el) => el.name === line.caracCombos.name
          ) &&
          line.caracCombos.valueList[0].name === carac.name &&
          !line.ProjectImportElemCaracs.find(
            (el) => el.name === line.caracCombos.name
          ).value
        ) {
          caracOptions = [];
        }

        if (
          caracOptions &&
          line.caracCombos.name === carac.name &&
          caracOptions[0].name
        ) {
          caracOptions = caracOptions.filter((el) => el.valueList?.length > 0);
        }

        return (
          <div key={carac.name} className="line-carac">
            <p
              style={
                line.ProjectImportElemCaracs?.length > 2 ? { width: '68%' } : null
              }
            >
              {carac.name?.length > 26 ? (
                <Tooltip title={carac.name}>
                  <p>{`${carac.name.substring(0, 26)}[...]`}</p>
                </Tooltip>
              ) : (
                carac.name
              )}{' '}
              <span
                style={{
                  display: !carac.unit ? 'none' : 'inline-block',
                  minWidth: '20px',
                }}
              >
                ({carac.unit})
              </span>
            </p>

            {((carac.valueList && carac.valueList.length > 0) || caracOptions) && (
              <Select
                required
                dropdownStyle={{ width: '220px' }}
                type="text"
                as="select"
                MenuProps={{
                  disableScrollLock: true,
                }}
                custom
                disabled={
                  line.SheetId ||
                  (line.caracCombos?.valueList[0]?.name === carac.name &&
                    !line.ProjectImportElemCaracs.find(
                      (el) => el.name === line.caracCombos.name
                    )?.value) ||
                  lineIsArchived
                }
                defaultValue={carac.value}
                key={carac.value}
                className="line-select"
                style={{
                  width: '38%',
                  maxWidth: '175px',
                  marginTop: '0.2em',
                  borderBottom:
                    (line.caracCombos?.valueList[0]?.name === carac.name &&
                      !line.ProjectImportElemCaracs.find(
                        (el) => el.name === line.caracCombos.name
                      )?.value) ||
                    caracOptions.length === 1
                      ? '1px solid #ccc'
                      : !carac.value || carac.statusCode
                      ? '2px solid red'
                      : '1px solid black',
                }}
                onChange={(e, value) => {
                  let patchSecondDiscrete = false;

                  if (carac.name === line.caracCombos.name) {
                    patchSecondDiscrete = true;
                  }

                  const newList = line.ProjectImportElemCaracs.map((car) => {
                    if (car.id === carac.id) {
                      return { ...carac, value: e.target.value };
                    } else if (
                      patchSecondDiscrete &&
                      car.name === line.caracCombos.valueList[0].name
                    ) {
                      patchSecondDiscrete = { ...car, value: null };

                      return { ...car, value: null };
                    } else {
                      return car;
                    }
                  });

                  const newLine = { ...line, ProjectImportElemCaracs: newList };

                  if (
                    patchSecondDiscrete &&
                    line.caracCombos.valueList.find(
                      (el) => el.value === e.target.value
                    ).valueList?.length > 1
                  ) {
                    dispatch(
                      verifyRowsEquipment(line.id, {
                        newCarac: patchSecondDiscrete,
                        newLine: newLine,
                      })
                    );
                  }

                  dispatch(
                    verifyRowsEquipment(line.id, {
                      newCarac: { ...carac, value: e.target.value },
                      newLine: newLine,
                      oldCarac: carac,
                    })
                  );
                }}
              >
                {caracOptions
                  .sort((a, b) => a.value - b.value)
                  .map((el) => (
                    <MenuItem key={el.value} value={el.value}>
                      {el.value}
                    </MenuItem>
                  ))}
              </Select>
            )}
            {(!carac.valueList || carac.valueList.length === 0) && !caracOptions && (
              <TextField
                disabled={line.SheetId || lineIsArchived}
                className="line-quantity-input"
                onKeyDown={handleKeyPress}
                defaultValue={carac.value || null}
                style={{
                  width: '38.5%',
                  borderBottom:
                    !carac.value || carac.statusCode
                      ? '2px solid red'
                      : '1px solid black',
                }}
                variant="standard"
                onBlur={(e) => {
                  if (e.target.value !== carac.value) {
                    let newCarac = { ...carac, value: e.target.value };

                    const newList = line.ProjectImportElemCaracs.map((car) => {
                      if (car.id === carac.id) {
                        return newCarac;
                      } else {
                        return car;
                      }
                    });

                    const newLine = {
                      ...line,
                      ProjectImportElemCaracs: newList,
                    };

                    setTimeout(() => {
                      dispatch(
                        verifyRowsEquipment(line.id, {
                          newCarac,
                          newLine: newLine,
                          oldCarac: carac,
                        })
                      );
                    }, 100);
                  }
                }}
              />
            )}
          </div>
        );
      })
    );
  };

  const getTrimLength = () => {
    if (window.innerWidth >= 1600) {
      return 60;
    } else {
      return 50;
    }
  };

  let lineIsArchived = line.isArchived;

  return (
    <div
      className={`mapping-table-wrapper line ${
        copyLine?.id === line.id ? 'line-selected' : ''
      } ${lineIsArchived ? 'line-archived' : ''} `}
      style={{ cursor: copyLine ? 'copy' : undefined }}
      onClick={() =>
        copyLine && copyLine.id !== line.id ? handleCopyLine(line) : null
      }
    >
      <div className="block-center">
        <div style={{ marginBottom: '8px' }}>
          {line.originalName?.length > 150 ? (
            <Tooltip title={line.originalName} placement="top">
              <p className={`mapping-libelle-source`}>
                {!lineIsArchived && (
                  <Checkbox
                    key={linesSelected}
                    style={{ marginRight: '1em' }}
                    checked={linesSelected.find((el) => el === line.id)}
                    onClick={(e) =>
                      handleLineCheckbox(
                        e.target.checked,
                        line.id,
                        linesSelected,
                        allLinesChecked,
                        exceptLinesFromMultipleActions,
                        dispatch
                      )
                    }
                  />
                )}
                <p>{`${line.originalName?.slice(0, 150)}...`}</p>
              </p>
            </Tooltip>
          ) : (
            <p className={`mapping-libelle-source`}>
              {!lineIsArchived && (
                <Checkbox
                  key={linesSelected}
                  style={{ marginRight: '1em' }}
                  checked={linesSelected.find((el) => el === line.id)}
                  onClick={(e) =>
                    handleLineCheckbox(
                      e.target.checked,
                      line.id,
                      linesSelected,
                      allLinesChecked,
                      exceptLinesFromMultipleActions,
                      dispatch
                    )
                  }
                />
              )}
              <span style={{ display: 'inline-block' }}>{line.originalName}</span>
            </p>
          )}
        </div>
        {line.originalRow && (
          <p className="file-number">(ligne fichier: {line.originalRow})</p>
        )}
      </div>
      <div className="block-center">
        <SearchGroup
          t={t}
          companyId={currentProject.CompanyId}
          gridStyle={'[first] 20% [line2] 70% [line3] 10%'}
          triggerBreadcrumb={() => {
            dispatch(updateFormValue('openBreadcrumb', true));
            dispatch(updateFormValue('currentLine', line));
          }}
          bundleOptions={bundleOptions}
          searchBarOptionsFiltered={searchBarOptions}
          findParentNode={findParentNode}
          certifiedAllotments={certifiedAllotments}
          allAllotments={allGroups.flatAllotments}
          projectTypeId={currentProject.ProjectTypeId}
          allotmentsAvailable={
            line.OptionSortedByAI ||
            nodesAvailableForProjectType?.filter((el) => !el.isObsolete)
          }
          dropdownHeight="70px"
          api={api}
          dropdownProps={{
            onSelect: () => {},
            disabled: line.isArchived,
            onChange: (value) => {
              if (
                value === SEARCH_GROUP_OPTION_VALUES.EQUIPEMENTS ||
                value === SEARCH_GROUP_OPTION_VALUES.PRODUITS ||
                value === SEARCH_GROUP_OPTION_VALUES.ENSEMBLES ||
                value === SEARCH_GROUP_OPTION_VALUES.ENERGIE ||
                value === SEARCH_GROUP_OPTION_VALUES.EAU ||
                value === SEARCH_GROUP_OPTION_VALUES.BIBLIOTHEQUE
              ) {
                handleEdit('AllotmentNode', line.id, null, null, true);
              } else if (typeof value === 'number') {
                handleEdit(
                  'AllotmentNode',
                  line.id,
                  null,
                  allGroups.flatAllotments.find((el) => el.id === value),
                  true
                );
              } else {
                handleEdit('sheetPepFdes', line.id, null, value);
              }
            },
            placeholder: 'Rechercher...',
            treeCheckable: false,
            value: line.Sheet?.processedLabel
              ? line.Sheet?.processedLabel
              : line.Sheet?.name
              ? line.Sheet?.name
              : line.AllotmentNode
              ? line.AllotmentNode?.name
              : null,
          }}
          selectProps1={{
            defaultValue: returnDefaultValueForSearchGroupSelectType(
              line,
              allGroups
            ),
            filterOptions: [
              SEARCH_GROUP_OPTION_VALUES.EQUIPEMENTS,
              SEARCH_GROUP_OPTION_VALUES.INIES,
              SEARCH_GROUP_OPTION_VALUES.EAU,
              SEARCH_GROUP_OPTION_VALUES.ENERGIE,
              SEARCH_GROUP_OPTION_VALUES.BIBLIOTHEQUE,
            ],
            disabled: Boolean(line.isArchived),
          }}
          selectProps2={{
            ListType: ['Nooco'],
            noocoOptions: line?.allAllotmentNodesRelatedToLineMasterData || [],
            keyDisplay: 'name',
            keyItem: 'id',
            onChange: (selectedOptionValue, type) => {
              let data;

              if (currentAllotment.id === ALLOTMENT_IDS.NOOCO) {
                data = line?.allAllotmentNodesRelatedToLineMasterData.find(
                  (el) => el.name === selectedOptionValue
                );
              } else {
                data = allGroups.flatAllotments.find(
                  (el) =>
                    el.name === selectedOptionValue &&
                    el.AllotmentId === currentAllotment.id
                );
              }

              handleEdit(
                currentAllotment.id === ALLOTMENT_IDS.NOOCO
                  ? 'AllotmentNode'
                  : 're2020lot',
                line.id,
                null,
                data.details
                  ? data.details.find((el) => el.MasterDataEquipmentId)
                  : data,
                true
              );
            },
            disabled: Boolean(line.isArchived),

            value: [line.AllotmentNode],
          }}
          selectWidth="100px"
        />
      </div>
      <div>{renderCaracsPart(line)}</div>
      <Tooltip
        title={
          line.statusCode === 'Validated' ? (
            <span style={{ display: 'block' }}>
              {t('APP.MAPPINGLINE.INDEX.PH_40F019', {
                defaultValue: 'Ligne à vérifier.',
              })}
              <br />
              {t('APP.MAPPINGLINE.INDEX.PH_B4F640', {
                defaultValue: 'Cliquer ici pour la valider',
              })}
            </span>
          ) : line.statusCode === 'userValidated' ? (
            t('APP.MAPPINGLINE.INDEX.PH_19BA0D', {
              defaultValue:
                'Cette ligne est active dans le mapping et sera prévalidée dans vos futurs imports',
            })
          ) : (
            line.status
          )
        }
      >
        <div className="validation-button" style={{ position: 'relative' }}>
          <img
            style={{ cursor: lineIsArchived ? 'not-allowed' : undefined }}
            src={
              line.statusCode === 'Validated'
                ? yellow
                : line.statusCode === 'userValidated'
                ? valid
                : red
            }
            alt=""
            onClick={() =>
              !lineIsArchived
                ? line.statusCode === 'Validated'
                  ? handleEdit('onValidation', line.id, null, null)
                  : null
                : null
            }
          />
          {line.statusCode !== 'Validated' &&
            line.statusCode !== 'userValidated' && (
              <img
                className="show-error"
                style={{
                  height: '25px',
                  width: '29Px',
                  cursor: 'initial',
                  top: '7px',
                  left: '9px',
                  position: 'absolute',
                  borderRadius: '0px',
                }}
                src={mdIcon}
              />
            )}
        </div>
      </Tooltip>

      <div className="actions-top">
        <Tooltip
          title={t('APP.MAPPINGLINE.INDEX.PH_36F533', {
            defaultValue: 'Supprimer une ligne',
          })}
        >
          <DeleteIcon
            style={{
              cursor: line.isArchived ? 'not-allowed' : 'pointer',
              color: '#52626A',
              display: 'inline-block',
              marginLeft: 'auto',
              height: '20px',
            }}
            onClick={() => {
              if (!lineIsArchived) dispatch(deleteRowsImport(line.id));
            }}
          />
        </Tooltip>
      </div>
      <div className="actions-bottom">
        <Tooltip
          title={t('APP.MAPPINGLINE.INDEX.PH_92BA7C', {
            defaultValue: 'Copier cet équipement/matériau',
          })}
        >
          <img
            className="filter-svg"
            onClick={() =>
              !copyLine && !line.isArchived
                ? handleCopyLine(line)
                : setCopyLine(null)
            }
            style={{
              width: '16px',
              height: '20px',
              marginTop: 'auto',
              cursor: !lineIsArchived ? 'pointer' : undefined,
            }}
            src={penPic}
            alt=""
          />
        </Tooltip>
        <Popover
          content={() => (
            <div>
              <TextField
                onBlur={(e) => {
                  handleEdit('comment', line.id, null, e.target.value, null);
                }}
                variant="outlined"
                id="outlined-multiline-static"
                multiline
                rows={4}
                defaultValue={line.comment}
              />
            </div>
          )}
          trigger="click"
          title={t('APP.MAPPINGLINE.INDEX.PH_1C3203', {
            defaultValue: 'Ajouter/Modifier un commentaire',
          })}
        >
          <Tooltip
            title={
              line.comment ||
              t('APP.MAPPINGLINE.INDEX.PH_8E839A', {
                defaultValue: 'Affecter un commentaire à la ligne',
              })
            }
          >
            <img
              className={line.comment ? 'filter-svg' : 'filter-svg-exist'}
              style={{
                width: '17px',
                height: '19px',
                marginTop: 'auto',
                cursor: !lineIsArchived ? 'pointer' : undefined,
              }}
              src={commentPic}
              alt=""
            />
          </Tooltip>
        </Popover>
        <Tooltip
          title={
            !line.isArchived
              ? t('APP.MAPPINGLINE.INDEX.PH_510BBE', {
                  defaultValue: 'Archiver cette ligne',
                })
              : ''
          }
        >
          {line.isArchived ? (
            <UnarchiveIcon
              onClick={() =>
                dispatch(
                  verifyRowsEquipment({
                    id: line.id,
                    isArchived: !line.isArchived,
                  })
                )
              }
              style={{ color: '#52626A', height: '20px' }}
            />
          ) : (
            <ArchiveIcon
              onClick={() =>
                dispatch(
                  verifyRowsEquipment({
                    id: line.id,
                    isArchived: !lineIsArchived,
                  })
                )
              }
              style={{ color: '#52626A', height: '20px' }}
            />
          )}
        </Tooltip>
      </div>
    </div>
  );
};

export default MappingLine;
