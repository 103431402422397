import { Switch } from 'antd';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NoocoCheckbox from '../NoocoCheckbox';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ReemployCheck = ({
  reEmploy,
  setSelectedSheetObj,
  setReEmploy,
  setIsEditing,
  importScreen,
  verifyRowsEquipment,
  line,
  handleEdit,
  loading,
  updateFormValue,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={!importScreen ? 'composant-part' : null}
      style={!importScreen ? { width: '420px', padding: '1em' } : null}
    >
      <FormControlLabel
        labelPlacement="start"
        control={
          <Switch
            checked={!importScreen ? reEmploy : line.isRefurbished}
            onChange={() => {
              if (line) {
                updateFormValue('loadingSwitch', [...loading, line.id]);
                handleEdit('isRefurbished', line.id, null, !line.isRefurbished);

                return;
              }

              if (!reEmploy) {
                setSelectedSheetObj();
              }

              if (setIsEditing) {
                setIsEditing(true);
              }

              setReEmploy(!reEmploy);
            }}
            name="checkedA"
            loading={loading ? loading.includes(line?.id) : false}
          />
        }
        label={t('APP.REEMPLOYCHECK.INDEX.PH_19B03E', {
          defaultValue: "Il s'agit d'un équipement / matériau issu du réemploi",
        })}
      />
    </div>
  );
};

export default ReemployCheck;
