/**
 * Created by nanaa on 26/05/2021
 */

import { connect } from 'react-redux';

import { updateFormValue } from '../../actions/projects';

import {
  closeDedModal,
  sendRequestDed,
  changeValueEquipments,
} from '../../actions/equipments';

import DedModal from '../../app/projects/EquipmentList/DedModal';

const mapStateToProps = (state) => ({
  showDedModal: state.projects.showDedModal,
  explainDed: state.projects.explainDed,
  nameDed: state.projects.nameDed,
  productDed: state.projects.productDed,
  unitDed: state.projects.unitDed,
  calculDed: state.projects.calculDed,
  nomenDed: state.projects.nomenDed,
  equipmentDedAsking: state.projects.equipmentDedAsking,
  iniesNomenclature: state.projects.iniesNomenclature,
  composantVideForm: state.equipments.composantVideForm,
});

const mapDispatchToProps = (dispatch) => ({
  closeDedModal: () => {
    dispatch(closeDedModal());
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  sendRequestDed: () => {
    dispatch(sendRequestDed());
  },
  changeValueEquipments: (name, value) => {
    dispatch(changeValueEquipments(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DedModal);
