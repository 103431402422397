import { Button } from '@photocarbone/nooco-ui';
import { MenuItem, Select } from '@material-ui/core';
import { Amplify } from 'aws-amplify';
import { basicConfigAmplify } from '../../../index';
import { Modal } from 'react-bootstrap';
import {
  fetchProjectById,
  setFileUploadStatus,
  updateFormValue,
} from '../../../actions/projects';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import AppContext from '../../AppContext';
import BackupIcon from '@material-ui/icons/Backup';
import ClearIcon from '@material-ui/icons/Clear';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoIcon from '@material-ui/icons/Info';
import React, { useContext, useEffect, useState } from 'react';

import { calculatorReset, calculatorWaterReset } from '../../../actions/calculator';
import { getReferenceTypeNameFromProjectType } from '../utils';
import { updateMonitoring } from '../../../actions/monitoring';
import Tooltip from '@material-ui/core/Tooltip';
import config from '../../../config';
import jsonImport from './JsonImport';
import rsetImport from './RsetImportCode';
import { useTranslation } from 'react-i18next';
import { uploadData } from 'aws-amplify/storage';

var convert = require('xml-js');

const RsetImport = ({
  settings = false,
  alertCallback,
  projectId,
  renderCallback,
  creationSteps,
  project,
  resetBuildingSections,
  uuid,
}) => {
  const { currentProject, lots, buildingTypes, currentIndicator } = useSelector(
    (state) => state.projects
  );
  const isRset = currentProject.isRset;

  let dispatch = useDispatch();

  const ctx = useContext(AppContext);
  const [file, setFile] = useState(null);
  const [showFile, setShowFile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [zonesMap, setZonesMap] = useState({});
  const [datasComp, setDatasComp] = useState({});
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  let referenceType = settings
    ? getReferenceTypeNameFromProjectType(currentProject.templateType)
    : creationSteps[creationSteps.length - 1] === 'Mode RE2020'
    ? 'RE2020'
    : 'E+C-';

  const onChange = async (file) => {
    if (!file) {
      resetBuildingSections();
      dispatch(updateFormValue('buildingSiteDurationRset', null));
      dispatch(updateFormValue('parcelAreaRset', null));
      dispatch(updateFormValue('projectNameRset', null));
      dispatch(updateFormValue('phaseIdRset', null));
      dispatch(updateFormValue('typeOfProject', 'standard'));
      dispatch(updateFormValue('rsetFile', null));
      dispatch(updateFormValue('isRsenv', false));
      dispatch(updateFormValue('postCodeRset', null));
      dispatch(setFileUploadStatus(false));
      setShowFile(false);

      return setFile(null);
    } else {
      dispatch(setFileUploadStatus(true));
      setFile(file);
      setShowFile(true);
    }

    const format = file.name.substring(
      file.name.lastIndexOf('.') + 1,
      file.name.length
    );

    if (format !== 'xml' && !settings && format !== 'json') {
      const badFileExtensionErrorMessage = t('APP.RSETUPLOAD.RSETIMPORT.PH_E0E0F8', {
        defaultValue: 'Le fichier doit être un xml ou un json.',
      });

      alertCallback(badFileExtensionErrorMessage, 'error');

      return;
    }

    setFile(file);

    try {
      Amplify.configure({
        ...basicConfigAmplify,
        Storage: {
          S3: {
            bucket: config.config.BUCKET,
            region: config.cognito.REGION,
          },
        },
      });

      await uploadData({ key: `temp/${uuid}/RSET_file.xml`, data: file });
    } catch (error) {
      console.log('Error trying to upload rset file, error is ', error);
    }
  };

  const setAndShowSnackBarMEssage = (severity, message) => {
    dispatch(updateMonitoring('alertSeverity', severity));
    dispatch(updateMonitoring('alertMessage', message));
  };

  useEffect(() => {
    return;
  }, [settings]);

  useEffect(() => {
    if (!file) {
      return;
    }

    try {
      parseFile();
    } catch (e) {
      alertCallback(e, 'error');
    }
  }, [file]);

  const parseFile = () => {
    const reader = new FileReader();

    reader.readAsText(file);

    reader.onloadend = (event) => {
      const readerData = event.target.result;

      let bodyString = readerData.toString('utf8');
      let rset;
      let resultArray;

      const format = file.name.substring(
        file.name.lastIndexOf('.') + 1,
        file.name.length
      );

      if (format === 'json') {
        const reader = new FileReader();

        reader.readAsText(file);
        reader.onloadend = (event) => {
          const readerData = event.target.result;

          resultArray = jsonImport(
            JSON.parse(readerData.toString('utf8')),
            buildingTypes
          );

          const [newBatCollection, parcelArea, ddvChantier, projectName, phaseId] =
            resultArray;

          dispatch(
            updateFormValue('parcelAreaRset', parcelArea ? parcelArea : null)
          );
          dispatch(updateFormValue('buildingSections', newBatCollection));
          dispatch(updateFormValue('buildingSiteDurationRset', ddvChantier));
          dispatch(updateFormValue('projectNameRset', projectName));
          dispatch(updateFormValue('phaseIdRset', phaseId));

          return;
        };

        return;
      }

      try {
        rset = convert.xml2json(bodyString, { compact: true, spaces: 4 });
        rset = JSON.parse(rset);
        console.log(rset);
        resultArray = rsetImport(
          rset,
          settings,
          project,
          alertCallback,
          buildingTypes,
          setFile,
          referenceType
        );
      } catch (error) {
        console.log('Error during import is ', error);

        alertCallback(
          t('APP.RSETUPLOAD.RSETIMPORT.PH_78BB17', {
            defaultValue:
              "Le fichier xml n'est pas valide, vérifiez la structure du fichier et ses balises.",
          }),
          'error'
        );

        return;
      }

      const [
        rsenv,
        newDatasComp,
        matchingZonesMap,
        newBatCollection,
        buildingSiteDuration,
        parcelArea,
        isRsenv,
        projectName,
        phaseId,
        projectPostCode,
        permitDate,
        allZonesMatch,
      ] = resultArray;

      if (settings) {
        setZonesMap(matchingZonesMap);
        setDatasComp(newDatasComp);
        if (Object.keys(matchingZonesMap).length > 0) {
          setShowModal(true);
        }
        dispatch(updateFormValue('rsetFile', file));
        dispatch(updateFormValue('isRsenv', isRsenv));
        setConfirmDisabled(!allZonesMatch);

        return;
      }

      dispatch(
        updateFormValue(
          'buildingSiteDurationRset',
          buildingSiteDuration ? buildingSiteDuration : null
        )
      );
      dispatch(updateFormValue('parcelAreaRset', parcelArea ? parcelArea : null));
      dispatch(updateFormValue('buildingSections', newBatCollection));
      dispatch(updateFormValue('typeOfProject', 'projectWithZones'));
      dispatch(updateFormValue('buildingSite', true));
      dispatch(updateFormValue('parcel', true));
      dispatch(updateFormValue('rsetFile', file));
      dispatch(updateFormValue('isRsenv', isRsenv));
      dispatch(updateFormValue('datasComp', newDatasComp));
      dispatch(updateFormValue('projectNameRset', projectName));
      dispatch(updateFormValue('permitDateRset', permitDate));
      dispatch(updateFormValue('postCodeRset', projectPostCode));
      dispatch(updateFormValue('phaseIdRset', phaseId));

      setShowFile(true);

      if (rsenv?.entree_projet.parcelle) {
        dispatch(
          updateFormValue('plotEquips', rsenv.entree_projet.parcelle.contributeur)
        );
      }
    };
  };

  const updateRset = async () => {
    setIsLoading(true);
    setConfirmDisabled(true);

    let editZones = {
      zones: [],
      rset: true,
    };

    for (const [key] of Object.entries(zonesMap)) {
      const zone = zonesMap[key];
      const updatedZone = { ...zone.selectedMatchingZone };

      updatedZone['name'] = zone.newZone['name'];
      updatedZone['area'] = zone.newZone['area'];
      updatedZone['zone_c'] = { ...zone.newZone['zone_c'] };
      updatedZone['urbanConso'] = { ...zone.newZone['urbanConso'] };
      updatedZone['OEefProdPvAcAnnuel'] = zone.newZone['OEefProdPvAcAnnuel'];
      updatedZone['OEefProdPvAnnuel'] = zone.newZone['OEefProdPvAnnuel'];
      updatedZone['iniesBuildingTypeId'] = zone.newZone['iniesBuildingTypeId'];
      updatedZone['srt'] = zone.newZone['srt'];

      editZones.zones.push(updatedZone);
    }

    const promises = [];
    const newLots = [...lots];

    if (!lots.find((lot) => lot?.name === 'Energie')) {
      lots.push(lots.find((lot) => lot?.name === 'Energie'));
    }

    Amplify.configure({
      ...basicConfigAmplify,
      Storage: {
        S3: {
          bucket: config.config.BUCKET,
          region: config.cognito.REGION,
        },
      },
    });

    promises.push(
      ctx.api.patchProject({
        id: projectId,
        isRset: true,
        Lots: newLots,
        DatasComp: datasComp,
      })
    );
    promises.push(ctx.api.patchZones(projectId, editZones));
    promises.push(
      uploadData({
        key: `${projectId}/RSET_${currentProject.name}.xml`,
        data: file,
      })
    );

    const response = await Promise.all(promises);

    setShowModal(false);
    setConfirmDisabled(false);

    if (response[0].status === 200 && response[1].status === 200) {
      renderCallback();
      dispatch(
        updateFormValue('currentProject', { ...currentProject, isRset: true })
      );

      if (settings) {
        setAndShowSnackBarMEssage(
          'success',
          t('APP.RSETUPLOAD.RSETIMPORT.PH_2D6D88', {
            defaultValue: 'RSET importé avec succès.',
          })
        );
        dispatch(calculatorReset());
        dispatch(calculatorWaterReset());
        dispatch(fetchProjectById(projectId));
        setIsLoading(false);

        return;
      } else {
        setIsLoading(false);

        return alertCallback(
          t('APP.RSETUPLOAD.RSETIMPORT.PH_1D2488', {
            defaultValue: 'RSET importé avec succès.',
          })
        );
      }
    }
    if (settings) {
      setAndShowSnackBarMEssage(
        'error',
        t('APP.RSETUPLOAD.RSETIMPORT.PH_F93F75', {
          defaultValue: "Erreur de l'import du RSET.",
        })
      );

      setIsLoading(false);

      return;
    } else {
      setIsLoading(false);

      return alertCallback(
        t('APP.RSETUPLOAD.RSETIMPORT.PH_D6DEAF', {
          defaultValue: "Erreur de l'import du RSET.",
        }),
        'error'
      );
    }
  };

  const handleRsetDelete = async () => {
    setIsLoading(true);

    //Make array of all energie ProjectSheets from xml and not in a buildingSite
    const buildingSitesZonesId = project.zones
      .filter((zone) => zone.type === 'buildingSite')
      .map((zone) => zone.id);
    const energyProjectSheetsIds = project.raw
      .filter(
        (projectSheet) =>
          projectSheet.AllotmentNodes.find((an) => an.MasterDataEquipment)
            ?.MasterDataEquipment.lot === 'Energie' &&
          (projectSheet?.source === 'xml' || projectSheet?.source === null) &&
          !buildingSitesZonesId.includes(projectSheet.ZoneId)
      )
      .map((projectSheet) => projectSheet.id);

    try {
      await ctx.api.patchProject({ id: projectId, isRset: false });
      await ctx.api.deleteEquipments(projectId, energyProjectSheetsIds);
    } catch (e) {
      if (settings) {
        setAndShowSnackBarMEssage(
          'error',
          t('APP.RSETUPLOAD.RSETIMPORT.PH_3AFE21', {
            defaultValue: 'Erreur lors de la suppression du RSET',
          })
        );
        setIsLoading(false);

        return;
      } else {
        setIsLoading(false);

        return alertCallback(
          t('APP.RSETUPLOAD.RSETIMPORT.PH_3476E9', {
            defaultValue: 'Erreur lors de la suppression du RSET',
          }),
          'error'
        );
      }
    }

    setShowModal(false);
    renderCallback();
    dispatch(
      updateFormValue('currentProject', { ...currentProject, isRset: false })
    );
    if (settings) {
      setAndShowSnackBarMEssage(
        'success',
        t('APP.RSETUPLOAD.RSETIMPORT.PH_B2019B', {
          defaultValue: 'RSET supprimé avec succès.',
        })
      );
      dispatch(fetchProjectById(projectId, currentProject.id));

      setIsLoading(false);

      return;
    } else {
      setIsLoading(false);

      return alertCallback(
        t('APP.RSETUPLOAD.RSETIMPORT.PH_4A3474', {
          defaultValue: 'RSET supprimé avec succès.',
        }),
        'success'
      );
    }
  };

  let button = <Button />;
  let modalBody = null,
    modalFooter = null;

  if (settings && isRset) {
    modalBody = (
      <Modal.Body>
        {t('APP.RSETUPLOAD.RSETIMPORT.PH_6A96F0', {
          defaultValue:
            'En cliquant sur "Supprimer", vous allez supprimer le RSET présent dans votre projet, ainsi que les consommations d\'énergie de votre projet qui en proviennent. Cette action est irréversible. Souhaitez-vous continuer et supprimer votre RSET ?',
        })}
      </Modal.Body>
    );

    modalFooter = (
      <Modal.Footer>
        <Button
          onClick={() => setShowModal(false)}
          className="close-button"
          text={t('APP.RSETUPLOAD.RSETIMPORT.PH_55DE25', {
            defaultValue: 'Fermer',
          })}
          disabled={isLoading}
        />

        <Button
          onClick={handleRsetDelete}
          loading={isLoading}
          disabled={isLoading}
          className="delete-button"
          text={t('APP.RSETUPLOAD.RSETIMPORT.PH_739EDF', {
            defaultValue: 'Supprimer',
          })}
        />
      </Modal.Footer>
    );

    button = (
      <div
        style={{
          marginTop: settings ? '1rem' : '',
          width: settings ? '50%' : '',
        }}
      >
        {!settings && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginBottom: '0' }}>Import RSET</p>
          </div>
        )}

        <Tooltip
          title={t('APP.RSETUPLOAD.RSETIMPORT.PH_02B1D3', {
            defaultValue: 'Supprimer le RSET rattaché à votre projet',
          })}
        >
          <Button
            onClick={() => setShowModal(true)}
            additionalIcon={<ClearIcon />}
            className="contact-button"
            style={{ width: settings ? '100%' : '' }}
            // htmlFor="file"
            text={t('APP.RSETUPLOAD.RSETIMPORT.PH_7398AF', {
              defaultValue: 'Supprimer le RSET',
            })}
          />
        </Tooltip>
      </div>
    );
  } else {
    button = (
      <div style={{ width: settings ? '50%' : '' }}>
        {!settings && (
          <p style={{ marginBottom: '0', display: 'none' }}>
            {t('APP.RSETUPLOAD.RSETIMPORT.PH_8C3089', {
              defaultValue: 'Import RSET',
            })}
            {!currentProject?.referenceType?.includes('E+C-') ? 'ou RSEE' : null}
            (facultatif)
          </p>
        )}

        <input
          style={{ display: 'none' }}
          id="contained-button-file"
          key={file}
          className="upload-input"
          type="file"
          accept={'.xml, .json'}
          onChange={(e) => onChange(e.target.files[0])}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label
            htmlFor="contained-button-file"
            style={{ marginBottom: settings ? '0' : '' }}
          >
            <Button
              className="contact-button"
              additionalIcon={<BackupIcon />}
              text={
                settings
                  ? t('APP.RSETUPLOAD.RSETIMPORT.PH_295105', {
                      defaultValue: 'Importer un RSET',
                    })
                  : t('APP.RSETUPLOAD.RSETIMPORT.PH_30ADC5', {
                      defaultValue: 'Créer à partir d’un fichier',
                    })
              }
              onClick={() => {
                document.getElementById('contained-button-file').click();
              }}
              style={{ margin: '1em auto 0 auto' }}
            />

            <p
              style={{
                fontSize: '0.75rem',
                fontStyle: 'italic',
                display: 'flex',
                alignItems: 'center',
                marginTop: '5px',
                marginBottom: '0',
              }}
            >
              <Tooltip
                title={
                  <div>
                    <p
                      style={{
                        color: 'white',
                        justifyContent: settings ? 'center' : '',
                        justifySelf: settings ? 'center' : '',
                      }}
                    >
                      {t('APP.RSETUPLOAD.RSETIMPORT.PH_A78CAB', {
                        defaultValue:
                          'RSET : Récapitulatif Standardisé d’Etude Thermique, fichier au format .xml issu du calcul thermique',
                      })}
                    </p>
                    {/* <p style={{ color: 'white' }}>RSEE : Récapitulatif Standardisé d’Etude Energétique et Environnementale, fichier au format .xml réunissant les calculs thermique et environnemental (livrable en E+C- et en RE2020)</p> */}
                    {/* <p style={{ color: 'white' }}>Elodie : fichier au format .json, issu d’un calcul réalisé sur le logiciel Elodie</p> */}
                  </div>
                }
              >
                <InfoIcon size={3} />
              </Tooltip>
              {`${
                settings
                  ? t('APP.RSETUPLOAD.RSETIMPORT.PH_54C39F', {
                      defaultValue: 'Format accepté : RSET(.xml)',
                    })
                  : t('APP.RSETUPLOAD.RSETIMPORT.PH_2D7E4D', {
                      defaultValue:
                        'Formats acceptés : RSET/RSEE (.xml), export Elodie (.json)',
                    })
              } `}
            </p>
          </label>
          {showFile && (
            <div className="xmlName">
              {file?.name}
              <HighlightOffIcon
                className="infoIconSettings"
                style={{ marginLeft: '10px' }}
                onClick={() => onChange(null)}
              />
            </div>
          )}
        </div>

        {/* </Tooltip> */}
      </div>
    );

    modalBody = (
      <Modal.Body>
        {Object.keys(zonesMap).map((key, i) => {
          return (
            <div
              key={key.id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1em',
                background: 'white',
                marginBottom: '0.7em',
                borderRadius: '5px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  justifyContent: 'center',
                  marginRight: '0.3em',
                }}
              >
                <p>
                  <span
                    style={{
                      color: '#4B5157',
                      marginRight: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {t('APP.RSETUPLOAD.RSETIMPORT.PH_4FCDCE', {
                      defaultValue: 'Nom :',
                    })}
                  </span>
                  {zonesMap[key].newZone.name}
                </p>
                <p>
                  <span
                    style={{
                      color: '#4B5157',
                      marginRight: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {referenceType === 'RE2020' ? 'Surface :' : 'Surface RT :'}
                  </span>
                  {referenceType === 'RE2020'
                    ? zonesMap[key].newZone.area
                    : zonesMap[key].newZone.srt}
                </p>
                <p>
                  <span
                    style={{
                      color: '#4B5157',
                      marginRight: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {t('APP.RSETUPLOAD.RSETIMPORT.PH_002888', {
                      defaultValue: 'Typologie :',
                    })}
                  </span>
                  {zonesMap[key].newZone.BuildingType.name}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  justifyContent: 'center',
                }}
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  defaultValue={
                    zonesMap[key].selectedMatchingZone
                      ? zonesMap[key].selectedMatchingZone.id
                      : null
                  }
                  label="Zone"
                  style={{ marginBottom: '0.5em' }}
                  onChange={(event) => {
                    const selectedZoneId = event.target.value;
                    const selectedZone = zonesMap[key].matchingZones.find(
                      (zone) => zone.id === selectedZoneId
                    );

                    zonesMap[key].selectedMatchingZone = selectedZone;
                    setZonesMap({ ...zonesMap });
                  }}
                >
                  {zonesMap[key].matchingZones.map((zone) => (
                    <MenuItem key={zone.id} value={zone.id}>
                      {`${zone.parentName} - ${zone.name}`}
                    </MenuItem>
                  ))}
                </Select>
                <p>
                  {referenceType === 'RE2020'
                    ? zonesMap[key].selectedMatchingZone.area
                    : zonesMap[key].selectedMatchingZone.srt}
                </p>
                <p>{zonesMap[key].selectedMatchingZone.BuildingType.name}</p>
              </div>
            </div>
          );
        })}
      </Modal.Body>
    );

    modalFooter = (
      <Modal.Footer>
        <Button
          disabled={isLoading}
          onClick={() => setShowModal(false)}
          className="close-button"
          text={t('APP.RSETUPLOAD.RSETIMPORT.PH_AB1B5D', {
            defaultValue: 'Fermer',
          })}
        />

        <Button
          disabled={confirmDisabled || isLoading}
          onClick={updateRset}
          loading={isLoading}
          className="purple-button"
          text={t('APP.RSETUPLOAD.RSETIMPORT.PH_7C1D89', {
            defaultValue: 'Confirmer',
          })}
        />
      </Modal.Footer>
    );
  }

  return (
    <div>
      {button}
      <Modal show={showModal} size="small">
        <Modal.Header closeButton>
          <Modal.Title>
            {settings && isRset
              ? t('APP.RSETUPLOAD.RSETIMPORT.PH_9B886D', {
                  defaultValue: 'Suppression RSET',
                })
              : t('APP.RSETUPLOAD.RSETIMPORT.PH_E445CB', {
                  defaultValue: 'Import RSET',
                })}
          </Modal.Title>
        </Modal.Header>
        {modalBody}
        {modalFooter}
      </Modal>
    </div>
  );
};

export default RsetImport;
