import { epcmBuildingTypesMap } from '../../services/mathematics/indicators/buildingTypesMap.js';
import _ from 'lodash';
import dayjs from 'dayjs';
import moment from 'moment';

// TODO_TRAD : trad externe & hook no usable here
const attributesToMatchRE2020 = [
  { attr: 'BuildingTypeId', name: 'type de zone' },
  { attr: 'nbLgt', name: 'nombre de logements' },
  { attr: 'nbOccupants', name: "nombre d'occupants" },
];

const attributesToMatchEPCM = [
  { attr: 'BuildingTypeId', name: 'type de zone' },
  { attr: 'nbLgt', name: 'nombre de logements' },
];

let matchingZonesMap = {};
let allZonesMatch = null;
let totZonesCount = 0;

const epcmSrtCoeff = {
  BUREAUX: 1.1,
  ENSEIGNEMENT_PRIMAIRE: 1.1,
  ENSEIGNEMENT_SECONDAIRE_JOUR: 1.2,
  ENSEIGNEMENT_SECONDAIRE_NUIT: 1.2,
  ETABLISSEMENT_PETITE_ENFANCE: 1.2,
};

function findKeysInObject(objectToSearchIn, keyToFind) {
  const matches = [];

  JSON.stringify(objectToSearchIn, (_, value) => {
    if (value && value[keyToFind]) matches.push(value[keyToFind]);

    return value;
  });

  return matches.length >= 1 ? matches[0] : matches;
}

const extractNbOccupantsEPCM = (rsenv, index) => {
  let pathToBuildings = rsenv.data_comp.batiment;

  return Array.isArray(pathToBuildings)
    ? pathToBuildings[index].nb_occupant?._text
    : index == 0
    ? pathToBuildings.nb_occupant?._text
    : null;
};

function extractGroundExpansion(index, pathToBuildings) {
  if (!pathToBuildings) return null;

  return Array.isArray(pathToBuildings)
    ? pathToBuildings[index].emprise_au_sol?._text
    : index == 0
    ? pathToBuildings.emprise_au_sol?._text
    : null;
}

const extractParkingSpotsEPCM = (rsenv, index, pathToBuildings) => {
  let nbParkSurfacePlu = Array.isArray(pathToBuildings)
    ? pathToBuildings[index].nb_place_parking_surface_plu?._text
    : index == 0
    ? pathToBuildings.nb_place_parking_surface_plu?._text
    : null;
  let nbParkUndergroundPlu = Array.isArray(pathToBuildings)
    ? pathToBuildings[index].nb_place_parking_ssol_plu?._text
    : index == 0
    ? pathToBuildings.nb_place_parking_ssol_plu?._text
    : null;
  let nbParkSurface = Array.isArray(pathToBuildings)
    ? pathToBuildings[index].nb_place_parking_surface?._text
    : index == 0
    ? pathToBuildings.nb_place_parking_surface?._text
    : null;
  let nbParkUnderground = Array.isArray(pathToBuildings)
    ? pathToBuildings[index].nb_place_parking_ssol?._text
    : index == 0
    ? pathToBuildings.nb_place_parking_ssol?._text
    : null;

  return [nbParkSurfacePlu, nbParkUndergroundPlu, nbParkSurface, nbParkUnderground];
};

const extractPlotArea = (isRE2020, rsenv) => {
  let plotArea = null;

  try {
    if (rsenv) {
      plotArea = isRE2020
        ? rsenv.entree_projet.parcelle.surface_parcelle._text
        : rsenv.data_comp.donnees_generales.operation.surface_parcelle._text;
    }
  } catch (error) {
    return null;
  }

  return plotArea;
};

const extractBuildingSiteDuration = (isRE2020, rsenv) => {
  if (!rsenv) return null;

  let pathToBuildings = isRE2020
    ? rsenv.entree_projet.batiment
    : rsenv.data_comp.batiment;

  return Array.isArray(pathToBuildings)
    ? pathToBuildings[0].duree_chantier._text
    : pathToBuildings.duree_chantier._text;
};

const extractMiscinfos = (rootItem, isRE2020) => {
  let projectName = null;
  let phaseId = null;
  let permitDate = null;
  let projectPostcode = null;

  try {
    projectName = isRE2020
      ? rootItem.Datas_Comp.donnees_generales.operation.nom._text
      : rootItem.RSET.Datas_Comp.donnees_generales.operation.name._text;

    if (rootItem.RSEnv) {
      phaseId = rootItem.RSEnv._attributes.phase;
    }

    permitDate = findKeysInObject(rootItem, 'date_depot_PC');
    permitDate = permitDate._text ? permitDate._text : permitDate;
    permitDate = dayjs(permitDate).format('L');
    const address = isRE2020
      ? rootItem.Datas_Comp.donnees_generales.operation.adresse
      : rootItem.RSET.Datas_Comp.donnees_generales.operation.adresse;

    if (address) { 
      projectPostcode = isRE2020
        ? address.postcode?._text
        : address.code_postal?._text;
    }
  } catch (error) {
    console.log('Error trying to extract misc datas, error is ', error);
  }

  return [projectName, phaseId, projectPostcode, permitDate];
};

const rsetImport = (
  xmlData,
  settings,
  project,
  alertCallback,
  buildingTypes,
  setFile,
  referenceType
) => {
  let isRE2020 = referenceType === 'RE2020';
  const firstKey = xmlData.projet ? 'projet' : 'RSEE';

  allZonesMatch = true;

  let rsenv = xmlData[firstKey]?.RSEnv || xmlData[firstKey]?.RSENV;

  const plotArea = extractPlotArea(isRE2020, rsenv);
  const buildingSiteDuration = extractBuildingSiteDuration(isRE2020, rsenv);
  const [projectName, phaseId, projectAddress, permitDate] = extractMiscinfos(
    xmlData[firstKey],
    isRE2020
  );

  matchingZonesMap = {};

  const newDatasComp = getNewDataComp(xmlData[firstKey], isRE2020);
  const rsenvBatiments = rsenv
    ? Array.isArray(rsenv?.entree_projet.batiment)
      ? rsenv?.entree_projet.batiment
      : [rsenv?.entree_projet.batiment]
    : null;

  totZonesCount = 0;

  let newBatCollection = loopOverBuildings(
    isRE2020,
    xmlData[firstKey],
    rsenvBatiments,
    project,
    settings,
    buildingTypes,
    setFile,
    alertCallback,
    rsenv
  );

  if (settings) {
    const projectNewZones = newBatCollection
      .map((building) => building.Zones)
      .flat();

    for (let newZone of projectNewZones) {
      const [zoneMatch, message] = matchZones(newZone, project, isRE2020);

      if (!zoneMatch) {
        allZonesMatch = false;

        alertCallback(message, 'error');

        break;
      }
    }
  }

  return [
    rsenv,
    newDatasComp,
    matchingZonesMap,
    newBatCollection,
    buildingSiteDuration,
    plotArea,
    rsenv != null,
    projectName,
    phaseId,
    projectAddress,
    permitDate,
    allZonesMatch,
  ];
};

const getPvProdAnnuel = (isRE2020, zoneSortie, option) => {
  try {
    let tagName = '';

    if (option === 'regular')
      tagName = isRE2020 ? 'O_Eef_Prod_PV_annuel' : 'O_E_ef_prelec_annuel';
    else tagName = isRE2020 ? 'O_Eef_Prod_PV_AC_annuel' : 'O_E_ep_prelec_annuel';

    return zoneSortie[tagName]._text
      ? zoneSortie[tagName]._text
      : zoneSortie[tagName];
  } catch (error) {
    console.error('Error during pv prod extraction, error is ', error);

    return 0;
  }
};

const getBuildingTypeId = (isRE2020, buildingTypes, currentZoneUsage) => {
  for (let type of buildingTypes) {
    if (isRE2020 && type.iniesId == currentZoneUsage) return type;

    if (!isRE2020) {
      if (type.Parent && type.Parent.length != 0) {
        for (let childType of type.Parent) {
          if (childType.iniesIdEpcm == currentZoneUsage) return childType;
        }
      } else if (type.iniesIdEpcm == currentZoneUsage) return type;
    }
  }

  return null;
};

const getZoneArea = (isRE2020, groupe, rsenvZone, zone_c) => {
  let area = '0';

  try {
    if (isRE2020) {
      area = zone_c.O_SREF._text;
    } else {
      if (rsenvZone) {
        area = rsenvZone.sdp._text;
      } else {
        area = groupe.SURT._text;
      }
    }

    if (area === '0') {
      area = groupe.SHAB._text;
    }
  } catch (error) {
    console.error('Error trying to get area, error is ', error);
  }

  return String(area);
};

const getZoneSrt = (zoneSortieC, isRE2020, buildingTypeId, area) => {
  if (isRE2020) return null;

  if (
    buildingTypeId === epcmBuildingTypesMap.LOGEMENT_COL ||
    buildingTypeId === epcmBuildingTypesMap.MAISON
  ) {
    return String(zoneSortieC['O_Shon_RT']._text);
  } else if (
    buildingTypeId === epcmBuildingTypesMap.BUREAUX ||
    buildingTypeId === epcmBuildingTypesMap.ENSEIGNEMENT_PRIMAIRE ||
    buildingTypeId === epcmBuildingTypesMap.ENSEIGNEMENT_SECONDAIRE_JOUR ||
    buildingTypeId === epcmBuildingTypesMap.ENSEIGNEMENT_SECONDAIRE_NUIT ||
    buildingTypeId === epcmBuildingTypesMap.ETABLISSEMENT_PETITE_ENFANCE
  ) {
    const buildingTypeKey = Object.keys(epcmBuildingTypesMap).find(
      (key) => epcmBuildingTypesMap[key] === buildingTypeId
    );
    const coeff = epcmSrtCoeff[buildingTypeKey];

    const correctSurt = (Number(zoneSortieC['O_SURT']._text) * coeff).toFixed(2);

    return String(correctSurt);
  }

  return area;
};

const loopOverZones = (
  isRE2020,
  currentBuilding,
  batiment_sortie_c,
  rsenvBatiments,
  newBat,
  project,
  settings,
  buildingTypes,
  setFile,
  alertCallback,
  rsenv
) => {
  let currentRsenvBuildingZones = rsenvBatiments
    ? getRsenvBuildingZones(rsenvBatiments, currentBuilding)
    : null;
  let zoneCountForThisBuilding = 0;
  const currentBuildingZones = Array.isArray(currentBuilding.Zone_Collection.Zone)
    ? currentBuilding.Zone_Collection.Zone
    : [currentBuilding.Zone_Collection.Zone];
  const currentBuildingSortie = batiment_sortie_c.find(
    (bat) => Number(bat.Index._text) == Number(currentBuilding.Index._text)
  );
  const zoneSortieC = Array.isArray(
    currentBuildingSortie.Sortie_Zone_C_Collection.Sortie_Zone_C
  )
    ? currentBuildingSortie.Sortie_Zone_C_Collection.Sortie_Zone_C
    : [currentBuildingSortie.Sortie_Zone_C_Collection.Sortie_Zone_C];

  for (let currentZone of currentBuildingZones) {
    totZonesCount += 1;
    zoneCountForThisBuilding += 1;

    checkIfZoneCountIsGood(totZonesCount, project, settings, setFile, alertCallback);

    const zoneSortie = zoneSortieC.find(
      (zone) => zone.Index._text == currentZone.Index._text
    );

    let groupe = Array.isArray(currentZone.Groupe_Collection.Groupe)
      ? _.cloneDeep(currentZone.Groupe_Collection.Groupe[0])
      : currentZone.Groupe_Collection.Groupe;
    const currentZoneUsage = currentZone.Usage._text;
    const buildingTypeId = getBuildingTypeId(
      isRE2020,
      buildingTypes,
      currentZoneUsage
    );

    if (Array.isArray(currentZone.Groupe_Collection.Groupe)) {
      let shab = Number(groupe.SHAB._text);
      let su = Number(isRE2020 ? groupe.SU._text : groupe.SURT._text);
      let scombles = isRE2020 ? Number(groupe.S_combles._text) : null;

      for (
        let index = 1;
        index < currentZone.Groupe_Collection.Groupe.length;
        index += 1
      ) {
        shab += Number(currentZone.Groupe_Collection.Groupe[index].SHAB._text);
        su += Number(
          isRE2020
            ? currentZone.Groupe_Collection.Groupe[index].SU._text
            : currentZone.Groupe_Collection.Groupe[index].SURT._text
        );

        if (isRE2020)
          scombles += Number(
            currentZone.Groupe_Collection.Groupe[index].S_combles._text
          );
      }

      groupe.SHAB._text = shab;

      if (isRE2020) {
        groupe.SU._text = su;
        groupe.S_combles._text = scombles;
      } else {
        groupe.SURT._text = su;
      }
    }

    let area = getZoneArea(
      isRE2020,
      groupe,
      currentRsenvBuildingZones
        ? currentRsenvBuildingZones[zoneCountForThisBuilding - 1]
        : null,
      zoneSortie,
      groupe.SHAB._text
    );

    const newZone = {
      id: getZoneId(currentZone, totZonesCount),
      name: currentZone.Name._text,
      BuildingType: buildingTypeId,
      BuildingTypeId: buildingTypeId.id,
      iniesBuildingTypeId: currentZoneUsage,
      logementInput: currentZone.NB_logement._text,
      area: area,
      srt: getZoneSrt(zoneSortie, isRE2020, buildingTypeId.id, area),
      nbLgt: currentZone.NB_logement._text,
      type: 'zone',
      zone_c: zoneSortie,
      IsConnectedToUrbanNetwork:
        groupe.Is_Reseau_Ch._text === '1' || groupe.Is_Reseau_Fr._text === '1',
      urbanConso: handleUrbanConso(groupe, currentBuilding, currentZone),
      OEefProdPvAnnuel: getPvProdAnnuel(isRE2020, zoneSortie, 'regular'),
      OEefProdPvAcAnnuel: getPvProdAnnuel(isRE2020, zoneSortie, 'AC'),
    };

    if (isRE2020) {
      let pathToBuildings = rsenv?.entree_projet?.batiment;

      newZone.menInput = currentZone.Nocc._text;
      newZone.comblesInput = groupe.S_combles._text;
      newZone.sCombles = groupe.S_combles._text;
      newZone.nbOccupants = currentZone.Nocc._text;
      newZone.mcCat = zoneSortie.O_Mccat._text || 0;
      newZone.groundExpansion = Number(
        extractGroundExpansion(newBat.flag, pathToBuildings)
      );
    } else {
      newZone.category = 'CE' + groupe.Categorie_Ce1_Ce2._text;

      if (rsenvBatiments) {
        newZone.nbOccupants = extractNbOccupantsEPCM(rsenv, newBat.flag);
        newZone.menInput = newZone.nbOccupants;

        let pathToBuildings = rsenv.data_comp.batiment;

        let nbParkingSpots = extractParkingSpotsEPCM(
          rsenv,
          newBat.flag,
          pathToBuildings
        );

        newZone.nbParkingSpotsSurfacePlu = Number(nbParkingSpots[0]);
        newZone.nbParkingSpotsUndergroundPlu = Number(nbParkingSpots[1]);
        newZone.nbParkingSpotsSurface = Number(nbParkingSpots[2]);
        newZone.nbParkingSpotsUnderground = Number(nbParkingSpots[3]);
        newZone.groundExpansion = Number(
          extractGroundExpansion(newBat.flag, pathToBuildings)
        );
      }
    }

    addContributeurs(currentRsenvBuildingZones, currentZone, newZone, totZonesCount);

    newBat.Zones.push(newZone);
  }
};

const getZoneId = (currentZone, totZonesCount) => {
  const zoneId = currentZone.Index._text || totZonesCount;

  return zoneId;
};

const checkIfZoneCountIsGood = (
  totZonesCount,
  project,
  settings,
  setFile,
  alertCallback
) => {
  const currProjectNbZones = settings
    ? project.zones.filter((zone) => zone.type === 'zone').length
    : 0;

  if (settings && totZonesCount > currProjectNbZones) {
    setFile(null);
    allZonesMatch = false;

    return alertCallback('Le nombre de zones doit être identique.', 'error');
  }
};

const addContributeurs = (
  currentRsenvBuildingZones,
  currentZone,
  newZone,
  totZonesCount
) => {
  let rsenvZone = null;

  if (currentRsenvBuildingZones) {
    if (currentRsenvBuildingZones.length === 1) {
      rsenvZone = currentRsenvBuildingZones[0];
      rsenvZone.index._text = currentZone.Index._text;
    } else {
      rsenvZone = currentRsenvBuildingZones.find(
        (zone) => zone.index._text == currentZone.Index._text
      );
    }
  } else {
    rsenvZone = null;
  }

  if (!rsenvZone) {
    rsenvZone = currentRsenvBuildingZones
      ? currentRsenvBuildingZones.find((zone) => zone.index._text == totZonesCount)
      : null;
  }

  if (rsenvZone) {
    newZone.contributeur = rsenvZone.contributeur;
  }
};

const loopOverBuildings = (
  isRE2020,
  rootObject,
  rsenvBatiments,
  project,
  settings,
  buildingTypes,
  setFile,
  alertCallback,
  rsenv
) => {
  let newBatCollection = [];
  const [batimentCollection, batiment_sortie_c] = exctractBuildings(rootObject);

  for (let [buildingIndex, currentBuilding] of batimentCollection.entries()) {
    checkIfEmptyBuilding(currentBuilding, buildingIndex, alertCallback);

    const newBat = {
      flag: buildingIndex,
      name: currentBuilding.Name._text,
      type: 'building',
      Zones: [],
    };

    loopOverZones(
      isRE2020,
      currentBuilding,
      batiment_sortie_c,
      rsenvBatiments,
      newBat,
      project,
      settings,
      buildingTypes,
      setFile,
      alertCallback,
      rsenv
    );

    newBatCollection.push(newBat);
  }

  return newBatCollection;
};

const checkIfEmptyBuilding = (building, buildingIndex, alertCallback) => {
  if (!building.Zone_Collection || !building.Zone_Collection.Zone) {
    return alertCallback(
      'Fichier incorrect : batiment ' + buildingIndex + 1 + ' : pas de zones'
    );
  }
};

const getRsenvBuildingZones = (rsenvBatiments, currentBuilding) => {
  let currentRSENVBuilding = null;

  if (rsenvBatiments.length === 1) {
    currentRSENVBuilding = rsenvBatiments[0];
    currentRSENVBuilding.index._text = currentBuilding.Index._text;
  } else {
    currentRSENVBuilding = rsenvBatiments.find(
      (building) => building.index._text == currentBuilding.Index._text
    );
  }

  if (currentRSENVBuilding) {
    return Array.isArray(currentRSENVBuilding?.zone)
      ? currentRSENVBuilding.zone
      : [currentRSENVBuilding.zone];
  }

  return null;
};

const exctractBuildings = (rootItem) => {
  if (rootItem.RSET) rootItem = rootItem.RSET;

  const batimentCollection = Array.isArray(
    rootItem.Entree_Projet.Batiment_Collection.Batiment
  )
    ? rootItem.Entree_Projet.Batiment_Collection.Batiment
    : [rootItem.Entree_Projet.Batiment_Collection.Batiment];

  const batiment_sortie_c = Array.isArray(
    rootItem.Sortie_Projet.Sortie_Batiment_C_Collection.Sortie_Batiment_C
  )
    ? rootItem.Sortie_Projet.Sortie_Batiment_C_Collection.Sortie_Batiment_C
    : [rootItem.Sortie_Projet.Sortie_Batiment_C_Collection.Sortie_Batiment_C];

  return [batimentCollection, batiment_sortie_c];
};

const getNewDataComp = (rootItem, isRE2020) => {
  if (!isRE2020) return null;

  let datePaGaz = '2022-01-01'; // Default value

  if (rootItem.Datas_Comp.donnees_generales.operation.date_obtention_pa_gaz)
    datePaGaz =
      rootItem.Datas_Comp.donnees_generales.operation.date_obtention_pa_gaz._text;

  return { paGazObtentionDate: datePaGaz };
};

const matchZones = (newZone, project, isRE2020) => {
  let message = null;
  const projectZones = project.zones.filter((zone) => zone.type === 'zone');
  let attributesToMatch = isRE2020 ? attributesToMatchRE2020 : attributesToMatchEPCM;

  if (newZone.area === '0') {
    message = `La zone : ${newZone.name} ne peut pas être reliée à votre projet : sa surface est nulle.`;

    return [false, message];
  }

  let matchingZones = projectZones.filter((zone) => {
    zone.parentName = project.zones.find(
      (projectZone) => projectZone.id === zone.ParentId
    ).name;

    const newZoneRefArea = isRE2020 ? Number(newZone.area) : Number(newZone.srt);
    const oldZoneRefArea = isRE2020 ? Number(zone.area) : Number(zone.srt);

    const surfaceDiff = Math.abs(newZoneRefArea - oldZoneRefArea);

    if (surfaceDiff > 1) {
      if (isRE2020) {
        message = `La zone : ${newZone.name} ne peut pas être reliée à votre projet : les surfaces de référence ne correspondent pas (aucune zone n'a une surface de référence de ${newZoneRefArea}).`;
      } else {
        message = `La zone : ${newZone.name} ne peut pas être reliée à votre projet : les surfaces thermiques ne correspondent pas (aucune zone n'a une surface thermique de ${newZoneRefArea}).`;
      }

      return false;
    } else {
      return true;
    }
  });

  matchingZones = matchingZones.filter((zone) => {
    for (const attribute of attributesToMatch) {
      const newAttributeValue = Number(newZone[attribute.attr]);
      const oldAttributeValue = Number(zone[attribute.attr]);

      if (newAttributeValue !== oldAttributeValue) {
        message = `La zone : ${newZone.name} ne peut pas être reliée à votre projet : la balise ${attribute.name} est différente (${newAttributeValue} vs ${oldAttributeValue}).`;

        return false;
      }
    }

    return true;
  });

  if (!matchingZones || matchingZones.length === 0) {
    if (!message) {
      message = `La zone : ${newZone.name} ne peut pas être reliée à votre projet : aucune zone correspondante n'a été trouvée.`;
    }

    return [false, message];
  }

  matchingZonesMap[newZone.id] = {
    newZone,
    matchingZones,
    selectedMatchingZone: matchingZones[0],
  };

  return [true, null];
};

const handleUrbanConso = (groupe, currentBuilding) => {
  if (!currentBuilding.Type_Reseau) return null;

  const typeReseau = currentBuilding.Type_Reseau._text;

  const conso = {
    typeReseau,
  };

  if (groupe.Is_Reseau_Ch._text === '1') {
    conso.contenuCo2Ch = Number(groupe.Contenu_CO2_Reseau_Ch._text);
  }

  if (groupe.Is_Reseau_Fr._text === '1') {
    conso.contenuCo2Fr = Number(groupe.Contenu_CO2_Reseau_Fr._text);
  }

  return conso;
};

export default rsetImport;
