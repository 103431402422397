import {
  CompanyDTO,
  ProjectDTO,
  StructuredCompanyDTO,
} from '../../../models/ProjectDTO';
import { Console } from 'console';
import { PathDTO } from '../../../models/ProjectsDTO';
import { StateDTO } from '../../../models/GeneralDTO';
import { get } from 'lodash';
import { getProjectsByFolderResponse } from '../../../../apiCallFunctions/project/project';
import { isBundle } from 'typescript';
import {
  updateArianeCompanyValues,
  updateArianeFolderValues,
  updateFormValue,
  updateInitProjectsListMonitoring,
} from '../../../../actions/projects';
import { updateBundleValue } from '../../../../actions/bundle';
import { updateProjectsListMonitoring } from '../../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';

const useCase = () => {
  const { allCompanies, parentCompanies, projectsListMonitoring } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { isBundleScreen, folderSheetId } = useSelector(
    (state: StateDTO) => state.bundle
  );
  const { arianeCompany, arianeFolder, foldersData, mappingProject } =
    projectsListMonitoring;
  const { selectedPathId } = foldersData;

  const dispatch = useDispatch();

  // console.log(foldersData);
  const initFolders = (foldersData) => {
    const parentsPaths = getParentPaths(foldersData);

    dispatch(
      updateArianeFolderValues([
        {
          name: 'isBreadcrumbFolderVisible',
          value: true,
        },
        {
          name: 'selectedPathId',
          value: null,
        },
        {
          name: 'selectedPaths',
          value: [],
        },
        {
          name: 'isExistChildrenPaths',
          value: true,
        },
        {
          name: 'childrenPaths',
          value: parentsPaths,
        },
      ])
    );
  };

  const addFolder = (foldersData: PathDTO[], selectedPathId: number) => {
    // console.log('addFolderUseCase');
    // console.log('flodersData',
    //   foldersData,
    //   'PathId',
    //   selectedPathId);

    const newChildrenFolders = getChildrenFolders(selectedPathId, foldersData);

    dispatch(
      updateArianeFolderValues([
        {
          name: 'isExistChildrenPaths',
          value: true,
        },
        {
          name: 'childrenPaths',
          value: newChildrenFolders,
        },
      ])
    );
  };

  const initBreadCrumb = () => {
    dispatch(updateInitProjectsListMonitoring(true));

    const isMultipleCompanies = allCompanies.length > 1;
    // verifier si plusieurs companies
    // si non pas d'affichage companies donc
    //isvisible: false, childCompanies:[], selecTedCompany = la companyId, selectedCompanies: undefined, isExistChildrenCompanyes: false, isMultipleCompanies: false
    // faire les choses en relation avec les folders
    // getProjetsCompanyId

    // is plusieurs companies:

    if (isMultipleCompanies) {
      dispatch(updateProjectsListMonitoring('mappingProject', null));
      dispatch(
        updateArianeCompanyValues([
          {
            name: 'isBreadcrumbCompanyVisible',
            value: true,
          },
          {
            name: 'isExistChildrenCompanies',
            value: true,
          },
          {
            name: 'isMultipleCompanies',
            value: true,
          },
          {
            name: 'childrenCompanies',
            value: parentCompanies,
          },
        ])
      );
    }

    if (!isMultipleCompanies) {
      dispatch(updateBundleValue('companyId', allCompanies[0].id));
      dispatch(
        updateArianeCompanyValues([
          {
            name: 'selectedCompanyId',
            value: allCompanies[0].id,
          },
          {
            name: 'selectedCompanies',
            value: allCompanies,
          },
          {
            name: 'isBreadcrumbCompanyVisible',
            value: true,
          },
          {
            name: 'isExistChildrenCompanies',
            value: false,
          },
          {
            name: 'isMultipleCompanies',
            value: false,
          },
          {
            name: 'childrenCompanies',
            value: [],
          },
        ])
      );
      dispatch(
        updateArianeFolderValues([
          {
            name: 'isBreadcrumbFolderVisible',
            value: true,
          },

          //TODO TO DELETE BUT CALL INSTEAD
          {
            name: 'isExistChildrenPaths',
            value: true,
          },
        ])
      );
    }

    // console.log('initBreadCrumb');
  };

  // action functions
  const selectDropDownCompany = (
    companyId: number,
    company: StructuredCompanyDTO
  ) => {
    resetProjectsListMonitoring();
    // console.log('company dropdown', company)
    dispatch(updateProjectsListMonitoring('dropDownInput', company.title));

    const childrenCompanies = getChildrenCompanies(companyId);
    const isExistChildrenCompanies = childrenCompanies.length > 0;

    const selectedCompanies = getCompanyHierarchy(companyId);
    // console.log('selectedCompanies', selectedCompanies)
    // console.log(childrenCompanies)
    // console.log(isExistChildrenCompanies)

    //TODO TO COMPLETE
    dispatch(
      updateArianeCompanyValues([
        {
          name: 'selectedCompanies',
          value: selectedCompanies,
        },
        {
          name: 'selectedCompanyId',
          value: companyId,
        },
        {
          name: 'isBreadcrumbCompanyVisible',
          value: true,
        },
        {
          name: 'isExistChildrenCompanies',
          value: isExistChildrenCompanies,
        },
        {
          name: 'isMultipleCompanies',
          value: true,
        },
        {
          name: 'childrenCompanies',
          value: childrenCompanies,
        },
      ])
    );

    // foldersData call + filled
    //
  };

  const selectPathOnSubmenu = (path: PathDTO) => {
    const selectedPaths = [...arianeFolder.selectedPaths, path];
    const childrenPaths = getChildrenFolders(path.id, foldersData);
    const isExistChildrenPaths = childrenPaths.length > 0;

    if (isBundleScreen) {
      dispatch(updateBundleValue('folderSheetId', path.id));
    }

    dispatch(updateFormValue('projectInput', ''));
    dispatch(
      updateArianeFolderValues([
        {
          name: 'selectedPathId',
          value: path.id,
        },
        {
          name: 'selectedPaths',
          value: selectedPaths,
        },
        {
          name: 'isExistChildrenPaths',
          value: isExistChildrenPaths,
        },
        {
          name: 'childrenPaths',
          value: childrenPaths,
        },
      ])
    );
  };

  const selectCompanyOnSubmenu = (company: CompanyDTO) => {
    // ajouter selectedCompany dans selectedCompany
    // ajouter selected company dans  selectedCompanies
    // verifier si elle a des enfants
    // mettre à jour childrenCompanies
    // replir isExistChildrenCompanies
    // si pas d'enfant: arianFolderIsBreadcrumbFolderVisible a true
    // faire les choses / folders: getProjects, folder, ...

    const selectedCompanies = [...arianeCompany.selectedCompanies, company];

    const childrenCompanies = getChildrenCompanies(company.id);
    const isExistChildrenCompanies = childrenCompanies.length > 0;

    dispatch(updateFormValue('projectInput', ''));
    dispatch(updateProjectsListMonitoring('dropDownInput', company.name));
    dispatch(
      updateArianeCompanyValues([
        {
          name: 'selectedCompanyId',
          value: company.id,
        },
        {
          name: 'selectedCompanies',
          value: selectedCompanies,
        },
        {
          name: 'isExistChildrenCompanies',
          value: isExistChildrenCompanies,
        },
        {
          name: 'childrenCompanies',
          value: childrenCompanies,
        },
      ])
    );
    if (isBundleScreen) {
      dispatch(updateBundleValue('companyId', company.id));
    }

    dispatch(
      updateArianeFolderValues([
        {
          name: 'isBreadcrumbFolderVisible',
          value: true,
        },
        //TODO TO DELETE BUT CALL INSTEAD
        {
          name: 'isExistChildrenPaths',
          value: true,
        },
      ])
    );
  };

  const selectCompanyOnBreadCrumb = (company: CompanyDTO) => {
    // mettre selectedCompany à jour
    // mettre selectedCompanies à jour: comment?
    const selectedCompanies = arianeCompany.selectedCompanies;
    const selectedCompanyIndex = getCompanyIndex(company, selectedCompanies);
    const newSelectedCompanies = selectedCompanies.slice(
      0,
      selectedCompanyIndex + 1
    );
    const childrenCompanies = getChildrenCompanies(company.id);
    const isExistChildrenCompanies = childrenCompanies.length > 0;
    const isFolderDataFilled = hasFolderData();

    if (isBundleScreen && folderSheetId) {
      dispatch(updateBundleValue('folderSheetId', null));
    }

    // mettre à jour isExistChildrenCompanies
    // mettre à jour childrenCompanies
    // Si a de senfants =>  isBreadCompanyvisible !isBreadFolderVisible
    dispatch(
      updateArianeCompanyValues([
        {
          name: 'selectedCompanyId',
          value: company.id,
        },
        {
          name: 'selectedCompanies',
          value: newSelectedCompanies,
        },
        {
          name: 'isExistChildrenCompanies',
          value: isExistChildrenCompanies,
        },
        {
          name: 'childrenCompanies',
          value: childrenCompanies,
        },
        {
          name: 'isBreadcrumbFolderVisible',
          value: isExistChildrenCompanies,
        },
      ])
    );
    dispatch(updateFormValue('projectInput', ''));
    dispatch(updateProjectsListMonitoring('dropDownInput', company.name));
    if (isExistChildrenCompanies) {
      dispatch(updateProjectsListMonitoring('mappingProject', null));
      dispatch(
        updateArianeFolderValues([
          {
            name: 'isBreadcrumbFolderVisible',
            value: false,
          },
          {
            name: 'selectedPaths',
            value: [],
          },
          {
            name: 'selectedPathId',
            value: null,
          },
          //TODO TO DELETE BUT CALL INSTEAD
          {
            name: 'isExistChildrenPaths',
            value: false,
          },
          {
            name: 'childrenPaths',
            value: [],
          },
        ])
      );
      dispatch(updateFormValue('projectsList', []));
      dispatch(updateFormValue('projects', []));
    }
    if (!isExistChildrenCompanies) {
      dispatch(updateBundleValue('companyId', company.id));
      dispatch(
        updateArianeFolderValues([
          {
            name: 'isExistChildrenPaths',
            value: isFolderDataFilled,
          },
          {
            name: 'selectedPaths',
            value: [],
          },
          {
            name: 'selectedPathId',
            value: null,
          },
          {
            name: 'childrenPaths',
            value: getParentPaths(foldersData),
          },
        ])
      );
    }
  };

  const selectPathOnBreadCrumb = (path: PathDTO) => {
    const selectedPaths = arianeFolder.selectedPaths;

    const selectedFolderIndex = getFolderIndex(path, selectedPaths);
    const newSelectedPaths = selectedPaths.slice(0, selectedFolderIndex + 1);
    const childrenFolders = getChildrenFolders(path.id, foldersData);
    const isExistChildrenFolders = childrenFolders.length > 0;

    if (isBundleScreen) {
      dispatch(updateBundleValue('folderSheetId', path.id));
    }

    dispatch(updateFormValue('projectInput', ''));
    dispatch(
      updateArianeFolderValues([
        {
          name: 'selectedPathId',
          value: path.id,
        },
        {
          name: 'selectedPaths',
          value: newSelectedPaths,
        },
        {
          name: 'isExistChildrenPaths',
          value: isExistChildrenFolders,
        },
        {
          name: 'childrenPaths',
          value: childrenFolders,
        },
      ])
    );
  };

  interface selectHomeBreadCrumbArgs {
    needToReset?: boolean;
  }

  const selectHomeBreadCrumb = ({
    needToReset = false,
  }: selectHomeBreadCrumbArgs = {}): void => {
    const isMultipleCompanies = allCompanies.length > 1;
    const parentsPaths = getParentPaths(foldersData);

    if (needToReset) {
      dispatch(
        updateFormValue('projectsListMonitoring', {
          ...projectsListMonitoring,
          isInit: false,
          foldersData: [],
          isCreateFolder: false,
          inputCreateFolder: '',
          mappingProject: undefined,
          isMultipleCompanies: isMultipleCompanies,
          arianeCompany: {
            isBreadcrumbCompanyVisible: false,
            selectedCompanyId: undefined,
            isExistChildrenCompanies: undefined,
            isMultipleCompanies: undefined,
            childrenCompanies: [],
            selectedCompanies: [],
          },
          arianeFolder: {
            isBreadcrumbFolderVisible: false,
            selectedPathId: undefined,
            isExistChildrenPaths: true,
            childrenPaths: [],
            selectedPaths: [],
          },
        })
      );
      dispatch(updateBundleValue('companyId', undefined));
      dispatch(updateBundleValue('folderSheetId', null));
      dispatch(updateBundleValue('bundleSearchInput', ''));
    }

    if (!!arianeCompany.selectedCompanyId) {
      dispatch(updateProjectsListMonitoring('mappingProject', null));
      dispatch(
        updateArianeCompanyValues([
          {
            name: 'selectedCompanyId',
            value: undefined,
          },
          {
            name: 'selectedCompanies',
            value: [],
          },
          {
            name: 'isExistChildrenCompanies',
            value: true,
          },
          {
            name: 'childrenCompanies',
            value: parentCompanies,
          },
        ])
      );
      dispatch(
        updateArianeFolderValues([
          {
            name: 'isBreadcrumbFolderVisible',
            value: false,
          },
          {
            name: 'selectedPathId',
            value: null,
          },
          {
            name: 'selectedPaths',
            value: [],
          },
          {
            name: 'isExistChildrenPaths',
            value: true,
          },
          {
            name: 'childrenPaths',
            value: [],
          },
        ])
      );
      dispatch(updateFormValue('projectsList', []));
      dispatch(updateFormValue('projects', []));
    }
    if (!isMultipleCompanies) {
      dispatch(updateBundleValue('companyId', allCompanies[0].id));
      dispatch(
        updateArianeFolderValues([
          {
            name: 'isBreadcrumbFolderVisible',
            value: true,
          },
          {
            name: 'selectedPathId',
            value: null,
          },
          {
            name: 'selectedPaths',
            value: [],
          },
          {
            name: 'childrenPaths',
            value: parentsPaths,
          },
          {
            name: 'isExistChildrenPaths',
            value: true,
          },
        ])
      );
    }
    dispatch(updateProjectsListMonitoring('dropDownInput', undefined));

    dispatch(updateFormValue('projectInput', ''));
  };

  //helper functions
  const getProjectsByFolder = (data: getProjectsByFolderResponse) => {
    if (!!arianeCompany.selectedCompanyId) {
      const newMappingProject = data.pages[0].rows.find(
        (project) => project.ProjectTypeId === 9
      );

      dispatch(updateProjectsListMonitoring('mappingProject', newMappingProject));
    }

    dispatch(updateFormValue('projects', data.pages));
    // console.log('response pour les projects by folder',
    // projectsWithoutMappingRef
    // );
  };

  const getProjectsBySearch = (data: getProjectsByFolderResponse) => {
    // console.log('data recive in useCase getProjectsBySearch',
    //   data);

    // const projectsWithoutMappingRef = data.rows.filter((project) => project?.ProjectType?.name !== "mappingRef");

    // dispatch(updateFormValue('projects',
    //   projectsWithoutMappingRef));
    //TODO change getProjectsByFolderResponse
    dispatch(updateFormValue('projectsList', data.pages));
    // dispatch(updateFormValue('projects',
    //   data.pages));
  };

  const resetProjectsListMonitoring = () => {
    const isMultipleCompanies = allCompanies.length > 1;

    dispatch(
      updateFormValue('projectsListMonitoring', {
        ...projectsListMonitoring,
        isInit: true,
        foldersData: [],
        isCreateFolder: false,
        inputCreateFolder: '',
        mappingProject: undefined,
        isMultipleCompanies: isMultipleCompanies,
        arianeCompany: {
          isBreadcrumbCompanyVisible: false,
          selectedCompanyId: undefined,
          isExistChildrenCompanies: undefined,
          isMultipleCompanies: undefined,
          childrenCompanies: [],
          selectedCompanies: [],
        },
        arianeFolder: {
          isBreadcrumbFolderVisible: false,
          selectedPathId: undefined,
          isExistChildrenPaths: true,
          childrenPaths: [],
          selectedPaths: [],
        },
      })
    );

    dispatch(updateFormValue('projectInput', ''));
    dispatch(updateFormValue('projectsList', []));
    dispatch(updateFormValue('projects', []));
  };

  const generateRecursiveHierarchy = (pathProject) => {
    const children = pathProject.find((path) => path.depth === 0);
    let hierarchyArray = [children];
    const getHierarchy = (children) => {
      const parent = pathProject.find((path) => path.depth === children.depth + 1);

      if (!parent) {
        return;
      }
      hierarchyArray.unshift(parent);
      getHierarchy(parent);
    };

    getHierarchy(children);

    // const hierarchy = '/' + names.reverse().join('/');

    const hierarchy = '/' + hierarchyArray.map((path) => path.name).join('/');

    return hierarchy;
  };

  const getUrl = (project: ProjectDTO): string => {
    const { pathProject } = project;

    if (pathProject.length === 0) {
      return '';
    }

    // console.log(generateRecursiveHierarchy(pathProject));

    return generateRecursiveHierarchy(pathProject);
  };

  const hasFolderData = () => {
    return foldersData.length > 0;
  };

  const getChildrenCompanies = (companyId: number) => {
    return allCompanies.filter((company) => company.ParentNodeId === companyId);
  };

  const getParentPaths = (foldersData: PathDTO[]): PathDTO[] => {
    // console.log('foldersData',
    //   foldersData);

    return foldersData
      .filter((folder) => !folder.parentId)
      .sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
  };

  const getChildrenFolders = (pathId: number, foldersData: PathDTO[]): PathDTO[] => {
    // var _ = require('lodash');
    // const test = _.cloneDeep(foldersData);

    // console.log('copy foldersData before getChildrens',
    //   test);

    const childrenFolders = foldersData.filter(
      (folder) => folder.parentId === pathId
    );

    childrenFolders.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return childrenFolders;
  };

  const getCompanyHierarchy = (selectedCompanyId: number): CompanyDTO[] => {
    const hierarchy: CompanyDTO[] = [];
    const selectedCompany = allCompanies.find(
      (company) => company.id === selectedCompanyId
    );

    if (selectedCompany) {
      hierarchy.push(selectedCompany);

      let parentCompanyId = selectedCompany.ParentNodeId;

      while (parentCompanyId !== null) {
        const parentCompany = allCompanies.find(
          (company) => company.id === parentCompanyId
        );

        if (parentCompany) {
          hierarchy.unshift(parentCompany);
          parentCompanyId = parentCompany.ParentNodeId;
        } else {
          break;
        }
      }
    }

    return hierarchy;
  };

  const getCompanyIndex = (selectedCompany: CompanyDTO, companies: CompanyDTO[]) => {
    return companies.findIndex((company) => company.id === selectedCompany.id);
  };
  const getFolderIndex = (selectedFolder: PathDTO, Folders: PathDTO[]) => {
    return Folders.findIndex((folder) => folder.id === selectedFolder.id);
  };

  return {
    initBreadCrumb,
    selectCompanyOnSubmenu,
    selectCompanyOnBreadCrumb,
    selectHomeBreadCrumb,
    initFolders,
    selectPathOnSubmenu,
    selectPathOnBreadCrumb,
    getProjectsByFolder,
    getProjectsBySearch,
    selectDropDownCompany,
    addFolder,
    getUrl,
  };
};

export default useCase;
