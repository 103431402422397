import '../Garbage/garbage.scss';
import { Button } from '@photocarbone/nooco-ui';
import { NewActionDTO } from '../../../../models/ExploitationDTO';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import SelectUi from '@mui/material/Select';
import TextField from '@material-ui/core/TextField';
import add from '../../../../../assets/images/exploitation/add.png';
import bus from '../../../../../assets/images/exploitation/transports/bus.png';
import metro from '../../../../../assets/images/exploitation/transports/metro.png';
import plane from '../../../../../assets/images/exploitation/transports/plane.png';
import train from '../../../../../assets/images/exploitation/transports/train.png';
import transportIcon from '../../../../../assets/images/exploitation/transportIcon.png';
import velo from '../../../../../assets/images/exploitation/transports/velo.png';
import voiture from '../../../../../assets/images/exploitation/transports/voiture.png';

import './transport.scss';
import { Autocomplete } from '@mui/material';
import { StateDTO } from '../../../../models/GeneralDTO';
import { getCaracsCombos } from '../../../../../actions/exploitation';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../../../services/API';
import RangePickerCustom from '../../../../components/RangePicker';
import { useTranslation } from 'react-i18next';

interface TransportProps {
  transportChoice: string;
  updateExploitationValue: Function;
  newAction: NewActionDTO;
  resetAddAction: Function;
  createAction: Function;
}

let api = new API();

const Transport = ({
  transportChoice,
  updateExploitationValue,
  newAction,
  resetAddAction,
  createAction,
}: TransportProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { allGroups } = useSelector((state: StateDTO) => state.projects);
  const { transportLines } = useSelector((state: StateDTO) => state.exploitation);

  useEffect(() => {
    return () => {
      updateExploitationValue('transportLines', [
        {
          id: 0,
          MasterDataEquipmentId: null,
          AllotmentNode: null,
          quantity: null,
          type: null,
          carburant: null,
          actionType: 'transport',
        },
      ]);
    };
  }, []);

  const checkValidationForm = () => {
    if (
      transportLines.find(
        (el) =>
          !el.MasterDataEquipmentId ||
          !el.quantity ||
          (el.caracs &&
            el.caracs.caracs?.length > 0 &&
            el.caracs.caracs?.find((carac) => !carac.value)) ||
          !newAction.date
      )
    ) {
      return true;
    }

    return false;
  };

  const callGetCaracs = (defaultMasterDataEquipmentId, caracs, line) => {
    let allot = allGroups.flatAllotments.find(
      (el) => el.MasterDataEquipmentId === defaultMasterDataEquipmentId
    );

    api
      .getEquipmentCaracListById(allot.id)
      .then((response) => {
        caracs = {
          ...response.data,
          caracs: response.data.caracs.map((el) => ({
            name: el.name,
            id: el.id,
          })),
        };

        if (line) {
          handleEditTransport({
            ...line,
            AllotmentNode: allot,
            MasterDataEquipmentId: defaultMasterDataEquipmentId,
            caracs,
          });

          return;
        }

        if (
          defaultMasterDataEquipmentId &&
          transportLines.find((el) => !el.MasterDataEquipmentId)
        ) {
          handleEditTransport({
            ...transportLines.find((el) => !el.MasterDataEquipmentId),
            AllotmentNode: allot,
            MasterDataEquipmentId: defaultMasterDataEquipmentId,
            caracs,
          });
        } else {
          const newLines = [
            ...transportLines,
            {
              id: Math.max(...transportLines.map((el) => el.id)) + 1,
              MasterDataEquipmentId: defaultMasterDataEquipmentId,
              AllotmentNode: allot,
              caracs,
              quantity: null,
              unit: 'km',
              actionType: 'transport',
            },
          ];

          updateExploitationValue('transportLines', newLines);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const addNewTransport = (defaultMasterDataEquipmentId: number | null): void => {
    let caracs = [];

    if (defaultMasterDataEquipmentId) {
      // dispatch(getCaracsCombos());
      callGetCaracs(defaultMasterDataEquipmentId, caracs, null);
    } else {
      const newLines = [
        ...transportLines,
        {
          id: Math.max(...transportLines.map((el) => el.id)) + 1,
          MasterDataEquipmentId: null,
          AllotmentNode: null,
          caracs,
          quantity: null,
          unit: 'km',
          actionType: 'transport',
        },
      ];

      updateExploitationValue('transportLines', newLines);
    }
  };

  const handleEditTransport = (newValue: {}): void => {
    const newLines = transportLines.map((el: {}) => {
      if (el.id === newValue.id) {
        return newValue;
      }

      return el;
    });

    updateExploitationValue('transportLines', newLines);
  };

  return (
    <>
      <div className="transport-form">
        <div className="transport-header">
          <img src={transportIcon} alt="" />
          <h4>
            {t('APP.TRANSPORT.INDEX.PH_542CCF', { defaultValue: 'Transports' })}
          </h4>
        </div>
        <div className="transport-infos">
          <div style={{ width: '80%', margin: 'auto' }}>
            <div style={{ width: '30%' }}>
              <RangePickerCustom
                label={t('APP.TRANSPORT.INDEX.PH_FF83FF', {
                  defaultValue: 'Période concernée',
                })}
              />
            </div>
          </div>
          <div className="transport-infos-top">
            <div
              className="garbage-item"
              onClick={() =>
                addNewTransport(
                  allGroups.type.find((el) => el.name === 'Véhicule routier').id
                )
              }
            >
              <img src={voiture} alt="" />
              <p>
                {t('APP.TRANSPORT.INDEX.PH_1355EE', {
                  defaultValue: 'Véhicule routier',
                })}
              </p>
            </div>
            <div
              className="garbage-item"
              onClick={() =>
                addNewTransport(
                  allGroups.type.find((el) => el.name === 'Métro / Tramway').id
                )
              }
            >
              <img src={metro} alt="" />
              <p>
                {t('APP.TRANSPORT.INDEX.PH_2BE049', {
                  defaultValue: 'Métro / Tramway',
                })}
              </p>
            </div>
            <div
              className="garbage-item"
              onClick={() =>
                addNewTransport(allGroups.type.find((el) => el.name === 'Vélo').id)
              }
            >
              <img src={velo} alt="" />
              <p>{t('APP.TRANSPORT.INDEX.PH_01F8CE', { defaultValue: 'Vélo' })}</p>
            </div>
            <div
              className="garbage-item"
              onClick={() =>
                addNewTransport(
                  allGroups.type.find((el) => el.name === 'Autobus / Autocar').id
                )
              }
            >
              <img src={bus} alt="" />
              <p>
                {t('APP.TRANSPORT.INDEX.PH_45D5E7', {
                  defaultValue: 'Autobus / Autocar',
                })}
              </p>
            </div>
            <div
              className="garbage-item"
              onClick={() =>
                addNewTransport(allGroups.type.find((el) => el.name === 'Train').id)
              }
            >
              <img src={train} alt="" />
              <p>{t('APP.TRANSPORT.INDEX.PH_7488F3', { defaultValue: 'Train' })}</p>
            </div>
            <div
              className="garbage-item"
              onClick={() =>
                addNewTransport(allGroups.type.find((el) => el.name === 'Avion').id)
              }
            >
              <img src={plane} alt="" />
              <p>{t('APP.TRANSPORT.INDEX.PH_9AEB45', { defaultValue: 'Avion' })}</p>
            </div>
          </div>
          <div className="garbage-add" style={{ width: '80%', margin: 'auto' }}>
            <div className="garbage-lines-header">
              <p style={{ width: '27%', paddingRight: '30px' }}>
                {t('APP.TRANSPORT.INDEX.PH_AB0B3E', {
                  defaultValue: ' Type de transports',
                })}
              </p>
              <p style={{ width: '18%' }}>
                {t('APP.TRANSPORT.INDEX.PH_5DAF87', {
                  defaultValue: 'Quantité',
                })}
              </p>
              <p style={{ width: '10%' }}>
                {t('APP.TRANSPORT.INDEX.PH_7732B5', { defaultValue: 'Unité' })}
              </p>
              <p style={{ width: '17%' }}>
                {t('APP.TRANSPORT.INDEX.PH_F58F5F', { defaultValue: 'Type' })}
              </p>
              <p style={{ width: '17%' }}>
                {t('APP.TRANSPORT.INDEX.PH_194B1D', {
                  defaultValue: 'Carburant',
                })}
              </p>
              <p style={{ width: '3%' }}></p>
            </div>
            <div className="garbage-lines">
              {transportLines.map((el, i) => (
                <div className="garbage-line" key={i}>
                  <div style={{ position: 'absolute', left: '3px' }}>
                    {i === transportLines.length - 1 && (
                      <img
                        onClick={() => addNewTransport(null)}
                        style={{
                          width: '25px',
                          height: '25px',
                          marginTop: '10px',
                          marginRight: '20px',
                          cursor: 'pointer',
                        }}
                        src={add}
                      />
                    )}
                  </div>
                  <div style={{ width: '27%' }}>
                    <Autocomplete
                      value={allGroups.type.find(
                        (ele) => ele.id === el.MasterDataEquipmentId
                      )}
                      key={el.MasterDataEquipmentId}
                      id="checkboxes-tags-demo"
                      options={allGroups.type.filter(
                        (el) => el.fonction === 'Transports' && !el.isObsolete
                      )}
                      noOptionsText={t('APP.TRANSPORT.INDEX.PH_8C8C27', {
                        defaultValue: 'Aucun résultat trouvé',
                      })}
                      onChange={(event, newValue) => {
                        let caracs = [];

                        if (newValue) {
                          callGetCaracs(newValue.id, [], el);
                        } else {
                          handleEditTransport({
                            ...el,
                            MasterDataEquipmentId: null,
                            caracs,
                          });
                        }
                      }}
                      size={'small'}
                      getOptionLabel={(option) => option.name || ''}
                      renderInput={(params) => (
                        <TextField
                          style={{ borderRadius: '10px' }}
                          {...params}
                          placeholder={t('APP.TRANSPORT.INDEX.PH_41253A', {
                            defaultValue: 'Ajouter un transport',
                          })}
                          variant="outlined"
                          className="researchBar"
                        />
                      )}
                    />
                  </div>
                  <div style={{ width: '18%' }}>
                    <TextField
                      value={el.quantity}
                      onChange={(e) =>
                        handleEditTransport({
                          ...el,
                          quantity: Number(e.target.value),
                        })
                      }
                      type="number"
                      style={{ width: '100%' }}
                      placeholder={t('APP.TRANSPORT.INDEX.PH_8AC66A', {
                        defaultValue: 'Quantité',
                      })}
                      variant="outlined"
                      className="input-boarding researchBar"
                    />
                  </div>
                  <div style={{ width: '10%' }}>
                    <p
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      km
                    </p>
                  </div>
                  {el.caracs &&
                    el.caracs.caracs &&
                    el.caracs.caracs
                      .sort((a, b) => b.name.localeCompare(a.name))
                      .map((carac, i) => {
                        let caracListOptions = el.caracs.caracCombos.valueList;

                        if (carac.name === 'Motorisation') {
                          if (
                            el.caracs.caracs.find((myCar) => myCar.name === 'Type')
                              .value
                          ) {
                            caracListOptions = el.caracs.caracCombos.valueList.find(
                              (combo) =>
                                carac.name === combo.name &&
                                combo.value ===
                                  el.caracs.caracs.find(
                                    (myCar) => myCar.name === 'Type'
                                  )?.value[0]
                            )?.valueList;
                          } else {
                            caracListOptions = [];
                          }
                        }

                        return (
                          <div style={{ width: '17%' }} key={carac.name}>
                            <SelectUi
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              defaultValue={carac.value && carac.value[0]}
                              onChange={(e) =>
                                handleEditTransport({
                                  ...el,
                                  caracs: {
                                    ...el.caracs,
                                    caracs: [
                                      ...el.caracs.caracs.filter(
                                        (car) => car.name !== carac.name
                                      ),
                                      { ...carac, value: [e.target.value] },
                                    ],
                                  },
                                })
                              }
                              disabled={caracListOptions.length === 0}
                              style={{ width: '100%', height: '40px' }}
                              placeholder="  -"
                              variant="outlined"
                              className="input-boarding researchBar"
                            >
                              {caracListOptions?.map((value, i) => (
                                <MenuItem
                                  value={value.value}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  key={i}
                                >
                                  {value.value}
                                </MenuItem>
                              ))}
                            </SelectUi>
                          </div>
                        );
                      })}
                  {el.caracs?.caracs && el.caracs?.caracs.length === 1 && (
                    <div style={{ width: '17%' }}></div>
                  )}
                  {(!el.caracs?.caracs || el.caracs?.caracs.length === 0) && (
                    <>
                      <div style={{ width: '17%' }}></div>
                      <div style={{ width: '17%' }}></div>
                    </>
                  )}

                  <div
                    style={{
                      width: '2%',
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '10px',
                    }}
                  >
                    <DeleteIcon
                      onClick={() =>
                        updateExploitationValue(
                          'transportLines',
                          transportLines.filter((ele) => ele.id !== el.id)
                        )
                      }
                      style={{
                        display: transportLines.length > 1 ? 'block' : 'none',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', marginLeft: 'auto', width: '15%' }}>
        <Button
          style={{
            fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
            marginRight: '10px',
          }}
          onClick={() => resetAddAction()}
          className="close-button"
          text={t('APP.TRANSPORT.INDEX.PH_541A47', { defaultValue: 'Fermer' })}
        />

        <Button
          style={{
            fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
          }}
          loading={isLoading}
          onClick={() => {
            createAction(t, null, null, true);
            setIsLoading(true);
          }}
          disabled={checkValidationForm()}
          text={t('APP.TRANSPORT.INDEX.PH_285B90', { defaultValue: 'Ajouter' })}
          className="purple-button"
        />
      </div>
    </>
  );
};

export default Transport;
