import { connect } from 'react-redux';
import { resetBuildingSections, updateFormValue } from '../../actions/projects';
import RsetImport from '../../app/shared/RsetUpload/RsetImport';

const mapStateToProps = (state) => ({
  buildingTypes: state.projects.buildingTypes,
  project: state.projects.parsedProject,
  isRset: state.projects.currentProject.isRset,
  projectName: state.projects.currentProject.name,
  equipsBulkArray: state.projects.equipsBulkArray,
  fluidsArray: state.projects.fluidsArray,
  rsetmodalOpen: state.projects.rsetmodalOpen,
  referenceType: state.projects.templateType,
  referenceTypeSettings: state.projects.currentProject.templateType,
});

const mapDispatchToProps = (dispatch) => ({
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  resetBuildingSections: () => {
    dispatch(resetBuildingSections());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RsetImport);
