import { AllotmentNodeDTO } from '../../../app/models/ProjectDTO';
import { GetCaracsDTO } from '../../../app/models/EquipmentsDTO';
import { CurrentScreen, StateDTO } from '../../../app/models/GeneralDTO';
import { useTranslation } from 'react-i18next';
import {
  createEquipment,
  fetchCaracsByAllotmentNodeId,
  patchEquipments,
  postPepFdesDed,
} from '../../../apiCallFunctions/equipment/equipment';
import {
  fetchProjectById,
  loadProjectById,
  updateFormValue,
} from '../../../actions/projects';
import { updateFavoritesValue } from '../../../actions/favorites';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { changeValueEquipments } from '../../../actions/equipments';
import {
  selectCalculatorWater,
  selectCalculatorWorksite,
} from '../../../actions/calculator';
import { APP_ROUTES_PATH_ARRAY } from '../../../app/globalVariable/generalDefinitions';
import { useHistory } from 'react-router-dom';
import { EquipmentFromListEquipment } from '../../../app/projects/EquipmentList/ListEquipments/ListEquipmentsFunction';

const screenWhichDoesntNeedSheets = [
  CurrentScreen.listContributor,
  CurrentScreen.multipleActions,
  CurrentScreen.quickAddBar,
];

export const redirectToManualAdd = (dispatch) => {
  dispatch(updateFormValue('searchValue', ''));
  dispatch(updateFormValue('inputValue', ''));
  dispatch(updateFormValue('selectedLot', null));
  dispatch(updateFormValue('selectedFonction', null));
  dispatch(updateFormValue('selectedCategorie', null));
  dispatch(updateFormValue('selectedMaterial', null));
  dispatch(updateFormValue('selectedType', null));
  dispatch(changeValueEquipments('addDisplay', ['1']));
};

export enum ChangeViewSource {
  MANUAL_ADD = 'manualAdd',
  SELECT_CALCULATOR_WORKSITE = 'selectCalculatorWorksite',
  SELECT_CALCULATOR_WATER = 'selectCalculatorWater',
}

type UseGetCaracsByAllotmentNodeIdProps = {
  allotmentNodeId: number | null;
  screen: CurrentScreen;
  caracRenderOpen: boolean;
  ProjectSheetId?: number;
  disabledCaracs: boolean;
  setCaracValues: Function;
  getValues: Function;
  row: EquipmentFromListEquipment;
  onSuccessCallback?: (data: any) => void; // Replace 'any' with the actual data type
  onErrorCallback?: (error: any) => void; // Replace 'any' with the actual error type
};

export const useGetCaracsByAllotmentNodeId = ({
  allotmentNodeId,
  screen,
  caracRenderOpen,
  ProjectSheetId,
  disabledCaracs,
  setCaracValues,
  getValues,
  row,
  currentSearchGroup,
  onSuccessCallback,
  onErrorCallback,
}: UseGetCaracsByAllotmentNodeIdProps) => {
  const dispatch = useDispatch();
  const { caracValuesInContributorList } = useSelector(
    (state: StateDTO) => state.equipments
  );
  const isUserOnContributorList = screen === CurrentScreen.listContributor;

  const enabledCondition =
    (allotmentNodeId && screen !== CurrentScreen.listContributor) ||
    (isUserOnContributorList &&
      allotmentNodeId &&
      caracRenderOpen &&
      !disabledCaracs);
  return useQuery<GetCaracsDTO>(
    ['caracsByAllotmentNodeId', allotmentNodeId, ProjectSheetId],
    () =>
      fetchCaracsByAllotmentNodeId(
        allotmentNodeId,
        ProjectSheetId,
        !screenWhichDoesntNeedSheets.includes(screen)
      ),
    {
      enabled: enabledCondition,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (onSuccessCallback) {
          onSuccessCallback(data);
        }
        if (
          isUserOnContributorList &&
          data?.projectSheetCaracs &&
          data.projectSheetCaracs?.AllotmentNodeId === allotmentNodeId
        ) {
          let discreteValue = data.projectSheetCaracs?.caracs.find(
            (carac) => carac.CaracId === data.discreteId
          )?.value;
          let secondDiscreteValue = data.projectSheetCaracs.caracs.find(
            (carac) => carac.CaracId === data.secondDiscreteId
          )?.value;
          let xAxisValue = data.projectSheetCaracs.caracs.find(
            (carac) => carac.CaracId === data.xAxisId
          )?.value;

          let caracsIdInPatch = [];
          let caracsNeeded = [data.discreteId, data.secondDiscreteId, data.xAxisId];
          if (getValues) {
            caracsIdInPatch =
              getValues(`ProjectSheets[${row?.id}].caracs`)?.map(
                (carac) => carac.id
              ) || [];
            console.log(caracsIdInPatch, 'caracsIdInPatch', data);

            if (
              caracsIdInPatch.length > 0 &&
              caracsNeeded.find((caracId) => caracsIdInPatch.includes(caracId))
            ) {
              return;
            }
          }

          let caracFormatForm = caracsNeeded.map((caracIdToCheck) => {
            let projectSheetCarac = data.projectSheetCaracs?.caracs.find(
              (carac) => carac.CaracId === caracIdToCheck
            );

            if (projectSheetCarac?.value) {
              return {
                id: caracIdToCheck,
                value: projectSheetCarac.value[0],
                name: data.caracs.find((carac) => carac.id === caracIdToCheck)?.name,
              };
            }
          });

          setCaracValues(caracFormatForm.filter((carac) => carac));

          dispatch(
            changeValueEquipments('caracValuesInContributorList', {
              ...caracValuesInContributorList,
              [data?.projectSheetCaracs.ProjectSheetId]: {
                ...caracValuesInContributorList[ProjectSheetId],
                discrete: discreteValue ? discreteValue[0] : null,
                secondDiscrete: secondDiscreteValue ? secondDiscreteValue[0] : null,
                xAxis: xAxisValue ? xAxisValue[0] : null,
              },
            })
          );
        }
      },
      onError: (error) => {
        if (onErrorCallback) {
          onErrorCallback(error);
        }
        console.log('error in getCaracsByAllotmentNodeId', error?.message);
      },
    }
  );
};

const handleRedirect = (
  isOpenModalExitNavigationConfirmation,
  dispatch,
  history
) => {
  if (APP_ROUTES_PATH_ARRAY.includes(isOpenModalExitNavigationConfirmation)) {
    history.push(isOpenModalExitNavigationConfirmation);
  } else if (isOpenModalExitNavigationConfirmation === ChangeViewSource.MANUAL_ADD) {
    redirectToManualAdd(dispatch);
  } else if (
    isOpenModalExitNavigationConfirmation ===
    ChangeViewSource.SELECT_CALCULATOR_WORKSITE
  ) {
    dispatch(selectCalculatorWorksite());
  } else if (
    isOpenModalExitNavigationConfirmation ===
    ChangeViewSource.SELECT_CALCULATOR_WATER
  ) {
    dispatch(selectCalculatorWater());
  }
};

export const usePatchLinesFromList = (setTabValue, tableEditModeRef) => {
  const dispatch = useDispatch();
  const { currentProject, currentIndicator } = useSelector(
    (state: StateDTO) => state.projects
  );

  const { selectedRowKeys, isOpenModalExitNavigationConfirmation } = useSelector(
    (state: StateDTO) => state.equipments
  );

  const history = useHistory();

  const mutation = useMutation(
    (data) =>
      patchEquipments(
        currentProject.id,
        data.ProjectSheetsToPatch,
        data.needToRecomputeProject
      ),
    {
      onSuccess: (data, variables) => {
        if (
          variables.ProjectSheetsToPatch.length === 1 &&
          variables.needToRecomputeProject === false
        ) {
          return;
        } else if (typeof data !== 'object') {
          dispatch(fetchProjectById(currentProject.id, currentIndicator.id, true));
        } else {
          dispatch(loadProjectById({ data }));
          if (data.createdProjectSheetsIds && selectedRowKeys.length > 0) {
            let newSelectedRowKeys = selectedRowKeys.map((key) => {
              if (data.deletedProjectSheetsIds.includes(key)) {
                return data.createdProjectSheetsIds[
                  data.deletedProjectSheetsIds.indexOf(key)
                ];
              }
              return key;
            });

            dispatch(changeValueEquipments('selectedRowKeys', newSelectedRowKeys));
          }

          if (
            Boolean(
              isOpenModalExitNavigationConfirmation ||
                isOpenModalExitNavigationConfirmation === 0
            )
          ) {
            if (typeof isOpenModalExitNavigationConfirmation === 'string') {
              setTimeout(() => {
                handleRedirect(
                  isOpenModalExitNavigationConfirmation,
                  dispatch,
                  history
                );
              }, 1500);
            } else {
              setTabValue(isOpenModalExitNavigationConfirmation);
            }
            dispatch(
              changeValueEquipments('isOpenModalExitNavigationConfirmation', false)
            );
            dispatch(changeValueEquipments('tableEditMode', false));
            tableEditModeRef.current = false;
          }
        }

        dispatch(changeValueEquipments('caracValuesInContributorList', {}));
      },
      onError: (error) => {
        console.log('error', error);
      },
    }
  );

  return mutation;
};

export type BodyInPostEquipementDTO = {
  id: number; //AllotmentNodeId????!!!!
  comment?: string;
  customName?: string;
  caracs: { name: string; value: string | number | [string] }[];
  AllotmentNodes: AllotmentNodeDTO[]; // AllotmentNodes qui est un array d'allotmentnode avec au moins le nooco (plus les epcm et re2020 si on veut changer de ceux de base)
  fluid: { selectedFluid: number; fluidQuantity: string } | null;
  ZoneId: number;
  quantity: number;
  UrbanNetworkId: any | null;
  type_fiche: 'DEC' | 'REFURBISHED' | 'users';
};
export const usePostEquipmentInProject = () => {
  const { t } = useTranslation();

  const currentProjectId = useSelector(
    (state: StateDTO) => state.projects.currentProject
  ).id;
  const dispatch = useDispatch();

  return useMutation(
    (body: BodyInPostEquipementDTO) => createEquipment({ body, currentProjectId }),
    {
      onSuccess: (response) => {
        dispatch(
          updateFavoritesValue(
            'favoritesSnackbar',
            t('APP.GENERALFORM.ADD_EQUIPMENT.NOTIF.SUCCESS', {
              defaultValue: 'Equipement ajouté avec succès',
            })
          )
        );
        if (response?.data?.project) {
          dispatch(loadProjectById(response));
        }
      },
      onError: (error) => {
        console.log('error in usePostEquipmentInProject', error);
      },
    }
  );
};

export type BodyInPepFdesDedInProject = {
  comment?: string;
  customName?: string;
  caracs: { name: string; value: string | number | [string] }[];
  AllotmentNodes: AllotmentNodeDTO[]; // AllotmentNodes qui est un array d'allotmentnode avec au moins le nooco (plus les epcm et re2020 si on veut changer de ceux de base)
  fluid: { selectedFluid: number; fluidQuantity: number } | null; // selectedFluid = id of fluid!!!!
  ZoneId: number;
  quantity: number;
  UrbanNetworkId: any | null;
  re2020lot: any | null; // probably AllotmentNode[] in place of in AllotmentNodes?
  lineImportId: any | null;
  SheetId: number;
};
export const usePostPepFdesDedInProject = () => {
  const ProjectId = useSelector(
    (state: StateDTO) => state.projects.currentProject
  ).id;

  return useMutation((body: BodyInPepFdesDedInProject) =>
    postPepFdesDed({ body, ProjectId })
  );
};
