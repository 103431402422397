import './rangePicker.scss';
import { DatePicker } from 'antd';
import { StateDTO } from '../../models/GeneralDTO';
import { updateExploitationValue } from '../../../actions/exploitation';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import React from 'react';
import locale from 'antd/es/date-picker/locale/fr_FR';
import dayjs from 'dayjs';
import { getAntdLocale } from '../../shared/getAntdLocale';

const { RangePicker } = DatePicker;

interface RangePickerProps {
  label?: string;
}

const RangePickerCustom = ({ label }: RangePickerProps) => {
  const dispatch = useDispatch();
  const { newAction } = useSelector((state: StateDTO) => state.exploitation);
  const { currentProject } = useSelector((state: StateDTO) => state.projects);

  return (
    <div className="range-picker-wrapper">
      <p className="range-picker-label">{label}</p>
      <RangePicker
        disabledDate={(d) => {
          return (
            d.isBefore(currentProject.exploitContract[0].value) ||
            d.isAfter(currentProject.exploitContract[1].value)
          );
        }}
        onChange={(e, dates) => {
          dispatch(
            updateExploitationValue('newAction', {
              ...newAction,
              date:
                dates.find((el) => el === '') === ''
                  ? null
                  : dates.map((el) =>
                      dayjs(el, dayjs.localeData().longDateFormat('L'))
                        .hour(12)
                        .format()
                    ),
            })
          );
        }}
        className={`permis-form`}
        format={dayjs.localeData().longDateFormat('L')}
        locale={getAntdLocale()}
      />
    </div>
  );
};

export default RangePickerCustom;
