/* eslint-disable no-undef */
import MyResponsivePie from '../ResponsivePie';
import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

type UsageWidgetsProps = {
  secondData: {
    id: number | string;
    label: string;
    color: string;
    value: string;
    count: number;
  }[];
};

const bdd = require('../../../../assets/images/synthese/Image8.png');

const UsageWidgets = ({ secondData }: UsageWidgetsProps): JSX.Element => {
  const [data, setData] = useState(secondData);
  const { t } = useTranslation();

  useEffect(() => {
    setData(secondData);
  }, [secondData]);

  return (
    <div
      className="report-usage container-resizable-ok"
      style={{ overflow: 'auto' }}
    >
      <div className="report-stock-header">
        <h3 className="dashboard-title">
          {t('APP.WIDGETS.USAGEWIDGETS.PH_F0BB88', {
            defaultValue: 'Usage de données - Composants',
          })}
        </h3>
        <Tooltip
          title={t('APP.WIDGETS.USAGEWIDGETS.PH_ED9596', {
            defaultValue: 'Visualiser le type de données utilisées dans le projet',
          })}
        >
          <img
            style={{
              width: window.screen.availHeight < 950 ? '40px' : undefined,
            }}
            src={bdd}
            alt=""
          />
        </Tooltip>
      </div>
      <div className="report-stock-graph">
        <MyResponsivePie
          isPercentage={true}
          data={secondData.filter((el) => el.id !== 'count' && el.value !== '0.00')}
          colors={(d: { data: { color: string } }) => {
            //console.log(d);

            return d.data.color;
          }}
          margin={{ top: 20, right: 0, bottom: 30, left: 40 }}
          legends={undefined}
          currentIndicator={undefined}
        />
      </div>
      <div className="legends-custom-second">
        {data.map((d) => (
          <div
            key={d.id}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              fontSize: '10px',
              gap: '5px',
              justifyContent: 'flex-start',
              fontFamily: ' sans-serif',
            }}
          >
            <div
              style={{
                border: '0px solid',
                borderRadius: '50%',
                width: '10px',
                height: '10px',
                backgroundColor: d.color,
              }}
            />

            {d.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UsageWidgets;
