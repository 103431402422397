import { StateDTO } from '../../../models/GeneralDTO';
import {
  isExploitationProject,
  isRegularProjectNorNooco,
  optionsPhase,
} from '../SettingsSharedFunction/SettingsSharedFunction';
import { updateMonitoring } from '../../../../actions/monitoring';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import HeaderForm from '../FormElement/HeaderForm';
import ImpactForm from './ImpactForm/ImpactForm';
import React, { useEffect, useRef, useState } from 'react';
import RsetImport from '../../../../containers/RsetImport';
import ZoneSection from '../../../shared/ZoneSection/ZoneSection';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

// import rsetImport from '../../../shared/RsetUpload/RsetImportCode';

const dateFormat = 'L';

const ArchitectureForm = ({
  control,
  errors,
  setValue,
  getValues,
  watch,
  updateSettingsDataWithDataComps,
  projectDataComps,
}) => {
  const {
    projectNameRset,
    permitDateRset,
    phaseIdRset,
    currentProject,
    currentIndicator,
  } = useSelector((state: StateDTO) => state.projects);
  const currentIndicatorId = currentIndicator.id;
  const scrollRef = useRef(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (projectNameRset) {
      setValue('name', projectNameRset);
    }
    if (permitDateRset) {
      setValue('permitDate', dayjs(permitDateRset, dateFormat));
    }
    if (phaseIdRset && phaseIdRset < optionsPhase.length) {
      setValue('PhaseId', optionsPhase[phaseIdRset - 1].value);
    }
  }, [projectNameRset, permitDateRset, phaseIdRset]);

  const alertCallback = (message, severity) => {
    dispatch(updateMonitoring('alertSeverity', severity));
    dispatch(updateMonitoring('alertMessage', message));
    // setAlertOpen(true);
  };

  return (
    <>
      <div className="form-wrapper">
        <HeaderForm
          title={t('APP.ZONESFORM.HEADER', { defaultValue: 'Architecture' })}
        />
        <div className="zone-form-container" ref={scrollRef}>
          {isRegularProjectNorNooco(currentProject.templateType) && (
            <div className="architecture-core">
              <RsetImport
                settings={true}
                alertCallback={alertCallback}
                projectId={currentProject.id}
                renderCallback={() => {}}
              />
            </div>
          )}
          {!isExploitationProject(currentProject.templateType) && (
            <ImpactForm
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
            />
          )}
          <div className="form-main-wrapper ">
            <ZoneSection
              isSettings={true}
              scrollRef={scrollRef}
              updateSettingsDataWithDataComps={updateSettingsDataWithDataComps}
              projectDataComps={projectDataComps}
              control={control}
              errors={errors}
              setValue={setValue}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ArchitectureForm;
