import { connect } from 'react-redux';

import IndicatorsModal from '../../app/projects/IndicatorsModal';

import { changeValueEquipments } from '../../actions/equipments';
import {
  fetchIndicatorsDatas,
  openIndicators,
  setCurrentIndicator,
  updateFormValue,
} from '../../actions/projects';

const mapStateToProps = (state) => ({
  isIndicatorsOpen: state.projects.isIndicatorsOpen,
  currentProject: state.projects.currentProject,
  indicators: state.projects.indicators,
  currentIndicator: state.projects.currentIndicator,
});

const mapDispatchToProps = (dispatch) => ({
  openIndicators: (bool) => {
    dispatch(openIndicators(bool));
  },
  setCurrentIndicator: (id) => {
    dispatch(setCurrentIndicator(id));
  },
  fetchIndicatorsDatas: (id) => {
    dispatch(fetchIndicatorsDatas(id));
  },
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
  changeValueEquipments: (name, value) => {
    dispatch(changeValueEquipments(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorsModal);
