import React, { useEffect } from 'react';

import './productsChoice.scss';
import { Button } from '@photocarbone/nooco-ui';
import { StateDTO } from '../../models/GeneralDTO';
import { updateFormValue } from '../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CompaniesProducts from '../../../containers/CompaniesProducts';
import ComponentsProducts from '../../../containers/ComponentsProducts';
import FavoriteOverlay from '../../shared/FavoriteOverlay';
import ProductsList from '../../../containers/ProductsList';
import { useTranslation } from 'react-i18next';

type ProductsChoiceProps = {
  productView: string;
  updateProductsValue: Function;
  initDatasProducts: Function;
  isMenuOpen: boolean;
};

const ProductsChoice = ({
  productView,
  updateProductsValue,
  initDatasProducts,
  isMenuOpen,
}: ProductsChoiceProps): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { licenceTypeAccess } = useSelector((state: StateDTO) => state.projects);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(updateFormValue('isMenuOpen', false));
    updateProductsValue('requests', [1]);
    initDatasProducts();
  }, []);

  const handleViewProduct = (): JSX.Element => {
    switch (productView) {
      case 'components':
        return <ComponentsProducts />;
      case 'companies':
        return <CompaniesProducts />;
    }
  };

  if (licenceTypeAccess === 'read') {
    history.push('/');

    return null;
  }

  return (
    <div className="products-choice-wrapper" style={{ width: '100%' }}>
      <FavoriteOverlay screen={'products'} />
      <div
        style={{
          width:
            productView === 'components' || productView === 'companies'
              ? '40%'
              : '60%',
        }}
      >
        <div className="products-header" style={{ zIndex: 3, display: 'flex' }}>
          <Button
            dataTestId="product-name"
            style={{ marginRight: '10px' }}
            onClick={() => {
              updateProductsValue('productView', 'components');
              // updateProductsValue('listProducts', []);
            }}
            className={
              productView === 'components' ? 'purple-button' : 'close-button'
            }
            text={t('APP.PRODUCTSCHOICE.INDEX.PH_994841', {
              defaultValue: 'Produits',
            })}
          />

          <Button
            style={{ marginRight: '10px' }}
            dataTestId="brand-name"
            onClick={() => {
              // updateProductsValue('listProducts', []);
              updateProductsValue('productView', 'companies');
            }}
            className={
              productView === 'companies' ? 'purple-button' : 'close-button'
            }
            text={
              <div>
                {t('APP.PRODUCTSCHOICE.INDEX.PH_DFCF78', {
                  defaultValue: 'Marques',
                })}
              </div>
            }
          />
        </div>
        <div className="products-main" style={{ width: '100%' }}>
          {handleViewProduct()}
        </div>
      </div>
      <div
        style={{
          width:
            productView === 'components' || productView === 'companies'
              ? '60%'
              : '40%',
        }}
      >
        <ProductsList />
      </div>
    </div>
  );
};

export default ProductsChoice;
