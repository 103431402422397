import './refProject.scss';
import { Button } from 'antd';
import { StateDTO } from '../../../models/GeneralDTO';
import { Tooltip } from '@material-ui/core';
import { openImportModal } from '../../../../actions/equipments';
import { updateFormValue } from '../../../../actions/projects';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import NoocoCheckbox from '../../../shared/NoocoCheckbox';
import React, { useEffect, useState } from 'react';
import pin from '../../../../assets/images/project/pin.png';
import { useTranslation } from 'react-i18next';

interface RefProjectCardProps {
  home: boolean;
  onClick: Function;
  refProjects: [];
}

const RefProjectCard = ({ home, onClick }: RefProjectCardProps) => {
  const [isChecked, setIsChecked] = useState(
    localStorage.getItem('displayRefs') === 'false' ? false : true
  );

  const dispatch = useDispatch();

  const { companies, refProjects, currentProject, refStates, licenceTypeAccess } =
    useSelector((state: StateDTO) => state.projects);
  const { importDate } = useSelector((state: StateDTO) => state.imports);
  // Management to display checkbox and refs projects on number of companies

  const { t } = useTranslation();

  useEffect(() => {
    if (companies) {
      setIsChecked(
        companies.length === 1
          ? true
          : localStorage.getItem('displayRefs') === 'false'
          ? false
          : true
      );
    }
  }, [companies]);

  const storeStateRefs = (value) => {
    localStorage.setItem('displayRefs', value);
    setIsChecked(value);
  };

  return (
    <div className="ref-project-card-wrapper">
      {home && (
        <>
          {companies?.length > 1 && (
            <div className="checkbox-display">
              <NoocoCheckbox
                checked={isChecked}
                onChange={(e) => storeStateRefs(e.target.checked)}
              />

              <p>
                {t('APP.REFPROJECTCARD.INDEX.PH_5B769C', {
                  defaultValue: 'Afficher/masquer les mappings de références',
                })}
              </p>
            </div>
          )}

          {isChecked && (
            <Tooltip
              placement="top"
              title={
                <>
                  <span
                    style={{
                      display: licenceTypeAccess === 'pro' ? 'none' : 'block',
                      visibility: licenceTypeAccess === 'pro' ? 'hidden' : 'visible',
                    }}
                  >
                    {t('APP.REFPROJECTCARD.INDEX.PH_70368C', {
                      defaultValue:
                        'Fonctionnalité disponible avec une licence pro seulement',
                    })}
                  </span>
                  {t('APP.REFPROJECTCARD.INDEX.PH_E89749', {
                    defaultValue:
                      "Le mapping de référence permet d'établir des correspondances entre vos appellations et nos articles Nooco pour la reconnaissance de vos fichiers d'imports",
                  })}
                </>
              }
            >
              <div
                className="ref-project-card-wrapper-inside"
                style={{
                  overflowY: refProjects.length > 2 ? 'scroll' : 'none',
                }}
              >
                {refProjects?.map((refProject, index) => (
                  <div
                    className={`ref-project-card ${
                      licenceTypeAccess !== 'pro' ? 'disabled' : ''
                    }`}
                    key={index}
                    onClick={() => {
                      if (licenceTypeAccess === 'pro') {
                        onClick(refProject);
                      }
                    }}
                  >
                    <div
                      className={`ref-companies-info ${
                        licenceTypeAccess !== 'pro' ? 'disabled' : ''
                      }`}
                    >
                      <img src={pin} alt="pin" style={{ marginRight: '2em' }} />
                      <p>
                        {t('APP.REFPROJECTCARD.INDEX.PH_B95E4F', {
                          ref: refProject.Company.name,
                          defaultValue: 'Mapping de référence - {ref}',
                        })}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </Tooltip>
          )}
        </>
      )}

      {!home && (
        <>
          <div className="ref-project-card ref-project-card-in">
            <div className="ref-companies-info">
              <img src={pin} alt="pin" style={{ marginRight: '2em' }} />
              <p>
                {t('APP.REFPROJECTCARD.INDEX.PH_653B09', {
                  ref: currentProject.Company.name,
                  defaultValue: 'Mapping de référence - {ref}',
                })}
                {importDate.length > 0 && (
                  <Tooltip
                    title={
                      <div>
                        <ul>
                          <li>
                            {t('APP.REFPROJECTCARD.INDEX.PH_26F47E', {
                              defaultValue:
                                'Mappez une fois et réutilisez la reconnaissance du mapping de référence sur vos futurs projets',
                            })}
                          </li>
                          <li>
                            {t('APP.REFPROJECTCARD.INDEX.PH_5DF121', {
                              defaultValue:
                                "Vous pouvez définir la famille produit cible, et de manière facultative préciser dans l'automatisation le choix de caractéristiques ou de références produits (PEP/FDES)",
                            })}
                          </li>
                          <li>
                            {t('APP.REFPROJECTCARD.INDEX.PH_07BE63', {
                              defaultValue:
                                'Vos projets déjà existants peuvent être utilisés comme projets de référence',
                            })}
                          </li>
                          <li>
                            {t('APP.REFPROJECTCARD.INDEX.PH_B7CB7F', {
                              defaultValue:
                                'Vous pouvez charger avoir autant de fichiers et projets de référence que vous le souhaitez',
                            })}
                          </li>
                          <li>
                            {t('APP.REFPROJECTCARD.INDEX.PH_3467D6', {
                              defaultValue:
                                'Attention : nous ne gérons pas les conflits (un même libellé mappé sur différentes notions dans des projets de référence différents)',
                            })}
                          </li>
                        </ul>
                        <span>
                          {t('APP.REFPROJECTCARD.INDEX.PH_BF6D41', {
                            defaultValue:
                              'Utilisez les boutons en haut à droite pour commencer à mapper',
                          })}
                        </span>
                      </div>
                    }
                    placement="right"
                  >
                    <InfoIcon />
                  </Tooltip>
                )}
              </p>
            </div>
            <div className="buttons">
              <Button
                onClick={() => dispatch(openImportModal())}
                style={{ fontSize: '0.85rem' }}
                type="primary"
                shape="round"
                icon={<AddIcon />}
              >
                {t('APP.REFPROJECTCARD.INDEX.PH_8E6C40', {
                  defaultValue: 'Importer un fichier',
                })}
              </Button>
              <Button
                // onClick={openImportModal}
                style={{ fontSize: '0.85rem' }}
                onClick={() =>
                  dispatch(
                    updateFormValue('refStates', {
                      ...refStates,
                      refModal: true,
                    })
                  )
                }
                type="primary"
                shape="round"
                icon={<AddIcon />}
              >
                {t('APP.REFPROJECTCARD.INDEX.PH_1556B1', {
                  defaultValue: 'Ajouter un projet de référence',
                })}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RefProjectCard;
