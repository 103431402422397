import { Button } from '@photocarbone/nooco-ui';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import './addActions.scss';

import { updateExploitationValue as updateExploitationValueAsAction } from '../../../../actions/exploitation';
import Control from '../../../../containers/Control';
import EnergyWater from '../../../../containers/EnergyWater';

import { StateDTO } from '../../../models/GeneralDTO';
import { useSelector } from 'react-redux';
import Garbage from '../../../../containers/Garbage';
import Renewal from '../../../../containers/Renewal';
import SimplifiedEnergy from '../../../shared/SimplifiedEnergy';
import Transport from '../../../../containers/Transport';
import control from '../../../../assets/images/exploitation/control.png';
import eau from '../../../../assets/images/equipments/water.png';
import elec from '../../../../assets/images/equipments/energy.png';
import garbage from '../../../../assets/images/exploitation/garbage.png';
import renouvellement from '../../../../assets/images/exploitation/renouvellement.png';
import transport from '../../../../assets/images/exploitation/transport.png';
import transportnot from '../../../../assets/images/exploitation/transportnot.png';
import { useTranslation } from 'react-i18next';

interface AddActionsProps {
  updateExploitationValue: Function;
  addAction: string;
}

const AddActions = ({ updateExploitationValue, addAction }: AddActionsProps) => {
  const { t } = useTranslation();

  const renderChoiceAction = () => {
    switch (addAction) {
      case 'renewal':
        return <Renewal />;
      case 'control':
        return <Control />;
      case 'transport':
        return <Transport />;
      case 'energy':
        return (
          <SimplifiedEnergy
            exploitation={true}
            changeValue={updateExploitationValueAsAction}
          />
        );
      case 'water':
        return <EnergyWater consumer={'Eau'} />;
      case 'garbage':
        return <Garbage />;
      default:
        break;
    }
  };

  return (
    <div className="add-actions-wrapper">
      <div className="breadcrumb-add-actions" style={{ display: 'flex' }}>
        <Button
          onClick={() => updateExploitationValue('addAction', null)}
          style={{
            fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
          }}
          className="golden-button"
          text="1 - Action"
        />

        <Button
          style={{
            fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
          }}
          className="gray-button"
          text="2 - Caractéristiques"
        />
      </div>
      {!addAction ? (
        <>
          <div className="core-choice-add">
            <h4>
              {t('APP.ADDACTIONS.INDEX.PH_B5ACE9', {
                defaultValue: "Ajout d'actions",
              })}
            </h4>
            <div className="actions-choice">
              <div
                className="choice"
                onClick={() => {
                  updateExploitationValue('newAction', {
                    actionType: 'renewal',
                    equipment: null,
                    quantity: null,
                    comment: null,
                    renewalEquipment: null,
                  });
                  updateExploitationValue('addAction', 'renewal');
                }}
              >
                <img src={renouvellement} alt="" />
                <p>
                  {t('APP.ADDACTIONS.INDEX.PH_836786', {
                    defaultValue: 'Renouvellement',
                  })}
                </p>
              </div>
              <div
                className="choice"
                onClick={() => {
                  updateExploitationValue('newAction', {
                    actionType: 'water',
                    MasterDataEquipment: null,
                    quantity: null,
                    comment: null,
                    caracs: [],
                  });

                  updateExploitationValue('addAction', 'water');
                }}
              >
                <img src={eau} style={{ width: '60px' }} alt="" />
                <p>{t('APP.ADDACTIONS.INDEX.PH_E8D3EC', { defaultValue: 'Eau' })}</p>
              </div>
              <div
                className="choice"
                onClick={() => updateExploitationValue('addAction', 'control')}
              >
                <img src={control} alt="" />
                <p>
                  {t('APP.ADDACTIONS.INDEX.PH_3DD332', {
                    defaultValue: 'Contrôle et entretien',
                  })}
                </p>
              </div>
              <div
                className="choice"
                onClick={() => {
                  updateExploitationValue('newAction', {
                    actionType: 'energy',
                    MasterDataEquipment: null,
                    quantity: null,
                    comment: null,
                    caracs: [],
                  });

                  updateExploitationValue('addAction', 'energy');
                }}
              >
                <img src={elec} style={{ width: '60px' }} alt="" />
                <p>
                  {t('APP.ADDACTIONS.INDEX.PH_4300B1', {
                    defaultValue: 'Energie',
                  })}
                </p>
              </div>
              <div
                className="choice"
                onClick={() => updateExploitationValue('addAction', 'transport')}
              >
                <div>
                  <img src={transport} alt="" />
                  <p>
                    {t('APP.ADDACTIONS.INDEX.PH_9A9FDB', {
                      defaultValue: 'Transport',
                    })}
                  </p>
                </div>
              </div>

              <div
                className="choice"
                onClick={() => {
                  updateExploitationValue('newAction', [
                    {
                      actionType: 'garbage',
                      garbage: null,
                      quantity: null,
                      unit: null,
                      mode: null,
                    },
                  ]);

                  updateExploitationValue('addAction', 'garbage');
                }}
              >
                <img src={garbage} alt="" />
                <p>
                  {t('APP.ADDACTIONS.INDEX.PH_3A8CDB', {
                    defaultValue: 'Déchet',
                  })}
                </p>
              </div>
            </div>
          </div>
          <div className="buttons-end" style={{ display: 'flex' }}>
            <Button
              style={{
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
              }}
              onClick={() => updateExploitationValue('displayScreen', 'list')}
              text={t('APP.ADDACTIONS.INDEX.PH_54F42F', {
                defaultValue: 'Fermer',
              })}
              className="close-button"
            />
          </div>
        </>
      ) : (
        renderChoiceAction()
      )}
    </div>
  );
};

export default AddActions;
