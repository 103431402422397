import React, { useContext, useEffect, useState } from 'react';

import { ModalNooco } from '@photocarbone/nooco-ui';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loader from '@material-ui/core/CircularProgress';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import TextField from '@material-ui/core/TextField';

import AppContext from '../../../AppContext';
import formatNumber, {
  LIBELLE_KG_EQ_CO2,
  getUnitConversion,
  getFormattedWeightNumber,
  formatValueWithWeight,
} from '../../../services/mathematics/formatNumber';

import construct from '../../../../assets/images/variant/building.svg';
import energy from '../../../../assets/images/variant/energy.svg';
import water from '../../../../assets/images/variant/water.svg';

import './cardvariant.scss';
import { RoundElement } from '../../HomeProject/ProjectsList/ProjectCard/RoundElement';
import { updateMonitoring } from '../../../../actions/monitoring';
import {
  useDeleteVariant,
  usePatchVariant,
} from '../../../../hooks/useQuery/variants';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ALLOTMENT_IDS } from '../../../globalVariable/typeProject';

const CardVariant = ({
  addVariantSelection,
  variant,
  addCardVariant,
  projects,
  changeValueVariant,
  searchVariant,
  addProjectToVariant,
  currentIndicatorId,
  selectedVariants,
  removeAddedProject,
  variantsInDetails,
  fetchProjectForGraph,
  addedVariant,
  currentProject,
  variants,
  currentIndicator,
  currentAllotment,
  isVariantEditable,
}) => {
  // const ctx = useContext(AppContext);

  //! addedVariant = extern project add in variant
  //! addCardVariant  (props): if true it s a add card variant
  //! searchVariant === true ? display searchVariant Input : not display researchInput

  //! selectedVariants = variants selected in slider
  //! variants = all variants
  //! variantsInDetails = all variants   how dif with variants?

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { t } = useTranslation();

  const onSuccessDeleteVariant = () => {
    setAlert(
      t('APP.CARDVARIANT.INDEX.PH_85CBA1', {
        defaultValue: 'Variante supprimée avec succès',
      }),
      'success'
    );
    setOpenDeleteModal(false);
  };
  const onErrorDeleteVariant = () => {
    setAlert(
      t('APP.CARDVARIANT.INDEX.PH_1F97BA', {
        defaultValue: 'Erreur lors de la suppression de la variante',
      }),
      'error'
    );
    setOpenDeleteModal(false);
  };
  const onSuccessUpdateVariant = () => {
    setAlert(
      t('APP.CARDVARIANT.INDEX.PH_691938', {
        defaultValue: 'La variante du projet a été renommée',
      }),
      'success'
    );
  };

  const [isAdded, setIsAdded] = useState(true);
  const dispatch = useDispatch();
  const [isEditVariantName, setIsEditVariantName] = useState(false);

  const [variantName, setVariantName] = useState('');
  const deleteVariant = useDeleteVariant(
    onSuccessDeleteVariant,
    onErrorDeleteVariant
  );
  const updateVariant = usePatchVariant(onSuccessUpdateVariant);

  let indicatorId = currentIndicator.id === 26 ? 1 : currentIndicator.id;

  useEffect(() => {
    if (
      variant &&
      (!variantsInDetails[variant.id] ||
        !variantsInDetails[variant.id].project.Ic[indicatorId])
    ) {
      fetchProjectForGraph(variant || variant.project, isAdded ? true : false);
    }

    setIsAdded(false);
  }, [variant]);

  const handleOpenVariant = (variantId) => {
    window.open(`/projects/${variantId}`, '_blank');
  };

  const handleClickEditVariantName = () => {
    setIsEditVariantName(!isEditVariantName);
    setVariantName(variant.name);
  };

  const handlePatchVariantName = () => {
    updateVariant.mutate({
      variantId: variant.id,
      body: {
        name: variantName,
      },
    });
    setIsEditVariantName(false);
    setVariantName(variantName);
  };

  const selectionProject = () => {
    let filters = [];

    for (let y = 0; y < variants.length; y++) {
      if (y === 0) {
        filters = projects.filter((el) => el.id !== variants[y].id);
      } else {
        filters = filters.filter((el) => el.id !== variants[y].id);
      }
    }

    return filters;
  };

  if (!variant && !addCardVariant) {
    return null;
  }

  const handleOpenDeleteModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const setAlert = (message, severity) => {
    dispatch(updateMonitoring('alertSeverity', severity));
    dispatch(updateMonitoring('alertMessage', message));
  };

  const { isLoading: isLoadingDeleteVariant } = deleteVariant;
  const handleDeleteVariant = (variantId) => {
    deleteVariant.mutate(variantId);
  };

  const formattedValue = (variant, indicatorId, icType) => {
    const impactProject = variant.project.Ic[indicatorId];
    const icProjectType =
      currentAllotment.id === ALLOTMENT_IDS.RE2020 ? 'IcProjetRe2020' : 'IcProjet';

    const impact = icType === 'IcProjet' ? icProjectType : icType;

    return impactProject[icType]
      ? currentProject.refUnit === 'total'
        ? impactProject[impact] * variant.project.area
        : impactProject[impact]
      : 0;
  };

  return (
    <div
      className={
        !addCardVariant &&
        selectedVariants.find((el) => el && el?.id === variant?.id)
          ? 'card-variant is-selected'
          : 'card-variant'
      }
      style={{
        cursor: addCardVariant ? 'initial' : null,
        padding: window.screen.availHeight < 750 ? '0.7em' : null,
      }}
      onClick={
        !addCardVariant
          ? () => {
              if (variantsInDetails[variant?.id]) {
                addVariantSelection(variantsInDetails[variant?.id].project);
              } else {
                fetchProjectForGraph(variant, true);
                addVariantSelection(variant);
              }
            }
          : null
      }
    >
      <ModalNooco
        title={t('APP.CARDVARIANT.INDEX.PH_6BD7C6', {
          defaultValue: 'Suppression de la variante',
        })}
        isOpen={openDeleteModal}
        loading={isLoadingDeleteVariant}
        disabled={isLoadingDeleteVariant}
        buttonType="delete"
        cancelFunction={handleCloseDeleteModal}
        validateFunction={() => handleDeleteVariant(variant.id)}
        closeOnOutsideClick={false}
        closeIcon={true}
      >
        <div>
          {t('APP.CARDVARIANT.INDEX.PH_ED88F9', {
            defaultValue:
              'Vous êtes sur le point de supprimer définitivement votre variante, voulez-vous continuer ?',
          })}
        </div>
      </ModalNooco>
      {/* remove extern project from variant list */}
      {!addCardVariant && addedVariant.find((el) => el.id === variant?.id) && (
        <RemoveCircleOutlineIcon
          onClick={() => removeAddedProject(variant)}
          className="removeIcon"
        />
      )}

      {/* delete, edit, open */}
      {!addCardVariant &&
        isVariantEditable &&
        !addedVariant.find((el) => el.id === variant?.id) && (
          <>
            <RoundElement
              type="delete"
              variant={true}
              onClick={handleOpenDeleteModal}
            />
            <RoundElement
              type="edit"
              variant={true}
              onClick={handleClickEditVariantName}
            />
            <RoundElement
              type="open"
              variant={true}
              onClick={() => handleOpenVariant(variant.id)}
            />
          </>
        )}

      {/* Main Variant/project Card*/}
      {!addCardVariant && (
        <>
          {/* title */}
          <div className="card-title">
            {!isEditVariantName && <h4>{variant ? variant?.name : null}</h4>}
            {isEditVariantName && (
              <TextField
                style={{ width: '100%' }}
                autoFocus
                id="standard-basic"
                value={variantName}
                onChange={(e) => {
                  setVariantName(e.currentTarget.value);
                }}
                // onBlur={(e) => { e.preventDefault(); e.stopPropagation(); handleEditProjectCard(e); }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handlePatchVariantName(e);
                  }
                }}
              />
            )}
          </div>
          {/* loader */}
          {!variantsInDetails[variant?.id] && (
            <Loader
              variant="indeterminate"
              disableShrink
              style={{
                display: 'block',
                color: '#73b7ec',
                margin: 'auto',
                marginTop: '2em',
              }}
              size={70}
              thickness={4}
            />
          )}
          {/* content */}
          {variantsInDetails[variant?.id] &&
            variantsInDetails[variant?.id].project.Ic[indicatorId] && (
              <div className="card-content">
                {/* impact */}
                <div
                  className="card-impact"
                  style={{
                    height:
                      window.screen.availHeight < 750
                        ? '90px'
                        : window.screen.availHeight < 950
                        ? '110px'
                        : null,
                    width:
                      window.screen.availHeight < 750
                        ? '90px'
                        : window.screen.availHeight < 950
                        ? '110px'
                        : null,
                  }}
                >
                  <div
                    style={{
                      height:
                        window.screen.availHeight < 750
                          ? '90px'
                          : window.screen.availHeight < 950
                          ? '110px'
                          : null,
                    }}
                  >
                    <span className="card-impact-value">
                      {formatValueWithWeight(
                        formattedValue(
                          variantsInDetails[variant?.id],
                          indicatorId,
                          'IcProjet'
                        ),
                        currentIndicator
                      )}
                    </span>
                    <span>
                      {currentIndicator.unit === LIBELLE_KG_EQ_CO2
                        ? getUnitConversion(
                            formattedValue(
                              variantsInDetails[variant?.id],
                              indicatorId,
                              'IcProjet'
                            )
                          )
                        : currentIndicator.unit}
                      {currentProject.refUnit === 'total' ? '' : '/m²'}
                    </span>
                  </div>
                </div>
                {/* indicators */}
                <div className="card-indicators">
                  <div className="card-construct">
                    <img src={construct} alt />
                    <div>
                      <p>
                        {t('APP.CARDVARIANT.INDEX.PH_5D20A5', {
                          defaultValue: 'Construction :',
                        })}
                      </p>
                      <p>
                        {formatValueWithWeight(
                          formattedValue(
                            variantsInDetails[variant?.id],
                            indicatorId,
                            'IcConstruction'
                          ),
                          currentIndicator
                        )}
                        {currentIndicator.unit === LIBELLE_KG_EQ_CO2
                          ? getUnitConversion(
                              formattedValue(
                                variantsInDetails[variant?.id],
                                indicatorId,
                                'IcConstruction'
                              )
                            )
                          : currentIndicator.unit}
                        {currentProject.refUnit === 'total' ? '' : '/m²'}
                      </p>
                    </div>
                  </div>
                  <div className="card-energy">
                    <img src={energy} alt />
                    <div>
                      <p>
                        {t('APP.CARDVARIANT.INDEX.PH_E7226B', {
                          defaultValue: 'Energie :',
                        })}
                      </p>
                      <p>
                        {variantsInDetails[variant?.id].project.Ic[indicatorId]
                          .IcEnergie
                          ? formatNumber(
                              currentProject.refUnit === 'total'
                                ? variantsInDetails[variant?.id].project.Ic[
                                    indicatorId
                                  ].IcEnergie *
                                    variantsInDetails[variant?.id].project.area
                                : variantsInDetails[variant?.id].project.Ic[
                                    indicatorId
                                  ].IcEnergie
                            )
                          : 0}
                        {currentIndicator.unit}
                        {currentProject.refUnit === 'total' ? '' : '/m²'}
                      </p>
                    </div>
                  </div>
                  <div className="card-water">
                    <img src={water} alt />
                    <div>
                      <p>
                        {t('APP.CARDVARIANT.INDEX.PH_264CA0', {
                          defaultValue: 'Eau :',
                        })}
                      </p>
                      <p>
                        {variantsInDetails[variant?.id].project.Ic[indicatorId].IcEau
                          ? formatNumber(
                              currentProject.refUnit === 'total'
                                ? variantsInDetails[variant?.id].project.Ic[
                                    indicatorId
                                  ].IcEau *
                                    variantsInDetails[variant?.id].project.area
                                : variantsInDetails[variant?.id].project.Ic[
                                    indicatorId
                                  ].IcEau
                            )
                          : 0}
                        {currentIndicator.unit}
                        {currentProject.refUnit === 'total' ? '' : '/m²'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </>
      )}

      {/* add VariantCard from project */}
      {addCardVariant && (
        <div className="card-content" style={{ cursor: 'initial', width: '110%' }}>
          {/* Autocomplete for select project to Add in VariantList */}
          <div
            className="card-title"
            style={{ marginBottom: 'auto', width: '100%' }}
          >
            {searchVariant && variants.length > 0 && (
              <h4
                style={{
                  padding: window.screen.availHeight < 750 ? '1em 5px' : null,
                  margin: window.screen.availHeight < 750 ? '1em' : null,
                  width: '90%',
                }}
              >
                <Autocomplete
                  onChange={(event, newValue) => {
                    // setSelectedCompany(newValue);
                    console.log('newValue', newValue);
                    changeValueVariant('projectToVariant', newValue);
                    addProjectToVariant();
                    setIsAdded(true);
                  }}
                  getOptionLabel={(option) => (option.name ? option.name : '')}
                  id="controllable-states-demo"
                  key={projects}
                  options={selectionProject()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('APP.CARDVARIANT.INDEX.PH_57BD17', {
                        defaultValue: 'Rechercher un projet',
                      })}
                      style={{
                        marginTop: window.screen.availHeight < 750 ? '0px' : null,
                      }}
                      onKeyPress={(event, value) => {
                        console.log('value', value);
                        if (event.key === 'Enter') {
                          addProjectToVariant();
                          event.target.blur();
                        }
                      }}
                    />
                  )}
                />
              </h4>
            )}
          </div>
          {/* Add Icon */}
          {!searchVariant && (
            <div
              className="card-impact"
              style={{
                height:
                  window.screen.availHeight < 750
                    ? '90px'
                    : window.screen.availHeight < 950
                    ? '110px'
                    : null,
                width:
                  window.screen.availHeight < 750
                    ? '90px'
                    : window.screen.availHeight < 950
                    ? '110px'
                    : null,
                cursor: 'pointer',
              }}
              onClick={() => changeValueVariant('searchVariant', true)}
            >
              <div
                style={{
                  height:
                    window.screen.availHeight < 750
                      ? '90px'
                      : window.screen.availHeight < 950
                      ? '110px'
                      : null,
                }}
              >
                <span>
                  <AddIcon fontSize="large" />
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CardVariant;
