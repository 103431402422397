/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import {
  Button,
  Input as InputNooco,
  ModalNooco,
  Select as SelectNooco,
} from '@photocarbone/nooco-ui';
import { Checkbox, Form, Table } from 'antd';
import { FilterFilled, FilterOutlined, RightCircleFilled } from '@ant-design/icons';
import { Modal } from 'react-bootstrap';
import {
  checkIfNodeCanBeAddedInProject,
  findParentNode,
  isBuildingSiteAValidDestination,
  returnBadgeTypeFiche,
  returnSearchBarOptionsFiltered,
} from '../../shared/utils';
import { debounce } from 'lodash';
import {
  getGroupedEquipmentList,
  getProcessEquipmentRows,
} from '../../shared/groupByEquipments';
import { useDispatch, useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import AddEquipment from '../../../containers/AddEquipment';
import Alert from '@material-ui/lab/Alert';
import AppContext from '../../AppContext';
import DedModal from '../../../containers/Equipments/DedModal';
import EquipmentListFilter, { GroupByOptions } from './EquipmentListFilter';
import HelpIcon from '@material-ui/icons/Help';
import ImportModal from '../../../containers/ImportModal';
import Link from '@material-ui/core/Link';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';

import './editequipment.scss';
import './listequipments.scss';
import Calculator from './Calculator/Calculator';

import { FooterTable } from './ListEquipments/FooterTable/FooterTable';
import { ItemTypes } from '../../models/DragConstants';
import {
  ALLOTMENT_IDS,
  REFERENCE_TYPE_NAME,
  TYPE_EPCM,
  TYPE_NOOCO,
  TYPE_RE2020,
} from '../../globalVariable/typeProject';
import {
  ZONE_TYPES,
  iniesTypeFiche,
  LOTS_IDS,
  TYPE_FICHE,
} from '../../globalVariable/generalDefinitions.ts';
import { calculatorUpdate } from '../../../actions/calculator';
import {
  dragAndDropContributors,
  updateFavoritesValue,
} from '../../../actions/favorites';
import {
  handleTableEdit,
  initStateOfFilter,
  returnListOfZonesForSelect,
} from './ListEquipments/ListEquipmentsFunction';
import { klona } from 'klona';
import {
  returnCoreColumns,
  returnFilterColumn,
  returnHideColumnInit,
} from './ListEquipments/tableColumns.js';
import { useLocalStorage, useUpdateEffect } from 'react-use';
import { usePatchLinesFromList } from '../../../hooks/useQuery/equipments';
import EditContributor from './EditContributor';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import LoadingBar from './ListEquipments/LoadingBar/index.tsx';
import {
  customNameColumn,
  statusColumn,
  statusItems,
  updateFormData,
  typeFicheColumn,
} from './ListEquipments/allColumnsObject.tsx';
import BreadcrumbModal from '../../../containers/BreadcrumbModal';
import { changeValueEquipments } from '../../../actions/equipments.js';
import { updateFormValue } from '../../../actions/projects.js';
import { getSheetType } from '../../shared/utilsFunction';
import {
  checkIdBundleCanBeAddedInProject,
  checkIfConfiguredSheetCanBeAdded,
} from '../../shared/QuickAddBarEquipment/QuickAddBarEquipment.tsx';
import { useHistory } from 'react-router-dom';

export const checkIfEnergieInRset = (elems, currentProject, dispatch, t) => {
  if (!currentProject.isRset) return false;

  if (!Array.isArray(elems)) elems = [elems];

  for (const elem of elems) {
    let nodeToCheck =
      elem.detailsNode?.find((node) => node.level === 0) ||
      elem.AllotmentNodes?.find((el) => el.AllotmentId === ALLOTMENT_IDS.NOOCO);
    if (
      nodeToCheck &&
      (nodeToCheck.id === LOTS_IDS.ENERGIE ||
        (elem.isDec && elem.isDec.lot === 'Energie'))
    ) {
      dispatch(
        updateFormValue(
          'errorDeletionEquipmentRsee',
          t('APP.EQUIPMENTLIST.LISTEQUIPMENTS.PH_C0EE89', {
            defaultValue:
              "Vous ne pouvez effectuer d'action sur un equipement du lot Energie si votre projet à été créé à partir d'un RSET.",
          })
        )
      );

      dispatch(changeValueEquipments('isLoadingProject', false));
      return true;
    }
  }

  return false;
};

const applyColumnsFiltersToEquipmentList = (dataTable, filtersTableActive) => {
  let valuesToFilterOn = filtersTableActive.filter(
    (col) => col && col.values.length > 0
  );

  if (valuesToFilterOn.length === 0) {
    return dataTable;
  }

  const filterElement = (elem, valuesToFilterOn) => {
    if (
      valuesToFilterOn.reduce(
        (acc, filter) => acc && filter.values.includes(elem[filter.name]),
        true
      )
    )
      return elem;
  };

  const filterLevel = (data, valuesToFilterOn) => {
    let elemCheck;
    const filteredData = data.map((el) => {
      if (el.hasOwnProperty('children') && el.children.length > 0) {
        const childrenData = filterLevel(el.children, valuesToFilterOn).filter(
          (el) => el
        );

        if (childrenData.length > 0) {
          elemCheck = filterElement(el, valuesToFilterOn);
          if (elemCheck) return elemCheck;

          return { ...el, children: childrenData };
        }
      }

      return filterElement(el, valuesToFilterOn);
    });

    return filteredData.filter((el) => el);
  };

  dataTable = filterLevel(dataTable, valuesToFilterOn); //.filter((el) => el.children.length > 0);

  return dataTable;
};

const applySearchToEquipmentList = (searchBarValue, dataTable) => {
  if (searchBarValue === '') {
    return dataTable;
  }

  const normalizedSearchBarValue =
    typeof searchBarValue === 'string'
      ? searchBarValue
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .split(' ')
          .filter((string) => string !== '' && string !== ' ')
      : [searchBarValue];

  dataTable = dataTable.reduce((result, tableRow) => {
    const childData = applySearchFilterRecursive(
      klona(tableRow),
      normalizedSearchBarValue
    );

    if (childData && childData.children?.length > 0) {
      result.push(childData);
    }

    return result;
  }, []);

  return dataTable;
};

const applySearchFilterRecursive = (tableRow, searchValues) => {
  if (tableRow.children) {
    const filteredChildren = tableRow.children
      .map((child) => applySearchFilterRecursive(child, searchValues))
      .filter(Boolean);

    return filteredChildren.length > 0
      ? { ...tableRow, children: filteredChildren }
      : null;
  } else {
    const rowMatches = searchValues.every((searchValue) =>
      tableRow.searchables.some((searchable) => searchable.includes(searchValue))
    );

    return rowMatches ? tableRow : null;
  }
};

const typeFicheSpecific = [
  TYPE_FICHE.BUNDLE,
  TYPE_FICHE.CUSTOM_USER,
  TYPE_FICHE.REFURBISHED,
  TYPE_FICHE.FICHES_CONFIG,
];

export const returnTypeOfEquipment = (equipment, allGroups) => {
  if (iniesTypeFiche.includes(equipment.type_fiche)) {
    return 'INIES';
  } else if (typeFicheSpecific.includes(equipment.type_fiche)) {
    return equipment.type_fiche;
  }

  return getSheetType(equipment.AllotmentNode, allGroups.flatAllotments);
};

export const reformatDataForPatch = (data, allGroups) => {
  const properties = [
    'quantity',
    'customName',
    'ZoneId',
    'AllotmentNode',
    'AllotmentNodeCertified',
    'status',
    'caracs',
    'comment',
    'SheetId',
    'id_inies',
    'fluid',
    'type_fiche',
    'lifespan',
  ];

  data = {
    ProjectSheets: Object.keys(data.ProjectSheets).map((key) => {
      const newObject = properties.reduce(
        (obj, prop) => {
          if (data.ProjectSheets[key][prop]) {
            if (prop === 'AllotmentNodeCertified') {
              obj['AllotmentNodes'] = [data.ProjectSheets[key][prop]];
            } else if (prop === 'SheetId') {
              obj['SheetId'] = data.ProjectSheets[key][prop].id;
              obj['type_fiche'] = data.ProjectSheets[key][prop].type_fiche;
            } else {
              obj[prop] = data.ProjectSheets[key][prop];
            }
          }
          return obj;
        },
        { id: Number(key) }
      );

      newObject.type = returnTypeOfEquipment(newObject, allGroups);

      delete newObject.type_fiche;

      return newObject;
    }),
  };

  return data.ProjectSheets;
};

export const getListScrollPosition = (dispatch, dispatchFunction) => {
  const rowsDisplayed = document.querySelectorAll('.ant-table-row');

  let rowCenterIndex =
    rowsDisplayed.length > 3
      ? rowsDisplayed.length - 2
      : rowsDisplayed.length === 3
      ? 2
      : 0;

  dispatch
    ? dispatch(
        dispatchFunction(
          'listScrollPosition',
          rowsDisplayed[rowCenterIndex].getAttribute('data-row-key')
        )
      )
    : dispatchFunction(
        'listScrollPosition',
        rowsDisplayed[rowCenterIndex].getAttribute('data-row-key')
      );
};

export const resetStatesAfterPatchEquipments = (
  dispatch,
  resetForm,
  resetOnlyState
) => {
  if (!resetOnlyState) {
    resetForm();
  }
  dispatch(updateFormValue('mdToLines', null));
  dispatch(changeValueEquipments('caracValuesInContributorList', {}));
  dispatch(updateFormValue('confirmPatchRefurbished', false));
};

const ListEquipments = ({
  selectedRowKeys,
  changeValueEquipments,
  groupBy,
  referenceSurfaceTag,
  searchBarValue,
  currentProject,
  updateFormValue,
  zones,
  callback,
  fetchImport,
  allGroups,
  lots,
  dataTable,
  modalLoadingDed,
  askingIsDed,
  filterLots,
  hideColumns,
  columns,
  lotMode,
  isMenuOpen,
  currentIndicator,
  isLoadingProject,
  setTabValue,
  addDisplay,
  setSearchValue,
  fetchProjectById,
  updateNewInies,
  currentAllotment,
  projectAllot,
  licenceTypeAccess,
  isUserStartEditColumns,
  tabValue,
  tableEditModeRef,
}) => {
  const { t } = useTranslation();
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.ProductCard,
      drop: (item, monitor) => {
        if (
          checkIfNodeCanBeAddedInProject(
            item.AllotmentNodes[0],
            currentProject,
            allGroups.flatAllotments
          )
        ) {
          dispatch(dragAndDropContributors(item));
        } else {
          setOpenSnackbar(
            t('APP.EQUIPMENTLIST.LISTEQUIPMENTS.PH_88527F', {
              defaultValue:
                'Votre équipement n’est pas disponible pour ce type de projet.',
            })
          );
        }
      },
      collect: (monitor, props) => {
        return {
          isOver: !!monitor.isOver(),
        };
      },
    }),
    []
  );
  const ctx = useContext(AppContext);
  const [currentEquipment, setCurrentEquipment] = useState();
  const [refCaracs, setRefCaracs] = useState(null);
  const [groups, setGroups] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [brands, setBrands] = useState(undefined);
  const [selectedBrand, setSelectedBrand] = useState(undefined);
  const [filteredSheets, setFilteredSheets] = useState([]);
  const [selectedSheetObj, setSelectedSheetObj] = useState(undefined);
  const [, setImportError] = useState(false);
  const [templateError, setTemplateError] = useState(false);
  const [totalEmmited, setTotalEmmited] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [currentDestination, setCurrentDestination] = useState();
  const [reEmploy, setReEmploy] = useState();
  const [listEquipementSortOrder, setListEquipementSortOrder] = useLocalStorage(
    'listEquipement_sortOrder'
  );
  const [isQuickAddBarListEquipmentsOpen, setIsQuickAddBarListEquipmentsOpen] =
    useLocalStorage(`isQuickAddBarListEquipmentsOpen-${currentProject.id}`, false);
  const [listEquipementCollapsedRows, setListEquipementCollapsedRows] =
    useLocalStorage(`listEquipement_collapsed-${currentProject.id}`);
  const {
    allFiltersFromTable,
    filtersTableActive,
    tableEditMode,
    visibleFilters,
    listScrollPosition,
    allFiltersFromTableModel,
    isOpenModalExitNavigationConfirmation,
  } = useSelector((state) => state.equipments);
  const [filterSearchValue, setFilterSearchValue] = useState({});
  const {
    certifiedAllotments,
    nodesAvailableForProjectType,
    bundleOptions,
    modeledProductOptions,
    configuredSheetOptions,
    errorDeletionEquipmentRsee,
  } = useSelector((state) => state.projects);
  const { creationImport, currentLine } = useSelector((state) => state.imports);
  const tableRef = useRef();
  const usePatchEquipments = usePatchLinesFromList(setTabValue, tableEditModeRef);
  const [allEquipmentRows, setAllEquipmentRows] = useState(null);
  const { isLoading, isSuccess } = usePatchEquipments;
  const [filterBy, setFilterBy, removeFilterBy] = useLocalStorage(
    `filterBy-${currentProject.id}`,
    ['project']
  );

  const history = useHistory();

  const searchBarOptions = returnSearchBarOptionsFiltered({
    allAllotments: allGroups.flatAllotments,
    allotmentsAvailable: nodesAvailableForProjectType,
    iniesOptions: [],
    bundleOptions: bundleOptions?.filter((bundle) =>
      checkIdBundleCanBeAddedInProject(bundle, currentProject, allGroups)
    ),
    modeledProductOptions,
    configuredSheetOptions: configuredSheetOptions?.filter((configuredSheet) =>
      checkIfConfiguredSheetCanBeAdded(configuredSheet, currentProject, allGroups)
    ),
    currentAllotment,
  });
  const {
    setValue,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    const ProjectSheetsToPatch = reformatDataForPatch(data, allGroups);

    getListScrollPosition(undefined, changeValueEquipments);

    usePatchEquipments.mutate({ ProjectSheetsToPatch: ProjectSheetsToPatch });
  };

  useEffect(() => {
    if (
      !isLoading &&
      !isLoadingProject &&
      listScrollPosition &&
      tableRef.current &&
      allEquipmentRows
    ) {
      setTimeout(() => {
        if (tableRef.current && typeof tableRef.current.scrollTo === 'function') {
          tableRef.current.scrollTo({ key: Number(listScrollPosition) });
          resetStatesAfterPatchEquipments(dispatch, reset);

          changeValueEquipments('listScrollPosition', null);
          changeValueEquipments('tableEditMode', false);
        }
      }, 20);
    }
  }, [allEquipmentRows]);

  const impactLineSelected = (selectedRowKeys) => {
    let selectedElems = selectedRowKeys.map((d) =>
      currentProject.Sheets.find((el) => el.ProjectSheet.id === d)
    );
    let totalEmmited = 0;

    for (let index = 0; index < selectedElems.length; index++) {
      if (selectedElems[index]) {
        const value =
          lotMode === 'Référentiel NOOCO'
            ? selectedElems[index].acvResults.total
            : selectedElems[index].Re2020Sheet.acvResults.total;

        totalEmmited += Number(value / currentProject.area);
      }
    }

    setTotalEmmited(totalEmmited);
  };

  useEffect(() => {
    setAllEquipmentRows(
      getProcessEquipmentRows(
        zones,
        currentProject,
        referenceSurfaceTag,
        allGroups.allotments,
        currentAllotment,
        currentIndicator,
        allGroups
      )
    );
  }, [zones, referenceSurfaceTag]);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    changeValueEquipments('selectedRowKeys', selectedRowKeys);

    impactLineSelected(selectedRowKeys);
  };

  function getAllKeysWithChildren(rows) {
    const keys = [];

    function getKey(node) {
      if (node.key) {
        keys.push(node.key);
      }

      if (node.children && Array.isArray(node.children)) {
        for (const child of node.children) {
          getKey(child);
        }
      }
    }

    for (const row of rows) {
      getKey(row);
    }

    return keys;
  }

  // TABLE SETTINGS
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: 50,
    checkStrictly: false,
  };

  const expandable = useMemo(
    () => ({
      expandIcon: ({ expanded, onExpand, record }) => (
        <RightCircleFilled
          className={`expand ${expanded ? 'isExpanded' : 'notExpanded'}`}
          style={{
            fontSize: '20px',
            display:
              !record.lot && !record.type_fiche?.includes('EMPTY')
                ? 'inline-block'
                : 'none',
          }}
        />
      ),
      expandRowByClick: true,
    }),
    []
  );

  const dispatch = useDispatch();
  const { parsedProject } = useSelector((state) => state.projects);
  const {
    isCalculatorActived,
    isOldBuildingSiteInProject,
    isBuildingSiteActivatedInParams,
    isOldWaterLotInProject,
  } = useSelector((state) => state.projects.calculator);
  const { favoritesSnackbar } = useSelector((state) => state.favorites);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requestId, setRequestId] = useState(null);

  const handleOpenModal = (record) => {
    setCurrentEquipment(record);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentEquipment(null);
  };

  const handleNextStep = () => {
    askingIsDed(currentEquipment);
    handleCloseModal();
  };

  const handleYesClick = () => {
    ctx.api
      .patchEquipment(
        {
          ...currentEquipment,
          isDEDRequested: true,
          id_demandeur: Number(requestId),
        },
        currentProject.id
      )
      .then((res) => {
        changeValueEquipments('isLoadingProject', true);
        fetchProjectById(currentProject.id, currentIndicator.id);
      });
    handleCloseModal();
  };

  const handleSelectChange = (optionSelected) => {
    const selected = itemsArray.find((item) => item.id === optionSelected);

    setSelectedOption(selected);
    setShowNumberField(selected.name === 'Oui' ? true : false);
  };

  const [itemsArray, setItemsArray] = useState([
    { id: 1, name: 'Oui' },
    { id: 2, name: 'Non' },
  ]);
  const [selectedOption, setSelectedOption] = useState(itemsArray[0]);
  const [showNumberField, setShowNumberField] = useState(
    selectedOption.name === 'Oui' ? true : false
  );

  const handleCheckIfAlreadyExist = () => {
    return (
      <Modal show={isModalOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Demande de fiche DED</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('APP.EQUIPMENTLIST.LISTEQUIPMENTS.PH_B54428', {
              defaultValue: 'Une demande est-elle déjà présente sur le site',
            })}
            <Link href="https://mdegd.dimn-cstb.fr/home">
              https://mdegd.dimn-cstb.fr/home
            </Link>
            {t('APP.EQUIPMENTLIST.LISTEQUIPMENTS.PH_60FFCC', {
              defaultValue: 'pour ce produit ?',
            })}
          </p>
          <p>
            {t('APP.EQUIPMENTLIST.LISTEQUIPMENTS.PH_AD0566', {
              defaultValue: "Si oui, veuillez renseigner l'ID de la demande",
            })}
          </p>
          <div>
            <SelectNooco
              value={selectedOption}
              itemsArray={itemsArray}
              width="150px"
              keyValue="name"
              size="small"
              keyItem="id"
              keyDisplay="name"
              onChange={(e) => {
                handleSelectChange(e);
              }}
              style={{
                marginRight: '1rem!important',
                marginLeft: '0!important',
              }}
            />

            {showNumberField && (
              <div
                style={{
                  display: 'flex',
                  marginTop: '1rem',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: '1rem' }}>ID de la demande</div>
                <Tooltip
                  title={t('APP.EQUIPMENTLIST.LISTEQUIPMENTS.PH_9FDDB1', {
                    defaultValue: 'L’ID de la demande est précédé d’un #',
                  })}
                >
                  <p
                    style={{
                      cursor: 'pointer',
                      marginRight: '1rem',
                      marginBottom: '0',
                    }}
                  >
                    <HelpIcon />
                  </p>
                </Tooltip>
                <InputNooco
                  autoFocus={true}
                  type="number"
                  value={requestId}
                  onChange={(value) => setRequestId(value)}
                  width={'250px'}
                />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {showNumberField && (
            <Button
              className="purple-button"
              onClick={handleYesClick}
              style={{ marginLeft: '1rem' }}
              text={t('APP.EQUIPMENTLIST.LISTEQUIPMENTS.PH_D5FAD2', {
                defaultValue: 'Valider',
              })}
            ></Button>
          )}

          {!showNumberField && (
            <Button
              className="purple-button"
              onClick={handleNextStep}
              style={{ marginLeft: '1rem' }}
              text={t('APP.EQUIPMENTLIST.LISTEQUIPMENTS.PH_8E5625', {
                defaultValue: 'Suivant',
              })}
            ></Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  useEffect(() => {
    if (
      isOldBuildingSiteInProject === undefined ||
      isOldBuildingSiteInProject === true
    ) {
      const buildingSiteZones = zones.filter(
        (zone) => zone.type === ZONE_TYPES.buildingSite
      );
      const buildingSiteArrayNotFromCalculator = buildingSiteZones.flatMap((zone) =>
        zone.ProjectSheets.filter(
          (projectSheet) => projectSheet.source !== 'buildingSiteCalculator'
        )
      );

      const isOldBuildingSiteInProject =
        buildingSiteArrayNotFromCalculator.length > 0;
      const isBuildingSiteActivatedInParams = buildingSiteZones.length > 0;

      dispatch(
        calculatorUpdate('isOldBuildingSiteInProject', isOldBuildingSiteInProject)
      );
      dispatch(
        calculatorUpdate(
          'isBuildingSiteActivatedInParams',
          isBuildingSiteActivatedInParams
        )
      );
    }

    if (
      isOldWaterLotInProject === undefined ||
      isOldWaterLotInProject === null ||
      isOldWaterLotInProject === true
    ) {
      const BuildingSiteAndWaterIdArray = parsedProject.raw
        .filter((equipment) =>
          ['buildingSiteCalculator', 'waterCalculator'].includes(equipment.source)
        )
        .map((equipment) => equipment.id);

      const isWaterEquipmentNotFromBuildingSiteFound = parsedProject.raw.some(
        (equipment) =>
          equipment.MasterDataEquipment?.lot === 'Eau' &&
          !BuildingSiteAndWaterIdArray.includes(equipment.id)
      );

      dispatch(
        calculatorUpdate(
          'isOldWaterLotInProject',
          isWaterEquipmentNotFromBuildingSiteFound
        )
      );
    }
  }, [currentEquipment, dataTable, parsedProject]);

  // Remove lots that are not in the project
  useEffect(() => {
    const lotNames = [];

    for (let index = 0; index < lots.length; index += 1) {
      lotNames.push(lots[index].name.toUpperCase());
    }

    let newGroups = allGroups.type.filter((elem) => {
      let nodeAvailable = allGroups.flatAllotments.filter(
        (el) => el.MasterDataEquipmentId === elem.id
      );

      let bool = false;

      nodeAvailable.map((node) => {
        let detailsNode = findParentNode(node, allGroups.flatAllotments, [], []);

        if (detailsNode.some((el) => lotNames.includes(el.name.toUpperCase()))) {
          bool = true;

          return;
        }
      });

      return bool;
    });

    newGroups = newGroups.filter((group) => group.isObsolete !== true);

    if (currentProject.templateType === TYPE_EPCM) {
      newGroups = newGroups.filter((group) =>
        group.referenceType.includes(REFERENCE_TYPE_NAME.EPCM)
      );
    }

    newGroups.sort(
      (a, b) =>
        a.lot.localeCompare(b.lot) ||
        a.fonction.localeCompare(b.fonction) ||
        a.categorie.localeCompare(b.categorie) ||
        a.materiel.localeCompare(b.materiel) ||
        a.type.localeCompare(b.type)
    );

    setGroups(newGroups);
  }, [lots, allGroups]);

  /**
   * Delete selected equipments
   * @param elemsToDelete, list of equipments as they show in the list
   */
  const deleteSelection = async (elemsToDelete, dispatch) => {
    if (checkIfEnergieInRset(elemsToDelete, currentProject, dispatch, t)) return;

    // We create a list containing only the id's of the equipments
    const idList = [];

    for (let index = 0; index < elemsToDelete.length; index++) {
      idList.push(elemsToDelete[index].ProjectSheet.id);

      if (elemsToDelete[index].childEquip) {
        idList.push(elemsToDelete[index].childEquip.ProjectSheet.id);
      }
    }
    getListScrollPosition(null, changeValueEquipments);

    let formData = getValues();

    if (formData.ProjectSheets) {
      handleSubmit(onSubmit);
    }

    const deleteResponse = await ctx.api
      .deleteEquipments(currentProject.id, idList)
      .then(() => {
        callback();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Change main style on headers columns and buttons

  useEffect(() => {
    if (window.screen.availHeight < 950) {
      const allHeaders = document.querySelectorAll('th');
      const buttons = document.querySelectorAll('.bottom-buttons button');
      const buttonsTop = document.querySelectorAll('.header-left button');

      if (allHeaders) {
        allHeaders.forEach((el) => {
          if (window.screen.availHeight > 600 && window.screen.availHeight <= 800) {
            el.style.fontSize = '0.875rem';
          } else {
            el.style.fontSize = '1rem';
          }
        });
      }

      if (buttonsTop) {
        buttonsTop.forEach((el) => {
          if (window.screen.availHeight > 600 && window.screen.availHeight <= 800) {
            el.style.fontSize = '0.85rem';
          }
        });
      }

      if (buttons) {
        buttons.forEach((el) => {
          el.style.fontSize = '0.85rem';
        });
      }
    }
  }, [groupBy, dataTable, columns, addDisplay]);

  // Handle the snackbar alert

  const handleCloseSnack = () => {
    setTimeout(() => {
      setOpenSnackbar(false);
      setTemplateError(false);
      dispatch(updateFavoritesValue('favoritesSnackbar', null));
      updateFormValue('errorDeletionEquipmentRsee', null);
    }, 200);
  };

  useEffect(() => {
    updateFormValue('nodeSearchValue', null);

    return () => {
      updateFavoritesValue('favoritesSnackbar', '');
    };
  }, []);

  const updateFilteredLots = (filterLots, projectLots) => {
    let allLotsOfProject = [];

    if (filterLots.length === 0) {
      projectLots.forEach((lot) => {
        allLotsOfProject = [...allLotsOfProject, { lot: lot.name, check: false }];
      });
    } else {
      allLotsOfProject = filterLots;
    }

    changeValueEquipments('filterLots', allLotsOfProject);
  };

  useEffect(() => {
    updateFilteredLots(filterLots, currentProject.Lots);

    if (currentProject.templateType.includes(TYPE_EPCM)) {
      allGroups.sousLot = allGroups.allotments.find(
        (a) => a.id === 34
      ).AllotmentNodes;
    }

    if (!currentAllotment) return;

    let newDataTable = getFilteredDataTable();

    changeValueEquipments('dataTable', newDataTable);
  }, [
    allEquipmentRows,
    groupBy,
    filterBy,
    currentProject,
    filtersTableActive,
    searchBarValue,
    currentProject.Sheets,
    filterSearchValue,
    currentIndicator,
  ]);

  useEffect(() => {
    if (!(listEquipementCollapsedRows && listEquipementCollapsedRows[groupBy])) {
      toggleCollapseAll();
    }
  }, [dataTable]);

  useEffect(() => {
    tableEditModeRef.current = Boolean(tableEditMode && isUserStartEditColumns);
  }, [isUserStartEditColumns, tableEditMode]);

  // management of filters

  const getFilteredDataTable = (activeColumnName = null) => {
    let filteredDataTable = [];

    try {
      const filtersTableToUse = activeColumnName
        ? filtersTableActive.filter((col) => col?.name !== activeColumnName)
        : filtersTableActive;

      filteredDataTable = getGroupedEquipmentList(
        zones,
        currentProject,
        JSON.parse(localStorage.getItem(`filterBy-${currentProject.id}`)) ||
          filterBy,
        allGroups,
        allEquipmentRows,
        currentAllotment,
        JSON.parse(localStorage.getItem(`groupBy-${currentProject.id}`)) || groupBy
      );

      if (
        filtersTableActive &&
        filtersTableActive.some((col) => col.values.length > 0)
      ) {
        filteredDataTable = applyColumnsFiltersToEquipmentList(
          filteredDataTable,
          filtersTableToUse
        );
      }

      if (searchBarValue.length > 0) {
        filteredDataTable = applySearchToEquipmentList(
          searchBarValue,
          filteredDataTable
        );
      }
    } catch (error) {
      console.error('Error while getting filtered data table, error is : ', error);
    }

    return filteredDataTable;
  };

  const getColumnSearchProps = (dataIndex, t) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      const debouncedOnChange = debounce((value) => {
        setFilterSearchValue({
          ...filterSearchValue,
          [dataIndex]: value.toLowerCase(),
        });
      }, 300);

      if (visibleFilters.includes(dataIndex)) {
        const columnFilterData = allFiltersFromTableModel?.find(
          (col) => col?.name === dataIndex
        );
        const dataChecked = filtersTableActive?.find(
          (col) => col?.name === dataIndex
        )?.values;

        if (!columnFilterData || !dataChecked) {
          return;
        }

        let newDataTable = getFilteredDataTable(dataIndex);
        let availableFilterDataValues = initStateOfFilter(
          columns.filter((col) => col?.dataIndex === dataIndex),
          newDataTable
        )?.[0];

        if (filterSearchValue[dataIndex] && filterSearchValue[dataIndex] !== '') {
          availableFilterDataValues.values = availableFilterDataValues.values.filter(
            (el) => el.name.toLowerCase().includes(filterSearchValue[dataIndex])
          );
        }

        const handleKeyPress = (e) => {
          if (e.key === 'Enter') {
            setFilterSearchValue({
              ...filterSearchValue,
              [dataIndex]: e.target.value.toLowerCase(),
            });
          }
        };

        const renderCustomFilter = (dataIndex, filter, value) => {
          switch (dataIndex) {
            case 'type_fiche': {
              return (
                <span className="badge">
                  {returnBadgeTypeFiche(value, 'filter')}
                </span>
              );
            }
            case 'status': {
              const statusCircle = statusItems.find(
                (status) => status.value === filter.value
              );
              return (
                <span style={{ marginBottom: '10px' }}>
                  {statusCircle.optionRender}
                </span>
              );
            }
            default: {
              return filter.value;
            }
          }
        };

        return (
          <div style={{ padding: 8 }}>
            <Tooltip
              title={t('APP.EQUIPMENTLIST.LISTEQUIPMENTS.PH_2ED405', {
                defaultValue: 'Taper sur entrer pour lancer la recherche',
              })}
            >
              <Form.Item name={`${dataIndex}-input`} style={{ marginBottom: '0' }}>
                <InputNooco
                  placeholder={t('APP.EQUIPMENTLIST.LISTEQUIPMENTS.PH_8B9BF7', {
                    defaultValue: 'Rechercher',
                  })}
                  disabledDebouncing={true}
                  style={{ width: '100%' }}
                  onChange={debouncedOnChange}
                  onKeyPress={handleKeyPress}
                />
              </Form.Item>
            </Tooltip>
            <div
              style={{
                marginTop: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <p
                onClick={() =>
                  handleCheckedValues(
                    selectedKeys,
                    'all',
                    dataIndex,
                    availableFilterDataValues.values.map((el) => el.value)
                  )
                }
                style={{
                  textDecoration: 'underline',
                  marginRight: '2em',
                  color: '#A3AED0',
                }}
              >
                {t('APP.EQUIPMENTLIST.LISTEQUIPMENTS.PH_A136EB', {
                  defaultValue: 'Tout sélectionner',
                })}
              </p>
              <p
                onClick={() => {
                  handleCheckedValues(selectedKeys, 'reset', dataIndex);
                }}
                style={{ color: '#5E5ADB' }}
              >
                {t('APP.EQUIPMENTLIST.LISTEQUIPMENTS.PH_C9190D', {
                  defaultValue: 'Réinitialiser',
                })}
              </p>
            </div>
            <div className="core-filters-values">
              {availableFilterDataValues.values.map((filter) => (
                <div
                  className="filter-choice"
                  key={filter.value}
                  style={{
                    marginBottom:
                      availableFilterDataValues.name === 'status'
                        ? '10px'
                        : undefined,
                  }}
                >
                  <Checkbox
                    style={{ margin: '0' }}
                    checked={dataChecked?.includes(filter.value)}
                    onChange={(e) => {
                      e.preventDefault();
                      handleCheckedValues(filter.value, 'check', dataIndex);
                    }}
                  ></Checkbox>
                  <p>{renderCustomFilter(dataIndex, filter, filter.value)}</p>
                </div>
              ))}
            </div>
          </div>
        );
      }
    },
    filterDropdownOpen: visibleFilters.includes(dataIndex) ? true : false,
    onFilterDropdownOpenChange: (visible) =>
      visible
        ? changeValueEquipments('visibleFilters', [...visibleFilters, dataIndex])
        : changeValueEquipments(
            'visibleFilters',
            visibleFilters.filter((el) => el !== dataIndex)
          ),

    filterIcon: (text) =>
      filtersTableActive?.find((col) => col && col.name === dataIndex)?.values
        .length > 0 ? (
        <FilterFilled style={{ color: '#5f5ae3' }} />
      ) : (
        <FilterOutlined
          onClick={() =>
            changeValueEquipments('visibleFilters', [...visibleFilters, dataIndex])
          }
        />
      ),
  });

  const updateAllFiltersFromTableOnSearch = (dataIndex, selectedKey) => {
    const newDataValues = [...allFiltersFromTable];
    const columnFilterListToUpdate = newDataValues.find(
      (col) => col.name === dataIndex
    );

    if (columnFilterListToUpdate) {
      let newFilterListValues;

      if (selectedKey === '') {
        newFilterListValues = klona(
          allFiltersFromTableModel.find((sourceCol) => sourceCol.name === dataIndex)
            .values
        );
      } else {
        newFilterListValues = klona(
          columnFilterListToUpdate.values.filter((el) =>
            String(el.value).toLowerCase().includes(selectedKey.toLowerCase())
          )
        );
      }

      columnFilterListToUpdate.values = newFilterListValues;
    }

    changeValueEquipments('allFiltersFromTable', newDataValues);
  };

  const updateAllFiltersFromTableOnReset = (dataIndex) => {
    let newActiveFiltersTable = filtersTableActive.map((col) => {
      if (col.name === dataIndex) {
        col.values = [];
      }

      return col;
    });

    let newAllFiltersFromTable = allFiltersFromTable.map((col) => {
      if (col.name === dataIndex) {
        col.values = allFiltersFromTableModel.find(
          (sourceCol) => sourceCol.name === dataIndex
        ).values;
      }

      return col;
    });

    changeValueEquipments('filtersTableActive', newActiveFiltersTable);
    changeValueEquipments('allFiltersFromTable', newAllFiltersFromTable);

    if (filtersTableActive.some((col) => col.values.length > 0)) {
      localStorage.setItem(
        `stateOfFilter-${currentProject.id}`,
        JSON.stringify(newActiveFiltersTable)
      );
    } else {
      localStorage.removeItem(`stateOfFilter-${currentProject.id}`);
    }
  };

  const updateAllFiltersFromTableOnAll = (dataIndex, valueList) => {
    let newData = filtersTableActive.map((col) => {
      if (col.name === dataIndex) {
        col.values = valueList;
      }

      return col;
    });

    localStorage.setItem(
      `stateOfFilter-${currentProject.id}`,
      JSON.stringify(newData)
    );

    changeValueEquipments('filtersTableActive', newData);
  };

  const updateAllFiltersFromTableOnCheck = (dataIndex, selectedKey) => {
    let newData = filtersTableActive.map((col) => {
      if (col.name !== dataIndex) return { ...col };

      let values = col.values;

      if (!values.includes(selectedKey)) {
        values = [...values, selectedKey];
      } else {
        values = values.filter((key) => key !== selectedKey);
      }

      return {
        ...col,
        values,
      };
    });

    localStorage.setItem(
      `stateOfFilter-${currentProject.id}`,
      JSON.stringify(newData)
    );

    changeValueEquipments('filtersTableActive', [...newData]);
  };

  const toggleCollapseAll = (dataTableParam = null) => {
    if (!dataTable.length) {
      return;
    }

    const listEquipementCollapsedRows =
      JSON.parse(
        localStorage.getItem(`listEquipement_collapsed-${currentProject.id}`)
      ) || [];

    const newListEquipmentsCollapsedRows = {
      ...listEquipementCollapsedRows,
      [groupBy]:
        listEquipementCollapsedRows[groupBy] &&
        listEquipementCollapsedRows[groupBy].length
          ? []
          : getAllKeysWithChildren(dataTableParam || dataTable),
    };

    setListEquipementCollapsedRows(newListEquipmentsCollapsedRows);
  };

  const handleRowExpand = (records) => {
    setListEquipementCollapsedRows({
      ...listEquipementCollapsedRows,
      [groupBy]: records,
    });
  };

  const handleCheckedValues = (
    selectedKey,
    actionType,
    dataIndex,
    valueList = []
  ) => {
    switch (actionType) {
      case 'search':
        updateAllFiltersFromTableOnSearch(dataIndex, selectedKey);
        break;
      case 'reset':
        setFilterSearchValue({ ...filterSearchValue, [dataIndex]: '' });
        updateAllFiltersFromTableOnReset(dataIndex);
        break;
      case 'all':
        updateAllFiltersFromTableOnAll(dataIndex, valueList);
        break;
      case 'check':
        updateAllFiltersFromTableOnCheck(dataIndex, selectedKey);
        break;
      default:
        console.log('Action type: ${actionType} not handled');
        break;
    }
  };

  // Handle click on name to redirect on graph

  const toLineProductGraph = (line) => {
    let details;

    updateFormValue('loadingGraph', false);

    projectAllot.structureGraph.forEach((cur) => {
      const struct = line.ProjectSheet.AllotmentNodes.find(
        (el) => el.id === cur['0']
      );

      if (struct && line.ProjectSheet.ZoneId === cur.sheets.ProjectSheet.ZoneId) {
        let copy = [...cur.details];

        details = copy.reverse();
      }
    });

    let node = null;
    let detailsId = details
      ? details.find((el) => el?.MasterDataEquipmentId).id
      : null;

    if (line) {
      allGroups.flatAllotments.map((el) => {
        let nodesDetails = findParentNode(el, allGroups.flatAllotments, [], [])[0]
          .details;

        if (detailsId && nodesDetails.find((el) => el.id === detailsId)) {
          node = el;
        }
      });
    }

    updateFormValue('allotSteps', details);
    updateFormValue('selectedLot', line.lot);
    updateFormValue('selectedFonction', line.fonction);
    updateFormValue('selectedCategory', line.categorie);
    updateFormValue('selectedMaterial', line.materiel);
    updateFormValue('selectedType', line.type);
    setSearchValue(
      allGroups.type.find((md) => md.id === node.MasterDataEquipmentId)
    );
    updateFormValue('nodeSearchValue', node);
    changeValueEquipments('lockedEquipmentNeeded', line.ProjectSheet.SheetId);
    updateFormValue('sheetToLifeCycle', line);
    updateFormValue('loadingWaitingGraph', true);

    setTabValue(0);
  };

  // useEffect(() => {
  //   const scrollBar = document.querySelector('.rc-virtual-list-scrollbar-thumb');
  //   const scrollBarRef = tableRef?.current?.querySelector('.rc-virtual-list-scrollbar-thumb');
  //   const body = document.querySelector('div.ant-table-body');

  //   if(tableRef) {
  //     body.scrollTop = 300;
  //   }

  //   console.log(scrollBar?.scrollTop,
  //     scrollBarRef?.scrollTop,
  //     scrollBar,
  //     scrollBarRef,
  //     scrollBar?.style?.top
  //   );
  // },
  // [tableEditMode]);

  useEffect(() => {
    if (!addDisplay) {
      const scrollContainer = document.querySelector('.ant-table-body');

      if (scrollContainer) {
        if (window.screen.availHeight > 950) {
          scrollContainer.style.height = '545px';
        } else if (
          window.screen.availHeight > 810 &&
          window.screen.availHeight < 950
        ) {
          scrollContainer.style.height = '400px';
        }
      }
    }
  }, [addDisplay, isEditMode]);

  // Management of hide columns

  useEffect(() => {
    if (hideColumns.length === 0 && currentAllotment) {
      const showBuildingSites = isBuildingSiteAValidDestination(zones);

      let hideInit = returnHideColumnInit(
        dispatch,
        currentProject,
        currentIndicator,
        currentAllotment,
        getColumnSearchProps,
        tableEditMode,
        returnListOfZonesForSelect(zones, []),
        referenceSurfaceTag,
        showBuildingSites,
        t,
        setValue,
        isUserStartEditColumns,
        listEquipementSortOrder,
        allGroups,
        groupBy,
        watch,
        getValues
      );
      changeValueEquipments('hideColumns', hideInit);
    } else if (
      hideColumns.length > 0 &&
      !hideColumns.find((column) => column.key === 'status') &&
      groupBy !== GroupByOptions.IMPORT
    ) {
      changeValueEquipments('hideColumns', [
        ...hideColumns,
        statusColumn(
          t,
          tableEditMode,
          setValue,
          dispatch,
          isUserStartEditColumns,
          getColumnSearchProps,
          watch,
          currentProject,
          getValues
        ),
        customNameColumn(
          t,
          getColumnSearchProps,
          tableEditMode,
          setValue,
          dispatch,
          isUserStartEditColumns,
          currentProject,
          getValues
        ),
      ]);
    } else if (
      hideColumns.length > 0 &&
      hideColumns.find((column) => column.key === 'status') &&
      groupBy === GroupByOptions.IMPORT
    ) {
      changeValueEquipments(
        'hideColumns',
        hideColumns.filter(
          (column) => column.key !== 'status' && column.key !== 'originalName'
        )
      );
    }
  }, [
    groupBy,
    lotMode,
    currentIndicator,
    currentAllotment,
    dataTable,
    tableEditMode,
  ]);

  // Management of default columns displayed

  const isAllCollapsed = () => {
    const listEquipementCollapsedRows =
      JSON.parse(
        localStorage.getItem(`listEquipement_collapsed-${currentProject.id}`)
      ) || [];

    return (
      listEquipementCollapsedRows && !!listEquipementCollapsedRows[groupBy]?.length
    );
  };

  useEffect(() => {
    const buildingSitesIds = zones
      .filter((zone) => zone.type === ZONE_TYPES.buildingSite)
      .map((zone) => zone.id);
    let columns = returnCoreColumns(
      dispatch,
      listEquipementSortOrder,
      buildingSitesIds,
      modalLoadingDed,
      handleOpenModal,
      toLineProductGraph,
      getColumnSearchProps,
      currentProject,
      currentIndicator,
      tableEditMode,
      currentAllotment,
      allGroups,
      isUserStartEditColumns,
      referenceSurfaceTag,
      toggleCollapseAll,
      isAllCollapsed(),
      t,
      searchBarOptions,
      bundleOptions,
      certifiedAllotments,
      nodesAvailableForProjectType,
      ctx.api,
      setValue,
      groupBy,
      getValues,
      watch
    );

    const hideColumnChoice = returnFilterColumn({
      currentProject,
      licenceTypeAccess,
      updateNewInies,
      hideColumns,
      handleColumnsHide,
      deleteSelection,
      changeValueEquipments,
      visibleFilters,
      tableEditMode,
      isQuickAddBarListEquipmentsOpen,
      t,
      setValue,
    });

    const showBuildingSites = isBuildingSiteAValidDestination(zones);

    let allHides = [
      ...returnHideColumnInit(
        dispatch,
        currentProject,
        currentIndicator,
        currentAllotment,
        getColumnSearchProps,
        tableEditMode,
        returnListOfZonesForSelect(zones, []),
        referenceSurfaceTag,
        showBuildingSites,
        t,
        setValue,
        isUserStartEditColumns,
        listEquipementSortOrder,
        allGroups,
        groupBy,
        watch,
        getValues
      )?.map((col) => ({
        ...col,
        check:
          hideColumns.find((checkState) => checkState.dataIndex === col.dataIndex)
            ?.check || col.check,
      })),
    ];

    const hides = [...allHides.filter((el) => el.check === true)];
    const hidesColumnSorted = hides.map((column) => {
      const { defaultSortOrder, ...columnProps } = column;
      const val = listEquipementSortOrder && JSON.parse(listEquipementSortOrder);

      if (val && column.dataIndex === Object.keys(val)[0]) {
        return { ...columnProps, sortOrder: Object.values(val)[0] };
      }

      return column;
    });

    columns = [...columns, ...hidesColumnSorted, ...hideColumnChoice];

    changeValueEquipments('columns', columns);
  }, [
    groupBy,
    hideColumns,
    selectedRowKeys,
    filterLots,
    currentIndicator,
    referenceSurfaceTag,
    listEquipementSortOrder,
    tableEditMode,
    visibleFilters,
    listEquipementCollapsedRows,
    filtersTableActive,
    dataTable,
  ]);

  useEffect(() => {
    if (columns?.length > 0 && dataTable?.length > 0 && !allFiltersFromTable) {
      const allColumns = [...columns, ...hideColumns];
      const initData = initStateOfFilter(allColumns, dataTable);

      let localStorageFilter = localStorage.getItem(
        `stateOfFilter-${currentProject.id}`
      );
      const updatedFiltersTableActive = localStorageFilter
        ? JSON.parse(localStorageFilter)
        : allColumns.map((col) => ({ name: col.dataIndex, values: [] }));

      changeValueEquipments('filtersTableActive', updatedFiltersTableActive);
      changeValueEquipments('allFiltersFromTable', initData);
      changeValueEquipments('allFiltersFromTableModel', klona(initData));
    }
  }, [columns]);

  const handleColumnsHide = (e, value) => {
    let copy = hideColumns.find((el) => el.title === value);
    let list = hideColumns.filter((el) => el.title !== value);

    copy.check = e.target.checked;

    list = [...list, copy];

    changeValueEquipments('hideColumns', list);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (tableEditModeRef.current === true) {
      changeValueEquipments(
        'isOpenModalExitNavigationConfirmation',
        nextLocation.pathname
      );
      return false;
    }
    return true;
  };

  useEffect(() => {
    const unblock = history.block(handleBlockedNavigation);

    return () => {
      unblock();
    };
  }, []);

  return (
    <div>
      {handleCheckIfAlreadyExist()}
      <ModalNooco
        title="Enregistrer vos modifications"
        isOpen={Boolean(
          isOpenModalExitNavigationConfirmation ||
            isOpenModalExitNavigationConfirmation === 0
        )}
        cancelFunction={() =>
          changeValueEquipments('isOpenModalExitNavigationConfirmation', false)
        }
        validateFunction={handleSubmit(onSubmit)}
        closeOnOutsideClick={true}
        closeIcon={true}
        loading={isLoading}
      >
        <div>
          {t('APP.EQUIPMENTLIST.LISTEQUIPMENTS.PH_0FDEB4', {
            defaultValue:
              'Vous êtes sur le point de quitter la liste des contributeurs sans sauvegarder vos modifications à la volée. Souhaitez vous Enregistrer ces modifications ?',
          })}
        </div>
      </ModalNooco>

      {!isCalculatorActived && (
        <div
          className="list-equipments-wrapper"
          style={{ width: '100%', minHeight: '200px' }}
        >
          <Snackbar
            open={
              errorDeletionEquipmentRsee ||
              favoritesSnackbar ||
              openSnackbar ||
              templateError
            }
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity={favoritesSnackbar ? 'success' : 'error'}
            >
              {errorDeletionEquipmentRsee
                ? errorDeletionEquipmentRsee
                : favoritesSnackbar
                ? favoritesSnackbar
                : openSnackbar
                ? openSnackbar
                : templateError
                ? t('APP.EQUIPMENTLIST.LISTEQUIPMENTS.PH_B57295', {
                    defaultValue:
                      'Votre fichier doit contenir un ou plusieurs onglets avec obligatoirement les colonnes : Description, Quantité, Unité',
                  })
                : null}
            </Alert>
          </Snackbar>
          <div
            className="core-list-equipments"
            style={{
              margin: window.screen.availHeight < 950 ? '0.5em 1em' : null,
            }}
          >
            {addDisplay && !isEditMode && (
              <AddEquipment
                setOpenSnackbar={setOpenSnackbar}
                allGroups={allGroups}
                groups={groups}
                callback={callback}
                isMenuOpen={isMenuOpen}
              />
            )}

            {!addDisplay && !isEditMode && (
              <FormProvider
                getValues={getValues}
                watch={watch}
                setValue={setValue}
                reset={reset}
              >
                <div className="wrapper-table">
                  <EquipmentListFilter
                    zones={zones}
                    handleSubmitForm={handleSubmit(onSubmit)}
                    allGroups={allGroups}
                    currentProject={currentProject}
                    deleteSelection={deleteSelection}
                    filterBy={filterBy}
                    setFilterBy={setFilterBy}
                    allEquipmentRows={allEquipmentRows || []}
                    isQuickAddBarListEquipmentsOpen={isQuickAddBarListEquipmentsOpen}
                    setIsQuickAddBarListEquipmentsOpen={
                      setIsQuickAddBarListEquipmentsOpen
                    }
                    isLoadingPatchEquipments={isLoading}
                    tableEditModeRef={tableEditModeRef}
                  />
                  <div
                    ref={drop}
                    style={{
                      opacity: isOver ? 0.5 : 1,
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <>
                      {creationImport ? (
                        <LoadingBar />
                      ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <BreadcrumbModal
                            onChangeListContributor={(value) =>
                              updateFormData(
                                setValue,
                                currentLine,
                                'AllotmentNode',
                                value,
                                dispatch,
                                isUserStartEditColumns
                              )
                            }
                          />

                          <Table
                            ref={tableRef}
                            rowClassName={(record, index) => {
                              let className = '';

                              let sheet = currentProject.isRe2020
                                ? record.Re2020Sheet
                                : record;

                              if (!record.children) {
                                className += ' fontweightInitial';
                              }
                              if (record.children) {
                                className += 'table-row-expandable';
                              }
                              if (record?.isFromBundle) {
                                className += ' linesFromBundle';
                              }

                              if (record?.ProjectSheet?.options?.treatmentMode) {
                                className += ' lines-from-bbca-not-amortized';
                              }

                              if (
                                (sheet?.isDisabled && !sheet?.isProductDisabled) ||
                                (record.type_fiche === 'users' &&
                                  record.Re2020Sheet.endDateEquiv)
                              ) {
                                className += ' refurbished';
                              } else if (
                                sheet?.isProductDisabled ||
                                (sheet?.isProductDisabled &&
                                  !(
                                    record?.Re2020Sheet?.isProductDisabled ||
                                    record?.Re2020Sheet?.isDisabled
                                  ) &&
                                  record?.type_fiche !== 'users')
                              ) {
                                className += ' product-disabled';
                              }

                              if (
                                window.screen.availHeight > 600 &&
                                window.screen.availHeight <= 800
                              ) {
                                className += ' fontSizeMinMin';
                              }

                              if (
                                window.screen.availHeight > 800 &&
                                window.screen.availHeight < 950
                              ) {
                                className += ' fontSizeMin';
                              }

                              if (
                                index % 2 === 0 &&
                                !sheet?.isDisabled &&
                                !sheet?.isProductDisabled
                              ) {
                                // className += ' background-on-hover';
                              }
                              let key =
                                typeof record.key === 'string'
                                  ? Math.random()
                                  : record.key;
                              return `${className} ${key}`;
                            }}
                            scrollToFirstRowOnChange={false}
                            virtual
                            loading={isLoadingProject || !allEquipmentRows}
                            rowSelection={rowSelection}
                            columns={columns}
                            indentSize={45}
                            dataSource={[...dataTable]}
                            pagination={false}
                            style={{ background: '#F4F7FC' }}
                            rowKey={(record) => record.key}
                            onChange={(pagination, filters, sorter) => {
                              const order = JSON.stringify({
                                [sorter.field]: sorter.order,
                              });

                              setListEquipementSortOrder(order);
                            }}
                            expandable={expandable}
                            expandedRowKeys={
                              listEquipementCollapsedRows &&
                              listEquipementCollapsedRows[groupBy]
                                ? listEquipementCollapsedRows[groupBy]
                                : []
                            }
                            onExpandedRowsChange={handleRowExpand}
                            showSorterTooltip={false}
                            scroll={{
                              y: (window.outerHeight - 500) * 0.9,
                              x: 'max-content',
                            }}
                            summary={(dataTable) => {
                              return (
                                <Table.Summary fixed>
                                  <FooterTable
                                    dataTable={dataTable}
                                    columns={columns}
                                    selectedRowKeys={selectedRowKeys}
                                    allEquipmentRows={allEquipmentRows || []}
                                  />
                                </Table.Summary>
                              );
                            }}
                          />
                        </form>
                      )}
                    </>
                  </div>
                </div>
              </FormProvider>
            )}
          </div>
          <DedModal />
          <ImportModal
            setOpenSnackbar={setOpenSnackbar}
            fetchImport={fetchImport}
            setImportError={setImportError}
            setTemplateError={setTemplateError}
          />
        </div>
      )}

      {isCalculatorActived && (
        <>
          <Calculator groups={groups} />
        </>
      )}
    </div>
  );
};

export default ListEquipments;
