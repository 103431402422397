import './projectCard.scss';
import { ProjectDTO } from '../../../../models/ProjectDTO';
import { TextField, Tooltip } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import React, { useState } from 'react';
import formatNumber, { getFormattedWeightNumber, getUnitConversion } from '../../../../services/mathematics/formatNumber';

import { StateDTO } from '../../../../models/GeneralDTO';
import { Trans, date, select } from 'react-i18next/icu.macro';
import { displayResultImpact } from '../../../../shared/utils';
import { formatDate } from '../../../../shared/utilsFunction';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUpdateProject } from '../../../../../hooks/useQuery/projects/patchProject';
import ExploitationElement from './ExploitationElement';
import dayjs from 'dayjs';
import sheetToActualiseIcon from '../../../../../assets/images/project/sheetToActualise.png';
import {
  ALLOTMENT_IDS,
  PROJECT_TYPE_TITLE,
  TEMPLATE_TYPE_NAME,
} from '../../../../globalVariable/typeProject';
import { CurrentAllotmentDTO } from '../../../../models/ProjectsDTO';

type DynamicFooterProps = {
  project: ProjectDTO;
  cardHeader?: boolean;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    // border: '1px solid',
    padding: theme.spacing(1),
    // margin: '4px',

    // borderColor: '1px solid black',
    backgroundColor: theme.palette.background.paper,
  },
}));

const getScoreColorHex = (score) => {
  score = Math.max(0, Math.min(100, score));

  let color;

  if (score <= 10) {
    color = interpolateColors('#F46666', '#EFB82C', score / 10);
  } else if (score <= 20) {
    color = interpolateColors('#EFB82C', '#FCDB86', (score - 10) / 10);
  } else if (score <= 30) {
    color = interpolateColors('#FCDB86', '#FFEC85', (score - 20) / 10);
  } else if (score <= 40) {
    color = interpolateColors('#FFEC85', '#BEE5A6', (score - 30) / 10);
  } else if (score <= 50) {
    color = interpolateColors('#BEE5A6', '#7CDB8E', (score - 40) / 10);
  } else {
    color = '#7CDB8E';
  }

  return color;
};

const interpolateColors = (color1, color2, ratio) => {
  const r1 = parseInt(color1.substring(1, 3), 16);
  const g1 = parseInt(color1.substring(3, 5), 16);
  const b1 = parseInt(color1.substring(5, 7), 16);

  const r2 = parseInt(color2.substring(1, 3), 16);
  const g2 = parseInt(color2.substring(3, 5), 16);
  const b2 = parseInt(color2.substring(5, 7), 16);

  const r = Math.round(r1 + (r2 - r1) * ratio);
  const g = Math.round(g1 + (g2 - g1) * ratio);
  const b = Math.round(b1 + (b2 - b1) * ratio);

  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}`;
};

const returnDoubleCalculName = (
  projectTypeName: string,
  currentAllotment: CurrentAllotmentDTO,
  calculName: string
): string => {
  return [TEMPLATE_TYPE_NAME.RE2020, TEMPLATE_TYPE_NAME.EPCM].includes(
    projectTypeName
  ) && currentAllotment?.id === ALLOTMENT_IDS.NOOCO
    ? 'Nooco'
    : calculName;
};

const returnCalculName = (projectTypeName: string) => {
  return projectTypeName === TEMPLATE_TYPE_NAME.RE2020
    ? PROJECT_TYPE_TITLE.RE2020
    : projectTypeName === TEMPLATE_TYPE_NAME.EPCM
    ? PROJECT_TYPE_TITLE.EPCM
    : projectTypeName === TEMPLATE_TYPE_NAME.BBCA
    ? PROJECT_TYPE_TITLE.BBCA
    : PROJECT_TYPE_TITLE.NOOCO;
};

const DynamicFooter = ({ project, cardHeader = false }: DynamicFooterProps) => {
  const { currentProject, currentAllotment } = useSelector(
    (state: StateDTO) => state.projects
  );
  const { t } = useTranslation();

  const projectTypeName = project?.ProjectType?.name;
  const isDoubleCalcul = currentProject?.ProjectOption?.showAlternativeResult;
  const calculName = !isDoubleCalcul
    ? returnCalculName(projectTypeName)
    : returnDoubleCalculName(
        projectTypeName,
        currentAllotment,
        returnCalculName(projectTypeName)
      );
const referentialName = calculName.toLowerCase()
  const isProjectTypeEPCM = [
    TEMPLATE_TYPE_NAME.EPCM,
    TEMPLATE_TYPE_NAME.BBCA,
  ].includes(projectTypeName);
  const formattedAcvValue =
    projectTypeName === TEMPLATE_TYPE_NAME.GLOBAL_MONITORING
      ? project.staticAcv
      : referentialName === TEMPLATE_TYPE_NAME.RE2020
      ? project.dynamicRe2020Acv
      : isProjectTypeEPCM
      ? project.staticRe2020Acv
      : project.dynamicAcv;
  const doubleCalculValueRegularProject = [
    TEMPLATE_TYPE_NAME.EPCM,
    TEMPLATE_TYPE_NAME.BBCA,
  ].includes(projectTypeName)
    ? project.staticRe2020Acv
    : project.dynamicRe2020Acv;
  const doubleCalculValueNoocoProject = [
    TEMPLATE_TYPE_NAME.EPCM,
    TEMPLATE_TYPE_NAME.BBCA,
  ].includes(projectTypeName)
    ? project.staticAcv
    : project.dynamicAcv;

  const lifespanLeft = () => {
    const moment = require('moment');
    const ddv = project.ddv;
    if (!project?.exploitContract) return;
    const startDate = project?.exploitContract[0]?.value;
    const currentDate = moment();

    // Calculer la durée écoulée depuis le début de vie du bâtiment
    const elapsedTime = moment.duration(currentDate.diff(startDate));

    // Calculer la durée de vie restante
    const remainingTime = moment.duration(ddv, 'years').subtract(elapsedTime);

    // Obtenir la durée restante sous forme d'années, de mois et de jours
    const remainingYears = remainingTime.years();
    const remainingMonths = remainingTime.months();

    const result = () => {
      if (remainingYears < 0) {
        return 0;
      }

      if (remainingMonths >= 6) {
        return remainingYears + 1;
      }

      return remainingYears;
    };

    return result();
  };

  let verificationPercentage =
    currentProject.Sheets?.length === 0
      ? null
      : currentProject.VerificationData?.level;

  let stateOfVerification =
    currentProject.Sheets?.length === 0
      ? { backgroundColor: 'grey', className: '', color: '#825245' }
      : verificationPercentage <= 50
      ? { backgroundColor: '#ea1710', className: 'error', color: '#825245' }
      : verificationPercentage <= 80
      ? { backgroundColor: '#feea00', className: 'warning', color: '#66561A' }
      : verificationPercentage === 100
      ? { backgroundColor: '#35cb4a', className: 'success', color: '#5BA46B' }
      : { backgroundColor: '#bfdc6f', className: 'success', color: '#6B9576' };

  const returnCircleOfVerification = () => {
    return (
      <div
        style={{
          backgroundColor: stateOfVerification.backgroundColor,
          border: 'none',
        }}
        className={`circle-option-render ${stateOfVerification.className}`}
      ></div>
    );
  };

  return (
    <>
      {/* footer for all projects with simple calcul expect exploitation */}
      {projectTypeName !== 'exploitation' ? (
        <div
          style={{ width: '100%' }}
          className={cardHeader ? 'header-wrapper' : ''}
        >
          {cardHeader && (
            <Tooltip
              title={t('APP.PROJECTCARD.FOOTERCARD.VERIFICATION_TOOLTIP', {
                defaultValue:
                  'Le niveau de vérification du projet correspond au pourcentage de lignes vérifiées',
              })}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p className="title">
                  {t('APP.PROJECTCARD.FOOTERCARD.LEVEL_OF_VERIFICATION', {
                    defaultValue: 'Niveau de vérification :',
                  })}
                </p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {returnCircleOfVerification()}
                  {verificationPercentage && (
                    <p
                      style={{
                        color: stateOfVerification.color,
                        fontWeight: '600',
                        marginLeft: '5px',
                      }}
                    >
                      {formatNumber(verificationPercentage)}%
                    </p>
                  )}
                </div>
              </div>
            </Tooltip>
          )}
          <div className={`calculDynamic ${cardHeader ? 'cardHeader' : ''}`}>
            <Tooltip
              title={
                isProjectTypeEPCM
                  ? t('APP.PROJECTCARD.FOOTERCARD.PH_84FA93', {
                      defaultValue:
                        'Impact total du projet avec prise en compte des bénéfices et charges au-delà du cycle de vie du bâtiment',
                    })
                  : ''
              }
            >
              <div data-testid="headerComputeResultTitle" className="title">
                {`${t('APP.PROJECTCARD.FOOTERCARD.PH_203528', {
                  defaultValue: 'Calcul',
                })} ${
                  isProjectTypeEPCM ||
                  projectTypeName === TEMPLATE_TYPE_NAME.GLOBAL_MONITORING
                    ? ''
                    : t('APP.PROJECTCARD.FOOTERCARD.PH_CE68FE', {
                        defaultValue: 'dynamique',
                      })
                } ${calculName} :`}
              </div>
            </Tooltip>
            <div className="result" data-testid="headerComputeResultValue">
                {`${getFormattedWeightNumber(project?.refUnit === 'm²' ? formattedAcvValue / project?.area : formattedAcvValue)} ${project?.refUnit === 'm²' ? `${getUnitConversion(project?.refUnit === 'm²' 
                ? formattedAcvValue / project?.area : formattedAcvValue)}/m²` 
                : `${getUnitConversion(project?.refUnit === 'm²' ? formattedAcvValue / project?.area : formattedAcvValue)}`}`}
            </div>
          </div>
          <div className={`optimisation ${cardHeader ? 'cardHeader' : ''}`}>
            {!cardHeader && (
              <div className="title">
                {t('APP.PROJECTCARD.FOOTERCARD.PH_0BC3CE', {
                  defaultValue: 'Optimisation :',
                })}
              </div>
            )}
            <div className="slider-container">
              <div
                className="slider"
                style={{
                  width: `${Math.round(project.optiScore * 100)}%`,
                  backgroundColor: getScoreColorHex(
                    Math.round(project.optiScore * 100)
                  ),
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        //  : projectTypeName !== 'exploitation' && isDoubleCalcul && cardHeader ? (
        //   <>
        //       {/* footer for all projects with double calcul expect exploitation and only for headerCard */}
        //     <div className="doubleCalculWrapper">
        //       <div className="doubleCalcul">
        //         <div className={`calculContainer`}>
        //           <Tooltip
        //             title={
        //               isProjectTypeEPCM
        //                 ? t('APP.PROJECTCARD.FOOTERCARD.PH_093B70', {
        //                     defaultValue:
        //                       'Impact total du projet avec prise en compte des bénéfices et charges au-delà du cycle de vie du bâtiment',
        //                   })
        //                 : ''
        //             }
        //           >
        //             <div className="title">
        //               {`${t('APP.PROJECTCARD.FOOTERCARD.PH_A02BA5', {
        //                 defaultValue: 'Calcul',
        //               })} ${isProjectTypeEPCM ? 'E+C-' : 'dynamique RE2020'} : `}
        //             </div>
        //           </Tooltip>
        //           <div className="result">
        //             {`${formatNumber(
        //               project?.refUnit === 'm²'
        //                 ? doubleCalculValueRegularProject / project?.area
        //                 : doubleCalculValueRegularProject
        //             )} ${project?.refUnit === 'm²' ? 'kg eq. CO₂/m²' : 'kg eq. CO₂'}`}
        //           </div>
        //         </div>
        //         <div className={`calculContainer`}>
        //           <div className="title">
        //             {`Calcul  ${
        //               isProjectTypeEPCM ? 'statique' : 'dynamique'
        //             } Nooco : `}
        //           </div>
        //           <div className="result">
        //             {`${formatNumber(
        //               project?.refUnit === 'm²'
        //                 ? doubleCalculValueNoocoProject / project?.area
        //                 : doubleCalculValueNoocoProject
        //             )} ${project?.refUnit === 'm²' ? 'kg eq. CO₂/m²' : 'kg eq. CO₂'}`}
        //           </div>
        //         </div>
        //       </div>
        //       <div className="doubleOptimisation">
        //         <div className="title">
        //           {t('APP.PROJECTCARD.FOOTERCARD.PH_11305E', {
        //             defaultValue: 'Optimisation :',
        //           })}
        //         </div>
        //         <div className="slider-container">
        //           <div
        //             className="slider"
        //             style={{
        //               width: `${Math.round(project.optiScore * 100)}%`,
        //               backgroundColor: getScoreColorHex(
        //                 Math.round(project.optiScore * 100)
        //               ),
        //             }}
        //           />
        //         </div>
        //       </div>
        //     </div>
        //   </>
        // )
        <div className="exploitation">
          <div className={`header ${cardHeader ? 'cardHeader' : ''}`}>
            {!cardHeader &&
              project?.exploitContract &&
              t(
                'APP.PROJECTCARD.FOOTERCARD.PH_45CCB2',

                {
                  lifespanLeft: lifespanLeft(),
                  durationContractStart: formatDate(
                    project?.exploitContract[0]?.value
                  ),
                  durationContractEnd: formatDate(
                    project?.exploitContract[1]?.value
                  ),
                  defaultValue:
                    'Bâtiment: Reste à vivre = {lifespanLeft} ans   |   Durée du contrat : {durationContractStart} - {durationContractEnd}',
                }
              )}
          </div>
          <div className="main">
            <ExploitationElement
              name={t('APP.PROJECTCARD.FOOTERCARD.PH_EE828C', {
                defaultValue: "Impact de l'exploitation",
              })}
              value={displayResultImpact(
                project.EmissionValues ? project?.EmissionValues[0].Global2 : 0,
                project,
                'kg éq. CO₂'
              )}
              logoName={'impact'}
              cardHeader={cardHeader}
            />

            <ExploitationElement
              name={t('APP.PROJECTCARD.FOOTERCARD.PH_8B5DF9', {
                defaultValue: 'Trajectoire de réference',
              })}
              value={displayResultImpact(
                project.EmissionValues ? project?.EmissionValues[0].Global1 : 0,
                project,
                'kg éq. CO₂'
              )}
              logoName={'referenceTrajectory'}
              cardHeader={cardHeader}
            />

            <ExploitationElement
              name={t('APP.PROJECTCARD.FOOTERCARD.PH_8A58B6', {
                defaultValue: 'Trajectoire actualisée',
              })}
              value={displayResultImpact(
                project.EmissionValues ? project?.EmissionValues[0].TOTAL : 0,
                project,
                'kg éq. CO₂'
              )}
              logoName={'updatedTrajectory'}
              cardHeader={cardHeader}
            />
          </div>
        </div>
      )}
    </>
  );
};

type FooterCardProps = {
  project: ProjectDTO;
  cardHeader?: boolean;
};
export const FooterCard = ({ project, cardHeader = false }: FooterCardProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isOpen: isOpenFavorite } = useSelector(
    (state: StateDTO) => state.favorites
  );
  const { currentProject } = useSelector((state: StateDTO) => state.projects);
  const isCommentFilled = project?.comment;
  const [editCommentInput, setEditCommentInput] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen(!open);
  };
  const isIniesWarning =
    currentProject?.Sheets?.find(
      (el) => el.endDate || el.isDisabled || (el.ReEqs && el.ReEqs[0]?.endDate)
    ) !== undefined;

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const updateProject = useUpdateProject();

  const handleEditComment = () => {
    updateProject.mutate({
      projectId: project.id,
      body: {
        comment: editCommentInput,
      },
    });
    setEditCommentInput('');
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <div className={`footerCardWrapper ${cardHeader ? 'cardHeader' : ''} `}>
      <DynamicFooter project={project} cardHeader={cardHeader} />

      {cardHeader && isIniesWarning && (
        <div className={`sheetToActualise ${isOpenFavorite ? 'favoriteOpen' : ''}`}>
          <Tooltip
            title={t('APP.PROJECTCARD.FOOTERCARD.PH_3F326D', {
              defaultValue:
                'Des fiches INIES ont été modifiées et/ou supprimées sur ce projet',
            })}
          >
            <img src={sheetToActualiseIcon} alt="" />
          </Tooltip>
        </div>
      )}

      <div
        className={`comment ${isCommentFilled ? 'filled' : ''} ${
          isOpenFavorite ? 'favoriteOpen' : ''
        }`}
      >
        <Popper
          disablePortal={true}
          open={open}
          anchorEl={anchorEl}
          placement={`${cardHeader ? 'bottom-end' : 'left'}`}
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: 'scrollParent',
            },
            arrow: {
              enabled: true,
              // element: arrowRef,
            },
          }}
        >
          <div
            className={classes.paper}
            style={{
              width: '200px',
              minHeight: '150px',
              border: '1px solid black',
              display: 'flex',
              alignItems: 'flex-end',
              marginRight: '1rem',
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            // onMouseLeave={handleClose}
          >
            {open && (
              <TextField
                autoFocus
                // id="standard-basic"
                id="filled-multiline-flexible"
                multiline
                // rows={4}
                value={editCommentInput}
                onChange={(e) => {
                  setEditCommentInput(e.currentTarget.value);
                }}
                onBlur={handleClose}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleEditComment();
                  }
                }}
              />
            )}
          </div>
        </Popper>

        <Tooltip
          title={
            project?.comment
              ? project?.comment
              : t('APP.PROJECTCARD.FOOTERCARD.PH_51EA9A', {
                  defaultValue: 'Vous pouvez ajouter un commentaire',
                })
          }
          placement="top"
        >
          <svg
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setEditCommentInput(project?.comment);
              handleClick(e);
            }}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_615_3776)">
              <path
                className={`backgroundSvg ${isCommentFilled ? 'filled' : ''}`}
                d="M25.4036 6.60367C24.187 5.383 22.7432 4.41235 21.1536 3.74653C19.5232 3.06153 17.772 2.71055 16.0036 2.71439H15.9429C12.3821 2.72867 9.05 4.12153 6.55714 6.63939C4.06071 9.15724 2.7 12.5001 2.71428 16.0608C2.725 18.2144 3.26071 20.3537 4.26786 22.243L4.42857 22.543V27.5715H9.45714L9.75714 27.7322C11.6464 28.7394 13.7857 29.2751 15.9393 29.2858H16C19.5357 29.2858 22.8571 27.9215 25.3607 25.443C27.875 22.9501 29.2714 19.618 29.2929 16.0608C29.3 14.2537 28.9536 12.5037 28.2607 10.8537C27.5945 9.26432 26.6239 7.82056 25.4036 6.60367ZM8.85714 17.7144C8.40854 17.7052 7.98141 17.5206 7.66739 17.2001C7.35337 16.8796 7.17749 16.4488 7.17749 16.0001C7.17749 15.5514 7.35337 15.1206 7.66739 14.8001C7.98141 14.4796 8.40854 14.295 8.85714 14.2858C9.30574 14.295 9.73287 14.4796 10.0469 14.8001C10.3609 15.1206 10.5368 15.5514 10.5368 16.0001C10.5368 16.4488 10.3609 16.8796 10.0469 17.2001C9.73287 17.5206 9.30574 17.7052 8.85714 17.7144ZM16 17.7144C15.5514 17.7052 15.1243 17.5206 14.8102 17.2001C14.4962 16.8796 14.3203 16.4488 14.3203 16.0001C14.3203 15.5514 14.4962 15.1206 14.8102 14.8001C15.1243 14.4796 15.5514 14.295 16 14.2858C16.4486 14.295 16.8757 14.4796 17.1897 14.8001C17.5038 15.1206 17.6796 15.5514 17.6796 16.0001C17.6796 16.4488 17.5038 16.8796 17.1897 17.2001C16.8757 17.5206 16.4486 17.7052 16 17.7144ZM23.1429 17.7144C22.6943 17.7052 22.2671 17.5206 21.9531 17.2001C21.6391 16.8796 21.4632 16.4488 21.4632 16.0001C21.4632 15.5514 21.6391 15.1206 21.9531 14.8001C22.2671 14.4796 22.6943 14.295 23.1429 14.2858C23.5915 14.295 24.0186 14.4796 24.3326 14.8001C24.6466 15.1206 24.8225 15.5514 24.8225 16.0001C24.8225 16.4488 24.6466 16.8796 24.3326 17.2001C24.0186 17.5206 23.5915 17.7052 23.1429 17.7144Z"
                fill="current"
              />
              <path
                className={`dots ${isCommentFilled ? 'filled' : ''}`}
                d="M21.4286 15.9999C21.4286 16.4546 21.6092 16.8906 21.9307 17.2121C22.2522 17.5336 22.6882 17.7142 23.1429 17.7142C23.5975 17.7142 24.0336 17.5336 24.3551 17.2121C24.6766 16.8906 24.8572 16.4546 24.8572 15.9999C24.8572 15.5453 24.6766 15.1092 24.3551 14.7877C24.0336 14.4663 23.5975 14.2856 23.1429 14.2856C22.6882 14.2856 22.2522 14.4663 21.9307 14.7877C21.6092 15.1092 21.4286 15.5453 21.4286 15.9999ZM7.14288 15.9999C7.14288 16.4546 7.32349 16.8906 7.64499 17.2121C7.96648 17.5336 8.40251 17.7142 8.85717 17.7142C9.31183 17.7142 9.74786 17.5336 10.0694 17.2121C10.3908 16.8906 10.5715 16.4546 10.5715 15.9999C10.5715 15.5453 10.3908 15.1092 10.0694 14.7877C9.74786 14.4663 9.31183 14.2856 8.85717 14.2856C8.40251 14.2856 7.96648 14.4663 7.64499 14.7877C7.32349 15.1092 7.14288 15.5453 7.14288 15.9999Z"
                fill="current"
              />
              <path
                d="M30.7572 9.8C29.95 7.88214 28.7929 6.16071 27.3179 4.68214C25.8532 3.21213 24.1144 2.04365 22.2 1.24286C20.2357 0.417857 18.15 0 16 0H15.9286C13.7643 0.0107143 11.6679 0.439286 9.69645 1.28214C7.79844 2.09115 6.07596 3.2617 4.62502 4.72857C3.16431 6.20357 2.01788 7.91786 1.22502 9.82857C0.403591 11.8071 -0.0106948 13.9107 1.95113e-05 16.075C0.0121383 18.5553 0.598919 20.999 1.71431 23.2143V28.6429C1.71431 29.0786 1.88739 29.4964 2.19549 29.8045C2.50358 30.1126 2.92145 30.2857 3.35716 30.2857H8.78931C11.0046 31.4011 13.4483 31.9879 15.9286 32H16.0036C18.1429 32 20.2179 31.5857 22.1715 30.775C24.0762 29.9838 25.8085 28.8289 27.2715 27.375C28.7465 25.9143 29.9072 24.2071 30.7179 22.3036C31.5607 20.3321 31.9893 18.2357 32 16.0714C32.0107 13.8964 31.5893 11.7857 30.7572 9.8ZM25.3607 25.4429C22.8572 27.9214 19.5357 29.2857 16 29.2857H15.9393C13.7857 29.275 11.6465 28.7393 9.75716 27.7321L9.45716 27.5714H4.42859V22.5429L4.26788 22.2429C3.26073 20.3536 2.72502 18.2143 2.71431 16.0607C2.70002 12.5 4.06073 9.15714 6.55716 6.63929C9.05002 4.12143 12.3822 2.72857 15.9429 2.71429H16.0036C17.7893 2.71429 19.5215 3.06071 21.1536 3.74643C22.7465 4.41429 24.175 5.375 25.4036 6.60357C26.6286 7.82857 27.5929 9.26071 28.2607 10.8536C28.9536 12.5036 29.3 14.2536 29.2929 16.0607C29.2715 19.6179 27.875 22.95 25.3607 25.4429Z"
                fill="current"
              />
              <path
                className={`dots ${isCommentFilled ? 'filled' : ''}`}
                d="M14.2857 15.9999C14.2857 16.4546 14.4663 16.8906 14.7878 17.2121C15.1093 17.5336 15.5453 17.7142 16 17.7142C16.4546 17.7142 16.8907 17.5336 17.2122 17.2121C17.5337 16.8906 17.7143 16.4546 17.7143 15.9999C17.7143 15.5453 17.5337 15.1092 17.2122 14.7877C16.8907 14.4663 16.4546 14.2856 16 14.2856C15.5453 14.2856 15.1093 14.4663 14.7878 14.7877C14.4663 15.1092 14.2857 15.5453 14.2857 15.9999Z"
                fill="current"
              />
            </g>
            <defs>
              <clipPath id="clip0_615_3776">
                <rect width="32" height="32" />
              </clipPath>
            </defs>
          </svg>
        </Tooltip>
      </div>
    </div>
  );
};
