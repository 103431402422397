import { AnimatePresence, motion } from 'framer-motion';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Grid,
  InfiniteLoader,
  List,
} from 'react-virtualized';
import { useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import { useUpdateEffect } from 'react-use';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useRef } from 'react';
import SearchBar from '../../rights/SearchBar/SearchBar';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import CFA from '../../../assets/images/products/listPics/cfa-removebg-preview.png';
import CFO from '../../../assets/images/products/listPics/cfo-removebg-preview.png';
import CVC from '../../../assets/images/products/listPics/cvc-removebg-preview.png';
import Cloison from '../../../assets/images/products/listPics/Cloison-removebg-preview.png';
import Couv from '../../../assets/images/products/listPics/couv-removebg-preview.png';
import Deplacement from '../../../assets/images/products/listPics/deplacement-removebg-preview.png';
import Eau from '../../../assets/images/products/listPics/eau-removebg-preview.png';
import EclairagesPublic from '../../../assets/images/products/EclairagesPublic.png';
import Energy from '../../../assets/images/products/listPics/energy-removebg-preview.png';
import Façades from '../../../assets/images/products/listPics/façades-removebg-preview.png';
import Infra from '../../../assets/images/products/listPics/infra-removebg-preview.png';
import Menuiserie from '../../../assets/images/products/listPics/Menuiserie-removebg-preview.png';
import Plomberie from '../../../assets/images/products/listPics/plomberie-removebg-preview.png';
import Réseaux from '../../../assets/images/products/Réseaux.png';
import SecuriteIncendie from '../../../assets/images/products/SecuriteIncendie.svg';
import Superstructure from '../../../assets/images/products/listPics/superstructure-removebg-preview.png';
import SystemesTriBagage from '../../../assets/images/products/SystemesTriBagage.png';
import Terre from '../../../assets/images/products/listPics/terre-removebg-preview.png';
import TransportsEngins from '../../../assets/images/products/TransportsEngins.png';
import VRD from '../../../assets/images/products/listPics/vrd-removebg-preview.png';
import defaultPic from '../../../assets/images/products/default.png';
import IRVE from '../../../assets/images/products/IRVE.svg';
import PIF from '../../../assets/images/products/PIF.svg';
import OuvrageArt from '../../../assets/images/products/OuvrageArt.svg';
import Fluide from '../../../assets/images/products/Fluide.svg';
import EnergieSimplifieePic from '../../../assets/images/products/EnergieSimplifiee.svg';

import './productsList.scss';
import { BrandDTO, ProductDTO } from '../../models/ProductDTO';

import { StateDTO } from '../../models/GeneralDTO';
import { formatViewCard } from '../../shared/utils';
import { off } from 'process';
import { useSelector, useDispatch } from 'react-redux';
import FavoritesIcons from '../../shared/FavoritesIcons';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

import {
  dragAndDropContributors,
  updateFavoritesValue,
} from '../../../actions/favorites';
import { ModeNight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

type ProductsListProps = {
  updateProductsValue: Function;
  brandsSelected: BrandDTO[];
  brandsList: BrandDTO[];
  brandsFilter: BrandDTO[];
  listProducts: ProductDTO[];
  viewMode: string;
  requests: string[];
  fetchProducts: Function;
  filterListProduct: Function;
  inputSearchBar: string;
  offsetList: number;
  productView: string;
  searchRequest: number;
  allGroups: { id: number; lot: string }[];
  totalSheetCount: number;
};

const ProductsList = ({
  updateProductsValue,
  brandsSelected,
  listProducts,
  viewMode,
  requests,
  fetchProducts,
  filterListProduct,
  brandsList,
  inputSearchBar,
  offsetList,
  productView,
  searchRequest,
  allGroups,
  totalSheetCount,
  brandsFilter,
}: ProductsListProps): JSX.Element => {
  const [needRefresh, setNeedRefresh] = React.useState(false);
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state: StateDTO) => state.favorites);
  const handleChangeBrands = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue
  ): void => {
    // console.log(event.target.value, newValue);

    updateProductsValue('brandsSelected', [...newValue]);
  };

  const handleLineView = (el, style): JSX.Element => {
    // console.log(el);

    return (
      <div className="product-item" key={el.id} style={style}>
        <div className="container-badge">
          <p
            className="badge"
            style={{
              backgroundColor:
                el.type_fiche === 'DEC' || el.type_fiche === 'FORFAIT'
                  ? '#8e67ff'
                  : el.type_fiche === 'DED'
                  ? '#363e63'
                  : '#43ddc4',
              color: 'white',
            }}
          >
            {el.type_fiche}
          </p>
        </div>

        <p style={{ width: '85%' }}>{el.name}</p>
      </div>
    );
  };

  const handleCardView = ({ columnIndex, key, rowIndex, style }): JSX.Element => {
    let el = listProducts[rowIndex][columnIndex];
    // console.log('el', el, listProducts, rowIndex, columnIndex);
    if (el) {
      const md = allGroups.find(
        (ele) => ele.id === el?.AllotmentNodes[0]?.MasterDataEquipmentId
      );
      return (
        <div key={key} style={style}>
          <div className="product-item-card">
            <div className="container-badge">
              <p
                className="badge"
                style={{
                  backgroundColor:
                    el?.type_fiche === 'DEC' || el?.type_fiche === 'FORFAIT'
                      ? '#8e67ff'
                      : el?.type_fiche === 'DED'
                      ? '#363e63'
                      : '#43ddc4',
                  color: 'white',
                }}
              >
                {el?.type_fiche}
              </p>
            </div>
            {/* TODO_TRAD : fr */}
            <img
              data-testid="product-catalog-image"
              className="bg"
              src={
                md?.lot === 'Superstructure'
                  ? Superstructure
                  : md?.lot === 'CFA'
                  ? CFA
                  : md?.lot === 'Menuiseries ext.'
                  ? Menuiserie
                  : md?.lot === 'Plomberie'
                  ? Plomberie
                  : md?.lot === 'Cloison-Plafond'
                  ? Cloison
                  : md?.lot === 'Infrastructure'
                  ? Infra
                  : md?.lot === 'CVC'
                  ? CVC
                  : md?.lot === 'CFO'
                  ? CFO
                  : md?.lot === 'Façades'
                  ? Façades
                  : md?.lot === 'VRD-Aménagement'
                  ? VRD
                  : md?.lot === 'Déplacement int.'
                  ? Deplacement
                  : md?.lot === 'Terre'
                  ? Terre
                  : md?.lot === 'Couverture-étanchéité'
                  ? Couv
                  : md?.lot === 'Energie'
                  ? Energy
                  : md?.lot === 'Eau'
                  ? Eau
                  : md?.lot === 'Réseaux'
                  ? Réseaux
                  : md?.lot === 'Système tri-bagages'
                  ? SystemesTriBagage
                  : md?.lot === 'Sécurité Incendie'
                  ? SecuriteIncendie
                  : md?.lot === 'Transports et engins'
                  ? TransportsEngins
                  : md?.lot === 'Éclairage public-SLT'
                  ? EclairagesPublic
                  : md?.lot === "Ouvrage d'art"
                  ? OuvrageArt
                  : md?.lot === "Poste d'Inspection Filtrage"
                  ? PIF
                  : md?.lot === 'IRVE'
                  ? IRVE
                  : md?.lot === 'Energie simplifiée'
                  ? EnergieSimplifieePic
                  : md?.lot === 'Fluides'
                  ? Fluide
                  : defaultPic
              }
              alt=""
            />
            <div className="name-card">
              <Tooltip title={el?.name?.length > 100 ? el.name : ''}>
                <div className="cardContent">
                  <p className="cardCOntentName">
                    {el?.name?.length > 60
                      ? `${el.name.slice(0, 100)}[...]`
                      : el.name}
                  </p>

                  {el.brand ? (
                    <Tooltip className="brandTooltip" title={el.brand}>
                      <p className="cardContentBrand">
                        {el.brand && el.brand.length > 40
                          ? `${el.brand.slice(0, 25)}[...]`
                          : el.brand}
                      </p>
                    </Tooltip>
                  ) : null}
                </div>
              </Tooltip>
            </div>
            <div className="cardContentStars">
              <FavoritesIcons SheetId={el?.id} />
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleLoading = () => {
    return (
      <div className={viewMode === 'card' ? 'products-list-card-view' : undefined}>
        {[...Array(20)].map((el, i) =>
          viewMode === 'list' ? (
            <div
              key={i}
              className="product-item"
              style={{ margin: '1em 0', width: '80%' }}
            >
              <Skeleton width="5%" style={{ marginRight: '2em' }} />

              <Skeleton width="100%" />
            </div>
          ) : (
            <div
              key={i}
              style={{ margin: '2em', background: 'transparent !important' }}
            >
              <Skeleton variant="rectangular" width={210} height={118} />
              <Skeleton />
              <Skeleton width="60%" />
            </div>
          )
        )}
      </div>
    );
  };

  // Management of request when brands are selected and deselected
  useUpdateEffect(() => {
    if (brandsSelected) {
      updateProductsValue('offsetList', 0);
      updateProductsValue('nodeId', 'null');
      updateProductsValue('requests', [...requests, 1]);
      fetchProducts(null, inputSearchBar, brandsSelected);
    }
  }, [brandsSelected]);

  //Management of virtualized list and pagination with scroll
  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 200,
    })
  );

  const secondCache = useRef(
    new CellMeasurerCache({
      minWidth: 200,
      minHeight: 370,
    })
  );

  const _isRowLoaded = ({ index }) => {
    const compare = listProducts.length - 1;

    return compare === index ? false : true;
  };

  const _loadMoreRows = () => {
    updateProductsValue('offsetList', offsetList + 36);
    fetchProducts(true);
  };

  useEffect(() => {
    cache.current.clearAll();
    secondCache.current.clearAll();

    // console.log(cache);

    setNeedRefresh(true);
  }, []);

  let count = 0;

  let _onRowsRendered;

  const _onSectionRendered = ({
    columnStartIndex,
    columnStopIndex,
    rowStartIndex,
    rowStopIndex,
  }) => {
    const startIndex = rowStartIndex * 3 + columnStartIndex;
    const stopIndex = rowStopIndex * 3 + columnStopIndex;

    _onRowsRendered({ startIndex, stopIndex });
  };

  const { favoritesSnackbar } = useSelector((state) => state.favorites);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [templateError, setTemplateError] = useState(false);
  const { t } = useTranslation();

  const handleCloseSnack = () => {
    setTimeout(() => {
      setOpenSnackbar(false);
      setTemplateError(false);
      dispatch(updateFavoritesValue('favoritesSnackbar', null));
    }, 200);
  };

  useEffect(() => {
    return () => {
      dispatch(updateFavoritesValue('favoritesSnackbar', ''));
    };
  }, [dispatch]);

  return (
    <div className="products-list-wrapper">
      <div className="products-list-header" style={{ zIndex: isOpen ? 0 : 6 }}>
        <h3 data-testid="products-list-result-number">
          {t('APP.PRODUCTSLIST.INDEX.PH_614D07', {
            result: totalSheetCount ?? 0,
            defaultValue: 'Résultats {result}',
          })}
        </h3>
        <div style={{ display: 'flex', width: '77%' }}>
          <SearchBar
            dataTestId="product-catalog-search-bar"
            searchRequest={searchRequest}
            updateProductsValue={updateProductsValue}
            inputSearchBar={inputSearchBar}
            fetchProducts={fetchProducts}
            style={{
              width: productView === 'components' ? '50%' : '70%',
              margin: '0 1em',
            }}
            productsList={true}
            placeHolder={t('APP.PRODUCTSLIST.INDEX.PH_6B079C', {
              defaultValue: 'Rechercher un équipement/matériau/ID Inies',
            })}
          />

          {/* {productView === 'components' && ( */}
          <Autocomplete
            id="checkboxes-tags-demo"
            limitTags={1}
            value={brandsSelected}
            multiple
            disableCloseOnSelect
            options={brandsFilter || brandsList}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => (
              <div>
                <Checkbox />
                {option.name}
              </div>
            )}
            onChange={(event, newValue) => handleChangeBrands(event, newValue)}
            size={'small'}
            renderInput={(params) => (
              <div data-testid="brands-search">
                <TextField
                  {...params}
                  style={{ width: '270px' }}
                  variant="outlined"
                  placeholder={t('APP.PRODUCTSLIST.INDEX.PH_45F52C', {
                    defaultValue: 'Marque',
                  })}
                  className="rights-search "
                />
              </div>
            )}
          />

          {/* )} */}
        </div>

        <div className="icons-header" style={{ marginRight: '1em' }}>
          <div
            data-testid="list-view-mode"
            onClick={() => {
              updateProductsValue(
                'listProducts',
                formatViewCard([...listProducts], 'list')
              );
              updateProductsValue('viewMode', 'list');
            }}
            className={`iconbutton-img ${viewMode === 'list' ? 'active' : null}`}
          >
            <FormatListBulletedIcon
              style={{ cursor: 'pointer', color: '#A3AED0' }}
            />
          </div>
          <div
            data-testid="card-view-mode"
            className={`iconbutton-img ${viewMode === 'card' ? 'active' : null}`}
            onClick={() => {
              updateProductsValue(
                'listProducts',
                formatViewCard([...listProducts], 'card')
              );
              updateProductsValue('viewMode', 'card');
            }}
          >
            <ViewModuleIcon style={{ cursor: 'pointer', color: '#A3AED0' }} />
          </div>
        </div>
      </div>
      <div className="brands-selected">
        {brandsSelected.map((option) => (
          <>
            <Chip
              style={{
                background: `#44DDC3`,
                fontWeight: '600',
                fontSize: '11px',
                color: 'white',
                borderRadius: '0.25rem',
                border: 'none',
                height: '27px',
                margin: '0.3em',
              }}
              key={option.name}
              variant="outlined"
              label={option.name}
              onDelete={(data) => {
                updateProductsValue('brandsSelected', [...brandsSelected]);
              }}
            />
          </>
        ))}
      </div>
      <Snackbar
        open={favoritesSnackbar || openSnackbar || templateError}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={favoritesSnackbar ? 'success' : 'error'}
        >
          {favoritesSnackbar
            ? favoritesSnackbar
            : openSnackbar
            ? openSnackbar
            : templateError
            ? t('APP.PRODUCTSLIST.INDEX.PH_7C0E36', {
                defaultValue:
                  'Votre fichier doit contenir un ou plusieurs onglets avec obligatoirement les colonnes : Description, Quantité, Unité',
              })
            : null}
        </Alert>
      </Snackbar>
      <div className="products-list-content">
        {requests.length > 0 && handleLoading()}
        <AnimatePresence>
          {viewMode === 'list' &&
            requests.length === 0 &&
            cache &&
            cache.current && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ width: '100%', height: '83vh' }}
                className="products-list-line-view"
              >
                <InfiniteLoader
                  isRowLoaded={_isRowLoaded}
                  loadMoreRows={_loadMoreRows}
                  rowCount={listProducts.length}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <AutoSizer>
                      {({ width, height }) => (
                        <List
                          className="import-container-list"
                          width={width}
                          onRowsRendered={onRowsRendered}
                          ref={registerChild}
                          height={height}
                          deferredMeasurementCache={cache.current}
                          rowHeight={cache.current.rowHeight}
                          rowCount={listProducts.length}
                          rowRenderer={({ key, index, style, parent }) => {
                            return (
                              <CellMeasurer
                                key={key}
                                cache={cache.current}
                                parent={parent}
                                columnIndex={0}
                                rowIndex={index}
                              >
                                {handleLineView(listProducts[index], style)}
                                {/*  */}
                              </CellMeasurer>
                            );
                          }}
                        />
                      )}
                    </AutoSizer>
                  )}
                </InfiniteLoader>
              </motion.div>
            )}
        </AnimatePresence>
        <AnimatePresence>
          {/* {console.log(secondCache, viewMode, requests)} */}
          {viewMode === 'card' &&
            requests.length === 0 &&
            secondCache &&
            secondCache.current && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ width: '100%', height: '100vh' }}
              >
                <InfiniteLoader
                  isRowLoaded={_isRowLoaded}
                  loadMoreRows={_loadMoreRows}
                  rowCount={listProducts.length}
                >
                  {({ onRowsRendered, registerChild }) => {
                    _onRowsRendered = onRowsRendered;
                    // console.log(listProducts);

                    return (
                      <AutoSizer>
                        {({ width, height }) => (
                          <Grid
                            cellRenderer={handleCardView}
                            columnCount={3}
                            ref={registerChild}
                            columnWidth={280}
                            height={height}
                            onSectionRendered={_onSectionRendered}
                            rowCount={listProducts.length}
                            rowHeight={370}
                            width={width}
                          />
                          // <List
                          //   width={width}
                          //   onRowsRendered={onRowsRendered}
                          //   ref={registerChild}
                          //   height={height}
                          //   key={viewMode}
                          //   deferredMeasurementCache={secondCache.current}
                          //   rowHeight={secondCache.current.rowHeight}
                          //   rowCount={listProducts.length < 3 ? listProducts.length : listProducts.length / 3}
                          //   rowRenderer={({ key, index, style, parent }) => {

                          //     if(index > listProducts.length - 1) {

                          //       console.log('rien', count);

                          //       return;

                          //     }

                          //     return(
                          //       <CellMeasurer
                          //         key={key}
                          //         cache={secondCache.current}
                          //         parent={parent}
                          //         columnIndex={0}
                          //         rowIndex={index}
                          //         style={{ width: '33%' }}
                          //       >

                          //         <div style={{...style, display: 'flex'}} key={key}>

                          //           { listProducts.length ? handleCardView(listProducts[index]) : [...Array(3)].map((el, i) => {

                          //             if(index === 0 && i === 0) {

                          //               count = 0;

                          //             } else {

                          //               count+= 1;

                          //             }

                          //             if(!listProducts[count]) {

                          //               console.log('rien', count);

                          //               return null;

                          //             }

                          //             console.log(count);

                          //             return(
                          //               handleCardView(listProducts[count])
                          //             );

                          //           })}
                          //         </div>
                          //       </CellMeasurer>
                          //     );

                          //   }}
                          // />
                        )}
                      </AutoSizer>
                    );
                  }}
                </InfiniteLoader>
              </motion.div>
            )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default ProductsList;
