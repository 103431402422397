import './footerTable.scss';
import { Table } from 'antd';
import { StateDTO } from '../../../../models/GeneralDTO';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import formatNumber from '../../../../services/mathematics/formatNumber';
import { useTranslation } from 'react-i18next';

export type ColumnEquipmentDTO = {
  title: string;
  check: boolean;
  dataIndex: string;
  fixed: undefined | 'left' | 'right';
  key: string;
  render: Function;
  sorter: Function;
  width: number;
};

export type DataTableRowDTO = {
  impactRe2020: number;
  impactByUnitRe2020: number;
  globalImpactRe2020: number;
  impactByArea: number;
  impactByUnit: number;
  ProjectSheet: {
    StockC: number;
  };
  totalArea: number;
  moduleD: number;
  acvResults: {
    base: {
      end: number;
      edif: number | null;
      prod: number;
    };
    usage: {
      b1: number | null;
      b2: number | null;
      b3: number | null;
      b4: number | null;
      b5: number | null;
    };
  };
};

export type DataTableDTO = DataTableRowDTO[];

export type FooterTableProps = {
  columns: ColumnEquipmentDTO[];
  dataTable: DataTableDTO;
  selectedRowKeys: number[];
  allEquipmentRows: any[];
};

export const isTotalColumnsListDisplay = (
  column: ColumnEquipmentDTO,
  referenceSurfaceTag: string = 'totalProject'
): boolean => {
  if (referenceSurfaceTag !== 'totalProject') {
    return false;
  }

  const columnsToDisplayKeyList = [
    'impactRe2020',
    'impactByUnitRe2020',
    'globalImpactRe2020',
    'impactByArea',
    'impactByUnit',
    'Fin',
    'Entretien',
    'Edification',
    'Production',
    'stockc',
    'moduleD',
    'totalArea',
  ];

  if (columnsToDisplayKeyList.includes(column.dataIndex)) {
    return true;
  }

  return false;
};

export const totalColumnToDisplay = (
  column: ColumnEquipmentDTO,
  dataTable: DataTableDTO,
  selectedRowKeys: number[] = []
): string | number | null => {
  const getFlatDataTable = (dataTableRow) => {
    dataTableRow.forEach((row) => {
      if (row?.children) {
        getFlatDataTable(row.children);
      } else {
        flatData.push(row);
      }
    });
  };
  let flatData = [];

  getFlatDataTable(dataTable);

  let total: number | null = null;

  const impactColumnsList = [
    'impactRe2020',
    'impactByUnitRe2020',
    'globalImpactRe2020',
    'impactByArea',
    'impactByUnit',
    'Fin',
    'Entretien',
    'Edification',
    'Production',
    'stockc',
    'moduleD',
    'totalArea',
  ];

  const columnNameSource = {
    impactRe2020: 'impactRe2020',
    impactByUnitRe2020: 'impactByUnitRe2020',
    globalImpactRe2020: 'globalImpactRe2020',
    impactByArea: 'impactByArea',
    impactByUnit: 'impactByUnit',
    Fin: 'fin',
    Entretien: 'entretien',
    Edification: 'edification',
    Production: 'production',
    stockc: 'stockC',
    moduleD: 'moduleD',
    totalArea: 'totalArea',
  };

  if (impactColumnsList.includes(column.dataIndex)) {
    total = getTotalColumn(
      flatData,
      columnNameSource[column.dataIndex],
      selectedRowKeys
    );
  }

  return formatNumber(total);
};

export const getTotalColumn = (
  dataTable: DataTableDTO,
  columnKey: string,
  selectedRowKeys: number[]
): number => {
  return dataTable.reduce((acc, row) => {
    if (selectedRowKeys.length > 0) {
      if (selectedRowKeys.includes(row.key)) {
        return acc + row[columnKey];
      } else {
        return acc;
      }
    } else {
      return acc + row[columnKey];
    }
  }, 0);
};

const countChildrenRec = (dataTable) => {
  let count = 0;

  for (let data of dataTable) {
    if (data.children && data.children.length > 0) {
      count += countChildrenRec(data.children);
    } else {
      count += 1;
    }
  }

  return count;
};

export const FooterTable = ({
  columns,
  dataTable,
  selectedRowKeys,
  allEquipmentRows,
}: FooterTableProps) => {
  const { referenceSurfaceTag, filtersTableActive } = useSelector(
    (state: StateDTO) => state.equipments
  );
  const { currentProject } = useSelector((state: StateDTO) => state.projects);

  const [displayedCount, setDisplayedCount] = useState(0);
  const [selectedCount, setSelectedCount] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    setDisplayedCount(countChildrenRec(dataTable));
  }, [dataTable]);

  useEffect(() => {
    const selectedCount = allEquipmentRows
      ? allEquipmentRows.filter((row) => selectedRowKeys.includes(row.key)).length
      : 0;

    setSelectedCount(selectedCount);
  }, [selectedRowKeys]);

  const footerTotalRowsDisplay = () => {
    const isFilteredActivated = filtersTableActive?.find(
      (filterTab) => filterTab.values.length > 0
    );
    const totalCount = allEquipmentRows.length;

    if (selectedCount) {
      return t(
        'APP.FOOTERTABLE.FOOTERTABLE.PH_36452C',

        {
          selectedCount,
          totalCount,
          defaultValue:
            '{selectedCount} lignes selectionnées sur les {totalCount} du projet',
        }
      );
    }

    if (isFilteredActivated) {
      return t(
        'APP.FOOTERTABLE.FOOTERTABLE.PH_C69D0D',

        {
          displayedCount,
          totalCount,
          defaultValue:
            '{displayedCount} lignes filtrées sur {totalCount} lignes du projet',
        }
      );
    }

    return t('APP.FOOTERTABLE.FOOTERTABLE.PH_CCC919', {
      displayedCount,
      totalCount,
      defaultValue: '{displayedCount} lignes sur {totalCount} lignes du projet',
    });
  };

  let statsLines = [
    {
      type: 'success',
      count: currentProject.VerificationData?.validatedCount,
      background: '#35CB4B',
    },
    {
      type: 'warning',
      count: currentProject.VerificationData?.reliableCount,
      background: '#FAE100',
    },
    {
      type: 'error',
      count: currentProject.VerificationData?.toCheckCount,
      background: '#EA1710',
    },
  ];

  const returnCircle = (stat) => {
    return (
      <div style={{ display: 'flex', marginRight: '10px' }}>
        <span
          style={{ background: stat.background, border: 'none' }}
          className={`circle-option-render ${stat.type}`}
        ></span>
        <span style={{ marginLeft: '5px' }}>: {stat.count}</span>
      </div>
    );
  };

  const renderColumnInformation = (column, index) => {
    if (index === 0) {
      return (
        <div
          className="footer-cell"
          style={{
            textTransform: 'none',
            fontSize: '12px',
            color: '#687182',
            whiteSpace: 'nowrap',
          }}
        >
          {footerTotalRowsDisplay()}
        </div>
      );
    }
    if (index === 7) {
      return (
        <div
          className="footer-cell"
          style={{ display: 'flex', justifyContent: 'center', whiteSpace: 'nowrap' }}
        >
          {statsLines.map((stat) => returnCircle(stat))}
        </div>
      );
    }
    if (column.key?.includes('impact') || column.group?.includes('impact')) {
      return (
        <div
          style={{
            textTransform: 'none',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          {isTotalColumnsListDisplay(column, referenceSurfaceTag) &&
            totalColumnToDisplay(column, dataTable, selectedRowKeys)}
        </div>
      );
    }
  };

  const newObject: any = {
    title: '',
    dataIndex: '',
    key: '',
    filterDropdownOpen: false,
    align: '',
    group: 'carac',
    order: '5',
    check: false,
    width: 100,
  };
  const newColumns = [...columns];

  newColumns.splice(1, 0, newObject);

  return (
    <Table.Summary.Row>
      {newColumns.map((column, index) => (
        <Table.Summary.Cell key={column.key} index={index}>
          {renderColumnInformation(column, index)}
        </Table.Summary.Cell>
      ))}
      <Table.Summary.Cell index={columns.length + 1}>{''}</Table.Summary.Cell>
      <Table.Summary.Cell index={columns.length + 2}>{''}</Table.Summary.Cell>
    </Table.Summary.Row>
  );
};

// export default FooterTable;
