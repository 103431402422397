import './exportRsee.scss';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { GenerateRSEnvDTO } from '../../../../models/ProjectsDTO';
import { Input, Select } from 'antd';
import { StateDTO } from '../../../../models/GeneralDTO';
import {
  fetchRseeExport,
  postRseeExport,
  resetPostRseeExportSuccess,
  updateFormValue,
} from '../../../../../actions/projects';
import { generateRsee } from '../../../../../actions/report';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

const configLogo = require('../../../../../assets/images/creation/exploitation.png');
const batimentLogo = require('../../../../../assets/images/creation/batiment.png');

export type AppProps = {
  setShow: Function;
};

export type Nav = 'next' | 'previous';

let formData: Data[] = [];
// Datas from json for select option and value
export type Data = {
  categoryIndex: number;
  category: string;
  items: {
    id: number;
    ref:
      | 'type_structure_principale'
      | 'elements_prefabriques'
      | 'materiau_principal'
      | 'materiau_remplissage_facade'
      | 'mur_mode_isolation'
      | 'mur_nature_isolant'
      | 'mur_revetement_ext'
      | 'type_fondation'
      | 'type_plancher'
      | 'plancher_mode_isolation'
      | 'plancher_nature_isolant'
      | 'plancher_nature_espace'
      | 'type_toiture'
      | 'toiture_mode_isolation'
      | 'toiture_nature_isolant'
      | 'toiture_vegetalisee'
      | 'toiture_couverture'
      | 'type_menuiserie'
      | 'type_pm'
      | 'vecteur_energie_principal_ch'
      | 'vecteur_energie_principal_ecs'
      | 'vecteur_energie_principal_fr'
      | 'generateur_principal_ch'
      | 'generateur_ch_liaison'
      | 'emetteur_principal_ch'
      | 'type_ventilation_principale'
      | 'gestion_active'
      | 'type_eclairage';
    options: { [key: string]: number }[];
    categoryIndex: number;
    category: string;
    page: number;
    name: string;
  }[];
};

export default function ExportRsee({ setShow }: AppProps) {
  const {
    isLoadingGenerateRSEnv,
    generateRSEnv,
    currentProject,
    postRseeExportSuccess,
  } = useSelector((state: StateDTO) => state.projects);

  const dispatch = useDispatch();
  const [indexNav, setIndexNav] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(updateFormValue('isLoadingGenerateRSEnv', true));
    dispatch(fetchRseeExport());
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
    getValues,
    register,
  } = useForm<GenerateRSEnvDTO>({
    mode: 'onChange',
    defaultValues: {
      acvDesignOffice: generateRSEnv.acvDesignOffice,
      earthQuakeZone: generateRSEnv.earthQuakeZone,
      buildings: generateRSEnv.buildings,
      digitalModel: generateRSEnv.digitalModel,
      projectOwnerSiret: generateRSEnv.projectOwnerSiret,
      projectOwnerType: generateRSEnv.projectOwnerType,
      rsenvVersion: generateRSEnv.rsenvVersion,
    },
  });

  // For gestion of array in useForm
  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'buildings', // unique name for your Field Array
  });

  const indexMax = fields.length;

  const [isFormCompleted, setIsFormCompleted] = useState(false);

  const extractRefsFromFormData = (formData: Data[]) => {
    const refs: string[] = [];

    for (const category of formData) {
      for (const item of category.items) {
        refs.push(item.ref);
      }
    }

    return refs;
  };

  const allRefsFromFormData = generateRSEnv.formData
    ? extractRefsFromFormData(generateRSEnv.formData)
    : undefined;

  const isBuildingsCompleted = (buildings: {}[]): boolean => {
    let formCompleted = true;

    for (const building of buildings) {
      for (const key in building) {
        const value = building[key];

        if (
          value === undefined ||
          (value === null && allRefsFromFormData?.includes(key))
        ) {
          formCompleted = false;
          break;
        }
      }
      if (!formCompleted) {
        break;
      }
    }
    return formCompleted;
  };

  const handleChange = () => {
    const buildings = getValues('buildings');

    setIsFormCompleted(isBuildingsCompleted(buildings));
  };

  // Update data in useForm when receive data from get
  useEffect(() => {
    reset({
      acvDesignOffice: generateRSEnv.acvDesignOffice,
      earthQuakeZone: generateRSEnv.earthQuakeZone,
      buildings: generateRSEnv.buildings,
      digitalModel: generateRSEnv.digitalModel,
      projectOwnerSiret: generateRSEnv.projectOwnerSiret,
      projectOwnerType: generateRSEnv.projectOwnerType,
      rsenvVersion: generateRSEnv.rsenvVersion,
    });

    formData = generateRSEnv.formData;

    const buildings = getValues('buildings');
    setIsFormCompleted(isBuildingsCompleted(buildings));
  }, [generateRSEnv]);

  // Save and quit
  const onSubmit = (data: any) => {
    if (data.acvDesignOffice.postal === '') {
      data.acvDesignOffice.postal = null;
    }

    dispatch(postRseeExport(data));
    setShow(false);
  };

  useEffect(() => {
    if (postRseeExportSuccess) {
      dispatch(generateRsee(currentProject.id, currentProject.name));
      setShow(false);
      dispatch(resetPostRseeExportSuccess());
    }
  }, [postRseeExportSuccess]);

  const onGenerateSubmit = (data: any) => {
    dispatch(postRseeExport(data, true));
  };

  const onSubmitError = () => {};

  // Next button
  const onNextSubmit = (data: any) => {
    dispatch(postRseeExport(data));
    navigation('next');
  };

  const navigation = (nav: Nav) => {
    if (nav === 'next' && indexNav !== indexMax) {
      setIndexNav(indexNav + 1);
    }

    if (nav === 'previous' && indexNav !== 0) {
      setIndexNav(indexNav - 1);
    }
  };

  return (
    <div
      className="exportRseeContainer"
      style={{ alignItems: isLoadingGenerateRSEnv ? 'center' : '' }}
    >
      {isLoadingGenerateRSEnv && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loader
            variant="indeterminate"
            disableShrink
            style={{ display: 'block', color: '#73b7ec' }}
            size={70}
            thickness={4}
          />
        </div>
      )}
      {!isLoadingGenerateRSEnv && (
        <>
          {/* sidebar */}
          <div className="exportRseeContainer__menu">
            <div className="exportRseeContainer__menu--item-smallTitle">
              <h3>
                {t('APP.EXPORTRSEE.EXPORTRSEE.PH_5C6C17', {
                  version: generateRSEnv.rsenvVersion,
                  defaultValue: 'Version du RSEnv : {version}',
                })}
              </h3>
            </div>
            <div
              className={
                indexNav === 0
                  ? 'exportRseeContainer__menu--item active'
                  : 'exportRseeContainer__menu--item '
              }
              onClick={() => {
                setIndexNav(0);
              }}
            >
              <div className="exportRseeContainer__menu--item-img">
                <img src={configLogo} alt="" />
              </div>
              <div className="exportRseeContainer__menu--item-detail">
                <h3>
                  {t('APP.EXPORTRSEE.EXPORTRSEE.PH_41E0AA', {
                    defaultValue: 'Informations',
                  })}
                </h3>
                <h4>{currentProject.name}</h4>
              </div>
            </div>
            {fields.map((item, index) => (
              <div
                key={index}
                className={
                  indexNav === index + 1
                    ? 'exportRseeContainer__menu--item active'
                    : 'exportRseeContainer__menu--item '
                }
                onClick={() => {
                  if (isValid) {
                    setIndexNav(index + 1);
                  }
                }}
              >
                <div className="exportRseeContainer__menu--item-img">
                  <img src={batimentLogo} alt="" />
                </div>
                <div className="exportRseeContainer__menu--item-detail">
                  <h3>
                    {t('APP.EXPORTRSEE.EXPORTRSEE.PH_B30BBA', {
                      defaultValue: 'Bâtiment',
                    })}{' '}
                    {index + 1}
                  </h3>
                  <h4>{item.name}</h4>
                </div>
              </div>
            ))}
          </div>
          {/* main */}
          <div className="exportRseeContainer__main">
            {/* <div className='exportRseeContainer__menu--item-smallTitle'>
                <h3 style={{ marginTop: '20px', color: "red", marginLeft: '80px', marginRight: '80px' }}>
                  {generateRSEnv.rsenvVersion === '1.0.1.3' ? "Attention, les équipements ne correspondant pas à cette version de RSEnv ne seront pas intégrés dans votre livrable (Configurateurs de fiche configurées > 1003, id d'unité fonctionnelle > 47 et composants vides)": ''}
                </h3>
              </div> */}
            {indexNav === 0 && (
              <div className="exportRseeContainer__content">
                <div style={{ display: 'flex' }}>
                  <div style={{ flexGrow: 1, padding: '1rem' }}>
                    <div className="exportRseeContainer__content--category information">
                      {t('APP.EXPORTRSEE.EXPORTRSEE.PH_348519', {
                        defaultValue: 'Acteurs: Bureau ACV',
                      })}
                    </div>
                    <div className="exportRseeContainer__content--item">
                      <div className="exportRseeContainer__content--item-label">
                        {t('APP.EXPORTRSEE.EXPORTRSEE.PH_D00AF7', {
                          defaultValue: 'Nom',
                        })}
                      </div>
                      <div className="exportRseeContainer__content--item-selector">
                        <Controller
                          name={'acvDesignOffice.name'}
                          control={control}
                          rules={{
                            required: t('APP.EXPORTRSEE.EXPORTRSEE.PH_4E5956', {
                              defaultValue: 'Veuillez remplir ce champ',
                            }),
                          }}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={t('APP.EXPORTRSEE.EXPORTRSEE.PH_62B950', {
                                defaultValue: 'Votre nom',
                              })}
                              size="large"
                              style={{ width: '300px' }}
                              status={errors?.acvDesignOffice?.name ? 'error' : ''}
                              required={true}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="exportRseeContainer__content--item">
                      <div className="exportRseeContainer__content--item-label">
                        {t('APP.EXPORTRSEE.EXPORTRSEE.PH_7F0749', {
                          defaultValue: 'Siret',
                        })}
                      </div>
                      <div className="exportRseeContainer__content--item-selector">
                        <Controller
                          name={'acvDesignOffice.siret'}
                          control={control}
                          rules={{
                            required: t('APP.EXPORTRSEE.EXPORTRSEE.PH_BEFF9B', {
                              defaultValue: 'Veuillez remplir ce champ',
                            }),

                            validate: {
                              isSiret: (v) => {
                                if (v === undefined || v?.toString().length === 0) {
                                  return true;
                                } else if (v?.toString().length !== 14) {
                                  return t('APP.EXPORTRSEE.EXPORTRSEE.PH_416A02', {
                                    defaultValue: 'Entrez un nombre de 14 chiffres',
                                  });
                                } else if (isNaN(Number(v))) {
                                  return t('APP.EXPORTRSEE.EXPORTRSEE.PH_A1C6FA', {
                                    defaultValue: 'Entrez un nombre de 14 chiffres',
                                  });
                                } else {
                                  return true;
                                }
                              },
                            },
                          }}
                          render={({ field }) => (
                            <Input
                              {...field}
                              status={errors?.acvDesignOffice?.siret ? 'error' : ''}
                              placeholder={t('APP.EXPORTRSEE.EXPORTRSEE.PH_68247D', {
                                defaultValue: 'Nombre avec 14 chiffres',
                              })}
                              size="large"
                              style={{ width: '300px' }}
                              required={true}
                            />
                          )}
                        />
                      </div>
                      {
                        // errors.acvDesignOffice?.siret  && isSubmitted &&
                        errors.acvDesignOffice?.siret && (
                          <div className="errorMessage">
                            {errors.acvDesignOffice?.siret.message}
                          </div>
                        )
                      }
                    </div>
                    <div className="exportRseeContainer__content--item">
                      <div className="exportRseeContainer__content--item-label">
                        {t('APP.EXPORTRSEE.EXPORTRSEE.PH_529561', {
                          defaultValue: 'Adresse',
                        })}
                      </div>
                      <div className="exportRseeContainer__content--item-selector">
                        <Controller
                          rules={{
                            required: t('APP.EXPORTRSEE.EXPORTRSEE.PH_1F87DD', {
                              defaultValue: 'Veuillez remplir ce champ',
                            }),
                          }}
                          name={'acvDesignOffice.address'}
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={t('APP.EXPORTRSEE.EXPORTRSEE.PH_921627', {
                                defaultValue: 'Entrez votre rue',
                              })}
                              size="large"
                              style={{ width: '300px' }}
                              required={true}
                              status={
                                errors?.acvDesignOffice?.address ? 'error' : ''
                              }
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="exportRseeContainer__content--item">
                      <div className="exportRseeContainer__content--item-label">
                        {t('APP.EXPORTRSEE.EXPORTRSEE.PH_7BCAAA', {
                          defaultValue: 'Code Postal',
                        })}
                      </div>
                      <div className="exportRseeContainer__content--item-selector">
                        <Controller
                          control={control}
                          rules={{
                            required: t('APP.EXPORTRSEE.EXPORTRSEE.PH_0102A3', {
                              defaultValue: 'Veuillez remplir ce champ',
                            }),
                            validate: {
                              isPostal: (v) => {
                                if (v === undefined || v?.toString().length === 0) {
                                  return true;
                                } else if (v?.toString().length !== 5) {
                                  return t('APP.EXPORTRSEE.EXPORTRSEE.PH_C91409', {
                                    defaultValue: 'Entrez un nombre de 5 chiffres',
                                  });
                                } else if (isNaN(Number(v))) {
                                  return t('APP.EXPORTRSEE.EXPORTRSEE.PH_67D48C', {
                                    defaultValue: 'Entrez un nombre de 5 chiffres',
                                  });
                                } else {
                                  return true;
                                }
                              },
                            },
                          }}
                          {...register('acvDesignOffice.postal')}
                          render={({ field }) => (
                            <Input
                              {...field}
                              status={errors?.acvDesignOffice?.postal ? 'error' : ''}
                              placeholder={t('APP.EXPORTRSEE.EXPORTRSEE.PH_4A2514', {
                                defaultValue: 'code postal',
                              })}
                              size="large"
                              style={{ width: '300px' }}
                              required={true}
                            />
                          )}
                        />
                      </div>
                      {
                        // errors.acvDesignOffice?.postal  && isSubmitted &&
                        errors.acvDesignOffice?.postal && (
                          <div className="errorMessage">
                            {errors.acvDesignOffice?.postal.message}
                          </div>
                        )
                      }
                    </div>
                    <div className="exportRseeContainer__content--item">
                      <div className="exportRseeContainer__content--item-label">
                        {t('APP.EXPORTRSEE.EXPORTRSEE.PH_C01BDF', {
                          defaultValue: 'Ville',
                        })}
                      </div>
                      <div className="exportRseeContainer__content--item-selector">
                        <Controller
                          rules={{
                            required: t('APP.EXPORTRSEE.EXPORTRSEE.PH_7207E7', {
                              defaultValue: 'Veuillez remplir ce champ',
                            }),
                          }}
                          name={'acvDesignOffice.city'}
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={t('APP.EXPORTRSEE.EXPORTRSEE.PH_EDF130', {
                                defaultValue: 'Ville',
                              })}
                              size="large"
                              style={{ width: '300px' }}
                              required={true}
                              status={errors?.acvDesignOffice?.city ? 'error' : ''}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="exportRseeContainer__content--separator" />
                    <div className="exportRseeContainer__content--category information">
                      {t('APP.EXPORTRSEE.EXPORTRSEE.PH_1164E2', {
                        defaultValue: 'Risques Sismiques',
                      })}
                    </div>
                    <div className="exportRseeContainer__content--item-selector">
                      <Controller
                        name={'earthQuakeZone'}
                        control={control}
                        rules={{
                          required: t('APP.EXPORTRSEE.EXPORTRSEE.PH_908CC9', {
                            defaultValue: 'Veuillez remplir ce champ',
                          }),
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            style={{ width: '300px' }}
                            defaultValue={undefined}
                            options={[
                              {
                                value: 1,
                                label: t('APP.EXPORTRSEE.EXPORTRSEE.PH_CECBC8', {
                                  defaultValue: 'Très faible',
                                }),
                              },
                              {
                                value: 2,
                                label: t('APP.EXPORTRSEE.EXPORTRSEE.PH_584EFC', {
                                  defaultValue: 'Faible',
                                }),
                              },
                              {
                                value: 3,
                                label: t('APP.EXPORTRSEE.EXPORTRSEE.PH_72488F', {
                                  defaultValue: 'Moyenne',
                                }),
                              },
                              {
                                value: 4,
                                label: t('APP.EXPORTRSEE.EXPORTRSEE.PH_7908CB', {
                                  defaultValue: 'Forte',
                                }),
                              },
                            ]}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      flexGrow: 1,
                      padding: '1rem',
                      width: '430px!important',
                    }}
                  >
                    <div className="exportRseeContainer__content--category information">
                      {t('APP.EXPORTRSEE.EXPORTRSEE.PH_9F0235', {
                        defaultValue: "Réalisation d'une maquette numérique",
                      })}
                    </div>
                    <div style={{ minHeight: '16.5px' }} />

                    <div
                      className="exportRseeContainer__content--item-selector"
                      style={{ marginBottom: '20px' }}
                    >
                      <Controller
                        name={'digitalModel'}
                        control={control}
                        rules={{
                          // required: "Veuillez remplir ce champ",
                          validate: {
                            isBoolean: (v) => {
                              if (v === false || v === true) {
                                return true;
                              } else {
                                return false;
                              }
                            },
                          },
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            style={{ width: '300px' }}
                            defaultValue={undefined}
                            options={[
                              {
                                value: true,
                                label: 'Oui',
                              },
                              {
                                value: false,
                                label: 'Non',
                              },
                            ]}
                            className={errors?.digitalModel ? 'error' : ''}
                          />
                        )}
                      />
                    </div>

                    <div className="exportRseeContainer__content--category information">
                      {t('APP.EXPORTRSEE.EXPORTRSEE.PH_D9CA5D', {
                        defaultValue: "Maître d'ouvrage",
                      })}
                    </div>
                    <div
                      className="exportRseeContainer__content--item-selector"
                      style={{ marginBottom: '20px' }}
                    >
                      <Controller
                        name={'projectOwnerType'}
                        control={control}
                        rules={{
                          required: t('APP.EXPORTRSEE.EXPORTRSEE.PH_420689', {
                            defaultValue: 'Veuillez remplir ce champ',
                          }),
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            style={{ width: '300px' }}
                            defaultValue={undefined}
                            options={[
                              {
                                value: 1,
                                label: t('APP.EXPORTRSEE.EXPORTRSEE.PH_8BBDEF', {
                                  defaultValue: 'Personne morale',
                                }),
                              },
                              {
                                value: 2,
                                label: t('APP.EXPORTRSEE.EXPORTRSEE.PH_6D8612', {
                                  defaultValue: 'Personne physique',
                                }),
                              },
                            ]}
                            className={errors?.projectOwnerType ? 'error' : ''}
                          />
                        )}
                      />
                    </div>

                    <div className="exportRseeContainer__content--item">
                      <div className="exportRseeContainer__content--item-label">
                        {t('APP.EXPORTRSEE.EXPORTRSEE.PH_254520', {
                          defaultValue: 'Siret',
                        })}
                      </div>
                      <div className="exportRseeContainer__content--item-selector">
                        <Controller
                          name={'projectOwnerSiret'}
                          control={control}
                          rules={{
                            // required: "Veuillez remplir ce champ",
                            validate: {
                              isSiret: (v) => {
                                if (
                                  v === undefined ||
                                  v?.toString().length === 0 ||
                                  v === null
                                ) {
                                  return true;
                                } else if (v?.toString().length !== 14) {
                                  return t('APP.EXPORTRSEE.EXPORTRSEE.PH_D909B5', {
                                    defaultValue: 'Entrez un nombre de 14 chiffres',
                                  });
                                } else if (isNaN(Number(v))) {
                                  return t('APP.EXPORTRSEE.EXPORTRSEE.PH_8ABD78', {
                                    defaultValue: 'Entrez un nombre de 14 chiffres',
                                  });
                                } else {
                                  return true;
                                }
                              },
                            },
                          }}
                          render={({ field }) => (
                            <Input
                              {...field}
                              status={errors?.projectOwnerSiret ? 'error' : ''}
                              placeholder={t('APP.EXPORTRSEE.EXPORTRSEE.PH_DE92F4', {
                                defaultValue: 'Nombre avec 14 chiffres',
                              })}
                              size="large"
                              style={{ width: '300px' }}
                              required={true}
                            />
                          )}
                        />
                      </div>
                      {
                        // errors.acvDesignOffice?.siret  && isSubmitted &&
                        errors.projectOwnerSiret && (
                          <div className="errorMessage">
                            {errors.projectOwnerSiret?.message}
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* buildings */}
            {fields.map((building, indexBuilding) => {
              return (
                indexNav === indexBuilding + 1 && (
                  <div key={building.id} className="exportRseeContainer__content">
                    {formData.map((category) => {
                      return (
                        <>
                          {/* Display of Categories */}
                          <div
                            key={category.categoryIndex}
                            className="exportRseeContainer__content--category"
                          >
                            {category.category}
                          </div>
                          {/* Display all items from Category */}
                          <div className="exportRseeContainer__content--items">
                            {/* display each Item */}
                            {category.items.map((item, index: number) => {
                              const ref = item.ref;

                              return (
                                <div
                                  key={index}
                                  className="exportRseeContainer__content--item"
                                >
                                  <div className="exportRseeContainer__content--item-label">
                                    {item.name}
                                  </div>
                                  <div className="exportRseeContainer__content--item-selector">
                                    <Controller
                                      control={control}
                                      {...register(
                                        `buildings.${indexBuilding}.${ref}`
                                      )}
                                      render={({ field }) => (
                                        <Select
                                          {...field}
                                          defaultValue={undefined}
                                          onClick={() => {
                                            handleChange();
                                          }}
                                          options={item.options.map((option) => {
                                            const [key] = Object.keys(option);
                                            const [value] = Object.values(option);

                                            return {
                                              value: value,
                                              label: key,
                                            };
                                          })}
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="exportRseeContainer__content--separator" />
                        </>
                      );
                    })}
                  </div>
                )
              );
            })}

            {/* buttons footer */}
            <div className="exportRseeContainer__content--footer">
              <button onClick={handleSubmit(onSubmit, onSubmitError)}>
                {t('APP.EXPORTRSEE.EXPORTRSEE.PH_5D8313', {
                  defaultValue: 'Sauvegarder et quitter',
                })}
              </button>
              {indexNav !== 0 && (
                <div
                  className="exportRsee__previusButton"
                  onClick={() => navigation('previous')}
                >
                  <DoubleLeftOutlined className=" exportRsee__previusButton--icon" />
                  <p>
                    {t('APP.EXPORTRSEE.EXPORTRSEE.PH_DE9AEF', {
                      defaultValue: 'Précédent',
                    })}
                  </p>
                </div>
              )}
              {indexNav !== indexMax && (
                <div
                  className="exportRsee__nextButton"
                  onClick={handleSubmit(onNextSubmit, onSubmitError)}
                >
                  <p>
                    {t('APP.EXPORTRSEE.EXPORTRSEE.PH_7D6E5C', {
                      defaultValue: 'Suivant',
                    })}
                  </p>
                  <DoubleRightOutlined className=" exportRsee__nextButton--icon" />
                </div>
              )}
              {indexNav === indexMax && !isFormCompleted && (
                <Tooltip
                  title={t('APP.EXPORTRSEE.EXPORTRSEE.PH_524FA9', {
                    defaultValue:
                      'Tous les champs de tous les bâtiments doivent être remplis',
                  })}
                  placement="top"
                >
                  <div className="exportRsee__nextButton disabled">
                    <p>
                      {t('APP.EXPORTRSEE.EXPORTRSEE.PH_0B2E63', {
                        defaultValue: 'Générer RSEnv/Rsee',
                      })}
                    </p>
                  </div>
                </Tooltip>
              )}
              {indexNav === indexMax && isFormCompleted && (
                <div
                  className="exportRsee__nextButton"
                  onClick={handleSubmit(onGenerateSubmit, onSubmitError)}
                >
                  <p>
                    {t('APP.EXPORTRSEE.EXPORTRSEE.PH_10876E', {
                      defaultValue: 'Générer RSEnv/Rsee',
                    })}
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
