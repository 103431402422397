export const UPDATE_PRODUCTS_VALUE = 'UPDATE_PRODUCTS_VALUE';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const INIT_DATAS_PRODUCTS = 'INIT_DATAS_PRODUCTS';
export const FILTER_LIST_PRODUCTS = 'FILTER_LIST_PRODUCTS';

export const updateProductsValue = (name, value) => ({
  type: UPDATE_PRODUCTS_VALUE,
  name,
  value,
});

export const fetchProducts = (concat) => ({
  type: FETCH_PRODUCTS,
  concat,
});

export const initDatasProducts = () => ({
  type: INIT_DATAS_PRODUCTS,
});
