import { connect } from 'react-redux';

import { patchZones, updateFormValue } from '../../actions/projects';
import Settings from '../../app/projects/Settings/Settings';

const mapStateToProps = (state) => ({
  editZones: state.projects.editZones,
  currentIndicatorId: state.projects.currentIndicator.id,
  companies: state.projects.companies,
  settingsDatas: state.projects.settingsDatas,
  // currentProject: state.projects.currentProject,
  projects: state.projects.projects,
  variantName: state.imports.variantName,
  zones: state.projects.zones,
  licenceTypeAccess: state.projects.licenceTypeAccess,
});

const mapDispatchToProps = (dispatch) => ({
  updateFormValue: (name, value) => {
    dispatch(updateFormValue(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
