import React, { useEffect } from 'react';
import API from '../../services/API';
import { useHistory, useLocation } from 'react-router-dom';
const api = new API(true);

const CatcherLoginRedirect = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    // Now you can get any query parameter you want like this:
    const uuid = queryParams.get('uuid');
    if (uuid) {
      api.postUuidForVinciSSO(uuid).then((response) => {
        window.location.href = '/projects';
      });
    }
  }, []);

  return <div></div>;
};

export default CatcherLoginRedirect;
