import {
  ALLOTMENT_IDS,
  TYPE_AIRPORT,
  TYPE_DATACENTER,
  TYPE_EPCM,
  TYPE_NOOCO,
  TYPE_PROTOTYPE,
  TYPE_RE2020,
  TYPE_RENOVATION,
} from '../globalVariable/typeProject';
import { Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import SearchBar from './SearchBar';
import { useTranslation } from 'react-i18next';
import { LOTS_IDS } from '../globalVariable/generalDefinitions';

let lodash = require('lodash/array');

const Breadcrumb = ({
  groups,
  onSelect,
  onTypeSelected,
  searchBar,
  searchValue,
  setSearchValue,
  inputValue,
  setInputValue,
  selectedLot,
  selectedFonction,
  selectedCategory,
  selectedMaterial,
  selectedType,
  updateFormValue,
  lineId,
  disabledBreadcrumbSelects,
  updateActivatedSelects,
  modifyActivatedSelects,
  isRe2020,
  currentProject,
  addPage,
  importBreadcrumb,
  projectAllot,
  currentAllotment,
  validZonesIds,
}) => {
  const [groupsFiltered, setGroupsFiltered] = useState([...groups]);
  const { allGroups, allotmentName } = useSelector((state) => state.projects);
  const { t } = useTranslation();
  const { isBundleScreen } = useSelector((state) => state.bundle);

  /**
   * Callback used for searchbar component
   * @param value
   */
  const handleSearchValue = (value) => {
    updateFormValue('nodeSearchValue', value);
    setSearchValue(
      allGroups.type.find((el) => el.id === value.MasterDataEquipmentId)
    );
  };

  /**
   * Callback used for searchBar Component
   * @param value
   */
  const handleInputValue = (value) => {
    setInputValue(value);
  };

  /**
   * This function is called when the user selects an element in the search bar.
   * @param value, the row, its the groups.type matching the search bar value.
   */
  const handleSearchBar = (value) => {
    let lot, fonction, category, material, type;

    if (!value) {
      lot = undefined;
      fonction = undefined;
      category = undefined;
      material = undefined;
      type = undefined;
      modifyActivatedSelects([false, true, true, true, true]);
    } else {
      lot = value.lot;
      fonction = value.fonction;
      category = value.categorie;
      material = value.materiel;
      type = value.type;
      modifyActivatedSelects([false, false, false, false, false]);
    }

    const flag = !value ? 'lot' : 'type';

    // We set our state to the values of the selected option. Basically, we fill in or clear the breadcrumb.

    updateFormValue('selectedLot', lot);
    updateFormValue('selectedFonction', fonction);
    updateFormValue('selectedCategory', category);
    updateFormValue('selectedMaterial', material);
    updateFormValue('selectedType', type);

    // These are the callbacks used by other components. For instance, on the Project page breadcrumb, when we
    // search for an elem, it renders the corresponding Line Chart Graph

    if (onSelect) {
      onSelect(lot, fonction, category, material, type, flag);
    }

    if (onTypeSelected) {
      onTypeSelected(type, lot, fonction, category, material);
    }

    // Weird fix for lot no changing input value
    if (!value) {
      updateFormValue('selectedLot', '');
      setSearchValue(undefined);
    }
  };

  useEffect(() => {
    const templateTypeForTransportAndGears = [
      TYPE_NOOCO,
      TYPE_DATACENTER,
      TYPE_PROTOTYPE,
      TYPE_AIRPORT,
      TYPE_RENOVATION,
      TYPE_RE2020,
      TYPE_EPCM,
    ];
    let lotNotAvailable = [LOTS_IDS.ENERGIE_SIMPLIFIEE];
    let allAllotmentsNoocoAtLvl0 = allGroups.flatAllotments.filter(
      (el) => el.AllotmentId === ALLOTMENT_IDS.NOOCO && el.level === 0
    );

    if (!currentProject.Lots) {
      setGroupsFiltered([...allAllotmentsNoocoAtLvl0]);
    } else if (
      templateTypeForTransportAndGears.includes(currentProject.templateType)
    ) {
      setGroupsFiltered([
        ...allAllotmentsNoocoAtLvl0.filter(
          (el) =>
            !lotNotAvailable.includes(el.id) &&
            el.id !== LOTS_IDS.TRANSPORTS_ENGINS &&
            currentProject.Lots?.find((lot) => lot.id === el.id)
        ),
      ]);
    } else {
      setGroupsFiltered([
        ...allAllotmentsNoocoAtLvl0.filter(
          (el) =>
            !lotNotAvailable.includes(el.id) &&
            currentProject.Lots?.find((lot) => lot.id === el.id)
        ),
      ]);
    }
  }, []);

  let excludesAllotmentNodeWhichIncludesForfait = (allotmentNode) => {
    return allotmentNode.name.toLowerCase().includes('forfaitaire');
  };

  return (
    <div
      style={
        !lineId
          ? {
              display: !importBreadcrumb ? 'flex' : null,
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10px',
              flexWrap: 'wrap',
              paddingLeft: '12.5px',
            }
          : {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '20px',
              flexWrap: 'wrap',
              paddingLeft: '12.5px',
            }
      }
    >
      {searchBar ? (
        <SearchBar
          searchValue={searchValue}
          inputValue={inputValue}
          // groups={[...groups.filter((el) => !el.isObsolete)]}
          groups={[...groups]}
          projectAllot={projectAllot}
          currentAllotment={currentAllotment}
          updateFormValue={updateFormValue}
          onChange={handleSearchBar}
          handleSearchValue={handleSearchValue}
          handleInputValue={handleInputValue}
          currentProject={currentProject}
          validZonesIds={validZonesIds}
        />
      ) : null}
      {!searchBar && (
        <>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Lot</Form.Label>
            <Form.Control
              as="select"
              value={selectedLot}
              disabled={disabledBreadcrumbSelects[0] || (isRe2020 && !addPage)}
              onChange={(e) => {
                updateFormValue('selectedLot', e.target.value);
                // TODO Function that handles reseting the values
                // TODO These functions are shite, make a smarter function
                updateFormValue('selectedFonction', undefined);
                updateFormValue('selectedCategory', undefined);
                updateFormValue('selectedMaterial', undefined);
                setInputValue('');
                setSearchValue('');
                updateFormValue('selectedAllotmentNode', null);
                updateActivatedSelects(0, e.target.value);

                let flag;

                if (e.target.value) flag = 'fonction';
                else flag = 'lot';

                if (onSelect) {
                  onSelect(
                    e.target.value,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    flag
                  );
                }
              }}
            >
              <option value={undefined} label="Choisir un lot" />
              {groupsFiltered
                ? lodash
                    .uniqBy(groupsFiltered, 'name')
                    .filter(
                      (group) => group.name !== 'Terre' && group.isObsolete === false
                    )
                    .map((group) => (
                      <option key={group.name} label={group.name} value={group.id} />
                    ))
                : null}
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>Fonction</Form.Label>
            <Form.Control
              disabled={disabledBreadcrumbSelects[1] || (isRe2020 && !addPage)}
              required
              value={selectedFonction}
              onChange={(e) => {
                updateFormValue('selectedFonction', e.target.value);
                updateFormValue('selectedCategory', undefined);
                updateFormValue('selectedMaterial', undefined);
                updateFormValue('selectedAllotmentNode', null);

                setInputValue('');
                setSearchValue('');

                let flag;

                if (e.target.value) flag = 'categorie';
                else flag = 'fonction';

                updateActivatedSelects(1, e.target.value);

                if (onSelect) {
                  onSelect(
                    selectedLot,
                    e.target.value,
                    undefined,
                    undefined,
                    undefined,
                    flag
                  );
                }
              }}
              type="text"
              as="select"
            >
              <option value={undefined} label="Choisir une fonction" />

              {groupsFiltered
                ? allGroups.flatAllotments
                    .filter(
                      (el) =>
                        el.AllotmentId === ALLOTMENT_IDS.NOOCO &&
                        el.level === 1 &&
                        !el.isObsolete &&
                        !excludesAllotmentNodeWhichIncludesForfait(el)
                    )

                    .map((group) => {
                      if (group.ParentId === Number(selectedLot)) {
                        return (
                          <option
                            key={group.name}
                            label={group.name}
                            value={group.id}
                          />
                        );
                      }

                      return null;
                    })
                : null}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {t('APP.SHARED.BREADCRUMB.PH_29E262', {
                defaultValue: 'Veuillez choisir un lot.',
              })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>
              {t('APP.SHARED.BREADCRUMB.PH_71079E', {
                defaultValue: 'Catégorie',
              })}
            </Form.Label>
            <Form.Control
              disabled={disabledBreadcrumbSelects[2] || (isRe2020 && !addPage)}
              required
              value={selectedCategory}
              onChange={(e) => {
                updateFormValue('selectedCategory', e.target.value);
                updateFormValue('selectedMaterial', undefined);
                updateFormValue('selectedAllotmentNode', null);

                setInputValue('');
                setSearchValue('');

                let flag;

                if (e.target.value) flag = 'materiel';
                else flag = 'categorie';

                updateActivatedSelects(2, e.target.value);

                if (onSelect) {
                  onSelect(
                    selectedLot,
                    selectedFonction,
                    e.target.value,
                    undefined,
                    undefined,
                    flag
                  );
                }
              }}
              type="text"
              as="select"
            >
              <option
                value={undefined}
                label={t('APP.SHARED.BREADCRUMB.PH_68D6E1', {
                  defaultValue: 'Choisir une categorie',
                })}
              />

              {groupsFiltered
                ? allGroups.flatAllotments
                    .filter(
                      (el) =>
                        el.AllotmentId === ALLOTMENT_IDS.NOOCO &&
                        el.level === 2 &&
                        !el.isObsolete &&
                        !excludesAllotmentNodeWhichIncludesForfait(el)
                    )

                    .map((group) => {
                      if (group.ParentId === Number(selectedFonction)) {
                        return (
                          <option
                            key={group.name}
                            label={group.name}
                            value={group.id}
                          />
                        );
                      }

                      return null;
                    })
                : null}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {t('APP.SHARED.BREADCRUMB.PH_2D6B8E', {
                defaultValue: 'Veuillez choisir un lot.',
              })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>
              {t('APP.SHARED.BREADCRUMB.PH_50FAAA', {
                defaultValue: 'Materiel',
              })}
            </Form.Label>
            <Form.Control
              disabled={disabledBreadcrumbSelects[3] || (isRe2020 && !addPage)}
              required
              value={selectedMaterial}
              onChange={(e) => {
                updateFormValue('selectedMaterial', e.target.value);
                updateFormValue('selectedAllotmentNode', null);

                setInputValue('');
                setSearchValue('');

                let flag;

                if (e.target.value) flag = 'type';
                else flag = 'materiel';

                updateActivatedSelects(3, e.target.value);

                if (onSelect) {
                  onSelect(
                    selectedLot,
                    selectedFonction,
                    selectedCategory,
                    e.target.value,
                    undefined,
                    flag
                  );
                }
              }}
              type="text"
              as="select"
            >
              <option
                value={undefined}
                label={t('APP.SHARED.BREADCRUMB.PH_5E54B4', {
                  defaultValue: 'Choisir un materiel',
                })}
              />
              {groupsFiltered
                ? allGroups.flatAllotments
                    .filter(
                      (el) =>
                        el.AllotmentId === ALLOTMENT_IDS.NOOCO &&
                        el.level === 3 &&
                        !el.isObsolete &&
                        !excludesAllotmentNodeWhichIncludesForfait(el)
                    )

                    .map((group) => {
                      if (group.ParentId === Number(selectedCategory)) {
                        return (
                          <option
                            key={group.name}
                            label={group.name}
                            value={group.id}
                          />
                        );
                      }

                      return null;
                    })
                : null}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {t('APP.SHARED.BREADCRUMB.PH_BDF06B', {
                defaultValue: 'Veuillez choisir un lot.',
              })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>
              {t('APP.SHARED.BREADCRUMB.PH_EAF6C4', {
                defaultValue: 'Libellé',
              })}
            </Form.Label>
            <Form.Control
              disabled={disabledBreadcrumbSelects[4] || (isRe2020 && !addPage)}
              required
              value={selectedType}
              onChange={(e) => {
                updateFormValue('selectedType', e.target.value);

                const flag = 'type';

                setInputValue('');
                setSearchValue('');

                updateActivatedSelects(4, e.target.value);

                if (onSelect && !lineId) {
                  onSelect(
                    selectedLot,
                    selectedFonction,
                    selectedCategory,
                    selectedMaterial,
                    e.target.value,
                    flag
                  );
                }

                if (lineId) {
                  onSelect(
                    selectedLot,
                    selectedFonction,
                    selectedCategory,
                    selectedMaterial,
                    e.target.value,
                    lineId
                  );
                }

                if (onTypeSelected) {
                  onTypeSelected(e.target.value);
                }
              }}
              type="text"
              as="select"
            >
              <option
                value={undefined}
                label={t('APP.SHARED.BREADCRUMB.PH_F370F3', {
                  defaultValue: 'Choisir un libellé',
                })}
              />
              {groupsFiltered
                ? allGroups.flatAllotments
                    .filter(
                      (el) =>
                        el.AllotmentId === ALLOTMENT_IDS.NOOCO &&
                        el.level === 4 &&
                        !el.isObsolete
                    )

                    .map((group) => {
                      if (group.ParentId === Number(selectedMaterial)) {
                        return (
                          <option
                            key={group.name}
                            label={group.name}
                            value={group.id}
                          />
                        );
                      }

                      return null;
                    })
                : null}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {t('APP.SHARED.BREADCRUMB.PH_C9BE17', {
                defaultValue: 'Veuillez choisir un lot.',
              })}
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}
    </div>
  );
};

export default Breadcrumb;
