import { Button } from '@photocarbone/nooco-ui';
import { Controller, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { ImportedLand } from '../../../../../models/CalculatorDTO';
import { Input } from 'antd';
import { StateDTO } from '../../../../../models/GeneralDTO';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

import { calculatorUpdate } from '../../../../../../actions/calculator';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React from 'react';
import useCaseWorksite from './UseCaseWorksite';
import { useTranslation } from 'react-i18next';

const WorksiteCarriedAway = ({ fieldsImportedForm, changeSourceOfEmptyRows }) => {
  const { zones } = useSelector((state: StateDTO) => state.projects.parsedProject);
  const { worksiteCalculatorDetailedActive } = useSelector(
    (state: StateDTO) => state.projects.calculator
  );
  const { zoneSelected } = useSelector(
    (state: StateDTO) => state.monitoring.breadcrumbMultizone
  );
  const {
    control,
    register,
    getValues,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useFormContext();
  const { fields, append, remove, replace, insert } = fieldsImportedForm;
  const {
    onSubmitScreen: onSubmit,
    isEquipmentEditable,
    isEquipmentFromParentZone,
    calculateAreaZoneProrata,
    isDisplayEquipment,
    isProjectIsOnlyIndividualHouse,
    getPreviousScreenActiveId,
    getNextScreenActiveId,
    zoneSourceToDisplay,
  } = useCaseWorksite();
  const isSmallScreenMode = window.innerWidth < 1550;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const importedEarthWork = watch('importedEarthWork');

  const onError = (errors) => {
    console.log('validation errors', errors);
  };

  const onSubmitPrevious = (data) => {
    if (isValid) {
      changeSourceOfEmptyRows({
        zone: zoneSelected,
        sideBarActiveId: getPreviousScreenActiveId(),
      });
      dispatch(
        calculatorUpdate(
          'worksiteCalculatorDetailedActive',
          worksiteCalculatorDetailedActive - 1
        )
      );
    } else {
      onSubmit(data, 'previous');
    }
  };

  const onSubmitNext = (data) => {
    if (isValid) {
      changeSourceOfEmptyRows({
        zone: zoneSelected,
        sideBarActiveId: getNextScreenActiveId(),
      });
      dispatch(
        calculatorUpdate(
          'worksiteCalculatorDetailedActive',
          worksiteCalculatorDetailedActive + 1
        )
      );
    } else {
      onSubmit(data, 'next');
    }
  };

  const onBlur = (data) => {
    // console.log("OnBlurdata imported data to send", data);
    //change state of
    // dispatch(updatecalculatorData('importedLand', data));
    dispatch(calculatorUpdate('calculatorData', data));
  };

  const onBlurError = (errors) => {
    console.log('onBlur imported validation errors', errors);
  };

  return (
    <>
      {/*header */}
      <div
        className={`calculatorFlexStart ${
          isSmallScreenMode ? 'isSmallScreenMode' : ''
        } `}
        style={{ marginBottom: '1rem', padding: '0 10px' }}
      >
        <h3
          className="calculator__label"
          style={{ width: isSmallScreenMode ? '350px' : '400px' }}
        >
          {t('APP.CALCULATORWORKSITECONTENT.WORKSITECARRIEDAWAY.PH_895574', {
            defaultValue: 'Quantités de terres importées sur chantier (tonnes)',
          })}
        </h3>
        <div
          style={{
            width: isSmallScreenMode ? '300px' : '400px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginBottom: '0.5rem',
          }}
        >
          <h3 className="calculator__label" style={{ marginBottom: '0.5rem' }}>
            {t('APP.CALCULATORWORKSITECONTENT.WORKSITECARRIEDAWAY.PH_E432BB', {
              defaultValue: 'Distance lieu d’import-chantier (km)',
            })}
          </h3>
          {isProjectIsOnlyIndividualHouse() && (
            <div
              style={{
                marginBottom: '0.5rem',
                marginLeft: '0.5rem',
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <Tooltip
                title={
                  "En calcul simplifié, la distance d'approvisionnement du chantier peut être prise par défaut à 30km"
                }
              >
                <InfoIcon
                  style={{ marginLeft: '5px', width: '18px', height: '18px' }}
                />
              </Tooltip>
            </div>
          )}
        </div>
        <h3
          className="calculator__label"
          style={{
            minWidth: isSmallScreenMode ? '100px' : '130px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {'Source'}
        </h3>
        <div
          style={{ display: 'flex', flexGrow: '1', justifyContent: 'flex-end' }}
        />
      </div>
      {/* array of raw */}
      <form
        className={`calculatorFlexColumnStart ${
          isSmallScreenMode ? 'isSmallScreenMode' : ''
        }`}
        style={{ flexGrow: '1' }}
        action=""
        // onSubmit={handleSubmit(onSubmit, onError)}
      >
        <div className="calculatorScrollContainer">
          {fields.map((item, index) => {
            let quantity = getValues(`importedEarthWork.${index}.quantity`);

            if (isEquipmentFromParentZone(item)) {
              const quantityProrata = calculateAreaZoneProrata(item);

              quantity = Math.round(quantity * quantityProrata * 10000) / 10000;
            }

            return (
              <div key={item.id} className="calculatorFlexStart">
                <div
                  className="calculatorFlexStart calculatorCardWrapper"
                  style={{ width: '100%' }}
                >
                  {isEquipmentEditable(item) ? (
                    // editable raw
                    <div className="cardWrapper " style={{ width: '100%' }}>
                      {/* // earth imported  */}
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          width: isSmallScreenMode ? '350px' : '400px',
                        }}
                      >
                        <h4
                          className="calculator__subLabel"
                          style={{ marginRight: '1rem' }}
                        >
                          <span className="calculator__label--grow">Q</span>
                          terres imp,{index + 1}
                        </h4>
                        <div
                          className="calculatorFlexColumnStart"
                          style={{ marginRight: '2.5rem' }}
                        >
                          <Controller
                            control={control}
                            {...register(`importedEarthWork.${index}.quantity`)}
                            rules={{
                              required:
                                importedEarthWork[index].distance &&
                                !isProjectIsOnlyIndividualHouse()
                                  ? 'Champ obligatoire'
                                  : false,
                              validate: {
                                isNumber: (v) => {
                                  if (!isNaN(Number(v.replace(/,/, '.')))) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                },
                              },
                            }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                status={
                                  errors?.importedEarthWork?.[index]?.quantity
                                    ? 'error'
                                    : ''
                                }
                                placeholder={t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITECARRIEDAWAY.PH_AAE755',
                                  { defaultValue: 'Saisir une quantité' }
                                )}
                                style={{ width: '240px' }}
                                onBlur={handleSubmit(onBlur, onBlurError)}
                              />
                            )}
                          />
                          {errors.importedEarthWork && (
                            <div className="container__main--errorMessage">
                              {errors?.importedEarthWork?.[index]?.quantity?.type ===
                              'isNumber'
                                ? t(
                                    'APP.CALCULATORWORKSITECONTENT.WORKSITECARRIEDAWAY.PH_80F05C',
                                    {
                                      defaultValue: 'Vous devez entrer un nombre',
                                    }
                                  )
                                : errors?.importedEarthWork?.[index]?.quantity
                                    ?.message}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* // distance buildingSite  */}
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          width: isSmallScreenMode ? '300px' : '400px',
                        }}
                      >
                        <h4
                          className="calculator__subLabel"
                          style={{ marginRight: '1rem' }}
                        >
                          <span className="calculator__label--grow">d</span>imp,
                          {t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITECARRIEDAWAY.PH_4FF857',
                            {
                              index: index + 1,
                              defaultValue: '{index}-chantier',
                            }
                          )}
                        </h4>
                        <Tooltip
                          title={
                            isProjectIsOnlyIndividualHouse()
                              ? t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITECARRIEDAWAY.PH_439A96',
                                  {
                                    defaultValue:
                                      'En calcul simplifié, la distance d’approvisionnement du chantier peut être prise par défaut à 30 km',
                                  }
                                )
                              : ''
                          }
                        >
                          <div className="calculatorFlexColumnStart">
                            <Controller
                              control={control}
                              {...register(`importedEarthWork.${index}.distance`)}
                              rules={{
                                required: importedEarthWork[index].quantity
                                  ? t(
                                      'APP.CALCULATORWORKSITECONTENT.WORKSITECARRIEDAWAY.PH_72DF89',
                                      { defaultValue: 'Champ obligatoire' }
                                    )
                                  : false,
                                validate: {
                                  isNumber: (v) => {
                                    if (!isNaN(Number(v.replace(/,/, '.')))) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  },
                                },
                              }}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  status={
                                    errors?.importedEarthWork?.[index]?.distance
                                      ? 'error'
                                      : ''
                                  }
                                  placeholder={t(
                                    'APP.CALCULATORWORKSITECONTENT.WORKSITECARRIEDAWAY.PH_7E421F',
                                    { defaultValue: 'Saisir une quantité' }
                                  )}
                                  style={{ width: '150px' }}
                                  onBlur={handleSubmit(onBlur, onBlurError)}
                                />
                              )}
                            />
                            {errors.importedEarthWork && (
                              <div className="container__main--errorMessage">
                                {errors?.importedEarthWork?.[index]?.distance
                                  ?.type === 'isNumber'
                                  ? t(
                                      'APP.CALCULATORWORKSITECONTENT.WORKSITECARRIEDAWAY.PH_5E8BA1',
                                      {
                                        defaultValue: 'Vous devez entrer un nombre',
                                      }
                                    )
                                  : errors?.importedEarthWork?.[index]?.distance
                                      ?.message}
                              </div>
                            )}
                          </div>
                        </Tooltip>
                      </div>

                      {/* // source indicator  */}
                      <div
                        style={{
                          minWidth: isSmallScreenMode ? '100px' : '130px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {zoneSourceToDisplay(item.origin)}
                      </div>

                      {/* Delete energy Type */}
                      <div
                        style={{
                          display: 'flex',
                          flexGrow: '1',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          if (!isEquipmentEditable(item)) {
                            return;
                          }
                          if (watch('importedEarthWork').length > 1) {
                            remove(index);
                            dispatch(calculatorUpdate('resultOfSearchCarac', null));
                          } else {
                            // resetField('importedEarthWork');

                            replace({
                              // masterDataId: null,
                              quantity: '',
                              distance: isProjectIsOnlyIndividualHouse() ? '30' : '',
                              origin: {
                                type: zoneSelected?.type || 'project',
                                sourceId: zoneSelected?.id || null,
                                uniqueId: uuidv4(),
                              },
                            });
                            remove(index);
                            insert(0, {
                              // masterDataId: null,
                              quantity: '',
                              distance: isProjectIsOnlyIndividualHouse() ? '30' : '',
                              origin: {
                                type: zoneSelected?.type || 'project',
                                sourceId: zoneSelected?.id || null,
                                uniqueId: uuidv4(),
                              },
                            });
                          }
                        }}
                      >
                        <HighlightOffIcon
                          style={{ width: '40px', height: '40px' }}
                          className="calculator__delElement"
                        />
                      </div>
                    </div>
                  ) : isDisplayEquipment(item) ? (
                    // not editable raw
                    <div className="cardWrapper disabled" style={{ width: '100%' }}>
                      {/* earth imported */}
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          width: isSmallScreenMode ? '350px' : '400px',
                        }}
                      >
                        <h4
                          className="calculator__subLabel"
                          style={{ marginRight: '1rem' }}
                        >
                          <span className="calculator__label--grow">Q</span>
                          terres imp,{index + 1}
                        </h4>
                        <div
                          className="calculatorFlexColumnStart"
                          style={{ marginRight: '2.5rem' }}
                        >
                          <Input
                            placeholder={t(
                              'APP.CALCULATORWORKSITECONTENT.WORKSITECARRIEDAWAY.PH_7814A0',
                              { defaultValue: 'Saisir une quantité' }
                            )}
                            style={{ width: '240px' }}
                            disabled={true}
                            value={quantity}
                          />
                        </div>
                      </div>

                      {/* distance buildingSite */}
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          width: isSmallScreenMode ? '300px' : '400px',
                        }}
                      >
                        <h4
                          className="calculator__subLabel"
                          style={{ marginRight: '1rem' }}
                        >
                          <span className="calculator__label--grow">d</span>imp,
                          {t(
                            'APP.CALCULATORWORKSITECONTENT.WORKSITECARRIEDAWAY.PH_DEF55D',
                            {
                              index: index + 1,
                              defaultValue: '{index}-chantier',
                            }
                          )}
                        </h4>
                        <div className="calculatorFlexColumnStart">
                          <Controller
                            control={control}
                            {...register(`importedEarthWork.${index}.distance`)}
                            rules={{
                              required: importedEarthWork[index].quantity
                                ? t(
                                    'APP.CALCULATORWORKSITECONTENT.WORKSITECARRIEDAWAY.PH_619292',
                                    { defaultValue: 'Champ obligatoire' }
                                  )
                                : false,
                              validate: {
                                isNumber: (v) => {
                                  if (!isNaN(Number(v.replace(/,/, '.')))) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                },
                              },
                            }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                status={
                                  errors?.importedEarthWork?.[index]?.distance
                                    ? 'error'
                                    : ''
                                }
                                placeholder={t(
                                  'APP.CALCULATORWORKSITECONTENT.WORKSITECARRIEDAWAY.PH_4FC5BC',
                                  { defaultValue: 'Saisir une quantité' }
                                )}
                                style={{ width: '150px' }}
                                disabled={true}
                              />
                            )}
                          />
                          {errors.importedEarthWork && (
                            <div className="container__main--errorMessage">
                              {errors?.importedEarthWork?.[index]?.distance?.type ===
                              'isNumber'
                                ? t(
                                    'APP.CALCULATORWORKSITECONTENT.WORKSITECARRIEDAWAY.PH_D8E2DF',
                                    {
                                      defaultValue: 'Vous devez entrer un nombre',
                                    }
                                  )
                                : errors?.importedEarthWork?.[index]?.distance
                                    ?.message}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* source indicator */}
                      <div
                        style={{
                          minWidth: isSmallScreenMode ? '100px' : '130px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#268F82',
                        }}
                      >
                        {zoneSourceToDisplay(item.origin)}
                      </div>
                    </div>
                  ) : (
                    // not displayed raw
                    <></>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <div
          className="calculator__addElement"
          onClick={() =>
            append({
              quantity: '',
              distance: isProjectIsOnlyIndividualHouse() ? '30' : '',
              origin: {
                type: zoneSelected?.type || 'project',
                sourceId: zoneSelected?.id || null,
                uniqueId: uuidv4(),
              },
            })
          }
        >
          <ControlPointIcon />
          {t('APP.CALCULATORWORKSITECONTENT.WORKSITECARRIEDAWAY.PH_5ACC26', {
            defaultValue: 'Ajouter une autre quantité de terres importées',
          })}
        </div>

        {/* next Button and Previus Button */}
        <div
          className="calculatorFlexColumnStart"
          style={{
            flexGrow: 1,
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <div
            className="calculatorFlexStart"
            style={{ justifyContent: 'flex-end' }}
          >
            {/* <div          
                      className='calculator__previusButton'
                      onClick={handleSubmit(onSubmitPrevious, onError)}
                     >
                      <DoubleLeftOutlined className=' calculator__previusButton--icon'/>
                      <p>Précédent</p>
                     </div> */}
            <Button
              style={{
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
              }}
              className="close-button"
              onClick={handleSubmit(onSubmitPrevious, onError)}
              disabled={getPreviousScreenActiveId() === null}
              text="Précédent"
            />

            <Button
              style={{
                fontSize: window.screen.availHeight > 950 ? '0.9rem' : '0.85rem',
                marginLeft: '5px',
              }}
              className="purple-button"
              onClick={handleSubmit(onSubmitNext, onError)}
              disabled={getNextScreenActiveId() === null}
              text="Suivant"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default WorksiteCarriedAway;
